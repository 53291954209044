import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import FlightFareOptionHeader from "./FlightFareOptionHeader";
import FlightFareOption from "./FlightFareOption";
import { isArrayNotEmpty } from "../../../utilities/validators";

function FlightFareOptionDetails(props) {
  const {
    fare,
    serviceFee,
    secType,
    sty,
    flights,
    selectedFlight,
    request,
    rtnflt,
    selFlt,
    region,
    mapping,
    sect,
  } = props;
  const [isCorporate, setIsCorporate] = useState(false);
  const [showDiscount, setShowDiscount] = useState(false);
  const [enableVandeBharath, setEnableVandeBharath] = useState(false);
  const [baggage, setBaggage] = useState("");
  const [showJeddah, setShowJeddah] = useState(false);
  const [show, setShow] = useState(false);
  const [showContent, setShowContent] = useState(false);
  const [gccCodes, setGccCodes] = useState([
    "XNB",
    "DWC",
    "DXB",
    "AZAZ",
    "DZB",
    "DOH",
    "XSB",
    "KWI",
    "MCT",
    "DMM",
    "SHJ",
    "IST",
    "JED",
  ]);
  const [vandeList1, setVandeList1] = useState([
    "TRV",
    "COK",
    "CNN",
    "HYD",
    "BLR",
    "BOM",
    "CCJ",
    "MAA",
    "LKO",
  ]);
  const [vandeList2, setVandeList2] = useState([
    "TRV",
    "COK",
    "CNN",
    "HYD",
    "BLR",
    "BOM",
    "CCJ",
    "MAA",
    "LKO",
    "AMD",
    "BBI",
  ]);
  useEffect(() => {
    checkIndigoContent(fare);
  }, []);

  //Checking Indigo sectors
  const checkIndigoContent = (fare) => {
    setIsCorporate(false);
    setShowDiscount(false);
    setEnableVandeBharath(false);
    if (region !== "" && region !== undefined) {
      if (region === "International") {
        //For International
        if (request.srcCode && request.srcCode === "JED" && request.dest[0] && request.dest[0].countryCode === "IN") {
          //JED - IND showing 5L Zamzam water
          setBaggage("30");
          setShow(true);
          setShowJeddah(true);
          setShowDiscount(true);
        } else {
          if (
            isArrayNotEmpty(request.src) && request.src[0].countryCode === "IN" &&
            isArrayNotEmpty(request.destCode) && request.destCode === "JED"
          ) {
            //IND - JED showing 30KG with same jeddah content
            setBaggage("30");
            setShow(true);
            setShowJeddah(true);
            setShowContent(true);
          } else {
            if (
              gccCodes.includes(request.srcCode) ||
              gccCodes.includes(request.destCode) ||
              gccCodes.includes(request.src_code) ||
              gccCodes.includes(request.dest_code)
            ) {
              //GCC Cities checking
              setBaggage("30");
              setShow(true);
            } else {
              //NON GCC Cities
              setBaggage("20");
              setShow(true);
            }
          }
        }
        //Cheking Vande Bharath Codes
        if (
          isArrayNotEmpty(request.src) && request.src[0].countryCode === "QA" &&
          isArrayNotEmpty(request.dest) && request.dest[0].countryCode === "IN"
        ) {
          if (vandeList1.includes(request.destCode)) {
            setEnableVandeBharath(true);
          }
        } else if (
          isArrayNotEmpty(request.src) && request.src[0].countryCode === "KW" &&
          isArrayNotEmpty(request.dest) && request.dest[0].countryCode === "IN"
        ) {
          if (vandeList2.includes(request.destCode)) {
            setEnableVandeBharath(true);
          }
        }
      } else {
        //Domestic
        setShow(true);
      }
      //Checking Corp Deal
      if (fare.pr.ftyp.includes("Corporate")) {
        setIsCorporate(true);
      }
    }
  };

  return (
      <div className="fareLCCWrap">
        <FlightFareOptionHeader
          mapping={mapping}
          sectors={sect}
          secType={secType}
        />
        <FlightFareOption
          fare={fare}
          showJeddah={showJeddah}
          region={region}
          isCorporate={isCorporate}
          showContent={showContent}
          showDiscount={showDiscount}
          enableVandeBharath={enableVandeBharath}
          baggage={baggage}
          airline={secType === 'arrival'?rtnflt.tktair:flights.tktair}
        />
      </div>
  );
}

export default FlightFareOptionDetails;
