import React, { useContext } from "react";
import { Col, Row, Typography } from "antd";
import { Context } from "../../App";

import Android from "../../assets/svg/android.svg";
import Ios from "../../assets/svg/ios.svg";
import QrCode from "../../assets/img/qrcode.png";

import AppDwd from "../../assets/img/App-Screen.png";

const { Title } = Typography;

function AppDownload() {
  const [context, setContext] = useContext(Context);
  return (
    <>
      <div className="appDownload">
        <div className="container">
          <Row>
            <Col className="da-app">
              <Title level={2}>
                {/* Download our APP & get the latest offers */}
                {context.translation && context.translation.downloadAppTlt}
              </Title>
              <Title level={5}>
                {/* Download our app now for easier flights & hotel bookings. Enjoy
                the best booking experience. */}
                {context.translation && context.translation.downloadAppDesp}
              </Title>
              {/* <Title level={5}>
                Enjoy the best booking experience.
              </Title> */}
            </Col>
            <Col className="da-now">
              <Title level={4}>
                {/* Download App Now? */}
                {context.translation && context.translation.downloadApp}
              </Title>
              <Row className="qrcodeApp">
                <Col span={12} className="maxWidth100">
                  <a href="#">
                    {" "}
                    <img
                      src={Android}
                      alt="Download Click N Travel"
                      className="anroidApp"
                    />
                  </a>
                  <a href="#">
                    {" "}
                    <img
                      src={Ios}
                      alt="Download Click N Travel"
                      className="iosApp"
                    />
                  </a>
                </Col>
                <Col span={12}  className="maxWidth100">
                  <img
                    src={QrCode}
                    alt="Download Click N Travel"
                    className="qrCodeImg"
                  />
                </Col>
              </Row>
            </Col>
            <Col className="da-img">
              <img
                src={AppDwd}
                alt="Download Click N Travel"
                className="mobileHeight"
              />
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}

export default AppDownload;
