import React, {
  useEffect,
  useRef,
} from "react";
import Footer from "./Footer";
import Header from "./Header";
import ShowHide from "./showHide/ShowHide";
import StickyBox from "react-sticky-box";
import { Col, Row, Typography } from "antd";

function FlightTermsAndConditions(props) {
  const tcGeneral = useRef(null);
  const tc1 = useRef(null);
  const tc2 = useRef(null);
  const tc3 = useRef(null);
  const tc4 = useRef(null);
  const tc5 = useRef(null);
  const tc6 = useRef(null);
  const tc7 = useRef(null);
  const executeScrolltop = () =>
    tcGeneral.current.scrollIntoView({ behavior: "smooth" });
  const executeScrolltop1 = () =>
    tc1.current.scrollIntoView({ behavior: "smooth" });
  const executeScrolltop2 = () =>
    tc2.current.scrollIntoView({ behavior: "smooth" });
  const executeScrolltop3 = () =>
    tc3.current.scrollIntoView({ behavior: "smooth" });
  const executeScrolltop4 = () =>
    tc4.current.scrollIntoView({ behavior: "smooth" });
  const executeScrolltop5 = () =>
    tc5.current.scrollIntoView({ behavior: "smooth" });
  const executeScrolltop6 = () =>
    tc6.current.scrollIntoView({ behavior: "smooth" });
  const executeScrolltop7 = () =>
    tc7.current.scrollIntoView({ behavior: "smooth" });
  const general = (e) => {
    executeScrolltop();
  };
  const general1 = (e) => {
    executeScrolltop1();
  };
  const general2 = (e) => {
    executeScrolltop2();
  };
  const general3 = (e) => {
    executeScrolltop3();
  };
  const general4 = (e) => {
    executeScrolltop4();
  };
  const general5 = (e) => {
    executeScrolltop5();
  };
  const general6 = (e) => {
    executeScrolltop6();
  };
  const general7 = (e) => {
    executeScrolltop7();
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  const { Title } = Typography;
  const update=(type)=>{
  }
  return (
    <>
      <Header posType="floating" setTab={update} />
      <div className="container tndC">
        <Title level={4} className="tcTitle">
          Flights Terms and Condtions
        </Title>
        <Row>
          <Col span={6} className="tcleftSidebar">
            <StickyBox offsetTop={100} offsetBottom={20}>
              <ul variant="pills" className="flex-column">
                <li>
                  <span eventKey="general" onClick={general} className="active">
                    Terms of the Airlines
                  </span>
                </li>
                <li>
                  <span eventKey="clientResp" onClick={general1}>
                    Pricing
                  </span>
                </li>
                <li>
                  <span eventKey="disclaimer" onClick={general2}>
                    Travel Documents
                  </span>
                </li>
                <li>
                  <span eventKey="pvcStatement" onClick={general3}>
                    Check-In Terms
                  </span>
                </li>
                <li>
                  <span eventKey="copyright" onClick={general4}>
                    Use of Flight Segments
                  </span>
                </li>
                <li>
                  <span eventKey="agency" onClick={general5}>
                    Changes to Existing Booking
                  </span>
                </li>
                <li>
                  <span eventKey="confidentiality" onClick={general6}>
                    Refund
                  </span>
                </li>
                <li>
                  <span eventKey="indemnity" onClick={general7}>
                    Others
                  </span>
                </li>
              </ul>
            </StickyBox>
          </Col>
          <Col span={18} className="tcLayout">
            <div ref={tcGeneral}>
              <ShowHide visible={false} icon="" title="Terms of the Airlines">
                <div className="showHide-content">
                  <p>
                    The airline tickets available through the Application are
                    subject to the terms & conditions of the concerned airline,
                    including but not limited to cancellation and refund
                    policies.
                  </p>
                  <p>
                    Clickntravel merely acts as a facilitator to enable the User
                    to book a flight ticket. The contract of service for
                    utilization of the flight is always between the User and the
                    concerned airline.
                  </p>
                  <p>
                    Airlines retain the right to reschedule flight times, route,
                    change or cancel flights or itineraries independent of and
                    without prior intimation to Clickntravel. As a facilitator
                    Clickntravel has no control or authority over the logistics
                    of the airlines and therefore is not liable for any loss,
                    direct or incidental, that a User may incur due to such
                    change or cancellation of a flight.
                  </p>
                  <p>
                    The baggage allowance on given fare is as per the terms
                    decided by the airline, and Clickntravel has no role to play
                    in the same. Some of the fares shown in the Application are
                    excluding “hand baggage” which do not entitle the User for
                    free check in baggage and therefore the User will be
                    required to pay separately for check in baggage. The prices
                    for adding check-in baggage to a booking may vary from
                    airline to airline. The User is advised to contact the
                    airlines for detailed costs.
                  </p>
                </div>
              </ShowHide>
            </div>
            <div ref={tc1}>
              <ShowHide visible="true" icon="" title="Pricing">
                <div className="showHide-content">
                  <p>
                    The total price displayed on the Application on the payment
                    page usually includes base fare, applicable government taxes
                    and convenience fee. Users are required to pay the entire
                    amount prior to the confirmation of their booking(s). In the
                    event the User does not pay the entire amount, Clickntravel
                    reserves its right to cancel the booking. User agrees to pay
                    all taxes, surcharges and fees, as applicable on the date of
                    travel.
                  </p>

                  <p>
                    To avail infant fares, the age of the child must be under 24
                    months throughout the entire itinerary. This includes both
                    onward and return journeys. If the infant is 24 months or
                    above on the return journey, User will be required to make a
                    separate booking using a child fare. Any infants or children
                    must be accompanied by an adult as per the terms of the
                    airlines.
                  </p>
                </div>
              </ShowHide>
            </div>
            <div ref={tc2}>
              <ShowHide visible="true" icon="" title="Travel Documents">
                <div className="showHide-content">
                  <p>
                    It shall be the sole responsibility of the User to ensure
                    they are in possession of valid travel documents such as
                    identity proof, passport, visa (including transit visa) etc.
                    to undertake the travel. User agrees that in case of
                    inability to travel for not carrying valid travel documents,
                    Clickntravel shall in no way be held liable.
                  </p>

                  <p>
                    User understands that the information (if any) provided by
                    Clickntravel regarding the travel documents is only advisory
                    in nature and can’t be considered conclusive. The User shall
                    ensure checking the requirements of travel with the
                    respective airlines of the respective jurisdictions the User
                    may transit through or choose to visit.
                  </p>
                </div>
              </ShowHide>
            </div>
            <div ref={tc3}>
              <ShowHide visible="true" icon="" title="Check-In Terms">
                <div className="showHide-content">
                  <p>
                    User should check with the airlines directly regarding the
                    check-in timings. Usually, check-in begins 3 hours before
                    departure for international flights.
                  </p>
                  <p>
                    User should carry valid identification proof, passport,
                    Vaccination certificate, Negative RT-PCR report as may be
                    required to prove the identity, nationality, and readiness
                    of the passengers travelling on a ticket, including infants.
                  </p>
                </div>
              </ShowHide>
            </div>
            <div ref={tc4}>
              <ShowHide visible="true" icon="" title="Use of Flight Segments">
                <div className="showHide-content">
                  <p>
                    In the event User does not embark on the onward journey, the
                    entire PNR pertaining to that booking shall be automatically
                    cancelled by the airline. In such a scenario Clickntravel
                    has no control in the said process nor will be obligated to
                    provide alternate bookings to the User. The cancellation and
                    No-show penalties in such an event shall be as per the
                    applicable airline rules.
                  </p>
                </div>
              </ShowHide>
            </div>
            <div ref={tc5}>
              <ShowHide
                visible="true"
                icon=""
                title="Changes to Existing Booking"
              >
                <div className="showHide-content">
                  <p>
                    Any changes that are made to any existing booking shall be
                    subject to certain charges levied by the respective airline,
                    apart from the service fee charged by Clickntravel.
                  </p>
                  <p>
                    The User shall be obligated to pay applicable charges in the
                    event of any alteration or modification to an existing
                    booking. However, depending on the airline's policy and fare
                    class, charges for changes or modifications to existing
                    bookings may vary.
                  </p>
                </div>
              </ShowHide>
            </div>
            <div ref={tc6}>
              <ShowHide visible="true" icon="" title="Refund">
                <div className="showHide-content">
                  <p>
                    Refunds will be processed as per the airline fare rules and
                    cancellation policy. Such refunds shall be subject to
                    Clickntravel receiving the same from the airlines. However,
                    the convenience fee paid to Clickntravel paid at the time of
                    booking is a non-refundable fee.
                  </p>
                  <p>
                    All cancellations made directly with the airline need to be
                    intimated to Clickntravel, in order to initiate the process
                    of refund. The processing time for refunds may vary
                    depending on the mode of payment and release by partner
                    bank. The refund shall be processed after deducting the
                    Clickntravel service fee which is independent of the
                    convenience fee as mentioned above.{" "}
                  </p>
                  <p>
                    The refund will be credited to the same account from which
                    the payment was made. For example, if the User used a credit
                    card, Clickntravel will make an appropriate charge reversal
                    to the same credit card; like-wise if the User used a debit
                    card, Clickntravel will credit the money to the same debit
                    card.
                  </p>
                  <p>
                    In the event of cancellation and refund of partially
                    utilized tickets, upfront discount and promo code discount
                    availed at the time of booking would be deducted from the
                    refund amount.
                  </p>
                </div>
              </ShowHide>
            </div>

            <div ref={tc7}>
              <ShowHide visible="true" icon="" title="Others">
                <div className="showHide-content">
                  <p>
                    Clickntravel shall not be liable or responsible for any
                    damage or loss, injury, accident, death, irregularity,
                    delay/change in schedule, cancellation without cause,
                    inaccurate information, deficiency of service/product, or
                    cancellation due to conditions not under it’s control.
                  </p>
                </div>
              </ShowHide>
            </div>
          </Col>
        </Row>
      </div>
      <Footer />
    </>
  );
}

export default FlightTermsAndConditions;
