import axios from "axios";

export const apiPostHelper = async (URL, PAYLOAD, HEADERS) => {
  try {
    const responseData = await axios.post(URL, PAYLOAD, HEADERS);
    return responseData;
  } catch (e) {
    if (!e.response) {
      // network error
      const obj = {
        data: {
          errors: [
            {
              errorMessage: "Error: Network Error",
            },
          ],
        },
      };
      return obj;
    }
    const obj = {
      data: {
        errors: [
          {
            errorMessage: "Something went wrong",
          },
        ],
      },
    };
    return obj;
  }
};
