import React, { useState, useContext } from 'react';
import { Context } from '../../App';
import {
  Col,
  Row,
  Typography,
  Select,
} from "antd";
import CancelHotel from "./CancelHotel";
import Utilities from '../utilities/Utilities';
import FormatCurrency from '../utilities/FormatCurrency';
const { Title } = Typography;
const { Option } = Select;

const contentStyle = {
  margin: 0,
  height: "178px",
  width: "242px",
  color: "#fff",
  lineHeight: "160px",
  textAlign: "center",
  background: "#364d79",
  borderRadius: "4px",
};

function MyTripsHtlItineraryPopup({ itiData, close, active }) {
  const [context, setContext] = useContext(Context);
  const [cancelType, setCancelType] = useState("");

  const handleCancelTypeChange = (value) => {
    setCancelType(value);
  };

  return (
    <>
    <div className='viewItineraryWrap'>
      <div className="commonFlex paddingRight">
        <div className='itineraryTitle'>{context.translation && context.translation.bokSumy}</div>
        {active === "Title1" &&
        <Select
          defaultValue={context.translation && context.translation.manageBok}
          onChange={handleCancelTypeChange}
          className="manageBooking"
        >
          <Option value="cancel">{context.translation && context.translation.canReq}</Option>
        </Select>
        }
      </div>
      
      <Row>
        <Col span={24}>
          <div className="hotelReviewDetails marginTop30">
            <div className="sliderWrapper">
              <span className="hotelTag">{itiData.hotel_name}</span>
              <div className="Carousel">
                <img src={itiData.hotel_image} style={contentStyle} />
              </div>
            </div>
            <div className="fullWidth">
              <div>
                <div>
                  <Title level={4} className="hotelName">
                    {itiData.hotel_name}
                  </Title>
                  <span className="stars">
                    {Utilities.getHotelStartRating(
                      +itiData.star_rating.split(".")[0]
                    ).map((star, idx) => (
                      <React.Fragment key={idx}>
                        <span className="hotelRating">
                          <i className="icon icon-star"></i>
                        </span>
                      </React.Fragment>
                    ))}
                  </span>
                </div>
                <Title level={5} className="hotelAddress">
                  {itiData.hotel_address}, {itiData.hotel_city},{" "}
                  {itiData.hotel_country}
                </Title>
              </div>
              <div className="onlyFlex">
                <div className="checkInBorderRight">
                  <span className="checkInOut">
                    {context.translation && context.translation.checkin}
                  </span>
                  <span className="dateTime">
                    {itiData.cin_date}, {itiData.cin_time}
                  </span>
                </div>
                <div>
                  <span className="checkInOut">
                    {context.translation && context.translation.checkOut}
                  </span>
                  <span className="dateTime">
                    {itiData.cout_date}, {itiData.cout_time}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>

      {cancelType === "" && (
        <div>
          <Title level={4} className='roomDetailTitle'>
            {context.translation && context.translation.roomDetails}
          </Title>
          {itiData &&
            itiData.rooms.length > 0 &&
            itiData.rooms.map((room, index) => (
              <React.Fragment key={index}>
              <div className="guestsWrapper">
                <div className='roomWrapr'> {context.translation && context.translation.room} {index + 1}: {room.room_name}</div> 
                <div className="travellersWrapper">
                  <span className="travellersTitle">
                    {context.translation && context.translation.guests}:
                  </span>
                  <span className="travellersDetailWrap">
                    {room.pax.map((pax, index) => (
                      <React.Fragment key={index}>
                        <span className="travellersDetail">
                          <span><i className="icon icon-tick"></i>
                          {pax.title} {pax.fname} {pax.lname} ({pax.type})</span>
                        </span>
                      </React.Fragment>
                    ))}
                  </span>
                </div>
              </div>
                <div className='tableScroll displayNone-640'>
                  <div className="travellerDetails tableWrap">
                    <Row className="tableHeader">
                      <Col span={3} className="tableValue">
                        {context.translation && context.translation.roomType}
                      </Col>
                      <Col span={3} className="tableValue">
                        {context.translation && context.translation.occupancy}
                      </Col>
                      <Col span={3} className="tableValue">
                        {context.translation && context.translation.boardType}
                      </Col>
                      <Col span={3} className="tableValue">
                        {/* {context.translation && context.translation.hotelRef} */}
                        {context.translation && context.translation.hotRef}#
                      </Col>
                      <Col span={3} className="tableValue">
                        {/* {context.translation && context.translation.supConfno} */}
                        {context.translation && context.translation.supConf}#
                      </Col>
                      <Col span={3} className="tableValue">
                        {context.translation && context.translation.specialReq}
                      </Col>
                      <Col span={3} className="tableValue">
                        {context.translation && context.translation.status}
                      </Col>
                      <Col span={3} className="tableValue">
                        {context.translation && context.translation.totPrice}
                      </Col>
                    </Row>

                    <Row className="tableData">
                      <Col span={3} className="tableValue">
                        {room.bed_type ? room.bed_type : "N/A"}
                      </Col>
                      <Col span={3} className="tableValue">
                        {room.adults}{" "}
                        {+room.childs.split(" ")[0] === 0
                          ? ""
                          : "," + room.childs}
                      </Col>
                      <Col span={3} className="tableValue">
                        {/* {!Array.isArray(room.board_basis) && room.board_basis.toUpperCase().split(" ").join("") === "ROOMONLY" ? "Room Only" : Array.isArray(room.board_basis) ? room.board_basis.join(", ") :"N/A"} */}
                        {!Array.isArray(room.board_basis) && "Room Only"}
                        {Array.isArray(room.board_basis) &&
                          room.board_basis.join(", ")}
                      </Col>
                      <Col span={3} className="tableValue wordBreak">
                        {room.hotel_ref ? room.hotel_ref : "N/A"}
                      </Col>
                      <Col span={3} className="tableValue wordBreak">
                        {room.supplier_ref ? room.supplier_ref : "N/A"}
                      </Col>
                      <Col span={3} className="tableValue">
                        {room.special_request ? room.special_request : "N/A "}
                      </Col>
                      <Col span={3} className={room.room_status.toUpperCase()==="CONFIRMED"?"tableValue active" : "tableValue primary"}>
                        {room.room_status}
                      </Col>
                      <Col span={3} className="tableValue wordBreak">
                        {itiData.usr_currency} <FormatCurrency value={room.total_price * (itiData.base_usr_exrate ? itiData.base_usr_exrate : 1)} />
                      </Col>
                    </Row>
                  </div>
                </div>
                <div className='smallScreenTable displayNoneLG'>
                <Row className="tableHeader">
                  <Col span={24} className="tableData">
                    <div className="tableValueWrap">
                      <div className="tableValue">
                        {context.translation && context.translation.roomType}
                      </div>
                      <div className="tableValue">
                        {room.bed_type ? room.bed_type : "N/A"}
                      </div>
                    </div>
                    <div className="tableValueWrap">
                      <div className="tableValue">
                        {context.translation && context.translation.occupancy}
                      </div>
                      <div className="tableValue">
                        {room.adults}{" "}
                        {+room.childs.split(" ")[0] === 0
                          ? ""
                          : "," + room.childs}
                      </div>
                    </div>
                    <div className="tableValueWrap">
                      <div className="tableValue">
                        {context.translation && context.translation.boardType}
                      </div>
                      <div className="tableValue">
                        {/* {!Array.isArray(room.board_basis) && room.board_basis.toUpperCase().split(" ").join("") === "ROOMONLY" ? "Room Only" : Array.isArray(room.board_basis) ? room.board_basis.join(", ") :"N/A"} */}
                        {!Array.isArray(room.board_basis) && "Room Only"}
                        {Array.isArray(room.board_basis) &&
                          room.board_basis.join(", ")}
                      </div>
                    </div>
                    <div className="tableValueWrap">
                      <div className="tableValue">
                        {/* {context.translation && context.translation.hotelRef} */}
                        {context.translation && context.translation.hotRef}#
                      </div>
                      <div className="tableValue wordBreak">
                        {room.hotel_ref ? room.hotel_ref : "N/A"}
                      </div>
                    </div>
                    <div className="tableValueWrap">
                      <div className="tableValue">
                        {/* {context.translation && context.translation.supConfno} */}
                        {context.translation && context.translation.supConf}#
                      </div>
                      <div className="tableValue wordBreak">
                        {room.supplier_ref ? room.supplier_ref : "N/A"}
                      </div>
                    </div>
                    <div className="tableValueWrap">
                      <div className="tableValue">
                        {context.translation && context.translation.specialReq}
                      </div>
                      <div className="tableValue">
                        {room.special_request ? room.special_request : "N/A "}
                      </div>
                    </div>
                    <div className="tableValueWrap">
                      <div className="tableValue">
                        {context.translation && context.translation.status}
                      </div>
                      <div className={room.room_status.toUpperCase()==="CONFIRMED"?"tableValue active" : "tableValue primary"}>
                        {room.room_status}
                      </div>
                    </div>
                    <div className="tableValueWrap">
                      <div className="tableValue">
                        {context.translation && context.translation.totPrice}
                      </div>
                      <div className="tableValue wordBreak">
                        {itiData.usr_currency} <FormatCurrency value={room.total_price * (itiData.base_usr_exrate ? itiData.base_usr_exrate : 1)} />
                      </div>
                    </div>
                  </Col>
                </Row>
                </div>
                <div className="horizontalDivider"></div>
                <div>
                  <div className='impWrap'>
                    <Title level={4} className='impTitle'>{context.translation && context.translation.canpolcy}</Title>
                    {room.cancellation_policy && room.cancellation_policy?.length!==0 &&
                      <>
                        {room.cancellation_policy.map((canp, index) => (
                          <React.Fragment key={index}>
                          <p className="impContent">{canp}</p>
                          </React.Fragment>
                        ))}
                      </>
                    }
                    {room.cancellation_policy?.length===0 &&
                      <>
                      <p className="impContent">{context.translation && context.translation.nonRefundable}</p>
                      </>
                    }
                  </div>
                  <div className='impWrap'>
                    <Title level={4} className='impTitle'>{context.translation && context.translation.impInf}</Title>
                    <>
                      {room.important_info && room.important_info !== undefined &&
                      <div className="toolTipCUstoom cnclInfo">
                        <p dangerouslySetInnerHTML={{__html: room.important_info.instructions}}  className="importantContent"></p>
                        <p dangerouslySetInnerHTML={{__html: room.important_info.specialInstructions}}  className="importantContent"></p>
                      </div>
                      }
                      {(room.important_info!==undefined && (room.important_info.instructions==="" || room.important_info.specialInstructions==="")) &&
                      <div className="toolTipCUstoom cnclInfo">
                        <p className="importantContent">N/A</p>
                      </div>
                      }
                    </>
                  </div>
                  {itiData.note !== "" && itiData.note !== undefined && (
                    <div className='impWrap'>
                      <Title level={4} className='impTitle'>{context.translation && context.translation.note}</Title>
                      <p className='impContent'>{itiData.note}</p>
                    </div>
                  )}
                </div>
                <div className="horizontalDivider"></div>
              </React.Fragment>
            ))}
        </div>
      )}

      {cancelType === "" && (
        <>
        <Title level={3} className="heading">
              {context.translation && context.translation.paymentDetails}
            </Title>
        <div className='tableScroll displayNone-640'>
          <div className="travellerDetails tableWrap">
            <Row className="tableHeader" justify="space-between">
              <Col span={6} className="tableValue">
                {context.translation && context.translation.modePayment}
              </Col>
              <Col span={6} className="tableValue">
                {context.translation && context.translation.cardType}
              </Col>
              <Col span={6} className="tableValue">
                {context.translation && context.translation.cardDetails}
              </Col>
              <Col span={6} md={3} className="tableValue">
                {context.translation && context.translation.amountPaid}
              </Col>
            </Row>
            <Row className="tableData" justify="space-between">
              <Col span={6} className="tableValue">
                {itiData.payment_mode} Card
              </Col>
              <Col span={6} className="tableValue">
                {itiData.card_type} Card
              </Col>
              <Col span={6} className="tableValue">
              XXXXXXXXXXXX{itiData.card_details}
              </Col>
              <Col span={6} md={3} className="tableValue">
                {itiData.usr_currency} <FormatCurrency value={itiData.paid_amount} />
              </Col>
            </Row>
          </div>
        </div>
        <div className='smallScreenTable displayNoneLG'>
          <Row className="tableHeader">
            <Col span={24} className="tableData">
              <div className="tableValueWrap">
                <div className="tableValue">
                  {context.translation && context.translation.modePayment}
                </div>
                <div className="tableValue">
                  {itiData.payment_mode} Card
                </div>
              </div>
              <div className="tableValueWrap">
                <div className="tableValue">
                  {context.translation && context.translation.cardType}
                </div>
                <div className="tableValue">
                  {itiData.card_type} Card
                </div>
              </div>
              <div className="tableValueWrap">
                <div className="tableValue">
                  {context.translation && context.translation.cardDetails}
                </div>
                <div className="tableValue">
                XXXXXXXXXXXX{itiData.card_details}
                </div>
              </div>
              <div className="tableValueWrap">
                <div className="tableValue">
                  {context.translation && context.translation.amountPaid}
                </div>
                <div className="tableValue">
                  {itiData.usr_currency} <FormatCurrency value={itiData.paid_amount} />
                </div>
              </div>
          </Col>
          </Row>
        </div>
        </>
      )}

      
      {cancelType === "cancel" && (
        <div>
          <Title level={4} className='roomDetailTitle roomHotelDetailTitle'>
            {context.translation && context.translation.roomDetails}
          </Title>
          {/* <Title level={4}>Room Details</Title> */}
          <CancelHotel close={close} cancelData={itiData} />
        </div>
      )}
    </div>
    </>
  );
}

export default MyTripsHtlItineraryPopup;
