import React, { useContext, useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Context,ExchangeRates } from "../App";
import FlightConfirmation from "../components/bookingflow/flights/confirmation/FlightConfirmation";
import FlightResults from "../components/bookingflow/flights/results/FlightResults";
import FlightReviewPage from "../components/bookingflow/flights/review/FlightReviewPage";
import HotelDetails from "../components/bookingflow/hotels/hoteldetails/HotelDetails";
import HotelResults from "../components/bookingflow/hotels/results/HotelResults";
import HotelReview from "../components/bookingflow/hotels/review/HotelReview";
import PageNotFound from "../components/common/PageNotFound";
import Home from "../components/homepage/Home";
import AddPreferences from "../components/myAccount/AddPreferences";
import AddTravellers from "../components/myAccount/AddTravellers";
import ChangePassword from "../components/myAccount/ChangePassword";
import MyTrips from "../components/myAccount/MyTrips";
import UserProfile from "../components/myAccount/UserProfile";
import Payment from "../components/payment/Payment";
import RedirectByUrl from "../components/common/RedirectByUrl";
import HotelConfirmation from "../components/bookingflow/hotels/confirmation/HotelConfirmation";
import About from "../components/common/About";
import Contact from "../components/common/Contact";
import TermsAndConditions from "../components/common/TermsAndConditions";
import PrivacyPolicy from "../components/common/PrivacyPolicy";
import UserAgreement from "../components/common/UserAgreement";
import UserAgreementAr from "../components/common/UserAgreementAr";
import TermsAndConAr from "../components/common/TermsAndConditionsAr";
import PrivacyPolicyAr from "../components/common/PrivacyPolicyAr";
import TermsOfAgreement from "../components/common/TermsOfAgreement";
import FlightTermsAndConditions from "../components/common/FlightTermsAndConditions";
import FlightTermsAndConditionsAr from "../components/common/FlightTermsAndConAr";
import HotelTermsAndConditions from "../components/common/HotelTermsAndConditions";
import HotelTermsAndConditionsAr from "../components/common/HotelTermsAndConAr";
import SupplierTermsAndConditions from "../components/common/SupplierTermsAndConditions";
import CntCommonService from "../services/common/CntCommonService";
const EXRATES_TIME = process.env.REACT_APP_SCHEDULAR_EXRATES;

function Router() {
  const [context, setContext] = useContext(Context);
  const [executionTime, setExecutionTime] = useState(EXRATES_TIME); // Runs every 5 hours
  // for testing purpose changed schedular time to 15 minutes
  // const [executionTime, setExecutionTime] = useState(15); // Runs every 15 minutes
  const [nextExecutionTime, setNextExecutionTime] = useState(null);
  let timeoutId;

  useEffect(() => {
    if(localStorage.getItem("CNT_EX_RATES")===null){
      getExratesContext();
    }
    const storedNextExecutionTime = context.schedular !== "" && context.schedular !== undefined ? 
    context.schedular : localStorage.getItem('nextExecutionTime');
    if (storedNextExecutionTime) {
      setNextExecutionTime(new Date(parseInt(storedNextExecutionTime)));
      scheduleNextExecution(parseInt(storedNextExecutionTime) - Date.now());
    } else {
      getExratesContext();
      scheduleNextExecution();
    }

    // Clean up function
    return () => clearTimeout(timeoutId);
  }, []);

  const scheduleNextExecution = (timeUntilNextExecution = null) => {
    // Calculate the time until the next execution
    const nextExecutionTime = getNextExecutionTime(executionTime);
    const timeUntilNextExecutionAdjusted = timeUntilNextExecution ? timeUntilNextExecution : nextExecutionTime - Date.now();

    // Schedule the API call
    timeoutId = setTimeout(() => {
      getExratesContext("fivehours");
      scheduleNextExecution(); // Reschedule the next execution
    }, timeUntilNextExecutionAdjusted);
    // Update state and local storage to store the next execution time
    setNextExecutionTime(new Date(Date.now() + timeUntilNextExecutionAdjusted));
    localStorage.setItem('nextExecutionTime', Date.now() + timeUntilNextExecutionAdjusted);
    setContext({...context, schedular: Date.now() + timeUntilNextExecutionAdjusted});
  }

  const getNextExecutionTime = (executionTime) => {
    try {
      const hours = parseInt(executionTime);

      // Calculate the next execution time based on the current time and hour component
      const now = new Date();
      let nextExecutionTime = new Date(now);
      nextExecutionTime.setHours(now.getHours() + hours);

      // // for testing purpose use minutes
      // const minutes = parseInt(executionTime);

      // // Calculate the next execution time based on the current time and hour component
      // const now = new Date();
      // let nextExecutionTime = new Date(now);
      // nextExecutionTime.setMinutes(now.getMinutes() + minutes);

      return nextExecutionTime.getTime();
    } catch (error) {
      console.error('Error parsing cron expression:', error);
      return 0;
    }
  }

  useEffect(() => {
    var userData = localStorage.getItem("SIGNINRESPONSE");
    var exRates = localStorage.getItem("CNT_EX_RATES");
    var exrt_schedular = localStorage.getItem('nextExecutionTime');
    var usr_cur = sessionStorage.getItem('SEL_CUR');
    setContext({ 
      user_resp: (context.user_resp === "" && userData && userData !== undefined) ? JSON.parse(userData) : context.user_resp,
      ex_rates: (context.ex_rates === "" && exRates && exRates !== undefined) ? JSON.parse(exRates) : context.ex_rates,
      schedular: (context.schedular === "" && exrt_schedular && exrt_schedular !== undefined) ? exrt_schedular : context.schedular,
      userCur: (context.userCur === "" && usr_cur && usr_cur !== undefined) ? usr_cur : context.userCur});
  }, []);

  // API TO LOAD ALL THE EX RATES
  const getExratesContext=(schedule)=>{
    try {
    if(schedule === "fivehours") {
      localStorage.removeItem("CNT_EX_RATES")
    }
    if(localStorage.getItem("CNT_EX_RATES")===null){
     CntCommonService.getExchangeRates().then((response) => {
      if(response.data!==undefined && response.data.res!==null && response.data.res!==undefined){
        var userData = localStorage.getItem("SIGNINRESPONSE");
        var usr_cur = sessionStorage.getItem('SEL_CUR');
        setContext({ ...context, ex_rates: response.data.res, 
          userCur: context.userCur!==undefined && context.userCur!==""?context.userCur:usr_cur,
          user_resp: (context.user_resp!==undefined && context.user_resp!=="") ? context.user_resp : (userData && userData!==null && userData!==undefined) ? JSON.parse(userData) : ""});
        localStorage.setItem("CNT_EX_RATES",JSON.stringify(response.data.res));
      }
      }).catch(function (error) {
        console.error("Error in getExratesContext" + error);      
      });
    }
    } catch (err) {
    console.error("Error in getExratesContext" + err);
  }
  }

  return (
    <BrowserRouter forceRefresh={true}>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/en" element={<Home />} />
        <Route path="/ar" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/terms-conditions" element={<TermsAndConditions />} />
        <Route path="/terms-conditions-ar" element={<TermsAndConAr />} />
        <Route path="/flight/terms-conditions" element={<FlightTermsAndConditions />} />
        <Route path="/flight/terms-conditions-ar" element={<FlightTermsAndConditionsAr />} />
        <Route path="/hotel/terms-conditions" element={<HotelTermsAndConditions />} />
        <Route path="/hotel/terms-conditions-ar" element={<HotelTermsAndConditionsAr />} />
        <Route path="/supplier/terms-conditions" element={<SupplierTermsAndConditions />} />
        <Route path="/user-agreement-ar" element={<UserAgreementAr />} />
        <Route path="/user-agreement" element={<UserAgreement />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/privacy-policy-ar" element={<PrivacyPolicyAr />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/cnt" element={<RedirectByUrl />} />
        <Route path="/flight/results" element={<FlightResults />} />
        <Route path="/flight/review" element={<FlightReviewPage />} />
        <Route path="/availability" element={<FlightReviewPage />} />
        <Route path="/payment" element={<Payment />} />
        <Route path="/flight/confirmation" element={<FlightConfirmation />} />
        <Route path="/my-account/user-profile" element={<UserProfile />} />
        <Route
          path="/my-account/add-preferences"
          element={<AddPreferences />}
        />
        <Route path="/my-account/my-trips" element={<MyTrips />} />
        <Route path="/my-account/add-travellers" element={<AddTravellers />} />
        <Route
          path="/my-account/change-password"
          element={<ChangePassword />}
        />
        <Route
          path="/terms-of-agreement"
          element={<TermsOfAgreement />}
        />
        <Route path="/hotel/results" element={<HotelResults />} />
        <Route path="/hotel/review" element={<HotelReview />} />
        <Route path="/hotel/details" element={<HotelDetails/>} />
        <Route path="/hotel/payment" element={<Payment />} />
        <Route path="*" element={<PageNotFound />} />
        <Route path="/hotel/confirmation" element={<HotelConfirmation />} />
      </Routes>
    </BrowserRouter>
  );
}

export default Router;
