import React, { useState, useEffect } from "react";
import { Select } from "antd";
import { isArray } from "lodash";
const { Option } = Select;

/**
 * @description:This function will creaet all the Add AddMeal details
 * @author: Ubedullah
 * @param {*}
 * @function AddMeal details
 * @date : 09-aug-2023
 */

function AddMeal(props) {
  const [mealDefault, setMealDefault] = useState([]);
  const [mealsLis, setMealsList] = useState(props.mealList);

  useEffect(() => {
    if (mealsLis !== undefined) {
      if(mealsLis?.length === 1) {
        if (mealsLis[0]?.includes("Select")) {
          setMealDefault([]);
        } else {
          if (mealsLis[0]?.length !== 0 && mealsLis[0][0] !== undefined) {
            let list = converLabelPrice(mealsLis[0]);
            setMealDefault({
              value: list[0].value,
              label: list[0].label,
            });
            // props.sendMealprice(list[0][0]);
            props.sendMealprice(isArray(list[0]) ? list[0][0] : list[0]);
          }
        }
      } else {
        if (mealsLis!==undefined && mealsLis[0] !== undefined && mealsLis?.includes("Select")) {
          setMealDefault([]);
        } else {
          if (mealsLis!==undefined && mealsLis?.length !== 0 && mealsLis[0] !== undefined) {
           let list = converLabelPrice(mealsLis);
            setMealDefault({
              value: list[0].value,
              label: list[0].label,
            });
            props.sendMealprice(list[0]);
          }
        }
      }
    }
  }, []);

  const converLabelPrice=(mealsLis)=>{
    for(let val of mealsLis){
      if(val.price!==0 && val.price > 0){
        if(val.label?.includes(props.cur)) {
          val.label=val.label;
        } else {
          val.label=val.label +"-"+(val.price * props.base_sup_exrate).toFixed(2)+" "+props.cur;
        }
      }
    }
    return mealsLis
  }

  const setMealPrice = (value) => {
    if(mealsLis!==undefined && mealsLis?.length === 1) { 
      const obj = mealsLis[0]?.find((option) => option.label === value);
      // const obj = mealsLis.filter((val) => val.value === value);
      props.sendMealprice(obj);
      if (mealsLis!==undefined && !mealsLis[0]?.includes("Select")) {
        setMealDefault(value);
      }
    } else {
      const obj = mealsLis?.find((option) => option.label === value);
      // const obj = mealsLis.filter((val) => val.value === value);
      props.sendMealprice(obj);
      if (mealsLis!==undefined && !mealsLis?.includes("Select")) {
        setMealDefault(value);
      }
    }

    
  };

  return (
    <Select
      // options={mealsLis}
      // isDisabled={props.disable}
      value={mealDefault}
      onChange={(value) => setMealPrice(value)}
      className="selectBoxShadow"
    >
      {mealsLis!==undefined && mealsLis?.length === 1 ? (
        <>
      {mealsLis!==undefined && mealsLis[0]?.length > 0 && mealsLis[0]?.map((option) => {
            return (
              <Option key={option.label} value={option.label}>
                {option.label}
              </Option>
            )
          }
      )}
      </>
      ) : (<>
        {mealsLis!==undefined && mealsLis?.length > 0 && mealsLis?.map((option) => {
          return (
            <Option key={option.label} value={option.label}>
              {option.label}
            </Option>
          )
        })}
      
      </>)}
    </Select>
  );
}

export default AddMeal;
