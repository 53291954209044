import React from 'react'
import { useState, useEffect,useContext } from 'react' 
import { Context } from '../../../../App';
import Visa from "../../../../assets/img/visa.png";
import MasterCard from "../../../../assets/img/master-card.png";

function HotelPolicies(props) {
  const [context, setContext] = useContext(Context)
  const [state, setState] = useState({})
  const [payments , seyPayments] = useState()
  const [cash, setCash] = useState("")
  const [debitcardNot, setDebitCardNot] = useState("")
  const [debitcard, setDebitCard] = useState("")
  const [masterCard, setMasterCard] = useState("")
  const [dinersClub, setDinersClub] = useState("")
  const [jsb, setJsb] = useState("")
  const [visa, setVisa] = useState("")
  const [american, setAmerican] = useState("")
  const [discover, setDiscover] = useState("")


  useEffect(() => {
    if(props.payments!==undefined && props.payments.types!==null){
      seyPayments(props.payments.types)
      if(props.payments.types!==undefined){
        let cash=props.payments.types[407]
        if(cash!==undefined){
          setCash(cash.name)
        }
        let cardnot=props.payments.types[1073744166]
        if(cardnot!==undefined && cardnot.name!==undefined){
          setDebitCardNot(cardnot.name)
        }
        let acpcard=props.payments.types[1073742461]
        if(acpcard!==undefined && acpcard.name!==undefined){
          setDebitCard(acpcard.name)
        }
        let american=props.payments.types[171]
        if(american!==undefined && american.name!==undefined){
          setAmerican(american.name)
        }
        let diners=props.payments.types[179]
        if(diners!==undefined && diners.name!==undefined){
          setDinersClub(diners.name)
        }
        let jcb=props.payments.types[181]
        if(jcb!==undefined && jcb.name!==undefined){
          setJsb(jcb.name)
        }
        let disc=props.payments.types[184]
        if(disc!==undefined && disc.name!==undefined){
          setMasterCard(disc.name)
        }
        let visa=props.payments.types[189]
        if(visa!==undefined && visa.name!==undefined){
          setVisa(visa.name)
        }
      }
    }
  }, [])
  return (
    <div className='hotelPoliciesWrapper hrsWrapper'>
      <div className='checkInCheckOut'>
        <div className='checkIn'>
        {(sessionStorage.getItem("SEL_LAG") === "English"||sessionStorage.getItem("SEL_LAG") === null) && (
          <>          
          <span className='left'>{context.translation && context.translation.checkin} : </span>
          <span className='right'>{props.cin.beginTime}</span>
        </>
        )}
        {sessionStorage.getItem("SEL_LAG") === "Arabic" && (
          <>
          <span className='left'>{context.translation && context.translation.checkin} : </span>
          <span className='right' style={{float:"right"}}>{props.cin.beginTime}</span>
        </>
        )}
        </div>
        <div className='checkOut'>
        {(sessionStorage.getItem("SEL_LAG") === "English"||sessionStorage.getItem("SEL_LAG") === null) && (
          <>          
          <span className='left'>{context.translation && context.translation.checkOut} :</span>
          <span className='right'>{props.cout.time}</span>
        </>
        )}
        {sessionStorage.getItem("SEL_LAG") === "Arabic" && (
          <>
          <span className='left'>{context.translation && context.translation.checkOut} :</span>
          <span className='right' style={{float:"right"}}>{props.cout.time}</span>
        </>
        )}
        </div>
      </div>
      <div className='specialCheckIn'>{context.translation && context.translation.specialCheckin}</div>
      {props.canpolicy.instructions!==undefined && props.canpolicy.instructions!==null && props.canpolicy.specialInstructions!==undefined ? (
                      <>
                      <p dangerouslySetInnerHTML={{ __html: props.canpolicy.instructions }} className='specialCheckInInstruction'>{ }</p>
                      <p dangerouslySetInnerHTML={{ __html: props.canpolicy.specialInstructions }} className='specialCheckInInstruction'>{ }</p>
                      {props.canpolicy.endTime !== undefined && props.canpolicy.endTime !== "" && (
                        <p className='specialCheckInInstruction'>End time: {props.canpolicy.endTime }</p>
                      )}
                      {props.canpolicy.minAge !== undefined && props.canpolicy.minAge !== "" && (
                        <p className='specialCheckInInstruction'>Minimun age: {props.canpolicy.minAge }</p>
                      )}
                      </>
                    ) : (
                      <p>{context.translation && context.translation.notAvail}</p>
                    )
                    }
      <div className='dashedDivider'></div>
      <div className='policyTitle'>{context.translation && context.translation.policies}</div>
      {props.policy.knowBeforeYouGo!==undefined && props.policy.knowBeforeYouGo!==null ? (
        <div  dangerouslySetInnerHTML={{ __html: props.policy.knowBeforeYouGo }} className='policiesList'/>
                    ) : (
                      <p>{context.translation && context.translation.notAvail}</p>
                    )
                    }
                    {/* //fees */}
      <div className='dashedDivider'></div>
        <div className='policyTitle'>{"Fees"}</div>
        {props.fees!==undefined && props.fees!==null ? (
          <>

            {props.fees.mandatory!==undefined && props.fees.mandatory!==null && ( 
              <>
              <div className='policyTitle' style={{marginBottom:"10px", marginTop:"20px"}}>{"Mandatory"}</div>
              <div dangerouslySetInnerHTML={{ __html: props.fees.mandatory }} className='policiesList specialCheckInInstruction'/>
              </>
            )}
            {props.fees.optional!==undefined && props.fees.optional!==null && ( 
              <>
              <div className='policyTitle' style={{marginBottom:"10px", marginTop:"20px"}}>{"Optional"}</div>
              <div dangerouslySetInnerHTML={{ __html: props.fees.optional }} className='policiesList specialCheckInInstruction'/>
              </>
            )}
            </>

                      ) : (
                        <p>{context.translation && context.translation.notAvail}</p>
                      )
                      }
      {/* <ul className='policiesList'>
        <li><i className="icon icon-tick1"></i> Reservations are required for massage services and spa treatments. Reservations can be made by contacting the hotel prior to arrival, using the contact information on the booking confirmation.</li>
      </ul> */}
      {props.flow === undefined && (
        <>  
          <div className='dashedDivider'></div>
            {cash!=="" && cash!==undefined &&
            <strong className="cardsAcceptedTitle">{context.translation && context.translation.accepectCash}</strong>
            }
            {debitcardNot!=="" && debitcardNot!==undefined &&
              <strong className="cardsAcceptedTitle"> {context.translation && context.translation.debitcardsNotaccp} </strong>
            }
            {debitcard!=="" && debitcard!==undefined &&
              <>
              <strong className="cardsAcceptedTitle"> {context.translation && context.translation.debitcardsAccp}</strong>
            </>
            }
            {(masterCard!=="" || dinersClub!=="" || jsb!=="" || visa!=="" || american!=="") &&
            <>
            <strong className="cardsAcceptedTitle"> {context.translation && context.translation.cardaccept}</strong>
            <div className='cardImage'>
              <img src={Visa} alt='visa-image' className='visa'/>
              <img src={MasterCard} alt='masterCard-image' className='masterCard'/>
            </div>
            </>
            }
        </>
      )}
    </div>
  )
}

export default HotelPolicies