/**
 * @ Author: Ubedullah Mohammed
 * @ Create Time: 2023-06-14 02:56:45
 * @ Description: This is an service for My Account api
 */

import axios from "axios";
import api from "../../components/utilities/api"
// axios.defaults.withCredentials = true;

const API_URL = process.env.REACT_APP_B2C_ENGINE_URL;
const API_URL_B2C_ENGINE = process.env.REACT_APP_B2C_HOTEL_COMMON

// const API_URL_B2B = process.env.REACT_APP_B2B_URL;

const API_URL_PREDICTIVE = process.env.REACT_APP_PREDICTIVE_URL;

class MyAccountService {
  async userLogin(userData,token) {
    const headers = {
      headers: {
          'Content-Type': 'application/json',
          'auth-token': token
        }
     };
    return await api.post(`${API_URL}/cnt/user/login`, userData,headers);
  }

  async userSignup(userData,token) {
    const headers = {
      headers: {
          'Content-Type': 'application/json',
          'auth-token': token
        }
     };
    return await api.post(`${API_URL}/cnt/user/b2cregister`, userData,headers);
  }

  async verifyOTP(userData,token) {
    const headers = {
      headers: {
          'Content-Type': 'application/json',
          'auth-token': token
        }
     };
    return await api.post(`${API_URL}/cnt/user/b2cregisterOTP`, userData,headers);
  }

  async resentOtp(userData,token) {
    const headers = {
      headers: {
          'Content-Type': 'application/json',
          'auth-token': token
        }
     };
    return await api.post(`${API_URL}/cnt/user/b2cregisterOTP`, userData,headers);
  }

  async forgotPassword(data,token) {
    const headers = {
      headers: {
          'Content-Type': 'application/json',
          'auth-token': token
        }
     };
    return await api.post(`${API_URL}/cnt/user/b2cforgotPwd`, data,headers);
  }

  async changePassword(data,token) {
    const headers = {
      headers: {
          'Content-Type': 'application/json',
          'auth-token': token
        }
     };
    return await api.post(`${API_URL}/cnt/user/b2cchange-password`, data,headers);
  }

  async getProfile(data,token) {
    const headers = {
      headers: {
          'Content-Type': 'application/json',
          'auth-token': token
        }
     };
    return await api.post(`${API_URL}/cnt/user/fetchprofile`, data,headers);
  }

  async getCountryList() {
    return await api.get(`${API_URL}/cnt/att/common/country`);
  }

  async updateProfile(data,token) {
    const headers = {
      headers: {
          'Content-Type': 'application/json',
          'auth-token': token
        }
     };
    return await api.post(`${API_URL}/cnt/user/b2cprofileupdate`, data,headers);
  }

  async deleteFrequentFlyer(id) {
    return await api.delete(`${API_URL}/cnt/user/b2cdeleteffp/${id}`);
  }

  async getTravellers(data,token) {
    const headers = {
      headers: {
          'Content-Type': 'application/json',
          'auth-token': token
        }
     };
    return await api.post(`${API_URL}/cnt/user/fetchtraveller`, data,headers);
  }

  async saveTravellers(data,token) {
    const headers = {
      headers: {
          'Content-Type': 'application/json',
          'auth-token': token
        }
     };
    return await api.post(`${API_URL}/cnt/user/b2caddtraveller`, data,headers);
  }

  async updateTravellers(data,token) {
    const headers = {
      headers: {
          'Content-Type': 'application/json',
          'auth-token': token
        }
     };
    return await api.post(`${API_URL}/cnt/user/b2caddtraveller`, data,headers);
  }

  async deleteTravellers(id) {
    return await api.delete(`${API_URL}/cnt/user/b2cdeletetraveller/${id}`);
  }

  // async delAirline(id) {
  //   return await axios.delete(`${API_URL_B2B}/user/deleteairline/${id}`);
  // }

  // async deleteRoutes(id) {
  //   return await axios.delete(`${API_URL_B2B}/att/revenue/deleteRoutes/${id}`);
  // }

  // async getCountryList() {
  //   return await axios.get(`${API_URL_B2B}/att/common/country`);
  // }

  async savePreferences(data,token) {
    const headers = {
      headers: {
          'Content-Type': 'application/json',
          'auth-token': token
        }
     };
    return await api.post(`${API_URL}/cnt/user/addpreferences`, data,headers);
  }

  async getPreferences(data,token) {
    const headers = {
      headers: {
          'Content-Type': 'application/json',
          'auth-token': token
        }
     };
    return await api.post(`${API_URL}/cnt/user/fetchpreferences`, data,headers);
  }

  async delPrefairlines(id) {
    return await api.delete(`${API_URL}/cnt/user/delete-prefairline/${id}`);
  }

  async delPrefHtls(id) {
    return await api.delete(`${API_URL}/cnt/user/delete-prefhotel/${id}`);
  }

  async delFreqVisitedCity(id) {
    return await api.delete(`${API_URL}/cnt/user/delete-freqcity/${id}`);
  }

  async delPrefDest(id) {
    return await api.delete(`${API_URL}/cnt/user/delete-prefdest/${id}`);
  }

  async getMytrips(data,token) {
    const headers = {
      headers: {
          'Content-Type': 'application/json',
          'auth-token': token
        }
     };
    return await api.post(`${API_URL}/cnt/user/get-mytrips`, data,headers);
  }

  async getFlightItinerary(data,token) {
    const headers = {
      headers: {
          'Content-Type': 'application/json',
          'auth-token': token
        }
     };
    return await api.post(`${API_URL}/cnt/user/get-itineraries`, data,headers);
  }

  async getHotelItinerary(data,token) {
    const headers = {
      headers: {
          'Content-Type': 'application/json',
          'auth-token': token
        }
     };
    return await api.post(`${API_URL}/cnt/user/get_htl_itinerary`, data,headers);
  }

  async postflowOperations(data,token) {
    const headers = {
      headers: {
          'Content-Type': 'application/json',
          'auth-token': token
        }
     };
    return await api.post(`${API_URL}/cnt/booking/postflow-operations`, data,headers);
  }

  async cancelRoom(data,token) {
    const headers = {
      headers: {
          'Content-Type': 'application/json',
          'auth-token': token
        }
     };
    return await api.post(`${API_URL_B2C_ENGINE}/hotelpostbook/refund_req`, data,headers);
  }

  async retreieveSearch(data) {
    return await api.get(
      `${API_URL_PREDICTIVE}/search/` +
        // data +
        data.searchCriteria +
        "?skip=0&limitCity=10&limitProperty=50"
    );
  }

  async retriveRecentSearch(id) {
    return await api.get(`${API_URL}/cnt/common/fetchPredictive/${id}`);
  }

  async saveGmailUser(data,token) {
    const headers = {
      headers: {
          'Content-Type': 'application/json',
          'auth-token': token
        }
     };
    return await api.post(`${API_URL}/cnt/user/gauthlogin`, data,headers);
  }
  async saveFBUser(data) {
    return await api.post(`${API_URL}/cnt/user/fblogin`, data);
  }
}

export default new MyAccountService();
