/**
 * @ Author: Srikanth Bandaru
 * @ Create Time: 2023-06-15 09:47:01
 * @ Description: The common component for the Flight Multicity search panel
 */

import { Button, Col, Row } from "antd";
import React, { useContext } from "react";
import DateContainer from "./DateContainer";
import { Context } from "../../../../App";

function MultiCityContainer(props) {
  const [context, setContext] = useContext(Context)
  const current = new Date();

  const sourceClassName = (type) => {
    return "s-field dest-from " + type;
  };

  const destClassName = (type) => {
    return "s-field dest-to " + type;
  };

  const dateClassName = (type) => {
    return "multicityDate " + type;
  };

  return (
    <Row className="search-row">
      {props.enable && (
        <>
          <Col className={sourceClassName(props.srckey)}>
            <label>
              {/* From */}    
              <i className="icon icon-Pin"></i>          
              {context.translation && context.translation.from} :
            </label>

            <input
              className="effect-9 destFromTo"
              type="text"
              // placeholder="City or Airport"
              placeholder={context.translation && context.translation.fltSrhPlaceholder}
              value={props.srcval}
              title={props.srcval}
              onChange={(e) => props.srconc(e, props.srckey)}
              onFocus={(e) => props.srcDestFocus()}
            />
            <span className="focus-border">
              <i></i>
            </span>
            {props.srcenbl && (
              <div className="airportNamecode-box-wrap">
                <ul>
                  {!props.srcPreLoading &&
                    !props.srcPreLoaded &&
                    props.srcval.length > 2 && (
                      <li className="no-match-found">
                        <span>
                          <i className="icon icon-Info"></i> 
                          {/* Please enter a valid departure city or airport. */}
                          {context.translation && context.translation.validInputFrom}
                        </span>
                      </li>
                    )}
                  {props.ow !== undefined &&
                    props.ow.length > 0 &&
                    props.ow.map((result) => (
                      <li
                        key={result.value}
                        onClick={(e) => props.srcons(e, result, props.srckey)}
                        className="airportNamecode-box"
                      >
                          <div className="airportNamecode">
                            <span>{result.city}</span>
                            <span>{result.airport}</span>
                          </div>
                          <span> {result.label}</span>
                      </li>
                    ))}
                </ul>
              </div>
            )}
            {props.srcclr && (
              // <Button
              //   className="predictiveClear ezyIcon icon-close"
              //   onClick={(e) => props.clrsrc(e, props.srckey)}
              // />
              <Button onClick={(e) => props.clrsrc(e, props.srckey)}>
                <i className="icon icon-cancel clearField"></i>
              </Button>
            )}
          </Col>
          <Col className={destClassName(props.destkey)}>
            <label>
              {/* To */}
              <i className="icon icon-Pin"></i>
              {context.translation && context.translation.to} :
            </label>

            <input
              className="effect-9 destFromTo"
              type="text"
              // placeholder="City or Airport"
              placeholder={context.translation && context.translation.fltSrhPlaceholder}
              value={props.destval}
              title={props.destval}
              onChange={(e) => props.destonc(e, props.destkey)}
              onFocus={(e) => props.srcDestFocus()}
            />
            <span className="focus-border">
              <i></i>
            </span>
            {props.destenbl && (
              <div className="airportNamecode-box-wrap">
                <ul>
                  {!props.destPreLoading &&
                    !props.destPreLoaded &&
                    props.destval.length > 2 && (
                      <li className="no-match-found">
                        <span>
                          <i className="icon icon-Info"></i> 
                          {/* Please enter a valid arrival city or airport. */}
                          {context.translation && context.translation.validInputTo}
                        </span>
                      </li>
                    )}
                  {props.rt !== undefined &&
                    props.rt.length > 0 &&
                    props.rt.map((result) => (
                      <li
                        key={result.value}
                        onClick={(e) => props.destons(e, result, props.destkey)}
                        className="airportNamecode-box"
                      >
                        {" "}
                          <div className="airportNamecode">
                            <span>{result.city}</span>
                            <span>{result.airport}</span>
                          </div>
                          <span> {result.label}</span>
                      </li>
                    ))}
                </ul>
              </div>
            )}

            {props.dstclr && (
              // <Button
              //   className="predictiveClear ezyIcon icon-close"
              //   onClick={(e) => props.clrdest(e, props.destkey)}
              // />
              <Button onClick={(e) => props.clrdest(e, props.destkey)}>
                <i className="icon icon-cancel clearField"></i>
              </Button>
            )}
          </Col>
          <Col className={(context.selectLang === "en" || context.selectLang === undefined) ? "dpicker" : "dpicker dpickerArabic"}>
            <DateContainer
              className={dateClassName(props.srckey)}
              placeholders={context.selectLang==="en"||context.selectLang=== undefined ?["DD/MM/YY"]:["يوم/شهر/سنة"]}
              open={props.open}
              focus={(e) => props.focus(e, props.destkey)}
              onchange={(e) => props.dtonc(e, props.destkey)}
              date={props.mindate}
              popupClassName="multiDatePicker"
              tt={3}
              selDate={props.selDate}
            />
          </Col>

          {props.delete && (
            <Col className="clearFlights">
              {" "}
              {/* <Button onClick={props.delsec}><i className="icon icon-cancel"></i></Button> */}
              <i className="icon icon-cancel" onClick={props.delsec}></i>
              <Button type="button" className="deleteFlights" onClick={props.delsec}>- Delete Flights</Button>
            </Col>
          )}
        </>
      )}
    </Row>
  );
}

export default MultiCityContainer;
