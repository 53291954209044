import React, { useEffect } from "react";
import { Row, Button, Col, Tooltip } from "antd";
import { useState,useContext } from "react";
import { Context } from "../../../../App";
import Utilities from "../../../utilities/Utilities";
import FormatCurrency from "../../../utilities/FormatCurrency";
import HotelBrandIcon from "../../../../assets/gif/Forever/White/H_WHITE_FOREVER.gif";

function HotelRoomSelection(props) {
  const [context, setContext] = useContext(Context)
  const { selectedRooms, onflyMkp, noofNights, finalSelRooms, base_usr_exrate } = props;
  const [roomeListshow, setRoomeListshow] = useState(false);
  const [cur, setCur] = useState("");

  useEffect(() => {
    setCur(context.userCur!==undefined && context.userCur!==""?context.userCur:sessionStorage.getItem("SEL_CUR"));
  }, [sessionStorage.getItem("SEL_CUR") || context.userCur])

  const calculatePrice = () => {
    let price = 0;
    let onfly = 0;
    let roomList = selectedRooms.filter((ob) => ob.rst);
    try {
      if (roomList !== undefined && roomList.length !== 0) {
        onfly = onflyMkp * roomList.length;
        for (let val of roomList) {
          if (price === 0) {
            price=Number(val.rooms.rmpr[0].pr[0].tf) + Number(val.rooms.rmpr[0].servicefee.length > 0?val.rooms.rmpr[0].servicefee[0].sf:0);
          } else {
            price=Number(price) + Number(val.rooms.rmpr[0].pr[0].tf) + Number(val.rooms.rmpr[0].servicefee.length > 0?val.rooms.rmpr[0].servicefee[0].sf:0);
          }
        }
      }
    } catch (error) {
      console.error("Error inside calculate price", error);
    }
    return Number(price) * base_usr_exrate;
  };

  const enableBook = () => {
    let enable = true;
    for (let val of selectedRooms) {
      if (!val.rst) {
        enable = false;
      }
    }
    return enable;
  };

  return (
    <>
      <div className="yourSelectionWrap">
        <div className="yourSelectionTitle">
          <div><i className="icon icon-tick1"></i> {context.translation && context.translation.urSelection}</div>
          <div className="yourSelectionBtnWrap">
          {enableBook() === true && (
                    <Button
                      className="cntBtn hotelBtn"
                      type="primary"
                      onClick={finalSelRooms}
                    >
                      {context.translation && context.translation.plrHtlsBtn}
                      {/* Book Now */}
                      <img src={HotelBrandIcon} alt="hotelBrandIcon" className="hotelBrandIcon" />
                      {/* <i className="icon icon-H-logo"></i> */}
                    </Button>
                  )}
        {selectedRooms && selectedRooms.length >= 4 ? (
          <Button
            className="showmorelink showAllLess"
            variant="link"
            onClick={() => setRoomeListshow(!roomeListshow)}
          >
            {roomeListshow ? "- Show less" : "+ Show All"}
          </Button>
        ) : (
          ""
        )}
          </div>
        
     
        </div>
        <div className="tableScroll">
      <div className="hotelRoomOccupancyWrapper">
        <Row gutter={40} className="">
          <Col span={7} className="mobileWidthcol7">
            <div className="occupancyTitle">{context.translation && context.translation.rooms}</div>
          </Col>
          
          <Col span={3} className="mobileHide">
            <div className="occupancyTitle">{context.translation && context.translation.bedtype}</div>
          </Col>
          
          <Col span={4} className="mobileHide">
            <div className="occupancyTitle">{context.translation && context.translation.occupancy}</div>
          </Col>
          
          <Col span={3} className="mobileWidthcol3">
            <div className="occupancyTitle">{context.translation && context.translation.boardBasis}</div>
          </Col>
          
          <Col span={3} className="mobileWidthcol3">
            <div className="occupancyTitle ">{context.translation && context.translation.priceperroom}</div>
          </Col>
          
          <Col span={4} className="mobileHide">
            <div className="occupancyTitle">{context.translation && context.translation.totPrice}</div>
          </Col>
        </Row>
        <div>
        {selectedRooms &&
          selectedRooms
            .map((room, indx) => (
              <Row gutter={40} className="yourSelecRow" key={indx}>
                <Col span={7} className="mobileWidthcol7">
                  <div className="occupancySelection">
                    <Tooltip placement="bottom" title={<>{context.translation && context.translation.room} {room.rmindex} :{" "}
                    {room.rst
                      ? room.rooms !== undefined && room.rooms.rnm
                      : context.translation && context.translation.notSelect}</>}>
                    {context.translation && context.translation.room} {room.rmindex} :{" "}
                    {room.rst
                      ? room.rooms !== undefined && room.rooms.rnm
                      : context.translation && context.translation.notSelect}
                    </Tooltip>
                  </div>
                </Col>
                
                <Col span={3} className="mobileHide">
                  <div className="occupancySelection">
                    <Tooltip placement="bottom" title={room.rst
                      ? room.rooms !== undefined &&
                        room.rooms.rmpr !== undefined &&
                        room.rooms.rmpr[0].bed
                      : "---"}>
                    {room.rst
                      ? room.rooms !== undefined &&
                        room.rooms.rmpr !== undefined &&
                        room.rooms.rmpr[0].bed
                      : "---"}
                      </Tooltip>
                  </div>
                </Col>
                
                <Col span={4} className="mobileHide">
                  <div className="occupancySelection">
                    <Tooltip placement="bottom" title={<>
                      {room.rst
                      ? room.rooms !== undefined && room.rooms.chd > 0
                        ? room.rooms.adt +
                          " Adult(s), " +
                          room.rooms.chd +
                          " Child(s) " + 
                          room.rooms.chdage
                        : room.rooms.adt + " Adult(s) "
                      : "---"}
                    </>}>
                    {room.rst
                      ? room.rooms !== undefined && room.rooms.chd > 0
                        ? room.rooms.adt +
                          " Adult(s), " +
                          room.rooms.chd +
                          " Child(s) " + 
                          room.rooms.chdage
                        : room.rooms.adt + " Adult(s) "
                      : "---"}
                      </Tooltip>
                  </div>
                </Col>
                
                <Col span={3}  className="mobileWidthcol3">
                  <div className="occupancySelection">
                    <Tooltip placement="bottom" title={room.rst
                      ? room.rooms !== undefined &&
                        room.rooms.rmpr[0] !== undefined &&
                        room.rooms.rmpr[0].ra[0] !== undefined
                        ? Utilities.getBoardBasisHotel(room.rooms.rmpr[0].ra)
                        : context.translation && context.translation.roomOnly
                      : "---"}>
                    {room.rst
                      ? room.rooms !== undefined &&
                        room.rooms.rmpr[0] !== undefined &&
                        room.rooms.rmpr[0].ra[0] !== undefined
                        ? Utilities.getBoardBasisHotel(room.rooms.rmpr[0].ra)
                        : context.translation && context.translation.roomOnly
                      : "---"}
                      </Tooltip>
                  </div>
                </Col>
                
                <Col span={3}  className="mobileWidthcol3">
                  {room.rst && (
                    <div className="occupancySelection">
                      <Tooltip placement="bottom" title={<> {cur}{" "}
                      <FormatCurrency
                        value={
                          room.rooms !== undefined &&
                          room.rooms.rmpr !== undefined &&
                          (Number(room.rooms.rmpr[0].pr[0].tf) + Number(room.rooms.rmpr[0].servicefee.length > 0?room.rooms.rmpr[0].servicefee[0].sf:0)) * base_usr_exrate}
                      /></>}>
                      {cur}{" "}
                      <FormatCurrency
                        value={
                          room.rooms !== undefined &&
                          room.rooms.rmpr !== undefined &&
                          (Number(room.rooms.rmpr[0].pr[0].tf) + Number(room.rooms.rmpr[0].servicefee.length > 0?room.rooms.rmpr[0].servicefee[0].sf:0)) * base_usr_exrate}
                      />
                      </Tooltip>
                    </div>
                  )}
                {!room.rst && <div className="occupancySelection">---</div>}
                </Col>
                
              </Row>
            ))
            .slice(
              0,
              roomeListshow ? selectedRooms && selectedRooms.length : "3"
            )}
            {selectedRooms.length >=4 ?
              <div style={{textAlign:"center"}}>
                <Button className="showmorelink" variant="link" onClick={() => setRoomeListshow(!roomeListshow)}>
                    {roomeListshow ? "- Show less" : "+ Show All"}
                </Button>
              </div> :''}
            </div>
             {selectedRooms.length!==0 &&
                <div className="hotelRoomPrice mobileHide">
                  <div>
                    <span className="countryCode">{cur}</span>
                    <FormatCurrency value={calculatePrice()} className="currencyValue" />
                  </div>
                  <div className="totalPrice mobileHide">
                    ({context.translation && context.translation.totalPrice} {noofNights} {context.translation && context.translation.nights})
                  </div>
                </div>
              }
            
      </div>
      </div>
      </div>
    </>
  );
}

export default HotelRoomSelection;
