import React from "react";
import { Row, Button, Col, Typography } from "antd";
import { Context } from "../../../../App";
import { useContext } from "react";
import FormatCurrency from "../../../utilities/FormatCurrency";
const { Title } = Typography;

function HotelPaymentDetails(props) {
  const [context, setContext] = useContext(Context)
  return (
    <>
      <Title level={3} className="heading">
        {context.translation && context.translation.paymentDetails}
      </Title>
      <div className="tableScroll displayNone-640">
        <div className="tableWrap">
        <Row className="tableHeader" justify="space-between" >
          <Col span={6} className="tableValue">
            {context.translation && context.translation.modePayment}
          </Col>
          <Col span={6} className="tableValue">
            {context.translation && context.translation.cardType}
          </Col>
          <Col span={6} className="tableValue">
            {context.translation && context.translation.cardDetails}
          </Col>
          <Col span={6} sm={6} md={4} className="tableValue">
            {context.translation && context.translation.amountPaid}
          </Col>
        </Row>
        {props.payment_info!==undefined &&
        <Row className="tableData" justify="space-between" >
          <Col span={6} className="tableValue">
            {props.payment_info.ptype}
          </Col>
          <Col span={6} className="tableValue">
          {props.payment_info.ctype}
          </Col>
          <Col span={6} className="tableValue">
          XXXXXXXXXXXX{props.payment_info.cno}
          </Col>
          <Col span={6} sm={6} md={4} className="tableValue">
          {props.payment_info.ccode} <FormatCurrency value={props.payment_info.amt}/>
          </Col>
        </Row>
          }
        </div>
      </div>
      <div className='smallScreenTable displayNoneLG'>
      <Row className="tableHeader">
      {props.payment_info!==undefined &&
      <Col span={24} className="tableData">
        <div className="tableValueWrap">
          <div className="tableValue">
            {context.translation && context.translation.modePayment}
          </div>
          <div className="tableValue">
            {props.payment_info.ptype}
          </div>
        </div>
        <div className="tableValueWrap">
          <div className="tableValue">
            {context.translation && context.translation.cardType}
          </div>
          <div className="tableValue">
            {props.payment_info.ctype}
          </div>
        </div>
        <div className="tableValueWrap">
          <div className="tableValue">
            {context.translation && context.translation.cardDetails}
          </div>
          <div className="tableValue paymentData">
          XXXXXXXXXXXX{props.payment_info.cno}
          </div>
        </div>
        <div className="tableValueWrap">
          <div className="tableValue">
            {context.translation && context.translation.amountPaid}
          </div>
          <div className="tableValue">
            {props.payment_info.ccode} <FormatCurrency value={props.payment_info.amt}/>
          </div>
        </div>
      </Col>
       }
      </Row>
      </div>
    </>
  );
}

export default HotelPaymentDetails;
