import React, { useEffect } from "react";
import { useReducer } from "react";
import { useState } from "react";
import HotelOccupancyDetails from "./HotelOccupancyDetails";

const initialState = {
  selRoom1: false,
  selRoom2: false,
  selRoom3: false,
  selRoom4: false,
  selRoom5: false,
  selRoom6: false,
  selRoom7: false,
  selRoom8: false,
  selected: false,
};

//=====================================
// Reducer function for userReact Hook
// which will update the state
//=====================================
const reducer = (state, action) => {
  switch (action.type) {
    case "clear":
      return {
        ...state,
      };
    default:
      return { ...state, [action.type]: action.payload };
  }
};

function HotelRoomOccupancy(props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [roomOcp1, setRoomOcp1] = useState({});
  const [roomOcp2, setRoomOcp2] = useState({});
  const [roomOcp3, setRoomOcp3] = useState({});
  const [roomOcp4, setRoomOcp4] = useState({});
  const [roomOcp5, setRoomOcp5] = useState({});
  const [roomOcp6, setRoomOcp6] = useState({});
  const [roomOcp7, setRoomOcp7] = useState({});
  const [roomOcp8, setRoomOcp8] = useState({});
  const [rmpr, setRoomPrice] = useState(props.rmpr);
  // const [isloading, setIsloading] = useState(false);
  // const [selected, setSelected] = useState(false);

  //Room 1
  useEffect(() => {
    setRoomOcp1(props.rmocp1);
  }, [props.rmocp1]);

  //Room 2
  useEffect(() => {
    setRoomOcp2(props.rmocp2);
  }, [props.rmocp2]);

  //Room 3
  useEffect(() => {
    setRoomOcp3(props.rmocp3);
  }, [props.rmocp3]);

  //Room 4
  useEffect(() => {
    setRoomOcp4(props.rmocp4);
  }, [props.rmocp4]);

  //Room 5
  useEffect(() => {
    setRoomOcp5(props.rmocp5);
  }, [props.rmocp5]);

  //Room 6
  useEffect(() => {
    setRoomOcp6(props.rmocp6);
  }, [props.rmocp6]);

  //Room 7
  useEffect(() => {
    setRoomOcp7(props.rmocp7);
  }, [props.rmocp7]);

  //Room 8
  useEffect(() => {
    setRoomOcp8(props.rmocp8);
  }, [props.rmocp8]);

  const handleChange = (obj, selOcupncy) => (e) => {
    e.preventDefault();
    const { id, value } = e.target;
    if (selOcupncy === 1) {
      let selOcp =
        props.ocpnsList1 !== undefined &&
        props.ocpnsList1.filter((ob) => ob.selected1);
      if (
        selOcp !== undefined &&
        selOcp[0] !== undefined &&
        selOcp[0].selected1 !== undefined &&
        selOcp[0].selected1
      ) {
        selOcp[0].selected1 = false;
      }
      obj.selected1 = true;
      props.sendSelRoom(obj);
    } else if (selOcupncy === 2) {
      let selOcp =
        props.ocpnsList2 !== undefined &&
        props.ocpnsList2.filter((ob) => ob.selected2);
      if (
        selOcp !== undefined &&
        selOcp[0] !== undefined &&
        selOcp[0].selected2 !== undefined &&
        selOcp[0].selected2
      ) {
        selOcp[0].selected2 = false;
      }
      obj.selected2 = true;
      props.sendSelRoom(obj);
    } else if (selOcupncy === 3) {
      let selOcp =
        props.ocpnsList3 !== undefined &&
        props.ocpnsList3.filter((ob) => ob.selected3);
      if (
        selOcp !== undefined &&
        selOcp[0] !== undefined &&
        selOcp[0].selected3 !== undefined &&
        selOcp[0].selected3
      ) {
        selOcp[0].selected3 = false;
      }
      obj.selected3 = true;
      props.sendSelRoom(obj);
    } else if (selOcupncy === 4) {
      let selOcp =
        props.ocpnsList4 !== undefined &&
        props.ocpnsList4.filter((ob) => ob.selected4);
      if (
        selOcp !== undefined &&
        selOcp[0] !== undefined &&
        selOcp[0].selected4 !== undefined &&
        selOcp[0].selected4
      ) {
        selOcp[0].selected4 = false;
      }
      obj.selected4 = true;
      props.sendSelRoom(obj);
    } else if (selOcupncy === 5) {
      let selOcp =
        props.ocpnsList4 !== undefined &&
        props.ocpnsList5.filter((ob) => ob.selected5);
      if (
        selOcp !== undefined &&
        selOcp[0] !== undefined &&
        selOcp[0].selected5 !== undefined &&
        selOcp[0].selected5
      ) {
        selOcp[0].selected5 = false;
      }
      obj.selected5 = true;
      props.sendSelRoom(obj);
    } else if (selOcupncy === 6) {
      let selOcp =
        props.ocpnsList6 !== undefined &&
        props.ocpnsList6.filter((ob) => ob.selected6);
      if (
        selOcp !== undefined &&
        selOcp[0] !== undefined &&
        selOcp[0].selected6 !== undefined &&
        selOcp[0].selected6
      ) {
        selOcp[0].selected6 = false;
      }
      obj.selected6 = true;
      props.sendSelRoom(obj);
    } else if (selOcupncy === 7) {
      let selOcp =
        props.ocpnsList7 !== undefined &&
        props.ocpnsList7.filter((ob) => ob.selected7);
      if (
        selOcp !== undefined &&
        selOcp[0] !== undefined &&
        selOcp[0].selected7 !== undefined &&
        selOcp[0].selected7
      ) {
        selOcp[0].selected7 = false;
      }
      obj.selected7 = true;
      props.sendSelRoom(obj);
    } else if (selOcupncy === 8) {
      let selOcp =
        props.ocpnsList8 !== undefined &&
        props.ocpnsList8.filter((ob) => ob.selected8);
      if (
        selOcp !== undefined &&
        selOcp[0] !== undefined &&
        selOcp[0].selected8 !== undefined &&
        selOcp[0].selected8
      ) {
        selOcp[0].selected8 = false;
      }
      obj.selected8 = true;
      props.sendSelRoom(obj);
    }
    dispatch({ type: id, payload: value });
  };

  const showRoom = (roomObj) => {
    props.sendRoomInfo(roomObj);
  };

  return (
    <>
    
      {roomOcp1 !== undefined &&
        roomOcp1.rmindex !== undefined &&
        roomOcp1.rmindex === 1 && (
          <>
            <HotelOccupancyDetails
              rmocp={roomOcp1}
              rmpr={rmpr}
              sendRoom={showRoom}
              pricePerNight={props.pricePerNight}
              onflyMkp={props.onflyMkp}
              nights={props.nights}
              bordname={props.bordname}
              sno={1}
              handleChange={handleChange}
              base_usr_exrate={props.base_usr_exrate}
              cur={props.cur}
            />
            
          </>
           
        )}
       
      {roomOcp2 !== undefined &&
        roomOcp2.rmindex !== undefined &&
        roomOcp2.rmindex === 2 && (
         <>
            <HotelOccupancyDetails
              rmocp={roomOcp2}
              rmpr={rmpr}
              sendRoom={showRoom}
              pricePerNight={props.pricePerNight}
              onflyMkp={props.onflyMkp}
              nights={props.nights}
              bordname={props.bordname}
              sno={2}
              handleChange={handleChange}
              base_usr_exrate={props.base_usr_exrate}
              cur={props.cur}
            />
            </>
        )}
      {roomOcp3 !== undefined &&
        roomOcp3.rmindex !== undefined &&
        roomOcp3.rmindex === 3 && (
           <>
            <HotelOccupancyDetails
              rmocp={roomOcp3}
              rmpr={rmpr}
              sendRoom={showRoom}
              pricePerNight={props.pricePerNight}
              onflyMkp={props.onflyMkp}
              nights={props.nights}
              bordname={props.bordname}
              sno={3}
              handleChange={handleChange}
              base_usr_exrate={props.base_usr_exrate}
              cur={props.cur}
            />
           </>
        )}
      {roomOcp4 !== undefined &&
        roomOcp4.rmindex !== undefined &&
        roomOcp4.rmindex === 4 && (
          <>
            <HotelOccupancyDetails
              rmocp={roomOcp4}
              rmpr={rmpr}
              sendRoom={showRoom}
              pricePerNight={props.pricePerNight}
              onflyMkp={props.onflyMkp}
              nights={props.nights}
              bordname={props.bordname}
              sno={4}
              handleChange={handleChange}
              base_usr_exrate={props.base_usr_exrate}
              cur={props.cur}
            />
          </>
        )}
      {roomOcp5 !== undefined &&
        roomOcp5.rmindex !== undefined &&
        roomOcp5.rmindex === 5 && (
          <>
            <HotelOccupancyDetails
              rmocp={roomOcp5}
              rmpr={rmpr}
              sendRoom={showRoom}
              pricePerNight={props.pricePerNight}
              onflyMkp={props.onflyMkp}
              nights={props.nights}
              bordname={props.bordname}
              sno={5}
              handleChange={handleChange}
              base_usr_exrate={props.base_usr_exrate}
              cur={props.cur}
            />
          </>
        )}
      {roomOcp6 !== undefined &&
        roomOcp6.rmindex !== undefined &&
        roomOcp6.rmindex === 6 && (
          <>
            <HotelOccupancyDetails
              rmocp={roomOcp6}
              rmpr={rmpr}
              sendRoom={showRoom}
              pricePerNight={props.pricePerNight}
              onflyMkp={props.onflyMkp}
              nights={props.nights}
              bordname={props.bordname}
              sno={6}
              handleChange={handleChange}
              base_usr_exrate={props.base_usr_exrate}
              cur={props.cur}
            />
          </>
        )}
      {roomOcp7 !== undefined &&
        roomOcp7.rmindex !== undefined &&
        roomOcp7.rmindex === 7 && (
          <>
            <HotelOccupancyDetails
              rmocp={roomOcp7}
              rmpr={rmpr}
              sendRoom={showRoom}
              pricePerNight={props.pricePerNight}
              onflyMkp={props.onflyMkp}
              nights={props.nights}
              bordname={props.bordname}
              sno={7}
              handleChange={handleChange}
              base_usr_exrate={props.base_usr_exrate}
              cur={props.cur}
            />
          </>
        )}
      {roomOcp8 !== undefined &&
        roomOcp8.rmindex !== undefined &&
        roomOcp8.rmindex === 8 && (
          <>
            <HotelOccupancyDetails
              rmocp={roomOcp8}
              rmpr={rmpr}
              sendRoom={showRoom}
              pricePerNight={props.pricePerNight}
              onflyMkp={props.onflyMkp}
              nights={props.nights}
              bordname={props.bordname}
              sno={8}
              handleChange={handleChange}
              base_usr_exrate={props.base_usr_exrate}
              cur={props.cur}
            />
          </>
        )}
    </>
  );
}

export default HotelRoomOccupancy;
