import React, { useEffect, useRef } from "react";
import Footer from "./Footer";
import Header from "./Header";
import ShowHide from "./showHide/ShowHide";
import StickyBox from "react-sticky-box";
import { Col, Row, Typography } from "antd";
import Title from "antd/es/skeleton/Title";

function TermsAndConditions(props) {
  const tcGeneral = useRef(null);
  const tc1 = useRef(null);
  const tc2 = useRef(null);
  const tc3 = useRef(null);
  const tc4 = useRef(null);
  const tc5 = useRef(null);
  const tc6 = useRef(null);
  const tc7 = useRef(null);
  const tc8 = useRef(null);
  const tc9 = useRef(null);
  const tc10 = useRef(null);
  const tc11 = useRef(null);
  const tc12 = useRef(null);
  const tc13 = useRef(null);
  const tc14 = useRef(null);
  const tc15 = useRef(null);
  const tc16 = useRef(null);
  const tc17 = useRef(null);
  const tc18 = useRef(null);
  const tc19 = useRef(null);
  const tc20 = useRef(null);
  const tc21 = useRef(null);
  const tc22 = useRef(null);
  const tc23 = useRef(null);
  const tc24 = useRef(null);
  const tc25 = useRef(null);
  const executeScrolltop = () =>
    tcGeneral.current.scrollIntoView({ behavior: "smooth" });
  const executeScrolltop1 = () =>
    tc1.current.scrollIntoView({ behavior: "smooth" });
  const executeScrolltop2 = () =>
    tc2.current.scrollIntoView({ behavior: "smooth" });
  const executeScrolltop3 = () =>
    tc3.current.scrollIntoView({ behavior: "smooth" });
  const executeScrolltop4 = () =>
    tc4.current.scrollIntoView({ behavior: "smooth" });
  const executeScrolltop5 = () =>
    tc5.current.scrollIntoView({ behavior: "smooth" });
  const executeScrolltop6 = () =>
    tc6.current.scrollIntoView({ behavior: "smooth" });
  const executeScrolltop7 = () =>
    tc7.current.scrollIntoView({ behavior: "smooth" });
  const executeScrolltop8 = () =>
    tc8.current.scrollIntoView({ behavior: "smooth" });
  const executeScrolltop9 = () =>
    tc9.current.scrollIntoView({ behavior: "smooth" });
  const executeScrolltop10 = () =>
    tc10.current.scrollIntoView({ behavior: "smooth" });
  const executeScrolltop11 = () =>
    tc11.current.scrollIntoView({ behavior: "smooth" });
  const executeScrolltop12 = () =>
    tc12.current.scrollIntoView({ behavior: "smooth" });
  const executeScrolltop13 = () =>
    tc13.current.scrollIntoView({ behavior: "smooth" });
  const executeScrolltop14 = () =>
    tc14.current.scrollIntoView({ behavior: "smooth" });
  const executeScrolltop15 = () =>
    tc15.current.scrollIntoView({ behavior: "smooth" });
  const executeScrolltop16 = () =>
    tc16.current.scrollIntoView({ behavior: "smooth" });
  const executeScrolltop17 = () =>
    tc17.current.scrollIntoView({ behavior: "smooth" });
  const executeScrolltop18 = () =>
    tc18.current.scrollIntoView({ behavior: "smooth" });
  const executeScrolltop19 = () =>
    tc19.current.scrollIntoView({ behavior: "smooth" });
  const executeScrolltop20 = () =>
    tc20.current.scrollIntoView({ behavior: "smooth" });
  const executeScrolltop21 = () =>
    tc21.current.scrollIntoView({ behavior: "smooth" });
  const executeScrolltop22 = () =>
    tc22.current.scrollIntoView({ behavior: "smooth" });
  const executeScrolltop23 = () =>
    tc23.current.scrollIntoView({ behavior: "smooth" });
  const executeScrolltop24 = () =>
    tc24.current.scrollIntoView({ behavior: "smooth" });
  const executeScrolltop25 = () =>
    tc25.current.scrollIntoView({ behavior: "smooth" });
  const general = (e) => {
    executeScrolltop();
  };
  const general1 = (e) => {
    executeScrolltop1();
  };
  const general2 = (e) => {
    executeScrolltop2();
  };
  const general3 = (e) => {
    executeScrolltop3();
  };
  const general4 = (e) => {
    executeScrolltop4();
  };
  const general5 = (e) => {
    executeScrolltop5();
  };
  const general6 = (e) => {
    executeScrolltop6();
  };
  const general7 = (e) => {
    executeScrolltop7();
  };
  const general8 = (e) => {
    executeScrolltop8();
  };
  const general9 = (e) => {
    executeScrolltop9();
  };
  const general10 = (e) => {
    executeScrolltop10();
  };
  const general11 = (e) => {
    executeScrolltop11();
  };
  const general12 = (e) => {
    executeScrolltop12();
  };
  const general13 = (e) => {
    executeScrolltop13();
  };
  const general14 = (e) => {
    executeScrolltop14();
  };
  const general15 = (e) => {
    executeScrolltop15();
  };
  const general16 = (e) => {
    executeScrolltop16();
  };
  const general17 = (e) => {
    executeScrolltop17();
  };
  const general18 = (e) => {
    executeScrolltop18();
  };
  const general19 = (e) => {
    executeScrolltop19();
  };
  const general20 = (e) => {
    executeScrolltop20();
  };
  const general21 = (e) => {
    executeScrolltop21();
  };
  const general22 = (e) => {
    executeScrolltop22();
  };
  const general23 = (e) => {
    executeScrolltop23();
  };
  const general24 = (e) => {
    executeScrolltop24();
  };
  const general25 = (e) => {
    executeScrolltop25();
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  const { Title } = Typography;

  return (
    <>
      <Header posType="floating" />
      <div className="container tndC">
        <Title level={4} className="tcTitle">
          Terms and Conditions
        </Title>
        <Row>
          <Col span={6} className="tcleftSidebar">
            <StickyBox offsetTop={100} offsetBottom={20}>
              <ul variant="pills" className="flex-column">
                <li>
                  <span eventKey="general" onClick={general} className="active" title="Applicability">
                    Applicability
                  </span>
                </li>
                <li>
                  <span eventKey="clientResp" onClick={general1} title="Eligibility to Use">
                    Eligibility to Use
                  </span>
                </li>
                <li>
                  <span eventKey="disclaimer" onClick={general2} title="Application">
                    Application
                  </span>
                </li>
                <li>
                  <span eventKey="pvcStatement" onClick={general3} title="Content">
                    Content
                  </span>
                </li>
                <li>
                  <span eventKey="copyright" onClick={general4} title="Limited Liability of Clickntravel">
                    Limited Liability of Clickntravel
                  </span>
                </li>
                <li>
                  <span eventKey="agency" onClick={general5} title="User's Responsibility">
                    User's Responsibility
                  </span>
                </li>
                <li>
                  <span eventKey="confidentiality" onClick={general6} title="Security and Account Related information">
                    Security and Account Related information
                  </span>
                </li>
                <li>
                  <span eventKey="indemnity" onClick={general7} title="Fees and Payment">
                    Fees and Payment
                  </span>
                </li>
                <li>
                  <span eventKey="governingLaw" onClick={general8} title="Obligation to Obtain Visa">
                    Obligation to Obtain Visa
                  </span>
                </li>
                <li>
                  <span eventKey="forceMajeure" onClick={general9} title="Force Majeure">
                    Force Majeure
                  </span>
                </li>
                <li>
                  <span eventKey="variations" onClick={general10} title="Right to Cancel">
                    Right to Cancel
                  </span>
                </li>
                <li>
                  <span eventKey="indiv" onClick={general11} title="Fake Calls and Other Similar Phishing, Spamming or
                    Fraudulent Activities">
                    Fake Calls and Other Similar Phishing, Spamming or
                    Fraudulent Activities
                  </span>
                </li>
                <li>
                  <span eventKey="tndc" onClick={general12} title="Indemnification">
                    Indemnification
                  </span>
                </li>
                <li>
                  <span eventKey="tndc" onClick={general13} title="Covid-19 Related Terms & Conditions">
                    Covid-19 Related Terms & Conditions
                  </span>
                </li>
                <li>
                  <span eventKey="tndc" onClick={general14} title="Miscellaneous">
                    Miscellaneous
                  </span>
                </li>

                {/* Flights */}
                <Title level={4} className="tcTitle" style={{marginTop: "2rem"}}>
                  Flights
                </Title>
                <li>
                  <span eventKey="general" onClick={general15} title="Terms of the Airlines">
                    Terms of the Airlines
                  </span>
                </li>
                <li>
                  <span eventKey="clientResp" onClick={general16} title="Pricing">
                    Pricing
                  </span>
                </li>
                <li>
                  <span eventKey="disclaimer" onClick={general17} title="Travel Documents">
                    Travel Documents
                  </span>
                </li>
                <li>
                  <span eventKey="pvcStatement" onClick={general18} title="Check-In Terms">
                    Check-In Terms
                  </span>
                </li>
                <li>
                  <span eventKey="copyright" onClick={general19} title="Use of Flight Segments">
                    Use of Flight Segments
                  </span>
                </li>
                <li>
                  <span eventKey="agency" onClick={general20} title="Changes to Existing Booking">
                    Changes to Existing Booking
                  </span>
                </li>
                <li>
                  <span eventKey="confidentiality" onClick={general21} title="Refund">
                    Refund
                  </span>
                </li>
                <li>
                  <span eventKey="indemnity" onClick={general22} title="Others">
                    Others
                  </span>
                </li>

                {/* Hotels */}
                <Title level={4} className="tcTitle" style={{marginTop: "2rem"}}>
                  Hotels
                </Title>
                <li>
                  <span eventKey="general" onClick={general23} title="Terms & Liabilities of Clickntravel">
                    Terms & Liabilities of Clickntravel
                  </span>
                </li>
                <li>
                  <span eventKey="clientResp" onClick={general24} title="Responsibilities of the User">
                    Responsibilities of the User
                  </span>
                </li>
                <li>
                  <span eventKey="disclaimer" onClick={general25} title="Mode of Payment">
                    Mode of Payment
                  </span>
                </li>
              </ul>
            </StickyBox>
          </Col>
          <Col span={18} className="tcLayout">
            <div ref={tcGeneral}>
              <ShowHide visible={false} icon="" title="Applicability">
                <div className="showHide-content">
                  <p>
                    This User Agreement along with Terms of Service
                    (collectively, the "User Agreement") forms the terms and
                    conditions for the use of services and products offered by
                    Tawfeeq Travel Company W.L.L (“Clickntravel").
                  </p>

                  <p>
                    Any person ("User") who inquiries about or purchases any
                    products or services of Clickntravel through its mobile
                    applications, salespersons, offices, call centers etc. (all
                    the aforesaid platforms collectively referred to as “Sales
                    Channels”) agree to be governed by this User Agreement.
                  </p>

                  <p>
                    Both User and Clickntravel are individually referred to as
                    'Party' and collectively referred to as 'Parties' to the
                    User Agreement.
                  </p>

                  <p>
                    “Terms of Service” available in the Clickntravel application
                    details out terms & conditions applicable on various
                    services or products facilitated by Clickntravel. The User
                    should refer to the relevant Terms of Service applicable for
                    the given product or service as booked by the User. Such
                    Terms of Service are binding on the User.
                  </p>
                </div>
              </ShowHide>
            </div>
            <div ref={tc1}>
              <ShowHide visible="true" icon="" title="Eligibility to Use">
                <div className="showHide-content">
                  <p>
                    The User must be at least 18 years of age and must possess
                    the legal authority to enter into an agreement so as become
                    a User and use the services of Clickntravel. If you are a
                    minor or are below the age of 18 years, you shall not
                    register as a User of the application and shall not transact
                    on or use the application.
                  </p>

                  <p>
                    As a minor if you wish to use or transact on the
                    Application, such use or transaction shall only be made by a
                    person of legal contracting age (legal guardian or parents).
                    We reserve the right to terminate your membership and/or
                    block access to the Application if it is discovered that you
                    are a minor or incompetent to contract according to the law
                    or any information pertaining to your age entered at the
                    time of creation of account is false.
                  </p>

                  <p>
                    Before using the Application, approaching any Sales Channels
                    or procuring the services of Clickntravel, the Users shall
                    compulsorily read and understand this User Agreement, and
                    shall be deemed to have accepted this User Agreement as a
                    binding document that governs User’s dealings and
                    transactions with Clickntravel. If the User does not agree
                    with any part of this Agreement, then the User must not
                    avail Clickntravel's services and must not access or
                    approach the Sales Channels of Clickntravel.
                  </p>
                  <p>
                    All rights and liabilities of the User and Clickntravel with
                    respect to any services or product facilitated by
                    Clickntravel shall be restricted to the scope of this User
                    Agreement.
                  </p>
                </div>
              </ShowHide>
            </div>
            <div ref={tc2}>
              <ShowHide visible="true" icon="" title="Application">
                <div className="showHide-content">
                  <p>
                    The Application is meant to be used by bonafide User(s) for
                    a lawful use.
                  </p>
                  <p>
                    The User Agreement grants a limited, non-exclusive,
                    non-transferable right to use this Application as expressly
                    permitted in this User Agreement. The User agrees not to
                    interrupt or attempt to interrupt the operation of the
                    Application in any manner whatsoever.
                  </p>
                  <p>
                    Clickntravel reserves the right, in its sole discretion, to
                    terminate the access to the Application and the services
                    offered on the same or any portion thereof at any time,
                    without notice, for general maintenance or any other reason
                    whatsoever.
                  </p>
                  <p>
                    Clickntravel will always make its best endeavors to ensure
                    that the content on its Application or other sales channels
                    are free of any virus or such other malwares. However, any
                    data or information downloaded or otherwise obtained through
                    the use of the Application, or any other Sales Channel is
                    done entirely at the User’s own discretion and risk and they
                    will be solely responsible for any damage to their Mobile
                    Device or loss of data that may result from the download of
                    such data or information.
                  </p>
                  <p>
                    Clickntravel reserves the right to periodically make
                    improvements or changes in its application, at any time
                    without any prior notice to the User. User(s) are requested
                    to report any content on the Application, which is deemed to
                    be unlawful, objectionable, libelous, defamatory, obscene,
                    harassing, invasive to privacy, abusive, fraudulent, against
                    any religious beliefs, spam, or is violative of any
                    applicable law to <a href="mailto:complianceofficer@att.qa">complianceofficer@att.qa</a> receiving such
                    report, Clickntravel reserves the right to investigate
                    and/or take such action as the Company may deem appropriate.
                  </p>
                </div>
              </ShowHide>
            </div>
            <div ref={tc3}>
              <ShowHide visible="true" icon="" title="Content">
                <div className="showHide-content">
                  <p>
                    All content provided through various Sales Channels,
                    including but not limited to images, software, text, icons
                    and such similar content ("Content"), are registered by
                    Clickntravel and protected under applicable intellectual
                    property laws. User cannot use this Content for any other
                    purpose, except as specified herein.
                  </p>
                  <p>
                    User agrees to follow all instructions provided by
                    Clickntravel which will prescribe the way such User may use
                    the Content.
                  </p>
                  <p>
                    There are a number of proprietary logos, service marks and
                    trademarks displayed on the Application and through other
                    Sales Channels of Clickntravel, as may be applicable.
                    Clickntravel does not grant the User a license, right or
                    authority to utilize such proprietary logos, service marks,
                    or trademarks in any manner. Any unauthorized use of the
                    Content will be in violation of the applicable law.
                  </p>
                  <p>
                    Clickntravel respects the intellectual property rights of
                    others. If you notice any act of infringement on the
                    Application, you are requested to send us a written notice/
                    intimation which must include the following information;
                    <ul>
                      <li>
                        clear identification of such copyrighted work that you
                        claim has been infringed;{" "}
                      </li>
                      <li>
                        The proof that the alleged copyrighted work is owned by
                        you;{" "}
                      </li>
                      <li>Contact information;</li>
                      <li>
                        The aforesaid notices can be sent to Clickntravel by
                        mail at <a href="mailto:complianceofficer@att.qa">complianceofficer@att.qa</a>.
                      </li>
                    </ul>
                  </p>
                </div>
              </ShowHide>
            </div>
            <div ref={tc4}>
              <ShowHide
                visible="true"
                icon=""
                title="Limited Liability of Clickntravel"
              >
                <div className="showHide-content">
                  <p>
                    Unless Clickntravel explicitly acts as a reseller in certain
                    scenarios, Clickntravel always acts as a facilitator by
                    connecting the User with the respective service providers
                    like airlines, hotels etc. (collectively referred to as
                    “Service Providers”). Clickntravel’s liability is limited to
                    providing the User with a confirmed booking as selected by
                    the User.
                  </p>
                  <p>
                    Any issues or concerns faced by the User at the time of
                    availing any such services shall be the sole responsibility
                    of the Service Provider. Clickntravel will have no liability
                    with respect to the acts, omissions, errors,
                    representations, warranties, breaches or negligence on part
                    of any Service Provider
                  </p>
                  <p>
                    Unless explicitly committed by as a part of any product or
                    service Clickntravel:
                    <ul>
                      <li>
                        Assumes no liability for the standard of services as
                        provided by the respective Service Providers;
                      </li>
                      <li>
                        Provides no guarantee with regard to their quality or
                        fitness as represented;
                      </li>
                      <li>
                        Doesn't Guarantee the availability of any services as
                        listed by a Service Provider;
                      </li>
                      <li>
                        By making a booking, User understands Clickntravel
                        merely provides a technology platform for booking of
                        services and products and the ultimate liability rests
                        on the respective Service Provider and not Clickntravel.
                        Thus, the ultimate contract of service is between User
                        and Service Provider.
                      </li>
                      <li>
                        User further understands that the information displayed
                        on the Application with respect to any service is
                        displayed as furnished by the Service Provider.
                        Clickntravel, therefore cannot be held liable in case if
                        the information provided by the Service Provider is
                        found to be inaccurate, inadequate or obsolete or in
                        contravention of any laws, rules, regulations or
                        directions in force.
                      </li>
                    </ul>
                  </p>
                </div>
              </ShowHide>
            </div>
            <div ref={tc5}>
              <ShowHide visible="true" icon="" title="User's Responsibility">
                <div className="showHide-content">
                  <p>
                    Users are advised to check the description of the services
                    and products carefully before making a booking. User(s)
                    agree to be bound by all the conditions as contained in
                    booking confirmation or as laid out in the confirmed booking
                    voucher. These conditions are also to be read in consonance
                    with the User Agreement.
                  </p>
                  <p>
                    If a User intends to make a booking on behalf of another
                    person, it shall be the responsibility of the User to inform
                    such person about the terms of this Agreement, including all
                    rules and restrictions applicable thereto.
                  </p>
                  <p>
                    The User undertakes to abide by all procedures and
                    guidelines, as modified from time to time, in connection
                    with the use of the services available through Clickntravel.
                    The User further undertakes to comply with all applicable
                    laws, regulations, orders, directions etc. issued by either
                    the Ministry of Public Health (M.O.P.H) or any other body
                    empowered to do so by the government w.r.t use of services
                    or for each transaction.
                  </p>
                  <p>
                    The services are provided on an "as is" and "as available"
                    basis. Clickntravel may change the features or functionality
                    of the services being provided at any time, in its sole
                    discretion, without any prior notice. Clickntravel expressly
                    disclaims all warranties of any kind, whether express or
                    implied, including, but not limited to the implied
                    warranties of merchantability, reasonably fit for all
                    purposes. No advice or information, whether oral or written,
                    which the User obtains from Clickntravel or through the
                    services opted shall create any warranty not expressly made
                    herein or in the terms and conditions of the services.
                  </p>
                </div>
              </ShowHide>
            </div>
            <div ref={tc6}>
              <ShowHide
                visible="true"
                icon=""
                title="Security and Account Related Information"
              >
                <div className="showHide-content">
                  <p>
                    While registering on the Website, the User will have to
                    choose a password to access that User’s account and User
                    shall be solely responsible for maintaining the
                    confidentiality of both the password and the account as well
                    as for all activities on the account. It is the duty of the
                    User to notify Clickntravel immediately in writing of any
                    unauthorized use of their password or account or any other
                    breach of security. Clickntravel will not be liable for any
                    loss that may be incurred by the User as a result of
                    unauthorized use of the password or account, either with or
                    without the User’s knowledge. The User shall not use anyone
                    else's account at any time.
                  </p>
                  <p>
                    Clickntravel keeps all the data in relation to credit card,
                    debit card, bank information etc. secured and in an
                    encrypted form in compliance with the applicable laws and
                    regulations.
                  </p>
                  <p>
                    Clickntravel adopts the best industry standard to secure the
                    information as provided by the User. However, Clickntravel
                    cannot guarantee that there will never be any security
                    breach of its systems which may have an impact on User’s
                    information too.
                  </p>
                  <p>
                    The data of the User as available with Clickntravel may be
                    shared with concerned law enforcement agencies for any
                    lawful or investigation purpose without the consent of the
                    User.
                  </p>
                </div>
              </ShowHide>
            </div>
            <div ref={tc7}>
              <ShowHide visible="true" icon="" title="Fees and Payment">
                <div className="showHide-content">
                  <p>
                    In addition to the cost of booking as charged by the Service
                    Providers, Clickntravel reserves the right to charge certain
                    fees in the nature of service fees. Clickntravel further
                    reserves the right to alter any and all fees from time to
                    time. Any such additional fees, including fee towards any
                    modifications thereof, will be displayed to the User before
                    confirming the booking or collecting the payment from such
                    User.
                  </p>
                  <p>
                    In the rare circumstance of a booking not getting confirmed
                    for any reason whatsoever, Clickntravel will process the
                    refund of the booking amount paid by the User and intimate
                    the User about the same. Clickntravel is not under any
                    obligation to provide an alternate booking in lieu of or to
                    compensate or replace the unconfirmed booking. All
                    subsequent bookings will be treated as new transactions. Any
                    applicable refund will be processed as per the defined
                    policies of the service provider and Clickntravel as the
                    case may be.
                  </p>
                  <p>
                    The User shall be completely responsible for all charges,
                    fees, duties, taxes, and assessments arising out of the use
                    of the service, as per the applicable laws
                  </p>
                  <p>
                    The User agrees and understands that all payments shall only
                    be made to bank accounts of Clickntravel. Clickntravel or
                    its agents, representatives or employees shall never ask a
                    customer to transfer money to any private account or to an
                    account not held in the name of Clickntravel. The User
                    agrees that if that user transfers any amount against any
                    booking or transaction to any bank account that is not
                    legitimately held by Clickntravel or to any personal account
                    of any person, Clickntravel shall not be held liable for the
                    same. User shall not hold any right to recover from
                    Clickntravel any amount which is transferred by the User to
                    any third party.
                  </p>
                  <p>
                    The User will not share his personal sensitive information
                    like credit/debit card number, CVV, OTP, card expiry date,
                    user IDs, passwords etc. with any person including the
                    agents, employees or representatives of Clickntravel. The
                    User shall immediately inform Clickntravel if such details
                    are demanded by any of its agents’ employees or
                    representatives. Clickntravel shall not be liable for any
                    loss that the User incurs for sharing the aforesaid details.
                  </p>
                  <p>
                    Refunds, if any, on cancelled bookings will always be
                    processed to the respective account or the banking
                    instrument (credit/debit card etc.) from which payment was
                    made for that booking.
                  </p>
                  <p>
                    Booking(s) made by the User through Clickntravel are subject
                    to the applicable cancellation policy as set out on the
                    booking page or as communicated to the customers in writing.
                  </p>
                </div>
              </ShowHide>
            </div>
            <div ref={tc8}>
              <ShowHide
                visible="true"
                icon=""
                title="Obligation to Obtain Visa"
              >
                <div className="showHide-content">
                  <p>
                    International bookings made through Clickntravel are subject
                    to the requirements of visa including but not limited to
                    transit visa, OK TO BOARD which are to be obtained by the
                    User as per the requirement of their travel bookings and the
                    requirements of the countries the User intends to visit or
                    transit through.
                  </p>
                  <p>
                    Clickntravel is not responsible for any issues, including
                    inability to travel, arising out of such visa requirements
                    and is also not liable to refund any amount to the User for
                    being unable to utilize the booking due to absence or denial
                    of visa, irrespective whether or not the User has availed
                    the services of Clickntravel for the visa process too.
                    Refund, if any, will be as per the applicable terms of
                    booking and cancellation policy.
                  </p>
                </div>
              </ShowHide>
            </div>
            <div ref={tc9}>
              <ShowHide visible="true" icon="" title="Force Majeure">
                <div className="showHide-content">
                  <p>
                    There can be exceptional circumstances where Clickntravel
                    and / or the Service Providers may be unable to honor the
                    confirmed bookings due to various reasons like act of God,
                    labor unrest, insolvency, business exigencies, government
                    decisions, Pandemic, Epidemic, terrorist activity, any
                    operational and technical issues, route and flight
                    cancellations etc. or any other reason beyond the control of
                    Clickntravel. If Clickntravel has advance knowledge of any
                    such situations where dishonor of bookings may happen, it
                    will make its best efforts to provide similar alternative to
                    the User or refund the booking amount after deducting
                    applicable service charges, if supported and refunded by
                    that respective service operators. The User agrees that
                    Clickntravel being merely a facilitator of the services and
                    products booked, cannot be held responsible for any such
                    Force Majeure circumstance. The User has to contact the
                    Service Provider directly for any further resolutions and
                    refunds.
                  </p>
                  <p>
                    The User agrees that in the event of non-confirmation of
                    booking due to any technical reasons (like network downtime,
                    disconnection with third party platforms such as payment
                    gateways, banks etc.) or any other similar failures,
                    Clickntravel's obligation shall be limited refunding the
                    booking amount, if any, received from the customer. Such
                    refund shall completely discharge Clickntravel from all
                    liabilities with respect to that transaction. Additional
                    liabilities, if any, shall be borne by the User.
                  </p>
                  <p>
                    In no event shall Clickntravel and be liable for any direct,
                    indirect, punitive, incidental, special or consequential
                    damages, and any other damages like damages for loss of use,
                    data or profits, arising out of or in any way connected with
                    the use or performance of the Application or any other Sales
                    Channel.
                  </p>
                </div>
              </ShowHide>
              <div ref={tc10}>
                <ShowHide visible="true" icon="" title="Right to Cancel">
                  <div className="showHide-content">
                    <p>
                      The User expressly undertakes to provide Clickntravel with
                      correct and valid information while making use of the
                      Website under this User Agreement, and not to make any
                      misrepresentation of facts. Any default on part of the
                      User would disentitle the User from availing the services
                      from Clickntravel.
                    </p>
                    <p>
                      In case Clickntravel discovers or has reasons to believe
                      at any time during or after receiving a request for
                      services from the User that the request for services is
                      either unauthorized or the information provided by the
                      User or any of the travelers is not correct or that any
                      fact has been misrepresented by that User, Clickntravel
                      shall be entitled to appropriate legal remedies against
                      the User, including cancellation of the bookings, without
                      any prior intimation to the User. In such an event,
                      Clickntravel shall not be responsible or liable for any
                      loss or damage that may be caused to the User or any other
                      person in the booking, as a consequence of such
                      cancellation of booking or services.
                    </p>
                    <p>
                      If any judicial, quasi-judicial, investigation agency,
                      government authority approaches Clickntravel to cancel any
                      booking, Clickntravel will cancel the same without
                      approaching the concerned User whose booking has been
                      cancelled.
                    </p>
                    <p>
                      The User shall not hold Clickntravel responsible for any
                      loss or damage arising out of measures taken by
                      Clickntravel for safeguarding its own interest and that of
                      its genuine customers. This would also include
                      Clickntravel denying or cancelling any bookings on account
                      of suspected fraud transactions.
                    </p>
                  </div>
                </ShowHide>
              </div>
            </div>
            <div ref={tc11}>
              <ShowHide
                visible="true"
                icon=""
                title="Fake Calls and Other Similar Phishing, Spamming or
                Fraudulent Activities"
              >
                <div className="showHide-content">
                  <p>
                    Clickntravel's employees or authorized representatives will
                    never contact a User asking for his/ her credit or debit
                    card number, expiry date, CVV, net banking login, passwords,
                    OTP etc. nor will they ever request for a fund transfer to a
                    personal or an individual bank account. Further, they will
                    also not ask a User to install any third-party applications
                    that enable them to view a User's mobile or computer screen.
                  </p>
                  <p>
                    Acting on any of these requests may make you a victim of
                    fraud and may potentially lead to loss of your valuable
                    money or information.
                  </p>
                  <p>
                    If you are ever asked for any of the aforesaid information,
                    please report it immediately on <a href="mailto:complianceofficer@att.qa">complianceofficer@att.qa</a>
                  </p>
                </div>
              </ShowHide>
            </div>
            <div ref={tc12}>
              <ShowHide visible="true" icon="" title="Indemnification">
                <div className="showHide-content">
                  <p>
                    The User agrees to indemnify, defend and hold harmless
                    Clickntravel, its affiliates and their respective officers,
                    directors, lawful successors and assigns from and against
                    any and all losses, liabilities, claims, damages, costs and
                    expenses (including legal fees and disbursements in
                    connection therewith and interest chargeable thereon)
                    asserted against or incurred by such indemnified persons,
                    that arise out of, result from, or may be payable by virtue
                    of, any breach of any representation or warranty provided by
                    the User, or non-performance of any covenant by the User.
                  </p>
                  <p>
                    The User shall be solely liable for any breach of any
                    country specific rules and regulations or general code of
                    conduct and Clickntravel cannot be held responsible for the
                    same.
                  </p>
                </div>
              </ShowHide>
            </div>
            <div ref={tc13}>
              <ShowHide
                visible="true"
                icon=""
                title="Covid-19 Related Terms & Conditions"
              >
                <div className="showHide-content">
                  <p>
                    User agrees that due to recent outbreak of COVID-19 pandemic
                    on a global level, there may be instances, where the end
                    service provider either cancels the booking or refuses to
                    provide the services, as agreed with the User, by assigning
                    or not assigning any specific reason for such refusal.
                    Refusal of service may be due to the reasons including but
                    not limited to the User:
                    <ul>
                      <li>exhibiting any symptoms associated with COVID 19.</li>
                      <li>
                        refusing to comply with safety advisories, like wearing
                        protective gear, complying with social distancing norms
                        etc.
                      </li>
                      <li>
                        suffering of any ailment making the User vulnerable to
                        the virus
                      </li>
                      <li>
                        posing a threat to the health and safety of others
                      </li>
                      <li>not fulfilling Coivd-19 vaccination criteria</li>
                    </ul>
                    In such cases CLICKNTRAVEL will assume no liability for the
                    refusal of service by the end service provider. The User
                    also agrees that refund, if any, for such bookings will be
                    processed by CLICKNTRAVEL to the User subject to receipt of
                    the same from the end service provider.
                  </p>
                  <p>
                    User further understands that due to the outbreak of
                    COVID-19, M.O.P.H have/may come up with detailed set of
                    guidelines or standard operating procedure (SOP) that a
                    traveler will have to follow in order to undertake the
                    travel.
                  </p>
                  <p>
                    The User agrees to abide with all such guidelines/SOP while
                    undertaking any travel. Failure to abide by such detailed
                    set of guidelines/SOP, may lead to a situation where the
                    service provider or any person authorized by M.O.P.H may
                    disallow the User to undertake the travel. In such
                    circumstances, CLICKNTRAVEL will not be held liable for such
                    refusal to travel and refund, if any, shall be strictly as
                    per the terms of the service provider.
                  </p>

                  <p>
                    The User agrees to indemnify, defend and hold harmless
                    Clickntravel, its affiliates and their respective officers,
                    directors, lawful successors and assigns from and against
                    any and all losses, liabilities, claims, damages, costs and
                    expenses (including legal fees and disbursements in
                    connection therewith and interest chargeable thereon)
                    asserted against or incurred by such indemnified persons,
                    that arise out of, result from, or may be payable by virtue
                    of, any breach of any representation or warranty provided by
                    the User, or non-performance of any covenant by the User.
                  </p>
                  <p>
                    The User shall be solely liable for any breach of any
                    country specific rules and regulations or general code of
                    conduct and Clickntravel cannot be held responsible for the
                    same.
                  </p>
                </div>
              </ShowHide>
            </div>
            <div ref={tc14}>
              <ShowHide visible="true" icon="" title="Miscellaneous">
                <div className="showHide-content">
                  <p>
                    <strong>SEVERABILITY:</strong> If any provision of this User
                    Agreement is determined to be invalid or unenforceable in
                    whole or in part, such invalidity or unenforceability shall
                    attach only to such provision or part of such provision and
                    the remaining part of such provision and all other
                    provisions of this User Agreement shall continue to be in
                    full force and effect.
                  </p>
                  <p>
                    <strong>JURISDICTION:</strong> This Agreement is subject to
                    interpretation as per the laws of Qatar.
                  </p>
                  <p>
                    <strong>AMENDMENT TO THE USER AGREEMENT:</strong>{" "}
                    Clickntravel reserves the right to change the User Agreement
                    from time to time. The User is responsible for regularly
                    reviewing the User Agreement.
                  </p>
                  <p>
                    <strong>PRIVACY POLICY:</strong> User shall also refer to
                    Clickntravel's Privacy policy available on Clickntravel's
                    Application which governs use of the Application. By using
                    the Application, User agrees to the terms of the Privacy
                    Policy and accordingly consents to the use of the User's
                    personal information by Clickntravel and its affiliates in
                    accordance with the terms of the Privacy Policy.
                  </p>

                  <p></p>
                </div>
              </ShowHide>
            </div>

            {/* Flights  */}
            <Title level={4} className="tcTitle" style={{marginTop: "2rem"}}>
              Flights
            </Title>
            <div ref={tc15}>
              <ShowHide visible="true" icon="" title="Terms of the Airlines">
                <div className="showHide-content">
                  <p>
                    The airline tickets available through the Application are
                    subject to the terms & conditions of the concerned airline,
                    including but not limited to cancellation and refund
                    policies.
                  </p>
                  <p>
                    Clickntravel merely acts as a facilitator to enable the User
                    to book a flight ticket. The contract of service for
                    utilization of the flight is always between the User and the
                    concerned airline.
                  </p>
                  <p>
                    Airlines retain the right to reschedule flight times, route,
                    change or cancel flights or itineraries independent of and
                    without prior intimation to Clickntravel. As a facilitator
                    Clickntravel has no control or authority over the logistics
                    of the airlines and therefore is not liable for any loss,
                    direct or incidental, that a User may incur due to such
                    change or cancellation of a flight.
                  </p>
                  <p>
                    The baggage allowance on given fare is as per the terms
                    decided by the airline, and Clickntravel has no role to play
                    in the same. Some of the fares shown in the Application are
                    excluding “hand baggage” which do not entitle the User for
                    free check in baggage and therefore the User will be
                    required to pay separately for check in baggage. The prices
                    for adding check-in baggage to a booking may vary from
                    airline to airline. The User is advised to contact the
                    airlines for detailed costs.
                  </p>
                </div>
              </ShowHide>
            </div>
            <div ref={tc16}>
              <ShowHide visible="true" icon="" title="Pricing">
                <div className="showHide-content">
                  <p>
                    The total price displayed on the Application on the payment
                    page usually includes base fare, applicable government taxes
                    and convenience fee. Users are required to pay the entire
                    amount prior to the confirmation of their booking(s). In the
                    event the User does not pay the entire amount, Clickntravel
                    reserves its right to cancel the booking. User agrees to pay
                    all taxes, surcharges and fees, as applicable on the date of
                    travel.
                  </p>

                  <p>
                    To avail infant fares, the age of the child must be under 24
                    months throughout the entire itinerary. This includes both
                    onward and return journeys. If the infant is 24 months or
                    above on the return journey, User will be required to make a
                    separate booking using a child fare. Any infants or children
                    must be accompanied by an adult as per the terms of the
                    airlines.
                  </p>
                </div>
              </ShowHide>
            </div>
            <div ref={tc17}>
              <ShowHide visible="true" icon="" title="Travel Documents">
                <div className="showHide-content">
                  <p>
                    It shall be the sole responsibility of the User to ensure
                    they are in possession of valid travel documents such as
                    identity proof, passport, visa (including transit visa) etc.
                    to undertake the travel. User agrees that in case of
                    inability to travel for not carrying valid travel documents,
                    Clickntravel shall in no way be held liable.
                  </p>

                  <p>
                    User understands that the information (if any) provided by
                    Clickntravel regarding the travel documents is only advisory
                    in nature and can’t be considered conclusive. The User shall
                    ensure checking the requirements of travel with the
                    respective airlines of the respective jurisdictions the User
                    may transit through or choose to visit.
                  </p>
                </div>
              </ShowHide>
            </div>
            <div ref={tc18}>
              <ShowHide visible="true" icon="" title="Check-In Terms">
                <div className="showHide-content">
                  <p>
                    User should check with the airlines directly regarding the
                    check-in timings. Usually, check-in begins 3 hours before
                    departure for international flights.
                  </p>
                  <p>
                    User should carry valid identification proof, passport,
                    Vaccination certificate, Negative RT-PCR report as may be
                    required to prove the identity, nationality, and readiness
                    of the passengers travelling on a ticket, including infants.
                  </p>
                </div>
              </ShowHide>
            </div>
            <div ref={tc19}>
              <ShowHide visible="true" icon="" title="Use of Flight Segments">
                <div className="showHide-content">
                  <p>
                    In the event User does not embark on the onward journey, the
                    entire PNR pertaining to that booking shall be automatically
                    cancelled by the airline. In such a scenario Clickntravel
                    has no control in the said process nor will be obligated to
                    provide alternate bookings to the User. The cancellation and
                    No-show penalties in such an event shall be as per the
                    applicable airline rules.
                  </p>
                </div>
              </ShowHide>
            </div>
            <div ref={tc20}>
              <ShowHide
                visible="true"
                icon=""
                title="Changes to Existing Booking"
              >
                <div className="showHide-content">
                  <p>
                    Any changes that are made to any existing booking shall be
                    subject to certain charges levied by the respective airline,
                    apart from the service fee charged by Clickntravel.
                  </p>
                  <p>
                    The User shall be obligated to pay applicable charges in the
                    event of any alteration or modification to an existing
                    booking. However, depending on the airline's policy and fare
                    class, charges for changes or modifications to existing
                    bookings may vary.
                  </p>
                </div>
              </ShowHide>
            </div>
            <div ref={tc21}>
              <ShowHide visible="true" icon="" title="Refund">
                <div className="showHide-content">
                  <p>
                    Refunds will be processed as per the airline fare rules and
                    cancellation policy. Such refunds shall be subject to
                    Clickntravel receiving the same from the airlines. However,
                    the convenience fee paid to Clickntravel paid at the time of
                    booking is a non-refundable fee.
                  </p>
                  <p>
                    All cancellations made directly with the airline need to be
                    intimated to Clickntravel, in order to initiate the process
                    of refund. The processing time for refunds may vary
                    depending on the mode of payment and release by partner
                    bank. The refund shall be processed after deducting the
                    Clickntravel service fee which is independent of the
                    convenience fee as mentioned above.{" "}
                  </p>
                  <p>
                    The refund will be credited to the same account from which
                    the payment was made. For example, if the User used a credit
                    card, Clickntravel will make an appropriate charge reversal
                    to the same credit card; like-wise if the User used a debit
                    card, Clickntravel will credit the money to the same debit
                    card.
                  </p>
                  <p>
                    In the event of cancellation and refund of partially
                    utilized tickets, upfront discount and promo code discount
                    availed at the time of booking would be deducted from the
                    refund amount.
                  </p>
                </div>
              </ShowHide>
            </div>
            <div ref={tc22}>
              <ShowHide visible="true" icon="" title="Others">
                <div className="showHide-content">
                  <p>
                    Clickntravel shall not be liable or responsible for any
                    damage or loss, injury, accident, death, irregularity,
                    delay/change in schedule, cancellation without cause,
                    inaccurate information, deficiency of service/product, or
                    cancellation due to conditions not under it’s control.
                  </p>
                </div>
              </ShowHide>
            </div>

            {/* Hotels */}
            <Title level={4} className="tcTitle" style={{marginTop: "2rem"}}>
              Hotels
            </Title>
            <div ref={tc23}>
              <ShowHide
                visible="true"
                icon=""
                title="Terms & Liabilities of Clickntravel"
              >
                <div className="showHide-content">
                  <p>
                    Clickntravel acts as a facilitator and merely provides an
                    online platform to the User to select and book a particular
                    hotel. Hotels in this context includes all categories of
                    accommodations such as hotels, homestays, bed and breakfast
                    stays and any other alternate accommodations.
                  </p>
                  <p>
                    All the information pertaining to the hotel including the
                    category of the hotel, images, room type, amenities and
                    facilities available at the hotel are as per the information
                    provided by the hotel/Partner Agency to Clickntravel. This
                    information is for reference only. Any discrepancy that may
                    exist between the Application pictures and actual settings
                    of the hotel shall be raised by the User with the hotel
                    directly and shall be resolved between the User and hotel.
                    Clickntravel will have no responsibility in that process of
                    resolution and shall not take any liability for such
                    discrepancies.
                  </p>
                  <p>
                    The hotel booking voucher which Clickntravel issues to a
                    User is solely based on the information provided or updated
                    by the hotel/Agency partner regarding the inventory
                    availability. In no circumstances can Clickntravel be held
                    liable for failure on part of a hotel to accommodate the
                    User with a confirmed booking, the standard of service or
                    any insufficiency in the services, or any other
                    service-related issues at the hotel. The liability of
                    Clickntravel in case of denial of check-in by a hotel for
                    any reason what-so-ever including over-booking, system or
                    technical errors, or unavailability of rooms etc., will be
                    limited to either providing a similar alternate
                    accommodation at the discretion of Clickntravel (subject to
                    availability at that time), or refunding the booking amount
                    (to the extent paid) to the User. Any other service-related
                    issues should be directly resolved by the User with the
                    hotel.
                  </p>
                  <p>
                    Hotels reserves the sole right of admission and Clickntravel
                    has no say whatsoever in admission or denial for admission
                    by the hotel. Unmarried or unrelated couples may not be
                    allowed to check-in by some hotels as per their policies.
                    Similarly, accommodation may be denied to guests posing as a
                    couple if suitable proof of identification is not presented
                    at the time check-in. Clickntravel will not be responsible
                    for any check-in denied by the hotel due to the aforesaid
                    reasons or any other reason not under the control of
                    Clickntravel. No refund would be applicable in case the
                    hotel denies check-in under such circumstances.
                  </p>
                  <p>
                    Clickntravel shall not be liable or responsible for any
                    damage or loss, injury, accident, death, irregularity,
                    delay/change in schedule, cancellation without cause,
                    inaccurate information, deficiency of service/product, or
                    cancellation due to conditions not under it’s control.
                  </p>
                </div>
              </ShowHide>
            </div>
            <div ref={tc24}>
              <ShowHide
                visible="true"
                icon=""
                title="Responsibilities of the User"
              >
                <div className="showHide-content">
                  <p>
                    The User would be liable to make good any damage(s) caused
                    by any act of him/ her/ or their accompanying guests
                    (willful/negligent) to the property of the hotel in any
                    manner whatsoever. The extent and the amount of the damage
                    so caused would be determined by the concerned hotel.
                    Clickntravel would not, in any way, intervene in the same.
                  </p>
                  <p>
                    The primary guest must be at least 18 years old to be able
                    to check into the hotel.
                  </p>
                  <p>
                    The User has to be in possession of a valid identity proof
                    and address proof, at the time of check-in. The hotel shall
                    be within its rights to deny check-in to a User if a valid
                    identity proof is not presented at the time of check-in.
                  </p>
                  <p>
                    Check-in time, check-out time, and any changes in those
                    timings, will be as per hotel policy & terms. Early check-in
                    or late check-out request is subject to availability and the
                    hotel may charge an additional fee for providing such
                    services.
                  </p>
                  <p>
                    The booking amount paid by the User is only for stay at the
                    hotel. Some bookings may include breakfast and/ or meals as
                    confirmed at the time of booking. Any other services
                    utilized by the User at the hotel, including laundry, room
                    service, internet, telephone, extra food, drinks, beverages
                    etc. shall be paid by the User directly to the hotel.
                  </p>
                  <p>
                    Any additional charges need to be cleared directly at the
                    hotel. Clickntravel will have no control over waiving the
                    same.
                  </p>
                </div>
              </ShowHide>
            </div>
            <div ref={tc25}>
              <ShowHide visible="true" icon="" title="Mode of Payment">
                <div className="showHide-content">
                  <p>
                    Booking of a hotel can be “Prepaid” as per the option made
                    available by a hotel on the Application of Clickntravel.
                  </p>
                  <p>
                    In “Prepaid” model, the total booking amount is paid by the
                    User at the time of booking itself. Such total booking
                    amount includes the hotel reservation rate, taxes, service
                    fees as may be charged on behalf of the actual service
                    provider, and any additional booking fee or convenience fee
                    charged by Clickntravel. Sometimes additional taxes laid
                    down by the government may be required to pay at the
                    property.
                  </p>
                  <p>
                    Clickntravel is not responsible for any errors, omissions or
                    representations on any of its pages, links or any linked
                    Application pages to the extent such information is updated
                    or provided directly by the Service Providers or the
                    advertisers.
                  </p>
                </div>
              </ShowHide>
            </div>
          </Col>
        </Row>
      </div>
      <Footer />
    </>
  );
}

export default TermsAndConditions;
