import React from "react";
import { useContext } from "react";
import { Tooltip } from "antd";
import { Context } from "../../../../App";

function HotelCancellationPolicy(props) {
  const [context, setContext] = useContext(Context)
  return (
    <>
      {props.isRefund &&
        props.canPolicy !== undefined &&
        props.canPolicy.length !== 0 && (
            <div className="policyCancel">
              <Tooltip placement="bottom" overlayClassName="policyCancelContent" title={<><p>{props.canPolicy[0]}</p><p style={{marginBottom: "0"}}>{props.canPolicy[1]}</p></>}>
              {context.translation && context.translation.canpolicy}
              </Tooltip>
            </div>
        )}
      {!props.isRefund && (
        <div>
          {/* {context.translation && context.translation.canpolicy} */}
          <div className="policyCancel">
            <Tooltip placement="bottom" overlayClassName="policyCancelContent" title={context.translation && context.translation.nonRefundable}>
            {context.translation && context.translation.canpolicy}
            </Tooltip>
          </div>
        </div>
      )}
    </>
  );
}

export default HotelCancellationPolicy;
