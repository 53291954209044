import React, { useEffect, useState,useContext } from "react";
import { Context } from "../../../../App";
import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Select,
  Tag,
  Typography,
  Carousel,
} from "antd";
import Scrollspy from 'react-scrollspy';
import Link from "antd/es/typography/Link";
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from "react-image-gallery";
import ExpediaLogo from "../../../../assets/img/expedia-logo.png";
import Utilities from "../../../utilities/Utilities";
import HotelBrandIcon from "../../../../assets/gif/Forever/White/H_WHITE_FOREVER.gif";

const { Title } = Typography;

export const HTL_REQUEST = "htlRequest";

function HotelBasicDetails(props) {
  const [context, setContext] = useContext(Context)
  const { hotelContentResp } = props;
  const [images, setImages] = useState();
  const [searchRequest, setSearchRequest] = useState(props.request?(typeof props.request === 'object'?props.request:JSON.parse(props.request)):localStorage.getItem(HTL_REQUEST)?JSON.parse(localStorage.getItem(HTL_REQUEST)):'');

  useEffect(() => {
    if(props.request){
      setSearchRequest(typeof props.request === 'object'?props.request:JSON.parse(props.request));
    }
  }, [props.request])
  

  useEffect(() => {
    if (hotelContentResp) {
      setImages(
        hotelContentResp.hImages.map((imageUrl) => ({
          original: imageUrl,
          thumbnail: imageUrl,
        }))
      );
    }
  }, [hotelContentResp]);

  return (
    <>
      <div className="ratingViewsWrapper">
        <div className="container commonFlex">
          <div>
            <div className="onlyFlex hotelnameRating">
              <Title level={4} className="hotelName">
                <Link>{hotelContentResp.htnm}</Link>
              </Title>
              <span className="hotelRating">
                {Utilities.getHotelStartRating(hotelContentResp.rat).map(
                  (star, idx) => (
                    <span key={idx} className="icon icon-star"></span>
                  )
                )}
              </span>
            </div>
            <div className="hotelAddress">
              {hotelContentResp.add}, {hotelContentResp.city},{" "}
              {hotelContentResp.country}
            </div>
          </div>
          <div className="hotelReviews onlyFlex">
            <div className="ratingWrap">
              <div>
                <Title level={5} className="excellent">
                  {hotelContentResp.urat !== undefined &&
                    Utilities.getUserStatus(hotelContentResp.urat)}
                </Title>
                {/* <Title level={5} className="reviews">
                  <img src={ExpediaLogo} className="expediaLogo" />{" "}
                  <span>{hotelContentResp.expediareview} Reviews</span>
                </Title> */}
              </div>
              {hotelContentResp.urat !== "" && <>
              <div className="rating">
                <span>
                  <i className="icon icon-leaf"></i>
                </span>
                {hotelContentResp.urat}
                <span className="right">
                  <i className="icon icon-leaf"></i>
                </span>
              </div>
              </>}
            </div>
            <Scrollspy className="ChooseRoomScroll" items={['chooseRoom']} currentClassName="isCurrent">
              <a href="#chooseRoom"> 
                <Button className="cntBtn hotelBtn" type="primary">
                   {/* Choose Room */}
                   {context.translation && context.translation.chooseRoom}
                    <img src={HotelBrandIcon} alt="hotelBrandIcon" className="hotelBrandIcon" />
                    {/* <i className="icon icon-H-logo"></i> */}
                </Button>
              </a>
            </Scrollspy>
          </div>
        </div>
      </div>
     
    <div className='container'>
      <div className="hotelImagegallery">
        {hotelContentResp.hImages !== undefined && images !== undefined && (
              <ImageGallery
                items={images}
                thumbnailPosition="right"
                showNav={false}
                lazyLoad={true}
                autoPlay={true}
              />
            )}
          <div className='checkInCheckOut'>
            <div className='checkIn'>
              {(sessionStorage.getItem("SEL_LAG") === "English"||sessionStorage.getItem("SEL_LAG") === null) && (
                <>
                <span className='left'>
                  <span className="checkTitle">{context.translation && context.translation.checkindat}</span>
                  <span>{searchRequest?searchRequest.cin:''}, </span>
                </span>
                <span className='right'>{hotelContentResp?hotelContentResp.cin.beginTime:''}</span>
              </>
              )}
              {sessionStorage.getItem("SEL_LAG") === "Arabic" && (
                <>
                <span className='left'>
                  <span className="checkTitle">{context.translation && context.translation.checkindat}</span>
                  <span>{searchRequest?searchRequest.cin:''} </span>
                </span>
                <span className='right' style={{float: "left"}}>{hotelContentResp?hotelContentResp.cin.beginTime:''},</span>
              </>
              )}
            </div>
            <div className='checkOut'>
              {(sessionStorage.getItem("SEL_LAG") === "English"||sessionStorage.getItem("SEL_LAG") === null) && (
                <>
                <span className='left'>
                <span className="checkTitle">{context.translation && context.translation.checkoutdat}</span>
                <span>{searchRequest?searchRequest.cout:''}, </span>
              </span>
              <span className='right'>{hotelContentResp?hotelContentResp.cout.time:''}</span>
              </>
              )}
              {sessionStorage.getItem("SEL_LAG") === "Arabic" && (
                <>
                <span className='left'>
                <span className="checkTitle">{context.translation && context.translation.checkoutdat}</span>
                <span>{searchRequest?searchRequest.cout:''} </span>
              </span>
              <span className='right' style={{float: "left"}}>{hotelContentResp?hotelContentResp.cout.time:''},</span>
              </>
              )}
            </div>
          </div>
      </div>
    </div>
    </>
  );
}

export default HotelBasicDetails;
