import React, { useEffect, useReducer, useState,useContext } from "react";
import { Context } from "../../../../App";
import { Progress } from "antd";
import { getGuestReviews } from "../../../common/services/HotelOperations";
import Utilities from "../../../utilities/Utilities";
import DateUtils from "../../../utilities/DateUtils";

const initialState = {
  guestReviews: [],
  reviewObj: "",
  expRating: 0,
  usrRating: 0,
  response: "",
  isLoading: false,
  notiMessage: "",
  notiMessageShow: false,
  notiVarient: "danger",
};
//=====================================
// Reducer function for userReact Hook
// which will update the state
//=====================================
const reducer = (state, action) => {
  switch (action.type) {
    case "clear":
      return {
        ...state,
      };
    default:
      return { ...state, [action.type]: action.payload };
  }
};

function HotelReviewsAndRatings(props) {
  const [context, setContext] = useContext(Context)
  const { reviewObj, reviewCount, urat, pid, page } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const [cleanless, setCleanless] = useState(0);
  const [service, setService] = useState(0);
  const [amenities, setAmenites] = useState(0);
  const [fecilities, setFacitliies] = useState(0);
  const [showReview, setShowReview] = useState(false);
  const [from, setFrom] = useState(0);
  const [to, setTo] = useState(5);
  const [cleanlessRate, setCleanlessRate] = useState(0);
  const [serviceRate, setServiceRate] = useState(0);
  const [amenitiesRate, setAmenitesRate] = useState(0);
  const [fecilitiesRate, setFacitliiesRate] = useState(0);

  useEffect(() => {
    if(reviewObj!==undefined){
        dispatch({type: "isLoading",payload: true})
        let reviewCont="";
        let pid="";
        if(page==="Results"){
        reviewCont=reviewObj.cont.guestreview;
        pid=reviewObj.pid;
        }else{
        reviewCont=reviewObj;
        pid=props.pid;
        }
        try {
          getGuestReviews(pid).then((response) => {
              if (response.data.suc) {
               dispatch({ type: 'guestReviews', payload: response.data.data }) 
               dispatch({type: "isLoading",payload: false})
              if(reviewCont.cleaniness!==undefined){
                  let val=checProgresBar(reviewCont.cleaniness);
                  setCleanless(val)
                  setCleanlessRate(reviewCont.cleaniness)
                 }
                  if(reviewCont.staff_service!==undefined){
                      let val=checProgresBar(reviewCont.staff_service);
                      setService(val)
                      setServiceRate(reviewCont.staff_service)
                  }
                  if(reviewCont.amenities!==undefined){
                      let val=checProgresBar(reviewCont.amenities);
                      setAmenites(val)
                      setAmenitesRate(reviewCont.amenities)
                  }
                  if(reviewCont.conditions_facilities!==undefined){
                      let val=checProgresBar(reviewCont.conditions_facilities);
                      setFacitliies(val)
                      setFacitliiesRate(reviewCont.conditions_facilities)
                  }
              } else {
              dispatch({ type: "notiMessage", payload: (context.translation && context.translation.guestReview) })
              dispatch({ type: "notiVarient", payload: "danger" })
              dispatch({ type: "notiMessageShow", payload: true })
              dispatch({type: "isLoading",payload: false})
              }
          }).catch(function (error) {
            console.error("Error in Guest Reviews :" + error);      
          });          
        } catch (error) {
          console.error("Error in Guest Reviews :" + error);            
        }
    }
    }, [reviewObj])

    const checProgresBar=(val)=>{
        if(val!==undefined && val!==0){
            let valAray=val.split(".");
            let finalVal=valAray[0]+valAray[1];
            return finalVal*2;
        }else{
            return 0;
        }

    }
    const showtag = () =>{
        if(state.guestReviews.length > to){

            if(state.guestReviews.length <= (to + 5)) {
                setShowReview(true)
            } else {
                setShowReview(false)
            }
            setTo(to + 5)

        }else{
            if(showReview) {
                setShowReview(false)
            } else {
                setShowReview(true)
            }
            setFrom(0)
            setTo(5)
        }
    }

  return (
    <div className="hotelReviewsAndRatingsWrapper hrsWrapper">
      <div className="hotelReviewsAndRatings">
        <div className="excellentRating">
          <div className="ratingValue">{urat}</div>
          <div className="outOfFull">{context.translation && context.translation.outoffive}</div>
          <div className="ratingTitle">{urat!==undefined && Utilities.getUserStatus(urat)}</div>
          <div className="reviewsBasedOn">{context.translation && context.translation.basedOn} {reviewCount} {context.translation && context.translation.reviews}</div>
        </div>
        <div className="leftProgressbar">
          <div className="progressBarWrapper">
            <div className="progressBarTitle">{context.translation && context.translation.serviceQuality}</div>
            <div className="onlyFlex">
              <Progress percent={90} showInfo={false} className="progressBar" />
              <span className="progressBarValue">{serviceRate}</span>
            </div>
          </div>
          <div className="progressBarWrapper">
            <div className="progressBarTitle">{context.translation && context.translation.location}</div>
            <div className="onlyFlex">
              <Progress percent={70} showInfo={false} className="progressBar" />
              <span className="progressBarValue">{fecilitiesRate}</span>
            </div>
          </div>
          <div className="progressBarWrapper">
            <div className="progressBarTitle">{context.translation && context.translation.cleanlinss}</div>
            <div className="onlyFlex">
              <Progress percent={60} showInfo={false} className="progressBar" />
              <span className="progressBarValue">{cleanlessRate}</span>
            </div>
          </div>
        </div>
        <div className="leftProgressbar rightProgressbar">
          <div className="progressBarWrapper">
            <div className="progressBarTitle">{context.translation && context.translation.amenities}</div>
            <div className="onlyFlex">
              <Progress percent={90} showInfo={false} className="progressBar" />
              <span className="progressBarValue">{amenitiesRate}</span>
            </div>
          </div>
        </div>
      </div>
      {state.guestReviews.length > 0 && state.guestReviews.map((review, index) => (
         <div className="reviewsWithIcon" key={index}>
         <div className="contentWrapper">
           <div className="leftIcon">{review.reviewer_name[0].toUpperCase()}</div>
           <div className="reviewName">{review.reviewer_name}</div>
           {review.text!==undefined && review.text!=="" &&
                  <div className="longReview">{review.text}</div>
                  }
           <div className="reviewedDate">{context.translation && context.translation.reviewed} {DateUtils.prettyDate(review.date_submitted,'ddd, DD MMM, YYYY')}</div>
         </div>
       </div> 
      )).slice(from, to )}
      
      {state.guestReviews.length >=6 ?
        <span className="seeMoreReviews" onClick={() => showtag()}>
            {showReview ? <span className="seeMoreReviews">Show Less Reviews </span> : <span className="seeMoreReviews">Show More Reviews </span>}
        </span> :''
      }
    </div>
  );
}

export default HotelReviewsAndRatings;
