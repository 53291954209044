import { Col, Row, Typography } from "antd";
import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import Logo from "../../assets/gif/Forever/Coloured/CLICK-N-TRAVEL_FOREVER.gif";
import CMSService from "../../services/cms/CMSService";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Context } from "../../App";
import Utilities from "../utilities/Utilities";
export const REGION_COUNTRY = "REGION_COUNTRY";

const { Title } = Typography;

function Recommendations(props) {
  const [context, setContext] = useContext(Context);
  const [res, setRes] = useState([]);

  useEffect(() => {
    const payload = { 
      region:
        localStorage.getItem(REGION_COUNTRY) === "IN"
          ? "India"
          : "Qatar",
      channel: "B2C", 
      status: "Active" 
    }
    let tokenKey = Utilities.generateAPIToken(payload);
    CMSService.loadRecommendations(payload,tokenKey)
      .then((response) => {
        if (response.data.suc && response.data.res) {
          setRes(response.data.res[0].rec_images_list);
        }
      })
      .catch(function (error) {
        console.error("Recommendations response error ", error);
      });
  }, []);

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", background: "#fff", boxShadow: "0px 6px 30px #00000029" }}
        onClick={onClick}
      />
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", background: "#fff", boxShadow: "0px 6px 30px #00000029" }}
        onClick={onClick}
      />
    );
  }

  var settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    rtl: false,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 2,
          centerMode: true,
          centerPadding: "30px 20px",
          slidesToScroll: 1,
          nextArrow: false,
          prevArrow: false,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "30px 20px",
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <>
    
      {res.length >= 3 && (
        <div className="container recommendations">
          <Title level={2} className="cust-title">
            <img src={Logo} alt="Click N Travel" className="brandLogo" />{" "}
            <span>
              {/* Recommendations */}
              {context.translation && context.translation.recommendation}
            </span>
          </Title>
          
          <Slider {...settings} className="cntSlider cntHomepage">
              {res.map((data, index) => {
                return (
                  <div key={index}>
                    <Link>
                      <img
                        src={props.enableInd ? data.url_en : data.url_ar}
                        alt="Recmnd1"
                        className="sliderImageRecomm"
                      />
                    </Link>
                  </div> 
                  )
              })}
          </Slider>
          

        
          {/* {res.length === 0 && (
            <Title level={3} align="center">
              <span>No data found</span>
            </Title>
          )} */}
        </div>
      )}
    </>
  );
}

export default Recommendations;
