import React, { useEffect, useState, useReducer,useContext } from "react";
import { Context } from "../../App";
import { Radio, Row, Col, Checkbox, Input, Button, Alert } from "antd";
import { isArrayNotEmpty } from "../utilities/validators";
import { postflowOperations } from "./operations";
import Utilities from "../utilities/Utilities";

const _ = require("lodash");

/**
 * Initial State Declaration
 */
const initialState = {
  notiMessage: "",
  notiMessageShow: false,
  notiVarient: "",
};

//=====================================
// Reducer function for UserAction
// which will update the state
//=====================================
const reducer = (state, action) => {
  switch (action.type) {
    default:
      return { ...state, [action.type]: action.payload };
  }
};

function CancelFlightTrip({ cancelData, close }) {
  const [context, setContext] = useContext(Context);
  const [state, dispatch] = useReducer(reducer, initialState);
  const [sectors, setSectors] = useState([]);
  const [selectedRadio, setSelectedRadio] = useState("FC");
  const [selectedItems, setSelectedItems] = useState([0]);
  const [selectedRow, setSelectedRow] = useState(1); // Default selected row
  const [remarksFC, setRemarksFC] = useState([]);
  const [remarksPC, setRemarksPC] = useState([]);
  const [remarksCT, setRemarksCT] = useState([]);
  const [email, setEmail] = useState("");

  useEffect(() => {
    constructSectors();
  }, []);

  const constructSectors = () => {
    // Initialize the result array
    var sec = [];
    var tt = parseInt(cancelData.tt);
    if (tt === 1 || tt === 2 || tt === 3) {
      cancelData.origns[0].srcorigns.forEach((srcorign, index) => {
        var src = srcorign.seg[0].da;
        var des = srcorign.seg[srcorign.seg.length - 1].ar;
        var psngrs = tt === 3 ? cancelData.psngrs : cancelData.psngrs.filter((pax) => pax.tid === srcorign.tid);
        var route =
          srcorign.seg[0].da + "-" + srcorign.seg[srcorign.seg.length - 1].ar;
        var office_id = srcorign.office_id;
        var tid = srcorign.tid;
        var re = srcorign.re;
        // var st = "Confirmed";
        var st = cancelData.sty === "split" ? cancelData.psngrs.filter((pax) => pax.tid === srcorign.tid)[0].tktst : "Confirmed";        
        var pnr = cancelData.flt_b_data[0].pnr;
        var air_pnr = cancelData?.flt_d_data[0]?.airline_pnr;
        sec.push({
          src,
          des,
          leg: index + 1, // Increment leg number
          office_id,
          psngrs,
          tid,
          route,
          re,
          st,
          pnr,
          air_pnr,
        });
      });
      if (tt === 2 || cancelData.sty === "split") {
        cancelData.origns[0].destorigins.forEach((destorigin, index) => {
          var src = destorigin.seg[0].da;
          var des = destorigin.seg[destorigin.seg.length - 1].ar;
          var psngrs = cancelData.sty === "split" ? (cancelData.psngrs.filter((pax) => pax.tid === destorigin.tid)) : cancelData.psngrs;
          var route =
            destorigin.seg[0].da +
            "-" +
            destorigin.seg[destorigin.seg.length - 1].ar;
          var office_id = destorigin.office_id;
          var tid = destorigin.tid;
          var re = destorigin.re;
          // var st = "Confirmed";
          var st = cancelData.sty === "split" ? cancelData.psngrs.filter((pax) => pax.tid === destorigin.tid)[0].tktst : "Confirmed";  
          var pnr = cancelData?.flt_b_data.length === 1 ? cancelData?.flt_b_data[0]?.pnr : cancelData?.flt_b_data[1]?.pnr;
          var air_pnr = cancelData?.flt_d_data[cancelData?.flt_d_data.length - 1]?.airline_pnr;
          // Push the data into the "sec" array
          sec.push({
            src,
            des,
            leg: cancelData.origns[0].srcorigns.length + index + 1, // Increment leg number
            office_id,
            psngrs,
            tid,
            route,
            re,
            st,
            pnr,
            air_pnr,
          });
        });
      }
    }
    if (isArrayNotEmpty(sec)) {
      setSectors(sec);
    }

    const data = JSON.parse(localStorage.getItem("SIGNINRESPONSE"));
    if (data !== null) {
      setEmail(data.res.email);
    }
  };

  const handleRadioChange = (e) => {
    setSelectedRadio(e.target.value);
  };

  // State to track selected checkboxes
  const [selectedCheckboxes, setSelectedCheckboxes] = useState({
    // Initial checkbox of first passengers array
    0: [0],
  });

  // Function to handle changes in nested checkboxes
  const handleNestedChange = (parentIndex, nestedIndex) => {
    setSelectedCheckboxes((prevSelected) => {
      const updatedSelected = { ...prevSelected };
      if (!updatedSelected[parentIndex]) {
        updatedSelected[parentIndex] = [];
      }

      const nestedIndexInArray =
        updatedSelected[parentIndex].indexOf(nestedIndex);

      if (nestedIndexInArray === -1) {
        updatedSelected[parentIndex].push(nestedIndex);
      } else {
        updatedSelected[parentIndex].splice(nestedIndexInArray, 1);
      }

      return updatedSelected;
    });
  };

  const handlePartialCancel = (e) => {
    setSelectedRow(e.target.value);
  };

  const handleRemarksChange = (type) => (e) => {
    // const newText = e.target.value;
    // setRemarks([...remarks, newText]);
    if (type === "FC") {
      setRemarksFC([e.target.value]);
    }
    if (type === "PC") {
      setRemarksPC([e.target.value]);
    }
    if (type === "CT") {
      setRemarksCT([e.target.value]);
    }
  };

  const backToNormal = () => {
    close(false);
  };

  const submitCancel = (type) => {
    let payload;
    let sectsCopy = _.cloneDeep(sectors);
    let sects = sectsCopy?.map((sec) => {
      delete sec.psngrs;
      delete sec.src;
      delete sec.des;
      delete sec.leg;
      delete sec.re;
      return sec;
    });
    let filterRefundable = sects?.filter((sec) => {
      return sec.re !== "Non-Refundable" && sec.st == "Confirmed";
    });
    // let tickets;
    // if(cancelData.sup == 4) {
    //   tickets = cancelData.psngrs?.map((ticket) => {
    //    return [ticket.tkt, ticket.amedeus_ref_num];
    //  });
    // } else {
    //    tickets = cancelData.psngrs?.map((ticket) => {
    //     return ticket.tkt;
    //   });
    // }    
    let passengesNames = []
    cancelData.psngrs?.map((ticket) => {
      if(ticket.inf !== "" && ticket.inf !== undefined) {
        passengesNames.push(ticket.fn+"-"+ticket.ln);
        passengesNames.push(ticket.inf.fn+"-"+ticket.inf.ln);
      } else {
        passengesNames.push(ticket.fn+"-"+ticket.ln);
      }
    });
    try {
      if (type === "FC") {
        payload = {
          action: "cancellation",
          j_type: cancelData.tt == 2 ? "O/R" : "O",
          email_id: email,
          region_type: cancelData.region_type,
          action_type: type,
          sty: cancelData.sty,
          tid: cancelData.tid,
          trip_type: cancelData.tt,
          booking_id: cancelData.booking_id,
          cid: cancelData.cid,
          sup: cancelData.sup,
          // tkts: tickets.length === 1 ? ((tickets[0] === null || tickets[0] === "" || tickets[0] === undefined) ? [] : tickets) : tickets[0].concat(tickets[1]).filter(t => t!==null && t!=="" && t!==undefined),
          tkts: passengesNames,
          psngr_det: cancelData.psngr_det,
          psngrs: cancelData.psngrs,
          Cancelledpsngrs : cancelData.psngrs, 
          sectors: filterRefundable,
          remarks: remarksFC,
          origns: cancelData.origns,
          mappings: cancelData.mappings
        };
      }
      if (type === "PC") {
        if(cancelData.tt == 3) {
          payload = {
            action: "cancellation",
            j_type: "O",
            email_id: email,
            region_type: cancelData.region_type,
            action_type: type,
            sty: cancelData.sty,
            tid: cancelData.tid,
            trip_type: cancelData.tt,
            booking_id: cancelData.booking_id,
            cid: cancelData.cid,
            sup: cancelData.sup,
            // tkts: tickets.length === 1 ? ((tickets[0] === null || tickets[0] === "" || tickets[0] === undefined) ? [] : tickets) : tickets[0].concat(tickets[1]).filter(t => t!==null && t!=="" && t!==undefined),
            tkts: passengesNames,
            psngr_det: cancelData.psngr_det,
            psngrs: cancelData.psngrs,
            Cancelledpsngrs: cancelData.psngrs,
            sectors: [sects[selectedRow - 1]],
            remarks: remarksPC,
            origns: [{srcorigns: [cancelData.origns[0].srcorigns[selectedRow - 1]]}],
            mappings: cancelData.mappings
          };
        } else {
          payload = {
            action: "cancellation",
            j_type: selectedRow == 1 ? "O" : "R",
            email_id: email,
            region_type: cancelData.region_type,
            action_type: type,
            sty: cancelData.sty,
            tid: cancelData.tid,
            trip_type: cancelData.tt,
            booking_id: cancelData.booking_id,
            cid: cancelData.cid,
            sup: cancelData.sup,
            // tkts: tickets.length === 1 ? ((tickets[0] === null || tickets[0] === "" || tickets[0] === undefined) ? [] : tickets) : tickets[0].concat(tickets[1]).filter(t => t!==null && t!=="" && t!==undefined),
            tkts: passengesNames,
            psngr_det: cancelData.psngr_det,
            psngrs: cancelData.psngrs,
            Cancelledpsngrs: cancelData.sty !== "split" ? cancelData.psngrs : cancelData.psngrs.filter((psn) => psn.tid === (selectedRow == 1 ? cancelData.origns[0].srcorigns[0].tid : cancelData.origns[0].destorigins[0].tid)),
            sectors: [sects[selectedRow - 1]],
            remarks: remarksPC,
            origns: selectedRow == 1 ? [{srcorigns: [cancelData.origns[0].srcorigns[0]]}] : [{destorigns: [cancelData.origns[0].destorigins[0]]}],
            mappings: cancelData.mappings
          };
        }
      }
      if (type === "CT") {
        let sectsCTCopy = _.cloneDeep(sectors);

        let secCombine = [
          selectedCheckboxes[0] !== undefined &&
            selectedCheckboxes[0]?.length !== 0 &&
            sectsCTCopy[0],
          selectedCheckboxes[1] !== undefined &&
            selectedCheckboxes[1]?.length !== 0 &&
            sectsCTCopy[1],
          selectedCheckboxes[2] !== undefined &&
            selectedCheckboxes[2]?.length !== 0 &&
            sectsCTCopy[2],
          selectedCheckboxes[3] !== undefined &&
            selectedCheckboxes[3]?.length !== 0 &&
            sectsCTCopy[3],
          selectedCheckboxes[4] !== undefined &&
            selectedCheckboxes[4]?.length !== 0 &&
            sectsCTCopy[4],
          selectedCheckboxes[5] !== undefined &&
            selectedCheckboxes[5]?.length !== 0 &&
            sectsCTCopy[5],
          selectedCheckboxes[6] !== undefined &&
            selectedCheckboxes[6]?.length !== 0 &&
            sectsCTCopy[6],
          selectedCheckboxes[7] !== undefined &&
            selectedCheckboxes[7]?.length !== 0 &&
            sectsCTCopy[7],
        ];

        var filterSectorCombine = secCombine.filter(
          (obj) => obj !== undefined && obj !== false
        );

        let sectorsCT = filterSectorCombine?.map((sec) => {
          delete sec.psngrs;
          delete sec.src;
          delete sec.des;
          delete sec.leg;
          delete sec.re;
          return sec;
        });

        let ticketsCT = cancelData.psngrs;
        const tkCTOne =
          selectedCheckboxes[0]?.length !== 0 &&
          selectedCheckboxes[0]?.map((t, i) => ticketsCT[t].fn+"-"+ticketsCT[t].ln);
        const tkCTTwo =
          selectedCheckboxes[1]?.length !== 0 &&
          selectedCheckboxes[1]?.map((t, i) => ticketsCT[t].fn+"-"+ticketsCT[t].ln);
        const tkCTThree =
          selectedCheckboxes[2]?.length !== 0 &&
          selectedCheckboxes[2]?.map((t, i) => ticketsCT[t].fn+"-"+ticketsCT[t].ln);
        const tkCTFour =
          selectedCheckboxes[3]?.length !== 0 &&
          selectedCheckboxes[3]?.map((t, i) => ticketsCT[t].fn+"-"+ticketsCT[t].ln);
        const tkCTFive =
          selectedCheckboxes[4]?.length !== 0 &&
          selectedCheckboxes[4]?.map((t, i) => ticketsCT[t].fn+"-"+ticketsCT[t].ln);
        const tkCTSix =
          selectedCheckboxes[5]?.length !== 0 &&
          selectedCheckboxes[5]?.map((t, i) => ticketsCT[t].fn+"-"+ticketsCT[t].ln);
        const tkCTSeven =
          selectedCheckboxes[6]?.length !== 0 &&
          selectedCheckboxes[6]?.map((t, i) => ticketsCT[t].fn+"-"+ticketsCT[t].ln);

        const tkCT = [].concat(
          tkCTOne,
          tkCTTwo,
          tkCTThree,
          tkCTFour,
          tkCTFive,
          tkCTSix,
          tkCTSeven
        );
        const filtertkCT = tkCT.filter((t) => t !== undefined);

        let cnclticketsCT = cancelData.psngrs;
        const cncltkCTOne =
          selectedCheckboxes[0]?.length !== 0 &&
          selectedCheckboxes[0]?.map((t, i) => cnclticketsCT[t]);
        const cncltkCTTwo =
          selectedCheckboxes[1]?.length !== 0 &&
          selectedCheckboxes[1]?.map((t, i) => cnclticketsCT[t]);
        const cncltkCTThree =
          selectedCheckboxes[2]?.length !== 0 &&
          selectedCheckboxes[2]?.map((t, i) => cnclticketsCT[t]);
        const cncltkCTFour =
          selectedCheckboxes[3]?.length !== 0 &&
          selectedCheckboxes[3]?.map((t, i) => cnclticketsCT[t]);
        const cncltkCTFive =
          selectedCheckboxes[4]?.length !== 0 &&
          selectedCheckboxes[4]?.map((t, i) => cnclticketsCT[t]);
        const cncltkCTSix =
          selectedCheckboxes[5]?.length !== 0 &&
          selectedCheckboxes[5]?.map((t, i) => cnclticketsCT[t]);
        const cncltkCTSeven =
          selectedCheckboxes[6]?.length !== 0 &&
          selectedCheckboxes[6]?.map((t, i) => cnclticketsCT[t]);

        const cncltkCT = [].concat(
          cncltkCTOne,
          cncltkCTTwo,
          cncltkCTThree,
          cncltkCTFour,
          cncltkCTFive,
          cncltkCTSix,
          cncltkCTSeven
        );
        const cnclfiltertkCT = cncltkCT.filter((t) => t !== undefined);
        
        if(cancelData.tt == 3) {
          let cncloriginsCT = cancelData.origns[0];
          const cnclsrcoriginOne =
            selectedCheckboxes[0]?.length !== 0 && cncloriginsCT.srcorigns[0];
          const cnclsrcoriginTwo =
            selectedCheckboxes[1]?.length !== 0 && cncloriginsCT.srcorigns[1];
          const cnclsrcoriginThree =
            selectedCheckboxes[2]?.length !== 0 && cncloriginsCT.srcorigns[2];
          const cnclsrcoriginFour =
            selectedCheckboxes[3]?.length !== 0 && cncloriginsCT.srcorigns[3];
          const cnclsrcoriginFive =
            selectedCheckboxes[4]?.length !== 0 && cncloriginsCT.srcorigns[4];
          const cnclsrcoriginSix =
            selectedCheckboxes[5]?.length !== 0 && cncloriginsCT.srcorigns[5];

          const cnclorgCT = [].concat(
            cnclsrcoriginOne,
            cnclsrcoriginTwo,
            cnclsrcoriginThree,
            cnclsrcoriginFour,
            cnclsrcoriginFive,
            cnclsrcoriginSix
          );

          const cnclfilterorignsCT = cnclorgCT.filter((t) => t !== undefined);
          
          payload = {
            action: "cancellation",
            j_type: "O",
            email_id: email,
            region_type: cancelData.region_type,
            action_type: type,
            sty: cancelData.sty,
            tid: cancelData.tid,
            trip_type: cancelData.tt,
            booking_id: cancelData.booking_id,
            cid: cancelData.cid,
            sup: cancelData.sup,
            // tkts: (filtertkCT[0] === null || filtertkCT[0] === "" || filtertkCT[0] === undefined) ? [] : filtertkCT,
            tkts: filtertkCT,
            psngr_det: cancelData.psngr_det,
            psngrs: cancelData.psngrs,
            Cancelledpsngrs: cnclfiltertkCT,
            sectors: sectorsCT,
            remarks: remarksCT,
            origns: [{srcorigns: cnclfilterorignsCT}],
            mappings: cancelData.mappings
          };
        } else {
          let cncloriginsCT = cancelData.origns[0];
          const cnclsrcorigin =
          selectedCheckboxes[0] === undefined ? undefined : (selectedCheckboxes[0]?.length !== 0 && cncloriginsCT.srcorigns);
          const cncldestorigin =
          selectedCheckboxes[1] === undefined ? undefined : (selectedCheckboxes[1]?.length !== 0 && cncloriginsCT.destorigins);

          const cnclsrcorgCT = [].concat(
            cnclsrcorigin
          );

          const cncldestorgCT = [].concat(
            cncldestorigin
          );

          const cnclfiltersrcorignsCT = cnclsrcorgCT.filter((t) => t !== undefined);
          const cnclfilterdestorignsCT = cncldestorgCT.filter((t) => t !== undefined);

          payload = {
            action: "cancellation",
            j_type: selectedCheckboxes[0]?.length !== 0 && selectedCheckboxes[1]?.length !== 0 ? "O/R" : selectedCheckboxes[0]?.length !== 0  ? "O" : selectedCheckboxes[1]?.length !== 0 && "R",
            email_id: email,
            region_type: cancelData.region_type,
            action_type: type,
            sty: cancelData.sty,
            tid: cancelData.tid,
            trip_type: cancelData.tt,
            booking_id: cancelData.booking_id,
            cid: cancelData.cid,
            sup: cancelData.sup,
            tkts: (filtertkCT[0] === null || filtertkCT[0] === "") ? [] : filtertkCT,
            psngr_det: cancelData.psngr_det,
            psngrs: cancelData.psngrs,
            Cancelledpsngrs: cnclfiltertkCT,
            sectors: sectorsCT,
            remarks: remarksCT,
            origns: [cnclfiltersrcorignsCT.length !==0 && {srcorigns: cnclfiltersrcorignsCT}, cnclfilterdestorignsCT.length !==0 && {destorigns: cnclfilterdestorignsCT}],
            mappings: cancelData.mappings
          };
        }
      }
      //Call Cancel Ticket API
      let tokenKey = Utilities.generateAPIToken(payload);
      postflowOperations(payload,tokenKey).then((response) => {
        if (response?.suc) {
          dispatch({ type: "notiMessageShow", payload: true });
          dispatch({
            type: "notiMessage",
            payload: response.res,
          });
          dispatch({ type: "notiVarient", payload: "success" });
          hidemessage();
          setTimeout(() => {
            backToNormal();
          }, 3000);
        } else {
          dispatch({ type: "notiMessageShow", payload: true });
          dispatch({
            type: "notiMessage",
            payload: response?.err?.message,
          });
          dispatch({ type: "notiVarient", payload: "error" });
          hidemessage();
          setTimeout(() => {
            backToNormal();
          }, 3000);
        }
      }).catch(function (error) {
        console.error("Error:" + error);      
      });
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const hidemessage = () => {
    setTimeout(function () {
      dispatch({ type: "notiMessageShow", payload: false });
      dispatch({ type: "notiMessage", payload: "" });
      dispatch({ type: "notiVarient", payload: "" });
    }, 3000);
  };

  return (
    <>
      <div className="horizontalDivider"></div>
      <div>
        {state.notiMessageShow ? (
          <Alert
            description={state.notiMessage}
            type={state.notiVarient}
            closable
            onClose={() =>
              dispatch({ type: "notiMessageShow", payload: false })
            }
            className="alertForm"
            // showIcon
          >
            {" "}
            {state.notiMessage}
          </Alert>
        ) : (
          ""
        )}
        <Radio.Group
          onChange={handleRadioChange}
          value={selectedRadio}
          className="cancellationWrap custumRadio"
        >
          <Radio value="FC" className="cancellationType">
            {context.translation && context.translation.fullCanc}
          </Radio>
          {cancelData && sectors && sectors.length > 1 && (
            <Radio value="PC" className="cancellationType">
              {context.translation && context.translation.partial}
            </Radio>
          )}
          {/* {cancelData &&
            sectors &&
            sectors.length === 1 &&
            sectors[0].psngrs.length > 1 && (
              <Radio value="CT" className="cancellationType">
                {context.translation && context.translation.canBytrav}
              </Radio>
            )}
          {cancelData &&
            sectors &&
            sectors.length === 2 &&
            (sectors[0].psngrs.length > 1 || sectors[1].psngrs.length > 1) && (
              <Radio value="CT" className="cancellationType">
                {context.translation && context.translation.canBytrav}
              </Radio>
            )}
          {cancelData &&
            sectors &&
            sectors.length === 3 &&
            (sectors[0].psngrs.length > 1 ||
              sectors[1].psngrs.length > 1 ||
              sectors[2].psngrs.length > 1) && (
              <Radio value="CT" className="cancellationType">
                {context.translation && context.translation.canBytrav}
              </Radio>
            )}
          {cancelData &&
            sectors &&
            sectors.length === 4 &&
            (sectors[0].psngrs.length > 1 ||
              sectors[1].psngrs.length > 1 ||
              sectors[2].psngrs.length > 1 ||
              sectors[3].psngrs.length > 1) && (
              <Radio value="CT" className="cancellationType">
                {context.translation && context.translation.canBytrav}
              </Radio>
            )} */}
        </Radio.Group>

        {selectedRadio === "FC" && (
          <div>
            <div className="tableScroll">
              <div className="tableWrap">
              <Row className="tableHeader">
              <Col span={2} className="tableValue"></Col>
              <Col span={6} className="tableValue">
                {context.translation && context.translation.sectors}
              </Col>
              <Col span={6} className="tableValue">
                {context.translation && context.translation.travType}
              </Col>
              <Col span={4} className="tableValue">
                {context.translation && context.translation.trvType}
              </Col>
              <Col span={3} className="tableValue">
                {context.translation && context.translation.pnr}
              </Col>
              <Col span={3} className="tableValue">
                {context.translation && context.translation.status}
              </Col>
            </Row>
            <div>
              {cancelData &&
                sectors &&
                sectors.map((sec, idx) => (
                  <Row key={idx} className={(sec.re === "Non-Refundable") || (sec.psngrs[0]?.tktst?.toUpperCase() !== "CONFIRMED" && sec.psngrs[1]?.tktst?.toUpperCase() !== "CONFIRMED") ? "tableData alignPaxDetails disabledText" : "tableData alignPaxDetails"} >
                    <Col span={2} className="tableValue">
                      <Radio.Group
                        value="sector"
                        disabled={(sec.re === "Non-Refundable") || (sec.psngrs[0]?.tktst?.toUpperCase() !== "CONFIRMED" && sec.psngrs[1]?.tktst?.toUpperCase() !== "CONFIRMED") ? true : false}
                      >
                        <Radio value="sector"></Radio>
                      </Radio.Group>
                    </Col>
                    <Col span={6} className="tableValue">
                      {sec.src} - {sec.des}
                    </Col>
                    <Col span={6} className="tableValue">
                      {sec.psngrs.map((pax, index) => (
                        <React.Fragment key={index}>
                          <div className="paxFullName">{pax.fn} {pax.ln}</div>
                        
                          {pax.inf !== undefined && pax.inf !== "" && (
                            <>
                              <div className="paxFullName">{pax.inf.fn} {pax.inf.ln}</div>
                             
                            </>
                          )}
                        </React.Fragment>
                      ))}
                    </Col>
                    <Col span={4} className="tableValue">
                      {sec.psngrs.map((pax, index) => (
                        <React.Fragment key={index}>
                          <div>{pax.ty}</div>
                       
                          {pax.inf !== undefined && pax.inf !== "" && (
                            <>
                              <div>{pax.inf.ty}</div>
                         
                            </>
                          )}
                        </React.Fragment>
                      ))}
                    </Col>
                    <Col span={3} className="tableValue">
                      {sec.pnr !== "" && sec.pnr !== undefined ? sec.pnr : "N/A"}
                    </Col>
                    <Col span={3} className="tableValue active">
                      {sec.psngrs.map((pax, index) => (
                        <React.Fragment key={index}>
                          <div>{pax.tktst}</div>  
                            {pax.inf!=="" && pax.inf!==undefined &&   
                              <>
                              {pax.inf.tktst?pax.inf.tktst:pax.tktst} 
                              </>
                            }                     
                        </React.Fragment>
                      ))}
                    </Col>
                  </Row>
                ))}
            </div>
              </div>
            </div>
           
            <Input.TextArea
              rows={3}
              placeholder={context.translation && context.translation.remarks}
              onChange={handleRemarksChange(selectedRadio)}
              value={remarksFC[remarksFC.length - 1]}
              className="remarkArea"
            />
            <p className="noteWrap"> <span className="notetitle">{context.translation && context.translation.note}</span>  {context.translation && context.translation.willconnect} </p>
            <div className="buttonWrap">
              <Button onClick={backToNormal} className="backBtn">
                {context.translation && context.translation.cancel}
              </Button>
              {cancelData.tt == 1 && cancelData.sty !== "split" ? (
                <Button
                  type="primary"
                  onClick={() => submitCancel(selectedRadio)}
                  disabled={
                    (sectors[0]?.psngrs.filter((pax) => pax.tktst == "Confirmed")
                      .length === 0 || sectors.filter((sec) => sec.re !== "Non-Refundable")
                      .length === 0)
                      ? true
                      : false
                  }
                  className="cntBtn"
                >
                  {context.translation && context.translation.reqCan}
                </Button>
              ) : (
                <>
                {cancelData.tt == 2 || cancelData.sty === "split" && (
                  <Button
                    type="primary"
                    onClick={() => submitCancel(selectedRadio)}
                    disabled={
                      ((sectors[0]?.psngrs.filter((pax) => pax.tktst == "Confirmed")
                        .length === 0 && sectors[1]?.psngrs.filter((pax) => pax.tktst == "Confirmed")
                        .length === 0) || sectors.filter((sec) => sec.re !== "Non-Refundable")
                        .length === 0)
                        ? true
                        : false
                    }
                    className="cntBtn"
                  >
                    {context.translation && context.translation.reqCan}
                  </Button>
                )}
                </>
              )}
            </div>
          </div>
        )}
        {selectedRadio === "PC" && (
          <div>
            <div className="tableScroll">
              <div className="tableWrap">
                <Row className="tableHeader">
                <Col span={2} className="tableValue"></Col>
                <Col span={6} className="tableValue">
                  {context.translation && context.translation.sectors}
                </Col>
                <Col span={6} className="tableValue">
                  {context.translation && context.translation.travType}
                </Col>
                <Col span={4} className="tableValue">
                  {context.translation && context.translation.trvType}
                </Col>
                <Col span={3} className="tableValue">
                  {context.translation && context.translation.pnr}
                </Col>
                <Col span={3} className="tableValue">
                  {context.translation && context.translation.status}
                </Col>
                </Row>
                <div className="partialCancellation">
                  {cancelData && sectors && (
                    <Radio.Group onChange={handlePartialCancel} value={selectedRow}>
                      {sectors.map((sec, idx) => (
                        <React.Fragment key={idx}>
                          <Radio
                            value={idx + 1}
                            disabled={(sec.re === "Non-Refundable") || (cancelData.psngrs[idx]?.tktst?.toUpperCase() !== "CONFIRMED") ? true : false}
                          >
                            <Row className={(sec.re === "Non-Refundable") || (cancelData.psngrs[idx]?.tktst?.toUpperCase() !== "CONFIRMED") ? "tableData alignPaxDetails disabledText" : "tableData alignPaxDetails"} >
                              <Col span={2} className="tableValue">
                              
                              </Col>
                              <Col span={6} className="tableValue">
                                {sec.src} - {sec.des}
                              </Col>
                              <Col span={6} className="tableValue">
                                {sec.psngrs.map((pax, index) => (
                                  <React.Fragment key={index}>
                                    <div className="paxFullName">{pax.fn} {pax.ln}</div>
                                    
                                    {pax.inf !== undefined && pax.inf !== "" && (
                                      <>
                                        <div className="paxFullName">{pax.inf.fn} {pax.inf.ln}</div>
                                      </>
                                    )}
                                    
                                  </React.Fragment>
                                ))}
                              </Col>
                              <Col span={4} className="tableValue">
                                {sec.psngrs.map((pax, index) => (
                                  <React.Fragment key={index}>
                                    <div>{pax.ty}</div>
                                    
                                    {pax.inf !== undefined && pax.inf !== "" && (
                                      <><div>{pax.inf.ty}</div></>
                                    )}
                                    
                                  </React.Fragment>
                                ))}
                              </Col>
                              <Col span={3} className="tableValue">
                                {sec.pnr !== "" ? sec.pnr : "N/A"}
                              </Col>                          
                              <Col span={3} className="tableValue active">
                                {sec.psngrs.map((pax, index) => (
                                  <React.Fragment key={index}>
                                    <div>{pax.tktst}</div>
                                    {pax.inf!=="" && pax.inf!==undefined &&   
                                      <>
                                      {pax.inf.tktst?pax.inf.tktst:pax.tktst} 
                                      </>
                                    }     
                                  </React.Fragment>
                                ))}
                              </Col>
                            </Row>
                          </Radio>
                        </React.Fragment>
                      ))}
                    </Radio.Group>
                  )}
                </div>
              </div>
            </div>
            
            <Input.TextArea
              rows={4}
              placeholder={context.translation && context.translation.remarks}
              onChange={handleRemarksChange(selectedRadio)}
              value={remarksPC[remarksPC.length - 1]}
              className="remarkArea"
            />
            <p className="noteWrap"> <span className="notetitle">{context.translation && context.translation.note}</span>  {context.translation && context.translation.willconnect} </p>
            <div className="buttonWrap">
              <Button onClick={backToNormal} className="backBtn">
                {context.translation && context.translation.cancel}
              </Button>
              {cancelData.tt == 1 && cancelData.sty !== "split" ? (
                <Button
                  type="primary"
                  onClick={() => submitCancel(selectedRadio)}
                  disabled={
                    (sectors[0]?.psngrs.filter((pax) => pax.tktst == "Confirmed")
                      .length === 0 || sectors.filter((sec) => sec.re !== "Non-Refundable")
                      .length === 0)
                      ? true
                      : false
                  }
                  className="cntBtn"
                >
                  {context.translation && context.translation.reqCan}
                </Button>
              ) : (
                <>
                {cancelData.tt == 2 || cancelData.sty === "split" && (
                  <Button
                    type="primary"
                    onClick={() => submitCancel(selectedRadio)}
                    disabled={
                      ((sectors[0]?.psngrs.filter((pax) => pax.tktst == "Confirmed")
                        .length === 0 && sectors[1]?.psngrs.filter((pax) => pax.tktst == "Confirmed")
                        .length === 0) || sectors.filter((sec) => sec.re !== "Non-Refundable")
                        .length === 0)
                        ? true
                        : false
                    }
                    className="cntBtn"
                  >
                    {context.translation && context.translation.reqCan}
                  </Button>
                )}
                </>
              )}
            </div>
          </div>
        )}
        {selectedRadio === "CT" && (
          <div>
            <Row className="tableHeader">
              <Col span={6} className="tableValue">
                {context.translation && context.translation.sectors}
              </Col>
              <Col span={6} className="tableValue">
                {context.translation && context.translation.travType}
              </Col>
              <Col span={4} className="tableValue">
                {context.translation && context.translation.trvType}
              </Col>
              <Col span={3} className="tableValue">
                {context.translation && context.translation.pnr}
              </Col>
              <Col span={3} className="tableValue">
                {context.translation && context.translation.status}
              </Col>
            </Row>
            <div>
              {cancelData &&
                sectors &&
                sectors.map((sec, parentidx) => (
                  <Row className="tableData alignPaxDetails" key={parentidx}>
                    <Col span={6} className="tableValue">
                      {sec.src} - {sec.des}
                    </Col>
                    <Col span={6} className="tableValue">
                      {sec.psngrs.map((pax, index) => (
                        <React.Fragment key={index}>
                          <Checkbox
                            checked={selectedCheckboxes[parentidx]?.includes(
                              index
                            )}
                            onChange={() =>
                              handleNestedChange(parentidx, index)
                            }
                            disabled={
                              (sec.re === "Non-Refundable" || pax?.tktst?.toUpperCase() !== "CONFIRMED") ? true : false
                            }
                            className="byPaxFN"
                          >
                            <div className="paxFullName">{pax.fn} {pax.ln}</div>
                            
                            {pax.inf !== undefined && pax.inf !== "" && (
                              <>
                                <div className="paxFullName">{pax.inf.fn} {pax.inf.ln}</div>
                              </>
                            )}
                            
                          </Checkbox>
                        </React.Fragment>
                      ))}
                    </Col>
                    <Col span={4} className="tableValue">
                      {sec.psngrs.map((pax, index) => (
                        <React.Fragment key={index}>
                          <div>{pax.ty}</div>
                          
                          {pax.inf !== undefined && pax.inf !== "" && (
                            <><div>{pax.inf.ty}</div></>
                          )}
                          
                        </React.Fragment>
                      ))}
                    </Col>
                    <Col span={3} className="tableValue">
                      {sec.pnr !== "" ? sec.pnr : "N/A"}
                    </Col>                
                    <Col span={3} className="tableValue active">
                      {sec.psngrs.map((pax, index) => (
                        <React.Fragment key={index}>
                          <div>{pax.tktst}</div>
                            {pax.inf!=="" && pax.inf!==undefined &&   
                              <>
                              {pax.inf.tktst?pax.inf.tktst:pax.tktst} 
                              </>
                            }     
                        </React.Fragment>
                      ))}
                    </Col>
                  </Row>
                ))}
            </div>
            <Input.TextArea
              rows={4}
              placeholder={context.translation && context.translation.remarks}
              onChange={handleRemarksChange(selectedRadio)}
              value={remarksCT[remarksCT.length - 1]}
              className="remarkArea"
            />
            <p className="noteWrap"> <span className="notetitle">{context.translation && context.translation.note}</span>  {context.translation && context.translation.willconnect} </p>

            <div className="buttonWrap">
              <Button onClick={backToNormal} className="backBtn">
                {context.translation && context.translation.cancel}
              </Button>
              <Button
                type="primary"
                onClick={() => submitCancel(selectedRadio)}
                disabled={
                  sectors.filter((sec) => sec.re !== "Non-Refundable")
                    .length === 0
                    ? true
                    : false
                }
                className="cntBtn"
              >
                {context.translation && context.translation.reqCan}
              </Button>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default CancelFlightTrip;
