import React, {
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react";
import { Alert, Button, Col, Row, Select, Typography,Modal } from "antd";
import Link from "antd/es/typography/Link";
import DateContainer from "../../../bookingflow/flights/search/DateContainer";
import HotelPredective from "./predective/HotelPredective";
import { debounce } from "lodash";
import { format, parse } from "date-fns";
import AddGuestDetails from "./AddGuestDetails";
import DateUtils from "../../../utilities/DateUtils";
import { Context } from "../../../../App";
import { useNavigate } from "react-router-dom";
import HotelBrandIcon from "../../../../assets/gif/Forever/White/H_WHITE_FOREVER.gif";
import { isArrayNotEmpty, isNotNull } from "../../../utilities/validators";
import Umbrella from "../../../../assets/img/umb2.png";
import Slider from 'react-slick';
import CntCommonService from "../../../../services/common/CntCommonService";
import PromoCodePopup from "../../../common/PromoCodePopup";
import { useRef } from "react";
import Cookies from 'universal-cookie'
import Utilities from "../../../utilities/Utilities";

export const HTL_REQUEST = "htlRequest";
export const SEL_PID_REQ = "pidRequest";
export const HTL_CTID = "ctid";

const { Title } = Typography;
const { Option } = Select;
const HOTEL_SEARCH_CID = process.env.REACT_APP_FLIGHT_SEARCH_CID;
const COOKIE_EXP_TIME = process.env.REACT_APP_CNT_HTL_COOKIE_EXP_TIME;

const initialState = {
  htlData: [],
  nights: 1,
  pkgRate: false,
  isSearching: false,
  rmCount: 1,
  guestCount: 1,
  hotelData: [],
  selHotel: [],
  notiMessage: "",
  notiMessageShow: false,
  notiVarient: "danger",
  roomsInfo: [],
  ismodify: false,
  flow: "Results",
  showResults: false,
  destName: "",
  hotelPredectiveDropDown: false,
  _startDateCal: false,
  _endDateCal: false,
  isSearching: false,
  openArivalDate: new Date(),
  selDest: [],
  cleardb: false,
  showInboundPopup: false,
  selDate: null,
  cin: "",
  cout: ""
};
//=====================================
// Reducer function for userReact Hook
// which will update the state
//=====================================
const reducer = (state, action) => {
  switch (action.type) {
    case "clear":
      return {
        ...state,
      };
    default:
      return { ...state, [action.type]: action.payload };
  }
};

function HotelSearch(props) {
  const navigate = useNavigate();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [context, setContext] = useContext(Context);
  const [hotelCity, setHotelCity] = useState("");
  const [showGuests, setShowGuests] = useState(false);
  const [showGuestType, setshowGuestType] = useState(false);
  const [checkInDateFocus, setCheckInDateFocus] = useState(false);
  const [checkInDate, setCheckInDate] = useState(null);
  const [checkOutDate, setCheckOutDate] = useState(null);
  const [validFrom, setValidFrom] = useState();
  const [validFromDate, setValidFromDate] = useState();
  const [validToDate, setValidToDate] = useState();
  const [isValid, setIsValid] = useState(false);
  const [isLoad, setIsLoad] = useState(false);
  const [packageRate, setPackageRate] = useState(true);
  const [startDate, setStartDate] = useState(new Date());
  const [endtDate, setEndtDate] = useState(DateUtils.addDatePlusOne(new Date()));
  const [predectiveLoading, setPredectiveLoading] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [hotelPredectiveDropDown, setHotelPredectiveDropDown] = useState(false);
  const [hotelCityPopulate, setHotelCityPopulate] = useState("");
  const [promoCodes, setPromoCodes] = useState([]);
  const [promoHotelObj, setHotelPromoObj] = useState("");
  const [enableHotelPromo, setEnableHotelPromo] = useState(false);
  const [searchField, setSearchField] = useState(true);
  const inputRef = useRef(null);
  const cookies = new Cookies()

    useEffect(() => {
      onLoad();
    }, [])

    useEffect(() => {
      if(props.data){
        onLoad();
      }
    }, [props.data, props.crosssell])

    useEffect(() => {
      if(context.popularHotels !== "" && context.popularHotels !== undefined) {
        setSearchField(false);
        setHotelCityPopulate(context.popularHotels?.hotelData?.description);
        dispatch({ type: "destName", payload: context.popularHotels?.hotelData?.description });
        dispatch({ type: "selHotel", payload: [context.popularHotels?.hotelData] });
        if(context.popularHotels?.e){
          dispatch({ type: "cleardb", payload: true });
        }
        errorRemoveBlur();
      }
    }, [context.popularHotels])

    useEffect(() => {
      let src =  cookies.get("HTL");
      if(src!==undefined && src!=="undefined"){
      selectHotelOrCity(src[0], src[0].type)();
      dispatch({ type: "cleardb", payload: true });
      }
    }, []);
    

    const onLoad = () => {
      if (props.data) {
        let dates = [];
        if (props.data.cin) {
          dates[0] = { $d: props.data.cin };
        }
        if (props.data.cout) {
          dates[1] = { $d: props.data.cout };
        }
        onModifyCheckInDateChange(dates);
        if(props.data.cityHotelData && props.data.cityHotelData[0]){
          const selectedFunction = selectHotelOrCity(props.data.cityHotelData[0],props.data.cityHotelData[0].type);
          selectedFunction();
        }
        setCheckInDateFocus(false);
        dispatch({ type: "roomsInfo", payload: props.data.roomsInfo });
        dispatch({ type: "ismodify", payload: props.data.modify });
        dispatch({type: "cleardb", payload: true})
      } else {
        if(localStorage.getItem(HTL_REQUEST) !== null && localStorage.getItem(HTL_REQUEST) !== "") {
          let data = JSON.parse(localStorage.getItem(HTL_REQUEST));
          if(props.crosssell) {
            let dates = [];
            if (data.cin) {
              dates[0] = { $d: data.cin };
            }
            if (data.cout) {
              dates[1] = { $d: data.cout };
            }
            onModifyCheckInDateChange(dates);
            // if(data.cityHotelData && data.cityHotelData[0]){
            //   const selectedFunction = selectHotelOrCity(data.cityHotelData[0],data.cityHotelData[0].type);
            //   selectedFunction();
            // }
            // setCheckInDateFocus(false);
            // dispatch({ type: "roomsInfo", payload: data.roomsInfo });
            // dispatch({ type: "ismodify", payload: data.modify });
            // dispatch({type: "cleardb", payload: true})
          }
        }
      }
      window.scrollTo(0, 0);
    }

    useEffect(() => {
      if(checkInDate && checkOutDate){
        var diff_In_Time = checkOutDate.getTime() - checkInDate.getTime();
        var diff_In_Days = diff_In_Time / (1000 * 3600 * 24);
        dispatch({ type: "nights", payload: Math.round(diff_In_Days) })
      }
    }, [checkInDate,checkOutDate])

    useEffect(() => {
      setPromoCodes([]);
    if(!enableHotelPromo){
        getHotelPromo();
      }
    }, []);
    
    const getHotelPromo=()=>{
      var region=localStorage.getItem("REGION_COUNTRY")!==null && localStorage.getItem("REGION_COUNTRY")==="IN"?"India":"Qatar";
      const request={
         "region_type":region,
         "sales_chnnel":"B2C",
         "product_type":"Hotel"
      }
      try {
        if(promoCodes.length===0){
          let tokenKey = Utilities.generateAPIToken(request);
          CntCommonService.getPromoCodeList(request,tokenKey).then((response) => {
            if (response.data !== undefined && response.data.suc) {
              setPromoCodes(response.data.res);
            }
          }).catch(function (error) {
            console.error("Error in getFlightPromo" + error);      
          });
        }
      } catch (err) {
        console.error("Error in getFlightPromo" + err);
      }
    }
  
    const getPromoDetails=(obj)=>{
      try {
        if(obj.promo_id!==undefined){
          const req={
            "promo_id":obj.promo_id,
            "product_type": "Hotel"
          }
          let tokenKey = Utilities.generateAPIToken(req);
          CntCommonService.getProDetails(req,tokenKey).then((response) => {
          if (response.data !== undefined && response.data.suc) {
              setEnableHotelPromo(true)
              setHotelPromoObj(response.data.res)
             }
          }).catch(function (error) {
            console.error("Error in getFlightPromo" + error);      
          });
        }
      } catch (err) {
        console.error("Error in getFlightPromo" + err);
      }
    }

    const closePromo=()=>{
      setEnableHotelPromo(false)
    }
  

  useEffect(() => {
    setShowGuests(false);
  }, [showGuestType]);

  const showHideGuest = () => {
    setShowGuests(!showGuests);
  };

  const closeGuest = () => {
    showHideGuest();
  };

  const clearDestination = ()=>{
    dispatch({type: "destName", payload: ""})
    dispatch({ type: "hotelData", payload: [] });
    dispatch({type: "cleardb", payload: false})
    setHotelCity('');
    setHotelCityPopulate('');
  }

  const guestCountupdate = (data) => {
    dispatch({ type: "guestCount", payload: data });
  };

  const roomCountupdate = (data) => {
    dispatch({ type: "rmCount", payload: data });
  };

  const roomInfoupdate = (data) => {
    dispatch({ type: "roomsInfo", payload: data });
  };

  //=====================================
  // This function will get the Source data
  //=====================================
  const handleHotelCitySearch = (e) => {
    setSearchField(true);
     setHotelCity(e.target.value);
           setPredectiveLoading(true);
         setDataLoaded(false);
    const { id, value } = e.target;
    if (value.length > 0) {
      dispatch({ type: "cleardb", payload: true });
    } else {
      dispatch({ type: "cleardb", payload: false });
    }
    dispatch({ type: "isSearching", payload: true });
    if (value.length > 2) {
      let obj = {
        textValue: value,
      };
      debouncedSave(obj);
    } else {
      dispatch({ type: "hotelData", payload: [] });
    }
    dispatch({ type: id, payload: value });
    dispatch({ type: "isSearching", payload: false });
  };

  const debouncedSave = useCallback(
    debounce((obj) => predectiveCall(obj.textValue), 500),
    [] // will be created only once initially
  );

  //=====================================
  // This function will call the service to get the Hotel data
  //=====================================
  const predectiveCall = (input) => {
    dispatch({ type: "hotelPredectiveDropDown", payload: false });
    setHotelPredectiveDropDown(false);
    HotelPredective.retrieveSearch(input).then((data) => {
      dispatch({
        type: "hotelData",
        payload: data.data.data !== "" ? [data.data.data] : [],
      });
      dispatch({ type: "showResults", payload: true });
      dispatch({ type: "hotelPredectiveDropDown", payload: true });
      setHotelPredectiveDropDown(true);
    }).catch(function (error) {
      console.error("Error:" + error);      
    });
  };

  const preLoadHotelOrCity = (obj, type) => {
    selectHotelOrCity(obj, type);
  }

  const selectHotelOrCity = (obj, type) => () => {
    if (obj !== undefined) {
      dispatch({ type: "hotelPredectiveDropDown", payload: false });
      setHotelPredectiveDropDown(false);
      obj.type = type;
      if (type !== "city") {
        dispatch({
          type: "destName",
          // payload: obj.description + ", " + obj.country,
          payload: obj.description,
        });
        // setHotelCity(obj.description + ", " + obj.country);
        setHotelCity(obj.description);
      } else {
        if (obj.description !== null) {
          dispatch({ type: "destName", payload: obj.description });
          setHotelCity(obj.description);
        } else {
          dispatch({
            type: "destName",
            payload: obj.text + ", " + obj.country,
          });
          setHotelCity(obj.text + ", " + obj.country);
        }
      }

      dispatch({ type: "selHotel", payload: [obj] });
      openStartDateCal();
      errorRemoveBlur();
      setCheckInDateFocus(true);
    }
  };

  const openStartDateCal = () => (state._startDateCal = true);
  const openEndDateCal = () => (state._endDateCal = true);

  const errorRemoveBlur = () => {
    dispatch({ type: "notiMessageShow", payload: false });
  };

  //For handling the date focus in the hotel panel
  const dateFocus = useCallback((data) => {
    setCheckInDateFocus(data);
    dispatch({ type: "hotelPredectiveDropDown", payload: false });
    setHotelPredectiveDropDown(false);
    // dispatch({type: "destName", payload: ""})
    // dispatch({ type: "hotelData", payload: [] });
    // dispatch({type: "cleardb", payload: false})
    // setHotelCity('');
  }, []);

  //For handling the checkin date changes in the flight search panel
  const onModifyCheckInDateChange = useCallback((data) => {
    if (data) {
      dispatch({ type: "selDate", payload: data });
      if (data[0]) {
        setCheckInDate(data ? parse(data[0].$d,'dd-MM-yyyy',new Date()) : null);
        dispatch({
          type: "dd",
          payload: data ? format(parse(data[0].$d,'dd-MM-yyyy',new Date()), "yyyy-MM-dd") : null,
        });
        dispatch({
          type: "cin",
          payload: data ? format(parse(data[0].$d,'dd-MM-yyyy',new Date()), "dd-MM-yyyy") : null,
        });
        data ? setValidFromDate(true) : setValidFromDate(false);
      }
      if (data[1]) {
        setCheckOutDate(data ? parse(data[1].$d,'dd-MM-yyyy',new Date()) : null);
        setCheckInDateFocus(false);
        dispatch({
          type: "ad",
          payload: data ? format(parse(data[1].$d,'dd-MM-yyyy',new Date()), "yyyy-MM-dd") : null,
        });
        dispatch({
          type: "cout",
          payload: data ? format(parse(data[1].$d,'dd-MM-yyyy',new Date()), "dd-MM-yyyy") : null,
        });
        data ? setValidToDate(true) : setValidToDate(false);
      }
    } else {
      setCheckInDate(null);
      setCheckOutDate(null);
      setValidFromDate(false);
      setValidToDate(false);
    }
  }, []);

  const onCheckInDateChange = useCallback((data) => {
    if (data && data[0] && data[1]) {
      dispatch({ type: "selDate", payload: data });
      if (data[0]) {
        setCheckInDate(data ? new Date(data[0].$d) : null);
        dispatch({
          type: "dd",
          payload: data ? format(new Date(data[0].$d), "yyyy-MM-dd") : null,
        });
        dispatch({
          type: "cin",
          payload: data ? format(new Date(data[0].$d), "dd-MM-yyyy") : null,
        });
        data ? setValidFromDate(true) : setValidFromDate(false);
      }
      if (data[1]) {
        setCheckOutDate(data ? new Date(data[1].$d) : null);
        setCheckInDateFocus(false);
        dispatch({
          type: "ad",
          payload: data ? format(new Date(data[1].$d), "yyyy-MM-dd") : null,
        });
        dispatch({
          type: "cout",
          payload: data ? format(new Date(data[1].$d), "dd-MM-yyyy") : null,
        });
        data ? setValidToDate(true) : setValidToDate(false);
      }
    } else {
      setCheckInDate(null);
      setCheckOutDate(null);
      setValidFromDate(false);
      setValidToDate(false);
    }
  }, []);

  const searchHotel = () => {
    if (validateSearch()) {
      /* if (state.selHotel[0].countryCode !== "QA") { */
        handleHotelSearch();
     /* }  else{
        let checkCard = DateUtils.checkHayyCardDate(startDate,"QA");
        if(checkCard){
          dispatch({ type: "showInboundPopup", payload: true })
        }else{
          handleHotelSearch();
        }
     } */
    }
  };

  //=====================================
  // This function will call the service for Search response
  //=====================================
  const handleHotelSearch = () => {
    dispatch({ type: "showInboundPopup", payload: false });
    try {
      setIsLoad(true);
      let rooms = [];
      if (state.roomsInfo.length === 0) {
        const payload = {
          rmno: 1,
          adt: 1,
          chd: 0,
          chdArray: [],
          chdAges: [],
        };
        rooms.push(payload);
      }
      let pkg = 0;
      if (packageRate) {
        pkg = 1;
      }
      let regionType = localStorage.getItem("REGION_COUNTRY")!==null && localStorage.getItem("REGION_COUNTRY")==="IN"?"India":"Qatar";;
      let cur = context.userCur!==""  && context.userCur!==undefined ? context.userCur : sessionStorage.getItem("SEL_CUR");
      if (
        context.user_resp !== "" &&
        context.user_resp !== undefined &&
        context.user_resp.region_type !== undefined &&
        context.user_resp.region_type !== ""
      ) {
        regionType = context.user_resp.region_type;
        cur = cur;
      } else {
        cur = cur;
        regionType = regionType
      }
      constructURLAndRedirectResults(cur,regionType,(isArrayNotEmpty(state.roomsInfo)? state.roomsInfo: rooms));
    } catch (e) {
      console.error("Exception occured in handleHotelSearch : " + e);
    }
  };

  const storeCookie=()=>{
    let src = cookies.get("HTL");
    if(src!==undefined && src!=="undefined"){
      if(state.selHotel === undefined) {
        state.selHotel = src;
      }
    }
    let maximumAge = COOKIE_EXP_TIME && parseFloat(COOKIE_EXP_TIME);
    if(process.env.NODE_ENV!=="development"){
      cookies.set('HTL',JSON.stringify(state.selHotel), {path: '/', maxAge: maximumAge,secure :true,httpOnly :true,sameSite:true});
    }else{
      cookies.set('HTL',JSON.stringify(state.selHotel), {path: '/', maxAge: maximumAge,sameSite:true});
    }
  }

  const constructURLAndRedirectResults = (cur,regionType,rooms) => {
    try {
      setIsLoad(true);
      storeCookie();
      const params = new URLSearchParams({
        searchType: state.selHotel[0].type,
        cin: state.cin,
        cout: state.cout,
        nights: state.nights !== 0 ? state.nights : 1,
        roomCount: rooms.length,
        adultCount: rooms.map(room => room.adt).join(','),
        childCount: rooms.map(room => room.chd).join(','),
        childAges: rooms.map(room => {
          return room.chd === 0 && room.chdAges.length === 0 && room.chdArray.length === 0
            ? []
            : room.chdArray.map(child => child.age || 0).join(',');
        }).join('|'),
        city: state.selHotel[0].expediaCityId,
        hotelId: state.selHotel[0].expediapid,
        ctid:  state.selHotel[0]._id,
        modify: state.ismodify,
        // cur: cur,
        region: regionType,
        usr_cur: cur,
      });
      if (state.selHotel[0].type !== "city") {
        let reqObj = parseQueryString(params);
        let selHtl = [];
        if (state.selHotel[0].sup === 0) {
          selHtl = state.selHotel[0]
        } else {
          selHtl = state.selHotel[0];
        }
        selHtl.sgst = state.selHotel[0].sgst !== undefined ? state.selHotel[0].sgst : 0;
        selHtl.cgst = state.selHotel[0].cgst !== undefined ? state.selHotel[0].cgst : 0;
        selHtl.igst = state.selHotel[0].igst !== undefined ? state.selHotel[0].igst : 0;
        selHtl.tds_per = state.selHotel[0].tds_per !== undefined ? state.selHotel[0].tds_per : 0;
        // selHtl.sup_base_exrate = response.sup_base_exrate;
        // selHtl.base_sup_exrate = response.base_sup_exrate;
        selHtl.sup_cur = reqObj.sup_cur !== "" && reqObj.sup_cur !== undefined ? reqObj.sup_cur : "USD";
        let htl = JSON.stringify(selHtl);
        // //selHtl.request=searchReq;
        localStorage.setItem(HTL_REQUEST, JSON.stringify(reqObj));
        // localStorage.setItem(HTL_MARKUP, state.markp);
        localStorage.setItem(HTL_CTID, state.selHotel[0].ctid);
        constructRoomInfo(reqObj)
        window.localStorage.setItem("ispkg",props.isPackage);
        window.localStorage.setItem("sreq",JSON.stringify(reqObj));
        window.localStorage.setItem("modify",reqObj.modify);
        window.sessionStorage.setItem(SEL_PID_REQ, htl);
        window.open("/hotel/details","_self");
        window.htl = htl;
        window.cmpid = HOTEL_SEARCH_CID;
        window.isPackage = reqObj && reqObj.package !== undefined && reqObj.package === 1 ? true : false;
        window.searchRequest = reqObj;
      } else if(props.isDetailsModify === undefined || (props.isDetailsModify && props.isDetailsModify === false)){
        const urlParams = params.toString();
        // Construct the URL with the parameters
        const url = `/hotel/results?${urlParams}`;
        var h = window.location.host.includes('localhost')?'http':'https';
        window.location.href = `${h}://${window.location.host}`+url;
      } else if (props.isDetailsModify && props.isDetailsModify === true){
        let comid = sessionStorage.getItem("CMPID");
        let reqObj = parseQueryString(params);
        constructRoomInfo(reqObj)
        window.localStorage.setItem("ispkg",props.isPackage);
        window.localStorage.setItem("sreq",JSON.stringify(reqObj));
        window.localStorage.setItem("modify",props.isDetailsModify);
        window.open("/hotel/details","_self");
      }
    } catch (e) {
      console.error("Exception in hotel search request" + e);
    }
  }

  const parseQueryString = (params) => {
    const payload = {};

    for (const [key, value] of params.entries()) {
      payload[key] = value;
    }

    return payload;
  };

  const constructRoomInfo = (request) => {
    // Split the comma-separated values in the request object
    const adultCounts = request.adultCount.split(',').map(Number);
    const childAgesPerRoom =request.childAges!==""?request.childAges.split('|').map(group => group.split(',').map(Number)):[];
    const childCounts = request.childCount.split(',').map(Number);
    const roomCount = parseInt(request.roomCount, 10);

    // Create the rooms array based on the provided data
    const rooms = [];
    for (let rmno = 1; rmno <= roomCount; rmno++) {
      const adt = adultCounts[rmno - 1];
      const chdAges =childAgesPerRoom.length!==0?childAgesPerRoom[rmno - 1]:[];
      const chd = childCounts[rmno - 1];

      // Create the chdArray based on chdAges
      let chdArray = []
      if(chdAges.length!==0 && chd !== 0){
        chdArray= chdAges.map((age, chd) => ({ chd: chd + 1, age }));
      }
      rooms.push({ rmno, adt, chd, chdAges, chdArray });
    }
    if(isArrayNotEmpty(rooms)){request.roomsInfo = rooms}
  }

  const hidemessage = () => {
    setTimeout(function () {
      dispatch({ type: 'notiMessageShow', payload: false })
      dispatch({ type: 'notiMessage', payload: '' })
      dispatch({ type: 'notiVarient', payload: '' })
    }, 5000);
  }

  //=====================================
  // This function will validate the Search Fields data exists or not
  //=====================================
  const validateSearch = () => {
    if (state.destName === "" && state.flow === "Results" && (state.cin === "" || state.cout === "")) {
      document.querySelector(".dest-from").classList.add("errorBox");
      document.querySelector(".roundTripDate").classList.add("errorDate");
    }
    if (state.cin === "") {
      document.querySelector(".roundTripDate").classList.add("errorDateCinField");
    }
    if (state.cout === "") {
      document.querySelector(".roundTripDate").classList.add("errorDateCoutField");
    }
    
    if (state.destName === "" && state.flow === "Results") {
      document.querySelector(".dest-from").classList.add("errorBox");
      // dispatch({ type: "notiMessage", payload: "Please enter Destination." });
      // dispatch({ type: "notiVarient", payload: "error" });
      // dispatch({ type: "notiMessageShow", payload: true });
      // hidemessage();
      return false;
    }

    if (state.cin === "") {
      document.querySelector(".roundTripDate").classList.add("errorDate");
      // dispatch({
      //   type: "notiMessage",
      //   payload: "Please select Check-In Date.",
      // });
      // dispatch({ type: "notiVarient", payload: "error" });
      // dispatch({ type: "notiMessageShow", payload: true });
      // hidemessage();
      return false;
    }
    if (state.cout === "") {
      document.querySelector(".roundTripDate").classList.add("errorDate");
      // dispatch({
      //   type: "notiMessage",
      //   payload: "Please select Check-Out Date.",
      // });
      // dispatch({ type: "notiVarient", payload: "error" });
      // dispatch({ type: "notiMessageShow", payload: true });
      // hidemessage();
      return false;
    }
    if (
      state.cin !== "" &&
      state.cout !== "" &&
      state.cin === state.cout
      // DateUtils.convertToDateHifen(state.cin) ===
      //   DateUtils.convertToDateHifen(state.cout)
    ) {
      dispatch({
        type: "notiMessage",
        payload: (context.translation && context.translation.checkinNcheckout),
      });
      dispatch({ type: "notiVarient", payload: "error" });
      dispatch({ type: "notiMessageShow", payload: true });
      hidemessage();
      return false;
    }
    if (state.selHotel.length === 0) {
      dispatch({ type: "notiMessageShow", payload: true });
      dispatch({
        type: "notiMessage",
        payload: (context.translation && context.translation.plzprefdest),
      });
      dispatch({ type: "notiVarient", payload: "error" });
      hidemessage();
      return false;
    }
    if (state.nights < 1) {
      dispatch({ type: "notiMessageShow", payload: true });
      dispatch({
        type: "notiMessage",
        payload: (context.translation && context.translation.plzselcheckinNcheckout),
      });
      dispatch({ type: "notiVarient", payload: "error" });
      hidemessage();
      return false;
    }
    if (state.dd && state.ad) {

      const checkInDateString = new Date(state.dd);
      const checkOutDateString = new Date(state.ad);

      // To calculate the time difference of two dates
      var Difference_In_Time = checkOutDateString.getTime() - checkInDateString.getTime();

      // To calculate the no. of days between two dates
      var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

      // Check if the check in date and check out date has more than 30 nights difference
      if (Difference_In_Days >= 30) {
        dispatch({ type: "notiMessageShow", payload: true });
        dispatch({ type: "notiMessage", payload: (context.translation && context.translation.thereareNoroom) });
        dispatch({ type: "notiVarient", payload: "error" });
        hidemessage();
        return false;
      }
    }
    if (state.dd && state.ad && state.ismodify) {

      const checkInDateString = new Date(state.dd);
      const checkOutDateString = new Date(state.ad);

      // To calculate the time difference of two dates
      var Difference_In_Time = checkOutDateString.getTime() - checkInDateString.getTime();

      // To calculate the no. of days between two dates
      var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

      // Check if the check in date and check out date has more than 30 nights difference
      if (Difference_In_Days >= 30) {
        dispatch({ type: "notiMessageShow", payload: true });
        dispatch({ type: "notiMessage", payload: (context.translation && context.translation.thereareNoroom) });
        dispatch({ type: "notiVarient", payload: "error" });
        hidemessage();
        return false;
      }
    }
    return true;
  };

  useEffect(() => {
    if (hotelCity && hotelCity.length > 2) {
      if (
        state.hotelData === undefined ||
        (state.hotelData && state.hotelData.length === 0)
      ) {
        setPredectiveLoading(false);
        setDataLoaded(false);
      } else {
        if (state.hotelData.length > 0) {
          setPredectiveLoading(false);
          setDataLoaded(true);
        }
      }
    } else {
      setPredectiveLoading(false);
    }
  }, [state.hotelData])

  useEffect(() => {
    if(checkInDateFocus){
      dispatch({ type: "hotelPredectiveDropDown", payload: false });
      setHotelPredectiveDropDown(false);
    }
  }, [checkInDateFocus])

  

  var offers;
  let flow_lng=sessionStorage.getItem("SEL_LAG");
  if (flow_lng && flow_lng === "Arabic") {
    offers = {
      infinite: true,
      speed: 1000,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      rtl: false,
      nextArrow: false,
      prevArrow: false,
      dots: false,
    }
  } else {
      offers = {
      infinite: true,
      speed: 1000,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      rtl: true,
      nextArrow: false,
      prevArrow: false,
      dots: false,
    }
  }
  

  return (
    <>
      {state.notiMessageShow ? (
        <Alert
          description={state.notiMessage}
          type={state.notiVarient}
          closable
          onClose={() => dispatch({ type: "notiMessageShow", payload: false })}
          className="alertForm"
          // style={{position:"fixed", top:"60px"}}
        >
          {" "}
          {state.notiMessage}
        </Alert>
      ) : (
        ""
      )}
      <div className="container hotelSearch" ref={inputRef}>
      <div className="offerSliderWrap">
      {promoCodes.length!==0 &&
        <div className="offerSlider">
          <Slider {...offers} className="cntSlider cntHomepage">
              {promoCodes.map((promo, index) => {
                return (
                  <div key={index}>
                    <Title level={2} className="offerTitle">
                      <span>{context.selectLang == undefined || context.selectLang === "en" ? promo.offer_desc_en : promo.offer_desc_ar}</span>
                      <img src={Umbrella} alt="umbImage" className="umbImage"/>
                    </Title>
                    <div className="offerDesc">
                    <span className="offerDescCont">{context.selectLang == undefined || context.selectLang === "en" ? promo.offer_nm : promo.offer_nm_ar}</span>
                      <span className="pipe"></span>{context.translation && context.translation.code} <span className="offerCode">{promo.pro_code}</span>&nbsp; <Link onClick={()=>getPromoDetails(promo)} className="offerDetails">{context.translation && context.translation.details}</Link></div>
                  </div> 
                  )
              })}
          </Slider>
        </div>
        }
        </div>
          <div className="searchPanel">
            <Row className={"select-trips " + (props.scrollDown ? "" : "dNone")}>
              <Col className={"guest-section " + (showGuests && "active")}>
                <AddGuestDetails
                  showHideGuest={showHideGuest}
                  showGuests={showGuests}
                  sendGuestCount={guestCountupdate}
                  sendRoomsCount={roomCountupdate}
                  sendRoomsInfo={roomInfoupdate}
                  roomsInfo={state.roomsInfo}
                  closeBtn={closeGuest}
                  ismodify={state.ismodify}
                  focus={setCheckInDateFocus}
                  predectiveSearch={setHotelPredectiveDropDown}
                />
              </Col>
            </Row>
            <Row className="search-row">
              <Col className="dest-from s-field">
                <label><i className="icon icon-Pin"></i>{context.translation && context.translation.destination} </label>

                <input
                  className="effect-9"
                  type="text"
                  onChange={e => handleHotelCitySearch(e)}
                  value={hotelCity&&searchField?hotelCity:hotelCityPopulate}
                  title={hotelCity&&searchField?hotelCity:hotelCityPopulate}
                  placeholder={context.translation && context.translation.cityOrhot}
                  onFocus={e => setCheckInDateFocus(false)}
                />
                {state.cleardb  && 
            //     <Button
            //   className="predictiveClear ezyIcon icon-close"
            //   onClick={clearDestination}
            // />
            <Button onClick={clearDestination}>
                <i className="icon icon-cancel clearField"></i>
              </Button>
            }
                <span className="focus-border">
                  <i></i>
                </span>
              </Col>
              <Col className={(context.selectLang === "en" || context.selectLang === undefined) ? "dpicker hdpicker" : "dpicker hdpicker hdpickerArabic"}>
                <DateContainer
                  className="roundTripDate"
                  open={checkInDateFocus}
                  focus={dateFocus}
                  onchange={onCheckInDateChange}
                  placeholders={context.selectLang==="en"||context.selectLang=== undefined ?["DD/MM/YY", "DD/MM/YY"]:["يوم/شهر/سنة","يوم/شهر/سنة"]}
                  allowempty={[false, false]}
                  disabled={[false, false]}
                  date={new Date()}
                  selDate={[state.dd, state.ad]}
                  htl={true}
                />
              </Col>
              <Col className="one-round-btn">
                <Button type="primary" className="cntBtn hBtn" onClick={searchHotel}>
                {!state.ismodify && context.translation && context.translation.search}
                {state.ismodify && context.translation && context.translation.modify}
                  <img src={HotelBrandIcon} alt="hotelBrandIcon" className="hotelBrandIcon" />
                  {/* <i className="icon icon-H-logo"></i> */}
                </Button>
              </Col>
            </Row>
            <Row>
              {hotelCity && hotelCity.length > 2 && hotelPredectiveDropDown && (
                <div className="hotelPredictiveDropdownWrap">
                  <div className={state.hotelData.length !== 0?"hotelPredictiveDropdown":""}>
                      <div>
                        {state.hotelData !== undefined &&
                          state.showResults &&
                          state.hotelData.length !== 0 && (
                            <>
                              {state.hotelData !== undefined &&
                                state.hotelData[0].city.length === 0 &&
                                state.hotelData[0].landmark.length === 0 &&
                                state.hotelData[0].area.length === 0 &&
                                state.hotelData[0].property.length === 0 && (
                                  <div className="noMatchesFound">
                                    {context.translation && context.translation.nomfound}
                                  </div>
                                )}
                              <Row gutter={20}>
                                {state.hotelData[0].city !== undefined &&
                                  state.hotelData[0].city.length !== 0 && (
                                    <Col span={12}>
                                      {state.hotelData[0].city.map(
                                        (cty, index) => (
                                          <div key={index}>
                                            {index === 0 && (
                                              <>
                                                <h4 className="type">{context.translation && context.translation.city}</h4>
                                              </>
                                            )}
                                            <div
                                              onClick={selectHotelOrCity(
                                                cty,
                                                "city"
                                              )}
                                              className="cityNameWrapper"
                                            >
                                              {cty.description !== null && (
                                                
                                                <span
                                                  title={cty.description}
                                                  className="cityName"
                                                >
                                                  <span><i className="icon icon-Pin"></i></span>
                                                  {" "}
                                                  {cty.description}
                                                </span>
                                              )}
                                              {cty.description == null && (
                                                <span
                                                  title={cty.text + cty.country}
                                                  className="cityName"
                                                >
                                                  {" "}
                                                  {cty.text}, {cty.country}
                                                </span>
                                              )}
                                            </div>
                                          </div>
                                        )
                                      )}
                                    </Col>
                                  )}

                                {state.hotelData[0].property !== undefined &&
                                  state.hotelData[0].property.length !== 0 && (
                                    <Col span={12}>
                                      {state.hotelData[0].property.map(
                                        (hotel, index) => (
                                          <div key={index}>
                                            {index === 0 && (
                                              <>
                                                <h4 className="type">{context.translation && context.translation.hotel}</h4>
                                              </>
                                            )}
                                            <div
                                              onClick={selectHotelOrCity(
                                                hotel,
                                                "property"
                                              )}
                                              className="hotelNameWrapper"
                                            >
                                              <span
                                                title={
                                                  hotel.description
                                                }
                                                className="hotelName"
                                              >
                                                <i className="icon icon-H1"></i>
                                                {" "}
                                                {hotel.description}
                                              </span>
                                            </div>
                                          </div>
                                        )
                                      )}
                                    </Col>
                                  )}
                              </Row>
                            </>
                          )}
                        {!predectiveLoading && !dataLoaded && hotelCity && hotelCity.length > 2 && state.hotelData.length === 0 && (
                          <div className="noResultsFound">{context.translation && context.translation.plzvalidCityorHotel}</div>
                        )}
                      </div>
                  </div>
                </div>
              )}
            </Row>
          </div>
        </div>
        <Modal title="" open={enableHotelPromo} footer={null} className="promoCodePopup">
        {promoHotelObj!=="" &&  
         <PromoCodePopup promoObj={promoHotelObj} product={"Hotel"} closePopup={closePromo}/>
         }
      </Modal>
    </>
  );
}

export default HotelSearch;
