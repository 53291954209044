/**
 * @ Author: Srikanth Bandaru
 * @ Create Time: 2023-06-15 09:47:01
 * @ Description: This is a component used to display the results blocks in the flight search results page
 */

import { Button, Col, Drawer, Radio, Tooltip,Modal,Typography,Alert } from "antd";
import Link from "antd/es/typography/Link";
import React, { useEffect, useState,useContext } from "react";
import { Context,ExchangeRates } from "../../../../App";
import ResultSectionHeader from "./ResultSectionHeader";
import TripDetails from "./TripDetails";
import FlightDetailsPopup from "./FlightDetailsPopup";
import ReturnTripDetails from "./ReturnTripDetails";
import ResultSectionOptionsHeader from "./ResultSectionOptionsHeader";
import LoaderN from "../../../common/loaders/LoaderN";
import FlightMultiCityBlock from "./FlightMultiCityBlock";
import FormatCurrency from "../../../utilities/FormatCurrency";
import Utilities from "../../../utilities/Utilities";
import BrandIcon from "../../../../assets/gif/Forever/White/ICON_WHITE_FOREVER.gif";
import CntCommonService from "../../../../services/common/CntCommonService";
import DateUtils from "../../../utilities/DateUtils";
const _ = require("lodash");
const FLIGHT_SEARCH_CID = process.env.REACT_APP_FLIGHT_SEARCH_CID;
const BASE_CURRENCY = process.env.REACT_APP_BASE_CUR;
function FlightResultsBlock(props) {
  const [context, setContext] = useContext(Context)
  const {
    sty,
    mapping,
    searchRes,
    searchRTRes,
    trip,
    sect,
    currency,
    secType,
    sectors,
    // fltIndex,
    srequest,
    // flightsSel,
    serviceFee,
    // onFlyMkp,
    regionType,
    secTypeIndex,
    price,
    selectedFlight,
    selectedRTFlight,
    paxpr,
    handleShortenUrl,
    fireFareRule,
    base_usr_exrate
  } = props;
  const { Title } = Typography;
  const [showFlightOptions, setShowFlightOptions] = useState(false);
  const [flightOptions, setFlightOptions] = useState();
  const [defaultOption, setDefaultOption] = useState();
  const [isChecked, setIsChecked] = useState(false);
  const [returnFlightOptions, setReturnFlightOptions] = useState();
  const [returnDefaultOption, setReturnDefaultOption] = useState();
  const [isRTChecked, setIsRTChecked] = useState(false);
  const [open, setOpen] = useState(false);
  const [openOne, setOpenOne] = useState(false);
  const [openTwo, setOpenTwo] = useState(false);
  const [openThree, setOpenThree] = useState(false);
  const [openFour, setOpenFour] = useState(false);
  const [openFive, setOpenFive] = useState(false);
  const [openSix, setOpenSix] = useState(false);
  const [openSeven, setOpenSeven] = useState(false);
  const [openEight, setOpenEight] = useState(false);
  const [openNine, setOpenNine] = useState(false);
  const [openTen, setOpenTen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchResOne, setSearchResOne] = useState([]);
  const [searchResTwo, setSearchResTwo] = useState([]);
  const [searchResThree, setSearchResThree] = useState([]);
  const [searchResFour, setSearchResFour] = useState([]);
  const [searchResFive, setSearchResFive] = useState([]);
  const [searchResSix, setSearchResSix] = useState([]);
  const [flightOptionsOne, setFlightOptionsOne] = useState();
  const [flightOptionsTwo, setFlightOptionsTwo] = useState();
  const [flightOptionsThree, setFlightOptionsThree] = useState();
  const [flightOptionsFour, setFlightOptionsFour] = useState();
  const [flightOptionsFive, setFlightOptionsFive] = useState();
  const [flightOptionsSix, setFlightOptionsSix] = useState();
  const [defaultOptionOne, setDefaultOptionOne] = useState();
  const [defaultOptionTwo, setDefaultOptionTwo] = useState();
  const [defaultOptionThree, setDefaultOptionThree] = useState();
  const [defaultOptionFour, setDefaultOptionFour] = useState();
  const [defaultOptionFive, setDefaultOptionFive] = useState();
  const [defaultOptionSix, setDefaultOptionSix] = useState();
  const [isCheckedOne, setIsCheckedOne] = useState(false);
  const [isCheckedTwo, setIsCheckedTwo] = useState(false);
  const [isCheckedThree, setIsCheckedThree] = useState(false);
  const [isCheckedFour, setIsCheckedFour] = useState(false);
  const [isCheckedFive, setIsCheckedFive] = useState(false);
  const [isCheckedSix, setIsCheckedSix] = useState(false);
  const [showFlightOptionsOne, setShowFlightOptionsOne] = useState(false);
  const [showFlightOptionsTwo, setShowFlightOptionsTwo] = useState(false);
  const [showFlightOptionsThree, setShowFlightOptionsThree] = useState(false);
  const [showFlightOptionsFour, setShowFlightOptionsFour] = useState(false);
  const [showFlightOptionsFive, setShowFlightOptionsFive] = useState(false);
  const [showFlightOptionsSix, setShowFlightOptionsSix] = useState(false);
  // const totalSegments = [1, 2, 3, 4, 5, 6];
  const [paxPrice, setPaxPrice] = useState(paxpr);
  const [selPrice, setSelPrice] = useState(sectors.pr);
  const [selAAFlt, setSelAAFlt] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [aaFareOp, setAaFareOp] = useState([]);
  const [showUpdatePrice, setShowUpdatedPrice] = useState(false);
  const [oldAAPrice, setOldAAPrice] = useState(0);
  const [newAAPrice, setNewAAPrice] = useState(0);
  const [selAAFltRt, setSelAAFltRt] = useState("");
  const [aaFareOpRt, setAaFareOpRt] = useState([]);
  const [showPriceFailed, setShowPriceFailed] = useState(false);
  const [notiMessage, setNotiMessage] = useState("");
  const [notiMessageShow, setNotiMessageShow] = useState(false);
  const [notiVarient, setNotiVarient] = useState();

  useEffect(() => {
    onLoad();
  }, []);
  
  useEffect(() => {
    if(paxpr){
      setPaxPrice(paxpr);
    }
    if(sectors){
      setSelPrice(sectors.pr);
    }
  }, [paxpr,sectors])

  
  //On load operations to be executed
  const onLoad = () => {
    if (parseInt(trip) !== 3) {
      if (searchRes.length > 0) {
        const updatedArray = searchRes.map((obj) => ({
          ...obj,
          selected: false,
          class: "",
        }));
        setFlightOptions(updatedArray);
        const defaultFlightArray = [...updatedArray];
        defaultFlightArray[0].class = "selected";
        setDefaultOption(defaultFlightArray[0]);
        setIsChecked(false);
      }
      if (searchRTRes && searchRTRes.length > 0) {
        const updatedArray = searchRTRes.map((obj) => ({
          ...obj,
          selected: false,
          class: "",
        }));
        setReturnFlightOptions(updatedArray);
        const defaultFlightArray = [...updatedArray];
        defaultFlightArray[0].class = "selected";
        setReturnDefaultOption(defaultFlightArray[0]);
        setIsRTChecked(false);
      } else {
        setReturnFlightOptions();
        setReturnDefaultOption();
      }
    } else {
      setSearchResOne(
        searchRes.filter((flt) => {
          return flt.ref === 1;
        })
      );
      setSearchResTwo(
        searchRes.filter((flt) => {
          return flt.ref === 2;
        })
      );
      setSearchResThree(
        searchRes.filter((flt) => {
          return flt.ref === 3;
        })
      );
      setSearchResFour(
        searchRes.filter((flt) => {
          return flt.ref === 4;
        })
      );
      setSearchResFive(
        searchRes.filter((flt) => {
          return flt.ref === 5;
        })
      );
      setSearchResSix(
        searchRes.filter((flt) => {
          return flt.ref === 6;
        })
      );
    }
  };

  useEffect(() => {
    onLoad();
    setShowFlightOptions(false);
    setShowFlightOptionsOne(false);
    setShowFlightOptionsTwo(false);
    setShowFlightOptionsThree(false);
    setShowFlightOptionsFour(false);
    setShowFlightOptionsFive(false);
    setShowFlightOptionsSix(false);
  }, [searchRes]);

  useEffect(() => {
    if (searchResOne.length > 0) {
      const updatedArray = searchResOne.map((obj) => ({
        ...obj,
        selected: false,
        class: "",
      }));
      setFlightOptionsOne(updatedArray);
      const defaultFlightArray = [...updatedArray];
      defaultFlightArray[0].class = "selected";
      setDefaultOptionOne(defaultFlightArray[0]);
      setIsCheckedOne(false);
    }
  }, [searchResOne]);

  useEffect(() => {
    if (searchResTwo.length > 0) {
      const updatedArray = searchResTwo.map((obj) => ({
        ...obj,
        selected: false,
        class: "",
      }));
      setFlightOptionsTwo(updatedArray);
      const defaultFlightArray = [...updatedArray];
      defaultFlightArray[0].class = "selected";
      setDefaultOptionTwo(defaultFlightArray[0]);
      setIsCheckedTwo(false);
    }
  }, [searchResTwo]);

  useEffect(() => {
    if (searchResThree.length > 0) {
      const updatedArray = searchResThree.map((obj) => ({
        ...obj,
        selected: false,
        class: "",
      }));
      setFlightOptionsThree(updatedArray);
      const defaultFlightArray = [...updatedArray];
      defaultFlightArray[0].class = "selected";
      setDefaultOptionThree(defaultFlightArray[0]);
      setIsCheckedThree(false);
    }
  }, [searchResThree]);

  useEffect(() => {
    if (searchResFour.length > 0) {
      const updatedArray = searchResFour.map((obj) => ({
        ...obj,
        selected: false,
        class: "",
      }));
      setFlightOptionsFour(updatedArray);
      const defaultFlightArray = [...updatedArray];
      defaultFlightArray[0].class = "selected";
      setDefaultOptionFour(defaultFlightArray[0]);
      setIsCheckedFour(false);
    }
  }, [searchResFour]);

  useEffect(() => {
    if (searchResFive.length > 0) {
      const updatedArray = searchResFive.map((obj) => ({
        ...obj,
        selected: false,
        class: "",
      }));
      setFlightOptionsFive(updatedArray);
      const defaultFlightArray = [...updatedArray];
      defaultFlightArray[0].class = "selected";
      setDefaultOptionFive(defaultFlightArray[0]);
      setIsCheckedFive(false);
    }
  }, [searchResFive]);

  useEffect(() => {
    if (searchResSix.length > 0) {
      const updatedArray = searchResSix.map((obj) => ({
        ...obj,
        selected: false,
        class: "",
      }));
      setFlightOptionsSix(updatedArray);
      const defaultFlightArray = [...updatedArray];
      defaultFlightArray[0].class = "selected";
      setDefaultOptionSix(defaultFlightArray[0]);
      setIsCheckedSix(false);
    }
  }, [searchResSix]);

  useEffect(() => {
    if (parseInt(trip) !== 3) {
      if (parseInt(trip) === 1) {
        selectedFlight !== undefined &&
        defaultOption &&
        defaultOption.tid === selectedFlight.tid
          ? setIsChecked(true)
          : setIsChecked(false);
      } else if (parseInt(trip) === 2) {
        selectedFlight !== undefined &&
        defaultOption &&
        defaultOption.tid === selectedFlight.tid &&
        selectedRTFlight !== undefined &&
        returnDefaultOption &&
        returnDefaultOption.tid === selectedRTFlight.tid
          ? setIsChecked(true)
          : setIsChecked(false);
      }
    } else {
      if (selectedFlight !== undefined) {
        selectedFlight[0] !== undefined &&
        defaultOptionOne &&
        defaultOptionOne.tid === selectedFlight[0].tid
          ? setIsCheckedOne(true)
          : setIsCheckedOne(false);

        selectedFlight[1] !== undefined &&
        defaultOptionTwo &&
        defaultOptionTwo.tid === selectedFlight[1].tid
          ? setIsCheckedTwo(true)
          : setIsCheckedTwo(false);

        selectedFlight[2] !== undefined &&
        defaultOptionThree &&
        defaultOptionThree.tid === selectedFlight[2].tid
          ? setIsCheckedThree(true)
          : setIsCheckedThree(false);

        selectedFlight[3] !== undefined &&
        defaultOptionFour &&
        defaultOptionFour.tid === selectedFlight[3].tid
          ? setIsCheckedFour(true)
          : setIsCheckedFour(false);

        selectedFlight[4] !== undefined &&
        defaultOptionFive &&
        defaultOptionFive.tid === selectedFlight[4].tid
          ? setIsCheckedFive(true)
          : setIsCheckedFive(false);

        selectedFlight[5] !== undefined &&
        defaultOptionSix &&
        defaultOptionSix.tid === selectedFlight[5].tid
          ? setIsCheckedSix(true)
          : setIsCheckedSix(false);
      }
    }
  }, [selectedFlight]);

  useEffect(() => {
    selectedFlight !== undefined &&
    defaultOption &&
    defaultOption.tid === selectedFlight.tid &&
    selectedRTFlight !== undefined &&
    returnDefaultOption &&
    returnDefaultOption.tid === selectedRTFlight.tid
      ? setIsRTChecked(true)
      : setIsRTChecked(false);
  }, [selectedRTFlight]);

  //Checking Selected Flight Timings Diffrence between arrival time and return deparure time
  const validateSelectedFlight=()=>{
    if(defaultOption && returnDefaultOption){
      let timeDiff = DateUtils.calculateHoursandMinsbetweendates(returnDefaultOption.seg[0].dd, defaultOption.seg[defaultOption.seg.length - 1].ad);
      let minutes = _.cloneDeep(timeDiff);
      var hours = Math.floor(minutes / 60);
      if(hours >= 5){
        return true;
      }else{
        return false;  
      }
    }
  }

  const hidemessage = () => {
    setTimeout(function () {
      setNotiMessageShow(false);
      setNotiMessage("");
      setNotiVarient("");
    }, 3000);
    };

  const selectDefOptionsOnFareRuleHit = (isMulti) => {
      //On button click select the default options as selected
      let check = true;
      if(parseInt(trip) === 1){
        selectOption(null,defaultOption,"ow",isMulti);
      } else if(parseInt(trip) === 2){
        if(validateSelectedFlight()){
          selectOption(null,returnDefaultOption,"rt",isMulti);
        }else{
        setNotiMessageShow(true);
        setNotiMessage(context.translation && context.translation.booknowmsg);
        setNotiVarient("error");
        hidemessage();
        return false;
        }
      } else if(parseInt(trip) === 3){
        changeFlight(1,defaultOptionOne,false,false);
      }
        //Calling Fare rules
       if(check){
        fireFareRule(sectors);
       }
        
        //}
       
  }

  //To be executed on the change of flight selection
  const selectOption = (data, flight, type,isMuilti) => {
    if (type === "ow") {
        changeFlight(data, flight,isMuilti,true);
      if (trip === "2") {
        changeRTFlight(data, returnDefaultOption,isMuilti,true);
      }
    } else if (type === "rt") {
      changeRTFlight(data, flight,isMuilti,true);
      changeFlight(data, defaultOption,isMuilti,false);
    }
  };
  //Calling Price check to Update the Price
  const fireAirArabaiPriceCheck=(flt,tripType,sel_type,isMultiOpt,checPriceCall)=>{
    setLoading(true);
    let payload="";
    if(tripType===1){
    const request={
      "tid":flt.tid,
      "ac":flt.seg[0].ma,
      "tt": tripType,
      "dep": srequest.srcCode,
      "arr": srequest.destCode,
      "adt": Number(srequest.adt),
      "chd": Number(srequest.chd),
      "inf": Number(srequest.inf),
      "dd": srequest.dd,
      "ad": srequest.ad,
      "cl":srequest.cls,
      "ns": "false",
      "cid": FLIGHT_SEARCH_CID,
      "sty": srequest.sty,
      "cur": srequest.cur,
      "region_type" : regionType,
      "req_cur":BASE_CURRENCY,
      "region_cur":context.regionCur!==undefined && context.regionCur!==""?context.regionCur:localStorage.getItem("REGION_CUR"),
      "origns": [{
         "srcorigns": [flt]
       }]
  }
  payload=request;
}else{
  let src=[];
  let dest=[];
  if(sel_type==="onward"){
    src=[flt]
    dest=[returnDefaultOption];
  }else{
    src=[defaultOption]
    dest=[flt];
  }
  const request1={
    "tid":flt.tid,
    "ac":flt.seg[0].ma,
    "tt": tripType,
    "dep": srequest.srcCode,
    "arr": srequest.destCode,
    "adt": Number(srequest.adt),
    "chd": Number(srequest.chd),
    "inf": Number(srequest.inf),
    "dd": srequest.dd,
    "ad": srequest.ad,
    "cl":srequest.cl,
    "ns": "false",
    "cid": FLIGHT_SEARCH_CID,
    "sty": srequest.sty,
    "cur": srequest.cur,
    "region_type" : regionType,
    "req_cur":BASE_CURRENCY,
    "region_cur":context.regionCur!==undefined && context.regionCur!==""?context.regionCur:localStorage.getItem("REGION_CUR"),
    "origns": [{
       "srcorigns": src,
       "destorigins":dest
     }]
}
payload=request1;
}
if(checPriceCall){
  try {
    let tokenKey = Utilities.generateAPIToken(payload);
    CntCommonService.fetchAirArabaiPrice(payload,tokenKey).then(response => {
       if(response.data.suc && response.data.data !== undefined){
         setLoading(false);
         if(isMultiOpt){
         if(tripType===1){
           let sfValOld=serviceFee.length!==0?serviceFee[0].sf:0
           let sfValNew=response.data.data.origns[0].srcorigns[0].fareop[0].servicefee.length!==0?response.data.data.origns[0].srcorigns[0].fareop[0].servicefee[0].sf:0
           let oldPrice=Number(selPrice.tf) + Number(sfValOld);
           let newPrice=Number(response.data.data.origns[0].srcorigns[0].fareop[0].pr.tf) + Number(sfValNew);
           if(Number(newPrice) > Number(oldPrice)){
             setSelAAFlt(flt)
             setAaFareOp(response.data.data.origns[0].srcorigns[0].fareop);
             setDefaultOption(response.data.data.origns[0].srcorigns[0]);
             setOldAAPrice(oldPrice);
             setNewAAPrice(newPrice);
             setShowPopup(true);
           }else{
             updateFlight(flt,response,"onward");
           }
         }else{
           if(sel_type==="onward"){
             let sfValOld1=returnDefaultOption.fareop[0].servicefee.length!==0?returnDefaultOption.fareop[0].servicefee[0].sf:0
             let sfValOld2=flt.fareop[0].servicefee.length!==0?flt.fareop[0].servicefee[0].sf:0
             let sfValNew1=response.data.data.origns[0].srcorigns[0].fareop[0].servicefee.length!==0?response.data.data.origns[0].srcorigns[0].fareop[0].servicefee[0].sf:0
             let sfValNew2=response.data.data.origns[0].destorigins[0].fareop[0].servicefee.length!==0?response.data.data.origns[0].destorigins[0].fareop[0].servicefee[0].sf:0
             let oldPrice=Number(returnDefaultOption.fareop[0].pr.tf) + Number(flt.fareop[0].pr.tf) + Number(sfValOld1) + Number(sfValOld2);
             let newPrice=Number(response.data.data.origns[0].srcorigns[0].fareop[0].pr.tf) + Number(response.data.data.origns[0].destorigins[0].fareop[0].pr.tf) + Number(sfValNew1) + Number(sfValNew2);
             if(Number(newPrice) > Number(oldPrice)){
               setSelAAFlt(flt);
               setSelAAFltRt(response.data.data.origns[0].destorigins)
               setAaFareOp(flt.fareop);
               setAaFareOpRt(response.data.data.origns[0].destorigins[0].fareop);
               setOldAAPrice(oldPrice);
               setNewAAPrice(newPrice);
               setShowPopup(true);
             }else{
               updateFlight(flt,response,"onward")
             }
           }else{
             if(defaultOption.fareop!==undefined && defaultOption.fareop.length > 0){
             let sfValOld1=defaultOption.fareop[0].servicefee.length!==0?defaultOption.fareop[0].servicefee[0].sf:0
             let sfValOld2=flt.fareop!==undefined && flt.fareop.length > 0 && flt.fareop[0].servicefee!==undefined && flt.fareop[0].servicefee.length!==0?flt.fareop[0].servicefee[0].sf:0
             let sfValNew1=response.data.data.origns[0].srcorigns[0].fareop[0].servicefee.length!==0?response.data.data.origns[0].srcorigns[0].fareop[0].servicefee[0].sf:0
             let sfValNew2=response.data.data.origns[0].destorigins[0].fareop[0].servicefee.length!==0?response.data.data.origns[0].destorigins[0].fareop[0].servicefee[0].sf:0
             let oldPrice=Number(defaultOption.fareop[0].pr.tf) + Number(flt.fareop[0].pr.tf) + Number(sfValOld1) + Number(sfValOld2);
             let newPrice=Number(response.data.data.origns[0].srcorigns[0].fareop[0].pr.tf) + Number(response.data.data.origns[0].destorigins[0].fareop[0].pr.tf) + Number(sfValNew1) + Number(sfValNew2);
             if(Number(newPrice) > Number(oldPrice)){
               setSelAAFlt(response.data.data.origns[0].srcorigns[0].fareop[0])
               setSelAAFltRt(flt);
               setAaFareOpRt(flt.fareop);
               setAaFareOp(response.data.data.origns[0].srcorigns[0].fareop);
               setOldAAPrice(oldPrice);
               setNewAAPrice(newPrice);
               setShowPopup(true);
             }
             }else{
               updateFlight(flt,response,"return")
             }
           }
         }
       }else{
         if(tripType===1) {
           props.updateFlt(flt);
           props.updateSty(sty);
           flt.selected = true;
           setDefaultOption(flt);
           const updatedArray = searchRes.map((obj) => ({
             ...obj,
             selected: obj.tid === flt.tid ? true : false,
             class: obj.tid === flt.tid ? "selected" : "",
           }));
           setFlightOptions(updatedArray);        
         } else if (tripType===2) {
           if(sel_type==="onward"){          
             props.updateFlt(flt);
             props.updateSty(sty);
             flt.selected = true;
             setDefaultOption(flt);
             const updatedArray = searchRes.map((obj) => ({
               ...obj,
               selected: obj.tid === flt.tid ? true : false,
               class: obj.tid === flt.tid ? "selected" : "",
             }));
             setFlightOptions(updatedArray);      
   
             props.updateRTFlt(returnDefaultOption);
             props.updateSty(sty);
             returnDefaultOption.selected = true;
             setReturnDefaultOption(returnDefaultOption);
             const updatedArray1 = searchRTRes.map((obj) => ({
               ...obj,
               selected: obj.tid === returnDefaultOption.tid ? true : false,
               class: obj.tid === returnDefaultOption.tid ? "selected" : "",
             }));
             setReturnFlightOptions(updatedArray1);
           }else{
             props.updateFlt(defaultOption);
             props.updateSty(sty);
             defaultOption.selected = true;
             setDefaultOption(defaultOption);
             const updatedArray = searchRes.map((obj) => ({
               ...obj,
               selected: obj.tid === defaultOption.tid ? true : false,
               class: obj.tid === defaultOption.tid ? "selected" : "",
             }));
             setFlightOptions(updatedArray);      
   
             props.updateRTFlt(flt);
             props.updateSty(sty);
             flt.selected = true;
             setReturnDefaultOption(flt);
             const updatedArray1 = searchRTRes.map((obj) => ({
               ...obj,
               selected: obj.tid === flt.tid ? true : false,
               class: obj.tid === flt.tid ? "selected" : "",
             }));
             setReturnFlightOptions(updatedArray1);
           }
         }
       }
       }else{
         setLoading(false)
         setShowPriceFailed(true)
       }
     }).catch(function (error) {
      console.error("Error:" + error);      
    });    
  } catch (error) {
    console.error("Error: " + error);    
  }
  }
  }
  const updateFlight=(flt,resp,type)=>{
    flt.fareop=resp.data.data.origns[0].srcorigns[0].fareop;
    if(type==="onward"){
      props.updateSty(sty);
      flt.selected = true;
      props.updateFlt(resp.data.data.origns[0].srcorigns[0]);
      setDefaultOption(resp.data.data.origns[0].srcorigns[0]);
      const updatedArray = searchRes.map((obj) => ({
        ...obj,
        selected: obj.tid === flt.tid ? true : false,
        class: obj.tid === flt.tid ? "selected" : "",
      }));
      setFlightOptions(updatedArray);
    }else{
      flt.fareop=resp.data.data.origns[0].srcorigns[0].fareop;
      props.updateSty(sty);
      flt.selected = true;
      props.updateRTFlt(resp.data.data.origns[0].srcorigns[0]);
      setReturnDefaultOption(resp.data.data.origns[0].srcorigns[0]);
      const updatedArray = searchRTRes.map((obj) => ({
        ...obj,
        selected: obj.tid === flt.tid ? true : false,
        class: obj.tid === flt.tid ? "selected" : "",
      }));
      setReturnFlightOptions(updatedArray);
    }
  }
  const changeFlight = (count, flight,isMultiOpt,check) => {
    if (parseInt(trip) !== 3) {
      if(parseInt(defaultOption.sup) === 2 && sty==="normal"){//Calling Air Arabia Price check
          fireAirArabaiPriceCheck(flight,parseInt(trip),"onward",isMultiOpt,check);
      }else{
        props.updateFlt(flight);
        props.updateSty(sty);
        flight.selected = true;
        setDefaultOption(flight);
        const updatedArray = searchRes.map((obj) => ({
          ...obj,
          selected: obj.tid === flight.tid ? true : false,
          class: obj.tid === flight.tid ? "selected" : "",
        }));
        setFlightOptions(updatedArray);
      }
    } else {
      let selResult = [];
      if (count === 1) {
        selResult.push(flight);
        selResult.push(defaultOptionTwo);
        selResult.push(defaultOptionThree);
        selResult.push(defaultOptionFour);
        selResult.push(defaultOptionFive);
        selResult.push(defaultOptionSix);
        props.updateFlt(count, selResult);
        flight.selected = true;
        setDefaultOptionOne(flight);
        const updatedArray = searchResOne.map((obj) => ({
          ...obj,
          selected: obj.tid === flight.tid ? true : false,
          class: obj.tid === flight.tid ? "selected" : "",
        }));
        setFlightOptionsOne(updatedArray);
      } else if (count === 2) {
        selResult.push(defaultOptionOne);
        selResult.push(flight);
        selResult.push(defaultOptionThree);
        selResult.push(defaultOptionFour);
        selResult.push(defaultOptionFive);
        selResult.push(defaultOptionSix);
        props.updateFlt(count, selResult);
        flight.selected = true;
        setDefaultOptionTwo(flight);
        const updatedArray = searchResTwo.map((obj) => ({
          ...obj,
          selected: obj.tid === flight.tid ? true : false,
          class: obj.tid === flight.tid ? "selected" : "",
        }));
        setFlightOptionsTwo(updatedArray);
      } else if (count === 3) {
        selResult.push(defaultOptionOne);
        selResult.push(defaultOptionTwo);
        selResult.push(flight);
        selResult.push(defaultOptionFour);
        selResult.push(defaultOptionFive);
        selResult.push(defaultOptionSix);
        props.updateFlt(count, selResult);
        flight.selected = true;
        setDefaultOptionThree(flight);
        const updatedArray = searchResThree.map((obj) => ({
          ...obj,
          selected: obj.tid === flight.tid ? true : false,
          class: obj.tid === flight.tid ? "selected" : "",
        }));
        setFlightOptionsThree(updatedArray);
      } else if (count === 4) {
        selResult.push(defaultOptionOne);
        selResult.push(defaultOptionTwo);
        selResult.push(defaultOptionThree);
        selResult.push(flight);
        selResult.push(defaultOptionFive);
        selResult.push(defaultOptionSix);
        props.updateFlt(count, selResult);
        flight.selected = true;
        setDefaultOptionFour(flight);
        const updatedArray = searchResFour.map((obj) => ({
          ...obj,
          selected: obj.tid === flight.tid ? true : false,
          class: obj.tid === flight.tid ? "selected" : "",
        }));
        setFlightOptionsFour(updatedArray);
      } else if (count === 5) {
        selResult.push(defaultOptionOne);
        selResult.push(defaultOptionTwo);
        selResult.push(defaultOptionThree);
        selResult.push(defaultOptionFour);
        selResult.push(flight);
        selResult.push(defaultOptionSix);
        props.updateFlt(count, selResult);
        flight.selected = true;
        setDefaultOptionFive(flight);
        const updatedArray = searchResFive.map((obj) => ({
          ...obj,
          selected: obj.tid === flight.tid ? true : false,
          class: obj.tid === flight.tid ? "selected" : "",
        }));
        setFlightOptionsFive(updatedArray);
      } else if (count === 6) {
        selResult.push(defaultOptionOne);
        selResult.push(defaultOptionTwo);
        selResult.push(defaultOptionThree);
        selResult.push(defaultOptionFour);
        selResult.push(defaultOptionFive);
        selResult.push(flight);
        props.updateFlt(count, selResult);
        flight.selected = true;
        setDefaultOptionSix(flight);
        const updatedArray = searchResSix.map((obj) => ({
          ...obj,
          selected: obj.tid === flight.tid ? true : false,
          class: obj.tid === flight.tid ? "selected" : "",
        }));
        setFlightOptionsSix(updatedArray);
      }
    }
  };

  //To be executed on the change of return flight selection
  const changeRTFlight = (data, flight,isMulti,check) => {
    if(parseInt(defaultOption.sup) === 2 && sty==="normal"){//Calling Air Arabia Price check
        fireAirArabaiPriceCheck(flight,parseInt(trip),"return",isMulti,check);
    }else{
      props.updateRTFlt(flight);
      props.updateSty(sty);
      flight.selected = true;
      setReturnDefaultOption(flight);
      const updatedArray = searchRTRes.map((obj) => ({
        ...obj,
        selected: obj.tid === flight.tid ? true : false,
        class: obj.tid === flight.tid ? "selected" : "",
      }));
      setReturnFlightOptions(updatedArray);
    }
  };

  const cancelShowPopUp=()=>{
    setShowPopup(false)
  }

  const closePopUp=()=>{
    setShowPriceFailed(false);
  }

  const updateAirArabiaprice=()=>{
         props.updateSty(sty);
        if(parseInt(trip)===1){
          selAAFlt.fareop=aaFareOp;
          selAAFlt.selected = true;
          props.updateFlt(selAAFlt);
          const updatedArray = searchRes.map((obj) => ({
            ...obj,
            selected: obj.tid === selAAFlt.tid ? true : false,
            class: obj.tid === selAAFlt.tid ? "selected" : "",
          }));
          setFlightOptions(updatedArray);
          setDefaultOption(selAAFlt);
        }else{
          //OW
          selAAFlt.fareop=aaFareOp;
          selAAFlt.selected = true;
          props.updateFlt(selAAFlt);
          const updatedArray = searchRes.map((obj) => ({
            ...obj,
            selected: obj.tid === selAAFlt.tid ? true : false,
            class: obj.tid === selAAFlt.tid ? "selected" : "",
          }));
          setFlightOptions(updatedArray);
          setDefaultOption(selAAFlt);
          //RT
          selAAFltRt.fareop=aaFareOpRt;
          selAAFltRt.selected = true;
          props.updateRTFlt(selAAFltRt);
          const updatedArray1 = searchRTRes.map((obj) => ({
            ...obj,
            selected: obj.tid === selAAFltRt.tid ? true : false,
            class: obj.tid === selAAFltRt.tid ? "selected" : "",
          }));
          setReturnFlightOptions(updatedArray1);
          setReturnDefaultOption(selAAFltRt);
          }
        setShowUpdatedPrice(true);
        setShowPopup(false)
  }

  const showDrawer = () => {
    setOpen(true);
  };

  const openForMulti = (index) => {
    if (index === 1) {
      return openOne;
    } else if (index === 2) {
      return openTwo;
    } else if (index === 3) {
      return openThree;
    } else if (index === 4) {
      return openFour;
    } else if (index === 5) {
      return openFive;
    } else if (index === 6) {
      return openSix;
    } else if (index === 7) {
      return openSeven;
    } else if (index === 8) {
      return openEight;
    } else if (index === 9) {
      return openNine;
    } else if (index === 10) {
      return openTen;
    }
  };

  const showMultiDrawer = (e, index) => {
    if (index === 0) {
      setOpenOne(true);
    } else if (index === 1) {
      setOpenTwo(true);
    } else if (index === 2) {
      setOpenThree(true);
    } else if (index === 3) {
      setOpenFour(true);
    } else if (index === 4) {
      setOpenFive(true);
    } else if (index === 5) {
      setOpenSix(true);
    } else if (index === 6) {
      setOpenSeven(true);
    } else if (index === 7) {
      setOpenEight(true);
    } else if (index === 8) {
      setOpenNine(true);
    } else if (index === 9) {
      setOpenTen(true);
    }
  };

  const onCloseMulti = (e, index) => {
    if (index === 1) {
      setOpenOne(false);
    } else if (index === 2) {
      setOpenTwo(false);
    } else if (index === 3) {
      setOpenThree(false);
    } else if (index === 4) {
      setOpenFour(false);
    } else if (index === 5) {
      setOpenFive(false);
    } else if (index === 6) {
      setOpenSix(false);
    } else if (index === 7) {
      setOpenSeven(false);
    } else if (index === 8) {
      setOpenEight(false);
    } else if (index === 9) {
      setOpenNine(false);
    } else if (index === 10) {
      setOpenTen(false);
    }
  };

  const onClose = () => {
    setOpen(false);
  };

  const updatePrice = () => {
    if (defaultOption && returnDefaultOption && sty && sty === "split") {
      let owPaxPr =
        parseInt(defaultOption.sup) === 0
          ? defaultOption.paxpr
          : defaultOption.fareop[0]?.paxpr;
      let owPr =
        parseInt(defaultOption.sup) === 0
          ? defaultOption.pr
          : defaultOption.fareop[0]?.pr;
      let rtPaxPr =
        parseInt(returnDefaultOption.sup) === 0
          ? returnDefaultOption.paxpr
          : returnDefaultOption.fareop[0]?.paxpr;
      let rtPr =
        parseInt(returnDefaultOption.sup) === 0
          ? returnDefaultOption.pr
          : returnDefaultOption.fareop[0]?.pr;
      //Now prepare final paxpr object with owPaxPr and rtPaxPr
      let mainPaxPr = calculateMainPaxPr(owPaxPr, rtPaxPr);
      if (mainPaxPr) setPaxPrice(mainPaxPr);
      let mainPr = calculateMainPr(owPr, rtPr);
      if (mainPr) setSelPrice(mainPr);
    } else if (
      defaultOption &&
      returnDefaultOption &&
      sty &&
      sty === "normal"
    ) {
      setPaxPrice(paxpr);
      setSelPrice(sectors.pr);
    }
  };

  const calculateMainPaxPr = (owpaxpr, rtpaxpr) => {
    const mainpaxpr = [];
    for (const paxType of ["ADT", "CHD", "INF"]) {
      const owpaxprForType =
        owpaxpr && owpaxpr.find((pax) => pax.ptype === paxType);
      const rtpaxprForType =
        rtpaxpr && rtpaxpr.find((pax) => pax.ptype === paxType);
      if (owpaxprForType && rtpaxprForType) {
        const sup_fareArray1 = owpaxprForType.sup_fare?.split("_").map(Number);
        const sup_fareArray2 = rtpaxprForType.sup_fare?.split("_").map(Number);
        const sup_fareSumArray =
          sup_fareArray1 &&
          sup_fareArray2 &&
          sup_fareArray1.map((value, index) => value + sup_fareArray2[index]);
        const combinedSupFare = sup_fareSumArray && sup_fareSumArray.join("_");
        const psup_fareArray1 = owpaxprForType.psup_fare
          ?.split("_")
          .map(Number);
        const psup_fareArray2 = rtpaxprForType.psup_fare
          ?.split("_")
          .map(Number);
        const psup_fareSumArray =
          psup_fareArray1 &&
          psup_fareArray2 &&
          psup_fareArray1.map((value, index) => value + psup_fareArray2[index]);
        const combinedPSupFare =
          psup_fareSumArray && psup_fareSumArray.join("_");
        mainpaxpr.push({
          ptype: paxType,
          bf: owpaxprForType.bf + rtpaxprForType.bf,
          tf: owpaxprForType.tf + rtpaxprForType.tf,
          tax: sumTaxArrays(owpaxprForType.tax, rtpaxprForType.tax),
          scur: owpaxprForType.scur,
          sup_fare: combinedSupFare,
          psup_fare: combinedPSupFare,
        });
      }
    }
    return mainpaxpr;
  };

  const calculateMainPr = (owpr, rtpr) => {
    const mainpr = {
      bf: (owpr && owpr.bf ? owpr.bf : 0) + (rtpr && rtpr.bf ? rtpr.bf : 0),
      tf: (owpr && owpr.tf ? owpr.tf : 0) + (rtpr && rtpr.tf ? rtpr.tf : 0),
      tax: owpr && rtpr && sumTaxArrays(owpr.tax, rtpr.tax),
      scur: owpr && owpr.scur,
      sup_fare: (() => {
        const sup_fareArray1 = owpr && owpr.sup_fare?.split("_").map(Number);
        const sup_fareArray2 = rtpr && rtpr.sup_fare?.split("_").map(Number);
        if (sup_fareArray1 && sup_fareArray2) {
          const sup_fareSumArray = sup_fareArray1.map(
            (value, index) => value + sup_fareArray2[index]
          );
          return sup_fareSumArray && sup_fareSumArray.join("_");
        }
      })(),
      ttf: owpr && rtpr && owpr.ttf + rtpr.ttf,
    };
    return mainpr;
  };

  const sumTaxArrays = (arr1, arr2) => {
    const result = [];
    const maxLength = Math.max(arr1.length, arr2.length);
    for (let i = 0; i < maxLength; i++) {
      const taxObj = {};
      if (i < arr1.length) {
        for (const key in arr1[i]) {
          taxObj[key] = arr1[i][key] || 0;
        }
      }
      if (i < arr2.length) {
        for (const key in arr2[i]) {
          taxObj[key] = (taxObj[key] || 0) + (arr2[i][key] || 0);
        }
      }
      result.push(taxObj);
    }
    return result;
  };

  return (
    <>
     <div className="rb-contLeft">
     {notiMessageShow ? (
        <Alert
          description={notiMessage}
          type={notiVarient}
          closable
          onClose={() => setNotiMessageShow(false)}
          className="alertForm"
          // showIcon
          style={{zIndex:99999}}
        >
          {notiMessage}
        </Alert>
      ) : (
        ""
      )}
     {loading && (
        <LoaderN
          title={context.translation && context.translation.pleaseWait}
          subtitle={context.translation && context.translation.redir}
        />
      )}
      {trip !== "3" && trip !== 3 && searchRes && searchRes.length > 0 && (
        <>
          {
            <ResultSectionHeader
              mapping={mapping}
              sectors={sect}
              secType={secType}
              secTypeIndex={secTypeIndex}
              trip={trip}
              refund={defaultOption ? defaultOption.re : undefined}
            />
          }
          {defaultOption && (
            <div className="rb-mid">
                  <TripDetails
                    flt={defaultOption}
                    seg={defaultOption.seg[0]}
                    sty={sty}
                    price={price}
                    currency={currency}
                    sectors={sectors}
                    serviceFee={serviceFee}
                    mapping={mapping}
                  />
               </div>  
          )}
          {returnDefaultOption && (
            <>
              <ResultSectionHeader
                mapping={mapping}
                sectors={sect}
                secType={"arrival"}
                secTypeIndex={secTypeIndex}
                trip={trip}
                refund={
                  returnDefaultOption ? returnDefaultOption.re : undefined
                }
              />
              <div className="rb-mid">
                    <TripDetails
                      flt={returnDefaultOption}
                      seg={returnDefaultOption.seg[0]}
                      sty={sty}
                      price={price}
                      currency={currency}
                      sectors={sectors}
                      serviceFee={serviceFee}
                      mapping={mapping}
                    />
                </div>  
            </>
          )}
          <div className="rb-bot">
            {defaultOption && (
              <div className="dFlex flightDetails">
                <Link onClick={showDrawer} className="flightDetailsFirst">
                  {context.translation && context.translation.flgtDetls}
                </Link>
                <Drawer placement="right" open={open} active={"tab1"} className="paxDetailsPopup">
                  <i className="icon icon-cancel" onClick={onClose}></i>
                  <FlightDetailsPopup
                    flt={defaultOption}
                    seg={defaultOption.seg[0]}
                    rtnflt={returnDefaultOption}
                    rtnseg={
                      returnDefaultOption ? returnDefaultOption.seg[0] : ""
                    }
                    sty={sty}
                    price={price}
                    currency={currency}
                    sectors={sect}
                    serviceFee={serviceFee}
                    mapping={mapping}
                    secType={secType}
                    secTypeIndex={secTypeIndex}
                    trip={trip}
                    req={srequest}
                    paxpr={paxPrice}
                    regionType={regionType}
                    pr={selPrice}
                    sf={serviceFee}
                    refund={defaultOption ? defaultOption.re : undefined}
                    open={open}
                    updatePrice={updatePrice}
                    flow={"SRP"}
                    base_usr_exrate={base_usr_exrate}
                  />
                </Drawer>
                {(((parseInt(trip) === 1 || parseInt(trip) === 3) &&
                  searchRes &&
                  searchRes.length > 1) ||
                  (parseInt(trip) === 2 &&
                    searchRes &&
                    searchRTRes &&
                    (searchRes.length > 1 || searchRTRes.length > 1))) && (
                  <Link
                    onClick={(e) => setShowFlightOptions(!showFlightOptions)}
                    className={showFlightOptions && "active"}
                  >
                    {!showFlightOptions &&
                      (searchRTRes
                        ? searchRes.length > searchRTRes.length
                          ? searchRes.length
                          : searchRTRes.length
                        : searchRes.length)}
                    {showFlightOptions && (context.translation && context.translation.hide)} {context.translation && context.translation.flgtOpt}
                  </Link>
                )}
              </div>
            )}
          </div>
          <div className="moreFlightOptions">
            {(trip === "1" || parseInt(trip) === 3) && (
              <>
                {" "}
                {showFlightOptions &&
                  flightOptions &&
                  flightOptions.length > 0 &&
                  flightOptions.map((flt, index) => (
                    <>
                      <div className={flt.class} key={index}>
                        <Col onClick={(e) => changeFlight(e, flt,true,true)}>
                          <TripDetails
                            flt={flt}
                            seg={flt.seg[0]}
                            sty={sty}
                            price={price}
                            currency={currency}
                            sectors={sectors}
                            serviceFee={serviceFee}
                            mapping={mapping}
                          />
                        </Col>
                      </div>
                    </>
                  ))}
              </>
            )}

            {trip === "2" && (
              <>
                {" "}
                {showFlightOptions && (
                  <div className="roundTrip">
                    <div className="flexItemLeft">
                      <div className="roundTripInfo">
                        <ResultSectionOptionsHeader
                          mapping={mapping}
                          sectors={sect}
                          secType={"onward"}
                          secTypeIndex={secTypeIndex}
                          trip={trip}
                          showDate={true}
                        />
                        {flightOptions &&
                          flightOptions.length > 0 &&
                          flightOptions.map((flt, index) => (
                            <>
                              {trip === "2" && (
                                <Col onClick={(e) => changeFlight(e, flt,true,true)}>
                                  <ReturnTripDetails
                                    flt={flt}
                                    seg={flt.seg[0]}
                                    sty={sty}
                                    price={price}
                                    currency={currency}
                                    sectors={sectors}
                                    serviceFee={serviceFee}
                                    mapping={mapping}
                                  />
                                </Col>
                              )}
                            </>
                          ))}
                      </div>
                    </div>
                    <div className="flexItemRight">
                      <div className="roundTripInfo">
                        <ResultSectionOptionsHeader
                          mapping={mapping}
                          sectors={sect}
                          secType={"arrival"}
                          secTypeIndex={secTypeIndex}
                          trip={trip}
                          showDate={true}
                        />
                        {returnFlightOptions &&
                          returnFlightOptions.length > 0 &&
                          returnFlightOptions.map((flt, index) => (
                            <>
                              {trip === "2" && (
                                <div className={flt.class}>
                                  <Col onClick={(e) => changeRTFlight(e, flt,true,true)}>
                                    <ReturnTripDetails
                                      flt={flt}
                                      seg={flt.seg[0]}
                                      sty={sty}
                                      price={price}
                                      currency={currency}
                                      sectors={sectors}
                                      serviceFee={serviceFee}
                                      mapping={mapping}
                                    />
                                  </Col>
                                </div>
                              )}
                            </>
                          ))}
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </>
      )}
      {parseInt(trip) === 3 && (
        <FlightMultiCityBlock
          searchRes={searchResOne}
          defaultOption={defaultOptionOne}
          count={1}
          isChecked={isCheckedOne}
          showFlightOptions={showFlightOptionsOne}
          flightOptions={flightOptionsOne}
          setShowFlightOptions={setShowFlightOptionsOne}
          mapping={mapping}
          sect={sect}
          trip={trip}
          changeFlight={changeFlight}
          sty={sty}
          price={price}
          currency={currency}
          sectors={sectors}
          serviceFee={serviceFee}
          openForMulti={openForMulti}
          onCloseMulti={onCloseMulti}
          secType={secType}
          secTypeIndex={secTypeIndex}
          srequest={srequest}
          paxpr={paxpr}
          regionType={regionType}
          showMultiDrawer={showMultiDrawer}
          base_usr_exrate={base_usr_exrate}
        />
      )}
      {parseInt(trip) === 3 && (
        <FlightMultiCityBlock
          searchRes={searchResTwo}
          defaultOption={defaultOptionTwo}
          count={2}
          isChecked={isCheckedTwo}
          showFlightOptions={showFlightOptionsTwo}
          flightOptions={flightOptionsTwo}
          setShowFlightOptions={setShowFlightOptionsTwo}
          mapping={mapping}
          sect={sect}
          trip={trip}
          changeFlight={changeFlight}
          sty={sty}
          price={price}
          currency={currency}
          sectors={sectors}
          serviceFee={serviceFee}
          openForMulti={openForMulti}
          onCloseMulti={onCloseMulti}
          secType={secType}
          secTypeIndex={secTypeIndex}
          srequest={srequest}
          paxpr={paxpr}
          regionType={regionType}
          showMultiDrawer={showMultiDrawer}
          base_usr_exrate={base_usr_exrate}
        />
      )}
      {parseInt(trip) === 3 && (
        <FlightMultiCityBlock
          searchRes={searchResThree}
          defaultOption={defaultOptionThree}
          count={3}
          isChecked={isCheckedThree}
          showFlightOptions={showFlightOptionsThree}
          flightOptions={flightOptionsThree}
          setShowFlightOptions={setShowFlightOptionsThree}
          mapping={mapping}
          sect={sect}
          trip={trip}
          changeFlight={changeFlight}
          sty={sty}
          price={price}
          currency={currency}
          sectors={sectors}
          serviceFee={serviceFee}
          openForMulti={openForMulti}
          onCloseMulti={onCloseMulti}
          secType={secType}
          secTypeIndex={secTypeIndex}
          srequest={srequest}
          paxpr={paxpr}
          regionType={regionType}
          showMultiDrawer={showMultiDrawer}
          base_usr_exrate={base_usr_exrate}
        />
      )}
      {parseInt(trip) === 3 && (
        <FlightMultiCityBlock
          searchRes={searchResFour}
          defaultOption={defaultOptionFour}
          count={4}
          isChecked={isCheckedFour}
          showFlightOptions={showFlightOptionsFour}
          flightOptions={flightOptionsFour}
          setShowFlightOptions={setShowFlightOptionsFour}
          mapping={mapping}
          sect={sect}
          trip={trip}
          changeFlight={changeFlight}
          sty={sty}
          price={price}
          currency={currency}
          sectors={sectors}
          serviceFee={serviceFee}
          openForMulti={openForMulti}
          onCloseMulti={onCloseMulti}
          secType={secType}
          secTypeIndex={secTypeIndex}
          srequest={srequest}
          paxpr={paxpr}
          regionType={regionType}
          showMultiDrawer={showMultiDrawer}
          base_usr_exrate={base_usr_exrate}
        />
      )}
      {parseInt(trip) === 3 && (
        <FlightMultiCityBlock
          searchRes={searchResFive}
          defaultOption={defaultOptionFive}
          count={5}
          isChecked={isCheckedFive}
          showFlightOptions={showFlightOptionsFive}
          flightOptions={flightOptionsFive}
          setShowFlightOptions={setShowFlightOptionsFive}
          mapping={mapping}
          sect={sect}
          trip={trip}
          changeFlight={changeFlight}
          sty={sty}
          price={price}
          currency={currency}
          sectors={sectors}
          serviceFee={serviceFee}
          openForMulti={openForMulti}
          onCloseMulti={onCloseMulti}
          secType={secType}
          secTypeIndex={secTypeIndex}
          srequest={srequest}
          paxpr={paxpr}
          regionType={regionType}
          showMultiDrawer={showMultiDrawer}
          base_usr_exrate={base_usr_exrate}
        />
      )}
      {parseInt(trip) === 3 && (
        <FlightMultiCityBlock
          searchRes={searchResSix}
          defaultOption={defaultOptionSix}
          count={6}
          isChecked={isCheckedSix}
          showFlightOptions={showFlightOptionsSix}
          flightOptions={flightOptionsSix}
          setShowFlightOptions={setShowFlightOptionsSix}
          mapping={mapping}
          sect={sect}
          trip={trip}
          changeFlight={changeFlight}
          sty={sty}
          price={price}
          currency={currency}
          sectors={sectors}
          serviceFee={serviceFee}
          openForMulti={openForMulti}
          onCloseMulti={onCloseMulti}
          secType={secType}
          secTypeIndex={secTypeIndex}
          srequest={srequest}
          paxpr={paxpr}
          regionType={regionType}
          showMultiDrawer={showMultiDrawer}
          base_usr_exrate={base_usr_exrate}
        />
      )}
      </div>
      <div className="rb-contRight">
                      <Tooltip placement="top" title={context.translation && context.translation.share}>
                      <Link className="icon icon-share" onClick={e => handleShortenUrl(sectors)}></Link>
                      </Tooltip>
                        <div className="rb-price">
                          <div className="bgPriceDetail">
                         <em> 
                            {srequest.cur}{" "}
                           </em>
                           {!showUpdatePrice &&
                           <FormatCurrency
                           value={Utilities.addPrice(
                             sectors.pr.tf,
                             sectors.servicefee && sectors.servicefee[0]
                               ? sectors.servicefee[0].complete_sfvalue
                               : 0,
                             "Add"
                           ) * base_usr_exrate}
                           className="currencyAmount"
                         />
                           }
                           {showUpdatePrice &&
                           <FormatCurrency
                           value={newAAPrice * base_usr_exrate}
                           className="currencyAmount"
                           />
                           }
                         
                          <span className="travellerPrice">
                          ({context.translation && context.translation.priceFor}{" "}
                          {Number(srequest.adt) + Number(srequest.chd) + Number(srequest.inf)}{" "}
                          {Number(srequest.adt) + Number(srequest.chd) + Number(srequest.inf) > 1
                            ? context.translation && context.translation.travellers
                            : context.translation && context.translation.traveller}
                          )
                        </span>
                        </div>
                          <Button
                            type="primary"
                            className="cntBtn"
                            onClick={(e) => selectDefOptionsOnFareRuleHit(false)}
                          >
                            {context.translation && context.translation.bokFlgt}
                            <img src={BrandIcon} alt="brandIcon" className="brandIcon" />
                            {/* <i className="sprite btn-arrow-sprite"></i> */}
                          </Button>
                        </div>
                      </div>
                      <div className="botSemicircle"></div>
                      <div className="botSemicircleCover"></div>
                      {props.index===0 && (
<>
<Modal
                  open={showPopup}
                  onOk={updateAirArabiaprice}
                  onCancel={cancelShowPopUp}
                  okText={context.translation && context.translation.continue}
                  cancelText="Cancel"
                  className="fareChangeAlert"
                  centered
                  >
                  <Title level={4}>
                  <span>{context.translation && context.translation.airfarechangedesc}</span><br/><br/>
                    <i className="icon icon-price-arrow"></i> {context.translation && context.translation.fareAlert}
                  </Title>
                 
                  <div className="fareStatusWrap dashedSeparator">
                    <div>
                        <span className="fareStatus">{context.translation && context.translation.currentFare}</span>
                    </div>
                  <div className="bgGrayColor">
                      <span className="currencyCode">{srequest.cur}</span>
                      <span> <FormatCurrency value={oldAAPrice * base_usr_exrate} className="currencyAmount"  /></span>
                    </div>
                  </div>
                  
                  <div className="fareStatusWrap solidSeparator">
                    <div>
                    <span className="fareStatus">{context.translation && context.translation.changedFare}</span>
                    </div>
                    <div className="bgGrayColor">
                      <span className="currencyCode">{srequest.cur}</span> <span><FormatCurrency value={newAAPrice * base_usr_exrate} className="currencyAmount"  /></span>
                    </div>
                  </div>
                  <div className="fareChangeAlertMsg"><i className="icon icon-Info"> </i> {context.translation && context.translation.airfarechangedesc}</div>
                </Modal>
                <Modal title="" centered open={showPriceFailed} onOk={""} onCancel={""} footer={null} className="wrongAlertWrapper">
              <div className="oopsTitle"><i className="icon icon-alert-popup"></i> {context.translation && context.translation.oops}</div>
              <div className="wrongContent">{context.translation && context.translation.pir}</div>
              <Button
                  type="primary"
                  className="wrongBtn"
                  onClick={closePopUp}
                >
                OK
                </Button>
              </Modal>
</>
                      )}

                 

    </>

    
  );
}

export default FlightResultsBlock;
