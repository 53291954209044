import React, {
    useEffect,
    useRef,
  } from "react";
  import Footer from "./Footer";
  import Header from "./Header";
  import ShowHide from "./showHide/ShowHide";
  import StickyBox from "react-sticky-box";
  import { Col, Row, Typography } from "antd";
  
  function HotelTermsAndConditions(props) {
    const tcGeneral = useRef(null);
    const tc1 = useRef(null);
    const tc2 = useRef(null);
    const executeScrolltop = () =>
      tcGeneral.current.scrollIntoView({ behavior: "smooth" });
    const executeScrolltop1 = () =>
      tc1.current.scrollIntoView({ behavior: "smooth" });
    const executeScrolltop2 = () =>
      tc2.current.scrollIntoView({ behavior: "smooth" });
    const general = (e) => {
      executeScrolltop();
    };
    const general1 = (e) => {
      executeScrolltop1();
    };
    const general2 = (e) => {
      executeScrolltop2();
    };
    useEffect(() => {
      window.scrollTo(0, 0);
    });
  
    const { Title } = Typography;
    const update=(type)=>{
    }
    return (
      <>
        <Header posType="floating" setTab={update} />
        <div className="container tndC">
          <Title level={4} className="tcTitle">
            Hotels Terms and Condtions
          </Title>
          <Row>
            <Col span={6} className="tcleftSidebar">
              <StickyBox offsetTop={100} offsetBottom={20}>
                <ul variant="pills" className="flex-column">
                <li>
                  <span eventKey="general" onClick={general} title="Clickntravel  شروط ومسؤوليات ">
                 Clickntravel شروط ومسؤوليات 
                  </span>
                </li>
                <li>
                  <span eventKey="clientResp" onClick={general1} title="مسؤوليات المستخدم ">
                  مسؤوليات المستخدم
                  </span>
                </li>
                <li>
                  <span eventKey="disclaimer" onClick={general2} title="طريقة الدفع">
                  طريقة الدفع
                  </span>
                </li>
                </ul>
              </StickyBox>
            </Col>
            <Col span={18} className="tcLayout">
            <div ref={tcGeneral}>
              <ShowHide
                visible="true"
                icon=""
                title="Clickntravel  شروط ومسؤوليات "
              >
                <div className="showHide-content">
                  <p>
                 .كوسيط وتوفر منصة عبر الإنترنت للمستخدم لاختيار وحجز فندق معين. يشمل مصطلح "الفنادق" في هذا السياق جميع فئات أماكن الإقامة مثل الفنادق والمنازل ونزل مبيت وإفطار وأي إقامات بديلة أخرى Clickntravel تعمل
                  </p>
                  <p>
                 .لن تكون لها مسؤولية في عملية الحل هذه  ولن تتحمل أي مسؤولية عن هذه الاختلافات Clickntravel . هذه المعلومات هي للمعلومات فقط. يجب على المستخدم الإبلاغ عن أي اختلاف قد يكون موجوداً بين صور التطبيق والخدمات الفعلية للفندق مع الفندق مباشرة ويجب حله بين المستخدم والفندق. Clickntravel جميع المعلومات المتعلقة بالفندق بما في ذلك فئة الفندق والصور ونوع الغرفة والمرافق والخدمات المتاحة في الفندق هي وفقاً للمعلومات التي قدمها الفندق/الوكالة ل
                  </p>
                  <p>
                 .للمستخدم. يجب أن يتم حل أي مشكلة أخرى متعلقة بالخدمة مباشرة بين المستخدم والفندق (في حدود المدفوع) أو استرداد مبلغ الحجز ,(على أساس التوفر في ذلك الوقت) Clickntravel في حالة رفض الفندق للتسجيل لأي سبب كان بمثابة توفير إقامة بديلة مماثلة بتقدير Clickntravel عن عدم تمكن فندق من استضافة المستخدم بحجز مؤكد، أو مستوى الخدمة، أو أي نقص في الخدمات، أو أي مشكلة أخرى متعلقة بخدمات الفندق. تكون المسؤولية القصوى ل Clickntravel للمستخدم تعتمد بشكل كامل على المعلومات المقدمة أو التي تم تحديثها من قبل الفندق/وكالة الشراكة بخصوص توافر الخدمة. في أي ظرف لا يمكن تحميل المسؤولية على Clickntravel إن قسيمة حجز الفندق التي يصدرها
                  </p>
                  <p>
                  لا يحق استرداد أي مبلغ في حال رفض الفندق للتسجيل في مثل هذه الظروف. Clickntravel مسؤولة عن أي تسجيل يتم رفضه من قبل الفندق للأسباب المذكورة أعلاه أو أي سبب آخر لا يخضع لرقابة Clickntravel أي دور في قبول أو رفض الفندق. قد لا يسمح بتسجيل الوافدين من غير المتزوجين أو غير المتصلين ببعضهم البعض بصلة نسب في بعض الفنادق وفقاً لسياساتها. بالمثل، قد يتم رفض الإقامة للنزلاء الذين يظهرون كزوجين إذا لم يتم تقديم إثبات مناسب للهوية أثناء تسجيل الوصول. لن تكون Clickntravel تحتفظ الفنادق بالحق الحصري في قبول الحجز  وليس لدي و
                   
                  </p>
                  <p>
                 .لن تكون مسؤولة عن أي ضرر أو خسارة أو إصابة أو حادث أو وفاة أو عدم انتظام أو تأخير/تغيير في الجدول أو إلغاء بدون سبب أو معلومات غير دقيقة أو نقص في الخدمة/المنتج أو إلغاء بسبب ظروف خارجة عن سيطرتها Clickntravel 
                  </p>
                </div>
              </ShowHide>
            </div>
            <div ref={tc1}>
              <ShowHide
                visible="true"
                icon=""
                title="مسؤوليات المستخدم "
              >
                <div className="showHide-content">
                  <p>
                 .لن تتدخل بأي شكل من الأشكال في هذا الصدد Clickntravel . لممتلكات الفندق بأي طريقة كانت. يتم تحديد نطاق وقيمة الضرر الناتج عن ذلك من قبل الفندق المعني (بصورة متعمدة/مقصودة) المستخدم مسؤول عن تعويض أي ضرر يتسبب فيه
                  </p>
                  <p>
                  يجب أن يكون الضيف الرئيسي على الأقل 18 عامًا للسماح له بتسجيل الوصول إلى الفندق.
                  </p>
                  <p>
                 .يجب أن يكون بحوزة المستخدم إثبات هوية صالح وإثبات عنوان في وقت تسجيل الوصول. يحق للفندق رفض تسجيل الوصول إلى المستخدم إذا لم يتم تقديم إثبات هوية صالح في وقت تسجيل الوصول
                  </p>
                  <p>
                 .سيكون وقت تسجيل الوصول ووقت تسجيل الخروج وأي تغييرات في تلك الأوقات وفقًا لسياسة وشروط الفندق. يخضع طلب تسجيل الوصول المبكر أو تسجيل الخروج المتأخر للتوفر، وقد يفرض الفندق رسوماً إضافية لتقديم مثل هذه الخدمات
                  </p>
                  <p>
                 .المبلغ المدفوع من قبل المستخدم هو فقط للإقامة في الفندق. قد تتضمن بعض الحجوزات وجبة الإفطار و/أو وجبات أخرى كما تم بيانها في وقت الحجز. يجب على المستخدم دفع أي خدمات أخرى يستخدمها في الفندق، بما في ذلك خدمة الغسيل، وخدمة الغرف، وخدمة الإنترنت، والهاتف، والطعام الإضافي، والمشروبات ...الخ. مباشرة إلى الفندق
                  </p>
                  <p>
                . أي تحكم في إلغاء ذلك Clickntravel يجب تسوية أي رسوم إضافية مباشرة في الفندق. لا يمتلك
                  </p>
                </div>
              </ShowHide>
            </div>
            <div ref={tc2}>
              <ShowHide visible="true" icon="" title="طريقة الدفع">
                <div className="showHide-content">
                  <p>
                    Clickntravel يمكن أن يكون حجز الفندق "مدفوع مسبقاً" إن كان الإختيار متاحاًمن قبل الفندق على تطبيق
                  </p>
                  <p>
                 .في بعض الأحيان قد يكون من الضروري دفع ضرائب إضافية مفروضة من قبل الحكومة إلى الفندق .Clickntravel في نموذج "المدفوع مسبقاً"، يدفع المستخدم المبلغ الإجمالي للحجز أثناء الحجز. يشمل هذا المبلغ قيمة حجز الفندق والضرائب ورسوم الخدمة التي قد يتم فرضها نيابةً عن مقدم الخدمة، وأي رسوم إضافية للحجز أو رسوم خدمات تفرضها
                  </p>
                  <p>
                 .مسؤولية أي أخطاء أو إغفال أو تمثيلات على أي من صفحاتها أو روابط أو أي صفحات تطبيق مرتبطة بما يخرج عن ما يتم تحديثه أو توفيره من معلومات مباشرةً من قبل مقدمي الخدمات أو المعلنين Clickntravel لا تتحمل
                  </p>
                </div>
              </ShowHide>
            </div>
            </Col>
          </Row>
        </div>
        <Footer />
      </>
    );
  }
  
  export default HotelTermsAndConditions;
  