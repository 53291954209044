import { Typography, Tooltip } from "antd";
import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import CMSService from "../../services/cms/CMSService";
import { Context } from '../../App';
import BrandFRedIcon from "../../assets/gif/Forever/Red/F_RED_FOREVER.gif";
import Utilities from "../utilities/Utilities";
export const REGION_COUNTRY = "REGION_COUNTRY";


const { Title } = Typography;

function MostlyFlownRoutes(props) {
  const [res, setRes] = useState([]);
  const [context, setContext] = useContext(Context);
  useEffect(() => {
    const payload = {
      region:
        localStorage.getItem(REGION_COUNTRY) === "IN"
          ? "India"
          : "Qatar",
      channel: "B2C", 
      status: "Active"
    }
    let tokenKey = Utilities.generateAPIToken(payload);
    CMSService.loadTopRoutes(payload,tokenKey)
      .then((response) => {
        if (response.data.suc && response.data.res) {
          setRes(response.data.res[0].sec_data);
        }
      })
      .catch(function (error) {
        console.error("Most Flown Routes response error ", error);
      });
  }, []);

  const scrolltop = () => {
    setTimeout(function () {
      window.scrollTo(0, 0);
    }, 1000);
  };

  const handleCityClick = (data, srcObj, destObj) => {
    let flownData = {
      sourceCity : (`${srcObj.cityName}(${srcObj.airportCode}), ${srcObj.countryName}`),
      destinationCity : (`${destObj.cityName}(${destObj.airportCode}), ${destObj.countryName}`),
      sourceDataObj : {
          country: `${srcObj.countryCode}`,
          city: `${srcObj.cityName}`,
          airport: `${srcObj.airportCode}`,
          display: `${srcObj.cityName}(${srcObj.airportCode}), ${srcObj.countryName}`,
          label: `${srcObj.airportName}, ${srcObj.countryName}`,
          value: `${srcObj.airportName}, ${srcObj.cityName} , ${srcObj.countryName} , ${srcObj.airportCode}`
      },
      destinationDataObj : {
          country: `${destObj.countryCode}`,
          city: `${destObj.cityName}`,
          airport: `${destObj.airportCode}`,
          display: `${destObj.cityName}(${destObj.airportCode}), ${destObj.countryName}`,
          label: `${destObj.airportName}, ${destObj.countryName}`,
          value: `${destObj.airportName}, ${destObj.cityName} , ${destObj.countryName} , ${destObj.airportCode}`
      },
      e : data
    }

    setContext({...context, mostFlownRoutes: flownData})

    setTimeout(function () {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }, 100);

    if(sessionStorage.getItem("SEL_TAB") === "Hotels") {
      sessionStorage.setItem("SEL_TAB", "Flights");
    }

  };

  return (
    <>
    {/* <div style={{marginTop: "30px"}}><p>Window width: {window.innerWidth}</p></div> */}
      {res.length > 0 && (
        <div className="mostlyFlownRoutes">
          <div className="container">
            <Title level={2} className="cust-title">
              <img src={BrandFRedIcon} alt="brandFRedIcon" className="brandFRedIcon" />
              {/* <i className="sprite mfr-sprite"></i>{" "} */}
              <span>
                {/* Mostly Flown Routes */}
                {context.translation && context.translation.mfr}
              </span>
            </Title>

            <ul className="mfr-list">
              {res.map((data, index) => {
                return (
                  <li key={index}>
                    <Link to="#" className="cardBox" onClick={(e) => handleCityClick(e, data.src_info, data.dest_info)}>
                       
                        <img src={data.url_destination} alt="Doha-Dubai" />
                      
                       
                          <div className="sourceDestinationRoutes">
                            <Tooltip placement="top" title={<>
                              <span>{context.selectLang == undefined || context.selectLang === "en"
                              ? data.src_info.cityName
                              : data.dep_city_ar}{" "}</span>
                            </>}>
                            <span>{context.selectLang == undefined || context.selectLang === "en"
                              ? data.src_info.cityName
                              : data.dep_city_ar}{" "}</span>
                            </Tooltip>
                            
                            <i className="icon icon-roundtrip-arrow"></i>{" "}
                            <Tooltip placement="bottom" title={<>
                              <span>{context.selectLang == undefined || context.selectLang === "en"
                              ? data.dest_info.cityName
                              : data.arr_city_ar}</span>
                            </>}>
                            <span>{context.selectLang == undefined || context.selectLang === "en"
                              ? data.dest_info.cityName
                              : data.arr_city_ar}</span>
                            </Tooltip>
                          </div>  
                      <div className="go-corner" to="#">
                        <div className="go-arrow">→</div>
                      </div>
                    </Link>
                  </li>
                );
              })}
            </ul>
            {/* {res.length === 0 &&          
            <Title level={3} align="center">
              <span>No data found</span>
            </Title>  
          } */}
          </div>
        </div>
      )}
    </>
  );
}

export default MostlyFlownRoutes;
