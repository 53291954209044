/**
 * @ Author: Ubedullah Mohammed
 * @ Create Time: 2024-01-05 15:26:17
 * @ Description: This is for displaying the Fare Rules Terms & Condition for flights under FareRules tab of FlightDetails
 */

import React from "react";
import { useContext } from "react";
import { Context } from "../../../../App";
import Utilities from "../../../utilities/Utilities";

function FlightFareRulesTerms(props) {
  const [context, setContext] = useContext(Context)
  return (
    <>
      <div className="terms"> 
        <div className="fareRuleTermNCondTitle">* {context.translation && context.translation.termsNcondi}</div>
        <ul className="fareRuleTermNCond">
          <li>
            <i className="icon icon-tick1"></i>
           {context.translation && context.translation.Penaltyfour}
          </li>
          <li>
          <i className="icon icon-tick1"></i> {context.translation && context.translation.perpass}</li>
          <li>
            <i className="icon icon-tick1"></i>
            {context.translation && context.translation.resch}
          </li>
          <li>
            <i className="icon icon-tick1"></i>
            {context.translation && context.translation.tpas}
          </li>
          <li>
            <i className="icon icon-tick1"></i>
            {context.translation && context.translation.ifthecust}
          </li>
          <li>
            <i className="icon icon-tick1"></i> {context.translation && context.translation.noAllowance}
          </li>
          <li>
            <i className="icon icon-tick1"></i>
            {context.translation && context.translation.noAmend}
          </li>
          <li>
            <i className="icon icon-tick1"></i>
            {context.translation && context.translation.anycancRchang}
          </li>
          <li>
            <i className="icon icon-tick1"></i>
            {context.translation && context.translation.aIRnotic}
          </li>
        </ul>
      </div>
    </>
  );
}

export default FlightFareRulesTerms;
