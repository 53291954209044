import React, { useState, useRef, useContext, useEffect} from "react";
import { Context } from "../../../../App";
import { Row, Button, Modal, Space, Input, Col, Typography, Alert, Spin} from "antd";
import Footer from "../../../common/Footer";
import Header from "../../../common/Header";
import SecureGuarantee from "../../../homepage/SecureGuarantee";
import HotelPaymentDetails from "./HotelPaymentDetails";
import DateUtils from "../../../utilities/DateUtils";
import HotelRoomDetails from "./HotelRoomDetails";
import HotelBookingDetails from "../common/HotelBookingDetails";
import { useLocation, useNavigate } from "react-router-dom";
import { emailValidation } from "../../../utilities/FormValidations";
import HotelBookingService from "../../../../services/hotels/HotelBookingService";
import HotelVoucherpdf from "./HotelVoucherpdf";
import html2pdf from "html2pdf.js";
import Utilities from "../../../utilities/Utilities";
const { Title } = Typography;

function HotelConfirmation() {
  const [context, setContext] = useContext(Context)
  const location = useLocation();
  const navigate = useNavigate();  
  const [book_resp, setBookResep] = useState(
    location.state !== undefined &&
      location.state?.resp !== undefined &&
      location.state?.resp !== ""
      ? location.state?.resp
      : ""
  );
  //const [book_resp, setBookResep] = useState(resp.bookRs.data[0]);
  const [email, setEmail] = useState("");
  const [notiMessage, setNotiMessage] = useState("");
  const [notiMessageShow, setNotiMessageShow] = useState(false);
  const [notiVarient, setNotiVarient] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };

  useEffect(() => {
    window.addEventListener('popstate', () => {
      window.location="/";
      // navigate("/");
    })
  }, [])

  const handleCancel = () => {
    setIsModalOpen(false);
    setEmail("");
  };

  const validateEmail = () => {
    if (email === "") {
      setNotiMessageShow(true);
      setNotiMessage(context.translation && context.translation.plzEnterem);
      setNotiVarient("error");
      hidemessage();
      return false;
    } else if (!emailValidation(email)) {
      setNotiMessageShow(true);
      setNotiMessage(context.translation && context.translation.plzEntervem);
      setNotiVarient("error");
      hidemessage();
      return false;
    }
    return true;
  }

  const componentRef = useRef();
  // const opendlevent =
  //   useReactToPrint({
  //     content: () => componentRef.current,
  //     documentTitle: book_resp !== undefined && book_resp.booking_id !== undefined && ("HOTEL_VOUCHER-" + book_resp.booking_id)
  //   }
  // );
  

  const itineraryPDF = () => {

    // Create HTML content for PDF
    const pdfContent = componentRef.current;

    // Configure PDF options
    const pdfOptions = {
      margin: 0,
      filename: book_resp !== undefined && book_resp.booking_id !== undefined && ("HOTEL_VOUCHER--" + book_resp.booking_id),
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2, allowTaint: false, useCORS: true, letterRendering: true  },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" }
    };

    // Generate PDF
    html2pdf(pdfContent, pdfOptions);
  };

  const handleChange = (e) => {
    e.preventDefault();
    let trimmedEmail = e.target.value.trim();
    setEmail(trimmedEmail);
  }

  const shareItinerary = (data) => {
    setLoading(true);
    let tokenKey = Utilities.generateAPIToken(data);
    HotelBookingService.sendConfirmationMail(data,tokenKey).then((res) => {
      if (res.data.suc) {
        setLoading(false);
        setIsModalOpen(false);
        setNotiMessageShow(true);
        setNotiMessage(context.translation && context.translation.emailSentsuc)
        setNotiVarient("success")
        hidemessage();
        setEmail("");
      } else {
        setLoading(false);
        setIsModalOpen(false);
        setNotiMessageShow(true);
        setNotiMessage(context.translation && context.translation.plzTryagain)
        setNotiVarient("error")
        hidemessage();
        setEmail("");
      }
    })
    .catch(function (error) {
      console.error("Exception occured in Sending confirmation mail function :" + error);   
      setIsModalOpen(false);
      setEmail("");
    });
  };

  const sendConfirmationEmail = () => {
    if (validateEmail()) {
      const payload = {
        "bookingid" : book_resp.booking_id,
        "email" : email,
        "hid" : book_resp.hid
      }
      shareItinerary(payload)
    }
  };
  
  const hidemessage = () => {
    setTimeout(function () {
      setNotiMessageShow(false);
      setNotiMessage("");
      setNotiVarient("");
    }, 3000);
  };
  const update=(type)=>{
  }


  return (
    <>
      {notiMessageShow ? (
        <Alert
          description={notiMessage}
          type={notiVarient}
          closable
          onClose={() => setNotiMessageShow(false)}
          className="alertForm"
          // showIcon
          style={{zIndex:99999}}
        >
          {notiMessage}
        </Alert>
      ) : (
        ""
      )}
      <Header setTab={update} page={"Confirm"}/>
      <div className="container">
        <div className="confirmation hotelConfirmation">
          <div className="paxBookingWrap">
              <div className="paxBooking">
                {book_resp.st == "Confirmed" && (
                  <>
                    <Title level={3}>
                      {context.translation && context.translation.bokNow}{" "}
                      {book_resp && (
                        <span>
                          {book_resp.st} <i className="icon icon-tick"></i>
                        </span>
                      )}
                    </Title>
                    <p>
                      {context.translation && context.translation.confSentEmail}.
                    </p>
                  </>
                )}
                {book_resp.st !== "Confirmed" && (
                  <Title level={3}>
                    {context.translation && context.translation.gotFailed}
                  </Title>
                )}
              </div>
            {book_resp && (
              <div className="booking">
                <div> 
                  <span className="dob">{context.translation && context.translation.dateofBok} :&nbsp;</span>
                  <span className="date">
                    {DateUtils.prettyDate(book_resp.bd, "ddd, DD MMM, YYYY")}
                  </span>
                </div>
                <div>  
                  <span className="bReference">{context.translation && context.translation.bokRefer} :&nbsp;</span>
                  <span className="reference">{book_resp.booking_id}</span>
                </div>
              </div>
            )}
          </div>
          <div className="emailDownloadWrap onlyFlex">
            {book_resp.st === "Confirmed" && (
              <div className="emailItinerary" onClick={showModal}>
                <Button type="secondary" className="emailBtn" ghost>
                <i className="icon icon-Email"></i>
                  {context.translation && context.translation.emailIti}
                </Button>
              </div>
            )}
            <Modal
              title=""
              open={isModalOpen}
              onCancel={handleCancel}
              width={460}
              footer={null}
              className="emailItineraryPopup itineraryPopup"
              maskClosable={false}
            >
              <i
                className="icon icon-cancel"
                onClick={() => {
                  handleCancel();
                }}
              ></i>
              <Title level={4} className="shareItienaryTitle">
                {context.translation && context.translation.shrThsIti}
              </Title>
              <Space direction="horizontal">
                <Input
                  type="email"
                  className="shareItienaryInput"
                  placeholder={context.translation && context.translation.eYeid}
                  value={email}
                  onChange={handleChange}
                />

                <Button 
                  type="primary" 
                  disabled={loading ? true : false}
                  className="cntBtn smallCntBtn" 
                  onClick={sendConfirmationEmail}>

                  {loading ? (
                    <Space size={"middle"}>
                      <Spin size="large" />
                    </Space>
                  ) : (
                    context.translation && context.translation.send
                  )}
                </Button>
              </Space>
            </Modal>
            {book_resp.st == "Confirmed"  && (
              <div className="download" onClick={itineraryPDF}>
                <i className="icon icon-Download"></i>
              </div>
            )}
          </div>
        </div>

        <div style={{ display: "none" }}>
          <HotelVoucherpdf ref={componentRef} book_resp={book_resp} />
        </div>
        {/* <div className="horizontalDivider"></div> */}
        <Title level={3} className="marginTop30 summaryTitle">{context.translation && context.translation.bokSumy}</Title>
        {book_resp && 
            <HotelBookingDetails htldtls={book_resp.htldtls}/>
        }
       {/* <div className="horizontalDivider"></div> */}
       <Title level={3} className="roomDetailTitle">{context.translation && context.translation.roomDetails}</Title>
       {book_resp && book_resp.rooms.map((room, index) => (
          <React.Fragment key={index}>
            {book_resp.roompax.map((rm, idx) => (
              <React.Fragment key={idx}>
                {room.rmindex === rm.rmindex &&
                  <div>
                    <div className="guestsWrapper">
                      <div className="roomWrapr">{context.translation && context.translation.room} {room.rmindex}: {room.rnm}</div>
                      <div className="travellersWrapper">
                        <span className="travellersTitle">{context.translation && context.translation.guests}:</span>
                        <span className="travellersDetailWrap">
                        {rm.pax.map((pax, index) => (
                        <React.Fragment key={index}>
                        <span className="travellersDetail">
                          <span><i className="icon icon-tick"></i>{pax.tl} {pax.fn} {pax.ln} ({pax.ty})</span>
                        </span>
                        </React.Fragment>
                        ))}
                        </span>
                      </div>
                    </div>
                    <div className="travellerDetails">
                    <HotelRoomDetails room_price={room} spr={rm.spr} canpolicy={rm.canpolicy} note={room.note} imp={book_resp.htldtls} 
                    currency={book_resp.cur} 
                    base_usr_exrate={book_resp.base_usr_exrate}/>
                    </div>
                  </div>
                }
              </React.Fragment>
            ))}
          </React.Fragment>
        ))}
        
        <div className="horizontalDivider"></div>
        {book_resp && book_resp.pay.length > 0 && (
          <div className="travellerDetails">
            <HotelPaymentDetails payment_info={book_resp.pay[0]} />
          </div>
        )}
        <div className="horizontalDivider"></div>
      </div>
      <div className="marginTop50">
        <SecureGuarantee />
      </div>
      <Footer />
    </>
  );
}

export default HotelConfirmation;
