import Footer from "../../../common/Footer";
import Header from "../../../common/Header";
import SecureGuarantee from "../../../homepage/SecureGuarantee";
import HotelSearch from "../search/HotelSearch";
import HotelResultsDetails from "./HotelResultsDetails";
import _ from "lodash";
import { useLocation } from "react-router-dom";
import { useEffect, useReducer, useState, useContext, useCallback } from "react";
import { Context } from "../../../../App";
import {
  // getHotelSRPContent,
  searchRequest,
} from "../../../common/services/HotelOperations";
import { isArrayNotEmpty } from "../../../utilities/validators";
import CntCommonService from "../../../../services/common/CntCommonService";
import HotelPredective from "../search/predective/HotelPredective";
import LoaderH from "../../../common/loaders/LoaderH";
import HotelSearchLoader from "./HotelSearchLoader";
import NoResultsFound from "./NoResultsFound";
import HotelBrandIcon from "../../../../assets/gif/Forever/White/H_WHITE_FOREVER.gif";
import { Button, Drawer } from "antd";
import Utilities from "../../../utilities/Utilities";
export const HTL_CHD_AGES = "chdages";

const FLIGHT_SEARCH_CID = process.env.REACT_APP_FLIGHT_SEARCH_CID;
const BASE_CURRENCY = process.env.REACT_APP_BASE_CUR;

const initialState = {
  searchResp: [],
  initialResponse: {},
  searchReq: {},
  loading: false,
  results: true,
  enable: true,
  load: true,
  hotelContResp: "",
  contentAvail: false,
  respAvail: false,
  showPop: false,
  hotelsearchRes: "",
  filtersearchRes: "",
  sortsearchRes: "",
  update: false,
  showMapView: false,
  hideSort: false,
  noofnights: 0,
  markupVal: 0,
  applToData: [],
  offers: [],
  clickedMap: false,
  noresults: false,
  reset: false,
  markupValue: 0,
  response: {},
};

//=====================================
// Reducer function for userReact Hook
// which will update the state
//=====================================
const reducer = (state, action) => {
  switch (action.type) {
    case "clear":
      return {
        ...state,
      };
    default:
      return { ...state, [action.type]: action.payload };
  }
};

function HotelResults(props) {
  const [context, setContext] = useContext(Context)
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const parseQueryString = () => {
    const payload = {};

    for (const [key, value] of searchParams.entries()) {
      payload[key] = value;
    }

    return payload;
  };
  const [state, dispatch] = useReducer(reducer, initialState);
  const [loading, setLoading] = useState(true);
  const [request, setRequest] = useState(
    location ? parseQueryString() : ""
  );
  const [longUrl, setLongUrl] = useState(window.location.href);
  const [shortUrl, setShortUrl] = useState('');
  const [initialResp, setInitialResp] = useState([]);

  useEffect(() => {
    if (request !== undefined && request !== "") {
      constructRoomInfo(request);
     // dispatch({ type: "searchReq", payload: location.state.req });
      if (!loading) setLoading(true);
      loadHotelsAndResults(request);
    }
  }, [request]);

  useEffect(() => {  
    handleShortenUrl();
  },[request, sessionStorage.getItem("SEL_CUR")])

  useEffect(() => {
    sessionStorage.setItem("HTLLONGURL", window.location.href)
  }, []);

  const constructRoomInfo = (request) => {
    // Split the comma-separated values in the request object
    const adultCounts = request.adultCount.split(',').map(Number);
    const childAgesPerRoom =request.childAges!==""?request.childAges.split('|').map(group => group.split(',').map(Number)):[];
    const childCounts = request.childCount.split(',').map(Number);
    const roomCount = parseInt(request.roomCount, 10);

    // Create the rooms array based on the provided data
    const rooms = [];
    for (let rmno = 1; rmno <= roomCount; rmno++) {
      const adt = adultCounts[rmno - 1];
      const chdAges =childAgesPerRoom.length!==0?childAgesPerRoom[rmno - 1]:[];
      const chd = childCounts[rmno - 1];

      // Create the chdArray based on chdAges
      let chdArray = []
      if(chdAges.length!==0 && chd !== 0){
        chdArray= chdAges.map((age, chd) => ({ chd: chd + 1, age }));
      }
      rooms.push({ rmno, adt, chd, chdAges, chdArray });
    }
    if(isArrayNotEmpty(rooms)){request.roomsInfo = rooms}
  }

  const loadHotelsAndResults = (request) => {
    try {
      if(request.searchType === 'city' ){
        HotelPredective.retrieveHotelorCityData(request.city,true).then((response) => {
          request.cityHotelData = response.data.data.city;
        }).catch(function (error) {
          console.error("Error:" + error);      
        });
      } else {
        HotelPredective.retrieveHotelorCityData(request.hotelId,false).then((response) => {
          request.cityHotelData = response.data.data.property;
        }).catch(function (error) {
          console.error("Error:" + error);      
        });
      }
      callHotelSearchRequest(request);
  } catch (error) {
      console.error("Error in loadHotelsAndResults:", error);
  }
}

  //=====================================
  // Calling the Search request.state.req
  //=====================================
  const callHotelSearchRequest = (request) => {
    try {
      let chdArray = request.roomsInfo.filter(
        (obj) => obj.chd !== undefined && obj.chd
      );
      var count = 0;
      for (let val of chdArray) {
        if (count === 0) {
          count = val.chd;
        } else {
          count = count + val.chd;
        }
      }
      var ages = "";
      var adts = "";
      ages = convertChildAge(request.roomsInfo);
      adts = convertAdts(request.roomsInfo);
      var randomStr = Math.random().toString(36).slice(2);
      var requrl = "";
      var type = "";
      let cntCode = localStorage.getItem("REGION_COUNTRY") !== null &&
      localStorage.getItem("REGION_COUNTRY") === "IN"
        ? "IN"
        : "QA";
      var cur = localStorage.getItem("REGION_COUNTRY") !== null &&
      localStorage.getItem("REGION_COUNTRY") === "IN"
        ? "INR"
        : "QAR";
      var usr_cur = context.userCur!==undefined && context.userCur!==""?context.userCur:sessionStorage.getItem("SEL_CUR");
      var region_cur =context.regionCur!==undefined && context.regionCur!==""?context.regionCur:localStorage.getItem("REGION_CUR");
      if (request.searchType === "city") {
        type = "cityId";
      } else if (request.searchType === "landmark") {
        type = "landmarkId";
      } else if (request.searchType === "area") {
        type = "areaId";
      }
      if (request.searchType !== "property") {
        requrl =
          "cin=" +
          request.cin +
          "&cout=" +
          request.cout +
          "&rms=" +
          request.roomsInfo.length +
          "&adt=" +
          adts +
          "&chd=" +
          count +
          "&echo=" +
          randomStr +
          "&cid=" +
          FLIGHT_SEARCH_CID +
          "&cha=" +
          "B2C" +
          "&cur=" +
          usr_cur +
          "&rty=" +
          type +
          "&prtyp=" +
          "hotelOnely" +
          "&country_code=" +
          cntCode +
          "&ctid=" +
          request.ctid +
          "&chdages=" +
          ages +
          "&package=" +
            1 +
          "&req_cur=" +
          BASE_CURRENCY +
          "&region_type=" +
          request.region +
          "&region_cur=" +
          region_cur +
          "&expediacityid=" +
          request.city;
      } else {
        requrl =
          "cin=" +
          request.cin +
          "&cout=" +
          request.cout +
          "&rms=" +
          request.roomsInfo.length +
          "&adt=" +
          adts +
          "&chd=" +
          count +
          "&echo=" +
          randomStr +
          "&cid=" +
          FLIGHT_SEARCH_CID +
          "&cha=" +
          "B2C" +
          "&cur=" +
          usr_cur +
          "&rty=" +
          "prid" +
          "&prtyp=" +
          "hotelOnely" +
          "&country_code=" +
          cntCode +
          "&ctid=" +
          request.ctid +
          "&chdages=" +
          ages +
          "&package=" +
           1 +
          "&req_cur=" +
          BASE_CURRENCY +
          "&region_type=" +
          request.region +
          "&region_cur=" +
          region_cur +
          "&expediacityid=" +
          request.city+
          "&expediaprsearch=" +
          request.hotelId;
      }
      requrl && searchRequest(requrl).then((response) => {
        if (response.data.suc === true) {
          setLoading(false);
          dispatch({ type: "response", payload: response.data.data });
          const properties = _.cloneDeep(response.data.data)
          setInitialResp(properties);
          localStorage.setItem(HTL_CHD_AGES, ages);
          //props.hotelresponse(response.data.data);
          dispatch({ type: "checkAvail", payload: true });
        } else {
          console.error("Error in hotel search response");
          setLoading(false);
          dispatch({ type: "response", payload: undefined });
        }
      }).catch(function (error) {
        console.error("Exception occured in callHotelSearchRequest => " + error);      
      });
    } catch (e) {
      console.error("Exception occured in callHotelSearchRequest => " + e);
    }
  };

  //Converting Child Ages
  const convertChildAge = (roomsInfo) => {
    var ages = "";
    for (let val of roomsInfo) {
      if (val.chdArray.length !== 0) {
        if (ages === "") {
          if (val.chdArray.length === 1) {
            ages = val.chdArray.reduce((acc, curr) => `${acc}${curr.age}`, "");
          } else {
            ages = val.chdArray.reduce((acc, curr) => `${acc}${curr.age},`, "");
          }
        } else {
          if (val.chdArray.length === 1) {
            ages =
              ages +
              "|" +
              val.chdArray.reduce((acc, curr) => `${acc}${curr.age}`, "");
          } else {
            ages =
              ages +
              "|" +
              val.chdArray.reduce((acc, curr) => `${acc}${curr.age},`, "");
          }
        }
      } else {
        if (ages !== "") {
          ages = ages + "|" + "0";
        } else {
          ages = "0";
        }
      }
    }
    return ages;
  };

  const convertAdts = (roomsInfo) => {
    var adts = "";
    for (let val of roomsInfo) {
      if (adts === "") {
        adts = val.adt;
      } else {
        adts = adts + "," + val.adt;
      }
    }
    return adts;
  };
  
  const update=(type)=>{
  }

  const handleShortenUrl = () => {
    try {
      const payload = {
        url: longUrl.replace(longUrl.substring(longUrl.lastIndexOf('&'))?.split("=")[1], sessionStorage.getItem("SEL_CUR")),
        product_type: 'hotel'
      }
      let tokenKey = Utilities.generateAPIToken(payload);
      CntCommonService.storeLongUrl(payload,tokenKey).then((response) => {
        if (response.data.res !== undefined && response.data.suc) {
          var h = window.location.host.includes('localhost')?'http':'https';
          setShortUrl(`${h}://${window.location.host}`+"/cnt?"+response.data.res.url_id); // need to change host to hostname
        }
      }).catch(function (error) {
        console.error("Error:" + error);      
      });      
    } catch (error) {
      console.error("Error:", error);       
    }
  }

  // const addActiveClass=()=>{
  //   document.querySelector(".hotelSRP").classList.add("active");
  // }

  // const removeActiveClass=()=>{
  //   document.querySelector(".hotelSRP").classList.remove("active");
  // }

  const [scrollDown, setScrollDown] = useState(false);
//   const [y, setY] = useState(window.scrollY);
// const handleNavigation = useCallback(
//   e => {
//     const window = e.currentTarget;
//     if (y > window.scrollY) {
//       setScrollDown(false);
//     } else if (y < window.scrollY) {
//       setScrollDown(true);
//     }    setY(window.scrollY);
//   }, [y]
// );useEffect(() => {
//   setY(window.scrollY);
//   window.addEventListener("scroll", handleNavigation);

//   return () => {
//     window.removeEventListener("scroll", handleNavigation);
//   };
// }, [handleNavigation]);

const [open, setOpen] = useState(false);

const showDrawer = () => {
  setOpen(true);
};

const onClose = () => {
  setOpen(false);
};

  return (
    <>
      <Header posType="floating" setTab={update}/>
      <div className="hotelSRP modifySearch sticky displayNone-640" onMouseEnter={() => setScrollDown(true)}
        onMouseLeave={() => setScrollDown(false)}>
      {request && (request.cityHotelData) && <HotelSearch 
        modify={true}
        data={request}
        scrollDown={scrollDown}
        />}
      </div>
      <div className="container">
        <Button type="primary" className="cntBtn planeBtn displayNoneLG" onClick={showDrawer}>
        {context && context.translation && context.translation.modiSearch}
              <img src={HotelBrandIcon} alt="hotelBrandIcon" className="hotelBrandIcon" />
        </Button>
      </div>
      <Drawer placement="bottom" open={open} className="modifySearchPopup hotelSRP">
        <div className="modifySearchTitleWrap">
            <div className="modifySearchTitle">{context.translation && context.translation.modiSearch}</div>
            <i className="icon icon-cancel" onClick={onClose}></i>
        </div>
      <div className="hotelSRP modifySearch sticky">
      {request && (request.cityHotelData) && <HotelSearch 
        modify={true}
        data={request}
        scrollDown={scrollDown}
        />}
      </div>
      </Drawer>
      {/* {loading && <Spin/>} */}
      {loading && (
        <>
        <HotelSearchLoader />
          <LoaderH
            title={context.translation && context.translation.plzwait}
            subtitle={context.translation && context.translation.bestHotelu}
          />
        </>
      )}
      <div className="container resultsPage hotelResultsPage">
        {!loading && state.response !== undefined && state.response !== "" && (
          <HotelResultsDetails
            checkAvail={state.checkAvail}
            response={state.response}
            request={request}
            shortUrl={shortUrl}
            initialResp={initialResp}
          />
        )}
        {!loading && state.response === undefined && <NoResultsFound />}
      </div>
      <SecureGuarantee />
      <Footer />
    </>
  );
}

export default HotelResults;
