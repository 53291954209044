import React, { useEffect, useState, useContext } from "react";
import { Context } from "../../App";
import FlightDetailsContent from "../bookingflow/flights/results/FlightDetailsContent";
import PassengersDetails from "../bookingflow/flights/confirmation/PassengersDetails";
import { isArrayNotEmpty } from "../utilities/validators";
import { Select } from "antd";
import CancelFlightTrip from "./CancelFlightTrip";
import FareDetails from "../bookingflow/flights/confirmation/FareDetails";
import PaymentDetails from "../bookingflow/flights/confirmation/PaymentDetails";
import MyTripsPassengersDetails from "./MyTripsPassengersDetails";
import ReissueFlightTrip from "./ReissueFlightTrip";

const { Option } = Select;

function MyTripsItineraryPopup({ itiData, close, active }) {
  const [context, setContext] = useContext(Context);
  const [sectors,setSectors] = useState();
  const [cancelType, setCancelType] = useState("");

  const handleCancelTypeChange = (value) => {
    setCancelType(value);
  };

  useEffect(() => {
    constructSectors();
  }, []);

  const constructSectors = () => {
    // Initialize the result array
    var sec = [];
    var tt = parseInt(itiData.tt);
    if (tt === 1 || tt === 2 || tt === 3) {
      itiData.origns[0].srcorigns.forEach((srcorign, index) => {
        var src = srcorign.seg[0].da;
        var des = srcorign.seg[srcorign.seg.length - 1].ar;
        var dd = srcorign.seg[0].dd.split("T")[0]; // Format "dd" as yyyy-MM-dd
        var ad = srcorign.seg[srcorign.seg.length - 1].ad.split("T")[0]; // Format "ad" as yyyy-MM-dd
        var pnr = itiData?.flt_b_data[0].pnr;
        sec.push({
          src,
          des,
          dd,
          ad,
          leg: index + 1, // Increment leg number
          pnr,
        });
      });
      if (tt === 2 || itiData.sty === "split") {
        itiData.origns[0].destorigins.forEach((destorigin, index) => {
          var src = destorigin.seg[0].da;
          var des = destorigin.seg[destorigin.seg.length - 1].ar;
          var dd = destorigin.seg[0].dd.split("T")[0]; // Format "dd" as yyyy-MM-dd
          var ad = destorigin.seg[destorigin.seg.length - 1].ad.split("T")[0]; // Format "ad" as yyyy-MM-dd
          var pnr = itiData?.flt_b_data.length === 1 ? itiData?.flt_b_data[0]?.pnr : itiData?.flt_b_data[1]?.pnr;

          // Push the data into the "sec" array
          sec.push({
            src,
            des,
            dd,
            ad,
            leg: itiData.origns[0].srcorigns.length + index + 1, // Increment leg number
            pnr,
          });
        });
      }
    }
    if(isArrayNotEmpty(sec)){
      setSectors(sec);
    }

  };

  return (
    <div className="viewItineraryWrap">
      <div className="commonFlex paddingRight">
        <span className="itineraryTitle">{context.translation && context.translation.urItinerary}</span>
        {/* <span className="manageBooking">Manage Booking</span> */}
        {(itiData.sty === "split" || itiData.tt == 1) && active === "Title1" && (
        <Select defaultValue={context.translation && context.translation.manageBok} onChange={handleCancelTypeChange} className="manageBooking">
          <Option value="reissue">{context.translation && context.translation.changeReq}</Option>
          <Option value="cancel">{context.translation && context.translation.canReq}</Option>
        </Select>
        )}
      </div>
      {itiData && sectors &&
        itiData.origns[0] && itiData.origns[0].srcorigns &&
        itiData.origns[0].srcorigns.length > 0 &&
        itiData.origns[0].srcorigns.map((s, i) => (
          <React.Fragment key={i}>
            <FlightDetailsContent
              key={i}
              mapping={itiData.mappings}
              sectors={itiData.tt.toString()!=="3"?sectors:(sectors.length > 0 &&
                sectors.filter((sec) => {
                  return sec.leg === i+1;
                }))}
              secType={"onward"}
              secTypeIndex={i}
              trip={itiData.tt.toString()}
              flt={s} // Use s directly as the flt prop
              seg={s.seg[0]}
              refund={s.re}
              flow={false}
              pnr={true}
            />
            {itiData.sty === "split" && cancelType === "" && (
              <>
              <div className="horizontalDivider"></div>
              <MyTripsPassengersDetails sup={s.sup} response={itiData} psngrs={itiData.psngrs.filter((pax) => pax.tid === itiData.origns[0].srcorigns[0].tid)} />
              </>
            )}
          </React.Fragment>
        ))}

      {itiData && sectors &&
        itiData.origns[0] && itiData.origns[0].destorigins &&
        itiData.origns[0].destorigins.length > 0 &&
        itiData.origns[0].destorigins.map((s, i) => (
          <React.Fragment key={i}>
          <div className="horizontalDivider"></div>
            <FlightDetailsContent
              key={i}
              mapping={itiData.mappings}
              sectors={sectors}
              secType={"arrival"}
              secTypeIndex={0}
              trip={itiData.tt.toString()}
              flt={s} // Use s directly as the flt prop
              seg={s.seg[0]}
              refund={s.re}
              flow={false}
              pnr={true}
            />
            {itiData.sty === "split" && cancelType === "" && (
              <>
              <div className="horizontalDivider"></div>
              <MyTripsPassengersDetails sup={s.sup} response={itiData} psngrs={itiData.psngrs.filter((pax) => pax.tid === itiData.origns[0].destorigins[0].tid)} />
              </>
            )}
          </React.Fragment>
        ))}

        {cancelType === "" && (
          <>
          
            {itiData.sty !== "split" && (
              <PassengersDetails sup={itiData.sup} response={itiData} />
            )}
            {itiData && (
              <>
              <div className="horizontalDivider"></div>
              <div className="travellerDetails">
                <FareDetails response={itiData} />
              </div>
              </>
            )}
            {itiData && itiData.pay.length > 0 && (
              <>
              <div className="horizontalDivider"></div>
              <div className="travellerDetails">
                <PaymentDetails payment_info={itiData.pay[0]} base_usr_exrate={itiData?.base_usr_exrate !== undefined ? itiData?.base_usr_exrate : 1} />
              </div>
              </>
            )}
          </>
        )}
        {cancelType === "cancel" && (
          <CancelFlightTrip close={close} cancelData={itiData}/>
        )}
        {cancelType === "reissue" && (
          <ReissueFlightTrip close={close} cancelData={itiData}/>
        )}
    </div>
  );
}

export default MyTripsItineraryPopup;
