/**
 * @ Author: Lakshmi
 * @ Create Time: 2023-04-24 12:32:04
 * @ Description: This is an service for flights api
 */

import axios from "axios";
import api from "../../components/utilities/api"

const API_URL_B2C_ENGINE = process.env.REACT_APP_B2C_HOTEL_COMMON
const API_URL = process.env.REACT_APP_B2C_ENGINE_URL

class HotelBookingService {
    
    async savePrebook(obj,token){
        const headers = {
            headers: {
                'Content-Type': 'application/json',
                'auth-token': token
              }
           };
        return await api.post(`${API_URL}/cnth/hotel/prebook`,obj,headers)
    }
    
    async fireHoldBooking(obj,token){
        const headers = {
            headers: {
                'Content-Type': 'application/json',
                'auth-token': token
              }
           };
        return await api.post(`${API_URL_B2C_ENGINE}/hotel/onholdbook`,obj,headers)
    }
    async fireBook(obj,token){
        const headers = {
            headers: {
                'Content-Type': 'application/json',
                'auth-token': token
              }
           };
        return await api.post(`${API_URL_B2C_ENGINE}/hotel/confirm`,obj,headers)
    }

    async sendConfirmationMail(obj,token){
        const headers = {
            headers: {
                'Content-Type': 'application/json',
                'auth-token': token
              }
           };
        return await api.post(`${API_URL_B2C_ENGINE}/hotel/send_email`,obj,headers)
    }

}
export default new HotelBookingService()  

