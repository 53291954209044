import React, {
  useContext,
  useEffect,
  useState,
  useReducer,
} from "react";
import {
  Alert,
  Row,
  Col,
  Typography,
  Form,
  Input,
  Select,
} from "antd";
import Link from "antd/es/typography/Link";
import Month from "../../../common/Month";
import Years from "../../../common/Years";
import Days from "../../../common/Days";
import CntCommonService from "../../../../services/common/CntCommonService";
import AuthenticationPopUp from "../../../common/AuthenticationPopUp";
import { Context } from "../../../../App";
import {
  characterValidation,
  emailValidation,
  mobileNumberValidation,
} from "../../../utilities/FormValidations";
import Utilities from "../../../utilities/Utilities";

let stdCodes = require("../../flights/common/masterData/CountrySTDCodes.json");
let countrieslist = require("../../flights/common/masterData/CountriesList.json");

const { Title } = Typography;
const { Option } = Select;
const { TextArea } = Input;

//=====================================
// Reducer function for userReact Hook
// which will update the state
//=====================================
const reducer = (state, action) => {
  switch (action.type) {
    default:
      return { ...state, [action.type]: action.payload };
  }
};
/**
 * Initial State Declaration
 */
const initialState = {
  roomPax: [],
  chds: 0,
  doc: "Yes",
  doct: "P",
  countriesMenu: [],
  selTraveller: "",
  adtList: [],
  chdList: [],
  enableImport: false,
  passportEnable: false,
  flowType: "",
  title: [],
  enbalePPInfo: false,
  docMandatory: "",
  infTitles: [],
  bOwname: "",
  bRtname: "",
  owbagTid: "",
  rtBagTid: "",
  cheked: false,
  passengerUniqueKey: [],
  onwardAJbaggage: false,
  returnAJbaggage: false,
  owmealTid: "",
  rtmealTid: "",
  deptDate: "",
  depYear: 0,
  childDeptDate: "",
  adtTraList: [],
  chdTraList: [],
  notiMessage: "",
  notiMessageShow: false,
  notiVarient: "",
};

function HotelGuestDetails(props) {
  const [splReqShow, setSplReqShow] = useState(false);
  const [cancelPolShow, srtCancelPolShow] = useState(false);
  const [impInfoShow, setImpInfoShow] = useState(false);
  const [state, dispatch] = useReducer(reducer, initialState);
  const [signin, setSignin] = useState(true);
  const [visible, setVisible] = useState(false);
  const [request, setRequest] = useState(props.srequest);
  const [context, setContext] = useContext(Context);
  const [showSignIn, setShowSignIn] = useState(false);
  const [showViewDetails, setShowViewDetails] = useState(true);
  const [paxDetails, setPaxDetails] = useState(false);
  const [selectedIndexes, setSelectedIndexes] = useState([]); 
  const [adt_titles, setAdtTitles] = useState([
    { value: "Mr", label: "Mr" },
    { value: "Miss", label: "Miss" },
    { value: "Mrs", label: "Mrs" },
    { value: "Ms", label: "Ms" },
  ]);
  const [adt_titlesar, setAdtTitlesar] = useState([
    { value: "سيد", label: "سيد" },
    { value: "آنسة", label: "آنسة" },
    { value: "سيدة", label: "سيدة" },
  ]);
  const [chd_titles, setChdTitles] = useState([
    { value: "Miss", label: "Miss" },
    { value: "Mstr", label: "Mstr" },
    { value: "Ms", label: "Ms" },
  ]);
  const [chd_titlesar, setChdTitlesar] = useState([
    { value: "Miss", label: "آنسة" },
    { value: "Mstr", label: "السيد" },
  ]);

  const splreqFun = () => {
    setSplReqShow(true);
    srtCancelPolShow(false);
    setImpInfoShow(false);
  };
  const cancelPolFun = () => {
    setSplReqShow(false);
    srtCancelPolShow(true);
    setImpInfoShow(false);
  };
  const impInfoFun = () => {
    setSplReqShow(false);
    srtCancelPolShow(false);
    setImpInfoShow(true);
  };


  useEffect(() => {
     createRoomList();
    window.scrollTo(0, 0);
  }, [request]);

  useEffect(() => {
    getTravellersList();
  }, [context.user_resp]);

  const createRoomList = () => {
    if (request && request !== undefined) {
      let roomInitRequest = [];
      for (let eachRoom of request.rooms) {
        let arr = [];
        let obj = "";
        for (var i = 1; i <= eachRoom.adt; i++){
          obj=roomsPayload(eachRoom.rmindex,"ADT",i)
          if(i===1){
            obj.em= "";
            obj.phc= "";
            obj.phn= "";
            obj.lead=true;
          }
          arr.push(obj);
          }
        if(eachRoom.chd!==0){
          for (var i = 1; i <= eachRoom.chd; i++){
            obj=roomsPayload(eachRoom.rmindex,"CHD",i)
            arr.push(obj);
        }
        }
        let payload = {
          rmindex: eachRoom.rmindex,
          rmid: eachRoom.rmid,
          adt: eachRoom.adt,
          chd: eachRoom.chd ? eachRoom.chd : 0,
          spr: "",
          pax: arr,
          canpolicy: eachRoom.rmpr[0].canpol,
        };
        roomInitRequest.push(payload);
        //setAdt(eachRoom.adt);
        let chd = 0;
        if (eachRoom.chd && eachRoom.chd > 0) {
          chd = eachRoom.chd;
          //setChd(chd);
        }
      }
      dispatch({ type: "roomPax", payload: roomInitRequest });
      dispatch({ type: "initialResponse", payload: true });
      //props.roomsresponse(roomInitRequest);
    }
  };

  const roomsPayload=(index,type,i)=>{
    var request={
            sno: i,
            tl: "",
            ty: type==="ADT"?"ADT":"CHD",
            plbl: type==="ADT"?"Adult":"Child",
            fn: "",
            ln: "",
            pty: "P",
            num: "",
            na: "",
            isc: "",
            isd: "",
            exd: "",
            dob_month: "",
            dob_day: "",
            dob_year: "",
            isd_month: "",
            isd_day: "",
            isd_year: "",
            exp_month: "",
            exp_day: "",
            exp_year: "",
        }
    return request;
  }

  const getTravellersList = () => {
    try {
      if (
        context.user_resp !== undefined &&
        context.user_resp?.res !== undefined &&
        context.user_resp?.res?.usr_uniq_id !== undefined
      ) {
        const payload = {
          usr_uniq_id: context.user_resp?.res?.usr_uniq_id
        }
        let tokenKey = Utilities.generateAPIToken(payload);
        CntCommonService.loadTravellers(payload,tokenKey).then((response) => {
          if (response.data !== undefined && response.data.suc) {
              let adtList = response.data.res.filter(
                (pax) => pax.pax_type === "ADT"
              );
              dispatch({ type: "adtTraList", payload: adtList });
              let chdList = response.data.res.filter(
                (pax) => pax.pax_type === "CHD"
              );
              dispatch({ type: "chdTraList", payload: chdList });
          }
        }).catch(function (error) {
          console.error("Error in getTravellersList" + error);      
        });
      }
    } catch (err) {
      console.error("Error in getTravellersList" + err);
    }
  };

  const validatePax = () => {
    if (state.roomPax && state.roomPax.length !== 0) {
      var paxType = "";
      var count = 1;
      let paxName = "";
      for (let eachRoom of state.roomPax) {
        if (eachRoom.pax && eachRoom.pax.length !== 0) {
          for (let val of eachRoom.pax) {
            if (val.ty === "ADT") {
              paxType = "Adult";
            } else {
              paxType = "Child";
            }
            if (val.tl === "") {
              return validateTitle(val.sno, paxType, eachRoom.rmindex);
            }
            if (val.fn === "") {
              return validateName(
                false,
                "first name",
                val.sno,
                paxType,
                eachRoom.rmindex
              );
            } else {
              val.fn = val.fn.trim();
              if (!characterValidation(val.fn)) {
                return validateName(
                  true,
                  "first name",
                  val.sno,
                  paxType,
                  eachRoom.rmindex
                );
              }
            }
            if (val.ln === "") {
              return validateName(
                false,
                "last name",
                val.sno,
                paxType,
                eachRoom.rmindex
              );
            } else {
              val.ln = val.ln.trim();
              if (!characterValidation(val.ln)) {
                return validateName(
                  true,
                  "last name",
                  val.sno,
                  paxType,
                  eachRoom.rmindex
                );
              }
            }
            if (val.fn !== "" && val.fn !== undefined && val.fn.length === 1) {
              dispatch({ type: "notiMessageShow", payload: true });
              dispatch({
                type: "notiMessage",
                payload:
                  (context.translation && context.translation.firstNametwo) +
                  paxType +
                  "" +
                  val.sno +
                  (context.translation && context.translation.forroom) +
                  eachRoom.rmindex,
              });
              dispatch({ type: "notiVarient", payload: "danger" });
              hidemessage();
              return false;
            }
            if (val.ln !== "" && val.ln !== undefined && val.ln.length === 1) {
              dispatch({ type: "notiMessageShow", payload: true });
              dispatch({
                type: "notiMessage",
                payload:
                  (context.translation && context.translation.lastNametwo) +
                  paxType +
                  "" +
                  val.sno +
                  (context.translation && context.translation.forroom) +
                  eachRoom.rmindex,
              });
              dispatch({ type: "notiVarient", payload: "danger" });
              hidemessage();
              return false;
            }

            if (
              val.fn !== "" &&
              val.fn !== undefined &&
              val.ln !== "" &&
              val.ln !== undefined &&
              val.fn === val.ln
            ) {
              dispatch({ type: "notiMessageShow", payload: true });
              dispatch({
                type: "notiMessage",
                payload:
                  (context.translation && context.translation.firstNameNlast) +
                  paxType +
                  "" +
                  val.sno +
                  (context.translation && context.translation.forroom) +
                  eachRoom.rmindex,
              });
              dispatch({ type: "notiVarient", payload: "danger" });
              hidemessage();
              return false;
            }
            //Validate Duplicate Pax
            if (
              val.fn !== "" &&
              val.fn !== undefined &&
              val.ln !== "" &&
              val.ln !== undefined
            ) {
              paxName = val.fn + " " + val.ln;
            }
            if (paxName !== "") {
              for (let room of state.roomPax) {
                if (room.rmindex !== eachRoom.rmindex) {
                  for (let pax of room.pax) {
                    let name = pax.fn + " " + pax.ln;
                    if (paxName === name) {
                      dispatch({ type: "notiMessageShow", payload: true });
                      dispatch({
                        type: "notiMessage",
                        payload: (context.translation && context.translation.leadguestname),
                      });
                      dispatch({ type: "notiVarient", payload: "danger" });
                      hidemessage();
                      return false;
                    }
                  }
                }
              }
            }

            //Lead pax validations
            if (val.ty === "ADT" && val.lead && eachRoom.rmindex === 1) {
              if (val.phn !== undefined) {
                if (val.phn !== null) {
                  val.phn = val.phn.trim();
                }
                if (val.phn === "") {
                  dispatch({ type: "notiMessageShow", payload: true });
                  dispatch({
                    type: "notiMessage",
                    payload: (context.translation && context.translation.plzenterphroom),
                  });
                  dispatch({ type: "notiVarient", payload: "danger" });
                  hidemessage();
                  return false;
                } else if (!mobileNumberValidation(val.phn)) {
                  dispatch({ type: "notiMessageShow", payload: true });
                  dispatch({
                    type: "notiMessage",
                    payload:
                      (context.translation && context.translation.plzentvalemproom),
                  });
                  dispatch({ type: "notiVarient", payload: "danger" });
                  hidemessage();
                  return false;
                }
              }
              if (val.em !== undefined) {
                if (val.em !== "") {
                  val.em = val.em.trim();
                }
                if (val.em === "") {
                  dispatch({ type: "notiMessageShow", payload: true });
                  dispatch({
                    type: "notiMessage",
                    payload: (context.translation && context.translation.plzentvalemidleadpax),
                  });
                  dispatch({ type: "notiVarient", payload: "danger" });
                  hidemessage();
                  return false;
                } else if (!emailValidation(val.em)) {
                  dispatch({ type: "notiMessageShow", payload: true });
                  dispatch({
                    type: "notiMessage",
                    payload:
                      (context.translation && context.translation.plzenteremroomlead),
                  });
                  dispatch({ type: "notiVarient", payload: "danger" });
                  hidemessage();
                  return false;
                }
              }
              if (val.phc !== undefined) {
                if (val.phc === "") {
                  dispatch({ type: "notiMessageShow", payload: true });
                  dispatch({
                    type: "notiMessage",
                    payload: (context.translation && context.translation.plzselephcdldpx),
                  });
                  dispatch({ type: "notiVarient", payload: "danger" });
                  hidemessage();
                  return false;
                }
              }
            }
          }
        } else {
          dispatch({ type: "notiMessageShow", payload: true });
          dispatch({
            type: "notiMessage",
            payload: (context.translation && context.translation.plzentergd),
          });
          dispatch({ type: "notiVarient", payload: "danger" });
          hidemessage();
          return false;
        }
        if (state.custms !== undefined) {
          for (let val of state.custms) {
            if (val.cval === "") {
              dispatch({ type: "notiMessageShow", payload: true });
              dispatch({
                type: "notiMessage",
                payload: (context.translation && context.translation.plzentaddinfo),
              });
              dispatch({ type: "notiVarient", payload: "danger" });
              hidemessage();
              return false;
            }
          }
        }
        count = count + 1;
      }
    } else {
      dispatch({ type: "notiMessageShow", payload: true });
      dispatch({ type: "notiMessage", payload: (context.translation && context.translation.plzentergd) });
      dispatch({ type: "notiVarient", payload: "danger" });
      hidemessage();
      return false;
    }
    return true;
  };

  const validateTitle = (count, type, rm) => {
    dispatch({ type: "notiMessageShow", payload: true });
    dispatch({
      type: "notiMessage",
      payload:
        (context.translation && context.translation.plzselecttitgust) +
        type +
        "" +
        count +
        (context.translation && context.translation.forroom) +
        rm,
    });
    dispatch({ type: "notiVarient", payload: "danger" });
    hidemessage();
    return false;
  };
  const validateName = (name, type, count, pax, rm) => {
    if (!name) {
      dispatch({ type: "notiMessageShow", payload: true });
      dispatch({
        type: "notiMessage",
        payload:
          (context.translation && context.translation.plzEenter) +
          type +
          (context.translation && context.translation.forguest) +
          pax +
          "" +
          count +
          (context.translation && context.translation.forroom) +
          rm,
      });
      dispatch({ type: "notiVarient", payload: "danger" });
      hidemessage();
      return false;
    } else {
      dispatch({ type: "notiMessageShow", payload: true });
      dispatch({
        type: "notiMessage",
        payload:
          (context.translation && context.translation.plzentval) +
          type +
          (context.translation && context.translation.forguest) +
          pax +
          "" +
          count +
          (context.translation && context.translation.forroom) +
          rm,
      });
      dispatch({ type: "notiVarient", payload: "danger" });
      hidemessage();
      return false;
    }
  };

  const hidemessage = () => {
    setTimeout(function () {
      dispatch({ type: "notiMessageShow", payload: false });
      dispatch({ type: "notiMessage", payload: "" });
      dispatch({ type: "notiVarient", payload: "" });
    }, 3000);
  };

  const handleChangeText = (idx, type, paxType,rmIndex) => (e) => {
    const { id, value } = e.target;
    try {
      let paxList = state.roomPax;
      for(let room of paxList){
      if(Number(room.rmindex)===Number(rmIndex)){
      for (let i = 0; i < room.pax.length; i++) {
        if (i === idx) {
            if (type === "fn") {
              room.pax[i].fn = value;
            } else if (type === "ln") {
              room.pax[i].ln = value;
            } else if (room.pax[i].lead && type === "em") {
              room.pax[i].em = value;
            } else if (room.pax[i].lead && type === "phn") {
              room.pax[i].phn = value;
            } else if (type === "pnum") {
              room.pax[i].num = value;
            }
          }
      }
      dispatch({ type: "roomPax", payload: paxList });
      props.sendPsngersData(paxList);
      }
      }
    } catch (err) {
      console.error("Eroor+++++++++++" + err);
    }
  };

  const handleChangeRequest = (rmIndex) => (e) => {
    const { id, value } = e.target;
    try {
      let paxList = state.roomPax;
      for(let room of paxList){
      if(Number(room.rmindex)===Number(rmIndex)){
        room.spr=value;
      }
      }
      dispatch({ type: "roomPax", payload: paxList });
      props.sendPsngersData(paxList);
    } catch (err) {
      console.error("Eroor+++++++++++" + err);
    }
  };

  // OnChange event we will call this and update the state
  const handleChangeSelect = (idx, type, field, paxType, rmIndex) => (e) => {
    try {
      if (e !== "") {
        let paxList = state.roomPax;
        for(let room of paxList){
        if(Number(room.rmindex)===Number(rmIndex)){
        // for (let pax of room.pax) {
        //   if (pax.sno === idx) {
          for (let i = 0; i < room.pax.length; i++) {
            if (i === idx) {
              if(type === "tl") {
                room.pax[i].tl = e;
              } else if (room.pax[i].lead && type === "phc") {
                room.pax[i].phc = e;
              } else if (field === "dob") {
                createDobInfo(room.pax[i], type, e);
              } else if (field === "isudate") {
                createISDateInfo(room.pax[i], type, e);
              } else if (field === "expiry") {
                createExpiryInfo(room.pax[i], type, e);
              } else if (type === "na") {
                room.pax[i].na = e;
              } else if (type === "isc") {
                room.pax[i].isc = e;
              }
          }
        }
      }
      }
      dispatch({ type: "roomPax", payload: paxList });
        props.sendPsngersData(paxList);
      }
    } catch (err) {
      console.error("Eroor+++++++++++" + err);
    }
  };

  const createDobInfo = (pax, type, e) => {
    if (type === "month") {
        pax.dob_month = e;
    } else if (type === "day") {
        pax.dob_day = e;
    } else if (type === "year") {
        pax.dob_year = e;
    }
    return pax;
  };
  const createISDateInfo = (pax, type, e) => {
    if (type === "month") {
        pax.isd_month = e;
    } else if (type === "day") {
        pax.isd_day = e;
    } else if (type === "year") {
        pax.isd_year = e;
    }
    return pax;
  };

  const createExpiryInfo = (pax, type, e) => {
    if (type === "month") {
        pax.exp_month = e;
    } else if (type === "day") {
        pax.exp_day = e;
    } else if (type === "year") {
        pax.exp_year = e;
    }
  };

  const convertDobMonth = (pax, dob, type) => {
    if(dob !== null && dob !== undefined && dob !== "") {
      let dobAry = dob.split("-");
      if (type === "dob") {
        pax.dob_month = dobAry[1];
      } else if (type === "isd") {
        pax.isd_month = dobAry[1];
      } else {
        pax.exp_month = dobAry[1];
      }
    } else {
      if (type === "dob") {
        pax.dob_month = "";
      } else if (type === "isd") {
        pax.isd_month = "";
      } else {
        pax.exp_month = "";
      }
    }
  };
  const convertDobDate = (pax, dob, type) => {
    if(dob !== null && dob !== undefined && dob !== "") {
      let dobAry = dob.split("-");
      if (type === "dob") {
        pax.dob_day = dobAry[0];
      } else if (type === "isd") {
        pax.isd_day = dobAry[0];
      } else {
        pax.exp_day = dobAry[0];
      }
    } else {
      if (type === "dob") {
        pax.dob_day = "";
      } else if (type === "isd") {
        pax.isd_day = "";
      } else {
        pax.exp_day = "";
      }
    }
  };
  const convertDobYear = (pax, dob, type) => {
    if(dob !== null && dob !== undefined && dob !== "") {
      let dobAry = dob.split("-");
      pax.year = dobAry[2];
      if (type === "dob") {
        pax.dob_year = dobAry[2];
      } else if (type === "isd") {
        pax.isd_year = dobAry[2];
      } else {
        pax.exp_year = dobAry[2];
      }
    } else {
      if (type === "dob") {
        pax.dob_year = "";
      } else if (type === "isd") {
        pax.isd_year = "";
      } else {
        pax.exp_year = "";
      }
    }
  };
  const updatePax = (e, obj, paxType, idx,roomIndex) => {
    let paxList = state.roomPax;
    for (let room of paxList) {
      if(room.rmindex===roomIndex){
        // for(let pax of room.pax){
        //   if (pax.sno === idx) {
        for (let i = 0; i < room.pax.length; i++) {
          if (i === idx) {
            if (paxType !== "INF") {
              room.pax[i].tl = obj.title;
              room.pax[i].fn = obj.fname;
              room.pax[i].ln = obj.lname;
              room.pax[i].em = obj.email;
              room.pax[i].phc = obj.phn_code;
              room.pax[i].phn = obj.ph_num;
              room.pax[i].trave_id = e;
              room.pax[i].num = obj.passport_no;
              room.pax[i].na = obj.na;
              room.pax[i].isc = obj.issue_cnt;
              //DOB
              convertDobMonth(room.pax[i], obj.dob, "dob");
              convertDobDate(room.pax[i], obj.dob, "dob");
              convertDobYear(room.pax[i], obj.dob, "dob");
              //ISSUE DATE
              convertDobMonth(room.pax[i], obj.issue_date, "isd");
              convertDobDate(room.pax[i], obj.issue_date, "isd");
              convertDobYear(room.pax[i], obj.issue_date, "isd");
              //EXP DATE
              convertDobMonth(room.pax[i], obj.exp_date, "exp");
              convertDobDate(room.pax[i], obj.exp_date, "exp");
              convertDobYear(room.pax[i], obj.exp_date, "exp");
              break;
            }
          }
        }
     
    }
    }
    dispatch({ type: "roomPax", payload: paxList });
    props.sendPsngersData(paxList);
  };

  const handleShowModal = () => {
    setVisible(true);
  };
  const handleHideModal = () => {
    setVisible(false);
    // window.location.reload();
  };

  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };


  const handleClick = (e) => {

    let cn = e.currentTarget.classList;

    if (cn.contains('active')) {
      cn.remove('active')
    } else {
      cn.add('active')
    }
  };

  const handleClickInside = (e) => {

    let cn = e.currentTarget.classList;

    if (cn.contains('active')) {
      cn.remove('active')
    } else {
      cn.add('active')
    }
  };

  // Handle extras click event
  const handleTabsClick = (rowIndex, extrasIndex) => {
    if (selectedIndexes[rowIndex] === extrasIndex) {
      // If the clicked title is already selected, hide it
      selectedIndexes[rowIndex] = null;
      setSelectedIndexes([...selectedIndexes]);
    } else {
      // If the clicked title is not selected, show it and hide others in the same row
      selectedIndexes[rowIndex] = extrasIndex;
      setSelectedIndexes([...selectedIndexes]);
    }
  };

  return (
    <>
      {state.notiMessageShow ? (
        <Alert
          description={state.notiMessage}
          type={state.notiVarient}
          closable
          onClose={() => dispatch({ type: "notiMessageShow", payload: false })}
          className="alertForm"
        >
          {state.notiMessage}
        </Alert>
      ) : (
        ""
      )}
      <div className="addDetails">
        <span className="number">{context.translation && context.translation.two}</span>

        <Title level={4}>
          <span className="addDetailsTitle">{context.translation && context.translation.addGuestDetails}</span>
          {context.user_resp === "" && (
            <>
            <span className="fasterBookingWrap">
              <span className="fasterBooking">
                {context.translation && context.translation.getBok}{" "}
              </span>
              <Link to="#" className="signInFocus" onClick={handleShowModal}>
                <i className="sprite sign-sprite"></i> {context.translation && context.translation.signInSignUp}
              </Link>
            </span>
            </>
          )}
        </Title>
        <AuthenticationPopUp
          visible={visible}
          onCancel={handleHideModal}
          htlReviewSignin={signin}
        />
      </div>
      {state.roomPax && state.roomPax.length > 0 && (
        <>
        {state.roomPax.map((roomInfo, index) => (//R0OM INFO
        <div className="paxList" key={index}>
          <div className="paxDetailsWrapper">
            <div 
             className={"commonFlex guestDetailHeader " + (index===0 && 'active')}  
             onClick={handleClick}>
              <div className="guest">
                <span>{context.translation && context.translation.room} {roomInfo.rmindex}. </span>
                <span className="roomAdultChild">
                      {
                          " " +
                          roomInfo.adt +
                          (roomInfo.adt > 1 ? " Adults" : " Adult") +
                          (roomInfo.chd && roomInfo.chd > 0
                            ? " & " + roomInfo.chd + (roomInfo.chd > 1 ? " Childs" : " Child")
                            : "") +
                          ""
                        }
                  </span>
              </div>
              <div>
              <span>
                    <i
                      className={
                        "icon icon-Minus"
                      }
                    ></i>
                    <i
                      className={
                        "icon icon-Plus"
                      }
                    ></i>
                  </span>
                </div>
            </div>
            <div className="guestDetailBodySec">
          {roomInfo.pax.map((room_pax, idx) => (//R0OM LEVEL PAX INFO
            <div className=" guestDetailBody" key={idx}>
              <div
                className={
                  " guestDetailSubHeader dFlex " + (idx===0 && 'active')
                }
                onClick={handleClickInside}
               
              >
                {room_pax.ty.toUpperCase() === "ADT" && (
                <div>
                  <Title level={5}>
                  {/* <span className="traveller">
                      {context.translation && context.translation.room} {" "}
                    </span> */}
                    <span className="stages">{context.translation && context.translation.adult} {room_pax.sno}</span>
                  </Title>
                </div>
                )}
                {room_pax.ty.toUpperCase() === "CHD" && (
                <div>
                  <Title level={5}>
                    {/* <span className="traveller">
                      {context.translation && context.translation.room} {room_pax.sno}{" "}
                    </span> */}
                    <span className="stages">{context.translation && context.translation.chd} {room_pax.sno}</span>
                  </Title>
                </div>
                )}
                <div>
                <span>
                    <i
                      className={
                        "icon icon-Minus"
                      }
                    ></i>
                    <i
                      className={
                        "icon icon-Plus"
                      }
                    ></i>
                  </span>
                </div>
              </div>
              {true && (
                <>
                  <div className="guestDetailSubHeaderCont">
                    <Row gutter={20}>
                    {(room_pax.ty.toUpperCase() === "ADT" && state.adtTraList.length !== 0 || room_pax.ty.toUpperCase() === "CHD" && state.chdTraList.length !== 0) && (
                      <Col span={8} className="selectGuest">
                        <Form.Item label="">
                          {room_pax.ty.toUpperCase() === "ADT" &&
                            state.adtTraList.length !== 0 && (
                            <Select
                                name="title"
                                defaultValue={context.translation && context.translation.selectGuest}
                                value={
                                room_pax.trave_id
                                    ? room_pax.trave_id
                                    : undefined
                                }
                                onSelect={(value, event) =>
                                updatePax(value, event, "ADT", idx,roomInfo.rmindex)
                                }
                                className="selectBoxShadow"
                                options={state.adtTraList}
                            />
                            )}
                            {room_pax.ty.toUpperCase() === "CHD" && 
                            state.chdTraList.length !== 0 && (
                            <Select
                                name="title"
                                defaultValue={context.translation && context.translation.selectGuest}
                                value={
                                room_pax.trave_id
                                    ? room_pax.trave_id
                                    : undefined
                                }
                                onSelect={(value, event) =>
                                updatePax(value, event, "CHD", idx,roomInfo.rmindex)
                                }
                                options={state.chdTraList}
                                className="selectBoxShadow"
                            />
                            )}
                        </Form.Item>
                      </Col>
                      )}
                    </Row>
                    <Row gutter={20} className="nameDateWrap">
                      <Col span={8}>
                        <Form.Item autoComplete="off" className="pRelative">
                          <label className="requiredField">{context.translation && context.translation.fn}</label>
                          {room_pax.ty.toUpperCase() === "ADT" && (
                          <Input
                            addonBefore={
                              <Select
                                name="title"
                                // defaultValue={context.translation && context.translation.select}
                                defaultValue={context.translation && context.translation.select ? context.translation.select : "Select"}
                                value={room_pax.tl ? room_pax.tl : undefined}
                                onChange={handleChangeSelect(
                                  idx,
                                  "tl",
                                  "title",
                                  room_pax.ty,
                                  roomInfo.rmindex
                                )}
                                options={context.selectLang==="en"||context.selectLang==undefined?adt_titles:adt_titlesar}
                              />
                            }
                            className="selectBoxShadow"
                            type="text"
                            name="fn"
                            value={room_pax.fn}
                            placeholder={context.translation && context.translation.efn}
                            onChange={handleChangeText(
                              idx,
                              "fn",
                              room_pax.ty,roomInfo.rmindex
                            )}
                          />
                          )}
                        {room_pax.ty.toUpperCase() === "CHD" && (
                          <Input
                            addonBefore={
                              <Select
                                name="tl"
                                // defaultValue={context.translation && context.translation.select}
                                defaultValue={context.translation && context.translation.select ? context.translation.select : "Select"}
                                value={room_pax.tl ? room_pax.tl : undefined}
                                onChange={handleChangeSelect(
                                  idx,
                                  "tl",
                                  "title",
                                  room_pax.ty,
                                  roomInfo.rmindex
                                )}
                                options={context.selectLang==="en"||context.selectLang==undefined?chd_titles:chd_titlesar}
                              />
                            }
                            className="selectBoxShadow"
                            type="text"
                            name="fn"
                            value={room_pax.fn}
                            placeholder={context.translation && context.translation.efn}
                            onChange={handleChangeText(
                              idx,
                              "fn",
                              room_pax.ty,roomInfo.rmindex
                            )}
                          />
                        )}
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item className="pRelative">
                          <label className="requiredField">{context.translation && context.translation.ln}</label>
                          <Input
                            type="text"
                            className="innerBoxShadow"
                            name="ln"
                            value={room_pax.ln}
                            placeholder={context.translation && context.translation.eln}
                            onChange={handleChangeText(
                              idx,
                              "ln",
                              room_pax.ty,roomInfo.rmindex
                            )}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item>
                          {room_pax.ty === "ADT" && (
                            <label className="requiredField">
                                {context.translation && context.translation.dob}
                            </label>
                          )}
                          {room_pax.ty === "CHD" && (
                            <label className="requiredField">{context.translation && context.translation.dob}</label>
                          )}
                          <div className="onlyFlex">
                            <div className="dateMY month">
                              <Month
                                updateMonth={room_pax.dob_month}
                                sendMonth={handleChangeSelect(
                                  idx,
                                  "month",
                                  "dob",
                                  room_pax.ty,
                                  roomInfo.rmindex
                                )}
                              />
                            </div>
                            <div className="dateMY day">
                              <Days
                                updateDay={room_pax.dob_day}
                                sendDay={handleChangeSelect(
                                  idx,
                                  "day",
                                  "dob",
                                  room_pax.ty,
                                  roomInfo.rmindex
                                )}
                              />
                            </div>
                            <div className="dateMY year">
                              <Years
                                updateYear={room_pax.dob_year}
                                type={room_pax.ty === "ADT" ? "HTLADT" : "HTLCHD"}
                                sendYear={handleChangeSelect(
                                  idx,
                                  "year",
                                  "dob",
                                  room_pax.ty,
                                  roomInfo.rmindex
                                )}
                              />
                            </div>
                          </div>
                        </Form.Item>
                      </Col>
                    </Row>
                    {room_pax.lead && 
                    <Row gutter={20}>
                      <Col span={8}>
                        <Form.Item className="pRelative">
                          <label className="requiredField">{context.translation && context.translation.em}</label>
                          <Input
                            type="text"
                            className="innerBoxShadow"
                            name="em"
                            value={room_pax.em}
                            placeholder={context.translation && context.translation.eem}
                            onChange={handleChangeText(
                              idx,
                              "em",
                              room_pax.ty,roomInfo.rmindex
                            )}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item autoComplete="off" className="pRelative">
                          <label className="requiredField">
                            {context.translation && context.translation.ph}
                          </label>
                          <Input
                            addonBefore={
                              <Select
                                optionLabelProp="value"
                                popupClassName="contactDpDown"
                                name="phc"
                                showSearch
                                // defaultValue={context.translation && context.translation.select}
                                defaultValue={context.translation && context.translation.select ? context.translation.select : "Select"}
                                value={
                                  room_pax.phc ? room_pax.phc : undefined
                                }
                                onChange={handleChangeSelect(
                                  idx,
                                  "phc",
                                  "code",
                                  room_pax.ty,
                                  roomInfo.rmindex
                                )}
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  (option?.label ?? "")
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                                }
                                options={stdCodes}
                              />
                            }
                            className="selectBoxShadow enterContactNumber"
                            type="number"
                            name="phn"
                            max={10}
                            defaultValue={context.translation && context.translation.select}
                            value={room_pax.phn ? room_pax.phn : undefined}
                            placeholder={context.translation && context.translation.eph}
                            onChange={handleChangeText(
                              idx,
                              "phn",
                              room_pax.ty,roomInfo.rmindex
                            )}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    }
                    <div className="emailDivider"></div>
                    <Row gutter={20}>
                      <Col span={8}>
                        <Form.Item className="pRelative">
                          <label
                            className={
                              state.docMandatory === "yes" ||
                              props.indPassportEnable
                                ? "requiredField"
                                : "notRequiredField"
                            }
                          >
                            {context.translation && context.translation.pn}
                          </label>
                          <Input
                            type="text"
                            className="innerBoxShadow"
                            name="num"
                            value={room_pax.num}
                            placeholder={context.translation && context.translation.epn}
                            onChange={handleChangeText(
                              idx,
                              "pnum",
                              room_pax.ty,roomInfo.rmindex
                            )}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item className="pRelative">
                          <label
                            className={
                              state.docMandatory === "yes" ||
                              props.checkMandatoryFlagNat === true
                                ? "requiredField"
                                : "notRequiredField"
                            }
                          >
                            {context.translation && context.translation.nn}
                          </label>
                          <Select
                            // defaultValue={context.translation && context.translation.select}
                            defaultValue={context.translation && context.translation.select ? context.translation.select : "Select"}
                            showSearch
                            optionFilterProp="children"
                            name="na"
                            value={
                              room_pax.na ? room_pax.na : undefined
                            }
                            className="selectBoxShadow"
                            onChange={handleChangeSelect(
                              idx,
                              "na",
                              "isc",
                              room_pax.ty,
                              roomInfo.rmindex
                            )}
                            filterOption={(input, option) =>
                              (option?.label ?? "")
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            options={countrieslist}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item>
                          <label
                            className={
                              state.docMandatory === "yes"
                                ? "requiredField"
                                : "notRequiredField"
                            }
                          >
                            {context.translation && context.translation.ic}
                          </label>
                          <Select
                            // defaultValue={context.translation && context.translation.select}
                            defaultValue={context.translation && context.translation.select ? context.translation.select : "Select"}
                            showSearch
                            optionFilterProp="children"
                            name="isc"
                            value={
                              room_pax.isc ? room_pax.isc : undefined
                            }
                            className="selectBoxShadow"
                            onChange={handleChangeSelect(
                              idx,
                              "isc",
                              "isd",
                              room_pax.ty,
                              roomInfo.rmindex
                            )}
                            filterOption={(input, option) =>
                              (option?.label ?? "")
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            options={countrieslist}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={20} className="issueExpiryDateWrap">
                      <Col span={8}>
                        <Form.Item>
                          <label className="notRequiredField">
                            {context.translation && context.translation.iDate}
                          </label>
                          <div className="onlyFlex">
                                  <div className="dateMY month">
                                    <Month
                                      updateMonth={room_pax.isd_month}
                                      sendMonth={handleChangeSelect(
                                        idx,
                                        "month",
                                        "isudate",
                                        room_pax.ty,
                                        roomInfo.rmindex
                                      )}
                                    />
                                  </div>
                                  <div className="dateMY day">
                                    <Days
                                      updateDay={room_pax.isd_day}
                                      sendDay={handleChangeSelect(
                                        idx,
                                        "day",
                                        "isudate",
                                        room_pax.ty,
                                        roomInfo.rmindex
                                      )}
                                    />
                                  </div>
                                  <div className="dateMY year">
                                    <Years
                                      updateYear={room_pax.isd_year}
                                      type="isuDate"
                                      sendYear={handleChangeSelect(
                                        idx,
                                        "year",
                                        "isudate",
                                        room_pax.ty,
                                        roomInfo.rmindex
                                      )}
                                    />
                                  </div>
                                </div>
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item>
                          <label
                            className={
                              state.docMandatory === "yes" ||
                              props.indPassportEnable
                                ? "requiredField"
                                : "notRequiredField"
                            }
                          >
                            {context.translation && context.translation.eDate}
                          </label>
                          <div className="onlyFlex">
                                      <div className="dateMY month">
                                        <Month
                                          updateMonth={room_pax.exp_month}
                                          sendMonth={handleChangeSelect(
                                            idx,
                                            "month",
                                            "expiry",
                                            room_pax.ty,
                                            roomInfo.rmindex
                                          )}
                                        />
                                      </div>
                                      <div className="dateMY day">
                                        <Days
                                          updateDay={room_pax.exp_day}
                                          sendDay={handleChangeSelect(
                                            idx,
                                            "day",
                                            "expiry",
                                            room_pax.ty,
                                            roomInfo.rmindex
                                          )}
                                        />
                                      </div>
                                      <div className="dateMY year">
                                        <Years
                                          updateYear={room_pax.exp_year}
                                          type="expDate"
                                          sendYear={handleChangeSelect(
                                            idx,
                                            "year",
                                            "expiry",
                                            room_pax.ty,
                                            roomInfo.rmindex
                                          )}
                                        />
                                      </div>
                                    </div>
                        </Form.Item>
                      </Col>
                    </Row>
                  </div>
                </>
              )}
              
            </div>
          ))}
          <div className="guestDetailFooter">
                        <div className="addExtra">
                          <div className="">
                            <Title
                              level={5}
                              className={selectedIndexes[index] === 0 && "active"}
                              // onClick={splreqFun}
                              onClick={() => handleTabsClick(index, 0)}
                            >
                              <i className="icon icon-Special-Request"></i> {context.translation && context.translation.specialReq}
                            </Title>
                          </div>
                          <div>
                            <Title
                              level={5}
                              className={selectedIndexes[index] === 1 && "active"}
                              // onClick={cancelPolFun}
                              onClick={() => handleTabsClick(index, 1)}
                            >
                              {context.translation && context.translation.canpolicy} <i className="icon icon-Info"></i> 
                            </Title>
                          </div>
                          <div>
                            <Title
                              level={5}
                              className={selectedIndexes[index] === 2 && "active"}
                              // onClick={impInfoFun}
                              onClick={() => handleTabsClick(index, 2)}
                            >
                              {context.translation && context.translation.impInfo} <i className="icon icon-Info"></i> 
                            </Title>
                          </div>
                        </div>
                        {/* <div className="addExtraInner"> */}
                            {/* {splReqShow && ( */}
                          {selectedIndexes[index] === 0 ? (
                              <div className="addExtraInner">
                                <div className="specialRequest">
                                <TextArea rows={3}
                                type="text"
                                name="spr"
                                value={roomInfo.spr} onChange={handleChangeRequest(roomInfo.rmindex)} placeholder={context.translation && context.translation.peasrtymh} maxLength={200} />
                                </div>
                              </div>
                          ) : null}
                            {/* {cancelPolShow && ( */}
                          {selectedIndexes[index] === 1 ? (
                              <div className="addExtraInner">
                                <div className="cancellationPolicy">
                                {roomInfo.canpolicy && roomInfo.canpolicy.length!==0 &&
                                <>
                                  {roomInfo.canpolicy.map((canp, index) => (
                                    <React.Fragment key={index}>
                                    <p className="importantContent">{canp}</p>
                                    </React.Fragment>
                                  ))}
                                  </>
                                }
                                {roomInfo.canpolicy.length===0 &&
                                  <>
                                  <p className="importantContent">{context.translation && context.translation.nonRefundable}</p>
                                  </>
                                }
                                </div>
                                </div>
                          ) : null}
                                {/* {impInfoShow && ( */}
                          {selectedIndexes[index] === 2 ? (
                              <div className="addExtraInner">
                                <div className="importantInfo">
                                <>
                                {request.htldtls.remarks && request.htldtls.remarks !== undefined &&
                                <div className="toolTipCUstoom cnclInfo">
                                  <p dangerouslySetInnerHTML={{__html: request.htldtls.remarks.instructions}}  className="importantContent"></p>
                                  <p dangerouslySetInnerHTML={{__html: request.htldtls.remarks.specialInstructions}}  className="importantContent"></p>
                                  </div>
                                  }
                                  {(request.htldtls.remarks!==undefined && (request.htldtls.remarks.instructions==="" || request.htldtls.remarks.specialInstructions==="")) &&
                                  <div className="toolTipCUstoom cnclInfo">
                                  <p className="importantContent">N/A</p>
                                  </div>
                                  }
                                  </>
                                </div>
                              </div>
                          ) : null}
                        {/* </div> */}
                      </div>
                      </div>
          </div>
        </div>
        ))}
        </>
      )}
    </>
  ) 
}

export default HotelGuestDetails;
