import { Typography } from "antd";
import React, { useContext, useEffect, useState } from "react";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import CMSService from "../../services/cms/CMSService";
import { Context } from "../../App";
import Utilities from "../utilities/Utilities";
export const REGION_COUNTRY = "REGION_COUNTRY";

const { Title } = Typography;

function YouWillLoveIt(props) {
  const [context, setContext] = useContext(Context)
  const [res, setRes] = useState([]);

  useEffect(() => {
    const payload = { 
      region:
        localStorage.getItem(REGION_COUNTRY) === "IN"
          ? "India"
          : "Qatar",
      channel: "B2C", 
      status: "Active"
    }
    let tokenKey = Utilities.generateAPIToken(payload);
    CMSService.loadTopDestinations(payload,tokenKey)
      .then((response) => {
        if (response.data.suc && response.data.res) {
          setRes(response.data.res[0].des_list);
        }
      })
      .catch(function (error) {
        console.error("You Will Love It response error ", error);
      });
  }, []);

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", background: "#fff", boxShadow: "0px 6px 30px #00000029" }}
        onClick={onClick}
      />
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", background: "#fff", boxShadow: "0px 6px 30px #00000029" }}
        onClick={onClick}
      />
    );
  }

  var settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    rtl: false,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1270,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 999,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: "30px 20px",
          nextArrow: false,
          prevArrow: false,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "30px 20px",
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <>
      {res.length >= 4 && (
        <div className="container ywlis">
          <Title level={2} className="cust-title">
          <i className="icon icon-Thumb"></i>
            {/* <i className="sprite ywli-sprite"></i>  */}
            <span>
              {/* You Will Love It */}
              {context.translation && context.translation.loveIt}
            </span>
          </Title>
          <Slider {...settings} className="cntSlider cntHomepage">
          {res.map((data, index) => {
              return (
                <div key={index}>
          <div className="ywli-slider">
          
            
                <div key={index} className="ywlis-block">
                  <img src={data.url_img} alt="Saudi-Arabia" />
                  <span>
                    <strong>
                      {context.selectLang == undefined || context.selectLang === "en" ? data.city_en : data.city_ar}
                    </strong>
                    <small>{context.selectLang == undefined || context.selectLang === "en" ? data.des_en : data.des_ar}</small>
                  </span>
                </div>
                </div>
            
          </div>
          );
        })}
          </Slider>
          {/* {res.length === 0 &&          
          <Title level={3} align="center">
            <span>No data found</span>
          </Title>  
        } */}
        </div>
      )}
    </>
  );
}

export default YouWillLoveIt;
