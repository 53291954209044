/**
 * @ Author: Srikanth Bandaru
 * @ Create Time: 2023-06-14 18:08:54
 * @ Description: This is the common component for Flight search for One Way and Round Trip
 */

import { LoadingOutlined } from "@ant-design/icons";
import { Alert, Button, Checkbox, Col, Row } from "antd";
import { format } from "date-fns";
import moment from 'moment';
import { debounce } from "lodash";
import React, {
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState,
  useContext,
} from "react";
import { useNavigate } from "react-router-dom";
import DateContainer from "./DateContainer";
import PredectiveSearch from "./predective";
import {isNotNull } from "../../../utilities/validators";
import Cookies from 'universal-cookie'
import { Context , ExchangeRates } from '../../../../App';
import BrandIcon from "../../../../assets/gif/Forever/White/ICON_WHITE_FOREVER.gif";
import CntCommonService from "../../../../services/common/CntCommonService";
import DateUtils from "../../../utilities/DateUtils";
import Utilities from "../../../utilities/Utilities";
let airportsList = require("../common/masterData/DefaultPredictive.json");


const FLIGHT_SEARCH_CID = process.env.REACT_APP_FLIGHT_SEARCH_CID;
const FLIGHT_SEARCH_REGION_TYPE =
  process.env.REACT_APP_FLIGHT_SEARCH_REGION_TYPE;
const FLIGHT_SEARCH_OFFICE_ID = process.env.REACT_APP_FLIGHT_SEARCH_OFFICE_ID;
const COOKIE_EXP_TIME = process.env.REACT_APP_CNT_COOKIE_EXP_TIME;
const initialState = {
  src: [],
  dest: [],
  airportsOW: [],
  selOrigin: [],
  airportsRT: [],
  selDestination: [],
  adt: 1,
  chd: 0,
  inf: 0,
  cls: "Economy",
  paxCount: 1,
  selAir: [],
  tt: 2,
  dd: null,
  ad: null,
  ns: false,
  selDate: null,
};

//=====================================
// Reducer function for userReact Hook
// which will update the state
//=====================================
const reducer = (state, action) => {
  switch (action.type) {
    case "clear":
      return {
        ...state,
        src: "",
        dest: "",
        airportsOW: [],
        selOrigin: [],
        airportsRT: [],
        selDestination: [],
        adt: 1,
        chd: 0,
        inf: 0,
        selDate: [],
      };
    default:
      return { ...state, [action.type]: action.payload };
  }
};

function CommonFlightSearch(props) {
  const { showTripType, showTravellers } = props;
  const navigate = useNavigate();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [source, setSource] = useState("");
  const [sourceCity, setSourceCity] = useState("");
  const [destination, setDestination] = useState("");
  const [destinationCity, setDestinationCity] = useState("");
  const [departureDate, setDepartureDate] = useState(null);
  const [arrivalDate, setArrivalDate] = useState(null);
  const [departureDateFocus, setDepartureDateFocus] = useState(false);
  const [arrivalDateFocus, setArrivalDateFocus] = useState(false);
  const [sourceEnable, setSourceEnable] = useState(false);
  const [destinationEnable, setDestinationEnable] = useState(false);
  const [sourceClearEnable, setSourceClearEnable] = useState(false);
  const [destinationClearEnable, setDestinationClearEnable] = useState(false);
  const [isLoad, setIsLoad] = useState(false);
  const [validFrom, setValidFrom] = useState();
  const [validTo, setValidTo] = useState();
  const [validFromDate, setValidFromDate] = useState();
  const [validToDate, setValidToDate] = useState();
  const [isValid, setIsValid] = useState(false);
  const inputRef = useRef(null);
  const cookies = new Cookies()
  state.tt = props.tt;
  const [modifySearch, setModifySearch] = useState(
    props && props.modify ? props.modify : false
  );
  const [srcPredectiveLoading, setSrcPredectiveLoading] = useState(false);
  const [srcLoaded, setSrcLoaded] = useState(false);
  const [destPredectiveLoading, setDestPredectiveLoading] = useState(false);
  const [destLoaded, setDestLoaded] = useState(false);
  const [srcInfo, setSrcInfo] = useState({});
  const [context, setContext] = useContext(Context);
  const [isArabicLang, setIsArabicLang] = useState(false);
  const [showMsg, setShowMessage] = useState(false);
  const [showError,setShowError] = useState(false);
  const [searchSrc, setSearchSrc] = useState(true);
  const [searchDest, setSearchDest] = useState(true);
  const [onwAirports, setOnwAirpots] = useState([]);
  const [retAirports, setRetAirpots] = useState([]);
  const [srcTabChange, setSrcTabChange] = useState(true);
  const [destTabChange, setDestTabChange] = useState(true);

  useEffect(() => {
    if (showTripType || showTravellers) {
      setDepartureDateFocus(false);
    }
  }, [showTripType, showTravellers]);

  useEffect(() => {
    dispatch({ type: "tt", payload: props.tt });
  }, [props.tt]);

  useEffect(() => {
  localStorage.removeItem("air_codes");
   let src =  cookies.get("DEPT");
   let dest =  cookies.get("ARR");
   if(src!==undefined && src!=="undefined"){
    onSourceSelect(true, src);
    setValidFrom(true);
   }
   if(dest!==undefined && dest!=="undefined"){
    onDestinationSelect(true, dest);
    setValidTo(true);
   }
   loadDefaultAirports();
  }, []);

  const loadDefaultAirports=()=>{
    let options=[];
    airportsList.map((val) => {
      if(val.countryCode===localStorage.getItem("REGION_COUNTRY")){
        options.push(constructData(val));
      }
    });
    setOnwAirpots(options)
    setRetAirpots(options)
  }

  useEffect(() => {
    if (props.data && Object.keys(props.data).length !== 0 && props.data.src) {
      onSourceSelect(true, props.data.src);
      onDestinationSelect(true, props.data.dest);
      if (props.data.modify) setDepartureDateFocus(false);
      let dates = [];
      if (props.data.dd) {
        dates[0] = { $d: props.data.dd };
      }
      if (props.data.ad) {
        dates[1] = { $d: props.data.ad };
      }
      onDepartureDateChange(dates);
      setValidFrom(true);
      setValidTo(true);
      dispatch({ type: "ns", payload: props.data.ns });
      dispatch({ type: "cls", payload: setCls(props.data.cls) });
    }
  }, []);

  useEffect(() => {
    dispatch({ type: "adt", payload: props.sendCount[0] });
    dispatch({ type: "chd", payload: props.sendCount[1] });
    dispatch({ type: "inf", payload: props.sendCount[2] });
    dispatch({
      type: "cls",
      payload: [{ value: props.sendCount[3], label: props.sendCount[3] }],
    });
  }, [props.sendCount]);  

  useEffect(() => {
    //Updating Predictive data based on the Language selection
    if(context.selectLang!==null){
      if(props.data && Object.keys(props.data).length !== 0) {
      if(context.selectLang==="en"){
        setSource(props.data.src.display);
        setDestination(props.data.dest.display);
      }else{
        setSource(props.data.src.display_ar);
        setDestination(props.data.dest.display_ar);
      }
    }else{
      if(state.airportsOW.length > 0 && state.airportsRT.length > 0){
      if(context.selectLang==="en"){
        setSource(state.airportsOW[0].display);
        setDestination(state.airportsRT[0].display);
      }else{
        setSource(state.airportsOW[0].display_ar);
        setDestination(state.airportsRT[0].display_ar);
      }
    }
    }
    }
  }, [context.selectLang]); 

  

  useEffect(() => {
    if(context.mostFlownRoutes !== "" && context.mostFlownRoutes !== undefined) {
        setSearchSrc(false);
        state.src = context.mostFlownRoutes?.sourceDataObj;
        setSrcInfo(context.mostFlownRoutes?.sourceDataObj);
        //setSourceCity(context.mostFlownRoutes?.sourceDataObj?.display);
        if(context.selectLang==="en"){
          setSourceCity(context.mostFlownRoutes?.sourceDataObj?.display);
        }else{
          setSourceCity(context.mostFlownRoutes?.sourceDataObj?.display_ar);
        }
        state.selOrigin = context.mostFlownRoutes?.sourceDataObj?.city;
    
        if(context.mostFlownRoutes?.e){
            context.mostFlownRoutes.sourceCity ? setValidFrom(true) : setValidFrom(false);
            setSourceClearEnable(true);
        }
    }
}, [context.mostFlownRoutes])

useEffect(() => {      
    if(context.mostFlownRoutes !== "" && context.mostFlownRoutes !== undefined) {  
        setSearchDest(false);
        state.dest = context.mostFlownRoutes?.destinationDataObj;
        state.selDestination = context.mostFlownRoutes?.destinationDataObj?.city;
        //setDestinationCity(context.mostFlownRoutes?.destinationDataObj?.display);
        if(context.selectLang==="en"){
          setDestinationCity(context.mostFlownRoutes?.destinationDataObj?.display);
        }else{
          setDestinationCity(context.mostFlownRoutes?.destinationDataObj?.display_ar);
        }
        if(context.mostFlownRoutes?.e){
            context.mostFlownRoutes.destinationCity ? setValidTo(true) : setValidTo(false);
            setDestinationClearEnable(true);
        }
    }
}, [context.mostFlownRoutes])

const checkEnterLang=(input)=>{
  var arabic = /[\u0600-\u06FF]/;
    if(arabic.test(input)){
      setIsArabicLang(true);
      alert(true)
    }
}

  //For handling origin related actions in flight search panel
  const handleOrigin = (input, e) => {
    dispatch({ type: "selOrigin", payload: [] });

    if (input.length > 0) {
      state.selOrigin = [];
      let obj = {
        textValue: input,
        type: "airportsOW",
      };
      state.airportsOW=[];
      debouncedSave(obj);
    } else {
      state.selOrigin = [];
      dispatch({ type: "airportsOW", payload: [] });
    }
  };

  const debouncedSave = useCallback(
    debounce((obj) => predectiveCall(obj.textValue, obj.type), 500),
    [] // will be created only once initially
  );

  //For handling destination related actions in flight search panel
  const handleDestination = (input, e) => {
    dispatch({ type: "selDestination", payload: [] });

    if (input.length > 0) {
      state.selDestination = [];
      let obj = {
        textValue: input,
        type: "airportsRT",
      };
      state.airportsRT=[];
      debouncedSave(obj);
    } else {
      state.selDestination = [];
      dispatch({ type: "airportsRT", payload: [] });
    }
  };

  //For handling predective search hits
  const predectiveCall = async (input, route) => {       
    setShowMessage(false)
    try {
      if (input.length > 0) {
        let data =[]
        // if(localStorage.getItem("air_codes")!==null){
        // let list = JSON.parse(localStorage.getItem("air_codes"));
        // data=list.filter((obj) => obj.airport == input.toUpperCase());
        // }
        if(data.length===0){
        const payload = {
          searchCriteria: input
        };
        let tokenKey = Utilities.generateAPIToken(payload);
        await PredectiveSearch.retreieveSearch(payload,tokenKey).then((data) => {
          const options = [];
          if(data && data.data && !data.data.err && data.data.length > 0){
            data.data.map((val) => {
              options.push(constructData(val));
            });
          dispatch({ type: route, payload: data.data !== "" ? options : [] });
          }else{
            setShowMessage(true)
            dispatch({ type: route, payload: [] });
          }
        }).catch(function (error) {
          console.error("Error in Airports Predective Search" + error);      
        });
      } else {
        if(data.length > 0){
          dispatch({ type: route, payload: data });
        }else{
          dispatch({ type: route, payload: [] });
        }
        
      }
      }    
    } catch (error) {
      console.error("Error in Airports Predective Search" + error);       
    }
  };

  const constructData=(val)=>{
   const req ={
    country: `${val.countryCode}`,
    city: `${val.cityName}`,
    city_ar: `${val.cityName_ar!==undefined?val.cityName_ar:""}`,
    airport: `${val.airportCode}`,
    display: `${val.cityName}(${val.airportCode}), ${val.countryName}`,
    display_ar: `${val.cityName_ar}(${val.airportCode}), ${val.countryName_ar}`,
    label: `${val.airportName}, ${val.countryName}`,
    label_ar: `${val.cityName_ar!==undefined?val.airportName_ar:""}, ${val.countryName_ar!==undefined?val.countryName_ar:""}`,
    value: `${val.airportName}, ${val.cityName} , ${val.countryName} , ${val.airportCode}`,
  }
  return req;
  }

  //For handling source change related actions in flight search panel
  const onSourceChange = (data) => {
    setSearchSrc(true);
    setSrcPredectiveLoading(true);
    setSrcLoaded(false);
    setSrcTabChange(false);
    setSource(data.target.value);
    handleOrigin(data.target.value);
    setSourceClearEnable(true);
    setDepartureDateFocus(false);
    if(!isNotNull(data.target.value)){
      setSourceClearEnable(false);
    }
    setSourceEnable(true);
    if(data.target.value.length>0){
      setSourceEnable(true);
    } else {
      setSourceEnable(false);
    }
  };

  //For handling destination change related actions in flight search panel
  const onDestinationChange = (data) => {
    setSearchDest(true);
    setDestPredectiveLoading(true);
    setDestLoaded(false);
    setDestTabChange(false);
    setDestination(data.target.value);
    handleDestination(data.target.value);
    setDestinationClearEnable(true);
    //destination ? setValidTo(true) : setValidTo(false);
    setDepartureDateFocus(false);
    if(!isNotNull(data.target.value)){
      setDestinationClearEnable(false);
    }
    if(data.target.value.length>0){
      setDestinationEnable(true);
    } else {
      setDestinationEnable(false);
    }
  };

  //For handling source select related actions in flight search panel
  const onSourceSelect = (data, obj) => {
    dispatch({ type: "src", payload: obj });
    dispatch({ type: "selOrigin", payload: obj.city });
    if(context.selectLang==="en"){
      setSource(obj.display);
    }else{
      setSource(obj.display_ar);
    }
    setSourceEnable(false);
    setSourceClearEnable(true);
    //updateAirports(obj);
    if (data) {
      obj.display ? setValidFrom(true) : setValidFrom(false);
    }
    setDepartureDateFocus(false);
  };

  //For handling destination select related actions in flight search panel
  const onDestinationSelect = (data, obj) => {
    dispatch({ type: "dest", payload: obj });
    dispatch({ type: "selDestination", payload: obj.city });
    //setDestination(obj.display);
    if(context.selectLang==="en"){
      setDestination(obj.display);
    }else{
      setDestination(obj.display_ar);
    }
    setDestinationEnable(false);
    setDestinationClearEnable(true);
    //updateAirports(obj);
    if (data) {
      obj.display ? setValidTo(true) : setValidTo(false);
    }
    setDepartureDateFocus(false);
  };

  const updateAirports=(obj)=>{
    if(localStorage.getItem("air_codes")!==null){
      let list= JSON.parse(localStorage.getItem("air_codes"));
      let finalData = list.filter((ob) => ob.airport == obj.airport);
      if(finalData.length ===0){
        list.push(obj);
        localStorage.setItem("air_codes",JSON.stringify(list));
      }
    }else{
       localStorage.setItem("air_codes",JSON.stringify([obj]));
    }
  }

  useEffect(() => {
    if(source && destination && source === destination){
      setShowError(true);
    } else if((!source || !destination)){
      if(source !== destination){
        setShowError(false);
      }
    }
  }, [source,destination])

  //For clearing the source field
  const clearSource = () => {
    setSource("");
    setSourceCity("");
    state.selOrigin = [];
    //setSourceEnable(false);
    setSourceClearEnable(false);
    setValidFrom(false);
    if(onwAirports.length > 0){
      setSourceEnable(true)
      dispatch({ type: "airportsOW", payload: onwAirports });
    }
    //dispatch({ type: "src", payload: [] });
  };

  //For clearing the destination field
  const clearDestination = () => {
    setDestination("");
    setDestinationCity("");
    state.selDestination = "";
    //setDestinationEnable(false);
    setDestinationClearEnable(false);
    setValidTo(false);
    if(retAirports.length > 0){
      setDestinationEnable(true) 
      dispatch({ type: "airportsRT", payload: retAirports });
    }
    //dispatch({ type: "dest", payload: [] });
  };

  useEffect(() => {
    if (source && source.length > 0) {
      if (
        state.airportsOW === undefined ||
        (state.airportsOW && state.airportsOW.length === 0)
      ) {
        setSrcPredectiveLoading(false);
        setSrcLoaded(false);
      } else {
        if (state.airportsOW.length > 0) {
          setSrcPredectiveLoading(false);
          setSrcLoaded(true);
        }
      }
    } else {
      setSrcPredectiveLoading(false);
    }
  }, [state.airportsOW]);

  useEffect(() => {
    if (destination && destination.length > 0) {
      if (
        state.airportsRT === undefined ||
        (state.airportsRT && state.airportsRT.length === 0)
      ) {
        setDestPredectiveLoading(false);
        setDestLoaded(false);
      } else {
        if (state.airportsRT.length > 0) {
          setDestPredectiveLoading(false);
          setDestLoaded(true);
        }
      }
    } else {
      setSrcPredectiveLoading(false);
    }
  }, [state.airportsRT]);

  //=====================================
  // This function will get the Pax count and Total count for Add traveller
  //=====================================
  const paxCount = useCallback((data) => {
    dispatch({ type: "adt", payload: data[0] });
    dispatch({ type: "chd", payload: data[1] });
    dispatch({ type: "inf", payload: data[2] });
    dispatch({ type: "paxCount", payload: data[3] });
  }, []);

  //=====================================
  // This function will get Class
  //=====================================
  const clsType = useCallback((type) => {
    dispatch({ type: "cls", payload: type });
  }, []);

  //=================================================
  // This function will close Travel details pop up
  //=================================================
  const closeTravel = (type) => {
    if (type === "coseTrv") {
      //setAddTraveller(false);
    }
  };

  //For handling the trip type changes
  const handleTripType = (data) => {
    state.tt = data;
    dispatch({ type: "tt", payload: data });
  };

  //For handling the date focus in the flight panel
  const dateFocus = useCallback((data) => {
    setDepartureDateFocus(data);
    setSourceEnable(false);
    setDestinationEnable(false);
    props.togglett(false);
    props.toggleTravellers(false);
  }, []);

  //For handling the departure date changes in the flight search panel
  const onDepartureDateChange = useCallback((data, tripType) => {
    if (!tripType) tripType = state.tt;
    if (tripType === 2 && data && data[0] && data[1]) {
      dispatch({ type: "selDate", payload: data });
      if (data[0]) {
        setDepartureDate(data ? new Date(data[0].$d) : null);
        dispatch({
          type: "dd",
          payload: data ? format(new Date(data[0].$d), "yyyy-MM-dd") : null,
        });
        data ? setValidFromDate(true) : setValidFromDate(false);
      }
      if (data[1] && isNotNull(data[1].$d)) {
        setArrivalDate(data ? new Date(data[1].$d) : null);
        setDepartureDateFocus(false);
        dispatch({
          type: "ad",
          payload: data ? format(new Date(data[1].$d), "yyyy-MM-dd") : null,
        });
        data ? setValidToDate(true) : setValidToDate(false);
      }
    }
   else if (tripType !== 2 && data) {
       {
        data = data.$d ? data : data[0] ? data[0] : undefined;
        if (data) {
          dispatch({ type: "selDate", payload: data });
          setDepartureDate(data ? new Date(data.$d) : null);
          setDepartureDateFocus(false);
          dispatch({
            type: "dd",
            payload: data ? format(new Date(data.$d), "yyyy-MM-dd") : null,
          });
          data ? setValidFromDate(true) : setValidFromDate(false);
        }
      }
    } else {
      setDepartureDate(null);
      setArrivalDate(null);
      setValidFromDate(false);
      setValidToDate(false);
    }
  }, []);

  const fieldFoucs = (e) => {
    document.querySelector(".dest-from").classList.add("fieldFocus");
  };

  const changeTripType = () => {
    props.sendReturn("Round");
  };

  const checkAndRemoveStoredIternaries = () => {
    if (localStorage.getItem("AVAILABILITY_FAILURE") !== null) {
      localStorage.removeItem("AVAILABILITY_FAILURE");
      localStorage.removeItem("SELECTED_FLIGHT_OW");
      localStorage.removeItem("SELECTED_FLIGHT_RT");
      localStorage.removeItem("SELECTED_MULTI_ONE");
      localStorage.removeItem("SELECTED_MULTI_TWO");
      localStorage.removeItem("SELECTED_MULTI_THREE");
      localStorage.removeItem("SELECTED_MULTI_FOUR");
      localStorage.removeItem("SELECTED_MULTI_FIVE");
      localStorage.removeItem("SELECTED_MULTI_SIX");
    }
  };

  const storeCookie=()=>{
    let src =  cookies.get("DEPT");
    let dest =  cookies.get("ARR");
    if(src!==undefined && src!=="undefined"){
      if(state.src === undefined) {
        state.src =  cookies.get("DEPT");
      }
    }
    if(dest!==undefined && dest!=="undefined"){
      if(state.dest === undefined) {
        state.dest =  cookies.get("ARR");
      }
    }
    let maximumAge = COOKIE_EXP_TIME && parseFloat(COOKIE_EXP_TIME);
    if(process.env.NODE_ENV!=="development"){
      cookies.set('DEPT',JSON.stringify(state.src), {path: '/', maxAge: maximumAge,secure :true,httpOnly :true,sameSite:true});
      cookies.set('ARR',JSON.stringify(state.dest), {path: '/', maxAge: maximumAge,secure :true,httpOnly :true,sameSite:true});
    }else{
      cookies.set('DEPT',JSON.stringify(state.src), {path: '/', maxAge: maximumAge,sameSite:true});
      cookies.set('ARR',JSON.stringify(state.dest), {path: '/', maxAge: maximumAge,sameSite:true});
    }
    // if(state.tt===1){
    //   if(process.env.NODE_ENV!=="development"){
    //     cookies.set('DEPT_DT',state.dd, {path: '/', maxAge: maximumAge,secure :true,httpOnly :true,sameSite:true});
    //   }else{
    //     cookies.set('DEPT_DT',state.dd, {path: '/', maxAge: maximumAge,sameSite:true});
    //   }
    // }else{
    //   if(process.env.NODE_ENV!=="development"){
    //     cookies.set('DEPT_DT',state.dd, {path: '/', maxAge: maximumAge,secure :true,httpOnly :true,sameSite:true});
    //     cookies.set('ARR_DT',state.ad, {path: '/', maxAge: 86400,secure :true, httpOnly :true,sameSite:true});
    //   }else{
    //     cookies.set('DEPT_DT',state.dd, {path: '/', maxAge: maximumAge,sameSite:true});
    //     cookies.set('ARR_DT',state.ad, {path: '/', maxAge: 86400,sameSite:true});
    //   }
    // }
  }
  
  const constructURL = () => {
    try {
      let isPaxChanged = false;
      setIsLoad(true);
      storeCookie();
      let cls = setClsType();
      const params = new URLSearchParams({
        srcCode: state.src.airport,
        destCode: state.dest.airport,
        dd: state.dd,
        ad: state.ad,
        tt: state.tt,
        adt: state.adt,
        chd: state.chd,
        inf: state.inf,
        ns: state.ns,
        cls: cls,
        modify: modifySearch,
        usr_cur: context.userCur!=="" && context.userCur!==undefined ? context.userCur : sessionStorage.getItem("SEL_CUR"),
      });
      const urlParams = params.toString();
      // Construct the URL with the parameters
      const url = `/flight/results?${urlParams}`;
      var h = window.location.host.includes('localhost')?'http':'https';
      window.location.href = `${h}://${window.location.host}`+url;
      //navigate(url);
    } catch (e) {
      console.error("Exception in flight search request" + e);
    }
  }

  //The search hit call for one way and round trip
  const handleSearch = () => {
    checkAndRemoveStoredIternaries();
    if (isValid & !showError) {
      constructURL();
    } else {
      validateSearch();
    }
  };

  //=====================================
  // This function will check the Class type
  //=====================================
  const setClsType = () => {
    var cl = "E";
    if (state.cls && state.cls.length > 0)
      if (state.cls[0].value === "Economy"|| state.cls[0].value === "سياحية") {
        cl = "E";
      } else if (state.cls[0].value === "Business"||state.cls[0].value === "رجال الأعمال") {
        cl = "B";
      } else if (state.cls[0].value === "First class"||state.cls[0].value === "الصف الأول") {
        cl = "F";
      } else if (state.cls[0].value == "Premium"||state.cls[0].value == "غالي") {
        cl = "P";
      }
    return cl;
  };
  
  const setCls = (selcls) => {
    let cl = {};
    if (selcls && selcls.length > 0)
      if (selcls === "E") {
        cl = { value: "Economy", label: "Economy", title: " " }
      } else if (selcls === "P") {
        cl = { value: "Premium", label: "Premium Economy", title: " " };
      } else if (selcls === "B") {
        cl = { value: "Business", label: "Business", title: " " };
      } else if (selcls == "F") {
        cl = { value: "First class", label: "First class", title: " " };
      }
      return cl;
  };

  //For handling the change direct flight
  const handleChangeDirectFlight = (e) => {
    dispatch({ type: "ns", payload: !state.ns });
  };

  //For handling the focus on dates ,trip types and travellers
  const handleClickOutsideRangePicker = (event) => {
    if (
      inputRef.current &&
      inputRef.current instanceof HTMLElement &&
      !inputRef.current.contains(event.target)
    ) {
      if (
        event.target.classList.contains("ant-row") ||
        event.target.classList.contains("ant-col") ||
        event.target.classList.contains("container") ||
        event.target.classList.contains("ant-typography") ||
        event.target.classList.contains("flightSearch") ||
        event.target.classList.contains("select-trips") ||
        event.target.classList.contains("searchPanel") ||
        event.target.classList.contains("offerSliderWrap") ||
        event.target.classList.contains("offerTitle") ||
        event.target.classList.contains("offerDesc") ||
        event.target.classList.contains("signInFocus") ||
        event.target.classList.contains("mainMenu") ||
        event.target.classList.contains("slick-slide") ||
        event.target.classList.contains("action-links") ||
        event.target.classList.contains("multicityBtnWrapper") 
      ) {
        setDepartureDateFocus(false);
        props.togglett(false);
        props.toggleTravellers(false);
        setSourceEnable(false);
        setDestinationEnable(false);
      }
    }
  };

  

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutsideRangePicker);
    return () => {
      document.removeEventListener("mousedown", handleClickOutsideRangePicker);
    };
  }, []);

  //For swapping soruce and destinations selections
  const swapSrcDest = () => {
    let src = cookies.get("DEPT");
    let dest = cookies.get("ARR");
    if(src!==undefined && src!=="undefined"){
      if(state.src === undefined) {
        state.src = src;
      }
      if(state.selOrigin === undefined) {
        state.selOrigin = src.city;
      }
    }
    if(dest!==undefined && dest!=="undefined"){
      if(state.dest === undefined) {
        state.dest = dest;
      }
      if(state.selDestination === undefined) {
        state.selDestination = dest.city;
      }
    }
    if (
      state.src != undefined &&
      state.selOrigin != undefined &&
      state.dest != undefined &&
      state.selDestination != undefined &&
      source != undefined &&
      source != "" &&
      destination != undefined &&
      destination != ""
    ) {
      let sourceObj = state.src;
      let destinationObj = state.dest;

      dispatch({ type: "selOrigin", payload: destinationObj.city });
      dispatch({ type: "src", payload: destinationObj });
      dispatch({ type: "selDestination", payload: sourceObj.city });
      dispatch({ type: "dest", payload: sourceObj });
      if(context.selectLang==="en"){
        setSource(destinationObj.display);
      }else{
        setSource(destinationObj.display_ar);
      }
      if(context.selectLang==="en"){
        setDestination(sourceObj.display);
      }else{
        setDestination(sourceObj.display_ar);
      }
    }
  };

  useEffect(() => {
    const validate = Boolean(
      state.tt === 1
        ? validFrom && validTo && validFromDate
        : validFrom && validTo && validFromDate && validToDate
    );
    setIsValid(validate);

    validFrom !== undefined && !validFrom
      ? document.querySelector(".dest-from").classList.add("errorBox")
      : document.querySelector(".dest-from").classList.remove("errorBox");
    validTo !== undefined && !validTo
      ? document.querySelector(".dest-to").classList.add("errorBox")
      : document.querySelector(".dest-to").classList.remove("errorBox");
    if (state.tt === 1)
      validFromDate !== undefined && !validFromDate
        ? document.querySelector(".oneWayDate") &&
          document.querySelector(".oneWayDate").classList.add("errorDate")
        : document.querySelector(".oneWayDate") &&
          document.querySelector(".oneWayDate").classList.remove("errorDate");
    // if (state.tt === 2) {
    //   validFromDate !== undefined && !validFromDate
    //     ? document.querySelector(".roundTripDate") &&
    //       document.querySelector(".roundTripDate").classList.add("errorDate")
    //     : document.querySelector(".roundTripDate") &&
    //       document
    //         .querySelector(".roundTripDate")
    //         .classList.remove("errorDate");
    //   validToDate !== undefined && !validToDate ? document.querySelector('.dpicker').classList.add('errorDate') : document.querySelector('.dpicker').classList.remove('errorDate') ;
    // }
    if (state.tt === 2){
      validFromDate !== undefined && !validFromDate
        ? document.querySelector(".roundTripDate") &&
          document.querySelector(".roundTripDate").classList.add("depDate")
        : document.querySelector(".roundTripDate") &&
          document
            .querySelector(".roundTripDate")
            .classList.remove("depDate");
    }
    if (state.tt === 2){
      validToDate !== undefined && !validToDate
        ? document.querySelector(".roundTripDate") &&
          document.querySelector(".roundTripDate").classList.add("retDate")
        : document.querySelector(".roundTripDate") &&
          document
            .querySelector(".roundTripDate")
            .classList.remove("retDate");
    }
  }, [validFrom, validTo, validFromDate, validToDate]);

  //For validating the search before hitting the search api
  const validateSearch = () => {
    if (validFrom === undefined) setValidFrom(false);
    if (validTo === undefined) setValidTo(false);
    if (validFromDate === undefined) setValidFromDate(false);
    if (validToDate === undefined) setValidToDate(false);
  };

  const onSourceFocus = () => {
    setDestinationEnable(false) 
    if(srcTabChange) {
      if(onwAirports.length > 0){
        setSourceEnable(true)
        dispatch({ type: "airportsOW", payload: onwAirports });
      }else{
        loadDefaultAirports();
      }
    }
    setDepartureDateFocus(false);
    props.togglett(false);
    props.toggleTravellers(false);
  }

  const onDestinationFocus = () => {
    setSourceEnable(false)
    if(destTabChange) {
      if(retAirports.length > 0){
        setDestinationEnable(true) 
        dispatch({ type: "airportsRT", payload: retAirports });
      }
    }
    setDepartureDateFocus(false);
    props.togglett(false);
    props.toggleTravellers(false);
  }

  return (
    <>
    {showError && <Alert
            message={context.translation && context.translation.sourceDest}
            closable
            type="error" style={{marginTop: "15px"}}
          />}
      <Row className="search-row" ref={inputRef}>
        <Col className="dest-from s-field">
          <label>
            {/* From */}
            <i className="icon icon-Pin"></i>
            {context.translation && context.translation.from} :
          </label>

          <input
            className="effect-9 destFromTo"
            type="text"
            onFocus={(e) => onSourceFocus()}
            value={source&&searchSrc?source:sourceCity}
            title={source&&searchSrc?source:sourceCity}
            onChange={onSourceChange}
            // placeholder="City or Airport"
            placeholder={context.translation && context.translation.fltSrhPlaceholder}
            // onBlur={handleOnBlur}
          />
          <span className="focus-border">
            <i></i>
          </span>
          {sourceEnable &&  (
            <div className="airportNamecode-box-wrap">
              <ul>
                {/* {(!srcPredectiveLoading && !srcLoaded && source.length > 3) || (showMsg) && ( */}
                   {showMsg && (
                  <li className="no-match-found">
                    <span>
                      <i className="icon icon-Info"></i> 
                      {/* Please enter a valid departure city or airport. */}
                      {context.translation && context.translation.validInputFrom}
                    </span>
                  </li>
                )}
                {state.airportsOW !== undefined &&
                  state.airportsOW.length > 0 &&
                  state.airportsOW.map((result) => (
                    <li
                      key={result.value}
                      onClick={(e) => onSourceSelect(e, result)}
                      className={result.city!='undefined' &&  result.city_ar!=='undefined'?"airportNamecode-box":""}  
                    >
                      
                      {context.selectLang==="en" &&
                      <>
                      <div className="airportNamecode">
                          <span>{result.city}</span>
                          <span>{result.airport}</span>
                        </div>
                        <span> {result.label}</span>
                        </>
                      }
                     {context.selectLang==="ar" &&
                      <>
                      <div className="airportNamecode">
                          <span>{result.city_ar}</span>
                          <span>{result.airport}</span>
                        </div>
                        <span> {result.label_ar}</span>
                        </>
                      } 
                    </li>
                  ))}
              </ul>
            </div>
          )}
          {sourceClearEnable && (
            // <Button
            //   className="predictiveClear ezyIcon icon-close"
            //   onClick={clearSource}
            // />
            <Button onClick={clearSource}>
              <i className="icon icon-cancel clearField"></i>
            </Button>
          )}

        </Col>
        <Col className="swap-dest" onClick={swapSrcDest}>
          <i className="swap-sprite"></i>
        </Col>
        <Col className="dest-to s-field">
          <label>
            {/* To */}
            <i className="icon icon-Pin"></i>
            {context.translation && context.translation.to} :
          </label>
          <input
            className="effect-9 destFromTo"
            type="text"
            value={destination&&searchDest?destination:destinationCity}
            title={destination&&searchDest?destination:destinationCity}
            onFocus={(e) => onDestinationFocus()}
            onChange={onDestinationChange}
            // placeholder="City or Airport"
            placeholder={context.translation && context.translation.fltSrhPlaceholder}
            // onBlur={handleOnBlur}
          />
          <span className="focus-border">
            <i></i>
          </span>
          {destinationEnable && (
            <div className="airportNamecode-box-wrap">
              <ul>
                {/* {(!destPredectiveLoading &&
                  !destLoaded &&
                  destination.length > 3) || (showMsg) && ( */}
                    {showMsg && (
                    <li className="no-match-found">
                      <span>
                        <i className="icon icon-Info"></i> 
                        {/* Please enter a valid arrival city or airport. */}
                        {context.translation && context.translation.validInputTo}
                      </span>
                    </li>
                  )}
                {state.airportsRT !== undefined &&
                  state.airportsRT.length > 0 &&
                  state.airportsRT.map((result) => (
                    <li
                      key={result.value}
                      onClick={(e) => onDestinationSelect(e, result)}
                      className="airportNamecode-box"
                    >
                      {context.selectLang==="en" &&
                      <>
                      <div className="airportNamecode">
                          <span>{result.city}</span>
                          <span>{result.airport}</span>
                        </div>
                        <span> {result.label}</span>
                        </>
                      }
                      {context.selectLang==="ar" && result.city_ar!=='undefined' &&
                      <>
                      <div className="airportNamecode">
                          <span>{result.city_ar}</span>
                          <span>{result.airport}</span>
                        </div>
                        <span> {result.label_ar}</span>
                        </>
                      } 
                    </li>
                  ))}
              </ul>
            </div>
          )}
          {destinationClearEnable && (
            // <Button
            //   className="predictiveClear ezyIcon icon-close"
            //   onClick={clearDestination}
            // />
            <Button onClick={clearDestination}>
              <i className="icon icon-cancel clearField"></i>
            </Button>
          )}
          {/* <AutoComplete
                            placeholder="City or Airport"
                            id="destination"
                            value={destination}
                            label={airport => `${airport.airportCode}, ${airport.cityName} - ${airport.countryName}`}
                            // onSearch={(text) => handleOrigin(text)}
                            options={state.airportsRT}
                            onChange={onDestinationChange}
                            onSelect={onDestinationSelect}
                            autoFocus
                        /> */}
        </Col>

        {state.tt === 2 && (
          <Col className={(context.selectLang === "en" || context.selectLang === undefined) ? "dpicker" : "dpicker dpickerArabic"}>
            <DateContainer
              className="roundTripDate"
              open={departureDateFocus}
              focus={dateFocus}
              onchange={onDepartureDateChange}
              placeholders={context.selectLang==="en"||context.selectLang=== undefined ?["DD/MM/YY", "DD/MM/YY"]:["يوم/شهر/سنة","يوم/شهر/سنة"]}
              allowempty={[false, false]}
              disabled={[false, false]}
              date={new Date()}
              selDate={[state.dd, state.ad]}
              tt={state.tt}
            />
          </Col>
        )}
        {state.tt === 1 && (
          <Col className={(context.selectLang === "en" || context.selectLang === undefined) ? "dpicker" : "dpicker dpickerArabic"}>
            <DateContainer
              className="oneWayDate"
              open={departureDateFocus}
              focus={dateFocus}
              onchange={onDepartureDateChange}
              placeholders={context.selectLang==="en"||context.selectLang=== undefined ?["DD/MM/YY"]:["يوم/شهر/سنة"]}
              date={new Date()}
              selDate={state.dd}
              tt={state.tt}
            />
            <Button
              type="button"
              className="addArrivalDate"
              onClick={changeTripType}
            >
              {/* Add Return Date + */}
              {context.translation && context.translation.rtnDate}
            </Button>
          </Col>
        )}
        <Col className="one-round-btn">
          <Button type="primary" onClick={handleSearch} className="cntBtn planeBtn">
            {/* <img src={ICON_N} alt="logo-icon" /> */}
            {/* Search */}
            {!modifySearch && context.translation && context.translation.search}
            {modifySearch && context.translation && context.translation.modify}
            {/* <i className="sprite btn-arrow-sprite"></i> */}
            <img src={BrandIcon} alt="brandIcon" className="brandIcon" />
          </Button>
        </Col>
      </Row>
      <Row>
        <Col>
          <Checkbox
            value={state.ns}
            checked={state.ns}
            onClick={handleChangeDirectFlight}
            className={"directFlights " + (props.scrollDown ? "" : "dNone")}
          >
            {/* Direct Flights Only */}
            {context.translation && context.translation.dfo}
          </Checkbox>
        </Col>
      </Row>
    </>
  );
}

export default CommonFlightSearch;
