import React, {
    useEffect,
    useRef,
  } from "react";
  import Footer from "./Footer";
  import Header from "./Header";
  import ShowHide from "./showHide/ShowHide";
  import StickyBox from "react-sticky-box";
  import { Col, Row, Typography } from "antd";
  
  function FlightTermsAndConditions(props) {
    const tcGeneral = useRef(null);
    const tc1 = useRef(null);
    const tc2 = useRef(null);
    const tc3 = useRef(null);
    const tc4 = useRef(null);
    const tc5 = useRef(null);
    const tc6 = useRef(null);
    const tc7 = useRef(null);
    const executeScrolltop = () =>
      tcGeneral.current.scrollIntoView({ behavior: "smooth" });
    const executeScrolltop1 = () =>
      tc1.current.scrollIntoView({ behavior: "smooth" });
    const executeScrolltop2 = () =>
      tc2.current.scrollIntoView({ behavior: "smooth" });
    const executeScrolltop3 = () =>
      tc3.current.scrollIntoView({ behavior: "smooth" });
    const executeScrolltop4 = () =>
      tc4.current.scrollIntoView({ behavior: "smooth" });
    const executeScrolltop5 = () =>
      tc5.current.scrollIntoView({ behavior: "smooth" });
    const executeScrolltop6 = () =>
      tc6.current.scrollIntoView({ behavior: "smooth" });
    const executeScrolltop7 = () =>
      tc7.current.scrollIntoView({ behavior: "smooth" });
    const general = (e) => {
      executeScrolltop();
    };
    const general1 = (e) => {
      executeScrolltop1();
    };
    const general2 = (e) => {
      executeScrolltop2();
    };
    const general3 = (e) => {
      executeScrolltop3();
    };
    const general4 = (e) => {
      executeScrolltop4();
    };
    const general5 = (e) => {
      executeScrolltop5();
    };
    const general6 = (e) => {
      executeScrolltop6();
    };
    const general7 = (e) => {
      executeScrolltop7();
    };
    useEffect(() => {
      window.scrollTo(0, 0);
    });
  
    const { Title } = Typography;
    const update=(type)=>{
    }
    return (
      <>
        <Header posType="floating" setTab={update} />
        <div className="container tndC">
          <Title level={4} className="tcTitle">
            Flights Terms and Conditions
          </Title>
          <Row>
            <Col span={6} className="tcleftSidebar">
              <StickyBox offsetTop={100} offsetBottom={20}>
                <ul variant="pills" className="flex-column">
                <li>
                  <span eventKey="general" onClick={general} title="شروط شركات الطيران">
                  شروط شركات الطيران
                  </span>
                </li>
                <li>
                  <span eventKey="clientResp" onClick={general1} title="التسعير">
                  التسعير
                  </span>
                </li>
                <li>
                  <span eventKey="disclaimer" onClick={general2} title="وثائق السفر">
                  وثائق السفر
                  </span>
                </li>
                <li>
                  <span eventKey="pvcStatement" onClick={general3} title="شروط تسجيل الوصول">
                  شروط تسجيل الوصول
                  </span>
                </li>
                <li>
                  <span eventKey="copyright" onClick={general4} title="استخدام قطاعات الطيران">
                  استخدام قطاعات الطيران
                  </span>
                </li>
                <li>
                  <span eventKey="agency" onClick={general5} title="التغييرات على حجز قائم">
                  التغييرات على حجز قائم
                  </span>
                </li>
                <li>
                  <span eventKey="confidentiality" onClick={general6} title="الاسترداد">
                  الاسترداد
                  </span>
                </li>
                <li>
                  <span eventKey="indemnity" onClick={general7} title=" أخرى">
                  أخرى
                  </span>
                </li>
                </ul>
              </StickyBox>
            </Col>
            <Col span={18} className="tcLayout">
            <div ref={tcGeneral}>
              <ShowHide visible={false} icon="" title="شروط شركات الطيران">
                <div className="showHide-content">
                  <p>
                 . تخضع تذاكر الطيران المتاحة من خلال التطبيق لشروط وأحكام الشركة الطيران المعنية، بما في ذلك ولكن لا يقتصر على سياسات الإلغاء والاسترداد
                  </p>
                  <p>
                 . مجرد وسيط لتمكين المستخدم من حجز تذكرة طيران. يكون عقد الخدمة لاستخدام الرحلة دائمًا بين المستخدم والشركة الطيران المعنية Clickntravel تعمل
                  </p>
                  <p>
                 .أي تحكم أو سلطة على لوجستيات الشركات الطيران وبالتالي لا تتحمل أي مسؤولية عن أي خسارة، سواء كانت مباشرة أو غير مباشرة، يمكن أن يتعرض لها المستخدم نتيجة لمثل هذا التغيير أو إلغاء الرحلة Clickntravel كوسيط، لا يمتلك .Clickntravel تحتفظ الشركات الطيران بالحق في إعادة جدولة أوقات الرحلة، أو التغيير أو إلغاء الرحلات أو التواريخ المتعلقة بدون إشعار مسبق لـ
                  </p>
                  <p>
                 .ليست لها أي دور في هذا الصدد. يتم استثناء بعض الأسعار المعروضة في التطبيق من "حقيبة اليد" والتي لا تؤهل المستخدم للحصول على حقيبة تسجيل مجانية، وبالتالي سيكون على المستخدم دفع رسوم إضافية للحقيبة تسجيل. يمكن أن تختلف أسعار إضافة حقيبة تسجيل إلى الحجز من شركة طيران إلى أخرى. يُنصح بالتواصل مع شركات الطيران للحصول على تفاصيل التكاليف بدقة  Clickntravel  حقيبة اليد على الفارغة الخاصة بالأجرة المحددة تعتمد على الشروط التي قررتها الشركة الطيران، و 
                  </p>
                </div>
              </ShowHide>
            </div>
            <div ref={tc1}>
              <ShowHide visible="true" icon="" title="التسعير">
                <div className="showHide-content">
                  <p>
                 .بحقه في إلغاء الحجز. يتعهد المستخدم بدفع جميع الضرائب والرسوم والتعريفات، حسب تاريخ السفر  Clickntravel السعر الإجمالي الذي يتم عرضه في التطبيق على صفحة الدفع عادة يشمل الرسوم الأساسية والضرائب الحكومية السارية ورسوم الراحة. يُطلب من المستخدمين دفع المبلغ الكامل قبل تأكيد حجزهم. في حال عدم دفع المستخدم للمبلغ الكامل، يحتفظ
                   
                  </p>

                  <p>
                 .للاستفادة من أسعار الرضع، يجب أن يكون عمر الطفل دون 24 شهرًا طوال جدول الرحلة بأكمله. ويشمل ذلك الرحلات الذهاب والعودة. إذا كان الرضيع عمره 24 شهرًا أو أكثر في رحلة العودة، سيكون على المستخدم أن يقوم بحجز منفصل باستخدام تذكرة طفل. يجب أن يكون أي رضيع أو طفل مصحوباً بكبار السن وفقاً لشروط شركات الطيران
                  </p>
                </div>
              </ShowHide>
            </div>
            <div ref={tc2}>
              <ShowHide visible="true" icon="" title="وثائق السفر">
                <div className="showHide-content">
                  <p>
                 .ليست مسؤولة بأي شكل من الأشكال  Clickntravel إلخ. للقيام بالسفر. يتفق المستخدم على أنه في حالة عدم القدرة على السفر بسبب عدم حمل وثائق السفر الصالحة أن (بما في ذلك التأشيرة العابرة) تقع مسؤولية حمل وثائق السفر الصالحة على المستخدم كلياً، مثل إثبات الهوية، جواز السفر، التأشيرة
                  </p>

                  <p>
                 .بخصوص وثائق السفر هي طابع استشاري فقط ولا يمكن اعتبارها نهائية. يجب على المستخدم التحقق من متطلبات وثائق السفر مع شركات الطيران المعنية في السلطات المعنية التي قد يمر بها المستخدم أو قد يختار زيارتها Clickntravel التي تقدمها (إذا كانت متاحة) يفهم المستخدم أن المعلومات
                  </p>
                </div>
              </ShowHide>
            </div>
            <div ref={tc3}>
              <ShowHide visible="true" icon="" title="شروط تسجيل الوصول">
                <div className="showHide-content">
                  <p>
                 .يجب على المستخدم التحقق مباشرة من شركات الطيران بخصوص أوقات تسجيل الوصول. عادةً ما يبدأ تسجيل الوصول قبل 3 ساعات من موعد الرحيل للرحلات الدولية
                  </p>
                  <p>
                .السلبي وكل مل قد يكون مطلوباً لإثبات هوية وجنسية وجاهزية الركاب الذين يسافرون على تذكرة، بما في ذلك الرضع RT-PCR يجب على المستخدم حمل إثبات الهوية الصالح، وجواز السفر، وشهادة التطعيم، وتقرير
                  </p>
                </div>
              </ShowHide>
            </div>
            <div ref={tc4}>
              <ShowHide visible="true" icon="" title="استخدام قطاعات الطيران">
                <div className="showHide-content">
                  <p>
                .أي تحكم في هذه العملية ولن تكون ملزمة بتوفير حجوزات بديلة للمستخدم. ستكون الغرامات المتعلقة بالإلغاء وعدم الحضور في مثل هذه الحالة وفقًا لقوانين الطيران المعمول بها Clickntravel المتعلق بهذا الحجز تلقائيًا من قبل شركة الطيران. في مثل هذه الحالة، ليس لدى PNR في حالة عدم انطلاق المستخدم في الرحلة الصادرة، سيتم إلغاء مجموع
                  </p>
                </div>
              </ShowHide>
            </div>
            <div ref={tc5}>
              <ShowHide
                visible="true"
                icon=""
                title="التغييرات على حجز قائم"
              >
                <div className="showHide-content">
                  <p>
                     .Clickntravel أي تغييرات تتم على أي حجز قائم ستكون خاضعة لرسوم محددة تفرضها شركة الطيران المعنية، بخلاف رسوم الخدمة التي تفرضها
                  </p>
                  <p>
                 .يجب على المستخدم أن يلتزم بدفع الرسوم المطبقة في حالة أي تعديل أو تغيير على حجز قائم. ومع ذلك، قد تختلف رسوم التغيير أو التعديل على الحجوزات القائمة حسب سياسة الشركة الجوية وفئة الأسعار
                  </p>
                </div>
              </ShowHide>
            </div>
            <div ref={tc6}>
              <ShowHide visible="true" icon="" title="الاسترداد">
                <div className="showHide-content">
                  <p>
                 .عند الحجز هي رسوم غير قابلة للاسترداد Clickntravel لنفس المبلغ من شركات الطيران. ومع ذلك، فإن رسوم الراحة التي دُفعت إلى  Clickntravel سيتم معالجة عمليات الاسترداد وفقاً لقواعد أسعار الشركة الجوية وسياسة الإلغاء. يخضع هذا الاسترداد لاستلام
                  </p>
                  <p>
                   {" "}. وهي مستقلة عن رسوم الراحة المذكورة أعلاه Clickntravel من أجل بدء عملية الاسترداد. قد تختلف فترة معالجة الاسترداد حسب وسيلة الدفع والإصدار من قبل البنك الشريك. سيتم معالجة الاسترداد بعد خصم رسوم خدمة ,Clickntravel يجب أن تتم جميع عمليات الإلغاء المُجراة مباشرة مع الشركة الجوية بإبلاغ
                  </p>
                  <p>
                 .بتحويل المال إلى نفس بطاقة الخصم Clickntravel بإجراء عملية عكس الدفع إلى نفس بطاقة الائتمان. وبالمثل، إذا استخدم المستخدم بطاقة ائتمان، سيقوم Clickntravel  سيتم تحويل المبلغ المسترد إلى نفس الحساب الذي تم من خلاله الدفع. على سبيل المثال، إذا استخدم المستخدم بطاقة ائتمان، سيقوم
                  </p>
                  <p>
                 .في حالة إلغاء واسترداد تذاكر مستخدمة جزئياً، سيتم خصم اي مبلغ تخفيض وخصم رمز الترويج الذي تم الاستفادة منه أثناء الحجز من المبلغ المسترد
                  </p>
                </div>
              </ShowHide>
            </div>
            <div ref={tc7}>
              <ShowHide visible="true" icon="" title=" أخرى">
                <div className="showHide-content">
                  <p>
                 .على عاتقها أي مسؤولية عن أي ضرر أو خسارة أو إصابة أو حادث أو وفاة أو عدم انتظام أو تأخير/تغيير في الجدول أو إلغاء بدون سبب أو معلومات غير دقيقة أو نقص في الخدمة/المنتج أو إلغاء بسبب ظروف خارجة عن سيطرتها Clickntravel لا تتحمل
                  </p>
                </div>
              </ShowHide>
            </div>
                
            </Col>
          </Row>
        </div>
        <Footer />
      </>
    );
  }
  
  export default FlightTermsAndConditions;
  