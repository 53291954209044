import React,{useEffect, useState} from "react";
import { Typography, Tooltip } from "antd";
import { useContext } from "react";
import { Context } from "../../../../App";
import Utilities from "../../../utilities/Utilities";
const { Title } = Typography;

function PassengersDetails(props) {
  const [enableParentCls,setEnableParentCls] = useState(true)
  const [context, setContext] = useContext(Context)
  useEffect(() => {
    if(props.sup!=="0"){
      setEnableParentCls(false);
    }
  }, [props.sup]);

  return (
    <>
      <Title level={3} className="heading">
       {context.translation && context.translation.travellerDetail}
      </Title>
      <div className="tableScroll">
        <div className="tableHeader onlyFlex">
          <div style={{width:"25%"}} className="tableValue">
            {context.translation && context.translation.traveller}
          </div>
          <div style={{width:"20%"}} className="tableValue">
            {context.translation && context.translation.ticketNo}
          </div>
          {props.sup==="0" &&
          <>
          <div style={{width:"30%"}} className="tableValue">
          {context.translation && context.translation.specialReq}
          </div>
          <div style={{width:"25%"}} className="tableValue">
          {context.translation && context.translation.ff}  #
          </div>
          </>
          }
          {props.sup!=="0" &&
          <>
          <div style={{width:"20%"}} className="tableValue">
          {context.translation && context.translation.bagg}
          </div>
          <div style={{width:"25%"}} className="tableValue">
          {context.translation && context.translation.meal}
          </div>
          <div style={{width:"10%"}} className="tableValue">
          {context.translation && context.translation.seat}
          </div>
          </>
          }
        </div>
        {props.response && props.response.psngrs.map((pax, index) => (
        <React.Fragment key={index}>
        <div className="tableData alignPaxDetails onlyFlex">
          <div style={{width:"25%"}} className="tableValue">
          {pax.tl} {pax.fn} {pax.ln} ({pax.ty})
          </div>
          
          {pax.tkt!==undefined && pax.tkt!=="" &&
          <div style={{width:"20%"}} className="tableValue">
          {pax.tkt}
          </div>
          }
          {(pax.tkt===undefined || pax.tkt==="") && 
          <div style={{width:"20%"}} className="tableValue">
          {context.translation && context.translation.na}
          </div>
          }
          {props.sup==="0" && pax.opref!=undefined &&
          <div style={{width:"30%"}} className="tableValue">
            {pax.opref.sa || pax.opref.sp || pax.opref.mp ?
              <>
                {/* {pax.opref.sa!=""?pax.opref.sa+" / ":""} {pax.opref.sp!==""?pax.opref.sp+" / ":""} {pax.opref.mp!==""?pax.opref.mp:""}  */}
                {pax.opref.sa && pax.opref.sp ==="" && pax.opref.mp ==="" && (
                  <>
                    {pax.opref.sa}
                  </>
                )}
                {pax.opref.sp && pax.opref.sa === "" && pax.opref.mp === "" && (
                  <>
                    {pax.opref.sp}
                  </>
                )}
                {pax.opref.mp && pax.opref.sp === "" && pax.opref.sa === "" && (
                  <>
                    {pax.opref.mp}
                  </>
                )}
                {pax.opref.sa && pax.opref.sp && pax.opref.mp === "" && (
                  <>
                    {pax.opref.sa + " / " + pax.opref.sp}
                  </>
                )}
                {pax.opref.sa && pax.opref.mp && pax.opref.sp === "" && (
                  <>
                    {pax.opref.sa + " / " + pax.opref.mp}
                  </>
                )}
                {pax.opref.sp && pax.opref.mp && pax.opref.sa === "" && (
                  <>
                    {pax.opref.sp + " / " + pax.opref.mp}
                  </>
                )}
                {pax.opref.sa && pax.opref.sp && pax.opref.mp && (
                  <>
                    {pax.opref.sa + " / " + pax.opref.sp + " / " + pax.opref.mp}
                  </>
                )}
              </> : context.translation && context.translation.na
            }
          </div>
          }

          {props.sup==="0" && pax.ffinfo!=undefined &&
          <div style={{width:"25%"}} className="tableValue">
          {pax.ffinfo.ffnum!==""?pax.ffinfo.ffnum:context.translation && context.translation.na}
          </div>
          }
          {props.sup!=="0" &&
          <>
          <div style={{width:"20%"}} className="tableValue">
          {pax.baggage!=undefined && pax.baggage? pax.baggage.label:context.translation && context.translation.na}
          <br/>
          </div>
          <div style={{width:"25%"}} className="tableValue">
          {pax.meal!=undefined && pax.meal? pax.meal.label:context.translation && context.translation.na}
          </div>
          <div style={{width:"10%"}} className="tableValue">
          {pax.seat!==undefined && pax.seat? pax.seat?.label?.split(",").filter((s) => s !== "-").join(","):context.translation && context.translation.na}
      
          </div>
          </>
          }    
        </div>

        {pax.inf!==undefined && pax.inf!=="" && (
        <div className="tableData alignPaxDetails onlyFlex">
          <div style={{width:"25%"}} className="tableValue">
              {pax.inf.tl} {pax.inf.fn} {pax.inf.ln} ({pax.inf.ty})
          </div>
          
          {pax.tkt!==undefined && pax.tkt!=="" &&
          <div style={{width:"20%"}} className="tableValue">
          {pax.inf.tkt!=="" &&
              <>
              {pax.inf.tkt}
              </>
          }
          </div>
          }
            {(pax.tkt===undefined || pax.tkt==="") && 
            <div style={{width:"20%"}} className="tableValue">
                {context.translation && context.translation.na}
            </div>
            }
            {props.sup==="0" && pax.opref!=undefined &&
            <div style={{width:"30%"}} className="tableValue">
              {context.translation && context.translation.na}
            </div>
            }

          {props.sup==="0" && pax.ffinfo!=undefined &&
            <div style={{width:"25%"}} className="tableValue">
              {context.translation && context.translation.na}
            </div>
          }
          {props.sup!=="0" &&
            <>
              <div style={{width:"20%"}} className="tableValue">
                {context.translation && context.translation.na}
              </div>
              <div style={{width:"25%"}} className="tableValue">
                {context.translation && context.translation.na}
              </div>
              <div style={{width:"10%"}} className="tableValue">      
                {context.translation && context.translation.na}
              </div>
            </>
          }     
        </div>

        )}
        </React.Fragment>
        ))}
        </div>
    </>
  );
}

export default PassengersDetails;
