/**
 * @ Author: Srikanth Bandaru
 * @ Create Time: 2023-05-26 22:39:10
 * @ Description: This is a component built for displaying the Flight Fare summary in the results page of one way and round trip
 */

import React, { useEffect, useState,useContext } from "react";
import AirlinesMemo from "../common/airlines/AirlinesMemo";
import { Col, Row } from "antd";
import Link from "antd/es/typography/Link";
// import { isArrayNotEmpty } from "../../../utilities/validators";
import FormatCurrency from "../../../utilities/FormatCurrency";
import { Context } from "../../../../App";
const CDNURL = process.env.REACT_APP_CNT_CDN_URL
const FlightMatrix = (props) => {
  // Example list of flight objects
  const { searchRes, searchRequest, initRes, base_usr_exrate } = props;
  const [context, setContext] = useContext(Context)
  const [totalCount, setTotalCount] = useState();
  const [flightPriceData, setFlightPriceData] = useState([]);
  const [airlineNames, setAirlineNames] = useState();
  const [flightTypes, setFlightTypes] = useState(context.selectLang === "en" || context.selectLang === undefined ? ["Non Stop", "1+ Stops"] : ["مباشر", "1+ توقف"]);

  useEffect(() => {
    if (searchRes) {
      if (searchRes.filter.fare && searchRes.filter.fare.fareSummary) {
        setTotalCount(searchRes.filter.fare.totalCount);
        setFlightPriceData(searchRes.filter.fare.fareSummary);
        setAirlineNames(
          Array.from(
            new Set(
              searchRes.filter.fare.fareSummary.flatMap(
                (flight) => flight.airlines
              )
            )
          )
        );
      }
    }
  }, []);

  // Generate the matrix based on flight data
  const generateMatrix = () => {
    // Step 3: Create and populate the matrix
    const matrix = [];
    flightTypes &&
      flightTypes.forEach((flightType) => {
        const row = [];
        airlineNames &&
          airlineNames.forEach((airline) => {
            const flight = flightPriceData.find((f) =>
              f && f.airlines && airline && f.airlines.includes(airline)
            );
            let price = "";
            if (flightType === "Non Stop"||flightType ==="مباشر") {
              price = flight ? flight.price.nonstop : "";
            } else if (flightType === "1+ Stops"||flightType ==="1+ توقف") {
              price = flight ? flight.price.stop : "";
            }
            //row.push(price?flight.currency+" "+price:"----");
            row.push(price ? flight : "----");
          });
        matrix.push(row);
      });

    // Return the generated matrix
    return matrix;
  };

  // Generate the matrix
  const matrix = generateMatrix();

  const filterSelected = (tid,airlines) => {
    if (tid && airlines) {
      let cloneRes = initRes && Object.assign({}, initRes);
      let res = [];
      cloneRes.origns.forEach((flights) => {
        let filtered = flights.oaairlineList === airlines && flights.srcorigns.filter((src) => {
          return tid.includes(src.tid);
        });
        if (filtered.length > 0) {
          flights.srcorigns = [];
          filtered.map((f) => flights.srcorigns.push(f));
          res.push(flights);
        } else if (flights.destorigins) {
          let filtered = flights.oaairlineList === airlines && flights.destorigins.filter((dest) => {
            return dest.tid === tid;
          });
          if (filtered.length > 0) {
            flights.destorigins = [];
            flights.destorigins.push(filtered);
            cloneRes.origns = [];
            cloneRes.origns.push(flights);
          }
        }
      });
      if (res.length > 0) {
        cloneRes.origns = [];
        res.map((r) => cloneRes.origns.push(r));
      }
      props.handleFilterChanges(cloneRes, "filter");
    }
  };

  return (
    <>
      <Row>
        <Col md={24}>
          <div className="overFlow">
            <table>
              <thead>
                <tr>
                  <th>
                    <Link
                      onClick={(e) => props.handleFilterChanges(e, "clear")}
                      className="clear"
                    >
                      <i className="icon icon-Clear"></i> {context.translation && context.translation.clear}
                    </Link>
                  </th>
                  {airlineNames &&
                    airlineNames.map((airline) => (
                      <>{airline && 
                      <th key={airline}>
                        {!airline.includes("_") && (
                          <><div className="airwaysAirline"><img
                            src={CDNURL+"/airlines/1x/airline-logos/" +
                              airline.split('_')[0] +
                              ".png"}
                            alt="AirLine" /><strong><AirlinesMemo code={airline.split('_')[0]} /></strong></div></>
                        )}
                        {airline.includes("_") &&
                          airline.split("_").map((s, i) => (
                            <div key={i} className="airwaysAirline">
                              <><img
                                src={CDNURL+"/airlines/1x/airline-logos/" +
                                  s +
                                  ".png"}
                                alt="AirLine" /><strong><AirlinesMemo code={s} /></strong></>
                            </div>
                          ))}
                      </th>
                  }</>))}

                </tr>
              </thead>
              <tbody>
                {flightTypes &&
                  flightTypes.map((flightType, rowIndex) => (
                    <tr key={flightType}>
                      <td>{flightType} ({totalCount && (flightType === 'Non Stop'||flightType === 'مباشر')?totalCount.nonstop:totalCount && totalCount.stops})</td>
                      {airlineNames &&
                        airlineNames.map((airline, colIndex) => (
                          <td key={airline}>
                            {matrix[rowIndex][colIndex] &&
                              matrix[rowIndex][colIndex].price ? (
                              <>
                                {flightType === "Non Stop"||flightType ==="مباشر" &&
                                  matrix[rowIndex][colIndex].price.nonstop ? (
                                  <> 
                                        <Link
                                          className="prices"
                                          onClick={() =>
                                            filterSelected(
                                              matrix[rowIndex][colIndex].price
                                                .tid
                                                ? matrix[rowIndex][colIndex]
                                                  .price.tid
                                                : ""
                                              , matrix[rowIndex][colIndex].airlines
                                            )
                                          }
                                        >
                                          <span className="currencyCode">
                                            {searchRequest.cur}{" "}
                                          </span>
                                          <span className="currencyAmount">
                                            <FormatCurrency value={
                                              matrix[rowIndex][colIndex].price
                                                .nonstop * base_usr_exrate
                                            } />
                                          </span>

                                        </Link>
                                        <Link className="moreFlights"
                                          onClick={() =>
                                            filterSelected(
                                              matrix[rowIndex][colIndex].options
                                                ? matrix[rowIndex][colIndex]
                                                  .options
                                                : ""
                                                ,matrix[rowIndex][colIndex].airlines
                                            )
                                          }
                                        >
                                          +{" "}
                                          {matrix[rowIndex][colIndex].options &&
                                            matrix[rowIndex][colIndex].options
                                              .length}{" "}
                                          {context.translation && context.translation.flights}
                                        </Link> 
                                      
                                  </>
                                ) : flightType === "1+ Stops"||flightType ==="1+ توقف" &&
                                  matrix[rowIndex][colIndex].price.stop ? (
                                  <>
                                    {" "} 
                                      
                                        <Link
                                        className="prices"
                                          onClick={() =>
                                            filterSelected(
                                              matrix[rowIndex][colIndex].price
                                                .tid
                                                ? matrix[rowIndex][colIndex]
                                                  .price.tid
                                                : ""
                                                ,matrix[rowIndex][colIndex].airlines
                                            )
                                          }
                                        >
                                          <span className="currencyCode">
                                            {
                                              searchRequest.cur
                                            }{" "}
                                          </span>
                                          <span className="currencyAmount">
                                          <FormatCurrency value={
                                              matrix[rowIndex][colIndex].price
                                                .stop * base_usr_exrate
                                            }/>
                                          </span>
                                        </Link>
                                      
                                      <strong className="d-none">
                                        {
                                          matrix[rowIndex][colIndex].price
                                            .stopcount
                                        }{" "}
                                        {context.translation && context.translation.stops}{" "}
                                      </strong> 
                                     
                                   
                                        <Link className="moreFlights"
                                          onClick={() =>
                                            filterSelected(
                                              matrix[rowIndex][colIndex].options
                                                ? matrix[rowIndex][colIndex]
                                                  .options
                                                : ""
                                                ,matrix[rowIndex][colIndex].airlines
                                            )
                                          }
                                        >
                                          +{" "}
                                          {matrix[rowIndex][colIndex].options &&
                                            matrix[rowIndex][colIndex].options
                                              .length}{" "}
                                          {context.translation && context.translation.flights}
                                        </Link> 
                                  </>
                                ) : (
                                  ""
                                )}
                              </>
                            ) : (
                              "----"
                            )}
                          </td>
                        ))}
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </Col>
        <Col>
          <Link onClick={props.toggle} className="close">
              <i className="icon icon-cancel clearField"></i>
          </Link>
        </Col>
      </Row>
    </>
  );
};

export default FlightMatrix;
