import React from "react";
import { Row, Col } from "antd";
import { useState,useContext } from "react";
import { Context } from "../../../../App";
import { Link } from "react-router-dom";

function HotelServiceAndAmenities(props) {
  const [context, setContext] = useContext(Context)
  const [showAmenities, setShowAmenities] = useState(false);

  return (
    <div className="hotelServiceAndAmenitiesWrapper hrsWrapper">
      <Row gutter={30} justify="space-between">
        {props.amenities !== undefined && (
          <>
            {Object.keys(props.amenities)
              .map((key) => (
                <Col span={8}>
                  <ul className="hotelServiceAndAmenities">
                    <li value={key.name}>
                      {props.amenities[key].name}
                    </li>
                  </ul>
                </Col>
              ))
              .slice(0, showAmenities ? props.amenities.length : "12")}
          </>
        )}
      </Row>
      
        {Object.keys(props.amenities).length >= 13 ? (
          <div className={showAmenities ? "showMore active" : "showMore"}>
          <Link variant="link" onClick={() => setShowAmenities(!showAmenities)}>
            {showAmenities ? (context.translation && context.translation.showless) : (context.translation && context.translation.showMore)}
          </Link>
          </div>
        ) : (
          ""
        )}
      </div>
    
  );
}

export default HotelServiceAndAmenities;
