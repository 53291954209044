import React, {
  useContext,
  useState,
} from "react";
import { Row, Col, Checkbox, Typography, Button } from "antd";
import { Context } from "../../../../App";


const { Title } = Typography;

function InsurancePlans(props) {
  const [showViewPlans, setShowViewPlans] = useState(false);
  const [context, setContext] = useContext(Context)
  return (
    <>
      <div className="container">
        <Title level={5} className="insuranceTitle">
          <i className="icon icon-Insure"></i>
          <span className="heading">{context.translation && context.translation.insurance}</span>
        </Title>
        <div className="insuranceSubWrapper dFlex">
          <div>
            <Checkbox
              value={context.translation && context.translation.travelInsur}
              className="travelInsurance"
            >
              {context.translation && context.translation.travelInsur}
            </Checkbox>
            <span className="currencyCode">(QAR </span>
            <span className="currencyAmount">80) </span>
            {/* <span className="currencyAmount">80.00) </span> */}
            <span className="priceTraveller">{context.translation && context.translation.oneTraveller}</span>
          </div>
          <div>
            <span
              className={"viewPlans " + (showViewPlans ? "active" : "")}
              onClick={() => setShowViewPlans(!showViewPlans)}
            >
              {context.translation && context.translation.viewPlan}
            </span>
          </div>
        </div>
        {showViewPlans && (
          <Row gutter={20} className="marginTop30">
            <Col span="8">
              <div className="insuranceList">
                <div className="recommended">
                  <i className="icon icon-thumb"></i> {context.translation && context.translation.recommended}
                </div>
                <Title level={5} className="flyTurkey">
                  {context.translation && context.translation.flyEuroNturk}
                </Title>
                <ul>
                  <li>
                    <i className="icon icon-tick"></i> {context.translation && context.translation.travelBag}
                  </li>
                  <li>
                    <i className="icon icon-tick"></i> {context.translation && context.translation.travelAcci}
                  </li>
                  <li>
                    <i className="icon icon-tick"></i> {context.translation && context.translation.emergMedical}
                  </li>
                  <li>
                    <i className="icon icon-tick"></i> {context.translation && context.translation.lossPasport}
                  </li>
                  <li>
                    <i className="icon icon-tick"></i> {context.translation && context.translation.covidExten}
                  </li>
                  <li>
                    <i className="icon icon-tick"></i> {context.translation && context.translation.tripCancel}
                  </li>
                  <li>
                    <i className="icon icon-tick"></i> {context.translation && context.translation.travelBag}
                  </li>
                  <li>
                    <i className="icon icon-tick"></i> {context.translation && context.translation.travelAcci}
                  </li>
                  <li>
                    <i className="icon icon-tick"></i> {context.translation && context.translation.emergMedical}
                  </li>
                  <li>
                    <i className="icon icon-tick"></i> {context.translation && context.translation.lossPasport}
                  </li>
                  <li>
                    <i className="icon icon-tick"></i> {context.translation && context.translation.covidExten}
                  </li>
                  <li>
                    <i className="icon icon-tick"></i> {context.translation && context.translation.tripCancel}
                  </li>
                </ul>
                <div className="dFlex selectedArea">
                  <div>
                    <span className="currencyCode">(QAR </span>
                    <span className="currencyAmount">80)</span>
                    {/* <span className="currencyAmount">80.00)</span> */}
                  </div>
                  <div>
                    <Button type="primary" className="selectBtn active">
                      {context.translation && context.translation.selected}
                    </Button>
                  </div>
                </div>
              </div>
            </Col>
            <Col span="8">
              <div className="insuranceList">
                <Title level={5} className="flyTurkey">
                  {context.translation && context.translation.flyEuroNturk}
                </Title>
                <ul>
                  <li>
                    <i className="icon icon-tick"></i> {context.translation && context.translation.travelBag}
                  </li>
                  <li>
                    <i className="icon icon-tick"></i> {context.translation && context.translation.travelAcci}
                  </li>
                  <li>
                    <i className="icon icon-tick"></i> {context.translation && context.translation.emergMedical}
                  </li>
                  <li>
                    <i className="icon icon-tick"></i> {context.translation && context.translation.lossPasport}
                  </li>
                  <li>
                    <i className="icon icon-tick"></i> {context.translation && context.translation.covidExten}
                  </li>
                  <li>
                    <i className="icon icon-tick"></i> {context.translation && context.translation.tripCancel}
                  </li>
                </ul>
                <div className="dFlex selectedArea">
                  <div>
                    <span className="currencyCode">(QAR </span>
                    <span className="currencyAmount">80)</span>
                    {/* <span className="currencyAmount">80.00)</span> */}
                  </div>
                  <div>
                    <Button type="primary" className="selectBtn">
                     {context.translation && context.translation.select}
                    </Button>
                  </div>
                </div>
              </div>
            </Col>
            <Col span="8">
              <div className="insuranceList">
                <Title level={5} className="flyTurkey">
                  {context.translation && context.translation.flyEuroNturk}
                </Title>
                <ul>
                  <li>
                    <i className="icon icon-tick"></i> {context.translation && context.translation.travelBag}
                  </li>
                  <li>
                    <i className="icon icon-tick"></i> {context.translation && context.translation.travelAcci}
                  </li>
                  <li>
                    <i className="icon icon-tick"></i> {context.translation && context.translation.emergMedical}
                  </li>
                  <li>
                    <i className="icon icon-tick"></i> {context.translation && context.translation.lossPasport}
                  </li>
                  <li>
                    <i className="icon icon-tick"></i> {context.translation && context.translation.covidExten}
                  </li>
                  <li>
                    <i className="icon icon-tick"></i> {context.translation && context.translation.tripCancel}
                  </li>
                </ul>
                <div className="dFlex selectedArea">
                  <div>
                    <span className="currencyCode">(QAR </span>
                    <span className="currencyAmount">80)</span>
                    {/* <span className="currencyAmount">80.00)</span> */}
                  </div>
                  <div>
                    <Button type="primary" className="selectBtn">
                     {context.translation && context.translation.select}
                    </Button>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        )}
      </div>
    </>
  );
}

export default InsurancePlans;
