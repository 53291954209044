import React, { useEffect } from 'react'
import { useLocation, useParams } from 'react-router-dom';
import CntCommonService from '../../services/common/CntCommonService';
import { Space, Spin } from 'antd';
import { isNotNull } from '../utilities/validators';
import Utilities from '../utilities/Utilities';

function RedirectByUrl() {
    const location = useLocation();

    useEffect(() => {
      const payload = {
        url_id: location.search.replace('?',''),
      }
      let tokenKey = Utilities.generateAPIToken(payload);
        CntCommonService.fetchLongUrl(payload,tokenKey).then((response) => {
          if (isNotNull(response)) {
            localStorage.setItem('meshkey', response.data.res[0].mesh_key);
            //localStorage.setItem('meshkey', response.data.res[0].mesh_key?response.data.res[0].mesh_key:"1_1#25:15_11:35#1#AI_J9#HYD_DOH#BOM_KWI#2023-10-29T22:30:00_2023-10-31T11:30:00#2023-10-30T00:10:00_2023-10-31T13:00:00#698_118#AI_J9#01:40_undefined");
            window.location.href = response.data.res[0].url;
            window.location.meshkey = response.data.res[0].url;
          }
        }).catch(function (error) {
          console.error("Error:" + error);      
        });
    }, [])
    
  return (
    <div>
      {/* <Space size="middle">
    <Spin size="large" />
  </Space> */}
  </div>
  )
}

export default RedirectByUrl