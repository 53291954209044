import React from "react";
import { useContext } from "react";
import { Context } from "../../../../App";
import { Row, Col, Typography } from "antd";
import FormatCurrency from "../../../utilities/FormatCurrency";
const { Title } = Typography;

function FareDetails(props) {
  const [context, setContext] = useContext(Context);

  const psngrsLength = props.response?.psngrs?.length / 2;
  const removeDupPsngrs = props.response?.psngrs?.slice(0, psngrsLength);

  return (
    <>
      <Title level={3} className="heading">
        {context.translation && context.translation.fareDetails}
      </Title>
      <div className="travellersWrapper">
        <span className="travellersTitle">{context.translation && context.translation.travellers}:</span>
        <span className="travellersDetailWrap">
          {props.response.sty !== "split" ? (
            <>
            {props.response && props.response.psngrs && props.response.psngrs.map((pax, index) => (
              <React.Fragment key={index}>  
                <span className="travellersDetail">
                  <span><i className="icon icon-tick"></i>   {pax.tl} {pax.fn} {pax.ln} ({pax.ty}){" "}</span>
                  {pax.inf!==undefined && pax.inf!=="" &&
                    <span className="infpsngr">
                    <i className="icon icon-tick"></i> {pax.inf.tl} {pax.inf.fn} {pax.inf.ln} ({pax.inf.ty})
                    </span>
                  }
                </span>
              </React.Fragment>
            ))}
          </>
          ) : (
            <>
            {removeDupPsngrs && removeDupPsngrs !== undefined && removeDupPsngrs?.map((pax, index) => (
              <React.Fragment key={index}>  
                <span className="travellersDetail">
                  <span><i className="icon icon-tick"></i>   {pax.tl} {pax.fn} {pax.ln} ({pax.ty}){" "}</span>
                  {pax.inf!==undefined && pax.inf!=="" &&
                    <span className="infpsngr">
                    <i className="icon icon-tick"></i> {pax.inf.tl} {pax.inf.fn} {pax.inf.ln} ({pax.inf.ty})
                    </span>
                  }
                </span>
              </React.Fragment>
            ))}
            </>
          )}
          </span>
      </div>
      <div className="tableScroll displayNone-640">
        <div className="tableWrap">
        <Row className="tableHeader">
          <Col span={4} className="tableValue">
            {context.translation && context.translation.airFare}
          </Col>
          <Col span={4} className="tableValue">
            {context.translation && context.translation.travInsurance}
          </Col>
          <Col span={4} className="tableValue">
            {context.translation && context.translation.serFee}
          </Col>
          <Col span={4} className="tableValue">
            {context.translation && context.translation.discount}
          </Col>
          <Col span={4} className="tableValue">
            {context.translation && context.translation.prodisc}
          </Col>
          <Col span={4} className="tableValue">
            {context.translation && context.translation.tolPay}
          </Col>
        </Row>
        {props.response && props.response.trip_price!==undefined &&
        <Row className="tableData">
          <Col span={4} className="tableValue">
            {props.response.cur}    <FormatCurrency value={props.response.trip_price.air_fare * (props.response.base_usr_exrate ? props.response.base_usr_exrate : 1)} className="currencyAmount"  />
          
          </Col>
          <Col span={4} className="tableValue">
          {props.response.cur} <FormatCurrency value={props.response.trip_price.ins_fare * (props.response.base_usr_exrate ? props.response.base_usr_exrate : 1)} className="currencyAmount"  />
          </Col>
          <Col span={4} className="tableValue">
          {props.response.cur} <FormatCurrency value={props.response.trip_price.service_fee * (props.response.base_usr_exrate ? props.response.base_usr_exrate : 1)} className="currencyAmount"  />
          </Col>
          <Col span={4} className="tableValue active">
          {props.response.cur} <FormatCurrency value={props.response.trip_price.disc * (props.response.base_usr_exrate ? props.response.base_usr_exrate : 1)} className="currencyAmount"  />
          </Col>
          <Col span={4} className="tableValue active">
          {props.response.cur} <FormatCurrency value={props.response.trip_price.promo_disc?props.response.trip_price.promo_disc * props.response.base_usr_exrate:0} className="currencyAmount"  />
          </Col>
          <Col span={4} className="tableValue">
          {props.response.cur} <FormatCurrency value={props.response.trip_price.total_price * (props.response.base_usr_exrate ? props.response.base_usr_exrate : 1)} className="currencyAmount"  />
          </Col>
        </Row>
        }
        </div>
      </div>
      <div className='smallScreenTable displayNoneLG'>
        <Row className="tableHeader">
        {props.response && props.response.trip_price!==undefined &&
        <Col span={24} className="tableData">
          <div className="tableValueWrap">
            <div className="tableValue">
              {context.translation && context.translation.airFare}
            </div>
            <div className="tableValue">
              {props.response.cur}    <FormatCurrency value={props.response.trip_price.air_fare * (props.response.base_usr_exrate ? props.response.base_usr_exrate : 1)} className="currencyAmount"  />
            </div>
          </div>
          <div className="tableValueWrap">
            <div className="tableValue">
              {context.translation && context.translation.travInsurance}
            </div>
            <div className="tableValue">
              {props.response.cur} <FormatCurrency value={props.response.trip_price.ins_fare * (props.response.base_usr_exrate ? props.response.base_usr_exrate : 1)} className="currencyAmount"  />
            </div>
          </div>
          <div className="tableValueWrap">
            <div className="tableValue">
              {context.translation && context.translation.serFee}
            </div>
            <div className="tableValue">
            {props.response.cur} <FormatCurrency value={props.response.trip_price.service_fee * (props.response.base_usr_exrate ? props.response.base_usr_exrate : 1)} className="currencyAmount"  />
            </div>
          </div>
          <div className="tableValueWrap">
            <div className="tableValue">
              {context.translation && context.translation.discount}
            </div>
            <div className="tableValue active">
              {props.response.cur} <FormatCurrency value={props.response.trip_price.disc * (props.response.base_usr_exrate ? props.response.base_usr_exrate : 1)} className="currencyAmount"  />
            </div>
          </div>
          <div className="tableValueWrap">
            <div className="tableValue">
              {context.translation && context.translation.tolPay}
            </div>
            <div className="tableValue">
              {props.response.cur} <FormatCurrency value={props.response.trip_price.total_price * (props.response.base_usr_exrate ? props.response.base_usr_exrate : 1)} className="currencyAmount"  />
            </div>
          </div>
        </Col>
        }
        </Row>
      </div>
    </>
  );
}

export default FareDetails;
