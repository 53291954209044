import React from "react";
import { Context } from "../../../../App";
import { useContext } from "react";
import {
  Col,
  Row,
  Typography,
  Carousel,
} from "antd";
import Utilities from "../../../utilities/Utilities";
const { Title } = Typography;

const contentStyle = {
  margin: 0,
  height: "178px",
  width: "242px",
  color: "#fff",
  lineHeight: "160px",
  textAlign: "center",
  background: "#364d79",
  borderRadius: "4px",
};

function HotelBookingDetails(props) {
  const [context, setContext] = useContext(Context)
  const htlImg = props.htldtls?.htimg?.replace("https://i.travelapi.com", "https://cdn-hotel.mncdn.com")
  return (
    <>
      <Row>
        <Col span={24}>
          <div className="hotelReviewDetails">
              <div className="sliderWrapper">
                <span className="hotelTag">{props.htldtls.category.name}</span>
                  <div className="Carousel">
                    <img
                      src={htlImg ? htlImg : props.htldtls.htimg}
                      style={contentStyle}
                    />
                  </div>
              </div>
              <div className="fullWidth">
                  <div>
                    <div>
                      <Title level={4} className="hotelName">
                        {props.htldtls.htnm}
                      </Title>
                      <span className="stars">
                        {Utilities.getHotelStartRating(props.htldtls.rating).map((star, idx) => (
                        <React.Fragment key={idx}>
                        <span className="hotelRating">
                        <i className="icon icon-star"></i>
                        </span>
                        </React.Fragment>
                        ))}
                        </span>
                    </div>
                    <Title level={5} className="hotelAddress">
                      {props.htldtls.add}, {props.htldtls.city}, {props.htldtls.country}
                    </Title>
                    </div>
                    <div className="onlyFlex">
                    <div className="checkInBorderRight">
                      <span className="checkInOut">{context.translation && context.translation.checkin}</span>
                      <span className="dateTime">{props.htldtls.cin}</span>
                    </div>
                    <div>
                      <span className="checkInOut">{context.translation && context.translation.checkOut}</span>
                      <span className="dateTime">{props.htldtls.cout}</span>
                    </div>
                  </div>
              </div>
          </div>
        </Col>
      </Row>
    </>
  )
}

export default HotelBookingDetails