/**
 * @ Author: Srikanth Bandaru
 * @ Create Time: 2023-06-12 09:05:48
 * @ Description: Component for Flight Details that will be used in the search results page flight details pop up
 */

import React, { useEffect } from "react";
// import AirlinesMemo from "../common/airlines/AirlinesMemo";
// import AirportMemo from "../common/airlines/AirportNames";
// import DateUtils from "../../../utilities/DateUtils";
// import ResultSectionHeader from "./ResultSectionHeader";
import FlightDetailsContent from "./FlightDetailsContent";

function FlightDetails(props) {
  const {
    mapping,
    sectors,
    secType,
    secTypeIndex,
    trip,
    flt,
    seg,
    rtnflt,
    rtnseg,
    refund,
    selected,
    flow,
    regionType,
    req
  } = props;


  return (
    <div className="tabContent">
    {flow && flow === 'SRP' && <>
      <FlightDetailsContent
        mapping={mapping}
        sectors={sectors}
        secType={secType}
        secTypeIndex={secTypeIndex}
        trip={trip}
        flt={flt}
        seg={seg}
        refund={refund}
        req={req}
        regionType={regionType}
      />
      {rtnflt && (
        <FlightDetailsContent
          mapping={mapping}
          sectors={sectors}
          secType={"arrival"}
          secTypeIndex={secTypeIndex}
          trip={trip}
          flt={rtnflt}
          seg={rtnseg}
          refund={refund}
          req={req}
          regionType={regionType}
        />
      )}</>}
      {flow && flow === 'SRPMULTI' && selected && 
      <>
        {selected.map((sel,index) =>  sel.seg && (
          <FlightDetailsContent
            mapping={mapping}
            sectors={sectors}
            secType={secType}
            secTypeIndex={parseInt(trip)!==3?index:index}
            trip={trip}
            flt={sel}
            seg={sel.seg[0]}
            refund={sel ? sel.re : undefined}
            flow={'SRPMULTI'}
            req={req}
            regionType={regionType}
          />
        ))}
      </>
      }
      {flow && flow === 'REVIEW' && selected && 
      <>
        {selected.map((sel,index) =>  sel.seg && (
          <FlightDetailsContent
          mapping={mapping}
          sectors={sectors}
          secType={index !== 1 ?secType:'arrival'}
          secTypeIndex={parseInt(trip)!==3?index:index-2}
          trip={trip}
          flt={sel}
          seg={sel.seg[0]}
          refund={sel ? sel.re : undefined}
          flow={'REVIEW'}
          req={req}
          regionType={regionType}
        />
        ))}
      </>
      }
      {flow && flow === 'PAYMENT' && selected && 
      <>
        {selected.map((sel,index) =>  sel.seg && (
          <FlightDetailsContent
          mapping={mapping}
          sectors={sectors}
          secType={index !== 1 ?secType:'arrival'}
          secTypeIndex={parseInt(trip)!==3?index:index-2}
          trip={trip}
          flt={sel}
          seg={sel.seg[0]}
          refund={sel ? sel.re : undefined}
          flow={'PAYMENT'}
          req={req}
          regionType={regionType}
        />
        ))}
      </>
      }
    </div>
  );
}

export default FlightDetails;
