import React, { useState,useContext} from "react";
import { Context } from "../../App";
import {
  Typography,
  Collapse,
  Drawer,
} from "antd";
import Link from "antd/es/typography/Link"
import DateUtils from "../utilities/DateUtils";
import FormatCurrency from "../utilities/FormatCurrency";
import { useLocation } from "react-router-dom";
import BrandIcon from "../../assets/gif/Forever/White/ICON_WHITE_FOREVER.gif";
import FlightDetailsPopup from "../bookingflow/flights/results/FlightDetailsPopup";

const { Title } = Typography;
const { Panel } = Collapse;

function FlightPaymentInfo(props) {
  const [showTraveller, setShowTraveller] = useState(true);
  const [context, setContext] = useContext(Context)
  const location = useLocation();
  const [request, setRequest] = useState(props.request);
  const [response, setResponse] = useState(location.state!==null &&
    location.state.resp && location.state.resp !== undefined
      ? location.state.resp
      : ""
  );
  const [sectors, setSectors] = useState(
    location.state?.sect && location.state?.sect !== undefined
      ? location?.state.sect
      : ""
  );
  const [selectedObj, setSelectedObj] = useState(
    location.state?.selected &&
      location.state?.selected !== undefined &&
      location.state?.selected !== ""
      ? location.state?.selected
      : ""
  );
  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <>
            {/* <div className="bookingSummary">
              <Title level={3} className="bookingTitle">
                {context.translation && context.translation.bokSumy}
              </Title>
              {request !== "" && (
                <div className="bookingSubWrapper">
                  <div>
                  ONEWAY AND ROUNDTRIP
                  {Number(request.tt)!==3 &&
                  <div className="rb-top">
                    <div className="info">
                      <span className="smallFlight">
                        <i className="icon icon-flight"></i>
                      </span>
                      <span className="route">{request.src_city}</span>
                      <span className="rightArrow">
                        <i className="icon icon-next-long-arrow-right-svgrepo-com"></i>
                      </span>
                      <span className="route">{request.dest_city}</span>
                    </div>
                    <span className="dateTime"> {DateUtils.prettyDate(request.dept_date, "ddd, DD MMM, YYYY")}</span>
                  </div>
                  }
                   ROUNDTRIP
                  {Number(request.tt)===2 &&
                  <div className="rb-top">
                    <div className="info">
                      <span className="smallFlight">
                        <i className="icon icon-flight"></i>
                      </span>
                      <span className="route">{request.dest_city}</span>
                      <span className="rightArrow">
                        <i className="icon icon-next-long-arrow-right-svgrepo-com"></i>
                      </span>
                      <span className="route">{request.src_city}</span>
                    </div>
                    <span className="dateTime"> {DateUtils.prettyDate(request.arval_date, "ddd, DD MMM, YYYY")}</span>
                  </div>
                   }
                   MULTICITY
                  {request.tt===3 && request.multi_list.length > 0 &&
                  <>
                  {request.multi_list.map((multi, index) => (
                    <React.Fragment key={index}>
                  <div className="rb-top">
                    <div className="info">
                      <span className="smallFlight">
                        <i className="icon icon-flight"></i>
                      </span>
                      <span className="route">{multi.src}</span>
                      <span className="rightArrow">
                        <i className="icon icon-next-long-arrow-right-svgrepo-com"></i>
                      </span>
                      <span className="route">{multi.dest}</span>
                    </div>
                    <span className="dateTime"> {DateUtils.prettyDate(multi.dd, "ddd, DD MMM, YYYY")}</span>
                  </div>
                  </React.Fragment>
                  ))}
                  </>
                  }
                  </div>
                  <div className="innerSumDivider"></div>
                  <Collapse className="travellerDetailWrapper" defaultActiveKey={["1"]}>
                    <Panel
                      header={request.pax_list.length + (context.translation && context.translation.travellers)}
                      key="1"
                    >
                      {request.pax_list.map((pax, index) => (
                        <React.Fragment key={index}>
                          <span className="travellerName">{pax}</span>
                        </React.Fragment>
                      ))}
                    </Panel>
                  </Collapse>
                  
                  <div className="innerSumDivider"></div>
                  <div className="payablePriceWrapper commonFlex">
                    <div>
                        <span className="payablePrice">{context.translation && context.translation.totPayPrice}</span>
                        <span className="allTaxes">{context.translation && context.translation.inclAllTaxNfee}</span>
                    </div>
                    <div>
                        <span className="countryCode">{request.cur}&nbsp;</span>
                        <span className="currencyAmount"><FormatCurrency value={request.total_price} /></span>
                    </div>
                  </div>

                  
                </div>
              )}
            </div> */}

            <div className='outerSummary'>
              <div className="commonFlex bSumWrap">
              <Title level={4} className='summaryMainHeading'>
                  {context.translation && context.translation.bokSumy}
                  <img src={BrandIcon} alt="brandIcon" className="brandIcon"></img>
                  {/* <Link onClick={showDrawer} className="fDetails" style={{float:"right", 
                    color: "#fff", fontSize: "14px", textDecoration: "underline"}}>
                    {context.translation && context.translation.flgtDetls}
                  </Link> */}
                </Title>
                
                  <Link onClick={showDrawer} className="fDetails">
                    {context.translation && context.translation.flgtDetls}
                  </Link>
              </div>
                
                  <Drawer placement="right" open={open} active={"tab1"} className="paxDetailsPopup paymentPagePopup">
                    <i className="icon icon-cancel" onClick={onClose}></i>
                    {selectedObj !== "" && (
                      <FlightDetailsPopup
                        flt={selectedObj.ow}
                        seg={selectedObj.ow ? selectedObj.ow.seg[0] : ""}
                        rtnflt={selectedObj.rt}
                        rtnseg={selectedObj.rt ? selectedObj.rt.seg[0] : ""}
                        sty={request.sty}
                        price={request.pr}
                        currency={request.cur}
                        sectors={sectors}
                        serviceFee={request.servicefee}
                        mapping={request.mappings}
                        secType={"onward"}
                        secTypeIndex={0}
                        trip={request.tt}
                        req={request}
                        paxpr={request.paxpr}
                        regionType={request.region_type}
                        pr={request.pr}
                        sf={request.servicefee}
                        //sf={sectors.sf ? sectors.sf[0].sf : 0}
                        refund={selectedObj.ow ? selectedObj.ow.re : undefined}
                        open={open}
                        //updatePrice={updatePrice}
                        flow={"PAYMENT"}
                        selected={[
                          selectedObj !== "" && selectedObj.ow !== undefined
                            ? selectedObj.ow
                            : "",
                          selectedObj !== "" && selectedObj.rt !== undefined
                            ? selectedObj.rt
                            : "",
                          selectedObj !== "" &&
                          selectedObj.multi !== undefined &&
                          selectedObj.multi.multiSelectOne !== undefined
                            && selectedObj.multi.multiSelectOne,
                          selectedObj !== "" &&
                          selectedObj.multi !== undefined &&
                          selectedObj.multi.multiSelectTwo != undefined
                            && selectedObj.multi.multiSelectTwo,
                          selectedObj !== "" &&
                          selectedObj.multi !== undefined &&
                          selectedObj.multi.multiSelectThree != undefined
                            && selectedObj.multi.multiSelectThree,
                          selectedObj !== "" &&
                          selectedObj.multi !== undefined &&
                          selectedObj.multi.multiSelectFour != undefined
                            && selectedObj.multi.multiSelectFour,
                          selectedObj !== "" &&
                          selectedObj.multi !== undefined &&
                          selectedObj.multi.multiSelectFive != undefined
                            && selectedObj.multi.multiSelectFive,
                          selectedObj !== "" &&
                          selectedObj.multi !== undefined &&
                          selectedObj.multi.multiSelectSix != undefined
                            && selectedObj.multi.multiSelectSix,
                        ]}
                      />
                    )}
                  </Drawer>
                <div className="summaryShape"></div>
                {request !== "" && (
                <div className="innerSummary">
                  {/* ONEWAY AND ROUNDTRIP */}
                  {Number(request.tt)!==3 &&
                  <div className="rb-top">
                    <div className="info">
                      <span className="smallFlight">
                        <i className="icon icon-flight"></i>
                      </span>
                      <span className="route">{request.src_city}</span>
                      <span className="rightArrow">
                        <i className="icon icon-next-long-arrow-right-svgrepo-com"></i>
                      </span>
                      <span className="route">{request.dest_city}</span>
                    </div>
                    <span className="dateTime"> {DateUtils.prettyDate(request.dept_date, "ddd, DD MMM, YYYY")}</span>
                  </div>
                  }
                   {/* ROUNDTRIP */}
                  {Number(request.tt)===2 &&
                  <div className="rb-top">
                    <div className="info">
                      <span className="smallFlight">
                        <i className="icon icon-flight"></i>
                      </span>
                      <span className="route">{request.dest_city}</span>
                      <span className="rightArrow">
                        <i className="icon icon-next-long-arrow-right-svgrepo-com"></i>
                      </span>
                      <span className="route">{request.src_city}</span>
                    </div>
                    <span className="dateTime"> {DateUtils.prettyDate(request.arval_date, "ddd, DD MMM, YYYY")}</span>
                  </div>
                   }
                   {/* MULTICITY */}
                  {request.tt===3 && request.multi_list.length > 0 &&
                  <>
                  {request.multi_list.map((multi, index) => (
                    <React.Fragment key={index}>
                  <div className="rb-top">
                    <div className="info">
                      <span className="smallFlight">
                        <i className="icon icon-flight"></i>
                      </span>
                      <span className="route">{multi.src}</span>
                      <span className="rightArrow">
                        <i className="icon icon-next-long-arrow-right-svgrepo-com"></i>
                      </span>
                      <span className="route">{multi.dest}</span>
                    </div>
                    <span className="dateTime"> {DateUtils.prettyDate(multi.dd, "ddd, DD MMM, YYYY")}</span>
                  </div>
                  </React.Fragment>
                  ))}
                  </>
                  }
                   <div className="innerSumDivider"></div>
                  {/* <Collapse className="travellerDetailWrapper" defaultActiveKey={["1"]}>
                    <Panel
                      header={request.pax_list.length + (context.translation && context.translation.travellers)}
                      key="1"
                    >
                      {request.pax_list.map((pax, index) => (
                        <React.Fragment key={index}>
                          <span className="travellerName">{pax}</span>
                        </React.Fragment>
                      ))}
                    </Panel>
                  </Collapse> */}
                  <div className="paxDetailWrap">
                    <div className="paxDetailSubWrap">
                      <div onClick={() => setShowTraveller(!showTraveller)}
                        className={showTraveller ? "rightArrow active" : "rightArrow"}>
                        <span className="pax">
                          {request.pax_list.length +" "+ (context.translation && context.translation.travellers)}
                        </span>
                      </div>
                    </div>
                  {showTraveller && (
                    <>
                      <div className="paxInnerDetail">
                          <span className="paxDetailList addMoreList">
                          {request.pax_list.map((pax, index) => (
                        <React.Fragment key={index}>
                          <span className="paxDetailListItem">{pax}</span>
                        </React.Fragment>
                      ))}
                          </span>
                      </div>
                    </>
                  )}
                </div>
                  <div className="innerSumDivider"></div>
                  <div className="payablePriceWrapper commonFlex">
                    <div>
                        <span className="payablePrice">{context.translation && context.translation.totPayPrice}</span>
                    </div>
                    <div>
                        <span className="countryCode">{request.cur}&nbsp;</span>
                        <span className="currencyAmount"><FormatCurrency value={request.total_price * request.base_usr_exrate} /></span>
                    </div>
                  </div>
                  <div className="allTaxes">{context.translation && context.translation.inclAllTaxNfee}</div>
                </div>
              )}
            </div>
    </>
  );
}

export default FlightPaymentInfo;
