import React, { useState,useContext } from "react";
import { Context } from "../../App";
import {
    Form,
    Select,
  } from "antd";
import { useEffect } from "react";
let months = require("../bookingflow/flights/common/masterData/Months.json");
let monthsArabic =require("../bookingflow/flights/common/masterData/MonthsArabic.json")

function Month(props) {
  const { Option } = Select;
  const [context, setContext] = useContext(Context)
    const [month,setMonth] = useState("")

    useEffect(() => {
      setMonth(props.updateMonth)
    }, [props.updateMonth]);

    

      const updateMonth=(month)=>{
        setMonth(month)
        props.sendMonth(month)
      }

  return (
    <>
    <Form.Item autoComplete="off" className="pRelative">
      <>                 
        <Select
          name="month"
          value={month?month:undefined}
          defaultValue="Month"
          onChange={(value) => (updateMonth(value))}
          placeholder={context.translation && context.translation.month}
          popupClassName="monthDpDown"
        >
          <Option value="Month">{context.translation && context.translation.month}</Option>
          {context.selectLang==="en"||context.selectLang=== undefined ? 
          <>
            {months.map((month, index) => (
              <Option key={index} value={month.value}>
                {month.label}
              </Option>
            ))}
          </> 
          :
          <>
            {monthsArabic.map((month, index) => (
              <Option key={index} value={month.value}>
                {month.label}
              </Option>
            ))}
          </>
          }
        </Select>
      </>
    </Form.Item>                  
    </>
  );
}
export default Month;
