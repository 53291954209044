import React, { useState, useReducer, useContext, useEffect } from "react";
import { Button, Modal, Row, Col, Typography, Drawer, Alert, Radio } from "antd";
import moment from "moment";
import Footer from "../../../common/Footer";
import Header from "../../../common/Header";
import SecureGuarantee from "../../../homepage/SecureGuarantee";
import TravellerDetails from "./TravellerDetails";
import FlightBookService from "../../../../services/flights/FlightBookService";
import HotelImage from "../../../../assets/img/pupular-hotels/Golden-Tulip-Doha.png";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import {
  fireAvailabilityReq,
  getCheapestPrice,
} from "../common/services/operations";
import { useLocation, useNavigate } from "react-router-dom";
import { Context,ExchangeRates } from "../../../../App";
import {
  characterNumberValidation,
  characterValidation,
  emailValidation,
  mobileNumberValidation,
} from "../../../utilities/FormValidations";
import LoaderN from "../../../common/loaders/LoaderN";
import ResultSectionHeader from "../results/ResultSectionHeader";
import TripDetails from "../results/TripDetails";
import Link from "antd/es/typography/Link";
import FlightDetailsPopup from "../results/FlightDetailsPopup";
import FlightFareDetails from "../results/FlightFareDetails";
import InsurancePlans from "./InsurancePlans";
import FormatCurrency from "../../../utilities/FormatCurrency";
import SeatSelection from "./SeatSelection";
import BrandIcon from "../../../../assets/gif/Forever/White/ICON_WHITE_FOREVER.gif";
import ApplyPromoCode from "../../../common/ApplyPromoCode";
import CntCommonService from "../../../../services/common/CntCommonService";
import Utilities from "../../../utilities/Utilities";
import DateUtils from "../../../utilities/DateUtils";

const FLIGHT_SEARCH_CID = process.env.REACT_APP_FLIGHT_SEARCH_CID;
const BASE_CURRENCY = process.env.REACT_APP_BASE_CUR;
const CID_NAME = process.env.REACT_APP_FLIGHT_SEARCH_CNAME;
//=====================================
// Reducer function for userReact Hook
// which will update the state
//=====================================
const reducer = (state, action) => {
  switch (action.type) {
    default:
      return { ...state, [action.type]: action.payload };
  }
};

/**
 * Initial State Declaration
 */
const initialState = {
  psngers: [],
  prof: "",
  custms: [],
  remarks: [],
  preBkresponse: "",
  enabletimer: false,
  totalprice: 0,
  bdata: "",
  diffamt: 0,
  creditBal: 0,
  travelsList: [],
  traveller: "",
  fareruleResp: [],
  prebookSuc: false,
  isClAvailable: false,
  fareEnable: false,
  isItineraryLoading: true,
  notiMessage: "",
  notiMessageShow: false,
  notiVarient: "",
  showNote: false,
  pcomName: "",
  serviceFee: 0,
  showPop: false,
  fareRule: false,
  prepersist: false,
  bookfail: false,
  showOvercl: false,
  plbDiscount: 0,
  summaryContiueClick: "",
  reissueRes: "",
  olditnararyResp: "",
  enableOldItnarary: false,
  showCustoms: true,
  timer: 900000,
  grandTotal: 0,
  disable: false,
  seatPrice: 0,
  onwardRoute: "",
  onwardRouteSrc: "",
  onwardRouteDest: "",
  returnRoute: "",
  returnRouteSrc: "",
  returnRouteDest: "",
  onwardSupp: "",
  returnSupp: "",
  baggagePrice: 0,
  selMealprice: 0,
  showPriceCheck: false,
  tfFlow: false,
  otherCharge: 0,
  adtBagPrice: 0,
  chdBagPrice: 0,
  adtMealPrice: 0,
  chdMealPrice: 0,
  importTag: "",
  disabledcn: false,
  selSeatsList: [],
  passengerUniqueKey: [],
  departure_date: "",
  disableseat: false,
  inf_dept_date: "",
  check_price: null,
  firstName: "",
  lastName: "",
  pcode: "",
  phoneNum: "",
  email: "",
  title: "",
  document: "",
  indigo_passport: false,
  showssr_popup: false,
  cnfEmail: "",
  gstObj: [],
  tds_obj: {},
  total_gst_val: 0,
  total_tds_val: 0,
  enableFlynas: false,
  isModalOpen: false,
  oldprice:0,
  chnagedPrice:0,
  promo_disc:0,
  promo_code:"",
  promo_remarks:"",
  promo_id:0,
  pax_pdisc:0,
  promoCodeList:[],
  appliedList:[],
  wego_enabled:false,
  wego_failed:false,
  totalAmount: 0,
  wego_click_id:""
};

function FlightReviewPage() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const location = useLocation();
  const navigate = useNavigate();
  const [context, setContext] = useContext(Context);
  const [isLoading, setIsLoading] = useState(false);
  const [isAvaial, setIsAvail] = useState(false);
  const [startTime, setStartTime] = useState("");
  const [showCheapest, setShowCheapest] = useState(false);
  const [cheapestResponse, setChapestResponse] = useState("");
  const [availabilityPop, setAvailabilityPop] = useState(false);
  const [selectedObj, setSelectedObj] = useState(
    location.state!==null && location.state.selected &&
      location.state.selected !== undefined &&
      location.state.selected !== ""
      ? location.state.selected
      : ""
  );
  const [request, setRequest] = useState(
    location.state !== null &&
      location.state.req &&
      location.state.req !== undefined
      ? location.state.req
      : ""
  );
  const [fareruleResp, setFareRuleResp] = useState(
    location.state!==null && location.state.res !== null &&
      location.state.res &&
      location.state.res !== undefined
      ? location.state.res
      : ""
  );
  const [sectors, setSectors] = useState(
    location.state!==null && location.state.sect && location.state.sect !== undefined
      ? location.state.sect
      : ""
  );

  const { Title } = Typography;
  const [open, setOpen] = useState(false);
  const [promoCode, setPromoCode] = useState("");

  useEffect(() => {
    let st = moment(new Date()).add(15, "minutes").format("YYYY-MM-DD HH:mm:ss");
    setStartTime(st);
    window.scrollTo(0, 0)
  }, []);

  useEffect(() => {      
    if(location.state===null || location.state===undefined){
      if(location.pathname!==undefined && location.search!==undefined && location.pathname==="/availability"){
        if(location.search.includes("meta=true") && location.search.includes("meta_type=WEGO")){
          var url=window.location.href;
          //var url="https://dev.clickntravel.com"+location.pathname+location.search;
          loadMetaWegoResp(url);
        }
      }
    }
  }, [location])

  useEffect(() => {      
    let pUkey = [];
    if(fareruleResp.data!==undefined && fareruleResp.data.length > 0 && fareruleResp.data[0] && fareruleResp.data[0].passengerUniqueKey!==undefined) {
      pUkey = fareruleResp.data[0].passengerUniqueKey
    }
    dispatch({ type:"passengerUniqueKey", payload:pUkey}) 
  }, [])

  useEffect(() => {      
    if(context.user_resp !== "" && context.user_resp!==undefined && context.user_resp?.res !== undefined) {
      loadPromoCodes("Register");
    }else{
      loadPromoCodes("Guest");
    }
   }, [context.user_resp])

  useEffect(() => {  
    if(request.origns!==undefined){
    let onward=request.origns[0].srcorigns[0].seg[0].da+"-"+request.origns[0].srcorigns[0].seg.slice(-1)[0].ar;
    let onwardSrc=request.origns[0].srcorigns[0].seg[0].da;
    let onwardDest=request.origns[0].srcorigns[0].seg.slice(-1)[0].ar;
    dispatch({ type: 'onwardRoute', payload: onward })    
    dispatch({ type: 'onwardRouteSrc', payload: onwardSrc })    
    dispatch({ type: 'onwardRouteDest', payload: onwardDest })   
    dispatch({ type: 'onwardSupp', payload: request.origns[0].srcorigns[0].sup })
    dispatch({ type: 'departure_date', payload: request.origns[0].srcorigns[0].seg[0].dd})
    let returnSrc="";
    let returnDest="";
    let returnRoute="";
    if(request.tt==="2" || request.sty === "split"){
      returnRoute=request.origns[0].destorigins[0].seg[0].da+"-"+request.origns[0].destorigins[0].seg.slice(-1)[0].ar;
      returnSrc=request.origns[0].destorigins[0].seg[0].da;
      returnDest=request.origns[0].destorigins[0].seg.slice(-1)[0].ar;
      dispatch({ type: 'returnSupp', payload: request.origns[0].destorigins[0].sup })
      dispatch({ type: 'returnRoute', payload: returnRoute })
      dispatch({ type: 'returnRouteSrc', payload: returnSrc })
      dispatch({ type: 'returnRouteDest', payload: returnDest })
    }
    if((request.origns.length > 0 && request.origns[0].srcorigns[0].sup==="4") || (request.origns.length > 0 && request.origns[0].destorigins!==undefined && request.origns[0].destorigins.length > 0 && request.origns[0].destorigins[0].sup==="4")){
      if(request.src_country!=="IN" && request.dest_country==="IN"){
        dispatch({ type: 'indigo_passport', payload: true })
      }
    }
  }
  }, [request])

  //Loading data For Wego flow
  const loadMetaWegoResp=(url)=>{
    setIsAvail(true)
    try{
      const payload = {"token":url}
      //let tokenKey = Utilities.generateAPIToken(payload);
      CntCommonService.loadMetaResp(payload).then((response) => {
        //dispatch({ type: 'wego_failed', payload: true })
        if(response.status===200 && response.data !== undefined && response.data.length > 0){
          dispatch({ type: 'wego_enabled', payload: true })
          let urlSplitOne = url.split("=");
          let clickIdAray =urlSplitOne[1].split("&");
          dispatch({ type: 'wego_click_id', payload: clickIdAray[0] })
          let respData = response.data[0];
          let req = createFareRulesRequest(respData);
          setRequest(req);
          setSectors(respData.sec);
          //Implemented only For Oneway and Round trip
          if(respData.tt=="1"){
            setSelectedObj({"ow":respData.origns[0].srcorigns[0]})
          }else if(respData.tt=="2"){
            setSelectedObj({"ow":respData.origns[0].srcorigns[0],"rt":respData.origns[0].destorigins[0]})
          }
          fireMetaAvailability(req,response.data[0]);
          }else{
          dispatch({ type: 'wego_failed', payload: true })
          setIsAvail(false);
          }
        });
      }catch (err) {
       console.error("Error in loadMetaWegoResp" + err);
       setIsAvail(false);
      }
  }

  const fireMetaAvailability=(req,resp)=>{
    try{
      let tokenKey = Utilities.generateAPIToken(req);
      fireAvailabilityReq(req,tokenKey).then((response) => {
        if (response !== "" && response !== undefined) {
          if (
            response.fareRuleRes !== undefined &&
            response.fareRuleRes.status === "success"
          ) {
          setFareRuleResp(response.fareRuleRes);
          setIsAvail(false)
          setContext({...context, userCur:resp.cur,regionCur:resp.region==="Qatar"?"QAR":"SAR"})
          window.sessionStorage.setItem("SEL_CUR",resp.cur);
          localStorage.setItem("REGION_COUNTRY", resp.region==="Qatar"?"QA":"SA");
          }else{
            setIsAvail(false)
            setAvailabilityPop(true);
          }
        }
      }).catch(function (error) {
        console.error("Exception in callAvailability : " + error);      
      });
    }catch (err) {
       console.error("Error in loadMetaWegoResp" + err);
      }
    
  }

 
  //Consturting Fare rule request object
  const createFareRulesRequest = (data)=>{
    data.tid=data.sty==="normal"?data.origns[0].srcorigns[0].tid:data.origns[0].srcorigns[0].tid+"/"+data.origns[0].destorigins[0].tid
    data.flowType="WEGO";
    data.paxpr=data.origns[0].paxpr;
    data.pr=data.origns[0].pr;
    data.origns[0].srcorigns[0].pr=data.origns[0].pr;
    data.origns[0].srcorigns[0].paxpr=data.origns[0].paxpr;
    if(Number(data.tt)===2){
      data.origns[0].destorigins[0].pr=data.origns[0].pr;
      data.origns[0].destorigins[0].paxpr=data.origns[0].paxpr;
    }
    data.servicefee=data.origns[0].servicefee;
    data.req_cur=BASE_CURRENCY;
    data.region_cur=data.region==="Qatar"?"QAR":"SAR";
    data.sup_base_exrate=data.origns[0].sup_base_exrate;
    data.dest_sup_base_exrate=data.origns[0].dest_sup_base_exrate!==undefined?data.origns[0].dest_sup_base_exrate:1;
    data.base_sup_exrate=data.origns[0].base_sup_exrate;
    data.dest_base_sup_exrate=data.origns[0].dest_base_sup_exrate!==undefined?data.origns[0].dest_base_sup_exrate:1;
    let currecny=BASE_CURRENCY+" - "+data.cur;
    if(BASE_CURRENCY!==data.cur){
    if(context.ex_rates!==undefined && context.ex_rates!==""){
      if(context.ex_rates.hasOwnProperty(currecny)){
        var ex_rate = context.ex_rates[currecny];
        data.base_usr_exrate=ex_rate;
      }
    }else{
      var exRates = JSON.parse(localStorage.getItem("CNT_EX_RATES"))
      if(exRates!==null && exRates.hasOwnProperty(currecny)){
        var ex_rate = exRates[currecny];
        data.base_usr_exrate=ex_rate;
      }else{
        data.base_usr_exrate=1;
      }
    }
    }else{
        data.base_usr_exrate=1;
    }
    return data;
  }


  //=====================================
  // This functiona will Create the Prebook request Json and Call Pre presistance service
  //=====================================
  const countineBooking = () => {
    for(let pax of state.psngers){
      if(pax.fn!=="" && !pax.fn.includes(" ")){
      let fn=pax.fn;
      pax.fn=fn.trim();
      }else{
       var str =pax.fn;
       let fn = str.replace(/ +/g, " ");
       pax.fn=fn.trim();
      }
      if(pax.ln!=="" && !pax.ln.includes(" ")){
       let ln=pax.ln;
       pax.ln=ln.trim();
       }else{
       var str =pax.ln;
       let ln = str.replace(/ +/g, " ");
       pax.ln=ln.trim();
       }
     if(pax.dob===""){
       pax.dob=null;
       }
      if(pax.doc.isd===""){
        pax.doc.isd=null;
      }
      if(pax.doc.exd===""){
        pax.doc.exd=null;
      }
    if(pax.inf!=="" && pax.inf!==undefined){
     if(pax.inf.fn!=="" && pax.inf.fn!==undefined){
       let fn=pax.inf.fn;
       pax.inf.fn=fn.trim();
       }
       if(pax.inf.ln!=="" && pax.inf.ln!==undefined){
        let ln=pax.inf.ln;
        pax.inf.ln=ln.trim();
        }
      if(pax.inf.dob===""){
      pax.inf.dob=null;
      }
      if(pax.inf.doc!==undefined && pax.inf.doc.isd===""){
      pax.inf.doc.isd=null;
      }
      if(pax.inf.doc!==undefined && pax.inf.doc.exd===""){
      pax.inf.doc.exd=null;
      }
    }
   }
    if (validatePax()){
      setIsAvail(true);
      if(((request.sty==="normal" && state.onwardSupp==="0") || (request.sty==="split" && state.onwardSupp==="0" && state.returnSupp==="0"))){
        let currentTime = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
      if (currentTime > startTime) {
        // 15 minuts crossed
        setIsLoading(true);
        request.region_cur = context.regionCur!==undefined && context.regionCur!==""?context.regionCur:localStorage.getItem("REGION_CUR")
        checkAvailability(request, true);
      } else {
        // below 15 minutes
        setIsAvail(true);
        continuePersistance();
      }
      }else{
        let tid="";
        let type="";
        let supplier="";
        let fareinfo = null
        if(request.sty=="normal"){
          tid=fareruleResp.data[0].tid;
          type="normal";
          supplier=state.onwardSupp;
        }else{
          let tidAry=fareruleResp.data[0].tid.split("/");
          if(state.onwardSupp!=="0" && state.returnSupp!=="0"){
            tid=fareruleResp.data[0].tid;
            type="split";
            supplier=state.onwardSupp+"/"+state.returnSupp;
          }else if(state.onwardSupp!=="0"){
            tid=tidAry[0];
            type="normal";
            supplier=state.onwardSupp;
          }else if(state.returnSupp!=="0"){
            tid=tidAry[1];
            type="normal";
            supplier=state.returnSupp;
          }
        }
        if(fareruleResp.data[0].fareinfo) {
          fareinfo = fareruleResp.data[0].fareinfo
        }
        for(let pax of state.psngers){
          if(pax.baggage!==undefined && pax.baggage.length!==0){
            pax.baggage = pax.baggage.filter(s=> s.label !== "Select")
          }
          if(pax.meals!==undefined && pax.meals.length!==0){
            pax.meals = pax.meals.filter(s=> s.label !== "Select")
          }
        }
        const payload={
            tid: tid,
            sty: type,
            psngrs: state.psngers,
            sup:supplier,
            fareinfo: fareinfo,
            region_type :localStorage.getItem("REGION_COUNTRY") !== null &&
            localStorage.getItem("REGION_COUNTRY") === "IN"
              ? "India"
              : "Qatar"
        }
        setAvailabilityPop(false)
        try {
          let tokenKey = Utilities.generateAPIToken(payload);
          FlightBookService.priceCheckService(payload,tokenKey).then(response => {
            if(response.data!==undefined && response.data.suc){
              if(response.data.data && response.data.data.retail_price) {
              state.check_price = response.data.data.retail_price
              dispatch({ type: 'check_price', payload: state.check_price })
              }
            if(response.data.data !== undefined && response.data.data.isFareChanged){
              let src = request.mappings.filter(
                (obj) => obj.airportCode === request.src_code
              );
              request.src_city =src.length >0?src[0].cityName:"";
              let dest = request.mappings.filter(
                (obj) => obj.airportCode === request.dest_code
              );
              request.dest_city =dest.length >0?dest[0].cityName:"";
              let sfVal=0;
              let bagprice=0;
              let mealprice=0;
              if(request.servicefee!==undefined && request.servicefee.length >0){
                sfVal=Number(request.servicefee[0].complete_sfvalue);
              }
              if(state.adtBagPrice){
                bagprice=Number(state.adtBagPrice) + Number(state.chdBagPrice);
              }
              if(state.adtMealPrice){
                mealprice=Number(state.adtMealPrice) + Number(state.chdMealPrice);
              }
              let totalPrice = Number(request.pr.tf) + Number(sfVal) + Number(bagprice) + Number(mealprice) + Number(state.seatPrice);
              if(request.sty==="normal"){
                dispatch({ type: 'otherCharge', payload: response.data.data.otcharge })
                dispatch({ type: 'chnagedPrice', payload: Number(totalPrice) + Number(response.data.data.otcharge) })
              }else if(request.sty==="split"){
                if(response.data.data.otcharge!==undefined ){
                  if(response.data.data.otcharge?.includes(",")) {
                    let OtAry=response.data.data.otcharge.split("/");
                    let total=Number(OtAry[0])+Number(OtAry[1]);
                    dispatch({ type: 'otherCharge', payload: total })
                    dispatch({ type: 'chnagedPrice', payload: Number(totalPrice) + Number(total) })
                  }
                }
              }
              dispatch({ type: 'oldprice', payload:  totalPrice })
              dispatch({ type: 'showPriceCheck', payload: true })
              setIsLoading(false)
              setIsAvail(false)
            }else{
              continuePersistance();
            }
          }else{
            setAvailabilityPop(true)
          }
          }).catch(function (error) {
            if(error?.response?.data==="Unauthorized"){
              setAvailabilityPop(true)
            }
            console.error("Error :" + error);      
          });     
        } catch (error) {
          console.error("Error: " + error);          
        }
      }
    }
  };

  const continueChapestBooking = () => {
    let req = cheapestResponse;
    let org = [];
    req.office_id = request.office_id;
    req.tkt_user_type =
      context.user_resp !== "" && context.user_resp?.res !== undefined
        ? "Registered"
        : "Guest";
    req.tkt_user_id =
      context.user_resp !== undefined && context.user_resp !== ""
        ? context.user_resp?.res?.id
        : 0;
    req.client_ip = request.client_ip;
    req.journey_type = request.journey_type;
    req.src_code = request.src_code;
    req.dest_code = request.dest_code;
    req.tid = cheapestResponse.origns[0].srcorigns[0].tid;
    req.flowType = request.flowType;
    if (request.tt === "1") {
      org = {
        srcorigns: [cheapestResponse.origns[0].srcorigns[0]],
      };
      req.origns = [org];
    } else if (request.tt === "2") {
      org = {
        srcorigns: [cheapestResponse.origns[0].srcorigns[0]],
        destorigins: [cheapestResponse.origns[0].destorigins[0]],
      };
      req.origns = [org];
    } else {
      org = {
        srcorigns: cheapestResponse.origns[0].srcorigns,
      };
      req.origns = [org];
    }
    req.region_cur = context.regionCur!==undefined && context.regionCur!==""?context.regionCur:localStorage.getItem("REGION_CUR")
    delete req.filter;
    delete req.airports;
    checkAvailability(req, false);
  };

  const continuePersistance = async () => {
    // setIsLoading(true);
    dispatch({ type: "showPriceCheck", payload: false });
    try {
      //UPDATE DOB,ISSUE DATE AND EXP DATE
      for (let pax of state.psngers) {
        if (pax.ty === "ADT" || pax.ty === "CHD") {
          if (pax.dob_year && pax.dob_month && pax.dob_day) {
            pax.dob = `${pax.dob_year}-${pax.dob_month}-${pax.dob_day}`;
          } else {
            pax.dob = "";
          }
          if (pax.doc.isd_year && pax.doc.isd_month && pax.doc.isd_day) {
            pax.doc.isd = `${pax.doc.isd_year}-${pax.doc.isd_month}-${pax.doc.isd_day}`;
          } else {
            pax.doc.isd = "";
          }
          if (pax.doc.exp_year && pax.doc.exp_month && pax.doc.exp_day) {
            pax.doc.exd = `${pax.doc.exp_year}-${pax.doc.exp_month}-${pax.doc.exp_day}`;
          } else {
            pax.doc.exd = "";
          }
        } else {
          pax.inf.dob = `${pax.inf.dob_year}-${pax.inf.dob_month}-${pax.inf.dob_day}`;
          pax.inf.doc.isd = `${pax.inf.doc.isd_year}-${pax.inf.doc.isd_month}-${pax.inf.doc.isd_day}`;
          pax.inf.doc.exd = `${pax.inf.doc.exp_year}-${pax.inf.doc.exp_month}-${pax.inf.doc.exp_day}`;
        }
      }
      let regionCur=context.regionCur!==undefined && context.regionCur!==""?context.regionCur:localStorage.getItem("REGION_CUR");
      let currecny=BASE_CURRENCY+" - "+regionCur;
      let baseToRegionEx=1;
      if (context.ex_rates!==undefined && context.ex_rates.hasOwnProperty(currecny)){
         baseToRegionEx = context.ex_rates[currecny];
      }
      //Converting Total Price
      convertTotalPrice();
      const prebook = {
        "pbreq": {
        tid: fareruleResp.data[0].tid,
        offline: fareruleResp.data[0].offline,
        echo: request.echo,
        cid:  Number(request.cid),
        cname: CID_NAME,
        office_id : request.office_id,
        sty: request.sty,
        psngrs: state.psngers,
        plb:fareruleResp.data[0].plb,
        tds:{},
        service_fee:request.servicefee,
        bktype:"DIRECT",
        otcharge:state.otherCharge,
        utype:
          context.user_resp !== "" && context.user_resp?.res !== undefined
            ? "Registered"
            : "Guest",
        bkby:
          context.user_resp !== undefined && context.user_resp !== ""
            ? context.user_resp?.res?.id
            : 0,
      bkby_name:context.user_resp !== "" && context.user_resp?.res !== undefined
      ? context.user_resp?.res?.usr_name
      : state.psngers[0].fn+" "+state.psngers[0].ln,
        region_type:
          localStorage.getItem("REGION_COUNTRY") !== null &&
          localStorage.getItem("REGION_COUNTRY") === "IN"
            ? "India"
            : "Qatar",
            promo_code:state.promo_code,
            promo_disc:state.promo_disc,
            promo_id:state.promo_id,
            promo_remarks:state.promo_remarks,
            room_pdisc:state.room_pdisc,
            cur:context.userCur!==""&&context.userCur!==undefined?context.userCur:request.cur,
            region_cur:regionCur,
            req_cur:BASE_CURRENCY,
            sup_base_exrate:request.sup_base_exrate,
            dest_sup_base_exrate:request.dest_sup_base_exrate,
            base_sup_exrate:request.base_sup_exrate,
            dest_base_sup_exrate:request.dest_base_sup_exrate,
            base_usr_exrate:request.base_usr_exrate,
            base_region_exrate:baseToRegionEx,
            bct:Number(request.total_price.toFixed(2)),
            click_id:state.wego_click_id,
            source_type:state.wego_enabled && state.wego_click_id?"WEGO":"",
            tfflow:request.sty==="normal" && request.tt == "2"?true:false
        }
      };
      let tokenKey = Utilities.generateAPIToken(prebook);
      FlightBookService.saveBooking(prebook,tokenKey).then((response) => {
    
        if (response.data!== undefined && response.data.res!==undefined && response.data.suc) {
          if (
            response.data.res.pbres.status !== undefined &&
            response.data.res.pbres.status === "inserted"
          ) {
            createPayRequest(response.data.res.pbres.bookingId);
            dispatch({ type: "preBkresponse", payload: response });
            sessionStorage.setItem(
              "BOOKING_REF_NO",
              response.data.res.pbres.bookingId
            );
          } else {
            dispatch({ type: "showPop", payload: true });
            dispatch({ type: "prepersist", payload: true });
            setIsAvail(false)
            setIsLoading(false)
          }
        } else {
          dispatch({ type: "showPop", payload: true });
          dispatch({ type: "prepersist", payload: true });
          setIsAvail(false)
          setIsLoading(false)
        }
      }).catch(function (error) {
        if(error?.response?.data==="Unauthorized"){
          dispatch({ type: "showPop", payload: true });
          dispatch({ type: "prepersist", payload: true });
          setIsAvail(false)
          setIsLoading(false)
        }
        console.error("Error in save Booking+++++++++++++++ :" + error);      
      });
    } catch (err) {
      console.error("Error in save Booking+++++++++++++++ : " + err);
    }
  };

  const convertTotalPrice=()=>{
    request.total_price=request.pr.tf;
    if(request.servicefee!==undefined && request.servicefee.length >0){
      request.total_price=Number(request.total_price) + Number(request.servicefee[0].complete_sfvalue);
    }
    if(state.otherCharge!==0 && state.otherCharge!==undefined){
      request.total_price=Number(request.total_price) + Number(state.otherCharge);
    }
    if(state.adtBagPrice!==0 || state.chdBagPrice!==0){
      request.total_price=Number(request.total_price) + Number(state.adtBagPrice) + Number(state.chdBagPrice);
    }
    if(state.adtMealPrice!==0 || state.chdMealPrice!==0){
      request.total_price=Number(request.total_price) + Number(state.adtMealPrice) + Number(state.chdMealPrice);
    }
    if(state.seatPrice!==0 && state.seatPrice!==undefined){
      request.total_price= Number(request.total_price) + Number(state.seatPrice);
    }
    if(state.promo_disc!==0 && state.promo_code!==""){
      request.total_price= Number(request.total_price) - Number(state.promo_disc);
    }
  }

  const checkAvailability = (req, isDirect) => {
    try {
      let tokenKey = Utilities.generateAPIToken(req);
      fireAvailabilityReq(req,tokenKey).then((response) => {
        if (response !== "" && response !== undefined) {
          if (
            response.fareRuleRes !== undefined &&
            response.fareRuleRes.status === "success"
          ) {
            if (!isDirect) {
              request.pr = cheapestResponse.origns[0].pr;
              request.paxpr = cheapestResponse.origns[0].paxpr;
              request.mappings = cheapestResponse.mappings;
              request.origns = cheapestResponse.origns;
            }
            continuePersistance();
          } else {
            if (isDirect) {
              checkCheapestPrice();
            } else {
              setIsAvail(false);
              setIsLoading(false);
              setShowCheapest(false);
              setAvailabilityPop(true);
              sessionStorage.removeItem("cnt_search");
            }
          }
        }
      }).catch(function (error) {
        console.error("Exception in callAvailability : " + error);      
      });
    } catch (error) {
      console.error("Exception in callAvailability : " + error);
    }
  };

  const checkCheapestPrice = () => {
    setIsAvail(true);
    let req = sessionStorage.getItem("cnt_search");
    try {
      let tokenKey = Utilities.generateAPIToken(JSON.parse(req));
      getCheapestPrice(JSON.parse(req),tokenKey).then((response) => {
        if (response !== "" && response !== undefined) {
          if (response.status === "success" && response.data !== undefined) {
            setShowCheapest(true);
            setChapestResponse(response.data[0]);
            let st = moment(new Date())
              .add(15, "minutes")
              .format("YYYY-MM-DD HH:mm:ss");
            setStartTime(st);
            setIsAvail(false);
          } else {
            setAvailabilityPop(true);
          }
        }
      }).catch(function (error) {
        console.error("Error : " + error);      
      });
    } catch (error) {
      console.error("Error: " + error);        
    }
  };

  const createPayRequest = (bookingId) => {
    request.bookingId = bookingId;
    request.plb = fareruleResp.data[0].plb;
    request.user_type =
      context.user_resp !== "" && context.user_resp?.res !== undefined
        ? "Registered"
        : "Guest";
    request.user_id =
      context.user_resp !== undefined && context.user_resp !== ""
        ? context.user_resp?.res?.id
        : 0;
    request.sup=request.sty==="normal"?request.origns.length !== 0 && request.origns[0].srcorigns.length!==0 && request.origns[0].srcorigns[0].sup:request.origns[0].srcorigns.length!==0 && request.origns[0].destorigins.length!==0 && request.origns[0].srcorigns[0].sup+"/"+request.origns[0].destorigins[0].sup;
    if(request.sty==="split"){
      request.tid=request.origns[0].srcorigns[0].tid+"/"+request.origns[0].destorigins[0].tid;
    }
    request.region_type =
      localStorage.getItem("REGION_COUNTRY") !== null &&
      localStorage.getItem("REGION_COUNTRY") === "IN"
        ? "India"
        : "Qatar";
    request.dept_date = request.origns[0].srcorigns[0].seg[0].dd;
    if(request.tt == "1"){
      request.arval_date =
        request.origns[0].srcorigns[0].seg[
          request.origns[0].srcorigns.length - 1
        ].ad;
        let src = request.mappings.filter(
          (obj) => obj.airportCode === request.src_code
        );
        request.src_city =src.length >0?src[0].cityName:"";
        let dest = request.mappings.filter(
          (obj) => obj.airportCode === request.dest_code
        );
        request.dest_city =dest.length >0?dest[0].cityName:"";
    }else if(request.tt == "2"){
      let src = request.mappings.filter(
        (obj) => obj.airportCode === request.src_code
      );
      request.src_city =src.length >0?src[0].cityName:"";
      let dest = request.mappings.filter(
        (obj) => obj.airportCode === request.dest_code
      );
      request.dest_city =dest.length >0?dest[0].cityName:"";
      request.arval_date =
        request.origns[0].destorigins[0].seg[
          request.origns[0].destorigins.length - 1
        ].ad;
      }else{
        let multiList=[];
        for(let val of request.origns[0].srcorigns){
          let src = request.mappings.filter(
            (obj) => obj.airportCode === val.seg[0].da
          );
          let dest;
          for(let seg of val.seg){ 
            dest = request.mappings.filter(
              (obj) => obj.airportCode === seg.ar
            );
          }
           const req={
            "src":src.length >0?src[0].cityName:"",
            "dest":dest.length >0?dest[0].cityName:"",
            "dd":val.seg[0].dd
           }
           multiList.push(req);
        }
        request.multi_list=multiList;
    }
    let list = [];
    for (let val of state.psngers) {
      if (val.ty === "ADT" || val.ty === "CHD") {
        list.push(val.fn + " " + val.ln + " (" + val.ty + ")");
        if (val.inf !== "") {
          list.push(val.inf.fn + " " + val.inf.ln + " (INF)");
        }
      }
    }
    request.pax_list = list;
    request.otCharge=state.check_price;
    request.product="Flight";
    delete request.origns;
    // delete request.mappings;
    delete request.psngrs;
    //Adding Promo Code details
    request.promo_code=state.promo_code;
    request.promo_disc=state.promo_disc;
    request.promo_id=state.promo_id;
    request.promo_remarks=state.promo_remarks;
    request.pax_pdisc=state.pax_pdisc;
    request.region_cur =context.regionCur!==undefined && context.regionCur!==""?context.regionCur:localStorage.getItem("REGION_CUR");
    request.click_id=state.wego_click_id;
    request.source_type=state.wego_enabled && state.wego_click_id?"WEGO":""
    sessionStorage.removeItem("cnt_search");
    navigate("/payment", {
      state: {
        sect: sectors,
        selected: selectedObj,
        req: request,
      },
    });
  };
  const validatePax = (check) => {
    var count = 1;
    let pType = {
      ADT: "Adult",
      INF: "Infant",
      CHD: "Child",
    };
    if (state.psngers && state.psngers.length !== 0) {
      for (let val of state.psngers) {
        if (val.dob_year && val.dob_month && val.dob_day) {
          val.dob = `${val.dob_year}-${val.dob_month}-${val.dob_day}`;
        }
        if (val.doc.isd_year && val.doc.isd_month && val.doc.isd_day) {
          val.doc.isd = `${val.doc.isd_year}-${val.doc.isd_month}-${val.doc.isd_day}`;
        }
        if (val.doc.exp_year && val.doc.exp_month && val.doc.exp_day) {
          val.doc.exd = `${val.doc.exp_year}-${val.doc.exp_month}-${val.doc.exp_day}`;
        }
        if (val.inf.dob_year && val.inf.dob_month && val.inf.dob_day) {
          val.inf.dob = `${val.inf.dob_year}-${val.inf.dob_month}-${val.inf.dob_day}`;
          val.inf.doc.isd = `${val.inf.doc.isd_year}-${val.inf.doc.isd_month}-${val.inf.doc.isd_day}`;
          val.inf.doc.exd = `${val.inf.doc.exp_year}-${val.inf.doc.exp_month}-${val.inf.doc.exp_day}`;
        }

        if (val.tl === "") {
          return validateTitle(val.fesno, pType[val.ty]);
        }
        if (state.onwardSupp !== "0") {
          if (val.fn === "") {
            return validateName(false, "first name", val.fesno, pType[val.ty]);
          } else {
            if (!characterValidation(val.fn)) {
              return validateName(true, "first name", val.fesno, pType[val.ty]);
            }
          }
        }
        if (val.ln === "") {
          return validateName(false, "last name", val.fesno, pType[val.ty]);
        } else {
          if (!characterValidation(val.ln)) {
            return validateName(true, "last name", val.fesno, pType[val.ty]);
          }
        }
        // if(val.dob === null) {
        //   dispatch({ type: "notiMessageShow", payload: true });
        //   dispatch({
        //     type: "notiMessage",
        //     payload:
        //       (context.translation && context.translation.plzseleDate) +
        //       pType[val.ty] +
        //       "" +
        //       val.fesno,
        //   });
        //   dispatch({ type: "notiVarient", payload: "error" });
        //   hidemessage();
        //   return false;
        // }

        // if(val.inf.dob !== null) {
        //   // return ValidateInfDob(val.inf.dob, val.fesno)

        //   // "year-month-day" (e.g., "2021-04-3")
        //   const DobString = val.inf.dob;
        //   const Dob = new Date(DobString);

        //   // Get the current date
        //   const currentDate = new Date();
        //   // Check if the date of birth is a future date
        //   if (Dob > currentDate) {
        //     dispatch({ type: "notiMessageShow", payload: true });
        //     dispatch({
        //       type: "notiMessage",
        //       payload:
        //         (context.translation && context.translation.docannot) + " " + count,
        //     });
        //     dispatch({ type: "notiVarient", payload: "error" });
        //     hidemessage();
        //     return false
        //   }

        //   // Calculate the difference between the current date and the date of birth in milliseconds
        //   const differenceInMs = currentDate - Dob;

        //   // Calculate the difference in years
        //   const differenceInYears = differenceInMs / (1000 * 60 * 60 * 24 * 365);

        //   // Check if the date of birth is greater than 2 years from the current date
        //   if (differenceInYears > 2) {
        //     dispatch({ type: "notiMessageShow", payload: true });
        //     dispatch({
        //       type: "notiMessage",
        //       payload:
        //         (context.translation && context.translation.datelestwoinf) + " " + count,
        //     });
        //     dispatch({ type: "notiVarient", payload: "error" });
        //     hidemessage();
        //     return false;
        //   }
        // }

        // }

        if (
          val.ty === "ADT" &&
          (val.dob === null || val.dob === "") &&
          (fareruleResp.data[0].doc === "yes" ||
            state.onwardSupp === "3" ||
            state.returnSupp === "3" ||
            state.onwardSupp === "5" ||
            state.returnSupp === "5" ||
            state.onwardSupp === "6" ||
            state.returnSupp === "6")
        ) {
          dispatch({ type: "notiMessageShow", payload: true });
          dispatch({
            type: "notiMessage",
            payload:
              (context.translation && context.translation.plzseleDate) +
              pType[val.ty] +
              "" +
              val.fesno,
          });
          dispatch({ type: "notiVarient", payload: "error" });
          hidemessage();
          return false;
        } else if (
          val.ty === "ADT" &&
          (val.dob === null || val.dob === "") &&
          (state.onwardSupp === "4" || state.returnSupp === "4")
        ) {
          dispatch({ type: "notiMessageShow", payload: true });
          dispatch({
            type: "notiMessage",
            payload:
              (context.translation && context.translation.plzseleDate) +
              pType[val.ty] +
              "" +
              val.fesno,
          });
          dispatch({ type: "notiVarient", payload: "error" });
          hidemessage();
          return false;
        }

        // validation date of birth for adult
        if (
          val.ty === "ADT" &&
          (val.dob !== null || val.dob !== "") &&
          (fareruleResp.data[0].doc === "yes" ||
            state.onwardSupp === "3" ||
            state.returnSupp === "3" ||
            state.onwardSupp === "5" ||
            state.returnSupp === "5" ||
            state.onwardSupp === "6" ||
            state.returnSupp === "6")
        ) {
          // return ValidateAdtDob(val.dob, val.fesno)

          // "year-month-day" (e.g., "2021-04-3")
          const DobString = val.dob;
          const Dob = new Date(DobString);

          // Get the current date
          const currentDate = new Date();
          // Calculate the difference between the current date and the date of birth in milliseconds
          const differenceInMs = currentDate - Dob;

          // Calculate the difference in years
          const differenceInYears =
            differenceInMs / (1000 * 60 * 60 * 24 * 365.25);

          // Check if the date of birth is greater than 2 years from the current date
          if (differenceInYears < 12) {
            dispatch({ type: "notiMessageShow", payload: true });
            dispatch({
              type: "notiMessage",
              payload:
                (context.translation && context.translation.datetwel) +
                " " +
                count,
            });
            dispatch({ type: "notiVarient", payload: "error" });
            hidemessage();
            return false;
          }
        }
        if (val.ty === "ADT" && val.dob !== null) {
          // return ValidateAdtDob(val.dob, val.fesno)

          // "year-month-day" (e.g., "2021-04-3")
          const DobString = val.dob;
          const Dob = new Date(DobString);

          // Get the current date
          const currentDate = new Date();
          // Calculate the difference between the current date and the date of birth in milliseconds
          const differenceInMs = currentDate - Dob;

          // Calculate the difference in years
          const differenceInYears =
            differenceInMs / (1000 * 60 * 60 * 24 * 365.25);

          // Check if the date of birth is greater than 2 years from the current date
          if (differenceInYears < 12) {
            dispatch({ type: "notiMessageShow", payload: true });
            dispatch({
              type: "notiMessage",
              payload:
                (context.translation && context.translation.datetwel) +
                " " +
                count,
            });
            dispatch({ type: "notiVarient", payload: "error" });
            hidemessage();
            return false;
          }
        }

        // validation date of birth for adult
        if (
          val.ty === "ADT" &&
          (val.dob !== null || val.dob !== "") &&
          (fareruleResp.data[0].doc === "yes" ||
            state.onwardSupp === "3" ||
            state.returnSupp === "3" ||
            state.onwardSupp === "5" ||
            state.returnSupp === "5" ||
            state.onwardSupp === "6" ||
            state.returnSupp === "6")
        ) {
          // return ValidateAdtDob(val.dob, val.fesno)

          // "year-month-day" (e.g., "2021-04-3")
          const DobString = val.dob;
          const Dob = new Date(DobString);

          // Get the current date
          const currentDate = new Date();
          // Calculate the difference between the current date and the date of birth in milliseconds
          const differenceInMs = currentDate - Dob;

          // Calculate the difference in years
          const differenceInYears =
            differenceInMs / (1000 * 60 * 60 * 24 * 365.25);

          // Check if the date of birth is greater than 2 years from the current date
          if (differenceInYears < 12) {
            dispatch({ type: "notiMessageShow", payload: true });
            dispatch({
              type: "notiMessage",
              payload:
                (context.translation && context.translation.datetwel) +
                " " +
                count,
            });
            dispatch({ type: "notiVarient", payload: "error" });
            hidemessage();
            return false;
          }
        }
        if (val.ty === "ADT" && val.dob !== null) {
          // return ValidateAdtDob(val.dob, val.fesno)

          // "year-month-day" (e.g., "2021-04-3")
          const DobString = val.dob;
          const Dob = new Date(DobString);

          // Get the current date
          const currentDate = new Date();
          // Calculate the difference between the current date and the date of birth in milliseconds
          const differenceInMs = currentDate - Dob;

          // Calculate the difference in years
          const differenceInYears =
            differenceInMs / (1000 * 60 * 60 * 24 * 365.25);

          // Check if the date of birth is greater than 2 years from the current date
          if (differenceInYears < 12) {
            dispatch({ type: "notiMessageShow", payload: true });
            dispatch({
              type: "notiMessage",
              payload:
                (context.translation && context.translation.datetwel) +
                " " +
                count,
            });
            dispatch({ type: "notiVarient", payload: "error" });
            hidemessage();
            return false;
          }
        }
        if (checkMandatoryFlagNat() === true && val.doc.na === "") {
          return validateNationality(val.fesno, pType[val.ty]);
        }
        if (val.ty === "CHD" && val.dob === null) {
          dispatch({ type: "notiMessageShow", payload: true });
          dispatch({
            type: "notiMessage",
            payload:
              (context.translation && context.translation.plzseleDate) +
              pType[val.ty] +
              "" +
              val.fesno,
          });
          dispatch({ type: "notiVarient", payload: "error" });
          hidemessage();
          return false;
        }

        // Child date of birth validation
        if (val.ty === "CHD" && val.dob !== null) {
          // "year-month-day" (e.g., "2021-04-3")
          const DobString = val.dob;
          const Dob = new Date(DobString);

          // Get the current date
          const currentDate = new Date();
          // Calculate the difference between the current date and the date of birth in milliseconds
          const differenceInMs = currentDate - Dob;

          // Calculate the difference in years
          const differenceInYears =
            differenceInMs / (1000 * 60 * 60 * 24 * 365);

          // Check if the date of birth is less than 2 years from the current date
          if (differenceInYears < 2) {
            dispatch({ type: "notiMessageShow", payload: true });
            dispatch({
              type: "notiMessage",
              payload:
                (context.translation && context.translation.datetwo) +
                pType[val.ty] +
                " " +
                count,
            });
            dispatch({ type: "notiVarient", payload: "error" });
            hidemessage();
            return false;
          }

          // Check if the date of birth is greater than 12 years from the current date
          if (differenceInYears > 12) {
            dispatch({ type: "notiMessageShow", payload: true });
            dispatch({
              type: "notiMessage",
              payload:
                (context.translation && context.translation.datelestwo) +
                pType[val.ty] +
                " " +
                count,
            });
            dispatch({ type: "notiVarient", payload: "error" });
            hidemessage();
            return false;
          }
        }

        //Call CHD DOB
        // if( pType[val.ty]==="CHD" && val.dob!==undefined){
        //   var date="2020-01-20";
        //   let newDate=DateUtils.convertStringToDate(new Date());
        //   var dates = new Date().getFullYear()-12+'-'+(new Date().getMonth()-1)+'-'+new Date().getDate();
        //   alert(dates);//2019-01-22
        //   alert(date <= newDate && date <= dates);
        //   if(val.inf.dob <= newDate && val.inf.dob <= dates){
        //    dispatch({ type: 'notiMessageShow', payload: true })
        //    dispatch({ type: 'notiMessage', payload: 'Invalid date of Birth for CHD '+count})
        //    dispatch({ type: 'notiVarient', payload: 'danger' })
        //    hidemessage();
        //    return false;
        //   }
        //  }
        if (
          state.onwardSupp !== "0" &&
          val.fn !== "" &&
          val.fn !== undefined &&
          val.fn.length === 1
        ) {
          dispatch({ type: "notiMessageShow", payload: true });
          dispatch({
            type: "notiMessage",
            payload:
              (context.translation && context.translation.firstNametwo) +
              pType[val.ty] +
              "" +
              val.fesno,
          });
          dispatch({ type: "notiVarient", payload: "error" });
          hidemessage();
          return false;
        }
        if (val.ln !== "" && val.ln !== undefined && val.ln.length === 1) {
          dispatch({ type: "notiMessageShow", payload: true });
          dispatch({
            type: "notiMessage",
            payload:
              (context.translation && context.translation.lastNametwo) +
              pType[val.ty] +
              "" +
              val.fesno,
          });
          dispatch({ type: "notiVarient", payload: "error" });
          hidemessage();
          return false;
        }
        //Infant validations
        if (val.inf !== undefined && request.inf > 0) {
          if (val.inf.tl === "") {
            return validateTitle(val.fesno, "Infant");
          }
          if (state.onwardSupp !== "0" && val.inf.fn === "") {
            return validateName(false, "first name", val.fesno, "Infant");
          } else {
            if (state.onwardSupp !== "0" && !characterValidation(val.inf.fn)) {
              return validateName(true, "first name", val.fesno, "Infant");
            }
          }
          if (val.inf.ln === "") {
            return validateName(false, "last name", val.fesno, "Infant");
          } else {
            if (!characterValidation(val.inf.ln)) {
              return validateName(true, "last name", val.fesno, "Infant");
            }
          }

          if (
            state.onwardSupp !== "0" &&
            val.inf !== "" &&
            val.inf.fn !== undefined &&
            val.inf.fn !== "" &&
            val.inf.fn.length === 1
          ) {
            dispatch({ type: "notiMessageShow", payload: true });
            dispatch({
              type: "notiMessage",
              payload:
                (context.translation && context.translation.firstmintwo) +
                val.fesno,
            });
            dispatch({ type: "notiVarient", payload: "error" });
            hidemessage();
            return false;
          }
          if (
            val.inf !== "" &&
            val.inf.ln !== undefined &&
            val.inf.ln !== "" &&
            val.inf.ln.length === 1
          ) {
            dispatch({ type: "notiMessageShow", payload: true });
            dispatch({
              type: "notiMessage",
              payload:
                (context.translation && context.translation.lastmintwo) +
                val.fesno,
            });
            dispatch({ type: "notiVarient", payload: "error" });
            hidemessage();
            return false;
          }
          if (
            val.inf !== "" &&
            val.inf !== undefined &&
            (val.inf.dob === "" || val.inf.dob === null)
          ) {
            dispatch({ type: "notiMessageShow", payload: true });
            dispatch({
              type: "notiMessage",
              payload: (context.translation && context.translation.plzseleDateinf) + val.fesno,
            });
            dispatch({ type: "notiVarient", payload: "error" });
            hidemessage();
            return false;
          }
          if (
            val.inf !== "" &&
            val.inf !== undefined &&
            (val.inf.dob !== "" || val.inf.dob !== null)
          ) {
            // return ValidateInfDob(val.inf.dob, val.fesno)

            // "year-month-day" (e.g., "2021-04-3")
            const DobString = val.inf.dob;
            const Dob = new Date(DobString);

            // Get the current date
            const currentDate = new Date();
            // Check if the date of birth is a future date
            if (Dob > currentDate) {
              dispatch({ type: "notiMessageShow", payload: true });
              dispatch({
                type: "notiMessage",
                payload:
                  (context.translation && context.translation.docannot) + " " + count,
              });
              dispatch({ type: "notiVarient", payload: "error" });
              hidemessage();
              return false;
            }

            // Calculate the difference between the current date and the date of birth in milliseconds
            const differenceInMs = currentDate - Dob;

            // Calculate the difference in years
            const differenceInYears =
              differenceInMs / (1000 * 60 * 60 * 24 * 365);

            // Check if the date of birth is greater than 2 years from the current date
            if (differenceInYears > 2) {
              dispatch({ type: "notiMessageShow", payload: true });
              dispatch({
                type: "notiMessage",
                payload:
                  (context.translation && context.translation.datelestwoinf) +
                  " " +
                  count,
              });
              dispatch({ type: "notiVarient", payload: "error" });
              hidemessage();
              return false;
            }
          }

          //alJazeera conditions handle for inf dob > 14 days from flight date
          if (
            val.inf !== "" &&
            val.inf !== undefined &&
            val.inf.dob !== "" &&
            val.inf.dob !== null &&
            (state.onwardSupp == "3" || state.returnSupp == "3") &&
            state.departure_date
          ) {
            let departureTime = new Date(state.departure_date).getTime();
            let dobTime = new Date(val.inf.dob).getTime();
            let hourDiff = departureTime - dobTime;
            var hDiff = hourDiff / 3600 / 1000; //in hours
            let hours = Math.floor(hDiff);
            if (hours < 336) {
              dispatch({ type: "notiMessageShow", payload: true });
              dispatch({
                type: "notiMessage",
                payload:
                  (context.translation && context.translation.datecannotless) +
                  val.fesno,
              });
              dispatch({ type: "notiVarient", payload: "error" });
              hidemessage();
              return false;
            }
          }
          // inf_dept_date
          if (
            val.inf !== "" &&
            val.inf !== undefined &&
            val.inf.dob !== "" &&
            val.inf.dob !== null &&
            state.inf_dept_date
          ) {
            let dobinNum = Number(moment(val.inf.dob).format("YYYYMMDD"));
            let deptinNum = Number(
              moment(state.inf_dept_date).format("YYYYMMDD")
            );
            if (dobinNum < deptinNum) {
              dispatch({ type: "notiMessageShow", payload: true });
              dispatch({
                type: "notiMessage",
                payload:
                  (context.translation && context.translation.infantdate) +
                  val.fesno,
              });
              dispatch({ type: "notiVarient", payload: "error" });
              hidemessage();
              return false;
            }
          }
          if (
            val.inf !== "" &&
            val.inf !== undefined &&
            fareruleResp.data[0] !== undefined &&
            fareruleResp.data[0].doc === "yes" &&
            val.inf.doc.exd === null
          ) {
            dispatch({ type: "notiMessageShow", payload: true });
            dispatch({
              type: "notiMessage",
              payload: (context.translation && context.translation.plzsleDateinf) + val.fesno,
            });
            dispatch({ type: "notiVarient", payload: "error" });
            hidemessage();
            return false;
          }

          //Check INF DOB
          // if(val.inf!=="" && val.inf.dob!==undefined){
          //    var date="2020-01-20";
          //    let newDate=DateUtils.convertStringToDate(new Date());
          //    var dates = new Date().getFullYear()-2+'-'+(new Date().getMonth()-1)+'-'+new Date().getDate();
          //    alert(dates);//2019-01-22
          //    alert(date <= newDate && date <= dates);
          //    if(val.inf.dob <= newDate && val.inf.dob <= dates){
          //     dispatch({ type: 'notiMessageShow', payload: true })
          //     dispatch({ type: 'notiMessage', payload: 'Invalid date of Birth for INF '+count})
          //     dispatch({ type: 'notiVarient', payload: 'danger' })
          //     hidemessage();
          //     return false;
          //    }
          //   }
          //Passport validations
          if (
            fareruleResp.data[0] !== undefined &&
            fareruleResp.data[0].doc === "yes"
          ) {
            if (val.inf !== undefined && val.inf.doc !== undefined) {
              if (val.inf.doc.na === undefined) {
                return validateNational(val.fesno, "Infant");
              } else if (
                val.inf.doc !== undefined &&
                val.inf.doc.isc === undefined
              ) {
                return validateIssuCntry(val.fesno, "Infant");
              } else if (val.inf.doc !== undefined && val.inf.doc.num === "") {
                return validatePassport(false, val.fesno, "Infant");
              } else {
                if (!characterNumberValidation(val.inf.doc.num)) {
                  return validatePassport(true, val.fesno, "Infant");
                }
              }
            }
          }

          //Validating Passport and Expiry date for Indigo
          if (
            (request.origns.length > 0 &&
              request.origns[0].srcorigns[0].sup === "4") ||
            (request.origns.length > 0 &&
              request.origns[0].destorigins !== undefined &&
              request.origns[0].destorigins.length > 0 &&
              request.origns[0].destorigins[0].sup === "4")
          ) {
            if (state.indigo_passport) {
              if (
                val.inf.doc !== undefined &&
                val.inf.doc.num !== undefined &&
                val.inf.doc.num === ""
              ) {
                return validatePassport(false, val.fesno, "Infant");
              } else if (
                val.inf.doc !== undefined &&
                val.inf.doc.exd !== undefined &&
                val.inf.doc.exd === null
              ) {
                return validateExpiry(val.fesno, "Infant");
              }
            }
          }
        }
        //Lead pax validations
        if (val.ty === "ADT" && val.lead) {
          if (
            // state.bookingType === "DIRECT" &&
            (val.phc === "" || val.phc === null)
          ) {
            dispatch({ type: "notiMessageShow", payload: true });
            dispatch({
              type: "notiMessage",
              payload: (context.translation && context.translation.plzslephcd),
            });
            dispatch({ type: "notiVarient", payload: "error" });
            hidemessage();
            return false;
          }
          if (val.phn === "") {
            dispatch({ type: "notiMessageShow", payload: true });
            dispatch({
              type: "notiMessage",
              payload: (context.translation && context.translation.plzslephnum),
            });
            dispatch({ type: "notiVarient", payload: "error" });
            hidemessage();
            return false;
          } else if (!mobileNumberValidation(val.phn)) {
            dispatch({ type: "notiMessageShow", payload: true });
            dispatch({
              type: "notiMessage",
              payload: (context.translation && context.translation.plzvalphnum),
            });
            dispatch({ type: "notiVarient", payload: "error" });
            hidemessage();
            return false;
          }
          if (
            state.bookingType === "DIRECT" &&
            val.destnum !== undefined &&
            val.destnum !== "" &&
            !mobileNumberValidation(val.destnum)
          ) {
            dispatch({ type: "notiMessageShow", payload: true });
            dispatch({
              type: "notiMessage",
              payload:
                (context.translation && context.translation.plzvaldest),
            });
            dispatch({ type: "notiVarient", payload: "error" });
            hidemessage();
            return false;
          }
          if (val.em === "") {
            dispatch({ type: "notiMessageShow", payload: true });
            dispatch({
              type: "notiMessage",
              payload: (context.translation && context.translation.plzentem),
            });
            dispatch({ type: "notiVarient", payload: "error" });
            hidemessage();
            return false;
          } else if (!emailValidation(val.em)) {
            dispatch({ type: "notiMessageShow", payload: true });
            dispatch({
              type: "notiMessage",
              payload: (context.translation && context.translation.plzentvalemid),
            });
            dispatch({ type: "notiVarient", payload: "error" });
            hidemessage();
            return false;
          }
        }
        //Passport validations
        if (
          (!check &&
            fareruleResp.data[0] !== undefined &&
            fareruleResp.data[0].doc === "yes") ||
          state.enableFlynas
        ) {
          if (val.doc.na === "") {
            return validateNational(val.fesno, pType[val.ty]);
          } else if (val.doc.isc === "") {
            return validateIssuCntry(val.fesno, pType[val.ty]);
          } else if (val.doc.num === "") {
            return validatePassport(false, val.fesno, pType[val.ty]);
          }
          // else if(val.doc.isd===""){
          //   return validateIssuDate(val.fesno,  pType[val.ty]);
          // }
          else if (val.doc.isd !== null) {
            // "year-month-day" (e.g., "2021-04-3")
            const IsdString = val.doc.isd;
            const isd = new Date(IsdString);

            // Get the current date
            const currentDate = new Date();
            // Check if the date is a past date
            if (isd > currentDate) {
              dispatch({ type: "notiMessageShow", payload: true });
              dispatch({
                type: "notiMessage",
                payload:
                  (context.translation && context.translation.issdatecnot) +
                  pType[val.ty] +
                  " " +
                  val.fesno,
              });
              dispatch({ type: "notiVarient", payload: "error" });
              hidemessage();
              return false;
            }
          } else if (val.doc.exd === null) {
            return validateExpiry(val.fesno, pType[val.ty]);
          } else if (val.doc.exd !== null) {
            // "year-month-day" (e.g., "2021-04-3")
            const ExdString = val.doc.exd;
            const exd = new Date(ExdString);

            // Get the current date
            const currentDate = new Date();
            // Check if the date is a past date
            if (exd < currentDate) {
              dispatch({ type: "notiMessageShow", payload: true });
              dispatch({
                type: "notiMessage",
                payload:
                  (context.translation && context.translation.expdatecnot) +
                  pType[val.ty] +
                  " " +
                  val.fesno,
              });
              dispatch({ type: "notiVarient", payload: "error" });
              hidemessage();
              return false;
            }
          } else {
            if (!characterNumberValidation(val.doc.num)) {
              return validatePassport(true, val.fesno, pType[val.ty]);
            }
          }
        }

        //Validating Passport Number for Indigo
        if (
          (request.origns.length > 0 &&
            request.origns[0].srcorigns[0].sup === "4") ||
          (request.origns.length > 0 &&
            request.origns[0].destorigins !== undefined &&
            request.origns[0].destorigins.length > 0 &&
            request.origns[0].destorigins[0].sup === "4")
        ) {
          if (state.indigo_passport) {
            if (val.doc.num === "") {
              return validatePassport(false, val.fesno, pType[val.ty]);
            } else if (val.doc.isd !== null) {
              // "year-month-day" (e.g., "2021-04-3")
              const IsdString = val.doc.isd;
              const isd = new Date(IsdString);

              // Get the current date
              const currentDate = new Date();
              // Check if the date is a past date
              if (isd > currentDate) {
                dispatch({ type: "notiMessageShow", payload: true });
                dispatch({
                  type: "notiMessage",
                  payload:
                    (context.translation && context.translation.issdatecnot) +
                    pType[val.ty] +
                    " " +
                    val.fesno,
                });
                dispatch({ type: "notiVarient", payload: "error" });
                hidemessage();
                return false;
              }
            } else if (val.doc.exd === null) {
              return validateExpiry(val.fesno, pType[val.ty]);
            } else if (val.doc.exd !== null) {
              // "year-month-day" (e.g., "2021-04-3")
              const ExdString = val.doc.exd;
              const exd = new Date(ExdString);

              // Get the current date
              const currentDate = new Date();
              // Check if the date is a past date
              if (exd < currentDate) {
                dispatch({ type: "notiMessageShow", payload: true });
                dispatch({
                  type: "notiMessage",
                  payload:
                    (context.translation && context.translation.expdatecnot) +
                    pType[val.ty] +
                    " " +
                    val.fesno,
                });
                dispatch({ type: "notiVarient", payload: "error" });
                hidemessage();
                return false;
              }
            }
          }
        }

        // validating if issued date and expiry date of adult and child only after filled fields
        if (val.doc.isd_year && val.doc.isd_month && val.doc.isd_day) {
          if (val.doc.isd !== null && val.doc.isd !== "") {
            // "year-month-day" (e.g., "2021-04-3")
            const IsdString = val.doc.isd;
            const isd = new Date(IsdString);

            // Get the current date
            const currentDate = new Date();
            // Check if the date is a past date
            if (isd > currentDate) {
              dispatch({ type: "notiMessageShow", payload: true });
              dispatch({
                type: "notiMessage",
                payload:
                  (context.translation && context.translation.issdatecnot) +
                  pType[val.ty] +
                  " " +
                  val.fesno,
              });
              dispatch({ type: "notiVarient", payload: "error" });
              hidemessage();
              return false;
            }
          }
        }
        if (val.doc.exp_year && val.doc.exp_month && val.doc.exp_day) {
          if (val.doc.exd !== null && val.doc.exd !== "") {
            // "year-month-day" (e.g., "2021-04-3")
            const ExdString = val.doc.exd;
            const exd = new Date(ExdString);

            // Get the current date
            const currentDate = new Date();
            // Check if the date is a past date
            if (exd < currentDate) {
              dispatch({ type: "notiMessageShow", payload: true });
              dispatch({
                type: "notiMessage",
                payload:
                  (context.translation && context.translation.expdatecnot) +
                  pType[val.ty] +
                  " " +
                  val.fesno,
              });
              dispatch({ type: "notiVarient", payload: "error" });
              hidemessage();
              return false;
            }
          }
        }

        // validating if issued date and expiry date of infant only after filled fields
        if(val.inf !== "" && val.inf !== undefined) {
          if(val.inf.doc.isd_year && val.inf.doc.isd_month && val.inf.doc.isd_day) {
            if (val.inf.doc.isd !== null && val.inf.doc.isd !== "") {
              // "year-month-day" (e.g., "2021-04-3")
              const IsdString = val.inf.doc.isd;
              const isd = new Date(IsdString);
    
              // Get the current date
              const currentDate = new Date();
              // Check if the date is a past date
              if (isd > currentDate) {
                dispatch({ type: "notiMessageShow", payload: true });
                dispatch({
                  type: "notiMessage",
                  payload:
                    (context.translation && context.translation.issdatecnot) +
                    " Infant " +
                    val.fesno,
                });
                dispatch({ type: "notiVarient", payload: "error" });
                hidemessage();
                return false;
              }
            }
          }
          if(val.inf.doc.exp_year && val.inf.doc.exp_month && val.inf.doc.exp_day) {
            if (val.inf.doc.exd !== null && val.inf.doc.exd !== "") {
              // "year-month-day" (e.g., "2021-04-3")
              const ExdString = val.inf.doc.exd;
              const exd = new Date(ExdString);
    
              // Get the current date
              const currentDate = new Date();
              // Check if the date is a past date
              if (exd < currentDate) {
                dispatch({ type: "notiMessageShow", payload: true });
                dispatch({
                  type: "notiMessage",
                  payload:
                    (context.translation && context.translation.expdatecnot) +
                    " Infant " +
                    val.fesno,
                });
                dispatch({ type: "notiVarient", payload: "error" });
                hidemessage();
                return false;
              }
            }
          }
        }
        //Validating the Seat
        if (state.bookingType === "DIRECT") {
          var seat = "";
          if (val.ty === "ADT" || val.ty === "CHD") {
            if (request.sty === "normal") {
              if (request.tt === "1") {
                seat = request.origns[0].srcorigns[0].pr.seats;
                if (
                  seat !== undefined &&
                  seat === "Free" &&
                  val.seats.length === 0
                ) {
                  dispatch({ type: "notiMessageShow", payload: true });
                  dispatch({
                    type: "notiMessage",
                    payload: (context.translation && context.translation.plzseleSeat) + val.ty + val.fesno,
                  });
                  dispatch({ type: "notiVarient", payload: "error" });
                  hidemessage();
                  return false;
                }
              } else if (request.tt === "2") {
                seat = request.origns[0].srcorigns[0].pr.seats;
                var retSeat = request.origns[0].destorigins[0].pr.seats;
                if (
                  seat !== undefined &&
                  seat !== "" &&
                  retSeat !== "" &&
                  val.seats.length === 0 &&
                  (seat === "Free" || retSeat === "Free")
                ) {
                  dispatch({ type: "notiMessageShow", payload: true });
                  dispatch({
                    type: "notiMessage",
                    payload: (context.translation && context.translation.plzseleSeat) + val.ty + val.fesno,
                  });
                  dispatch({ type: "notiVarient", payload: "error" });
                  hidemessage();
                  return false;
                }
              }
              //Validating the Meal
              if (
                request.origns[0].srcorigns[0].sup === "4" &&
                request.region_type === "International"
              ) {
                if (
                  request.origns[0].srcorigns[0].pr.ftyp.includes("Flexi") ||
                  request.origns[0].srcorigns[0].pr.ftyp.includes("Corporate")
                ) {
                  let count = 1;
                  for (let meal of val.meals) {
                    if (meal.label === "Select") {
                      dispatch({ type: "notiMessageShow", payload: true });
                      if (count === 1) {
                        dispatch({
                          type: "notiMessage",
                          payload:
                            (context.translation && context.translation.plzseleonwardMeal) +
                            val.ty +
                            val.fesno,
                        });
                      } else {
                        dispatch({
                          type: "notiMessage",
                          payload:
                            (context.translation && context.translation.plzseleReturnMeal) +
                            val.ty +
                            val.fesno,
                        });
                      }
                      dispatch({ type: "notiVarient", payload: "error" });
                      hidemessage();
                      return false;
                    }
                    count = count + 1;
                  }
                }
              }
            } else {
              var retSeat = "";
              if (state.onwardSupp === "2") {
                seat = request.origns[0].srcorigns[0].pr.seats;
              } else if (
                state.returnSupp === "2" &&
                request.origns[0].destorigins !== undefined
              ) {
                retSeat = request.origns[0].destorigins[0].pr.seats;
              }
              if (
                seat !== undefined &&
                retSeat !== "" &&
                val.seats.length === 0 &&
                (seat === "Free" || retSeat === "Free")
              ) {
                dispatch({ type: "notiMessageShow", payload: true });
                dispatch({
                  type: "notiMessage",
                  payload: (context.translation && context.translation.plzseleSeat) + val.ty + val.fesno,
                });
                dispatch({ type: "notiVarient", payload: "error" });
                hidemessage();
                return false;
              }
              //Validating the Meal
              if (
                request.region_type === "International" &&
                (request.origns[0].srcorigns[0].sup === "4" ||
                  request.origns[0].destorigins[0].sup === "4")
              ) {
                let onwardftype = false;
                let returnftype = false;
                if (
                  request.origns[0].srcorigns[0].sup === "4" &&
                  (request.origns[0].srcorigns[0].pr.ftyp.includes("Flexi") ||
                    request.origns[0].srcorigns[0].pr.ftyp.includes(
                      "Corporate"
                    ))
                ) {
                  onwardftype = true;
                }
                if (
                  request.origns[0].destorigins[0].sup === "4" &&
                  (request.origns[0].destorigins[0].pr.ftyp.includes("Flexi") ||
                    request.origns[0].destorigins[0].pr.ftyp.includes(
                      "Corporate"
                    ))
                ) {
                  returnftype = true;
                }
                if (onwardftype || returnftype) {
                  let count = 1;
                  for (let meal of val.meals) {
                    if (meal.label === "Select") {
                      dispatch({ type: "notiMessageShow", payload: true });
                      if (count === 1) {
                        dispatch({
                          type: "notiMessage",
                          payload:
                            (context.translation && context.translation.plzseleonwardMeal) +
                            val.ty +
                            val.fesno,
                        });
                      } else {
                        dispatch({
                          type: "notiMessage",
                          payload:
                            (context.translation && context.translation.plzseleReturnMeal) +
                            val.ty +
                            val.fesno,
                        });
                      }
                      dispatch({ type: "notiVarient", payload: "error" });
                      hidemessage();
                      return false;
                    }
                    count = count + 1;
                  }
                }
              }
            }
          }
        }
      }
      count = count + 1;
    } else {
      dispatch({ type: "notiMessageShow", payload: true });
      dispatch({
        type: "notiMessage",
        payload: (context.translation && context.translation.plzentPassdetails),
      });
      dispatch({ type: "notiVarient", payload: "error" });
      hidemessage();
      return false;
    }

    return true;
  };

  const validateTitle = (count, type) => {
    dispatch({ type: "notiMessageShow", payload: true });
    dispatch({
      type: "notiMessage",
      payload: (context.translation && context.translation.plzseleTitle) + type + " " + count,
    });
    dispatch({ type: "notiVarient", payload: "error" });
    hidemessage();
    return false;
  };
  const validateNationality = (count, pax) => {
    dispatch({ type: "notiMessageShow", payload: true });
    dispatch({
      type: "notiMessage",
      payload: (context.translation && context.translation.plzselenation) + pax + " " + count,
    });
    dispatch({ type: "notiVarient", payload: "error" });
    hidemessage();
    return false;
  };
  const validateName = (name, type, count, pax) => {
    if (!name) {
      dispatch({ type: "notiMessageShow", payload: true });
      dispatch({
        type: "notiMessage",
        payload: (context.translation && context.translation.plzEenter) + type + (context.translation && context.translation.forPass) + pax + " " + count,
      });
      dispatch({ type: "notiVarient", payload: "error" });
      hidemessage();
      return false;
    } else {
      dispatch({ type: "notiMessageShow", payload: true });
      dispatch({
        type: "notiMessage",
        payload:
          (context.translation && context.translation.plzentval) + type + (context.translation && context.translation.forPass) + pax + " " + count,
      });
      dispatch({ type: "notiVarient", payload: "error" });
      hidemessage();
      return false;
    }
  };

  const validatePassport = (name, count, type) => {
    if (!name) {
      dispatch({ type: "notiMessageShow", payload: true });
      dispatch({
        type: "notiMessage",
        payload:
          (context.translation && context.translation.plzentpasnum) + type + " " + +count,
      });
      dispatch({ type: "notiVarient", payload: "error" });
      hidemessage();
      return false;
    } else {
      dispatch({ type: "notiMessageShow", payload: true });
      dispatch({
        type: "notiMessage",
        payload:
          (context.translation && context.translation.plzentvalpass) +
          type +
          " " +
          +count,
      });
      dispatch({ type: "notiVarient", payload: "error" });
      hidemessage();
      return false;
    }
  };
  const validateNational = (count, type) => {
    dispatch({ type: "notiMessageShow", payload: true });
    dispatch({
      type: "notiMessage",
      payload: (context.translation && context.translation.plzselenationpass) + type + " " + count,
    });
    dispatch({ type: "notiVarient", payload: "error" });
    hidemessage();
    return false;
  };
  const validateIssuCntry = (count, type) => {
    dispatch({ type: "notiMessageShow", payload: true });
    dispatch({
      type: "notiMessage",
      payload:
        (context.translation && context.translation.plzseleisscountry)+ type + " " + count,
    });
    dispatch({ type: "notiVarient", payload: "error" });
    hidemessage();
    return false;
  };

  const validateExpiry = (count, type) => {
    dispatch({ type: "notiMessageShow", payload: true });
    dispatch({
      type: "notiMessage",
      payload: (context.translation && context.translation.pleaseSelectexpdate) + type + " " + count,
    });
    dispatch({ type: "notiVarient", payload: "error" });
    hidemessage();
    return false;
  };
  const checkMandatoryFlagNat = () => {
    if (
      state.onwardSupp === "2" ||
      state.returnSupp === "2" ||
      state.onwardSupp === "3" ||
      state.returnSupp === "3"
    ) {
      return true;
    } else {
      return false;
    }
  };

  const hidemessage = () => {
    setTimeout(function () {
      dispatch({ type: "notiMessageShow", payload: false });
      dispatch({ type: "notiMessage", payload: "" });
      dispatch({ type: "notiVarient", payload: "" });
    }, 5000);
  };

  const passengrsData = (data) => {
    dispatch({ type: "psngers", payload: data });
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  //Constucting URL from WEGO
  const loadSRPResults=()=>{
    let urlSplitOne = location.search.split("=");
    urlSplitOne=urlSplitOne.toString().split("&")
    urlSplitOne = urlSplitOne.toString().split(",")
    const params = new URLSearchParams({
      srcCode: urlSplitOne[3],
      destCode: urlSplitOne[5],
      dd: urlSplitOne[7],
      ad: urlSplitOne[9],
      tt: urlSplitOne[11],
      adt: urlSplitOne[17],
      chd: urlSplitOne[19],
      inf: urlSplitOne[21],
      ns: false,
      cls: urlSplitOne[15],
      cur: urlSplitOne[27],
      modify: false,
    });
    sessionStorage.setItem("SEL_CUR",urlSplitOne[27]);
    const urlParams = params.toString();
    // Construct the URL with the parameters
    const url = `/flight/results?${urlParams}`;
    var h = window.location.host.includes('localhost')?'http':'https';
    window.location.href = `${h}://${window.location.host}`+url;
  }

  const showMoreFlights = () => {
    if(location.state!==null && location.state!==undefined && location.state.url!==undefined){
      window.location.href = location.state.url
    }else{
      loadSRPResults();
    }
  };

  const updateBaggagePrice=(obj)=>{
    dispatch({ type: 'baggagePrice', payload: obj })
  }

  const updateMealprice=(obj)=>{
    dispatch({ type: 'selMealprice', payload: obj })
  }

  const updateSeatPrice=(price)=>{
    dispatch({ type: 'seatPrice', payload: price })
    if(state.seatPrice!==price) {
      state.seatPrice=price;
      aplyPromoCode(promoCode, "autoApply");
    }
  }

  const updateSelectedSeats=(segList)=>{
    for(let pax of state.psngers){
      createSeatsList(pax,segList);
    }
  }

  const updateAdtPrice=(obj)=>{
    if(state.adtBagPrice!==obj) {
      state.adtBagPrice=obj;
      aplyPromoCode(promoCode, "autoApply");
    }
  }

  const updateChdPrice=(obj)=>{
    if(state.chdBagPrice!==obj) {
      state.chdBagPrice=obj;
      aplyPromoCode(promoCode, "autoApply");
    }
  }

  const updateAdtMealPrice=(obj)=>{
    if(state.adtMealPrice!==obj) {
      state.adtMealPrice=obj;
      aplyPromoCode(promoCode, "autoApply");
    }
  }

  const updateChdMealPrice=(obj)=>{
    if(state.chdMealPrice!==obj) {
      state.chdMealPrice=obj;
      aplyPromoCode(promoCode, "autoApply");
    }
  }

  const createSeatsList=(pax,segList)=>{
    let selSeats=[];
     for(let seg of segList){
        let paxList=seg.paxlist.filter((obj) => obj.pax_id===pax.sno);
        if(paxList!==undefined){
          for(let pax of paxList){
            if(pax.seat!=="" && pax.st==="Avail"){
              const payload={
                "sno":pax.rno+pax.seat,
                "jtype": seg.jtype,
                "route":seg.dept+"-"+seg.arvl,
                "price": Number(pax.price),
                "sup_price": Number(pax.price),
                "sup_cur_price":pax.sup_cur_price,
                "tid" : seg.tid
                }
                if(pax.seatKey) {
                  payload.seatKey = pax.seatKey
                  payload.segKey = pax.segKey
                }
                  if(pax.seatId) {
                  payload.seatId = pax.seatId
                }
                selSeats.push(payload)
            }
          }
        }
     }
     pax.seats=selSeats;
  }

  const update=(type)=>{
  }

  var settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: false,
    rtl: false,
    nextArrow: false,
    prevArrow: false,
    dots: true,
    responsive: [
      {
        breakpoint: 1367,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          nextArrow: false,
          prevArrow: false,
        }
      },
      {
        breakpoint: 480,
        settings: {
          centerMode: true,
          centerPadding: "30px 20px",
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
//Load Promocode for Register
  const loadPromoCodes=(type)=>{
    try {
    if(type==="Register" && context.user_resp !== "" && context.user_resp?.res !== undefined && context.user_resp?.res?.id!==undefined){
      dispatch({ type: "promoCodeList", payload: [] });
      let deptDt="";
      let arriDt="";
      let supplier=request.origns[0].srcorigns[0].sup;
      if(Number(request.tt) !== 2){
        deptDt=DateUtils.convertToDate(request.origns[0].srcorigns[0].seg[0].dd);
        arriDt=DateUtils.convertToDate(request.origns[0].srcorigns[0].seg[
          request.origns[0].srcorigns[0].seg.length - 1
        ].ad);
      }else{
        deptDt=DateUtils.convertToDate(request.origns[0].srcorigns[0].seg[0].dd);
        arriDt=DateUtils.convertToDate(request.origns[0].destorigins[0].seg[
          request.origns[0].destorigins.length - 1
        ].ad);
         supplier =request.sty==="normal"?request.origns[0].srcorigns[0].sup:request.origns[0].srcorigns[0].sup+"/"+request.origns[0].destorigins[0].sup;
      }
      const req={
          "user_id":context.user_resp?.res?.id,
          "region_type":localStorage.getItem("REGION_COUNTRY") !== null &&
          localStorage.getItem("REGION_COUNTRY") === "IN"
            ? "India"
            : "Qatar",
          "comp_id":FLIGHT_SEARCH_CID,
          "sup":supplier,
          "office_id":request.office_id,
          "tt":request.tt,
          "sty":request.sty,
          "product_type":"Flight",
          "from_date":deptDt,
          "to_date":arriDt
      }
      try {
        let tokenKey = Utilities.generateAPIToken(req);
        CntCommonService.getRegdPromoCodes(req,tokenKey).then((response) => {
          if (response.data !== undefined && response.data.suc) {
              dispatch({ type: "promoCodeList", payload: response.data.res });
             }else{
              dispatch({ type: "promoCodeList", payload: [] });
             }
          }).catch(function (error) {
            console.error("Error in getFlightPromo : " + error);      
          });        
      } catch (error) {
        console.error("Error in getFlightPromo" + error);        
      }
    }else{
      dispatch({ type: "promoCodeList", payload: [] });
      const request={
        "region_type":localStorage.getItem("REGION_COUNTRY") !== null &&
        localStorage.getItem("REGION_COUNTRY") === "IN"
          ? "India"
          : "Qatar",
        "sales_chnnel":"B2C",
        "product_type":"Flight"
     }
     try {
      let tokenKey = Utilities.generateAPIToken(request);
      CntCommonService.getPromoCodeList(request,tokenKey).then((response) => {
        if (response.data !== undefined && response.data.suc) {
            dispatch({ type: "promoCodeList", payload: response.data.res });
           }
        }).catch(function (error) {
          console.error("Error in getFlightPromo : " + error);      
        });      
     } catch (error) {
      console.error("Error in getFlightPromo" + error);        
     }
    }
  }catch (err) {
    console.error("Error in getFlightPromo" + err);
  }
  }

  const removeAppliedData=()=>{
    dispatch({ type: "promo_disc", payload: 0 });
    dispatch({ type: "promo_remarks", payload: "" });
    dispatch({ type: "promo_code", payload: "" });
    dispatch({ type: "promo_id", payload: 0 });
    setPromoCode("");
    // dispatch({ type: "notiMessageShow", payload: true });
    // dispatch({ type: "notiMessage", payload: context.translation && context.translation.removedpromo});
    // dispatch({ type: "notiVarient", payload: "success" });
    // hidemessage();
  }

  // APPLY PROMO CODE
  const aplyPromoCode=(code, SSR)=>{
    if(code!=="") {
      setPromoCode(code);
      try {
        let checkPromo = state.promoCodeList.length > 0 && state.promoCodeList.filter((promo) => promo.pro_code===code && promo.st);
        let promoExistList = [];
        if(state.appliedList.length > 0){
          promoExistList = state.appliedList.length > 0 && state.appliedList.filter((obj) => obj.pro_code===code);
         if(promoExistList===undefined){
            promoExistList = [];
         }
        }
        if(code!=="" && (checkPromo.length!==0 || SSR==="autoApply")){
          let adtFare = request.paxpr.filter((pax =>pax.ptype==="ADT"));
          let chdFare = request.paxpr.filter((pax =>pax.ptype==="CHD"));
          let infFare = request.paxpr.filter((pax =>pax.ptype==="INF"));
          let baseFare=Number(adtFare[0].bf) * Number(request.adt);
          if(request.chd > 0 && chdFare.length > 0){
            baseFare=Number(baseFare) + Number(chdFare[0].bf) * Number(request.chd);
          }
          if(request.inf > 0 && infFare.length > 0){
            baseFare=Number(baseFare) + Number(infFare[0].bf) * Number(request.inf);
          }
          let total=request.pr.tf;
          if(request.servicefee!==undefined && request.servicefee.length >0){
            total=Number(total) + Number(request.servicefee[0].complete_sfvalue);
          }
          if(state.otherCharge!==0 && state.otherCharge!==undefined){
            total=Number(total) + Number(state.otherCharge);
          }
          if(state.adtBagPrice!==0 || state.chdBagPrice!==0){
            total=Number(total) + Number(state.adtBagPrice) + Number(state.chdBagPrice);
          }
          if(state.adtMealPrice!==0 || state.chdMealPrice!==0){
            total=Number(total) + Number(state.adtMealPrice) + Number(state.chdMealPrice);
          }
          if(state.seatPrice!==0 && state.seatPrice!==undefined){
            total= Number(total) + Number(state.seatPrice);
          }
          if(state.totalAmount !== total || promoExistList.length===0) {
            const req={
              "promo_code":code,
              "user_id":context.user_resp?.res?.id,
              "promo_id":checkPromo[0].promo_id,
              "base_fare":baseFare,
              "total_fare":total,
              "product_type":"Flight"
           }
            let tokenKey = Utilities.generateAPIToken(req);
            CntCommonService.applyPromoCode(req,tokenKey).then((response) => {
            if (response.data !== undefined && response.data.suc) {
              dispatch({ type: "promo_disc", payload: response.data.res.promo_discount });
              dispatch({ type: "promo_remarks", payload: response.data.res.remarks });
              dispatch({ type: "promo_code", payload: code });
              dispatch({ type: "promo_id", payload: response.data.res.promo_id });
              let noPax=request.adt+request.chd+request.inf;
              let paxDisc = response.data.res.promo_discount/noPax;
              dispatch({ type: "pax_pdisc", payload: request.sty==="normal"?paxDisc:Number(paxDisc)/2 });
              dispatch({ type: "notiMessageShow", payload: true });
              dispatch({ type: "notiMessage", payload: context.translation && context.translation.sucpromo});
              dispatch({ type: "notiVarient", payload: "success" });
              response.data.res.pro_code=code;
              response.data.res.pax_pdisc=request.sty==="normal"?paxDisc:Number(paxDisc)/2
              let list = state.appliedList;
              list.unshift(response.data.res);
              dispatch({ type: "appliedList", payload: list });
              dispatch({ type: "totalAmount", payload: total });
              hidemessage();
              }else{
              if(response.data.res===500){
                dispatch({ type: "notiMessage", payload: context.translation && context.translation.promoerrmsgone});
              }else if(response.data.res===501){
                dispatch({ type: "notiMessage", payload: context.translation && context.translation.promoerrmsgtwo});
              }else if(response.data.res===502){
                dispatch({ type: "notiMessage", payload: context.translation && context.translation.promoerrmsgthree});
              }else if(response.data.res===503){
                dispatch({ type: "notiMessage", payload: context.translation && context.translation.promoerrmsgfour});
              }else if(response.data.res===504){
                dispatch({ type: "notiMessage", payload: context.translation && context.translation.promoerrmsgfive});
              }
              dispatch({ type: "notiMessageShow", payload: true });
              dispatch({ type: "notiVarient", payload: "error" });
              dispatch({ type: "promo_disc", payload: 0 });
              dispatch({ type: "pax_pdisc", payload: 0 });
              dispatch({ type: "promo_code", payload: "" });
              hidemessage();
               }
            }).catch(function (error) {
              if(error?.response?.data==="Unauthorized"){
                dispatch({ type: "notiMessage", payload: context.translation && context.translation.promoerrmsgfive});
                dispatch({ type: "notiMessageShow", payload: true });
                dispatch({ type: "notiVarient", payload: "error" });
                dispatch({ type: "promo_disc", payload: 0 });
                dispatch({ type: "pax_pdisc", payload: 0 });
                dispatch({ type: "promo_code", payload: "" });
              }
              console.error("Error in applyPromoCode : " + error);      
            });
          } else if(promoExistList.length!==0){
            dispatch({ type: "promo_disc", payload: promoExistList[0].promo_discount });
            dispatch({ type: "promo_remarks", payload: promoExistList[0].remarks });
            dispatch({ type: "promo_code", payload: code });
            dispatch({ type: "promo_id", payload: promoExistList[0].promo_id });
            dispatch({ type: "pax_pdisc", payload: promoExistList[0].pax_pdisc });
            dispatch({ type: "notiMessageShow", payload: true });
            dispatch({ type: "notiMessage", payload: context.translation && context.translation.sucpromo});
            dispatch({ type: "notiVarient", payload: "success" });
            hidemessage()
          }else{
            dispatch({ type: "notiMessageShow", payload: true });
            dispatch({ type: "notiMessage", payload: context.translation && context.translation.validpromo});
            dispatch({ type: "notiVarient", payload: "error" });
            dispatch({ type: "promo_disc", payload: 0 });
            dispatch({ type: "promo_code", payload: "" });
            hidemessage();
          }      
        }else if(promoExistList.length!==0){
          dispatch({ type: "promo_disc", payload: promoExistList[0].promo_discount });
          dispatch({ type: "promo_remarks", payload: promoExistList[0].remarks });
          dispatch({ type: "promo_code", payload: code });
          dispatch({ type: "promo_id", payload: promoExistList[0].promo_id });
          dispatch({ type: "pax_pdisc", payload: promoExistList[0].pax_pdisc });
          dispatch({ type: "notiMessageShow", payload: true });
          dispatch({ type: "notiMessage", payload: context.translation && context.translation.sucpromo});
          dispatch({ type: "notiVarient", payload: "success" });
          hidemessage()
        }else{
          dispatch({ type: "notiMessageShow", payload: true });
          dispatch({ type: "notiMessage", payload: context.translation && context.translation.validpromo});
          dispatch({ type: "notiVarient", payload: "error" });
          dispatch({ type: "promo_disc", payload: 0 });
          dispatch({ type: "promo_code", payload: "" });
          hidemessage();
        }
      } catch (err) {
        console.error("Error in applyPromoCode" + err);
      }
    }
  }
  return (
    <>
      {state.notiMessageShow ? (
        <Alert
          description={state.notiMessage}
          type={state.notiVarient}
          closable
          onClose={() => dispatch({ type: "notiMessageShow", payload: false })}
          className="alertForm"
        >
          {" "}
          {state.notiMessage}
        </Alert>
      ) : (
        ""
      )}
      <Header setTab={update} page={"Review"}/>
      <Row className="statusCheck">
        <Col span={6} className="circleWrap completed">
          <span className="circle">
            <i className="icon icon-tick"></i>
          </span>
          <span className="text">{context.translation && context.translation.reviewBok}  <span className="displayNone-640"> &nbsp;{context.translation && context.translation.booking}</span></span>
        </Col>
        <Col span={6} className="circleWrap active">
          <span className="circle">{context.translation && context.translation.two}</span>
          <span className="text">{context.translation && context.translation.travellerDetail}</span>
        </Col>
        <Col span={6} className="circleWrap">
          <span className="circle">{context.translation && context.translation.three}</span>
          <span className="text">{context.translation && context.translation.payment}</span>
        </Col>
        <Col span={6} className="circleWrap">
          <span className="circle">{context.translation && context.translation.four}</span>
          <span className="text">{context.translation && context.translation.confirmation}</span>
        </Col>
      </Row>
      <div className="container">
        <div className="travellerDetails">
          <Row gutter={30} className="paxDetailsWrap">
            <Col span={17}>
              <div className="selectedFlights">
                <span>{context.translation && context.translation.one}</span>
                <Title level={4}>{context.translation && context.translation.belowFlight}</Title>
              </div>
              {selectedObj !== "" && (
                <div className="infoWrapper">
                  {Number(request.tt) !== 3 && (
                    <div className="infoSubWrapper">
                      {selectedObj && (
                        <>
                          <ResultSectionHeader
                            mapping={request.mappings}
                            sectors={sectors}
                            secType={"onward"}
                            secTypeIndex={0}
                            refund={selectedObj.ow ? selectedObj.ow.re : undefined}
                          />
                          {selectedObj.ow !== undefined && (
                            <TripDetails
                              flt={selectedObj.ow}
                              seg={selectedObj.ow.seg[0]}
                              mapping={request.mappings}
                            />
                          )}
                        </>
                      )}
                    </div>
                  )}
                  {Number(request.tt) === 2 && (
                    <div className="infoSubWrapper">
                      {selectedObj && (
                        <>
                          <ResultSectionHeader
                            mapping={request.mappings}
                            sectors={sectors}
                            secType={"arrival"}
                            secTypeIndex={0}
                            refund={selectedObj.rt ? selectedObj.rt.re : undefined}
                          />
                          {selectedObj.rt !== undefined && (
                            <TripDetails
                              flt={selectedObj.rt}
                              seg={selectedObj.rt.seg[0]}
                              mapping={request.mappings}
                            />
                          )}
                        </>
                      )}
                    </div>
                  )}
                  
                    {selectedObj.multi && selectedObj.multi.multiSelectOne && (
                      <>
                      <div className="infoSubWrapper">
                        <ResultSectionHeader
                          mapping={request.mappings}
                          sectors={sectors}
                          secType={"onward"}
                          secTypeIndex={0}
                          refund={
                            selectedObj.multi.multiSelectOne
                              ? selectedObj.multi.multiSelectOne.re
                              : undefined
                          }
                          flow={"REVIEW"}
                          trip={request.tt}
                        />
                        <TripDetails
                          flt={selectedObj.multi.multiSelectOne}
                          seg={selectedObj.multi.multiSelectOne.seg[0]}
                          mapping={request.mappings}
                        />
                        </div>
                      </>
                    )}
                  
                  
                    {selectedObj.multi && selectedObj.multi.multiSelectTwo && (
                      <>
                      <div className="infoSubWrapper">
                        <ResultSectionHeader
                          mapping={request.mappings}
                          sectors={sectors}
                          secType={"onward"}
                          secTypeIndex={1}
                          refund={
                            selectedObj.multi.multiSelectTwo
                              ? selectedObj.multi.multiSelectTwo.re
                              : undefined
                          }
                          flow={"REVIEW"}
                          trip={request.tt}
                        />
                        <TripDetails
                          flt={selectedObj.multi.multiSelectTwo}
                          seg={selectedObj.multi.multiSelectTwo.seg[0]}
                          mapping={request.mappings}
                        />
                        </div>
                      </>
                    )}
                  
                    {selectedObj.multi &&
                      selectedObj.multi.multiSelectThree && (
                        <>
                        <div className="infoSubWrapper">
                          <ResultSectionHeader
                            mapping={request.mappings}
                            sectors={sectors}
                            secType={"onward"}
                            secTypeIndex={2}
                            refund={
                              selectedObj.multi.multiSelectThree
                                ? selectedObj.multi.multiSelectThree.re
                                : undefined
                            }
                            flow={"REVIEW"}
                            trip={request.tt}
                          />
                          <TripDetails
                            flt={selectedObj.multi.multiSelectThree}
                            seg={selectedObj.multi.multiSelectThree.seg[0]}
                            mapping={request.mappings}
                          />
                          </div>
                        </>
                      )}
                  
                    {selectedObj.multi && selectedObj.multi.multiSelectFour && (
                      <>
                      <div className="infoSubWrapper">
                        <ResultSectionHeader
                          mapping={request.mappings}
                          sectors={sectors}
                          secType={"onward"}
                          secTypeIndex={3}
                          refund={
                            selectedObj.multi.multiSelectFour
                              ? selectedObj.multi.multiSelectFour.re
                              : undefined
                          }
                          flow={"REVIEW"}
                          trip={request.tt}
                        />
                        <TripDetails
                          flt={selectedObj.multi.multiSelectFour}
                          seg={selectedObj.multi.multiSelectFour.seg[0]}
                          mapping={request.mappings}
                        />
                        </div>
                      </>
                    )}
                  
                    {selectedObj.multi && selectedObj.multi.multiSelectFive && (
                      <>
                      <div className="infoSubWrapper">
                        <ResultSectionHeader
                          mapping={request.mappings}
                          sectors={sectors}
                          secType={"onward"}
                          secTypeIndex={4}
                          refund={
                            selectedObj.multi.multiSelectFive
                              ? selectedObj.multi.multiSelectFive.re
                              : undefined
                          }
                          flow={"REVIEW"}
                          trip={request.tt}
                        />
                        <TripDetails
                          flt={selectedObj.multi.multiSelectFive}
                          seg={selectedObj.multi.multiSelectFive.seg[0]}
                          mapping={request.mappings}
                        />
                        </div>
                      </>
                    )}
                  
                    {selectedObj.multi && selectedObj.multi.multiSelectSix && (
                      <>
                      <div className="infoSubWrapper">
                        <ResultSectionHeader
                          mapping={request.mappings}
                          sectors={sectors}
                          secType={"onward"}
                          secTypeIndex={5}
                          refund={
                            selectedObj.multi.multiSelectSix
                              ? selectedObj.multi.multiSelectSix.re
                              : undefined
                          }
                          flow={"REVIEW"}
                          trip={request.tt}
                        />
                        <TripDetails
                          flt={selectedObj.multi.multiSelectSix}
                          seg={selectedObj.multi.multiSelectSix.seg[0]}
                          mapping={request.mappings}
                        />
                        </div>
                      </>
                    )}
                </div>
              )}

              {/* <div className="insuranceWrapper">
                <InsurancePlans />
              </div> */}
              {selectedObj !== "" && selectedObj !== undefined && (
                <TravellerDetails
                  // enableFlynas={state.enableFlynas}
                  checkmandatoryflagnat={checkMandatoryFlagNat() ? "true" : "false"}
                  // checkMandatoryFlagDOB={""}
                  // deptDate={
                  //   request.origns[0].destorigins
                  //     ? request.origns[0].destorigins[0].seg[
                  //         request.origns[0].destorigins[0].seg.length - 1
                  //       ].dd
                  //     : request.origns[0].srcorigns[0].seg[0].dd
                  // }
                  srequest={request}
                  sendpsngersdata={passengrsData}
                  // travellersList={state.travelsList}
                  fareresp={fareruleResp}
                  // importFlow={state.enableImprtFlow}
                  passengeruniquekey={state.passengerUniqueKey}
                  paxlist={state.psngers}
                  oonwardroute={state.onwardRoute}
                  oonwardroutesrc={state.onwardRouteSrc}
                  oonwardroutedest={state.onwardRouteDest}
                  returnroute={state.returnRoute}
                  returnroutesrc={state.returnRouteSrc}
                  returnroutedest={state.returnRouteDest}
                  oonwardsupp={state.onwardSupp}
                  returnsupp={state.returnSupp}
                  selbagprice={updateBaggagePrice}
                  selmealprice={updateMealprice}
                  // seatprice={state.seatPrice}
                  selected={[
                    selectedObj !== "" && selectedObj.ow !== undefined
                      ? selectedObj.ow
                      : "",
                    selectedObj !== "" && selectedObj.rt !== undefined
                      ? selectedObj.rt
                      : "",
                    selectedObj !== "" &&
                    selectedObj.multi !== undefined &&
                    selectedObj.multi.multiSelectOne !== undefined
                      && selectedObj.multi.multiSelectOne,
                    selectedObj !== "" &&
                    selectedObj.multi !== undefined &&
                    selectedObj.multi.multiSelectTwo != undefined
                      && selectedObj.multi.multiSelectTwo,
                    selectedObj !== "" &&
                    selectedObj.multi !== undefined &&
                    selectedObj.multi.multiSelectThree != undefined
                      && selectedObj.multi.multiSelectThree,
                    selectedObj !== "" &&
                    selectedObj.multi !== undefined &&
                    selectedObj.multi.multiSelectFour != undefined
                      && selectedObj.multi.multiSelectFour,
                    selectedObj !== "" &&
                    selectedObj.multi !== undefined &&
                    selectedObj.multi.multiSelectFive != undefined
                      && selectedObj.multi.multiSelectFive,
                    selectedObj !== "" &&
                    selectedObj.multi !== undefined &&
                    selectedObj.multi.multiSelectSix != undefined
                      && selectedObj.multi.multiSelectSix,
                  ]}
                  mapping={request.mappings}
                  sectors={sectors}
                  indpassportenable={state.indigo_passport ? "true" : "false"}
                  base_sup_exrate={request.base_usr_exrate}
                />
              )}

              {/* //Seat Details  */}
              {(state.onwardSupp==="2" || state.returnSupp==="2" || state.onwardSupp==="3" || state.returnSupp==="3" || state.onwardSupp==="4" || state.returnSupp==="4"|| state.onwardSupp==="7" || state.returnSupp==="7") &&
                <SeatSelection base_sup_exrate={request.base_usr_exrate} disable={state.disable} paxList={state.psngers} srequest={request} sendSeatPrice={updateSeatPrice} sendSelectedSeats={updateSelectedSeats} onwardSupp={state.onwardSupp} returnSupp={state.returnSupp} disableseat = {state.disableseat} />
              }

            </Col>
            <Col span={7} className="marginTop30">
              <div className="rightSideSticky">
              <div className="outerSummary reviewPage">
                <div className="commonFlex fSummary">
                  <Title level={4}>
                    {context.translation && context.translation.fareSumy}
                    <img src={BrandIcon} alt="brandIcon" className="brandIcon"></img>
                  </Title>
                  <Link onClick={showDrawer} className="fDetails">
                    {context.translation && context.translation.flgtDetls}
                  </Link>
                  <Drawer placement="right" open={open} active={"tab1"} className="paxDetailsPopup">
                    <i className="icon icon-cancel" onClick={onClose}></i>
                    {selectedObj !== "" && (
                      <FlightDetailsPopup
                        flt={selectedObj.ow}
                        seg={selectedObj.ow ? selectedObj.ow.seg[0] : ""}
                        rtnflt={selectedObj.rt}
                        rtnseg={selectedObj.rt ? selectedObj.rt.seg[0] : ""}
                        sty={request.sty}
                        price={request.pr}
                        currency={request.cur}
                        sectors={sectors}
                        serviceFee={request.servicefee}
                        mapping={request.mappings}
                        secType={"onward"}
                        secTypeIndex={0}
                        trip={request.tt}
                        req={request}
                        paxpr={request.paxpr}
                        regionType={request.region_type}
                        pr={request.pr}
                        sf={request.servicefee}
                        //sf={sectors.sf ? sectors.sf[0].sf : 0}
                        refund={selectedObj.ow ? selectedObj.ow.re : undefined}
                        open={open}
                        //updatePrice={updatePrice}
                        flow={"REVIEW"}
                        selected={[
                          selectedObj !== "" && selectedObj.ow !== undefined
                            ? selectedObj.ow
                            : "",
                          selectedObj !== "" && selectedObj.rt !== undefined
                            ? selectedObj.rt
                            : "",
                          selectedObj !== "" &&
                          selectedObj.multi !== undefined &&
                          selectedObj.multi.multiSelectOne !== undefined
                            && selectedObj.multi.multiSelectOne,
                          selectedObj !== "" &&
                          selectedObj.multi !== undefined &&
                          selectedObj.multi.multiSelectTwo != undefined
                            && selectedObj.multi.multiSelectTwo,
                          selectedObj !== "" &&
                          selectedObj.multi !== undefined &&
                          selectedObj.multi.multiSelectThree != undefined
                            && selectedObj.multi.multiSelectThree,
                          selectedObj !== "" &&
                          selectedObj.multi !== undefined &&
                          selectedObj.multi.multiSelectFour != undefined
                            && selectedObj.multi.multiSelectFour,
                          selectedObj !== "" &&
                          selectedObj.multi !== undefined &&
                          selectedObj.multi.multiSelectFive != undefined
                            && selectedObj.multi.multiSelectFive,
                          selectedObj !== "" &&
                          selectedObj.multi !== undefined &&
                          selectedObj.multi.multiSelectSix != undefined
                            && selectedObj.multi.multiSelectSix,
                        ]}
                      />
                    )}
                  </Drawer>
                </div>
                <div className="summaryShape"></div>
                  <FlightFareDetails
                    paxpr={request.paxpr}
                    req={request}
                    pr={request.pr}
                    sf={request.servicefee}
                    flow={"REVIEW"}
                    baggagePrice={state.baggagePrice}
                    selMealprice={state.selMealprice}
                    sendAdtPrice={updateAdtPrice} 
                    sendChdPrice={updateChdPrice}
                    sendMealAdtPrice={updateAdtMealPrice} 
                    sendMealChdPrice={updateChdMealPrice}
                    seatPrice={state.seatPrice}
                    promoDisc={state.promo_disc}
                    base_usr_exrate={request.base_usr_exrate}
                  />
                  {/* <span className="shareIconWrap"><span className="shareInvoice">Share Invoice</span><i className="icon icon-share"></i></span> */}
                
              </div>
              {state.promoCodeList.length>0&&(
                <div className="applyPromoCodeMainWrap">
                <ApplyPromoCode product="Flight" sendPromo={aplyPromoCode} removeData={removeAppliedData} promo_codes={state.promoCodeList} promo_disc={state.promo_disc} base_usr_exrate={request.base_usr_exrate}/>
              </div>
              )}
              </div>
            </Col>
          </Row>
        </div>

   

        <Row gutter={30}>
          <Col span={17} className="colFullWidth">
            <div className="conBtnDetails">
            <Button
                  onClick={countineBooking}
                  //onClick={showModal}
                  type="primary"
                  className="cntBtn continueBtn"
                >
                  <span>{context.translation && context.translation.contPayment}</span>
                  <img src={BrandIcon} alt="brandIcon" className="brandIcon" />
                  {/* <i className="sprite btn-arrow-sprite"></i> */}
                </Button>
                <Modal
                  open={state.showPriceCheck}
                  onOk={continuePersistance}
                  onCancel={()=>showMoreFlights()}
                  okText={context.translation && context.translation.continue}
                  cancelText="Back to Results"
                  className="fareChangeAlert"
                  centered
                >
                  <Title className="modalTitle" level={4}>
                    <i className="icon icon-price-arrow"></i> {context.translation && context.translation.fareAlert}
                  </Title>
                  <span className="fareStatus">{context.translation && context.translation.currentFare}</span>
                  <div className="commonFlex dashedSeparator">
                    <div>
                      <span className="route">
                        {request.src_city} <i className="icon icon-ico-roundtrip"></i>{" "}
                        {request.dest_city}
                      </span>
                    </div>
                    <div className="bgGrayColor">
                      <span className="currencyCode">{request.cur}</span>
                      <span> <FormatCurrency value={state.oldprice * request.base_usr_exrate} className="currencyAmount"  /></span>
                    </div>
                  </div>
                  <span className="fareStatus">{context.translation && context.translation.changedFare}</span>
                  <div className="commonFlex solidSeparator">
                    <div>
                      <span className="route">
                      {request.src_city} <i className="icon icon-ico-roundtrip"></i>{" "}
                      {request.dest_city}
                      </span>
                    </div>
                    <div className="bgGrayColor">
                      <span className="currencyCode">{request.cur}</span> <span><FormatCurrency value={state.chnagedPrice * request.base_usr_exrate} className="currencyAmount"  /></span>
                    </div>
                  </div>
                </Modal>
            </div>
          </Col>
          <Col span={8}></Col>
        </Row>
      </div>

      {(isLoading || isAvaial) && (
        <LoaderN
          title={context.translation && context.translation.pleaseWait}
          subtitle={
            isLoading
              ? context.translation && context.translation.redirPayment
              : context.translation && context.translation.processingReq
          }
        />
      )}
      <Modal
        title=""
        open={showCheapest}
        footer={null}
        className="flightAvailabilityWrapper"
        centered
      >
        <Title level={4} className="flightGone">
          {context.translation && context.translation.flgtAvailGon}
        </Title>
        <Title level={5} className="currentFlight">
          {context.translation && context.translation.currNoAvail}.
        </Title>
        <div className="results-block">
          <div className="topSemicircle"></div>
          <div className="rb-contLeft">
            <div className="infoWrapper">
              
                {Number(request.tt) !== 3 && cheapestResponse && (
                  <>
                  <div className="infoSubWrapper">
                    <ResultSectionHeader
                      mapping={cheapestResponse.mappings}
                      sectors={sectors}
                      secType={"onward"}
                      secTypeIndex={0}
                      refund={cheapestResponse.origns[0].srcorigns[0].re}
                    />
                    <TripDetails
                      flt={cheapestResponse.origns[0].srcorigns[0]}
                      seg={cheapestResponse.origns[0].srcorigns[0].seg[0]}
                      mapping={request.mappings}
                    />
                    </div>
                  </>
                )}
              
                {Number(request.tt) === 2 && cheapestResponse && (
                  <>
                  <div className="infoSubWrapper">
                    <ResultSectionHeader
                      mapping={cheapestResponse.mappings}
                      sectors={sectors}
                      secType={"arrival"}
                      secTypeIndex={0}
                      refund={
                        cheapestResponse.origns[0].destorigins !== undefined &&
                        cheapestResponse.origns[0].destorigins[0].re
                      }
                    />
                    <TripDetails
                      flt={
                        cheapestResponse.origns[0].destorigins !== undefined &&
                        cheapestResponse.origns[0].destorigins[0]
                      }
                      seg={
                        cheapestResponse.origns[0].destorigins !== undefined &&
                        cheapestResponse.origns[0].destorigins[0].seg[0]
                      }
                      mapping={cheapestResponse.mappings}
                    />
                    </div>
                  </>
                )}
              {Number(request.tt) === 3 &&
                cheapestResponse &&
                cheapestResponse.origns !== undefined && (
                  <div className="infoSubWrapper">
                    {cheapestResponse.origns[0].srcorigns.map((src, idx) => (
                      <>
                        <ResultSectionHeader
                          mapping={request.mappings}
                          sectors={sectors}
                          secType={"onward"}
                          secTypeIndex={0}
                          refund={src.re}
                          flow={"REVIEW"}
                        />
                        <TripDetails
                          flt={src}
                          seg={src.seg[0]}
                          mapping={request.mappings}
                        />
                      </>
                    ))}
                    
                  </div>
                )}

            </div>
          </div>
          <div className="rb-contRight">
            <div className="rb-price">
              {cheapestResponse !== "" &&
                cheapestResponse.origns.length > 0 &&
                cheapestResponse.origns[0].pr !== undefined && (
                  <>
                    <Title level={5}>
                      {request.cur}{" "}
                      <span><FormatCurrency value={cheapestResponse.origns[0].pr.tf * request.base_usr_exrate} /></span>
                    </Title>
                    <span className="travellerPrice">({context.translation && context.translation.oneTraveller})</span>
                  </>
                )}
              <Button
                onClick={continueChapestBooking}
                type="primary"
                className="cntBtn continueBtn"
              >
                <span>{context.translation && context.translation.bokFlgt}</span>
                <i className="sprite btn-arrow-sprite"></i>
              </Button>
            </div>
          </div>
          <div className="botSemicircle"></div>
          <div className="botSemicircleCover"></div>
        </div>

        <Button
          onClick={()=>showMoreFlights()}
          type="primary"
          className="findMoreFlightBtn"
        >
          {context.translation && context.translation.findMoreFlight}
        </Button>
      </Modal>
     
      <Modal title="" centered open={availabilityPop} onOk={""} onCancel={""} footer={null} className="alertWrapper">
        <div className="alertTitle"><i className="icon icon-alert-popup"></i> {context.translation && context.translation.alert}</div>
        <div className="alertContent">{context.translation && context.translation.availGone}</div>
        <Button
          type="primary"
          className="availabilityGoneItinarary"
          onClick={()=>showMoreFlights()}
        >
           {context.translation && context.translation.moreFlight}
        </Button>
      </Modal>

      <Modal title="" centered open={state.prepersist} onOk={""} onCancel={""} footer={null} className="wrongAlertWrapper">
      <div className="oopsTitle"><i className="icon icon-alert-popup"></i> {context.translation && context.translation.oops}</div>
      <div className="wrongContent">{context.translation && context.translation.wentWrg}</div>
      <Button
          type="primary"
          className="wrongBtn"
          onClick={()=>showMoreFlights()}
        >
           {context.translation && context.translation.moreFlight}
        </Button>
      </Modal>
      <Modal title="" open={state.wego_failed} onOk={""} onCancel={""} footer={null} className="alertWrapper">
        <div className="alertTitle"><i className="icon icon-alert-popup"></i> {context.translation && context.translation.alert}</div>
        <div className="alertContent">{context.translation && context.translation.availGone}</div>
        <Button
          type="primary"
          className="availabilityGoneItinarary"
          onClick={()=>loadSRPResults()}
        >
           {context.translation && context.translation.moreFlight}
        </Button>
      </Modal>
      <SecureGuarantee />
      <Footer />
    </>
  );
}

export default FlightReviewPage;
