import React, { useCallback, useEffect, useReducer, useState,useContext,useRef } from "react";
import useOnclickOutside from "react-cool-onclickoutside";
import { Context } from "../../../../App";
import { Button, Col, Form, Input, Row, Select, Tag, Typography } from "antd";
import Link from "antd/es/typography/Link";

const { Title } = Typography;
const { Option } = Select;

const initialState = {
  adultCount: 1,
  childCount: 0,
  adtmax: 6,
  chdmax: 5,
  noOfRooms: 1,
  childAgeArray: [],
  roomsArray: [],
  age: 0,
  removedRm: 0,
};
//=====================================
// Reducer function for userReact Hook
// which will update the state
//=====================================
const reducer = (state, action) => {
  switch (action.type) {
    case "editBranch":
      return Object.assign(state, action.payload);
    default:
      return { ...state, [action.type]: action.payload };
  }
};

function AddGuestDetails(props) {
  const [context, setContext] = useContext(Context)
  const {
    // showHideGuest,
    // showGuests,
    sendGuestCount,
    sendRoomsCount,
    sendRoomsInfo,
    roomsInfo,
    closeBtn,
    ismodify,
  } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const [addRooms, setAddRooms] = useState(false);
  const [chdList, setchdList] = useState([]);
  const [roomsList, setRoomsList] = useState([]);
  const [showGuests, setShowGuests] = useState(false);
  const [guestCnt, setGuestCnt] = useState(1);
  const inputRef = useRef(null);
  
  useEffect(() => {
    if (state.roomsArray.length === 0) {
      const payload = {
        rmno: 1,
        adt: 1,
        chd: 0,
        chdArray: [],
        chdAges: [],
      };
      roomsList.push(payload);
      dispatch({ type: "roomsArray", payload: roomsList });
    }
  }, [state.roomsArray]);

  useEffect(() => {
    if (props.roomsInfo.length !== 0) {
      dispatch({ type: "roomsArray", payload: props.roomsInfo });
      dispatch({ type: "noOfRooms", payload: props.roomsInfo.length });
      let guestCnt = 0;
      for(let val of props.roomsInfo) {
        guestCnt = guestCnt + Number(val.adt) + Number(val.chd);
      }
      setGuestCnt(guestCnt);
    }
  }, [props.roomsInfo]);

  const handleIncrementAdlt = (rmno) => () => {
    let adtsCount = 0;
    let chdCounts = 0;
    //if(props.ismodify){
    for (let val of state.roomsArray) {
      if (adtsCount === 0) {
        adtsCount = val.adt;
        chdCounts = val.chd;
      } else {
        adtsCount = adtsCount + val.adt;
        chdCounts = chdCounts + val.chd;
      }
    }
    //}
    for (let val of state.roomsArray) {
      if (val.adt < 6 && val.rmno === rmno) {
        val.adt = val.adt + 1;
        dispatch({ type: "adultCount", payload: state.adultCount + 1 });
        props.sendGuestCount(adtsCount + 1 + chdCounts);
        setGuestCnt(adtsCount + 1 + chdCounts);
      }
    }
    props.sendRoomsInfo(state.roomsArray);
  };
  const handleDecrementAdlt = (rmno) => () => {
    let adtsCount = 0;
    let chdCounts = 0;
    //if(props.ismodify){
    for (let val of state.roomsArray) {
      if (adtsCount === 0) {
        adtsCount = val.adt;
        chdCounts = val.chd;
      } else {
        adtsCount = adtsCount + val.adt;
        chdCounts = chdCounts + val.chd;
      }
    }
    //}
    for (let val of state.roomsArray) {
      if (val.adt > 1 && val.rmno === rmno) {
        val.adt = val.adt - 1;
        dispatch({ type: "adultCount", payload: state.adultCount - 1 });
        props.sendGuestCount(adtsCount - 1 + chdCounts);
        setGuestCnt(adtsCount - 1 + chdCounts);
      }
    }
    props.sendRoomsInfo(state.roomsArray);
  };
  const handleIncrementChild = (rmno) => () => {
    let adtsCount = 0;
    let chdCounts = 0;
    //if(props.ismodify){
    for (let val of state.roomsArray) {
      if (adtsCount === 0) {
        adtsCount = val.adt;
        chdCounts = val.chd;
      } else {
        adtsCount = adtsCount + val.adt;
        chdCounts = chdCounts + val.chd;
      }
    }
    // }
    for (let val of state.roomsArray) {
      if (val.chd < 5 && val.rmno === rmno) {
        val.chd = val.chd + 1;
        const pay = {
          chd: val.chd,
          age: 1,
        };
        val.chdArray.push(pay);
        //val.chdAges.push(1);
        props.sendGuestCount(adtsCount + chdCounts + 1);
        setGuestCnt(adtsCount + chdCounts + 1);
        dispatch({ type: "childCount", payload: state.childCount + 1 });
      }
    }
    chdList.push(state.childCount + 1);
    dispatch({ type: "childAgeArray", payload: chdList });
    props.sendRoomsInfo(state.roomsArray);
  };
  const handleDecrementChild = (rmno) => () => {
    let adtsCount = 0;
    let chdCounts = 0;
    //if(props.ismodify){
    for (let val of state.roomsArray) {
      if (adtsCount === 0) {
        adtsCount = val.adt;
        chdCounts = val.chd;
      } else {
        adtsCount = adtsCount + val.adt;
        chdCounts = chdCounts + val.chd;
      }
    }
    //}
    for (let val of state.roomsArray) {
      if (val.chd !== 0 && val.rmno === rmno) {
        val.chd = val.chd - 1;
        val.chdArray.splice(val.chd);
        val.chdAges.splice(1);
        dispatch({ type: "childCount", payload: state.childCount - 1 });
        props.sendGuestCount(adtsCount + chdCounts - 1);
        setGuestCnt(adtsCount + chdCounts - 1);
      }
    }
    props.sendRoomsInfo(state.roomsArray);
  };

  const ChildernAge = [
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
    { value: "5", label: "5" },
    { value: "6", label: "6" },
    { value: "7", label: "7" },
    { value: "8", label: "8" },
    { value: "9", label: "9" },
    { value: "10", label: "10" },
    { value: "11", label: "11" },
    { value: "12", label: "12" },
    { value: "13", label: "13" },
    { value: "14", label: "14" },
    { value: "15", label: "15" },
    { value: "16", label: "16" },
    { value: "17", label: "17" },
  ];

  const handleChange = (room, index) => (e) => {
    let ages = [];
    for (let val of state.roomsArray) {
      if (val.rmno === room.rmno && val.chd === room.chd) {
        let count = 1;
        for (let chd of val.chdArray) {
          if (count === index) {
            chd.age = Number(e);
            val.chdAges.push(chd.age);
          }
          count = count + 1;
        }
      }
    }
    props.sendRoomsInfo(state.roomsArray);
  };

  const addMoreRooms = (e) => {
    setAddRooms(true);
    let roomNo = 0;
    let list = state.roomsArray;
    let adtsCount = 0;
    let chdCounts = 0;
    for (let val of list) {
      if (adtsCount === 0) {
        adtsCount = val.adt;
        chdCounts = val.chd;
      } else {
        adtsCount = adtsCount + val.adt;
        chdCounts = chdCounts + val.chd;
      }
      roomNo = roomNo + 1;
    }
    const payload = {
      rmno: list.length + 1,
      adt: 1,
      chd: 0,
      chdArray: [],
      chdAges: [],
    };
    list.push(payload);

    dispatch({ type: "adultCount", payload: adtsCount + 1 });
    dispatch({ type: "noOfRooms", payload: list.length });
    props.sendGuestCount(adtsCount + 1 + chdCounts);
    setGuestCnt(adtsCount + 1 + chdCounts);
    props.sendRoomsCount(list.length);
    dispatch({ type: "roomsArray", payload: list });
    props.sendRoomsInfo(list);
  };
  const dltMoreRooms = (room) => (e) => {
    setAddRooms(false);
    let rcount = state.roomsArray.length - 1;
    let adtsCount = 0;
    let chdCounts = 0;
    for (let val of state.roomsArray) {
      //if(props.ismodify){
      if (adtsCount === 0) {
        adtsCount = val.adt;
        chdCounts = val.chd;
      } else {
        adtsCount = adtsCount + val.adt;
        chdCounts = chdCounts + val.chd;
      }
      //}
    }
    state.roomsArray.splice(
      state.roomsArray.findIndex((a) => a.rmno === room.rmno),
      1
    );
    let count = 1;
    for (let val of state.roomsArray) {
      val.rmno = count;
      count = count + 1;
    }
    dispatch({ type: "noOfRooms", payload: rcount });
    dispatch({ type: "roomsArray", payload: state.roomsArray });
    dispatch({ type: "removedRm", payload: room });
    dispatch({ type: "adultCount", payload: state.adultCount - room.adt });
    dispatch({ type: "childCount", payload: state.childCount - room.chd });
    //if(props.ismodify){
    props.sendGuestCount(adtsCount - room.adt + chdCounts - room.chd);
    setGuestCnt(adtsCount - room.adt + chdCounts - room.chd);
    dispatch({ type: "adultCount", payload: adtsCount - room.adt });
    dispatch({ type: "childCount", payload: chdCounts - room.chd });
    // }else{
    //   props.sendGuestCount(state.adultCount-room.adt + state.childCount-room.chd);
    // }
    props.sendRoomsCount(rcount);
  };

  // const ref = useOnclickOutside(() => {
  //   setShowGuests(false);
  // });

  const showHideGuest = () => {
    setShowGuests(!showGuests);
    props.focus(false);
    props.predectiveSearch(false);
  };
  
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutsideRangePicker);
    return () => {
      document.removeEventListener("mousedown", handleClickOutsideRangePicker);
    };
  }, []);

  //For handling the focus on dates ,trip types and travellers
  const handleClickOutsideRangePicker = (event) => {
    if (
      inputRef.current &&
      inputRef.current instanceof HTMLElement &&
      !inputRef.current.contains(event.target)
    ) {
      if (
        event.target.classList.contains("whiteBg") ||
        event.target.classList.contains("ant-row") ||
        event.target.classList.contains("ant-col") ||
        event.target.classList.contains("ant-picker-input") ||
        event.target.tagName === "INPUT" ||
        event.target.classList.contains("container") ||
        event.target.classList.contains("originDestinationBox") ||
        event.target.classList.contains("select-trips") ||
        event.target.classList.contains("searchPanel") ||
        event.target.classList.contains("select-trips") ||
        event.target.classList.contains("signInFocus") ||
        event.target.classList.contains("pRelative") ||
        event.target.classList.contains("flightSearch") ||
        event.target.classList.contains("mostlyFlownRoutes") ||
        event.target.classList.contains("homeSearch") ||
        event.target.classList.contains("cust-title") ||
        event.target.classList.contains("effect-9") ||
        event.target.classList.contains("hotelRoomSelection") ||
        event.target.classList.contains("scrollspy") ||
        event.target.classList.contains("modifySearch") ||
        event.target.classList.contains("ant-picker") ||
        event.target.matches("#chooseRoom") ||
        event.target.classList.contains("filterButtonWrap") ||
        event.target.classList.contains("hotelModifyClick") ||
        event.target.classList.contains("header") ||
        event.target.classList.contains("hotelRoomFilter") ||
        event.target.classList.contains("hotelFilterHeader") ||
        event.target.classList.contains("hotelPrice") ||
        event.target.classList.contains("modifyTitle") ||
        event.target.classList.contains("roomCancelRefund") ||
        event.target.classList.contains("commonFlex") ||
        event.target.classList.contains("hotelSelect") ||
        event.target.classList.contains("boardBasis") ||
        event.target.classList.contains("roomOccupancy") ||
        event.target.classList.contains("hotelDetailsFilter") ||
        event.target.classList.contains("showOnlyRooms") ||
        event.target.classList.contains("ant-btn") ||
        event.target.classList.contains("policyCancel") ||
        event.target.classList.contains("empty-image") ||
        event.target.classList.contains("hoyelImage") ||
        event.target.classList.contains("chooseOne") ||
        event.target.classList.contains("ant-radio-inner") ||
        event.target.classList.contains("yourSelectionTitle") ||
        event.target.classList.contains("occupancyTitle") ||
        event.target.classList.contains("occupancySelection") ||
        event.target.classList.contains("hotelRoomOccupancyWrapper") ||
        event.target.classList.contains("currencyValue") ||
        event.target.classList.contains("totalPrice") ||
        event.target.classList.contains("adultChildSeparator") ||
        event.target.classList.contains("occupancy") ||
        event.target.classList.contains("room") ||
        event.target.classList.contains("rightSideDetails") ||
        event.target.classList.contains("horozontalDashedDivider") ||
        event.target.classList.contains("bedType") ||
        event.target.classList.contains("roomType") ||
        event.target.classList.contains("bedRoomType")
      ) {
        dispatch({ type: "showResults", payload: false });
        props.focus(false);
        setShowGuests(false);
      }
    }
  };

  return (
    <>
      <div
        onClick={showHideGuest}
        className={"guest-title " + (showGuests && "active")}
        ref={inputRef}
      >
        {" "}
        <i className="icon-ico-add-travellers1"></i>{" "}
        <span>
          <label>{state.noOfRooms} {state.noOfRooms == 1 ? context.translation && context.translation.room : context.translation && context.translation.rooms}, {guestCnt} {guestCnt == 1 ? context.translation && context.translation.guest : context.translation && context.translation.guests}</label>
        </span>
      </div>
      <div
        className={
          "roomGuest " +
          (showGuests && "active ") +
          (state.noOfRooms > 1 || state.roomsArray.length > 1 ? "rgWrap " : "")
        }
      >
        {state.roomsArray.length !== 0 &&
          state.roomsArray.map((room, idx) => (
            <div className="roomWrapper" key={idx}>
              <div className="titleWrapper">
                <Title level={5}>
                  <span className="titleRoom">{context.translation && context.translation.room} {room.rmno}</span>
                  {room.rmno !== 1 && (
                    <Link onClick={dltMoreRooms(room)} className="remove">
                      {context.translation && context.translation.remove}
                    </Link>
                  )}
                </Title>
              </div>
              <ul>
                <li>
                  <label>
                  {context.translation && context.translation.adts} <small>
                    {/* ({context.translation && context.translation.adtYrs}) */}
                    {context.translation && context.translation.ageoe}
                    </small>
                  </label>
                  <div className="count">
                    <Button
                      type="default"
                      onClick={handleDecrementAdlt(room.rmno)}
                      disabled={room.adt === 1 ? true : false}
                    >
                      -
                    </Button>
                    <Input readOnly value={room.adt} />
                    <Button
                      type="default"
                      onClick={handleIncrementAdlt(room.rmno)}
                    >
                      +
                    </Button>
                  </div>
                </li>
                <li>
                  <label>
                  {context.translation && context.translation.chds} <small>
                    {context.translation && context.translation.ageos}
                    </small>
                  </label>
                  <div className="count">
                    <Button
                      type="default"
                      onClick={handleDecrementChild(room.rmno)}
                      disabled={room.chd === 0 ? true : false}
                    >
                      -
                    </Button>
                    <Input readOnly value={room.chd} />
                    <Button
                      type="default"
                      onClick={handleIncrementChild(room.rmno)}
                    >
                      +
                    </Button>
                  </div>
                </li>
              </ul>
              <Row gutter={15}>
                {room.chdArray.map((chd, index) => (
                  <Col span={8} className="childAgeWrapper" key={index}>
                    <Form.Item>
                      <label>{context.translation && context.translation.chd} {index + 1} {context.translation && context.translation.age}</label>
                      <Select
                        name="child"
                        value={[{ value: chd.age, label: chd.age }]}
                        className="guestBoxShadow"
                        options={ChildernAge}
                        onChange={handleChange(room, index + 1)}
                        listHeight={160}
                      ></Select>
                    </Form.Item>
                  </Col>
                ))}
              </Row>
            </div>
          ))}
        {state.noOfRooms < 8 && state.roomsArray.length < 8 && (
          <div className="addRoom" onClick={addMoreRooms}>
            + {context.translation && context.translation.addAnotherrom}
          </div>
        )}
      </div>
    </>
  );
}

export default AddGuestDetails;
