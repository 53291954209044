import React, { useState, useRef,useContext,useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Context } from "../../../../App";
import { Button, Modal, Space, Input, Typography, Alert, Spin, Row, Col, Radio } from "antd";
import Footer from "../../../common/Footer";
import Header from "../../../common/Header";
import SecureGuarantee from "../../../homepage/SecureGuarantee";
import FlightSummary from "./FlightSummary";
import PassengersDetails from "./PassengersDetails";
import FareDetails from "./FareDetails";
import PaymentDetails from "./PaymentDetails";
import DateUtils from "../../../utilities/DateUtils";
import { useLocation } from "react-router-dom";
import { emailValidation } from "../../../utilities/FormValidations";
import FlightBookService from "../../../../services/flights/FlightBookService";
import FlightItinerarypdf from "./FlightItinerarypdf";
import FormatCurrency from "../../../utilities/FormatCurrency";
import html2pdf from "html2pdf.js";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import HotelImage from "../../../../assets/img/pupular-hotels/Golden-Tulip-Doha.png";
import HotelBrandIcon from "../../../../assets/gif/Forever/White/H_WHITE_FOREVER.gif";
import HotelPredective from "../../hotels/search/predective/HotelPredective";
import Utilities from "../../../utilities/Utilities";
// let resp = require("../common/masterData/Test.json");
export const HTL_REQUEST = "htlRequest";

const { Title } = Typography;
const FLIGHT_SEARCH_CID = process.env.REACT_APP_FLIGHT_SEARCH_CID;
const WEGO_PIXEL_URL = process.env.REACT_APP_WEGO_PIXEL_URL;
const CONVERSION_ID = process.env.REACT_APP_WEGO_CONVERSION_ID;


function FlightConfirmation() {
  const [context, setContext] = useContext(Context)
  const location = useLocation();
  const navigate = useNavigate();   
  const [book_resp, setBookResep] = useState(
    location.state !== undefined &&
      location.state?.resp !== undefined &&
      location.state?.resp !== "" && location.state?.resp!==null
      ? location.state?.resp
      : ""
  );
  //const [book_resp, setBookResep] = useState(resp.bookRs.data[0]);
  const [email, setEmail] = useState("");
  const [notiMessage, setNotiMessage] = useState("");
  const [notiMessageShow, setNotiMessageShow] = useState(false);
  const [notiVarient, setNotiVarient] = useState();
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [hotelsList, setHotelsList] = useState([]);
  const [cur, setCur] = useState([]);
  const [cityName,setCityName] = useState("");
  const [cin, setCin] = useState("");
  const [cout, setCout] = useState("");
  const [supToBaseEx, setSupToBaseExRate] = useState(1);
  const [baseToSupExRate, setBaseToSupExRate] = useState(1);
  const [supCur, setSupCur] = useState("");
  const [region_type, setRegion] = useState("");  
  const [nonights, setNoNights] = useState(1);  
  const [noadts, setNoAdts] = useState(1);   
  const [noRooms, setNoRooms] = useState(1);  

  useEffect(() => {
    window.scrollTo(0, 0)

  }, []);

  useEffect(() => {
    window.addEventListener('popstate', () => {
      return window.location="/";
      // navigate("/");
    })
  }, [])

  useEffect(() => {
    loadHotelResults();
    sendWegoPixel();
  }, [book_resp])

  const sendWegoPixel=()=>{
    if(book_resp!==undefined && book_resp.click_id!=="" && book_resp.source_type!=="" && book_resp.source_type==="WEGO" && book_resp.pay.length > 0){
      let wegourl=WEGO_PIXEL_URL+"conversion_id="+CONVERSION_ID+"&click_id=["+book_resp.click_id+"]&comm_currency_code=["+book_resp.cur+"]&bv_currency_code=["+book_resp.cur+"]&transaction_id=["+book_resp.booking_id+"]&commission=["+0+"]&total_booking_value=["+(book_resp.pay[0].amt * book_resp.base_usr_exrate).toFixed(2)+"]&status=["+book_resp.status.toLowerCase()+"]";
      console.log("PIXEL URL : "+wegourl)
      return (
      <>
        <img src={wegourl} width='1' height='1' border='0' alt=''></img>
      </>
    );
    }
  }

  const loadHotelResults=()=>{
    if(book_resp!=="" && book_resp.mappings.length!==0){
      let dest = book_resp.mappings.filter(
        (obj) => obj.airportCode === book_resp.dest_code && book_resp.dest_country === obj.countryCode
      );
      try {
        if(dest.length!==0 && Number(book_resp.tt)!==3){
          HotelPredective.retrieveSearch(dest[0].cityName).then((data) => {
            if(data.data.data!=="" && data.data.data.city.length){
              setHotelsList([])
              setCityName(dest[0].cityName);
              var region = localStorage.getItem("REGION_COUNTRY")!==null && localStorage.getItem("REGION_COUNTRY")==="IN"?"India":"Qatar";
              let arriDt= "";
              if(Number(book_resp.tt)!==3){
                arriDt=book_resp.origns[0].srcorigns[0].seg[
                  book_resp.origns[0].srcorigns[0].seg.length - 1
                ].ad;
              }
              const req={
                  "cin": arriDt,
                  "cid": FLIGHT_SEARCH_CID,
                  "city_id":data.data.data.city[0].expediaCityId,
                  "cur": book_resp.cur,
                  "country_code": region==="India"?"IN":"QA",
                  "region_type": region
              }
              let tokenKey = Utilities.generateAPIToken(req);
              FlightBookService.loadCrossSellHotels(req,tokenKey).then((response) => {
                if (response.data !== undefined && response.data.suc) {
                  setHotelsList(response.data.data.prpts)
                  setCin(response.data.data?.cin);
                  setCout(response.data.data?.cout);
                  setSupToBaseExRate(response.data.data?.sup_base_exrate);
                  setBaseToSupExRate(response.data.data?.base_sup_exrate);
                  setSupCur(response.data.data?.sup_cur);
                  setRegion(response.data.data?.conf.region_type);
                  setNoNights(response.data.data?.conf.per_night);
                  setNoAdts(response.data.data?.conf.per_adult);
                  setNoRooms(response.data.data?.conf.per_room)
                   }
                })
                .catch(function (error) {
                  console.error("Error:" + error);      
                });
            }
          }).catch(function (error) {
            console.error("Error:" + error);      
          });
        }        
      } catch (error) {
        console.error("Error:" + error);            
      }
    }
  }

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setEmail("");
  };

  const validateEmail = () => {
    if (email === "") {
      setNotiMessageShow(true);
      setNotiMessage(context.translation && context.translation.plzEnterem);
      setNotiVarient("error");
      hidemessage();
      return false;
    } else if (!emailValidation(email)) {
      setNotiMessageShow(true);
      setNotiMessage(context.translation && context.translation.plzEntervem);
      setNotiVarient("error");
      hidemessage();
      return false;
    }
    return true;
  }

  const componentRef = useRef();
  // const opendlevent =
  //   useReactToPrint({
  //     content: () => componentRef.current,
  //     documentTitle: book_resp !== undefined && book_resp.booking_id !== undefined && ("FLIGHT_ITINERARY-" + book_resp.booking_id)
  //   }
  // );

  const itineraryPDF = () => {

    // Create HTML content for PDF
    const pdfContent = componentRef.current;

    // Configure PDF options
    const pdfOptions = {
      margin: [8,5],
      filename: book_resp !== undefined && book_resp.booking_id !== undefined && ("FLIGHT_ITINERARY-" + book_resp.booking_id),
      image: { type: "png", quality: 0.98 },
      html2canvas: { scale: 2, allowTaint: false, useCORS: true, letterRendering: true },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
      // pagebreak: { mode: ['avoid-all', 'css', 'legacy'] }
    };

    // Generate PDF
    html2pdf(pdfContent, pdfOptions);
  };

  const handleChange = (e) => {
    e.preventDefault();
    let trimmedEmail = e.target.value.trim();
    setEmail(trimmedEmail);
  }

  const shareItinerary = (data) => {
    setLoading(true);
    let tokenKey = Utilities.generateAPIToken(data);
    FlightBookService.sendConfirmationMail(data,tokenKey).then((res) => {
      if (res.data.success) {
        setLoading(false);
        setIsModalOpen(false);
        setEmail("");
        setNotiMessageShow(true);
        setNotiMessage(context.translation && context.translation.emailSentsuc)
        setNotiVarient("success")
        hidemessage();
        setEmail("");
      } else {
        setLoading(false);
        setIsModalOpen(false);
        setNotiMessageShow(true);
        setNotiMessage(context.translation && context.translation.failMail)
        setNotiVarient("error")
        hidemessage();
        setEmail("");
      }
    })
    .catch(function (error) {
      console.error("Exception occured in Sending confirmation mail function:" + error);      
      setIsModalOpen(false);
      setEmail("");
    });
  };

  const sendConfirmationEmail = () => {
    if (validateEmail()) {
      if (
        book_resp.status == "Confirmed" ||
        book_resp.status == "Partially Confirmed"
      ) {
        if (book_resp.sty == "normal") {
          if (
            book_resp.sup == "0"
          ) {
            const payload = {              
              booking_id: book_resp.booking_id,
              tid: book_resp.tid,
              email_id: email,
              sup: "0",
              jtype: book_resp.tt == "1" ? "O" : book_resp.tt == "2" && "O/R",
              ftype: book_resp.sty,
              is_eticket: true,
              is_invoice: true,
              // is_eticket: state.conf_mail,
              // is_invoice: state.inv_mail,
            };
            shareItinerary(payload);
          } else {
            const payload1 = {
              booking_id: book_resp.booking_id,
              tid: book_resp.tt == "1" ? book_resp.tid : book_resp.tt == "2" && book_resp.origns[0].srcorigns[0].tid == book_resp.origns[0].destorigins[0].tid ? book_resp.origns[0].srcorigns[0].tid : book_resp.tt == "2" && book_resp.origns[0].srcorigns[0].tid +
                        "/" + book_resp.origns[0].destorigins[0].tid,
              email_id: email,  
              sup: book_resp.sup,
              jtype: book_resp.tt == "1" ? "O" : book_resp.tt == "2" && "O/R",
              ftype: book_resp.sty,
              is_eticket: true,
              is_invoice: true,
            };
            shareItinerary(payload1);
          }
          // else {
          //   const payload1 = {
          //     booking_id: book_resp.booking_id,
          //     tid: book_resp.tt == "1" ? book_resp.tid : book_resp.tid +
          //           "/" + book_resp.origns[0].srcorigns[0].tid,
          //     email_id: email,  
          //     sup: book_resp.sup,
          //     jtype: "O",
          //     ftype: book_resp.sty,
          //     is_eticket: true,
          //     is_invoice: false,
          //   };
          //   shareItinerary(payload1);
          //   if (book_resp.tt == "2") {
          //     setTimeout(() => {
          //       const payload2 = {
          //         booking_id: book_resp.booking_id,
          //         tid: book_resp.tid +
          //           "/" +
          //           book_resp.origns[0].destorigins[0].tid,
          //         email_id: email,  
          //         sup: book_resp.sup,
          //         jtype: "R",
          //         ftype: book_resp.sty,
          //         is_eticket: true,
          //         is_invoice: false,
          //       };
          //       shareItinerary(payload2);
          //     }, 10000);
          //   }
          // }
        } else {
          const payload = {
            booking_id: book_resp.booking_id,
            tid: book_resp.origns[0].srcorigns[0].tid,
            email_id: email,  
            sup: book_resp.origns[0].srcorigns[0].sup,
            jtype: "O",
            ftype: book_resp.sty,
            is_eticket: true,
            is_invoice: true,
          };
          shareItinerary(payload);
          setTimeout(() => {
            const payload = {
              booking_id: book_resp.booking_id,
              tid: book_resp.origns[0].destorigins[0].tid,
              email_id: email,  
              sup: book_resp.origns[0].destorigins[0].sup,
              jtype: "R",
              ftype: book_resp.sty,
              is_eticket: true,
              is_invoice: true,
            };
            shareItinerary(payload);
          }, 1000);
        }
      }
    }
  };
  
  const hidemessage = () => {
    setTimeout(function () {
      setNotiMessageShow(false);
      setNotiMessage("");
      setNotiVarient("");
    }, 3000);
  };

  const selectHotel = (obj) => {
    obj.expediapid = obj.pid;
    obj.searchType = "city";
    obj.flt_booking_id = book_resp.booking_id;
    let htlData = {...obj, cin, cout}
    let htl = JSON.stringify(htlData);
    var w = window.open("/hotel/details");
    w.htl = htl;
    let roomInfo = []
    for (let i = 0; i < noRooms; i++) {
      roomInfo.push({
        adt:noadts,
        chd:0,
        chdAges : [],
        chdArray : [],
        rmno : i + 1
      })
    }
    let searchResp = JSON.parse(localStorage.getItem(HTL_REQUEST));
    let cinCout = {...searchResp, 
      cin: cin, cout: cout, 
      sup_base_exrate: supToBaseEx, 
      base_sup_exrate: baseToSupExRate, 
      sup_cur: supCur,
      region: region_type,
      nights: nonights,
      roomsInfo: roomInfo,
    };
    localStorage.setItem(HTL_REQUEST, JSON.stringify(cinCout));
    w.crosssell = true;
    w.cmpid = FLIGHT_SEARCH_CID;
    w.isPackage = false;
  };

  const update=(type)=>{
  }

  var settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: false,
    rtl: false,
    nextArrow: false,
    prevArrow: false,
    dots: true,
    responsive: [
      {
        breakpoint: 1367,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          nextArrow: false,
          prevArrow: false,
        }
      },
      {
        breakpoint: 480,
        settings: {
          centerMode: true,
          centerPadding: "30px 20px",
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };


  return (
    <>
      {notiMessageShow ? (
        <Alert
          description={notiMessage}
          type={notiVarient}
          closable
          onClose={() => setNotiMessageShow(false)}
          className="alertForm"
          // showIcon
          style={{zIndex:99999}}
        >
          {notiMessage}
        </Alert>
      ) : (
        ""
      )}
      <Header setTab={update} page={"Confirm"}/>
      <div className="container">
        <div className="confirmation">
          <div className="paxBookingWrap">
            <div className="paxBooking">
              {(book_resp.status.toUpperCase() == "CONFIRMED" || book_resp.status.toUpperCase().split(" ").join("") == "PARTIALLYCONFIRMED") && (
                <>
                  <Title level={3}>
                    {context.translation && context.translation.uFlightbok}{" "}
                    {book_resp && (
                      <span>
                        {(book_resp.status.toUpperCase() == "CONFIRMED")? context.translation && context.translation.confirmed : context.translation && context.translation.parConfirmed} <i className="icon icon-tick"></i>
                      </span>
                    )}
                  </Title>
                  <p>
                    {context.translation && context.translation.confSentEmail}.
                  </p>
                </>
              )}
              {(book_resp.status.toUpperCase().split(" ").join("") == "TICKETINGFAILED" || book_resp.status.toUpperCase() == "FAILED") && (
                <>
                <Title level={3}>
                  {context.translation && context.translation.flgBokFailed}{" "}
                  <span className="flBookFailed">{context.translation && context.translation.failed} <i className="icon icon-Failed"></i></span>
                </Title>
              
                </>
              )}
            </div>
            {book_resp && (
              <div className="booking">
                <div>
                  <span className="dob">{context.translation && context.translation.dateofBok} :&nbsp;</span>
                  <span className="date">
                    {DateUtils.prettyDate(book_resp.bd, "ddd, DD MMM, YYYY")}
                  </span>
                </div>
                <div>
                  <span className="bReference">{context.translation && context.translation.bokRefer} :&nbsp;</span>
                  <span className="reference">{book_resp.booking_id}</span>
                </div>
              </div>
            )}
          </div>
          {book_resp.status.toUpperCase() == "CONFIRMED" && (
          <div className="emailDownloadWrap onlyFlex">
           
              <div className="emailItinerary" onClick={showModal}>
                <Button type="secondary" className="emailBtn" ghost>
                <i className="icon icon-Email"></i>
                  {context.translation && context.translation.emailIti}
                </Button>
              </div>
            
            <Modal
              title=""
              open={isModalOpen}
              onCancel={handleCancel}
              width={460}
              footer={null}
              className="emailItineraryPopup itineraryPopup"
              maskClosable={false}
            >
              <i
                className="icon icon-cancel"
                onClick={() => {
                  handleCancel();
                }}
              ></i>
              <Title level={4} className="shareItienaryTitle">
                {context.translation && context.translation.shrThsIti}
              </Title>
              <Space direction="horizontal">
                <Input
                  type="email"
                  className="shareItienaryInput"
                  placeholder={context.translation && context.translation.eYeid}
                  value={email}
                  onChange={handleChange}
                />

                <Button type="primary" className="cntBtn smallCntBtn" onClick={sendConfirmationEmail}>
                  {loading ? (
                    <Space size={"middle"}>
                      <Spin size="large" />
                    </Space>
                  ) : (
                    context.translation && context.translation.send
                  )}
                </Button>
              </Space>
            </Modal>
              <div className="download" onClick={itineraryPDF} >
                <i className="icon icon-Download"></i>
              </div>
          </div>
          )}
        </div>
        
        {(book_resp.status.toUpperCase().split(" ").join("") == "TICKETINGFAILED" || book_resp.status.toUpperCase() == "FAILED") && (
        <div className="alertBookingFailed">
                {context.translation && context.translation.sryForTheIncov} <a className="contactIfFailed" href="tel:+974 5572 2351"> +974 5572 2351</a> {context.translation && context.translation.orSendUs} 
                <a className="contactIfFailed" href="mailto:support@clickntravel.com"> support@clickntravel.com</a>, {context.translation && context.translation.gladlyAssist}
              </div>
        )}
        <div style={{ display: "none" }}>
          <FlightItinerarypdf ref={componentRef} confirmationObj={book_resp} baggage="baggageDesign" pdf={true} />
        </div>

        {(book_resp.status.toUpperCase() == "CONFIRMED" || book_resp.status.toUpperCase().split(" ").join("") == "PARTIALLYCONFIRMED") && (
        <>
        {/* CROSS SELL HOTELS */}
        {hotelsList.length > 0 &&
          <>
          {/* <div className="promoCodeListTitle">{context.translation && context.translation.bktrip}</div>
            <div className="promoCodeList">
            {hotelsList.map((htl, index) => {
              return (
                <div key={index} onClick={()=>selectHotel(htl)}>
                  <img src={htl.htl_img} className="expediaLogo" />
                  {htl.name}
                  {htl.ratings}
                {book_resp.cur} <FormatCurrency value={htl.rtf} className="currencyAmount"  />
                </div>
                )
            })}
          </div> */}



          <Row className="crossSellConfirmationPage">
          <Col span={24}>
            <div className="crossSellWrapper">
              <Title level={4} className="addHotelTitle">
                 <i className="icon icon-tick-circle"></i> {context.translation && context.translation.bktrip} {cityName}!
              </Title>
              <div className="crssSellSlideWrap">
                <Slider {...settings} className="crossSellSlider">
                {hotelsList.map((htl, index) => {
                  return (
                  <div key={index} >
                  <div className="crossSellCardWrap">
                  <img src={htl.htl_img} alt="hotel-image" className="hotelImage" />
                      <Title level={5} className="hotelName">
                      {htl.name}
                      </Title>
                      <div className="commonFlex">
                        <div>
                        
                      {htl.ratings !== undefined &&
                        htl.ratings !== 0 && (
                          <>
                          {Array(Math.ceil(htl.ratings)).fill("").map((_, i) => <i key={i} className="icon icon-star"></i>)}
                          </>
                        )}
                        </div>
                        <div>
                          <span className="currencyCode">{book_resp.cur}</span>
                          <span className="currencyAmount"> <FormatCurrency value={htl.rtf * book_resp.base_usr_exrate} /></span>
                        </div>
                      </div>
                      <Button type="primary" className="cntBtn hBtn" onClick={()=>selectHotel(htl)}>
                          {context.translation && context.translation.plrHtlsBtn}
                          <img src={HotelBrandIcon} alt="hotelBrandIcon" className="hotelBrandIcon" />
                        </Button>
                  </div>
                  </div>
                   )
                  })}
                </Slider>
              </div>
            </div>
          </Col>
        </Row> 
          </>
          }
          
        </>
        )}

        {/* FOR ONE WAY,ROUNTRIP AND LCC ONWARD */}
        <div className="flightSummary marginTop30">
          {book_resp && !book_resp.flexi_flow && (
            <>
              <Title level={3}>{context.translation && context.translation.flgtSumy}</Title>
              {Number(book_resp.tt) !== 3 &&
                book_resp.origns.map((org, idx) => (
                  <div className="newlayoutDesign" key={idx}>
                    {org["srcorigns"] && (
                      <FlightSummary
                        mapping={book_resp.mappings}
                        tripType={book_resp.tt}
                        sector={org.srcorigns[0]}
                        headerInfo={{
                          ad: org.srcorigns[0].seg[0].ad,
                          dd: org.srcorigns[0].seg[0].dd,
                          src_code: book_resp.src_code,
                          dest_code: book_resp.dest_code,
                        }}
                        seg={org.srcorigns[0].seg[0]}
                      />
                    )}
                    <br />
                    {Number(book_resp.tt) === 2 && org["destorigins"] && (
                      <FlightSummary
                        mapping={book_resp.mappings}
                        tripType={book_resp.tt}
                        sector={org.destorigins[0]}
                        headerInfo={{
                          ad: org.destorigins[0].seg[0].ad,
                          dd: org.destorigins[0].seg[0].dd,
                          src_code: book_resp.dest_code,
                          dest_code: book_resp.src_code,
                        }}
                        seg={org.destorigins[0].seg[0]}
                      />
                    )}
                  </div>
                ))}
              {/* MULTI CITY */}
              {book_resp &&
                book_resp.origns.length > 0 &&
                Number(book_resp.tt) === 3 &&
                book_resp.origns[0].srcorigns.map((org, idx) => (
                  <div className="newlayoutDesign" key={idx}>
                    <FlightSummary
                      mapping={book_resp.mappings}
                      tripType={book_resp.tt}
                      sector={org}
                      headerInfo={{
                        dd: org.seg[0].dd,
                        ad: org.seg[0].ad,
                        src_code: org.seg[0].da,
                        dest_code: org.seg[org.seg.length-1].ar,
                      }}
                      seg={org.seg[0]}
                    />
                  </div>
                ))}
              <div className="horizontalDivider"></div>
              <div className="travellerDetails">
                <PassengersDetails
                  sector={book_resp.tt}
                  sup={book_resp.sup}
                  response={book_resp}
                />
              </div>
            </>
          )}
          {/* <br /> */}
          {/* FOR FLEXI FLOW  */}
          {book_resp && book_resp.flexi_flow && (
            <>
              {book_resp.origns.map((org, idx) => (
                  <React.Fragment key={idx}>
                    {org["srcorigns"][0].ref === 1 && (
                      <div className="newlayoutDesign">
                        <Title level={3}>{context.translation && context.translation.flgtSumy}</Title>
                        {org["srcorigns"] && (
                          <FlightSummary
                            origin={[org["srcorigns"][0], "Confirm"]}
                            mapping={book_resp.mappings}
                            tripType={book_resp.tt}
                            bktype={book_resp.bktype}
                            sector={org.srcorigns[0]}
                            headerInfo={{
                              ad: org.srcorigns[0].seg[0].ad,
                              dd: org.srcorigns[0].seg[0].dd,
                              src_code: book_resp.src_code,
                              dest_code: book_resp.dest_code,
                            }}
                            seg={org.srcorigns[0].seg[0]}
                          />
                        )}
                        <div className="horizontalDivider"></div>
                        <div className="travellerDetails">
                          <PassengersDetails
                            sector={"O"}
                            sup={org.srcorigns[0].sup}
                            response={org.srcorigns[0]}
                          />
                        </div>
                      </div>
                    )}

                    {org["destorigins"][0].ref === 2 && (
                      <>
                      <div className="horizontalDivider"></div>
                      <div className="newlayoutDesign">
                        <br/>
                        <Title level={3}>{context.translation && context.translation.flgtSumy}</Title>
                        {org["destorigins"] && (
                          <FlightSummary
                            origin={[org["destorigins"][0], "Confirm"]}
                            mapping={book_resp.mappings}
                            tripType={book_resp.tt}
                            bktype={book_resp.bktype}
                            sector={org.destorigins[0]}
                            headerInfo={{
                              ad: org.destorigins[0].seg[0].ad,
                              dd: org.destorigins[0].seg[0].dd,
                              src_code: book_resp.dest_code,
                              dest_code: book_resp.src_code,
                            }}
                            seg={org.destorigins[0].seg[0]}
                          />
                        )}
                        <div className="horizontalDivider"></div>
                        <div className="travellerDetails">
                          <PassengersDetails
                            sector={"R"}
                            sup={org.destorigins[0].sup}
                            response={org.destorigins[0]}
                          />
                        </div>
                      </div>
                      </>
                    )}
                  </React.Fragment>
              ))}
            </>
          )}
        </div>
        <div className="horizontalDivider"></div>
        {book_resp && (
          <div className="travellerDetails">
            <FareDetails response={book_resp} />
          </div>
        )}

        <div className="horizontalDivider"></div>
        {book_resp && book_resp.pay.length > 0 && (
          <div className="travellerDetails">
            <PaymentDetails payment_info={book_resp.pay[0]} base_usr_exrate = {book_resp.base_usr_exrate} />
          </div>
        )}

        <div className="horizontalDivider"></div>
      </div>

      <div className="marginTop50">
        <SecureGuarantee />
      </div>
      <Footer />
    </>
  );
}

export default FlightConfirmation;
