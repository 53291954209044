import React, { useEffect, useReducer, useState , useContext} from "react";
import { Context } from "../../../../App";
import Utilities from "../../../utilities/Utilities";
import { Radio, Alert } from "antd";
import { Link } from "react-router-dom";
import HotelCancellationPolicy from "./HotelCancellationPolicy";
import FormatCurrency from "../../../utilities/FormatCurrency";

const initialState = {
  selRoom1: false,
  selRoom2: false,
  selRoom3: false,
  selRoom4: false,
  selRoom5: false,
  selRoom6: false,
  selRoom7: false,
  selRoom8: false,
  selected: false,
};

//=====================================
// Reducer function for userReact Hook
// which will update the state
//=====================================
const reducer = (state, action) => {
  switch (action.type) {
    case "clear":
      return {
        ...state,
      };
    default:
      return { ...state, [action.type]: action.payload };
  }
};

function HotelOccupancyDetails(props) {
  const [context, setContext] = useContext(Context)
  const [state, dispatch] = useReducer(reducer, initialState);
  const [roominfo, setRoomOcp1] = useState(props.rmocp);
  const [rmpr, setRoomPrice] = useState(props.rmpr);
  const [cur, setCur] = useState("");

  const handleShow = (roomObj) => () => {
    props.sendRoom(roomObj);
  };

  useEffect(() => {
    setCur(context.userCur!==undefined && context.userCur!==""?context.userCur:sessionStorage.getItem("SEL_CUR"));
  }, [sessionStorage.getItem("SEL_CUR") || context.userCur])

  useEffect(() => {
    setRoomOcp1(props.rmocp);
  }, [props.rmocp]);

  const calPricePerNight = (price, serviceFee, gstprice, tdsVal) => {
    let total =
      Number(price) +
      Number(props.onflyMkp) +
      Number(gstprice) +
      tdsVal +
      Number(serviceFee);
    let totalPrice = Utilities.addPrice(total, props.nights, "divide");
    return totalPrice * props.base_usr_exrate;
  };

  return (
    <>
     <div className="horozontalDashedDivider">
        <div className="onlyFlex">
          <div className="leftSideDetails">
            {roominfo.rmimg !== undefined && roominfo.rmimg !== "" ? (
              <img
                src={roominfo.rmimg}
                title="Hotel"
                alt="hotel-image"
                className="hoyelImage"
              />
            ):(
              <div className="empty-image">
                {context.translation && context.translation.noimage}
              </div>
            )}
          </div>
          <div className="rightSideDetails">
            <div className=" innerHotelDetailsFilter">
              <div className="commonFlex">
              <div className="bedRoomType">
                <div className="roomType">{roominfo.rnm}</div>
                <div className="bedType">
                  {context.translation && context.translation.bedtype} :{" "}
                  {roominfo.rmpr !== undefined &&
                  roominfo.rmpr.bed !== undefined
                    ? roominfo.rmpr.bed
                    : roominfo.rmpr[0].bed}
                </div>
                
              </div>
              <div className="roomCancelRefund">
                <div className="onlyRoom">
                  {roominfo.rmpr[0] !== undefined &&
                    roominfo.rmpr[0].ra.length !== 0 &&
                    Utilities.getBoardBasisHotel(roominfo.rmpr[0].ra)}
                  {roominfo.rmpr[0] !== undefined &&
                    roominfo.rmpr[0].ra.length === 0 && <>{context.translation && context.translation.roomOnly}</>}
                </div>
                {roominfo.rmpr[0] !== undefined &&
                  roominfo.rmpr[0].canpol !== undefined &&
                  roominfo.rmpr[0].refundable && (
                    <HotelCancellationPolicy
                      canPolicy={roominfo.rmpr[0].canpol}
                      isRefund={true}
                    />
                  )}
                {!roominfo.rmpr[0].refundable && (
                  <HotelCancellationPolicy
                    canPolicy={roominfo.rmpr[0].canpol}
                    isRefund={false}
                  />
                )}
                <div className="refundableApplies">
                  {roominfo.rmpr[0].refundable
                    ? context.translation && context.translation.penaltyApp
                    : context.translation && context.translation.nonRefund }
                </div>
              </div>
              {roominfo.rmpr[0].pr[0].disc !== "0" &&
              roominfo.rmpr[0].pr[0].disc !== 0 ? (
                <div className="totalAmount">
                  {!props.pricePerNight ? (
                    <>
                      <div>
                        <span className="countryCode">{props.cur?props.cur:cur} </span>
                        <span className="currencyValue">
                          <FormatCurrency
                            value={
                              (Number(roominfo.rmpr[0].pr[0].tf) +
                              Number(props.onflyMkp) +
                              Number(roominfo.rmpr[0].pr[0].gst_total) +
                              Number(roominfo.rmpr[0].pr[0].tds_val) +
                              Number(roominfo.rmpr[0].servicefee.length > 0?roominfo.rmpr[0].servicefee[0].sf:0)
                               -
                              Number(roominfo.rmpr[0].pr[0].com_disc)) * 
                              props.base_usr_exrate
                            }
                          />
                        </span>
                      </div>
                      <div className="totalPrice">
                        {!props.pricePerNight ? (
                          <>({context.translation && context.translation.totalPrice} {props.nights} {context.translation && context.translation.nights})</>
                        ) : (
                          <>({context.translation && context.translation.totalPriceforOne})</>
                        )}
                      </div>
                    </>
                  ) : (
                    <>
                      <div>
                        <span className="countryCode">{props.cur?props.cur:cur} </span>
                        <span className="currencyValue">
                          <FormatCurrency
                            value={calPricePerNight(
                              Number(roominfo.rmpr[0].pr[0].tf) -
                              Number(roominfo.rmpr[0].pr[0].com_disc),
                              Number(roominfo.rmpr[0].servicefee.length > 0?roominfo.rmpr[0].servicefee[0].sf:0),
                              Number(roominfo.rmpr[0].pr[0].gst_total),
                              Number(roominfo.rmpr[0].pr[0].tds_val)
                            )}
                          />
                        </span>
                      </div>
                      <div className="totalPrice">
                        {!props.pricePerNight ? (
                          <>{context.translation && context.translation.totalPrice} {props.nights} {context.translation && context.translation.nights}</>
                        ) : (
                          <>{context.translation && context.translation.totalPriceforOne}</>
                        )}
                      </div>
                    </>
                  )}
                </div>
              ) : (
                <div className="totalAmount">
                  {!props.pricePerNight ? (
                    <>
                      <div>
                        <span className="countryCode">{props.cur?props.cur:cur} </span>
                        <span className="currencyValue">
                          <FormatCurrency
                            value={
                              (Number(roominfo.rmpr[0].pr[0].tf) +
                              Number(props.onflyMkp) +
                              Number(roominfo.rmpr[0].pr[0].gst_total) +
                              Number(roominfo.rmpr[0].pr[0].tds_val) +
                              Number(roominfo.rmpr[0].servicefee.length > 0?roominfo.rmpr[0].servicefee[0].sf:0)
                              -
                              Number(roominfo.rmpr[0].pr[0].com_disc)) * 
                              props.base_usr_exrate
                            }
                          />
                        </span>
                      </div>
                      <div className="totalPrice">
                        {!props.pricePerNight ? (
                          <>{context.translation && context.translation.totalPrice} {props.nights} {context.translation && context.translation.nights}</>
                        ) : (
                          <>{context.translation && context.translation.totalPriceforOne}</>
                        )}
                      </div>
                    </>
                  ) : (
                    <>
                      <div>
                        <span className="countryCode">{props.cur?props.cur:cur} </span>
                        <span className="currencyValue">
                          <FormatCurrency
                            value={calPricePerNight(
                              Number(roominfo.rmpr[0].pr[0].tf) -
                              Number(roominfo.rmpr[0].pr[0].com_disc),
                              Number(roominfo.rmpr[0].servicefee.length > 0?roominfo.rmpr[0].servicefee[0].sf:0),
                              Number(roominfo.rmpr[0].pr[0].gst_total),
                              Number(roominfo.rmpr[0].pr[0].tds_val)
                            )}
                          />
                        </span>
                      </div>
                      <div className="totalPrice">
                        {!props.pricePerNight ? (
                          <>{context.translation && context.translation.totalPrice} {props.nights} {context.translation && context.translation.nights}</>
                        ) : (
                          <>{context.translation && context.translation.totalPriceforOne}</>
                        )}
                      </div>
                    </>
                  )}
                </div>
              )}
              <div className="hotelRadioSelect">
              <Radio id={props.rmocp.count}
                    checked={props.sno === 1?props.rmocp.selected1:
                      props.sno === 2?props.rmocp.selected2:
                      props.sno === 3?props.rmocp.selected3:
                      props.sno === 4?props.rmocp.selected4:
                      props.sno === 5?props.rmocp.selected5:
                      props.sno === 6?props.rmocp.selected6:
                      props.sno === 7?props.rmocp.selected7:
                      props.sno === 8?props.rmocp.selected8:false}
                    value={props.sno === 1?props.rmocp.selected1:
                      props.sno === 2?props.rmocp.selected2:
                      props.sno === 3?props.rmocp.selected3:
                      props.sno === 4?props.rmocp.selected4:
                      props.sno === 5?props.rmocp.selected5:
                      props.sno === 6?props.rmocp.selected6:
                      props.sno === 7?props.rmocp.selected7:
                      props.sno === 8?props.rmocp.selected8:false}
                    name={"selectRoom"+props.sno}
                    onChange={props.handleChange(props.rmocp, props.sno)}
                    nights={props.nights}
                  ></Radio>
              </div>
              </div>
              <div className="infoMessage">
                  <i className="icon icon-Info"> </i>
                  {context.translation && context.translation.honrRoom}
                </div>
            </div>
            {roominfo.rmimg !== undefined && roominfo.rmimg !== "" && (
              <div>
                <span className="showRoomInfo">
                  <Link onClick={handleShow(roominfo)}>
                    {context.translation && context.translation.showRoominfor}
                  </Link>
                </span>
                <span></span>
              </div>
            )}
          </div>
        </div>
        
    </div>
      
    </>
  );
}

export default HotelOccupancyDetails;
