import { Tabs } from "antd";
import React, { useState, useEffect, useTransition } from "react";
import Header from "../common/Header";
import Footer from "../common/Footer";
import FlightSearch from "../bookingflow/flights/search/Search";
import Recommendations from "./Recommendations";
import MostlyFlownRoutes from "./MostlyFlownRoutes";
import HighInDemand from "./HighInDemand";
import YouWillLoveIt from "./YouWillLoveIt";
import MostPopularHotels from "./MostPopularHotels";
import { useNavigate } from "react-router-dom";
//import MailingList from "./MailingList";
import AppDownload from "./AppDownload";
import SecureGuarantee from "./SecureGuarantee";
import HotelSearch from "../bookingflow/hotels/search/HotelSearch";
export const REGION_COUNTRY = "REGION_COUNTRY";

function Home(props) {
  const [enableInd, setEnableInd] = useState(false);
  const [enableArab, setEnableArab] = useState(false);
  const [enableTab, setEnableTab] = useState(false);
  const [region, setRegion] = useState("");
  const [selectedTab, setSelectedTab] = useState("Flights");
  const [lanPath, setLanPath] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem(REGION_COUNTRY) !== null) {
      if (
        !enableInd &&
        localStorage.getItem(REGION_COUNTRY) === "IN"
      ) {
        setEnableInd(true);
        setRegion("India");
      } else if (!enableArab) {
        setEnableArab(true);
        setRegion("Qatar");
      }
    } else {
      setEnableArab(true);
    }
  }, [localStorage.getItem(REGION_COUNTRY) !== null]);

  useEffect(() => {
    if((lanPath === "en" || window.location.pathname !== "/ar") && sessionStorage.getItem("SEL_LAG") === "English") {
      navigate("/en");
    } else if ((lanPath === "ar" || window.location.pathname !== "/en") && sessionStorage.getItem("SEL_LAG") === "Arabic") {
      navigate("/ar");
    }
  }, [sessionStorage.getItem("SEL_LAG")])

  const selLangPath = (path) => {
    setLanPath(path);
  }

  // useEffect(() => {
  //   if(sessionStorage.getItem("SEL_TAB") !== null) {
  //     setSelectedTab(sessionStorage.getItem("SEL_TAB"))
  //   }else{
  //     if(selectedTab===""){
  //       alert("Enter")
  //       setSelectedTab("Flights")
  //     }
  //   }
  // }, [selectedTab]);

  const update=(type)=>{
    setSelectedTab(type)
    setEnableTab(true)
    if (type === "Hotels") {
      sessionStorage.setItem("SEL_TAB", "Hotels")
    } else if (type === "Flights") {
      sessionStorage.setItem("SEL_TAB", "Flights")
    }
  }

  return (
    <>
      <Header setTab={update} lang={selLangPath} />
      <div className="homeSearch">
       {/* {selectedTab === 'Flights' && <FlightSearch />} 
       {selectedTab === 'Hotels' && <HotelSearch />}  */}
       {sessionStorage.getItem("SEL_TAB") === null ? <FlightSearch /> : sessionStorage.getItem("SEL_TAB") === "Flights" && <FlightSearch />} 
       {sessionStorage.getItem("SEL_TAB") === "Hotels" && <HotelSearch />} 
       
      </div>
      <div className="whiteBg">
        <Recommendations enableInd={enableInd} />
        <MostlyFlownRoutes enableInd={enableInd} />
        <HighInDemand enableInd={enableInd} />
        <YouWillLoveIt enableInd={enableInd} />
        <MostPopularHotels enableInd={enableInd} />
        {/* <MailingList /> */}
        {/* Hide AppDownload Component */}
        <SecureGuarantee />
      </div>

      <Footer />
    </>
  );
}

export default Home;
