import React from "react";
import { useContext } from "react";
import { Context } from "../../../../App";
import DateUtils from "../../../utilities/DateUtils";
import Logo from "../../../../assets/svg/logo.svg";
import FlightSummarypdf from "./FlightSummarypdf";
import PassengersDetailspdf from "./PassengersDetailspdf";
import { Typography, Table } from "antd";
const { Title } = Typography;

const FlightItinerarypdf = React.forwardRef((props, ref) => {
  const [context, setContext] = useContext(Context)
  return (
  <div className="pagewidth">
    <div ref={ref} className="mainLayoutWrap">
        <div>
        <img src={Logo} alt="Click N Travel" />
        
        </div>
        <div className="greetingsWrap">
        <div>
        <div className="greetings">
        {props.confirmationObj && !props.confirmationObj.flexi_flow && (
          <>
            {context.translation && context.translation.hi} {props.confirmationObj 
            && props.confirmationObj.psngrs && props.confirmationObj.psngrs[0] 
            && (props.confirmationObj.psngrs[0].fn + " " + props.confirmationObj.psngrs[0].ln)},
          </>
        )}
        {props.confirmationObj && props.confirmationObj.flexi_flow && (
          <>
            {context.translation && context.translation.hi} {props.confirmationObj 
            && props.confirmationObj.origns && props.confirmationObj.origns[0] 
            && (props.confirmationObj.origns[0].srcorigns[0].psngrs[0].fn + " " + props.confirmationObj.origns[0].srcorigns[0].psngrs[0].ln)},
          </>
        )}
        </div>
        <div className="bookingIsConfirmed"><i className="icon icon-Confirm-Tick"></i> {context.translation && context.translation.bokConfirm}</div>
        </div>
        {props.confirmationObj && (
          <div className="booking">
            <div className="dateOfBooking">
              <span className="dob">{context.translation && context.translation.dateofBok} :&nbsp;</span>
              <span className="date">
                {DateUtils.prettyDate(
                  props.confirmationObj.bd,
                  "ddd, DD MMM, YYYY"
                )}
              </span>
            </div>
            <div className="bookingReference">
              <span className="bReference">{context.translation && context.translation.bokRefer} :&nbsp;</span>
              <span className="reference">{props.confirmationObj.booking_id}</span>
            </div>
          </div>
        )}
        </div>
        <div className="horizontalDivider"></div>
        <div className="flightSummary">
          {props.confirmationObj && !props.confirmationObj.flexi_flow && (
            <>
            {props.baggage !== "baggageDesign" && ( 
              <Title level={3}>{context.translation && context.translation.flgtSumy}</Title>
            )}
            {props.baggage === "baggageDesign" && (
              <Title level={3}>{context.translation && context.translation.urItinerary}</Title>
            )}
              {Number(props.confirmationObj.tt) !== 3 &&
                props.confirmationObj.origns.map((org, idx) => (
                  <div className="newlayoutDesignpdf" key={idx}>
                    {org["srcorigns"] && (
                      <FlightSummarypdf
                        mapping={props.confirmationObj.mappings}
                        tripType={props.confirmationObj.tt}
                        sector={org.srcorigns[0]}
                        headerInfo={{
                          ad: org.srcorigns[0].seg[0].ad,
                          dd: org.srcorigns[0].seg[0].dd,
                          src_code: props.confirmationObj.src_code,
                          dest_code: props.confirmationObj.dest_code,
                        }}
                        seg={org.srcorigns[0].seg[0]}
                        baggage={props.baggage}
                      />
                    )}
                  
                    {Number(props.confirmationObj.tt) === 2 &&
                      org["destorigins"] && (
                        <FlightSummarypdf
                          mapping={props.confirmationObj.mappings}
                          tripType={props.confirmationObj.tt}
                          sector={org.destorigins[0]}
                          headerInfo={{
                            ad: org.destorigins[0].seg[0].ad,
                            dd: org.destorigins[0].seg[0].dd,
                            src_code: props.confirmationObj.dest_code,
                            dest_code: props.confirmationObj.src_code,
                          }}
                          seg={org.destorigins[0].seg[0]}
                          baggage={props.baggage}
                        />
                      )}
                  </div>
                ))}
              {/* MULTI CITY */}
              {props.confirmationObj &&
                props.confirmationObj.origns.length > 0 &&
                Number(props.confirmationObj.tt) === 3 &&
                props.confirmationObj.origns[0].srcorigns.map((org, idx) => (
                  <div className="newlayoutDesignpdf" key={idx}>
                    <FlightSummarypdf
                      mapping={props.confirmationObj.mappings}
                      tripType={props.confirmationObj.tt}
                      sector={org}
                      headerInfo={{
                        dd: org.seg[0].dd,
                        ad: org.seg[0].ad,
                        src_code: org.seg[0].da,
                        dest_code: org.seg[org.seg.length-1].ar,
                      }}
                      seg={org.seg[0]}
                      baggage={props.baggage}
                    />
                  </div>
                ))}
              <div className="horizontalDivider"></div>
              <div className="travellerDetails ">
                <PassengersDetailspdf
                  sector={props.confirmationObj.tt}
                  sup={props.confirmationObj.sup}
                  response={props.confirmationObj}
                />
              </div>
            </>
          )}
      
          {/* FOR FLEXI FLOW  */}
          {props.confirmationObj && props.confirmationObj.flexi_flow && (
            <>
              {props.confirmationObj.origns.map((org, idx) => (
                <>
                  <React.Fragment key={idx}>
                    {org["srcorigns"][0].ref === 1 && (
                      <div className="newlayoutDesignpdf">
                        <Title level={3}>{context.translation && context.translation.flgtSumy}</Title>
                        {org["srcorigns"] && (
                          <FlightSummarypdf
                            origin={[org["srcorigns"][0], "Confirm"]}
                            mapping={props.confirmationObj.mappings}
                            tripType={props.confirmationObj.tt}
                            bktype={props.confirmationObj.bktype}
                            sector={org.srcorigns[0]}
                            headerInfo={{
                              ad: org.srcorigns[0].seg[0].ad,
                              dd: org.srcorigns[0].seg[0].dd,
                              src_code: props.confirmationObj.src_code,
                              dest_code: props.confirmationObj.dest_code,
                            }}
                            seg={org.srcorigns[0].seg[0]}
                            baggage={props.baggage}
                          />
                        )}
                        <div className="horizontalDivider"></div>
                        <div className="travellerDetails ">
                          <PassengersDetailspdf
                            sector={"O"}
                            sup={org.srcorigns[0].sup}
                            response={org.srcorigns[0]}
                          />
                        </div>
                      </div>
                    )}

                    {org["destorigins"][0].ref === 2 && (
                      <div className="newlayoutDesignpdf">
                      
                        <Title level={3}>{context.translation && context.translation.flgtSumy}</Title>
                        {org["destorigins"] && (
                          <FlightSummarypdf
                            origin={[org["destorigins"][0], "Confirm"]}
                            mapping={props.confirmationObj.mappings}
                            tripType={props.confirmationObj.tt}
                            bktype={props.confirmationObj.bktype}
                            sector={org.destorigins[0]}
                            headerInfo={{
                              ad: org.destorigins[0].seg[0].ad,
                              dd: org.destorigins[0].seg[0].dd,
                              src_code: props.confirmationObj.dest_code,
                              dest_code: props.confirmationObj.src_code,
                            }}
                            seg={org.destorigins[0].seg[0]}
                            baggage={props.baggage}
                          />
                        )}
                        <div className="horizontalDivider"></div>
                        <div className="travellerDetails ">
                          <PassengersDetailspdf
                            sector={"R"}
                            sup={org.destorigins[0].sup}
                            response={org.destorigins[0]}
                          />
                        </div>
                      </div>
                    )}
                  </React.Fragment>
                </>
              ))}
            </>
          )}
        </div>
        <div style={{height: "4px", background: "#3156AB", display: "block", marginTop: "30px"}}></div>
                            <div style={{display: "block", marginTop: "30px", borderRadius: "6px", textAlign: "center"}}>
                                <span style={{display: "block", padding: "15px", fontSize: "16px", fontFamily: "var(--fontMedium)",  background: "#e2ecfd"}}>For general enquiries Call us on: <a href="tel:+97444484847" style={{color:"#3156ab", textDecoration: "none"}}><img src="../Hotel/Images/phone.png" style={{display: "inline-block", height: "12px"}} /> +97444484847</a> (OR) Email us on : <a href="mailto:support@clickntravel.com" style={{color:"#3156ab", textDecoration: "none"}}><img src="../Hotel/Images/email.png" style={{display: "inline-block", height: "10px"}} /> support@clickntravel.com</a>    </span>
                            </div>
                            

        <div className="a4-screen-sized">
          <div className="copy mt-4 mb-4"> Copyright &#169; {new Date().getFullYear()} Click n Travel.</div>
        </div>
    </div>
  </div>
  );
});

export default FlightItinerarypdf;