/**
 * @ Author: Srikanth Bandaru
 * @ Create Time: 2023-05-13 14:05:26
 * @ Description: Common footer for the application
 */

import React, {useState,useContext, useEffect} from  "react";
import {
  Col,
  Form,
  Input,
  Button,
  Row,
  Typography,
  Modal,
  Space 
} from "antd";
import Link from "antd/es/typography/Link";
import {characterValidation} from "../utilities/FormValidations";
import { Context } from "../../App";
import PromoCodePopup from "./PromoCodePopup";
import CntCommonService from "../../services/common/CntCommonService";
import Utilities from "../utilities/Utilities";

function ApplyPromoCode(props) {
    const [context, setContext] = useContext(Context);
    const [promoCode, setpromoCode] = useState(""); 
    const [showMand, setShowMand] = useState(""); 
    const [enablePromo,setEnablePromo] = useState(false); 
    const [promoObject,setpromoObject] = useState(false);
    const [promoCodesList,setPromoCodesList] = useState([]);
    const [active, setActive] = useState(false);
    

    useEffect(() => {
    if(props.promo_codes.length!==0){
      setPromoCodesList(props.promo_codes)
    }else{
      setPromoCodesList([])
    }
    }, [props.promo_codes]);
    
    const updatePromo=(val)=>{
      setpromoCode(val.target.value)
      if(val.target.value===""){
        props.removeData();
      }
      
    }

    const applyPromoCode=()=>{
        setShowMand(false);
        if(promoCode!==""){
          if(characterValidation(promoCode)){
            props.sendPromo(promoCode);
          }else{
            setShowMand(context.translation && context.translation.validpromo);
          }
        }else{
            setShowMand(context.translation && context.translation.entpromo);
        }
    }
    const removeCode=()=>{
        setpromoCode("")
        for(let val of promoCodesList){
          val.active=false;
          val.selected=false;
        }
        props.removeData();
  }
  const closePromo=()=>{
    setEnablePromo(false)
  }
  const getPromoDetails=(obj)=>{
    try {
      if(obj.promo_id!==undefined){
        const req={
          "promo_id":obj.promo_id,
          "product_type": props.product
        }
        let tokenKey = Utilities.generateAPIToken(req);
        CntCommonService.getProDetails(req,tokenKey).then((response) => {
        if (response.data !== undefined && response.data.suc) {
            setEnablePromo(true)
            setpromoObject(response.data.res);
           }
        }).catch(function (error) {
          console.error("Error in getPromoDetails" + error);      
        });
      }
    } catch (err) {
      console.error("Error in getPromoDetails" + err);
    }
  }

  const updateSelPromo=(code, index)=>{
    for(let val of promoCodesList){
      val.active=false;
      if(val.pro_code===code){
        val.selected=true;
      }
    }
      promoCodesList[index].active = true;
    setpromoCode(code)
  }

  return (
    <>
   <div className="pRelative"> 
               {promoCodesList.length > 0 && context.user_resp !== "" && context.user_resp?.res !== undefined &&
                      <Row span={4} className="applyPromoCodeWrap">
                        <div className="applyPromoCodeWrapTitle">{context.translation && context.translation.addpromo}</div>
                      <Space.Compact className="applyPromoCodeInputWrap">
                          <Input
                            type="text"
                            name="promoCode"
                            className="innerBoxShadow"
                            value={promoCode}
                            onChange={(value) =>  updatePromo(value)}
                            placeholder={context.translation && context.translation.entpromo}
                          />
                          {showMand!=="" &&
                            <div className="error">
                             <span>{showMand}</span>
                            </div>
                          }
                          {promoCode!=="" &&
                          <i className="icon icon-cancel" onClick={()=>removeCode()}></i>
                          }
                        
                        <Button
                        type="primary"
                        className={props.promo_disc!==0?"appliedBtn cntBtn":"cntBtn"}
                        onClick={applyPromoCode}
                        >
                          {props.promo_disc===0 &&
                            context.translation && context.translation.apply
                          }
                          {props.promo_disc!==0 && 
                          <>
                             <i className="icon icon-tick1"></i> {context.translation && context.translation.applied}
                             </>
                          }
                          
                      </Button>
                      </Space.Compact>
                      </Row>
                  }
                     
                       {promoCodesList.length > 0 &&
                       <>
                       <div className="promoCodeListTitle">{context.translation && context.translation.promocodes}</div>
                        <div className="promoCodeList">
                        {promoCodesList.map((promo, index) => {
                          return (
                            // className={promo.selected!==undefined && promo.selected?"promoCodeList":"diff"}
                            <div className={promo.st!==undefined && !promo.st?"disableCls":"enableCls"} key={index}>
                            <div onClick={()=>updateSelPromo(promo.pro_code, index)} className={promo.active!==undefined && promo.active?"promoCodeDetailWrap active":"promoCodeDetailWrap"}>
                              <div className="offerCode">{promo.pro_code}</div>
                              <div className="offerDesc">
                                {context.selectLang == undefined || context.selectLang === "en" ? promo.offer_nm : promo.offer_nm_ar}
                              </div>
                              <Link onClick={()=>getPromoDetails(promo)} className="offerDetails">{context.translation && context.translation.knowmore}</Link>
                            </div> 
                            </div>
                            )
                        })}
                      </div>
                      </>
                       }
                </div>
                <Modal title="" centered open={enablePromo} footer={null} className="promoCodePopup">
                {promoObject!=="" &&  
                <PromoCodePopup promoObj={promoObject} product={props.product} closePopup={closePromo}/>
                }
              </Modal>
    </>
  );
}

export default ApplyPromoCode;
