/**
 * @ Author: Srikanth Bandaru
 * @ Create Time: 2023-06-06 20:28:49
 * @ Description: The component used to display all the flight details section includes flight details , fare rules and fare details
 */

import { Tabs } from "antd";
import { useContext } from "react";
import { Context } from "../../../../App";
import { useState } from "react";
import FlightDetails from "./FlightDetails";
import FlightFareDetails from "./FlightFareDetails";
import FlightFareRules from "./FlightFareRules";
import FlightLccFareRules from "./FlightLccFareRules";
import { useEffect } from "react";
import FlightStaticFareRules from "./FlightStaticFareRules";
import FlightFareRulesTerms from "./FlightFareRulesTerms";

// const initialState = {
//   searchResp: [],
//   initialResponse: {},
// };

//=====================================
// Reducer function for userReact Hook
// which will update the state
//=====================================
// const reducer = (state, action) => {
//   switch (action.type) {
//     case "clear":
//       return {
//         ...state,
//       };
//     default:
//       return { ...state, [action.type]: action.payload };
//   }
// };
/**
 *
 *
 * @param {*}
 * @return {*}
 */
function FlightDetailsPopup(props) {
  const [context, setContext] = useContext(Context)
  const {
    flt,
    seg,
    rtnflt,
    rtnseg,
    sty,
    // price,
    // currency,
    sectors,
    // serviceFee,
    mapping,
    secType,
    secTypeIndex,
    trip,
    req,
    paxpr,
    regionType,
    pr,
    sf,
    refund,
    open,
    updatePrice,
    flow,
    selected,
    base_usr_exrate
  } = props;

  const [activeTab, setActiveTab] = useState("tab1");
  const [paxPrice,setPaxPrice] = useState(paxpr);
  const [selPrice,setSelPrice] = useState(pr);

  useEffect(() => {
    handleTabPaneRender();
  }, [open]);

  //For setting the active tab on change
  const handleTabChange = (key) => {
    setActiveTab(key);
    if(key === 'tab2' && parseInt(trip) === 2 && flow === 'SRP'){
      updatePrice();
    }
  };

  useEffect(() => {
    if(paxpr) setPaxPrice(paxpr);
    if(pr) setSelPrice(pr);
  }, [pr,paxpr])
  

  const handleTabPaneRender = () => {
    setActiveTab("tab1");
  };

  // const { Panel } = Collapse;
let items;
if((flow==="SRP" || flow==="SRPMULTI")) {
  items = [
    {
      key: 'tab1',
      label: `${context.translation && context.translation.flgtDetls}`,
      children: 
      <FlightDetails
        mapping={mapping}
        sectors={sectors}
        secType={secType}
        secTypeIndex={secTypeIndex}
        trip={trip}
        flt={flt}
        seg={seg}
        rtnflt={rtnflt}
        rtnseg={rtnseg}
        refund={refund}
        selected={selected}
        flow={flow}
        req={req}
        regionType={regionType}
      />
    },
    {
      key: `${(flow==="SRP" || flow==="SRPMULTI") ? 'tab2' : ""}`,
      label: `${(flow==="SRP" || flow==="SRPMULTI") ? context.translation && context.translation.fareSumy : ""}`,
      children: <>{(flow==="SRP" || flow==="SRPMULTI") &&
        <FlightFareDetails paxpr={paxpr} req={req} pr={pr} sf={sf} flow={'SRP'} notReview={true} base_usr_exrate={base_usr_exrate}/>
     }</>
    },
    {
      key: 'tab3',
      label: `${context.translation && context.translation.fareRules}`,
      children: <>
      {sty && flt !== undefined && flt.sup !== undefined && (
        (sty === 'normal' && flt.sup === "0" && parseInt(trip) !== 3) || (sty === 'split' && flt.sup === rtnflt.sup && flt.sup === "0" && parseInt(trip) !== 3) ? (
          <>
          <div className={parseInt(trip) !== 1 ? "alignment" : ""}>
          <FlightFareRules
            mapping={mapping}
            sty={sty}
            flights={flt}
            selectedFlight={seg}
            request={req}
            rtnflt={rtnflt}
            rtnseg={rtnseg}
            sf={sf}
            sectors={sectors}
            secType={secType}
          />
          </div>
          <FlightFareRulesTerms cur={req.cur} sf={sf} />
          </>
        ) : (
          (sty === 'normal' && flt.sup !== "0" && parseInt(trip) !== 3) || (sty === 'split' && flt.sup !== "0" && rtnflt.sup !== "0" && parseInt(trip) !== 3) ? (
            <>
            <div className={parseInt(trip) !== 1 ? "alignment" : ""}>
            <FlightLccFareRules
              secType={secType}
              sty={sty}
              flights={flt}
              selectedFlight={seg}
              request={req}
              rtnflt={rtnflt}
              selFlt={flt}
              regionType={regionType}
              mapping={mapping}
              sect={sectors}
            />
            </div>
            <FlightFareRulesTerms cur={req.cur} sf={sf} />
            </>
          ) : null
        )
      )}
      {sty && flt !== undefined && flt.sup !== undefined && (
        (sty === 'split' && flt.sup !== rtnflt.sup && flt.sup === "0" && parseInt(trip) !== 3) ? (
          <>
          <div className={parseInt(trip) !== 1 ? "alignment" : ""}>
            <FlightFareRules
              mapping={mapping}
              sty={sty}
              flights={flt}
              selectedFlight={seg}
              request={req}
              rtnflt={rtnflt}
              rtnseg={rtnseg}
              sf={sf}
              sectors={sectors}
              secType={secType} />

            <FlightLccFareRules
              secType={secType}
              sty={sty}
              flights={flt}
              selectedFlight={seg}
              request={req}
              rtnflt={rtnflt}
              selFlt={flt}
              regionType={regionType}
              mapping={mapping}
              sect={sectors} />
            </div>
            <FlightFareRulesTerms cur={req.cur} sf={sf} />
            </>
        ) : (
          (sty === 'split' && flt.sup !== rtnflt.sup && rtnflt.sup === "0" && parseInt(trip) !== 3) ? (
            <>
            <div className={parseInt(trip) !== 1 ? "alignment" : ""}>
              <FlightLccFareRules
                secType={secType}
                sty={sty}
                flights={flt}
                selectedFlight={seg}
                request={req}
                rtnflt={rtnflt}
                selFlt={flt}
                regionType={regionType}
                mapping={mapping}
                sect={sectors} />
                  
              <FlightFareRules
                mapping={mapping}
                sty={sty}
                flights={flt}
                selectedFlight={seg}
                request={req}
                rtnflt={rtnflt}
                rtnseg={rtnseg}
                sf={sf}
                sectors={sectors}
                secType={secType} />
            </div>
            <FlightFareRulesTerms cur={req.cur} sf={sf} />
            </>
          ) : null
        )
      )}
    {sty && selected !== undefined && (
        (parseInt(trip) === 3) && (
          <FlightStaticFareRules
            mapping={mapping}
            sect={sectors}
            secType={secType}
            flights={flt}
            rtnflt={rtnflt}
            trip={trip}
            sf={sf}
            cur={req.cur} />
          )
      )}
    </>
    },
  ];
} else {
  items = [
    {
      key: 'tab1',
      label: `${context.translation && context.translation.flgtDetls}`,
      children: 
      <FlightDetails
        mapping={mapping}
        sectors={sectors}
        secType={secType}
        secTypeIndex={secTypeIndex}
        trip={trip}
        flt={flt}
        seg={seg}
        rtnflt={rtnflt}
        rtnseg={rtnseg}
        refund={refund}
        selected={selected}
        flow={flow}
        req={req}
        regionType={regionType}
      />
    },
    {
      key: 'tab3',
      label: `${context.translation && context.translation.fareRules}`,
      children: <>
      {sty && flt !== undefined && flt.sup !== undefined && (
        (sty === 'normal' && flt.sup === "0" && parseInt(trip) !== 3) || (sty === 'split' && flt.sup === rtnflt.sup && flt.sup === "0" && parseInt(trip) !== 3) ? (
          <>
          <div className={parseInt(trip) !== 1 ? "alignment" : ""}>
          <FlightFareRules
            mapping={mapping}
            sty={sty}
            flights={flt}
            selectedFlight={seg}
            request={req}
            rtnflt={rtnflt}
            rtnseg={rtnseg}
            sf={sf}
            sectors={sectors}
            secType={secType}
          />
          </div>
          <FlightFareRulesTerms cur={req.cur} sf={sf} />
          </>
        ) : (
          (sty === 'normal' && flt.sup !== "0" && parseInt(trip) !== 3) || (sty === 'split' && flt.sup !== "0" && rtnflt.sup !== "0" && parseInt(trip) !== 3) ? (
            <>
            <div className={parseInt(trip) !== 1 ? "alignment" : ""}>
            <FlightLccFareRules
              secType={secType}
              sty={sty}
              flights={flt}
              selectedFlight={seg}
              request={req}
              rtnflt={rtnflt}
              selFlt={flt}
              regionType={regionType}
              mapping={mapping}
              sect={sectors}
            />
            </div>
            <FlightFareRulesTerms cur={req.cur} sf={sf} />
            </>
          ) : null
        )
      )}
      {sty && flt !== undefined && flt.sup !== undefined && (
        (sty === 'split' && flt.sup !== rtnflt.sup && flt.sup === "0" && parseInt(trip) !== 3) ? (
          <>
          <div className={parseInt(trip) !== 1 ? "alignment" : ""}>
            <FlightFareRules
              mapping={mapping}
              sty={sty}
              flights={flt}
              selectedFlight={seg}
              request={req}
              rtnflt={rtnflt}
              rtnseg={rtnseg}
              sf={sf}
              sectors={sectors}
              secType={secType} />

            <FlightLccFareRules
              secType={secType}
              sty={sty}
              flights={flt}
              selectedFlight={seg}
              request={req}
              rtnflt={rtnflt}
              selFlt={flt}
              regionType={regionType}
              mapping={mapping}
              sect={sectors} />
            </div>
            <FlightFareRulesTerms cur={req.cur} sf={sf} />
            </>
        ) : (
          (sty === 'split' && flt.sup !== rtnflt.sup && rtnflt.sup === "0" && parseInt(trip) !== 3) ? (
            <>
            <div className={parseInt(trip) !== 1 ? "alignment" : ""}>
              <FlightLccFareRules
                secType={secType}
                sty={sty}
                flights={flt}
                selectedFlight={seg}
                request={req}
                rtnflt={rtnflt}
                selFlt={flt}
                regionType={regionType}
                mapping={mapping}
                sect={sectors} />
                  
              <FlightFareRules
                mapping={mapping}
                sty={sty}
                flights={flt}
                selectedFlight={seg}
                request={req}
                rtnflt={rtnflt}
                rtnseg={rtnseg}
                sf={sf}
                sectors={sectors}
                secType={secType} />
            </div>
            <FlightFareRulesTerms cur={req.cur} sf={sf} />
            </>
          ) : null
        )
      )}
    {sty && selected !== undefined && (
        (parseInt(trip) === 3) && (
          <FlightStaticFareRules
            mapping={mapping}
            sect={sectors}
            secType={secType}
            flights={flt}
            rtnflt={rtnflt}
            trip={trip}
            sf={sf}
            cur={req.cur} />
          )
      )}
    </>
    },
  ];
}

  return (
    <>
    {flow === "PAYMENT" ? (
      <div className="flightDetailsPopup">
        <FlightDetails
          mapping={mapping}
          sectors={sectors}
          secType={secType}
          secTypeIndex={secTypeIndex}
          trip={trip}
          flt={flt}
          seg={seg}
          rtnflt={rtnflt}
          rtnseg={rtnseg}
          refund={refund}
          selected={selected}
          flow={flow}
          req={req}
          regionType={regionType}
      />        
    </div>
    ) : 
      <Tabs
        className="flightDetailsPopup"
        activeKey={activeTab}
        onChange={handleTabChange}
        onTabClick={handleTabPaneRender}
        items={items}
      />
    }
    </>
  );
}

export default FlightDetailsPopup;
