/**
 * @ Author: Srikanth Bandaru
 * @ Create Time: 2023-05-18 00:16:44
 * @ Description: Common utility file for overal application
 */
import CryptoJS from "crypto-js";
export const STA_SERVICE_FEE = "staFee";
const SECRET_KEY = process.env.REACT_APP_PAY_CRYPTO_SECRET_KEY;

class Utilities {
  //For price operations
  addPrice = (famt, tamt, type) => {
    tamt = tamt?tamt:0;
    if (type === "Add") {
      const fare = Number(famt) + Number(tamt);
      const tfare = (
        Math.round((Number(fare) + Number.EPSILON) * 100) / 100
      ).toFixed(2);
      return tfare;
    } else if (type === "Cnt") {
      const fare = Number(famt) * Number(tamt);
      const tfare = (
        Math.round((Number(fare) + Number.EPSILON) * 100) / 100
      ).toFixed(2);
      return tfare;
    } else if (type === "Sub") {
      const fare = Number(famt) - Number(tamt);
      const tfare = (
        Math.round((Number(fare) + Number.EPSILON) * 100) / 100
      ).toFixed(2);
      return tfare;
    } else if (type === "divide") {
      const fare = Number(famt) / Number(tamt);
      const tfare = (
        Math.round((Number(fare) + Number.EPSILON) * 100) / 100
      ).toFixed(2);
      return tfare;
    }
  };

  // round of price functions starts
  getDecimalPart(num) {
    const number = +num;
    if (Number.isInteger(number)) {
      return 0;
    }
    const decimalStr = number.toString().split('.')[1];
    return "0." + decimalStr;
  }

  roundPrice = (totalPrice) => {
    const decimalValue = this.getDecimalPart(totalPrice);
    const numDecimalValue = +decimalValue;
    const roundedPrice = numDecimalValue < 0.05 ? Math.floor(totalPrice) : Math.ceil(totalPrice);
    return roundedPrice;
  }
  // round of price functions ends

  //Calculating the Service fee for Normal flow
  calculateServiceFee(serviceList, type, request) {
    if (serviceList !== undefined && serviceList.length !== 0) {
      let totalServicePbk = 0;
      let totalServicePtkt = 0;
      let totalServiceFee = 0;
      // let service_fee = serviceList.filter((obj) => obj.type === type);
      let service_fee = serviceList;
      if (service_fee !== undefined && service_fee.length !== 0) {
        if (service_fee[0].apl_on !== undefined) {
          if (service_fee[0].apl_on === "Per Booking") {
            totalServicePbk = this.addPrice(
              service_fee[0].sf,
              service_fee[0].sup_fee,
              "Add"
            );
            if (type === "STA") {
              localStorage.setItem(STA_SERVICE_FEE, service_fee[0].sf);
            }
          } else {
            let totalsf = 0;
            let staFee = 0;
            if (service_fee[0].adt !== 0) {
              const sf = this.addPrice(service_fee[0].adt, request.adt, "Cnt");
              totalsf = sf;
              staFee = service_fee[0].adt;
            }
            if (service_fee[0].chd !== 0) {
              const sf = this.addPrice(service_fee[0].chd, request.chd, "Cnt");
              const total = this.addPrice(totalsf, sf, "Add");
              totalsf = total;
              staFee = this.addPrice(staFee, service_fee[0].chd, "Add");
            }
            if (service_fee[0].inf !== 0) {
              const sf = this.addPrice(service_fee[0].inf, request.inf, "Cnt");
              const total = this.addPrice(totalsf, sf, "Add");
              totalsf = total;
              staFee = this.addPrice(staFee, service_fee[0].inf, "Add");
            }
            totalServicePtkt = this.addPrice(
              totalsf,
              service_fee[0].sup_fee,
              "Add"
            );
            if (type === "STA") {
              localStorage.setItem(STA_SERVICE_FEE, staFee);
            }
          }
        }
      }
      if (totalServicePbk !== 0 && totalServicePtkt !== 0) {
        if (
          totalServicePbk !== "NaN" &&
          totalServicePtkt !== "NaN" &&
          totalServicePbk !== undefined &&
          totalServicePtkt !== undefined
        ) {
          totalServiceFee = this.addPrice(
            totalServicePbk,
            totalServicePtkt,
            "Add"
          );
          return parseFloat(totalServiceFee);
        } else {
          return 0.0;
        }
      } else if (totalServicePbk !== 0) {
        if (totalServicePbk !== "NaN") {
          return parseFloat(totalServicePbk);
        } else {
          return 0.0;
        }
      } else if (totalServicePtkt !== 0) {
        if (totalServicePtkt !== "NaN") {
          return parseFloat(totalServicePtkt);
        } else {
          return 0.0;
        }
      } else {
        return 0.0;
      }
    } else {
      return 0.0;
    }
  }

  calculateHoursandMinsbetweendates(endDate, startDate) {
    var timeStart = new Date(startDate).getTime();
    var timeEnd = new Date(endDate).getTime();
    var hourDiff = timeEnd - timeStart; //in ms
    var secDiff = hourDiff / 1000; //in s
    var minDiff = hourDiff / 60 / 1000; //in minutes
    var hDiff = hourDiff / 3600 / 1000; //in hours
    let hours = Math.floor(hDiff);
    return (hours);
  }

  discountCalculation(taxesObj, sty){
    let arr =0;
    if(taxesObj!==undefined){
      if(sty === undefined) {
        for(let taxIndex=0; taxIndex < taxesObj.length; taxIndex++){
        var mak=taxesObj[taxIndex];
          if(!mak.attmkp && !mak.tamkp && !mak.stamkp && !mak.YQ && !mak.OT && !mak.YR && !mak.dealmkp){
            if(mak.attdisc !== undefined) {
              arr = arr + mak.attdisc;
            }
          }
        }
      } else if(sty !== "split") {
        for(let taxIndex=0; taxIndex < taxesObj.length; taxIndex++){
        var mak=taxesObj[taxIndex];
          if(!mak.attmkp && !mak.tamkp && !mak.stamkp && !mak.YQ && !mak.OT && !mak.YR && !mak.dealmkp){
              arr = this.addPrice(arr,parseFloat(Object.values(taxesObj[taxIndex])),"Add")
          }
        }
      } else {
        for(let taxIndex=0; taxIndex < taxesObj.length; taxIndex++){
        var mak=taxesObj[taxIndex];
          if(!mak.attmkp && !mak.tamkp && !mak.stamkp && !mak.YQ && !mak.OT && !mak.YR && !mak.dealmkp){
            if(mak.attdisc !== undefined) {
              arr = arr + mak.attdisc;
            }
          }
        }
      }
    }
    return arr;
  }

  // This function will provide the Uniq JSON after dplication removed
  getUniqData(jsonarray){
    let myArrSerialized = jsonarray.map(e => JSON.stringify(e));
    const mySetSerialized = new Set(myArrSerialized);
    const myUniqueArrSerialized = [...mySetSerialized];
    const myUniqueArr = myUniqueArrSerialized.map(e => JSON.parse(e));
    return myUniqueArr;
  }

  //Retrun the List based on the Star rating
  getHotelStartRating=(count)=>{
    let startList=[];
    for (var i = 0; i < count; i++) {
      startList.push(i);
    }
    return startList;
  }

  // Return the Status based on user rating
getUserStatus=(count)=>{
  var status="";
  if(count<=2){
    status="Good";
  }else if(count>2 && count<=4){
    status="Very Good"
  }else{
    status="Excellent";
  }
  return status;
}

applyTDS(tdsPer,plbVal){
  return ((Number(tdsPer)/ 100) * (Number(plbVal))).toFixed(2);
}

getBoardBasisHotel(ha){
  let bbase =[];
  for(let taxIndex=0; taxIndex < ha.length; taxIndex++){
    var mak=ha[taxIndex];
    if(mak.id!=="2109" && mak.id!=="2195" && mak.id!=="2192" && mak.id!=="2191"){
      bbase.push(mak.name);
      
    }
    }
   if(bbase.length===0){
      bbase.push("Room Only")
    }
  return bbase.join(", ");
}

calHotelServiceFee(serviceList,type,flowType){  
  let totalServicePbk=0;
  var userType=(type==="ta_user")?"TA":"STA";
  if(serviceList
    !==undefined && serviceList.length!==0){
      // let service_fee=serviceList.filter((obj) => obj.type === userType);
  let service_fee=serviceList;
  if(service_fee!==undefined && service_fee.length!==0){
    if(flowType==="All"){
      totalServicePbk=this.addPrice(service_fee[0].sf,service_fee[0].sup_fee,"Add");
    }else{
      totalServicePbk=service_fee[0].sf;
    }
  }
 if(totalServicePbk!==undefined && totalServicePbk!==0){
  return +totalServicePbk;
 }else{
  return 0;
 }
}else{
  return 0;
}
}

taxCalculation(taxesObj, sty){
  let arr =0;
  if(sty === undefined) {
    for(let taxIndex=0; taxIndex < taxesObj.length; taxIndex++){
      var mak=taxesObj[taxIndex];
      if((!mak.attmkp && !mak.tamkp && !mak.stamkp && !mak.attdisc && !mak.tadisc && !mak.stadisc && !mak.dealmkp || (mak.OT !== undefined) || (mak.YQ !== undefined) || ((mak.YR !== undefined)))){
        if(mak.OT !== undefined) {
          arr = arr + mak.OT;
        }
        if(mak.YQ !== undefined) {
          arr = arr + mak.YQ;
        }
        if(mak.YR !== undefined) {
          arr = arr + mak.YR;
        }
      }    
    }
  } else if (sty !== "split") {
    for(let taxIndex=0; taxIndex < taxesObj.length; taxIndex++){
      var mak=taxesObj[taxIndex];
      if(!mak.attmkp && !mak.tamkp && !mak.stamkp && !mak.attdisc && !mak.tadisc && !mak.stadisc && !mak.dealmkp){
          arr = arr + parseFloat(Object.values(taxesObj[taxIndex]));
      }
    } 
  }
  else {
    for(let taxIndex=0; taxIndex < taxesObj.length; taxIndex++){
      var mak=taxesObj[taxIndex];
      if((!mak.attmkp && !mak.tamkp && !mak.stamkp && !mak.attdisc && !mak.tadisc && !mak.stadisc && !mak.dealmkp || (mak.OT !== undefined) || (mak.YQ !== undefined) || ((mak.YR !== undefined)))){
        if(mak.OT !== undefined) {
          arr = arr + mak.OT;
        }
        if(mak.YQ !== undefined) {
          arr = arr + mak.YQ;
        }
        if(mak.YR !== undefined) {
          arr = arr + mak.YR;
        }
      }    
    }
  }
  return arr;
}

hotelTaxCalculation(taxesObj){
  let arr =0;
  for(let taxIndex=0; taxIndex < taxesObj.length; taxIndex++){
  var mak=taxesObj[taxIndex];
  if(mak.OT && mak.OT!==undefined && mak.OT!==0){
      arr = arr + parseFloat(Object.values(taxesObj[taxIndex]));
  }
  }
  return arr;
}

hotelSTaxCalculation(taxesObj){
  let arr =0;
  for(let taxIndex=0; taxIndex < taxesObj.length; taxIndex++){
  var mak=taxesObj[taxIndex];
  if(mak.ST && mak.ST!==undefined && mak.ST!==0){
      arr =  mak.ST;
  }
  }
  return arr;
}

hotelDiscount(taxesObj){
  let arr =0;
  for(let taxIndex=0; taxIndex < taxesObj.length; taxIndex++){
    var mak=taxesObj[taxIndex];
    if(mak.attdisc && mak.attdisc!==undefined && mak.attdisc!==0){
        arr =  mak.attdisc;
    }
    }
  return arr;
}

generateRandomString() {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let randomString = '';
  for (let i = 0; i < 5; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    randomString += characters.charAt(randomIndex);
  }
  return randomString;
};
getBoardBasisHotel(ha){
  let bbase =[];
  for(let taxIndex=0; taxIndex < ha.length; taxIndex++){
    var mak=ha[taxIndex];
    if(mak.id!=="2109" && mak.id!=="2195" && mak.id!=="2192" && mak.id!=="2191"){
      bbase.push(mak.name);
      
    }
    }
   if(bbase.length===0){
      bbase.push("Room Only")
    }
  return bbase.join(", ");
}

createMeshKey = (options) => {
  const { srcorigns, destorigins } = options;

  // Extract information from srcorigns
  const srcRef = srcorigns[0].ref;
  const srcSdur = srcorigns[0].sdur;
  const srcStop = srcorigns[0].stop;
  const srcTktair = srcorigns[0].tktair;
  const srcSeg = srcorigns[0].seg[0];
  const srcDa = srcSeg.da;
  const srcAr = srcSeg.ar;
  const srcDd = srcSeg.dd;
  const srcAd = srcSeg.ad;
  const srcFn = srcSeg.fn;
  const srcAc = srcSeg.ac;
  const srcFdur = srcSeg.fdur;

  // Extract information from destorigins
  const destRef = destorigins && destorigins[0]?destorigins[0].ref:'';
  const destSdur = destorigins && destorigins[0]?destorigins[0].sdur:''
  const destTktair = destorigins && destorigins[0]?destorigins[0].tktair:'';
  const destSeg = destorigins && destorigins[0]?destorigins[0].seg[0]:'';
  const destDa = destSeg?destSeg.da:'';
  const destAr = destSeg?destSeg.ar:'';
  const destDd = destSeg?destSeg.dd:'';
  const destAd = destSeg?destSeg.ad:'';
  const destFn = destSeg?destSeg.fn:'';
  const destAc = destSeg?destSeg.ac:'';
  const destFdur = destSeg?destSeg.fdur:'';

  const key = `${srcRef}_${destRef}#${srcSdur}_${destSdur}#${srcStop}#${srcTktair}_${destTktair}#${srcDa}_${destDa}#${srcAr}_${destAr}#${srcDd}_${destDd}#${srcAd}_${destAd}#${srcFn}_${destFn}#${srcAc}_${destAc}#${srcFdur}_${destFdur}`;
  // Frame the key with all the values and separators
  return key;
}

//Generating the Key for API security Using Crypto JS(SHA256) and Secret Key
generateAPITokenOld(payload){
  let encrypted_key = "";
    let constructed_payload = "";
    let bodyArry = [];
    for (let itm in payload) {
      let content = payload[itm];
      if (typeof content === "object") {
        let innorObject = [];
        for (let innor in content) {
         if(content[innor]!==undefined){
          innorObject.push(`${innor}=${content[innor]}`)
         }
        }
        innorObject.sort();
        bodyArry.push(`${itm}=${innorObject}`)
      } else {
        if(content!==undefined){
          bodyArry.push(`${itm}=${content}`)
        }
      }
    }
    bodyArry.sort();
    constructed_payload = JSON.stringify(bodyArry) + SECRET_KEY
    encrypted_key = CryptoJS.SHA256(constructed_payload).toString(CryptoJS.enc.Hex);
  return encrypted_key;
};

sortKeysRecursion = async function (obj) {
  let arr = obj ? Object.keys(obj) : [];
  arr.forEach((item) => {
      if (obj[item] && typeof obj[item] === "object" && !Array.isArray(obj[item])) {
          this.sortKeysRecursion(obj[item]);
          obj[item] = this.sortKeys(obj[item]);
      }
  });
}

sortKeys = (obj) => {
  let arr = obj ? Object.keys(obj) : [];
  let matchingObject = {};
  arr = arr
      .map((v) => {
          matchingObject[v.toLowerCase()] = v;
          return v.toLowerCase();
      })
      .sort();

  return arr.reduce(
      (r, k) => ((r[matchingObject[k]] = obj[matchingObject[k]]), r),
      {}
  );
};
generateAPIToken =(payload)=>{
  let constructed_payload = "";
    try {
         let arr1 = Object.keys(payload);
         this.sortKeysRecursion(payload);
         let matchingObject = {};
        arr1 = arr1
            .map((v) => {
                matchingObject[v.toLowerCase()] = v;
                return v.toLowerCase();
            })
            .sort();
        const sortedBody = arr1.reduce(
            (r, k) => ((r[matchingObject[k]] = payload[matchingObject[k]]), r),
            {}
        );
        constructed_payload = JSON.stringify(sortedBody) + SECRET_KEY
               let encrypted_key = CryptoJS.SHA256(constructed_payload).toString(CryptoJS.enc.Hex);
        console.log("::::: KEY :::", encrypted_key);
      return encrypted_key;
    } catch (error) {
        console.log("Error in generateAPITokenNew",error)
    }
  }
}
export default new Utilities();
