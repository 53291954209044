/**
 * @ Author: Srikanth Bandaru
 * @ Create Time: 2023-05-13 14:05:26
 * @ Description: Common footer for the application
 */

import React from "react";
import { Link } from "react-router-dom";
import { Col, Row, Select, Form, Input, Button } from "antd";
import Header from "./Header";
import Footer from "./Footer";

const { TextArea } = Input;
const { Option } = Select;

function Contact(props) {

    const update=(type)=>{
    }

    

  return (
    <>
    <Header setTab={update}/>
    <div className="breadcrumbWrap">
        <div className="container">
            <ul className="breadcrumb">
                <li className="breadcrumbMenu">
                    <Link to="/">
                        Home
                    </Link>
                </li>
                <li className="breadcrumbMenu">
                    <Link to="/contact">
                        Contact
                    </Link>
                </li>
            </ul>
        </div>
    </div>
      <div className="contactPage">
        <div className="container">
        <div className="conMainHeading">Get in touch by completing the below form:</div>
            <Row gutter={30}>
                <Col span={18}>
                        <div className="contactMainWrap">
                            <div className="conSubHeading">Contact details</div>
                            <Row gutter={30}>
                                <Col span={12}>
                                    <Form.Item className="pRelative">
                                    <label className="requiredField">Full name</label>
                                    <Input
                                        type="text"
                                        className="innerBoxShadow"
                                        name="fn"
                                        placeholder="Full name"
                                    />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item className="pRelative">
                                    <label className="requiredField">Email</label>
                                    <Input
                                        type="email"
                                        className="innerBoxShadow"
                                        name="email"
                                        placeholder="Email"
                                    />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={30}>
                                <Col span={12}>
                                    <Form.Item autocomplete="off" className="pRelative">
                                        <label className="requiredField">
                                        Mobile number
                                        </label>
                                        <Input
                                        addonBefore={
                                            <Select
                                            name="phc"
                                            showSearch
                                            defaultValue="Select"
                                            //   options={stdCodes}
                                            />
                                        }
                                        className="selectBoxShadow enterContactNumber"
                                        type="number"
                                        name="phn"
                                        max={10}
                                        placeholder="Mobile number"
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item className="pRelative">
                                        <label
                                            className="requiredField">
                                            Enquiry type
                                        </label>
                                        <Select
                                            defaultValue="Select"
                                            showSearch
                                            name="na"
                                            className="selectBoxShadow"
                                            // options=(Flight,Hotel,Packages,Others)
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <div className="contactDivider"></div>
                            <div className="conSubHeading">What can we help you with?</div>
                            <div>
                                <Form.Item className="pRelative">
                                    <label className="requiredField">Summary</label>
                                    <Input
                                        type="text"
                                        className="innerBoxShadow"
                                        name="summary"
                                        placeholder="Summary"
                                    />
                                </Form.Item>
                            </div>
                            <div>
                                <label className="descTitle">Description</label>
                                <TextArea rows={4}
                                type="text"
                                name="desc"
                                className="description"
                                placeholder="Description" maxLength={200} />
                            </div>
                        </div>
                </Col>
                <Col span={6}>
                    <div className="contactMainWrap sticky">
                        <div className="conSubHeading">Other ways you can reach us</div>
                        <ul className="inquiriesWrap">
                            <li>
                                <span className="inquiriesText">General inquiries</span>
                                <Link className="inquiriesMail">support@clickntravel.com</Link>
                            </li>
                            <li>
                                <span className="inquiriesText">Flights inquiries</span>
                                <Link className="inquiriesMail">flightsupport@clickntravel.com</Link>
                            </li>
                        </ul>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col span={18}>
                    <div className="submitBtnWrap">
                        <Button type="primary" className="submitBtn">Submit</Button>
                    </div>
                </Col>
            </Row>
        </div>
      </div>
      <Footer/>
    </>
  );
}

export default Contact;
