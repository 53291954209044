import React, { useState } from "react";
import { useContext } from "react";
import { Context } from "../../../../App";
import { Collapse, Radio, Row, Col, Typography, Button } from "antd";
import Link from "antd/es/typography/Link";
import HotelBrandIcon from "../../../../assets/gif/Forever/White/H_WHITE_FOREVER.gif";


const { Panel } = Collapse;
const { Title } = Typography;

function ResultsBlock() {
  const [context, setContext] = useContext(Context)
  return (
    <>
     <Row>
        <Col span={24}>
          <div className="hotelResultsBlockWrapper">
            <div className="hotelContLeft">
              <div className="sliderWrapper">
                
                
                <div className="hotelImg240-180"></div>
               
              </div>
              <div className="fullWidth">
                <div className="hotelDetails">
                <div className="hotelReviews onlyFlex">
                    <div className="rating">
                      <span>
                        <i className=""></i>
                      </span>
                      <div className="emptyLoader40-6"></div>
                      <span className="right">
                        <i className=""></i>
                      </span>
                    </div>
                    <Title level={5} className="excellent">
                      <div className="emptyLoader60-6"></div>
                      </Title>
                  </div>
                  <div className="">
                      <Title level={4} className="hotelName">
                        <Link>
                          <div className="emptyLoader100-12"></div>
                        </Link>
                        <span className="hotelRating">
                        {/* <i className="icon icon-star"></i>
                        <i className="icon icon-star"></i>
                        <i className="icon icon-star"></i>
                        <i className="icon icon-star"></i>
                        <i className="icon icon-star"></i> */}
                        <div className="emptyLoader100-12"></div>
                      </span>
                      </Title>
                     
                          <>
                            <span className="stars">
                              <span
                                className={
                                  "star star" + Math.ceil(5)
                                }
                              ></span>
                            </span>
                          </>
                       
                      
                   
                      <div className="propertyAdress">
                        <span className="ezyIcon map icon-map"></span>
                        <Title level={5} className="hotelAddress">
                        <div className="emptyLoader80-6"></div>
                        </Title>
                      </div>
                   
                   
                      <Title level={5} className="nearestLandmark">
                        <i className=""></i> <div className="emptyLoader80-6"></div>
                      </Title>
                     
                  </div>
                  
                </div>
                <div className="hotelAmenities">
                  <span className="roomOnly">
                    <i className=""></i>
                    <div className="emptyLoader40-6"></div>
                  </span>

                 
                      <span className="hotelAmenitiesDivider"></span>
                      <span className="iconsOnly"> 
                      <div className="emptyLoader80-6"></div> 
                       </span>
                   

                 
                      <span className="hotelAmenitiesDivider"></span>
                      <span className="cancelOnly">
                        <i className=""></i>

                        <div className="emptyLoader80-6"></div>
                      </span>
                   
                </div>
              </div>
            </div>
            <div className="hotelContRight">
              
                  
                      <div className="shareIconLoader">
                        <i className=""><div className="emptyLoader30-30"></div></i>
                      </div>
             
                <div className="hotDealOffer">
                  <i className=""><div className="emptyLoaderCircle10"></div></i>
                  <div className="emptyLoader40-10"></div>
                </div>
                <div className="hpriceWrap">
                <div className="hpriceSubWrap">
                  <del>
                    <span className="countryCode"></span> <div className="emptyLoader100-12"></div>
                  </del>
                  <Title level={5} className="finalAmount">
                    <span className="countryCode"></span> <div className="emptyLoader100-12"></div>
                  </Title>
                  <span className="travellerPrice">
                  <div className="emptyLoader100-12"></div>
                  </span>
                </div>
                <Button
                      className="cntBtn hotelBtn"
                      type="primary">
                      <div className="emptyLoader100-12"></div>
                </Button>
              </div>
             
              {/* <del>
                <span> </span> <div className="emptyLoader40-6"></div>
              </del>
              <Title level={5} className="finalAmount">
                <span className="countryCode"></span> <div className="emptyLoader100-12"></div>
              </Title>
              <span className="travellerPrice">
              <div className="emptyLoader40-6"></div>
              </span>
              <Button
                    
                    className="cntBtn hotelBtn"
                    type="primary">
                    {context.translation && context.translation.chooseRoom}
                    <img src={HotelBrandIcon} alt="hotelBrandIcon" className="hotelBrandIcon" /> 
                    <div className="emptyLoader100-12"></div>
                </Button> */}
            </div>
          </div>
        </Col>
      </Row>
    </>
  )
}

function HotelSearchLoader() {
  const [context, setContext] = useContext(Context)
  const [enable, setEnable] = useState();

  return (
    <div className="container resultsPage hotelResultsPage">
      <Row gutter={30}>
      <Col xl={6} lg={0} md={0} sm={0} xs={0}>
          <div className="filterWrapper hotelFilterWrapper">
         

            <div className="filterYourResult marginTop30">
              <div className="commonFlex">
                <div>
                  <Title level={4}><div className="emptyLoader100-12"  style={{ height: "20px" }}></div></Title>
                </div>
                <div className="commonFlex">
                  <Link>
                    <div className="emptyLoader100-12"></div>
                  </Link>
                </div>
              </div>
              <span><div className="emptyLoader100-12"></div></span>
            </div>

            <Collapse
              size="small"
              defaultActiveKey={["1"]}
              items={[
                {
                key: '1',
                label: <><div className="emptyLoader100-12"></div></>,
                className: "marginTop30 rangeSliders collapseLoader",
                children: 
                  <>
                    <div className="priceLoader" style={{ margin: "0" }}></div>
                  </>,
                },
                {
                  key: '2',
                  label: <><div className="emptyLoader100-12"></div></>,
                  className: "marginTop30 custumCheckBox collapseLoader",
                  children: <>
                    <div className="stops">
                      <div className="selectListLoader"></div>
    
                      <div className="selectListLoader"></div>
                    </div>
                  </>,
                },
                {
                  key: '3',
                  label: <><div className="emptyLoader100-12"></div></>,
                  className: "marginTop30 custumRadio collapseLoader",
                  children: <>
                    <div className="selectListLoader"></div>
                    <div className="selectListLoader"></div>
                    <div className="selectListLoader"></div>
                    <div className="selectListLoader"></div>
                  </>,
                },
                {
                  key: '4',
                  label: <><div className="emptyLoader100-12"></div></>,
                  className: "marginTop30 custumRadio collapseLoader",
                  children: <>
                    <div className="selectListLoader"></div>
                    <div className="selectListLoader"></div>
                  </>,
                },
                {
                  key: '5',
                  label: <><div className="emptyLoader100-12"></div></>,
                  className: "marginTop30 custumCheckBox collapseLoader",
                  children: <>
                    <div className="selectListLoader"></div>
                    <div className="selectListLoader"></div>
                    <div className="selectListLoader"></div>
                    <div className="selectListLoader"></div>
                    <div className="selectListLoader"></div>
                  </>,
                },
                {
                  key: '6',
                  label: <><div className="emptyLoader100-12"></div></>,
                  className: "marginTop30 custumCheckBox collapseLoader",
                  children: <>
                    <div className="selectListLoader"></div>
                    <div className="selectListLoader"></div>
                    <div className="selectListLoader"></div>
                  </>,
                },
                {
                  key: '7',
                  label: <><div className="emptyLoader100-12"></div></>,
                  className: "marginTop30 custumCheckBox collapseLoader",
                  children: <>
                    <div className="selectListLoader"></div>
                    <div className="selectListLoader"></div>
                    <div className="selectListLoader"></div>
                  </>,
                }
              ]}
              >
            </Collapse>
          </div>
        </Col>
        <Col xl={18} lg={24} md={24} sm={24} xs={24} className="hotelResultsBox">
        <div className="rightSideResult">
        <Row gutter={20}>
        <Col xl={8} lg={8} md={12} xs={24}>
          <div className="cardWithShadow hotelSorting hotelSortingLoader">
            <div className="dFlex">
             <div className={enable && enable === "HOT DEAL"?"onlyFlex hotDeal active":"onlyFlex hotDeal"}  >
                <i className="" style={{ background: "transparent" }}><div className="emptyLoaderCircle32" style={{ height: "32px" }}></div></i>
                <span className="heading"><div className="emptyLoader60-12" style={{ marginTop: "6px" }}></div></span>
              </div>
              <div className={enable && enable === "RC"?"onlyFlex recomm active":"onlyFlex recomm"}  >
                <i className="" style={{ background: "transparent" }}><div className="emptyLoaderCircle32" style={{ height: "32px" }}></div></i>
                <span className="heading"><div className="emptyLoader60-12" style={{ marginTop: "6px" }}></div></span>
              </div>
            </div>
          </div>
        </Col>
        <Col xl={8} lg={8} md={12} xs={24}>
          <div className="cardWithShadow hotelSorting hotelSortingLoader">
            <div className="dFlex">
              <div className={enable && enable === "LH"?"onlyFlex lPrice active":"onlyFlex lPrice"}  >
                <i className=""><div className="emptyLoaderCircle32" style={{ height: "32px" }}></div></i>
                <span className="heading"><div className="emptyLoader60-12" style={{ marginTop: "6px" }}></div></span>
              </div>
              <div className={enable && enable === "HL"?"onlyFlex hPrice active":"onlyFlex hPrice"}  >
                <i className=""><div className="emptyLoaderCircle32" style={{ height: "32px" }}></div></i>
                <span className="heading"><div className="emptyLoader60-12" style={{ marginTop: "6px" }}></div></span>
              </div>
            </div>
          </div>
        </Col>
        <Col xl={8} lg={8} md={24} xs={24}>
          {/* <div className={context.selectLang == undefined || context.selectLang == 'en' ? "mapWrapper":"mapWrapperar"} style={{ height: "50px" }}>
          <div className="emptyLoader60-12"></div>
          </div> */}
          <div className="mapLoader"><div className="mapImg240-50"></div></div>
          
        </Col>
      </Row>

          <ResultsBlock/>
          <ResultsBlock/>
          <ResultsBlock/>
          <ResultsBlock/>
          <ResultsBlock/>
        </div>
        </Col>
      </Row>
    </div>
  );
}

export default HotelSearchLoader;
