import React, { useState, useReducer, useEffect,useContext } from "react";
import { Context } from "../../../../App";
import {
  Button,
  Col,
  Row,
  Modal,
  Typography,
  Radio,
  Alert,
  Tooltip,
  Space,
  Spin,
} from "antd";
// import Flightlogo from "../../../../assets/img/Air-Arabia.png";
import { getSeatMapDetails } from "../common/services/operations";
import brandIcon from "../../../../assets/gif/Forever/White/ICON_WHITE_FOREVER.gif"
import FormatCurrency from "../../../utilities/FormatCurrency";
import Utilities from "../../../utilities/Utilities";
export const SEL_SEG1 = "seg_1";
export const SEL_SEG2 = "seg_2";
export const SEL_SEG3 = "seg_3";
export const SEL_SEG4 = "seg_4";
export const SEL_SEG5 = "seg_5";
export const SEL_SEG6 = "seg_6";

const _ = require("lodash");
const CDNURL = process.env.REACT_APP_CNT_CDN_URL

const { Title } = Typography;

/**
 * Initial State Declaration
 */
const initialState = {
  noRecords: false,
  notiMessage: "",
  notiMessageShow: false,
  notiMessageShowModal: false,
  notiVarient: "",
  segmentList: [],
  flt_no: "",
  airline: "",
  seatsList: [],
  showSegments: true,
  showSetMap: true,
  confirmSeat: false,
  showSlectedSeats: false,
  paxList: 0,
};


// Reducer function for useReact Hook which will update the state
const reducer = (state, action) => {
  switch (action.type) {
    case "clear":
      return {
        ...state,
      };
    default:
      return { ...state, [action.type]: action.payload };
  }
};

function SeatSelection(props) {
  const [context, setContext] = useContext(Context)
  // const [isModalOpen, setIsModalOpen] = useState(false);
  const [state, dispatch] = useReducer(reducer, initialState);
  const [show, setShow] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [seatLoading, setSeatLoading] = useState(false);
  const [tepmSeglist, setTempSegList] = useState([]);
  // const [seatIdToDelete, setSeatIdToDelete] = useState(0);

  // const showModal = () => {
  //   setIsModalOpen(true);
  // };

  const handleCancel = () => {
    // setIsModalOpen(false);
    dispatch({ type: "segmentList", payload: tepmSeglist });
    setShow(false);
    setIsLoadingMore(false);
  };

  useEffect(() => {
    if (props.paxList.length !== 0 && state.segmentList.length === 0) {   
      constructSeatMap();
      dispatch({ type: "paxList", payload: props.paxList.length });
    }
  }, [props.paxList]);

  const constructSeatMap = () => {
    let segmentsList = [];
    let counter = 1;
    if (
      props.srequest.origns[0].srcorigns[0].sup === "2" ||
      props.srequest.origns[0].srcorigns[0].sup === "4" ||
      props.srequest.origns[0].srcorigns[0].sup === "3" ||
      props.srequest.origns[0].srcorigns[0].sup === "7"
    ) {
      for (let seg of props.srequest.origns[0].srcorigns[0].seg) {
        seg.seg_id = counter;
        const payload = {
          seg_id: counter,
          dept: seg.da,
          flt_num: seg.fn,
          air_code: seg.ma,
          arvl: seg.ar,
          jtype: "O",
          selected: counter == 1 ? true : false,
          paxlist: createSeatPaxList(),
          tid: props.srequest.origns[0].srcorigns[0].tid,
        };
        if (counter === 1) {
          dispatch({ type: "flt_no", payload: seg.fn });
          dispatch({ type: "airline", payload: seg.ma });
        }
        segmentsList.push(payload);
        counter = counter + 1;
      }
    }
    if (props.srequest.tt === "2" || props.srequest.tt === 2) {
      if (
        props.srequest.origns[0].destorigins[0].sup === "2" ||
        props.srequest.origns[0].destorigins[0].sup === "3" ||
        props.srequest.origns[0].destorigins[0].sup === "4" ||
        props.srequest.origns[0].destorigins[0].sup === "7"
      ) {
        for (let seg of props.srequest.origns[0].destorigins[0].seg) {
          seg.seg_id = counter;
          const payload1 = {
            seg_id: counter,
            dept: seg.da,
            arvl: seg.ar,
            flt_num: seg.fn,
            air_code: seg.ma,
            selected: counter == 1 ? true : false,
            jtype: "R",
            paxlist: createSeatPaxList(),
            tid:
              props.srequest.sty == "split"
                ? props.srequest.origns[0].destorigins[0].tid
                : props.srequest.origns[0].srcorigns[0].tid,
          };
          if (counter=== 1) {
            dispatch({ type: "flt_no", payload: seg.fn });
            dispatch({ type: "airline", payload: seg.ma });
          }
          segmentsList.push(payload1);
          counter = counter + 1;
        }
      }
    }
    dispatch({ type: "segmentList", payload: segmentsList });
  };

 

  const createSeatPaxList = () => {
    let paxList = [];
    if (props.paxList.length !== 0) {
      for (let pax of props.paxList) {
        if (pax.fn !== "" && pax.ln !== "") {
          if (pax.ty !== "INF") {
            const req = {
              pax_id: pax.sno,
              pax_name: pax.tl + " " + pax.fn + " " + pax.ln,
              seat: "",
              rno: "",
              st: "",
              price: 0,
              sup_price: 0,
              sup_cur_price: 0,
              selected:false,
              is_inf:
                pax.inf !== undefined && pax.inf !== "" && pax.inf.ty === "INF"
                  ? 1
                  : 0,
            };
            paxList.push(req);
          }
        }
      }
      return paxList;
    }
  };


  const hidemessage = () => {
    setTimeout(function () {
      dispatch({ type: "notiMessageShow", payload: false });
      dispatch({ type: "notiMessageShowModal", payload: false });
      dispatch({ type: "notiMessage", payload: "" });
      dispatch({ type: "notiVarient", payload: "" });
    }, 5000);
  };

  const calculatePrice = (seg) => {
    let totalPrice = 0;
    for (let pax of seg.paxlist) {
      if (pax.seat !== "" && pax.price !== 0 && pax.price !== undefined) {
        if (totalPrice === 0) {
          totalPrice = Number(pax.price);
        } else {
          totalPrice = Number(totalPrice + Number(pax.price));
        }
      }
    }
    return totalPrice;
  };

  const handleChangeSegment = (segId) => (e) => {
    for (let obj of state.segmentList) {
      if (obj.seg_id === 1) {
        obj.selected = false;
      }
      if (obj.seg_id === segId) {
        dispatch({ type: "showSetMap", payload: false });
        setSeatLoading(true);
        obj.selected = true;
        var fltNumber = "";
        var airCode = "";
        let request = _.cloneDeep(props.srequest, false, "");
        for (let val of props.srequest.origns[0].srcorigns[0].seg) {
          if((props.srequest.origns[0].srcorigns[0].stop == 1 && segId <= 2) || segId == 1){
          if (val.seg_id === segId) {
            const SEG = "seg_" + segId;
            let selSegData1 = JSON.parse(window.sessionStorage.getItem(SEG));
            let restriction = false
            if(props.srequest.sty == "normal") {
              if(request.origns[0].srcorigns[0].sup == "7") {
                restriction = true
              }
            }
            if (selSegData1 !== null && selSegData1 !== undefined) {
                for(let val of selSegData1){
                  for(let seat of val.seats){
                    seat.selected=false;
                  }
                }
                if (obj.selected && obj.seg_id === segId) {
                  fltNumber = obj.flt_num;
                  airCode = obj.air_code;
                  for (let pax of obj.paxlist) {
                    for (let seat of selSegData1) {
                      if (
                        pax.rno !== undefined &&
                        pax.rno === seat.rno &&
                        obj.jtype === "O"
                      ) {
                        for (let s of seat.seats) {
                          if (s.sn === pax.seat) {
                            s.selected = true;
                            break;
                          }
                        }
                      }
                    }
                  }
                }
              dispatch({ type: "seatsList", payload: selSegData1 });
              dispatch({ type: "showSetMap", payload: true });
              setSeatLoading(false);
              dispatch({ type: "flt_no", payload: fltNumber });
              dispatch({ type: "airline", payload: airCode });
            } else if(!restriction){
              if(request.origns[0].srcorigns[0].sup!=="2" && request.origns[0].srcorigns[0].sup!=="7"){
              request.origns[0].srcorigns[0].seg = [val];
              }else{
              request.origns[0].srcorigns[0].sel_ref=val.ref;
              }
              // if(request.sup == undefined) {
              request.sup = props.srequest.origns[0].srcorigns[0].sup;
              if(props.srequest.sty === "split"){
                request.tid= props.srequest.origns[0].srcorigns[0].tid
              }

              // }
              delete request.origns[0].destorigins;
              delete request.mappings;
              delete request.psngrs;
              delete request.flowType;
              let tokenKey = Utilities.generateAPIToken(request);
              getSeatMapDetails(request,tokenKey).then((response) => {
                if (response.status === "success") {
                  if (request.origns[0].srcorigns[0].sup == 7) {
                    //riya travels
                    let riya_seat_response = response.data
                    for (let eachSegment of state.segmentList) {
                      let seat_availability = riya_seat_response.filter(function (s) {
                        if (s.segment == (eachSegment.dept + "_" + eachSegment.arvl)) {
                          return s
                        }
                      })
                      if (seat_availability && seat_availability.length > 0) {
                        if (eachSegment.seg_id == 1) {
                          dispatch({ type: "seatsList", payload: seat_availability[0].res });
                        }
                        let tab_value = eachSegment.seg_id == 1 ? SEL_SEG1 : eachSegment.seg_id == 2 ? SEL_SEG2 : eachSegment.seg_id == 3 ? SEL_SEG3 : eachSegment.seg_id == 4 ? SEL_SEG4 : eachSegment.seg_id == 5 ? SEL_SEG5 : SEL_SEG6
                        window.sessionStorage.setItem(
                          tab_value,
                          JSON.stringify(seat_availability[0].res)
                        );
      
                      }
                    }
      
                  } else {
                    dispatch({ type: "seatsList", payload: response.data });
                    const SEG = "seg_" + segId;
                    window.sessionStorage.setItem(
                      SEG,
                      JSON.stringify(response.data)
                    );
                  }
                  // dispatch({ type: "seatsList", payload: response.data });
                  setShow(true);
                  dispatch({ type: "showSetMap", payload: true });
                  setSeatLoading(false);
                  dispatch({ type: "flt_no", payload: obj.flt_num });
                  dispatch({ type: "airline", payload: obj.air_code });
                  const SEG = "seg_" + segId;
                  // window.sessionStorage.setItem(
                  //   SEG,
                  //   JSON.stringify(response.data)
                  // );
                } else {
                  dispatch({ type: "notiMessageShow", payload: true });
                  dispatch({
                    type: "notiMessage",
                    payload: (context.translation && context.translation.seatnotavail),
                  });
                  dispatch({ type: "notiVarient", payload: "error" });
                  hidemessage();
                  setIsLoadingMore(false);
                }
              }).catch(function (error) {
                console.error("Error:" + error);      
              });
            }
          }
        } else if ((props.srequest.tt === "2" || props.srequest.tt === 2) && segId >= 2){
            const SEG = "seg_" + segId;
            let selSegData2 = JSON.parse(window.sessionStorage.getItem(SEG));
            var fltNumber = "";
            var airCode = "";
            let restriction = false
            if(props.srequest.sty == "normal") {
              if(request.origns[0].destorigins && request.origns[0].destorigins[0].sup == "7") {
                restriction = true
              }
            }
            if (selSegData2 !== null && selSegData2 !== undefined) {
              for(let val of selSegData2){
                for(let seat of val.seats){
                  seat.selected=false;
                }
              }
              for (let seg of state.segmentList) {
                if (seg.selected && seg.seg_id === segId) {
                  fltNumber = seg.flt_num;
                  airCode = seg.air_code;
                  for (let pax of seg.paxlist) {
                    for (let seat of selSegData2) {
                      if (
                        pax.rno !== undefined &&
                        pax.rno === seat.rno &&
                        seg.jtype === "R"
                      ) {
                        for (let obj of seat.seats) {
                          if (obj.sn === pax.seat) {
                            obj.selected = true;
                            break;
                          }
                        }
                      }
                    }
                  }
                }
              }
              dispatch({ type: "seatsList", payload: selSegData2 });
              dispatch({ type: "showSetMap", payload: true });
              setSeatLoading(false);
              dispatch({ type: "flt_no", payload: fltNumber });
              dispatch({ type: "airline", payload: airCode });
            } else if(props.srequest.origns[0].destorigins && !restriction){
              for (let val of props.srequest.origns[0].destorigins[0].seg) {
                if (val.seg_id === segId) {
                  if(request.origns[0].srcorigns[0].sup!=="2" && props.srequest.origns[0].destorigins[0].sup!=="7"){
                    request.origns[0].srcorigns[0].seg = [val];
                    }else{
                    request.origns[0].srcorigns=props.srequest.origns[0].destorigins;
                    request.origns[0].srcorigns[0].sel_ref=val.ref;
                    }
                  delete request.origns[0].destorigins;
                  delete request.mappings;
                  delete request.psngrs;
                  delete request.flowType;
                  request.sup = props.srequest.origns[0].destorigins[0].sup;
                  if(props.srequest.sty === "split"){
                  request.tid= props.srequest.origns[0].destorigins[0].tid
                  }

                  if (request.sup == 3) {
                    if (props.srequest.origns[0]?.destorigins[0]?.ugk) {
                      request.origns[0].srcorigns[0].ugk = props.srequest.origns[0]?.destorigins[0].ugk;
                      request.ugk = props.srequest.origns[0]?.destorigins[0].ugk;
                      request.sup_currency = props.srequest.origns[0]?.destorigins[0]?.sup_currency;
                    }
                  }
                  request.src_code = props.srequest.dest_code
                  request.dest_code = props.srequest.src_code
                  try {
                    let tokenKey = Utilities.generateAPIToken(request);
                    getSeatMapDetails(request,tokenKey).then((response) => {
                      if (response.status === "success") {
                        if (request.origns[0].srcorigns[0].sup == 7) {
                          //riya travels
                          let riya_seat_response = response.data
                          for (let eachSegment of state.segmentList) {
                            let seat_availability = riya_seat_response.filter(function (s) {
                              if (s.segment == (eachSegment.dept + "_" + eachSegment.arvl)) {
                                return s
                              }
                            })
                            if (seat_availability && seat_availability.length > 0) {
                              if (eachSegment.seg_id == 1) {
                                dispatch({ type: "seatsList", payload: seat_availability[0].res });
                              }
                              let tab_value = eachSegment.seg_id == 1 ? SEL_SEG1 : eachSegment.seg_id == 2 ? SEL_SEG2 : eachSegment.seg_id == 3 ? SEL_SEG3 : eachSegment.seg_id == 4 ? SEL_SEG4 : eachSegment.seg_id == 5 ? SEL_SEG5 : SEL_SEG6
                              window.sessionStorage.setItem(
                                tab_value,
                                JSON.stringify(seat_availability[0].res)
                              );
            
                            }
                          }
            
                        } else {
                          dispatch({ type: "seatsList", payload: response.data });
                          const SEG = "seg_" + segId;
                          window.sessionStorage.setItem(
                            SEG,
                            JSON.stringify(response.data)
                          );
                        }
                        // dispatch({ type: "seatsList", payload: response.data });
                        dispatch({ type: "showSetMap", payload: true });
                        setSeatLoading(false);
                        dispatch({ type: "flt_no", payload: obj.flt_num });
                        dispatch({ type: "airline", payload: obj.air_code });
                        setShow(true);
                        const SEG = "seg_" + segId;
                        // window.sessionStorage.setItem(
                        //   SEG,
                        //   JSON.stringify(response.data)
                        // );
                      } else {
                        dispatch({ type: "notiMessageShow", payload: true });
                        dispatch({
                          type: "notiMessage",
                          payload:
                            (context.translation && context.translation.seatnotavail),
                        });
                        dispatch({ type: "notiVarient", payload: "error" });
                        hidemessage();
                        setIsLoadingMore(false);
                      }
                    }).catch(function (error) {
                      console.error("Error in SeatMap :" + error);      
                    });                    
                  } catch (error) {
                    console.error("Error in SeatMap :" + error);                    
                  }
                }
              }
            }
          }
        }
      } else {
        obj.selected = false;
      }
    }
  };

  const handleShow = () => {
    // setIsModalOpen(true);
    dispatch({ type: "seatsList", payload: [] });
    setShow(false);
    setIsLoadingMore(false);
    dispatch({ type: "showSetMap", payload: false });
    setSeatLoading(true);
    constructSeatMap();
    window.sessionStorage.removeItem(SEL_SEG1);
    window.sessionStorage.removeItem(SEL_SEG2);
    window.sessionStorage.removeItem(SEL_SEG3);
    window.sessionStorage.removeItem(SEL_SEG4);
    window.sessionStorage.removeItem(SEL_SEG5);
    window.sessionStorage.removeItem(SEL_SEG6);
    let request = "";
    if (
      props.paxList !== undefined &&
      props.paxList.length !== 0 &&
      props.paxList[0].fn !== "" &&
      props.paxList[0].ln !== ""
    ) {
      setIsLoadingMore(true);
      if (props.srequest.sty === "normal") {
        request = _.cloneDeep(props.srequest, false, "");
        if(request.origns[0].srcorigns[0].sup!=="2" && request.origns[0].srcorigns[0].sup!=="7"){
           request.origns[0].srcorigns[0].seg = [
            props.srequest.origns[0].srcorigns[0].seg[0],
          ];
        }else{
          request.origns[0].srcorigns = props.srequest.origns[0].srcorigns;
          request.origns[0].srcorigns[0].sel_ref=1;
        }
       
        if(request.origns[0].srcorigns[0].sup!=="7") {
        delete request.origns[0].destorigins;
        }
        request.sup = request.origns[0].srcorigns[0].sup;
        delete request.mappings;
        delete request.psngrs;
        delete request.flowType;
        for (let pax of props.paxList) {
          pax.seats = [];
        }
        request.psngrs = props.paxList
      } else {
        for (let org of props.srequest.origns) {
          if (
            org.srcorigns[0].sup === "2" ||
            org.srcorigns[0].sup === "3" ||
            org.srcorigns[0].sup === "4" ||
            org.srcorigns[0].sup === "7"
          ) {
            request = _.cloneDeep(props.srequest, false, "");
            request.origns[0].srcorigns = org.srcorigns;
            if((request.origns[0].srcorigns[0].sup!=="2" || request.origns[0].srcorigns[0].sup!=="0")  && (request.origns[0].srcorigns[0].sup!=="7" || request.origns[0].srcorigns[0].sup!=="0")){
            // if(request.origns[0].srcorigns[0].sup!=="2"  && request.origns[0].srcorigns[0].sup!=="7"){
              request.origns[0].srcorigns[0].sel_ref=1;
            }
            request.sup = request.origns[0].srcorigns[0].sup;
            request.tid= org.srcorigns[0].tid
            delete request.origns[0].destorigins;
            delete request.mappings;
            delete request.psngrs;
            delete request.flowType;
            for (let pax of props.paxList) {
              pax.seats = [];
            }
            request.psngrs = props.paxList
          } else {
            request = _.cloneDeep(props.srequest, false, "");
            request.origns[0].srcorigns = org.destorigins;
            request.sup = request.origns[0].srcorigns[0].sup;
            if((request.origns[0].srcorigns[0].sup!=="2" || request.origns[0].srcorigns[0].sup!=="0")  && (request.origns[0].srcorigns[0].sup!=="7" || request.origns[0].srcorigns[0].sup!=="0")){
          // if(request.origns[0].srcorigns[0].sup!=="2"  && request.origns[0].srcorigns[0].sup!=="7"){
              request.origns[0].srcorigns[0].sel_ref=1;
            }
            delete request.origns[0].destorigins;
            delete request.mappings;
            delete request.psngrs;
            delete request.flowType;
            request.tid= org.destorigins[0].tid
            request.src_code = props.srequest.dest_code
            request.dest_code = props.srequest.src_code
            for (let pax of props.paxList) {
              pax.seats = [];
            }
            request.psngrs = props.paxList
          }
          dispatch({ type: "seatsList", payload: [] });
        }
      }
      let tokenKey = Utilities.generateAPIToken(request);
      getSeatMapDetails(request,tokenKey).then((response) => {
        if (response.status === "success") {
          setIsLoadingMore(true);
          
          // if (props.srequest.sty === "normal") {

            if (request.origns[0].srcorigns[0].sup == 7) {
              //riya travels
              let riya_seat_response = response.data
              for (let eachSegment of state.segmentList) {
                let seat_availability = riya_seat_response.filter(function (s) {
                  if (s.segment == (eachSegment.dept + "_" + eachSegment.arvl)) {
                    return s
                  }
                })
                if (seat_availability && seat_availability.length > 0) {
                  if (eachSegment.seg_id == 1) {
                    dispatch({ type: "seatsList", payload: seat_availability[0].res });
                  }
                  let tab_value = eachSegment.seg_id == 1 ? SEL_SEG1 : eachSegment.seg_id == 2 ? SEL_SEG2 : eachSegment.seg_id == 3 ? SEL_SEG3 : eachSegment.seg_id == 4 ? SEL_SEG4 : eachSegment.seg_id == 5 ? SEL_SEG5 : SEL_SEG6
                  window.sessionStorage.setItem(
                    tab_value,
                    JSON.stringify(seat_availability[0].res)
                  );

                }
              }

            } else {
              dispatch({ type: "seatsList", payload: response.data });
              window.sessionStorage.setItem(
                SEL_SEG1,
                JSON.stringify(response.data)
              );
            }

         

          setShow(true);
          dispatch({ type: "showSetMap", payload: true });
          setSeatLoading(false);

        } else {
          if (
            props.srequest.sty === "normal" &&
            props.srequest.origns[0].destorigins != undefined && props.srequest.origns[0].destorigins[0].sup != 7
          ) {
            var request1 = _.cloneDeep(props.srequest, false, "");
            if(request.origns[0].srcorigns[0].sup!=="2" && request.origns[0].srcorigns[0].sup!=="7"){
            request1.origns[0].srcorigns[0].seg = [
              props.srequest.origns[0].destorigins[0].seg[0],
            ];
           }else{
            request1.origns[0].srcorigns = props.srequest.origns[0].destorigins;
           }
            delete request1.origns[0].destorigins;
            delete request1.mappings;
            delete request1.psngrs;
            delete request1.flowType;
            let tokenKey = Utilities.generateAPIToken(request1);
            getSeatMapDetails(request1,tokenKey).then((resp) => {
              if (resp.status === "success") {
                if (request.origns[0].srcorigns[0].sup == 7) {
                  //riya travels
                  let riya_seat_response = response.data
                  for (let eachSegment of state.segmentList) {
                    let seat_availability = riya_seat_response.filter(function (s) {
                      if (s.segment == (eachSegment.dept + "_" + eachSegment.arvl)) {
                        return s
                      }
                    })
                    if (seat_availability && seat_availability.length > 0) {
                      if (eachSegment.seg_id == 1) {
                        dispatch({ type: "seatsList", payload: seat_availability[0].res });
                      }
                      let tab_value = eachSegment.seg_id == 1 ? SEL_SEG1 : eachSegment.seg_id == 2 ? SEL_SEG2 : eachSegment.seg_id == 3 ? SEL_SEG3 : eachSegment.seg_id == 4 ? SEL_SEG4 : eachSegment.seg_id == 5 ? SEL_SEG5 : SEL_SEG6
                      window.sessionStorage.setItem(
                        tab_value,
                        JSON.stringify(seat_availability[0].res)
                      );
    
                    }
                  }
    
                } else {
                  dispatch({ type: "seatsList", payload: response.data });
                  window.sessionStorage.setItem(
                    SEL_SEG1,
                    JSON.stringify(response.data)
                  );
                }
                // dispatch({ type: "seatsList", payload: resp.data });
                setIsLoadingMore(true);
                setShow(true);
                dispatch({ type: "showSetMap", payload: true });
                setSeatLoading(false);
                // window.sessionStorage.setItem(
                //   SEL_SEG1,
                //   JSON.stringify(resp.data)
                // );
              } else {
                dispatch({ type: "notiMessageShow", payload: true });
                dispatch({
                  type: "notiMessage",
                  payload: (context.translation && context.translation.seatnotavail),
                });
                dispatch({ type: "notiVarient", payload: "error" });
                hidemessage();
                setIsLoadingMore(false);
              }
            }).catch(function (error) {
              console.error("Error:" + error);      
            });
          } else {
            dispatch({ type: "notiMessageShow", payload: true });
            dispatch({
              type: "notiMessage",
              payload: (context.translation && context.translation.seatnotavail),
            });
            dispatch({ type: "notiVarient", payload: "error" });
            hidemessage();
            setIsLoadingMore(false);
          }
        }
      }).catch(function (error) {
        console.error("Error:" + error);      
      });
    } else {
      dispatch({ type: "notiMessageShow", payload: true });
      dispatch({
        type: "notiMessage",
        payload: (context.translation && context.translation.plzentpaxdetails),
      });
      dispatch({ type: "notiVarient", payload: "error" });
      hidemessage();
    }
  };

  const handleEditShow = (id) => {
    let selSegData1 = JSON.parse(window.sessionStorage.getItem(SEL_SEG1));
    if (selSegData1 !== null && selSegData1 !== undefined) {
      for(let val of selSegData1){
        for(let seat of val.seats){
          seat.selected=false;
        }
      }
      let counter = id;
      for (let seg of state.segmentList) {
        if (seg.seg_id === id) {
          seg.selected = true;
        } else {
          seg.selected = false;
        }
        for (let pax of seg.paxlist) {
          for (let seat of selSegData1) {
            if (
              pax.rno !== undefined &&
              pax.rno === seat.rno &&
              seg.jtype === "O" && counter === 1
            ) {
              for (let obj of seat.seats) {
                if (obj.sn === pax.seat) {
                  obj.selected = true;
                  break;
                }
              }
            }
          }
        }
        counter = counter + 1;
      }
      let list = _.cloneDeep(state.segmentList);
      setTempSegList(list);
      dispatch({ type: "seatsList", payload: selSegData1 });
      dispatch({ type: "showSetMap", payload: true });
      setSeatLoading(false);
      dispatch({ type: 'flt_no', payload: list[0].flt_num })
      dispatch({ type: 'airline', payload: list[0].air_code })
      setShow(true);
    }
  };

  const checkIndigoInfAvail = (infAvail, infSeat,isPaxSelect) => {
    let checkInf = true;
    if (
      props.srequest.sty === "normal" &&
      props.srequest.origns[0].srcorigns[0].sup === "4"
    ) {
      if (infAvail === 1 && !infSeat && !isPaxSelect) {
        checkInf = false;
      }
    } else if (props.srequest.sty === "split") {
      let osupplier = props.srequest.origns[0].srcorigns[0].sup;
      let retsupplier = props.srequest.origns[0].destorigins[0].sup;
      if (osupplier === "4" || retsupplier === "4") {
        if (infAvail === 1 && !infSeat && !isPaxSelect) {
          checkInf = false;
        }
      }
    }
    return checkInf;
  };

  const handleDeleteSelectSeat = (object, rowNo) => {
    let selectedId;
    if(object.seat === "A") {
      selectedId = 1;
    } else if (object.seat === "B") {
      selectedId = 2;
    } else if (object.seat === "C") {
      selectedId = 3;
    } else if (object.seat === "D") {
      selectedId = 4;
    } else if (object.seat === "E") {
      selectedId = 5;
    } else if (object.seat === "F") {
      selectedId = 6;
    }
    let obj = {id:selectedId, infant:object.is_inf === 0 ? false: true, price:object.price, selected:object.selected, sn:object.seat, st:object.st, sup_cur_price:object.sup_cur_price, sup_price:object.sup_price}
    let paxIsTrue = true
    dispatch({ type: "showSegments", payload: false });
    for (let seg of state.segmentList) {
      if (seg.selected) {
        for (let sobj of state.seatsList) {
          if (sobj.rno === rowNo) {
            for (let seat of sobj.seats) {
              for (let pax of seg.paxlist) {
                if(paxIsTrue){
                if (seat.id === obj.id) {
                  if (
                    seat.infant === undefined ||
                    (seat.infant !== undefined &&
                      checkIndigoInfAvail(pax.is_inf, seat.infant,pax.selected))
                  ) {
                    if (pax.seat === "" && !obj.selected) {
                      if (obj.seatKey) {
                        pax.seatKey = obj.seatKey;
                        pax.segKey = obj.segKey;
                      }
                      if(obj.seatId) {
                        pax.seatId = obj.seatId
                      }
                      pax.seat = obj.sn;
                      pax.rno = rowNo;
                      pax.price = obj.price;
                      pax.sup_price = obj.sup_price;
                      pax.sup_cur_price = obj.sup_cur_price;
                      pax.st = obj.st;
                      pax.selected=true;
                      obj.selected = true;
                      seg.price = calculatePrice(seg);
                      seat.selected = true;
                      dispatch({ type: "showSegments", payload: true });
                      if (props.srequest.sty === "split") {
                        window.sessionStorage.setItem(
                          SEL_SEG1,
                          JSON.stringify(state.seatsList)
                        );
                      }
                      paxIsTrue=!paxIsTrue
                      break;
                    } else {
                      if (
                        pax.seat !== "" &&
                        pax.seat === obj.sn &&
                        pax.rno === rowNo &&
                        seat.id === obj.id &&
                        obj.selected
                      ) {
                        if (obj.seatKey) {
                          pax.seatKey = obj.seatKey;
                          pax.segKey = obj.segKey;
                        }
                        if(obj.seatId) {
                          pax.seatId = obj.seatId
                        }
                        seat.selected = false;
                        obj.selected = false;
                        pax.seat = "";
                        pax.rno = "";
                        seg.price = seg.price - pax.price;
                        pax.sup_price = 0;
                        pax.sup_cur_price = 0;
                        pax.price = 0;
                        pax.sup_price = 0;
                        pax.sup_cur_price = 0;
                        pax.selected=false;
                        paxIsTrue=!paxIsTrue
                        break;
                      }
                    }
                  } else {
                    dispatch({ type: "notiMessageShowModal", payload: true });
                    dispatch({
                      type: "notiMessage",
                      payload: (context.translation && context.translation.infantSeat),
                    });
                    dispatch({ type: "notiVarient", payload: "error" });
                    hidemessage();
                    break;
                  }
                }
              }
             }
            }
          }
        }
      }
    }
    dispatch({ type: "showSegments", payload: true });
  };

  
  
  const handleSelectSeat = (obj, rowNo) => {
    // setSeatIdToDelete(obj.id);
    let paxIsTrue = true
    dispatch({ type: "showSegments", payload: false });
    for (let seg of state.segmentList) {
      if (seg.selected) {
        for (let sobj of state.seatsList) {
          if (sobj.rno === rowNo) {
            for (let seat of sobj.seats) {
              for (let pax of seg.paxlist) {
                if(paxIsTrue){
                if (seat.id === obj.id) {
                  if (
                    seat.infant === undefined ||
                    (seat.infant !== undefined &&
                      checkIndigoInfAvail(pax.is_inf, seat.infant,pax.selected))
                  ) {
                    if (pax.seat === "" && !obj.selected) {
                      if (obj.seatKey) {
                        pax.seatKey = obj.seatKey;
                        pax.segKey = obj.segKey;
                      }
                      if(obj.seatId) {
                        pax.seatId = obj.seatId
                      }
                      pax.seat = obj.sn;
                      pax.rno = rowNo;
                      pax.price = obj.price;
                      pax.sup_price = obj.sup_price;
                      pax.sup_cur_price = obj.sup_cur_price;
                      pax.st = obj.st;
                      pax.selected=true;
                      obj.selected = true;
                      seg.price = calculatePrice(seg);
                      seat.selected = true;
                      dispatch({ type: "showSegments", payload: true });
                      if (props.srequest.sty === "split") {
                        window.sessionStorage.setItem(
                          SEL_SEG1,
                          JSON.stringify(state.seatsList)
                        );
                      }
                      paxIsTrue=!paxIsTrue
                      break;
                    } else {
                      if (
                        pax.seat !== "" &&
                        pax.seat === obj.sn &&
                        pax.rno === rowNo &&
                        seat.id === obj.id &&
                        obj.selected
                      ) {
                        if (obj.seatKey) {
                          pax.seatKey = obj.seatKey;
                          pax.segKey = obj.segKey;
                        }
                        if(obj.seatId) {
                          pax.seatId = obj.seatId
                        }
                        seat.selected = false;
                        obj.selected = false;
                        pax.seat = "";
                        pax.rno = "";
                        seg.price = seg.price - pax.price;
                        pax.sup_price = 0;
                        pax.sup_cur_price = 0;
                        pax.price = 0;
                        pax.sup_price = 0;
                        pax.sup_cur_price = 0;
                        pax.selected=false;
                        paxIsTrue=!paxIsTrue
                        break;
                      }
                    }
                  } else {
                    dispatch({ type: "notiMessageShowModal", payload: true });
                    dispatch({
                      type: "notiMessage",
                      payload: (context.translation && context.translation.infantSeat),
                    });
                    dispatch({ type: "notiVarient", payload: "error" });
                    hidemessage();
                    break;
                  }
                }
              }
             }
            }
          }
        }
      }
    }
    dispatch({ type: "showSegments", payload: true });
  };

  const confirmSeat = () => {
    let checkPax = true;
    var seat = "";
    var seatRet = "";
    if (
      props.srequest.sty === "normal" &&
      (props.onwardSupp === "2" ||
        props.onwardSupp === "3" ||
        props.onwardSupp === "4")
    ) {
      seat = props.srequest.origns[0].srcorigns[0].pr.seats;
      if (props.srequest.tt === "2" || props.srequest.tt === 2) {
        seatRet = props.srequest.origns[0].destorigins[0].pr.seats;
      }
    }
    if (props.srequest.sty === "split") {
      if (
        props.onwardSupp === "2" ||
        props.onwardSupp === "3" ||
        props.onwardSupp === "4"
      ) {
        seat = props.srequest.origns[0].srcorigns[0].pr.seats;
      }
      if (
        props.returnSupp === "2" ||
        props.returnSupp === "4" ||
        props.returnSupp === "3"
      ) {
        seatRet = props.srequest.origns[0].destorigins[0].pr.seats;
      }
    }
    for (let seg of state.segmentList) {
      for (let pax of seg.paxlist) {
        if (pax.seat === "" && props.srequest.sty === "normal") {
          if (props.srequest.tt === "1" || props.srequest.tt === 1) {
            if (seat !== "" && seat === "Free") {
              checkPax = false;
              dispatch({ type: "notiMessageShow", payload: true });
              dispatch({
                type: "notiMessage",
                payload: (context.translation && context.translation.plzseleSeatallpax),
              });
              dispatch({ type: "notiVarient", payload: "error" });
              hidemessage();
            }
          } else {
            if (seg.seg_id === 1 && seat !== "" && seat === "Free") {
              checkPax = false;
              dispatch({ type: "notiMessageShow", payload: true });
              dispatch({
                type: "notiMessage",
                payload: (context.translation && context.translation.plzseleSeatallpax),
              });
              dispatch({ type: "notiVarient", payload: "error" });
              hidemessage();
            } else if (
              seg.seg_id === 2 &&
              seatRet !== "" &&
              seatRet === "Free"
            ) {
              checkPax = false;
              dispatch({ type: "notiMessageShow", payload: true });
              dispatch({
                type: "notiMessage",
                payload: (context.translation && context.translation.plzseleSeatallpax),
              });
              dispatch({ type: "notiVarient", payload: "error" });
              hidemessage();
            }
          }
        } else if (props.srequest.sty === "split") {
          if (
            seg.seg_id === 1 &&
            pax.seat === "" &&
            seat === "Free" &&
            seg.selected
          ) {
            checkPax = false;
            dispatch({ type: "notiMessageShow", payload: true });
            dispatch({
              type: "notiMessage",
              payload: (context.translation && context.translation.plzseleSeatallpax),
            });
            dispatch({ type: "notiVarient", payload: "error" });
            hidemessage();
            break;
          } else if (
            seg.seg_id === 2 &&
            pax.seat === "" &&
            seatRet === "Free" &&
            seg.selected
          ) {
            checkPax = false;
            dispatch({ type: "notiMessageShow", payload: true });
            dispatch({
              type: "notiMessage",
              payload: (context.translation && context.translation.plzseleSeatallpax),
            });
            dispatch({ type: "notiVarient", payload: "error" });
            hidemessage();
            break;
          }
        }
      }
    }
    if (checkPax) {
      dispatch({ type: "confirmSeat", payload: true });
      handleSeatConfirmation();
    }
  };

  const handleSeatConfirmation = () => {
    Modal.confirm({
      className: "seatConfirmPopup",
      title: (context.translation && context.translation.confSeats),
      okText: (context.translation && context.translation.yes),
      cancelText: (context.translation && context.translation.no),
      onOk: () => {
        continueSelection();
      },
      onCancel: () => {
        closeConfirm();
      },
    });
  };
  

  const continueSelection = () => {
    dispatch({ type: "confirmSeat", payload: false });
    setShow(false);
    dispatch({ type: "showSlectedSeats", payload: true });
    let totalPrice = 0;
    if (state.segmentList.length !== 0) {
      for (let seg of state.segmentList) {
        for (let pax of seg.paxlist) {
          if (pax.seat !== "" && pax.price !== 0) {
            if (totalPrice === 0) {
              totalPrice = Number(pax.price);
            } else {
              totalPrice = Number(totalPrice + Number(pax.price));
            }
          }
        }
      }
      props.sendSeatPrice(totalPrice);
      let list = _.cloneDeep(state.segmentList);
      setTempSegList(list);
      props.sendSelectedSeats(state.segmentList);
    }
  };

  const closeConfirm = () => {
    dispatch({ type: "confirmSeat", payload: false });
  };

  const handleClick = (e) => {

    let cn = e.currentTarget.classList;

   if(cn.contains('active')){
    cn.remove('active')
   } else {
    cn.add('active')
   }    
 };

  return (
    <>
      {state.notiMessageShow ? (
        <Alert
          description={state.notiMessage}
          type={state.notiVarient}
          closable
          onClose={() => dispatch({ type: "notiMessageShow", payload: false })}
          className="alertForm"
          // showIcon
          style={{position: "fixed", top: "0", left: "0", width:"100%"}}
        >
          {" "}
          {state.notiMessage}
        </Alert>
      ) : (
        ""
      )}
      
      {state.showSlectedSeats && state.segmentList !== undefined && (
        <div className="seatsSelectedWrapper">        
          <div className="onlyFlex">
            <i className="icon icon-Seat"></i>
            <Title level={5} className="yourSeatsSelected">
             {context.translation && context.translation.selectSeat}
            </Title>
          </div>

          {state.showSlectedSeats && (
            <div className="seatsSelected">
              <Row gutter={30} className="seatsSelectedHeading">
                <Col span={8}>
                <div className="passengerNameHeading">
                  {context.translation && context.translation.travellers}
                </div>
                  {state.showSlectedSeats &&
                  state.segmentList !== undefined &&
                  state.segmentList[0].paxlist.map((pax, idx) => (
                    <React.Fragment key={idx}>
                      <>
                          <div className="passengerName">{pax.pax_name}</div>             
                      </>
                    </React.Fragment>
                  ))}
                </Col>   
                <Col span={16}> 
                <Row gutter={30}>
                <Col span={6}>
                  {/* {state.segmentList.length === 1 && ( */}
                    <>
                    <div className="originToDestWrap">
                      <span className="smallFlight">
                        <i className="icon icon-flight"></i>
                      </span>
                      <span className="route">{state.segmentList[0]?.dept}</span>
                      <span className="rightArrow">
                        <i className="icon icon-next-long-arrow-right-svgrepo-com"></i>
                      </span>
                      <span className="route">{state.segmentList[0]?.arvl}</span>
                    </div>
                    </>
                  {/* )} */}
                {state.showSlectedSeats &&
                state.segmentList !== undefined &&
                // state.segmentList.length === 1 &&
                state.segmentList[0]?.paxlist.map((pax, idx) => (
                  <React.Fragment key={idx}>
                    <>
                        {pax.rno !== "" ? (
                          <div className="confirmSeat commonFlex confirmSelectSeat">
                            <span className="seatNum">{pax.rno}{pax.seat}</span>
                            <span>                            
                              {state.showSlectedSeats && (
                                <span
                                  variant="primary"
                                  disabled={props.disable}
                                  onClick={() => handleEditShow(state.segmentList[0]?.seg_id)}
                                >
                                  <i className="icon icon-Edit"></i>
                                </span>
                              )}                            
                            </span>
                          </div>
                        ): <div className="selectSeat commonFlex confirmSelectSeat" onClick={() => handleEditShow(state.segmentList[0]?.seg_id)}>{context.translation && context.translation.seleSeat}</div>}                
                      
                    </>
                  </React.Fragment>
                ))}
                </Col>

                {state.segmentList.length >= 2 && (
                <Col span={6}>
                  {state.segmentList.length >= 2 && (
                    <>
                    <div className="originToDestWrap">
                      <span className="smallFlight">
                        <i className="icon icon-flight"></i>
                      </span>
                      <span className="route">{state.segmentList[1]?.dept}</span>
                      <span className="rightArrow">
                        <i className="icon icon-next-long-arrow-right-svgrepo-com"></i>
                      </span>
                      <span className="route">{state.segmentList[1]?.arvl}</span>
                    </div>
                    </>
                  )}
                {state.showSlectedSeats &&
                state.segmentList !== undefined &&
                state.segmentList.length >= 2 &&
                state.segmentList[1]?.paxlist.map((pax, idx) => (
                  <React.Fragment key={idx}>
                    <>
                        {pax.rno !== "" ? (
                          <div className="confirmSeat commonFlex confirmSelectSeat">
                            <span className="seatNum">{pax.rno}{pax.seat}</span>
                            <span>                            
                              {state.showSlectedSeats && (
                                <span
                                  variant="primary"
                                  disabled={props.disable}
                                  onClick={() => handleEditShow(state.segmentList[1]?.seg_id)}
                                >
                                  <i className="icon icon-Edit"></i>
                                </span>
                              )}                            
                            </span>
                          </div>
                        ): <div className="selectSeat commonFlex confirmSelectSeat" onClick={() => handleEditShow(state.segmentList[1]?.seg_id)}>{context.translation && context.translation.seleSeat}</div>}                
                      
                    </>
                  </React.Fragment>
                ))}
                </Col>
                )}
                

                {state.segmentList.length >= 3 && (
                <Col span={6}>
                {state.segmentList.length >= 3 && (
                  <>
                  <div className="originToDestWrap">
                    <span className="smallFlight">
                      <i className="icon icon-flight"></i>
                    </span>
                    <span className="route">{state.segmentList[2]?.dept}</span>
                    <span className="rightArrow">
                      <i className="icon icon-next-long-arrow-right-svgrepo-com"></i>
                    </span>
                    <span className="route">{state.segmentList[2]?.arvl}</span>
                  </div>
                  </>
                )}
                {state.showSlectedSeats &&
                state.segmentList !== undefined &&
                state.segmentList.length >= 3 &&
                state.segmentList[2]?.paxlist.map((pax, idx) => (
                  <React.Fragment key={idx}>
                    <>
                        {pax.rno !== "" ? (
                          <div className="confirmSeat commonFlex confirmSelectSeat">
                            <span className="seatNum">{pax.rno}{pax.seat}</span>
                            <span>                            
                              {state.showSlectedSeats && (
                                <span
                                  variant="primary"
                                  disabled={props.disable}
                                  onClick={() => handleEditShow(state.segmentList[2]?.seg_id)}
                                >
                                  <i className="icon icon-Edit"></i>
                                </span>
                              )}                            
                            </span>
                          </div>
                        ): <div className="selectSeat commonFlex confirmSelectSeat" onClick={() => handleEditShow(state.segmentList[2]?.seg_id)}>{context.translation && context.translation.seleSeat}</div>}                
                      
                    </>
                  </React.Fragment>
                ))}
                </Col>
                )}
                

                {state.segmentList.length >= 4 && (
              <Col span={6}>
                {state.segmentList.length >= 4 && (
                <>
                <div className="originToDestWrap">
                  <span className="smallFlight">
                    <i className="icon icon-flight"></i>
                  </span>
                  <span className="route">{state.segmentList[3]?.dept}</span>
                  <span className="rightArrow">
                    <i className="icon icon-next-long-arrow-right-svgrepo-com"></i>
                  </span>
                  <span className="route">{state.segmentList[3]?.arvl}</span> 
                </div>
                </>                 
                )}
              {state.showSlectedSeats &&
              state.segmentList !== undefined &&
              state.segmentList.length >= 4 &&
              state.segmentList[3]?.paxlist.map((pax, idx) => (
                <React.Fragment key={idx}>
                  <>
                      {pax.rno !== "" ? (
                        <div className="confirmSeat commonFlex confirmSelectSeat">
                          <span className="seatNum">{pax.rno}{pax.seat}</span>
                          <span>                            
                            {state.showSlectedSeats && (
                              <span
                                variant="primary"
                                disabled={props.disable}
                                onClick={() => handleEditShow(state.segmentList[3]?.seg_id)}
                              >
                                <i className="icon icon-Edit"></i>
                              </span>
                            )}                            
                          </span>
                        </div>
                      ): <div className="selectSeat commonFlex confirmSelectSeat" onClick={() => handleEditShow(state.segmentList[3]?.seg_id)}>{context.translation && context.translation.seleSeat}</div>}                
                    
                  </>
                </React.Fragment>
              ))}
              </Col>
                )}
              </Row>
              </Col>
              </Row>   
            </div>
          )}
         
        </div>
      )}

    
      {!state.showSlectedSeats && (
        <div className="seatsSelection dFlex">
            <div>
              <Title level={5}>
                <i className="icon icon-Seat"></i> {context.translation && context.translation.notSelected}
              </Title>
            </div>
          <div>
            {/* <Button type="primary" className="addSeats" onClick={showModal} ghost>
              Select Your Seats
            </Button> */}
            
            {!state.showSlectedSeats && (
              <Button
                type="primary"
                className="addSeats"
                disabled={isLoadingMore ? true : props.disableseat}
                onClick={handleShow}
                ghost
              >
                
                {isLoadingMore ? (
                  // <Space size={"middle"}>
                  //   <Spin size="large" />
                  // </Space>
                  <span className="circleLoader"></span>
                ) : (
                  context.translation && context.translation.selurSeats
                )}
              </Button>
            )}
          </div>
        </div>
      )}

      <Modal
        title=""
        open={show}
        onCancel={handleCancel}
        width={1200}
        footer={null}
        maskClosable={false}
        className="seatSelectionWrapper"
      >
        <div className="modalHeader commonFlex">
          <Title level={4} className="selectSeatTitle">
            {context.translation && context.translation.selectTrip}
          </Title>
          <i
            className="icon icon-cancel"
            onClick={() => {
              handleCancel();
            }}
          ></i>
        </div>
        {/* <SeatSelection /> */}
        <div className="modalBody">
        {state.notiMessageShowModal ? (
        <Alert
          description={state.notiMessage}
          type={state.notiVarient}
          closable
          onClose={() => dispatch({ type: "notiMessageShow", payload: false })}
          // showIcon
          style={{position: "sticky", top: 0, left: 0, width:"100%", padding:"10px 15px", display:"flex", alignItems:"center", borderRadius:"0", zIndex:"99"}}
        >
          {" "}
          {state.notiMessage}
        </Alert>
      ) : (
        ""
      )}
          <Row>
            <Col span={11}>
              <div className="legendWrapper">
                <div className="legendTitle">{context.translation && context.translation.legend}</div>
                <div className="commonFlex">
                  <div className="onlyFlex">
                    <div className="colorBox Selected"></div>
                    <div className="colorBoxTitle">{context.translation && context.translation.selected}</div>
                  </div>
                  <div className="onlyFlex">
                    <div className="colorBox Free"></div>
                    <div className="colorBoxTitle">{context.translation && context.translation.free}</div>
                  </div>
                  <div className="onlyFlex">
                    <div className="colorBox Occupied">
                      <i className="icon icon-cross"></i>
                    </div>
                    <div className="colorBoxTitle">{context.translation && context.translation.occupied}</div>
                  </div>
                  <div className="onlyFlex">
                    <div className="colorBox Available"></div>
                    <div className="colorBoxTitle">{context.translation && context.translation.available}</div>
                  </div>
                </div>
              </div>
              <div className="segmentDetailsWrapper">
                <div className="collapseWrapper">

                  
                {/* {state.airline !== "" && (
                  <div className="airLine mb-3">
                    <img
                      src={
                        CDNURL+"/airlines/1x/airline-logos/" +
                        state.airline +
                        ".png"
                      }
                      className="logo"
                      alt="AirLine"
                    />
                    <div className="airLineName">
                      <strong>
                        <AirlinesMemo code={state.airline} />
                      </strong>
                      <span>
                        {state.airline} {state.flt_no}
                      </span>
                    </div>
                  </div>
                )} */}                

                  {state.showSegments &&
                   state.segmentList !== undefined &&
                   state.segmentList.map((seg, idx) => (
                    <React.Fragment key={idx}>
                      <div className={"collapseHeader " + (idx===0 && 'active')} onClick={handleClick}>
                        <div className="leftSide">
                          <span>
                            <Radio 
                              type="radio"
                              id={idx + 1 + "seat"}
                              onChange={handleChangeSegment(seg.seg_id)}
                              checked={seg.selected}
                              value={seg.seg_id}
                            >
                            </Radio>
                          </span>
                          <span className="flightLogo">
                            <img
                              src={
                                CDNURL+"/airlines/1x/airline-logos/" +
                                seg.air_code +
                                ".png"
                              }
                            />
                          </span>
                          <span className="flightCode">{seg.air_code}</span>
                          <span className="route origin">{seg.dept}</span>
                          <span className="rightArrow">
                            <i className="icon icon-next-long-arrow-right-svgrepo-com"></i>
                          </span>
                          <span className="route">{seg.arvl}</span>
                        </div>
                        <div className="rightSide">
                          <span className="flightValue">{props.srequest.cur}&nbsp; 
                          <FormatCurrency
                            value={seg.price === undefined ? 0 : seg.price.toFixed(2) * props.base_sup_exrate}
                            className="currencyAmount"
                          />
                          {/* {seg.price === undefined ? 0 : seg.price.toFixed(2)} */}
                          </span>
                          <span>
                            <i className={"icon icon-Minus"}></i><i className={ "icon icon-Plus"}></i>
                          </span>
                        </div>
                      </div>

                      
                      <div className="collapseBody">
                        {seg &&
                        seg.paxlist !== undefined &&
                        seg.paxlist.map((pax, idx) => (
                          <div className="innerBodyWrapper" key={idx}>
                            <div className="leftSide">{pax.pax_name}</div>
                            <div className="rightSide">
                              {pax.seat !== "" && (
                              <>
                                <span className="seatNum">{pax.rno}{pax.seat}</span>
                                <span onClick={() =>
                                        handleDeleteSelectSeat(pax, pax.rno)}>
                                  <i className="icon icon-Delete"></i>
                                </span>
                              </>
                              )}  
                              <span className="flightValue">
                                {props.srequest.cur} &nbsp;
                                <FormatCurrency value={pax.price !== 0 ? +Number(pax.price)*props.base_sup_exrate : 0} />
                              </span>
                            </div>
                          </div>                              
                        ))}
                      </div>
                    </React.Fragment>
                   ))}
                </div>
              </div>
            </Col>


            <Col span={13}>
              <div className="seatOptionsWrapper">
                <div className="innerSeatOptionsWrapper">
                  <Row gutter={80}>
                    <Col span={24}>
                      <div className="onlyFlex">
                        <span className="seatBox heading">A</span>
                        <span className="seatBox heading">B</span>
                        <span className="seatBox heading">C</span>
                        <span className="seatBox heading">D</span>
                        <span className="seatBox heading">E</span>
                        <span className="seatBox heading">F</span>
                      </div>

                    {seatLoading ? (
                      // <Space size={"middle"} className="loaderCenter">
                      //   <Spin size="large" />
                      // </Space>
                      <div className="loaderCenter">
                        <span className="circleLoader"></span>
                      </div>
                      
                      ) : (
                      <>
                      {state.showSetMap &&
                      state.seatsList.length !== 0 &&
                      state.seatsList.map((seatmap, index) => (
                        <React.Fragment key={index}>

                          <div className="seats">
                            <div className="onlyFlex">
                              {seatmap.seats.map((obj, idx) => (
                                <React.Fragment key={idx}>
                          
                                  <div className="seatBox">
                                  <Tooltip placement="top" overlayClassName="seatBoxTooltip" title={obj.infant !== undefined && obj.infant ? (
                                      <div className="toolTipCUstoom">
                                        <span className="check">
                                          <span className="d-flex align-items-center">
                                            <span className="ezyIcon icon-infant1">
                                              {context.translation && context.translation.infantAssoc}
                                            </span>
                                          </span> &nbsp;
                                          <span className="d-flex align-items-center justify-content-between">
                                            <span>{props.srequest.cur}&nbsp;
                                            <FormatCurrency
                                              value={obj.price!=null ? Number(obj.price).toFixed(2)*props.base_sup_exrate : 0}
                                              className="currencyAmount"
                                            />
                                            {/* {obj.price!=null ? Number(obj.price).toFixed(2) : 0} */}
                                            </span>
                                          </span>
                                        </span>
                                      </div>
                                    ) : (
                                      <div className="toolTipCUstoom">
                                        <span>{props.srequest.cur}&nbsp;
                                        <FormatCurrency
                                          value={obj.price!=null ? Number(obj.price).toFixed(2)*props.base_sup_exrate : 0}
                                          className="currencyAmount"
                                        />
                                        {/* {obj.price!=null ? Number(obj.price).toFixed(2) : 0} */}
                                        </span>
                                      </div>
                                    )}>                                    
                                    <input
                                      type="checkbox"
                                      value={obj.sn}
                                      id={seatmap.rno + obj.sn}
                                      onChange={() =>
                                        handleSelectSeat(obj, seatmap.rno)
                                      }
                                      disabled={obj.st === "Booked"}
                                      checked={obj.selected}
                                      className={obj.selected?"afterSelect":"beforeSelect"}
                                      // className={obj.selected && state.segmentList[0].paxlist[0].seat !== "" ?"afterSelect":"beforeSelect"}
                                    />
                                    {obj.infant !== undefined && obj.infant ? (
                                      <label for={seatmap.rno + obj.sn}>                                        
                                        <span className={obj.st === "Booked" ? "Occupied" : Number(obj.price) !== 0
                                            ? "Available"
                                            : "Free"                                        
                                          }>
                                          {obj.st === "Booked" && (
                                            <i className="icon icon-cross"></i>
                                          )}
                                          {obj.st !== "Booked" && (
                                            <i className="icon icon-Infant"></i>
                                          )}
                                        </span>
                                      </label>
                                    ) : (
                                      <label for={seatmap.rno + obj.sn} className="seatNumber">
                                        <span className={obj.st === "Booked" ? "Occupied" : Number(obj.price) !== 0
                                            ? "Available"
                                            : "Free"                                          
                                          }>
                                          {obj.st === "Booked" ? <i className="icon icon-cross"></i> : <>{seatmap.rno + obj.sn}</>}
                                        </span>
                                      </label>
                                    )}
                                    </Tooltip>
                                    
                                    {/* <span className="seatBox Free">1B</span>
                                    <span className="seatBox Selected">7C</span> */}
                                  </div>
                                </React.Fragment>
                              ))}
                            </div>

                            {seatmap.isExit && (
                              <li>
                                <div className="exit exit--back fuselage"></div>
                              </li>
                            )}
                          </div>
                        </React.Fragment>
                      ))}
                      </>
                      )}
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
        </div>         
        <div className="modalFooter">
            <Button type="primary" className="cntBtn" onClick={confirmSeat}>
              {context.translation && context.translation.confSeat}
              {/* <i className="sprite btn-arrow-sprite"></i> */}
              <img src={brandIcon} alt="brandIcon" className="brandIcon"></img>
            </Button>
        </div>     
      </Modal>
    </>
  );
}

export default SeatSelection;
