import React from 'react';

function HotelOverview(props) {
  return (
    <div className='hotelOverviewWrapper hrsWrapper'>
        {props.userreview!==undefined &&
              <p className='hotelOverview'>{props.userreview.location!==undefined && props.userreview.location}
              {props.userreview.amenities!==undefined &&  props.userreview.amenities}
              {props.userreview.businessAmenities!==undefined &&  props.userreview.businessAmenities}
              {props.userreview.rooms!==undefined &&  props.userreview.rooms}
              {props.userreview.dining!==undefined &&  props.userreview.dining}
              </p>
              }
    </div>
  );
}

export default HotelOverview