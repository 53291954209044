import React from "react";
import { Row, Col, Typography } from "antd";
import FormatCurrency from "../../../utilities/FormatCurrency";
import { useContext } from "react";
import { Context } from "../../../../App";
const { Title } = Typography;

function PaymentDetails(props) {
  const [context, setContext] = useContext(Context)
  return (
    <>
      <Title level={3} className="heading">
       {context.translation && context.translation.paymentDetails}
      </Title>
      <div className="tableScroll displayNone-640">
        <div className="tableWrap">
        <Row className="tableHeader" justify="space-between">
          <Col span={5} className="tableValue">
            {context.translation && context.translation.modePayment}
          </Col>
          <Col span={5} className="tableValue">
            {context.translation && context.translation.cardType}
          </Col>
          <Col span={5} className="tableValue">
            {context.translation && context.translation.cardDetails}
          </Col>
          <Col span={5} sm={5} md={4} className="tableValue">
            {context.translation && context.translation.amountPaid}
          </Col>
          <Col span={4} className="tableValue">
            {context.translation && context.translation.status}
          </Col>
        </Row>
        {props.payment_info!==undefined &&
        <Row className="tableData" justify="space-between">
          <Col span={5} className="tableValue">
            {props.payment_info.ptype} {context.translation && context.translation.card}
          </Col>
          <Col span={5} className="tableValue">
          {props.payment_info.ctype}
          </Col>
          <Col span={5} className="tableValue">
          XXXXXXXXXXXX{props.payment_info.cno}
          </Col>
          <Col span={5} sm={5} md={4} className="tableValue wordBreak">
          {props.payment_info.ccode}  <FormatCurrency value={props.payment_info.amt * props.base_usr_exrate} className="currencyAmount"  />
          </Col>
          <Col span={4} className="tableValue">
          {"Confirmed"}
          </Col>
        </Row>
          }
        </div>
      </div>
      <div className='smallScreenTable displayNoneLG'>
      <Row className="tableHeader">
      {props.payment_info!==undefined &&
      <Col span={24} className="tableData">
        <div className="tableValueWrap">
          <div className="tableValue">
            {context.translation && context.translation.modePayment}
          </div>
          <div className="tableValue">
            {props.payment_info.ptype} {context.translation && context.translation.card}
          </div>
        </div>
        <div className="tableValueWrap">
          <div className="tableValue">
            {context.translation && context.translation.cardType}
          </div>
          <div className="tableValue">
            {props.payment_info.ctype}
          </div>
        </div>
        <div className="tableValueWrap">
          <div className="tableValue">
            {context.translation && context.translation.cardDetails}
          </div>
          <div className="tableValue">
          XXXXXXXXXXXX{props.payment_info.cno}
          </div>
        </div>
        <div className="tableValueWrap">
          <div className="tableValue">
            {context.translation && context.translation.amountPaid}
          </div>
          <div className="tableValue wordBreak">
            {props.payment_info.ccode}  <FormatCurrency value={props.payment_info.amt * props.base_usr_exrate} className="currencyAmount"  />
          </div>
        </div>
        <div className="tableValueWrap">
          <div className="tableValue">
            {context.translation && context.translation.status}
          </div>
          <div className="tableValue wordBreak">
            {"Confirmed"}
          </div>
        </div>
        </Col>
        }
      </Row>
      </div>
    </>
  );
}

export default PaymentDetails;
