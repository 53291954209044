import { Tabs } from "antd";
import TabPane from "antd/es/tabs/TabPane";
import React, { useState } from "react";
import { Context } from "../../../../App";
// import Scrollspy from 'react-scrollspy';
import ScrollSpy from "react-ui-scrollspy";
import HotelRoomsAndPrices from "./HotelRoomsAndPrices";
import HotelOverview from "./HotelOverview";
import HotelServiceAndAmenities from "./HotelServiceAndAmenities";
import HotelPolicies from "./HotelPolicies";
import HotelReviewsAndRatings from "../common/HotelReviewsAndRatings";
import { useEffect,useContext } from "react";
import HotelMapView from "./HotelMapView";
import HotelSearch from '../search/HotelSearch';
import HotelRoomOccupancy from './HotelRoomOccupancy';

function HotelDetailTabs(props) {
  const [context, setContext] = useContext(Context)
  const {
    boardBasisList,
    applyBoardBasis,
    isRefundable,
    isNonRefundable,
    showClear,
    clearBoardBasis,
    precepernight,
    value,
    handleChange,
    sendSelRoom,
    sendRoomInfo,
    ocpnsList1,
    noofNights,
    pricePerNight,
    onflyMkp,
    updateResults,
    roomOneOcpList,
  roomTwoOcpList,
  roomThreeOcpList,
  roomForOcpList,
  roomFiveOcpList,
  roomSixOcpList,
  roomSevenOcpList,
  roomEightOcpList,
    checkRoomPrice,
    showRoomInfo,
    selectedRooms,
    showRoomChoice,
    finalSelRooms,
    reviewObj,
    expRating,
    usrRating,
    pid,
    searchRequest,
    base_usr_exrate
  } = props;
  const [request, setRequest] = useState(props.searchRequest);
  const [current, setCurrent] = useState("block1")

  useEffect(() => {
    if(props.searchRequest){
      setRequest(props.searchRequest);
    }
  }, [props.searchRequest])

  const handleCurrentClass = (block) => {
    setCurrent(block);
  }

  return (
    <>
    <div className="container">
  <ul className="scrollspy">
      <li className={current === "block1" ? "isCurrent" : ""} onClick={() => handleCurrentClass("block1")}><a  href="#roomAndPrices">{context.translation && context.translation.roomNprice}</a></li>
      <li className={current === "block2" ? "isCurrent" : ""} onClick={() => handleCurrentClass("block2")}><a  href="#mapView">{context.translation && context.translation.mapview}</a></li>
      <li className={current === "block3" ? "isCurrent" : ""} onClick={() => handleCurrentClass("block3")}><a  href="#overview">{context.translation && context.translation.overview}</a></li>
      <li className={current === "block4" ? "isCurrent" : ""} onClick={() => handleCurrentClass("block4")}><a  href="#servicesAndAmenities">{context.translation && context.translation.serviceNamenities}</a></li>
      <li className={current === "block5" ? "isCurrent" : ""} onClick={() => handleCurrentClass("block5")}><a  href="#hotelPolicies">{context.translation && context.translation.hotelPolicies}</a></li>
      <li className={current === "block6" ? "isCurrent" : ""} onClick={() => handleCurrentClass("block6")}><a  href="#reviewAndRatings">{context.translation && context.translation.reviewNratings}</a></li>
  </ul>
    
      <div>
      <ScrollSpy>
        <div id="roomAndPrices" className="sectionTp">
          {/* <div className="hrsTitle">{context.translation && context.translation.selormodifyrooms}</div> */}
          <div id="chooseRoom">
          <HotelRoomsAndPrices
            boardBasisList={boardBasisList}
            applyBoardBasis={applyBoardBasis}
            isRefundable={isRefundable}
            isNonRefundable={isNonRefundable}
            showClear={showClear}
            clearBoardBasis={clearBoardBasis}
            pricePerNight={props.precepernight}
            handleChange={handleChange}
            sendSelRoom={sendSelRoom}
            sendRoomInfo={sendRoomInfo}
            ocpnsList1={ocpnsList1}
            noofNights={noofNights}
            onflyMkp={onflyMkp}
            updateResults={updateResults}
            roomOneOcpList={roomOneOcpList}
            roomTwoOcpList={roomTwoOcpList}
          roomThreeOcpList={roomThreeOcpList}
          roomForOcpList={roomForOcpList}
          roomFiveOcpList={roomFiveOcpList}
          roomSixOcpList={roomSixOcpList}
          roomSevenOcpList={roomSevenOcpList}
          roomEightOcpList={roomEightOcpList}
            checkRoomPrice={checkRoomPrice}
            showRoomInfo={showRoomInfo}
            selectedRooms={selectedRooms}
            showRoomChoice={showRoomChoice}
            finalSelRooms={finalSelRooms}
            searchRequest={request}
            base_usr_exrate={base_usr_exrate}
          />
        </div>
        </div>
        <div id="mapView" className="section">
        <div className="hrsTitle marginTop30">{context.translation && context.translation.mapview}</div>
        {props.hotelContentResp!==undefined && props.hotelContentResp.attractions!==undefined &&
          <HotelMapView
            attractions={props.hotelContentResp.attractions}
            location={props.hotelContentResp.location}
            hname={props.hotelContentResp.htnm}
          />
        }
        </div>
        {props.hotelContentResp!==undefined &&
        <div id="overview" className="section">
        <div className="hrsTitle marginTop30">{context.translation && context.translation.overview}</div>
          <HotelOverview userreview={props.hotelContentResp.descriptions} />
        </div>
        }
        {props.hotelContentResp !== undefined &&
          props.hotelContentResp.amenities !== undefined && (
            <>
              <div id="servicesAndAmenities" className="section">
              <div className="hrsTitle marginTop30">{context.translation && context.translation.serviceNamenities}</div>
                <HotelServiceAndAmenities
                  amenities={props.hotelContentResp.amenities}
                />
              </div>
            </>
          )}
        
        {props.hotelContentResp.cin !== undefined &&
            props.hotelContentResp.cout &&
            props.hotelContentResp.policies !== undefined && (
              <>
              <div id="hotelPolicies"  className="section">
              <div className="hrsTitle marginTop30">{context.translation && context.translation.hotelPolicies}</div>
              <HotelPolicies
                cin={props.hotelContentResp.cin}
                cout={props.hotelContentResp.cout}
                payments={
                  props.hotelContentResp.onsitePayments !== null &&
                  props.hotelContentResp.onsitePayments
                }
                policy={props.hotelContentResp.policies}
                canpolicy={props.hotelContentResp.checkin}
                fees={props.hotelContentResp.fees}
              />
              </div>
              </>
            )}
        
        <div id="reviewAndRatings"  className="section">
          <div className="hrsTitle marginTop30">{context.translation && context.translation.reviewNratings}</div>
          {reviewObj !== undefined &&
          <HotelReviewsAndRatings reviewObj={reviewObj} reviewCount={expRating} urat={usrRating} pid={pid} page={"Review"}/>
            }
        </div>
        </ScrollSpy>
      </div>
    </div>
    </>
  );
}

export default HotelDetailTabs;
