import React from "react";
import { Typography } from "antd";
import Flight from "../../../../assets/img/no-results-found.png";
import { useContext } from "react";
import { Context } from "../../../../App";


const { Title } = Typography;

const NoResultsFound = () => {
  const [context, setContext] = useContext(Context)
  return (
    <>
      <div className="no-results-found">
        <div>
          <img src={Flight} alt="Flight-image" />
          <Title level={5}>{context.translation && context.translation.noFlightAvail}</Title>
          <span className="smallText">
            {context.translation && context.translation.diffAirp}
          </span>
        </div>
      </div>
    </>
  );
};

export default NoResultsFound;
