/**
 * @ Author: Srikanth Bandaru
 * @ Create Time: 2023-06-23 17:41:31
 * @ Description: This component is for displaying the fare option under fare rules
 */

import React, { useEffect, useState ,useContext} from "react";
import { Context } from "../../../../App";
import { Link } from "react-router-dom";
export const CLIENT_IP = "clientIp";

function FlightFareOption(props) {
  const [context, setContext] = useContext(Context)
  const {
    fare,
    showJeddah,
    region,
    isCorporate,
    showContent,
    showDiscount,
    enableVandeBharath,
    baggage,
    airline,
  } = props;
  const [isIndigo, setIsIndigo] = useState(false);
  useEffect(() => {
    if (airline && airline === "6E") {
      setIsIndigo(true);
    }
  }, []);

  return (
    <>
      <ul className="fareLCC">
        <li>
          <span>{context.translation && context.translation.fareType}</span>
          {isIndigo ? (
            <>
              {fare.pr.ftyp === "Regular - R" && <span>{context.translation && context.translation.saver} </span>}
              {fare.pr.ftyp === "Flexi - J" && <span>{context.translation && context.translation.flex} </span>}
              {fare.pr.ftyp.toUpperCase() === "CORPORATE - F" && (
                <span>{context.translation && context.translation.corr} </span>
              )}
            </>
          ) : (
            <span>{fare.pr.ftyp.toUpperCase()}</span>
          )}
        </li>
        <li>
          {!isIndigo ? <span>{context.translation && context.translation.fareConfig}</span> : <span>{context.translation && context.translation.eTiffin}</span>}
          {isIndigo ? (
            <>
              {region === "International" &&
                fare.pr.ftyp === "Regular - R" &&
                showJeddah && <span>{context.translation && context.translation.saverLink}</span>}
              {region === "International" &&
                fare.pr.ftyp === "Flexi - J" &&
                showJeddah && <span>{context.translation && context.translation.flexiLink}</span>}

              {region === "International" &&
                fare.pr.ftyp === "Regular - R" &&
                !showJeddah && <span>{context.translation && context.translation.chargeable}</span>}
              {region === "International" &&
                fare.pr.ftyp === "Flexi - J" &&
                !showJeddah && <span>{context.translation && context.translation.included}</span>}

              {region === "International" && isCorporate && (
                <span>{context.translation && context.translation.included}</span>
              )}

              {region === "Domestic" && fare.pr.ftyp === "Regular - R" && (
                <span>{context.translation && context.translation.chargeable}</span>
              )}
              {region === "Domestic" && fare.pr.ftyp === "Flexi - J" && (
                <span>{context.translation && context.translation.complimentary}</span>
              )}
              {region === "Domestic" && isCorporate && (
                <span>{context.translation && context.translation.complimentary}</span>
              )}
            </>
          ) : (
            <span>{context.translation && context.translation.toChange}</span>
          )}
        </li>
        <li>
          <span>{context.translation && context.translation.standSeat}</span>
          {isIndigo ? (
            <>
              {fare.pr.ftyp === "Regular - R" && <span>{context.translation && context.translation.chargeable}</span>}
              {fare.pr.ftyp === "Flexi - J" && <span>{context.translation && context.translation.included}</span>}
              {isCorporate && <span>{context.translation && context.translation.included}</span>}
            </>
          ) : (
            <span>{context.translation && context.translation.toChange}</span>
          )}
        </li>
        <li>
          <span>{context.translation && context.translation.dcfppps}</span>
          {isIndigo ? (
            <>
              {region === "Domestic" && fare.pr.ftyp === "Regular - R" && (
                <span>{context.translation && context.translation.upToInr}</span>
              )}
              {region === "Domestic" && fare.pr.ftyp === "Flexi - J" && (
                <span>{context.translation && context.translation.noFe}</span>
              )}
              {region === "Domestic" && isCorporate && (
                <span>{context.translation && context.translation.noChagFe}</span>
              )}
              {region === "International" && fare.pr.ftyp === "Regular - R" && (
                <span>
                  <Link
                    to="https://www.goindigo.in/travel-information/en/fare-rules.en-fd9bd1d98a5b47909c2518489e0e617c.html"
                    target="_blank"
                  >
                    {context.translation && context.translation.plsClkForDet}
                  </Link>
                </span>
              )}
              {region === "International" && fare.pr.ftyp === "Flexi - J" && (
                <span>{context.translation && context.translation.noFe}</span>
              )}
              {region === "International" && isCorporate && (
                <span>
                  <Link
                    to="https://www.goindigo.in/travel-information/en/fare-rules.en-fd9bd1d98a5b47909c2518489e0e617c.html"
                    target="_blank"
                  >
                    {context.translation && context.translation.plsClkForDet}
                  </Link>
                </span>
              )}
            </>
          ) : (
            <span>{context.translation && context.translation.toChange}</span>
          )}
        </li>
        <li>
          <span>{context.translation && context.translation.ccppps}</span>
          {isIndigo ? (
            <>
              {region === "Domestic" && fare.pr.ftyp === "Regular - R" && (
            <span>{context.translation && context.translation.upToInr}</span>
          )}
          {region === "Domestic" && fare.pr.ftyp === "Flexi - J" && (
            <span>{context.translation && context.translation.inrFive}</span>
          )}
          {region === "Domestic" && isCorporate && (
            <span>{context.translation && context.translation.noCan}</span>
          )}
          {region === "International" && fare.pr.ftyp === "Regular - R" && (
            <span>
              <Link
                to="https://www.goindigo.in/travel-information/en/fare-rules.en-fd9bd1d98a5b47909c2518489e0e617c.html"
                target="_blank"
              >
                {context.translation && context.translation.plsClkForDet}
              </Link>
            </span>
          )}
          {region === "International" && fare.pr.ftyp === "Flexi - J" && (
            <span>{context.translation && context.translation.inrTen}</span>
          )}
          {region === "International" && isCorporate && (
            <span>
              <Link
                to="https://www.goindigo.in/travel-information/en/fare-rules.en-fd9bd1d98a5b47909c2518489e0e617c.html"
                target="_blank"
              >
                {context.translation && context.translation.plsClkForDet}
              </Link>
            </span>
          )}
            </>
          ) : (
            <span>{context.translation && context.translation.toChange}</span>
          )}
          
        </li>
        <li>
          <span>{context.translation && context.translation.seatXl}</span>
          {isIndigo ? (
            <>
              {region === "Domestic" && fare.pr.ftyp === "Regular - R" && (
            <span>{context.translation && context.translation.noDis}</span>
          )}
          {region === "Domestic" && fare.pr.ftyp === "Flexi - J" && (
            <span>{context.translation && context.translation.onRetail}</span>
          )}
          {region === "Domestic" && isCorporate && (
            <span>{context.translation && context.translation.onRetail}</span>
          )}
          {region === "International" && fare.pr.ftyp === "Regular - R" && (
            <span>
              {!showContent && showJeddah && !showDiscount
                ? context.translation && context.translation.onRetail
                : context.translation && context.translation.onDis}
            </span>
          )}
          {region === "International" && fare.pr.ftyp === "Flexi - J" && (
            <span>
              {showDiscount
                ? context.translation && context.translation.onRetail
                : context.translation && context.translation.onDis}
            </span>
          )}
          {region === "International" && isCorporate && (
            <span>{showDiscount && context.translation && context.translation.onRetail}</span>
          )}
          {region === "International" && isCorporate && (
            <span>{!showDiscount && context.translation && context.translation.onDis}</span>
          )}
            </>
          ) : (
            <span>{context.translation && context.translation.toChange}</span>
          )}
          
        </li>
        <li>
          <span>{context.translation && context.translation.chckinBag}</span>
          {isIndigo ? (
            <>
              {region === "Domestic" && fare.pr.ftyp === "Regular - R" && (
            <span>{context.translation && context.translation.bagWgtMax}</span>
          )}
          {region === "Domestic" && fare.pr.ftyp === "Flexi - J" && (
            <span>{context.translation && context.translation.bagWgtMax}</span>
          )}
          {region === "Domestic" && isCorporate && <span>{context.translation && context.translation.bagWgtMax}</span>}
          {region === "International" && fare.pr.ftyp === "Regular - R" && (
            <span>{baggage}</span>
          )}
          {region === "International" && fare.pr.ftyp === "Flexi - J" && (
            <span>{baggage}</span>
          )}
          {region === "International" && isCorporate && <span>{context.translation && context.translation.thirtyKg}</span>}
            </>
          ) : (
            <span>{context.translation && context.translation.toChange}</span>
          )}
          
        </li>
        <li>
          <span>{context.translation && context.translation.handBag}</span>
          {isIndigo ? (
            <>
              {region === "Domestic" && fare.pr.ftyp === "Regular - R" && (
            <span>
              {context.translation && context.translation.handBagMin}
            </span>
          )}
          {region === "Domestic" && fare.pr.ftyp === "Flexi - J" && (
            <span>
             {context.translation && context.translation.handBagMin}
            </span>
          )}
          {region === "Domestic" && isCorporate && (
            <span>
             {context.translation && context.translation.handBagMax}
            </span>
          )}
          {region === "International" && fare.pr.ftyp === "Regular - R" && (
            <span>
              {context.translation && context.translation.handBagMin}
            </span>
          )}
          {region === "International" && fare.pr.ftyp === "Flexi - J" && (
            <span>
              {context.translation && context.translation.handBagMin}
            </span>
          )}
          {region === "International" && isCorporate && (
            <span>
              {context.translation && context.translation.handBagMax}
            </span>
          )}
            </>
          ) : (
            <span>{context.translation && context.translation.toChange}</span>
          )}
          
        </li>
        <li>
          <span>{context.translation && context.translation.canCharges}</span>
          {isIndigo ? (
            <>
              {region === "Domestic" && fare.pr.ftyp === "Regular - R" && (
            <span>-</span>
          )}
          {region === "Domestic" && fare.pr.ftyp === "Flexi - J" && (
            <span>-</span>
          )}
          {region === "Domestic" && isCorporate && <span>-</span>}
          {region === "International" &&
            (enableVandeBharath &&
            (fare.pr.ftyp === "Regular - R" ||
              fare.pr.ftyp === "Flexi - J" ||
              isCorporate) ? (
              <span>
               {context.translation && context.translation.canHred}
              </span>
            ) : (
              <span>-</span>
            ))}
            </>
          ) : (
            <span>{context.translation && context.translation.toChange}</span>
          )}
        </li>
      </ul>
    </>
  );
}

export default FlightFareOption;
