/**
 * @ Author: Srikanth Bandaru
 * @ Create Time: 2023-05-13 14:05:26
 * @ Description: Common footer for the application
 */

import React, {useContext, useState} from  "react";
import { Col, Row, Typography, Modal, Tooltip,Button } from "antd";
import { Context } from "../../App";
import DateUtils from "../utilities/DateUtils";
const { Title } = Typography;


function PromoCodePopup(props) {
    const [context, setContext] = useContext(Context);
    const [cur,setCur] = useState(context.userCur!==""&&context.userCur!==undefined?context.userCur:sessionStorage.getItem("SEL_CUR"))
      
      const closePromo=()=>{
        props.closePopup()
      }

  return (
    <>
      <div>
          <div className="commonFlex">
          <Title level={4} className="promoCodeTitle">
            {context.selectLang == undefined || context.selectLang === "en" ? props.promoObj.offer_desc_en : props.promoObj.offer_desc_ar}
          </Title>
          <Button onClick={closePromo} style={{border:"none",height:"0",position:"absolute",top:"18px",right:"20px",padding:"0"}}>
            <i className="icon icon-cancel"></i>
          </Button>
          </div>
        <div className="promoCodeSubTitle">
          {context.selectLang == undefined || context.selectLang === "en" ? props.promoObj.offer_nm : props.promoObj.offer_nm_ar}
        </div>
        {props.promoObj.apdate && props.promoObj.apdate.map((promo, index) => {
          return (
            <div key={index}> 
                <div className="offerDateAmntWrap">
                  <div className="offerDateAmnt">
                    <span>{context.translation && context.translation.startdat}</span>
                    <span className="colondNone">:</span>
                    <span>{DateUtils.convertToDateHifen(promo.fm)}</span>
                  </div>
                  <div className="offerDateAmnt">
                    <span>{context.translation && context.translation.enddat}</span>
                    <span className="colondNone">:</span>
                    <span>{DateUtils.convertToDateHifen(promo.too)}</span>
                  </div>
                  <div className="offerDateAmnt">
                    <span>{context.translation && context.translation.miniPur} 
                      <Tooltip placement="bottom" title={<>{context.translation && context.translation.tpiop} {cur} {props.promoObj.min_purchase}</>}><span><i className="icon icon-Info"></i></span></Tooltip>
                    </span>
                    <span className="colondNone"> &nbsp;:</span>
                    <span>{cur} {props.promoObj.min_purchase}</span>
                  </div>
                  <div className="offerDateAmnt">
                    <span>{context.translation && context.translation.promocod}</span>
                    <span className="colondNone">:</span>
                    <span>{props.promoObj.pro_code}</span>
                  </div>
                </div>
            </div> 
          )
        })}
                <div className="promoCodeOfferDetails">
                  <Title level={4} className="OfferDetailsTitle">
                    {context.translation && context.translation.offDet}
                  </Title>
                  {/* <div className="OfferDetailsContent">
                    {context.selectLang == undefined || context.selectLang === "en" ? props.promoObj.offer_det_en : props.promoObj.offer_det_ar}                    
                  </div> */}
                  <div
                    className="OfferDetailsContent"
                    dangerouslySetInnerHTML={{ __html: context.selectLang == undefined || context.selectLang === "en" ? props.promoObj.offer_det_en : props.promoObj.offer_det_ar }}
                  ></div>
                </div>
        </div>
    </>
  );
}

export default PromoCodePopup;
