import {
  Button,
  Col,
  Form,
  Input,
  Radio,
  Row,
  Select,
  Space,
  Table,
  Typography,
  Modal,
  Alert,
  Drawer
} from "antd";
import React, { useState, useEffect, useReducer, useContext, useCallback, useRef,} from "react";
import Header from "../common/Header";
import Footer from "../common/Footer";
import Sidebar from "./Sidebar";
import { Context } from "../../App";
import { useNavigate } from "react-router-dom";
import {
  getPreferences,
  savePreferences,
  delPrefairlines,
  delPrefHtls,
  delFreqVisitedCity,
  delPrefDest,
} from "./operations";
import PredectiveSearch from "../../services/myAccount/MyAccountService";
import { debounce } from "lodash";
import Utilities from "../utilities/Utilities";

const { Title } = Typography;

let airlineMaster = require("../bookingflow/flights/common/masterData/AirlinesMasterData.json");

/**
 * Initial State Declaration
 */

const initialState = {
  id: [],
  pref_air: [],
  airlineName: [],
  airlinesList: [],
  code: "",
  rbd: [],
  enableView: false,
  notiMessage: "",
  notiMessageShow: false,
  notiVarient: "",

  src_code: "",
  scname: "",
  srcct: "",
  destct: "",
  dcname: "",
  value: "",

  // isSearching: false,
  hotelPredectiveDropDown: false,
  hotelChainPredectiveDropDown: false,
  hotelChainCityHotelPredectiveDropDown: false,
  prefDestPredectiveDropDown: false,
  cityNamePredectiveDropDown: false,
  prefHotelCityNamePredectiveDropDown: false,

  src: [],
  dest: [],
  airportsOW: [],
  cls: "Economy",
  paxCount: 1,
  selAir: [],
  tt: 2,
  dd: null,
  ad: null,
  ns: false,
  selDate: null,

  hotelData: [],
  selHotel: [],
  showResults: false,
  destName: "",
  prefDestName: "",
  hotelChainName: "",
  hotelChainCityHotelName: "",
  hotelChainCityName: "",
  hotelChainCityNameId: 0,
  cleardb: false,
  selectairlineField: false,
  htlChainField: false,
  prefHotelCityField: false,
  prefHotelHotelField: false,
  freqVisCity: false,
  prefDestError: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "clear":
      return {
        ...state,
        src: "",
        dest: "",
        airportsOW: [],
        selDate: [],
      };
    default:
      return { ...state, [action.type]: action.payload };
  }
};

function AddPreferences() {
  const [context, setContext] = useContext(Context);
  const [showMenu, setshowMenu] = useState(false);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [hotelChain, setHotelChain] = useState(true);
  const [hotelChainName, setHotelChainName] = useState("");
  const [tableData, setTableData] = useState([]);
  const [confirmModalVisible, setConfirmModalVisible] = useState(false);
  const [confirmModalVisiblePrefHtls, setConfirmModalVisiblePrefHtls] = useState(false);
  const [confirmModalVisibleFreqVisCities, setConfirmModalVisibleFreqVisCities] = useState(false);
  const [confirmModalVisiblePrefDest, setConfirmModalVisiblePrefDest] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null);
  const [loadData, setLoadData] = useState(false);
  const inputRef = useRef(null);
  

  // hotelchain and city - Preferred Hotel
  const [city, setCity] = useState("");
  const [htl_name, setHotelName] = useState("");
  const [country, setCountry] = useState("");
  const [selectedHotels, setSelectedHotels] = useState([]);

  // frequently visited cities
  const [cities, setCities] = useState([]);
  const [newCity, setNewCity] = useState("");

  // Preferred Destination
  const [destinationCities, setDestinationCities] = useState([]);
  const [destinationNewCity, setDestinationNewCity] = useState("");

  const contentRef = useRef(null);

  const [email, setEmail] = useState("");
  const [userUniqueId, setUserUniqueId] = useState("");

  const region = sessionStorage.getItem("REGION_COUNTRY");

  const data = JSON.parse(localStorage.getItem("SIGNINRESPONSE"));
  useEffect(() => {
    if (data !== null) {
      setEmail(data.res.email);
      setUserUniqueId(data.res.usr_uniq_id);
    }
  }, []);

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("SIGNINRESPONSE"));
    if (data !== null) {
      const payload = {
        email: data.res.email
      };
      let tokenKey = Utilities.generateAPIToken(payload);
      getPreferences(payload,tokenKey).then((response) => {
        if (response.suc) {
          dispatch({ type: "editProfile", payload: response });
        }
        setSelectedHotels(response.res.pref_htl.htl_list);
        setCities(response.res.freq_city);
        setDestinationCities(response.res.pref_dest);
        // dispatch({ type: "pref_air", payload: response.res.pref_air });
        setTableData(response.res.pref_air);
      }).catch(function (error) {
        console.error("Error:" + error);      
      });
    }
  }, [loadData]);

  var difference = airlineMaster.filter((x) =>
    state.pref_air.includes(x.value)
  );

  var prefAirlines = difference.map((item) => {
    const { value: code, label: name } = item;
    return { code, name };
  });

  // Handle add button click
  // const handleAddClick = () => {
  //   const newData = prefAirlines.map((item) => {
  //     return {
  //       key: item.code, // Assuming each item has a unique value
  //       name: item.name,
  //     };
  //   });
  //   setTableData([...tableData, ...newData]);
  //   // setSelectedItems([]); // Clear selected items
  // };

  // Handle add button click
  const handleAddClick = () => {
    if (state.pref_air.length === 0) { 
      dispatch({ type: "selectairlineField", payload: true });
      // dispatch({ type: "notiMessageShow", payload: true });
      // dispatch({ type: "notiMessage", payload: "Please Select Airline" });
      // dispatch({ type: "notiVarient", payload: "error" });
      hidemessage();
      return false;
    } else if ((tableData.length + state.pref_air.length) > 5) {
      dispatch({ type: "notiMessageShow", payload: true });
      dispatch({ type: "notiMessage", payload: (context.translation && context.translation.fiveairlines) });
      dispatch({ type: "notiVarient", payload: "error" });
      hidemessage();
      return false;
    } else if (tableData.length > 0) {
      const selectedAirline = state.pref_air.filter((airline) => airline);
      // const tableList = tableData.filter((airline) => airline.code === selectedAirline[0] || selectedAirline[1] || selectedAirline[2] || selectedAirline[3] || selectedAirline[4]);   
      const tableList1 = tableData.filter((airline) => airline.code === selectedAirline[0]);   
      const tableList2 = tableData.filter((airline) => airline.code === selectedAirline[1]);   
      const tableList3 = tableData.filter((airline) => airline.code === selectedAirline[2]);   
      const tableList4 = tableData.filter((airline) => airline.code === selectedAirline[3]);   
      const tableList5 = tableData.filter((airline) => airline.code === selectedAirline[4]);   
      if(tableList1.length > 0) {
        dispatch({ type: "notiMessageShow", payload: true });
        dispatch({ type: "notiMessage", payload: (context.translation && context.translation.airlinealexist) });
        dispatch({ type: "notiVarient", payload: "error" });
        hidemessage();
        // dispatch({ type: "pref_air", payload: [] });
        return false;
      } else if (tableList2.length > 0) {
        dispatch({ type: "notiMessageShow", payload: true });
        dispatch({ type: "notiMessage", payload: (context.translation && context.translation.airlinealexist) });
        dispatch({ type: "notiVarient", payload: "error" });
        hidemessage();
        return false;
      } else if (tableList3.length > 0) {
        dispatch({ type: "notiMessageShow", payload: true });
        dispatch({ type: "notiMessage", payload: (context.translation && context.translation.airlinealexist) });
        dispatch({ type: "notiVarient", payload: "error" });
        hidemessage();
        return false;
      } else if (tableList4.length > 0) {
        dispatch({ type: "notiMessageShow", payload: true });
        dispatch({ type: "notiMessage", payload: (context.translation && context.translation.airlinealexist) });
        dispatch({ type: "notiVarient", payload: "error" });
        hidemessage();
        return false;
      } else if (tableList5.length > 0) {
        dispatch({ type: "notiMessageShow", payload: true });
        dispatch({ type: "notiMessage", payload: (context.translation && context.translation.airlinealexist) });
        dispatch({ type: "notiVarient", payload: "error" });
        hidemessage();
        return false;
      } else {
        const newData = prefAirlines.map((item) => {
          return {
            code: item.code, // Assuming each item has a unique value
            name: item.name,
            initialData: true, // Flag to indicate initially added data
          };
        });
        setTableData([...tableData, ...newData]);
        // setSelectedItems([]); // Clear selected items
        dispatch({ type: "pref_air", payload: [] });
        prefAirlines = [];
        state.pref_air = [];
      }
    }
    else if (tableData.length === 0) {
      const newData = prefAirlines.map((item) => {
        return {
          code: item.code, // Assuming each item has a unique value
          name: item.name,
          initialData: true, // Flag to indicate initially added data
        };
      });
      setTableData([...tableData, ...newData]);
      // setSelectedItems([]); // Clear selected items
      dispatch({ type: "pref_air", payload: [] });
      prefAirlines = [];
      state.pref_air = [];
    }
    dispatch({ type: "selectairlineField", payload: false });
  };

  const hidemessage = () => {
    setTimeout(function () {
      dispatch({ type: "notiMessageShow", payload: false });
      dispatch({ type: "notiMessage", payload: "" });
      dispatch({ type: "notiVarient", payload: "" });
    }, 3000);
  };

  // Handle delete button click (for initially added data)
  const handleInitialDeleteClick = (key) => {
    const updatedData = tableData.filter((item) => item.code !== key);
    setTableData(updatedData);
  };

  // Handle delete button click (for API fetched data)
  const handleDeleteClick = (id) => {
    setDeleteItemId(id);
    setConfirmModalVisible(true);
  };

  // Handle confirm delete
  const handleConfirmDelete = () => {
    // Call API with deleteItemId and perform delete operation
    delPrefairlines(deleteItemId).then((response) => {
      if (response) {        
        dispatch({ type: "notiMessageShow", payload: true });
        dispatch({ type: "notiMessage", payload: (context.translation && context.translation.prefairline) });
        dispatch({ type: "notiVarient", payload: "success" });
        hidemessage();
        // setTimeout(() => {
        //   window.location.reload();
        // }, 300)
        setLoadData(!loadData);
      }
    }).catch(function (error) {
      console.error("Error:" + error);      
    });
    // ...
    // After successful deletion, update the tableData state
    const updatedData = tableData.filter((item) => item.key !== deleteItemId);
    setTableData(updatedData);
    setConfirmModalVisible(false);
  };

  // Handle cancel delete
  const handleCancelDelete = () => {
    setConfirmModalVisible(false);
  };

  // Table columns
  const airlinesColumns = [
    {
      title: (context.translation && context.translation.preferredAirline),
      dataIndex: "name",
      key: "name",
      id: "id",
    },
    {
      title: (context.translation && context.translation.action),
      dataIndex: "action",
      key: "action",
      id: "id",
      render: (_, record) =>
        record.initialData ? (
          <i
            className="icon icon-Delete"
            onClick={() => handleInitialDeleteClick(record.code)}
          ></i>
        ) : (
          // <Button onClick={() => handleInitialDeleteClick(record.key)}>
          //   Delete
          // </Button>
          <>
            <i
              className="icon icon-Delete"
              onClick={() => handleDeleteClick(record.id)}
            ></i>
            {/* <Button onClick={() => handleDeleteClick(record.id)}>Delete</Button> */}
          </>
        ),
    },
  ];

  const handleRadioChange = (e) => {
    setHotelChain(e.target.value === "hotelChain");
    setHotelChainName("");
    setCity("");
    setHotelName("");
    setCountry("");
  };

  const handleAdd = () => {
    if (hotelChain && !state.hotelChainName.includes(",")) {
      dispatch({ type: "htlChainField", payload: true });
      // dispatch({ type: "notiMessageShow", payload: true });      
      // dispatch({ type: "notiMessage", payload: "Please Select Hotel Chain" });
      // dispatch({ type: "notiVarient", payload: "error" });
      hidemessage();
      return false;
    }
    if (hotelChain) {
      // Perform hotel chain search logic
      // Add hotel chain to the selectedHotels array
      const existingHotelChain = selectedHotels.filter((city) => city.htl_name === state.hotelChainName)
      if(existingHotelChain[0]?.htl_name === state.hotelChainName) {
        dispatch({ type: "notiMessageShow", payload: true });
        dispatch({ type: "notiMessage", payload: (context.translation && context.translation.hotelChainexist) });
        dispatch({ type: "notiVarient", payload: "error" });
        hidemessage();
        return false;
      }
      const newHotelChain = {
        city: state.hotelChainCityName,
        type: "hotelChain",
        htl_name: state.hotelChainName,
        country: "",
      };
      setSelectedHotels([...selectedHotels, newHotelChain]);
      setHotelChainName("");
    } else {
      if (!state.hotelChainCityName.includes(",")) {
        dispatch({ type: "prefHotelCityField", payload: true });        
        // dispatch({ type: "notiMessageShow", payload: true });
        // dispatch({ type: "notiMessage", payload: "Please select city name" });
        // dispatch({ type: "notiVarient", payload: "error" });
        hidemessage();
        return false;
      } 
      let suc = false;
      for (let val of selectedHotels) {
        if (val.city?.toLowerCase() == state.hotelChainCityName.split(",")[0]?.toLowerCase()) {
          if (
            val.city?.toLowerCase() == state.hotelChainCityName.split(",")[0]?.toLowerCase() &&
            val.country?.toLowerCase() == state.hotelChainCityHotelName.split(",")[0]?.toLowerCase()
          ) {
            suc = true;
          }
        }
      }
      if(suc) {
        dispatch({ type: "notiMessageShow", payload: true });
        dispatch({ type: "notiMessage", payload: (context.translation && context.translation.citywithHotel) });
        dispatch({ type: "notiVarient", payload: "error" });
        hidemessage();
        return false;
      }
      // const existingCity = selectedHotels.filter((city) => city.city)
      // if(existingCity[0]?.city === state.hotelChainCityName.split(",")[0]) {
      //   dispatch({ type: "notiMessageShow", payload: true });
      //   dispatch({ type: "notiMessage", payload: "City with Hotel name Already Exists" });
      //   dispatch({ type: "notiVarient", payload: "error" });
      //   hidemessage();
      //   return false;
      // }
      if (!state.hotelChainCityHotelName.includes(",")) {
        dispatch({ type: "prefHotelHotelField", payload: true });
        // dispatch({ type: "notiMessageShow", payload: true });
        // dispatch({ type: "notiMessage", payload: "Please select hotel name" });
        // dispatch({ type: "notiVarient", payload: "error" });
        hidemessage();
        return false;
      }
      // Extract city and country from the entered value
      const [enteredCity, enteredCountry] = state.hotelChainCityName.split(",");
      const [hotelName, hotelCountry] = state.hotelChainCityHotelName.split(",");

      // Add city and hotel to the selectedHotels array
      const newCityHotel = {
        city: enteredCity,
        type: "city",
        htl_name,
        country: hotelName,
      };
      setSelectedHotels([...selectedHotels, newCityHotel]);
      setCity("");
      setHotelName("");
      setCountry("");
    }
    dispatch({ type: "hotelChainName", payload: "" });
    dispatch({ type: "hotelChainCityHotelName", payload: "" });
    dispatch({ type: "hotelChainCityName", payload: "" });
    dispatch({ type: "htlChainField", payload: false });
    dispatch({ type: "prefHotelHotelField", payload: false });
  };

  // Handle delete button click
  const handleDeleteClickPrefHtl = (_, record, index) => {
    if (record.id === undefined) {      
      // const updatedHotels = [...selectedHotels];
      // updatedHotels.splice(index, 1);
      // setSelectedHotels(updatedHotels);
      const updatedHotels = selectedHotels.filter((item) => item.htl_name !== record.htl_name);
      setSelectedHotels(updatedHotels);
    } else {
      setDeleteItemId(record.id);
      setConfirmModalVisiblePrefHtls(true);
    }
  };

  // Handle confirm delete
  const handleConfirmDeletePrefHtl = () => {
    // Call API with deleteItemId and perform delete operation
    delPrefHtls(deleteItemId).then((response) => {
      if (response) {     
        dispatch({ type: "notiMessageShow", payload: true });
        dispatch({ type: "notiMessage", payload: (context.translation && context.translation.prefhotel) });
        dispatch({ type: "notiVarient", payload: "success" });
        hidemessage();
        // setTimeout(() => {
        //   window.location.reload();
        // }, 300)
        setLoadData(!loadData);
      }
    }).catch(function (error) {
      console.error("Error:" + error);      
    });
    // ....
    // After successful deletion, update the tableData state
    const updatedHotels = selectedHotels.filter(
      (item) => item.key !== deleteItemId
    );
    setSelectedHotels(updatedHotels);
    setConfirmModalVisiblePrefHtls(false);
  };

  // Handle cancel delete
  const handleCancelDeletePrefHtl = () => {
    setConfirmModalVisiblePrefHtls(false);
  };
 
  const columns = [
    {
      title: (context.translation && context.translation.type),
      dataIndex: "type",
      key: "type",
      id: "id",
    },
    {
      title: (context.translation && context.translation.hotelChain),
      dataIndex: "htl_name",
      key: "htl_name",
      id: "id",
    },
    {
      title: (context.translation && context.translation.city),
      dataIndex: "city",
      key: "city",
      id: "id",
    },
    {
      title: (context.translation && context.translation.hotelName),
      dataIndex: "country",
      key: "country",
      id: "id",
    },
    {
      title: (context.translation && context.translation.actions),
      dataIndex: "actions",
      key: "actions",
      id: "id",
      render: (_, record, index) => (
        <Space>
          <i
            className="icon icon-Delete"
            onClick={() => handleDeleteClickPrefHtl(_, record, index)}
          ></i>
        </Space>
      ),
    },
  ];

  const handleAddCity = () => {
    if (!state.destName.includes(",")) {
      dispatch({ type: "freqVisCity", payload: true });
      // dispatch({ type: "notiMessageShow", payload: true });
      // dispatch({ type: "notiMessage", payload: "Please Select Frequently visited city" });
      // dispatch({ type: "notiVarient", payload: "error" });
      hidemessage();
      return false;
    }
    if (state.destName.trim() === "") return;
    const existingCities = cities.filter((city) => city.city === state.destName.split(",")[0])
    if (existingCities[0]?.city === state.destName.split(",")[0]) {
      dispatch({ type: "notiMessageShow", payload: true });
      dispatch({ type: "notiMessage", payload: (context.translation && context.translation.fvCityex) });
      dispatch({ type: "notiVarient", payload: "error" });
      hidemessage();
      return false;
    }

    const updatedCities = [
      ...cities,
      {
        city: state.destName.split(",")[0],
        country: state.destName.split(",")[1],
      },
    ];
    setCities(updatedCities);
    setNewCity("");
    dispatch({ type: "destName", payload: "" });
    dispatch({ type: "freqVisCity", payload: false });
  };

  // Handle delete button click 
  const handleClickFreqVisitedCity = (_, record, index) => {
    if (record.id === undefined) {       
      const updatedCities = cities.filter((item) => item.city !== record.city);
      setCities(updatedCities);
    } else {
      setDeleteItemId(record.id);
      setConfirmModalVisibleFreqVisCities(true);
    }
  }

  // Handle confirm delete
  const handleConfirmDeleteFreqVisitedCity = () => {
    // Call API with deleteItemId and perform delete operation
    delFreqVisitedCity(deleteItemId).then((response) => {
      if (response) {     
        dispatch({ type: "notiMessageShow", payload: true });
        dispatch({ type: "notiMessage", payload: (context.translation && context.translation.fvCitysuc) });
        dispatch({ type: "notiVarient", payload: "success" });
        hidemessage();
        // setTimeout(() => {
        //   window.location.reload();
        // }, 300)
        setLoadData(!loadData);
      }
    }).catch(function (error) {
      console.error("Error:" + error);      
    });
    // ....
    // After successful deletion, update the tableData state
    const updatedCities = cities.filter((item) => item.key !== deleteItemId);
    setCities(updatedCities);
    setConfirmModalVisibleFreqVisCities(false);
  };

  // Handle cancel delete
  const handleCancelDeleteFreqVisitedCity = () => {
    setConfirmModalVisibleFreqVisCities(false);
  };

  const CitiesColumns = [
    {
      title: (context.translation && context.translation.city),
      dataIndex: "city",
      key: "city",
      id: "id",
    },
    {
      title: (context.translation && context.translation.actions),
      key: "actions",
      id: "id",
      render: (_, record, index) => (
        <Space>
          <i
            className="icon icon-Delete"
            onClick={() => handleClickFreqVisitedCity(_, record, index)}
          ></i>
        </Space>
      ),
    },
  ];

  const handleDestinationAddCity = () => {
    if (!state.prefDestName.includes(",")) {      
      dispatch({ type: "prefDestError", payload: true });      
      // dispatch({ type: "notiMessageShow", payload: true });
      // dispatch({ type: "notiMessage", payload: "Please Select Preferred Destination" });
      // dispatch({ type: "notiVarient", payload: "error" });
      hidemessage();
      return false;
    }
    if (state.prefDestName.trim() === "") return;
    const existingDestCities = destinationCities.filter((city) => city.city === state.prefDestName.split(",")[0])
    if (existingDestCities[0]?.city === state.prefDestName.split(",")[0]) {
      dispatch({ type: "notiMessageShow", payload: true });
      dispatch({ type: "notiMessage", payload: (context.translation && context.translation.perfDestexist) });
      dispatch({ type: "notiVarient", payload: "error" });
      hidemessage();
      return false;
    }

    const updatedCities = [
      ...destinationCities,
      {
        city: state.prefDestName.split(",")[0],
        country: state.prefDestName.split(",")[2],
      },
    ];
    setDestinationCities(updatedCities);
    setDestinationNewCity("");
    dispatch({ type: "prefDestName", payload: "" });
    dispatch({ type: "prefDestError", payload: false });
  };

  // Handle delete button click 
  const handleDeleteClickPrefDest = (_, record, index) => {
    if (record.id === undefined) {      
      const updatedCities = [...destinationCities];
      updatedCities.splice(index, 1);
      setDestinationCities(updatedCities);
    } else {
      setDeleteItemId(record.id);
      setConfirmModalVisiblePrefDest(true);
    }

  }

  // Handle confirm delete
  const handleConfirmDeletePrefDest = () => {
    // Call API with deleteItemId and perform delete operation
    delPrefDest(deleteItemId).then((response) => {
      if (response) {     
        dispatch({ type: "notiMessageShow", payload: true });
        dispatch({ type: "notiMessage", payload: (context.translation && context.translation.perfDestsuc) });
        dispatch({ type: "notiVarient", payload: "success" });
        hidemessage();
        // setTimeout(() => {
        //   window.location.reload();
        // }, 300)
        setLoadData(!loadData);
      }
    }).catch(function (error) {
      console.error("Error:" + error);      
    });
    // ....
    // After successful deletion, update the tableData state
    const updatedCities = destinationCities.filter(
      (item) => item.key !== deleteItemId
    );
    setDestinationCities(updatedCities);
    setConfirmModalVisiblePrefDest(false);
  };

  // Handle cancel delete
  const handleCancelDeletePrefDest = () => {
    setConfirmModalVisiblePrefDest(false);
  };

  const destinationColumns = [
    {
      title: (context.translation && context.translation.city),
      dataIndex: "city",
      key: "city",
      id: "id",
    },
    {
      title: (context.translation && context.translation.actions),
      key: "actions",
      id: "id",
      render: (_, record, index) => (
        <Space>
          <i
            className="icon icon-Delete"
            onClick={() => handleDeleteClickPrefDest(_, record, index)}
          ></i>
        </Space>
      ),
    },
  ];

  const validateAddPreferences = () => {
    const uniq_tableDatas = tableData.filter((obj) => {
      return obj.id === undefined;
    });
    const uniq_cities = cities.filter((obj) => {
      return obj.id === undefined;
    });
    const uniq_dest_cities = destinationCities.filter((obj) => {
      return obj.id === undefined;
    });
    const uniq_htlList = selectedHotels.filter((obj) => {
      return obj.id === undefined;
    });
    if (tableData.length === 0 && selectedHotels.length === 0 && cities.length === 0 && destinationCities.length === 0) {
      dispatch({ type: "notiMessageShow", payload: true });
      // dispatch({ type: "notiMessage", payload: (context.translation && context.translation.plzaddairline) });
      dispatch({ type: "notiMessage", payload: "Please add any one preference" });
      dispatch({ type: "notiVarient", payload: "error" });
      hidemessage();
      return false;
    } else if (uniq_tableDatas.length === 0 && uniq_htlList.length === 0 && uniq_cities.length === 0 && uniq_dest_cities.length === 0) {
      dispatch({ type: "notiMessageShow", payload: true });
      // dispatch({ type: "notiMessage", payload: (context.translation && context.translation.plzaddairline) });
      dispatch({ type: "notiMessage", payload: "Please add atleast one new preference" });
      dispatch({ type: "notiVarient", payload: "error" });
      hidemessage();
      return false;
    } 
    // else if (selectedHotels.length === 0) {
    //   dispatch({ type: "notiMessageShow", payload: true });
    //   dispatch({ type: "notiMessage", payload: (context.translation && context.translation.plzaddprefhotel) });
    //   dispatch({ type: "notiVarient", payload: "error" });
    //   hidemessage();
    //   return false;
    // } else if (cities.length === 0) {
    //   dispatch({ type: "notiMessageShow", payload: true });
    //   dispatch({ type: "notiMessage", payload: (context.translation && context.translation.plzaddfvcity) });
    //   dispatch({ type: "notiVarient", payload: "error" });
    //   hidemessage();
    //   return false;
    // } else if (destinationCities.length === 0) {
    //   dispatch({ type: "notiMessageShow", payload: true });
    //   dispatch({ type: "notiMessage", payload: (context.translation && context.translation.plzaddprefdest) });
    //   dispatch({ type: "notiVarient", payload: "error" });
    //   hidemessage();
    //   return false;
    // }
    return true;
  };

  const handleSavePreferencesCreation = (e) => {
    e.preventDefault();
    if (validateAddPreferences()) {
      const uniq_tableDatas = tableData.filter((obj) => {
        return obj.id === undefined;
      });
      const uniq_cities = cities.filter((obj) => {
        return obj.id === undefined;
      });
      const uniq_dest_cities = destinationCities.filter((obj) => {
        return obj.id === undefined;
      });
      const uniq_htlList = selectedHotels.filter((obj) => {
        return obj.id === undefined;
      });
      const payload = {
        // usr_id: state.id,
        region_type: region === "IN" ? "India" : "Qatar",
        usr_uniq_id: userUniqueId,
        usr_email: email,
        pref_air: uniq_tableDatas,
        pref_htl: {
          pref_type: hotelChain ? "HotelChain" : "City",
          htl_list: uniq_htlList,
        },
        freq_city: uniq_cities,
        pref_dest: uniq_dest_cities,
      };
      let tokenKey = Utilities.generateAPIToken(payload);
      savePreferences(payload,tokenKey).then((response) => {
        if (response.suc) {
          dispatch({ type: "notiMessageShow", payload: true });
          dispatch({ type: "notiMessage", payload: (context.translation && context.translation.perfsavSucc) });
          dispatch({ type: "notiVarient", payload: "success" });
          hidemessage();
          // setTimeout(() => {
          //   window.location.reload();
          // }, 1000)
          setLoadData(!loadData);
        } else if (
          // response.data.status === 404 ||
          response !== null &&
          response?.data?.errors?.length > 0
        ) {
          dispatch({ type: "notiMessageShow", payload: true });
          dispatch({ type: "notiMessage", payload: (context.translation && context.translation.somewnWrong) });
          dispatch({ type: "notiVarient", payload: "error" });
          hidemessage();
          return false;
        } else {
          dispatch({ type: "notiMessageShow", payload: true });
          dispatch({ type: "notiMessage", payload: (context.translation && context.translation.prefdtnotSav) });
          dispatch({ type: "notiVarient", payload: "error" });
          hidemessage();
          return false;
        }
      }).catch(function (error) {
        console.error("Error:" + error);      
      });
    }
  };

  const handleResetForm = () => {
    dispatch({ type: "pref_air", payload: [] });
    dispatch({ type: "hotelChainName", payload: "" });
    dispatch({ type: "hotelChainCityHotelName", payload: "" });
    dispatch({ type: "hotelChainCityName", payload: "" });
    dispatch({ type: "destName", payload: "" });
    dispatch({ type: "prefDestName", payload: "" });
  };

  //=====================================
  // This function will get the Source data
  //=====================================
  const handleChangOrigin = (e) => {
    const { id, value } = e.target;
    if (value.length > 0) {
      dispatch({ type: "cleardb", payload: true });
    } else {
      dispatch({ type: "cleardb", payload: false });
    }
    // dispatch({ type: "isSearching", payload: true });
    if (value.length > 2) {
      let obj = {
        textValue: value,
      };
      debouncedSave(obj);
    } else {
      dispatch({ type: "hotelData", payload: [] });
    }
    dispatch({ type: id, payload: value });
    // dispatch({ type: "isSearching", payload: false });
  };

  //=====================================
  // This function will get the Source data
  //=====================================
  const handleChangOriginPD = (e) => {
    const { id, value } = e.target;
    if (value.length > 0) {
      dispatch({ type: "cleardb", payload: true });
    } else {
      dispatch({ type: "cleardb", payload: false });
    }
    // dispatch({ type: "isSearching", payload: true });
    if (value.length > 2) {
      let obj = {
        textValue: value,
      };
      debouncedSavePD(obj);
    } else {
      dispatch({ type: "hotelData", payload: [] });
    }
    dispatch({ type: id, payload: value });
    // dispatch({ type: "isSearching", payload: false });
  };

  //=====================================
  // This function will get the Source data
  //=====================================
  const handleChangOriginPHCN = (e) => {
    const { id, value } = e.target;
    if (value.length > 0) {
      dispatch({ type: "cleardb", payload: true });
    } else {
      dispatch({ type: "cleardb", payload: false });
    }
    // dispatch({ type: "isSearching", payload: true });
    if (value.length > 2) {
      let obj = {
        textValue: value,
      };
      debouncedSavePHCN(obj);
    } else {
      dispatch({ type: "hotelData", payload: [] });
    }
    dispatch({ type: id, payload: value });
    // dispatch({ type: "isSearching", payload: false });
  };

  // highlight-starts
  const debouncedSavePHCN = useCallback(
    debounce((obj) => predectiveCallPHCN(obj.textValue), 500),
    [] // will be created only once initially
  );

  //=====================================
  // This function will call the service to get the Hotel data
  //=====================================
  const predectiveCallPHCN = (input) => {
    dispatch({ type: "prefHotelCityNamePredectiveDropDown", payload: false });
    try {
      const payload = {
        searchCriteria: input,
        lang: context.selectLang
      };
      let tokenKey = Utilities.generateAPIToken(payload);
      PredectiveSearch.retreieveSearch(payload,tokenKey).then((data) => {
        dispatch({
          type: "hotelData",
          payload: data.data.data !== "" ? [data.data.data] : [],
        });
        dispatch({ type: "showResults", payload: true });
        dispatch({ type: "prefHotelCityNamePredectiveDropDown", payload: true });
      }).catch(function (error) {
        console.error("Error in Preferences" + error);      
      });      
    } catch (error) {
      console.error("Error in Preferences" + error);        
    }
  };

  //=====================================
  // This function will get the Source data
  //=====================================
  const handleChangOriginHC = (e) => {
    const { id, value } = e.target;
    if (value.length > 0) {
      dispatch({ type: "cleardb", payload: true });
    } else {
      dispatch({ type: "cleardb", payload: false });
    }
    // dispatch({ type: "isSearching", payload: true });
    if (value.length > 2) {
      let obj = {
        textValue: value,
      };
      debouncedSaveHC(obj);
    } else {
      dispatch({ type: "hotelData", payload: [] });
    }
    dispatch({ type: id, payload: value });
    // dispatch({ type: "isSearching", payload: false });
  };

  //=====================================
  // This function will get the Source data
  //=====================================
  const handleChangOriginHCCH = (e) => {
    const { id, value } = e.target;
    if (value.length > 0) {
      dispatch({ type: "cleardb", payload: true });
    } else {
      dispatch({ type: "cleardb", payload: false });
    }
    // dispatch({ type: "isSearching", payload: true });
    if (value.length > 2) {
      let obj = {
        textValue: value,
        filterid: state.hotelChainCityNameId,
      };
      debouncedSaveHCCH(obj);
    } else {
      dispatch({ type: "hotelData", payload: [] });
    }
    dispatch({ type: id, payload: value });
    // dispatch({ type: "isSearching", payload: false });
  };

  // highlight-starts
  const debouncedSave = useCallback(
    debounce((obj) => predectiveCall(obj.textValue), 500),
    [] // will be created only once initially
  );

  //=====================================
  // This function will call the service to get the Hotel data
  //=====================================
  const predectiveCall = (input) => {
    dispatch({ type: "hotelPredectiveDropDown", payload: false });
    try {
      const payload = {
        searchCriteria: input,
        lang: context.selectLang
      };
      let tokenKey = Utilities.generateAPIToken(payload);
      PredectiveSearch.retreieveSearch(payload,tokenKey).then((data) => {
        dispatch({
          type: "hotelData",
          payload: data.data.data !== "" ? [data.data.data] : [],
        });
        dispatch({ type: "showResults", payload: true });
        dispatch({ type: "hotelPredectiveDropDown", payload: true });
      }).catch(function (error) {
        console.error("Error in Preferences" + error);      
      });      
    } catch (error) {
      console.error("Error in Preferences" + error);        
    }
  };

  // highlight-starts
  const debouncedSavePD = useCallback(
    debounce((obj) => predectiveCallPD(obj.textValue), 500),
    [] // will be created only once initially
  );

  //=====================================
  // This function will call the service to get the Hotel data
  //=====================================
  const predectiveCallPD = (input) => {
    dispatch({ type: "prefDestPredectiveDropDown", payload: false });
    try {
      const payload = {
        searchCriteria: input,
        lang: context.selectLang
      };
      let tokenKey = Utilities.generateAPIToken(payload);
      PredectiveSearch.retreieveSearch(payload,tokenKey).then((data) => {
        dispatch({
          type: "hotelData",
          payload: data.data.data !== "" ? [data.data.data] : [],
        });
        dispatch({ type: "showResults", payload: true });
        dispatch({ type: "prefDestPredectiveDropDown", payload: true });
      }).catch(function (error) {
        console.error("Error in Preferences" + error);      
      });      
    } catch (error) {
      console.error("Error in Preferences" + error);         
    }
  };

  // highlight-starts
  const debouncedSaveHC = useCallback(
    debounce((obj) => predectiveCallHC(obj.textValue), 500),
    [] // will be created only once initially
  );

  //=====================================
  // This function will call the service to get the Hotel data
  //=====================================
  const predectiveCallHC = (input) => {
    dispatch({ type: "hotelChainPredectiveDropDown", payload: false });
    try {
      const payload = {
        searchCriteria: input,
        lang: context.selectLang
      };
      let tokenKey = Utilities.generateAPIToken(payload);
      PredectiveSearch.retreieveSearch(payload,tokenKey).then((data) => {
        dispatch({
          type: "hotelData",
          payload: data.data.data !== "" ? [data.data.data] : [],
        });
        // setHotelChainName(data.data.data.property);
        dispatch({ type: "showResults", payload: true });
        dispatch({ type: "hotelChainPredectiveDropDown", payload: true });
      }).catch(function (error) {
        console.error("Error in Preferences" + error);      
      });      
    } catch (error) {
      console.error("Error in Preferences" + error);        
    }
  };

  // highlight-starts
  const debouncedSaveHCCH = useCallback(
    debounce((obj) => predectiveCallHCCH(obj.textValue, obj.filterid), 500),
    [] // will be created only once initially
  );

  //=====================================
  // This function will call the service to get the Hotel data
  //=====================================
  const predectiveCallHCCH = (input, id) => {
    dispatch({ type: "hotelChainCityHotelPredectiveDropDown", payload: false });
    try {
      const payload = {
        searchCriteria: input,
        lang: context.selectLang
      };
      let tokenKey = Utilities.generateAPIToken(payload);
      PredectiveSearch.retreieveSearch(payload,tokenKey).then((data) => {
        const filterHotel = data.data.data !== "" ? data.data.data.property.filter((obj) => id === obj.expediaCityId) : []
        // dispatch({
        //   type: "hotelData",
        //   payload: data.data.data !== "" ? [data.data.data] : [],
        // });
        dispatch({
          type: "hotelData",
          payload: filterHotel,
        });
        // setHotelChainName(data.data.data.property);
        dispatch({ type: "showResults", payload: true });
        dispatch({
          type: "hotelChainCityHotelPredectiveDropDown",
          payload: true,
        });
      }).catch(function (error) {
        console.error("Error in Preferences" + error);      
      });    
    } catch (error) {
      console.error("Error in Preferences" + error);             
    }
  };

  //=====================================
  // This function will get the Source data
  //=====================================
  const handleOnOrigin = (obj, type) => () => {
    if (obj !== undefined) {
      dispatch({ type: "hotelPredectiveDropDown", payload: false });
      obj.type = type;
      if (type !== "city") {
        dispatch({
          type: "destName",
          payload: obj.text + ", " + obj.country,
        });
      } else {
        if (obj.description !== null) {
          dispatch({
            type: "destName",
            payload: obj.text + ", " + obj.country,
          });
        } else {
          dispatch({
            type: "destName",
            payload: obj.text + ", " + obj.country,
          });
        }
      }

      dispatch({ type: "selHotel", payload: [obj] });
    }
  };

  //=====================================
  // This function will get the Source data
  //=====================================
  const handleOnOriginPD = (obj, type) => () => {
    if (obj !== undefined) {
      dispatch({ type: "prefDestPredectiveDropDown", payload: false });
      obj.type = type;
      if (type !== "city") {
        dispatch({
          type: "prefDestName",
          payload: obj.text + ", " + obj.country,
        });
      } else {
        if (obj.description !== null) {
          dispatch({
            type: "prefDestName",
            payload: obj.text + ", " + obj.country,
          });
        } else {
          dispatch({
            type: "prefDestName",
            payload: obj.text + ", " + obj.country,
          });
        }
      }

      dispatch({ type: "selHotel", payload: [obj] });
    }
  };

  //=====================================
  // This function will get the Source data
  //=====================================
  const handleOnOriginPHCN = (obj, type) => () => {
    if (obj !== undefined) {
      dispatch({ type: "prefHotelCityNamePredectiveDropDown", payload: false });
      obj.type = type;
      if (type !== "city") {
        dispatch({
          type: "hotelChainCityName",
          payload: obj.text + ", " + obj.country,
        });
        dispatch({
          type: "hotelChainCityNameId",
          payload: obj.expediaCityId,
        });
      } else {
        if (obj.description !== null) {
          dispatch({
            type: "hotelChainCityName",
            payload: obj.text + ", " + obj.country,
          });
          dispatch({
            type: "hotelChainCityNameId",
            payload: obj.expediaCityId,
          });
        } else {
          dispatch({
            type: "hotelChainCityName",
            payload: obj.text + ", " + obj.country,
          });
          dispatch({
            type: "hotelChainCityNameId",
            payload: obj.expediaCityId,
          });
        }
      }

      dispatch({ type: "selHotel", payload: [obj] });
    }
  };

  //=====================================
  // This function will get the Source data
  //=====================================
  const handleOnOriginHC = (obj, type) => () => {
    if (obj !== undefined) {
      dispatch({ type: "hotelChainPredectiveDropDown", payload: false });
      obj.type = type;
      if (type !== "city") {
        dispatch({
          type: "hotelChainName",
          payload: obj.text + ", " + obj.country,
        });
      } else {
        if (obj.description !== null) {
          dispatch({ type: "hotelChainName", payload: obj.description });
        } else {
          dispatch({
            type: "hotelChainName",
            payload: obj.text + ", " + obj.country,
          });
        }
      }

      dispatch({ type: "selHotel", payload: [obj] });
    }
  };

  //=====================================
  // This function will get the Source data
  //=====================================
  const handleOnOriginHCCH = (obj, type) => () => {
    if (obj !== undefined) {
      dispatch({
        type: "hotelChainCityHotelPredectiveDropDown",
        payload: false,
      });
      obj.type = type;
      if (type !== "city") {
        dispatch({
          type: "hotelChainCityHotelName",
          payload: obj.text + ", " + obj.country,
        });
      } else {
        if (obj.description !== null) {
          dispatch({
            type: "hotelChainCityHotelName",
            payload: obj.description,
          });
        } else {
          dispatch({
            type: "hotelChainCityHotelName",
            payload: obj.text + ", " + obj.country,
          });
        }
      }

      dispatch({ type: "selHotel", payload: [obj] });
    }
  };

  
  //For handling the outside click
  const handleClickOutside = (event) => {
    if (
      inputRef.current &&
      inputRef.current instanceof HTMLElement &&
      !inputRef.current.contains(event.target)
    ) {
      if (
        event.target.classList.contains("ant-row") ||
        event.target.classList.contains("ant-col") ||
        event.target.classList.contains("container") ||
        event.target.classList.contains("ant-typography") ||
        event.target.classList.contains("ant-radio-group") ||
        event.target.classList.contains("ant-radio-input") ||
        event.target.classList.contains("preferredHotel")
      ) {
        dispatch({ type: "hotelPredectiveDropDown", payload: false });
        dispatch({ type: "prefDestPredectiveDropDown", payload: false });
        dispatch({ type: "hotelChainPredectiveDropDown", payload: false });
        dispatch({ type: "hotelChainCityHotelPredectiveDropDown", payload: false });
        dispatch({ type: "prefHotelCityNamePredectiveDropDown", payload: false });
      }
      if(event.target.classList.contains("ant-radio-input")) {
        dispatch({ type: "hotelChainName", payload: "" });
        dispatch({ type: "hotelChainCityName", payload: "" });
      }
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const homePage = () => {
    navigate("/");
  }

  if (context.user_resp === "" && localStorage.getItem("SIGNINRESPONSE") === null) {
    return ( 
        <Modal
          title=""
          open={true}
          width={460}
          footer={null}
          maskClosable={false}
        >
          <Title level={4}>
            {/* {context.translation && context.translation.plzlog} */}
            {(sessionStorage.getItem("SEL_LAG") === null || sessionStorage.getItem("SEL_LAG") === "English") &&
              "Please log in to access your profile"              
            }
            {sessionStorage.getItem("SEL_LAG") === "Arabic" &&
              "يرجى تسجيل الدخول لملفك الشخصي"              
            }
          </Title>

          <Button type="primary" className="cntBtn sendBtn" onClick={homePage}>
            {/* {context.translation && context.translation.backHg} */}
            {(sessionStorage.getItem("SEL_LAG") === null || sessionStorage.getItem("SEL_LAG") === "English") &&
              "Back to Home Page"              
            }
            {sessionStorage.getItem("SEL_LAG") === "Arabic" &&
              "عودة للصفحة الرئيسية"              
            }
          </Button>
        </Modal>
    );
  }

  

  const showDrawer = () => {
    setOpen(true);
    setshowMenu(!showMenu);
  };

  const onClose = () => {
    setOpen(false);
  };

  const closeMenu = () => {
    showDrawer();
  };

  const update=(type)=>{
  }

  return (
    <>
      {state.notiMessageShow ? (
        <Alert
          description={state.notiMessage}
          type={state.notiVarient}
          closable
          onClose={() => dispatch({ type: "notiMessageShow", payload: false })}
          className="alertForm"
          // showIcon
        >
          {" "}
          {state.notiMessage}
        </Alert>
      ) : (
        ""
      )}
      <Header setTab={update} />
      <div className="myAccountPage">
        <div className="myAccountBanner"></div>
        <div className="container">
        {/* <div className="sideMenuBar">
            <div
              onClick={showDrawer}
              className={"sideMenu btn5 " + (showMenu && "active")}
              data-menu="5"
              closeMenus={closeMenu}
            >
              <div className="icon"></div>
            </div>
          </div>
          <Drawer placement="left" open={open} className="navDrawer">
            <div>
              <i className="icon icon-cancel" onClick={onClose}></i>
            </div>
            <Sidebar active="userProfile" />
          </Drawer> */}
          <Row>
            <Col md={5} className="firstChild">
              <Sidebar active="preferences" />
            </Col>
            <Col md={19} className="lastChild">
              <div className="cardDetails addPreferencesDetails">
                <div className="cardDetailsHeader">
                  <div>
                    <Title level={4} className="addPreferenceTitle">{context.translation && context.translation.addPreference}</Title>
                    {/* <p>Loreum ipsum dolar met</p> */}
                  </div>
                </div>
                <div className="cardDetailsBody">
                  <Form layout="vertical">
                    <div className="padding20 preferredHotel twoData">
                      <Row ref={inputRef}>
                        <Col span={8}>
                          <Form.Item label={context.translation && context.translation.preferredAirline}>
                            <Space
                              style={{ width: "100%" }}
                              direction="vertical"
                            >
                              <Select
                                mode="multiple"
                                showSearch
                                allowClear
                                // style={{ width: "100%" }}
                                placeholder={context.translation && context.translation.select}
                                options={airlineMaster}
                                value={state.pref_air}
                                // defaultValue={["a10", "c12"]}
                                // className="preferredAirlineInput"
                                className={
                                  "preferredAirlineInput " +
                                  (state.pref_air.length === 0 && state.selectairlineField ? "errorForm" : "")
                                }
                                onChange={(value) => (
                                  dispatch({
                                    type: "pref_air",
                                    payload: value,
                                  })
                                )}
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  (option?.label ?? "")
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                                }
                              />
                            </Space>
                          </Form.Item>                         
                            {state.pref_air.length === 0 && state.selectairlineField && (
                              <div className="error">
                                {context.translation && context.translation.plzseleAirline}
                              </div>
                            )}
                        </Col>
                        <Col span={2}>
                          {tableData.length < 5 && (
                          <Button
                            type="primary"
                            className="cntBtn addBtn"
                            onClick={handleAddClick}
                            // disabled={!prefAirlines.length}
                          >
                            +
                            {/* <span>{context.translation && context.translation.add}</span> */}
                            {/* <i className="sprite btn-arrow-sprite"></i> */}
                          </Button>)}
                        </Col>
                      </Row>
                      {tableData.length > 0 && (
                        <Table
                          dataSource={tableData}
                          columns={airlinesColumns}
                          pagination={false}
                        />
                      )}

                      <Modal
                        title={context.translation && context.translation.confirmDel}
                        open={confirmModalVisible}
                        onOk={handleConfirmDelete}
                        onCancel={handleCancelDelete}
                        okText= {context.translation && context.translation.ok}
                        cancelText= {context.translation && context.translation.cancel}
                      >
                        <p>{context.translation && context.translation.delItem}</p>
                      </Modal>
                    </div>
                    <div className="padding20 preferredHotel twoData">
                      <Title level={5} className="preferredHotelTitle">{context.translation && context.translation.prefHotel}</Title>
                      <Radio.Group
                        onChange={handleRadioChange}
                        value={hotelChain ? "hotelChain" : "city"}
                      >
                        <Radio value="hotelChain">{context.translation && context.translation.hotelChain}</Radio>
                        <Radio value="city">{context.translation && context.translation.city}</Radio>
                      </Radio.Group>
                      {hotelChain && (
                        <Row className="hotelChain">
                          <Col span={8}>
                            <Form.Item label="" id="hotelChain">
                              <Input
                                allowClear
                                id="hotelChainName"
                                type="text"
                                autoComplete="off"
                                value={state.hotelChainName}
                                // className="innerBoxShadow"
                                className={
                                  "innerBoxShadow " +
                                  (state.hotelChainName === "" && state.htlChainField ? "errorForm" : "")
                                }
                                placeholder={context.translation && context.translation.searchdot}
                                onChange={handleChangOriginHC}
                                ref={contentRef}
                              />

                              {state.hotelChainPredectiveDropDown && (
                                <div
                                  className={
                                    state.hotelData !== undefined &&
                                    state.hotelData.length !== 0 &&
                                    state.hotelData[0].city.length !== 0
                                      ? "pdtSerchPart"
                                      : "pdtSerchPart nocity"
                                  }
                                >
                                  <div>
                                    <div className="searchContent">
                                      {state.hotelData !== undefined &&
                                        state.showResults &&
                                        state.hotelData.length !== 0 && (
                                          <>
                                            {/* {state.isSearching && <p className="searchig">Searching...</p>} */}
                                            {state.hotelData !== undefined &&
                                              state.hotelData[0].city.length ===
                                                0 &&
                                              state.hotelData[0].landmark
                                                .length === 0 &&
                                              state.hotelData[0].area.length ===
                                                0 &&
                                              state.hotelData[0].property
                                                .length === 0 && (
                                                <p className="nomachFound">
                                                  {context.translation && context.translation.nomfound}
                                                </p>
                                              )}
                                            <Row>
                                              {state.hotelData[0].property !==
                                                undefined &&
                                                state.hotelData[0].property
                                                  .length !== 0 && (
                                                  <Col className="pl-2">
                                                    {state.hotelData[0].property.map(
                                                      (hotel, index) => (
                                                        <div
                                                          key={index}
                                                          className="plc"
                                                          // className="plc ezyIcon icon-hotel"
                                                          onClick={handleOnOriginHC(
                                                            hotel,
                                                            "property"
                                                          )}
                                                        >
                                                          <span
                                                            className="plcNm"
                                                            title={
                                                              hotel.description +
                                                              hotel.country
                                                            }
                                                          >
                                                            {" "}
                                                            {hotel.text},{" "}
                                                            {hotel.country}{" "}
                                                          </span>
                                                        </div>
                                                      )
                                                    )}
                                                  </Col>
                                                )}
                                            </Row>
                                          </>
                                        )}
                                      {state.hotelData.length === 0 && (
                                        <div className="typ">
                                          {context.translation && context.translation.noresultFund}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              )}
                            </Form.Item>                            
                            {state.hotelChainName === "" && state.htlChainField && (
                              <div className="error">
                                {context.translation && context.translation.plzsHc}
                              </div>
                            )}
                          </Col>
                          {selectedHotels.length < 5 && (
                            <Col span={2}>
                              <Button
                                type="primary"
                                className="addMore"
                                onClick={handleAdd}
                              >
                                +
                              </Button>
                            </Col>
                          )}
                        </Row>
                      )}
                      {!hotelChain && (
                        <Row className="cityAndHotel">
                          <Col span={8}>
                            <Form.Item label="">
                              <Input
                                allowClear
                                id="hotelChainCityName"
                                type="text"
                                autoComplete="off"
                                value={state.hotelChainCityName}
                                // className="innerBoxShadow"
                                className={
                                  "innerBoxShadow " +
                                  (state.hotelChainCityName === "" && state.prefHotelCityField ? "errorForm" : "")
                                }
                                placeholder={context.translation && context.translation.selecturCity}
                                onChange={handleChangOriginPHCN}
                                ref={contentRef}
                              />
                              {state.prefHotelCityNamePredectiveDropDown && (
                                <div
                                  className={
                                    state.hotelData !== undefined &&
                                    state.hotelData.length !== 0 &&
                                    state.hotelData[0].city.length !== 0
                                      ? "pdtSerchPart"
                                      : "pdtSerchPart nocity"
                                  }
                                >
                                  <div>
                                    <div className="searchContent">
                                      {state.hotelData !== undefined &&
                                        state.showResults &&
                                        state.hotelData.length !== 0 && (
                                          <>
                                            {/* {state.isSearching && <p className="searchig">Searching...</p>} */}
                                            {state.hotelData !== undefined &&
                                              state.hotelData[0].city.length ===
                                                0 &&
                                              state.hotelData[0].landmark
                                                .length === 0 &&
                                              state.hotelData[0].area.length ===
                                                0 &&
                                              state.hotelData[0].property
                                                .length === 0 && (
                                                <p className="nomachFound">
                                                  {context.translation && context.translation.nomfound}
                                                </p>
                                              )}
                                            <Row>
                                              {state.hotelData[0].city !==
                                                undefined &&
                                                state.hotelData[0].city
                                                  .length !== 0 && (
                                                  <Col className="pr-2">
                                                    {state.hotelData[0].city.map(
                                                      (cty, index) => (
                                                        <div
                                                          key={index}
                                                          className="plc ezyIcon icon-map"
                                                          onClick={handleOnOriginPHCN(
                                                            cty,
                                                            "city"
                                                          )}
                                                        >
                                                          {cty.description !==
                                                            null && (
                                                            <span
                                                              className="plcNm"
                                                              title={
                                                                cty.description
                                                              }
                                                            >
                                                              {" "}
                                                              {
                                                                cty.description
                                                              }
                                                            </span>
                                                          )}
                                                          {cty.description ==
                                                            null && (
                                                            <span
                                                              className="plcNm"
                                                              title={
                                                                cty.text +
                                                                cty.country
                                                              }
                                                            >
                                                              {" "}
                                                              {cty.text},{" "}
                                                              {cty.country}
                                                            </span>
                                                          )}
                                                          {/* <span className="plcTyp">City</span> */}
                                                        </div>
                                                      )
                                                    )}
                                                  </Col>
                                                )}
                                            </Row>
                                          </>
                                        )}
                                      {state.hotelData.length === 0 && (
                                        <div className="typ">
                                          {context.translation && context.translation.noresultFund}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              )}
                            </Form.Item>                      
                            {state.hotelChainCityName === "" && state.prefHotelCityField && (
                              <div className="error">
                                {context.translation && context.translation.plzsCitynm}
                              </div>
                            )}
                          </Col>
                          <Col span={8} className="searchYourHotel">
                            <Form.Item label="">
                              <Input
                                allowClear
                                id="hotelChainCityHotelName"
                                type="text"
                                autoComplete="off"
                                value={state.hotelChainCityHotelName}
                                // className="innerBoxShadow"
                                className={
                                  "innerBoxShadow " +
                                  (state.hotelChainCityHotelName === "" && state.prefHotelHotelField ? "errorForm" : "")
                                }
                                placeholder={context.translation && context.translation.searchurHotel}
                                disabled={state.hotelChainCityName === "" ? true : false}
                                onChange={handleChangOriginHCCH}
                                ref={contentRef}
                              />

                              {state.hotelChainCityHotelPredectiveDropDown && (
                                <div
                                  className={
                                    state.hotelData !== undefined &&
                                    state.hotelData.length !== 0 &&
                                    state.hotelData.length !== 0
                                      ? "pdtSerchPart"
                                      : "pdtSerchPart nocity"
                                  }
                                >
                                  <div>
                                    <div className="searchContent">
                                      {state.hotelData !== undefined &&
                                        state.showResults &&
                                        state.hotelData.length !== 0 && (
                                          <>
                                            {/* {state.isSearching && <p className="searchig">Searching...</p>} */}
                                            {state.hotelData !== undefined &&
                                              // state.hotelData[0].city.length ===
                                              //   0 &&
                                              // state.hotelData[0].landmark
                                              //   .length === 0 &&
                                              // state.hotelData[0].area.length ===
                                              //   0 &&
                                              state.hotelData.length === 0 && (
                                                <p className="nomachFound">
                                                  {context.translation && context.translation.nomfound}
                                                </p>
                                              )}
                                            <Row>
                                              {state.hotelData !==
                                                undefined &&
                                                state.hotelData.length !== 0 && (
                                                  <Col className="pl-2">
                                                    {state.hotelData.map(
                                                      (hotel, index) => (
                                                        <div
                                                          key={index}
                                                          className="plc"
                                                          // className="plc ezyIcon icon-hotel"
                                                          onClick={handleOnOriginHCCH(
                                                            hotel,
                                                            "property"
                                                          )}
                                                        >
                                                          <span
                                                            className="plcNm"
                                                            title={
                                                              hotel.description +
                                                              hotel.country
                                                            }
                                                          >
                                                            {" "}
                                                            {hotel.text},{" "}
                                                            {hotel.country}{" "}
                                                          </span>
                                                        </div>
                                                      )
                                                    )}
                                                  </Col>
                                                )}
                                            </Row>
                                          </>
                                        )}
                                      {state.hotelData.length === 0 && (
                                        <div className="typ">
                                          {context.translation && context.translation.noresultFund}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              )}
                            </Form.Item>                   
                            {state.hotelChainCityHotelName === "" && state.prefHotelHotelField && (
                              <div className="error">
                                {context.translation && context.translation.plzperfhotel}
                              </div>
                            )}
                          </Col>
                          {selectedHotels.length < 5 && (                            
                            <Col span={2}>
                            <Button
                              type="primary"
                              className="addMore"
                              onClick={handleAdd}
                            >
                              +
                            </Button>
                          </Col>
                          )}
                        </Row>
                      )}
                      {selectedHotels.length > 0 && (
                        <Table
                          columns={columns}
                          dataSource={selectedHotels}
                          pagination={false}
                        />
                      )}

                      <Modal
                        title={context.translation && context.translation.confirmDel}
                        open={confirmModalVisiblePrefHtls}
                        onOk={handleConfirmDeletePrefHtl}
                        onCancel={handleCancelDeletePrefHtl}
                        okText= {context.translation && context.translation.ok}
                        cancelText= {context.translation && context.translation.cancel}
                      >
                        <p>{context.translation && context.translation.delItem}</p>
                      </Modal>
                    </div>

                    <div className="padding20 preferredHotel twoData">
                      <Title level={5}>{context.translation && context.translation.fvCity}</Title>
                      <Row>
                        <Col span={8}>
                          <Form.Item label="" id="destName">
                            <Input
                              allowClear
                              id="destName"
                              type="text"
                              autoComplete="off"
                              value={state.destName}
                              // className="innerBoxShadow"
                              className={
                                "innerBoxShadow " +
                                (state.destName === "" && state.freqVisCity ? "errorForm" : "")
                              }
                              placeholder={context.translation && context.translation.searchdot}
                              onChange={handleChangOrigin}
                              ref={contentRef}
                            />

                            {state.hotelPredectiveDropDown && (
                              <div
                                className={
                                  state.hotelData !== undefined &&
                                  state.hotelData.length !== 0 &&
                                  state.hotelData[0].city.length !== 0
                                    ? "pdtSerchPart"
                                    : "pdtSerchPart nocity"
                                }
                              >
                                <div>
                                  <div className="searchContent">
                                    {state.hotelData !== undefined &&
                                      state.showResults &&
                                      state.hotelData.length !== 0 && (
                                        <>
                                          {/* {state.isSearching && <p className="searchig">Searching...</p>} */}
                                          {state.hotelData !== undefined &&
                                            state.hotelData[0].city.length ===
                                              0 &&
                                            state.hotelData[0].landmark
                                              .length === 0 &&
                                            state.hotelData[0].area.length ===
                                              0 &&
                                            state.hotelData[0].property
                                              .length === 0 && (
                                              <p className="nomachFound">
                                                {context.translation && context.translation.nomfound}
                                              </p>
                                            )}
                                          <Row>
                                            {state.hotelData[0].city !==
                                              undefined &&
                                              state.hotelData[0].city.length !==
                                                0 && (
                                                <Col className="pr-2">
                                                  {state.hotelData[0].city.map(
                                                    (cty, index) => (
                                                      <div
                                                        key={index}
                                                        className="plc ezyIcon icon-map"
                                                        onClick={handleOnOrigin(
                                                          cty,
                                                          "city"
                                                        )}
                                                      >
                                                        {cty.description !==
                                                          null && (
                                                          <span
                                                            className="plcNm"
                                                            title={
                                                              cty.description
                                                            }
                                                          >
                                                            {" "}
                                                            {cty.description}
                                                          </span>
                                                        )}
                                                        {cty.description ==
                                                          null && (
                                                          <span
                                                            className="plcNm"
                                                            title={
                                                              cty.text +
                                                              cty.country
                                                            }
                                                          >
                                                            {" "}
                                                            {cty.text},{" "}
                                                            {cty.country}
                                                          </span>
                                                        )}
                                                        {/* <span className="plcTyp">City</span> */}
                                                      </div>
                                                    )
                                                  )}
                                                </Col>
                                              )}
                                          </Row>
                                        </>
                                      )}
                                    {state.hotelData.length === 0 && (
                                      <div className="typ">{context.translation && context.translation.noresultFund}</div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            )}
                          </Form.Item>
                          {state.destName === "" && state.freqVisCity && (
                            <div className="error">
                              {/* {context.translation && context.translation.plzaddfvcity} */}
                              {context.translation && context.translation.plzSelectFvc}
                            </div>
                          )}
                        </Col>
                        {cities.length < 5 && (
                        <Col span={2}>
                        <Button
                          type="primary"
                          className="addMore"
                          onClick={handleAddCity}
                        >
                          +
                        </Button>
                        </Col>
                        )}
                      </Row>
                      {cities.length > 0 && (
                        <Table
                          dataSource={cities}
                          columns={CitiesColumns}
                          pagination={false}
                        />
                      )}

                      <Modal
                        title={context.translation && context.translation.confirmDel}
                        open={confirmModalVisibleFreqVisCities}
                        onOk={handleConfirmDeleteFreqVisitedCity}
                        onCancel={handleCancelDeleteFreqVisitedCity}
                        okText= {context.translation && context.translation.ok}
                        cancelText= {context.translation && context.translation.cancel}
                      >
                        <p>{context.translation && context.translation.delItem}</p>
                      </Modal>
                    </div>
                    <div className="padding20 preferredHotel twoData">
                      <Row>
                        <Col span={8}>
                          <Form.Item
                            label={context.translation && context.translation.perfDest}
                            id="prefDestName"
                          >
                            <Input
                              allowClear
                              id="prefDestName"
                              type="text"
                              autoComplete="off"
                              value={state.prefDestName}
                              // className="innerBoxShadow"
                              className={
                                "innerBoxShadow " +
                                (state.prefDestError && state.prefDestName === "" ? "errorForm" : "")
                              }
                              placeholder={context.translation && context.translation.searchdot}
                              onChange={handleChangOriginPD}
                              ref={contentRef}
                            />

                            {state.prefDestPredectiveDropDown && (
                              <div
                                className={
                                  state.hotelData !== undefined &&
                                  state.hotelData.length !== 0 &&
                                  state.hotelData[0].city.length !== 0
                                    ? "pdtSerchPart"
                                    : "pdtSerchPart nocity"
                                }
                              >
                                <div>
                                  <div className="searchContent">
                                    {state.hotelData !== undefined &&
                                      state.showResults &&
                                      state.hotelData.length !== 0 && (
                                        <>
                                          {/* {state.isSearching && <p className="searchig">Searching...</p>} */}
                                          {state.hotelData !== undefined &&
                                            state.hotelData[0].city.length ===
                                              0 &&
                                            state.hotelData[0].landmark
                                              .length === 0 &&
                                            state.hotelData[0].area.length ===
                                              0 &&
                                            state.hotelData[0].property
                                              .length === 0 && (
                                              <p className="nomachFound">
                                                {context.translation && context.translation.nomfound}
                                              </p>
                                            )}
                                          <Row>
                                            {state.hotelData[0].city !==
                                              undefined &&
                                              state.hotelData[0].city.length !==
                                                0 && (
                                                <Col className="pr-2">
                                                  {state.hotelData[0].city.map(
                                                    (cty, index) => (
                                                      <div
                                                        key={index}
                                                        className="plc ezyIcon icon-map"
                                                        onClick={handleOnOriginPD(
                                                          cty,
                                                          "city"
                                                        )}
                                                      >
                                                        {cty.description !==
                                                          null && (
                                                          <span
                                                            className="plcNm"
                                                            title={
                                                              cty.description
                                                            }
                                                          >
                                                            {" "}
                                                            {cty.description}
                                                          </span>
                                                        )}
                                                        {cty.description ==
                                                          null && (
                                                          <span
                                                            className="plcNm"
                                                            title={
                                                              cty.text +
                                                              cty.country
                                                            }
                                                          >
                                                            {" "}
                                                            {cty.text},{" "}
                                                            {cty.country}
                                                          </span>
                                                        )}
                                                        {/* <span className="plcTyp">City</span> */}
                                                      </div>
                                                    )
                                                  )}
                                                </Col>
                                              )}
                                          </Row>
                                        </>
                                      )}
                                    {state.hotelData.length === 0 && (
                                      <div className="typ">{context.translation && context.translation.noresultFund}</div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            )}
                          </Form.Item>
                          {state.prefDestName === "" && state.prefDestError && (
                            <div className="error">
                              {context.translation && context.translation.plzsprefdest}
                            </div>
                          )}
                        </Col>
                        {destinationCities.length < 5 && (
                          <Col span={2}>
                            <Button
                              type="primary"
                              className="addMore preferedDestinationBtn"
                              onClick={handleDestinationAddCity}
                            >
                              +
                            </Button>
                          </Col>
                        )}
                      </Row>
                      {destinationCities.length > 0 && (
                        <Table
                          dataSource={destinationCities}
                          columns={destinationColumns}
                          pagination={false}
                        />
                      )}

                      <Modal
                        title={context.translation && context.translation.confirmDel}
                        open={confirmModalVisiblePrefDest}
                        onOk={handleConfirmDeletePrefDest}
                        onCancel={handleCancelDeletePrefDest}
                        okText= {context.translation && context.translation.ok}
                        cancelText= {context.translation && context.translation.cancel}
                      >
                        <p>{context.translation && context.translation.delItem}</p>
                      </Modal>
                    </div>
                  </Form>
                </div>
                <div className="cardDetailsFooter">
                  <Button
                    type="secondary"
                    className="cancelBtn"
                    ghost
                    onClick={handleResetForm}
                  >
                    {context.translation && context.translation.cancel}
                  </Button>
                  <Button
                    type="primary"
                    className="cntBtn"
                    onClick={handleSavePreferencesCreation}
                  >
                    <span>{context.translation && context.translation.save}</span>
                    {/* <i className="sprite btn-arrow-sprite"></i> */}
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default AddPreferences;
