import React, {
  useEffect,
  useRef,
} from "react";
import Footer from "./Footer";
import Header from "./Header";
import ShowHide from "./showHide/ShowHide";
import StickyBox from "react-sticky-box";
import { Col, Row, Typography } from "antd";

function TermsOfAgreement(props) {
  const tcGeneral = useRef(null);
  const tc1 = useRef(null);
  const tc2 = useRef(null);
  const tc3 = useRef(null);
  const tc4 = useRef(null);
  const tc5 = useRef(null);
  const tc6 = useRef(null);
  const tc7 = useRef(null);
  const tc8 = useRef(null);
  const tc9 = useRef(null);
  const tc10 = useRef(null);
  const executeScrolltop = () =>
    tcGeneral.current.scrollIntoView({ behavior: "smooth" });
  const executeScrolltop1 = () =>
    tc1.current.scrollIntoView({ behavior: "smooth" });
  const executeScrolltop2 = () =>
    tc2.current.scrollIntoView({ behavior: "smooth" });
  const executeScrolltop3 = () =>
    tc3.current.scrollIntoView({ behavior: "smooth" });
  const executeScrolltop4 = () =>
    tc4.current.scrollIntoView({ behavior: "smooth" });
  const executeScrolltop5 = () =>
    tc5.current.scrollIntoView({ behavior: "smooth" });
  const executeScrolltop6 = () =>
    tc6.current.scrollIntoView({ behavior: "smooth" });
  const executeScrolltop7 = () =>
    tc7.current.scrollIntoView({ behavior: "smooth" });
  const executeScrolltop8 = () =>
    tc8.current.scrollIntoView({ behavior: "smooth" });
  const executeScrolltop9 = () =>
    tc9.current.scrollIntoView({ behavior: "smooth" });
  const executeScrolltop10 = () =>
    tc10.current.scrollIntoView({ behavior: "smooth" });
  const general = (e) => {
    executeScrolltop();
  };
  const general1 = (e) => {
    executeScrolltop1();
  };
  const general2 = (e) => {
    executeScrolltop2();
  };
  const general3 = (e) => {
    executeScrolltop3();
  };
  const general4 = (e) => {
    executeScrolltop4();
  };
  const general5 = (e) => {
    executeScrolltop5();
  };
  const general6 = (e) => {
    executeScrolltop6();
  };
  const general7 = (e) => {
    executeScrolltop7();
  };
  const general8 = (e) => {
    executeScrolltop8();
  };
  const general9 = (e) => {
    executeScrolltop9();
  };
  const general10 = (e) => {
    executeScrolltop10();
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  const { Title } = Typography;
  const update=(type)=>{
  }
  return (
    <>
      <Header posType="floating" setTab={update} />
      <div className="container tndC">
        <Title level={4} className="tcTitle">
          Terms of the Agreement
        </Title>
        <Row>
          <Col span={6} className="tcleftSidebar">
            <StickyBox offsetTop={100} offsetBottom={20}>
              <ul variant="pills" className="flex-column">
                {/* Flights */}
              <Title level={4} className="tcTitle">
                Flights
              </Title>
                <li>
                  <span eventKey="general" onClick={general} className="active">
                    Terms of the Airlines
                  </span>
                </li>
                <li>
                  <span eventKey="clientResp" onClick={general1}>
                    Pricing
                  </span>
                </li>
                <li>
                  <span eventKey="disclaimer" onClick={general2}>
                    Travel Documents
                  </span>
                </li>
                <li>
                  <span eventKey="pvcStatement" onClick={general3}>
                    Check-In Terms
                  </span>
                </li>
                <li>
                  <span eventKey="copyright" onClick={general4}>
                    Use of Flight Segments
                  </span>
                </li>
                <li>
                  <span eventKey="agency" onClick={general5}>
                    Changes to Existing Booking
                  </span>
                </li>
                <li>
                  <span eventKey="confidentiality" onClick={general6}>
                    Refund
                  </span>
                </li>
                <li>
                  <span eventKey="indemnity" onClick={general7}>
                    Others
                  </span>
                </li>

                {/* Hotels */}
                <Title level={4} className="tcTitle" style={{marginTop: "2rem"}}>
                  Hotels
                </Title>
                <li>
                  <span eventKey="general" onClick={general8}>
                    Terms & Liabilities of Clickntravel
                  </span>
                </li>
                <li>
                  <span eventKey="clientResp" onClick={general9}>
                    Responsibilities of the User
                  </span>
                </li>
                <li>
                  <span eventKey="disclaimer" onClick={general10}>
                    Mode of Payment
                  </span>
                </li>
              </ul>
            </StickyBox>
          </Col>
          <Col span={18} className="tcLayout">

            {/* Flights  */}
            <Title level={4} className="tcTitle">
              Flights
            </Title>
            <div ref={tcGeneral}>
              <ShowHide visible={false} icon="" title="Terms of the Airlines">
                <div className="showHide-content">
                  <p>
                    The airline tickets available through the Application are
                    subject to the terms & conditions of the concerned airline,
                    including but not limited to cancellation and refund
                    policies.
                  </p>
                  <p>
                    Clickntravel merely acts as a facilitator to enable the User
                    to book a flight ticket. The contract of service for
                    utilization of the flight is always between the User and the
                    concerned airline.
                  </p>
                  <p>
                    Airlines retain the right to reschedule flight times, route,
                    change or cancel flights or itineraries independent of and
                    without prior intimation to Clickntravel. As a facilitator
                    Clickntravel has no control or authority over the logistics
                    of the airlines and therefore is not liable for any loss,
                    direct or incidental, that a User may incur due to such
                    change or cancellation of a flight.
                  </p>
                  <p>
                    The baggage allowance on given fare is as per the terms
                    decided by the airline, and Clickntravel has no role to play
                    in the same. Some of the fares shown in the Application are
                    excluding “hand baggage” which do not entitle the User for
                    free check in baggage and therefore the User will be
                    required to pay separately for check in baggage. The prices
                    for adding check-in baggage to a booking may vary from
                    airline to airline. The User is advised to contact the
                    airlines for detailed costs.
                  </p>
                </div>
              </ShowHide>
            </div>
            <div ref={tc1}>
              <ShowHide visible="true" icon="" title="Pricing">
                <div className="showHide-content">
                  <p>
                    The total price displayed on the Application on the payment
                    page usually includes base fare, applicable government taxes
                    and convenience fee. Users are required to pay the entire
                    amount prior to the confirmation of their booking(s). In the
                    event the User does not pay the entire amount, Clickntravel
                    reserves its right to cancel the booking. User agrees to pay
                    all taxes, surcharges and fees, as applicable on the date of
                    travel.
                  </p>

                  <p>
                    To avail infant fares, the age of the child must be under 24
                    months throughout the entire itinerary. This includes both
                    onward and return journeys. If the infant is 24 months or
                    above on the return journey, User will be required to make a
                    separate booking using a child fare. Any infants or children
                    must be accompanied by an adult as per the terms of the
                    airlines.
                  </p>
                </div>
              </ShowHide>
            </div>
            <div ref={tc2}>
              <ShowHide visible="true" icon="" title="Travel Documents">
                <div className="showHide-content">
                  <p>
                    It shall be the sole responsibility of the User to ensure
                    they are in possession of valid travel documents such as
                    identity proof, passport, visa (including transit visa) etc.
                    to undertake the travel. User agrees that in case of
                    inability to travel for not carrying valid travel documents,
                    Clickntravel shall in no way be held liable.
                  </p>

                  <p>
                    User understands that the information (if any) provided by
                    Clickntravel regarding the travel documents is only advisory
                    in nature and can’t be considered conclusive. The User shall
                    ensure checking the requirements of travel with the
                    respective airlines of the respective jurisdictions the User
                    may transit through or choose to visit.
                  </p>
                </div>
              </ShowHide>
            </div>
            <div ref={tc3}>
              <ShowHide visible="true" icon="" title="Check-In Terms">
                <div className="showHide-content">
                  <p>
                    User should check with the airlines directly regarding the
                    check-in timings. Usually, check-in begins 3 hours before
                    departure for international flights.
                  </p>
                  <p>
                    User should carry valid identification proof, passport,
                    Vaccination certificate, Negative RT-PCR report as may be
                    required to prove the identity, nationality, and readiness
                    of the passengers travelling on a ticket, including infants.
                  </p>
                </div>
              </ShowHide>
            </div>
            <div ref={tc4}>
              <ShowHide visible="true" icon="" title="Use of Flight Segments">
                <div className="showHide-content">
                  <p>
                    In the event User does not embark on the onward journey, the
                    entire PNR pertaining to that booking shall be automatically
                    cancelled by the airline. In such a scenario Clickntravel
                    has no control in the said process nor will be obligated to
                    provide alternate bookings to the User. The cancellation and
                    No-show penalties in such an event shall be as per the
                    applicable airline rules.
                  </p>
                </div>
              </ShowHide>
            </div>
            <div ref={tc5}>
              <ShowHide
                visible="true"
                icon=""
                title="Changes to Existing Booking"
              >
                <div className="showHide-content">
                  <p>
                    Any changes that are made to any existing booking shall be
                    subject to certain charges levied by the respective airline,
                    apart from the service fee charged by Clickntravel.
                  </p>
                  <p>
                    The User shall be obligated to pay applicable charges in the
                    event of any alteration or modification to an existing
                    booking. However, depending on the airline's policy and fare
                    class, charges for changes or modifications to existing
                    bookings may vary.
                  </p>
                </div>
              </ShowHide>
            </div>
            <div ref={tc6}>
              <ShowHide visible="true" icon="" title="Refund">
                <div className="showHide-content">
                  <p>
                    Refunds will be processed as per the airline fare rules and
                    cancellation policy. Such refunds shall be subject to
                    Clickntravel receiving the same from the airlines. However,
                    the convenience fee paid to Clickntravel paid at the time of
                    booking is a non-refundable fee.
                  </p>
                  <p>
                    All cancellations made directly with the airline need to be
                    intimated to Clickntravel, in order to initiate the process
                    of refund. The processing time for refunds may vary
                    depending on the mode of payment and release by partner
                    bank. The refund shall be processed after deducting the
                    Clickntravel service fee which is independent of the
                    convenience fee as mentioned above.{" "}
                  </p>
                  <p>
                    The refund will be credited to the same account from which
                    the payment was made. For example, if the User used a credit
                    card, Clickntravel will make an appropriate charge reversal
                    to the same credit card; like-wise if the User used a debit
                    card, Clickntravel will credit the money to the same debit
                    card.
                  </p>
                  <p>
                    In the event of cancellation and refund of partially
                    utilized tickets, upfront discount and promo code discount
                    availed at the time of booking would be deducted from the
                    refund amount.
                  </p>
                </div>
              </ShowHide>
            </div>
            <div ref={tc7}>
              <ShowHide visible="true" icon="" title="Others">
                <div className="showHide-content">
                  <p>
                    Clickntravel shall not be liable or responsible for any
                    damage or loss, injury, accident, death, irregularity,
                    delay/change in schedule, cancellation without cause,
                    inaccurate information, deficiency of service/product, or
                    cancellation due to conditions not under it’s control.
                  </p>
                </div>
              </ShowHide>
            </div>

          {/* Hotels */}
          <Title level={4} className="tcTitle">
            Hotels
          </Title>
          <div ref={tc8}>
            <ShowHide
              visible="true"
              icon=""
              title="Terms & Liabilities of Clickntravel"
            >
              <div className="showHide-content">
                <p>
                  Clickntravel acts as a facilitator and merely provides an
                  online platform to the User to select and book a particular
                  hotel. Hotels in this context includes all categories of
                  accommodations such as hotels, homestays, bed and breakfast
                  stays and any other alternate accommodations.
                </p>
                <p>
                  All the information pertaining to the hotel including the
                  category of the hotel, images, room type, amenities and
                  facilities available at the hotel are as per the information
                  provided by the hotel/Partner Agency to Clickntravel. This
                  information is for reference only. Any discrepancy that may
                  exist between the Application pictures and actual settings
                  of the hotel shall be raised by the User with the hotel
                  directly and shall be resolved between the User and hotel.
                  Clickntravel will have no responsibility in that process of
                  resolution and shall not take any liability for such
                  discrepancies.
                </p>
                <p>
                  The hotel booking voucher which Clickntravel issues to a
                  User is solely based on the information provided or updated
                  by the hotel/Agency partner regarding the inventory
                  availability. In no circumstances can Clickntravel be held
                  liable for failure on part of a hotel to accommodate the
                  User with a confirmed booking, the standard of service or
                  any insufficiency in the services, or any other
                  service-related issues at the hotel. The liability of
                  Clickntravel in case of denial of check-in by a hotel for
                  any reason what-so-ever including over-booking, system or
                  technical errors, or unavailability of rooms etc., will be
                  limited to either providing a similar alternate
                  accommodation at the discretion of Clickntravel (subject to
                  availability at that time), or refunding the booking amount
                  (to the extent paid) to the User. Any other service-related
                  issues should be directly resolved by the User with the
                  hotel.
                </p>
                <p>
                  Hotels reserves the sole right of admission and Clickntravel
                  has no say whatsoever in admission or denial for admission
                  by the hotel. Unmarried or unrelated couples may not be
                  allowed to check-in by some hotels as per their policies.
                  Similarly, accommodation may be denied to guests posing as a
                  couple if suitable proof of identification is not presented
                  at the time check-in. Clickntravel will not be responsible
                  for any check-in denied by the hotel due to the aforesaid
                  reasons or any other reason not under the control of
                  Clickntravel. No refund would be applicable in case the
                  hotel denies check-in under such circumstances.
                </p>
                <p>
                  Clickntravel shall not be liable or responsible for any
                  damage or loss, injury, accident, death, irregularity,
                  delay/change in schedule, cancellation without cause,
                  inaccurate information, deficiency of service/product, or
                  cancellation due to conditions not under it’s control.
                </p>
              </div>
            </ShowHide>
          </div>
          <div ref={tc9}>
            <ShowHide
              visible="true"
              icon=""
              title="Responsibilities of the User"
            >
              <div className="showHide-content">
                <p>
                  The User would be liable to make good any damage(s) caused
                  by any act of him/ her/ or their accompanying guests
                  (willful/negligent) to the property of the hotel in any
                  manner whatsoever. The extent and the amount of the damage
                  so caused would be determined by the concerned hotel.
                  Clickntravel would not, in any way, intervene in the same.
                </p>
                <p>
                  The primary guest must be at least 18 years old to be able
                  to check into the hotel.
                </p>
                <p>
                  The User has to be in possession of a valid identity proof
                  and address proof, at the time of check-in. The hotel shall
                  be within its rights to deny check-in to a User if a valid
                  identity proof is not presented at the time of check-in.
                </p>
                <p>
                  Check-in time, check-out time, and any changes in those
                  timings, will be as per hotel policy & terms. Early check-in
                  or late check-out request is subject to availability and the
                  hotel may charge an additional fee for providing such
                  services.
                </p>
                <p>
                  The booking amount paid by the User is only for stay at the
                  hotel. Some bookings may include breakfast and/ or meals as
                  confirmed at the time of booking. Any other services
                  utilized by the User at the hotel, including laundry, room
                  service, internet, telephone, extra food, drinks, beverages
                  etc. shall be paid by the User directly to the hotel.
                </p>
                <p>
                  Any additional charges need to be cleared directly at the
                  hotel. Clickntravel will have no control over waiving the
                  same.
                </p>
              </div>
            </ShowHide>
          </div>
          <div ref={tc10}>
            <ShowHide visible="true" icon="" title="Mode of Payment">
              <div className="showHide-content">
                <p>
                  Booking of a hotel can be “Prepaid” as per the option made
                  available by a hotel on the Application of Clickntravel.
                </p>
                <p>
                  In “Prepaid” model, the total booking amount is paid by the
                  User at the time of booking itself. Such total booking
                  amount includes the hotel reservation rate, taxes, service
                  fees as may be charged on behalf of the actual service
                  provider, and any additional booking fee or convenience fee
                  charged by Clickntravel. Sometimes additional taxes laid
                  down by the government may be required to pay at the
                  property.
                </p>
                <p>
                  Clickntravel is not responsible for any errors, omissions or
                  representations on any of its pages, links or any linked
                  Application pages to the extent such information is updated
                  or provided directly by the Service Providers or the
                  advertisers.
                </p>
              </div>
            </ShowHide>
          </div>
          </Col>
        </Row>
      </div>
      <Footer />
    </>
  );
}

export default TermsOfAgreement;
