import React from "react";
import { useContext } from "react";
import { Context } from "../../../../App";

function HotelRoomHeader(props) {
  const [context, setContext] = useContext(Context)
  return (
    <div className="hotelFilterHeader">
          <div className="roomOccupancy">
            <span className="room">{context.translation && context.translation.room} {props.rmno} : </span>
            <span className="occupancy">
              {context.translation && context.translation.occupancy} <span className="adultChildSeparator"> {props.ocp.adt} {props.ocp.adt > 1 ? context.translation && context.translation.adult : context.translation && context.translation.adts}</span>{" "}
              {props.ocp.chd !== 0 && (
              <span className="adultChildSeparator"> {props.ocp.chd !== 0 && props.ocp.chd + (context.translation && context.translation.chd)}</span>
              )}
            </span>
          </div>
          <div className="boardBasis">{context.translation && context.translation.boardBasis}</div>
          <div className="hotelPrice">{context.translation && context.translation.price}</div>
          <div className="hotelSelect">{context.translation && context.translation.select}</div>
    </div>
  );
}

export default HotelRoomHeader;
