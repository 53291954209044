/**
 * @ Author: Srikanth Bandaru
 * @ Create Time: 2023-06-15 09:47:01
 * @ Description: The main component of the Flight Search Flow
 */

import { Col, Row, Typography,Modal } from "antd";
import React, { useCallback, useEffect, useReducer, useState, useContext } from "react";
import Link from "antd/es/typography/Link";
import CommonFlightSearch from "./CommonFlightSearch";
import MultiCitySearch from "./MultiCitySearch";
import AddTraveller from "./addTraveller";
import { Context } from "../../../../App";
import Slider from 'react-slick';
import Umbrella from "../../../../assets/img/umb2.png";
import CntCommonService from "../../../../services/common/CntCommonService";
import PromoCodePopup from "../../../common/PromoCodePopup";
import Utilities from "../../../utilities/Utilities";

let promos = require("../common/masterData/Test.json");
// let airlineMaster = require("../common/masterData/AirlinesMasterData.json");
const { Title } = Typography;

const initialState = {
  airportsOW: [],
  selOrigin: [],
  airportsRT: [],
  selDestination: [],
  adt: 1,
  chd: 0,
  inf: 0,
  cls: "Economy",
  paxCount: 1,
  selAir: [],
  tt: 2,
};

//=====================================
// Reducer function for userReact Hook
// which will update the state
//=====================================
const reducer = (state, action) => {
  switch (action.type) {
    case "clear":
      return {
        ...state,
        airportsOW: [],
        selOrigin: [],
        airportsRT: [],
        selDestination: [],
        adt: 1,
        chd: 0,
        inf: 0,
      };
    default:
      return { ...state, [action.type]: action.payload };
  }
};

function FlightSearch(props) {
  const [context, setContext] = useContext(Context);
  const [cls, setCls] = useState((context.selectLang === "en" || context.selectLang === undefined) ? "Economy" : "اقتصاد")
  const [state, dispatch] = useReducer(reducer, initialState);
  const [source, setSource] = useState([]);
  const [destination, setDestination] = useState([]);
  const [showTravellers, setShowTravellers] = useState(false);
  const [promoCodes, setPromoCodes] = useState([]);
  const [promoObj, setPromoObj] = useState("");
  const [enablePromo, setEnablePromo] = useState(false);
  const [tablist, setTablist] = useState({
    // active: props && props.activeTab ? props.activeTab : "RoundTrip",
    active: props && props.activeTab ? props.activeTab : (context.selectLang === "en" || context.selectLang === undefined) ? "RoundTrip" : context.translation && context.translation.roundTripTitle,
  });
  const [showTripType, setShowTripType] = useState(false);
  const [modify, setModify] = useState(
    props && props.modify ? props.modify : false
  );

  useEffect(() => {
    // setCls(context.translation && context.translation.economy)
    setCls((sessionStorage.getItem("SEL_LAG") === "English" || sessionStorage.getItem("SEL_LAG") === null) ? "Economy" : "اقتصاد");
  }, [context.translation])

  useEffect(() => {
    // setTablist({ active: (context.selectLang === "en" || context.selectLang === undefined) ? "RoundTrip" : context.translation && context.translation.roundTripTitle })
    setTablist({ active: (sessionStorage.getItem("SEL_LAG") === "English" || sessionStorage.getItem("SEL_LAG") === null) ? "RoundTrip" : "ذهابوعودة" })
  }, [context.translation])

  useEffect(() => {
    if(showTripType)
    setShowTravellers(false);
  }, [showTripType]);

  useEffect(() => {
    if(showTravellers)
    setShowTripType(false);
  }, [showTravellers]);

  useEffect(() => {
    setPromoCodes([]);
    if(!enablePromo){
      if(localStorage.getItem("REGION_COUNTRY")!==null && localStorage.getItem("REGION_COUNTRY")!==undefined) {
        getFlightPromo();
      }
    }
  }, [localStorage.getItem("REGION_COUNTRY")]);

  const getFlightPromo=()=>{
    var region=localStorage.getItem("REGION_COUNTRY")!==null && localStorage.getItem("REGION_COUNTRY")==="IN"?"India":"Qatar";
    const request={
       "region_type":region,
       "sales_chnnel":"B2C",
       "product_type":"Flight"
    }
    try {
      if(promoCodes.length===0){
        let tokenKey = Utilities.generateAPIToken(request);
        CntCommonService.getPromoCodeList(request,tokenKey).then((response) => {
          if (response.data !== undefined && response.data.suc) {
            setPromoCodes(response.data.res);
          }
        }).catch(function (error) {
          console.error("Error in getFlightPromo" + error);      
        });
      }
    } catch (err) {
      console.error("Error in getFlightPromo" + err);
    }
  }

  const getPromoDetails=(obj)=>{
    try {
      if(obj.promo_id!==undefined){
        const req={
          "promo_id":obj.promo_id,
          "product_type": "Flight"
        }
        let tokenKey = Utilities.generateAPIToken(req);
        CntCommonService.getProDetails(req,tokenKey).then((response) => {
        if (response.data !== undefined && response.data.suc) {
            setEnablePromo(true)
            setPromoObj(response.data.res)
           }
        }).catch(function (error) {
          console.error("Error in getFlightPromo" + error);      
        });
      }
    } catch (err) {
      console.error("Error in getFlightPromo" + err);
    }
  }

  const closePromo=()=>{
    setEnablePromo(false)
  }

  const [blur, setBlur] = useState(false);
  useEffect(() => {
    setTimeout(function () {
      document.querySelector(".homeSearch .trips-block") &&
        document.querySelector(".trips-block").classList.add("tripFocus");
    }, 4000);

    setTimeout(function () {
      document.querySelector(".homeSearch .traveller-section") &&
        document.querySelector(".traveller-section").classList.add("tripFocus");
    }, 5000);

    setTimeout(function () {
      document.querySelector(".homeSearch .dest-from") &&
        document.querySelector(".dest-from").classList.add("destFocus");
    }, 6000);

    setTimeout(function () {
      document.querySelector(".homeSearch .dest-to") &&
        document.querySelector(".dest-to").classList.add("destFocus");
    }, 7000);
    setTimeout(function () {
      document.querySelector(
        ".homeSearch .dpicker .ant-picker > :nth-child(1)"
      ) &&
        document
          .querySelector(".dpicker .ant-picker > :nth-child(1)")
          .classList.add("dpFocus");
    }, 8000);
    setTimeout(function () {
      document.querySelector(
        ".homeSearch .dpicker .ant-picker > :nth-child(3)"
      ) &&
        document
          .querySelector(".dpicker .ant-picker > :nth-child(3)")
          .classList.add("dpFocus");
    }, 9000);
    setTimeout(function () {
      document.querySelector(".homeSearch .searchPanel .cntBtn") &&
        document
          .querySelector(".searchPanel .cntBtn")
          .classList.add("btnFocus");
    }, 10000);
    appendDpBorder();

    if (props.data) {
      if (props.activeTab) {
        handleTripType(props.activeTab);
      }
      if (props.data.adt) {
        dispatch({ type: "adt", payload: parseInt(props.data.adt) });
      }
      if (props.data.chd) {
        dispatch({ type: "chd", payload: parseInt(props.data.chd) });
      }
      if (props.data.inf) {
        dispatch({ type: "inf", payload: parseInt(props.data.inf) });
      }
      if (props.data.paxCount) {
        dispatch({ type: "paxCount", payload: props.data.paxCount });
      }
      if (props.data.cls) {
        if (props.data.cls instanceof Object) {
          // dispatch({ type: "cls", payload: props.data.cls[0].label });
          setCls(props.data.cls[0].label);
        } else {
          dispatch({ type: "cls", payload: setClsTyp(props.data.cls) });
          // dispatch({ type: "cls", payload: props.data.cls });
          setCls(setClsTyp(props.data.cls));
        }
      }
    }
    window.scrollTo(0, 0);
  }, []);

  const setClsTyp = (selcls) => {
    let cl = {};
    if (selcls && selcls.length > 0)
      if (selcls === "E") {
        cl = (context.selectLang === "en" || context.selectLang === undefined) ? "Economy" : "اقتصاد";
      } else if (selcls === "P") {
        cl = (context.selectLang === "en" || context.selectLang === undefined) ? "Premium Economy" : "السياحية المتميزة";
      } else if (selcls === "B") {
        cl = (context.selectLang === "en" || context.selectLang === undefined) ? "Business" : "رجال الأعمال";
      } else if (selcls == "F") {
        cl = (context.selectLang === "en" || context.selectLang === undefined) ? "First class" : "الصف الأول";
      }
      return cl;
  };

  const appendDpBorder = () => {
    if (
      document.querySelector(".dpicker .ant-picker > .ant-picker-input > input")
    ) {
      var dpInput = document.querySelectorAll(
          ".dpicker .ant-picker > .ant-picker-input > input"
        ),
        i;
      for (i = 0; i < dpInput.length; ++i) {
        dpInput[i].classList.add("effect-9");
        dpInput[i].insertAdjacentHTML(
          "afterend",
          "<span className='focus-border'><i></i></span>"
        );
      }
    }
  };

  const showHideTrav = () => {
    setShowTravellers(!showTravellers);

  };
  //=====================================
  // This function will get the Pax count and Total count for Add traveller
  //=====================================
  const paxCount = useCallback((data) => {
    dispatch({ type: "adt", payload: data[0] });
    dispatch({ type: "chd", payload: data[1] });
    dispatch({ type: "inf", payload: data[2] });
    dispatch({ type: "paxCount", payload: data[3] });
  }, []);

  //=====================================
  // This function will get Class
  //=====================================
  const clsType = useCallback((type) => {
    if (type instanceof Object) {
      // dispatch({ type: "cls", payload: type[0].label });
      setCls(type[0].label);
    } else {
      // dispatch({ type: "cls", payload: type });
      setCls(type);
    }
  }, []);

  //=================================================
  // This function will close Travel details pop up
  //=================================================
  const closeTravel = () => {
    showHideTrav();
  };

  const srcObj = (obj) => {
    setSource(obj);
  };

  const destObj = (obj) => {
    setDestination(obj);
  };

  const srcMultiObj = (obj) => {
    setSource(obj);
  };

  const destMultiObj = (obj) => {
    setDestination(obj);
  };

  const retValue = useCallback((type) => {
    if (type === "Round") {
      // setTablist({ active: "RoundTrip" });
      // setTablist({ active: context.translation && context.translation.roundTripTitle });
      setTablist({ active: context.selectLang == undefined || context.selectLang === "en" ? "RoundTrip" : "ذهابوعودة" });
      setTimeout(function () {
        appendDpBorder();
      }, 500);
    }
  }, []);

  

  const content = {
    RoundTrip: (
      <>
        {
          <CommonFlightSearch
            sendReturn={retValue}
            tt={2}
            togglett={setShowTripType}
            toggleTravellers={setShowTravellers}
            // sendCount={[state.adt, state.chd, state.inf, state.cls]}
            sendCount={[state.adt, state.chd, state.inf, cls]}
            modify={modify}
            request={props.request}
            data={!props.multiModifySearch?props.data:null}
            selTrip={tablist.active}
            showTripType={showTripType}
            showTravellers={showTravellers}
            scrollDown={props.scrollDown}
          />
        }
      </>
    ),
    ذهابوعودة: (
      <>
        {
          <CommonFlightSearch
            sendSrc={srcObj}
            sendDest={destObj}
            srcMultiData={source}
            destMultiData={destination}
            sendReturn={retValue}
            tt={2}
            togglett={setShowTripType}
            toggleTravellers={setShowTravellers}
            sendCount={[state.adt, state.chd, state.inf, cls]}
            modify={modify}
            request={props.data}
            data={!props.multiModifySearch?props.data:null}
            selTrip={tablist.active}
            showTripType={showTripType}
            showTravellers={showTravellers}
            scrollDown={props.scrollDown}
          />
        }
      </>
    ),
    OneWay: (
      <>
        {
          <CommonFlightSearch
            sendReturn={retValue}
            tt={1}
            togglett={setShowTripType}
            toggleTravellers={setShowTravellers}
            // sendCount={[state.adt, state.chd, state.inf, state.cls]}
            sendCount={[state.adt, state.chd, state.inf, cls]}
            modify={modify}
            request={props.request}
            data={!props.multiModifySearch?props.data:null}
            selTrip={tablist.active}
            showTripType={showTripType}
            showTravellers={showTravellers}
            scrollDown={props.scrollDown}
          />
        }
      </>
    ),
    ذهابفقط: (
      <>
        {
          <CommonFlightSearch
            sendSrc={srcObj}
            sendDest={destObj}
            srcMultiData={source}
            destMultiData={destination}
            sendReturn={retValue}
            tt={1}
            togglett={setShowTripType}
            toggleTravellers={setShowTravellers}
            sendCount={[state.adt, state.chd, state.inf, cls]}
            modify={modify}
            request={props.data}
            data={!props.multiModifySearch?props.data:null}
            selTrip={tablist.active}
            showTripType={showTripType}
            showTravellers={showTravellers}
            scrollDown={props.scrollDown}
          />
        }
      </>
    ),
    MultiCity: (
      <>
        {
          <MultiCitySearch
            sendMultiSrc={srcMultiObj}
            sendMultiDest={destMultiObj}
            srcData={source}
            destData={destination}
            tt={3}
            blur={blur}
            togglett={setShowTripType}
            toggleTravellers={setShowTravellers}
            // sendCount={[state.adt, state.chd, state.inf, state.cls]}
            sendCount={[state.adt, state.chd, state.inf, cls]}
            modify={modify}
            request={props.request}
            data={props.multiModifySearch?props.data:null}
            selTrip={tablist.active}
            showPanel={props.multiModifySearch}
          />
        }
      </>
    ),
    مدنمتعددة: (
      <>
        {
          <MultiCitySearch
            sendMultiSrc={srcMultiObj}
            sendMultiDest={destMultiObj}
            srcData={source}
            destData={destination}
            tt={3}
            blur={blur}
            togglett={setShowTripType}
            toggleTravellers={setShowTravellers}
            // sendCount={[state.adt, state.chd, state.inf, state.cls]}
            sendCount={[state.adt, state.chd, state.inf, cls]}
            modify={modify}
            request={props.data}
            data={props.multiModifySearch?props.data:null}
            selTrip={tablist.active}
            showPanel={props.multiModifySearch}
          />
        }
      </>
    ),
  };

  const handleTripType = (tripType) => {
    if(tripType==="RoundTrip"){
      setTablist({ active: tripType });
    }else if(tripType==="ذهابوعودة" && sessionStorage.getItem("SEL_LAG")==="Arabic"){
      setTablist({ active: "ذهابوعودة" });
    }

    if(tripType==="OneWay"){
      setTablist({ active: tripType });
    }else if(tripType==="ذهابفقط" && sessionStorage.getItem("SEL_LAG")==="Arabic"){
      setTablist({ active: "ذهابفقط" });
    }

    if(tripType==="MultiCity"){
      setTablist({ active: tripType });
    }else if(tripType==="مدنمتعددة" && sessionStorage.getItem("SEL_LAG")==="Arabic"){
      setTablist({ active: "مدنمتعددة" });
    }
    
    setShowTripType(false);
    setTimeout(function () {
      appendDpBorder();
    }, 500);
  };
  var offers;
  let flow_lng=sessionStorage.getItem("SEL_LAG");
  if (flow_lng && flow_lng === "Arabic") {
    offers = {
      infinite: true,
      speed: 1000,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      rtl: true,
      nextArrow: false,
      prevArrow: false,
      dots: false,
    }
  } else {
      offers = {
      infinite: true,
      speed: 1000,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      rtl: false,
      nextArrow: false,
      prevArrow: false,
      dots: false,
    }
  }
  

  return (
    <>
      <div className="container">
        <div className="offerSliderWrap">
      {promoCodes.length!==0 &&
        <div className="offerSlider">
          <Slider {...offers} className="cntSlider cntHomepage">
              {promoCodes.map((promo, index) => {
                return (
                  <div key={index}>
                    <Title level={2} className="offerTitle">
                      <span>{context.selectLang == undefined || context.selectLang === "en" ? promo.offer_desc_en : promo.offer_desc_ar}</span>
                      <img src={Umbrella} alt="umbImage" className="umbImage"/>
                    </Title>
                    <div className="offerDesc">
                      <span className="offerDescCont">{context.selectLang == undefined || context.selectLang === "en" ? promo.offer_nm : promo.offer_nm_ar}</span>
                      <span className="pipe"></span>
                      <span>{context.translation && context.translation.code}</span> 
                      <span className="offerCode">&nbsp;{promo.pro_code}</span> 
                      <span>&nbsp;<Link onClick={()=>getPromoDetails(promo)} className="offerDetails">{context.translation && context.translation.details}</Link></span></div>
                  </div> 
                  )
              })}
          </Slider>
        </div>
        }
        </div>
          <div className="searchPanel">
            <Row className={"select-trips " + (props.scrollDown ? "" : "dNone")}>
              <Col
                className={"trips-block " + (showTripType && "active")}
                onClick={(t) => setShowTripType(!showTripType)}
              >
                <div className={"trips-title " + (showTripType && "active")}>
                  <i
                    className={
                      "icon " +
                      (((tablist.active === "RoundTrip" || tablist.active === "ذهابوعودة") && 
                        "icon-ico-roundtrip") ||
                        ((tablist.active === "OneWay" || tablist.active === "ذهابفقط") && "icon-ico-oneway") ||
                        ((tablist.active === "MultiCity" || tablist.active === "مدنمتعددة") &&
                          "icon-ico-multicity"))
                    }
                  ></i>
                  {/* <i
                    className={
                      "icon" +
                      ((tablist.active === context.translation && context.translation.roundTripTitle &&
                        "icon-ico-roundtrip") ||
                        (tablist.active === context.translation && context.translation.oneWayTitle && "icon-ico-oneway") ||
                        (tablist.active === context.translation && context.translation.multiCityTitle &&
                          "icon-ico-multicity"))
                    }
                  ></i> */}
                  <span>
                    {/* <label>{tablist.active}</label> */}
<label>{tablist.active === "ذهابوعودة" ? context.translation && context.translation.roundTrip : tablist.active === "ذهابفقط" ? context.translation && context.translation.oneWay : tablist.active === "مدنمتعددة" ? context.translation && context.translation.multiCity : tablist.active}</label>
                  </span>
                </div>
                <ul className={"trips-cont " + (showTripType && "active")}>
                  {showTripType && (
                    <>
                      <li
                        className="list-item"
                        // onClick={(t) => handleTripType("RoundTrip")}
                        onClick={(t) => handleTripType(context.translation && context.translation.roundTripTitle)}
                      >
                        {/* Round Trip */}
                        <i className="icon icon-ico-roundtrip"></i>
                        {context.translation && context.translation.roundTrip}    
                      </li>
                      <li
                        className="list-item"
                        // onClick={(t) => handleTripType("OneWay")}
                        onClick={(t) => handleTripType(context.translation && context.translation.oneWayTitle)}
                      >
                        {/* One Way */}
                        <i className="icon icon-ico-oneway"></i>
                        {context.translation && context.translation.oneWay}    
                      </li>
                      <li
                        className="list-item"
                        // onClick={(t) => handleTripType("MultiCity")}
                        onClick={(t) => handleTripType(context.translation && context.translation.multiCityTitle)}
                      >
                        {/* Multi City */}
                        <i className="icon icon-ico-multicity"></i>
                        {context.translation && context.translation.multiCity}    
                      </li>
                    </>
                  )}
                </ul>
              </Col>
              <Col
                className={"traveller-section " + (showTravellers && "active")}
                
              >
                <div
                  onClick={showHideTrav}
                  className={"traveller-title " + (showTravellers && "active")}
                >
                  {" "}
                  <i className="icon-ico-add-travellers1"></i>{" "}
                  <span>
                    <label>
                      {/* {state.paxCount + " Traveller, " + state.cls}{" "} */}
                      {state.paxCount} {context.translation && context.translation.traveller}, {cls}
                    </label>
                  </span>{" "}
                </div>

                <div
                  className={"traveller-cont " + (showTravellers && "active")}
                >
                  {" "}
                  <AddTraveller
                    sendData={paxCount}
                    sendClass={clsType}
                    closetraveller={closeTravel}
                    // sendCount={[state.adt, state.chd, state.inf, state.cls]}
                    sendCount={[state.adt, state.chd, state.inf, cls]}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col span={24} className={tablist.active}>
                {content[tablist.active]}
              </Col>
            </Row>
          </div>
          {/* {tablist.active !== "MultiCity" && (
            <div className="originDestinationBox">
              <div className="recentSearches">
                <span>Recent Searches : &nbsp;</span>
              </div>
              <div className="originDestination">
               
                <span>{context.translation && context.translation.doha}</span>
                <i className="icon icon-next-long-arrow-right-svgrepo-com"></i>
                <span>{context.translation && context.translation.london}</span>
              </div>
              <div className="originDestination">
               
                <span>{context.translation && context.translation.doha}</span>
                <i className="icon icon-ico-roundtrip"></i>
                <span>{context.translation && context.translation.dubai}</span>
              </div>
              <div className="originDestination">
               
                <span>{context.translation && context.translation.doha}</span>
                <i className="icon icon-next-long-arrow-right-svgrepo-com"></i>
                <span>{context.translation && context.translation.riyadh}</span>
              </div>
            </div>
          )} */}
        </div>
       <Modal title="" centered open={enablePromo} footer={null} className="promoCodePopup">
        {promoObj!=="" && 
         <PromoCodePopup promoObj={promoObj} product={"Flight"} closePopup={closePromo}/>
         }
      </Modal>
    </>
  );
}

export default FlightSearch;
