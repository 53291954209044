/**
 * @ Author: Srikanth Bandaru
 * @ Create Time: 2023-06-15 09:47:01
 * @ Description: The component used in the flight details popup's fare rules tab
 */

import React, { useEffect, useState } from "react";
import AirportMemo from "../common/airlines/AirportNames";
import DateUtils from "../../../utilities/DateUtils";

function FlightFareOptionHeader(props) {
  const { mapping, sectors, secType } = props;
  const [displayObj, setDisplayObj] = useState({ fd: "", da: "", aa: "" });

  useEffect(() => {
    if (secType === "onward") {
      if (sectors.length > 0) {
        setDisplayObj({
          fd: sectors[0].dd,
          da: sectors[0].src,
          aa: sectors[0].des,
        });
      }
    } else if (secType === "arrival") {
      if (sectors.length > 0) {
        setDisplayObj({
          fd: sectors[0].ad,
          da: sectors[0].des,
          aa: sectors[0].src,
        });
      }
    }
  }, [secType, sectors]);
  return (
    <>
      {displayObj.da && (
        <div className="onlyFlex">
          <span className="smallFlight">
            <i className="icon icon-flight"></i>
          </span>
          <span>
            <AirportMemo
              field="ResultSectionHeader"
              code={displayObj.da}
              mapping={mapping}
            />
          </span>
          <span className="rightArrow">
            <i className="icon icon-next-long-arrow-right-svgrepo-com"></i>
          </span>
          <span>
            <AirportMemo
              field="ResultSectionHeader"
              code={displayObj.aa}
              mapping={mapping}
            />
          </span>
        </div>
      )}
    </>
  );
}

export default FlightFareOptionHeader;
