import React, {
  useEffect,
  useRef,
} from "react";
import Footer from "./Footer";
import Header from "./Header";
import ShowHide from "./showHide/ShowHide";
import StickyBox from "react-sticky-box";
import { Col, Row, Typography } from "antd";

function HotelTermsAndConditions(props) {
  const tcGeneral = useRef(null);
  const tc1 = useRef(null);
  const tc2 = useRef(null);
  const executeScrolltop = () =>
    tcGeneral.current.scrollIntoView({ behavior: "smooth" });
  const executeScrolltop1 = () =>
    tc1.current.scrollIntoView({ behavior: "smooth" });
  const executeScrolltop2 = () =>
    tc2.current.scrollIntoView({ behavior: "smooth" });
  const general = (e) => {
    executeScrolltop();
  };
  const general1 = (e) => {
    executeScrolltop1();
  };
  const general2 = (e) => {
    executeScrolltop2();
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  const { Title } = Typography;
  const update=(type)=>{
  }
  return (
    <>
      <Header posType="floating" setTab={update} />
      <div className="container tndC">
        <Title level={4} className="tcTitle">
          Hotels Terms and Condtions
        </Title>
        <Row>
          <Col span={6} className="tcleftSidebar">
            <StickyBox offsetTop={100} offsetBottom={20}>
              <ul variant="pills" className="flex-column">
                <li>
                  <span eventKey="general" onClick={general} className="active">
                    Terms & Liabilities of Clickntravel
                  </span>
                </li>
                <li>
                  <span eventKey="clientResp" onClick={general1}>
                    Responsibilities of the User
                  </span>
                </li>
                <li>
                  <span eventKey="disclaimer" onClick={general2}>
                    Mode of Payment
                  </span>
                </li>
              </ul>
            </StickyBox>
          </Col>
          <Col span={18} className="tcLayout">
            <div ref={tcGeneral}>
              <ShowHide
                visible={false}
                icon=""
                title="Terms & Liabilities of Clickntravel"
              >
                <div className="showHide-content">
                  <p>
                    Clickntravel acts as a facilitator and merely provides an
                    online platform to the User to select and book a particular
                    hotel. Hotels in this context includes all categories of
                    accommodations such as hotels, homestays, bed and breakfast
                    stays and any other alternate accommodations.
                  </p>
                  <p>
                    All the information pertaining to the hotel including the
                    category of the hotel, images, room type, amenities and
                    facilities available at the hotel are as per the information
                    provided by the hotel/Partner Agency to Clickntravel. This
                    information is for reference only. Any discrepancy that may
                    exist between the Application pictures and actual settings
                    of the hotel shall be raised by the User with the hotel
                    directly and shall be resolved between the User and hotel.
                    Clickntravel will have no responsibility in that process of
                    resolution and shall not take any liability for such
                    discrepancies.
                  </p>
                  <p>
                    The hotel booking voucher which Clickntravel issues to a
                    User is solely based on the information provided or updated
                    by the hotel/Agency partner regarding the inventory
                    availability. In no circumstances can Clickntravel be held
                    liable for failure on part of a hotel to accommodate the
                    User with a confirmed booking, the standard of service or
                    any insufficiency in the services, or any other
                    service-related issues at the hotel. The liability of
                    Clickntravel in case of denial of check-in by a hotel for
                    any reason what-so-ever including over-booking, system or
                    technical errors, or unavailability of rooms etc., will be
                    limited to either providing a similar alternate
                    accommodation at the discretion of Clickntravel (subject to
                    availability at that time), or refunding the booking amount
                    (to the extent paid) to the User. Any other service-related
                    issues should be directly resolved by the User with the
                    hotel.
                  </p>
                  <p>
                    Hotels reserves the sole right of admission and Clickntravel
                    has no say whatsoever in admission or denial for admission
                    by the hotel. Unmarried or unrelated couples may not be
                    allowed to check-in by some hotels as per their policies.
                    Similarly, accommodation may be denied to guests posing as a
                    couple if suitable proof of identification is not presented
                    at the time check-in. Clickntravel will not be responsible
                    for any check-in denied by the hotel due to the aforesaid
                    reasons or any other reason not under the control of
                    Clickntravel. No refund would be applicable in case the
                    hotel denies check-in under such circumstances.
                  </p>
                  <p>
                    Clickntravel shall not be liable or responsible for any
                    damage or loss, injury, accident, death, irregularity,
                    delay/change in schedule, cancellation without cause,
                    inaccurate information, deficiency of service/product, or
                    cancellation due to conditions not under it’s control.
                  </p>
                </div>
              </ShowHide>
            </div>
            <div ref={tc1}>
              <ShowHide
                visible="true"
                icon=""
                title="Responsibilities of the User"
              >
                <div className="showHide-content">
                  <p>
                    The User would be liable to make good any damage(s) caused
                    by any act of him/ her/ or their accompanying guests
                    (willful/negligent) to the property of the hotel in any
                    manner whatsoever. The extent and the amount of the damage
                    so caused would be determined by the concerned hotel.
                    Clickntravel would not, in any way, intervene in the same.
                  </p>
                  <p>
                    The primary guest must be at least 18 years old to be able
                    to check into the hotel.
                  </p>
                  <p>
                    The User has to be in possession of a valid identity proof
                    and address proof, at the time of check-in. The hotel shall
                    be within its rights to deny check-in to a User if a valid
                    identity proof is not presented at the time of check-in.
                  </p>
                  <p>
                    Check-in time, check-out time, and any changes in those
                    timings, will be as per hotel policy & terms. Early check-in
                    or late check-out request is subject to availability and the
                    hotel may charge an additional fee for providing such
                    services.
                  </p>
                  <p>
                    The booking amount paid by the User is only for stay at the
                    hotel. Some bookings may include breakfast and/ or meals as
                    confirmed at the time of booking. Any other services
                    utilized by the User at the hotel, including laundry, room
                    service, internet, telephone, extra food, drinks, beverages
                    etc. shall be paid by the User directly to the hotel.
                  </p>
                  <p>
                    Any additional charges need to be cleared directly at the
                    hotel. Clickntravel will have no control over waiving the
                    same.
                  </p>
                </div>
              </ShowHide>
            </div>
            <div ref={tc2}>
              <ShowHide visible="true" icon="" title="Mode of Payment">
                <div className="showHide-content">
                  <p>
                    Booking of a hotel can be “Prepaid” as per the option made
                    available by a hotel on the Application of Clickntravel.
                  </p>
                  <p>
                    In “Prepaid” model, the total booking amount is paid by the
                    User at the time of booking itself. Such total booking
                    amount includes the hotel reservation rate, taxes, service
                    fees as may be charged on behalf of the actual service
                    provider, and any additional booking fee or convenience fee
                    charged by Clickntravel. Sometimes additional taxes laid
                    down by the government may be required to pay at the
                    property.
                  </p>
                  <p>
                    Clickntravel is not responsible for any errors, omissions or
                    representations on any of its pages, links or any linked
                    Application pages to the extent such information is updated
                    or provided directly by the Service Providers or the
                    advertisers.
                  </p>
                </div>
              </ShowHide>
            </div>
          </Col>
        </Row>
      </div>
      <Footer />
    </>
  );
}

export default HotelTermsAndConditions;
