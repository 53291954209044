/**
 * @ Author: Srikanth Bandaru
 * @ Create Time: 2023-08-03 09:47:01
 * @ Description: A common file to document all the samples of flights to re-use whenever required to reduce traffic on the test servers
 */

export const HOTEL_RESPONSE_ONE = {
    "suc": true,
    "err": false,
    "data": {
        "bid": "538",
        "cha": "B2B",
        "ctid": "600418fc1da0d517502e538f",
        "cur": "INR",
        "echo": "2b9c8fcl0zv",
        "otilacityid": "undefined",
        "prpts": [
            {
                "tid": "EJBssAZWLmIU80FR",
                "pid": "5814743",
                "hs": 0,
                "rmid": "200259976",
                "rtid": "201522091",
                "refundable": false,
                "available_rooms": 1,
                "score": 251,
                "sno": "608",
                "cont": {
                    "_id": "600d3ee7a1bc371b80f9b7ef",
                    "name": "Ramada by Wyndham Beach Hotel Ajman",
                    "ratings": "4.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.408205,
                            "longitude": 55.432338
                        }
                    },
                    "externalId": "5814743",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/6000000/5820000/5814800/5814743/e18e1aec_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "3.8",
                        "staff_service": "4.0",
                        "amenities": "4.0",
                        "conditions_facilities": "3.6"
                    },
                    "rat": "4.0",
                    "urat": "3.8",
                    "expediareview": 243,
                    "pid": "5814743",
                    "line1": "Sh. Humaid Bin Rashid Al Nuaimi Street",
                    "city": "Ajman",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Ajman Beach - 0.2 km / 0.1 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Very Good",
                "brdbs": [
                    "Breakfast"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 21510.49,
                "dwtf": 80335.11,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 4311.13,
                "com_disc": 0,
                "rtf": "58825.00",
                "rdwtf": "80335.00"
            },
            {
                "tid": "skJuJ44QNMO2GhUh",
                "pid": "3033052",
                "hs": 0,
                "rmid": "200017475",
                "rtid": "200085600",
                "refundable": true,
                "available_rooms": 10,
                "score": 778,
                "sno": "603",
                "cont": {
                    "_id": "600d422fa1bc371b80fe3a43",
                    "name": "Armani Hotel Dubai",
                    "ratings": "5.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.197116,
                            "longitude": 55.274058
                        }
                    },
                    "externalId": "3033052",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/22167ea6_b.jpg",
                    "ha": [
                        "BAR",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.7",
                        "staff_service": "4.6",
                        "amenities": "4.5",
                        "conditions_facilities": "4.6"
                    },
                    "rat": "5.0",
                    "urat": "4.6",
                    "expediareview": 573,
                    "pid": "3033052",
                    "line1": "Burj Khalifa, Mohamed Bin Rashid Blvd",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Burj Khalifa - 0.4 km / 0.3 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 16830.73,
                "dwtf": 66643.93,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 3811.13,
                "com_disc": 0,
                "rtf": "49813.00",
                "rdwtf": "66644.00"
            },
            {
                "tid": "D2sdUEfH0TENbLH3",
                "pid": "973713",
                "hs": 0,
                "rmid": "200175122",
                "rtid": "383428902",
                "refundable": false,
                "available_rooms": 32,
                "score": 757,
                "sno": "544",
                "cont": {
                    "_id": "600d411aa1bc371b80fcbb5c",
                    "name": "Jumeirah Mina A Salam",
                    "ratings": "5.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.135036,
                            "longitude": 55.186004
                        }
                    },
                    "externalId": "973713",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/1000000/980000/973800/973713/e4910b4c_b.jpg",
                    "ha": [
                        "BAR",
                        "POOL",
                        "WIFI",
                        "GYM"
                    ],
                    "guestreview": {
                        "cleaniness": "4.8",
                        "staff_service": "4.7",
                        "amenities": "4.7",
                        "conditions_facilities": "4.8"
                    },
                    "rat": "5.0",
                    "urat": "4.8",
                    "expediareview": 909,
                    "pid": "973713",
                    "line1": "Madinat Jumeirah, Al Sufouh Rd",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Madinat Jumeirah - 0.1 km / 0.1 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 13218.28,
                "dwtf": 32539.95,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 1473.71,
                "com_disc": 0,
                "rtf": "19322.00",
                "rdwtf": "32540.00"
            },
            {
                "tid": "daKsG9opu7IcD8xZ",
                "pid": "71587991",
                "hs": 0,
                "rmid": "315947636",
                "rtid": "389468152",
                "refundable": false,
                "available_rooms": 10,
                "score": 222,
                "sno": "605",
                "cont": {
                    "_id": "62506b902aee3e1bb50b695c",
                    "ratings": "0.0",
                    "name": "Anantara World Islands Dubai Resort",
                    "location": {
                        "coordinates": {
                            "latitude": 25.194131,
                            "longitude": 55.177019
                        },
                        "obfuscationRequired": false
                    },
                    "externalId": "71587991",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/72000000/71590000/71588000/71587991/febb0f0f_b.jpg",
                    "ha": [
                        "WIFI",
                        "GYM"
                    ],
                    "guestreview": {
                        "cleaniness": 0,
                        "staff_service": 0,
                        "amenities": 0,
                        "conditions_facilities": 0
                    },
                    "rat": "0.0",
                    "urat": "",
                    "expediareview": 0,
                    "pid": "71587991",
                    "line1": "South America, The World Islands",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Sheikh Zayed Road - 3.4 km / 2.1 mi "
                },
                "hdtype": "HOTELHD",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 13136.18,
                "dwtf": 64395.18,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 4391.59,
                "com_disc": 0,
                "rtf": "51259.00",
                "rdwtf": "64395.00"
            },
            {
                "tid": "Bu8rMaw43BJD4AgE",
                "pid": "15536928",
                "hs": 0,
                "rmid": "201708990",
                "rtid": "209439598",
                "refundable": true,
                "available_rooms": 24,
                "score": 507,
                "sno": "595",
                "cont": {
                    "_id": "600d4341a1bc371b80ff9f63",
                    "name": "Address Boulevard",
                    "ratings": "5.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.20087,
                            "longitude": 55.276589
                        }
                    },
                    "externalId": "15536928",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/16000000/15540000/15537000/15536928/931b3055_b.jpg",
                    "ha": [
                        "BAR",
                        "POOL",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.7",
                        "staff_service": "4.6",
                        "amenities": "4.5",
                        "conditions_facilities": "4.7"
                    },
                    "rat": "5.0",
                    "urat": "4.7",
                    "expediareview": 98,
                    "pid": "15536928",
                    "line1": "Sheikh Mohammed Bin Rashid Boulevard",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Dubai Aquarium & Underwater Zoo - 0.5 km / 0.3 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Breakfast"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 12971.97,
                "dwtf": 51378.87,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 2938.4,
                "com_disc": 0,
                "rtf": "38407.00",
                "rdwtf": "51379.00"
            },
            {
                "tid": "59ouFm46W7U7JrTp",
                "pid": "2224974",
                "hs": 0,
                "rmid": "288740",
                "rtid": "200845987",
                "refundable": true,
                "available_rooms": 15,
                "score": 589,
                "sno": "590",
                "cont": {
                    "_id": "600d43b0a1bc371b80002697",
                    "name": "Address Downtown",
                    "ratings": "5.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.19333,
                            "longitude": 55.27966
                        }
                    },
                    "externalId": "2224974",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/3000000/2230000/2225000/2224974/c4849fea_b.jpg",
                    "ha": [
                        "BAR",
                        "GYM",
                        "POOL",
                        "POOL",
                        "BAR"
                    ],
                    "guestreview": {
                        "cleaniness": "4.8",
                        "staff_service": "4.5",
                        "amenities": "4.6",
                        "conditions_facilities": "4.7"
                    },
                    "rat": "5.0",
                    "urat": "4.6",
                    "expediareview": 282,
                    "pid": "2224974",
                    "line1": "Mohammed Bin Rashed Boulevard",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Souk al Bahar - 0.4 km / 0.2 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 12397.27,
                "dwtf": 48974.95,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 2798.83,
                "com_disc": 0,
                "rtf": "36578.00",
                "rdwtf": "48975.00"
            },
            {
                "tid": "IFH6FlusHzHvPG6V",
                "pid": "33010540",
                "hs": 0,
                "rmid": "219939667",
                "rtid": "286163605",
                "refundable": true,
                "available_rooms": 3,
                "score": 576,
                "sno": "591",
                "cont": {
                    "_id": "600d3f31a1bc371b80fa1e78",
                    "name": "Address Fountain Views",
                    "ratings": "5.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.194945,
                            "longitude": 55.282781
                        }
                    },
                    "externalId": "33010540",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/34000000/33020000/33010600/33010540/ee546467_b.jpg",
                    "ha": [
                        "POOL",
                        "POOL",
                        "WIFI",
                        "GYM"
                    ],
                    "guestreview": {
                        "cleaniness": "4.7",
                        "staff_service": "4.6",
                        "amenities": "4.3",
                        "conditions_facilities": "4.8"
                    },
                    "rat": "5.0",
                    "urat": "4.5",
                    "expediareview": 14,
                    "pid": "33010540",
                    "line1": "Sheikh Mohammed Bin Rashid Boulevard",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Dubai Water Canal - 1.1 km / 0.7 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 12397.27,
                "dwtf": 48974.95,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 2798.83,
                "com_disc": 0,
                "rtf": "36578.00",
                "rdwtf": "48975.00"
            },
            {
                "tid": "G8R017Xx8IAdtkst",
                "pid": "5617257",
                "hs": 0,
                "rmid": "315654666",
                "rtid": "386823229",
                "refundable": true,
                "available_rooms": 3,
                "score": 805,
                "sno": "536",
                "cont": {
                    "_id": "600d433ea1bc371b80ff9d4c",
                    "name": "JA Ocean View Hotel",
                    "ratings": "5.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.073644,
                            "longitude": 55.130215
                        }
                    },
                    "externalId": "5617257",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/6000000/5620000/5617300/5617257/22533f0a_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "POOL",
                        "BAR",
                        "POOL"
                    ],
                    "guestreview": {
                        "cleaniness": "4.7",
                        "staff_service": "4.6",
                        "amenities": "4.4",
                        "conditions_facilities": "4.5"
                    },
                    "rat": "5.0",
                    "urat": "4.5",
                    "expediareview": 951,
                    "pid": "5617257",
                    "line1": "The Walk Jbr",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "The Walk - 0.1 km / 0.1 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 10673.14,
                "dwtf": 29276.43,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 1408.85,
                "com_disc": 0,
                "rtf": "18603.00",
                "rdwtf": "29276.00"
            },
            {
                "tid": "BuRxurTl27Vt5v8J",
                "pid": "1725259",
                "hs": 0,
                "rmid": "286377",
                "rtid": "200846075",
                "refundable": true,
                "available_rooms": 5,
                "score": 675,
                "sno": "572",
                "cont": {
                    "_id": "600d3e8ea1bc371b80f948b5",
                    "name": "Palace Downtown",
                    "ratings": "5.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.193534,
                            "longitude": 55.275966
                        }
                    },
                    "externalId": "1725259",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/2000000/1730000/1725300/1725259/d9ab9ef4_b.jpg",
                    "ha": [
                        "BAR",
                        "POOL",
                        "POOL",
                        "BAR",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.7",
                        "staff_service": "4.7",
                        "amenities": "4.6",
                        "conditions_facilities": "4.6"
                    },
                    "rat": "5.0",
                    "urat": "4.7",
                    "expediareview": 927,
                    "pid": "1725259",
                    "line1": "Sheikh Mohammed Bin Rashed",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Souk al Bahar - 0.2 km / 0.1 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 8949.02,
                "dwtf": 35360.12,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 2020.51,
                "com_disc": 0,
                "rtf": "26411.00",
                "rdwtf": "35360.00"
            },
            {
                "tid": "xWduu1Uf92BtiSSr",
                "pid": "33010066",
                "hs": 0,
                "rmid": "220565602",
                "rtid": "290264559",
                "refundable": true,
                "available_rooms": 3,
                "score": 668,
                "sno": "573",
                "cont": {
                    "_id": "600d4066a1bc371b80fbb6de",
                    "name": "Address Sky View",
                    "ratings": "5.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.201961,
                            "longitude": 55.271246
                        }
                    },
                    "externalId": "33010066",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/34000000/33020000/33010100/33010066/a408a4a3_b.jpg",
                    "ha": [
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.8",
                        "staff_service": "4.2",
                        "amenities": "4.4",
                        "conditions_facilities": "4.6"
                    },
                    "rat": "5.0",
                    "urat": "4.4",
                    "expediareview": 26,
                    "pid": "33010066",
                    "line1": "Sheikh Mohammed Bin Rashed Boulevard",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Emaar Square - 0.3 km / 0.2 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 8949.02,
                "dwtf": 35360.12,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 2020.51,
                "com_disc": 0,
                "rtf": "26411.00",
                "rdwtf": "35360.00"
            },
            {
                "tid": "IwCEAHpUaKNR0cfk",
                "pid": "1666993",
                "hs": 0,
                "rmid": "171911",
                "rtid": "400035",
                "refundable": true,
                "available_rooms": 22,
                "score": 523,
                "sno": "466",
                "cont": {
                    "_id": "600d3deaa1bc371b80f84d7d",
                    "name": "Vida Downtown",
                    "ratings": "4.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.190036,
                            "longitude": 55.274481
                        }
                    },
                    "externalId": "1666993",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/2000000/1670000/1667000/1666993/aa0279c8_b.jpg",
                    "ha": [
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.7",
                        "staff_service": "4.6",
                        "amenities": "4.3",
                        "conditions_facilities": "4.6"
                    },
                    "rat": "4.0",
                    "urat": "4.5",
                    "expediareview": 551,
                    "pid": "1666993",
                    "line1": "Sheikh Mohammed Bin Rashid Boulevard",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Souk al Bahar - 0.6 km / 0.4 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 8784.82,
                "dwtf": 21781.42,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 994.24,
                "com_disc": 0,
                "rtf": "12997.00",
                "rdwtf": "21781.00"
            },
            {
                "tid": "UTmKS1U7Vk77geOg",
                "pid": "22627",
                "hs": 0,
                "rmid": "200147469",
                "rtid": "391638524",
                "refundable": true,
                "available_rooms": 11,
                "score": 543,
                "sno": "482",
                "cont": {
                    "_id": "600d3f90a1bc371b80faa1f0",
                    "name": "JA Beach Hotel",
                    "ratings": "5.0",
                    "location": {
                        "coordinates": {
                            "latitude": 24.987,
                            "longitude": 55.022962
                        }
                    },
                    "externalId": "22627",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/1000000/30000/22700/22627/751c6b9c_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.6",
                        "staff_service": "4.5",
                        "amenities": "4.2",
                        "conditions_facilities": "4.4"
                    },
                    "rat": "5.0",
                    "urat": "4.4",
                    "expediareview": 639,
                    "pid": "22627",
                    "line1": "Within JA The Resort",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "MOTIONGATE™ Dubai - 17.6 km / 10.9 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 8292.21,
                "dwtf": 22348.74,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 1058.28,
                "com_disc": 0,
                "rtf": "14057.00",
                "rdwtf": "22349.00"
            },
            {
                "tid": "4WHkqkb8oHJLAKrm",
                "pid": "2983165",
                "hs": 0,
                "rmid": "424031",
                "rtid": "200846022",
                "refundable": false,
                "available_rooms": 19,
                "score": 648,
                "sno": "565",
                "cont": {
                    "_id": "600d434ba1bc371b80ffa893",
                    "name": "Address Dubai Marina",
                    "ratings": "5.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.077362,
                            "longitude": 55.140328
                        }
                    },
                    "externalId": "2983165",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/3000000/2990000/2983200/2983165/180fa315_b.jpg",
                    "ha": [
                        "BAR",
                        "GYM",
                        "POOL",
                        "POOL",
                        "BAR"
                    ],
                    "guestreview": {
                        "cleaniness": "4.8",
                        "staff_service": "4.8",
                        "amenities": "4.7",
                        "conditions_facilities": "4.7"
                    },
                    "rat": "5.0",
                    "urat": "4.7",
                    "expediareview": 687,
                    "pid": "2983165",
                    "line1": "Al Marsa Street, 66 Dubai Marina",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Dubai Marina Mall - 0.5 km / 0.3 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 8292.21,
                "dwtf": 32669.67,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 1864.52,
                "com_disc": 0,
                "rtf": "24377.00",
                "rdwtf": "32670.00"
            },
            {
                "tid": "atVNEPfHpZhFrE9Z",
                "pid": "26743083",
                "hs": 0,
                "rmid": "217212060",
                "rtid": "268101921",
                "refundable": false,
                "available_rooms": 6,
                "score": 619,
                "sno": "493",
                "cont": {
                    "_id": "600d3e02a1bc371b80f86a3e",
                    "name": "Royal Central Hotel The Palm",
                    "ratings": "5.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.1259,
                            "longitude": 55.15305
                        }
                    },
                    "externalId": "26743083",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/27000000/26750000/26743100/26743083/80645cc9_b.jpg",
                    "ha": [
                        "BAR",
                        "GYM",
                        "POOL",
                        "POOL",
                        "BAR"
                    ],
                    "guestreview": {
                        "cleaniness": "4.3",
                        "staff_service": "4.2",
                        "amenities": "3.9",
                        "conditions_facilities": "4.3"
                    },
                    "rat": "5.0",
                    "urat": "4.1",
                    "expediareview": 174,
                    "pid": "26743083",
                    "line1": "Palm Jumeirah, East Crescent",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Aquaventure - 5.1 km / 3.2 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 8210.11,
                "dwtf": 23037.57,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 1132.17,
                "com_disc": 0,
                "rtf": "14827.00",
                "rdwtf": "23038.00"
            },
            {
                "tid": "GdXle4ZezbV3TH4m",
                "pid": "26669791",
                "hs": 0,
                "rmid": "321614456",
                "rtid": "389155863",
                "refundable": false,
                "available_rooms": 10,
                "score": 503,
                "sno": "390",
                "cont": {
                    "_id": "600d3fcfa1bc371b80faea3b",
                    "name": "Canal Central Hotel",
                    "ratings": "5.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.180305,
                            "longitude": 55.266409
                        }
                    },
                    "externalId": "26669791",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/27000000/26670000/26669800/26669791/39785ac7_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.6",
                        "staff_service": "4.4",
                        "amenities": "4.3",
                        "conditions_facilities": "4.5"
                    },
                    "rat": "5.0",
                    "urat": "4.3",
                    "expediareview": 274,
                    "pid": "26669791",
                    "line1": "Al Abraj Street",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Souk al Bahar - 3 km / 1.9 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 7799.6,
                "dwtf": 16333.19,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 651.06,
                "com_disc": 0,
                "rtf": "8534.00",
                "rdwtf": "16333.00"
            },
            {
                "tid": "qT7qA92ouumk0c7V",
                "pid": "36399251",
                "hs": 0,
                "rmid": "215143292",
                "rtid": "391638555",
                "refundable": true,
                "available_rooms": 23,
                "score": 412,
                "sno": "469",
                "cont": {
                    "_id": "600d3f71a1bc371b80fa7ec9",
                    "name": "JA Lake View Hotel",
                    "ratings": "0.0",
                    "location": {
                        "coordinates": {
                            "latitude": 24.987361,
                            "longitude": 55.023104
                        }
                    },
                    "externalId": "36399251",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/37000000/36400000/36399300/36399251/d3f4307d_b.jpg",
                    "ha": [
                        "BAR",
                        "POOL",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.8",
                        "staff_service": "4.5",
                        "amenities": "4.4",
                        "conditions_facilities": "4.7"
                    },
                    "rat": "0.0",
                    "urat": "4.5",
                    "expediareview": 35,
                    "pid": "36399251",
                    "line1": "Within JA The Resort, Jebel Ali Beach",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "MOTIONGATE™ Dubai - 16.2 km / 10 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 7799.6,
                "dwtf": 21030.2,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 996.71,
                "com_disc": 0,
                "rtf": "13231.00",
                "rdwtf": "21030.00"
            },
            {
                "tid": "1nd6M9WkQEdWE0ci",
                "pid": "527497",
                "hs": 0,
                "rmid": "314079055",
                "rtid": "382186554",
                "refundable": false,
                "available_rooms": 32,
                "score": 1003,
                "sno": "612",
                "cont": {
                    "_id": "600d43a2a1bc371b800013da",
                    "name": "Burj Al Arab Jumeirah",
                    "ratings": "5.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.140873,
                            "longitude": 55.185927
                        }
                    },
                    "externalId": "527497",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/1000000/530000/527500/527497/db8dcc05_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "BAR",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.9",
                        "staff_service": "4.8",
                        "amenities": "4.8",
                        "conditions_facilities": "4.8"
                    },
                    "rat": "5.0",
                    "urat": "4.8",
                    "expediareview": 568,
                    "pid": "527497",
                    "line1": "Jumeirah Beach Road",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Burj Al Arab - 0.1 km / 0.1 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Breakfast"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 7060.69,
                "dwtf": 138277.14,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 10039.32,
                "com_disc": 0,
                "rtf": "131216.00",
                "rdwtf": "138277.00"
            },
            {
                "tid": "hNW09yz1s64aPCnV",
                "pid": "41997872",
                "hs": 0,
                "rmid": "218170169",
                "rtid": "274380003",
                "refundable": false,
                "available_rooms": 12,
                "score": 618,
                "sno": "414",
                "cont": {
                    "_id": "600d4332a1bc371b80ff9055",
                    "name": "C Central Resort the Palm",
                    "ratings": "5.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.139238,
                            "longitude": 55.141492
                        }
                    },
                    "externalId": "41997872",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/42000000/42000000/41997900/41997872/526af621_b.jpg",
                    "ha": [
                        "BAR",
                        "GYM",
                        "POOL",
                        "BAR",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.5",
                        "staff_service": "4.3",
                        "amenities": "4.2",
                        "conditions_facilities": "4.4"
                    },
                    "rat": "5.0",
                    "urat": "4.2",
                    "expediareview": 65,
                    "pid": "41997872",
                    "line1": "Crescent Rd, Palm Jumeirah",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Aquaventure - 2.9 km / 1.8 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 6568.09,
                "dwtf": 16132.87,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 729.88,
                "com_disc": 0,
                "rtf": "9565.00",
                "rdwtf": "16133.00"
            },
            {
                "tid": "ptMfP7E0NifsIVQH",
                "pid": "6481219",
                "hs": 0,
                "rmid": "202417552",
                "rtid": "212296459",
                "refundable": true,
                "available_rooms": 18,
                "score": 264,
                "sno": "154",
                "cont": {
                    "_id": "600d4380a1bc371b80ffe6d6",
                    "name": "Copthorne Hotel Sharjah",
                    "ratings": "4.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.334429,
                            "longitude": 55.375854
                        }
                    },
                    "externalId": "6481219",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/7000000/6490000/6481300/6481219/642e695c_b.jpg",
                    "ha": [
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.1",
                        "staff_service": "4.1",
                        "amenities": "3.4",
                        "conditions_facilities": "4.1"
                    },
                    "rat": "4.0",
                    "urat": "4.0",
                    "expediareview": 174,
                    "pid": "6481219",
                    "line1": "Al Majaz 3, Buheira Corniche Road",
                    "city": "Sharjah",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Khalid Lake - 0.1 km / 0.1 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 6403.89,
                "dwtf": 10809.43,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 310.34,
                "com_disc": 0,
                "rtf": "4406.00",
                "rdwtf": "10809.00"
            },
            {
                "tid": "9Zt2RPcMdf3nNGDL",
                "pid": "9860088",
                "hs": 0,
                "rmid": "200907360",
                "rtid": "204592811",
                "refundable": false,
                "available_rooms": 1,
                "score": 337,
                "sno": "494",
                "cont": {
                    "_id": "600d432aa1bc371b80ff8895",
                    "name": "Pearl Marina Hotel Apartments",
                    "ratings": "3.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.069436,
                            "longitude": 55.128942
                        }
                    },
                    "externalId": "9860088",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/10000000/9870000/9860100/9860088/e8219142_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "POOL",
                        "POOL"
                    ],
                    "guestreview": {
                        "cleaniness": "4.2",
                        "staff_service": "4.3",
                        "amenities": "4.1",
                        "conditions_facilities": "3.9"
                    },
                    "rat": "3.0",
                    "urat": "4.1",
                    "expediareview": 194,
                    "pid": "9860088",
                    "line1": "Marina Water Front",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "The Walk - 1.4 km / 0.8 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 6403.89,
                "dwtf": 21240.38,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 1137.92,
                "com_disc": 0,
                "rtf": "14836.00",
                "rdwtf": "21240.00"
            },
            {
                "tid": "ALTuOS5G9mWV5TXn",
                "pid": "4051284",
                "hs": 0,
                "rmid": "321543209",
                "rtid": "389022098",
                "refundable": false,
                "available_rooms": 1,
                "score": 257,
                "sno": "296",
                "cont": {
                    "_id": "600d416aa1bc371b80fd0f81",
                    "name": "Golden Sands Hotel Sharjah",
                    "ratings": "4.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.298427,
                            "longitude": 55.37226
                        }
                    },
                    "externalId": "4051284",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/5000000/4060000/4051300/4051284/c61041d0_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.1",
                        "staff_service": "4.1",
                        "amenities": "4.1",
                        "conditions_facilities": "3.9"
                    },
                    "rat": "4.0",
                    "urat": "4.0",
                    "expediareview": 331,
                    "pid": "4051284",
                    "line1": "Al Nahda Street 115, opp. Sahara Center",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Sahara Centre - 0.5 km / 0.3 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 6075.48,
                "dwtf": 12387.41,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 483.58,
                "com_disc": 0,
                "rtf": "6312.00",
                "rdwtf": "12387.00"
            },
            {
                "tid": "cCW5CCU0BnZ41JqC",
                "pid": "73586368",
                "hs": 0,
                "rmid": "315901033",
                "rtid": "384992379",
                "refundable": true,
                "available_rooms": 2147483647,
                "score": 334,
                "sno": "445",
                "cont": {
                    "_id": "62506b6f2aee3e1bb50b68d4",
                    "ratings": "5.0",
                    "name": "25hours Hotel One Central",
                    "location": {
                        "coordinates": {
                            "latitude": 25.21945,
                            "longitude": 55.284403
                        },
                        "obfuscationRequired": false
                    },
                    "externalId": "73586368",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/74000000/73590000/73586400/73586368/1c4665fc_b.jpg",
                    "ha": [
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": 0,
                        "staff_service": 0,
                        "amenities": 0,
                        "conditions_facilities": 0
                    },
                    "rat": "5.0",
                    "urat": "",
                    "expediareview": 0,
                    "pid": "73586368",
                    "line1": "Trade Centre Street off Sheikh Zayed Rd",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Sheikh Zayed Road - 0.1 km / 0.1 mi "
                },
                "hdtype": "HOTELHD",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 5582.87,
                "dwtf": 17151.74,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 972.9,
                "com_disc": 0,
                "rtf": "11569.00",
                "rdwtf": "17152.00"
            },
            {
                "tid": "qNBL5sT85qa7lTmt",
                "pid": "3347891",
                "hs": 0,
                "rmid": "316007562",
                "rtid": "385240535",
                "refundable": false,
                "available_rooms": 29,
                "score": 489,
                "sno": "439",
                "cont": {
                    "_id": "600d411fa1bc371b80fcc05c",
                    "name": "The Meydan Hotel",
                    "ratings": "5.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.157425,
                            "longitude": 55.30139
                        }
                    },
                    "externalId": "3347891",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/4000000/3350000/3347900/3347891/48c03780_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "POOL",
                        "BAR",
                        "POOL"
                    ],
                    "guestreview": {
                        "cleaniness": "4.6",
                        "staff_service": "4.4",
                        "amenities": "4.3",
                        "conditions_facilities": "4.5"
                    },
                    "rat": "5.0",
                    "urat": "4.4",
                    "expediareview": 429,
                    "pid": "3347891",
                    "line1": "Meydan Racecourse,  Al Meydan Road",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Meydan Racecourse - 0.1 km / 0.1 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 5336.57,
                "dwtf": 16267.51,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 834.15,
                "com_disc": 0,
                "rtf": "10931.00",
                "rdwtf": "16268.00"
            },
            {
                "tid": "0DzbD57y0XlHNahG",
                "pid": "74743075",
                "hs": 0,
                "rmid": "316058882",
                "rtid": "385617193",
                "refundable": true,
                "available_rooms": 17,
                "score": 299,
                "sno": "448",
                "cont": {
                    "_id": "62506b152aee3e1bb50b6792",
                    "name": "Paramount Hotel Midtown",
                    "location": {
                        "coordinates": {
                            "latitude": 25.193442,
                            "longitude": 55.265401
                        },
                        "obfuscatedCoordinates": {
                            "latitude": 0.002934,
                            "longitude": 0.000184
                        },
                        "obfuscationRequired": false
                    },
                    "externalId": "74743075",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/75000000/74750000/74743100/74743075/0e67618c_b.jpg",
                    "ha": [
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": 0,
                        "staff_service": 0,
                        "amenities": 0,
                        "conditions_facilities": 0
                    },
                    "rat": "",
                    "urat": "",
                    "expediareview": 0,
                    "ratings": "",
                    "pid": "74743075",
                    "line1": "Al Mustaqbal St",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Sheikh Zayed Road - 0.1 km / 0.1 mi "
                },
                "hdtype": "HOTELHD",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 5172.37,
                "dwtf": 16901.33,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 1004.1,
                "com_disc": 0,
                "rtf": "11729.00",
                "rdwtf": "16901.00"
            },
            {
                "tid": "IsLmiX9o5R0ptqLe",
                "pid": "57930073",
                "hs": 0,
                "rmid": "314163480",
                "rtid": "389054825",
                "refundable": true,
                "available_rooms": 18,
                "score": 163,
                "sno": "474",
                "cont": {
                    "_id": "62506c582aee3e1bb50b6bee",
                    "ratings": "0.0",
                    "name": "Vida Beach Resort Umm Al Quwain",
                    "location": {
                        "coordinates": {
                            "latitude": 25.5787,
                            "longitude": 55.56466
                        },
                        "obfuscatedCoordinates": {
                            "latitude": 25.581593,
                            "longitude": 55.56268
                        },
                        "obfuscationRequired": false
                    },
                    "externalId": "57930073",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/58000000/57940000/57930100/57930073/591144f6_b.jpg",
                    "ha": [
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.5",
                        "staff_service": "4.1",
                        "amenities": "4.5",
                        "conditions_facilities": "4.6"
                    },
                    "rat": "0.0",
                    "urat": "4.1",
                    "expediareview": 18,
                    "pid": "57930073",
                    "line1": "UMM AL QUWAIN, AL KHOR 1",
                    "city": "Umm al Quwain",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Al Hamriyah Free Zone - 17 km / 10.6 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 5172.37,
                "dwtf": 18797.87,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 1061.57,
                "com_disc": 0,
                "rtf": "13625.00",
                "rdwtf": "18798.00"
            },
            {
                "tid": "fILLkTHuWxp96ZR0",
                "pid": "38170272",
                "hs": 0,
                "rmid": "216955753",
                "rtid": "266337811",
                "refundable": true,
                "available_rooms": 8,
                "score": 419,
                "sno": "480",
                "cont": {
                    "_id": "600d401ea1bc371b80fb56f6",
                    "name": "Paramount Hotel Dubai",
                    "ratings": "0.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.185574,
                            "longitude": 55.292194
                        }
                    },
                    "externalId": "38170272",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/39000000/38180000/38170300/38170272/003e8a24_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "POOL",
                        "BAR",
                        "POOL"
                    ],
                    "guestreview": {
                        "cleaniness": "4.1",
                        "staff_service": "4.0",
                        "amenities": "4.2",
                        "conditions_facilities": "4.0"
                    },
                    "rat": "0.0",
                    "urat": "3.9",
                    "expediareview": 29,
                    "pid": "38170272",
                    "line1": "Business Bay",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Dubai Fountain - 2.3 km / 1.5 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 4761.86,
                "dwtf": 18619.71,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 1057.46,
                "com_disc": 0,
                "rtf": "13858.00",
                "rdwtf": "18620.00"
            },
            {
                "tid": "FetR6M76pi3Bhkb8",
                "pid": "16210008",
                "hs": 0,
                "rmid": "201692005",
                "rtid": "389274403",
                "refundable": true,
                "available_rooms": 11,
                "score": 367,
                "sno": "431",
                "cont": {
                    "_id": "600d41dfa1bc371b80fdd2d7",
                    "name": "Hyatt Place Dubai Al Rigga Residences",
                    "ratings": "3.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.26292,
                            "longitude": 55.32799
                        }
                    },
                    "externalId": "16210008",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/17000000/16220000/16210100/16210008/2ef3a156_b.jpg",
                    "ha": [
                        "BAR",
                        "GYM",
                        "POOL",
                        "POOL",
                        "BAR"
                    ],
                    "guestreview": {
                        "cleaniness": "4.4",
                        "staff_service": "4.3",
                        "amenities": "4.0",
                        "conditions_facilities": "4.2"
                    },
                    "rat": "3.0",
                    "urat": "4.3",
                    "expediareview": 250,
                    "pid": "16210008",
                    "line1": "Al Rigga Road",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Reef Mall - 1.1 km / 0.7 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 4679.76,
                "dwtf": 15181.31,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 801.31,
                "com_disc": 0,
                "rtf": "10502.00",
                "rdwtf": "15181.00"
            },
            {
                "tid": "qWSxO4zHCBm3OkSW",
                "pid": "42691507",
                "hs": 0,
                "rmid": "219647382",
                "rtid": "284195449",
                "refundable": true,
                "available_rooms": 5,
                "score": 462,
                "sno": "530",
                "cont": {
                    "_id": "600d4302a1bc371b80ff5d40",
                    "name": "ME Dubai by Meliá",
                    "ratings": "5.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.189323,
                            "longitude": 55.267007
                        }
                    },
                    "externalId": "42691507",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/43000000/42700000/42691600/42691507/9d0fb28e_b.jpg",
                    "ha": [
                        "BAR",
                        "GYM",
                        "POOL",
                        "POOL",
                        "BAR"
                    ],
                    "guestreview": {
                        "cleaniness": 0,
                        "staff_service": 0,
                        "amenities": 0,
                        "conditions_facilities": 0
                    },
                    "rat": "5.0",
                    "urat": "",
                    "expediareview": 0,
                    "pid": "42691507",
                    "line1": "The Opus by Zaha Hadid, Al A'amal Street",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Dubai Opera - 1.3 km / 0.8 mi "
                },
                "hdtype": "HOTELHD",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 4515.56,
                "dwtf": 22301.94,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 1363.7,
                "com_disc": 0,
                "rtf": "17786.00",
                "rdwtf": "22302.00"
            },
            {
                "tid": "f6GXRA2aciNDwNmT",
                "pid": "9832383",
                "hs": 0,
                "rmid": "201183246",
                "rtid": "209310811",
                "refundable": false,
                "available_rooms": 10,
                "score": 602,
                "sno": "535",
                "cont": {
                    "_id": "600d3e97a1bc371b80f95409",
                    "name": "Palazzo Versace Dubai",
                    "ratings": "5.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.22668,
                            "longitude": 55.342368
                        }
                    },
                    "externalId": "9832383",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/10000000/9840000/9832400/9832383/cf5f46ba_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "POOL",
                        "BAR",
                        "POOL"
                    ],
                    "guestreview": {
                        "cleaniness": "4.8",
                        "staff_service": "4.7",
                        "amenities": "4.9",
                        "conditions_facilities": "4.8"
                    },
                    "rat": "5.0",
                    "urat": "4.7",
                    "expediareview": 305,
                    "pid": "9832383",
                    "line1": "Jaddaf Waterfront",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Dubai Creek - 3.9 km / 2.4 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 4515.56,
                "dwtf": 22992.41,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 1409.68,
                "com_disc": 0,
                "rtf": "18477.00",
                "rdwtf": "22992.00"
            },
            {
                "tid": "02TLrUGvXONsFbTS",
                "pid": "55234969",
                "hs": 0,
                "rmid": "233152584",
                "rtid": "294381939",
                "refundable": false,
                "available_rooms": 1,
                "score": 188,
                "sno": "298",
                "cont": {
                    "_id": "600d4334a1bc371b80ff9344",
                    "name": "Beach Walk Hotel",
                    "ratings": "0.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.173867,
                            "longitude": 55.218688
                        }
                    },
                    "externalId": "55234969",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/56000000/55240000/55235000/55234969/9dc958d1_b.jpg",
                    "ha": [
                        "POOL",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": 0,
                        "staff_service": 0,
                        "amenities": 0,
                        "conditions_facilities": 0
                    },
                    "rat": "0.0",
                    "urat": "",
                    "expediareview": 0,
                    "pid": "55234969",
                    "line1": "Beach Walk Hotel",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Kite Beach - 0.8 km / 0.5 mi "
                },
                "hdtype": "HOTELHD",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 4351.36,
                "dwtf": 10670.68,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 482.75,
                "com_disc": 0,
                "rtf": "6319.00",
                "rdwtf": "10671.00"
            },
            {
                "tid": "6iOFRc6UpAIVtTeg",
                "pid": "3374468",
                "hs": 0,
                "rmid": "321529839",
                "rtid": "389086513",
                "refundable": true,
                "available_rooms": 37,
                "score": 446,
                "sno": "130",
                "cont": {
                    "_id": "600d3fd7a1bc371b80faf423",
                    "name": "Fraser Suites Dubai",
                    "ratings": "4.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.107647,
                            "longitude": 55.178357
                        }
                    },
                    "externalId": "3374468",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/4000000/3380000/3374500/3374468/015899c4_b.jpg",
                    "ha": [
                        "POOL",
                        "POOL",
                        "WIFI",
                        "GYM"
                    ],
                    "guestreview": {
                        "cleaniness": "4.2",
                        "staff_service": "4.2",
                        "amenities": "4.2",
                        "conditions_facilities": "4.1"
                    },
                    "rat": "4.0",
                    "urat": "4.1",
                    "expediareview": 315,
                    "pid": "3374468",
                    "line1": "Sidra Tower, Media City",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Dubai College - 2 km / 1.3 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 4269.26,
                "dwtf": 8458.88,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 321.02,
                "com_disc": 0,
                "rtf": "4190.00",
                "rdwtf": "8459.00"
            },
            {
                "tid": "CxFuFz2ygauzN1QP",
                "pid": "5414971",
                "hs": 0,
                "rmid": "318605981",
                "rtid": "386887053",
                "refundable": false,
                "available_rooms": 80,
                "score": 611,
                "sno": "417",
                "cont": {
                    "_id": "600d42c5a1bc371b80ff064d",
                    "name": "The Oberoi",
                    "ratings": "5.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.187137,
                            "longitude": 55.263879
                        }
                    },
                    "externalId": "5414971",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/6000000/5420000/5415000/5414971/62e9c387_b.jpg",
                    "ha": [
                        "BAR",
                        "POOL",
                        "POOL",
                        "BAR",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.8",
                        "staff_service": "4.8",
                        "amenities": "4.8",
                        "conditions_facilities": "4.7"
                    },
                    "rat": "5.0",
                    "urat": "4.7",
                    "expediareview": 409,
                    "pid": "5414971",
                    "line1": "The Oberoi Centre, Business Bay",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Dubai Opera - 1.8 km / 1.1 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 4269.26,
                "dwtf": 13875.91,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 746.3,
                "com_disc": 0,
                "rtf": "9607.00",
                "rdwtf": "13876.00"
            },
            {
                "tid": "h6cBUcUtnQ7zNplc",
                "pid": "3806375",
                "hs": 0,
                "rmid": "200317511",
                "rtid": "384438890",
                "refundable": false,
                "available_rooms": 12,
                "score": 290,
                "sno": "99",
                "cont": {
                    "_id": "600d407ba1bc371b80fbd509",
                    "name": "Cassells Al Barsha Hotel by IGH",
                    "ratings": "4.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.11384,
                            "longitude": 55.193125
                        }
                    },
                    "externalId": "3806375",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/4000000/3810000/3806400/3806375/2e038b84_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "POOL",
                        "BAR",
                        "POOL"
                    ],
                    "guestreview": {
                        "cleaniness": "3.5",
                        "staff_service": "3.6",
                        "amenities": "4.0",
                        "conditions_facilities": "3.5"
                    },
                    "rat": "4.0",
                    "urat": "3.6",
                    "expediareview": 154,
                    "pid": "3806375",
                    "line1": "Sheikh Zayed Road",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Fatima Abdullah Mohammed Rasheed Mosque - 0.2 km / 0.1 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 4022.95,
                "dwtf": 7925.22,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 298.03,
                "com_disc": 0,
                "rtf": "3902.00",
                "rdwtf": "7925.00"
            },
            {
                "tid": "C4HTphRDOROXRrzt",
                "pid": "20242915",
                "hs": 0,
                "rmid": "202309854",
                "rtid": "294216557",
                "refundable": false,
                "available_rooms": 3,
                "score": 412,
                "sno": "392",
                "cont": {
                    "_id": "600d3ec6a1bc371b80f98c55",
                    "name": "Park Regis Boutique Hotel",
                    "ratings": "3.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.146352,
                            "longitude": 55.195563
                        }
                    },
                    "externalId": "20242915",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/21000000/20250000/20243000/20242915/e0d3416c_b.jpg",
                    "ha": [
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.8",
                        "staff_service": "4.7",
                        "amenities": "4.3",
                        "conditions_facilities": "4.6"
                    },
                    "rat": "3.0",
                    "urat": "4.5",
                    "expediareview": 84,
                    "pid": "20242915",
                    "line1": "325 Rd, Al Thanya Street",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Umm Suqeim Beach - 0.2 km / 0.1 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 3858.75,
                "dwtf": 12464.59,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 656.81,
                "com_disc": 0,
                "rtf": "8606.00",
                "rdwtf": "12465.00"
            },
            {
                "tid": "xc2n1xOrmMc20ryO",
                "pid": "22855372",
                "hs": 0,
                "rmid": "216001386",
                "rtid": "260161823",
                "refundable": true,
                "available_rooms": 2,
                "score": 239,
                "sno": "446",
                "cont": {
                    "_id": "600d4155a1bc371b80fcfb55",
                    "name": "Wyndham Garden Ajman Corniche",
                    "ratings": "4.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.408626,
                            "longitude": 55.433127
                        }
                    },
                    "externalId": "22855372",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/23000000/22860000/22855400/22855372/78e02aea_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "POOL",
                        "BAR",
                        "POOL"
                    ],
                    "guestreview": {
                        "cleaniness": "4.7",
                        "staff_service": "4.4",
                        "amenities": "4.6",
                        "conditions_facilities": "4.6"
                    },
                    "rat": "4.0",
                    "urat": "4.5",
                    "expediareview": 31,
                    "pid": "22855372",
                    "line1": "Sheikh Humaid Bin Rashid-",
                    "city": "Ajman",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Ajman Beach - 0.7 km / 0.4 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 3858.75,
                "dwtf": 15430.08,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 763.54,
                "com_disc": 0,
                "rtf": "11571.00",
                "rdwtf": "15430.00"
            },
            {
                "tid": "T0K9E8bz44vDH6bg",
                "pid": "1696177",
                "hs": 0,
                "rmid": "314071313",
                "rtid": "381938855",
                "refundable": true,
                "available_rooms": 2147483647,
                "score": 592,
                "sno": "497",
                "cont": {
                    "_id": "600d4216a1bc371b80fe1752",
                    "name": "Raffles Dubai",
                    "ratings": "5.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.227805,
                            "longitude": 55.320973
                        }
                    },
                    "externalId": "1696177",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/2000000/1700000/1696200/1696177/3074e41c_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "POOL",
                        "BAR",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.9",
                        "staff_service": "4.9",
                        "amenities": "4.8",
                        "conditions_facilities": "4.8"
                    },
                    "rat": "5.0",
                    "urat": "4.8",
                    "expediareview": 716,
                    "pid": "1696177",
                    "line1": "Sheikh Rashid Road, Wafi",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Wafi City Mall - 0.4 km / 0.2 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Breakfast"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 3858.75,
                "dwtf": 18886.54,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 1146.13,
                "com_disc": 0,
                "rtf": "15028.00",
                "rdwtf": "18887.00"
            },
            {
                "tid": "POG2T1setKzSq0oi",
                "pid": "1198022",
                "hs": 0,
                "rmid": "200174723",
                "rtid": "383428366",
                "refundable": false,
                "available_rooms": 4,
                "score": 718,
                "sno": "585",
                "cont": {
                    "_id": "600d3f9da1bc371b80faafce",
                    "name": "Jumeirah Al Qasr",
                    "ratings": "5.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.13197,
                            "longitude": 55.18411
                        }
                    },
                    "externalId": "1198022",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/2000000/1200000/1198100/1198022/34835e64_b.jpg",
                    "ha": [
                        "BAR",
                        "GYM",
                        "POOL",
                        "POOL"
                    ],
                    "guestreview": {
                        "cleaniness": "4.9",
                        "staff_service": "4.8",
                        "amenities": "4.8",
                        "conditions_facilities": "4.8"
                    },
                    "rat": "5.0",
                    "urat": "4.8",
                    "expediareview": 645,
                    "pid": "1198022",
                    "line1": "Jumeirah Al Qasr-Madinat Jumeirah",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Madinat Jumeirah - 0.1 km / 0.1 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 3858.75,
                "dwtf": 37672.91,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 2580.44,
                "com_disc": 0,
                "rtf": "33814.00",
                "rdwtf": "37673.00"
            },
            {
                "tid": "lzI17OKFtS3tipwH",
                "pid": "1876527",
                "hs": 0,
                "rmid": "200915451",
                "rtid": "204668425",
                "refundable": true,
                "available_rooms": 3,
                "score": 245,
                "sno": "222",
                "cont": {
                    "_id": "600d3f4aa1bc371b80fa436c",
                    "name": "Star Metro Deira Hotel Apartments",
                    "ratings": "4.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.270378,
                            "longitude": 55.322647
                        }
                    },
                    "externalId": "1876527",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/2000000/1880000/1876600/1876527/28ecc62b_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "POOL",
                        "POOL"
                    ],
                    "guestreview": {
                        "cleaniness": "3.6",
                        "staff_service": "3.7",
                        "amenities": "3.3",
                        "conditions_facilities": "3.5"
                    },
                    "rat": "4.0",
                    "urat": "3.6",
                    "expediareview": 310,
                    "pid": "1876527",
                    "line1": "Salahuddin Road",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Reef Mall - 0.4 km / 0.2 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 3776.65,
                "dwtf": 8938.35,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 395.73,
                "com_disc": 0,
                "rtf": "5162.00",
                "rdwtf": "8938.00"
            },
            {
                "tid": "4MPFhkpCR0LOoSCN",
                "pid": "2699605",
                "hs": 0,
                "rmid": "200229442",
                "rtid": "201345899",
                "refundable": false,
                "available_rooms": 1,
                "score": 345,
                "sno": "161",
                "cont": {
                    "_id": "600d3ee0a1bc371b80f9b1c4",
                    "name": "Signature Hotel Apartments & Spa",
                    "ratings": "0.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.06826,
                            "longitude": 55.131668
                        }
                    },
                    "externalId": "2699605",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/3000000/2700000/2699700/2699605/452b8d2e_b.jpg",
                    "ha": [
                        "POOL",
                        "POOL",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "3.8",
                        "staff_service": "3.9",
                        "amenities": "3.5",
                        "conditions_facilities": "3.6"
                    },
                    "rat": "0.0",
                    "urat": "3.8",
                    "expediareview": 307,
                    "pid": "2699605",
                    "line1": "Marina Waterfront, JBR, Dubai Marina",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "The Walk - 1.1 km / 0.7 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 3612.45,
                "dwtf": 8095.99,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 342.36,
                "com_disc": 0,
                "rtf": "4484.00",
                "rdwtf": "8096.00"
            },
            {
                "tid": "dgTET1cc7cZsuBWD",
                "pid": "3802792",
                "hs": 0,
                "rmid": "213019917",
                "rtid": "240321437",
                "refundable": false,
                "available_rooms": 154,
                "score": 626,
                "sno": "323",
                "cont": {
                    "_id": "600d4332a1bc371b80ff9110",
                    "name": "Oaks Ibn Battuta Gate Dubai",
                    "ratings": "0.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.041535,
                            "longitude": 55.115803
                        }
                    },
                    "externalId": "3802792",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/4000000/3810000/3802800/3802792/0caab956_b.jpg",
                    "ha": [
                        "BAR",
                        "GYM",
                        "POOL",
                        "POOL",
                        "BAR"
                    ],
                    "guestreview": {
                        "cleaniness": "4.6",
                        "staff_service": "4.6",
                        "amenities": "4.4",
                        "conditions_facilities": "4.5"
                    },
                    "rat": "0.0",
                    "urat": "4.5",
                    "expediareview": 992,
                    "pid": "3802792",
                    "line1": "Off Sheikh Zayed Road",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Ibn Battuta Mall - 0.7 km / 0.5 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 3448.25,
                "dwtf": 10297.12,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 525.45,
                "com_disc": 0,
                "rtf": "6849.00",
                "rdwtf": "10297.00"
            },
            {
                "tid": "ukHnIXoIarDDD2Xv",
                "pid": "38931821",
                "hs": 0,
                "rmid": "216952291",
                "rtid": "266229813",
                "refundable": true,
                "available_rooms": 3,
                "score": 191,
                "sno": "202",
                "cont": {
                    "_id": "600d3e29a1bc371b80f8a1ab",
                    "name": "sleep ’n fly Sleep Lounge – Dubai Airport, A-Gates (Terminal 3)",
                    "ratings": "0.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.249366,
                            "longitude": 55.359748
                        }
                    },
                    "externalId": "38931821",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/39000000/38940000/38931900/38931821/31716bfd_b.jpg",
                    "ha": [
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.0",
                        "staff_service": "4.0",
                        "amenities": "2.9",
                        "conditions_facilities": "3.4"
                    },
                    "rat": "0.0",
                    "urat": "3.4",
                    "expediareview": 28,
                    "pid": "38931821",
                    "line1": "Dubai International Airport",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Dubai Tennis Stadium - 2.6 km / 1.6 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 3284.04,
                "dwtf": 8178.91,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 394.91,
                "com_disc": 0,
                "rtf": "4895.00",
                "rdwtf": "8179.00"
            },
            {
                "tid": "hncAoXXKRLWtEv6e",
                "pid": "74807407",
                "hs": 0,
                "rmid": "316072487",
                "rtid": "385365998",
                "refundable": false,
                "available_rooms": 8,
                "score": 64,
                "sno": "35",
                "cont": {
                    "_id": "62506a8a2aee3e1bb50b6694",
                    "ratings": "0.0",
                    "name": "OYO 800 Moon Creek Hotel",
                    "location": {
                        "coordinates": {
                            "latitude": 25.267709,
                            "longitude": 55.302177
                        },
                        "obfuscatedCoordinates": {
                            "latitude": 25.264182,
                            "longitude": 55.301663
                        },
                        "obfuscationRequired": false
                    },
                    "externalId": "74807407",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/75000000/74810000/74807500/74807407/ea3f1921_b.jpg",
                    "ha": [
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": 0,
                        "staff_service": 0,
                        "amenities": 0,
                        "conditions_facilities": 0
                    },
                    "rat": "0.0",
                    "urat": "",
                    "expediareview": 0,
                    "pid": "74807407",
                    "line1": "Al Kabeer St",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Spice Souk - 0.5 km / 0.3 mi "
                },
                "hdtype": "HOTELHD",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 3201.94,
                "dwtf": 6453.15,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 279.96,
                "com_disc": 0,
                "rtf": "3251.00",
                "rdwtf": "6453.00"
            },
            {
                "tid": "SZKfTbldtrw7v4NX",
                "pid": "38990076",
                "hs": 0,
                "rmid": "216650012",
                "rtid": "264212655",
                "refundable": true,
                "available_rooms": 6,
                "score": 722,
                "sno": "442",
                "cont": {
                    "_id": "600d3ecca1bc371b80f99534",
                    "name": "Andaz by Hyatt – Palm Jumeirah",
                    "ratings": "5.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.115355,
                            "longitude": 55.140408
                        }
                    },
                    "externalId": "38990076",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/39000000/39000000/38990100/38990076/e121c72b_b.jpg",
                    "ha": [
                        "BAR",
                        "GYM",
                        "POOL",
                        "POOL"
                    ],
                    "guestreview": {
                        "cleaniness": "4.7",
                        "staff_service": "4.2",
                        "amenities": "4.9",
                        "conditions_facilities": "4.4"
                    },
                    "rat": "5.0",
                    "urat": "4.3",
                    "expediareview": 28,
                    "pid": "38990076",
                    "line1": "The Palm Jumeirah",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "American University of Dubai (AUD) - 4 km / 2.5 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 3201.94,
                "dwtf": 14507.26,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 856.31,
                "com_disc": 0,
                "rtf": "11305.00",
                "rdwtf": "14507.00"
            },
            {
                "tid": "m1iuS97dRnCLfTew",
                "pid": "1378847",
                "hs": 0,
                "rmid": "211805314",
                "rtid": "232106830",
                "refundable": false,
                "available_rooms": 8,
                "score": 672,
                "sno": "576",
                "cont": {
                    "_id": "600d3f42a1bc371b80fa396b",
                    "name": "Kempinski Mall Of The Emirates",
                    "ratings": "5.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.117942,
                            "longitude": 55.196862
                        }
                    },
                    "externalId": "1378847",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/2000000/1380000/1378900/1378847/404a3244_b.jpg",
                    "ha": [
                        "BAR",
                        "POOL",
                        "POOL",
                        "BAR",
                        "POOL"
                    ],
                    "guestreview": {
                        "cleaniness": "4.6",
                        "staff_service": "4.5",
                        "amenities": "4.7",
                        "conditions_facilities": "4.5"
                    },
                    "rat": "5.0",
                    "urat": "4.5",
                    "expediareview": 805,
                    "pid": "1378847",
                    "line1": "Sheikh Zayed Road",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Mall of the Emirates - 0.5 km / 0.3 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 3201.94,
                "dwtf": 31431.59,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 2197.03,
                "com_disc": 0,
                "rtf": "28230.00",
                "rdwtf": "31432.00"
            },
            {
                "tid": "mDcuERUgqNQHp4S0",
                "pid": "20142741",
                "hs": 0,
                "rmid": "315917226",
                "rtid": "385058665",
                "refundable": false,
                "available_rooms": 5,
                "score": 70,
                "sno": "30",
                "cont": {
                    "_id": "62506a122aee3e1bb50b65ee",
                    "ratings": "1.0",
                    "name": "OYO 338 Transworld Hotel",
                    "location": {
                        "coordinates": {
                            "latitude": 25.269696,
                            "longitude": 55.311773
                        },
                        "obfuscationRequired": false
                    },
                    "externalId": "20142741",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/21000000/20150000/20142800/20142741/3aed856b_b.jpg",
                    "ha": [
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "2.3",
                        "staff_service": "1.8",
                        "amenities": 0,
                        "conditions_facilities": "2.2"
                    },
                    "rat": "1.0",
                    "urat": "2.0",
                    "expediareview": 6,
                    "pid": "20142741",
                    "line1": "Al Makhtoum Road",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Fish Roundabout - 0.5 km / 0.3 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 3119.84,
                "dwtf": 6293.05,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 267.65,
                "com_disc": 0,
                "rtf": "3173.00",
                "rdwtf": "6293.00"
            },
            {
                "tid": "GeiV110IRyzP7kxJ",
                "pid": "9517628",
                "hs": 0,
                "rmid": "315946154",
                "rtid": "385124971",
                "refundable": false,
                "available_rooms": 14,
                "score": 305,
                "sno": "150",
                "cont": {
                    "_id": "600d3fb7a1bc371b80facd53",
                    "name": "Signature Hotel Al Barsha",
                    "ratings": "0.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.117398,
                            "longitude": 55.194942
                        }
                    },
                    "externalId": "9517628",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/10000000/9520000/9517700/9517628/2dbf7a5b_b.jpg",
                    "ha": [
                        "POOL",
                        "WIFI",
                        "GYM"
                    ],
                    "guestreview": {
                        "cleaniness": "4.1",
                        "staff_service": "3.7",
                        "amenities": "3.0",
                        "conditions_facilities": "3.7"
                    },
                    "rat": "0.0",
                    "urat": "3.8",
                    "expediareview": 98,
                    "pid": "9517628",
                    "line1": "Al Barsha 1",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Mall of the Emirates - 0.6 km / 0.4 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 3037.74,
                "dwtf": 7428.51,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 334.97,
                "com_disc": 0,
                "rtf": "4391.00",
                "rdwtf": "7429.00"
            },
            {
                "tid": "2mQmdpfT45TCyug8",
                "pid": "66960178",
                "hs": 0,
                "rmid": "314136298",
                "rtid": "386550669",
                "refundable": true,
                "available_rooms": 63,
                "score": 245,
                "sno": "310",
                "cont": {
                    "_id": "62506a382aee3e1bb50b6621",
                    "ratings": "4.0",
                    "name": "Revier Dubai",
                    "location": {
                        "coordinates": {
                            "latitude": 25.179407,
                            "longitude": 55.272084
                        },
                        "obfuscationRequired": false
                    },
                    "externalId": "66960178",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/67000000/66970000/66960200/66960178/07749f59_b.jpg",
                    "ha": [
                        "GYM",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.3",
                        "staff_service": "4.1",
                        "amenities": "4.3",
                        "conditions_facilities": "4.5"
                    },
                    "rat": "4.0",
                    "urat": "4.1",
                    "expediareview": 16,
                    "pid": "66960178",
                    "line1": "Al Asayel Street, Business Bay",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Dubai Water Canal - 0.5 km / 0.3 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 3037.74,
                "dwtf": 9650.98,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 463.05,
                "com_disc": 0,
                "rtf": "6613.00",
                "rdwtf": "9651.00"
            },
            {
                "tid": "tJmPFUguDh5VAELo",
                "pid": "1722824",
                "hs": 0,
                "rmid": "200328996",
                "rtid": "202029754",
                "refundable": false,
                "available_rooms": 48,
                "score": 206,
                "sno": "27",
                "cont": {
                    "_id": "600d4022a1bc371b80fb5ab4",
                    "name": "OYO 367 Eureka Hotel",
                    "ratings": "2.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.261046,
                            "longitude": 55.325413
                        }
                    },
                    "externalId": "1722824",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/2000000/1730000/1722900/1722824/e205a9af_b.jpg",
                    "ha": [
                        "BAR",
                        "POOL",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "2.8",
                        "staff_service": "2.9",
                        "amenities": "3.7",
                        "conditions_facilities": "2.5"
                    },
                    "rat": "2.0",
                    "urat": "2.8",
                    "expediareview": 328,
                    "pid": "1722824",
                    "line1": "Al Rigga Road, Deira",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Al Ghurair Centre - 1.3 km / 0.8 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 2955.64,
                "dwtf": 6073.84,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 262.72,
                "com_disc": 0,
                "rtf": "3118.00",
                "rdwtf": "6074.00"
            },
            {
                "tid": "G9571P6Q7RdQW6Vu",
                "pid": "33010119",
                "hs": 0,
                "rmid": "218139626",
                "rtid": "274269336",
                "refundable": true,
                "available_rooms": 81,
                "score": 357,
                "sno": "397",
                "cont": {
                    "_id": "600d41b2a1bc371b80fd8186",
                    "name": "Vida Creek Harbour",
                    "ratings": "4.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.205406,
                            "longitude": 55.34441
                        }
                    },
                    "externalId": "33010119",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/34000000/33020000/33010200/33010119/45a41c21_b.jpg",
                    "ha": [
                        "POOL"
                    ],
                    "guestreview": {
                        "cleaniness": "4.6",
                        "staff_service": "4.3",
                        "amenities": "4.6",
                        "conditions_facilities": "4.7"
                    },
                    "rat": "4.0",
                    "urat": "4.6",
                    "expediareview": 20,
                    "pid": "33010119",
                    "line1": "Dubai Harbour Creek",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Dubai Water Canal - 9.5 km / 5.9 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 2955.64,
                "dwtf": 11698.59,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 669.12,
                "com_disc": 0,
                "rtf": "8743.00",
                "rdwtf": "11699.00"
            },
            {
                "tid": "qH3ZOBor1ksa42Mu",
                "pid": "6915556",
                "hs": 0,
                "rmid": "216953231",
                "rtid": "266233420",
                "refundable": true,
                "available_rooms": 2,
                "score": 520,
                "sno": "398",
                "cont": {
                    "_id": "600d426ba1bc371b80fe8ab4",
                    "name": "Ramada Hotel and Suites by Wyndham Dubai JBR",
                    "ratings": "4.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.074565,
                            "longitude": 55.130847
                        }
                    },
                    "externalId": "6915556",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/7000000/6920000/6915600/6915556/426d5c37_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.0",
                        "staff_service": "4.1",
                        "amenities": "3.9",
                        "conditions_facilities": "3.9"
                    },
                    "rat": "4.0",
                    "urat": "3.9",
                    "expediareview": 347,
                    "pid": "6915556",
                    "line1": "The Walk, Jumeirah Beach",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "The Walk - 0.1 km / 0.1 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 2955.64,
                "dwtf": 11765.91,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 596.87,
                "com_disc": 0,
                "rtf": "8810.00",
                "rdwtf": "11766.00"
            },
            {
                "tid": "UBMoSscrGAFocU2K",
                "pid": "1590517",
                "hs": 0,
                "rmid": "213609703",
                "rtid": "244117550",
                "refundable": true,
                "available_rooms": 3,
                "score": 542,
                "sno": "443",
                "cont": {
                    "_id": "600d407ba1bc371b80fbd531",
                    "name": "MELIA DESERT PALM RESORT & HOTEL",
                    "ratings": "5.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.169814,
                            "longitude": 55.444893
                        }
                    },
                    "externalId": "1590517",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/2000000/1600000/1590600/1590517/5d331ed4_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "POOL",
                        "BAR",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.4",
                        "staff_service": "4.3",
                        "amenities": "5.0",
                        "conditions_facilities": "4.4"
                    },
                    "rat": "5.0",
                    "urat": "4.4",
                    "expediareview": 109,
                    "pid": "1590517",
                    "line1": "Al Awir Road",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Dubai Safari Park - 0.8 km / 0.5 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 2955.64,
                "dwtf": 14377.54,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 871.91,
                "com_disc": 0,
                "rtf": "11422.00",
                "rdwtf": "14378.00"
            },
            {
                "tid": "BTwZMa8ms5GBiCHp",
                "pid": "18352604",
                "hs": 0,
                "rmid": "202195696",
                "rtid": "211230307",
                "refundable": false,
                "available_rooms": 2147483647,
                "score": 754,
                "sno": "570",
                "cont": {
                    "_id": "600d43d8a1bc371b80005b49",
                    "name": "Rixos Premium Dubai JBR",
                    "ratings": "5.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.080029,
                            "longitude": 55.135992
                        }
                    },
                    "externalId": "18352604",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/19000000/18360000/18352700/18352604/628a25bb_b.jpg",
                    "ha": [
                        "BAR",
                        "GYM",
                        "POOL",
                        "POOL",
                        "BAR"
                    ],
                    "guestreview": {
                        "cleaniness": "4.6",
                        "staff_service": "4.3",
                        "amenities": "4.6",
                        "conditions_facilities": "4.6"
                    },
                    "rat": "5.0",
                    "urat": "4.4",
                    "expediareview": 352,
                    "pid": "18352604",
                    "line1": "The Walk, Jumeirah Beach Residence",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "The Walk - 0.1 km / 0.1 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 2955.64,
                "dwtf": 28558.05,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 1954.01,
                "com_disc": 0,
                "rtf": "25602.00",
                "rdwtf": "28558.00"
            },
            {
                "tid": "pry78kwe00e9qDCZ",
                "pid": "16083123",
                "hs": 0,
                "rmid": "201670960",
                "rtid": "208335392",
                "refundable": false,
                "available_rooms": 46,
                "score": 452,
                "sno": "120",
                "cont": {
                    "_id": "600d405da1bc371b80fbacb8",
                    "name": "dusitD2 kenz Hotel Dubai",
                    "ratings": "4.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.099447,
                            "longitude": 55.17262
                        }
                    },
                    "externalId": "16083123",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/17000000/16090000/16083200/16083123/20d392c2_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.6",
                        "staff_service": "4.5",
                        "amenities": "4.3",
                        "conditions_facilities": "4.6"
                    },
                    "rat": "4.0",
                    "urat": "4.5",
                    "expediareview": 310,
                    "pid": "16083123",
                    "line1": "Sheikh Zayed Road, Barsha Heights",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Jebel Ali Race Course - 1.3 km / 0.8 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 2873.54,
                "dwtf": 6979.41,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 312.81,
                "com_disc": 0,
                "rtf": "4106.00",
                "rdwtf": "6979.00"
            },
            {
                "tid": "nbRueByHka1GmhaM",
                "pid": "41484136",
                "hs": 0,
                "rmid": "214827976",
                "rtid": "386050067",
                "refundable": true,
                "available_rooms": 60,
                "score": 416,
                "sno": "126",
                "cont": {
                    "_id": "600d438ea1bc371b80fffa49",
                    "name": "Avani Ibn Battuta Dubai Hotel",
                    "ratings": "3.5",
                    "location": {
                        "coordinates": {
                            "latitude": 25.041884,
                            "longitude": 55.115901
                        }
                    },
                    "externalId": "41484136",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/42000000/41490000/41484200/41484136/c5951266_b.jpg",
                    "ha": [
                        "BAR",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.7",
                        "staff_service": "4.7",
                        "amenities": "4.3",
                        "conditions_facilities": "4.7"
                    },
                    "rat": "3.5",
                    "urat": "4.6",
                    "expediareview": 27,
                    "pid": "41484136",
                    "line1": "Sheikh Zayed Road",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Ibn Battuta Mall - 0.7 km / 0.4 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 2873.54,
                "dwtf": 7027.85,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 316.91,
                "com_disc": 0,
                "rtf": "4154.00",
                "rdwtf": "7028.00"
            },
            {
                "tid": "sUdJGet3RDK7v4iT",
                "pid": "17905889",
                "hs": 0,
                "rmid": "219339390",
                "rtid": "282185680",
                "refundable": false,
                "available_rooms": 5,
                "score": 347,
                "sno": "568",
                "cont": {
                    "_id": "600d3de4a1bc371b80f8472e",
                    "name": "The Oberoi Beach Resort, Al Zorah",
                    "ratings": "5.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.43743,
                            "longitude": 55.463322
                        }
                    },
                    "externalId": "17905889",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/18000000/17910000/17905900/17905889/eed4902e_b.jpg",
                    "ha": [
                        "BAR",
                        "POOL",
                        "POOL",
                        "POOL"
                    ],
                    "guestreview": {
                        "cleaniness": "4.8",
                        "staff_service": "4.7",
                        "amenities": "4.9",
                        "conditions_facilities": "4.8"
                    },
                    "rat": "5.0",
                    "urat": "4.7",
                    "expediareview": 59,
                    "pid": "17905889",
                    "line1": "Al Zorah Al Ittihad Road",
                    "city": "Ajman",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Al Zorah Golf Club - 2.5 km / 1.6 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Breakfast"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 2873.54,
                "dwtf": 27782.19,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 2034.47,
                "com_disc": 0,
                "rtf": "24909.00",
                "rdwtf": "27782.00"
            },
            {
                "tid": "l0hBTz6r0tHTNUDR",
                "pid": "33010100",
                "hs": 0,
                "rmid": "220538242",
                "rtid": "290151379",
                "refundable": true,
                "available_rooms": 27,
                "score": 444,
                "sno": "374",
                "cont": {
                    "_id": "600d3fb6a1bc371b80facb34",
                    "name": "Vida Emirates Hills",
                    "ratings": "4.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.078288,
                            "longitude": 55.15839
                        }
                    },
                    "externalId": "33010100",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/34000000/33020000/33010100/33010100/80d84d0e_b.jpg",
                    "ha": [
                        "BAR",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.5",
                        "staff_service": "4.3",
                        "amenities": "4.3",
                        "conditions_facilities": "4.6"
                    },
                    "rat": "4.0",
                    "urat": "4.2",
                    "expediareview": 31,
                    "pid": "33010100",
                    "line1": "The Hills, Emirates Hills",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "American University of Dubai (AUD) - 2.2 km / 1.4 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 2791.44,
                "dwtf": 10903.85,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 620.68,
                "com_disc": 0,
                "rtf": "8112.00",
                "rdwtf": "10904.00"
            },
            {
                "tid": "T2k2GLriGetuR6tE",
                "pid": "5527177",
                "hs": 0,
                "rmid": "210609062",
                "rtid": "387728882",
                "refundable": false,
                "available_rooms": 2147483647,
                "score": 789,
                "sno": "505",
                "cont": {
                    "_id": "600d3e08a1bc371b80f87060",
                    "name": "Fairmont The Palm",
                    "ratings": "5.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.110454,
                            "longitude": 55.141261
                        }
                    },
                    "externalId": "5527177",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/6000000/5530000/5527200/5527177/7bf1e348_b.jpg",
                    "ha": [
                        "BAR",
                        "GYM",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.7",
                        "staff_service": "4.7",
                        "amenities": "4.5",
                        "conditions_facilities": "4.4"
                    },
                    "rat": "5.0",
                    "urat": "4.5",
                    "expediareview": 979,
                    "pid": "5527177",
                    "line1": "Palm Jumeirah",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "American University of Dubai (AUD) - 3.5 km / 2.2 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 2791.44,
                "dwtf": 18385.72,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 845.64,
                "com_disc": 0,
                "rtf": "15594.00",
                "rdwtf": "18386.00"
            },
            {
                "tid": "98GbGmNtD5PRWblm",
                "pid": "18732016",
                "hs": 0,
                "rmid": "202021560",
                "rtid": "210314702",
                "refundable": true,
                "available_rooms": 2147483647,
                "score": 504,
                "sno": "366",
                "cont": {
                    "_id": "600d3e37a1bc371b80f8b4c6",
                    "name": "Mercure Suites Dubai Barsha Heights",
                    "ratings": "3.5",
                    "location": {
                        "coordinates": {
                            "latitude": 25.1014,
                            "longitude": 55.1728
                        }
                    },
                    "externalId": "18732016",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/19000000/18740000/18732100/18732016/9306264d_b.jpg",
                    "ha": [
                        "BAR",
                        "GYM",
                        "POOL",
                        "POOL",
                        "BAR"
                    ],
                    "guestreview": {
                        "cleaniness": "4.4",
                        "staff_service": "4.4",
                        "amenities": "4.4",
                        "conditions_facilities": "4.3"
                    },
                    "rat": "3.5",
                    "urat": "4.3",
                    "expediareview": 91,
                    "pid": "18732016",
                    "line1": "Sheikh Zayed Road",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Jebel Ali Race Course - 1.7 km / 1.1 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 2709.34,
                "dwtf": 10614.85,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 605.91,
                "com_disc": 0,
                "rtf": "7906.00",
                "rdwtf": "10615.00"
            },
            {
                "tid": "2GM4m46gLa6p4z40",
                "pid": "13062822",
                "hs": 0,
                "rmid": "201364547",
                "rtid": "211072582",
                "refundable": false,
                "available_rooms": 9,
                "score": 108,
                "sno": "371",
                "cont": {
                    "_id": "600d42f4a1bc371b80ff47ed",
                    "name": "Bin Majid Flamingo",
                    "ratings": "3.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.58406,
                            "longitude": 55.578184
                        }
                    },
                    "externalId": "13062822",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/14000000/13070000/13062900/13062822/9cad4e48_b.jpg",
                    "ha": [
                        "BAR",
                        "POOL",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.4",
                        "staff_service": "4.4",
                        "amenities": "2.0",
                        "conditions_facilities": "3.6"
                    },
                    "rat": "3.0",
                    "urat": "3.9",
                    "expediareview": 8,
                    "pid": "13062822",
                    "line1": "Al Ghub Street",
                    "city": "Umm al Quwain",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Marine Research Center and Aquarium - 1.6 km / 1 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 2709.34,
                "dwtf": 10769.2,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 628.89,
                "com_disc": 0,
                "rtf": "8060.00",
                "rdwtf": "10769.00"
            },
            {
                "tid": "RdShgTtSHTpET947",
                "pid": "64434236",
                "hs": 0,
                "rmid": "314307137",
                "rtid": "383635112",
                "refundable": false,
                "available_rooms": 9,
                "score": 349,
                "sno": "85",
                "cont": {
                    "_id": "62506b122aee3e1bb50b678a",
                    "ratings": "0.0",
                    "name": "Radisson Dubai Damac Hills",
                    "location": {
                        "coordinates": {
                            "latitude": 25.019409,
                            "longitude": 55.24547
                        },
                        "obfuscationRequired": false
                    },
                    "externalId": "64434236",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/65000000/64440000/64434300/64434236/a217b7fa_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.7",
                        "staff_service": "4.5",
                        "amenities": "4.8",
                        "conditions_facilities": "4.8"
                    },
                    "rat": "0.0",
                    "urat": "4.5",
                    "expediareview": 24,
                    "pid": "64434236",
                    "line1": "Al Hebiah",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Trump International Golf Club, Dubai - 1 km / 0.6 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 2627.24,
                "dwtf": 6394.85,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 287.35,
                "com_disc": 0,
                "rtf": "3768.00",
                "rdwtf": "6395.00"
            },
            {
                "tid": "vEOr9XAA088zaqiR",
                "pid": "32187645",
                "hs": 0,
                "rmid": "215726306",
                "rtid": "258126716",
                "refundable": true,
                "available_rooms": 33,
                "score": 315,
                "sno": "93",
                "cont": {
                    "_id": "600d4081a1bc371b80fbdca3",
                    "name": "Studio M Arabian Plaza",
                    "ratings": "3.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.277082,
                            "longitude": 55.349859
                        }
                    },
                    "externalId": "32187645",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/33000000/32190000/32187700/32187645/cf64bdd0_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "POOL",
                        "BAR",
                        "POOL"
                    ],
                    "guestreview": {
                        "cleaniness": "4.4",
                        "staff_service": "4.4",
                        "amenities": "4.3",
                        "conditions_facilities": "4.4"
                    },
                    "rat": "3.0",
                    "urat": "4.4",
                    "expediareview": 58,
                    "pid": "32187645",
                    "line1": "Al Ittihad Road",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Al-Maktoum Stadium - 1.7 km / 1 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 2627.24,
                "dwtf": 6472.03,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 277.5,
                "com_disc": 0,
                "rtf": "3845.00",
                "rdwtf": "6472.00"
            },
            {
                "tid": "bsyLl41NIZVBtqzS",
                "pid": "72093154",
                "hs": 0,
                "rmid": "315740535",
                "rtid": "384426153",
                "refundable": false,
                "available_rooms": 2147483647,
                "score": 174,
                "sno": "419",
                "cont": {
                    "_id": "62506af82aee3e1bb50b6741",
                    "ratings": "0.0",
                    "name": "Hyde Hotel Dubai",
                    "location": {
                        "coordinates": {
                            "latitude": 25.181708,
                            "longitude": 55.279054
                        },
                        "obfuscatedCoordinates": {
                            "latitude": 25.182435,
                            "longitude": 55.280285
                        },
                        "obfuscationRequired": false
                    },
                    "externalId": "72093154",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/73000000/72100000/72093200/72093154/9cd9593d_b.jpg",
                    "ha": [
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": 0,
                        "staff_service": 0,
                        "amenities": 0,
                        "conditions_facilities": 0
                    },
                    "rat": "0.0",
                    "urat": "5.0",
                    "expediareview": 1,
                    "pid": "72093154",
                    "line1": "Dubai Business Bay Dubai",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Dubai Water Canal - 0.3 km / 0.2 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 2627.24,
                "dwtf": 12342.26,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 733.16,
                "com_disc": 0,
                "rtf": "9715.00",
                "rdwtf": "12342.00"
            },
            {
                "tid": "6wIu4INolHlMO5pp",
                "pid": "18381583",
                "hs": 0,
                "rmid": "314307236",
                "rtid": "383636566",
                "refundable": true,
                "available_rooms": 9,
                "score": 272,
                "sno": "425",
                "cont": {
                    "_id": "600d4037a1bc371b80fb774d",
                    "name": "Radisson Blu Hotel, Ajman",
                    "ratings": "5.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.402677,
                            "longitude": 55.483774
                        }
                    },
                    "externalId": "18381583",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/19000000/18390000/18381600/18381583/3badb444_b.jpg",
                    "ha": [
                        "BAR",
                        "GYM",
                        "POOL",
                        "POOL",
                        "BAR"
                    ],
                    "guestreview": {
                        "cleaniness": "4.7",
                        "staff_service": "4.7",
                        "amenities": "4.3",
                        "conditions_facilities": "4.6"
                    },
                    "rat": "5.0",
                    "urat": "4.7",
                    "expediareview": 91,
                    "pid": "18381583",
                    "line1": "Off Al Ittihad Street (E11)",
                    "city": "Ajman",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "City Centre Ajman - 1.3 km / 0.8 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 2627.24,
                "dwtf": 12749.48,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 752.87,
                "com_disc": 0,
                "rtf": "10122.00",
                "rdwtf": "12749.00"
            },
            {
                "tid": "85MtelpK7PT9CnuO",
                "pid": "13351655",
                "hs": 0,
                "rmid": "321325629",
                "rtid": "388590168",
                "refundable": false,
                "available_rooms": 42,
                "score": 372,
                "sno": "65",
                "cont": {
                    "_id": "600d42caa1bc371b80ff0e58",
                    "name": "Signature 1 Hotel Tecom",
                    "ratings": "4.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.096279,
                            "longitude": 55.173751
                        }
                    },
                    "externalId": "13351655",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/14000000/13360000/13351700/13351655/455c00a3_b.jpg",
                    "ha": [
                        "BAR",
                        "GYM",
                        "POOL",
                        "POOL",
                        "BAR"
                    ],
                    "guestreview": {
                        "cleaniness": "4.2",
                        "staff_service": "4.3",
                        "amenities": "4.1",
                        "conditions_facilities": "4.2"
                    },
                    "rat": "4.0",
                    "urat": "4.1",
                    "expediareview": 267,
                    "pid": "13351655",
                    "line1": "Al Thanayah 1, Tecom",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Jebel Ali Race Course - 0.9 km / 0.5 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 2545.13,
                "dwtf": 6167.43,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 276.68,
                "com_disc": 0,
                "rtf": "3622.00",
                "rdwtf": "6167.00"
            },
            {
                "tid": "ntl4TJ71fAS0Wh4v",
                "pid": "1764839",
                "hs": 0,
                "rmid": "202048957",
                "rtid": "226114237",
                "refundable": false,
                "available_rooms": 13,
                "score": 553,
                "sno": "337",
                "cont": {
                    "_id": "600d4202a1bc371b80fdfdc3",
                    "name": "The H Dubai",
                    "ratings": "5.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.230015,
                            "longitude": 55.287273
                        }
                    },
                    "externalId": "1764839",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/2000000/1770000/1764900/1764839/fdcffdc4_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "POOL",
                        "BAR",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.5",
                        "staff_service": "4.4",
                        "amenities": "4.4",
                        "conditions_facilities": "4.3"
                    },
                    "rat": "5.0",
                    "urat": "4.3",
                    "expediareview": 449,
                    "pid": "1764839",
                    "line1": "One Sheikh Zayed Road",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Dubai International Convention and Exhibition Centre - 0.8 km / 0.5 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 2545.13,
                "dwtf": 9792.2,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 553.36,
                "com_disc": 0,
                "rtf": "7247.00",
                "rdwtf": "9792.00"
            },
            {
                "tid": "0tEB7p5h301zMByy",
                "pid": "1608859",
                "hs": 0,
                "rmid": "224377",
                "rtid": "387133562",
                "refundable": true,
                "available_rooms": 27,
                "score": 560,
                "sno": "562",
                "cont": {
                    "_id": "600d3db9a1bc371b80f818d0",
                    "name": "Manzil Downtown",
                    "ratings": "4.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.191339,
                            "longitude": 55.277326
                        }
                    },
                    "externalId": "1608859",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/2000000/1610000/1608900/1608859/a8b4a420_b.jpg",
                    "ha": [
                        "BAR",
                        "GYM",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.7",
                        "staff_service": "4.6",
                        "amenities": "4.4",
                        "conditions_facilities": "4.6"
                    },
                    "rat": "4.0",
                    "urat": "4.6",
                    "expediareview": 761,
                    "pid": "1608859",
                    "line1": "Sheikh Mohammed Bin Rashid",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Souk al Bahar - 0.2 km / 0.1 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Breakfast"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 2545.13,
                "dwtf": 24952.17,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 1714.27,
                "com_disc": 0,
                "rtf": "22407.00",
                "rdwtf": "24952.00"
            },
            {
                "tid": "11DkLdyWI67JHxpO",
                "pid": "1794962",
                "hs": 0,
                "rmid": "232281",
                "rtid": "569824",
                "refundable": true,
                "available_rooms": 3,
                "score": 341,
                "sno": "171",
                "cont": {
                    "_id": "600d4166a1bc371b80fd0b71",
                    "name": "Copthorne Hotel Dubai",
                    "ratings": "4.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.254557,
                            "longitude": 55.328187
                        }
                    },
                    "externalId": "1794962",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/2000000/1800000/1795000/1794962/e762bc4e_b.jpg",
                    "ha": [
                        "BAR",
                        "GYM",
                        "POOL",
                        "POOL"
                    ],
                    "guestreview": {
                        "cleaniness": "3.7",
                        "staff_service": "3.9",
                        "amenities": "3.8",
                        "conditions_facilities": "3.6"
                    },
                    "rat": "4.0",
                    "urat": "3.7",
                    "expediareview": 406,
                    "pid": "1794962",
                    "line1": "Port Saeed Road",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "City Centre Deira - 1.2 km / 0.7 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 2463.03,
                "dwtf": 7028.68,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 330.05,
                "com_disc": 0,
                "rtf": "4566.00",
                "rdwtf": "7029.00"
            },
            {
                "tid": "xLyqCuJsOCMyZtoZ",
                "pid": "69378647",
                "hs": 0,
                "rmid": "314445930",
                "rtid": "383464334",
                "refundable": true,
                "available_rooms": 171,
                "score": 273,
                "sno": "338",
                "cont": {
                    "_id": "62506b262aee3e1bb50b67c5",
                    "ratings": "4.0",
                    "name": "The First Collection at Jumeirah Village Circle",
                    "location": {
                        "coordinates": {
                            "latitude": 25.054741,
                            "longitude": 55.204809
                        },
                        "obfuscationRequired": false
                    },
                    "externalId": "69378647",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/70000000/69380000/69378700/69378647/333442e5_b.jpg",
                    "ha": [
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "5.0",
                        "staff_service": "4.8",
                        "amenities": "4.3",
                        "conditions_facilities": "4.8"
                    },
                    "rat": "4.0",
                    "urat": "4.5",
                    "expediareview": 18,
                    "pid": "69378647",
                    "line1": "Street 1, JVC",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Dubai Sports City - 7.6 km / 4.7 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 2463.03,
                "dwtf": 9732.26,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 556.65,
                "com_disc": 0,
                "rtf": "7269.00",
                "rdwtf": "9732.00"
            },
            {
                "tid": "SSp84QhSow2aALkI",
                "pid": "27190443",
                "hs": 0,
                "rmid": "220226535",
                "rtid": "391309823",
                "refundable": true,
                "available_rooms": 17,
                "score": 486,
                "sno": "379",
                "cont": {
                    "_id": "600d4042a1bc371b80fb8586",
                    "name": "Grand Millennium Business Bay",
                    "ratings": "5.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.182503,
                            "longitude": 55.274056
                        }
                    },
                    "externalId": "27190443",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/28000000/27200000/27190500/27190443/62c6f514_b.jpg",
                    "ha": [
                        "BAR",
                        "GYM",
                        "POOL",
                        "POOL",
                        "BAR"
                    ],
                    "guestreview": {
                        "cleaniness": "4.6",
                        "staff_service": "4.5",
                        "amenities": "4.4",
                        "conditions_facilities": "4.5"
                    },
                    "rat": "5.0",
                    "urat": "4.5",
                    "expediareview": 130,
                    "pid": "27190443",
                    "line1": "Al Abraj Street, Business Bay",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Souk al Bahar - 3.7 km / 2.3 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 2463.03,
                "dwtf": 10846.38,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 626.43,
                "com_disc": 0,
                "rtf": "8383.00",
                "rdwtf": "10846.00"
            },
            {
                "tid": "SBCbLxBaET8dzrws",
                "pid": "48241914",
                "hs": 0,
                "rmid": "229501772",
                "rtid": "270250447",
                "refundable": true,
                "available_rooms": 3,
                "score": 274,
                "sno": "557",
                "cont": {
                    "_id": "600d4033a1bc371b80fb7213",
                    "name": "LEGOLAND Hotel Dubai",
                    "location": {
                        "coordinates": {
                            "latitude": 24.923946,
                            "longitude": 55.006095
                        }
                    },
                    "externalId": "48241914",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/49000000/48250000/48242000/48241914/dae5c060_b.jpg",
                    "ha": [
                        "BAR",
                        "POOL"
                    ],
                    "guestreview": {
                        "cleaniness": 0,
                        "staff_service": 0,
                        "amenities": 0,
                        "conditions_facilities": 0
                    },
                    "rat": "",
                    "urat": "",
                    "expediareview": 0,
                    "ratings": "",
                    "pid": "48241914",
                    "line1": "Sheikh Zayed Rd",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Bollywood Parks™ Dubai - 0.2 km / 0.1 mi "
                },
                "hdtype": "HOTELHD",
                "brdbs": [
                    "Breakfast"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 2463.03,
                "dwtf": 23959.56,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 1640.38,
                "com_disc": 0,
                "rtf": "21497.00",
                "rdwtf": "23960.00"
            },
            {
                "tid": "tuG7J0GXA91wA8B1",
                "pid": "1162761",
                "hs": 0,
                "rmid": "320819390",
                "rtid": "387335256",
                "refundable": false,
                "available_rooms": 5,
                "score": 792,
                "sno": "559",
                "cont": {
                    "_id": "600d431aa1bc371b80ff79c4",
                    "name": "Bab Al Shams Desert Resort and Spa",
                    "ratings": "5.0",
                    "location": {
                        "coordinates": {
                            "latitude": 24.81637,
                            "longitude": 55.231321
                        }
                    },
                    "externalId": "1162761",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/2000000/1170000/1162800/1162761/61aaf713_b.jpg",
                    "ha": [
                        "BAR",
                        "GYM",
                        "POOL",
                        "BAR",
                        "POOL"
                    ],
                    "guestreview": {
                        "cleaniness": "4.6",
                        "staff_service": "4.6",
                        "amenities": "4.6",
                        "conditions_facilities": "4.5"
                    },
                    "rat": "5.0",
                    "urat": "4.6",
                    "expediareview": 712,
                    "pid": "1162761",
                    "line1": "Al Qudra Road",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Dubailand - 41.1 km / 25.5 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Breakfast"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 2463.03,
                "dwtf": 24080.25,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 1649.41,
                "com_disc": 0,
                "rtf": "21617.00",
                "rdwtf": "24080.00"
            },
            {
                "tid": "3xK8S5GzxWirKaiN",
                "pid": "15508095",
                "hs": 0,
                "rmid": "220237663",
                "rtid": "288153100",
                "refundable": true,
                "available_rooms": 10,
                "score": 424,
                "sno": "226",
                "cont": {
                    "_id": "600d4059a1bc371b80fba599",
                    "name": "Metropolitan Hotel Dubai",
                    "ratings": "0.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.132963,
                            "longitude": 55.212988
                        }
                    },
                    "externalId": "15508095",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/16000000/15510000/15508100/15508095/58d59252_b.jpg",
                    "ha": [
                        "BAR",
                        "GYM",
                        "POOL",
                        "POOL",
                        "BAR"
                    ],
                    "guestreview": {
                        "cleaniness": "4.4",
                        "staff_service": "4.3",
                        "amenities": "4.1",
                        "conditions_facilities": "4.3"
                    },
                    "rat": "0.0",
                    "urat": "4.2",
                    "expediareview": 300,
                    "pid": "15508095",
                    "line1": "Sheikh Zayed Road, Exit 41",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Umm Suqeim Beach - 2.6 km / 1.6 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 2380.93,
                "dwtf": 7641.97,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 401.47,
                "com_disc": 0,
                "rtf": "5261.00",
                "rdwtf": "7642.00"
            },
            {
                "tid": "6nhmukhHQO291AT9",
                "pid": "18464497",
                "hs": 0,
                "rmid": "202035230",
                "rtid": "210426415",
                "refundable": true,
                "available_rooms": 2147483647,
                "score": 377,
                "sno": "330",
                "cont": {
                    "_id": "600d3e68a1bc371b80f90ad3",
                    "name": "Mercure Hotel Apartments Dubai Barsha Heights",
                    "ratings": "4.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.101281,
                            "longitude": 55.172866
                        }
                    },
                    "externalId": "18464497",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/19000000/18470000/18464500/18464497/8e399e5a_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "POOL",
                        "BAR",
                        "POOL"
                    ],
                    "guestreview": {
                        "cleaniness": "4.2",
                        "staff_service": "4.1",
                        "amenities": "4.0",
                        "conditions_facilities": "3.9"
                    },
                    "rat": "4.0",
                    "urat": "4.0",
                    "expediareview": 153,
                    "pid": "18464497",
                    "line1": "Sheikh Zayed Road, Al Barsha",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Jebel Ali Race Course - 1.7 km / 1.1 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 2380.93,
                "dwtf": 9383.33,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 536.94,
                "com_disc": 0,
                "rtf": "7002.00",
                "rdwtf": "9383.00"
            },
            {
                "tid": "bvpqKNTnkIhX5LfR",
                "pid": "2348266",
                "hs": 0,
                "rmid": "200603986",
                "rtid": "236237161",
                "refundable": true,
                "available_rooms": 11,
                "score": 206,
                "sno": "167",
                "cont": {
                    "_id": "600d3d84a1bc371b80f7bc1b",
                    "name": "Holiday International Sharjah",
                    "ratings": "4.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.339581,
                            "longitude": 55.385829
                        }
                    },
                    "externalId": "2348266",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/3000000/2350000/2348300/2348266/6d1062f8_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "POOL",
                        "POOL",
                        "BAR"
                    ],
                    "guestreview": {
                        "cleaniness": "3.8",
                        "staff_service": "3.7",
                        "amenities": "3.0",
                        "conditions_facilities": "3.5"
                    },
                    "rat": "4.0",
                    "urat": "3.7",
                    "expediareview": 108,
                    "pid": "2348266",
                    "line1": "Al Majaz 1",
                    "city": "Sharjah",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Al Noor Mosque - 0.6 km / 0.4 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 2298.83,
                "dwtf": 6849.69,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 304.6,
                "com_disc": 0,
                "rtf": "4551.00",
                "rdwtf": "6850.00"
            },
            {
                "tid": "8RGteFTfPXSVcPud",
                "pid": "7610",
                "hs": 0,
                "rmid": "321907227",
                "rtid": "390994676",
                "refundable": false,
                "available_rooms": 17,
                "score": 642,
                "sno": "172",
                "cont": {
                    "_id": "600d4302a1bc371b80ff5c78",
                    "name": "Crowne Plaza Dubai",
                    "ratings": "5.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.220069,
                            "longitude": 55.28055
                        }
                    },
                    "externalId": "7610",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/1000000/10000/7700/7610/b353f819_b.jpg",
                    "ha": [
                        "BAR",
                        "GYM",
                        "POOL",
                        "POOL",
                        "BAR"
                    ],
                    "guestreview": {
                        "cleaniness": "4.3",
                        "staff_service": "4.3",
                        "amenities": "4.0",
                        "conditions_facilities": "3.9"
                    },
                    "rat": "5.0",
                    "urat": "4.0",
                    "expediareview": 789,
                    "pid": "7610",
                    "line1": "Sheikh Zayed Road",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Dubai International Financial Centre - 1 km / 0.6 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 2298.83,
                "dwtf": 6868.58,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 339.08,
                "com_disc": 0,
                "rtf": "4570.00",
                "rdwtf": "6869.00"
            },
            {
                "tid": "w06Had9mH6fInmnv",
                "pid": "177932",
                "hs": 0,
                "rmid": "210603194",
                "rtid": "224094231",
                "refundable": false,
                "available_rooms": 5,
                "score": 597,
                "sno": "291",
                "cont": {
                    "_id": "600d3fcea1bc371b80fae9d2",
                    "name": "Le Meridien Dubai Hotel & Conference Centre",
                    "ratings": "5.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.249397,
                            "longitude": 55.347909
                        }
                    },
                    "externalId": "177932",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/1000000/180000/178000/177932/7a46a84f_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.5",
                        "staff_service": "4.5",
                        "amenities": "4.6",
                        "conditions_facilities": "4.3"
                    },
                    "rat": "5.0",
                    "urat": "4.4",
                    "expediareview": 987,
                    "pid": "177932",
                    "line1": "Airport Road",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Dubai Tennis Stadium - 1.4 km / 0.9 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 2298.83,
                "dwtf": 8508.96,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 467.98,
                "com_disc": 0,
                "rtf": "6210.00",
                "rdwtf": "8509.00"
            },
            {
                "tid": "eEBivZM4UCB8HS74",
                "pid": "8255648",
                "hs": 0,
                "rmid": "321461782",
                "rtid": "388895112",
                "refundable": false,
                "available_rooms": 6,
                "score": 279,
                "sno": "318",
                "cont": {
                    "_id": "600d3fada1bc371b80fac161",
                    "name": "Somewhere Hotel Apartment",
                    "ratings": "0.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.254259,
                            "longitude": 55.32976
                        }
                    },
                    "externalId": "8255648",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/9000000/8260000/8255700/8255648/d787ae77_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.4",
                        "staff_service": "4.4",
                        "amenities": "4.5",
                        "conditions_facilities": "4.3"
                    },
                    "rat": "0.0",
                    "urat": "4.4",
                    "expediareview": 277,
                    "pid": "8255648",
                    "line1": "Al Ittihad Road, Port Saeed, Deira",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "City Centre Deira - 1 km / 0.6 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 2298.83,
                "dwtf": 9001.56,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 511.49,
                "com_disc": 0,
                "rtf": "6703.00",
                "rdwtf": "9002.00"
            },
            {
                "tid": "pppN1r2x7VJP2FWI",
                "pid": "35064054",
                "hs": 0,
                "rmid": "321907076",
                "rtid": "390994206",
                "refundable": false,
                "available_rooms": 13,
                "score": 533,
                "sno": "142",
                "cont": {
                    "_id": "600d4219a1bc371b80fe1a67",
                    "name": "Crowne Plaza Dubai Apartments",
                    "ratings": "5.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.219976,
                            "longitude": 55.280384
                        }
                    },
                    "externalId": "35064054",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/36000000/35070000/35064100/35064054/abc5db13_b.jpg",
                    "ha": [
                        "BAR",
                        "GYM",
                        "POOL",
                        "POOL",
                        "BAR"
                    ],
                    "guestreview": {
                        "cleaniness": "4.5",
                        "staff_service": "4.6",
                        "amenities": "4.3",
                        "conditions_facilities": "4.2"
                    },
                    "rat": "5.0",
                    "urat": "4.2",
                    "expediareview": 35,
                    "pid": "35064054",
                    "line1": "Sheikh Zayed Al Nahyan Road",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Dubai International Financial Centre - 0.9 km / 0.6 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 2216.73,
                "dwtf": 6545.1,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 321.02,
                "com_disc": 0,
                "rtf": "4328.00",
                "rdwtf": "6545.00"
            },
            {
                "tid": "VZ9JaEDURdAaWhGr",
                "pid": "22937965",
                "hs": 0,
                "rmid": "216601577",
                "rtid": "264006660",
                "refundable": true,
                "available_rooms": 8,
                "score": 653,
                "sno": "388",
                "cont": {
                    "_id": "600d428aa1bc371b80feb35d",
                    "name": "Gevora Hotel",
                    "ratings": "4.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.21252,
                            "longitude": 55.276812
                        }
                    },
                    "externalId": "22937965",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/23000000/22940000/22938000/22937965/338df1d5_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "POOL",
                        "POOL"
                    ],
                    "guestreview": {
                        "cleaniness": "4.0",
                        "staff_service": "4.1",
                        "amenities": "4.0",
                        "conditions_facilities": "4.0"
                    },
                    "rat": "4.0",
                    "urat": "4.0",
                    "expediareview": 535,
                    "pid": "22937965",
                    "line1": "Sheikh Zayed Road, Next to Al Attar",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Dubai International Financial Centre - 0.5 km / 0.3 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 2216.73,
                "dwtf": 10719.12,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 649.42,
                "com_disc": 0,
                "rtf": "8502.00",
                "rdwtf": "10719.00"
            },
            {
                "tid": "Tf1PswTkIQGAEgeB",
                "pid": "7228208",
                "hs": 0,
                "rmid": "200475565",
                "rtid": "202645140",
                "refundable": true,
                "available_rooms": 1,
                "score": 128,
                "sno": "393",
                "cont": {
                    "_id": "600d3e69a1bc371b80f90ba3",
                    "name": "Royal Residence Resort",
                    "ratings": "3.5",
                    "location": {
                        "coordinates": {
                            "latitude": 25.508222,
                            "longitude": 55.545257
                        }
                    },
                    "externalId": "7228208",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/8000000/7230000/7228300/7228208/ca64bd36_b.jpg",
                    "ha": [
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "3.1",
                        "staff_service": "3.1",
                        "amenities": 0,
                        "conditions_facilities": "3.0"
                    },
                    "rat": "3.5",
                    "urat": "3.1",
                    "expediareview": 80,
                    "pid": "7228208",
                    "line1": "King Faisal Street",
                    "city": "Umm al Quwain",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Al Hamriyah Free Zone - 7.2 km / 4.5 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 2216.73,
                "dwtf": 10836.52,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 673.23,
                "com_disc": 0,
                "rtf": "8620.00",
                "rdwtf": "10837.00"
            },
            {
                "tid": "EC1CzG6wlgvhuRN3",
                "pid": "3123973",
                "hs": 0,
                "rmid": "201328970",
                "rtid": "242239142",
                "refundable": false,
                "available_rooms": 32,
                "score": 347,
                "sno": "181",
                "cont": {
                    "_id": "600d4238a1bc371b80fe467d",
                    "name": "Premier Inn Dubai Silicon Oasis",
                    "ratings": "3.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.122954,
                            "longitude": 55.374346
                        }
                    },
                    "externalId": "3123973",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/4000000/3130000/3124000/3123973/51eb387a_b.jpg",
                    "ha": [
                        "BAR",
                        "GYM",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.4",
                        "staff_service": "4.4",
                        "amenities": "4.1",
                        "conditions_facilities": "4.2"
                    },
                    "rat": "3.0",
                    "urat": "4.1",
                    "expediareview": 682,
                    "pid": "3123973",
                    "line1": "Silicon Oasis",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Dubai Silicon Oasis - 1 km / 0.6 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Breakfast"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 2134.63,
                "dwtf": 6783.19,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 353.86,
                "com_disc": 0,
                "rtf": "4649.00",
                "rdwtf": "6783.00"
            },
            {
                "tid": "0oyBZvXcBHlvrfZV",
                "pid": "3071573",
                "hs": 0,
                "rmid": "201803087",
                "rtid": "242232000",
                "refundable": true,
                "available_rooms": 45,
                "score": 388,
                "sno": "184",
                "cont": {
                    "_id": "600d3f87a1bc371b80fa97e7",
                    "name": "Avani Deira Dubai Hotel",
                    "ratings": "5.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.270743,
                            "longitude": 55.330001
                        }
                    },
                    "externalId": "3071573",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/4000000/3080000/3071600/3071573/d25fe29a_b.jpg",
                    "ha": [
                        "BAR",
                        "GYM",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.4",
                        "staff_service": "4.4",
                        "amenities": "4.1",
                        "conditions_facilities": "4.2"
                    },
                    "rat": "5.0",
                    "urat": "4.2",
                    "expediareview": 998,
                    "pid": "3071573",
                    "line1": "Abu Baker Al Siddque & Sallahuddin Road",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Hamarain Centre - 0.4 km / 0.3 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 2134.63,
                "dwtf": 6831.63,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 358.78,
                "com_disc": 0,
                "rtf": "4697.00",
                "rdwtf": "6832.00"
            },
            {
                "tid": "zvCikJBRLVSJTFak",
                "pid": "8835794",
                "hs": 0,
                "rmid": "200764157",
                "rtid": "204730355",
                "refundable": false,
                "available_rooms": 28,
                "score": 446,
                "sno": "375",
                "cont": {
                    "_id": "600d43aaa1bc371b80001e56",
                    "name": "Armada BlueBay",
                    "ratings": "4.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.074958,
                            "longitude": 55.144951
                        }
                    },
                    "externalId": "8835794",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/9000000/8840000/8835800/8835794/ace7204c_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "POOL",
                        "BAR",
                        "POOL"
                    ],
                    "guestreview": {
                        "cleaniness": "3.8",
                        "staff_service": "3.7",
                        "amenities": "3.4",
                        "conditions_facilities": "3.6"
                    },
                    "rat": "4.0",
                    "urat": "3.5",
                    "expediareview": 527,
                    "pid": "8835794",
                    "line1": "Armada Towers, Jumeira Lake Towers",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Dubai Marina Mall - 2.1 km / 1.3 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 2134.63,
                "dwtf": 10292.19,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 622.33,
                "com_disc": 0,
                "rtf": "8158.00",
                "rdwtf": "10292.00"
            },
            {
                "tid": "WwaLdUTNyWUP1rgT",
                "pid": "1844998",
                "hs": 0,
                "rmid": "200207193",
                "rtid": "204777538",
                "refundable": false,
                "available_rooms": 10,
                "score": 239,
                "sno": "477",
                "cont": {
                    "_id": "600d431ca1bc371b80ff7c01",
                    "name": "Saffron Boutique Hotel",
                    "ratings": "4.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.276963,
                            "longitude": 55.314635
                        }
                    },
                    "externalId": "1844998",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/2000000/1850000/1845000/1844998/c3fd04e6_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "POOL",
                        "POOL"
                    ],
                    "guestreview": {
                        "cleaniness": "3.4",
                        "staff_service": "3.5",
                        "amenities": "3.7",
                        "conditions_facilities": "3.1"
                    },
                    "rat": "4.0",
                    "urat": "3.2",
                    "expediareview": 122,
                    "pid": "1844998",
                    "line1": "Burj Nahar Intersection – Al Murar–Deira",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Fish Roundabout - 0.8 km / 0.5 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 2134.63,
                "dwtf": 15935,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 1053.36,
                "com_disc": 0,
                "rtf": "13800.00",
                "rdwtf": "15935.00"
            },
            {
                "tid": "ZFxuK7OaXkJTBTUZ",
                "pid": "9098862",
                "hs": 0,
                "rmid": "218749511",
                "rtid": "278183176",
                "refundable": true,
                "available_rooms": 6,
                "score": 284,
                "sno": "42",
                "cont": {
                    "_id": "600d3f8ba1bc371b80fa9bdb",
                    "name": "Landmark Premier Hotel",
                    "ratings": "4.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.262715,
                            "longitude": 55.32002
                        }
                    },
                    "externalId": "9098862",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/10000000/9100000/9098900/9098862/e96ef4a3_b.jpg",
                    "ha": [
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.1",
                        "staff_service": "4.2",
                        "amenities": "3.6",
                        "conditions_facilities": "4.0"
                    },
                    "rat": "4.0",
                    "urat": "4.0",
                    "expediareview": 106,
                    "pid": "9098862",
                    "line1": "40B Street",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Al Ghurair Centre - 0.7 km / 0.4 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 2052.53,
                "dwtf": 5417.03,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 256.98,
                "com_disc": 0,
                "rtf": "3365.00",
                "rdwtf": "5417.00"
            },
            {
                "tid": "xi6ScTAOGksB2p1J",
                "pid": "18313422",
                "hs": 0,
                "rmid": "314307284",
                "rtid": "383641121",
                "refundable": true,
                "available_rooms": 9,
                "score": 658,
                "sno": "355",
                "cont": {
                    "_id": "600d413ba1bc371b80fcdf0b",
                    "name": "Radisson Blu Hotel Dubai Waterfront",
                    "ratings": "5.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.186304,
                            "longitude": 55.268159
                        }
                    },
                    "externalId": "18313422",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/19000000/18320000/18313500/18313422/3f484883_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "POOL",
                        "BAR",
                        "POOL"
                    ],
                    "guestreview": {
                        "cleaniness": "4.6",
                        "staff_service": "4.5",
                        "amenities": "4.5",
                        "conditions_facilities": "4.5"
                    },
                    "rat": "5.0",
                    "urat": "4.4",
                    "expediareview": 454,
                    "pid": "18313422",
                    "line1": "Al Abraaj Street",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Souk al Bahar - 1.5 km / 0.9 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 2052.53,
                "dwtf": 9781.53,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 590.31,
                "com_disc": 0,
                "rtf": "7729.00",
                "rdwtf": "9782.00"
            },
            {
                "tid": "mTTOAGaExLrPPdUR",
                "pid": "850069",
                "hs": 0,
                "rmid": "315475125",
                "rtid": "384092334",
                "refundable": false,
                "available_rooms": 15,
                "score": 478,
                "sno": "519",
                "cont": {
                    "_id": "600d4141a1bc371b80fce629",
                    "name": "Hilton Dubai Creek",
                    "ratings": "5.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.259781,
                            "longitude": 55.318066
                        }
                    },
                    "externalId": "850069",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/1000000/860000/850100/850069/77b42760_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "POOL",
                        "BAR",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.6",
                        "staff_service": "4.5",
                        "amenities": "4.4",
                        "conditions_facilities": "4.4"
                    },
                    "rat": "5.0",
                    "urat": "4.4",
                    "expediareview": 687,
                    "pid": "850069",
                    "line1": "Baniyas Road",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Al Ghurair Centre - 1.3 km / 0.8 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 2052.53,
                "dwtf": 18642.7,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 1250.4,
                "com_disc": 0,
                "rtf": "16590.00",
                "rdwtf": "18643.00"
            },
            {
                "tid": "S8tSgsNfUDresbqK",
                "pid": "1453623",
                "hs": 0,
                "rmid": "172855",
                "rtid": "402294",
                "refundable": false,
                "available_rooms": 18,
                "score": 448,
                "sno": "106",
                "cont": {
                    "_id": "600d3eeda1bc371b80f9be36",
                    "name": "Grand Excelsior Hotel Bur Dubai",
                    "ratings": "4.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.250532,
                            "longitude": 55.292217
                        }
                    },
                    "externalId": "1453623",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/2000000/1460000/1453700/1453623/2e06b035_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "POOL",
                        "BAR",
                        "POOL"
                    ],
                    "guestreview": {
                        "cleaniness": "3.7",
                        "staff_service": "3.7",
                        "amenities": "3.6",
                        "conditions_facilities": "3.5"
                    },
                    "rat": "4.0",
                    "urat": "3.6",
                    "expediareview": 248,
                    "pid": "1453623",
                    "line1": "Kuwait Road - Bur Dubai",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "BurJuman Mall - 1.6 km / 1 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 1970.43,
                "dwtf": 5954.79,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 303.77,
                "com_disc": 0,
                "rtf": "3984.00",
                "rdwtf": "5955.00"
            },
            {
                "tid": "ktTaafUVyXrXprmK",
                "pid": "13300536",
                "hs": 0,
                "rmid": "202377504",
                "rtid": "282171115",
                "refundable": true,
                "available_rooms": 99,
                "score": 653,
                "sno": "265",
                "cont": {
                    "_id": "600d3de4a1bc371b80f84713",
                    "name": "Wyndham Dubai Marina",
                    "ratings": "4.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.06971,
                            "longitude": 55.128059
                        }
                    },
                    "externalId": "13300536",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/14000000/13310000/13300600/13300536/fe06b352_b.jpg",
                    "ha": [
                        "BAR",
                        "POOL",
                        "POOL",
                        "BAR",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.3",
                        "staff_service": "4.3",
                        "amenities": "4.2",
                        "conditions_facilities": "4.2"
                    },
                    "rat": "4.0",
                    "urat": "4.2",
                    "expediareview": 609,
                    "pid": "13300536",
                    "line1": "Al Seba Street",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "The Beach Mall - 1.3 km / 0.8 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 1970.43,
                "dwtf": 7700.26,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 387.52,
                "com_disc": 0,
                "rtf": "5730.00",
                "rdwtf": "7700.00"
            },
            {
                "tid": "U6S8OsfWo7HCXAO9",
                "pid": "45263327",
                "hs": 0,
                "rmid": "314144428",
                "rtid": "383641075",
                "refundable": true,
                "available_rooms": 9,
                "score": 324,
                "sno": "349",
                "cont": {
                    "_id": "600d43d5a1bc371b80005788",
                    "name": "Radisson Red Dubai Silicon Oasis",
                    "ratings": "4.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.118437,
                            "longitude": 55.379942
                        }
                    },
                    "externalId": "45263327",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/46000000/45270000/45263400/45263327/673ba23b_b.jpg",
                    "ha": [
                        "BAR",
                        "POOL",
                        "WIFI",
                        "GYM"
                    ],
                    "guestreview": {
                        "cleaniness": "4.1",
                        "staff_service": "3.9",
                        "amenities": 0,
                        "conditions_facilities": "3.7"
                    },
                    "rat": "4.0",
                    "urat": "3.9",
                    "expediareview": 7,
                    "pid": "45263327",
                    "line1": "Silicon Park, Silicon Oasis",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Dubai Silicon Oasis - 1 km / 0.6 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 1970.43,
                "dwtf": 9527.01,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 576.35,
                "com_disc": 0,
                "rtf": "7557.00",
                "rdwtf": "9527.00"
            },
            {
                "tid": "Ekcn5FXabgRTCtOG",
                "pid": "16433491",
                "hs": 0,
                "rmid": "201749386",
                "rtid": "212169168",
                "refundable": false,
                "available_rooms": 17,
                "score": 0,
                "sno": "45",
                "cont": {
                    "_id": "600d3e31a1bc371b80f8ae2d",
                    "name": "Gateway Hotel",
                    "ratings": "3.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.260381,
                            "longitude": 55.291073
                        }
                    },
                    "externalId": "16433491",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/17000000/16440000/16433500/16433491/84d8e4ac_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.1",
                        "staff_service": "4.1",
                        "amenities": "4.1",
                        "conditions_facilities": "3.8"
                    },
                    "rat": "3.0",
                    "urat": "3.9",
                    "expediareview": 100,
                    "pid": "16433491",
                    "line1": "Al Souqe Al Kabeer, Khalid Bin Al Waleed",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Textile Souk - 0.6 km / 0.4 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 1888.33,
                "dwtf": 5269.25,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 258.62,
                "com_disc": 0,
                "rtf": "3381.00",
                "rdwtf": "5269.00"
            },
            {
                "tid": "4avpiZiMoJP4mLbA",
                "pid": "1548322",
                "hs": 0,
                "rmid": "189244",
                "rtid": "234136289",
                "refundable": true,
                "available_rooms": 13,
                "score": 399,
                "sno": "129",
                "cont": {
                    "_id": "600d3df1a1bc371b80f85648",
                    "name": "Majestic City Retreat Hotel",
                    "ratings": "4.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.253404,
                            "longitude": 55.293802
                        }
                    },
                    "externalId": "1548322",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/2000000/1550000/1548400/1548322/8d03454d_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "POOL",
                        "BAR",
                        "POOL"
                    ],
                    "guestreview": {
                        "cleaniness": "4.2",
                        "staff_service": "4.3",
                        "amenities": "4.1",
                        "conditions_facilities": "4.0"
                    },
                    "rat": "4.0",
                    "urat": "4.1",
                    "expediareview": 992,
                    "pid": "1548322",
                    "line1": "Mankhool Road",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Meena Bazaar - 1.2 km / 0.8 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 1888.33,
                "dwtf": 6059.06,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 318.55,
                "com_disc": 0,
                "rtf": "4171.00",
                "rdwtf": "6059.00"
            },
            {
                "tid": "VkfOlJHdw5e2SbNK",
                "pid": "30560988",
                "hs": 0,
                "rmid": "321473537",
                "rtid": "388909307",
                "refundable": true,
                "available_rooms": 28,
                "score": 623,
                "sno": "233",
                "cont": {
                    "_id": "600d4367a1bc371b80ffc806",
                    "name": "Stella Di Mare Dubai Marina Hotel",
                    "ratings": "5.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.06883,
                            "longitude": 55.1341
                        }
                    },
                    "externalId": "30560988",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/31000000/30570000/30561000/30560988/3a7c38c2_b.jpg",
                    "ha": [
                        "BAR",
                        "GYM",
                        "POOL",
                        "BAR",
                        "POOL"
                    ],
                    "guestreview": {
                        "cleaniness": "4.4",
                        "staff_service": "4.4",
                        "amenities": "4.3",
                        "conditions_facilities": "4.3"
                    },
                    "rat": "5.0",
                    "urat": "4.3",
                    "expediareview": 442,
                    "pid": "30560988",
                    "line1": "Al Mattla'ee  Street",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "The Walk - 0.9 km / 0.5 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 1888.33,
                "dwtf": 7222.43,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 407.22,
                "com_disc": 0,
                "rtf": "5334.00",
                "rdwtf": "7222.00"
            },
            {
                "tid": "qAWK9maaoPXaOFIq",
                "pid": "44564297",
                "hs": 0,
                "rmid": "219114367",
                "rtid": "387123045",
                "refundable": true,
                "available_rooms": 59,
                "score": 325,
                "sno": "33",
                "cont": {
                    "_id": "600d3fb4a1bc371b80fac95f",
                    "name": "The Manor by JA",
                    "ratings": "4.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.018584,
                            "longitude": 55.14229
                        }
                    },
                    "externalId": "44564297",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/45000000/44570000/44564300/44564297/e1da2f06_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.8",
                        "staff_service": "4.5",
                        "amenities": "4.4",
                        "conditions_facilities": "4.6"
                    },
                    "rat": "4.0",
                    "urat": "4.2",
                    "expediareview": 16,
                    "pid": "44564297",
                    "line1": "Al Furjan Jebel Ali 1",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Dubai Expo 2020 - 8.2 km / 5.1 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 1806.22,
                "dwtf": 5032.8,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 245.48,
                "com_disc": 0,
                "rtf": "3227.00",
                "rdwtf": "5033.00"
            },
            {
                "tid": "Fgtk5TE5VT5uSFtl",
                "pid": "315936",
                "hs": 0,
                "rmid": "210305996",
                "rtid": "222129562",
                "refundable": true,
                "available_rooms": 5,
                "score": 318,
                "sno": "111",
                "cont": {
                    "_id": "600d41d6a1bc371b80fdc672",
                    "name": "Excelsior Hotel Downtown",
                    "ratings": "4.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.264,
                            "longitude": 55.3253
                        }
                    },
                    "externalId": "315936",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/1000000/320000/316000/315936/d6cd6476_b.jpg",
                    "ha": [
                        "BAR",
                        "POOL",
                        "WIFI",
                        "GYM"
                    ],
                    "guestreview": {
                        "cleaniness": "3.5",
                        "staff_service": "3.7",
                        "amenities": "3.8",
                        "conditions_facilities": "3.2"
                    },
                    "rat": "4.0",
                    "urat": "3.4",
                    "expediareview": 179,
                    "pid": "315936",
                    "line1": "Al Rigga Street",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Al Ghurair Centre - 1 km / 0.6 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 1806.22,
                "dwtf": 5864.48,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 310.34,
                "com_disc": 0,
                "rtf": "4058.00",
                "rdwtf": "5864.00"
            },
            {
                "tid": "kCXVSgKZqR669uTL",
                "pid": "1210397",
                "hs": 0,
                "rmid": "200075773",
                "rtid": "380345991",
                "refundable": false,
                "available_rooms": 8,
                "score": 369,
                "sno": "254",
                "cont": {
                    "_id": "600d4202a1bc371b80fdfe69",
                    "name": "Hotel Riviera",
                    "ratings": "4.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.267175,
                            "longitude": 55.306543
                        }
                    },
                    "externalId": "1210397",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/2000000/1220000/1210400/1210397/1d1bc884_b.jpg",
                    "ha": [
                        "GYM",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.4",
                        "staff_service": "4.5",
                        "amenities": "4.2",
                        "conditions_facilities": "4.1"
                    },
                    "rat": "4.0",
                    "urat": "4.4",
                    "expediareview": 468,
                    "pid": "1210397",
                    "line1": "Baniyas Road 5",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Deira Twin Towers Shopping Centre - 0.2 km / 0.1 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 1806.22,
                "dwtf": 7406.34,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 427.75,
                "com_disc": 0,
                "rtf": "5600.00",
                "rdwtf": "7406.00"
            },
            {
                "tid": "qoAgIPtEiC0P8if8",
                "pid": "31761212",
                "hs": 0,
                "rmid": "315511716",
                "rtid": "383778904",
                "refundable": true,
                "available_rooms": 2,
                "score": 426,
                "sno": "320",
                "cont": {
                    "_id": "600d3df0a1bc371b80f85535",
                    "name": "Leva Hotel and Suites, Mazaya Centre",
                    "ratings": "4.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.198518,
                            "longitude": 55.265532
                        }
                    },
                    "externalId": "31761212",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/32000000/31770000/31761300/31761212/3a936f84_b.jpg",
                    "ha": [
                        "POOL",
                        "POOL",
                        "WIFI",
                        "GYM"
                    ],
                    "guestreview": {
                        "cleaniness": "4.5",
                        "staff_service": "4.4",
                        "amenities": "4.2",
                        "conditions_facilities": "4.3"
                    },
                    "rat": "4.0",
                    "urat": "4.3",
                    "expediareview": 136,
                    "pid": "31761212",
                    "line1": "Sheikh Zayed Rd",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Emaar Square - 1.2 km / 0.7 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 1806.22,
                "dwtf": 8568.89,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 516.42,
                "com_disc": 0,
                "rtf": "6763.00",
                "rdwtf": "8569.00"
            },
            {
                "tid": "9kncMQUr5FRx9Jvz",
                "pid": "45847029",
                "hs": 0,
                "rmid": "322712922",
                "rtid": "391666297",
                "refundable": true,
                "available_rooms": 34,
                "score": 404,
                "sno": "328",
                "cont": {
                    "_id": "600d3ed2a1bc371b80f99de0",
                    "name": "Grand Cosmopolitan Hotel",
                    "ratings": "5.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.113158,
                            "longitude": 55.192815
                        }
                    },
                    "externalId": "45847029",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/46000000/45850000/45847100/45847029/5cb7a605_b.jpg",
                    "ha": [
                        "POOL",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "5.0",
                        "staff_service": "5.0",
                        "amenities": 0,
                        "conditions_facilities": "5.0"
                    },
                    "rat": "5.0",
                    "urat": "5.0",
                    "expediareview": 1,
                    "pid": "45847029",
                    "line1": "Al Barsha1, behind Mashreq metro station",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Fatima Abdullah Mohammed Rasheed Mosque - 0.3 km / 0.2 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 1806.22,
                "dwtf": 8709.28,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 526.27,
                "com_disc": 0,
                "rtf": "6903.00",
                "rdwtf": "8709.00"
            },
            {
                "tid": "Slvc55eQqJqAVKuX",
                "pid": "910165",
                "hs": 0,
                "rmid": "200284526",
                "rtid": "201781100",
                "refundable": false,
                "available_rooms": 22,
                "score": 529,
                "sno": "409",
                "cont": {
                    "_id": "600d4154a1bc371b80fcf966",
                    "name": "Hyatt Regency Dubai",
                    "ratings": "5.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.278457,
                            "longitude": 55.303673
                        }
                    },
                    "externalId": "910165",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/1000000/920000/910200/910165/8f4d7d72_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "POOL",
                        "BAR",
                        "POOL"
                    ],
                    "guestreview": {
                        "cleaniness": "4.5",
                        "staff_service": "4.4",
                        "amenities": "4.2",
                        "conditions_facilities": "4.4"
                    },
                    "rat": "5.0",
                    "urat": "4.4",
                    "expediareview": 992,
                    "pid": "910165",
                    "line1": "Deira Corniche Alkhaleej Road",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Naif Souq - 1 km / 0.6 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 1806.22,
                "dwtf": 11160,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 707.71,
                "com_disc": 0,
                "rtf": "9354.00",
                "rdwtf": "11160.00"
            },
            {
                "tid": "lASe9M95eeOpI3Vo",
                "pid": "5944430",
                "hs": 0,
                "rmid": "200757569",
                "rtid": "254028836",
                "refundable": true,
                "available_rooms": 2,
                "score": 252,
                "sno": "504",
                "cont": {
                    "_id": "600d3e9ea1bc371b80f95ac1",
                    "name": "Bahi Ajman Palace Hotel",
                    "ratings": "5.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.416658,
                            "longitude": 55.43928
                        }
                    },
                    "externalId": "5944430",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/6000000/5950000/5944500/5944430/92327c61_b.jpg",
                    "ha": [
                        "BAR",
                        "POOL",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.3",
                        "staff_service": "4.3",
                        "amenities": "4.1",
                        "conditions_facilities": "4.3"
                    },
                    "rat": "5.0",
                    "urat": "4.2",
                    "expediareview": 187,
                    "pid": "5944430",
                    "line1": "Sheikh Humaid Bin Rashid Al Nuaimi St.",
                    "city": "Ajman",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Ajman Museum - 0.9 km / 0.6 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 1806.22,
                "dwtf": 17379.98,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 1157.63,
                "com_disc": 0,
                "rtf": "15574.00",
                "rdwtf": "17380.00"
            },
            {
                "tid": "BExK8fWhgyHzM89B",
                "pid": "43989118",
                "hs": 0,
                "rmid": "315687524",
                "rtid": "384291738",
                "refundable": true,
                "available_rooms": 2147483647,
                "score": 281,
                "sno": "506",
                "cont": {
                    "_id": "600d3efca1bc371b80f9cee1",
                    "name": "Sofitel Dubai The Obelisk",
                    "ratings": "0.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.231201,
                            "longitude": 55.318607
                        }
                    },
                    "externalId": "43989118",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/44000000/43990000/43989200/43989118/b32b90ca_b.jpg",
                    "ha": [
                        "POOL",
                        "POOL",
                        "BAR",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": 0,
                        "staff_service": 0,
                        "amenities": 0,
                        "conditions_facilities": 0
                    },
                    "rat": "0.0",
                    "urat": "",
                    "expediareview": 0,
                    "pid": "43989118",
                    "line1": "Wafi - Sheikh Rashid Rd",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Wafi City Mall - 0.3 km / 0.2 mi "
                },
                "hdtype": "HOTELHD",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 1806.22,
                "dwtf": 17506.42,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 1198.68,
                "com_disc": 0,
                "rtf": "15700.00",
                "rdwtf": "17506.00"
            },
            {
                "tid": "z69XIlavhyBDaaWW",
                "pid": "7672819",
                "hs": 0,
                "rmid": "200723338",
                "rtid": "268153299",
                "refundable": true,
                "available_rooms": 6,
                "score": 398,
                "sno": "509",
                "cont": {
                    "_id": "600d413ba1bc371b80fcdf75",
                    "name": "Hyatt Place Dubai Al Rigga",
                    "ratings": "4.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.262958,
                            "longitude": 55.328023
                        }
                    },
                    "externalId": "7672819",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/8000000/7680000/7672900/7672819/3ad3df3c_b.jpg",
                    "ha": [
                        "BAR",
                        "POOL",
                        "POOL",
                        "BAR",
                        "POOL"
                    ],
                    "guestreview": {
                        "cleaniness": "4.5",
                        "staff_service": "4.3",
                        "amenities": "4.1",
                        "conditions_facilities": "4.3"
                    },
                    "rat": "4.0",
                    "urat": "4.3",
                    "expediareview": 697,
                    "pid": "7672819",
                    "line1": "45th St.",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Reef Mall - 1.1 km / 0.7 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Breakfast"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 1806.22,
                "dwtf": 17577.85,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 890.8,
                "com_disc": 0,
                "rtf": "15772.00",
                "rdwtf": "17578.00"
            },
            {
                "tid": "HckrudUo6pOrSONx",
                "pid": "48183630",
                "hs": 0,
                "rmid": "321749987",
                "rtid": "389410566",
                "refundable": true,
                "available_rooms": 22,
                "score": 232,
                "sno": "137",
                "cont": {
                    "_id": "600d41e0a1bc371b80fdd34c",
                    "name": "IntercityHotel Dubai Jaddaf Waterfront",
                    "location": {
                        "coordinates": {
                            "latitude": 25.227034,
                            "longitude": 55.338245
                        }
                    },
                    "externalId": "48183630",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/49000000/48190000/48183700/48183630/e68432b7_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": 0,
                        "staff_service": 0,
                        "amenities": 0,
                        "conditions_facilities": 0
                    },
                    "rat": "",
                    "urat": "",
                    "expediareview": 0,
                    "ratings": "",
                    "pid": "48183630",
                    "line1": "Al Jaddaf Waterfront, Bur Dubai",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Dubai Creek - 3.7 km / 2.3 mi "
                },
                "hdtype": "HOTELHD",
                "brdbs": [
                    "Breakfast"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 1724.12,
                "dwtf": 6018.83,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 329.23,
                "com_disc": 0,
                "rtf": "4295.00",
                "rdwtf": "6019.00"
            },
            {
                "tid": "tWygVHKiawGDFTy5",
                "pid": "31788666",
                "hs": 0,
                "rmid": "221730793",
                "rtid": "298131414",
                "refundable": true,
                "available_rooms": 34,
                "score": 318,
                "sno": "191",
                "cont": {
                    "_id": "600d3d83a1bc371b80f7ba2e",
                    "name": "Studio One Hotel",
                    "ratings": "4.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.042323,
                            "longitude": 55.251473
                        }
                    },
                    "externalId": "31788666",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/32000000/31790000/31788700/31788666/d67155ec_b.jpg",
                    "ha": [
                        "BAR",
                        "GYM",
                        "POOL",
                        "POOL"
                    ],
                    "guestreview": {
                        "cleaniness": "4.2",
                        "staff_service": "4.5",
                        "amenities": "4.4",
                        "conditions_facilities": "4.5"
                    },
                    "rat": "4.0",
                    "urat": "4.2",
                    "expediareview": 19,
                    "pid": "31788666",
                    "line1": "Dubai Studio City",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Dubailand - 2.4 km / 1.5 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 1724.12,
                "dwtf": 6536.07,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 366.99,
                "com_disc": 0,
                "rtf": "4812.00",
                "rdwtf": "6536.00"
            },
            {
                "tid": "lLdABlSaU4T2ZA4X",
                "pid": "72108064",
                "hs": 0,
                "rmid": "315736541",
                "rtid": "384419477",
                "refundable": true,
                "available_rooms": 7,
                "score": 279,
                "sno": "312",
                "cont": {
                    "_id": "62506b302aee3e1bb50b67de",
                    "ratings": "0.0",
                    "name": "Hyatt Centric Jumeirah Dubai",
                    "location": {
                        "coordinates": {
                            "latitude": 25.235072,
                            "longitude": 55.260023
                        },
                        "obfuscatedCoordinates": {
                            "latitude": 25.231657,
                            "longitude": 55.258673
                        },
                        "obfuscationRequired": false
                    },
                    "externalId": "72108064",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/73000000/72110000/72108100/72108064/600232b6_b.jpg",
                    "ha": [
                        "WIFI",
                        "GYM"
                    ],
                    "guestreview": {
                        "cleaniness": 0,
                        "staff_service": 0,
                        "amenities": 0,
                        "conditions_facilities": 0
                    },
                    "rat": "0.0",
                    "urat": "",
                    "expediareview": 0,
                    "pid": "72108064",
                    "line1": "La Mer Beach (Entrance North)",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "La Mer North Beach - 0.2 km / 0.1 mi "
                },
                "hdtype": "HOTELHD",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 1724.12,
                "dwtf": 8362.82,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 513.13,
                "com_disc": 0,
                "rtf": "6639.00",
                "rdwtf": "8363.00"
            },
            {
                "tid": "icrvb9HCMy2Htsmk",
                "pid": "39154422",
                "hs": 0,
                "rmid": "217536315",
                "rtid": "270235774",
                "refundable": true,
                "available_rooms": 19,
                "score": 397,
                "sno": "490",
                "cont": {
                    "_id": "600d3fcfa1bc371b80faeacd",
                    "name": "Hyatt Place Dubai Jumeirah Residences",
                    "ratings": "0.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.24625,
                            "longitude": 55.277975
                        }
                    },
                    "externalId": "39154422",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/40000000/39160000/39154500/39154422/49f9eee6_b.jpg",
                    "ha": [
                        "BAR",
                        "POOL",
                        "POOL",
                        "BAR",
                        "POOL"
                    ],
                    "guestreview": {
                        "cleaniness": "4.6",
                        "staff_service": "4.4",
                        "amenities": "4.4",
                        "conditions_facilities": "4.6"
                    },
                    "rat": "0.0",
                    "urat": "4.2",
                    "expediareview": 68,
                    "pid": "39154422",
                    "line1": "Al Mina Road",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Dubai Cruise Terminal - 2.1 km / 1.3 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 1724.12,
                "dwtf": 16265.05,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 1110.01,
                "com_disc": 0,
                "rtf": "14541.00",
                "rdwtf": "16265.00"
            },
            {
                "tid": "ufXuSkaTThUpUIpi",
                "pid": "3002123",
                "hs": 0,
                "rmid": "201992682",
                "rtid": "210167120",
                "refundable": true,
                "available_rooms": 1,
                "score": 556,
                "sno": "495",
                "cont": {
                    "_id": "600d4132a1bc371b80fcd508",
                    "name": "Ramada by Wyndham Downtown Dubai",
                    "ratings": "4.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.19371,
                            "longitude": 55.272446
                        }
                    },
                    "externalId": "3002123",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/4000000/3010000/3002200/3002123/d90edc06_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "POOL",
                        "POOL"
                    ],
                    "guestreview": {
                        "cleaniness": "4.3",
                        "staff_service": "4.3",
                        "amenities": "4.0",
                        "conditions_facilities": "4.2"
                    },
                    "rat": "4.0",
                    "urat": "4.3",
                    "expediareview": 683,
                    "pid": "3002123",
                    "line1": "Mohammed Bin Rashid Boulevard",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Souk al Bahar - 0.9 km / 0.5 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 1724.12,
                "dwtf": 16560.61,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 1004.92,
                "com_disc": 0,
                "rtf": "14836.00",
                "rdwtf": "16561.00"
            },
            {
                "tid": "whg3EXvzXMJsU3Jn",
                "pid": "44814988",
                "hs": 0,
                "rmid": "321900823",
                "rtid": "389764066",
                "refundable": true,
                "available_rooms": 14,
                "score": 232,
                "sno": "28",
                "cont": {
                    "_id": "600d3e92a1bc371b80f94e0c",
                    "name": "Lemon Tree Hotel Jumeirah Dubai",
                    "ratings": "0.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.138578,
                            "longitude": 55.203372
                        }
                    },
                    "externalId": "44814988",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/45000000/44820000/44815000/44814988/c13414fe_b.jpg",
                    "ha": [
                        "POOL",
                        "GYM"
                    ],
                    "guestreview": {
                        "cleaniness": "4.0",
                        "staff_service": "3.5",
                        "amenities": "5.0",
                        "conditions_facilities": "3.9"
                    },
                    "rat": "0.0",
                    "urat": "3.6",
                    "expediareview": 8,
                    "pid": "44814988",
                    "line1": "Al Wasl Road",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Umm Suqeim Beach - 1.6 km / 1 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 1642.02,
                "dwtf": 4760.22,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 234.81,
                "com_disc": 0,
                "rtf": "3118.00",
                "rdwtf": "4760.00"
            },
            {
                "tid": "htEz2hkr3oEUIKGt",
                "pid": "13320046",
                "hs": 0,
                "rmid": "315616494",
                "rtid": "384153879",
                "refundable": false,
                "available_rooms": 10,
                "score": 317,
                "sno": "39",
                "cont": {
                    "_id": "600d4021a1bc371b80fb5994",
                    "name": "Auris Inn Al Muhanna Hotel",
                    "ratings": "4.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.094254,
                            "longitude": 55.17748
                        }
                    },
                    "externalId": "13320046",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/14000000/13330000/13320100/13320046/e1ab84f5_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "POOL",
                        "POOL"
                    ],
                    "guestreview": {
                        "cleaniness": "4.1",
                        "staff_service": "4.0",
                        "amenities": "4.0",
                        "conditions_facilities": "3.9"
                    },
                    "rat": "4.0",
                    "urat": "3.9",
                    "expediareview": 142,
                    "pid": "13320046",
                    "line1": "Al Fosool St.",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Jebel Ali Race Course - 1 km / 0.6 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 1642.02,
                "dwtf": 4994.21,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 252.87,
                "com_disc": 0,
                "rtf": "3352.00",
                "rdwtf": "4994.00"
            },
            {
                "tid": "eEmWIaJodTIQ0KCN",
                "pid": "38874623",
                "hs": 0,
                "rmid": "218469910",
                "rtid": "276258270",
                "refundable": true,
                "available_rooms": 2,
                "score": 333,
                "sno": "299",
                "cont": {
                    "_id": "600d43bba1bc371b80003533",
                    "name": "Millennium Executive Apartments Mont Rose",
                    "ratings": "0.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.069654,
                            "longitude": 55.245073
                        }
                    },
                    "externalId": "38874623",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/39000000/38880000/38874700/38874623/63ceca3a_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL"
                    ],
                    "guestreview": {
                        "cleaniness": "4.6",
                        "staff_service": "4.3",
                        "amenities": "4.7",
                        "conditions_facilities": "4.7"
                    },
                    "rat": "0.0",
                    "urat": "4.5",
                    "expediareview": 23,
                    "pid": "38874623",
                    "line1": "Dubai Science Park",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Dubai Science Park - 0.1 km / 0.1 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 1642.02,
                "dwtf": 7972.02,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 456.48,
                "com_disc": 0,
                "rtf": "6330.00",
                "rdwtf": "7972.00"
            },
            {
                "tid": "qpVw8T2p0anLUvVz",
                "pid": "16046759",
                "hs": 0,
                "rmid": "315886549",
                "rtid": "384932651",
                "refundable": true,
                "available_rooms": 10,
                "score": 697,
                "sno": "483",
                "cont": {
                    "_id": "600d4230a1bc371b80fe3b31",
                    "name": "Dukes The Palm, a Royal Hideaway Hotel",
                    "ratings": "5.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.113123,
                            "longitude": 55.137524
                        }
                    },
                    "externalId": "16046759",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/17000000/16050000/16046800/16046759/3f8cc4f6_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "POOL",
                        "POOL",
                        "BAR"
                    ],
                    "guestreview": {
                        "cleaniness": "4.4",
                        "staff_service": "4.4",
                        "amenities": "4.3",
                        "conditions_facilities": "4.3"
                    },
                    "rat": "5.0",
                    "urat": "4.3",
                    "expediareview": 768,
                    "pid": "16046759",
                    "line1": "Palm Jumeirah",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "American University of Dubai (AUD) - 4 km / 2.5 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Breakfast"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 1642.02,
                "dwtf": 15770.8,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 1077.99,
                "com_disc": 0,
                "rtf": "14129.00",
                "rdwtf": "15771.00"
            },
            {
                "tid": "X6QuqtMtWKfxWD9y",
                "pid": "17325218",
                "hs": 0,
                "rmid": "221119344",
                "rtid": "294064834",
                "refundable": false,
                "available_rooms": 66,
                "score": 425,
                "sno": "471",
                "cont": {
                    "_id": "600d3f6ba1bc371b80fa7751",
                    "name": "Al Habtoor Polo Resort",
                    "ratings": "5.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.079519,
                            "longitude": 55.366395
                        }
                    },
                    "externalId": "17325218",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/18000000/17330000/17325300/17325218/bc8ce4ed_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "POOL",
                        "BAR",
                        "POOL"
                    ],
                    "guestreview": {
                        "cleaniness": "4.1",
                        "staff_service": "4.0",
                        "amenities": "3.7",
                        "conditions_facilities": "4.1"
                    },
                    "rat": "5.0",
                    "urat": "3.9",
                    "expediareview": 35,
                    "pid": "17325218",
                    "line1": "Wadi Al Safa 5, Emirates Road 611",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Dubai Outlet Mall - 9.2 km / 5.7 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 1559.92,
                "dwtf": 14843.88,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 1013.95,
                "com_disc": 0,
                "rtf": "13284.00",
                "rdwtf": "14844.00"
            },
            {
                "tid": "CTpCNNc5XM3deqHf",
                "pid": "38742260",
                "hs": 0,
                "rmid": "217252848",
                "rtid": "268226180",
                "refundable": true,
                "available_rooms": 8,
                "score": 518,
                "sno": "478",
                "cont": {
                    "_id": "600d3ee3a1bc371b80f9b3ab",
                    "name": "Hyatt Place Dubai Jumeirah",
                    "ratings": "4.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.245996,
                            "longitude": 55.277753
                        }
                    },
                    "externalId": "38742260",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/39000000/38750000/38742300/38742260/2b1c6d4c_b.jpg",
                    "ha": [
                        "BAR",
                        "POOL",
                        "POOL",
                        "BAR",
                        "POOL"
                    ],
                    "guestreview": {
                        "cleaniness": "4.2",
                        "staff_service": "4.0",
                        "amenities": "4.1",
                        "conditions_facilities": "4.1"
                    },
                    "rat": "4.0",
                    "urat": "3.9",
                    "expediareview": 76,
                    "pid": "38742260",
                    "line1": "Al Mina Road",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Dubai Cruise Terminal - 2.1 km / 1.3 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Very Good",
                "brdbs": [
                    "Breakfast"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 1559.92,
                "dwtf": 15360.29,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 779.14,
                "com_disc": 0,
                "rtf": "13800.00",
                "rdwtf": "15360.00"
            },
            {
                "tid": "bb3P4pDvaSTJ2bA6",
                "pid": "20321",
                "hs": 0,
                "rmid": "200181583",
                "rtid": "201039511",
                "refundable": true,
                "available_rooms": 13,
                "score": 631,
                "sno": "479",
                "cont": {
                    "_id": "600d3e05a1bc371b80f86d1a",
                    "name": "Grand Hyatt Dubai",
                    "ratings": "5.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.227608,
                            "longitude": 55.328004
                        }
                    },
                    "externalId": "20321",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/1000000/30000/20400/20321/61f77951_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "POOL",
                        "BAR",
                        "POOL"
                    ],
                    "guestreview": {
                        "cleaniness": "4.7",
                        "staff_service": "4.6",
                        "amenities": "4.7",
                        "conditions_facilities": "4.6"
                    },
                    "rat": "5.0",
                    "urat": "4.6",
                    "expediareview": 685,
                    "pid": "20321",
                    "line1": "Riyadh Street, Sheikh Rashid Road",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Mohammed Bin Rashid University Of Medicine and Health Sciences - 1.1 km / 0.7 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 1559.92,
                "dwtf": 15360.29,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 934.31,
                "com_disc": 0,
                "rtf": "13800.00",
                "rdwtf": "15360.00"
            },
            {
                "tid": "ONbGIoIumVoTAuDx",
                "pid": "6403757",
                "hs": 0,
                "rmid": "200328197",
                "rtid": "202025511",
                "refundable": false,
                "available_rooms": 13,
                "score": 253,
                "sno": "38",
                "cont": {
                    "_id": "600d42e2a1bc371b80ff2b52",
                    "name": "Ramee Rose Hotel",
                    "ratings": "4.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.09823,
                            "longitude": 55.179836
                        }
                    },
                    "externalId": "6403757",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/7000000/6410000/6403800/6403757/e5b458b8_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "3.5",
                        "staff_service": "3.3",
                        "amenities": "3.1",
                        "conditions_facilities": "3.3"
                    },
                    "rat": "4.0",
                    "urat": "3.2",
                    "expediareview": 138,
                    "pid": "6403757",
                    "line1": "Tecom Al Barsha",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Jebel Ali Race Course - 1.2 km / 0.7 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 1477.82,
                "dwtf": 4784.03,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 252.05,
                "com_disc": 0,
                "rtf": "3306.00",
                "rdwtf": "4784.00"
            },
            {
                "tid": "clo83dTSTd3IMQTR",
                "pid": "70108301",
                "hs": 0,
                "rmid": "315654350",
                "rtid": "384319418",
                "refundable": false,
                "available_rooms": 8,
                "score": 234,
                "sno": "123",
                "cont": {
                    "_id": "62506b752aee3e1bb50b68ef",
                    "ratings": "0.0",
                    "name": "Al Khoory Sky Garden Hotel",
                    "location": {
                        "coordinates": {
                            "latitude": 25.239081,
                            "longitude": 55.37374
                        },
                        "obfuscationRequired": false
                    },
                    "externalId": "70108301",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/71000000/70110000/70108400/70108301/63eb0e19_b.jpg",
                    "ha": [
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "3.0",
                        "staff_service": "1.0",
                        "amenities": "1.0",
                        "conditions_facilities": "4.0"
                    },
                    "rat": "0.0",
                    "urat": "2.0",
                    "expediareview": 1,
                    "pid": "70108301",
                    "line1": "Airport Rd",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Dubai Festival City Mall - 3.7 km / 2.3 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 1477.82,
                "dwtf": 5605.04,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 353.86,
                "com_disc": 0,
                "rtf": "4127.00",
                "rdwtf": "5605.00"
            },
            {
                "tid": "oHSCyt9SDJF9EFiT",
                "pid": "2595659",
                "hs": 0,
                "rmid": "214286045",
                "rtid": "248276071",
                "refundable": false,
                "available_rooms": 14,
                "score": 330,
                "sno": "132",
                "cont": {
                    "_id": "600d3f7ca1bc371b80fa8b7d",
                    "name": "City Seasons Hotel Dubai Airport",
                    "ratings": "4.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.25674,
                            "longitude": 55.334919
                        }
                    },
                    "externalId": "2595659",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/3000000/2600000/2595700/2595659/6b721977_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "POOL",
                        "POOL"
                    ],
                    "guestreview": {
                        "cleaniness": "4.1",
                        "staff_service": "4.0",
                        "amenities": "3.8",
                        "conditions_facilities": "4.0"
                    },
                    "rat": "4.0",
                    "urat": "4.0",
                    "expediareview": 415,
                    "pid": "2595659",
                    "line1": "Port Saeed Al Maktoum Street",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "City Centre Deira - 1.3 km / 0.8 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 1477.82,
                "dwtf": 5687.14,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 321.84,
                "com_disc": 0,
                "rtf": "4209.00",
                "rdwtf": "5687.00"
            },
            {
                "tid": "r14vx35B43kiUdWb",
                "pid": "14933085",
                "hs": 0,
                "rmid": "201489848",
                "rtid": "209299446",
                "refundable": false,
                "available_rooms": 11,
                "score": 303,
                "sno": "138",
                "cont": {
                    "_id": "600d43b0a1bc371b8000262d",
                    "name": "City Seasons Towers",
                    "ratings": "4.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.252512,
                            "longitude": 55.30296
                        }
                    },
                    "externalId": "14933085",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/15000000/14940000/14933100/14933085/95871e6a_b.jpg",
                    "ha": [
                        "POOL",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.1",
                        "staff_service": "4.0",
                        "amenities": "3.7",
                        "conditions_facilities": "4.0"
                    },
                    "rat": "4.0",
                    "urat": "3.9",
                    "expediareview": 194,
                    "pid": "14933085",
                    "line1": "Khalifa Bin Zayed Road, Mankhool",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "BurJuman Mall - 0.1 km / 0.1 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 1477.82,
                "dwtf": 5778.28,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 329.23,
                "com_disc": 0,
                "rtf": "4300.00",
                "rdwtf": "5778.00"
            },
            {
                "tid": "1g5SIxNTO2kBq7An",
                "pid": "4239506",
                "hs": 0,
                "rmid": "201845524",
                "rtid": "209894013",
                "refundable": false,
                "available_rooms": 6,
                "score": 593,
                "sno": "156",
                "cont": {
                    "_id": "600d3ee3a1bc371b80f9b3b4",
                    "name": "Millennium Central Downtown",
                    "ratings": "4.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.193583,
                            "longitude": 55.289332
                        }
                    },
                    "externalId": "4239506",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/5000000/4240000/4239600/4239506/05acc24c_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "POOL",
                        "BAR",
                        "POOL"
                    ],
                    "guestreview": {
                        "cleaniness": "3.9",
                        "staff_service": "3.9",
                        "amenities": "3.7",
                        "conditions_facilities": "3.7"
                    },
                    "rat": "4.0",
                    "urat": "3.7",
                    "expediareview": 842,
                    "pid": "4239506",
                    "line1": "Al Asayel St. Business Bay",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Souk al Bahar - 1.6 km / 1 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 1477.82,
                "dwtf": 5894.04,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 319.37,
                "com_disc": 0,
                "rtf": "4416.00",
                "rdwtf": "5894.00"
            },
            {
                "tid": "aQtKCDeqZTQcy39U",
                "pid": "38937814",
                "hs": 0,
                "rmid": "314144218",
                "rtid": "383640536",
                "refundable": true,
                "available_rooms": 1,
                "score": 428,
                "sno": "251",
                "cont": {
                    "_id": "600d4398a1bc371b80000633",
                    "name": "Radisson Blu Hotel, Dubai Canal View",
                    "ratings": "0.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.191235,
                            "longitude": 55.290824
                        }
                    },
                    "externalId": "38937814",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/39000000/38940000/38937900/38937814/e692927a_b.jpg",
                    "ha": [
                        "BAR",
                        "GYM",
                        "POOL",
                        "POOL"
                    ],
                    "guestreview": {
                        "cleaniness": "4.7",
                        "staff_service": "4.6",
                        "amenities": "4.7",
                        "conditions_facilities": "4.7"
                    },
                    "rat": "0.0",
                    "urat": "4.4",
                    "expediareview": 21,
                    "pid": "38937814",
                    "line1": "Marasi Drive",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Souk al Bahar - 1.9 km / 1.2 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 1477.82,
                "dwtf": 7058.23,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 425.28,
                "com_disc": 0,
                "rtf": "5580.00",
                "rdwtf": "7058.00"
            },
            {
                "tid": "BTFpUlPQcEJUK0SH",
                "pid": "4937616",
                "hs": 0,
                "rmid": "321498035",
                "rtid": "388945706",
                "refundable": true,
                "available_rooms": 23,
                "score": 399,
                "sno": "262",
                "cont": {
                    "_id": "600d3d8aa1bc371b80f7c523",
                    "name": "Elite Byblos Hotel",
                    "ratings": "5.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.113148,
                            "longitude": 55.200905
                        }
                    },
                    "externalId": "4937616",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/5000000/4940000/4937700/4937616/ae5f92e2_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "POOL",
                        "BAR",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.4",
                        "staff_service": "4.2",
                        "amenities": "4.0",
                        "conditions_facilities": "4.3"
                    },
                    "rat": "5.0",
                    "urat": "4.2",
                    "expediareview": 625,
                    "pid": "4937616",
                    "line1": "Al Barsha 1",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Fatima Abdullah Mohammed Rasheed Mosque - 0.9 km / 0.6 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 1477.82,
                "dwtf": 7166.61,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 434.31,
                "com_disc": 0,
                "rtf": "5689.00",
                "rdwtf": "7167.00"
            },
            {
                "tid": "WFPxHnsBpzopB48c",
                "pid": "860152",
                "hs": 0,
                "rmid": "315603342",
                "rtid": "384116229",
                "refundable": true,
                "available_rooms": 2147483647,
                "score": 590,
                "sno": "463",
                "cont": {
                    "_id": "600d415ca1bc371b80fd02f3",
                    "name": "Fairmont Dubai",
                    "ratings": "5.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.226203,
                            "longitude": 55.284586
                        }
                    },
                    "externalId": "860152",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/1000000/870000/860200/860152/1df627fb_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.5",
                        "staff_service": "4.5",
                        "amenities": "4.4",
                        "conditions_facilities": "4.4"
                    },
                    "rat": "5.0",
                    "urat": "4.4",
                    "expediareview": 964,
                    "pid": "860152",
                    "line1": "Sheikh Zayed Road",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Dubai World Trade Centre - 1.3 km / 0.8 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 1477.82,
                "dwtf": 14292.16,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 925.28,
                "com_disc": 0,
                "rtf": "12814.00",
                "rdwtf": "14292.00"
            },
            {
                "tid": "L7bTfFdpbmIzvhhw",
                "pid": "115888",
                "hs": 0,
                "rmid": "322461051",
                "rtid": "391212668",
                "refundable": true,
                "available_rooms": 9,
                "score": 443,
                "sno": "20",
                "cont": {
                    "_id": "600d4105a1bc371b80fca236",
                    "name": "Four Points by Sheraton Bur Dubai",
                    "ratings": "4.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.257337,
                            "longitude": 55.299425
                        }
                    },
                    "externalId": "115888",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/1000000/120000/115900/115888/0ee9e423_b.jpg",
                    "ha": [
                        "BAR",
                        "GYM",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.4",
                        "staff_service": "4.3",
                        "amenities": "4.3",
                        "conditions_facilities": "4.1"
                    },
                    "rat": "4.0",
                    "urat": "4.2",
                    "expediareview": 692,
                    "pid": "115888",
                    "line1": "Khalid Bin Walid Street",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Meena Bazaar - 0.6 km / 0.4 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 1395.72,
                "dwtf": 4470.4,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 234.81,
                "com_disc": 0,
                "rtf": "3075.00",
                "rdwtf": "4470.00"
            },
            {
                "tid": "t1oFnPCNmi1gN3kr",
                "pid": "1742022",
                "hs": 0,
                "rmid": "255864",
                "rtid": "242296860",
                "refundable": true,
                "available_rooms": 9,
                "score": 395,
                "sno": "100",
                "cont": {
                    "_id": "600d4312a1bc371b80ff731c",
                    "name": "Holiday Inn Express Dubai, Internet City",
                    "ratings": "2.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.104346,
                            "longitude": 55.166522
                        }
                    },
                    "externalId": "1742022",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/2000000/1750000/1742100/1742022/a960c3a9_b.jpg",
                    "ha": [
                        "BAR",
                        "WIFI",
                        "GYM"
                    ],
                    "guestreview": {
                        "cleaniness": "3.9",
                        "staff_service": "4.0",
                        "amenities": "3.9",
                        "conditions_facilities": "3.9"
                    },
                    "rat": "2.0",
                    "urat": "3.9",
                    "expediareview": 461,
                    "pid": "1742022",
                    "line1": "Knowledge City",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Dubai College - 0.9 km / 0.6 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Very Good",
                "brdbs": [
                    "Breakfast"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 1395.72,
                "dwtf": 5307.84,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 296.38,
                "com_disc": 0,
                "rtf": "3912.00",
                "rdwtf": "5308.00"
            },
            {
                "tid": "RzpOu2i0pslHIarL",
                "pid": "2222454",
                "hs": 0,
                "rmid": "200167334",
                "rtid": "200944599",
                "refundable": true,
                "available_rooms": 1,
                "score": 292,
                "sno": "227",
                "cont": {
                    "_id": "600d4244a1bc371b80fe56e9",
                    "name": "Samaya Hotel Deira",
                    "ratings": "4.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.26068,
                            "longitude": 55.319056
                        }
                    },
                    "externalId": "2222454",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/3000000/2230000/2222500/2222454/89fdefd1_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.1",
                        "staff_service": "4.0",
                        "amenities": "3.6",
                        "conditions_facilities": "3.8"
                    },
                    "rat": "4.0",
                    "urat": "4.0",
                    "expediareview": 219,
                    "pid": "2222454",
                    "line1": "Riggat Al Buteen, Deira",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Al Ghurair Centre - 1.2 km / 0.7 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 1395.72,
                "dwtf": 6656.76,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 401.47,
                "com_disc": 0,
                "rtf": "5261.00",
                "rdwtf": "6657.00"
            },
            {
                "tid": "MfTeT8ogwNy5Mfsk",
                "pid": "6895145",
                "hs": 0,
                "rmid": "202321593",
                "rtid": "264164898",
                "refundable": false,
                "available_rooms": 8,
                "score": 224,
                "sno": "347",
                "cont": {
                    "_id": "600d4039a1bc371b80fb79b8",
                    "name": "72 Hotel",
                    "ratings": "5.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.32772,
                            "longitude": 55.378242
                        }
                    },
                    "externalId": "6895145",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/7000000/6900000/6895200/6895145/9f29e896_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.6",
                        "staff_service": "4.3",
                        "amenities": "4.0",
                        "conditions_facilities": "4.5"
                    },
                    "rat": "5.0",
                    "urat": "4.2",
                    "expediareview": 64,
                    "pid": "6895145",
                    "line1": "Al Majaz, Corniche Area",
                    "city": "Sharjah",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Al Qasba - 0.8 km / 0.5 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 1395.72,
                "dwtf": 8905.51,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 560.75,
                "com_disc": 0,
                "rtf": "7510.00",
                "rdwtf": "8906.00"
            },
            {
                "tid": "Iu7IbXU4claN0mWT",
                "pid": "41262024",
                "hs": 0,
                "rmid": "217255016",
                "rtid": "268338047",
                "refundable": false,
                "available_rooms": 11,
                "score": 509,
                "sno": "160",
                "cont": {
                    "_id": "600d426ca1bc371b80fe8ad8",
                    "name": "Park Regis Business Bay Hotel",
                    "ratings": "0.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.185443,
                            "longitude": 55.261785
                        }
                    },
                    "externalId": "41262024",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/42000000/41270000/41262100/41262024/62b3abd4_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "POOL",
                        "BAR",
                        "POOL"
                    ],
                    "guestreview": {
                        "cleaniness": "4.1",
                        "staff_service": "3.7",
                        "amenities": "3.2",
                        "conditions_facilities": "3.8"
                    },
                    "rat": "0.0",
                    "urat": "3.4",
                    "expediareview": 15,
                    "pid": "41262024",
                    "line1": "Park Lane Tower,Business Bay.",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Dubai Opera - 2 km / 1.2 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 1313.62,
                "dwtf": 5788.13,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 341.54,
                "com_disc": 0,
                "rtf": "4475.00",
                "rdwtf": "5788.00"
            },
            {
                "tid": "gKX8hlEd0fN6t3aH",
                "pid": "1764405",
                "hs": 0,
                "rmid": "317275",
                "rtid": "206520637",
                "refundable": false,
                "available_rooms": 33,
                "score": 342,
                "sno": "193",
                "cont": {
                    "_id": "600d4385a1bc371b80ffeee3",
                    "name": "Tamani Marina Hotel and Hotel Apartments",
                    "ratings": "0.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.091239,
                            "longitude": 55.14926
                        }
                    },
                    "externalId": "1764405",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/2000000/1770000/1764500/1764405/992a579e_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "POOL",
                        "POOL"
                    ],
                    "guestreview": {
                        "cleaniness": "3.9",
                        "staff_service": "3.8",
                        "amenities": "4.4",
                        "conditions_facilities": "3.7"
                    },
                    "rat": "0.0",
                    "urat": "3.7",
                    "expediareview": 993,
                    "pid": "1764405",
                    "line1": "Al Sufouh Road, Dubai Marina",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "American University of Dubai (AUD) - 1 km / 0.6 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 1313.62,
                "dwtf": 6145.27,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 369.45,
                "com_disc": 0,
                "rtf": "4832.00",
                "rdwtf": "6145.00"
            },
            {
                "tid": "NuMmygABSHA8IxDy",
                "pid": "16467386",
                "hs": 0,
                "rmid": "201778518",
                "rtid": "290137892",
                "refundable": false,
                "available_rooms": 5,
                "score": 272,
                "sno": "194",
                "cont": {
                    "_id": "600d407ea1bc371b80fbd997",
                    "name": "Fortune Park Hotel",
                    "ratings": "4.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.005972,
                            "longitude": 55.155776
                        }
                    },
                    "externalId": "16467386",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/17000000/16470000/16467400/16467386/3b5cb11e_b.jpg",
                    "ha": [
                        "BAR",
                        "GYM",
                        "POOL",
                        "POOL",
                        "BAR"
                    ],
                    "guestreview": {
                        "cleaniness": "4.0",
                        "staff_service": "3.9",
                        "amenities": "4.0",
                        "conditions_facilities": "3.9"
                    },
                    "rat": "4.0",
                    "urat": "3.7",
                    "expediareview": 49,
                    "pid": "16467386",
                    "line1": "Dubai Investment Park",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Jumeirah Golf Estates - 11.1 km / 6.9 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 1313.62,
                "dwtf": 6145.27,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 369.45,
                "com_disc": 0,
                "rtf": "4832.00",
                "rdwtf": "6145.00"
            },
            {
                "tid": "5qtc2sC2XbHtZHz5",
                "pid": "73338667",
                "hs": 0,
                "rmid": "315901107",
                "rtid": "384993657",
                "refundable": false,
                "available_rooms": 2147483647,
                "score": 288,
                "sno": "204",
                "cont": {
                    "_id": "62506ba82aee3e1bb50b69ab",
                    "ratings": "0.0",
                    "name": "Novotel Jumeirah Village Triangle",
                    "location": {
                        "coordinates": {
                            "latitude": 25.041551,
                            "longitude": 55.187752
                        },
                        "obfuscatedCoordinates": {
                            "latitude": 25.043692,
                            "longitude": 55.185718
                        },
                        "obfuscationRequired": false
                    },
                    "externalId": "73338667",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/74000000/73340000/73338700/73338667/966e7fdc_b.jpg",
                    "ha": [
                        "POOL",
                        "WIFI",
                        "GYM"
                    ],
                    "guestreview": {
                        "cleaniness": "4.0",
                        "staff_service": "3.0",
                        "amenities": "4.0",
                        "conditions_facilities": "4.0"
                    },
                    "rat": "0.0",
                    "urat": "3.0",
                    "expediareview": 1,
                    "pid": "73338667",
                    "line1": "B1 JOORY STREET DISTRICT 3",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Jebel Ali Race Course - 8 km / 5 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 1313.62,
                "dwtf": 6243.79,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 422.82,
                "com_disc": 0,
                "rtf": "4930.00",
                "rdwtf": "6244.00"
            },
            {
                "tid": "iJWTpTNv8fdqntxN",
                "pid": "45959441",
                "hs": 0,
                "rmid": "214311481",
                "rtid": "248224936",
                "refundable": true,
                "available_rooms": 2147483647,
                "score": 203,
                "sno": "207",
                "cont": {
                    "_id": "600d3db6a1bc371b80f81415",
                    "name": "Pullman Sharjah",
                    "ratings": "",
                    "location": {
                        "coordinates": {
                            "latitude": 25.31404,
                            "longitude": 55.376157
                        }
                    },
                    "externalId": "45959441",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/46000000/45960000/45959500/45959441/3862ec75_b.jpg",
                    "ha": [
                        "POOL",
                        "POOL",
                        "BAR",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "5.0",
                        "staff_service": "5.0",
                        "amenities": "5.0",
                        "conditions_facilities": "5.0"
                    },
                    "rat": "",
                    "urat": "5.0",
                    "expediareview": 4,
                    "pid": "45959441",
                    "line1": "Al Taawun Street",
                    "city": "Sharjah",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Al Qasba - 1.2 km / 0.7 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 1313.62,
                "dwtf": 6265.96,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 368.63,
                "com_disc": 0,
                "rtf": "4952.00",
                "rdwtf": "6266.00"
            },
            {
                "tid": "EA1dIA1GVqOGIZEt",
                "pid": "33403743",
                "hs": 0,
                "rmid": "220858967",
                "rtid": "292241714",
                "refundable": true,
                "available_rooms": 12,
                "score": 383,
                "sno": "219",
                "cont": {
                    "_id": "600d3e99a1bc371b80f955ee",
                    "name": "Millennium AL Barsha",
                    "ratings": "4.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.115342,
                            "longitude": 55.195676
                        }
                    },
                    "externalId": "33403743",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/34000000/33410000/33403800/33403743/76d30e0a_b.jpg",
                    "ha": [
                        "GYM",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "3.7",
                        "staff_service": "3.6",
                        "amenities": "3.7",
                        "conditions_facilities": "3.6"
                    },
                    "rat": "4.0",
                    "urat": "3.6",
                    "expediareview": 22,
                    "pid": "33403743",
                    "line1": "Al Barsha Rd",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Fatima Abdullah Mohammed Rasheed Mosque - 0.4 km / 0.2 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 1313.62,
                "dwtf": 6461.36,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 371.92,
                "com_disc": 0,
                "rtf": "5148.00",
                "rdwtf": "6461.00"
            },
            {
                "tid": "yF4CmkwaJopT80cf",
                "pid": "1769023",
                "hs": 0,
                "rmid": "212100442",
                "rtid": "391309793",
                "refundable": false,
                "available_rooms": 23,
                "score": 536,
                "sno": "295",
                "cont": {
                    "_id": "600d4102a1bc371b80fc9e49",
                    "name": "Grand Millennium Dubai",
                    "ratings": "5.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.100978,
                            "longitude": 55.177677
                        }
                    },
                    "externalId": "1769023",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/2000000/1770000/1769100/1769023/d96da2a1_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "POOL",
                        "BAR",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.4",
                        "staff_service": "4.4",
                        "amenities": "4.2",
                        "conditions_facilities": "4.3"
                    },
                    "rat": "5.0",
                    "urat": "4.3",
                    "expediareview": 460,
                    "pid": "1769023",
                    "line1": "Sheikh Zayed Road, Exit 36",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Jebel Ali Race Course - 1.4 km / 0.9 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 1313.62,
                "dwtf": 7600.92,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 469.62,
                "com_disc": 0,
                "rtf": "6287.00",
                "rdwtf": "7601.00"
            },
            {
                "tid": "wXhFwfaTLtx6aW80",
                "pid": "22499594",
                "hs": 0,
                "rmid": "202461963",
                "rtid": "212495420",
                "refundable": false,
                "available_rooms": 2,
                "score": 103,
                "sno": "336",
                "cont": {
                    "_id": "600d3e1ca1bc371b80f88f22",
                    "name": "Royal Hotel Sharjah",
                    "ratings": "3.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.373208,
                            "longitude": 55.396708
                        }
                    },
                    "externalId": "22499594",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/23000000/22500000/22499600/22499594/c3a2eba3_b.jpg",
                    "ha": [
                        "GYM",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": 0,
                        "staff_service": 0,
                        "amenities": 0,
                        "conditions_facilities": 0
                    },
                    "rat": "3.0",
                    "urat": "",
                    "expediareview": 0,
                    "pid": "22499594",
                    "line1": "Al Qulayaa",
                    "city": "Sharjah",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Sharjah Ladies Club - 1.2 km / 0.8 mi "
                },
                "hdtype": "HOTELHD",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 1313.62,
                "dwtf": 8539.34,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 589.49,
                "com_disc": 0,
                "rtf": "7226.00",
                "rdwtf": "8539.00"
            },
            {
                "tid": "lIhvfDNGOisHLiEv",
                "pid": "49102294",
                "hs": 0,
                "rmid": "230736454",
                "rtid": "278329035",
                "refundable": true,
                "available_rooms": 38,
                "score": 419,
                "sno": "339",
                "cont": {
                    "_id": "600d4151a1bc371b80fcf694",
                    "name": "Rove La Mer Beach",
                    "ratings": "0.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.22619,
                            "longitude": 55.253693
                        }
                    },
                    "externalId": "49102294",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/50000000/49110000/49102300/49102294/9479b41b_b.jpg",
                    "ha": [
                        "BAR",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": 0,
                        "staff_service": 0,
                        "amenities": 0,
                        "conditions_facilities": 0
                    },
                    "rat": "0.0",
                    "urat": "",
                    "expediareview": 0,
                    "pid": "49102294",
                    "line1": "La Mer South, Jumeirah 1",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "La Mer Water Park - 0.3 km / 0.2 mi "
                },
                "hdtype": "HOTELHD",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 1313.62,
                "dwtf": 8603.37,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 557.47,
                "com_disc": 0,
                "rtf": "7290.00",
                "rdwtf": "8603.00"
            },
            {
                "tid": "TUCgbarqdsTqJ49x",
                "pid": "18516665",
                "hs": 0,
                "rmid": "314144332",
                "rtid": "383640992",
                "refundable": true,
                "available_rooms": 9,
                "score": 380,
                "sno": "162",
                "cont": {
                    "_id": "600d3e77a1bc371b80f926ef",
                    "name": "Park Inn by Radisson Dubai Motor City",
                    "ratings": "4.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.047105,
                            "longitude": 55.244314
                        }
                    },
                    "externalId": "18516665",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/19000000/18520000/18516700/18516665/c1e1697b_b.jpg",
                    "ha": [
                        "BAR",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.3",
                        "staff_service": "4.2",
                        "amenities": "4.3",
                        "conditions_facilities": "4.1"
                    },
                    "rat": "4.0",
                    "urat": "4.3",
                    "expediareview": 71,
                    "pid": "18516665",
                    "line1": "Turin Boulevard Road 125/126",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Dubailand - 1.6 km / 1 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 1231.52,
                "dwtf": 5719.16,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 342.36,
                "com_disc": 0,
                "rtf": "4488.00",
                "rdwtf": "5719.00"
            },
            {
                "tid": "FpfvwV7v2WcBnhMu",
                "pid": "33299119",
                "hs": 0,
                "rmid": "221140937",
                "rtid": "294166886",
                "refundable": true,
                "available_rooms": 2147483647,
                "score": 259,
                "sno": "178",
                "cont": {
                    "_id": "600d4307a1bc371b80ff644a",
                    "name": "Novotel Sharjah Expo Center",
                    "ratings": "4.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.309086,
                            "longitude": 55.371017
                        }
                    },
                    "externalId": "33299119",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/34000000/33300000/33299200/33299119/49e698ef_b.jpg",
                    "ha": [
                        "POOL",
                        "POOL",
                        "BAR",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.8",
                        "staff_service": "4.6",
                        "amenities": "4.3",
                        "conditions_facilities": "4.7"
                    },
                    "rat": "4.0",
                    "urat": "4.5",
                    "expediareview": 22,
                    "pid": "33299119",
                    "line1": "Al khan Area",
                    "city": "Sharjah",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Expo Centre Sharjah - 0.8 km / 0.5 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 1231.52,
                "dwtf": 5852.17,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 343.18,
                "com_disc": 0,
                "rtf": "4621.00",
                "rdwtf": "5852.00"
            },
            {
                "tid": "DQRA16RXI7EKQUra",
                "pid": "49217048",
                "hs": 0,
                "rmid": "214332060",
                "rtid": "248249761",
                "refundable": true,
                "available_rooms": 19,
                "score": 228,
                "sno": "180",
                "cont": {
                    "_id": "600d42cfa1bc371b80ff1522",
                    "name": "Edge Creekside Hotel",
                    "ratings": "0.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.258888,
                            "longitude": 55.321862
                        }
                    },
                    "externalId": "49217048",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/50000000/49220000/49217100/49217048/15e2ba70_b.jpg",
                    "ha": [
                        "POOL",
                        "WIFI",
                        "GYM"
                    ],
                    "guestreview": {
                        "cleaniness": 0,
                        "staff_service": 0,
                        "amenities": 0,
                        "conditions_facilities": 0
                    },
                    "rat": "0.0",
                    "urat": "",
                    "expediareview": 0,
                    "pid": "49217048",
                    "line1": "Rigga Al Buteen Street",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Al Ghurair Centre - 1.3 km / 0.8 mi "
                },
                "hdtype": "HOTELHD",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 1231.52,
                "dwtf": 5868.59,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 353.86,
                "com_disc": 0,
                "rtf": "4637.00",
                "rdwtf": "5869.00"
            },
            {
                "tid": "wVzVmMoSslhDV8Ux",
                "pid": "6394",
                "hs": 0,
                "rmid": "211803816",
                "rtid": "232091487",
                "refundable": false,
                "available_rooms": 5,
                "score": 479,
                "sno": "278",
                "cont": {
                    "_id": "600d43c6a1bc371b80004408",
                    "name": "Sheraton Dubai Creek Hotel & Towers",
                    "ratings": "5.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.26185,
                            "longitude": 55.3144
                        }
                    },
                    "externalId": "6394",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/1000000/10000/6400/6394/dfd263ce_b.jpg",
                    "ha": [
                        "POOL",
                        "POOL",
                        "WIFI",
                        "GYM"
                    ],
                    "guestreview": {
                        "cleaniness": "4.7",
                        "staff_service": "4.6",
                        "amenities": "4.5",
                        "conditions_facilities": "4.5"
                    },
                    "rat": "5.0",
                    "urat": "4.6",
                    "expediareview": 995,
                    "pid": "6394",
                    "line1": "Baniyas Street",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Deira Twin Towers Shopping Centre - 0.9 km / 0.5 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 1231.52,
                "dwtf": 7208.48,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 504.92,
                "com_disc": 0,
                "rtf": "5977.00",
                "rdwtf": "7208.00"
            },
            {
                "tid": "lVWp0WP4zJ4l1sEl",
                "pid": "10176034",
                "hs": 0,
                "rmid": "220256227",
                "rtid": "288231965",
                "refundable": true,
                "available_rooms": 4,
                "score": 166,
                "sno": "317",
                "cont": {
                    "_id": "600d42e8a1bc371b80ff335e",
                    "name": "Al Jawhara Metro Hotel",
                    "ratings": "2.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.269039,
                            "longitude": 55.313651
                        }
                    },
                    "externalId": "10176034",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/11000000/10180000/10176100/10176034/502c0f13_b.jpg",
                    "ha": [
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "3.2",
                        "staff_service": "3.5",
                        "amenities": "3.1",
                        "conditions_facilities": "2.9"
                    },
                    "rat": "2.0",
                    "urat": "3.2",
                    "expediareview": 19,
                    "pid": "10176034",
                    "line1": "19th A Street, Riqqa Street -",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Fish Roundabout - 0.4 km / 0.2 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 1231.52,
                "dwtf": 7934.25,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 505.74,
                "com_disc": 0,
                "rtf": "6703.00",
                "rdwtf": "7934.00"
            },
            {
                "tid": "C3Tw6ncTrTBInfE7",
                "pid": "48997",
                "hs": 0,
                "rmid": "215402336",
                "rtid": "256012249",
                "refundable": true,
                "available_rooms": 49,
                "score": 468,
                "sno": "148",
                "cont": {
                    "_id": "600d3dd3a1bc371b80f83311",
                    "name": "Millennium Airport Hotel Dubai",
                    "ratings": "4.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.24826,
                            "longitude": 55.344145
                        }
                    },
                    "externalId": "48997",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/1000000/50000/49000/48997/05bb3723_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "POOL",
                        "BAR",
                        "POOL"
                    ],
                    "guestreview": {
                        "cleaniness": "4.5",
                        "staff_service": "4.5",
                        "amenities": "4.3",
                        "conditions_facilities": "4.4"
                    },
                    "rat": "4.0",
                    "urat": "4.4",
                    "expediareview": 985,
                    "pid": "48997",
                    "line1": "Airport Road, Casablanca Street",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Dubai Tennis Stadium - 0.7 km / 0.5 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 1149.42,
                "dwtf": 5508.16,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 314.45,
                "com_disc": 0,
                "rtf": "4359.00",
                "rdwtf": "5508.00"
            },
            {
                "tid": "5Zf1cMTqoqnWq9l6",
                "pid": "4717738",
                "hs": 0,
                "rmid": "200186134",
                "rtid": "382752024",
                "refundable": true,
                "available_rooms": 1,
                "score": 267,
                "sno": "288",
                "cont": {
                    "_id": "600d4150a1bc371b80fcf5c7",
                    "name": "Hues Boutique Hotel",
                    "ratings": "4.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.281969,
                            "longitude": 55.31514
                        }
                    },
                    "externalId": "4717738",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/5000000/4720000/4717800/4717738/9ea845d8_b.jpg",
                    "ha": [
                        "POOL",
                        "POOL",
                        "BAR",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.1",
                        "staff_service": "3.9",
                        "amenities": "4.1",
                        "conditions_facilities": "3.9"
                    },
                    "rat": "4.0",
                    "urat": "3.8",
                    "expediareview": 261,
                    "pid": "4717738",
                    "line1": "Al Khaleej Road",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Fish Roundabout - 1.7 km / 1.1 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Very Good",
                "brdbs": [
                    "Breakfast"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 1149.42,
                "dwtf": 7311.92,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 470.44,
                "com_disc": 0,
                "rtf": "6163.00",
                "rdwtf": "7312.00"
            },
            {
                "tid": "tLp3VSBRSNE1aKTx",
                "pid": "13561574",
                "hs": 0,
                "rmid": "220826897",
                "rtid": "292096440",
                "refundable": true,
                "available_rooms": 65,
                "score": 685,
                "sno": "290",
                "cont": {
                    "_id": "600d4160a1bc371b80fd06df",
                    "name": "Rove Downtown Dubai",
                    "ratings": "3.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.202982,
                            "longitude": 55.279365
                        }
                    },
                    "externalId": "13561574",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/14000000/13570000/13561600/13561574/f29410a0_b.jpg",
                    "ha": [
                        "POOL",
                        "WIFI",
                        "GYM"
                    ],
                    "guestreview": {
                        "cleaniness": "4.7",
                        "staff_service": "4.7",
                        "amenities": "4.6",
                        "conditions_facilities": "4.6"
                    },
                    "rat": "3.0",
                    "urat": "4.7",
                    "expediareview": 1049,
                    "pid": "13561574",
                    "line1": "312 Al Mustaqbal Street, Zabeel 2",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Dubai Mall - 0.8 km / 0.5 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 1149.42,
                "dwtf": 7354.62,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 474.54,
                "com_disc": 0,
                "rtf": "6205.00",
                "rdwtf": "7355.00"
            },
            {
                "tid": "RlkmQ4nTVfSJmdKG",
                "pid": "45470766",
                "hs": 0,
                "rmid": "217632764",
                "rtid": "270257002",
                "refundable": false,
                "available_rooms": 15,
                "score": 282,
                "sno": "22",
                "cont": {
                    "_id": "600d3f6aa1bc371b80fa75bd",
                    "name": "URBAN Al Khoory Hotel",
                    "ratings": "0.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.162812,
                            "longitude": 55.248081
                        }
                    },
                    "externalId": "45470766",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/46000000/45480000/45470800/45470766/c6002c2c_b.jpg",
                    "ha": [
                        "POOL",
                        "POOL",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "5.0",
                        "staff_service": "5.0",
                        "amenities": 0,
                        "conditions_facilities": "5.0"
                    },
                    "rat": "0.0",
                    "urat": "5.0",
                    "expediareview": 1,
                    "pid": "45470766",
                    "line1": "Al Waha Street",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Dubai Water Canal - 2.9 km / 1.8 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 1067.31,
                "dwtf": 4164.99,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 236.45,
                "com_disc": 0,
                "rtf": "3098.00",
                "rdwtf": "4165.00"
            },
            {
                "tid": "MsCxgT8fdlffHv65",
                "pid": "19332507",
                "hs": 0,
                "rmid": "202100179",
                "rtid": "211936067",
                "refundable": false,
                "available_rooms": 38,
                "score": 527,
                "sno": "69",
                "cont": {
                    "_id": "600d3e45a1bc371b80f8ca78",
                    "name": "TRYP by Wyndham Dubai",
                    "ratings": "4.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.097104,
                            "longitude": 55.174722
                        }
                    },
                    "externalId": "19332507",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/20000000/19340000/19332600/19332507/ea8dd088_b.jpg",
                    "ha": [
                        "BAR",
                        "POOL",
                        "POOL",
                        "BAR",
                        "POOL"
                    ],
                    "guestreview": {
                        "cleaniness": "4.6",
                        "staff_service": "4.4",
                        "amenities": "4.4",
                        "conditions_facilities": "4.5"
                    },
                    "rat": "4.0",
                    "urat": "4.4",
                    "expediareview": 466,
                    "pid": "19332507",
                    "line1": "Al Saef 1 Street, Barsha Heights",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Jebel Ali Race Course - 1.1 km / 0.7 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 1067.31,
                "dwtf": 4715.07,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 275.04,
                "com_disc": 0,
                "rtf": "3648.00",
                "rdwtf": "4715.00"
            },
            {
                "tid": "IHOXehwbvQgXPBfS",
                "pid": "35308016",
                "hs": 0,
                "rmid": "219945910",
                "rtid": "286189222",
                "refundable": false,
                "available_rooms": 8,
                "score": 192,
                "sno": "74",
                "cont": {
                    "_id": "600d413ca1bc371b80fce032",
                    "name": "Center Hotel Sharjah",
                    "ratings": "4.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.362279,
                            "longitude": 55.391323
                        }
                    },
                    "externalId": "35308016",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/36000000/35310000/35308100/35308016/c7a9a9d8_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.7",
                        "staff_service": "4.3",
                        "amenities": "4.5",
                        "conditions_facilities": "4.7"
                    },
                    "rat": "4.0",
                    "urat": "4.1",
                    "expediareview": 8,
                    "pid": "35308016",
                    "line1": "Arabian Gulf St",
                    "city": "Sharjah",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Rolla Mall - 0.5 km / 0.3 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 1067.31,
                "dwtf": 4761.86,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 295.56,
                "com_disc": 0,
                "rtf": "3695.00",
                "rdwtf": "4762.00"
            },
            {
                "tid": "H7HdMFzKBTz1cCox",
                "pid": "69641271",
                "hs": 0,
                "rmid": "314433551",
                "rtid": "383454173",
                "refundable": true,
                "available_rooms": 5,
                "score": 361,
                "sno": "94",
                "cont": {
                    "_id": "62506aff2aee3e1bb50b6757",
                    "ratings": "4.0",
                    "name": "Aloft Dubai Airport",
                    "location": {
                        "coordinates": {
                            "latitude": 25.2469,
                            "longitude": 55.348313
                        },
                        "obfuscatedCoordinates": {
                            "latitude": 25.243627,
                            "longitude": 55.351545
                        },
                        "obfuscationRequired": false
                    },
                    "externalId": "69641271",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/70000000/69650000/69641300/69641271/4dbe8c01_b.jpg",
                    "ha": [
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.7",
                        "staff_service": "4.5",
                        "amenities": "4.0",
                        "conditions_facilities": "4.2"
                    },
                    "rat": "4.0",
                    "urat": "4.4",
                    "expediareview": 5,
                    "pid": "69641271",
                    "line1": "Airport Road, Al Garhoud",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Dubai Tennis Stadium - 1 km / 0.6 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 1067.31,
                "dwtf": 4919.5,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 231.53,
                "com_disc": 0,
                "rtf": "3852.00",
                "rdwtf": "4920.00"
            },
            {
                "tid": "PhKqrtWz6HLovmA7",
                "pid": "66252432",
                "hs": 0,
                "rmid": "227169572",
                "rtid": "254425979",
                "refundable": true,
                "available_rooms": 5,
                "score": 321,
                "sno": "95",
                "cont": {
                    "_id": "62506c102aee3e1bb50b6b35",
                    "ratings": "4.0",
                    "name": "Aloft Al Mina, Dubai",
                    "location": {
                        "coordinates": {
                            "latitude": 25.248941,
                            "longitude": 55.284319
                        },
                        "obfuscationRequired": false
                    },
                    "externalId": "66252432",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/67000000/66260000/66252500/66252432/11b84a46_b.jpg",
                    "ha": [
                        "WIFI",
                        "GYM"
                    ],
                    "guestreview": {
                        "cleaniness": "4.0",
                        "staff_service": "3.7",
                        "amenities": 0,
                        "conditions_facilities": "4.0"
                    },
                    "rat": "4.0",
                    "urat": "3.0",
                    "expediareview": 4,
                    "pid": "66252432",
                    "line1": "Sheikh Rashid Road & 37th Street",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Meena Bazaar - 2.3 km / 1.4 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 1067.31,
                "dwtf": 4919.5,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 231.53,
                "com_disc": 0,
                "rtf": "3852.00",
                "rdwtf": "4920.00"
            },
            {
                "tid": "8QvDyowusoran3fE",
                "pid": "2227239",
                "hs": 0,
                "rmid": "201389125",
                "rtid": "244322432",
                "refundable": false,
                "available_rooms": 1,
                "score": 338,
                "sno": "96",
                "cont": {
                    "_id": "600d4338a1bc371b80ff9712",
                    "name": "Donatello Hotel",
                    "ratings": "4.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.115616,
                            "longitude": 55.193957
                        }
                    },
                    "externalId": "2227239",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/3000000/2230000/2227300/2227239/50b21120_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "POOL",
                        "BAR",
                        "POOL"
                    ],
                    "guestreview": {
                        "cleaniness": "4.3",
                        "staff_service": "4.2",
                        "amenities": "3.9",
                        "conditions_facilities": "4.0"
                    },
                    "rat": "4.0",
                    "urat": "4.1",
                    "expediareview": 387,
                    "pid": "2227239",
                    "line1": "Sheikh Zayed Road, Al Barsha 1",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Fatima Abdullah Mohammed Rasheed Mosque - 0.4 km / 0.2 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 1067.31,
                "dwtf": 4930.99,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 293.92,
                "com_disc": 0,
                "rtf": "3864.00",
                "rdwtf": "4931.00"
            },
            {
                "tid": "RnSaERTSsaGJSp92",
                "pid": "6495722",
                "hs": 0,
                "rmid": "314088786",
                "rtid": "382361976",
                "refundable": false,
                "available_rooms": 13,
                "score": 230,
                "sno": "107",
                "cont": {
                    "_id": "600d4365a1bc371b80ffc6d1",
                    "name": "Fortune Grand Hotel",
                    "ratings": "4.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.27591,
                            "longitude": 55.315585
                        }
                    },
                    "externalId": "6495722",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/7000000/6500000/6495800/6495722/655eec08_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "3.4",
                        "staff_service": "3.2",
                        "amenities": "4.2",
                        "conditions_facilities": "3.1"
                    },
                    "rat": "4.0",
                    "urat": "3.2",
                    "expediareview": 45,
                    "pid": "6495722",
                    "line1": "Omar Bin Khattab Road, Naif Junction",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Fish Roundabout - 0.7 km / 0.4 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 1067.31,
                "dwtf": 5097.66,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 308.7,
                "com_disc": 0,
                "rtf": "4030.00",
                "rdwtf": "5098.00"
            },
            {
                "tid": "0NNrW5WVUr4SaWxL",
                "pid": "897743",
                "hs": 0,
                "rmid": "201029949",
                "rtid": "205025035",
                "refundable": true,
                "available_rooms": 9,
                "score": 223,
                "sno": "110",
                "cont": {
                    "_id": "600d3d5aa1bc371b80f77fec",
                    "name": "Landmark Hotel",
                    "ratings": "3.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.269025,
                            "longitude": 55.305448
                        }
                    },
                    "externalId": "897743",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/1000000/900000/897800/897743/f2442e0c_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "3.5",
                        "staff_service": "3.5",
                        "amenities": "2.8",
                        "conditions_facilities": "3.2"
                    },
                    "rat": "3.0",
                    "urat": "3.3",
                    "expediareview": 172,
                    "pid": "897743",
                    "line1": "Baniyas Square-14th Rd",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Deira Twin Towers Shopping Centre - 0.5 km / 0.3 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 1067.31,
                "dwtf": 5125.57,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 310.34,
                "com_disc": 0,
                "rtf": "4058.00",
                "rdwtf": "5126.00"
            },
            {
                "tid": "9f4se7pGGBBVPnkJ",
                "pid": "69652110",
                "hs": 0,
                "rmid": "315446257",
                "rtid": "383633800",
                "refundable": true,
                "available_rooms": 5,
                "score": 119,
                "sno": "223",
                "cont": {
                    "_id": "62506c642aee3e1bb50b6c0f",
                    "ratings": "0.0",
                    "name": "Element Dubai Airport",
                    "location": {
                        "coordinates": {
                            "latitude": 25.246201,
                            "longitude": 55.347912
                        },
                        "obfuscatedCoordinates": {
                            "latitude": 25.251521,
                            "longitude": 55.343538
                        },
                        "obfuscationRequired": false
                    },
                    "externalId": "69652110",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/70000000/69660000/69652200/69652110/6a108c5f_b.jpg",
                    "ha": [
                        "POOL"
                    ],
                    "guestreview": {
                        "cleaniness": 0,
                        "staff_service": 0,
                        "amenities": 0,
                        "conditions_facilities": 0
                    },
                    "rat": "0.0",
                    "urat": "",
                    "expediareview": 0,
                    "pid": "69652110",
                    "line1": "20c Street, Garhoud",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Dubai Tennis Stadium - 0.9 km / 0.6 mi "
                },
                "hdtype": "HOTELHD",
                "brdbs": [
                    "Breakfast"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 1067.31,
                "dwtf": 6242.97,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 311.16,
                "com_disc": 0,
                "rtf": "5176.00",
                "rdwtf": "6243.00"
            },
            {
                "tid": "eesPcUN2tTZJk7Fu",
                "pid": "3026573",
                "hs": 0,
                "rmid": "441745",
                "rtid": "204171209",
                "refundable": true,
                "available_rooms": 108,
                "score": 568,
                "sno": "406",
                "cont": {
                    "_id": "600d4136a1bc371b80fcd970",
                    "name": "Media One Hotel Dubai",
                    "ratings": "4.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.089656,
                            "longitude": 55.152013
                        }
                    },
                    "externalId": "3026573",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/4000000/3030000/3026600/3026573/e9ca3e4f_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "POOL",
                        "BAR",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.4",
                        "staff_service": "4.3",
                        "amenities": "4.4",
                        "conditions_facilities": "4.3"
                    },
                    "rat": "4.0",
                    "urat": "4.1",
                    "expediareview": 592,
                    "pid": "3026573",
                    "line1": "Dubai Media City, Plot No. 1",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "American University of Dubai (AUD) - 0.6 km / 0.4 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 1067.31,
                "dwtf": 10215.02,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 698.68,
                "com_disc": 0,
                "rtf": "9148.00",
                "rdwtf": "10215.00"
            },
            {
                "tid": "rsC8HdL44tZ0zlNu",
                "pid": "985916",
                "hs": 0,
                "rmid": "137065",
                "rtid": "210254947",
                "refundable": false,
                "available_rooms": 8,
                "score": 631,
                "sno": "416",
                "cont": {
                    "_id": "600d41eaa1bc371b80fddf25",
                    "name": "Dubai Marine Beach Resort & Spa",
                    "ratings": "5.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.233526,
                            "longitude": 55.263618
                        }
                    },
                    "externalId": "985916",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/1000000/990000/986000/985916/bfd7bdbb_b.jpg",
                    "ha": [
                        "BAR",
                        "POOL",
                        "BAR",
                        "POOL",
                        "POOL"
                    ],
                    "guestreview": {
                        "cleaniness": "4.2",
                        "staff_service": "4.3",
                        "amenities": "3.9",
                        "conditions_facilities": "3.9"
                    },
                    "rat": "5.0",
                    "urat": "4.1",
                    "expediareview": 312,
                    "pid": "985916",
                    "line1": "Jumierah Beach Road",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "La Mer North Beach - 0.5 km / 0.3 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 1067.31,
                "dwtf": 10665.75,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 735.63,
                "com_disc": 0,
                "rtf": "9598.00",
                "rdwtf": "10666.00"
            },
            {
                "tid": "Q5O9tu2T8ZwotJ1y",
                "pid": "3957319",
                "hs": 0,
                "rmid": "201612750",
                "rtid": "382097312",
                "refundable": true,
                "available_rooms": 26,
                "score": 683,
                "sno": "541",
                "cont": {
                    "_id": "600d4322a1bc371b80ff8160",
                    "name": "Jumeirah Zabeel Saray",
                    "ratings": "5.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.098007,
                            "longitude": 55.123512
                        }
                    },
                    "externalId": "3957319",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/4000000/3960000/3957400/3957319/6e189999_b.jpg",
                    "ha": [
                        "BAR",
                        "POOL",
                        "BAR",
                        "POOL",
                        "POOL"
                    ],
                    "guestreview": {
                        "cleaniness": "4.7",
                        "staff_service": "4.6",
                        "amenities": "4.7",
                        "conditions_facilities": "4.7"
                    },
                    "rat": "5.0",
                    "urat": "4.6",
                    "expediareview": 949,
                    "pid": "3957319",
                    "line1": "Palm Jumeirah, West Crescent",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Aquaventure - 5 km / 3.1 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 1067.31,
                "dwtf": 19934.15,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 1440.05,
                "com_disc": 0,
                "rtf": "18867.00",
                "rdwtf": "19934.00"
            },
            {
                "tid": "af1x227u87LSsVhe",
                "pid": "33277716",
                "hs": 0,
                "rmid": "215733202",
                "rtid": "258370910",
                "refundable": true,
                "available_rooms": 1,
                "score": 297,
                "sno": "57",
                "cont": {
                    "_id": "600d406ea1bc371b80fbc364",
                    "name": "Grayton Hotel Dubai",
                    "ratings": "4.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.253,
                            "longitude": 55.2969
                        }
                    },
                    "externalId": "33277716",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/34000000/33280000/33277800/33277716/ed1d98ef_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "POOL",
                        "POOL"
                    ],
                    "guestreview": {
                        "cleaniness": "4.9",
                        "staff_service": "4.5",
                        "amenities": "4.3",
                        "conditions_facilities": "4.7"
                    },
                    "rat": "4.0",
                    "urat": "4.6",
                    "expediareview": 36,
                    "pid": "33277716",
                    "line1": "12A Street, Al Mankhool, Bur Dubai",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "BurJuman Mall - 0.9 km / 0.5 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 985.21,
                "dwtf": 4550.04,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 271.75,
                "com_disc": 0,
                "rtf": "3565.00",
                "rdwtf": "4550.00"
            },
            {
                "tid": "eiw4hVtRNBVccKmT",
                "pid": "5389621",
                "hs": 0,
                "rmid": "200764942",
                "rtid": "232052009",
                "refundable": false,
                "available_rooms": 31,
                "score": 361,
                "sno": "62",
                "cont": {
                    "_id": "600d418ba1bc371b80fd3d6e",
                    "name": "Al Khoory Executive Hotel",
                    "ratings": "3.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.231779,
                            "longitude": 55.270296
                        }
                    },
                    "externalId": "5389621",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/6000000/5390000/5389700/5389621/41878a53_b.jpg",
                    "ha": [
                        "GYM",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.5",
                        "staff_service": "4.2",
                        "amenities": "4.1",
                        "conditions_facilities": "4.4"
                    },
                    "rat": "3.0",
                    "urat": "4.2",
                    "expediareview": 736,
                    "pid": "5389621",
                    "line1": "Al Wasl Area",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Jumeirah Mosque - 0.7 km / 0.4 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 985.21,
                "dwtf": 4585.35,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 275.04,
                "com_disc": 0,
                "rtf": "3600.00",
                "rdwtf": "4585.00"
            },
            {
                "tid": "TXovsOGF3cZ3zi16",
                "pid": "64626575",
                "hs": 0,
                "rmid": "228365272",
                "rtid": "262389924",
                "refundable": false,
                "available_rooms": 11,
                "score": 148,
                "sno": "63",
                "cont": {
                    "_id": "62506acd2aee3e1bb50b66f1",
                    "ratings": "0.0",
                    "name": "Kingsgate Canal Hotel",
                    "location": {
                        "coordinates": {
                            "latitude": 25.226003,
                            "longitude": 55.3405
                        },
                        "obfuscatedCoordinates": {
                            "latitude": 25.22677,
                            "longitude": 55.339854
                        },
                        "obfuscationRequired": false
                    },
                    "externalId": "64626575",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/65000000/64630000/64626600/64626575/f826fa50_b.jpg",
                    "ha": [
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": 0,
                        "staff_service": 0,
                        "amenities": 0,
                        "conditions_facilities": 0
                    },
                    "rat": "0.0",
                    "urat": "1.0",
                    "expediareview": 1,
                    "pid": "64626575",
                    "line1": "Building number 6, street 10, al jadaf,",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Mohammed Bin Rashid University Of Medicine and Health Sciences - 3.3 km / 2.1 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 985.21,
                "dwtf": 4600.12,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 261.08,
                "com_disc": 0,
                "rtf": "3615.00",
                "rdwtf": "4600.00"
            },
            {
                "tid": "TL3ZhCJmKDdBa8cq",
                "pid": "15472104",
                "hs": 0,
                "rmid": "221122099",
                "rtid": "294074099",
                "refundable": false,
                "available_rooms": 5,
                "score": 358,
                "sno": "67",
                "cont": {
                    "_id": "600d3ffba1bc371b80fb25af",
                    "name": "Al Khoory Atrium Hotel",
                    "ratings": "4.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.111367,
                            "longitude": 55.196082
                        }
                    },
                    "externalId": "15472104",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/16000000/15480000/15472200/15472104/3ad7625d_b.jpg",
                    "ha": [
                        "POOL",
                        "POOL",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.5",
                        "staff_service": "4.4",
                        "amenities": "4.2",
                        "conditions_facilities": "4.4"
                    },
                    "rat": "4.0",
                    "urat": "4.3",
                    "expediareview": 406,
                    "pid": "15472104",
                    "line1": "Sheikh Zayed Road, Al Barsha 1",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Fatima Abdullah Mohammed Rasheed Mosque - 0.3 km / 0.2 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 985.21,
                "dwtf": 4619.83,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 277.5,
                "com_disc": 0,
                "rtf": "3635.00",
                "rdwtf": "4620.00"
            },
            {
                "tid": "TOOKSO0fdRsAah5f",
                "pid": "67353944",
                "hs": 0,
                "rmid": "314169657",
                "rtid": "382588047",
                "refundable": true,
                "available_rooms": 131,
                "score": 263,
                "sno": "71",
                "cont": {
                    "_id": "62506a8a2aee3e1bb50b6693",
                    "ratings": "4.0",
                    "name": "TIME Asma Hotel",
                    "location": {
                        "coordinates": {
                            "latitude": 25.110943,
                            "longitude": 55.19658
                        },
                        "obfuscationRequired": false
                    },
                    "externalId": "67353944",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/68000000/67360000/67354000/67353944/9a8fa925_b.jpg",
                    "ha": [
                        "POOL",
                        "WIFI",
                        "GYM"
                    ],
                    "guestreview": {
                        "cleaniness": "4.2",
                        "staff_service": "4.4",
                        "amenities": "4.5",
                        "conditions_facilities": "4.4"
                    },
                    "rat": "4.0",
                    "urat": "3.5",
                    "expediareview": 11,
                    "pid": "67353944",
                    "line1": "Al Barsha Rd",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Sheikh Zayed Road - 0.7 km / 0.5 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 985.21,
                "dwtf": 4656.77,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 279.96,
                "com_disc": 0,
                "rtf": "3672.00",
                "rdwtf": "4657.00"
            },
            {
                "tid": "8lX3GBUFc429dP0P",
                "pid": "19643941",
                "hs": 0,
                "rmid": "315440298",
                "rtid": "383639734",
                "refundable": true,
                "available_rooms": 54,
                "score": 158,
                "sno": "75",
                "cont": {
                    "_id": "600d4207a1bc371b80fe0551",
                    "name": "Red Castle Hotel",
                    "ratings": "3.5",
                    "location": {
                        "coordinates": {
                            "latitude": 25.346895,
                            "longitude": 55.368268
                        }
                    },
                    "externalId": "19643941",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/20000000/19650000/19644000/19643941/e5321d83_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.0",
                        "staff_service": "4.0",
                        "amenities": 0,
                        "conditions_facilities": "4.0"
                    },
                    "rat": "3.5",
                    "urat": "4.0",
                    "expediareview": 1,
                    "pid": "19643941",
                    "line1": "Al Meena Street 101, Al Khan Beach Area",
                    "city": "Sharjah",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Al Jazeera Park - 2 km / 1.3 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 985.21,
                "dwtf": 4687.15,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 303.77,
                "com_disc": 0,
                "rtf": "3702.00",
                "rdwtf": "4687.00"
            },
            {
                "tid": "huEyTfXSnAXMD5AP",
                "pid": "1366982",
                "hs": 0,
                "rmid": "314252790",
                "rtid": "382776646",
                "refundable": false,
                "available_rooms": 9,
                "score": 510,
                "sno": "79",
                "cont": {
                    "_id": "600d415ca1bc371b80fd0257",
                    "name": "Radisson Blu Hotel, Dubai Media City",
                    "ratings": "4.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.09377,
                            "longitude": 55.155653
                        }
                    },
                    "externalId": "1366982",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/2000000/1370000/1367000/1366982/0da548e6_b.jpg",
                    "ha": [
                        "WIFI",
                        "GYM"
                    ],
                    "guestreview": {
                        "cleaniness": "4.5",
                        "staff_service": "4.2",
                        "amenities": "4.1",
                        "conditions_facilities": "4.2"
                    },
                    "rat": "4.0",
                    "urat": "4.1",
                    "expediareview": 429,
                    "pid": "1366982",
                    "line1": "Dubai Media City",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "American University of Dubai (AUD) - 0.5 km / 0.3 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 985.21,
                "dwtf": 4729.84,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 284.89,
                "com_disc": 0,
                "rtf": "3745.00",
                "rdwtf": "4730.00"
            },
            {
                "tid": "X0QfFVSf1A9DJ7vM",
                "pid": "71143500",
                "hs": 0,
                "rmid": "321125329",
                "rtid": "389591909",
                "refundable": false,
                "available_rooms": 29,
                "score": 54,
                "sno": "90",
                "cont": {
                    "_id": "62506bce2aee3e1bb50b6a3a",
                    "ratings": "0.0",
                    "name": "Grand Kingsgate Waterfront By Millennium Hotels",
                    "location": {
                        "coordinates": {
                            "latitude": 25.22665,
                            "longitude": 55.34054
                        },
                        "obfuscatedCoordinates": {
                            "latitude": 25.226754,
                            "longitude": 55.338122
                        },
                        "obfuscationRequired": false
                    },
                    "externalId": "71143500",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/72000000/71150000/71143500/71143500/d958cedb_b.jpg",
                    "ha": [
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": 0,
                        "staff_service": 0,
                        "amenities": 0,
                        "conditions_facilities": 0
                    },
                    "rat": "0.0",
                    "urat": "",
                    "expediareview": 0,
                    "pid": "71143500",
                    "line1": "Al Jaddaf",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Mohammed Bin Rashid University Of Medicine and Health Sciences - 3.3 km / 2 mi "
                },
                "hdtype": "HOTELHD",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 985.21,
                "dwtf": 4784.85,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 310.34,
                "com_disc": 0,
                "rtf": "3800.00",
                "rdwtf": "4785.00"
            },
            {
                "tid": "CEQMNr0V3giSBhXl",
                "pid": "68997818",
                "hs": 0,
                "rmid": "314917708",
                "rtid": "383554488",
                "refundable": true,
                "available_rooms": 244,
                "score": 392,
                "sno": "218",
                "cont": {
                    "_id": "62506c072aee3e1bb50b6b15",
                    "ratings": "3.0",
                    "name": "Rove City Walk",
                    "location": {
                        "coordinates": {
                            "latitude": 25.205254,
                            "longitude": 55.267959
                        },
                        "obfuscatedCoordinates": {
                            "latitude": 25.206169,
                            "longitude": 55.266892
                        },
                        "obfuscationRequired": false
                    },
                    "externalId": "68997818",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/69000000/69000000/68997900/68997818/fd138868_b.jpg",
                    "ha": [
                        "WIFI",
                        "GYM"
                    ],
                    "guestreview": {
                        "cleaniness": "4.6",
                        "staff_service": "4.7",
                        "amenities": "4.3",
                        "conditions_facilities": "4.7"
                    },
                    "rat": "3.0",
                    "urat": "4.5",
                    "expediareview": 73,
                    "pid": "68997818",
                    "line1": "Al Wasl and Al Safa road-junction",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Sheikh Zayed Road - 0.1 km / 0.1 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 985.21,
                "dwtf": 6105.86,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 391.62,
                "com_disc": 0,
                "rtf": "5121.00",
                "rdwtf": "6106.00"
            },
            {
                "tid": "x2sQ6QXBuTMLCSno",
                "pid": "21307739",
                "hs": 0,
                "rmid": "321104462",
                "rtid": "390522025",
                "refundable": false,
                "available_rooms": 8,
                "score": 288,
                "sno": "230",
                "cont": {
                    "_id": "600d3e57a1bc371b80f8ef90",
                    "name": "The Act Hotel",
                    "ratings": "5.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.327829,
                            "longitude": 55.378151
                        }
                    },
                    "externalId": "21307739",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/22000000/21310000/21307800/21307739/f7c2808e_b.jpg",
                    "ha": [
                        "POOL",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.7",
                        "staff_service": "4.5",
                        "amenities": "4.0",
                        "conditions_facilities": "4.7"
                    },
                    "rat": "5.0",
                    "urat": "4.7",
                    "expediareview": 23,
                    "pid": "21307739",
                    "line1": "Al Majaz, Corniche Area",
                    "city": "Sharjah",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Al Qasba - 0.9 km / 0.5 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 985.21,
                "dwtf": 6296.33,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 355.5,
                "com_disc": 0,
                "rtf": "5311.00",
                "rdwtf": "6296.00"
            },
            {
                "tid": "HU4RTToTsIfTryK5",
                "pid": "18303940",
                "hs": 0,
                "rmid": "219326294",
                "rtid": "282104848",
                "refundable": true,
                "available_rooms": 161,
                "score": 466,
                "sno": "235",
                "cont": {
                    "_id": "600d3e7da1bc371b80f92f24",
                    "name": "Rove Trade Centre",
                    "ratings": "3.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.231948,
                            "longitude": 55.285539
                        }
                    },
                    "externalId": "18303940",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/19000000/18310000/18304000/18303940/d2ae663d_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.8",
                        "staff_service": "4.7",
                        "amenities": "4.5",
                        "conditions_facilities": "4.7"
                    },
                    "rat": "3.0",
                    "urat": "4.6",
                    "expediareview": 379,
                    "pid": "18303940",
                    "line1": "2nd December Road, Jafiliya",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Dubai World Trade Centre - 0.8 km / 0.5 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 985.21,
                "dwtf": 6378.43,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 412.97,
                "com_disc": 0,
                "rtf": "5393.00",
                "rdwtf": "6378.00"
            },
            {
                "tid": "wbMF9a3UvBwOXTTD",
                "pid": "16225389",
                "hs": 0,
                "rmid": "219611063",
                "rtid": "284050217",
                "refundable": true,
                "available_rooms": 145,
                "score": 348,
                "sno": "236",
                "cont": {
                    "_id": "600d41daa1bc371b80fdcc6f",
                    "name": "Rove City Centre",
                    "ratings": "3.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.255882,
                            "longitude": 55.332356
                        }
                    },
                    "externalId": "16225389",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/17000000/16230000/16225400/16225389/df149add_b.jpg",
                    "ha": [
                        "POOL",
                        "POOL",
                        "WIFI",
                        "GYM"
                    ],
                    "guestreview": {
                        "cleaniness": "4.7",
                        "staff_service": "4.6",
                        "amenities": "4.4",
                        "conditions_facilities": "4.6"
                    },
                    "rat": "3.0",
                    "urat": "4.6",
                    "expediareview": 572,
                    "pid": "16225389",
                    "line1": "24 19b Street",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "City Centre Deira - 0.8 km / 0.5 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 985.21,
                "dwtf": 6378.43,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 412.97,
                "com_disc": 0,
                "rtf": "5393.00",
                "rdwtf": "6378.00"
            },
            {
                "tid": "GBo1dXkiLJpkCaVe",
                "pid": "24197743",
                "hs": 0,
                "rmid": "216907521",
                "rtid": "266028201",
                "refundable": true,
                "available_rooms": 7,
                "score": 478,
                "sno": "367",
                "cont": {
                    "_id": "600d4104a1bc371b80fc9fd7",
                    "name": "Hyatt Regency Dubai Creek Heights Residences",
                    "ratings": "0.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.23408,
                            "longitude": 55.32427
                        }
                    },
                    "externalId": "24197743",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/25000000/24200000/24197800/24197743/8c97de64_b.jpg",
                    "ha": [
                        "BAR",
                        "GYM",
                        "POOL",
                        "BAR",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.4",
                        "staff_service": "4.1",
                        "amenities": "4.4",
                        "conditions_facilities": "4.4"
                    },
                    "rat": "0.0",
                    "urat": "4.1",
                    "expediareview": 152,
                    "pid": "24197743",
                    "line1": "20th Street",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Mohammed Bin Rashid University Of Medicine and Health Sciences - 0.7 km / 0.5 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 985.21,
                "dwtf": 8940.81,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 606.73,
                "com_disc": 0,
                "rtf": "7956.00",
                "rdwtf": "8941.00"
            },
            {
                "tid": "2mGlNqRboiLhvpQ7",
                "pid": "9709394",
                "hs": 0,
                "rmid": "200935637",
                "rtid": "204686945",
                "refundable": true,
                "available_rooms": 8,
                "score": 541,
                "sno": "368",
                "cont": {
                    "_id": "600d3f40a1bc371b80fa3667",
                    "name": "Hyatt Regency Dubai Creek Heights",
                    "ratings": "5.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.23371,
                            "longitude": 55.323698
                        }
                    },
                    "externalId": "9709394",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/10000000/9710000/9709400/9709394/7fcc9f83_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "POOL",
                        "BAR",
                        "POOL"
                    ],
                    "guestreview": {
                        "cleaniness": "4.4",
                        "staff_service": "4.2",
                        "amenities": "4.1",
                        "conditions_facilities": "4.3"
                    },
                    "rat": "5.0",
                    "urat": "4.2",
                    "expediareview": 526,
                    "pid": "9709394",
                    "line1": "20th Street, Dubai Healthcare City",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Mohammed Bin Rashid University Of Medicine and Health Sciences - 0.7 km / 0.4 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 985.21,
                "dwtf": 8971.19,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 605.09,
                "com_disc": 0,
                "rtf": "7986.00",
                "rdwtf": "8971.00"
            },
            {
                "tid": "i356n0AV5lCBTKPq",
                "pid": "15705800",
                "hs": 0,
                "rmid": "202452252",
                "rtid": "384104873",
                "refundable": false,
                "available_rooms": 1,
                "score": 217,
                "sno": "380",
                "cont": {
                    "_id": "600d3df2a1bc371b80f85729",
                    "name": "Pearl Hotel & Spa",
                    "ratings": "3.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.545109,
                            "longitude": 55.558088
                        }
                    },
                    "externalId": "15705800",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/16000000/15710000/15705800/15705800/83b7ce22_b.jpg",
                    "ha": [
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.2",
                        "staff_service": "4.2",
                        "amenities": "4.3",
                        "conditions_facilities": "4.1"
                    },
                    "rat": "3.0",
                    "urat": "4.3",
                    "expediareview": 30,
                    "pid": "15705800",
                    "line1": "Sheikh Ahmed Bin Al Moalla Street",
                    "city": "Umm al Quwain",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Marine Research Center and Aquarium - 6.9 km / 4.3 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 985.21,
                "dwtf": 9384.98,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 655.17,
                "com_disc": 0,
                "rtf": "8400.00",
                "rdwtf": "9385.00"
            },
            {
                "tid": "pexQOf2bzcpyiTOM",
                "pid": "38869896",
                "hs": 0,
                "rmid": "216949080",
                "rtid": "266218519",
                "refundable": false,
                "available_rooms": 2,
                "score": 753,
                "sno": "381",
                "cont": {
                    "_id": "600d3dd3a1bc371b80f83301",
                    "name": "FIVE Jumeirah Village Dubai",
                    "ratings": "5.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.054013,
                            "longitude": 55.207195
                        }
                    },
                    "externalId": "38869896",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/39000000/38870000/38869900/38869896/84a552fa_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "POOL",
                        "BAR",
                        "POOL"
                    ],
                    "guestreview": {
                        "cleaniness": "4.4",
                        "staff_service": "4.3",
                        "amenities": "4.3",
                        "conditions_facilities": "4.4"
                    },
                    "rat": "5.0",
                    "urat": "4.1",
                    "expediareview": 370,
                    "pid": "38869896",
                    "line1": "Jumeirah Village Circle",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Al Barsha Pond Park - 7.9 km / 4.9 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 985.21,
                "dwtf": 9420.28,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 644.49,
                "com_disc": 0,
                "rtf": "8435.00",
                "rdwtf": "9420.00"
            },
            {
                "tid": "aw9q0Gw4ntvv8p1S",
                "pid": "21902448",
                "hs": 0,
                "rmid": "202399902",
                "rtid": "294237453",
                "refundable": false,
                "available_rooms": 18,
                "score": 228,
                "sno": "41",
                "cont": {
                    "_id": "600d3ef1a1bc371b80f9c14a",
                    "name": "Al Khoory Inn",
                    "ratings": "0.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.258495,
                            "longitude": 55.300461
                        }
                    },
                    "externalId": "21902448",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/22000000/21910000/21902500/21902448/f5711497_b.jpg",
                    "ha": [
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.4",
                        "staff_service": "4.5",
                        "amenities": "4.1",
                        "conditions_facilities": "4.3"
                    },
                    "rat": "0.0",
                    "urat": "4.3",
                    "expediareview": 93,
                    "pid": "21902448",
                    "line1": "17th Street",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Bastakiya - 0.8 km / 0.5 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 903.11,
                "dwtf": 4265.97,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 256.98,
                "com_disc": 0,
                "rtf": "3363.00",
                "rdwtf": "4266.00"
            },
            {
                "tid": "lSqFNtAdKysoW55p",
                "pid": "1001421",
                "hs": 0,
                "rmid": "211804664",
                "rtid": "232170220",
                "refundable": true,
                "available_rooms": 5,
                "score": 393,
                "sno": "157",
                "cont": {
                    "_id": "600d4104a1bc371b80fc9ffd",
                    "name": "Le Meridien Fairway",
                    "ratings": "4.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.247886,
                            "longitude": 55.337717
                        }
                    },
                    "externalId": "1001421",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/2000000/1010000/1001500/1001421/aed41503_b.jpg",
                    "ha": [
                        "BAR",
                        "GYM",
                        "POOL",
                        "POOL",
                        "BAR"
                    ],
                    "guestreview": {
                        "cleaniness": "4.4",
                        "staff_service": "4.3",
                        "amenities": "4.6",
                        "conditions_facilities": "4.0"
                    },
                    "rat": "4.0",
                    "urat": "4.1",
                    "expediareview": 977,
                    "pid": "1001421",
                    "line1": "Al Garhoud Road - Dubai",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Dubai Tennis Stadium - 0.8 km / 0.5 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 903.11,
                "dwtf": 5320.15,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 306.24,
                "com_disc": 0,
                "rtf": "4417.00",
                "rdwtf": "5320.00"
            },
            {
                "tid": "xdc7T1VmWyNz2b5M",
                "pid": "22779634",
                "hs": 0,
                "rmid": "220527774",
                "rtid": "290095208",
                "refundable": true,
                "available_rooms": 201,
                "score": 538,
                "sno": "197",
                "cont": {
                    "_id": "600d3eaaa1bc371b80f96a3f",
                    "name": "Rove Dubai Marina",
                    "ratings": "3.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.069649,
                            "longitude": 55.128236
                        }
                    },
                    "externalId": "22779634",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/23000000/22780000/22779700/22779634/f61c10f3_b.jpg",
                    "ha": [
                        "POOL",
                        "WIFI",
                        "GYM"
                    ],
                    "guestreview": {
                        "cleaniness": "4.7",
                        "staff_service": "4.6",
                        "amenities": "4.4",
                        "conditions_facilities": "4.6"
                    },
                    "rat": "3.0",
                    "urat": "4.6",
                    "expediareview": 613,
                    "pid": "22779634",
                    "line1": "Dubai Marina Promenade",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "The Walk - 1.4 km / 0.9 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 903.11,
                "dwtf": 5753.65,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 371.1,
                "com_disc": 0,
                "rtf": "4851.00",
                "rdwtf": "5754.00"
            },
            {
                "tid": "OEiVobyW38TSPTVl",
                "pid": "4649108",
                "hs": 0,
                "rmid": "210602820",
                "rtid": "224084224",
                "refundable": true,
                "available_rooms": 2147483647,
                "score": 478,
                "sno": "241",
                "cont": {
                    "_id": "600d41e3a1bc371b80fdd7a6",
                    "name": "The Canvas Hotel Dubai - MGallery",
                    "ratings": "5.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.255469,
                            "longitude": 55.285593
                        }
                    },
                    "externalId": "4649108",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/5000000/4650000/4649200/4649108/c577331b_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.3",
                        "staff_service": "4.3",
                        "amenities": "4.1",
                        "conditions_facilities": "4.2"
                    },
                    "rat": "5.0",
                    "urat": "4.1",
                    "expediareview": 997,
                    "pid": "4649108",
                    "line1": "23, Kuwait Street",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Meena Bazaar - 1.5 km / 0.9 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 903.11,
                "dwtf": 6342.31,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 385.88,
                "com_disc": 0,
                "rtf": "5439.00",
                "rdwtf": "6342.00"
            },
            {
                "tid": "Mrsz5qnEGSZHQwhe",
                "pid": "74999217",
                "hs": 0,
                "rmid": "316117881",
                "rtid": "385463746",
                "refundable": false,
                "available_rooms": 7,
                "score": 43,
                "sno": "348",
                "cont": {
                    "_id": "62506bbe2aee3e1bb50b69ff",
                    "ratings": "0.0",
                    "name": "City Stay Grand Hotel Apartments",
                    "location": {
                        "coordinates": {
                            "latitude": 25.113031,
                            "longitude": 55.201831
                        },
                        "obfuscatedCoordinates": {
                            "latitude": 0.002121,
                            "longitude": 0.000487
                        },
                        "obfuscationRequired": false
                    },
                    "externalId": "74999217",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/75000000/75000000/74999300/74999217/d908967d_b.jpg",
                    "ha": [],
                    "guestreview": {
                        "cleaniness": 0,
                        "staff_service": 0,
                        "amenities": 0,
                        "conditions_facilities": 0
                    },
                    "rat": "0.0",
                    "urat": "",
                    "expediareview": 0,
                    "pid": "74999217",
                    "line1": "11th B Street",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Sheikh Zayed Road - 1.1 km / 0.7 mi "
                },
                "hdtype": "HOTELHD",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 903.11,
                "dwtf": 8421.93,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 646.96,
                "com_disc": 0,
                "rtf": "7519.00",
                "rdwtf": "8422.00"
            },
            {
                "tid": "gbu28xSSIxvwLNrJ",
                "pid": "2393053",
                "hs": 0,
                "rmid": "335579",
                "rtid": "1038966",
                "refundable": true,
                "available_rooms": 3,
                "score": 184,
                "sno": "350",
                "cont": {
                    "_id": "600d3f6fa1bc371b80fa7b9a",
                    "name": "Ramada Hotel & Suites by Wyndham Ajman",
                    "ratings": "4.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.389376,
                            "longitude": 55.463227
                        }
                    },
                    "externalId": "2393053",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/3000000/2400000/2393100/2393053/0385411d_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.0",
                        "staff_service": "4.0",
                        "amenities": "4.0",
                        "conditions_facilities": "3.8"
                    },
                    "rat": "4.0",
                    "urat": "3.9",
                    "expediareview": 346,
                    "pid": "2393053",
                    "line1": "Sheikh Khalifa Bin Zayed Street",
                    "city": "Ajman",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Safeer Mall - 2.1 km / 1.3 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 903.11,
                "dwtf": 8477.76,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 500,
                "com_disc": 0,
                "rtf": "7575.00",
                "rdwtf": "8478.00"
            },
            {
                "tid": "D1BAgOv8TMBAu9ID",
                "pid": "32905077",
                "hs": 0,
                "rmid": "218740846",
                "rtid": "278236186",
                "refundable": true,
                "available_rooms": 11,
                "score": 404,
                "sno": "364",
                "cont": {
                    "_id": "600d4258a1bc371b80fe716c",
                    "name": "Hyatt Place Dubai Wasl District",
                    "ratings": "4.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.270555,
                            "longitude": 55.315121
                        }
                    },
                    "externalId": "32905077",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/33000000/32910000/32905100/32905077/81253d2f_b.jpg",
                    "ha": [
                        "BAR",
                        "POOL",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.5",
                        "staff_service": "4.4",
                        "amenities": "4.2",
                        "conditions_facilities": "4.4"
                    },
                    "rat": "4.0",
                    "urat": "4.3",
                    "expediareview": 232,
                    "pid": "32905077",
                    "line1": "Al Wasl District",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Fish Roundabout - 0.1 km / 0.1 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Breakfast"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 903.11,
                "dwtf": 8788.92,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 444.99,
                "com_disc": 0,
                "rtf": "7886.00",
                "rdwtf": "8789.00"
            },
            {
                "tid": "6xG3GbTaD3uNJDr4",
                "pid": "8023381",
                "hs": 0,
                "rmid": "201962304",
                "rtid": "209983314",
                "refundable": true,
                "available_rooms": 5,
                "score": 545,
                "sno": "365",
                "cont": {
                    "_id": "600d4005a1bc371b80fb342f",
                    "name": "Carlton Downtown",
                    "ratings": "4.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.209508,
                            "longitude": 55.274126
                        }
                    },
                    "externalId": "8023381",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/9000000/8030000/8023400/8023381/4435a90c_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "POOL",
                        "BAR",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.2",
                        "staff_service": "4.1",
                        "amenities": "4.2",
                        "conditions_facilities": "4.2"
                    },
                    "rat": "4.0",
                    "urat": "4.1",
                    "expediareview": 973,
                    "pid": "8023381",
                    "line1": "Sheikh Zayed Road",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Dubai International Financial Centre - 1 km / 0.6 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 903.11,
                "dwtf": 8793.85,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 602.62,
                "com_disc": 0,
                "rtf": "7891.00",
                "rdwtf": "8794.00"
            },
            {
                "tid": "s9UeQHL0Mfn6677e",
                "pid": "917157",
                "hs": 0,
                "rmid": "201030006",
                "rtid": "385906712",
                "refundable": false,
                "available_rooms": 8,
                "score": 212,
                "sno": "134",
                "cont": {
                    "_id": "600d40aaa1bc371b80fc1c74",
                    "name": "Landmark Plaza Hotel",
                    "ratings": "3.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.269122,
                            "longitude": 55.30687
                        }
                    },
                    "externalId": "917157",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/1000000/920000/917200/917157/d2080ce4_b.jpg",
                    "ha": [
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "3.6",
                        "staff_service": "3.7",
                        "amenities": "3.5",
                        "conditions_facilities": "3.4"
                    },
                    "rat": "3.0",
                    "urat": "3.6",
                    "expediareview": 213,
                    "pid": "917157",
                    "line1": "Baniyas Square, Deira",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Naif Souq - 0.6 km / 0.4 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 821.01,
                "dwtf": 5046.75,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 322.66,
                "com_disc": 0,
                "rtf": "4226.00",
                "rdwtf": "5047.00"
            },
            {
                "tid": "5GtgKaGS0dta9pmr",
                "pid": "9774377",
                "hs": 0,
                "rmid": "200876272",
                "rtid": "205754625",
                "refundable": false,
                "available_rooms": 5,
                "score": 268,
                "sno": "313",
                "cont": {
                    "_id": "600d3e6aa1bc371b80f90c67",
                    "name": "XVA Art Hotel",
                    "ratings": "0.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.264176,
                            "longitude": 55.299908
                        }
                    },
                    "externalId": "9774377",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/10000000/9780000/9774400/9774377/4c0d9c29_b.jpg",
                    "ha": [
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.6",
                        "staff_service": "4.6",
                        "amenities": "4.1",
                        "conditions_facilities": "4.4"
                    },
                    "rat": "0.0",
                    "urat": "4.5",
                    "expediareview": 115,
                    "pid": "9774377",
                    "line1": "Al Fahidi Neighborhood, Bur Dubai",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Bastakiya - 0.1 km / 0.1 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Breakfast"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 821.01,
                "dwtf": 7475.31,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 510.67,
                "com_disc": 0,
                "rtf": "6654.00",
                "rdwtf": "7475.00"
            },
            {
                "tid": "O4ESNyePSH2ZhD6N",
                "pid": "10974533",
                "hs": 0,
                "rmid": "201152895",
                "rtid": "280074937",
                "refundable": true,
                "available_rooms": 6,
                "score": 434,
                "sno": "327",
                "cont": {
                    "_id": "600d416ba1bc371b80fd10af",
                    "name": "Hyatt Place Dubai Baniyas Square",
                    "ratings": "4.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.268574,
                            "longitude": 55.305298
                        }
                    },
                    "externalId": "10974533",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/11000000/10980000/10974600/10974533/7403a5e3_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.3",
                        "staff_service": "4.3",
                        "amenities": "3.8",
                        "conditions_facilities": "4.2"
                    },
                    "rat": "4.0",
                    "urat": "4.1",
                    "expediareview": 480,
                    "pid": "10974533",
                    "line1": "Baniyas Square -14th Road, Diera",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Deira Twin Towers Shopping Centre - 0.5 km / 0.3 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Breakfast"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 821.01,
                "dwtf": 7720.79,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 389.98,
                "com_disc": 0,
                "rtf": "6900.00",
                "rdwtf": "7721.00"
            },
            {
                "tid": "E2XKaOtIZ23tdv0c",
                "pid": "83995",
                "hs": 0,
                "rmid": "43050",
                "rtid": "97938",
                "refundable": true,
                "available_rooms": 9,
                "score": 452,
                "sno": "332",
                "cont": {
                    "_id": "600d4327a1bc371b80ff854a",
                    "name": "Carlton Palace Hotel",
                    "ratings": "5.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.261267,
                            "longitude": 55.320108
                        }
                    },
                    "externalId": "83995",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/1000000/90000/84000/83995/497b6bb8_b.jpg",
                    "ha": [
                        "BAR",
                        "GYM",
                        "POOL",
                        "POOL",
                        "BAR"
                    ],
                    "guestreview": {
                        "cleaniness": "4.2",
                        "staff_service": "4.2",
                        "amenities": "4.0",
                        "conditions_facilities": "4.0"
                    },
                    "rat": "5.0",
                    "urat": "4.1",
                    "expediareview": 997,
                    "pid": "83995",
                    "line1": "Al Maktoum Street",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Al Ghurair Centre - 1.1 km / 0.7 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 821.01,
                "dwtf": 7864.46,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 537.76,
                "com_disc": 0,
                "rtf": "7043.00",
                "rdwtf": "7864.00"
            },
            {
                "tid": "yQVgQBoLQVXdqF0r",
                "pid": "795905",
                "hs": 0,
                "rmid": "321286771",
                "rtid": "388495494",
                "refundable": true,
                "available_rooms": 33,
                "score": 668,
                "sno": "340",
                "cont": {
                    "_id": "600d4051a1bc371b80fb997b",
                    "name": "Dusit Thani Dubai",
                    "ratings": "5.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.206041,
                            "longitude": 55.273234
                        }
                    },
                    "externalId": "795905",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/1000000/800000/796000/795905/f711ef2f_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "POOL",
                        "POOL",
                        "BAR"
                    ],
                    "guestreview": {
                        "cleaniness": "4.6",
                        "staff_service": "4.5",
                        "amenities": "4.4",
                        "conditions_facilities": "4.4"
                    },
                    "rat": "5.0",
                    "urat": "4.4",
                    "expediareview": 971,
                    "pid": "795905",
                    "line1": "133 Sheikh Zayed Road",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Dubai International Financial Centre - 1.3 km / 0.8 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 821.01,
                "dwtf": 8116.51,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 558.29,
                "com_disc": 0,
                "rtf": "7296.00",
                "rdwtf": "8117.00"
            },
            {
                "tid": "Etp76NyUArfIlyZ8",
                "pid": "10073644",
                "hs": 0,
                "rmid": "200931463",
                "rtid": "272149346",
                "refundable": false,
                "available_rooms": 9,
                "score": 164,
                "sno": "7",
                "cont": {
                    "_id": "600d3ef8a1bc371b80f9c92e",
                    "name": "Mount Royal Hotel",
                    "ratings": "2.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.270152,
                            "longitude": 55.306036
                        }
                    },
                    "externalId": "10073644",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/11000000/10080000/10073700/10073644/01c38855_b.jpg",
                    "ha": [
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "2.7",
                        "staff_service": "2.8",
                        "amenities": "3.0",
                        "conditions_facilities": "2.5"
                    },
                    "rat": "2.0",
                    "urat": "2.6",
                    "expediareview": 36,
                    "pid": "10073644",
                    "line1": "Al Naser Square Deira",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Naif Souq - 0.4 km / 0.2 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 738.91,
                "dwtf": 3560.72,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 174.88,
                "com_disc": 0,
                "rtf": "2822.00",
                "rdwtf": "3561.00"
            },
            {
                "tid": "XNuMun9RX14uVaiZ",
                "pid": "15472935",
                "hs": 0,
                "rmid": "221719811",
                "rtid": "298193753",
                "refundable": false,
                "available_rooms": 10,
                "score": 179,
                "sno": "9",
                "cont": {
                    "_id": "600d3ef6a1bc371b80f9c729",
                    "name": "Al Khaleej Grand Hotel",
                    "ratings": "3.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.268428,
                            "longitude": 55.30356
                        }
                    },
                    "externalId": "15472935",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/16000000/15480000/15473000/15472935/5e809904_b.jpg",
                    "ha": [
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "3.3",
                        "staff_service": "3.3",
                        "amenities": "2.7",
                        "conditions_facilities": "3.2"
                    },
                    "rat": "3.0",
                    "urat": "3.2",
                    "expediareview": 210,
                    "pid": "15472935",
                    "line1": "Baniyas Square",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Naif Souq - 0.5 km / 0.3 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 738.91,
                "dwtf": 3607.52,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 178.98,
                "com_disc": 0,
                "rtf": "2869.00",
                "rdwtf": "3608.00"
            },
            {
                "tid": "35Qgg4fFznibhiId",
                "pid": "57120046",
                "hs": 0,
                "rmid": "314240866",
                "rtid": "382745814",
                "refundable": false,
                "available_rooms": 27,
                "score": 321,
                "sno": "12",
                "cont": {
                    "_id": "600d3eaea1bc371b80f96ef5",
                    "name": "Lotus Retreat Hotel",
                    "ratings": "0.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.265322,
                            "longitude": 55.321107
                        }
                    },
                    "externalId": "57120046",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/58000000/57130000/57120100/57120046/962f04c6_b.jpg",
                    "ha": [
                        "POOL"
                    ],
                    "guestreview": {
                        "cleaniness": "5.0",
                        "staff_service": "5.0",
                        "amenities": 0,
                        "conditions_facilities": "5.0"
                    },
                    "rat": "0.0",
                    "urat": "5.0",
                    "expediareview": 1,
                    "pid": "57120046",
                    "line1": "Al Rigga Street, Dubai,",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Al Ghurair Centre - 0.4 km / 0.3 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 738.91,
                "dwtf": 3676.49,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 198.68,
                "com_disc": 0,
                "rtf": "2938.00",
                "rdwtf": "3676.00"
            },
            {
                "tid": "28uk0hPWday4SEDp",
                "pid": "44874529",
                "hs": 0,
                "rmid": "216433539",
                "rtid": "384945169",
                "refundable": false,
                "available_rooms": 2147483647,
                "score": 412,
                "sno": "86",
                "cont": {
                    "_id": "600d424da1bc371b80fe6301",
                    "name": "Grand Mercure Dubai Airport Hotel",
                    "ratings": "4.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.250724,
                            "longitude": 55.343463
                        }
                    },
                    "externalId": "44874529",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/45000000/44880000/44874600/44874529/010e782f_b.jpg",
                    "ha": [
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": 0,
                        "staff_service": 0,
                        "amenities": 0,
                        "conditions_facilities": 0
                    },
                    "rat": "4.0",
                    "urat": "",
                    "expediareview": 0,
                    "pid": "44874529",
                    "line1": "61B Street, Garhoud",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Dubai Tennis Stadium - 1 km / 0.6 mi "
                },
                "hdtype": "HOTELHD",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 738.91,
                "dwtf": 4517.2,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 287.35,
                "com_disc": 0,
                "rtf": "3778.00",
                "rdwtf": "4517.00"
            },
            {
                "tid": "E9p5rmdGpDc34gTJ",
                "pid": "16700525",
                "hs": 0,
                "rmid": "220527760",
                "rtid": "290095145",
                "refundable": true,
                "available_rooms": 177,
                "score": 381,
                "sno": "108",
                "cont": {
                    "_id": "600d3dcba1bc371b80f82bac",
                    "name": "Rove Healthcare City",
                    "ratings": "3.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.23444,
                            "longitude": 55.312975
                        }
                    },
                    "externalId": "16700525",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/17000000/16710000/16700600/16700525/cbc9f26f_b.jpg",
                    "ha": [
                        "POOL",
                        "WIFI",
                        "GYM"
                    ],
                    "guestreview": {
                        "cleaniness": "4.6",
                        "staff_service": "4.6",
                        "amenities": "4.4",
                        "conditions_facilities": "4.7"
                    },
                    "rat": "3.0",
                    "urat": "4.6",
                    "expediareview": 374,
                    "pid": "16700525",
                    "line1": "19th Street, Oud Metha, Bur Dubai",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "American Hospital Dubai - 0.3 km / 0.2 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 738.91,
                "dwtf": 4776.64,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 308.7,
                "com_disc": 0,
                "rtf": "4038.00",
                "rdwtf": "4777.00"
            },
            {
                "tid": "W4JCfq1mxBTDAnD7",
                "pid": "60539202",
                "hs": 0,
                "rmid": "231041287",
                "rtid": "280294428",
                "refundable": true,
                "available_rooms": 10,
                "score": 414,
                "sno": "186",
                "cont": {
                    "_id": "62506b1b2aee3e1bb50b67a2",
                    "ratings": "4.0",
                    "name": "La Quinta by Wyndham Dubai Jumeirah",
                    "location": {
                        "coordinates": {
                            "latitude": 25.249073,
                            "longitude": 55.283002
                        },
                        "obfuscatedCoordinates": {
                            "latitude": 25.234024,
                            "longitude": 55.299987
                        },
                        "obfuscationRequired": false
                    },
                    "externalId": "60539202",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/61000000/60540000/60539300/60539202/875035a9_b.jpg",
                    "ha": [
                        "POOL",
                        "POOL",
                        "WIFI",
                        "GYM"
                    ],
                    "guestreview": {
                        "cleaniness": "4.0",
                        "staff_service": "3.5",
                        "amenities": "3.5",
                        "conditions_facilities": "3.0"
                    },
                    "rat": "4.0",
                    "urat": "3.0",
                    "expediareview": 3,
                    "pid": "60539202",
                    "line1": "Sheikh Rashid Rd",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Etihad Museum - 2.2 km / 1.4 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 738.91,
                "dwtf": 5508.16,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 358.78,
                "com_disc": 0,
                "rtf": "4769.00",
                "rdwtf": "5508.00"
            },
            {
                "tid": "2TwetSNIgX2wsgb8",
                "pid": "1937184",
                "hs": 0,
                "rmid": "200761205",
                "rtid": "203786612",
                "refundable": true,
                "available_rooms": 3,
                "score": 283,
                "sno": "239",
                "cont": {
                    "_id": "600d4048a1bc371b80fb8e23",
                    "name": "Royal Ascot Hotel",
                    "ratings": "4.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.260088,
                            "longitude": 55.285944
                        }
                    },
                    "externalId": "1937184",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/2000000/1940000/1937200/1937184/baede420_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "3.9",
                        "staff_service": "3.7",
                        "amenities": "4.2",
                        "conditions_facilities": "3.6"
                    },
                    "rat": "4.0",
                    "urat": "3.6",
                    "expediareview": 105,
                    "pid": "1937184",
                    "line1": "Khalid Bin Walid Road, Bur Dubai",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Meena Bazaar - 1.1 km / 0.7 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 738.91,
                "dwtf": 6164.15,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 403.12,
                "com_disc": 0,
                "rtf": "5425.00",
                "rdwtf": "6164.00"
            },
            {
                "tid": "dRMfIolCJivlP8CB",
                "pid": "66252433",
                "hs": 0,
                "rmid": "228967315",
                "rtid": "386473097",
                "refundable": false,
                "available_rooms": 5,
                "score": 249,
                "sno": "263",
                "cont": {
                    "_id": "62506c112aee3e1bb50b6b38",
                    "ratings": "0.0",
                    "name": "Element Al Mina, Dubai",
                    "location": {
                        "coordinates": {
                            "latitude": 25.24155,
                            "longitude": 55.29273
                        },
                        "obfuscationRequired": false
                    },
                    "externalId": "66252433",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/67000000/66260000/66252500/66252433/9fbf67d5_b.jpg",
                    "ha": [
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "2.0",
                        "staff_service": "2.0",
                        "amenities": 0,
                        "conditions_facilities": "4.0"
                    },
                    "rat": "0.0",
                    "urat": "4.0",
                    "expediareview": 1,
                    "pid": "66252433",
                    "line1": "Sheikh Rashid Road & 37th Street",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Zabeel Park - 1 km / 0.6 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 738.91,
                "dwtf": 6447.4,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 430.21,
                "com_disc": 0,
                "rtf": "5708.00",
                "rdwtf": "6447.00"
            },
            {
                "tid": "mI7uEqizg3BkGofa",
                "pid": "65306293",
                "hs": 0,
                "rmid": "227765115",
                "rtid": "258393525",
                "refundable": false,
                "available_rooms": 2,
                "score": 147,
                "sno": "267",
                "cont": {
                    "_id": "62506bfb2aee3e1bb50b6ae8",
                    "ratings": "0.0",
                    "name": "Grand Heights Hotel Apartments",
                    "location": {
                        "coordinates": {
                            "latitude": 25.097117,
                            "longitude": 55.175354
                        },
                        "obfuscationRequired": false
                    },
                    "externalId": "65306293",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/66000000/65310000/65306300/65306293/339a9410_b.jpg",
                    "ha": [
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.3",
                        "staff_service": "4.4",
                        "amenities": "4.2",
                        "conditions_facilities": "4.1"
                    },
                    "rat": "0.0",
                    "urat": "4.1",
                    "expediareview": 24,
                    "pid": "65306293",
                    "line1": "Al Thaniya Street, Barsha Heights",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Jebel Ali Race Course - 1.2 km / 0.7 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 738.91,
                "dwtf": 6511.44,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 440.06,
                "com_disc": 0,
                "rtf": "5773.00",
                "rdwtf": "6511.00"
            },
            {
                "tid": "fySdX6kgwTiKWMK8",
                "pid": "39914722",
                "hs": 0,
                "rmid": "213928310",
                "rtid": "246172953",
                "refundable": true,
                "available_rooms": 41,
                "score": 371,
                "sno": "271",
                "cont": {
                    "_id": "600d3fc8a1bc371b80fae1df",
                    "name": "Millennium Place Barsha Heights Hotel",
                    "ratings": "4.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.09266,
                            "longitude": 55.17597
                        }
                    },
                    "externalId": "39914722",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/40000000/39920000/39914800/39914722/efdbc6ff_b.jpg",
                    "ha": [
                        "BAR",
                        "POOL",
                        "BAR",
                        "POOL",
                        "POOL"
                    ],
                    "guestreview": {
                        "cleaniness": "4.3",
                        "staff_service": "3.9",
                        "amenities": "3.9",
                        "conditions_facilities": "4.1"
                    },
                    "rat": "4.0",
                    "urat": "3.8",
                    "expediareview": 46,
                    "pid": "39914722",
                    "line1": "TECOM BARSHA HEIGHTS",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Jebel Ali Race Course - 1.2 km / 0.7 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 738.91,
                "dwtf": 6583.69,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 444.17,
                "com_disc": 0,
                "rtf": "5845.00",
                "rdwtf": "6584.00"
            },
            {
                "tid": "KpkkdE3XkmVg8UGr",
                "pid": "11788864",
                "hs": 0,
                "rmid": "201202214",
                "rtid": "387088066",
                "refundable": false,
                "available_rooms": 35,
                "score": 384,
                "sno": "273",
                "cont": {
                    "_id": "600d3f86a1bc371b80fa96f1",
                    "name": "Flora Al Barsha Hotel at the Mall",
                    "ratings": "4.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.117794,
                            "longitude": 55.196512
                        }
                    },
                    "externalId": "11788864",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/12000000/11790000/11788900/11788864/2fc29d20_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.6",
                        "staff_service": "4.5",
                        "amenities": "4.1",
                        "conditions_facilities": "4.5"
                    },
                    "rat": "4.0",
                    "urat": "4.4",
                    "expediareview": 480,
                    "pid": "11788864",
                    "line1": "Al Barsha Road",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Mall of the Emirates - 0.5 km / 0.3 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 738.91,
                "dwtf": 6627.2,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 449.09,
                "com_disc": 0,
                "rtf": "5888.00",
                "rdwtf": "6627.00"
            },
            {
                "tid": "vHuebuuVr97f3SuV",
                "pid": "25756019",
                "hs": 0,
                "rmid": "219315487",
                "rtid": "387076751",
                "refundable": false,
                "available_rooms": 35,
                "score": 412,
                "sno": "274",
                "cont": {
                    "_id": "600d4108a1bc371b80fca50f",
                    "name": "Flora Inn Hotel Dubai Airport",
                    "ratings": "4.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.252514,
                            "longitude": 55.343157
                        }
                    },
                    "externalId": "25756019",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/26000000/25760000/25756100/25756019/5e60c601_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "POOL",
                        "POOL"
                    ],
                    "guestreview": {
                        "cleaniness": "4.5",
                        "staff_service": "4.4",
                        "amenities": "4.3",
                        "conditions_facilities": "4.5"
                    },
                    "rat": "4.0",
                    "urat": "4.4",
                    "expediareview": 551,
                    "pid": "25756019",
                    "line1": "Airport Road",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Dubai Tennis Stadium - 1.4 km / 0.9 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 738.91,
                "dwtf": 6627.2,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 449.09,
                "com_disc": 0,
                "rtf": "5888.00",
                "rdwtf": "6627.00"
            },
            {
                "tid": "OI6aT0BTmSdzltKm",
                "pid": "863980",
                "hs": 0,
                "rmid": "211211666",
                "rtid": "228219270",
                "refundable": true,
                "available_rooms": 2147483647,
                "score": 511,
                "sno": "276",
                "cont": {
                    "_id": "600d4322a1bc371b80ff807f",
                    "name": "Movenpick Hotel & Apartments Bur Dubai",
                    "ratings": "5.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.234275,
                            "longitude": 55.311687
                        }
                    },
                    "externalId": "863980",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/1000000/870000/864000/863980/fe475bbf_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "POOL",
                        "BAR",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.5",
                        "staff_service": "4.4",
                        "amenities": "4.1",
                        "conditions_facilities": "4.3"
                    },
                    "rat": "5.0",
                    "urat": "4.3",
                    "expediareview": 784,
                    "pid": "863980",
                    "line1": "19th Street",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "American Hospital Dubai - 0.4 km / 0.3 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 738.91,
                "dwtf": 6655.94,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 450.74,
                "com_disc": 0,
                "rtf": "5917.00",
                "rdwtf": "6656.00"
            },
            {
                "tid": "SLFCh3Q3ECXBTGzI",
                "pid": "36378545",
                "hs": 0,
                "rmid": "216040138",
                "rtid": "260203235",
                "refundable": false,
                "available_rooms": 40,
                "score": 549,
                "sno": "277",
                "cont": {
                    "_id": "600d41b1a1bc371b80fd8100",
                    "name": "Millennium Place Marina",
                    "ratings": "0.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.071125,
                            "longitude": 55.136799
                        }
                    },
                    "externalId": "36378545",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/37000000/36380000/36378600/36378545/eedebd0d_b.jpg",
                    "ha": [
                        "BAR",
                        "GYM",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.7",
                        "staff_service": "4.7",
                        "amenities": "4.3",
                        "conditions_facilities": "4.6"
                    },
                    "rat": "0.0",
                    "urat": "4.4",
                    "expediareview": 66,
                    "pid": "36378545",
                    "line1": "Al Marsa Street",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Dubai Marina Mall - 0.9 km / 0.6 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Breakfast"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 738.91,
                "dwtf": 6692.88,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 455.66,
                "com_disc": 0,
                "rtf": "5954.00",
                "rdwtf": "6693.00"
            },
            {
                "tid": "seC4IdaltG2SEm09",
                "pid": "42120601",
                "hs": 0,
                "rmid": "216657674",
                "rtid": "264284479",
                "refundable": false,
                "available_rooms": 28,
                "score": 190,
                "sno": "279",
                "cont": {
                    "_id": "600d3f52a1bc371b80fa50cb",
                    "name": "Lavender Hotel & Hotel Apartments Al Nahda",
                    "ratings": "0.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.293117,
                            "longitude": 55.361177
                        }
                    },
                    "externalId": "42120601",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/43000000/42130000/42120700/42120601/38fe0aa7_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "POOL",
                        "POOL"
                    ],
                    "guestreview": {
                        "cleaniness": "4.0",
                        "staff_service": "1.0",
                        "amenities": 0,
                        "conditions_facilities": "4.0"
                    },
                    "rat": "0.0",
                    "urat": "3.0",
                    "expediareview": 1,
                    "pid": "42120601",
                    "line1": "15th Street, Community 231",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Al-Maktoum Stadium - 3.3 km / 2 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 738.91,
                "dwtf": 6749.53,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 460.59,
                "com_disc": 0,
                "rtf": "6011.00",
                "rdwtf": "6750.00"
            },
            {
                "tid": "AJ4XuRW0tUUzfyFD",
                "pid": "4617919",
                "hs": 0,
                "rmid": "316570343",
                "rtid": "387475452",
                "refundable": true,
                "available_rooms": 3,
                "score": 599,
                "sno": "280",
                "cont": {
                    "_id": "600d4339a1bc371b80ff989d",
                    "name": "Millennium Plaza Hotel Dubai",
                    "ratings": "5.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.217927,
                            "longitude": 55.279978
                        }
                    },
                    "externalId": "4617919",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/5000000/4620000/4618000/4617919/ebc7ea32_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "POOL",
                        "BAR",
                        "POOL"
                    ],
                    "guestreview": {
                        "cleaniness": "4.2",
                        "staff_service": "4.2",
                        "amenities": "4.1",
                        "conditions_facilities": "4.1"
                    },
                    "rat": "5.0",
                    "urat": "4.1",
                    "expediareview": 991,
                    "pid": "4617919",
                    "line1": "Sheikh Zayed Road",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Dubai International Financial Centre - 0.4 km / 0.3 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 738.91,
                "dwtf": 6752.82,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 458.95,
                "com_disc": 0,
                "rtf": "6014.00",
                "rdwtf": "6753.00"
            },
            {
                "tid": "30U7WIlNuJXv3eIG",
                "pid": "18811168",
                "hs": 0,
                "rmid": "202026818",
                "rtid": "210383003",
                "refundable": false,
                "available_rooms": 2,
                "score": 132,
                "sno": "282",
                "cont": {
                    "_id": "600d3fbca1bc371b80fad222",
                    "name": "FLORIDA SQUARE HOTEL",
                    "ratings": "0.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.270149,
                            "longitude": 55.303694
                        }
                    },
                    "externalId": "18811168",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/19000000/18820000/18811200/18811168/b0472f10_b.jpg",
                    "ha": [
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "2.8",
                        "staff_service": "2.0",
                        "amenities": 0,
                        "conditions_facilities": "2.5"
                    },
                    "rat": "0.0",
                    "urat": "3.0",
                    "expediareview": 6,
                    "pid": "18811168",
                    "line1": "Sabkha Street, Deira",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Naif Souq - 0.3 km / 0.2 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 738.91,
                "dwtf": 6778.27,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 461.41,
                "com_disc": 0,
                "rtf": "6039.00",
                "rdwtf": "6778.00"
            },
            {
                "tid": "heXtVyOt7UNPJq4H",
                "pid": "1545211",
                "hs": 0,
                "rmid": "321631784",
                "rtid": "389186455",
                "refundable": true,
                "available_rooms": 45,
                "score": 280,
                "sno": "286",
                "cont": {
                    "_id": "600d4118a1bc371b80fcb8b5",
                    "name": "Savoy Crest Hotel Apartments",
                    "ratings": "0.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.252997,
                            "longitude": 55.295926
                        }
                    },
                    "externalId": "1545211",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/2000000/1550000/1545300/1545211/30f4ec86_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "POOL",
                        "POOL"
                    ],
                    "guestreview": {
                        "cleaniness": "4.4",
                        "staff_service": "4.5",
                        "amenities": "4.3",
                        "conditions_facilities": "4.2"
                    },
                    "rat": "0.0",
                    "urat": "4.3",
                    "expediareview": 282,
                    "pid": "1545211",
                    "line1": "Al Mankhool 10b Street, Bur Dubai",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "BurJuman Mall - 1.1 km / 0.7 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 738.91,
                "dwtf": 6825.06,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 467.16,
                "com_disc": 0,
                "rtf": "6086.00",
                "rdwtf": "6825.00"
            },
            {
                "tid": "GLLd6P0HbTA2QDzv",
                "pid": "65401015",
                "hs": 0,
                "rmid": "228964537",
                "rtid": "266374469",
                "refundable": false,
                "available_rooms": 29,
                "score": 120,
                "sno": "297",
                "cont": {
                    "_id": "62506bfc2aee3e1bb50b6aeb",
                    "ratings": "0.0",
                    "name": "Home Away Holiday Homes",
                    "location": {
                        "coordinates": {
                            "latitude": 24.986796,
                            "longitude": 55.202986
                        },
                        "obfuscationRequired": false
                    },
                    "externalId": "65401015",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/66000000/65410000/65401100/65401015/e02d0b32_b.jpg",
                    "ha": [
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": 0,
                        "staff_service": 0,
                        "amenities": 0,
                        "conditions_facilities": 0
                    },
                    "rat": "0.0",
                    "urat": "",
                    "expediareview": 0,
                    "pid": "65401015",
                    "line1": "11th Street 597-810",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Jumeirah Golf Estates - 6.8 km / 4.2 mi "
                },
                "hdtype": "HOTELHD",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 738.91,
                "dwtf": 7054.13,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 568.96,
                "com_disc": 0,
                "rtf": "6315.00",
                "rdwtf": "7054.00"
            },
            {
                "tid": "Xxhk3PdxpwdrmpmS",
                "pid": "33765129",
                "hs": 0,
                "rmid": "215131675",
                "rtid": "254155282",
                "refundable": true,
                "available_rooms": 210,
                "score": 269,
                "sno": "53",
                "cont": {
                    "_id": "600d3f7aa1bc371b80fa89cf",
                    "name": "Rove At The Park",
                    "ratings": "3.0",
                    "location": {
                        "coordinates": {
                            "latitude": 24.911162,
                            "longitude": 55.013314
                        }
                    },
                    "externalId": "33765129",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/34000000/33770000/33765200/33765129/fd6e39f0_b.jpg",
                    "ha": [
                        "POOL",
                        "POOL",
                        "WIFI",
                        "GYM"
                    ],
                    "guestreview": {
                        "cleaniness": "4.6",
                        "staff_service": "4.3",
                        "amenities": "4.4",
                        "conditions_facilities": "4.4"
                    },
                    "rat": "3.0",
                    "urat": "4.3",
                    "expediareview": 100,
                    "pid": "33765129",
                    "line1": "Dubai Parks and Resorts",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Legoland® Dubai - 1 km / 0.6 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 656.81,
                "dwtf": 4159.24,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 267.65,
                "com_disc": 0,
                "rtf": "3502.00",
                "rdwtf": "4159.00"
            },
            {
                "tid": "BNJbTOx3kdCCOhDI",
                "pid": "1338993",
                "hs": 0,
                "rmid": "212700222",
                "rtid": "238002652",
                "refundable": false,
                "available_rooms": 4,
                "score": 281,
                "sno": "101",
                "cont": {
                    "_id": "600d4218a1bc371b80fe1995",
                    "name": "Avenue Hotel",
                    "ratings": "4.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.26241,
                            "longitude": 55.32599
                        }
                    },
                    "externalId": "1338993",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/2000000/1340000/1339000/1338993/7ad9afde_b.jpg",
                    "ha": [
                        "POOL",
                        "POOL",
                        "BAR",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "3.9",
                        "staff_service": "3.9",
                        "amenities": "4.1",
                        "conditions_facilities": "3.7"
                    },
                    "rat": "4.0",
                    "urat": "3.8",
                    "expediareview": 537,
                    "pid": "1338993",
                    "line1": "Al Rigga Street",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Al Ghurair Centre - 1 km / 0.6 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Very Good",
                "brdbs": [
                    "Breakfast"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 656.81,
                "dwtf": 4601.77,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 300.49,
                "com_disc": 0,
                "rtf": "3945.00",
                "rdwtf": "4602.00"
            },
            {
                "tid": "ifhbNPQW8qoifiTi",
                "pid": "9392825",
                "hs": 0,
                "rmid": "200823421",
                "rtid": "204147780",
                "refundable": false,
                "available_rooms": 3,
                "score": 136,
                "sno": "112",
                "cont": {
                    "_id": "600d407da1bc371b80fbd83c",
                    "name": "Saffron Hotel",
                    "ratings": "2.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.261231,
                            "longitude": 55.32557
                        }
                    },
                    "externalId": "9392825",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/10000000/9400000/9392900/9392825/b914cfc8_b.jpg",
                    "ha": [
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "3.1",
                        "staff_service": "3.1",
                        "amenities": "4.0",
                        "conditions_facilities": "2.9"
                    },
                    "rat": "2.0",
                    "urat": "2.9",
                    "expediareview": 86,
                    "pid": "9392825",
                    "line1": "Al Rigga Road, Behind ADCB Bank, Deira",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Al Ghurair Centre - 1.3 km / 0.8 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 656.81,
                "dwtf": 4719.17,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 309.52,
                "com_disc": 0,
                "rtf": "4062.00",
                "rdwtf": "4719.00"
            },
            {
                "tid": "BIXqWoMZ76ob745s",
                "pid": "2870907",
                "hs": 0,
                "rmid": "216643443",
                "rtid": "389702306",
                "refundable": false,
                "available_rooms": 25,
                "score": 249,
                "sno": "182",
                "cont": {
                    "_id": "600d3da8a1bc371b80f7fe5e",
                    "name": "Landmark Hotel Riqqa",
                    "ratings": "4.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.266967,
                            "longitude": 55.320187
                        }
                    },
                    "externalId": "2870907",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/3000000/2880000/2871000/2870907/55cd9511_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "3.7",
                        "staff_service": "3.7",
                        "amenities": "3.3",
                        "conditions_facilities": "3.4"
                    },
                    "rat": "4.0",
                    "urat": "3.6",
                    "expediareview": 211,
                    "pid": "2870907",
                    "line1": "Al-Jazeera Street 28763",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Al Ghurair Centre - 0.4 km / 0.3 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 656.81,
                "dwtf": 5311.94,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 355.5,
                "com_disc": 0,
                "rtf": "4655.00",
                "rdwtf": "5312.00"
            },
            {
                "tid": "utQf5GhnLNupP5Qc",
                "pid": "21854816",
                "hs": 0,
                "rmid": "315910897",
                "rtid": "385749209",
                "refundable": false,
                "available_rooms": 5,
                "score": 397,
                "sno": "185",
                "cont": {
                    "_id": "600d4048a1bc371b80fb8d92",
                    "name": "FORM Hotel Dubai, a Member of Design Hotels",
                    "ratings": "4.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.222936,
                            "longitude": 55.334741
                        }
                    },
                    "externalId": "21854816",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/22000000/21860000/21854900/21854816/710733c2_b.jpg",
                    "ha": [
                        "POOL",
                        "POOL",
                        "BAR",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.6",
                        "staff_service": "4.5",
                        "amenities": "4.3",
                        "conditions_facilities": "4.5"
                    },
                    "rat": "4.0",
                    "urat": "4.3",
                    "expediareview": 202,
                    "pid": "21854816",
                    "line1": "Al Jaddaf",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Mohammed Bin Rashid University Of Medicine and Health Sciences - 3.4 km / 2.1 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 656.81,
                "dwtf": 5407.18,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 357.14,
                "com_disc": 0,
                "rtf": "4750.00",
                "rdwtf": "5407.00"
            },
            {
                "tid": "R31nfZhX8hH2xLtS",
                "pid": "68864674",
                "hs": 0,
                "rmid": "315616493",
                "rtid": "384153483",
                "refundable": false,
                "available_rooms": 17,
                "score": 230,
                "sno": "206",
                "cont": {
                    "_id": "62506a9a2aee3e1bb50b66aa",
                    "ratings": "3.0",
                    "name": "Days Hotel By Wyndham Dubai Deira",
                    "location": {
                        "coordinates": {
                            "latitude": 25.267698,
                            "longitude": 55.292703
                        },
                        "obfuscatedCoordinates": {
                            "latitude": 25.26689,
                            "longitude": 55.288166
                        },
                        "obfuscationRequired": false
                    },
                    "externalId": "68864674",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/69000000/68870000/68864700/68864674/d6e1fff5_b.jpg",
                    "ha": [
                        "GYM",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.2",
                        "staff_service": "4.0",
                        "amenities": "4.8",
                        "conditions_facilities": "4.5"
                    },
                    "rat": "3.0",
                    "urat": "4.2",
                    "expediareview": 20,
                    "pid": "68864674",
                    "line1": "One Deira Plaza Gold Souk Metro Station",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Spice Souk - 0.8 km / 0.5 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 656.81,
                "dwtf": 5608.33,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 372.74,
                "com_disc": 0,
                "rtf": "4952.00",
                "rdwtf": "5608.00"
            },
            {
                "tid": "EIZiACt5CScmM9ma",
                "pid": "81083",
                "hs": 0,
                "rmid": "321473358",
                "rtid": "388908926",
                "refundable": false,
                "available_rooms": 22,
                "score": 286,
                "sno": "215",
                "cont": {
                    "_id": "600d3dd6a1bc371b80f83733",
                    "name": "J5 Rimal Hotel Apartments",
                    "ratings": "4.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.264261,
                            "longitude": 55.327224
                        }
                    },
                    "externalId": "81083",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/1000000/90000/81100/81083/4380bc91_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "POOL",
                        "BAR",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.5",
                        "staff_service": "4.6",
                        "amenities": "4.5",
                        "conditions_facilities": "4.4"
                    },
                    "rat": "4.0",
                    "urat": "4.4",
                    "expediareview": 680,
                    "pid": "81083",
                    "line1": "Al Muraqqabat Street",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Reef Mall - 1.1 km / 0.7 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 656.81,
                "dwtf": 5704.38,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 385.05,
                "com_disc": 0,
                "rtf": "5048.00",
                "rdwtf": "5704.00"
            },
            {
                "tid": "XBuOkFOcphRft330",
                "pid": "12497497",
                "hs": 0,
                "rmid": "201331524",
                "rtid": "211551883",
                "refundable": false,
                "available_rooms": 11,
                "score": 338,
                "sno": "216",
                "cont": {
                    "_id": "600d40afa1bc371b80fc22c1",
                    "name": "Jannah Marina Hotel Apartments",
                    "ratings": "0.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.068618,
                            "longitude": 55.130284
                        }
                    },
                    "externalId": "12497497",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/13000000/12500000/12497500/12497497/ec51c076_b.jpg",
                    "ha": [
                        "POOL",
                        "WIFI",
                        "GYM"
                    ],
                    "guestreview": {
                        "cleaniness": "3.9",
                        "staff_service": "4.0",
                        "amenities": "3.4",
                        "conditions_facilities": "3.6"
                    },
                    "rat": "0.0",
                    "urat": "3.9",
                    "expediareview": 185,
                    "pid": "12497497",
                    "line1": "Al Seba Street",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "The Walk - 1.2 km / 0.7 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 656.81,
                "dwtf": 5742.97,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 388.34,
                "com_disc": 0,
                "rtf": "5086.00",
                "rdwtf": "5743.00"
            },
            {
                "tid": "5opUf2ftDc8hgTcH",
                "pid": "22868663",
                "hs": 0,
                "rmid": "318645213",
                "rtid": "386899328",
                "refundable": false,
                "available_rooms": 2147483647,
                "score": 378,
                "sno": "217",
                "cont": {
                    "_id": "600d4231a1bc371b80fe3c8a",
                    "name": "Queen Elizabeth 2 Hotel",
                    "ratings": "4.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.260558,
                            "longitude": 55.283279
                        }
                    },
                    "externalId": "22868663",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/23000000/22870000/22868700/22868663/7ca25788_b.jpg",
                    "ha": [
                        "BAR",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.6",
                        "staff_service": "4.6",
                        "amenities": "4.1",
                        "conditions_facilities": "4.1"
                    },
                    "rat": "4.0",
                    "urat": "4.3",
                    "expediareview": 201,
                    "pid": "22868663",
                    "line1": "Mina Rashid Marina, Bur Dubai District",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Dubai Cruise Terminal - 0.2 km / 0.1 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 656.81,
                "dwtf": 5743.79,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 385.88,
                "com_disc": 0,
                "rtf": "5087.00",
                "rdwtf": "5744.00"
            },
            {
                "tid": "3fyvRuZ737AcmWPg",
                "pid": "12497496",
                "hs": 0,
                "rmid": "201334151",
                "rtid": "206482425",
                "refundable": true,
                "available_rooms": 7,
                "score": 327,
                "sno": "224",
                "cont": {
                    "_id": "600d42bba1bc371b80fef816",
                    "name": "Jannah Place Dubai Marina",
                    "ratings": "0.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.069519,
                            "longitude": 55.133341
                        }
                    },
                    "externalId": "12497496",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/13000000/12500000/12497500/12497496/447a3cd1_b.jpg",
                    "ha": [
                        "POOL",
                        "POOL",
                        "WIFI",
                        "GYM"
                    ],
                    "guestreview": {
                        "cleaniness": "4.0",
                        "staff_service": "4.0",
                        "amenities": "4.1",
                        "conditions_facilities": "3.8"
                    },
                    "rat": "0.0",
                    "urat": "3.9",
                    "expediareview": 154,
                    "pid": "12497496",
                    "line1": "Al Nasaq Street",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "The Walk - 0.8 km / 0.5 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 656.81,
                "dwtf": 5872.69,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 397.37,
                "com_disc": 0,
                "rtf": "5216.00",
                "rdwtf": "5873.00"
            },
            {
                "tid": "eoKqnZCkfFFTqkAh",
                "pid": "57399440",
                "hs": 0,
                "rmid": "228344826",
                "rtid": "262359437",
                "refundable": false,
                "available_rooms": 70,
                "score": 267,
                "sno": "225",
                "cont": {
                    "_id": "600d4351a1bc371b80ffaff0",
                    "name": "Wyndham Dubai Deira",
                    "ratings": "0.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.268365,
                            "longitude": 55.296214
                        }
                    },
                    "externalId": "57399440",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/58000000/57400000/57399500/57399440/824ca834_b.jpg",
                    "ha": [
                        "POOL",
                        "POOL",
                        "BAR",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": 0,
                        "staff_service": 0,
                        "amenities": 0,
                        "conditions_facilities": 0
                    },
                    "rat": "0.0",
                    "urat": "",
                    "expediareview": 0,
                    "pid": "57399440",
                    "line1": "Sherina Plaza 4 Al Corniche Rd 111",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Spice Souk - 0.3 km / 0.2 mi "
                },
                "hdtype": "HOTELHD",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 656.81,
                "dwtf": 5898.14,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 394.09,
                "com_disc": 0,
                "rtf": "5241.00",
                "rdwtf": "5898.00"
            },
            {
                "tid": "c2Q2ksUsXn3tfoaI",
                "pid": "20219460",
                "hs": 0,
                "rmid": "202219125",
                "rtid": "211341896",
                "refundable": true,
                "available_rooms": 7,
                "score": 513,
                "sno": "229",
                "cont": {
                    "_id": "600d410da1bc371b80fcac7c",
                    "name": "Rose Park Hotel Al Barsha",
                    "ratings": "4.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.114309,
                            "longitude": 55.190694
                        }
                    },
                    "externalId": "20219460",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/21000000/20220000/20219500/20219460/8dd8bbde_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "POOL",
                        "POOL"
                    ],
                    "guestreview": {
                        "cleaniness": "4.5",
                        "staff_service": "4.6",
                        "amenities": "4.4",
                        "conditions_facilities": "4.5"
                    },
                    "rat": "4.0",
                    "urat": "4.5",
                    "expediareview": 370,
                    "pid": "20219460",
                    "line1": "Opp. Mashreq Metro Station",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Fatima Abdullah Mohammed Rasheed Mosque - 0.5 km / 0.3 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 656.81,
                "dwtf": 5961.36,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 404.76,
                "com_disc": 0,
                "rtf": "5305.00",
                "rdwtf": "5961.00"
            },
            {
                "tid": "T0ocvQAcHsGhLULt",
                "pid": "41828999",
                "hs": 0,
                "rmid": "216055278",
                "rtid": "260242515",
                "refundable": false,
                "available_rooms": 2,
                "score": 183,
                "sno": "231",
                "cont": {
                    "_id": "600d3e68a1bc371b80f90a85",
                    "name": "OYO 314 24 Gold Hotel",
                    "ratings": "0.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.182162,
                            "longitude": 55.284166
                        }
                    },
                    "externalId": "41828999",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/42000000/41830000/41829000/41828999/e870601e_b.jpg",
                    "ha": [],
                    "guestreview": {
                        "cleaniness": "3.7",
                        "staff_service": "3.3",
                        "amenities": "4.5",
                        "conditions_facilities": "3.7"
                    },
                    "rat": "0.0",
                    "urat": "3.3",
                    "expediareview": 6,
                    "pid": "41828999",
                    "line1": "79 Sikkat Al Khail Road",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Souk al Bahar - 2.7 km / 1.7 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 656.81,
                "dwtf": 5971.21,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 406.4,
                "com_disc": 0,
                "rtf": "5314.00",
                "rdwtf": "5971.00"
            },
            {
                "tid": "XG2m1WmJIF16E6zI",
                "pid": "1546377",
                "hs": 0,
                "rmid": "316073660",
                "rtid": "385368147",
                "refundable": true,
                "available_rooms": 24,
                "score": 368,
                "sno": "238",
                "cont": {
                    "_id": "600d41cfa1bc371b80fdbb27",
                    "name": "Courtyard by Marriott Dubai Green Community",
                    "ratings": "4.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.003858,
                            "longitude": 55.164923
                        }
                    },
                    "externalId": "1546377",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/2000000/1550000/1546400/1546377/b9acf7ed_b.jpg",
                    "ha": [
                        "BAR",
                        "GYM",
                        "POOL",
                        "POOL",
                        "BAR"
                    ],
                    "guestreview": {
                        "cleaniness": "4.3",
                        "staff_service": "4.2",
                        "amenities": "4.7",
                        "conditions_facilities": "4.1"
                    },
                    "rat": "4.0",
                    "urat": "4.0",
                    "expediareview": 135,
                    "pid": "1546377",
                    "line1": "Green Community",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Jumeirah Golf Estates - 10 km / 6.2 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 656.81,
                "dwtf": 6078.77,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 433.49,
                "com_disc": 0,
                "rtf": "5422.00",
                "rdwtf": "6079.00"
            },
            {
                "tid": "1VIWQX9Mbg8Q99Qk",
                "pid": "1063960",
                "hs": 0,
                "rmid": "31235",
                "rtid": "204399204",
                "refundable": false,
                "available_rooms": 9,
                "score": 317,
                "sno": "240",
                "cont": {
                    "_id": "600d4364a1bc371b80ffc4b3",
                    "name": "Carlton Tower Hotel",
                    "ratings": "4.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.266976,
                            "longitude": 55.30696
                        }
                    },
                    "externalId": "1063960",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/2000000/1070000/1064000/1063960/f2b6df36_b.jpg",
                    "ha": [
                        "BAR",
                        "GYM",
                        "POOL",
                        "POOL",
                        "BAR"
                    ],
                    "guestreview": {
                        "cleaniness": "3.7",
                        "staff_service": "3.8",
                        "amenities": "3.9",
                        "conditions_facilities": "3.5"
                    },
                    "rat": "4.0",
                    "urat": "3.7",
                    "expediareview": 534,
                    "pid": "1063960",
                    "line1": "Baniyas Street",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Deira Twin Towers Shopping Centre - 0.2 km / 0.1 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 656.81,
                "dwtf": 6096.01,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 415.43,
                "com_disc": 0,
                "rtf": "5439.00",
                "rdwtf": "6096.00"
            },
            {
                "tid": "aCEPmEBaBGS2tNFv",
                "pid": "18810932",
                "hs": 0,
                "rmid": "202026744",
                "rtid": "210382622",
                "refundable": true,
                "available_rooms": 5,
                "score": 175,
                "sno": "249",
                "cont": {
                    "_id": "600d4125a1bc371b80fcc793",
                    "name": "Florida Al Souq Hotel",
                    "ratings": "2.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.270364,
                            "longitude": 55.304134
                        }
                    },
                    "externalId": "18810932",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/19000000/18820000/18811000/18810932/959ec5fc_b.jpg",
                    "ha": [
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.5",
                        "staff_service": "4.3",
                        "amenities": "5.0",
                        "conditions_facilities": "4.3"
                    },
                    "rat": "2.0",
                    "urat": "4.4",
                    "expediareview": 11,
                    "pid": "18810932",
                    "line1": "Al Burj Street, Deira",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Naif Souq - 0.3 km / 0.2 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 656.81,
                "dwtf": 6213.41,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 424.46,
                "com_disc": 0,
                "rtf": "5557.00",
                "rdwtf": "6213.00"
            },
            {
                "tid": "ogToXldqevn81w4p",
                "pid": "16302167",
                "hs": 0,
                "rmid": "201722640",
                "rtid": "282280169",
                "refundable": true,
                "available_rooms": 34,
                "score": 178,
                "sno": "1",
                "cont": {
                    "_id": "600d40f4a1bc371b80fc893c",
                    "name": "Al Farej Hotel",
                    "ratings": "3.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.276139,
                            "longitude": 55.310575
                        }
                    },
                    "externalId": "16302167",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/17000000/16310000/16302200/16302167/8e05e07a_b.jpg",
                    "ha": [
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "3.5",
                        "staff_service": "3.6",
                        "amenities": "2.5",
                        "conditions_facilities": "3.4"
                    },
                    "rat": "3.0",
                    "urat": "3.6",
                    "expediareview": 39,
                    "pid": "16302167",
                    "line1": "Opposite Delhi Resturant, Al Murar area",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Naif Souq - 0.9 km / 0.5 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 574.71,
                "dwtf": 2662.54,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 160.1,
                "com_disc": 0,
                "rtf": "2088.00",
                "rdwtf": "2663.00"
            },
            {
                "tid": "S0vhba4mO9qnQMpi",
                "pid": "70380635",
                "hs": 0,
                "rmid": "315462128",
                "rtid": "383694494",
                "refundable": true,
                "available_rooms": 4,
                "score": 186,
                "sno": "6",
                "cont": {
                    "_id": "62506b332aee3e1bb50b67e7",
                    "ratings": "3.0",
                    "name": "Rove Expo 2020",
                    "location": {
                        "coordinates": {
                            "latitude": 24.96082,
                            "longitude": 55.150086
                        },
                        "obfuscationRequired": false
                    },
                    "externalId": "70380635",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/71000000/70390000/70380700/70380635/3e4113a7_b.jpg",
                    "ha": [
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": 0,
                        "staff_service": 0,
                        "amenities": 0,
                        "conditions_facilities": 0
                    },
                    "rat": "3.0",
                    "urat": "",
                    "expediareview": 0,
                    "pid": "70380635",
                    "line1": "Al Wasl Plaza, Expo 2020",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Dubai Expo 2020 - 0.1 km / 0.1 mi "
                },
                "hdtype": "HOTELHD",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 574.71,
                "dwtf": 3367.79,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 213.46,
                "com_disc": 0,
                "rtf": "2793.00",
                "rdwtf": "3368.00"
            },
            {
                "tid": "qVtVLA09ahDNgoOa",
                "pid": "44167148",
                "hs": 0,
                "rmid": "217877202",
                "rtid": "272280342",
                "refundable": true,
                "available_rooms": 2147483647,
                "score": 307,
                "sno": "16",
                "cont": {
                    "_id": "600d3fd0a1bc371b80faec42",
                    "name": "ibis Styles Dubai Airport Hotel",
                    "ratings": "3.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.250222,
                            "longitude": 55.3443
                        }
                    },
                    "externalId": "44167148",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/45000000/44170000/44167200/44167148/7bd050db_b.jpg",
                    "ha": [
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.8",
                        "staff_service": "4.8",
                        "amenities": "4.8",
                        "conditions_facilities": "4.8"
                    },
                    "rat": "3.0",
                    "urat": "4.7",
                    "expediareview": 59,
                    "pid": "44167148",
                    "line1": "61B street",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Dubai Tennis Stadium - 1 km / 0.6 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 574.71,
                "dwtf": 3600.13,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 218.39,
                "com_disc": 0,
                "rtf": "3025.00",
                "rdwtf": "3600.00"
            },
            {
                "tid": "zgUr3sBhOJEq23lC",
                "pid": "4290737",
                "hs": 0,
                "rmid": "202081693",
                "rtid": "388585275",
                "refundable": false,
                "available_rooms": 3,
                "score": 270,
                "sno": "21",
                "cont": {
                    "_id": "600d4213a1bc371b80fe14ec",
                    "name": "Grand Central Hotel",
                    "ratings": "4.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.263626,
                            "longitude": 55.326791
                        }
                    },
                    "externalId": "4290737",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/5000000/4300000/4290800/4290737/0f458c93_b.jpg",
                    "ha": [
                        "BAR",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "3.4",
                        "staff_service": "2.7",
                        "amenities": "4.0",
                        "conditions_facilities": "3.3"
                    },
                    "rat": "4.0",
                    "urat": "2.8",
                    "expediareview": 37,
                    "pid": "4290737",
                    "line1": "Al Rigga Road, Deira",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Reef Mall - 1.1 km / 0.7 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 574.71,
                "dwtf": 3651.04,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 234.81,
                "com_disc": 0,
                "rtf": "3076.00",
                "rdwtf": "3651.00"
            },
            {
                "tid": "SbOSDgKNzPs6vvTx",
                "pid": "30549665",
                "hs": 0,
                "rmid": "218160324",
                "rtid": "274220402",
                "refundable": true,
                "available_rooms": 31,
                "score": 216,
                "sno": "23",
                "cont": {
                    "_id": "600d3dfaa1bc371b80f86098",
                    "name": "Signature Inn Deira",
                    "ratings": "3.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.26437,
                            "longitude": 55.32215
                        }
                    },
                    "externalId": "30549665",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/31000000/30550000/30549700/30549665/3282be58_b.jpg",
                    "ha": [
                        "BAR",
                        "POOL",
                        "BAR",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "2.1",
                        "staff_service": "2.5",
                        "amenities": "1.8",
                        "conditions_facilities": "2.0"
                    },
                    "rat": "3.0",
                    "urat": "2.2",
                    "expediareview": 18,
                    "pid": "30549665",
                    "line1": "Al Riqqa Street",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Al Ghurair Centre - 0.6 km / 0.4 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 574.71,
                "dwtf": 3673.2,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 128.9,
                "com_disc": 0,
                "rtf": "3098.00",
                "rdwtf": "3673.00"
            },
            {
                "tid": "26SxNc6WVlnErrnv",
                "pid": "805863",
                "hs": 0,
                "rmid": "200761203",
                "rtid": "381438531",
                "refundable": false,
                "available_rooms": 5,
                "score": 259,
                "sno": "140",
                "cont": {
                    "_id": "600d4381a1bc371b80ffe866",
                    "name": "Sea View Hotel",
                    "ratings": "4.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.254914,
                            "longitude": 55.28442
                        }
                    },
                    "externalId": "805863",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/1000000/810000/805900/805863/a2417676_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "3.8",
                        "staff_service": "4.0",
                        "amenities": "3.9",
                        "conditions_facilities": "3.4"
                    },
                    "rat": "4.0",
                    "urat": "3.5",
                    "expediareview": 167,
                    "pid": "805863",
                    "line1": "Al Mina Road, Bur Dubai",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Meena Bazaar - 1.7 km / 1 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 574.71,
                "dwtf": 4894.87,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 326.76,
                "com_disc": 0,
                "rtf": "4320.00",
                "rdwtf": "4895.00"
            },
            {
                "tid": "6zLwZs4eGI5J4qVR",
                "pid": "10106700",
                "hs": 0,
                "rmid": "201738846",
                "rtid": "292203783",
                "refundable": false,
                "available_rooms": 20,
                "score": 293,
                "sno": "144",
                "cont": {
                    "_id": "600d3de6a1bc371b80f849a8",
                    "name": "Raviz Center Point Hotel",
                    "ratings": "4.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.255906,
                            "longitude": 55.301825
                        }
                    },
                    "externalId": "10106700",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/11000000/10110000/10106700/10106700/ff23ea7b_b.jpg",
                    "ha": [
                        "BAR",
                        "GYM",
                        "POOL",
                        "POOL"
                    ],
                    "guestreview": {
                        "cleaniness": "4.3",
                        "staff_service": "4.4",
                        "amenities": "3.9",
                        "conditions_facilities": "4.2"
                    },
                    "rat": "4.0",
                    "urat": "4.3",
                    "expediareview": 86,
                    "pid": "10106700",
                    "line1": "Khalid Bin Al Waleed Street, Bank Street",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "BurJuman Mall - 0.5 km / 0.3 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 574.71,
                "dwtf": 4921.96,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 331.69,
                "com_disc": 0,
                "rtf": "4347.00",
                "rdwtf": "4922.00"
            },
            {
                "tid": "xlAUL1sFi0RRHLXi",
                "pid": "14902055",
                "hs": 0,
                "rmid": "220249267",
                "rtid": "288198363",
                "refundable": true,
                "available_rooms": 50,
                "score": 180,
                "sno": "158",
                "cont": {
                    "_id": "600d412fa1bc371b80fcd1e3",
                    "name": "San Marco Hotel",
                    "ratings": "0.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.278313,
                            "longitude": 55.309945
                        }
                    },
                    "externalId": "14902055",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/15000000/14910000/14902100/14902055/2d1b37ca_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "3.4",
                        "staff_service": "3.2",
                        "amenities": 0,
                        "conditions_facilities": "2.8"
                    },
                    "rat": "0.0",
                    "urat": "2.8",
                    "expediareview": 20,
                    "pid": "14902055",
                    "line1": "Frij Al Murar",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Naif Souq - 1.2 km / 0.7 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 574.71,
                "dwtf": 5011.45,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 340.72,
                "com_disc": 0,
                "rtf": "4437.00",
                "rdwtf": "5011.00"
            },
            {
                "tid": "M8Ohki4OZ5L9PBQb",
                "pid": "526364",
                "hs": 0,
                "rmid": "201762712",
                "rtid": "210000629",
                "refundable": false,
                "available_rooms": 4,
                "score": 231,
                "sno": "159",
                "cont": {
                    "_id": "600d3d8ca1bc371b80f7c794",
                    "name": "Admiral Plaza Hotel Dubai",
                    "ratings": "3.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.26022,
                            "longitude": 55.291049
                        }
                    },
                    "externalId": "526364",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/1000000/530000/526400/526364/67a0144d_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "3.5",
                        "staff_service": "3.6",
                        "amenities": "2.8",
                        "conditions_facilities": "3.1"
                    },
                    "rat": "3.0",
                    "urat": "3.3",
                    "expediareview": 54,
                    "pid": "526364",
                    "line1": "Khalid Bin Al Waleed Road, Al Nahdha St",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Textile Souk - 0.6 km / 0.4 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 574.71,
                "dwtf": 5011.45,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 340.72,
                "com_disc": 0,
                "rtf": "4437.00",
                "rdwtf": "5011.00"
            },
            {
                "tid": "n6scNPeAf4M9Nd0y",
                "pid": "1767717",
                "hs": 0,
                "rmid": "200689422",
                "rtid": "203418349",
                "refundable": false,
                "available_rooms": 9,
                "score": 184,
                "sno": "164",
                "cont": {
                    "_id": "600d3f40a1bc371b80fa3681",
                    "name": "Golden Tulip Sharjah",
                    "ratings": "4.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.324215,
                            "longitude": 55.382478
                        }
                    },
                    "externalId": "1767717",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/2000000/1770000/1767800/1767717/19ff4338_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "POOL"
                    ],
                    "guestreview": {
                        "cleaniness": "4.0",
                        "staff_service": "3.7",
                        "amenities": "4.2",
                        "conditions_facilities": "3.8"
                    },
                    "rat": "4.0",
                    "urat": "3.8",
                    "expediareview": 86,
                    "pid": "1767717",
                    "line1": "Al Fardan Centre Corniche Road",
                    "city": "Sharjah",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Al Majaz Park - 0.6 km / 0.4 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 574.71,
                "dwtf": 5074.67,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 307.06,
                "com_disc": 0,
                "rtf": "4500.00",
                "rdwtf": "5075.00"
            },
            {
                "tid": "DBTUkJcNnRNzDTGt",
                "pid": "59056060",
                "hs": 0,
                "rmid": "231025447",
                "rtid": "280279501",
                "refundable": false,
                "available_rooms": 14,
                "score": 194,
                "sno": "165",
                "cont": {
                    "_id": "600d4035a1bc371b80fb7443",
                    "name": "Super 8 by Wyndham Dubai Deira",
                    "location": {
                        "coordinates": {
                            "latitude": 25.272803,
                            "longitude": 55.298301
                        }
                    },
                    "externalId": "59056060",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/60000000/59060000/59056100/59056060/48f2dd88_b.jpg",
                    "ha": [
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": 0,
                        "staff_service": 0,
                        "amenities": 0,
                        "conditions_facilities": 0
                    },
                    "rat": "",
                    "urat": "",
                    "expediareview": 0,
                    "ratings": "",
                    "pid": "59056060",
                    "line1": "Maryam Plaza 1, Al Daghaya Rd- 113",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Gold Souk - 0.4 km / 0.2 mi "
                },
                "hdtype": "HOTELHD",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 574.71,
                "dwtf": 5114.9,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 342.36,
                "com_disc": 0,
                "rtf": "4540.00",
                "rdwtf": "5115.00"
            },
            {
                "tid": "bKHJ1ootNKyGJRM8",
                "pid": "19531804",
                "hs": 0,
                "rmid": "220501315",
                "rtid": "290003307",
                "refundable": false,
                "available_rooms": 35,
                "score": 266,
                "sno": "166",
                "cont": {
                    "_id": "600d4063a1bc371b80fbb425",
                    "name": "Omega Hotel",
                    "ratings": "4.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.254756,
                            "longitude": 55.291707
                        }
                    },
                    "externalId": "19531804",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/20000000/19540000/19531900/19531804/15d451b3_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.2",
                        "staff_service": "3.6",
                        "amenities": "3.4",
                        "conditions_facilities": "3.8"
                    },
                    "rat": "4.0",
                    "urat": "3.8",
                    "expediareview": 25,
                    "pid": "19531804",
                    "line1": "Al Mankhool  Bur Dubai",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Meena Bazaar - 1 km / 0.6 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 574.71,
                "dwtf": 5117.36,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 344,
                "com_disc": 0,
                "rtf": "4543.00",
                "rdwtf": "5117.00"
            },
            {
                "tid": "QyOBJS9bmuNCSkQA",
                "pid": "4252421",
                "hs": 0,
                "rmid": "213302279",
                "rtid": "242046484",
                "refundable": false,
                "available_rooms": 4,
                "score": 303,
                "sno": "169",
                "cont": {
                    "_id": "62506c4c2aee3e1bb50b6bd1",
                    "ratings": "0.0",
                    "name": "Golden Tulip Media Hotel",
                    "location": {
                        "coordinates": {
                            "latitude": 25.099649,
                            "longitude": 55.178223
                        },
                        "obfuscationRequired": false
                    },
                    "externalId": "4252421",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/5000000/4260000/4252500/4252421/65683c28_b.jpg",
                    "ha": [
                        "GYM",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.0",
                        "staff_service": "3.9",
                        "amenities": 0,
                        "conditions_facilities": "3.8"
                    },
                    "rat": "0.0",
                    "urat": "3.8",
                    "expediareview": 157,
                    "pid": "4252421",
                    "line1": "Tecom Al Barsha",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Jebel Ali Race Course - 1.2 km / 0.8 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 574.71,
                "dwtf": 5137.89,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 384.23,
                "com_disc": 0,
                "rtf": "4563.00",
                "rdwtf": "5138.00"
            },
            {
                "tid": "c1RlBVM6UnTGXGtg",
                "pid": "1693670",
                "hs": 0,
                "rmid": "179945",
                "rtid": "238055304",
                "refundable": true,
                "available_rooms": 4,
                "score": 228,
                "sno": "174",
                "cont": {
                    "_id": "600d40f1a1bc371b80fc84af",
                    "name": "Ramee Royal Hotel",
                    "ratings": "4.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.249228,
                            "longitude": 55.312856
                        }
                    },
                    "externalId": "1693670",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/2000000/1700000/1693700/1693670/41de2cbe_b.jpg",
                    "ha": [
                        "BAR",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "3.3",
                        "staff_service": "2.9",
                        "amenities": "3.0",
                        "conditions_facilities": "2.9"
                    },
                    "rat": "4.0",
                    "urat": "2.7",
                    "expediareview": 98,
                    "pid": "1693670",
                    "line1": "Khalid Bin Al Waleed St.",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "BurJuman Mall - 1.3 km / 0.8 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 574.71,
                "dwtf": 5163.34,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 349.75,
                "com_disc": 0,
                "rtf": "4589.00",
                "rdwtf": "5163.00"
            },
            {
                "tid": "2RQmXy5eWbrCt1Rc",
                "pid": "17333665",
                "hs": 0,
                "rmid": "201835759",
                "rtid": "209313641",
                "refundable": false,
                "available_rooms": 37,
                "score": 296,
                "sno": "177",
                "cont": {
                    "_id": "600d428aa1bc371b80feb215",
                    "name": "Best Western Plus Pearl Creek",
                    "ratings": "0.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.267577,
                            "longitude": 55.304743
                        }
                    },
                    "externalId": "17333665",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/18000000/17340000/17333700/17333665/113842ef_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.1",
                        "staff_service": "4.1",
                        "amenities": "3.3",
                        "conditions_facilities": "4.0"
                    },
                    "rat": "0.0",
                    "urat": "4.0",
                    "expediareview": 174,
                    "pid": "17333665",
                    "line1": "90 Baniyas Road",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Deira Twin Towers Shopping Centre - 0.4 km / 0.2 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 574.71,
                "dwtf": 5192.89,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 255.33,
                "com_disc": 0,
                "rtf": "4618.00",
                "rdwtf": "5193.00"
            },
            {
                "tid": "4VeVFTbc61dMX2ag",
                "pid": "42490297",
                "hs": 0,
                "rmid": "216059594",
                "rtid": "260252590",
                "refundable": true,
                "available_rooms": 1,
                "score": 275,
                "sno": "183",
                "cont": {
                    "_id": "600d41f2a1bc371b80fde8cf",
                    "name": "Ramada by Wyndham Dubai Deira",
                    "ratings": "4.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.270624,
                            "longitude": 55.321675
                        }
                    },
                    "externalId": "42490297",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/43000000/42500000/42490300/42490297/d77e1567_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "3.9",
                        "staff_service": "3.6",
                        "amenities": "3.5",
                        "conditions_facilities": "3.4"
                    },
                    "rat": "4.0",
                    "urat": "3.5",
                    "expediareview": 8,
                    "pid": "42490297",
                    "line1": "155 Salahudin Road, Deira",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Reef Mall - 0.3 km / 0.2 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 574.71,
                "dwtf": 5257.75,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 317.73,
                "com_disc": 0,
                "rtf": "4683.00",
                "rdwtf": "5258.00"
            },
            {
                "tid": "yTJKE0xmTV5m34kR",
                "pid": "2357274",
                "hs": 0,
                "rmid": "202357746",
                "rtid": "212109974",
                "refundable": true,
                "available_rooms": 28,
                "score": 414,
                "sno": "188",
                "cont": {
                    "_id": "600d43c1a1bc371b80003c77",
                    "name": "TIME Oak Hotel & Suites",
                    "ratings": "4.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.096611,
                            "longitude": 55.172878
                        }
                    },
                    "externalId": "2357274",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/3000000/2360000/2357300/2357274/c7be91c5_b.jpg",
                    "ha": [
                        "POOL",
                        "POOL",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.2",
                        "staff_service": "4.4",
                        "amenities": "4.1",
                        "conditions_facilities": "4.1"
                    },
                    "rat": "4.0",
                    "urat": "4.3",
                    "expediareview": 301,
                    "pid": "2357274",
                    "line1": "Abdulla Omran Tayram St, Al Thanyah 1",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Jebel Ali Race Course - 0.9 km / 0.6 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 574.71,
                "dwtf": 5357.1,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 365.35,
                "com_disc": 0,
                "rtf": "4782.00",
                "rdwtf": "5357.00"
            },
            {
                "tid": "sUmIIiERqVAHOuFh",
                "pid": "69801",
                "hs": 0,
                "rmid": "212415802",
                "rtid": "236265507",
                "refundable": true,
                "available_rooms": 2147483647,
                "score": 506,
                "sno": "190",
                "cont": {
                    "_id": "600d3d6ea1bc371b80f79de3",
                    "name": "Mövenpick Grand Al Bustan Dubai",
                    "ratings": "5.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.247727,
                            "longitude": 55.345076
                        }
                    },
                    "externalId": "69801",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/1000000/70000/69900/69801/d74752ae_b.jpg",
                    "ha": [
                        "BAR",
                        "GYM",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.5",
                        "staff_service": "4.5",
                        "amenities": "4.3",
                        "conditions_facilities": "4.4"
                    },
                    "rat": "5.0",
                    "urat": "4.4",
                    "expediareview": 802,
                    "pid": "69801",
                    "line1": "Casablanca Road, Al Garhoud",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Dubai Tennis Stadium - 1.6 km / 1 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 574.71,
                "dwtf": 5380.91,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 366.99,
                "com_disc": 0,
                "rtf": "4806.00",
                "rdwtf": "5381.00"
            },
            {
                "tid": "0M49bXplJg2g671i",
                "pid": "9842947",
                "hs": 0,
                "rmid": "200900602",
                "rtid": "206494981",
                "refundable": false,
                "available_rooms": 112,
                "score": 476,
                "sno": "198",
                "cont": {
                    "_id": "600d41e3a1bc371b80fdd7bc",
                    "name": "Ghaya Grand Hotel",
                    "ratings": "5.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.033877,
                            "longitude": 55.203842
                        }
                    },
                    "externalId": "9842947",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/10000000/9850000/9843000/9842947/3edf8809_b.jpg",
                    "ha": [
                        "POOL",
                        "POOL",
                        "BAR",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.3",
                        "staff_service": "4.3",
                        "amenities": "4.1",
                        "conditions_facilities": "4.3"
                    },
                    "rat": "5.0",
                    "urat": "4.1",
                    "expediareview": 336,
                    "pid": "9842947",
                    "line1": "Al Fay Road, Sports City",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Dubai Sports City - 2.4 km / 1.5 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 574.71,
                "dwtf": 5429.35,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 371.1,
                "com_disc": 0,
                "rtf": "4855.00",
                "rdwtf": "5429.00"
            },
            {
                "tid": "NhOzTyaZ7s4yaGBk",
                "pid": "12333050",
                "hs": 0,
                "rmid": "201280754",
                "rtid": "385715495",
                "refundable": false,
                "available_rooms": 33,
                "score": 385,
                "sno": "200",
                "cont": {
                    "_id": "600d435ea1bc371b80ffbe0b",
                    "name": "Asiana Hotel Dubai",
                    "ratings": "5.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.270252,
                            "longitude": 55.325102
                        }
                    },
                    "externalId": "12333050",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/13000000/12340000/12333100/12333050/11fce722_b.jpg",
                    "ha": [
                        "POOL",
                        "WIFI",
                        "GYM"
                    ],
                    "guestreview": {
                        "cleaniness": "4.4",
                        "staff_service": "4.3",
                        "amenities": "4.2",
                        "conditions_facilities": "4.3"
                    },
                    "rat": "5.0",
                    "urat": "4.2",
                    "expediareview": 337,
                    "pid": "12333050",
                    "line1": "Salahuddin Street",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Reef Mall - 0.4 km / 0.2 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 574.71,
                "dwtf": 5465.47,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 372.74,
                "com_disc": 0,
                "rtf": "4891.00",
                "rdwtf": "5465.00"
            },
            {
                "tid": "cJnbZfMntRXaRtMu",
                "pid": "988207",
                "hs": 0,
                "rmid": "213013855",
                "rtid": "240210183",
                "refundable": true,
                "available_rooms": 80,
                "score": 325,
                "sno": "203",
                "cont": {
                    "_id": "600d41dda1bc371b80fdd03a",
                    "name": "Ramada Plaza by Wyndham Dubai Deira",
                    "ratings": "4.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.270249,
                            "longitude": 55.330721
                        }
                    },
                    "externalId": "988207",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/1000000/990000/988300/988207/7ed80e35_b.jpg",
                    "ha": [
                        "POOL",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.5",
                        "staff_service": "4.4",
                        "amenities": "3.3",
                        "conditions_facilities": "4.3"
                    },
                    "rat": "4.0",
                    "urat": "4.3",
                    "expediareview": 592,
                    "pid": "988207",
                    "line1": "Corner Of Abu Baker Al Siddique Street",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Reef Mall - 1.1 km / 0.7 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 574.71,
                "dwtf": 5503.24,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 333.33,
                "com_disc": 0,
                "rtf": "4929.00",
                "rdwtf": "5503.00"
            },
            {
                "tid": "RkITJrUUaAsnavpf",
                "pid": "1227381",
                "hs": 0,
                "rmid": "201726159",
                "rtid": "222038639",
                "refundable": false,
                "available_rooms": 21,
                "score": 482,
                "sno": "209",
                "cont": {
                    "_id": "600d4031a1bc371b80fb6e88",
                    "name": "Arabian Courtyard Hotel & Spa",
                    "ratings": "4.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.262647,
                            "longitude": 55.297222
                        }
                    },
                    "externalId": "1227381",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/2000000/1230000/1227400/1227381/fb0b92f6_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.2",
                        "staff_service": "4.3",
                        "amenities": "4.0",
                        "conditions_facilities": "3.9"
                    },
                    "rat": "4.0",
                    "urat": "4.1",
                    "expediareview": 994,
                    "pid": "1227381",
                    "line1": "Al Fahidi Street",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Dubai Museum - 0.2 km / 0.1 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 574.71,
                "dwtf": 5553.32,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 380.13,
                "com_disc": 0,
                "rtf": "4979.00",
                "rdwtf": "5553.00"
            },
            {
                "tid": "vQlnlqGL5OLANfix",
                "pid": "3930355",
                "hs": 0,
                "rmid": "202357957",
                "rtid": "212110774",
                "refundable": true,
                "available_rooms": 21,
                "score": 364,
                "sno": "210",
                "cont": {
                    "_id": "600d4081a1bc371b80fbdc31",
                    "name": "TIME Grand Plaza Hotel, Dubai Airport",
                    "ratings": "4.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.270751,
                            "longitude": 55.380263
                        }
                    },
                    "externalId": "3930355",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/4000000/3940000/3930400/3930355/e066bdb8_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.4",
                        "staff_service": "4.3",
                        "amenities": "4.2",
                        "conditions_facilities": "4.3"
                    },
                    "rat": "4.0",
                    "urat": "4.2",
                    "expediareview": 312,
                    "pid": "3930355",
                    "line1": "Damascus Road Al Qusais",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Al-Maktoum Stadium - 4.3 km / 2.7 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Breakfast"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 574.71,
                "dwtf": 5574.66,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 381.77,
                "com_disc": 0,
                "rtf": "5000.00",
                "rdwtf": "5575.00"
            },
            {
                "tid": "NleDxUCr9pA0SLLs",
                "pid": "24206066",
                "hs": 0,
                "rmid": "314320230",
                "rtid": "383024993",
                "refundable": true,
                "available_rooms": 9,
                "score": 513,
                "sno": "214",
                "cont": {
                    "_id": "600d4345a1bc371b80ffa298",
                    "name": "Gulf Court Hotel Business Bay",
                    "ratings": "4.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.182687,
                            "longitude": 55.27472
                        }
                    },
                    "externalId": "24206066",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/25000000/24210000/24206100/24206066/c9780fdf_b.jpg",
                    "ha": [
                        "BAR",
                        "POOL",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.5",
                        "staff_service": "4.4",
                        "amenities": "4.2",
                        "conditions_facilities": "4.4"
                    },
                    "rat": "4.0",
                    "urat": "4.3",
                    "expediareview": 93,
                    "pid": "24206066",
                    "line1": "Al Abraj Street",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Dubai Water Canal - 0.1 km / 0.1 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 574.71,
                "dwtf": 5615.72,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 410.51,
                "com_disc": 0,
                "rtf": "5041.00",
                "rdwtf": "5616.00"
            },
            {
                "tid": "HWPVa2kzmfZL9nbF",
                "pid": "10257665",
                "hs": 0,
                "rmid": "200938977",
                "rtid": "280202105",
                "refundable": true,
                "available_rooms": 27,
                "score": 183,
                "sno": "2",
                "cont": {
                    "_id": "600d3f6ba1bc371b80fa7753",
                    "name": "Hafez Hotel Apartments",
                    "ratings": "3.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.267556,
                            "longitude": 55.29397
                        }
                    },
                    "externalId": "10257665",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/11000000/10260000/10257700/10257665/18ef569b_b.jpg",
                    "ha": [
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "2.4",
                        "staff_service": "2.9",
                        "amenities": "2.6",
                        "conditions_facilities": "2.4"
                    },
                    "rat": "3.0",
                    "urat": "2.6",
                    "expediareview": 84,
                    "pid": "10257665",
                    "line1": "Next to E NBD Bank Bldg, Al Ras",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Spice Souk - 0.5 km / 0.3 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 492.61,
                "dwtf": 3087.82,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 90.31,
                "com_disc": 0,
                "rtf": "2595.00",
                "rdwtf": "3088.00"
            },
            {
                "tid": "pcOqmknvJIvNZ9sP",
                "pid": "2510628",
                "hs": 0,
                "rmid": "200141256",
                "rtid": "200791423",
                "refundable": true,
                "available_rooms": 2147483647,
                "score": 497,
                "sno": "5",
                "cont": {
                    "_id": "600d4277a1bc371b80fe99bd",
                    "name": "Mercure Gold Hotel Al Mina Road Dubai",
                    "ratings": "4.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.242837,
                            "longitude": 55.275787
                        }
                    },
                    "externalId": "2510628",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/3000000/2520000/2510700/2510628/9120ed99_b.jpg",
                    "ha": [
                        "BAR",
                        "GYM",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.0",
                        "staff_service": "4.2",
                        "amenities": "4.1",
                        "conditions_facilities": "3.9"
                    },
                    "rat": "4.0",
                    "urat": "3.9",
                    "expediareview": 993,
                    "pid": "2510628",
                    "line1": "Al Mina Road",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Jumeirah Mosque - 1.9 km / 1.2 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 492.61,
                "dwtf": 3262.7,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 199.51,
                "com_disc": 0,
                "rtf": "2770.00",
                "rdwtf": "3263.00"
            },
            {
                "tid": "gtVpJaSAKdJWnM7b",
                "pid": "4144930",
                "hs": 0,
                "rmid": "200196298",
                "rtid": "201124489",
                "refundable": false,
                "available_rooms": 23,
                "score": 227,
                "sno": "48",
                "cont": {
                    "_id": "600d3fe8a1bc371b80fb09f2",
                    "name": "Howard Johnson by Wyndham Bur Dubai",
                    "ratings": "3.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.260224,
                            "longitude": 55.285261
                        }
                    },
                    "externalId": "4144930",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/5000000/4150000/4145000/4144930/3c6d9bb3_b.jpg",
                    "ha": [
                        "BAR",
                        "GYM",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "3.8",
                        "staff_service": "3.5",
                        "amenities": "4.5",
                        "conditions_facilities": "3.6"
                    },
                    "rat": "3.0",
                    "urat": "3.5",
                    "expediareview": 246,
                    "pid": "4144930",
                    "line1": "Khalid Bin Waleed Rd",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Meena Bazaar - 1.1 km / 0.7 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 492.61,
                "dwtf": 3931.82,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 258.62,
                "com_disc": 0,
                "rtf": "3439.00",
                "rdwtf": "3932.00"
            },
            {
                "tid": "D8cbZPTi1pt4sxMr",
                "pid": "33752649",
                "hs": 0,
                "rmid": "217513412",
                "rtid": "270091425",
                "refundable": false,
                "available_rooms": 33,
                "score": 340,
                "sno": "64",
                "cont": {
                    "_id": "600d420ea1bc371b80fe0e64",
                    "name": "The S Hotel Al Barsha",
                    "ratings": "4.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.069861,
                            "longitude": 55.244083
                        }
                    },
                    "externalId": "33752649",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/34000000/33760000/33752700/33752649/0e909a29_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "POOL",
                        "POOL"
                    ],
                    "guestreview": {
                        "cleaniness": "4.7",
                        "staff_service": "4.4",
                        "amenities": "4.3",
                        "conditions_facilities": "4.6"
                    },
                    "rat": "4.0",
                    "urat": "4.1",
                    "expediareview": 63,
                    "pid": "33752649",
                    "line1": "Al Barsha, South Umm Suqeim Road",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Dubai Science Park - 0.1 km / 0.1 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 492.61,
                "dwtf": 4111.62,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 276.68,
                "com_disc": 0,
                "rtf": "3619.00",
                "rdwtf": "4112.00"
            },
            {
                "tid": "4CR9inZvHgFWk2Kp",
                "pid": "34994633",
                "hs": 0,
                "rmid": "220543710",
                "rtid": "387115392",
                "refundable": false,
                "available_rooms": 9,
                "score": 225,
                "sno": "70",
                "cont": {
                    "_id": "600d413ea1bc371b80fce3a4",
                    "name": "City Avenue Hotel",
                    "ratings": "0.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.274081,
                            "longitude": 55.322644
                        }
                    },
                    "externalId": "34994633",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/35000000/35000000/34994700/34994633/79a3782d_b.jpg",
                    "ha": [
                        "GYM",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.4",
                        "staff_service": "4.4",
                        "amenities": "3.7",
                        "conditions_facilities": "4.2"
                    },
                    "rat": "0.0",
                    "urat": "4.3",
                    "expediareview": 6,
                    "pid": "34994633",
                    "line1": "Al Mateena St",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Fish Roundabout - 1.1 km / 0.7 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 492.61,
                "dwtf": 4145.28,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 305.42,
                "com_disc": 0,
                "rtf": "3653.00",
                "rdwtf": "4145.00"
            },
            {
                "tid": "CKna3xOE1OWFqWyC",
                "pid": "4794423",
                "hs": 0,
                "rmid": "200129920",
                "rtid": "201946288",
                "refundable": false,
                "available_rooms": 15,
                "score": 332,
                "sno": "73",
                "cont": {
                    "_id": "600d3ed6a1bc371b80f9a39d",
                    "name": "Grand Excelsior Hotel Al Barsha",
                    "ratings": "4.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.111729,
                            "longitude": 55.203126
                        }
                    },
                    "externalId": "4794423",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/5000000/4800000/4794500/4794423/038899e9_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "3.7",
                        "staff_service": "3.7",
                        "amenities": "3.5",
                        "conditions_facilities": "3.6"
                    },
                    "rat": "4.0",
                    "urat": "3.6",
                    "expediareview": 453,
                    "pid": "4794423",
                    "line1": "60th Street, Al Barsha 1",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Fatima Abdullah Mohammed Rasheed Mosque - 1.3 km / 0.8 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 492.61,
                "dwtf": 4165.81,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 279.96,
                "com_disc": 0,
                "rtf": "3673.00",
                "rdwtf": "4166.00"
            },
            {
                "tid": "XFVXaFq0DGqob75a",
                "pid": "74445717",
                "hs": 0,
                "rmid": "315999594",
                "rtid": "385212559",
                "refundable": false,
                "available_rooms": 1,
                "score": 155,
                "sno": "80",
                "cont": {
                    "_id": "62506a942aee3e1bb50b66a2",
                    "ratings": "0.0",
                    "name": "Maaeen Hotel",
                    "location": {
                        "coordinates": {
                            "latitude": 25.269369,
                            "longitude": 55.300156
                        },
                        "obfuscatedCoordinates": {
                            "latitude": 0.000891,
                            "longitude": 0.002758
                        },
                        "obfuscationRequired": false
                    },
                    "externalId": "74445717",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/75000000/74450000/74445800/74445717/259c6f63_b.jpg",
                    "ha": [],
                    "guestreview": {
                        "cleaniness": 0,
                        "staff_service": 0,
                        "amenities": 0,
                        "conditions_facilities": 0
                    },
                    "rat": "0.0",
                    "urat": "",
                    "expediareview": 0,
                    "pid": "74445717",
                    "line1": "10th Street, Al butteen Rd, Deria",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Gold Souk - 0.3 km / 0.2 mi "
                },
                "hdtype": "HOTELHD",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 492.61,
                "dwtf": 4239.7,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 322.66,
                "com_disc": 0,
                "rtf": "3747.00",
                "rdwtf": "4240.00"
            },
            {
                "tid": "ZJDS8qTOEhKt3X2C",
                "pid": "55414427",
                "hs": 0,
                "rmid": "231016376",
                "rtid": "280262059",
                "refundable": false,
                "available_rooms": 2,
                "score": 212,
                "sno": "84",
                "cont": {
                    "_id": "600d430aa1bc371b80ff67e4",
                    "name": "Al Karnak Hotel",
                    "ratings": "0.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.271209,
                            "longitude": 55.301968
                        }
                    },
                    "externalId": "55414427",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/56000000/55420000/55414500/55414427/823b93a3_b.jpg",
                    "ha": [
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": 0,
                        "staff_service": 0,
                        "amenities": 0,
                        "conditions_facilities": 0
                    },
                    "rat": "0.0",
                    "urat": "",
                    "expediareview": 0,
                    "pid": "55414427",
                    "line1": "Al Sabkha 109, Deira",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Naif Souq - 0.4 km / 0.3 mi "
                },
                "hdtype": "HOTELHD",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 492.61,
                "dwtf": 4255.3,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 285.71,
                "com_disc": 0,
                "rtf": "3763.00",
                "rdwtf": "4255.00"
            },
            {
                "tid": "ITl9WbxFLRp9spmL",
                "pid": "3592422",
                "hs": 0,
                "rmid": "200314104",
                "rtid": "204504967",
                "refundable": false,
                "available_rooms": 18,
                "score": 398,
                "sno": "91",
                "cont": {
                    "_id": "600d4341a1bc371b80ff9f83",
                    "name": "The Apartments Dubai World Trade Centre",
                    "ratings": "3.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.224167,
                            "longitude": 55.284902
                        }
                    },
                    "externalId": "3592422",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/4000000/3600000/3592500/3592422/d900c5d4_b.jpg",
                    "ha": [
                        "BAR",
                        "POOL",
                        "POOL",
                        "BAR",
                        "POOL"
                    ],
                    "guestreview": {
                        "cleaniness": "3.7",
                        "staff_service": "3.7",
                        "amenities": "3.8",
                        "conditions_facilities": "3.5"
                    },
                    "rat": "3.0",
                    "urat": "3.7",
                    "expediareview": 333,
                    "pid": "3592422",
                    "line1": "Sheikh Zayed Road",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Dubai International Convention and Exhibition Centre - 0.1 km / 0.1 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 492.61,
                "dwtf": 4296.35,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 289.82,
                "com_disc": 0,
                "rtf": "3804.00",
                "rdwtf": "4296.00"
            },
            {
                "tid": "BGhGaSu9A3VO5cQm",
                "pid": "20183124",
                "hs": 0,
                "rmid": "317969207",
                "rtid": "386770056",
                "refundable": false,
                "available_rooms": 15,
                "score": 330,
                "sno": "104",
                "cont": {
                    "_id": "600d3fc6a1bc371b80fadf2a",
                    "name": "MENA Plaza Hotel Albarsha",
                    "ratings": "4.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.113495,
                            "longitude": 55.202113
                        }
                    },
                    "externalId": "20183124",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/21000000/20190000/20183200/20183124/26ad9309_b.jpg",
                    "ha": [
                        "POOL",
                        "POOL",
                        "WIFI",
                        "GYM"
                    ],
                    "guestreview": {
                        "cleaniness": "4.5",
                        "staff_service": "4.4",
                        "amenities": 0,
                        "conditions_facilities": "4.2"
                    },
                    "rat": "4.0",
                    "urat": "4.3",
                    "expediareview": 36,
                    "pid": "20183124",
                    "line1": "Street 13 Al Barsha 1",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Fatima Abdullah Mohammed Rasheed Mosque - 1 km / 0.6 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 492.61,
                "dwtf": 4453.98,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 302.13,
                "com_disc": 0,
                "rtf": "3961.00",
                "rdwtf": "4454.00"
            },
            {
                "tid": "SSKSTNff0fio0VgN",
                "pid": "14827079",
                "hs": 0,
                "rmid": "219339264",
                "rtid": "282204221",
                "refundable": false,
                "available_rooms": 15,
                "score": 237,
                "sno": "117",
                "cont": {
                    "_id": "600d3e5fa1bc371b80f8fa30",
                    "name": "Landmark Summit Hotel",
                    "ratings": "4.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.264382,
                            "longitude": 55.319641
                        }
                    },
                    "externalId": "14827079",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/15000000/14830000/14827100/14827079/69704a54_b.jpg",
                    "ha": [
                        "BAR",
                        "POOL",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "3.2",
                        "staff_service": "3.7",
                        "amenities": "4.3",
                        "conditions_facilities": "3.3"
                    },
                    "rat": "4.0",
                    "urat": "3.4",
                    "expediareview": 59,
                    "pid": "14827079",
                    "line1": "34 B Street",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Al Ghurair Centre - 0.6 km / 0.4 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 492.61,
                "dwtf": 4573.85,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 311.16,
                "com_disc": 0,
                "rtf": "4081.00",
                "rdwtf": "4574.00"
            },
            {
                "tid": "QcRpbMcTJSvnvWso",
                "pid": "3474919",
                "hs": 0,
                "rmid": "201159228",
                "rtid": "386537091",
                "refundable": false,
                "available_rooms": 10,
                "score": 165,
                "sno": "118",
                "cont": {
                    "_id": "62506c6c2aee3e1bb50b6c21",
                    "ratings": "4.0",
                    "name": "Rayan Hotel Sharjah",
                    "location": {
                        "coordinates": {
                            "latitude": 25.34435,
                            "longitude": 55.4034
                        },
                        "obfuscationRequired": false
                    },
                    "externalId": "3474919",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/4000000/3480000/3475000/3474919/6dbde6fe_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.3",
                        "staff_service": "4.1",
                        "amenities": 0,
                        "conditions_facilities": "4.1"
                    },
                    "rat": "4.0",
                    "urat": "4.2",
                    "expediareview": 52,
                    "pid": "3474919",
                    "line1": "Off Al Wahda Road",
                    "city": "Sharjah",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Sharjah Gold Center - 0.3 km / 0.2 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 492.61,
                "dwtf": 4582.88,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 306.24,
                "com_disc": 0,
                "rtf": "4090.00",
                "rdwtf": "4583.00"
            },
            {
                "tid": "yIThA9qAO7Q45mM5",
                "pid": "34981609",
                "hs": 0,
                "rmid": "214208398",
                "rtid": "248092965",
                "refundable": true,
                "available_rooms": 4,
                "score": 202,
                "sno": "124",
                "cont": {
                    "_id": "600d41d8a1bc371b80fdc944",
                    "name": "Queen's Hotel",
                    "ratings": "2.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.2732,
                            "longitude": 55.3019
                        }
                    },
                    "externalId": "34981609",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/35000000/34990000/34981700/34981609/7a9b3d29_b.jpg",
                    "ha": [
                        "POOL",
                        "POOL"
                    ],
                    "guestreview": {
                        "cleaniness": "2.7",
                        "staff_service": "3.2",
                        "amenities": "2.9",
                        "conditions_facilities": "2.9"
                    },
                    "rat": "2.0",
                    "urat": "3.0",
                    "expediareview": 23,
                    "pid": "34981609",
                    "line1": "DEIRA",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Naif Souq - 0.5 km / 0.3 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 492.61,
                "dwtf": 4622.29,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 312.81,
                "com_disc": 0,
                "rtf": "4130.00",
                "rdwtf": "4622.00"
            },
            {
                "tid": "RKnn0TaMHFyiQm13",
                "pid": "5239808",
                "hs": 0,
                "rmid": "200189690",
                "rtid": "391443306",
                "refundable": false,
                "available_rooms": 21,
                "score": 333,
                "sno": "125",
                "cont": {
                    "_id": "600d414ca1bc371b80fcf27c",
                    "name": "Chelsea Plaza Hotel Dubai",
                    "ratings": "3.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.235246,
                            "longitude": 55.279274
                        }
                    },
                    "externalId": "5239808",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/6000000/5240000/5239900/5239808/c11296d4_b.jpg",
                    "ha": [
                        "BAR",
                        "GYM",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "3.7",
                        "staff_service": "3.5",
                        "amenities": "3.1",
                        "conditions_facilities": "3.3"
                    },
                    "rat": "3.0",
                    "urat": "3.3",
                    "expediareview": 247,
                    "pid": "5239808",
                    "line1": "Satwa Roundabout",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Dubai World Trade Centre - 1.6 km / 1 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 492.61,
                "dwtf": 4642,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 346.47,
                "com_disc": 0,
                "rtf": "4149.00",
                "rdwtf": "4642.00"
            },
            {
                "tid": "I7Py084L7N43cZcS",
                "pid": "12474441",
                "hs": 0,
                "rmid": "201550457",
                "rtid": "278395809",
                "refundable": false,
                "available_rooms": 15,
                "score": 495,
                "sno": "135",
                "cont": {
                    "_id": "600d4207a1bc371b80fe0595",
                    "name": "Atana Hotel",
                    "ratings": "4.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.10297,
                            "longitude": 55.176701
                        }
                    },
                    "externalId": "12474441",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/13000000/12480000/12474500/12474441/e7e333bb_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.4",
                        "staff_service": "4.3",
                        "amenities": "4.0",
                        "conditions_facilities": "4.3"
                    },
                    "rat": "4.0",
                    "urat": "4.2",
                    "expediareview": 787,
                    "pid": "12474441",
                    "line1": "Tecom",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Jebel Ali Race Course - 1.7 km / 1.1 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 492.61,
                "dwtf": 4731.49,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 323.48,
                "com_disc": 0,
                "rtf": "4239.00",
                "rdwtf": "4731.00"
            },
            {
                "tid": "LXbngs8lPNMqX3dJ",
                "pid": "10971762",
                "hs": 0,
                "rmid": "201137105",
                "rtid": "274382212",
                "refundable": true,
                "available_rooms": 1,
                "score": 175,
                "sno": "136",
                "cont": {
                    "_id": "600d409ba1bc371b80fc041a",
                    "name": "Manhattan Avenue Hotel",
                    "ratings": "1.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.251583,
                            "longitude": 55.290745
                        }
                    },
                    "externalId": "10971762",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/11000000/10980000/10971800/10971762/3eb56086_b.jpg",
                    "ha": [
                        "BAR",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "3.5",
                        "staff_service": "3.6",
                        "amenities": "3.7",
                        "conditions_facilities": "3.2"
                    },
                    "rat": "1.0",
                    "urat": "3.5",
                    "expediareview": 35,
                    "pid": "10971762",
                    "line1": "Al Mankhool Road",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Meena Bazaar - 1.5 km / 0.9 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 492.61,
                "dwtf": 4743.8,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 325.12,
                "com_disc": 0,
                "rtf": "4251.00",
                "rdwtf": "4744.00"
            },
            {
                "tid": "5XsAeC382KZtvJUM",
                "pid": "45485856",
                "hs": 0,
                "rmid": "233113585",
                "rtid": "294329734",
                "refundable": false,
                "available_rooms": 27,
                "score": 229,
                "sno": "11",
                "cont": {
                    "_id": "600d4265a1bc371b80fe8291",
                    "name": "KSK Homes",
                    "ratings": "0.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.129696,
                            "longitude": 55.427313
                        }
                    },
                    "externalId": "45485856",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/46000000/45490000/45485900/45485856/cfe3dcf6_b.jpg",
                    "ha": [
                        "POOL",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": 0,
                        "staff_service": 0,
                        "amenities": 0,
                        "conditions_facilities": 0
                    },
                    "rat": "0.0",
                    "urat": "",
                    "expediareview": 0,
                    "pid": "45485856",
                    "line1": "Academic City",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "American University in the Emirates - 2.1 km / 1.3 mi "
                },
                "hdtype": "HOTELHD",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 410.51,
                "dwtf": 3345.62,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 224.14,
                "com_disc": 0,
                "rtf": "2935.00",
                "rdwtf": "3346.00"
            },
            {
                "tid": "580VVTpOrNM7mhqb",
                "pid": "2696807",
                "hs": 0,
                "rmid": "201939664",
                "rtid": "209833367",
                "refundable": false,
                "available_rooms": 18,
                "score": 195,
                "sno": "15",
                "cont": {
                    "_id": "600d4166a1bc371b80fd0c18",
                    "name": "Royal Falcon Hotel",
                    "ratings": "3.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.270587,
                            "longitude": 55.319878
                        }
                    },
                    "externalId": "2696807",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/3000000/2700000/2696900/2696807/6f110a8e_b.jpg",
                    "ha": [
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "2.6",
                        "staff_service": "2.8",
                        "amenities": "2.8",
                        "conditions_facilities": "2.4"
                    },
                    "rat": "3.0",
                    "urat": "2.6",
                    "expediareview": 264,
                    "pid": "2696807",
                    "line1": "Salahuddin Road",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Fish Roundabout - 0.4 km / 0.3 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 410.51,
                "dwtf": 3431.83,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 230.7,
                "com_disc": 0,
                "rtf": "3021.00",
                "rdwtf": "3432.00"
            },
            {
                "tid": "9RgKTSH8eMtHwCnT",
                "pid": "69555306",
                "hs": 0,
                "rmid": "314421365",
                "rtid": "383430413",
                "refundable": false,
                "available_rooms": 5,
                "score": 200,
                "sno": "17",
                "cont": {
                    "_id": "62506a592aee3e1bb50b664f",
                    "ratings": "0.0",
                    "name": "MOUNT SINA HOTEL BY AURA",
                    "location": {
                        "coordinates": {
                            "latitude": 25.269253,
                            "longitude": 55.303148
                        },
                        "obfuscationRequired": false
                    },
                    "externalId": "69555306",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/70000000/69560000/69555400/69555306/7471d4b8_b.jpg",
                    "ha": [
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "3.4",
                        "staff_service": "3.4",
                        "amenities": "2.7",
                        "conditions_facilities": "3.2"
                    },
                    "rat": "0.0",
                    "urat": "3.6",
                    "expediareview": 20,
                    "pid": "69555306",
                    "line1": "Al Sabkha Rd",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Naif Souq - 0.4 km / 0.3 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 410.51,
                "dwtf": 3441.68,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 195.4,
                "com_disc": 0,
                "rtf": "3031.00",
                "rdwtf": "3442.00"
            },
            {
                "tid": "VToCadbUkRsT1ZTc",
                "pid": "69371396",
                "hs": 0,
                "rmid": "315575449",
                "rtid": "383955374",
                "refundable": true,
                "available_rooms": 4,
                "score": 110,
                "sno": "18",
                "cont": {
                    "_id": "62506a7a2aee3e1bb50b667d",
                    "ratings": "0.0",
                    "name": "Ecos Dubai Hotel at Al Furjan",
                    "location": {
                        "coordinates": {
                            "latitude": 25.02086,
                            "longitude": 55.153197
                        },
                        "obfuscatedCoordinates": {
                            "latitude": 25.019646,
                            "longitude": 55.15466
                        },
                        "obfuscationRequired": false
                    },
                    "externalId": "69371396",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/70000000/69380000/69371400/69371396/5d93396c_b.jpg",
                    "ha": [
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "1.0",
                        "staff_service": "1.0",
                        "amenities": 0,
                        "conditions_facilities": "1.0"
                    },
                    "rat": "0.0",
                    "urat": "1.0",
                    "expediareview": 1,
                    "pid": "69371396",
                    "line1": "Jebel Ali 1",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Ibn Battuta Mall - 6.9 km / 4.3 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 410.51,
                "dwtf": 3453.17,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 231.53,
                "com_disc": 0,
                "rtf": "3043.00",
                "rdwtf": "3453.00"
            },
            {
                "tid": "UDGxfvZAK2tQnFge",
                "pid": "3470732",
                "hs": 0,
                "rmid": "200835122",
                "rtid": "204352106",
                "refundable": false,
                "available_rooms": 7,
                "score": 195,
                "sno": "19",
                "cont": {
                    "_id": "600d43b9a1bc371b80003349",
                    "name": "Astoria Hotel",
                    "ratings": "3.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.262613,
                            "longitude": 55.291941
                        }
                    },
                    "externalId": "3470732",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/4000000/3480000/3470800/3470732/4f69d12d_b.jpg",
                    "ha": [
                        "BAR",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "3.2",
                        "staff_service": "3.4",
                        "amenities": "2.0",
                        "conditions_facilities": "2.7"
                    },
                    "rat": "3.0",
                    "urat": "2.9",
                    "expediareview": 97,
                    "pid": "3470732",
                    "line1": "Al Fahidi Street, Bur Dubai",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Textile Souk - 0.3 km / 0.2 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 410.51,
                "dwtf": 3453.17,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 231.53,
                "com_disc": 0,
                "rtf": "3043.00",
                "rdwtf": "3453.00"
            },
            {
                "tid": "223wKgASGPbu0Piz",
                "pid": "10073764",
                "hs": 0,
                "rmid": "200931335",
                "rtid": "381785703",
                "refundable": false,
                "available_rooms": 10,
                "score": 179,
                "sno": "24",
                "cont": {
                    "_id": "600d43a2a1bc371b800013b4",
                    "name": "White Fort Hotel",
                    "ratings": "1.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.268981,
                            "longitude": 55.305822
                        }
                    },
                    "externalId": "10073764",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/11000000/10080000/10073800/10073764/11a8c106_b.jpg",
                    "ha": [
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "3.0",
                        "staff_service": "3.1",
                        "amenities": "3.3",
                        "conditions_facilities": "3.0"
                    },
                    "rat": "1.0",
                    "urat": "3.1",
                    "expediareview": 123,
                    "pid": "10073764",
                    "line1": "Nasser Square, Near Baniyas",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Naif Souq - 0.5 km / 0.3 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 410.51,
                "dwtf": 3522.96,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 197.86,
                "com_disc": 0,
                "rtf": "3112.00",
                "rdwtf": "3523.00"
            },
            {
                "tid": "66f7RWWDmRSd1ws8",
                "pid": "16098107",
                "hs": 0,
                "rmid": "201669891",
                "rtid": "208330338",
                "refundable": false,
                "available_rooms": 10,
                "score": 163,
                "sno": "25",
                "cont": {
                    "_id": "600d3f3fa1bc371b80fa3509",
                    "name": "Mariana Hotel",
                    "ratings": "1.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.269498,
                            "longitude": 55.303229
                        }
                    },
                    "externalId": "16098107",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/17000000/16100000/16098200/16098107/6ca22ff7_b.jpg",
                    "ha": [
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "2.2",
                        "staff_service": "2.7",
                        "amenities": "1.0",
                        "conditions_facilities": "2.2"
                    },
                    "rat": "1.0",
                    "urat": "2.3",
                    "expediareview": 38,
                    "pid": "16098107",
                    "line1": "Al Sabkha Road",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Naif Souq - 0.4 km / 0.2 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 410.51,
                "dwtf": 3522.96,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 197.86,
                "com_disc": 0,
                "rtf": "3112.00",
                "rdwtf": "3523.00"
            },
            {
                "tid": "lhCH8EAmuTlxlAh7",
                "pid": "58003934",
                "hs": 0,
                "rmid": "232861013",
                "rtid": "382404542",
                "refundable": false,
                "available_rooms": 9,
                "score": 161,
                "sno": "26",
                "cont": {
                    "_id": "600d41c5a1bc371b80fda91b",
                    "name": "Downtown Hotel",
                    "ratings": "0.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.270998,
                            "longitude": 55.302518
                        }
                    },
                    "externalId": "58003934",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/59000000/58010000/58004000/58003934/940a8440_b.jpg",
                    "ha": [],
                    "guestreview": {
                        "cleaniness": 0,
                        "staff_service": 0,
                        "amenities": 0,
                        "conditions_facilities": 0
                    },
                    "rat": "0.0",
                    "urat": "",
                    "expediareview": 0,
                    "pid": "58003934",
                    "line1": "Al Sabkha Rd",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Naif Souq - 0.3 km / 0.2 mi "
                },
                "hdtype": "HOTELHD",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 410.51,
                "dwtf": 3522.96,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 197.86,
                "com_disc": 0,
                "rtf": "3112.00",
                "rdwtf": "3523.00"
            },
            {
                "tid": "Rw8hOP4zx1CRTw85",
                "pid": "68863561",
                "hs": 0,
                "rmid": "314447733",
                "rtid": "383465296",
                "refundable": false,
                "available_rooms": 15,
                "score": 146,
                "sno": "40",
                "cont": {
                    "_id": "62506c682aee3e1bb50b6c17",
                    "ratings": "0.0",
                    "name": "Citadines Culture Village Dubai, Waterfront Jadaf",
                    "location": {
                        "coordinates": {
                            "latitude": 25.229369,
                            "longitude": 55.338065
                        },
                        "obfuscatedCoordinates": {
                            "latitude": 25.229469,
                            "longitude": 55.332264
                        },
                        "obfuscationRequired": false
                    },
                    "externalId": "68863561",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/69000000/68870000/68863600/68863561/7d55af56_b.jpg",
                    "ha": [
                        "GYM",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.7",
                        "staff_service": 0,
                        "amenities": "4.0",
                        "conditions_facilities": "4.5"
                    },
                    "rat": "0.0",
                    "urat": "4.6",
                    "expediareview": 20,
                    "pid": "68863561",
                    "line1": "Al Jaddaf, Culture Village",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Mohammed Bin Rashid University Of Medicine and Health Sciences - 3.2 km / 2 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 410.51,
                "dwtf": 3767.62,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 280.79,
                "com_disc": 0,
                "rtf": "3357.00",
                "rdwtf": "3768.00"
            },
            {
                "tid": "y1l5KJbohgLlSqZX",
                "pid": "1210393",
                "hs": 0,
                "rmid": "200528428",
                "rtid": "210284204",
                "refundable": false,
                "available_rooms": 14,
                "score": 277,
                "sno": "46",
                "cont": {
                    "_id": "600d3fc7a1bc371b80fae19b",
                    "name": "Marco Polo Hotel",
                    "ratings": "4.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.273349,
                            "longitude": 55.318951
                        }
                    },
                    "externalId": "1210393",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/2000000/1220000/1210400/1210393/c0c92f95_b.jpg",
                    "ha": [
                        "BAR",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "3.8",
                        "staff_service": "3.9",
                        "amenities": "3.1",
                        "conditions_facilities": "3.5"
                    },
                    "rat": "4.0",
                    "urat": "3.6",
                    "expediareview": 531,
                    "pid": "1210393",
                    "line1": "Al Muteena Street",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Fish Roundabout - 0.8 km / 0.5 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 410.51,
                "dwtf": 3806.21,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 259.44,
                "com_disc": 0,
                "rtf": "3396.00",
                "rdwtf": "3806.00"
            },
            {
                "tid": "43xTFfGUJwH37Oc2",
                "pid": "64007079",
                "hs": 0,
                "rmid": "225629016",
                "rtid": "382834579",
                "refundable": false,
                "available_rooms": 19,
                "score": 169,
                "sno": "47",
                "cont": {
                    "_id": "62506c042aee3e1bb50b6b0a",
                    "ratings": "0.0",
                    "name": "Kingsgate Al Jadaf by Millennium Hotels",
                    "location": {
                        "coordinates": {
                            "latitude": 25.216757,
                            "longitude": 55.330939
                        },
                        "obfuscationRequired": false
                    },
                    "externalId": "64007079",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/65000000/64010000/64007100/64007079/7a3f7644_b.jpg",
                    "ha": [
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "2.3",
                        "staff_service": "2.5",
                        "amenities": "4.0",
                        "conditions_facilities": "3.3"
                    },
                    "rat": "0.0",
                    "urat": "2.2",
                    "expediareview": 4,
                    "pid": "64007079",
                    "line1": "Al Jaddaf",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Dubai Festival City Mall - 4.2 km / 2.6 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 410.51,
                "dwtf": 3835.76,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 247.12,
                "com_disc": 0,
                "rtf": "3425.00",
                "rdwtf": "3836.00"
            },
            {
                "tid": "8hPo17HhfILNXy8y",
                "pid": "114730",
                "hs": 0,
                "rmid": "210028385",
                "rtid": "220174844",
                "refundable": false,
                "available_rooms": 1,
                "score": 260,
                "sno": "50",
                "cont": {
                    "_id": "600d3e41a1bc371b80f8c3bb",
                    "name": "AL KHALEEJ PALACE DEIRA HOTEL",
                    "ratings": "4.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.263,
                            "longitude": 55.316844
                        }
                    },
                    "externalId": "114730",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/1000000/120000/114800/114730/4e0b3d9a_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "3.5",
                        "staff_service": "3.4",
                        "amenities": "3.2",
                        "conditions_facilities": "3.3"
                    },
                    "rat": "4.0",
                    "urat": "3.1",
                    "expediareview": 64,
                    "pid": "114730",
                    "line1": "Al Maktoum Street",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Al Ghurair Centre - 0.8 km / 0.5 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 410.51,
                "dwtf": 3866.96,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 263.54,
                "com_disc": 0,
                "rtf": "3456.00",
                "rdwtf": "3867.00"
            },
            {
                "tid": "STITkBiz1Jd2xZ8t",
                "pid": "39604927",
                "hs": 0,
                "rmid": "216351353",
                "rtid": "262230433",
                "refundable": true,
                "available_rooms": 6,
                "score": 145,
                "sno": "51",
                "cont": {
                    "_id": "600d3db1a1bc371b80f80cf4",
                    "name": "Le Wana Hotel",
                    "ratings": "0.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.267997,
                            "longitude": 55.315293
                        }
                    },
                    "externalId": "39604927",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/40000000/39610000/39605000/39604927/2312ae0a_b.jpg",
                    "ha": [
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.1",
                        "staff_service": "3.4",
                        "amenities": "3.0",
                        "conditions_facilities": "3.4"
                    },
                    "rat": "0.0",
                    "urat": "3.2",
                    "expediareview": 9,
                    "pid": "39604927",
                    "line1": "4th St",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Al Ghurair Centre - 0.3 km / 0.2 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 410.51,
                "dwtf": 3872.71,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 265.19,
                "com_disc": 0,
                "rtf": "3462.00",
                "rdwtf": "3873.00"
            },
            {
                "tid": "oe4sCGrXCdxpEP1u",
                "pid": "55606003",
                "hs": 0,
                "rmid": "228640269",
                "rtid": "264347827",
                "refundable": true,
                "available_rooms": 9,
                "score": 161,
                "sno": "55",
                "cont": {
                    "_id": "600d3e25a1bc371b80f89b3e",
                    "name": "Rose Plaza Hotel Al Barsha",
                    "ratings": "3.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.114504,
                            "longitude": 55.202577
                        }
                    },
                    "externalId": "55606003",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/56000000/55610000/55606100/55606003/ba8f9b52_b.jpg",
                    "ha": [
                        "POOL",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": 0,
                        "staff_service": 0,
                        "amenities": 0,
                        "conditions_facilities": 0
                    },
                    "rat": "3.0",
                    "urat": "",
                    "expediareview": 0,
                    "pid": "55606003",
                    "line1": "Near Mall of the Emirates,",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Fatima Abdullah Mohammed Rasheed Mosque - 1.1 km / 0.7 mi "
                },
                "hdtype": "HOTELHD",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 410.51,
                "dwtf": 3920.33,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 267.65,
                "com_disc": 0,
                "rtf": "3510.00",
                "rdwtf": "3920.00"
            },
            {
                "tid": "bW31yka2cEMZBygQ",
                "pid": "10368423",
                "hs": 0,
                "rmid": "201494981",
                "rtid": "207504802",
                "refundable": false,
                "available_rooms": 2,
                "score": 224,
                "sno": "56",
                "cont": {
                    "_id": "62506b892aee3e1bb50b6941",
                    "ratings": "3.0",
                    "name": "Al Sarab Hotel",
                    "location": {
                        "coordinates": {
                            "latitude": 25.262574,
                            "longitude": 55.322014
                        },
                        "obfuscationRequired": false
                    },
                    "externalId": "10368423",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/11000000/10370000/10368500/10368423/4248920a_b.jpg",
                    "ha": [
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.1",
                        "staff_service": "3.7",
                        "amenities": "3.6",
                        "conditions_facilities": "3.9"
                    },
                    "rat": "3.0",
                    "urat": "3.7",
                    "expediareview": 56,
                    "pid": "10368423",
                    "line1": "Al Rigga Street",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Al Ghurair Centre - 0.8 km / 0.5 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 410.51,
                "dwtf": 3936.75,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 269.29,
                "com_disc": 0,
                "rtf": "3526.00",
                "rdwtf": "3937.00"
            },
            {
                "tid": "zOs3sndbPmIC29RU",
                "pid": "11969003",
                "hs": 0,
                "rmid": "201235236",
                "rtid": "207002540",
                "refundable": true,
                "available_rooms": 2147483647,
                "score": 387,
                "sno": "72",
                "cont": {
                    "_id": "600d3fbba1bc371b80fad179",
                    "name": "ibis Styles Dubai Jumeira",
                    "ratings": "0.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.238864,
                            "longitude": 55.272738
                        }
                    },
                    "externalId": "11969003",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/12000000/11970000/11969100/11969003/256d9648_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.1",
                        "staff_service": "3.9",
                        "amenities": "3.7",
                        "conditions_facilities": "4.0"
                    },
                    "rat": "0.0",
                    "urat": "3.9",
                    "expediareview": 359,
                    "pid": "11969003",
                    "line1": "Al Mina Rd",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Jumeirah Mosque - 1.2 km / 0.8 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 410.51,
                "dwtf": 4082.89,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 232.35,
                "com_disc": 0,
                "rtf": "3672.00",
                "rdwtf": "4083.00"
            },
            {
                "tid": "MiUkiFbb0S19TgEH",
                "pid": "68444242",
                "hs": 0,
                "rmid": "314294228",
                "rtid": "387115513",
                "refundable": false,
                "available_rooms": 8,
                "score": 219,
                "sno": "3",
                "cont": {
                    "_id": "62506c442aee3e1bb50b6bbf",
                    "ratings": "3.0",
                    "name": "City Avenue Al Reqqa Hotel",
                    "location": {
                        "coordinates": {
                            "latitude": 25.274135,
                            "longitude": 55.327265
                        },
                        "obfuscationRequired": false
                    },
                    "externalId": "68444242",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/69000000/68450000/68444300/68444242/24bba205_b.jpg",
                    "ha": [
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.0",
                        "staff_service": "3.5",
                        "amenities": 0,
                        "conditions_facilities": "4.2"
                    },
                    "rat": "3.0",
                    "urat": "3.8",
                    "expediareview": 6,
                    "pid": "68444242",
                    "line1": "34 A Street",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Reef Mall - 1 km / 0.6 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 328.4,
                "dwtf": 2931.01,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 218.39,
                "com_disc": 0,
                "rtf": "2603.00",
                "rdwtf": "2931.00"
            },
            {
                "tid": "OUgHwDvlzOHVdqX0",
                "pid": "27772378",
                "hs": 0,
                "rmid": "219369866",
                "rtid": "282271312",
                "refundable": false,
                "available_rooms": 5,
                "score": 243,
                "sno": "4",
                "cont": {
                    "_id": "600d3d7ea1bc371b80f7b44c",
                    "name": "Smana Hotel Al Raffa",
                    "ratings": "3.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.2595,
                            "longitude": 55.28977
                        }
                    },
                    "externalId": "27772378",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/28000000/27780000/27772400/27772378/5c196c6d_b.jpg",
                    "ha": [
                        "BAR",
                        "GYM"
                    ],
                    "guestreview": {
                        "cleaniness": "3.5",
                        "staff_service": "3.7",
                        "amenities": "3.5",
                        "conditions_facilities": "3.5"
                    },
                    "rat": "3.0",
                    "urat": "3.4",
                    "expediareview": 38,
                    "pid": "27772378",
                    "line1": "37, Al Raffa, Bur Dubai, Dubai",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Meena Bazaar - 0.7 km / 0.4 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 328.4,
                "dwtf": 3051.7,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 208.54,
                "com_disc": 0,
                "rtf": "2723.00",
                "rdwtf": "3052.00"
            },
            {
                "tid": "HLetwJrQeJGrX0xh",
                "pid": "8836227",
                "hs": 0,
                "rmid": "228641364",
                "rtid": "382286637",
                "refundable": false,
                "available_rooms": 2,
                "score": 268,
                "sno": "8",
                "cont": {
                    "_id": "600d43daa1bc371b80005ec9",
                    "name": "Lotus Grand Hotel",
                    "ratings": "4.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.265466,
                            "longitude": 55.319332
                        }
                    },
                    "externalId": "8836227",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/9000000/8840000/8836300/8836227/0dd53808_b.jpg",
                    "ha": [
                        "BAR",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "3.4",
                        "staff_service": "3.4",
                        "amenities": "2.5",
                        "conditions_facilities": "3.1"
                    },
                    "rat": "4.0",
                    "urat": "3.0",
                    "expediareview": 77,
                    "pid": "8836227",
                    "line1": "Al Riqqa road, Deira",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Al Ghurair Centre - 0.5 km / 0.3 mi "
                },
                "hdtype": "HOTELHD",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 328.4,
                "dwtf": 3164.18,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 215.93,
                "com_disc": 0,
                "rtf": "2836.00",
                "rdwtf": "3164.00"
            },
            {
                "tid": "tmLknH81qJtMtL8t",
                "pid": "75831559",
                "hs": 0,
                "rmid": "316228545",
                "rtid": "385628235",
                "refundable": false,
                "available_rooms": 39,
                "score": 90,
                "sno": "199",
                "cont": {
                    "_id": "62506b772aee3e1bb50b68f8",
                    "name": "Studio M Al Barsha By Millennium",
                    "location": {
                        "coordinates": {
                            "latitude": 25.11281,
                            "longitude": 55.21478
                        },
                        "obfuscationRequired": false
                    },
                    "externalId": "75831559",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/76000000/75840000/75831600/75831559/9cbe4754_b.jpg",
                    "ha": [
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": 0,
                        "staff_service": 0,
                        "amenities": 0,
                        "conditions_facilities": 0
                    },
                    "rat": "",
                    "urat": "",
                    "expediareview": 0,
                    "ratings": "",
                    "pid": "75831559",
                    "line1": "Street 5, Al Barsha 1",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Mall of the Emirates - 1.9 km / 1.2 mi "
                },
                "hdtype": "HOTELHD",
                "brdbs": [
                    "Breakfast"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 328.4,
                "dwtf": 5185.51,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 396.55,
                "com_disc": 0,
                "rtf": "4857.00",
                "rdwtf": "5186.00"
            },
            {
                "tid": "47x8kx4DH3r6bNCs",
                "pid": "2235336",
                "hs": 0,
                "rmid": "211806808",
                "rtid": "232127509",
                "refundable": false,
                "available_rooms": 3,
                "score": 1087,
                "sno": "600",
                "cont": {
                    "_id": "600d426fa1bc371b80fe8ec6",
                    "name": "Atlantis The Palm",
                    "ratings": "5.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.130915,
                            "longitude": 55.116354
                        }
                    },
                    "externalId": "2235336",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/3000000/2240000/2235400/2235336/b85f4b92_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "BAR",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.6",
                        "staff_service": "4.4",
                        "amenities": "4.5",
                        "conditions_facilities": "4.5"
                    },
                    "rat": "5.0",
                    "urat": "4.4",
                    "expediareview": 1462,
                    "pid": "2235336",
                    "line1": "Crescent Road",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Aquaventure - 0.4 km / 0.2 mi "
                },
                "hdtype": "HOTELNHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 43475.82,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 3318.53,
                "com_disc": 0,
                "rtf": "43476.00",
                "rdwtf": "43476.00"
            },
            {
                "tid": "gtZg3mmWZDJAKEnV",
                "pid": "8697404",
                "hs": 0,
                "rmid": "216919865",
                "rtid": "266077586",
                "refundable": false,
                "available_rooms": 17,
                "score": 749,
                "sno": "599",
                "cont": {
                    "_id": "600d3e9fa1bc371b80f95cb7",
                    "name": "Four Seasons Resort Dubai at Jumeirah Beach",
                    "ratings": "5.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.201893,
                            "longitude": 55.24062
                        }
                    },
                    "externalId": "8697404",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/9000000/8700000/8697500/8697404/96ec3a3f_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "POOL",
                        "BAR",
                        "POOL"
                    ],
                    "guestreview": {
                        "cleaniness": "4.8",
                        "staff_service": "4.7",
                        "amenities": "4.8",
                        "conditions_facilities": "4.8"
                    },
                    "rat": "5.0",
                    "urat": "4.7",
                    "expediareview": 266,
                    "pid": "8697404",
                    "line1": "Jumeirah Road",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Jumeirah Archaeological Site - 0.6 km / 0.4 mi "
                },
                "hdtype": "HOTELNHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 42841.17,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 3217.54,
                "com_disc": 0,
                "rtf": "42841.00",
                "rdwtf": "42841.00"
            },
            {
                "tid": "INSLOXRyi7La9ogo",
                "pid": "527498",
                "hs": 0,
                "rmid": "200143712",
                "rtid": "382043138",
                "refundable": false,
                "available_rooms": 47,
                "score": 869,
                "sno": "584",
                "cont": {
                    "_id": "600d3f9ea1bc371b80fab0df",
                    "name": "Jumeirah Beach Hotel",
                    "ratings": "5.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.141357,
                            "longitude": 55.191811
                        }
                    },
                    "externalId": "527498",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/1000000/530000/527500/527498/e25f18bd_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "BAR",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.7",
                        "staff_service": "4.5",
                        "amenities": "4.5",
                        "conditions_facilities": "4.4"
                    },
                    "rat": "5.0",
                    "urat": "4.5",
                    "expediareview": 986,
                    "pid": "527498",
                    "line1": "Jumeirah Beach Road",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Umm Suqeim Beach - 0.4 km / 0.3 mi "
                },
                "hdtype": "HOTELNHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 33545.69,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 2559.91,
                "com_disc": 0,
                "rtf": "33546.00",
                "rdwtf": "33546.00"
            },
            {
                "tid": "86nIFRGO3hhUh0HE",
                "pid": "527458",
                "hs": 0,
                "rmid": "212107816",
                "rtid": "234158293",
                "refundable": false,
                "available_rooms": 6,
                "score": 743,
                "sno": "583",
                "cont": {
                    "_id": "600d41e4a1bc371b80fdd839",
                    "name": "One&Only Royal Mirage",
                    "ratings": "5.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.095031,
                            "longitude": 55.150397
                        }
                    },
                    "externalId": "527458",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/1000000/530000/527500/527458/0a38495f_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.7",
                        "staff_service": "4.6",
                        "amenities": "4.7",
                        "conditions_facilities": "4.6"
                    },
                    "rat": "5.0",
                    "urat": "4.6",
                    "expediareview": 425,
                    "pid": "527458",
                    "line1": "King Salman bin Abdul Aziz Al Saud St",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "American University of Dubai (AUD) - 1.1 km / 0.7 mi "
                },
                "hdtype": "HOTELNHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Breakfast"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 32858.5,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 2519.68,
                "com_disc": 0,
                "rtf": "32859.00",
                "rdwtf": "32859.00"
            },
            {
                "tid": "X0c1umZeskVDNQgW",
                "pid": "15378315",
                "hs": 0,
                "rmid": "201640003",
                "rtid": "208414509",
                "refundable": false,
                "available_rooms": 3,
                "score": 569,
                "sno": "580",
                "cont": {
                    "_id": "600d3e9ca1bc371b80f958a6",
                    "name": "Four Seasons Hotel Dubai International Financial Centre",
                    "ratings": "5.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.215094,
                            "longitude": 55.280139
                        }
                    },
                    "externalId": "15378315",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/16000000/15380000/15378400/15378315/3fd8661b_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.9",
                        "staff_service": "4.6",
                        "amenities": "4.8",
                        "conditions_facilities": "4.7"
                    },
                    "rat": "5.0",
                    "urat": "4.7",
                    "expediareview": 61,
                    "pid": "15378315",
                    "line1": "Unit Gv09 Level Gf Gate Village",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Dubai International Financial Centre - 0.1 km / 0.1 mi "
                },
                "hdtype": "HOTELNHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 30241.12,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 2271.74,
                "com_disc": 0,
                "rtf": "30241.00",
                "rdwtf": "30241.00"
            },
            {
                "tid": "cTsbg4OS6d5ytwgc",
                "pid": "127464",
                "hs": 0,
                "rmid": "17572",
                "rtid": "258238074",
                "refundable": false,
                "available_rooms": 5,
                "score": 674,
                "sno": "579",
                "cont": {
                    "_id": "600d3e88a1bc371b80f93fb6",
                    "name": "The Ritz-Carlton, Dubai",
                    "ratings": "5.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.082476,
                            "longitude": 55.137897
                        }
                    },
                    "externalId": "127464",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/1000000/130000/127500/127464/0b78238e_b.jpg",
                    "ha": [
                        "BAR",
                        "GYM",
                        "POOL",
                        "POOL"
                    ],
                    "guestreview": {
                        "cleaniness": "4.8",
                        "staff_service": "4.7",
                        "amenities": "4.5",
                        "conditions_facilities": "4.6"
                    },
                    "rat": "5.0",
                    "urat": "4.6",
                    "expediareview": 506,
                    "pid": "127464",
                    "line1": "Jumeirah Beach Walk",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "The Walk - 0.1 km / 0.1 mi "
                },
                "hdtype": "HOTELNHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Breakfast"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 29451.31,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 2490.95,
                "com_disc": 0,
                "rtf": "29451.00",
                "rdwtf": "29451.00"
            },
            {
                "tid": "FEKX2wo388O7um5H",
                "pid": "4944843",
                "hs": 0,
                "rmid": "200165056",
                "rtid": "268088990",
                "refundable": true,
                "available_rooms": 5,
                "score": 531,
                "sno": "578",
                "cont": {
                    "_id": "600d41e9a1bc371b80fdde07",
                    "name": "The Ritz-Carlton Executive Residences",
                    "ratings": "5.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.212806,
                            "longitude": 55.278693
                        }
                    },
                    "externalId": "4944843",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/5000000/4950000/4944900/4944843/bab65ce2_b.jpg",
                    "ha": [
                        "BAR",
                        "POOL",
                        "POOL",
                        "POOL",
                        "BAR"
                    ],
                    "guestreview": {
                        "cleaniness": "4.7",
                        "staff_service": "4.8",
                        "amenities": "4.7",
                        "conditions_facilities": "4.6"
                    },
                    "rat": "5.0",
                    "urat": "4.6",
                    "expediareview": 59,
                    "pid": "4944843",
                    "line1": "Gate Village, DIFC",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Dubai International Financial Centre - 0.2 km / 0.2 mi "
                },
                "hdtype": "HOTELNHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 28750.98,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 1856.31,
                "com_disc": 0,
                "rtf": "28751.00",
                "rdwtf": "28751.00"
            },
            {
                "tid": "ipApnlMpPNxgNgSn",
                "pid": "70041341",
                "hs": 0,
                "rmid": "315478393",
                "rtid": "383718775",
                "refundable": true,
                "available_rooms": 2,
                "score": 429,
                "sno": "575",
                "cont": {
                    "_id": "62506a2f2aee3e1bb50b6614",
                    "ratings": "",
                    "name": "Centara Mirage Beach Resort Dubai",
                    "location": {
                        "coordinates": {
                            "latitude": 25.300778,
                            "longitude": 55.304158
                        },
                        "obfuscationRequired": false
                    },
                    "externalId": "70041341",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/71000000/70050000/70041400/70041341/54c0209a_b.jpg",
                    "ha": [
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.0",
                        "staff_service": "4.0",
                        "amenities": "4.1",
                        "conditions_facilities": "4.2"
                    },
                    "rat": "",
                    "urat": "3.9",
                    "expediareview": 63,
                    "pid": "70041341",
                    "line1": "Deira Islands",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Souk Al Marfa - 2.1 km / 1.3 mi "
                },
                "hdtype": "HOTELNHD",
                "usrst": "Very Good",
                "brdbs": [
                    "Breakfast"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 26837.21,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 2048.42,
                "com_disc": 0,
                "rtf": "26837.00",
                "rdwtf": "26837.00"
            },
            {
                "tid": "FqZxB1vtfE5SoUnz",
                "pid": "6483363",
                "hs": 0,
                "rmid": "201567377",
                "rtid": "250347203",
                "refundable": false,
                "available_rooms": 17,
                "score": 707,
                "sno": "567",
                "cont": {
                    "_id": "600d3f9da1bc371b80faaf7f",
                    "name": "Anantara The Palm Dubai Resort",
                    "ratings": "5.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.129584,
                            "longitude": 55.154022
                        }
                    },
                    "externalId": "6483363",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/7000000/6490000/6483400/6483363/8e231eee_b.jpg",
                    "ha": [
                        "BAR",
                        "GYM",
                        "POOL",
                        "BAR",
                        "POOL"
                    ],
                    "guestreview": {
                        "cleaniness": "4.6",
                        "staff_service": "4.5",
                        "amenities": "4.5",
                        "conditions_facilities": "4.5"
                    },
                    "rat": "5.0",
                    "urat": "4.5",
                    "expediareview": 552,
                    "pid": "6483363",
                    "line1": "Palm Jumeirah, East Crescent",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Aquaventure - 4.6 km / 2.8 mi "
                },
                "hdtype": "HOTELNHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 24689.44,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 1884.22,
                "com_disc": 0,
                "rtf": "24689.00",
                "rdwtf": "24689.00"
            },
            {
                "tid": "lG78NTbcOm1oPbIx",
                "pid": "72626661",
                "hs": 0,
                "rmid": "315790374",
                "rtid": "384684659",
                "refundable": true,
                "available_rooms": 5,
                "score": 403,
                "sno": "566",
                "cont": {
                    "_id": "62506b622aee3e1bb50b68a1",
                    "ratings": "5.0",
                    "name": "The Dubai EDITION",
                    "location": {
                        "coordinates": {
                            "latitude": 25.192105,
                            "longitude": 55.280669
                        },
                        "obfuscationRequired": false
                    },
                    "externalId": "72626661",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/73000000/72630000/72626700/72626661/543e31f0_b.jpg",
                    "ha": [
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "5.0",
                        "staff_service": "5.0",
                        "amenities": 0,
                        "conditions_facilities": "5.0"
                    },
                    "rat": "5.0",
                    "urat": "5.0",
                    "expediareview": 1,
                    "pid": "72626661",
                    "line1": "Dubai Fountain Street, Downtown",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Dubai Water Canal - 0.5 km / 0.3 mi "
                },
                "hdtype": "HOTELNHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 24660.71,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 1711.81,
                "com_disc": 0,
                "rtf": "24661.00",
                "rdwtf": "24661.00"
            },
            {
                "tid": "LS23fnEyXdR0VWTo",
                "pid": "70128096",
                "hs": 0,
                "rmid": "315444499",
                "rtid": "391488903",
                "refundable": false,
                "available_rooms": 2147483647,
                "score": 314,
                "sno": "564",
                "cont": {
                    "_id": "62506c482aee3e1bb50b6bc7",
                    "ratings": "",
                    "name": "Raffles The Palm",
                    "location": {
                        "coordinates": {
                            "latitude": 25.110713,
                            "longitude": 55.10938
                        },
                        "obfuscationRequired": false
                    },
                    "externalId": "70128096",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/71000000/70130000/70128100/70128096/b2bf0f1e_b.jpg",
                    "ha": [
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "5.0",
                        "staff_service": "5.0",
                        "amenities": "5.0",
                        "conditions_facilities": "5.0"
                    },
                    "rat": "",
                    "urat": "4.8",
                    "expediareview": 4,
                    "pid": "70128096",
                    "line1": "West Cresent",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Aquaventure - 3 km / 1.8 mi "
                },
                "hdtype": "HOTELNHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 23002.27,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 1712.63,
                "com_disc": 0,
                "rtf": "23002.00",
                "rdwtf": "23002.00"
            },
            {
                "tid": "8TzaLolR4SQ8u2NI",
                "pid": "190827",
                "hs": 0,
                "rmid": "213304363",
                "rtid": "242174818",
                "refundable": false,
                "available_rooms": 5,
                "score": 763,
                "sno": "563",
                "cont": {
                    "_id": "600d4344a1bc371b80ffa208",
                    "name": "Le Royal Meridien Beach Resort And Spa",
                    "ratings": "5.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.084205,
                            "longitude": 55.14052
                        }
                    },
                    "externalId": "190827",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/1000000/200000/190900/190827/8a68f3c4_b.jpg",
                    "ha": [
                        "BAR",
                        "GYM",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.8",
                        "staff_service": "4.7",
                        "amenities": "4.8",
                        "conditions_facilities": "4.6"
                    },
                    "rat": "5.0",
                    "urat": "4.7",
                    "expediareview": 976,
                    "pid": "190827",
                    "line1": "Al Mamsha Street",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "The Walk - 0.2 km / 0.1 mi "
                },
                "hdtype": "HOTELNHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 22925.91,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 1378.48,
                "com_disc": 0,
                "rtf": "22926.00",
                "rdwtf": "22926.00"
            },
            {
                "tid": "FSDtVPda8kS6wBmD",
                "pid": "1273525",
                "hs": 0,
                "rmid": "200184900",
                "rtid": "201053467",
                "refundable": true,
                "available_rooms": 3,
                "score": 596,
                "sno": "560",
                "cont": {
                    "_id": "600d4217a1bc371b80fe186d",
                    "name": "Park Hyatt Dubai",
                    "ratings": "5.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.244879,
                            "longitude": 55.333325
                        }
                    },
                    "externalId": "1273525",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/2000000/1280000/1273600/1273525/e566c847_b.jpg",
                    "ha": [
                        "POOL",
                        "POOL",
                        "BAR",
                        "WIFI",
                        "GYM"
                    ],
                    "guestreview": {
                        "cleaniness": "4.8",
                        "staff_service": "4.8",
                        "amenities": "4.7",
                        "conditions_facilities": "4.7"
                    },
                    "rat": "5.0",
                    "urat": "4.8",
                    "expediareview": 488,
                    "pid": "1273525",
                    "line1": "Port Saweed",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Dubai Tennis Stadium - 0.7 km / 0.4 mi "
                },
                "hdtype": "HOTELNHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 21985.03,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 1664.19,
                "com_disc": 0,
                "rtf": "21985.00",
                "rdwtf": "21985.00"
            },
            {
                "tid": "4FtqCqcfDlwXvqGw",
                "pid": "19366493",
                "hs": 0,
                "rmid": "315628388",
                "rtid": "388146836",
                "refundable": true,
                "available_rooms": 5,
                "score": 558,
                "sno": "556",
                "cont": {
                    "_id": "600d413ca1bc371b80fce0e3",
                    "name": "Renaissance Downtown Hotel, Dubai",
                    "ratings": "5.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.186081,
                            "longitude": 55.273674
                        }
                    },
                    "externalId": "19366493",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/20000000/19370000/19366500/19366493/25acd2df_b.jpg",
                    "ha": [
                        "POOL",
                        "POOL",
                        "WIFI",
                        "GYM"
                    ],
                    "guestreview": {
                        "cleaniness": "4.8",
                        "staff_service": "4.8",
                        "amenities": "4.7",
                        "conditions_facilities": "4.8"
                    },
                    "rat": "5.0",
                    "urat": "4.7",
                    "expediareview": 246,
                    "pid": "19366493",
                    "line1": "Marasi Drive",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Dubai Water Canal - 0.3 km / 0.2 mi "
                },
                "hdtype": "HOTELNHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 21437.42,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 1487.67,
                "com_disc": 0,
                "rtf": "21437.00",
                "rdwtf": "21437.00"
            },
            {
                "tid": "NMy6EP8LFmf6p6UO",
                "pid": "3089613",
                "hs": 0,
                "rmid": "200041470",
                "rtid": "258215705",
                "refundable": true,
                "available_rooms": 5,
                "score": 577,
                "sno": "555",
                "cont": {
                    "_id": "600d41e9a1bc371b80fdde82",
                    "name": "The Ritz-Carlton, Dubai International Financial Centre",
                    "ratings": "5.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.212905,
                            "longitude": 55.278773
                        }
                    },
                    "externalId": "3089613",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/4000000/3090000/3089700/3089613/e934e200_b.jpg",
                    "ha": [
                        "BAR",
                        "GYM",
                        "POOL",
                        "POOL"
                    ],
                    "guestreview": {
                        "cleaniness": "4.6",
                        "staff_service": "4.6",
                        "amenities": "4.6",
                        "conditions_facilities": "4.5"
                    },
                    "rat": "5.0",
                    "urat": "4.6",
                    "expediareview": 261,
                    "pid": "3089613",
                    "line1": "Gate Village, DIFC",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Dubai International Financial Centre - 0.2 km / 0.2 mi "
                },
                "hdtype": "HOTELNHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 21397.19,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 1286.52,
                "com_disc": 0,
                "rtf": "21397.00",
                "rdwtf": "21397.00"
            },
            {
                "tid": "z4eKHelMKQM9sgqp",
                "pid": "6750573",
                "hs": 0,
                "rmid": "200412103",
                "rtid": "258077551",
                "refundable": true,
                "available_rooms": 4,
                "score": 641,
                "sno": "554",
                "cont": {
                    "_id": "600d4051a1bc371b80fb99aa",
                    "name": "DoubleTree by Hilton Dubai - Jumeirah Beach",
                    "ratings": "4.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.072105,
                            "longitude": 55.128146
                        }
                    },
                    "externalId": "6750573",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/7000000/6760000/6750600/6750573/2b0cc00e_b.jpg",
                    "ha": [
                        "BAR",
                        "POOL",
                        "BAR",
                        "POOL",
                        "POOL"
                    ],
                    "guestreview": {
                        "cleaniness": "4.4",
                        "staff_service": "4.4",
                        "amenities": "3.8",
                        "conditions_facilities": "4.2"
                    },
                    "rat": "4.0",
                    "urat": "4.2",
                    "expediareview": 645,
                    "pid": "6750573",
                    "line1": "Jumeirah Beach Residence - The Walk",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "The Walk - 0.1 km / 0.1 mi "
                },
                "hdtype": "HOTELNHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 21173.05,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 1603.43,
                "com_disc": 0,
                "rtf": "21173.00",
                "rdwtf": "21173.00"
            },
            {
                "tid": "vqBnG6V6RkVZwTHF",
                "pid": "1596020",
                "hs": 0,
                "rmid": "446982",
                "rtid": "1601496",
                "refundable": false,
                "available_rooms": 5,
                "score": 562,
                "sno": "552",
                "cont": {
                    "_id": "600d3ef8a1bc371b80f9c923",
                    "name": "Dubai Marriott Harbour Hotel & Suites",
                    "ratings": "4.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.087536,
                            "longitude": 55.145817
                        }
                    },
                    "externalId": "1596020",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/2000000/1600000/1596100/1596020/15ae34cb_b.jpg",
                    "ha": [
                        "BAR",
                        "GYM",
                        "POOL"
                    ],
                    "guestreview": {
                        "cleaniness": "4.6",
                        "staff_service": "4.6",
                        "amenities": "4.6",
                        "conditions_facilities": "4.4"
                    },
                    "rat": "4.0",
                    "urat": "4.5",
                    "expediareview": 512,
                    "pid": "1596020",
                    "line1": "King Salman Bin Abdulaziz Al Saud St",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "The Walk - 1.1 km / 0.7 mi "
                },
                "hdtype": "HOTELNHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 21041.69,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 1779.13,
                "com_disc": 0,
                "rtf": "21042.00",
                "rdwtf": "21042.00"
            },
            {
                "tid": "wJnCI41FMibudH8q",
                "pid": "7332018",
                "hs": 0,
                "rmid": "200522130",
                "rtid": "384363407",
                "refundable": false,
                "available_rooms": 1,
                "score": 733,
                "sno": "548",
                "cont": {
                    "_id": "600d4244a1bc371b80fe5799",
                    "name": "Waldorf Astoria Dubai Palm Jumeirah",
                    "ratings": "5.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.134858,
                            "longitude": 55.151324
                        }
                    },
                    "externalId": "7332018",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/8000000/7340000/7332100/7332018/95d0b8ae_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "BAR",
                        "POOL",
                        "POOL"
                    ],
                    "guestreview": {
                        "cleaniness": "4.9",
                        "staff_service": "4.7",
                        "amenities": "4.5",
                        "conditions_facilities": "4.8"
                    },
                    "rat": "5.0",
                    "urat": "4.7",
                    "expediareview": 847,
                    "pid": "7332018",
                    "line1": "Crescent Road, The Palm Jumeirah",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Aquaventure - 3.9 km / 2.4 mi "
                },
                "hdtype": "HOTELNHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Half Board"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 20148.43,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 1525.44,
                "com_disc": 0,
                "rtf": "20148.00",
                "rdwtf": "20148.00"
            },
            {
                "tid": "XS9LMnC4T0aT5F4q",
                "pid": "33086673",
                "hs": 0,
                "rmid": "219613492",
                "rtid": "284073644",
                "refundable": true,
                "available_rooms": 7,
                "score": 581,
                "sno": "547",
                "cont": {
                    "_id": "600d3eeba1bc371b80f9bc30",
                    "name": "Waldorf Astoria Dubai International Financial Centre",
                    "ratings": "5.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.211322,
                            "longitude": 55.281284
                        }
                    },
                    "externalId": "33086673",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/34000000/33090000/33086700/33086673/3c9aece3_b.jpg",
                    "ha": [
                        "POOL",
                        "POOL",
                        "BAR",
                        "WIFI",
                        "GYM"
                    ],
                    "guestreview": {
                        "cleaniness": "4.6",
                        "staff_service": "4.6",
                        "amenities": "4.4",
                        "conditions_facilities": "4.6"
                    },
                    "rat": "5.0",
                    "urat": "4.7",
                    "expediareview": 19,
                    "pid": "33086673",
                    "line1": "Burj Daman, Al Mustaqbal Street, DIFC",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Dubai International Financial Centre - 1.1 km / 0.7 mi "
                },
                "hdtype": "HOTELNHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 19903.77,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 1506.56,
                "com_disc": 0,
                "rtf": "19904.00",
                "rdwtf": "19904.00"
            },
            {
                "tid": "L4CrWNmM5yIGPfJ0",
                "pid": "9642646",
                "hs": 0,
                "rmid": "315603298",
                "rtid": "384114879",
                "refundable": true,
                "available_rooms": 2147483647,
                "score": 299,
                "sno": "542",
                "cont": {
                    "_id": "600d4317a1bc371b80ff774f",
                    "name": "Fairmont Ajman",
                    "ratings": "5.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.41738,
                            "longitude": 55.439597
                        }
                    },
                    "externalId": "9642646",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/10000000/9650000/9642700/9642646/c2e0f1c3_b.jpg",
                    "ha": [
                        "POOL",
                        "POOL",
                        "BAR",
                        "POOL",
                        "POOL"
                    ],
                    "guestreview": {
                        "cleaniness": "4.6",
                        "staff_service": "4.4",
                        "amenities": "3.9",
                        "conditions_facilities": "4.5"
                    },
                    "rat": "5.0",
                    "urat": "4.4",
                    "expediareview": 186,
                    "pid": "9642646",
                    "line1": "Sheikh Humaid Bin Rashid Al Nuaimi St.",
                    "city": "Ajman",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Ajman Museum - 0.8 km / 0.5 mi "
                },
                "hdtype": "HOTELNHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 19228.08,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 1429.38,
                "com_disc": 0,
                "rtf": "19228.00",
                "rdwtf": "19228.00"
            },
            {
                "tid": "xP7F3hmuT3zaitfZ",
                "pid": "63852448",
                "hs": 0,
                "rmid": "227762450",
                "rtid": "258389890",
                "refundable": true,
                "available_rooms": 2147483647,
                "score": 404,
                "sno": "540",
                "cont": {
                    "_id": "62506bdf2aee3e1bb50b6a7a",
                    "ratings": "5.0",
                    "name": "SLS Dubai Hotel & Residences",
                    "location": {
                        "coordinates": {
                            "latitude": 25.184721,
                            "longitude": 55.292264
                        },
                        "obfuscatedCoordinates": {
                            "latitude": 25.20507,
                            "longitude": 55.267339
                        },
                        "obfuscationRequired": false
                    },
                    "externalId": "63852448",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/64000000/63860000/63852500/63852448/bdd74890_b.jpg",
                    "ha": [
                        "GYM",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.5",
                        "staff_service": "4.3",
                        "amenities": "4.6",
                        "conditions_facilities": "4.5"
                    },
                    "rat": "5.0",
                    "urat": "4.3",
                    "expediareview": 42,
                    "pid": "63852448",
                    "line1": "Marsi Drive",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Dubai Water Canal - 1 km / 0.6 mi "
                },
                "hdtype": "HOTELNHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 18785.55,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 1433.49,
                "com_disc": 0,
                "rtf": "18786.00",
                "rdwtf": "18786.00"
            },
            {
                "tid": "s2g4lB9R9Vc8L80s",
                "pid": "65376308",
                "hs": 0,
                "rmid": "231652969",
                "rtid": "284322814",
                "refundable": false,
                "available_rooms": 5,
                "score": 491,
                "sno": "539",
                "cont": {
                    "_id": "62506ae42aee3e1bb50b6710",
                    "ratings": "0.0",
                    "name": "The St. Regis Dubai, The Palm",
                    "location": {
                        "coordinates": {
                            "latitude": 25.11404,
                            "longitude": 55.140009
                        },
                        "obfuscationRequired": false
                    },
                    "externalId": "65376308",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/66000000/65380000/65376400/65376308/c4207c1c_b.jpg",
                    "ha": [
                        "POOL",
                        "WIFI",
                        "GYM"
                    ],
                    "guestreview": {
                        "cleaniness": "4.9",
                        "staff_service": "4.8",
                        "amenities": "4.5",
                        "conditions_facilities": "4.9"
                    },
                    "rat": "0.0",
                    "urat": "4.8",
                    "expediareview": 23,
                    "pid": "65376308",
                    "line1": "Palm Jumeirah",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "The View at The Palm - 0.1 km / 0.1 mi "
                },
                "hdtype": "HOTELNHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 18765.85,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 1413.78,
                "com_disc": 0,
                "rtf": "18766.00",
                "rdwtf": "18766.00"
            },
            {
                "tid": "9HJsQswiQ56avmJM",
                "pid": "3454761",
                "hs": 0,
                "rmid": "211503733",
                "rtid": "230097999",
                "refundable": true,
                "available_rooms": 5,
                "score": 650,
                "sno": "537",
                "cont": {
                    "_id": "600d410ca1bc371b80fcaa42",
                    "name": "Sheraton Mall of the Emirates Hotel, Dubai",
                    "ratings": "5.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.119911,
                            "longitude": 55.202935
                        }
                    },
                    "externalId": "3454761",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/4000000/3460000/3454800/3454761/66290978_b.jpg",
                    "ha": [
                        "POOL",
                        "BAR",
                        "POOL",
                        "WIFI",
                        "GYM"
                    ],
                    "guestreview": {
                        "cleaniness": "4.6",
                        "staff_service": "4.5",
                        "amenities": "4.4",
                        "conditions_facilities": "4.5"
                    },
                    "rat": "5.0",
                    "urat": "4.4",
                    "expediareview": 796,
                    "pid": "3454761",
                    "line1": "Sheikh Zayed Road - Al Barsha",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Mall of the Emirates - 0.3 km / 0.2 mi "
                },
                "hdtype": "HOTELNHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 18691.96,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 1386.69,
                "com_disc": 0,
                "rtf": "18692.00",
                "rdwtf": "18692.00"
            },
            {
                "tid": "G7S3EnQwDyEtOz11",
                "pid": "47377",
                "hs": 0,
                "rmid": "200930561",
                "rtid": "204665859",
                "refundable": false,
                "available_rooms": 5,
                "score": 704,
                "sno": "527",
                "cont": {
                    "_id": "600d4231a1bc371b80fe3c25",
                    "name": "Habtoor Grand Resort, Autograph Collection",
                    "ratings": "5.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.085844,
                            "longitude": 55.141793
                        }
                    },
                    "externalId": "47377",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/1000000/50000/47400/47377/7d295732_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "POOL",
                        "BAR",
                        "POOL"
                    ],
                    "guestreview": {
                        "cleaniness": "4.3",
                        "staff_service": "4.3",
                        "amenities": "4.6",
                        "conditions_facilities": "4.1"
                    },
                    "rat": "5.0",
                    "urat": "4.2",
                    "expediareview": 981,
                    "pid": "47377",
                    "line1": "Al Falea Street, Jumeirah Beach",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "The Walk - 0.5 km / 0.3 mi "
                },
                "hdtype": "HOTELNHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 17577.02,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 1324.29,
                "com_disc": 0,
                "rtf": "17577.00",
                "rdwtf": "17577.00"
            },
            {
                "tid": "0ncgDNogxHFRBAea",
                "pid": "6899004",
                "hs": 0,
                "rmid": "200666075",
                "rtid": "203259471",
                "refundable": true,
                "available_rooms": 2147483647,
                "score": 755,
                "sno": "523",
                "cont": {
                    "_id": "600d4315a1bc371b80ff758c",
                    "name": "Sofitel Dubai Downtown",
                    "ratings": "5.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.200879,
                            "longitude": 55.269158
                        }
                    },
                    "externalId": "6899004",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/7000000/6900000/6899100/6899004/be084259_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "BAR",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.8",
                        "staff_service": "4.7",
                        "amenities": "4.6",
                        "conditions_facilities": "4.7"
                    },
                    "rat": "5.0",
                    "urat": "4.7",
                    "expediareview": 989,
                    "pid": "6899004",
                    "line1": "Sheihk Zayed Road",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Emaar Square - 0.5 km / 0.3 mi "
                },
                "hdtype": "HOTELNHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 17149.28,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 1309.51,
                "com_disc": 0,
                "rtf": "17149.00",
                "rdwtf": "17149.00"
            },
            {
                "tid": "gzX5isT5FW22CHiQ",
                "pid": "1305504",
                "hs": 0,
                "rmid": "213304759",
                "rtid": "242174834",
                "refundable": true,
                "available_rooms": 5,
                "score": 726,
                "sno": "517",
                "cont": {
                    "_id": "600d4355a1bc371b80ffb3f1",
                    "name": "Grosvenor House, a Luxury Collection Hotel, Dubai",
                    "ratings": "5.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.085225,
                            "longitude": 55.143426
                        }
                    },
                    "externalId": "1305504",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/2000000/1310000/1305600/1305504/1863666f_b.jpg",
                    "ha": [
                        "POOL",
                        "BAR",
                        "POOL",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.9",
                        "staff_service": "4.8",
                        "amenities": "4.8",
                        "conditions_facilities": "4.8"
                    },
                    "rat": "5.0",
                    "urat": "4.8",
                    "expediareview": 985,
                    "pid": "1305504",
                    "line1": "Al Emreef Street",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "The Walk - 0.7 km / 0.4 mi "
                },
                "hdtype": "HOTELNHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Breakfast"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 16370.96,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 1136.28,
                "com_disc": 0,
                "rtf": "16371.00",
                "rdwtf": "16371.00"
            },
            {
                "tid": "SOVrufG22pEPiRAq",
                "pid": "12034252",
                "hs": 0,
                "rmid": "201261187",
                "rtid": "280040020",
                "refundable": true,
                "available_rooms": 9,
                "score": 615,
                "sno": "513",
                "cont": {
                    "_id": "600d4039a1bc371b80fb798e",
                    "name": "Shangri-La Residences and Apartments",
                    "ratings": "5.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.208217,
                            "longitude": 55.272297
                        }
                    },
                    "externalId": "12034252",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/13000000/12040000/12034300/12034252/1844de31_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.4",
                        "staff_service": "4.5",
                        "amenities": "4.2",
                        "conditions_facilities": "4.2"
                    },
                    "rat": "5.0",
                    "urat": "4.4",
                    "expediareview": 75,
                    "pid": "12034252",
                    "line1": "Sheikh Zayed Road",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Dubai International Financial Centre - 1.4 km / 0.9 mi "
                },
                "hdtype": "HOTELNHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 16205.12,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 1236.44,
                "com_disc": 0,
                "rtf": "16205.00",
                "rdwtf": "16205.00"
            },
            {
                "tid": "y4gySsbHRywpRHnB",
                "pid": "12346991",
                "hs": 0,
                "rmid": "220822193",
                "rtid": "292081306",
                "refundable": true,
                "available_rooms": 9,
                "score": 564,
                "sno": "512",
                "cont": {
                    "_id": "600d3ee9a1bc371b80f9b969",
                    "name": "Habtoor Palace Dubai, LXR Hotels & Resorts",
                    "ratings": "5.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.182824,
                            "longitude": 55.254523
                        }
                    },
                    "externalId": "12346991",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/13000000/12350000/12347000/12346991/2c097a14_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "POOL",
                        "BAR",
                        "POOL"
                    ],
                    "guestreview": {
                        "cleaniness": "4.6",
                        "staff_service": "4.4",
                        "amenities": "4.5",
                        "conditions_facilities": "4.6"
                    },
                    "rat": "5.0",
                    "urat": "4.4",
                    "expediareview": 52,
                    "pid": "12346991",
                    "line1": "Al Habtoor City, Sheikh Zayed Road",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Safa Park - 4.1 km / 2.5 mi "
                },
                "hdtype": "HOTELNHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 16196.91,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 1226.59,
                "com_disc": 0,
                "rtf": "16197.00",
                "rdwtf": "16197.00"
            },
            {
                "tid": "Mq53759Lt3Csmk70",
                "pid": "486304",
                "hs": 0,
                "rmid": "200057740",
                "rtid": "236351667",
                "refundable": true,
                "available_rooms": 41,
                "score": 766,
                "sno": "511",
                "cont": {
                    "_id": "600d3eaea1bc371b80f96e3a",
                    "name": "Jumeirah Emirates Towers",
                    "ratings": "5.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.216667,
                            "longitude": 55.282249
                        }
                    },
                    "externalId": "486304",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/1000000/490000/486400/486304/43387e51_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "POOL",
                        "BAR",
                        "POOL"
                    ],
                    "guestreview": {
                        "cleaniness": "4.9",
                        "staff_service": "4.8",
                        "amenities": "4.7",
                        "conditions_facilities": "4.8"
                    },
                    "rat": "5.0",
                    "urat": "4.8",
                    "expediareview": 992,
                    "pid": "486304",
                    "line1": "Sheikh Zayed Road",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Dubai International Financial Centre - 0.4 km / 0.2 mi "
                },
                "hdtype": "HOTELNHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 16102.49,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 1229.05,
                "com_disc": 0,
                "rtf": "16102.00",
                "rdwtf": "16102.00"
            },
            {
                "tid": "gpakknDy38dyOkF5",
                "pid": "34039882",
                "hs": 0,
                "rmid": "220239895",
                "rtid": "288163547",
                "refundable": false,
                "available_rooms": 5,
                "score": 871,
                "sno": "510",
                "cont": {
                    "_id": "600d425aa1bc371b80fe74e5",
                    "name": "W Dubai - The Palm",
                    "ratings": "0.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.106196,
                            "longitude": 55.110932
                        }
                    },
                    "externalId": "34039882",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/35000000/34040000/34039900/34039882/4517d196_b.jpg",
                    "ha": [
                        "POOL",
                        "POOL",
                        "BAR",
                        "POOL",
                        "POOL"
                    ],
                    "guestreview": {
                        "cleaniness": "4.7",
                        "staff_service": "4.8",
                        "amenities": "4.7",
                        "conditions_facilities": "4.8"
                    },
                    "rat": "0.0",
                    "urat": "4.7",
                    "expediareview": 110,
                    "pid": "34039882",
                    "line1": "West Crescent, Palm Jumeirah",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Aquaventure - 3.5 km / 2.2 mi "
                },
                "hdtype": "HOTELNHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 16005.61,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 1205.24,
                "com_disc": 0,
                "rtf": "16006.00",
                "rdwtf": "16006.00"
            },
            {
                "tid": "NkLOm8lIpFhsr9i9",
                "pid": "69404538",
                "hs": 0,
                "rmid": "314413072",
                "rtid": "383423137",
                "refundable": true,
                "available_rooms": 9,
                "score": 76,
                "sno": "503",
                "cont": {
                    "_id": "62506c0d2aee3e1bb50b6b28",
                    "ratings": "0.0",
                    "name": "Hilton Dubai Palm Jumeirah",
                    "location": {
                        "coordinates": {
                            "latitude": 25.105782,
                            "longitude": 55.146821
                        },
                        "obfuscationRequired": false
                    },
                    "externalId": "69404538",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/70000000/69410000/69404600/69404538/w3836h2523x3y0-1e4dfaaa_b.jpg",
                    "ha": [],
                    "guestreview": {
                        "cleaniness": 0,
                        "staff_service": 0,
                        "amenities": 0,
                        "conditions_facilities": 0
                    },
                    "rat": "0.0",
                    "urat": "",
                    "expediareview": 0,
                    "pid": "69404538",
                    "line1": "Palm Jumeirah Trunk Road",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Nakheel Mall - 1.2 km / 0.7 mi "
                },
                "hdtype": "HOTELNHD",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 15454.71,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 1170.76,
                "com_disc": 0,
                "rtf": "15455.00",
                "rdwtf": "15455.00"
            },
            {
                "tid": "dh7Nc3lbUq4sZf2g",
                "pid": "1738573",
                "hs": 0,
                "rmid": "211211220",
                "rtid": "380746223",
                "refundable": false,
                "available_rooms": 1,
                "score": 655,
                "sno": "492",
                "cont": {
                    "_id": "600d3ee3a1bc371b80f9b485",
                    "name": "InterContinental Dubai Festival City",
                    "ratings": "5.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.223505,
                            "longitude": 55.349895
                        }
                    },
                    "externalId": "1738573",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/2000000/1740000/1738600/1738573/3ff1298c_b.jpg",
                    "ha": [
                        "POOL",
                        "POOL",
                        "BAR",
                        "POOL",
                        "POOL"
                    ],
                    "guestreview": {
                        "cleaniness": "4.7",
                        "staff_service": "4.6",
                        "amenities": "4.6",
                        "conditions_facilities": "4.6"
                    },
                    "rat": "5.0",
                    "urat": "4.6",
                    "expediareview": 376,
                    "pid": "1738573",
                    "line1": "Crescent Drive",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Dubai Festival City Mall - 0.8 km / 0.5 mi "
                },
                "hdtype": "HOTELNHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 14774.91,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 1113.29,
                "com_disc": 0,
                "rtf": "14775.00",
                "rdwtf": "14775.00"
            },
            {
                "tid": "wS8kPaOQ55bQTQlD",
                "pid": "2557312",
                "hs": 0,
                "rmid": "398668",
                "rtid": "1350829",
                "refundable": true,
                "available_rooms": 2147483647,
                "score": 816,
                "sno": "491",
                "cont": {
                    "_id": "600d4378a1bc371b80ffdb75",
                    "name": "Sofitel Dubai Jumeirah Beach",
                    "ratings": "5.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.075025,
                            "longitude": 55.13258
                        }
                    },
                    "externalId": "2557312",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/3000000/2560000/2557400/2557312/f0d37077_b.jpg",
                    "ha": [
                        "BAR",
                        "GYM",
                        "POOL",
                        "POOL",
                        "BAR"
                    ],
                    "guestreview": {
                        "cleaniness": "4.7",
                        "staff_service": "4.6",
                        "amenities": "4.5",
                        "conditions_facilities": "4.5"
                    },
                    "rat": "5.0",
                    "urat": "4.6",
                    "expediareview": 987,
                    "pid": "2557312",
                    "line1": "Jumeirah Beach - Dubai Marina",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "The Beach Mall - 0.8 km / 0.5 mi "
                },
                "hdtype": "HOTELNHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 14733.86,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 1124.79,
                "com_disc": 0,
                "rtf": "14734.00",
                "rdwtf": "14734.00"
            },
            {
                "tid": "yHrfWMfldWxrxH7b",
                "pid": "918930",
                "hs": 0,
                "rmid": "202143037",
                "rtid": "387384924",
                "refundable": true,
                "available_rooms": 4,
                "score": 836,
                "sno": "488",
                "cont": {
                    "_id": "600d4321a1bc371b80ff8060",
                    "name": "Shangri La Hotel Dubai",
                    "ratings": "5.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.207977,
                            "longitude": 55.27221
                        }
                    },
                    "externalId": "918930",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/1000000/920000/919000/918930/a690acdc_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "POOL",
                        "POOL",
                        "BAR"
                    ],
                    "guestreview": {
                        "cleaniness": "4.8",
                        "staff_service": "4.7",
                        "amenities": "4.7",
                        "conditions_facilities": "4.6"
                    },
                    "rat": "5.0",
                    "urat": "4.6",
                    "expediareview": 994,
                    "pid": "918930",
                    "line1": "Sheikh Zayed Road",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "City Walk - 1.1 km / 0.7 mi "
                },
                "hdtype": "HOTELNHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 14467.03,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 1073.88,
                "com_disc": 0,
                "rtf": "14467.00",
                "rdwtf": "14467.00"
            },
            {
                "tid": "digWaEz8N6spegJK",
                "pid": "21564979",
                "hs": 0,
                "rmid": "220234599",
                "rtid": "288142878",
                "refundable": false,
                "available_rooms": 2,
                "score": 562,
                "sno": "486",
                "cont": {
                    "_id": "600d4256a1bc371b80fe6fe5",
                    "name": "Aloft Palm Jumeirah",
                    "ratings": "4.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.125452,
                            "longitude": 55.154643
                        }
                    },
                    "externalId": "21564979",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/22000000/21570000/21565000/21564979/27c6975b_b.jpg",
                    "ha": [
                        "POOL",
                        "POOL",
                        "BAR",
                        "POOL",
                        "POOL"
                    ],
                    "guestreview": {
                        "cleaniness": "4.5",
                        "staff_service": "4.4",
                        "amenities": "3.9",
                        "conditions_facilities": "4.4"
                    },
                    "rat": "4.0",
                    "urat": "4.2",
                    "expediareview": 169,
                    "pid": "21564979",
                    "line1": "East Crescent, The Palm Jumeirah",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Aquaventure - 5.1 km / 3.2 mi "
                },
                "hdtype": "HOTELNHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 14384.11,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 1083.73,
                "com_disc": 0,
                "rtf": "14384.00",
                "rdwtf": "14384.00"
            },
            {
                "tid": "pWEz8zsXxHT0V58E",
                "pid": "2882514",
                "hs": 0,
                "rmid": "200044573",
                "rtid": "380937228",
                "refundable": false,
                "available_rooms": 7,
                "score": 474,
                "sno": "476",
                "cont": {
                    "_id": "600d3e18a1bc371b80f88931",
                    "name": "InterContinental Residence Suites Dubai Festival City",
                    "ratings": "5.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.231598,
                            "longitude": 55.346731
                        }
                    },
                    "externalId": "2882514",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/3000000/2890000/2882600/2882514/0c091cff_b.jpg",
                    "ha": [
                        "POOL",
                        "POOL",
                        "GYM"
                    ],
                    "guestreview": {
                        "cleaniness": "4.4",
                        "staff_service": "4.3",
                        "amenities": "4.4",
                        "conditions_facilities": "4.3"
                    },
                    "rat": "5.0",
                    "urat": "4.1",
                    "expediareview": 108,
                    "pid": "2882514",
                    "line1": "Dubai Festival City",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Dubai Festival City Mall - 1.5 km / 0.9 mi "
                },
                "hdtype": "HOTELNHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 13755.22,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 1036.12,
                "com_disc": 0,
                "rtf": "13755.00",
                "rdwtf": "13755.00"
            },
            {
                "tid": "T8d7IMB7cikhXUH7",
                "pid": "559080",
                "hs": 0,
                "rmid": "94088",
                "rtid": "226061476",
                "refundable": true,
                "available_rooms": 5,
                "score": 785,
                "sno": "475",
                "cont": {
                    "_id": "600d4148a1bc371b80fced83",
                    "name": "Hilton Dubai Jumeirah",
                    "ratings": "5.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.078572,
                            "longitude": 55.133885
                        }
                    },
                    "externalId": "559080",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/1000000/560000/559100/559080/72d6669a_b.jpg",
                    "ha": [
                        "POOL",
                        "BAR",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.5",
                        "staff_service": "4.4",
                        "amenities": "4.3",
                        "conditions_facilities": "4.1"
                    },
                    "rat": "5.0",
                    "urat": "4.3",
                    "expediareview": 980,
                    "pid": "559080",
                    "line1": "The Walk, Dubai Marina",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "The Walk - 0.1 km / 0.1 mi "
                },
                "hdtype": "HOTELNHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 13691.18,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 865.35,
                "com_disc": 0,
                "rtf": "13691.00",
                "rdwtf": "13691.00"
            },
            {
                "tid": "MFIMTaTSpUyqhQyr",
                "pid": "57777067",
                "hs": 0,
                "rmid": "228346849",
                "rtid": "262364406",
                "refundable": true,
                "available_rooms": 8,
                "score": 264,
                "sno": "472",
                "cont": {
                    "_id": "600d3ed6a1bc371b80f9a357",
                    "name": "Jumeirah Living Marina Gate",
                    "ratings": "0.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.087474,
                            "longitude": 55.146577
                        }
                    },
                    "externalId": "57777067",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/58000000/57780000/57777100/57777067/f1ae7fb7_b.jpg",
                    "ha": [
                        "BAR",
                        "GYM",
                        "POOL",
                        "POOL"
                    ],
                    "guestreview": {
                        "cleaniness": 0,
                        "staff_service": 0,
                        "amenities": 0,
                        "conditions_facilities": 0
                    },
                    "rat": "0.0",
                    "urat": "",
                    "expediareview": 0,
                    "pid": "57777067",
                    "line1": "Al Khayay Street, Dubai Marina",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "The Walk - 1.2 km / 0.8 mi "
                },
                "hdtype": "HOTELNHD",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 13417.78,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 1023.8,
                "com_disc": 0,
                "rtf": "13418.00",
                "rdwtf": "13418.00"
            },
            {
                "tid": "X0cNQEIe7yGkobhd",
                "pid": "9374465",
                "hs": 0,
                "rmid": "201864766",
                "rtid": "209436424",
                "refundable": true,
                "available_rooms": 2147483647,
                "score": 395,
                "sno": "473",
                "cont": {
                    "_id": "600d4079a1bc371b80fbd21b",
                    "name": "Pullman Dubai Creek City Centre Residences",
                    "ratings": "0.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.250868,
                            "longitude": 55.335063
                        }
                    },
                    "externalId": "9374465",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/10000000/9380000/9374500/9374465/3d694c9f_b.jpg",
                    "ha": [
                        "BAR",
                        "POOL",
                        "POOL",
                        "BAR",
                        "POOL"
                    ],
                    "guestreview": {
                        "cleaniness": "4.7",
                        "staff_service": "4.6",
                        "amenities": "4.4",
                        "conditions_facilities": "4.6"
                    },
                    "rat": "0.0",
                    "urat": "4.6",
                    "expediareview": 188,
                    "pid": "9374465",
                    "line1": "Deira City Centre Baniyas Road",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "City Centre Deira - 0.4 km / 0.3 mi "
                },
                "hdtype": "HOTELNHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 13417.78,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 1023.8,
                "com_disc": 0,
                "rtf": "13418.00",
                "rdwtf": "13418.00"
            },
            {
                "tid": "L4TX7vT7hmapPnpa",
                "pid": "15679765",
                "hs": 0,
                "rmid": "201792209",
                "rtid": "209173268",
                "refundable": false,
                "available_rooms": 5,
                "score": 482,
                "sno": "467",
                "cont": {
                    "_id": "600d3fb4a1bc371b80fac8fc",
                    "name": "La Ville Hotel & Suites CITY WALK, Dubai, Autograph Collection",
                    "ratings": "5.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.208512,
                            "longitude": 55.260307
                        }
                    },
                    "externalId": "15679765",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/16000000/15680000/15679800/15679765/e270b165_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.7",
                        "staff_service": "4.6",
                        "amenities": "4.7",
                        "conditions_facilities": "4.7"
                    },
                    "rat": "5.0",
                    "urat": "4.7",
                    "expediareview": 84,
                    "pid": "15679765",
                    "line1": "Al Multaqa Street",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "City Walk - 0.4 km / 0.2 mi "
                },
                "hdtype": "HOTELNHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 13037.65,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 1101.8,
                "com_disc": 0,
                "rtf": "13038.00",
                "rdwtf": "13038.00"
            },
            {
                "tid": "wwl053KItQMibxDS",
                "pid": "5715227",
                "hs": 0,
                "rmid": "200251611",
                "rtid": "246027779",
                "refundable": true,
                "available_rooms": 9,
                "score": 627,
                "sno": "465",
                "cont": {
                    "_id": "600d428ba1bc371b80feb415",
                    "name": "Conrad Dubai",
                    "ratings": "5.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.2254,
                            "longitude": 55.284748
                        }
                    },
                    "externalId": "5715227",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/6000000/5720000/5715300/5715227/4a0a331d_b.jpg",
                    "ha": [
                        "BAR",
                        "GYM",
                        "POOL",
                        "POOL",
                        "BAR"
                    ],
                    "guestreview": {
                        "cleaniness": "4.7",
                        "staff_service": "4.6",
                        "amenities": "4.6",
                        "conditions_facilities": "4.6"
                    },
                    "rat": "5.0",
                    "urat": "4.6",
                    "expediareview": 992,
                    "pid": "5715227",
                    "line1": "Sheikh Zayed Road",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Dubai World Trade Centre - 1.1 km / 0.7 mi "
                },
                "hdtype": "HOTELNHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 12970.33,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 982.75,
                "com_disc": 0,
                "rtf": "12970.00",
                "rdwtf": "12970.00"
            },
            {
                "tid": "umgeQKAu8hQpqhJ5",
                "pid": "9423640",
                "hs": 0,
                "rmid": "201139617",
                "rtid": "205407456",
                "refundable": true,
                "available_rooms": 16,
                "score": 749,
                "sno": "464",
                "cont": {
                    "_id": "600d3e0ba1bc371b80f87292",
                    "name": "Taj Dubai",
                    "ratings": "5.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.1928,
                            "longitude": 55.26706
                        }
                    },
                    "externalId": "9423640",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/10000000/9430000/9423700/9423640/2bafae65_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "POOL",
                        "BAR",
                        "POOL"
                    ],
                    "guestreview": {
                        "cleaniness": "4.8",
                        "staff_service": "4.8",
                        "amenities": "4.6",
                        "conditions_facilities": "4.7"
                    },
                    "rat": "5.0",
                    "urat": "4.7",
                    "expediareview": 839,
                    "pid": "9423640",
                    "line1": "Burj Khalifa Street",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Dubai Opera - 1 km / 0.6 mi "
                },
                "hdtype": "HOTELNHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 12936.67,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 991.78,
                "com_disc": 0,
                "rtf": "12937.00",
                "rdwtf": "12937.00"
            },
            {
                "tid": "6Te0n5hJB3PQnGr4",
                "pid": "48995",
                "hs": 0,
                "rmid": "213603584",
                "rtid": "244082829",
                "refundable": false,
                "available_rooms": 5,
                "score": 720,
                "sno": "456",
                "cont": {
                    "_id": "62506b8f2aee3e1bb50b6959",
                    "ratings": "5.0",
                    "name": "Sheraton Jumeirah Beach Resort",
                    "location": {
                        "coordinates": {
                            "latitude": 25.072935,
                            "longitude": 55.129271
                        },
                        "obfuscationRequired": false
                    },
                    "externalId": "48995",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/1000000/50000/49000/48995/f3983560_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.3",
                        "staff_service": "4.3",
                        "amenities": "4.3",
                        "conditions_facilities": "4.0"
                    },
                    "rat": "5.0",
                    "urat": "4.1",
                    "expediareview": 832,
                    "pid": "48995",
                    "line1": "Al Mamsha Road",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "The Walk - 0.1 km / 0.1 mi "
                },
                "hdtype": "HOTELNHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 12490.04,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 1056.64,
                "com_disc": 0,
                "rtf": "12490.00",
                "rdwtf": "12490.00"
            },
            {
                "tid": "9eLKyOtsOh4KiiLF",
                "pid": "9695519",
                "hs": 0,
                "rmid": "201503188",
                "rtid": "272010554",
                "refundable": true,
                "available_rooms": 14,
                "score": 453,
                "sno": "453",
                "cont": {
                    "_id": "600d42bca1bc371b80fef8d8",
                    "name": "DAMAC Maison Cour Jardin",
                    "ratings": "5.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.187564,
                            "longitude": 55.268054
                        }
                    },
                    "externalId": "9695519",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/10000000/9700000/9695600/9695519/50ba8947_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.0",
                        "staff_service": "3.8",
                        "amenities": "4.5",
                        "conditions_facilities": "4.0"
                    },
                    "rat": "5.0",
                    "urat": "3.9",
                    "expediareview": 92,
                    "pid": "9695519",
                    "line1": "Al Abraj Street, Business Bay",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Souk al Bahar - 1.3 km / 0.8 mi "
                },
                "hdtype": "HOTELNHD",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 12076.25,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 921.17,
                "com_disc": 0,
                "rtf": "12076.00",
                "rdwtf": "12076.00"
            },
            {
                "tid": "Z2DiQ9y1tKWZNM6b",
                "pid": "30468789",
                "hs": 0,
                "rmid": "228316198",
                "rtid": "262296881",
                "refundable": true,
                "available_rooms": 2147483647,
                "score": 445,
                "sno": "454",
                "cont": {
                    "_id": "600d3de7a1bc371b80f84ad0",
                    "name": "Mövenpick Hotel Apartments Downtown Dubai",
                    "ratings": "0.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.194269,
                            "longitude": 55.283747
                        }
                    },
                    "externalId": "30468789",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/31000000/30470000/30468800/30468789/48814e9b_b.jpg",
                    "ha": [
                        "POOL",
                        "POOL",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.6",
                        "staff_service": "4.5",
                        "amenities": "4.6",
                        "conditions_facilities": "4.6"
                    },
                    "rat": "0.0",
                    "urat": "4.5",
                    "expediareview": 141,
                    "pid": "30468789",
                    "line1": "Al Ohood Street",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Dubai Water Canal - 0.8 km / 0.5 mi "
                },
                "hdtype": "HOTELNHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 12076.25,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 921.17,
                "com_disc": 0,
                "rtf": "12076.00",
                "rdwtf": "12076.00"
            },
            {
                "tid": "b4TwI1siApzC082T",
                "pid": "66960192",
                "hs": 0,
                "rmid": "315512897",
                "rtid": "383781340",
                "refundable": true,
                "available_rooms": 19,
                "score": 93,
                "sno": "452",
                "cont": {
                    "_id": "62506c302aee3e1bb50b6b91",
                    "ratings": "3.5",
                    "name": "Umm Al Quwain Beach Hotel",
                    "location": {
                        "coordinates": {
                            "latitude": 25.548682,
                            "longitude": 55.538865
                        },
                        "obfuscationRequired": false
                    },
                    "externalId": "66960192",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/67000000/66970000/66960200/66960192/db7fdb70_b.jpg",
                    "ha": [
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.6",
                        "staff_service": "4.6",
                        "amenities": "4.0",
                        "conditions_facilities": "4.4"
                    },
                    "rat": "3.5",
                    "urat": "5.0",
                    "expediareview": 5,
                    "pid": "66960192",
                    "line1": "Shaikh Saud Street",
                    "city": "Umm al Quwain",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Umm Al Quwain- Beach - 0.4 km / 0.3 mi "
                },
                "hdtype": "HOTELNHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Breakfast"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 12060.65,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 1036.12,
                "com_disc": 0,
                "rtf": "12061.00",
                "rdwtf": "12061.00"
            },
            {
                "tid": "5nPnvLCPmaPKiAkd",
                "pid": "18552366",
                "hs": 0,
                "rmid": "218700978",
                "rtid": "380938312",
                "refundable": false,
                "available_rooms": 2,
                "score": 399,
                "sno": "444",
                "cont": {
                    "_id": "600d3f57a1bc371b80fa5843",
                    "name": "Holiday Inn Dubai Festival City",
                    "ratings": "0.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.221962,
                            "longitude": 55.358019
                        }
                    },
                    "externalId": "18552366",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/19000000/18560000/18552400/18552366/a545cdd1_b.jpg",
                    "ha": [
                        "POOL",
                        "POOL",
                        "BAR",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.6",
                        "staff_service": "4.6",
                        "amenities": "4.5",
                        "conditions_facilities": "4.6"
                    },
                    "rat": "0.0",
                    "urat": "4.5",
                    "expediareview": 160,
                    "pid": "18552366",
                    "line1": "Gateway Avenue, Festival City",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Dubai Festival City Mall - 0.9 km / 0.5 mi "
                },
                "hdtype": "HOTELNHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 11463.78,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 863.7,
                "com_disc": 0,
                "rtf": "11464.00",
                "rdwtf": "11464.00"
            },
            {
                "tid": "kU00Kfc0nVuWKi35",
                "pid": "8680611",
                "hs": 0,
                "rmid": "218112368",
                "rtid": "380821960",
                "refundable": false,
                "available_rooms": 7,
                "score": 606,
                "sno": "441",
                "cont": {
                    "_id": "600d3eeea1bc371b80f9becb",
                    "name": "Intercontinental Dubai Marina",
                    "ratings": "0.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.078757,
                            "longitude": 55.137189
                        }
                    },
                    "externalId": "8680611",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/9000000/8690000/8680700/8680611/4858ae75_b.jpg",
                    "ha": [
                        "POOL",
                        "POOL",
                        "BAR",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.7",
                        "staff_service": "4.7",
                        "amenities": "4.3",
                        "conditions_facilities": "4.6"
                    },
                    "rat": "0.0",
                    "urat": "4.6",
                    "expediareview": 232,
                    "pid": "8680611",
                    "line1": "King Salman Bin Abdul Aziz Al Saud St",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Jumeirah Beach - 0.6 km / 0.4 mi "
                },
                "hdtype": "HOTELNHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 11183.81,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 843.18,
                "com_disc": 0,
                "rtf": "11184.00",
                "rdwtf": "11184.00"
            },
            {
                "tid": "gcE46Eze1haGJ82r",
                "pid": "13333164",
                "hs": 0,
                "rmid": "220822181",
                "rtid": "292093164",
                "refundable": true,
                "available_rooms": 9,
                "score": 578,
                "sno": "438",
                "cont": {
                    "_id": "600d4270a1bc371b80fe8ffd",
                    "name": "Hilton Dubai Al Habtoor City",
                    "ratings": "5.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.184368,
                            "longitude": 55.253553
                        }
                    },
                    "externalId": "13333164",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/14000000/13340000/13333200/13333164/627afee1_b.jpg",
                    "ha": [
                        "POOL",
                        "BAR",
                        "POOL",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.1",
                        "staff_service": "4.0",
                        "amenities": "4.4",
                        "conditions_facilities": "4.2"
                    },
                    "rat": "5.0",
                    "urat": "4.1",
                    "expediareview": 153,
                    "pid": "13333164",
                    "line1": "Al Habtoor City, Sheikh Zayed Road",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Safa Park - 1.7 km / 1 mi "
                },
                "hdtype": "HOTELNHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 10927.66,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 827.58,
                "com_disc": 0,
                "rtf": "10928.00",
                "rdwtf": "10928.00"
            },
            {
                "tid": "hNvA8ICiDEgS8q9d",
                "pid": "18113069",
                "hs": 0,
                "rmid": "201997132",
                "rtid": "384380274",
                "refundable": true,
                "available_rooms": 9,
                "score": 544,
                "sno": "437",
                "cont": {
                    "_id": "600d3ec5a1bc371b80f98b02",
                    "name": "DoubleTree by Hilton Dubai - Business Bay",
                    "ratings": "4.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.186721,
                            "longitude": 55.280758
                        }
                    },
                    "externalId": "18113069",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/19000000/18120000/18113100/18113069/723bb70b_b.jpg",
                    "ha": [
                        "BAR",
                        "POOL",
                        "POOL",
                        "BAR",
                        "POOL"
                    ],
                    "guestreview": {
                        "cleaniness": "4.7",
                        "staff_service": "4.6",
                        "amenities": "4.4",
                        "conditions_facilities": "4.7"
                    },
                    "rat": "4.0",
                    "urat": "4.6",
                    "expediareview": 543,
                    "pid": "18113069",
                    "line1": "Bay Square 5, Business Bay",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Souk al Bahar - 1.5 km / 0.9 mi "
                },
                "hdtype": "HOTELNHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Breakfast"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 10883.32,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 824.3,
                "com_disc": 0,
                "rtf": "10883.00",
                "rdwtf": "10883.00"
            },
            {
                "tid": "TvM1vQZhumBQfaJo",
                "pid": "525237",
                "hs": 0,
                "rmid": "200095283",
                "rtid": "200542143",
                "refundable": true,
                "available_rooms": 2147483647,
                "score": 484,
                "sno": "435",
                "cont": {
                    "_id": "600d3eb8a1bc371b80f97a97",
                    "name": "Pullman Dubai Creek City Centre",
                    "ratings": "5.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.251922,
                            "longitude": 55.334647
                        }
                    },
                    "externalId": "525237",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/1000000/530000/525300/525237/f5ce4b04_b.jpg",
                    "ha": [
                        "POOL",
                        "POOL",
                        "BAR",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.5",
                        "staff_service": "4.4",
                        "amenities": "4.5",
                        "conditions_facilities": "4.4"
                    },
                    "rat": "5.0",
                    "urat": "4.4",
                    "expediareview": 689,
                    "pid": "525237",
                    "line1": "Baniyas Road",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "City Centre Deira - 0.4 km / 0.3 mi "
                },
                "hdtype": "HOTELNHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 10733.9,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 818.55,
                "com_disc": 0,
                "rtf": "10734.00",
                "rdwtf": "10734.00"
            },
            {
                "tid": "zpTqsi2bRzSINheT",
                "pid": "1795484",
                "hs": 0,
                "rmid": "315127",
                "rtid": "381008002",
                "refundable": false,
                "available_rooms": 9,
                "score": 550,
                "sno": "433",
                "cont": {
                    "_id": "600d4091a1bc371b80fbf5da",
                    "name": "Crowne Plaza Festival City",
                    "ratings": "5.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.224115,
                            "longitude": 55.349434
                        }
                    },
                    "externalId": "1795484",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/2000000/1800000/1795500/1795484/9283b6c4_b.jpg",
                    "ha": [
                        "BAR",
                        "GYM",
                        "POOL",
                        "POOL"
                    ],
                    "guestreview": {
                        "cleaniness": "4.7",
                        "staff_service": "4.6",
                        "amenities": "4.6",
                        "conditions_facilities": "4.6"
                    },
                    "rat": "5.0",
                    "urat": "4.6",
                    "expediareview": 637,
                    "pid": "1795484",
                    "line1": "Dubai Festival City",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Festival Center - 0.8 km / 0.5 mi "
                },
                "hdtype": "HOTELNHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 10698.59,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 805.41,
                "com_disc": 0,
                "rtf": "10699.00",
                "rdwtf": "10699.00"
            },
            {
                "tid": "7bor37nmnkiL21RM",
                "pid": "11910831",
                "hs": 0,
                "rmid": "218129314",
                "rtid": "274109237",
                "refundable": false,
                "available_rooms": 5,
                "score": 301,
                "sno": "429",
                "cont": {
                    "_id": "600d3ea0a1bc371b80f95ea7",
                    "name": "Sheraton Sharjah Beach Resort & Spa",
                    "ratings": "5.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.395157,
                            "longitude": 55.423157
                        }
                    },
                    "externalId": "11910831",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/12000000/11920000/11910900/11910831/3b6543d8_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "POOL",
                        "POOL"
                    ],
                    "guestreview": {
                        "cleaniness": "4.4",
                        "staff_service": "4.4",
                        "amenities": "4.1",
                        "conditions_facilities": "4.5"
                    },
                    "rat": "5.0",
                    "urat": "4.3",
                    "expediareview": 338,
                    "pid": "11910831",
                    "line1": "Al Muntazah Street",
                    "city": "Sharjah",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Ajman Beach - 1.3 km / 0.8 mi "
                },
                "hdtype": "HOTELNHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Breakfast"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 10247.86,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 750.4,
                "com_disc": 0,
                "rtf": "10248.00",
                "rdwtf": "10248.00"
            },
            {
                "tid": "ceElJcDqzfXwMxiE",
                "pid": "64383195",
                "hs": 0,
                "rmid": "228364439",
                "rtid": "262389335",
                "refundable": false,
                "available_rooms": 9,
                "score": 431,
                "sno": "428",
                "cont": {
                    "_id": "62506b372aee3e1bb50b67f7",
                    "ratings": "0.0",
                    "name": "Staybridge Suites Dubai Financial Centre, an IHG Hotel",
                    "location": {
                        "coordinates": {
                            "latitude": 25.211623,
                            "longitude": 55.274288
                        },
                        "obfuscatedCoordinates": {
                            "latitude": 25.210607,
                            "longitude": 55.270035
                        },
                        "obfuscationRequired": false
                    },
                    "externalId": "64383195",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/65000000/64390000/64383200/64383195/8e98e510_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.6",
                        "staff_service": "4.1",
                        "amenities": "5.0",
                        "conditions_facilities": "4.4"
                    },
                    "rat": "0.0",
                    "urat": "4.5",
                    "expediareview": 17,
                    "pid": "64383195",
                    "line1": "SHEIKH ZAYED ROAD",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Sheikh Zayed Road - 0.1 km / 0.1 mi "
                },
                "hdtype": "HOTELNHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Breakfast"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 10162.47,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 764.36,
                "com_disc": 0,
                "rtf": "10162.00",
                "rdwtf": "10162.00"
            },
            {
                "tid": "OWK6OvqftydsJDnn",
                "pid": "12294745",
                "hs": 0,
                "rmid": "314045428",
                "rtid": "381324011",
                "refundable": true,
                "available_rooms": 2147483647,
                "score": 609,
                "sno": "424",
                "cont": {
                    "_id": "600d3ec1a1bc371b80f9863c",
                    "name": "Steigenberger Hotel Downtown Dubai",
                    "ratings": "5.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.187316,
                            "longitude": 55.27169
                        }
                    },
                    "externalId": "12294745",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/13000000/12300000/12294800/12294745/73f63969_b.jpg",
                    "ha": [
                        "BAR",
                        "POOL",
                        "POOL",
                        "BAR",
                        "POOL"
                    ],
                    "guestreview": {
                        "cleaniness": "4.6",
                        "staff_service": "4.5",
                        "amenities": "4.3",
                        "conditions_facilities": "4.5"
                    },
                    "rat": "5.0",
                    "urat": "4.4",
                    "expediareview": 738,
                    "pid": "12294745",
                    "line1": "Al Abraj Street with Marasi Drive",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Souk al Bahar - 0.9 km / 0.6 mi "
                },
                "hdtype": "HOTELNHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 10063.95,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 768.47,
                "com_disc": 0,
                "rtf": "10064.00",
                "rdwtf": "10064.00"
            },
            {
                "tid": "wT6d2Uh4pUA9zOWd",
                "pid": "13320158",
                "hs": 0,
                "rmid": "221422269",
                "rtid": "296082434",
                "refundable": true,
                "available_rooms": 8,
                "score": 604,
                "sno": "422",
                "cont": {
                    "_id": "600d3ff2a1bc371b80fb185e",
                    "name": "V Hotel Dubai, Curio Collection by Hilton",
                    "ratings": "5.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.184565,
                            "longitude": 55.253265
                        }
                    },
                    "externalId": "13320158",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/14000000/13330000/13320200/13320158/d3f10dc4_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "POOL",
                        "BAR",
                        "POOL"
                    ],
                    "guestreview": {
                        "cleaniness": "4.3",
                        "staff_service": "4.1",
                        "amenities": "4.6",
                        "conditions_facilities": "4.2"
                    },
                    "rat": "5.0",
                    "urat": "4.2",
                    "expediareview": 127,
                    "pid": "13320158",
                    "line1": "Sheikh Zayed Road",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Safa Park - 3.5 km / 2.2 mi "
                },
                "hdtype": "HOTELNHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 9834.07,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 743.84,
                "com_disc": 0,
                "rtf": "9834.00",
                "rdwtf": "9834.00"
            },
            {
                "tid": "oWh2VzHI8LbLUxDI",
                "pid": "2885038",
                "hs": 0,
                "rmid": "211510143",
                "rtid": "230229652",
                "refundable": true,
                "available_rooms": 2147483647,
                "score": 772,
                "sno": "412",
                "cont": {
                    "_id": "600d4210a1bc371b80fe1138",
                    "name": "Movenpick Hotel Jumeirah Beach",
                    "ratings": "5.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.080469,
                            "longitude": 55.136524
                        }
                    },
                    "externalId": "2885038",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/3000000/2890000/2885100/2885038/22b39dfd_b.jpg",
                    "ha": [
                        "POOL",
                        "POOL",
                        "BAR",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.4",
                        "staff_service": "4.5",
                        "amenities": "4.2",
                        "conditions_facilities": "4.2"
                    },
                    "rat": "5.0",
                    "urat": "4.2",
                    "expediareview": 984,
                    "pid": "2885038",
                    "line1": "Jumeirah Beach Residence",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "The Walk - 0.1 km / 0.1 mi "
                },
                "hdtype": "HOTELNHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 9392.37,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 716.74,
                "com_disc": 0,
                "rtf": "9392.00",
                "rdwtf": "9392.00"
            },
            {
                "tid": "kJb8SyuaNI9ki45C",
                "pid": "2175146",
                "hs": 0,
                "rmid": "200281321",
                "rtid": "202014686",
                "refundable": false,
                "available_rooms": 2147483647,
                "score": 376,
                "sno": "411",
                "cont": {
                    "_id": "600d3ec7a1bc371b80f98e28",
                    "name": "Novotel Dubai Deira City Centre",
                    "ratings": "4.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.25353,
                            "longitude": 55.332519
                        }
                    },
                    "externalId": "2175146",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/3000000/2180000/2175200/2175146/076ed4a7_b.jpg",
                    "ha": [
                        "BAR",
                        "GYM",
                        "POOL",
                        "POOL",
                        "BAR"
                    ],
                    "guestreview": {
                        "cleaniness": "4.1",
                        "staff_service": "4.1",
                        "amenities": "3.8",
                        "conditions_facilities": "4.0"
                    },
                    "rat": "4.0",
                    "urat": "4.0",
                    "expediareview": 931,
                    "pid": "2175146",
                    "line1": "8th Street Port Saeed District",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "City Centre Deira - 0.5 km / 0.3 mi "
                },
                "hdtype": "HOTELNHD",
                "usrst": "Very Good",
                "brdbs": [
                    "Breakfast"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 9378.41,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 677.33,
                "com_disc": 0,
                "rtf": "9378.00",
                "rdwtf": "9378.00"
            },
            {
                "tid": "calWmCV2HHW2if1s",
                "pid": "2564867",
                "hs": 0,
                "rmid": "387815",
                "rtid": "252177609",
                "refundable": false,
                "available_rooms": 7,
                "score": 456,
                "sno": "407",
                "cont": {
                    "_id": "600d4254a1bc371b80fe6c27",
                    "name": "Address Montgomerie",
                    "ratings": "5.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.067463,
                            "longitude": 55.164388
                        }
                    },
                    "externalId": "2564867",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/3000000/2570000/2564900/2564867/95ad40e1_b.jpg",
                    "ha": [
                        "BAR",
                        "POOL",
                        "POOL",
                        "BAR",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.8",
                        "staff_service": "4.7",
                        "amenities": "4.5",
                        "conditions_facilities": "4.7"
                    },
                    "rat": "5.0",
                    "urat": "4.7",
                    "expediareview": 67,
                    "pid": "2564867",
                    "line1": "Sheikh Zayed Road, 5th Interchange",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "American University of Dubai (AUD) - 3.7 km / 2.3 mi "
                },
                "hdtype": "HOTELNHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 9193.68,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 702.79,
                "com_disc": 0,
                "rtf": "9194.00",
                "rdwtf": "9194.00"
            },
            {
                "tid": "UEd7CDzbnN5OOKkT",
                "pid": "4588682",
                "hs": 0,
                "rmid": "200099757",
                "rtid": "228059671",
                "refundable": true,
                "available_rooms": 9,
                "score": 698,
                "sno": "405",
                "cont": {
                    "_id": "600d3fc1a1bc371b80fad981",
                    "name": "Hilton Dubai The Walk",
                    "ratings": "4.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.078572,
                            "longitude": 55.133885
                        }
                    },
                    "externalId": "4588682",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/5000000/4590000/4588700/4588682/22cd18e0_b.jpg",
                    "ha": [
                        "BAR",
                        "GYM",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.5",
                        "staff_service": "4.4",
                        "amenities": "4.3",
                        "conditions_facilities": "4.2"
                    },
                    "rat": "4.0",
                    "urat": "4.3",
                    "expediareview": 985,
                    "pid": "4588682",
                    "line1": "The Walk, Jumeirah Beach Residence",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "The Walk - 0.1 km / 0.1 mi "
                },
                "hdtype": "HOTELNHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 9132.93,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 691.29,
                "com_disc": 0,
                "rtf": "9133.00",
                "rdwtf": "9133.00"
            },
            {
                "tid": "OQkM5KtymwhPqmai",
                "pid": "32773397",
                "hs": 0,
                "rmid": "228906307",
                "rtid": "266286614",
                "refundable": true,
                "available_rooms": 2147483647,
                "score": 554,
                "sno": "403",
                "cont": {
                    "_id": "600d3faaa1bc371b80fabcc8",
                    "name": "Grand Plaza Mövenpick Media City",
                    "ratings": "5.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.097825,
                            "longitude": 55.165508
                        }
                    },
                    "externalId": "32773397",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/33000000/32780000/32773400/32773397/5bc5c817_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "5.0",
                        "staff_service": "4.9",
                        "amenities": "4.6",
                        "conditions_facilities": "4.8"
                    },
                    "rat": "5.0",
                    "urat": "4.9",
                    "expediareview": 29,
                    "pid": "32773397",
                    "line1": "Sheikh Zayed Road, Opp. Innovation Hub",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Dubai College - 1.9 km / 1.2 mi "
                },
                "hdtype": "HOTELNHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 8990.07,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 685.54,
                "com_disc": 0,
                "rtf": "8990.00",
                "rdwtf": "8990.00"
            },
            {
                "tid": "RibiouRR41UsskZK",
                "pid": "5399338",
                "hs": 0,
                "rmid": "200225354",
                "rtid": "201277287",
                "refundable": false,
                "available_rooms": 5,
                "score": 794,
                "sno": "401",
                "cont": {
                    "_id": "600d3e02a1bc371b80f869e2",
                    "name": "JW Marriott Marquis Hotel Dubai",
                    "ratings": "5.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.185311,
                            "longitude": 55.258521
                        }
                    },
                    "externalId": "5399338",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/6000000/5400000/5399400/5399338/7d93c312_b.jpg",
                    "ha": [
                        "POOL",
                        "POOL",
                        "BAR",
                        "POOL",
                        "POOL"
                    ],
                    "guestreview": {
                        "cleaniness": "4.7",
                        "staff_service": "4.7",
                        "amenities": "4.7",
                        "conditions_facilities": "4.7"
                    },
                    "rat": "5.0",
                    "urat": "4.7",
                    "expediareview": 985,
                    "pid": "5399338",
                    "line1": "Sheikh Zayed Road, Business Bay",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Dubai Water Canal - 0.9 km / 0.6 mi "
                },
                "hdtype": "HOTELNHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 8920.28,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 672.41,
                "com_disc": 0,
                "rtf": "8920.00",
                "rdwtf": "8920.00"
            },
            {
                "tid": "TCGDgGiTamBJe2QB",
                "pid": "791990",
                "hs": 0,
                "rmid": "315887699",
                "rtid": "384938542",
                "refundable": true,
                "available_rooms": 10,
                "score": 337,
                "sno": "395",
                "cont": {
                    "_id": "600d4268a1bc371b80fe8640",
                    "name": "Occidental Sharjah Grand",
                    "ratings": "4.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.338368,
                            "longitude": 55.36266
                        }
                    },
                    "externalId": "791990",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/1000000/800000/792000/791990/c0ab8a1c_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "5.0",
                        "staff_service": "5.0",
                        "amenities": 0,
                        "conditions_facilities": "5.0"
                    },
                    "rat": "4.0",
                    "urat": "5.0",
                    "expediareview": 1,
                    "pid": "791990",
                    "line1": "Al Meena Street",
                    "city": "Sharjah",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Sharjah Aquarium - 1 km / 0.6 mi "
                },
                "hdtype": "HOTELNHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Breakfast"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 8660.02,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 644.49,
                "com_disc": 0,
                "rtf": "8660.00",
                "rdwtf": "8660.00"
            },
            {
                "tid": "1H9IPOxUiucaNaDf",
                "pid": "4480234",
                "hs": 0,
                "rmid": "212711862",
                "rtid": "238216387",
                "refundable": true,
                "available_rooms": 5,
                "score": 580,
                "sno": "394",
                "cont": {
                    "_id": "600d403aa1bc371b80fb7a2d",
                    "name": "Delta hotels by Marriott Jumeirah Beach, Dubai",
                    "ratings": "4.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.077591,
                            "longitude": 55.136283
                        }
                    },
                    "externalId": "4480234",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/5000000/4490000/4480300/4480234/98153501_b.jpg",
                    "ha": [
                        "GYM",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.1",
                        "staff_service": "4.1",
                        "amenities": "4.1",
                        "conditions_facilities": "3.9"
                    },
                    "rat": "4.0",
                    "urat": "4.1",
                    "expediareview": 531,
                    "pid": "4480234",
                    "line1": "Bahar 7",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "The Walk - 0.4 km / 0.2 mi "
                },
                "hdtype": "HOTELNHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 8620.62,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 518.88,
                "com_disc": 0,
                "rtf": "8621.00",
                "rdwtf": "8621.00"
            },
            {
                "tid": "2VW32vGSHGahXM9W",
                "pid": "3840652",
                "hs": 0,
                "rmid": "212419386",
                "rtid": "236360737",
                "refundable": false,
                "available_rooms": 5,
                "score": 512,
                "sno": "387",
                "cont": {
                    "_id": "600d4168a1bc371b80fd0dd6",
                    "name": "Grand Stay Hotel Dubai",
                    "ratings": "4.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.209277,
                            "longitude": 55.274643
                        }
                    },
                    "externalId": "3840652",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/4000000/3850000/3840700/3840652/75c3ee72_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.0",
                        "staff_service": "3.7",
                        "amenities": "3.3",
                        "conditions_facilities": "3.7"
                    },
                    "rat": "4.0",
                    "urat": "3.7",
                    "expediareview": 640,
                    "pid": "3840652",
                    "line1": "Sheikh Zayed Road",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Dubai International Financial Centre - 0.9 km / 0.6 mi "
                },
                "hdtype": "HOTELNHD",
                "usrst": "Very Good",
                "brdbs": [
                    "Breakfast"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 8483.51,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 639.57,
                "com_disc": 0,
                "rtf": "8484.00",
                "rdwtf": "8484.00"
            },
            {
                "tid": "ECP5p5qBQUkmnONH",
                "pid": "21870",
                "hs": 0,
                "rmid": "314307244",
                "rtid": "382948520",
                "refundable": false,
                "available_rooms": 9,
                "score": 566,
                "sno": "384",
                "cont": {
                    "_id": "600d4052a1bc371b80fb9aa9",
                    "name": "Radisson Blu Hotel, Dubai Deira Creek",
                    "ratings": "5.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.26542,
                            "longitude": 55.31155
                        }
                    },
                    "externalId": "21870",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/1000000/30000/21900/21870/1e6b9824_b.jpg",
                    "ha": [
                        "BAR",
                        "POOL",
                        "POOL",
                        "BAR",
                        "POOL"
                    ],
                    "guestreview": {
                        "cleaniness": "4.5",
                        "staff_service": "4.5",
                        "amenities": "4.4",
                        "conditions_facilities": "4.3"
                    },
                    "rat": "5.0",
                    "urat": "4.4",
                    "expediareview": 993,
                    "pid": "21870",
                    "line1": "Bani Yas Road",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Deira Twin Towers Shopping Centre - 0.4 km / 0.3 mi "
                },
                "hdtype": "HOTELNHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 8462.98,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 648.6,
                "com_disc": 0,
                "rtf": "8463.00",
                "rdwtf": "8463.00"
            },
            {
                "tid": "GiFNvTBUTrgom2R6",
                "pid": "9509332",
                "hs": 0,
                "rmid": "200900501",
                "rtid": "204563198",
                "refundable": true,
                "available_rooms": 2147483647,
                "score": 534,
                "sno": "383",
                "cont": {
                    "_id": "600d3effa1bc371b80f9d20a",
                    "name": "Pullman Dubai Jumeirah Lakes Towers - Hotel and Residence",
                    "ratings": "5.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.080022,
                            "longitude": 55.150019
                        }
                    },
                    "externalId": "9509332",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/10000000/9510000/9509400/9509332/35e724f2_b.jpg",
                    "ha": [
                        "POOL",
                        "POOL",
                        "BAR",
                        "POOL",
                        "POOL"
                    ],
                    "guestreview": {
                        "cleaniness": "4.3",
                        "staff_service": "4.3",
                        "amenities": "4.3",
                        "conditions_facilities": "4.3"
                    },
                    "rat": "5.0",
                    "urat": "4.3",
                    "expediareview": 390,
                    "pid": "9509332",
                    "line1": "Jumeirah Lakes Towers Cluster T",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Dubai Marina Mall - 1.7 km / 1 mi "
                },
                "hdtype": "HOTELNHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 8453.95,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 645.31,
                "com_disc": 0,
                "rtf": "8454.00",
                "rdwtf": "8454.00"
            },
            {
                "tid": "WM381qc1vMTOfNs7",
                "pid": "536502",
                "hs": 0,
                "rmid": "212404932",
                "rtid": "236187262",
                "refundable": true,
                "available_rooms": 5,
                "score": 629,
                "sno": "382",
                "cont": {
                    "_id": "600d4355a1bc371b80ffb406",
                    "name": "Four Points by Sheraton Sheikh Zayed Road, Dubai",
                    "ratings": "4.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.213592,
                            "longitude": 55.276007
                        }
                    },
                    "externalId": "536502",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/1000000/540000/536600/536502/41ad17e4_b.jpg",
                    "ha": [
                        "POOL",
                        "POOL",
                        "BAR",
                        "POOL",
                        "POOL"
                    ],
                    "guestreview": {
                        "cleaniness": "4.7",
                        "staff_service": "4.6",
                        "amenities": "4.4",
                        "conditions_facilities": "4.5"
                    },
                    "rat": "4.0",
                    "urat": "4.5",
                    "expediareview": 986,
                    "pid": "536502",
                    "line1": "Sheikh Zayed Road",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Dubai International Financial Centre - 1 km / 0.6 mi "
                },
                "hdtype": "HOTELNHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 8444.1,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 585.38,
                "com_disc": 0,
                "rtf": "8444.00",
                "rdwtf": "8444.00"
            },
            {
                "tid": "Wqx3EC75ukC6abnU",
                "pid": "64178013",
                "hs": 0,
                "rmid": "231650037",
                "rtid": "284321336",
                "refundable": true,
                "available_rooms": 5,
                "score": 171,
                "sno": "377",
                "cont": {
                    "_id": "62506c082aee3e1bb50b6b18",
                    "ratings": "0.0",
                    "name": "Residence Inn by Marriott Al Jaddaf",
                    "location": {
                        "coordinates": {
                            "latitude": 25.210751,
                            "longitude": 55.31414
                        },
                        "obfuscatedCoordinates": {
                            "latitude": 25.24379,
                            "longitude": 55.312798
                        },
                        "obfuscationRequired": false
                    },
                    "externalId": "64178013",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/65000000/64180000/64178100/64178013/6f01d189_b.jpg",
                    "ha": [
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.9",
                        "staff_service": "4.4",
                        "amenities": "4.5",
                        "conditions_facilities": "4.6"
                    },
                    "rat": "0.0",
                    "urat": "4.6",
                    "expediareview": 9,
                    "pid": "64178013",
                    "line1": "Oud Metha Road, Al Jaddaf Area",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Sheikh Zayed Road - 3.3 km / 2.1 mi "
                },
                "hdtype": "HOTELNHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Breakfast"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 8278.25,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 497.53,
                "com_disc": 0,
                "rtf": "8278.00",
                "rdwtf": "8278.00"
            },
            {
                "tid": "MJ2mLekhQbtpoWvl",
                "pid": "3990073",
                "hs": 0,
                "rmid": "315928269",
                "rtid": "385088457",
                "refundable": false,
                "available_rooms": 2,
                "score": 669,
                "sno": "378",
                "cont": {
                    "_id": "600d3df6a1bc371b80f85c7d",
                    "name": "voco Dubai - an IHG Hotel",
                    "ratings": "5.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.223325,
                            "longitude": 55.282699
                        }
                    },
                    "externalId": "3990073",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/4000000/4000000/3990100/3990073/ff2cbb05_b.jpg",
                    "ha": [
                        "BAR",
                        "GYM",
                        "POOL",
                        "POOL",
                        "BAR"
                    ],
                    "guestreview": {
                        "cleaniness": "4.5",
                        "staff_service": "4.4",
                        "amenities": "4.4",
                        "conditions_facilities": "4.4"
                    },
                    "rat": "5.0",
                    "urat": "4.4",
                    "expediareview": 766,
                    "pid": "3990073",
                    "line1": "Plot 49 Sheikh Zayed Road",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Dubai International Financial Centre - 1.3 km / 0.8 mi "
                },
                "hdtype": "HOTELNHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 8278.25,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 623.15,
                "com_disc": 0,
                "rtf": "8278.00",
                "rdwtf": "8278.00"
            },
            {
                "tid": "5XCyrrh2rr0eAJt5",
                "pid": "1234599",
                "hs": 0,
                "rmid": "225609109",
                "rtid": "244410898",
                "refundable": true,
                "available_rooms": 2147483647,
                "score": 703,
                "sno": "376",
                "cont": {
                    "_id": "600d435aa1bc371b80ffb9a1",
                    "name": "Swissôtel Al Murooj Dubai",
                    "ratings": "5.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.20284,
                            "longitude": 55.278153
                        }
                    },
                    "externalId": "1234599",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/2000000/1240000/1234600/1234599/b5724c08_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "POOL",
                        "BAR",
                        "POOL"
                    ],
                    "guestreview": {
                        "cleaniness": "4.5",
                        "staff_service": "4.5",
                        "amenities": "4.3",
                        "conditions_facilities": "4.3"
                    },
                    "rat": "5.0",
                    "urat": "4.4",
                    "expediareview": 990,
                    "pid": "1234599",
                    "line1": "Al Mustaqbal Steet",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Dubai Mall - 0.6 km / 0.4 mi "
                },
                "hdtype": "HOTELNHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 8187.12,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 591.13,
                "com_disc": 0,
                "rtf": "8187.00",
                "rdwtf": "8187.00"
            },
            {
                "tid": "hWNAnvUrDLldARsG",
                "pid": "71681447",
                "hs": 0,
                "rmid": "315657715",
                "rtid": "384227508",
                "refundable": true,
                "available_rooms": 2,
                "score": 379,
                "sno": "362",
                "cont": {
                    "_id": "62506a3c2aee3e1bb50b6627",
                    "ratings": "0.0",
                    "name": "Courtyard by Marriott World Trade Centre, Dubai",
                    "location": {
                        "coordinates": {
                            "latitude": 25.228253,
                            "longitude": 55.283416
                        },
                        "obfuscationRequired": false
                    },
                    "externalId": "71681447",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/72000000/71690000/71681500/71681447/87d21b27_b.jpg",
                    "ha": [
                        "WIFI",
                        "GYM"
                    ],
                    "guestreview": {
                        "cleaniness": "4.9",
                        "staff_service": "4.8",
                        "amenities": "5.0",
                        "conditions_facilities": "4.9"
                    },
                    "rat": "0.0",
                    "urat": "4.4",
                    "expediareview": 10,
                    "pid": "71681447",
                    "line1": "Al Hassabi Street, Jumeirah Garden City",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Sheikh Zayed Road - 0.1 km / 0.1 mi "
                },
                "hdtype": "HOTELNHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 7796.32,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 541.05,
                "com_disc": 0,
                "rtf": "7796.00",
                "rdwtf": "7796.00"
            },
            {
                "tid": "FEk7QcanJVHJmWBO",
                "pid": "4595744",
                "hs": 0,
                "rmid": "212416851",
                "rtid": "236290289",
                "refundable": true,
                "available_rooms": 2147483647,
                "score": 442,
                "sno": "358",
                "cont": {
                    "_id": "600d3ebba1bc371b80f97e37",
                    "name": "Swissotel Al Ghurair",
                    "ratings": "5.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.26926,
                            "longitude": 55.31705
                        }
                    },
                    "externalId": "4595744",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/5000000/4600000/4595800/4595744/2f09769b_b.jpg",
                    "ha": [
                        "BAR",
                        "GYM",
                        "POOL",
                        "POOL"
                    ],
                    "guestreview": {
                        "cleaniness": "4.6",
                        "staff_service": "4.5",
                        "amenities": "4.2",
                        "conditions_facilities": "4.6"
                    },
                    "rat": "5.0",
                    "urat": "4.5",
                    "expediareview": 946,
                    "pid": "4595744",
                    "line1": "Omar Bin Al Khattab Street",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Fish Roundabout - 0.2 km / 0.1 mi "
                },
                "hdtype": "HOTELNHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 7782.36,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 593.59,
                "com_disc": 0,
                "rtf": "7782.00",
                "rdwtf": "7782.00"
            },
            {
                "tid": "G92gwOVp3cBrtKcW",
                "pid": "28827007",
                "hs": 0,
                "rmid": "215718390",
                "rtid": "383858063",
                "refundable": true,
                "available_rooms": 72,
                "score": 485,
                "sno": "359",
                "cont": {
                    "_id": "600d3dbfa1bc371b80f81fec",
                    "name": "Zabeel House by Jumeirah, The Greens",
                    "ratings": "4.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.097066,
                            "longitude": 55.168214
                        }
                    },
                    "externalId": "28827007",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/29000000/28830000/28827100/28827007/99019618_b.jpg",
                    "ha": [
                        "BAR",
                        "GYM",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.8",
                        "staff_service": "4.7",
                        "amenities": "4.6",
                        "conditions_facilities": "4.8"
                    },
                    "rat": "4.0",
                    "urat": "4.7",
                    "expediareview": 124,
                    "pid": "28827007",
                    "line1": "Sheikh Zayed Road, The Greens",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Jebel Ali Race Course - 1.2 km / 0.8 mi "
                },
                "hdtype": "HOTELNHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 7782.36,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 593.59,
                "com_disc": 0,
                "rtf": "7782.00",
                "rdwtf": "7782.00"
            },
            {
                "tid": "rkdNwW9aVZxLXOC2",
                "pid": "4557255",
                "hs": 0,
                "rmid": "200230438",
                "rtid": "382323006",
                "refundable": true,
                "available_rooms": 115,
                "score": 636,
                "sno": "360",
                "cont": {
                    "_id": "600d4395a1bc371b800002e4",
                    "name": "Jumeirah Creekside Hotel",
                    "ratings": "5.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.243,
                            "longitude": 55.3398
                        }
                    },
                    "externalId": "4557255",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/5000000/4560000/4557300/4557255/d8091d65_b.jpg",
                    "ha": [
                        "BAR",
                        "GYM",
                        "POOL",
                        "BAR",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.7",
                        "staff_service": "4.6",
                        "amenities": "4.5",
                        "conditions_facilities": "4.6"
                    },
                    "rat": "5.0",
                    "urat": "4.6",
                    "expediareview": 988,
                    "pid": "4557255",
                    "line1": "Road Al Garhoud Deira",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Dubai Tennis Stadium - 0.7 km / 0.4 mi "
                },
                "hdtype": "HOTELNHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 7782.36,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 593.59,
                "com_disc": 0,
                "rtf": "7782.00",
                "rdwtf": "7782.00"
            },
            {
                "tid": "3ZH5LkhzLcTzsBIv",
                "pid": "23055261",
                "hs": 0,
                "rmid": "215127685",
                "rtid": "254228199",
                "refundable": true,
                "available_rooms": 5,
                "score": 406,
                "sno": "352",
                "cont": {
                    "_id": "600d43bda1bc371b800037b0",
                    "name": "Aloft City Centre Deira, Dubai",
                    "ratings": "4.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.250729,
                            "longitude": 55.332186
                        }
                    },
                    "externalId": "23055261",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/24000000/23060000/23055300/23055261/257535de_b.jpg",
                    "ha": [
                        "BAR",
                        "GYM",
                        "POOL",
                        "POOL"
                    ],
                    "guestreview": {
                        "cleaniness": "4.6",
                        "staff_service": "4.5",
                        "amenities": "4.4",
                        "conditions_facilities": "4.6"
                    },
                    "rat": "4.0",
                    "urat": "4.5",
                    "expediareview": 106,
                    "pid": "23055261",
                    "line1": "Baniyas Road, Deira",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "City Centre Deira - 0.1 km / 0.1 mi "
                },
                "hdtype": "HOTELNHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 7616.52,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 458.12,
                "com_disc": 0,
                "rtf": "7617.00",
                "rdwtf": "7617.00"
            },
            {
                "tid": "xL3xP6yiJiOrIa2P",
                "pid": "19625773",
                "hs": 0,
                "rmid": "315742749",
                "rtid": "384431962",
                "refundable": false,
                "available_rooms": 4,
                "score": 466,
                "sno": "351",
                "cont": {
                    "_id": "600d3fb2a1bc371b80fac6bd",
                    "name": "Crowne Plaza Dubai Marina",
                    "ratings": "0.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.075142,
                            "longitude": 55.139161
                        }
                    },
                    "externalId": "19625773",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/20000000/19630000/19625800/19625773/575e416f_b.jpg",
                    "ha": [
                        "POOL",
                        "POOL",
                        "BAR",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.7",
                        "staff_service": "4.6",
                        "amenities": "3.9",
                        "conditions_facilities": "4.5"
                    },
                    "rat": "0.0",
                    "urat": "4.4",
                    "expediareview": 19,
                    "pid": "19625773",
                    "line1": "Al Yahoom Street, Dubai Marina",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Dubai Marina Mall - 0.5 km / 0.3 mi "
                },
                "hdtype": "HOTELNHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 7595.17,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 564.03,
                "com_disc": 0,
                "rtf": "7595.00",
                "rdwtf": "7595.00"
            },
            {
                "tid": "WKJyKkzoDoIChcEM",
                "pid": "21766364",
                "hs": 0,
                "rmid": "220955515",
                "rtid": "292301707",
                "refundable": true,
                "available_rooms": 2,
                "score": 372,
                "sno": "344",
                "cont": {
                    "_id": "600d4152a1bc371b80fcf7d6",
                    "name": "Canopy by Hilton Dubai Al Seef",
                    "ratings": "3.5",
                    "location": {
                        "coordinates": {
                            "latitude": 25.25632,
                            "longitude": 55.311724
                        }
                    },
                    "externalId": "21766364",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/22000000/21770000/21766400/21766364/126a6012_b.jpg",
                    "ha": [
                        "BAR",
                        "POOL",
                        "POOL",
                        "BAR",
                        "POOL"
                    ],
                    "guestreview": {
                        "cleaniness": "4.6",
                        "staff_service": "4.2",
                        "amenities": "4.6",
                        "conditions_facilities": "4.5"
                    },
                    "rat": "3.5",
                    "urat": "4.4",
                    "expediareview": 45,
                    "pid": "21766364",
                    "line1": "Al Seef Street Building A",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Al Seef - 0.3 km / 0.2 mi "
                },
                "hdtype": "HOTELNHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 7416.19,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 561.57,
                "com_disc": 0,
                "rtf": "7416.00",
                "rdwtf": "7416.00"
            },
            {
                "tid": "2u1BK2MgtLVPMWMX",
                "pid": "41976524",
                "hs": 0,
                "rmid": "216659541",
                "rtid": "264245288",
                "refundable": true,
                "available_rooms": 17,
                "score": 446,
                "sno": "342",
                "cont": {
                    "_id": "600d403aa1bc371b80fb7b45",
                    "name": "Taj Jumeirah Lakes Towers",
                    "ratings": "0.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.08057,
                            "longitude": 55.153843
                        }
                    },
                    "externalId": "41976524",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/42000000/41980000/41976600/41976524/8975338e_b.jpg",
                    "ha": [
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.8",
                        "staff_service": "4.5",
                        "amenities": "4.4",
                        "conditions_facilities": "4.6"
                    },
                    "rat": "0.0",
                    "urat": "4.4",
                    "expediareview": 21,
                    "pid": "41976524",
                    "line1": "DMCC Plot No 2-27",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Dubai Marina Mall - 2.1 km / 1.3 mi "
                },
                "hdtype": "HOTELNHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 7368.57,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 564.86,
                "com_disc": 0,
                "rtf": "7369.00",
                "rdwtf": "7369.00"
            },
            {
                "tid": "4Q7lXNKeXbyWynQh",
                "pid": "8797323",
                "hs": 0,
                "rmid": "217509635",
                "rtid": "270056127",
                "refundable": false,
                "available_rooms": 5,
                "score": 719,
                "sno": "341",
                "cont": {
                    "_id": "600d3f8ca1bc371b80fa9dc4",
                    "name": "Sheraton Grand Hotel, Dubai",
                    "ratings": "5.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.229362,
                            "longitude": 55.28684
                        }
                    },
                    "externalId": "8797323",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/9000000/8800000/8797400/8797323/e4ec6bd2_b.jpg",
                    "ha": [
                        "POOL",
                        "POOL",
                        "BAR",
                        "POOL",
                        "POOL"
                    ],
                    "guestreview": {
                        "cleaniness": "4.5",
                        "staff_service": "4.6",
                        "amenities": "4.5",
                        "conditions_facilities": "4.5"
                    },
                    "rat": "5.0",
                    "urat": "4.5",
                    "expediareview": 1225,
                    "pid": "8797323",
                    "line1": "3 Sheikh Zayed Road",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Dubai International Convention and Exhibition Centre - 0.9 km / 0.6 mi "
                },
                "hdtype": "HOTELNHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 7336.55,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 552.54,
                "com_disc": 0,
                "rtf": "7337.00",
                "rdwtf": "7337.00"
            },
            {
                "tid": "T8WmJ2GshJ6Dpn1a",
                "pid": "70260071",
                "hs": 0,
                "rmid": "315453319",
                "rtid": "384380915",
                "refundable": true,
                "available_rooms": 1,
                "score": 171,
                "sno": "333",
                "cont": {
                    "_id": "62506ad82aee3e1bb50b66ff",
                    "ratings": "4.0",
                    "name": "DoubleTree by Hilton Sharjah Waterfront Hotel & Residences",
                    "location": {
                        "coordinates": {
                            "latitude": 25.323094,
                            "longitude": 55.387978
                        },
                        "obfuscationRequired": false
                    },
                    "externalId": "70260071",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/71000000/70270000/70260100/70260071/7e02638d_b.jpg",
                    "ha": [
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "5.0",
                        "staff_service": "5.0",
                        "amenities": "5.0",
                        "conditions_facilities": "5.0"
                    },
                    "rat": "4.0",
                    "urat": "5.0",
                    "expediareview": 1,
                    "pid": "70260071",
                    "line1": "Plot 393, Jamal Abdul Nasser Street",
                    "city": "Sharjah",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Al Qasba - 1.6 km / 1 mi "
                },
                "hdtype": "HOTELNHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Breakfast"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 7067.26,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 521.34,
                "com_disc": 0,
                "rtf": "7067.00",
                "rdwtf": "7067.00"
            },
            {
                "tid": "oEZoQTNCo1oiu27U",
                "pid": "33298316",
                "hs": 0,
                "rmid": "314042114",
                "rtid": "381281005",
                "refundable": false,
                "available_rooms": 1,
                "score": 435,
                "sno": "325",
                "cont": {
                    "_id": "600d4306a1bc371b80ff6225",
                    "name": "Hotel Indigo Dubai Downtown",
                    "location": {
                        "coordinates": {
                            "latitude": 25.19238,
                            "longitude": 55.287046
                        }
                    },
                    "externalId": "33298316",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/34000000/33300000/33298400/33298316/e4ab8104_b.jpg",
                    "ha": [
                        "POOL",
                        "POOL",
                        "BAR",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": 0,
                        "staff_service": 0,
                        "amenities": 0,
                        "conditions_facilities": 0
                    },
                    "rat": "",
                    "urat": "",
                    "expediareview": 0,
                    "ratings": "",
                    "pid": "33298316",
                    "line1": "Marasi Drive, PO Box 24666",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Dubai Water Canal - 0.7 km / 0.4 mi "
                },
                "hdtype": "HOTELNHD",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 6878.43,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 518.06,
                "com_disc": 0,
                "rtf": "6878.00",
                "rdwtf": "6878.00"
            },
            {
                "tid": "TUAOcWIn2wLtM3tn",
                "pid": "2829306",
                "hs": 0,
                "rmid": "315844154",
                "rtid": "384810907",
                "refundable": true,
                "available_rooms": 4,
                "score": 267,
                "sno": "321",
                "cont": {
                    "_id": "600d43afa1bc371b80002564",
                    "name": "Arabian Ranches Golf Club",
                    "ratings": "2.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.051534,
                            "longitude": 55.268671
                        }
                    },
                    "externalId": "2829306",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/3000000/2830000/2829400/2829306/1474b8f2_b.jpg",
                    "ha": [
                        "BAR",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.5",
                        "staff_service": "4.2",
                        "amenities": "4.0",
                        "conditions_facilities": "4.2"
                    },
                    "rat": "2.0",
                    "urat": "4.3",
                    "expediareview": 34,
                    "pid": "2829306",
                    "line1": "Arabian Ranches Emirates Road PO Box 367",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Arabian Ranches Golf Club - 1.8 km / 1.1 mi "
                },
                "hdtype": "HOTELNHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 6776.62,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 518.06,
                "com_disc": 0,
                "rtf": "6777.00",
                "rdwtf": "6777.00"
            },
            {
                "tid": "fapJlQ0T0AieEKO4",
                "pid": "6975243",
                "hs": 0,
                "rmid": "200553954",
                "rtid": "202970321",
                "refundable": false,
                "available_rooms": 5,
                "score": 548,
                "sno": "311",
                "cont": {
                    "_id": "600d4345a1bc371b80ffa2f1",
                    "name": "Marriott Hotel Al Jaddaf, Dubai",
                    "ratings": "5.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.211999,
                            "longitude": 55.315605
                        }
                    },
                    "externalId": "6975243",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/7000000/6980000/6975300/6975243/38b817bc_b.jpg",
                    "ha": [
                        "BAR",
                        "GYM",
                        "POOL",
                        "POOL",
                        "BAR"
                    ],
                    "guestreview": {
                        "cleaniness": "4.6",
                        "staff_service": "4.6",
                        "amenities": "4.2",
                        "conditions_facilities": "4.5"
                    },
                    "rat": "5.0",
                    "urat": "4.5",
                    "expediareview": 442,
                    "pid": "6975243",
                    "line1": "Oud Metha Road, Al Jaddaf Area",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "American Hospital Dubai - 3 km / 1.8 mi "
                },
                "hdtype": "HOTELNHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 6623.1,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 498.35,
                "com_disc": 0,
                "rtf": "6623.00",
                "rdwtf": "6623.00"
            },
            {
                "tid": "k9Wkr8hisbP3dkqn",
                "pid": "74422003",
                "hs": 0,
                "rmid": "316004706",
                "rtid": "385231786",
                "refundable": false,
                "available_rooms": 2147483647,
                "score": 86,
                "sno": "308",
                "cont": {
                    "_id": "62506a6c2aee3e1bb50b6669",
                    "ratings": "0.0",
                    "name": "Adagio Jumeirah Village Triangle",
                    "location": {
                        "coordinates": {
                            "latitude": 25.041413,
                            "longitude": 55.187361
                        },
                        "obfuscationRequired": false
                    },
                    "externalId": "74422003",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/75000000/74430000/74422100/74422003/321189ed_b.jpg",
                    "ha": [
                        "GYM",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": 0,
                        "staff_service": 0,
                        "amenities": 0,
                        "conditions_facilities": 0
                    },
                    "rat": "0.0",
                    "urat": "",
                    "expediareview": 0,
                    "pid": "74422003",
                    "line1": "B1 Joory Street District 3",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Jebel Ali Race Course - 8 km / 4.9 mi "
                },
                "hdtype": "HOTELNHD",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 6570.55,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 562.39,
                "com_disc": 0,
                "rtf": "6571.00",
                "rdwtf": "6571.00"
            },
            {
                "tid": "0SCVWvVLEElgy1DE",
                "pid": "6501513",
                "hs": 0,
                "rmid": "214024968",
                "rtid": "246227042",
                "refundable": true,
                "available_rooms": 2147483647,
                "score": 715,
                "sno": "307",
                "cont": {
                    "_id": "600d4397a1bc371b80000567",
                    "name": "Movenpick Hotel Jumeirah Lakes Towers",
                    "ratings": "5.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.066015,
                            "longitude": 55.137978
                        }
                    },
                    "externalId": "6501513",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/7000000/6510000/6501600/6501513/9d0538f3_b.jpg",
                    "ha": [
                        "BAR",
                        "GYM",
                        "POOL",
                        "POOL"
                    ],
                    "guestreview": {
                        "cleaniness": "4.4",
                        "staff_service": "4.4",
                        "amenities": "4.2",
                        "conditions_facilities": "4.1"
                    },
                    "rat": "5.0",
                    "urat": "4.1",
                    "expediareview": 978,
                    "pid": "6501513",
                    "line1": "Jumeirah Lakes Towers, Cluster A",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Dubai Marina Mall - 1.8 km / 1.1 mi "
                },
                "hdtype": "HOTELNHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 6522.11,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 498.35,
                "com_disc": 0,
                "rtf": "6522.00",
                "rdwtf": "6522.00"
            },
            {
                "tid": "39dDto7v1A3MA2oM",
                "pid": "68498007",
                "hs": 0,
                "rmid": "314311825",
                "rtid": "382973215",
                "refundable": false,
                "available_rooms": 4,
                "score": 242,
                "sno": "306",
                "cont": {
                    "_id": "62506c652aee3e1bb50b6c11",
                    "ratings": "",
                    "name": "Crowne Plaza Dubai Jumeirah, an IHG Hotel",
                    "location": {
                        "coordinates": {
                            "latitude": 25.240618,
                            "longitude": 55.275475
                        },
                        "obfuscatedCoordinates": {
                            "latitude": 25.243539,
                            "longitude": 55.274435
                        },
                        "obfuscationRequired": false
                    },
                    "externalId": "68498007",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/69000000/68500000/68498100/68498007/ee674ddc_b.jpg",
                    "ha": [
                        "GYM",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.4",
                        "staff_service": "4.1",
                        "amenities": "3.0",
                        "conditions_facilities": "4.1"
                    },
                    "rat": "",
                    "urat": "4.2",
                    "expediareview": 11,
                    "pid": "68498007",
                    "line1": "Al Mina Road",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Etihad Museum - 0.9 km / 0.5 mi "
                },
                "hdtype": "HOTELNHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 6492.55,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 486.86,
                "com_disc": 0,
                "rtf": "6493.00",
                "rdwtf": "6493.00"
            },
            {
                "tid": "8R4EOvflVEFcklHL",
                "pid": "26794669",
                "hs": 0,
                "rmid": "320815009",
                "rtid": "387367876",
                "refundable": false,
                "available_rooms": 10,
                "score": 0,
                "sno": "300",
                "cont": {
                    "_id": "600d3db2a1bc371b80f80ece",
                    "name": "Tulip in al Khan",
                    "ratings": "4.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.31775,
                            "longitude": 55.38103
                        }
                    },
                    "externalId": "26794669",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/27000000/26800000/26794700/26794669/8d5804d2_b.jpg",
                    "ha": [
                        "GYM",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.8",
                        "staff_service": "4.8",
                        "amenities": "4.6",
                        "conditions_facilities": "4.8"
                    },
                    "rat": "4.0",
                    "urat": "4.8",
                    "expediareview": 10,
                    "pid": "26794669",
                    "line1": "SHARJAH, ABDUL SALAM",
                    "city": "Sharjah",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Al Majaz Park - 1.4 km / 0.8 mi "
                },
                "hdtype": "HOTELNHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 6375.15,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 519.7,
                "com_disc": 0,
                "rtf": "6375.00",
                "rdwtf": "6375.00"
            },
            {
                "tid": "rde3Fvq33WST1gRS",
                "pid": "4018144",
                "hs": 0,
                "rmid": "201808102",
                "rtid": "384260056",
                "refundable": false,
                "available_rooms": 35,
                "score": 496,
                "sno": "289",
                "cont": {
                    "_id": "600d3f59a1bc371b80fa5c2a",
                    "name": "Park Regis Kris Kin Hotel Dubai",
                    "ratings": "5.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.249618,
                            "longitude": 55.301516
                        }
                    },
                    "externalId": "4018144",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/5000000/4020000/4018200/4018144/a223adbf_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.5",
                        "staff_service": "4.3",
                        "amenities": "4.5",
                        "conditions_facilities": "4.2"
                    },
                    "rat": "5.0",
                    "urat": "4.2",
                    "expediareview": 988,
                    "pid": "4018144",
                    "line1": "Sheikh Khalifah Bin Zayed Street",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "BurJuman Mall - 0.4 km / 0.3 mi "
                },
                "hdtype": "HOTELNHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 6183.03,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 471.26,
                "com_disc": 0,
                "rtf": "6183.00",
                "rdwtf": "6183.00"
            },
            {
                "tid": "yuzi5rV17iWZV8Ny",
                "pid": "31718649",
                "hs": 0,
                "rmid": "215424577",
                "rtid": "256193623",
                "refundable": true,
                "available_rooms": 5,
                "score": 381,
                "sno": "287",
                "cont": {
                    "_id": "600d3f71a1bc371b80fa7dd7",
                    "name": "Courtyard by Marriott Al Barsha, Dubai",
                    "ratings": "4.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.11437,
                            "longitude": 55.197438
                        }
                    },
                    "externalId": "31718649",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/32000000/31720000/31718700/31718649/303e1f1b_b.jpg",
                    "ha": [
                        "POOL",
                        "POOL",
                        "BAR",
                        "POOL",
                        "POOL"
                    ],
                    "guestreview": {
                        "cleaniness": "4.5",
                        "staff_service": "4.3",
                        "amenities": "4.2",
                        "conditions_facilities": "4.5"
                    },
                    "rat": "4.0",
                    "urat": "4.1",
                    "expediareview": 47,
                    "pid": "31718649",
                    "line1": "Al Barsha Road, Behind Mall of Emirates",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Fatima Abdullah Mohammed Rasheed Mosque - 0.8 km / 0.5 mi "
                },
                "hdtype": "HOTELNHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 6114.07,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 367.81,
                "com_disc": 0,
                "rtf": "6114.00",
                "rdwtf": "6114.00"
            },
            {
                "tid": "TUntEsVU9rvLPTEf",
                "pid": "59493778",
                "hs": 0,
                "rmid": "228351369",
                "rtid": "262375157",
                "refundable": true,
                "available_rooms": 9,
                "score": 325,
                "sno": "285",
                "cont": {
                    "_id": "600d3ea0a1bc371b80f95e4c",
                    "name": "DoubleTree by Hilton Dubai M Square Hotel & Residences",
                    "location": {
                        "coordinates": {
                            "latitude": 25.250293,
                            "longitude": 55.300884
                        }
                    },
                    "externalId": "59493778",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/60000000/59500000/59493800/59493778/20e795dc_b.jpg",
                    "ha": [
                        "GYM",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": 0,
                        "staff_service": 0,
                        "amenities": 0,
                        "conditions_facilities": 0
                    },
                    "rat": "",
                    "urat": "",
                    "expediareview": 0,
                    "ratings": "",
                    "pid": "59493778",
                    "line1": "M Square Building, Al Mankhool",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "BurJuman Mall - 0.4 km / 0.3 mi "
                },
                "hdtype": "HOTELNHD",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 6049.21,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 458.12,
                "com_disc": 0,
                "rtf": "6049.00",
                "rdwtf": "6049.00"
            },
            {
                "tid": "3xN93LCv1OWMMAFi",
                "pid": "517439",
                "hs": 0,
                "rmid": "202339151",
                "rtid": "381093631",
                "refundable": false,
                "available_rooms": 2,
                "score": 459,
                "sno": "284",
                "cont": {
                    "_id": "600d3ddaa1bc371b80f83b5b",
                    "name": "Crowne Plaza Dubai Deira",
                    "ratings": "5.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.270448,
                            "longitude": 55.326984
                        }
                    },
                    "externalId": "517439",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/1000000/520000/517500/517439/c5bcf52b_b.jpg",
                    "ha": [
                        "POOL",
                        "POOL",
                        "BAR",
                        "POOL",
                        "POOL"
                    ],
                    "guestreview": {
                        "cleaniness": "4.5",
                        "staff_service": "4.4",
                        "amenities": "4.1",
                        "conditions_facilities": "4.2"
                    },
                    "rat": "5.0",
                    "urat": "4.3",
                    "expediareview": 592,
                    "pid": "517439",
                    "line1": "Salahuddin Road",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Reef Mall - 0.7 km / 0.4 mi "
                },
                "hdtype": "HOTELNHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 6048.39,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 451.56,
                "com_disc": 0,
                "rtf": "6048.00",
                "rdwtf": "6048.00"
            },
            {
                "tid": "M4lwfSTenWnm26eu",
                "pid": "23474875",
                "hs": 0,
                "rmid": "220507809",
                "rtid": "290027215",
                "refundable": false,
                "available_rooms": 1,
                "score": 362,
                "sno": "283",
                "cont": {
                    "_id": "600d3ef2a1bc371b80f9c24e",
                    "name": "VERSAILLES BY RAVIZ",
                    "ratings": "4.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.26321,
                            "longitude": 55.3204
                        }
                    },
                    "externalId": "23474875",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/24000000/23480000/23474900/23474875/628fddf2_b.jpg",
                    "ha": [
                        "BAR",
                        "GYM",
                        "POOL",
                        "POOL",
                        "BAR"
                    ],
                    "guestreview": {
                        "cleaniness": "4.4",
                        "staff_service": "4.2",
                        "amenities": "4.2",
                        "conditions_facilities": "4.2"
                    },
                    "rat": "4.0",
                    "urat": "4.1",
                    "expediareview": 74,
                    "pid": "23474875",
                    "line1": "15TH STREET,AL RIGGA,DEIRA",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Al Ghurair Centre - 0.7 km / 0.5 mi "
                },
                "hdtype": "HOTELNHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 6039.36,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 461.41,
                "com_disc": 0,
                "rtf": "6039.00",
                "rdwtf": "6039.00"
            },
            {
                "tid": "JdpcrT9TTZJpBbpC",
                "pid": "39886064",
                "hs": 0,
                "rmid": "315886510",
                "rtid": "384932026",
                "refundable": true,
                "available_rooms": 72,
                "score": 254,
                "sno": "281",
                "cont": {
                    "_id": "600d43afa1bc371b80002507",
                    "name": "Occidental Al Jaddaf",
                    "ratings": "0.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.217876,
                            "longitude": 55.32252
                        }
                    },
                    "externalId": "39886064",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/40000000/39890000/39886100/39886064/64b27509_b.jpg",
                    "ha": [
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": 0,
                        "staff_service": 0,
                        "amenities": 0,
                        "conditions_facilities": 0
                    },
                    "rat": "0.0",
                    "urat": "",
                    "expediareview": 0,
                    "pid": "39886064",
                    "line1": "A326 Al Khail Rd.",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Wafi City Mall - 2.6 km / 1.6 mi "
                },
                "hdtype": "HOTELNHD",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 6035.25,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 463.05,
                "com_disc": 0,
                "rtf": "6035.00",
                "rdwtf": "6035.00"
            },
            {
                "tid": "q5TlLk1hpBFuAHDC",
                "pid": "21865124",
                "hs": 0,
                "rmid": "219334664",
                "rtid": "282151046",
                "refundable": false,
                "available_rooms": 2,
                "score": 272,
                "sno": "270",
                "cont": {
                    "_id": "600d414aa1bc371b80fcefef",
                    "name": "Element Me'aisam, Dubai",
                    "ratings": "0.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.035662,
                            "longitude": 55.199882
                        }
                    },
                    "externalId": "21865124",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/22000000/21870000/21865200/21865124/13df2d51_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.9",
                        "staff_service": "4.8",
                        "amenities": "4.8",
                        "conditions_facilities": "4.9"
                    },
                    "rat": "0.0",
                    "urat": "4.6",
                    "expediareview": 14,
                    "pid": "21865124",
                    "line1": "Dubai Production City",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Dubai Sports City - 3.1 km / 1.9 mi "
                },
                "hdtype": "HOTELNHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Breakfast"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 5832.46,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 438.42,
                "com_disc": 0,
                "rtf": "5832.00",
                "rdwtf": "5832.00"
            },
            {
                "tid": "aU3nFQ7dTXvSqZDa",
                "pid": "61208075",
                "hs": 0,
                "rmid": "227755869",
                "rtid": "381202709",
                "refundable": true,
                "available_rooms": 2,
                "score": 143,
                "sno": "269",
                "cont": {
                    "_id": "62506a932aee3e1bb50b66a0",
                    "ratings": "0.0",
                    "name": "TIME Onyx Hotel Apartment",
                    "location": {
                        "coordinates": {
                            "latitude": 25.270662,
                            "longitude": 55.395735
                        },
                        "obfuscationRequired": false
                    },
                    "externalId": "61208075",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/62000000/61210000/61208100/61208075/482576ec_b.jpg",
                    "ha": [
                        "POOL",
                        "WIFI",
                        "GYM"
                    ],
                    "guestreview": {
                        "cleaniness": "4.0",
                        "staff_service": "4.3",
                        "amenities": "4.5",
                        "conditions_facilities": "4.3"
                    },
                    "rat": "0.0",
                    "urat": "4.3",
                    "expediareview": 3,
                    "pid": "61208075",
                    "line1": "Beirut St",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Al Twar Center - 2 km / 1.2 mi "
                },
                "hdtype": "HOTELNHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 5797.16,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 442.52,
                "com_disc": 0,
                "rtf": "5797.00",
                "rdwtf": "5797.00"
            },
            {
                "tid": "kp2Cb1zm5IigKPmT",
                "pid": "2173527",
                "hs": 0,
                "rmid": "316415",
                "rtid": "381083826",
                "refundable": true,
                "available_rooms": 4,
                "score": 394,
                "sno": "268",
                "cont": {
                    "_id": "600d41eca1bc371b80fde1cf",
                    "name": "Holiday Inn Express Dubai Safa Park",
                    "ratings": "2.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.188895,
                            "longitude": 55.255332
                        }
                    },
                    "externalId": "2173527",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/3000000/2180000/2173600/2173527/3d59098c_b.jpg",
                    "ha": [
                        "BAR",
                        "WIFI",
                        "GYM"
                    ],
                    "guestreview": {
                        "cleaniness": "4.1",
                        "staff_service": "4.1",
                        "amenities": "4.0",
                        "conditions_facilities": "3.9"
                    },
                    "rat": "2.0",
                    "urat": "4.0",
                    "expediareview": 705,
                    "pid": "2173527",
                    "line1": "Al Wasl District",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Dubai Water Canal - 0.4 km / 0.3 mi "
                },
                "hdtype": "HOTELNHD",
                "usrst": "Very Good",
                "brdbs": [
                    "Breakfast"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 5780.74,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 438.42,
                "com_disc": 0,
                "rtf": "5781.00",
                "rdwtf": "5781.00"
            },
            {
                "tid": "xLDkLEC55T0uD1fb",
                "pid": "60005289",
                "hs": 0,
                "rmid": "228653405",
                "rtid": "264374678",
                "refundable": true,
                "available_rooms": 5,
                "score": 333,
                "sno": "266",
                "cont": {
                    "_id": "600d3ed6a1bc371b80f9a36a",
                    "name": "Element Al Jaddaf, Dubai",
                    "ratings": "0.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.219744,
                            "longitude": 55.332128
                        }
                    },
                    "externalId": "60005289",
                    "externalProvider": "EXPEDIA",
                    "ha": [
                        "BAR",
                        "POOL"
                    ],
                    "guestreview": {
                        "cleaniness": 0,
                        "staff_service": 0,
                        "amenities": 0,
                        "conditions_facilities": 0
                    },
                    "rat": "0.0",
                    "urat": "",
                    "expediareview": 0,
                    "pid": "60005289",
                    "line1": "AL KHAIL RD, AL JADDAF DUBAI STREET 26",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Dubai Creek Golf & Yacht Club - 3.7 km / 2.3 mi "
                },
                "hdtype": "HOTELNHD",
                "brdbs": [
                    "Breakfast"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 5731.48,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 344.82,
                "com_disc": 0,
                "rtf": "5731.00",
                "rdwtf": "5731.00"
            },
            {
                "tid": "U6ixf9CdXKUpScNL",
                "pid": "115885",
                "hs": 0,
                "rmid": "321325653",
                "rtid": "388590281",
                "refundable": true,
                "available_rooms": 33,
                "score": 0,
                "sno": "264",
                "cont": {
                    "_id": "62506a302aee3e1bb50b6615",
                    "ratings": "4.0",
                    "name": "Jumeira Rotana",
                    "location": {
                        "coordinates": {
                            "latitude": 25.237132,
                            "longitude": 55.274227
                        },
                        "obfuscationRequired": false
                    },
                    "externalId": "115885",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/1000000/120000/115900/115885/af6f7211_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.4",
                        "staff_service": "4.4",
                        "amenities": "4.2",
                        "conditions_facilities": "4.1"
                    },
                    "rat": "4.0",
                    "urat": "4.2",
                    "expediareview": 715,
                    "pid": "115885",
                    "line1": "2nd December St., Al Badaa, Al Jafiliya",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Etihad Museum - 0.8 km / 0.5 mi "
                },
                "hdtype": "HOTELNHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 5721.63,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 438.42,
                "com_disc": 0,
                "rtf": "5722.00",
                "rdwtf": "5722.00"
            },
            {
                "tid": "oWbhK987HvZs2Z5O",
                "pid": "68060304",
                "hs": 0,
                "rmid": "314244126",
                "rtid": "382753204",
                "refundable": true,
                "available_rooms": 9,
                "score": 179,
                "sno": "257",
                "cont": {
                    "_id": "62506b0c2aee3e1bb50b677a",
                    "ratings": "0.0",
                    "name": "Rose Executive Hotel - DWTC",
                    "location": {
                        "coordinates": {
                            "latitude": 25.22474,
                            "longitude": 55.280689
                        },
                        "obfuscationRequired": false
                    },
                    "externalId": "68060304",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/69000000/68070000/68060400/68060304/6525cd4b_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "5.0",
                        "staff_service": "4.5",
                        "amenities": "4.0",
                        "conditions_facilities": "4.5"
                    },
                    "rat": "0.0",
                    "urat": "4.0",
                    "expediareview": 2,
                    "pid": "68060304",
                    "line1": "Jumeirah Garden City",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Sheikh Zayed Road - 0.1 km / 0.1 mi "
                },
                "hdtype": "HOTELNHD",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 5630.49,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 430.21,
                "com_disc": 0,
                "rtf": "5630.00",
                "rdwtf": "5630.00"
            },
            {
                "tid": "H0GnPf4er0VkSVpO",
                "pid": "21766370",
                "hs": 0,
                "rmid": "217334895",
                "rtid": "268262678",
                "refundable": true,
                "available_rooms": 1,
                "score": 339,
                "sno": "250",
                "cont": {
                    "_id": "600d3eada1bc371b80f96d5e",
                    "name": "Hampton by Hilton Dubai Al Seef",
                    "ratings": "3.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.255941,
                            "longitude": 55.311112
                        }
                    },
                    "externalId": "21766370",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/22000000/21770000/21766400/21766370/e1a94b9d_b.jpg",
                    "ha": [
                        "BAR",
                        "POOL",
                        "WIFI",
                        "GYM"
                    ],
                    "guestreview": {
                        "cleaniness": "4.8",
                        "staff_service": "4.5",
                        "amenities": "4.6",
                        "conditions_facilities": "4.7"
                    },
                    "rat": "3.0",
                    "urat": "4.6",
                    "expediareview": 77,
                    "pid": "21766370",
                    "line1": "Al Seef Street, Dubai Creek",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Al Seef - 0.4 km / 0.3 mi "
                },
                "hdtype": "HOTELNHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Breakfast"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 5561.53,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 421.18,
                "com_disc": 0,
                "rtf": "5562.00",
                "rdwtf": "5562.00"
            },
            {
                "tid": "rqf72Mzs38WfxXA0",
                "pid": "4560641",
                "hs": 0,
                "rmid": "200094434",
                "rtid": "228274934",
                "refundable": false,
                "available_rooms": 5,
                "score": 257,
                "sno": "244",
                "cont": {
                    "_id": "600d3deaa1bc371b80f84e19",
                    "name": "Fortune Plaza Hotel",
                    "ratings": "3.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.267075,
                            "longitude": 55.38071
                        }
                    },
                    "externalId": "4560641",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/5000000/4570000/4560700/4560641/8142d87c_b.jpg",
                    "ha": [
                        "BAR",
                        "GYM",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "3.2",
                        "staff_service": "3.2",
                        "amenities": "4.0",
                        "conditions_facilities": "3.0"
                    },
                    "rat": "3.0",
                    "urat": "3.1",
                    "expediareview": 406,
                    "pid": "4560641",
                    "line1": "Al Nadha Road",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Al-Maktoum Stadium - 4.3 km / 2.7 mi "
                },
                "hdtype": "HOTELNHD",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 5524.58,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 421.18,
                "com_disc": 0,
                "rtf": "5525.00",
                "rdwtf": "5525.00"
            },
            {
                "tid": "M6ZzmsgNOlkW9oi8",
                "pid": "32937461",
                "hs": 0,
                "rmid": "213606689",
                "rtid": "244104805",
                "refundable": true,
                "available_rooms": 5,
                "score": 252,
                "sno": "243",
                "cont": {
                    "_id": "600d3de7a1bc371b80f84a85",
                    "name": "Four Points by Sheraton Sharjah",
                    "ratings": "4.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.341124,
                            "longitude": 55.388393
                        }
                    },
                    "externalId": "32937461",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/33000000/32940000/32937500/32937461/f700009a_b.jpg",
                    "ha": [
                        "POOL",
                        "POOL",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.5",
                        "staff_service": "4.5",
                        "amenities": "4.5",
                        "conditions_facilities": "4.4"
                    },
                    "rat": "4.0",
                    "urat": "4.5",
                    "expediareview": 37,
                    "pid": "32937461",
                    "line1": "King Faisal Street, Al Qasimia",
                    "city": "Sharjah",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Crystal Plaza - 0.7 km / 0.5 mi "
                },
                "hdtype": "HOTELNHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 5490.92,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 403.12,
                "com_disc": 0,
                "rtf": "5491.00",
                "rdwtf": "5491.00"
            },
            {
                "tid": "WXpsKvhFB4weGTTU",
                "pid": "903302",
                "hs": 0,
                "rmid": "200673219",
                "rtid": "238249855",
                "refundable": false,
                "available_rooms": 30,
                "score": 277,
                "sno": "237",
                "cont": {
                    "_id": "600d411ba1bc371b80fcbc1f",
                    "name": "Dubai Grand Hotel by Fortune",
                    "ratings": "4.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.273245,
                            "longitude": 55.381437
                        }
                    },
                    "externalId": "903302",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/1000000/910000/903400/903302/17df2692_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "POOL",
                        "POOL",
                        "BAR"
                    ],
                    "guestreview": {
                        "cleaniness": "3.6",
                        "staff_service": "3.5",
                        "amenities": "3.4",
                        "conditions_facilities": "3.3"
                    },
                    "rat": "4.0",
                    "urat": "3.4",
                    "expediareview": 115,
                    "pid": "903302",
                    "line1": "Al Quasis",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Al-Maktoum Stadium - 4.5 km / 2.8 mi "
                },
                "hdtype": "HOTELNHD",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 5403.07,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 412.97,
                "com_disc": 0,
                "rtf": "5403.00",
                "rdwtf": "5403.00"
            },
            {
                "tid": "GpLBU0Mxv0quLKPz",
                "pid": "911528",
                "hs": 0,
                "rmid": "185746",
                "rtid": "448803",
                "refundable": true,
                "available_rooms": 2147483647,
                "score": 569,
                "sno": "234",
                "cont": {
                    "_id": "600d3f44a1bc371b80fa3c59",
                    "name": "Novotel World Trade Centre",
                    "ratings": "4.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.221349,
                            "longitude": 55.288544
                        }
                    },
                    "externalId": "911528",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/1000000/920000/911600/911528/b0603ba3_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "POOL",
                        "BAR",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.2",
                        "staff_service": "4.0",
                        "amenities": "4.1",
                        "conditions_facilities": "4.1"
                    },
                    "rat": "4.0",
                    "urat": "4.0",
                    "expediareview": 996,
                    "pid": "911528",
                    "line1": "Al Mustaqbal Street",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Dubai International Convention and Exhibition Centre - 0.6 km / 0.4 mi "
                },
                "hdtype": "HOTELNHD",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 5339.86,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 407.22,
                "com_disc": 0,
                "rtf": "5340.00",
                "rdwtf": "5340.00"
            },
            {
                "tid": "wwxcHGL5Vs6Arkez",
                "pid": "27546056",
                "hs": 0,
                "rmid": "220955523",
                "rtid": "292301644",
                "refundable": true,
                "available_rooms": 1,
                "score": 301,
                "sno": "232",
                "cont": {
                    "_id": "600d4218a1bc371b80fe1911",
                    "name": "Al Seef Heritage Hotel Dubai, Curio Collection by Hilton",
                    "ratings": "0.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.264075,
                            "longitude": 55.305116
                        }
                    },
                    "externalId": "27546056",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/28000000/27550000/27546100/27546056/7a193bcd_b.jpg",
                    "ha": [
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.7",
                        "staff_service": "4.5",
                        "amenities": "4.3",
                        "conditions_facilities": "4.6"
                    },
                    "rat": "0.0",
                    "urat": "4.6",
                    "expediareview": 211,
                    "pid": "27546056",
                    "line1": "Al Seef Street, Dubai Creek",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Bastakiya - 0.6 km / 0.4 mi "
                },
                "hdtype": "HOTELNHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 5314.4,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 402.3,
                "com_disc": 0,
                "rtf": "5314.00",
                "rdwtf": "5314.00"
            },
            {
                "tid": "K0BbNwciuV9AXy5y",
                "pid": "2239650",
                "hs": 0,
                "rmid": "200059554",
                "rtid": "232315243",
                "refundable": true,
                "available_rooms": 6,
                "score": 399,
                "sno": "213",
                "cont": {
                    "_id": "600d3de1a1bc371b80f84410",
                    "name": "Holiday Inn Express Dubai Jumeirah",
                    "ratings": "2.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.243006,
                            "longitude": 55.271558
                        }
                    },
                    "externalId": "2239650",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/3000000/2240000/2239700/2239650/31325dcf_b.jpg",
                    "ha": [
                        "BAR",
                        "GYM",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.0",
                        "staff_service": "4.1",
                        "amenities": "3.7",
                        "conditions_facilities": "3.9"
                    },
                    "rat": "2.0",
                    "urat": "3.9",
                    "expediareview": 820,
                    "pid": "2239650",
                    "line1": "Jumeirah Road",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Jumeirah Mosque - 1.3 km / 0.8 mi "
                },
                "hdtype": "HOTELNHD",
                "usrst": "Very Good",
                "brdbs": [
                    "Breakfast"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 5038.54,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 373.56,
                "com_disc": 0,
                "rtf": "5039.00",
                "rdwtf": "5039.00"
            },
            {
                "tid": "M2t8Jz0uROS0bhv3",
                "pid": "23512412",
                "hs": 0,
                "rmid": "221419870",
                "rtid": "296066600",
                "refundable": true,
                "available_rooms": 9,
                "score": 365,
                "sno": "212",
                "cont": {
                    "_id": "600d3ebca1bc371b80f97fdc",
                    "name": "Hampton by Hilton Dubai Airport",
                    "ratings": "0.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.272373,
                            "longitude": 55.380845
                        }
                    },
                    "externalId": "23512412",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/24000000/23520000/23512500/23512412/e852d8ec_b.jpg",
                    "ha": [
                        "BAR",
                        "POOL",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.4",
                        "staff_service": "4.3",
                        "amenities": "4.0",
                        "conditions_facilities": "4.3"
                    },
                    "rat": "0.0",
                    "urat": "4.2",
                    "expediareview": 315,
                    "pid": "23512412",
                    "line1": "Damascus Street, Al Qusais",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Al-Maktoum Stadium - 4.4 km / 2.7 mi "
                },
                "hdtype": "HOTELNHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Breakfast"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 5005.7,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 311.16,
                "com_disc": 0,
                "rtf": "5006.00",
                "rdwtf": "5006.00"
            },
            {
                "tid": "vq5OIm6du6hNR4lh",
                "pid": "10175890",
                "hs": 0,
                "rmid": "322412689",
                "rtid": "391002137",
                "refundable": false,
                "available_rooms": 6,
                "score": 270,
                "sno": "195",
                "cont": {
                    "_id": "600d41d0a1bc371b80fdbc90",
                    "name": "Al Jawhara Gardens Hotel",
                    "ratings": "4.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.254838,
                            "longitude": 55.334878
                        }
                    },
                    "externalId": "10175890",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/11000000/10180000/10175900/10175890/079b3faa_b.jpg",
                    "ha": [
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "3.9",
                        "staff_service": "4.0",
                        "amenities": "4.1",
                        "conditions_facilities": "3.7"
                    },
                    "rat": "4.0",
                    "urat": "3.8",
                    "expediareview": 203,
                    "pid": "10175890",
                    "line1": "Port Saeed Street, Deira",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "City Centre Deira - 0.7 km / 0.4 mi "
                },
                "hdtype": "HOTELNHD",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 4831.65,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 369.45,
                "com_disc": 0,
                "rtf": "4832.00",
                "rdwtf": "4832.00"
            },
            {
                "tid": "EsM8zZdM8tmGTR8E",
                "pid": "17398301",
                "hs": 0,
                "rmid": "201836225",
                "rtid": "210301510",
                "refundable": false,
                "available_rooms": 19,
                "score": 329,
                "sno": "192",
                "cont": {
                    "_id": "600d4276a1bc371b80fe9719",
                    "name": "Royal Continental Hotel – Dubai Airport",
                    "ratings": "4.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.252806,
                            "longitude": 55.333524
                        }
                    },
                    "externalId": "17398301",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/18000000/17400000/17398400/17398301/5335a3dd_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.2",
                        "staff_service": "4.2",
                        "amenities": "4.1",
                        "conditions_facilities": "4.1"
                    },
                    "rat": "4.0",
                    "urat": "4.1",
                    "expediareview": 230,
                    "pid": "17398301",
                    "line1": "Opposite Deira City Center",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "City Centre Deira - 0.5 km / 0.3 mi "
                },
                "hdtype": "HOTELNHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 4827.54,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 366.17,
                "com_disc": 0,
                "rtf": "4828.00",
                "rdwtf": "4828.00"
            },
            {
                "tid": "PMVaIxTHvKtJdcqw",
                "pid": "16021758",
                "hs": 0,
                "rmid": "219924198",
                "rtid": "286080393",
                "refundable": false,
                "available_rooms": 5,
                "score": 152,
                "sno": "187",
                "cont": {
                    "_id": "600d3f4ba1bc371b80fa4527",
                    "name": "Lavender Hotel Sharjah",
                    "ratings": "4.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.336852,
                            "longitude": 55.361849
                        }
                    },
                    "externalId": "16021758",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/17000000/16030000/16021800/16021758/1eac3580_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": 0,
                        "staff_service": 0,
                        "amenities": 0,
                        "conditions_facilities": 0
                    },
                    "rat": "4.0",
                    "urat": "",
                    "expediareview": 0,
                    "pid": "16021758",
                    "line1": "Al Khan Beach Road",
                    "city": "Sharjah",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Sharjah Aquarium - 0.9 km / 0.6 mi "
                },
                "hdtype": "HOTELNHD",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 4776.64,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 389.98,
                "com_disc": 0,
                "rtf": "4777.00",
                "rdwtf": "4777.00"
            },
            {
                "tid": "8Wo3kUyWNT2IcWCR",
                "pid": "2425531",
                "hs": 0,
                "rmid": "201229814",
                "rtid": "205820259",
                "refundable": true,
                "available_rooms": 2147483647,
                "score": 481,
                "sno": "175",
                "cont": {
                    "_id": "600d418ca1bc371b80fd3f60",
                    "name": "Novotel Suites Mall of the Emirates",
                    "ratings": "3.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.116523,
                            "longitude": 55.195676
                        }
                    },
                    "externalId": "2425531",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/3000000/2430000/2425600/2425531/b2c7fd29_b.jpg",
                    "ha": [
                        "BAR",
                        "GYM",
                        "POOL",
                        "POOL"
                    ],
                    "guestreview": {
                        "cleaniness": "4.1",
                        "staff_service": "4.2",
                        "amenities": "3.9",
                        "conditions_facilities": "4.0"
                    },
                    "rat": "3.0",
                    "urat": "4.1",
                    "expediareview": 989,
                    "pid": "2425531",
                    "line1": "2A Street",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Fatima Abdullah Mohammed Rasheed Mosque - 0.6 km / 0.3 mi "
                },
                "hdtype": "HOTELNHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 4588.63,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 349.75,
                "com_disc": 0,
                "rtf": "4589.00",
                "rdwtf": "4589.00"
            },
            {
                "tid": "997iXkIqPmh1l091",
                "pid": "12949919",
                "hs": 0,
                "rmid": "201368720",
                "rtid": "288093162",
                "refundable": true,
                "available_rooms": 9,
                "score": 540,
                "sno": "173",
                "cont": {
                    "_id": "600d3ef7a1bc371b80f9c8bc",
                    "name": "Hilton Garden Inn Dubai Mall Of The Emirates",
                    "ratings": "4.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.115862,
                            "longitude": 55.196503
                        }
                    },
                    "externalId": "12949919",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/13000000/12950000/12950000/12949919/e647dcc9_b.jpg",
                    "ha": [
                        "BAR",
                        "POOL",
                        "WIFI",
                        "GYM"
                    ],
                    "guestreview": {
                        "cleaniness": "4.6",
                        "staff_service": "4.5",
                        "amenities": "4.2",
                        "conditions_facilities": "4.5"
                    },
                    "rat": "4.0",
                    "urat": "4.4",
                    "expediareview": 611,
                    "pid": "12949919",
                    "line1": "22nd St, Al Barsha",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Fatima Abdullah Mohammed Rasheed Mosque - 0.5 km / 0.3 mi "
                },
                "hdtype": "HOTELNHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 4572.21,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 345.65,
                "com_disc": 0,
                "rtf": "4572.00",
                "rdwtf": "4572.00"
            },
            {
                "tid": "UbTRcRekxxmq94W1",
                "pid": "55562354",
                "hs": 0,
                "rmid": "230754613",
                "rtid": "278374444",
                "refundable": true,
                "available_rooms": 23,
                "score": 220,
                "sno": "170",
                "cont": {
                    "_id": "600d3e35a1bc371b80f8b1ae",
                    "name": "Fortune Atrium Hotel",
                    "ratings": "0.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.257144,
                            "longitude": 55.29985
                        }
                    },
                    "externalId": "55562354",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/56000000/55570000/55562400/55562354/07eb02f6_b.jpg",
                    "ha": [
                        "BAR",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": 0,
                        "staff_service": 0,
                        "amenities": 0,
                        "conditions_facilities": 0
                    },
                    "rat": "0.0",
                    "urat": "",
                    "expediareview": 0,
                    "pid": "55562354",
                    "line1": "Khalid Bin Al Waleed Rd",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Meena Bazaar - 0.7 km / 0.4 mi "
                },
                "hdtype": "HOTELNHD",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 4564.82,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 348.11,
                "com_disc": 0,
                "rtf": "4565.00",
                "rdwtf": "4565.00"
            },
            {
                "tid": "moeFXT5UiNBM7vJ2",
                "pid": "5440701",
                "hs": 0,
                "rmid": "200288273",
                "rtid": "201720453",
                "refundable": true,
                "available_rooms": 2147483647,
                "score": 473,
                "sno": "168",
                "cont": {
                    "_id": "600d4213a1bc371b80fe1504",
                    "name": "Novotel Dubai Al Barsha",
                    "ratings": "4.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.112802,
                            "longitude": 55.188001
                        }
                    },
                    "externalId": "5440701",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/6000000/5450000/5440800/5440701/2ffd99fd_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "POOL",
                        "BAR",
                        "POOL"
                    ],
                    "guestreview": {
                        "cleaniness": "4.3",
                        "staff_service": "4.3",
                        "amenities": "4.3",
                        "conditions_facilities": "4.2"
                    },
                    "rat": "4.0",
                    "urat": "4.2",
                    "expediareview": 873,
                    "pid": "5440701",
                    "line1": "Opp. Mashreq Metro Station",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Fatima Abdullah Mohammed Rasheed Mosque - 1.7 km / 1.1 mi "
                },
                "hdtype": "HOTELNHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 4562.36,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 348.11,
                "com_disc": 0,
                "rtf": "4562.00",
                "rdwtf": "4562.00"
            },
            {
                "tid": "Aa7QDizR55UA7kSl",
                "pid": "1773507",
                "hs": 0,
                "rmid": "211803550",
                "rtid": "232094913",
                "refundable": false,
                "available_rooms": 5,
                "score": 437,
                "sno": "163",
                "cont": {
                    "_id": "600d4399a1bc371b8000074d",
                    "name": "Four Points by Sheraton Downtown Dubai",
                    "ratings": "4.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.252112,
                            "longitude": 55.292547
                        }
                    },
                    "externalId": "1773507",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/2000000/1780000/1773600/1773507/303c8dab_b.jpg",
                    "ha": [
                        "BAR",
                        "GYM",
                        "POOL",
                        "POOL",
                        "BAR"
                    ],
                    "guestreview": {
                        "cleaniness": "4.5",
                        "staff_service": "4.4",
                        "amenities": "4.4",
                        "conditions_facilities": "4.4"
                    },
                    "rat": "4.0",
                    "urat": "4.3",
                    "expediareview": 990,
                    "pid": "1773507",
                    "line1": "Mankhool Road, 4c Street",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "BurJuman Mall - 1.5 km / 0.9 mi "
                },
                "hdtype": "HOTELNHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 4499.14,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 380.95,
                "com_disc": 0,
                "rtf": "4499.00",
                "rdwtf": "4499.00"
            },
            {
                "tid": "T5a6nKwxFf2Dz0LD",
                "pid": "4109045",
                "hs": 0,
                "rmid": "200900850",
                "rtid": "278232174",
                "refundable": false,
                "available_rooms": 24,
                "score": 207,
                "sno": "152",
                "cont": {
                    "_id": "600d4168a1bc371b80fd0d7b",
                    "name": "Tulip Hotel Apartment",
                    "ratings": "3.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.25515,
                            "longitude": 55.298167
                        }
                    },
                    "externalId": "4109045",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/5000000/4110000/4109100/4109045/351ef32b_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "3.4",
                        "staff_service": "3.5",
                        "amenities": "3.2",
                        "conditions_facilities": "3.4"
                    },
                    "rat": "3.0",
                    "urat": "3.5",
                    "expediareview": 139,
                    "pid": "4109045",
                    "line1": "5a street 317 community",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "BurJuman Mall - 1.1 km / 0.7 mi "
                },
                "hdtype": "HOTELNHD",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 4396.51,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 337.44,
                "com_disc": 0,
                "rtf": "4397.00",
                "rdwtf": "4397.00"
            },
            {
                "tid": "WO32NtQNBT2Wfdth",
                "pid": "4127757",
                "hs": 0,
                "rmid": "200652284",
                "rtid": "390333385",
                "refundable": true,
                "available_rooms": 5,
                "score": 161,
                "sno": "153",
                "cont": {
                    "_id": "62506b5e2aee3e1bb50b6892",
                    "ratings": "4.0",
                    "name": "Sharjah Palace Hotel",
                    "location": {
                        "coordinates": {
                            "latitude": 25.305206,
                            "longitude": 55.36394
                        },
                        "obfuscationRequired": false
                    },
                    "externalId": "4127757",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/5000000/4130000/4127800/4127757/def88aee_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "3.8",
                        "staff_service": "3.5",
                        "amenities": "3.0",
                        "conditions_facilities": "3.6"
                    },
                    "rat": "4.0",
                    "urat": "3.6",
                    "expediareview": 43,
                    "pid": "4127757",
                    "line1": "Al Tawun Street",
                    "city": "Sharjah",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Expo Centre Sharjah - 1.6 km / 1 mi "
                },
                "hdtype": "HOTELNHD",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 4396.51,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 360.42,
                "com_disc": 0,
                "rtf": "4397.00",
                "rdwtf": "4397.00"
            },
            {
                "tid": "TIrVcxUaCdebhfEG",
                "pid": "29079976",
                "hs": 0,
                "rmid": "216927449",
                "rtid": "266350816",
                "refundable": false,
                "available_rooms": 5,
                "score": 323,
                "sno": "151",
                "cont": {
                    "_id": "600d3de0a1bc371b80f84284",
                    "name": "Aloft Dubai South",
                    "ratings": "4.0",
                    "location": {
                        "coordinates": {
                            "latitude": 24.86664,
                            "longitude": 55.146261
                        }
                    },
                    "externalId": "29079976",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/30000000/29080000/29080000/29079976/de5fa622_b.jpg",
                    "ha": [
                        "BAR",
                        "POOL",
                        "WIFI",
                        "GYM"
                    ],
                    "guestreview": {
                        "cleaniness": "4.4",
                        "staff_service": "3.9",
                        "amenities": "4.5",
                        "conditions_facilities": "4.5"
                    },
                    "rat": "4.0",
                    "urat": "4.1",
                    "expediareview": 9,
                    "pid": "29079976",
                    "line1": "Aviation City Dubai",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Legoland® Dubai - 18.4 km / 11.4 mi "
                },
                "hdtype": "HOTELNHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 4394.05,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 331.69,
                "com_disc": 0,
                "rtf": "4394.00",
                "rdwtf": "4394.00"
            },
            {
                "tid": "QnOwwUmkkdrrGwin",
                "pid": "12946724",
                "hs": 0,
                "rmid": "201392421",
                "rtid": "282058103",
                "refundable": true,
                "available_rooms": 2147483647,
                "score": 314,
                "sno": "146",
                "cont": {
                    "_id": "600d4230a1bc371b80fe3b98",
                    "name": "ibis Styles Dragon Mart Dubai",
                    "ratings": "3.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.174169,
                            "longitude": 55.419705
                        }
                    },
                    "externalId": "12946724",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/13000000/12950000/12946800/12946724/58322d06_b.jpg",
                    "ha": [
                        "BAR",
                        "GYM",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.2",
                        "staff_service": "4.0",
                        "amenities": "4.0",
                        "conditions_facilities": "4.1"
                    },
                    "rat": "3.0",
                    "urat": "4.0",
                    "expediareview": 107,
                    "pid": "12946724",
                    "line1": "Al Awir Road E44 on Hatta Road",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Dragon Mart - 0.6 km / 0.4 mi "
                },
                "hdtype": "HOTELNHD",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 4353.82,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 275.04,
                "com_disc": 0,
                "rtf": "4354.00",
                "rdwtf": "4354.00"
            },
            {
                "tid": "45Tl2DN2XAHHUL6P",
                "pid": "37260445",
                "hs": 0,
                "rmid": "219954440",
                "rtid": "286224887",
                "refundable": true,
                "available_rooms": 2147483647,
                "score": 354,
                "sno": "147",
                "cont": {
                    "_id": "600d42c9a1bc371b80ff0c08",
                    "name": "Novotel Bur Dubai",
                    "ratings": "0.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.2315,
                            "longitude": 55.3244
                        }
                    },
                    "externalId": "37260445",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/38000000/37270000/37260500/37260445/a0104d04_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "BAR",
                        "POOL",
                        "POOL"
                    ],
                    "guestreview": {
                        "cleaniness": "4.8",
                        "staff_service": "4.6",
                        "amenities": "4.3",
                        "conditions_facilities": "4.7"
                    },
                    "rat": "0.0",
                    "urat": "4.4",
                    "expediareview": 32,
                    "pid": "37260445",
                    "line1": "Dubai Healthcare City",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Mohammed Bin Rashid University Of Medicine and Health Sciences - 0.2 km / 0.1 mi "
                },
                "hdtype": "HOTELNHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 4353.82,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 314.45,
                "com_disc": 0,
                "rtf": "4354.00",
                "rdwtf": "4354.00"
            },
            {
                "tid": "t81phSci4yonTObw",
                "pid": "27338819",
                "hs": 0,
                "rmid": "315914970",
                "rtid": "385055013",
                "refundable": true,
                "available_rooms": 9,
                "score": 371,
                "sno": "141",
                "cont": {
                    "_id": "600d4040a1bc371b80fb821f",
                    "name": "Hilton Garden Inn Dubai Al Jadaf Culture Village",
                    "ratings": "4.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.22237,
                            "longitude": 55.33187
                        }
                    },
                    "externalId": "27338819",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/28000000/27340000/27338900/27338819/cd10916a_b.jpg",
                    "ha": [
                        "BAR",
                        "GYM",
                        "POOL",
                        "POOL"
                    ],
                    "guestreview": {
                        "cleaniness": "4.6",
                        "staff_service": "4.5",
                        "amenities": "4.4",
                        "conditions_facilities": "4.5"
                    },
                    "rat": "4.0",
                    "urat": "4.4",
                    "expediareview": 187,
                    "pid": "27338819",
                    "line1": "Plot 3261450 - Al Jadaf",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Wafi City Mall - 3.6 km / 2.2 mi "
                },
                "hdtype": "HOTELNHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 4325.91,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 327.58,
                "com_disc": 0,
                "rtf": "4326.00",
                "rdwtf": "4326.00"
            },
            {
                "tid": "DCcBLrGZHuPL3yDd",
                "pid": "9988389",
                "hs": 0,
                "rmid": "200892345",
                "rtid": "381692390",
                "refundable": true,
                "available_rooms": 2,
                "score": 193,
                "sno": "139",
                "cont": {
                    "_id": "62506b202aee3e1bb50b67b3",
                    "ratings": "3.0",
                    "name": "Zain International Hotel",
                    "location": {
                        "coordinates": {
                            "latitude": 25.264481,
                            "longitude": 55.317781
                        },
                        "obfuscationRequired": false
                    },
                    "externalId": "9988389",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/10000000/9990000/9988400/9988389/ee28ea85_b.jpg",
                    "ha": [
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "3.1",
                        "staff_service": "2.7",
                        "amenities": 0,
                        "conditions_facilities": "2.8"
                    },
                    "rat": "3.0",
                    "urat": "2.8",
                    "expediareview": 45,
                    "pid": "9988389",
                    "line1": "Al Rigga Road, Deira",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Al Ghurair Centre - 0.6 km / 0.4 mi "
                },
                "hdtype": "HOTELNHD",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 4311.95,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 330.05,
                "com_disc": 0,
                "rtf": "4312.00",
                "rdwtf": "4312.00"
            },
            {
                "tid": "zvVVSdGXz36U9bTU",
                "pid": "11766572",
                "hs": 0,
                "rmid": "315998063",
                "rtid": "385211308",
                "refundable": false,
                "available_rooms": 25,
                "score": 207,
                "sno": "133",
                "cont": {
                    "_id": "600d3f4ba1bc371b80fa44c6",
                    "name": "Fortune Karama Hotel",
                    "ratings": "3.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.242501,
                            "longitude": 55.297876
                        }
                    },
                    "externalId": "11766572",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/12000000/11770000/11766600/11766572/2002371d_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "3.3",
                        "staff_service": "3.3",
                        "amenities": "2.8",
                        "conditions_facilities": "3.0"
                    },
                    "rat": "3.0",
                    "urat": "3.2",
                    "expediareview": 52,
                    "pid": "11766572",
                    "line1": "Near karama Metro Station",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Zabeel Park - 1 km / 0.6 mi "
                },
                "hdtype": "HOTELNHD",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 4224.92,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 322.66,
                "com_disc": 0,
                "rtf": "4225.00",
                "rdwtf": "4225.00"
            },
            {
                "tid": "WIBzCMfFec5uwB9u",
                "pid": "45601132",
                "hs": 0,
                "rmid": "315681938",
                "rtid": "384279819",
                "refundable": false,
                "available_rooms": 9,
                "score": 346,
                "sno": "131",
                "cont": {
                    "_id": "600d411ca1bc371b80fcbd9d",
                    "name": "Holiday Inn Dubai Al-Maktoum Airport",
                    "ratings": "3.5",
                    "location": {
                        "coordinates": {
                            "latitude": 24.86766,
                            "longitude": 55.145984
                        }
                    },
                    "externalId": "45601132",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/46000000/45610000/45601200/45601132/9ce58847_b.jpg",
                    "ha": [
                        "POOL",
                        "POOL",
                        "BAR",
                        "POOL",
                        "POOL"
                    ],
                    "guestreview": {
                        "cleaniness": 0,
                        "staff_service": 0,
                        "amenities": 0,
                        "conditions_facilities": 0
                    },
                    "rat": "3.5",
                    "urat": "",
                    "expediareview": 0,
                    "pid": "45601132",
                    "line1": "Aviation City",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Dubai Parks and Resorts - 17.7 km / 11 mi "
                },
                "hdtype": "HOTELNHD",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 4197.01,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 304.6,
                "com_disc": 0,
                "rtf": "4197.00",
                "rdwtf": "4197.00"
            },
            {
                "tid": "ThyeP7hkt6fhxXoJ",
                "pid": "3125411",
                "hs": 0,
                "rmid": "212701947",
                "rtid": "238265329",
                "refundable": true,
                "available_rooms": 102,
                "score": 432,
                "sno": "127",
                "cont": {
                    "_id": "600d4254a1bc371b80fe6d13",
                    "name": "Premier Inn Dubai International Airport",
                    "ratings": "3.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.24295,
                            "longitude": 55.35882
                        }
                    },
                    "externalId": "3125411",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/4000000/3130000/3125500/3125411/28c13170_b.jpg",
                    "ha": [
                        "BAR",
                        "GYM",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.4",
                        "staff_service": "4.4",
                        "amenities": "4.1",
                        "conditions_facilities": "4.2"
                    },
                    "rat": "3.0",
                    "urat": "4.3",
                    "expediareview": 1023,
                    "pid": "3125411",
                    "line1": "Opposite Terminal 3, via Street 52b",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Dubai Festival City Mall - 3.1 km / 1.9 mi "
                },
                "hdtype": "HOTELNHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 4163.35,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 316.91,
                "com_disc": 0,
                "rtf": "4163.00",
                "rdwtf": "4163.00"
            },
            {
                "tid": "c4h01t3VPrhwcqqa",
                "pid": "16723088",
                "hs": 0,
                "rmid": "216606907",
                "rtid": "264207239",
                "refundable": true,
                "available_rooms": 15,
                "score": 404,
                "sno": "122",
                "cont": {
                    "_id": "600d4253a1bc371b80fe6b23",
                    "name": "Premier Inn Dubai Ibn Battuta Mall",
                    "ratings": "3.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.046701,
                            "longitude": 55.118808
                        }
                    },
                    "externalId": "16723088",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/17000000/16730000/16723100/16723088/7f28603c_b.jpg",
                    "ha": [
                        "BAR",
                        "POOL",
                        "WIFI",
                        "GYM"
                    ],
                    "guestreview": {
                        "cleaniness": "4.5",
                        "staff_service": "4.5",
                        "amenities": "4.1",
                        "conditions_facilities": "4.5"
                    },
                    "rat": "3.0",
                    "urat": "4.4",
                    "expediareview": 94,
                    "pid": "16723088",
                    "line1": "Sheikh Zayed Road",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Ibn Battuta Mall - 1.4 km / 0.9 mi "
                },
                "hdtype": "HOTELNHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 4117.37,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 313.63,
                "com_disc": 0,
                "rtf": "4117.00",
                "rdwtf": "4117.00"
            },
            {
                "tid": "rgCd3MTTgmHM9QoR",
                "pid": "11628336",
                "hs": 0,
                "rmid": "201207466",
                "rtid": "276081706",
                "refundable": true,
                "available_rooms": 9,
                "score": 425,
                "sno": "115",
                "cont": {
                    "_id": "600d426aa1bc371b80fe8889",
                    "name": "Hilton Garden Inn Dubai Al Mina",
                    "ratings": "4.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.247522,
                            "longitude": 55.278543
                        }
                    },
                    "externalId": "11628336",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/12000000/11630000/11628400/11628336/84102faf_b.jpg",
                    "ha": [
                        "BAR",
                        "POOL",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.5",
                        "staff_service": "4.4",
                        "amenities": "4.0",
                        "conditions_facilities": "4.3"
                    },
                    "rat": "4.0",
                    "urat": "4.2",
                    "expediareview": 435,
                    "pid": "11628336",
                    "line1": "Al Mina Road",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Dubai Cruise Terminal - 1.9 km / 1.2 mi "
                },
                "hdtype": "HOTELNHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 4077.96,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 308.7,
                "com_disc": 0,
                "rtf": "4078.00",
                "rdwtf": "4078.00"
            },
            {
                "tid": "NNhoOkBTxdsQXTFe",
                "pid": "11638229",
                "hs": 0,
                "rmid": "201207553",
                "rtid": "270042164",
                "refundable": true,
                "available_rooms": 2,
                "score": 345,
                "sno": "116",
                "cont": {
                    "_id": "600d4244a1bc371b80fe570f",
                    "name": "Hilton Garden Inn Dubai Al Muraqabat",
                    "ratings": "4.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.26475,
                            "longitude": 55.330002
                        }
                    },
                    "externalId": "11638229",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/12000000/11640000/11638300/11638229/34439aff_b.jpg",
                    "ha": [
                        "BAR",
                        "POOL",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.5",
                        "staff_service": "4.5",
                        "amenities": "3.9",
                        "conditions_facilities": "4.4"
                    },
                    "rat": "4.0",
                    "urat": "4.3",
                    "expediareview": 444,
                    "pid": "11638229",
                    "line1": "Abu Baker Al Siddique Road",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Reef Mall - 1 km / 0.6 mi "
                },
                "hdtype": "HOTELNHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 4077.96,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 308.7,
                "com_disc": 0,
                "rtf": "4078.00",
                "rdwtf": "4078.00"
            },
            {
                "tid": "JJn3AsBCgt0rG1eJ",
                "pid": "1971502",
                "hs": 0,
                "rmid": "211801258",
                "rtid": "232315198",
                "refundable": false,
                "available_rooms": 9,
                "score": 442,
                "sno": "114",
                "cont": {
                    "_id": "600d40d9a1bc371b80fc62da",
                    "name": "Holiday Inn Dubai - Al Barsha",
                    "ratings": "4.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.11659,
                            "longitude": 55.193665
                        }
                    },
                    "externalId": "1971502",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/2000000/1980000/1971600/1971502/d3255c41_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "POOL",
                        "BAR",
                        "POOL"
                    ],
                    "guestreview": {
                        "cleaniness": "4.4",
                        "staff_service": "4.4",
                        "amenities": "4.3",
                        "conditions_facilities": "4.3"
                    },
                    "rat": "4.0",
                    "urat": "4.3",
                    "expediareview": 518,
                    "pid": "1971502",
                    "line1": "Sheikh Zayed Road",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Fatima Abdullah Mohammed Rasheed Mosque - 0.5 km / 0.3 mi "
                },
                "hdtype": "HOTELNHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 4074.68,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 302.13,
                "com_disc": 0,
                "rtf": "4075.00",
                "rdwtf": "4075.00"
            },
            {
                "tid": "9i0NsTi0JyHwKkAq",
                "pid": "46129288",
                "hs": 0,
                "rmid": "219130145",
                "rtid": "280199978",
                "refundable": true,
                "available_rooms": 5,
                "score": 130,
                "sno": "109",
                "cont": {
                    "_id": "600d3ef3a1bc371b80f9c3bd",
                    "name": "TIME Express Hotel Al Khan",
                    "ratings": "3.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.329,
                            "longitude": 55.368
                        }
                    },
                    "externalId": "46129288",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/47000000/46130000/46129300/46129288/8cd51446_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "2.0",
                        "staff_service": "3.0",
                        "amenities": 0,
                        "conditions_facilities": "2.0"
                    },
                    "rat": "3.0",
                    "urat": "2.0",
                    "expediareview": 1,
                    "pid": "46129288",
                    "line1": "Al Khan",
                    "city": "Sharjah",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Sharjah Aquarium - 1.1 km / 0.7 mi "
                },
                "hdtype": "HOTELNHD",
                "usrst": "Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 4040.2,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 302.13,
                "com_disc": 0,
                "rtf": "4040.00",
                "rdwtf": "4040.00"
            },
            {
                "tid": "GcwO0i9v5grfzVUg",
                "pid": "2221509",
                "hs": 0,
                "rmid": "478907",
                "rtid": "1853636",
                "refundable": true,
                "available_rooms": 2147483647,
                "score": 352,
                "sno": "105",
                "cont": {
                    "_id": "600d40dca1bc371b80fc67ac",
                    "name": "ibis Deira City Centre",
                    "ratings": "3.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.253543,
                            "longitude": 55.332519
                        }
                    },
                    "externalId": "2221509",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/3000000/2230000/2221600/2221509/28c3d072_b.jpg",
                    "ha": [
                        "BAR",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.0",
                        "staff_service": "3.8",
                        "amenities": "3.7",
                        "conditions_facilities": "3.9"
                    },
                    "rat": "3.0",
                    "urat": "3.8",
                    "expediareview": 810,
                    "pid": "2221509",
                    "line1": "8th Str Port Saeed District F",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "City Centre Deira - 0.5 km / 0.3 mi "
                },
                "hdtype": "HOTELNHD",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 3970.41,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 251.23,
                "com_disc": 0,
                "rtf": "3970.00",
                "rdwtf": "3970.00"
            },
            {
                "tid": "A53PCouhMMdT5tqH",
                "pid": "21903961",
                "hs": 0,
                "rmid": "220234514",
                "rtid": "288221125",
                "refundable": false,
                "available_rooms": 5,
                "score": 343,
                "sno": "102",
                "cont": {
                    "_id": "600d410da1bc371b80fcac27",
                    "name": "Aloft Me'aisam, Dubai",
                    "ratings": "0.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.037931,
                            "longitude": 55.197168
                        }
                    },
                    "externalId": "21903961",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/22000000/21910000/21904000/21903961/cd3972b2_b.jpg",
                    "ha": [
                        "BAR",
                        "POOL",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.6",
                        "staff_service": "4.5",
                        "amenities": "3.7",
                        "conditions_facilities": "4.5"
                    },
                    "rat": "0.0",
                    "urat": "4.5",
                    "expediareview": 40,
                    "pid": "21903961",
                    "line1": "Dubai Production City",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Dubai Sports City - 3.8 km / 2.4 mi "
                },
                "hdtype": "HOTELNHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 3951.53,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 297.21,
                "com_disc": 0,
                "rtf": "3952.00",
                "rdwtf": "3952.00"
            },
            {
                "tid": "BfShWCRgGbxL6xHq",
                "pid": "804814",
                "hs": 0,
                "rmid": "33803",
                "rtid": "201642703",
                "refundable": false,
                "available_rooms": 15,
                "score": 240,
                "sno": "103",
                "cont": {
                    "_id": "600d3d5ba1bc371b80f7811e",
                    "name": "Regent Palace Hotel",
                    "ratings": "4.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.253484,
                            "longitude": 55.303741
                        }
                    },
                    "externalId": "804814",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/1000000/810000/804900/804814/5d991e65_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "3.5",
                        "staff_service": "3.4",
                        "amenities": "2.9",
                        "conditions_facilities": "3.2"
                    },
                    "rat": "4.0",
                    "urat": "3.3",
                    "expediareview": 401,
                    "pid": "804814",
                    "line1": "World Trade Centre Rd",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "BurJuman Mall - 0.4 km / 0.2 mi "
                },
                "hdtype": "HOTELNHD",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 3951.53,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 301.31,
                "com_disc": 0,
                "rtf": "3952.00",
                "rdwtf": "3952.00"
            },
            {
                "tid": "JPGhXo0LB0ulv6C5",
                "pid": "37141494",
                "hs": 0,
                "rmid": "221455340",
                "rtid": "296226015",
                "refundable": true,
                "available_rooms": 3,
                "score": 363,
                "sno": "97",
                "cont": {
                    "_id": "600d3fdca1bc371b80faf83a",
                    "name": "Hampton by Hilton Dubai Al Barsha",
                    "ratings": "0.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.11376,
                            "longitude": 55.193951
                        }
                    },
                    "externalId": "37141494",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/38000000/37150000/37141500/37141494/08b72439_b.jpg",
                    "ha": [
                        "BAR",
                        "POOL",
                        "WIFI",
                        "GYM"
                    ],
                    "guestreview": {
                        "cleaniness": "4.6",
                        "staff_service": "4.6",
                        "amenities": "4.3",
                        "conditions_facilities": "4.5"
                    },
                    "rat": "0.0",
                    "urat": "4.4",
                    "expediareview": 117,
                    "pid": "37141494",
                    "line1": "Al Barsha 1 Street",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Fatima Abdullah Mohammed Rasheed Mosque - 0.1 km / 0.1 mi "
                },
                "hdtype": "HOTELNHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Breakfast"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 3885.02,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 302.13,
                "com_disc": 0,
                "rtf": "3885.00",
                "rdwtf": "3885.00"
            },
            {
                "tid": "hkK6Hk4sJmv6PBGZ",
                "pid": "3236020",
                "hs": 0,
                "rmid": "460601",
                "rtid": "242296714",
                "refundable": false,
                "available_rooms": 9,
                "score": 411,
                "sno": "92",
                "cont": {
                    "_id": "600d422da1bc371b80fe372e",
                    "name": "Holiday Inn Express Dubai Airport",
                    "ratings": "2.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.242287,
                            "longitude": 55.359924
                        }
                    },
                    "externalId": "3236020",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/4000000/3240000/3236100/3236020/ce07365c_b.jpg",
                    "ha": [
                        "BAR",
                        "WIFI",
                        "GYM"
                    ],
                    "guestreview": {
                        "cleaniness": "4.1",
                        "staff_service": "4.1",
                        "amenities": "3.8",
                        "conditions_facilities": "3.9"
                    },
                    "rat": "2.0",
                    "urat": "3.9",
                    "expediareview": 1188,
                    "pid": "3236020",
                    "line1": "Opp. Dubai Int'l Airport Terminal 3",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Dubai Festival City Mall - 2.9 km / 1.8 mi "
                },
                "hdtype": "HOTELNHD",
                "usrst": "Very Good",
                "brdbs": [
                    "Breakfast"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 3833.3,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 284.07,
                "com_disc": 0,
                "rtf": "3833.00",
                "rdwtf": "3833.00"
            },
            {
                "tid": "sFQNwU7CEqqUzBBU",
                "pid": "1632097",
                "hs": 0,
                "rmid": "200492964",
                "rtid": "381652609",
                "refundable": false,
                "available_rooms": 4,
                "score": 125,
                "sno": "88",
                "cont": {
                    "_id": "62506a622aee3e1bb50b665b",
                    "ratings": "3.0",
                    "name": "Nejoum Al Emarate",
                    "location": {
                        "coordinates": {
                            "latitude": 25.354013,
                            "longitude": 55.389847
                        },
                        "obfuscationRequired": false
                    },
                    "externalId": "1632097",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/2000000/1640000/1632100/1632097/44d2f119_b.jpg",
                    "ha": [
                        "GYM",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "3.5",
                        "staff_service": "3.6",
                        "amenities": "2.7",
                        "conditions_facilities": "3.5"
                    },
                    "rat": "3.0",
                    "urat": "3.6",
                    "expediareview": 81,
                    "pid": "1632097",
                    "line1": "Al Qasmiya Street Sharjah",
                    "city": "Sharjah",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Rolla Mall - 0.7 km / 0.4 mi "
                },
                "hdtype": "HOTELNHD",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 3787.32,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 283.25,
                "com_disc": 0,
                "rtf": "3787.00",
                "rdwtf": "3787.00"
            },
            {
                "tid": "en0T2ZTp56CMVUv4",
                "pid": "32070004",
                "hs": 0,
                "rmid": "216328693",
                "rtid": "262310390",
                "refundable": true,
                "available_rooms": 7,
                "score": 426,
                "sno": "87",
                "cont": {
                    "_id": "600d3fdda1bc371b80faf938",
                    "name": "Premier Inn Dubai Al Jaddaf",
                    "ratings": "3.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.221896,
                            "longitude": 55.329611
                        }
                    },
                    "externalId": "32070004",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/33000000/32080000/32070100/32070004/512ea84e_b.jpg",
                    "ha": [
                        "BAR",
                        "GYM",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.7",
                        "staff_service": "4.6",
                        "amenities": "4.3",
                        "conditions_facilities": "4.7"
                    },
                    "rat": "3.0",
                    "urat": "4.6",
                    "expediareview": 208,
                    "pid": "32070004",
                    "line1": "Culture Village, Al Jaddaf",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Wafi City Mall - 3.4 km / 2.1 mi "
                },
                "hdtype": "HOTELNHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 3785.68,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 288.17,
                "com_disc": 0,
                "rtf": "3786.00",
                "rdwtf": "3786.00"
            },
            {
                "tid": "cwqTM4vXDAlTOwd8",
                "pid": "6438114",
                "hs": 0,
                "rmid": "200377827",
                "rtid": "248043735",
                "refundable": true,
                "available_rooms": 1,
                "score": 427,
                "sno": "82",
                "cont": {
                    "_id": "600d4134a1bc371b80fcd6f1",
                    "name": "DoubleTree by Hilton Hotel and Residences Dubai Al Barsha",
                    "ratings": "4.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.114411,
                            "longitude": 55.20096
                        }
                    },
                    "externalId": "6438114",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/7000000/6440000/6438200/6438114/df5f1beb_b.jpg",
                    "ha": [
                        "BAR",
                        "POOL",
                        "POOL",
                        "POOL"
                    ],
                    "guestreview": {
                        "cleaniness": "4.3",
                        "staff_service": "4.3",
                        "amenities": "4.1",
                        "conditions_facilities": "4.2"
                    },
                    "rat": "4.0",
                    "urat": "4.2",
                    "expediareview": 682,
                    "pid": "6438114",
                    "line1": "Al Barsha - 1",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Fatima Abdullah Mohammed Rasheed Mosque - 0.9 km / 0.5 mi "
                },
                "hdtype": "HOTELNHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 3759.41,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 284.07,
                "com_disc": 0,
                "rtf": "3759.00",
                "rdwtf": "3759.00"
            },
            {
                "tid": "S10TT3qCIdXvhaJJ",
                "pid": "39638313",
                "hs": 0,
                "rmid": "216965902",
                "rtid": "266307861",
                "refundable": true,
                "available_rooms": 135,
                "score": 222,
                "sno": "83",
                "cont": {
                    "_id": "600d3fa5a1bc371b80fab77b",
                    "name": "Premier Inn Dubai Dragon Mart",
                    "ratings": "3.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.175211,
                            "longitude": 55.416738
                        }
                    },
                    "externalId": "39638313",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/40000000/39640000/39638400/39638313/10ad6ee3_b.jpg",
                    "ha": [
                        "BAR",
                        "WIFI",
                        "GYM"
                    ],
                    "guestreview": {
                        "cleaniness": "4.5",
                        "staff_service": "4.4",
                        "amenities": "4.2",
                        "conditions_facilities": "4.5"
                    },
                    "rat": "3.0",
                    "urat": "4.4",
                    "expediareview": 60,
                    "pid": "39638313",
                    "line1": "Al Awir Road",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Dragon Mart - 0.3 km / 0.2 mi "
                },
                "hdtype": "HOTELNHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 3759.41,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 285.71,
                "com_disc": 0,
                "rtf": "3759.00",
                "rdwtf": "3759.00"
            },
            {
                "tid": "b5ZD1DKi6w5WnFmt",
                "pid": "114530",
                "hs": 0,
                "rmid": "212711750",
                "rtid": "238261489",
                "refundable": false,
                "available_rooms": 5,
                "score": 208,
                "sno": "78",
                "cont": {
                    "_id": "600d4221a1bc371b80fe2574",
                    "name": "Mayfair Hotel",
                    "ratings": "3.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.260833,
                            "longitude": 55.322285
                        }
                    },
                    "externalId": "114530",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/1000000/120000/114600/114530/84a30d98_b.jpg",
                    "ha": [
                        "BAR",
                        "POOL",
                        "WIFI",
                        "GYM"
                    ],
                    "guestreview": {
                        "cleaniness": "3.3",
                        "staff_service": "3.1",
                        "amenities": "2.0",
                        "conditions_facilities": "3.1"
                    },
                    "rat": "3.0",
                    "urat": "3.1",
                    "expediareview": 199,
                    "pid": "114530",
                    "line1": "Rigga Al Buteen Deira",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Al Ghurair Centre - 1.3 km / 0.8 mi "
                },
                "hdtype": "HOTELNHD",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 3743.81,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 285.71,
                "com_disc": 0,
                "rtf": "3744.00",
                "rdwtf": "3744.00"
            },
            {
                "tid": "Nv3KpD6q5i2BfuDB",
                "pid": "9646284",
                "hs": 0,
                "rmid": "200861318",
                "rtid": "204367987",
                "refundable": true,
                "available_rooms": 1,
                "score": 139,
                "sno": "77",
                "cont": {
                    "_id": "600d3d8da1bc371b80f7c869",
                    "name": "West Hotel",
                    "ratings": "1.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.273206,
                            "longitude": 55.310449
                        }
                    },
                    "externalId": "9646284",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/10000000/9650000/9646300/9646284/cb632914_b.jpg",
                    "ha": [
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "2.4",
                        "staff_service": "2.6",
                        "amenities": 0,
                        "conditions_facilities": "2.1"
                    },
                    "rat": "1.0",
                    "urat": "2.5",
                    "expediareview": 68,
                    "pid": "9646284",
                    "line1": "Naif Road, Naif, Deira",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Naif Souq - 0.8 km / 0.5 mi "
                },
                "hdtype": "HOTELNHD",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 3731.49,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 286.53,
                "com_disc": 0,
                "rtf": "3731.00",
                "rdwtf": "3731.00"
            },
            {
                "tid": "VFdLlVa9bZ8ztTKb",
                "pid": "27507087",
                "hs": 0,
                "rmid": "320857932",
                "rtid": "387351129",
                "refundable": true,
                "available_rooms": 5,
                "score": 293,
                "sno": "76",
                "cont": {
                    "_id": "600d4048a1bc371b80fb8d1f",
                    "name": "Maisan Hotel",
                    "ratings": "4.0",
                    "location": {
                        "coordinates": {
                            "latitude": 24.978981,
                            "longitude": 55.17622
                        }
                    },
                    "externalId": "27507087",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/28000000/27510000/27507100/27507087/d222aacf_b.jpg",
                    "ha": [
                        "BAR",
                        "POOL",
                        "POOL",
                        "BAR",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.5",
                        "staff_service": "4.2",
                        "amenities": "5.0",
                        "conditions_facilities": "4.4"
                    },
                    "rat": "4.0",
                    "urat": "4.4",
                    "expediareview": 14,
                    "pid": "27507087",
                    "line1": "Dubai Investment Park",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Dubai Expo 2020 - 7.8 km / 4.8 mi "
                },
                "hdtype": "HOTELNHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 3711.79,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 229.88,
                "com_disc": 0,
                "rtf": "3712.00",
                "rdwtf": "3712.00"
            },
            {
                "tid": "eMeLrqiTQV6Dm2th",
                "pid": "1235808",
                "hs": 0,
                "rmid": "483171",
                "rtid": "232001285",
                "refundable": true,
                "available_rooms": 34,
                "score": 313,
                "sno": "66",
                "cont": {
                    "_id": "600d3e7ca1bc371b80f92d03",
                    "name": "Coral Dubai Deira Hotel",
                    "ratings": "4.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.266035,
                            "longitude": 55.32523
                        }
                    },
                    "externalId": "1235808",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/2000000/1240000/1235900/1235808/cca82793_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.3",
                        "staff_service": "4.2",
                        "amenities": "3.9",
                        "conditions_facilities": "4.2"
                    },
                    "rat": "4.0",
                    "urat": "4.2",
                    "expediareview": 756,
                    "pid": "1235808",
                    "line1": "Al Muraqqabat Street Deira",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Reef Mall - 0.5 km / 0.3 mi "
                },
                "hdtype": "HOTELNHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 3622.3,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 276.68,
                "com_disc": 0,
                "rtf": "3622.00",
                "rdwtf": "3622.00"
            },
            {
                "tid": "t0e9rLtHTiP34AVK",
                "pid": "3124140",
                "hs": 0,
                "rmid": "213912884",
                "rtid": "246145721",
                "refundable": false,
                "available_rooms": 4,
                "score": 316,
                "sno": "58",
                "cont": {
                    "_id": "600d424ea1bc371b80fe63e6",
                    "name": "Premier Inn Dubai Investment Park",
                    "ratings": "3.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.007992,
                            "longitude": 55.15697
                        }
                    },
                    "externalId": "3124140",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/4000000/3130000/3124200/3124140/74243ba9_b.jpg",
                    "ha": [
                        "BAR",
                        "POOL",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.4",
                        "staff_service": "4.4",
                        "amenities": "4.1",
                        "conditions_facilities": "4.2"
                    },
                    "rat": "3.0",
                    "urat": "4.2",
                    "expediareview": 597,
                    "pid": "3124140",
                    "line1": "Sheikh Mohammed Bin Zayed Rd Dubai",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Jumeirah Golf Estates - 10.9 km / 6.7 mi "
                },
                "hdtype": "HOTELNHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 3586.18,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 272.58,
                "com_disc": 0,
                "rtf": "3586.00",
                "rdwtf": "3586.00"
            },
            {
                "tid": "sCxvWk0SERJOI0Dr",
                "pid": "12997370",
                "hs": 0,
                "rmid": "219307433",
                "rtid": "282027362",
                "refundable": true,
                "available_rooms": 2147483647,
                "score": 174,
                "sno": "54",
                "cont": {
                    "_id": "600d3faba1bc371b80fabe66",
                    "name": "ibis styles Sharjah Hotel",
                    "ratings": "3.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.33823,
                            "longitude": 55.38924
                        }
                    },
                    "externalId": "12997370",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/13000000/13000000/12997400/12997370/c5e4e9a5_b.jpg",
                    "ha": [
                        "WIFI",
                        "GYM"
                    ],
                    "guestreview": {
                        "cleaniness": "3.8",
                        "staff_service": "3.8",
                        "amenities": "3.8",
                        "conditions_facilities": "3.8"
                    },
                    "rat": "3.0",
                    "urat": "3.8",
                    "expediareview": 86,
                    "pid": "12997370",
                    "line1": "King Faisal Street",
                    "city": "Sharjah",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Crystal Plaza - 1.1 km / 0.7 mi "
                },
                "hdtype": "HOTELNHD",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 3505.72,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 184.73,
                "com_disc": 0,
                "rtf": "3506.00",
                "rdwtf": "3506.00"
            },
            {
                "tid": "ZvnqyEpWF1fVzIuX",
                "pid": "16620981",
                "hs": 0,
                "rmid": "219935396",
                "rtid": "286387760",
                "refundable": false,
                "available_rooms": 11,
                "score": 341,
                "sno": "52",
                "cont": {
                    "_id": "600d4153a1bc371b80fcf8e8",
                    "name": "Grandeur Hotel",
                    "ratings": "4.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.109438,
                            "longitude": 55.202926
                        }
                    },
                    "externalId": "16620981",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/17000000/16630000/16621000/16620981/cb2d35dc_b.jpg",
                    "ha": [
                        "BAR",
                        "GYM",
                        "POOL",
                        "POOL",
                        "BAR"
                    ],
                    "guestreview": {
                        "cleaniness": "4.1",
                        "staff_service": "4.0",
                        "amenities": "4.2",
                        "conditions_facilities": "4.0"
                    },
                    "rat": "4.0",
                    "urat": "4.0",
                    "expediareview": 46,
                    "pid": "16620981",
                    "line1": "Al BARSHA 1, Behind Mall of the Emirates",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Fatima Abdullah Mohammed Rasheed Mosque - 1.4 km / 0.8 mi "
                },
                "hdtype": "HOTELNHD",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 3467.95,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 265.19,
                "com_disc": 0,
                "rtf": "3468.00",
                "rdwtf": "3468.00"
            },
            {
                "tid": "1wQPia8QR8RSJqfw",
                "pid": "9988395",
                "hs": 0,
                "rmid": "200907149",
                "rtid": "204618765",
                "refundable": false,
                "available_rooms": 10,
                "score": 182,
                "sno": "49",
                "cont": {
                    "_id": "600d3d88a1bc371b80f7c24f",
                    "name": "Mark Inn Hotel Deira",
                    "ratings": "2.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.270345,
                            "longitude": 55.309885
                        }
                    },
                    "externalId": "9988395",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/10000000/9990000/9988400/9988395/0a93264a_b.jpg",
                    "ha": [
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.3",
                        "staff_service": "4.3",
                        "amenities": "3.4",
                        "conditions_facilities": "4.0"
                    },
                    "rat": "2.0",
                    "urat": "4.1",
                    "expediareview": 143,
                    "pid": "9988395",
                    "line1": "Al Maktoum Hospital Street",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Fish Roundabout - 0.7 km / 0.5 mi "
                },
                "hdtype": "HOTELNHD",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 3449.89,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 264.37,
                "com_disc": 0,
                "rtf": "3450.00",
                "rdwtf": "3450.00"
            },
            {
                "tid": "28F0fDnAMZ3Gbytr",
                "pid": "9074437",
                "hs": 0,
                "rmid": "200785442",
                "rtid": "390732238",
                "refundable": false,
                "available_rooms": 20,
                "score": 204,
                "sno": "43",
                "cont": {
                    "_id": "600d3da8a1bc371b80f7fdfb",
                    "name": "Fortune Hotel Deira",
                    "ratings": "3.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.274556,
                            "longitude": 55.315629
                        }
                    },
                    "externalId": "9074437",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/10000000/9080000/9074500/9074437/6eb98ef1_b.jpg",
                    "ha": [
                        "BAR",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "3.1",
                        "staff_service": "3.1",
                        "amenities": "3.4",
                        "conditions_facilities": "3.0"
                    },
                    "rat": "3.0",
                    "urat": "3.0",
                    "expediareview": 66,
                    "pid": "9074437",
                    "line1": "Omar Al Khattab Street",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Fish Roundabout - 0.5 km / 0.3 mi "
                },
                "hdtype": "HOTELNHD",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 3369.43,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 256.98,
                "com_disc": 0,
                "rtf": "3369.00",
                "rdwtf": "3369.00"
            },
            {
                "tid": "vea12znEgdT6M4XD",
                "pid": "12946728",
                "hs": 0,
                "rmid": "201381179",
                "rtid": "206835742",
                "refundable": true,
                "available_rooms": 2147483647,
                "score": 583,
                "sno": "36",
                "cont": {
                    "_id": "600d439ea1bc371b80000e11",
                    "name": "ibis One Central Hotel",
                    "ratings": "3.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.221643,
                            "longitude": 55.286558
                        }
                    },
                    "externalId": "12946728",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/13000000/12950000/12946800/12946728/fc10c41c_b.jpg",
                    "ha": [
                        "BAR",
                        "GYM",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.3",
                        "staff_service": "4.0",
                        "amenities": "4.0",
                        "conditions_facilities": "4.1"
                    },
                    "rat": "3.0",
                    "urat": "4.0",
                    "expediareview": 626,
                    "pid": "12946728",
                    "line1": "Trade Centre District",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Dubai International Convention and Exhibition Centre - 0.5 km / 0.3 mi "
                },
                "hdtype": "HOTELNHD",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 3258.59,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 206.07,
                "com_disc": 0,
                "rtf": "3259.00",
                "rdwtf": "3259.00"
            },
            {
                "tid": "eudHDAX3s5UkwFa7",
                "pid": "2266468",
                "hs": 0,
                "rmid": "479095",
                "rtid": "1854709",
                "refundable": true,
                "available_rooms": 2147483647,
                "score": 410,
                "sno": "37",
                "cont": {
                    "_id": "600d3dc0a1bc371b80f8225d",
                    "name": "ibis Al Barsha",
                    "ratings": "3.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.109076,
                            "longitude": 55.182977
                        }
                    },
                    "externalId": "2266468",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/3000000/2270000/2266500/2266468/fa0af338_b.jpg",
                    "ha": [
                        "BAR",
                        "GYM",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "3.7",
                        "staff_service": "3.7",
                        "amenities": "3.5",
                        "conditions_facilities": "3.5"
                    },
                    "rat": "3.0",
                    "urat": "3.5",
                    "expediareview": 566,
                    "pid": "2266468",
                    "line1": "Sheikh Zayed Road",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Fatima Abdullah Mohammed Rasheed Mosque - 1.5 km / 0.9 mi "
                },
                "hdtype": "HOTELNHD",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 3258.59,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 206.07,
                "com_disc": 0,
                "rtf": "3259.00",
                "rdwtf": "3259.00"
            },
            {
                "tid": "gssCPDysnvdi80Dv",
                "pid": "3084786",
                "hs": 0,
                "rmid": "200020367",
                "rtid": "200097702",
                "refundable": true,
                "available_rooms": 2147483647,
                "score": 325,
                "sno": "34",
                "cont": {
                    "_id": "600d437ca1bc371b80ffe14a",
                    "name": "ibis Al Rigga",
                    "ratings": "3.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.264835,
                            "longitude": 55.321093
                        }
                    },
                    "externalId": "3084786",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/4000000/3090000/3084800/3084786/ecc5eb0b_b.jpg",
                    "ha": [
                        "BAR",
                        "GYM",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.1",
                        "staff_service": "4.0",
                        "amenities": "3.9",
                        "conditions_facilities": "4.0"
                    },
                    "rat": "3.0",
                    "urat": "4.0",
                    "expediareview": 796,
                    "pid": "3084786",
                    "line1": "Al Rigga Road, Deira",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Al Ghurair Centre - 0.5 km / 0.3 mi "
                },
                "hdtype": "HOTELNHD",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 3231.5,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 204.43,
                "com_disc": 0,
                "rtf": "3231.00",
                "rdwtf": "3232.00"
            },
            {
                "tid": "fhTZG57TZf9quHAp",
                "pid": "2301578",
                "hs": 0,
                "rmid": "479109",
                "rtid": "1854813",
                "refundable": true,
                "available_rooms": 2147483647,
                "score": 429,
                "sno": "31",
                "cont": {
                    "_id": "600d42f8a1bc371b80ff4cd4",
                    "name": "ibis Dubai Mall of the Emirates Hotel",
                    "ratings": "3.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.116723,
                            "longitude": 55.195991
                        }
                    },
                    "externalId": "2301578",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/3000000/2310000/2301600/2301578/75ca8ff7_b.jpg",
                    "ha": [
                        "BAR",
                        "POOL",
                        "POOL",
                        "BAR",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.0",
                        "staff_service": "4.0",
                        "amenities": "3.5",
                        "conditions_facilities": "3.9"
                    },
                    "rat": "3.0",
                    "urat": "3.8",
                    "expediareview": 665,
                    "pid": "2301578",
                    "line1": "2 A Street",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Fatima Abdullah Mohammed Rasheed Mosque - 0.6 km / 0.4 mi "
                },
                "hdtype": "HOTELNHD",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 3203.58,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 202.79,
                "com_disc": 0,
                "rtf": "3204.00",
                "rdwtf": "3204.00"
            },
            {
                "tid": "H1oKTrSWwz6E0aoW",
                "pid": "10073918",
                "hs": 0,
                "rmid": "200911457",
                "rtid": "280060840",
                "refundable": false,
                "available_rooms": 10,
                "score": 153,
                "sno": "29",
                "cont": {
                    "_id": "600d40b8a1bc371b80fc2f2e",
                    "name": "Grand Sina Hotel",
                    "ratings": "1.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.269718,
                            "longitude": 55.302166
                        }
                    },
                    "externalId": "10073918",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/11000000/10080000/10074000/10073918/4a0a7b09_b.jpg",
                    "ha": [
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "2.7",
                        "staff_service": "3.0",
                        "amenities": "2.5",
                        "conditions_facilities": "2.7"
                    },
                    "rat": "1.0",
                    "urat": "2.8",
                    "expediareview": 37,
                    "pid": "10073918",
                    "line1": "Al Sabakha Street",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Naif Souq - 0.4 km / 0.2 mi "
                },
                "hdtype": "HOTELNHD",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 3160.07,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 201.15,
                "com_disc": 0,
                "rtf": "3160.00",
                "rdwtf": "3160.00"
            },
            {
                "tid": "S2qetMNwKF4ubgPT",
                "pid": "75189394",
                "hs": 0,
                "rmid": "316282462",
                "rtid": "390875511",
                "refundable": false,
                "available_rooms": 25,
                "score": 84,
                "sno": "14",
                "cont": {
                    "_id": "62506b5b2aee3e1bb50b6887",
                    "ratings": "0.0",
                    "name": "Pearl Swiss Hotel",
                    "location": {
                        "coordinates": {
                            "latitude": 25.264335,
                            "longitude": 55.3196
                        },
                        "obfuscatedCoordinates": {
                            "latitude": 25.269974,
                            "longitude": 55.315244
                        },
                        "obfuscationRequired": false
                    },
                    "externalId": "75189394",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/76000000/75190000/75189400/75189394/4c844d15_b.jpg",
                    "ha": [
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": 0,
                        "staff_service": 0,
                        "amenities": 0,
                        "conditions_facilities": 0
                    },
                    "rat": "0.0",
                    "urat": "",
                    "expediareview": 0,
                    "pid": "75189394",
                    "line1": "Behind Commercial Bank Building",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Al Ghurair Centre - 0.5 km / 0.3 mi "
                },
                "hdtype": "HOTELNHD",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 2978.63,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 255.33,
                "com_disc": 0,
                "rtf": "2979.00",
                "rdwtf": "2979.00"
            },
            {
                "tid": "6P79ZD2e6VS3yzqc",
                "pid": "1723385",
                "hs": 0,
                "rmid": "201836237",
                "rtid": "244111014",
                "refundable": false,
                "available_rooms": 10,
                "score": 231,
                "sno": "10",
                "cont": {
                    "_id": "600d4265a1bc371b80fe81f5",
                    "name": "Nihal Hotel",
                    "ratings": "3.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.260147,
                            "longitude": 55.324649
                        }
                    },
                    "externalId": "1723385",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/2000000/1730000/1723400/1723385/eb514a57_b.jpg",
                    "ha": [
                        "BAR",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "3.2",
                        "staff_service": "3.2",
                        "amenities": "2.8",
                        "conditions_facilities": "2.8"
                    },
                    "rat": "3.0",
                    "urat": "3.1",
                    "expediareview": 97,
                    "pid": "1723385",
                    "line1": "Between Al Rigga and Al Maktoum Road",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hotel",
                    "nearby": "Al Ghurair Centre - 1.3 km / 0.8 mi "
                },
                "hdtype": "HOTELNHD",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 2931.83,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 223.31,
                "com_disc": 0,
                "rtf": "2932.00",
                "rdwtf": "2932.00"
            },
            {
                "tid": "EMINkFWLTBxLfpFd",
                "pid": "48914671",
                "hs": 0,
                "rmid": "230130627",
                "rtid": "274312502",
                "refundable": false,
                "available_rooms": 1,
                "score": 24,
                "sno": "613",
                "cont": {
                    "_id": "600d41aba1bc371b80fd71bd",
                    "name": "Maison Privee Frond Villa O",
                    "ratings": "0.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.11825,
                            "longitude": 55.120572
                        }
                    },
                    "externalId": "48914671",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/49000000/48920000/48914700/48914671/a45e9524_b.jpg",
                    "ha": [],
                    "guestreview": {
                        "cleaniness": 0,
                        "staff_service": 0,
                        "amenities": 0,
                        "conditions_facilities": 0
                    },
                    "rat": "0.0",
                    "urat": "",
                    "expediareview": 0,
                    "pid": "48914671",
                    "line1": "Al Bateen, Dubai Marina",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Villa",
                    "nearby": "Aquaventure - 4.4 km / 2.7 mi "
                },
                "hdtype": "HOTDEAL",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 67733.41,
                "dwtf": 218372.51,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 6643.62,
                "com_disc": 0,
                "rtf": "150639.00",
                "rdwtf": "218373.00"
            },
            {
                "tid": "TaTu4TVIunPL7P68",
                "pid": "486249",
                "hs": 0,
                "rmid": "321722594",
                "rtid": "390295737",
                "refundable": false,
                "available_rooms": 11,
                "score": 542,
                "sno": "521",
                "cont": {
                    "_id": "600d3dd4a1bc371b80f83523",
                    "name": "JA Oasis Beach Tower",
                    "ratings": "5.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.080065,
                            "longitude": 55.136122
                        }
                    },
                    "externalId": "486249",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/1000000/490000/486300/486249/be1d1259_b.jpg",
                    "ha": [
                        "BAR",
                        "GYM",
                        "POOL",
                        "BAR",
                        "POOL"
                    ],
                    "guestreview": {
                        "cleaniness": "4.6",
                        "staff_service": "4.6",
                        "amenities": "4.4",
                        "conditions_facilities": "4.6"
                    },
                    "rat": "5.0",
                    "urat": "4.6",
                    "expediareview": 1162,
                    "pid": "486249",
                    "line1": "The Walk, Jumeirah Beach Residence",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Aparthotel",
                    "nearby": "The Walk - 0.1 km / 0.1 mi "
                },
                "hdtype": "HOTDEAL",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 32265.73,
                "dwtf": 49173.63,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 1290.63,
                "com_disc": 0,
                "rtf": "16908.00",
                "rdwtf": "49174.00"
            },
            {
                "tid": "puaKuARLgn6g9KR6",
                "pid": "18926712",
                "hs": 0,
                "rmid": "202029490",
                "rtid": "286334996",
                "refundable": false,
                "available_rooms": 1,
                "score": 82,
                "sno": "598",
                "cont": {
                    "_id": "600d4199a1bc371b80fd51b0",
                    "name": "Dream Inn Dubai - City Walk",
                    "ratings": "0.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.203457,
                            "longitude": 55.261367
                        }
                    },
                    "externalId": "18926712",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/19000000/18930000/18926800/18926712/1cf28434_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.0",
                        "staff_service": "3.2",
                        "amenities": "2.0",
                        "conditions_facilities": "4.0"
                    },
                    "rat": "0.0",
                    "urat": "3.6",
                    "expediareview": 5,
                    "pid": "18926712",
                    "line1": "42A, Building 12, Al Safa Street",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Apartment",
                    "nearby": "City Walk - 0.9 km / 0.5 mi "
                },
                "hdtype": "HOTDEAL",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 22742,
                "dwtf": 64889.43,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 3137.9,
                "com_disc": 0,
                "rtf": "42147.00",
                "rdwtf": "64889.00"
            },
            {
                "tid": "a7GOqDMrOGX6tLES",
                "pid": "62998293",
                "hs": 0,
                "rmid": "232873263",
                "rtid": "292420744",
                "refundable": false,
                "available_rooms": 1,
                "score": 21,
                "sno": "601",
                "cont": {
                    "_id": "62506b862aee3e1bb50b6933",
                    "ratings": "0.0",
                    "name": "Private Pool Villas by Simply Comfort",
                    "location": {
                        "coordinates": {
                            "latitude": 25.140596,
                            "longitude": 55.136983
                        },
                        "obfuscationRequired": false
                    },
                    "externalId": "62998293",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/63000000/63000000/62998300/62998293/19fd8b50_b.jpg",
                    "ha": [
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": 0,
                        "staff_service": 0,
                        "amenities": 0,
                        "conditions_facilities": 0
                    },
                    "rat": "0.0",
                    "urat": "3.0",
                    "expediareview": 1,
                    "pid": "62998293",
                    "line1": "Crescent Rd",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Villa",
                    "nearby": "Aquaventure - 2.3 km / 1.4 mi "
                },
                "hdtype": "HOTDEAL",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 19293.76,
                "dwtf": 64148.87,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 2383.39,
                "com_disc": 0,
                "rtf": "44855.00",
                "rdwtf": "64149.00"
            },
            {
                "tid": "UH3hPf3XcdwAoJ4R",
                "pid": "18927536",
                "hs": 0,
                "rmid": "202053571",
                "rtid": "286064490",
                "refundable": false,
                "available_rooms": 2,
                "score": 75,
                "sno": "587",
                "cont": {
                    "_id": "600d3f38a1bc371b80fa2c11",
                    "name": "Dream Inn Dubai Apartments - Burj Views",
                    "ratings": "0.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.190889,
                            "longitude": 55.281291
                        }
                    },
                    "externalId": "18927536",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/19000000/18930000/18927600/18927536/db951a58_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL"
                    ],
                    "guestreview": {
                        "cleaniness": "4.0",
                        "staff_service": "4.0",
                        "amenities": "4.0",
                        "conditions_facilities": "3.4"
                    },
                    "rat": "0.0",
                    "urat": "3.4",
                    "expediareview": 5,
                    "pid": "18927536",
                    "line1": "Fountain Street",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Apartment",
                    "nearby": "Dubai Water Canal - 0.9 km / 0.5 mi "
                },
                "hdtype": "HOTDEAL",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 18390.65,
                "dwtf": 52524.18,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 2541.85,
                "com_disc": 0,
                "rtf": "34134.00",
                "rdwtf": "52524.00"
            },
            {
                "tid": "l0L9ofbI7oJTEgbk",
                "pid": "57821246",
                "hs": 0,
                "rmid": "227147078",
                "rtid": "385184975",
                "refundable": true,
                "available_rooms": 16,
                "score": 394,
                "sno": "574",
                "cont": {
                    "_id": "600d3dfba1bc371b80f861f3",
                    "name": "Address Beach Resort",
                    "ratings": "0.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.072556,
                            "longitude": 55.126544
                        }
                    },
                    "externalId": "57821246",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/58000000/57830000/57821300/57821246/1e8dc9a0_b.jpg",
                    "ha": [
                        "BAR",
                        "GYM",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": 0,
                        "staff_service": 0,
                        "amenities": 0,
                        "conditions_facilities": 0
                    },
                    "rat": "0.0",
                    "urat": "",
                    "expediareview": 0,
                    "pid": "57821246",
                    "line1": "The Walk, Jumeirah Beach Residences",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Resort",
                    "nearby": "The Walk - 0.2 km / 0.2 mi "
                },
                "hdtype": "HOTDEAL",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 14449.79,
                "dwtf": 40881.42,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 2022.15,
                "com_disc": 0,
                "rtf": "26432.00",
                "rdwtf": "40881.00"
            },
            {
                "tid": "HNqXgKmtJbeh0LvE",
                "pid": "2143430",
                "hs": 0,
                "rmid": "277464",
                "rtid": "804159",
                "refundable": true,
                "available_rooms": 1,
                "score": 121,
                "sno": "596",
                "cont": {
                    "_id": "600d42f0a1bc371b80ff42b3",
                    "name": "Arabian Dreams Deluxe Hotel Apartments",
                    "ratings": "0.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.249642,
                            "longitude": 55.286266
                        }
                    },
                    "externalId": "2143430",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/3000000/2150000/2143500/2143430/e9a7e6a3_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "3.7",
                        "staff_service": "3.5",
                        "amenities": "4.0",
                        "conditions_facilities": "3.4"
                    },
                    "rat": "0.0",
                    "urat": "3.4",
                    "expediareview": 84,
                    "pid": "2143430",
                    "line1": "Mankhool, Street 24C",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Apartment",
                    "nearby": "Meena Bazaar - 1.9 km / 1.2 mi "
                },
                "hdtype": "HOTDEAL",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 13875.09,
                "dwtf": 52883.78,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 2954,
                "com_disc": 0,
                "rtf": "39009.00",
                "rdwtf": "52884.00"
            },
            {
                "tid": "8o6m3s7CMevzuWT0",
                "pid": "4849400",
                "hs": 0,
                "rmid": "200324067",
                "rtid": "262275968",
                "refundable": false,
                "available_rooms": 8,
                "score": 430,
                "sno": "370",
                "cont": {
                    "_id": "600d40cca1bc371b80fc4e1f",
                    "name": "City Premiere Hotel Apartment",
                    "ratings": "4.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.193283,
                            "longitude": 55.262678
                        }
                    },
                    "externalId": "4849400",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/5000000/4850000/4849400/4849400/f923c1ea_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "POOL",
                        "BAR",
                        "POOL"
                    ],
                    "guestreview": {
                        "cleaniness": "4.1",
                        "staff_service": "4.0",
                        "amenities": "3.8",
                        "conditions_facilities": "4.0"
                    },
                    "rat": "4.0",
                    "urat": "4.0",
                    "expediareview": 426,
                    "pid": "4849400",
                    "line1": "204 Sheikh Zayed Rd",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Aparthotel",
                    "nearby": "Dubai Opera - 1.4 km / 0.9 mi "
                },
                "hdtype": "HOTDEAL",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 12397.27,
                "dwtf": 20448.1,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 614.12,
                "com_disc": 0,
                "rtf": "8051.00",
                "rdwtf": "20448.00"
            },
            {
                "tid": "T24Ev2CTRV3JsOsm",
                "pid": "1109595",
                "hs": 0,
                "rmid": "200237017",
                "rtid": "391638542",
                "refundable": true,
                "available_rooms": 11,
                "score": 595,
                "sno": "546",
                "cont": {
                    "_id": "600d3f03a1bc371b80f9d778",
                    "name": "JA Palm Tree Court",
                    "ratings": "5.0",
                    "location": {
                        "coordinates": {
                            "latitude": 24.98646,
                            "longitude": 55.022046
                        }
                    },
                    "externalId": "1109595",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/2000000/1110000/1109600/1109595/c7686a25_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.7",
                        "staff_service": "4.7",
                        "amenities": "4.4",
                        "conditions_facilities": "4.6"
                    },
                    "rat": "5.0",
                    "urat": "4.6",
                    "expediareview": 390,
                    "pid": "1109595",
                    "line1": "Within JA The Resort",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Resort",
                    "nearby": "MOTIONGATE™ Dubai - 17.7 km / 11 mi "
                },
                "hdtype": "HOTDEAL",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 11740.46,
                "dwtf": 31593.32,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 1495.06,
                "com_disc": 0,
                "rtf": "19853.00",
                "rdwtf": "31593.00"
            },
            {
                "tid": "B3cKMbmrMk9dT6cU",
                "pid": "1723703",
                "hs": 0,
                "rmid": "511084",
                "rtid": "380075512",
                "refundable": false,
                "available_rooms": 1,
                "score": 416,
                "sno": "515",
                "cont": {
                    "_id": "600d3f80a1bc371b80fa8fb9",
                    "name": "Nuran Marina Serviced Residences",
                    "ratings": "0.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.074233,
                            "longitude": 55.137455
                        }
                    },
                    "externalId": "1723703",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/2000000/1730000/1723800/1723703/e53a1751_b.jpg",
                    "ha": [
                        "POOL",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.4",
                        "staff_service": "4.5",
                        "amenities": "4.4",
                        "conditions_facilities": "4.3"
                    },
                    "rat": "0.0",
                    "urat": "4.5",
                    "expediareview": 280,
                    "pid": "1723703",
                    "line1": "Al Majara 4, Street H, District 4",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Aparthotel",
                    "nearby": "The Walk - 0.7 km / 0.4 mi "
                },
                "hdtype": "HOTDEAL",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 11165.75,
                "dwtf": 27411.92,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 1239.73,
                "com_disc": 0,
                "rtf": "16246.00",
                "rdwtf": "27412.00"
            },
            {
                "tid": "INlIXRnTDpH9GVAc",
                "pid": "1938669",
                "hs": 0,
                "rmid": "211504471",
                "rtid": "230172833",
                "refundable": true,
                "available_rooms": 5,
                "score": 735,
                "sno": "561",
                "cont": {
                    "_id": "600d3fffa1bc371b80fb2cfe",
                    "name": "The Westin Dubai Mina Seyahi Beach Resort & Marina",
                    "ratings": "5.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.092392,
                            "longitude": 55.149502
                        }
                    },
                    "externalId": "1938669",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/2000000/1940000/1938700/1938669/6929bbbe_b.jpg",
                    "ha": [
                        "POOL",
                        "POOL",
                        "WIFI",
                        "GYM"
                    ],
                    "guestreview": {
                        "cleaniness": "4.7",
                        "staff_service": "4.7",
                        "amenities": "4.6",
                        "conditions_facilities": "4.6"
                    },
                    "rat": "5.0",
                    "urat": "4.6",
                    "expediareview": 996,
                    "pid": "1938669",
                    "line1": "Dubai Marina",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Resort",
                    "nearby": "American University of Dubai (AUD) - 1 km / 0.6 mi "
                },
                "hdtype": "HOTDEAL",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 10508.94,
                "dwtf": 32798.57,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 1340.71,
                "com_disc": 0,
                "rtf": "22290.00",
                "rdwtf": "32799.00"
            },
            {
                "tid": "uuklcWKCacbXKhq0",
                "pid": "71111562",
                "hs": 0,
                "rmid": "315583389",
                "rtid": "384200808",
                "refundable": false,
                "available_rooms": 1,
                "score": 13,
                "sno": "594",
                "cont": {
                    "_id": "62506a5f2aee3e1bb50b6657",
                    "ratings": "0.0",
                    "name": "bnbme 4B-Murjan-3501",
                    "location": {
                        "coordinates": {
                            "latitude": 25.076944,
                            "longitude": 55.134135
                        },
                        "obfuscationRequired": false
                    },
                    "externalId": "71111562",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/72000000/71120000/71111600/71111562/bbc98be4_b.jpg",
                    "ha": [],
                    "guestreview": {
                        "cleaniness": 0,
                        "staff_service": 0,
                        "amenities": 0,
                        "conditions_facilities": 0
                    },
                    "rat": "0.0",
                    "urat": "",
                    "expediareview": 0,
                    "pid": "71111562",
                    "line1": "Murjan 6 JBR",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Apartment",
                    "nearby": "The Beach Mall - 0.4 km / 0.3 mi "
                },
                "hdtype": "HOTDEAL",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 9523.73,
                "dwtf": 47343.6,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 2111.64,
                "com_disc": 0,
                "rtf": "37820.00",
                "rdwtf": "47344.00"
            },
            {
                "tid": "3GrBWQLRq2R2VRPM",
                "pid": "75260049",
                "hs": 0,
                "rmid": "321196906",
                "rtid": "388142169",
                "refundable": false,
                "available_rooms": 1,
                "score": 19,
                "sno": "550",
                "cont": {
                    "_id": "62506a962aee3e1bb50b66a5",
                    "ratings": "0.0",
                    "name": "Maison Privee - 5242 Emaar",
                    "location": {
                        "coordinates": {
                            "latitude": 25.071374,
                            "longitude": 55.124944
                        },
                        "obfuscatedCoordinates": {
                            "latitude": 25.067548,
                            "longitude": 55.124662
                        },
                        "obfuscationRequired": false
                    },
                    "externalId": "75260049",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/76000000/75270000/75260100/75260049/a3ece694_b.jpg",
                    "ha": [],
                    "guestreview": {
                        "cleaniness": 0,
                        "staff_service": 0,
                        "amenities": 0,
                        "conditions_facilities": 0
                    },
                    "rat": "0.0",
                    "urat": "",
                    "expediareview": 0,
                    "pid": "75260049",
                    "line1": "King Salman Bin Abdulaziz Al Saud St",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Apartment",
                    "nearby": "The Beach Mall - 1.3 km / 0.8 mi "
                },
                "hdtype": "HOTDEAL",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 9195.32,
                "dwtf": 29516.17,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 1124.79,
                "com_disc": 0,
                "rtf": "20321.00",
                "rdwtf": "29516.00"
            },
            {
                "tid": "kuVFziNGRL30RU6o",
                "pid": "41948974",
                "hs": 0,
                "rmid": "319590624",
                "rtid": "387015598",
                "refundable": false,
                "available_rooms": 1,
                "score": 97,
                "sno": "528",
                "cont": {
                    "_id": "600d3e1ba1bc371b80f88e4c",
                    "name": "Maison Privee - Burj Views",
                    "ratings": "4.5",
                    "location": {
                        "coordinates": {
                            "latitude": 25.190987,
                            "longitude": 55.281347
                        }
                    },
                    "externalId": "41948974",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/42000000/41950000/41949000/41948974/52b0ed72_b.jpg",
                    "ha": [
                        "POOL"
                    ],
                    "guestreview": {
                        "cleaniness": "2.0",
                        "staff_service": "2.0",
                        "amenities": 0,
                        "conditions_facilities": "3.0"
                    },
                    "rat": "4.5",
                    "urat": "2.0",
                    "expediareview": 2,
                    "pid": "41948974",
                    "line1": "Al Asayel Street",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Apartment",
                    "nearby": "Dubai Water Canal - 0.9 km / 0.5 mi "
                },
                "hdtype": "HOTDEAL",
                "usrst": "Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 8045.91,
                "dwtf": 25815.87,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 766,
                "com_disc": 0,
                "rtf": "17770.00",
                "rdwtf": "25816.00"
            },
            {
                "tid": "tRkt6m7lfcusnVB0",
                "pid": "35581351",
                "hs": 0,
                "rmid": "214536805",
                "rtid": "250192110",
                "refundable": false,
                "available_rooms": 2,
                "score": 401,
                "sno": "514",
                "cont": {
                    "_id": "600d3ef9a1bc371b80f9ca71",
                    "name": "Roda Al Murooj Residences",
                    "ratings": "0.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.202905,
                            "longitude": 55.2778
                        }
                    },
                    "externalId": "35581351",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/36000000/35590000/35581400/35581351/918370df_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.7",
                        "staff_service": "4.0",
                        "amenities": "3.5",
                        "conditions_facilities": "4.2"
                    },
                    "rat": "0.0",
                    "urat": "4.1",
                    "expediareview": 10,
                    "pid": "35581351",
                    "line1": "Financial Center Road",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Aparthotel",
                    "nearby": "Dubai Mall - 0.6 km / 0.4 mi "
                },
                "hdtype": "HOTDEAL",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 7060.69,
                "dwtf": 23305.22,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 1243.01,
                "com_disc": 0,
                "rtf": "16245.00",
                "rdwtf": "23305.00"
            },
            {
                "tid": "QxeaxoTLTCHMG4S3",
                "pid": "13443879",
                "hs": 0,
                "rmid": "219609869",
                "rtid": "284042474",
                "refundable": true,
                "available_rooms": 9,
                "score": 419,
                "sno": "498",
                "cont": {
                    "_id": "600d432ca1bc371b80ff8a4e",
                    "name": "Hyatt Regency Galleria Residence Dubai",
                    "ratings": "5.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.278365,
                            "longitude": 55.304674
                        }
                    },
                    "externalId": "13443879",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/14000000/13450000/13443900/13443879/b035a670_b.jpg",
                    "ha": [
                        "POOL",
                        "POOL",
                        "BAR",
                        "POOL"
                    ],
                    "guestreview": {
                        "cleaniness": "4.3",
                        "staff_service": "3.9",
                        "amenities": "4.0",
                        "conditions_facilities": "4.2"
                    },
                    "rat": "5.0",
                    "urat": "3.9",
                    "expediareview": 149,
                    "pid": "13443879",
                    "line1": "Deira Corniche",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Aparthotel",
                    "nearby": "Naif Souq - 0.9 km / 0.6 mi "
                },
                "hdtype": "HOTDEAL",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 6650.19,
                "dwtf": 21677.97,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 1146.13,
                "com_disc": 0,
                "rtf": "15028.00",
                "rdwtf": "21678.00"
            },
            {
                "tid": "9DhlnxZPhkJk0Lv5",
                "pid": "21591581",
                "hs": 0,
                "rmid": "321191200",
                "rtid": "388113139",
                "refundable": false,
                "available_rooms": 1,
                "score": 44,
                "sno": "481",
                "cont": {
                    "_id": "600d3f14a1bc371b80f9f33a",
                    "name": "Maison Privee - Tiara Ruby",
                    "ratings": "0.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.115895,
                            "longitude": 55.14061
                        }
                    },
                    "externalId": "21591581",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/22000000/21600000/21591600/21591581/620c8b0c_b.jpg",
                    "ha": [
                        "POOL",
                        "POOL"
                    ],
                    "guestreview": {
                        "cleaniness": 0,
                        "staff_service": 0,
                        "amenities": 0,
                        "conditions_facilities": 0
                    },
                    "rat": "0.0",
                    "urat": "4.0",
                    "expediareview": 1,
                    "pid": "21591581",
                    "line1": "Palm Jumeirah, Tiara Residence",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Private vacation home",
                    "nearby": "American University of Dubai (AUD) - 4.1 km / 2.5 mi "
                },
                "hdtype": "HOTDEAL",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 6321.78,
                "dwtf": 20377.49,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 676.51,
                "com_disc": 0,
                "rtf": "14056.00",
                "rdwtf": "20377.00"
            },
            {
                "tid": "M5LkfrTywlt3zpnT",
                "pid": "7495229",
                "hs": 0,
                "rmid": "200731264",
                "rtid": "203631412",
                "refundable": true,
                "available_rooms": 6,
                "score": 394,
                "sno": "420",
                "cont": {
                    "_id": "600d432fa1bc371b80ff8da9",
                    "name": "City Première Marina Hotel Apartments",
                    "ratings": "0.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.079774,
                            "longitude": 55.142679
                        }
                    },
                    "externalId": "7495229",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/8000000/7500000/7495300/7495229/9d8e1517_b.jpg",
                    "ha": [
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.1",
                        "staff_service": "3.9",
                        "amenities": "4.4",
                        "conditions_facilities": "3.9"
                    },
                    "rat": "0.0",
                    "urat": "3.9",
                    "expediareview": 208,
                    "pid": "7495229",
                    "line1": "Marina Waterfront",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Aparthotel",
                    "nearby": "Jumeirah Beach - 0.8 km / 0.5 mi "
                },
                "hdtype": "HOTDEAL",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 6157.58,
                "dwtf": 15890.67,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 742.19,
                "com_disc": 0,
                "rtf": "9733.00",
                "rdwtf": "15891.00"
            },
            {
                "tid": "uC3aETRy2nS4oOgm",
                "pid": "9360689",
                "hs": 0,
                "rmid": "321900110",
                "rtid": "389761370",
                "refundable": false,
                "available_rooms": 5,
                "score": 201,
                "sno": "346",
                "cont": {
                    "_id": "600d40d0a1bc371b80fc53b7",
                    "name": "Al Raya Hotel Apartment",
                    "ratings": "0.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.249844,
                            "longitude": 55.295819
                        }
                    },
                    "externalId": "9360689",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/10000000/9370000/9360700/9360689/4746c44f_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "3.3",
                        "staff_service": "3.5",
                        "amenities": "3.4",
                        "conditions_facilities": "3.3"
                    },
                    "rat": "0.0",
                    "urat": "3.4",
                    "expediareview": 33,
                    "pid": "9360689",
                    "line1": "Bur Dubai Al Mankhool area",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Aparthotel",
                    "nearby": "BurJuman Mall - 1.2 km / 0.7 mi "
                },
                "hdtype": "HOTDEAL",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 6075.48,
                "dwtf": 13574.6,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 572.24,
                "com_disc": 0,
                "rtf": "7499.00",
                "rdwtf": "13575.00"
            },
            {
                "tid": "t1TFVTFkNWkzoEiS",
                "pid": "48880688",
                "hs": 0,
                "rmid": "232833340",
                "rtid": "292341602",
                "refundable": false,
                "available_rooms": 1,
                "score": 12,
                "sno": "457",
                "cont": {
                    "_id": "600d3f34a1bc371b80fa24fa",
                    "name": "Maison Privee Miska 1",
                    "ratings": "0.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.189162,
                            "longitude": 55.276085
                        }
                    },
                    "externalId": "48880688",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/49000000/48890000/48880700/48880688/4ffdd26e_b.jpg",
                    "ha": [],
                    "guestreview": {
                        "cleaniness": 0,
                        "staff_service": 0,
                        "amenities": 0,
                        "conditions_facilities": 0
                    },
                    "rat": "0.0",
                    "urat": "",
                    "expediareview": 0,
                    "pid": "48880688",
                    "line1": "Sheikh Mohammed bin Rashid Blvd",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Apartment",
                    "nearby": "Souk al Bahar - 0.5 km / 0.3 mi "
                },
                "hdtype": "HOTDEAL",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 5664.98,
                "dwtf": 18200.17,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 564.03,
                "com_disc": 0,
                "rtf": "12535.00",
                "rdwtf": "18200.00"
            },
            {
                "tid": "Ka5RD9hoQ0oSugbi",
                "pid": "49145491",
                "hs": 0,
                "rmid": "230734963",
                "rtid": "278326357",
                "refundable": false,
                "available_rooms": 40,
                "score": 444,
                "sno": "508",
                "cont": {
                    "_id": "600d3e32a1bc371b80f8ae9b",
                    "name": "Riu Dubai - All Inclusive",
                    "ratings": "4.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.300434,
                            "longitude": 55.306705
                        }
                    },
                    "externalId": "49145491",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/50000000/49150000/49145500/49145491/673d2347_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": 0,
                        "staff_service": 0,
                        "amenities": 0,
                        "conditions_facilities": 0
                    },
                    "rat": "4.0",
                    "urat": "",
                    "expediareview": 0,
                    "pid": "49145491",
                    "line1": "Deira Islands",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "All-inclusive property",
                    "nearby": "Al Ghurair Centre - 9.2 km / 5.7 mi "
                },
                "hdtype": "HOTDEAL",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 5418.67,
                "dwtf": 21154.99,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 1348.1,
                "com_disc": 0,
                "rtf": "15736.00",
                "rdwtf": "21155.00"
            },
            {
                "tid": "z5geCCmbBnumn1lZ",
                "pid": "42074031",
                "hs": 0,
                "rmid": "230134134",
                "rtid": "274316793",
                "refundable": false,
                "available_rooms": 6,
                "score": 123,
                "sno": "553",
                "cont": {
                    "_id": "600d411ea1bc371b80fcc00f",
                    "name": "Simply Comfort Luxury Sarai Apartments",
                    "ratings": "0.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.140599,
                            "longitude": 55.13698
                        }
                    },
                    "externalId": "42074031",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/43000000/42080000/42074100/42074031/518f7b7b_b.jpg",
                    "ha": [
                        "POOL",
                        "GYM"
                    ],
                    "guestreview": {
                        "cleaniness": "3.0",
                        "staff_service": "5.0",
                        "amenities": "4.0",
                        "conditions_facilities": "5.0"
                    },
                    "rat": "0.0",
                    "urat": "3.8",
                    "expediareview": 4,
                    "pid": "42074031",
                    "line1": "Sarai Apartments",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Aparthotel",
                    "nearby": "Aquaventure - 2.3 km / 1.4 mi "
                },
                "hdtype": "HOTDEAL",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 5336.57,
                "dwtf": 26484.99,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 1013.13,
                "com_disc": 0,
                "rtf": "21148.00",
                "rdwtf": "26485.00"
            },
            {
                "tid": "7BwI7EB1F2TgnfXN",
                "pid": "48828770",
                "hs": 0,
                "rmid": "217646886",
                "rtid": "270268916",
                "refundable": false,
                "available_rooms": 6,
                "score": 155,
                "sno": "450",
                "cont": {
                    "_id": "600d41d0a1bc371b80fdbd89",
                    "name": "Dusit Princess Residences - Dubai Marina",
                    "ratings": "0.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.082855,
                            "longitude": 55.144653
                        }
                    },
                    "externalId": "48828770",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/49000000/48830000/48828800/48828770/a6a0832b_b.jpg",
                    "ha": [
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.3",
                        "staff_service": 0,
                        "amenities": 0,
                        "conditions_facilities": 0
                    },
                    "rat": "0.0",
                    "urat": "4.2",
                    "expediareview": 4,
                    "pid": "48828770",
                    "line1": "Al Emreef St",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Apartment",
                    "nearby": "The Walk - 0.9 km / 0.6 mi "
                },
                "hdtype": "HOTDEAL",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 5254.47,
                "dwtf": 17194.43,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 970.44,
                "com_disc": 0,
                "rtf": "11940.00",
                "rdwtf": "17194.00"
            },
            {
                "tid": "o989orqCZclHV5Kt",
                "pid": "27745798",
                "hs": 0,
                "rmid": "230400250",
                "rtid": "276284902",
                "refundable": false,
                "available_rooms": 2,
                "score": 130,
                "sno": "487",
                "cont": {
                    "_id": "600d3f50a1bc371b80fa4cbd",
                    "name": "Westminster Dubai Mall",
                    "ratings": "0.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.19988,
                            "longitude": 55.27743
                        }
                    },
                    "externalId": "27745798",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/28000000/27750000/27745800/27745798/d1a49740_b.jpg",
                    "ha": [
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "3.0",
                        "staff_service": "2.0",
                        "amenities": 0,
                        "conditions_facilities": "4.0"
                    },
                    "rat": "0.0",
                    "urat": "3.0",
                    "expediareview": 1,
                    "pid": "27745798",
                    "line1": "The Address Dubai Mall",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Apartment",
                    "nearby": "KidZania - 0.3 km / 0.2 mi "
                },
                "hdtype": "HOTDEAL",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 4843.96,
                "dwtf": 19276.52,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 779.14,
                "com_disc": 0,
                "rtf": "14433.00",
                "rdwtf": "19277.00"
            },
            {
                "tid": "x36TNaUqJBDeLLIS",
                "pid": "39181814",
                "hs": 0,
                "rmid": "219035388",
                "rtid": "280162862",
                "refundable": false,
                "available_rooms": 1,
                "score": 39,
                "sno": "440",
                "cont": {
                    "_id": "600d3e6ca1bc371b80f910a5",
                    "name": "bnbme| St-Claren-502",
                    "ratings": "0.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.190881,
                            "longitude": 55.271589
                        }
                    },
                    "externalId": "39181814",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/40000000/39190000/39181900/39181814/dae42a2d_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "POOL"
                    ],
                    "guestreview": {
                        "cleaniness": 0,
                        "staff_service": 0,
                        "amenities": 0,
                        "conditions_facilities": 0
                    },
                    "rat": "0.0",
                    "urat": "",
                    "expediareview": 0,
                    "pid": "39181814",
                    "line1": "Claren Tower 1, Downtown",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Apartment",
                    "nearby": "Souk al Bahar - 0.8 km / 0.5 mi "
                },
                "hdtype": "HOTDEAL",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 4761.86,
                "dwtf": 15788.04,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 558.29,
                "com_disc": 0,
                "rtf": "11026.00",
                "rdwtf": "15788.00"
            },
            {
                "tid": "pKx2hN84rwIoF8fl",
                "pid": "73349297",
                "hs": 0,
                "rmid": "315859897",
                "rtid": "384864259",
                "refundable": false,
                "available_rooms": 2,
                "score": 49,
                "sno": "255",
                "cont": {
                    "_id": "62506c3c2aee3e1bb50b6bab",
                    "name": "Royal Regency Holiday Homes",
                    "location": {
                        "coordinates": {
                            "latitude": 25.077547,
                            "longitude": 55.141866
                        },
                        "obfuscationRequired": false
                    },
                    "externalId": "73349297",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/74000000/73350000/73349300/73349297/5f8b29c5_b.jpg",
                    "ha": [
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": 0,
                        "staff_service": 0,
                        "amenities": 0,
                        "conditions_facilities": 0
                    },
                    "rat": "",
                    "urat": "",
                    "expediareview": 0,
                    "ratings": "",
                    "pid": "73349297",
                    "line1": "Al Suwayeb St",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Condo",
                    "nearby": "Dubai Marina Mall - 0.5 km / 0.3 mi "
                },
                "hdtype": "HOTDEAL",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 4597.66,
                "dwtf": 10201.88,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 510.67,
                "com_disc": 0,
                "rtf": "5604.00",
                "rdwtf": "10202.00"
            },
            {
                "tid": "ZJSemDdX4HS8VpKT",
                "pid": "48880545",
                "hs": 0,
                "rmid": "318497937",
                "rtid": "386862642",
                "refundable": false,
                "available_rooms": 1,
                "score": 26,
                "sno": "410",
                "cont": {
                    "_id": "600d3e1fa1bc371b80f89418",
                    "name": "Maison Privee Marquise Square",
                    "ratings": "0.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.18506,
                            "longitude": 55.27588
                        }
                    },
                    "externalId": "48880545",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/49000000/48890000/48880600/48880545/910abe5f_b.jpg",
                    "ha": [],
                    "guestreview": {
                        "cleaniness": 0,
                        "staff_service": 0,
                        "amenities": 0,
                        "conditions_facilities": 0
                    },
                    "rat": "0.0",
                    "urat": "",
                    "expediareview": 0,
                    "pid": "48880545",
                    "line1": "Marquise Square, Downtown Dubai",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Apartment",
                    "nearby": "Dubai Water Canal - 0.3 km / 0.2 mi "
                },
                "hdtype": "HOTDEAL",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 4269.26,
                "dwtf": 13647.67,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 366.99,
                "com_disc": 0,
                "rtf": "9378.00",
                "rdwtf": "13648.00"
            },
            {
                "tid": "2K3ur5qb3SpVdreM",
                "pid": "61528330",
                "hs": 0,
                "rmid": "227158130",
                "rtid": "254414186",
                "refundable": false,
                "available_rooms": 1,
                "score": 15,
                "sno": "522",
                "cont": {
                    "_id": "62506c6d2aee3e1bb50b6c25",
                    "name": "bnbme 1B-The 8-10201",
                    "location": {
                        "coordinates": {
                            "latitude": 25.117615,
                            "longitude": 55.109975
                        },
                        "obfuscationRequired": false
                    },
                    "externalId": "61528330",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/62000000/61530000/61528400/61528330/a3c4c598_b.jpg",
                    "ha": [],
                    "guestreview": {
                        "cleaniness": 0,
                        "staff_service": 0,
                        "amenities": 0,
                        "conditions_facilities": 0
                    },
                    "rat": "",
                    "urat": "",
                    "expediareview": 0,
                    "ratings": "",
                    "pid": "61528330",
                    "line1": "Crescent Road, West Palm Jumeirah",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Apartment",
                    "nearby": "Aquaventure - 2.2 km / 1.3 mi "
                },
                "hdtype": "HOTDEAL",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 4269.26,
                "dwtf": 21220.67,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 756.15,
                "com_disc": 0,
                "rtf": "16951.00",
                "rdwtf": "21221.00"
            },
            {
                "tid": "JLXvhQLvuOD8Nv3W",
                "pid": "16060666",
                "hs": 0,
                "rmid": "201664249",
                "rtid": "383428450",
                "refundable": false,
                "available_rooms": 36,
                "score": 841,
                "sno": "593",
                "cont": {
                    "_id": "600d3eada1bc371b80f96d63",
                    "name": "Jumeirah Al Naseem",
                    "ratings": "5.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.137135,
                            "longitude": 55.187994
                        }
                    },
                    "externalId": "16060666",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/17000000/16070000/16060700/16060666/c7f67229_b.jpg",
                    "ha": [
                        "BAR",
                        "GYM",
                        "POOL",
                        "POOL"
                    ],
                    "guestreview": {
                        "cleaniness": "4.8",
                        "staff_service": "4.6",
                        "amenities": "4.5",
                        "conditions_facilities": "4.8"
                    },
                    "rat": "5.0",
                    "urat": "4.7",
                    "expediareview": 236,
                    "pid": "16060666",
                    "line1": "King Salman bin Abdulaziz Al Saud",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Resort",
                    "nearby": "Madinat Jumeirah - 0.1 km / 0.1 mi "
                },
                "hdtype": "HOTDEAL",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 4269.26,
                "dwtf": 41707.36,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 2857.94,
                "com_disc": 0,
                "rtf": "37438.00",
                "rdwtf": "41707.00"
            },
            {
                "tid": "tLTkvQx50A96cKLx",
                "pid": "49190999",
                "hs": 0,
                "rmid": "213734998",
                "rtid": "244332306",
                "refundable": true,
                "available_rooms": 3,
                "score": 136,
                "sno": "293",
                "cont": {
                    "_id": "62506b252aee3e1bb50b67c3",
                    "ratings": "0.0",
                    "name": "sleep ’n fly Sleep Lounge, Dubai International Airport (DXB), C-Gates (Terminal 3/ Transit Area)",
                    "location": {
                        "coordinates": {
                            "latitude": 25.252401,
                            "longitude": 55.354309
                        },
                        "obfuscationRequired": false
                    },
                    "externalId": "49190999",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/50000000/49200000/49191000/49190999/33fc38db_b.jpg",
                    "ha": [
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "3.9",
                        "staff_service": "3.9",
                        "amenities": "4.0",
                        "conditions_facilities": "3.6"
                    },
                    "rat": "0.0",
                    "urat": "3.1",
                    "expediareview": 14,
                    "pid": "49190999",
                    "line1": "Between Gate C16/C14, Concourse C",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Capsule Hotel",
                    "nearby": "Dubai Tennis Stadium - 1.6 km / 1 mi "
                },
                "hdtype": "HOTDEAL",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 4187.16,
                "dwtf": 10423.56,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 503.28,
                "com_disc": 0,
                "rtf": "6236.00",
                "rdwtf": "10424.00"
            },
            {
                "tid": "aCuNKwTFcp6q3zuC",
                "pid": "38122488",
                "hs": 0,
                "rmid": "217532693",
                "rtid": "389274278",
                "refundable": true,
                "available_rooms": 8,
                "score": 363,
                "sno": "399",
                "cont": {
                    "_id": "600d3fa1a1bc371b80fab378",
                    "name": "Hyatt Place Wasl District Residences",
                    "ratings": "0.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.269854,
                            "longitude": 55.314183
                        }
                    },
                    "externalId": "38122488",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/39000000/38130000/38122500/38122488/5a7f337a_b.jpg",
                    "ha": [
                        "BAR",
                        "POOL",
                        "WIFI",
                        "GYM"
                    ],
                    "guestreview": {
                        "cleaniness": "4.6",
                        "staff_service": "4.5",
                        "amenities": "4.2",
                        "conditions_facilities": "4.6"
                    },
                    "rat": "0.0",
                    "urat": "4.5",
                    "expediareview": 34,
                    "pid": "38122488",
                    "line1": "Wasl District",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Aparthotel",
                    "nearby": "Fish Roundabout - 0.2 km / 0.1 mi "
                },
                "hdtype": "HOTDEAL",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 3940.85,
                "dwtf": 12826.65,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 678.16,
                "com_disc": 0,
                "rtf": "8886.00",
                "rdwtf": "12827.00"
            },
            {
                "tid": "r0hEEesbhs2m5shm",
                "pid": "23596374",
                "hs": 0,
                "rmid": "216603855",
                "rtid": "264051802",
                "refundable": false,
                "available_rooms": 1,
                "score": 294,
                "sno": "588",
                "cont": {
                    "_id": "600d4154a1bc371b80fcf937",
                    "name": "Rose Garden Hotel Apartments - Bur Dubai",
                    "ratings": "3.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.25424,
                            "longitude": 55.30114
                        }
                    },
                    "externalId": "23596374",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/24000000/23600000/23596400/23596374/f7a68abc_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "POOL",
                        "POOL"
                    ],
                    "guestreview": {
                        "cleaniness": "4.4",
                        "staff_service": "4.3",
                        "amenities": "4.1",
                        "conditions_facilities": "3.9"
                    },
                    "rat": "3.0",
                    "urat": "4.3",
                    "expediareview": 35,
                    "pid": "23596374",
                    "line1": "Behind Burjman Shopping Mall",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Aparthotel",
                    "nearby": "BurJuman Mall - 0.9 km / 0.6 mi "
                },
                "hdtype": "HOTDEAL",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 3940.85,
                "dwtf": 38269.79,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 2619.85,
                "com_disc": 0,
                "rtf": "34329.00",
                "rdwtf": "38270.00"
            },
            {
                "tid": "olGznikuaH5OALGp",
                "pid": "1198396",
                "hs": 0,
                "rmid": "200174741",
                "rtid": "383428424",
                "refundable": false,
                "available_rooms": 6,
                "score": 733,
                "sno": "581",
                "cont": {
                    "_id": "600d4249a1bc371b80fe5dfe",
                    "name": "Jumeirah Dar Al Masyaf",
                    "ratings": "5.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.13226,
                            "longitude": 55.18452
                        }
                    },
                    "externalId": "1198396",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/2000000/1200000/1198400/1198396/52bcfd01_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.8",
                        "staff_service": "4.8",
                        "amenities": "4.8",
                        "conditions_facilities": "4.8"
                    },
                    "rat": "5.0",
                    "urat": "4.8",
                    "expediareview": 558,
                    "pid": "1198396",
                    "line1": "King Salman bin Abdulaziz Al Saud",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Resort",
                    "nearby": "Madinat Jumeirah - 0.1 km / 0.1 mi "
                },
                "hdtype": "HOTDEAL",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 3776.65,
                "dwtf": 36383.1,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 2488.48,
                "com_disc": 0,
                "rtf": "32606.00",
                "rdwtf": "36383.00"
            },
            {
                "tid": "JUwtU6FUClyORsvx",
                "pid": "16387503",
                "hs": 0,
                "rmid": "221413281",
                "rtid": "386414169",
                "refundable": false,
                "available_rooms": 4,
                "score": 376,
                "sno": "582",
                "cont": {
                    "_id": "600d405ea1bc371b80fbad0f",
                    "name": "Grand Hyatt Residence",
                    "ratings": "0.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.225978,
                            "longitude": 55.327018
                        }
                    },
                    "externalId": "16387503",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/17000000/16390000/16387600/16387503/a7212395_b.jpg",
                    "ha": [
                        "BAR",
                        "GYM",
                        "POOL",
                        "POOL"
                    ],
                    "guestreview": {
                        "cleaniness": "5.0",
                        "staff_service": "5.0",
                        "amenities": 0,
                        "conditions_facilities": "4.5"
                    },
                    "rat": "0.0",
                    "urat": "4.8",
                    "expediareview": 5,
                    "pid": "16387503",
                    "line1": "E11 Sheikh Rashid Road",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Aparthotel",
                    "nearby": "Wafi City Mall - 1.1 km / 0.7 mi "
                },
                "hdtype": "HOTDEAL",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 3776.65,
                "dwtf": 36624.48,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 2507.37,
                "com_disc": 0,
                "rtf": "32848.00",
                "rdwtf": "36624.00"
            },
            {
                "tid": "63FuPlCCDAiZLTas",
                "pid": "11075436",
                "hs": 0,
                "rmid": "201180508",
                "rtid": "387398592",
                "refundable": false,
                "available_rooms": 14,
                "score": 170,
                "sno": "434",
                "cont": {
                    "_id": "600d3fffa1bc371b80fb2bbb",
                    "name": "Palma Beach Resort and Spa",
                    "ratings": "3.5",
                    "location": {
                        "coordinates": {
                            "latitude": 25.566336,
                            "longitude": 55.564541
                        }
                    },
                    "externalId": "11075436",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/12000000/11080000/11075500/11075436/416ee7c3_b.jpg",
                    "ha": [
                        "BAR",
                        "GYM",
                        "POOL",
                        "BAR",
                        "POOL"
                    ],
                    "guestreview": {
                        "cleaniness": "3.5",
                        "staff_service": "3.6",
                        "amenities": "4.0",
                        "conditions_facilities": "3.4"
                    },
                    "rat": "3.5",
                    "urat": "3.6",
                    "expediareview": 57,
                    "pid": "11075436",
                    "line1": "Al Dar Al Baida",
                    "city": "Umm al Quwain",
                    "countryCode": "AE",
                    "catnm": "Resort",
                    "nearby": "Marine Research Center and Aquarium - 4 km / 2.5 mi "
                },
                "hdtype": "HOTDEAL",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 3612.45,
                "dwtf": 14318.43,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 834.97,
                "com_disc": 0,
                "rtf": "10706.00",
                "rdwtf": "14318.00"
            },
            {
                "tid": "fq7184hs3uSZSSu0",
                "pid": "16216917",
                "hs": 0,
                "rmid": "202202183",
                "rtid": "211263962",
                "refundable": false,
                "available_rooms": 12,
                "score": 940,
                "sno": "577",
                "cont": {
                    "_id": "600d3df7a1bc371b80f85d19",
                    "name": "FIVE Palm Jumeirah Dubai",
                    "ratings": "5.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.104575,
                            "longitude": 55.148995
                        }
                    },
                    "externalId": "16216917",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/17000000/16220000/16217000/16216917/3d2f70a9_b.jpg",
                    "ha": [
                        "POOL",
                        "POOL",
                        "WIFI",
                        "GYM"
                    ],
                    "guestreview": {
                        "cleaniness": "4.6",
                        "staff_service": "4.4",
                        "amenities": "4.4",
                        "conditions_facilities": "4.5"
                    },
                    "rat": "5.0",
                    "urat": "4.3",
                    "expediareview": 1193,
                    "pid": "16216917",
                    "line1": "No. 1 The Palm",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Resort",
                    "nearby": "American University of Dubai (AUD) - 3 km / 1.9 mi "
                },
                "hdtype": "HOTDEAL",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 3284.04,
                "dwtf": 31543.24,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 2156.8,
                "com_disc": 0,
                "rtf": "28259.00",
                "rdwtf": "31543.00"
            },
            {
                "tid": "dfhbUHoqwNwLRyVN",
                "pid": "20643202",
                "hs": 0,
                "rmid": "202270003",
                "rtid": "211704581",
                "refundable": false,
                "available_rooms": 5,
                "score": 653,
                "sno": "606",
                "cont": {
                    "_id": "600d4135a1bc371b80fcd905",
                    "name": "Bulgari Hotel & Resorts, Dubai",
                    "ratings": "5.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.210024,
                            "longitude": 55.236144
                        }
                    },
                    "externalId": "20643202",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/21000000/20650000/20643300/20643202/0daee219_b.jpg",
                    "ha": [
                        "POOL",
                        "POOL",
                        "BAR",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.8",
                        "staff_service": "4.6",
                        "amenities": "4.7",
                        "conditions_facilities": "4.8"
                    },
                    "rat": "5.0",
                    "urat": "4.7",
                    "expediareview": 71,
                    "pid": "20643202",
                    "line1": "Jumeirah Bay Island",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Resort",
                    "nearby": "Box Park - 2.6 km / 1.6 mi "
                },
                "hdtype": "HOTDEAL",
                "usrst": "Excellent",
                "brdbs": [
                    "Breakfast"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 3201.94,
                "dwtf": 57652.21,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 4101.77,
                "com_disc": 0,
                "rtf": "54450.00",
                "rdwtf": "57652.00"
            },
            {
                "tid": "kucepAyUqTiqBKSM",
                "pid": "58960880",
                "hs": 0,
                "rmid": "228047817",
                "rtid": "260367440",
                "refundable": true,
                "available_rooms": 22,
                "score": 646,
                "sno": "353",
                "cont": {
                    "_id": "62506a972aee3e1bb50b66a6",
                    "ratings": "0.0",
                    "name": "Avani Palm View Dubai Hotel & Suites",
                    "location": {
                        "coordinates": {
                            "latitude": 25.098461,
                            "longitude": 55.156847
                        },
                        "obfuscationRequired": false
                    },
                    "externalId": "58960880",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/59000000/58970000/58960900/58960880/40026d2b_b.jpg",
                    "ha": [
                        "GYM",
                        "WIFI",
                        "GYM"
                    ],
                    "guestreview": {
                        "cleaniness": "4.7",
                        "staff_service": 0,
                        "amenities": "4.6",
                        "conditions_facilities": "4.9"
                    },
                    "rat": "0.0",
                    "urat": "4.6",
                    "expediareview": 42,
                    "pid": "58960880",
                    "line1": "P.O. Box 502624, Dubai Media City, TECOM",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Aparthotel",
                    "nearby": "Sheikh Zayed Road - 2.1 km / 1.3 mi "
                },
                "hdtype": "HOTDEAL",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 2955.64,
                "dwtf": 10596.79,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 583.74,
                "com_disc": 0,
                "rtf": "7641.00",
                "rdwtf": "10597.00"
            },
            {
                "tid": "i7XsUKnVIk8qIWQi",
                "pid": "21198252",
                "hs": 0,
                "rmid": "202309343",
                "rtid": "391321478",
                "refundable": false,
                "available_rooms": 3,
                "score": 253,
                "sno": "301",
                "cont": {
                    "_id": "600d4129a1bc371b80fccbf3",
                    "name": "Class Hotel Apartments",
                    "ratings": "0.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.101738,
                            "longitude": 55.177389
                        }
                    },
                    "externalId": "21198252",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/22000000/21200000/21198300/21198252/8e2e827d_b.jpg",
                    "ha": [
                        "POOL",
                        "POOL",
                        "WIFI",
                        "GYM"
                    ],
                    "guestreview": {
                        "cleaniness": "4.4",
                        "staff_service": "4.1",
                        "amenities": "3.9",
                        "conditions_facilities": "4.3"
                    },
                    "rat": "0.0",
                    "urat": "4.2",
                    "expediareview": 24,
                    "pid": "21198252",
                    "line1": "Barsha Heights Al Thanya Street",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Aparthotel",
                    "nearby": "Jebel Ali Race Course - 1.6 km / 1 mi "
                },
                "hdtype": "HOTDEAL",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 2873.54,
                "dwtf": 9264.29,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 487.68,
                "com_disc": 0,
                "rtf": "6391.00",
                "rdwtf": "9264.00"
            },
            {
                "tid": "y5v2vqf0QXbcxBO9",
                "pid": "40234810",
                "hs": 0,
                "rmid": "322685891",
                "rtid": "391590588",
                "refundable": true,
                "available_rooms": 10,
                "score": 248,
                "sno": "430",
                "cont": {
                    "_id": "600d4361a1bc371b80ffc113",
                    "name": "Andaz by Hyatt – Palm Jumeirah Residences",
                    "ratings": "0.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.115191,
                            "longitude": 55.140668
                        }
                    },
                    "externalId": "40234810",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/41000000/40240000/40234900/40234810/f4d4855f_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.4",
                        "staff_service": "3.7",
                        "amenities": "4.0",
                        "conditions_facilities": "4.7"
                    },
                    "rat": "0.0",
                    "urat": "4.3",
                    "expediareview": 6,
                    "pid": "40234810",
                    "line1": "Palm Jumeirah",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Aparthotel",
                    "nearby": "American University of Dubai (AUD) - 4.4 km / 2.7 mi "
                },
                "hdtype": "HOTDEAL",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 2873.54,
                "dwtf": 13367.7,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 800.49,
                "com_disc": 0,
                "rtf": "10494.00",
                "rdwtf": "13368.00"
            },
            {
                "tid": "6eTTHOVnrLv1VBrz",
                "pid": "74258137",
                "hs": 0,
                "rmid": "315974079",
                "rtid": "385177508",
                "refundable": false,
                "available_rooms": 1,
                "score": 18,
                "sno": "389",
                "cont": {
                    "_id": "62506a9c2aee3e1bb50b66ac",
                    "ratings": "0.0",
                    "name": "Lux BnB 2BD Amazing Burj View",
                    "location": {
                        "coordinates": {
                            "latitude": 25.190671,
                            "longitude": 55.281873
                        },
                        "obfuscatedCoordinates": {
                            "latitude": 25.190203,
                            "longitude": 55.286459
                        },
                        "obfuscationRequired": false
                    },
                    "externalId": "74258137",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/75000000/74260000/74258200/74258137/a720c03d_b.jpg",
                    "ha": [],
                    "guestreview": {
                        "cleaniness": 0,
                        "staff_service": 0,
                        "amenities": 0,
                        "conditions_facilities": 0
                    },
                    "rat": "0.0",
                    "urat": "",
                    "expediareview": 0,
                    "pid": "74258137",
                    "line1": "BURJ VIEW",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Apartment",
                    "nearby": "Dubai Water Canal - 0.9 km / 0.6 mi "
                },
                "hdtype": "HOTDEAL",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 2791.44,
                "dwtf": 11316.82,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 849.75,
                "com_disc": 0,
                "rtf": "8525.00",
                "rdwtf": "11317.00"
            },
            {
                "tid": "bhAHRUd8EpTOHiDS",
                "pid": "4804157",
                "hs": 0,
                "rmid": "200193027",
                "rtid": "234094734",
                "refundable": false,
                "available_rooms": 20,
                "score": 345,
                "sno": "247",
                "cont": {
                    "_id": "600d3ed4a1bc371b80f9a09c",
                    "name": "First Central Hotel Suites",
                    "ratings": "4.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.097725,
                            "longitude": 55.174801
                        }
                    },
                    "externalId": "4804157",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/5000000/4810000/4804200/4804157/b53e4ae7_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "3.9",
                        "staff_service": "3.8",
                        "amenities": "3.7",
                        "conditions_facilities": "3.8"
                    },
                    "rat": "4.0",
                    "urat": "3.8",
                    "expediareview": 492,
                    "pid": "4804157",
                    "line1": "TECOM, Al Barsha 3",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Aparthotel",
                    "nearby": "Jebel Ali Race Course - 1.1 km / 0.7 mi "
                },
                "hdtype": "HOTDEAL",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 2627.24,
                "dwtf": 8173.16,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 417.89,
                "com_disc": 0,
                "rtf": "5546.00",
                "rdwtf": "8173.00"
            },
            {
                "tid": "OIqoTBZe6oVTJ5aL",
                "pid": "46110658",
                "hs": 0,
                "rmid": "315645544",
                "rtid": "384197397",
                "refundable": true,
                "available_rooms": 4,
                "score": 131,
                "sno": "81",
                "cont": {
                    "_id": "62506c542aee3e1bb50b6be5",
                    "ratings": "0.0",
                    "name": "sleep 'n fly Sleep Lounge, Dubai International Airport (DXB), D-Gates (Terminal 1/ Transit Area)",
                    "location": {
                        "coordinates": {
                            "latitude": 25.256624,
                            "longitude": 55.34715
                        },
                        "obfuscatedCoordinates": {
                            "latitude": 0.001485,
                            "longitude": 0.003606
                        },
                        "obfuscationRequired": false
                    },
                    "externalId": "46110658",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/47000000/46120000/46110700/46110658/03625c2d_b.jpg",
                    "ha": [
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.0",
                        "staff_service": "4.0",
                        "amenities": 0,
                        "conditions_facilities": "3.5"
                    },
                    "rat": "0.0",
                    "urat": "3.0",
                    "expediareview": 3,
                    "pid": "46110658",
                    "line1": "Opposite Gates D6/D7, Concourse D",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Capsule Hotel",
                    "nearby": "Dubai Tennis Stadium - 1.5 km / 1 mi "
                },
                "hdtype": "HOTDEAL",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 2545.13,
                "dwtf": 6300.44,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 302.95,
                "com_disc": 0,
                "rtf": "3755.00",
                "rdwtf": "6300.00"
            },
            {
                "tid": "wE4yTCblSX8TE3J4",
                "pid": "75982997",
                "hs": 0,
                "rmid": "316244801",
                "rtid": "385671101",
                "refundable": false,
                "available_rooms": 1,
                "score": 15,
                "sno": "427",
                "cont": {
                    "_id": "62506af62aee3e1bb50b673d",
                    "name": "1B-La Fontana-613 by bnbme homes",
                    "location": {
                        "coordinates": {
                            "latitude": 25.076429,
                            "longitude": 55.231445
                        },
                        "obfuscatedCoordinates": {
                            "latitude": 25.077835,
                            "longitude": 55.23229
                        },
                        "obfuscationRequired": false
                    },
                    "externalId": "75982997",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/76000000/75990000/75983000/75982997/fc9dc5b8_b.jpg",
                    "ha": [],
                    "guestreview": {
                        "cleaniness": 0,
                        "staff_service": 0,
                        "amenities": 0,
                        "conditions_facilities": 0
                    },
                    "rat": "",
                    "urat": "",
                    "expediareview": 0,
                    "ratings": "",
                    "pid": "75982997",
                    "line1": "Al Barsha",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Apartment",
                    "nearby": "Dubai Science Park - 0.4 km / 0.3 mi "
                },
                "hdtype": "HOTDEAL",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 2545.13,
                "dwtf": 12700.22,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 347.29,
                "com_disc": 0,
                "rtf": "10155.00",
                "rdwtf": "12700.00"
            },
            {
                "tid": "0tz7BlLOBvVR9khI",
                "pid": "17330745",
                "hs": 0,
                "rmid": "316602434",
                "rtid": "386509908",
                "refundable": false,
                "available_rooms": 4,
                "score": 265,
                "sno": "334",
                "cont": {
                    "_id": "600d43cba1bc371b80004a17",
                    "name": "Pearl City Suites By Gemstones",
                    "ratings": "0.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.253923,
                            "longitude": 55.331921
                        }
                    },
                    "externalId": "17330745",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/18000000/17340000/17330800/17330745/e211ed93_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "POOL",
                        "BAR",
                        "POOL"
                    ],
                    "guestreview": {
                        "cleaniness": "3.5",
                        "staff_service": "3.5",
                        "amenities": "2.5",
                        "conditions_facilities": "3.4"
                    },
                    "rat": "0.0",
                    "urat": "3.5",
                    "expediareview": 124,
                    "pid": "17330745",
                    "line1": "8th St., Opp. Deira City Center",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Aparthotel",
                    "nearby": "City Centre Deira - 0.6 km / 0.4 mi "
                },
                "hdtype": "HOTDEAL",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 2463.03,
                "dwtf": 9580.38,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 544.33,
                "com_disc": 0,
                "rtf": "7117.00",
                "rdwtf": "9580.00"
            },
            {
                "tid": "pNOtJPyAiD5GMGpg",
                "pid": "23711862",
                "hs": 0,
                "rmid": "217827312",
                "rtid": "272089476",
                "refundable": false,
                "available_rooms": 8,
                "score": 308,
                "sno": "549",
                "cont": {
                    "_id": "600d3fd3a1bc371b80faef1d",
                    "name": "The Chedi Al Bait, Sharjah, UAE",
                    "ratings": "5.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.359435,
                            "longitude": 55.383238
                        }
                    },
                    "externalId": "23711862",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/24000000/23720000/23711900/23711862/c12239f7_b.jpg",
                    "ha": [
                        "POOL",
                        "POOL",
                        "WIFI",
                        "GYM"
                    ],
                    "guestreview": {
                        "cleaniness": "5.0",
                        "staff_service": "5.0",
                        "amenities": "5.0",
                        "conditions_facilities": "5.0"
                    },
                    "rat": "5.0",
                    "urat": "4.9",
                    "expediareview": 10,
                    "pid": "23711862",
                    "line1": "Heart of Sharjah, Al Mareija",
                    "city": "Sharjah",
                    "countryCode": "AE",
                    "catnm": "Resort",
                    "nearby": "Sharjah Heritage Museum - 0.4 km / 0.2 mi "
                },
                "hdtype": "HOTDEAL",
                "usrst": "Excellent",
                "brdbs": [
                    "Breakfast"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 2298.83,
                "dwtf": 22495.7,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 1509.02,
                "com_disc": 0,
                "rtf": "20197.00",
                "rdwtf": "22496.00"
            },
            {
                "tid": "ntk1wcT9xq8VaPam",
                "pid": "74275369",
                "hs": 0,
                "rmid": "315981976",
                "rtid": "385192169",
                "refundable": false,
                "available_rooms": 1,
                "score": 22,
                "sno": "315",
                "cont": {
                    "_id": "62506c312aee3e1bb50b6b93",
                    "ratings": "0.0",
                    "name": "LuX BnB Studio Downtown Amazing Views",
                    "location": {
                        "coordinates": {
                            "latitude": 25.191737,
                            "longitude": 55.273362
                        },
                        "obfuscatedCoordinates": {
                            "latitude": 25.191214,
                            "longitude": 55.272625
                        },
                        "obfuscationRequired": false
                    },
                    "externalId": "74275369",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/75000000/74280000/74275400/74275369/00726cf9_b.jpg",
                    "ha": [],
                    "guestreview": {
                        "cleaniness": 0,
                        "staff_service": 0,
                        "amenities": 0,
                        "conditions_facilities": 0
                    },
                    "rat": "0.0",
                    "urat": "",
                    "expediareview": 0,
                    "pid": "74275369",
                    "line1": "Dubai",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Apartment",
                    "nearby": "Souk al Bahar - 0.6 km / 0.4 mi "
                },
                "hdtype": "HOTDEAL",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 2216.73,
                "dwtf": 8893.19,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 665.84,
                "com_disc": 0,
                "rtf": "6676.00",
                "rdwtf": "8893.00"
            },
            {
                "tid": "5qMRVdJ3LE9WPsrU",
                "pid": "2536137",
                "hs": 0,
                "rmid": "200330371",
                "rtid": "238254999",
                "refundable": false,
                "available_rooms": 2,
                "score": 243,
                "sno": "292",
                "cont": {
                    "_id": "600d42e9a1bc371b80ff3496",
                    "name": "Al Waleed Palace Hotel Apartments Oud Metha",
                    "ratings": "0.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.235622,
                            "longitude": 55.306465
                        }
                    },
                    "externalId": "2536137",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/3000000/2540000/2536200/2536137/fbce578e_b.jpg",
                    "ha": [
                        "POOL",
                        "POOL",
                        "WIFI",
                        "GYM"
                    ],
                    "guestreview": {
                        "cleaniness": "3.6",
                        "staff_service": "3.8",
                        "amenities": "2.9",
                        "conditions_facilities": "3.6"
                    },
                    "rat": "0.0",
                    "urat": "3.6",
                    "expediareview": 110,
                    "pid": "2536137",
                    "line1": "Oud Metha Road",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Aparthotel",
                    "nearby": "Lamcy Plaza - 0.3 km / 0.2 mi "
                },
                "hdtype": "HOTDEAL",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 2134.63,
                "dwtf": 8366.1,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 475.37,
                "com_disc": 0,
                "rtf": "6231.00",
                "rdwtf": "8366.00"
            },
            {
                "tid": "zZDNAC9tVGUQQUke",
                "pid": "227448",
                "hs": 0,
                "rmid": "202421669",
                "rtid": "242284828",
                "refundable": false,
                "available_rooms": 4,
                "score": 330,
                "sno": "386",
                "cont": {
                    "_id": "600d4316a1bc371b80ff76d2",
                    "name": "Ajman Hotel",
                    "ratings": "5.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.422752,
                            "longitude": 55.443025
                        }
                    },
                    "externalId": "227448",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/1000000/230000/227500/227448/2199be5f_b.jpg",
                    "ha": [
                        "BAR",
                        "GYM",
                        "POOL",
                        "POOL",
                        "BAR"
                    ],
                    "guestreview": {
                        "cleaniness": "4.5",
                        "staff_service": "4.4",
                        "amenities": "4.3",
                        "conditions_facilities": "4.2"
                    },
                    "rat": "5.0",
                    "urat": "4.3",
                    "expediareview": 367,
                    "pid": "227448",
                    "line1": "Sheikh Humaid Bin Rashid Al Nuaimi Str.",
                    "city": "Ajman",
                    "countryCode": "AE",
                    "catnm": "Resort",
                    "nearby": "Ajman Museum - 1.4 km / 0.9 mi "
                },
                "hdtype": "HOTDEAL",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 2134.63,
                "dwtf": 10617.31,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 633.82,
                "com_disc": 0,
                "rtf": "8483.00",
                "rdwtf": "10617.00"
            },
            {
                "tid": "MU70vypz4p2H4a7p",
                "pid": "67628753",
                "hs": 0,
                "rmid": "314216095",
                "rtid": "382694071",
                "refundable": false,
                "available_rooms": 1,
                "score": 32,
                "sno": "294",
                "cont": {
                    "_id": "62506a312aee3e1bb50b6617",
                    "name": "Lux BnB 1BD Green Views",
                    "location": {
                        "coordinates": {
                            "latitude": 25.091381,
                            "longitude": 55.174166
                        },
                        "obfuscationRequired": false
                    },
                    "externalId": "67628753",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/68000000/67630000/67628800/67628753/6270aace_b.jpg",
                    "ha": [],
                    "guestreview": {
                        "cleaniness": 0,
                        "staff_service": 0,
                        "amenities": 0,
                        "conditions_facilities": 0
                    },
                    "rat": "",
                    "urat": "",
                    "expediareview": 0,
                    "ratings": "",
                    "pid": "67628753",
                    "line1": "5th St",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Apartment",
                    "nearby": "Jebel Ali Race Course - 0.3 km / 0.2 mi "
                },
                "hdtype": "HOTDEAL",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 2052.53,
                "dwtf": 8325.05,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 559.93,
                "com_disc": 0,
                "rtf": "6273.00",
                "rdwtf": "8325.00"
            },
            {
                "tid": "sEktVTyTeEFn6k8W",
                "pid": "2102708",
                "hs": 0,
                "rmid": "314252782",
                "rtid": "383635623",
                "refundable": false,
                "available_rooms": 6,
                "score": 482,
                "sno": "361",
                "cont": {
                    "_id": "600d43bca1bc371b800036f3",
                    "name": "Radisson Blu Residence, Dubai Marina",
                    "ratings": "5.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.0786,
                            "longitude": 55.143163
                        }
                    },
                    "externalId": "2102708",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/3000000/2110000/2102800/2102708/09235222_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.4",
                        "staff_service": "4.2",
                        "amenities": "3.9",
                        "conditions_facilities": "4.1"
                    },
                    "rat": "5.0",
                    "urat": "4.1",
                    "expediareview": 484,
                    "pid": "2102708",
                    "line1": "392 Al Marsa Street",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Aparthotel",
                    "nearby": "Dubai Marina Mall - 0.7 km / 0.5 mi "
                },
                "hdtype": "HOTDEAL",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 2052.53,
                "dwtf": 9848.03,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 573.89,
                "com_disc": 0,
                "rtf": "7795.00",
                "rdwtf": "9848.00"
            },
            {
                "tid": "xV3bSR233J5UD4dG",
                "pid": "75879276",
                "hs": 0,
                "rmid": "316234871",
                "rtid": "385641973",
                "refundable": false,
                "available_rooms": 1,
                "score": 17,
                "sno": "532",
                "cont": {
                    "_id": "62506a922aee3e1bb50b669f",
                    "ratings": "0.0",
                    "name": "1B - Anantara - 316 by bnbme homes",
                    "location": {
                        "coordinates": {
                            "latitude": 25.129475,
                            "longitude": 55.153145
                        },
                        "obfuscatedCoordinates": {
                            "latitude": 25.127579,
                            "longitude": 55.15118
                        },
                        "obfuscationRequired": false
                    },
                    "externalId": "75879276",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/76000000/75880000/75879300/75879276/7f05ede4_b.jpg",
                    "ha": [],
                    "guestreview": {
                        "cleaniness": 0,
                        "staff_service": 0,
                        "amenities": 0,
                        "conditions_facilities": 0
                    },
                    "rat": "0.0",
                    "urat": "",
                    "expediareview": 0,
                    "pid": "75879276",
                    "line1": "East Crescent, Palm",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Apartment",
                    "nearby": "Aquaventure - 4.7 km / 2.9 mi "
                },
                "hdtype": "HOTDEAL",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 2052.53,
                "dwtf": 20105.74,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 1035.29,
                "com_disc": 0,
                "rtf": "18053.00",
                "rdwtf": "20106.00"
            },
            {
                "tid": "s3BWKMFRsNgom9P5",
                "pid": "38499620",
                "hs": 0,
                "rmid": "214820658",
                "rtid": "252148998",
                "refundable": false,
                "available_rooms": 10,
                "score": 273,
                "sno": "524",
                "cont": {
                    "_id": "600d3f2ea1bc371b80fa1a6f",
                    "name": "Vida Downtown Residences",
                    "ratings": "0.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.190168,
                            "longitude": 55.274495
                        }
                    },
                    "externalId": "38499620",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/39000000/38500000/38499700/38499620/2eadd671_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "POOL",
                        "POOL"
                    ],
                    "guestreview": {
                        "cleaniness": "4.5",
                        "staff_service": "4.1",
                        "amenities": "5.0",
                        "conditions_facilities": "4.5"
                    },
                    "rat": "0.0",
                    "urat": "4.7",
                    "expediareview": 18,
                    "pid": "38499620",
                    "line1": "Sheikh Mohammed Bin Rasid Boulevard",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Aparthotel",
                    "nearby": "Souk al Bahar - 0.5 km / 0.3 mi "
                },
                "hdtype": "HOTDEAL",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 1970.43,
                "dwtf": 19122.17,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 1309.51,
                "com_disc": 0,
                "rtf": "17152.00",
                "rdwtf": "19122.00"
            },
            {
                "tid": "eFkHOIdd1cobdUdk",
                "pid": "29217023",
                "hs": 0,
                "rmid": "316491535",
                "rtid": "389321222",
                "refundable": false,
                "available_rooms": 1,
                "score": 55,
                "sno": "526",
                "cont": {
                    "_id": "600d3ed9a1bc371b80f9a816",
                    "name": "HiGuests Vacation Homes - Mon Reve",
                    "ratings": "0.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.194224,
                            "longitude": 55.282569
                        }
                    },
                    "externalId": "29217023",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/30000000/29220000/29217100/29217023/c92eaea4_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "3.3",
                        "staff_service": "3.0",
                        "amenities": "3.7",
                        "conditions_facilities": "3.1"
                    },
                    "rat": "0.0",
                    "urat": "3.2",
                    "expediareview": 10,
                    "pid": "29217023",
                    "line1": "Unnamed Rd, Mon Reve Building",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Apartment",
                    "nearby": "Souk al Bahar - 0.7 km / 0.4 mi "
                },
                "hdtype": "HOTDEAL",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 1970.43,
                "dwtf": 19520.36,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 985.21,
                "com_disc": 0,
                "rtf": "17550.00",
                "rdwtf": "19520.00"
            },
            {
                "tid": "7V0qeXKuggFCFuaG",
                "pid": "3181052",
                "hs": 0,
                "rmid": "200303392",
                "rtid": "211686454",
                "refundable": false,
                "available_rooms": 10,
                "score": 193,
                "sno": "44",
                "cont": {
                    "_id": "600d4150a1bc371b80fcf5fa",
                    "name": "Pearl Executive Hotel Apartments",
                    "ratings": "0.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.258564,
                            "longitude": 55.322765
                        }
                    },
                    "externalId": "3181052",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/4000000/3190000/3181100/3181052/c352fb9e_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "POOL",
                        "POOL"
                    ],
                    "guestreview": {
                        "cleaniness": "3.4",
                        "staff_service": "3.4",
                        "amenities": "3.1",
                        "conditions_facilities": "3.3"
                    },
                    "rat": "0.0",
                    "urat": "3.4",
                    "expediareview": 278,
                    "pid": "3181052",
                    "line1": "Riggat Al Buteen Street",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Aparthotel",
                    "nearby": "Al Ghurair Centre - 1.4 km / 0.9 mi "
                },
                "hdtype": "HOTDEAL",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 1888.33,
                "dwtf": 5265.14,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 207.72,
                "com_disc": 0,
                "rtf": "3377.00",
                "rdwtf": "5265.00"
            },
            {
                "tid": "TaLNNTeFBTGiGeOE",
                "pid": "66777651",
                "hs": 0,
                "rmid": "225930980",
                "rtid": "246317140",
                "refundable": false,
                "available_rooms": 2,
                "score": 17,
                "sno": "331",
                "cont": {
                    "_id": "62506be92aee3e1bb50b6a9d",
                    "ratings": "0.0",
                    "name": "Bespoke Residence - Luxury Studio Apt",
                    "location": {
                        "coordinates": {
                            "latitude": 25.183165,
                            "longitude": 55.272887
                        },
                        "obfuscationRequired": false
                    },
                    "externalId": "66777651",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/67000000/66780000/66777700/66777651/5d3c6dd3_b.jpg",
                    "ha": [
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": 0,
                        "staff_service": 0,
                        "amenities": 0,
                        "conditions_facilities": 0
                    },
                    "rat": "0.0",
                    "urat": "",
                    "expediareview": 0,
                    "pid": "66777651",
                    "line1": "Al Asayel Street, Business Bay",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Apartment",
                    "nearby": "Dubai Water Canal - 0.1 km / 0.1 mi "
                },
                "hdtype": "HOTDEAL",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 1806.22,
                "dwtf": 8819.3,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 577.17,
                "com_disc": 0,
                "rtf": "7013.00",
                "rdwtf": "8819.00"
            },
            {
                "tid": "0iFSdV3GU9vg9wl0",
                "pid": "1210385",
                "hs": 0,
                "rmid": "201499065",
                "rtid": "207592228",
                "refundable": false,
                "available_rooms": 10,
                "score": 387,
                "sno": "502",
                "cont": {
                    "_id": "600d3ea2a1bc371b80f9614c",
                    "name": "Golden Sands Hotel Apartments",
                    "ratings": "3.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.251559,
                            "longitude": 55.295027
                        }
                    },
                    "externalId": "1210385",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/2000000/1220000/1210400/1210385/0b314243_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "3.9",
                        "staff_service": "3.9",
                        "amenities": "3.9",
                        "conditions_facilities": "3.6"
                    },
                    "rat": "3.0",
                    "urat": "3.8",
                    "expediareview": 1979,
                    "pid": "1210385",
                    "line1": "Al Mankhool Road Bur Dubai",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Aparthotel",
                    "nearby": "BurJuman Mall - 1.2 km / 0.7 mi "
                },
                "hdtype": "HOTDEAL",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 1806.22,
                "dwtf": 17242.05,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 1178.15,
                "com_disc": 0,
                "rtf": "15436.00",
                "rdwtf": "17242.00"
            },
            {
                "tid": "oJ7szl3FUFX3xoVu",
                "pid": "2212742",
                "hs": 0,
                "rmid": "213021662",
                "rtid": "240353484",
                "refundable": true,
                "available_rooms": 5,
                "score": 473,
                "sno": "408",
                "cont": {
                    "_id": "600d4130a1bc371b80fcd382",
                    "name": "Ascott Park Place Dubai",
                    "ratings": "4.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.2244,
                            "longitude": 55.28346
                        }
                    },
                    "externalId": "2212742",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/3000000/2220000/2212800/2212742/98007744_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.5",
                        "staff_service": "4.3",
                        "amenities": "4.4",
                        "conditions_facilities": "4.4"
                    },
                    "rat": "4.0",
                    "urat": "4.4",
                    "expediareview": 185,
                    "pid": "2212742",
                    "line1": "Park Place Tower, Sheikh Zayed Road",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Aparthotel",
                    "nearby": "Dubai International Financial Centre - 1.5 km / 0.9 mi "
                },
                "hdtype": "HOTDEAL",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 1724.12,
                "dwtf": 10960.5,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 706.89,
                "com_disc": 0,
                "rtf": "9236.00",
                "rdwtf": "10961.00"
            },
            {
                "tid": "M0VWaT5sAUy9IDVQ",
                "pid": "42571677",
                "hs": 0,
                "rmid": "214233517",
                "rtid": "248196267",
                "refundable": true,
                "available_rooms": 1,
                "score": 206,
                "sno": "496",
                "cont": {
                    "_id": "600d3f46a1bc371b80fa3ee4",
                    "name": "Vida Emirates Hills Residences",
                    "ratings": "0.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.078651,
                            "longitude": 55.157495
                        }
                    },
                    "externalId": "42571677",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/43000000/42580000/42571700/42571677/af7e06db_b.jpg",
                    "ha": [
                        "BAR",
                        "GYM",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "2.0",
                        "staff_service": "3.0",
                        "amenities": 0,
                        "conditions_facilities": "3.0"
                    },
                    "rat": "0.0",
                    "urat": "3.0",
                    "expediareview": 1,
                    "pid": "42571677",
                    "line1": "Al Naseem Street Emiartes Hills area",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Aparthotel",
                    "nearby": "American University of Dubai (AUD) - 1.8 km / 1.1 mi "
                },
                "hdtype": "HOTDEAL",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 1724.12,
                "dwtf": 16739.59,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 1151.06,
                "com_disc": 0,
                "rtf": "15015.00",
                "rdwtf": "16740.00"
            },
            {
                "tid": "gxObntgFqckD9l2o",
                "pid": "2802091",
                "hs": 0,
                "rmid": "200051752",
                "rtid": "238320765",
                "refundable": true,
                "available_rooms": 8,
                "score": 315,
                "sno": "275",
                "cont": {
                    "_id": "600d4256a1bc371b80fe6f8c",
                    "name": "Number One Tower Suites",
                    "ratings": "0.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.210304,
                            "longitude": 55.273769
                        }
                    },
                    "externalId": "2802091",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/3000000/2810000/2802100/2802091/d4ff1440_b.jpg",
                    "ha": [
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "3.7",
                        "staff_service": "3.5",
                        "amenities": "2.9",
                        "conditions_facilities": "3.4"
                    },
                    "rat": "0.0",
                    "urat": "3.6",
                    "expediareview": 349,
                    "pid": "2802091",
                    "line1": "Sheikh Zayed Road 1",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Aparthotel",
                    "nearby": "Dubai International Financial Centre - 1.1 km / 0.7 mi "
                },
                "hdtype": "HOTDEAL",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 1559.92,
                "dwtf": 7473.66,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 453.2,
                "com_disc": 0,
                "rtf": "5914.00",
                "rdwtf": "7474.00"
            },
            {
                "tid": "JgSIuqI8Hpc6MHDk",
                "pid": "10120476",
                "hs": 0,
                "rmid": "200931441",
                "rtid": "272400904",
                "refundable": true,
                "available_rooms": 1,
                "score": 295,
                "sno": "460",
                "cont": {
                    "_id": "600d4236a1bc371b80fe43aa",
                    "name": "Deira Suites Hotel Apartment",
                    "ratings": "4.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.260739,
                            "longitude": 55.319141
                        }
                    },
                    "externalId": "10120476",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/11000000/10130000/10120500/10120476/c6a2e1dd_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.5",
                        "staff_service": "4.3",
                        "amenities": "4.4",
                        "conditions_facilities": "4.3"
                    },
                    "rat": "4.0",
                    "urat": "4.4",
                    "expediareview": 50,
                    "pid": "10120476",
                    "line1": "Al Maktoum Road",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Aparthotel",
                    "nearby": "Al Ghurair Centre - 1 km / 0.6 mi "
                },
                "hdtype": "HOTDEAL",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 1559.92,
                "dwtf": 14204.31,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 957.3,
                "com_disc": 0,
                "rtf": "12644.00",
                "rdwtf": "14204.00"
            },
            {
                "tid": "gNvKQ032pfr2Q2OG",
                "pid": "8745057",
                "hs": 0,
                "rmid": "200746668",
                "rtid": "203709391",
                "refundable": true,
                "available_rooms": 11,
                "score": 427,
                "sno": "470",
                "cont": {
                    "_id": "600d40bea1bc371b80fc378b",
                    "name": "Roda Amwaj Suites",
                    "ratings": "0.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.07524,
                            "longitude": 55.131399
                        }
                    },
                    "externalId": "8745057",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/9000000/8750000/8745100/8745057/0b4dd011_b.jpg",
                    "ha": [
                        "GYM",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.2",
                        "staff_service": "4.1",
                        "amenities": "4.0",
                        "conditions_facilities": "4.1"
                    },
                    "rat": "0.0",
                    "urat": "4.2",
                    "expediareview": 478,
                    "pid": "8745057",
                    "line1": "The Walk, Jumeirah Beach Residence",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Aparthotel",
                    "nearby": "The Walk - 0.1 km / 0.1 mi "
                },
                "hdtype": "HOTDEAL",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 1559.92,
                "dwtf": 14820.07,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 1011.49,
                "com_disc": 0,
                "rtf": "13260.00",
                "rdwtf": "14820.00"
            },
            {
                "tid": "q1xsyOyKabQuxhCW",
                "pid": "18810662",
                "hs": 0,
                "rmid": "202025604",
                "rtid": "210375675",
                "refundable": true,
                "available_rooms": 5,
                "score": 188,
                "sno": "228",
                "cont": {
                    "_id": "600d4117a1bc371b80fcb76b",
                    "name": "FLORIDA CITY HOTEL APARTMENTS",
                    "ratings": "0.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.26863,
                            "longitude": 55.30587
                        }
                    },
                    "externalId": "18810662",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/19000000/18820000/18810700/18810662/54b57f9e_b.jpg",
                    "ha": [
                        "GYM",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.0",
                        "staff_service": "4.0",
                        "amenities": "4.3",
                        "conditions_facilities": "3.6"
                    },
                    "rat": "0.0",
                    "urat": "3.9",
                    "expediareview": 31,
                    "pid": "18810662",
                    "line1": "Baniyas Square, Deira",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Aparthotel",
                    "nearby": "Deira Twin Towers Shopping Centre - 0.5 km / 0.3 mi "
                },
                "hdtype": "HOTDEAL",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 1477.82,
                "dwtf": 6778.27,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 404.76,
                "com_disc": 0,
                "rtf": "5300.00",
                "rdwtf": "6778.00"
            },
            {
                "tid": "XfDQKqceWdEWy0Fg",
                "pid": "52970555",
                "hs": 0,
                "rmid": "232542708",
                "rtid": "290352758",
                "refundable": false,
                "available_rooms": 1,
                "score": 40,
                "sno": "256",
                "cont": {
                    "_id": "600d3d8ea1bc371b80f7ca1c",
                    "name": "Golden Stay Vacation Continental Tower",
                    "ratings": "0.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.07996,
                            "longitude": 55.139128
                        }
                    },
                    "externalId": "52970555",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/53000000/52980000/52970600/52970555/8878db78_b.jpg",
                    "ha": [],
                    "guestreview": {
                        "cleaniness": "4.0",
                        "staff_service": 0,
                        "amenities": 0,
                        "conditions_facilities": 0
                    },
                    "rat": "0.0",
                    "urat": "5.0",
                    "expediareview": 1,
                    "pid": "52970555",
                    "line1": "King Salman Bin Abdulaziz Al Saud",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Apartment",
                    "nearby": "The Walk - 0.4 km / 0.2 mi "
                },
                "hdtype": "HOTDEAL",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 1477.82,
                "dwtf": 7091.89,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 505.74,
                "com_disc": 0,
                "rtf": "5614.00",
                "rdwtf": "7092.00"
            },
            {
                "tid": "5wsBdgkiKTvoTAA5",
                "pid": "4308762",
                "hs": 0,
                "rmid": "200472052",
                "rtid": "386018777",
                "refundable": false,
                "available_rooms": 10,
                "score": 157,
                "sno": "258",
                "cont": {
                    "_id": "600d3ea3a1bc371b80f961c8",
                    "name": "Ewan Ajman Suites Hotel",
                    "ratings": "4.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.394467,
                            "longitude": 55.485578
                        }
                    },
                    "externalId": "4308762",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/5000000/4310000/4308800/4308762/498c81b0_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "POOL",
                        "POOL"
                    ],
                    "guestreview": {
                        "cleaniness": "3.5",
                        "staff_service": "3.6",
                        "amenities": 0,
                        "conditions_facilities": "3.3"
                    },
                    "rat": "4.0",
                    "urat": "3.5",
                    "expediareview": 53,
                    "pid": "4308762",
                    "line1": "Sheikh Maktoum Bin Rashid Street",
                    "city": "Ajman",
                    "countryCode": "AE",
                    "catnm": "Aparthotel",
                    "nearby": "City Centre Ajman - 1.4 km / 0.9 mi "
                },
                "hdtype": "HOTDEAL",
                "usrst": "Very Good",
                "brdbs": [
                    "Breakfast"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 1477.82,
                "dwtf": 7112.42,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 421.18,
                "com_disc": 0,
                "rtf": "5635.00",
                "rdwtf": "7112.00"
            },
            {
                "tid": "WqSwMoJrmct9AMAa",
                "pid": "2586645",
                "hs": 0,
                "rmid": "212112763",
                "rtid": "389666601",
                "refundable": false,
                "available_rooms": 5,
                "score": 394,
                "sno": "447",
                "cont": {
                    "_id": "600d403ba1bc371b80fb7c14",
                    "name": "Suha Hotel Apartments JBR",
                    "ratings": "0.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.080679,
                            "longitude": 55.138402
                        }
                    },
                    "externalId": "2586645",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/3000000/2590000/2586700/2586645/0faf715f_b.jpg",
                    "ha": [
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.0",
                        "staff_service": "4.0",
                        "amenities": "4.3",
                        "conditions_facilities": "3.7"
                    },
                    "rat": "0.0",
                    "urat": "4.0",
                    "expediareview": 252,
                    "pid": "2586645",
                    "line1": "Sadaf 3, Jumeirah Beach residence",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Aparthotel",
                    "nearby": "The Walk - 0.2 km / 0.1 mi "
                },
                "hdtype": "HOTDEAL",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 1395.72,
                "dwtf": 13061.46,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 889.98,
                "com_disc": 0,
                "rtf": "11666.00",
                "rdwtf": "13061.00"
            },
            {
                "tid": "TyMeevrR1DTdBsk8",
                "pid": "2224895",
                "hs": 0,
                "rmid": "289220",
                "rtid": "200207720",
                "refundable": false,
                "available_rooms": 7,
                "score": 275,
                "sno": "143",
                "cont": {
                    "_id": "600d4151a1bc371b80fcf6f2",
                    "name": "Chelsea Gardens Hotel Apartments",
                    "ratings": "4.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.04844,
                            "longitude": 55.13078
                        }
                    },
                    "externalId": "2224895",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/3000000/2230000/2224900/2224895/87b8116c_b.jpg",
                    "ha": [
                        "GYM"
                    ],
                    "guestreview": {
                        "cleaniness": "4.1",
                        "staff_service": "3.9",
                        "amenities": "1.0",
                        "conditions_facilities": "3.9"
                    },
                    "rat": "4.0",
                    "urat": "3.7",
                    "expediareview": 92,
                    "pid": "2224895",
                    "line1": "Discovery Gardens",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Aparthotel",
                    "nearby": "Ibn Battuta Mall - 1.4 km / 0.9 mi "
                },
                "hdtype": "HOTDEAL",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 1231.52,
                "dwtf": 5560.71,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 328.4,
                "com_disc": 0,
                "rtf": "4329.00",
                "rdwtf": "5561.00"
            },
            {
                "tid": "Rg7EcsqqrzT6lyL4",
                "pid": "10076887",
                "hs": 0,
                "rmid": "315615994",
                "rtid": "386767254",
                "refundable": false,
                "available_rooms": 5,
                "score": 298,
                "sno": "179",
                "cont": {
                    "_id": "600d4204a1bc371b80fe0181",
                    "name": "MENA APARTHOTEL",
                    "ratings": "0.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.117043,
                            "longitude": 55.19632
                        }
                    },
                    "externalId": "10076887",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/11000000/10080000/10076900/10076887/1aff8b64_b.jpg",
                    "ha": [
                        "POOL",
                        "GYM"
                    ],
                    "guestreview": {
                        "cleaniness": "4.1",
                        "staff_service": "4.1",
                        "amenities": 0,
                        "conditions_facilities": "4.0"
                    },
                    "rat": "0.0",
                    "urat": "3.9",
                    "expediareview": 120,
                    "pid": "10076887",
                    "line1": "A4 Street, Al Barsha 1",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Aparthotel",
                    "nearby": "Mall of the Emirates - 0.6 km / 0.4 mi "
                },
                "hdtype": "HOTDEAL",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 1231.52,
                "dwtf": 5868.59,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 353.86,
                "com_disc": 0,
                "rtf": "4637.00",
                "rdwtf": "5869.00"
            },
            {
                "tid": "cSnxwGtqTmIsHDZe",
                "pid": "2442729",
                "hs": 0,
                "rmid": "200761967",
                "rtid": "242169746",
                "refundable": false,
                "available_rooms": 2,
                "score": 266,
                "sno": "189",
                "cont": {
                    "_id": "600d3ffea1bc371b80fb2a66",
                    "name": "Al Khoory Hotel Apartments",
                    "ratings": "0.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.112866,
                            "longitude": 55.193337
                        }
                    },
                    "externalId": "2442729",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/3000000/2450000/2442800/2442729/fb41948b_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.3",
                        "staff_service": "4.3",
                        "amenities": "4.8",
                        "conditions_facilities": "4.2"
                    },
                    "rat": "0.0",
                    "urat": "4.3",
                    "expediareview": 328,
                    "pid": "2442729",
                    "line1": "Sheikh Zayed Road, Al Barsha",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Aparthotel",
                    "nearby": "Fatima Abdullah Mohammed Rasheed Mosque - 0.3 km / 0.2 mi "
                },
                "hdtype": "HOTDEAL",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 1231.52,
                "dwtf": 6013.91,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 365.35,
                "com_disc": 0,
                "rtf": "4782.00",
                "rdwtf": "6014.00"
            },
            {
                "tid": "1N63TNML2vXxfmNw",
                "pid": "2222430",
                "hs": 0,
                "rmid": "229210767",
                "rtid": "268286859",
                "refundable": false,
                "available_rooms": 8,
                "score": 197,
                "sno": "316",
                "cont": {
                    "_id": "600d4206a1bc371b80fe032c",
                    "name": "Marbella Resort Sharjah",
                    "ratings": "4.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.341507,
                            "longitude": 55.384885
                        }
                    },
                    "externalId": "2222430",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/3000000/2230000/2222500/2222430/c94a3a3e_b.jpg",
                    "ha": [
                        "BAR",
                        "POOL",
                        "POOL",
                        "BAR",
                        "POOL"
                    ],
                    "guestreview": {
                        "cleaniness": "3.6",
                        "staff_service": "3.6",
                        "amenities": "3.0",
                        "conditions_facilities": "3.3"
                    },
                    "rat": "4.0",
                    "urat": "3.6",
                    "expediareview": 30,
                    "pid": "2222430",
                    "line1": "Buhaira Corniche Road",
                    "city": "Sharjah",
                    "countryCode": "AE",
                    "catnm": "Resort",
                    "nearby": "Crystal Plaza - 0.6 km / 0.4 mi "
                },
                "hdtype": "HOTDEAL",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 1231.52,
                "dwtf": 7915.37,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 495.07,
                "com_disc": 0,
                "rtf": "6684.00",
                "rdwtf": "7915.00"
            },
            {
                "tid": "JmwQbCIDTxsE90Xb",
                "pid": "9813763",
                "hs": 0,
                "rmid": "321496914",
                "rtid": "388944640",
                "refundable": false,
                "available_rooms": 25,
                "score": 397,
                "sno": "68",
                "cont": {
                    "_id": "600d40e5a1bc371b80fc7454",
                    "name": "Emirates Grand Hotel Apartments",
                    "ratings": "0.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.211093,
                            "longitude": 55.274427
                        }
                    },
                    "externalId": "9813763",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/10000000/9820000/9813800/9813763/0f77b2fd_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL"
                    ],
                    "guestreview": {
                        "cleaniness": "3.0",
                        "staff_service": "3.0",
                        "amenities": "3.4",
                        "conditions_facilities": "2.9"
                    },
                    "rat": "0.0",
                    "urat": "3.1",
                    "expediareview": 365,
                    "pid": "9813763",
                    "line1": "Sheikh Zayed Road, Trade Centr",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Aparthotel",
                    "nearby": "Dubai International Financial Centre - 1.2 km / 0.7 mi "
                },
                "hdtype": "HOTDEAL",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 1149.42,
                "dwtf": 4785.67,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 277.5,
                "com_disc": 0,
                "rtf": "3636.00",
                "rdwtf": "4786.00"
            },
            {
                "tid": "8EZUoCWqbp9tHbou",
                "pid": "2206423",
                "hs": 0,
                "rmid": "314052479",
                "rtid": "381440241",
                "refundable": false,
                "available_rooms": 35,
                "score": 382,
                "sno": "421",
                "cont": {
                    "_id": "600d3f75a1bc371b80fa83cd",
                    "name": "Flora Creek Deluxe Hotel Apartments",
                    "ratings": "5.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.253129,
                            "longitude": 55.327856
                        }
                    },
                    "externalId": "2206423",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/3000000/2210000/2206500/2206423/27c92a06_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.4",
                        "staff_service": "4.5",
                        "amenities": "4.5",
                        "conditions_facilities": "4.4"
                    },
                    "rat": "5.0",
                    "urat": "4.4",
                    "expediareview": 350,
                    "pid": "2206423",
                    "line1": "Near Deira City Centre",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Aparthotel",
                    "nearby": "City Centre Deira - 1.2 km / 0.8 mi "
                },
                "hdtype": "HOTDEAL",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 1149.42,
                "dwtf": 10931.76,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 747.12,
                "com_disc": 0,
                "rtf": "9782.00",
                "rdwtf": "10932.00"
            },
            {
                "tid": "9O4JApWup8T0ou89",
                "pid": "46271513",
                "hs": 0,
                "rmid": "230715857",
                "rtid": "278306430",
                "refundable": false,
                "available_rooms": 2,
                "score": 36,
                "sno": "426",
                "cont": {
                    "_id": "600d4186a1bc371b80fd3867",
                    "name": "Staycae Business Bay City View",
                    "ratings": "0.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.180318,
                            "longitude": 55.268396
                        }
                    },
                    "externalId": "46271513",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/47000000/46280000/46271600/46271513/29996123_b.jpg",
                    "ha": [],
                    "guestreview": {
                        "cleaniness": "1.0",
                        "staff_service": 0,
                        "amenities": 0,
                        "conditions_facilities": 0
                    },
                    "rat": "0.0",
                    "urat": "1.0",
                    "expediareview": 1,
                    "pid": "46271513",
                    "line1": "Marasi Drive, Business Bay",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Apartment",
                    "nearby": "Souk al Bahar - 3.3 km / 2 mi "
                },
                "hdtype": "HOTDEAL",
                "usrst": "Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 1149.42,
                "dwtf": 11301.22,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 483.58,
                "com_disc": 0,
                "rtf": "10152.00",
                "rdwtf": "11301.00"
            },
            {
                "tid": "vJlgxAMiOdhXA29Q",
                "pid": "54292500",
                "hs": 0,
                "rmid": "227734295",
                "rtid": "258372371",
                "refundable": false,
                "available_rooms": 14,
                "score": 356,
                "sno": "13",
                "cont": {
                    "_id": "600d422fa1bc371b80fe3920",
                    "name": "The Leela Hotel Deira",
                    "ratings": "0.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.251966,
                            "longitude": 55.332803
                        }
                    },
                    "externalId": "54292500",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/55000000/54300000/54292500/54292500/76c4e7a2_b.jpg",
                    "ha": [
                        "POOL",
                        "POOL",
                        "BAR",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.0",
                        "staff_service": "4.0",
                        "amenities": 0,
                        "conditions_facilities": "3.5"
                    },
                    "rat": "0.0",
                    "urat": "3.5",
                    "expediareview": 2,
                    "pid": "54292500",
                    "line1": "8th St",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Property",
                    "nearby": "City Centre Deira - 0.2 km / 0.1 mi "
                },
                "hdtype": "HOTDEAL",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 1067.31,
                "dwtf": 4039.37,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 206.07,
                "com_disc": 0,
                "rtf": "2972.00",
                "rdwtf": "4039.00"
            },
            {
                "tid": "XCgwQVcGtT4KVJse",
                "pid": "38262032",
                "hs": 0,
                "rmid": "221457613",
                "rtid": "296346386",
                "refundable": false,
                "available_rooms": 1,
                "score": 26,
                "sno": "314",
                "cont": {
                    "_id": "600d41c4a1bc371b80fda6c6",
                    "name": "One Perfect Stay -  Residence 1",
                    "ratings": "0.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.196081,
                            "longitude": 55.271341
                        }
                    },
                    "externalId": "38262032",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/39000000/38270000/38262100/38262032/56232c40_b.jpg",
                    "ha": [
                        "POOL"
                    ],
                    "guestreview": {
                        "cleaniness": 0,
                        "staff_service": 0,
                        "amenities": 0,
                        "conditions_facilities": 0
                    },
                    "rat": "0.0",
                    "urat": "",
                    "expediareview": 0,
                    "pid": "38262032",
                    "line1": "Residence, Downtown Dubai",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Apartment",
                    "nearby": "Dubai Opera - 0.3 km / 0.2 mi "
                },
                "hdtype": "HOTDEAL",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 1067.31,
                "dwtf": 7728.18,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 600.16,
                "com_disc": 0,
                "rtf": "6661.00",
                "rdwtf": "7728.00"
            },
            {
                "tid": "3zKTqRXsxgXXoWJi",
                "pid": "1141374",
                "hs": 0,
                "rmid": "316073659",
                "rtid": "385368146",
                "refundable": true,
                "available_rooms": 9,
                "score": 291,
                "sno": "400",
                "cont": {
                    "_id": "600d4272a1bc371b80fe9292",
                    "name": "Marriott Executive Apartments Green Community",
                    "ratings": "0.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.003967,
                            "longitude": 55.163652
                        }
                    },
                    "externalId": "1141374",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/2000000/1150000/1141400/1141374/c4972880_b.jpg",
                    "ha": [
                        "BAR",
                        "GYM",
                        "POOL",
                        "POOL"
                    ],
                    "guestreview": {
                        "cleaniness": "4.1",
                        "staff_service": "4.0",
                        "amenities": "5.0",
                        "conditions_facilities": "3.8"
                    },
                    "rat": "0.0",
                    "urat": "3.8",
                    "expediareview": 15,
                    "pid": "1141374",
                    "line1": "Dubai Investment Park",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Aparthotel",
                    "nearby": "Jumeirah Golf Estates - 10.1 km / 6.3 mi "
                },
                "hdtype": "HOTDEAL",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 1067.31,
                "dwtf": 9962.97,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 709.35,
                "com_disc": 0,
                "rtf": "8896.00",
                "rdwtf": "9963.00"
            },
            {
                "tid": "4UqU76IaLaKtRNHM",
                "pid": "33215543",
                "hs": 0,
                "rmid": "217838921",
                "rtid": "272152390",
                "refundable": false,
                "available_rooms": 9,
                "score": 468,
                "sno": "404",
                "cont": {
                    "_id": "600d411fa1bc371b80fcc0c2",
                    "name": "Millennium Atria Business Bay",
                    "ratings": "0.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.179811,
                            "longitude": 55.264327
                        }
                    },
                    "externalId": "33215543",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/34000000/33220000/33215600/33215543/14830209_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL"
                    ],
                    "guestreview": {
                        "cleaniness": "4.0",
                        "staff_service": "3.9",
                        "amenities": "4.1",
                        "conditions_facilities": "4.0"
                    },
                    "rat": "0.0",
                    "urat": "3.9",
                    "expediareview": 46,
                    "pid": "33215543",
                    "line1": "Al Abraj Street, Business Bay",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Aparthotel",
                    "nearby": "Souk al Bahar - 2.8 km / 1.7 mi "
                },
                "hdtype": "HOTDEAL",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 1067.31,
                "dwtf": 10061.49,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 648.6,
                "com_disc": 0,
                "rtf": "8994.00",
                "rdwtf": "10061.00"
            },
            {
                "tid": "nDVycD11rpIpvo0a",
                "pid": "28058049",
                "hs": 0,
                "rmid": "215115603",
                "rtid": "254063052",
                "refundable": false,
                "available_rooms": 21,
                "score": 184,
                "sno": "59",
                "cont": {
                    "_id": "600d3e3da1bc371b80f8bebb",
                    "name": "Westzone Plaza Hotel Apartments",
                    "ratings": "0.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.2491,
                            "longitude": 55.2949
                        }
                    },
                    "externalId": "28058049",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/29000000/28060000/28058100/28058049/a38c60c6_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "3.6",
                        "staff_service": "3.6",
                        "amenities": "3.0",
                        "conditions_facilities": "3.7"
                    },
                    "rat": "0.0",
                    "urat": "4.0",
                    "expediareview": 12,
                    "pid": "28058049",
                    "line1": "Kuwait St",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Aparthotel",
                    "nearby": "BurJuman Mall - 1.4 km / 0.8 mi "
                },
                "hdtype": "HOTDEAL",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 985.21,
                "dwtf": 4578.78,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 274.22,
                "com_disc": 0,
                "rtf": "3594.00",
                "rdwtf": "4579.00"
            },
            {
                "tid": "4FlITytDiInRqFFw",
                "pid": "527499",
                "hs": 0,
                "rmid": "321473336",
                "rtid": "388908853",
                "refundable": false,
                "available_rooms": 7,
                "score": 300,
                "sno": "119",
                "cont": {
                    "_id": "600d3dd9a1bc371b80f83a77",
                    "name": "J5 Hotels - Port Saeed",
                    "ratings": "3.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.254008,
                            "longitude": 55.336057
                        }
                    },
                    "externalId": "527499",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/1000000/530000/527500/527499/fafc2181_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "POOL",
                        "BAR",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.5",
                        "staff_service": "4.6",
                        "amenities": "4.4",
                        "conditions_facilities": "4.3"
                    },
                    "rat": "3.0",
                    "urat": "4.4",
                    "expediareview": 609,
                    "pid": "527499",
                    "line1": "Al Garhoud Road, Deira City Centre",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Aparthotel",
                    "nearby": "City Centre Deira - 0.8 km / 0.5 mi "
                },
                "hdtype": "HOTDEAL",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 985.21,
                "dwtf": 5078.77,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 312.81,
                "com_disc": 0,
                "rtf": "4094.00",
                "rdwtf": "5079.00"
            },
            {
                "tid": "yFaRDzHoDOgcl8B6",
                "pid": "2538761",
                "hs": 0,
                "rmid": "362357",
                "rtid": "236334649",
                "refundable": true,
                "available_rooms": 2,
                "score": 151,
                "sno": "357",
                "cont": {
                    "_id": "600d3e17a1bc371b80f88682",
                    "name": "Royal Club at Palm Jumeirah",
                    "ratings": "0.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.106845,
                            "longitude": 55.150891
                        }
                    },
                    "externalId": "2538761",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/3000000/2540000/2538800/2538761/915668b5_b.jpg",
                    "ha": [
                        "GYM",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.1",
                        "staff_service": "3.7",
                        "amenities": "4.0",
                        "conditions_facilities": "4.0"
                    },
                    "rat": "0.0",
                    "urat": "3.8",
                    "expediareview": 82,
                    "pid": "2538761",
                    "line1": "Al Khushkar Building,J1",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Apartment",
                    "nearby": "American University of Dubai (AUD) - 2.8 km / 1.7 mi "
                },
                "hdtype": "HOTDEAL",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 903.11,
                "dwtf": 8665.77,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 595.23,
                "com_disc": 0,
                "rtf": "7763.00",
                "rdwtf": "8666.00"
            },
            {
                "tid": "rrqC7dU0AG8TKGtK",
                "pid": "50904812",
                "hs": 0,
                "rmid": "230438383",
                "rtid": "276390233",
                "refundable": false,
                "available_rooms": 5,
                "score": 233,
                "sno": "363",
                "cont": {
                    "_id": "600d4195a1bc371b80fd4ca7",
                    "name": "Royal Continental Suites",
                    "ratings": "5.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.183165,
                            "longitude": 55.272887
                        }
                    },
                    "externalId": "50904812",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/51000000/50910000/50904900/50904812/9f4165a7_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": 0,
                        "staff_service": 0,
                        "amenities": 0,
                        "conditions_facilities": 0
                    },
                    "rat": "5.0",
                    "urat": "",
                    "expediareview": 0,
                    "pid": "50904812",
                    "line1": "Business Bay",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Aparthotel",
                    "nearby": "Dubai Water Canal - 0.1 km / 0.1 mi "
                },
                "hdtype": "HOTDEAL",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 903.11,
                "dwtf": 8728.99,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 597.7,
                "com_disc": 0,
                "rtf": "7826.00",
                "rdwtf": "8729.00"
            },
            {
                "tid": "Kb2IK6ZhrOSsu7Ok",
                "pid": "1545207",
                "hs": 0,
                "rmid": "321621905",
                "rtid": "389167549",
                "refundable": true,
                "available_rooms": 44,
                "score": 327,
                "sno": "372",
                "cont": {
                    "_id": "600d42bca1bc371b80fef927",
                    "name": "Savoy Suites Hotel Apartments",
                    "ratings": "0.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.253852,
                            "longitude": 55.297513
                        }
                    },
                    "externalId": "1545207",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/2000000/1550000/1545300/1545207/0d56705c_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "POOL",
                        "POOL"
                    ],
                    "guestreview": {
                        "cleaniness": "4.2",
                        "staff_service": "4.4",
                        "amenities": "4.5",
                        "conditions_facilities": "4.1"
                    },
                    "rat": "0.0",
                    "urat": "4.2",
                    "expediareview": 329,
                    "pid": "1545207",
                    "line1": "Mankhool 12 A Bur Dubai",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Aparthotel",
                    "nearby": "BurJuman Mall - 1 km / 0.6 mi "
                },
                "hdtype": "HOTDEAL",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 903.11,
                "dwtf": 9001.56,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 620.68,
                "com_disc": 0,
                "rtf": "8098.00",
                "rdwtf": "9002.00"
            },
            {
                "tid": "P7qQhKlPUKfaGxfB",
                "pid": "5395774",
                "hs": 0,
                "rmid": "321637879",
                "rtid": "389190763",
                "refundable": false,
                "available_rooms": 48,
                "score": 313,
                "sno": "304",
                "cont": {
                    "_id": "600d405da1bc371b80fbacb9",
                    "name": "Savoy Central Hotel Apartments",
                    "ratings": "3.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.257092,
                            "longitude": 55.289233
                        }
                    },
                    "externalId": "5395774",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/6000000/5400000/5395800/5395774/69cc19c8_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "POOL",
                        "POOL"
                    ],
                    "guestreview": {
                        "cleaniness": "4.5",
                        "staff_service": "4.5",
                        "amenities": "4.3",
                        "conditions_facilities": "4.3"
                    },
                    "rat": "3.0",
                    "urat": "4.4",
                    "expediareview": 400,
                    "pid": "5395774",
                    "line1": "Al Rolla Road Bur Dubai",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Aparthotel",
                    "nearby": "Meena Bazaar - 1 km / 0.6 mi "
                },
                "hdtype": "HOTDEAL",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 821.01,
                "dwtf": 7255.27,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 487.68,
                "com_disc": 0,
                "rtf": "6434.00",
                "rdwtf": "7255.00"
            },
            {
                "tid": "XWxabCdRXzsamSsL",
                "pid": "2844235",
                "hs": 0,
                "rmid": "431642",
                "rtid": "234009349",
                "refundable": false,
                "available_rooms": 10,
                "score": 251,
                "sno": "324",
                "cont": {
                    "_id": "600d4162a1bc371b80fd07be",
                    "name": "Al Bustan Residence Hotel-Apartments",
                    "ratings": "4.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.274486,
                            "longitude": 55.367528
                        }
                    },
                    "externalId": "2844235",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/3000000/2850000/2844300/2844235/0672829c_b.jpg",
                    "ha": [
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "3.7",
                        "staff_service": "3.9",
                        "amenities": "4.5",
                        "conditions_facilities": "3.4"
                    },
                    "rat": "4.0",
                    "urat": "3.6",
                    "expediareview": 100,
                    "pid": "2844235",
                    "line1": "Al Qusais Area at Al Nahda Street",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Aparthotel",
                    "nearby": "Al-Maktoum Stadium - 2.7 km / 1.7 mi "
                },
                "hdtype": "HOTDEAL",
                "usrst": "Very Good",
                "brdbs": [
                    "Breakfast"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 821.01,
                "dwtf": 7670.71,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 518.88,
                "com_disc": 0,
                "rtf": "6850.00",
                "rdwtf": "7671.00"
            },
            {
                "tid": "grc4HD8TeBGEQ5IS",
                "pid": "39747310",
                "hs": 0,
                "rmid": "220264647",
                "rtid": "288263486",
                "refundable": true,
                "available_rooms": 26,
                "score": 375,
                "sno": "329",
                "cont": {
                    "_id": "600d4357a1bc371b80ffb678",
                    "name": "Millennium Place Barsha Heights Hotel Apartments",
                    "ratings": "0.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.094581,
                            "longitude": 55.179032
                        }
                    },
                    "externalId": "39747310",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/40000000/39750000/39747400/39747310/2980435b_b.jpg",
                    "ha": [
                        "BAR",
                        "GYM",
                        "POOL",
                        "BAR",
                        "POOL"
                    ],
                    "guestreview": {
                        "cleaniness": "4.2",
                        "staff_service": "4.1",
                        "amenities": "3.9",
                        "conditions_facilities": "4.3"
                    },
                    "rat": "0.0",
                    "urat": "4.1",
                    "expediareview": 43,
                    "pid": "39747310",
                    "line1": "First Al Khail Street,Barsha Heights",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Aparthotel",
                    "nearby": "Jebel Ali Race Course - 0.5 km / 0.3 mi "
                },
                "hdtype": "HOTDEAL",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 821.01,
                "dwtf": 7730.64,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 524.63,
                "com_disc": 0,
                "rtf": "6910.00",
                "rdwtf": "7731.00"
            },
            {
                "tid": "iUhaf397lLSKECB0",
                "pid": "48602706",
                "hs": 0,
                "rmid": "231927038",
                "rtid": "286331508",
                "refundable": false,
                "available_rooms": 1,
                "score": 32,
                "sno": "335",
                "cont": {
                    "_id": "600d41aba1bc371b80fd7388",
                    "name": "Al Ashrafia Holiday - Marina Pinnacle",
                    "ratings": "0.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.088456,
                            "longitude": 55.148064
                        }
                    },
                    "externalId": "48602706",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/49000000/48610000/48602800/48602706/91f2f415_b.jpg",
                    "ha": [],
                    "guestreview": {
                        "cleaniness": 0,
                        "staff_service": 0,
                        "amenities": 0,
                        "conditions_facilities": 0
                    },
                    "rat": "0.0",
                    "urat": "",
                    "expediareview": 0,
                    "pid": "48602706",
                    "line1": "Pinnacle Tiger Tower Marina",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Apartment",
                    "nearby": "The Walk - 1.2 km / 0.8 mi "
                },
                "hdtype": "HOTDEAL",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 821.01,
                "dwtf": 8002.39,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 586.2,
                "com_disc": 0,
                "rtf": "7181.00",
                "rdwtf": "8002.00"
            },
            {
                "tid": "ihhsBRM0GD0bFTNm",
                "pid": "3949414",
                "hs": 0,
                "rmid": "316380982",
                "rtid": "385943729",
                "refundable": false,
                "available_rooms": 2,
                "score": 291,
                "sno": "252",
                "cont": {
                    "_id": "600d3ea7a1bc371b80f96601",
                    "name": "Auris Boutique Hotel Apartments",
                    "ratings": "0.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.117523,
                            "longitude": 55.195195
                        }
                    },
                    "externalId": "3949414",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/4000000/3950000/3949500/3949414/6388a765_b.jpg",
                    "ha": [
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.2",
                        "staff_service": "4.1",
                        "amenities": "4.2",
                        "conditions_facilities": "4.0"
                    },
                    "rat": "0.0",
                    "urat": "4.0",
                    "expediareview": 141,
                    "pid": "3949414",
                    "line1": "Al Barsha 1, Near Mall of the Emirates",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Aparthotel",
                    "nearby": "Mall of the Emirates - 0.6 km / 0.4 mi "
                },
                "hdtype": "HOTDEAL",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 738.91,
                "dwtf": 6325.07,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 404.76,
                "com_disc": 0,
                "rtf": "5586.00",
                "rdwtf": "6325.00"
            },
            {
                "tid": "UZDMbEpZBtq81a0C",
                "pid": "16623092",
                "hs": 0,
                "rmid": "201759679",
                "rtid": "288405760",
                "refundable": true,
                "available_rooms": 2,
                "score": 221,
                "sno": "272",
                "cont": {
                    "_id": "600d3ec8a1bc371b80f98fd9",
                    "name": "Rolla Residence Hotel Apartment",
                    "ratings": "0.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.256299,
                            "longitude": 55.289396
                        }
                    },
                    "externalId": "16623092",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/17000000/16630000/16623100/16623092/62984476_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.2",
                        "staff_service": "4.3",
                        "amenities": "2.0",
                        "conditions_facilities": "4.0"
                    },
                    "rat": "0.0",
                    "urat": "4.2",
                    "expediareview": 12,
                    "pid": "16623092",
                    "line1": "Al Rolla Road, Bur Dubai",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Aparthotel",
                    "nearby": "Meena Bazaar - 1.1 km / 0.7 mi "
                },
                "hdtype": "HOTDEAL",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 738.91,
                "dwtf": 6624.74,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 446.63,
                "com_disc": 0,
                "rtf": "5886.00",
                "rdwtf": "6625.00"
            },
            {
                "tid": "du8eaFuKgx14XtQg",
                "pid": "57483154",
                "hs": 0,
                "rmid": "316297567",
                "rtid": "389646404",
                "refundable": false,
                "available_rooms": 1,
                "score": 205,
                "sno": "302",
                "cont": {
                    "_id": "600d421ba1bc371b80fe1c9e",
                    "name": "Suha Mina Rashid Hotel Apartment",
                    "ratings": "0.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.251634,
                            "longitude": 55.291354
                        }
                    },
                    "externalId": "57483154",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/58000000/57490000/57483200/57483154/c7891eda_b.jpg",
                    "ha": [
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": 0,
                        "staff_service": 0,
                        "amenities": 0,
                        "conditions_facilities": 0
                    },
                    "rat": "0.0",
                    "urat": "",
                    "expediareview": 0,
                    "pid": "57483154",
                    "line1": "Al Mankhool Rd, Bur Dubai",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Aparthotel",
                    "nearby": "Meena Bazaar - 1.4 km / 0.9 mi "
                },
                "hdtype": "HOTDEAL",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 738.91,
                "dwtf": 7139.51,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 488.5,
                "com_disc": 0,
                "rtf": "6401.00",
                "rdwtf": "7140.00"
            },
            {
                "tid": "BiXyeoNmzBxM8Llw",
                "pid": "73446353",
                "hs": 0,
                "rmid": "315873007",
                "rtid": "384881816",
                "refundable": false,
                "available_rooms": 18,
                "score": 102,
                "sno": "176",
                "cont": {
                    "_id": "62506bee2aee3e1bb50b6ab4",
                    "name": "West Zone Pearl Hotel Apartment",
                    "location": {
                        "coordinates": {
                            "latitude": 25.255514,
                            "longitude": 55.301995
                        },
                        "obfuscationRequired": false
                    },
                    "externalId": "73446353",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/74000000/73450000/73446400/73446353/f6b52bfe_b.jpg",
                    "ha": [
                        "GYM",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": 0,
                        "staff_service": 0,
                        "amenities": 0,
                        "conditions_facilities": 0
                    },
                    "rat": "",
                    "urat": "",
                    "expediareview": 0,
                    "ratings": "",
                    "pid": "73446353",
                    "line1": "Bur Dubai Bank street",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Aparthotel",
                    "nearby": "BurJuman Mall - 0.6 km / 0.4 mi "
                },
                "hdtype": "HOTDEAL",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 656.81,
                "dwtf": 5263.5,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 394.91,
                "com_disc": 0,
                "rtf": "4607.00",
                "rdwtf": "5264.00"
            },
            {
                "tid": "x9lvykNyEk9uw4WI",
                "pid": "4333545",
                "hs": 0,
                "rmid": "201817729",
                "rtid": "228030585",
                "refundable": true,
                "available_rooms": 10,
                "score": 444,
                "sno": "201",
                "cont": {
                    "_id": "600d3d76a1bc371b80f7a7da",
                    "name": "Marina View Hotel Apartments",
                    "ratings": "4.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.07927,
                            "longitude": 55.144618
                        }
                    },
                    "externalId": "4333545",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/5000000/4340000/4333600/4333545/9a90c92f_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "3.9",
                        "staff_service": "3.7",
                        "amenities": "3.8",
                        "conditions_facilities": "3.6"
                    },
                    "rat": "4.0",
                    "urat": "3.7",
                    "expediareview": 494,
                    "pid": "4333545",
                    "line1": "Dubai Marina",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Aparthotel",
                    "nearby": "Dubai Marina Mall - 0.8 km / 0.5 mi "
                },
                "hdtype": "HOTDEAL",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 656.81,
                "dwtf": 5550.86,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 370.28,
                "com_disc": 0,
                "rtf": "4894.00",
                "rdwtf": "5551.00"
            },
            {
                "tid": "fDbTsV8fny5mM6oK",
                "pid": "12739457",
                "hs": 0,
                "rmid": "201341389",
                "rtid": "209504496",
                "refundable": false,
                "available_rooms": 9,
                "score": 223,
                "sno": "245",
                "cont": {
                    "_id": "600d424da1bc371b80fe6262",
                    "name": "Treppan Hotel & Suites by Fakhruddin",
                    "ratings": "0.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.04213,
                            "longitude": 55.221744
                        }
                    },
                    "externalId": "12739457",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/13000000/12740000/12739500/12739457/29cd0cff_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.3",
                        "staff_service": "4.1",
                        "amenities": "4.1",
                        "conditions_facilities": "4.2"
                    },
                    "rat": "0.0",
                    "urat": "4.1",
                    "expediareview": 81,
                    "pid": "12739457",
                    "line1": "Dubai Sports City",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Aparthotel",
                    "nearby": "Dubai Sports City - 1 km / 0.6 mi "
                },
                "hdtype": "HOTDEAL",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 656.81,
                "dwtf": 6200.28,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 423.64,
                "com_disc": 0,
                "rtf": "5543.00",
                "rdwtf": "6200.00"
            },
            {
                "tid": "wHWxLOSaushO2AyK",
                "pid": "58007149",
                "hs": 0,
                "rmid": "228646493",
                "rtid": "264360064",
                "refundable": false,
                "available_rooms": 10,
                "score": 173,
                "sno": "248",
                "cont": {
                    "_id": "600d3f4aa1bc371b80fa435f",
                    "name": "New Royal Mark Hotel Apartments",
                    "ratings": "0.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.269271,
                            "longitude": 55.307624
                        }
                    },
                    "externalId": "58007149",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/59000000/58010000/58007200/58007149/5f787082_b.jpg",
                    "ha": [],
                    "guestreview": {
                        "cleaniness": 0,
                        "staff_service": 0,
                        "amenities": 0,
                        "conditions_facilities": 0
                    },
                    "rat": "0.0",
                    "urat": "",
                    "expediareview": 0,
                    "pid": "58007149",
                    "line1": "Dubai",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Aparthotel",
                    "nearby": "Naif Souq - 0.6 km / 0.3 mi "
                },
                "hdtype": "HOTDEAL",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 656.81,
                "dwtf": 6210.13,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 383.41,
                "com_disc": 0,
                "rtf": "5553.00",
                "rdwtf": "6210.00"
            },
            {
                "tid": "X0Iq9JnzmK67UerT",
                "pid": "5317671",
                "hs": 0,
                "rmid": "212403832",
                "rtid": "236057558",
                "refundable": true,
                "available_rooms": 30,
                "score": 368,
                "sno": "253",
                "cont": {
                    "_id": "600d4105a1bc371b80fca229",
                    "name": "Citadines Metro Central Dubai",
                    "ratings": "4.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.099017,
                            "longitude": 55.173809
                        }
                    },
                    "externalId": "5317671",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/6000000/5320000/5317700/5317671/e4ece337_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.3",
                        "staff_service": "4.3",
                        "amenities": "4.3",
                        "conditions_facilities": "4.2"
                    },
                    "rat": "4.0",
                    "urat": "4.2",
                    "expediareview": 598,
                    "pid": "5317671",
                    "line1": "Tecom Area, Al Barsha",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Aparthotel",
                    "nearby": "Jebel Ali Race Course - 1.2 km / 0.8 mi "
                },
                "hdtype": "HOTDEAL",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 656.81,
                "dwtf": 6244.61,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 427.75,
                "com_disc": 0,
                "rtf": "5588.00",
                "rdwtf": "6245.00"
            },
            {
                "tid": "2uTS6dwO0LNM7w7F",
                "pid": "55405808",
                "hs": 0,
                "rmid": "230153768",
                "rtid": "389666621",
                "refundable": false,
                "available_rooms": 3,
                "score": 122,
                "sno": "259",
                "cont": {
                    "_id": "600d420da1bc371b80fe0de5",
                    "name": "Suha Park Hotel Apartment",
                    "ratings": "0.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.225359,
                            "longitude": 55.337816
                        }
                    },
                    "externalId": "55405808",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/56000000/55410000/55405900/55405808/716acf1a_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL"
                    ],
                    "guestreview": {
                        "cleaniness": 0,
                        "staff_service": 0,
                        "amenities": 0,
                        "conditions_facilities": 0
                    },
                    "rat": "0.0",
                    "urat": "",
                    "expediareview": 0,
                    "pid": "55405808",
                    "line1": "7 Al Jaddaf, Waterfront Dubai",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Aparthotel",
                    "nearby": "Mohammed Bin Rashid University Of Medicine and Health Sciences - 3.1 km / 1.9 mi "
                },
                "hdtype": "HOTDEAL",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 656.81,
                "dwtf": 6308.65,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 431.03,
                "com_disc": 0,
                "rtf": "5652.00",
                "rdwtf": "6309.00"
            },
            {
                "tid": "VtKJSpT3HhP9nRcQ",
                "pid": "2824212",
                "hs": 0,
                "rmid": "200219532",
                "rtid": "212056983",
                "refundable": true,
                "available_rooms": 3,
                "score": 184,
                "sno": "260",
                "cont": {
                    "_id": "600d3e9aa1bc371b80f95797",
                    "name": "TIME Ruby Hotel Apartments",
                    "ratings": "4.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.333476,
                            "longitude": 55.361314
                        }
                    },
                    "externalId": "2824212",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/3000000/2830000/2824300/2824212/901b3607_b.jpg",
                    "ha": [
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "3.3",
                        "staff_service": "3.7",
                        "amenities": "3.5",
                        "conditions_facilities": "3.3"
                    },
                    "rat": "4.0",
                    "urat": "3.6",
                    "expediareview": 87,
                    "pid": "2824212",
                    "line1": "Al Khan Facing The Beach",
                    "city": "Sharjah",
                    "countryCode": "AE",
                    "catnm": "Aparthotel",
                    "nearby": "Sharjah Aquarium - 0.6 km / 0.4 mi "
                },
                "hdtype": "HOTDEAL",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 656.81,
                "dwtf": 6337.38,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 424.46,
                "com_disc": 0,
                "rtf": "5681.00",
                "rdwtf": "6337.00"
            },
            {
                "tid": "QEMT4LgFTfolxatv",
                "pid": "1554896",
                "hs": 0,
                "rmid": "321627220",
                "rtid": "389178087",
                "refundable": true,
                "available_rooms": 96,
                "score": 288,
                "sno": "261",
                "cont": {
                    "_id": "600d43ada1bc371b800021db",
                    "name": "Savoy Park Hotel Apartments",
                    "ratings": "0.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.253621,
                            "longitude": 55.296332
                        }
                    },
                    "externalId": "1554896",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/2000000/1560000/1554900/1554896/cd3fbffe_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "POOL",
                        "POOL"
                    ],
                    "guestreview": {
                        "cleaniness": "4.2",
                        "staff_service": "4.4",
                        "amenities": "4.2",
                        "conditions_facilities": "4.0"
                    },
                    "rat": "0.0",
                    "urat": "4.2",
                    "expediareview": 285,
                    "pid": "1554896",
                    "line1": "10B Community 317Mankhool",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Aparthotel",
                    "nearby": "BurJuman Mall - 1.2 km / 0.7 mi "
                },
                "hdtype": "HOTDEAL",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 656.81,
                "dwtf": 6343.95,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 435.96,
                "com_disc": 0,
                "rtf": "5687.00",
                "rdwtf": "6344.00"
            },
            {
                "tid": "WFQpFJZq0TWGuQc6",
                "pid": "8800126",
                "hs": 0,
                "rmid": "200900520",
                "rtid": "204563301",
                "refundable": true,
                "available_rooms": 2147483647,
                "score": 349,
                "sno": "322",
                "cont": {
                    "_id": "600d4081a1bc371b80fbdccf",
                    "name": "Adagio Premium Dubai Al Barsha",
                    "ratings": "0.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.113019,
                            "longitude": 55.188299
                        }
                    },
                    "externalId": "8800126",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/9000000/8810000/8800200/8800126/c7b7a6a1_b.jpg",
                    "ha": [
                        "BAR",
                        "GYM",
                        "POOL",
                        "POOL",
                        "BAR"
                    ],
                    "guestreview": {
                        "cleaniness": "4.5",
                        "staff_service": "4.5",
                        "amenities": "4.1",
                        "conditions_facilities": "4.5"
                    },
                    "rat": "0.0",
                    "urat": "4.5",
                    "expediareview": 85,
                    "pid": "8800126",
                    "line1": "Sheikh Zayed Road",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Aparthotel",
                    "nearby": "Fatima Abdullah Mohammed Rasheed Mosque - 0.8 km / 0.5 mi "
                },
                "hdtype": "HOTDEAL",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 656.81,
                "dwtf": 7445.75,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 518.06,
                "com_disc": 0,
                "rtf": "6789.00",
                "rdwtf": "7446.00"
            },
            {
                "tid": "ffUdD5bCZApK25CD",
                "pid": "5773629",
                "hs": 0,
                "rmid": "215446212",
                "rtid": "256230474",
                "refundable": true,
                "available_rooms": 2147483647,
                "score": 303,
                "sno": "145",
                "cont": {
                    "_id": "600d3ff5a1bc371b80fb1d29",
                    "name": "Movenpick Hotel Apartments Al Mamzar Dubai",
                    "ratings": "0.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.290855,
                            "longitude": 55.354705
                        }
                    },
                    "externalId": "5773629",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/6000000/5780000/5773700/5773629/43018062_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "3.9",
                        "staff_service": "4.0",
                        "amenities": "3.7",
                        "conditions_facilities": "3.9"
                    },
                    "rat": "0.0",
                    "urat": "3.8",
                    "expediareview": 335,
                    "pid": "5773629",
                    "line1": "Corner Cairo road and 46th street",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Aparthotel",
                    "nearby": "Al-Maktoum Stadium - 0.8 km / 0.5 mi "
                },
                "hdtype": "HOTDEAL",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 574.71,
                "dwtf": 4921.96,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 331.69,
                "com_disc": 0,
                "rtf": "4347.00",
                "rdwtf": "4922.00"
            },
            {
                "tid": "ZshxDnd2zITmawor",
                "pid": "57482292",
                "hs": 0,
                "rmid": "231958396",
                "rtid": "389666570",
                "refundable": false,
                "available_rooms": 1,
                "score": 216,
                "sno": "208",
                "cont": {
                    "_id": "600d43bca1bc371b80003698",
                    "name": "Suha Creek Hotel Apartment",
                    "ratings": "0.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.225929,
                            "longitude": 55.340265
                        }
                    },
                    "externalId": "57482292",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/58000000/57490000/57482300/57482292/36930483_b.jpg",
                    "ha": [
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": 0,
                        "staff_service": 0,
                        "amenities": 0,
                        "conditions_facilities": 0
                    },
                    "rat": "0.0",
                    "urat": "",
                    "expediareview": 0,
                    "pid": "57482292",
                    "line1": "Jaddaf Waterfront, Al Jaddaf",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Aparthotel",
                    "nearby": "Mohammed Bin Rashid University Of Medicine and Health Sciences - 3.4 km / 2.1 mi "
                },
                "hdtype": "HOTDEAL",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 574.71,
                "dwtf": 5549.21,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 379.31,
                "com_disc": 0,
                "rtf": "4975.00",
                "rdwtf": "5549.00"
            },
            {
                "tid": "J5gf6UThnFL5OclS",
                "pid": "75614085",
                "hs": 0,
                "rmid": "316157974",
                "rtid": "385534387",
                "refundable": false,
                "available_rooms": 14,
                "score": 48,
                "sno": "211",
                "cont": {
                    "_id": "62506bd32aee3e1bb50b6a4f",
                    "ratings": "0.0",
                    "name": "City Stay Premium Hotel Apartments",
                    "location": {
                        "coordinates": {
                            "latitude": 25.273212,
                            "longitude": 55.307283
                        },
                        "obfuscatedCoordinates": {
                            "latitude": 25.272624,
                            "longitude": 55.309034
                        },
                        "obfuscationRequired": false
                    },
                    "externalId": "75614085",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/76000000/75620000/75614100/75614085/0dcbe211_b.jpg",
                    "ha": [],
                    "guestreview": {
                        "cleaniness": 0,
                        "staff_service": 0,
                        "amenities": 0,
                        "conditions_facilities": 0
                    },
                    "rat": "0.0",
                    "urat": "",
                    "expediareview": 0,
                    "pid": "75614085",
                    "line1": "Naif St",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Aparthotel",
                    "nearby": "Naif Souq - 0.5 km / 0.3 mi "
                },
                "hdtype": "HOTDEAL",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 574.71,
                "dwtf": 5575.49,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 430.21,
                "com_disc": 0,
                "rtf": "5001.00",
                "rdwtf": "5575.00"
            },
            {
                "tid": "WCRAN9gopfivN7IE",
                "pid": "45628261",
                "hs": 0,
                "rmid": "233405611",
                "rtid": "296312642",
                "refundable": false,
                "available_rooms": 4,
                "score": 44,
                "sno": "113",
                "cont": {
                    "_id": "600d4113a1bc371b80fcb2a4",
                    "name": "Better Living Hotel Apartment",
                    "ratings": "0.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.256323,
                            "longitude": 55.30403
                        }
                    },
                    "externalId": "45628261",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/46000000/45630000/45628300/45628261/483381e4_b.jpg",
                    "ha": [
                        "POOL",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "3.5",
                        "staff_service": "3.0",
                        "amenities": "5.0",
                        "conditions_facilities": "2.5"
                    },
                    "rat": "0.0",
                    "urat": "3.5",
                    "expediareview": 4,
                    "pid": "45628261",
                    "line1": "20th St",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Apartment",
                    "nearby": "BurJuman Mall - 0.5 km / 0.3 mi "
                },
                "hdtype": "HOTDEAL",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 492.61,
                "dwtf": 4566.46,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 331.69,
                "com_disc": 0,
                "rtf": "4074.00",
                "rdwtf": "4566.00"
            },
            {
                "tid": "2tUDSW7sFQEJxB5X",
                "pid": "31918874",
                "hs": 0,
                "rmid": "217833499",
                "rtid": "272135078",
                "refundable": false,
                "available_rooms": 1,
                "score": 266,
                "sno": "155",
                "cont": {
                    "_id": "600d3f7fa1bc371b80fa8f1b",
                    "name": "Tulip Al Barsha Hotel Apartment",
                    "ratings": "0.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.116528,
                            "longitude": 55.196111
                        }
                    },
                    "externalId": "31918874",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/32000000/31920000/31918900/31918874/32a17695_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.4",
                        "staff_service": "4.4",
                        "amenities": "3.8",
                        "conditions_facilities": "4.5"
                    },
                    "rat": "0.0",
                    "urat": "4.4",
                    "expediareview": 41,
                    "pid": "31918874",
                    "line1": "Al Barsha 1, Beside Mall of The Emirates",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Aparthotel",
                    "nearby": "Fatima Abdullah Mohammed Rasheed Mosque - 0.6 km / 0.4 mi "
                },
                "hdtype": "HOTDEAL",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 492.61,
                "dwtf": 4905.54,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 338.26,
                "com_disc": 0,
                "rtf": "4413.00",
                "rdwtf": "4906.00"
            },
            {
                "tid": "XT14p0VDDPLng6O2",
                "pid": "59202819",
                "hs": 0,
                "rmid": "232564109",
                "rtid": "388082383",
                "refundable": false,
                "available_rooms": 1,
                "score": 20,
                "sno": "385",
                "cont": {
                    "_id": "600d40c2a1bc371b80fc3d81",
                    "name": "Al Ashrafia Luxury Apts-Downtown",
                    "ratings": "0.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.195255,
                            "longitude": 55.283496
                        }
                    },
                    "externalId": "59202819",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/60000000/59210000/59202900/59202819/85072ee2_b.jpg",
                    "ha": [
                        "POOL",
                        "POOL"
                    ],
                    "guestreview": {
                        "cleaniness": 0,
                        "staff_service": 0,
                        "amenities": 0,
                        "conditions_facilities": 0
                    },
                    "rat": "0.0",
                    "urat": "",
                    "expediareview": 0,
                    "pid": "59202819",
                    "line1": "Downtown Dubai",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Apartment",
                    "nearby": "Dubai Water Canal - 1.1 km / 0.7 mi "
                },
                "hdtype": "HOTDEAL",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 492.61,
                "dwtf": 8964.62,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 763.54,
                "com_disc": 0,
                "rtf": "8472.00",
                "rdwtf": "8965.00"
            },
            {
                "tid": "WWnN2tn152RAF8Bh",
                "pid": "31783592",
                "hs": 0,
                "rmid": "216324591",
                "rtid": "384764982",
                "refundable": false,
                "available_rooms": 6,
                "score": 145,
                "sno": "32",
                "cont": {
                    "_id": "600d412ca1bc371b80fccf6c",
                    "name": "Al Raien Hotel Apartment",
                    "ratings": "0.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.261868,
                            "longitude": 55.290477
                        }
                    },
                    "externalId": "31783592",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/32000000/31790000/31783600/31783592/91bfd9dc_b.jpg",
                    "ha": [],
                    "guestreview": {
                        "cleaniness": "3.2",
                        "staff_service": "3.2",
                        "amenities": "3.0",
                        "conditions_facilities": "2.5"
                    },
                    "rat": "0.0",
                    "urat": "3.2",
                    "expediareview": 9,
                    "pid": "31783592",
                    "line1": "Al Riff Road, , Bur Dubai",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Aparthotel",
                    "nearby": "Textile Souk - 0.5 km / 0.3 mi "
                },
                "hdtype": "HOTDEAL",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 410.51,
                "dwtf": 3614.09,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 245.48,
                "com_disc": 0,
                "rtf": "3204.00",
                "rdwtf": "3614.00"
            },
            {
                "tid": "EnyfyeolWqdBSimx",
                "pid": "4301167",
                "hs": 0,
                "rmid": "200741844",
                "rtid": "205633545",
                "refundable": false,
                "available_rooms": 1,
                "score": 184,
                "sno": "60",
                "cont": {
                    "_id": "600d3db1a1bc371b80f80d62",
                    "name": "Nihal Residency Hotel Apartments",
                    "ratings": "0.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.256083,
                            "longitude": 55.299644
                        }
                    },
                    "externalId": "4301167",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/5000000/4310000/4301200/4301167/2e015177_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "3.3",
                        "staff_service": "3.3",
                        "amenities": "1.0",
                        "conditions_facilities": "3.1"
                    },
                    "rat": "0.0",
                    "urat": "3.4",
                    "expediareview": 37,
                    "pid": "4301167",
                    "line1": "Behind ADCB Bank &",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Aparthotel",
                    "nearby": "BurJuman Mall - 0.8 km / 0.5 mi "
                },
                "hdtype": "HOTDEAL",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 410.51,
                "dwtf": 4009.82,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 275.04,
                "com_disc": 0,
                "rtf": "3599.00",
                "rdwtf": "4010.00"
            },
            {
                "tid": "Xy4QweTBPvMNRZzO",
                "pid": "70886931",
                "hs": 0,
                "rmid": "316073008",
                "rtid": "385366682",
                "refundable": true,
                "available_rooms": 44,
                "score": 21,
                "sno": "343",
                "cont": {
                    "_id": "62506a0f2aee3e1bb50b65ea",
                    "ratings": "0.0",
                    "name": "Millennium Place Mirdif Apartments",
                    "location": {
                        "coordinates": {
                            "latitude": 25.226121,
                            "longitude": 55.441351
                        },
                        "obfuscationRequired": false
                    },
                    "externalId": "70886931",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/71000000/70890000/70887000/70886931/d584e65e_b.jpg",
                    "ha": [
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": 0,
                        "staff_service": 0,
                        "amenities": 0,
                        "conditions_facilities": 0
                    },
                    "rat": "0.0",
                    "urat": "",
                    "expediareview": 0,
                    "pid": "70886931",
                    "line1": "The Avenue, 78th Street",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Apartment",
                    "nearby": "Mushrif Park - 2.2 km / 1.4 mi "
                },
                "hdtype": "HOTDEAL",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 410.51,
                "dwtf": 7824.23,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 535.3,
                "com_disc": 0,
                "rtf": "7414.00",
                "rdwtf": "7824.00"
            },
            {
                "tid": "NbPbHGSzey8laTKJ",
                "pid": "48602582",
                "hs": 0,
                "rmid": "228615608",
                "rtid": "264293607",
                "refundable": false,
                "available_rooms": 1,
                "score": 52,
                "sno": "128",
                "cont": {
                    "_id": "600d3fc2a1bc371b80fadb01",
                    "name": "Al Ashrafia Holiday Waterfront Downtown",
                    "ratings": "0.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.187658,
                            "longitude": 55.284098
                        }
                    },
                    "externalId": "48602582",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/49000000/48610000/48602600/48602582/7946c441_b.jpg",
                    "ha": [],
                    "guestreview": {
                        "cleaniness": "5.0",
                        "staff_service": 0,
                        "amenities": "5.0",
                        "conditions_facilities": 0
                    },
                    "rat": "0.0",
                    "urat": "5.0",
                    "expediareview": 2,
                    "pid": "48602582",
                    "line1": "Damac Maison Canal View Building Marasi",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Apartment",
                    "nearby": "Dubai Water Canal - 0.1 km / 0.1 mi "
                },
                "hdtype": "HOTDEAL",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 246.3,
                "dwtf": 4415.4,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 339.9,
                "com_disc": 0,
                "rtf": "4169.00",
                "rdwtf": "4415.00"
            },
            {
                "tid": "pLi9Fst2rzhvT49X",
                "pid": "44327124",
                "hs": 0,
                "rmid": "232219815",
                "rtid": "288328291",
                "refundable": false,
                "available_rooms": 3,
                "score": 100,
                "sno": "0",
                "cont": {
                    "_id": "600d429aa1bc371b80fec79c",
                    "name": "Heartland Hostel",
                    "ratings": "0.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.217021,
                            "longitude": 55.278424
                        }
                    },
                    "externalId": "44327124",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/45000000/44330000/44327200/44327124/0b1cb078_b.jpg",
                    "ha": [
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "3.3",
                        "staff_service": "3.5",
                        "amenities": 0,
                        "conditions_facilities": "3.5"
                    },
                    "rat": "0.0",
                    "urat": "3.5",
                    "expediareview": 6,
                    "pid": "44327124",
                    "line1": "Al Safa Tower",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Hostel/Backpacker accommodation",
                    "nearby": "Dubai International Financial Centre - 0.9 km / 0.5 mi "
                },
                "hdtype": "HOTDEAL",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 164.2,
                "dwtf": 1376.01,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 101.81,
                "com_disc": 0,
                "rtf": "1212.00",
                "rdwtf": "1376.00"
            },
            {
                "tid": "b1Fdw8weSIpVFtzz",
                "pid": "40092178",
                "hs": 0,
                "rmid": "219047357",
                "rtid": "280167997",
                "refundable": false,
                "available_rooms": 1,
                "score": 58,
                "sno": "611",
                "cont": {
                    "_id": "600d3fb9a1bc371b80facfd7",
                    "name": "Dubai Creek Club Villas",
                    "ratings": "0.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.241668,
                            "longitude": 55.333521
                        }
                    },
                    "externalId": "40092178",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/41000000/40100000/40092200/40092178/7c1a0e8f_b.jpg",
                    "ha": [],
                    "guestreview": {
                        "cleaniness": "4.0",
                        "staff_service": "4.0",
                        "amenities": 0,
                        "conditions_facilities": "3.0"
                    },
                    "rat": "0.0",
                    "urat": "4.0",
                    "expediareview": 2,
                    "pid": "40092178",
                    "line1": "Port Saeed Area, Opp. Deira City Centre",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Villa",
                    "nearby": "Dubai Creek Golf & Yacht Club - 0.1 km / 0.1 mi "
                },
                "hdtype": "NOHOTDEAL",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 90125.66,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 7594.35,
                "com_disc": 0,
                "rtf": "90126.00",
                "rdwtf": "90126.00"
            },
            {
                "tid": "LwcGiQ0HMyLPL82F",
                "pid": "71606802",
                "hs": 0,
                "rmid": "315634437",
                "rtid": "384184467",
                "refundable": false,
                "available_rooms": 1,
                "score": 16,
                "sno": "610",
                "cont": {
                    "_id": "62506b512aee3e1bb50b685c",
                    "ratings": "0.0",
                    "name": "LUX White Modern Villa",
                    "location": {
                        "coordinates": {
                            "latitude": 25.245043,
                            "longitude": 55.257689
                        },
                        "obfuscationRequired": false
                    },
                    "externalId": "71606802",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/72000000/71610000/71606900/71606802/809e0e37_b.jpg",
                    "ha": [],
                    "guestreview": {
                        "cleaniness": 0,
                        "staff_service": 0,
                        "amenities": 0,
                        "conditions_facilities": 0
                    },
                    "rat": "0.0",
                    "urat": "",
                    "expediareview": 0,
                    "pid": "71606802",
                    "line1": "Pearl Jumeirah",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Villa",
                    "nearby": "Etihad Museum - 1.7 km / 1.1 mi "
                },
                "hdtype": "NOHOTDEAL",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 72057.67,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 3329.2,
                "com_disc": 0,
                "rtf": "72058.00",
                "rdwtf": "72058.00"
            },
            {
                "tid": "7cxItuysTVUaDedD",
                "pid": "57333708",
                "hs": 0,
                "rmid": "231630636",
                "rtid": "284296398",
                "refundable": false,
                "available_rooms": 1,
                "score": 17,
                "sno": "609",
                "cont": {
                    "_id": "62506b222aee3e1bb50b67b9",
                    "ratings": "0.0",
                    "name": "Amazing Stay at Dubai Downtown - Souk Al Bahar",
                    "location": {
                        "coordinates": {
                            "latitude": 25.194754,
                            "longitude": 55.276525
                        },
                        "obfuscatedCoordinates": {
                            "latitude": 25.193457,
                            "longitude": 55.274577
                        },
                        "obfuscationRequired": false
                    },
                    "externalId": "57333708",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/58000000/57340000/57333800/57333708/5b04e25a_b.jpg",
                    "ha": [
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": 0,
                        "staff_service": 0,
                        "amenities": 0,
                        "conditions_facilities": 0
                    },
                    "rat": "0.0",
                    "urat": "",
                    "expediareview": 0,
                    "pid": "57333708",
                    "line1": "Unit 8055 Souk Al Bahar,Downtown, boulev",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Apartment",
                    "nearby": "Dubai Fountain - 0.2 km / 0.1 mi "
                },
                "hdtype": "NOHOTDEAL",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 60381.25,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 4705.21,
                "com_disc": 0,
                "rtf": "60381.00",
                "rdwtf": "60381.00"
            },
            {
                "tid": "kiHD3RFCtpVm1Jcs",
                "pid": "65561875",
                "hs": 0,
                "rmid": "227167465",
                "rtid": "254423385",
                "refundable": false,
                "available_rooms": 1,
                "score": 21,
                "sno": "607",
                "cont": {
                    "_id": "62506a502aee3e1bb50b6642",
                    "ratings": "0.0",
                    "name": "LUX Holiday Home - Frond E 1",
                    "location": {
                        "coordinates": {
                            "latitude": 25.128272,
                            "longitude": 55.133531
                        },
                        "obfuscationRequired": false
                    },
                    "externalId": "65561875",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/66000000/65570000/65561900/65561875/c555e45c_b.jpg",
                    "ha": [],
                    "guestreview": {
                        "cleaniness": 0,
                        "staff_service": 0,
                        "amenities": 0,
                        "conditions_facilities": 0
                    },
                    "rat": "0.0",
                    "urat": "",
                    "expediareview": 0,
                    "pid": "65561875",
                    "line1": "Frond E",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Villa",
                    "nearby": "The Pointe - 1.9 km / 1.2 mi "
                },
                "hdtype": "NOHOTDEAL",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 58674.37,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 2287.34,
                "com_disc": 0,
                "rtf": "58674.00",
                "rdwtf": "58674.00"
            },
            {
                "tid": "p4Tp7U1ZvDuLhlPm",
                "pid": "29842432",
                "hs": 0,
                "rmid": "216322542",
                "rtid": "262097349",
                "refundable": false,
                "available_rooms": 2,
                "score": 688,
                "sno": "604",
                "cont": {
                    "_id": "600d3fc5a1bc371b80fadf12",
                    "name": "Mandarin Oriental Jumeira, Dubai",
                    "ratings": "5.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.216767,
                            "longitude": 55.251357
                        }
                    },
                    "externalId": "29842432",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/30000000/29850000/29842500/29842432/2debbc87_b.jpg",
                    "ha": [
                        "BAR",
                        "GYM",
                        "POOL",
                        "BAR",
                        "POOL"
                    ],
                    "guestreview": {
                        "cleaniness": "4.9",
                        "staff_service": "4.8",
                        "amenities": "4.8",
                        "conditions_facilities": "4.9"
                    },
                    "rat": "5.0",
                    "urat": "4.8",
                    "expediareview": 67,
                    "pid": "29842432",
                    "line1": "Jumeira 1, Jumeirah Road",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Resort",
                    "nearby": "La Mer Water Park - 1.1 km / 0.7 mi "
                },
                "hdtype": "NOHOTDEAL",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 50139.96,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 3894.88,
                "com_disc": 0,
                "rtf": "50140.00",
                "rdwtf": "50140.00"
            },
            {
                "tid": "A7OWpGvb3p3INkJt",
                "pid": "30313798",
                "hs": 0,
                "rmid": "220827171",
                "rtid": "292097969",
                "refundable": false,
                "available_rooms": 2,
                "score": 73,
                "sno": "602",
                "cont": {
                    "_id": "600d41bea1bc371b80fd9a78",
                    "name": "J5 Villas Holiday Homes - Barsha Gardens",
                    "ratings": "0.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.106038,
                            "longitude": 55.201194
                        }
                    },
                    "externalId": "30313798",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/31000000/30320000/30313800/30313798/7a6824de_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "POOL",
                        "POOL"
                    ],
                    "guestreview": {
                        "cleaniness": "5.0",
                        "staff_service": "5.0",
                        "amenities": "5.0",
                        "conditions_facilities": "5.0"
                    },
                    "rat": "0.0",
                    "urat": "5.0",
                    "expediareview": 3,
                    "pid": "30313798",
                    "line1": "Street 23, Dubai UAE",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Villa",
                    "nearby": "Al Barsha Pond Park - 0.6 km / 0.4 mi "
                },
                "hdtype": "NOHOTDEAL",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 47932.26,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 3676.49,
                "com_disc": 0,
                "rtf": "47932.00",
                "rdwtf": "47932.00"
            },
            {
                "tid": "C7MsCxzgmgFh56Tn",
                "pid": "3565885",
                "hs": 0,
                "rmid": "213007692",
                "rtid": "391590521",
                "refundable": false,
                "available_rooms": 6,
                "score": 465,
                "sno": "597",
                "cont": {
                    "_id": "600d3e70a1bc371b80f91bac",
                    "name": "Kempinski Hotel & Residences Palm Jumeirah",
                    "ratings": "5.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.112941,
                            "longitude": 55.108842
                        }
                    },
                    "externalId": "3565885",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/4000000/3570000/3565900/3565885/f24b6fc2_b.jpg",
                    "ha": [
                        "BAR",
                        "GYM",
                        "POOL",
                        "POOL",
                        "BAR"
                    ],
                    "guestreview": {
                        "cleaniness": "4.5",
                        "staff_service": "4.3",
                        "amenities": "4.4",
                        "conditions_facilities": "4.4"
                    },
                    "rat": "5.0",
                    "urat": "4.3",
                    "expediareview": 224,
                    "pid": "3565885",
                    "line1": "Crescent West, Palm Jumeirah",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Aparthotel",
                    "nearby": "Aquaventure - 2.7 km / 1.7 mi "
                },
                "hdtype": "NOHOTDEAL",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 41958.59,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 3214.26,
                "com_disc": 0,
                "rtf": "41959.00",
                "rdwtf": "41959.00"
            },
            {
                "tid": "H791FR3UuRBuT7H6",
                "pid": "42292205",
                "hs": 0,
                "rmid": "322436755",
                "rtid": "391055510",
                "refundable": false,
                "available_rooms": 1,
                "score": 30,
                "sno": "592",
                "cont": {
                    "_id": "600d405ba1bc371b80fba7d8",
                    "name": "Westminster Burj Residence",
                    "ratings": "0.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.192674,
                            "longitude": 55.273337
                        }
                    },
                    "externalId": "42292205",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/43000000/42300000/42292300/42292205/53e10606_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": 0,
                        "staff_service": 0,
                        "amenities": 0,
                        "conditions_facilities": 0
                    },
                    "rat": "0.0",
                    "urat": "",
                    "expediareview": 0,
                    "pid": "42292205",
                    "line1": "Burj Khalifa Boulevard",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Residence",
                    "nearby": "Souk al Bahar - 0.7 km / 0.5 mi "
                },
                "hdtype": "NOHOTDEAL",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 36978.34,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 1909.67,
                "com_disc": 0,
                "rtf": "36978.00",
                "rdwtf": "36978.00"
            },
            {
                "tid": "xG4WL6nzTTPik4Rq",
                "pid": "16039653",
                "hs": 0,
                "rmid": "232846247",
                "rtid": "292366928",
                "refundable": true,
                "available_rooms": 4,
                "score": 681,
                "sno": "589",
                "cont": {
                    "_id": "600d4346a1bc371b80ffa410",
                    "name": "Nikki Beach Resort & Spa Dubai",
                    "ratings": "5.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.246887,
                            "longitude": 55.255871
                        }
                    },
                    "externalId": "16039653",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/17000000/16040000/16039700/16039653/4a2f3a26_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "BAR",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.8",
                        "staff_service": "4.6",
                        "amenities": "4.3",
                        "conditions_facilities": "4.7"
                    },
                    "rat": "5.0",
                    "urat": "4.6",
                    "expediareview": 346,
                    "pid": "16039653",
                    "line1": "Pearl Jumeira",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Resort",
                    "nearby": "Jumeirah Mosque - 2.8 km / 1.7 mi "
                },
                "hdtype": "NOHOTDEAL",
                "usrst": "Excellent",
                "brdbs": [
                    "Breakfast"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 36230.39,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 2765.17,
                "com_disc": 0,
                "rtf": "36230.00",
                "rdwtf": "36230.00"
            },
            {
                "tid": "RLfHthwItl89Ay6N",
                "pid": "36087014",
                "hs": 0,
                "rmid": "216939337",
                "rtid": "266173293",
                "refundable": true,
                "available_rooms": 2,
                "score": 44,
                "sno": "586",
                "cont": {
                    "_id": "600d41b3a1bc371b80fd84ce",
                    "name": "J5 Four Bedroom Villa in Mirdif",
                    "ratings": "0.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.231601,
                            "longitude": 55.419434
                        }
                    },
                    "externalId": "36087014",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/37000000/36090000/36087100/36087014/dd2f3f5b_b.jpg",
                    "ha": [],
                    "guestreview": {
                        "cleaniness": "5.0",
                        "staff_service": "5.0",
                        "amenities": 0,
                        "conditions_facilities": "3.0"
                    },
                    "rat": "0.0",
                    "urat": "5.0",
                    "expediareview": 1,
                    "pid": "36087014",
                    "line1": "83rd St, Mirdif",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Villa",
                    "nearby": "Mirdif City Center - 2.7 km / 1.7 mi "
                },
                "hdtype": "NOHOTDEAL",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 33902.83,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 3056.62,
                "com_disc": 0,
                "rtf": "33903.00",
                "rdwtf": "33903.00"
            },
            {
                "tid": "iBETt9UhcmLRJM5D",
                "pid": "31520413",
                "hs": 0,
                "rmid": "314193954",
                "rtid": "390188921",
                "refundable": true,
                "available_rooms": 16,
                "score": 709,
                "sno": "571",
                "cont": {
                    "_id": "600d3e01a1bc371b80f86938",
                    "name": "Caesars Palace Bluewaters Dubai",
                    "ratings": "5.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.081719,
                            "longitude": 55.122735
                        }
                    },
                    "externalId": "31520413",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/32000000/31530000/31520500/31520413/00437c9b_b.jpg",
                    "ha": [
                        "BAR",
                        "GYM",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.8",
                        "staff_service": "4.9",
                        "amenities": "4.9",
                        "conditions_facilities": "4.8"
                    },
                    "rat": "5.0",
                    "urat": "4.9",
                    "expediareview": 98,
                    "pid": "31520413",
                    "line1": "Bluewaters Island D94",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Resort",
                    "nearby": "Bluewaters Island - 0.1 km / 0.1 mi "
                },
                "hdtype": "NOHOTDEAL",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 25753.47,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 1970.43,
                "com_disc": 0,
                "rtf": "25753.00",
                "rdwtf": "25753.00"
            },
            {
                "tid": "PkBQ2EwiqEmEOO1h",
                "pid": "67313618",
                "hs": 0,
                "rmid": "314160230",
                "rtid": "382568989",
                "refundable": false,
                "available_rooms": 1,
                "score": 27,
                "sno": "569",
                "cont": {
                    "_id": "62506b712aee3e1bb50b68dd",
                    "ratings": "0.0",
                    "name": "Gorgeous 2 bed at The Address Dubai Mall",
                    "location": {
                        "coordinates": {
                            "latitude": 25.198765,
                            "longitude": 55.279605
                        },
                        "obfuscatedCoordinates": {
                            "latitude": 25.195622,
                            "longitude": 55.278339
                        },
                        "obfuscationRequired": false
                    },
                    "externalId": "67313618",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/68000000/67320000/67313700/67313618/99e285b8_b.jpg",
                    "ha": [
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": 0,
                        "staff_service": 0,
                        "amenities": 0,
                        "conditions_facilities": 0
                    },
                    "rat": "0.0",
                    "urat": "",
                    "expediareview": 0,
                    "pid": "67313618",
                    "line1": "The Dubai Mall Residences , Apartment 17",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Apartment",
                    "nearby": "KidZania - 0.1 km / 0.1 mi "
                },
                "hdtype": "NOHOTDEAL",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 24912.76,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 1383.4,
                "com_disc": 0,
                "rtf": "24913.00",
                "rdwtf": "24913.00"
            },
            {
                "tid": "OgPgplSg8vbUQoo6",
                "pid": "71916244",
                "hs": 0,
                "rmid": "315705594",
                "rtid": "384333924",
                "refundable": false,
                "available_rooms": 1,
                "score": 26,
                "sno": "558",
                "cont": {
                    "_id": "62506a2b2aee3e1bb50b660f",
                    "ratings": "0.0",
                    "name": "LUX The Modern Marina Suite 2 bed",
                    "location": {
                        "coordinates": {
                            "latitude": 25.069038,
                            "longitude": 55.133247
                        },
                        "obfuscationRequired": false
                    },
                    "externalId": "71916244",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/72000000/71920000/71916300/71916244/969e7f5d_b.jpg",
                    "ha": [],
                    "guestreview": {
                        "cleaniness": 0,
                        "staff_service": 0,
                        "amenities": 0,
                        "conditions_facilities": 0
                    },
                    "rat": "0.0",
                    "urat": "",
                    "expediareview": 0,
                    "pid": "71916244",
                    "line1": "Al Seba St",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Apartment",
                    "nearby": "The Walk - 0.9 km / 0.6 mi "
                },
                "hdtype": "NOHOTDEAL",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 21512.13,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 420.36,
                "com_disc": 0,
                "rtf": "21512.00",
                "rdwtf": "21512.00"
            },
            {
                "tid": "psNJ2ikaJaHRg1PS",
                "pid": "7316461",
                "hs": 0,
                "rmid": "200619354",
                "rtid": "203009450",
                "refundable": true,
                "available_rooms": 2147483647,
                "score": 548,
                "sno": "551",
                "cont": {
                    "_id": "600d3e87a1bc371b80f93d9b",
                    "name": "Sofitel Dubai The Palm Luxury Apartments",
                    "ratings": "5.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.140601,
                            "longitude": 55.132162
                        }
                    },
                    "externalId": "7316461",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/8000000/7320000/7316500/7316461/b594ba2e_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "POOL",
                        "BAR",
                        "POOL"
                    ],
                    "guestreview": {
                        "cleaniness": "4.7",
                        "staff_service": "4.6",
                        "amenities": "4.5",
                        "conditions_facilities": "4.7"
                    },
                    "rat": "5.0",
                    "urat": "4.6",
                    "expediareview": 448,
                    "pid": "7316461",
                    "line1": "East Crescent road",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Apartment",
                    "nearby": "Aquaventure - 1.9 km / 1.2 mi "
                },
                "hdtype": "NOHOTDEAL",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 20663.2,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 1576.34,
                "com_disc": 0,
                "rtf": "20663.00",
                "rdwtf": "20663.00"
            },
            {
                "tid": "GTA17kJKpDRuiegR",
                "pid": "432678",
                "hs": 0,
                "rmid": "322013509",
                "rtid": "390166598",
                "refundable": false,
                "available_rooms": 5,
                "score": 744,
                "sno": "545",
                "cont": {
                    "_id": "600d411da1bc371b80fcbeae",
                    "name": "Le Meridien Mina Seyahi Beach Resort & Marina",
                    "ratings": "5.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.091793,
                            "longitude": 55.147317
                        }
                    },
                    "externalId": "432678",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/1000000/440000/432700/432678/171a0073_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "BAR",
                        "POOL",
                        "POOL"
                    ],
                    "guestreview": {
                        "cleaniness": "4.6",
                        "staff_service": "4.5",
                        "amenities": "4.4",
                        "conditions_facilities": "4.4"
                    },
                    "rat": "5.0",
                    "urat": "4.4",
                    "expediareview": 990,
                    "pid": "432678",
                    "line1": "Al Sofouh Road, Jumeirah Beach",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Resort",
                    "nearby": "American University of Dubai (AUD) - 1.2 km / 0.7 mi "
                },
                "hdtype": "NOHOTDEAL",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 19613.95,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 1178.97,
                "com_disc": 0,
                "rtf": "19614.00",
                "rdwtf": "19614.00"
            },
            {
                "tid": "odXi0I2f9k01S0gZ",
                "pid": "50587854",
                "hs": 0,
                "rmid": "230738465",
                "rtid": "278334189",
                "refundable": false,
                "available_rooms": 5,
                "score": 34,
                "sno": "543",
                "cont": {
                    "_id": "600d41b7a1bc371b80fd8ce5",
                    "name": "Marbella Luxury 2 Bedrooms Balcony",
                    "ratings": "0.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.215886,
                            "longitude": 55.326714
                        }
                    },
                    "externalId": "50587854",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/51000000/50590000/50587900/50587854/6ff0def1_b.jpg",
                    "ha": [
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": 0,
                        "staff_service": 0,
                        "amenities": 0,
                        "conditions_facilities": 0
                    },
                    "rat": "0.0",
                    "urat": "",
                    "expediareview": 0,
                    "pid": "50587854",
                    "line1": "Al Khail Road, D-68",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Apartment",
                    "nearby": "Dubai Festival City Mall - 4.4 km / 2.8 mi "
                },
                "hdtype": "NOHOTDEAL",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 19248.6,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 996.71,
                "com_disc": 0,
                "rtf": "19249.00",
                "rdwtf": "19249.00"
            },
            {
                "tid": "yEsOtyCwpErcCRcc",
                "pid": "50588841",
                "hs": 0,
                "rmid": "231611576",
                "rtid": "284255253",
                "refundable": false,
                "available_rooms": 5,
                "score": 18,
                "sno": "538",
                "cont": {
                    "_id": "600d420aa1bc371b80fe0acb",
                    "name": "Marbella Luxury 2 Bedrooms",
                    "ratings": "0.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.137937,
                            "longitude": 55.260241
                        }
                    },
                    "externalId": "50588841",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/51000000/50590000/50588900/50588841/2d683897_b.jpg",
                    "ha": [
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": 0,
                        "staff_service": 0,
                        "amenities": 0,
                        "conditions_facilities": 0
                    },
                    "rat": "0.0",
                    "urat": "",
                    "expediareview": 0,
                    "pid": "50588841",
                    "line1": "Al Khail Road, D-68",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Apartment",
                    "nearby": "Dubai Water Canal - 6.4 km / 4 mi "
                },
                "hdtype": "NOHOTDEAL",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 18756,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 952.37,
                "com_disc": 0,
                "rtf": "18756.00",
                "rdwtf": "18756.00"
            },
            {
                "tid": "Tgnpq3o8F2IsTLwA",
                "pid": "50587862",
                "hs": 0,
                "rmid": "227724120",
                "rtid": "258317863",
                "refundable": false,
                "available_rooms": 5,
                "score": 17,
                "sno": "534",
                "cont": {
                    "_id": "600d3da7a1bc371b80f7fc56",
                    "name": "Marbella Executive 2 Bedrooms Balcony",
                    "ratings": "3.5",
                    "location": {
                        "coordinates": {
                            "latitude": 25.137937,
                            "longitude": 55.260241
                        }
                    },
                    "externalId": "50587862",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/51000000/50590000/50587900/50587862/45bb63af_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": 0,
                        "staff_service": 0,
                        "amenities": 0,
                        "conditions_facilities": 0
                    },
                    "rat": "3.5",
                    "urat": "",
                    "expediareview": 0,
                    "pid": "50587862",
                    "line1": "Al Khail Road, D-68",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Apartment",
                    "nearby": "Dubai Water Canal - 6.4 km / 4 mi "
                },
                "hdtype": "NOHOTDEAL",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 18345.49,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 915.43,
                "com_disc": 0,
                "rtf": "18345.00",
                "rdwtf": "18345.00"
            },
            {
                "tid": "Kapl6g3MELai6XJB",
                "pid": "5914122",
                "hs": 0,
                "rmid": "200978168",
                "rtid": "204772049",
                "refundable": false,
                "available_rooms": 2147483647,
                "score": 802,
                "sno": "533",
                "cont": {
                    "_id": "600d433da1bc371b80ff9c9a",
                    "name": "Sofitel Dubai The Palm Resort & Spa",
                    "ratings": "5.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.140681,
                            "longitude": 55.132178
                        }
                    },
                    "externalId": "5914122",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/6000000/5920000/5914200/5914122/a4f09dee_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.7",
                        "staff_service": "4.6",
                        "amenities": "4.5",
                        "conditions_facilities": "4.6"
                    },
                    "rat": "5.0",
                    "urat": "4.6",
                    "expediareview": 992,
                    "pid": "5914122",
                    "line1": "East Crescent Road",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Resort",
                    "nearby": "Aquaventure - 1.9 km / 1.2 mi "
                },
                "hdtype": "NOHOTDEAL",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 18141.88,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 1377.66,
                "com_disc": 0,
                "rtf": "18142.00",
                "rdwtf": "18142.00"
            },
            {
                "tid": "P8BxP4TBaohlUL4s",
                "pid": "50588683",
                "hs": 0,
                "rmid": "228923448",
                "rtid": "266309841",
                "refundable": false,
                "available_rooms": 5,
                "score": 24,
                "sno": "531",
                "cont": {
                    "_id": "600d3db5a1bc371b80f8135d",
                    "name": "Marbella Executive 2 Bedrooms",
                    "ratings": "0.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.137937,
                            "longitude": 55.260241
                        }
                    },
                    "externalId": "50588683",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/51000000/50590000/50588700/50588683/aee275a1_b.jpg",
                    "ha": [
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": 0,
                        "staff_service": 0,
                        "amenities": 0,
                        "conditions_facilities": 0
                    },
                    "rat": "0.0",
                    "urat": "",
                    "expediareview": 0,
                    "pid": "50588683",
                    "line1": "Al Khail Road, D-68",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Apartment",
                    "nearby": "Dubai Water Canal - 6.4 km / 4 mi "
                },
                "hdtype": "NOHOTDEAL",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 17852.88,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 870.27,
                "com_disc": 0,
                "rtf": "17853.00",
                "rdwtf": "17853.00"
            },
            {
                "tid": "WkX9LiiXfSEGzd8n",
                "pid": "75876230",
                "hs": 0,
                "rmid": "316233932",
                "rtid": "385640086",
                "refundable": false,
                "available_rooms": 1,
                "score": 16,
                "sno": "529",
                "cont": {
                    "_id": "62506a4e2aee3e1bb50b663f",
                    "name": "1B-Address JBR - 1107 by bnbme homes",
                    "location": {
                        "coordinates": {
                            "latitude": 25.072584,
                            "longitude": 55.126567
                        },
                        "obfuscatedCoordinates": {
                            "latitude": 25.069693,
                            "longitude": 55.126153
                        },
                        "obfuscationRequired": false
                    },
                    "externalId": "75876230",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/76000000/75880000/75876300/75876230/e3150c48_b.jpg",
                    "ha": [],
                    "guestreview": {
                        "cleaniness": 0,
                        "staff_service": 0,
                        "amenities": 0,
                        "conditions_facilities": 0
                    },
                    "rat": "",
                    "urat": "",
                    "expediareview": 0,
                    "ratings": "",
                    "pid": "75876230",
                    "line1": "Address Beach Resort - The Walk",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Apartment",
                    "nearby": "The Walk - 0.1 km / 0.1 mi "
                },
                "hdtype": "NOHOTDEAL",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 17772.43,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 1009.84,
                "com_disc": 0,
                "rtf": "17772.00",
                "rdwtf": "17772.00"
            },
            {
                "tid": "veehhGgELuIn4xv0",
                "pid": "50587861",
                "hs": 0,
                "rmid": "213738638",
                "rtid": "244340426",
                "refundable": false,
                "available_rooms": 5,
                "score": 18,
                "sno": "525",
                "cont": {
                    "_id": "600d3e83a1bc371b80f937bc",
                    "name": "Marbella 2 Bedrooms",
                    "ratings": "0.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.137937,
                            "longitude": 55.26024
                        }
                    },
                    "externalId": "50587861",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/51000000/50590000/50587900/50587861/a529f976_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": 0,
                        "staff_service": 0,
                        "amenities": 0,
                        "conditions_facilities": 0
                    },
                    "rat": "0.0",
                    "urat": "",
                    "expediareview": 0,
                    "pid": "50587861",
                    "line1": "Al Khail Road, D-68",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Apartment",
                    "nearby": "Dubai Water Canal - 6.4 km / 4 mi "
                },
                "hdtype": "NOHOTDEAL",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 17360.28,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 826.76,
                "com_disc": 0,
                "rtf": "17360.00",
                "rdwtf": "17360.00"
            },
            {
                "tid": "9mPcfBonDoEKcqUm",
                "pid": "4263530",
                "hs": 0,
                "rmid": "218109064",
                "rtid": "274026823",
                "refundable": false,
                "available_rooms": 1,
                "score": 360,
                "sno": "520",
                "cont": {
                    "_id": "600d434fa1bc371b80ffadb7",
                    "name": "Marina Hotel Apartments",
                    "ratings": "0.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.08173,
                            "longitude": 55.14578
                        }
                    },
                    "externalId": "4263530",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/5000000/4270000/4263600/4263530/c213a02a_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.3",
                        "staff_service": "4.6",
                        "amenities": "4.2",
                        "conditions_facilities": "4.3"
                    },
                    "rat": "0.0",
                    "urat": "4.5",
                    "expediareview": 122,
                    "pid": "4263530",
                    "line1": "Marina Walk El Emreef Street No 14",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Apartment",
                    "nearby": "The Walk - 1 km / 0.6 mi "
                },
                "hdtype": "NOHOTDEAL",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 16883.27,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 1288.99,
                "com_disc": 0,
                "rtf": "16883.00",
                "rdwtf": "16883.00"
            },
            {
                "tid": "dZnAlnxT7cmQMPug",
                "pid": "2211788",
                "hs": 0,
                "rmid": "290446",
                "rtid": "383932705",
                "refundable": false,
                "available_rooms": 1,
                "score": 473,
                "sno": "518",
                "cont": {
                    "_id": "600d3fa6a1bc371b80fab892",
                    "name": "Jumeirah Living - World Trade Centre Residence",
                    "ratings": "5.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.226811,
                            "longitude": 55.289077
                        }
                    },
                    "externalId": "2211788",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/3000000/2220000/2211800/2211788/87b1daf0_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "POOL",
                        "POOL"
                    ],
                    "guestreview": {
                        "cleaniness": "4.7",
                        "staff_service": "4.6",
                        "amenities": "3.7",
                        "conditions_facilities": "4.6"
                    },
                    "rat": "5.0",
                    "urat": "4.5",
                    "expediareview": 62,
                    "pid": "2211788",
                    "line1": "2nd Zabeel Road",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Aparthotel",
                    "nearby": "Dubai World Trade Centre - 0.2 km / 0.1 mi "
                },
                "hdtype": "NOHOTDEAL",
                "usrst": "Excellent",
                "brdbs": [
                    "Breakfast"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 16388.2,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 1256.97,
                "com_disc": 0,
                "rtf": "16388.00",
                "rdwtf": "16388.00"
            },
            {
                "tid": "tbKDiwuk4pqoXei9",
                "pid": "6926162",
                "hs": 0,
                "rmid": "201502914",
                "rtid": "252006877",
                "refundable": false,
                "available_rooms": 14,
                "score": 421,
                "sno": "516",
                "cont": {
                    "_id": "600d415ea1bc371b80fd052c",
                    "name": "DAMAC Maison Mall Street",
                    "ratings": "5.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.194687,
                            "longitude": 55.283396
                        }
                    },
                    "externalId": "6926162",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/7000000/6930000/6926200/6926162/023fe9bc_b.jpg",
                    "ha": [
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.0",
                        "staff_service": "4.0",
                        "amenities": "3.9",
                        "conditions_facilities": "3.8"
                    },
                    "rat": "5.0",
                    "urat": "3.7",
                    "expediareview": 336,
                    "pid": "6926162",
                    "line1": "Mohammed Bin Rashid Boulevard Downtown",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Aparthotel",
                    "nearby": "Dubai Water Canal - 0.8 km / 0.5 mi "
                },
                "hdtype": "NOHOTDEAL",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 16304.46,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 1244.65,
                "com_disc": 0,
                "rtf": "16304.00",
                "rdwtf": "16304.00"
            },
            {
                "tid": "nNkd7EqD3rKlL9KE",
                "pid": "74549512",
                "hs": 0,
                "rmid": "316073878",
                "rtid": "385369508",
                "refundable": true,
                "available_rooms": 3,
                "score": 380,
                "sno": "507",
                "cont": {
                    "_id": "62506c6e2aee3e1bb50b6c27",
                    "ratings": "0.0",
                    "name": "Taj Exotica Resort & Spa, The Palm, Dubai",
                    "location": {
                        "coordinates": {
                            "latitude": 25.124073,
                            "longitude": 55.153991
                        },
                        "obfuscatedCoordinates": {
                            "latitude": 25.125343,
                            "longitude": 55.153401
                        },
                        "obfuscationRequired": false
                    },
                    "externalId": "74549512",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/75000000/74550000/74549600/74549512/2483e8d3_b.jpg",
                    "ha": [
                        "POOL",
                        "WIFI",
                        "GYM"
                    ],
                    "guestreview": {
                        "cleaniness": 0,
                        "staff_service": 0,
                        "amenities": 0,
                        "conditions_facilities": 0
                    },
                    "rat": "0.0",
                    "urat": "",
                    "expediareview": 0,
                    "pid": "74549512",
                    "line1": "East Crescent Road, Palm Jumeirah, P O B",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Property",
                    "nearby": "Aquaventure - 5.2 km / 3.2 mi "
                },
                "hdtype": "NOHOTDEAL",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 15716.61,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 1352.21,
                "com_disc": 0,
                "rtf": "15717.00",
                "rdwtf": "15717.00"
            },
            {
                "tid": "lw0vCXNQrDPAXXNX",
                "pid": "72301419",
                "hs": 0,
                "rmid": "315748459",
                "rtid": "384438177",
                "refundable": false,
                "available_rooms": 1,
                "score": 24,
                "sno": "501",
                "cont": {
                    "_id": "62506ade2aee3e1bb50b6707",
                    "ratings": "0.0",
                    "name": "Luxury at The Address Jumeirah Beach Residence",
                    "location": {
                        "coordinates": {
                            "latitude": 25.072584,
                            "longitude": 55.126567
                        },
                        "obfuscatedCoordinates": {
                            "latitude": 25.071733,
                            "longitude": 55.127402
                        },
                        "obfuscationRequired": false
                    },
                    "externalId": "72301419",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/73000000/72310000/72301500/72301419/0e42514b_b.jpg",
                    "ha": [
                        "POOL"
                    ],
                    "guestreview": {
                        "cleaniness": 0,
                        "staff_service": 0,
                        "amenities": 0,
                        "conditions_facilities": 0
                    },
                    "rat": "0.0",
                    "urat": "",
                    "expediareview": 0,
                    "pid": "72301419",
                    "line1": "Address Beach Resort Tower 1 Jumeirah Be",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Apartment",
                    "nearby": "The Walk - 0.1 km / 0.1 mi "
                },
                "hdtype": "NOHOTDEAL",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 15416.94,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 969.61,
                "com_disc": 0,
                "rtf": "15417.00",
                "rdwtf": "15417.00"
            },
            {
                "tid": "kbuTdNrab7wkn7N5",
                "pid": "74191605",
                "hs": 0,
                "rmid": "315958264",
                "rtid": "385153664",
                "refundable": false,
                "available_rooms": 1,
                "score": 17,
                "sno": "500",
                "cont": {
                    "_id": "62506a1f2aee3e1bb50b6600",
                    "ratings": "0.0",
                    "name": "The address JBR Luxury 1 bedroom",
                    "location": {
                        "coordinates": {
                            "latitude": 25.078176,
                            "longitude": 55.134802
                        },
                        "obfuscatedCoordinates": {
                            "latitude": 25.075699,
                            "longitude": 55.137547
                        },
                        "obfuscationRequired": false
                    },
                    "externalId": "74191605",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/75000000/74200000/74191700/74191605/bc9baa47_b.jpg",
                    "ha": [
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": 0,
                        "staff_service": 0,
                        "amenities": 0,
                        "conditions_facilities": 0
                    },
                    "rat": "0.0",
                    "urat": "",
                    "expediareview": 0,
                    "pid": "74191605",
                    "line1": "1108, Address JBR, JBR, Dubai",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Apartment",
                    "nearby": "The Walk - 0.1 km / 0.1 mi "
                },
                "hdtype": "NOHOTDEAL",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 15187.88,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 844.82,
                "com_disc": 0,
                "rtf": "15188.00",
                "rdwtf": "15188.00"
            },
            {
                "tid": "fBU8AkRG5pIrJSHN",
                "pid": "75626271",
                "hs": 0,
                "rmid": "316162524",
                "rtid": "385536928",
                "refundable": false,
                "available_rooms": 1,
                "score": 23,
                "sno": "499",
                "cont": {
                    "_id": "62506bc52aee3e1bb50b6a1a",
                    "ratings": "0.0",
                    "name": "LUX Opulent Island Suite View 7",
                    "location": {
                        "coordinates": {
                            "latitude": 25.125997,
                            "longitude": 55.122698
                        },
                        "obfuscatedCoordinates": {
                            "latitude": 25.127058,
                            "longitude": 55.124334
                        },
                        "obfuscationRequired": false
                    },
                    "externalId": "75626271",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/76000000/75630000/75626300/75626271/31ffff5e_b.jpg",
                    "ha": [],
                    "guestreview": {
                        "cleaniness": 0,
                        "staff_service": 0,
                        "amenities": 0,
                        "conditions_facilities": 0
                    },
                    "rat": "0.0",
                    "urat": "",
                    "expediareview": 0,
                    "pid": "75626271",
                    "line1": "Palm Jumeirah Rd",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Apartment",
                    "nearby": "The Pointe - 1.1 km / 0.7 mi "
                },
                "hdtype": "NOHOTDEAL",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 15109.89,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 701.14,
                "com_disc": 0,
                "rtf": "15110.00",
                "rdwtf": "15110.00"
            },
            {
                "tid": "RMsw5kUt8QCgrOos",
                "pid": "4623412",
                "hs": 0,
                "rmid": "211812144",
                "rtid": "232255665",
                "refundable": true,
                "available_rooms": 2147483647,
                "score": 402,
                "sno": "489",
                "cont": {
                    "_id": "600d4124a1bc371b80fcc5e9",
                    "name": "Swissotel Living Al Ghurair",
                    "ratings": "5.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.26926,
                            "longitude": 55.31705
                        }
                    },
                    "externalId": "4623412",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/5000000/4630000/4623500/4623412/7c56e390_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.6",
                        "staff_service": "4.4",
                        "amenities": "4.5",
                        "conditions_facilities": "4.5"
                    },
                    "rat": "5.0",
                    "urat": "4.5",
                    "expediareview": 339,
                    "pid": "4623412",
                    "line1": "Umar Bin Al kattab Rd, Deira",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Apartment",
                    "nearby": "Fish Roundabout - 0.2 km / 0.1 mi "
                },
                "hdtype": "NOHOTDEAL",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 14531.07,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 1181.43,
                "com_disc": 0,
                "rtf": "14531.00",
                "rdwtf": "14531.00"
            },
            {
                "tid": "nfuTlner1SobyIOk",
                "pid": "7347819",
                "hs": 0,
                "rmid": "215727366",
                "rtid": "258212652",
                "refundable": false,
                "available_rooms": 4,
                "score": 356,
                "sno": "485",
                "cont": {
                    "_id": "600d4107a1bc371b80fca3d0",
                    "name": "Ajman Saray, a Luxury Collection Resort, Ajman",
                    "ratings": "5.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.420659,
                            "longitude": 55.442022
                        }
                    },
                    "externalId": "7347819",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/8000000/7350000/7347900/7347819/f07b3d49_b.jpg",
                    "ha": [
                        "POOL",
                        "POOL",
                        "WIFI",
                        "GYM"
                    ],
                    "guestreview": {
                        "cleaniness": "4.7",
                        "staff_service": "4.7",
                        "amenities": "4.4",
                        "conditions_facilities": "4.6"
                    },
                    "rat": "5.0",
                    "urat": "4.6",
                    "expediareview": 191,
                    "pid": "7347819",
                    "line1": "Sheikh Humaid Bin Rashid Al Nuaimi St.",
                    "city": "Ajman",
                    "countryCode": "AE",
                    "catnm": "Resort",
                    "nearby": "Ajman Museum - 1.1 km / 0.7 mi "
                },
                "hdtype": "NOHOTDEAL",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 14293.8,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 954.84,
                "com_disc": 0,
                "rtf": "14294.00",
                "rdwtf": "14294.00"
            },
            {
                "tid": "7401d53WynXAuk8J",
                "pid": "39181816",
                "hs": 0,
                "rmid": "230761168",
                "rtid": "278390473",
                "refundable": false,
                "available_rooms": 1,
                "score": 25,
                "sno": "484",
                "cont": {
                    "_id": "600d429fa1bc371b80fed108",
                    "name": "Bnbme - Churchill Towers 1 Bedroom Canal View",
                    "ratings": "0.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.18133,
                            "longitude": 55.26212
                        }
                    },
                    "externalId": "39181816",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/40000000/39190000/39181900/39181816/977d330b_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL"
                    ],
                    "guestreview": {
                        "cleaniness": 0,
                        "staff_service": 0,
                        "amenities": 0,
                        "conditions_facilities": 0
                    },
                    "rat": "0.0",
                    "urat": "",
                    "expediareview": 0,
                    "pid": "39181816",
                    "line1": "Churchill Towers, Downtown",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Apartment",
                    "nearby": "Souk al Bahar - 2.4 km / 1.5 mi "
                },
                "hdtype": "NOHOTDEAL",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 14241.26,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 831.68,
                "com_disc": 0,
                "rtf": "14241.00",
                "rdwtf": "14241.00"
            },
            {
                "tid": "qzJvhRsBW6HsWR2h",
                "pid": "8664126",
                "hs": 0,
                "rmid": "201503098",
                "rtid": "268007373",
                "refundable": false,
                "available_rooms": 8,
                "score": 428,
                "sno": "468",
                "cont": {
                    "_id": "600d3d75a1bc371b80f7a63b",
                    "name": "DAMAC Maison Canal Views",
                    "ratings": "5.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.187583,
                            "longitude": 55.271571
                        }
                    },
                    "externalId": "8664126",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/9000000/8670000/8664200/8664126/80192ea8_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.5",
                        "staff_service": "4.4",
                        "amenities": "3.7",
                        "conditions_facilities": "4.4"
                    },
                    "rat": "5.0",
                    "urat": "4.2",
                    "expediareview": 224,
                    "pid": "8664126",
                    "line1": "Al Abraj Street, Business Bay",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Aparthotel",
                    "nearby": "Souk al Bahar - 0.9 km / 0.6 mi "
                },
                "hdtype": "NOHOTDEAL",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 13042.58,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 995.89,
                "com_disc": 0,
                "rtf": "13043.00",
                "rdwtf": "13043.00"
            },
            {
                "tid": "T4WA6f2w2zRsesfT",
                "pid": "70536433",
                "hs": 0,
                "rmid": "315486188",
                "rtid": "383750216",
                "refundable": false,
                "available_rooms": 1,
                "score": 32,
                "sno": "462",
                "cont": {
                    "_id": "62506aa42aee3e1bb50b66b7",
                    "ratings": "0.0",
                    "name": "LUX - Marina Ocean View Suite",
                    "location": {
                        "coordinates": {
                            "latitude": 25.080542,
                            "longitude": 55.140343
                        },
                        "obfuscationRequired": false
                    },
                    "externalId": "70536433",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/71000000/70540000/70536500/70536433/ca02844c_b.jpg",
                    "ha": [
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": 0,
                        "staff_service": 0,
                        "amenities": 0,
                        "conditions_facilities": 0
                    },
                    "rat": "0.0",
                    "urat": "",
                    "expediareview": 0,
                    "pid": "70536433",
                    "line1": "Ocean Heights - Dubai Marina",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Apartment",
                    "nearby": "The Walk - 0.4 km / 0.2 mi "
                },
                "hdtype": "NOHOTDEAL",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 12806.95,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 255.33,
                "com_disc": 0,
                "rtf": "12807.00",
                "rdwtf": "12807.00"
            },
            {
                "tid": "b1yp0KfqZ8a1STnN",
                "pid": "71606516",
                "hs": 0,
                "rmid": "315634306",
                "rtid": "384184429",
                "refundable": false,
                "available_rooms": 1,
                "score": 17,
                "sno": "461",
                "cont": {
                    "_id": "62506c392aee3e1bb50b6ba3",
                    "ratings": "0.0",
                    "name": "LUX  Marina Promenade Penthouse suite",
                    "location": {
                        "coordinates": {
                            "latitude": 25.073579,
                            "longitude": 55.132849
                        },
                        "obfuscationRequired": false
                    },
                    "externalId": "71606516",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/72000000/71610000/71606600/71606516/a010e7b7_b.jpg",
                    "ha": [],
                    "guestreview": {
                        "cleaniness": 0,
                        "staff_service": 0,
                        "amenities": 0,
                        "conditions_facilities": 0
                    },
                    "rat": "0.0",
                    "urat": "",
                    "expediareview": 0,
                    "pid": "71606516",
                    "line1": "Jumeirah Beach Road",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Apartment",
                    "nearby": "The Walk - 0.6 km / 0.4 mi "
                },
                "hdtype": "NOHOTDEAL",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 12754.41,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 583.74,
                "com_disc": 0,
                "rtf": "12754.00",
                "rdwtf": "12754.00"
            },
            {
                "tid": "pkOmRUNTmlB1MDUG",
                "pid": "74091366",
                "hs": 0,
                "rmid": "315938795",
                "rtid": "385229345",
                "refundable": false,
                "available_rooms": 1,
                "score": 25,
                "sno": "458",
                "cont": {
                    "_id": "62506ae62aee3e1bb50b6713",
                    "ratings": "0.0",
                    "name": "Wonderful Stay in Downtown Views",
                    "location": {
                        "coordinates": {
                            "latitude": 25.200949,
                            "longitude": 55.280844
                        },
                        "obfuscatedCoordinates": {
                            "latitude": 25.200037,
                            "longitude": 55.277925
                        },
                        "obfuscationRequired": false
                    },
                    "externalId": "74091366",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/75000000/74100000/74091400/74091366/0342fd43_b.jpg",
                    "ha": [],
                    "guestreview": {
                        "cleaniness": 0,
                        "staff_service": 0,
                        "amenities": 0,
                        "conditions_facilities": 0
                    },
                    "rat": "0.0",
                    "urat": "",
                    "expediareview": 0,
                    "pid": "74091366",
                    "line1": "Downtown Views 1 Unit 406 Financial Cent",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Apartment",
                    "nearby": "Dubai Mall - 0.9 km / 0.6 mi "
                },
                "hdtype": "NOHOTDEAL",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 12592.67,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 685.54,
                "com_disc": 0,
                "rtf": "12593.00",
                "rdwtf": "12593.00"
            },
            {
                "tid": "SUg9tLmD1tbHX1W0",
                "pid": "74105725",
                "hs": 0,
                "rmid": "315942535",
                "rtid": "385229329",
                "refundable": false,
                "available_rooms": 1,
                "score": 22,
                "sno": "459",
                "cont": {
                    "_id": "62506c332aee3e1bb50b6b97",
                    "ratings": "0.0",
                    "name": "Fabulous Stay in Downtown Views",
                    "location": {
                        "coordinates": {
                            "latitude": 25.200949,
                            "longitude": 55.280847
                        },
                        "obfuscatedCoordinates": {
                            "latitude": 25.201681,
                            "longitude": 55.279378
                        },
                        "obfuscationRequired": false
                    },
                    "externalId": "74105725",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/75000000/74110000/74105800/74105725/6246ba46_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL"
                    ],
                    "guestreview": {
                        "cleaniness": 0,
                        "staff_service": 0,
                        "amenities": 0,
                        "conditions_facilities": 0
                    },
                    "rat": "0.0",
                    "urat": "",
                    "expediareview": 0,
                    "pid": "74105725",
                    "line1": "Downtown Views 1 Unit 602 Financial Cent",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Apartment",
                    "nearby": "Dubai Mall - 0.9 km / 0.6 mi "
                },
                "hdtype": "NOHOTDEAL",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 12592.67,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 685.54,
                "com_disc": 0,
                "rtf": "12593.00",
                "rdwtf": "12593.00"
            },
            {
                "tid": "TBGwRzvqAKp4aJ0o",
                "pid": "18647545",
                "hs": 0,
                "rmid": "202155628",
                "rtid": "282021406",
                "refundable": false,
                "available_rooms": 2,
                "score": 435,
                "sno": "455",
                "cont": {
                    "_id": "600d3ec9a1bc371b80f99046",
                    "name": "DAMAC Maison Distinction",
                    "ratings": "5.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.187672,
                            "longitude": 55.279423
                        }
                    },
                    "externalId": "18647545",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/19000000/18650000/18647600/18647545/ea24e6cf_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.4",
                        "staff_service": "4.4",
                        "amenities": "4.2",
                        "conditions_facilities": "4.4"
                    },
                    "rat": "5.0",
                    "urat": "4.4",
                    "expediareview": 74,
                    "pid": "18647545",
                    "line1": "School Street Burj Area",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Aparthotel",
                    "nearby": "Souk al Bahar - 1 km / 0.6 mi "
                },
                "hdtype": "NOHOTDEAL",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 12317.63,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 940.06,
                "com_disc": 0,
                "rtf": "12318.00",
                "rdwtf": "12318.00"
            },
            {
                "tid": "lNr0Fp76oywFPmi7",
                "pid": "65705944",
                "hs": 0,
                "rmid": "231981916",
                "rtid": "286429152",
                "refundable": false,
                "available_rooms": 2147483647,
                "score": 230,
                "sno": "451",
                "cont": {
                    "_id": "62506be42aee3e1bb50b6a8a",
                    "ratings": "0.0",
                    "name": "Adagio Premium The Palm",
                    "location": {
                        "coordinates": {
                            "latitude": 25.108655,
                            "longitude": 55.14311
                        },
                        "obfuscatedCoordinates": {
                            "latitude": 25.104599,
                            "longitude": 55.135795
                        },
                        "obfuscationRequired": false
                    },
                    "externalId": "65705944",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/66000000/65710000/65706000/65705944/bd624b1e_b.jpg",
                    "ha": [
                        "GYM",
                        "WIFI",
                        "GYM"
                    ],
                    "guestreview": {
                        "cleaniness": "4.4",
                        "staff_service": 0,
                        "amenities": "4.2",
                        "conditions_facilities": "4.4"
                    },
                    "rat": "0.0",
                    "urat": "4.3",
                    "expediareview": 17,
                    "pid": "65705944",
                    "line1": "Palm Jumeirah West Beach",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Aparthotel",
                    "nearby": "Nakheel Mall - 0.8 km / 0.5 mi "
                },
                "hdtype": "NOHOTDEAL",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 12049.16,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 919.53,
                "com_disc": 0,
                "rtf": "12049.00",
                "rdwtf": "12049.00"
            },
            {
                "tid": "OVOpO9VL7Z73MAps",
                "pid": "16263390",
                "hs": 0,
                "rmid": "201718597",
                "rtid": "388480988",
                "refundable": false,
                "available_rooms": 5,
                "score": 627,
                "sno": "449",
                "cont": {
                    "_id": "600d43bea1bc371b80003984",
                    "name": "Lapita, Dubai Parks and Resorts, Autograph Collection",
                    "ratings": "4.0",
                    "location": {
                        "coordinates": {
                            "latitude": 24.916165,
                            "longitude": 55.003542
                        }
                    },
                    "externalId": "16263390",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/17000000/16270000/16263400/16263390/479dd808_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.5",
                        "staff_service": "4.3",
                        "amenities": "4.5",
                        "conditions_facilities": "4.5"
                    },
                    "rat": "4.0",
                    "urat": "4.4",
                    "expediareview": 112,
                    "pid": "16263390",
                    "line1": "Sheikh Zayed Road",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Resort",
                    "nearby": "Legoland® Dubai - 1.1 km / 0.7 mi "
                },
                "hdtype": "NOHOTDEAL",
                "usrst": "Excellent",
                "brdbs": [
                    "Breakfast"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 11799.57,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 889.15,
                "com_disc": 0,
                "rtf": "11800.00",
                "rdwtf": "11800.00"
            },
            {
                "tid": "4MtgOXh6BirwCynS",
                "pid": "869326",
                "hs": 0,
                "rmid": "185426",
                "rtid": "493204",
                "refundable": true,
                "available_rooms": 5,
                "score": 389,
                "sno": "436",
                "cont": {
                    "_id": "600d4071a1bc371b80fbc718",
                    "name": "Marriott Executive Apartments Dubai Creek",
                    "ratings": "4.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.257997,
                            "longitude": 55.323623
                        }
                    },
                    "externalId": "869326",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/1000000/870000/869400/869326/8b60c745_b.jpg",
                    "ha": [
                        "POOL",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.3",
                        "staff_service": "4.4",
                        "amenities": "4.0",
                        "conditions_facilities": "4.3"
                    },
                    "rat": "4.0",
                    "urat": "4.3",
                    "expediareview": 153,
                    "pid": "869326",
                    "line1": "Riggat Albuteen Street",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Aparthotel",
                    "nearby": "Al Ghurair Centre - 1.8 km / 1.1 mi "
                },
                "hdtype": "NOHOTDEAL",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 10769.2,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 849.75,
                "com_disc": 0,
                "rtf": "10769.00",
                "rdwtf": "10769.00"
            },
            {
                "tid": "n99bN1k9LhLd7hPB",
                "pid": "72122482",
                "hs": 0,
                "rmid": "315736301",
                "rtid": "384418224",
                "refundable": false,
                "available_rooms": 1,
                "score": 22,
                "sno": "432",
                "cont": {
                    "_id": "62506a952aee3e1bb50b66a3",
                    "ratings": "0.0",
                    "name": "LUX The Cayan Superior Suite 2",
                    "location": {
                        "coordinates": {
                            "latitude": 25.100359,
                            "longitude": 55.157174
                        },
                        "obfuscationRequired": false
                    },
                    "externalId": "72122482",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/73000000/72130000/72122500/72122482/ffc34614_b.jpg",
                    "ha": [],
                    "guestreview": {
                        "cleaniness": 0,
                        "staff_service": 0,
                        "amenities": 0,
                        "conditions_facilities": 0
                    },
                    "rat": "0.0",
                    "urat": "",
                    "expediareview": 0,
                    "pid": "72122482",
                    "line1": "King Salman Bin Abdulaziz Al Saud St",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Apartment",
                    "nearby": "Sheikh Zayed Road - 2 km / 1.2 mi "
                },
                "hdtype": "NOHOTDEAL",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 10511.4,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 366.99,
                "com_disc": 0,
                "rtf": "10511.00",
                "rdwtf": "10511.00"
            },
            {
                "tid": "rowSqRnXvZNKdsC9",
                "pid": "71064278",
                "hs": 0,
                "rmid": "315588851",
                "rtid": "383983029",
                "refundable": false,
                "available_rooms": 1,
                "score": 18,
                "sno": "423",
                "cont": {
                    "_id": "62506a542aee3e1bb50b6647",
                    "ratings": "0.0",
                    "name": "Luxury Stay at Park View Dubai",
                    "location": {
                        "coordinates": {
                            "latitude": 25.054156,
                            "longitude": 55.202929
                        },
                        "obfuscatedCoordinates": {
                            "latitude": 25.053942,
                            "longitude": 55.207131
                        },
                        "obfuscationRequired": false
                    },
                    "externalId": "71064278",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/72000000/71070000/71064300/71064278/34f9df83_b.jpg",
                    "ha": [],
                    "guestreview": {
                        "cleaniness": 0,
                        "staff_service": 0,
                        "amenities": 0,
                        "conditions_facilities": 0
                    },
                    "rat": "0.0",
                    "urat": "",
                    "expediareview": 0,
                    "pid": "71064278",
                    "line1": "Park View Tower Jumeirah Village Circle",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Apartment",
                    "nearby": "Al Barsha Pond Park - 7.4 km / 4.6 mi "
                },
                "hdtype": "NOHOTDEAL",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 9994.17,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 376.84,
                "com_disc": 0,
                "rtf": "9994.00",
                "rdwtf": "9994.00"
            },
            {
                "tid": "JvTbvTepPu3bHzSO",
                "pid": "38261023",
                "hs": 0,
                "rmid": "229527768",
                "rtid": "381713483",
                "refundable": true,
                "available_rooms": 2,
                "score": 130,
                "sno": "418",
                "cont": {
                    "_id": "600d405da1bc371b80fbab74",
                    "name": "Marbella Holiday Homes",
                    "ratings": "0.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.216757,
                            "longitude": 55.33094
                        }
                    },
                    "externalId": "38261023",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/39000000/38270000/38261100/38261023/cfab3943_b.jpg",
                    "ha": [
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "2.5",
                        "staff_service": "1.5",
                        "amenities": 0,
                        "conditions_facilities": "2.0"
                    },
                    "rat": "0.0",
                    "urat": "1.5",
                    "expediareview": 2,
                    "pid": "38261023",
                    "line1": "Al Jaddaf",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Aparthotel",
                    "nearby": "Dubai Festival City Mall - 4.2 km / 2.6 mi "
                },
                "hdtype": "NOHOTDEAL",
                "usrst": "Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 9637.03,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 734.8,
                "com_disc": 0,
                "rtf": "9637.00",
                "rdwtf": "9637.00"
            },
            {
                "tid": "BoCeDMMA1mCtzKRZ",
                "pid": "2193284",
                "hs": 0,
                "rmid": "201889310",
                "rtid": "209555145",
                "refundable": false,
                "available_rooms": 18,
                "score": 398,
                "sno": "415",
                "cont": {
                    "_id": "600d3f40a1bc371b80fa3710",
                    "name": "Oaks Liwa Heights Suites",
                    "ratings": "3.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.070245,
                            "longitude": 55.140839
                        }
                    },
                    "externalId": "2193284",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/3000000/2200000/2193300/2193284/b911f64c_b.jpg",
                    "ha": [
                        "BAR",
                        "POOL",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.2",
                        "staff_service": "4.1",
                        "amenities": "4.6",
                        "conditions_facilities": "4.1"
                    },
                    "rat": "3.0",
                    "urat": "4.1",
                    "expediareview": 181,
                    "pid": "2193284",
                    "line1": "Jumeirah Lake Towers",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Aparthotel",
                    "nearby": "Dubai Marina Mall - 1.3 km / 0.8 mi "
                },
                "hdtype": "NOHOTDEAL",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 9583.66,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 734.8,
                "com_disc": 0,
                "rtf": "9584.00",
                "rdwtf": "9584.00"
            },
            {
                "tid": "0crsE6fU4pHX3yV7",
                "pid": "31815811",
                "hs": 0,
                "rmid": "218732219",
                "rtid": "278125822",
                "refundable": false,
                "available_rooms": 1,
                "score": 38,
                "sno": "413",
                "cont": {
                    "_id": "600d3d9ea1bc371b80f7eaac",
                    "name": "Teecom 1 Bedroom Apartment",
                    "ratings": "0.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.099941,
                            "longitude": 55.175114
                        }
                    },
                    "externalId": "31815811",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/32000000/31820000/31815900/31815811/252f3930_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL"
                    ],
                    "guestreview": {
                        "cleaniness": 0,
                        "staff_service": 0,
                        "amenities": 0,
                        "conditions_facilities": 0
                    },
                    "rat": "0.0",
                    "urat": "",
                    "expediareview": 0,
                    "pid": "31815811",
                    "line1": "Madison Residency, Teecom",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Apartment",
                    "nearby": "Jebel Ali Race Course - 1.4 km / 0.9 mi "
                },
                "hdtype": "NOHOTDEAL",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 9504.84,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 856.31,
                "com_disc": 0,
                "rtf": "9505.00",
                "rdwtf": "9505.00"
            },
            {
                "tid": "wrBfkOuHJQHJ2T0z",
                "pid": "71064272",
                "hs": 0,
                "rmid": "315588856",
                "rtid": "383983035",
                "refundable": false,
                "available_rooms": 1,
                "score": 21,
                "sno": "402",
                "cont": {
                    "_id": "62506a522aee3e1bb50b6645",
                    "ratings": "0.0",
                    "name": "Park View Spacious Studio With Balcony",
                    "location": {
                        "coordinates": {
                            "latitude": 25.054156,
                            "longitude": 55.202929
                        },
                        "obfuscatedCoordinates": {
                            "latitude": 25.054774,
                            "longitude": 55.206693
                        },
                        "obfuscationRequired": false
                    },
                    "externalId": "71064272",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/72000000/71070000/71064300/71064272/e5eb1258_b.jpg",
                    "ha": [],
                    "guestreview": {
                        "cleaniness": 0,
                        "staff_service": 0,
                        "amenities": 0,
                        "conditions_facilities": 0
                    },
                    "rat": "0.0",
                    "urat": "",
                    "expediareview": 0,
                    "pid": "71064272",
                    "line1": "Park View Tower Jumeirah Village Circle",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Apartment",
                    "nearby": "Al Barsha Pond Park - 7.4 km / 4.6 mi "
                },
                "hdtype": "NOHOTDEAL",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 8926.03,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 319.37,
                "com_disc": 0,
                "rtf": "8926.00",
                "rdwtf": "8926.00"
            },
            {
                "tid": "tEDd6gTU7PQat6Bh",
                "pid": "5210010",
                "hs": 0,
                "rmid": "200184607",
                "rtid": "201057059",
                "refundable": false,
                "available_rooms": 17,
                "score": 426,
                "sno": "396",
                "cont": {
                    "_id": "600d435da1bc371b80ffbc61",
                    "name": "Nassima Tower Hotel Apartments",
                    "ratings": "0.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.22311,
                            "longitude": 55.283155
                        }
                    },
                    "externalId": "5210010",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/6000000/5220000/5210100/5210010/08027829_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "POOL",
                        "BAR",
                        "POOL"
                    ],
                    "guestreview": {
                        "cleaniness": "4.3",
                        "staff_service": "4.3",
                        "amenities": "4.5",
                        "conditions_facilities": "4.2"
                    },
                    "rat": "0.0",
                    "urat": "4.3",
                    "expediareview": 229,
                    "pid": "5210010",
                    "line1": "P O Box 49260 Sheikh Zayed Road",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Aparthotel",
                    "nearby": "Dubai International Financial Centre - 1.2 km / 0.7 mi "
                },
                "hdtype": "NOHOTDEAL",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 8742.13,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 661.73,
                "com_disc": 0,
                "rtf": "8742.00",
                "rdwtf": "8742.00"
            },
            {
                "tid": "NfRRpS5nFfpmPTsd",
                "pid": "71082514",
                "hs": 0,
                "rmid": "315735257",
                "rtid": "384653799",
                "refundable": true,
                "available_rooms": 1,
                "score": 142,
                "sno": "391",
                "cont": {
                    "_id": "62506a832aee3e1bb50b6689",
                    "ratings": "0.0",
                    "name": "SITARA HOTEL APARTMENT",
                    "location": {
                        "coordinates": {
                            "latitude": 25.235208,
                            "longitude": 55.307934
                        },
                        "obfuscationRequired": false
                    },
                    "externalId": "71082514",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/72000000/71090000/71082600/71082514/5ede7070_b.jpg",
                    "ha": [
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": 0,
                        "staff_service": 0,
                        "amenities": 0,
                        "conditions_facilities": 0
                    },
                    "rat": "0.0",
                    "urat": "",
                    "expediareview": 0,
                    "pid": "71082514",
                    "line1": "17 6B ,OUD METHA, OPP.LAMCY PLAZA MALL",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Aparthotel",
                    "nearby": "Lamcy Plaza - 0.5 km / 0.3 mi "
                },
                "hdtype": "NOHOTDEAL",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 8601.73,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 737.27,
                "com_disc": 0,
                "rtf": "8602.00",
                "rdwtf": "8602.00"
            },
            {
                "tid": "TtzN7XHWbZLwmUGt",
                "pid": "6975242",
                "hs": 0,
                "rmid": "200553936",
                "rtid": "206873178",
                "refundable": false,
                "available_rooms": 5,
                "score": 356,
                "sno": "373",
                "cont": {
                    "_id": "600d3f8aa1bc371b80fa9b27",
                    "name": "Marriott Executive Apartments Dubai, Al Jaddaf",
                    "ratings": "0.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.211998,
                            "longitude": 55.315604
                        }
                    },
                    "externalId": "6975242",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/7000000/6980000/6975300/6975242/96175b2a_b.jpg",
                    "ha": [
                        "BAR",
                        "GYM",
                        "POOL",
                        "POOL",
                        "BAR"
                    ],
                    "guestreview": {
                        "cleaniness": "4.7",
                        "staff_service": "4.6",
                        "amenities": "4.3",
                        "conditions_facilities": "4.7"
                    },
                    "rat": "0.0",
                    "urat": "4.6",
                    "expediareview": 121,
                    "pid": "6975242",
                    "line1": "Oud Metha Road, Al Jaddaf Area",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Aparthotel",
                    "nearby": "American Hospital Dubai - 3 km / 1.8 mi "
                },
                "hdtype": "NOHOTDEAL",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 8107.48,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 685.54,
                "com_disc": 0,
                "rtf": "8107.00",
                "rdwtf": "8107.00"
            },
            {
                "tid": "HMAKHO7fvVQhmvkb",
                "pid": "22561809",
                "hs": 0,
                "rmid": "315790424",
                "rtid": "384685140",
                "refundable": true,
                "available_rooms": 1,
                "score": 448,
                "sno": "369",
                "cont": {
                    "_id": "600d3dbba1bc371b80f81b02",
                    "name": "Barcelo Residences Dubai Marina",
                    "ratings": "0.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.083576,
                            "longitude": 55.142494
                        }
                    },
                    "externalId": "22561809",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/23000000/22570000/22561900/22561809/8947c788_b.jpg",
                    "ha": [
                        "BAR",
                        "GYM",
                        "POOL",
                        "POOL"
                    ],
                    "guestreview": {
                        "cleaniness": "3.9",
                        "staff_service": "4.2",
                        "amenities": "4.0",
                        "conditions_facilities": "4.2"
                    },
                    "rat": "0.0",
                    "urat": "4.2",
                    "expediareview": 107,
                    "pid": "22561809",
                    "line1": "King Salman Bin Abdulaziz Road",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Aparthotel",
                    "nearby": "The Walk - 0.4 km / 0.2 mi "
                },
                "hdtype": "NOHOTDEAL",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 8002.39,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 614.12,
                "com_disc": 0,
                "rtf": "8002.00",
                "rdwtf": "8002.00"
            },
            {
                "tid": "KzP83VSXSGOTHOWM",
                "pid": "64989627",
                "hs": 0,
                "rmid": "226830323",
                "rtid": "385352858",
                "refundable": false,
                "available_rooms": 15,
                "score": 82,
                "sno": "356",
                "cont": {
                    "_id": "62506ac62aee3e1bb50b66e7",
                    "ratings": "0.0",
                    "name": "Abar Hotel Apartments",
                    "location": {
                        "coordinates": {
                            "latitude": 25.004179,
                            "longitude": 55.169813
                        },
                        "obfuscationRequired": false
                    },
                    "externalId": "64989627",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/65000000/64990000/64989700/64989627/e444a2fd_b.jpg",
                    "ha": [
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "5.0",
                        "staff_service": 0,
                        "amenities": "5.0",
                        "conditions_facilities": "5.0"
                    },
                    "rat": "0.0",
                    "urat": "5.0",
                    "expediareview": 4,
                    "pid": "64989627",
                    "line1": "Dubai Investment Park - 1",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Aparthotel",
                    "nearby": "Jumeirah Golf Estates - 3.6 km / 2.2 mi "
                },
                "hdtype": "NOHOTDEAL",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 7740.49,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 593.59,
                "com_disc": 0,
                "rtf": "7740.00",
                "rdwtf": "7740.00"
            },
            {
                "tid": "IQAlL3aSUzJVTLSc",
                "pid": "23368348",
                "hs": 0,
                "rmid": "314089183",
                "rtid": "382367563",
                "refundable": false,
                "available_rooms": 9,
                "score": 311,
                "sno": "354",
                "cont": {
                    "_id": "600d424da1bc371b80fe6204",
                    "name": "AlSalam Grand Hotel & Hotel Apartments",
                    "ratings": "0.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.102156,
                            "longitude": 55.170765
                        }
                    },
                    "externalId": "23368348",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/24000000/23370000/23368400/23368348/1525120b_b.jpg",
                    "ha": [
                        "POOL",
                        "POOL",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.4",
                        "staff_service": "4.3",
                        "amenities": "4.4",
                        "conditions_facilities": "4.2"
                    },
                    "rat": "0.0",
                    "urat": "4.3",
                    "expediareview": 53,
                    "pid": "23368348",
                    "line1": "AlSalam Tecom Tower, Al Sufouh Second",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Aparthotel",
                    "nearby": "Dubai College - 1.3 km / 0.8 mi "
                },
                "hdtype": "NOHOTDEAL",
                "usrst": "Excellent",
                "brdbs": [
                    "Breakfast"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 7642.79,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 575.53,
                "com_disc": 0,
                "rtf": "7643.00",
                "rdwtf": "7643.00"
            },
            {
                "tid": "WUt741SE1ABmpZOT",
                "pid": "2270780",
                "hs": 0,
                "rmid": "200470031",
                "rtid": "383427700",
                "refundable": true,
                "available_rooms": 5,
                "score": 138,
                "sno": "345",
                "cont": {
                    "_id": "600d42fca1bc371b80ff534f",
                    "name": "Spark Residence Deluxe Hotel Apartments",
                    "ratings": "3.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.343187,
                            "longitude": 55.391835
                        }
                    },
                    "externalId": "2270780",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/3000000/2280000/2270800/2270780/23765787_b.jpg",
                    "ha": [
                        "GYM",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "3.9",
                        "staff_service": "4.1",
                        "amenities": 0,
                        "conditions_facilities": "3.5"
                    },
                    "rat": "3.0",
                    "urat": "3.9",
                    "expediareview": 10,
                    "pid": "2270780",
                    "line1": "King Faisal Road",
                    "city": "Sharjah",
                    "countryCode": "AE",
                    "catnm": "Aparthotel",
                    "nearby": "King Faisal Mosque - 1 km / 0.6 mi "
                },
                "hdtype": "NOHOTDEAL",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 7427.69,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 552.54,
                "com_disc": 0,
                "rtf": "7428.00",
                "rdwtf": "7428.00"
            },
            {
                "tid": "xuTG5MrSyVHqh5S6",
                "pid": "4321780",
                "hs": 0,
                "rmid": "201918397",
                "rtid": "210178493",
                "refundable": false,
                "available_rooms": 9,
                "score": 278,
                "sno": "326",
                "cont": {
                    "_id": "600d4356a1bc371b80ffb50d",
                    "name": "Dunes Hotel Apartments Oud Metha",
                    "ratings": "0.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.234585,
                            "longitude": 55.314638
                        }
                    },
                    "externalId": "4321780",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/5000000/4330000/4321800/4321780/82b2fcd5_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.2",
                        "staff_service": "4.3",
                        "amenities": "3.1",
                        "conditions_facilities": "4.2"
                    },
                    "rat": "0.0",
                    "urat": "4.2",
                    "expediareview": 67,
                    "pid": "4321780",
                    "line1": "Oud Metha St, Near to American Hospital",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Aparthotel",
                    "nearby": "American Hospital Dubai - 0.2 km / 0.1 mi "
                },
                "hdtype": "NOHOTDEAL",
                "usrst": "Excellent",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 6883.36,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 525.45,
                "com_disc": 0,
                "rtf": "6883.00",
                "rdwtf": "6883.00"
            },
            {
                "tid": "6G7pvgOIws62ggD3",
                "pid": "5468555",
                "hs": 0,
                "rmid": "200221790",
                "rtid": "232006704",
                "refundable": false,
                "available_rooms": 5,
                "score": 255,
                "sno": "319",
                "cont": {
                    "_id": "600d4025a1bc371b80fb5e72",
                    "name": "Abidos Hotel Apartment, Dubailand",
                    "ratings": "0.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.08916,
                            "longitude": 55.379508
                        }
                    },
                    "externalId": "5468555",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/6000000/5470000/5468600/5468555/b5dce876_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "POOL",
                        "BAR",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.2",
                        "staff_service": "4.1",
                        "amenities": "4.0",
                        "conditions_facilities": "4.1"
                    },
                    "rat": "0.0",
                    "urat": "4.0",
                    "expediareview": 250,
                    "pid": "5468555",
                    "line1": "Dubailand Residence Area",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Aparthotel",
                    "nearby": "Dubai Outlet Mall - 4.9 km / 3 mi "
                },
                "hdtype": "NOHOTDEAL",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 6739.68,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 514.77,
                "com_disc": 0,
                "rtf": "6740.00",
                "rdwtf": "6740.00"
            },
            {
                "tid": "6wIDu4qp1pNhoRdN",
                "pid": "59446802",
                "hs": 0,
                "rmid": "321482299",
                "rtid": "388928322",
                "refundable": true,
                "available_rooms": 2147483647,
                "score": 150,
                "sno": "309",
                "cont": {
                    "_id": "600d3da9a1bc371b80f7fec1",
                    "name": "Aparthotel Adagio Dubai Deira",
                    "ratings": "0.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.314175,
                            "longitude": 55.342257
                        }
                    },
                    "externalId": "59446802",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/60000000/59450000/59446900/59446802/8d21da4c_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": 0,
                        "staff_service": 0,
                        "amenities": 0,
                        "conditions_facilities": 0
                    },
                    "rat": "0.0",
                    "urat": "",
                    "expediareview": 0,
                    "pid": "59446802",
                    "line1": "Afra Plaza",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Aparthotel",
                    "nearby": "Al Mamzar Beach Park - 0.5 km / 0.3 mi "
                },
                "hdtype": "NOHOTDEAL",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 6605.85,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 495.07,
                "com_disc": 0,
                "rtf": "6606.00",
                "rdwtf": "6606.00"
            },
            {
                "tid": "4TV8QIkCC0O7F3eV",
                "pid": "68600200",
                "hs": 0,
                "rmid": "314917598",
                "rtid": "383554466",
                "refundable": false,
                "available_rooms": 10,
                "score": 145,
                "sno": "305",
                "cont": {
                    "_id": "62506a1d2aee3e1bb50b65fd",
                    "ratings": "0.0",
                    "name": "HE Hotel Apartments",
                    "location": {
                        "coordinates": {
                            "latitude": 25.05995,
                            "longitude": 55.216416
                        },
                        "obfuscationRequired": false
                    },
                    "externalId": "68600200",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/69000000/68610000/68600200/68600200/deaf634b_b.jpg",
                    "ha": [
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "2.0",
                        "staff_service": 0,
                        "amenities": 0,
                        "conditions_facilities": 0
                    },
                    "rat": "0.0",
                    "urat": "2.0",
                    "expediareview": 1,
                    "pid": "68600200",
                    "line1": "Assel Blvd. C30, District 17, JVC",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Aparthotel",
                    "nearby": "Dubai International Stadium - 6.4 km / 4 mi "
                },
                "hdtype": "NOHOTDEAL",
                "usrst": "Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 6479.42,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 495.89,
                "com_disc": 0,
                "rtf": "6479.00",
                "rdwtf": "6479.00"
            },
            {
                "tid": "8wqiPkvMMX5dsCse",
                "pid": "68079765",
                "hs": 0,
                "rmid": "317962452",
                "rtid": "386769678",
                "refundable": false,
                "available_rooms": 5,
                "score": 46,
                "sno": "303",
                "cont": {
                    "_id": "62506af52aee3e1bb50b673b",
                    "ratings": "0.0",
                    "name": "City Stay Residences",
                    "location": {
                        "coordinates": {
                            "latitude": 25.109313,
                            "longitude": 55.202998
                        },
                        "obfuscationRequired": false
                    },
                    "externalId": "68079765",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/69000000/68080000/68079800/68079765/8fa8e682_b.jpg",
                    "ha": [
                        "GYM",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": 0,
                        "staff_service": 0,
                        "amenities": 0,
                        "conditions_facilities": 0
                    },
                    "rat": "0.0",
                    "urat": "",
                    "expediareview": 0,
                    "pid": "68079765",
                    "line1": "23rd Street, Al Barsha 1",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Aparthotel",
                    "nearby": "Sheikh Zayed Road - 1.3 km / 0.8 mi "
                },
                "hdtype": "NOHOTDEAL",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 6425.23,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 471.26,
                "com_disc": 0,
                "rtf": "6425.00",
                "rdwtf": "6425.00"
            },
            {
                "tid": "0IOP7RgC2lwGUGRa",
                "pid": "1855531",
                "hs": 0,
                "rmid": "228315899",
                "rtid": "381652220",
                "refundable": false,
                "available_rooms": 1,
                "score": 250,
                "sno": "246",
                "cont": {
                    "_id": "62506c2f2aee3e1bb50b6b8f",
                    "ratings": "4.0",
                    "name": "Emirates Stars Hotel Apartments Dubai",
                    "location": {
                        "coordinates": {
                            "latitude": 25.280067,
                            "longitude": 55.357395
                        },
                        "obfuscationRequired": false
                    },
                    "externalId": "1855531",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/2000000/1860000/1855600/1855531/3cfe66f9_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "3.7",
                        "staff_service": "3.7",
                        "amenities": "3.0",
                        "conditions_facilities": "3.7"
                    },
                    "rat": "4.0",
                    "urat": "3.7",
                    "expediareview": 63,
                    "pid": "1855531",
                    "line1": "Al Nahda2, Al Qusais near NMC Hospital",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Aparthotel",
                    "nearby": "Al Mamzar Beach - 2.9 km / 1.8 mi "
                },
                "hdtype": "NOHOTDEAL",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 5545.11,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 425.28,
                "com_disc": 0,
                "rtf": "5545.00",
                "rdwtf": "5545.00"
            },
            {
                "tid": "bty33BkKXIvTSqd9",
                "pid": "10017195",
                "hs": 0,
                "rmid": "200907309",
                "rtid": "211922216",
                "refundable": false,
                "available_rooms": 5,
                "score": 231,
                "sno": "242",
                "cont": {
                    "_id": "600d4088a1bc371b80fbe538",
                    "name": "Tulip Creek Hotel Apartments",
                    "ratings": "4.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.254175,
                            "longitude": 55.330313
                        }
                    },
                    "externalId": "10017195",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/11000000/10020000/10017200/10017195/67a3706f_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "POOL",
                        "POOL"
                    ],
                    "guestreview": {
                        "cleaniness": "3.1",
                        "staff_service": "3.3",
                        "amenities": "3.2",
                        "conditions_facilities": "3.1"
                    },
                    "rat": "4.0",
                    "urat": "3.2",
                    "expediareview": 117,
                    "pid": "10017195",
                    "line1": "Behind clock tower, Port Saeed Area",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Aparthotel",
                    "nearby": "City Centre Deira - 0.7 km / 0.4 mi "
                },
                "hdtype": "NOHOTDEAL",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 5463.01,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 418.72,
                "com_disc": 0,
                "rtf": "5463.00",
                "rdwtf": "5463.00"
            },
            {
                "tid": "fRG8rpowoVMH3Ggz",
                "pid": "4636372",
                "hs": 0,
                "rmid": "202094017",
                "rtid": "210759626",
                "refundable": false,
                "available_rooms": 3,
                "score": 208,
                "sno": "220",
                "cont": {
                    "_id": "600d3f6ea1bc371b80fa7ab7",
                    "name": "Xclusive Maples Hotel Apartment",
                    "ratings": "0.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.252141,
                            "longitude": 55.29627
                        }
                    },
                    "externalId": "4636372",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/5000000/4640000/4636400/4636372/d73d0880_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "3.9",
                        "staff_service": "3.6",
                        "amenities": "3.7",
                        "conditions_facilities": "3.8"
                    },
                    "rat": "0.0",
                    "urat": "3.7",
                    "expediareview": 183,
                    "pid": "4636372",
                    "line1": "Building # 20, Street # 12",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Aparthotel",
                    "nearby": "BurJuman Mall - 1 km / 0.6 mi "
                },
                "hdtype": "NOHOTDEAL",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 5151.84,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 389.98,
                "com_disc": 0,
                "rtf": "5152.00",
                "rdwtf": "5152.00"
            },
            {
                "tid": "nFcmwDzTZsIfe96y",
                "pid": "2103299",
                "hs": 0,
                "rmid": "271339",
                "rtid": "201588728",
                "refundable": false,
                "available_rooms": 3,
                "score": 210,
                "sno": "221",
                "cont": {
                    "_id": "600d40cfa1bc371b80fc52bd",
                    "name": "XCLUSIVE Hotel Apartments",
                    "ratings": "0.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.250674,
                            "longitude": 55.296539
                        }
                    },
                    "externalId": "2103299",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/3000000/2110000/2103300/2103299/c50a0bd7_b.jpg",
                    "ha": [
                        "GYM",
                        "POOL",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "4.1",
                        "staff_service": "4.1",
                        "amenities": "3.0",
                        "conditions_facilities": "3.9"
                    },
                    "rat": "0.0",
                    "urat": "4.0",
                    "expediareview": 413,
                    "pid": "2103299",
                    "line1": "Street 17B",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Aparthotel",
                    "nearby": "BurJuman Mall - 1.1 km / 0.7 mi "
                },
                "hdtype": "NOHOTDEAL",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 5151.84,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 389.98,
                "com_disc": 0,
                "rtf": "5152.00",
                "rdwtf": "5152.00"
            },
            {
                "tid": "zO0DyI9iFJh83EOQ",
                "pid": "45601126",
                "hs": 0,
                "rmid": "217641527",
                "rtid": "381152392",
                "refundable": false,
                "available_rooms": 4,
                "score": 214,
                "sno": "205",
                "cont": {
                    "_id": "600d4326a1bc371b80ff8390",
                    "name": "Staybridge Suites Dubai Al-Maktoum Airport",
                    "ratings": "0.0",
                    "location": {
                        "coordinates": {
                            "latitude": 24.867784,
                            "longitude": 55.146224
                        }
                    },
                    "externalId": "45601126",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/46000000/45610000/45601200/45601126/ab38ea1d_b.jpg",
                    "ha": [
                        "POOL",
                        "WIFI",
                        "GYM"
                    ],
                    "guestreview": {
                        "cleaniness": 0,
                        "staff_service": 0,
                        "amenities": 0,
                        "conditions_facilities": 0
                    },
                    "rat": "0.0",
                    "urat": "",
                    "expediareview": 0,
                    "pid": "45601126",
                    "line1": "Aviation City A",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Aparthotel",
                    "nearby": "Legoland® Dubai - 17.6 km / 11 mi "
                },
                "hdtype": "NOHOTDEAL",
                "brdbs": [
                    "Breakfast"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 4938.38,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 355.5,
                "com_disc": 0,
                "rtf": "4938.00",
                "rdwtf": "4938.00"
            },
            {
                "tid": "UrggRDzopCxNZFe1",
                "pid": "2818894",
                "hs": 0,
                "rmid": "201329288",
                "rtid": "209216115",
                "refundable": false,
                "available_rooms": 5,
                "score": 245,
                "sno": "196",
                "cont": {
                    "_id": "600d42c4a1bc371b80ff0638",
                    "name": "ZiQoo Hotel Apartments",
                    "ratings": "3.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.049051,
                            "longitude": 55.130253
                        }
                    },
                    "externalId": "2818894",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/3000000/2820000/2818900/2818894/c4d14c7a_b.jpg",
                    "ha": [
                        "GYM",
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "3.9",
                        "staff_service": "3.9",
                        "amenities": "3.2",
                        "conditions_facilities": "3.8"
                    },
                    "rat": "3.0",
                    "urat": "3.8",
                    "expediareview": 41,
                    "pid": "2818894",
                    "line1": "Discovery Gardens",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Aparthotel",
                    "nearby": "Ibn Battuta Mall - 1.3 km / 0.8 mi "
                },
                "hdtype": "NOHOTDEAL",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 4831.65,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 369.45,
                "com_disc": 0,
                "rtf": "4832.00",
                "rdwtf": "4832.00"
            },
            {
                "tid": "iXrP3Eu5vCzT8MLm",
                "pid": "1911951",
                "hs": 0,
                "rmid": "201150829",
                "rtid": "206181821",
                "refundable": false,
                "available_rooms": 3,
                "score": 134,
                "sno": "149",
                "cont": {
                    "_id": "600d419ca1bc371b80fd558a",
                    "name": "Al Manar Hotel Apartments",
                    "ratings": "0.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.280222,
                            "longitude": 55.351199
                        }
                    },
                    "externalId": "1911951",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/2000000/1920000/1912000/1911951/00811951_b.jpg",
                    "ha": [
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "3.6",
                        "staff_service": "3.4",
                        "amenities": "3.0",
                        "conditions_facilities": "3.4"
                    },
                    "rat": "0.0",
                    "urat": "3.5",
                    "expediareview": 149,
                    "pid": "1911951",
                    "line1": "the corner 24th and 9th street",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Aparthotel",
                    "nearby": "Al-Maktoum Stadium - 1.2 km / 0.8 mi "
                },
                "hdtype": "NOHOTDEAL",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 4381.74,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 336.61,
                "com_disc": 0,
                "rtf": "4382.00",
                "rdwtf": "4382.00"
            },
            {
                "tid": "NApkv752uAekOmfR",
                "pid": "13457708",
                "hs": 0,
                "rmid": "201422015",
                "rtid": "207135535",
                "refundable": false,
                "available_rooms": 4,
                "score": 107,
                "sno": "121",
                "cont": {
                    "_id": "600d4144a1bc371b80fcea17",
                    "name": "Al Manar Grand Hotel Apartments",
                    "ratings": "0.0",
                    "location": {
                        "coordinates": {
                            "latitude": 25.248328,
                            "longitude": 55.296851
                        }
                    },
                    "externalId": "13457708",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/14000000/13460000/13457800/13457708/7fceed3f_b.jpg",
                    "ha": [
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "3.6",
                        "staff_service": "3.4",
                        "amenities": "3.0",
                        "conditions_facilities": "3.4"
                    },
                    "rat": "0.0",
                    "urat": "3.4",
                    "expediareview": 87,
                    "pid": "13457708",
                    "line1": "41 Kuwait Street",
                    "city": "Dubai",
                    "countryCode": "AE",
                    "catnm": "Aparthotel",
                    "nearby": "BurJuman Mall - 1.1 km / 0.7 mi "
                },
                "hdtype": "NOHOTDEAL",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 4106.7,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 314.45,
                "com_disc": 0,
                "rtf": "4107.00",
                "rdwtf": "4107.00"
            },
            {
                "tid": "q8wqIJaU7pkOdQhb",
                "pid": "19300659",
                "hs": 0,
                "rmid": "202077321",
                "rtid": "210664098",
                "refundable": true,
                "available_rooms": 9,
                "score": 129,
                "sno": "98",
                "cont": {
                    "_id": "600d3e5fa1bc371b80f8fa65",
                    "name": "Midtown Furnished Apartments",
                    "ratings": "3.5",
                    "location": {
                        "coordinates": {
                            "latitude": 25.388975,
                            "longitude": 55.446813
                        }
                    },
                    "externalId": "19300659",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/20000000/19310000/19300700/19300659/e15031d0_b.jpg",
                    "ha": [
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "3.5",
                        "staff_service": "3.5",
                        "amenities": "1.0",
                        "conditions_facilities": "3.5"
                    },
                    "rat": "3.5",
                    "urat": "3.5",
                    "expediareview": 4,
                    "pid": "19300659",
                    "line1": "King Faisal Street, Opposite Safeer Mall",
                    "city": "Ajman",
                    "countryCode": "AE",
                    "catnm": "Aparthotel",
                    "nearby": "Safeer Mall - 0.6 km / 0.3 mi "
                },
                "hdtype": "NOHOTDEAL",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 3898.16,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 290.64,
                "com_disc": 0,
                "rtf": "3898.00",
                "rdwtf": "3898.00"
            },
            {
                "tid": "JwVHRWFXepxaBXWA",
                "pid": "1837926",
                "hs": 0,
                "rmid": "211505843",
                "rtid": "381652194",
                "refundable": false,
                "available_rooms": 1,
                "score": 116,
                "sno": "89",
                "cont": {
                    "_id": "62506afe2aee3e1bb50b6755",
                    "ratings": "3.0",
                    "name": "Emirates Stars Hotel Apartments Sharjah",
                    "location": {
                        "coordinates": {
                            "latitude": 25.33126,
                            "longitude": 55.362157
                        },
                        "obfuscationRequired": false
                    },
                    "externalId": "1837926",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/2000000/1840000/1838000/1837926/edf2c379_b.jpg",
                    "ha": [
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "3.9",
                        "staff_service": "3.9",
                        "amenities": 0,
                        "conditions_facilities": "3.5"
                    },
                    "rat": "3.0",
                    "urat": "3.3",
                    "expediareview": 25,
                    "pid": "1837926",
                    "line1": "Al Khan Corniche Road",
                    "city": "Sharjah",
                    "countryCode": "AE",
                    "catnm": "Aparthotel",
                    "nearby": "Sharjah Aquarium - 0.4 km / 0.2 mi "
                },
                "hdtype": "NOHOTDEAL",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 3787.32,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 283.25,
                "com_disc": 0,
                "rtf": "3787.00",
                "rdwtf": "3787.00"
            },
            {
                "tid": "HCinzVG4T5xBBPID",
                "pid": "6400621",
                "hs": 0,
                "rmid": "200328086",
                "rtid": "262061638",
                "refundable": false,
                "available_rooms": 3,
                "score": 104,
                "sno": "61",
                "cont": {
                    "_id": "600d4239a1bc371b80fe4864",
                    "name": "Al Smou Hotel Apartments",
                    "ratings": "3.5",
                    "location": {
                        "coordinates": {
                            "latitude": 25.410366,
                            "longitude": 55.441102
                        }
                    },
                    "externalId": "6400621",
                    "externalProvider": "EXPEDIA",
                    "image": "https://i.travelapi.com/hotels/7000000/6410000/6400700/6400621/ed58c8b5_b.jpg",
                    "ha": [
                        "WIFI"
                    ],
                    "guestreview": {
                        "cleaniness": "3.5",
                        "staff_service": "3.2",
                        "amenities": "3.2",
                        "conditions_facilities": "3.3"
                    },
                    "rat": "3.5",
                    "urat": "3.3",
                    "expediareview": 30,
                    "pid": "6400621",
                    "line1": "Abu Bakr Al Sadeq Street",
                    "city": "Ajman",
                    "countryCode": "AE",
                    "catnm": "Apartment",
                    "nearby": "Ajman Museum - 0.7 km / 0.4 mi "
                },
                "hdtype": "NOHOTDEAL",
                "usrst": "Very Good",
                "brdbs": [
                    "Room Only"
                ],
                "gst": 0,
                "tds": 0,
                "ty": "lux",
                "disc": 0,
                "dwtf": 3600.13,
                "cur": "USD",
                "package": true,
                "ezy_deal": false,
                "market_fee": 268.47,
                "com_disc": 0,
                "rtf": "3600.00",
                "rdwtf": "3600.00"
            }
        ],
        "filters": {
            "hotelname": [
                {
                    "pid": "44327124",
                    "hname": "Heartland Hostel"
                },
                {
                    "pid": "16302167",
                    "hname": "Al Farej Hotel"
                },
                {
                    "pid": "10257665",
                    "hname": "Hafez Hotel Apartments"
                },
                {
                    "pid": "68444242",
                    "hname": "City Avenue Al Reqqa Hotel"
                },
                {
                    "pid": "27772378",
                    "hname": "Smana Hotel Al Raffa"
                },
                {
                    "pid": "2510628",
                    "hname": "Mercure Gold Hotel Al Mina Road Dubai"
                },
                {
                    "pid": "70380635",
                    "hname": "Rove Expo 2020"
                },
                {
                    "pid": "10073644",
                    "hname": "Mount Royal Hotel"
                },
                {
                    "pid": "8836227",
                    "hname": "Lotus Grand Hotel"
                },
                {
                    "pid": "15472935",
                    "hname": "Al Khaleej Grand Hotel"
                },
                {
                    "pid": "1723385",
                    "hname": "Nihal Hotel"
                },
                {
                    "pid": "45485856",
                    "hname": "KSK Homes"
                },
                {
                    "pid": "57120046",
                    "hname": "Lotus Retreat Hotel"
                },
                {
                    "pid": "54292500",
                    "hname": "The Leela Hotel Deira"
                },
                {
                    "pid": "75189394",
                    "hname": "Pearl Swiss Hotel"
                },
                {
                    "pid": "2696807",
                    "hname": "Royal Falcon Hotel"
                },
                {
                    "pid": "44167148",
                    "hname": "ibis Styles Dubai Airport Hotel"
                },
                {
                    "pid": "69555306",
                    "hname": "MOUNT SINA HOTEL BY AURA"
                },
                {
                    "pid": "69371396",
                    "hname": "Ecos Dubai Hotel at Al Furjan"
                },
                {
                    "pid": "3470732",
                    "hname": "Astoria Hotel"
                },
                {
                    "pid": "115888",
                    "hname": "Four Points by Sheraton Bur Dubai"
                },
                {
                    "pid": "4290737",
                    "hname": "Grand Central Hotel"
                },
                {
                    "pid": "45470766",
                    "hname": "URBAN Al Khoory Hotel"
                },
                {
                    "pid": "30549665",
                    "hname": "Signature Inn Deira"
                },
                {
                    "pid": "10073764",
                    "hname": "White Fort Hotel"
                },
                {
                    "pid": "16098107",
                    "hname": "Mariana Hotel"
                },
                {
                    "pid": "58003934",
                    "hname": "Downtown Hotel"
                },
                {
                    "pid": "1722824",
                    "hname": "OYO 367 Eureka Hotel"
                },
                {
                    "pid": "44814988",
                    "hname": "Lemon Tree Hotel Jumeirah Dubai"
                },
                {
                    "pid": "10073918",
                    "hname": "Grand Sina Hotel"
                },
                {
                    "pid": "20142741",
                    "hname": "OYO 338 Transworld Hotel"
                },
                {
                    "pid": "2301578",
                    "hname": "ibis Dubai Mall of the Emirates Hotel"
                },
                {
                    "pid": "31783592",
                    "hname": "Al Raien Hotel Apartment"
                },
                {
                    "pid": "44564297",
                    "hname": "The Manor by JA"
                },
                {
                    "pid": "3084786",
                    "hname": "ibis Al Rigga"
                },
                {
                    "pid": "74807407",
                    "hname": "OYO 800 Moon Creek Hotel"
                },
                {
                    "pid": "12946728",
                    "hname": "ibis One Central Hotel"
                },
                {
                    "pid": "2266468",
                    "hname": "ibis Al Barsha"
                },
                {
                    "pid": "6403757",
                    "hname": "Ramee Rose Hotel"
                },
                {
                    "pid": "13320046",
                    "hname": "Auris Inn Al Muhanna Hotel"
                },
                {
                    "pid": "68863561",
                    "hname": "Citadines Culture Village Dubai, Waterfront Jadaf"
                },
                {
                    "pid": "21902448",
                    "hname": "Al Khoory Inn"
                },
                {
                    "pid": "9098862",
                    "hname": "Landmark Premier Hotel"
                },
                {
                    "pid": "9074437",
                    "hname": "Fortune Hotel Deira"
                },
                {
                    "pid": "3181052",
                    "hname": "Pearl Executive Hotel Apartments"
                },
                {
                    "pid": "16433491",
                    "hname": "Gateway Hotel"
                },
                {
                    "pid": "1210393",
                    "hname": "Marco Polo Hotel"
                },
                {
                    "pid": "64007079",
                    "hname": "Kingsgate Al Jadaf by Millennium Hotels"
                },
                {
                    "pid": "4144930",
                    "hname": "Howard Johnson by Wyndham Bur Dubai"
                },
                {
                    "pid": "9988395",
                    "hname": "Mark Inn Hotel Deira"
                },
                {
                    "pid": "114730",
                    "hname": "AL KHALEEJ PALACE DEIRA HOTEL"
                },
                {
                    "pid": "39604927",
                    "hname": "Le Wana Hotel"
                },
                {
                    "pid": "16620981",
                    "hname": "Grandeur Hotel"
                },
                {
                    "pid": "33765129",
                    "hname": "Rove At The Park"
                },
                {
                    "pid": "12997370",
                    "hname": "ibis styles Sharjah Hotel"
                },
                {
                    "pid": "55606003",
                    "hname": "Rose Plaza Hotel Al Barsha"
                },
                {
                    "pid": "10368423",
                    "hname": "Al Sarab Hotel"
                },
                {
                    "pid": "33277716",
                    "hname": "Grayton Hotel Dubai"
                },
                {
                    "pid": "3124140",
                    "hname": "Premier Inn Dubai Investment Park"
                },
                {
                    "pid": "28058049",
                    "hname": "Westzone Plaza Hotel Apartments"
                },
                {
                    "pid": "4301167",
                    "hname": "Nihal Residency Hotel Apartments"
                },
                {
                    "pid": "6400621",
                    "hname": "Al Smou Hotel Apartments"
                },
                {
                    "pid": "5389621",
                    "hname": "Al Khoory Executive Hotel"
                },
                {
                    "pid": "64626575",
                    "hname": "Kingsgate Canal Hotel"
                },
                {
                    "pid": "33752649",
                    "hname": "The S Hotel Al Barsha"
                },
                {
                    "pid": "13351655",
                    "hname": "Signature 1 Hotel Tecom"
                },
                {
                    "pid": "1235808",
                    "hname": "Coral Dubai Deira Hotel"
                },
                {
                    "pid": "15472104",
                    "hname": "Al Khoory Atrium Hotel"
                },
                {
                    "pid": "9813763",
                    "hname": "Emirates Grand Hotel Apartments"
                },
                {
                    "pid": "19332507",
                    "hname": "TRYP by Wyndham Dubai"
                },
                {
                    "pid": "34994633",
                    "hname": "City Avenue Hotel"
                },
                {
                    "pid": "67353944",
                    "hname": "TIME Asma Hotel"
                },
                {
                    "pid": "11969003",
                    "hname": "ibis Styles Dubai Jumeira"
                },
                {
                    "pid": "4794423",
                    "hname": "Grand Excelsior Hotel Al Barsha"
                },
                {
                    "pid": "35308016",
                    "hname": "Center Hotel Sharjah"
                },
                {
                    "pid": "19643941",
                    "hname": "Red Castle Hotel"
                },
                {
                    "pid": "27507087",
                    "hname": "Maisan Hotel"
                },
                {
                    "pid": "9646284",
                    "hname": "West Hotel"
                },
                {
                    "pid": "114530",
                    "hname": "Mayfair Hotel"
                },
                {
                    "pid": "1366982",
                    "hname": "Radisson Blu Hotel, Dubai Media City"
                },
                {
                    "pid": "74445717",
                    "hname": "Maaeen Hotel"
                },
                {
                    "pid": "46110658",
                    "hname": "sleep 'n fly Sleep Lounge, Dubai International Airport (DXB), D-Gates (Terminal 1/ Transit Area)"
                },
                {
                    "pid": "6438114",
                    "hname": "DoubleTree by Hilton Hotel and Residences Dubai Al Barsha"
                },
                {
                    "pid": "39638313",
                    "hname": "Premier Inn Dubai Dragon Mart"
                },
                {
                    "pid": "55414427",
                    "hname": "Al Karnak Hotel"
                },
                {
                    "pid": "64434236",
                    "hname": "Radisson Dubai Damac Hills"
                },
                {
                    "pid": "44874529",
                    "hname": "Grand Mercure Dubai Airport Hotel"
                },
                {
                    "pid": "32070004",
                    "hname": "Premier Inn Dubai Al Jaddaf"
                },
                {
                    "pid": "1632097",
                    "hname": "Nejoum Al Emarate"
                },
                {
                    "pid": "1837926",
                    "hname": "Emirates Stars Hotel Apartments Sharjah"
                },
                {
                    "pid": "71143500",
                    "hname": "Grand Kingsgate Waterfront By Millennium Hotels"
                },
                {
                    "pid": "3592422",
                    "hname": "The Apartments Dubai World Trade Centre"
                },
                {
                    "pid": "3236020",
                    "hname": "Holiday Inn Express Dubai Airport"
                },
                {
                    "pid": "32187645",
                    "hname": "Studio M Arabian Plaza"
                },
                {
                    "pid": "69641271",
                    "hname": "Aloft Dubai Airport"
                },
                {
                    "pid": "66252432",
                    "hname": "Aloft Al Mina, Dubai"
                },
                {
                    "pid": "2227239",
                    "hname": "Donatello Hotel"
                },
                {
                    "pid": "37141494",
                    "hname": "Hampton by Hilton Dubai Al Barsha"
                },
                {
                    "pid": "19300659",
                    "hname": "Midtown Furnished Apartments"
                },
                {
                    "pid": "3806375",
                    "hname": "Cassells Al Barsha Hotel by IGH"
                },
                {
                    "pid": "1742022",
                    "hname": "Holiday Inn Express Dubai, Internet City"
                },
                {
                    "pid": "1338993",
                    "hname": "Avenue Hotel"
                },
                {
                    "pid": "21903961",
                    "hname": "Aloft Me'aisam, Dubai"
                },
                {
                    "pid": "804814",
                    "hname": "Regent Palace Hotel"
                },
                {
                    "pid": "20183124",
                    "hname": "MENA Plaza Hotel Albarsha"
                },
                {
                    "pid": "2221509",
                    "hname": "ibis Deira City Centre"
                },
                {
                    "pid": "1453623",
                    "hname": "Grand Excelsior Hotel Bur Dubai"
                },
                {
                    "pid": "6495722",
                    "hname": "Fortune Grand Hotel"
                },
                {
                    "pid": "16700525",
                    "hname": "Rove Healthcare City"
                },
                {
                    "pid": "46129288",
                    "hname": "TIME Express Hotel Al Khan"
                },
                {
                    "pid": "897743",
                    "hname": "Landmark Hotel"
                },
                {
                    "pid": "315936",
                    "hname": "Excelsior Hotel Downtown"
                },
                {
                    "pid": "9392825",
                    "hname": "Saffron Hotel"
                },
                {
                    "pid": "45628261",
                    "hname": "Better Living Hotel Apartment"
                },
                {
                    "pid": "1971502",
                    "hname": "Holiday Inn Dubai - Al Barsha"
                },
                {
                    "pid": "11628336",
                    "hname": "Hilton Garden Inn Dubai Al Mina"
                },
                {
                    "pid": "11638229",
                    "hname": "Hilton Garden Inn Dubai Al Muraqabat"
                },
                {
                    "pid": "14827079",
                    "hname": "Landmark Summit Hotel"
                },
                {
                    "pid": "3474919",
                    "hname": "Rayan Hotel Sharjah"
                },
                {
                    "pid": "527499",
                    "hname": "J5 Hotels - Port Saeed"
                },
                {
                    "pid": "16083123",
                    "hname": "dusitD2 kenz Hotel Dubai"
                },
                {
                    "pid": "13457708",
                    "hname": "Al Manar Grand Hotel Apartments"
                },
                {
                    "pid": "16723088",
                    "hname": "Premier Inn Dubai Ibn Battuta Mall"
                },
                {
                    "pid": "70108301",
                    "hname": "Al Khoory Sky Garden Hotel"
                },
                {
                    "pid": "34981609",
                    "hname": "Queen's Hotel"
                },
                {
                    "pid": "5239808",
                    "hname": "Chelsea Plaza Hotel Dubai"
                },
                {
                    "pid": "41484136",
                    "hname": "Avani Ibn Battuta Dubai Hotel"
                },
                {
                    "pid": "3125411",
                    "hname": "Premier Inn Dubai International Airport"
                },
                {
                    "pid": "48602582",
                    "hname": "Al Ashrafia Holiday Waterfront Downtown"
                },
                {
                    "pid": "1548322",
                    "hname": "Majestic City Retreat Hotel"
                },
                {
                    "pid": "3374468",
                    "hname": "Fraser Suites Dubai"
                },
                {
                    "pid": "45601132",
                    "hname": "Holiday Inn Dubai Al-Maktoum Airport"
                },
                {
                    "pid": "2595659",
                    "hname": "City Seasons Hotel Dubai Airport"
                },
                {
                    "pid": "11766572",
                    "hname": "Fortune Karama Hotel"
                },
                {
                    "pid": "917157",
                    "hname": "Landmark Plaza Hotel"
                },
                {
                    "pid": "12474441",
                    "hname": "Atana Hotel"
                },
                {
                    "pid": "10971762",
                    "hname": "Manhattan Avenue Hotel"
                },
                {
                    "pid": "48183630",
                    "hname": "IntercityHotel Dubai Jaddaf Waterfront"
                },
                {
                    "pid": "14933085",
                    "hname": "City Seasons Towers"
                },
                {
                    "pid": "9988389",
                    "hname": "Zain International Hotel"
                },
                {
                    "pid": "805863",
                    "hname": "Sea View Hotel"
                },
                {
                    "pid": "27338819",
                    "hname": "Hilton Garden Inn Dubai Al Jadaf Culture Village"
                },
                {
                    "pid": "35064054",
                    "hname": "Crowne Plaza Dubai Apartments"
                },
                {
                    "pid": "2224895",
                    "hname": "Chelsea Gardens Hotel Apartments"
                },
                {
                    "pid": "10106700",
                    "hname": "Raviz Center Point Hotel"
                },
                {
                    "pid": "5773629",
                    "hname": "Movenpick Hotel Apartments Al Mamzar Dubai"
                },
                {
                    "pid": "12946724",
                    "hname": "ibis Styles Dragon Mart Dubai"
                },
                {
                    "pid": "37260445",
                    "hname": "Novotel Bur Dubai"
                },
                {
                    "pid": "48997",
                    "hname": "Millennium Airport Hotel Dubai"
                },
                {
                    "pid": "1911951",
                    "hname": "Al Manar Hotel Apartments"
                },
                {
                    "pid": "9517628",
                    "hname": "Signature Hotel Al Barsha"
                },
                {
                    "pid": "29079976",
                    "hname": "Aloft Dubai South"
                },
                {
                    "pid": "4109045",
                    "hname": "Tulip Hotel Apartment"
                },
                {
                    "pid": "4127757",
                    "hname": "Sharjah Palace Hotel"
                },
                {
                    "pid": "6481219",
                    "hname": "Copthorne Hotel Sharjah"
                },
                {
                    "pid": "31918874",
                    "hname": "Tulip Al Barsha Hotel Apartment"
                },
                {
                    "pid": "4239506",
                    "hname": "Millennium Central Downtown"
                },
                {
                    "pid": "1001421",
                    "hname": "Le Meridien Fairway"
                },
                {
                    "pid": "14902055",
                    "hname": "San Marco Hotel"
                },
                {
                    "pid": "526364",
                    "hname": "Admiral Plaza Hotel Dubai"
                },
                {
                    "pid": "41262024",
                    "hname": "Park Regis Business Bay Hotel"
                },
                {
                    "pid": "2699605",
                    "hname": "Signature Hotel Apartments & Spa"
                },
                {
                    "pid": "18516665",
                    "hname": "Park Inn by Radisson Dubai Motor City"
                },
                {
                    "pid": "1773507",
                    "hname": "Four Points by Sheraton Downtown Dubai"
                },
                {
                    "pid": "1767717",
                    "hname": "Golden Tulip Sharjah"
                },
                {
                    "pid": "59056060",
                    "hname": "Super 8 by Wyndham Dubai Deira"
                },
                {
                    "pid": "19531804",
                    "hname": "Omega Hotel"
                },
                {
                    "pid": "2348266",
                    "hname": "Holiday International Sharjah"
                },
                {
                    "pid": "5440701",
                    "hname": "Novotel Dubai Al Barsha"
                },
                {
                    "pid": "4252421",
                    "hname": "Golden Tulip Media Hotel"
                },
                {
                    "pid": "55562354",
                    "hname": "Fortune Atrium Hotel"
                },
                {
                    "pid": "1794962",
                    "hname": "Copthorne Hotel Dubai"
                },
                {
                    "pid": "7610",
                    "hname": "Crowne Plaza Dubai"
                },
                {
                    "pid": "12949919",
                    "hname": "Hilton Garden Inn Dubai Mall Of The Emirates"
                },
                {
                    "pid": "1693670",
                    "hname": "Ramee Royal Hotel"
                },
                {
                    "pid": "2425531",
                    "hname": "Novotel Suites Mall of the Emirates"
                },
                {
                    "pid": "73446353",
                    "hname": "West Zone Pearl Hotel Apartment"
                },
                {
                    "pid": "17333665",
                    "hname": "Best Western Plus Pearl Creek"
                },
                {
                    "pid": "33299119",
                    "hname": "Novotel Sharjah Expo Center"
                },
                {
                    "pid": "10076887",
                    "hname": "MENA APARTHOTEL"
                },
                {
                    "pid": "49217048",
                    "hname": "Edge Creekside Hotel"
                },
                {
                    "pid": "3123973",
                    "hname": "Premier Inn Dubai Silicon Oasis"
                },
                {
                    "pid": "2870907",
                    "hname": "Landmark Hotel Riqqa"
                },
                {
                    "pid": "42490297",
                    "hname": "Ramada by Wyndham Dubai Deira"
                },
                {
                    "pid": "3071573",
                    "hname": "Avani Deira Dubai Hotel"
                },
                {
                    "pid": "21854816",
                    "hname": "FORM Hotel Dubai, a Member of Design Hotels"
                },
                {
                    "pid": "60539202",
                    "hname": "La Quinta by Wyndham Dubai Jumeirah"
                },
                {
                    "pid": "16021758",
                    "hname": "Lavender Hotel Sharjah"
                },
                {
                    "pid": "2357274",
                    "hname": "TIME Oak Hotel & Suites"
                },
                {
                    "pid": "2442729",
                    "hname": "Al Khoory Hotel Apartments"
                },
                {
                    "pid": "69801",
                    "hname": "Mövenpick Grand Al Bustan Dubai"
                },
                {
                    "pid": "31788666",
                    "hname": "Studio One Hotel"
                },
                {
                    "pid": "17398301",
                    "hname": "Royal Continental Hotel – Dubai Airport"
                },
                {
                    "pid": "1764405",
                    "hname": "Tamani Marina Hotel and Hotel Apartments"
                },
                {
                    "pid": "16467386",
                    "hname": "Fortune Park Hotel"
                },
                {
                    "pid": "10175890",
                    "hname": "Al Jawhara Gardens Hotel"
                },
                {
                    "pid": "2818894",
                    "hname": "ZiQoo Hotel Apartments"
                },
                {
                    "pid": "22779634",
                    "hname": "Rove Dubai Marina"
                },
                {
                    "pid": "9842947",
                    "hname": "Ghaya Grand Hotel"
                },
                {
                    "pid": "75831559",
                    "hname": "Studio M Al Barsha By Millennium"
                },
                {
                    "pid": "12333050",
                    "hname": "Asiana Hotel Dubai"
                },
                {
                    "pid": "4333545",
                    "hname": "Marina View Hotel Apartments"
                },
                {
                    "pid": "38931821",
                    "hname": "sleep ’n fly Sleep Lounge – Dubai Airport, A-Gates (Terminal 3)"
                },
                {
                    "pid": "988207",
                    "hname": "Ramada Plaza by Wyndham Dubai Deira"
                },
                {
                    "pid": "73338667",
                    "hname": "Novotel Jumeirah Village Triangle"
                },
                {
                    "pid": "45601126",
                    "hname": "Staybridge Suites Dubai Al-Maktoum Airport"
                },
                {
                    "pid": "68864674",
                    "hname": "Days Hotel By Wyndham Dubai Deira"
                },
                {
                    "pid": "45959441",
                    "hname": "Pullman Sharjah"
                },
                {
                    "pid": "57482292",
                    "hname": "Suha Creek Hotel Apartment"
                },
                {
                    "pid": "1227381",
                    "hname": "Arabian Courtyard Hotel & Spa"
                },
                {
                    "pid": "3930355",
                    "hname": "TIME Grand Plaza Hotel, Dubai Airport"
                },
                {
                    "pid": "75614085",
                    "hname": "City Stay Premium Hotel Apartments"
                },
                {
                    "pid": "23512412",
                    "hname": "Hampton by Hilton Dubai Airport"
                },
                {
                    "pid": "2239650",
                    "hname": "Holiday Inn Express Dubai Jumeirah"
                },
                {
                    "pid": "24206066",
                    "hname": "Gulf Court Hotel Business Bay"
                },
                {
                    "pid": "81083",
                    "hname": "J5 Rimal Hotel Apartments"
                },
                {
                    "pid": "12497497",
                    "hname": "Jannah Marina Hotel Apartments"
                },
                {
                    "pid": "22868663",
                    "hname": "Queen Elizabeth 2 Hotel"
                },
                {
                    "pid": "68997818",
                    "hname": "Rove City Walk"
                },
                {
                    "pid": "33403743",
                    "hname": "Millennium AL Barsha"
                },
                {
                    "pid": "4636372",
                    "hname": "Xclusive Maples Hotel Apartment"
                },
                {
                    "pid": "2103299",
                    "hname": "XCLUSIVE Hotel Apartments"
                },
                {
                    "pid": "1876527",
                    "hname": "Star Metro Deira Hotel Apartments"
                },
                {
                    "pid": "69652110",
                    "hname": "Element Dubai Airport"
                },
                {
                    "pid": "12497496",
                    "hname": "Jannah Place Dubai Marina"
                },
                {
                    "pid": "57399440",
                    "hname": "Wyndham Dubai Deira"
                },
                {
                    "pid": "15508095",
                    "hname": "Metropolitan Hotel Dubai"
                },
                {
                    "pid": "2222454",
                    "hname": "Samaya Hotel Deira"
                },
                {
                    "pid": "18810662",
                    "hname": "FLORIDA CITY HOTEL APARTMENTS"
                },
                {
                    "pid": "20219460",
                    "hname": "Rose Park Hotel Al Barsha"
                },
                {
                    "pid": "21307739",
                    "hname": "The Act Hotel"
                },
                {
                    "pid": "41828999",
                    "hname": "OYO 314 24 Gold Hotel"
                },
                {
                    "pid": "27546056",
                    "hname": "Al Seef Heritage Hotel Dubai, Curio Collection by Hilton"
                },
                {
                    "pid": "30560988",
                    "hname": "Stella Di Mare Dubai Marina Hotel"
                },
                {
                    "pid": "911528",
                    "hname": "Novotel World Trade Centre"
                },
                {
                    "pid": "18303940",
                    "hname": "Rove Trade Centre"
                },
                {
                    "pid": "16225389",
                    "hname": "Rove City Centre"
                },
                {
                    "pid": "903302",
                    "hname": "Dubai Grand Hotel by Fortune"
                },
                {
                    "pid": "1546377",
                    "hname": "Courtyard by Marriott Dubai Green Community"
                },
                {
                    "pid": "1937184",
                    "hname": "Royal Ascot Hotel"
                },
                {
                    "pid": "1063960",
                    "hname": "Carlton Tower Hotel"
                },
                {
                    "pid": "4649108",
                    "hname": "The Canvas Hotel Dubai - MGallery"
                },
                {
                    "pid": "10017195",
                    "hname": "Tulip Creek Hotel Apartments"
                },
                {
                    "pid": "32937461",
                    "hname": "Four Points by Sheraton Sharjah"
                },
                {
                    "pid": "4560641",
                    "hname": "Fortune Plaza Hotel"
                },
                {
                    "pid": "12739457",
                    "hname": "Treppan Hotel & Suites by Fakhruddin"
                },
                {
                    "pid": "1855531",
                    "hname": "Emirates Stars Hotel Apartments Dubai"
                },
                {
                    "pid": "4804157",
                    "hname": "First Central Hotel Suites"
                },
                {
                    "pid": "58007149",
                    "hname": "New Royal Mark Hotel Apartments"
                },
                {
                    "pid": "18810932",
                    "hname": "Florida Al Souq Hotel"
                },
                {
                    "pid": "21766370",
                    "hname": "Hampton by Hilton Dubai Al Seef"
                },
                {
                    "pid": "38937814",
                    "hname": "Radisson Blu Hotel, Dubai Canal View"
                },
                {
                    "pid": "3949414",
                    "hname": "Auris Boutique Hotel Apartments"
                },
                {
                    "pid": "5317671",
                    "hname": "Citadines Metro Central Dubai"
                },
                {
                    "pid": "1210397",
                    "hname": "Hotel Riviera"
                },
                {
                    "pid": "73349297",
                    "hname": "Royal Regency Holiday Homes"
                },
                {
                    "pid": "52970555",
                    "hname": "Golden Stay Vacation Continental Tower"
                },
                {
                    "pid": "68060304",
                    "hname": "Rose Executive Hotel - DWTC"
                },
                {
                    "pid": "4308762",
                    "hname": "Ewan Ajman Suites Hotel"
                },
                {
                    "pid": "55405808",
                    "hname": "Suha Park Hotel Apartment"
                },
                {
                    "pid": "2824212",
                    "hname": "TIME Ruby Hotel Apartments"
                },
                {
                    "pid": "1554896",
                    "hname": "Savoy Park Hotel Apartments"
                },
                {
                    "pid": "4937616",
                    "hname": "Elite Byblos Hotel"
                },
                {
                    "pid": "66252433",
                    "hname": "Element Al Mina, Dubai"
                },
                {
                    "pid": "115885",
                    "hname": "Jumeira Rotana"
                },
                {
                    "pid": "13300536",
                    "hname": "Wyndham Dubai Marina"
                },
                {
                    "pid": "60005289",
                    "hname": "Element Al Jaddaf, Dubai"
                },
                {
                    "pid": "65306293",
                    "hname": "Grand Heights Hotel Apartments"
                },
                {
                    "pid": "2173527",
                    "hname": "Holiday Inn Express Dubai Safa Park"
                },
                {
                    "pid": "61208075",
                    "hname": "TIME Onyx Hotel Apartment"
                },
                {
                    "pid": "21865124",
                    "hname": "Element Me'aisam, Dubai"
                },
                {
                    "pid": "39914722",
                    "hname": "Millennium Place Barsha Heights Hotel"
                },
                {
                    "pid": "16623092",
                    "hname": "Rolla Residence Hotel Apartment"
                },
                {
                    "pid": "11788864",
                    "hname": "Flora Al Barsha Hotel at the Mall"
                },
                {
                    "pid": "25756019",
                    "hname": "Flora Inn Hotel Dubai Airport"
                },
                {
                    "pid": "2802091",
                    "hname": "Number One Tower Suites"
                },
                {
                    "pid": "863980",
                    "hname": "Movenpick Hotel & Apartments Bur Dubai"
                },
                {
                    "pid": "36378545",
                    "hname": "Millennium Place Marina"
                },
                {
                    "pid": "6394",
                    "hname": "Sheraton Dubai Creek Hotel & Towers"
                },
                {
                    "pid": "42120601",
                    "hname": "Lavender Hotel & Hotel Apartments Al Nahda"
                },
                {
                    "pid": "4617919",
                    "hname": "Millennium Plaza Hotel Dubai"
                },
                {
                    "pid": "39886064",
                    "hname": "Occidental Al Jaddaf"
                },
                {
                    "pid": "18811168",
                    "hname": "FLORIDA SQUARE HOTEL"
                },
                {
                    "pid": "23474875",
                    "hname": "VERSAILLES BY RAVIZ"
                },
                {
                    "pid": "517439",
                    "hname": "Crowne Plaza Dubai Deira"
                },
                {
                    "pid": "59493778",
                    "hname": "DoubleTree by Hilton Dubai M Square Hotel & Residences"
                },
                {
                    "pid": "1545211",
                    "hname": "Savoy Crest Hotel Apartments"
                },
                {
                    "pid": "31718649",
                    "hname": "Courtyard by Marriott Al Barsha, Dubai"
                },
                {
                    "pid": "4717738",
                    "hname": "Hues Boutique Hotel"
                },
                {
                    "pid": "4018144",
                    "hname": "Park Regis Kris Kin Hotel Dubai"
                },
                {
                    "pid": "13561574",
                    "hname": "Rove Downtown Dubai"
                },
                {
                    "pid": "177932",
                    "hname": "Le Meridien Dubai Hotel & Conference Centre"
                },
                {
                    "pid": "2536137",
                    "hname": "Al Waleed Palace Hotel Apartments Oud Metha"
                },
                {
                    "pid": "49190999",
                    "hname": "sleep ’n fly Sleep Lounge, Dubai International Airport (DXB), C-Gates (Terminal 3/ Transit Area)"
                },
                {
                    "pid": "67628753",
                    "hname": "Lux BnB 1BD Green Views"
                },
                {
                    "pid": "1769023",
                    "hname": "Grand Millennium Dubai"
                },
                {
                    "pid": "4051284",
                    "hname": "Golden Sands Hotel Sharjah"
                },
                {
                    "pid": "65401015",
                    "hname": "Home Away Holiday Homes"
                },
                {
                    "pid": "55234969",
                    "hname": "Beach Walk Hotel"
                },
                {
                    "pid": "38874623",
                    "hname": "Millennium Executive Apartments Mont Rose"
                },
                {
                    "pid": "26794669",
                    "hname": "Tulip in al Khan"
                },
                {
                    "pid": "21198252",
                    "hname": "Class Hotel Apartments"
                },
                {
                    "pid": "57483154",
                    "hname": "Suha Mina Rashid Hotel Apartment"
                },
                {
                    "pid": "68079765",
                    "hname": "City Stay Residences"
                },
                {
                    "pid": "5395774",
                    "hname": "Savoy Central Hotel Apartments"
                },
                {
                    "pid": "68600200",
                    "hname": "HE Hotel Apartments"
                },
                {
                    "pid": "68498007",
                    "hname": "Crowne Plaza Dubai Jumeirah, an IHG Hotel"
                },
                {
                    "pid": "6501513",
                    "hname": "Movenpick Hotel Jumeirah Lakes Towers"
                },
                {
                    "pid": "74422003",
                    "hname": "Adagio Jumeirah Village Triangle"
                },
                {
                    "pid": "59446802",
                    "hname": "Aparthotel Adagio Dubai Deira"
                },
                {
                    "pid": "66960178",
                    "hname": "Revier Dubai"
                },
                {
                    "pid": "6975243",
                    "hname": "Marriott Hotel Al Jaddaf, Dubai"
                },
                {
                    "pid": "72108064",
                    "hname": "Hyatt Centric Jumeirah Dubai"
                },
                {
                    "pid": "9774377",
                    "hname": "XVA Art Hotel"
                },
                {
                    "pid": "38262032",
                    "hname": "One Perfect Stay -  Residence 1"
                },
                {
                    "pid": "74275369",
                    "hname": "LuX BnB Studio Downtown Amazing Views"
                },
                {
                    "pid": "2222430",
                    "hname": "Marbella Resort Sharjah"
                },
                {
                    "pid": "10176034",
                    "hname": "Al Jawhara Metro Hotel"
                },
                {
                    "pid": "8255648",
                    "hname": "Somewhere Hotel Apartment"
                },
                {
                    "pid": "5468555",
                    "hname": "Abidos Hotel Apartment, Dubailand"
                },
                {
                    "pid": "31761212",
                    "hname": "Leva Hotel and Suites, Mazaya Centre"
                },
                {
                    "pid": "2829306",
                    "hname": "Arabian Ranches Golf Club"
                },
                {
                    "pid": "8800126",
                    "hname": "Adagio Premium Dubai Al Barsha"
                },
                {
                    "pid": "3802792",
                    "hname": "Oaks Ibn Battuta Gate Dubai"
                },
                {
                    "pid": "2844235",
                    "hname": "Al Bustan Residence Hotel-Apartments"
                },
                {
                    "pid": "33298316",
                    "hname": "Hotel Indigo Dubai Downtown"
                },
                {
                    "pid": "4321780",
                    "hname": "Dunes Hotel Apartments Oud Metha"
                },
                {
                    "pid": "10974533",
                    "hname": "Hyatt Place Dubai Baniyas Square"
                },
                {
                    "pid": "45847029",
                    "hname": "Grand Cosmopolitan Hotel"
                },
                {
                    "pid": "39747310",
                    "hname": "Millennium Place Barsha Heights Hotel Apartments"
                },
                {
                    "pid": "18464497",
                    "hname": "Mercure Hotel Apartments Dubai Barsha Heights"
                },
                {
                    "pid": "66777651",
                    "hname": "Bespoke Residence - Luxury Studio Apt"
                },
                {
                    "pid": "83995",
                    "hname": "Carlton Palace Hotel"
                },
                {
                    "pid": "70260071",
                    "hname": "DoubleTree by Hilton Sharjah Waterfront Hotel & Residences"
                },
                {
                    "pid": "17330745",
                    "hname": "Pearl City Suites By Gemstones"
                },
                {
                    "pid": "48602706",
                    "hname": "Al Ashrafia Holiday - Marina Pinnacle"
                },
                {
                    "pid": "22499594",
                    "hname": "Royal Hotel Sharjah"
                },
                {
                    "pid": "1764839",
                    "hname": "The H Dubai"
                },
                {
                    "pid": "69378647",
                    "hname": "The First Collection at Jumeirah Village Circle"
                },
                {
                    "pid": "49102294",
                    "hname": "Rove La Mer Beach"
                },
                {
                    "pid": "795905",
                    "hname": "Dusit Thani Dubai"
                },
                {
                    "pid": "8797323",
                    "hname": "Sheraton Grand Hotel, Dubai"
                },
                {
                    "pid": "41976524",
                    "hname": "Taj Jumeirah Lakes Towers"
                },
                {
                    "pid": "70886931",
                    "hname": "Millennium Place Mirdif Apartments"
                },
                {
                    "pid": "21766364",
                    "hname": "Canopy by Hilton Dubai Al Seef"
                },
                {
                    "pid": "2270780",
                    "hname": "Spark Residence Deluxe Hotel Apartments"
                },
                {
                    "pid": "9360689",
                    "hname": "Al Raya Hotel Apartment"
                },
                {
                    "pid": "6895145",
                    "hname": "72 Hotel"
                },
                {
                    "pid": "74999217",
                    "hname": "City Stay Grand Hotel Apartments"
                },
                {
                    "pid": "45263327",
                    "hname": "Radisson Red Dubai Silicon Oasis"
                },
                {
                    "pid": "2393053",
                    "hname": "Ramada Hotel & Suites by Wyndham Ajman"
                },
                {
                    "pid": "19625773",
                    "hname": "Crowne Plaza Dubai Marina"
                },
                {
                    "pid": "23055261",
                    "hname": "Aloft City Centre Deira, Dubai"
                },
                {
                    "pid": "58960880",
                    "hname": "Avani Palm View Dubai Hotel & Suites"
                },
                {
                    "pid": "23368348",
                    "hname": "AlSalam Grand Hotel & Hotel Apartments"
                },
                {
                    "pid": "18313422",
                    "hname": "Radisson Blu Hotel Dubai Waterfront"
                },
                {
                    "pid": "64989627",
                    "hname": "Abar Hotel Apartments"
                },
                {
                    "pid": "2538761",
                    "hname": "Royal Club at Palm Jumeirah"
                },
                {
                    "pid": "4595744",
                    "hname": "Swissotel Al Ghurair"
                },
                {
                    "pid": "28827007",
                    "hname": "Zabeel House by Jumeirah, The Greens"
                },
                {
                    "pid": "4557255",
                    "hname": "Jumeirah Creekside Hotel"
                },
                {
                    "pid": "2102708",
                    "hname": "Radisson Blu Residence, Dubai Marina"
                },
                {
                    "pid": "71681447",
                    "hname": "Courtyard by Marriott World Trade Centre, Dubai"
                },
                {
                    "pid": "50904812",
                    "hname": "Royal Continental Suites"
                },
                {
                    "pid": "32905077",
                    "hname": "Hyatt Place Dubai Wasl District"
                },
                {
                    "pid": "8023381",
                    "hname": "Carlton Downtown"
                },
                {
                    "pid": "18732016",
                    "hname": "Mercure Suites Dubai Barsha Heights"
                },
                {
                    "pid": "24197743",
                    "hname": "Hyatt Regency Dubai Creek Heights Residences"
                },
                {
                    "pid": "9709394",
                    "hname": "Hyatt Regency Dubai Creek Heights"
                },
                {
                    "pid": "22561809",
                    "hname": "Barcelo Residences Dubai Marina"
                },
                {
                    "pid": "4849400",
                    "hname": "City Premiere Hotel Apartment"
                },
                {
                    "pid": "13062822",
                    "hname": "Bin Majid Flamingo"
                },
                {
                    "pid": "1545207",
                    "hname": "Savoy Suites Hotel Apartments"
                },
                {
                    "pid": "6975242",
                    "hname": "Marriott Executive Apartments Dubai, Al Jaddaf"
                },
                {
                    "pid": "33010100",
                    "hname": "Vida Emirates Hills"
                },
                {
                    "pid": "8835794",
                    "hname": "Armada BlueBay"
                },
                {
                    "pid": "1234599",
                    "hname": "Swissôtel Al Murooj Dubai"
                },
                {
                    "pid": "64178013",
                    "hname": "Residence Inn by Marriott Al Jaddaf"
                },
                {
                    "pid": "3990073",
                    "hname": "voco Dubai - an IHG Hotel"
                },
                {
                    "pid": "27190443",
                    "hname": "Grand Millennium Business Bay"
                },
                {
                    "pid": "15705800",
                    "hname": "Pearl Hotel & Spa"
                },
                {
                    "pid": "38869896",
                    "hname": "FIVE Jumeirah Village Dubai"
                },
                {
                    "pid": "536502",
                    "hname": "Four Points by Sheraton Sheikh Zayed Road, Dubai"
                },
                {
                    "pid": "9509332",
                    "hname": "Pullman Dubai Jumeirah Lakes Towers - Hotel and Residence"
                },
                {
                    "pid": "21870",
                    "hname": "Radisson Blu Hotel, Dubai Deira Creek"
                },
                {
                    "pid": "59202819",
                    "hname": "Al Ashrafia Luxury Apts-Downtown"
                },
                {
                    "pid": "227448",
                    "hname": "Ajman Hotel"
                },
                {
                    "pid": "3840652",
                    "hname": "Grand Stay Hotel Dubai"
                },
                {
                    "pid": "22937965",
                    "hname": "Gevora Hotel"
                },
                {
                    "pid": "74258137",
                    "hname": "Lux BnB 2BD Amazing Burj View"
                },
                {
                    "pid": "26669791",
                    "hname": "Canal Central Hotel"
                },
                {
                    "pid": "71082514",
                    "hname": "SITARA HOTEL APARTMENT"
                },
                {
                    "pid": "20242915",
                    "hname": "Park Regis Boutique Hotel"
                },
                {
                    "pid": "7228208",
                    "hname": "Royal Residence Resort"
                },
                {
                    "pid": "4480234",
                    "hname": "Delta hotels by Marriott Jumeirah Beach, Dubai"
                },
                {
                    "pid": "791990",
                    "hname": "Occidental Sharjah Grand"
                },
                {
                    "pid": "5210010",
                    "hname": "Nassima Tower Hotel Apartments"
                },
                {
                    "pid": "33010119",
                    "hname": "Vida Creek Harbour"
                },
                {
                    "pid": "6915556",
                    "hname": "Ramada Hotel and Suites by Wyndham Dubai JBR"
                },
                {
                    "pid": "38122488",
                    "hname": "Hyatt Place Wasl District Residences"
                },
                {
                    "pid": "1141374",
                    "hname": "Marriott Executive Apartments Green Community"
                },
                {
                    "pid": "5399338",
                    "hname": "JW Marriott Marquis Hotel Dubai"
                },
                {
                    "pid": "71064272",
                    "hname": "Park View Spacious Studio With Balcony"
                },
                {
                    "pid": "32773397",
                    "hname": "Grand Plaza Mövenpick Media City"
                },
                {
                    "pid": "33215543",
                    "hname": "Millennium Atria Business Bay"
                },
                {
                    "pid": "4588682",
                    "hname": "Hilton Dubai The Walk"
                },
                {
                    "pid": "3026573",
                    "hname": "Media One Hotel Dubai"
                },
                {
                    "pid": "2564867",
                    "hname": "Address Montgomerie"
                },
                {
                    "pid": "2212742",
                    "hname": "Ascott Park Place Dubai"
                },
                {
                    "pid": "910165",
                    "hname": "Hyatt Regency Dubai"
                },
                {
                    "pid": "48880545",
                    "hname": "Maison Privee Marquise Square"
                },
                {
                    "pid": "2175146",
                    "hname": "Novotel Dubai Deira City Centre"
                },
                {
                    "pid": "2885038",
                    "hname": "Movenpick Hotel Jumeirah Beach"
                },
                {
                    "pid": "31815811",
                    "hname": "Teecom 1 Bedroom Apartment"
                },
                {
                    "pid": "41997872",
                    "hname": "C Central Resort the Palm"
                },
                {
                    "pid": "2193284",
                    "hname": "Oaks Liwa Heights Suites"
                },
                {
                    "pid": "985916",
                    "hname": "Dubai Marine Beach Resort & Spa"
                },
                {
                    "pid": "5414971",
                    "hname": "The Oberoi"
                },
                {
                    "pid": "38261023",
                    "hname": "Marbella Holiday Homes"
                },
                {
                    "pid": "72093154",
                    "hname": "Hyde Hotel Dubai"
                },
                {
                    "pid": "7495229",
                    "hname": "City Première Marina Hotel Apartments"
                },
                {
                    "pid": "2206423",
                    "hname": "Flora Creek Deluxe Hotel Apartments"
                },
                {
                    "pid": "13320158",
                    "hname": "V Hotel Dubai, Curio Collection by Hilton"
                },
                {
                    "pid": "71064278",
                    "hname": "Luxury Stay at Park View Dubai"
                },
                {
                    "pid": "12294745",
                    "hname": "Steigenberger Hotel Downtown Dubai"
                },
                {
                    "pid": "18381583",
                    "hname": "Radisson Blu Hotel, Ajman"
                },
                {
                    "pid": "46271513",
                    "hname": "Staycae Business Bay City View"
                },
                {
                    "pid": "75982997",
                    "hname": "1B-La Fontana-613 by bnbme homes"
                },
                {
                    "pid": "64383195",
                    "hname": "Staybridge Suites Dubai Financial Centre, an IHG Hotel"
                },
                {
                    "pid": "11910831",
                    "hname": "Sheraton Sharjah Beach Resort & Spa"
                },
                {
                    "pid": "40234810",
                    "hname": "Andaz by Hyatt – Palm Jumeirah Residences"
                },
                {
                    "pid": "16210008",
                    "hname": "Hyatt Place Dubai Al Rigga Residences"
                },
                {
                    "pid": "72122482",
                    "hname": "LUX The Cayan Superior Suite 2"
                },
                {
                    "pid": "1795484",
                    "hname": "Crowne Plaza Festival City"
                },
                {
                    "pid": "11075436",
                    "hname": "Palma Beach Resort and Spa"
                },
                {
                    "pid": "525237",
                    "hname": "Pullman Dubai Creek City Centre"
                },
                {
                    "pid": "869326",
                    "hname": "Marriott Executive Apartments Dubai Creek"
                },
                {
                    "pid": "18113069",
                    "hname": "DoubleTree by Hilton Dubai - Business Bay"
                },
                {
                    "pid": "13333164",
                    "hname": "Hilton Dubai Al Habtoor City"
                },
                {
                    "pid": "3347891",
                    "hname": "The Meydan Hotel"
                },
                {
                    "pid": "39181814",
                    "hname": "bnbme| St-Claren-502"
                },
                {
                    "pid": "8680611",
                    "hname": "Intercontinental Dubai Marina"
                },
                {
                    "pid": "38990076",
                    "hname": "Andaz by Hyatt – Palm Jumeirah"
                },
                {
                    "pid": "1590517",
                    "hname": "MELIA DESERT PALM RESORT & HOTEL"
                },
                {
                    "pid": "18552366",
                    "hname": "Holiday Inn Dubai Festival City"
                },
                {
                    "pid": "73586368",
                    "hname": "25hours Hotel One Central"
                },
                {
                    "pid": "22855372",
                    "hname": "Wyndham Garden Ajman Corniche"
                },
                {
                    "pid": "2586645",
                    "hname": "Suha Hotel Apartments JBR"
                },
                {
                    "pid": "74743075",
                    "hname": "Paramount Hotel Midtown"
                },
                {
                    "pid": "16263390",
                    "hname": "Lapita, Dubai Parks and Resorts, Autograph Collection"
                },
                {
                    "pid": "48828770",
                    "hname": "Dusit Princess Residences - Dubai Marina"
                },
                {
                    "pid": "65705944",
                    "hname": "Adagio Premium The Palm"
                },
                {
                    "pid": "66960192",
                    "hname": "Umm Al Quwain Beach Hotel"
                },
                {
                    "pid": "9695519",
                    "hname": "DAMAC Maison Cour Jardin"
                },
                {
                    "pid": "30468789",
                    "hname": "Mövenpick Hotel Apartments Downtown Dubai"
                },
                {
                    "pid": "18647545",
                    "hname": "DAMAC Maison Distinction"
                },
                {
                    "pid": "48995",
                    "hname": "Sheraton Jumeirah Beach Resort"
                },
                {
                    "pid": "48880688",
                    "hname": "Maison Privee Miska 1"
                },
                {
                    "pid": "74091366",
                    "hname": "Wonderful Stay in Downtown Views"
                },
                {
                    "pid": "74105725",
                    "hname": "Fabulous Stay in Downtown Views"
                },
                {
                    "pid": "10120476",
                    "hname": "Deira Suites Hotel Apartment"
                },
                {
                    "pid": "71606516",
                    "hname": "LUX  Marina Promenade Penthouse suite"
                },
                {
                    "pid": "70536433",
                    "hname": "LUX - Marina Ocean View Suite"
                },
                {
                    "pid": "860152",
                    "hname": "Fairmont Dubai"
                },
                {
                    "pid": "9423640",
                    "hname": "Taj Dubai"
                },
                {
                    "pid": "5715227",
                    "hname": "Conrad Dubai"
                },
                {
                    "pid": "1666993",
                    "hname": "Vida Downtown"
                },
                {
                    "pid": "15679765",
                    "hname": "La Ville Hotel & Suites CITY WALK, Dubai, Autograph Collection"
                },
                {
                    "pid": "8664126",
                    "hname": "DAMAC Maison Canal Views"
                },
                {
                    "pid": "36399251",
                    "hname": "JA Lake View Hotel"
                },
                {
                    "pid": "8745057",
                    "hname": "Roda Amwaj Suites"
                },
                {
                    "pid": "17325218",
                    "hname": "Al Habtoor Polo Resort"
                },
                {
                    "pid": "57777067",
                    "hname": "Jumeirah Living Marina Gate"
                },
                {
                    "pid": "9374465",
                    "hname": "Pullman Dubai Creek City Centre Residences"
                },
                {
                    "pid": "57930073",
                    "hname": "Vida Beach Resort Umm Al Quwain"
                },
                {
                    "pid": "559080",
                    "hname": "Hilton Dubai Jumeirah"
                },
                {
                    "pid": "2882514",
                    "hname": "InterContinental Residence Suites Dubai Festival City"
                },
                {
                    "pid": "1844998",
                    "hname": "Saffron Boutique Hotel"
                },
                {
                    "pid": "38742260",
                    "hname": "Hyatt Place Dubai Jumeirah"
                },
                {
                    "pid": "20321",
                    "hname": "Grand Hyatt Dubai"
                },
                {
                    "pid": "38170272",
                    "hname": "Paramount Hotel Dubai"
                },
                {
                    "pid": "21591581",
                    "hname": "Maison Privee - Tiara Ruby"
                },
                {
                    "pid": "22627",
                    "hname": "JA Beach Hotel"
                },
                {
                    "pid": "16046759",
                    "hname": "Dukes The Palm, a Royal Hideaway Hotel"
                },
                {
                    "pid": "39181816",
                    "hname": "Bnbme - Churchill Towers 1 Bedroom Canal View"
                },
                {
                    "pid": "7347819",
                    "hname": "Ajman Saray, a Luxury Collection Resort, Ajman"
                },
                {
                    "pid": "21564979",
                    "hname": "Aloft Palm Jumeirah"
                },
                {
                    "pid": "27745798",
                    "hname": "Westminster Dubai Mall"
                },
                {
                    "pid": "918930",
                    "hname": "Shangri La Hotel Dubai"
                },
                {
                    "pid": "4623412",
                    "hname": "Swissotel Living Al Ghurair"
                },
                {
                    "pid": "39154422",
                    "hname": "Hyatt Place Dubai Jumeirah Residences"
                },
                {
                    "pid": "2557312",
                    "hname": "Sofitel Dubai Jumeirah Beach"
                },
                {
                    "pid": "1738573",
                    "hname": "InterContinental Dubai Festival City"
                },
                {
                    "pid": "26743083",
                    "hname": "Royal Central Hotel The Palm"
                },
                {
                    "pid": "9860088",
                    "hname": "Pearl Marina Hotel Apartments"
                },
                {
                    "pid": "3002123",
                    "hname": "Ramada by Wyndham Downtown Dubai"
                },
                {
                    "pid": "42571677",
                    "hname": "Vida Emirates Hills Residences"
                },
                {
                    "pid": "1696177",
                    "hname": "Raffles Dubai"
                },
                {
                    "pid": "13443879",
                    "hname": "Hyatt Regency Galleria Residence Dubai"
                },
                {
                    "pid": "75626271",
                    "hname": "LUX Opulent Island Suite View 7"
                },
                {
                    "pid": "74191605",
                    "hname": "The address JBR Luxury 1 bedroom"
                },
                {
                    "pid": "72301419",
                    "hname": "Luxury at The Address Jumeirah Beach Residence"
                },
                {
                    "pid": "1210385",
                    "hname": "Golden Sands Hotel Apartments"
                },
                {
                    "pid": "69404538",
                    "hname": "Hilton Dubai Palm Jumeirah"
                },
                {
                    "pid": "5944430",
                    "hname": "Bahi Ajman Palace Hotel"
                },
                {
                    "pid": "5527177",
                    "hname": "Fairmont The Palm"
                },
                {
                    "pid": "43989118",
                    "hname": "Sofitel Dubai The Obelisk"
                },
                {
                    "pid": "74549512",
                    "hname": "Taj Exotica Resort & Spa, The Palm, Dubai"
                },
                {
                    "pid": "49145491",
                    "hname": "Riu Dubai - All Inclusive"
                },
                {
                    "pid": "7672819",
                    "hname": "Hyatt Place Dubai Al Rigga"
                },
                {
                    "pid": "34039882",
                    "hname": "W Dubai - The Palm"
                },
                {
                    "pid": "486304",
                    "hname": "Jumeirah Emirates Towers"
                },
                {
                    "pid": "12346991",
                    "hname": "Habtoor Palace Dubai, LXR Hotels & Resorts"
                },
                {
                    "pid": "12034252",
                    "hname": "Shangri-La Residences and Apartments"
                },
                {
                    "pid": "35581351",
                    "hname": "Roda Al Murooj Residences"
                },
                {
                    "pid": "1723703",
                    "hname": "Nuran Marina Serviced Residences"
                },
                {
                    "pid": "6926162",
                    "hname": "DAMAC Maison Mall Street"
                },
                {
                    "pid": "1305504",
                    "hname": "Grosvenor House, a Luxury Collection Hotel, Dubai"
                },
                {
                    "pid": "2211788",
                    "hname": "Jumeirah Living - World Trade Centre Residence"
                },
                {
                    "pid": "850069",
                    "hname": "Hilton Dubai Creek"
                },
                {
                    "pid": "4263530",
                    "hname": "Marina Hotel Apartments"
                },
                {
                    "pid": "486249",
                    "hname": "JA Oasis Beach Tower"
                },
                {
                    "pid": "61528330",
                    "hname": "bnbme 1B-The 8-10201"
                },
                {
                    "pid": "6899004",
                    "hname": "Sofitel Dubai Downtown"
                },
                {
                    "pid": "38499620",
                    "hname": "Vida Downtown Residences"
                },
                {
                    "pid": "50587861",
                    "hname": "Marbella 2 Bedrooms"
                },
                {
                    "pid": "29217023",
                    "hname": "HiGuests Vacation Homes - Mon Reve"
                },
                {
                    "pid": "47377",
                    "hname": "Habtoor Grand Resort, Autograph Collection"
                },
                {
                    "pid": "41948974",
                    "hname": "Maison Privee - Burj Views"
                },
                {
                    "pid": "75876230",
                    "hname": "1B-Address JBR - 1107 by bnbme homes"
                },
                {
                    "pid": "42691507",
                    "hname": "ME Dubai by Meliá"
                },
                {
                    "pid": "50588683",
                    "hname": "Marbella Executive 2 Bedrooms"
                },
                {
                    "pid": "75879276",
                    "hname": "1B - Anantara - 316 by bnbme homes"
                },
                {
                    "pid": "5914122",
                    "hname": "Sofitel Dubai The Palm Resort & Spa"
                },
                {
                    "pid": "50587862",
                    "hname": "Marbella Executive 2 Bedrooms Balcony"
                },
                {
                    "pid": "9832383",
                    "hname": "Palazzo Versace Dubai"
                },
                {
                    "pid": "5617257",
                    "hname": "JA Ocean View Hotel"
                },
                {
                    "pid": "3454761",
                    "hname": "Sheraton Mall of the Emirates Hotel, Dubai"
                },
                {
                    "pid": "50588841",
                    "hname": "Marbella Luxury 2 Bedrooms"
                },
                {
                    "pid": "65376308",
                    "hname": "The St. Regis Dubai, The Palm"
                },
                {
                    "pid": "63852448",
                    "hname": "SLS Dubai Hotel & Residences"
                },
                {
                    "pid": "3957319",
                    "hname": "Jumeirah Zabeel Saray"
                },
                {
                    "pid": "9642646",
                    "hname": "Fairmont Ajman"
                },
                {
                    "pid": "50587854",
                    "hname": "Marbella Luxury 2 Bedrooms Balcony"
                },
                {
                    "pid": "973713",
                    "hname": "Jumeirah Mina A Salam"
                },
                {
                    "pid": "432678",
                    "hname": "Le Meridien Mina Seyahi Beach Resort & Marina"
                },
                {
                    "pid": "1109595",
                    "hname": "JA Palm Tree Court"
                },
                {
                    "pid": "33086673",
                    "hname": "Waldorf Astoria Dubai International Financial Centre"
                },
                {
                    "pid": "7332018",
                    "hname": "Waldorf Astoria Dubai Palm Jumeirah"
                },
                {
                    "pid": "23711862",
                    "hname": "The Chedi Al Bait, Sharjah, UAE"
                },
                {
                    "pid": "75260049",
                    "hname": "Maison Privee - 5242 Emaar"
                },
                {
                    "pid": "7316461",
                    "hname": "Sofitel Dubai The Palm Luxury Apartments"
                },
                {
                    "pid": "1596020",
                    "hname": "Dubai Marriott Harbour Hotel & Suites"
                },
                {
                    "pid": "42074031",
                    "hname": "Simply Comfort Luxury Sarai Apartments"
                },
                {
                    "pid": "6750573",
                    "hname": "DoubleTree by Hilton Dubai - Jumeirah Beach"
                },
                {
                    "pid": "3089613",
                    "hname": "The Ritz-Carlton, Dubai International Financial Centre"
                },
                {
                    "pid": "19366493",
                    "hname": "Renaissance Downtown Hotel, Dubai"
                },
                {
                    "pid": "48241914",
                    "hname": "LEGOLAND Hotel Dubai"
                },
                {
                    "pid": "71916244",
                    "hname": "LUX The Modern Marina Suite 2 bed"
                },
                {
                    "pid": "1162761",
                    "hname": "Bab Al Shams Desert Resort and Spa"
                },
                {
                    "pid": "1273525",
                    "hname": "Park Hyatt Dubai"
                },
                {
                    "pid": "1938669",
                    "hname": "The Westin Dubai Mina Seyahi Beach Resort & Marina"
                },
                {
                    "pid": "1608859",
                    "hname": "Manzil Downtown"
                },
                {
                    "pid": "190827",
                    "hname": "Le Royal Meridien Beach Resort And Spa"
                },
                {
                    "pid": "70128096",
                    "hname": "Raffles The Palm"
                },
                {
                    "pid": "2983165",
                    "hname": "Address Dubai Marina"
                },
                {
                    "pid": "72626661",
                    "hname": "The Dubai EDITION"
                },
                {
                    "pid": "6483363",
                    "hname": "Anantara The Palm Dubai Resort"
                },
                {
                    "pid": "17905889",
                    "hname": "The Oberoi Beach Resort, Al Zorah"
                },
                {
                    "pid": "67313618",
                    "hname": "Gorgeous 2 bed at The Address Dubai Mall"
                },
                {
                    "pid": "18352604",
                    "hname": "Rixos Premium Dubai JBR"
                },
                {
                    "pid": "31520413",
                    "hname": "Caesars Palace Bluewaters Dubai"
                },
                {
                    "pid": "1725259",
                    "hname": "Palace Downtown"
                },
                {
                    "pid": "33010066",
                    "hname": "Address Sky View"
                },
                {
                    "pid": "57821246",
                    "hname": "Address Beach Resort"
                },
                {
                    "pid": "70041341",
                    "hname": "Centara Mirage Beach Resort Dubai"
                },
                {
                    "pid": "1378847",
                    "hname": "Kempinski Mall Of The Emirates"
                },
                {
                    "pid": "16216917",
                    "hname": "FIVE Palm Jumeirah Dubai"
                },
                {
                    "pid": "4944843",
                    "hname": "The Ritz-Carlton Executive Residences"
                },
                {
                    "pid": "127464",
                    "hname": "The Ritz-Carlton, Dubai"
                },
                {
                    "pid": "15378315",
                    "hname": "Four Seasons Hotel Dubai International Financial Centre"
                },
                {
                    "pid": "1198396",
                    "hname": "Jumeirah Dar Al Masyaf"
                },
                {
                    "pid": "16387503",
                    "hname": "Grand Hyatt Residence"
                },
                {
                    "pid": "527458",
                    "hname": "One&Only Royal Mirage"
                },
                {
                    "pid": "527498",
                    "hname": "Jumeirah Beach Hotel"
                },
                {
                    "pid": "1198022",
                    "hname": "Jumeirah Al Qasr"
                },
                {
                    "pid": "36087014",
                    "hname": "J5 Four Bedroom Villa in Mirdif"
                },
                {
                    "pid": "18927536",
                    "hname": "Dream Inn Dubai Apartments - Burj Views"
                },
                {
                    "pid": "23596374",
                    "hname": "Rose Garden Hotel Apartments - Bur Dubai"
                },
                {
                    "pid": "16039653",
                    "hname": "Nikki Beach Resort & Spa Dubai"
                },
                {
                    "pid": "2224974",
                    "hname": "Address Downtown"
                },
                {
                    "pid": "33010540",
                    "hname": "Address Fountain Views"
                },
                {
                    "pid": "42292205",
                    "hname": "Westminster Burj Residence"
                },
                {
                    "pid": "16060666",
                    "hname": "Jumeirah Al Naseem"
                },
                {
                    "pid": "71111562",
                    "hname": "bnbme 4B-Murjan-3501"
                },
                {
                    "pid": "15536928",
                    "hname": "Address Boulevard"
                },
                {
                    "pid": "2143430",
                    "hname": "Arabian Dreams Deluxe Hotel Apartments"
                },
                {
                    "pid": "3565885",
                    "hname": "Kempinski Hotel & Residences Palm Jumeirah"
                },
                {
                    "pid": "18926712",
                    "hname": "Dream Inn Dubai - City Walk"
                },
                {
                    "pid": "8697404",
                    "hname": "Four Seasons Resort Dubai at Jumeirah Beach"
                },
                {
                    "pid": "2235336",
                    "hname": "Atlantis The Palm"
                },
                {
                    "pid": "62998293",
                    "hname": "Private Pool Villas by Simply Comfort"
                },
                {
                    "pid": "30313798",
                    "hname": "J5 Villas Holiday Homes - Barsha Gardens"
                },
                {
                    "pid": "3033052",
                    "hname": "Armani Hotel Dubai"
                },
                {
                    "pid": "29842432",
                    "hname": "Mandarin Oriental Jumeira, Dubai"
                },
                {
                    "pid": "71587991",
                    "hname": "Anantara World Islands Dubai Resort"
                },
                {
                    "pid": "20643202",
                    "hname": "Bulgari Hotel & Resorts, Dubai"
                },
                {
                    "pid": "65561875",
                    "hname": "LUX Holiday Home - Frond E 1"
                },
                {
                    "pid": "5814743",
                    "hname": "Ramada by Wyndham Beach Hotel Ajman"
                },
                {
                    "pid": "57333708",
                    "hname": "Amazing Stay at Dubai Downtown - Souk Al Bahar"
                },
                {
                    "pid": "71606802",
                    "hname": "LUX White Modern Villa"
                },
                {
                    "pid": "40092178",
                    "hname": "Dubai Creek Club Villas"
                },
                {
                    "pid": "527497",
                    "hname": "Burj Al Arab Jumeirah"
                },
                {
                    "pid": "48914671",
                    "hname": "Maison Privee Frond Villa O"
                }
            ],
            "price": {
                "minprc": 1212,
                "maxprc": 150639
            },
            "strrat": [
                {
                    "rat": 5,
                    "ischeck": false
                },
                {
                    "rat": 4,
                    "ischeck": false
                },
                {
                    "rat": 3,
                    "ischeck": false
                },
                {
                    "rat": 2,
                    "ischeck": false
                },
                {
                    "rat": 1,
                    "ischeck": false
                },
                {
                    "rat": 0,
                    "ischeck": false
                }
            ],
            "accom": [
                {
                    "type": "Hostel/Backpacker accommodation",
                    "count": 1,
                    "isaccom": false,
                    "sno": [
                        "0"
                    ]
                },
                {
                    "type": "Hotel",
                    "count": 435,
                    "isaccom": false,
                    "sno": [
                        "1",
                        "2",
                        "3",
                        "4",
                        "5",
                        "6",
                        "7",
                        "8",
                        "9",
                        "10",
                        "11",
                        "12",
                        "14",
                        "15",
                        "16",
                        "17",
                        "18",
                        "19",
                        "20",
                        "21",
                        "22",
                        "23",
                        "24",
                        "25",
                        "26",
                        "27",
                        "28",
                        "29",
                        "30",
                        "31",
                        "33",
                        "34",
                        "35",
                        "36",
                        "37",
                        "38",
                        "39",
                        "40",
                        "41",
                        "42",
                        "43",
                        "45",
                        "46",
                        "47",
                        "48",
                        "49",
                        "50",
                        "51",
                        "52",
                        "53",
                        "54",
                        "55",
                        "56",
                        "57",
                        "58",
                        "62",
                        "63",
                        "64",
                        "65",
                        "66",
                        "67",
                        "69",
                        "70",
                        "71",
                        "72",
                        "73",
                        "74",
                        "75",
                        "76",
                        "77",
                        "78",
                        "79",
                        "80",
                        "82",
                        "83",
                        "84",
                        "85",
                        "86",
                        "87",
                        "88",
                        "90",
                        "91",
                        "92",
                        "93",
                        "94",
                        "95",
                        "96",
                        "97",
                        "99",
                        "100",
                        "101",
                        "102",
                        "103",
                        "104",
                        "105",
                        "106",
                        "107",
                        "108",
                        "109",
                        "110",
                        "111",
                        "112",
                        "114",
                        "115",
                        "116",
                        "117",
                        "118",
                        "120",
                        "122",
                        "123",
                        "124",
                        "125",
                        "126",
                        "127",
                        "129",
                        "130",
                        "131",
                        "132",
                        "133",
                        "134",
                        "135",
                        "136",
                        "137",
                        "138",
                        "139",
                        "140",
                        "141",
                        "142",
                        "144",
                        "146",
                        "147",
                        "148",
                        "150",
                        "151",
                        "152",
                        "153",
                        "154",
                        "156",
                        "157",
                        "158",
                        "159",
                        "160",
                        "161",
                        "162",
                        "163",
                        "164",
                        "165",
                        "166",
                        "167",
                        "168",
                        "169",
                        "170",
                        "171",
                        "172",
                        "173",
                        "174",
                        "175",
                        "177",
                        "178",
                        "180",
                        "181",
                        "182",
                        "183",
                        "184",
                        "185",
                        "186",
                        "187",
                        "188",
                        "190",
                        "191",
                        "192",
                        "193",
                        "194",
                        "195",
                        "197",
                        "198",
                        "199",
                        "200",
                        "202",
                        "203",
                        "204",
                        "206",
                        "207",
                        "209",
                        "210",
                        "212",
                        "213",
                        "214",
                        "215",
                        "216",
                        "217",
                        "218",
                        "219",
                        "222",
                        "223",
                        "224",
                        "225",
                        "226",
                        "227",
                        "229",
                        "230",
                        "231",
                        "232",
                        "233",
                        "234",
                        "235",
                        "236",
                        "237",
                        "238",
                        "239",
                        "240",
                        "241",
                        "243",
                        "244",
                        "249",
                        "250",
                        "251",
                        "254",
                        "257",
                        "262",
                        "263",
                        "264",
                        "265",
                        "266",
                        "267",
                        "268",
                        "269",
                        "270",
                        "271",
                        "273",
                        "274",
                        "276",
                        "277",
                        "278",
                        "279",
                        "280",
                        "281",
                        "282",
                        "283",
                        "284",
                        "285",
                        "286",
                        "287",
                        "288",
                        "289",
                        "290",
                        "291",
                        "295",
                        "296",
                        "297",
                        "298",
                        "299",
                        "300",
                        "306",
                        "307",
                        "308",
                        "310",
                        "311",
                        "312",
                        "313",
                        "317",
                        "318",
                        "320",
                        "321",
                        "323",
                        "325",
                        "327",
                        "328",
                        "330",
                        "332",
                        "333",
                        "336",
                        "337",
                        "338",
                        "339",
                        "340",
                        "341",
                        "342",
                        "344",
                        "347",
                        "348",
                        "349",
                        "350",
                        "351",
                        "352",
                        "355",
                        "358",
                        "359",
                        "360",
                        "362",
                        "364",
                        "365",
                        "366",
                        "367",
                        "368",
                        "371",
                        "374",
                        "375",
                        "376",
                        "377",
                        "378",
                        "379",
                        "380",
                        "381",
                        "382",
                        "383",
                        "384",
                        "387",
                        "388",
                        "390",
                        "392",
                        "393",
                        "394",
                        "395",
                        "397",
                        "398",
                        "401",
                        "403",
                        "405",
                        "406",
                        "407",
                        "409",
                        "411",
                        "412",
                        "414",
                        "416",
                        "417",
                        "419",
                        "422",
                        "424",
                        "425",
                        "428",
                        "429",
                        "431",
                        "433",
                        "435",
                        "437",
                        "438",
                        "439",
                        "441",
                        "442",
                        "443",
                        "444",
                        "445",
                        "446",
                        "448",
                        "452",
                        "453",
                        "454",
                        "456",
                        "463",
                        "464",
                        "465",
                        "466",
                        "467",
                        "469",
                        "471",
                        "472",
                        "473",
                        "474",
                        "475",
                        "476",
                        "477",
                        "478",
                        "479",
                        "480",
                        "482",
                        "483",
                        "486",
                        "488",
                        "490",
                        "491",
                        "492",
                        "493",
                        "494",
                        "495",
                        "497",
                        "503",
                        "504",
                        "505",
                        "506",
                        "509",
                        "510",
                        "511",
                        "512",
                        "513",
                        "517",
                        "519",
                        "523",
                        "527",
                        "530",
                        "535",
                        "536",
                        "537",
                        "539",
                        "540",
                        "541",
                        "542",
                        "544",
                        "547",
                        "548",
                        "552",
                        "554",
                        "555",
                        "556",
                        "557",
                        "559",
                        "560",
                        "562",
                        "563",
                        "564",
                        "565",
                        "566",
                        "567",
                        "568",
                        "570",
                        "572",
                        "573",
                        "575",
                        "576",
                        "578",
                        "579",
                        "580",
                        "583",
                        "584",
                        "585",
                        "590",
                        "591",
                        "595",
                        "599",
                        "600",
                        "603",
                        "605",
                        "608",
                        "612"
                    ]
                },
                {
                    "type": "Property",
                    "count": 2,
                    "isaccom": false,
                    "sno": [
                        "13",
                        "507"
                    ]
                },
                {
                    "type": "Aparthotel",
                    "count": 92,
                    "isaccom": false,
                    "sno": [
                        "32",
                        "44",
                        "59",
                        "60",
                        "68",
                        "89",
                        "98",
                        "119",
                        "121",
                        "143",
                        "145",
                        "149",
                        "155",
                        "176",
                        "179",
                        "189",
                        "196",
                        "201",
                        "205",
                        "208",
                        "211",
                        "220",
                        "221",
                        "228",
                        "242",
                        "245",
                        "246",
                        "247",
                        "248",
                        "252",
                        "253",
                        "258",
                        "259",
                        "260",
                        "261",
                        "272",
                        "275",
                        "292",
                        "301",
                        "302",
                        "303",
                        "304",
                        "305",
                        "309",
                        "319",
                        "322",
                        "324",
                        "326",
                        "329",
                        "334",
                        "345",
                        "346",
                        "353",
                        "354",
                        "356",
                        "361",
                        "363",
                        "369",
                        "370",
                        "372",
                        "373",
                        "391",
                        "396",
                        "399",
                        "400",
                        "404",
                        "408",
                        "415",
                        "418",
                        "420",
                        "421",
                        "430",
                        "436",
                        "447",
                        "451",
                        "455",
                        "460",
                        "468",
                        "470",
                        "496",
                        "498",
                        "502",
                        "514",
                        "515",
                        "516",
                        "518",
                        "521",
                        "524",
                        "553",
                        "582",
                        "588",
                        "597"
                    ]
                },
                {
                    "type": "Apartment",
                    "count": 53,
                    "isaccom": false,
                    "sno": [
                        "61",
                        "113",
                        "128",
                        "256",
                        "294",
                        "314",
                        "315",
                        "331",
                        "335",
                        "343",
                        "357",
                        "385",
                        "389",
                        "402",
                        "410",
                        "413",
                        "423",
                        "426",
                        "427",
                        "432",
                        "440",
                        "450",
                        "457",
                        "458",
                        "459",
                        "461",
                        "462",
                        "484",
                        "487",
                        "489",
                        "499",
                        "500",
                        "501",
                        "520",
                        "522",
                        "525",
                        "526",
                        "528",
                        "529",
                        "531",
                        "532",
                        "534",
                        "538",
                        "543",
                        "550",
                        "551",
                        "558",
                        "569",
                        "587",
                        "594",
                        "596",
                        "598",
                        "609"
                    ]
                },
                {
                    "type": "Capsule Hotel",
                    "count": 2,
                    "isaccom": false,
                    "sno": [
                        "81",
                        "293"
                    ]
                },
                {
                    "type": "Condo",
                    "count": 1,
                    "isaccom": false,
                    "sno": [
                        "255"
                    ]
                },
                {
                    "type": "Resort",
                    "count": 18,
                    "isaccom": false,
                    "sno": [
                        "316",
                        "386",
                        "434",
                        "449",
                        "485",
                        "533",
                        "545",
                        "546",
                        "549",
                        "561",
                        "571",
                        "574",
                        "577",
                        "581",
                        "589",
                        "593",
                        "604",
                        "606"
                    ]
                },
                {
                    "type": "Private vacation home",
                    "count": 1,
                    "isaccom": false,
                    "sno": [
                        "481"
                    ]
                },
                {
                    "type": "All-inclusive property",
                    "count": 1,
                    "isaccom": false,
                    "sno": [
                        "508"
                    ]
                },
                {
                    "type": "Villa",
                    "count": 7,
                    "isaccom": false,
                    "sno": [
                        "586",
                        "601",
                        "602",
                        "607",
                        "610",
                        "611",
                        "613"
                    ]
                },
                {
                    "type": "Residence",
                    "count": 1,
                    "isaccom": false,
                    "sno": [
                        "592"
                    ]
                }
            ],
            "exprat": {
                "minprc": 0,
                "maxprc": 5
            },
            "amenities": [
                {
                    "name": "Free WiFi",
                    "count": 546,
                    "ischeck": false,
                    "sno": [
                        "0",
                        "1",
                        "2",
                        "3",
                        "5",
                        "6",
                        "7",
                        "8",
                        "9",
                        "10",
                        "11",
                        "13",
                        "14",
                        "15",
                        "16",
                        "17",
                        "18",
                        "19",
                        "20",
                        "21",
                        "22",
                        "23",
                        "24",
                        "25",
                        "27",
                        "29",
                        "30",
                        "31",
                        "33",
                        "34",
                        "35",
                        "36",
                        "37",
                        "38",
                        "39",
                        "40",
                        "41",
                        "42",
                        "43",
                        "44",
                        "45",
                        "46",
                        "47",
                        "48",
                        "49",
                        "50",
                        "51",
                        "52",
                        "53",
                        "54",
                        "55",
                        "56",
                        "57",
                        "58",
                        "59",
                        "60",
                        "61",
                        "62",
                        "63",
                        "64",
                        "65",
                        "66",
                        "67",
                        "69",
                        "70",
                        "71",
                        "72",
                        "73",
                        "74",
                        "75",
                        "76",
                        "77",
                        "78",
                        "79",
                        "81",
                        "82",
                        "83",
                        "84",
                        "85",
                        "86",
                        "87",
                        "88",
                        "89",
                        "90",
                        "91",
                        "92",
                        "93",
                        "94",
                        "95",
                        "96",
                        "97",
                        "98",
                        "99",
                        "100",
                        "101",
                        "102",
                        "103",
                        "104",
                        "105",
                        "106",
                        "107",
                        "108",
                        "109",
                        "110",
                        "111",
                        "112",
                        "113",
                        "114",
                        "115",
                        "116",
                        "117",
                        "118",
                        "119",
                        "120",
                        "121",
                        "122",
                        "123",
                        "125",
                        "126",
                        "127",
                        "129",
                        "130",
                        "131",
                        "132",
                        "133",
                        "134",
                        "135",
                        "136",
                        "137",
                        "138",
                        "139",
                        "140",
                        "141",
                        "142",
                        "144",
                        "145",
                        "146",
                        "147",
                        "148",
                        "149",
                        "150",
                        "151",
                        "152",
                        "153",
                        "154",
                        "155",
                        "156",
                        "157",
                        "158",
                        "159",
                        "160",
                        "161",
                        "162",
                        "163",
                        "165",
                        "166",
                        "167",
                        "168",
                        "169",
                        "170",
                        "171",
                        "172",
                        "173",
                        "174",
                        "175",
                        "176",
                        "177",
                        "178",
                        "180",
                        "181",
                        "182",
                        "183",
                        "184",
                        "185",
                        "186",
                        "187",
                        "188",
                        "189",
                        "190",
                        "191",
                        "192",
                        "193",
                        "194",
                        "195",
                        "196",
                        "197",
                        "198",
                        "199",
                        "200",
                        "201",
                        "202",
                        "203",
                        "204",
                        "205",
                        "206",
                        "207",
                        "208",
                        "209",
                        "210",
                        "212",
                        "213",
                        "214",
                        "215",
                        "216",
                        "217",
                        "218",
                        "219",
                        "220",
                        "221",
                        "222",
                        "224",
                        "225",
                        "226",
                        "227",
                        "228",
                        "229",
                        "230",
                        "232",
                        "233",
                        "234",
                        "235",
                        "236",
                        "237",
                        "238",
                        "239",
                        "240",
                        "241",
                        "242",
                        "243",
                        "244",
                        "245",
                        "246",
                        "247",
                        "249",
                        "250",
                        "251",
                        "252",
                        "253",
                        "254",
                        "255",
                        "257",
                        "258",
                        "260",
                        "261",
                        "262",
                        "263",
                        "264",
                        "265",
                        "267",
                        "268",
                        "269",
                        "270",
                        "271",
                        "272",
                        "273",
                        "274",
                        "275",
                        "276",
                        "277",
                        "278",
                        "279",
                        "280",
                        "281",
                        "282",
                        "283",
                        "284",
                        "285",
                        "286",
                        "287",
                        "288",
                        "289",
                        "290",
                        "291",
                        "292",
                        "293",
                        "295",
                        "296",
                        "297",
                        "298",
                        "300",
                        "301",
                        "302",
                        "303",
                        "304",
                        "305",
                        "306",
                        "307",
                        "308",
                        "309",
                        "310",
                        "312",
                        "313",
                        "316",
                        "317",
                        "318",
                        "319",
                        "320",
                        "321",
                        "322",
                        "323",
                        "324",
                        "325",
                        "326",
                        "327",
                        "328",
                        "329",
                        "330",
                        "331",
                        "332",
                        "333",
                        "334",
                        "336",
                        "337",
                        "338",
                        "339",
                        "340",
                        "341",
                        "342",
                        "343",
                        "344",
                        "345",
                        "346",
                        "347",
                        "349",
                        "350",
                        "351",
                        "352",
                        "353",
                        "354",
                        "355",
                        "356",
                        "357",
                        "358",
                        "359",
                        "360",
                        "361",
                        "362",
                        "363",
                        "364",
                        "365",
                        "366",
                        "367",
                        "368",
                        "369",
                        "371",
                        "372",
                        "373",
                        "374",
                        "375",
                        "376",
                        "377",
                        "378",
                        "379",
                        "380",
                        "381",
                        "382",
                        "383",
                        "384",
                        "386",
                        "387",
                        "388",
                        "390",
                        "391",
                        "392",
                        "393",
                        "394",
                        "395",
                        "396",
                        "398",
                        "399",
                        "400",
                        "401",
                        "403",
                        "405",
                        "406",
                        "407",
                        "408",
                        "409",
                        "411",
                        "412",
                        "414",
                        "415",
                        "416",
                        "417",
                        "418",
                        "419",
                        "420",
                        "421",
                        "422",
                        "424",
                        "425",
                        "428",
                        "429",
                        "430",
                        "431",
                        "433",
                        "434",
                        "435",
                        "436",
                        "437",
                        "438",
                        "439",
                        "441",
                        "442",
                        "443",
                        "444",
                        "445",
                        "446",
                        "447",
                        "448",
                        "449",
                        "450",
                        "451",
                        "452",
                        "453",
                        "454",
                        "455",
                        "456",
                        "460",
                        "462",
                        "463",
                        "464",
                        "465",
                        "466",
                        "467",
                        "468",
                        "469",
                        "470",
                        "471",
                        "472",
                        "473",
                        "474",
                        "475",
                        "477",
                        "478",
                        "479",
                        "480",
                        "482",
                        "483",
                        "485",
                        "486",
                        "487",
                        "488",
                        "489",
                        "490",
                        "491",
                        "492",
                        "493",
                        "494",
                        "495",
                        "496",
                        "497",
                        "500",
                        "502",
                        "504",
                        "505",
                        "506",
                        "507",
                        "508",
                        "509",
                        "511",
                        "512",
                        "513",
                        "514",
                        "515",
                        "516",
                        "517",
                        "518",
                        "519",
                        "520",
                        "521",
                        "523",
                        "524",
                        "525",
                        "526",
                        "527",
                        "530",
                        "531",
                        "533",
                        "534",
                        "535",
                        "536",
                        "537",
                        "538",
                        "539",
                        "540",
                        "541",
                        "542",
                        "543",
                        "544",
                        "545",
                        "546",
                        "547",
                        "548",
                        "549",
                        "551",
                        "554",
                        "555",
                        "556",
                        "559",
                        "560",
                        "561",
                        "562",
                        "563",
                        "564",
                        "565",
                        "566",
                        "567",
                        "568",
                        "569",
                        "570",
                        "571",
                        "572",
                        "573",
                        "574",
                        "575",
                        "576",
                        "577",
                        "578",
                        "579",
                        "580",
                        "581",
                        "582",
                        "583",
                        "584",
                        "585",
                        "588",
                        "589",
                        "590",
                        "591",
                        "592",
                        "593",
                        "595",
                        "596",
                        "597",
                        "598",
                        "599",
                        "600",
                        "601",
                        "603",
                        "604",
                        "605",
                        "606",
                        "608",
                        "609",
                        "612"
                    ]
                },
                {
                    "name": "Bar/lounge",
                    "count": 159,
                    "ischeck": false,
                    "sno": [
                        "4",
                        "5",
                        "8",
                        "10",
                        "19",
                        "20",
                        "21",
                        "23",
                        "27",
                        "31",
                        "34",
                        "36",
                        "37",
                        "43",
                        "46",
                        "48",
                        "52",
                        "58",
                        "65",
                        "69",
                        "76",
                        "78",
                        "82",
                        "83",
                        "87",
                        "91",
                        "92",
                        "97",
                        "100",
                        "102",
                        "105",
                        "111",
                        "115",
                        "116",
                        "117",
                        "122",
                        "125",
                        "126",
                        "127",
                        "136",
                        "141",
                        "142",
                        "144",
                        "146",
                        "151",
                        "157",
                        "162",
                        "163",
                        "170",
                        "171",
                        "172",
                        "173",
                        "174",
                        "175",
                        "181",
                        "184",
                        "190",
                        "191",
                        "194",
                        "212",
                        "213",
                        "214",
                        "217",
                        "226",
                        "233",
                        "238",
                        "240",
                        "244",
                        "250",
                        "251",
                        "265",
                        "266",
                        "268",
                        "271",
                        "277",
                        "283",
                        "307",
                        "311",
                        "316",
                        "321",
                        "322",
                        "323",
                        "329",
                        "332",
                        "339",
                        "344",
                        "349",
                        "352",
                        "358",
                        "359",
                        "360",
                        "364",
                        "366",
                        "367",
                        "369",
                        "371",
                        "373",
                        "374",
                        "378",
                        "379",
                        "384",
                        "386",
                        "399",
                        "400",
                        "405",
                        "407",
                        "411",
                        "414",
                        "415",
                        "416",
                        "417",
                        "424",
                        "425",
                        "431",
                        "433",
                        "434",
                        "437",
                        "442",
                        "465",
                        "469",
                        "472",
                        "473",
                        "478",
                        "490",
                        "491",
                        "493",
                        "496",
                        "504",
                        "505",
                        "509",
                        "521",
                        "530",
                        "541",
                        "544",
                        "552",
                        "554",
                        "555",
                        "557",
                        "559",
                        "562",
                        "563",
                        "565",
                        "567",
                        "568",
                        "570",
                        "571",
                        "572",
                        "574",
                        "576",
                        "578",
                        "579",
                        "582",
                        "585",
                        "590",
                        "593",
                        "595",
                        "597",
                        "603",
                        "604"
                    ]
                },
                {
                    "name": "Fitness facilities",
                    "count": 309,
                    "ischeck": false,
                    "sno": [
                        "4",
                        "5",
                        "20",
                        "33",
                        "34",
                        "36",
                        "37",
                        "38",
                        "39",
                        "40",
                        "44",
                        "45",
                        "48",
                        "50",
                        "52",
                        "57",
                        "59",
                        "60",
                        "62",
                        "64",
                        "65",
                        "66",
                        "68",
                        "70",
                        "72",
                        "73",
                        "74",
                        "75",
                        "85",
                        "87",
                        "88",
                        "93",
                        "96",
                        "99",
                        "103",
                        "106",
                        "107",
                        "109",
                        "110",
                        "114",
                        "118",
                        "119",
                        "120",
                        "125",
                        "127",
                        "129",
                        "132",
                        "133",
                        "135",
                        "137",
                        "140",
                        "141",
                        "142",
                        "143",
                        "144",
                        "145",
                        "146",
                        "147",
                        "148",
                        "152",
                        "153",
                        "155",
                        "156",
                        "157",
                        "158",
                        "159",
                        "160",
                        "163",
                        "164",
                        "166",
                        "167",
                        "168",
                        "169",
                        "171",
                        "172",
                        "175",
                        "176",
                        "177",
                        "181",
                        "182",
                        "183",
                        "184",
                        "187",
                        "189",
                        "190",
                        "191",
                        "192",
                        "193",
                        "194",
                        "196",
                        "201",
                        "206",
                        "209",
                        "210",
                        "213",
                        "215",
                        "219",
                        "220",
                        "221",
                        "222",
                        "226",
                        "227",
                        "228",
                        "229",
                        "233",
                        "234",
                        "235",
                        "237",
                        "238",
                        "239",
                        "240",
                        "241",
                        "242",
                        "244",
                        "245",
                        "246",
                        "247",
                        "251",
                        "253",
                        "254",
                        "257",
                        "258",
                        "259",
                        "261",
                        "262",
                        "264",
                        "270",
                        "272",
                        "273",
                        "274",
                        "276",
                        "277",
                        "279",
                        "280",
                        "283",
                        "285",
                        "286",
                        "289",
                        "291",
                        "295",
                        "296",
                        "299",
                        "300",
                        "303",
                        "304",
                        "306",
                        "307",
                        "308",
                        "309",
                        "310",
                        "311",
                        "318",
                        "319",
                        "322",
                        "323",
                        "326",
                        "327",
                        "329",
                        "330",
                        "332",
                        "334",
                        "336",
                        "337",
                        "340",
                        "345",
                        "346",
                        "347",
                        "350",
                        "352",
                        "353",
                        "355",
                        "357",
                        "358",
                        "359",
                        "360",
                        "361",
                        "363",
                        "365",
                        "366",
                        "367",
                        "368",
                        "369",
                        "370",
                        "372",
                        "373",
                        "375",
                        "376",
                        "378",
                        "379",
                        "381",
                        "386",
                        "387",
                        "388",
                        "390",
                        "394",
                        "395",
                        "396",
                        "398",
                        "400",
                        "403",
                        "404",
                        "405",
                        "406",
                        "408",
                        "409",
                        "411",
                        "413",
                        "414",
                        "421",
                        "422",
                        "425",
                        "428",
                        "429",
                        "430",
                        "431",
                        "433",
                        "434",
                        "439",
                        "440",
                        "442",
                        "443",
                        "446",
                        "449",
                        "451",
                        "453",
                        "455",
                        "456",
                        "459",
                        "460",
                        "463",
                        "464",
                        "465",
                        "467",
                        "468",
                        "470",
                        "471",
                        "472",
                        "477",
                        "479",
                        "480",
                        "482",
                        "483",
                        "484",
                        "488",
                        "489",
                        "491",
                        "493",
                        "494",
                        "495",
                        "496",
                        "497",
                        "502",
                        "505",
                        "508",
                        "511",
                        "512",
                        "513",
                        "514",
                        "518",
                        "519",
                        "520",
                        "521",
                        "523",
                        "524",
                        "525",
                        "526",
                        "527",
                        "530",
                        "533",
                        "534",
                        "535",
                        "536",
                        "540",
                        "545",
                        "546",
                        "548",
                        "551",
                        "552",
                        "555",
                        "559",
                        "562",
                        "563",
                        "565",
                        "567",
                        "570",
                        "571",
                        "574",
                        "579",
                        "580",
                        "581",
                        "582",
                        "583",
                        "584",
                        "585",
                        "587",
                        "588",
                        "589",
                        "590",
                        "592",
                        "593",
                        "596",
                        "597",
                        "598",
                        "599",
                        "600",
                        "602",
                        "604",
                        "608",
                        "612"
                    ]
                },
                {
                    "name": "Outdoor pool",
                    "count": 361,
                    "ischeck": false,
                    "sno": [
                        "5",
                        "8",
                        "11",
                        "12",
                        "13",
                        "20",
                        "21",
                        "22",
                        "27",
                        "28",
                        "31",
                        "33",
                        "38",
                        "39",
                        "44",
                        "45",
                        "48",
                        "50",
                        "52",
                        "53",
                        "55",
                        "57",
                        "58",
                        "59",
                        "60",
                        "64",
                        "65",
                        "66",
                        "67",
                        "69",
                        "73",
                        "74",
                        "75",
                        "76",
                        "78",
                        "82",
                        "91",
                        "93",
                        "96",
                        "97",
                        "99",
                        "101",
                        "102",
                        "103",
                        "104",
                        "106",
                        "107",
                        "108",
                        "109",
                        "110",
                        "111",
                        "113",
                        "114",
                        "117",
                        "119",
                        "120",
                        "122",
                        "125",
                        "126",
                        "129",
                        "130",
                        "131",
                        "132",
                        "133",
                        "135",
                        "137",
                        "138",
                        "140",
                        "141",
                        "142",
                        "144",
                        "145",
                        "148",
                        "150",
                        "151",
                        "152",
                        "154",
                        "155",
                        "156",
                        "157",
                        "158",
                        "160",
                        "161",
                        "163",
                        "166",
                        "167",
                        "168",
                        "171",
                        "172",
                        "174",
                        "175",
                        "177",
                        "178",
                        "179",
                        "180",
                        "181",
                        "182",
                        "183",
                        "184",
                        "185",
                        "186",
                        "187",
                        "188",
                        "189",
                        "190",
                        "191",
                        "192",
                        "193",
                        "194",
                        "195",
                        "197",
                        "198",
                        "200",
                        "201",
                        "205",
                        "207",
                        "209",
                        "210",
                        "212",
                        "214",
                        "215",
                        "216",
                        "220",
                        "221",
                        "222",
                        "223",
                        "224",
                        "225",
                        "226",
                        "227",
                        "229",
                        "230",
                        "234",
                        "235",
                        "236",
                        "237",
                        "238",
                        "239",
                        "240",
                        "241",
                        "242",
                        "243",
                        "244",
                        "245",
                        "247",
                        "250",
                        "251",
                        "252",
                        "253",
                        "258",
                        "259",
                        "261",
                        "262",
                        "265",
                        "266",
                        "270",
                        "272",
                        "273",
                        "274",
                        "276",
                        "277",
                        "279",
                        "280",
                        "281",
                        "283",
                        "284",
                        "286",
                        "287",
                        "288",
                        "289",
                        "290",
                        "292",
                        "295",
                        "298",
                        "299",
                        "301",
                        "304",
                        "307",
                        "309",
                        "311",
                        "314",
                        "316",
                        "318",
                        "319",
                        "320",
                        "322",
                        "323",
                        "325",
                        "326",
                        "327",
                        "328",
                        "330",
                        "332",
                        "334",
                        "337",
                        "339",
                        "340",
                        "341",
                        "342",
                        "344",
                        "346",
                        "349",
                        "351",
                        "352",
                        "354",
                        "355",
                        "358",
                        "359",
                        "361",
                        "363",
                        "364",
                        "365",
                        "366",
                        "368",
                        "369",
                        "370",
                        "371",
                        "372",
                        "373",
                        "374",
                        "375",
                        "376",
                        "378",
                        "379",
                        "381",
                        "382",
                        "383",
                        "384",
                        "385",
                        "386",
                        "387",
                        "388",
                        "390",
                        "395",
                        "396",
                        "397",
                        "398",
                        "399",
                        "400",
                        "401",
                        "403",
                        "404",
                        "405",
                        "406",
                        "407",
                        "408",
                        "409",
                        "411",
                        "412",
                        "413",
                        "415",
                        "417",
                        "418",
                        "420",
                        "422",
                        "424",
                        "425",
                        "428",
                        "429",
                        "431",
                        "433",
                        "435",
                        "436",
                        "437",
                        "439",
                        "440",
                        "441",
                        "442",
                        "443",
                        "444",
                        "446",
                        "447",
                        "450",
                        "453",
                        "454",
                        "455",
                        "460",
                        "463",
                        "464",
                        "465",
                        "466",
                        "467",
                        "468",
                        "471",
                        "472",
                        "473",
                        "477",
                        "478",
                        "480",
                        "481",
                        "483",
                        "484",
                        "486",
                        "488",
                        "489",
                        "490",
                        "491",
                        "492",
                        "493",
                        "494",
                        "495",
                        "496",
                        "497",
                        "498",
                        "501",
                        "502",
                        "504",
                        "506",
                        "509",
                        "511",
                        "512",
                        "513",
                        "514",
                        "515",
                        "516",
                        "518",
                        "519",
                        "520",
                        "524",
                        "525",
                        "526",
                        "527",
                        "528",
                        "530",
                        "531",
                        "534",
                        "535",
                        "536",
                        "538",
                        "542",
                        "543",
                        "547",
                        "549",
                        "552",
                        "553",
                        "555",
                        "556",
                        "557",
                        "560",
                        "562",
                        "565",
                        "568",
                        "569",
                        "570",
                        "572",
                        "576",
                        "578",
                        "580",
                        "582",
                        "587",
                        "588",
                        "590",
                        "591",
                        "592",
                        "595",
                        "596",
                        "597",
                        "598",
                        "602",
                        "603",
                        "609"
                    ]
                },
                {
                    "name": "Swimming pool",
                    "count": 173,
                    "ischeck": false,
                    "sno": [
                        "11",
                        "13",
                        "22",
                        "27",
                        "33",
                        "39",
                        "44",
                        "53",
                        "55",
                        "57",
                        "59",
                        "64",
                        "65",
                        "66",
                        "67",
                        "69",
                        "72",
                        "82",
                        "86",
                        "91",
                        "93",
                        "96",
                        "102",
                        "106",
                        "113",
                        "114",
                        "115",
                        "116",
                        "124",
                        "127",
                        "129",
                        "131",
                        "132",
                        "137",
                        "141",
                        "142",
                        "144",
                        "145",
                        "147",
                        "153",
                        "156",
                        "157",
                        "160",
                        "161",
                        "164",
                        "166",
                        "167",
                        "172",
                        "177",
                        "178",
                        "183",
                        "185",
                        "188",
                        "191",
                        "194",
                        "203",
                        "210",
                        "212",
                        "217",
                        "222",
                        "224",
                        "227",
                        "229",
                        "233",
                        "235",
                        "236",
                        "241",
                        "242",
                        "243",
                        "251",
                        "260",
                        "261",
                        "264",
                        "270",
                        "271",
                        "275",
                        "278",
                        "279",
                        "284",
                        "286",
                        "287",
                        "291",
                        "292",
                        "298",
                        "301",
                        "304",
                        "307",
                        "311",
                        "316",
                        "326",
                        "327",
                        "329",
                        "330",
                        "341",
                        "344",
                        "351",
                        "352",
                        "354",
                        "355",
                        "364",
                        "368",
                        "370",
                        "372",
                        "373",
                        "375",
                        "376",
                        "380",
                        "382",
                        "383",
                        "384",
                        "388",
                        "401",
                        "403",
                        "416",
                        "429",
                        "431",
                        "434",
                        "437",
                        "438",
                        "440",
                        "442",
                        "446",
                        "449",
                        "454",
                        "456",
                        "459",
                        "465",
                        "467",
                        "469",
                        "471",
                        "477",
                        "478",
                        "479",
                        "481",
                        "482",
                        "485",
                        "486",
                        "490",
                        "492",
                        "494",
                        "495",
                        "509",
                        "510",
                        "511",
                        "512",
                        "517",
                        "518",
                        "524",
                        "525",
                        "530",
                        "534",
                        "535",
                        "536",
                        "537",
                        "541",
                        "542",
                        "545",
                        "546",
                        "548",
                        "549",
                        "554",
                        "556",
                        "559",
                        "561",
                        "568",
                        "577",
                        "579",
                        "585",
                        "588",
                        "593",
                        "595",
                        "602",
                        "604"
                    ]
                },
                {
                    "name": "Poolside bar",
                    "count": 162,
                    "ischeck": false,
                    "sno": [
                        "13",
                        "23",
                        "31",
                        "52",
                        "65",
                        "69",
                        "76",
                        "91",
                        "93",
                        "96",
                        "99",
                        "101",
                        "106",
                        "114",
                        "119",
                        "129",
                        "131",
                        "142",
                        "147",
                        "148",
                        "156",
                        "157",
                        "160",
                        "163",
                        "167",
                        "168",
                        "172",
                        "178",
                        "185",
                        "194",
                        "198",
                        "207",
                        "215",
                        "225",
                        "226",
                        "233",
                        "234",
                        "237",
                        "238",
                        "240",
                        "262",
                        "265",
                        "271",
                        "276",
                        "280",
                        "283",
                        "284",
                        "287",
                        "288",
                        "295",
                        "311",
                        "316",
                        "319",
                        "322",
                        "323",
                        "325",
                        "329",
                        "330",
                        "332",
                        "334",
                        "337",
                        "340",
                        "341",
                        "344",
                        "351",
                        "355",
                        "360",
                        "365",
                        "366",
                        "367",
                        "368",
                        "370",
                        "373",
                        "375",
                        "376",
                        "378",
                        "379",
                        "381",
                        "382",
                        "383",
                        "384",
                        "386",
                        "396",
                        "401",
                        "406",
                        "407",
                        "409",
                        "411",
                        "412",
                        "414",
                        "416",
                        "417",
                        "422",
                        "424",
                        "425",
                        "431",
                        "434",
                        "435",
                        "437",
                        "438",
                        "439",
                        "441",
                        "443",
                        "444",
                        "446",
                        "464",
                        "465",
                        "471",
                        "473",
                        "475",
                        "478",
                        "479",
                        "480",
                        "483",
                        "486",
                        "488",
                        "490",
                        "491",
                        "492",
                        "493",
                        "497",
                        "498",
                        "506",
                        "509",
                        "510",
                        "511",
                        "512",
                        "517",
                        "519",
                        "521",
                        "523",
                        "527",
                        "530",
                        "535",
                        "536",
                        "537",
                        "541",
                        "542",
                        "545",
                        "547",
                        "548",
                        "551",
                        "554",
                        "555",
                        "559",
                        "560",
                        "565",
                        "567",
                        "570",
                        "572",
                        "576",
                        "578",
                        "582",
                        "584",
                        "589",
                        "590",
                        "597",
                        "599",
                        "600",
                        "604",
                        "606",
                        "612"
                    ]
                },
                {
                    "name": "Children's pool",
                    "count": 223,
                    "ischeck": false,
                    "sno": [
                        "22",
                        "39",
                        "44",
                        "57",
                        "58",
                        "64",
                        "67",
                        "71",
                        "82",
                        "85",
                        "91",
                        "93",
                        "96",
                        "99",
                        "101",
                        "104",
                        "114",
                        "117",
                        "118",
                        "129",
                        "130",
                        "131",
                        "132",
                        "138",
                        "142",
                        "147",
                        "148",
                        "155",
                        "156",
                        "159",
                        "160",
                        "161",
                        "163",
                        "167",
                        "168",
                        "171",
                        "172",
                        "173",
                        "175",
                        "186",
                        "188",
                        "189",
                        "192",
                        "193",
                        "198",
                        "201",
                        "204",
                        "207",
                        "214",
                        "222",
                        "226",
                        "229",
                        "230",
                        "237",
                        "238",
                        "239",
                        "242",
                        "243",
                        "246",
                        "257",
                        "258",
                        "261",
                        "264",
                        "269",
                        "271",
                        "274",
                        "278",
                        "279",
                        "280",
                        "283",
                        "284",
                        "286",
                        "287",
                        "289",
                        "296",
                        "304",
                        "311",
                        "320",
                        "322",
                        "323",
                        "324",
                        "328",
                        "329",
                        "330",
                        "332",
                        "333",
                        "334",
                        "340",
                        "341",
                        "343",
                        "350",
                        "354",
                        "355",
                        "358",
                        "361",
                        "363",
                        "366",
                        "368",
                        "369",
                        "371",
                        "372",
                        "376",
                        "378",
                        "381",
                        "382",
                        "383",
                        "385",
                        "386",
                        "388",
                        "390",
                        "393",
                        "395",
                        "396",
                        "398",
                        "400",
                        "401",
                        "408",
                        "409",
                        "411",
                        "412",
                        "416",
                        "422",
                        "424",
                        "425",
                        "428",
                        "429",
                        "430",
                        "431",
                        "433",
                        "435",
                        "436",
                        "438",
                        "439",
                        "441",
                        "444",
                        "448",
                        "449",
                        "453",
                        "454",
                        "456",
                        "463",
                        "464",
                        "468",
                        "469",
                        "471",
                        "472",
                        "473",
                        "474",
                        "475",
                        "476",
                        "477",
                        "479",
                        "480",
                        "482",
                        "483",
                        "485",
                        "486",
                        "489",
                        "492",
                        "493",
                        "494",
                        "495",
                        "498",
                        "504",
                        "506",
                        "507",
                        "508",
                        "509",
                        "510",
                        "511",
                        "512",
                        "514",
                        "515",
                        "517",
                        "518",
                        "520",
                        "521",
                        "524",
                        "527",
                        "533",
                        "535",
                        "536",
                        "539",
                        "541",
                        "542",
                        "544",
                        "545",
                        "546",
                        "548",
                        "551",
                        "554",
                        "559",
                        "561",
                        "563",
                        "564",
                        "565",
                        "567",
                        "568",
                        "570",
                        "571",
                        "574",
                        "575",
                        "576",
                        "577",
                        "579",
                        "581",
                        "582",
                        "583",
                        "584",
                        "585",
                        "588",
                        "590",
                        "591",
                        "592",
                        "593",
                        "596",
                        "597",
                        "599",
                        "600",
                        "602",
                        "604",
                        "606",
                        "612"
                    ]
                },
                {
                    "name": "24-hour fitness facilities",
                    "count": 117,
                    "ischeck": false,
                    "sno": [
                        "28",
                        "53",
                        "54",
                        "58",
                        "69",
                        "71",
                        "78",
                        "79",
                        "82",
                        "83",
                        "92",
                        "95",
                        "97",
                        "100",
                        "102",
                        "104",
                        "108",
                        "111",
                        "115",
                        "116",
                        "122",
                        "130",
                        "132",
                        "142",
                        "150",
                        "151",
                        "161",
                        "172",
                        "173",
                        "178",
                        "179",
                        "180",
                        "185",
                        "186",
                        "188",
                        "197",
                        "198",
                        "200",
                        "204",
                        "205",
                        "212",
                        "214",
                        "216",
                        "218",
                        "224",
                        "235",
                        "236",
                        "243",
                        "250",
                        "265",
                        "268",
                        "269",
                        "270",
                        "278",
                        "287",
                        "290",
                        "292",
                        "301",
                        "312",
                        "320",
                        "341",
                        "344",
                        "349",
                        "351",
                        "353",
                        "354",
                        "362",
                        "363",
                        "364",
                        "367",
                        "382",
                        "384",
                        "399",
                        "401",
                        "409",
                        "411",
                        "412",
                        "417",
                        "435",
                        "437",
                        "441",
                        "444",
                        "451",
                        "454",
                        "456",
                        "465",
                        "467",
                        "473",
                        "476",
                        "485",
                        "486",
                        "491",
                        "506",
                        "507",
                        "509",
                        "510",
                        "517",
                        "519",
                        "537",
                        "539",
                        "542",
                        "544",
                        "547",
                        "549",
                        "553",
                        "555",
                        "556",
                        "560",
                        "561",
                        "568",
                        "576",
                        "577",
                        "578",
                        "591",
                        "599",
                        "605",
                        "606"
                    ]
                },
                {
                    "name": "Indoor pool",
                    "count": 35,
                    "ischeck": false,
                    "sno": [
                        "68",
                        "72",
                        "115",
                        "116",
                        "124",
                        "158",
                        "159",
                        "164",
                        "167",
                        "170",
                        "193",
                        "203",
                        "237",
                        "258",
                        "274",
                        "296",
                        "340",
                        "347",
                        "350",
                        "415",
                        "421",
                        "476",
                        "479",
                        "483",
                        "488",
                        "510",
                        "513",
                        "551",
                        "555",
                        "578",
                        "582",
                        "593",
                        "599",
                        "606",
                        "608"
                    ]
                }
            ],
            "boardbasis": [
                {
                    "name": "Room Only",
                    "sno": [
                        "0",
                        "1",
                        "2",
                        "3",
                        "4",
                        "5",
                        "6",
                        "7",
                        "8",
                        "9",
                        "10",
                        "11",
                        "12",
                        "13",
                        "14",
                        "15",
                        "16",
                        "17",
                        "18",
                        "19",
                        "20",
                        "21",
                        "22",
                        "23",
                        "24",
                        "25",
                        "26",
                        "27",
                        "28",
                        "29",
                        "30",
                        "31",
                        "32",
                        "33",
                        "34",
                        "35",
                        "36",
                        "37",
                        "38",
                        "39",
                        "40",
                        "41",
                        "42",
                        "43",
                        "44",
                        "45",
                        "46",
                        "47",
                        "48",
                        "49",
                        "50",
                        "51",
                        "52",
                        "53",
                        "54",
                        "55",
                        "56",
                        "57",
                        "58",
                        "59",
                        "60",
                        "61",
                        "62",
                        "63",
                        "64",
                        "65",
                        "66",
                        "67",
                        "68",
                        "69",
                        "70",
                        "71",
                        "72",
                        "73",
                        "74",
                        "75",
                        "76",
                        "77",
                        "78",
                        "79",
                        "80",
                        "81",
                        "82",
                        "83",
                        "84",
                        "85",
                        "86",
                        "87",
                        "88",
                        "89",
                        "90",
                        "91",
                        "93",
                        "94",
                        "95",
                        "96",
                        "98",
                        "99",
                        "102",
                        "103",
                        "104",
                        "105",
                        "106",
                        "107",
                        "108",
                        "109",
                        "110",
                        "111",
                        "112",
                        "113",
                        "114",
                        "115",
                        "116",
                        "117",
                        "118",
                        "119",
                        "120",
                        "121",
                        "122",
                        "123",
                        "124",
                        "125",
                        "126",
                        "127",
                        "128",
                        "129",
                        "130",
                        "131",
                        "132",
                        "133",
                        "134",
                        "135",
                        "136",
                        "138",
                        "139",
                        "140",
                        "141",
                        "142",
                        "143",
                        "144",
                        "145",
                        "146",
                        "147",
                        "148",
                        "149",
                        "150",
                        "151",
                        "152",
                        "153",
                        "154",
                        "155",
                        "156",
                        "157",
                        "158",
                        "159",
                        "160",
                        "161",
                        "162",
                        "163",
                        "164",
                        "165",
                        "166",
                        "167",
                        "168",
                        "169",
                        "170",
                        "171",
                        "172",
                        "173",
                        "174",
                        "175",
                        "176",
                        "177",
                        "178",
                        "179",
                        "180",
                        "182",
                        "183",
                        "184",
                        "185",
                        "186",
                        "187",
                        "188",
                        "189",
                        "190",
                        "191",
                        "192",
                        "193",
                        "194",
                        "195",
                        "196",
                        "197",
                        "198",
                        "200",
                        "201",
                        "202",
                        "203",
                        "204",
                        "206",
                        "207",
                        "208",
                        "209",
                        "211",
                        "214",
                        "215",
                        "216",
                        "217",
                        "218",
                        "219",
                        "220",
                        "221",
                        "222",
                        "224",
                        "225",
                        "226",
                        "227",
                        "228",
                        "229",
                        "230",
                        "231",
                        "232",
                        "233",
                        "234",
                        "235",
                        "236",
                        "237",
                        "238",
                        "239",
                        "240",
                        "241",
                        "242",
                        "243",
                        "244",
                        "245",
                        "246",
                        "247",
                        "248",
                        "249",
                        "251",
                        "252",
                        "253",
                        "254",
                        "255",
                        "256",
                        "257",
                        "259",
                        "260",
                        "261",
                        "262",
                        "263",
                        "264",
                        "265",
                        "267",
                        "269",
                        "271",
                        "272",
                        "273",
                        "274",
                        "275",
                        "276",
                        "278",
                        "279",
                        "280",
                        "281",
                        "282",
                        "283",
                        "284",
                        "285",
                        "286",
                        "287",
                        "289",
                        "290",
                        "291",
                        "292",
                        "293",
                        "294",
                        "295",
                        "296",
                        "297",
                        "298",
                        "299",
                        "300",
                        "301",
                        "302",
                        "303",
                        "304",
                        "305",
                        "306",
                        "307",
                        "308",
                        "309",
                        "310",
                        "311",
                        "312",
                        "314",
                        "315",
                        "316",
                        "317",
                        "318",
                        "319",
                        "320",
                        "321",
                        "322",
                        "323",
                        "325",
                        "326",
                        "328",
                        "329",
                        "330",
                        "331",
                        "332",
                        "334",
                        "335",
                        "336",
                        "337",
                        "338",
                        "339",
                        "340",
                        "341",
                        "342",
                        "343",
                        "344",
                        "345",
                        "346",
                        "347",
                        "348",
                        "349",
                        "350",
                        "351",
                        "352",
                        "353",
                        "355",
                        "356",
                        "357",
                        "358",
                        "359",
                        "360",
                        "361",
                        "362",
                        "363",
                        "365",
                        "366",
                        "367",
                        "368",
                        "369",
                        "370",
                        "371",
                        "372",
                        "373",
                        "374",
                        "375",
                        "376",
                        "378",
                        "379",
                        "380",
                        "381",
                        "382",
                        "383",
                        "384",
                        "385",
                        "386",
                        "388",
                        "389",
                        "390",
                        "391",
                        "392",
                        "393",
                        "394",
                        "396",
                        "397",
                        "398",
                        "399",
                        "400",
                        "401",
                        "402",
                        "403",
                        "404",
                        "405",
                        "406",
                        "407",
                        "408",
                        "409",
                        "410",
                        "412",
                        "413",
                        "414",
                        "415",
                        "416",
                        "417",
                        "418",
                        "419",
                        "420",
                        "421",
                        "422",
                        "423",
                        "424",
                        "425",
                        "426",
                        "427",
                        "430",
                        "431",
                        "432",
                        "433",
                        "434",
                        "435",
                        "436",
                        "438",
                        "439",
                        "440",
                        "441",
                        "442",
                        "443",
                        "444",
                        "445",
                        "446",
                        "447",
                        "448",
                        "450",
                        "451",
                        "453",
                        "454",
                        "455",
                        "456",
                        "457",
                        "458",
                        "459",
                        "460",
                        "461",
                        "462",
                        "463",
                        "464",
                        "465",
                        "466",
                        "467",
                        "468",
                        "469",
                        "470",
                        "471",
                        "472",
                        "473",
                        "474",
                        "475",
                        "476",
                        "477",
                        "479",
                        "480",
                        "481",
                        "482",
                        "484",
                        "485",
                        "486",
                        "487",
                        "488",
                        "489",
                        "490",
                        "491",
                        "492",
                        "493",
                        "494",
                        "495",
                        "496",
                        "498",
                        "499",
                        "500",
                        "501",
                        "502",
                        "503",
                        "504",
                        "505",
                        "506",
                        "507",
                        "508",
                        "510",
                        "511",
                        "512",
                        "513",
                        "514",
                        "515",
                        "516",
                        "519",
                        "520",
                        "521",
                        "522",
                        "523",
                        "524",
                        "525",
                        "526",
                        "527",
                        "528",
                        "529",
                        "530",
                        "531",
                        "532",
                        "533",
                        "534",
                        "535",
                        "536",
                        "537",
                        "538",
                        "539",
                        "540",
                        "541",
                        "542",
                        "543",
                        "544",
                        "545",
                        "546",
                        "547",
                        "550",
                        "551",
                        "552",
                        "553",
                        "554",
                        "555",
                        "556",
                        "558",
                        "560",
                        "561",
                        "563",
                        "564",
                        "565",
                        "566",
                        "567",
                        "569",
                        "570",
                        "571",
                        "572",
                        "573",
                        "574",
                        "576",
                        "577",
                        "578",
                        "580",
                        "581",
                        "582",
                        "584",
                        "585",
                        "586",
                        "587",
                        "588",
                        "590",
                        "591",
                        "592",
                        "593",
                        "594",
                        "596",
                        "597",
                        "598",
                        "599",
                        "600",
                        "601",
                        "602",
                        "603",
                        "604",
                        "605",
                        "607",
                        "609",
                        "610",
                        "611",
                        "613"
                    ],
                    "count": 560
                },
                {
                    "name": "Breakfast",
                    "sno": [
                        "92",
                        "97",
                        "100",
                        "101",
                        "137",
                        "181",
                        "199",
                        "205",
                        "210",
                        "212",
                        "213",
                        "223",
                        "250",
                        "258",
                        "266",
                        "268",
                        "270",
                        "277",
                        "288",
                        "313",
                        "324",
                        "327",
                        "333",
                        "354",
                        "364",
                        "377",
                        "387",
                        "395",
                        "411",
                        "428",
                        "429",
                        "437",
                        "449",
                        "452",
                        "478",
                        "483",
                        "497",
                        "509",
                        "517",
                        "518",
                        "549",
                        "557",
                        "559",
                        "562",
                        "568",
                        "575",
                        "579",
                        "583",
                        "589",
                        "595",
                        "606",
                        "608",
                        "612"
                    ],
                    "count": 53
                },
                {
                    "name": "Half Board",
                    "sno": [
                        "548"
                    ],
                    "count": 1
                }
            ],
            "ishotdeal": true,
            "area": [
                {
                    "name": "Trade Centre 1",
                    "count": 19,
                    "ischeck": false,
                    "sno": [
                        "0",
                        "68",
                        "91",
                        "142",
                        "172",
                        "275",
                        "280",
                        "337",
                        "341",
                        "365",
                        "378",
                        "382",
                        "396",
                        "408",
                        "428",
                        "463",
                        "465",
                        "488",
                        "513"
                    ]
                },
                {
                    "name": "Al Satwa",
                    "count": 7,
                    "ischeck": false,
                    "sno": [
                        "0",
                        "142",
                        "172",
                        "257",
                        "280",
                        "378",
                        "408"
                    ]
                },
                {
                    "name": "Trade Center",
                    "count": 33,
                    "ischeck": false,
                    "sno": [
                        "0",
                        "36",
                        "68",
                        "91",
                        "142",
                        "172",
                        "234",
                        "275",
                        "280",
                        "290",
                        "337",
                        "340",
                        "341",
                        "365",
                        "376",
                        "378",
                        "382",
                        "387",
                        "388",
                        "396",
                        "408",
                        "428",
                        "445",
                        "463",
                        "465",
                        "488",
                        "511",
                        "513",
                        "514",
                        "518",
                        "555",
                        "578",
                        "580"
                    ]
                },
                {
                    "name": "Deira",
                    "count": 109,
                    "ischeck": false,
                    "sno": [
                        "1",
                        "2",
                        "3",
                        "7",
                        "8",
                        "9",
                        "10",
                        "12",
                        "13",
                        "14",
                        "15",
                        "17",
                        "21",
                        "23",
                        "24",
                        "25",
                        "26",
                        "27",
                        "29",
                        "30",
                        "34",
                        "35",
                        "42",
                        "43",
                        "44",
                        "46",
                        "49",
                        "50",
                        "51",
                        "56",
                        "66",
                        "70",
                        "77",
                        "78",
                        "80",
                        "84",
                        "93",
                        "101",
                        "105",
                        "107",
                        "110",
                        "111",
                        "112",
                        "116",
                        "117",
                        "119",
                        "124",
                        "132",
                        "134",
                        "139",
                        "145",
                        "149",
                        "158",
                        "165",
                        "171",
                        "177",
                        "180",
                        "182",
                        "183",
                        "184",
                        "192",
                        "195",
                        "200",
                        "203",
                        "206",
                        "211",
                        "215",
                        "222",
                        "225",
                        "227",
                        "228",
                        "231",
                        "236",
                        "240",
                        "242",
                        "248",
                        "249",
                        "254",
                        "278",
                        "282",
                        "283",
                        "284",
                        "288",
                        "309",
                        "317",
                        "318",
                        "327",
                        "332",
                        "334",
                        "352",
                        "358",
                        "364",
                        "384",
                        "399",
                        "409",
                        "411",
                        "421",
                        "431",
                        "435",
                        "436",
                        "460",
                        "473",
                        "477",
                        "489",
                        "498",
                        "509",
                        "519",
                        "560",
                        "611"
                    ]
                },
                {
                    "name": "Old Dubai",
                    "count": 53,
                    "ischeck": false,
                    "sno": [
                        "1",
                        "2",
                        "4",
                        "7",
                        "9",
                        "13",
                        "17",
                        "19",
                        "20",
                        "24",
                        "25",
                        "26",
                        "29",
                        "30",
                        "32",
                        "35",
                        "41",
                        "43",
                        "45",
                        "49",
                        "51",
                        "77",
                        "80",
                        "84",
                        "107",
                        "110",
                        "113",
                        "124",
                        "134",
                        "158",
                        "159",
                        "165",
                        "170",
                        "177",
                        "206",
                        "209",
                        "211",
                        "225",
                        "228",
                        "231",
                        "232",
                        "240",
                        "248",
                        "249",
                        "254",
                        "282",
                        "313",
                        "317",
                        "327",
                        "364",
                        "384",
                        "399",
                        "477"
                    ]
                },
                {
                    "name": "Al Ras",
                    "count": 3,
                    "ischeck": false,
                    "sno": [
                        "2",
                        "206",
                        "225"
                    ]
                },
                {
                    "name": "Al Shindagha",
                    "count": 2,
                    "ischeck": false,
                    "sno": [
                        "4",
                        "32"
                    ]
                },
                {
                    "name": "Bur Dubai",
                    "count": 86,
                    "ischeck": false,
                    "sno": [
                        "4",
                        "19",
                        "20",
                        "32",
                        "40",
                        "41",
                        "45",
                        "47",
                        "48",
                        "57",
                        "59",
                        "60",
                        "63",
                        "87",
                        "90",
                        "95",
                        "103",
                        "106",
                        "108",
                        "113",
                        "121",
                        "129",
                        "133",
                        "136",
                        "137",
                        "138",
                        "140",
                        "141",
                        "144",
                        "147",
                        "152",
                        "159",
                        "163",
                        "166",
                        "170",
                        "174",
                        "176",
                        "185",
                        "186",
                        "208",
                        "209",
                        "217",
                        "220",
                        "221",
                        "232",
                        "239",
                        "241",
                        "250",
                        "259",
                        "261",
                        "263",
                        "266",
                        "272",
                        "276",
                        "281",
                        "285",
                        "286",
                        "289",
                        "290",
                        "292",
                        "302",
                        "304",
                        "311",
                        "313",
                        "326",
                        "344",
                        "346",
                        "367",
                        "368",
                        "372",
                        "373",
                        "377",
                        "391",
                        "418",
                        "458",
                        "459",
                        "479",
                        "497",
                        "502",
                        "506",
                        "535",
                        "543",
                        "547",
                        "582",
                        "588",
                        "596"
                    ]
                },
                {
                    "name": "Al Mina",
                    "count": 4,
                    "ischeck": false,
                    "sno": [
                        "5",
                        "115",
                        "213",
                        "217"
                    ]
                },
                {
                    "name": "Al Muraqqabat",
                    "count": 25,
                    "ischeck": false,
                    "sno": [
                        "8",
                        "10",
                        "12",
                        "14",
                        "21",
                        "23",
                        "27",
                        "34",
                        "42",
                        "56",
                        "66",
                        "78",
                        "101",
                        "111",
                        "112",
                        "117",
                        "139",
                        "182",
                        "215",
                        "283",
                        "332",
                        "358",
                        "431",
                        "489",
                        "509"
                    ]
                },
                {
                    "name": "Al Rigga",
                    "count": 13,
                    "ischeck": false,
                    "sno": [
                        "9",
                        "17",
                        "24",
                        "30",
                        "51",
                        "110",
                        "134",
                        "228",
                        "254",
                        "317",
                        "327",
                        "384",
                        "399"
                    ]
                },
                {
                    "name": "Academic City",
                    "count": 1,
                    "ischeck": false,
                    "sno": [
                        "11"
                    ]
                },
                {
                    "name": "Garhoud",
                    "count": 12,
                    "ischeck": false,
                    "sno": [
                        "16",
                        "86",
                        "92",
                        "94",
                        "127",
                        "148",
                        "157",
                        "190",
                        "223",
                        "274",
                        "291",
                        "360"
                    ]
                },
                {
                    "name": "Al Sabkha",
                    "count": 10,
                    "ischeck": false,
                    "sno": [
                        "17",
                        "25",
                        "26",
                        "29",
                        "35",
                        "80",
                        "84",
                        "124",
                        "165",
                        "231"
                    ]
                },
                {
                    "name": "Al Quoz",
                    "count": 1,
                    "ischeck": false,
                    "sno": [
                        "22"
                    ]
                },
                {
                    "name": "Umm Suqeim",
                    "count": 6,
                    "ischeck": false,
                    "sno": [
                        "28",
                        "298",
                        "392",
                        "544",
                        "584",
                        "593"
                    ]
                },
                {
                    "name": "Jumeira 3",
                    "count": 6,
                    "ischeck": false,
                    "sno": [
                        "28",
                        "298",
                        "392",
                        "584",
                        "593",
                        "612"
                    ]
                },
                {
                    "name": "Al Barsha",
                    "count": 37,
                    "ischeck": false,
                    "sno": [
                        "31",
                        "37",
                        "52",
                        "55",
                        "64",
                        "67",
                        "71",
                        "73",
                        "82",
                        "96",
                        "97",
                        "99",
                        "104",
                        "114",
                        "150",
                        "155",
                        "168",
                        "173",
                        "175",
                        "179",
                        "189",
                        "199",
                        "219",
                        "229",
                        "252",
                        "262",
                        "273",
                        "287",
                        "299",
                        "303",
                        "322",
                        "328",
                        "348",
                        "427",
                        "537",
                        "576",
                        "602"
                    ]
                },
                {
                    "name": "Al Barsha 1",
                    "count": 32,
                    "ischeck": false,
                    "sno": [
                        "31",
                        "37",
                        "52",
                        "55",
                        "67",
                        "71",
                        "73",
                        "82",
                        "96",
                        "97",
                        "99",
                        "104",
                        "114",
                        "150",
                        "155",
                        "168",
                        "173",
                        "175",
                        "179",
                        "189",
                        "219",
                        "229",
                        "252",
                        "262",
                        "273",
                        "287",
                        "303",
                        "322",
                        "328",
                        "348",
                        "537",
                        "576"
                    ]
                },
                {
                    "name": "Trade Centre 2",
                    "count": 13,
                    "ischeck": false,
                    "sno": [
                        "36",
                        "234",
                        "340",
                        "376",
                        "387",
                        "388",
                        "445",
                        "511",
                        "514",
                        "518",
                        "555",
                        "578",
                        "580"
                    ]
                },
                {
                    "name": "Trade Centre Area",
                    "count": 18,
                    "ischeck": false,
                    "sno": [
                        "36",
                        "91",
                        "234",
                        "340",
                        "365",
                        "376",
                        "387",
                        "388",
                        "396",
                        "445",
                        "465",
                        "511",
                        "514",
                        "518",
                        "547",
                        "555",
                        "578",
                        "580"
                    ]
                },
                {
                    "name": "Barsha Heights",
                    "count": 18,
                    "ischeck": false,
                    "sno": [
                        "38",
                        "39",
                        "65",
                        "69",
                        "120",
                        "135",
                        "169",
                        "188",
                        "247",
                        "253",
                        "267",
                        "271",
                        "295",
                        "301",
                        "329",
                        "330",
                        "366",
                        "413"
                    ]
                },
                {
                    "name": "Culture Village",
                    "count": 13,
                    "ischeck": false,
                    "sno": [
                        "40",
                        "47",
                        "63",
                        "87",
                        "90",
                        "137",
                        "141",
                        "185",
                        "208",
                        "259",
                        "266",
                        "418",
                        "535"
                    ]
                },
                {
                    "name": "Jadaf",
                    "count": 18,
                    "ischeck": false,
                    "sno": [
                        "40",
                        "47",
                        "63",
                        "87",
                        "90",
                        "137",
                        "141",
                        "185",
                        "208",
                        "259",
                        "266",
                        "281",
                        "311",
                        "373",
                        "377",
                        "418",
                        "535",
                        "543"
                    ]
                },
                {
                    "name": "Naif",
                    "count": 4,
                    "ischeck": false,
                    "sno": [
                        "49",
                        "77",
                        "211",
                        "364"
                    ]
                },
                {
                    "name": "Jebel Ali",
                    "count": 6,
                    "ischeck": false,
                    "sno": [
                        "53",
                        "449",
                        "469",
                        "482",
                        "546",
                        "557"
                    ]
                },
                {
                    "name": "Sharjah",
                    "count": 27,
                    "ischeck": false,
                    "sno": [
                        "54",
                        "74",
                        "75",
                        "88",
                        "89",
                        "109",
                        "118",
                        "153",
                        "154",
                        "164",
                        "167",
                        "178",
                        "187",
                        "207",
                        "230",
                        "243",
                        "260",
                        "296",
                        "300",
                        "316",
                        "333",
                        "336",
                        "345",
                        "347",
                        "395",
                        "429",
                        "549"
                    ]
                },
                {
                    "name": "Al Qasimia",
                    "count": 3,
                    "ischeck": false,
                    "sno": [
                        "54",
                        "243",
                        "345"
                    ]
                },
                {
                    "name": "Al Majaz",
                    "count": 10,
                    "ischeck": false,
                    "sno": [
                        "54",
                        "154",
                        "164",
                        "167",
                        "230",
                        "243",
                        "300",
                        "316",
                        "333",
                        "347"
                    ]
                },
                {
                    "name": "Al Mankhool",
                    "count": 22,
                    "ischeck": false,
                    "sno": [
                        "57",
                        "59",
                        "60",
                        "106",
                        "121",
                        "129",
                        "136",
                        "144",
                        "152",
                        "163",
                        "176",
                        "220",
                        "221",
                        "261",
                        "263",
                        "285",
                        "286",
                        "302",
                        "346",
                        "372",
                        "502",
                        "588"
                    ]
                },
                {
                    "name": "Green Community",
                    "count": 6,
                    "ischeck": false,
                    "sno": [
                        "58",
                        "76",
                        "194",
                        "238",
                        "356",
                        "400"
                    ]
                },
                {
                    "name": "Dubai Investment Park",
                    "count": 7,
                    "ischeck": false,
                    "sno": [
                        "58",
                        "76",
                        "194",
                        "238",
                        "297",
                        "356",
                        "400"
                    ]
                },
                {
                    "name": "Ajman",
                    "count": 12,
                    "ischeck": false,
                    "sno": [
                        "61",
                        "98",
                        "258",
                        "350",
                        "386",
                        "425",
                        "446",
                        "485",
                        "504",
                        "542",
                        "568",
                        "608"
                    ]
                },
                {
                    "name": "Al Sharq",
                    "count": 2,
                    "ischeck": false,
                    "sno": [
                        "74",
                        "336"
                    ]
                },
                {
                    "name": "Al Gharb",
                    "count": 2,
                    "ischeck": false,
                    "sno": [
                        "74",
                        "88"
                    ]
                },
                {
                    "name": "Layyah",
                    "count": 1,
                    "ischeck": false,
                    "sno": [
                        "75"
                    ]
                },
                {
                    "name": "Beach & Coast",
                    "count": 6,
                    "ischeck": false,
                    "sno": [
                        "75",
                        "89",
                        "187",
                        "260",
                        "395",
                        "429"
                    ]
                },
                {
                    "name": "Dubai Investment Park - 1",
                    "count": 1,
                    "ischeck": false,
                    "sno": [
                        "76"
                    ]
                },
                {
                    "name": "Al Sufouh 2",
                    "count": 9,
                    "ischeck": false,
                    "sno": [
                        "79",
                        "100",
                        "353",
                        "354",
                        "403",
                        "406",
                        "432",
                        "561",
                        "583"
                    ]
                },
                {
                    "name": "Dubai Media City",
                    "count": 2,
                    "ischeck": false,
                    "sno": [
                        "79",
                        "406"
                    ]
                },
                {
                    "name": "International City",
                    "count": 2,
                    "ischeck": false,
                    "sno": [
                        "83",
                        "146"
                    ]
                },
                {
                    "name": "Al Khan",
                    "count": 4,
                    "ischeck": false,
                    "sno": [
                        "89",
                        "109",
                        "207",
                        "260"
                    ]
                },
                {
                    "name": "Dubai Production City",
                    "count": 3,
                    "ischeck": false,
                    "sno": [
                        "102",
                        "198",
                        "270"
                    ]
                },
                {
                    "name": "Al Karama",
                    "count": 6,
                    "ischeck": false,
                    "sno": [
                        "103",
                        "133",
                        "138",
                        "250",
                        "289",
                        "344"
                    ]
                },
                {
                    "name": "Oud Metha",
                    "count": 8,
                    "ischeck": false,
                    "sno": [
                        "108",
                        "147",
                        "276",
                        "292",
                        "326",
                        "367",
                        "368",
                        "391"
                    ]
                },
                {
                    "name": "Discovery Gardens",
                    "count": 5,
                    "ischeck": false,
                    "sno": [
                        "122",
                        "126",
                        "143",
                        "196",
                        "323"
                    ]
                },
                {
                    "name": "Business Bay",
                    "count": 27,
                    "ischeck": false,
                    "sno": [
                        "128",
                        "160",
                        "214",
                        "251",
                        "310",
                        "331",
                        "355",
                        "363",
                        "379",
                        "390",
                        "401",
                        "404",
                        "410",
                        "417",
                        "419",
                        "424",
                        "426",
                        "437",
                        "453",
                        "455",
                        "468",
                        "480",
                        "484",
                        "512",
                        "530",
                        "540",
                        "556"
                    ]
                },
                {
                    "name": "Downtown Dubai",
                    "count": 45,
                    "ischeck": false,
                    "sno": [
                        "128",
                        "156",
                        "160",
                        "314",
                        "315",
                        "325",
                        "355",
                        "370",
                        "385",
                        "389",
                        "401",
                        "410",
                        "417",
                        "424",
                        "437",
                        "440",
                        "448",
                        "453",
                        "454",
                        "455",
                        "457",
                        "464",
                        "466",
                        "468",
                        "487",
                        "495",
                        "516",
                        "523",
                        "524",
                        "526",
                        "528",
                        "530",
                        "556",
                        "562",
                        "566",
                        "569",
                        "572",
                        "573",
                        "587",
                        "590",
                        "591",
                        "592",
                        "595",
                        "603",
                        "609"
                    ]
                },
                {
                    "name": "Al Sufouh",
                    "count": 4,
                    "ischeck": false,
                    "sno": [
                        "130",
                        "581",
                        "583",
                        "585"
                    ]
                },
                {
                    "name": "Al Sufouh 1",
                    "count": 4,
                    "ischeck": false,
                    "sno": [
                        "130",
                        "544",
                        "581",
                        "585"
                    ]
                },
                {
                    "name": "Dubai World Central",
                    "count": 3,
                    "ischeck": false,
                    "sno": [
                        "131",
                        "151",
                        "205"
                    ]
                },
                {
                    "name": "Al Mamzar",
                    "count": 1,
                    "ischeck": false,
                    "sno": [
                        "145"
                    ]
                },
                {
                    "name": "Dubai Healthcare City",
                    "count": 7,
                    "ischeck": false,
                    "sno": [
                        "147",
                        "367",
                        "368",
                        "479",
                        "497",
                        "506",
                        "582"
                    ]
                },
                {
                    "name": "Jumeirah Beach Residence",
                    "count": 49,
                    "ischeck": false,
                    "sno": [
                        "161",
                        "197",
                        "201",
                        "216",
                        "224",
                        "233",
                        "255",
                        "256",
                        "265",
                        "277",
                        "298",
                        "351",
                        "361",
                        "369",
                        "394",
                        "398",
                        "405",
                        "412",
                        "420",
                        "441",
                        "447",
                        "450",
                        "456",
                        "461",
                        "462",
                        "470",
                        "475",
                        "491",
                        "494",
                        "500",
                        "501",
                        "515",
                        "517",
                        "520",
                        "521",
                        "527",
                        "529",
                        "536",
                        "545",
                        "550",
                        "552",
                        "554",
                        "558",
                        "563",
                        "565",
                        "570",
                        "574",
                        "579",
                        "594"
                    ]
                },
                {
                    "name": "Dubai Marina",
                    "count": 51,
                    "ischeck": false,
                    "sno": [
                        "161",
                        "197",
                        "201",
                        "216",
                        "224",
                        "233",
                        "255",
                        "256",
                        "265",
                        "277",
                        "351",
                        "361",
                        "369",
                        "394",
                        "398",
                        "405",
                        "412",
                        "420",
                        "441",
                        "447",
                        "450",
                        "456",
                        "461",
                        "462",
                        "470",
                        "472",
                        "475",
                        "491",
                        "494",
                        "500",
                        "501",
                        "515",
                        "517",
                        "520",
                        "521",
                        "527",
                        "529",
                        "536",
                        "545",
                        "550",
                        "552",
                        "554",
                        "558",
                        "561",
                        "563",
                        "565",
                        "570",
                        "574",
                        "579",
                        "583",
                        "594"
                    ]
                },
                {
                    "name": "Motor City",
                    "count": 1,
                    "ischeck": false,
                    "sno": [
                        "162"
                    ]
                },
                {
                    "name": "Dubai Studio City",
                    "count": 1,
                    "ischeck": false,
                    "sno": [
                        "191"
                    ]
                },
                {
                    "name": "Al Barsha 2",
                    "count": 2,
                    "ischeck": false,
                    "sno": [
                        "199",
                        "602"
                    ]
                },
                {
                    "name": "Al Khabaisi",
                    "count": 1,
                    "ischeck": false,
                    "sno": [
                        "203"
                    ]
                },
                {
                    "name": "Jumeirah Village",
                    "count": 7,
                    "ischeck": false,
                    "sno": [
                        "204",
                        "305",
                        "308",
                        "338",
                        "381",
                        "402",
                        "423"
                    ]
                },
                {
                    "name": "Jumeirah Village Triangle",
                    "count": 2,
                    "ischeck": false,
                    "sno": [
                        "204",
                        "308"
                    ]
                },
                {
                    "name": "Al Qusais",
                    "count": 7,
                    "ischeck": false,
                    "sno": [
                        "210",
                        "212",
                        "237",
                        "244",
                        "246",
                        "269",
                        "324"
                    ]
                },
                {
                    "name": "Al Wasl",
                    "count": 4,
                    "ischeck": false,
                    "sno": [
                        "218",
                        "268",
                        "467",
                        "598"
                    ]
                },
                {
                    "name": "Dubai Sports City",
                    "count": 1,
                    "ischeck": false,
                    "sno": [
                        "245"
                    ]
                },
                {
                    "name": "DIFC",
                    "count": 8,
                    "ischeck": false,
                    "sno": [
                        "275",
                        "340",
                        "365",
                        "387",
                        "388",
                        "555",
                        "578",
                        "580"
                    ]
                },
                {
                    "name": "Al Baraha",
                    "count": 1,
                    "ischeck": false,
                    "sno": [
                        "288"
                    ]
                },
                {
                    "name": "Zabeel",
                    "count": 4,
                    "ischeck": false,
                    "sno": [
                        "290",
                        "458",
                        "459",
                        "547"
                    ]
                },
                {
                    "name": "Za'abeel 2",
                    "count": 3,
                    "ischeck": false,
                    "sno": [
                        "290",
                        "376",
                        "514"
                    ]
                },
                {
                    "name": "The Greens",
                    "count": 1,
                    "ischeck": false,
                    "sno": [
                        "294"
                    ]
                },
                {
                    "name": "Al Nahda",
                    "count": 1,
                    "ischeck": false,
                    "sno": [
                        "296"
                    ]
                },
                {
                    "name": "Umm Suqeim 1",
                    "count": 1,
                    "ischeck": false,
                    "sno": [
                        "298"
                    ]
                },
                {
                    "name": "Jumeirah Village Circle",
                    "count": 5,
                    "ischeck": false,
                    "sno": [
                        "305",
                        "338",
                        "381",
                        "402",
                        "423"
                    ]
                },
                {
                    "name": "Jumeirah Lake Towers",
                    "count": 5,
                    "ischeck": false,
                    "sno": [
                        "307",
                        "342",
                        "375",
                        "383",
                        "415"
                    ]
                },
                {
                    "name": "Arabian Ranches",
                    "count": 1,
                    "ischeck": false,
                    "sno": [
                        "321"
                    ]
                },
                {
                    "name": "Mirdif",
                    "count": 2,
                    "ischeck": false,
                    "sno": [
                        "343",
                        "586"
                    ]
                },
                {
                    "name": "Dubai Internet City",
                    "count": 3,
                    "ischeck": false,
                    "sno": [
                        "353",
                        "354",
                        "403"
                    ]
                },
                {
                    "name": "Palm Jumeirah",
                    "count": 30,
                    "ischeck": false,
                    "sno": [
                        "357",
                        "414",
                        "430",
                        "442",
                        "451",
                        "481",
                        "483",
                        "486",
                        "493",
                        "499",
                        "503",
                        "505",
                        "507",
                        "510",
                        "522",
                        "532",
                        "533",
                        "539",
                        "541",
                        "548",
                        "551",
                        "553",
                        "564",
                        "567",
                        "577",
                        "597",
                        "600",
                        "601",
                        "607",
                        "613"
                    ]
                },
                {
                    "name": "Umm al Quwain",
                    "count": 6,
                    "ischeck": false,
                    "sno": [
                        "371",
                        "380",
                        "393",
                        "434",
                        "452",
                        "474"
                    ]
                },
                {
                    "name": "Umm Suqeim 3",
                    "count": 4,
                    "ischeck": false,
                    "sno": [
                        "392",
                        "584",
                        "593",
                        "612"
                    ]
                },
                {
                    "name": "Emirates Hills",
                    "count": 1,
                    "ischeck": false,
                    "sno": [
                        "407"
                    ]
                },
                {
                    "name": "Jumeirah",
                    "count": 4,
                    "ischeck": false,
                    "sno": [
                        "416",
                        "599",
                        "604",
                        "606"
                    ]
                },
                {
                    "name": "Jumeira 1",
                    "count": 2,
                    "ischeck": false,
                    "sno": [
                        "416",
                        "604"
                    ]
                },
                {
                    "name": "Al Jerf 2",
                    "count": 1,
                    "ischeck": false,
                    "sno": [
                        "425"
                    ]
                },
                {
                    "name": "Dubai Festival City",
                    "count": 4,
                    "ischeck": false,
                    "sno": [
                        "433",
                        "444",
                        "476",
                        "492"
                    ]
                },
                {
                    "name": "Nad Al Sheba",
                    "count": 1,
                    "ischeck": false,
                    "sno": [
                        "439"
                    ]
                },
                {
                    "name": "Rumailah",
                    "count": 2,
                    "ischeck": false,
                    "sno": [
                        "446",
                        "608"
                    ]
                },
                {
                    "name": "Dubai Parks and Resorts",
                    "count": 2,
                    "ischeck": false,
                    "sno": [
                        "449",
                        "557"
                    ]
                },
                {
                    "name": "Jebel Ali Free Zone",
                    "count": 3,
                    "ischeck": false,
                    "sno": [
                        "469",
                        "482",
                        "546"
                    ]
                },
                {
                    "name": "Endurance City",
                    "count": 1,
                    "ischeck": false,
                    "sno": [
                        "559"
                    ]
                }
            ],
            "landmark": [
                {
                    "name": "Boulevard at Emirates Towers",
                    "count": 16,
                    "ischeck": false,
                    "sno": [
                        "0",
                        "68",
                        "142",
                        "172",
                        "234",
                        "280",
                        "378",
                        "382",
                        "388",
                        "396",
                        "445",
                        "511",
                        "547",
                        "555",
                        "578",
                        "580"
                    ]
                },
                {
                    "name": "Empty Quarter",
                    "count": 13,
                    "ischeck": false,
                    "sno": [
                        "0",
                        "142",
                        "172",
                        "234",
                        "280",
                        "382",
                        "388",
                        "445",
                        "511",
                        "547",
                        "555",
                        "578",
                        "580"
                    ]
                },
                {
                    "name": "The Gate Building",
                    "count": 15,
                    "ischeck": false,
                    "sno": [
                        "0",
                        "68",
                        "142",
                        "172",
                        "275",
                        "280",
                        "365",
                        "382",
                        "387",
                        "388",
                        "511",
                        "547",
                        "555",
                        "578",
                        "580"
                    ]
                },
                {
                    "name": "Tabari Artspace",
                    "count": 11,
                    "ischeck": false,
                    "sno": [
                        "0",
                        "142",
                        "172",
                        "280",
                        "388",
                        "445",
                        "511",
                        "547",
                        "555",
                        "578",
                        "580"
                    ]
                },
                {
                    "name": "Dubai International Financial Centre",
                    "count": 16,
                    "ischeck": false,
                    "sno": [
                        "0",
                        "68",
                        "142",
                        "172",
                        "275",
                        "280",
                        "365",
                        "382",
                        "387",
                        "388",
                        "396",
                        "511",
                        "547",
                        "555",
                        "578",
                        "580"
                    ]
                },
                {
                    "name": "Ayyam Gallery",
                    "count": 13,
                    "ischeck": false,
                    "sno": [
                        "0",
                        "142",
                        "172",
                        "234",
                        "280",
                        "382",
                        "388",
                        "445",
                        "511",
                        "547",
                        "555",
                        "578",
                        "580"
                    ]
                },
                {
                    "name": "XVA Gallery",
                    "count": 13,
                    "ischeck": false,
                    "sno": [
                        "0",
                        "142",
                        "172",
                        "234",
                        "280",
                        "382",
                        "388",
                        "445",
                        "511",
                        "547",
                        "555",
                        "578",
                        "580"
                    ]
                },
                {
                    "name": "Sheikh Zayed Road",
                    "count": 99,
                    "ischeck": false,
                    "sno": [
                        "0",
                        "22",
                        "28",
                        "31",
                        "36",
                        "37",
                        "38",
                        "67",
                        "68",
                        "71",
                        "82",
                        "91",
                        "96",
                        "97",
                        "99",
                        "100",
                        "104",
                        "114",
                        "125",
                        "130",
                        "142",
                        "150",
                        "155",
                        "160",
                        "168",
                        "169",
                        "172",
                        "173",
                        "175",
                        "179",
                        "189",
                        "218",
                        "219",
                        "226",
                        "229",
                        "234",
                        "235",
                        "252",
                        "257",
                        "268",
                        "273",
                        "275",
                        "280",
                        "287",
                        "295",
                        "298",
                        "314",
                        "320",
                        "322",
                        "328",
                        "337",
                        "340",
                        "341",
                        "348",
                        "362",
                        "365",
                        "370",
                        "376",
                        "378",
                        "382",
                        "387",
                        "388",
                        "392",
                        "396",
                        "401",
                        "408",
                        "417",
                        "422",
                        "428",
                        "438",
                        "440",
                        "445",
                        "448",
                        "463",
                        "464",
                        "465",
                        "467",
                        "488",
                        "511",
                        "512",
                        "513",
                        "514",
                        "518",
                        "523",
                        "537",
                        "544",
                        "555",
                        "569",
                        "573",
                        "576",
                        "578",
                        "580",
                        "581",
                        "585",
                        "593",
                        "595",
                        "598",
                        "599",
                        "603"
                    ]
                },
                {
                    "name": "Museum of the Future",
                    "count": 17,
                    "ischeck": false,
                    "sno": [
                        "0",
                        "36",
                        "91",
                        "142",
                        "172",
                        "234",
                        "280",
                        "378",
                        "382",
                        "388",
                        "396",
                        "408",
                        "445",
                        "511",
                        "555",
                        "578",
                        "580"
                    ]
                },
                {
                    "name": "Palm Islands",
                    "count": 21,
                    "ischeck": false,
                    "sno": [
                        "1",
                        "8",
                        "15",
                        "43",
                        "46",
                        "49",
                        "51",
                        "70",
                        "77",
                        "107",
                        "158",
                        "182",
                        "183",
                        "200",
                        "222",
                        "317",
                        "358",
                        "364",
                        "399",
                        "477",
                        "489"
                    ]
                },
                {
                    "name": "Badiyah Mosque",
                    "count": 22,
                    "ischeck": false,
                    "sno": [
                        "1",
                        "7",
                        "9",
                        "24",
                        "25",
                        "29",
                        "49",
                        "77",
                        "84",
                        "110",
                        "124",
                        "134",
                        "158",
                        "177",
                        "228",
                        "240",
                        "249",
                        "254",
                        "282",
                        "327",
                        "409",
                        "498"
                    ]
                },
                {
                    "name": "Naif Souq",
                    "count": 24,
                    "ischeck": false,
                    "sno": [
                        "1",
                        "7",
                        "9",
                        "24",
                        "25",
                        "29",
                        "49",
                        "77",
                        "84",
                        "110",
                        "124",
                        "134",
                        "158",
                        "177",
                        "228",
                        "240",
                        "249",
                        "254",
                        "282",
                        "327",
                        "384",
                        "399",
                        "409",
                        "498"
                    ]
                },
                {
                    "name": "Burj Nahar Mall",
                    "count": 20,
                    "ischeck": false,
                    "sno": [
                        "1",
                        "8",
                        "15",
                        "43",
                        "46",
                        "49",
                        "51",
                        "70",
                        "77",
                        "107",
                        "158",
                        "182",
                        "183",
                        "222",
                        "317",
                        "358",
                        "364",
                        "399",
                        "477",
                        "489"
                    ]
                },
                {
                    "name": "Al-Ahmadiya School",
                    "count": 9,
                    "ischeck": false,
                    "sno": [
                        "2",
                        "9",
                        "25",
                        "29",
                        "84",
                        "124",
                        "177",
                        "249",
                        "282"
                    ]
                },
                {
                    "name": "Heritage House",
                    "count": 8,
                    "ischeck": false,
                    "sno": [
                        "2",
                        "9",
                        "25",
                        "29",
                        "84",
                        "124",
                        "249",
                        "282"
                    ]
                },
                {
                    "name": "Spice Souk",
                    "count": 18,
                    "ischeck": false,
                    "sno": [
                        "2",
                        "7",
                        "9",
                        "19",
                        "24",
                        "25",
                        "29",
                        "84",
                        "110",
                        "124",
                        "134",
                        "177",
                        "228",
                        "240",
                        "249",
                        "254",
                        "282",
                        "327"
                    ]
                },
                {
                    "name": "Gold Souk",
                    "count": 28,
                    "ischeck": false,
                    "sno": [
                        "2",
                        "7",
                        "9",
                        "13",
                        "17",
                        "24",
                        "25",
                        "26",
                        "29",
                        "35",
                        "80",
                        "84",
                        "110",
                        "124",
                        "134",
                        "165",
                        "177",
                        "206",
                        "211",
                        "225",
                        "228",
                        "231",
                        "240",
                        "248",
                        "249",
                        "254",
                        "282",
                        "327"
                    ]
                },
                {
                    "name": "Deira Spice Souq",
                    "count": 14,
                    "ischeck": false,
                    "sno": [
                        "2",
                        "7",
                        "9",
                        "24",
                        "25",
                        "29",
                        "84",
                        "110",
                        "124",
                        "177",
                        "228",
                        "249",
                        "282",
                        "327"
                    ]
                },
                {
                    "name": "Perfume Souq",
                    "count": 17,
                    "ischeck": false,
                    "sno": [
                        "2",
                        "7",
                        "9",
                        "24",
                        "25",
                        "29",
                        "84",
                        "110",
                        "124",
                        "134",
                        "177",
                        "228",
                        "240",
                        "249",
                        "254",
                        "282",
                        "327"
                    ]
                },
                {
                    "name": "Dubai Museum",
                    "count": 11,
                    "ischeck": false,
                    "sno": [
                        "4",
                        "19",
                        "20",
                        "32",
                        "41",
                        "45",
                        "159",
                        "170",
                        "209",
                        "232",
                        "313"
                    ]
                },
                {
                    "name": "Textile Souk",
                    "count": 13,
                    "ischeck": false,
                    "sno": [
                        "4",
                        "19",
                        "20",
                        "32",
                        "41",
                        "45",
                        "159",
                        "170",
                        "209",
                        "239",
                        "272",
                        "304",
                        "313"
                    ]
                },
                {
                    "name": "Ali bin Abi Talib Mosque",
                    "count": 12,
                    "ischeck": false,
                    "sno": [
                        "4",
                        "19",
                        "20",
                        "32",
                        "45",
                        "159",
                        "170",
                        "209",
                        "239",
                        "272",
                        "304",
                        "313"
                    ]
                },
                {
                    "name": "Grand Mosque",
                    "count": 11,
                    "ischeck": false,
                    "sno": [
                        "4",
                        "19",
                        "20",
                        "32",
                        "41",
                        "45",
                        "159",
                        "170",
                        "209",
                        "232",
                        "313"
                    ]
                },
                {
                    "name": "Sheikh Saeed Al Maktoum House",
                    "count": 6,
                    "ischeck": false,
                    "sno": [
                        "4",
                        "19",
                        "32",
                        "45",
                        "159",
                        "209"
                    ]
                },
                {
                    "name": "‘Hindi Lane’",
                    "count": 8,
                    "ischeck": false,
                    "sno": [
                        "4",
                        "19",
                        "32",
                        "45",
                        "159",
                        "209",
                        "232",
                        "313"
                    ]
                },
                {
                    "name": "The Dubai Heritage Village",
                    "count": 5,
                    "ischeck": false,
                    "sno": [
                        "4",
                        "19",
                        "32",
                        "45",
                        "159"
                    ]
                },
                {
                    "name": "Traditional Architecture Museum",
                    "count": 7,
                    "ischeck": false,
                    "sno": [
                        "4",
                        "19",
                        "32",
                        "45",
                        "159",
                        "209",
                        "239"
                    ]
                },
                {
                    "name": "Sheikh Juma Al-Maktoum House",
                    "count": 7,
                    "ischeck": false,
                    "sno": [
                        "4",
                        "19",
                        "32",
                        "45",
                        "159",
                        "209",
                        "239"
                    ]
                },
                {
                    "name": "Meena Bazaar",
                    "count": 19,
                    "ischeck": false,
                    "sno": [
                        "4",
                        "19",
                        "20",
                        "32",
                        "41",
                        "45",
                        "129",
                        "144",
                        "152",
                        "159",
                        "166",
                        "170",
                        "209",
                        "261",
                        "272",
                        "304",
                        "313",
                        "372",
                        "588"
                    ]
                },
                {
                    "name": "Al Hudaiba Park",
                    "count": 9,
                    "ischeck": false,
                    "sno": [
                        "5",
                        "72",
                        "115",
                        "125",
                        "213",
                        "264",
                        "306",
                        "478",
                        "490"
                    ]
                },
                {
                    "name": "Etihad Museum",
                    "count": 7,
                    "ischeck": false,
                    "sno": [
                        "5",
                        "72",
                        "213",
                        "264",
                        "306",
                        "478",
                        "490"
                    ]
                },
                {
                    "name": "Dubai Expo 2020",
                    "count": 1,
                    "ischeck": false,
                    "sno": [
                        "6"
                    ]
                },
                {
                    "name": "Deira Twin Towers Shopping Centre",
                    "count": 22,
                    "ischeck": false,
                    "sno": [
                        "7",
                        "9",
                        "24",
                        "25",
                        "29",
                        "49",
                        "50",
                        "51",
                        "84",
                        "110",
                        "134",
                        "177",
                        "228",
                        "240",
                        "249",
                        "254",
                        "278",
                        "282",
                        "327",
                        "358",
                        "384",
                        "489"
                    ]
                },
                {
                    "name": "Al Ghurair Centre",
                    "count": 28,
                    "ischeck": false,
                    "sno": [
                        "8",
                        "15",
                        "23",
                        "34",
                        "42",
                        "43",
                        "46",
                        "49",
                        "50",
                        "51",
                        "66",
                        "111",
                        "117",
                        "182",
                        "183",
                        "200",
                        "222",
                        "227",
                        "278",
                        "283",
                        "317",
                        "332",
                        "358",
                        "364",
                        "384",
                        "399",
                        "460",
                        "489"
                    ]
                },
                {
                    "name": "Reef Mall",
                    "count": 26,
                    "ischeck": false,
                    "sno": [
                        "8",
                        "15",
                        "21",
                        "23",
                        "34",
                        "46",
                        "51",
                        "66",
                        "111",
                        "116",
                        "117",
                        "182",
                        "183",
                        "184",
                        "200",
                        "203",
                        "215",
                        "222",
                        "284",
                        "317",
                        "358",
                        "364",
                        "399",
                        "431",
                        "489",
                        "509"
                    ]
                },
                {
                    "name": "Fish Roundabout",
                    "count": 27,
                    "ischeck": false,
                    "sno": [
                        "8",
                        "15",
                        "23",
                        "34",
                        "42",
                        "43",
                        "46",
                        "49",
                        "50",
                        "51",
                        "70",
                        "77",
                        "107",
                        "117",
                        "134",
                        "182",
                        "183",
                        "200",
                        "222",
                        "278",
                        "317",
                        "358",
                        "364",
                        "384",
                        "399",
                        "477",
                        "489"
                    ]
                },
                {
                    "name": "Deira Clocktower",
                    "count": 16,
                    "ischeck": false,
                    "sno": [
                        "10",
                        "13",
                        "21",
                        "27",
                        "44",
                        "66",
                        "101",
                        "111",
                        "112",
                        "116",
                        "171",
                        "180",
                        "215",
                        "431",
                        "436",
                        "509"
                    ]
                },
                {
                    "name": "Hamarain Centre",
                    "count": 17,
                    "ischeck": false,
                    "sno": [
                        "10",
                        "21",
                        "27",
                        "66",
                        "101",
                        "111",
                        "112",
                        "116",
                        "183",
                        "184",
                        "200",
                        "203",
                        "215",
                        "222",
                        "284",
                        "431",
                        "509"
                    ]
                },
                {
                    "name": "Stargate Dubai",
                    "count": 17,
                    "ischeck": false,
                    "sno": [
                        "13",
                        "105",
                        "132",
                        "171",
                        "180",
                        "192",
                        "195",
                        "236",
                        "242",
                        "318",
                        "334",
                        "352",
                        "411",
                        "421",
                        "435",
                        "436",
                        "473"
                    ]
                },
                {
                    "name": "City Centre Deira",
                    "count": 16,
                    "ischeck": false,
                    "sno": [
                        "13",
                        "105",
                        "119",
                        "171",
                        "192",
                        "195",
                        "236",
                        "242",
                        "318",
                        "334",
                        "352",
                        "411",
                        "421",
                        "435",
                        "473",
                        "560"
                    ]
                },
                {
                    "name": "Dubai Tennis Stadium",
                    "count": 6,
                    "ischeck": false,
                    "sno": [
                        "16",
                        "86",
                        "148",
                        "157",
                        "360",
                        "560"
                    ]
                },
                {
                    "name": "Majlis Gallery",
                    "count": 11,
                    "ischeck": false,
                    "sno": [
                        "19",
                        "20",
                        "32",
                        "41",
                        "45",
                        "113",
                        "159",
                        "170",
                        "209",
                        "232",
                        "313"
                    ]
                },
                {
                    "name": "SMCCU Sheikh Mohammed Centre for Cultural Understanding",
                    "count": 7,
                    "ischeck": false,
                    "sno": [
                        "19",
                        "20",
                        "41",
                        "170",
                        "209",
                        "232",
                        "313"
                    ]
                },
                {
                    "name": "Al-Serkal Cultural Foundation",
                    "count": 10,
                    "ischeck": false,
                    "sno": [
                        "19",
                        "20",
                        "32",
                        "41",
                        "45",
                        "159",
                        "170",
                        "209",
                        "232",
                        "313"
                    ]
                },
                {
                    "name": "Bastakiya",
                    "count": 7,
                    "ischeck": false,
                    "sno": [
                        "19",
                        "20",
                        "41",
                        "170",
                        "209",
                        "232",
                        "313"
                    ]
                },
                {
                    "name": "BurJuman Mall",
                    "count": 20,
                    "ischeck": false,
                    "sno": [
                        "20",
                        "41",
                        "57",
                        "60",
                        "103",
                        "113",
                        "121",
                        "138",
                        "144",
                        "152",
                        "170",
                        "220",
                        "221",
                        "261",
                        "286",
                        "289",
                        "346",
                        "372",
                        "502",
                        "588"
                    ]
                },
                {
                    "name": "The Emirates Golf Club",
                    "count": 12,
                    "ischeck": false,
                    "sno": [
                        "20",
                        "41",
                        "103",
                        "113",
                        "138",
                        "144",
                        "152",
                        "170",
                        "209",
                        "313",
                        "372",
                        "588"
                    ]
                },
                {
                    "name": "iFly Dubai",
                    "count": 14,
                    "ischeck": false,
                    "sno": [
                        "20",
                        "41",
                        "57",
                        "60",
                        "103",
                        "113",
                        "138",
                        "144",
                        "152",
                        "170",
                        "250",
                        "313",
                        "372",
                        "588"
                    ]
                },
                {
                    "name": "1x1 Art Space",
                    "count": 1,
                    "ischeck": false,
                    "sno": [
                        "28"
                    ]
                },
                {
                    "name": "Mall of the Emirates",
                    "count": 17,
                    "ischeck": false,
                    "sno": [
                        "31",
                        "82",
                        "96",
                        "99",
                        "114",
                        "150",
                        "155",
                        "173",
                        "175",
                        "179",
                        "219",
                        "252",
                        "262",
                        "273",
                        "287",
                        "537",
                        "576"
                    ]
                },
                {
                    "name": "Ski Dubai",
                    "count": 21,
                    "ischeck": false,
                    "sno": [
                        "31",
                        "67",
                        "82",
                        "96",
                        "97",
                        "99",
                        "104",
                        "114",
                        "150",
                        "155",
                        "173",
                        "175",
                        "179",
                        "219",
                        "252",
                        "262",
                        "273",
                        "287",
                        "328",
                        "537",
                        "576"
                    ]
                },
                {
                    "name": "Fatima Abdullah Mohammed Rasheed Mosque",
                    "count": 23,
                    "ischeck": false,
                    "sno": [
                        "31",
                        "67",
                        "82",
                        "96",
                        "97",
                        "99",
                        "104",
                        "114",
                        "150",
                        "155",
                        "173",
                        "175",
                        "179",
                        "189",
                        "219",
                        "229",
                        "252",
                        "262",
                        "273",
                        "287",
                        "322",
                        "328",
                        "576"
                    ]
                },
                {
                    "name": "Dubai International Convention and Exhibition Centre",
                    "count": 9,
                    "ischeck": false,
                    "sno": [
                        "36",
                        "91",
                        "234",
                        "235",
                        "257",
                        "362",
                        "378",
                        "445",
                        "518"
                    ]
                },
                {
                    "name": "Dubai World Trade Centre",
                    "count": 8,
                    "ischeck": false,
                    "sno": [
                        "36",
                        "91",
                        "234",
                        "235",
                        "337",
                        "341",
                        "362",
                        "518"
                    ]
                },
                {
                    "name": "Jebel Ali Race Course",
                    "count": 12,
                    "ischeck": false,
                    "sno": [
                        "38",
                        "39",
                        "65",
                        "69",
                        "169",
                        "188",
                        "247",
                        "253",
                        "267",
                        "271",
                        "294",
                        "329"
                    ]
                },
                {
                    "name": "Al Barsha Pond Park",
                    "count": 4,
                    "ischeck": false,
                    "sno": [
                        "52",
                        "67",
                        "73",
                        "602"
                    ]
                },
                {
                    "name": "The Outlet Village by Meraas",
                    "count": 1,
                    "ischeck": false,
                    "sno": [
                        "53"
                    ]
                },
                {
                    "name": "Legoland® Dubai",
                    "count": 2,
                    "ischeck": false,
                    "sno": [
                        "53",
                        "449"
                    ]
                },
                {
                    "name": "Dubai Parks and Resorts",
                    "count": 1,
                    "ischeck": false,
                    "sno": [
                        "53"
                    ]
                },
                {
                    "name": "Khalid Lake",
                    "count": 8,
                    "ischeck": false,
                    "sno": [
                        "54",
                        "154",
                        "164",
                        "167",
                        "230",
                        "316",
                        "333",
                        "347"
                    ]
                },
                {
                    "name": "Sharjah Corniche Hospital",
                    "count": 3,
                    "ischeck": false,
                    "sno": [
                        "54",
                        "167",
                        "316"
                    ]
                },
                {
                    "name": "Crystal Plaza",
                    "count": 5,
                    "ischeck": false,
                    "sno": [
                        "54",
                        "167",
                        "243",
                        "316",
                        "345"
                    ]
                },
                {
                    "name": "Children's City",
                    "count": 21,
                    "ischeck": false,
                    "sno": [
                        "57",
                        "59",
                        "106",
                        "121",
                        "129",
                        "136",
                        "140",
                        "152",
                        "163",
                        "166",
                        "220",
                        "221",
                        "241",
                        "261",
                        "272",
                        "286",
                        "304",
                        "346",
                        "372",
                        "502",
                        "596"
                    ]
                },
                {
                    "name": "Ajman Museum",
                    "count": 4,
                    "ischeck": false,
                    "sno": [
                        "61",
                        "485",
                        "504",
                        "542"
                    ]
                },
                {
                    "name": "Jumeirah Mosque",
                    "count": 2,
                    "ischeck": false,
                    "sno": [
                        "62",
                        "72"
                    ]
                },
                {
                    "name": "Iranian Mosque",
                    "count": 1,
                    "ischeck": false,
                    "sno": [
                        "62"
                    ]
                },
                {
                    "name": "Dubai Science Park",
                    "count": 2,
                    "ischeck": false,
                    "sno": [
                        "64",
                        "299"
                    ]
                },
                {
                    "name": "Derby Art Gallery",
                    "count": 8,
                    "ischeck": false,
                    "sno": [
                        "68",
                        "275",
                        "340",
                        "365",
                        "387",
                        "388",
                        "555",
                        "578"
                    ]
                },
                {
                    "name": "Al Hana Shopping Centre",
                    "count": 5,
                    "ischeck": false,
                    "sno": [
                        "72",
                        "125",
                        "235",
                        "264",
                        "306"
                    ]
                },
                {
                    "name": "St. Gregory the illuminator Armenian Church",
                    "count": 3,
                    "ischeck": false,
                    "sno": [
                        "74",
                        "88",
                        "549"
                    ]
                },
                {
                    "name": "Sharjah Museum of Islamic Civilisation",
                    "count": 2,
                    "ischeck": false,
                    "sno": [
                        "74",
                        "549"
                    ]
                },
                {
                    "name": "Sharjah Art Museum",
                    "count": 3,
                    "ischeck": false,
                    "sno": [
                        "74",
                        "88",
                        "549"
                    ]
                },
                {
                    "name": "Very Special Arts Centre",
                    "count": 3,
                    "ischeck": false,
                    "sno": [
                        "74",
                        "88",
                        "549"
                    ]
                },
                {
                    "name": "Islamic Museum",
                    "count": 2,
                    "ischeck": false,
                    "sno": [
                        "74",
                        "549"
                    ]
                },
                {
                    "name": "Al Hisn Sharjah Fort",
                    "count": 3,
                    "ischeck": false,
                    "sno": [
                        "74",
                        "88",
                        "549"
                    ]
                },
                {
                    "name": "Emirates Fine Arts Society",
                    "count": 3,
                    "ischeck": false,
                    "sno": [
                        "74",
                        "88",
                        "549"
                    ]
                },
                {
                    "name": "Rolla Mall",
                    "count": 3,
                    "ischeck": false,
                    "sno": [
                        "74",
                        "88",
                        "549"
                    ]
                },
                {
                    "name": "Corniche Mosque",
                    "count": 2,
                    "ischeck": false,
                    "sno": [
                        "74",
                        "336"
                    ]
                },
                {
                    "name": "Dubai Marina",
                    "count": 50,
                    "ischeck": false,
                    "sno": [
                        "79",
                        "161",
                        "193",
                        "197",
                        "201",
                        "216",
                        "224",
                        "233",
                        "256",
                        "265",
                        "277",
                        "298",
                        "335",
                        "342",
                        "351",
                        "361",
                        "369",
                        "383",
                        "394",
                        "398",
                        "405",
                        "406",
                        "412",
                        "415",
                        "420",
                        "441",
                        "447",
                        "450",
                        "470",
                        "475",
                        "491",
                        "494",
                        "496",
                        "515",
                        "517",
                        "520",
                        "521",
                        "527",
                        "536",
                        "541",
                        "545",
                        "552",
                        "554",
                        "561",
                        "563",
                        "565",
                        "570",
                        "571",
                        "579",
                        "583"
                    ]
                },
                {
                    "name": "American University of Dubai (AUD)",
                    "count": 7,
                    "ischeck": false,
                    "sno": [
                        "79",
                        "193",
                        "335",
                        "406",
                        "545",
                        "561",
                        "583"
                    ]
                },
                {
                    "name": "Dragon Mart",
                    "count": 2,
                    "ischeck": false,
                    "sno": [
                        "83",
                        "146"
                    ]
                },
                {
                    "name": "Trump International Golf Club, Dubai",
                    "count": 1,
                    "ischeck": false,
                    "sno": [
                        "85"
                    ]
                },
                {
                    "name": "King Faisal Mosque",
                    "count": 4,
                    "ischeck": false,
                    "sno": [
                        "88",
                        "243",
                        "316",
                        "345"
                    ]
                },
                {
                    "name": "Sharjah Maritime Museum",
                    "count": 5,
                    "ischeck": false,
                    "sno": [
                        "89",
                        "109",
                        "187",
                        "260",
                        "395"
                    ]
                },
                {
                    "name": "Sharjah Aquarium",
                    "count": 5,
                    "ischeck": false,
                    "sno": [
                        "89",
                        "109",
                        "187",
                        "260",
                        "395"
                    ]
                },
                {
                    "name": "Dubai Police Museum",
                    "count": 7,
                    "ischeck": false,
                    "sno": [
                        "95",
                        "115",
                        "140",
                        "186",
                        "217",
                        "478",
                        "490"
                    ]
                },
                {
                    "name": "Raffles Botanical Garden",
                    "count": 7,
                    "ischeck": false,
                    "sno": [
                        "95",
                        "115",
                        "140",
                        "186",
                        "217",
                        "478",
                        "490"
                    ]
                },
                {
                    "name": "Kenza Art Gallery",
                    "count": 7,
                    "ischeck": false,
                    "sno": [
                        "95",
                        "115",
                        "140",
                        "186",
                        "217",
                        "478",
                        "490"
                    ]
                },
                {
                    "name": "Hyatt Regency Dubai Golf Park",
                    "count": 7,
                    "ischeck": false,
                    "sno": [
                        "95",
                        "115",
                        "140",
                        "186",
                        "217",
                        "478",
                        "490"
                    ]
                },
                {
                    "name": "Els Golf Course",
                    "count": 7,
                    "ischeck": false,
                    "sno": [
                        "95",
                        "115",
                        "140",
                        "186",
                        "217",
                        "478",
                        "490"
                    ]
                },
                {
                    "name": "Safeer Mall",
                    "count": 1,
                    "ischeck": false,
                    "sno": [
                        "98"
                    ]
                },
                {
                    "name": "Dubai College",
                    "count": 1,
                    "ischeck": false,
                    "sno": [
                        "100"
                    ]
                },
                {
                    "name": "Al Seef",
                    "count": 6,
                    "ischeck": false,
                    "sno": [
                        "103",
                        "113",
                        "144",
                        "176",
                        "250",
                        "344"
                    ]
                },
                {
                    "name": "American Hospital Dubai",
                    "count": 6,
                    "ischeck": false,
                    "sno": [
                        "108",
                        "276",
                        "292",
                        "326",
                        "391",
                        "506"
                    ]
                },
                {
                    "name": "Lamcy Plaza",
                    "count": 5,
                    "ischeck": false,
                    "sno": [
                        "108",
                        "276",
                        "292",
                        "326",
                        "391"
                    ]
                },
                {
                    "name": "St. Mary's Church",
                    "count": 5,
                    "ischeck": false,
                    "sno": [
                        "108",
                        "276",
                        "326",
                        "368",
                        "506"
                    ]
                },
                {
                    "name": "Sharjah Mega Mall",
                    "count": 2,
                    "ischeck": false,
                    "sno": [
                        "118",
                        "345"
                    ]
                },
                {
                    "name": "Sharjah Gold Center",
                    "count": 1,
                    "ischeck": false,
                    "sno": [
                        "118"
                    ]
                },
                {
                    "name": "Ibn Battuta Mall",
                    "count": 2,
                    "ischeck": false,
                    "sno": [
                        "126",
                        "323"
                    ]
                },
                {
                    "name": "Dubai Water Canal",
                    "count": 26,
                    "ischeck": false,
                    "sno": [
                        "128",
                        "214",
                        "268",
                        "315",
                        "325",
                        "331",
                        "363",
                        "385",
                        "389",
                        "401",
                        "410",
                        "419",
                        "454",
                        "457",
                        "466",
                        "480",
                        "516",
                        "526",
                        "528",
                        "540",
                        "562",
                        "566",
                        "587",
                        "590",
                        "591",
                        "609"
                    ]
                },
                {
                    "name": "Zabeel Park",
                    "count": 2,
                    "ischeck": false,
                    "sno": [
                        "133",
                        "263"
                    ]
                },
                {
                    "name": "Dubai Frame",
                    "count": 2,
                    "ischeck": false,
                    "sno": [
                        "133",
                        "263"
                    ]
                },
                {
                    "name": "Al-Maktoum Stadium",
                    "count": 2,
                    "ischeck": false,
                    "sno": [
                        "145",
                        "149"
                    ]
                },
                {
                    "name": "Wafi City Mall",
                    "count": 6,
                    "ischeck": false,
                    "sno": [
                        "147",
                        "326",
                        "479",
                        "497",
                        "506",
                        "582"
                    ]
                },
                {
                    "name": "Mohammed Bin Rashid University Of Medicine and Health Sciences",
                    "count": 8,
                    "ischeck": false,
                    "sno": [
                        "147",
                        "326",
                        "367",
                        "368",
                        "479",
                        "497",
                        "506",
                        "582"
                    ]
                },
                {
                    "name": "Children’s City",
                    "count": 4,
                    "ischeck": false,
                    "sno": [
                        "147",
                        "367",
                        "368",
                        "611"
                    ]
                },
                {
                    "name": "Al Majaz Amphitheatre",
                    "count": 3,
                    "ischeck": false,
                    "sno": [
                        "154",
                        "230",
                        "347"
                    ]
                },
                {
                    "name": "The Beach Mall",
                    "count": 22,
                    "ischeck": false,
                    "sno": [
                        "161",
                        "224",
                        "233",
                        "256",
                        "277",
                        "298",
                        "351",
                        "394",
                        "398",
                        "405",
                        "412",
                        "441",
                        "447",
                        "470",
                        "475",
                        "491",
                        "515",
                        "521",
                        "536",
                        "554",
                        "570",
                        "579"
                    ]
                },
                {
                    "name": "The Walk",
                    "count": 49,
                    "ischeck": false,
                    "sno": [
                        "161",
                        "197",
                        "201",
                        "216",
                        "224",
                        "233",
                        "255",
                        "256",
                        "265",
                        "277",
                        "298",
                        "351",
                        "361",
                        "369",
                        "394",
                        "398",
                        "405",
                        "412",
                        "420",
                        "441",
                        "447",
                        "450",
                        "456",
                        "461",
                        "462",
                        "470",
                        "472",
                        "475",
                        "491",
                        "494",
                        "500",
                        "501",
                        "515",
                        "517",
                        "520",
                        "521",
                        "527",
                        "529",
                        "536",
                        "550",
                        "552",
                        "554",
                        "558",
                        "563",
                        "565",
                        "570",
                        "574",
                        "579",
                        "594"
                    ]
                },
                {
                    "name": "Yoshis Gaming Lounge",
                    "count": 21,
                    "ischeck": false,
                    "sno": [
                        "161",
                        "224",
                        "233",
                        "277",
                        "298",
                        "351",
                        "394",
                        "398",
                        "405",
                        "412",
                        "441",
                        "447",
                        "470",
                        "475",
                        "491",
                        "515",
                        "521",
                        "536",
                        "554",
                        "570",
                        "579"
                    ]
                },
                {
                    "name": "Khaled Lake Dancing Fountain",
                    "count": 3,
                    "ischeck": false,
                    "sno": [
                        "164",
                        "230",
                        "347"
                    ]
                },
                {
                    "name": "Al Majaz Park",
                    "count": 1,
                    "ischeck": false,
                    "sno": [
                        "164"
                    ]
                },
                {
                    "name": "Al Majaz Waterfront",
                    "count": 3,
                    "ischeck": false,
                    "sno": [
                        "164",
                        "230",
                        "347"
                    ]
                },
                {
                    "name": "Central Souq",
                    "count": 3,
                    "ischeck": false,
                    "sno": [
                        "167",
                        "243",
                        "316"
                    ]
                },
                {
                    "name": "Blue Souq Sharjah",
                    "count": 3,
                    "ischeck": false,
                    "sno": [
                        "167",
                        "243",
                        "316"
                    ]
                },
                {
                    "name": "Sharjah Gold Souq",
                    "count": 3,
                    "ischeck": false,
                    "sno": [
                        "167",
                        "243",
                        "316"
                    ]
                },
                {
                    "name": "Al Noor Island",
                    "count": 2,
                    "ischeck": false,
                    "sno": [
                        "167",
                        "316"
                    ]
                },
                {
                    "name": "Al Noor Mosque",
                    "count": 2,
                    "ischeck": false,
                    "sno": [
                        "167",
                        "316"
                    ]
                },
                {
                    "name": "Al Nasr Leisureland",
                    "count": 2,
                    "ischeck": false,
                    "sno": [
                        "174",
                        "326"
                    ]
                },
                {
                    "name": "Al Maktoum Stadium",
                    "count": 2,
                    "ischeck": false,
                    "sno": [
                        "174",
                        "326"
                    ]
                },
                {
                    "name": "Expo Centre Sharjah",
                    "count": 1,
                    "ischeck": false,
                    "sno": [
                        "178"
                    ]
                },
                {
                    "name": "Dubai Silicon Oasis",
                    "count": 2,
                    "ischeck": false,
                    "sno": [
                        "181",
                        "349"
                    ]
                },
                {
                    "name": "Bluewaters Island",
                    "count": 2,
                    "ischeck": false,
                    "sno": [
                        "197",
                        "571"
                    ]
                },
                {
                    "name": "Dubai Marina Mall",
                    "count": 15,
                    "ischeck": false,
                    "sno": [
                        "201",
                        "255",
                        "256",
                        "277",
                        "351",
                        "361",
                        "394",
                        "420",
                        "441",
                        "447",
                        "462",
                        "515",
                        "520",
                        "565",
                        "594"
                    ]
                },
                {
                    "name": "Thrill Zone",
                    "count": 21,
                    "ischeck": false,
                    "sno": [
                        "201",
                        "256",
                        "277",
                        "351",
                        "361",
                        "369",
                        "383",
                        "394",
                        "412",
                        "420",
                        "441",
                        "447",
                        "450",
                        "515",
                        "517",
                        "520",
                        "521",
                        "563",
                        "565",
                        "570",
                        "579"
                    ]
                },
                {
                    "name": "Jumeirah Beach",
                    "count": 34,
                    "ischeck": false,
                    "sno": [
                        "201",
                        "224",
                        "233",
                        "255",
                        "256",
                        "298",
                        "351",
                        "361",
                        "369",
                        "394",
                        "398",
                        "405",
                        "412",
                        "420",
                        "441",
                        "447",
                        "456",
                        "461",
                        "462",
                        "470",
                        "475",
                        "491",
                        "500",
                        "501",
                        "515",
                        "521",
                        "529",
                        "554",
                        "558",
                        "570",
                        "571",
                        "574",
                        "579",
                        "594"
                    ]
                },
                {
                    "name": "Mohammad Bin Ahmed Al Mulla Mosque",
                    "count": 20,
                    "ischeck": false,
                    "sno": [
                        "201",
                        "256",
                        "335",
                        "361",
                        "369",
                        "394",
                        "412",
                        "420",
                        "441",
                        "447",
                        "450",
                        "517",
                        "520",
                        "521",
                        "527",
                        "552",
                        "563",
                        "565",
                        "570",
                        "579"
                    ]
                },
                {
                    "name": "Maraya Art Centre",
                    "count": 3,
                    "ischeck": false,
                    "sno": [
                        "207",
                        "230",
                        "347"
                    ]
                },
                {
                    "name": "Al Qasba",
                    "count": 3,
                    "ischeck": false,
                    "sno": [
                        "207",
                        "230",
                        "347"
                    ]
                },
                {
                    "name": "Mina Rashid Marina",
                    "count": 1,
                    "ischeck": false,
                    "sno": [
                        "217"
                    ]
                },
                {
                    "name": "Dubai Cruise Terminal",
                    "count": 1,
                    "ischeck": false,
                    "sno": [
                        "217"
                    ]
                },
                {
                    "name": "Coca-Cola Arena",
                    "count": 4,
                    "ischeck": false,
                    "sno": [
                        "218",
                        "320",
                        "467",
                        "598"
                    ]
                },
                {
                    "name": "The Green Planet Dubai",
                    "count": 3,
                    "ischeck": false,
                    "sno": [
                        "218",
                        "467",
                        "598"
                    ]
                },
                {
                    "name": "City Walk",
                    "count": 4,
                    "ischeck": false,
                    "sno": [
                        "218",
                        "467",
                        "488",
                        "598"
                    ]
                },
                {
                    "name": "Al Twar Center",
                    "count": 1,
                    "ischeck": false,
                    "sno": [
                        "244"
                    ]
                },
                {
                    "name": "Dubai Sports City",
                    "count": 1,
                    "ischeck": false,
                    "sno": [
                        "245"
                    ]
                },
                {
                    "name": "Safa Park",
                    "count": 1,
                    "ischeck": false,
                    "sno": [
                        "268"
                    ]
                },
                {
                    "name": "Safestway Art Gallery",
                    "count": 2,
                    "ischeck": false,
                    "sno": [
                        "268",
                        "320"
                    ]
                },
                {
                    "name": "Zabeel Stadium",
                    "count": 1,
                    "ischeck": false,
                    "sno": [
                        "281"
                    ]
                },
                {
                    "name": "Dubai Mall",
                    "count": 12,
                    "ischeck": false,
                    "sno": [
                        "290",
                        "376",
                        "458",
                        "459",
                        "487",
                        "514",
                        "526",
                        "566",
                        "569",
                        "590",
                        "595",
                        "603"
                    ]
                },
                {
                    "name": "KidZania",
                    "count": 9,
                    "ischeck": false,
                    "sno": [
                        "290",
                        "376",
                        "487",
                        "514",
                        "526",
                        "562",
                        "572",
                        "590",
                        "595"
                    ]
                },
                {
                    "name": "Emaar Square",
                    "count": 10,
                    "ischeck": false,
                    "sno": [
                        "290",
                        "314",
                        "320",
                        "376",
                        "495",
                        "514",
                        "523",
                        "573",
                        "595",
                        "603"
                    ]
                },
                {
                    "name": "SEGA Republic",
                    "count": 19,
                    "ischeck": false,
                    "sno": [
                        "290",
                        "325",
                        "376",
                        "454",
                        "455",
                        "457",
                        "466",
                        "487",
                        "514",
                        "516",
                        "524",
                        "526",
                        "528",
                        "562",
                        "572",
                        "587",
                        "590",
                        "591",
                        "595"
                    ]
                },
                {
                    "name": "Dubai Aquarium & Underwater Zoo",
                    "count": 17,
                    "ischeck": false,
                    "sno": [
                        "290",
                        "376",
                        "458",
                        "459",
                        "487",
                        "514",
                        "526",
                        "528",
                        "562",
                        "566",
                        "569",
                        "573",
                        "587",
                        "590",
                        "595",
                        "603",
                        "609"
                    ]
                },
                {
                    "name": "Burj Khalifa",
                    "count": 9,
                    "ischeck": false,
                    "sno": [
                        "290",
                        "314",
                        "376",
                        "495",
                        "514",
                        "523",
                        "573",
                        "595",
                        "603"
                    ]
                },
                {
                    "name": "Dubai Ice Rink",
                    "count": 9,
                    "ischeck": false,
                    "sno": [
                        "290",
                        "376",
                        "487",
                        "514",
                        "526",
                        "573",
                        "590",
                        "595",
                        "603"
                    ]
                },
                {
                    "name": "Sahara Centre",
                    "count": 1,
                    "ischeck": false,
                    "sno": [
                        "296"
                    ]
                },
                {
                    "name": "La Mer South Beach",
                    "count": 3,
                    "ischeck": false,
                    "sno": [
                        "312",
                        "339",
                        "416"
                    ]
                },
                {
                    "name": "La Mer North Beach",
                    "count": 3,
                    "ischeck": false,
                    "sno": [
                        "312",
                        "339",
                        "416"
                    ]
                },
                {
                    "name": "Dubai Opera",
                    "count": 14,
                    "ischeck": false,
                    "sno": [
                        "314",
                        "440",
                        "457",
                        "464",
                        "466",
                        "468",
                        "495",
                        "523",
                        "524",
                        "562",
                        "573",
                        "592",
                        "595",
                        "603"
                    ]
                },
                {
                    "name": "Dubai Fountain",
                    "count": 24,
                    "ischeck": false,
                    "sno": [
                        "314",
                        "315",
                        "385",
                        "440",
                        "454",
                        "455",
                        "457",
                        "466",
                        "487",
                        "495",
                        "516",
                        "524",
                        "526",
                        "528",
                        "562",
                        "566",
                        "569",
                        "572",
                        "587",
                        "590",
                        "591",
                        "592",
                        "603",
                        "609"
                    ]
                },
                {
                    "name": "Al Bustan Center",
                    "count": 1,
                    "ischeck": false,
                    "sno": [
                        "324"
                    ]
                },
                {
                    "name": "Rashed Stadium",
                    "count": 1,
                    "ischeck": false,
                    "sno": [
                        "324"
                    ]
                },
                {
                    "name": "Sharjah Ladies Club",
                    "count": 1,
                    "ischeck": false,
                    "sno": [
                        "336"
                    ]
                },
                {
                    "name": "Mercato Beach",
                    "count": 2,
                    "ischeck": false,
                    "sno": [
                        "339",
                        "604"
                    ]
                },
                {
                    "name": "Al Mahatta Museum",
                    "count": 1,
                    "ischeck": false,
                    "sno": [
                        "345"
                    ]
                },
                {
                    "name": "Creekside Park",
                    "count": 2,
                    "ischeck": false,
                    "sno": [
                        "367",
                        "611"
                    ]
                },
                {
                    "name": "Skydive Dubai",
                    "count": 1,
                    "ischeck": false,
                    "sno": [
                        "369"
                    ]
                },
                {
                    "name": "UAQ National Museum",
                    "count": 1,
                    "ischeck": false,
                    "sno": [
                        "371"
                    ]
                },
                {
                    "name": "Umm Al Quwain Museum",
                    "count": 1,
                    "ischeck": false,
                    "sno": [
                        "371"
                    ]
                },
                {
                    "name": "Ras Al Khor Wildlife Sanctury",
                    "count": 1,
                    "ischeck": false,
                    "sno": [
                        "377"
                    ]
                },
                {
                    "name": "Wild Wadi Water Park",
                    "count": 7,
                    "ischeck": false,
                    "sno": [
                        "392",
                        "544",
                        "581",
                        "584",
                        "585",
                        "593",
                        "612"
                    ]
                },
                {
                    "name": "Umm Suqeim Beach",
                    "count": 3,
                    "ischeck": false,
                    "sno": [
                        "392",
                        "584",
                        "593"
                    ]
                },
                {
                    "name": "Kite Beach",
                    "count": 1,
                    "ischeck": false,
                    "sno": [
                        "392"
                    ]
                },
                {
                    "name": "Montgomerie Golf Club",
                    "count": 1,
                    "ischeck": false,
                    "sno": [
                        "407"
                    ]
                },
                {
                    "name": "Souk al Bahar",
                    "count": 20,
                    "ischeck": false,
                    "sno": [
                        "410",
                        "424",
                        "440",
                        "454",
                        "455",
                        "457",
                        "466",
                        "468",
                        "495",
                        "516",
                        "524",
                        "526",
                        "528",
                        "556",
                        "562",
                        "572",
                        "587",
                        "590",
                        "591",
                        "592"
                    ]
                },
                {
                    "name": "Pro Art Gallery",
                    "count": 1,
                    "ischeck": false,
                    "sno": [
                        "416"
                    ]
                },
                {
                    "name": "La Mer",
                    "count": 1,
                    "ischeck": false,
                    "sno": [
                        "416"
                    ]
                },
                {
                    "name": "Jumaira Plaza",
                    "count": 1,
                    "ischeck": false,
                    "sno": [
                        "416"
                    ]
                },
                {
                    "name": "The Village Mall",
                    "count": 1,
                    "ischeck": false,
                    "sno": [
                        "416"
                    ]
                },
                {
                    "name": "Gravity Zone",
                    "count": 3,
                    "ischeck": false,
                    "sno": [
                        "422",
                        "438",
                        "512"
                    ]
                },
                {
                    "name": "Nakheel Mall",
                    "count": 5,
                    "ischeck": false,
                    "sno": [
                        "430",
                        "442",
                        "481",
                        "483",
                        "505"
                    ]
                },
                {
                    "name": "Dubai Festival City Mall",
                    "count": 3,
                    "ischeck": false,
                    "sno": [
                        "433",
                        "444",
                        "492"
                    ]
                },
                {
                    "name": "Festival Center",
                    "count": 3,
                    "ischeck": false,
                    "sno": [
                        "433",
                        "444",
                        "492"
                    ]
                },
                {
                    "name": "Meydan Racecourse",
                    "count": 1,
                    "ischeck": false,
                    "sno": [
                        "439"
                    ]
                },
                {
                    "name": "Ajman Beach",
                    "count": 2,
                    "ischeck": false,
                    "sno": [
                        "446",
                        "608"
                    ]
                },
                {
                    "name": "Umm Al Quwain- Beach",
                    "count": 1,
                    "ischeck": false,
                    "sno": [
                        "452"
                    ]
                },
                {
                    "name": "Aquaventure",
                    "count": 1,
                    "ischeck": false,
                    "sno": [
                        "499"
                    ]
                },
                {
                    "name": "Dubai Garden Glow",
                    "count": 1,
                    "ischeck": false,
                    "sno": [
                        "518"
                    ]
                },
                {
                    "name": "Meem Gallery",
                    "count": 1,
                    "ischeck": false,
                    "sno": [
                        "537"
                    ]
                },
                {
                    "name": "Madinat Jumeirah",
                    "count": 6,
                    "ischeck": false,
                    "sno": [
                        "544",
                        "581",
                        "584",
                        "585",
                        "593",
                        "612"
                    ]
                },
                {
                    "name": "Burj Al Arab",
                    "count": 4,
                    "ischeck": false,
                    "sno": [
                        "544",
                        "584",
                        "593",
                        "612"
                    ]
                },
                {
                    "name": "Souk Madinat Jumeirah",
                    "count": 5,
                    "ischeck": false,
                    "sno": [
                        "544",
                        "581",
                        "585",
                        "593",
                        "612"
                    ]
                },
                {
                    "name": "Sharjah Heritage Museum",
                    "count": 1,
                    "ischeck": false,
                    "sno": [
                        "549"
                    ]
                },
                {
                    "name": "Sharjah Calligraphy Museum",
                    "count": 1,
                    "ischeck": false,
                    "sno": [
                        "549"
                    ]
                },
                {
                    "name": "Souq Al Arsah",
                    "count": 1,
                    "ischeck": false,
                    "sno": [
                        "549"
                    ]
                },
                {
                    "name": "Bollywood Parks™ Dubai",
                    "count": 1,
                    "ischeck": false,
                    "sno": [
                        "557"
                    ]
                },
                {
                    "name": "MOTIONGATE™ Dubai",
                    "count": 1,
                    "ischeck": false,
                    "sno": [
                        "557"
                    ]
                },
                {
                    "name": "Legoland® Water Park",
                    "count": 1,
                    "ischeck": false,
                    "sno": [
                        "557"
                    ]
                },
                {
                    "name": "Ain Dubai",
                    "count": 1,
                    "ischeck": false,
                    "sno": [
                        "571"
                    ]
                },
                {
                    "name": "Jumeirah Archaeological Site",
                    "count": 1,
                    "ischeck": false,
                    "sno": [
                        "599"
                    ]
                },
                {
                    "name": "Emirates hospital",
                    "count": 1,
                    "ischeck": false,
                    "sno": [
                        "599"
                    ]
                },
                {
                    "name": "Club Rush",
                    "count": 1,
                    "ischeck": false,
                    "sno": [
                        "600"
                    ]
                },
                {
                    "name": "Dubai International Art Centre",
                    "count": 1,
                    "ischeck": false,
                    "sno": [
                        "604"
                    ]
                },
                {
                    "name": "Mercato Shopping Mall",
                    "count": 1,
                    "ischeck": false,
                    "sno": [
                        "604"
                    ]
                },
                {
                    "name": "La Mer Water Park",
                    "count": 1,
                    "ischeck": false,
                    "sno": [
                        "604"
                    ]
                },
                {
                    "name": "Dubai Creek",
                    "count": 1,
                    "ischeck": false,
                    "sno": [
                        "611"
                    ]
                },
                {
                    "name": "Creek Park",
                    "count": 1,
                    "ischeck": false,
                    "sno": [
                        "611"
                    ]
                },
                {
                    "name": "Dubai Creek Golf & Yacht Club",
                    "count": 1,
                    "ischeck": false,
                    "sno": [
                        "611"
                    ]
                }
            ]
        },
        "tds_per": 10
    }
}


export const HOTEL_CONTENT = {
    "data": {
      "suc": true,
      "err": false,
      "data": {
        "echo": "wec14jism8",
        "cha": "B2B",
        "cur": "INR",
        "bid": "undefined",
        "rty": "prid",
        "pid": "3033052",
        "hid": "OP3TvPmkO3wQF2xk_1",
        "rooms": [
          {
            "rmid": "201748766",
            "rnm": "Armani Executive Suite",
            "hs": 0,
            "rmpr": [
              {
                "tid": "blmoryzJIv377FSx",
                "pid": "3033052",
                "prcheck": "/v3/properties/3033052/rooms/201748766/rates/208686491?token=F~OjogZCw0NhkyAQ9PYWUCZ3kgD2gCCVAKS1YBFAcNITJUAVQAGAlcTgdVc2dhNXdkHQQGUFwHUAIFFAcCBVIbBgcHBR5UBwQMGltSUlcMUgJaVQ0CBT5BB1tRUQYAWVcVAFAFVxpVVlFeGVwDV1kfVFgDBQFXAV8GVldQVDJQA1MOBQVBNAACVAdCZ1K-YDEDq2A8AlULUwn7NGsDFC0KXFIVcAACl2MbbhoTEk1DURBbEnoLDFEgBlUWTkdCXElcEQ1AfA1YXhpNFkhGVQhBUE1JQA8JTG7LNTWCZTAGUflkZQNRXVEeWFfYYjDcZDWTOTawZjajNmKsZDfFZDbMOkFUDFYHUwVSD0tSAwwKSAFXAlQVBFUOWRpRUlBRB1oGAQdVUVGAMnwHUQoKGVULHVAHZwZWXwIAXARfFwENVgIEXAsBVTmKMmFGVtOdOzM=",
                "prcheck_method": "GET",
                "rtid": "208686491",
                "refundable": true,
                "freeCancelDate": "2023-08-27T16:30:00",
                "rsize": "",
                "ra": [
                  {
                    "id": "2109",
                    "name": "Free self parking"
                  },
                  {
                    "id": "1073742786",
                    "name": "Free breakfast"
                  }
                ],
                "bed": "1 King Bed",
                "canpol": [
                  "Free Cancellation before August 27-2023, 15:00:00 of local hotel timing(India-time : August 27-2023, 16:30:00).",
                  "1 night(s) amount will be debited if cancelled between August 28-2023, 15:00:00(India-time : August 28-2023, 16:30:00) - August 29-2023, 15:00:00(India-time : August 29-2023, 16:30:00) of local hotel timing"
                ],
                "available_rooms": 11,
                "servicefee": [],
                "pr": [
                  {
                    "tf": "130777.6787",
                    "bf": 106515.64,
                    "tax": [
                      {
                        "OT": 24262.03,
                        "ST": 0,
                        "SUP_OT": 294.04,
                        "SUP_ST": 0
                      },
                      {
                        "attmkp": 0
                      },
                      {
                        "tamkp": 0
                      },
                      {
                        "stamkp": 0
                      },
                      {
                        "attdisc": 0
                      },
                      {
                        "tadisc": 0
                      },
                      {
                        "stadisc": 0
                      }
                    ],
                    "per_night": 119994.92,
                    "ty": "lux",
                    "disc": 44144.29,
                    "dwtf": 174921.97,
                    "cur": "USD",
                    "sup_fare": "1290.9_1584.94",
                    "package": true,
                    "ezy_deal": false,
                    "market_fee": 10006.32,
                    "sup_market_fee": 121.27,
                    "sup_per_night": 1454.26,
                    "sup_disc": 535,
                    "sup_dwtf": 45729.23,
                    "sup_usr_exc": 82.5127,
                    "sup_usr_com_exc": 82.5127,
                    "rtf": "130778.00",
                    "rdwtf": "174922.00",
                    "com_disc": 0,
                    "markupnames": []
                  }
                ]
              }
            ],
            "ocp": "1",
            "note": "Approximately INR 898.56 will be charged directly by the hotel to the guest for a stay. It is subject to hotel and city local policies"
          },
          {
            "rmid": "200017473",
            "rnm": "Armani Deluxe Room",
            "hs": 0,
            "rmpr": [
              {
                "tid": "weVbcsmBqXTrcBbQ",
                "pid": "3033052",
                "prcheck": "/v3/properties/3033052/rooms/200017473/rates/200085597?token=F~OjogZCw0NhkyAQ9PYWUCZ3kgD2gCCVAKS1YBFAcNITJUAVQAGAlcTgdVc2dhNXdkHQQGUFwHUAIFFAcCBVIbBgcHBR5UBwQMGltSUlcMUgJaVQ0CBT5BB1tRUQYAWVcVAFAFVxpVVlFeGVwDV1kfVFgDBQFXAV8GVldQVDJQA1MOBQVBNAACVAdCZ1K-YDEDq2A8AlULUwn7NGsDFC0KXFIVcAACl2MbbhoTEk1DURBbEnoLDFEgBlUWTkdCXElcEQ1AfA1YXhpNFkhGVQhBUE1JQA8JTG7LNTWCZTAGUflkZApdVEsFVYM5Ytk1YcFiOrw6ZacxNfs2ZJVnZ51kHFVcVlACAgRbGgVUXgcUUQNSBExZBw9XFVQFVlBSVVpVUgcFBoYwLlAFUwsUBF0eAVdlAgVeVAsCVA9ICQUKUwYOVw0DONkyMhUDTIFPwg==",
                "prcheck_method": "GET",
                "rtid": "200085597",
                "refundable": true,
                "freeCancelDate": "2023-08-27T16:30:00",
                "rsize": "",
                "ra": [
                  {
                    "id": "2109",
                    "name": "Free self parking"
                  }
                ],
                "bed": "1 King Bed",
                "canpol": [
                  "Free Cancellation before August 27-2023, 15:00:00 of local hotel timing(India-time : August 27-2023, 16:30:00).",
                  "1 night(s) amount will be debited if cancelled between August 28-2023, 15:00:00(India-time : August 28-2023, 16:30:00) - August 29-2023, 15:00:00(India-time : August 29-2023, 16:30:00) of local hotel timing"
                ],
                "available_rooms": 11,
                "servicefee": [],
                "pr": [
                  {
                    "tf": "73563.3726",
                    "bf": 59914.95,
                    "tax": [
                      {
                        "OT": 13648.43,
                        "ST": 0,
                        "SUP_OT": 165.41,
                        "SUP_ST": 0
                      },
                      {
                        "attmkp": 0
                      },
                      {
                        "tamkp": 0
                      },
                      {
                        "stamkp": 0
                      },
                      {
                        "attdisc": 0
                      },
                      {
                        "tadisc": 0
                      },
                      {
                        "stadisc": 0
                      }
                    ],
                    "per_night": 67497.86,
                    "ty": "lux",
                    "disc": 24836.32,
                    "dwtf": 98399.69,
                    "cur": "USD",
                    "sup_fare": "726.13_891.54",
                    "package": true,
                    "ezy_deal": false,
                    "market_fee": 5629.02,
                    "sup_market_fee": 68.22,
                    "sup_per_night": 818.03,
                    "sup_disc": 301,
                    "sup_dwtf": 25727.86,
                    "sup_usr_exc": 82.5127,
                    "sup_usr_com_exc": 82.5127,
                    "rtf": "73563.00",
                    "rdwtf": "98400.00",
                    "com_disc": 0,
                    "markupnames": []
                  }
                ]
              },
              {
                "tid": "lN7VizGV12WhHqv6",
                "pid": "3033052",
                "prcheck": "/v3/properties/3033052/rooms/200017473/rates/209716841?token=F~OjogZCw0NhkyAQ9PYWUCZ3kgD2gCCVAKS1YBFAcNITJUAVQAGAlcTgdVc2dhNXdkHQQGUFwHUAIFFAcCBVIbBgcHBR5UBwQMGltSUlcMUgJaVQ0CBT5BB1tRUQYAWVcVAFAFVxpVVlFeGVwDV1kfVFgDBQFXAV8GVldQVDJQA1MOBQVBNAACVAdCZ1K-YDEDq2A8AlULUwn7NGsDFC0KXFIVcAACl2MbbhoTEk1DURBbEnoLDFEgBlUWTkdCXElcEQ1AfA1YXhpNFkhGVQhBUE1JQA8JTG7LNTWCZTAGUflkZQNUVlYeUFrYYjDcZDWTOTawZjajNmKsZDfFZDbMOkFUDFYHUwVSD0tSAwwKSAFXAlQVBFUOWRpRUlBRB1oGAQdVUVGAMnwHUQoKGVULHVAHZwZWXwIAXARfFwENVgIEXAsBVTmKMmFGVk7xsjg=",
                "prcheck_method": "GET",
                "rtid": "209716841",
                "refundable": true,
                "freeCancelDate": "2023-08-27T16:30:00",
                "rsize": "",
                "ra": [
                  {
                    "id": "2109",
                    "name": "Free self parking"
                  },
                  {
                    "id": "1073742786",
                    "name": "Free breakfast"
                  }
                ],
                "bed": "1 King Bed",
                "canpol": [
                  "Free Cancellation before August 27-2023, 15:00:00 of local hotel timing(India-time : August 27-2023, 16:30:00).",
                  "1 night(s) amount will be debited if cancelled between August 28-2023, 15:00:00(India-time : August 28-2023, 16:30:00) - August 29-2023, 15:00:00(India-time : August 29-2023, 16:30:00) of local hotel timing"
                ],
                "available_rooms": 11,
                "servicefee": [],
                "pr": [
                  {
                    "tf": "85251.2965",
                    "bf": 69434.44,
                    "tax": [
                      {
                        "OT": 15816.86,
                        "ST": 0,
                        "SUP_OT": 191.69,
                        "SUP_ST": 0
                      },
                      {
                        "attmkp": 0
                      },
                      {
                        "tamkp": 0
                      },
                      {
                        "stamkp": 0
                      },
                      {
                        "attdisc": 0
                      },
                      {
                        "tadisc": 0
                      },
                      {
                        "stadisc": 0
                      }
                    ],
                    "per_night": 78222.04,
                    "ty": "lux",
                    "disc": 28796.93,
                    "dwtf": 114048.23,
                    "cur": "USD",
                    "sup_fare": "841.5_1033.19",
                    "package": true,
                    "ezy_deal": false,
                    "market_fee": 6522.63,
                    "sup_market_fee": 79.05,
                    "sup_per_night": 948,
                    "sup_disc": 349,
                    "sup_dwtf": 29830.12,
                    "sup_usr_exc": 82.5127,
                    "sup_usr_com_exc": 82.5127,
                    "rtf": "85251.00",
                    "rdwtf": "114048.00",
                    "com_disc": 0,
                    "markupnames": []
                  }
                ]
              }
            ],
            "ocp": "1",
            "note": "Approximately INR 898.56 will be charged directly by the hotel to the guest for a stay. It is subject to hotel and city local policies"
          },
          {
            "rmid": "200038661",
            "rnm": "Armani Premier Suite",
            "hs": 0,
            "rmpr": [
              {
                "tid": "khzQlymTfduW2ARf",
                "pid": "3033052",
                "prcheck": "/v3/properties/3033052/rooms/200038661/rates/200224442?token=F~OjogZCw0NhkyAQ9PYWUCZ3kgD2gCCVAKS1YBFAcNITJUAVQAGAlcTgdVc2dhNXdkHQQGUFwHUAIFFAcCBVIbBgcHBR5UBwQMGltSUlcMUgJaVQ0CBT5BB1tRUQYAWVcVAFAFVxpVVlFeGVwDV1kfVFgDBQFXAV8GVldQVDJQA1MOBQVBNAACVAdCZ1K-YDEDq2A8AlULUwn7NGsDFC0KXFIVcAACl2MbbhoTEk1DURBbEnoLDFEgBlUWTkdCXElcEQ1AfA1YXhpNFkhGVQhBUE1JQA8JTG7LNTWCZTAGUflkZQNWVl0eU1LYYjDcZDWTOTawZjajNmKsZDfFZDbMOkFUDFYHUwVSD0tSAwwKSAFXAlQVBFUOWRpRUlBRB1oGAQdVUVGAMnwHUQoKGVULHVAHZwZWXwIAXARfFwENVgIEXAsBVTmKMmFGVriYYmg=",
                "prcheck_method": "GET",
                "rtid": "200224442",
                "refundable": true,
                "freeCancelDate": "2023-08-27T16:30:00",
                "rsize": "",
                "ra": [
                  {
                    "id": "2109",
                    "name": "Free self parking"
                  },
                  {
                    "id": "1073742786",
                    "name": "Free breakfast"
                  }
                ],
                "bed": "1 King Bed",
                "canpol": [
                  "Free Cancellation before August 27-2023, 15:00:00 of local hotel timing(India-time : August 27-2023, 16:30:00).",
                  "1 night(s) amount will be debited if cancelled between August 28-2023, 15:00:00(India-time : August 28-2023, 16:30:00) - August 29-2023, 15:00:00(India-time : August 29-2023, 16:30:00) of local hotel timing"
                ],
                "available_rooms": 4,
                "servicefee": [],
                "pr": [
                  {
                    "tf": "102168.0503",
                    "bf": 83214.88,
                    "tax": [
                      {
                        "OT": 18953.17,
                        "ST": 0,
                        "SUP_OT": 229.7,
                        "SUP_ST": 0
                      },
                      {
                        "attmkp": 0
                      },
                      {
                        "tamkp": 0
                      },
                      {
                        "stamkp": 0
                      },
                      {
                        "attdisc": 0
                      },
                      {
                        "tadisc": 0
                      },
                      {
                        "stadisc": 0
                      }
                    ],
                    "per_night": 93743.5,
                    "ty": "lux",
                    "disc": 34490.31,
                    "dwtf": 136658.36,
                    "cur": "USD",
                    "sup_fare": "1008.51_1238.21",
                    "package": true,
                    "ezy_deal": false,
                    "market_fee": 7815.6,
                    "sup_market_fee": 94.72,
                    "sup_per_night": 1136.11,
                    "sup_disc": 418,
                    "sup_dwtf": 35728.52,
                    "sup_usr_exc": 82.5127,
                    "sup_usr_com_exc": 82.5127,
                    "rtf": "102168.00",
                    "rdwtf": "136658.00",
                    "com_disc": 0,
                    "markupnames": []
                  }
                ]
              }
            ],
            "ocp": "1",
            "note": "Approximately INR 898.56 will be charged directly by the hotel to the guest for a stay. It is subject to hotel and city local policies"
          },
          {
            "rmid": "200038664",
            "rnm": "Armani Fountain Suite",
            "hs": 0,
            "rmpr": [
              {
                "tid": "Evg0IFyQd18TyxeU",
                "pid": "3033052",
                "prcheck": "/v3/properties/3033052/rooms/200038664/rates/250200528?token=F~OjogZCw0NhkyAQ9PYWUCZ3kgD2gCCVAKS1YBFAcNITJUAVQAGAlcTgdVc2dhNXdkHQQGUFwHUAIFFAcCBVIbBgcHBR5UBwQMGltSUlcMUgJaVQ0CBT5BB1tRUQYAWVcVAFAFVxpVVlFeGVwDV1kfVFgDBQFXAV8GVldQVDJQA1MOBQVBNAACVAdCZ1K-YDEDq2A8AlULUwn7NGsDFC0KXFIVcAACl2MbbhoTEk1DURBbEnoLDFEgBlUWTkdCXElcEQ1AfA1YXhpNFkhGVQhBUE1JQA8JTG7LNTWCZTAGUflkZQNQXVAeWVvYYjDcZDWTOTawZjajNmKsZDfFZDbMOkFUDFYHUwVSD0tSAwwKSAFXAlQVBFUOWRpRUlBRB1oGAQdVUVGAMnwHUQoKGVULHVAHZwZWXwIAXARfFwENVgIEXAsBVTmKMmFGVrnOx5A=",
                "prcheck_method": "GET",
                "rtid": "250200528",
                "refundable": true,
                "freeCancelDate": "2023-08-27T16:30:00",
                "rsize": "",
                "ra": [
                  {
                    "id": "2109",
                    "name": "Free self parking"
                  }
                ],
                "bed": "1 King Bed",
                "canpol": [
                  "Free Cancellation before August 27-2023, 15:00:00 of local hotel timing(India-time : August 27-2023, 16:30:00).",
                  "1 night(s) amount will be debited if cancelled between August 28-2023, 15:00:00(India-time : August 28-2023, 16:30:00) - August 29-2023, 15:00:00(India-time : August 29-2023, 16:30:00) of local hotel timing"
                ],
                "available_rooms": 24,
                "servicefee": [],
                "pr": [
                  {
                    "tf": "122603.9707",
                    "bf": 99858.52,
                    "tax": [
                      {
                        "OT": 22745.45,
                        "ST": 0,
                        "SUP_OT": 275.66,
                        "SUP_ST": 0
                      },
                      {
                        "attmkp": 0
                      },
                      {
                        "tamkp": 0
                      },
                      {
                        "stamkp": 0
                      },
                      {
                        "attdisc": 0
                      },
                      {
                        "tadisc": 0
                      },
                      {
                        "stadisc": 0
                      }
                    ],
                    "per_night": 112494.51,
                    "ty": "lux",
                    "disc": 41421.38,
                    "dwtf": 164025.35,
                    "cur": "USD",
                    "sup_fare": "1210.22_1485.88",
                    "package": true,
                    "ezy_deal": false,
                    "market_fee": 9380.04,
                    "sup_market_fee": 113.68,
                    "sup_per_night": 1363.36,
                    "sup_disc": 502,
                    "sup_dwtf": 42907.26,
                    "sup_usr_exc": 82.5127,
                    "sup_usr_com_exc": 82.5127,
                    "rtf": "122604.00",
                    "rdwtf": "164025.00",
                    "com_disc": 0,
                    "markupnames": []
                  }
                ]
              }
            ],
            "ocp": "1",
            "note": "Approximately INR 898.56 will be charged directly by the hotel to the guest for a stay. It is subject to hotel and city local policies"
          },
          {
            "rmid": "200017475",
            "rnm": "Armani Classic Room",
            "hs": 0,
            "rmpr": [
              {
                "tid": "Wt0q4qQBVqEEstyL",
                "pid": "3033052",
                "prcheck": "/v3/properties/3033052/rooms/200017475/rates/200085600?token=F~OjogZCw0NhkyAQ9PYWUCZ3kgD2gCCVAKS1YBFAcNITJUAVQAGAlcTgdVc2dhNXdkHQQGUFwHUAIFFAcCBVIbBgcHBR5UBwQMGltSUlcMUgJaVQ0CBT5BB1tRUQYAWVcVAFAFVxpVVlFeGVwDV1kfVFgDBQFXAV8GVldQVDJQA1MOBQVBNAACVAdCZ1K-YDEDq2A8AlULUwn7NGsDFC0KXFIVcAACl2MbbhoTEk1DURBbEnoLDFEgBlUWTkdCXElcEQ1AfA1YXhpNFkhGVQhBUE1JQA8JTG7LNTWCZTAGUflkZAtdVUsFWYM5Ytk1YcFiOrw6ZacxNfs2ZJVnZ51kHFVcVlACAgRbGgVUXgcUUQNSBExZBw9XFVQFVlBSVVpVUgcFBoYwLlAFUwsUBF0eAVdlAgVeVAsCVA9ICQUKUwYOVw0DONkyMhUDU011_A==",
                "prcheck_method": "GET",
                "rtid": "200085600",
                "refundable": true,
                "freeCancelDate": "2023-08-27T16:30:00",
                "rsize": "",
                "ra": [
                  {
                    "id": "2109",
                    "name": "Free self parking"
                  }
                ],
                "bed": "1 King Bed",
                "canpol": [
                  "Free Cancellation before August 27-2023, 15:00:00 of local hotel timing(India-time : August 27-2023, 16:30:00).",
                  "1 night(s) amount will be debited if cancelled between August 28-2023, 15:00:00(India-time : August 28-2023, 16:30:00) - August 29-2023, 15:00:00(India-time : August 29-2023, 16:30:00) of local hotel timing"
                ],
                "available_rooms": 32,
                "servicefee": [],
                "pr": [
                  {
                    "tf": "81735.4304",
                    "bf": 66571.25,
                    "tax": [
                      {
                        "OT": 15164.18,
                        "ST": 0,
                        "SUP_OT": 183.78,
                        "SUP_ST": 0
                      },
                      {
                        "attmkp": 0
                      },
                      {
                        "tamkp": 0
                      },
                      {
                        "stamkp": 0
                      },
                      {
                        "attdisc": 0
                      },
                      {
                        "tadisc": 0
                      },
                      {
                        "stadisc": 0
                      }
                    ],
                    "per_night": 74995.79,
                    "ty": "lux",
                    "disc": 27641.75,
                    "dwtf": 109377.18,
                    "cur": "USD",
                    "sup_fare": "806.8_990.58",
                    "package": true,
                    "ezy_deal": false,
                    "market_fee": 6252.81,
                    "sup_market_fee": 75.78,
                    "sup_per_night": 908.9,
                    "sup_disc": 335,
                    "sup_dwtf": 28632.33,
                    "sup_usr_exc": 82.5127,
                    "sup_usr_com_exc": 82.5127,
                    "rtf": "81735.00",
                    "rdwtf": "109377.00",
                    "com_disc": 0,
                    "markupnames": []
                  }
                ]
              },
              {
                "tid": "UUMahtWF9mJv60gR",
                "pid": "3033052",
                "prcheck": "/v3/properties/3033052/rooms/200017475/rates/209716880?token=F~OjogZCw0NhkyAQ9PYWUCZ3kgD2gCCVAKS1YBFAcNITJUAVQAGAlcTgdVc2dhNXdkHQQGUFwHUAIFFAcCBVIbBgcHBR5UBwQMGltSUlcMUgJaVQ0CBT5BB1tRUQYAWVcVAFAFVxpVVlFeGVwDV1kfVFgDBQFXAV8GVldQVDJQA1MOBQVBNAACVAdCZ1K-YDEDq2A8AlULUwn7NGsDFC0KXFIVcAACl2MbbhoTEk1DURBbEnoLDFEgBlUWTkdCXElcEQ1AfA1YXhpNFkhGVQhBUE1JQA8JTG7LNTWCZTAGUflkZQNVVlceU1HYYjDcZDWTOTawZjajNmKsZDfFZDbMOkFUDFYHUwVSD0tSAwwKSAFXAlQVBFUOWRpRUlBRB1oGAQdVUVGAMnwHUQoKGVULHVAHZwZWXwIAXARfFwENVgIEXAsBVTmKMmFGVmg1zCw=",
                "prcheck_method": "GET",
                "rtid": "209716880",
                "refundable": true,
                "freeCancelDate": "2023-08-27T16:30:00",
                "rsize": "",
                "ra": [
                  {
                    "id": "2109",
                    "name": "Free self parking"
                  },
                  {
                    "id": "1073742786",
                    "name": "Free breakfast"
                  }
                ],
                "bed": "1 King Bed",
                "canpol": [
                  "Free Cancellation before August 27-2023, 15:00:00 of local hotel timing(India-time : August 27-2023, 16:30:00).",
                  "1 night(s) amount will be debited if cancelled between August 28-2023, 15:00:00(India-time : August 28-2023, 16:30:00) - August 29-2023, 15:00:00(India-time : August 29-2023, 16:30:00) of local hotel timing"
                ],
                "available_rooms": 32,
                "servicefee": [],
                "pr": [
                  {
                    "tf": "93422.5292",
                    "bf": 76091.56,
                    "tax": [
                      {
                        "OT": 17330.97,
                        "ST": 0,
                        "SUP_OT": 210.04,
                        "SUP_ST": 0
                      },
                      {
                        "attmkp": 0
                      },
                      {
                        "tamkp": 0
                      },
                      {
                        "stamkp": 0
                      },
                      {
                        "attdisc": 0
                      },
                      {
                        "tadisc": 0
                      },
                      {
                        "stadisc": 0
                      }
                    ],
                    "per_night": 85719.14,
                    "ty": "lux",
                    "disc": 31602.36,
                    "dwtf": 125024.89,
                    "cur": "USD",
                    "sup_fare": "922.18_1132.22",
                    "package": true,
                    "ezy_deal": false,
                    "market_fee": 7146.42,
                    "sup_market_fee": 86.61,
                    "sup_per_night": 1038.86,
                    "sup_disc": 383,
                    "sup_dwtf": 32734.58,
                    "sup_usr_exc": 82.5127,
                    "sup_usr_com_exc": 82.5127,
                    "rtf": "93423.00",
                    "rdwtf": "125025.00",
                    "com_disc": 0,
                    "markupnames": []
                  }
                ]
              }
            ],
            "ocp": "1",
            "note": "Approximately INR 898.56 will be charged directly by the hotel to the guest for a stay. It is subject to hotel and city local policies"
          },
          {
            "rmid": "201490001",
            "rnm": "Armani Signature Suite",
            "hs": 0,
            "rmpr": [
              {
                "tid": "bwgu5oW4BP8BZqAg",
                "pid": "3033052",
                "prcheck": "/v3/properties/3033052/rooms/201490001/rates/207475409?token=F~OjogZCw0NhkyAQ9PYWUCZ3kgD2gCCVAKS1YBFAcNITJUAVQAGAlcTgdVc2dhNXdkHQQGUFwHUAIFFAcCBVIbBgcHBR5UBwQMGltSUlcMUgJaVQ0CBT5BB1tRUQYAWVcVAFAFVxpVVlFeGVwDV1kfVFgDBQFXAV8GVldQVDJQA1MOBQVBNAACVAdCZ1K-YDEDq2A8AlULUwn7NGsDFC0KXFIVcAACl2MbbhoTEk1DURBbEnoLDFEgBlUWTkdCXElcEQ1AfA1YXhpNFkhGVQhBUE1JQA8JTG7LNTWCZTAGUflkZQZWVVweWFTYYjDcZDWTOTawZjajNmKsZDfFZDbMOkFUDFYHUwVSD0tSAwwKSAFXAlQVBFUOWRpRUlBRB1oGAQdVUVGAMnwHUQoKGVULHVAHZwZWXwIAXARfFwENVgIEXAsBVTmKMmFGVtPkK2k=",
                "prcheck_method": "GET",
                "rtid": "207475409",
                "refundable": true,
                "freeCancelDate": "2023-08-27T16:30:00",
                "rsize": "",
                "ra": [
                  {
                    "id": "2109",
                    "name": "Free self parking"
                  },
                  {
                    "id": "1073742786",
                    "name": "Free breakfast"
                  }
                ],
                "bed": "1 King Bed",
                "canpol": [
                  "Free Cancellation before August 27-2023, 15:00:00 of local hotel timing(India-time : August 27-2023, 16:30:00).",
                  "1 night(s) amount will be debited if cancelled between August 28-2023, 15:00:00(India-time : August 28-2023, 16:30:00) - August 29-2023, 15:00:00(India-time : August 29-2023, 16:30:00) of local hotel timing"
                ],
                "available_rooms": 3,
                "servicefee": [],
                "pr": [
                  {
                    "tf": "347375.9916",
                    "bf": 282931.1,
                    "tax": [
                      {
                        "OT": 64444.89,
                        "ST": 0,
                        "SUP_OT": 781.03,
                        "SUP_ST": 0
                      },
                      {
                        "attmkp": 0
                      },
                      {
                        "tamkp": 0
                      },
                      {
                        "stamkp": 0
                      },
                      {
                        "attdisc": 0
                      },
                      {
                        "tadisc": 0
                      },
                      {
                        "stadisc": 0
                      }
                    ],
                    "per_night": 318733.36,
                    "ty": "lux",
                    "disc": 117250.55,
                    "dwtf": 464626.54,
                    "cur": "USD",
                    "sup_fare": "3428.94_4209.97",
                    "package": true,
                    "ezy_deal": false,
                    "market_fee": 26576.52,
                    "sup_market_fee": 322.09,
                    "sup_per_night": 3862.84,
                    "sup_disc": 1421,
                    "sup_dwtf": 121460.52,
                    "sup_usr_exc": 82.5127,
                    "sup_usr_com_exc": 82.5127,
                    "rtf": "347376.00",
                    "rdwtf": "464627.00",
                    "com_disc": 0,
                    "markupnames": []
                  }
                ]
              }
            ],
            "ocp": "1",
            "note": "Approximately INR 898.56 will be charged directly by the hotel to the guest for a stay. It is subject to hotel and city local policies"
          }
        ],
        "note": "Approximately INR 898.56 will be charged directly by the hotel to the guest for a stay. It is subject to hotel and city local policies",
        "cont": {
          "_id": "600d422fa1bc371b80fe3a43",
          "name": "Armani Hotel Dubai",
          "address": {
            "line1": "Burj Khalifa, Mohamed Bin Rashid Blvd",
            "city": "Dubai",
            "countryCode": "AE",
            "obfuscationRequired": false
          },
          "ratings": "5.0",
          "location": {
            "lat": 25.197116,
            "long": 55.274058
          },
          "phone": "971-4-8883888",
          "category": {
            "id": "1",
            "name": "Hotel"
          },
          "checkin": {
            "beginTime": "3:00 PM",
            "endTime": "midnight",
            "instructions": "Extra-person charges may apply and vary depending on property policy Government-issued photo identification and a credit card, debit card, or cash deposit may be required at check-in for incidental charges Special requests are subject to availability upon check-in and may incur additional charges; special requests cannot be guaranteed This property accepts credit cards; cash is not accepted Safety features at this property include a fire extinguisher, a smoke detector, a security system, and a first aid kit Please note that cultural norms and guest policies may differ by country and by property; the policies listed are provided by the property Alcohol is not available via room service for Room (1 Bedroom Residence) and Armani Two Bedroom Residence categories.",
            "specialInstructions": "Front desk staff will greet guests on arrival. For more details, please contact the property using the information on the booking confirmation.  A tourism fee is imposed by the city and collected at the property. For hotel stays, the fee is AED 20 for the first bedroom per night, and increases by AED 20 per night for each additional bedroom. For residence stays, the fee is AED 10 for the first bedroom per night, and increases by AED 10 per night for each additional bedroom.",
            "minAge": 18
          },
          "checkout": {
            "time": "12:00 PM"
          },
          "fees": {
            "mandatory": "<p>You'll be asked to pay the following charges at the property:</p> <ul><li>A tax is imposed by the city: AED 20 per accommodation, per night</li></ul> <p>We have included all charges provided to us by the property. However, charges can vary, for example, based on length of stay or the room you book. </p>",
            "optional": "<p>The following fees and deposits are charged by the property at time of service, check-in, or check-out. </p> <ul> <li>Fee for buffet breakfast: AED 166 per person (approximately)</li>     <li>Airport shuttle fee: AED 500.00 per vehicle (one-way)</li>             <li>Rollaway bed fee: AED 250.0 per night</li>            </ul> <p>The above list may not be comprehensive. Fees and deposits may not include tax and are subject to change. </p>"
          },
          "policies": {
            "knowBeforeYouGo": "<ul>  <li>This property offers transfers from the airport (surcharges may apply). Guests must contact the property with arrival details before travel, using the contact information on the booking confirmation. </li>  <li>One child 3 years old or younger stays free when occupying the parent or guardian's room, using existing bedding. </li><li>Only registered guests are allowed in the guestrooms. </li> <li>No pets and no service animals are allowed at this property. </li><li>This property advises that enhanced cleaning and guest safety measures are currently in place.</li><li>Disinfectant is used to clean the property, and commonly-touched surfaces are cleaned with disinfectant between stays.</li><li>Personal protective equipment, including masks and gloves, will be available to guests.</li><li>Social distancing measures are in place; staff at the property wear personal protective equipment; periodic temperature checks are conducted on staff; temperature checks are available to guests; guests are provided with hand sanitizer.</li><li>Contactless check-in and contactless check-out are available.</li><li>Individually-wrapped food options are available through room service.</li> </ul>"
          },
          "attributes": {
            "pets": {
              "2050": {
                "id": "2050",
                "name": "Pets not allowed"
              },
              "2808": {
                "id": "2808",
                "name": "Service animals not allowed"
              }
            },
            "general": {
              "1073744992": {
                "id": "1073744992",
                "name": "Property is cleaned with disinfectant"
              },
              "1073744994": {
                "id": "1073744994",
                "name": "Staff wears personal protective equipment"
              },
              "1073744996": {
                "id": "1073744996",
                "name": "Protective clothing is available to guests"
              },
              "1073744997": {
                "id": "1073744997",
                "name": "Masks are available to guests"
              },
              "1073744998": {
                "id": "1073744998",
                "name": "Gloves are available to guests"
              },
              "1073745002": {
                "id": "1073745002",
                "name": "Guests are provided with free hand sanitizer"
              },
              "1073745004": {
                "id": "1073745004",
                "name": "Social distancing measures are in place"
              },
              "1073745006": {
                "id": "1073745006",
                "name": "Contactless check-in is available"
              },
              "1073745013": {
                "id": "1073745013",
                "name": "Property confirms they are implementing enhanced cleaning measures"
              },
              "1073745015": {
                "id": "1073745015",
                "name": "Individually-wrapped food options are available"
              },
              "1073745019": {
                "id": "1073745019",
                "name": "Individually-wrapped food options are available through room service"
              },
              "1073745045": {
                "id": "1073745045",
                "name": "Staff temperature checks are conducted regularly"
              },
              "1073745047": {
                "id": "1073745047",
                "name": "Temperature checks are available to guests"
              },
              "1073745051": {
                "id": "1073745051",
                "name": "Commonly-touched surfaces are cleaned with disinfectant"
              },
              "1073745053": {
                "id": "1073745053",
                "name": "Property confirms they are implementing guest safety measures"
              },
              "1073745055": {
                "id": "1073745055",
                "name": "Contactless check-out is available"
              }
            }
          },
          "amenities": {
            "3": {
              "id": "3",
              "name": "Bar/lounge"
            },
            "8": {
              "id": "8",
              "name": "Elevator"
            },
            "24": {
              "id": "24",
              "name": "Outdoor pool"
            },
            "43": {
              "id": "43",
              "name": "Concierge services"
            },
            "56": {
              "id": "56",
              "name": "Airport transportation (surcharge)"
            },
            "324": {
              "id": "324",
              "name": "Coffee/tea in common areas"
            },
            "361": {
              "id": "361",
              "name": "Breakfast available (surcharge)"
            },
            "369": {
              "id": "369",
              "name": "Laundry facilities"
            },
            "2004": {
              "id": "2004",
              "name": "Coffee shop or café"
            },
            "2008": {
              "id": "2008",
              "name": "Health club"
            },
            "2016": {
              "id": "2016",
              "name": "Safe-deposit box at front desk"
            },
            "2017": {
              "id": "2017",
              "name": "Spa services on site"
            },
            "2043": {
              "id": "2043",
              "name": "Multilingual staff"
            },
            "2047": {
              "id": "2047",
              "name": "Free newspapers in lobby"
            },
            "2063": {
              "id": "2063",
              "name": "24-hour front desk"
            },
            "2065": {
              "id": "2065",
              "name": "Business center"
            },
            "2066": {
              "id": "2066",
              "name": "Porter/bellhop"
            },
            "2070": {
              "id": "2070",
              "name": "Dry cleaning/laundry service"
            },
            "2072": {
              "id": "2072",
              "name": "Limo or Town Car service available"
            },
            "2123": {
              "id": "2123",
              "name": "Full-service spa"
            },
            "2131": {
              "id": "2131",
              "name": "Meeting rooms"
            },
            "2134": {
              "id": "2134",
              "name": "Steam room"
            },
            "2135": {
              "id": "2135",
              "name": "Sauna"
            },
            "2137": {
              "id": "2137",
              "name": "Smoke-free property"
            },
            "2167": {
              "id": "2167",
              "name": "Wedding services"
            },
            "2387": {
              "id": "2387",
              "name": "Tours/ticket assistance"
            },
            "2390": {
              "id": "2390",
              "name": "Free WiFi"
            },
            "2392": {
              "id": "2392",
              "name": "Free wired Internet"
            },
            "2423": {
              "id": "2423",
              "name": "In-room accessibility"
            },
            "2529": {
              "id": "2529",
              "name": "Area shuttle (surcharge)"
            },
            "2537": {
              "id": "2537",
              "name": "Number of restaurants - 5",
              "value": "5"
            },
            "3861": {
              "id": "3861",
              "name": "Free self parking"
            },
            "4003": {
              "id": "4003",
              "name": "Luggage storage"
            },
            "4438": {
              "id": "4438",
              "name": "Pool sun loungers"
            },
            "4514": {
              "id": "4514",
              "name": "Terrace"
            },
            "1073744110": {
              "id": "1073744110",
              "name": "Ballroom"
            },
            "1073744430": {
              "id": "1073744430",
              "name": "Wheelchair accessible (may have limitations)"
            }
          },
          "images": [
            {
              "heroImage": true,
              "category": 3,
              "links": {
                "350Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/22167ea6_b.jpg"
                },
                "70Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/22167ea6_t.jpg"
                },
                "1000Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/22167ea6_z.jpg"
                }
              },
              "caption": "Featured Image"
            },
            {
              "heroImage": false,
              "category": 10001,
              "links": {
                "350Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/96947159_b.jpg"
                },
                "70Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/96947159_t.jpg"
                },
                "1000Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/96947159_z.jpg"
                }
              },
              "caption": "Lobby"
            },
            {
              "heroImage": false,
              "category": 10003,
              "links": {
                "350Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/490690d0_b.jpg"
                },
                "70Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/490690d0_t.jpg"
                },
                "1000Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/490690d0_z.jpg"
                }
              },
              "caption": "Lobby Sitting Area"
            },
            {
              "heroImage": false,
              "category": 21001,
              "links": {
                "350Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/b28f9690_b.jpg"
                },
                "70Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/b28f9690_t.jpg"
                },
                "1000Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/b28f9690_z.jpg"
                }
              },
              "caption": "Room"
            },
            {
              "heroImage": false,
              "category": 21001,
              "links": {
                "350Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/3e03b509_b.jpg"
                },
                "70Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/3e03b509_t.jpg"
                },
                "1000Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/3e03b509_z.jpg"
                }
              },
              "caption": "Room"
            },
            {
              "heroImage": false,
              "category": 21001,
              "links": {
                "350Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/ed7d7dad_b.jpg"
                },
                "70Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/ed7d7dad_t.jpg"
                },
                "1000Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/ed7d7dad_z.jpg"
                }
              },
              "caption": "Room"
            },
            {
              "heroImage": false,
              "category": 21001,
              "links": {
                "350Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/b277d3dc_b.jpg"
                },
                "70Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/b277d3dc_t.jpg"
                },
                "1000Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/b277d3dc_z.jpg"
                }
              },
              "caption": "Room"
            },
            {
              "heroImage": false,
              "category": 21001,
              "links": {
                "350Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/3ceb5f52_b.jpg"
                },
                "70Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/3ceb5f52_t.jpg"
                },
                "1000Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/3ceb5f52_z.jpg"
                }
              },
              "caption": "Room"
            },
            {
              "heroImage": false,
              "category": 21001,
              "links": {
                "350Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/967026d1_b.jpg"
                },
                "70Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/967026d1_t.jpg"
                },
                "1000Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/967026d1_z.jpg"
                }
              },
              "caption": "Room"
            },
            {
              "heroImage": false,
              "category": 21001,
              "links": {
                "350Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/355a3348_b.jpg"
                },
                "70Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/355a3348_t.jpg"
                },
                "1000Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/355a3348_z.jpg"
                }
              },
              "caption": "Room"
            },
            {
              "heroImage": false,
              "category": 21001,
              "links": {
                "350Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/9dcd2f78_b.jpg"
                },
                "70Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/9dcd2f78_t.jpg"
                },
                "1000Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/9dcd2f78_z.jpg"
                }
              },
              "caption": "Room"
            },
            {
              "heroImage": false,
              "category": 21001,
              "links": {
                "350Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/5b58a63c_b.jpg"
                },
                "70Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/5b58a63c_t.jpg"
                },
                "1000Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/5b58a63c_z.jpg"
                }
              },
              "caption": "Room"
            },
            {
              "heroImage": false,
              "category": 21001,
              "links": {
                "350Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/d412af8a_b.jpg"
                },
                "70Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/d412af8a_t.jpg"
                },
                "1000Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/d412af8a_z.jpg"
                }
              },
              "caption": "Room"
            },
            {
              "heroImage": false,
              "category": 21001,
              "links": {
                "350Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/54d08faa_b.jpg"
                },
                "70Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/54d08faa_t.jpg"
                },
                "1000Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/54d08faa_z.jpg"
                }
              },
              "caption": "Room"
            },
            {
              "heroImage": false,
              "category": 21001,
              "links": {
                "350Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/ef810519_b.jpg"
                },
                "70Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/ef810519_t.jpg"
                },
                "1000Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/ef810519_z.jpg"
                }
              },
              "caption": "Room"
            },
            {
              "heroImage": false,
              "category": 21001,
              "links": {
                "350Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/39502684_b.jpg"
                },
                "70Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/39502684_t.jpg"
                },
                "1000Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/39502684_z.jpg"
                }
              },
              "caption": "Room"
            },
            {
              "heroImage": false,
              "category": 21001,
              "links": {
                "350Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/fc280589_b.jpg"
                },
                "70Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/fc280589_t.jpg"
                },
                "1000Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/fc280589_z.jpg"
                }
              },
              "caption": "Room"
            },
            {
              "heroImage": false,
              "category": 21001,
              "links": {
                "350Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/72ad8128_b.jpg"
                },
                "70Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/72ad8128_t.jpg"
                },
                "1000Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/72ad8128_z.jpg"
                }
              },
              "caption": "Room"
            },
            {
              "heroImage": false,
              "category": 21001,
              "links": {
                "350Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/3d46aa46_b.jpg"
                },
                "70Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/3d46aa46_t.jpg"
                },
                "1000Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/3d46aa46_z.jpg"
                }
              },
              "caption": "Room"
            },
            {
              "heroImage": false,
              "category": 21001,
              "links": {
                "350Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/e89f3ef6_b.jpg"
                },
                "70Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/e89f3ef6_t.jpg"
                },
                "1000Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/e89f3ef6_z.jpg"
                }
              },
              "caption": "Room"
            },
            {
              "heroImage": false,
              "category": 21001,
              "links": {
                "350Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/d0bb600e_b.jpg"
                },
                "70Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/d0bb600e_t.jpg"
                },
                "1000Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/d0bb600e_z.jpg"
                }
              },
              "caption": "Room"
            },
            {
              "heroImage": false,
              "category": 22001,
              "links": {
                "350Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/be5405c7_b.jpg"
                },
                "70Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/be5405c7_t.jpg"
                },
                "1000Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/be5405c7_z.jpg"
                }
              },
              "caption": "In-Room Dining"
            },
            {
              "heroImage": false,
              "category": 22005,
              "links": {
                "350Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/71037b43_b.jpg"
                },
                "70Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/71037b43_t.jpg"
                },
                "1000Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/71037b43_z.jpg"
                }
              },
              "caption": "Living Area"
            },
            {
              "heroImage": false,
              "category": 22005,
              "links": {
                "350Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/a2f8b2f3_b.jpg"
                },
                "70Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/a2f8b2f3_t.jpg"
                },
                "1000Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/a2f8b2f3_z.jpg"
                }
              },
              "caption": "Living Area"
            },
            {
              "heroImage": false,
              "category": 23000,
              "links": {
                "350Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/31d1cb15_b.jpg"
                },
                "70Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/31d1cb15_t.jpg"
                },
                "1000Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/31d1cb15_z.jpg"
                }
              },
              "caption": "Bathroom"
            },
            {
              "heroImage": false,
              "category": 23000,
              "links": {
                "350Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/0a8b3f2c_b.jpg"
                },
                "70Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/0a8b3f2c_t.jpg"
                },
                "1000Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/0a8b3f2c_z.jpg"
                }
              },
              "caption": "Bathroom"
            },
            {
              "heroImage": false,
              "category": 30000,
              "links": {
                "350Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/167e83ec_b.jpg"
                },
                "70Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/167e83ec_t.jpg"
                },
                "1000Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/167e83ec_z.jpg"
                }
              },
              "caption": "Pool"
            },
            {
              "heroImage": false,
              "category": 30000,
              "links": {
                "350Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/64ea36f4_b.jpg"
                },
                "70Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/64ea36f4_t.jpg"
                },
                "1000Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/64ea36f4_z.jpg"
                }
              },
              "caption": "Pool"
            },
            {
              "heroImage": false,
              "category": 41001,
              "links": {
                "350Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/2b31d667_b.jpg"
                },
                "70Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/2b31d667_t.jpg"
                },
                "1000Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/2b31d667_z.jpg"
                }
              },
              "caption": "Gym"
            },
            {
              "heroImage": false,
              "category": 51000,
              "links": {
                "350Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/b89adb69_b.jpg"
                },
                "70Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/b89adb69_t.jpg"
                },
                "1000Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/b89adb69_z.jpg"
                }
              },
              "caption": "Spa"
            },
            {
              "heroImage": false,
              "category": 51000,
              "links": {
                "350Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/b9e48442_b.jpg"
                },
                "70Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/b9e48442_t.jpg"
                },
                "1000Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/b9e48442_z.jpg"
                }
              },
              "caption": "Spa"
            },
            {
              "heroImage": false,
              "category": 51000,
              "links": {
                "350Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/2fb48a66_b.jpg"
                },
                "70Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/2fb48a66_t.jpg"
                },
                "1000Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/2fb48a66_z.jpg"
                }
              },
              "caption": "Spa"
            },
            {
              "heroImage": false,
              "category": 52002,
              "links": {
                "350Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/c23b5dd7_b.jpg"
                },
                "70Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/c23b5dd7_t.jpg"
                },
                "1000Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/c23b5dd7_z.jpg"
                }
              },
              "caption": "Spa Treatment"
            },
            {
              "heroImage": false,
              "category": 52002,
              "links": {
                "350Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/20ae8154_b.jpg"
                },
                "70Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/20ae8154_t.jpg"
                },
                "1000Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/20ae8154_z.jpg"
                }
              },
              "caption": "Spa Treatment"
            },
            {
              "heroImage": false,
              "category": 81001,
              "links": {
                "350Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/9d90dd4a_b.jpg"
                },
                "70Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/9d90dd4a_t.jpg"
                },
                "1000Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/9d90dd4a_z.jpg"
                }
              },
              "caption": "Breakfast Area"
            },
            {
              "heroImage": false,
              "category": 81003,
              "links": {
                "350Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/4e93036a_b.jpg"
                },
                "70Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/4e93036a_t.jpg"
                },
                "1000Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/4e93036a_z.jpg"
                }
              },
              "caption": "Restaurant"
            },
            {
              "heroImage": false,
              "category": 81003,
              "links": {
                "350Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/23249624_b.jpg"
                },
                "70Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/23249624_t.jpg"
                },
                "1000Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/23249624_z.jpg"
                }
              },
              "caption": "Restaurant"
            },
            {
              "heroImage": false,
              "category": 81003,
              "links": {
                "350Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/925c98f3_b.jpg"
                },
                "70Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/925c98f3_t.jpg"
                },
                "1000Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/925c98f3_z.jpg"
                }
              },
              "caption": "Restaurant"
            },
            {
              "heroImage": false,
              "category": 81003,
              "links": {
                "350Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/e2de36b2_b.jpg"
                },
                "70Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/e2de36b2_t.jpg"
                },
                "1000Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/e2de36b2_z.jpg"
                }
              },
              "caption": "Restaurant"
            },
            {
              "heroImage": false,
              "category": 81003,
              "links": {
                "350Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/86e667e7_b.jpg"
                },
                "70Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/86e667e7_t.jpg"
                },
                "1000Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/86e667e7_z.jpg"
                }
              },
              "caption": "Restaurant"
            },
            {
              "heroImage": false,
              "category": 81003,
              "links": {
                "350Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/1dca3f4f_b.jpg"
                },
                "70Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/1dca3f4f_t.jpg"
                },
                "1000Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/1dca3f4f_z.jpg"
                }
              },
              "caption": "Restaurant"
            },
            {
              "heroImage": false,
              "category": 81003,
              "links": {
                "350Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/47b7f7b8_b.jpg"
                },
                "70Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/47b7f7b8_t.jpg"
                },
                "1000Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/47b7f7b8_z.jpg"
                }
              },
              "caption": "Restaurant"
            },
            {
              "heroImage": false,
              "category": 81003,
              "links": {
                "350Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/638f90fe_b.jpg"
                },
                "70Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/638f90fe_t.jpg"
                },
                "1000Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/638f90fe_z.jpg"
                }
              },
              "caption": "Restaurant"
            },
            {
              "heroImage": false,
              "category": 82001,
              "links": {
                "350Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/f38c2e0c_b.jpg"
                },
                "70Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/f38c2e0c_t.jpg"
                },
                "1000Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/f38c2e0c_z.jpg"
                }
              },
              "caption": "Lounge"
            },
            {
              "heroImage": false,
              "category": 82001,
              "links": {
                "350Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/f47e38a0_b.jpg"
                },
                "70Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/f47e38a0_t.jpg"
                },
                "1000Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/f47e38a0_z.jpg"
                }
              },
              "caption": "Lounge"
            },
            {
              "heroImage": false,
              "category": 83003,
              "links": {
                "350Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/8e931d08_b.jpg"
                },
                "70Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/8e931d08_t.jpg"
                },
                "1000Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/8e931d08_z.jpg"
                }
              },
              "caption": "Banquet Hall"
            },
            {
              "heroImage": false,
              "category": 83009,
              "links": {
                "350Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/56b127e8_b.jpg"
                },
                "70Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/56b127e8_t.jpg"
                },
                "1000Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/56b127e8_z.jpg"
                }
              },
              "caption": "Meeting Facility"
            },
            {
              "heroImage": false,
              "category": 83009,
              "links": {
                "350Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/42376bf9_b.jpg"
                },
                "70Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/42376bf9_t.jpg"
                },
                "1000Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/42376bf9_z.jpg"
                }
              },
              "caption": "Meeting Facility"
            },
            {
              "heroImage": false,
              "category": 83010,
              "links": {
                "350Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/67095c3a_b.jpg"
                },
                "70Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/67095c3a_t.jpg"
                },
                "1000Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/67095c3a_z.jpg"
                }
              },
              "caption": "Business Center"
            },
            {
              "heroImage": false,
              "category": 83011,
              "links": {
                "350Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/7a8bbb8e_b.jpg"
                },
                "70Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/7a8bbb8e_t.jpg"
                },
                "1000Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/7a8bbb8e_z.jpg"
                }
              },
              "caption": "Interior Detail"
            },
            {
              "heroImage": false,
              "category": 83011,
              "links": {
                "350Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/30629d3b_b.jpg"
                },
                "70Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/30629d3b_t.jpg"
                },
                "1000Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/30629d3b_z.jpg"
                }
              },
              "caption": "Interior Detail"
            },
            {
              "heroImage": false,
              "category": 83011,
              "links": {
                "350Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/870b8a34_b.jpg"
                },
                "70Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/870b8a34_t.jpg"
                },
                "1000Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/870b8a34_z.jpg"
                }
              },
              "caption": "Interior Detail"
            },
            {
              "heroImage": false,
              "category": 83011,
              "links": {
                "350Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/2ee56443_b.jpg"
                },
                "70Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/2ee56443_t.jpg"
                },
                "1000Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/2ee56443_z.jpg"
                }
              },
              "caption": "Interior Detail"
            },
            {
              "heroImage": false,
              "category": 91012,
              "links": {
                "350Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/ab48d4de_b.jpg"
                },
                "70Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/ab48d4de_t.jpg"
                },
                "1000Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/ab48d4de_z.jpg"
                }
              },
              "caption": "Outdoor Banquet Area"
            },
            {
              "heroImage": false,
              "category": 91012,
              "links": {
                "350Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/341f9425_b.jpg"
                },
                "70Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/341f9425_t.jpg"
                },
                "1000Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/341f9425_z.jpg"
                }
              },
              "caption": "Outdoor Banquet Area"
            },
            {
              "heroImage": false,
              "category": 91023,
              "links": {
                "350Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/0ac7ba1f_b.jpg"
                },
                "70Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/0ac7ba1f_t.jpg"
                },
                "1000Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/0ac7ba1f_z.jpg"
                }
              },
              "caption": "Exterior detail"
            },
            {
              "heroImage": false,
              "category": 92001,
              "links": {
                "350Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/29c8d020_b.jpg"
                },
                "70Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/29c8d020_t.jpg"
                },
                "1000Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/29c8d020_z.jpg"
                }
              },
              "caption": "Aerial View"
            }
          ],
          "onsitePayments": {
            "currency": "AED",
            "types": {
              "171": {
                "id": "171",
                "name": "American Express"
              },
              "179": {
                "id": "179",
                "name": "Diners Club"
              },
              "183": {
                "id": "183",
                "name": "JCB International"
              },
              "184": {
                "id": "184",
                "name": "Mastercard"
              },
              "189": {
                "id": "189",
                "name": "Visa"
              },
              "190": {
                "id": "190",
                "name": "Cash not accepted"
              },
              "1073744166": {
                "id": "1073744166",
                "name": "Debit cards not accepted"
              }
            }
          },
          "rooms": {},
          "descriptions": {
            "amenities": "Pamper yourself with a visit to the spa, which offers massages, body treatments, and facials. You're sure to appreciate the recreational amenities, including a health club, an outdoor pool, and a sauna. This Art Deco hotel also features complimentary wireless Internet access, concierge services, and gift shops/newsstands. Guests can catch a ride to nearby destinations on the area shuttle (surcharge).",
            "dining": "Enjoy Mediterranean cuisine at Armani/Mediterraneo, one of the hotel's 5 restaurants, or stay in and take advantage of the 24-hour room service. Snacks are also available at the coffee shop/café. Wrap up your day with a drink at the bar/lounge. Buffet breakfasts are available daily from 6:30 AM to 10:00 AM for a fee.",
            "businessAmenities": "Featured amenities include complimentary wired Internet access, a business center, and limo/town car service. A roundtrip airport shuttle is provided for a surcharge (available 24 hours), and free self parking is available onsite.",
            "rooms": "Make yourself at home in one of the 160 guestrooms featuring iPod docking stations and minibars. Your bed comes with down comforters and premium bedding. Wired and wireless Internet access is complimentary, while 62-inch plasma televisions with cable programming provide entertainment. Bathrooms feature separate bathtubs and showers, designer toiletries, and bidets.",
            "attractions": "Distances are displayed to the nearest 0.1 mile and kilometer. <br /> <p>Burj Khalifa - 0.4 km / 0.3 mi <br /> Dubai Opera - 0.6 km / 0.4 mi <br /> Emaar Square - 0.7 km / 0.4 mi <br /> Dubai Aquarium & Underwater Zoo - 1.2 km / 0.7 mi <br /> Dubai Fountain - 1.2 km / 0.7 mi <br /> Dubai Mall - 1.2 km / 0.8 mi <br /> KidZania - 1.3 km / 0.8 mi <br /> Souk al Bahar - 1.6 km / 1 mi <br /> Dubai Water Canal - 2.3 km / 1.4 mi <br /> City Walk - 2.6 km / 1.6 mi <br /> Dubai International Financial Centre - 2.7 km / 1.7 mi <br /> Dubai International Convention and Exhibition Centre - 4.1 km / 2.6 mi <br /> Dubai World Trade Centre - 4.7 km / 2.9 mi <br /> Dubai Garden Glow - 4.9 km / 3 mi <br /> La Mer Water Park - 5.8 km / 3.6 mi <br /> </p><p>The nearest airports are:<br />Dubai Intl. Airport (DXB) - 13.9 km / 8.6 mi<br /> Sharjah (SHJ-Sharjah Intl.) - 41.2 km / 25.6 mi<br /> Dubai (DWC-Al Maktoum Intl.) - 53.3 km / 33.1 mi<br /> </p><p>The preferred airport for Armani Hotel Dubai is Dubai Intl. Airport (DXB). </p>",
            "location": "Armani Hotel Dubai is centrally located in Dubai, a 5-minute walk from Burj Khalifa and 15 minutes by foot from Dubai Mall.  This 5-star hotel is 3.2 mi (5.1 km) from Dubai World Trade Centre and 6.1 mi (9.8 km) from Dubai Creek.",
            "headline": "Near Burj Khalifa"
          },
          "externalId": "3033052",
          "externalProvider": "EXPEDIA",
          "hotelImages": [
            {
              "heroImage": true,
              "category": 3,
              "links": {
                "350Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/22167ea6_b.jpg"
                },
                "70Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/22167ea6_t.jpg"
                },
                "1000Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/22167ea6_z.jpg"
                }
              },
              "caption": "Featured Image"
            },
            {
              "heroImage": false,
              "category": 10001,
              "links": {
                "350Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/96947159_b.jpg"
                },
                "70Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/96947159_t.jpg"
                },
                "1000Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/96947159_z.jpg"
                }
              },
              "caption": "Lobby"
            },
            {
              "heroImage": false,
              "category": 10003,
              "links": {
                "350Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/490690d0_b.jpg"
                },
                "70Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/490690d0_t.jpg"
                },
                "1000Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/490690d0_z.jpg"
                }
              },
              "caption": "Lobby Sitting Area"
            },
            {
              "heroImage": false,
              "category": 21001,
              "links": {
                "350Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/b28f9690_b.jpg"
                },
                "70Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/b28f9690_t.jpg"
                },
                "1000Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/b28f9690_z.jpg"
                }
              },
              "caption": "Room"
            },
            {
              "heroImage": false,
              "category": 21001,
              "links": {
                "350Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/3e03b509_b.jpg"
                },
                "70Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/3e03b509_t.jpg"
                },
                "1000Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/3e03b509_z.jpg"
                }
              },
              "caption": "Room"
            },
            {
              "heroImage": false,
              "category": 21001,
              "links": {
                "350Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/ed7d7dad_b.jpg"
                },
                "70Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/ed7d7dad_t.jpg"
                },
                "1000Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/ed7d7dad_z.jpg"
                }
              },
              "caption": "Room"
            },
            {
              "heroImage": false,
              "category": 21001,
              "links": {
                "350Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/b277d3dc_b.jpg"
                },
                "70Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/b277d3dc_t.jpg"
                },
                "1000Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/b277d3dc_z.jpg"
                }
              },
              "caption": "Room"
            },
            {
              "heroImage": false,
              "category": 21001,
              "links": {
                "350Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/3ceb5f52_b.jpg"
                },
                "70Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/3ceb5f52_t.jpg"
                },
                "1000Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/3ceb5f52_z.jpg"
                }
              },
              "caption": "Room"
            },
            {
              "heroImage": false,
              "category": 21001,
              "links": {
                "350Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/967026d1_b.jpg"
                },
                "70Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/967026d1_t.jpg"
                },
                "1000Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/967026d1_z.jpg"
                }
              },
              "caption": "Room"
            },
            {
              "heroImage": false,
              "category": 21001,
              "links": {
                "350Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/355a3348_b.jpg"
                },
                "70Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/355a3348_t.jpg"
                },
                "1000Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/355a3348_z.jpg"
                }
              },
              "caption": "Room"
            },
            {
              "heroImage": false,
              "category": 21001,
              "links": {
                "350Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/9dcd2f78_b.jpg"
                },
                "70Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/9dcd2f78_t.jpg"
                },
                "1000Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/9dcd2f78_z.jpg"
                }
              },
              "caption": "Room"
            },
            {
              "heroImage": false,
              "category": 21001,
              "links": {
                "350Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/5b58a63c_b.jpg"
                },
                "70Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/5b58a63c_t.jpg"
                },
                "1000Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/5b58a63c_z.jpg"
                }
              },
              "caption": "Room"
            },
            {
              "heroImage": false,
              "category": 21001,
              "links": {
                "350Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/d412af8a_b.jpg"
                },
                "70Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/d412af8a_t.jpg"
                },
                "1000Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/d412af8a_z.jpg"
                }
              },
              "caption": "Room"
            },
            {
              "heroImage": false,
              "category": 21001,
              "links": {
                "350Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/54d08faa_b.jpg"
                },
                "70Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/54d08faa_t.jpg"
                },
                "1000Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/54d08faa_z.jpg"
                }
              },
              "caption": "Room"
            },
            {
              "heroImage": false,
              "category": 21001,
              "links": {
                "350Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/ef810519_b.jpg"
                },
                "70Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/ef810519_t.jpg"
                },
                "1000Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/ef810519_z.jpg"
                }
              },
              "caption": "Room"
            },
            {
              "heroImage": false,
              "category": 21001,
              "links": {
                "350Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/39502684_b.jpg"
                },
                "70Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/39502684_t.jpg"
                },
                "1000Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/39502684_z.jpg"
                }
              },
              "caption": "Room"
            },
            {
              "heroImage": false,
              "category": 21001,
              "links": {
                "350Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/fc280589_b.jpg"
                },
                "70Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/fc280589_t.jpg"
                },
                "1000Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/fc280589_z.jpg"
                }
              },
              "caption": "Room"
            },
            {
              "heroImage": false,
              "category": 21001,
              "links": {
                "350Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/72ad8128_b.jpg"
                },
                "70Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/72ad8128_t.jpg"
                },
                "1000Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/72ad8128_z.jpg"
                }
              },
              "caption": "Room"
            },
            {
              "heroImage": false,
              "category": 21001,
              "links": {
                "350Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/3d46aa46_b.jpg"
                },
                "70Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/3d46aa46_t.jpg"
                },
                "1000Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/3d46aa46_z.jpg"
                }
              },
              "caption": "Room"
            },
            {
              "heroImage": false,
              "category": 21001,
              "links": {
                "350Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/e89f3ef6_b.jpg"
                },
                "70Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/e89f3ef6_t.jpg"
                },
                "1000Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/e89f3ef6_z.jpg"
                }
              },
              "caption": "Room"
            },
            {
              "heroImage": false,
              "category": 21001,
              "links": {
                "350Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/d0bb600e_b.jpg"
                },
                "70Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/d0bb600e_t.jpg"
                },
                "1000Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/d0bb600e_z.jpg"
                }
              },
              "caption": "Room"
            },
            {
              "heroImage": false,
              "category": 22001,
              "links": {
                "350Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/be5405c7_b.jpg"
                },
                "70Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/be5405c7_t.jpg"
                },
                "1000Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/be5405c7_z.jpg"
                }
              },
              "caption": "In-Room Dining"
            },
            {
              "heroImage": false,
              "category": 22005,
              "links": {
                "350Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/71037b43_b.jpg"
                },
                "70Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/71037b43_t.jpg"
                },
                "1000Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/71037b43_z.jpg"
                }
              },
              "caption": "Living Area"
            },
            {
              "heroImage": false,
              "category": 22005,
              "links": {
                "350Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/a2f8b2f3_b.jpg"
                },
                "70Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/a2f8b2f3_t.jpg"
                },
                "1000Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/a2f8b2f3_z.jpg"
                }
              },
              "caption": "Living Area"
            },
            {
              "heroImage": false,
              "category": 23000,
              "links": {
                "350Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/31d1cb15_b.jpg"
                },
                "70Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/31d1cb15_t.jpg"
                },
                "1000Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/31d1cb15_z.jpg"
                }
              },
              "caption": "Bathroom"
            },
            {
              "heroImage": false,
              "category": 23000,
              "links": {
                "350Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/0a8b3f2c_b.jpg"
                },
                "70Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/0a8b3f2c_t.jpg"
                },
                "1000Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/0a8b3f2c_z.jpg"
                }
              },
              "caption": "Bathroom"
            },
            {
              "heroImage": false,
              "category": 30000,
              "links": {
                "350Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/167e83ec_b.jpg"
                },
                "70Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/167e83ec_t.jpg"
                },
                "1000Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/167e83ec_z.jpg"
                }
              },
              "caption": "Pool"
            },
            {
              "heroImage": false,
              "category": 30000,
              "links": {
                "350Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/64ea36f4_b.jpg"
                },
                "70Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/64ea36f4_t.jpg"
                },
                "1000Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/64ea36f4_z.jpg"
                }
              },
              "caption": "Pool"
            },
            {
              "heroImage": false,
              "category": 41001,
              "links": {
                "350Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/2b31d667_b.jpg"
                },
                "70Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/2b31d667_t.jpg"
                },
                "1000Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/2b31d667_z.jpg"
                }
              },
              "caption": "Gym"
            },
            {
              "heroImage": false,
              "category": 51000,
              "links": {
                "350Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/b89adb69_b.jpg"
                },
                "70Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/b89adb69_t.jpg"
                },
                "1000Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/b89adb69_z.jpg"
                }
              },
              "caption": "Spa"
            },
            {
              "heroImage": false,
              "category": 51000,
              "links": {
                "350Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/b9e48442_b.jpg"
                },
                "70Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/b9e48442_t.jpg"
                },
                "1000Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/b9e48442_z.jpg"
                }
              },
              "caption": "Spa"
            },
            {
              "heroImage": false,
              "category": 51000,
              "links": {
                "350Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/2fb48a66_b.jpg"
                },
                "70Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/2fb48a66_t.jpg"
                },
                "1000Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/2fb48a66_z.jpg"
                }
              },
              "caption": "Spa"
            },
            {
              "heroImage": false,
              "category": 52002,
              "links": {
                "350Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/c23b5dd7_b.jpg"
                },
                "70Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/c23b5dd7_t.jpg"
                },
                "1000Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/c23b5dd7_z.jpg"
                }
              },
              "caption": "Spa Treatment"
            },
            {
              "heroImage": false,
              "category": 52002,
              "links": {
                "350Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/20ae8154_b.jpg"
                },
                "70Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/20ae8154_t.jpg"
                },
                "1000Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/20ae8154_z.jpg"
                }
              },
              "caption": "Spa Treatment"
            },
            {
              "heroImage": false,
              "category": 81001,
              "links": {
                "350Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/9d90dd4a_b.jpg"
                },
                "70Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/9d90dd4a_t.jpg"
                },
                "1000Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/9d90dd4a_z.jpg"
                }
              },
              "caption": "Breakfast Area"
            },
            {
              "heroImage": false,
              "category": 81003,
              "links": {
                "350Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/4e93036a_b.jpg"
                },
                "70Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/4e93036a_t.jpg"
                },
                "1000Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/4e93036a_z.jpg"
                }
              },
              "caption": "Restaurant"
            },
            {
              "heroImage": false,
              "category": 81003,
              "links": {
                "350Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/23249624_b.jpg"
                },
                "70Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/23249624_t.jpg"
                },
                "1000Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/23249624_z.jpg"
                }
              },
              "caption": "Restaurant"
            },
            {
              "heroImage": false,
              "category": 81003,
              "links": {
                "350Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/925c98f3_b.jpg"
                },
                "70Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/925c98f3_t.jpg"
                },
                "1000Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/925c98f3_z.jpg"
                }
              },
              "caption": "Restaurant"
            },
            {
              "heroImage": false,
              "category": 81003,
              "links": {
                "350Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/e2de36b2_b.jpg"
                },
                "70Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/e2de36b2_t.jpg"
                },
                "1000Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/e2de36b2_z.jpg"
                }
              },
              "caption": "Restaurant"
            },
            {
              "heroImage": false,
              "category": 81003,
              "links": {
                "350Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/86e667e7_b.jpg"
                },
                "70Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/86e667e7_t.jpg"
                },
                "1000Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/86e667e7_z.jpg"
                }
              },
              "caption": "Restaurant"
            },
            {
              "heroImage": false,
              "category": 81003,
              "links": {
                "350Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/1dca3f4f_b.jpg"
                },
                "70Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/1dca3f4f_t.jpg"
                },
                "1000Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/1dca3f4f_z.jpg"
                }
              },
              "caption": "Restaurant"
            },
            {
              "heroImage": false,
              "category": 81003,
              "links": {
                "350Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/47b7f7b8_b.jpg"
                },
                "70Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/47b7f7b8_t.jpg"
                },
                "1000Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/47b7f7b8_z.jpg"
                }
              },
              "caption": "Restaurant"
            },
            {
              "heroImage": false,
              "category": 81003,
              "links": {
                "350Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/638f90fe_b.jpg"
                },
                "70Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/638f90fe_t.jpg"
                },
                "1000Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/638f90fe_z.jpg"
                }
              },
              "caption": "Restaurant"
            },
            {
              "heroImage": false,
              "category": 82001,
              "links": {
                "350Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/f38c2e0c_b.jpg"
                },
                "70Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/f38c2e0c_t.jpg"
                },
                "1000Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/f38c2e0c_z.jpg"
                }
              },
              "caption": "Lounge"
            },
            {
              "heroImage": false,
              "category": 82001,
              "links": {
                "350Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/f47e38a0_b.jpg"
                },
                "70Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/f47e38a0_t.jpg"
                },
                "1000Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/f47e38a0_z.jpg"
                }
              },
              "caption": "Lounge"
            },
            {
              "heroImage": false,
              "category": 83003,
              "links": {
                "350Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/8e931d08_b.jpg"
                },
                "70Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/8e931d08_t.jpg"
                },
                "1000Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/8e931d08_z.jpg"
                }
              },
              "caption": "Banquet Hall"
            },
            {
              "heroImage": false,
              "category": 83009,
              "links": {
                "350Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/56b127e8_b.jpg"
                },
                "70Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/56b127e8_t.jpg"
                },
                "1000Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/56b127e8_z.jpg"
                }
              },
              "caption": "Meeting Facility"
            },
            {
              "heroImage": false,
              "category": 83009,
              "links": {
                "350Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/42376bf9_b.jpg"
                },
                "70Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/42376bf9_t.jpg"
                },
                "1000Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/42376bf9_z.jpg"
                }
              },
              "caption": "Meeting Facility"
            },
            {
              "heroImage": false,
              "category": 83010,
              "links": {
                "350Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/67095c3a_b.jpg"
                },
                "70Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/67095c3a_t.jpg"
                },
                "1000Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/67095c3a_z.jpg"
                }
              },
              "caption": "Business Center"
            },
            {
              "heroImage": false,
              "category": 83011,
              "links": {
                "350Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/7a8bbb8e_b.jpg"
                },
                "70Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/7a8bbb8e_t.jpg"
                },
                "1000Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/7a8bbb8e_z.jpg"
                }
              },
              "caption": "Interior Detail"
            },
            {
              "heroImage": false,
              "category": 83011,
              "links": {
                "350Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/30629d3b_b.jpg"
                },
                "70Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/30629d3b_t.jpg"
                },
                "1000Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/30629d3b_z.jpg"
                }
              },
              "caption": "Interior Detail"
            },
            {
              "heroImage": false,
              "category": 83011,
              "links": {
                "350Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/870b8a34_b.jpg"
                },
                "70Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/870b8a34_t.jpg"
                },
                "1000Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/870b8a34_z.jpg"
                }
              },
              "caption": "Interior Detail"
            },
            {
              "heroImage": false,
              "category": 83011,
              "links": {
                "350Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/2ee56443_b.jpg"
                },
                "70Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/2ee56443_t.jpg"
                },
                "1000Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/2ee56443_z.jpg"
                }
              },
              "caption": "Interior Detail"
            },
            {
              "heroImage": false,
              "category": 91012,
              "links": {
                "350Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/ab48d4de_b.jpg"
                },
                "70Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/ab48d4de_t.jpg"
                },
                "1000Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/ab48d4de_z.jpg"
                }
              },
              "caption": "Outdoor Banquet Area"
            },
            {
              "heroImage": false,
              "category": 91012,
              "links": {
                "350Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/341f9425_b.jpg"
                },
                "70Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/341f9425_t.jpg"
                },
                "1000Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/341f9425_z.jpg"
                }
              },
              "caption": "Outdoor Banquet Area"
            },
            {
              "heroImage": false,
              "category": 91023,
              "links": {
                "350Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/0ac7ba1f_b.jpg"
                },
                "70Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/0ac7ba1f_t.jpg"
                },
                "1000Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/0ac7ba1f_z.jpg"
                }
              },
              "caption": "Exterior detail"
            },
            {
              "heroImage": false,
              "category": 92001,
              "links": {
                "350Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/29c8d020_b.jpg"
                },
                "70Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/29c8d020_t.jpg"
                },
                "1000Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/29c8d020_z.jpg"
                }
              },
              "caption": "Aerial View"
            }
          ],
          "general": {
            "1073744992": "Property is cleaned with disinfectant",
            "1073744994": "Staff wears personal protective equipment",
            "1073744996": "Protective clothing is available to guests",
            "1073744997": "Masks are available to guests",
            "1073744998": "Gloves are available to guests",
            "1073745002": "Guests are provided with free hand sanitizer",
            "1073745004": "Social distancing measures are in place",
            "1073745006": "Contactless check-in is available",
            "1073745013": "Property confirms they are implementing enhanced cleaning measures",
            "1073745015": "Individually-wrapped food options are available",
            "1073745019": "Individually-wrapped food options are available through room service",
            "1073745045": "Staff temperature checks are conducted regularly",
            "1073745047": "Temperature checks are available to guests",
            "1073745051": "Commonly-touched surfaces are cleaned with disinfectant",
            "1073745053": "Property confirms they are implementing guest safety measures",
            "1073745055": "Contactless check-out is available"
          },
          "pets": {
            "2050": "Pets not allowed",
            "2808": "Service animals not allowed"
          },
          "image": [
            {
              "heroImage": true,
              "category": 3,
              "links": {
                "350Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/22167ea6_b.jpg"
                },
                "70Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/22167ea6_t.jpg"
                },
                "1000Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/22167ea6_z.jpg"
                }
              },
              "caption": "Featured Image"
            },
            {
              "heroImage": false,
              "category": 10001,
              "links": {
                "350Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/96947159_b.jpg"
                },
                "70Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/96947159_t.jpg"
                },
                "1000Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/96947159_z.jpg"
                }
              },
              "caption": "Lobby"
            },
            {
              "heroImage": false,
              "category": 10003,
              "links": {
                "350Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/490690d0_b.jpg"
                },
                "70Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/490690d0_t.jpg"
                },
                "1000Px": {
                  "method": "GET",
                  "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/490690d0_z.jpg"
                }
              },
              "caption": "Lobby Sitting Area"
            }
          ],
          "topAmenities": {
            "3": "Bar/lounge",
            "24": "Outdoor pool",
            "2390": "Free WiFi"
          },
          "ha": [
            "BAR",
            "POOL",
            "WIFI"
          ],
          "attractions": "Distances are displayed to the nearest 0.1 mile and kilometer. </li><li> <ul><li>Burj Khalifa - 0.4 km / 0.3 mi </li><li> Dubai Opera - 0.6 km / 0.4 mi </li><li> Emaar Square - 0.7 km / 0.4 mi </li><li> Dubai Aquarium & Underwater Zoo - 1.2 km / 0.7 mi </li><li> Dubai Fountain - 1.2 km / 0.7 mi </li><li> Dubai Mall - 1.2 km / 0.8 mi </li><li> KidZania - 1.3 km / 0.8 mi </li><li> Souk al Bahar - 1.6 km / 1 mi </li><li> Dubai Water Canal - 2.3 km / 1.4 mi </li><li> City Walk - 2.6 km / 1.6 mi </li><li> Dubai International Financial Centre - 2.7 km / 1.7 mi </li><li> Dubai International Convention and Exhibition Centre - 4.1 km / 2.6 mi </li><li> Dubai World Trade Centre - 4.7 km / 2.9 mi </li><li> Dubai Garden Glow - 4.9 km / 3 mi </li><li> La Mer Water Park - 5.8 km / 3.6 mi </li><li> </li></ul><ul><li>The nearest airports are:</li><li>Dubai Intl. Airport (DXB) - 13.9 km / 8.6 mi</li><li> Sharjah (SHJ-Sharjah Intl.) - 41.2 km / 25.6 mi</li><li> Dubai (DWC-Al Maktoum Intl.) - 53.3 km / 33.1 mi</li><li> </li></ul><ul><li>The preferred airport for Armani Hotel Dubai is Dubai Intl. Airport (DXB). </li></ul>",
          "phone_no": "971-4-8883888",
          "email": "",
          "guestreview": {
            "cleaniness": "4.7",
            "staff_service": "4.6",
            "amenities": "4.5",
            "conditions_facilities": "4.6"
          },
          "rat": "5.0",
          "urat": "4.6",
          "userreview": "Pamper yourself with a visit to the spa, which offers massages, body treatments, and facials. You're sure to appreciate the recreational amenities, including a health club, an outdoor pool, and a sauna. This Art Deco hotel also features complimentary wireless Internet access, concierge services, and gift shops/newsstands. Guests can catch a ride to nearby destinations on the area shuttle (surcharge).",
          "expediareview": 573,
          "pid": "3033052",
          "nearby": "Burj Khalifa - 0.4 km / 0.3 mi ",
          "onsite_payments": {
            "currency": "AED",
            "types": {
              "171": {
                "id": "171",
                "name": "American Express"
              },
              "179": {
                "id": "179",
                "name": "Diners Club"
              },
              "183": {
                "id": "183",
                "name": "JCB International"
              },
              "184": {
                "id": "184",
                "name": "Mastercard"
              },
              "189": {
                "id": "189",
                "name": "Visa"
              },
              "190": {
                "id": "190",
                "name": "Cash not accepted"
              },
              "1073744166": {
                "id": "1073744166",
                "name": "Debit cards not accepted"
              }
            }
          },
          "ra": [
            "BAR",
            "POOL",
            "WIFI"
          ],
          "cin": {
            "beginTime": "3:00 PM",
            "endTime": "midnight",
            "instructions": "Extra-person charges may apply and vary depending on property policy Government-issued photo identification and a credit card, debit card, or cash deposit may be required at check-in for incidental charges Special requests are subject to availability upon check-in and may incur additional charges; special requests cannot be guaranteed This property accepts credit cards; cash is not accepted Safety features at this property include a fire extinguisher, a smoke detector, a security system, and a first aid kit Please note that cultural norms and guest policies may differ by country and by property; the policies listed are provided by the property Alcohol is not available via room service for Room (1 Bedroom Residence) and Armani Two Bedroom Residence categories.",
            "specialInstructions": "Front desk staff will greet guests on arrival. For more details, please contact the property using the information on the booking confirmation.  A tourism fee is imposed by the city and collected at the property. For hotel stays, the fee is AED 20 for the first bedroom per night, and increases by AED 20 per night for each additional bedroom. For residence stays, the fee is AED 10 for the first bedroom per night, and increases by AED 10 per night for each additional bedroom.",
            "minAge": 18
          },
          "cout": {
            "time": "12:00 PM"
          },
          "cat": "Hotel",
          "lat": 25.197116,
          "lng": 55.274058,
          "country": "AE",
          "city": "Dubai",
          "add": "Burj Khalifa, Mohamed Bin Rashid Blvd",
          "pcd": "",
          "prov": "",
          "htnm": "Armani Hotel Dubai",
          "roomData": {},
          "hImages": [
            "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/22167ea6_z.jpg",
            "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/96947159_z.jpg",
            "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/490690d0_z.jpg",
            "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/b28f9690_z.jpg",
            "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/3e03b509_z.jpg",
            "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/ed7d7dad_z.jpg",
            "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/b277d3dc_z.jpg",
            "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/3ceb5f52_z.jpg",
            "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/967026d1_z.jpg",
            "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/355a3348_z.jpg",
            "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/9dcd2f78_z.jpg",
            "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/5b58a63c_z.jpg",
            "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/d412af8a_z.jpg",
            "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/54d08faa_z.jpg",
            "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/ef810519_z.jpg",
            "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/39502684_z.jpg",
            "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/fc280589_z.jpg",
            "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/72ad8128_z.jpg",
            "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/3d46aa46_z.jpg",
            "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/e89f3ef6_z.jpg",
            "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/d0bb600e_z.jpg",
            "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/be5405c7_z.jpg",
            "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/71037b43_z.jpg",
            "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/a2f8b2f3_z.jpg",
            "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/31d1cb15_z.jpg",
            "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/0a8b3f2c_z.jpg",
            "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/167e83ec_z.jpg",
            "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/64ea36f4_z.jpg",
            "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/2b31d667_z.jpg",
            "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/b89adb69_z.jpg",
            "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/b9e48442_z.jpg",
            "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/2fb48a66_z.jpg",
            "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/c23b5dd7_z.jpg",
            "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/20ae8154_z.jpg",
            "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/9d90dd4a_z.jpg",
            "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/4e93036a_z.jpg",
            "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/23249624_z.jpg",
            "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/925c98f3_z.jpg",
            "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/e2de36b2_z.jpg",
            "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/86e667e7_z.jpg",
            "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/1dca3f4f_z.jpg",
            "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/47b7f7b8_z.jpg",
            "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/638f90fe_z.jpg",
            "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/f38c2e0c_z.jpg",
            "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/f47e38a0_z.jpg",
            "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/8e931d08_z.jpg",
            "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/56b127e8_z.jpg",
            "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/42376bf9_z.jpg",
            "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/67095c3a_z.jpg",
            "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/7a8bbb8e_z.jpg",
            "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/30629d3b_z.jpg",
            "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/870b8a34_z.jpg",
            "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/2ee56443_z.jpg",
            "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/ab48d4de_z.jpg",
            "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/341f9425_z.jpg",
            "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/0ac7ba1f_z.jpg",
            "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/29c8d020_z.jpg"
          ]
        },
        "isExpedia": true
      }
    },
    "status": 200,
    "statusText": "",
    "headers": {
      "content-type": "application/json; charset=utf-8"
    },
    "config": {
      "transitional": {
        "silentJSONParsing": true,
        "forcedJSONParsing": true,
        "clarifyTimeoutError": false
      },
      "adapter": [
        "xhr",
        "http"
      ],
      "transformRequest": [
        null
      ],
      "transformResponse": [
        null
      ],
      "timeout": 0,
      "xsrfCookieName": "XSRF-TOKEN",
      "xsrfHeaderName": "X-XSRF-TOKEN",
      "maxContentLength": -1,
      "maxBodyLength": -1,
      "env": {},
      "headers": {
        "Accept": "application/json, text/plain, */*"
      },
      "method": "get",
      "url": "https://b2c-hotel-common-api-dev.clickntravel.com/cnt/hotelavailability/check?cin=29-08-2023&cout=31-08-2023&rms=1&adt=1&chd=0&rty=prid&echo=wec14jism8&cid=327&cha=B2B&cur=INR&bid=undefined&prtyp=hotelOnely&country_code=AE&pid=3033052&chdages=0&package=1&butype=undefined&_id=undefined&region_type=India&expediapid=3033052"
    },
    "request": {}
  }

  export const HOTEL_ROOMS = {
    "data": {
      "data": {
        "_id": "600d422fa1bc371b80fe3a43",
        "name": "Armani Hotel Dubai",
        "address": {
          "line1": "Burj Khalifa, Mohamed Bin Rashid Blvd",
          "city": "Dubai",
          "countryCode": "AE",
          "obfuscationRequired": false
        },
        "ratings": "5.0",
        "location": {
          "lat": 25.197116,
          "long": 55.274058
        },
        "phone": "971-4-8883888",
        "category": {
          "id": "1",
          "name": "Hotel"
        },
        "checkin": {
          "beginTime": "3:00 PM",
          "endTime": "midnight",
          "instructions": "Extra-person charges may apply and vary depending on property policy Government-issued photo identification and a credit card, debit card, or cash deposit may be required at check-in for incidental charges Special requests are subject to availability upon check-in and may incur additional charges; special requests cannot be guaranteed This property accepts credit cards; cash is not accepted Safety features at this property include a fire extinguisher, a smoke detector, a security system, and a first aid kit Please note that cultural norms and guest policies may differ by country and by property; the policies listed are provided by the property Alcohol is not available via room service for Room (1 Bedroom Residence) and Armani Two Bedroom Residence categories.",
          "specialInstructions": "Front desk staff will greet guests on arrival. For more details, please contact the property using the information on the booking confirmation.  A tourism fee is imposed by the city and collected at the property. For hotel stays, the fee is AED 20 for the first bedroom per night, and increases by AED 20 per night for each additional bedroom. For residence stays, the fee is AED 10 for the first bedroom per night, and increases by AED 10 per night for each additional bedroom.",
          "minAge": 18
        },
        "checkout": {
          "time": "12:00 PM"
        },
        "fees": {
          "mandatory": "<p>You'll be asked to pay the following charges at the property:</p> <ul><li>A tax is imposed by the city: AED 20 per accommodation, per night</li></ul> <p>We have included all charges provided to us by the property. However, charges can vary, for example, based on length of stay or the room you book. </p>",
          "optional": "<p>The following fees and deposits are charged by the property at time of service, check-in, or check-out. </p> <ul> <li>Fee for buffet breakfast: AED 166 per person (approximately)</li>     <li>Airport shuttle fee: AED 500.00 per vehicle (one-way)</li>             <li>Rollaway bed fee: AED 250.0 per night</li>            </ul> <p>The above list may not be comprehensive. Fees and deposits may not include tax and are subject to change. </p>"
        },
        "policies": {
          "knowBeforeYouGo": "<ul>  <li>This property offers transfers from the airport (surcharges may apply). Guests must contact the property with arrival details before travel, using the contact information on the booking confirmation. </li>  <li>One child 3 years old or younger stays free when occupying the parent or guardian's room, using existing bedding. </li><li>Only registered guests are allowed in the guestrooms. </li> <li>No pets and no service animals are allowed at this property. </li><li>This property advises that enhanced cleaning and guest safety measures are currently in place.</li><li>Disinfectant is used to clean the property, and commonly-touched surfaces are cleaned with disinfectant between stays.</li><li>Personal protective equipment, including masks and gloves, will be available to guests.</li><li>Social distancing measures are in place; staff at the property wear personal protective equipment; periodic temperature checks are conducted on staff; temperature checks are available to guests; guests are provided with hand sanitizer.</li><li>Contactless check-in and contactless check-out are available.</li><li>Individually-wrapped food options are available through room service.</li> </ul>"
        },
        "attributes": {
          "pets": {
            "2050": {
              "id": "2050",
              "name": "Pets not allowed"
            },
            "2808": {
              "id": "2808",
              "name": "Service animals not allowed"
            }
          },
          "general": {
            "1073744992": {
              "id": "1073744992",
              "name": "Property is cleaned with disinfectant"
            },
            "1073744994": {
              "id": "1073744994",
              "name": "Staff wears personal protective equipment"
            },
            "1073744996": {
              "id": "1073744996",
              "name": "Protective clothing is available to guests"
            },
            "1073744997": {
              "id": "1073744997",
              "name": "Masks are available to guests"
            },
            "1073744998": {
              "id": "1073744998",
              "name": "Gloves are available to guests"
            },
            "1073745002": {
              "id": "1073745002",
              "name": "Guests are provided with free hand sanitizer"
            },
            "1073745004": {
              "id": "1073745004",
              "name": "Social distancing measures are in place"
            },
            "1073745006": {
              "id": "1073745006",
              "name": "Contactless check-in is available"
            },
            "1073745013": {
              "id": "1073745013",
              "name": "Property confirms they are implementing enhanced cleaning measures"
            },
            "1073745015": {
              "id": "1073745015",
              "name": "Individually-wrapped food options are available"
            },
            "1073745019": {
              "id": "1073745019",
              "name": "Individually-wrapped food options are available through room service"
            },
            "1073745045": {
              "id": "1073745045",
              "name": "Staff temperature checks are conducted regularly"
            },
            "1073745047": {
              "id": "1073745047",
              "name": "Temperature checks are available to guests"
            },
            "1073745051": {
              "id": "1073745051",
              "name": "Commonly-touched surfaces are cleaned with disinfectant"
            },
            "1073745053": {
              "id": "1073745053",
              "name": "Property confirms they are implementing guest safety measures"
            },
            "1073745055": {
              "id": "1073745055",
              "name": "Contactless check-out is available"
            }
          }
        },
        "amenities": {
          "3": {
            "id": "3",
            "name": "Bar/lounge"
          },
          "8": {
            "id": "8",
            "name": "Elevator"
          },
          "24": {
            "id": "24",
            "name": "Outdoor pool"
          },
          "43": {
            "id": "43",
            "name": "Concierge services"
          },
          "56": {
            "id": "56",
            "name": "Airport transportation (surcharge)"
          },
          "324": {
            "id": "324",
            "name": "Coffee/tea in common areas"
          },
          "361": {
            "id": "361",
            "name": "Breakfast available (surcharge)"
          },
          "369": {
            "id": "369",
            "name": "Laundry facilities"
          },
          "2004": {
            "id": "2004",
            "name": "Coffee shop or café"
          },
          "2008": {
            "id": "2008",
            "name": "Health club"
          },
          "2016": {
            "id": "2016",
            "name": "Safe-deposit box at front desk"
          },
          "2017": {
            "id": "2017",
            "name": "Spa services on site"
          },
          "2043": {
            "id": "2043",
            "name": "Multilingual staff"
          },
          "2047": {
            "id": "2047",
            "name": "Free newspapers in lobby"
          },
          "2063": {
            "id": "2063",
            "name": "24-hour front desk"
          },
          "2065": {
            "id": "2065",
            "name": "Business center"
          },
          "2066": {
            "id": "2066",
            "name": "Porter/bellhop"
          },
          "2070": {
            "id": "2070",
            "name": "Dry cleaning/laundry service"
          },
          "2072": {
            "id": "2072",
            "name": "Limo or Town Car service available"
          },
          "2123": {
            "id": "2123",
            "name": "Full-service spa"
          },
          "2131": {
            "id": "2131",
            "name": "Meeting rooms"
          },
          "2134": {
            "id": "2134",
            "name": "Steam room"
          },
          "2135": {
            "id": "2135",
            "name": "Sauna"
          },
          "2137": {
            "id": "2137",
            "name": "Smoke-free property"
          },
          "2167": {
            "id": "2167",
            "name": "Wedding services"
          },
          "2387": {
            "id": "2387",
            "name": "Tours/ticket assistance"
          },
          "2390": {
            "id": "2390",
            "name": "Free WiFi"
          },
          "2392": {
            "id": "2392",
            "name": "Free wired Internet"
          },
          "2423": {
            "id": "2423",
            "name": "In-room accessibility"
          },
          "2529": {
            "id": "2529",
            "name": "Area shuttle (surcharge)"
          },
          "2537": {
            "id": "2537",
            "name": "Number of restaurants - 5",
            "value": "5"
          },
          "3861": {
            "id": "3861",
            "name": "Free self parking"
          },
          "4003": {
            "id": "4003",
            "name": "Luggage storage"
          },
          "4438": {
            "id": "4438",
            "name": "Pool sun loungers"
          },
          "4514": {
            "id": "4514",
            "name": "Terrace"
          },
          "1073744110": {
            "id": "1073744110",
            "name": "Ballroom"
          },
          "1073744430": {
            "id": "1073744430",
            "name": "Wheelchair accessible (may have limitations)"
          }
        },
        "images": [
          {
            "heroImage": true,
            "category": 3,
            "links": {
              "350Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/22167ea6_b.jpg"
              },
              "70Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/22167ea6_t.jpg"
              },
              "1000Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/22167ea6_z.jpg"
              }
            },
            "caption": "Featured Image"
          },
          {
            "heroImage": false,
            "category": 10001,
            "links": {
              "350Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/96947159_b.jpg"
              },
              "70Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/96947159_t.jpg"
              },
              "1000Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/96947159_z.jpg"
              }
            },
            "caption": "Lobby"
          },
          {
            "heroImage": false,
            "category": 10003,
            "links": {
              "350Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/490690d0_b.jpg"
              },
              "70Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/490690d0_t.jpg"
              },
              "1000Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/490690d0_z.jpg"
              }
            },
            "caption": "Lobby Sitting Area"
          },
          {
            "heroImage": false,
            "category": 21001,
            "links": {
              "350Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/b28f9690_b.jpg"
              },
              "70Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/b28f9690_t.jpg"
              },
              "1000Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/b28f9690_z.jpg"
              }
            },
            "caption": "Room"
          },
          {
            "heroImage": false,
            "category": 21001,
            "links": {
              "350Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/3e03b509_b.jpg"
              },
              "70Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/3e03b509_t.jpg"
              },
              "1000Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/3e03b509_z.jpg"
              }
            },
            "caption": "Room"
          },
          {
            "heroImage": false,
            "category": 21001,
            "links": {
              "350Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/ed7d7dad_b.jpg"
              },
              "70Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/ed7d7dad_t.jpg"
              },
              "1000Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/ed7d7dad_z.jpg"
              }
            },
            "caption": "Room"
          },
          {
            "heroImage": false,
            "category": 21001,
            "links": {
              "350Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/b277d3dc_b.jpg"
              },
              "70Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/b277d3dc_t.jpg"
              },
              "1000Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/b277d3dc_z.jpg"
              }
            },
            "caption": "Room"
          },
          {
            "heroImage": false,
            "category": 21001,
            "links": {
              "350Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/3ceb5f52_b.jpg"
              },
              "70Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/3ceb5f52_t.jpg"
              },
              "1000Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/3ceb5f52_z.jpg"
              }
            },
            "caption": "Room"
          },
          {
            "heroImage": false,
            "category": 21001,
            "links": {
              "350Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/967026d1_b.jpg"
              },
              "70Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/967026d1_t.jpg"
              },
              "1000Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/967026d1_z.jpg"
              }
            },
            "caption": "Room"
          },
          {
            "heroImage": false,
            "category": 21001,
            "links": {
              "350Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/355a3348_b.jpg"
              },
              "70Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/355a3348_t.jpg"
              },
              "1000Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/355a3348_z.jpg"
              }
            },
            "caption": "Room"
          },
          {
            "heroImage": false,
            "category": 21001,
            "links": {
              "350Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/9dcd2f78_b.jpg"
              },
              "70Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/9dcd2f78_t.jpg"
              },
              "1000Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/9dcd2f78_z.jpg"
              }
            },
            "caption": "Room"
          },
          {
            "heroImage": false,
            "category": 21001,
            "links": {
              "350Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/5b58a63c_b.jpg"
              },
              "70Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/5b58a63c_t.jpg"
              },
              "1000Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/5b58a63c_z.jpg"
              }
            },
            "caption": "Room"
          },
          {
            "heroImage": false,
            "category": 21001,
            "links": {
              "350Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/d412af8a_b.jpg"
              },
              "70Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/d412af8a_t.jpg"
              },
              "1000Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/d412af8a_z.jpg"
              }
            },
            "caption": "Room"
          },
          {
            "heroImage": false,
            "category": 21001,
            "links": {
              "350Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/54d08faa_b.jpg"
              },
              "70Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/54d08faa_t.jpg"
              },
              "1000Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/54d08faa_z.jpg"
              }
            },
            "caption": "Room"
          },
          {
            "heroImage": false,
            "category": 21001,
            "links": {
              "350Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/ef810519_b.jpg"
              },
              "70Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/ef810519_t.jpg"
              },
              "1000Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/ef810519_z.jpg"
              }
            },
            "caption": "Room"
          },
          {
            "heroImage": false,
            "category": 21001,
            "links": {
              "350Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/39502684_b.jpg"
              },
              "70Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/39502684_t.jpg"
              },
              "1000Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/39502684_z.jpg"
              }
            },
            "caption": "Room"
          },
          {
            "heroImage": false,
            "category": 21001,
            "links": {
              "350Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/fc280589_b.jpg"
              },
              "70Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/fc280589_t.jpg"
              },
              "1000Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/fc280589_z.jpg"
              }
            },
            "caption": "Room"
          },
          {
            "heroImage": false,
            "category": 21001,
            "links": {
              "350Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/72ad8128_b.jpg"
              },
              "70Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/72ad8128_t.jpg"
              },
              "1000Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/72ad8128_z.jpg"
              }
            },
            "caption": "Room"
          },
          {
            "heroImage": false,
            "category": 21001,
            "links": {
              "350Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/3d46aa46_b.jpg"
              },
              "70Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/3d46aa46_t.jpg"
              },
              "1000Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/3d46aa46_z.jpg"
              }
            },
            "caption": "Room"
          },
          {
            "heroImage": false,
            "category": 21001,
            "links": {
              "350Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/e89f3ef6_b.jpg"
              },
              "70Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/e89f3ef6_t.jpg"
              },
              "1000Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/e89f3ef6_z.jpg"
              }
            },
            "caption": "Room"
          },
          {
            "heroImage": false,
            "category": 21001,
            "links": {
              "350Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/d0bb600e_b.jpg"
              },
              "70Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/d0bb600e_t.jpg"
              },
              "1000Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/d0bb600e_z.jpg"
              }
            },
            "caption": "Room"
          },
          {
            "heroImage": false,
            "category": 22001,
            "links": {
              "350Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/be5405c7_b.jpg"
              },
              "70Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/be5405c7_t.jpg"
              },
              "1000Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/be5405c7_z.jpg"
              }
            },
            "caption": "In-Room Dining"
          },
          {
            "heroImage": false,
            "category": 22005,
            "links": {
              "350Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/71037b43_b.jpg"
              },
              "70Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/71037b43_t.jpg"
              },
              "1000Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/71037b43_z.jpg"
              }
            },
            "caption": "Living Area"
          },
          {
            "heroImage": false,
            "category": 22005,
            "links": {
              "350Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/a2f8b2f3_b.jpg"
              },
              "70Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/a2f8b2f3_t.jpg"
              },
              "1000Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/a2f8b2f3_z.jpg"
              }
            },
            "caption": "Living Area"
          },
          {
            "heroImage": false,
            "category": 23000,
            "links": {
              "350Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/31d1cb15_b.jpg"
              },
              "70Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/31d1cb15_t.jpg"
              },
              "1000Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/31d1cb15_z.jpg"
              }
            },
            "caption": "Bathroom"
          },
          {
            "heroImage": false,
            "category": 23000,
            "links": {
              "350Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/0a8b3f2c_b.jpg"
              },
              "70Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/0a8b3f2c_t.jpg"
              },
              "1000Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/0a8b3f2c_z.jpg"
              }
            },
            "caption": "Bathroom"
          },
          {
            "heroImage": false,
            "category": 30000,
            "links": {
              "350Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/167e83ec_b.jpg"
              },
              "70Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/167e83ec_t.jpg"
              },
              "1000Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/167e83ec_z.jpg"
              }
            },
            "caption": "Pool"
          },
          {
            "heroImage": false,
            "category": 30000,
            "links": {
              "350Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/64ea36f4_b.jpg"
              },
              "70Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/64ea36f4_t.jpg"
              },
              "1000Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/64ea36f4_z.jpg"
              }
            },
            "caption": "Pool"
          },
          {
            "heroImage": false,
            "category": 41001,
            "links": {
              "350Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/2b31d667_b.jpg"
              },
              "70Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/2b31d667_t.jpg"
              },
              "1000Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/2b31d667_z.jpg"
              }
            },
            "caption": "Gym"
          },
          {
            "heroImage": false,
            "category": 51000,
            "links": {
              "350Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/b89adb69_b.jpg"
              },
              "70Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/b89adb69_t.jpg"
              },
              "1000Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/b89adb69_z.jpg"
              }
            },
            "caption": "Spa"
          },
          {
            "heroImage": false,
            "category": 51000,
            "links": {
              "350Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/b9e48442_b.jpg"
              },
              "70Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/b9e48442_t.jpg"
              },
              "1000Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/b9e48442_z.jpg"
              }
            },
            "caption": "Spa"
          },
          {
            "heroImage": false,
            "category": 51000,
            "links": {
              "350Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/2fb48a66_b.jpg"
              },
              "70Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/2fb48a66_t.jpg"
              },
              "1000Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/2fb48a66_z.jpg"
              }
            },
            "caption": "Spa"
          },
          {
            "heroImage": false,
            "category": 52002,
            "links": {
              "350Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/c23b5dd7_b.jpg"
              },
              "70Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/c23b5dd7_t.jpg"
              },
              "1000Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/c23b5dd7_z.jpg"
              }
            },
            "caption": "Spa Treatment"
          },
          {
            "heroImage": false,
            "category": 52002,
            "links": {
              "350Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/20ae8154_b.jpg"
              },
              "70Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/20ae8154_t.jpg"
              },
              "1000Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/20ae8154_z.jpg"
              }
            },
            "caption": "Spa Treatment"
          },
          {
            "heroImage": false,
            "category": 81001,
            "links": {
              "350Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/9d90dd4a_b.jpg"
              },
              "70Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/9d90dd4a_t.jpg"
              },
              "1000Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/9d90dd4a_z.jpg"
              }
            },
            "caption": "Breakfast Area"
          },
          {
            "heroImage": false,
            "category": 81003,
            "links": {
              "350Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/4e93036a_b.jpg"
              },
              "70Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/4e93036a_t.jpg"
              },
              "1000Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/4e93036a_z.jpg"
              }
            },
            "caption": "Restaurant"
          },
          {
            "heroImage": false,
            "category": 81003,
            "links": {
              "350Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/23249624_b.jpg"
              },
              "70Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/23249624_t.jpg"
              },
              "1000Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/23249624_z.jpg"
              }
            },
            "caption": "Restaurant"
          },
          {
            "heroImage": false,
            "category": 81003,
            "links": {
              "350Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/925c98f3_b.jpg"
              },
              "70Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/925c98f3_t.jpg"
              },
              "1000Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/925c98f3_z.jpg"
              }
            },
            "caption": "Restaurant"
          },
          {
            "heroImage": false,
            "category": 81003,
            "links": {
              "350Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/e2de36b2_b.jpg"
              },
              "70Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/e2de36b2_t.jpg"
              },
              "1000Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/e2de36b2_z.jpg"
              }
            },
            "caption": "Restaurant"
          },
          {
            "heroImage": false,
            "category": 81003,
            "links": {
              "350Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/86e667e7_b.jpg"
              },
              "70Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/86e667e7_t.jpg"
              },
              "1000Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/86e667e7_z.jpg"
              }
            },
            "caption": "Restaurant"
          },
          {
            "heroImage": false,
            "category": 81003,
            "links": {
              "350Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/1dca3f4f_b.jpg"
              },
              "70Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/1dca3f4f_t.jpg"
              },
              "1000Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/1dca3f4f_z.jpg"
              }
            },
            "caption": "Restaurant"
          },
          {
            "heroImage": false,
            "category": 81003,
            "links": {
              "350Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/47b7f7b8_b.jpg"
              },
              "70Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/47b7f7b8_t.jpg"
              },
              "1000Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/47b7f7b8_z.jpg"
              }
            },
            "caption": "Restaurant"
          },
          {
            "heroImage": false,
            "category": 81003,
            "links": {
              "350Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/638f90fe_b.jpg"
              },
              "70Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/638f90fe_t.jpg"
              },
              "1000Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/638f90fe_z.jpg"
              }
            },
            "caption": "Restaurant"
          },
          {
            "heroImage": false,
            "category": 82001,
            "links": {
              "350Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/f38c2e0c_b.jpg"
              },
              "70Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/f38c2e0c_t.jpg"
              },
              "1000Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/f38c2e0c_z.jpg"
              }
            },
            "caption": "Lounge"
          },
          {
            "heroImage": false,
            "category": 82001,
            "links": {
              "350Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/f47e38a0_b.jpg"
              },
              "70Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/f47e38a0_t.jpg"
              },
              "1000Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/f47e38a0_z.jpg"
              }
            },
            "caption": "Lounge"
          },
          {
            "heroImage": false,
            "category": 83003,
            "links": {
              "350Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/8e931d08_b.jpg"
              },
              "70Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/8e931d08_t.jpg"
              },
              "1000Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/8e931d08_z.jpg"
              }
            },
            "caption": "Banquet Hall"
          },
          {
            "heroImage": false,
            "category": 83009,
            "links": {
              "350Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/56b127e8_b.jpg"
              },
              "70Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/56b127e8_t.jpg"
              },
              "1000Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/56b127e8_z.jpg"
              }
            },
            "caption": "Meeting Facility"
          },
          {
            "heroImage": false,
            "category": 83009,
            "links": {
              "350Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/42376bf9_b.jpg"
              },
              "70Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/42376bf9_t.jpg"
              },
              "1000Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/42376bf9_z.jpg"
              }
            },
            "caption": "Meeting Facility"
          },
          {
            "heroImage": false,
            "category": 83010,
            "links": {
              "350Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/67095c3a_b.jpg"
              },
              "70Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/67095c3a_t.jpg"
              },
              "1000Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/67095c3a_z.jpg"
              }
            },
            "caption": "Business Center"
          },
          {
            "heroImage": false,
            "category": 83011,
            "links": {
              "350Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/7a8bbb8e_b.jpg"
              },
              "70Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/7a8bbb8e_t.jpg"
              },
              "1000Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/7a8bbb8e_z.jpg"
              }
            },
            "caption": "Interior Detail"
          },
          {
            "heroImage": false,
            "category": 83011,
            "links": {
              "350Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/30629d3b_b.jpg"
              },
              "70Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/30629d3b_t.jpg"
              },
              "1000Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/30629d3b_z.jpg"
              }
            },
            "caption": "Interior Detail"
          },
          {
            "heroImage": false,
            "category": 83011,
            "links": {
              "350Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/870b8a34_b.jpg"
              },
              "70Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/870b8a34_t.jpg"
              },
              "1000Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/870b8a34_z.jpg"
              }
            },
            "caption": "Interior Detail"
          },
          {
            "heroImage": false,
            "category": 83011,
            "links": {
              "350Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/2ee56443_b.jpg"
              },
              "70Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/2ee56443_t.jpg"
              },
              "1000Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/2ee56443_z.jpg"
              }
            },
            "caption": "Interior Detail"
          },
          {
            "heroImage": false,
            "category": 91012,
            "links": {
              "350Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/ab48d4de_b.jpg"
              },
              "70Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/ab48d4de_t.jpg"
              },
              "1000Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/ab48d4de_z.jpg"
              }
            },
            "caption": "Outdoor Banquet Area"
          },
          {
            "heroImage": false,
            "category": 91012,
            "links": {
              "350Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/341f9425_b.jpg"
              },
              "70Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/341f9425_t.jpg"
              },
              "1000Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/341f9425_z.jpg"
              }
            },
            "caption": "Outdoor Banquet Area"
          },
          {
            "heroImage": false,
            "category": 91023,
            "links": {
              "350Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/0ac7ba1f_b.jpg"
              },
              "70Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/0ac7ba1f_t.jpg"
              },
              "1000Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/0ac7ba1f_z.jpg"
              }
            },
            "caption": "Exterior detail"
          },
          {
            "heroImage": false,
            "category": 92001,
            "links": {
              "350Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/29c8d020_b.jpg"
              },
              "70Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/29c8d020_t.jpg"
              },
              "1000Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/29c8d020_z.jpg"
              }
            },
            "caption": "Aerial View"
          }
        ],
        "onsitePayments": {
          "currency": "AED",
          "types": {
            "171": {
              "id": "171",
              "name": "American Express"
            },
            "179": {
              "id": "179",
              "name": "Diners Club"
            },
            "183": {
              "id": "183",
              "name": "JCB International"
            },
            "184": {
              "id": "184",
              "name": "Mastercard"
            },
            "189": {
              "id": "189",
              "name": "Visa"
            },
            "190": {
              "id": "190",
              "name": "Cash not accepted"
            },
            "1073744166": {
              "id": "1073744166",
              "name": "Debit cards not accepted"
            }
          }
        },
        "rooms": {},
        "descriptions": {
          "amenities": "Pamper yourself with a visit to the spa, which offers massages, body treatments, and facials. You're sure to appreciate the recreational amenities, including a health club, an outdoor pool, and a sauna. This Art Deco hotel also features complimentary wireless Internet access, concierge services, and gift shops/newsstands. Guests can catch a ride to nearby destinations on the area shuttle (surcharge).",
          "dining": "Enjoy Mediterranean cuisine at Armani/Mediterraneo, one of the hotel's 5 restaurants, or stay in and take advantage of the 24-hour room service. Snacks are also available at the coffee shop/café. Wrap up your day with a drink at the bar/lounge. Buffet breakfasts are available daily from 6:30 AM to 10:00 AM for a fee.",
          "businessAmenities": "Featured amenities include complimentary wired Internet access, a business center, and limo/town car service. A roundtrip airport shuttle is provided for a surcharge (available 24 hours), and free self parking is available onsite.",
          "rooms": "Make yourself at home in one of the 160 guestrooms featuring iPod docking stations and minibars. Your bed comes with down comforters and premium bedding. Wired and wireless Internet access is complimentary, while 62-inch plasma televisions with cable programming provide entertainment. Bathrooms feature separate bathtubs and showers, designer toiletries, and bidets.",
          "attractions": "Distances are displayed to the nearest 0.1 mile and kilometer. <br /> <p>Burj Khalifa - 0.4 km / 0.3 mi <br /> Dubai Opera - 0.6 km / 0.4 mi <br /> Emaar Square - 0.7 km / 0.4 mi <br /> Dubai Aquarium & Underwater Zoo - 1.2 km / 0.7 mi <br /> Dubai Fountain - 1.2 km / 0.7 mi <br /> Dubai Mall - 1.2 km / 0.8 mi <br /> KidZania - 1.3 km / 0.8 mi <br /> Souk al Bahar - 1.6 km / 1 mi <br /> Dubai Water Canal - 2.3 km / 1.4 mi <br /> City Walk - 2.6 km / 1.6 mi <br /> Dubai International Financial Centre - 2.7 km / 1.7 mi <br /> Dubai International Convention and Exhibition Centre - 4.1 km / 2.6 mi <br /> Dubai World Trade Centre - 4.7 km / 2.9 mi <br /> Dubai Garden Glow - 4.9 km / 3 mi <br /> La Mer Water Park - 5.8 km / 3.6 mi <br /> </p><p>The nearest airports are:<br />Dubai Intl. Airport (DXB) - 13.9 km / 8.6 mi<br /> Sharjah (SHJ-Sharjah Intl.) - 41.2 km / 25.6 mi<br /> Dubai (DWC-Al Maktoum Intl.) - 53.3 km / 33.1 mi<br /> </p><p>The preferred airport for Armani Hotel Dubai is Dubai Intl. Airport (DXB). </p>",
          "location": "Armani Hotel Dubai is centrally located in Dubai, a 5-minute walk from Burj Khalifa and 15 minutes by foot from Dubai Mall.  This 5-star hotel is 3.2 mi (5.1 km) from Dubai World Trade Centre and 6.1 mi (9.8 km) from Dubai Creek.",
          "headline": "Near Burj Khalifa"
        },
        "externalId": "3033052",
        "externalProvider": "EXPEDIA",
        "hotelImages": [
          {
            "heroImage": true,
            "category": 3,
            "links": {
              "350Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/22167ea6_b.jpg"
              },
              "70Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/22167ea6_t.jpg"
              },
              "1000Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/22167ea6_z.jpg"
              }
            },
            "caption": "Featured Image"
          },
          {
            "heroImage": false,
            "category": 10001,
            "links": {
              "350Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/96947159_b.jpg"
              },
              "70Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/96947159_t.jpg"
              },
              "1000Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/96947159_z.jpg"
              }
            },
            "caption": "Lobby"
          },
          {
            "heroImage": false,
            "category": 10003,
            "links": {
              "350Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/490690d0_b.jpg"
              },
              "70Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/490690d0_t.jpg"
              },
              "1000Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/490690d0_z.jpg"
              }
            },
            "caption": "Lobby Sitting Area"
          },
          {
            "heroImage": false,
            "category": 21001,
            "links": {
              "350Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/b28f9690_b.jpg"
              },
              "70Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/b28f9690_t.jpg"
              },
              "1000Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/b28f9690_z.jpg"
              }
            },
            "caption": "Room"
          },
          {
            "heroImage": false,
            "category": 21001,
            "links": {
              "350Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/3e03b509_b.jpg"
              },
              "70Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/3e03b509_t.jpg"
              },
              "1000Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/3e03b509_z.jpg"
              }
            },
            "caption": "Room"
          },
          {
            "heroImage": false,
            "category": 21001,
            "links": {
              "350Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/ed7d7dad_b.jpg"
              },
              "70Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/ed7d7dad_t.jpg"
              },
              "1000Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/ed7d7dad_z.jpg"
              }
            },
            "caption": "Room"
          },
          {
            "heroImage": false,
            "category": 21001,
            "links": {
              "350Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/b277d3dc_b.jpg"
              },
              "70Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/b277d3dc_t.jpg"
              },
              "1000Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/b277d3dc_z.jpg"
              }
            },
            "caption": "Room"
          },
          {
            "heroImage": false,
            "category": 21001,
            "links": {
              "350Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/3ceb5f52_b.jpg"
              },
              "70Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/3ceb5f52_t.jpg"
              },
              "1000Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/3ceb5f52_z.jpg"
              }
            },
            "caption": "Room"
          },
          {
            "heroImage": false,
            "category": 21001,
            "links": {
              "350Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/967026d1_b.jpg"
              },
              "70Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/967026d1_t.jpg"
              },
              "1000Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/967026d1_z.jpg"
              }
            },
            "caption": "Room"
          },
          {
            "heroImage": false,
            "category": 21001,
            "links": {
              "350Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/355a3348_b.jpg"
              },
              "70Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/355a3348_t.jpg"
              },
              "1000Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/355a3348_z.jpg"
              }
            },
            "caption": "Room"
          },
          {
            "heroImage": false,
            "category": 21001,
            "links": {
              "350Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/9dcd2f78_b.jpg"
              },
              "70Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/9dcd2f78_t.jpg"
              },
              "1000Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/9dcd2f78_z.jpg"
              }
            },
            "caption": "Room"
          },
          {
            "heroImage": false,
            "category": 21001,
            "links": {
              "350Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/5b58a63c_b.jpg"
              },
              "70Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/5b58a63c_t.jpg"
              },
              "1000Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/5b58a63c_z.jpg"
              }
            },
            "caption": "Room"
          },
          {
            "heroImage": false,
            "category": 21001,
            "links": {
              "350Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/d412af8a_b.jpg"
              },
              "70Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/d412af8a_t.jpg"
              },
              "1000Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/d412af8a_z.jpg"
              }
            },
            "caption": "Room"
          },
          {
            "heroImage": false,
            "category": 21001,
            "links": {
              "350Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/54d08faa_b.jpg"
              },
              "70Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/54d08faa_t.jpg"
              },
              "1000Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/54d08faa_z.jpg"
              }
            },
            "caption": "Room"
          },
          {
            "heroImage": false,
            "category": 21001,
            "links": {
              "350Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/ef810519_b.jpg"
              },
              "70Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/ef810519_t.jpg"
              },
              "1000Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/ef810519_z.jpg"
              }
            },
            "caption": "Room"
          },
          {
            "heroImage": false,
            "category": 21001,
            "links": {
              "350Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/39502684_b.jpg"
              },
              "70Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/39502684_t.jpg"
              },
              "1000Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/39502684_z.jpg"
              }
            },
            "caption": "Room"
          },
          {
            "heroImage": false,
            "category": 21001,
            "links": {
              "350Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/fc280589_b.jpg"
              },
              "70Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/fc280589_t.jpg"
              },
              "1000Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/fc280589_z.jpg"
              }
            },
            "caption": "Room"
          },
          {
            "heroImage": false,
            "category": 21001,
            "links": {
              "350Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/72ad8128_b.jpg"
              },
              "70Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/72ad8128_t.jpg"
              },
              "1000Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/72ad8128_z.jpg"
              }
            },
            "caption": "Room"
          },
          {
            "heroImage": false,
            "category": 21001,
            "links": {
              "350Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/3d46aa46_b.jpg"
              },
              "70Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/3d46aa46_t.jpg"
              },
              "1000Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/3d46aa46_z.jpg"
              }
            },
            "caption": "Room"
          },
          {
            "heroImage": false,
            "category": 21001,
            "links": {
              "350Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/e89f3ef6_b.jpg"
              },
              "70Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/e89f3ef6_t.jpg"
              },
              "1000Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/e89f3ef6_z.jpg"
              }
            },
            "caption": "Room"
          },
          {
            "heroImage": false,
            "category": 21001,
            "links": {
              "350Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/d0bb600e_b.jpg"
              },
              "70Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/d0bb600e_t.jpg"
              },
              "1000Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/d0bb600e_z.jpg"
              }
            },
            "caption": "Room"
          },
          {
            "heroImage": false,
            "category": 22001,
            "links": {
              "350Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/be5405c7_b.jpg"
              },
              "70Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/be5405c7_t.jpg"
              },
              "1000Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/be5405c7_z.jpg"
              }
            },
            "caption": "In-Room Dining"
          },
          {
            "heroImage": false,
            "category": 22005,
            "links": {
              "350Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/71037b43_b.jpg"
              },
              "70Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/71037b43_t.jpg"
              },
              "1000Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/71037b43_z.jpg"
              }
            },
            "caption": "Living Area"
          },
          {
            "heroImage": false,
            "category": 22005,
            "links": {
              "350Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/a2f8b2f3_b.jpg"
              },
              "70Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/a2f8b2f3_t.jpg"
              },
              "1000Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/a2f8b2f3_z.jpg"
              }
            },
            "caption": "Living Area"
          },
          {
            "heroImage": false,
            "category": 23000,
            "links": {
              "350Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/31d1cb15_b.jpg"
              },
              "70Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/31d1cb15_t.jpg"
              },
              "1000Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/31d1cb15_z.jpg"
              }
            },
            "caption": "Bathroom"
          },
          {
            "heroImage": false,
            "category": 23000,
            "links": {
              "350Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/0a8b3f2c_b.jpg"
              },
              "70Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/0a8b3f2c_t.jpg"
              },
              "1000Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/0a8b3f2c_z.jpg"
              }
            },
            "caption": "Bathroom"
          },
          {
            "heroImage": false,
            "category": 30000,
            "links": {
              "350Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/167e83ec_b.jpg"
              },
              "70Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/167e83ec_t.jpg"
              },
              "1000Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/167e83ec_z.jpg"
              }
            },
            "caption": "Pool"
          },
          {
            "heroImage": false,
            "category": 30000,
            "links": {
              "350Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/64ea36f4_b.jpg"
              },
              "70Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/64ea36f4_t.jpg"
              },
              "1000Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/64ea36f4_z.jpg"
              }
            },
            "caption": "Pool"
          },
          {
            "heroImage": false,
            "category": 41001,
            "links": {
              "350Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/2b31d667_b.jpg"
              },
              "70Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/2b31d667_t.jpg"
              },
              "1000Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/2b31d667_z.jpg"
              }
            },
            "caption": "Gym"
          },
          {
            "heroImage": false,
            "category": 51000,
            "links": {
              "350Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/b89adb69_b.jpg"
              },
              "70Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/b89adb69_t.jpg"
              },
              "1000Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/b89adb69_z.jpg"
              }
            },
            "caption": "Spa"
          },
          {
            "heroImage": false,
            "category": 51000,
            "links": {
              "350Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/b9e48442_b.jpg"
              },
              "70Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/b9e48442_t.jpg"
              },
              "1000Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/b9e48442_z.jpg"
              }
            },
            "caption": "Spa"
          },
          {
            "heroImage": false,
            "category": 51000,
            "links": {
              "350Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/2fb48a66_b.jpg"
              },
              "70Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/2fb48a66_t.jpg"
              },
              "1000Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/2fb48a66_z.jpg"
              }
            },
            "caption": "Spa"
          },
          {
            "heroImage": false,
            "category": 52002,
            "links": {
              "350Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/c23b5dd7_b.jpg"
              },
              "70Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/c23b5dd7_t.jpg"
              },
              "1000Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/c23b5dd7_z.jpg"
              }
            },
            "caption": "Spa Treatment"
          },
          {
            "heroImage": false,
            "category": 52002,
            "links": {
              "350Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/20ae8154_b.jpg"
              },
              "70Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/20ae8154_t.jpg"
              },
              "1000Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/20ae8154_z.jpg"
              }
            },
            "caption": "Spa Treatment"
          },
          {
            "heroImage": false,
            "category": 81001,
            "links": {
              "350Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/9d90dd4a_b.jpg"
              },
              "70Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/9d90dd4a_t.jpg"
              },
              "1000Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/9d90dd4a_z.jpg"
              }
            },
            "caption": "Breakfast Area"
          },
          {
            "heroImage": false,
            "category": 81003,
            "links": {
              "350Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/4e93036a_b.jpg"
              },
              "70Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/4e93036a_t.jpg"
              },
              "1000Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/4e93036a_z.jpg"
              }
            },
            "caption": "Restaurant"
          },
          {
            "heroImage": false,
            "category": 81003,
            "links": {
              "350Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/23249624_b.jpg"
              },
              "70Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/23249624_t.jpg"
              },
              "1000Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/23249624_z.jpg"
              }
            },
            "caption": "Restaurant"
          },
          {
            "heroImage": false,
            "category": 81003,
            "links": {
              "350Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/925c98f3_b.jpg"
              },
              "70Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/925c98f3_t.jpg"
              },
              "1000Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/925c98f3_z.jpg"
              }
            },
            "caption": "Restaurant"
          },
          {
            "heroImage": false,
            "category": 81003,
            "links": {
              "350Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/e2de36b2_b.jpg"
              },
              "70Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/e2de36b2_t.jpg"
              },
              "1000Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/e2de36b2_z.jpg"
              }
            },
            "caption": "Restaurant"
          },
          {
            "heroImage": false,
            "category": 81003,
            "links": {
              "350Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/86e667e7_b.jpg"
              },
              "70Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/86e667e7_t.jpg"
              },
              "1000Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/86e667e7_z.jpg"
              }
            },
            "caption": "Restaurant"
          },
          {
            "heroImage": false,
            "category": 81003,
            "links": {
              "350Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/1dca3f4f_b.jpg"
              },
              "70Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/1dca3f4f_t.jpg"
              },
              "1000Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/1dca3f4f_z.jpg"
              }
            },
            "caption": "Restaurant"
          },
          {
            "heroImage": false,
            "category": 81003,
            "links": {
              "350Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/47b7f7b8_b.jpg"
              },
              "70Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/47b7f7b8_t.jpg"
              },
              "1000Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/47b7f7b8_z.jpg"
              }
            },
            "caption": "Restaurant"
          },
          {
            "heroImage": false,
            "category": 81003,
            "links": {
              "350Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/638f90fe_b.jpg"
              },
              "70Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/638f90fe_t.jpg"
              },
              "1000Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/638f90fe_z.jpg"
              }
            },
            "caption": "Restaurant"
          },
          {
            "heroImage": false,
            "category": 82001,
            "links": {
              "350Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/f38c2e0c_b.jpg"
              },
              "70Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/f38c2e0c_t.jpg"
              },
              "1000Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/f38c2e0c_z.jpg"
              }
            },
            "caption": "Lounge"
          },
          {
            "heroImage": false,
            "category": 82001,
            "links": {
              "350Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/f47e38a0_b.jpg"
              },
              "70Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/f47e38a0_t.jpg"
              },
              "1000Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/f47e38a0_z.jpg"
              }
            },
            "caption": "Lounge"
          },
          {
            "heroImage": false,
            "category": 83003,
            "links": {
              "350Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/8e931d08_b.jpg"
              },
              "70Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/8e931d08_t.jpg"
              },
              "1000Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/8e931d08_z.jpg"
              }
            },
            "caption": "Banquet Hall"
          },
          {
            "heroImage": false,
            "category": 83009,
            "links": {
              "350Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/56b127e8_b.jpg"
              },
              "70Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/56b127e8_t.jpg"
              },
              "1000Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/56b127e8_z.jpg"
              }
            },
            "caption": "Meeting Facility"
          },
          {
            "heroImage": false,
            "category": 83009,
            "links": {
              "350Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/42376bf9_b.jpg"
              },
              "70Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/42376bf9_t.jpg"
              },
              "1000Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/42376bf9_z.jpg"
              }
            },
            "caption": "Meeting Facility"
          },
          {
            "heroImage": false,
            "category": 83010,
            "links": {
              "350Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/67095c3a_b.jpg"
              },
              "70Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/67095c3a_t.jpg"
              },
              "1000Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/67095c3a_z.jpg"
              }
            },
            "caption": "Business Center"
          },
          {
            "heroImage": false,
            "category": 83011,
            "links": {
              "350Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/7a8bbb8e_b.jpg"
              },
              "70Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/7a8bbb8e_t.jpg"
              },
              "1000Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/7a8bbb8e_z.jpg"
              }
            },
            "caption": "Interior Detail"
          },
          {
            "heroImage": false,
            "category": 83011,
            "links": {
              "350Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/30629d3b_b.jpg"
              },
              "70Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/30629d3b_t.jpg"
              },
              "1000Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/30629d3b_z.jpg"
              }
            },
            "caption": "Interior Detail"
          },
          {
            "heroImage": false,
            "category": 83011,
            "links": {
              "350Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/870b8a34_b.jpg"
              },
              "70Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/870b8a34_t.jpg"
              },
              "1000Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/870b8a34_z.jpg"
              }
            },
            "caption": "Interior Detail"
          },
          {
            "heroImage": false,
            "category": 83011,
            "links": {
              "350Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/2ee56443_b.jpg"
              },
              "70Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/2ee56443_t.jpg"
              },
              "1000Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/2ee56443_z.jpg"
              }
            },
            "caption": "Interior Detail"
          },
          {
            "heroImage": false,
            "category": 91012,
            "links": {
              "350Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/ab48d4de_b.jpg"
              },
              "70Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/ab48d4de_t.jpg"
              },
              "1000Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/ab48d4de_z.jpg"
              }
            },
            "caption": "Outdoor Banquet Area"
          },
          {
            "heroImage": false,
            "category": 91012,
            "links": {
              "350Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/341f9425_b.jpg"
              },
              "70Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/341f9425_t.jpg"
              },
              "1000Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/341f9425_z.jpg"
              }
            },
            "caption": "Outdoor Banquet Area"
          },
          {
            "heroImage": false,
            "category": 91023,
            "links": {
              "350Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/0ac7ba1f_b.jpg"
              },
              "70Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/0ac7ba1f_t.jpg"
              },
              "1000Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/0ac7ba1f_z.jpg"
              }
            },
            "caption": "Exterior detail"
          },
          {
            "heroImage": false,
            "category": 92001,
            "links": {
              "350Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/29c8d020_b.jpg"
              },
              "70Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/29c8d020_t.jpg"
              },
              "1000Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/29c8d020_z.jpg"
              }
            },
            "caption": "Aerial View"
          }
        ],
        "general": {
          "1073744992": "Property is cleaned with disinfectant",
          "1073744994": "Staff wears personal protective equipment",
          "1073744996": "Protective clothing is available to guests",
          "1073744997": "Masks are available to guests",
          "1073744998": "Gloves are available to guests",
          "1073745002": "Guests are provided with free hand sanitizer",
          "1073745004": "Social distancing measures are in place",
          "1073745006": "Contactless check-in is available",
          "1073745013": "Property confirms they are implementing enhanced cleaning measures",
          "1073745015": "Individually-wrapped food options are available",
          "1073745019": "Individually-wrapped food options are available through room service",
          "1073745045": "Staff temperature checks are conducted regularly",
          "1073745047": "Temperature checks are available to guests",
          "1073745051": "Commonly-touched surfaces are cleaned with disinfectant",
          "1073745053": "Property confirms they are implementing guest safety measures",
          "1073745055": "Contactless check-out is available"
        },
        "pets": {
          "2050": "Pets not allowed",
          "2808": "Service animals not allowed"
        },
        "image": [
          {
            "heroImage": true,
            "category": 3,
            "links": {
              "350Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/22167ea6_b.jpg"
              },
              "70Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/22167ea6_t.jpg"
              },
              "1000Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/22167ea6_z.jpg"
              }
            },
            "caption": "Featured Image"
          },
          {
            "heroImage": false,
            "category": 10001,
            "links": {
              "350Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/96947159_b.jpg"
              },
              "70Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/96947159_t.jpg"
              },
              "1000Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/96947159_z.jpg"
              }
            },
            "caption": "Lobby"
          },
          {
            "heroImage": false,
            "category": 10003,
            "links": {
              "350Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/490690d0_b.jpg"
              },
              "70Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/490690d0_t.jpg"
              },
              "1000Px": {
                "method": "GET",
                "href": "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/490690d0_z.jpg"
              }
            },
            "caption": "Lobby Sitting Area"
          }
        ],
        "topAmenities": {
          "3": "Bar/lounge",
          "24": "Outdoor pool",
          "2390": "Free WiFi"
        },
        "ha": [
          "BAR",
          "POOL",
          "WIFI"
        ],
        "attractions": "Distances are displayed to the nearest 0.1 mile and kilometer. </li><li> <ul><li>Burj Khalifa - 0.4 km / 0.3 mi </li><li> Dubai Opera - 0.6 km / 0.4 mi </li><li> Emaar Square - 0.7 km / 0.4 mi </li><li> Dubai Aquarium & Underwater Zoo - 1.2 km / 0.7 mi </li><li> Dubai Fountain - 1.2 km / 0.7 mi </li><li> Dubai Mall - 1.2 km / 0.8 mi </li><li> KidZania - 1.3 km / 0.8 mi </li><li> Souk al Bahar - 1.6 km / 1 mi </li><li> Dubai Water Canal - 2.3 km / 1.4 mi </li><li> City Walk - 2.6 km / 1.6 mi </li><li> Dubai International Financial Centre - 2.7 km / 1.7 mi </li><li> Dubai International Convention and Exhibition Centre - 4.1 km / 2.6 mi </li><li> Dubai World Trade Centre - 4.7 km / 2.9 mi </li><li> Dubai Garden Glow - 4.9 km / 3 mi </li><li> La Mer Water Park - 5.8 km / 3.6 mi </li><li> </li></ul><ul><li>The nearest airports are:</li><li>Dubai Intl. Airport (DXB) - 13.9 km / 8.6 mi</li><li> Sharjah (SHJ-Sharjah Intl.) - 41.2 km / 25.6 mi</li><li> Dubai (DWC-Al Maktoum Intl.) - 53.3 km / 33.1 mi</li><li> </li></ul><ul><li>The preferred airport for Armani Hotel Dubai is Dubai Intl. Airport (DXB). </li></ul>",
        "phone_no": "971-4-8883888",
        "email": "",
        "guestreview": {
          "cleaniness": "4.7",
          "staff_service": "4.6",
          "amenities": "4.5",
          "conditions_facilities": "4.6"
        },
        "rat": "5.0",
        "urat": "4.6",
        "userreview": "Pamper yourself with a visit to the spa, which offers massages, body treatments, and facials. You're sure to appreciate the recreational amenities, including a health club, an outdoor pool, and a sauna. This Art Deco hotel also features complimentary wireless Internet access, concierge services, and gift shops/newsstands. Guests can catch a ride to nearby destinations on the area shuttle (surcharge).",
        "expediareview": 573,
        "pid": "3033052",
        "nearby": "Burj Khalifa - 0.4 km / 0.3 mi ",
        "onsite_payments": {
          "currency": "AED",
          "types": {
            "171": {
              "id": "171",
              "name": "American Express"
            },
            "179": {
              "id": "179",
              "name": "Diners Club"
            },
            "183": {
              "id": "183",
              "name": "JCB International"
            },
            "184": {
              "id": "184",
              "name": "Mastercard"
            },
            "189": {
              "id": "189",
              "name": "Visa"
            },
            "190": {
              "id": "190",
              "name": "Cash not accepted"
            },
            "1073744166": {
              "id": "1073744166",
              "name": "Debit cards not accepted"
            }
          }
        },
        "ra": [
          "BAR",
          "POOL",
          "WIFI"
        ],
        "cin": {
          "beginTime": "3:00 PM",
          "endTime": "midnight",
          "instructions": "Extra-person charges may apply and vary depending on property policy Government-issued photo identification and a credit card, debit card, or cash deposit may be required at check-in for incidental charges Special requests are subject to availability upon check-in and may incur additional charges; special requests cannot be guaranteed This property accepts credit cards; cash is not accepted Safety features at this property include a fire extinguisher, a smoke detector, a security system, and a first aid kit Please note that cultural norms and guest policies may differ by country and by property; the policies listed are provided by the property Alcohol is not available via room service for Room (1 Bedroom Residence) and Armani Two Bedroom Residence categories.",
          "specialInstructions": "Front desk staff will greet guests on arrival. For more details, please contact the property using the information on the booking confirmation.  A tourism fee is imposed by the city and collected at the property. For hotel stays, the fee is AED 20 for the first bedroom per night, and increases by AED 20 per night for each additional bedroom. For residence stays, the fee is AED 10 for the first bedroom per night, and increases by AED 10 per night for each additional bedroom.",
          "minAge": 18
        },
        "cout": {
          "time": "12:00 PM"
        },
        "cat": "Hotel",
        "lat": 25.197116,
        "lng": 55.274058,
        "country": "AE",
        "city": "Dubai",
        "add": "Burj Khalifa, Mohamed Bin Rashid Blvd",
        "pcd": "",
        "prov": "",
        "htnm": "Armani Hotel Dubai",
        "roomData": {},
        "hImages": [
          "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/22167ea6_z.jpg",
          "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/96947159_z.jpg",
          "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/490690d0_z.jpg",
          "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/b28f9690_z.jpg",
          "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/3e03b509_z.jpg",
          "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/ed7d7dad_z.jpg",
          "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/b277d3dc_z.jpg",
          "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/3ceb5f52_z.jpg",
          "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/967026d1_z.jpg",
          "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/355a3348_z.jpg",
          "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/9dcd2f78_z.jpg",
          "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/5b58a63c_z.jpg",
          "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/d412af8a_z.jpg",
          "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/54d08faa_z.jpg",
          "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/ef810519_z.jpg",
          "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/39502684_z.jpg",
          "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/fc280589_z.jpg",
          "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/72ad8128_z.jpg",
          "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/3d46aa46_z.jpg",
          "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/e89f3ef6_z.jpg",
          "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/d0bb600e_z.jpg",
          "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/be5405c7_z.jpg",
          "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/71037b43_z.jpg",
          "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/a2f8b2f3_z.jpg",
          "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/31d1cb15_z.jpg",
          "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/0a8b3f2c_z.jpg",
          "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/167e83ec_z.jpg",
          "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/64ea36f4_z.jpg",
          "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/2b31d667_z.jpg",
          "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/b89adb69_z.jpg",
          "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/b9e48442_z.jpg",
          "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/2fb48a66_z.jpg",
          "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/c23b5dd7_z.jpg",
          "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/20ae8154_z.jpg",
          "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/9d90dd4a_z.jpg",
          "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/4e93036a_z.jpg",
          "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/23249624_z.jpg",
          "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/925c98f3_z.jpg",
          "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/e2de36b2_z.jpg",
          "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/86e667e7_z.jpg",
          "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/1dca3f4f_z.jpg",
          "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/47b7f7b8_z.jpg",
          "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/638f90fe_z.jpg",
          "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/f38c2e0c_z.jpg",
          "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/f47e38a0_z.jpg",
          "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/8e931d08_z.jpg",
          "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/56b127e8_z.jpg",
          "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/42376bf9_z.jpg",
          "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/67095c3a_z.jpg",
          "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/7a8bbb8e_z.jpg",
          "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/30629d3b_z.jpg",
          "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/870b8a34_z.jpg",
          "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/2ee56443_z.jpg",
          "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/ab48d4de_z.jpg",
          "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/341f9425_z.jpg",
          "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/0ac7ba1f_z.jpg",
          "https://i.travelapi.com/hotels/4000000/3040000/3033100/3033052/29c8d020_z.jpg"
        ]
      },
      "status": "success",
      "error": null
    },
    "status": 200,
    "statusText": "",
    "headers": {
      "content-type": "application/json; charset=utf-8"
    },
    "config": {
      "transitional": {
        "silentJSONParsing": true,
        "forcedJSONParsing": true,
        "clarifyTimeoutError": false
      },
      "adapter": [
        "xhr",
        "http"
      ],
      "transformRequest": [
        null
      ],
      "transformResponse": [
        null
      ],
      "timeout": 0,
      "xsrfCookieName": "XSRF-TOKEN",
      "xsrfHeaderName": "X-XSRF-TOKEN",
      "maxContentLength": -1,
      "maxBodyLength": -1,
      "env": {},
      "headers": {
        "Accept": "application/json, text/plain, */*"
      },
      "method": "get",
      "url": "https://b2c-hotel-content-dev.clickntravel.com/cnt/properties_review/?skip=0&limit=5&type=prid&expHtlId=3033052"
    },
    "request": {}
  }