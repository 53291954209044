/**
 * @ Author: Srikanth Bandaru
 * @ Create Time: 2023-04-27 15:42:53
 * @ Description: Page not found component when the route isn't exists
 */

import { Typography } from 'antd';
import React from 'react'
import {useContext} from 'react';
import { Context } from "../../App";
import Header from './Header';
import Footer from './Footer';
import PageError from "../../assets/img/not-found.png";

const { Title } = Typography;

function PageNotFound() {
    const [context, setContext] = useContext(Context)
const update=(type)=>{
}
  return (
    <>
        <Header setTab={update} />
        <div className='errorOuterWrap pageNotFound'>
            <div className='errorInnerWrap pageNotFound'>
                <center>
                    <img src={PageError} alt='Page-error' />
                    <Title level={2}>{context.translation && context.translation.pagenotfound}</Title>
                    <p>{ context.translation && context.translation.trrcnbfots}</p>
                </center>
            </div>
        </div>
        <Footer />
      </>
 )
}

export default PageNotFound;