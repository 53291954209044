import React,{useEffect, useState} from "react";
import { Row, Col, Typography, Tooltip } from "antd";
import { useContext } from "react";
import { Context } from "../../App";
const { Title } = Typography;

function MyTripsPassengersDetails(props) {
  const [enableParentCls,setEnableParentCls] = useState(true)
  const [context, setContext] = useContext(Context)
  useEffect(() => {
    if(props.sup!=="0"){
      setEnableParentCls(false);
    }
  }, [props.sup]);

  return props.psngrs && props.psngrs.length > 0 ? (
    <>
      <Title level={3} className="heading">
       {context.translation && context.translation.travellerDetail}
      </Title>
      <div className="tableScroll">
        <div className="tableWrap">
        <Row className="tableHeader">
          <Col span={6} className="tableValue">
            {context.translation && context.translation.traveller}
          </Col>
          <Col span={4} className="tableValue">
            {context.translation && context.translation.ticketNo}
          </Col>
          {props.sup==="0" &&
          <>
          <Col span={5} className="tableValue">
          {context.translation && context.translation.specialReq}
          </Col>
          <Col span={6} className="tableValue">
          {context.translation && context.translation.ff}  #
          </Col>
          </>
          }
          {props.sup!=="0" &&
          <>
          <Col span={4} className="tableValue">
          {context.translation && context.translation.bagg}
          </Col>
          <Col span={4} className="tableValue">
          {context.translation && context.translation.meal}
          </Col>
          <Col span={3} className="tableValue">
          {context.translation && context.translation.seat}
          </Col>
          </>
          }
          <Col span={3} className="tableValue">
            {context.translation && context.translation.status}
          </Col>
        </Row>
        {props.psngrs && props.psngrs.length > 0 && props.psngrs.map((pax, index) => (
        <React.Fragment key={index}>
        <Row className="tableData alignPaxDetails">
          <Col span={6} className="tableValue">
          {pax.tl} {pax.fn} {pax.ln} ({pax.ty})
          </Col>
          
          {pax.tkt!==undefined && pax.tkt!=="" &&
          <Col span={4} className="tableValue">
          {pax.tkt}
          </Col>
          }
          {(pax.tkt===undefined || pax.tkt==="") && 
          <Col span={4} className="tableValue">
          {context.translation && context.translation.na}
          </Col>
          }
          {props.sup==="0" && pax.opref!=undefined &&
          <Col span={5} className="tableValue">
            {pax.opref.sa || pax.opref.sp || pax.opref.mp ?
              <>
                {/* {pax.opref.sa!=""?pax.opref.sa+" / ":""} {pax.opref.sp!==""?pax.opref.sp+" / ":""} {pax.opref.mp!==""?pax.opref.mp:""}  */}
                {pax.opref.sa && pax.opref.sp ==="" && pax.opref.mp ==="" && (
                  <>
                    {pax.opref.sa}
                  </>
                )}
                {pax.opref.sp && pax.opref.sa === "" && pax.opref.mp === "" && (
                  <>
                    {pax.opref.sp}
                  </>
                )}
                {pax.opref.mp && pax.opref.sp === "" && pax.opref.sa === "" && (
                  <>
                    {pax.opref.mp}
                  </>
                )}
                {pax.opref.sa && pax.opref.sp && pax.opref.mp === "" && (
                  <>
                    {pax.opref.sa + " / " + pax.opref.sp}
                  </>
                )}
                {pax.opref.sa && pax.opref.mp && pax.opref.sp === "" && (
                  <>
                    {pax.opref.sa + " / " + pax.opref.mp}
                  </>
                )}
                {pax.opref.sp && pax.opref.mp && pax.opref.sa === "" && (
                  <>
                    {pax.opref.sp + " / " + pax.opref.mp}
                  </>
                )}
                {pax.opref.sa && pax.opref.sp && pax.opref.mp && (
                  <>
                    {pax.opref.sa + " / " + pax.opref.sp + " / " + pax.opref.mp}
                  </>
                )}
              </> : context.translation && context.translation.na
            }
          </Col>
          }

          {props.sup==="0" && pax.ffinfo!=undefined &&
          <Col span={6} className="tableValue">
          {pax.ffinfo.ffnum!==""?pax.ffinfo.ffnum:context.translation && context.translation.na}
          </Col>
          }
          {props.sup!=="0" &&
          <>
          <Col span={4} className="tableValue">
          {pax.baggage!=undefined && pax.baggage? pax.baggage.label:context.translation && context.translation.na}
          <br/>
          </Col>
          <Col span={4} className="tableValue 123">
          {pax.meal!=undefined && pax.meal? pax.meal.label:context.translation && context.translation.na}

          </Col>
          <Col span={3} className="tableValue">
          {pax.seat!==undefined && pax.seat? pax.seat?.label?.split(",").filter((s) => s !== "-").join(","):context.translation && context.translation.na}
      
          </Col>
          </>
          }
          <Col span={3} className="tableValue active">
          {pax.tktst}
          </Col>      
        </Row>

        {pax.inf!==undefined && pax.inf!=="" && (
        <Row className="tableData alignPaxDetails">
          <Col span={6} className="tableValue">
              {pax.inf.tl} {pax.inf.fn} {pax.inf.ln} ({pax.inf.ty})
          </Col>
          
          {pax.tkt!==undefined && pax.tkt!=="" &&
          <Col span={4} className="tableValue">
          {pax.inf.tkt!=="" &&
              <>
              {pax.inf.tkt}
              </>
          }
          </Col>
          }
            {(pax.tkt===undefined || pax.tkt==="") && 
            <Col span={4} className="tableValue">
                {context.translation && context.translation.na}
            </Col>
            }
            {props.sup==="0" && pax.opref!=undefined &&
            <Col span={5} className="tableValue">
              {context.translation && context.translation.na}
            </Col>
            }

          {props.sup==="0" && pax.ffinfo!=undefined &&
            <Col span={6} className="tableValue">
              {context.translation && context.translation.na}
            </Col>
          }
          {props.sup!=="0" &&
            <>
              <Col span={4} className="tableValue">
                {context.translation && context.translation.na}
              </Col>
              <Col span={4} className="tableValue 123">
                {context.translation && context.translation.na}
              </Col>
              <Col span={3} className="tableValue">      
                {context.translation && context.translation.na}
              </Col>
            </>
          }
          <Col span={3} className="tableValue active">
            {pax.inf.tktst?pax.inf.tktst:pax.tktst} 
          </Col>      
        </Row>

        )}
        </React.Fragment>
        ))}
        </div>
      </div>
    </>
  ) : null;
}

export default MyTripsPassengersDetails;
