import React, { useState } from 'react';
import { useContext } from 'react';
import { Context } from '../../../../App';
import { Row, Col, Typography, Tooltip, Modal } from "antd";
import Link from "antd/es/typography/Link";
import FormatCurrency from '../../../utilities/FormatCurrency';
import Utilities from '../../../utilities/Utilities';
import BrandIcon from "../../../../assets/gif/Forever/White/H_WHITE_FOREVER.gif";
const { Title } = Typography;

function HotelRoomPrice(props) {
  const [context, setContext] = useContext(Context)
  const [taxesInfoModal, setTaxesInfoModal] = useState(false);
  //Calculating the TAX
 const calServiceFee = (rooms) => {
  let serviceFee=0;
  for(let room of rooms){
  if(serviceFee===0){
    if(room.rmpr[0].servicefee.length > 0){
      serviceFee=parseFloat(room.rmpr[0].servicefee[0].sf)
    }
  }else{
    if(room.rmpr[0].servicefee.length > 0){
      serviceFee=parseFloat(serviceFee) + parseFloat(room.rmpr[0].servicefee[0].sf)
    }
  }
  }
  return serviceFee * props.srequest.base_usr_exrate;
}
const calTotalPrice = (rooms) => {
  let totalFare=0;
  for(let room of rooms){
  if(totalFare===0){
    totalFare=room.rmpr[0].pr[0].tf
    if(room.rmpr[0].servicefee.length>0){
      totalFare=parseFloat(totalFare)+parseFloat(room.rmpr[0].servicefee[0].sf);
    }
  }else{
    totalFare=parseFloat(totalFare)+parseFloat(room.rmpr[0].pr[0].tf);
    if(room.rmpr[0].servicefee.length>0){
      totalFare=parseFloat(totalFare)+ parseFloat(room.rmpr[0].servicefee[0].sf);
    }
  }
  let disc=Utilities.hotelDiscount(room.rmpr[0].pr[0].tax);
  if(disc!==undefined && disc!==0){
    totalFare=parseFloat(totalFare)-parseFloat(disc);
  }
  }
  return totalFare;
}

const showAmenities =(roomData)=>{
  let brdbase=[];
  if (roomData && roomData.rmpr && roomData.rmpr[0].ra) {
      for (let taxIndex = 0; taxIndex < roomData.rmpr[0].ra.length; taxIndex++) {
        var mak = roomData.rmpr[0].ra[taxIndex];
        if (mak.id !== "2109" && mak.id !== "2195" && mak.id !== "2192" && mak.id !== "2191" ) {
          brdbase.push(mak.name);
        }
      }
  }
  if (brdbase.length === 0) {
      brdbase.push("Room Only");
    }
  const strdata = brdbase.join(', ');
  return strdata
}

  const handleClick = (e) => {

    let cn = e.currentTarget.parentElement.classList;

    if (cn.contains('active')) {
      cn.remove('active')
    } else {
      cn.add('active')
    }
  };

  const taxesInfo = ()=>{
    setTaxesInfoModal(true);
  }

  const handelCancel = ()=>{
    setTaxesInfoModal(false);
  }

  

  return (
    <>
   
      {/* <div className="bSummary">
        <Title level={4}>{context.translation && context.translation.bokSumy}</Title>
      </div>
      <div className="innerBSSummary">
      {props.srequest.rooms && props.srequest.rooms.map((room, index) => (
        <div className="bookSummary">
          <Row>
            <Col span={24}>
              <div className="dFlex">
                <span className="roomTitle">{context.translation && context.translation.room} {room.rmindex}</span>
                <span className="boardBasis">
                  {context.translation && context.translation.boardBasis} 
                  <Tooltip placement="bottom" title={showAmenities(props.srequest.rooms)}>
                  <span><i className="icon icon-Info"> </i></span>
                  </Tooltip>
                </span>
              </div>
              <div className="adultChild">{room.adt} {context.translation && context.translation.adts}  {room.chd!==0?room.chd +"Childs":""} </div>
            </Col>
          </Row>
          <Row>
            <Col md={24}>
              <div className="bookSummaryCollapse">
                
                  <div className={" dFlex collapseHeader " + (index===0 && 'active') } onClick={handleClick}>
                    <div className='collapseArrow'>
                      <div className="rightArrow">
                        <span className="pax">
                        {room.rnm}
                        </span>
                      </div>
                    </div>
                    <div>
                      <span className="currencyCode">{props.srequest.cur}</span>
                      &nbsp;
                      <span className="currencyAmount"> <FormatCurrency value={parseFloat(room.rmpr[0].pr[0].tf) - Utilities.hotelDiscount(room.rmpr[0].pr[0].tax)} /></span>
                    </div>
                  </div>
                 
                <div className="innerBody">
                  <div className="dFlex">
                    <div>
                      <span className="fare">{context.translation && context.translation.baseFare}</span>
                    </div>
                    <div>
                      <span className="currencyCode">{props.srequest.cur}</span>
                      &nbsp;
                      <span className="currencyAmount"><FormatCurrency value={parseFloat(room.rmpr[0].pr[0].bf)} /></span>
                    </div>
                  </div>
                  <div className="dFlex">
                    <div>
                      <span className="taxes">{context.translation && context.translation.taxNfee}</span>
                    </div>
                    <div>
                      <span className="currencyCode">{props.srequest.cur}</span>
                      &nbsp;
                      <span className="currencyAmount"><FormatCurrency value={Utilities.hotelTaxCalculation(room.rmpr[0].pr[0].tax)} /></span>
                    </div>
                  </div>
                  <div className="dFlex discountWrapper">
                    <div>
                      <span className="discount">{context.translation && context.translation.discount}</span>
                    </div>
                    <div>
                      <span className="currencyCode">{props.srequest.cur}</span>
                      &nbsp;
                      <span className="currencyAmount"><FormatCurrency value={Utilities.hotelDiscount(room.rmpr[0].pr[0].tax)} /></span>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <div className="innerSumDivider"></div>
        </div>
         ))}
        <Row>
          <Col md={24}>
            <div className="serviceDiscount">
              <div className="dFlex serviceWrapper">
                <div>
                  <span className="serviceFee">{context.translation && context.translation.serFee}</span>
                </div>
                <div>
                  <span className="currencyCode">{props.srequest.cur} </span>
                  &nbsp;
                  <span className="currencyAmount"><FormatCurrency value={calServiceFee(props.srequest.rooms)} /></span>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={24}>
            <div className="dFlex payablePriceWrapper">
              <div>
                <span className="payablePrice">
                  {context.translation && context.translation.totPayPrice}
                </span>
                <span className="allNights">({props.srequest.nonights} {context.translation && context.translation.nights})</span>
              </div>
              <div>
                <span className="currencyCode">{props.srequest.cur} </span>
                <span className="currencyAmount"><FormatCurrency value={calTotalPrice(props.srequest.rooms)} /></span>
              </div>
            </div>
          </Col>
          </Row>
      </div> */}

      <div className='outerSummary sticky reviewPage'>
          <div className='bSumWrap'>
            <Title level={4} className='summaryMainHeading'>
              {context.translation && context.translation.bokSumy}
              <img src={BrandIcon} alt="brandIcon" className="hotelBrandIcon"></img>
            </Title>
          </div>
          <div className="summaryShape"></div>
          <div className="innerSummary">
            {props.srequest.rooms && props.srequest.rooms.map((room, index) => (
              <div className="paxDetailWrap">
                    <div className="dFlex">
                      <span className="innerSumTitle">{context.translation && context.translation.room} {room.rmindex}</span>
                      <span className="groupBasis">
                        <span className="boardBasis">{context.translation && context.translation.boardBasis}</span>
                        <Tooltip placement="bottom" title={showAmenities(props.srequest.rooms)}>
                        <span><i className="icon icon-Info"> </i></span>
                        </Tooltip>
                      </span>
                    </div>
                    <div className="innerSumContent">{room.adt} {context.translation && context.translation.adts}  {room.chd!==0?room.chd +" Childs":""}{room.chd!==0 && room.chdage} </div>
                    <div className="fareWrap">
                        <div className={" dFlex " + (index===0 && 'active') } >
                            <div className="rightArrow" onClick={handleClick}>
                              <span className="pax">
                                <Tooltip placement="top" title={room.rnm}>{room.rnm}</Tooltip>
                              </span>
                            </div>
                          <div className='currencyWrap'>
                            <span className="currencyCode">{props.srequest.cur}</span>
                            &nbsp;
                            <span className="currencyAmount"> <FormatCurrency value={(parseFloat(room.rmpr[0].pr[0].tf) - Utilities.hotelDiscount(room.rmpr[0].pr[0].tax)) * props.srequest.base_usr_exrate} /></span>
                          </div>
                        </div>
                      
                      <div className="fareDetailWrap">
                        <div className="dFlex">
                          <div>
                            <span className="fare">{context.translation && context.translation.baseFare}</span>
                          </div>
                          <div>
                            <span className="currencyCode">{props.srequest.cur}</span>
                            &nbsp;
                            <span className="currencyAmount"><FormatCurrency value={(parseFloat(room.rmpr[0].pr[0].bf)) * props.srequest.base_usr_exrate} /></span>
                          </div>
                        </div>
                        {Utilities.hotelTaxCalculation(room.rmpr[0].pr[0].tax) !== 0 && (
                          <div className="dFlex">
                            <div className='fareDetailsinner'>
                              <span className="taxes">{context.translation && context.translation.htltax}</span>&nbsp;
                              {/* {(room.rmpr[0].pr[0].tax[0]?.OT !== 0 || room.rmpr[0].pr[0].tax[0]?.ST !== 0) && (
                                <Tooltip placement="bottom" title={
                                <>
                                <div>
                                  {room.rmpr[0].pr[0].tax[0]?.OT !== 0 && 
                                  (
                                    <>
                                      <span>Other taxes : {props.srequest.cur} </span> 
                                      <span><FormatCurrency value={(room.rmpr[0].pr[0].tax[0]?.OT)} /></span>
                                    </>
                                  )}
                                </div>
                                <div>
                                  {room.rmpr[0].pr[0].tax[0]?.ST !== 0 && 
                                  (
                                    <>
                                      <span>Sales tax : {props.srequest.cur} </span>
                                      <span><FormatCurrency value={(room.rmpr[0].pr[0].tax[0]?.ST)} /></span>
                                    </>
                                  )}
                                </div></>}>
                                <span><i className="icon icon-Info"> </i></span>
                                </Tooltip>
                              )} */}
                              {/* <Tooltip placement="bottom" title={context.translation && context.translation.iee}>
                                
                              </Tooltip> */}
                              <span><i className="icon icon-Info" onClick={taxesInfo} > </i></span>
                          
                            </div>
                            <div>
                              <span className="currencyCode">{props.srequest.cur}</span>
                              &nbsp;
                              <span className="currencyAmount"><FormatCurrency value={(Utilities.hotelTaxCalculation(room.rmpr[0].pr[0].tax)) * props.srequest.base_usr_exrate} /></span>
                            </div>
                          </div>   
                        )}
                        {Utilities.hotelDiscount(room.rmpr[0].pr[0].tax) !== 0 && (
                          <div className="dFlex discountWrapper">
                            <div>
                              <span className="discount">{context.translation && context.translation.discount}</span>
                            </div>
                            <div>
                              <span className="currencyCode">{props.srequest.cur}</span>
                              &nbsp;
                              <span className="currencyAmount"><FormatCurrency value={(Utilities.hotelDiscount(room.rmpr[0].pr[0].tax)) * props.srequest.base_usr_exrate} /></span>
                            </div>
                          </div>
                        )}
                        {Utilities.hotelSTaxCalculation(room.rmpr[0].pr[0].tax) !==0 && (               
                        <div className="dFlex">
                          <div>
                            <span className="taxes">{"Sales Tax"}</span>&nbsp;
                          </div>
                          
                          {/* Sales Tax */}
                          <div>
                            <span className="currencyCode">{props.srequest.cur}</span>
                            &nbsp;
                            <span className="currencyAmount"><FormatCurrency value={(Utilities.hotelSTaxCalculation(room.rmpr[0].pr[0].tax)) * props.srequest.base_usr_exrate} /></span>
                          </div>
                        </div>
                        )}     
                      </div>
                    </div>
                <div className="innerSumDivider"></div>
              </div>
            ))}
                <Modal title="" mouseEnterDelay={50} centered visible={taxesInfoModal} onOk={""} onCancel={handelCancel} footer={null} className="taxesInfoWrapper">
                                <div className='commonFlex'>
                                <div className='taxesInfoModalTitle'>{context.translation && context.translation.htltax}</div>
                                <i className="icon icon-cancel"></i>
                                </div>
                                <div className="taxesInfoModalCont">{context.translation && context.translation.iee}</div>
                              </Modal>
            <div className="serviceDiscount">
              <div className="dFlex serviceWrapper">
                <div>
                  <span className="serviceTitle">{context.translation && context.translation.serFee}</span>
                </div>
                <div>
                  <span className="currencyCode">{props.srequest.cur} </span>
                  &nbsp;
                  <span className="currencyAmount"><FormatCurrency value={calServiceFee(props.srequest.rooms)} /></span>
                </div>
              </div>
            </div>
            {props.promoDisc!==0 &&
             <div className="dFlex discountWrapper">
                          <div>
                            <span className="discount">{context.translation && context.translation.prodisc}</span>
                          </div>
                          <div>
                            <span className="currencyCode">{props.srequest.cur}</span>
                            &nbsp;
                            <span className="currencyAmount"><FormatCurrency value={props.promoDisc * props.srequest.base_usr_exrate} /></span>
                          </div>
                        </div>
           }
            <div className="innerSumDivider"></div>
            <div className="commonFlex payablePriceWrapper">
              <div>
                <span className="payablePrice">
                  {context.translation && context.translation.totPayPrice}
                </span>
              </div>
              <div>
                <span className="currencyCode">{props.srequest.cur} </span>
                <span className="currencyAmount"><FormatCurrency value={(calTotalPrice(props.srequest.rooms) - props.promoDisc) * props.srequest.base_usr_exrate} /></span>
              </div>
            </div>
            <div className="allNights">({props.srequest.nonights} {context.translation && context.translation.nights})</div>
            <div>
              {props.srequest.priceNote!==undefined && props.srequest.priceNote!=="" &&
                // <span className="priceNote"><span>Note :</span> {roundUpPriceNote(props.srequest.priceNote)} </span>
                <span className="priceNote"><span>Note :</span> {props.srequest.priceNote} </span>
              }
            </div>
          </div>
      </div>

    </>
  )
}

export default HotelRoomPrice