/**
 * @ Author: Srikanth Bandaru
 * @ Create Time: 2023-04-24 14:07:28
 * @ Description: Flight operations for overal flight flow which includes all search related services
 */

import FlightService from "../../../../../services/flights/FlightService";
import { apiPostHelper } from "../../../../utilities/helper";
const API_URL_SEATMAP = process.env.REACT_APP_B2C_COMMON

// ========================================================================
//  Calling the Flight search request for One way and Round trip
// ========================================================================
export const fireSearchReq = async (url) => {
    let finalRes = [];
    await FlightService.fireFlightSearch(url).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}

// ========================================================================
// Load the Mini fare rules data http://localhost:3003/att/long-rules
// ========================================================================
export const getLongfareRules = async (request,token) => {
    let fareData = [];
    await FlightService.getLongfareRules(request,token).then(response => {
        if (response) {
            fareData = response;
        }
    });
    return Promise.resolve(fareData);
}

// ========================================================================
//  Calling the Flight search request for Multy
// ========================================================================
export const fireMultiCitySearch = async (req) => {
    let finalRes = [];
    await FlightService.fireMultyCityReq(req).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}

// ========================================================================
//  Update Pax Info
// ========================================================================
export const getSalamAirBaggage = async (obj,token) => {
    let finalRes = [];
    await FlightService.getBaggage(obj,token).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}

// ========================================================================
//  Calling the Flight search request for One way and Round trip
// ========================================================================
export const fireAvailabilityReq = async (url,token) => {
    let finalRes = [];
    await FlightService.fireFlightAvailability(url,token).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}

// ========================================================================
//  Calling the Flight search request for One way and Round trip
// ========================================================================
export const getCheapestPrice = async (obj,token) => {
    let finalRes = [];
    await FlightService.getChepestFlight(obj,token).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}



//Call Seat Map
export const getSeatMapDetails = async (obj,token) => {
    let finalRes = {};
    const headers = {
      headers: {
          'Content-Type': 'application/json',
          'auth-token': token
        }
     };
    await apiPostHelper(`${API_URL_SEATMAP}/flight/getseatmap`, obj, headers).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}

