/**
 * @ Author: Srikanth Bandaru
 * @ Create Time: 2023-06-16 20:57:46
 * @ Description: This is for change password section in profile
 */
import {
  Alert,
  Button,
  Col,
  Form,
  Input,
  Progress,
  Row,
  Space,
  Spin,
  Typography,
  notification,
  Modal,
  Tooltip
} from "antd";
import React, { useState, useContext } from "react";
import Header from "../common/Header";
import Footer from "../common/Footer";
import Sidebar from "./Sidebar";
import { Context } from "../../App";
import { useNavigate } from "react-router-dom";
import { changePassword } from "./operations";
import { useEffect } from "react";
import Utilities from "../utilities/Utilities";

const { Title } = Typography;

function MyProfile() {
  const [context, setContext] = useContext(Context);
  const navigate = useNavigate();
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [weakvalidationStatus, setWeakValidationStatus] = useState("");
  const [mediumvalidationStatus, setMediumValidationStatus] = useState("");
  const [strongvalidationStatus, setStrongValidationStatus] = useState("");
  const [passwordTooltip, setPasswordTooltip] = useState(false);
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [errorClass, setErrorClass] = useState("");
  const [enableSave, setEnableSave] = useState(false);
  const [alert, setAlert] = useState(false);
  const [notiMessage, setNotiMessage] = useState("");
  const [notiMessageShow, setNotiMessageShow] = useState(false);
  const [notiVarient, setNotiVarient] = useState();
  const [signinEmail, setSigninEmail] = useState("");
  const [pwdMatch, setPwdMatch] = useState("");
  const [error, setError] = useState(false);
  const [incorrect, setIncorrect] = useState(false);
  const [oldNewPassword, setOldNewPassword] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("SIGNINRESPONSE"));
    if (data !== null) {
      setSigninEmail(data.res.email);
    }
  }, [localStorage.getItem("SIGNINRESPONSE")]);

  useEffect(() => {
    setIncorrect(false);
  }, [currentPassword]);

  useEffect(() => {
    setOldNewPassword(false);
  }, [newPassword, confirmNewPassword]);

  /**
   * @description:Validation Password
   * @author: Ubedullah
   * @function Validating Form
   * @date : 22-09-2023
   */

  // Function to validate password and set validation status
  const validatePassword = (password) => {
    // password validation checks
    let weakValidationStatus = "";
    let strongValidationStatus = "";
    let mediumValidationStatus = "";

    if (password.length < 6 || password.length > 16) {
      weakValidationStatus = "Weak";
      setWeakValidationStatus(weakValidationStatus);
      setStrongValidationStatus("");
      setMediumValidationStatus("");
      setPasswordTooltip(true);
      return false;

    } else if (
      /[a-z]/.test(password) &&
      /[A-Z]/.test(password) &&
      /\d/.test(password) &&
      /[!@#$%^&*]/.test(password)
    ) {
      strongValidationStatus = "Strong";
      setPasswordTooltip(false);
    } else {
      mediumValidationStatus = "Medium";
      setMediumValidationStatus(mediumValidationStatus);
      setWeakValidationStatus("");
      setStrongValidationStatus("");
      setPasswordTooltip(true);
      return false;
    }

    setWeakValidationStatus("");
    setMediumValidationStatus("");
    setStrongValidationStatus(strongValidationStatus);
    setPasswordTooltip(false);
    return true;
  };

  /**
   * @description:Change Password Validating Form
   * @author: Ubedullah
   * @function Validating Form
   * @date : 21-06-2023
   */

  const validateChangePassword = () => {
    if (currentPassword === "") {
      // setErrorClass("error");
      setError(true);
      // setNotiMessageShow(true);
      // setNotiMessage("Please enter current password");
      // setNotiVarient("danger");
      // hidemessage();
      return false;
    } else if (newPassword === "") {
      // setErrorClass("error");
      setError(true);
      return false;
    } else if (confirmNewPassword === "") {
      // setErrorClass("error");
      setError(true);
      return false;
    } else if (newPassword !== confirmNewPassword) {
      setErrorClass("error");
      setPwdMatch(context.translation && context.translation.passDoesntmatch);
      return false;
    } else if (
      currentPassword === newPassword &&
      currentPassword === confirmNewPassword
    ) {
      setOldNewPassword(true);
      setErrorClass("error");
      setIncorrect(false);
      return false;
    }
    return true;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // if (!currentPassword) {
    //   setErrorClass("error");
    //   return;
    // }

    // if (!newPassword || !confirmNewPassword) {
    //   setErrorClass("error");
    //   setNewPassword("");
    //   setConfirmNewPassword("");
    //   return;
    // }

    // if (newPassword !== confirmNewPassword) {
    //   setErrorClass("error");
    //   setNewPassword("");
    //   setConfirmNewPassword("");
    //   return;
    // }

    // if (!currentPassword || !newPassword || !confirmNewPassword) {
    //   setErrorClass("error");
    //   return;
    // }

    // if (newPassword !== confirmNewPassword) {
    //   setErrorClass("error");
    //   return;
    // }

    // if (newPassword !== confirmNewPassword) {
    //   setError(true);
    //   setPwdMatch({context.translation && context.translation.searchBok});
    // }

    // if (
    //   currentPassword === newPassword &&
    //   currentPassword === confirmNewPassword
    // ) {
    //   setOldNewPassword(true);
    //   setErrorClass("error");
    //   setIncorrect(false);
    //   return;
    // }

    // if (newPassword.length < 6) {
    //   setNotiMessageShow(true);
    //   setNotiMessage("Your password must contain minimum of 6 and maximum of 14 characters.")
    //   setNotiVarient("error")
    //   hidemessage();
    //   return
    // }

    // if (newPassword.length > 14) {
    //   setNotiMessageShow(true);
    //   setNotiMessage("Your password must contain minimum of 6 and maximum of 14 characters.")
    //   setNotiVarient("error")
    //   hidemessage();
    //   return
    // }

    setErrorClass("");

    if (validateChangePassword() && validatePassword(newPassword)) {      
      setLoading(true);
      try {
        const payload = {
          email: signinEmail,
          password: currentPassword,
          new_pw: newPassword,
        };
        let tokenKey = Utilities.generateAPIToken(payload);
        changePassword(payload,tokenKey).then((response) => {
          if (response.suc) {
            setCurrentPassword("");
            setNewPassword("");
            setConfirmNewPassword("");
            // setTimeout(function () {
            //   window.location = "/";
            // }, 2000);          
            setNotiMessageShow(true);
            setNotiMessage(context.translation && context.translation.passChangeds)
            setNotiVarient("success")
            hidemessage();
            setIncorrect(false);
            setError(false);
            setTimeout(() => {
              setLoading(false);
            }, 100);
          } else {
            setLoading(false);
            setIncorrect(true);
            setErrorClass("error");
          }
        }).catch(function (error) {
          console.error("Error:" + error);      
        });        
      } catch (error) {
        console.error("Error:", error);        
      }
    }
  };

  const hidemessage = () => {
    setTimeout(function () {
      setNotiMessageShow(false);
      setNotiMessage("");
      setNotiVarient("");
    }, 3000);
  };

  const handleCancel = () => {
    setCurrentPassword("");
    setNewPassword("");
    setConfirmNewPassword("");
    setErrorClass("");
    setIncorrect(false);
  };

  const homePage = () => {
    navigate("/");
  }

  if (context.user_resp === "" && localStorage.getItem("SIGNINRESPONSE") === null) {
    return ( 
        <Modal
          title=""
          open={true}
          width={460}
          footer={null}
          maskClosable={false}
        >
          <Title level={4}>
            {/* {context.translation && context.translation.plzlog} */}
            {(sessionStorage.getItem("SEL_LAG") === null || sessionStorage.getItem("SEL_LAG") === "English") &&
              "Please log in to access your profile"              
            }
            {sessionStorage.getItem("SEL_LAG") === "Arabic" &&
              "يرجى تسجيل الدخول لملفك الشخصي"              
            }
          </Title>

          <Button type="primary" className="cntBtn sendBtn" onClick={homePage}>
            {/* {context.translation && context.translation.backHg} */}
            {(sessionStorage.getItem("SEL_LAG") === null || sessionStorage.getItem("SEL_LAG") === "English") &&
              "Back to Home Page"              
            }
            {sessionStorage.getItem("SEL_LAG") === "Arabic" &&
              "عودة للصفحة الرئيسية"              
            }
          </Button>
        </Modal>
    );
  }

  const update=(type)=>{
  }

  return (
    <>
      {notiMessageShow ? (
        <Alert
          description={notiMessage}
          type={notiVarient}
          closable
          onClose={() => setNotiMessageShow(false)}
          className="alertForm"
          // showIcon
        >
          {notiMessage}
        </Alert>
      ) : (
        ""
      )}
      {contextHolder}
      <Header setTab={update} />
      <div className="myAccountPage">
        <div className="myAccountBanner"></div>
        <div className="container">
          <Row>
            <Col md={5} className="firstChild">
              <Sidebar active="changePassword" />
            </Col>
            <Col md={19} className="lastChild">
              <div className="cardDetails changePasswordDetails">
                <div className="cardDetailsHeader">
                  <div>
                    <Title level={4}>{context.translation && context.translation.chgPass}</Title>
                    <p>
                      {/* Your password must contain minimum of 6 and maximum of 14
                      characters. */}
                      {context.translation && context.translation.includeOlOu}
                    </p>
                  </div>
                  {/* <div>
                    {!enableChangePassword && (
                      <Button
                        type="secondary"
                        className="editBtn"
                        onClick={() => editChangePassword()}
                      >
                        <i className="icon icon-Edit"></i> Edit
                      </Button>
                    )}
                  </div> */}
                </div>
                <div className="cardDetailsBody">
                  <p className="note">
                    <span>{context.translation && context.translation.note}</span> {context.translation && context.translation.reEnternewpass}
                  </p>
                  <Form layout="vertical">
                    <Row gutter={16}>
                      <Col span={8} className="addTopMargin">
                        <Form.Item className="pRelative">
                          <label className="requiredField">
                            {context.translation && context.translation.curPass}
                          </label>
                          <Input.Password
                            // className="innerBoxShadow"
                            className={"innerBoxShadow" + (error && currentPassword === "" ? " formError" : "")}
                            placeholder={context.translation && context.translation.enterCurrpas}
                            type="password"
                            value={currentPassword}
                            onChange={(e) => setCurrentPassword(e.target.value)}
                          />
                          <i className="icon icon-Alert"></i>
                        </Form.Item>
                        {/* {currentPassword === "" && errorClass && (
                          <div className={errorClass}>
                            Please enter current password.
                          </div>
                        )} */}
                        {incorrect && (
                          <div className={errorClass}>
                            {context.translation && context.translation.incorrOldpass}
                          </div>
                        )}
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      <Col span={8} className="addTopMargin">
                        <Form.Item className="pRelative">
                          <label className="requiredField">{context.translation && context.translation.newPass}</label>
                          <Tooltip overlayClassName="layoverPassword" placement="bottomRight" open={newPassword && passwordTooltip?true:false} title={
                            <small><i className="icon icon-Info"></i> {context.translation && context.translation.warning}</small>
                          }>
                          <Input.Password
                            // className="innerBoxShadow"
                            className={"innerBoxShadow newPassword" + (error && newPassword === "" ? " formError" : "")}
                            placeholder={context.translation && context.translation.enterNewpass}
                            type="password"
                            value={newPassword}
                            onChange={(e) => {
                              return (
                                setNewPassword(e.target.value),
                                validatePassword(e.target.value)
                              );
                            }}
                          />
                          <i className="icon icon-Alert"></i>
                          </Tooltip>
                          {newPassword && (
                            <>
                            <span
                              style={{                            
                                position: "absolute",
                                top: "-5px",
                                right: "111px",
                                borderBottom: "2px solid #ed1d24",
                                paddingBottom: "0",
                                zIndex: "9",
                                color:
                                  weakvalidationStatus === "weak"
                                    ? "#ed1d24"
                                    : "#ed1d24",
                              }}
                            >
                              {weakvalidationStatus}
                            </span>
                            <span
                              style={{
                                position: "absolute",
                                top: "-5px",
                                right: "51px",
                                borderBottom: "2px solid #E6BB07",
                                paddingBottom: "0",
                                zIndex: "9",
                                color:
                                  mediumvalidationStatus === "weak"
                                    ? "#ed1d24"
                                    : mediumvalidationStatus === "Medium"
                                    ? "#E6BB07"
                                    : "#008D00",
                              }}
                            >
                              {mediumvalidationStatus}
                            </span>
                            <span
                              style={{
                                position: "absolute",
                                top: "-5px",
                                right: "1px",
                                borderBottom: "2px solid #008D00",
                                paddingBottom: "0",
                                zIndex: "9",
                                color:
                                  strongvalidationStatus === "weak"
                                    ? "#ed1d24"
                                    : strongvalidationStatus === "Medium"
                                    ? "#E6BB07"
                                    : "#008D00",
                              }}
                            >
                              {strongvalidationStatus}
                            </span>
                            <span className="weakBorderBottom"></span>
                            <span className="mediumkBorderBottom"></span>
                            <span className="strongBorderBottom"></span>
                          </>
                          )}
                        </Form.Item>
                        {/* {newPassword === "" && errorClass && (
                          <div className={errorClass}>
                            Please enter new password.
                          </div>
                        )} */}
                        {oldNewPassword && (
                          <div className={errorClass}>
                            {context.translation && context.translation.oldNnewpass}
                          </div>
                        )}
                      </Col>
                      <Col span={8} className="addTopMargin">
                        <Form.Item className="pRelative">
                          <label className="requiredField">
                            {context.translation && context.translation.confirmPass}
                          </label>
                          <Input.Password
                            // className="innerBoxShadow"
                            className={"innerBoxShadow" + (error && confirmNewPassword === "" ? " formError" : "")}
                            placeholder={context.translation && context.translation.enterConfpas}
                            type="password"
                            value={confirmNewPassword}
                            onChange={(e) =>
                              setConfirmNewPassword(e.target.value)
                            }
                          />
                          <i className="icon icon-Alert"></i>
                        </Form.Item>
                        {/* {confirmNewPassword === "" && errorClass && (
                          <div className={errorClass}>
                            Please enter confirm password.
                          </div>
                        )} */}

                        {
                          <small style={{ color: "red" }}>
                            {newPassword !== "" &&
                            confirmNewPassword !== "" &&
                            newPassword !== confirmNewPassword
                              ? pwdMatch
                              : ""}
                          </small>
                        }
                      </Col>
                    </Row>
                  </Form>
                </div>
                <div className="cardDetailsFooter">
                  <Button
                    type="secondary"
                    className="cancelBtn"
                    ghost
                    onClick={handleCancel}
                  >
                    {context.translation && context.translation.cancel}
                  </Button>
                  <Button
                    type="primary"
                    className="cntBtn"
                    onClick={handleSubmit}
                    disabled={loading ? true : false}
                  >
                    {loading ? (
                        <div className="loading-bar animate">
                        <span>Saving...</span>
                      </div>
                      // <Space size={"middle"}>
                      //   <Spin size="large" />
                      // </Space>
                    ) : (
                      <span>{context.translation && context.translation.save}</span>
                    )}                    
                    {/* <i className="sprite btn-arrow-sprite"></i> */}
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default MyProfile;
