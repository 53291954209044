/**
 * @ Author: Srikanth Bandaru
 * @ Create Time: 2023-08-03 14:07:28
 * @ Description: Hotel operations for overal hotel flow which includes all search related services
 */

import HotelService from "../../../services/hotels/HotelService";
import {
  HOTEL_CONTENT,
  HOTEL_RESPONSE_ONE,
  HOTEL_ROOMS,
} from "../../bookingflow/flights/results/sampleHotelResponse";

const HOTEL_CONT_BOOK_URL = process.env.REACT_APP_B2C_HOTEL_COMMON;
const HOTEL_CONT_URL = process.env.REACT_APP_PREDICTIVE_URL;

export const getHotelSRPContent = async (id, type) => {
  let finalRes = {};
  const headers = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  finalRes = HotelService.fireHotelSearch(
    `${HOTEL_CONT_BOOK_URL}/properties/?skip=0&limit=5&` +
      type +
      "=" +
      id +
      "&getExpediaMap=false",
    headers
  );
  return Promise.resolve(finalRes);
};

export const searchRequest = async (req) => {
  // let finalRes = HOTEL_RESPONSE_ONE;
  let finalRes = [];
  await HotelService.fireHotelSearch(req).then((response) => {
    finalRes = response;
  });
  return Promise.resolve(finalRes);
};

export const getHotelReviewContent = async (id) => {
  let finalRes = {};
  const headers = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  finalRes = HotelService.getHotelReviewContent(
    `${HOTEL_CONT_URL}/properties_review/?skip=0&limit=5&type=prid&expHtlId=` +
      id,
    headers
  );
  //finalRes = HOTEL_CONTENT;
  return Promise.resolve(finalRes);
};

export const getHotelRooms = async (req) => {
  let finalRes = [];
  //finalRes = HOTEL_ROOMS;
  await HotelService.fireHotelSearch(req).then((response) => {
    finalRes = response.data;
  });
  return Promise.resolve(finalRes);
};

export const hotelPriceCheck = async (obj,token) => {
  let finalRes = {};
  const headers = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  finalRes = HotelService.saveHotelPriceCheck(
    `${HOTEL_CONT_BOOK_URL}/hotelpricing/pricecheck`,
    obj,token
  );
  return Promise.resolve(finalRes);
};

export const savePriceCheck = async (obj,token) => {
  let finalRes = {};
  const headers = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  finalRes = HotelService.saveHotelPriceCheck(
    `${HOTEL_CONT_URL}/common/hotelpricechecksave`,
    obj,token
  );
  return Promise.resolve(finalRes);
};

export const getGuestReviews= async (pid) => {
  let finalRes = {};
  finalRes = HotelService.getGuestReviews(
    `${HOTEL_CONT_BOOK_URL}/hotelavailability/user_review?pid=`+pid
  );
  return Promise.resolve(finalRes);
}
