import React, { useState,useContext,useEffect} from "react";
import { Context } from "../../App";
import {
  Typography,
  Row,
  Col,
  // Collapse,
} from "antd";
// import { Link } from "react-router-dom";
import Footer from "../common/Footer";
import Header from "../common/Header";
import SecureGuarantee from "../homepage/SecureGuarantee";
import CheckoutPay from "./CheckoutPay";
import FlightPaymentInfo from "./FlightPaymentInfo";
import HotelPaymentInfo from "./HotelPaymentInfo";
import { useLocation,useNavigate } from "react-router-dom";


 

const { Title } = Typography;
// const { Panel } = Collapse;

function Payment() {
  const [context, setContext] = useContext(Context)
  const location = useLocation();
  const navigate = useNavigate();   
  const [enablePay,setEnablePay] = useState(false)
  const [request, setRequest] = useState(location.state!==null &&
    location.state.req && location.state.req !== undefined && location.state.req!==null
      ? location.state.req
      : ""
  );
  const { pathname } = useLocation();

  // Automatically scrolls to top whenever pathname changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    
   if(location!==undefined && location.search!==undefined){
      if(location.search.includes("cko-session-id=")){
        setEnablePay(true);
      }
   }
  
  }, [location])

  
  useEffect(() => {
    window.addEventListener('popstate', () => {
      localStorage.removeItem("clickCount");
      if(request!==undefined && request.product && request.product==="Flight") {
        if(sessionStorage.getItem("FLTLONGURL") !== null){
          const url = sessionStorage.getItem("FLTLONGURL").split("?")[1];
          navigate("/flight/results?"+url);
        }
      } else if(request!==undefined && request.product && request.product==="Hotel") {
        if(sessionStorage.getItem("HTLLONGURL") !== null){
          const url = sessionStorage.getItem("HTLLONGURL").split("?")[1];
          navigate("/hotel/results?"+url);
        }
      }
    })
  }, [sessionStorage.getItem("FLTLONGURL"), sessionStorage.getItem("HTLLONGURL")])

  const update=(type)=>{
  }

  const updateRequest=(data)=>{
    setRequest(data)
  }

  return (
    <>
      <Header setTab={update} page={"Pay"}/>
      <Row className="statusCheck">
        <Col span={6} className="circleWrap completed">
          <span className="circle">
            <i className="icon icon-tick"></i>
          </span>
          <span className="text">{context.translation && context.translation.reviewBok} <span className="displayNone-640"> &nbsp;{context.translation && context.translation.booking}</span></span>
        </Col>
        {request!==undefined && request.product && request.product==="Flight" &&
          <Col span={6} className="circleWrap completed">
            <span className="circle">
              <i className="icon icon-tick"></i>
            </span>
            <span className="text">{context.translation && context.translation.travellerDetail}</span>
          </Col>
        }
        {request!==undefined && request.product && request.product==="Hotel" &&
          <Col span={6} className="circleWrap completed">
            <span className="circle">
              <i className="icon icon-tick"></i>
            </span>
            <span className="text">{context.translation && context.translation.guestDetail}</span>
          </Col>
        }
        <Col span={6} className="circleWrap active">
          <span className="circle">{context.translation && context.translation.three}</span>
          <span className="text">{context.translation && context.translation.payment}</span>
        </Col>
        <Col span={6} className="circleWrap">
          <span className="circle">{context.translation && context.translation.four}</span>
          <span className="text">{context.translation && context.translation.confirmation}</span>
        </Col>
      </Row>
      
      {request!==undefined && request.product && request.product==="Flight" && (
        <div className="container">
          <div className="travellerDetails">
            <Row gutter={30} className="paxDetailsWrap">
              <Col span={17} className="paymentMainWrap">
                <div className="selectedFlights">
                  <span>{context.translation && context.translation.one}</span>
                  <Title level={4}>{context.translation && context.translation.hwyltp}</Title>
                </div>
                {request && request.product &&
                 <CheckoutPay request={request} sendPayRequest={updateRequest}/>
                }
              </Col>
              <Col span={7} className="outerSummaryWrap marginTop30">
              {request && request.product &&
                <FlightPaymentInfo request={request}/>
              }
              </Col>
            </Row>
          </div>
        </div>
      )}
      
      {request!==undefined && request.product && request.product==="Hotel" && (
        <div className="container">
          <div className="travellerDetails">
            <Row gutter={30} className="paxDetailsWrap">
              <Col span={17} className="paymentMainWrap">
                <div className="selectedFlights">
                  <span>{context.translation && context.translation.one}</span>
                  <Title level={4}>{context.translation && context.translation.hwyltp}</Title>
                </div>
                {request && request.product &&
                 <CheckoutPay request={request} sendPayRequest={updateRequest}/>
                }
              </Col>
              <Col span={7} className="outerSummaryWrap marginTop30">
              {request && request.product &&
                <HotelPaymentInfo request={request} />
              }
              </Col>
            </Row>
          </div>
        </div>
      )}

      {enablePay &&
        <CheckoutPay location={location} base_usr_exrate={request.base_usr_exrate} sendPayRequest={updateRequest}/>
      }


      {/* <div className="container">
        <Row gutter={30}>
          <Col span={18} className="paymentMainWrap">
          </Col>
        </Row>
      </div> */}
      <SecureGuarantee />
      <Footer />
    </>
  );
}

export default Payment;
