/**
 * To Check null
 * @author: Srikanth Bandaru
 * Date:04-08-2023
 */
export const isNotNull = function (data) {
  if (
    data !== null &&
    data !== undefined &&
    data !== "null" &&
    data !== "undefined" &&
    data !== ""
  ) {
    return true;
  } else {
    return false;
  }
};

/**
 *to check success response
 * @author: Srikanth Bandaru
 * Date:04-08-2023
 */
export const isSuccessResponse = function (status) {
  if (isNotNull(status) && status === 200) {
    return true;
  } else {
    return false;
  }
};

/**
 * this to check array is empty or not
 * @author: Srikanth Bandaru
 * Date:04-08-2023
 */
export const isArrayNotEmpty = function (arr) {
  if (isNotNull(arr) && arr.length > 0) {
    return true;
  } else {
    return false;
  }
};
