/**
 * @ Author: Srikanth Bandaru
 * @ Create Time: 2023-06-16 20:57:46
 * @ Description: This is for Side bar in the my profile section
 */
import { Input, Progress, Space, Typography, Modal, notification } from "antd";
import React, { useEffect, useState, Component, useContext } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Context } from "../../App";
import { getProfile } from "./operations";
import Profile from "../../assets/img/profile.png";
import apiurl from "../../components/utilities/api"
import Utilities from "../utilities/Utilities";
const API_URL_PROFILE_IMG = process.env.REACT_APP_B2C_COMMON

const { Title } = Typography;

function Sidebar(props) {
  const [context, setContext] = useContext(Context);
  const { state } = props || {};
  const [userUniqId, setUserUniqId] = useState("");
  const [active, setActive] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  // const [name, setName] = useState("");
  const [profileImage, setProfileImage] = useState(null);
  const [uploadProfPic, setUploadProfPic] = useState(null);

  const navigate = useNavigate();

  let data = JSON.parse(localStorage.getItem("SIGNINRESPONSE"));
  useEffect(() => {
    if (data !== null) {
      setUserUniqId(data.res.usr_uniq_id);
      setProfileImage(data.res.profile_pic);
    }
  }, [localStorage.getItem("SIGNINRESPONSE")]);

  useEffect(() => {
    if (data !== null) {
      if(context.user_resp == "" || context.user_resp == undefined) {
        setContext({...context, user_resp: data })
        context.user_resp = data;
      }
    }
  }, []);

  // useEffect(() => {
  //   try {
  //     if (data !== null) {
  //       const payload = {
  //         email: data.res.email,
  //       };
  //       let tokenKey = Utilities.generateAPIToken(payload);
  //       getProfile(payload,tokenKey).then((response) => {
  //         if (response.suc) {
  //           setName(response?.res[0]?.fname);
  //         }
  //       }).catch(function (error) {
  //         console.error("Error in Profile API:" + error);      
  //       });
  //     }      
  //   } catch (error) {
  //     console.error("Error in Profile API:", error);        
  //   }
  // }, []);

  useEffect(() => {
    const name = localStorage.getItem("NAME");
    context.username = context.username !== undefined ? context.username : name;
  }, [context.username])

  let prof_pic = localStorage.getItem("PROFILE_PIC");
  useEffect(() => {
    if (prof_pic !== null) {
      setUploadProfPic(prof_pic);
    }
  }, [localStorage.getItem("PROFILE_PIC")]);

  // const handleProfile = () => {
  //   navigate("../my-account/user-profile");
  // };

  const handleImageUpload = (event) => {
    // Perform the delete existing image API call using image file name if it exists
    if (profileImage !== null) {
      const payload = {
        url: profileImage,
      };
      let tokenKey = Utilities.generateAPIToken(payload);
      const headers = {
        headers: {
          "Content-Type": "application/json",
          'auth-token': tokenKey
        },
      };
      apiurl
        .post(`${API_URL_PROFILE_IMG}/file/delete`,
        payload,headers
        )
        .then((response) => {
          if (response.data.suc) {
          } else {
          }
        }).catch(function (error) {
          console.error("Error:" + error);      
        });
    }

    const file = event.target.files[0];
    const formData = new FormData();
    formData.append("image", file);
    formData.append("type", "profile");
    formData.append("usr_uniq_id", userUniqId);

    let tokenKey = Utilities.generateAPIToken(formData);
    const headers = {
      headers: {
        'auth-token': tokenKey
      },
    };
    apiurl
      .post(`${API_URL_PROFILE_IMG}/file/upload`, formData,headers)
      .then((response) => {
        // Handle successful upload response
        if (response.data.suc) {
          // Update imageUrl state with the new image URL
          setProfileImage(response.data.res.url);
          localStorage.setItem("PROFILE_PIC", response.data.res.url);
          api.open({
            message: (context.translation && context.translation.profilesuc),
            duration: 2,
          });
        } else {
          api.open({
            message: (context.translation && context.translation.profilenot),
            duration: 2,
          });
        }
      })
      .catch((error) => {
        // Handle upload error
        console.error("Error uploading image:", error);
      });
    // Reset selectedImage state to null
  };
  const handleLogoutConfirmation = () => {
    Modal.confirm({
      title: (context.translation && context.translation.wlogout),
      okText: (context.translation && context.translation.yes),
      cancelText: (context.translation && context.translation.no),
      className: "logOut",
      onOk: () => {
        logout();
      },
      onCancel: () => {
        handleCancel();
      },
    });
  };

  const logout = () => {
    localStorage.removeItem("SIGNINRESPONSE");
    setContext({...context, user_resp:""})
    localStorage.removeItem("PROFILE_PIC");
    sessionStorage.removeItem("INT_RESPONSE");
    sessionStorage.removeItem("NAME");
    setActive(true);
    navigate("/");
    api.open({
      message: (context.translation && context.translation.signoutSuc),
      duration: 2,
    });
  };

  const handleCancel = () => {
    // window.location = "/";
  };

  const removeActive = () => {
    setActive(false);
  };

  return (
    <>
      {contextHolder}
      <div className="profilePic cardDetails">
        <div className="imgWrapper">
          <Space wrap>
            <Progress type="circle" percent={25} size={145} strokeWidth={3} />
          </Space>
          <div className="priority">
            <label htmlFor="profilepic-upload">
              {localStorage.getItem("SIGNINRESPONSE") === null ? (
                <img
                  src={Profile}
                  alt="Profile Picture"
                />
              ) : (
                <div>
                  {uploadProfPic !== null ? (
                    <img
                      src={
                        uploadProfPic
                          ? uploadProfPic
                          : Profile
                      }
                      alt="Profile Picture"
                    />
                  ) : (
                    <img
                      src={
                        profileImage
                          ? profileImage
                          : Profile
                      }
                      alt="Profile Picture"
                    />
                  )}
                </div>
              )}
            </label>
            <input
              id="profilepic-upload"
              type="file"
              accept="image/*"
              onChange={handleImageUpload}
              style={{ display: "none" }}
            />
          </div>
        </div>
        <Title level={4} className="profileName">
          {context.username !== undefined && context.username !== "" ? context.username : ""}          
        </Title>
        <span className="uid">UID : {userUniqId}</span>
      </div>
      <div className="profileNavigation cardDetails">
        <ul>
          <li className="listItem">
            <Link
              to="/my-account/user-profile"
              onClick={removeActive}
              className={
                state.active === "userProfile" && !active ? "active" : ""
              }
            >
              <i className="icon icon-Profile"></i> {context.translation && context.translation.myProf}
            </Link>
          </li>
          <li className="listItem">
            <Link
              to="/my-account/add-preferences"
              onClick={removeActive}
              className={
                state.active === "preferences" && !active ? "active" : ""
              }
            >
              <i className="icon icon-Preference"></i> {context.translation && context.translation.addPreference}
            </Link>
          </li>
          <li className="listItem">
            <Link
              to="/my-account/my-trips"
              onClick={removeActive}
              className={state.active === "trips" && !active ? "active" : ""}
            >
              <i className="icon icon-Trips"></i> {context.translation && context.translation.mytrips}
            </Link>
          </li>
          <li className="listItem">
            <Link
              to="/my-account/add-travellers"
              onClick={removeActive}
              className={
                state.active === "travellers" && !active ? "active" : ""
              }
            >
              <i className="icon icon-Add-Traveller"></i> {context.translation && context.translation.addTravellers}
            </Link>
          </li>
          {context.user_resp !== "" && context.user_resp !== undefined && (
            <>
            {context.user_resp?.res?.is_login && (
              <li className="listItem">
                <Link
                  to="/my-account/change-password"
                  onClick={removeActive}
                  className={
                    state.active === "changePassword" && !active ? "active" : ""
                  }
                >
                  <i className="icon icon-Change-Password"></i> {context.translation && context.translation.chgPass}
                </Link>
              </li>
            )}
            </>
          )}
          <li className="listItem">
            <Link
              to=""
              onClick={handleLogoutConfirmation}
              className={active ? "active" : ""}
            >
              <i className="icon icon-Signout"></i> {context.translation && context.translation.signout}
            </Link>
          </li>
        </ul>
      </div>
      {/* <div className="cardDetails keepTouch">
        <Title level={4}>
          <i className="icon icon-Email"></i> {context.translation && context.translation.letsKeep}
        </Title>
        <p>{context.translation && context.translation.freshNews}</p>
        <Input className="innerBoxShadow" placeholder={context.translation && context.translation.eYeid} />
        <i className="icon icon-Send"></i>
      </div> */}
    </>
  );
}

class SidebarMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: props.active,
    };
  }

  render() {
    return <Sidebar state={this.state} />;
  }
}

export default SidebarMenu;
