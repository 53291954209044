import { Space, Spin, Drawer } from "antd";
import { Button, Col, Form, Input, Row, Select, Tag, Typography } from "antd";
import { useState,useContext } from "react";
import { Context } from "../../../../App";
import { useReducer } from "react";
import { isArrayNotEmpty } from "../../../utilities/validators";
import _ from "lodash";
import { useEffect } from "react";

const { Title } = Typography;

const initialState = {
  applToData: [],listView: true, mapView: false, sortIcon:'Hot Deal',
};

// Reducer function for userReact Hook
const reducer = (state, action) => {
  switch (action.type) {
    case 'clear':
      return {
        ...state
      };
    default:
      return { ...state, [action.type]: action.payload };
  }
};

function HotelResultsSort(props) {
  const [context, setContext] = useContext(Context)
  const [state, dispatch] = useReducer(reducer, initialState);
  const [enable, setEnable] = useState("HOT DEAL");

  useEffect(() => {
    const searcres = props.hotelresponse;
    dispatch({ type: 'finalsortResponse', payload: Object.assign({}, searcres) })
    dispatch({ type: 'localsortResponse', payload: searcres })
    window.scrollTo(0, 0)
  }, [])

     /**
      *
      * @param {*} value
      * @description:Price Filter
      * @author: Rambabu
      * @date:01-03-2021
      */

  const sortinghandlechange = (obj) => {
    setEnable(obj);
    obj = [{ value: obj }];
    dispatch({ type: 'sortIcon', payload: obj[0].value });
    //if (!props.isreset) {
    let filterres = _.cloneDeep(props.hotelresponse.prpts);
    dispatch({ type: 'applToData', payload: obj })
    if (isArrayNotEmpty(obj) && obj[0].value.toUpperCase() === 'HOT DEAL') {

      //descending order
      filterres.sort(function (htl1, htl2) {
        return Number(htl2.disc) - Number(htl1.disc);
      });
      state.localsortResponse.prpts = filterres;
      props.sortresponse(state.localsortResponse,obj,'sort');

    } else if (isArrayNotEmpty(obj) && obj[0].value.toUpperCase() === 'LH') {//low to high

      //ascending order
      filterres.sort(function (htl1, htl2) {        
        return (Number(htl1.rtf)) - (Number(htl2.rtf));
      });
      state.localsortResponse.prpts = filterres;
      props.sortresponse(state.localsortResponse, obj,'sort');

    } else if (isArrayNotEmpty(obj) && obj[0].value.toUpperCase() === 'HL') {//high to low

      //descending order
      filterres.sort(function (htl1, htl2) {        
        return (Number(htl2.rtf)) - (Number(htl1.rtf));
      });
      state.localsortResponse.prpts = filterres;
      props.sortresponse(state.localsortResponse,obj,'sort');

    } else if (isArrayNotEmpty(obj) && obj[0].value.toUpperCase() === 'RC') {//Recommended

      //descending order
      filterres.sort(function (htl1, htl2) {
        return Number(htl2.score) - Number(htl1.score);
      });
      state.localsortResponse.prpts = filterres;
      props.sortresponse(state.localsortResponse,obj,'sort');

    }else {
      state.localsortResponse.prpts = state.finalsortResponse.prpts;
      props.sortresponse(state.localsortResponse,obj,'sort');

    }
  /*   } else {
      props.resetvalue('sort');
    } */

  }
  const showList=()=>{
    props.shwoListView(true)
    dispatch({ type: 'listView', payload: true })
        dispatch({ type: 'mapView', payload: false })
  }
  const showMap=()=>{
    props.shwoMapView(true)
    dispatch({ type: 'listView', payload: false })
        dispatch({ type: 'mapView', payload: true })
  }

  const [open, setOpen] = useState(false);

const showDrawer = () => {
  setOpen(true);
};

const onClose = () => {
  setOpen(false);
};

  return (
    <>
      <Row gutter={20}>
        <Col xl={8} lg={8} md={12} xs={24}>
          <div className="cardWithShadow hotelSorting displayNone-425">
            <div className="dFlex">
             <div className={enable && enable === "HOT DEAL"?"hotDeal active":"hotDeal"} onClick={value => (sortinghandlechange("HOT DEAL"))}>
                <i className="icon icon-hot-deal"></i>
                <span className="heading">{context.translation && context.translation.hotDeal}</span>
              </div>
              <div className={enable && enable === "RC"?"recomm active":"recomm"} onClick={value => (sortinghandlechange("RC"))}>
                <i className="icon icon-thumb"></i>
                <span className="heading">{context.translation && context.translation.recommended}</span>
              </div>
            </div>
          </div>
        </Col>
        <Col xl={8} lg={8} md={12} xs={24}>
          <div className="cardWithShadow hotelSorting displayNone-425">
            <div className="dFlex">
              <div className={enable && enable === "LH"?"lPrice active":"lPrice"} onClick={value => (sortinghandlechange("LH"))}>
                <i className="icon icon-sorting"></i>
                <span className="heading">{context.translation && context.translation.lowestPrice}</span>
              </div>
              <div className={enable && enable === "HL"?"hPrice active":"hPrice"} onClick={value => (sortinghandlechange("HL"))}>
                <i className="icon icon-sorting"></i>
                <span className="heading">{context.translation && context.translation.highestPrice}</span>
              </div>
            </div>
          </div>
        </Col>
        <div className="sortByWrap displayNoneLG" onClick={showDrawer}>
            <div className="sortBy">
              <i className="icon icon-sorting"></i>
              <span>{context.translation && context.translation.sort}</span>
            </div>
        </div>
        <Drawer placement="bottom" open={open} className="sortingOptionPopup hotelSortingPopup">
        <i className="icon icon-cancel" onClick={onClose}></i>
        <div className="sortByTitle">{context.translation && context.translation.sortby}</div>
            <div className="hotelSortingWrap">
              <div className={enable && enable === "HOT DEAL"?"hotDeal active":"hotDeal"} onClick={value => (sortinghandlechange("HOT DEAL"))}>
                <i className="icon icon-hot-deal"></i>
                <span className="heading">{context.translation && context.translation.hotDeal}</span>
              </div>
              <div className={enable && enable === "RC"?"recomm active":"recomm"} onClick={value => (sortinghandlechange("RC"))}>
                <i className="icon icon-thumb"></i>
                <span className="heading">{context.translation && context.translation.recommended}</span>
              </div>
              <div className={enable && enable === "LH"?"lPrice active":"lPrice"} onClick={value => (sortinghandlechange("LH"))}>
                <i className="icon icon-sorting"></i>
                <span className="heading">{context.translation && context.translation.lowestPrice}</span>
              </div>
              <div className={enable && enable === "HL"?"hPrice active":"hPrice"} onClick={value => (sortinghandlechange("HL"))}>
                <i className="icon icon-sorting"></i>
                <span className="heading">{context.translation && context.translation.highestPrice}</span>
              </div>
            </div>
        </Drawer>
        {/* <Col xl={8} lg={8} md={24} xs={24}>
          <div className={context.selectLang == undefined || context.selectLang == 'en' ? "mapWrapper":"mapWrapperar"}>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3807.443581102181!2d78.42460317491943!3d17.39048740271643!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb973a5d924355%3A0xd165687b868a3f6e!2sTawfeeq%20Tech%20%26%20Travel!5e0!3m2!1sen!2sin!4v1690894770788!5m2!1sen!2sin"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </Col> */}
      </Row>
    </>
  );
}

export default HotelResultsSort;
