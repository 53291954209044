/**
 * @ Author: Srikanth Bandaru
 * @ Create Time: 2023-06-12 09:05:48
 * @ Description: This is for Trip details
 */

import { Col, Row, Tooltip } from "antd";
import React from "react";
import AirlinesMemo from "../common/airlines/AirlinesMemo";
import AirportMemo from "../common/airlines/AirportNames";
import DateUtils from "../../../utilities/DateUtils";
// import FormatCurrency from "../../../utilities/FormatCurrency";
// import Utilities from "../../../utilities/Utilities";
import { useContext } from "react";
import { Context } from "../../../../App";
const CDNURL = process.env.REACT_APP_CNT_CDN_URL

function TripDetails(props) {
  const { flt, seg, 
    // sty, price, currency, sectors, serviceFee, 
    mapping } =
    props;
    const [context, setContext] = useContext(Context)
  //For layover time calculations
  const layOverTimeCal = (one, two) => {
    let totTime = Math.abs(new Date(one) - new Date(two));
    return DateUtils.msToTime(totTime);
  };
  const conditionsAJ = (data, type) => {
    let seats = "";
    let refundable = false;
    let avail_seats = data.filter((s) => s.selected == true);
    if (avail_seats.length > 0) {
      seats = avail_seats[0].seats_avail;
      refundable = avail_seats[0].refundable;
    }
    return type == "seats" ? seats : refundable;
  };
  return (
    <>
      {flt.seg && (
        <Row>
          <Col span={24}>
            <div className="ResultInfo dFlex">
              <div className="onlyFlex order">
                <div className="airlineName">
                  {flt.seg.filter((abc) => abc.ma !== seg.ma).length > 0 ? (
                    <>
                      <img
                        src={
                          CDNURL+"/airlines/1x/airline-logos/multipleAirline.png"
                        }
                        alt="AirLine"
                      />
                      <div>
                        {/* <strong className="planeName">Multiple Airline</strong> */}
                        <strong className="planeName"><Tooltip placement="top" title={context.translation && context.translation.mulAirlines}>{context.translation && context.translation.mulAirlines}</Tooltip></strong>
                        <span>
                          {seg.ma} - {seg.fn}
                        </span>
                      </div>
                    </>
                  ) : (
                    <>
                      <img
                        src={
                          CDNURL+"/airlines/1x/airline-logos/" +
                          seg.ma +
                          ".png"
                        }
                        alt="AirLine"
                      />
                      <div>
                        <span className="planeName">
                          <Tooltip placement="top" title={<AirlinesMemo code={seg.ma} />}><span><AirlinesMemo code={seg.ma} /></span></Tooltip>
                        </span>
                        <span className="planeCode">
                          {seg.ma} - {seg.fn}
                        </span>
                      </div>
                    </>
                  )}
                </div>
                <div className="airlineInfo">
                  <Row className="aiTop">
                    <Col className="fromTime">
                      {" "}
                      {seg.dd
                        .split("T")[1]
                        .substring(0, seg.dd.split("T")[1].length - 3)}
                    </Col>
                    <Col className="duration">
                      {flt.sdur !== "" && flt.sdur !== undefined && (
                        <span>
                          <i className="icon icon-time"></i>{" "}
                          {flt.sdur.split(":")[0] +
                            "h " +
                            flt.sdur.split(":")[1] +
                            "m"}
                        </span>
                      )}
                      {flt.bgd !== undefined && flt.bgd.length !== 0 && (
                        <span className="d-none">
                          {" "}
                          {flt.bgd[0].qun} {flt.bgd[0].wt}{" "}
                        </span>
                      )}
                    </Col>
                    <Col className="toTime">
                      {" "}
                      {flt.seg[flt.seg.length - 1].ad
                        .split("T")[1]
                        .substring(
                          0,
                          flt.seg[flt.seg.length - 1].ad.split("T")[1].length -
                          3
                        )}
                    </Col>
                  </Row>
                  <Row className="aiBottom">
                    <Col className="fromDest">
                    <Tooltip placement="bottom" title={<AirportMemo
                        field="ResultSegmentSection"
                        code={seg.da}
                        mapping={mapping}
                      />}>
                      <span><AirportMemo
                        field="ResultSegmentSection"
                        code={seg.da}
                        mapping={mapping}
                      /></span>
                    </Tooltip>
                    
                      {/* <strong>{seg.da}</strong> */}
                    </Col>
                    <Col className="stop">
                      {flt.seg.length > 1? 
                      <Tooltip overlayClassName="layoverTT" title={
                        <>{
                          flt.seg.length > 1? 
                            flt.seg.map((segs,idx) => (
                              flt.seg.length-1 > idx &&
                              <small key={idx}>{context.translation && context.translation.layover} : {layOverTimeCal(
                                segs.ad,
                                flt.seg[idx+1].dd
                              )} (<AirportMemo
                                field="ResultSegmentSection"
                                code={segs.ar}
                                mapping={mapping}
                              />)</small>
                            )):""
                         }</>
                      }>
                        <span>
                          {flt.seg.length - 1 === 0 ?  (context.translation && context.translation.noStop) : flt.seg.length - 1 + " " + ( context.translation && context.translation.stop)}{" "}
                        {flt.seg.length - 1 !== 0 && "-"}
                        </span>
                        {flt.seg.length !== 1}{" "}
                        <span>
                          {(flt.seg.length > 1
                            ? flt.seg
                              .slice(1, flt.seg.length)
                              .map((segs) => " " + segs.da + "")
                            : ""
                          ).toString()}
                        </span>
                      </Tooltip> 
                    :   <>
                    <span>
                    {flt.seg.length - 1 === 0 ?  (context.translation && context.translation.noStop) : flt.seg.length - 1 + " " + ( context.translation && context.translation.stop)}{" "}
                        {flt.seg.length - 1 !== 0 && "-"}
                        </span>
                        {flt.seg.length !== 1}{" "}
                        <span>
                          {(flt.seg.length > 1
                            ? flt.seg
                              .slice(1, flt.seg.length)
                              .map((segs) => " " + segs.da + "")
                            : ""
                          ).toString()}
                        </span>
                    </>
                    }
                    </Col>
                    <Col className="toDest">
                    <Tooltip placement="bottom" title={ <AirportMemo
                        field="ResultSegmentSection"
                        code={flt.seg[flt.seg.length - 1].ar}
                        mapping={mapping}
                      />}>
                      <span> <AirportMemo
                        field="ResultSegmentSection"
                        code={flt.seg[flt.seg.length - 1].ar}
                        mapping={mapping}
                      /></span>
                    </Tooltip>
                     
                      {/* <strong>{flt.seg[(flt.seg.length - 1)].ar}</strong> */}
                    </Col>
                  </Row>
                </div>
              </div>
              {/* <div className='flexDirection departure'>
                        
                        
                    </div> */}
              <div className="d-none">
                {
                  <>
                    <span>
                      {" "}
                      {flt.seg.length > 1 && (
                        <>
                          {" "}
                          {layOverTimeCal(
                            seg.ad,
                            flt.seg[flt.seg.length - 1].dd
                          )}
                        </>
                      )}
                    </span>
                  </>
                }
                <div>
                  <span></span>
                  <span></span>
                  {(flt.seg.length === 2 || flt.seg.length === 3) && (
                    <span>
                      <div>
                        <span>
                          {(flt.seg.length > 1
                            ? flt.seg
                              .slice(1, flt.seg.length)
                              .map((segs) => "(" + segs.da + ")")
                            : ""
                          ).toString()}
                        </span>{" "}
                        <br /> {context.translation && context.translation.layover} :{" "}
                        {layOverTimeCal(seg.ad, flt.seg[flt.seg.length - 1].dd)}
                      </div>
                    </span>
                  )}
                </div>
              </div>
              {/* <div>
                        
                        
                    </div>
                    <div className='flexDirection arrival'>
                        
                        
                    </div> */}
              <div className="weightSeats">
                {flt.bgd !== undefined && flt.bgd.length !== 0 && (
                  <div className="weight">
                    <span>
                      <i className="icon icon-Baggage"></i>
                      <>{flt.bgd[0].qun} {flt.bgd[0].wt}</>
                    </span>
                  </div>)}
                {flt.sup == "3"
                  ? conditionsAJ(flt.fareop, "seats") ? <div className="seats"><span className="seatsNumeric">{conditionsAJ(flt.fareop, "seats")}</span><span> {context.translation && context.translation.seatLeft}</span> </div> : ""
                  : seg.seat ? <div className="seats"><span className="seatsNumeric">{seg.seat}</span><span> {context.translation && context.translation.seatLeft}</span> </div> : ""}
              </div>
              <div className="d-none">
                {flt.sup !== "3" ? (
                  <>
                    {flt.re !== "" && flt.re !== undefined && (
                      <>
                        {flt.re === "Refundable Before Departure" ? (
                          <span>{context.translation && context.translation.penaltyApp}</span>
                        ) : (
                          <span>{context.translation && context.translation.nonRefund}</span>
                        )}
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {conditionsAJ(flt.fareop, "refund") ? (
                      <span>{context.translation && context.translation.penaltyApp}</span>
                    ) : (
                      <span>{context.translation && context.translation.nonRefund}</span>
                    )}
                  </>
                )}
              </div>
            </div>
          </Col>
          {/* <Col>
            {sty == 'split' &&
            <>
            {flt.sup!=="0" &&

            <div>LCC </div>
            }
            {flt.sup==="0" &&
            <div>FSC </div>
            }
            </>
                }
            {price && <div className="flighPrice d-flex justify-content-end col">
            {flt.sup!=="2" &&
                <strong><em className="priceTag"> {currency}</em> {<FormatCurrency value={Utilities.addPrice(price.tf + Number(props.onFlyMkp) + sectors.gst.total_gst, serviceFee, "Add")} />}</strong>
            }
            {flt.sup==="2" && props.sectors.show &&
                <strong><em className="priceTag"> {currency}</em> {<FormatCurrency value={Utilities.addPrice(price.tf + Number(props.onFlyMkp) + sectors.gst.total_gst, serviceFee, "Add")} />}</strong>
            }
            </div>}


            </Col> */}
        </Row>
      )}
    </>
  );
}

export default TripDetails;
