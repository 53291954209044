import {
  Button,
  Carousel,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Typography,
  notification,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import { validateEmail } from "../utilities/FormValidations";

import Logo from "../../assets/svg/logo.svg";
import CMSService from "../../services/cms/CMSService";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Context } from "../../App";
import Utilities from "../utilities/Utilities";
export const REGION_COUNTRY = "REGION_COUNTRY";
let stdCodesMaster = require("../bookingflow/flights/common/masterData/CountrySTDCodes.json");

const { Title } = Typography;

const { TextArea } = Input;

function HighInDemand(props) {
  const [context, setContext] = useContext(Context);
  const [res, setRes] = useState([]);
  const [api, contextHolder] = notification.useNotification();
  const [state, setState] = useState({
    fn: "",
    ln: "",
    phn: "",
    em: "",
    desc: "",
  });
  const [phc, setPhc] = useState("");
  const [cityName, setCityName] = useState("");
  const [days, setDays] = useState("");
  const [nights, setNights] = useState("");
  const [image, setImage] = useState("");
  const [loading, setLoading] = useState(false);

  const handleFieldChange = (fieldName, value) => {
    setState((prevstate) => ({
      ...prevstate,
      [fieldName]: value,
    }));
  };

  const handlePhoneCodeChange = (value) => {
    setPhc(value);
  };

  useEffect(() => {
    const payload = {       
      region:
        localStorage.getItem(REGION_COUNTRY) === "IN"
          ? "India"
          : "Qatar",
      channel: "B2C", 
      status: "Active"
     }
    let tokenKey = Utilities.generateAPIToken(payload);
    CMSService.loadHighInDemand(payload,tokenKey)
      .then((response) => {
        if (response.data.suc && response.data.res) {
          setRes(response.data.res[0]?.packages_list);
        }
      })
      .catch(function (error) {
        console.error("High In Demand response error ", error);
      });
  }, []);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = (city, days, nights, image) => {
    setIsModalOpen(true);
    setCityName(city);
    setDays(days);
    setNights(nights);
    setImage(image);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setState({
      fn: "",
      ln: "",
      phn: "",
      em: "",
      desc: "",
    });
    setPhc("");
  };

  const validate = () => {
    if (state.fn === "") {
      api.open({
        message: "Please enter first name",
        duration: 2,
      });
      return false;
    } else if (state.ln === "") {
      api.open({
        message: "Please enter last name",
        duration: 2,
      });
      return false;
    } else if (state.em === "") {
      api.open({
        message: "Please enter email",
        duration: 2,
      });
      return false;
    } else if (!validateEmail(state.em)) {
      api.open({
        message: "Please enter valid email",
        duration: 2,
      });
      return false;
    } else if (phc === "") {
      api.open({
        message: "Please enter phone code",
        duration: 2,
      });
      return false;
    } else if (state.phn === "") {
      api.open({
        message: "Please enter phone number",
        duration: 2,
      });
      return false;
    } else if (state.desc === "") {
      api.open({
        message: "Please enter description",
        duration: 2,
      });
      return false;
    }
    return true;
  };

  const sendEnquryMail = () => {
    if (validate()) {
      setLoading(true);
      state.phc = phc;
      state.city = cityName;
      state.days = days;
      state.nights = nights;
      state.img = image;
      let tokenKey = Utilities.generateAPIToken(state);
      CMSService.sendPackageEmail(state,tokenKey)
        .then((response) => {
          if (response.suc) {
            api.open({
              message: (context.translation && context.translation.emailSentsuc),
              duration: 2,
            });
            setTimeout(function () {
              setIsModalOpen(false);
              setLoading(false);
            }, 2000);
          } else {
            api.open({
              message: (context.translation && context.translation.ens),
              duration: 2,
            });
            setTimeout(function () {
              setIsModalOpen(false);
              setLoading(false);
            }, 2000);
          }
          setState({
            fn: "",
            ln: "",
            phn: "",
            em: "",
            desc: "",
          });
          setPhc("");
        })
        .catch(function (error) {
          console.error("sendEnquryMail response error ", error);
        });
    }
  };

  const contentStyle = {
    height: "455px",
    width: "100%",
    color: "#fff",
    textAlign: "center",
    background: "#364d79",
    objectFit: "cover",
  };
  
  const handleKeyPress = (e) => {
    // Check if the pressed key is the '+' symbol
    if (e.key === "+") {
      e.preventDefault(); // Prevent the '+' symbol from being entered
    }
    // Check if the pressed key is the '-' symbol
    if (e.key === "-") {
      e.preventDefault(); // Prevent the '-' symbol from being entered
    }
    // Check if the pressed key is the 'e' symbol
    if (e.key === "e") {
      e.preventDefault(); // Prevent the 'e' alphabet from being entered
    }
  };

  var settings = {
    infinite: true,
    speed: 500,
    dots: false,
    slidesToShow: 2,
    centerMode: true,
    centerPadding: "30px 20px",
    autoplay: false,
    rtl: false,
    nextArrow: false,
    prevArrow: false,
    responsive: [
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 2,
          centerMode: true,
          centerPadding: "30px 20px",
          slidesToScroll: 1,
          slidesToScroll: 1,
          nextArrow: false,
          prevArrow: false,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "30px 20px",
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <>
      {contextHolder}
      {res?.length >= 5 && (
        <div className="container hid">
          <div className="hid-slider">
          <Title level={2} className="cust-title" style={{ marginTop: 0 }}>
                <i className="sprite hid-sprite"></i>{" "}
                <span>
                  {/* High In Demand */}                  
                  {context.translation && context.translation.hid}
                </span>
              </Title>
              <Title level={4} className="hid-sub-title">
                {/* Search our best package deals! */}
                {context.translation && context.translation.hidTitle}
              </Title>
              <Slider {...settings} className="cntSlider cntHomepage">
              {res.map((data, index) => {
                return (
                  <div className="hids-block" key={index}>
                <Row className="hids-top">
                  <span className="overlay"></span>
                  <img src={res[0]?.url_img} alt="Malysia" height={580} />
                </Row>
                <div className="hids-bottom">
                  <div className="hidsContent">
                    <Title level={5}>
                      {context.selectLang == undefined || context.selectLang === "en"
                        ? res[0]?.city_country_en
                        : res[0]?.city_country_ar}
                    </Title>
                    <span>
                      {res[0]?.days} {context.translation && context.translation.days}, {res[0]?.nights} {context.translation && context.translation.nights}
                    </span>
                  </div>
                  <div className="hidsButton">
                    <Button type="secondary" ghost onClick={() => showModal(context.selectLang == undefined || context.selectLang === "en"
                        ? res[0]?.city_country_en
                        : res[0]?.city_country_ar, res[0]?.days, res[0]?.nights)}>
                      {/* Enquire Now */}
                      {context.translation && context.translation.hidBtn}
                    </Button>
                  </div>
                </div>
              </div>
                  )
              })}
          </Slider>
          </div>
          
          <Row className="hid-section">
            <Col>
              <Title level={2} className="cust-title" style={{ marginTop: "-7px" }}>
                <i className="sprite hid-sprite"></i>{" "}
                <span>
                  {/* High In Demand */}                  
                  {context.translation && context.translation.hid}
                </span>
              </Title>
              <Title level={4} className="hid-sub-title">
                {/* Search our best package deals! */}
                {context.translation && context.translation.hidTitle}
              </Title>

              <div className="hids-block">
                <Row className="hids-top">
                  <span className="overlay"></span>
                  <img src={res[0]?.url_img} alt="Malysia" height={580} />
                </Row>
                <div className="hids-bottom">
                  <div className="hidsContent">
                    <Title level={5}>
                      {context.selectLang == undefined || context.selectLang === "en"
                        ? res[0]?.city_country_en
                        : res[0]?.city_country_ar}
                    </Title>
                    <span>
                      {res[0]?.days} {context.translation && context.translation.days}, {res[0]?.nights} {context.translation && context.translation.nights}
                    </span>
                  </div>
                  <div className="hidsButton">
                    <Button type="secondary" ghost onClick={() => showModal(context.selectLang == undefined || context.selectLang === "en"
                        ? res[0]?.city_country_en
                        : res[0]?.city_country_ar, res[0]?.days, res[0]?.nights, res[0]?.url_img)}>
                      {/* Enquire Now */}
                      {context.translation && context.translation.hidBtn}
                    </Button>
                  </div>
                </div>
              </div>
            </Col>
            <Col>
              <div className="hids-block">
                <Row className="hids-top">
                  <span className="overlay"></span>
                  <img src={res[1]?.url_img} alt="London" height={370} />
                </Row>
                <div className="hids-bottom">
                  <div className="hidsContent">
                    <Title level={5}>
                      {context.selectLang == undefined || context.selectLang === "en"
                        ? res[1]?.city_country_en
                        : res[1]?.city_country_ar}
                    </Title>
                    <span>
                      {res[1]?.days} {context.translation && context.translation.days}, {res[1]?.nights} {context.translation && context.translation.nights}
                    </span>
                  </div>
                  <div className="hidsButton">
                    <Button type="secondary" ghost onClick={() => showModal(context.selectLang == undefined || context.selectLang === "en"
                        ? res[1]?.city_country_en
                        : res[1]?.city_country_ar, res[1]?.days, res[1]?.nights, res[1]?.url_img)}>
                      {/* Enquire Now */}
                      {context.translation && context.translation.hidBtn}
                    </Button>
                  </div>
                </div>
              </div>
              <div className="hids-block">
                <Row className="hids-top">
                  <span className="overlay"></span>
                  <img src={res[2]?.url_img} alt="Riyadh" height={370} />
                </Row>
                <div className="hids-bottom">
                  <div className="hidsContent">
                    <Title level={5}>
                      {context.selectLang == undefined || context.selectLang === "en"
                        ? res[2]?.city_country_en
                        : res[2]?.city_country_ar}
                    </Title>
                    <span>
                      {res[2]?.days} {context.translation && context.translation.days}, {res[2]?.nights} {context.translation && context.translation.nights}
                    </span>
                  </div>
                  <div className="hidsButton">
                    <Button type="secondary" ghost onClick={() => showModal(context.selectLang == undefined || context.selectLang === "en"
                        ? res[2]?.city_country_en
                        : res[2]?.city_country_ar, res[2]?.days, res[2]?.nights, res[2]?.url_img)}>
                      {/* Enquire Now */}
                      {context.translation && context.translation.hidBtn}
                    </Button>
                  </div>
                </div>
              </div>
            </Col>
            <Col>
              <div className="hids-block">
                <Row className="hids-top">
                  <span className="overlay"></span>
                  <img src={res[3]?.url_img} alt="Dubai" height={480} />
                </Row>
                <div className="hids-bottom">
                  <div className="hidsContent">
                    <Title level={5}>
                      {context.selectLang == undefined || context.selectLang === "en"
                        ? res[3]?.city_country_en
                        : res[3]?.city_country_ar}
                    </Title>
                    <span>
                      {res[3]?.days} {context.translation && context.translation.days}, {res[3]?.nights} {context.translation && context.translation.nights}
                    </span>
                  </div>
                  <div className="hidsButton">
                    <Button type="secondary" ghost onClick={() => showModal(context.selectLang == undefined || context.selectLang === "en"
                        ? res[3]?.city_country_en
                        : res[3]?.city_country_ar, res[3]?.days, res[3]?.nights, res[3]?.url_img)}>
                      {/* Enquire Now */}
                      {context.translation && context.translation.hidBtn}
                    </Button>
                  </div>
                </div>
              </div>
              <div className="hids-block">
                <Row className="hids-top">
                  <span className="overlay"></span>
                  <img src={res[4]?.url_img} alt="Istanbul" height={370} />
                </Row>
                <div className="hids-bottom">
                  <div className="hidsContent">
                    <Title level={5}>
                      {context.selectLang == undefined || context.selectLang === "en"
                        ? res[4]?.city_country_en
                        : res[4]?.city_country_ar}
                    </Title>
                    <span>
                      {res[4]?.days} {context.translation && context.translation.days}, {res[4]?.nights} {context.translation && context.translation.nights}
                    </span>
                  </div>
                  <div className="hidsButton">
                    <Button type="secondary" ghost onClick={() => showModal(context.selectLang == undefined || context.selectLang === "en"
                        ? res[4]?.city_country_en
                        : res[4]?.city_country_ar, res[4]?.days, res[4]?.nights, res[4]?.url_img)}>
                      {/* Enquire Now */}
                      {context.translation && context.translation.hidBtn}
                    </Button>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Modal
            title=""
            open={isModalOpen}
            onCancel={handleCancel}
            width={1030}
            footer={null}
            className="enquiryFormPopup"
            centered
          >
            <div className="enquiryFormWrapper">
              <button type="button" className="close" onClick={handleCancel}>
                <i className="icon icon-cancel"></i>
              </button>
              <Row>
                <Col md={24}>
                <Title level={4} className="title">
                    {/* Contact  */}
                    <span>{context.translation && context.translation.enqContactTitle} <img src={Logo} alt="Click N Travel" /> </span>
                    
                    {/* to book the perfect package for you! */}
                    <span>{context.translation && context.translation.enqTitle}</span>
                  </Title>
                  <Row gutter={20}>
                    <Col md={8}>
                      
                      <div>
                        <Carousel autoplay>
                          <div style={contentStyle}>
                              <img src={res[3]?.url_img} alt="Istanbul" />
                          </div>
                          <div style={contentStyle}>
                              <img src={res[3]?.url_img} alt="Istanbul" />
                          </div>
                          <div style={contentStyle}>
                              <img src={res[3]?.url_img} alt="Istanbul" />
                          </div>
                          <div style={contentStyle}>
                              <img src={res[3]?.url_img} alt="Istanbul" />
                          </div>
                          <div style={contentStyle}>
                              <img src={res[3]?.url_img} alt="Istanbul" />
                          </div>
                          <div style={contentStyle}>
                              <img src={res[3]?.url_img} alt="Istanbul" />
                          </div>
                        </Carousel>
                      </div>
                    </Col>
                    <Col md={16}>
                    
                    <div className="dFlex bgGray">
                        <Title level={5} className="destName">
                          {cityName}
                        </Title>
                        <span className="durationTime">
                          <i className="icon icon-time"></i> {days} {context.translation && context.translation.days}, {nights} {context.translation && context.translation.nights}
                        </span>
                      </div>
                    <Form layout="vertical" className="enquiryForm">
                        <div className="outerFormWrap">
                          <div className="innerFormWrap">
                          <Form.Item label={context.translation && context.translation.fn} autoComplete="off">
                          <Input
                            type="text"
                            placeholder={context.translation && context.translation.efn}
                            className="nameInput innerBoxShadow"
                            value={state.fn}
                            onChange={(e) =>
                              handleFieldChange("fn", e.target.value)
                            }
                          />
                        </Form.Item>
                          </div>
                          <div className="innerFormWrap">
                          <Form.Item label={context.translation && context.translation.ln} autoComplete="off">
                          <Input
                            type="text"
                            placeholder={context.translation && context.translation.eln}
                            className="nameInput innerBoxShadow"
                            value={state.ln}
                            onChange={(e) =>
                              handleFieldChange("ln", e.target.value)
                            }
                          />
                        </Form.Item>
                          </div>
                        </div>
                        <div className="outerFormWrap">
                          <div className="innerFormWrap">
                          <Form.Item label={context.translation && context.translation.em} autoComplete="off">
                          <Input
                            type="email"
                            placeholder={context.translation && context.translation.eem}
                            className="nameInput innerBoxShadow"
                            value={state.em}
                            onChange={(e) =>
                              handleFieldChange("em", e.target.value)
                            }
                          />
                        </Form.Item>
                          </div>
                          <div className="innerFormWrap">
                          <Form.Item label={context.translation && context.translation.ph} autoComplete="off">
                          <Input
                            addonBefore={
                              <>
                              {(sessionStorage.getItem("SEL_LAG") === null || sessionStorage.getItem("SEL_LAG") === "English") &&
                              <Select
                              optionLabelProp="value"
                              defaultValue="Select"
                              placeholder="Select"
                              popupClassName="hidContactDpDown"
                              showSearch
                              options={stdCodesMaster.map((option) => ({
                                label: option.label,
                                value: option.value,
                              }))}
                              value={phc?phc:undefined}
                              onChange={handlePhoneCodeChange}
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                (option?.label ?? "")
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                            />
                              }
                              {(sessionStorage.getItem("SEL_LAG") === "Arabic") &&
                              <Select
                              optionLabelProp="value"
                              defaultValue="اختار"
                              popupClassName="hidContactDpDown"
                              showSearch
                              options={stdCodesMaster.map((option) => ({
                                label: option.label,
                                value: option.value,
                              }))}
                              value={phc?phc:undefined}
                              onChange={handlePhoneCodeChange}
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                (option?.label ?? "")
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                            />
                              }
                            </>
                            }
                            className="contactNumber enterContactNumber selectBoxShadow"
                            type="number"
                            name="fn"
                            placeholder={context.translation && context.translation.eph}
                            onKeyPress={handleKeyPress} {...props}
                            value={state.phn}
                            onChange={(e) =>
                              handleFieldChange("phn", e.target.value)
                            }
                          />
                        </Form.Item>
                          </div>
                        </div>
                        
                        
                        
                        
                        <label className="descriptionTitle">
                          {/* Description */}
                          {context.translation && context.translation.desp}
                        </label>
                        <TextArea
                          rows={4}
                          // placeholder={context.translation && context.translation.text}
                          placeholder={context.translation && context.translation.desp}
                          className="textareaDesc"
                          value={state.desc}
                          onChange={(e) =>
                            handleFieldChange("desc", e.target.value)
                          }
                        />
                        <Button
                          type="primary"
                          className="cntBtn"
                          onClick={sendEnquryMail}
                          disabled={loading ? true : false}
                        >
                          {loading ? (
                            <span className="circleLoader"></span>
                          ) : (
                            context.translation && context.translation.enqBtn
                          )}                          
                          <i className="sprite btn-arrow-sprite"></i>
                        </Button>
                      </Form>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Modal>

          {/* {res.length > 0 &&    
          <Row gutter={24}>
            {res.map((data, index) => {
              return <Col span={8} key={index}>
                <div className="hids-block">
                  <Row className="hids-top">
                    <span className="overlay"></span>
                    <img src={data.url_img} alt="London" height={370} />
                  </Row>
                  <Row className="hids-bottom">
                    <div span={18} className="hidsContent">
                      <Title level={5}>
                        {props.enableInd ? data.city_country_en : data.city_country_ar}
                      </Title>
                      <span>{data.days} Days, {data.nights} Nights</span>
                    </Col>
                    <div span={6} className="hidsButton">
                      <Button type="secondary" ghost>Enquire Now</Button>
                    </Col>
                  </Row>
                </div>
              </Col>
            })}               
          </Row>
        }  */}

          {/* {res.length === 0 &&          
          <Title level={3} align="center">
            <span>No data found</span>
          </Title>  
        } */}
        </div>
      )}
    </>
  );
}

export default HighInDemand;
