import React, { useEffect, useRef } from "react";
import Footer from "./Footer";
import Header from "./Header";
import ShowHide from "./showHide/ShowHide";
import StickyBox from "react-sticky-box";
import { Col, Row, Typography } from "antd";

function UserAgreement(props) {
  const tcGeneral = useRef(null);
  const tc1 = useRef(null);
  const tc2 = useRef(null);
  const tc3 = useRef(null);
  const tc4 = useRef(null);
  const tc5 = useRef(null);
  const tc6 = useRef(null);
  const tc7 = useRef(null);
  const tc8 = useRef(null);
  const tc9 = useRef(null);
  const tc10 = useRef(null);
  const tc11 = useRef(null);
  const tc12 = useRef(null);
  const tc13 = useRef(null);
  const tc14 = useRef(null);
  const executeScrolltop = () =>
    tcGeneral.current.scrollIntoView({ behavior: "smooth" });
  const executeScrolltop1 = () =>
    tc1.current.scrollIntoView({ behavior: "smooth" });
  const executeScrolltop2 = () =>
    tc2.current.scrollIntoView({ behavior: "smooth" });
  const executeScrolltop3 = () =>
    tc3.current.scrollIntoView({ behavior: "smooth" });
  const executeScrolltop4 = () =>
    tc4.current.scrollIntoView({ behavior: "smooth" });
  const executeScrolltop5 = () =>
    tc5.current.scrollIntoView({ behavior: "smooth" });
  const executeScrolltop6 = () =>
    tc6.current.scrollIntoView({ behavior: "smooth" });
  const executeScrolltop7 = () =>
    tc7.current.scrollIntoView({ behavior: "smooth" });
  const executeScrolltop8 = () =>
    tc8.current.scrollIntoView({ behavior: "smooth" });
  const executeScrolltop9 = () =>
    tc9.current.scrollIntoView({ behavior: "smooth" });
  const executeScrolltop10 = () =>
    tc10.current.scrollIntoView({ behavior: "smooth" });
  const executeScrolltop11 = () =>
    tc11.current.scrollIntoView({ behavior: "smooth" });
  const executeScrolltop12 = () =>
    tc12.current.scrollIntoView({ behavior: "smooth" });
  const executeScrolltop13 = () =>
    tc13.current.scrollIntoView({ behavior: "smooth" });
  const executeScrolltop14 = () =>
    tc14.current.scrollIntoView({ behavior: "smooth" });
  const general = (e) => {
    executeScrolltop();
  };
  const general1 = (e) => {
    executeScrolltop1();
  };
  const general2 = (e) => {
    executeScrolltop2();
  };
  const general3 = (e) => {
    executeScrolltop3();
  };
  const general4 = (e) => {
    executeScrolltop4();
  };
  const general5 = (e) => {
    executeScrolltop5();
  };
  const general6 = (e) => {
    executeScrolltop6();
  };
  const general7 = (e) => {
    executeScrolltop7();
  };
  const general8 = (e) => {
    executeScrolltop8();
  };
  const general9 = (e) => {
    executeScrolltop9();
  };
  const general10 = (e) => {
    executeScrolltop10();
  };
  const general11 = (e) => {
    executeScrolltop11();
  };
  const general12 = (e) => {
    executeScrolltop12();
  };
  const general13 = (e) => {
    executeScrolltop13();
  };
  const general14 = (e) => {
    executeScrolltop14();
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  const { Title } = Typography;
  const update=(type)=>{
  }
  return (
    <>
      <Header posType="floating" setTab={update} />
      <div className="container tndC">
        <Title level={4} className="tcTitle">
        موافقة المستخدم
        </Title>
        <Row>
          <Col span={6} className="tcleftSidebar">
            <StickyBox offsetTop={100} offsetBottom={20}>
            <ul variant="pills" className="flex-column">
                <li>
                  <span eventKey="general" onClick={general} className="active" title="تطبيق الإتفاقية">
                  تطبيق الإتفاقية
                  </span>
                </li>
                <li>
                  <span eventKey="clientResp" onClick={general1} title="صلاحية الإستخدام">
                  صلاحية الإستخدام
                  </span>
                </li>
                <li>
                  <span eventKey="disclaimer" onClick={general2} title="التطبيق">
                  التطبيق
                  </span>
                </li>
                <li>
                  <span eventKey="pvcStatement" onClick={general3} title="المحتوى">
                  المحتوى
                  </span>
                </li>
                <li>
                  <span eventKey="copyright" onClick={general4} title="المسؤولية المحدودة لشركة كليك ان ترافل">
                  المسؤولية المحدودة لشركة كليك ان ترافل
                  </span>
                </li>
                <li>
                  <span eventKey="agency" onClick={general5} title="مسؤولية المستخدم">
                  مسؤولية المستخدم
                  </span>
                </li>
                <li>
                  <span eventKey="confidentiality" onClick={general6} title="المعلومات المتعلقة بالأمان والحساب">
                  المعلومات المتعلقة بالأمان والحساب
                  </span>
                </li>
                <li>
                  <span eventKey="indemnity" onClick={general7} title="الرسوم والدفعات">
                  الرسوم والدفعات
                  </span>
                </li>
                <li>
                  <span eventKey="governingLaw" onClick={general8} title="وجوب الحصول على التأشيرة">
                  وجوب الحصول على التأشيرة
                  </span>
                </li>
                <li>
                  <span eventKey="forceMajeure" onClick={general9} title="القوة القهرية">
                  القوة القهرية
                  </span>
                </li>
                <li>
                  <span eventKey="variations" onClick={general10} title="الحق في الإلغاء">
                  الحق في الإلغاء
                  </span>
                </li>
                <li>
                  <span eventKey="indiv" onClick={general11} title="المكالمات الزائفة وغيرها من أنشطة التصيد الاحتيالي أو البريد العشوائي أو الأنشطة الاحتيالية المماثلة">
                    المكالمات الزائفة وغيرها من أنشطة التصيد الاحتيالي أو البريد العشوائي أو الأنشطة الاحتيالية المماثلة
                  </span>
                </li>
                <li>
                  <span eventKey="tndc" onClick={general12} title="التعويض">
                  التعويض
                  </span>
                </li>
                <li>
                  <span eventKey="tndc" onClick={general13} title="Covid-19 الشروط والأحكام المتعلقة بـ ">
                 Covid-19 الشروط والأحكام المتعلقة بـ 
                  </span>
                </li>
                <li>
                  <span eventKey="tndc" onClick={general14} title="متفرقات">
                  متفرقات
                  </span>
                </li>
              </ul>
            </StickyBox>
          </Col>
          <Col span={18} className="tcLayout">
            <div ref={tcGeneral}>
              <ShowHide visible={false} icon="" title="تطبيق الإتفاقية">
                <div className="showHide-content">
                  <p>
                  هذا الاتفاق مع شروط الخدمة (جميعاً، "اتفاقية المستخدم") يشكل الشروط والأحكام لاستخدام الخدمات والمنتجات التي تقدمها شركة توفيق للسفريات ذ.م.م ("كليك ان ترافل").
                  </p>

                  <p>
                  أي شخص ("المستخدم") الذي يستفسر عن أو يشتري أي منتجات أو خدمات من كليك ان ترافل من خلال تطبيقاتها الجوالة، أو ممثلي المبيعات، أو المكاتب، أو مراكز الاتصال الهاتفي الخاصة بها وما إلى ذلك (يشار إليها جمعاً باسم "قنوات البيع") يوافق على أن يكون ملتزماً  بإتفاقية المستخدم.
                  </p>

                  <p>
                  كل من المستخدم وكليك ان ترافل يشار إليهما بصفة فردية بإسم 'الطرف' ويشار إليهما مجتمعين بصفة 'الأطراف' في هذه الاتفاقية.
                  </p>

                  <p>
                  "شروط الخدمة" المتاحة في تطبيق كليك ان ترافل توضح شروط وأحكام تطبيقها على مختلف الخدمات أو المنتجات التي يوفرها كليك ان ترافل. يجب على المستخدم الرجوع إلى شروط الخدمة ذات الصلة المطبقة على المنتج أو الخدمة المحددة التي تم حجزها من قبل المستخدم. وتكون شروط الخدمة ملزمة على المستخدم.
                  </p>
                </div>
              </ShowHide>
            </div>
            <div ref={tc1}>
              <ShowHide visible="true" icon="" title="صلاحية الإستخدام">
                <div className="showHide-content">
                  <p>
                  يجب أن يبلغ المستخدم  18 عاماً  على الأقل ويجب أن يكون لديه السلطة القانونية لدخول اتفاقية ليصبح مستخدماً لخدمات كليك ان ترافل. إذا كنت قاصراً أو تقل عن 18 عاماً، فلا يجوز لك التسجيل كمستخدم أو إجراء معاملات أو استخدام التطبيق.
                  </p>

                  <p>
                  وتحتفظ بكليك ان ترافل بحقها في إنهاء عضويتك و/أو حظر الوصول إلى التطبيق إذا تبين أنك قاصر أو غير قادر على التعاقد وفقاً للقانون أو إذا كانت أي معلومات تتعلق بعمرك المُدخلة أثناء إنشاء الحساب غير صحيحة. (الوصي القانوني أو الوالدين)  إذا كنت قاصراً وترغب في استخدام التطبيق أو إجراء معاملة عليه، يجب أن يتم الاستفادة من الخدمة بواسطة شخص ذو سن قانوني للتعاقد
</p>
                  <p>
                  قبل استخدام التطبيق، أو التواصل مع أي قنوات مبيعات، أو تأمين خدمات كليك ان ترافل، يجب على المستخدمين قراءة إتفاقية المستخدم هذه وفهمها بشكل إلزامي، ويُعتبر أنهم قد قبلوا هذه الاتفاقية كوثيقة رابطة تحكم في معاملات المستخدم مع كليك ان ترافل. إذا لم يوافق المستخدم على أي جزء من هذه الاتفاقية، فيجب عليه عدم الاستفادة من خدمات كليك ان ترافل وعدم الوصول إلى أو التوجه إلى قنوات بيع كليك ان ترافل.
                  </p>
                  <p>
                  .جميع حقوق والتزامات المستخدم وكليك ان ترافل فيما يتعلق بأي خدمة أو منتج يسهله كليك ان ترافل تكون مقيدة بنطاق اتفاقية المستخدم هذه
                  </p>
                </div>
              </ShowHide>
            </div>
            <div ref={tc2}>
              <ShowHide visible="true" icon="" title="التطبيق">
                <div className="showHide-content">
                  <p>
                .الشرعيين لأغراض قانونية (أو المستخدمين) يتعين استخدام التطبيق من قبل المستخدم 
                  </p>
                  <p>
                .يمنح اتفاق المستخدم حقاً محدوداً وغير حصري وغير قابل للنقل لاستخدام هذا التطبيق كما هو مسموح به صراحة في اتفاقية المستخدم. يتعهد المستخدم بعدم تعطيل أو محاولة تعطيل تشغيل التطبيق بأي طريقة من الطرق
                  </p>
                  <p>
                 .تحتفظ كليك ان ترافل بحقها، بمسؤوليتها الحصرية، في إنهاء الوصول إلى التطبيق والخدمات المقدمة على التطبيق أو أي جزء منه في أي وقت، دون إشعار مسبق، لأغراض الصيانة العامة أو أي سبب آخر
                  </p>
                  <p>
                .ستقوم كليك ان ترافل دائماً ببذل قصارى جهدها لضمان أن يكون محتوى تطبيقها أو غيره من قنوات البيع خالياً من أي فيروس أو برامج ضارة . ومع ذلك، أي بيانات أو معلومات تم تنزيلها أو إضافتها خلال استخدام التطبيق، أو أي قناة بيع أخرى تكون على عاتق ومسؤولية المستخدم الخاصة، ويكون مسؤولاً بشكل كامل عن أي ضرر قد يصيب الأجهزة الجوالة أو فقدان البيانات الذي قد ينجم عن تنزيل أو تحميل هذه البيانات أو المعلومات
                  </p>
                  <p>
                  عند استلام مثل هذا الإبلاغ، تحتفظ كليك ان ترافل بحقها في التحقيق و/أو اتخاذ الإجراءات التي قد تراها الشركة مناسبة. complianceofficer@att.qa  تحتفظ كليك ان ترافل بحقها في تحسين أو إجراء تغييرات دورية في تطبيقها في أي وقت دون إشعار مسبق للمستخدم. يُطلب من المستخدمين أن يقوموا بالإبلاغ عن أي محتوى في التطبيق يُعتبر غير قانوني، أو مرفوض، أو مؤدٍ للقذف، أو نابذ، أو مُخلٍ بالأدب، أو مُضايق للخصوصية، أو فاحش، أو مضايق، أو احتيالي، أو يتعارض مع أي معتقد ديني، أو يوجه رسالة غير مرغوب فيها، أو ينتهك أي قانون سارٍ إلى
                  </p>
                </div>
              </ShowHide>
            </div>
            <div ref={tc3}>
              <ShowHide visible="true" icon="" title="المحتوى">
                <div className="showHide-content">
                  <p>
                .مسجل من قبل كليك ان ترافل ومحمي بموجب قوانين الملكية الفكرية السارية. لا يمكن للمستخدم استخدام هذا المحتوى لأي غرض آخر، إلا كما هو محدد هنا.  (يشار لها جميعاً بإسم "المحتوى") كل المحتوى المقدم من خلال قنوات البيع المختلفة، بما في ذلك ولكن لا يقتصر على الصور والبرمجيات والنصوص والرموز ومحتوى مماثل
                  </p>
                  <p>
                 .يوافق المستخدم على اتباع جميع التعليمات التي توفرها كليك ان ترافل والتي ستحدد الطريقة التي يجوز له بها استخدام المحتوى
                  </p>
                  <p>
                 .هناك عدد من الشعارات التجارية وعلامات الخدمة والعلامات التجارية الخاصة المعروضة على التطبيق ومن خلال قنوات البيع الأخرى لكليك ان ترافل، كما تراه مناسباً. لا تمنح كليك ان ترافل للمستخدم ترخيصاً أو حقاً أو سلطةً لاستخدام مثل هذه الشعارات التجارية الخاصة أو علامات الخدمة أو العلامات التجارية بأي طريقة. سيكون أي استخدام غير مصرح به للمحتوى خرقاً للقانون المعمول به
                  </p>
                  <p>
                 :تحترم كليك ان ترافل حقوق الملكية الفكرية للآخرين. إذا لاحظت أي عملية انتهاك لهذه الحقوق على التطبيق، يُرجى إرسال إشعار/ تنبيه كتابي يجب أن يتضمن المعلومات التالية
                    <ul>
                      <li>
                      {""} تحديد واضح للعمل المحمي بحقوق التأليف والنشر الذي تزعم أنه تم انتهاكه-
                      </li>
                      <li>
                        {" "}  إثبات أن العمل المحمي بحقوق التأليف والنشر المزعوم يخصك-
                      </li>
                      <li> معلومات الاتصال-</li>
                      <li>
                       <a href="mailto:complianceofficer@att.qa">complianceofficer@att.qa</a> يمكن إرسال هذه الإشعارات إلى كليك ان ترافل عبر البريد الإلكتروني إل-
                      </li>
                    </ul>
                  </p>
                </div>
              </ShowHide>
            </div>
            <div ref={tc4}>
              <ShowHide
                visible="true"
                icon=""
                title="المسؤولية المحدودة لشركة كليك ان ترافل"
              >
                <div className="showHide-content">
                  <p>
                .وتقتصر مسؤولية كليك ان ترافل على توفير الحجز المؤكد للمستخدم كما تم اختياره من قبل المستخدم .(المشار إليهم جميعاً باسم "مقدمي الخدمات") ما لم تتصرف كليك ان ترافل صراحة كموزع في حالات معينة، تعمل كليك ان ترافل دائماً كوسيط عن طريق ربط المستخدم مع مقدمي الخدمات المعنيين مثل شركات الطيران والفنادق وما إلى ذلك
                  </p>
                  <p>
                 . أي قضايا أو مخاوف تواجه المستخدم أثناء الاستفادة من الخدمات المقدمة من مزود الخدمة تكون مسؤولية حصرية لمقدم الخدمة، ولا تتحمل كليك ان ترافل أي مسؤولية فيما يتعلق بتصرفات، أو إهمال، أو أخطاء، أو ممثلين، أو ضمانات، أو انتهاكات قد تبدرمن قبل أي مقدم خدمة
                  </p>
                  <p>
                 : مالم يتم التعهد به صراحة كجزء من أي منتج أو خدمة، فإن كليك ان ترافل
                    <ul>
                      <li>
                       لا تتحمل أي مسؤولية بشأن جودة الخدمات المقدمة من قبل مقدمي الخدمات المعنيين
                      </li>
                      <li>
                      لا تقدم أي ضمان بشأن جودتها أو ملاءمتها كما هو معروض
                      </li>
                      <li>
                      لا تضمن توفر أي خدمات كما هو مدرج من قبل مقدم الخدمة
                      </li>
                      <li>
                     .بواسطة إجراء الحجز، يفهم المستخدم أن كليك ان ترافل توفر ببساطة منصة تكنولوجية لحجز الخدمات والمنتجات وأن المسؤولية النهائية تقع على مقدم الخدمة وليس على كليك ان ترافل. بالتالي، يكون العقد النهائي للخدمة بين المستخدم ومقدم الخدمة
                      </li>
                      <li>
                     .يفهم المستخدم أيضًا أن المعلومات المعروضة على التطبيق بخصوص أي خدمة تُعرض كما هو مُقدم من قبل مقدم الخدمة. لذلك، لا يمكن تحميل كليك ان ترافل مسؤولية في حالة عدة دقة أو صحة، أو نقص، أو انقضاء للمعلومات المقدمة من قبل مقدم الخدمة أو انتهاك أي قوانين أو قواعد أو توجيهات نافذة
                      </li>
                    </ul>
                  </p>
                </div>
              </ShowHide>
            </div>
            <div ref={tc5}>
              <ShowHide visible="true" icon="" title="مسؤولية المستخدم">
                <div className="showHide-content">
                  <p>
                  .يُنصح المستخدمون بفحص وصف الخدمات والمنتجات بعناية قبل إجراء حجز. يوافق المستخدمون على أن يُلزموا بجميع الشروط المدرجة في تأكيد الحجز أو كما هو مفصل في قسيمة الحجز المؤكد. يجب أيضا قراءة هذه الشروط بالإضافة إلى اتفاقية المستخدم
                  </p>
                  <p>
                .إذا كان المستخدم ينوي إجراء حجز نيابة عن شخص آخر، يتحمل المستخدم مسؤولية إبلاغ هذا الشخص بشروط هذه الاتفاقية، بما في ذلك جميع القواعد والقيود المطبقة عليه
                  </p>
                  <p>
                  أو أي جهة أخرى مخولة بذلك من قبل الحكومة بشأن استخدام الخدمات أو لكل حجز.(M.O.P.H) يتعهد المستخدم بالامتثال لجميع الإجراءات والإرشادات، كما يتم تعديلها من وقت لآخر، فيما يتعلق باستخدام الخدمات المتاحة من خلال كليك ان ترافل. كما يتعهد المستخدم بالامتثال لجميع القوانين واللوائح والأوامر والتوجيهات وما إلى ذلك، الصادرة سواءً عن وزارة الصحة العامة
                  </p>
                  <p>
                . تُقدم الخدمات  "كما هي" و "كما هو متاح". ويحق لكليك ان ترافل تغيير الميزات أو وظائف الخدمات التي تُقدم في أي وقت، بحسب تقديرها الخاص، دون أي إشعار مسبق. ولا تتحمل كليك ان ترافل أي ضمانات مقدمة من أي نوع، سواء كانت صريحة أو ضمنية، بما في ذلك، وليس قصراً الضمانات الضمنية للتسويق أو موجهة لغاية معينة، ولا تعتبر أي نصيحة أو معلومات، سواء كانت شفوية أو كتابية قديحصل عليها المستخدم من كليك ان ترافل أو من خلال الخدمات المختارة أي ضمان لم يتم إعلانه صراحة هنا أو في شروط وأحكام الخدمات
                  </p>
                </div>
              </ShowHide>
            </div>
            <div ref={tc6}>
              <ShowHide
                visible="true"
                icon=""
                title="المعلومات المتعلقة بالأمان والحساب"
              >
                <div className="showHide-content">
                  <p>
                . أثناء التسجيل على الموقع، يتعين على المستخدم اختيار كلمة مرور للوصول إلى حسابه، ويتحمل المستخدم المسؤولية الكاملة عن الحفاظ على سرية كلمة المرور والحساب، بالإضافة إلى جميع الأنشطة على الحساب. يتعين على المستخدم إخطار كليك ان ترافل على الفور بالكتابة في حالة حدوث أي استخدام غير مصرح به لكلمة المرور أو الحساب أو أي انتهاك آخر  لشروط الأمان. ولن تكون كليك ان ترافل مسؤولة عن أي خسارة قد تحدث للمستخدم نتيجة لاستخدام غير مصرح به لكلمة المرور أو الحساب، سواء كان ذلك بمعرفة المستخدم أو بدون علمه. يجب على المستخدم عدم استخدام حساب أي شخص آخر في أي وقت
                  </p>
                  <p>
                .تحتفظ كليك ان ترافل بجميع البيانات المتعلقة ببطاقات الائتمان وبطاقات الخصم ومعلومات البنك إلخ بشكل آمن ومشفر وفقاً للقوانين والتنظيمات السارية

                  </p>
                  <p>
                .تعتمد كليك ان ترافل على أفضل معايير الصناعة لتأمين المعلومات التي يقدمها المستخدم. ومع ذلك، لا يمكن لكليك ان ترافل أن تضمن عدم حدوث أي انتهاك للأمان في أنظمتها قد يؤثر على أيضاً على بيانات المستخدم 
                  </p>
                  <p>
                 .قد تقوم كليك ان ترافل بمشاركة بيانات المستخدم المتاحة لديها مع الجهات الأمنية المختصة لأي هدف قانوني أو تحقيق أمني بدون الحاجة للحصول موافقة من المستخدم
                  </p>
                </div>
              </ShowHide>
            </div>
            <div ref={tc7}>
              <ShowHide visible="true" icon="" title="الرسوم والدفعات">
                <div className="showHide-content">
                  <p>
                .  بحق فرض رسوم معينة كرسوم خدمة. وأيضاً بحق تعديل جزء أو كل الرسوم من وقت لآخر. سيتم عرض أي رسوم إضافية مثل الرسوم المتعلقة بأي تعديلات على الحجز للمستخدم قبل تأكيد الحجز أو الدفع   Clickntravel  بالإضافة إلى تكلفة الحجز كما يتم فرضها من قبل مقدمي الخدمة، تحتفظ
                  </p>
                  <p>
                 . بتوفير حجز بديل بدلاً من الحجز غير المؤكد أو تعويضه أو استبداله. سيتم التعامل مع جميع الحجوزات اللاحقة كصفقات جديدة. سيتم تنفيذ أي استرداد مستحق وفقاً لسياسات مقدم الخدمة المحددة وحسب الحالة Clickntravel   برد مبلغ الحجز الذي دفعه المستخدم وإعلام المستخدم بذلك. لا يلتزم   Clickntravel  في حالة نادرة عدم تأكيد الحجز لأي سبب من الأسباب، سيقوم
                  </p>
                  <p>
                 . يكون المستخدم مسؤولًا تماماً عن جميع الرسوم والضرائب والتقديرات الناشئة عن استخدام الخدمة وفقاً للقوانين السارية
                  </p>
                  <p>
                 . لن تكون مسؤولة عن ذلك. لن يكون للمستخدم أي حق في استرداد أي مبلغ قام بتحويله إلى أي طرف ثالث Clickntravel  بشكل شرعي أو إلى أي حساب شخصي لأي شخص، فإن  Clickntravel يوافق المستخدم على أنه إذا قام بتحويل أي مبلغ لأي حجز أو معاملة إلى أي حساب بنكي لا تمتلكه . Clickntravel أو وكلاؤها أو ممثلوها أو موظفوها أبداً من العميل تحويل الأموال إلى أي حساب خاص أو إلى حساب غير مسجل باسم  Clickntravel   لن يطلب .Clickntravel يوافق المستخدم ويتفهم أن جميع المدفوعات يجب أن تتم فقط إلى حسابات بنك
                  </p>
                  <p>
                .  مسؤولة عن أي خسارة يتكبدها المستخدم بسبب مشاركة هذه التفاصيل Clickntravel فوراً إذا تم طلب مثل هذه التفاصيل من قبل أي وكلاء أو موظفين أو ممثلين لها. لن تكون  Clickntravel أو موظفيها أو ممثليها. يجب على المستخدم إبلاغ Clickntravel تاريخ انتهاء البطاقة، معرف المستخدم، كلمات المرور، إلخ مع أي شخص بما في ذلك وكلاء  ,CVV,OTP, لن يطلب من أي مستخدم أن يشارك معلوماته الشخصية الحساسة مثل رقم بطاقة الائتمان/الخصم
                  </p>
                  <p>
                 . الحجوزات التي يقوم المستخدم بإجرائها من خلال  (بطاقة الائتمان/الخصم إلخ) ستتم تنفيذ أي استرداد في حال إستحقاقه على الحجوزات الملغاة إلى الحساب أو الأداة المصرفية 
                  </p>
                  <p>
                  تخضع لسياسة الإلغاء السارية كما هو مبين على صفحة الحجز أو كما تم التواصل مع العملاء كتابةً Clickntravel التي تم الدفع بها لذلك الحجز

                  </p>
                </div>
              </ShowHide>
            </div>
            <div ref={tc8}>
              <ShowHide
                visible="true"
                icon=""
                title="وجوب الحصول على التأشيرة"
              >
                <div className="showHide-content">
                  <p>
                 .تخضع لمتطلبات التأشيرة بما في ذلك، ولكن لا تقتصر على التأشيرة العابرة، وتأشيرة "جواز المرور"، والتي يجب على المستخدم الحصول عليها وفقًا لمتطلبات حجوزات السفر الخاصة بهم ومتطلبات الدول التي يعتزم المستخدم زيارتها أو العبور من خلالها Clickntravel  الحجوزات الدولية التي تتم من خلال 
                  </p>
                  <p>
                 .لعملية التأشيرة أم لا. سيتم استرداد أي مبلغ وفقًا لشروط الحجز وسياسة الإلغاء المعمول بها  Clickntravel  مسؤولية أي قضايا، بما في ذلك عدم القدرة على السفر، ناتجة عن مثل هذه متطلبات التأشيرة، ولا تكون ملزمة برد أي مبلغ للمستخدم لعدم قدرته على استخدام الحجز بسبب عدم وجود التأشيرة أو رفضها، سواء كان المستخدم قد استفاد من خدمات  Clickntravel  لا تتحمل 
                  </p>
                </div>
              </ShowHide>
            </div>
            <div ref={tc9}>
              <ShowHide visible="true" icon="" title="القوة القهرية">
                <div className="showHide-content">
                  <p>
                  .كوسيط فقط للخدمات والمنتجات المحجوزة، لا يمكن أن تكون مسؤولة عن أي ظرف قاهر. يجب على المستخدم الاتصال بمقدم الخدمة مباشرة لأية قضايا واستردادات إضافية. Clickntravel  معرفة مسبقة بأي مواقف من هذا القبيل حيث قد تحدث عدم الامتثال للحجوزات، ستبذل أقصى جهد لتوفير بديل مماثل للمستخدم أو استرداد مبلغ الحجز بعد خصم الرسوم الخدمية المطبقة، إذا كانت مدعومة ومستردة من قبل مشغلي الخدمة ذلك. يتفق المستخدم على أن Clickntravel  إذا كانت لدى . Clickntravel  و/أو مقدمي الخدمة تأكيد الحجوزات المؤكدة لأسباب متنوعة مثل القوى الخارجة عن السيطرة مثل الكوارث الطبيعية، المضاهرات  العمالية، الإفلاس، الضروريات التجارية، قرارات الحكومة، الجائحات، الأوبئة، الأنشطة الإرهابية، أي قضايا تشغيلية أو تقنية، إلغاء الرحلات والرحلات الجوية، إلخ، أو أي سبب آخر خارج عن سيطرة  Clickntravel قد تكون هناك ظروف استثنائية حيث قد يتعذر على
                  </p>
                  <p>
                  من جميع المسؤوليات فيما يتعلق بتلك العملية. أية التزامات إضافية، إن وجدت، ستكون على عاتق المستخدم. Clickntravel محدودة بإعادة مبلغ الحجز، إذا كان هناك، الذي تم استلامه من العميل. ستقوم هذه الاستردادات بالتفريغ الكامل ل  Clickntravel أو أي فشل مماثل آخر، تكون التزامات (مثل توقف الشبكة، انقطاع الاتصال مع منصات الطرف الثالث مثل بوابات الدفع، البنوك، إلخ) يتفق المستخدم على أنه في حالة عدم تأكيد الحجز بسبب أي أسباب تقنية
                  </p>
                  <p>
                 .و... مسؤولين عن أية أضرار مباشرة أو غير مباشرة، أو عقوبية، أو عرضية، أو خاصة، أو ناتجة، وأية أضرار أخرى مثل فقدان الاستخدام، أو البيانات، أو الأرباح، ناتجة عن أو متصلة باستخدام أو أداء التطبيق أو أي قناة مبيعات أخرى Clickntravel في أي حال، لا يكون
                  </p>
                </div>
              </ShowHide>
              <div ref={tc10}>
                <ShowHide visible="true" icon="" title="الحق في الإلغاء">
                  <div className="showHide-content">
                    <p>
                    Clickntravel  يلتزم المستخدم صراحة بتوفير معلومات صحيحة وصالحة أثناء استخدامه للموقع في إطار اتفاق المستخدم هذا، وعدم قدرته على إعطاء أي تمثيل خاطئ للحقائق. أي تقاعس من جانب المستخدم سيحرمه من الاستفادة من خدمات
                    </p>
                    <p>
                   . مسؤولة أو ملزمة بأي خسارة أو ضرر قد يلحق بالمستخدم أو أي شخص آخر في الحجز، نتيجة لإلغاء هذا الحجز أو الخدمات Clickntravel مؤهلة للحصول على الوسائل القانونية المناسبة ضد المستخدم، بما في ذلك إلغاء الحجوزات، دون أي إخطار مسبق للمستخدم. في مثل هذا الحدث، لن تكون Clickntravel أو وجود أسباب للاعتقاد في أي وقت أثناء أو بعد استلام طلب الخدمات من المستخدم أن الطلب غير مصرح به أو أن المعلومات التي قدمها المستخدم أو أي من المسافرين غير صحيحة أو أن أي حقيقة قد تم تحريفها من قبل ذلك المستخدم، ستكون Clickntravel في حال اكتشاف
                    </p>
                    <p>
                   . بإلغاء الحجز نفسه دون التوجه إلى المستخدم المعني الذي تم إلغاء حجزه Clickntravel  لإلغاء أي حجز، ستقوم Clickntravel إذا تقدمت أي هيئة قضائية أو شبه قضائية أو وكالة تحقيق أو سلطة حكومية إلى
                    </p>
                    <p>
                   .أو إلغاء أي حجوزات بسبب المعاملات المشتبه فيها بالاحتيال  Clickntravel  لحماية مصلحتها الخاصة ومصلحة عملائها. ويشمل ذلك أيضًا رفض Clickntravel المسؤولية عن أي خسارة أو ضرر ناشئ عن التدابير التي اتخذتها  Clickntravel لا يجوز للمستخدم تحميل
                    </p>
                  </div>
                </ShowHide>
              </div>
            </div>
            <div ref={tc11}>
              <ShowHide
                visible="true"
                icon=""
                title="المكالمات الزائفة وغيرها من أنشطة التصيد الاحتيالي أو البريد العشوائي أو الأنشطة الاحتيالية المماثلة"
              >
                <div className="showHide-content">
                  <p>
                 .ولن يطلبوا أبداً تحويل أموال إلى حساب بنكي شخصي أو فردي. علاوة على ذلك، ولن يطلبوا أيضاً من المستخدم تثبيت أي تطبيقات من جهات خارجية تمكنهم من عرض شاشة هاتف المستخدم أو جهاز الكمبيوتر ,(OTP) أو معلومات تسجيل الدخول للخدمات المصرفية عبر الإنترنت، أو كلمات المرور، أو رموز مرة واحدة ,(CVV) أو الوكلاء المعتمدين بالمستخدم أبداً لطلب رقم بطاقته الائتمانية أو بطاقته الائتمانية، أو تاريخ انتهاء الصلاحية، أو رمز التحقق من البطاقة Clickntravel لن يتصل موظفو
                  </p>
                  <p>
                .الاستجابة لأي من هذه الطلبات قد تجعلك ضحية للتزوير وقد تؤدي بشكل محتمل إلى فقدان أموالك أو معلوماتك القيمة
                  </p>
                  <p>
                     <a href="mailto:complianceofficer@att.qa">complianceofficer@att.qa</a> إذا طُلبت منك أي من المعلومات المذكورة أعلاه، يرجى الإبلاغ عنها فورًا على البريد الإلكتروني
                  </p>
                </div>
              </ShowHide>
            </div>
            <div ref={tc12}>
              <ShowHide visible="true" icon="" title="التعويض">
                <div className="showHide-content">
                  <p>
                 .المطالب بها أو التي تكون قائمة من قبل أو يتحملها هؤلاء الأشخاص المعوَّضين، نتيجة لأو تنتج عن أي انتهاك لأي تمثيل أو ضمان قدمه المستخدم، أو عدم أداء أي تعهد من قبل المستخدم (بما في ذلك الرسوم القانونية والنفقات المتصلة بها والفائدة المتحملة على ذلك) وشركاتها التابعة، ومديريها، ومن يخلفهم بصفة قانونية، والوكلاء من وقع عليهم أو الذين تنتقل إليهم الالتزامات والحقوق، من جميع الخسائر والالتزامات والمطالب والضرر والتكاليف والنفقات  Clickntravel يوافق المستخدم على تعويض، والدفاع، وحماية 
                  </p>
                  <p>
                 .مسؤولية عن ذلك Clickntravel يكون المستخدم مسؤولًا بشكل حصري عن أي انتهاك لقواعد ولوائح بلد معين أو قواعد ولوائح عامة للسلوك، ولا يمكن تحميل
                  </p>
                </div>
              </ShowHide>
            </div>
            <div ref={tc13}>
              <ShowHide
                visible="true"
                icon=""
                title="Covid-19 الشروط والأحكام المتعلقة بـ "
              >
                <div className="showHide-content">
                  <p>
                 :على مستوى عالمي، قد تحدث حالات حيث يلغي مزود الخدمة النهائي الحجز أو يرفض تقديم الخدمات، كما تم الاتفاق مع المستخدم، سواء بتعيين سبب معين أو بدون تعيين أي سبب لهذا الرفض. يمكن أن يكون سبب رفض الخدمة بسبب المستخدم COVID-19 يوافق المستخدم على أنه نتيجة لانتشار جائحة 
                    <ul>
                      <li> COVID 19  تظهر أي أعراض مرتبطة بـ-</li>
                      <li>
                        . رفض الامتثال لتوجيهات السلامة، مثل ارتداء معدات الحماية، والامتثال لتوجيهات التباعد الاجتماعي، إلخ-
                      </li>
                      <li>
                      . عاناة أي مرض يجعل المستخدم عرضة للفيروس-
                      </li>
                      <li>
                      . تشكل تهديدًا لصحة وسلامة الآخرين-
                      </li>
                      <li> Coivd-19  عدم تحقيق معايير التطعيم ضد-</li>
                    </ul>
                   . للمستخدم بناءً على استلامها من مزود الخدمة النهائي CLICKNTRAVEL أي مسؤولية عن رفض مزود الخدمة النهائي لتقديم الخدمة. يوافق المستخدم أيضًا على أن يتم معالجة أي رد مالي، إذا كان هناك، لمثل هذه الحجوزات من قبل CLICKNTRAVEL في مثل هذه الحالات، فلن يكون على
                  </p>
                  <p>
                 . قد قامت وزارة الصحة العامة بوضع مجموعة مفصلة من الإرشادات أو إجراءات التشغيل القياسية التي يجب أن يتبعها المسافر للقيام بالرحلة  Coivd-19  كما يفهم المستخدم أنه نتيجة لانتشار
                  </p>
                  <p>
                 .مسؤولية رفض السفر وسيتم معالجة أي رد مالي، إذا كان هناك، بدقة وفقًا لشروط مزود الخدمة CLICKNTRAVEL يوافق المستخدم على الامتثال لجميع هذه الإرشادات / إجراءات التشغيل القياسية أثناء أي رحلة. في حال عدم الامتثال لهذه المجموعة المفصلة من الإرشادات / إجراءات التشغيل القياسية، قد يؤدي ذلك إلى حالة يمكن فيها لمزود الخدمة أو أي شخص يتم تفويضه من قبل وزارة الصحة العامة منع المستخدم من القيام بالرحلة. في مثل هذه الظروف، لن يتحمل
                  </p>

                  <p>
                   
                 .وشركاتها التابعة ومديريها ومن يخلفهم بصفة قانونية والوكلاء من وقع على أو الذين يتحملون المسؤولية التبعات القانونية والقضائية والوكلاء منهم، والذين ينشأون عنها أو ينتجون عنها أو قد تكون مستحقة بموجبها أي انتهاك لأي تمثيل أو ضمان قدمه المستخدم، أو عدم أداء أي تعهد من قبل المستخدم.Clickntravel يوافق المستخدم على تعويض والدفاع عن
                  </p>
                  <p>
                 . مسؤولية عن ذلك  Clickntravel المستخدم يكون مسؤولًا بشكل حصري عن أي انتهاك لقواعد ولوائح بلد معين أو قواعد ولوائح عامة للسلوك، ولا يمكن تحميل
                  </p>
                </div>
              </ShowHide>
            </div>
            <div ref={tc14}>
              <ShowHide visible="true" icon="" title="متفرقات">
                <div className="showHide-content">
                  <p>
                  . إذا تبين أن أي بند من بنود اتفاقية المستخدم هذه غير صالح أو غير قابل للتنفيذ جزئيًا أو كليًا، يكون التفريق بين الصلاحية أو عدم القدرة على التنفيذ تتعلق فقط بتلك الفقرة أو جزء من تلك الفقرة، ويظل الجزء الباقي من تلك الفقرة وجميع الفقرات الأخرى في هذه اتفاقية المستخدم سارية المفعول بكاملها :<strong>الاستقلالية</strong>
                  </p>
                  <p>
                .تخضع هذه الاتفاقية لقوانين وأحكام دولة قطر <strong>:الاختصاص القضائي</strong> 
                  </p>
                  <p>
                  بحق تغيير اتفاقية المستخدم من وقت لآخر. يتحمل المستخدم مسؤولية مراجعة اتفاقية المستخدم بشكل منتظم. Clickntravel  تحتفظ <strong>:تعديل على اتفاقية المستخدم</strong>{" "}
                  
                  </p>
                  <p>
                  وشركاتها التابعة وفقاً لشروط سياسة الخصوصية  Clickntravel  والتي تحكم استخدام التطبيق. من خلال استخدام التطبيق، يوافق المستخدم على شروط سياسة الخصوصية وبناءً على ذلك، يوافق على استخدام معلوماته الشخصية من قبل Clickntravel المتاحة على تطبيق Clickntravel يجب على المستخدم أيضاً الرجوع إلى سياسة الخصوصية الخاصة ب <strong>:سياسة الخصوصية</strong> 
                  </p>

                  <p></p>
                </div>
              </ShowHide>
            </div>
          </Col>
        </Row>
      </div>
      <Footer />
    </>
  );
}

export default UserAgreement;
