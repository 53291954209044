/**
 * @ Author: Ubedullah Mohammed
 * @ Create Time: 2024-01-16 12:10:53
 * @ Description: Error component when the API has no response
 */

import { Button, Typography } from 'antd';
import React from 'react';
import RejectedSession from "../../assets/img/reject-session.png";

const { Title } = Typography;

function ApiError(props) {
    const reload = () => {
      window.location.reload();
    }
    
  return (
    <div className='errorOuterWrap apiError'>
      <div className='errorInnerWrap apiError'>
        <center>
            <img src={RejectedSession} alt='rejected-session' />
            <Title level={2}>{props.context.translation && props.context.translation.whaht}</Title>
            <p>{props.context.translation && props.context.translation.srs}</p>
            <Button onClick={reload}>{props.context.translation && props.context.translation.retry}</Button>
        </center>
      </div>
    </div>
 )
}

export default ApiError;