/**
 * @ Author: Ubedullah Mohammed
 * @ Create Time: 2024-01-05 15:26:17
 * @ Description: This is for displaying the Static Fare Rules flights under FareRules tab of FlightDetails
 */

import React from "react";
import { useContext } from "react";
import { Context } from "../../../../App";
import AirportMemo from "../common/airlines/AirportNames";
import FlightFareRulesTerms from "./FlightFareRulesTerms";
import FlightFareOptionHeader from "./FlightFareOptionHeader";

function FlightStaticFareRules(props) {
  const [context, setContext] = useContext(Context);
  const { mapping, sect, secType, showSectors, flights, rtnflt, sf, trip, cur } = props;
  return (
    <>
      <div className="alignmentStaticFareRules">
        {trip !== undefined && parseInt(trip) === 3 ? (
          <>
            {sect !== undefined &&
              sect.map((sec, index) => (
                <div className="w-50">
                  <div className="onlyFlex">
                    <span className="smallFlight">
                      <i className="icon icon-flight"></i>
                    </span>
                    <span>
                      {sec.src !== "LON" && (
                        <AirportMemo
                          field="ResultSectionHeader"
                          code={sec.src}
                          mapping={mapping}
                        />
                      )}
                      {sec.src === "LON" && "London"}
                    </span>
                    <span className="rightArrow">
                      <i className="icon icon-next-long-arrow-right-svgrepo-com"></i>
                    </span>
                    <span>
                      {sec.des !== "LON" && (
                        <AirportMemo
                          field="ResultSectionHeader"
                          code={sec.des}
                          mapping={mapping}
                        />
                      )}
                      {sec.des === "LON" && "London"}
                    </span>
                  </div>
                  <div className="listItemWrap">
                    <div className="listItemHeading">
                    {context.translation && context.translation.canRei}
                    </div>
                    <ul className="listItemCharge">
                      <li>
                        <span>
                          {context.translation && context.translation.isttap}{" "}
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              ))}
          </>
        ) : (
          <>
            {flights !== undefined && secType !== undefined && (
              <>
                {flights !== undefined &&
                  flights.seg !== undefined &&
                  flights.seg.map((s, index) => (
                    <>
                      {index === 0 && (
                        <>
                          <div className="w-50">
                            <FlightFareOptionHeader
                              mapping={mapping}
                              sectors={sect}
                              secType={secType}
                            />
                            <div className="listItemWrap">
                              <div className="listItemHeading">
                                {context.translation && context.translation.canRei}
                              </div>
                              <ul className="listItemCharge">
                                <li>
                                  <span>
                                   {context.translation && context.translation.isttap}{" "}
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  ))}
                {rtnflt !== undefined &&
                  rtnflt.seg !== undefined &&
                  rtnflt.seg.map((s, index) => (
                    <>
                      {index === 0 && (
                        <>
                          <div className="w-50">
                            <FlightFareOptionHeader
                              mapping={mapping}
                              sectors={sect}
                              secType={secType}
                            />
                            <div className="listItemWrap">
                              <div className="listItemHeading">
                                {context.translation && context.translation.canRei}
                              </div>
                              <ul className="listItemCharge">
                                <li>
                                  <span>
                                   {context.translation && context.translation.isttap}{" "}
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  ))}
              </>
            )}
          </>
        )}
       
      </div>
      {trip !== undefined && parseInt(trip) === 3 &&
          <FlightFareRulesTerms cur={cur} sf={sf} />
        }
    </>
  );
}

export default FlightStaticFareRules;
