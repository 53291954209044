/**
 * @ Author: Srikanth Bandaru
 * @ Create Time: 2023-06-16 12:42:57
 * @ Description: This is a common component for the filters section of all the flight trip types.
 */

import { Checkbox, Collapse, Radio, Row, Slider, Typography, Affix } from "antd";
import Link from "antd/es/typography/Link";
import React, { useEffect, useReducer, useState, useContext } from "react";
import FormatCurrency from "../../../utilities/FormatCurrency";
import AirlinesMemo from "../common/airlines/AirlinesMemo";
import AirportNames from "../common/airlines/AirportNames";
import Utilities from "../../../utilities/Utilities";
import { cloneDeep } from "lodash";
import moment from "moment";
import { Context } from "../../../../App";
import DateUtils from "../../../utilities/DateUtils";
import { isArrayNotEmpty } from "../../../utilities/validators";
const CDNURL = process.env.REACT_APP_CNT_CDN_URL

//Initial State
const initialState = {
  price: { min: 0, max: 0 },
  //stopsList: [],
  selectedLayover: "",
  refundable: undefined,
  airlines: [],
  airlinesList: [],
  airportsArr: [],
  multiAirlines: undefined,
};

//=====================================
// Reducer function for userReact Hook
// which will update the state
//=====================================
const reducer = (state, action) => {
  switch (action.type) {
    case "clear":
      return {
        ...state,
      };
    default:
      return { ...state, [action.type]: action.payload };
  }
};

function FlightFilters(props) {
  const { searchRes, searchRequest, initRes,base_usr_exrate,reset } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const [context, setContext] = useContext(Context)
  const [prcMin, setPrcMin] = useState(0);
  const [prcMax, setPrcMax] = useState(0);
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(0);
  const [minDepTime, setMinDepTime] = useState(0);
  const [maxDepTime, setMaxDepTime] = useState(24);
  const [minArrTime, setMinArrTime] = useState(0);
  const [maxArrTime, setMaxArrTime] = useState(24);
  const [minRTDepTime, setMinRTDepTime] = useState(0);
  const [maxRTDepTime, setMaxRTDepTime] = useState(24);
  const [minRTArrTime, setMinRTArrTime] = useState(0);
  const [maxRTArrTime, setMaxRTArrTime] = useState(24);
  const [selLayover, setSelLayover] = useState(null);
  const [selRefund, setSelRefund] = useState(null);
  const [stopCheckboxes, setStopCheckboxes] = useState({});
  const [airlineCheckboxes, setAirlineCheckboxes] = useState({});
  const [airportCheckboxes, setAirportCheckboxes] = useState({});
  const [multiAirline, setMultiAirline] = useState(false);
  const [stopsList, setStopsList] = useState([]);
  const [getFareActive, setGetFareActive] = useState(false);
  const [airlineFilterCount,setAirlineFilterCount] = useState(5);
  const [top, setTop] = React.useState(100);
  const [filteredResp, setFilteredResp] = useState(false);

  useEffect(() => {
    onLoad();
  }, []);

  useEffect(() => {
    if (searchRequest && searchRequest.modify) {
      clearAllFilters();
    }
  }, [searchRequest, reset]);

  const onLoad = () => {
    dispatch({ type: "initRes", payload: cloneDeep(initRes) });
    if (
      searchRes.filter !== null &&
      searchRes.filter !== undefined &&
      Object.keys(searchRes.filter).length !== 0
    ) {
      setPrcMin(searchRes.filter.minprc);
      searchRes.filter.maxprc
        ? setPrcMax(searchRes.filter.maxprc)
        : setPrcMax(searchRes.filter.minprc);
      const min = searchRes.filter.minprc;
      const max = searchRes.filter.maxprc;
      dispatch({
        type: "price",
        payload: {
          min: min.toFixed(2),
          max: max.toFixed(2) ? max.toFixed(2) : min.toFixed(2),
        },
      });
    }
    //dispatch({ type: "stopsList", payload: [] });
    dispatch({ type: "selectedLayover", payload: "" });
    dispatch({ type: "refundable", payload: undefined });
    dispatch({ type: "airlines", payload: [] });
    //dispatch({ type: "airlinesList", payload: [] });
    dispatch({ type: "airportsArr", payload: [] });
  };

  useEffect(() => {
    onLoad();
  }, [searchRes]);

  useEffect(() => {
    if (prcMin) setMinPrice(prcMin);
    if (prcMax) setMaxPrice(prcMax);
  }, [prcMin, prcMax]);

  const layover = context.selectLang == undefined || context.selectLang === "en" ? [
    { label: "6 hours", value: "360" },
    { label: "12 hours", value: "720" },
    { label: "24 hours", value: "1440" },
    { label: "More than 24 hours", value: "2000" },
  ] : [
    { label: "6 ساعات", value: "360" },
    { label: "12 ساعة", value: "720" },
    { label: "24 ساعة", value: "1440" },
    { label: "أكثر من 24 ساعة", value: "2000" },
  ];

  const refund = context.selectLang == undefined || context.selectLang === "en" ? [
    { label: "Partial Refund", value: "Refundable" },
    { label: "Non-Refundable", value: "Non-Refundable" },
  ] : [
    { label: "إسترداد جزئي", value: "Refundable" },
    { label: "غير قابل للاسترداد", value: "Non-Refundable" },
  ];

  //Calculate Service Fee
  const calcuateServiceFee = (serviceFeeList) => {
    var userType = searchRequest.butype === "ta_user" ? "TA" : "STA";
    let servicefee = 0;
    if (serviceFeeList !== undefined) {
      servicefee = Utilities.calculateServiceFee(
        serviceFeeList,
        userType,
        searchRequest
      );
      if (servicefee === "NaN") {
        return 0.0;
      } else {
        return servicefee;
      }
    } else {
      return 0.0;
    }
  };
  //Calculate Discount
  const calculateDisc = (taxes) => {
    if (taxes !== undefined) {
      for (let taxIndex = 0; taxIndex < taxes.length; taxIndex++) {
        var mak = taxes[taxIndex];
        if (
          mak !== undefined &&
          mak.dealmkp !== undefined &&
          mak.dealmkp !== 0
        ) {
          return mak.dealmkp;
        } else {
          return 0.0;
        }
      }
    } else {
      return 0.0;
    }
  };

  const clearAllFilters = () => {
    setFilteredResp(false);
    if(sessionStorage.getItem("ISRESET")!==null){
    setStopCheckboxes({});
    setStopsList([]);
    sessionStorage.setItem("FILTERRESET", true);
    sessionStorage.removeItem("CHEAPEST");
    sessionStorage.removeItem("QUICKEST");
    //clear price
    setPrcMin(initRes.filter.minprc);
    setMinPrice(initRes.filter.minprc);
    initRes.filter.maxprc
      ? setPrcMax(initRes.filter.maxprc)
      : setPrcMax(initRes.filter.minprc);
    initRes.filter.maxprc
      ? setMaxPrice(initRes.filter.maxprc)
      : setMaxPrice(initRes.filter.minprc);
    dispatch({
      type: "price",
      payload: {
        min: initRes.filter.minprc /* .toFixed(2) */,
        max: initRes.filter.maxprc
          ? initRes.filter.maxprc /* .toFixed(2) */
          : initRes.filter.minprc /* .toFixed(2) */,
      },
    });

    //clear stops
    // dispatch({ type: "stopsList", payload: [] });
    //clear layover
    dispatch({ type: "selectedLayover", payload: "" });
    setSelLayover(null);
    //clear onward departure time
    setMinDepTime(0);
    setMaxDepTime(24);
    //clear onward arrival time
    setMinArrTime(0);
    setMaxArrTime(24);
    //clear return departure time
    setMinRTDepTime(0);
    setMaxRTDepTime(24);
    //clear return arrival time
    setMinRTArrTime(0);
    setMaxRTArrTime(24);
    //clear refundable filters
    dispatch({ type: "refundable", payload: undefined });
    setSelRefund(null);
    //clear airlines
    dispatch({ type: "airlines", payload: [] });
    dispatch({ type: "airlinesList", payload: [] });
    setAirlineCheckboxes({});
    setMultiAirline(false);
    dispatch({ type: "multiAirlines", payload: false });
    setAirportCheckboxes({});
    //applyFilter();
    setAirlineFilterCount(5);
      props.handleFilterChanges(null, "clear", props?.raw_srp_data);
    }
    
  };

  const handleStops = (value, e, key) => {
    setStopCheckboxes((prevCheckboxes) => ({
      ...prevCheckboxes,
      [key]: !prevCheckboxes[key],
    }));
    if (e.target.checked) {
      stopsList.push(parseInt(value));
    } else {
      for (var i = 0; i < stopsList.length; i++) {
        if (stopsList[i] === parseInt(value)) {
          stopsList.splice(i, 1);
          i--;
        }
      }
    }
    sessionStorage.setItem("FILTERRESET", false);
    sessionStorage.removeItem("CHEAPEST");
    applyFilter();
    window.scrollTo(0, 0);
  };

  const handleLayover = (e) => {
    state.selectedLayover = parseInt(e.target.value);
    setSelLayover(e.target.value);
    applyFilter("layover");
    sessionStorage.setItem("FILTERRESET", false);
    sessionStorage.removeItem("CHEAPEST");
    window.scrollTo(0, 0);
  };

  const handleDepartureSlider = (value) => {
    if (!(minDepTime === value[0] && maxDepTime === value[1])) {
      setMinDepTime(value[0]);
      setMaxDepTime(value[1]);
      applyFilter();
      sessionStorage.setItem("FILTERRESET", false);
      sessionStorage.removeItem("CHEAPEST");
    }
  };

  const handleArrivalSlider = (value) => {
    if (!(minArrTime === value[0] && maxArrTime === value[1])) {
      setMinArrTime(value[0]);
      setMaxArrTime(value[1]);
      applyFilter('arrivalTime',value);
      sessionStorage.setItem("FILTERRESET", false);
      sessionStorage.removeItem("CHEAPEST");
    }
  };

  const handleRTDepartureSlider = (value) => {
    if (!(minRTDepTime === value[0] && maxRTDepTime === value[1])) {
      setMinRTDepTime(value[0]);
      setMaxRTDepTime(value[1]);
      applyFilter();
      sessionStorage.setItem("FILTERRESET", false);
      sessionStorage.removeItem("CHEAPEST");
    }
  };

  const handleRTArrivalSlider = (value) => {
    if (!(minRTArrTime === value[0] && maxRTArrTime === value[1])) {
      setMinRTArrTime(value[0]);
      setMaxRTArrTime(value[1]);
      applyFilter();
      sessionStorage.setItem("FILTERRESET", false);
      sessionStorage.removeItem("CHEAPEST");
    }
  };

  const layOverTimeCal = (one, two) => {
    var from = moment(new Date(one));
    var to = moment(new Date(two));
    return to.diff(from, "minutes");
  };

  const handleRefund = (e) => {
    if (e.target.value === "Refundable") {
      state.refundable = true;
      setSelRefund("Refundable");
    } else {
      state.refundable = false;
      setSelRefund("Non-Refundable");
    }
    sessionStorage.setItem("FILTERRESET", false);
    sessionStorage.removeItem("CHEAPEST");
    applyFilter();
    window.scrollTo(0, 0);
  };

  const handleAirlines = (value, e, key) => {
    setAirlineCheckboxes((prevCheckboxes) => ({
      ...prevCheckboxes,
      [key]: !prevCheckboxes[key],
    }));
    if (e.target.checked) {
      state.airlinesList.push(value.airline);
    } else {
      for (var i = 0; i < state.airlinesList.length; i++) {
        if (state.airlinesList[i] === value.airline) {
          state.airlinesList.splice(i, 1);
          i--;
        }
      }
    }
    sessionStorage.setItem("FILTERRESET", false);
    sessionStorage.removeItem("CHEAPEST");
    applyFilter();
    window.scrollTo(0, 0);
  };

  const handleAirports = (value, e, key) => {
    setAirportCheckboxes((prevCheckboxes) => ({
      ...prevCheckboxes,
      [key]: !prevCheckboxes[key],
    }));
    if (e.target.checked) {
      state.airportsArr.push(value);
    } else {
      for (var i = 0; i < state.airportsArr.length; i++) {
        if (state.airportsArr[i] === value) {
          state.airportsArr.splice(i, 1);
          i--;
        }
      }
    }
    sessionStorage.setItem("FILTERRESET", false);
    sessionStorage.removeItem("CHEAPEST");
    applyFilter();
    window.scrollTo(0, 0);
  };

  const handleMultipleAirlines = (e) => {
    if (e.target.checked !== undefined) {
      dispatch({ type: "multiAirlines", payload: e.target.checked });
      setMultiAirline(e.target.checked);
    }
    sessionStorage.setItem("FILTERRESET", false);
    sessionStorage.removeItem("CHEAPEST");
  };

  useEffect(() => {
    if (state.multiAirlines !== undefined) {
      applyFilter();
    }
  }, [state.multiAirlines]);

  const formatCurrencyForSlider = (value) => {
    return <FormatCurrency value={value * base_usr_exrate}/>;
  };

  const applyFilter = (name, e) => {
    if(filteredResp) {
      return
    }
    let cloneRes = initRes && Object.assign({}, initRes);
    //price range
    let priceChanged = false;
    sessionStorage.setItem("ISRESET",true)
    if (name === "price") {
      if (!(minPrice === e[0] && maxPrice === e[1])) {
        priceChanged = true;
        setMinPrice(e[0]);
        setMaxPrice(e[1]);
        sessionStorage.setItem("FILTERRESET", false);
        sessionStorage.removeItem("CHEAPEST");
      }
    }
    if (minPrice && maxPrice && name === "price" && priceChanged) {
      // let priceRange = { min: minPrice, max: maxPrice };
      let priceRange = { min: e[0], max: e[1] };
      let res = cloneRes.origns.filter((flights) => {
        let price = flights.pr.tf;
        if (flights.pr.tax) {
          price =
            Number(flights.pr.tf) +
            Number(calcuateServiceFee(flights.servicefee)) +
            Number(calculateDisc(flights.pr.tax));
        }
        return (
          Number(price.toFixed(2)) >= Number(priceRange.min.toFixed(2)) &&
          Number(price.toFixed(2)) <= Number(priceRange.max.toFixed(2))
        );
      });
      cloneRes.origns = res;
      priceChanged = false;
    }

    //stops
    if (stopsList.length > 0) {
      let res = [];
      cloneRes.origns.forEach((flights) => {
        let srcFiltered = flights.srcorigns.filter((src) => {
          return stopsList.includes(parseInt(src.stop));
        });
        if (srcFiltered.length > 0) {
          if (searchRes.tt == 1) {
            flights.srcorigns = srcFiltered;
            res.push(flights);
          } else if (searchRes.tt == 2) {
            const filteredSources = [];
            const filteredDestinations = [];
            flights.srcorigns.forEach(source => {
              flights.destorigins.forEach(destination => {
                if (isMatch(source, destination)) {
                  filteredSources.push(source);
                  filteredDestinations.push(destination);
                }
              });
            });

            function isMatch(source, destination) {
              if (stopsList.includes(0) && source.stop === 0 && destination.stop === 0) {
                return true;
              }
              if (stopsList.includes(1) && source.stop === 1 && destination.stop === 1) {
                return true;
              }
              if (stopsList.includes(2) && source.stop === 2 && destination.stop === 2) {
                return true;
              }
              if (stopsList.includes(1) && ((source.stop === 1 && destination.stop === 0) || (source.stop === 0 && destination.stop === 1))) {
                return true;
              }
              if (stopsList.includes(2) && ((source.stop === 2 && destination.stop === 0) || (source.stop === 0 && destination.stop === 2) || (source.stop === 1 && destination.stop === 2) || (source.stop === 2 && destination.stop === 1))) {
                return true;
              }
              return false;
              // without 1 stop - 1 stop and 2 stops - 2 stops
              // if (stopsList.includes(0) && source.stop === 0 && destination.stop === 0) {
              //   return true;
              // }
              // if (stopsList.includes(1) && ((source.stop === 1 && destination.stop === 0) || (source.stop === 0 && destination.stop === 1))) {
              //   return true;
              // }
              // if (stopsList.includes(2) && ((source.stop === 2 && destination.stop === 0) || (source.stop === 0 && destination.stop === 2) || (source.stop === 1 && destination.stop === 2) || (source.stop === 2 && destination.stop === 1))) {
              //   return true;
              // }
              // return false;
            };

            if (filteredSources.length > 0 && filteredDestinations.length > 0) {
              flights.srcorigns = filteredSources;
              flights.destorigins = filteredDestinations;
              res.push(flights);
            }
          } else if (searchRes.tt == 3) {
            const refOne = srcFiltered.filter((jour) => jour.ref === 1);
            const refTwo = srcFiltered.filter((jour) => jour.ref === 2);
            const refThree = srcFiltered.filter((jour) => jour.ref === 3);
            const refFour = srcFiltered.filter((jour) => jour.ref === 4);
            const refFive = srcFiltered.filter((jour) => jour.ref === 5);
            const refSix = srcFiltered.filter((jour) => jour.ref === 6);
            if (
              cloneRes.sec.length === 2 &&
              refOne.length > 0 &&
              refTwo.length > 0 &&
              refThree.length === 0 &&
              refFour.length === 0 &&
              refFive.length === 0 &&
              refSix.length === 0
            ) {
              flights.srcorigns = srcFiltered;
              res.push(flights);
            }
            if (
              cloneRes.sec.length === 3 &&
              refOne.length > 0 &&
              refTwo.length > 0 &&
              refThree.length > 0 &&
              refFour.length === 0 &&
              refFive.length === 0 &&
              refSix.length === 0
            ) {
              flights.srcorigns = srcFiltered;
              res.push(flights);
            }
            if (
              cloneRes.sec.length === 4 &&
              refOne.length > 0 &&
              refTwo.length > 0 &&
              refThree.length > 0 &&
              refFour.length > 0 &&
              refFive.length === 0 &&
              refSix.length === 0
            ) {
              flights.srcorigns = srcFiltered;
              res.push(flights);
            }
            if (
              cloneRes.sec.length === 5 &&
              refOne.length > 0 &&
              refTwo.length > 0 &&
              refThree.length > 0 &&
              refFour.length > 0 &&
              refFive.length > 0 &&
              refSix.length === 0
            ) {
              flights.srcorigns = srcFiltered;
              res.push(flights);
            }
            if (
              cloneRes.sec.length === 6 &&
              refOne.length > 0 &&
              refTwo.length > 0 &&
              refThree.length > 0 &&
              refFour.length > 0 &&
              refFive.length > 0 &&
              refSix.length > 0
            ) {
              flights.srcorigns = srcFiltered;
              res.push(flights);
            }
          }
        }
      });
      cloneRes.origns = res;
    }

    //layover
    if (state.selectedLayover && state.selectedLayover !== "") {
      let res = [];
      
      const isLayoverConditionMet = (layover, ad, dd) => {
        return layover > layOverTimeCal(ad, dd);
      };
    
      cloneRes.origns.forEach((flights) => {
        let srcFiltered = flights.srcorigns.filter((flt) => {
          if (state.selectedLayover === 2000) {
            return "1440" < layOverTimeCal(flt.seg[0].ad, flt.seg[flt.seg.length - 1].dd);
          } else {
            return isLayoverConditionMet(state.selectedLayover, flt.seg[0].ad, flt.seg[flt.seg.length - 1].dd);
          }
        });
    
        if (srcFiltered.length > 0) {
          let desFiltered = flights.destorigins && flights.destorigins.filter((flt) => {
            if (state.selectedLayover === 2000) {
              return "1440" < layOverTimeCal(flt.seg[0].ad, flt.seg[flt.seg.length - 1].dd);
            } else {
              return isLayoverConditionMet(state.selectedLayover, flt.seg[0].ad, flt.seg[flt.seg.length - 1].dd);
            }
          });
    
          if (srcFiltered.length > 0 && desFiltered && desFiltered.length > 0) {
            flights.srcorigns = srcFiltered;
            flights.destorigins = desFiltered;
            res.push(flights);
          } else {
            flights.srcorigns = srcFiltered;
            res.push(flights);
          }
        }
      });
    
      cloneRes.origns = res;
    }
    

    //onwarddep
    if (minDepTime !== undefined && maxDepTime !== undefined) {
      let respd = [];
      let timeRange = { min: minDepTime, max: maxDepTime };
      cloneRes.origns.forEach((flights) => {
        let remainsRef = flights.srcorigns.filter((flt) => {
          let depDate = DateUtils.convertToHours(flt.seg[0].dd);
          return depDate >= timeRange.min && depDate <= timeRange.max;
        });
        if (remainsRef.length > 0) {
          if (searchRes.tt == 3) {
            const refOne = remainsRef.filter((jour) => jour.ref === 1);
            const refTwo = remainsRef.filter((jour) => jour.ref === 2);
            const refThree = remainsRef.filter((jour) => jour.ref === 3);
            const refFour = remainsRef.filter((jour) => jour.ref === 4);
            const refFive = remainsRef.filter((jour) => jour.ref === 5);
            const refSix = remainsRef.filter((jour) => jour.ref === 6);

            if (
              cloneRes.sec.length === 2 &&
              refOne.length > 0 &&
              refTwo.length > 0 &&
              refThree.length === 0 &&
              refFour.length === 0 &&
              refFive.length === 0 &&
              refSix.length === 0
            ) {
              flights.srcorigns = remainsRef;
              respd.push(flights);
            }

            if (
              cloneRes.sec.length === 3 &&
              refOne.length > 0 &&
              refTwo.length > 0 &&
              refThree.length > 0 &&
              refFour.length === 0 &&
              refFive.length === 0 &&
              refSix.length === 0
            ) {
              flights.srcorigns = remainsRef;
              respd.push(flights);
            }
            if (
              cloneRes.sec.length === 4 &&
              refOne.length > 0 &&
              refTwo.length > 0 &&
              refThree.length > 0 &&
              refFour.length > 0 &&
              refFive.length === 0 &&
              refSix.length === 0
            ) {
              flights.srcorigns = remainsRef;
              respd.push(flights);
            }

            if (
              cloneRes.sec.length === 5 &&
              refOne.length > 0 &&
              refTwo.length > 0 &&
              refThree.length > 0 &&
              refFour.length > 0 &&
              refFive.length > 0 &&
              refSix.length === 0
            ) {
              flights.srcorigns = remainsRef;
              respd.push(flights);
            }

            if (
              cloneRes.sec.length === 6 &&
              refOne.length > 0 &&
              refTwo.length > 0 &&
              refThree.length > 0 &&
              refFour.length > 0 &&
              refFive.length > 0 &&
              refSix.length > 0
            ) {
              flights.srcorigns = remainsRef;
              respd.push(flights);
            }
          } else {
            flights.srcorigns = [];
            flights.srcorigns = remainsRef;
            respd.push(flights);
          }
        }
      });
      if (respd.length > 0) {
        cloneRes.origns = respd;
      }
    }

    //onward arrival
    if ((name === 'arrivalTime' && isArrayNotEmpty(e)) || (minArrTime !== undefined && maxArrTime !== undefined)) {
      let resoa = [];
      let timeRange = { min: isArrayNotEmpty(e)?e[0]: minArrTime, max:isArrayNotEmpty(e)?e[1]: maxArrTime };
      cloneRes.origns.forEach((flights) => {
        let remainsRef = flights.srcorigns.filter((flt) => {
          let arrDate = DateUtils.convertToHours(
            flt.seg[flt.seg.length - 1].ad
          );
          return arrDate >= timeRange.min && arrDate <= timeRange.max;
        });
        if (remainsRef.length > 0) {
          if (searchRes.tt == 3) {
            const refOne = remainsRef.filter((jour) => jour.ref === 1);
            const refTwo = remainsRef.filter((jour) => jour.ref === 2);
            const refThree = remainsRef.filter((jour) => jour.ref === 3);
            const refFour = remainsRef.filter((jour) => jour.ref === 4);
            const refFive = remainsRef.filter((jour) => jour.ref === 5);
            const refSix = remainsRef.filter((jour) => jour.ref === 6);

            if (
              cloneRes.sec.length === 2 &&
              refOne.length > 0 &&
              refTwo.length > 0 &&
              refThree.length === 0 &&
              refFour.length === 0 &&
              refFive.length === 0 &&
              refSix.length === 0
            ) {
              flights.srcorigns = remainsRef;
              resoa.push(flights);
            }
            if (
              cloneRes.sec.length === 3 &&
              refOne.length > 0 &&
              refTwo.length > 0 &&
              refThree.length > 0 &&
              refFour.length === 0 &&
              refFive.length === 0 &&
              refSix.length === 0
            ) {
              flights.srcorigns = remainsRef;
              resoa.push(flights);
            }
            if (
              cloneRes.sec.length === 4 &&
              refOne.length > 0 &&
              refTwo.length > 0 &&
              refThree.length > 0 &&
              refFour.length > 0 &&
              refFive.length === 0 &&
              refSix.length === 0
            ) {
              flights.srcorigns = remainsRef;
              resoa.push(flights);
            }

            if (
              cloneRes.sec.length === 5 &&
              refOne.length > 0 &&
              refTwo.length > 0 &&
              refThree.length > 0 &&
              refFour.length > 0 &&
              refFive.length > 0 &&
              refSix.length === 0
            ) {
              flights.srcorigns = remainsRef;
              resoa.push(flights);
            }

            if (
              cloneRes.sec.length === 6 &&
              refOne.length > 0 &&
              refTwo.length > 0 &&
              refThree.length > 0 &&
              refFour.length > 0 &&
              refFive.length > 0 &&
              refSix.length > 0
            ) {
              flights.srcorigns = remainsRef;
              resoa.push(flights);
            }
          } else {
            flights.srcorigns = [];
            flights.srcorigns = remainsRef;
            resoa.push(flights);
          }
        }
      });
      if (resoa.length > 0) {
        cloneRes.origns = resoa;
      }
    }

    //returndep
    if (minRTDepTime !== undefined && maxRTDepTime !== undefined) {
      let respd = [];
      let timeRange = { min: minRTDepTime, max: maxRTDepTime };
      cloneRes.origns.forEach((flights) => {
        let remainsRef = flights.destorigins && flights.destorigins.filter((flt) => {
          let depDate = DateUtils.convertToHours(flt.seg[0].dd);
          return depDate >= timeRange.min && depDate <= timeRange.max;
        });
        if (remainsRef && remainsRef.length > 0) {
          if (searchRes.tt == 3) {
            const refOne = remainsRef.filter((jour) => jour.ref === 1);
            const refTwo = remainsRef.filter((jour) => jour.ref === 2);
            const refThree = remainsRef.filter((jour) => jour.ref === 3);
            const refFour = remainsRef.filter((jour) => jour.ref === 4);
            const refFive = remainsRef.filter((jour) => jour.ref === 5);
            const refSix = remainsRef.filter((jour) => jour.ref === 6);

            if (
              cloneRes.sec.length === 2 &&
              refOne.length > 0 &&
              refTwo.length > 0 &&
              refThree.length === 0 &&
              refFour.length === 0 &&
              refFive.length === 0 &&
              refSix.length === 0
            ) {
              flights.destorigins = remainsRef;
              respd.push(flights);
            }

            if (
              cloneRes.sec.length === 3 &&
              refOne.length > 0 &&
              refTwo.length > 0 &&
              refThree.length > 0 &&
              refFour.length === 0 &&
              refFive.length === 0 &&
              refSix.length === 0
            ) {
              flights.destorigins = remainsRef;
              respd.push(flights);
            }
            if (
              cloneRes.sec.length === 4 &&
              refOne.length > 0 &&
              refTwo.length > 0 &&
              refThree.length > 0 &&
              refFour.length > 0 &&
              refFive.length === 0 &&
              refSix.length === 0
            ) {
              flights.destorigins = remainsRef;
              respd.push(flights);
            }

            if (
              cloneRes.sec.length === 5 &&
              refOne.length > 0 &&
              refTwo.length > 0 &&
              refThree.length > 0 &&
              refFour.length > 0 &&
              refFive.length > 0 &&
              refSix.length === 0
            ) {
              flights.destorigins = remainsRef;
              respd.push(flights);
            }

            if (
              cloneRes.sec.length === 6 &&
              refOne.length > 0 &&
              refTwo.length > 0 &&
              refThree.length > 0 &&
              refFour.length > 0 &&
              refFive.length > 0 &&
              refSix.length > 0
            ) {
              flights.destorigins = remainsRef;
              respd.push(flights);
            }
          } else {
            flights.destorigins = [];
            flights.destorigins = remainsRef;
            respd.push(flights);
          }
        }
      });
      if (respd.length > 0) {
        cloneRes.origns = respd;
      }
    }

    //return arrival
    if (minRTArrTime !== undefined && maxRTArrTime !== undefined) {
      let resoa = [];
      let timeRange = { min: minRTArrTime, max: maxRTArrTime };
      cloneRes.origns.forEach((flights) => {
        let remainsRef = flights.destorigins && flights.destorigins.filter((flt) => {
          let arrDate = DateUtils.convertToHours(
            flt.seg[flt.seg.length - 1].ad
          );
          return arrDate >= timeRange.min && arrDate <= timeRange.max;
        });
        if (remainsRef && remainsRef.length > 0) {
          if (searchRes.tt == 3) {
            const refOne = remainsRef.filter((jour) => jour.ref === 1);
            const refTwo = remainsRef.filter((jour) => jour.ref === 2);
            const refThree = remainsRef.filter((jour) => jour.ref === 3);
            const refFour = remainsRef.filter((jour) => jour.ref === 4);
            const refFive = remainsRef.filter((jour) => jour.ref === 5);
            const refSix = remainsRef.filter((jour) => jour.ref === 6);

            if (
              cloneRes.sec.length === 2 &&
              refOne.length > 0 &&
              refTwo.length > 0 &&
              refThree.length === 0 &&
              refFour.length === 0 &&
              refFive.length === 0 &&
              refSix.length === 0
            ) {
              flights.destorigins = remainsRef;
              resoa.push(flights);
            }
            if (
              cloneRes.sec.length === 3 &&
              refOne.length > 0 &&
              refTwo.length > 0 &&
              refThree.length > 0 &&
              refFour.length === 0 &&
              refFive.length === 0 &&
              refSix.length === 0
            ) {
              flights.destorigins = remainsRef;
              resoa.push(flights);
            }
            if (
              cloneRes.sec.length === 4 &&
              refOne.length > 0 &&
              refTwo.length > 0 &&
              refThree.length > 0 &&
              refFour.length > 0 &&
              refFive.length === 0 &&
              refSix.length === 0
            ) {
              flights.destorigins = remainsRef;
              resoa.push(flights);
            }

            if (
              cloneRes.sec.length === 5 &&
              refOne.length > 0 &&
              refTwo.length > 0 &&
              refThree.length > 0 &&
              refFour.length > 0 &&
              refFive.length > 0 &&
              refSix.length === 0
            ) {
              flights.destorigins = remainsRef;
              resoa.push(flights);
            }

            if (
              cloneRes.sec.length === 6 &&
              refOne.length > 0 &&
              refTwo.length > 0 &&
              refThree.length > 0 &&
              refFour.length > 0 &&
              refFive.length > 0 &&
              refSix.length > 0
            ) {
              flights.destorigins = remainsRef;
              resoa.push(flights);
            }
          } else {
            flights.destorigins = [];
            flights.destorigins = remainsRef;
            resoa.push(flights);
          }
        }
      });
      if (resoa.length > 0) {
        cloneRes.origns = resoa;
      }
    }

    //  refundable
    if (state.refundable !== undefined) {
      let refundsList = [];   
      if(cloneRes.origns.length === 0) {
        cloneRes = initRes && Object.assign({}, initRes);
      }
      cloneRes.origns.forEach((flights) => {
        const refundsSRC = flights.srcorigns.filter((flt) => {
          return state.refundable
            ? flt.re === "Refundable Before Departure"
            : flt.re === "Non-Refundable";
        });
        if (searchRes.tt == 2 && refundsSRC.length > 0) {
          const refundsARR = flights.destorigins.filter((flt) => {
            return state.refundable
              ? flt.re === "Refundable Before Departure"
              : flt.re === "";
          });
          if (refundsSRC.length > 0 && refundsARR.length > 0) {
            flights.srcorigns = refundsSRC;
            flights.destorigins = refundsARR;
            refundsList.push(flights);
          }
        }
        if (searchRes.tt == 3) {
          const refOne = refundsSRC.filter((jour) => jour.ref === 1);
          const refTwo = refundsSRC.filter((jour) => jour.ref === 2);
          const refThree = refundsSRC.filter((jour) => jour.ref === 3);
          const refFour = refundsSRC.filter((jour) => jour.ref === 4);
          const refFive = refundsSRC.filter((jour) => jour.ref === 5);
          const refSix = refundsSRC.filter((jour) => jour.ref === 6);

          if (
            cloneRes.sec.length === 2 &&
            refOne.length > 0 &&
            refTwo.length > 0 &&
            refThree.length === 0 &&
            refFour.length === 0 &&
            refFive.length === 0 &&
            refSix.length === 0
          ) {
            flights.srcorigns = [];
            flights.srcorigns = refundsSRC;
            refundsList.push(flights);
          }

          if (
            cloneRes.sec.length === 3 &&
            refOne.length > 0 &&
            refTwo.length > 0 &&
            refThree.length > 0 &&
            refFour.length === 0 &&
            refFive.length === 0 &&
            refSix.length === 0
          ) {
            flights.srcorigns = [];
            flights.srcorigns = refundsSRC;
            refundsList.push(flights);
          }
          if (
            cloneRes.sec.length === 4 &&
            refOne.length > 0 &&
            refTwo.length > 0 &&
            refThree.length > 0 &&
            refFour.length > 0 &&
            refFive.length === 0 &&
            refSix.length === 0
          ) {
            flights.srcorigns = [];
            flights.srcorigns = refundsSRC;
            refundsList.push(flights);
          }

          if (
            cloneRes.sec.length === 5 &&
            refOne.length > 0 &&
            refTwo.length > 0 &&
            refThree.length > 0 &&
            refFour.length > 0 &&
            refFive.length > 0 &&
            refSix.length === 0
          ) {
            flights.srcorigns = [];
            flights.srcorigns = refundsSRC;
            refundsList.push(flights);
          }

          if (
            cloneRes.sec.length === 6 &&
            refOne.length > 0 &&
            refTwo.length > 0 &&
            refThree.length > 0 &&
            refFour.length > 0 &&
            refFive.length > 0 &&
            refSix.length > 0
          ) {
            flights.srcorigns = [];
            flights.srcorigns = refundsSRC;
            refundsList.push(flights);
          }
        } else {
          if (refundsSRC.length > 0) {
            flights.srcorigns = refundsSRC;
            refundsList.push(flights);
          }
        }
      });
      //if refundable is available then assign the filtered response to cloneRes
      if (refundsList.length > 0) {
        cloneRes.origns = refundsList;
      } else {
        cloneRes.origns = refundsList;
        cloneRes.pr = {};
      }
      let unique_city = [];
      cloneRes.origns.forEach((c) => {
        if (!unique_city.includes(c)) {
          unique_city.push(c);
        }
      });
      cloneRes.origns = unique_city;
    }

    //airline
    if (state.airlinesList.length > 0) {
      let newList =
        cloneRes.origns &&
        cloneRes.origns.filter((flt) => {
          if (flt.airlineList && !flt.airlineList.includes("_")) {
            return state.airlinesList.length > 0
              ? state.airlinesList.includes(flt.airlineList)
              : "";
          } else if (
            flt.airlineList &&
            flt.airlineList.includes("_") &&
            flt.airlineList.split("_")[0] === flt.airlineList.split("_")[1]
          ) {
            return state.airlinesList.length > 0
              ? state.airlinesList.includes(flt.airlineList.split("_")[0])
              : "";
          }
        });
      cloneRes.origns = [];
      cloneRes.origns = newList;
    }
    //multiple airline
    if (state.multiAirlines) {
      let sourceList = [];
      let destinationList = [];
      let originsList = [];
      cloneRes.origns &&
        cloneRes.origns.forEach((flights) => {
          sourceList = flights.srcorigns.filter((flt) => {
            return flt.seg.filter((abc) => abc.ma !== flt.seg[0].ma).length > 0;
          });
          if (flights.destorigins && flights.destorigins.length > 0)
            destinationList = flights.destorigins.filter((flt) => {
              return (
                flt.seg.filter((abc) => abc.ma !== flt.seg[0].ma).length > 0
              );
            });
          if (sourceList.length > 0) {
            flights.srcorigns = [];
            flights.srcorigns = sourceList;
          }
          if (destinationList.length > 0) {
            flights.destorigins = [];
            flights.destorigins = destinationList;
          }
          if (sourceList.length > 0 || destinationList.length > 0) {
            originsList.push(flights);
          }
        });
      cloneRes.origns = [];
      cloneRes.origns = originsList;
      window.scrollTo(0, 0);
    }

    //nearby airport arrival
    if (state.airportsArr.length > 0) {
      let sourceList = [];
      let destinationList = [];
      let originsList = [];
      if (state.airportsArr.length > 0) {
        cloneRes.origns.forEach((flights) => {
          sourceList = flights.srcorigns.filter((flt) => {
            return (
              flt.seg &&
              (state.airportsArr.includes(flt.seg[0].ar) ||
                state.airportsArr.includes(flt.seg[flt.seg.length - 1].ar))
            );
          });
          if (searchRes.tt == 2) {
            destinationList = flights.destorigins.filter((flt) => {
              return (
                flt.seg &&
                (state.airports.includes(flt.seg[0].ar) ||
                  state.airports.includes(flt.seg[flt.seg.length - 1].da))
              );
            });
            if (sourceList.length > 0 && destinationList.length > 0) {
              flights.srcorigns = [];
              flights.srcorigns = sourceList;
              flights.destorigins = [];
              flights.destorigins = destinationList;
              originsList.push(flights);
            }
          } else if (searchRes.tt == 3) {
            const refOne = sourceList.filter((jour) => jour.ref === 1);
            const refTwo = sourceList.filter((jour) => jour.ref === 2);
            const refThree = sourceList.filter((jour) => jour.ref === 3);
            const refFour = sourceList.filter((jour) => jour.ref === 4);
            const refFive = sourceList.filter((jour) => jour.ref === 5);
            const refSix = sourceList.filter((jour) => jour.ref === 6);

            if (
              cloneRes.sec.length === 2 &&
              refOne.length > 0 &&
              refTwo.length > 0 &&
              refThree.length === 0 &&
              refFour.length === 0 &&
              refFive.length === 0 &&
              refSix.length === 0
            ) {
              flights.srcorigns = sourceList;
              originsList.push(flights);
            }

            if (
              cloneRes.sec.length === 3 &&
              refOne.length > 0 &&
              refTwo.length > 0 &&
              refThree.length > 0 &&
              refFour.length === 0 &&
              refFive.length === 0 &&
              refSix.length === 0
            ) {
              flights.srcorigns = sourceList;
              originsList.push(flights);
            }
            if (
              cloneRes.sec.length === 4 &&
              refOne.length > 0 &&
              refTwo.length > 0 &&
              refThree.length > 0 &&
              refFour.length > 0 &&
              refFive.length === 0 &&
              refSix.length === 0
            ) {
              flights.srcorigns = sourceList;
              originsList.push(flights);
            }

            if (
              cloneRes.sec.length === 5 &&
              refOne.length > 0 &&
              refTwo.length > 0 &&
              refThree.length > 0 &&
              refFour.length > 0 &&
              refFive.length > 0 &&
              refSix.length === 0
            ) {
              flights.srcorigns = sourceList;
              originsList.push(flights);
            }

            if (
              cloneRes.sec.length === 6 &&
              refOne.length > 0 &&
              refTwo.length > 0 &&
              refThree.length > 0 &&
              refFour.length > 0 &&
              refFive.length > 0 &&
              refSix.length > 0
            ) {
              flights.srcorigns = sourceList;
              originsList.push(flights);
            }
          } else {
            if (sourceList.length > 0) {
              flights.srcorigns = [];
              flights.srcorigns = sourceList;
              originsList.push(flights);
            }
          }
        });
        cloneRes.origns = [];
        cloneRes.origns = originsList;
      }
    }
    if(cloneRes.origns.length === 0) {
      setFilteredResp(true);
    }
    props.handleFilterChanges(cloneRes, "filter");
  };

  const { Title } = Typography;
const handleGetFareSummary = () =>{
  props.toggle(!props.toggle);
  setGetFareActive(!getFareActive)
}

 const [scrollTwo, setScrollTwo] = useState(false);
 useEffect(() => {
   window.addEventListener("scroll", () => {
     setScrollTwo(window.scrollY > 350);
   });
 }, []);
 const [scrollThree, setScrollThree] = useState(false);
 useEffect(() => {
   window.addEventListener("scroll", () => {
     setScrollThree(window.scrollY > 420);
   });
 }, []);
  return (
    <Affix offsetTop={top} className="leftSideFilter">
    <div className="filterWrapper"> 
      <div className="cardWithShadow">
        {(searchRequest.tt !== 3 && searchRequest.tt !== "3") && (
          <Title onClick={handleGetFareSummary} level={5} className={getFareActive ? 'open' : '' }>
            <i className="icon icon-Fare-Summary"></i> 
            {context.translation && context.translation.getFarSummy} 
          </Title>
        )}
        {/* <Title level={5}>
          <i className="icon icon-Calendar"></i> Get Pricing calendar
        </Title> */}
      </div>
      <div className={"filterYourResult " + (searchRequest.tt !== 3 && searchRequest.tt !== "3" ? "marginTop30" : "")}>
        <div className="commonFlex">
          <div>
            <Title level={4}>{context.translation && context.translation.filterResult}</Title>
          </div>
          <div className="commonFlex">
            {" "}
            <Link onClick={clearAllFilters}>
              <i className="icon icon-Reset"></i> {context.translation && context.translation.reset}
            </Link>
          </div>
        </div>
        <span>
          {searchRes.origns.length} {context.translation && context.translation.of} {initRes.origns.length} {context.translation && context.translation.flights}
        </span>
      </div>
      <Collapse
        size="small"
        defaultActiveKey={['1', '2', '3', '4', '5', '6', '7', '8']}
        items={[
          {
          key: '1',
          label: context.translation && context.translation.price,
          className: "marginTop30 rangeSliders",
          children: <>
            <Slider
              range
              min={prcMin}
              max={prcMax}
              value={[minPrice, maxPrice]}
              onChange={(e) => applyFilter("price", e)}
              tooltip={{formatter:formatCurrencyForSlider}}
            />
            <div className="commonFlex price">
              <div>
                <span className="currencyCode">{searchRequest.cur}</span>{" "}
                <span className="currencyAmount">
                  <FormatCurrency
                    value={minPrice * base_usr_exrate}
                    className="currencyAmount"
                  />
                </span>
              </div>
              <div>
                <span className="currencyCode">{searchRequest.cur}</span>{" "}
                <span className="currencyAmount">
                  <FormatCurrency
                    value={maxPrice * base_usr_exrate}
                    className="currencyAmount"
                  />
                </span>
              </div>
            </div>
          </>,
          },
          {
            key: '2',
            label: context.translation && context.translation.stops,
            className: "marginTop30 custumCheckBox",
            children: <>
            <div className="stops">
              {searchRes.filter &&
                searchRes.filter.stps !== undefined &&
                searchRes.filter.stps.map((stp, index) => (
                  <li key={index}>
                    <Row className="commonFlex">
                      <div>
                        <Checkbox
                          checked={stopCheckboxes[index]}
                          onChange={(e) => handleStops(stp.count, e, index)}
                        >
                          {stp.count === 0 ? context.translation && context.translation.noStop : stp.count + " "+ (context.translation && context.translation.stop)}
                        </Checkbox>
                      </div>
                      <div className="amount">
                        <span className="currencyCode">
                          {searchRequest.cur + " "}
                        </span>
                        {
                          <FormatCurrency
                            value={stp.prc * base_usr_exrate}
                            className="currencyAmount"
                          />
                        }
                      </div>
                    </Row>
                  </li>
                ))}
            </div>
          </>,
          },
          {
            key: '3',
            label: context.translation && context.translation.layover,
            className: "marginTop30 custumRadio",
            children: <>
            <Radio.Group
              style={{ display: "flex", flexDirection: "column" }}
              options={layover}
              onChange={handleLayover}
              value={selLayover}
            />
          </>,
          },
          {
            key: '4',
            label: context.translation && context.translation.flightTime,
            className: "marginTop30 flightTimings rangeSliders",
            children: <>
            <Title level={5}>{context.translation && context.translation.onwdDptTim}</Title>
            <Slider
              min={0}
              max={24}
              range
              value={[minDepTime, maxDepTime]}
              onChange={handleDepartureSlider}
              className={scrollTwo ? "slideTwo active" : "slideTwo"}
            />
            <div className="commonFlex">
              <span>{minDepTime} {context.translation && context.translation.hrs}</span>
              <span>{maxDepTime} {context.translation && context.translation.hrs}</span>
            </div>
            <Title level={5} className="mt">
            {context.translation && context.translation.onwdArrTim}
            </Title>
            <Slider
              min={0}
              max={24}
              range
              value={[minArrTime, maxArrTime]}
              onChange={handleArrivalSlider}
              className={scrollThree ? "slideThree active" : "slideThree"}
            />
            <div className="commonFlex">
              <span>{minArrTime} {context.translation && context.translation.hrs}</span>
              <span>{maxArrTime} {context.translation && context.translation.hrs}</span>
            </div>

            {searchRequest.tt === 2 && (
              <>
                <Title level={5} className="mt">{context.translation && context.translation.rtnDptTim}</Title>
                <Slider
                  min={0}
                  max={24}
                  range
                  value={[minRTDepTime, maxRTDepTime]}
                  onChange={handleRTDepartureSlider}
                />
                <div className="commonFlex">
                  <span>{minRTDepTime} {context.translation && context.translation.hrs}</span>
                  <span>{maxRTDepTime} {context.translation && context.translation.hrs}</span>
                </div>
                <Title level={5} className="mt">
                  {context.translation && context.translation.rtnArrTim}
                </Title>
                <Slider
                  min={0}
                  max={24}
                  range
                  value={[minRTArrTime, maxRTArrTime]}
                  onChange={handleRTArrivalSlider}
                />
                <div className="commonFlex">
                  <span>{minRTArrTime} {context.translation && context.translation.hrs}</span>
                  <span>{maxRTArrTime} {context.translation && context.translation.hrs}</span>
                </div>
              </>
            )}
          </>,
          },
          {
            key: '5',
            label: context.translation && context.translation.farePolicy,
            className: "marginTop30 custumRadio",
            children: <>
            <Radio.Group
              style={{ display: "flex", flexDirection: "column" }}
              options={refund}
              onChange={handleRefund}
              value={selRefund}
            />
          </>,
          },
          {
            key: '6',
            label: context.translation && context.translation.airline,
            className: "marginTop30 custumCheckBox",
            children: <>
              {searchRes.filter &&
                searchRes.filter.airl !== undefined &&
                searchRes.filter.airl.map((air, index) => (
                  <li key={index}>
                    {index<airlineFilterCount &&
                    <Row>
                      <Checkbox
                        checked={airlineCheckboxes[index]}
                        onChange={(e) => handleAirlines(air, e, index)}
                      >
                        <img
                          src={
                            CDNURL+"/airlines/1x/airline-logos/" +
                            air.airline +
                            ".png"
                          }
                          alt="AirLine"
                          className="airlineImg"
                        />
                        <AirlinesMemo code={air.airline} />
                      </Checkbox> 
                    </Row>
                    }
                  </li>
                ))}
                
                {searchRes.filter.airl.length > 5 &&
                <div className="showMore">
                  {searchRes.filter && searchRes.filter.airl.length > airlineFilterCount ? (
                    <Link variant="link" onClick={() => setAirlineFilterCount(prevCount => prevCount + 5)} className="showAllLess">
                      {context.translation && context.translation.showAll}
                    </Link>
                  ) : (
                    <Link variant="link" onClick={() => setAirlineFilterCount(5)} className="showAllLess">
                    {context.translation && context.translation.showles}
                  </Link>
                  )}
              </div>
            }
            </>,
          },
          {
            key: '7',
            label: context.translation && context.translation.mulAirlines,
            className: "marginTop30 custumCheckBox",
            children: <>
              <Checkbox
                checked={multiAirline}
                onChange={(e) => handleMultipleAirlines(e)}
              >
                <img
                  src={
                    CDNURL+"/airlines/1x/airline-logos/multipleAirline.png"
                  }
                  alt="AirLine"
                  className="multipleAirlineImg"
                />
                <span> {context.translation && context.translation.mulAirlines}</span>
              </Checkbox>
            </>,
          }
        ]}
        >
      </Collapse>

      {searchRes.filter &&
        searchRes.filter.nerbyarr &&
        searchRes.filter.nerbyarr.length > 0 && (
          <Collapse
            size="small"
            defaultActiveKey={["9"]}
            className="marginTop30 custumCheckBox"
            items={[
              {
                key: '9',
                label: "Nearby Airports Arrival",
                className: "marginTop30 custumCheckBox",
                children: <>
                {searchRes.filter &&
                  searchRes.filter.nerbyarr.map((airport, index) => (
                    <li key={index}>
                      <Row>
  
                      
                    <Checkbox
                      checked={airportCheckboxes[index]}
                      onChange={(e) => handleAirports(airport, e, index)}
                    >
                      <AirportNames
                        code={airport}
                        mapping={searchRes.mappings}
                        type={"filters"}
                      />
                    </Checkbox>
                    </Row>
                    </li>
                  ))}
              </>,
            }]}
          >            
          </Collapse>
        )}
    </div>
    </Affix>
  );
}

export default FlightFilters;
