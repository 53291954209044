import React, { useContext } from "react";
import { Row, Col, Typography } from 'antd';
import { Context } from "../../App";
const { Title } = Typography;


function SecureGuarantee() {
  const [context, setContext] = useContext(Context);
  return (
    <>
    <div className="secure-section-wrap">
      <div className="container">
        <Row className="secure-section">
          <Col span={8} md={9} sm={8} className="ss-block">
            <i className="fs"></i>
            <Title level={5}>
              {/* Fast & Secure */}
              {context.translation && context.translation.secureTlt}
            </Title>
            <p>
              {/* We offer fast & secure verification services all over Qatar. */}
              {context.translation && context.translation.secureDesp}
            </p>
          </Col>
          <Col span={8} xl={6} md={7} sm={8} className="ss-block">
            <i className="cs"></i>
            <Title level={5}>
              {/* Customer Support */}
              {context.translation && context.translation.supportTlt}
            </Title>
            <p>
              {/* 24x7 Best Customer Support Team. */}
              {context.translation && context.translation.supportDesp}
            </p>
          </Col>
          <Col span={8} xl={7} md={7} sm={8} className="ss-block">
            <i className="bpg"></i>
            <Title level={5}>
              {/* Best Price Guarantee */}
              {context.translation && context.translation.prcGuaTlt}
            </Title>
            <p>
              {/* We deliver our products which are reasonably priced */}
              {context.translation && context.translation.prcGuaDesp}
            </p>
          </Col>
        </Row>
      </div>
    </div>
    </>
  )
}

export default SecureGuarantee;