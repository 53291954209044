/**
 * @ Author: Srikanth Bandaru
 * @ Create Time: 2023-06-16 20:57:46
 * @ Description: This is for add travellers in profile section
 */

import React, { useState, useEffect, useReducer, useContext } from "react";
import Header from "../common/Header";
import Sidebar from "./Sidebar";
import Footer from "../common/Footer";
import { Context } from "../../App";
import { useNavigate } from "react-router-dom";
import {
  Col,
  Form,
  Input,
  Button,
  Row,
  Select,
  Typography,
  Alert,
  Table,
  Space,
  Modal,
  notification,
} from "antd";
import {
  deleteFrequentFlyer,
  deleteTravellers,
  getTravellers,
  saveTravellers,
  updateTravellers,
} from "./operations";
import Utilities from "../utilities/Utilities";
let months = require("../bookingflow/flights/common/masterData/Months.json");
let monthsArabic =require("../bookingflow/flights/common/masterData/MonthsArabic.json");

const { Title } = Typography;
const { Option } = Select;
let countrieslist = require("../../components/bookingflow/flights/common/masterData/CountriesList.json");
let airlineMaster = require("../../components/bookingflow/flights/common/masterData/AirlinesMasterData.json");

const initialState = {
  id: 0,
  res: [],
  showSave: true,  
  notiMessage: "",
  notiMessageShow: false,
  notiVarient: "",
  saveTravellers: true,
  addTravellers: false,
  enableUpdate: false,
  error: false,
  title: "",
  firstName: "",
  lastName: "",
  dateOfBirth: {
    month: "",
    day: "",
    year: "",
  },
  nationality: "",
  travelDocuments: [
    {
      doc_type: "",
      passport_no: "",
      issue_cnt: "",
      issue_date: {
        month: "",
        day: "",
        year: "",
      },
      exp_date: {
        month: "",
        day: "",
        year: "",
      },
    },
  ],
  frequentFlyerData: [
    {
      airline_name: "",
      ffp: "",
      ffn: "",
    },
  ],
};

const reducer = (state, action) => {
  switch (action.type) {
    case "UPDATE_FIELD":
      return {
        ...state,
        [action.field]: action.value,
      };
    case "UPDATE_DATE_FIELD":
      const { objectName, field, value } = action;
      return {
        ...state,
        [objectName]: {
          ...state[objectName],
          [field]: value,
        },
      };
    case "UPDATE_NESTED_FIELD":
      return {
        ...state,
        [action.field]: {
          ...state[action.field],
          [action.subfield]: action.value,
        },
      };
    // add travel document
    case "ADD_DOCUMENT_ROW":
      return {
        ...state,
        travelDocuments: [
          ...state.travelDocuments,
          {
            doc_type: "",
            passport_no: "",
            issue_cnt: "",
            issue_date: { month: "", day: "", year: "" },
          },
        ],
      };

    // removing/deleting travel document
    // case "REMOVE_DOCUMENT_ROW":
    //   return {
    //     ...state,
    //     travelDocuments: state.travelDocuments.filter(
    //       (_, index) => index !== action.index
    //     )
    //   };

    case "UPDATE_TRAVEL_DOCUMENT_FIELD":
      return {
        ...state,
        travelDocuments: state.travelDocuments.map((data, index) => {
          if (index === action.index) {
            return {
              ...data,
              [action.field]: action.value,
            };
          }
          return data;
        }),
      };

    case "UPDATE_TRAVEL_DOCUMENT_FIELD_NESTED":
      return {
        ...state,
        travelDocuments: state.travelDocuments.map((data, index) => {
          if (index === action.index) {
            return {
              ...data,
              [action.field]: {
                ...data[action.field],
                [action.subField]: action.value,
              },
            };
          }
          return data;
        }),
      };

    case "UPDATE_DATE_FIELD_NESTED":
      return {
        ...state,
        travelDocuments: state.travelDocuments.map((data, index) => {
          if (index === action.index) {
            return {
              ...data,
              [action.field]: {
                ...data[action.field],
                [action.subfield]: action.value,
              },
            };
          }
          return data;
        }),
      };
    case "UPDATE_ISSUING_COUNTRY":
      return {
        ...state,
        travelDocuments: state.travelDocuments.map((data, index) => {
          if (index === action.index) {
            return {
              ...data,
              [action.field]: action.value,
            };
          }
          return data;
        }),
      };
    case "UPDATE_AIRLINES":
      return {
        ...state,
        frequentFlyerData: state.frequentFlyerData.map((data, index) => {
          if (index === action.index) {
            return {
              ...data,
              [action.field]: action.value,
            };
          }
          return data;
        }),
      };
    case "UPDATE_FREQUENT_FLYER_FIELD":
      const frequentFlyerData = [...state.frequentFlyerData];
      frequentFlyerData[action.index] = {
        ...frequentFlyerData[action.index],
        [action.field]: action.value,
      };
      return {
        ...state,
        ...state.formData,
        frequentFlyerData,
      };
    case "ADD_ROW":
      return {
        ...state,
        ...state,
        frequentFlyerData: [...state.frequentFlyerData, {}],
      };
    case "REMOVE_ROW":
      return {
        ...state,
        ...state,
        frequentFlyerData: state.frequentFlyerData.filter(
          (_, index) => index !== action.index
        ),
      };
    case "editTraveller":
      return Object.assign(state, action.payload);
    default:
      return { ...state, [action.type]: action.payload };
  }
};

const AddTravellers = () => {
  const [context, setContext] = useContext(Context);
  const navigate = useNavigate();
  const [notiMessage, setNotiMessage] = useState("");
  const [notiMessageShow, setNotiMessageShow] = useState(false);
  const [notiVarient, setNotiVarient] = useState();
  const [alert, setAlert] = useState(false);
  const [resp, setResp] = useState([]);
  const [deleteTraveller, setDeleteTraveller] = useState(false);
  const [userUniqId, setUserUniqId] = useState("");
  const [loadTraveller, setLoadTraveller] = useState(false);
  const [loadData, setLoadData] = useState(false);

  const [api, contextHolder] = notification.useNotification();

  const data = JSON.parse(localStorage.getItem("SIGNINRESPONSE"));
  useEffect(
    () => {
      if (data !== null) {
        setUserUniqId(data.res.usr_uniq_id);
        const payload = {
          email: data.res.email
        };
        let tokenKey = Utilities.generateAPIToken(payload);
        getTravellers(payload,tokenKey).then((response) => {
          if (response.suc) {
            loadResponse(response.res);
            setResp(response.res);
          } else {
            dispatch({ type: "res", payload: [] });
            setResp([]);
          }
        }).catch(function (error) {
          console.error("Error:" + error);      
        });
      }
    },
    [resp.length, loadTraveller, loadData]
  );

  const hidemessage = () => {
    setTimeout(function () {
      dispatch({ type: "notiMessageShow", payload: false });
      dispatch({ type: "notiMessage", payload: "" });
      dispatch({ type: "notiVarient", payload: "" });
    }, 3000);
  };

  const loadResponse = (response) => {
    let data = [];
    if (response !== undefined) {
      for (let val of response) {
        if(val.is_profile_lead === 0) {
          data.push({
            fullName: val.fn + " " + val.ln,
            dob: val.dob,
            options: (
              <>
                <span className="tbAction">
                  <Button
                    type="secondary"
                    className="editBtn"
                    ghost
                    onClick={editTraveller(val, "edit")}
                  >
                    <i className="icon icon-Edit"></i> {context.translation && context.translation.edit}
                  </Button>
                  <Button
                    type="primary"
                    className="deleteBtn"
                    ghost
                    onClick={editTraveller(val, "del")}
                  >
                    <i className="icon icon-Delete"></i>
                  </Button>
                </span>
              </>
            ),
          });
        }
      }
      if (response.length !== 0) {
        dispatch({ type: "res", payload: data });
      }
    }
  };

  const editTraveller = (obj, type) => () => {
    setLoadTraveller(true);
    dispatch({ type: "editTraveller", payload: obj });
    dispatch({ type: "enableUpdate", payload: true });
    if (type === "edit") {
      dispatch({ type: "id", payload: obj.id });
      dispatch({ type: "title", payload: obj.title });
      dispatch({ type: "firstName", payload: obj.fn });
      dispatch({ type: "lastName", payload: obj.ln });
      dispatch({ type: "dateOfBirth", payload: {
        month: obj.dob?.slice(0, 2),
        day: obj.dob?.slice(3, 5),
        year: obj.dob?.slice(6, 10),
      } });
      // state.dateOfBirth.month = obj.dob?.slice(0, 2);
      // state.dateOfBirth.day = obj.dob?.slice(3, 5);
      // state.dateOfBirth.year = obj.dob?.slice(6, 10);
      dispatch({ type: "nationality", payload: obj.na });
      if (obj.docs.length > 0) {
        // state.travelDocuments[0].id = obj.docs[0].id !== undefined && obj.docs[0].id;
        dispatch({ type: "travelDocuments", payload: [
          {
            id: obj.docs[0]?.id !== undefined && obj.docs[0]?.id,
            doc_type: "",
            passport_no: obj.docs[0].passport_no,
            issue_cnt: obj.docs[0].isu_cnt,
            issue_date: {
              month: obj.docs[0].isu_dt?.slice(
                0,
                2
              ),
              day: obj.docs[0].isu_dt?.slice(3, 5),
              year: obj.docs[0].isu_dt?.slice(
                6,
                10
              ),
            },
            exp_date: {
              month: obj.docs[0].exp_dt?.slice(0, 2),
              day: obj.docs[0].exp_dt?.slice(3, 5),
              year: obj.docs[0].exp_dt?.slice(6, 10),
            },
          },
        ]});
        // state.travelDocuments[0].passport_no = obj.docs[0].passport_no;
        // state.travelDocuments[0].issue_cnt = obj.docs[0].isu_cnt;
        // if(obj.docs[0].isu_dt !== "" && obj.docs[0].isu_dt !== null) {
        //   state.travelDocuments[0].issue_date.month = obj.docs[0].isu_dt?.slice(
        //     0,
        //     2
        //   );
        //   state.travelDocuments[0].issue_date.day = obj.docs[0].isu_dt?.slice(3, 5);
        //   state.travelDocuments[0].issue_date.year = obj.docs[0].isu_dt?.slice(
        //     6,
        //     10
        //   );
        // }
        // if(obj.docs[0].exp_dt !== "" && obj.docs[0].exp_dt !== null) {
        //   state.travelDocuments[0].exp_date.month = obj.docs[0].exp_dt?.slice(0, 2);
        //   state.travelDocuments[0].exp_date.day = obj.docs[0].exp_dt?.slice(3, 5);
        //   state.travelDocuments[0].exp_date.year = obj.docs[0].exp_dt?.slice(6, 10);
        // }
      }
      // state.frequentFlyerData[0].airline_name = obj.frq_fly[0].airline_name;
      // state.frequentFlyerData[0].ffp = obj.frq_fly[0].ffp;
      // state.frequentFlyerData[0].ffn = obj.frq_fly[0].ffn;

      // if (response.res[0].docs.length > 0) {
      //   dispatch({
      //     type: "travelDocuments",
      //     payload: response.res[0].docs,
      //   });
      // }
      if (obj.frq_fly.length > 0) {
        dispatch({
          type: "frequentFlyerData",
          payload: obj.frq_fly,
        });
      }
      dispatch({ type: "saveTravellers", payload: false });
      dispatch({ type: "addTravellers", payload: true });
    }
    if (type === "del") {
      handleDeleteConfirmation(obj);
    }
  };

  const handleDelete = (obj) => {
    deleteTravellers(obj.id).then((response) => {
      if (response) {
        setDeleteTraveller(true);
        // window.location.reload();
        setLoadData(!loadData);
        dispatch({ type: "notiMessageShow", payload: true });
        dispatch({ type: "notiMessage", payload: context.translation && context.translation.travDeletesucc });
        dispatch({ type: "notiVarient", payload: "success" });
        hidemessage();
      }
    }).catch(function (error) {
      console.error("Error:" + error);      
    });
  };

  const handleCancel = () => {
    // window.location = "/";
  };

  const handleCancelTraveller = () => {
    setLoadTraveller(false);
    window.scrollTo({ top: 0, behavior: "smooth" });
    dispatch({ type: "error", payload: false });
    dispatch({ type: "firstName", payload: "" });
    dispatch({ type: "lastName", payload: "" });
    dispatch({
      type: "dateOfBirth",
      payload: {
        month: "",
        day: "",
        year: "",
      },
    });
    dispatch({ type: "nationality", payload: "" });
    dispatch({
      type: "travelDocuments",
      payload: [
        {
          doc_type: "",
          passport_no: "",
          issue_cnt: "",
          issue_date: {
            month: "",
            day: "",
            year: "",
          },
          exp_date: {
            month: "",
            day: "",
            year: "",
          },
        },
      ],
    });
    dispatch({
      type: "frequentFlyerData",
      payload: [
        {
          airline_name: "",
          ffp: "",
          ffn: "",
        },
      ],
    });
    dispatch({ type: "addTravellers", payload: false });
    dispatch({ type: "saveTravellers", payload: true });
  };

  const handleDeleteConfirmation = (obj) => {
    Modal.confirm({
      title: (context.translation && context.translation.uWantdel),
      okText: (context.translation && context.translation.yes),
      cancelText: (context.translation && context.translation.no),
      onOk: () => {
        handleDelete(obj);
      },
      onCancel: () => {
        handleCancel();
      },
    });
  };

  const handleUpdateTravellers = (e) => {
    e.preventDefault();
    // window.scrollTo(0, 0);
    const dob =
      state.dateOfBirth.month +
      "-" +
      state.dateOfBirth.day +
      "-" +
      state.dateOfBirth.year;

      let dateString = "";
      if(state.travelDocuments[0].issue_date.month && state.travelDocuments[0].issue_date.day && state.travelDocuments[0].issue_date.year) {      
        const { month, day, year } = state.travelDocuments[0].issue_date;
        dateString = `${month}-${day}-${year}`;
      }

      let dateString1 = "";
      if(state.travelDocuments[0].exp_date.month && state.travelDocuments[0].exp_date.day && state.travelDocuments[0].exp_date.year) {
        const {
          month: expiryMonth,
          day: expiryDay,
          year: expiryYear,
        } = state.travelDocuments[0].exp_date;
        dateString1 = `${expiryMonth}-${expiryDay}-${expiryYear}`;
      }
    
    state.travelDocuments[0].issue_date = dateString;
    state.travelDocuments[0].exp_date = dateString1;
    const frq_fly_data = state.frequentFlyerData.filter(
      (obj) => obj.airline_name && obj.ffn && obj.ffp
    );
    if (validateAddTravellers()) {
      const payload = {
        id: state.id,
        usr_uniq_id: userUniqId,
        title: state.title,
        fn: state.firstName,
        ln: state.lastName,
        dob: dob,
        na: state.nationality,
        docs: state.travelDocuments,
        frq_fly: frq_fly_data,
      };
      let tokenKey = Utilities.generateAPIToken(payload);
      updateTravellers(payload,tokenKey).then((response) => {
        if (response.suc) {
          setAlert(true);
          // window.scrollTo(0, 0);
          window.scrollTo({ top: 0, behavior: "smooth" });
          setNotiMessageShow(true);
          setNotiMessage(context.translation && context.translation.travUpsucc);
          setNotiVarient("success");
          dispatch({ type: "notiMessageShow", payload: true });
          dispatch({ type: "notiMessage", payload: context.translation && context.translation.travUpsucc });
          dispatch({ type: "notiVarient", payload: "success" });
          hidemessage();
          setTimeout(function () {
            setAlert(false);
            dispatch({ type: "addTravellers", payload: false });
            dispatch({ type: "saveTravellers", payload: true });
            // window.location.reload();
            window.location = "/my-account/add-travellers";
            // setLoadData(!loadData);
          }, 1000);
        } else {
          setAlert(true);
          setNotiMessageShow(true);
          setNotiMessage(context.translation && context.translation.travNotup);
          setNotiVarient("error");
          dispatch({ type: "notiMessageShow", payload: true });
          dispatch({ type: "notiMessage", payload: context.translation && context.translation.travNotup });
          dispatch({ type: "notiVarient", payload: "error" });
          hidemessage();
          hidemessage();
        }
      }).catch(function (error) {
        console.error("Error:" + error);      
      });
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);
  const { formData } = state;

  const handleChange = (e) => {
    const { name, value } = e.target;
    const [field, subfield] = name?.split(".");

    if (subfield) {
      dispatch({ type: "UPDATE_NESTED_FIELD", field, subfield, value });
    } else {
      dispatch({ type: "UPDATE_FIELD", field, value });
    }
  };

  const handleDropdownChange = (e) => {
    const { name, value } = e.target;
    dispatch({ type: "UPDATE_FIELD", field: name, value });
  };

  const handleDateFieldChange = (objectName, field, value) => {
    dispatch({ type: "UPDATE_DATE_FIELD", objectName, field, value });
  };

  const handleIssuingDateFieldChange = (index, field, subfield, value) => {
    dispatch({
      type: "UPDATE_DATE_FIELD_NESTED",
      index,
      field,
      subfield,
      value,
    });
  };

  const handleCountryFieldChange = (index, field, value) => {
    dispatch({
      type: "UPDATE_ISSUING_COUNTRY",
      index,
      field,
      value,
    });
  };

  const handleAirlineLines = (index, field, value) => {
    dispatch({
      type: "UPDATE_AIRLINES",
      index,
      field,
      value,
    });
  };

  const handleChangeNationality = (value) => {
    dispatch({ type: "nationality", payload: value });
  };

  const currentYear = new Date().getFullYear();
  const yearsRange = 80;
  const years = Array.from(
    { length: yearsRange },
    (_, index) => currentYear - index
  );
  const expiryYears = Array.from(
    { length: yearsRange },
    (_, index) => currentYear + index
  );

  const getDaysInMonth = (month, year) => {
    const date = new Date(year, month, 0);
    return date.getDate();
  };

  const handleAddRow = () => {
    dispatch({ type: "ADD_ROW" });
  };

  const handleRemoveFFRow = (index, id) => {
    if(id === undefined) {        
      dispatch({ type: "REMOVE_ROW", index });
    } else {
      handleFFDeleteConfirmation(index, id)
    }
  };

  const handleFFDeleteConfirmation = (index, id) => {
    Modal.confirm({
      title: (context.translation && context.translation.uWantdel),
      okText: (context.translation && context.translation.yes),
      cancelText: (context.translation && context.translation.no),
      onOk: () => {
        deleteFF(index, id);
      },
      onCancel: () => {
        handleFFCancel();
      },
    });
  };  

  const deleteFF = (index, id) => {
    deleteFrequentFlyer(id).then((response) => {
      if (response) {
        dispatch({ type: "notiMessageShow", payload: true });
        dispatch({ type: "notiMessage", payload: (context.translation && context.translation.ffdelSucc) });
        dispatch({ type: "notiVarient", payload: "error" });
        hidemessage();
        dispatch({ type: "REMOVE_ROW", index });
      }
    }).catch(function (error) {
      console.error("Error:" + error);      
    });
  };

  const handleFFCancel = () => {
  };

  const handleAddDocumentRow = () => {
    dispatch({ type: "ADD_DOCUMENT_ROW" });
  };

  const handleRemoveDocumentRow = (index) => {
    dispatch({ type: "REMOVE_DOCUMENT_ROW", index });
  };

  const handleAddTravellers = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    // state.title = "";
    dispatch({ type: "title", payload: "" });
    // state.dateOfBirth.month = "";
    // state.dateOfBirth.day = "";
    // state.dateOfBirth.year = "";
    
    dispatch({ type: "dateOfBirth", payload: {
      month: "",
      day: "",
      year: ""
    } });
    state.travelDocuments[0].id = "";
    dispatch({ type: "travelDocuments", payload: [
      {
        doc_type: "",
        passport_no: "",
        issue_cnt: "",
        issue_date: {
          month: "",
          day: "",
          year: "",
        },
        exp_date: {
          month: "",
          day: "",
          year: "",
        },
      },
    ]});
    // state.travelDocuments[0].passport_no = "";
    // state.travelDocuments[0].issue_cnt = "";
    // if(state.travelDocuments[0].issue_date.month)  state.travelDocuments[0].issue_date.month = "";
    // if(state.travelDocuments[0].issue_date.day)  state.travelDocuments[0].issue_date.day = "";
    // if(state.travelDocuments[0].issue_date.year)  state.travelDocuments[0].issue_date.year = "";
    // if(state.travelDocuments[0].exp_date.month)  state.travelDocuments[0].exp_date.month = "";
    // if(state.travelDocuments[0].exp_date.day)  state.travelDocuments[0].exp_date.day = "";
    // if(state.travelDocuments[0].exp_date.year)  state.travelDocuments[0].exp_date.year = "";
    dispatch({ type: "saveTravellers", payload: false });
    dispatch({ type: "addTravellers", payload: true });
    dispatch({ type: "enableUpdate", payload: false });
  };

  /**
   * @description:Add Trvellers Validating Form
   * @author: Ubedullah
   * @function Validating Form
   * @date : 21-06-2023
   */

  const validateAddTravellers = () => {
    if (state.title === "") {     
      dispatch({ type: "error", payload: true }); 
      // dispatch({ type: "notiMessageShow", payload: true });
      // dispatch({ type: "notiMessage", payload: "Please select title" });
      // dispatch({ type: "notiVarient", payload: "error" });
      hidemessage();
      return false;
    } else if (state.firstName === "") {
      dispatch({ type: "error", payload: true });
      // dispatch({ type: "notiMessageShow", payload: true });
      // dispatch({ type: "notiMessage", payload: "Please enter firstname" });
      // dispatch({ type: "notiVarient", payload: "error" });
      hidemessage();
      return false;
    } else if (state.dateOfBirth.month === "") {
      dispatch({ type: "error", payload: true });
      // dispatch({ type: "notiMessageShow", payload: true });
      // dispatch({ type: "notiMessage", payload: "Please select DOB month" });
      // dispatch({ type: "notiVarient", payload: "error" });
      hidemessage();
      return false;
    } else if (state.dateOfBirth.day === "") {
      dispatch({ type: "error", payload: true });
      // dispatch({ type: "notiMessageShow", payload: true });
      // dispatch({ type: "notiMessage", payload: "Please select DOB day" });
      // dispatch({ type: "notiVarient", payload: "error" });
      hidemessage();
      return false;
    } else if (state.dateOfBirth.year === "") {
      dispatch({ type: "error", payload: true });
      // dispatch({ type: "notiMessageShow", payload: true });
      // dispatch({ type: "notiMessage", payload: "Please select DOB year" });
      // dispatch({ type: "notiVarient", payload: "error" });
      hidemessage();
      return false;
    } else if (state.nationality === "") {
      dispatch({ type: "error", payload: true });
      // dispatch({ type: "notiMessageShow", payload: true });
      // dispatch({ type: "notiMessage", payload: "Please select nationality" });
      // dispatch({ type: "notiVarient", payload: "error" });
      hidemessage();
      return false;
    } 

    if (state.dateOfBirth.month && 
      state.dateOfBirth.day && 
      state.dateOfBirth.year) {
      // "year-month-day" (e.g., "2021-04-3")
      const dateOfBirth = `${state.dateOfBirth.year}-${state.dateOfBirth.month}-${state.dateOfBirth.day}`
      
      const dateString = dateOfBirth;
      const dob = new Date(dateString);

      // Get the current date
      const currentDate = new Date();

      // Check if the date of birth is greater than 2 years from the current date
      if (dob > currentDate) {
        dispatch({ type: "notiMessageShow", payload: true });
        dispatch({ type: "notiMessage", payload: (context.translation && context.translation.datbirthgreater) });
        dispatch({ type: "notiVarient", payload: "error" });
        hidemessage();
        return false;
      }
    }       

    if (state.travelDocuments[0].issue_date.month && 
      state.travelDocuments[0].issue_date.day && 
      state.travelDocuments[0].issue_date.year) {
      // "year-month-day" (e.g., "2021-04-3")
      const issuingDate = `${state.travelDocuments[0].issue_date.year}-${state.travelDocuments[0].issue_date.month}-${state.travelDocuments[0].issue_date.day}`
      
      const dateString = issuingDate;
      const istdate = new Date(dateString);

      // Get the current date
      const currentDate = new Date();

      // Check if the date of birth is greater than 2 years from the current date
      if (istdate > currentDate) {
        dispatch({ type: "notiMessageShow", payload: true });
        dispatch({ type: "notiMessage", payload: (context.translation && context.translation.izgrtr) });
        dispatch({ type: "notiVarient", payload: "error" });
        hidemessage();
        return false;
      }
    }       
    if (state.travelDocuments[0].exp_date.month && 
      state.travelDocuments[0].exp_date.day && 
      state.travelDocuments[0].exp_date.year) {
      // "year-month-day" (e.g., "2021-04-3")
      const expiryDate = `${state.travelDocuments[0].exp_date.year}-${state.travelDocuments[0].exp_date.month}-${state.travelDocuments[0].exp_date.day}`
      
      const dateString = expiryDate;
      const expdate = new Date(dateString);

      // Get the current date
      const currentDate = new Date();

      // Check if the date of birth is greater than 2 years from the current date
      if (expdate < currentDate) {
        dispatch({ type: "notiMessageShow", payload: true });
        dispatch({ type: "notiMessage", payload: (context.translation && context.translation.expdatentLes) });
        dispatch({ type: "notiVarient", payload: "error" });
        hidemessage();
        return false;
      }
    }
    if (state.frequentFlyerData.length > 0) {
      for (let val of state.frequentFlyerData) {
          if (val.airline_name === "" || val.airline_name === null || val.airline_name === undefined) {
            return true;
          } else if (val.airline_name && (val.ffp === "" || val.ffp === null || val.ffp === undefined)) {
            dispatch({ type: "frequentFlyer", payload: true });
            return false;
          } else if (val.airline_name && (val.ffn === "" || val.ffn === null || val.ffn === undefined)) {
            dispatch({ type: "frequentFlyer", payload: true });
            return false;
          }
        }          
      }
      if(state.frequentFlyerData.length > 1) {
        const existingAirlines = state.frequentFlyerData.slice(0, -1);
        const newAirline = state.frequentFlyerData.slice(-1);
        const comparing = existingAirlines.filter((airline) => airline.airline_name === newAirline[0].airline_name)
        if(comparing[0]?.airline_name === newAirline[0].airline_name) {
          dispatch({ type: "notiMessageShow", payload: true });
          dispatch({ type: "notiMessage", payload: (context.translation && context.translation.airlinealexist) });
          dispatch({ type: "notiVarient", payload: "error" });
          hidemessage();
          return false;
        }
      }
    return true;
  };

  const handleAddTravellersCreation = (e) => {
    e.preventDefault();
    if (validateAddTravellers()) {
      // window.scrollTo({ top: 30, behavior: "smooth" });
      const dob =
        state.dateOfBirth.month +
        "-" +
        state.dateOfBirth.day +
        "-" +
        state.dateOfBirth.year;

      let dateString = "";
      if(state.travelDocuments[0].issue_date.month && state.travelDocuments[0].issue_date.day && state.travelDocuments[0].issue_date.year) {      
        const { month, day, year } = state.travelDocuments[0].issue_date;
        dateString = `${month}-${day}-${year}`;
      }

      let dateString1 = "";
      if(state.travelDocuments[0].exp_date.month && state.travelDocuments[0].exp_date.day && state.travelDocuments[0].exp_date.year) {
        const {
          month: expiryMonth,
          day: expiryDay,
          year: expiryYear,
        } = state.travelDocuments[0].exp_date;
        dateString1 = `${expiryMonth}-${expiryDay}-${expiryYear}`;
      }

      state.travelDocuments[0].issue_date = dateString;
      state.travelDocuments[0].exp_date = dateString1;
      // const unique_frq_fly = state.frequentFlyerData.filter(
      //   (obj) => obj.id === undefined
      // );
      const frq_fly_data = state.frequentFlyerData.filter(
        (obj) => obj.airline_name && obj.ffn && obj.ffp
      );
      const payload = {
        usr_uniq_id: userUniqId,
        title: state.title,
        fn: state.firstName,
        ln: state.lastName,
        dob: dob,
        na: state.nationality,
        docs: state.travelDocuments,
        // frq_fly: [{...state.frequentFlyerData[0], ...frq_fly_data}],
        frq_fly: frq_fly_data,
      };
      let tokenKey = Utilities.generateAPIToken(payload);
      saveTravellers(payload,tokenKey).then((response) => {
        if (response.suc) {
          // window.scrollTo(0, 0);
          setAlert(true);
          setNotiMessageShow(true);
          setNotiVarient("success");
          hidemessage();
          dispatch({ type: "notiMessageShow", payload: true });
          dispatch({ type: "notiMessage", payload: (context.translation && context.translation.travDetlsav) });
          dispatch({ type: "notiVarient", payload: "success" });
          hidemessage();
          setTimeout(function () {
            dispatch({ type: "addTravellers", payload: false });
            dispatch({ type: "saveTravellers", payload: true });
            // if (data !== null) {
            //   getTravellers({ email: data.res.email });
            // }
            // window.location.reload();
            window.location = "/my-account/add-travellers";
            // setLoadData(!loadData);
          }, 1000);
        } else if(response.err === "this traveller already existed") {
          setAlert(true);
          setNotiMessageShow(true);
          setNotiVarient("error");
          hidemessage();
          dispatch({ type: "notiMessageShow", payload: true });
          dispatch({ type: "notiMessage", payload: (context.translation && context.translation.travDetlsavexists) });
          dispatch({ type: "notiVarient", payload: "error" });
          hidemessage();          
        } else {
          setAlert(true);
          setNotiMessageShow(true);
          setNotiVarient("error");
          hidemessage();
          dispatch({ type: "notiMessageShow", payload: true });
          dispatch({ type: "notiMessage", payload: (context.translation && context.translation.travDetlsavnot) });
          dispatch({ type: "notiVarient", payload: "error" });
          hidemessage();
        }
      }).catch(function (error) {
        console.error("Error:" + error);      
      });
    } else if (!validateAddTravellers()) {
      // window.scrollTo({ top: 70, behavior: "smooth" });
      dispatch({ type: "addTravellers", payload: true });
      dispatch({ type: "saveTravellers", payload: false });
    }
  };

  const homePage = () => {
    navigate("/");
  }

  if (context.user_resp === "" && localStorage.getItem("SIGNINRESPONSE") === null) {
    return ( 
        <Modal
          title=""
          open={true}
          width={460}
          footer={null}
          maskClosable={false}
        >
          <Title level={4}>
            {/* {context.translation && context.translation.plzlog} */}
            {(sessionStorage.getItem("SEL_LAG") === null || sessionStorage.getItem("SEL_LAG") === "English") &&
              "Please log in to access your profile"              
            }
            {sessionStorage.getItem("SEL_LAG") === "Arabic" &&
              "يرجى تسجيل الدخول لملفك الشخصي"              
            }
          </Title>

          <Button type="primary" className="cntBtn sendBtn" onClick={homePage}>
            {/* {context.translation && context.translation.backHg} */}
            {(sessionStorage.getItem("SEL_LAG") === null || sessionStorage.getItem("SEL_LAG") === "English") &&
              "Back to Home Page"              
            }
            {sessionStorage.getItem("SEL_LAG") === "Arabic" &&
              "عودة للصفحة الرئيسية"              
            }
          </Button>
        </Modal>
    );
  }

  const update=(type)=>{
  }

  return (
    <>
      {state.notiMessageShow ? (
        <Alert
          description={state.notiMessage}
          type={state.notiVarient}
          closable
          onClose={() => dispatch({ type: "notiMessageShow", payload: false })}
          className="alertForm"
          // showIcon
        >
          {" "}
          {state.notiMessage}
        </Alert>
      ) : (
        ""
      )}
      {contextHolder}
      <Header setTab={update} />
      <div className="myAccountPage">
        <div className="myAccountBanner"></div>
        <div className="container">
          <Row>
            <Col md={5} className="firstChild">
              <Sidebar active="travellers" />
            </Col>
            <Col md={19} className="lastChild">
              {state.addTravellers && (
                <>
                  <div className="cardDetails myProfileDetails">
                    <div className="cardDetailsHeader">
                      <div>
                        <Title level={4}>{context.translation && context.translation.addNewtrav}</Title>
                        <p>
                          {context.translation && context.translation.saveTime}
                        </p>
                      </div>
                    </div>
                    <div className="cardDetailsBody">
                      <p className="note">
                        <span>{context.translation && context.translation.note}</span> {context.translation && context.translation.ensureName}
                      </p>
                      <Form layout="vertical">
                        <Row gutter={16}>
                          <Col span={8} className="addTopMargin">
                            <Form.Item autoComplete="off" className="pRelative">
                              <label className="requiredField">
                                {context.translation && context.translation.fn}
                              </label>
                              <>
                              {(sessionStorage.getItem("SEL_LAG") === null || sessionStorage.getItem("SEL_LAG") === "English") &&
                                 <Input
                                addonBefore={
                                  <Select
                                    name="title"
                                    value={state.title?state.title:undefined}
                                    defaultValue="Select"
                                    onChange={(value) =>
                                      dispatch({
                                        type: "UPDATE_FIELD",
                                        field: "title",
                                        value,
                                      })
                                    }
                                  >
                                    
                                   
                                      <> <Option value="Mr">Mr</Option>
                                      <Option value="Mrs">Mrs</Option>
                                      <Option value="Miss">Miss</Option>
                                      <Option value="Ms">Ms</Option>
                                      </>
                             
                                
                  
                                  </Select>
                                }
                                // className="selectBoxShadow"
                                className={
                                  "selectBoxShadow " +
                                  (state.error && state.firstName === "" ? "errorForm" : "")
                                }
                                type="text"
                                name="firstName"
                                value={state.firstName}
                                onChange={handleChange}
                                placeholder={context.translation && context.translation.efn}
                              />
                              }
                              {(sessionStorage.getItem("SEL_LAG") === "Arabic") &&
                               <Input
                               addonBefore={
                                 <Select
                                   name="title"
                                   value={state.title?state.title:undefined}
                                   defaultValue="اختار"
                                   onChange={(value) =>
                                     dispatch({
                                       type: "UPDATE_FIELD",
                                       field: "title",
                                       value,
                                     })
                                   }
                                 >
                                   
                                 <>
                                  <Option value="Mr">سيد</Option>
                               <Option value="Mrs">آنسة</Option>
                               <Option value="Miss">سيدة</Option>
                               </>
                               
                            
                                 </Select>
                               }
                               // className="selectBoxShadow"
                               className={
                                 "selectBoxShadow " +
                                 (state.error && state.firstName === "" ? "errorForm" : "")
                               }
                               type="text"
                               name="firstName"
                               value={state.firstName}
                               onChange={handleChange}
                               placeholder={context.translation && context.translation.efn}
                             />
                              }
                            </>
                              <i className="icon icon-Alert"></i>
                              {/* <Input addonBefore={selectBefore} className='selectBoxShadow' placeholder="Enter First Name" type="text" name="firstName" value={formData.firstName} onChange={handleChange}/> */}
                            </Form.Item>
                            {state.title === "" &&
                              state.error && (
                                <div className="error">{context.translation && context.translation.plzSelect}</div>
                              )}
                            {state.title !== "" && state.firstName === "" && state.error && (
                              <div className="error">{context.translation && context.translation.plzEnter}</div>
                            )}
                          </Col>
                          <Col span={8}>
                            <Form.Item label={context.translation && context.translation.ln}>
                              <Input
                                type="text"
                                className="innerBoxShadow"
                                name="lastName"
                                value={state.lastName}
                                onChange={handleChange}
                                placeholder={context.translation && context.translation.eln}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={8} className="addTopMargin">
                            <Form.Item>
                              <label className="requiredField">
                                {context.translation && context.translation.dob}
                              </label>
                              <div className="onlyFlex">
                                <div className="dateMY">
                                  <>
                                {(sessionStorage.getItem("SEL_LAG") === null || sessionStorage.getItem("SEL_LAG") === "English") &&
                                <Select
                                name="dateOfBirth.month"
                                value={state.dateOfBirth.month?state.dateOfBirth.month:undefined}
                                defaultValue="Month"
                                // className="selectBoxShadow mdy month"
                                className={
                                  "selectBoxShadow mdy month " +
                                  (state.error && state.dateOfBirth.month === "" ? "errorForm" : "")
                                }
                                onChange={(value) =>
                                  handleDateFieldChange(
                                    "dateOfBirth",
                                    "month",
                                    value
                                  )
                                }
                              >
                                {/* <Option value="">{context.translation && context.translation.month}</Option> */}
                                
                            
                                {months.map((month) => (
                                    <Option
                                      key={month.value}
                                      value={month.value}
                                      name={month.value}
                                    >
                                      {month.label}
                                    </Option>
                                    ))}
                          
                              </Select>
                              }
                              {(sessionStorage.getItem("SEL_LAG") === "Arabic") &&
                                <Select
                                name="dateOfBirth.month"
                                value={state.dateOfBirth.month?state.dateOfBirth.month:undefined}
                                defaultValue="شهر"
                                // className="selectBoxShadow mdy month"
                                className={
                                  "selectBoxShadow mdy month " +
                                  (state.error && state.dateOfBirth.month === "" ? "errorForm" : "")
                                }
                                onChange={(value) =>
                                  handleDateFieldChange(
                                    "dateOfBirth",
                                    "month",
                                    value
                                  )
                                }
                              >
                                {/* <Option value="">{context.translation && context.translation.month}</Option> */}
                                
                                {monthsArabic.map((month) => (
                                    <Option
                                      key={month.value}
                                      value={month.value}
                                      name={month.value}
                                    >
                                      {month.label}
                                    </Option>
                                  ))}
                              </Select>
                              }
                            </>
                                  {state.dateOfBirth.month === "" &&
                                    state.error && (
                                      <div className="error">{context.translation && context.translation.selectMonth}</div>
                                    )}
                                </div>
                                <div className="dateMY">
                                <>
                              {(sessionStorage.getItem("SEL_LAG") === null || sessionStorage.getItem("SEL_LAG") === "English") &&
                               <Select
                               name="dateOfBirth.day"
                               value={state.dateOfBirth.day?state.dateOfBirth.day:undefined}
                               defaultValue="Day"
                               // className="selectBoxShadow mdy day"
                               className={
                                 "selectBoxShadow mdy day " +
                                 (state.error && state.dateOfBirth.day === "" ? "errorForm" : "")
                               }
                               onChange={(value) =>
                                 handleDateFieldChange(
                                   "dateOfBirth",
                                   "day",
                                   value
                                 )
                               }
                             >
                               {/* <Option value="">{context.translation && context.translation.day}</Option> */}
                               {state.dateOfBirth.month &&
                                 Array.from(
                                   {
                                     length: getDaysInMonth(
                                       state.dateOfBirth.month,
                                       state.dateOfBirth.year
                                     ),
                                   },
                                   (_, i) => i + 1
                                 ).map((day) => (
                                   <Option
                                     key={day}
                                     value={
                                       day.toString().length === 1
                                         ? "0" + day.toString()
                                         : day
                                     }
                                   >
                                     {day.toString().length === 1
                                       ? "0" + day.toString()
                                       : day}
                                   </Option>
                                 ))}
                             </Select>
                              }
                              {(sessionStorage.getItem("SEL_LAG") === "Arabic") &&
                               <Select
                               name="dateOfBirth.day"
                               value={state.dateOfBirth.day?state.dateOfBirth.day:undefined}
                               defaultValue="يوم"
                               // className="selectBoxShadow mdy day"
                               className={
                                 "selectBoxShadow mdy day " +
                                 (state.error && state.dateOfBirth.day === "" ? "errorForm" : "")
                               }
                               onChange={(value) =>
                                 handleDateFieldChange(
                                   "dateOfBirth",
                                   "day",
                                   value
                                 )
                               }
                             >
                               {/* <Option value="">{context.translation && context.translation.day}</Option> */}
                               {state.dateOfBirth.month &&
                                 Array.from(
                                   {
                                     length: getDaysInMonth(
                                       state.dateOfBirth.month,
                                       state.dateOfBirth.year
                                     ),
                                   },
                                   (_, i) => i + 1
                                 ).map((day) => (
                                   <Option
                                     key={day}
                                     value={
                                       day.toString().length === 1
                                         ? "0" + day.toString()
                                         : day
                                     }
                                   >
                                     {day.toString().length === 1
                                       ? "0" + day.toString()
                                       : day}
                                   </Option>
                                 ))}
                             </Select>
                              }
                            </>
                                  {state.dateOfBirth.day === "" &&
                                    state.error && (
                                      <div className="error">{context.translation && context.translation.selectDay}</div>
                                    )}
                                </div>
                                <div className="dateMY">
                                <>
                              {(sessionStorage.getItem("SEL_LAG") === null || sessionStorage.getItem("SEL_LAG") === "English") &&
                                <Select
                                name="dateOfBirth.year"
                                value={state.dateOfBirth.year?state.dateOfBirth.year:undefined}
                                defaultValue="Year"
                                // className="selectBoxShadow"
                                className={
                                  "selectBoxShadow " +
                                  (state.error && state.dateOfBirth.year === "" ? "errorForm" : "")
                                }
                                onChange={(value) =>
                                  handleDateFieldChange(
                                    "dateOfBirth",
                                    "year",
                                    value
                                  )
                                }
                              >
                                {/* <Option value="">{context.translation && context.translation.year}</Option> */}
                                {years.map((year) => (
                                  <Option key={year} value={year}>
                                    {year}
                                  </Option>
                                ))}
                              </Select>
                              }
                              {(sessionStorage.getItem("SEL_LAG") === "Arabic") &&
                                <Select
                                name="dateOfBirth.year"
                                value={state.dateOfBirth.year?state.dateOfBirth.year:undefined}
                                defaultValue="سنة"
                                // className="selectBoxShadow"
                                className={
                                  "selectBoxShadow " +
                                  (state.error && state.dateOfBirth.year === "" ? "errorForm" : "")
                                }
                                onChange={(value) =>
                                  handleDateFieldChange(
                                    "dateOfBirth",
                                    "year",
                                    value
                                  )
                                }
                              >
                                {/* <Option value="">{context.translation && context.translation.year}</Option> */}
                                {years.map((year) => (
                                  <Option key={year} value={year}>
                                    {year}
                                  </Option>
                                ))}
                              </Select>
                              }
                            </>
                                  {state.dateOfBirth.year === "" &&
                                    state.error && (
                                      <div className="error">{context.translation && context.translation.selectYear}</div>
                                    )}
                                </div>
                              </div>
                            </Form.Item>
                          </Col>
                          <Col span={8} className="addTopMargin">
                            <Form.Item>
                              <label className="requiredField">
                                {context.translation && context.translation.nn}
                              </label>
                              {/* <Select
                                name="nationality"
                                value={state.nationality}
                                className="selectBoxShadow"
                                onChange={(value) =>
                                  dispatch({
                                    type: "UPDATE_FIELD",
                                    field: "nationality",
                                    value,
                                  })
                                }
                              >
                                <Option value="">Select</Option>
                                <Option value="India">India</Option>
                                <Option value="Qatar">Qatar</Option>
                              </Select> */}
<>
{(sessionStorage.getItem("SEL_LAG") === null || sessionStorage.getItem("SEL_LAG") === "English") &&
                                 <Select
                                 showSearch
                                 defaultValue="Select"
                                 name="nationality"
                                 value={state.nationality?state.nationality:undefined}
                                 // className="selectBoxShadow"
                                 className={
                                   "selectBoxShadow " +
                                   (state.error && state.nationality === "" ? "errorForm" : "")
                                 }
                                 onChange={(value) =>
                                   dispatch({
                                     type: "UPDATE_FIELD",
                                     field: "nationality",
                                     value,
                                   })
                                 }
                                 filterOption={(input, option) =>
                                   (option?.label ?? "")
                                     .toLowerCase()
                                     .includes(input.toLowerCase())
                                 }
                                 optionFilterProp="children"
                                 options={countrieslist}
                               />
                              }
                              {(sessionStorage.getItem("SEL_LAG") === "Arabic") &&
                                 <Select
                                 showSearch
                                 defaultValue="اختار"
                                 name="nationality"
                                 value={state.nationality?state.nationality:undefined}
                                 // className="selectBoxShadow"
                                 className={
                                   "selectBoxShadow " +
                                   (state.error && state.nationality === "" ? "errorForm" : "")
                                 }
                                 onChange={(value) =>
                                   dispatch({
                                     type: "UPDATE_FIELD",
                                     field: "nationality",
                                     value,
                                   })
                                 }
                                 filterOption={(input, option) =>
                                   (option?.label ?? "")
                                     .toLowerCase()
                                     .includes(input.toLowerCase())
                                 }
                                 optionFilterProp="children"
                                 options={countrieslist}
                               />
                              }
                            </>
                              {state.nationality === "" && state.error && (
                                <div className="error">
                                  {context.translation && context.translation.enn}
                                </div>
                              )}
                            </Form.Item>
                          </Col>
                        </Row>
                        {/* <Row gutter={16}>
                          <Col span={8}>
                            {state.maritalStatus === "Married" && (
                              <Form.Item label="Anniversary">
                                <div className="onlyFlex">
                                  <div>
                                    <Select
                                      name="anniversary.month"
                                      value={state.anniversary.month}
                                      // className="selectBoxShadow"
                                      className={
                                        "selectBoxShadow " +
                                        (state.error ? "errorForm" : "")
                                      }
                                      onChange={(value) =>
                                        handleDateFieldChange(
                                          "anniversary",
                                          "month",
                                          value
                                        )
                                      }
                                    >
                                      <Option value="">Month</Option>
                                      {months.map((month) => (
                                        <Option
                                          key={month.value}
                                          value={month.value}
                                        >
                                          {month.label}
                                        </Option>
                                      ))}
                                    </Select>
                                  </div>
                                  <div>
                                    <Select
                                      name="anniversary.day"
                                      value={state.anniversary.day}
                                      // className="selectBoxShadow"
                                      className={
                                        "selectBoxShadow " +
                                        (state.error ? "errorForm" : "")
                                      }
                                      onChange={(value) =>
                                        handleDateFieldChange(
                                          "anniversary",
                                          "day",
                                          value
                                        )
                                      }
                                    >
                                      <Option value="">Day</Option>
                                      {state.anniversary.month &&
                                        Array.from(
                                          {
                                            length: getDaysInMonth(
                                              state.anniversary.month,
                                              state.anniversary.year
                                            ),
                                          },
                                          (_, i) => i + 1
                                        ).map((day) => (
                                          <Option
                                            key={day}
                                            value={
                                              day.toString().length === 1
                                                ? "0" + day.toString()
                                                : day
                                            }
                                          >
                                            {day.toString().length === 1
                                              ? "0" + day.toString()
                                              : day}
                                          </Option>
                                        ))}
                                    </Select>
                                  </div>
                                  <div>
                                    <Select
                                      name="anniversary.year"
                                      value={state.anniversary.year}
                                      // className="selectBoxShadow"
                                      className={
                                        "selectBoxShadow " +
                                        (state.error ? "errorForm" : "")
                                      }
                                      onChange={(value) =>
                                        handleDateFieldChange(
                                          "anniversary",
                                          "year",
                                          value
                                        )
                                      }
                                    >
                                      <Option value="">Year</Option>
                                      {years.map((year) => (
                                        <Option key={year} value={year}>
                                          {year}
                                        </Option>
                                      ))}
                                    </Select>
                                  </div>
                                </div>
                              </Form.Item>
                            )}
                          </Col>
                        </Row> */}
                      </Form>
                    </div>
                  </div>
                  <div className="cardDetails travelDetails">
                    <div className="cardDetailsHeader">
                      <div>
                        <Title level={4}>{context.translation && context.translation.travelDoc}</Title>
                        <p>{context.translation && context.translation.addurDoc}</p>
                      </div>
                    </div>
                    <div className="cardDetailsBody">
                      <Form layout="vertical">
                        {state.travelDocuments.map((data, index) => (
                          <Row key={index} gutter={16}>
                            {/* <Col span={8}> */}
                            {/* <label>
                                  Document Type:
                                  <select
                                    name={`travelDocuments[${index}].doc_type`}
                                    value={data.doc_type || ""}
                                    onChange={(e) =>
                                      dispatch({
                                        type: "UPDATE_TRAVEL_DOCUMENT_FIELD",
                                        index,
                                        field: "doc_type",
                                        value: e.target.value
                                      })
                                    }
                                  >
                                    <Option value="">Select</Option>
                                    <Option value="passport">Passport</Option>
                                    <Option value="id_card">ID Card</Option>
                                    <Option value="driver_license">Driver's License</Option>
                                  </select>
                                </label> */}
                            {/* </Col> */}

                            <Col span={8} className="noSpaceBet">
                              <Form.Item label={context.translation && context.translation.pn}>
                                <Input
                                  type="text"
                                  name={`travelDocuments[${index}].passport_no`}
                                  value={data.passport_no || ""}
                                  placeholder={context.translation && context.translation.epn}
                                  className="innerBoxShadow"
                                  onChange={(e) =>
                                    dispatch({
                                      type: "UPDATE_TRAVEL_DOCUMENT_FIELD",
                                      index,
                                      field: "passport_no",
                                      value: e.target.value,
                                    })
                                  }
                                />
                              </Form.Item>
                              {/* {data.passport_no === "" && state.error && (
                                <div className="error">
                                  Please enter passport number.
                                </div>
                              )} */}
                            </Col>

                            <Col span={8} className="noSpaceBet issueCountryWrap">
                              <Form.Item label={context.translation && context.translation.issCountry}>
                              <>
                              {(sessionStorage.getItem("SEL_LAG") === null || sessionStorage.getItem("SEL_LAG") === "English") &&
                              <Select
                              showSearch
                              name={`travelDocuments[${index}].issue_cnt`}
                              value={data.issue_cnt?data.issue_cnt:undefined}
                              className="selectBoxShadow"
                              onChange={(value) =>
                                handleCountryFieldChange(
                                  index,
                                  "issue_cnt",
                                  value
                                )
                              }
                              defaultValue="Select"
                              filterOption={(input, option) =>
                                (option?.label ?? "")
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                              optionFilterProp="children"
                              options={countrieslist}
                              // name={`travelDocuments[${index}].issue_cnt`}
                              // value={data.issue_cnt || ""}
                              // className="selectBoxShadow"
                            ></Select>
                              }
                              {(sessionStorage.getItem("SEL_LAG") === "Arabic") &&
                              <Select
                              showSearch
                              name={`travelDocuments[${index}].issue_cnt`}
                              value={data.issue_cnt?data.issue_cnt:undefined}
                              className="selectBoxShadow"
                              onChange={(value) =>
                                handleCountryFieldChange(
                                  index,
                                  "issue_cnt",
                                  value
                                )
                              }
                              defaultValue="اختار"
                              filterOption={(input, option) =>
                                (option?.label ?? "")
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                              optionFilterProp="children"
                              options={countrieslist}
                              // name={`travelDocuments[${index}].issue_cnt`}
                              // value={data.issue_cnt || ""}
                              // className="selectBoxShadow"
                            ></Select>
                              }
                            </>
                                {/* <Select
                                  name={`travelDocuments[${index}].issue_cnt`}
                                  value={data.issue_cnt || ""}
                                  className="selectBoxShadow"
                                  onChange={(value) =>
                                    handleCountryFieldChange(
                                      index,
                                      "issue_cnt",
                                      value
                                    )
                                  }
                                >
                                  <Option value="">Select</Option>
                                  <Option value="India">India</Option>
                                </Select> */}
                                {/* {data.issue_cnt === "" && state.error && (
                                  <div className="error">
                                    Please select country.
                                  </div>
                                )} */}
                              </Form.Item>
                            </Col>

                            <Col span={8} className="noSpaceBet issueDateWrap">
                              <Form.Item label={context.translation && context.translation.issDate}>
                                <div className="onlyFlex">
                                  <div className="dateMY">
                                  <>
                              {(sessionStorage.getItem("SEL_LAG") === null || sessionStorage.getItem("SEL_LAG") === "English") &&
                               <Select
                               name={`travelDocuments[${index}].issue_date.month`}
                               value={data.issue_date.month?data.issue_date.month:undefined}
                               defaultValue="Month"
                               className="selectBoxShadow mdy month"
                               onChange={(value) =>
                                 handleIssuingDateFieldChange(
                                   index,
                                   "issue_date",
                                   "month",
                                   value
                                 )
                               }
                             >
                               {/* Add month options */}
                               {/* <Option value="">{context.translation && context.translation.month}</Option> */}
                             
                               {months.map((month) => (
                                   <Option
                                     key={month.value}
                                     value={month.value}
                                   >
                                     {month.label}
                                   </Option>
                                   ))}   
                               
                             </Select>
                              }
                              {(sessionStorage.getItem("SEL_LAG") === "Arabic") &&
                               <Select
                               name={`travelDocuments[${index}].issue_date.month`}
                               value={data.issue_date.month?data.issue_date.month:undefined}
                               defaultValue="شهر"
                               className="selectBoxShadow mdy month"
                               onChange={(value) =>
                                 handleIssuingDateFieldChange(
                                   index,
                                   "issue_date",
                                   "month",
                                   value
                                 )
                               }
                             >
                               {/* Add month options */}
                               {/* <Option value="">{context.translation && context.translation.month}</Option> */}
                             
                                 {monthsArabic.map((month) => (
                                   <Option
                                     key={month.value}
                                     value={month.value}
                                   >
                                     {month.label}
                                   </Option>
                                 ))}
                                 
                               
                             </Select>
                              }
                            </>
                                    {/* {data.issue_date.month === "" &&
                                      state.error && (
                                        <div className="error">
                                          Select month.
                                        </div>
                                      )} */}
                                  </div>
                                  <div className="dateMY">
                                  <>
                              {(sessionStorage.getItem("SEL_LAG") === null || sessionStorage.getItem("SEL_LAG") === "English") &&
                               <Select
                               name={`travelDocuments[${index}].issue_date.day`}
                               value={data.issue_date.day?data.issue_date.day:undefined}
                               defaultValue="Day"
                               className="selectBoxShadow mdy day"
                               onChange={(value) =>
                                 handleIssuingDateFieldChange(
                                   index,
                                   "issue_date",
                                   "day",
                                   value
                                 )
                               }
                             >
                               {/* <Option value="">{context.translation && context.translation.day}</Option> */}
                               {data.issue_date.month &&
                                 Array.from(
                                   {
                                     length: getDaysInMonth(
                                       data.issue_date.month,
                                       data.issue_date.year
                                     ),
                                   },
                                   (_, i) => i + 1
                                 ).map((day) => (
                                   <Option
                                     key={day}
                                     value={
                                       day.toString().length === 1
                                         ? "0" + day.toString()
                                         : day
                                     }
                                   >
                                     {day.toString().length === 1
                                       ? "0" + day.toString()
                                       : day}
                                   </Option>
                                 ))}
                             </Select>
                              }
                              {(sessionStorage.getItem("SEL_LAG") === "Arabic") &&
                              <Select
                              name={`travelDocuments[${index}].issue_date.day`}
                              value={data.issue_date.day?data.issue_date.day:undefined}
                              defaultValue="يوم"
                              className="selectBoxShadow mdy day"
                              onChange={(value) =>
                                handleIssuingDateFieldChange(
                                  index,
                                  "issue_date",
                                  "day",
                                  value
                                )
                              }
                            >
                              {/* <Option value="">{context.translation && context.translation.day}</Option> */}
                              {data.issue_date.month &&
                                Array.from(
                                  {
                                    length: getDaysInMonth(
                                      data.issue_date.month,
                                      data.issue_date.year
                                    ),
                                  },
                                  (_, i) => i + 1
                                ).map((day) => (
                                  <Option
                                    key={day}
                                    value={
                                      day.toString().length === 1
                                        ? "0" + day.toString()
                                        : day
                                    }
                                  >
                                    {day.toString().length === 1
                                      ? "0" + day.toString()
                                      : day}
                                  </Option>
                                ))}
                            </Select>
                              }
                            </>
                                    {/* {data.issue_date.day === "" &&
                                      state.error && (
                                        <div className="error">Select day.</div>
                                      )} */}
                                  </div>
                                  <div className="dateMY">
                                  <>
                              {(sessionStorage.getItem("SEL_LAG") === null || sessionStorage.getItem("SEL_LAG") === "English") &&
                                 <Select
                                 name={`travelDocuments[${index}].issue_date.year`}
                                 value={data.issue_date.year?data.issue_date.year:undefined}
                                 defaultValue="Year"
                                 className="selectBoxShadow mdy year"
                                 onChange={(value) =>
                                   handleIssuingDateFieldChange(
                                     index,
                                     "issue_date",
                                     "year",
                                     value
                                   )
                                 }
                               >
                                 {/* <Option value="">{context.translation && context.translation.year}</Option> */}
                                 {years.map((year) => (
                                   <Option key={year} value={year}>
                                     {year}
                                   </Option>
                                 ))}
                               </Select>
                              }
                              {(sessionStorage.getItem("SEL_LAG") === "Arabic") &&
                                <Select
                                name={`travelDocuments[${index}].issue_date.year`}
                                value={data.issue_date.year?data.issue_date.year:undefined}
                                defaultValue="سنة"
                                className="selectBoxShadow mdy year"
                                onChange={(value) =>
                                  handleIssuingDateFieldChange(
                                    index,
                                    "issue_date",
                                    "year",
                                    value
                                  )
                                }
                              >
                                {/* <Option value="">{context.translation && context.translation.year}</Option> */}
                                {years.map((year) => (
                                  <Option key={year} value={year}>
                                    {year}
                                  </Option>
                                ))}
                              </Select>
                              }
                            </>
                                    {/* {data.issue_date.year === "" &&
                                      state.error && (
                                        <div className="error">
                                          Select year.
                                        </div>
                                      )} */}
                                  </div>
                                </div>
                              </Form.Item>
                            </Col>

                            <Col span={8}>
                              <Form.Item label={context.translation && context.translation.eDate}>
                                <div className="onlyFlex">
                                  <div className="dateMY">
                                  <>
                              {(sessionStorage.getItem("SEL_LAG") === null || sessionStorage.getItem("SEL_LAG") === "English") &&
                                 <Select
                                 name={`travelDocuments[${index}].exp_date.month`}
                                 value={data.exp_date.month?data.exp_date.month:undefined}
                                 defaultValue="Month"
                                 className="selectBoxShadow mdy month"
                                 onChange={(value) =>
                                   handleIssuingDateFieldChange(
                                     index,
                                     "exp_date",
                                     "month",
                                     value
                                   )
                                 }
                               >
                                 {/* <Option value="">{context.translation && context.translation.month}</Option> */}
                                 {months.map((month) => (
                                     <Option
                                       key={month.value}
                                       value={month.value}
                                     >
                                       {month.label}
                                     </Option>
                                     ))}
                                
                               </Select>
                              }
                              {(sessionStorage.getItem("SEL_LAG") === "Arabic") &&
                                 <Select
                                 name={`travelDocuments[${index}].exp_date.month`}
                                 value={data.exp_date.month?data.exp_date.month:undefined}
                                 defaultValue="شهر"
                                 className="selectBoxShadow mdy month"
                                 onChange={(value) =>
                                   handleIssuingDateFieldChange(
                                     index,
                                     "exp_date",
                                     "month",
                                     value
                                   )
                                 }
                               >
                                 {/* <Option value="">{context.translation && context.translation.month}</Option> */}
                              
                                   {monthsArabic.map((month) => (
                                     <Option
                                       key={month.value}
                                       value={month.value}
                                     >
                                       {month.label}
                                     </Option>
                                   ))}
                                 
                               </Select>
                              }
                            </>
                                    {/* {data.exp_date.month === "" &&
                                      state.error && (
                                        <div className="error">
                                          Select month.
                                        </div>
                                      )} */}
                                  </div>
                                  <div className="dateMY">
                                  <>
                              {(sessionStorage.getItem("SEL_LAG") === null || sessionStorage.getItem("SEL_LAG") === "English") &&
                              <Select
                              name={`travelDocuments[${index}].exp_date.day`}
                              value={data.exp_date.day?data.exp_date.day:undefined}
                              defaultValue="Day"
                              className="selectBoxShadow mdy day"
                              onChange={(value) =>
                                handleIssuingDateFieldChange(
                                  index,
                                  "exp_date",
                                  "day",
                                  value
                                )
                              }
                            >
                              {/* <Option value="">{context.translation && context.translation.day}</Option> */}
                              {data.exp_date.month &&
                                Array.from(
                                  {
                                    length: getDaysInMonth(
                                      data.exp_date.month,
                                      data.exp_date.year
                                    ),
                                  },
                                  (_, i) => i + 1
                                ).map((day) => (
                                  <Option
                                    key={day}
                                    value={
                                      day.toString().length === 1
                                        ? "0" + day.toString()
                                        : day
                                    }
                                  >
                                    {day.toString().length === 1
                                      ? "0" + day.toString()
                                      : day}
                                  </Option>
                                ))}
                            </Select>
                              }
                              {(sessionStorage.getItem("SEL_LAG") === "Arabic") &&
                               <Select
                               name={`travelDocuments[${index}].exp_date.day`}
                               value={data.exp_date.day?data.exp_date.day:undefined}
                               defaultValue="يوم"
                               className="selectBoxShadow mdy day"
                               onChange={(value) =>
                                 handleIssuingDateFieldChange(
                                   index,
                                   "exp_date",
                                   "day",
                                   value
                                 )
                               }
                             >
                               {/* <Option value="">{context.translation && context.translation.day}</Option> */}
                               {data.exp_date.month &&
                                 Array.from(
                                   {
                                     length: getDaysInMonth(
                                       data.exp_date.month,
                                       data.exp_date.year
                                     ),
                                   },
                                   (_, i) => i + 1
                                 ).map((day) => (
                                   <Option
                                     key={day}
                                     value={
                                       day.toString().length === 1
                                         ? "0" + day.toString()
                                         : day
                                     }
                                   >
                                     {day.toString().length === 1
                                       ? "0" + day.toString()
                                       : day}
                                   </Option>
                                 ))}
                             </Select>
                              }
                            </>
                                    {/* {data.exp_date.day === "" &&
                                      state.error && (
                                        <div className="error">Select day.</div>
                                      )} */}
                                  </div>
                                  <div className="dateMY">
                                  <>
                              {(sessionStorage.getItem("SEL_LAG") === null || sessionStorage.getItem("SEL_LAG") === "English") &&
                                <Select
                                name={`travelDocuments[${index}].exp_date.year`}
                                value={data.exp_date.year?data.exp_date.year:undefined}
                                defaultValue="Year"
                                className="selectBoxShadow mdy year"
                                onChange={(value) =>
                                  handleIssuingDateFieldChange(
                                    index,
                                    "exp_date",
                                    "year",
                                    value
                                  )
                                }
                              >
                                {/* <Option value="">{context.translation && context.translation.year}</Option> */}
                                {expiryYears.map((year) => (
                                  <Option key={year} value={year}>
                                    {year}
                                  </Option>
                                ))}
                              </Select>
                              }
                              {(sessionStorage.getItem("SEL_LAG") === "Arabic") &&
                                <Select
                                name={`travelDocuments[${index}].exp_date.year`}
                                value={data.exp_date.year?data.exp_date.year:undefined}
                                defaultValue="سنة"
                                className="selectBoxShadow mdy year"
                                onChange={(value) =>
                                  handleIssuingDateFieldChange(
                                    index,
                                    "exp_date",
                                    "year",
                                    value
                                  )
                                }
                              >
                                {/* <Option value="">{context.translation && context.translation.year}</Option> */}
                                {expiryYears.map((year) => (
                                  <Option key={year} value={year}>
                                    {year}
                                  </Option>
                                ))}
                              </Select>
                              }
                            </>
                                    {/* {data.exp_date.year === "" &&
                                      state.error && (
                                        <div className="error">
                                          Select year.
                                        </div>
                                      )} */}
                                  </div>
                                </div>
                              </Form.Item>
                            </Col>
                            {/* <button onClick={() => handleRemoveDocumentRow(index)}>Delete</button> */}

                            {/* </Col> */}
                          </Row>
                        ))}
                      </Form>
                    </div>
                  </div>
                  <div className="cardDetails contactdDetails frequentFlyerWrapper">
                    <div className="cardDetailsHeader">
                      <div>
                        <Title level={4}>{context.translation && context.translation.ff}</Title>
                        <p>{context.translation && context.translation.addurDetails}</p>
                      </div>
                    </div>
                    <div className="cardDetailsBody pRelative">
                      <Form layout="vertical">
                        {state.frequentFlyerData.map((data, index) => (
                          <Row gutter={16} key={index} className="noTopMargin">
                            <Col span={8} className="colWidth addSpace">
                              <Form.Item label={context.translation && context.translation.airlineNam}>
                                {/* <Select
                                  name={`frequentFlyerData[${index}].airline_name`}
                                  value={data.airline_name || ""}
                                  className="selectBoxShadow"
                                  onChange={(value) =>
                                    handleAirlineLines(
                                      index,
                                      "airline_name",
                                      value
                                    )
                                  }
                                >
                                  <Option value="">Select</Option>
                                  <Option value="Qatar Airline">
                                    Qatar Airline
                                  </Option>
                                </Select> */}

<>
                              {(sessionStorage.getItem("SEL_LAG") === null || sessionStorage.getItem("SEL_LAG") === "English") &&
                               <Select
                               showSearch
                               defaultValue="Select"
                               optionFilterProp="children"
                               name={`frequentFlyerData[${index}].airline_name`}
                               value={data.airline_name?data.airline_name:undefined}
                               className="selectBoxShadow"
                               onChange={(value) =>
                                 handleAirlineLines(
                                   index,
                                   "airline_name",
                                   value
                                 )
                               }
                               // onChange={onChange}
                               filterOption={(input, option) =>
                                 (option?.label ?? "")
                                   .toLowerCase()
                                   .includes(input.toLowerCase())
                               }
                               options={airlineMaster}
                             />
                              }
                              {(sessionStorage.getItem("SEL_LAG") === "Arabic") &&
                               <Select
                               showSearch
                               defaultValue="اختر"
                               optionFilterProp="children"
                               name={`frequentFlyerData[${index}].airline_name`}
                               value={data.airline_name?data.airline_name:undefined}
                               className="selectBoxShadow"
                               onChange={(value) =>
                                 handleAirlineLines(
                                   index,
                                   "airline_name",
                                   value
                                 )
                               }
                               // onChange={onChange}
                               filterOption={(input, option) =>
                                 (option?.label ?? "")
                                   .toLowerCase()
                                   .includes(input.toLowerCase())
                               }
                               options={airlineMaster}
                             />
                              }
                            </>
                                {/* {data.airline_name === "" && state.error && (
                                  <div className="error">
                                    Please select airline.
                                  </div>
                                )} */}
                              </Form.Item>
                            </Col>
                            <Col span={8} className="colWidth addSpace spaceBet20">
                              <Form.Item label={context.translation && context.translation.ffp}>
                                <Input
                                  type="text"
                                  name={`frequentFlyerData[${index}].ffp`}
                                  value={data.ffp || ""}
                                  // className="innerBoxShadow"
                                  className={
                                    "innerBoxShadow " +
                                    (state.frequentFlyer && data.airline_name && (data.ffp === "" || data.ffp === undefined) ? "errorForm" : "")
                                  }
                                  placeholder={context.translation && context.translation.ffp}
                                  onChange={(e) =>
                                    dispatch({
                                      type: "UPDATE_FREQUENT_FLYER_FIELD",
                                      index,
                                      field: "ffp",
                                      value: e.target.value,
                                    })
                                  }
                                />
                              </Form.Item>
                              {(data.ffp === "" || data.ffp === undefined) && state.frequentFlyer && data.airline_name &&  (
                                <div className="error">
                                  {context.translation && context.translation.peffp}
                                </div>
                              )}
                            </Col>
                            <Col span={8} className="colWidth addSpace spaceBet20">
                              <Form.Item label={context.translation && context.translation.ffn}>
                                <Input
                                  type="text"
                                  name={`frequentFlyerData[${index}].ffn`}
                                  value={data.ffn || ""}
                                  // className="innerBoxShadow"
                                  className={
                                    "innerBoxShadow " +
                                    (data.airline_name && state.frequentFlyer && (data.ffn === "" || data.ffn === undefined) ? "errorForm" : "")
                                  }
                                  placeholder={context.translation && context.translation.ffn}
                                  onChange={(e) =>
                                    dispatch({
                                      type: "UPDATE_FREQUENT_FLYER_FIELD",
                                      index,
                                      field: "ffn",
                                      value: e.target.value,
                                    })
                                  }
                                />
                              </Form.Item>
                              {(data.ffn === "" || data.ffn === undefined) && state.frequentFlyer && data.airline_name && (
                                <div className="error">
                                  {context.translation && context.translation.peffn}
                                </div>
                              )}
                              {state.frequentFlyerData.length > 1 && (
                                <i className="icon icon-cancel" onClick={() => handleRemoveFFRow(index, state.frequentFlyerData[index].id, state.frequentFlyerData, state.id)}></i>
                              )}
                            </Col>
                          </Row>
                        ))}
                        {state.frequentFlyerData.length < 20 &&
                        <Button
                          type="primary"
                          className="addMore addProfile"
                          onClick={handleAddRow}
                        >
                          +
                        </Button>
                        }
                      </Form>
                    </div>
                    <div className="cardDetailsFooter">
                      <Button
                        type="secondary"
                        className="cancelBtn"
                        ghost
                        onClick={handleCancelTraveller}
                      >
                        {context.translation && context.translation.cancel}
                      </Button>
                      {!state.enableUpdate ? (
                        <Button
                          type="primary"
                          className="cntBtn"
                          onClick={handleAddTravellersCreation}
                        >
                          <span>{context.translation && context.translation.save}</span>{" "}
                          {/* <i className="sprite btn-arrow-sprite"></i> */}
                        </Button>
                      ) : (
                        <Button
                          type="primary"
                          className="cntBtn"
                          onClick={handleUpdateTravellers}
                        >
                          <span>{context.translation && context.translation.update}</span>{" "}
                          {/* <i className="sprite btn-arrow-sprite"></i> */}
                        </Button>
                      )}
                    </div>
                  </div>
                </>
              )}

              {state.saveTravellers && (
                <div className="cardDetails savedTravellersDetails">
                  <div className="cardDetailsHeader">
                    <div>
                      <Title level={4}>{context.translation && context.translation.savedTrav}</Title>
                      <p>{context.translation && context.translation.fastXtime}</p>
                    </div>
                    <div>
                      <Button
                        type="primary"
                        className="cntBtn addNew"
                        onClick={handleAddTravellers}
                      >
                        <span>{context.translation && context.translation.addNewtrav}</span>
                        <i className="sprite btn-arrow-sprite"></i>
                      </Button>
                    </div>
                  </div>
                  {/* <Table dataSource={state.res} columns={columns} />; */}
                  {state.res.length > 0 ? (
                    <div className="cardDetailsBody">
                      {state.res.map((data, index) => {
                        return (
                          <div className="dFlex padding20" key={index}>
                            <div className="travellersList">
                              <Title level={5}>
                                {data.ln} {data.fn} {data.fullName}
                              </Title>
                              <span className="dob">
                                DOB:{data.dob !== null && data.dob !== "" && data.dob?.split("-").reverse().join("-")}
                              </span>
                              <i className="icon icon-Profile2"></i>
                            </div>
                            <div className="actionList">{data.options}</div>
                          </div>
                        );
                      })}

                      {/* <div className="dFlex padding20">
                        <div className="travellersList">
                          <Title level={5}>Syed Mubeen Ahmed</Title>
                          <span className="dob">DOB:09-05-1988</span>
                          <i className="icon icon-Profile2"></i>
                        </div>
                        <div>
                          <Button type="secondary" className="editBtn" ghost>
                            <i className="icon icon-Edit"></i> Edit
                          </Button>
                          <Button type="primary" className="deleteBtn" ghost>
                            <i className="icon icon-Delete"></i>
                          </Button>
                        </div>
                      </div> */}
                    </div>
                  ) : (
                    // <Title level={2}></Title>
                    <Space
                      direction="horizontal"
                      align="center"
                      style={{
                        width: "100%",
                        justifyContent: "center",
                        alignItems: "center",
                        minHeight: "300px",
                      }}
                    >
                      <Title level={3} style={{ marginTop: "0.5em" }}>
                        {context.translation && context.translation.nodata}
                      </Title>
                    </Space>
                  )}
                </div>
              )}
              <Form layout="vertical">
                {/* <Button className='cntBtn' onClick={() => editProfile()}>Edit</Button> */}
              </Form>
            </Col>
          </Row>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AddTravellers;
