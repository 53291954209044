import React, { useEffect, useRef } from "react";
import Footer from "./Footer";
import Header from "./Header";
import ShowHide from "./showHide/ShowHide";
import StickyBox from "react-sticky-box";
import { Col, Row, Typography } from "antd";
import Title from "antd/es/skeleton/Title";

function PrivacyPolicy(props) {
  const tcGeneral = useRef(null);
  const tc1 = useRef(null);
  const tc2 = useRef(null);
  const tc3 = useRef(null);
  const tc4 = useRef(null);
  const tc5 = useRef(null);
  const tc6 = useRef(null);
  const tc7 = useRef(null);
  const tc8 = useRef(null);
  const tc9 = useRef(null);
  const tc10 = useRef(null);
  const tc11 = useRef(null);
  const tc12 = useRef(null);
  const executeScrolltop = () =>
    tcGeneral.current.scrollIntoView({ behavior: "smooth" });
  const executeScrolltop1 = () =>
    tc1.current.scrollIntoView({ behavior: "smooth" });
  const executeScrolltop2 = () =>
    tc2.current.scrollIntoView({ behavior: "smooth" });
  const executeScrolltop3 = () =>
    tc3.current.scrollIntoView({ behavior: "smooth" });
  const executeScrolltop4 = () =>
    tc4.current.scrollIntoView({ behavior: "smooth" });
  const executeScrolltop5 = () =>
    tc5.current.scrollIntoView({ behavior: "smooth" });
  const executeScrolltop6 = () =>
    tc6.current.scrollIntoView({ behavior: "smooth" });
  const executeScrolltop7 = () =>
    tc7.current.scrollIntoView({ behavior: "smooth" });
  const executeScrolltop8 = () =>
    tc8.current.scrollIntoView({ behavior: "smooth" });
  const executeScrolltop9 = () =>
    tc9.current.scrollIntoView({ behavior: "smooth" });
  const executeScrolltop10 = () =>
    tc10.current.scrollIntoView({ behavior: "smooth" });
  const executeScrolltop11 = () =>
    tc11.current.scrollIntoView({ behavior: "smooth" });
  const executeScrolltop12 = () =>
    tc12.current.scrollIntoView({ behavior: "smooth" });
  const general = (e) => {
    executeScrolltop();
  };
  const general1 = (e) => {
    executeScrolltop1();
  };
  const general2 = (e) => {
    executeScrolltop2();
  };
  const general3 = (e) => {
    executeScrolltop3();
  };
  const general4 = (e) => {
    executeScrolltop4();
  };
  const general5 = (e) => {
    executeScrolltop5();
  };
  const general6 = (e) => {
    executeScrolltop6();
  };
  const general7 = (e) => {
    executeScrolltop7();
  };
  const general8 = (e) => {
    executeScrolltop8();
  };
  const general9 = (e) => {
    executeScrolltop9();
  };
  const general10 = (e) => {
    executeScrolltop10();
  };
  const general11 = (e) => {
    executeScrolltop11();
  };
  const general12 = (e) => {
    executeScrolltop12();
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  const { Title } = Typography;
  const update=(type)=>{
  }
  return (
    <>
      <Header posType="floating" setTab={update} />
      <div className="container tndC">
        <Title level={4} className="tcTitle">
          Privacy Policy
        </Title>
        <Row>
          <Col span={6} className="tcleftSidebar">
            <StickyBox offsetTop={100} offsetBottom={20}>
              <ul variant="pills" className="flex-column">
                <li>
                  <span eventKey="general" onClick={general} className="active" title="Introduction">
                    Introduction
                  </span>
                </li>
                <li>
                  <span eventKey="clientResp" onClick={general1} title="Users Outside the Geographical limits">
                    Users Outside the Geographical limits
                  </span>
                </li>
                <li>
                  <span eventKey="disclaimer" onClick={general2} title="Type of Information we collect">
                    Type of Information we collect
                  </span>
                </li>
                <li>
                  <span eventKey="disclaimer" onClick={general3} title="How We Use Your Personal Information">
                    How We Use Your Personal Information
                  </span>
                </li>
                <li>
                  <span eventKey="pvcStatement" onClick={general4} title="How Long Do We Store Your Personal Information?">
                    How Long Do We Store Your Personal Information?
                  </span>
                </li>
                <li>
                  <span eventKey="copyright" onClick={general5} title="Cookies and Session Data">
                    Cookies and Session Data
                  </span>
                </li>
                <li>
                  <span eventKey="agency" onClick={general6} title="Who Shall Have Access to Your Personal Information?">
                    Who Shall Have Access to Your Personal Information?
                  </span>
                </li>
                <li>
                  <span eventKey="confidentiality" onClick={general7} title="How Can You Opt-Out Of Receiving Our Promotional E-Mails?">
                    How Can You Opt-Out Of Receiving Our Promotional E-Mails?
                  </span>
                </li>
                <li>
                  <span eventKey="indemnity" onClick={general8} title="Permissions Required for Using Our Mobile Application">
                    Permissions Required for Using Our Mobile Application
                  </span>
                </li>
                <li>
                  <span eventKey="governingLaw" onClick={general9} title="How We Protect Your Personal Information?">
                    How We Protect Your Personal Information?
                  </span>
                </li>
                <li>
                  <span eventKey="forceMajeure" onClick={general10} title="Withdrawal of Consent and Permission">
                    Withdrawal of Consent and Permission
                  </span>
                </li>
                <li>
                  <span eventKey="variations" onClick={general11} title="Eligibility to Transact With TTG">
                    Eligibility to Transact With TTG
                  </span>
                </li>
                <li>
                  <span eventKey="indiv" onClick={general12} title="Changes to the Privacy Policy">
                    Changes to the Privacy Policy
                  </span>
                </li>
              </ul>
            </StickyBox>
          </Col>
          <Col span={18} className="tcLayout">
            <div ref={tcGeneral}>
              <ShowHide visible={false} icon="" title="Introduction">
                <div className="showHide-content">
                  <p>
                    The domain Clickntravel.com is owned and operated by Tawfeeq
                    Travel Company W.L..L (hereinafter “TTG”) recognizes the
                    importance of privacy of its users and of maintaining
                    confidentiality of the information provided by its users as
                    a responsible data controller and data processor.
                  </p>
                  <p>
                    This Privacy Policy provides for the practices for handling
                    and securing user's Personal Information (defined hereunder)
                    by TTG and its subsidiaries and affiliates.
                  </p>
                  <p>
                    This Privacy Policy is applicable to any person (‘User’) who
                    purchases, intends to purchase, or inquire about any
                    product(s) or service(s) made available by TTG through any
                    of TTG’s customer interface channels including its mobile
                    app and offline channels including call centers and offices
                    (collectively referred herein as "Sales Channels").
                  </p>
                  <p>
                    For the purpose of this Privacy Policy, wherever the context
                    so requires "you" or "“your" shall mean User and the term
                    "we", "us", "our" shall mean TTG. For the purpose of this
                    Privacy Policy.
                  </p>
                  <p>
                    By using or accessing the Sales Channels, the User hereby
                    agrees with the terms of this Privacy Policy and the
                    contents herein. If you disagree with this Privacy Policy,
                    please do not use or access our Sales Channels.
                  </p>
                  <p>
                    This Privacy Policy does not apply to any website(s), mobile
                    sites and mobile apps of third parties, even if their
                    websites/products are linked to any of our Sales Channel.
                    User should take note that information and privacy practices
                    of TTG’s business partners, advertisers, sponsors or other
                    sites to which TTG provides hyperlink(s), may be materially
                    different from this Privacy Policy. Accordingly, it is
                    recommended that you review the privacy statements and
                    policies of any such third parties with whom they interact.
                  </p>
                  <p>
                    This Privacy Policy is an integral part of your User
                    Agreement with TTG, and all capitalized terms used, but not
                    otherwise defined herein, shall have the respective meanings
                    as ascribed to them in the User Agreement.
                  </p>
                </div>
              </ShowHide>
            </div>
            <div ref={tc1}>
              <ShowHide
                visible="true"
                icon=""
                title="Users Outside the Geographical limits"
              >
                <div className="showHide-content">
                  <p>
                    Please note that the data shared with TTG shall be primarily
                    processed in Qatar and such other jurisdictions where a
                    third party engaged by TTG may process the data on its
                    behalf. By agreeing to this policy, you are providing TTG
                    with your explicit consent to process your personal
                    information for the purpose(s) defined in this policy. The
                    data protection regulations in Qatar or such other
                    jurisdictions mentioned above may differ from those of your
                    country of residence.
                  </p>
                  <p>
                    If you have any concerns in the processing your data and
                    wish to withdraw your consent, you may do so by writing to
                    the following email id: <a href="mailto:privacy@att.qa">privacy@att.qa</a>
                  </p>
                  <p>
                    However, if such processing of data is essential for us to
                    be able to provide a service(s) to you, then we may not be
                    able to serve or confirm your bookings after your withdrawal
                    of consent. For instance, if you make a booking (flight or
                    hotel or any other product offered), then certain personal
                    information of yours like contact details, gender, dietary
                    preferences, choice of room with smoking facility, any
                    medical condition which may require specific attention or
                    facility etc. may have to be shared by us with our vendors,
                    and they may further process this information for making
                    suitable arrangements for you during your trip.
                  </p>
                  <p>
                    A withdrawal of consent by you for us to process your
                    information may:
                    <ul style={{ listStyleType: "number" }}>
                      <li>
                        severely inhibit our ability to serve you properly and
                        in such case, we may have to refuse the booking
                        altogether, or
                      </li>
                      <li>
                        unreasonably restrict us to service your booking (if a
                        booking is already made) which may further affect your
                        trip or may compel us to cancel your booking.
                      </li>
                    </ul>
                  </p>
                </div>
              </ShowHide>
            </div>
            <div ref={tc2}>
              <ShowHide
                visible="true"
                icon=""
                title="Type of Information we collect"
              >
                <div className="showHide-content">
                  <p>
                    The information as detailed below is collected for us to be
                    able to provide the services chosen by you and also to
                    fulfill our legal obligations as well as our obligations
                    towards third parties as per our User Agreement.
                  </p>
                  <p>
                    <strong> "Personal Information"</strong> of User shall
                    include the information shared by the User and collected by
                    us for the following purposes:
                    <ul style={{ listStyleType: "number" }}>
                      <li>
                        <strong>Registration on the Sales channel:</strong>{" "}
                        Information which you provide while subscribing to or
                        registering on any of our sales channel, including but
                        not limited to information about your personal identity
                        such as name, gender, marital status, religion, age,
                        profile picture etc., your contact details such as your
                        email address, frequent flyer number and/or telephone
                        (mobile or otherwise). The information may also include
                        information such as your banking details (including
                        credit/debit card) and billing information payment
                        history etc. (as shared by you).
                      </li>
                      <li>
                        <strong>Other information:</strong> We many also collect
                        some other information and documents including but not
                        limited to:
                        <ul>
                          <li>
                            Transactional history (other than banking details)
                            about your e-commerce activities, buying behavior.
                          </li>
                          <li>
                            Your usernames, passwords, email addresses and other
                            security-related information used by you in relation
                            to our Services.
                          </li>
                          <li>
                            Data either created by you or by a third party and
                            which you wish to store on our servers such as image
                            files, documents etc.
                          </li>
                          <li>
                            Information pertaining any other traveler(s) for
                            whom you make a booking through your registered TTG
                            account. In such case, you must confirm and
                            represent that each of the other traveler(s) for
                            whom a booking has been made, has agreed to have the
                            information shared by you disclosed to us and
                            further be shared by us with the concerned service
                            provider(s).
                          </li>
                          <li>
                            In case you opt for contactless check-in at Hotels,
                            then copies of your government identification like
                            QID, driving license etc., Self-declaration and any
                            other information like date of birth,
                            destination/origin of travel and place of residence
                            that may be required by the concerned Hotel to honor
                            your hotel booking.
                          </li>
                          <li>
                            Such information shall be strictly used for the
                            aforesaid specified & lawful purpose only. User
                            further understands that TTG may share this
                            information with the end service provider or any
                            other third party for provision and facilitation of
                            the desired booking. In case a User does not wish to
                            provide this information or opts for deletion of the
                            information already provided, TTG may not be able to
                            process the desired booking request. TTG will never
                            share any of the above information collected
                            including QID card details, Passport details without
                            their prior consent unless otherwise such action is
                            required by any law enforcement authority for
                            investigation, by court order or in reference to any
                            legal process.
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </p>
                </div>
              </ShowHide>
            </div>
            <div ref={tc3}>
              <ShowHide
                visible="true"
                icon=""
                title="How We Use Your Personal Information"
              >
                <div className="showHide-content">
                  <p>
                    <strong>
                      The Personal Information collected maybe used in the
                      following manner:{" "}
                    </strong>
                  </p>
                  <ul>
                    <li>
                      <strong>While making a booking:</strong>
                      <p>
                        We may use Personal Information including, payment
                        details which include cardholder name, credit/debit card
                        number (in encrypted form) with expiration date, banking
                        details etc. as shared and allowed to be stored by you.
                        We may also use the information of travelers list as
                        available in or linked with your account. This
                        information is presented to the User at the time of
                        making a booking to enable you to complete your bookings
                        expeditiously.{" "}
                      </p>
                    </li>
                    <li>
                      <strong>
                        Further, your Personal Information can be used by us for
                        several reasons including but not limited to:{" "}
                      </strong>
                      <ul style={{ listStyleType: "number" }}>
                        <li>
                          confirm your reservations with respective service
                          providers;
                        </li>
                        <li>keep you informed of the transaction status;</li>
                        <li>
                          send booking confirmations either via Email, SMS or
                          WhatsApp;
                        </li>
                        <li>send any updates or changes to your booking(s);</li>
                        <li>
                          allow our customer service to contact you, if
                          necessary;
                        </li>
                        <li>customize the content of our mobile app;</li>
                        <li>
                          request for reviews of products or services or any
                          other improvements;
                        </li>
                        <li>
                          send verification message(s) or email(s) during
                          registration;
                        </li>
                        <li>
                          Validate/authenticate your account and to prevent any
                          misuse or abuse:
                        </li>
                        <li>Enrollment for any reward program.</li>
                      </ul>
                    </li>
                    <li>
                      <strong>
                        Marketing Promotions, Research and Programs:
                      </strong>
                      <ul style={{ listStyleType: "number" }}>
                        <li>
                          Marketing promotions, research and programs help us to
                          identify your preferences, develop programs and
                          improve user experience. Personal Information
                          collected by us for such activities may include
                          contact information and survey questions. We use such
                          Personal Information to notify contest winners and
                          survey information to develop promotions and product
                          improvements. As a registered User, you will also
                          occasionally receive updates from us about fare sales
                          in your area, special offers, new services, other
                          noteworthy items (like savings and benefits on
                          airfares, hotel reservations, holiday packages, car
                          rentals and other travel services) and marketing
                          programs.
                        </li>
                        <li>
                          In addition, you may look forward to receiving
                          periodic marketing emails, newsletters and exclusive
                          promotions offering special deals.
                        </li>
                        <li>
                          From time to time, we may add or enhance services
                          available on the app. To the extent these services are
                          provided, and used by you, we will use the Personal
                          Information you provide to facilitate the service(s)
                          requested.
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </ShowHide>
            </div>
            <div ref={tc4}>
              <ShowHide
                visible="true"
                icon=""
                title="How Long Do We Store Your Personal Information?"
              >
                <div className="showHide-content">
                  <p>
                    TTG will retain your Personal Information on its servers for
                    as long as is reasonably necessary for the purposes listed
                    in this policy. In some circumstances we may retain your
                    Personal Information for longer periods of time, for
                    instance where we are required to do so in accordance with
                    any legal, regulatory, tax or accounting requirements.
                  </p>
                  <p>
                    Where your personal data is no longer required, we will
                    ensure it is either securely deleted or stored in a way
                    which means it will no longer be used by the business.
                  </p>
                </div>
              </ShowHide>
            </div>
            <div ref={tc5}>
              <ShowHide visible="true" icon="" title="Cookies and Session Data">
                <div className="showHide-content">
                  <p>
                    Cookies are used to personalize your experience on the
                    Website and the advertisements that maybe displayed. TTG’s
                    use of cookies is similar to that of any other reputable
                    online companies.
                  </p>
                  <p>
                    Cookies are small pieces of information that are stored by
                    your browser on your device's hard drive. Cookies allow us
                    to serve you better and more efficiently. Cookies also allow
                    ease of access, by logging you in without having to type
                    your login name each time (only your password is needed); we
                    may also use such cookies to display any advertisement(s) to
                    you while you are on the app or to send you offers (or
                    similar emails – provided you have not opted out of
                    receiving such emails) focusing on destinations which may be
                    of your interest.
                  </p>
                </div>
              </ShowHide>
            </div>
            <div ref={tc6}>
              <ShowHide
                visible="true"
                icon=""
                title="Who Shall Have Access to Your Personal Information?"
              >
                <div className="showHide-content">
                  <p>
                    Your Personal Information will be shared with our Service
                    Providers and suppliers.
                  </p>
                  <p>
                    In addition to the circumstances described above, TTG may
                    disclose User's Personal Information if required;
                    <ul style={{ listStyleType: "number" }}>
                      <li>
                        by law, required by any enforcement authority for
                        investigation, by court order or in reference to any
                        legal process;
                      </li>
                      <li>to conduct our business;</li>
                      <li>
                        for regulatory, internal compliance and audit
                        exercise(s)
                      </li>
                      <li>to secure our systems; or</li>
                      <li>
                        to enforce or protect our rights or properties of TTG or
                        any or all of its affiliates, associates, employees,
                        directors or officers or when we have reason to believe
                        that disclosing Personal Information of User(s) is
                        necessary to identify, contact or bring legal action
                        against someone who may be causing interference with our
                        rights or properties, whether intentionally or
                        otherwise, or when anyone else could be harmed by such
                        activities.
                      </li>
                    </ul>
                  </p>

                  <p>
                    Such disclosure and storage may take place without your
                    knowledge. In that case, we shall not be liable to you or
                    any third party for any damages howsoever arising from such
                    disclosure and storage.
                  </p>
                </div>
              </ShowHide>
            </div>
            <div ref={tc7}>
              <ShowHide
                visible="true"
                icon=""
                title="How Can You Opt-Out Of Receiving Our Promotional E-Mails?"
              >
                <div className="showHide-content">
                  <p>
                    You will occasionally receive e-mail updates from us about
                    fare sales in your area, special offers, new TTG services,
                    and other noteworthy items. We hope you will find these
                    updates interesting and informative. If you wish not to
                    receive them, please click on the "unsubscribe" link or
                    follow the instructions in each e-mail message.
                  </p>
                </div>
              </ShowHide>
            </div>

            <div ref={tc8}>
              <ShowHide
                visible="true"
                icon=""
                title="Permissions Required for Using Our Mobile Application"
              >
                <div className="showHide-content">
                  <p>
                    When the TTG app is installed on your phone or tablet, a
                    list of permissions appear and are needed for the app to
                    function effectively. There is no option to customize the
                    list. The permissions that TTG requires and the data that
                    shall be accessed and its use is as below:
                    <ul style={{ listStyleType: "number" }}>
                      <li>
                        <strong>Android Permissions:</strong>
                        <ul>
                          <li>
                            <strong>Device & App history:</strong> We need your
                            device permission to get information about your
                            device, like OS (operating system) name, OS version,
                            mobile network, hardware model, unique device
                            identifier, preferred language, etc. Basis these
                            inputs, we intend to optimize your travel booking
                            experience, use OS specific capabilities to drive
                            great in-funnel experiences using components of
                            device’s OS, etc.
                          </li>
                          <li>
                            <strong>Location:</strong> This permission enables
                            us to give you the benefit of location specific
                            deals and provide you a personalized in-funnel
                            experience. We require this permission to recommend
                            you nearest hotels in case you are running late and
                            want to make a quick last-minute booking for the
                            nearest hotel. Your options are personalized basis
                            your locations. For international travel, this
                            enables us to determine your time zone and provide
                            information accordingly.
                          </li>
                          <li>
                            <strong>SMS:</strong> If you allow us to access your
                            SMS, we read your SMS to autofill or prepopulate
                            ‘OTP’ while making a transaction and to validate
                            your mobile number. This provides you a seamless
                            purchase experience while making a booking and you
                            don’t need to move out of the app to read the SMS
                            and then enter it in the app. SMS is also used to
                            show a customer upcoming PNR status confirmation.
                          </li>
                          <li>
                            <strong>Phone:</strong> The app requires access to
                            make phone calls so that you can make phone calls to
                            hotels, airlines and our customer contact centers
                            directly through the app.
                          </li>
                          <li>
                            <strong>Calendar:</strong> This permission enables
                            us to put your travel plan on your calendar.
                          </li>
                          <li>
                            <strong>Photo & Media:</strong> The access to your
                            camera is required to enable you to be able to
                            click, review and upload your profile photo.
                            Alternately, access to your photo library is
                            required for to enable you to upload an existing
                            photograph as a profile picture.
                          </li>
                        </ul>
                      </li>
                      <li>
                        <strong>IOS Permissions:</strong>
                        <ul>
                          <li>
                            <strong>Notifications</strong> If you opt in for
                            notifications, it enables us to send across
                            exclusive deals, promotional offers, travel related
                            updates, etc. on your device. If you do not opt for
                            this, updates for your travel like PNR status,
                            booking confirmation, refund (in case of
                            cancellation), etc. will be sent through SMS.
                          </li>
                          <li>
                            <strong>Location</strong> This permission enables us
                            to give you the benefit of location specific deals
                            and provide you a personalized in-funnel experience.
                            When you launch our app to make a travel booking, we
                            auto-detect your location so that your nearest
                            Airport or City is auto-filled. We require this
                            permission to recommend your nearest hotels in case
                            you are running late and want to make a quick
                            last-minute booking for the nearest hotel. Your
                            options are personalized basis your locations. For
                            international travel, this enables us to determine
                            your time zone and provide information accordingly.
                          </li>
                          <li>
                            <strong>Camera & Photo Library</strong> The access
                            to your camera is required to enable you to be able
                            to click, review and upload your profile photo.
                            Alternately, access to your photo library is
                            required for to enable you to upload an existing
                            photograph as a profile picture.
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </p>
                </div>
              </ShowHide>
            </div>
            <div ref={tc9}>
              <ShowHide
                visible="true"
                icon=""
                title="How We Protect Your Personal Information?"
              >
                <div className="showHide-content">
                  <p>
                    All payments on the Application are secured as we are not
                    storing any user card details and user will be redirected to
                    the payment getway.
                  </p>
                </div>
              </ShowHide>
            </div>
            <div ref={tc10}>
              <ShowHide
                visible="true"
                icon=""
                title="Withdrawal of Consent and Permission"
              >
                <div className="showHide-content">
                  <p>
                    You may withdraw your consent to submit any or all Personal
                    Information or decline to provide any permissions on its
                    application as covered above at any time. In case, you
                    choose to do so then your access to the Website may be
                    limited, or we might not be able to provide the services to
                    you. You may withdraw your consent by sending an email to
                    .
                    <a href="mailto:privacy@att.qa">privacy@att.qa</a>
                  </p>
                </div>
              </ShowHide>
            </div>
            <div ref={tc11}>
                <ShowHide
                  visible="true"
                  icon=""
                  title="Eligibility to Transact With TTG"
                >
                  <div className="showHide-content">
                    <p>
                      You must at least 18 years of age to transact directly
                      with TTG and also to consent to the processing of your
                      personal data.
                    </p>
                  </div>
                </ShowHide>
              </div>
            <div ref={tc12}>
              <ShowHide
                visible="true"
                icon=""
                title="Changes to the Privacy Policy"
              >
                <div className="showHide-content">
                  <p>
                    We reserve the rights to revise the Privacy Policy from time
                    to time to suit various legal, business and customer
                    requirement. We will duly notify the users as may be
                    necessary.
                  </p>
                  <p>
                    You may always submit concerns regarding this Privacy Policy
                    via email to us at <a href="mailto:privacy@att.qa">privacy@att.qa</a>. TTG shall endeavor to
                    respond to all reasonable concerns and inquires.
                  </p>
                </div>
              </ShowHide>
            </div>
          </Col>
        </Row>
      </div>
      <Footer />
    </>
  );
}

export default PrivacyPolicy;
