import React, { useEffect, useState } from "react";
import ResultSectionHeader from "./ResultSectionHeader";
import { Col, Drawer, Radio } from "antd";
import TripDetails from "./TripDetails";
import Link from "antd/es/typography/Link";
import FlightDetailsPopup from "./FlightDetailsPopup";
import { useContext } from "react";
import { Context } from "../../../../App";
function FlightMultiCityBlock(props) {
  const [context, setContext] = useContext(Context)
  const {
    searchRes,
    defaultOption,
    count,
    isChecked,
    showFlightOptions,
    flightOptions,
    setShowFlightOptions,
    mapping,
    sect,
    trip,
    changeFlight,
    sty,
    price,
    currency,
    sectors,
    serviceFee,
    openForMulti,
    onCloseMulti,
    secType,
    secTypeIndex,
    srequest,
    paxpr,
    regionType,
    showMultiDrawer,
    base_usr_exrate
  } = props;

  const defaultFltOne = sectors.srcorigns.filter((flt) => flt.ref === 1)
  const defaultFltTwo = sectors.srcorigns.filter((flt) => flt.ref === 2)
  const defaultFltThree = sectors.srcorigns.filter((flt) => flt.ref === 3)
  const defaultFltFour = sectors.srcorigns.filter((flt) => flt.ref === 4)
  const defaultFltFive = sectors.srcorigns.filter((flt) => flt.ref === 5)
  const defaultFltSix = sectors.srcorigns.filter((flt) => flt.ref === 6)

  const [selectedOne, setSelectedOne] = useState(defaultFltOne.length!==0&&defaultFltOne[0]);
  const [selectedTwo, setSelectedTwo] = useState(defaultFltTwo.length!==0&&defaultFltTwo[0]);
  const [selectedThree, setSelectedThree] = useState(defaultFltThree.length!==0&&defaultFltThree[0]);
  const [selectedFour, setSelectedFour] = useState(defaultFltFour.length!==0&&defaultFltFour[0]);
  const [selectedFive, setSelectedFive] = useState(defaultFltFive.length!==0&&defaultFltFive[0]);
  const [selectedSix, setSelectedSix] = useState(defaultFltSix.length!==0&&defaultFltSix[0]);

  useEffect(() => {
    sessionStorage.setItem("SELFLTONE", 0);
    sessionStorage.setItem("SELFLTTWO", 0);
    sessionStorage.setItem("SELFLTTHREE", 0);
    sessionStorage.setItem("SELFLTFOUR", 0);
    sessionStorage.setItem("SELFLTFIVE", 0);
    sessionStorage.setItem("SELFLTSIX", 0);
  }, []);

  const selectFlight = (count, index) => {
    if(count === 1) {
      const flt = defaultFltOne[index]
      setSelectedOne(flt);
      sessionStorage.setItem("SELFLTONE", index)
    } 
    if(count === 2) {
      const flt = defaultFltTwo[index]
      setSelectedTwo(flt);
      sessionStorage.setItem("SELFLTTWO", index)
    }
    if(count === 3) {
      const flt = defaultFltThree[index]
      setSelectedThree(flt);
      sessionStorage.setItem("SELFLTTHREE", index)
    }
    if(count === 4) {
      const flt = defaultFltFour[index]
      setSelectedFour(flt);
      sessionStorage.setItem("SELFLTFOUR", index)
    }
    if(count === 5) {
      const flt = defaultFltFive[index]
      setSelectedFive(flt);
      sessionStorage.setItem("SELFLTFIVE", index)
    }
    if(count === 6) {
      const flt = defaultFltSix[index]
      setSelectedSix(flt);
      sessionStorage.setItem("SELFLTSIX", index)
    }
  };

  return searchRes && searchRes.length > 0 && defaultOption ? (
    <>
      <ResultSectionHeader
        mapping={mapping}
        sectors={
          sect.length > 0 &&
          sect.filter((sec) => {
            return sec.leg === count;
          })
        }
        secType={"onward"}
        secTypeIndex={count}
        trip={trip}
        refund={defaultOption ? defaultOption.re : undefined}
      />
      <div className="rb-mid">
            <TripDetails
              flt={defaultOption}
              seg={defaultOption.seg[0]}
              sty={sty}
              price={price}
              currency={currency}
              sectors={sectors}
              serviceFee={serviceFee}
              mapping={mapping}
            />
      </div>
      <div className="rb-bot">
        {defaultOption && (
          <div className="dFlex flightDetails">
            {count === 1 && count !== 3 && count !== 4 && count !== 5 && count !== 6 && (
              <Link onClick={(e) => showMultiDrawer(e, count-1)} className="multi">
                {context.translation && context.translation.flgtDetls}
              </Link>
            )}
            {count !== 1 && count === 3 && count !== 4 && count !== 5 && count !== 6 && (
              <Link onClick={(e) => showMultiDrawer(e, count-1)} className="multi">
                {context.translation && context.translation.flgtDetls}
              </Link>
            )}
            {count !== 1 && count !== 3 && count === 4 && count !== 5 && count !== 6 && (
              <Link onClick={(e) => showMultiDrawer(e, count-1)} className="multi">
                {context.translation && context.translation.flgtDetls}
              </Link>
            )}
            {count !== 1 && count !== 3 && count !== 4 && count === 5 && count !== 6 && (
              <Link onClick={(e) => showMultiDrawer(e, count-1)} className="multi">
                {context.translation && context.translation.flgtDetls}
              </Link>
            )}
            {count !== 1 && count !== 3 && count !== 4 && count !== 5 && count === 6 && (
              <Link onClick={(e) => showMultiDrawer(e, count-1)} className="multi">
                {context.translation && context.translation.flgtDetls}
              </Link>
            )}
            <Drawer
              placement="right"
              open={openForMulti(count)}
              active={"tab1"}
              className="paxDetailsPopup"
            >
              <i
                className="icon icon-cancel"
                onClick={(e) => onCloseMulti(e, count)}
              ></i>
              {count === 1 && (
                <FlightDetailsPopup
                  flt={defaultOption}
                  seg={defaultOption.seg[0]}
                  sty={sty}
                  price={price}
                  currency={currency}
                  sectors={sect}
                  serviceFee={serviceFee}
                  mapping={mapping}
                  secType={secType}
                  secTypeIndex={secTypeIndex}
                  trip={trip}
                  req={srequest}
                  paxpr={paxpr}
                  regionType={regionType}
                  pr={sectors.pr}
                  refund={defaultOption ? defaultOption.re : undefined}
                  open={openForMulti(1)}
                  sf={serviceFee}
                  flow={"SRPMULTI"}
                  base_usr_exrate={base_usr_exrate}
                  selected={[count === 1 && selectedOne,
                    count === 1 && selectedTwo,
                    count === 1 && selectedThree,
                    count === 1 && selectedFour,
                    count === 1 && selectedFive,
                    count === 1 && selectedSix]}
                />                
              )}
              {count === 2 && (
                <FlightDetailsPopup
                  flt={defaultOption}
                  seg={defaultOption.seg[0]}
                  sty={sty}
                  price={price}
                  currency={currency}
                  sectors={sect}
                  serviceFee={serviceFee}
                  mapping={mapping}
                  secType={secType}
                  secTypeIndex={secTypeIndex}
                  trip={trip}
                  req={srequest}
                  paxpr={paxpr}
                  regionType={regionType}
                  pr={sectors.pr}
                  refund={defaultOption ? defaultOption.re : undefined}
                  open={openForMulti(1)}
                  sf={serviceFee}
                  flow={"SRPMULTI"}
                  base_usr_exrate={base_usr_exrate}
                  selected={[count === 2 && defaultFltOne[sessionStorage.getItem("SELFLTONE")],
                    count === 2 && selectedTwo,
                    count === 2 && selectedThree,
                    count === 2 && selectedFour,
                    count === 2 && selectedFive,
                    count === 2 && selectedSix]}
                />                
              )}
              {count === 3 && (
                <FlightDetailsPopup
                  flt={defaultOption}
                  seg={defaultOption.seg[0]}
                  sty={sty}
                  price={price}
                  currency={currency}
                  sectors={sect}
                  serviceFee={serviceFee}
                  mapping={mapping}
                  secType={secType}
                  secTypeIndex={secTypeIndex}
                  trip={trip}
                  req={srequest}
                  paxpr={paxpr}
                  regionType={regionType}
                  pr={sectors.pr}
                  refund={defaultOption ? defaultOption.re : undefined}
                  open={openForMulti(1)}
                  sf={serviceFee}
                  flow={"SRPMULTI"}
                  base_usr_exrate={base_usr_exrate}
                  selected={[count === 3 && defaultFltOne[sessionStorage.getItem("SELFLTONE")],
                    count === 3 && defaultFltTwo[sessionStorage.getItem("SELFLTTWO")],
                    count === 3 && selectedThree,
                    count === 4 && selectedFour,
                    count === 5 && selectedFive,
                    count === 6 && selectedSix]}
                />                
              )}
              {count === 4 && (
                <FlightDetailsPopup
                  flt={defaultOption}
                  seg={defaultOption.seg[0]}
                  sty={sty}
                  price={price}
                  currency={currency}
                  sectors={sect}
                  serviceFee={serviceFee}
                  mapping={mapping}
                  secType={secType}
                  secTypeIndex={secTypeIndex}
                  trip={trip}
                  req={srequest}
                  paxpr={paxpr}
                  regionType={regionType}
                  pr={sectors.pr}
                  refund={defaultOption ? defaultOption.re : undefined}
                  open={openForMulti(1)}
                  sf={serviceFee}
                  flow={"SRPMULTI"}
                  base_usr_exrate={base_usr_exrate}
                  selected={[count === 4 && defaultFltOne[sessionStorage.getItem("SELFLTONE")],
                    count === 4 && defaultFltTwo[sessionStorage.getItem("SELFLTTWO")],
                    count === 4 && defaultFltThree[sessionStorage.getItem("SELFLTTHREE")],
                    count === 4 && selectedFour,
                    count === 4 && selectedFive,
                    count === 4 && selectedSix]}
                />
              )}
              {count === 5 && (
                <FlightDetailsPopup
                  flt={defaultOption}
                  seg={defaultOption.seg[0]}
                  sty={sty}
                  price={price}
                  currency={currency}
                  sectors={sect}
                  serviceFee={serviceFee}
                  mapping={mapping}
                  secType={secType}
                  secTypeIndex={secTypeIndex}
                  trip={trip}
                  req={srequest}
                  paxpr={paxpr}
                  regionType={regionType}
                  pr={sectors.pr}
                  refund={defaultOption ? defaultOption.re : undefined}
                  open={openForMulti(1)}
                  sf={serviceFee}
                  flow={"SRPMULTI"}
                  base_usr_exrate={base_usr_exrate}
                  selected={[count === 5 && defaultFltOne[sessionStorage.getItem("SELFLTONE")],
                    count === 5 && defaultFltTwo[sessionStorage.getItem("SELFLTTWO")],
                    count === 5 && defaultFltThree[sessionStorage.getItem("SELFLTTHREE")],
                    count === 5 && defaultFltFour[sessionStorage.getItem("SELFLTFOUR")],
                    count === 5 && selectedFive,
                    count === 5 && selectedSix]}
                />
              )}
              {count === 6 && (
                <FlightDetailsPopup
                  flt={defaultOption}
                  seg={defaultOption.seg[0]}
                  sty={sty}
                  price={price}
                  currency={currency}
                  sectors={sect}
                  serviceFee={serviceFee}
                  mapping={mapping}
                  secType={secType}
                  secTypeIndex={secTypeIndex}
                  trip={trip}
                  req={srequest}
                  paxpr={paxpr}
                  regionType={regionType}
                  pr={sectors.pr}
                  refund={defaultOption ? defaultOption.re : undefined}
                  open={openForMulti(1)}
                  sf={serviceFee}
                  flow={"SRPMULTI"}
                  base_usr_exrate={base_usr_exrate}
                  selected={[count === 6 && defaultFltOne[sessionStorage.getItem("SELFLTONE")],
                    count === 6 && defaultFltTwo[sessionStorage.getItem("SELFLTTWO")],
                    count === 6 && defaultFltThree[sessionStorage.getItem("SELFLTTHREE")],
                    count === 6 && defaultFltFour[sessionStorage.getItem("SELFLTFOUR")],
                    count === 6 && defaultFltFive[sessionStorage.getItem("SELFLTFIVE")],
                    count === 6 && selectedSix]}
                />
              )}
            </Drawer>
            {searchRes && searchRes.length > 1 && (
              <Link
                onClick={(e) => setShowFlightOptions(!showFlightOptions)}
                className={"multicityFlightOptions " + (showFlightOptions ? "active" : "")}
              >
                {!showFlightOptions && searchRes && searchRes.length}
                {showFlightOptions ? (context.translation && context.translation.hide) : ""} {context.translation && context.translation.flgtOpt}
              </Link>
            )}
          </div>
        )}
      </div>
      <div className="moreFlightOptions">
        {parseInt(trip) === 3 && (
          <>
            {showFlightOptions &&
              flightOptions &&
              flightOptions.length > 0 &&
              flightOptions.map((flt, index) => (
                <div className={flt.class} key={index}>
                  <Col onClick={(e) => {changeFlight(count, flt); selectFlight(count, index)}}>
                    <TripDetails
                      flt={flt}
                      seg={flt.seg[0]}
                      sty={sty}
                      price={price}
                      currency={currency}
                      sectors={sectors}
                      serviceFee={serviceFee}
                      mapping={mapping}
                    />
                  </Col>
                </div>
              ))}
          </>
        )}
      </div>
    </>
  ) : null;
}

export default FlightMultiCityBlock;
