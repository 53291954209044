import React, {
  useContext,
  useEffect,
  useState,
  useReducer,
  useLayoutEffect,
} from "react";
import {
  Alert,
  Row,
  Col,
  Drawer,
  Checkbox,
  Typography,
  Button,
  Form,
  Input,
  Select,
  Modal,
  Space,
} from "antd";
import Link from "antd/es/typography/Link";
import Month from "../../../common/Month";
import Years from "../../../common/Years";
import Days from "../../../common/Days";
import CntCommonService from "../../../../services/common/CntCommonService";
import AuthenticationPopUp from "../../../common/AuthenticationPopUp";
import {Context } from "../../../../App";
import AddBaggage from "./extra/AddBaggage";
import AddMeal from "./extra/AddMeal";
import SeatSelection from "./SeatSelection";
import { isArray } from "lodash";
import Utilities from "../../../utilities/Utilities";

let stdCodes = require("../common/masterData/CountrySTDCodes.json");
let countrieslist = require("../common/masterData/CountriesList.json");
let seatPrefs = require("../common/masterData/SeatPreference.json");
let specialPrefs = require("../common/masterData/SpecialAssistance.json");
let mealPrefs = require("../common/masterData/MealPreference.json");
let airportsData = require("../common/masterData/AirlinesMasterData.json");
const { Title } = Typography;
const { Option } = Select;

//=====================================
// Reducer function for userReact Hook
// which will update the state
//=====================================
const reducer = (state, action) => {
  switch (action.type) {
    default:
      return { ...state, [action.type]: action.payload };
  }
};
/**
 * Initial State Declaration
 */
const initialState = {
  chds: 0,
  infs: 0,
  doc: "Yes",
  doct: "P",
  passengers: [],
  countriesMenu: [],
  mealPrefs: "",
  spPrefs: "",
  seatPrefs: "",
  airlines: [],
  selTraveller: "",
  adtList: [],
  chdList: [],
  infList: [],
  enableImport: false,
  passportEnable: false,
  flowType: "",
  title: [],
  enbalePPInfo: false,
  docMandatory: "",
  infTitles: [],
  baggageOptions: [],
  baggageReturnOptions: [],
  mealOptions: [],
  bOwname: "",
  bRtname: "",
  owbagTid: "",
  rtBagTid: "",
  enableBagOption: false,
  cheked: false,
  mealReturnOptions: [],
  passengerUniqueKey: [],
  alJazeeraBooking: false,
  enableFields: true,
  onwardAJbaggage: false,
  returnAJbaggage: false,
  owmealTid: "",
  rtmealTid: "",
  deptDate: "",
  depYear: 0,
  childDeptDate: "",
  adtTraList: [],
  chdTraList: [],
  infTraList: [],
  airarabiairtn: false,
};

function TravellerDetails(props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [signin, setSignin] = useState(true);
  const [visible, setVisible] = useState(false);
  const [resp, setResponse] = useState();
  const [adts, setAdt] = useState(props.srequest.adt);
  const [chds, setChd] = useState(props.srequest.chd);
  const [infs, setInf] = useState(props.srequest.inf);
  const [context, setContext] = useContext(Context);
  const [showBaggage, setShowBaggage] = useState(false);
  const [showMeals, setShowMeals] = useState(false);
  const [selectedIndexes, setSelectedIndexes] = useState([]); 


  const baggageTab = () => {
    setShowBaggage(true);
    setShowMeals(false);
  };

  const mealsTab = () => {
    setShowBaggage(false);
    setShowMeals(true);
  };

  const [adt_titles, setAdtTitles] = useState([
    { value: "Mr", label: "Mr" },
    { value: "Miss", label: "Miss" },
    { value: "Mrs", label: "Mrs" },
    { value: "Ms", label: "Ms" },
  ]);
  const [adt_titlesar, setAdtTitlesar] = useState([
    { value: "سيد", label: "سيد" },
    { value: "آنسة", label: "آنسة" },
    { value: "سيدة", label: "سيدة" },
  ]);
  const [chd_titles, setChdTitles] = useState([
    { value: "Miss", label: "Miss" },
    { value: "Mstr", label: "Mstr" },
    { value: "Ms", label: "Ms" },
  ]);
  const [chd_titlesar, setChdTitlesar] = useState([
    { value: "Miss", label: "آنسة" },
    { value: "Mstr", label: "السيد" },
  ]);
  const [inf_titles, setInfTitles] = useState([
    { value: "Miss", label: "Miss" },
    { value: "Mstr", label: "Mstr" },
  ]);
  const [inf_titlesar, setInfTitlesar] = useState([
    { value: "Miss", label: "آنسة" },
    { value: "Mstr", label: "السيد" },
  ]);
  const [selectedFlightOW, setSelectedFlightOW] = useState();
  const [selectedFlightRT, setSelectedFlightRT] = useState();
  const [multiSelectOne, setMultiSelectOne] = useState();
  const [multiSelectTwo, setMultiSelectTwo] = useState();
  const [multiSelectThree, setMultiSelectThree] = useState();
  const [multiSelectFour, setMultiSelectFour] = useState();
  const [multiSelectFive, setMultiSelectFive] = useState();
  const [multiSelectSix, setMultiSelectSix] = useState();

  useEffect(() => {
    createPaxList();
    createAirlines();
  }, []);


  const createAirlines=()=>{
    let airlinesList = []
    if (Number(props.srequest.tt) === 1) {
      if (props.srequest.origns[0] !== undefined && props.srequest.origns[0].srcorigns[0] !== undefined){
        let airlines = props.srequest.origns[0].srcorigns[0].seg.map(q => q.ac);
        let airs = airlines.filter((q, idx) => airlines.indexOf(q) === idx)
        if (airs) {
          for (let value of airs) {
            if (airportsData.filter((obj) => obj.value === value)) {
              var name = airportsData.filter((obj) => obj.value === value);
              airlinesList.push({ label: name[0]?.label, value: name[0]?.value });
            }
          }
        }
      }
      dispatch({ type: 'airlines', payload: airlinesList })
    } else if (Number(props.srequest.tt) === 2) {
      let finailAirlines = [];
      if (props.srequest.origns[0] !== undefined && props.srequest.origns[0].srcorigns[0] !== undefined
        && props.srequest.origns[0] !== undefined && props.srequest.origns[0].destorigins[0] !== undefined) {
        let onairlines = props.srequest.origns[0].srcorigns[0].seg.map(q => q.ac);
        let onairs = onairlines.filter((q, idx) => onairlines.indexOf(q) === idx)
        finailAirlines.push(onairs);
        let reairlines = props.srequest.origns[0].destorigins[0].seg.map(q => q.ac);
        let reairs = reairlines.filter((q, idx) => reairlines.indexOf(q) === idx)
        finailAirlines.push(reairs);
        let finalList = finailAirlines.map(q => q[0]);
        let airsList = finalList.filter((q, idx) => finalList.indexOf(q) === idx)
        if (finalList) {
          for (let val of finalList) {
            if (airportsData.filter((obj) => obj.value === val)) {
              var name = airportsData.filter((obj) => obj.value === val);
              if(airlinesList.length===0){
                airlinesList.push({ label: name[0]?.label, value: name[0]?.value });
              }else{
                let list = airlinesList.filter((obj) => obj.value === val);
                if(list.length==0){
                  airlinesList.push({ label: name[0]?.label, value: name[0]?.value });
                }
              }
            }
          }
        }
        dispatch({ type: 'airlines', payload: airlinesList })
      }
    } else if (Number(props.srequest.tt) === 3) {
      if (props.srequest.origns[0] !== undefined && props.srequest.origns[0].srcorigns !== undefined) {
        for (let value of props.srequest.origns[0].srcorigns) {
          let airlines = value.seg.map(q => q.ac);
          let airs = airlines.filter((q, idx) => airlines.indexOf(q) === idx)
          if (airs) {
            for (let value of airs) {
              if (airportsData.filter((obj) => obj.value === value)) {
                var name = airportsData.filter((obj) => obj.value === value);
                airlinesList.push({ label: name[0]?.label, value: name[0]?.value });
              }
            }
          }
        }
      }
      const uniqueAirlinesList = airlinesList.filter((obj, index) => {
          return index === airlinesList.findIndex(o => obj.label === o.label);
      });
      dispatch({ type: 'airlines', payload: uniqueAirlinesList })
    }
  }

  
  useEffect(() => {
    if (props.fareresp !== undefined && props.fareresp.data != undefined && props.fareresp.data[0] !== undefined) {
      dispatch({ type: 'docMandatory', payload: props.fareresp.data[0].doc })
      if (props.fareresp.data[0].mand !== undefined && props.fareresp.data[0].mand[0] !== undefined) {
        if(props.fareresp.data[0].sup == undefined) {
        if (props.srequest.sty === "normal") {
          if (props.srequest.tt === "1") {
            if(props.fareresp.data[0].mand[0].bag!==undefined && props.fareresp.data[0].mand[0].bag.length!==0){
            let bagList = props.fareresp.data[0].mand[0].bag.filter((bag) => (bag.nm === "LuggageOptions" || bag.nm === "OutwardLuggageOptions") );
            let mealList = props.fareresp.data[0].mand[0].bag.filter((bag) => bag.nm === "OutwardMealOptions");
            if(bagList.length!==0 && bagList[0]!==undefined){
              dispatch({ type: 'bOwname', payload: bagList[0].nm })
              dispatch({ type: 'baggageOptions', payload: convertLabelUsrPrice(bagList[0].optins)})
            }
            if(mealList.length !== 0 && mealList[0] !== undefined) {
              dispatch({ type: 'mealOptions', payload: mealList[0].optins })
            }
          }
          } else {
            if(props.fareresp.data[0].mand[0].bag!==undefined && props.fareresp.data[0].mand[0].bag.length!==0){
            let bagOnwardList = props.fareresp.data[0].mand[0].bag.filter((bag) => bag.nm === "OutwardLuggageOptions");
            let bagRetunList = props.fareresp.data[0].mand[0].bag.filter((bag) => bag.nm === "ReturnLuggageOptions");
            let mealOnward = props.fareresp.data[0].mand[0].bag.filter((bag) => bag.nm === "OutwardMealOptions");
            let mealReturn = props.fareresp.data[0].mand[0].bag.filter((bag) => bag.nm === "ReturnMealOptions");
            if (bagOnwardList.length!==0 && bagOnwardList[0] !== undefined && bagOnwardList[0].optins !== undefined) {
              dispatch({ type: 'baggageOptions', payload: convertLabelUsrPrice(bagOnwardList[0].optins)})
              dispatch({ type: 'bOwname', payload: bagOnwardList[0].nm })
            }
            if (bagRetunList.length!==0 && bagRetunList[0] !== undefined && bagRetunList[0].optins !== undefined) {
              dispatch({ type: 'baggageReturnOptions', payload: convertLabelUsrPrice(bagRetunList[0].optins)})
              dispatch({ type: 'bRtname', payload: bagRetunList[0].nm })
            }
            if (mealOnward !== undefined && mealOnward[0] !== undefined && mealOnward[0].optins !== undefined) {
              dispatch({ type: 'mealOptions', payload: mealOnward[0].optins })
            }
            if (mealReturn !== undefined && mealReturn[0] !== undefined && mealReturn[0].optins !== undefined) {
              dispatch({ type: 'mealReturnOptions', payload: mealReturn[0].optins })
            }
            if (bagOnwardList.length === 0 && bagRetunList.length === 0) {
              let onwardList = props.fareresp.data[0].mand[0].bag.filter((bag) => bag.nm === "LuggageOptions");
              if(onwardList.length!==0){
                dispatch({ type: 'baggageOptions', payload: convertLabelUsrPrice(onwardList[0].optins)})
                dispatch({ type: 'bOwname', payload: onwardList[0].nm })
                dispatch({ type: 'enableBagOption', payload: true })
              }
            }
          }
        }
        } else {
          let onwardList = props.fareresp.data[0].mand.filter((type) => type.tid === props.srequest.origns[0].srcorigns[0].tid);
          let retunList = props.fareresp.data[0].mand.filter((type) => type.tid === props.srequest.origns[0].destorigins[0].tid);
          let bagAJonwardList = onwardList[0] !== undefined && onwardList[0].bag.length !== 0 && onwardList[0].bag.filter((bag) => bag.nm === "OutwardAJLuggageOptions");
          let bagAJreturnList = retunList[0] !== undefined && retunList[0].bag.length !== 0 && retunList[0].bag.filter((bag) => bag.nm === "ReturnAJLuggageOptions");
          // previous code 
          // let mealAJonwardList = onwardList[0] !== undefined && onwardList[0].bag.length !== 0 && onwardList[0].bag.filter((bag) => bag.nm === "MEALOPTIONS" && bag.jtype == "O");
          // let mealAJreturnList = retunList[0] !== undefined && retunList[0].bag.length !== 0 && retunList[0].bag.filter((bag) => bag.nm === "MEALOPTIONS" && bag.jtype == "R");

          // new code
          let bagreturnList = retunList[0] !== undefined && retunList[0].bag.length !== 0 && retunList[0].bag.filter((bag) => bag.nm === "ReturnLuggageOptions");
          let mealAJonwardListO = onwardList[0] !== undefined && onwardList[0].bag.length !== 0 && onwardList[0].bag.filter((bag) => bag.nm === "MEALOPTIONS" && bag.jtype == "O");
          let mealAJreturnListR = retunList[0] !== undefined && retunList[0].bag.length !== 0 && retunList[0].bag.filter((bag) => bag.nm === "MEALOPTIONS" && bag.jtype == "R");
          let mealAJonwardList;
          let mealAJreturnList;
          if(mealAJonwardListO.length === 0) {
            mealAJonwardList = onwardList[0] !== undefined && onwardList[0].bag.length !== 0 && onwardList[0].bag.filter((bag) => bag.nm === "MEALOPTIONS" || bag.nm === "OutwardMealOptions");
          } else {
            mealAJonwardList = onwardList[0] !== undefined && onwardList[0].bag.length !== 0 && onwardList[0].bag.filter((bag) => bag.nm === "MEALOPTIONS" && bag.jtype == "O");
          }
          if(mealAJreturnListR.length === 0) {
            mealAJreturnList = retunList[0] !== undefined && retunList[0].bag.length !== 0 && retunList[0].bag.filter((bag) => bag.nm === "MEALOPTIONS" || bag.nm === "OutwardMealOptions");
          } else {
            mealAJreturnList = retunList[0] !== undefined && retunList[0].bag.length !== 0 && retunList[0].bag.filter((bag) => bag.nm === "MEALOPTIONS" && bag.jtype == "R");
          }
          let bagOnwardList = onwardList[0] !== undefined && onwardList[0].bag.length !== 0 && onwardList[0].bag.filter((bag) => (bag.nm === "LuggageOptions" || bag.nm === "OutwardLuggageOptions"));
          let bagRetunList = retunList[0] !== undefined && retunList[0].bag.length !== 0 && retunList[0].bag.filter((bag) => (bag.nm === "LuggageOptions" || bag.nm === "OutwardLuggageOptions"));
          let mealOnward  = undefined
          let mealReturn = undefined
          if(props.fareresp.data[0].mand.filter(s=>s.sup == 4)) {
            if(onwardList.length > 0) {
            mealOnward = onwardList[0].bag.filter((bag) => bag.nm === "OutwardMealOptions");
            if(mealOnward && mealOnward.length > 0) {
              dispatch({type:"owmealTid",payload:onwardList[0].tid})
            }
          }
          if(retunList.length > 0) {
            mealReturn = retunList[0].bag.filter((bag) => bag.nm === "ReturnMealOptions");
            if(mealReturn && mealReturn.length > 0) {
              dispatch({type:"rtmealTid",payload:retunList[0].tid})
            }
          }
          } else {
            if(props.fareresp.data[0].mand && props.fareresp.data[0].mand.length > 0) {
             mealOnward = props.fareresp.data[0].mand[0].bag.filter((bag) => bag.nm === "OutwardMealOptions");
             mealReturn = props.fareresp.data[0].mand[0].bag.filter((bag) => (bag.nm === "ReturnMealOptions"));
          }
        }

          if(bagAJonwardList !== undefined&& bagAJonwardList[0] !== undefined && bagAJonwardList[0].optins !== undefined) {
            dispatch({ type: 'baggageOptions', payload: convertLabelUsrPrice(bagAJonwardList[0].optins)})
            dispatch({ type: 'bOwname', payload: bagAJonwardList[0].nm })
            dispatch({ type: 'owbagTid', payload: onwardList[0].tid })
            dispatch({type:"onwardAJbaggage",payload:true})
          }
          if(bagAJreturnList !== undefined&& bagAJreturnList[0] !== undefined && bagAJreturnList[0].optins !== undefined) {
            dispatch({ type: 'baggageReturnOptions', payload: convertLabelUsrPrice(bagAJreturnList[0].optins)})
            dispatch({ type: 'bRtname', payload: bagAJreturnList[0].nm })
            dispatch({ type: 'rtBagTid', payload: retunList[0].tid })
            dispatch({type:"returnAJbaggage",payload:true})
          }
          if(bagreturnList !== undefined&& bagreturnList[0] !== undefined && bagreturnList[0].optins !== undefined) {
            dispatch({ type: 'baggageReturnOptions', payload: convertLabelUsrPrice(bagreturnList[0].optins)})
            dispatch({ type: 'bRtname', payload: bagreturnList[0].nm })
            dispatch({ type: 'rtBagTid', payload: retunList[0].tid })
          }
          if(mealAJonwardList !== undefined&& mealAJonwardList[0] !== undefined && mealAJonwardList[0].optins !== undefined) {
            dispatch({ type: 'mealOptions', payload: mealAJonwardList })
            dispatch({type:"alJazeeraBooking",payload:true})
            if(props.srequest?.origns[0]?.srcorigns[0]?.sup == 3) {
              props.fareresp.data[0].sup = 3
            }
            dispatch({type:"owmealTid",payload:mealAJonwardList[0].tid})
          }
          if(mealAJreturnList !== undefined&& mealAJreturnList[0] !== undefined && mealAJreturnList[0].optins !== undefined) {
            
            dispatch({ type: 'mealReturnOptions', payload: mealAJreturnList })
            dispatch({type:"alJazeeraBooking",payload:true})
            if(props.srequest?.origns[0]?.destorigins[0]?.sup == 3) {
              props.fareresp.data[0].sup = 3
            }
            dispatch({type:"rtmealTid",payload:mealAJreturnList[0].tid})
          }
          if (bagOnwardList !== undefined && bagOnwardList[0] !== undefined && bagOnwardList[0].optins !== undefined) {
            dispatch({ type: 'baggageOptions', payload: convertLabelUsrPrice(bagOnwardList[0].optins) })
            dispatch({ type: 'bOwname', payload: bagOnwardList[0].nm })
            dispatch({ type: 'owbagTid', payload: onwardList[0].tid })
          }
          if (bagRetunList !== undefined && bagRetunList[0] !== undefined && bagRetunList[0].optins !== undefined) {
            dispatch({ type: 'baggageReturnOptions', payload: convertLabelUsrPrice(bagRetunList[0].optins)})
            dispatch({ type: 'bRtname', payload: bagRetunList[0].nm })
            dispatch({ type: 'rtBagTid', payload: retunList[0].tid })
          }
          if (mealOnward !== undefined && mealOnward[0] !== undefined && mealOnward[0].optins !== undefined) {
            dispatch({ type: 'mealOptions', payload: mealOnward[0].optins })
            dispatch({type:"alJazeeraBooking",payload:false})
          }
          if (mealReturn !== undefined && mealReturn[0] !== undefined && mealReturn[0].optins !== undefined) {
            
            dispatch({ type: 'mealReturnOptions', payload: mealReturn[0].optins })
            dispatch({type:"alJazeeraBooking",payload:false})
          }
        }
      } else {
         //********************************************AL JAZEERA FLOW HANDLING *********************************************/
        if(props.fareresp.data[0].sup == 3 || props.fareresp.data[0].sup == 4 || props.fareresp.data[0].sup == 7) {
          dispatch({type:"alJazeeraBooking",payload:true})
          if(props.srequest.sty === "normal") {
            if (props.srequest.tt === "1") {
              //one way
            if(props.fareresp.data[0].sup == 3){//For Jazzara
              let bagList = props.fareresp.data[0].mand[0].bag.filter((bag) => bag.nm === "OutwardAJLuggageOptions");
              if (bagList.length !== 0 && bagList[0] !== undefined) {
                dispatch({ type: 'bOwname', payload: bagList[0].nm})
                dispatch({ type: 'baggageOptions', payload: convertLabelUsrPrice(bagList[0].optins)})
                }
            }else{//For Indigo
              let bagList = props.fareresp.data[0].mand[0].bag.filter((bag) => (bag.nm === "LuggageOptions" || bag.nm === "OutwardLuggageOptions") )
              if(bagList.length!==0 && bagList[0]!==undefined){
                dispatch({ type: 'bOwname', payload: bagList[0].nm })
                dispatch({ type: 'baggageOptions', payload: convertLabelUsrPrice(bagList[0].optins)})
              }
            }
            let mealList = props.fareresp.data[0].mand[0].bag.filter((bag) => bag.nm === "MEALOPTIONS");
            if (mealList.length !== 0 && mealList[0] !== undefined) {

              dispatch({ type: 'mealOptions', payload:  mealList })
            }
            } else {
              if(props.fareresp.data[0].sup == 3){//For Jazzara
                let bagOnwardList = props.fareresp.data[0].mand[0].bag.filter((bag) => bag.nm === "OutwardAJLuggageOptions");
                let bagRetunList = props.fareresp.data[0].mand[0].bag.filter((bag) => bag.nm === "ReturnAJLuggageOptions");
                if (bagOnwardList[0] !== undefined && bagOnwardList[0] !== undefined && bagOnwardList[0].optins !== undefined) {
                  dispatch({ type: 'baggageOptions', payload: convertLabelUsrPrice(bagOnwardList[0].optins)})
                  dispatch({ type: 'bOwname', payload: bagOnwardList[0].nm })
                }
                if (bagRetunList[0] !== undefined && bagRetunList[0] !== undefined && bagRetunList[0].optins !== undefined) {
                  dispatch({ type: 'baggageReturnOptions', payload: convertLabelUsrPrice(bagRetunList[0].optins)})
                  dispatch({ type: 'bRtname', payload: bagRetunList[0].nm })
                }
              }else{//For Indigo
                let bagOnwardList = props.fareresp.data[0].mand[0].bag.filter((bag) => bag.nm === "OutwardLuggageOptions");
                let bagRetunList = props.fareresp.data[0].mand[0].bag.filter((bag) => bag.nm === "ReturnLuggageOptions");
                if (bagOnwardList.length!==0 && bagOnwardList[0] !== undefined && bagOnwardList[0].optins !== undefined) {
                  dispatch({ type: 'baggageOptions', payload: convertLabelUsrPrice(bagOnwardList[0].optins)})
                  dispatch({ type: 'bOwname', payload: bagOnwardList[0].nm })
                }
                if (bagRetunList.length!==0 && bagRetunList[0] !== undefined && bagRetunList[0].optins !== undefined){
                  dispatch({ type: 'baggageReturnOptions', payload: convertLabelUsrPrice(bagRetunList[0].optins)})
                  dispatch({ type: 'bRtname', payload: bagRetunList[0].nm })
                }
                if (bagOnwardList.length === 0 && bagRetunList.length === 0) {
                  let onwardList = props.fareresp.data[0].mand[0].bag.filter((bag) => bag.nm === "LuggageOptions");
                  if(onwardList.length!==0){
                    dispatch({ type: 'baggageOptions', payload:convertLabelUsrPrice(onwardList[0].optins)})
                    dispatch({ type: 'bOwname', payload: onwardList[0].nm })
                    dispatch({ type: 'enableBagOption', payload: true })
                  }
                }
              }
              let mealList = props.fareresp.data[0].mand[0].bag.filter((bag) => bag.nm === "MEALOPTIONS" && bag.jtype == "O");
              let mealReturn = props.fareresp.data[0].mand[0].bag.filter((bag) => bag.nm === "MEALOPTIONS"&& bag.jtype == "R");
              if (mealList.length !== 0 && mealList[0] !== undefined) {
                dispatch({ type: 'mealOptions', payload: mealList })
              }
              if (mealReturn !== undefined && mealReturn[0] !== undefined && mealReturn[0].optins !== undefined) {
                
                dispatch({ type: 'mealReturnOptions', payload: mealReturn })
              }
            }
          } else {
            let onwardList = props.fareresp.data[0].mand.filter((type) => type.tid === props.srequest.origns[0].srcorigns[0].tid);
            let retunList = props.fareresp.data[0].mand.filter((type) => type.tid === props.srequest.origns[0].destorigins[0].tid);
            let bagAJonwardList = onwardList[0] !== undefined && onwardList[0].bag.length !== 0 && onwardList[0].bag.filter((bag) => bag.nm === "OutwardAJLuggageOptions");
            let bagAJreturnList = retunList[0] !== undefined && retunList[0].bag.length !== 0 && retunList[0].bag.filter((bag) => bag.nm === "OutwardAJLuggageOptions");
            // previous code
            // let mealAJonwardList = onwardList[0] !== undefined && onwardList[0].bag.length !== 0 && onwardList[0].bag.filter((bag) => bag.nm === "MEALOPTIONS" && bag.jtype == "O");
            // let mealAJreturnList = retunList[0] !== undefined && retunList[0].bag.length !== 0 && retunList[0].bag.filter((bag) => bag.nm === "MEALOPTIONS" && bag.jtype == "R");

            // new code
            let bagreturnList = retunList[0] !== undefined && retunList[0].bag.length !== 0 && retunList[0].bag.filter((bag) => bag.nm === "ReturnLuggageOptions");
            let mealAJonwardListO = onwardList[0] !== undefined && onwardList[0].bag.length !== 0 && onwardList[0].bag.filter((bag) => bag.nm === "MEALOPTIONS" && bag.jtype == "O");
            let mealAJreturnListR = retunList[0] !== undefined && retunList[0].bag.length !== 0 && retunList[0].bag.filter((bag) => bag.nm === "MEALOPTIONS" && bag.jtype == "R");

            let mealAJonwardList;
            let mealAJreturnList;
            if(mealAJonwardListO.length === 0) {
              mealAJonwardList = onwardList[0] !== undefined && onwardList[0].bag.length !== 0 && onwardList[0].bag.filter((bag) => bag.nm === "MEALOPTIONS" || bag.nm === "OutwardMealOptions");
            } else {
              mealAJonwardList = onwardList[0] !== undefined && onwardList[0].bag.length !== 0 && onwardList[0].bag.filter((bag) => bag.nm === "MEALOPTIONS" && bag.jtype == "O");
            }
            if(mealAJreturnListR.length === 0) {
              mealAJreturnList = retunList[0] !== undefined && retunList[0].bag.length !== 0 && retunList[0].bag.filter((bag) => bag.nm === "MEALOPTIONS" || bag.nm === "OutwardMealOptions");
            } else {
              mealAJreturnList = retunList[0] !== undefined && retunList[0].bag.length !== 0 && retunList[0].bag.filter((bag) => bag.nm === "MEALOPTIONS" && bag.jtype == "R");
            }
            let bagOnwardList = onwardList[0] !== undefined && onwardList[0].bag.length !== 0 && onwardList[0].bag.filter((bag) => bag.nm === "LuggageOptions" || bag.nm === "OutwardLuggageOptions");
            let bagRetunList = retunList[0] !== undefined && retunList[0].bag.length !== 0 && retunList[0].bag.filter((bag) => bag.nm === "LuggageOptions" || bag.nm === "OutwardLuggageOptions");
            let mealOnward = props.fareresp.data[0].mand[0].bag.filter((bag) => bag.nm === "OutwardMealOptions");
            let mealReturn = props.fareresp.data[0].mand[0].bag.filter((bag) => bag.nm === "ReturnMealOptions");
            // new code start
            let mealreturnList = retunList[0] !== undefined && retunList[0].bag.length !== 0 && retunList[0].bag.filter((bag) => bag.nm === "ReturnMealOptions");
            // new code end
            if(bagAJonwardList !== undefined&& bagAJonwardList[0] !== undefined && bagAJonwardList[0].optins !== undefined) {
              dispatch({ type: 'baggageOptions', payload:convertLabelUsrPrice(bagAJonwardList[0].optins)})
              dispatch({ type: 'bOwname', payload: bagAJonwardList[0].nm })
              dispatch({ type: 'owbagTid', payload: onwardList[0].tid })
              dispatch({type:"onwardAJbaggage",payload:true})
            }
            if(bagAJreturnList !== undefined&& bagAJreturnList[0] !== undefined && bagAJreturnList[0].optins !== undefined) {
              dispatch({ type: 'baggageReturnOptions', payload:convertLabelUsrPrice(bagAJreturnList[0].optins)})
              dispatch({ type: 'bRtname', payload: bagAJreturnList[0].nm })
              dispatch({ type: 'rtBagTid', payload: retunList[0].tid })
              dispatch({type:"returnAJbaggage",payload:true})
            }
            // new code start
            if(bagreturnList !== undefined&& bagreturnList[0] !== undefined && bagreturnList[0].optins !== undefined) {
              dispatch({ type: 'baggageReturnOptions', payload: convertLabelUsrPrice(bagreturnList[0].optins)})
              dispatch({ type: 'bRtname', payload: bagreturnList[0].nm })
              dispatch({ type: 'rtBagTid', payload: retunList[0].tid })
            }
            // new code end
            if(mealAJonwardList !== undefined&& mealAJonwardList[0] !== undefined && mealAJonwardList[0].optins !== undefined) {
              dispatch({ type: 'mealOptions', payload: mealAJonwardList })
              dispatch({type:"alJazeeraBooking",payload:true})
              dispatch({type:"owmealTid",payload:onwardList[0].tid})
            }
            if(mealAJreturnList !== undefined&& mealAJreturnList[0] !== undefined && mealAJreturnList[0].optins !== undefined) {
              
              dispatch({ type: 'mealReturnOptions', payload: mealAJreturnList })
              dispatch({type:"alJazeeraBooking",payload:true})
              dispatch({type:"rtmealTid",payload:mealAJreturnList[0].tid})
            }
            // new code start
            if(mealreturnList !== undefined&& mealreturnList[0] !== undefined && mealreturnList[0].optins !== undefined) {
              
              dispatch({ type: 'mealReturnOptions', payload: mealreturnList })
              dispatch({type:"rtmealTid",payload:retunList[0].tid})
            }
            // new code end
            if (bagOnwardList !== undefined && bagOnwardList[0] !== undefined && bagOnwardList[0].optins !== undefined) {
              dispatch({ type: 'baggageOptions', payload: convertLabelUsrPrice(bagOnwardList[0].optins)})
              dispatch({ type: 'bOwname', payload: bagOnwardList[0].nm })
              dispatch({ type: 'owbagTid', payload: onwardList[0].tid })
            }
            if (bagRetunList !== undefined && bagRetunList[0] !== undefined && bagRetunList[0].optins !== undefined) {
              dispatch({ type: 'baggageReturnOptions', payload:convertLabelUsrPrice(bagRetunList[0].optins)})
              dispatch({ type: 'bRtname', payload: bagRetunList[0].nm })
              dispatch({ type: 'rtBagTid', payload: retunList[0].tid })
            }
            if (mealOnward !== undefined && mealOnward[0] !== undefined && mealOnward[0].optins !== undefined) {
              dispatch({ type: 'mealOptions', payload: mealOnward[0].optins })
            }
            if (mealReturn !== undefined && mealReturn[0] !== undefined && mealReturn[0].optins !== undefined) {
  
              dispatch({ type: 'mealReturnOptions', payload: mealReturn[0].optins })
            }
          }
        }
      }
      }
    } else {
      if(props.srequest.doc !== undefined && props.srequest.doc!==""){
        dispatch({ type: 'docMandatory', payload: props.srequest.doc})
      }
    }

    if(props.passengeruniquekey!==undefined && props.passengeruniquekey && props.passengeruniquekey.length > 0) {
      state.passengerUniqueKey = props.passengeruniquekey;
      dispatch({type:"passengerUniqueKey",payload:state.passengerUniqueKey})
    }

    createPaxList();
  }, []);

  const convertLabelUsrPrice=(list)=>{
    for(let val of list){
      if(isArray(val)) {
        for(let value of val){
          if(value.price!==0 && value.price > 0){
            value.label=value.label +" - "+(value.price * props.base_sup_exrate).toFixed(2)+" "+props.srequest.cur;
          }
        }
      } else {
        if(val.price!==0 && val.price > 0){
          val.label=val.label +" - "+(val.price * props.base_sup_exrate).toFixed(2)+" "+props.srequest.cur;
        }
      }
    }
    return list
  }


  useEffect(() => {
    setSelectedFlightOW(props.selected[0]);
    setSelectedFlightRT(props.selected[1]);
    setMultiSelectOne(props.selected[2]);
    setMultiSelectTwo(props.selected[3]);
    setMultiSelectThree(props.selected[4]);
    setMultiSelectFour(props.selected[5]);
    setMultiSelectFive(props.selected[6]);
    setMultiSelectSix(props.selected[7]);
  }, [props.selected]);

  useEffect(() => {
    getTravellersList();
  }, [context.user_resp]);

  const getTravellersList = () => {
    try {
      if (
        context.user_resp !== undefined &&
        context.user_resp?.res !== undefined &&
        context.user_resp?.res?.usr_uniq_id !== undefined
      ) {
        const payload = {
          usr_uniq_id: context.user_resp?.res.usr_uniq_id
        }
        let tokenKey = Utilities.generateAPIToken(payload);
        CntCommonService.loadTravellers(payload,tokenKey).then((response) => {
          if (response.data !== undefined && response.data.suc) {
            let adtList = response.data.res.filter(
              (pax) => pax.pax_type === "ADT"
            );
            dispatch({ type: "adtTraList", payload: adtList });
            let chdList = response.data.res.filter(
              (pax) => chds > 0 && pax.pax_type === "CHD"
            );
            dispatch({ type: "chdTraList", payload: chdList });
            let infList = response.data.res.filter(
              (pax) => infs > 0 && pax.pax_type === "INF"
            );
            dispatch({ type: "infTraList", payload: infList });
          }
        }).catch(function (error) {
          console.error("Error in getTravellersList" + error);      
        });
      }
    } catch (err) {
      console.error("Error in getTravellersList" + err);
    }
  };

  //=====================================
  // This function will create the Pax List based on selection
  // which will update the state
  //=====================================
  const createPaxList = () => {
    let paxList = [];
    var adtcount = 1;
    var infCount = infs;
    if (adts) {
      for (var i = 0; i < adts; i++) {
        let passKey = [];
        if (props.fareresp!=="" && props.fareresp.data[0].passengerUniqueKey!==undefined && props.fareresp.data[0].passengerUniqueKey?.length > 0) {
          passKey = props.fareresp.data[0].passengerUniqueKey.filter((s) => s.type == "ADT");
        }
        let uniqueKey = passKey && passKey.length > 0 ? passKey[i].key : "";
        const payload = createPaxPayload(
          "ADT",
          adtcount,
          "Adult",
          infCount,
          adtcount,
          uniqueKey
        );
        paxList.push(payload);
        adtcount = adtcount + 1;
        infCount = infCount - 1;
      }
    }
    if (chds && chds > 0) {
      var chdcount = adtcount;
      var fesno = 1;
      for (var i = 0; i < chds; i++) {
        let passKey = [];
        if (props.fareresp!=="" && props.fareresp.data[0].passengerUniqueKey!==undefined && props.fareresp.data[0].passengerUniqueKey?.length > 0) {
          passKey = props.fareresp.data[0].passengerUniqueKey.filter((s) => s.type == "CHD");
        }
        let uniqueKey = passKey && passKey.length > 0 ? passKey[i].key : "";
        const payload = createPaxPayload(
          "CHD",
          chdcount,
          "Child",
          0,
          fesno,
          uniqueKey
        );
        paxList.push(payload);
        chdcount = chdcount + 1;
        fesno = fesno + 1;
      }
    }
    dispatch({ type: "passengers", payload: paxList });
  };

  //=====================================
  // This is common function to create passengers
  //=====================================
  const createPaxPayload = (type, count, lable, infs, sno, pUkey) => {
    var lead = false;
    var docPayload = "";
    var prefPayload = "";
    var ffPayload = "";
    var infPayload = "";
    type === "ADT" && count === 1 ? (lead = true) : (lead = false);
    docPayload = {
      ty: "P",
      num: "",
      na: "",
      isc: "",
      isd: null,
      exd: null,
      isd_month: "",
      isd_day: "",
      isd_year: "",
      exp_month: "",
      exp_day: "",
      exp_year: "",
    };
    dispatch({ type: "passportEnable", payload: true });
    if (type !== "INF") {
      prefPayload = { sa: "", sp: "", mp: "" };
      ffPayload = { ffnum: "", air: "" };
    }
    if (infs > 0) {
      infPayload = {
        sno: count,
        tl: "",
        ty: "INF",
        plbl: "Infant",
        fn: "",
        ln: "",
        dob: null,
        doc: {
          ty: "P",
          num: "",
          na: "",
          isc: "",
          isd: null,
          exd: null,
          isd_month: "",
          isd_day: "",
          isd_year: "",
          exp_month: "",
          exp_day: "",
          exp_year: "",
        },
        trave_id: 0,
        dob_month: "",
        dob_day: "",
        dob_year: "",
      };
    }
    return {
      sno: count,
      passengerKey: pUkey ? pUkey : "",
      fesno: sno,
      tl: "",
      ty: type,
      plbl: lable,
      lead: lead,
      fn: "",
      ln: "",
      em: "",
      phc: "",
      phn: "",
      dob: null,
      inf: infPayload,
      doc: docPayload,
      opref: prefPayload,
      ffinfo: ffPayload,
      baggage: [],
      meals: [],
      seats: [],
      trave_id: 0,
      dob_month: "",
      dob_day: "",
      dob_year: "",
    };
  };

  const handleKeyPress = (e) => {
    // Check if the pressed key is the '+' symbol
    if (e.key === "+") {
      e.preventDefault(); // Prevent the '+' symbol from being entered
    }
    // Check if the pressed key is the '-' symbol
    if (e.key === "-") {
      e.preventDefault(); // Prevent the '-' symbol from being entered
    }
    // Check if the pressed key is the 'e' symbol
    if (e.key === "e") {
      e.preventDefault(); // Prevent the 'e' alphabet from being entered
    }
  };

  const handleChangeText = (idx, type, paxType) => (e) => {
    const { id, value } = e.target;
    try {
      let paxList = state.passengers;
      for (let pax of paxList) {
        if (pax.sno === idx) {
          if (paxType !== "INF") {
            if (type === "fn") {
              pax.fn = value;
            } else if (type === "ln") {
              pax.ln = value;
            } else if (pax.lead && type === "em") {
              pax.em = value;
            } else if (pax.lead && type === "phn") {
              pax.phn = value;
            } else if (type === "pnum") {
              pax.doc.num = value;
            } else if (type === "ffnum") {
              pax.ffinfo.ffnum = value;
            }
          } else {
            if (type === "fn") {
              pax.inf.fn = value;
            } else if (type === "ln") {
              pax.inf.ln = value;
            } else if (type === "pnum") {
              pax.inf.doc.num = value;
            }
          }
        }
      }
      dispatch({ type: "passengers", payload: paxList });
      props.sendpsngersdata(paxList);
    } catch (err) {
      console.error("Eroor+++++++++++" + err);
    }
  };

  // OnChange event we will call this and update the state
  const handleChangeSelect = (idx, type, field, paxType) => (e) => {
    try {
      if (e !== "") {
        let paxList = state.passengers;
        for (let pax of paxList) {
          if (pax.sno === idx) {
            if (paxType !== "INF") {
              if (type === "tl") {
                pax.tl = e;
              } else if (pax.lead && type === "phc") {
                pax.phc = e;
              } else if (field === "dob") {
                createDobInfo(pax, type, e, false);
              } else if (field === "isudate") {
                createISDateInfo(pax, type, e, false);
              } else if (field === "expiry") {
                createExpiryInfo(pax, type, e, false);
              } else if (type === "na") {
                pax.doc.na = e;
              } else if (type === "isc") {
                pax.doc.isc = e;
              } else if (type === "spe") {
                pax.opref.sa = e;
              } else if (type === "meal") {
                pax.opref.mp = e;
              } else if (type === "seat") {
                pax.opref.sp = e;
              } else if (type === "freq") {
                pax.ffinfo.air = e;
              }
            } else {
              if (field === "dob") {
                createDobInfo(pax, type, e, true);
              } else if (field === "isudate") {
                createISDateInfo(pax, type, e, true);
              } else if (field === "expiry") {
                createExpiryInfo(pax, type, e, true);
              } else if (type === "nationality") {
                pax.inf.doc.na = e;
              } else if (type === "issuedcountry") {
                pax.inf.doc.isc = e;
              } else if (type === "tl") {
                pax.inf.tl = e;
              }
            }
          }
        }
        dispatch({ type: "passengers", payload: paxList });
        props.sendpsngersdata(paxList);
      }
    } catch (err) {
      console.error("Eroor+++++++++++" + err);
    }
  };

  const createDobInfo = (pax, type, e, isInf) => {
    if (type === "month") {
      if (!isInf) {
        pax.dob_month = e;
      } else {
        pax.inf.dob_month = e;
      }
    } else if (type === "day") {
      if (!isInf) {
        pax.dob_day = e;
      } else {
        pax.inf.dob_day = e;
      }
    } else if (type === "year") {
      if (!isInf) {
        pax.dob_year = e;
      } else {
        pax.inf.dob_year = e;
      }
    }
    return pax;
  };
  const createISDateInfo = (pax, type, e, isInf) => {
    if (type === "month") {
      if (!isInf) {
        pax.doc.isd_month = e;
      } else {
        pax.inf.doc.isd_month = e;
      }
    } else if (type === "day") {
      if (!isInf) {
        pax.doc.isd_day = e;
      } else {
        pax.inf.doc.isd_day = e;
      }
    } else if (type === "year") {
      if (!isInf) {
        pax.doc.isd_year = e;
      } else {
        pax.inf.doc.isd_year = e;
      }
    }
    return pax;
  };

  const createExpiryInfo = (pax, type, e, isInf) => {
    if (type === "month") {
      if (!isInf) {
        pax.doc.exp_month = e;
      } else {
        pax.inf.doc.exp_month = e;
      }
    } else if (type === "day") {
      if (!isInf) {
        pax.doc.exp_day = e;
      } else {
        pax.inf.doc.exp_day = e;
      }
    } else if (type === "year") {
      if (!isInf) {
        pax.doc.exp_year = e;
      } else {
        pax.inf.doc.exp_year = e;
      }
    }
  };

  const convertDobMonth = (pax, dob, type) => {
    if(dob !== null && dob !== undefined && dob !== "") {
      let dobAry = dob.split("-");
      if (type === "dob") {
        pax.dob_month = dobAry[0];
      } else if (type === "isd") {
        pax.doc.isd_month = dobAry[0];
      } else {
        pax.doc.exp_month = dobAry[0];
      }
    } else {
      if (type === "dob") {
        pax.dob_month = "";
      } else if (type === "isd") {
        pax.doc.isd_month = "";
      } else {
        pax.doc.exp_month = "";
      }
    }
  };
  const convertDobDate = (pax, dob, type) => {
    if(dob !== null && dob !== undefined && dob !== "") {
      let dobAry = dob.split("-");
      if (type === "dob") {
        pax.dob_day = dobAry[1];
      } else if (type === "isd") {
        pax.doc.isd_day = dobAry[1];
      } else {
        pax.doc.exp_day = dobAry[1];
      }
    } else {
      if (type === "dob") {
        pax.dob_day = "";
      } else if (type === "isd") {
        pax.doc.isd_day = "";
      } else {
        pax.doc.exp_day = "";
      }
    }
  };
  const convertDobYear = (pax, dob, type) => {
    if(dob !== null && dob !== undefined && dob !== "") {
      let dobAry = dob.split("-");
      pax.year = dobAry[2];
      if (type === "dob") {
        pax.dob_year = dobAry[2];
      } else if (type === "isd") {
        pax.doc.isd_year = dobAry[2];
      } else {
        pax.doc.exp_year = dobAry[2];
      }
    } else {
      if (type === "dob") {
        pax.dob_year = "";
      } else if (type === "isd") {
        pax.doc.isd_year = "";
      } else {
        pax.doc.exp_year = "";
      }
    }
  };
  const updatePax = (e, obj, paxType, idx) => {
    let paxList = state.passengers;
    for (let pax of paxList) {
      if (pax.sno === idx) {
        if (paxType !== "INF") {
          pax.tl = obj.title;
          pax.fn = obj.fname;
          pax.ln = obj.lname;
          pax.em = obj.email;
          pax.phc = obj.phn_code;
          pax.phn = obj.ph_num;
          pax.trave_id = e;
          pax.doc.num = obj.passport_no;
          pax.doc.na = obj.na;
          pax.doc.isc = obj.issue_cnt;
          //DOB
          convertDobMonth(pax, obj.dob, "dob");
          convertDobDate(pax, obj.dob, "dob");
          convertDobYear(pax, obj.dob, "dob");
          //ISSUE DATE
          convertDobMonth(pax, obj.issue_date, "isd");
          convertDobDate(pax, obj.issue_date, "isd");
          convertDobYear(pax, obj.issue_date, "isd");
          //EXP DATE
          convertDobMonth(pax, obj.exp_date, "exp");
          convertDobDate(pax, obj.exp_date, "exp");
          convertDobYear(pax, obj.exp_date, "exp");
          break;
        } else {
          pax.inf.tl = obj.title;
          pax.inf.fn = obj.fname;
          pax.inf.ln = obj.lname;
          pax.inf.doc.num = obj.passport_no;
          pax.inf.doc.na = obj.na;
          pax.inf.doc.isc = obj.issue_cnt;
          pax.inf.trave_id = e;
          //DOB
          convertDobMonth(pax.inf, obj.dob, "dob");
          convertDobDate(pax.inf, obj.dob, "dob");
          convertDobYear(pax.inf, obj.dob, "dob");
          //ISSUE DATE
          convertDobMonth(pax.inf, obj.issue_date, "isd");
          convertDobDate(pax.inf, obj.issue_date, "isd");
          convertDobYear(pax.inf, obj.issue_date, "isd");
          //EXP DATE
          convertDobMonth(pax.inf, obj.exp_date, "exp");
          convertDobDate(pax.inf, obj.exp_date, "exp");
          convertDobYear(pax.inf, obj.exp_date, "exp");
          break;
        }
      }
    }
    dispatch({ type: "passengers", payload: paxList });
    // sessionStorage.setItem("passengers", JSON.stringify(paxList));
    props.sendpsngersdata(paxList);
    // props.sendpsngersdata(sessionStorage.getItem("passengers"));
  };

  //Creating Selected Baggage List for Normal and Split flow
  const updateBagprice = (price, no, idx, flow) => (obj) => {
    props.selbagprice([obj, price, no, flow]);
    let baggage = [];
    let newpax = state.passengers.map((pax, sidx) => {
      if (idx !== sidx) return pax;
      else {
        if (flow === "O") {
          const onward = createBaggageReq(obj, state.bOwname, props.srequest.sty === "normal" ? props.fareresp.data[0].mand[0].tid : state.owbagTid, "O", flow);
          // const onward = createBaggageReq(obj, state.bOwname, props.srequest.sty === "normal" ? "15tqXrCTeO1Sw7XU_1" : "15tqXrCTeO1Sw7XU_1");
          if (pax.baggage === undefined) {
            baggage.push(onward);
            return { ...pax, baggage: baggage };
          } else {
            var filtered = [];
            if (props.srequest.sty === "normal") {
              filtered = pax.baggage.filter(function (el) { return el.nm != onward.nm; });
            } else {
              filtered = pax.baggage.filter(function (el) { return el.nm === onward.nm && el.tid !== onward.tid; });
            }
            baggage.push(onward);
            if (filtered.length !== 0 && filtered[0] !== undefined) {
              baggage.push(filtered[0])
            }
            return { ...pax, baggage: baggage };
          }
        } else {
          const retun = createBaggageReq(obj, state.bRtname, props.srequest.sty === "normal" ? props.fareresp.data[0].mand[0].tid : state.rtBagTid, "R", flow);
          // const retun = createBaggageReq(obj, state.bRtname, props.srequest.sty === "normal" ? "15tqXrCTeO1Sw7XU_1" : "15tqXrCTeO1Sw7XU_1");
          if (pax.baggage === undefined) {
            baggage.push(retun);
            return { ...pax, baggage: baggage };
          } else {
            var filtered = [];
            if (props.srequest.sty === "normal") {
              filtered = pax.baggage.filter(function (el) { return el.nm != retun.nm; });
            } else {
              filtered = pax.baggage.filter(function (el) { return el.nm === retun.nm && el.tid !== retun.tid; });
            }
            baggage.push(retun);
            if (filtered.length !== 0 && filtered[0] !== undefined) {
              baggage.push(filtered[0])
            }
            return { ...pax, baggage: [...pax.baggage, ...baggage] };
          }
        }
      }
    });
    state.passengers=newpax
    //dispatch({ type: 'passengers', payload: newpax })
    props.sendpsngersdata(newpax);
  };
  
  //This is common for Baggage and Meal
  const createBaggageReq = (obj, name, tid,route,flow) => {
    if(obj!==undefined){
    const payload = {
      "tid": tid,
      "nm": name,
      "val": obj.value,
      "label": obj.label,
      "price": obj.price,
      "sup_price": obj.sup_price,
      "sup_cur_price": obj.sup_cur_price,
      "mkp": obj.mkp,
      "jtype": flow
    }
    if(route !== undefined) {
      payload.route = route
    }
    if(obj.ssrKey) {
      payload.ssrKey = obj.ssrKey
    }
    if(obj.hashcode) {
      payload.hashcode = obj.hashcode
      payload.routeIds = obj.routeIds
      payload.code = obj.code
      payload.offerId = obj.offerId
    }
    return payload;
  }
  };    

  //Creating Selected Baggage List for Normal and Split flow
  const updateMealprice = (price, no, idx, flow,route) => (obj) => {
    props.selmealprice([obj, price, no, flow,route]);
    let meals = [];
    let newpax = state.passengers.map((pax, sidx) => {
      if (idx !== sidx) return pax;
      else {
        if (flow === "O") {
          const onward = createBaggageReq(obj, "OutwardMealOptions", state.owmealTid ? state.owmealTid : props.fareresp.data[0].mand[0].tid,route,flow);
          // const onward = createBaggageReq(obj, "OutwardMealOptions" ? "15tqXrCTeO1Sw7XU_1" : "15tqXrCTeO1Sw7XU_1", route);
          if (pax.meals === undefined) {
            meals.push(onward);
            return { ...pax, meals: meals };
          } else {
            var filtered = pax.meals.filter(function (el) { return  el.route && onward.route ? el.route != onward.route : el.nm != onward.nm; });
            meals.push(onward);
            if (filtered.length !== 0 && filtered[0] !== undefined) {
              meals.push(filtered[0])
            }
            if(props.fareresp.data[0].sup == 3 || props.fareresp.data[0].sup == 4 || props.fareresp.data[0].sup == 7){
              meals=createMealsObj(pax,onward);
            }

            return { ...pax, meals: meals };
          }
        } else {
          const retun = createBaggageReq(obj, "ReturnMealOptions",  state.rtmealTid ? state.rtmealTid : props.fareresp.data[0].mand[0].tid,route,flow);
          // const retun = createBaggageReq(obj, "ReturnMealOptions" ? "15tqXrCTeO1Sw7XU_1" : "15tqXrCTeO1Sw7XU_1" ,route)
          if (pax.meals === undefined && retun!==undefined) {
            meals.push(retun);
            return { ...pax, meals: meals };
          } else {
            if(retun!==undefined){
            var filtered = pax.meals.filter(function (el) { return  el.route && retun.route!==undefined && retun.route ? el.route != retun.route : el.nm != retun.nm; });
            meals.push(retun);
            if (filtered.length !== 0 && filtered[0] !== undefined) {
              meals.push(filtered[0])
            }
            if(props.fareresp.data[0].sup == 3 || props.fareresp.data[0].sup == 4 || props.fareresp.data[0].sup == 7){
              meals=createMealsObj(pax,retun)
            }
            return { ...pax, meals: meals };
          }
        }
        }
      }
    });
    state.passengers=newpax
    props.sendpsngersdata(newpax);
  };

  const createMealsObj=(pax,obj)=>{
    let matched=false;
    let meals=pax.meals;
   for(let val of meals){
      if(val.route===obj.route){
        val.label=obj.label;
        val.price=obj.price;
        val.tid=obj.tid;
        val.sup_price=obj.sup_price;
        val.sup_cur_price=obj.sup_cur_price;
        val.val=obj.val;
        if(obj.ssrKey) {
          val.ssrKey = obj.ssrKey
        }
        if(obj.hashcode) {
          val.hashcode = obj.hashcode 
          val.routeIds = obj.routeIds 
          val.code = obj.code 
          val.offerId = obj.offerId
        }
        matched=true;
        break;
      }
   }
   if(!matched){
    meals=[];
    meals=pax.meals;
    meals.push(obj);
   }
   return meals;
  };

  /**
   * @author : Ubedullah
   * @description : this function return for the passengerkey required data
   * @date : 08-aug-2023
   */
  const getEachKeySSR = (data,key) => {
    let respected_data = [];
    for(let eachOptin of data) {
      if(isArray(eachOptin)) {
        for(let eacPax of eachOptin) {
          if(eacPax.paxKey && key!=="" && eacPax.paxKey == key) {
            respected_data = eachOptin
            break;
          }
        }
      } else {
        for(let eacPax of [eachOptin]) {
          if(eacPax.paxKey && key!=="" && eacPax.paxKey == key) {
            respected_data = eachOptin
            break;
          }
        }
      }
    }
    return respected_data
  };

  const handleShowModal = () => {
    setVisible(true);
  };
  const handleHideModal = () => {
    setVisible(false);
    // window.location.reload();
  };

  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleClick = (e) => {

    let cn = e.currentTarget.classList;

   if(cn.contains('active')){
    cn.remove('active')
   } else {
    cn.add('active')
   }    
 };

  // Handle extras click event
  const handleExtrasClick = (rowIndex, extrasIndex) => {
    if (selectedIndexes[rowIndex] === extrasIndex) {
      // If the clicked title is already selected, hide it
      selectedIndexes[rowIndex] = null;
      setSelectedIndexes([...selectedIndexes]);
    } else {
      // If the clicked title is not selected, show it and hide others in the same row
      selectedIndexes[rowIndex] = extrasIndex;
      setSelectedIndexes([...selectedIndexes]);
    }
  };

  return (
    <>
      {/* <div className="action-links">
        <AuthenticationPopUp visible={showSignIn} onCancel={handleHideModal} /> 
        </div> */}
      <div className="addDetails">
        <span className="number">{context.translation && context.translation.two}</span>

        <Title level={4}>
          <span className="addDetailsTitle">{context.translation && context.translation.addDetails}</span>
          {context.user_resp === "" && (
            <>
            <span className="fasterBookingWrap">
              <span className="fasterBooking">
                {context.translation && context.translation.getBok}{" "}
              </span>
              <Link to="#" className="signInFocus" onClick={handleShowModal}>
                <i className="sprite sign-sprite"></i>{context.translation && context.translation.signInSignUp}
              </Link>
            </span>
            </>
          )}
        </Title>
        <AuthenticationPopUp
          visible={visible}
          onCancel={handleHideModal}
          reviewSignin={signin}
        />
      </div>
      {state.passengers.length > 0 && (
        <div className="paxList">
          {state.passengers.map((pasenger, idx) => (
            <div className="paxDetailsWrapper" key={idx}>
              <div
                className={"travellerDetailHeader dFlex " + (idx===0 && 'active')} 
                 onClick={handleClick}
              >
                {pasenger.ty.toUpperCase() === "ADT" && (
                  <div>
                    <Title level={5}>
                      <span className="traveller">
                        {context.translation && context.translation.traveller} {pasenger.fesno}{" "}
                      </span>
                      <span className="stages">
                        {context.translation && context.translation.adult} {pasenger.lead ? (context.translation && context.translation.lead) : ""}
                      </span>
                    </Title>
                  </div>
                )}
                {pasenger.ty.toUpperCase() === "CHD" && (
                  <div>
                    <Title level={5}>
                      <span className="traveller">
                        {context.translation && context.translation.traveller} {pasenger.fesno}{" "}
                      </span>
                      <span className="stages">{context.translation && context.translation.chd}</span>
                    </Title>
                  </div>
                )}
                <div>
                  <span>
                    <i
                      className={
                        "icon icon-Minus"
                      }
                    ></i>
                    <i
                      className={
                        "icon icon-Plus"
                      }
                    ></i>
                  </span>
                </div>
              </div>
             
                <div className="travDetailsSec">
                  <div className="travellerDetailBody">
                    <Row gutter={20}>
                      <Col span={24}>
                        <p>
                          <span>{context.translation && context.translation.note} </span>{context.translation && context.translation.ensureName}
                        </p>
                      </Col>
                      {(pasenger.ty.toUpperCase() === "ADT" && state.adtTraList.length !== 0 || pasenger.ty.toUpperCase() === "CHD" && state.chdTraList.length !== 0) && (
                      <Col span={8}>
                        <Form.Item label="">
                          {pasenger.ty.toUpperCase() === "ADT" &&
                            state.adtTraList.length !== 0 && (
                            <>
                              {(sessionStorage.getItem("SEL_LAG") === null || sessionStorage.getItem("SEL_LAG") === "English") &&
                                <Select
                                  name="title"
                                  defaultValue="Select Traveller"
                                  value={
                                    pasenger.trave_id
                                      ? pasenger.trave_id
                                      : undefined
                                  }
                                  onSelect={(value, event) =>
                                    updatePax(value, event, "ADT", idx + 1)
                                  }
                                  options={state.adtTraList}
                                  className="selectBoxShadow"
                                />
                              }
                              {(sessionStorage.getItem("SEL_LAG") === "Arabic") &&
                                <Select
                                  name="title"
                                  defaultValue="إختر المسافر"
                                  value={
                                    pasenger.trave_id
                                      ? pasenger.trave_id
                                      : undefined
                                  }
                                  onSelect={(value, event) =>
                                    updatePax(value, event, "ADT", idx + 1)
                                  }
                                  options={state.adtTraList}
                                  className="selectBoxShadow"
                                />
                              }
                            </>
                            )}
                          {pasenger.ty.toUpperCase() === "CHD" &&
                            state.chdTraList.length !== 0 && (
                              <>
                              {(sessionStorage.getItem("SEL_LAG") === null || sessionStorage.getItem("SEL_LAG") === "English") &&
                                <Select
                                name="title"
                                defaultValue="Select Traveller"
                                value={
                                  pasenger.trave_id
                                    ? pasenger.trave_id
                                    : undefined
                                }
                                onSelect={(value, event) =>
                                  updatePax(value, event, "CHD", idx + 1)
                                }
                                options={state.chdTraList}
                                className="selectBoxShadow"
                              />
                              }
                              {(sessionStorage.getItem("SEL_LAG") === "Arabic") &&
                                <Select
                                name="title"
                                defaultValue="إختر المسافر"
                                value={
                                  pasenger.trave_id
                                    ? pasenger.trave_id
                                    : undefined
                                }
                                onSelect={(value, event) =>
                                  updatePax(value, event, "CHD", idx + 1)
                                }
                                options={state.chdTraList}
                                className="selectBoxShadow"
                              />
                              }
                            </>
                            )}
                        </Form.Item>
                      </Col>
                      )}
                    </Row>
                    <Row gutter={20}>
                      <Col span={8}>
                        <Form.Item autoComplete="off" className="pRelative">
                          <label className="requiredField">{context.translation && context.translation.fn}</label>
                          {pasenger.ty.toUpperCase() === "ADT" && (
                            <>
                              <Input
                                addonBefore={
                                  <Select
                                    name="title"
                                    defaultValue={context.translation && context.translation.select}
                                    value={pasenger.tl ? pasenger.tl : undefined}
                                    placeholder={context.translation && context.translation.select}
                                    onChange={handleChangeSelect(
                                      idx + 1,
                                      "tl",
                                      "title",
                                      pasenger.ty
                                    )}
                                    >
                                    <Option value="Select">{context.translation && context.translation.select}</Option>
                                    {context.selectLang==="en"||context.selectLang=== undefined ? 
                                    <>
                                      {adt_titles.map((option, index) => (
                                        <Option key={index} value={option.value}>
                                          {option.label}
                                        </Option>
                                      ))}
                                    </> 
                                    :
                                    <>
                                      {adt_titlesar.map((option, index) => (
                                        <Option key={index} value={option.value}>
                                          {option.label}
                                        </Option>
                                      ))}
                                    </>
                                    }
                                  </Select>
                                }
                                className="selectBoxShadow double"
                                type="text"
                                name="fn"
                                value={pasenger.fn}
                                placeholder={context.translation && context.translation.efn}
                                onChange={handleChangeText(
                                  idx + 1,
                                  "fn",
                                  pasenger.ty
                                )}
                              />
                              </>
                          )}
                          {pasenger.ty.toUpperCase() === "CHD" && (
                            <Input
                              addonBefore={
                                <>
                              {(sessionStorage.getItem("SEL_LAG") === null || sessionStorage.getItem("SEL_LAG") === "English") &&
                                <Select
                                name="tl"
                                defaultValue= "Select"
                                value={pasenger.tl ? pasenger.tl : undefined}
                                onChange={handleChangeSelect(
                                  idx + 1,
                                  "tl",
                                  "title",
                                  pasenger.ty
                                )}
                                options={context.selectLang==="en"?chd_titles:chd_titlesar}
                              />
                              }
                              {(sessionStorage.getItem("SEL_LAG") === "Arabic") &&
                                <Select
                                name="tl"
                                defaultValue="اختار"
                                value={pasenger.tl ? pasenger.tl : undefined}
                                onChange={handleChangeSelect(
                                  idx + 1,
                                  "tl",
                                  "title",
                                  pasenger.ty
                                )}
                                options={context.selectLang==="en"?chd_titles:chd_titlesar}
                              />
                              }
                            </>
                              }
                              className="selectBoxShadow"
                              type="text"
                              name="fn"
                              value={pasenger.fn}
                              placeholder={context.translation && context.translation.efn}
                              onChange={handleChangeText(
                                idx + 1,
                                "fn",
                                pasenger.ty
                              )}
                            />
                          )}
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item className="pRelative">
                          <label className="requiredField">{context.translation && context.translation.ln}</label>
                          <Input
                            type="text"
                            className="innerBoxShadow"
                            name="ln"
                            value={pasenger.ln}
                            placeholder={context.translation && context.translation.eln}
                            onChange={handleChangeText(
                              idx + 1,
                              "ln",
                              pasenger.ty
                            )}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item>
                          {pasenger.ty === "ADT" && (
                            <label
                              className={
                                state.docMandatory === "yes" ||
                                props.oonwardsupp === "3" ||
                                props.returnsupp === "3" ||
                                props.oonwardsupp === "4" ||
                                props.returnsupp === "4" ||
                                props.oonwardsupp === "5" ||
                                props.returnsupp === "5" ||
                                props.oonwardsupp === "6" ||
                                props.returnsupp === "6" ||
                                props.oonwardsupp === "7" ||
                                props.returnsupp === "7"
                                  ? "requiredField"
                                  : "notRequiredField"
                              }
                            >
                              {context.translation && context.translation.dob}
                            </label>
                          )}
                          {pasenger.ty === "CHD" && (
                            <label className="requiredField">
                              {context.translation && context.translation.dob}
                            </label>
                          )}
                          <div className="onlyFlex">
                            <div className="dateMY month">
                              <Month
                                updateMonth={pasenger.dob_month}
                                sendMonth={handleChangeSelect(
                                  idx + 1,
                                  "month",
                                  "dob",
                                  pasenger.ty
                                )}
                              />
                            </div>
                            <div className="dateMY day">
                              <Days
                                updateDay={pasenger.dob_day}
                                sendDay={handleChangeSelect(
                                  idx + 1,
                                  "day",
                                  "dob",
                                  pasenger.ty
                                )}
                              />
                            </div>
                            <div className="dateMY year">
                              <Years
                                updateYear={pasenger.dob_year}
                                type={pasenger.ty}
                                sendYear={handleChangeSelect(
                                  idx + 1,
                                  "year",
                                  "dob",
                                  pasenger.ty
                                )}
                              />
                            </div>
                          </div>
                        </Form.Item>
                      </Col>
                    </Row>
                    {pasenger.lead && pasenger.ty.toUpperCase() === "ADT" && (
                      <Row gutter={20}>
                        <Col span={8}>
                          <Form.Item className="pRelative">
                            <label className="requiredField">{context.translation && context.translation.em}</label>
                            <Input
                              type="text"
                              className="innerBoxShadow"
                              name="em"
                              value={pasenger.em}
                              placeholder={context.translation && context.translation.eem}
                              onChange={handleChangeText(
                                idx + 1,
                                "em",
                                pasenger.ty
                              )}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item autoComplete="off" className="pRelative">
                            <label className="requiredField">
                              {context.translation && context.translation.ph}
                            </label>
                            <Input
                              addonBefore={
                                <>
                              {(sessionStorage.getItem("SEL_LAG") === null || sessionStorage.getItem("SEL_LAG") === "English") &&
                                <Select
                                optionLabelProp="value"
                                popupClassName="contactDpDown"
                                name="phc"
                                showSearch
                                defaultValue="Select"
                                value={
                                  pasenger.phc ? pasenger.phc : undefined
                                }
                                onChange={handleChangeSelect(
                                  idx + 1,
                                  "phc",
                                  "code",
                                  pasenger.ty
                                )}
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  (option?.label ?? "")
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                                }
                                options={stdCodes}
                              />
                              }
                              {(sessionStorage.getItem("SEL_LAG") === "Arabic") &&
                                 <Select
                                 optionLabelProp="value"
                                 popupClassName="contactDpDown"
                                 name="phc"
                                 showSearch
                                 defaultValue="اختار"
                                 value={
                                   pasenger.phc ? pasenger.phc : undefined
                                 }
                                 onChange={handleChangeSelect(
                                   idx + 1,
                                   "phc",
                                   "code",
                                   pasenger.ty
                                 )}
                                 optionFilterProp="children"
                                 filterOption={(input, option) =>
                                   (option?.label ?? "")
                                     .toLowerCase()
                                     .includes(input.toLowerCase())
                                 }
                                 options={stdCodes}
                               />
                              }
                            </>
                              }
                              className="selectBoxShadow enterContactNumber"
                              type="number"
                              name="phn"
                              max={10}
                              value={pasenger.phn ? pasenger.phn : undefined}
                              onKeyPress={handleKeyPress} {...props}
                              placeholder={context.translation && context.translation.eph}
                              onChange={handleChangeText(
                                idx + 1,
                                "phn",
                                pasenger.ty
                              )}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    )}
                    <div className="emailDivider"></div>
                    <Row gutter={20}>
                      <Col span={8}>
                        <Form.Item className="pRelative">
                          <label
                            className={
                              state.docMandatory === "yes" ||
                              props.indpassportenable === "true"
                                ? "requiredField"
                                : "notRequiredField"
                            }
                          >
                            {context.translation && context.translation.pn}
                          </label>
                          <Input
                            type="text"
                            className="innerBoxShadow"
                            name="num"
                            value={pasenger.doc.num}
                            placeholder={context.translation && context.translation.epn}
                            onChange={handleChangeText(
                              idx + 1,
                              "pnum",
                              pasenger.ty
                            )}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item className="pRelative">
                          <label
                            className={
                              state.docMandatory === "yes" ||
                              props.checkmandatoryflagnat === "true"
                                ? "requiredField"
                                : "notRequiredField"
                            }
                          >
                            {context.translation && context.translation.nn}
                          </label>
                          <>
                            <Select
                              defaultValue={context.translation && context.translation.select}
                              placeholder={context.translation && context.translation.select}
                              showSearch
                              optionFilterProp="children"
                              name="na"
                              value={
                                pasenger.doc.na ? pasenger.doc.na : undefined
                              }
                              className="selectBoxShadow double"
                              onChange={handleChangeSelect(
                                idx + 1,
                                "na",
                                "isc",
                                pasenger.ty
                              )}
                              filterOption={(input, option) =>
                                (option?.label ?? "")
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                            >
                            <Option value="Select">{context.translation && context.translation.select}</Option>
                              {countrieslist.map((option, index) => (
                                <Option key={index} value={option.value}>
                                  {option.label}
                                </Option>
                              ))}
                          </Select>
                              {/* {(sessionStorage.getItem("SEL_LAG") === "Arabic") &&
                               <Select
                               defaultValue="اختار"
                               showSearch
                               optionFilterProp="children"
                               name="na"
                               value={
                                 pasenger.doc.na ? pasenger.doc.na : undefined
                               }
                               className="selectBoxShadow"
                               onChange={handleChangeSelect(
                                 idx + 1,
                                 "na",
                                 "isc",
                                 pasenger.ty
                               )}
                               filterOption={(input, option) =>
                                 (option?.label ?? "")
                                   .toLowerCase()
                                   .includes(input.toLowerCase())
                               }
                               options={countrieslist}
                             />
                              } */}
                            </>
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item>
                          <label
                            className={
                              state.docMandatory === "yes"
                                ? "requiredField"
                                : "notRequiredField"
                            }
                          >
                            {context.translation && context.translation.ic}
                          </label>
                           <>
                              {/* {(sessionStorage.getItem("SEL_LAG") === null || sessionStorage.getItem("SEL_LAG") === "English") && */}
                              <Select
                                defaultValue={context.translation && context.translation.select}
                                placeholder={context.translation && context.translation.select}
                                showSearch
                                optionFilterProp="children"
                                name="isc"
                                value={
                                  pasenger.doc.isc ? pasenger.doc.isc : undefined
                                }
                                className="selectBoxShadow double"
                                onChange={handleChangeSelect(
                                  idx + 1,
                                  "isc",
                                  "isd",
                                  pasenger.ty
                                )}
                                filterOption={(input, option) =>
                                  (option?.label ?? "")
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                                }
                                >
                                <Option value="Select">{context.translation && context.translation.select}</Option>
                                  {countrieslist.map((option, index) => (
                                    <Option key={index} value={option.value}>
                                      {option.label}
                                    </Option>
                                  ))}
                              </Select>
                              {/* // } */}
                              {/* {(sessionStorage.getItem("SEL_LAG") === "Arabic") &&
                                <Select
                            defaultValue= "اختار"
                            showSearch
                            optionFilterProp="children"
                            name="isc"
                            value={
                              pasenger.doc.isc ? pasenger.doc.isc : undefined
                            }
                            className="selectBoxShadow"
                            onChange={handleChangeSelect(
                              idx + 1,
                              "isc",
                              "isd",
                              pasenger.ty
                            )}
                            filterOption={(input, option) =>
                              (option?.label ?? "")
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            options={countrieslist}
                          />
                              } */}
                            </>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={20} className="issueExpiryDateWrap">
                      <Col span={8}>
                        <Form.Item>
                          <label className="notRequiredField">
                            {context.translation && context.translation.iDate}
                          </label>
                          <div className="onlyFlex">
                                  <div className="dateMY month">
                                    <Month
                                      updateMonth={pasenger.doc.isd_month}
                                      sendMonth={handleChangeSelect(
                                        idx + 1,
                                        "month",
                                        "isudate",
                                        pasenger.ty
                                      )}
                                    />
                                  </div>
                                  <div className="dateMY day">
                                    <Days
                                      updateDay={pasenger.doc.isd_day}
                                      sendDay={handleChangeSelect(
                                        idx + 1,
                                        "day",
                                        "isudate",
                                        pasenger.ty
                                      )}
                                    />
                                  </div>
                                  <div className="dateMY year">
                                    <Years
                                      updateYear={pasenger.doc.isd_year}
                                      type="isuDate"
                                      sendYear={handleChangeSelect(
                                        idx + 1,
                                        "year",
                                        "isudate",
                                        pasenger.ty
                                      )}
                                    />
                                  </div>
                                </div>
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item>
                          <label
                            className={
                              state.docMandatory === "yes" ||
                              props.indpassportenable === "true"
                                ? "requiredField"
                                : "notRequiredField"
                            }
                          >
                            {context.translation && context.translation.eDate}
                          </label>
                          <div className="onlyFlex">
                                      <div className="dateMY month">
                                        <Month
                                          updateMonth={pasenger.doc.exp_month}
                                          sendMonth={handleChangeSelect(
                                            idx + 1,
                                            "month",
                                            "expiry",
                                            pasenger.ty
                                          )}
                                        />
                                      </div>
                                      <div className="dateMY day">
                                        <Days
                                          updateDay={pasenger.doc.exp_day}
                                          sendDay={handleChangeSelect(
                                            idx + 1,
                                            "day",
                                            "expiry",
                                            pasenger.ty
                                          )}
                                        />
                                      </div>
                                      <div className="dateMY year">
                                        <Years
                                          updateYear={pasenger.doc.exp_year}
                                          type="expDate"
                                          sendYear={handleChangeSelect(
                                            idx + 1,
                                            "year",
                                            "expiry",
                                            pasenger.ty
                                          )}
                                        />
                                      </div>
                                    </div>
                        </Form.Item>
                      </Col>
                    </Row>
                  </div>

                  {props.srequest.sty!==undefined && props.srequest.sty==="normal" && 
                  props.oonwardsupp!=="" && props.oonwardsupp!=="0" && (
                    <>
                      {/* {props.oonwardsupp!=="" && props.oonwardsupp!=="0" &&  state.baggageOptions.length!==0 && (
                        <> */}
                      
                        {/* Add Baggage for LCC */}
                        <div className="addExtra">
                          <div>
                            <Title
                              level={5}
                              className={showBaggage && "active"}
                              onClick={baggageTab}
                            >
                              <i className="icon icon-Trips"></i> {context.translation && context.translation.addBag}
                            </Title>
                          </div>
                          
                            {(state.mealOptions.length !== 0 || state.mealReturnOptions.length !== 0) && (
                              <div>
                                <Title 
                                  level={5}
                                  className={showMeals && "active"}
                                  onClick={mealsTab}
                                >
                                  <i className="icon icon-Meal"></i> {context.translation && context.translation.addMeal}
                                </Title>
                              </div>
                            )}
                          {/* <div>
                            <Title level={5}>
                              <i className="icon icon-Special-Request"></i> Special
                              Request
                            </Title>
                          </div>
                          <div>
                            <Title level={5}>
                              <i className="icon icon-flight-product"></i> Add
                              Frequent Flyer
                            </Title>
                          </div> */}
                        </div>
                        {/* </>
                      )} */}

                      {/* Add Baggage Start Here */}
                      {/* {showBaggage && (
                        <> */}
                          <div className={showBaggage ? "addExtraInner" : "addExtraInner hideBaggage"}>
                            <Row gutter={20}>
                              
                              {props.oonwardsupp!=="" && props.oonwardsupp!=="0" &&  state.baggageOptions.length!==0 && (
                                <>   
                                  <Col span={12}>
                                    <div className="rb-top">
                                      <div className="info">
                                        <span className="smallFlight">
                                          <i className="icon icon-flight"></i>
                                        </span>
                                        <span className="route">{props.oonwardroutesrc}</span>
                                        <span className="rightArrow">
                                          <i className="icon icon-next-long-arrow-right-svgrepo-com"></i>
                                        </span>
                                        <span className="route">
                                          {props.oonwardroutedest}
                                        </span>
                                      </div>
                                    </div>
                                    <div className="addExtraIncluded">
                                      <div>
                                        <span className="included">{context.translation && context.translation.included}</span>
                                        <span className="checkin">
                                          {context.translation && context.translation.checkin}: {""}
                                          {props.srequest.origns[0].srcorigns[0].bgd.filter((b) => b.type === "Checkin")[0].qun}&nbsp;
                                          {props.srequest.origns[0].srcorigns[0].bgd.filter((b) => b.type === "Checkin")[0].wt}
                                          {/* <i className="icon icon-Info"></i> */}
                                        </span>
                                      </div>
                                      <div className="addExtraBaggage">
                                        <Title level={5}>{context.translation && context.translation.addExtBag}</Title>
                                        <Form.Item label="">                                    
                                          <AddBaggage 
                                            sendBagprice={updateBagprice(pasenger.ty,pasenger.fesno,idx,"O")} 
                                            // bagList={state.alJazeeraBooking && props.fareresp.data[0].sup == 3 ? getEachKeySSR(state.baggageOptions,pasenger.passengerKey) : state.baggageOptions} 
                                            bagList={state.alJazeeraBooking && props.fareresp.data[0].sup == 3 ? state.baggageOptions : state.baggageOptions} 
                                            // bagList={state.baggageOptions}
                                            disable={props.disable} 
                                          />
                                        </Form.Item>
                                      </div>
                                    </div>
                                  </Col>
                                </>
                              )}
                              
                              {((props.returnsupp!=="" && props.returnsupp==="0") || state.baggageReturnOptions.length === 0) && props.returnroute !== "" &&
                                <>                                              
                                  <Col span={12}>
                                    <div className="rb-top">
                                      <div className="info">
                                        <span className="smallFlight">
                                          <i className="icon icon-flight"></i>
                                        </span>
                                        <span className="route">{props.returnroutesrc}</span>
                                        <span className="rightArrow">
                                          <i className="icon icon-next-long-arrow-right-svgrepo-com"></i>
                                        </span>
                                        <span className="route">
                                          {props.returnroutedest}
                                        </span>
                                      </div>
                                    </div>
                                    <div className="addExtraIncluded">
                                      <div className="addExtraBaggage">
                                        <Title level={5} className="optionNotAvail">{context.translation && context.translation.bagOptNotAvail}</Title>
                                      </div>
                                    </div>
                                  </Col>
                                </>
                              }

                              {((props.oonwardsupp!=="" && props.oonwardsupp==="0") || state.baggageOptions.length === 0) &&
                                <>
                                  <Col span={12}>
                                    <div className="rb-top">
                                      <div className="info">
                                        <span className="smallFlight">
                                          <i className="icon icon-flight"></i>
                                        </span>
                                        <span className="route">{props.oonwardroutesrc}</span>
                                        <span className="rightArrow">
                                          <i className="icon icon-next-long-arrow-right-svgrepo-com"></i>
                                        </span>
                                        <span className="route">
                                          {props.oonwardroutedest}
                                        </span>
                                      </div>
                                    </div>
                                    <div className="addExtraIncluded">
                                      <div className="addExtraBaggage">
                                        <Title level={5} className="optionNotAvail">{context.translation && context.translation.bagOptNotAvail}</Title>
                                      </div>
                                    </div>
                                  </Col>
                                </>
                              }
                              {props.returnsupp !== "" && props.returnsupp !=="0" && state.baggageReturnOptions.length !== 0 &&
                                <>                           
                                  <Col span={12}>
                                    <div className="rb-top">
                                      <div className="info">
                                        <span className="smallFlight">
                                          <i className="icon icon-flight"></i>
                                        </span>
                                        <span className="route">{props.returnroutesrc}</span>
                                        <span className="rightArrow">
                                          <i className="icon icon-next-long-arrow-right-svgrepo-com"></i>
                                        </span>
                                        <span className="route">
                                          {props.returnroutedest}
                                        </span>
                                      </div>
                                    </div>
                                    <div className="addExtraIncluded">
                                      <div>
                                        <span className="included">{context.translation && context.translation.included}</span>
                                        <span className="checkin">
                                          {context.translation && context.translation.checkin}: {""} 
                                          {props.srequest.origns[0].destorigins[0].bgd.filter((b) => b.type === "Checkin")[0].qun}&nbsp;
                                          {props.srequest.origns[0].destorigins[0].bgd.filter((b) => b.type === "Checkin")[0].wt}
                                        </span>
                                      </div>
                                      <div className="addExtraBaggage">
                                        <Title level={5}>{context.translation && context.translation.addExtBag}</Title>
                                        <Form.Item label="">                                    
                                          <AddBaggage 
                                            sendBagprice={updateBagprice(pasenger.ty, pasenger.fesno, idx, "R")} 
                                            // bagList={state.alJazeeraBooking && props.fareresp.data[0].sup == 3 ? getEachKeySSR(state.baggageReturnOptions,pasenger.passengerKey) : state.baggageReturnOptions} 
                                            bagList={state.alJazeeraBooking && props.fareresp.data[0].sup == 3 ? state.baggageReturnOptions : state.baggageReturnOptions} 
                                            // bagList={state.baggageOptions}
                                            disable={props.disable} 
                                          />
                                        </Form.Item>
                                      </div>
                                    </div>
                                  </Col>
                                </>
                              }

                            </Row>
                          </div>
                        {/* </>          
                      )}                           */}
                    </>
                  )}

                  {props.srequest.sty!==undefined && props.srequest.sty==="split" && 
                    (props.oonwardsupp!=="" && props.returnsupp!=="" && (props.oonwardsupp!=="0" || props.returnsupp!=="0")) && 
                      <>                                
                        {/* {props.oonwardsupp!=="" && props.oonwardsupp!=="0" &&  state.baggageOptions.length!==0 && (
                          <> */}
                        
                          {/* Add Baggage for LCC */}
                          <div className="addExtra">
                            <div>
                              <Title
                                level={5}
                                className={showBaggage && "active"}
                                onClick={baggageTab}
                              >
                                <i className="icon icon-Trips"></i> {context.translation && context.translation.addBag}
                              </Title>
                            </div>
                            {(state.mealOptions.length !== 0 || state.mealReturnOptions.length !== 0) && (
                              <div>
                                <Title 
                                  level={5}
                                  className={showMeals && "active"}
                                  onClick={mealsTab}
                                >
                                  <i className="icon icon-Meal"></i> {context.translation && context.translation.addMeal}
                                </Title>
                              </div>
                            )}
                            {/* <div>
                              <Title level={5}>
                                <i className="icon icon-Special-Request"></i> Special
                                Request
                              </Title>
                            </div> */}
                            {/* <div>
                              <Title level={5}>
                                <i className="icon icon-flight-product"></i> Add
                                Frequent Flyer
                              </Title>
                            </div> */}
                          </div>
                          {/* </>
                        )} */}


                      {/* Add Baggage Start Here */}
                      {/* {showBaggage && (
                        <> */}
                          <div className={showBaggage ? "addExtraInner" : "addExtraInner hideBaggage"}>
                            <Row gutter={20}>
                                  
                                  {props.oonwardsupp!=="" && props.oonwardsupp!=="0" &&  state.baggageOptions.length!==0 &&
                                    <>
                                      <Col span={12}>
                                        <div className="rb-top">
                                          <div className="info">
                                            <span className="smallFlight">
                                              <i className="icon icon-flight"></i>
                                            </span>
                                            <span className="route">{props.oonwardroutesrc}</span>
                                            <span className="rightArrow">
                                              <i className="icon icon-next-long-arrow-right-svgrepo-com"></i>
                                            </span>
                                            <span className="route">
                                              {props.oonwardroutedest}
                                            </span>
                                          </div>
                                        </div>
                                        <div className="addExtraIncluded">
                                          <div>
                                            <span className="included">{context.translation && context.translation.included}</span>
                                            <span className="checkin">
                                              {context.translation && context.translation.checkin}: {""}  
                                              {props.srequest.origns[0].srcorigns[0].bgd.filter((b) => b.type === "Checkin")[0].qun}&nbsp;
                                              {props.srequest.origns[0].srcorigns[0].bgd.filter((b) => b.type === "Checkin")[0].wt}
                                              {/* <i className="icon icon-Info"></i> */}
                                            </span>
                                          </div>
                                          <div className="addExtraBaggage">
                                            <Title level={5}>{context.translation && context.translation.addExtBag}</Title>
                                            <Form.Item label="">    
                                              <AddBaggage 
                                                sendBagprice={updateBagprice(pasenger.ty,pasenger.fesno,idx,"O")} 
                                                // bagList={state.alJazeeraBooking && props.fareresp.data[0].sup == 3 ? getEachKeySSR(state.baggageOptions,pasenger.passengerKey) : state.baggageOptions} 
                                                bagList={state.alJazeeraBooking && props.fareresp.data[0].sup == 3 ? state.baggageOptions : state.baggageOptions} 
                                                // bagList={state.baggageOptions}
                                                disable={props.disable}
                                              />
                                            </Form.Item>
                                          </div>
                                        </div>
                                      </Col>
                                    </>
                                  }

                                  {((props.oonwardsupp!=="" && props.oonwardsupp==="0") || state.baggageOptions.length === 0) &&
                                    <>                         
                                      <Col span={12}>
                                        <div className="rb-top">
                                          <div className="info">
                                            <span className="smallFlight">
                                              <i className="icon icon-flight"></i>
                                            </span>
                                            <span className="route">{props.oonwardroutesrc}</span>
                                            <span className="rightArrow">
                                              <i className="icon icon-next-long-arrow-right-svgrepo-com"></i>
                                            </span>
                                            <span className="route">
                                              {props.oonwardroutedest}
                                            </span>
                                          </div>
                                        </div>
                                        <div className="addExtraIncluded">
                                          <div className="addExtraBaggage">
                                            <Title level={5} className="optionNotAvail">{context.translation && context.translation.bagOptNotAvail}</Title>
                                          </div>
                                        </div>
                                      </Col>
                                    </>
                                  }

                                  {((props.returnsupp!=="" && props.returnsupp==="0") || state.baggageReturnOptions.length === 0) &&
                                    <>                          
                                      <Col span={12}>
                                        <div className="rb-top">
                                          <div className="info">
                                            <span className="smallFlight">
                                              <i className="icon icon-flight"></i>
                                            </span>
                                            <span className="route">{props.returnroutesrc}</span>
                                            <span className="rightArrow">
                                              <i className="icon icon-next-long-arrow-right-svgrepo-com"></i>
                                            </span>
                                            <span className="route">
                                              {props.returnroutedest}
                                            </span>
                                          </div>
                                        </div>
                                        <div className="addExtraIncluded">
                                          <div className="addExtraBaggage">
                                            <Title level={5} className="optionNotAvail">{context.translation && context.translation.bagOptNotAvail}</Title>
                                          </div>
                                        </div>
                                      </Col>
                                    </>
                                  }

                                  {props.returnsupp !== "" && props.returnsupp !=="0" && state.baggageReturnOptions.length !== 0 &&
                                    <>                                                        
                                      <Col span={12}>
                                        <div className="rb-top">
                                          <div className="info">
                                            <span className="smallFlight">
                                              <i className="icon icon-flight"></i>
                                            </span>
                                            <span className="route">{props.returnroutesrc}</span>
                                            <span className="rightArrow">
                                              <i className="icon icon-next-long-arrow-right-svgrepo-com"></i>
                                            </span>
                                            <span className="route">
                                              {props.returnroutedest}
                                            </span>
                                          </div>
                                        </div>
                                        <div className="addExtraIncluded">
                                          <div>
                                            <span className="included">{context.translation && context.translation.included}</span>
                                            <span className="checkin">
                                              {context.translation && context.translation.checkin}: {""} 
                                              {props.srequest.origns[0].destorigins[0].bgd.filter((b) => b.type === "Checkin")[0].qun}&nbsp;
                                              {props.srequest.origns[0].destorigins[0].bgd.filter((b) => b.type === "Checkin")[0].wt}
                                            </span>
                                          </div>
                                          <div className="addExtraBaggage">
                                            <Title level={5}>{context.translation && context.translation.addExtBag}</Title>
                                            <Form.Item label="">   
                                              <AddBaggage 
                                                sendBagprice={updateBagprice(pasenger.ty, pasenger.fesno, idx, "R")} 
                                                // bagList={state.alJazeeraBooking && props.fareresp.data[0].sup == 3 ? getEachKeySSR(state.baggageReturnOptions,pasenger.passengerKey) : state.baggageReturnOptions} 
                                                bagList={state.alJazeeraBooking && props.fareresp.data[0].sup == 3 ? state.baggageReturnOptions : state.baggageReturnOptions} 
                                                // bagList={state.baggageOptions}
                                                disable={props.disable} 
                                              />        
                                            </Form.Item>
                                          </div>
                                        </div>
                                      </Col>
                                    </>
                                  }
                                  </Row>
                                </div>
                              {/* </>
                            )} */}
                          </>
                        }

                      {/* {showMeals && ( */}
                        {/* <> */}
                          {/* Meals for normal flow */}
                        {props.srequest.sty!==undefined && props.srequest.sty==="normal" && props.oonwardsupp!=="" && props.oonwardsupp!=="0" && (
                        <>
                          <div className={showMeals ? "addExtraInner" : "addExtraInner hideMeal"}>
                            {
                              (state.mealOptions.length !== 0 || state.mealReturnOptions.length !== 0) && (
                                <div className="flightMealWrap">
                                  {state.alJazeeraBooking ? (
                                    <>
                                      <div defaultactivekey="onml" id="roots" className="onmlrtmlWrap">
                                        {state.mealOptions.length !== 0 && (
                                          <div eventkey="onml" title={props.oonwardroute} className="onml">
                                            <Row gutter={20}>
                                              {state.mealOptions.length !== 0 &&
                                                state.mealOptions.map((each, index) => (
                                                  <React.Fragment key={index}>
                                                    <Col span={12}>
                                                      {/* <label className="mealLabel">{each.route}</label> */}
                                                      <div eventkey="dtp"> 
                                                        <div className="rb-top">
                                                          <div className="info">                                 
                                                            <span className="smallFlight">
                                                              <i className="icon icon-flight"></i>
                                                            </span>
                                                            <span className="route">{each.route}</span>
                                                            {/* <span className="rightArrow">
                                                              <i className="icon icon-next-long-arrow-right-svgrepo-com"></i>
                                                            </span> */}
                                                            {/* <span className="route">
                                                              {props.each.route}
                                                            </span> */}
                                                          </div>
                                                        </div>
                                                        
                                                        <div className="addMealWrapper">
                                                          <AddMeal
                                                           base_sup_exrate={props.base_sup_exrate}
                                                           cur={props.srequest.cur}
                                                            sendMealprice={updateMealprice(
                                                              pasenger.ty,
                                                              pasenger.fesno,
                                                              idx,
                                                              "O",
                                                              each.route
                                                            )}
                                                            passengerKey={pasenger.passengerKey}
                                                            mealList={
                                                              props.fareresp.data[0].sup == 3
                                                                ? getEachKeySSR(
                                                                    each.optins,
                                                                    pasenger.passengerKey
                                                                  )
                                                                : each.optins
                                                            }
                                                            // mealList={each.optins}
                                                            disable={props.disable}
                                                          />
                                                        </div>
                                                      </div>

                                          
                                                    </Col>
                                                  </React.Fragment>
                                                ))}
                                            </Row>
                                          </div>
                                        )}

                                        {state.mealReturnOptions.length !== 0 && (
                                          <div eventkey="rtml" title={props.returnroute} className="rtml">
                                            <Row gutter={20}>
                                              {state.mealReturnOptions.length !== 0 &&
                                                state.mealReturnOptions.map((each, index) => (
                                                  <React.Fragment key={index}>
                                                    <Col span={12}>
                                                      {/* <label className="mealLabel">{each.route}</label> */}
                                                      <div eventkey="dtp"> 
                                                        <div className="rb-top">
                                                          <div className="info">                                 
                                                            <span className="smallFlight">
                                                              <i className="icon icon-flight"></i>
                                                            </span>
                                                            <span className="route">{each.route}</span>
                                                            {/* <span className="rightArrow">
                                                              <i className="icon icon-next-long-arrow-right-svgrepo-com"></i>
                                                            </span> */}
                                                            {/* <span className="route">
                                                              {props.returnroutedest}
                                                            </span> */}
                                                          </div>
                                                        </div>
                                                        
                                                        <div className="addMealWrapper">
                                                          <AddMeal
                                                            base_sup_exrate={props.base_sup_exrate}
                                                            cur={props.srequest.cur}
                                                            sendMealprice={updateMealprice(
                                                              pasenger.ty,
                                                              pasenger.fesno,
                                                              idx,
                                                              "R",
                                                              each.route
                                                            )}
                                                            passengerKey={pasenger.passengerKey}
                                                            mealList={
                                                              props.fareresp.data[0].sup == 3
                                                                ? getEachKeySSR(
                                                                    each.optins,
                                                                    pasenger.passengerKey
                                                                  )
                                                                : each.optins
                                                            }
                                                            // mealList={each.optins}
                                                            disable={props.disable}
                                                          />
                                                        </div>
                                                      </div>
                                                    </Col>
                                                  </React.Fragment>
                                                ))}
                                            </Row>
                                          </div>
                                        )}
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      <div defaultactivekey="dtp" id="roots">    
                                      <Row gutter={20}>
                                        <Col span={12}>
                                        <div eventkey="dtp"> 
                                          <div className="rb-top">
                                            <div className="info">                                 
                                              <span className="smallFlight">
                                                <i className="icon icon-flight"></i>
                                              </span>
                                              <span className="route">{props.oonwardroutesrc}</span>
                                              <span className="rightArrow">
                                                <i className="icon icon-next-long-arrow-right-svgrepo-com"></i>
                                              </span>
                                              <span className="route">
                                                {props.oonwardroutedest}
                                              </span>
                                            </div>
                                          </div>
                                        
                                        <div className="addMealWrapper">
                                          <AddMeal
                                            base_sup_exrate={props.base_sup_exrate}
                                            cur={props.srequest.cur}
                                            sendMealprice={updateMealprice(
                                              pasenger.ty,
                                              pasenger.fesno,
                                              idx,
                                              "O",
                                              props.oonwardroute
                                            )}
                                            passengerKey={pasenger.passengerKey}
                                            mealList={state.mealOptions}
                                            disable={props.disable}
                                          />
                                        </div>
                                      </div>
                                        </Col>
                                      </Row>                              
                                        

                                      {props.returnroute !== "" && (
                                        <>
                                        <Row gutter={20}>
                                          <Col span={12}>
                                          <div eventkey="dtp"> 
                                          <div className="rb-top">
                                            <div className="info">                                 
                                              <span className="smallFlight">
                                                <i className="icon icon-flight"></i>
                                              </span>
                                              <span className="route">{props.returnroutesrc}</span>
                                              <span className="rightArrow">
                                                <i className="icon icon-next-long-arrow-right-svgrepo-com"></i>
                                              </span>
                                              <span className="route">
                                                {props.returnroutedest}
                                              </span>
                                            </div>
                                          </div>
                                        
                                        <div className="addMealWrapper">
                                          <AddMeal
                                            base_sup_exrate={props.base_sup_exrate}
                                            cur={props.srequest.cur}
                                            sendMealprice={updateMealprice(
                                              pasenger.ty,
                                              pasenger.fesno,
                                              idx,
                                              "R",
                                              props.returnroute
                                            )}
                                            mealList={state.mealReturnOptions}
                                            disable={props.disable}
                                          />
                                        </div>
                                      </div>
                                          </Col>
                                        </Row>
                                        
                                      </>
                                      )}                 
                                      
                                      </div>
                                    </>
                                  )}
                                </div>
                              )
                            }
                            </div>
                            </>
                          )}

                          {/* meals for split flow */}
                          {props.srequest.sty!==undefined && props.srequest.sty==="split" && (props.oonwardsupp!=="" && props.returnsupp!=="" && (props.oonwardsupp!=="0" || props.returnsupp!=="0")) && (
                          <>
                          <div className={showMeals ? "addExtraInner" : "addExtraInner hideMeal"}>
                            {
                              (state.mealOptions.length !== 0 || state.mealReturnOptions.length !== 0) && (
                                <div className="flightMealWrap">
                                  {state.alJazeeraBooking ? (
                                    <>
                                      <div defaultactivekey="onml" id="roots" className="onmlrtmlWrap">
                                        {state.mealOptions.length !== 0 && (
                                          <div eventkey="onml" title={props.oonwardroute} className="onml">
                                            <Row gutter={20}>
                                              {state.mealOptions.length !== 0 &&
                                                state.mealOptions.map((each, index) => (
                                                  <React.Fragment key={index}>
                                                    <Col span={12}>
                                                      <div eventkey="dtp"> 
                                                        <div className="rb-top">
                                                          <div className="info">                                 
                                                            <span className="smallFlight">
                                                              <i className="icon icon-flight"></i>
                                                            </span>
                                                            <span className="route">{each.route}</span>
                                                          </div>
                                                        </div>
                                                        
                                                        <div className="addMealWrapper">
                                                          <AddMeal
                                                            base_sup_exrate={props.base_sup_exrate}
                                                            cur={props.srequest.cur}
                                                            sendMealprice={updateMealprice(
                                                              pasenger.ty,
                                                              pasenger.fesno,
                                                              idx,
                                                              "O",
                                                              each.route
                                                            )}
                                                            passengerKey={pasenger.passengerKey}
                                                            mealList={
                                                              props.fareresp.data[0].sup == 3
                                                                ? getEachKeySSR(
                                                                    each.optins,
                                                                    pasenger.passengerKey
                                                                  )
                                                                : each && each.optins && each.jtype !== undefined ? each.optins : each.optins
                                                            }
                                                            // mealList={each && each.optins && each.jtype !== undefined ? each.optins : each.optins}
                                                            disable={props.disable}
                                                          />
                                                        </div>
                                                      </div>
                                                    </Col>
                                                  </React.Fragment>
                                                ))}
                                            </Row>
                                          </div>
                                        )}

                                        {state.mealReturnOptions.length !== 0 && (
                                          <div eventkey="rtml" title={props.returnroute} className="rtml">
                                            <Row gutter={20}>
                                              {state.mealReturnOptions.length !== 0 &&
                                                state.mealReturnOptions.map((each, index) => (
                                                  <React.Fragment key={index}>
                                                    <Col span={12}>
                                                      <div eventkey="dtp"> 
                                                        <div className="rb-top">
                                                          <div className="info">                                 
                                                            <span className="smallFlight">
                                                              <i className="icon icon-flight"></i>
                                                            </span>
                                                            <span className="route">{each.route}</span>
                                                          </div>
                                                        </div>
                                                        {state.airarabiairtn ? (
                                                          <div className="addMealWrapper">
                                                          <AddMeal
                                                            base_sup_exrate={props.base_sup_exrate}
                                                            cur={props.srequest.cur}
                                                            sendMealprice={updateMealprice(
                                                              pasenger.ty,
                                                              pasenger.fesno,
                                                              idx,
                                                              "R",
                                                              each.route
                                                            )}
                                                            passengerKey={pasenger.passengerKey}
                                                            mealList={
                                                              props.fareresp &&
                                                              props.fareresp.data &&
                                                              props.fareresp.data[0] &&
                                                              props.fareresp.data[0].sup == 3
                                                                ? getEachKeySSR(
                                                                    each.optins,
                                                                    pasenger.passengerKey
                                                                  )
                                                                : each && each.optins && each.optins[0]
                                                            }
                                                            // mealList={each && each.optins && each.optins[0]}
                                                            disable={props.disable}
                                                          />
                                                        </div>
                                                        ) : (
                                                          <div className="addMealWrapper">
                                                          <AddMeal
                                                            base_sup_exrate={props.base_sup_exrate}
                                                            cur={props.srequest.cur}
                                                            sendMealprice={updateMealprice(
                                                              pasenger.ty,
                                                              pasenger.fesno,
                                                              idx,
                                                              "R",
                                                              each.route
                                                            )}
                                                            passengerKey={pasenger.passengerKey}
                                                            mealList={
                                                              props.fareresp &&
                                                              props.fareresp.data &&
                                                              props.fareresp.data[0] &&
                                                              props.fareresp.data[0].sup == 3
                                                                ? getEachKeySSR(
                                                                    each.optins,
                                                                    pasenger.passengerKey
                                                                  )
                                                                : each && each.optins && each.optins
                                                            }
                                                            // mealList={each && each.optins && each.optins}
                                                            disable={props.disable}
                                                          />
                                                        </div>)
                                                        }
                                                      </div>                                     
                                                    </Col>
                                                  </React.Fragment>
                                                ))}
                                            </Row>
                                          </div>
                                        )}
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      <div defaultactivekey="dtp" id="roots">
                                        {props.oonwardsupp !== "" && props.oonwardsupp !== "0" && (
                                          <div eventkey="dtp"> 
                                            <div className="rb-top">
                                              <div className="info">                                 
                                                <span className="smallFlight">
                                                  <i className="icon icon-flight"></i>
                                                </span>
                                                <span className="route">{props.oonwardroutesrc}</span>
                                                <span className="rightArrow">
                                                  <i className="icon icon-next-long-arrow-right-svgrepo-com"></i>
                                                </span>
                                                <span className="route">
                                                  {props.oonwardroutedest}
                                                </span>
                                              </div>
                                            </div>
                                            
                                            <div className="addMealWrapper">
                                              <AddMeal
                                                base_sup_exrate={props.base_sup_exrate}
                                                cur={props.srequest.cur}
                                                sendMealprice={updateMealprice(
                                                  pasenger.ty,
                                                  pasenger.fesno,
                                                  idx,
                                                  "O",
                                                  props.oonwardroute
                                                )}
                                                mealList={state.mealOptions}
                                                disable={props.disable}
                                              />
                                            </div>
                                          </div>
                                        )}

                                       
                                        {props.returnsupp !== "" &&
                                          props.returnsupp !== "0" &&
                                          state.mealReturnOptions.length !== 0 && (
                                          <div eventkey="dtp"> 
                                            <div className="rb-top">
                                              <div className="info">                                 
                                                <span className="smallFlight">
                                                  <i className="icon icon-flight"></i>
                                                </span>
                                                <span className="route">{props.returnroutesrc}</span>
                                                <span className="rightArrow">
                                                  <i className="icon icon-next-long-arrow-right-svgrepo-com"></i>
                                                </span>
                                                <span className="route">
                                                  {props.returnroutedest}
                                                </span>
                                              </div>
                                            </div>
                                            
                                            <div className="addMealWrapper">
                                              <AddMeal
                                                base_sup_exrate={props.base_sup_exrate}
                                                cur={props.srequest.cur}
                                                sendMealprice={updateMealprice(
                                                  pasenger.ty,
                                                  pasenger.fesno,
                                                  idx,
                                                  "R",
                                                  props.returnroute
                                                )}
                                                mealList={state.mealReturnOptions}
                                                disable={props.disable}
                                              />
                                            </div>
                                          </div>
                                          )}

                                      </div>
                                    </>
                                  )}
                                </div>
                              )
                            }
                            </div>
                            </>
                          )}
                        {/* </> */}
                      {/* )} */}

                  {(pasenger.ty === "ADT" || pasenger.ty === "CHD") &&
                    ((props.srequest.sty === "normal" && props.oonwardsupp !== "" && props.oonwardsupp === "0") || (props.srequest.sty === "split" && props.oonwardsupp !== "" && props.returnsupp !== "" && props.oonwardsupp === "0" && props.returnsupp === "0")) && (
                      <div className="travellerDetailFooter smsf">
                        {/* for FSC Flow */}
                        <div className="addExtra">
                          <div>
                            <Title
                              level={5}
                              className={selectedIndexes[idx] === 0 && "active"}
                              // onClick={splreqFun}
                              onClick={() => handleExtrasClick(idx, 0)}
                            >
                              <i className="icon icon-Special-Request"></i> {context.translation && context.translation.specialPref}
                            </Title>
                          </div>
                          <div>
                            <Title
                              level={5}
                              className={selectedIndexes[idx] === 1 && "active"}
                              // onClick={mealPrefFun}
                              onClick={() => handleExtrasClick(idx, 1)}
                            >
                              <i className="icon icon-Meal"></i> {context.translation && context.translation.mealPref}
                            </Title>
                          </div>
                          <div>
                            <Title
                              level={5}
                              className={selectedIndexes[idx] === 2 && "active"}
                              // onClick={seatPrefFun}
                              onClick={() => handleExtrasClick(idx, 2)}
                            >
                              <i className="icon icon-Special-Request"></i> {context.translation && context.translation.seatPref}
                            </Title>
                          </div>
                          <div>
                            <Title
                              level={5}
                              className={selectedIndexes[idx] === 3 && "active"}
                              // onClick={frqFlyerFun}
                              onClick={() => handleExtrasClick(idx, 3)}
                            >
                              <i className="icon icon-flight-product"></i> {context.translation && context.translation.ff}
                            </Title>
                          </div>
                        </div>
                          {selectedIndexes[idx] === 0 ? (
                            <div className="addExtraInner">
                              <div className="specialPreference">
                                <Form.Item>
                                  <label className="notRequiredField">
                                    {context.translation && context.translation.specialPref}
                                  </label>
                                  <>
                              {/* {(sessionStorage.getItem("SEL_LAG") === null || sessionStorage.getItem("SEL_LAG") === "English") && */}
                                {/* <Select
                                    defaultValue="Select"
                                    showSearch
                                    optionFilterProp="children"
                                    name="opref.sa"
                                    value={
                                      pasenger.opref.sa
                                        ? pasenger.opref.sa
                                        : undefined
                                    }
                                    className="selectBoxShadow"
                                    onChange={handleChangeSelect(
                                      idx + 1,
                                      "spe",
                                      "sa",
                                      pasenger.ty
                                    )}
                                    filterOption={(input, option) =>
                                      (option?.label ?? "")
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                    }
                                    options={specialPrefs}
                                  /> */}                                 
                                  
                                  <Select
                                    defaultValue={context.translation && context.translation.select}
                                    placeholder={context.translation && context.translation.select}
                                    showSearch
                                    optionFilterProp="children"
                                    name="opref.sa"
                                    value={
                                      pasenger.opref.sa
                                        ? pasenger.opref.sa
                                        : undefined
                                    }
                                    className="selectBoxShadow double"
                                    onChange={handleChangeSelect(
                                      idx + 1,
                                      "spe",
                                      "sa",
                                      pasenger.ty
                                    )}
                                    filterOption={(input, option) =>
                                      (option?.label ?? "")
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                    }
                                  >
                                  <Option value="Select">{context.translation && context.translation.select}</Option>
                                    {specialPrefs.map((option, index) => (
                                      <Option key={index} value={option.value}>
                                        {option.label}
                                      </Option>
                                    ))}
                                </Select>
                              {/* } */}
                              {/* {(sessionStorage.getItem("SEL_LAG") === "Arabic") &&
                                <Select
                                    defaultValue="اختار"
                                    showSearch
                                    optionFilterProp="children"
                                    name="opref.sa"
                                    value={
                                      pasenger.opref.sa
                                        ? pasenger.opref.sa
                                        : undefined
                                    }
                                    className="selectBoxShadow"
                                    onChange={handleChangeSelect(
                                      idx + 1,
                                      "spe",
                                      "sa",
                                      pasenger.ty
                                    )}
                                    filterOption={(input, option) =>
                                      (option?.label ?? "")
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                    }
                                    options={specialPrefs}
                                  />
                              } */}
                            </>
                                </Form.Item>
                              </div>
                            </div>
                          ) : null}
                          {selectedIndexes[idx] === 1 ? (
                            <div className="addExtraInner">
                              <div className="mealPreference">
                                <Form.Item>
                                  <label className="notRequiredField">
                                    {context.translation && context.translation.mealPref}
                                  </label>
                                  <>
                              {/* {(sessionStorage.getItem("SEL_LAG") === null || sessionStorage.getItem("SEL_LAG") === "English") && */}
                                 {/* <Select
                                    defaultValue= "Select"
                                    showSearch
                                    optionFilterProp="children"
                                    name="isc"
                                    value={
                                      pasenger.opref.mp
                                        ? pasenger.opref.mp
                                        : undefined
                                    }
                                    className="selectBoxShadow"
                                    onChange={handleChangeSelect(
                                      idx + 1,
                                      "meal",
                                      "mp",
                                      pasenger.ty
                                    )}
                                    filterOption={(input, option) =>
                                      (option?.label ?? "")
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                    }
                                    options={mealPrefs}
                                  />                               */}
                                  
                                  <Select
                                    defaultValue={context.translation && context.translation.select}
                                    placeholder={context.translation && context.translation.select}
                                    showSearch
                                    optionFilterProp="children"
                                    name="isc"
                                    value={
                                      pasenger.opref.mp
                                        ? pasenger.opref.mp
                                        : undefined
                                    }
                                    className="selectBoxShadow double"
                                    onChange={handleChangeSelect(
                                      idx + 1,
                                      "meal",
                                      "mp",
                                      pasenger.ty
                                    )}
                                    filterOption={(input, option) =>
                                      (option?.label ?? "")
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                    }
                                  >
                                  <Option value="Select">{context.translation && context.translation.select}</Option>
                                    {mealPrefs.map((option, index) => (
                                      <Option key={index} value={option.value}>
                                        {option.label}
                                      </Option>
                                    ))}
                                </Select>
                              {/* } */}
                              {/* {(sessionStorage.getItem("SEL_LAG") === "Arabic") &&
                               <Select
                                    defaultValue="اختار"
                                    showSearch
                                    optionFilterProp="children"
                                    name="isc"
                                    value={
                                      pasenger.opref.mp
                                        ? pasenger.opref.mp
                                        : undefined
                                    }
                                    className="selectBoxShadow"
                                    onChange={handleChangeSelect(
                                      idx + 1,
                                      "meal",
                                      "mp",
                                      pasenger.ty
                                    )}
                                    filterOption={(input, option) =>
                                      (option?.label ?? "")
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                    }
                                    options={mealPrefs}
                                  />
                              } */}
                            </>
                                </Form.Item>
                              </div>
                            </div>
                          ) : null}
                          {selectedIndexes[idx] === 2 ? (
                            <div className="addExtraInner">
                              <div className="seatPreference">
                                <Form.Item>
                                  <label className="notRequiredField">
                                    {context.translation && context.translation.seatPref}
                                  </label>
                                  <>
                              {/* {(sessionStorage.getItem("SEL_LAG") === null || sessionStorage.getItem("SEL_LAG") === "English") && */}
                                 {/* <Select
                                    defaultValue="Select"
                                    showSearch
                                    optionFilterProp="children"
                                    name="isc"
                                    value={
                                      pasenger.opref.sp
                                        ? pasenger.opref.sp
                                        : undefined
                                    }
                                    className="selectBoxShadow"
                                    onChange={handleChangeSelect(
                                      idx + 1,
                                      "seat",
                                      "sp",
                                      pasenger.ty
                                    )}
                                    filterOption={(input, option) =>
                                      (option?.label ?? "")
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                    }
                                    options={seatPrefs}
                                  /> */}

                                  
                                  <Select
                                    defaultValue={context.translation && context.translation.select}
                                    placeholder={context.translation && context.translation.select}
                                    showSearch
                                    optionFilterProp="children"
                                    name="isc"
                                    value={
                                      pasenger.opref.sp
                                        ? pasenger.opref.sp
                                        : undefined
                                    }
                                    className="selectBoxShadow double"
                                    onChange={handleChangeSelect(
                                      idx + 1,
                                      "seat",
                                      "sp",
                                      pasenger.ty
                                    )}
                                    filterOption={(input, option) =>
                                      (option?.label ?? "")
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                    }
                                  >
                                  <Option value="Select">{context.translation && context.translation.select}</Option>
                                    {seatPrefs.map((option, index) => (
                                      <Option key={index} value={option.value}>
                                        {option.label}
                                      </Option>
                                    ))}
                                </Select>


                              {/* } */}
                              {/* {(sessionStorage.getItem("SEL_LAG") === "Arabic") &&
                                <Select
                                    defaultValue="اختار"
                                    showSearch
                                    optionFilterProp="children"
                                    name="isc"
                                    value={
                                      pasenger.opref.sp
                                        ? pasenger.opref.sp
                                        : undefined
                                    }
                                    className="selectBoxShadow"
                                    onChange={handleChangeSelect(
                                      idx + 1,
                                      "seat",
                                      "sp",
                                      pasenger.ty
                                    )}
                                    filterOption={(input, option) =>
                                      (option?.label ?? "")
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                    }
                                    options={seatPrefs}
                                  />
                              } */}
                            </>
                                </Form.Item>
                              </div>
                            </div>
                          ) : null}
                          {selectedIndexes[idx] === 3 ? (
                            <div className="addExtraInner">
                              <div className="frequentFlyer onlyFlex">
                                <Form.Item>
                                  <label className="notRequiredField">
                                    {context.translation && context.translation.airlineNam}
                                  </label>
                                  <>
                              {/* {(sessionStorage.getItem("SEL_LAG") === null || sessionStorage.getItem("SEL_LAG") === "English") && */}
                                {/* <Select 
                                    defaultValue="Select"
                                    showSearch
                                    optionFilterProp="children"
                                    name="ffinfo.air"
                                    value={
                                      pasenger.ffinfo.air
                                        ? pasenger.ffinfo.air
                                        : undefined
                                    }
                                    className="selectBoxShadow"
                                    onChange={handleChangeSelect(
                                      idx + 1,
                                      "freq",
                                      "spair",
                                      pasenger.ty
                                    )}
                                    filterOption={(input, option) =>
                                      (option?.label ?? "")
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                    }
                                    options={state.airlines}
                                  /> */}
                                  
                                  <Select
                                    defaultValue={context.translation && context.translation.select}
                                    placeholder={context.translation && context.translation.select}
                                    showSearch
                                    optionFilterProp="children"
                                    name="ffinfo.air"
                                    value={
                                      pasenger.ffinfo.air
                                        ? pasenger.ffinfo.air
                                        : undefined
                                    }
                                    className="selectBoxShadow double"
                                    onChange={handleChangeSelect(
                                      idx + 1,
                                      "freq",
                                      "spair",
                                      pasenger.ty
                                    )}
                                    filterOption={(input, option) =>
                                      (option?.label ?? "")
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                    }
                                  >
                                  <Option value="Select">{context.translation && context.translation.select}</Option>
                                    {state.airlines.map((option, index) => (
                                      <Option key={index} value={option.value}>
                                        {option.label}
                                      </Option>
                                    ))}
                                </Select>

                              {/* } */}
                              {/* {(sessionStorage.getItem("SEL_LAG") === "Arabic") &&
                               <Select 
                                    defaultValue="اختار"
                                    showSearch
                                    optionFilterProp="children"
                                    name="ffinfo.air"
                                    value={
                                      pasenger.ffinfo.air
                                        ? pasenger.ffinfo.air
                                        : undefined
                                    }
                                    className="selectBoxShadow"
                                    onChange={handleChangeSelect(
                                      idx + 1,
                                      "freq",
                                      "spair",
                                      pasenger.ty
                                    )}
                                    filterOption={(input, option) =>
                                      (option?.label ?? "")
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                    }
                                    options={state.airlines}
                                  />
                              } */}
                            </>
                                </Form.Item>
                                <Form.Item className="pRelative">
                                  <label className="notRequiredField">
                                    {context.translation && context.translation.freqNum}
                                  </label>
                                  <Input
                                    type="text"
                                    className="innerBoxShadow"
                                    name="ffinfo.ffnum"
                                    value={pasenger.ffinfo.ffnum}
                                    placeholder={context.translation && context.translation.en}
                                    onChange={handleChangeText(
                                      idx + 1,
                                      "ffnum",
                                      pasenger.ty
                                    )}
                                  />
                                </Form.Item>
                              </div>
                            </div>
                          ) : null}


                        


                      </div>
                    )}
                  {pasenger.inf !== "" && (
                    <div className="">
                      {/* <div className="travellerDetailsInfant"> */}
                      {/* <div className="travellerDetailHeader dFlex">
                    <div>
                      <Title level={5}>
                        <span className="traveller">
                          Traveller {pasenger.inf.fesno}
                          {pasenger.sno}
                        </span>
                        <span className="stages">Infant</span>
                      </Title>
                    </div>
                    <div>
                      <span>
                        <i className="icon icon-Plus"></i>
                      </span>
                    </div>
                  </div> */}
                      {/* <div className="travellerDetailBody"> */}
                      {/* <Row gutter={30}>
                      <Col span={24}>
                        <p>
                          <span>Note: </span>Please ensure the name you enter is
                          matching to your passport
                        </p>
                      </Col>
                      {state.infTraList.length !== 0 && (
                        <Col span={8}>
                          <Form.Item label="">
                            <Select
                              name="title"
                              defaultValue="Select"
                              placeholder="Select a person"
                              value={pasenger.tl}
                              onSelect={(value, event) =>
                                updatePax(value, event, "INF", idx + 1)
                              }
                              options={state.infTraList}
                            />
                          </Form.Item>
                        </Col>
                      )}
                    </Row> */}
                      {/* <Row gutter={30}>
                      <Col span={8}>
                        <Form.Item autoComplete="off" className="pRelative">
                          <label className="requiredField">First Name</label>
                          {pasenger.ty.toUpperCase() === "ADT" && (
                            <Input
                              addonBefore={
                                <Select
                                  name="title"
                                  defaultValue="Select"
                                  value={pasenger.tl?pasenger.tl:undefined}
                                  onChange={handleChangeSelect(
                                    idx + 1,
                                    "tl",
                                    "title",
                                    pasenger.ty
                                  )}
                                  options={adt_titles}
                                />
                              }
                              className="selectBoxShadow"
                              type="text"
                              name="fn"
                              value={pasenger.fn}
                              placeholder="Enter First Name"
                              onChange={handleChangeText(
                                idx + 1,
                                "fn",
                                pasenger.ty
                              )}
                            />
                          )}
                          {pasenger.ty.toUpperCase() === "CHD" && (
                            <Input
                              addonBefore={
                                <Select
                                  name="tl"
                                  defaultValue="Select"
                                  value={pasenger.tl?pasenger.tl:undefined}
                                  onChange={handleChangeSelect(
                                    idx + 1,
                                    "tl",
                                    "title",
                                    pasenger.ty
                                  )}
                                  options={chd_titles}
                                />
                              }
                              className="selectBoxShadow"
                              type="text"
                              name="fn"
                              value={pasenger.fn}
                              placeholder="Enter First Name"
                              onChange={handleChangeText(
                                idx + 1,
                                "fn",
                                pasenger.ty
                              )}
                            />
                          )}
                          <Input
                            addonBefore={
                              <Select
                                name="tl"
                                defaultValue="Select"
                                value={pasenger.inf.tl?pasenger.inf.tl:undefined}
                                onChange={handleChangeSelect(
                                  idx + 1,
                                  "tl",
                                  "title",
                                  "INF"
                                )}
                                options={inf_titles}
                              />
                            }
                            className="selectBoxShadow"
                            type="text"
                            name="fn"
                            value={pasenger.inf.fn}
                            placeholder="Enter First Name"
                            onChange={handleChangeText(idx + 1, "fn", "INF")}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item className="pRelative">
                          <label className="requiredField">Last Name</label>
                          <Input
                            type="text"
                            className="innerBoxShadow"
                            name="ln"
                            value={pasenger.inf.ln}
                            placeholder="Enter Last Name"
                            onChange={handleChangeText(idx + 1, "ln", "INF")}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item>
                          {pasenger.ty === "ADT" &&
                            <label className={state.docMandatory === "yes" || (props.oonwardsupp==="3" || props.returnsupp==="3"|| props.oonwardsupp==="4" || props.returnsupp==="4" || props.oonwardsupp==="5" || props.returnsupp==="5" || props.oonwardsupp==="6" || props.returnsupp==="6" || props.oonwardsupp==="7" || props.returnsupp==="7")? "requiredField" : "notRequiredField"}>
                              Date of Birth
                            </label>
                          }
                          {pasenger.ty === "CHD" &&
                            <label className="requiredField">
                              Date of Birth
                            </label>
                          }
                          <div className="onlyFlex">
                            <div className="dateMY month">
                              <Month
                                sendMonth={handleChangeSelect(
                                  idx + 1,
                                  "month",
                                  "dob",
                                  "INF"
                                )}
                              />
                            </div>
                            <div className="dateMY day">
                              <Days
                                sendDay={handleChangeSelect(
                                  idx + 1,
                                  "day",
                                  "dob",
                                  "INF"
                                )}
                              />
                            </div>
                            <div className="dateMY year">
                              <Years
                                updateYear={pasenger.dob_year}
                                type={pasenger.ty}
                                sendYear={handleChangeSelect(
                                  idx + 1,
                                  "year",
                                  "dob",
                                  "INF"
                                )}
                              />
                            </div>
                          </div>
                        </Form.Item>
                      </Col>
                    </Row> */}
                      {/* <div className="emailDivider"></div> */}
                      {/* <Row gutter={30}>
                      <Col span={8}>
                        <Form.Item className="pRelative">
                          <label className={state.docMandatory === "yes" || props.indpassportenable ? "requiredField" : "notRequiredField"}>
                            Passport Number
                          </label>
                          <Input
                            type="text"
                            className="innerBoxShadow"
                            name="num"
                            value={pasenger.doc.num}
                            placeholder="Enter Passport Number"
                            onChange={handleChangeText(
                              idx + 1,
                              "pnum",
                              pasenger.ty
                            )}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item className="pRelative">
                            <label className={state.docMandatory === "yes" || props.checkmandatoryflagnat === "true" ? "requiredField" : "notRequiredField"}>Nationality</label>
                          <Select
                            defaultValue="Select"
                            showSearch
                            optionFilterProp="children"
                            name="na"
                            value={pasenger.doc.na?pasenger.doc.na:undefined}
                            className="selectBoxShadow"
                            onChange={handleChangeSelect(
                              idx + 1,
                              "na",
                              "isc",
                              "INF"
                            )}
                            filterOption={(input, option) =>
                              (option?.label ?? "")
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            options={countrieslist}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item>
                          <label className={state.docMandatory === "yes" ? "requiredField" : "notRequiredField"}>
                            Issued Country
                          </label>
                          <Select
                            defaultValue="Select"
                            showSearch
                            optionFilterProp="children"
                            name="isc"
                            value={pasenger.doc.isc?pasenger.doc.isc:undefined}
                            className="selectBoxShadow"
                            onChange={handleChangeSelect(
                              idx + 1,
                              "isc",
                              "isd",
                              "INF"
                            )}
                            filterOption={(input, option) =>
                              (option?.label ?? "")
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            options={countrieslist}
                          />
                        </Form.Item>
                      </Col>
                    </Row> */}
                      {/* <Row gutter={30}>
                      <Col span={8}>
                        <Form.Item>
                          <label className="notRequiredField">Issued Date</label>
                          <div className="onlyFlex">
                            <div className="dateMY">
                              <Form.Item>
                                <div className="onlyFlex">
                                  <div className="dateMY month">
                                    <Month
                                      sendMonth={handleChangeSelect(
                                        idx + 1,
                                        "month",
                                        "isudate",
                                        "INF"
                                      )}
                                    />
                                  </div>
                                  <div className="dateMY day">
                                    <Days
                                      sendDay={handleChangeSelect(
                                        idx + 1,
                                        "day",
                                        "isudate",
                                        "INF"
                                      )}
                                    />
                                  </div>
                                  <div className="dateMY year">
                                    <Years
                                      updateYear={pasenger.doc.isd_year}
                                      type="isuDate"
                                      sendYear={handleChangeSelect(
                                        idx + 1,
                                        "year",
                                        "isudate",
                                        "INF"
                                      )}
                                    />
                                  </div>
                                </div>
                              </Form.Item>
                            </div>
                          </div>
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item>
                          <label className={state.docMandatory==="yes" || props.indpassportenable ? "requiredField" : "notRequiredField"}>Expiry Date</label>
                          <div className="onlyFlex">
                            <Form.Item>
                              <div className="onlyFlex">
                                <div className="dateMY">
                                  <Form.Item>
                                    <div className="onlyFlex">
                                      <div className="dateMY month">
                                        <Month
                                          sendMonth={handleChangeSelect(
                                            idx + 1,
                                            "month",
                                            "expiry",
                                            "INF"
                                          )}
                                        />
                                      </div>
                                      <div className="dateMY day">
                                        <Days
                                          sendDay={handleChangeSelect(
                                            idx + 1,
                                            "day",
                                            "expiry",
                                            "INF"
                                          )}
                                        />
                                      </div>
                                      <div className="dateMY year">
                                        <Years
                                          updateYear={pasenger.doc.exp_year}
                                          type="expDate"
                                          sendYear={handleChangeSelect(
                                            idx + 1,
                                            "year",
                                            "expiry",
                                            "INF"
                                          )}
                                        />
                                      </div>
                                    </div>
                                  </Form.Item>
                                </div>
                              </div>
                            </Form.Item>
                          </div>
                        </Form.Item>
                      </Col>
                    </Row> */}
                      {/* </div> */}
                      {/* {(pasenger.ty === "ADT" || pasenger.ty === "CHD") &&
                    (onward_sup === "0" || onward_sup === 0) && (
                      <div className="travellerDetailFooter">
                        <div className="addExtra">
                          <div>
                            <Title
                              level={5}
                              className={splReqShow && "active"}
                              onClick={splreqFun}
                            >
                              <i className="icon icon-Special-Request"></i> Special
                              Preference
                            </Title>
                          </div>
                          <div>
                            <Title
                              level={5}
                              className={mealPrefShow && "active"}
                              onClick={mealPrefFun}
                            >
                              <i className="icon icon-Meal"></i> {context.translation && context.translation.mealPref}
                            </Title>
                          </div>
                          <div>
                            <Title
                              level={5}
                              className={seatPrefShow && "active"}
                              onClick={seatPrefFun}
                            >
                              <i className="icon icon-Special-Request"></i> Seat
                              Preference
                            </Title>
                          </div>
                          <div>
                            <Title
                              level={5}
                              className={frqFlyerShow && "active"}
                              onClick={frqFlyerFun}
                            >
                              <i className="icon icon-flight-product"></i> Frequent
                              Flyer
                            </Title>
                          </div>
                        </div>
                        <div className="addExtraInner">
                          {splReqShow && (
                            <div className="specialPreference">
                              <Form.Item>
                                <Select
                                  defaultValue="All"
                                  showSearch
                                  optionFilterProp="children"
                                  name="opref.sa"
                                  value={pasenger.opref.sa}
                                  className="selectBoxShadow"
                                  onChange={handleChangeSelect(
                                    idx + 1,
                                    "spe",
                                    "sa",
                                    pasenger.ty
                                  )}
                                  filterOption={(input, option) =>
                                    (option?.label ?? "")
                                      .toLowerCase()
                                      .includes(input.toLowerCase())
                                  }
                                  options={specialPrefs}
                                />
                              </Form.Item>
                            </div>
                          )}
                          {mealPrefShow && (
                            <div className="mealPreference">
                              <Form.Item>
                                <Select
                                  defaultValue="All"
                                  showSearch
                                  optionFilterProp="children"
                                  name="isc"
                                  value={pasenger.opref.mp}
                                  className="selectBoxShadow"
                                  onChange={handleChangeSelect(
                                    idx + 1,
                                    "meal",
                                    "mp",
                                    pasenger.ty
                                  )}
                                  filterOption={(input, option) =>
                                    (option?.label ?? "")
                                      .toLowerCase()
                                      .includes(input.toLowerCase())
                                  }
                                  options={mealPrefs}
                                />
                              </Form.Item>
                            </div>
                          )}
                          {seatPrefShow && (
                            <div className="seatPreference">
                              <Form.Item>
                                <Select
                                  defaultValue="All"
                                  showSearch
                                  optionFilterProp="children"
                                  name="isc"
                                  value={pasenger.opref.sp}
                                  className="selectBoxShadow"
                                  onChange={handleChangeSelect(
                                    idx + 1,
                                    "seat",
                                    "sp",
                                    pasenger.ty
                                  )}
                                  filterOption={(input, option) =>
                                    (option?.label ?? "")
                                      .toLowerCase()
                                      .includes(input.toLowerCase())
                                  }
                                  options={seatPrefs}
                                />
                              </Form.Item>
                            </div>
                          )}
                          {frqFlyerShow && (
                            <div className="frequentFlyer dFlex">
                              <Form.Item>
                                <label className="requiredField">
                                  Airline Name
                                </label>
                                <Select
                                  defaultValue="All"
                                  showSearch
                                  optionFilterProp="children"
                                  name="ffinfo.air"
                                  value={pasenger.ffinfo.air}
                                  className="selectBoxShadow"
                                  onChange={handleChangeSelect(
                                    idx + 1,
                                    "freq",
                                    "spair",
                                    pasenger.ty
                                  )}
                                  filterOption={(input, option) =>
                                    (option?.label ?? "")
                                      .toLowerCase()
                                      .includes(input.toLowerCase())
                                  }
                                  options={state.airlines}
                                />
                              </Form.Item>
                              <Form.Item className="pRelative">
                                <label className="requiredField">
                                  Freq. Number
                                </label>
                                <Input
                                  type="text"
                                  className="innerBoxShadow"
                                  name="ffinfo.ffnum"
                                  value={pasenger.ffinfo.ffnum}
                                  placeholder="Enter  Number"
                                  onChange={handleChangeText(
                                    idx + 1,
                                    "ffnum",
                                    pasenger.ty
                                  )}
                                />
                              </Form.Item>
                            </div>
                          )}
                        </div>
                      </div>
                    )} */}
                      {pasenger.inf !== "" && (
                        <div className="travellerDetailsInfant">
                          <div className="travellerDetailHeader dFlex">
                            <div>
                              <Title level={5}>
                                <span className="traveller">
                                  {context.translation && context.translation.traveller} {pasenger.inf.fesno}
                                  {pasenger.sno}
                                </span>&nbsp;
                                <span className="stages">{context.translation && context.translation.inf}</span>
                              </Title>
                            </div>
                            {/* <div>
                              <span>
                                <i className="icon icon-Plus"></i>
                              </span>
                            </div> */}
                          </div>
                          <div className="travellerDetailBody">
                            <Row gutter={20}>
                              <Col span={24}>
                                <p>
                                  <span>{context.translation && context.translation.note} </span>{context.translation && context.translation.ensureName}
                                </p>
                              </Col>
                              {state.infTraList.length !== 0 && (
                                <Col span={8}>
                                  <Form.Item label="">
                                     <>
                              {(sessionStorage.getItem("SEL_LAG") === null || sessionStorage.getItem("SEL_LAG") === "English") &&
                               <Select
                                      name="title"
                                      defaultValue="Select Traveller"
                                      value={
                                        pasenger.inf.trave_id
                                          ? pasenger.inf.trave_id
                                          : undefined
                                      }
                                      onSelect={(value, event) =>
                                        updatePax(value, event, "INF", idx + 1)
                                      }
                                      options={state.infTraList}
                                      className="selectBoxShadow"
                                    />
                              }
                              {(sessionStorage.getItem("SEL_LAG") === "Arabic") &&
                              <Select
                                      name="title"
                                      defaultValue="إختر المسافر"
                                      value={
                                        pasenger.inf.trave_id
                                          ? pasenger.inf.trave_id
                                          : undefined
                                      }
                                      onSelect={(value, event) =>
                                        updatePax(value, event, "INF", idx + 1)
                                      }
                                      options={state.infTraList}
                                      className="selectBoxShadow"
                                    />
                              }
                            </>
                                  </Form.Item>
                                </Col>
                              )}
                            </Row>
                            <Row gutter={20}>
                              <Col span={8}>
                                <Form.Item
                                  autoComplete="off"
                                  className="pRelative"
                                >
                                  <label className="requiredField">
                                    {context.translation && context.translation.fn}
                                  </label>
                                  <Input
                                    addonBefore={
                                      <>
                              {(sessionStorage.getItem("SEL_LAG") === null || sessionStorage.getItem("SEL_LAG") === "English") &&
                                <Select
                                name="tl"
                                defaultValue="Select"
                                value={
                                  pasenger.inf.tl
                                    ? pasenger.inf.tl
                                    : undefined
                                }
                                onChange={handleChangeSelect(
                                  idx + 1,
                                  "tl",
                                  "title",
                                  "INF"
                                )}
                                options={context.selectLang==="en"?inf_titles:inf_titlesar}
                              />
                              }
                              {(sessionStorage.getItem("SEL_LAG") === "Arabic") &&
                                <Select
                                name="tl"
                                defaultValue="اختار"
                                value={
                                  pasenger.inf.tl
                                    ? pasenger.inf.tl
                                    : undefined
                                }
                                onChange={handleChangeSelect(
                                  idx + 1,
                                  "tl",
                                  "title",
                                  "INF"
                                )}
                                options={context.selectLang==="en"?inf_titles:inf_titlesar}
                              />
                              }
                            </>
                                    }
                                    className="selectBoxShadow"
                                    type="text"
                                    name="fn"
                                    value={pasenger.inf.fn}
                                    placeholder={context.translation && context.translation.efn}
                                    onChange={handleChangeText(
                                      idx + 1,
                                      "fn",
                                      "INF"
                                    )}
                                  />
                                </Form.Item>
                              </Col>
                              <Col span={8}>
                                <Form.Item className="pRelative">
                                  <label className="requiredField">
                                    {context.translation && context.translation.ln}
                                  </label>
                                  <Input
                                    type="text"
                                    className="innerBoxShadow"
                                    name="ln"
                                    value={pasenger.inf.ln}
                                    placeholder={context.translation && context.translation.eln}
                                    onChange={handleChangeText(
                                      idx + 1,
                                      "ln",
                                      "INF"
                                    )}
                                  />
                                </Form.Item>
                              </Col>
                              <Col span={8}>
                                <Form.Item>
                                  <label className="requiredField">
                                    {context.translation && context.translation.dob}
                                  </label>
                                  <div className="onlyFlex">
                                    <div className="dateMY month">
                                      <Month
                                        updateMonth={pasenger.inf.dob_month}
                                        sendMonth={handleChangeSelect(
                                          idx + 1,
                                          "month",
                                          "dob",
                                          "INF"
                                        )}
                                      />
                                    </div>
                                    <div className="dateMY day">
                                      <Days
                                        updateDay={pasenger.inf.dob_day}
                                        sendDay={handleChangeSelect(
                                          idx + 1,
                                          "day",
                                          "dob",
                                          "INF"
                                        )}
                                      />
                                    </div>
                                    <div className="dateMY year">
                                      <Years
                                        updateYear={pasenger.inf.dob_year}
                                        type="Inf"
                                        sendYear={handleChangeSelect(
                                          idx + 1,
                                          "year",
                                          "dob",
                                          "INF"
                                        )}
                                      />
                                    </div>
                                  </div>
                                </Form.Item>
                              </Col>
                            </Row>
                            <div className="emailDivider"></div>
                            <Row gutter={20}>
                              <Col span={8}>
                                <Form.Item className="pRelative">
                                  <label
                                    className={
                                      state.docMandatory === "yes" ||
                                      props.indpassportenable === "true"
                                        ? "requiredField"
                                        : "notRequiredField"
                                    }
                                  >
                                    {context.translation && context.translation.pn}
                                  </label>
                                  <Input
                                    type="text"
                                    className="innerBoxShadow"
                                    name="num"
                                    value={pasenger.inf.doc.num}
                                    placeholder={context.translation && context.translation.epn}
                                    onChange={handleChangeText(
                                      idx + 1,
                                      "pnum",
                                      "INF"
                                    )}
                                  />
                                </Form.Item>
                              </Col>
                              <Col span={8}>
                                <Form.Item className="pRelative">
                                  <label
                                    className={
                                      state.docMandatory === "yes" ||
                                      props.checkmandatoryflagnat === "true"
                                        ? "requiredField"
                                        : "notRequiredField"
                                    }
                                  >
                                    {context.translation && context.translation.nn}
                                  </label>
                                  <>
                                  
                                  <Select
                                    defaultValue={context.translation && context.translation.select}
                                    placeholder={context.translation && context.translation.select}
                                    showSearch
                                    optionFilterProp="children"
                                    name="na"
                                    value={
                                      pasenger.inf.doc.na
                                        ? pasenger.inf.doc.na
                                        : undefined
                                    }
                                    className="selectBoxShadow double"
                                    onChange={handleChangeSelect(
                                      idx + 1,
                                      "nationality",
                                      "isc",
                                      "INF"
                                    )}
                                    filterOption={(input, option) =>
                                      (option?.label ?? "")
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                    }
                                  >
                                  <Option value="Select">{context.translation && context.translation.select}</Option>
                                    {countrieslist.map((option, index) => (
                                      <Option key={index} value={option.value}>
                                        {option.label}
                                      </Option>
                                    ))}
                                </Select>
                              {/* {(sessionStorage.getItem("SEL_LAG") === null || sessionStorage.getItem("SEL_LAG") === "English") &&
                              <Select
                              defaultValue="Select"
                              showSearch
                              optionFilterProp="children"
                              name="na"
                              value={
                                pasenger.inf.doc.na
                                  ? pasenger.inf.doc.na
                                  : undefined
                              }
                              className="selectBoxShadow"
                              onChange={handleChangeSelect(
                                idx + 1,
                                "nationality",
                                "isc",
                                "INF"
                              )}
                              filterOption={(input, option) =>
                                (option?.label ?? "")
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                              options={countrieslist}
                            />
                              } */}
                              {/* {(sessionStorage.getItem("SEL_LAG") === "Arabic") &&
                              <Select
                              defaultValue="اختار"
                              showSearch
                              optionFilterProp="children"
                              name="na"
                              value={
                                pasenger.inf.doc.na
                                  ? pasenger.inf.doc.na
                                  : undefined
                              }
                              className="selectBoxShadow"
                              onChange={handleChangeSelect(
                                idx + 1,
                                "nationality",
                                "isc",
                                "INF"
                              )}
                              filterOption={(input, option) =>
                                (option?.label ?? "")
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                              options={countrieslist}
                            />
                              } */}
                            </>
                                </Form.Item>
                              </Col>
                              <Col span={8}>
                                <Form.Item>
                                  <label
                                    className={
                                      state.docMandatory === "yes"
                                        ? "requiredField"
                                        : "notRequiredField"
                                    }
                                  >
                                    {context.translation && context.translation.ic}
                                  </label>
                                  <>
                                  
                                  <Select
                                    defaultValue={context.translation && context.translation.select}
                                    placeholder={context.translation && context.translation.select}
                                    showSearch
                                    optionFilterProp="children"
                                    name="isc"
                                    value={
                                      pasenger.inf.doc.isc
                                        ? pasenger.inf.doc.isc
                                        : undefined
                                    }
                                    className="selectBoxShadow"
                                    onChange={handleChangeSelect(
                                      idx + 1,
                                      "issuedcountry",
                                      "isd",
                                      "INF"
                                    )}
                                    filterOption={(input, option) =>
                                      (option?.label ?? "")
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                    }
                                  >
                                  <Option value="Select">{context.translation && context.translation.select}</Option>
                                    {countrieslist.map((option, index) => (
                                      <Option key={index} value={option.value}>
                                        {option.label}
                                      </Option>
                                    ))}
                                </Select>

                              {/* {(sessionStorage.getItem("SEL_LAG") === null || sessionStorage.getItem("SEL_LAG") === "English") &&
                                <Select
                                defaultValue="Select"
                                showSearch
                                optionFilterProp="children"
                                name="isc"
                                value={
                                  pasenger.inf.doc.isc
                                    ? pasenger.inf.doc.isc
                                    : undefined
                                }
                                className="selectBoxShadow"
                                onChange={handleChangeSelect(
                                  idx + 1,
                                  "issuedcountry",
                                  "isd",
                                  "INF"
                                )}
                                filterOption={(input, option) =>
                                  (option?.label ?? "")
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                                }
                                options={countrieslist}
                              />
                              } */}
                              {/* {(sessionStorage.getItem("SEL_LAG") === "Arabic") &&
                                <Select
                                defaultValue="اختار"
                                showSearch
                                optionFilterProp="children"
                                name="isc"
                                value={
                                  pasenger.inf.doc.isc
                                    ? pasenger.inf.doc.isc
                                    : undefined
                                }
                                className="selectBoxShadow"
                                onChange={handleChangeSelect(
                                  idx + 1,
                                  "issuedcountry",
                                  "isd",
                                  "INF"
                                )}
                                filterOption={(input, option) =>
                                  (option?.label ?? "")
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                                }
                                options={countrieslist}
                              />
                              } */}
                            </>
                                </Form.Item>
                              </Col>
                            </Row>
                            <Row gutter={20} className="issueExpiryDateWrap">
                              <Col span={8}>
                                <Form.Item>
                                  <label className="notRequiredField">
                                    {context.translation && context.translation.iDate}
                                  </label>
                                  <div className="onlyFlex">
                                          <div className="dateMY month">
                                            <Month
                                              updateMonth={pasenger.inf.doc.isd_month}
                                              sendMonth={handleChangeSelect(
                                                idx + 1,
                                                "month",
                                                "isudate",
                                                "INF"
                                              )}
                                            />
                                          </div>
                                          <div className="dateMY day">
                                            <Days
                                              updateDay={pasenger.inf.doc.isd_day}
                                              sendDay={handleChangeSelect(
                                                idx + 1,
                                                "day",
                                                "isudate",
                                                "INF"
                                              )}
                                            />
                                          </div>
                                          <div className="dateMY year">
                                            <Years
                                              updateYear={pasenger.inf.doc.isd_year}
                                              type="isuDate"
                                              sendYear={handleChangeSelect(
                                                idx + 1,
                                                "year",
                                                "isudate",
                                                "INF"
                                              )}
                                            />
                                          </div>
                                        </div>
                                </Form.Item>
                              </Col>
                              <Col span={8}>
                                <Form.Item>
                                  <label
                                    className={
                                      state.docMandatory === "yes" ||
                                      props.indpassportenable === "true"
                                        ? "requiredField"
                                        : "notRequiredField"
                                    }
                                  >
                                    {context.translation && context.translation.eDate}
                                  </label>
                                  <div className="onlyFlex">
                                              <div className="dateMY month">
                                                <Month
                                                  updateMonth={pasenger.inf.doc.exp_month}
                                                  sendMonth={handleChangeSelect(
                                                    idx + 1,
                                                    "month",
                                                    "expiry",
                                                    "INF"
                                                  )}
                                                />
                                              </div>
                                              <div className="dateMY day">
                                                <Days
                                                  updateDay={pasenger.inf.doc.exp_day}
                                                  sendDay={handleChangeSelect(
                                                    idx + 1,
                                                    "day",
                                                    "expiry",
                                                    "INF"
                                                  )}
                                                />
                                              </div>
                                              <div className="dateMY year">
                                                <Years
                                                  updateYear={pasenger.inf.doc.exp_year}
                                                  type="expDate"
                                                  sendYear={handleChangeSelect(
                                                    idx + 1,
                                                    "year",
                                                    "expiry",
                                                    "INF"
                                                  )}
                                                />
                                              </div>
                                            </div>
                                </Form.Item>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
               
            </div>
          ))}
        </div>
      )}
      
    </>
  );
}

export default TravellerDetails;
