import axios from 'axios';
import api from "../../../../utilities/api"

const API_HOTEL_URL = process.env.REACT_APP_PREDICTIVE_URL

/**
 * @author: Srikanth Bandaru
 * @description: Hotel Predective API
 */
class HotelPredectiveSearch {
    
    async retrieveSearch(data) {
        return await api.get(`${API_HOTEL_URL}/search/`+data+'?skip=0&limitCity=10&limitProperty=50');
    };

    async retrieveHotelorCityData(data,isCity) {
        return await api.get(`${API_HOTEL_URL}/modify/search?`+(isCity?'expCityId=':'expHtlId=')+data);
    };

}
export default new HotelPredectiveSearch()