/**
 * @ Author: Srikanth Bandaru
 * @ Create Time: 2023-06-15 18:15:59
 * @ Description: This for flight results details in the flight search results page
 */

import { Alert, Button, Col, Drawer, Row, Spin, Typography, Tooltip, Modal, Space, Input } from "antd";
import React, {
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState,
  useCallback
} from "react";
import Link from "antd/es/typography/Link";
import FlightFareSummary from "./FlightFareSummary";
import FlightFilters from "./FlightFilters";
import FlightResultsBlock from "./FlightResultsBlock";
import FlightResultsSort from "./FlightResultsSort";
import FormatCurrency from "../../../utilities/FormatCurrency";
import StickyBox from "react-sticky-box";
import NoResultsFound from "./NoResultsFound";
import Utilities from "../../../utilities/Utilities";
import { publicIpv4 } from "public-ip";
import { Context,ExchangeRates } from "../../../../App";
// import { set } from "lodash";
import { json, useNavigate } from "react-router-dom";
import { fireAvailabilityReq } from "../common/services/operations";
import AirportMemo from "../common/airlines/AirportNames";
import ReactDOM from "react-dom/server";
import LoaderN from "../../../common/loaders/LoaderN";
import FlightGoneLoader from "../../../../assets/img/loader.gif";
import Whatsapp from "../../../../assets/img/whatsapp.png";
import Shareicon from "../../../../assets/img/shareicon.png";
import TooManyFilterIcon from "../../../../assets/img/tooManyFilter.png";
// import Messenger from "../../../../assets/img/messenger.png";
import { 
  // FacebookMessengerShareButton, 
  WhatsappShareButton } from "react-share";
import BrandIcon from "../../../../assets/gif/Forever/White/ICON_WHITE_FOREVER.gif";
import { isNotNull } from "../../../utilities/validators";
import CntCommonService from "../../../../services/common/CntCommonService";
// const FLIGHT_SEARCH_CID = process.env.REACT_APP_FLIGHT_SEARCH_CID;
// const FLIGHT_SEARCH_REGION_TYPE =
//   process.env.REACT_APP_FLIGHT_SEARCH_REGION_TYPE;
// const FLIGHT_SEARCH_OFFICE_ID = process.env.REACT_APP_FLIGHT_SEARCH_OFFICE_ID;
// const CNT_APP_ID = process.env.REACT_APP_FACEBOOK_APP_ID;
export const OTID = "otid";
export const RTID = "rtid";
export const SEL_FLT_COMBO = "selFltCombo";
export const SEL_SALAM_ONE = "sel_salam1";
export const SEL_SALAM_TWO = "sel_salam2";
const BASE_CURRENCY = process.env.REACT_APP_BASE_CUR;
//Initial State
const initialState = {
  response: "",
  errorMessage: "",
  showErrorMessage: false,
  onward_fare: "",
  return_fare: "",
  fareruleResp: [],
  passengerUniqueKey: [],
  fareEnable: false,
  totalprice: 0,
  onwardRoute: "",
  returnRoute: "",
  onwardSupp: "",
  returnSupp: "",
  departure_date: "",
  tfFlow: false,
  indigo_passport: false,
  showPop: false,
  fareRule: false,
  loadResults:false
};

//=====================================
// Reducer function for userReact Hook
// which will update the state
//=====================================
const reducer = (state, action) => {
  switch (action.type) {
    case "test":
      return Object.assign(state, action.payload);
    default:
      return { ...state, [action.type]: action.payload };
  }
};

function FlightResultsDetails(props) {
  const { searchRes, request, initRes, longUrl } = props;
  const navigate = useNavigate();
  const [context, setContext] = useContext(Context);
  const [state, dispatch] = useReducer(reducer, initialState);
  const [selectedFlightOW, setSelectedFlightOW] = useState();
  const [selectedFlightRT, setSelectedFlightRT] = useState();
  const [selectedSty, setSelectedSty] = useState();
  const [selectedFlights, setSelectedFlights] = useState();
  const [toggleFareSummary, setToggleFareSummary] = useState(false);
  const [page, setPage] = useState(1); // Current page
  const [showResults, setShowResults] = useState(10); // Number of results to show initially
  const [isLoading, setIsLoading] = useState(false); // Loading indicator
  const [shortUrl, setShortUrl] = useState('');
  const [loadShortUrl, setLoadShortUrl] = useState(false);
  const [gccCodes, setGccCodes] = useState([
    "XNB",
    "DWC",
    "DXB",
    "AZAZ",
    "DZB",
    "DOH",
    "XSB",
    "KWI",
    "MCT",
    "DMM",
    "SHJ",
    "IST",
    "JED",
  ]);
  const [loading, setLoading] = useState(false);
  //MultiCity Selection
  const [multiSelectOne, setMultiSelectOne] = useState();
  const [multiSelectTwo, setMultiSelectTwo] = useState();
  const [multiSelectThree, setMultiSelectThree] = useState();
  const [multiSelectFour, setMultiSelectFour] = useState();
  const [multiSelectFive, setMultiSelectFive] = useState();
  const [multiSelectSix, setMultiSelectSix] = useState();
  const [copied,setCopied] = useState(false);
  const [enable, setEnable] = useState(true);
  const EndDivRef = useRef(null);
  const [raw_srp_data, setRawSrpData] = useState("");
  const [base_usr_exrate, setBaseToUserExrate] = useState(1);
  const [filterEmpty, setFilterEmpty] = useState(false);
  const [tooManyFilterReset, setTooManyFilterReset] = useState(false);

  useEffect(() => {
    setIsLoading(false);
    setShowResults(10);
    dispatch({ type: "response", payload: searchRes });
    request.cur =context.userCur!==undefined && context.userCur!==""?context.userCur:sessionStorage.getItem("SEL_CUR");
  }, []);

  useEffect(() => {
    setIsLoading(false);
    setShowResults(10);
    dispatch({ type: "response", payload: searchRes });
    request.cur =context.userCur!==undefined && context.userCur!==""?context.userCur:sessionStorage.getItem("SEL_CUR")
  }, [searchRes]);

  useEffect(() =>{
    if(props.raw_srp_data){
      setRawSrpData({
        ...props.raw_srp_data

      });
    }
    // else{
    //   console.log("========== No rawdata data found raw_srp_data ========== FlightResultsDetails cmp");
    // }
  },[props?.raw_srp_data])

  useEffect(() => {
    var userCur = context.userCur!==undefined && context.userCur!==""?context.userCur:sessionStorage.getItem("SEL_CUR")
    var exRates = JSON.parse(localStorage.getItem("CNT_EX_RATES"))
    if(context.ex_rates === undefined || context.ex_rates === "") {
      setContext({...context, ex_rates: exRates})
    }
    if((context.ex_rates !==undefined && context.ex_rates!=="") || (exRates !== undefined && exRates !== "") && userCur!==undefined && userCur!==""){
      if(BASE_CURRENCY!==userCur){
           request.cur=userCur;
           let currecny=BASE_CURRENCY+" - "+userCur;
           getExchangeRate(currecny)
      }else if(BASE_CURRENCY===userCur){
        setBaseToUserExrate(1)
        request.cur=userCur;
      }
    }else{
      setBaseToUserExrate(1)
    }
  }, [context.userCur])
  
  useEffect(() => {
  if(context.ex_rates!=="" && context.ex_rates!==undefined){
    let currecny=BASE_CURRENCY+" - "+request.cur;
    getExchangeRate(currecny)
  } 
  }, [context.ex_rates])

  const getExchangeRate=(usrCur)=>{
    if(context.ex_rates!=="" && context.ex_rates!==undefined && context.ex_rates!==null){
      if (context.ex_rates?.hasOwnProperty(usrCur)) {
        var sup = context.ex_rates[usrCur];
        setBaseToUserExrate(Number(sup))
      }
    }
  }

  

  useEffect(() => {
    const divElement = EndDivRef.current;
    props.closeLoader()
    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          if (state.response && state.response.origns) {
            if (showResults >= state.response.origns.length) {
              return;
            }

            // Update the number of results to show
            setShowResults((prevShowResults) => prevShowResults + 10);
          }
        } /* else {
          console.log('Div is not visible in the viewport');
        } */
      });
    };

    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0, // Adjust this value to define the visibility threshold
    };

    const observer = new IntersectionObserver(handleIntersection, options);

    if (divElement) {
      observer.observe(divElement);
    }
    return () => {
      if (divElement) {
        observer.unobserve(divElement);
      }
    };
    
  }, [state.response]);

  //Update the flight selection
  const updateSelectedFlight = (data) => {
    setSelectedFlightOW(data);
  };

  //Update the return flight selection
  const updateSelectedRTFlight = (data) => {
    setSelectedFlightRT(data);
  };

  const updateSty = (selSty) => {
    setSelectedSty(selSty);
  };

  const updateMultiFlightSelection = (selection, data) => {
    setMultiSelectOne(data[0]);
    setMultiSelectTwo(data[1]);
    setMultiSelectThree(data[2]);
    setMultiSelectFour(data[3]);
    setMultiSelectFive(data[4]);
    setMultiSelectSix(data[5]);
  };

  useEffect(() => {
    if(isNotNull(selectedSty) && isNotNull(selectedFlights)){
      if ((parseInt(request.tt) === 1 && isNotNull(selectedFlightOW)) || (parseInt(request.tt) === 2 && isNotNull(selectedFlightOW) && isNotNull(selectedFlightRT))) {
        fireFareRuleRequest(selectedFlights);
      }
    }
  }, [selectedFlightOW, selectedFlightRT,selectedSty,selectedFlights]);

  useEffect(() => {
    if(parseInt(request.tt) === 3 && isNotNull(selectedFlights)){
      const multiSelectVars = [multiSelectOne, multiSelectTwo, multiSelectThree, multiSelectFour, multiSelectFive, multiSelectSix];
      for (let i = 1; i <= 6; i++) {
        if (state.response.sec.length === i && multiSelectVars[i - 1]) {
          fireFareRuleRequest(selectedFlights);
          break; 
        }
      }
    }
  }, [multiSelectOne,multiSelectTwo,multiSelectThree,multiSelectFour,multiSelectFive,multiSelectSix,selectedFlights])
  
  

  // //For handling the filter changes
  // //When its clear we dispatch the initial response else we dispatch the filtered response
  // const handleFilterChanges = useCallback((res,type) => {
  //   setEnable(false)
  //   dispatch({ type: "response", payload: "" });
  //   if (type!==undefined && type === "clear") {
  //    let response= sessionStorage.getItem("INT_RESPONSE")
  //     setEnable(true)
  //     dispatch({ type: "response", payload: initRes});
  //   } else if(res!==null && res!==undefined) {
  //     dispatch({ type: "response", payload: res });
  //     setEnable(true)
  //   }
  //    setShowResults(10);
  // }, []);

  const handleFilterChanges = (res, type, temp_raw) => {
    setFilterEmpty(true);
    setEnable(false)
    dispatch({ type: "response", payload: "" });
    if (type!==undefined && type === "clear") {
      setEnable(true)
      dispatch({ type: "response", payload: temp_raw ? temp_raw : raw_srp_data });
    } else if(res!==null && res!==undefined) {
      dispatch({ type: "response", payload: res });
      setEnable(true)
    }
     setShowResults(10);
  };

  const { Title } = Typography;

  //For toggle fare summary popup
  const toggleFare = () => {
    setToggleFareSummary(!toggleFareSummary);
  };

  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const checkCheapestFilterClicked = () => {
    if (
      sessionStorage.getItem("FILTERRESET") === null ||
      sessionStorage.getItem("FILTERRESET") === "true" ||
      sessionStorage.getItem("CHEAPEST") === "true"
    ) {
      return true;
    }
  };

  const checkQuickestFilterClicked = () => {
    if (
      sessionStorage.getItem("QUICKEST") === "true"
    ) {
      return true;
    }
  };

  const saveItineraryOnAvailabilityFailure = () => {};

  //Availabilty call for all trip types
  const callAvailability = (request) => {
    try {
      let tokenKey = Utilities.generateAPIToken(request);
      fireAvailabilityReq(request,tokenKey).then((response) => {
        if (response !== "" && response !== undefined) {
          if (
            response.fareRuleRes !== undefined &&
            response.fareRuleRes.status === "success"
          ) {
            sessionStorage.setItem("OFFLINE", response.fareRuleRes.data[0].offline);
            dispatch({ type: "fareRuleResp", payload: response.fareRuleRes });
            let pUkey = [];
            if (
              response.fareRuleRes.data[0] &&
              response.fareRuleRes.data[0].passengerUniqueKey
            ) {
              pUkey = response.fareRuleRes.data[0].passengerUniqueKey;
            }
            dispatch({ type: "passengerUniqueKey", payload: pUkey });
            dispatch({ type: "fareEnable", payload: true });
            setLoading(false);
            dispatch({ type: "enableImprtFlow", payload: false });
            if (request.sty === "normal") {
              dispatch({
                type: "totalprice",
                payload: request.origns[0].srcorigns[0].pr?.tf,
              });
            } else {
              const price = Utilities.addPrice(
                request.origns[0].srcorigns[0].pr?.tf,
                request.origns[0].destorigins[0].pr.tf,
                "Add"
              );
              dispatch({ type: "totalprice", payload: Number(price) });
            }
            let onward =
              request.origns[0].srcorigns[0].seg[0].da +
              "-" +
              request.origns[0].srcorigns[0].seg.slice(-1)[0].ar;
            let arrval = "";
            if (request.tt === "2" || request.sty === "split") {
              arrval =
                request.origns[0].destorigins[0].seg[0].da +
                "-" +
                request.origns[0].destorigins[0].seg.slice(-1)[0].ar;
              dispatch({
                type: "returnSupp",
                payload: request.origns[0].destorigins[0].sup,
              });
              dispatch({ type: "returnRoute", payload: arrval });
            }
            dispatch({ type: "onwardRoute", payload: onward });
            dispatch({
              type: "onwardSupp",
              payload: request.origns[0].srcorigns[0].sup,
            });
            dispatch({
              type: "departure_date",
              payload: request.origns[0].srcorigns[0].seg[0].dd,
            });
            if (
              request.sty === "normal" &&
              request.tt === "2" &&
              request.origns[0].srcorigns[0].sup !== "0" &&
              request.origns[0].destorigins !== undefined &&
              request.origns[0].destorigins[0].sup !== "0"
            ) {
              dispatch({ type: "tfFlow", payload: true });
            }
            if (
              (request.origns.length > 0 &&
                request.origns[0].srcorigns[0].sup === "4") ||
              (request.origns.length > 0 &&
                request.origns[0].destorigins !== undefined &&
                request.origns[0].destorigins.length > 0 &&
                request.origns[0].destorigins[0].sup === "4")
            ) {
              if (
                request.src_country !== "IN" &&
                request.dest_country === "IN"
              ) {
                dispatch({ type: "indigo_passport", payload: true });
              }
            }
            localStorage.removeItem("AVAILABILITY_FAILURE");
            localStorage.removeItem("SELECTED_FLIGHT_OW");
            localStorage.removeItem("SELECTED_FLIGHT_RT");
            localStorage.removeItem("SELECTED_MULTI_ONE");
            localStorage.removeItem("SELECTED_MULTI_TWO");
            localStorage.removeItem("SELECTED_MULTI_THREE");
            localStorage.removeItem("SELECTED_MULTI_FOUR");
            localStorage.removeItem("SELECTED_MULTI_FIVE");
            localStorage.removeItem("SELECTED_MULTI_SIX");
            sessionStorage.removeItem("INT_RESPONSE");
            navigate("/flight/review", {
              state: {
                req: request,
                url: longUrl,
                res: response.fareRuleRes,
                mapping: state.response.mappings,
                sect: state.response.sec,
                selected: {
                  ow: selectedFlightOW,
                  rt: selectedFlightRT,
                  multi: {
                    multiSelectOne,
                    multiSelectTwo,
                    multiSelectThree,
                    multiSelectFour,
                    multiSelectFive,
                    multiSelectSix,
                  },
                },
              },
            });
          } else {
            dispatch({ type: "showPop", payload: true });
            dispatch({ type: "fareRule", payload: true });
            dispatch({ type: "loadResults", payload: true });
            setLoading(false)
            setTimeout(function () {
              window.location.reload()
            }, 5000);
          }
        } else {
          dispatch({ type: "showPop", payload: true });
          dispatch({ type: "fareRule", payload: true });
          dispatch({ type: "loadResults", payload: true });
          setLoading(false)
          setTimeout(function () {
            window.location.reload()
          }, 5000);
        }
      }).catch(function (error) {
        console.error("Exception in callAvailability :" + error);      
      });
    } catch (error) {
      console.error("Exception in callAvailability : " + error);
    }
  };

  const updatePrice = (defaultOption, returnDefaultOption, flights) => {
    if (defaultOption && returnDefaultOption) {
      let owPaxPr =
        parseInt(defaultOption.sup) === 0
          ? defaultOption.paxpr
          : defaultOption.fareop[0]?.paxpr;
      let owPr =
        parseInt(defaultOption.sup) === 0
          ? defaultOption.pr
          : defaultOption.fareop[0]?.pr;
      let rtPaxPr =
        parseInt(returnDefaultOption.sup) === 0
          ? returnDefaultOption.paxpr
          : returnDefaultOption.fareop[0]?.paxpr;
      let rtPr =
        parseInt(returnDefaultOption.sup) === 0
          ? returnDefaultOption.pr
          : returnDefaultOption.fareop[0]?.pr;
      //Now prepare final paxpr object with owPaxPr and rtPaxPr
      let mainPaxPr = calculateMainPaxPr(owPaxPr, rtPaxPr);
      if (mainPaxPr) flights.paxpr = mainPaxPr;
      let mainPr = calculateMainPr(owPr, rtPr);
      if (mainPr) flights.pr = {...mainPr, rv: flights.pr?.rv !== undefined ? flights.pr?.rv : null, rs: flights.pr?.rs !== undefined ? flights.pr?.rs : null};
    }
  };

  const calculateMainPaxPr = (owpaxpr, rtpaxpr) => {
    const mainpaxpr = [];
    for (const paxType of ["ADT", "CHD", "INF"]) {
      const owpaxprForType =
        owpaxpr && owpaxpr.find((pax) => pax.ptype === paxType);
      const rtpaxprForType =
        rtpaxpr && rtpaxpr.find((pax) => pax.ptype === paxType);
      if (owpaxprForType && rtpaxprForType) {
        const sup_fareArray1 = owpaxprForType.sup_fare?.split("_").map(Number);
        const sup_fareArray2 = rtpaxprForType.sup_fare?.split("_").map(Number);
        const sup_fareSumArray =
          sup_fareArray1 &&
          sup_fareArray2 &&
          sup_fareArray1.map((value, index) => value + sup_fareArray2[index]);
        const combinedSupFare = sup_fareSumArray && sup_fareSumArray.join("_");
        const psup_fareArray1 = owpaxprForType.psup_fare
          ?.split("_")
          .map(Number);
        const psup_fareArray2 = rtpaxprForType.psup_fare
          ?.split("_")
          .map(Number);
        const psup_fareSumArray =
          psup_fareArray1 &&
          psup_fareArray2 &&
          psup_fareArray1.map((value, index) => value + psup_fareArray2[index]);
        const combinedPSupFare =
          psup_fareSumArray && psup_fareSumArray.join("_");
        mainpaxpr.push({
          ptype: paxType,
          bf: owpaxprForType.bf + rtpaxprForType.bf,
          tf: owpaxprForType.tf + rtpaxprForType.tf,
          tax: sumTaxArrays(owpaxprForType.tax, rtpaxprForType.tax),
          scur: owpaxprForType.scur,
          sup_fare: combinedSupFare,
          psup_fare: combinedPSupFare,
        });
      }
    }
    return mainpaxpr;
  };

  const calculateMainPr = (owpr, rtpr) => {
    const mainpr = {
      bf: (owpr && owpr.bf ? owpr.bf : 0) + (rtpr && rtpr.bf ? rtpr.bf : 0),
      tf: (owpr && owpr.tf ? owpr.tf : 0) + (rtpr && rtpr.tf ? rtpr.tf : 0),
      tax: owpr && rtpr && sumTaxArrays(owpr.tax, rtpr.tax),
      scur: owpr && owpr.scur,
      sup_fare: (() => {
        const sup_fareArray1 = owpr && owpr.sup_fare?.split("_").map(Number);
        const sup_fareArray2 = rtpr && rtpr.sup_fare?.split("_").map(Number);
        if (sup_fareArray1 && sup_fareArray2) {
          const sup_fareSumArray = sup_fareArray1.map(
            (value, index) => value + sup_fareArray2[index]
          );
          return sup_fareSumArray && sup_fareSumArray.join("_");
        }
      })(),
      ttf: owpr && rtpr && owpr.ttf + rtpr.ttf,
    };
    return mainpr;
  };

  const sumTaxArrays = (arr1, arr2) => {
    const result = [];
    const maxLength = Math.max(arr1.length, arr2.length);
    for (let i = 0; i < maxLength; i++) {
      const taxObj = {};
      if (i < arr1.length) {
        for (const key in arr1[i]) {
          taxObj[key] = arr1[i][key] || 0;
        }
      }
      if (i < arr2.length) {
        for (const key in arr2[i]) {
          taxObj[key] = (taxObj[key] || 0) + (arr2[i][key] || 0);
        }
      }
      result.push(taxObj);
    }
    return result;
  };

  const updateSelectedFlights = (flights) => {
    setSelectedFlights(flights);
  }

  const fireFareRuleRequest = (flights) => {
    let srcValid = false;
    let destValid = false;
    let sourceSelected = undefined;
    let destSelected = undefined;
    let valid = true;
    if (flights && flights.srcorigns) {
      if (parseInt(request.tt) === 1 || parseInt(request.tt) === 2) {
        sourceSelected = flights.srcorigns.filter((src) => {
          return selectedFlightOW && selectedFlightOW.tid === src.tid;
        });
      } else if (parseInt(request.tt) === 3) {
        sourceSelected = flights.srcorigns.filter((src) => {
          return multiSelectOne && multiSelectOne.tid === src.tid;
        });
      }
    }
    if (flights && flights.destorigins) {
      destSelected = flights.destorigins.filter((dest) => {
        return selectedFlightRT && selectedFlightRT.tid === dest.tid;
      });
    }

    if (sourceSelected && sourceSelected.length > 0) {
      srcValid = true;
    }
    if (request.tt === "2" || request.tt === 2) {
      if (destSelected && destSelected.length > 0) {
        destValid = true;
      }
    } else {
      destValid = true;
    }
    // if (!(srcValid && destValid)) {
    //   dispatch({ type: "errorMessage", payload: "Please select your flight" });
    //   dispatch({ type: "showErrorMessage", payload: true });
    //   window.scrollTo(0, 0);
    // } else {
    //   dispatch({ type: "errorMessage", payload: "" });
    //   dispatch({ type: "showErrorMessage", payload: false });
    // }

    if (parseInt(request.tt) === 2) {
      if (flights.sty === "split") {
        if (sourceSelected && destSelected) {
          updatePrice(selectedFlightOW, selectedFlightRT, flights);
        }
      }
    }
    //Calculating the time for Air arabia
    if (parseInt(request.tt) === 2 && flights.sup === "2") {
      let hrs = Utilities.calculateHoursandMinsbetweendates(
        flights.destorigins[0].seg[0].dd,
        flights.srcorigns[0].seg.slice(-1)[0].ad
      );
      if (Number(hrs) <= 4) {
        valid = false;
      }
    }
    try {
      if (
        valid &&
        srcValid &&
        destValid &&
        ((selectedFlightOW !== undefined && selectedFlightOW !== "") ||
          (multiSelectOne !== "" && parseInt(state.response.tt) === 3))
      ) {
        setLoading(true);
        let check = true;
        if (parseInt(request.tt) === 1) {
          createOnewayFareRuleRequest(flights);
        } else if (parseInt(request.tt) === 2) {
          if (
            selectedFlightOW.sup !== "4" ||
            (selectedFlightOW.sup === "4" && checkIndiFareCombination(flights))
          ) {
            createRoundTripFareRuleRequest(flights);
          } else {
            check = false;
          }
        } else if (parseInt(request.tt) === 3) {
          createMultiCityFareRuleRequest(flights);
        }
      }
    } catch (e) {
      console.error("Exception in flight availability request" + e);
      setLoading(false);
    }
  };

  const createOnewayFareRuleRequest = async (flt) => {
    let src_origns = [];
    if (flt.srcorigns.length > 1) {
      for (let src of flt.srcorigns) {
        if (
          window.sessionStorage.getItem(OTID) &&
          src.tid === window.sessionStorage.getItem(OTID)
        ) {
          src_origns = [src];
        }
        if (
          window.sessionStorage.getItem(SEL_SALAM_ONE) &&
          selectedFlightOW.sup === "6"
        ) {
          let resp = JSON.parse(window.sessionStorage.getItem(SEL_SALAM_ONE));
          if (src.tid === resp.tid) {
            src_origns = [src];
            break;
          }
        }
        /* else{
          src_origns=flt.srcorigns;
        } */
      }
    } else {
      src_origns = flt.srcorigns;
    }

    let indigo_sessionKey = "";

    if (selectedFlightOW.sup === "2" || selectedFlightOW.sup === "5" || selectedFlightOW.sup === "6") {
      src_origns = flt.srcorigns.filter((s) => s.tid == selectedFlightOW.tid);
    }
    if (selectedFlightOW.sup === "4") {
      src_origns = flt.srcorigns.filter((s) => s.tid == selectedFlightOW.tid);
      indigo_sessionKey = src_origns[0].indigo_ugkey;
    }
    localStorage.setItem("AVAILABILITY_FAILURE", true);
    localStorage.setItem(
      "SELECTED_FLIGHT_OW",
      JSON.stringify(selectedFlightOW)
    );
    const payload = {
      echo: state.response.echo,
      cha: state.response.cha,
      cur: request.cur,
      adt: state.response.adt,
      chd: state.response.chd,
      inf: state.response.inf,
      cid: state.response.cid,
      sty: selectedSty ? selectedSty : state.response.sty,
      tt: state.response.tt,
      office_id:
        selectedFlightOW.office_id !== undefined
          ? selectedFlightOW.office_id
          : "",
      tid: flt.srcorigns.tid ? flt.srcorigns.tid : selectedFlightOW.tid,
      tkt_user_type: "", //context.logindata.utype,
      tkt_user_id: context.user_resp === "" ? "" : context.user_resp?.res?.id,
      client_ip: await publicIpv4(),
      journey_type:
        request !== undefined && request.journey_type !== undefined
          ? request.journey_type
          : "Onward",
      mappings: state.response.mappings,
      origns: [
        {
          srcorigns: [
            selectedFlightOW.sup === "0"
              ? convertAmadeusFareRequest(selectedFlightOW,flt)
              : convertLCCFareRequest(src_origns[0],flt),
          ],
        },
      ],
      psngrs:
        !request.paxChanged &&
        request !== undefined &&
        request.psngrs !== undefined
          ? request.psngrs
          : "",
      flowType: "DIRECT",
      src_country:
        isNotNull(request.src) && request.src.country,
      dest_country:
      isNotNull(request.dest) && request.dest.country,
      src_code: request.srcCode,
      dest_code: request.destCode,
      region_type: state.response.region_type,
      gst: flt.gst !== undefined ? flt.gst : "",
      paxpr: flt.paxpr,
      pr: flt.pr,
      servicefee: flt.servicefee,
      req_cur:BASE_CURRENCY,
      region_cur:context.regionCur!==undefined && context.regionCur!==""?context.regionCur:localStorage.getItem("REGION_CUR"),
      base_usr_exrate:base_usr_exrate,
      sup_base_exrate:flt.sup_base_exrate,
      base_sup_exrate:flt.base_sup_exrate
    };
    if (indigo_sessionKey) {
      payload.indigo_sessionKey = indigo_sessionKey;
    }
    if (flt.ugk && flt.sup_currency) {
      payload.ugk = flt.ugk;
      payload.sup_currency = flt.sup_currency;
    }
    //Call Availability
    callAvailability(payload);
  };

  const convertAmadeusFareRequest = (selectedFlt,flt) => {
    let bag = [];
    if (
      request.flowType !== "REISSUE" &&
      selectedFlt.bgd !== undefined &&
      selectedFlt.bgd.length !== 0
    ) {
      bag = selectedFlt.bgd;
      bag[0].type = "Checkin";
      const req = {
        qun: "7",
        wt: "kg",
        type: "Cabin",
      };
      bag.push(req);
    } else {
      if (selectedFlt.bgd.length === 1) {
        bag = selectedFlt.bgd;
        bag[0].type = "Checkin";
        const req = {
          qun: "7",
          wt: "kg",
          type: "Cabin",
        };
        bag.push(req);
      } else {
        bag = selectedFlt.bgd;
        for (let val of bag) {
          val.type = "Checkin";
        }
        const req = {
          qun: "7",
          wt: "kg",
          type: "Cabin",
        };
        bag.push(req);
      }
    }
    const payload = {
      tid: selectedFlt.tid,
      ref: selectedFlt.ref,
      sdur: selectedFlt.sdur,
      re: selectedFlt.re,
      stop: selectedFlt.stop,
      sup: selectedFlt.sup,
      cl: selectedFlt.cl,
      ft: selectedFlt.ft,
      tktair: selectedFlt.tktair,
      tecst: selectedFlt.tecst,
      seg: selectedFlt.seg,
      bgd: bag,
      pr: selectedFlt.pr,
      paxpr: selectedFlt.paxpr,
      serviceFee: selectedFlt.serviceFee,
      dealcode: selectedFlt.dealcode,
      office_id:
        selectedFlt.office_id !== undefined ? selectedFlt.office_id : "",
        //For availability we are checking src orign and dest orign level pr and paxpr , if not available we are taking orign level pr and paxpr and sending 
      paxpr: selectedFlt.paxpr?selectedFlt.paxpr:flt.paxpr,
      pr: selectedFlt.pr?selectedFlt.pr:flt.pr
    };
    return payload;
  };

  //Convert Travel Fusion Object
  const convertLCCFareRequest = (selectedFlt,flt) => {
    let bag = [];
    var check_qun = "";
    var cabin_qun = "0";
    let selFareObj = selectedFlt.fareop.filter((obj) => obj.selected);
    if (selectedFlightOW.sup === "4") {
      if (
        selFareObj[0].pr.ftyp === "Regular - R" ||
        selFareObj[0].pr.ftyp === "Flexi - J"
      ) {
        if (state.response.region_type === "Domestic") {
          check_qun = "15";
        } else if (state.response.region_type === "International") {
          if (
            (request.srcCode === "JED" &&
            isNotNull(request.dest) &&
              request.dest.country === "IN") ||
            (isNotNull(request.src) &&
              request.src.country === "IN" &&
              request.destCode === "JED")
          ) {
            check_qun = "30";
          } else if (
            gccCodes.includes(request.srcCode) ||
            gccCodes.includes(request.destCode)
          ) {
            check_qun = "30";
          } else {
            check_qun = "20";
          }
        }
        cabin_qun = "7";
      } else if (selFareObj[0].pr.ftyp.includes("Corporate")) {
        cabin_qun = "10";
        if (state.response.region_type === "Domestic") {
          check_qun = "15";
        } else {
          check_qun = "30";
        }
      } else {
        check_qun = "30";
        cabin_qun = "7";
      }
      bag = createBaggage("Checkin", check_qun, "Cabin", cabin_qun);
    } else {
      // Setting Check in baggage
      if (selFareObj[0].pr.cabb !== undefined) {
        if (
          selFareObj[0].pr.cabb.includes("Chargable") ||
          selFareObj[0].pr.cabb.includes("Chargeable")
        ) {
          check_qun = "0";
        } else {
          if (
            selFareObj[0].pr.cabb.includes("free") ||
            selFareObj[0].pr.cabb.includes("Free")
          ) {
            if (selFareObj[0].pr.bagage_info !== undefined) {
              if (selFareObj[0].pr.bagage_info.includes("20")) {
                check_qun = "20";
              } else if (selFareObj[0].pr.bagage_info.includes("30")) {
                check_qun = "30";
              } else if (selFareObj[0].pr.bagage_info.includes("40")) {
                check_qun = "40";
              }
            }
          } else if (selFareObj[0].pr.cabb.includes("20")) {
            check_qun = "20";
          } else if (selFareObj[0].pr.cabb.includes("30")) {
            check_qun = "30";
          } else if (selFareObj[0].pr.cabb.includes("40")) {
            check_qun = "40";
          }
        }
      }
      if (
        selFareObj[0].pr.chekb !== undefined &&
        selFareObj[0].pr.chekb !== ""
      ) {
        // Setting Cabin baggage
        if (
          selFareObj[0].pr.chekb.includes("Chargable") ||
          selFareObj[0].pr.chekb.includes("Chargeable")
        ) {
          cabin_qun = "0";
          if (selectedFlightOW.sup === "6") {
            cabin_qun = "7";
          } else if (selectedFlightOW.sup === "2") {
            cabin_qun = "10";
          }
        } else {
          if (selFareObj[0].pr.chekb.includes("7")) {
            cabin_qun = "7";
          } else if (selFareObj[0].pr.chekb.includes("10")) {
            cabin_qun = "10";
          }
        }
      } else {
        if (selectedFlightOW.sup === "6") {
          cabin_qun = "7";
        } else if (selectedFlightOW.sup === "2") {
          cabin_qun = "10";
        }
      }
      bag = createBaggage("Checkin", check_qun, "Cabin", cabin_qun);
    }
    const payload = {
      tid: selectedFlt.tid,
      routeId: selFareObj[0].routeId,
      prouteId: selectedFlt.prouteId,
      ref: selectedFlt.ref,
      sdur: selectedFlt.sdur,
      re: selectedFlt.re,
      stop: selectedFlt.stop,
      sup: selectedFlt.sup,
      cl: selectedFlt.cl,
      ft: selectedFlt.ft,
      tktair: selectedFlt.tktair,
      tecst: selectedFlt.tecst,
      seg: selectedFlt.seg,
      bgd: bag,
      pr: selFareObj[0].pr,
      paxpr: selFareObj[0].paxpr,
      serviceFee: selFareObj[0].servicefee,
      dealcode: selectedFlt.dealcode,
      transId: selectedFlt.transId,
      jsessionId: selectedFlt.jsessionId,
      //For availability we are checking src orign and dest orign level pr and paxpr , if not available we are taking orign level pr and paxpr and sending 
      paxpr: selectedFlt.fareop[0].paxpr?selectedFlt.fareop[0].paxpr:flt.paxpr,
      pr: selectedFlt.fareop[0].pr?selectedFlt.fareop[0].pr:flt.pr,
      sup_currency:selectedFlt.sup_currency && selectedFlt.sup_currency!==undefined?selectedFlt.sup_currency:undefined,
      ugk:selectedFlt.ugk && selectedFlt.ugk!==undefined?selectedFlt.ugk:undefined
    };
    if (selectedFlt.token) {
      payload.token = selectedFlt.token;
      payload.extra = selectedFlt.extra ? selectedFlt.extra : "";
    }
    if (selectedFlt.journeyKey) {
      payload.journeyKey = selectedFlt.journeyKey;
    }
    if (selFareObj[0].aj_fare_key) {
      payload.aj_fare_key = selFareObj[0].aj_fare_key;
    }
    if (selFareObj[0].fareKey) {
      payload.fareKey = selFareObj[0].fareKey;
    }
    if (selFareObj[0].promotion_code) {
      payload.promotion_code = selFareObj[0].promotion_code;
    }
    return payload;
  };

  const createBaggage = (type1, qant1, type2, qant2) => {
    let bag = [];
    const req1 = {
      qun: qant1,
      wt: "kg",
      type: type1,
    };
    bag.push(req1);
    const req2 = {
      qun: qant2,
      wt: "kg",
      type: type2,
    };
    bag.push(req2);
    return bag;
  };

  //Checking both Fare options
  const checkIndiFareCombination = (flt) => {
    let src_origns = [];
    if (
      flt.srcorigns.length > 1 &&
      window.sessionStorage.getItem(OTID) !== null
    ) {
      for (let src of flt.srcorigns) {
        if (src.tid === window.sessionStorage.getItem(OTID)) {
          src_origns = [src];
          break;
        }
      }
    } else {
      src_origns = flt.srcorigns;
    }
    let dest_origns = [];
    if (
      flt.destorigins.length > 1 &&
      window.sessionStorage.getItem(RTID) !== null
    ) {
      for (let src of flt.destorigins) {
        if (src.tid === window.sessionStorage.getItem(RTID)) {
          dest_origns = [src];
          break;
        }
      }
    } else {
      dest_origns = flt.destorigins;
    }
    if (src_origns.length > 0 && dest_origns.length > 0) {
      let srcList = src_origns[0].fareop?.filter((obj) => obj.selected);
      let destList = dest_origns[0].fareop?.filter((obj) => obj.selected);
      if (
        srcList &&
        destList &&
        srcList.length !== 0 &&
        destList.length !== 0 &&
        srcList[0].pr.ftyp !== destList[0].pr.ftyp
      ) {
        //Checking both fare options are equal or not
        if (srcList[0].pr.ftyp === "Regular - R") {
          dispatch({ type: "onward_fare", payload: "SAVER" });
        } else if (srcList[0].pr.ftyp === "Flexi - J") {
          dispatch({ type: "onward_fare", payload: "FLEXI" });
        } else {
          dispatch({ type: "onward_fare", payload: "CORPORATE" });
        }
        if (destList[0].pr.ftyp === "Regular - R") {
          dispatch({ type: "return_fare", payload: "SAVER" });
        } else if (destList[0].pr.ftyp === "Flexi - J") {
          dispatch({ type: "return_fare", payload: "FLEXI" });
        } else {
          dispatch({ type: "return_fare", payload: "CORPORATE" });
        }
        return true;
      }
    }
    return true;
  };

  const createRoundTripFareRuleRequest = async (flt) => {
    let src_origns = [];
    if (flt.srcorigns.length > 1) {
      for (let src of flt.srcorigns) {
        if (src.tid === window.sessionStorage.getItem(OTID)) {
          src_origns = [src];
          break;
        }
        //For Salam Air
        if (window.sessionStorage.getItem(SEL_SALAM_ONE) && selectedFlightOW.sup === "6") {
          let resp = JSON.parse(window.sessionStorage.getItem(SEL_SALAM_ONE));
          if (src.tid === resp.tid) {
            src_origns = [src];
            break;
          }
        }
      }
    } else {
      src_origns = flt.srcorigns;
    }
    let dest_origns = [];
    if (flt.destorigins.length > 1) {
      for (let src of flt.destorigins) {
        if (src.tid === window.sessionStorage.getItem(RTID)) {
          dest_origns = [src];
          break;
        }
        //For Salam Air
        if (window.sessionStorage.getItem(SEL_SALAM_ONE) && selectedFlightOW.sup === "6") {
          let resp = JSON.parse(window.sessionStorage.getItem(SEL_SALAM_TWO));
          if (src.tid === resp.tid) {
            dest_origns = [src];
            break;
          }
        }
      }
    } else {
      dest_origns = flt.destorigins;
    }
    let indigo_sessionKey = "";
    //flyDubai exception
    if (selectedFlightOW.sup === "2" || selectedFlightOW.sup === "5" || selectedFlightOW.sup === "6") {
      src_origns = flt.srcorigns.filter((s) => s.tid == selectedFlightOW.tid);
    }
    if (selectedFlightRT.sup === "2" || selectedFlightRT.sup === "5") {
      dest_origns = flt.destorigins.filter(
        (s) => s.tid == selectedFlightRT.tid
      );
      let tids = selectedFlightOW.tid.replace("_1", "_2");
      dest_origns[0].tid = tids;
    }
    if (selectedFlightOW.sup === "4") {
      src_origns = flt.srcorigns.filter((s) => s.tid == selectedFlightOW.tid);
      indigo_sessionKey = src_origns[0].indigo_ugkey;
    }
    if (selectedFlightRT.sup === "4") {
      dest_origns = flt.destorigins.filter(
        (s) => s.tid == selectedFlightRT.tid
      );
      let tids = selectedFlightOW.tid.replace("_1", "_2");
      dest_origns[0].tid = tids;
    }
    localStorage.setItem("AVAILABILITY_FAILURE", true);
    localStorage.setItem(
      "SELECTED_FLIGHT_OW",
      JSON.stringify(selectedFlightOW)
    );
    localStorage.setItem(
      "SELECTED_FLIGHT_RT",
      JSON.stringify(selectedFlightRT)
    );
    const payload = {
      echo: state.response.echo,
      cha: state.response.cha,
      cur: request.cur,
      adt: state.response.adt,
      chd: state.response.chd,
      inf: state.response.inf,
      cid: state.response.cid,
      sty: selectedSty ? selectedSty : state.response.sty,
      tt: state.response.tt,
      tid: selectedFlightOW.tid,
      office_id:
        selectedFlightOW.office_id !== undefined
          ? selectedFlightOW.office_id
          : "",
      tkt_user_type: "", //context.logindata.utype,
      tkt_user_id: context.user_resp === "" ? "" : context.user_resp?.res != undefined ? context.user_resp?.res?.id : "",
      client_ip: await publicIpv4(),
      journey_type:
        (request !== undefined) !== undefined &&
        request.journey_type !== undefined
          ? request.journey_type
          : "Onward",
      mappings: state.response.mappings,
      origns: [
        {
          srcorigns: [
            selectedFlightOW.sup === "0"
              ? convertAmadeusFareRequest(selectedFlightOW,flt)
              : convertLCCFareRequest(src_origns[0],flt),
          ],
          destorigins: [
            selectedFlightRT.sup === "0"
              ? convertAmadeusFareRequest(selectedFlightRT,flt)
              : convertLCCFareRequest(dest_origns[0],flt),
          ],
        },
      ],
      psngrs:
        !request.paxChanged &&
        request !== undefined &&
        request.psngrs !== undefined
          ? request.psngrs
          : [],
      flowType: "DIRECT",
      src_country:
      isNotNull(request.src) && request.src.country,
      dest_country:
      isNotNull(request.dest) && request.dest.country,
      src_code: request.srcCode,
      dest_code: request.destCode,
      //"src_city":getCityName(request.srcCode),
      //"dest_city": getCityName(request.destCode),
      region_type: state.response.region_type,
      gst: flt.gst !== undefined ? flt.gst : "",
      paxpr: flt.paxpr,
      pr: flt.pr,
      servicefee: flt.servicefee,
      req_cur:BASE_CURRENCY,
      region_cur:context.regionCur!==undefined && context.regionCur!==""?context.regionCur:localStorage.getItem("REGION_CUR"),
      base_usr_exrate:base_usr_exrate,
      sup_base_exrate:flt.sup_base_exrate,
      dest_sup_base_exrate:flt.dest_sup_base_exrate,
      base_sup_exrate:flt.base_sup_exrate,
      dest_base_sup_exrate:flt.dest_base_sup_exrate,
    };
    if (flt.ugk && flt.sup_currency) {
      payload.ugk = flt.ugk;
      payload.sup_currency = flt.sup_currency;
    }
    //Call Availability
    callAvailability(payload);
  };

  const getCityName = (code) => {
    const root = ReactDOM.createRoot(document.getElementById("root"));
    root.render(
      <AirportMemo
        field="ResultSectionHeader"
        code={code}
        mapping={state.response.mappings}
      />
    );
  };

  const createMultiCityFareRuleRequest = async (flt) => {
    let selectedFlts = [];
    if (multiSelectOne) {
      selectedFlts.push(convertAmadeusFareRequest(multiSelectOne,flt));
      localStorage.setItem(
        "SELECTED_MULTI_ONE",
        JSON.stringify(multiSelectOne)
      );
    }
    if (multiSelectTwo) {
      selectedFlts.push(convertAmadeusFareRequest(multiSelectTwo,flt));
      localStorage.setItem(
        "SELECTED_MULTI_TWO",
        JSON.stringify(multiSelectTwo)
      );
    }
    if (multiSelectThree) {
      selectedFlts.push(convertAmadeusFareRequest(multiSelectThree,flt));
      localStorage.setItem(
        "SELECTED_MULTI_THREE",
        JSON.stringify(multiSelectThree)
      );
    }
    if (multiSelectFour) {
      selectedFlts.push(convertAmadeusFareRequest(multiSelectFour,flt));
      localStorage.setItem(
        "SELECTED_MULTI_FOUR",
        JSON.stringify(multiSelectFour)
      );
    }
    if (multiSelectFive) {
      selectedFlts.push(convertAmadeusFareRequest(multiSelectFive,flt));
      localStorage.setItem(
        "SELECTED_MULTI_FIVE",
        JSON.stringify(multiSelectFive)
      );
    }
    if (multiSelectSix) {
      selectedFlts.push(convertAmadeusFareRequest(multiSelectSix,flt));
      localStorage.setItem(
        "SELECTED_MULTI_SIX",
        JSON.stringify(multiSelectSix)
      );
    }
    localStorage.setItem("AVAILABILITY_FAILURE", true);
    const payload = {
      tid: multiSelectOne.tid,
      echo: state.response.echo,
      cha: state.response.cha,
      cur: request.cur,
      adt: state.response.adt,
      chd: state.response.chd,
      inf: state.response.inf,
      cid: state.response.cid,
      sty: selectedSty ? selectedSty : state.response.sty,
      tt: state.response.tt,
      office_id:
        multiSelectOne.office_id !== undefined ? multiSelectOne.office_id : "",
      mappings: state.response.mappings,
      client_ip: await publicIpv4(),
      journey_type:
        (request !== undefined) !== undefined &&
        request.journey_type !== undefined
          ? request.journey_type
          : "Onward",
      origns: [
        {
          srcorigns: selectedFlts,
        },
      ],
      psngrs:
        !request.paxChanged &&
        request !== undefined &&
        request.psngrs !== undefined
          ? request.psngrs
          : "",
      flowType:
        request !== undefined && request.flowType !== undefined
          ? request.flowType
          : "DIRECT",
      tkts:
        request !== undefined &&
        request.tkts !== undefined &&
        request.tkts.length !== 0
          ? request.tkts
          : [],
      bkref:
        request !== undefined && request.bref !== undefined ? request.bref : "",
      oldseg:
        request !== undefined &&
        request.seg !== undefined &&
        request.seg.length !== undefined
          ? request.seg
          : [],
      pnr:
        request !== undefined && request.pnr !== undefined ? request.pnr : "",
      oldtid: request.tid,
      segments:
        state.response.segments !== undefined ? state.response.segments : [],
      src_country: convertSrcCountry(request),
      dest_country: convertDestCountry(request),
      gst: "", //state.selPrice.gst!==undefined?state.selPrice.gst:"",
      paxpr: flt.paxpr,
      pr: flt.pr,
      servicefee: flt.servicefee,
      req_cur:BASE_CURRENCY,
      region_cur:context.regionCur!==undefined && context.regionCur!==""?context.regionCur:localStorage.getItem("REGION_CUR"),
      base_usr_exrate:base_usr_exrate,
      sup_base_exrate:flt.sup_base_exrate,
      base_sup_exrate:flt.base_sup_exrate
    };
    //Call Availability
    callAvailability(payload);
  };

  const convertSrcCountry = (srequest) => {
    var countryCodes = "";
    if (srequest.orignDataOne !== undefined) {
      countryCodes = srequest.orignDataOne.country;
    }
    if (srequest.orignDataTwo !== undefined) {
      countryCodes = countryCodes + "," + srequest.orignDataTwo.country;
    }
    if (srequest.orignDataThree !== undefined) {
      countryCodes = countryCodes + "," + srequest.orignDataThree.country;
    }
    if (srequest.orignDataFour !== undefined) {
      countryCodes = countryCodes + "," + srequest.orignDataFour.country;
    }
    if (srequest.orignDataFive !== undefined) {
      countryCodes = countryCodes + "," + srequest.orignDataFive.country;
    }
    if (srequest.orignDataSix !== undefined) {
      countryCodes = countryCodes + "," + srequest.orignDataSix.country;
    }
    if (countryCodes !== "" && countryCodes !== undefined) {
      countryCodes = Array.from(new Set(countryCodes.split(","))).toString();
    }
    return countryCodes;
  };
  const convertDestCountry = (srequest) => {
    var countryCodes = "";
    if (srequest.destDataOne !== undefined) {
      countryCodes = srequest.destDataOne.country;
    }
    if (srequest.destDataTwo !== undefined) {
      countryCodes = countryCodes + "," + srequest.destDataTwo.country;
    }
    if (srequest.destDataThree !== undefined) {
      countryCodes = countryCodes + "," + srequest.destDataThree.country;
    }
    if (srequest.destDataFour !== undefined) {
      countryCodes = countryCodes + "," + srequest.destDataFour.country;
    }
    if (srequest.destDataFive !== undefined) {
      countryCodes = countryCodes + "," + srequest.destDataFive.country;
    }
    if (srequest.destDataSix !== undefined) {
      countryCodes = countryCodes + "," + srequest.destDataSix.country;
    }
    if (countryCodes !== "" && countryCodes !== undefined) {
      countryCodes = Array.from(new Set(countryCodes.split(","))).toString();
    }
    return countryCodes;
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
 
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(shortUrl);
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 1500); // Reset the "copied" state after 1.5 seconds
    } catch (error) {
      console.error('Copy failed:', error);
    }
  }

  const handleShortenUrl = (options) => {
    let meshKey = "";
    // if(!isNotNull(shortUrl) && (sharedUrlCur !== sessionStorage.getItem("SEL_CUR"))){
      try {
        if(true){
          const payload = {
            url: longUrl.replace(longUrl.substring(longUrl.lastIndexOf('&'))?.split("=")[1], sessionStorage.getItem("SEL_CUR")),
            product_type: 'flight',
            mesh_key: Utilities.createMeshKey(options)
          }
          let tokenKey = Utilities.generateAPIToken(payload);
          CntCommonService.storeLongUrl(payload,tokenKey).then((response) => {
            if (response.data.res !== undefined && response.data.suc) {
              var h = window.location.host.includes('localhost')?'http':'https';
              setShortUrl(`${h}://${window.location.host}`+"/cnt?"+response.data.res.url_id); // need to change host to hostname
              setLoadShortUrl(true);
            }
          }).catch(function (error) {
            console.error("Error :" + error);      
          });
        } else {
          setLoadShortUrl(true);
      }        
      } catch (error) {
        console.error('Error:', error);        
      }
  }

  const closeShare=()=>{
    setLoadShortUrl(false);
  }
  const resetFilter = () => {
    setTooManyFilterReset(!tooManyFilterReset);
    handleFilterChanges(null, "clear", raw_srp_data);
  }

 

  return (
    <>
      {loading && (
        <LoaderN
          title={context.translation && context.translation.pleaseWait}
          subtitle={context.translation && context.translation.redir}
        />
      )}
      <div>
        {state.showErrorMessage && (
          <Alert
            message={state.errorMessage}
            closable
            type="error"
            onClose={() =>
              dispatch({ type: "showErrorMessage", payload: false })
            }
            className="alertMsg"
          />
        )}

        <Row>
          <div className="openFilter">
            <Button type="secondary" onClick={showDrawer} className="">
              {/* {context.translation && context.translation.opnFtr} */}
              <i className="icon icon-Filter"></i> {context.translation && context.translation.filter}
            </Button>
            <Drawer placement="left" open={open} className="flightFilterPopup">
            <i className="icon icon-cancel" onClick={onClose}></i>
              {state.response && state.response.origns && (
                <FlightFilters
                  searchRes={state.response}
                  handleFilterChanges={handleFilterChanges}
                  searchRequest={request}
                  initRes={initRes}
                  toggle={toggleFare}
                  raw_srp_data={raw_srp_data}
                  base_usr_exrate={base_usr_exrate}
                  reset={tooManyFilterReset}
                />
              )}
            </Drawer>
          </div>

          <Col xl={6} lg={0} md={0} sm={0} xs={0}>
            {state.response && state.response.origns && (
              <StickyBox offsetTop={100} offsetBottom={20}>
                <FlightFilters
                  searchRes={state.response}
                  handleFilterChanges={handleFilterChanges}
                  searchRequest={request}
                  initRes={initRes}
                  toggle={toggleFare}
                  raw_srp_data={raw_srp_data}
                  base_usr_exrate={base_usr_exrate}
                  reset={tooManyFilterReset}
                />
              </StickyBox>
            )}
          </Col>
          <Col xl={18} lg={24} md={24} sm={24} xs={24}>
          <div className="rightSideResult">
            {toggleFareSummary && (
              <>
                {" "}
                <Row>
                  {state.response && state.response.origns && (
                    <FlightFareSummary
                      searchRes={state.response}
                      handleFilterChanges={handleFilterChanges}
                      searchRequest={request}
                      initRes={initRes}
                      toggle={toggleFare}
                      base_usr_exrate={base_usr_exrate}
                    />
                  )}
                </Row>
                <div style={{ marginTop: "16px" }}></div>
              </>
            )}

            <Row>
            {state.response && state.response.origns && (
              <FlightResultsSort
                searchRes={state.response}
                handleFilterChanges={handleFilterChanges}
                searchRequest={request}
                initRes={initRes}
                raw_srp_data={raw_srp_data}
                base_usr_exrate={base_usr_exrate}
              />
            )}
            </Row>
            {state.response &&
              state.response.origns &&
              state.response.origns.length === 0 && (
                <>
                  {filterEmpty ?
                    <>
                    <div className="tooManySearchWrap">
                        {/* <img src={MagnifyingGlass} alt="magnifying-icon" /> */}
                        <img src={TooManyFilterIcon} alt="too-many-filter-icon" />
                        <Title level={3}>
                          {context.translation && context.translation.noMatch}
                        </Title>
                        <p>{context.translation && context.translation.noMatchPara}</p>
                        <Button type="secondary" onClick={resetFilter} ghost>
                          <i className="icon icon-Reset"></i> &nbsp; {context.translation && context.translation.noMatchBtn}
                        </Button>
                    </div>
                    </>
                  :
                  <NoResultsFound />
                }
                </>
              )}

            {state.response && state.response!=="" &&
              state.response?.origns?.map(
                (flights, index) =>
                  (state.response.tt === "1" ||
                    state.response.tt === "2" ||
                    state.response.tt === 3) &&
                  index < showResults && (
                    <div className={state.response.tt === "1"?"results-block Oneway":"results-block"} key={index}>
                      <div className="topSemicircle"></div>
                      {checkCheapestFilterClicked() &&
                        flights.cheapest === true && (
                          <div className="cheap">{context.translation && context.translation.chpest} </div>
                        )}
                        {checkQuickestFilterClicked() &&
                        flights.quickest === true && (
                          <div className="quick">{context.translation && context.translation.qukest} </div>
                        )}
                      {flights.prevSelected &&
                        flights.prevSelected === true && (
                          <div className="recentlySelected">
                            <i className="icon icon-tick-circle"></i>{context.translation && context.translation.yuReSe}
                          </div>
                        )}
                        {flights.shared &&
                        flights.shared === true && (
                          <div className="recentlySelected">
                            <i className="icon icon-tick-circle"></i>{context.translation && context.translation.shareIt}
                          </div>
                        )}
                     
                        {enable && state.response!=="" && parseInt(state.response.tt) !== 3 && (
                          <FlightResultsBlock
                            sty={flights.sty ? flights.sty : state.response.sty}
                            impTag={props.impTag}
                            mapping={state.response.mappings}
                            searchRes={flights.srcorigns}
                            searchRTRes={flights.destorigins}
                            paxpr={flights.paxpr}
                            trip={request.tt.toString()}
                            sect={state.response.sec}
                            currency={request.cur}
                            secType={"onward"}
                            sectors={flights}
                            fltIndex={index}
                            srequest={request}
                            flightsSel={[state.srcorigns, state.destorigins]}
                            serviceFee={flights.servicefee}
                            onFlyMkp={0}
                            regionType={state.response.region_type}
                            selectedFlight={selectedFlightOW}
                            selectedRTFlight={selectedFlightRT}
                            updateFlt={updateSelectedFlight}
                            updateRTFlt={updateSelectedRTFlight}
                            initRes={initRes}
                            updateSty={updateSty}
                            fireFareRule={updateSelectedFlights}
                            handleShortenUrl={handleShortenUrl}
                            base_usr_exrate={base_usr_exrate}
                            index={index}
                          />
                        )}
                        {parseInt(state.response.tt) === 3 && (
                          <FlightResultsBlock
                            sty={flights.sty ? flights.sty : state.response.sty}
                            impTag={props.impTag}
                            mapping={state.response.mappings}
                            searchRes={flights.srcorigns}
                            paxpr={flights.paxpr}
                            trip={request.tt.toString()}
                            sect={state.response.sec}
                            currency={request.cur}
                            secType={"onward"}
                            sectors={flights}
                            fltIndex={index}
                            srequest={request}
                            serviceFee={flights.servicefee}
                            onFlyMkp={0}
                            regionType={state.response.region_type}
                            selectedFlight={[
                              multiSelectOne,
                              multiSelectTwo,
                              multiSelectThree,
                              multiSelectFour,
                              multiSelectFive,
                              multiSelectSix,
                            ]}
                            updateFlt={updateMultiFlightSelection}
                            initRes={initRes}
                            count={1}
                            updateSty={updateSty}
                            fireFareRule={updateSelectedFlights}
                            handleShortenUrl={handleShortenUrl}
                            base_usr_exrate={base_usr_exrate}
                            index={index}
                          />
                        )} 
                    </div>
                  )
              )}
              </div>
          </Col>
        </Row>
        <div style={{ float: "right" }} ref={EndDivRef}></div>
      </div>
       <Modal title="" centered open={state.loadResults} footer={null} className="flightGoneLoader">
        <div className="titlePopup">{context.translation && context.translation.flgtAvailGon}</div>
        <div className="contentPopup">{context.translation && context.translation.warysatsfatbu}</div>
        <div className="loaderImagePopup"><img src={FlightGoneLoader} /></div>
      </Modal>

      <Modal title="" onOk={closeShare} onCancel={closeShare} centered open={loadShortUrl}
       footer={null}  className="srpSharePopup itineraryPopup">
                         <small> 
                         <i
                          className="icon icon-cancel"
                          onClick={() => {
                            closeShare();
                          }}
                        ></i>
                          <Title level={4} className="shareItienaryTitle">
                          {context.translation && context.translation.shrThsIti}
                        </Title>
                        <Space direction="horizontal" className="displayNone-640">
                          <Input disabled
                            className="shareItienaryInput"
                            placeholder={shortUrl}
                          />
                         <Button onClick={handleCopy} type="primary" className="cntBtn smallCntBtn">
                           {copied?(context.translation && context.translation.copied):(context.translation && context.translation.copy)}
                          {/* <Button type="primary" className="cntBtn smallCntBtn">
                            {context.translation && context.translation.send} */}
                          </Button>
                        </Space>
                        <div className="displayNoneLG copyLinkText gg" onClick={handleCopy}> <img src={Shareicon}/> &nbsp;{copied?(context.translation && context.translation.copied):(context.translation && context.translation.copy)} link</div>
                        <div className="socialMediaWrap">
                          <WhatsappShareButton url={shortUrl}>
                          <img src={Whatsapp} alt="social-media-icon" />
                          <span>{context.translation && context.translation.whtap}</span>
                          </WhatsappShareButton>
                        </div>
                        </small>
      </Modal>
    </>
  );
}

export default FlightResultsDetails;
