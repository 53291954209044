/**
 * @ Author: Srikanth Bandaru
 * @ Create Time: 2023-05-13 14:05:26
 * @ Description: Common footer for the application
 */

import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { Col, Row, Typography, Select, Form } from "antd";
import { Context } from "../../App";
import Logo from "../../assets/gif/Once/Coloured/CLICK-N-TRAVEL_ONCE.gif";
import Secure from "../../assets/svg/secure.svg";
import PCIDSS from "../../assets/svg/pci-dss.svg"

const { Title } = Typography;
const { Option } = Select;

function Footer() {
  const [context, setContext] = useContext(Context);
  const [showSupport, setShowSupport] = useState(false);


  return (
    <>
      <div className="footer">
        <div className="container">
          <div className="topFooter">
              <div className="topFooterRight">
                <div className="footerRightWrap">
                  <div className="footerRight">
                  <Title level={5}>
                {/* Company */}
                {context.translation && context.translation.ftrCmTlt}
              </Title>
              <div className="f-links">
                <Link to={sessionStorage.getItem("SEL_LAG") === "English"||sessionStorage.getItem("SEL_LAG") === null ? "/terms-conditions":"/terms-conditions-ar"} target="_blank">
                  {/* Terms & Conditions */}
                  {context.translation && context.translation.ftrCmLnk1}
                </Link>
              </div>
              <div className="f-links">
                <Link to={sessionStorage.getItem("SEL_LAG") === "English"||sessionStorage.getItem("SEL_LAG") === null ? "/privacy-policy":"/privacy-policy-ar"} target="_blank">
                  {/* Privacy Policy */}
                  {context.translation && context.translation.ftrCmLnk2}
                </Link>
              </div>
              {/* <div className="f-links">
                <Link to="#"> */}
                  {/* Legal */}
                  {/* {context.translation && context.translation.ftrCmLnk3}
                </Link>
              </div> */}
                  </div>
                  <div className="footerRight">
                  <Title level={5}>
                {/* Corporate */}
                {context.translation && context.translation.ftrCorTlt}
              </Title>
              <div className="f-links">
                {/* <Link to="/about" target="_blank"> */}
                  {/* About US  */}
                  {/* {context.translation && context.translation.ftrCorLnk1}
                </Link> */}
                <a href="">
                  {context.translation && context.translation.ftrCorLnk1}
                </a>
              </div>
              {/* <div className="f-links">
                <Link to="#"> */}
                  {/* Careers */}
                  {/* {context.translation && context.translation.ftrCorLnk2}
                </Link>
              </div> */}
              {/* <div className="f-links">
                <Link to="#"> */}
                  {/* FAQs */}
                  {/* {context.translation && context.translation.ftrSupLnk2}
                </Link>
              </div> */}
                  </div>
                  <div className="footerRight">
                  <Title level={5}>
                {/* Support */}
                {/* {context.translation && context.translation.ftrSupTlt} */}
                {context.translation && context.translation.generInq}  
              </Title>
              <div className="f-links">
              <i className="icon icon-footerEmail"></i>
              <a href="mailto:support@clickntravel.com">
                 support@clickntravel.com
                </a>
              </div>
              <Title level={5} className="marginTop20">
                {/* Support */}
                {/* {context.translation && context.translation.ftrSupTlt} */}
                {context.translation && context.translation.flightInq}
              </Title>
              <div className="f-links">
              <i className="icon icon-footerEmail"></i>
              <a href="mailto:flightsupport@clickntravel.com">
                  flightsupport@clickntravel.com
                </a>
              </div>
                  </div>
                  <div className="footerRight">
                  <Title level={5}>
                {/* Need help ? */}
                {/* {context.translation && context.translation.ftrHlpTlt} */}
                {/* 24X7 {context.translation && context.translation.ftrSupTlt} */}
                {/* Contact US  */}
                {context.translation && context.translation.ftrSupLnk1}
              </Title>
              <div className="f-links">
              <i className="icon icon-footerCall"></i>
                <a href="tel:+974 44484847" className="leftToRight">
                   +974 44484847
                </a>
              </div>
                  </div>
                </div>
              </div>
              <div className="topFooterLeft">
                <img src={Logo} alt="Click N Travel" className="footerBrandLogo" />
                <div className="social-links">
                  <Link to="https://www.facebook.com/profile.phpPid=61554406513897" target="_blank">
                    <i className="icon icon-facebook"></i>
                  </Link>
                  <Link to="https://twitter.com/letsclick23" target="_blank">
                    <i className="icon icon-twitter"></i>
                  </Link>
                  <Link  to="https://www.instagram.com/clickntravel_letsclick/" target="_blank">
                    <i className="icon icon-instagram"></i>
                  </Link>
                  <Link  to="https://www.linkedin.com/company/letsclickqa/?viewAsMember=true" target="_blank">
                    <i className="icon icon-linkedin"></i>
                  </Link>
                </div>
              </div>
          </div>
         
        </div>
        <div className="bottomFooter">
          <div className="container">
            <Row>
              <Col span={24}>
                <div className="commonFlex">
                  <div className="f-copyright">
                    <span>
                      {/* Copyright  */}
                      {context.translation && context.translation.copyRights}
                      </span> <span>© {new Date().getFullYear()} Click n Travel</span>
                  </div>
                  <div className="f-secure">
                    <img src={PCIDSS} alt="PCIDSS" className="PCIDSS" />
                    <img src={Secure} alt="Click N Travel Secure" />
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
