import React, { useState,useEffect,useContext } from "react";
import { Context } from "../../App";
import {
    Form,
    Select,
  } from "antd";

function Days(props) {
  const [context, setContext] = useContext(Context)
    const [day,setDay] = useState("")
    const [days,setDays] = useState([])
    const { Option } = Select;
    
    useEffect(()=>{
        var list = [];
        for (var i = 1; i <= 31; i++){
        if(String(i).length===1){
          i = "0"+i;
        }
        list.push({label:i,value:i});
        }
        setDays(list)
      },[])

      useEffect(() => {
        setDay(props.updateDay)
      }, [props.updateDay]);
   
      const updateDay=(day)=>{
        setDay(day)
        props.sendDay(day)
      }

  return (
    <> 
      <Form.Item autoComplete="off" className="pRelative">
      <>                   
          <Select
            name="day"
            defaultValue={context.translation && context.translation.day}
            value={day?day:undefined}
            onChange={(value) => (updateDay(value))}
            placeholder={context.translation && context.translation.day}
            popupClassName="dayDpDown" 
            >
            <Option value="Day">{context.translation && context.translation.day}</Option>
            {days.map((day, index) => (
              <Option key={index} value={day.value}>
                {day.label}
              </Option>
            ))}
          </Select>
      </>
    </Form.Item>              
    </>
  );
}
export default Days;
