import React, { useEffect, useState } from "react";
import { Select } from "antd";
import Utilities from "../../../../utilities/Utilities";
const { Option } = Select;

/**
 * @description:This function will creaet all the Add Baggage details
 * @author: Ubedullah
 * @param {*}
 * @function Baggagr details
 * @date : 08-08-2023
 */

const AddBaggage = (props) => {
  const [bagDefault, setBagDefault] = useState([]);
  const [bagOptions, setBagOptions] = useState([]);

  useEffect(() => {
    if (props.bagList !== undefined) {
      if (props.bagList.includes("Select")) {
        setBagDefault([]);
      } else {
        if (
          props.bagList.length !== 0 &&
          props.bagList[0] !== undefined &&
          props.bagList[0].value !== undefined
        ) {
          setBagOptions(props.bagList);
          setBagDefault({
            value: props.bagList[0].value,
            label: props.bagList[0].label,
          });
        } else {
          for (let data of props.bagList) {
            setBagOptions(data);
            setBagDefault({ value: data[0].value, label: data[0].label });
          }
        }
      }
    }
  }, []);

  const setBaggagePrice = (value) => {
    const obj = bagOptions.find((option) => option.label === value);
    // const obj = props.bagList.filter((val) => val.code === option.code);
    props.sendBagprice(obj);
    if (!props.bagList.includes("Select")) {
      setBagDefault(value);
    }
  };

  return (
    <>
    <div className="addBaggageWrap">
      <Select
        // isDisabled={props.disable}
        // options={bagOptions}
        value={bagDefault}
        onChange={setBaggagePrice}
        className="addBaggage"
        popupClassName="baggageDpDown"
      >
        {bagOptions.map((option) => {
          return (
            <Option key={option.label} value={option.label}>
              {option.label}
            </Option>
          )
          }
        )}
      </Select>
      
      </div>
    </>
  );
};

export default AddBaggage;
