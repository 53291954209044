import React, { useEffect, useState, useReducer,useContext } from "react";
import { Context } from "../../App";
import { Checkbox, Input, Button, Alert } from "antd";
import { cancelRoom } from "./operations";
import Utilities from "../utilities/Utilities";

/**
 * Initial State Declaration
 */
const initialState = {
  notiMessage: "",
  notiMessageShow: false,
  notiVarient: "",
};

//=====================================
// Reducer function for UserAction
// which will update the state
//=====================================
const reducer = (state, action) => {
  switch (action.type) {
    default:
      return { ...state, [action.type]: action.payload };
  }
};

function CancelHotel({ cancelData, close }) {
  const [context, setContext] = useContext(Context);
  const [state, dispatch] = useReducer(reducer, initialState);
  const [email, setEmail] = useState("");
  const [cancelRemarks, setCancelRemarks] = useState("");
  const [selectedRooms, setSelectedRooms] = useState([cancelData.rooms[0]]);

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("SIGNINRESPONSE"));
    if (data !== null) {
      setEmail(data.res.email);
    }
  }, [localStorage.getItem("SIGNINRESPONSE")]);

  // Function to handle checkbox changes
  const handleCheckboxChange = (checked, room) => {
    if (checked) {
      // If checked, add the item to the selectedRooms state
      setSelectedRooms([...selectedRooms, room]);
    } else {
      // If unchecked, remove the item from the selectedRooms state
      const updatedRooms = selectedRooms.filter(
        (selectedRoom) => selectedRoom.room_number !== room.room_number
      );
      setSelectedRooms(updatedRooms);
    }
  };

  const handleRemarksChange = (e) => {
    setCancelRemarks(e.target.value);
  };

  const backToNormal = () => {
    close(false);
  };

  const submitCancel = () => {
    var selrooms = [];
    const filterSelectedRooms = selectedRooms.filter((room) => room.room_status.toUpperCase() === "CONFIRMED");
    if (filterSelectedRooms.length > 0) {
      filterSelectedRooms.forEach((rm, idx) => {
        var rmid = rm.rmid;
        var htlconfno = rm.supplier_ref;
        var htlrefno = rm.hotel_ref;
        var room_number = rm.room_number;
        var cancellation_policy = rm.cancellation_policy;
        selrooms.push({
          rmid,
          htlconfno,
          htlrefno,
          room_number,
          cancellation_policy,
        });
      });
    }
    try {
      const payload = {
        email_id: email,
        sup: cancelData.sup,
        booking_id: cancelData.booking_id,
        hid: cancelData.hid,
        roomInfo: selrooms,
        upby: cancelData.upby,
        cid: cancelData.cid,
        cur: cancelData.usr_currency,
        region_type: cancelData.region_type,
        remarks: cancelRemarks,
      };
      //Call Cancel Room API
      let tokenKey = Utilities.generateAPIToken(payload);
      cancelRoom(payload,tokenKey).then((response) => {
        if (response.suc) {
          dispatch({ type: "notiMessageShow", payload: true });
          dispatch({
            type: "notiMessage",
            payload: response.res,
          });
          dispatch({ type: "notiVarient", payload: "success" });
          hidemessage();
          setTimeout(() => {
            backToNormal();
          }, 3000);
        } else {
          dispatch({ type: "notiMessageShow", payload: true });
          dispatch({
            type: "notiMessage",
            payload: response.data,
          });
          dispatch({ type: "notiVarient", payload: "error" });
          hidemessage();
          setTimeout(() => {
            backToNormal();
          }, 3000);
        }
      }).catch(function (error) {
        console.error("Error:" + error);      
      });
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const hidemessage = () => {
    setTimeout(function () {
      dispatch({ type: "notiMessageShow", payload: false });
      dispatch({ type: "notiMessage", payload: "" });
      dispatch({ type: "notiVarient", payload: "" });
    }, 3000);
  };

  return (
    <div>
      {state.notiMessageShow ? (
        <Alert
          description={state.notiMessage}
          type={state.notiVarient}
          closable
          onClose={() => dispatch({ type: "notiMessageShow", payload: false })}
          className="alertForm"
          // showIcon
        >
          {" "}
          {state.notiMessage}
        </Alert>
      ) : (
        ""
      )}
      {cancelData &&
        cancelData.rooms.map((room, idx) => (
          <div className="hotelRoomDetailsWrap" key={idx}>
            {room.room_status.toUpperCase() !== "CONFIRMED" ? (
              <>
              <span className="hotelRoomWrap">{context.translation && context.translation.room} {room.room_number} : {room.room_name} </span>
              <span className="hotelRoomStatusWrap">{context.translation && context.translation.status}:{" "}
                <span className="hotelRoomStatus primary">{room.room_status}</span>
              </span>
                
              </>
            ) :
            <Checkbox
              key={room.room_number}
              checked={selectedRooms.some(
                (selectedRoom) => selectedRoom.room_number === room.room_number
              )}
              onChange={(e) => handleCheckboxChange(e.target.checked, room)}
              disabled={room.room_status.toUpperCase() !== "CONFIRMED" ? true : false}
            >
              
              <span className="hotelRoomWrap">{context.translation && context.translation.room} {room.room_number} : {room.room_name} </span>
              <span className="hotelRoomStatusWrap">{context.translation && context.translation.status}:{" "}
                <span className="hotelRoomStatus active">{room.room_status}</span>
              </span>
            </Checkbox>
            } 
          </div>
        ))}

      <Input.TextArea
        rows={3}
        placeholder={context.translation && context.translation.remarks}
        onChange={handleRemarksChange}
        value={cancelRemarks}
        className="remarkArea"
      />
      <p className="noteWrap"> <span className="notetitle">{context.translation && context.translation.note}</span>  {context.translation && context.translation.willconnect} </p>
      <div className="buttonWrap">
        <Button onClick={backToNormal} className="backBtn">
          {context.translation && context.translation.cancel}
        </Button>
        <Button type="primary" onClick={submitCancel} className="cntBtn" 
          disabled={cancelData.rooms.filter((room) => room.room_status.toUpperCase() === "CONFIRMED").length > 0 ? false : true}
        >
          {context.translation && context.translation.reqCan}
        </Button>
      </div>
    </div>
  );
}

export default CancelHotel;
