/**
 * @ Author: Srikanth Bandaru
 * @ Create Time: 2023-05-18 00:16:44
 * @ Description: This is to fetch the Airlines Object with the help of airline code
 */

import React, { useContext } from "react";
import { AirlinesContext } from "../../../../../App";
import { Context } from "../../../../../App";

/**
 *  For the airlines object fetching with respective to the code received
 */
function AirlinesMemo({ code }) {
  const airlinesInfo = useContext(AirlinesContext);
  const [context, setContext] = useContext(Context);
  let name = "";
  if (
    airlinesInfo !== undefined &&
    airlinesInfo.filter((obj) => obj.value === code)[0]
  ) {
    name = context.selectLang == undefined || context.selectLang === "en" ? airlinesInfo.filter((obj) => obj.value === code)[0].label : airlinesInfo.filter((obj) => obj.value === code)[0].label_ar;
  }
  return name;
}

export default AirlinesMemo;
