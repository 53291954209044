/**
 * @ Author: Srikanth Bandaru
 * @ Create Time: 2023-06-12 09:05:48
 * @ Description: Common header for the application
 */

import React, { useContext, useState } from "react";
import useOnclickOutside from "react-cool-onclickoutside";
// import Select from 'react-select';
// import { colourOptions } from '../data';
import {Alert, Col, Drawer, Row, Select, Button, Typography, Modal, notification } from "antd";
import Logo from "../../assets/gif/Once/Coloured/CLICK-N-TRAVEL_ONCE.gif";
import { Link } from "react-router-dom";
import AuthenticationPopUp from "./AuthenticationPopUp";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Context } from "../../App";
import Title from "antd/es/skeleton/Title";
import { getProfile } from "../myAccount/operations";
import ProfileIcon from "../../assets/img/profile-icon.webp";
import Utilities from "../utilities/Utilities";

function Header(props) {
  const [api, contextHolder] = notification.useNotification();
  const [visible, setVisible] = useState(false);
  const [showMenu, setshowMenu] = useState(false);
  const [signin, setSignin] = useState(true);
  // const [signUp, setSignUp] = useState(false);
  const [profileImage, setProfileImage] = useState(null);
  const [uploadProfPic, setUploadProfPic] = useState(null);
  const navigate = useNavigate();
  const [notiMessage, setNotiMessage] = useState("");
  const [notiMessageShow, setNotiMessageShow] = useState(false);
  const [notiVarient, setNotiVarient] = useState();
  const [context, setContext] = useContext(Context);
  const [showSupport, setShowSupport] = useState(false);
  const [showLang, setShowLang] = useState(false);
  const [currencyList, setCurrencyList] = useState([{value:"AED","label":"AED"},{value:"KWD","label":"KWD"},{value:"OMR","label":"OMR"},{value:"QAR","label":"QAR"},{value:"SAR","label":"SAR"},]);
  const [userCur,setUserCur] = useState("");
  // const [name, setName] = useState("");
  const [userUniqId, setUserUniqId] = useState("");
  const [langLabel, setLangLabel] = useState(window.location.href.substring(window.location.href.lastIndexOf('/')) === "/ar" ? "Arabic" : sessionStorage.getItem("SEL_LAG") === "Arabic" ? "Arabic" : "");
  let data = JSON.parse(localStorage.getItem("SIGNINRESPONSE"));
  
  useEffect(() => {
    var filename = window.location.href.substring(window.location.href.lastIndexOf('/'));
    if(filename==="/en" || filename==="/ar" || filename==="/"){
      removeStorage();
    }
    var sharedUrl = window.location.href.substring(window.location.href.lastIndexOf('&'));
    var sharedUrlCur = sharedUrl?.split("=")[1];
    if (localStorage.getItem("REGION_COUNTRY") !== null && (sharedUrlCur === "QAR" || sharedUrlCur === undefined) &&
    (sessionStorage.getItem("SEL_CUR")===null || sessionStorage.getItem("SEL_CUR")==="" || sessionStorage.getItem("SEL_CUR")===undefined || localStorage.getItem("REGION_CUR")===null || localStorage.getItem("REGION_CUR")==="" || localStorage.getItem("REGION_CUR")===undefined)) {
      if(localStorage.getItem("REGION_COUNTRY")==="QA"){
        setUserCur("QAR");
        context.userCur="QAR";
        context.regionCur="QAR"
        sessionStorage.setItem("SEL_CUR", "QAR");
        localStorage.setItem("REGION_CUR","QAR");
      }else if(localStorage.getItem("REGION_COUNTRY")==="SA"){
        setUserCur("SAR");
        context.userCur="SAR";
        context.regionCur="SAR"
        sessionStorage.setItem("SEL_CUR", "SAR");
        localStorage.setItem("REGION_CUR","SAR");
      }else{
        setUserCur("QAR");
        context.userCur="QAR";
        context.regionCur="QAR"
        sessionStorage.setItem("SEL_CUR", "QAR");
        localStorage.setItem("REGION_CUR","QAR");
      }
    } else if((sessionStorage.getItem("SEL_CUR")===null || sessionStorage.getItem("SEL_CUR")==="" || sessionStorage.getItem("SEL_CUR")===undefined || localStorage.getItem("REGION_CUR")===null || localStorage.getItem("REGION_CUR")==="" || localStorage.getItem("REGION_CUR")===undefined)
     && localStorage.getItem("REGION_COUNTRY") !== null && sharedUrlCur !== "QAR" && sharedUrlCur !== undefined && sharedUrlCur !== "undefined" && sharedUrlCur !== "") {
      setUserCur(sharedUrlCur);
      context.userCur=sharedUrlCur;
      context.regionCur=sharedUrlCur;
      sessionStorage.setItem("SEL_CUR", sharedUrlCur);
      localStorage.setItem("REGION_CUR", sharedUrlCur);
    }else{
        setUserCur(sessionStorage.getItem("SEL_CUR"));
        context.userCur=sessionStorage.getItem("SEL_CUR");
        context.regionCur=localStorage.getItem("REGION_CUR")
    }
  }, [localStorage.getItem("REGION_COUNTRY")]);

  useEffect(() => {
    var pathUrl = window.location.pathname?.split("?");
    if(pathUrl[0] === "/hotel/results") {
      sessionStorage.setItem("SEL_TAB", "Hotels")
    }
  }, [])

  const removeStorage=()=>{
    localStorage.removeItem("staFee")
    localStorage.removeItem("MULTI")
    localStorage.removeItem("htlMarkup")
    localStorage.removeItem("ctid")
    localStorage.removeItem("chdages")
    localStorage.removeItem("htlRequest")
    localStorage.removeItem("ispkg");
    localStorage.removeItem("sreq");
    localStorage.removeItem("modify");
    localStorage.removeItem("meshkey");
    localStorage.removeItem("RESPONSE")
    localStorage.removeItem("AVAILABILITY_FAILURE");
    localStorage.removeItem("SELECTED_FLIGHT_OW");
    localStorage.removeItem("SELECTED_FLIGHT_RT");
    localStorage.removeItem("SELECTED_MULTI_ONE");
    localStorage.removeItem("SELECTED_MULTI_TWO");
    localStorage.removeItem("SELECTED_MULTI_THREE");
    localStorage.removeItem("SELECTED_MULTI_FOUR");
    localStorage.removeItem("SELECTED_MULTI_FIVE");
    localStorage.removeItem("SELECTED_MULTI_SIX");
    localStorage.removeItem("clickCount");
    // localStorage.removeItem("CNT_EX_RATES");    
    sessionStorage.removeItem("SEL_CUR");
    sessionStorage.removeItem("FILTERRESET")
    sessionStorage.removeItem("ISRESET")
    sessionStorage.removeItem("CHEAPEST");
    sessionStorage.removeItem("QUICKEST");
    sessionStorage.removeItem("SELFLTONE");
    sessionStorage.removeItem("SELFLTTWO");
    sessionStorage.removeItem("SELFLTTHREE");
    sessionStorage.removeItem("SELFLTFOUR");
    sessionStorage.removeItem("SELFLTFIVE");
    sessionStorage.removeItem("SELFLTSIX");
    sessionStorage.removeItem("FLTLONGURL")
    sessionStorage.removeItem("cnt_search")
    sessionStorage.removeItem("MAINTENANCE")
    sessionStorage.removeItem("APIFAILED")
    sessionStorage.removeItem("OFFLINE");
    sessionStorage.removeItem("BOOKING_REF_NO");
    sessionStorage.removeItem("seg_1");
    sessionStorage.removeItem("seg_2");
    sessionStorage.removeItem("seg_3");
    sessionStorage.removeItem("seg_4");
    sessionStorage.removeItem("seg_5");
    sessionStorage.removeItem("seg_6");
    sessionStorage.removeItem("pidRequest");
    sessionStorage.removeItem("isPack");
    sessionStorage.removeItem("CMPID");
    sessionStorage.removeItem("HTLLONGURL");
    sessionStorage.removeItem("activeTitle");
    sessionStorage.removeItem("tid");
    sessionStorage.removeItem("cnt_pay_request");
    localStorage.removeItem("air_codes");
  }

        
  useEffect(() => {
    if (data !== null) {
      setUserUniqId(data.res.usr_uniq_id);
      setProfileImage(data.res.profile_pic);
    }
  }, [localStorage.getItem("REGION_COUNTRY")]);

  useEffect(() => {
    
    if (sessionStorage.getItem("SEL_CUR")!== null){
        setUserCur(sessionStorage.getItem("SEL_CUR"))
        setContext({...context, userCur: sessionStorage.getItem("SEL_CUR")});
     }else{
      setUserCur(context.userCur);
      sessionStorage.setItem("SEL_CUR",context.userCur)
     }
  }, [sessionStorage.getItem("SEL_CUR")] || context.userCur);

  // useEffect(() => {
  //   try {
  //     if (data !== null) {
  //       const payload = {
  //         email: data.res.email,
  //       };
  //       let tokenKey = Utilities.generateAPIToken(payload);
  //       getProfile(payload,tokenKey).then((response) => {
  //         if (response.suc) {
  //           setName(response?.res[0]?.fname);
  //         }
  //       }).catch(function (error) {
  //         console.error("Error in Profile API:" + error);      
  //       });
  //     }      
  //   } catch (error) {
  //     console.error("Error in Profile API:", error);      
  //   }
    // }, []);

    useEffect(() => {
      const name = localStorage.getItem("NAME");
      context.username = context.username !== undefined ? context.username : name;
    }, [context.username])

  useEffect(() => {
    if (context.alert === "SIGNIN") {
      setTimeout(() => {
        setNotiMessageShow(true);
        setNotiMessage(context.translation && context.translation.loginsuc);
        setNotiVarient("success");
        hidemessage();
        setContext({...context, alert: ""})
      }, 1000)
    }
  }, [context.alert === "SIGNIN"]);

  useEffect(() => {
    if (context.alert === "SIGNUP") {
      setTimeout(() => {
        setNotiMessageShow(true);
        setNotiMessage(context.translation && context.translation.regiSuc);
        setNotiVarient("success");
        hidemessage();
        setContext({...context, alert: ""})
      }, 1000)
    }
  }, [context.alert === "SIGNUP"]);
  
  const hidemessage = () => {
    setTimeout(function () {
      setNotiMessageShow(false);
      setNotiMessage("");
      setNotiVarient("");
    }, 3000);
  };

  let prof_pic = localStorage.getItem("PROFILE_PIC");
  useEffect(() => {
    if (prof_pic !== null) {
      setUploadProfPic(prof_pic);
    }
  }, [localStorage.getItem("PROFILE_PIC")]);

  const handleMyAccount = () => {
    navigate("../my-account/user-profile");
    // window.location = "../my-account/user-profile";
  };

  const handleShowModal = () => {
    setVisible(true);
  };

  const handleHideModal = () => {
    setVisible(false);
    // window.location.reload();
  };
  const [top] = useState(10);
  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
    setshowMenu(!showMenu);
  };

  const onClose = () => {
    setOpen(false);
  };

  const closeMenu = () => {
    showDrawer();
  };

  const setTabs = (type) => {
    sessionStorage.setItem("SEL_TAB",type)
    props.setTab(type);
    setContext({...context, mostFlownRoutes: "", popularHotels: ""})
  }

  const [locale, setLocale] = useState("English");

  let English = require("../../lang/en.json");
  let Arabic = require("../../lang/ar.json");

  useEffect(() => {
    setContext({...context, translation:English, selectLang: "en"})
    context.translation=English;
    getLanguage();
    if(props.posType!=="floating"){
      let headerAffixHeight = document.querySelector('.header').offsetHeight;
      document.querySelector('.headerAffixHeight').style.height=headerAffixHeight-.5+'px';
    } else {
      document.querySelector('.header').style.position='relative';
    }
    
  }, [context.selectLang, sessionStorage.getItem("SEL_LAG")]);

  const selectCurrency = (value) => {
    setUserCur(value)
    setContext({...context, userCur: value});
    sessionStorage.setItem("SEL_CUR", value);
  }

  const selectLanguage = (value) => {
    const newLocale = value;
    sessionStorage.setItem("SEL_LAG", value);
    setLocale(newLocale);
    if (newLocale === 'English') {
        setContext({...context, selectLang: "en"});
        if(window.location.pathname === "/en" || window.location.pathname === "/" || window.location.pathname === "/ar") {
          props.lang("en");
        }
    } else {
        setContext({...context, selectLang: "ar"});
        if(window.location.pathname === "/ar" || window.location.pathname === "/" || window.location.pathname === "/en") {
          props.lang("ar");
        }
    }
  }

  const getLanguage = () => {
    // if(context.selectLang === "en" || context.selectLang === undefined) {
    if(sessionStorage.getItem("SEL_LAG") === "English") {      
      setContext({...context, translation:English, selectLang: "en"})
    }
    // } else if(context.selectLang === "ar") {
      else if(sessionStorage.getItem("SEL_LAG") === "Arabic") {
      setContext({...context, translation:Arabic, selectLang: "ar"})
    }
  }

  // const Dropdown = () => {
    // const [showSupport, setShowSupport] = useState(false);
    const ref = useOnclickOutside(() => {
      setShowSupport(false);
    });
  
    const supportClick = () => {
      setShowSupport(!showSupport);
    };

    const { Title } = Typography;

    const handleLogoutConfirmation = () => {
      Modal.confirm({
        title: (context.translation && context.translation.wlogout),
        okText: (context.translation && context.translation.yes),
        cancelText: (context.translation && context.translation.no),
        className: "logOut",
        onOk: () => {
          logout();
        },
        onCancel: () => {
          handleCancel();
        },
      });
    };
  
    const logout = () => {
      localStorage.removeItem("SIGNINRESPONSE");
      setContext({...context, user_resp:""})
      localStorage.removeItem("PROFILE_PIC");
      sessionStorage.removeItem("INT_RESPONSE");
      // setActive(true);
      navigate("/");
      api.open({
        message: (context.translation && context.translation.signoutSuc),
        duration: 2,
      });
    };
  
    const handleCancel = () => {
      // window.location = "/";
    };
  
    

  return (
    <>
     {contextHolder} 
        <div className="headerAffixHeight"></div>
        {notiMessageShow ? (
          <Alert
            description={notiMessage}
            type={notiVarient}
            closable
            onClose={() => setNotiMessageShow(false)}
            className="alertForm"
            // showIcon
            style={{zIndex:99999}}
          >
            {notiMessage}
          </Alert>
        ) : (
          ""
        )}
        <Row className="header">
          <Col md={24} lg={12}>
            <Row>
              <Col>
                <Link 
                // to={(context?.selectLang === undefined || context?.selectLang === "en") ? "/en" : "/ar"}
                to={(context?.selectLang === undefined || context?.selectLang === "en") && sessionStorage.getItem("SEL_LAG") === "English" ? "/en" : (context?.selectLang !== undefined || context?.selectLang === "ar") && sessionStorage.getItem("SEL_LAG") === "Arabic" ? "/ar" : "/"} 
                className="logo">
                  <img src={Logo} alt="Click N Travel" className="brandLogo" />
                </Link>
              </Col>
                <Col className="prod-links">
                  <Link
                    to={(context?.selectLang === undefined || context?.selectLang === "en") && sessionStorage.getItem("SEL_LAG") === "English" ? "/en" : (context?.selectLang !== undefined || context?.selectLang === "ar") && sessionStorage.getItem("SEL_LAG") === "Arabic" ? "/ar" : "/"}
                    onClick={(e) => setTabs("Flights")}
                    className={sessionStorage.getItem("SEL_TAB") === null ? "active" : sessionStorage.getItem("SEL_TAB") === "Flights" ? "active" : ""}                    
                  >
                    {/* Flight */}
                    <i className="icon icon-flight1"></i>
                    <span className="prod-text">{context.translation && context.translation.flight}</span>
                    
                  </Link>
                  <Link
                    onClick={(e) => setTabs("Hotels")}
                    to={(context?.selectLang === undefined || context?.selectLang === "en") && sessionStorage.getItem("SEL_LAG") === "English" ? "/en" : (context?.selectLang !== undefined || context?.selectLang === "ar") && sessionStorage.getItem("SEL_LAG") === "Arabic" ? "/ar" : "/"}
                    className={sessionStorage.getItem("SEL_TAB") === "Hotels" ? "active" : ""}  
                  > 
                    {/* Hotels */}
                     <i className="icon icon-H1"></i>
                     <span className="prod-text">{context.translation && context.translation.hotel}</span>
                    
                  </Link>
                  {/* <Link to="#">Transfers</Link>
                  <Link to="#">Packages</Link> */}
                </Col>
            </Row>
          </Col>
          
            <Col md={0} lg={12} className="mobileHidden">
              <div className="action-links">
                <div ref={ref}>
                <div 
                onClick={supportClick}
                className={"mainMenu " + (showSupport && "active")}>
                  <span className="headerMenu"><span><i className="icon icon-call"></i><span>24X7</span></span> {context.translation && context.translation.ftrSupTlt}</span>
                
                {showSupport && (
                <div className="headerSubMenu s-subMenuPosition">
                  <span className="callSupport"><a href="tel:+974 44484847"><i className="icon icon-Call-Solid"></i><span className="revertDirection">+974 44484847</span></a></span>
                 
                  <span className="mailSupport"><a href="mailto:support@clickntravel.com"><i className="icon icon-Email"></i><span>support@clickntravel.com</span></a></span>
                </div>
                )}
                </div>
                </div>
  {/* 
              <Link 
                onClick={() => setShowLang(!showLang)}
                className={showLang && "active"}>
                  <span className="headerMenu"><i className="icon icon-globe"></i>English</span>
                </Link>
                {showLang && (
                <div className="headerSubMenu l-subMenuPosition">
                  <span className="englishLang">English</span>
                  <span className="arabicLang">العربية</span>
                </div>
                )} */}

                {/* <Select
          className="basic-single"
          classNamePrefix="select"
          defaultValue={colourOptions[0]}
          name="color"
          options={colourOptions}
        /> */}


                <div to="#" title="العربية" className="mainMenu">
                  <i className="icon icon-globe"></i>
                    {/* العربية
                    language select dropdown */}
                    <Select 
                      value={{label : langLabel === "Arabic" ? "English" : "العربية"}} 
                      // value={sessionStorage.getItem("SEL_LAG") === null ? "English" : sessionStorage.getItem("SEL_LAG") === "English" ? "English" : "العربية"} 
                      onChange={(e) => {selectLanguage(e); 
                        setLangLabel(e);}} 
                      options={[
                        { value: "English", label: "English" },
                        { value: "Arabic", label: "العربية" },
                      ]}
                      popupClassName="langDpDown"
                    >                  
                    </Select>
                </div> 
                {props.page===undefined &&
                  <div to="#" title={userCur} className="mainMenu currTab">
                <i className="sprite flag-sprite"></i>
                  <Select 
                      value={userCur} 
                      onChange={selectCurrency} 
                      options={currencyList}
                      popupClassName="currDpDown"
                    >                  
                    </Select>
                </div>
                }
                {localStorage.getItem("SIGNINRESPONSE") === null ? (                  
                  <span className="signInFocus" onClick={handleShowModal} title={context.translation && context.translation.signin}>
                  <i className="icon icon-Sign-In blinkTextIcon"></i>
                    {/* Sign In */}
                    {context.translation && context.translation.signin}
                  </span>
                ) : (
                  <>
                    <span
                      to="#"
                      className="signInFocus"
                      onClick={handleMyAccount}
                      title={context.translation && context.translation.profile}
                    >
                      {uploadProfPic !== null ? (
                        uploadProfPic ? (
                          <img className="profileImg" src={uploadProfPic} />
                        ) : (
                          <i className="icon icon-Sign-In"></i>
                        )
                      ) : profileImage ? (
                        <img className="profileImg" src={profileImage} />
                      ) : (
                        <i className="icon icon-Sign-In"></i>
                      )}
                      {/* Profile */}
                      {uploadProfPic || profileImage ? "" : context.translation && context.translation.profile}
                      {/* {context.translation && context.translation.profile} */}
                    </span>
                    {/* <span to="#" className="signInFocus" onClick={logout}>
                      Logout
                    </span> */}
                  </>
                )}
                <AuthenticationPopUp
                  visible={visible}
                  onCancel={handleHideModal}
                  headerSignin={signin}
                />
              </div>
            </Col>
          
          <div className="mobileVisible">
              <div className="humberger">
                <div
                  onClick={showDrawer}
                  className={"menu btn5 " + (showMenu && "active")}
                  data-menu="5"
                  // closeMenus={closeMenu}
                >
                  <div className="icon"></div>
                </div>
              </div>
              <Drawer placement="right" open={open} className="navDrawer">
                <div className="dFlex sideBarHeader">
                    <Title level={4}>{context.translation && context.translation.myacc}</Title>
                    <i className="icon icon-cancel" onClick={onClose}></i>
                </div>
                {localStorage.getItem("SIGNINRESPONSE") === null && (
                <div className="loginWrap">
                  <p>{context.translation && context.translation.getFast}</p>
                  <Button type="primary" className="cntBtn signInSignUpBtn" onClick={handleShowModal}>
                    <span>{context.translation && context.translation.signInSignUp}</span>
                  </Button>
                </div>
                )}
                {localStorage.getItem("SIGNINRESPONSE") !== null && (
                <div className="loginWrap">
                    <div className="loginDetail">
                    {uploadProfPic !== null ? (
                        uploadProfPic ? (
                          <img className="profileImage" src={uploadProfPic} />
                        ) : (
                          <img src={ProfileIcon} alt="profile-pic" className="profileImage" />
                        )
                      ) : profileImage ? (
                        <img className="profileImage" src={profileImage} />
                      ) : (
                        <img src={ProfileIcon} alt="profile-pic" className="profileImage" />
                      )}
                      
                      <div className="profileName">
                        {context.username !== undefined && context.username !== "" ? context.username : ""}   
                      </div>
                      <div className="profileUid">UID : {userUniqId}</div>
                    </div>
                </div>
                )}
                <div className="action-links smallScreenMenu">
                {localStorage.getItem("SIGNINRESPONSE") !== null && (
                  <>
                <Title level={5}>{context.translation && context.translation.myacc}</Title>
                <Link className="mainMenu" to="/my-account/user-profile">
                  <i className="icon icon-Profile"></i>
                  <span className="headerMenu rightArrow">{context.translation && context.translation.myProf}</span>
                </Link>
                <Link className="mainMenu" to="/my-account/add-preferences">
                  <i className="icon icon-Preference"></i>
                  <span className="headerMenu rightArrow">{context.translation && context.translation.addPreference}</span>
                </Link>
                <Link className="mainMenu" to="/my-account/my-trips">
                  <i className="icon icon-Trips"></i>
                  <span className="headerMenu rightArrow">{context.translation && context.translation.mytrips}</span>
                </Link>
                <Link className="mainMenu" to="/my-account/add-travellers">
                  <i className="icon icon-Add-Traveller"></i>
                  <span className="headerMenu rightArrow">{context.translation && context.translation.addTravellers}</span>
                </Link>
                <Link className="mainMenu" to="/my-account/change-password">
                  <i className="icon icon-Change-Password"></i>
                  <span className="headerMenu rightArrow">{context.translation && context.translation.chgPass}</span>
                </Link>
                </>
                )}
                <Title level={5}>{context.translation && context.translation.ftrSupTlt}</Title>
                <div ref={ref}>
                <Link 
                onClick={supportClick}
                className={"mainMenu " + (showSupport && "active")}>
                  <span className="headerMenu"><i className="icon icon-call"></i>24X7 {context.translation && context.translation.ftrSupTlt}</span>
                
                {showSupport && (
                <div className="headerSubMenu s-subMenuPosition">
                  <span className="callSupport"><a href="tel:+974 44484847"><i className="icon icon-Call-Solid"></i><span className="revertDirection">+974 44484847</span></a></span>
                  <span className="mailSupport"><a href="mailto:support@clickntravel.com"><i className="icon icon-Email"></i><span>support@clickntravel.com</span></a></span>
                </div>
                )}
                </Link>
                </div>
  {/* 
              <Link 
                onClick={() => setShowLang(!showLang)}
                className={showLang && "active"}>
                  <span className="headerMenu"><i className="icon icon-globe"></i>English</span>
                </Link>
                {showLang && (
                <div className="headerSubMenu l-subMenuPosition">
                  <span className="englishLang">English</span>
                  <span className="arabicLang">العربية</span>
                </div>
                )} */}

                {/* <Select
          className="basic-single"
          classNamePrefix="select"
          defaultValue={colourOptions[0]}
          name="color"
          options={colourOptions}
        /> */}

              <Title level={5}>{context.translation && context.translation.setting}</Title>
              <div to="#" title="العربية" className="mainMenu">
                  <i className="icon icon-globe"></i>
                    <Select 
                      value={{label : langLabel === "Arabic" ? "English" : "العربية"}} 
                      onChange={(e) => {selectLanguage(e); 
                        setLangLabel(e);}} 
                      options={[
                        { value: "English", label: "English" },
                        { value: "Arabic", label: "العربية" },
                      ]}
                      popupClassName="langDpDown"
                    >                  
                    </Select>
                </div>

                {/* <Link to="#" title="INR" className="mainMenu">
                  <i className="sprite flag-sprite"></i> INR
                </Link> */}
                {props.page===undefined &&
                  <div to="#" title={userCur} className="mainMenu">
                <i className="sprite flag-sprite"></i>
                  <Select 
                      value={userCur} 
                      onChange={selectCurrency} 
                      options={currencyList}
                      popupClassName="currDpDown"
                    >                  
                    </Select>
                </div>
                }
                {/* {localStorage.getItem("SIGNINRESPONSE") === null ? (                  
                  <span className="signInFocus" onClick={handleShowModal} title="Sign In">
                  <i className="icon icon-Sign-In blinkTextIcon"></i>
                    {context.translation && context.translation.signin}
                  </span>
                ) : (
                  <>
                    <span
                      to="#"
                      className="signInFocus"
                      onClick={handleMyAccount}
                      title="Profile"
                    >
                      {uploadProfPic !== null ? (
                        uploadProfPic ? (
                          <img className="profileImg" src={uploadProfPic} />
                        ) : (
                          <i className="icon icon-Sign-In"></i>
                        )
                      ) : profileImage ? (
                        <img className="profileImg" src={profileImage} />
                      ) : (
                        <i className="icon icon-Sign-In"></i>
                      )}
                      {uploadProfPic || profileImage ? "" : context.translation && context.translation.profile}
                    </span>
                  </>
                )} */}
                <AuthenticationPopUp
                  visible={visible}
                  onCancel={handleHideModal}
                  headerSignin={signin}
                />
                <Title level={5}>{context.translation && context.translation.corr}</Title>
                <a className="mainMenu" href="https://www.tawfeeqtravel.com/about-us/" target="_blank">
                  <span className="headerMenu rightArrow">{context.translation && context.translation.ftrCorLnk1}</span>
                </a>
                {/* <Link className="mainMenu">
                  <span className="headerMenu">Careers</span>
                </Link>
                <Link className="mainMenu">
                  <span className="headerMenu">FAQs</span>
                </Link> */}
                <Title level={5}>{context.translation && context.translation.ftrCmTlt}</Title>
                <Link className="mainMenu" to={sessionStorage.getItem("SEL_LAG") === "English"||sessionStorage.getItem("SEL_LAG") === null ? "/terms-conditions":"/terms-conditions-ar"} target="_blank">
                  <span className="headerMenu rightArrow">{context.translation && context.translation.termsNcondi}</span>
                </Link>
                <Link className="mainMenu" to={sessionStorage.getItem("SEL_LAG") === "English"||sessionStorage.getItem("SEL_LAG") === null ? "/privacy-policy":"/privacy-policy-ar"} target="_blank">
                  <span className="headerMenu rightArrow">{context.translation && context.translation.ftrCmLnk2}</span>
                </Link>
                
                
              </div>
              {localStorage.getItem("SIGNINRESPONSE") !== null && (
              <div className="signOutWrap">
                  <Button type="primary" className="cntBtn signOutBtn" onClick={handleLogoutConfirmation}>
                    <span><i className="icon icon-Signout"></i> {context.translation && context.translation.signout}</span>
                  </Button>
                </div>
                )}
                {/* <Col className="smallScreenMenu prod-links">
                  <Link to="tel:+974 44798899">
                    <i className="sprite tel-sprite"></i>+974 44798899
                  </Link>
                  <Link to="#">
                    <i className="sprite globe-sprite"></i>العربية
                  </Link>
                  <Link to="#">
                    <i className="sprite flag-sprite"></i> QAR
                  </Link>
                  <Link
                    to="#"
                    className="signInFocus"
                    onClick={handleShowModal}
                  >
                    <i className="sprite sign-sprite"></i> Sign In
                  </Link>
                </Col> */}
                
              </Drawer>
            </div>
        </Row> 
    </>
  );
}

export default Header;
