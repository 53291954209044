/**

.* @ Author: Srikanth Bandaru
 * @ Create Time: 2023-06-15 09:47:01
 * @ Description: This is the main component for Flight multicity search which includes the multicity container component
 */

import { Alert, Button, Checkbox, Col, Row } from "antd";
import { format } from "date-fns";
import { debounce } from "lodash";
import React, {
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState,
  useContext
} from "react";
import { useNavigate } from "react-router-dom";
import MultiCityContainer from "./MultiCityContainer";
import PredectiveSearch from "./predective";
import { isNotNull } from "../../../utilities/validators";
import { Context } from "../../../../App";
import BrandIcon from "../../../../assets/gif/Forever/White/ICON_WHITE_FOREVER.gif";
import Utilities from "../../../utilities/Utilities";

let airlineMaster = require('../common/masterData/AirlinesMasterData.json');

const FLIGHT_SEARCH_CID = process.env.REACT_APP_FLIGHT_SEARCH_CID;
const FLIGHT_SEARCH_REGION_TYPE = process.env.REACT_APP_FLIGHT_SEARCH_REGION_TYPE;
const FLIGHT_SEARCH_OFFICE_ID = process.env.REACT_APP_FLIGHT_SEARCH_OFFICE_ID;

const initialState = {
  airportsOW1: [],
  airportsOW2: [],
  airportsOW3: [],
  airportsOW4: [],
  airportsOW5: [],
  airportsOW6: [],
  selOrigin: [],
  airportsRT1: [],
  airportsRT2: [],
  airportsRT3: [],
  airportsRT4: [],
  airportsRT5: [],
  airportsRT6: [],
  selDestination: [],
  adt: 1,
  chd: 0,
  inf: 0,
  cls: "Economy",
  paxCount: 1,
  selAir: [],
  tt: 3,
  enableSegTwo: true,
  enableSegThree: true,
  enableSegFour: false,
  enableSegFive: false,
  enableSegSix: false,
  clearob1: false,
  clearob2: false,
  clearob3: false,
  clearob4: false,
  clearob5: false,
  clearob6: false,
  cleardb1: false,
  cleardb2: false,
  cleardb3: false,
  cleardb4: false,
  cleardb5: false,
  cleardb6: false,
  orignDataOne: [],
  destDataOne: [],
  orignDataTwo: [],
  destDataTwo: [],
  orignDataThree: [],
  destDataThree: [],
  orignDataFour: [],
  destDataFour: [],
  orignDataFive: [],
  destDataFive: [],
  orignDataSix: [],
  destDataSix: [],
  dateOne: "",
  dateTwo: "",
  dateThree: "",
  dateFour: "",
  dateFive: "",
  dateSix: "",
  selDate:[],
  ns: false,
};

//=====================================
// Reducer function for userReact Hook
// which will update the state
//=====================================
const reducer = (state, action) => {
  switch (action.type) {
    case "clear":
      return {
        ...state,
        airportsOW1: [],
        airportsOW2: [],
        airportsOW3: [],
        airportsOW4: [],
        airportsOW5: [],
        airportsOW6: [],
        selOrigin: [],
        airportsRT1: [],
        airportsRT2: [],
        airportsRT3: [],
        airportsRT4: [],
        airportsRT5: [],
        airportsRT6: [],
        selDestination: [],
        adt: 1,
        chd: 0,
        inf: 0,
        selDate:[],
      };
    default:
      return { ...state, [action.type]: action.payload };
  }
};

function MultiCitySearch(props) {
  const [context, setContext] = useContext(Context)
  const navigate = useNavigate();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [sourceOne, setSourceOne] = useState(null);
  const [sourceTwo, setSourceTwo] = useState(null);
  const [sourceThree, setSourceThree] = useState(null);
  const [sourceFour, setSourceFour] = useState(null);
  const [sourceFive, setSourceFive] = useState(null);
  const [sourceSix, setSourceSix] = useState(null);
  const [destinationOne, setDestinationOne] = useState(null);
  const [destinationTwo, setDestinationTwo] = useState(null);
  const [destinationThree, setDestinationThree] = useState(null);
  const [destinationFour, setDestinationFour] = useState(null);
  const [destinationFive, setDestinationFive] = useState(null);
  const [destinationSix, setDestinationSix] = useState(null);
  const [depDateOne, setDepDateOne] = useState(null);
  const [depDateTwo, setDepDateTwo] = useState(null);
  const [depDateThree, setDepDateThree] = useState(null);
  const [depDateFour, setDepDateFour] = useState(null);
  const [depDateFive, setDepDateFive] = useState(null);
  const [depDateSix, setDepDateSix] = useState(null);
  const [depDateOneFocus, setDepDateOneFocus] = useState(false);
  const [depDateTwoFocus, setDepDateTwoFocus] = useState(false);
  const [depDateThreeFocus, setDepDateThreeFocus] = useState(false);
  const [depDateFourFocus, setDepDateFourFocus] = useState(false);
  const [depDateFiveFocus, setDepDateFiveFocus] = useState(false);
  const [depDateSixFocus, setDepDateSixFocus] = useState(false);
  const [sourceOneEnable, setSourceOneEnable] = useState(false);
  const [sourceTwoEnable, setSourceTwoEnable] = useState(false);
  const [sourceThreeEnable, setSourceThreeEnable] = useState(false);
  const [sourceFourEnable, setSourceFourEnable] = useState(false);
  const [sourceFiveEnable, setSourceFiveEnable] = useState(false);
  const [sourceSixEnable, setSourceSixEnable] = useState(false);
  const [destinationOneEnable, setDestinationOneEnable] = useState(false);
  const [destinationTwoEnable, setDestinationTwoEnable] = useState(false);
  const [destinationThreeEnable, setDestinationThreeEnable] = useState(false);
  const [destinationFourEnable, setDestinationFourEnable] = useState(false);
  const [destinationFiveEnable, setDestinationFiveEnable] = useState(false);
  const [destinationSixEnable, setDestinationSixEnable] = useState(false);
  const [sourceOneClearEnable, setSourceOneClearEnable] = useState(false);
  const [sourceTwoClearEnable, setSourceTwoClearEnable] = useState(false);
  const [sourceThreeClearEnable, setSourceThreeClearEnable] = useState(false);
  const [sourceFourClearEnable, setSourceFourClearEnable] = useState(false);
  const [sourceFiveClearEnable, setSourceFiveClearEnable] = useState(false);
  const [sourceSixClearEnable, setSourceSixClearEnable] = useState(false);
  const [destinationOneClearEnable, setDestinationOneClearEnable] =
    useState(false);
  const [destinationTwoClearEnable, setDestinationTwoClearEnable] =
    useState(false);
  const [destinationThreeClearEnable, setDestinationThreeClearEnable] =
    useState(false);
  const [destinationFourClearEnable, setDestinationFourClearEnable] =
    useState(false);
  const [destinationFiveClearEnable, setDestinationFiveClearEnable] =
    useState(false);
  const [destinationSixClearEnable, setDestinationSixClearEnable] =
    useState(false);

  const [validFromOne, setValidFromOne] = useState();
  const [validFromTwo, setValidFromTwo] = useState();
  const [validFromThree, setValidFromThree] = useState();
  const [validFromFour, setValidFromFour] = useState();
  const [validFromFive, setValidFromFive] = useState();
  const [validFromSix, setValidFromSix] = useState();
  const [validToOne, setValidToOne] = useState();
  const [validToTwo, setValidToTwo] = useState();
  const [validToThree, setValidToThree] = useState();
  const [validToFour, setValidToFour] = useState();
  const [validToFive, setValidToFive] = useState();
  const [validToSix, setValidToSix] = useState();
  const [validFromDateOne, setValidFromDateOne] = useState();
  const [validFromDateTwo, setValidFromDateTwo] = useState();
  const [validFromDateThree, setValidFromDateThree] = useState();
  const [validFromDateFour, setValidFromDateFour] = useState();
  const [validFromDateFive, setValidFromDateFive] = useState();
  const [validFromDateSix, setValidFromDateSix] = useState();

  const [isValidOne, setIsValidOne] = useState(false);
  const [isValidTwo, setIsValidTwo] = useState(false);
  const [isValidThree, setIsValidThree] = useState(false);
  const [isValidFour, setIsValidFour] = useState(false);
  const [isValidFive, setIsValidFive] = useState(false);
  const [isValidSix, setIsValidSix] = useState(false);

  const [isValid, setIsValid] = useState(false);
  const [isLoad, setIsLoad] = useState(false);
  const [modifySearch, setModifySearch] = useState(
    props && props.modify ? props.modify : false
  );

  const [showMultiTextPanel,setShowMultiTextPanel] = useState(false);
  const [showMultiPanel,setShowMultiPanel] = useState(false);
  const [showMultiSearchPanel,setShowMultiSearchPanel] = useState(false);

  const [srcOnePredectiveLoading,setSrcOnePredectiveLoading] = useState(false);
  const [srcOneLoaded,setSrcOneLoaded] = useState(false);
  const [srcTwoPredectiveLoading,setSrcTwoPredectiveLoading] = useState(false);
  const [srcTwoLoaded,setSrcTwoLoaded] = useState(false);
  const [srcThreePredectiveLoading,setSrcThreePredectiveLoading] = useState(false);
  const [srcThreeLoaded,setSrcThreeLoaded] = useState(false);
  const [srcFourPredectiveLoading,setSrcFourPredectiveLoading] = useState(false);
  const [srcFourLoaded,setSrcFourLoaded] = useState(false);
  const [srcFivePredectiveLoading,setSrcFivePredectiveLoading] = useState(false);
  const [srcFiveLoaded,setSrcFiveLoaded] = useState(false);
  const [srcSixPredectiveLoading,setSrcSixPredectiveLoading] = useState(false);
  const [srcSixLoaded,setSrcSixLoaded] = useState(false);

  const [destOnePredectiveLoading,setDestOnePredectiveLoading] = useState(false);
  const [destOneLoaded,setDestOneLoaded] = useState(false);
  const [destTwoPredectiveLoading,setDestTwoPredectiveLoading] = useState(false);
  const [destTwoLoaded,setDestTwoLoaded] = useState(false);
  const [destThreePredectiveLoading,setDestThreePredectiveLoading] = useState(false);
  const [destThreeLoaded,setDestThreeLoaded] = useState(false);
  const [destFourPredectiveLoading,setDestFourPredectiveLoading] = useState(false);
  const [destFourLoaded,setDestFourLoaded] = useState(false);
  const [destFivePredectiveLoading,setDestFivePredectiveLoading] = useState(false);
  const [destFiveLoaded,setDestFiveLoaded] = useState(false);
  const [destSixPredectiveLoading,setDestSixPredectiveLoading] = useState(false);
  const [destSixLoaded,setDestSixLoaded] = useState(false);

  const inputRef = useRef(null);
  const numbers = [1, 2, 3, 4, 5, 6];
  const current = new Date();

  const [errorOne, setErrorOne] = useState(false);
  const [errorTwo, setErrorTwo] = useState(false);
  const [errorThree, setErrorThree] = useState(false);
  const [errorFour, setErrorFour] = useState(false);
  const [errorFive, setErrorFive] = useState(false);
  const [errorSix, setErrorSix] = useState(false);

  const [errorMessageOne,setErrorMessageOne] = useState('');
  const [errorMessageTwo,setErrorMessageTwo] = useState('');
  const [errorMessageThree,setErrorMessageThree] = useState('');
  const [errorMessageFour,setErrorMessageFour] = useState('');
  const [errorMessageFive,setErrorMessageFive] = useState('');
  const [errorMessageSix,setErrorMessageSix] = useState('');

  state.tt = props.tt;

  useEffect(() => {
    if (props.data && Object.keys(props.data).length !== 0) {
      setValidFromOne(true);
      setValidFromTwo(true);
      setValidFromThree(true);
      setValidFromFour(true);
      setValidFromFive(true);
      setValidFromSix(true);

      setValidToOne(true);
      setValidToTwo(true);
      setValidToThree(true);
      setValidToFour(true);
      setValidToFive(true);
      setValidToSix(true);

      setValidFromDateOne(true);
      setValidFromDateTwo(true);
      setValidFromDateThree(true);
      setValidFromDateFour(true);
      setValidFromDateFive(true);
      setValidFromDateSix(true);

      //Populating the origins
      if (props.data) {
        if (props.data.orignDataOne) {
          dispatch({
            type: "orignDataOne",
            payload: props.data.orignDataOne,
          });
          setSourceOne(props.data.orignDataOne.display);
          setSourceOneClearEnable(true);
        }
        if (props.data.orignDataTwo) {
          dispatch({
            type: "orignDataTwo",
            payload: props.data.orignDataTwo,
          });
          setSourceTwo(props.data.orignDataTwo.display);
          setSourceTwoClearEnable(true);
        }
        if (props.data.orignDataThree) {
          dispatch({
            type: "orignDataThree",
            payload: props.data.orignDataThree,
          });
          setSourceThree(props.data.orignDataThree.display);
          setSourceThreeClearEnable(true);
        }
        if (props.data.orignDataFour) {
          dispatch({
            type: "orignDataFour",
            payload: props.data.orignDataFour,
          });
          setSourceFour(props.data.orignDataFour.display);
          setSourceFourClearEnable(true);
        }
        if (props.data.orignDataFive) {
          dispatch({
            type: "orignDataFive",
            payload: props.data.orignDataFive,
          });
          setSourceFive(props.data.orignDataFive.display);
          setSourceFiveClearEnable(true);
        }
        if (props.data.orignDataSix) {
          dispatch({
            type: "orignDataSix",
            payload: props.data.orignDataSix,
          });
          setSourceSix(props.data.orignDataSix.display);
          setSourceSixClearEnable(true);
        }

        //Populating the destinations
        if (props.data.destDataOne) {
          dispatch({
            type: "destDataOne",
            payload: props.data.destDataOne,
          });
          setDestinationOne(props.data.destDataOne.display);
          setDestinationOneClearEnable(true);
        }
        if (props.data.destDataTwo) {
          dispatch({
            type: "destDataTwo",
            payload: props.data.destDataTwo,
          });
          setDestinationTwo(props.data.destDataTwo.display);
          setDestinationTwoClearEnable(true);
        }
        if (props.data.destDataThree) {
          dispatch({
            type: "destDataThree",
            payload: props.data.destDataThree,
          });
          setDestinationThree(props.data.destDataThree.display);
          setDestinationThreeClearEnable(true);
        }
        if (props.data.destDataFour) {
          dispatch({
            type: "destDataFour",
            payload: props.data.destDataFour,
          });
          setDestinationFour(props.data.destDataFour.display);
          setDestinationFourClearEnable(true);
        }
        if (props.data.destDataFive) {
          dispatch({
            type: "destDataFive",
            payload: props.data.destDataFive,
          });
          setDestinationFive(props.data.destDataFive.display);
          setDestinationFiveClearEnable(true);
        }
        if (props.data.destDataSix) {
          dispatch({
            type: "destDataSix",
            payload: props.data.destDataSix,
          });
          setDestinationSix(props.data.destDataSix.display);
          setDestinationSixClearEnable(true);
        }

        //populating the dates
        if (props.data.dateOne) {
          setDepDateOne(props.data.dateOne);
          dispatch({ type: "dateOne", payload: props.data.dateOne });
        }
        if (props.data.dateTwo) {
          setDepDateTwo(props.data.dateTwo);
          dispatch({ type: "dateTwo", payload: props.data.dateTwo });
        }
        if (props.data.dateThree) {
          setDepDateThree(props.data.dateThree);
          dispatch({ type: "dateThree", payload: props.data.dateThree });
        }
        if (props.data.dateFour) {
          setDepDateFour(props.data.dateFour);
          dispatch({ type: "dateFour", payload: props.data.dateFour });
        }
        if (props.data.dateFive) {
          setDepDateFive(props.data.dateFive);
          dispatch({ type: "dateFive", payload: props.data.dateFive });
        }
        if (props.data.dateSix) {
          setDepDateSix(props.data.dateSix);
          dispatch({ type: "dateSix", payload: props.data.dateSix });
        }
      }
      if (props.data.cls instanceof Object) {
        dispatch({ type: "cls", payload: props.data.cls[0].label })
      } else {
          dispatch({ type: "cls", payload: props.data.cls })
      }
      dispatch({ type: "ns", payload: props.data.ns });
    }
  }, []);

  useEffect(() => {
    dispatch({ type: "adt", payload: props.sendCount[0] })
    dispatch({ type: "chd", payload: props.sendCount[1] })
    dispatch({ type: "inf", payload: props.sendCount[2] })
    dispatch({ type: "cls", payload: [{ "value": props.sendCount[3], "label": props.sendCount[3] }] })
  }, [props.sendCount]);

  //For class type loading
  const setClsType = () => {
    var cl = "E";
    if(state.cls && state.cls.length>0)
    if (state.cls[0].value === "Economy") {
      cl = "E"
    } else if (state.cls[0].value === "Business") {
      cl = "B"
    } else if (state.cls[0].value === "First class") {
      cl = "F"
    } else if (state.cls[0].value == "Premium") {
      cl = "P"
    }
    return cl;
  }

  const debouncedSave = useCallback(
    debounce((obj) => predectiveCall(obj.textValue, obj.type), 500),
    [] // will be created only once initially
  );

  //For handling the changes in the destination
  const handleDestination = (input, d) => {
    if (d == "d1") {
      if (input.length > 0) {
        dispatch({ type: "cleardb1", payload: true });
      } else {
        dispatch({ type: "cleardb1", payload: false });
      }
      predectiveCall(input, "airportsRT1");
    } else if (d == "d2") {
      if (input.length > 0) {
        dispatch({ type: "cleardb2", payload: true });
      } else {
        dispatch({ type: "cleardb2", payload: false });
      }
      predectiveCall(input, "airportsRT2");
    } else if (d == "d3") {
      if (input.length > 0) {
        dispatch({ type: "cleardb3", payload: true });
      } else {
        dispatch({ type: "cleardb3", payload: false });
      }
      predectiveCall(input, "airportsRT3");
    } else if (d == "d4") {
      if (input.length > 0) {
        dispatch({ type: "cleardb4", payload: true });
      } else {
        dispatch({ type: "cleardb4", payload: false });
      }
      predectiveCall(input, "airportsRT4");
    } else if (d == "d5") {
      if (input.length > 0) {
        dispatch({ type: "cleardb5", payload: true });
      } else {
        dispatch({ type: "cleardb5", payload: false });
      }
      predectiveCall(input, "airportsRT5");
    } else if (d == "d6") {
      if (input.length > 0) {
        dispatch({ type: "cleardb6", payload: true });
      } else {
        dispatch({ type: "cleardb6", payload: false });
      }
      predectiveCall(input, "airportsRT6");
    }
  };

  //For predective call in the source and destinations
  const predectiveCall = async (input, route) => {
    try {
      if (input.length > 0) {
        const payload = {
          searchCriteria: input,
          lang: context.selectLang
        };
        let tokenKey = Utilities.generateAPIToken(payload);
        await PredectiveSearch.retreieveSearch(payload,tokenKey).then((data) => {
          const options = [];
          data && data.data && !data.data.err && data.data.map((val) => {
            options.push({
              country: `${val.countryCode}`,
              city: `${val.cityName}`,
              airport: `${val.airportCode}`,
              display: `${val.cityName}(${val.airportCode}), ${val.countryName}`,
              label: `${val.airportName}, ${val.countryName}`,
              value: `${val.airportName}, ${val.cityName} , ${val.countryName} , ${val.airportCode}`,
            });
          });
          dispatch({ type: route, payload: data.data !== "" ? options : [] });
        }).catch(function (error) {
          console.error("Error in Airports Predective Search" + error);      
        });
      } else {
        dispatch({ type: route, payload: [] });
      }      
    } catch (error) {
      console.error("Error in Airports Predective Search" + error);        
    }
  };

  //For handling the changes in the source
  const onSourceChange = (data, o) => {
    if (o == "o1") {
      setSourceOne(data);
      setSourceOneEnable(true);
      setSourceOneClearEnable(true);
      setSrcOnePredectiveLoading(true);
      setSrcOneLoaded(false);
      if(!isNotNull(data)){
        setSourceOneClearEnable(false);
      }
    } else if (o == "o2") {
      setSourceTwo(data);
      setSourceTwoEnable(true);
      setSourceTwoClearEnable(true);
      setSrcTwoPredectiveLoading(true);
      setSrcTwoLoaded(false);
      if(!isNotNull(data)){
        setSourceTwoClearEnable(false);
      }
    } else if (o == "o3") {
      setSourceThree(data);
      setSourceThreeEnable(true);
      setSourceThreeClearEnable(true);
      setSrcThreePredectiveLoading(true);
      setSrcThreeLoaded(false);
      if(!isNotNull(data)){
        setSourceThreeClearEnable(false);
      }
    } else if (o == "o4") {
      setSourceFour(data);
      setSourceFourEnable(true);
      setSourceFourClearEnable(true);
      setSrcFourPredectiveLoading(true);
      setSrcFourLoaded(false);
      if(!isNotNull(data)){
        setSourceFourClearEnable(false);
      }
    } else if (o == "o5") {
      setSourceFive(data);
      setSourceFiveEnable(true);
      setSourceFiveClearEnable(true);
      setSrcFivePredectiveLoading(true);
      setSrcFiveLoaded(false);
      if(!isNotNull(data)){
        setSourceFiveClearEnable(false);
      }
    } else if (o == "o6") {
      setSourceSix(data);
      setSourceSixEnable(true);
      setSourceSixClearEnable(true);
      setSrcSixPredectiveLoading(true);
      setSrcSixLoaded(false);
      if(!isNotNull(data)){
        setSourceSixClearEnable(false);
      }
    }
    handleOrigin(data, o);
    setDepDateOneFocus(false);
    setDepDateTwoFocus(false);
    setDepDateThreeFocus(false);
    setDepDateFourFocus(false);
    setDepDateFiveFocus(false);
    setDepDateSixFocus(false);
  };

  //For handling the changes in the source
  const onSrcChange = useCallback((data, o) => {
    data = data.target.value;
    onSourceChange(data, o);
  }, []);

  //For handling the changes in the destination
  const onDestinationChange = (data, d) => {
    if (d == "d1") {
      setDestinationOne(data);
      setSourceTwo(data);
      setDestinationOneEnable(true);
      setDestinationOneClearEnable(true);
      setSourceTwoClearEnable(true);
      setDestOnePredectiveLoading(true);
      setDestOneLoaded(false);
      if(!isNotNull(data)){
        setDestinationOneClearEnable(false);
      }
    } else if (d == "d2") {
      setDestinationTwo(data);
      setSourceThree(data);
      setDestinationTwoEnable(true);
      setDestinationTwoClearEnable(true);
      setSourceThreeClearEnable(true);
      setDestTwoPredectiveLoading(true);
      setDestTwoLoaded(false);
      if(!isNotNull(data)){
        setDestinationTwoClearEnable(false);
      }
    } else if (d == "d3") {
      setDestinationThree(data);
      setSourceFour(data);
      setDestinationThreeEnable(true);
      setDestinationThreeClearEnable(true);
      setSourceFourClearEnable(true);
      setDestThreePredectiveLoading(true);
      setDestThreeLoaded(false);
      if(!isNotNull(data)){
        setDestinationThreeClearEnable(false);
      }
    } else if (d == "d4") {
      setDestinationFour(data);
      setSourceFive(data);
      setDestinationFourEnable(true);
      setDestinationFourClearEnable(true);
      setSourceFiveClearEnable(true);
      setDestFourPredectiveLoading(true);
      setDestFourLoaded(false);
      if(!isNotNull(data)){
        setDestinationFourClearEnable(false);
      }
    } else if (d == "d5") {
      setDestinationFive(data);
      setSourceSix(data);
      setDestinationFiveEnable(true);
      setDestinationFiveClearEnable(true);
      setSourceSixClearEnable(true);
      setDestFivePredectiveLoading(true);
      setDestFiveLoaded(false);
      if(!isNotNull(data)){
        setDestinationFiveClearEnable(false);
      }
    } else if (d == "d6") {
      setDestinationSix(data);
      setDestinationSixEnable(true);
      setDestinationSixClearEnable(true);
      setDestSixPredectiveLoading(true);
      setDestSixLoaded(false);
      if(!isNotNull(data)){
        setDestinationSixClearEnable(false);
      }
    }
    handleDestination(data, d);
    setDepDateOneFocus(false);
    setDepDateTwoFocus(false);
    setDepDateThreeFocus(false);
    setDepDateFourFocus(false);
    setDepDateFiveFocus(false);
    setDepDateSixFocus(false);
  };

  //For handling the changes in the destination
  const onDestChange = useCallback((data, d) => {
    data = data.target.value;
    onDestinationChange(data, d);
  }, []);

  //For handling the selection in the source
  const onSourceSelect = (data, obj, type) => {
    if (type === "o1") {
      dispatch({ type: "orignDataOne", payload: obj });
      setSourceOneEnable(false);
      setSourceOne(obj.display);
      setSourceOneClearEnable(true);
      obj && obj.display ? setValidFromOne(true) : setValidFromOne(false);
    } else if (type === "o2") {
      dispatch({ type: "orignDataTwo", payload: obj });
      setSourceTwoEnable(false);
      setSourceTwo(obj.display);
      setSourceTwoClearEnable(true);
      obj && obj.display ? setValidFromTwo(true) : setValidFromTwo(false);
    } else if (type === "o3") {
      dispatch({ type: "orignDataThree", payload: obj });
      setSourceThreeEnable(false);
      setSourceThree(obj.display);
      setSourceThreeClearEnable(true);
      obj && obj.display ? setValidFromThree(true) : setValidFromThree(false);
    } else if (type === "o4") {
      dispatch({ type: "orignDataFour", payload: obj });
      setSourceFourEnable(false);
      setSourceFour(obj.display);
      setSourceFourClearEnable(true);
      obj && obj.display ? setValidFromFour(true) : setValidFromFour(false);
    } else if (type === "o5") {
      dispatch({ type: "orignDataFive", payload: obj });
      setSourceFiveEnable(false);
      setSourceFive(obj.display);
      setSourceFiveClearEnable(true);
      obj && obj.display ? setValidFromFive(true) : setValidFromFive(false);
    } else if (type === "o6") {
      dispatch({ type: "orignDataSix", payload: obj });
      setSourceSixEnable(false);
      setSourceSix(obj.display);
      setSourceSixClearEnable(true);
      obj && obj.display ? setValidFromSix(true) : setValidFromSix(false);
    }
    setDepDateOneFocus(false);
    setDepDateTwoFocus(false);
    setDepDateThreeFocus(false);
    setDepDateFourFocus(false);
    setDepDateFiveFocus(false);
    setDepDateSixFocus(false);
  };

  useEffect(() => {
    if(sourceOne && destinationOne && sourceOne === destinationOne){
      setErrorMessageOne(context.translation && context.translation.sNdone);
      setErrorOne(true);
    } else if(!sourceOne || !destinationOne){
      if(sourceOne !== destinationOne){
      setErrorOne(false);
      }
    }
  }, [sourceOne,destinationOne])

  useEffect(() => {
    if(sourceTwo && destinationTwo && sourceTwo === destinationTwo){
      setErrorMessageTwo(context.translation && context.translation.sNdtwo);
      setErrorTwo(true);
    } else if(!sourceTwo || !destinationTwo){
      if(sourceTwo !== destinationTwo){
        setErrorTwo(false);
      }
    }
  }, [sourceTwo,destinationTwo])

  useEffect(() => {
    if(sourceThree && destinationThree && sourceThree === destinationThree){
      setErrorMessageThree(context.translation && context.translation.sNdthree);
      setErrorThree(true);
    } else if(!sourceThree || !destinationThree){
      if(sourceThree !== destinationThree){
        setErrorThree(false);
      }
    }
  }, [sourceThree,destinationThree])

  useEffect(() => {
    if(sourceFour && destinationFour && sourceFour === destinationFour){
      setErrorMessageFour(context.translation && context.translation.sNdfour);
      setErrorFour(true);
    } else if(!sourceFour || !destinationFour){
      if(sourceFour !== destinationFour){
        setErrorFour(false);
      }
    }
  }, [sourceFour,destinationFour])

  useEffect(() => {
    if(sourceFive && destinationFive && sourceFive === destinationFive){
      setErrorMessageFive(context.translation && context.translation.sNdfive);
      setErrorFive(true);
    } else if(!sourceFive || !destinationFive){
      if(sourceFive !== destinationFive){
        setErrorFive(false);
      }
    }
  }, [sourceFive,destinationFive])

  useEffect(() => {
    if(sourceSix && destinationSix && sourceSix === destinationSix){
      setErrorMessageSix(context.translation && context.translation.sNdsix);
      setErrorSix(true);
    } else if(!sourceSix || !destinationSix){
      if(sourceSix !== destinationSix){
        setErrorSix(false);
      }
    }
  }, [sourceSix,destinationSix])
  
  //For handling the selection in the source
  const onSrcSelect = useCallback((data, obj, type) => {
    onSourceSelect(data, obj, type);
  }, []);

  //For handling the selection in the destination
  const onDestinationSelect = (data, obj, type) => {
    if (type === "d1") {
      dispatch({ type: "destDataOne", payload: obj });
      dispatch({ type: "orignDataTwo", payload: obj });
      setDepDateOneFocus(true);
      setDestinationOneEnable(false);
      setDestinationOne(obj.display);
      setSourceTwo(obj.display);
      setDestinationOneClearEnable(true);
      setSourceTwoClearEnable(true);
      obj && obj.display ? setValidToOne(true) : setValidToOne(false);
      obj && obj.display ? setValidFromTwo(true) : setValidFromTwo(false);
    } else if (type === "d2") {
      dispatch({ type: "destDataTwo", payload: obj });
      dispatch({ type: "orignDataThree", payload: obj });
      setDepDateTwoFocus(true);
      setDestinationTwoEnable(false);
      setDestinationTwo(obj.display);
      setSourceThree(obj.display);
      setDestinationTwoClearEnable(true);
      setSourceThreeClearEnable(true);
      obj && obj.display ? setValidToTwo(true) : setValidToTwo(false);
      obj && obj.display ? setValidFromThree(true) : setValidFromThree(false);
    } else if (type === "d3") {
      dispatch({ type: "destDataThree", payload: obj });
      dispatch({ type: "orignDataFour", payload: obj });
      setDepDateThreeFocus(true);
      setDestinationThreeEnable(false);
      setDestinationThree(obj.display);
      setSourceFour(obj.display);
      setDestinationThreeClearEnable(true);
      setSourceFourClearEnable(true);
      obj && obj.display ? setValidToThree(true) : setValidToThree(false);
      obj && obj.display ? setValidFromFour(true) : setValidFromFour(false);
    } else if (type === "d4") {
      dispatch({ type: "destDataFour", payload: obj });
      dispatch({ type: "orignDataFive", payload: obj });
      setDepDateFourFocus(true);
      setDestinationFourEnable(false);
      setDestinationFour(obj.display);
      setSourceFive(obj.display);
      setDestinationFourClearEnable(true);
      setSourceFiveClearEnable(true);
      obj && obj.display ? setValidToFour(true) : setValidToFour(false);
      obj && obj.display ? setValidFromFive(true) : setValidFromFive(false);
    } else if (type === "d5") {
      dispatch({ type: "destDataFive", payload: obj });
      dispatch({ type: "orignDataSix", payload: obj });
      setDepDateFiveFocus(true);
      setDestinationFiveEnable(false);
      setDestinationFive(obj.display);
      setSourceSix(obj.display);
      setDestinationFiveClearEnable(true);
      setSourceSixClearEnable(true);
      obj && obj.display ? setValidToFive(true) : setValidToFive(false);
      obj && obj.display ? setValidFromSix(true) : setValidFromSix(false);
    } else if (type === "d6") {
      dispatch({ type: "destDataSix", payload: obj });
      setDepDateSixFocus(true);
      setDestinationSixEnable(false);
      setDestinationSix(obj.display);
      setDestinationSixClearEnable(true);
      obj && obj.display ? setValidToSix(true) : setValidToSix(false);
    }
    setDepDateOneFocus(false);
    setDepDateTwoFocus(false);
    setDepDateThreeFocus(false);
    setDepDateFourFocus(false);
    setDepDateFiveFocus(false);
    setDepDateSixFocus(false);
  };

  //For handling the selection in the destination
  const onDestSelect = useCallback(
    (data, obj, type) => {
      onDestinationSelect(data, obj, type);
    },
    [state]
  );

  //For handling the changes in the date
  const onDepartureDateChange = (data, type) => {
    if (type === "d1") {
      setDepDateOneFocus(false);
      setDepDateOne(new Date(data.$d));
      dispatch({
        type: "dateOne",
        payload: data ? format(new Date(data.$d), "yyyy-MM-dd") : null,
      });
      data ? setValidFromDateOne(true) : setValidFromDateOne(false);
      state.selDate.push(data);
    } else if (type === "d2") {
      setDepDateTwoFocus(false);
      setDepDateTwo(new Date(data.$d));
      dispatch({
        type: "dateTwo",
        payload: data ? format(new Date(data.$d), "yyyy-MM-dd") : null,
      });
      data ? setValidFromDateTwo(true) : setValidFromDateTwo(false);
      state.selDate.push(data);
    } else if (type === "d3") {
      setDepDateThreeFocus(false);
      setDepDateThree(new Date(data.$d));
      dispatch({
        type: "dateThree",
        payload: data ? format(new Date(data.$d), "yyyy-MM-dd") : null,
      });
      data ? setValidFromDateThree(true) : setValidFromDateThree(false);
    } else if (type === "d4") {
      setDepDateFourFocus(false);
      setDepDateFour(new Date(data.$d));
      dispatch({
        type: "dateFour",
        payload: data ? format(new Date(data.$d), "yyyy-MM-dd") : null,
      });
      data ? setValidFromDateFour(true) : setValidFromDateFour(false);
    } else if (type === "d5") {
      setDepDateFiveFocus(false);
      setDepDateFive(new Date(data.$d));
      dispatch({
        type: "dateFive",
        payload: data ? format(new Date(data.$d), "yyyy-MM-dd") : null,
      });
      data ? setValidFromDateFive(true) : setValidFromDateFive(false);
    } else if (type === "d6") {
      setDepDateSixFocus(false);
      setDepDateSix(new Date(data.$d));
      dispatch({
        type: "dateSix",
        payload: data ? format(new Date(data.$d), "yyyy-MM-dd") : null,
      });
      data ? setValidFromDateSix(true) : setValidFromDateSix(false);
    }
  };

  //For handling the changes in the date
  const onDepDateChange = useCallback((data, type) => {
    if (data) {
      onDepartureDateChange(data, type);
    } else {
      if (type === "d1") {
        setValidFromDateOne(false);
      } else if (type === "d2") {
        setValidFromDateTwo(false);
      } else if (type === "d3") {
        setValidFromDateThree(false);
      } else if (type === "d4") {
        setValidFromDateFour(false);
      } else if (type === "d5") {
        setValidFromDateFive(false);
      } else if (type === "d6") {
        setValidFromDateSix(false);
      }
    }
  }, []);

  //For handling the date focus
  const dateFocus = useCallback((data, type) => {
    if (type === "d1") {
      setDepDateOneFocus(data);
    } else if (type === "d2") {
      setDepDateTwoFocus(data);
    } else if (type === "d3") {
      setDepDateThreeFocus(data);
    } else if (type === "d4") {
      setDepDateFourFocus(data);
    } else if (type === "d5") {
      setDepDateFiveFocus(data);
    } else if (type === "d6") {
      setDepDateSixFocus(data);
    }
    setSourceOneEnable(false);
    setSourceTwoEnable(false);
    setSourceThreeEnable(false);
    setSourceFourEnable(false);
    setSourceFiveEnable(false);
    setSourceSixEnable(false);
    setDestinationOneEnable(false);
    setDestinationTwoEnable(false);
    setDestinationThreeEnable(false);
    setDestinationFourEnable(false);
    setDestinationFiveEnable(false);
    setDestinationSixEnable(false);
  }, []);

  //=====================================
  // This function will get the Pax count and Total count for Add traveller
  //=====================================
  const paxCount = useCallback((data) => {
    dispatch({ type: "adt", payload: data[0] });
    dispatch({ type: "chd", payload: data[1] });
    dispatch({ type: "inf", payload: data[2] });
    dispatch({ type: "paxCount", payload: data[3] });
  }, []);

  //=====================================
  // This function will get Class
  //=====================================
  const clsType = useCallback((type) => {
    dispatch({ type: "cls", payload: type });
  }, []);

  //=================================================
  // This function will close Travel details pop up
  //=================================================
  const closeTravel = (type) => {
    if (type === "coseTrv") {
      //setAddTraveller(false);
    }
  };

  //For handling the changes in the trip types
  const handleTripType = (data) => {
    state.tt = data;
    dispatch({ type: "tt", payload: data });
  };

  //For handling the add sectors action in the multi city
  const handleAddSector = () => {
    if (!state.enableSegThree) {
      dispatch({ type: "enableSegThree", payload: true });
    } else {
      dispatch({ type: "enableSegFour", payload: true });
    }

    if (state.enableSegFour) {
      dispatch({ type: "enableSegFive", payload: true });
      dispatch({ type: "enableFive", payload: true });
    }

    if (state.enableSegFour && state.enableSegFive && !state.enableSegSix) {
      dispatch({ type: "enableSegSix", payload: true });
      dispatch({ type: "enableSix", payload: true });
    }
  };

  //For handling the delete sector in the multicity panel
  const deleteSector = useCallback(() => {
    handleRemoveSector();
  }, [state]);

  //For handling the delete sector in the multicity panel
  const handleRemoveSector = () => {
    if (state.enableSegSix) {
      dispatch({ type: "enableSegSix", payload: false });
      dispatch({ type: "orignDataSix", payload: [] });
      dispatch({ type: "destDataSix", payload: [] });
      dispatch({ type: "enableSix", payload: false });
      dispatch({ type: "acty6", payload: undefined });
      dispatch({ type: "dcty6", payload: undefined });
      dispatch({ type: "arr6", payload: undefined });
      dispatch({ type: "dep6", payload: undefined });
      setSourceSix("");
      setDestinationSix("");
    }
    if (!state.enableSegSix && state.enableSegFive) {
      dispatch({ type: "enbaleFive", payload: true });
      dispatch({ type: "enableSegFive", payload: false });
      dispatch({ type: "orignDataFive", payload: [] });
      dispatch({ type: "destDataFive", payload: [] });
      dispatch({ type: "acty5", payload: undefined });
      dispatch({ type: "dcty5", payload: undefined });
      dispatch({ type: "arr5", payload: undefined });
      dispatch({ type: "dep5", payload: undefined });
      setSourceFive("");
      setDestinationFive("");
    }
    if (!state.enableSegSix && !state.enableSegFive && state.enableSegFour) {
      dispatch({ type: "enableSegFour", payload: false });
      dispatch({ type: "orignDataFour", payload: [] });
      dispatch({ type: "destDataFour", payload: [] });
      dispatch({ type: "acty4", payload: undefined });
      dispatch({ type: "dcty4", payload: undefined });
      dispatch({ type: "arr4", payload: undefined });
      dispatch({ type: "dep4", payload: undefined });
      setSourceFour("");
      setDestinationFour("");
    }
  };

  //For handling the origin related actions
  const handleOrigin = (input, o) => {
    if (o == "o1") {
      if (input.length > 0) {
        dispatch({ type: "clearob1", payload: true });
      } else {
        dispatch({ type: "clearob1", payload: false });
      }
      predectiveCall(input, "airportsOW1");
    } else if (o == "o2") {
      if (input.length > 0) {
        dispatch({ type: "clearob2", payload: true });
      } else {
        dispatch({ type: "clearob2", payload: false });
      }
      predectiveCall(input, "airportsOW2");
    } else if (o == "o3") {
      if (input.length > 0) {
        dispatch({ type: "clearob3", payload: true });
      } else {
        dispatch({ type: "clearob3", payload: false });
      }
      predectiveCall(input, "airportsOW3");
    } else if (o == "o4") {
      if (input.length > 0) {
        dispatch({ type: "clearob4", payload: true });
      } else {
        dispatch({ type: "clearob4", payload: false });
      }
      predectiveCall(input, "airportsOW4");
    } else if (o == "o5") {
      if (input.length > 0) {
        dispatch({ type: "clearob5", payload: true });
      } else {
        dispatch({ type: "clearob5", payload: false });
      }
      predectiveCall(input, "airportsOW5");
    } else if (o == "o6") {
      if (input.length > 0) {
        dispatch({ type: "clearob6", payload: true });
      } else {
        dispatch({ type: "clearob6", payload: false });
      }
      predectiveCall(input, "airportsOW6");
    }
  };

  //For handling the clear origin related actions
  const clearSource = (data, o) => {
    if (o == "o1") {
      setSourceOne("");
      setSourceOneEnable(false);
      setSourceOneClearEnable(false);
      dispatch({ type: "orignDataOne", payload: [] });
    } else if (o == "o2") {
      setSourceTwo("");
      setSourceTwoEnable(false);
      setSourceTwoClearEnable(false);
      dispatch({ type: "orignDataTwo", payload: [] });
    } else if (o == "o3") {
      setSourceThree("");
      setSourceThreeEnable(false);
      setSourceThreeClearEnable(false);
      dispatch({ type: "orignDataThree", payload: [] });
    } else if (o == "o4") {
      setSourceFour("");
      setSourceFourEnable(false);
      setSourceFourClearEnable(false);
      dispatch({ type: "orignDataFour", payload: [] });
    } else if (o == "o5") {
      setSourceFive("");
      setSourceFiveEnable(false);
      setSourceFiveClearEnable(false);
      dispatch({ type: "orignDataFive", payload: [] });
    } else if (o == "o6") {
      setSourceSix("");
      setSourceSixEnable(false);
      setSourceSixClearEnable(false);
      dispatch({ type: "orignDataSix", payload: [] });
    }
  };

  //For handling the clear destination related actions
  const clearDestination = (data, d) => {
    if (d == "d1") {
      setDestinationOne("");
      setDestinationOneEnable(false);
      setDestinationOneClearEnable(false);
      dispatch({ type: "destDataOne", payload: [] });
    } else if (d == "d2") {
      setDestinationTwo("");
      setDestinationTwoEnable(false);
      setDestinationTwoClearEnable(false);
      dispatch({ type: "destDataTwo", payload: [] });
    } else if (d == "d3") {
      setDestinationThree("");
      setDestinationThreeEnable(false);
      setDestinationThreeClearEnable(false);
      dispatch({ type: "destDataThree", payload: [] });
    } else if (d == "d4") {
      setDestinationFour("");
      setDestinationFourEnable(false);
      setDestinationFourClearEnable(false);
      dispatch({ type: "destDataFour", payload: [] });
    } else if (d == "d5") {
      setDestinationFive("");
      setDestinationFiveEnable(false);
      setDestinationFiveClearEnable(false);
      dispatch({ type: "destDataFive", payload: [] });
    } else if (d == "d6") {
      setDestinationSix("");
      setDestinationSixEnable(false);
      setDestinationSixClearEnable(false);
      dispatch({ type: "destDataSix", payload: [] });
    }
  };

  useEffect(() => {
    setDepDateOneFocus(false);
    setDepDateTwoFocus(false);
    setDepDateThreeFocus(false);
    setDepDateFourFocus(false);
    setDepDateFiveFocus(false);
    setDepDateSixFocus(false);
  }, [props.blur]);

  //For handling the focus related for the dates , trip types and traveller popups
  const handleClickOutsideRangePicker = (event) => {
    if (
      inputRef.current &&
      inputRef.current instanceof HTMLElement &&
      !inputRef.current.contains(event.target)
    ) {
      if (
        event.target.classList.contains("ant-row") ||
        event.target.classList.contains("ant-col") ||
        event.target.classList.contains("container") ||
        event.target.classList.contains("ant-typography") ||
        event.target.classList.contains("flightSearch") ||
        event.target.classList.contains("select-trips") ||
        event.target.classList.contains("searchPanel") ||
        event.target.classList.contains("select-trips") ||
        event.target.classList.contains("multicityBtnWrapper")
      ) {
        setDepDateOneFocus(false);
        setDepDateTwoFocus(false);
        setDepDateThreeFocus(false);
        setDepDateFourFocus(false);
        setDepDateFiveFocus(false);
        setDepDateSixFocus(false);
        props.togglett(false);
        props.toggleTravellers(false);
      }
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutsideRangePicker);
    return () => {
      document.removeEventListener("mousedown", handleClickOutsideRangePicker);
    };
  }, []);
  //One
  useEffect(() => {
    const validate = Boolean(validFromOne && validToOne && validFromDateOne);
    setIsValidOne(validate);

    validFromOne !== undefined && !validFromOne
      ? document.querySelector(".dest-from.o1") &&
      document.querySelector(".dest-from.o1").classList.add("errorBox")
      : document.querySelector(".dest-from.o1") &&
      document.querySelector(".dest-from.o1").classList.remove("errorBox");
    validToOne !== undefined && !validToOne
      ? document.querySelector(".dest-to.d1") &&
      document.querySelector(".dest-to.d1").classList.add("errorBox")
      : document.querySelector(".dest-to.d1") &&
      document.querySelector(".dest-to.d1").classList.remove("errorBox");
    validFromDateOne !== undefined && !validFromDateOne
      ? document.querySelector(".multicityDate.o1") &&
      document.querySelector(".multicityDate.o1").classList.add("errorDate")
      : document.querySelector(".multicityDate.o1") &&
      document.querySelector(".multicityDate.o1").classList.remove("errorDate");
  }, [validFromOne, validToOne, validFromDateOne]);

  //Two
  useEffect(() => {
    const validate = Boolean(validFromTwo && validToTwo && validFromDateTwo);
    setIsValidTwo(validate);

    validFromTwo !== undefined && !validFromTwo
      ? document.querySelector(".dest-from.o2") &&
      document.querySelector(".dest-from.o2").classList.add("errorBox")
      : document.querySelector(".dest-from.o2") &&
      document.querySelector(".dest-from.o2").classList.remove("errorBox");
    validToTwo !== undefined && !validToTwo
      ? document.querySelector(".dest-to.d2") &&
      document.querySelector(".dest-to.d2").classList.add("errorBox")
      : document.querySelector(".dest-to.d2") &&
      document.querySelector(".dest-to.d2").classList.remove("errorBox");
    validFromDateTwo !== undefined && !validFromDateTwo
      ? document.querySelector(".multicityDate.o2") &&
      document.querySelector(".multicityDate.o2").classList.add("errorDate")
      : document.querySelector(".multicityDate.o2") &&
      document.querySelector(".multicityDate.o2").classList.remove("errorDate");
  }, [validFromTwo, validToTwo, validFromDateTwo]);

  //Three
  useEffect(() => {
    const validate = Boolean(
      validFromThree && validToThree && validFromDateThree
    );
    setIsValidThree(validate);

    validFromThree !== undefined && !validFromThree
      ? document.querySelector(".dest-from.o3") &&
      document.querySelector(".dest-from.o3").classList.add("errorBox")
      : document.querySelector(".dest-from.o3") &&
      document.querySelector(".dest-from.o3").classList.remove("errorBox");
    validToThree !== undefined && !validToThree
      ? document.querySelector(".dest-to.d3") &&
      document.querySelector(".dest-to.d3").classList.add("errorBox")
      : document.querySelector(".dest-to.d3") &&
      document.querySelector(".dest-to.d3").classList.remove("errorBox");
    validFromDateThree !== undefined && !validFromDateThree
      ? document.querySelector(".multicityDate.o3") &&
      document.querySelector(".multicityDate.o3").classList.add("errorDate")
      : document.querySelector(".multicityDate.o3") &&
      document.querySelector(".multicityDate.o3").classList.remove("errorDate");
  }, [validFromThree, validToThree, validFromDateThree]);

  //Four
  useEffect(() => {
    const validate = Boolean(validFromFour && validToFour && validFromDateFour);
    setIsValidFour(validate);

    validFromFour !== undefined && !validFromFour
      ? document.querySelector(".dest-from.o4") &&
      document.querySelector(".dest-from.o4").classList.add("errorBox")
      : document.querySelector(".dest-from.o4") &&
      document.querySelector(".dest-from.o4").classList.remove("errorBox");
    validToFour !== undefined && !validToFour
      ? document.querySelector(".dest-to.d4") &&
      document.querySelector(".dest-to.d4").classList.add("errorBox")
      : document.querySelector(".dest-to.d4") &&
      document.querySelector(".dest-to.d4").classList.remove("errorBox");
    validFromDateFour !== undefined && !validFromDateFour
      ? document.querySelector(".multicityDate.o4") &&
      document.querySelector(".multicityDate.o4").classList.add("errorDate")
      : document.querySelector(".multicityDate.o4") &&
      document.querySelector(".multicityDate.o4").classList.remove("errorDate");
  }, [validFromFour, validToFour, validFromDateFour]);

  //Five
  useEffect(() => {
    const validate = Boolean(validFromFive && validToFive && validFromDateFive);
    setIsValidFive(validate);

    validFromFive !== undefined && !validFromFive
      ? document.querySelector(".dest-from.o5") &&
      document.querySelector(".dest-from.o5").classList.add("errorBox")
      : document.querySelector(".dest-from.o5") &&
      document.querySelector(".dest-from.o5").classList.remove("errorBox");
    validToFive !== undefined && !validToFive
      ? document.querySelector(".dest-to.d5") &&
      document.querySelector(".dest-to.d5").classList.add("errorBox")
      : document.querySelector(".dest-to.d5") &&
      document.querySelector(".dest-to.d5").classList.remove("errorBox");
    validFromDateFive !== undefined && !validFromDateFive
      ? document.querySelector(".multicityDate.o5") &&
      document.querySelector(".multicityDate.o5").classList.add("errorDate")
      : document.querySelector(".multicityDate.o5") &&
      document.querySelector(".multicityDate.o5").classList.remove("errorDate");
  }, [validFromFive, validToFive, validFromDateFive]);

  //Six
  useEffect(() => {
    const validate = Boolean(validFromSix && validToSix && validFromDateSix);
    setIsValidSix(validate);

    validFromSix !== undefined && !validFromSix
      ? document.querySelector(".dest-from.o6") &&
      document.querySelector(".dest-from.o6").classList.add("errorBox")
      : document.querySelector(".dest-from.o6") &&
      document.querySelector(".dest-from.o6").classList.remove("errorBox");
    validToSix !== undefined && !validToSix
      ? document.querySelector(".dest-to.d6") &&
      document.querySelector(".dest-to.d6").classList.add("errorBox")
      : document.querySelector(".dest-to.d6") &&
      document.querySelector(".dest-to.d6").classList.remove("errorBox");
    validFromDateSix !== undefined && !validFromDateSix
      ? document.querySelector(".multicityDate.o6") &&
      document.querySelector(".multicityDate.o6").classList.add("errorDate")
      : document.querySelector(".multicityDate.o6") &&
      document.querySelector(".multicityDate.o6").classList.remove("errorDate");
  }, [validFromSix, validToSix, validFromDateSix]);

  useEffect(() => {
    const validate =
      state.enableSegSix ?
        Boolean(
          isValidOne &&
          isValidTwo &&
          isValidThree &&
          isValidFour &&
          isValidFive &&
          isValidSix
        ) : state.enableSegFive ?
          Boolean(
            isValidOne &&
            isValidTwo &&
            isValidThree &&
            isValidFour &&
            isValidFive
          ) : state.enableSegFour ?
            Boolean(
              isValidOne &&
              isValidTwo &&
              isValidThree &&
              isValidFour
            ) : state.enableSegThree ?
              Boolean(
                isValidOne &&
                isValidTwo 
              ) : false;
    setIsValid(validate);
  }, [
    isValidOne,
    isValidTwo,
    isValidThree,
    isValidFour,
    isValidFive,
    isValidSix,
  ]);

  //For validating the search before hitting the search api
  const validateSearch = () => {
    if (validFromOne === undefined) setValidFromOne(false);
    if (validToOne === undefined) setValidToOne(false);
    if (validFromDateOne === undefined) setValidFromDateOne(false);

    if (validFromTwo === undefined) setValidFromTwo(false);
    if (validToTwo === undefined) setValidToTwo(false);
    if (validFromDateTwo === undefined) setValidFromDateTwo(false);

    if(state.enableSegFour){
      if (validFromThree === undefined) setValidFromThree(false);
      if (validToThree === undefined) setValidToThree(false);
      if (validFromDateThree === undefined) setValidFromDateThree(false);
    }

    if (validFromFour === undefined) setValidFromFour(false);
    if (validToFour === undefined) setValidToFour(false);
    if (validFromDateFour === undefined) setValidFromDateFour(false);

    if (validFromFive === undefined) setValidFromFive(false);
    if (validToFive === undefined) setValidToFive(false);
    if (validFromDateFive === undefined) setValidFromDateFive(false);

    if (validFromSix === undefined) setValidFromSix(false);
    if (validToSix === undefined) setValidToSix(false);
    if (validFromDateSix === undefined) setValidFromDateSix(false);
  };

  const checkAndRemoveStoredIternaries = () => {
    if(localStorage.getItem("AVAILABILITY_FAILURE")!==null){
        localStorage.removeItem("AVAILABILITY_FAILURE");
        localStorage.removeItem("SELECTED_FLIGHT_OW");
        localStorage.removeItem("SELECTED_FLIGHT_RT");
        localStorage.removeItem("SELECTED_MULTI_ONE");
        localStorage.removeItem("SELECTED_MULTI_TWO");
        localStorage.removeItem("SELECTED_MULTI_THREE");
        localStorage.removeItem("SELECTED_MULTI_FOUR");
        localStorage.removeItem("SELECTED_MULTI_FIVE");
        localStorage.removeItem("SELECTED_MULTI_SIX");
    }
}

//For handling the change direct flight
const handleChangeDirectFlight = (e) => {
  dispatch({ type: "ns", payload: !state.ns });
};

  const handleSearch = () => {
    checkAndRemoveStoredIternaries();
    if (isValid && !errorOne & !errorTwo && !errorThree && !errorFour && !errorFive && !errorSix) {
      checkIfTwoLegsOnly();
    } else {
      validateSearch();
    }
  };

  const checkIfTwoLegsOnly = () => {
    let multiSearch = true;
    if (!state.enableSegFour) {
      if ((!validFromThree && !validToThree && !validFromDateThree) || (validFromThree && !validToThree && !validFromDateThree)) {
        if (
          state.orignDataOne.country === state.destDataTwo.country &&
          state.orignDataOne.city === state.destDataTwo.city &&
          state.orignDataOne.airport === state.destDataTwo.airport &&
          state.destDataOne.country === state.orignDataTwo.country &&
          state.destDataOne.city === state.orignDataTwo.city &&
          state.destDataOne.airport === state.orignDataTwo.airport
        ) {
          if (validFromThree) {
            clearSource(null, "o3");
            setValidToThree(true);
            setValidFromDateThree(true);
          }
          multiSearch = false;
          if (!multiSearch) {
            constructURLForRoundTrip();
          }
        }
      }
    }
    if (multiSearch) {
      constructURLForMultiSearch();
    }
  };
  

  const constructURLForRoundTrip = () => {
    try {
      let isPaxChanged = false;
      setIsLoad(true);
      let cls = setClsType();
      const params = new URLSearchParams({
        srcCode: state.orignDataOne.airport,
        destCode: state.destDataOne.airport,
        dd: state.dateOne,
        ad: state.dateTwo,
        tt: 2,
        adt: state.adt,
        chd: state.chd,
        inf: state.inf,
        ns: state.ns,
        cls: cls,
        modify: modifySearch,
        usr_cur: context.userCur!=="" && context.userCur!==undefined ? context.userCur : sessionStorage.getItem("SEL_CUR"),
      });
      const urlParams = params.toString();
      // Construct the URL with the parameters
      const url = `/flight/results?${urlParams}`;
      //navigate(url);
      var h = window.location.host.includes('localhost')?'http':'https';
      window.location.href = `${h}://${window.location.host}`+url;
    } catch (e) {
      console.error("Exception in constructURLForRoundTrip:" + e);
    }
  }

  const constructURLForMultiSearch = () => {
    try {
      let isPaxChanged = false;
      setIsLoad(true);
      let cls = setClsType();
      const params = new URLSearchParams({
        dep1: state.orignDataOne.airport,
        dep2: state.orignDataTwo.airport,
        dep3: state.orignDataThree.airport,
        dep4: state.orignDataFour.airport,
        dep5: state.orignDataFive.airport,
        dep6: state.orignDataSix.airport,
        arr1: state.destDataOne.airport,
        arr2: state.destDataTwo.airport,
        arr3: state.destDataThree.airport,
        arr4: state.destDataFour.airport,
        arr5: state.destDataFive.airport,
        arr6: state.destDataSix.airport,
        depDate1: state.dateOne,
        depDate2: state.dateTwo,
        depDate3: state.dateThree,
        depDate4: state.dateFour,
        depDate5: state.dateFive,
        depDate6: state.dateSix,
        tt: 3,
        adt: state.adt,
        chd: state.chd,
        inf: state.inf,
        ns: state.ns,
        cl: cls,
        cur: context.userCur!==""&&context.userCur!==undefined?context.userCur:sessionStorage.getItem("SEL_CUR"),
        modify: modifySearch,
        usr_cur: context.userCur!=="" && context.userCur!==undefined ? context.userCur : sessionStorage.getItem("SEL_CUR"),
      });
      const urlParams = params.toString();
      // Construct the URL with the parameters
      const url = `/flight/results?${urlParams}`;
      //navigate(url);
      var h = window.location.host.includes('localhost')?'http':'https';
      window.location.href = `${h}://${window.location.host}`+url;
    } catch (e) {
      console.error("Exception in constructURLForMultiSearch:" + e);
    }
  }

  const fireMultiSearch = () => {
    let isPaxChanged = false;
    try {
      setIsLoad(true);
      let cls = setClsType();
      const payload = {
        search: state,
        tt: 3,
        cl: cls,
        cur: "INR",
        chan: "B2C",
        cid: FLIGHT_SEARCH_CID,
        sty: "normal",
        modify: modifySearch,
        flowType: "DIRECT",
        bktype: "DIRECT",
        journey_type: "Onward",
        region_type: localStorage.getItem("REGION_COUNTRY")!==null && localStorage.getItem("REGION_COUNTRY")==="IN"?"India":"Qatar",
        office_id: FLIGHT_SEARCH_OFFICE_ID,
        ns: state.ns
      };
      navigate("/flight/results", {
        state: {
          req: payload,
          data: {
            state: state,
            adt: state.adt,
            chd: state.chd,
            inf: state.inf,
            cls: state.cls,
            paxCount:
              state.adt +
              (state.chd ? state.chd : 0) +
              (state.inf ? state.inf : 0),
            tab: props.selTrip,
            modify: modifySearch,
            tt:3,
            ns: state.ns
          },
        },
      });
    } catch (e) {
      console.error("Exception in flight search request" + e);
    }
  }

  const fireRoundTrip = () => {
    try {//orignDataOne , destDataOne
      setIsLoad(true);
      let cls = setClsType();
      const payload = {
          "src": state.orignDataOne.country,
          "dest": state.destDataOne.country,
          "adt": state.adt,
          "chd": state.chd,
          "inf": state.inf,
          "dd": state.dateOne,
          "ad": state.dateTwo,
          "tt": 2,
          "ns": state.ns,
          "cls": cls,
          "clstyp": state.cls,
          "cur": context.userCur!==""&&context.userCur!==undefined?context.userCur:sessionStorage.getItem("SEL_CUR"),
          "modify": modifySearch,
          "srcCode": state.orignDataOne.airport,
          "destCode": state.destDataOne.airport,
          "srcCity": state.orignDataOne.city,
          "destCity": state.destDataOne.city,
          "srcDate": state.dateOne,
          "destDate": state.dateTwo,
          "sty": "normal",
          "bktype": "DIRECT",
          "flowType": "DIRECT",
          "paxChanged": false,
          "journey_type": "Onward",
          "cid": FLIGHT_SEARCH_CID,
          "region_type": localStorage.getItem("REGION_COUNTRY")!==null && localStorage.getItem("REGION_COUNTRY")==="IN"?"India":"Qatar",
          "office_id": FLIGHT_SEARCH_OFFICE_ID,
          "region_cur=": context.regionCur
       
      }
      navigate("/flight/results", 
      { state: { 
          req: payload ,
          data: {
              src : state.orignDataOne,
              dest : state.destDataOne,
              adt: state.adt,
              chd: state.chd,
              inf: state.inf,
              cls: state.cls,
              paxCount: state.adt+(state.chd?state.chd:0)+(state.inf?state.inf:0),
              tab: 'RoundTrip',
              dates: state.selDate,
              modify: modifySearch,
              tt: 2,
              ns: state.ns
          }
        } 
      }
      );

  } catch (e) {
      console.error('Exception in flight search request' + e);
  }
  }

  const toggleMultiPanel = () => {
    setShowMultiPanel(!showMultiPanel);
    setShowMultiSearchPanel(!showMultiSearchPanel)
  }

  useEffect(() => {
    setShowMultiTextPanel(Boolean(props.showPanel && ((state.orignDataOne && state.orignDataOne.city !== undefined) ||
    (state.orignDataTwo && state.orignDataTwo.city !== undefined) ||
    (state.orignDataThree && state.orignDataThree.city !== undefined) ||
    (state.orignDataFour && state.orignDataFour.city !== undefined) ||
    (state.orignDataFive && state.orignDataFive.city !== undefined) ||
    (state.orignDataSix && state.orignDataSix.city !== undefined) ||
    (state.destDataOne && state.destDataOne.city !== undefined) ||
    (state.destDataTwo && state.destDataTwo.city !== undefined) ||
    (state.destDataThree && state.destDataThree.city !== undefined) ||
    (state.destDataFour && state.destDataFour.city !== undefined) ||
    (state.destDataFive && state.destDataFive.city !== undefined) ||
    (state.destDataSix && state.destDataSix.city !== undefined))))
  }, [state.orignDataOne,state.orignDataTwo,state.orignDataThree,state.orignDataFour,state.orignDataFive,state.orignDataSix,
  state.destDataOne,state.destDataTwo,state.destDataThree,state.destDataFour,state.destDataFive,state.destDataSix])
  
  //For closing all date panels onfocus
  const onFocus = useCallback((data, o) => {
    setDepDateOneFocus(false);
    setDepDateTwoFocus(false);
    setDepDateThreeFocus(false);
    setDepDateFourFocus(false);
    setDepDateFiveFocus(false);
    setDepDateSixFocus(false);
    props.togglett(false);
    props.toggleTravellers(false);
  }, []);

useEffect(() => {
  if(sourceOne && sourceOne.length>2){
    if(state.airportsOW1 === undefined || (state.airportsOW1 && state.airportsOW1.length===0)){
        setSrcOnePredectiveLoading(false);
        setSrcOneLoaded(false);
    } else {
        if(state.airportsOW1.length > 0){
          setSrcOnePredectiveLoading(false);
          setSrcOneLoaded(true);
        }
    }
} else {
  setSrcOnePredectiveLoading(false);
}
}, [state.airportsOW1])


useEffect(() => {
if(sourceTwo && sourceTwo.length>2){
if(state.airportsOW2 === undefined || (state.airportsOW2 && state.airportsOW2.length===0)){
    setSrcTwoPredectiveLoading(false);
    setSrcTwoLoaded(false);
} else {
    if(state.airportsOW2.length > 0){
      setSrcTwoPredectiveLoading(false);
      setSrcTwoLoaded(true);
    }
}
} else {
setSrcTwoPredectiveLoading(false);
}
}, [state.airportsOW2])

useEffect(() => {
  if(sourceThree && sourceThree.length>2){
    if(state.airportsOW3 === undefined || (state.airportsOW3 && state.airportsOW3.length===0)){
        setSrcThreePredectiveLoading(false);
        setSrcThreeLoaded(false);
    } else {
        if(state.airportsOW3.length > 0){
          setSrcThreePredectiveLoading(false);
          setSrcThreeLoaded(true);
        }
    }
    } else {
    setSrcThreePredectiveLoading(false);
    }
    
}, [state.airportsOW3])

useEffect(() => {
  if(sourceFour && sourceFour.length>2){
    if(state.airportsOW4 === undefined || (state.airportsOW4 && state.airportsOW4.length===0)){
        setSrcFourPredectiveLoading(false);
        setSrcFourLoaded(false);
    } else {
        if(state.airportsOW4.length > 0){
          setSrcFourPredectiveLoading(false);
          setSrcFourLoaded(true);
        }
    }
    } else {
    setSrcFourPredectiveLoading(false);
    }
    
}, [state.airportsOW4])

useEffect(() => {
  if(sourceFive && sourceFive.length>2){
    if(state.airportsOW5 === undefined || (state.airportsOW5 && state.airportsOW5.length===0)){
        setSrcFivePredectiveLoading(false);
        setSrcFiveLoaded(false);
    } else {
        if(state.airportsOW5.length > 0){
          setSrcFivePredectiveLoading(false);
          setSrcFiveLoaded(true);
        }
    }
    } else {
    setSrcFivePredectiveLoading(false);
    }
    
}, [state.airportsOW5])

useEffect(() => {
  if(sourceSix && sourceSix.length>2){
    if(state.airportsOW6 === undefined || (state.airportsOW6 && state.airportsOW6.length===0)){
        setSrcSixPredectiveLoading(false);
        setSrcSixLoaded(false);
    } else {
        if(state.airportsOW6.length > 0){
          setSrcSixPredectiveLoading(false);
          setSrcSixLoaded(true);
        }
    }
    } else {
    setSrcSixPredectiveLoading(false);
    }
}, [state.airportsOW6])

useEffect(() => {
  if(destinationOne && destinationOne.length>2){
      if(state.airportsRT1 === undefined || (state.airportsRT1 && state.airportsRT1.length===0)){
          setDestOnePredectiveLoading(false);
          setDestOneLoaded(false);
      } else {
          if(state.airportsRT1.length > 0){
            setDestOnePredectiveLoading(false);
            setDestOneLoaded(true);
          }
      }
  } else {
    setDestOnePredectiveLoading(false);
  }
}, [state.airportsRT1])

useEffect(() => {
  if(destinationTwo && destinationTwo.length>2){
    if(state.airportsRT2 === undefined || (state.airportsRT2 && state.airportsRT2.length===0)){
        setDestTwoPredectiveLoading(false);
        setDestTwoLoaded(false);
    } else {
        if(state.airportsRT2.length > 0){
          setDestTwoPredectiveLoading(false);
          setDestTwoLoaded(true);
        }
    }
  } else {
    setDestTwoPredectiveLoading(false);
  }
}, [state.airportsRT2])

useEffect(() => {
  if(destinationThree && destinationThree.length>2){
    if(state.airportsRT3 === undefined || (state.airportsRT3 && state.airportsRT3.length===0)){
        setDestThreePredectiveLoading(false);
        setDestThreeLoaded(false);
    } else {
        if(state.airportsRT3.length > 0){
          setDestThreePredectiveLoading(false);
          setDestThreeLoaded(true);
        }
    }
  } else {
    setDestThreePredectiveLoading(false);
  }
}, [state.airportsRT3])

useEffect(() => {
  if(destinationFour && destinationFour.length>2){
    if(state.airportsRT4 === undefined || (state.airportsRT4 && state.airportsRT4.length===0)){
        setDestFourPredectiveLoading(false);
        setDestFourLoaded(false);
    } else {
        if(state.airportsRT4.length > 0){
          setDestFourPredectiveLoading(false);
          setDestFourLoaded(true);
        }
    }
  } else {
    setDestFourPredectiveLoading(false);
  }
}, [state.airportsRT4])

useEffect(() => {
  if(destinationFive && destinationFive.length>2){
    if(state.airportsRT5 === undefined || (state.airportsRT5 && state.airportsRT5.length===0)){
        setDestFivePredectiveLoading(false);
        setDestFiveLoaded(false);
    } else {
        if(state.airportsRT5.length > 0){
          setDestFivePredectiveLoading(false);
          setDestFiveLoaded(true);
        }
    }
  } else {
    setDestFivePredectiveLoading(false);
  }
}, [state.airportsRT5])

useEffect(() => {
  if(destinationSix && destinationSix.length>2){
    if(state.airportsRT6 === undefined || (state.airportsRT6 && state.airportsRT6.length===0)){
        setDestSixPredectiveLoading(false);
        setDestSixLoaded(false);
    } else {
        if(state.airportsRT6.length > 0){
          setDestSixPredectiveLoading(false);
          setDestSixLoaded(true);
        }
    }
  } else {
    setDestSixPredectiveLoading(false);
  }
}, [state.airportsRT6])


  return (
    <>
    {errorOne && <Alert message={errorMessageOne} closable type="error" />}
    {errorTwo && <Alert message={errorMessageTwo} closable type="error" />}
    {errorThree && <Alert message={errorMessageThree} closable type="error" />}
    {errorFour && <Alert message={errorMessageFour} closable type="error" />}
    {errorFive && <Alert message={errorMessageFive} closable type="error" />}
    {errorSix && <Alert message={errorMessageSix} closable type="error" />}
      {modifySearch &&
      showMultiTextPanel && 
      !showMultiSearchPanel &&
      (
        <div className="selectedMultiInfo" onClick={toggleMultiPanel}>
          <Row className="fullWidth">
            <Col>
              <div className="smi-cont">
                <strong>
                {state.orignDataOne && state.orignDataOne.city}
                {state.destDataOne && state.destDataOne.city !== undefined && <i className=" icon-next-long-arrow-right-svgrepo-com"></i>}
                {state.destDataOne && state.destDataOne.length !==0 && state.destDataOne.city}

                {state.orignDataTwo && (state.destDataOne && state.destDataOne.city !== state.orignDataTwo.city) && (state.destDataTwo && state.destDataTwo.city !== undefined) && <i className=" icon-next-long-arrow-right-svgrepo-com"></i>}
                {state.orignDataTwo && (state.destDataOne && state.destDataOne.city !== state.orignDataTwo.city) && state.orignDataTwo.city}
                {state.destDataTwo && state.destDataTwo.city !== undefined && <i className=" icon-next-long-arrow-right-svgrepo-com"></i>}
                {state.destDataTwo && state.destDataTwo.length !==0 && state.destDataTwo.city}

                {state.orignDataThree && (state.destDataTwo && state.destDataTwo.city !== state.orignDataThree.city) && (state.destDataThree && state.destDataThree.city !== undefined) && <i className=" icon-next-long-arrow-right-svgrepo-com"></i>}
                {state.orignDataThree && (state.destDataTwo && state.destDataTwo.city !== state.orignDataThree.city) && state.orignDataThree.city}
                {state.destDataThree && state.destDataThree.city !== undefined && <i className=" icon-next-long-arrow-right-svgrepo-com"></i>}
                {state.destDataThree && state.destDataThree.length !==0 && state.destDataThree.city}

                {state.orignDataFour && (state.destDataThree && state.destDataThree.city !== state.orignDataFour.city) && (state.destDataFour && state.destDataFour.city !== undefined) && <i className=" icon-next-long-arrow-right-svgrepo-com"></i>}
                {state.orignDataFour && (state.destDataThree && state.destDataThree.city !== state.orignDataFour.city) && state.orignDataFour.city}
                {state.destDataFour && state.destDataFour.city !== undefined && <i className=" icon-next-long-arrow-right-svgrepo-com"></i>}
                {state.destDataFour && state.destDataFour.length !==0 && state.destDataFour.city}

                {state.orignDataFive && (state.destDataFour && state.destDataFour.city !== state.orignDataFive.city) && (state.destDataFive && state.destDataFive.city !== undefined) && <i className=" icon-next-long-arrow-right-svgrepo-com"></i>}
                {state.orignDataFive && (state.destDataFour && state.destDataFour.city !== state.orignDataFive.city) && state.orignDataFive.city}
                {state.destDataFive && state.destDataFive.city !== undefined && <i className=" icon-next-long-arrow-right-svgrepo-com"></i>}
                {state.destDataFive && state.destDataFive.length !==0 && state.destDataFive.city}

                {state.orignDataSix && (state.destDataFive && state.destDataFive.city !== state.orignDataSix.city) && (state.destDataSix && state.destDataSix.city !== undefined) && <i className=" icon-next-long-arrow-right-svgrepo-com"></i>}
                {state.orignDataSix && (state.destDataFive && state.destDataFive.city !== state.orignDataSix.city) && state.orignDataSix.city}
                {state.destDataSix  && state.destDataSix.city !== undefined && <i className=" icon-next-long-arrow-right-svgrepo-com"></i>}
                {state.destDataSix && state.destDataSix.length !==0 && state.destDataSix.city}
                </strong>

              {/* <span className="icon-down-arrow"></span> */}
              </div>
            </Col>
            <Col>
                <Button type="primary" className="cntBtn">
                  {modifySearch && context.translation && context.translation.modify}
                  <img src={BrandIcon} alt="brandIcon" className="brandIcon"></img>
                </Button>
            </Col>
          </Row>
          
        </div>
      )}
      {(!modifySearch || showMultiPanel || !showMultiTextPanel) && (
        <div ref={inputRef} className={!state.enableSegFour && "withOutDel"}>
          {numbers.map((index) => {
            switch (index) {
              case 1:
                return (
                  <MultiCityContainer
                    enable={true}
                    delete={false}
                    ow={state.airportsOW1}
                    rt={state.airportsRT1}
                    srcval={sourceOne}
                    srconc={onSrcChange}
                    srcons={onSrcSelect}
                    srckey={"o1"}
                    destval={destinationOne}
                    destonc={onDestChange}
                    destons={onDestSelect}
                    destkey={"d1"}
                    open={depDateOneFocus}
                    focus={dateFocus}
                    dtonc={onDepDateChange}
                    delsec={deleteSector}
                    index={index}
                    srcenbl={sourceOneEnable}
                    destenbl={destinationOneEnable}
                    clrsrc={clearSource}
                    clrdest={clearDestination}
                    srcclr={sourceOneClearEnable}
                    dstclr={destinationOneClearEnable}
                    mindate={new Date()}
                    selDate={depDateOne !== undefined ? depDateOne : new Date()}
                    srcDestFocus={onFocus}
                    srcPreLoading={srcOnePredectiveLoading}
                    srcPreLoaded={srcOneLoaded}
                    destPreLoading={destOnePredectiveLoading}
                    destPreLoaded={destOneLoaded}
                  />
                );
                break;
              case 2:
                return (
                  <MultiCityContainer
                    enable={state.enableSegTwo}
                    delete={false}
                    ow={state.airportsOW2}
                    rt={state.airportsRT2}
                    srcval={sourceTwo}
                    srconc={onSrcChange}
                    srcons={onSrcSelect}
                    srckey={"o2"}
                    destval={destinationTwo}
                    destonc={onDestChange}
                    destons={onDestSelect}
                    destkey={"d2"}
                    open={depDateTwoFocus}
                    focus={dateFocus}
                    dtonc={onDepDateChange}
                    delsec={deleteSector}
                    index={index}
                    srcenbl={sourceTwoEnable}
                    destenbl={destinationTwoEnable}
                    clrsrc={clearSource}
                    clrdest={clearDestination}
                    srcclr={sourceTwoClearEnable}
                    dstclr={destinationTwoClearEnable}
                    mindate={depDateOne !== undefined ? depDateOne : new Date()}
                    selDate={depDateTwo !== undefined ? depDateTwo : new Date()}
                    srcDestFocus={onFocus}
                    srcPreLoading={srcTwoPredectiveLoading}
                    srcPreLoaded={srcTwoLoaded}
                    destPreLoading={destTwoPredectiveLoading}
                    destPreLoaded={destTwoLoaded}
                  />
                );
                break;
              case 3:
                return (
                  <MultiCityContainer
                    enable={state.enableSegThree}
                    delete={false}
                    ow={state.airportsOW3}
                    rt={state.airportsRT3}
                    srcval={sourceThree}
                    srconc={onSrcChange}
                    srcons={onSrcSelect}
                    srckey={"o3"}
                    destval={destinationThree}
                    destonc={onDestChange}
                    destons={onDestSelect}
                    destkey={"d3"}
                    open={depDateThreeFocus}
                    focus={dateFocus}
                    dtonc={onDepDateChange}
                    delsec={deleteSector}
                    index={index}
                    srcenbl={sourceThreeEnable}
                    destenbl={destinationThreeEnable}
                    clrsrc={clearSource}
                    clrdest={clearDestination}
                    srcclr={sourceThreeClearEnable}
                    dstclr={destinationThreeClearEnable}
                    mindate={depDateTwo !== undefined ? depDateTwo : new Date()}
                    selDate={
                      depDateThree !== undefined ? depDateThree : new Date()
                    }
                    srcDestFocus={onFocus}
                    srcPreLoading={srcThreePredectiveLoading}
                    srcPreLoaded={srcThreeLoaded}
                    destPreLoading={destThreePredectiveLoading}
                    destPreLoaded={destThreeLoaded}
                  />
                );
                break;
              case 4:
                return (
                  <MultiCityContainer
                    enable={state.enableSegFour}
                    delete={state.enableSegFour && !state.enableSegFive}
                    ow={state.airportsOW4}
                    rt={state.airportsRT4}
                    srcval={sourceFour}
                    srconc={onSrcChange}
                    srcons={onSrcSelect}
                    srckey={"o4"}
                    destval={destinationFour}
                    destonc={onDestChange}
                    destons={onDestSelect}
                    destkey={"d4"}
                    open={depDateFourFocus}
                    focus={dateFocus}
                    dtonc={onDepDateChange}
                    delsec={deleteSector}
                    index={index}
                    srcenbl={sourceFourEnable}
                    destenbl={destinationFourEnable}
                    clrsrc={clearSource}
                    clrdest={clearDestination}
                    srcclr={sourceFourClearEnable}
                    dstclr={destinationFourClearEnable}
                    mindate={
                      depDateThree !== undefined ? depDateThree : new Date()
                    }
                    selDate={
                      depDateFour !== undefined ? depDateFour : new Date()
                    }
                    srcDestFocus={onFocus}
                    srcPreLoading={srcFourPredectiveLoading}
                    srcPreLoaded={srcFourLoaded}
                    destPreLoading={destFourPredectiveLoading}
                    destPreLoaded={destFourLoaded}
                  />
                );
                break;
              case 5:
                return (
                  <MultiCityContainer
                    enable={state.enableSegFive}
                    delete={state.enableSegFive && !state.enableSegSix}
                    ow={state.airportsOW5}
                    rt={state.airportsRT5}
                    srcval={sourceFive}
                    srconc={onSrcChange}
                    srcons={onSrcSelect}
                    srckey={"o5"}
                    destval={destinationFive}
                    destonc={onDestChange}
                    destons={onDestSelect}
                    destkey={"d5"}
                    open={depDateFiveFocus}
                    focus={dateFocus}
                    dtonc={onDepDateChange}
                    delsec={deleteSector}
                    index={index}
                    srcenbl={sourceFiveEnable}
                    destenbl={destinationFiveEnable}
                    clrsrc={clearSource}
                    clrdest={clearDestination}
                    srcclr={sourceFiveClearEnable}
                    dstclr={destinationFiveClearEnable}
                    mindate={
                      depDateFour !== undefined ? depDateFour : new Date()
                    }
                    selDate={
                      depDateFive !== undefined ? depDateFive : new Date()
                    }
                    srcDestFocus={onFocus}
                    srcPreLoading={srcFivePredectiveLoading}
                    srcPreLoaded={srcFiveLoaded}
                    destPreLoading={destFivePredectiveLoading}
                    destPreLoaded={destFiveLoaded}
                  />
                );
                break;
              case 6:
                return (
                  <MultiCityContainer
                    enable={state.enableSegSix}
                    delete={state.enableSegSix}
                    ow={state.airportsOW6}
                    rt={state.airportsRT6}
                    srcval={sourceSix}
                    srconc={onSrcChange}
                    srcons={onSrcSelect}
                    srckey={"o6"}
                    destval={destinationSix}
                    destonc={onDestChange}
                    destons={onDestSelect}
                    destkey={"d6"}
                    open={depDateSixFocus}
                    focus={dateFocus}
                    dtonc={onDepDateChange}
                    delsec={deleteSector}
                    index={index}
                    srcenbl={sourceSixEnable}
                    destenbl={destinationSixEnable}
                    clrsrc={clearSource}
                    clrdest={clearDestination}
                    srcclr={sourceSixClearEnable}
                    dstclr={destinationSixClearEnable}
                    mindate={
                      depDateFive !== undefined ? depDateFive : new Date()
                    }
                    selDate={depDateSix !== undefined ? depDateSix : new Date()}
                    srcDestFocus={onFocus}
                    srcPreLoading={srcSixPredectiveLoading}
                    srcPreLoaded={srcSixLoaded}
                    destPreLoading={destSixPredectiveLoading}
                    destPreLoaded={destSixLoaded}
                  />
                );
                break;

              default:
                break;
            }
          })}
          <span className="multicityBtnWrapper">
            <Row className="addMoreFlights">
              {!state.enableSegSix && (
                <Button onClick={handleAddSector} variant="link">
                  {/* + Add More Flights */}
                  {context.translation && context.translation.amf}
                </Button>
              )}{" "}
            </Row>
            <Button type="primary" onClick={handleSearch} className="cntBtn">
              {/* Search */}
              {!modifySearch && context.translation && context.translation.search}
              {modifySearch && context.translation && context.translation.modify}
              {/* <i className="sprite btn-arrow-sprite"></i> */}
              <img src={BrandIcon} alt="brandIcon" className="brandIcon"></img>
            </Button>
          </span>
          {/* <Row style={{ marginTop: '20px' }}>
        <Col>
          <Checkbox
            value={state.ns}
            checked={state.ns}
            onClick={handleChangeDirectFlight}
          >
            Direct Flights Only
            {context.translation && context.translation.dfo}
          </Checkbox>
        </Col>
      </Row> */}
        </div>
      )}
    </>
  );
}

export default MultiCitySearch;
