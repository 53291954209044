/**
 * @ Author: Srikanth Bandaru
 * @ Create Time: 2023-06-15 09:47:01
 * @ Description: The component used in the search results page and blocks for flights to display the source and destination details along with date information
 */

import React, { useEffect, useState,useContext } from "react";
import AirportMemo from "../common/airlines/AirportNames";
import DateUtils from "../../../utilities/DateUtils";
import { Context } from "../../../../App";

//Main function For Result Header
function ResultSectionHeader(props) {
  const [context, setContext] = useContext(Context)
  const { mapping, sectors, secType, secTypeIndex, trip, refund,flow } = props;
  const [displayObj, setDisplayObj] = useState({ fd: "", da: "", aa: "", pnr: "" });
  
  useEffect(() => {
      if (secType === "onward") {
        if (sectors.length > 0) {
          setDisplayObj({
            fd: sectors[(!flow || (flow && flow === 'SRP'))?0:(parseInt(trip)!==3?0:secTypeIndex)]?.dd,
            da: sectors[(!flow || (flow && flow === 'SRP'))?0:(parseInt(trip)!==3?0:secTypeIndex)]?.src,
            aa: sectors[(!flow || (flow && flow === 'SRP'))?0:(parseInt(trip)!==3?0:secTypeIndex)]?.des,
            pnr: sectors[(!flow || (flow && flow === 'SRP'))?0:(parseInt(trip)!==3?0:secTypeIndex)]?.pnr,
          });
        }
      } else if (secType === "arrival") {
        if (sectors.length > 0) {
          setDisplayObj({
            fd: flow === "SRPMULTI" ? sectors[1].dd : 
                sectors.length === 1 ? sectors[0].ad : sectors.length === 2 && sectors[1].ad, 
            da: sectors[0].des,
            aa: sectors[0].src,
            pnr: sectors.length === 1 ? sectors[0].pnr : sectors.length === 2 && sectors[1].pnr,
          });
        }
      }
  }, [secType, sectors]);

  return (
    <>
      {displayObj.da && (
        <div className="rb-top dFlex">
          <div className="info">
            <div>
              <span className="smallFlight">
                <i className="icon icon-flight"></i>
              </span>
              <span className="route">
                <AirportMemo
                  field="ResultSectionHeader"
                  code={displayObj.da}
                  mapping={mapping}
                />
              </span>
              <span className="rightArrow">
                <i className="icon icon-next-long-arrow-right-svgrepo-com"></i>
              </span>
              <span className="route">
                <AirportMemo
                  field="ResultSectionHeader"
                  code={displayObj.aa}
                  mapping={mapping}
                />
              </span>
            </div>
            <div>
              <span className="date">
                {DateUtils.convertToLetterDate(displayObj.fd)}
              </span>
              {refund==="Non-Refundable" && <span className="nonRefund">{context.translation && context.translation.nonRefund}&nbsp;&nbsp;<i className="icon icon-Non-Refundable"></i></span>}
              {refund!=="Non-Refundable" && <span className="refund">{context.translation && context.translation.partialRefund}</span>}
            </div>

            
           
          </div>
          {props.pnr && (
              <div className="pnrWrapper">
                <span className="pnrTitle">PNR:&nbsp;</span>
                {props.sectors &&
                <span className="pnrNo">{(displayObj.pnr!==undefined && displayObj.pnr!=="")?displayObj.pnr:"N/A"}</span>
                }
              </div>
            )}
        </div>
      )}
    </>
  );
}

export default ResultSectionHeader;
