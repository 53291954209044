import React, { Component } from "react";
class ShowHide extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: this.props.visible || false,
    };
    this.handleClick = this.handleClick.bind(this);
  }
  handleClick(e) {
    this.setState({
      visible: !this.state.visible,
    });
  }
  render() {
    const { className = "penal" } = this.props;
    const divClass = !this.state.visible ? className : className + " hide";
    return (
      <>
        <div className="showHideLayout">
          <h5
            onClick={this.handleClick}
            className={"show-tg-line " + (this.state.visible ? "up" : "down")}
          >
            <span className={"panelTitle ezyIcon icon-" + this.props.icon}>
              {" "}
              {this.props.title}
            </span>{" "}
            <div className="iconWrapper">
              <span className="subtitl">{this.props.subtitle}</span>
              <i
                className={
                  "clickAction icon-" + (this.state.visible ? "Plus" : "Minus")
                }
              ></i>
              {/* <span
                className={
                  "clickAction ezyIcon icon-" +
                  (this.state.visible ? "minus" : "plus")
                }
              ></span> */}
            </div>
          </h5>
          {/* <ReactCSSTransitionGroup transitionName="example"> */}
          <div className={divClass}> {this.props.children}</div>
          {/* </ReactCSSTransitionGroup> */}
        </div>
      </>
    );
  }
}

export default ShowHide;
