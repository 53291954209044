/**
 * @ Author: Srikanth Bandaru
 * @ Create Time: 2023-06-16 20:57:46
 * @ Description:
 */

import React, { useState, useEffect, useReducer, useContext } from "react";
import Header from "../common/Header";
import Footer from "../common/Footer";
import { useNavigate } from "react-router-dom";
import {
  Col,
  Form,
  Input,
  Button,
  Row,
  Select,
  Typography,
  Alert,
  // notification,
  Modal,
  Drawer
} from "antd";
import { 
  // countriesWitCode, 
  deleteFrequentFlyer, getProfile, updateProfile } from "./operations";
import Sidebar from "./Sidebar";
import { Context } from "../../App";
import Utilities from "../utilities/Utilities";
// import { stringify } from "json5";
let months = require("../bookingflow/flights/common/masterData/Months.json");
let monthsArabic =require("../bookingflow/flights/common/masterData/MonthsArabic.json");

const { Title } = Typography;

let countrieslist = require("../bookingflow/flights/common/masterData/CountriesList.json");
let airlineMaster = require("../bookingflow/flights/common/masterData/AirlinesMasterData.json");
let stdCodes = require("../bookingflow/flights/common/masterData/CountrySTDCodes.json");

const initialState = {
  id: 0,
  res: [],
  countriesMenu: [],
  enableProfileBasicInfo: true,
  enableProfileContactDetails: false,
  enableProfileTravelDocuments: false,
  enableProfileFrequentFlyer: false,
  basicError: false,
  contactDetails: false,
  travelDocument: false,
  frequentFlyer: false,
  showSave: true, 
  notiMessage: "",
  notiMessageShow: false,
  notiVarient: "",
  title: "",
  firstName: "",
  lastName: "",
  dateOfBirth: {
    month: "",
    day: "",
    year: "",
  },
  nationality: "",
  maritalStatus: "",
  anniversary: {
    month: "",
    day: "",
    year: "",
  },
  email: "",
  contactNumber: {
    countryCode: "",
    number: "",
  },
  travelDocuments: [
    {
      doc_type: "",
      passport_no: "",
      issue_cnt: "",
      issue_date: {
        month: "",
        day: "",
        year: "",
      },
      exp_date: {
        month: "",
        day: "",
        year: "",
      },
    },
  ],
  frequentFlyerData: [
    {
      airline_name: "",
      ffp: "",
      ffn: "",
    },
  ],
  month: "",
};

const reducer = (state, action) => {
  switch (action.type) {
    case "UPDATE_FIELD":
      return {
        ...state,
        [action.field]: action.value,
      };
    case "UPDATE_DATE_FIELD":
      const { objectName, field, value } = action;
      return {
        ...state,
        [objectName]: {
          ...state[objectName],
          [field]: value,
        },
      };
    case "UPDATE_NESTED_FIELD":
      return {
        ...state,
        [action.field]: {
          ...state[action.field],
          [action.subfield]: action.value,
        },
      };
    // add travel document
    case "ADD_DOCUMENT_ROW":
      return {
        ...state,
        travelDocuments: [
          ...state.travelDocuments,
          {
            doc_type: "",
            passport_no: "",
            issue_cnt: "",
            issue_date: { month: "", day: "", year: "" },
          },
        ],
      };

    // removing/deleting travel document
    // case "REMOVE_DOCUMENT_ROW":
    //   return {
    //     ...state,
    //     travelDocuments: state.travelDocuments.filter(
    //       (_, index) => index !== action.index
    //     )
    //   };

    case "UPDATE_TRAVEL_DOCUMENT_FIELD":
      return {
        ...state,
        travelDocuments: state.travelDocuments.map((data, index) => {
          if (index === action.index) {
            return {
              ...data,
              [action.field]: action.value,
            };
          }
          return data;
        }),
      };

    case "UPDATE_TRAVEL_DOCUMENT_FIELD_NESTED":
      return {
        ...state,
        travelDocuments: state.travelDocuments.map((data, index) => {
          if (index === action.index) {
            return {
              ...data,
              [action.field]: {
                ...data[action.field],
                [action.subField]: action.value,
              },
            };
          }
          return data;
        }),
      };

    case "UPDATE_DATE_FIELD_NESTED":
      return {
        ...state,
        travelDocuments: state.travelDocuments.map((data, index) => {
          if (index === action.index) {
            return {
              ...data,
              [action.field]: {
                ...data[action.field],
                [action.subfield]: action.value,
              },
            };
          }
          return data;
        }),
      };
    case "UPDATE_ISSUING_COUNTRY":
      return {
        ...state,
        travelDocuments: state.travelDocuments.map((data, index) => {
          if (index === action.index) {
            return {
              ...data,
              [action.field]: action.value,
            };
          }
          return data;
        }),
      };
    case "UPDATE_AIRLINES":
      return {
        ...state,
        frequentFlyerData: state.frequentFlyerData.map((data, index) => {
          if (index === action.index) {
            return {
              ...data,
              [action.field]: action.value,
            };
          }
          return data;
        }),
      };
    case "UPDATE_FREQUENT_FLYER_FIELD":
      const frequentFlyerData = [...state.frequentFlyerData];
      frequentFlyerData[action.index] = {
        ...frequentFlyerData[action.index],
        [action.field]: action.value,
      };
      return {
        ...state,
        ...state.formData,
        frequentFlyerData,
      };
    case "ADD_ROW":
      return {
        ...state,
        ...state,
        frequentFlyerData: [...state.frequentFlyerData, {
          airline_name: "",
          ffp: "",
          ffn: "",
        }],
      };
    case "REMOVE_ROW":
      return {
        ...state,
        ...state,
        frequentFlyerData: state.frequentFlyerData.filter(
          (_, index) => index !== action.index
        ),
      };
    case "editProfile":
      return Object.assign(state, action.payload);
    default:
      return { ...state, [action.type]: action.payload };
  }
};

const UserProfile = () => {
  const navigate = useNavigate();
  const [userUniqId, setUserUniqId] = useState("");
  const [state, dispatch] = useReducer(reducer, initialState);
  const [FFD, setFFD] = useState([]);
  // const [alert, setAlert] = useState(false);
  const [disableBasicProfile, setDisableBasicProfile] = useState(false);
  const [disableContactDetails, setDisableContactDetails] = useState(true);
  const [disableTravelDocs, setDisableTravelDocs] = useState(true);
  const [disableFrequentFlyerData, setDisableFrequentFlyerData] =
    useState(true);
  const { formData } = state;
  const [context, setContext] = useContext(Context);
  const [showMenu, setshowMenu] = useState(false);

  useEffect(
    () => {
      const data = JSON.parse(localStorage.getItem("SIGNINRESPONSE"));
      try {
        if (data !== null) {
          setUserUniqId(data.res.usr_uniq_id);
          dispatch({ type: "email", payload: data.res.email });
          const payload = {
            email: data.res.email,
          };
          let tokenKey = Utilities.generateAPIToken(payload);
          getProfile(payload,tokenKey).then((response) => {
            if (response.suc) {
              localStorage.setItem("NAME", (response?.res[0]?.fname));
              setContext({ ...context, username: response?.res[0]?.fname })
              dispatch({ type: "editProfile", payload: response });
              dispatch({ type: "title", payload: response.res[0].title });
              dispatch({ type: "firstName", payload: response.res[0].fname });
              dispatch({ type: "lastName", payload: response.res[0].lname });
              if(response.res[0].dob !== null && response.res[0].dob !== undefined && response.res[0].dob !== "") {
                state.dateOfBirth.month = response.res[0].dob.slice(0, 2);
                state.dateOfBirth.day = response.res[0].dob.slice(3, 5);
                state.dateOfBirth.year = response.res[0].dob.slice(6, 10);
              } else {
                state.dateOfBirth.month = "";
                state.dateOfBirth.day = "";
                state.dateOfBirth.year = "";
              }
              dispatch({ type: "nationality", payload: response.res[0].na });
              dispatch({
                type: "maritalStatus",
                payload: response.res[0].mstatus,
              });
              if(response.res[0].mrg_date !== null && response.res[0].mrg_date !== undefined && response.res[0].mrg_date !== "") {
                state.anniversary.month = response.res[0].mrg_date.slice(0, 2);
                state.anniversary.day = response.res[0].mrg_date.slice(3, 5);
                state.anniversary.year = response.res[0].mrg_date.slice(6, 10);
              } else {
                state.anniversary.month = "";
                state.anniversary.day = "";
                state.anniversary.year = "";
              }
              state.contactNumber.countryCode = response.res[0].ph_code;
              state.contactNumber.number = response.res[0].phno;
  
              if (response.res[0].docs.length > 0) {
                state.travelDocuments[0].id = 
                  response.res[0].docs[0]?.id
                state.travelDocuments[0].passport_no =
                  response.res[0].docs[0]?.passport_no;
                state.travelDocuments[0].issue_cnt =
                  response.res[0].docs[0]?.issue_cnt;
                state.travelDocuments[0].issue_date.month =
                  response.res[0].docs[0]?.issue_date.slice(0, 2);
                state.travelDocuments[0].issue_date.day =
                  response.res[0].docs[0]?.issue_date.slice(3, 5);
                state.travelDocuments[0].issue_date.year =
                  response.res[0].docs[0]?.issue_date.slice(6, 10);
                state.travelDocuments[0].exp_date.month =
                  response.res[0].docs[0]?.exp_date.slice(0, 2);
                state.travelDocuments[0].exp_date.day =
                  response.res[0].docs[0]?.exp_date.slice(3, 5);
                state.travelDocuments[0].exp_date.year =
                  response.res[0].docs[0]?.exp_date.slice(6, 10);
              }
  
              // if (response.res[0].docs.length > 1) {
                // dispatch({
                //   type: "travelDocuments",
                //   payload: response.res[0].docs,
                // });
              // }
  
              if (response.res[0].frq_fly.length === 0) {
                state.frequentFlyerData[0].airline_name =
                  response.res[0].frq_fly[0]?.airline_name;
                state.frequentFlyerData[0].ffp = response.res[0].frq_fly[0]?.ffp;
                state.frequentFlyerData[0].ffn = response.res[0].frq_fly[0]?.ffn;
              }
  
              if (response.res[0].frq_fly.length > 0) {
                dispatch({
                  type: "frequentFlyerData",
                  payload: response.res[0].frq_fly,
                });
              }
            }
          }).catch(function (error) {
            console.error("Error in Profile API:" + error);      
          });
        }        
      } catch (error) {
        console.error("Error in Profile API:", error);          
      }
      // countriesWitCode().then((res) => {
      //   dispatch({ type: "countriesMenu", payload: res });
      // });
    },
    [localStorage.getItem("SIGNINRESPONSE")]    
  );

  const hidemessage = () => {
    setTimeout(function () {
      dispatch({ type: "notiMessageShow", payload: false });
      dispatch({ type: "notiMessage", payload: "" });
      dispatch({ type: "notiVarient", payload: "" });
    }, 3000);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const [field, subfield] = name.split(".");

    if (subfield) {
      dispatch({ type: "UPDATE_NESTED_FIELD", field, subfield, value });
    } else {
      dispatch({ type: "UPDATE_FIELD", field, value });
    }
  };

  const handleDropdownChange = (e) => {
    const { name, value } = e.target;
    dispatch({ type: "UPDATE_FIELD", field: name, value });
  };

  const handleDateFieldChange = (objectName, field, value) => {
    dispatch({ type: "UPDATE_DATE_FIELD", objectName, field, value });
  };

  const handleIssuingDateFieldChange = (index, field, subfield, value) => {
    dispatch({
      type: "UPDATE_DATE_FIELD_NESTED",
      index,
      field,
      subfield,
      value,
    });
  };

  const handleCountryFieldChange = (index, field, value) => {
    dispatch({
      type: "UPDATE_ISSUING_COUNTRY",
      index,
      field,
      value,
    });
  };

  const handleAirlineLines = (index, field, value) => {
    dispatch({
      type: "UPDATE_AIRLINES",
      index,
      field,
      value,
    });
  };

  const handleChangeNationality = (value) => {
    dispatch({ type: "nationality", payload: value });
  };

  const currentYear = new Date().getFullYear();
  const currentYearAdult = new Date().getFullYear() - 12;
  const yearsRange = 80;
  const years = Array.from(
    { length: yearsRange },
    (_, index) => currentYear - index
  );
  const yearsAdult = Array.from(
    { length: yearsRange },
    (_, index) => currentYearAdult - index
  );
  const expiryYears = Array.from(
    { length: yearsRange },
    (_, index) => currentYear + index
  );

  const getDaysInMonth = (month, year) => {
    const date = new Date(year, month, 0);
    return date.getDate();
  };

  const handleAddRow = () => {
    dispatch({ type: "ADD_ROW" });
    setFFD(state.frequentFlyerData);
    state.frequentFlyerData.airline_name = "";
    state.frequentFlyerData.ffn = "";
    state.frequentFlyerData.ffp = "";
  };

  const handleRemoveFFRow = (index, id) => {
      if(id === undefined) {        
        dispatch({ type: "REMOVE_ROW", index });
      } else {
        handleFFDeleteConfirmation(index, id)
      }
    // }
    setFFD(state.frequentFlyerData);
  };

  const handleFFDeleteConfirmation = (index, id) => {
    Modal.confirm({
      title: (context.translation && context.translation.uWantdel),
      okText: (context.translation && context.translation.yes),
      cancelText: (context.translation && context.translation.no),
      onOk: () => {
        deleteFF(index, id);
      },
      onCancel: () => {
        handleCancel();
      },
    });
  };  

  const deleteFF = (index, id) => {
    deleteFrequentFlyer(id).then((response) => {
      if (response) {
        dispatch({ type: "notiMessageShow", payload: true });
        dispatch({ type: "notiMessage", payload: (context.translation && context.translation.ffdelSucc) });
        dispatch({ type: "notiVarient", payload: "error" });
        hidemessage();
        dispatch({ type: "REMOVE_ROW", index });
      }
    }).catch(function (error) {
      console.error("Error:" + error);      
    });
  };

  const handleCancel = () => {
  };

  const handleAddDocumentRow = () => {
    dispatch({ type: "ADD_DOCUMENT_ROW" });
  };

  const handleRemoveDocumentRow = (index) => {
    dispatch({ type: "REMOVE_DOCUMENT_ROW", index });
  };

  const editProfileBasicInfo = (obj) => {
    setDisableBasicProfile(false);
    setDisableContactDetails(true);
    setDisableTravelDocs(true);
    setDisableFrequentFlyerData(true);
    dispatch({ type: "enableProfileBasicInfo", payload: true });
    dispatch({ type: "enableProfileContactDetails", payload: false });
    dispatch({ type: "enableProfileTravelDocuments", payload: false });
    dispatch({ type: "enableProfileFrequentFlyer", payload: false });
    dispatch({ type: "contactDetails", payload: false });
    dispatch({ type: "frequentFlyer", payload: false });
    dispatch({ type: "travelDocument", payload: false });
  };

  const editProfileContactDetails = (obj) => {
    setDisableContactDetails(false);
    setDisableBasicProfile(true);
    setDisableTravelDocs(true);
    setDisableFrequentFlyerData(true);
    dispatch({ type: "enableProfileContactDetails", payload: true });
    dispatch({ type: "enableProfileBasicInfo", payload: false });
    dispatch({ type: "enableProfileTravelDocuments", payload: false });
    dispatch({ type: "enableProfileFrequentFlyer", payload: false });
    dispatch({ type: "basicError", payload: false });
    dispatch({ type: "frequentFlyer", payload: false });
    dispatch({ type: "travelDocument", payload: false });
  };

  const editProfileTravelDocument = (obj) => {
    setDisableTravelDocs(false);
    setDisableBasicProfile(true);
    setDisableFrequentFlyerData(true);
    setDisableContactDetails(true);
    dispatch({ type: "enableProfileTravelDocuments", payload: true });
    dispatch({ type: "enableProfileContactDetails", payload: false });
    dispatch({ type: "enableProfileBasicInfo", payload: false });
    dispatch({ type: "enableProfileFrequentFlyer", payload: false });
    dispatch({ type: "contactDetails", payload: false });
    dispatch({ type: "frequentFlyer", payload: false });
    dispatch({ type: "basicError", payload: false });
  };

  const editProfileFrequentFlyer = (obj) => {
    setDisableFrequentFlyerData(false);
    setDisableBasicProfile(true);
    setDisableTravelDocs(true);
    setDisableContactDetails(true);
    dispatch({ type: "enableProfileFrequentFlyer", payload: true });
    dispatch({ type: "enableProfileTravelDocuments", payload: false });
    dispatch({ type: "enableProfileContactDetails", payload: false });
    dispatch({ type: "enableProfileBasicInfo", payload: false });
    dispatch({ type: "travelDocument", payload: false });
    dispatch({ type: "basicError", payload: false });
    dispatch({ type: "contactDetails", payload: false });
  };

  const handleCancelProfileBasicInfo = () => {
    setDisableBasicProfile(true);
    dispatch({ type: "enableProfileBasicInfo", payload: false });
    dispatch({ type: "basicError", payload: false });
  };

  const handleCancelContactDetails = () => {
    setDisableContactDetails(true);
    setDisableBasicProfile(false);
    dispatch({ type: "enableProfileContactDetails", payload: false });
    dispatch({ type: "enableProfileBasicInfo", payload: true });
    dispatch({ type: "contactDetails", payload: false });
  };

  const handleCancelProfileTravelDocuments = () => {
    setDisableTravelDocs(true);
    setDisableBasicProfile(false);
    dispatch({ type: "enableProfileTravelDocuments", payload: false });
    dispatch({ type: "enableProfileBasicInfo", payload: true });
    dispatch({ type: "travelDocument", payload: false });
  };

  const handleCancelProfileFrequentFlyer = () => {
    setDisableFrequentFlyerData(true);
    setDisableBasicProfile(false);
    dispatch({ type: "enableProfileFrequentFlyer", payload: false });
    dispatch({ type: "enableProfileBasicInfo", payload: true });
    dispatch({ type: "frequentFlyer", payload: false });
  };

  const validateAnniversaryDate = (date) => {
    const dateString = date;
    const anniversaryDate = new Date(dateString);

    // Get the current date
    const currentDate = new Date();

    // Check if the Anniversary date is greater than current date
    if (anniversaryDate > currentDate) {
      dispatch({ type: "notiMessageShow", payload: true });
      dispatch({ type: "notiMessage", payload: (context.translation && context.translation.anivDateshnot) });
      dispatch({ type: "notiVarient", payload: "error" });
      hidemessage();
      return false;
    } else if (state.dateOfBirth.month && state.dateOfBirth.day && state.dateOfBirth.year) {
      // "year-month-day" (e.g., "2021-04-3")
      const adultDob = `${state.dateOfBirth.year}-${state.dateOfBirth.month}-${state.dateOfBirth.day}`
      
      const DobString = adultDob;
      const Dob = new Date(DobString);

      if (anniversaryDate < Dob) {
        dispatch({ type: "notiMessageShow", payload: true });
        dispatch({ type: "notiMessage", payload: (context.translation && context.translation.anivDatebirth) });
        dispatch({ type: "notiVarient", payload: "error" });
        hidemessage();
        return false;
      } else {
        return true
      }
    } else {
      return true;
    }
  }

  /**
   * @description:User Profile Validating Form
   * @author: Ubedullah
   * @function Validating Form
   * @date : 21-06-2023
   */

  const validateUserProfile = (type) => {
    if (type === "profile" && type !== "contact") {
      if(state.title === "") {   
        dispatch({ type: "basicError", payload: true });      
        // dispatch({ type: "notiMessageShow", payload: true });
        // dispatch({ type: "notiMessage", payload: "Please select title" });
        // dispatch({ type: "notiVarient", payload: "error" });
        hidemessage();
        return false;
      } else if (state.firstName === "") {
        // setAlert(true);
        dispatch({ type: "basicError", payload: true });    
        // dispatch({ type: "notiMessageShow", payload: true });
        // dispatch({ type: "notiMessage", payload: "Please enter firstname" });
        // dispatch({ type: "notiVarient", payload: "error" });
        hidemessage();
        return false;
      } else if (state.dateOfBirth.month === "") {
        // setAlert(true);
        dispatch({ type: "basicError", payload: true });  
        // dispatch({ type: "notiMessageShow", payload: true });
        // dispatch({ type: "notiMessage", payload: "Please select month" });
        // dispatch({ type: "notiVarient", payload: "error" });
        hidemessage();
        return false;
      } else if (state.dateOfBirth.day === "") {
        // setAlert(true);
        dispatch({ type: "basicError", payload: true });
        // dispatch({ type: "notiMessageShow", payload: true });
        // dispatch({ type: "notiMessage", payload: "Please select day" });
        // dispatch({ type: "notiVarient", payload: "error" });
        hidemessage();
        return false;
      } else if (state.dateOfBirth.year === "") {
        // setAlert(true);
        dispatch({ type: "basicError", payload: true });
        // dispatch({ type: "notiMessageShow", payload: true });
        // dispatch({ type: "notiMessage", payload: "Please select year" });
        // dispatch({ type: "notiVarient", payload: "error" });
        hidemessage();
        return false;
      } else if (state.nationality === "") {
        // setAlert(true);
        dispatch({ type: "basicError", payload: true });
        // dispatch({ type: "notiMessageShow", payload: true });
        // dispatch({ type: "notiMessage", payload: "Please select nationality" });
        // dispatch({ type: "notiVarient", payload: "error" });
        hidemessage();
        return false;
      } else if (state.maritalStatus === "Married") {
        // setAlert(true);
        dispatch({ type: "basicError", payload: true });
        if(state.anniversary.month == "") {
          // dispatch({ type: "notiMessageShow", payload: true });
          // dispatch({ type: "notiMessage", payload: "Please select anniversary month" });
          // dispatch({ type: "notiVarient", payload: "error" });
          hidemessage();
          return false;
        } else if (state.anniversary.day == "") {
          // dispatch({ type: "notiMessageShow", payload: true });
          // dispatch({ type: "notiMessage", payload: "Please select anniversary day" });
          // dispatch({ type: "notiVarient", payload: "error" });
          hidemessage();
          return false;
        } else if (state.anniversary.year == "") {
          // dispatch({ type: "notiMessageShow", payload: true });
          // dispatch({ type: "notiMessage", payload: "Please select anniversary year" });
          // dispatch({ type: "notiVarient", payload: "error" });
          hidemessage();
          return false;
        }
      } 
      if (state.dateOfBirth.month && state.dateOfBirth.day && state.dateOfBirth.year) {
        // "year-month-day" (e.g., "2021-04-3")
        const adultDob = `${state.dateOfBirth.year}-${state.dateOfBirth.month}-${state.dateOfBirth.day}`
        
        const DobString = adultDob;
        const Dob = new Date(DobString);

        // Get the current date
        const currentDate = new Date();
        // Calculate the difference between the current date and the date of birth in milliseconds
        const differenceInMs = currentDate - Dob;

        // Calculate the difference in years
        const differenceInYears =
          differenceInMs / (1000 * 60 * 60 * 24 * 365.25);

        // Check if the date of birth is greater than 2 years from the current date
        if (differenceInYears < 12) {
          dispatch({ type: "notiMessageShow", payload: true });
          dispatch({ type: "notiMessage", payload: (context.translation && context.translation.dobirthgreater) });
          dispatch({ type: "notiVarient", payload: "error" });
          hidemessage();
          return false;
        }
      }
      if (state.maritalStatus === "Married") {
        if(state.anniversary.month && state.anniversary.day && state.anniversary.year) {
          // "year-month-day" (e.g., "2021-04-3")
          const AnniversaryDate = `${state.anniversary.year}-${state.anniversary.month}-${state.anniversary.day}`
          return validateAnniversaryDate(AnniversaryDate);
        }
      }
      return true;
    }

    if (type === "contact" ? "profile" : "") {
      if (state.contactNumber.countryCode === "") {
        // setAlert(true);
        dispatch({ type: "contactDetails", payload: true });        
        // dispatch({ type: "notiMessageShow", payload: true });
        // dispatch({ type: "notiMessage", payload: "Please select country code" });
        // dispatch({ type: "notiVarient", payload: "error" });
        hidemessage();
        return false;
      } else if (state.contactNumber.number === "") {
        // setAlert(true);
        dispatch({ type: "contactDetails", payload: true });        
        // dispatch({ type: "notiMessageShow", payload: true });
        // dispatch({ type: "notiMessage", payload: "Please enter contact number" });
        // dispatch({ type: "notiVarient", payload: "error" });
        hidemessage();
        return false;
      } else if (state.contactNumber.number.length < 6) {
        // setAlert(true);
        dispatch({ type: "contactDetails", payload: true });        
        dispatch({ type: "notiMessageShow", payload: true });
        dispatch({ type: "notiMessage", payload: (context.translation && context.translation.sixNmax) });
        dispatch({ type: "notiVarient", payload: "error" });
        hidemessage();
        return false;
      } else if (state.contactNumber.number.length > 14) {
        // setAlert(true);
        dispatch({ type: "contactDetails", payload: true });        
        dispatch({ type: "notiMessageShow", payload: true });
        dispatch({ type: "notiMessage", payload: (context.translation && context.translation.sixNmax) });
        dispatch({ type: "notiVarient", payload: "error" });
        hidemessage();
        return false;
      }
      return true;
    }
    if (type === "docs") {
      // if(state.travelDocuments[0].passport_no.length !== 0) {
        if (state.travelDocuments[0].passport_no === "") {
          // setAlert(true);
          // dispatch({ type: "basicError", payload: true });       
          // dispatch({ type: "notiMessageShow", payload: true });
          // dispatch({ type: "notiMessage", payload: "Please enter passport number" });
          // dispatch({ type: "notiVarient", payload: "error" });
          // hidemessage();
          return false;
        } else if (state.travelDocuments[0].issue_cnt === "") {
          // setAlert(true);
          dispatch({ type: "travelDocument", payload: true });         
          // dispatch({ type: "notiMessageShow", payload: true });
          // dispatch({ type: "notiMessage", payload: "Please select issuing country" });
          // dispatch({ type: "notiVarient", payload: "error" });
          hidemessage();
          return false;
        } else if (state.travelDocuments[0].issue_date.month === "") {
          // setAlert(true);
          dispatch({ type: "travelDocument", payload: true });          
          // dispatch({ type: "notiMessageShow", payload: true });
          // dispatch({ type: "notiMessage", payload: "Please select passport issuing month" });
          // dispatch({ type: "notiVarient", payload: "error" });
          hidemessage();
          return false;
        } else if (state.travelDocuments[0].issue_date.day === "") {
          // setAlert(true);
          dispatch({ type: "travelDocument", payload: true });      
          // dispatch({ type: "notiMessageShow", payload: true });
          // dispatch({ type: "notiMessage", payload: "Please select passport issuing day" });
          // dispatch({ type: "notiVarient", payload: "error" });
          hidemessage();
          return false;
        } else if (state.travelDocuments[0].issue_date.year === "") {
          // setAlert(true);
          dispatch({ type: "travelDocument", payload: true }); 
          // dispatch({ type: "notiMessageShow", payload: true });
          // dispatch({ type: "notiMessage", payload: "Please select passport issuing year" });
          // dispatch({ type: "notiVarient", payload: "error" });
          hidemessage();
          return false;
        } else if (state.travelDocuments[0].exp_date.month === "") {
          // setAlert(true);
          dispatch({ type: "travelDocument", payload: true }); 
          // dispatch({ type: "notiMessageShow", payload: true });
          // dispatch({ type: "notiMessage", payload: "Please select passport expiry month" });
          // dispatch({ type: "notiVarient", payload: "error" });
          hidemessage();
          return false;
        } else if (state.travelDocuments[0].exp_date.day === "") {
          // setAlert(true);
          dispatch({ type: "travelDocument", payload: true });
          // dispatch({ type: "notiMessageShow", payload: true });
          // dispatch({ type: "notiMessage", payload: "Please select passport expiry day" });
          // dispatch({ type: "notiVarient", payload: "error" });
          hidemessage();
          return false;
        } else if (state.travelDocuments[0].exp_date.year === "") {
          // setAlert(true);
          dispatch({ type: "travelDocument", payload: true });
          // dispatch({ type: "notiMessageShow", payload: true });
          // dispatch({ type: "notiMessage", payload: "Please select passport expiry year" });
          // dispatch({ type: "notiVarient", payload: "error" });
          hidemessage();
          return false;
        }       
        if (state.travelDocuments[0].issue_date.month && 
          state.travelDocuments[0].issue_date.day && 
          state.travelDocuments[0].issue_date.year) {
          // "year-month-day" (e.g., "2021-04-3")
          const issuingDate = `${state.travelDocuments[0].issue_date.year}-${state.travelDocuments[0].issue_date.month}-${state.travelDocuments[0].issue_date.day}`
          
          const dateString = issuingDate;
          const istdate = new Date(dateString);

          // Get the current date
          const currentDate = new Date();

          // Check if the date of birth is greater than 2 years from the current date
          if (istdate > currentDate) {
            dispatch({ type: "notiMessageShow", payload: true });
            dispatch({ type: "notiMessage", payload: (context.translation && context.translation.passGrestercurr) });
            dispatch({ type: "notiVarient", payload: "error" });
            hidemessage();
            return false;
          }
        }       
        if (state.travelDocuments[0].exp_date.month && 
          state.travelDocuments[0].exp_date.day && 
          state.travelDocuments[0].exp_date.year) {
          // "year-month-day" (e.g., "2021-04-3")
          const expiryDate = `${state.travelDocuments[0].exp_date.year}-${state.travelDocuments[0].exp_date.month}-${state.travelDocuments[0].exp_date.day}`
          
          const dateString = expiryDate;
          const expdate = new Date(dateString);

          // Get the current date
          const currentDate = new Date();

          // Check if the date of birth is greater than 2 years from the current date
          if (expdate < currentDate) {
            dispatch({ type: "notiMessageShow", payload: true });
            dispatch({ type: "notiMessage", payload: (context.translation && context.translation.passexpdateles) });
            dispatch({ type: "notiVarient", payload: "error" });
            hidemessage();
            return false;
          }
        // }      
      return true;  
      }
    }
    if (type === "ffp") {
      if (state.frequentFlyerData.length > 0) {
        for (let val of state.frequentFlyerData) {
            if (val.airline_name === "" || val.airline_name === null || val.airline_name === undefined) {
              // setAlert(true);
              // dispatch({ type: "frequentFlyer", payload: true });
              // dispatch({ type: "notiMessageShow", payload: true });
              // dispatch({ type: "notiMessage", payload: "Please enter airline name" });
              // dispatch({ type: "notiVarient", payload: "error" });
              // hidemessage();
              return false;
            } else if (val.ffp === "" || val.ffp === null || val.ffp === undefined) {
              // setAlert(true);
              dispatch({ type: "frequentFlyer", payload: true });
              // dispatch({ type: "notiMessageShow", payload: true });
              // dispatch({ type: "notiMessage", payload: "Please enter frequent flyer program" });
              // dispatch({ type: "notiVarient", payload: "error" });
              hidemessage();
              return false;
            } else if (val.ffn === "" || val.ffn === null || val.ffn === undefined) {
              // setAlert(true);
              dispatch({ type: "frequentFlyer", payload: true });
              // dispatch({ type: "notiMessageShow", payload: true });
              // dispatch({ type: "notiMessage", payload: "Please enter frequent flyer number" });
              // dispatch({ type: "notiVarient", payload: "error" });
              hidemessage();
              return false;
            }
          }          
        }
        if(state.frequentFlyerData.length > 1) {
          const existingAirlines = state.frequentFlyerData.slice(0, -1);
          const newAirline = state.frequentFlyerData.slice(-1);
          const comparing = existingAirlines.filter((airline) => airline.airline_name === newAirline[0].airline_name)
          if(comparing[0]?.airline_name === newAirline[0].airline_name) {
            dispatch({ type: "notiMessageShow", payload: true });
            dispatch({ type: "notiMessage", payload: (context.translation && context.translation.airlinealexist) });
            dispatch({ type: "notiVarient", payload: "error" });
            hidemessage();
            return false;
          }
      }
      return true;
    }
  };

  const handleProfileSave = (type) => () => {
    if (validateUserProfile(type)) {
      let dob;
      if (
        state.dateOfBirth.month &&
        state.dateOfBirth.day &&
        state.dateOfBirth.year
      ) {
        dob =
          state.dateOfBirth.month +
          "-" +
          state.dateOfBirth.day +
          "-" +
          state.dateOfBirth.year;
      }

      let anniversary, mrg_date;
      if (state.maritalStatus === "Married") {
        anniversary = state.maritalStatus;
        mrg_date = `${state.anniversary.month}-${state.anniversary.day}-${state.anniversary.year}`
      }

      const { month, day, year } = state.travelDocuments[0].issue_date;
      const dateString = `${month}-${day}-${year}`;

      const {
        month: expiryMonth,
        day: expiryDay,
        year: expiryYear,
      } = state.travelDocuments[0].exp_date;
      const dateString1 = `${expiryMonth}-${expiryDay}-${expiryYear}`;

      state.travelDocuments[0].issue_date = dateString;
      state.travelDocuments[0].exp_date = dateString1;
      let payload;
      var proType = "";
      if (true) {
        if (type === "profile") {
          proType = (context.translation && context.translation.profile);
          payload = {
            update_type: "profile",
            usr_uniq_id: userUniqId,
            // usr_id: state.id,
            title: state.title,
            fn: state.firstName,
            ln: state.lastName,
            dob: dob,
            na: state.nationality,
            mstatus: state.maritalStatus,
            ph_code: state.contactNumber.countryCode,
            phno: state.contactNumber.number,
            email: state.email,
            mrg_date: mrg_date,
            // frq_fly: newFFD,
          };
        } else if (type === "contact" ? "profile" : "") {
          proType = type === "contact" ? (context.translation && context.translation.enqContactTitle) : (context.translation && context.translation.profile);
          payload = {
            update_type: type == "contact" ? "profile" : "",
            usr_uniq_id: userUniqId,
            // usr_id: state.id,
            title: state.title,
            fn: state.firstName,
            ln: state.lastName,
            dob: dob,
            na: state.nationality,
            mstatus: state.maritalStatus,
            ph_code: state.contactNumber.countryCode,
            phno: state.contactNumber.number,
            email: state.email,
            mrg_date: mrg_date,
            // frq_fly: newFFD,
          };
        } else if (type === "docs") {
          proType = (context.translation && context.translation.doc);
          payload = {
            update_type: type,
            usr_uniq_id: userUniqId,
            docs: state.travelDocuments,
          };
        } else if (type === "ffp") {
          // const unique_frq_fly = state.frequentFlyerData.filter(
          //   (obj) => obj.id === undefined
          // );
          proType = (context.translation && context.translation.ff);
          payload = {
            update_type: type,
            usr_uniq_id: userUniqId,
            frq_fly: state.frequentFlyerData,
            // frq_fly: newFFD,
          };
        }

        //Call Profile update API
        let tokenKey = Utilities.generateAPIToken(payload);
        updateProfile(payload,tokenKey).then((response) => {
          if (response.suc) {          
            dispatch({ type: "notiMessageShow", payload: true });
            dispatch({ type: "notiMessage", payload: proType + (context.translation && context.translation.detailsucc) });
            dispatch({ type: "notiVarient", payload: "success" });
            hidemessage();
            setTimeout(function () {
              window.location="/my-account/user-profile";
            }, 2000);
          } else {
            dispatch({ type: "notiMessageShow", payload: true });
            dispatch({ type: "notiMessage", payload: proType + (context.translation && context.translation.detailnotup) });
            dispatch({ type: "notiVarient", payload: "error" });
            hidemessage();
          }
        }).catch(function (error) {
          console.error("Error:" + error);      
        });
      }
      // const data = JSON.parse(localStorage.getItem("SIGNINRESPONSE"));
      // try {
      //   if (data !== null) {
      //     const payload = {
      //       email: data.res.email,
      //     };
      //     let tokenKey = Utilities.generateAPIToken(payload);
      //     getProfile(payload,tokenKey).then((response => {
      //       if(response.suc) {
      //         // Nothing doing
      //       }
      //     })).catch(function (error) {
      //       console.error("Error in Profile API:" + error);      
      //     });;
      //   }        
      // } catch (error) {
      //   console.error("Error in Profile API:", error);          
      // }
    }
  };

  const { Option } = Select;

  const update=(type)=>{
  }

  const homePage = () => {
    navigate("/");
  }
  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
    setshowMenu(!showMenu);
  };

  const onClose = () => {
    setOpen(false);
  };

  const closeMenu = () => {
    showDrawer();
  };

  if (context.user_resp === "" && localStorage.getItem("SIGNINRESPONSE") === null) {
    return ( 
        <Modal
          title=""
          open={true}
          width={460}
          footer={null}
          maskClosable={false}
        >
          <Title level={4}>
            {/* {context.translation && context.translation.plzlog} */}
            {(sessionStorage.getItem("SEL_LAG") === null || sessionStorage.getItem("SEL_LAG") === "English") &&
              "Please log in to access your profile"              
            }
            {sessionStorage.getItem("SEL_LAG") === "Arabic" &&
              "يرجى تسجيل الدخول لملفك الشخصي"              
            }
          </Title>

          <Button type="primary" className="cntBtn sendBtn" onClick={homePage}>
            {/* {context.translation && context.translation.backHg} */}
            {(sessionStorage.getItem("SEL_LAG") === null || sessionStorage.getItem("SEL_LAG") === "English") &&
              "Back to Home Page"              
            }
            {sessionStorage.getItem("SEL_LAG") === "Arabic" &&
              "عودة للصفحة الرئيسية"              
            }
          </Button>
        </Modal>
    );
  }

  return (
    <>
      {state.notiMessageShow ? (
        <Alert
          description={state.notiMessage}
          type={state.notiVarient}
          closable
          onClose={() => dispatch({ type: "notiMessageShow", payload: false })}
          className="alertForm"
          // showIcon
        >
          {" "}
          {state.notiMessage}
        </Alert>
      ) : (
        ""
      )}
      {/* {contextHolder} */}
      <Header setTab={update}/>
      <div className="myAccountPage">
        <div className="myAccountBanner"></div>
        <div className="container">
          {/* <div className="sideMenuBar">
            <div
              onClick={showDrawer}
              className={"sideMenu btn5 " + (showMenu && "active")}
              data-menu="5"
              closeMenus={closeMenu}
            >
              <div className="icon"></div>
            </div>
          </div>
          <Drawer placement="left" open={open} className="navDrawer">
            <div>
              <i className="icon icon-cancel" onClick={onClose}></i>
            </div>
            <Sidebar active="userProfile" />
          </Drawer> */}
          <Row>
            <Col md={5} className="firstChild">
              <Sidebar active="userProfile" />
            </Col>
            <Col md={19} className="lastChild">
              <div className="cardDetails myProfileDetails">
                <div className="cardDetailsHeader">
                  <div>
                    <Title level={4}>{context.translation && context.translation.myProf}</Title>
                    <p>{context.translation && context.translation.updateurperdtl}</p>
                  </div>
                  <div>
                    {!state.enableProfileBasicInfo && (
                      <Button
                        type="secondary"
                        className="editBtn"
                        ghost
                        onClick={() => editProfileBasicInfo()}
                      >
                        <i className="icon icon-Edit"></i> {context.translation && context.translation.edit}
                      </Button>
                    )}
                  </div>
                </div>
                <div className="cardDetailsBody">
                  <Form layout="vertical">
                    <Row gutter={16} className="noTopMargin">
                      <Col span={8}>
                        <Form.Item className="pRelative">
                          <label className="requiredField">{context.translation && context.translation.fn}</label>
                          <Input
                            addonBefore={
                              <Select
                                name="title"
                                value={state.title?state.title:undefined}
                                // defaultValue={context.translation && context.translation.select}
                                defaultValue={context.translation && context.translation.select ? context.translation.select : "Select"}
                                disabled={disableBasicProfile}
                                onChange={(value) =>
                                  dispatch({
                                    type: "UPDATE_FIELD",
                                    field: "title",
                                    value,
                                  })
                                }
                              >
                               { (context.selectLang==="en"||context.selectLang===undefined)?(
                                      <> <Option value="Mr">Mr</Option>
                                      <Option value="Mrs">Mrs</Option>
                                      <Option value="Miss">Miss</Option>
                                      <Option value="Ms">Ms</Option>
                                      </>
                                    )
                                :
                                (
                                  <>
                                   <Option value="Mr">سيد</Option>
                                <Option value="Mrs">آنسة</Option>
                                <Option value="Miss">سيدة</Option>
                                </>
                                )
}
                              </Select>
                            }
                            // className="selectBoxShadow"
                            className={
                              "selectBoxShadow " +
                              (state.basicError && state.firstName === "" ? "errorForm" : "")
                            }
                            type="text"
                            name="firstName"
                            value={state.firstName}
                            disabled={disableBasicProfile}
                            onChange={handleChange}
                            placeholder={context.translation && context.translation.efn}
                          />
                          <i className="icon icon-Alert"></i>
                          {/* <Input addonBefore={selectBefore} className='selectBoxShadow' placeholder="Enter First Name" type="text" name="firstName" value={formData.firstName} onChange={handleChange}/> */}
                        </Form.Item>
                        {state.title === "" &&
                          state.basicError && (
                            <div className="error">{context.translation && context.translation.plzSelect}</div>
                          )}
                        {state.title !== "" && state.firstName === "" && state.basicError && (
                          <div className="error">{context.translation && context.translation.plzEnter}</div>
                        )}
                      </Col>
                      <Col span={8} className="lastNameLabel">
                        <Form.Item label={context.translation && context.translation.ln}>
                          <Input
                            type="text"
                            className="innerBoxShadow"
                            name="lastName"
                            value={state.lastName}
                            disabled={disableBasicProfile}
                            onChange={handleChange}
                            placeholder={context.translation && context.translation.eln}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={8} className="addMargin">
                        <Form.Item>
                          <label className="requiredField">{context.translation && context.translation.dob}</label>
                          <div className="onlyFlex">
                            <div className="dateMY">
                              <Select
                                name="dateOfBirth.month"
                                value={state.dateOfBirth.month?state.dateOfBirth.month:undefined}
                                defaultValue={context.translation && context.translation.month}
                                disabled={disableBasicProfile}
                                // className="selectBoxShadow mdy month"
                                className={
                                  "selectBoxShadow mdy month " +
                                  (state.basicError && state.dateOfBirth.month === "" ? "errorForm" : "")
                                }
                                onChange={(value) =>
                                  handleDateFieldChange(
                                    "dateOfBirth",
                                    "month",
                                    value
                                  )
                                }
                                placeholder="Month"
                              >
                                {context.selectLang==="en"||context.selectLang=== undefined ? (
                                  <>
                                    {months.map((month) => (
                                      <Option
                                        key={month.value}
                                        value={month.value}
                                        name={month.value}
                                      >
                                        {month.label}
                                      </Option>
                                    ))}
                                  </>
                                ) : (
                                  <>
                                  {monthsArabic.map((month) => (
                                    <Option
                                      key={month.value}
                                      value={month.value}
                                      name={month.value}
                                    >
                                      {month.label}
                                    </Option>
                                  ))}
                                  </>
                                )}
                              </Select>
                              {state.dateOfBirth.month === "" &&
                                state.basicError && (
                                  <div className="error">{context.translation && context.translation.selectMonth}</div>
                                )}
                            </div>
                            <div className="dateMY">
                              <Select
                                name="dateOfBirth.day"
                                value={state.dateOfBirth.day?state.dateOfBirth.day:undefined}
                                defaultValue={context.translation && context.translation.day}
                                disabled={disableBasicProfile}
                                // className="selectBoxShadow mdy day"
                                className={
                                  "selectBoxShadow mdy day " +
                                  (state.basicError && state.dateOfBirth.day === "" ? "errorForm" : "")
                                }
                                onChange={(value) =>
                                  handleDateFieldChange(
                                    "dateOfBirth",
                                    "day",
                                    value
                                  )
                                }
                                placeholder="Day"
                              >
                                {state.dateOfBirth.month &&
                                  Array.from(
                                    {
                                      length: getDaysInMonth(
                                        state.dateOfBirth.month,
                                        state.dateOfBirth.year
                                      ),
                                    },
                                    (_, i) => i + 1
                                  ).map((day) => (
                                    <Option
                                      key={day}
                                      value={
                                        day.toString().length === 1
                                          ? "0" + day.toString()
                                          : day
                                      }
                                    >
                                      {day.toString().length === 1
                                        ? "0" + day.toString()
                                        : day}
                                    </Option>
                                  ))}
                              </Select>
                              {state.dateOfBirth.day === "" &&
                                state.basicError && (
                                  <div className="error">{context.translation && context.translation.selectDay}</div>
                                )}
                            </div>
                            <div className="dateMY">
                              <Select
                                name="dateOfBirth.year"
                                value={state.dateOfBirth.year?state.dateOfBirth.year:undefined}
                                defaultValue={context.translation && context.translation.year}
                                disabled={disableBasicProfile}
                                // className="selectBoxShadow mdy year"
                                className={
                                  "selectBoxShadow mdy year " +
                                  (state.basicError && state.dateOfBirth.year === "" ? "errorForm" : "")
                                }
                                onChange={(value) =>
                                  handleDateFieldChange(
                                    "dateOfBirth",
                                    "year",
                                    value
                                  )
                                }
                                placeholder="Year"
                              >
                                {yearsAdult.map((year) => (
                                  <Option key={year} value={year}>
                                    {year}
                                  </Option>
                                ))}
                              </Select>
                              {state.dateOfBirth.year === "" &&
                                state.basicError && (
                                  <div className="error">{context.translation && context.translation.selectYear}</div>
                                )}
                            </div>
                          </div>
                        </Form.Item>
                      </Col>
                      <Col span={8} className="addTopMargin">
                        <Form.Item>
                          <label className="requiredField">{context.translation && context.translation.nn}</label>
                          {/* <Select
                            // showSearch
                            // optionFilterProp="children"
                            name="nationality"
                            value={state.nationality}
                            className="selectBoxShadow"
                            disabled={disableBasicProfile}
                            placeholder={context.translation && context.translation.select}
                            defaultValue={context.translation && context.translation.select}
                            options={countrieslist}
                            onChange={(value) =>
                              dispatch({
                                type: "UPDATE_FIELD",
                                field: "nationality",
                                value,
                              })
                            }
                          ></Select> */}

                          <Select
                            showSearch
                            defaultValue={context.translation && context.translation.select ? context.translation.select : "Select"}
                            name="nationality"
                            value={state.nationality?state.nationality:undefined}
                            // className="selectBoxShadow"
                            className={
                              "selectBoxShadow " +
                              (state.basicError && state.nationality === "" ? "errorForm" : "")
                            }
                            disabled={disableBasicProfile}
                            onChange={(value) =>
                              dispatch({
                                type: "UPDATE_FIELD",
                                field: "nationality",
                                value,
                              })
                            }
                            filterOption={(input, option) =>
                              (option?.label ?? "")
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            optionFilterProp="children"
                            options={countrieslist}
                          />

                          {/* <select
                            name="nationality"
                            value={state.nationality}
                            className="selectBoxShadow"
                            onChange={handleChange}
                          >
                            <option>Select</option>
                            {!!countryArr?.length &&
                              countryArr.map(({ label, value }) => (
                                <option key={value} value={value}>
                                  {label}
                                </option>
                              ))}
                          </select> */}
                          {state.nationality === "" && state.basicError && (
                            <div className="error">
                              {context.translation && context.translation.selectNn}
                            </div>
                          )}
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={16} className="maritalStatusDivider">
                      <Col span={8}>
                        <Form.Item label={context.translation && context.translation.maritalStatus}>
                          <Select
                            name="maritalStatus"
                            // defaultValue={context.translation && context.translation.select}
                            defaultValue={context.translation && context.translation.select ? context.translation.select : "Select"}
                            value={state.maritalStatus?state.maritalStatus:undefined}
                            // className="selectBoxShadow"
                            className={
                              "selectBoxShadow " +
                              (state.maritalStatus === "" && state.basicError ? "errorForm" : "")
                            }
                            disabled={disableBasicProfile}
                            onChange={(value) =>
                              dispatch({
                                type: "UPDATE_FIELD",
                                field: "maritalStatus",
                                value,
                              })
                            }
                          >
                            <Option value="Select">Select</Option>
                            <Option value="Single">{context.translation && context.translation.single}</Option>
                            <Option value="Married">{context.translation && context.translation.married}</Option>
                            {/* <Option value="Widowed">Widowed</Option>
                            <Option value="Separated">Separated</Option>
                            <Option value="Divorced">Divorced</Option> */}
                          </Select>
                          {/* {state.maritalStatus === "" && state.basicError && (
                            <div className="error">
                              Please select marital status.
                            </div>
                          )} */}
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        {state.maritalStatus === "Married" && (
                          <Form.Item label={context.translation && context.translation.anni}>
                            <div className="onlyFlex">
                              <div className="dateMY">
                                <Select
                                  name="anniversary.month"
                                  value={state.anniversary.month?state.anniversary.month:undefined}
                                  defaultValue={context.translation && context.translation.month}
                                  // className="selectBoxShadow mdy month"
                                  className={
                                    "selectBoxShadow mdy month " +
                                    (state.basicError && state.anniversary.month === "" ? "errorForm" : "")
                                  }
                                  disabled={disableBasicProfile}
                                  onChange={(value) =>
                                    handleDateFieldChange(
                                      "anniversary",
                                      "month",
                                      value
                                    )
                                  }
                                  placeholder="Month"
                                >
                                {context.selectLang==="en"||context.selectLang=== undefined ? (
                                  <>
                                    {months.map((month) => (
                                      <Option
                                      key={month.value}
                                      value={month.value}
                                      >
                                        {month.label}
                                      </Option>
                                    ))}
                                  </>
                                ) : (
                                  <>
                                  {monthsArabic.map((month) => (
                                    <Option
                                      key={month.value}
                                      value={month.value}
                                    >
                                      {month.label}
                                    </Option>
                                  ))}
                                  </>
                                )}
                                </Select>
                                {state.anniversary.month === "" &&
                                  state.basicError && (
                                    <div className="error">{context.translation && context.translation.selectMonth}</div>
                                  )}
                              </div>
                              <div className="dateMY">
                                <Select
                                  name="anniversary.day"
                                  value={state.anniversary.day?state.anniversary.day:undefined}
                                  defaultValue={context.translation && context.translation.day}
                                  // className="selectBoxShadow mdy day"
                                  className={
                                    "selectBoxShadow mdy day " +
                                    (state.basicError && state.anniversary.day === "" ? "errorForm" : "")
                                  }
                                  disabled={disableBasicProfile}
                                  onChange={(value) =>
                                    handleDateFieldChange(
                                      "anniversary",
                                      "day",
                                      value
                                    )
                                  }
                                  placeholder="Day"
                                >
                                  {state.anniversary.month &&
                                    Array.from(
                                      {
                                        length: getDaysInMonth(
                                          state.anniversary.month,
                                          state.anniversary.year
                                        ),
                                      },
                                      (_, i) => i + 1
                                    ).map((day) => (
                                      <Option
                                        key={day}
                                        value={
                                          day.toString().length === 1
                                            ? "0" + day.toString()
                                            : day
                                        }
                                      >
                                        {day.toString().length === 1
                                          ? "0" + day.toString()
                                          : day}
                                      </Option>
                                    ))}
                                </Select>
                                {state.anniversary.day === "" &&
                                  state.basicError && (
                                    <div className="error">{context.translation && context.translation.selectDay}</div>
                                  )}
                              </div>
                              <div className="dateMY">
                                <Select
                                  name="anniversary.year"
                                  value={state.anniversary.year?state.anniversary.year:undefined}
                                  defaultValue={context.translation && context.translation.year}
                                  // className="selectBoxShadow mdy year"
                                  className={
                                    "selectBoxShadow mdy year " +
                                    (state.basicError && state.anniversary.year === "" ? "errorForm" : "")
                                  }
                                  disabled={disableBasicProfile}
                                  onChange={(value) =>
                                    handleDateFieldChange(
                                      "anniversary",
                                      "year",
                                      value
                                    )
                                  }
                                  placeholder="Year"
                                >
                                  <Option value="">{context.translation && context.translation.year}</Option>
                                  {years.map((year) => (
                                    <Option key={year} value={year}>
                                      {year}
                                    </Option>
                                  ))}
                                </Select>
                                {state.anniversary.year === "" &&
                                  state.basicError && (
                                    <div className="error">{context.translation && context.translation.selectYear}</div>
                                  )}
                              </div>
                            </div>
                          </Form.Item>
                        )}
                      </Col>
                    </Row>
                  </Form>
                </div>
                {state.enableProfileBasicInfo && state.showSave && (
                  <div className="cardDetailsFooter">
                    <Button
                      type="secondary"
                      className="cancelBtn"
                      ghost
                      onClick={handleCancelProfileBasicInfo}
                    >
                      {context.translation && context.translation.cancel}
                    </Button>
                    <Button
                      type="primary"
                      className="cntBtn"
                      onClick={handleProfileSave("profile")}
                    >
                      {/* <span>{loading ? "" : "Save"}</span> */}
                      <span>{context.translation && context.translation.save}</span>
                      {/* <i className="sprite btn-arrow-sprite"></i> */}
                    </Button>
                  </div>
                )}
              </div>
              <div className="cardDetails contactdDetails">
                <div className="cardDetailsHeader">
                  <div>
                    <Title level={4}>{context.translation && context.translation.contactDetails}</Title>
                    <p>{context.translation && context.translation.numNpass}</p>
                  </div>
                  <div>
                    {!state.enableProfileContactDetails && (
                      <Button
                        type="secondary"
                        className="editBtn"
                        ghost
                        onClick={() => editProfileContactDetails()}
                      >
                        <i className="icon icon-Edit"></i> {context.translation && context.translation.edit}
                      </Button>
                    )}
                  </div>
                </div>

                <div className="cardDetailsBody">
                  <Form layout="vertical">
                    <Row gutter={16} className="noTopMargin">
                      <Col span={8}>
                        <Form.Item>
                          <label className="requiredField">{context.translation && context.translation.em}</label>
                          <Input
                            type="email"
                            name="email"
                            disabled={true}
                            className="innerBoxShadow"
                            value={state.email}
                            onChange={handleChange}
                            placeholder={context.translation && context.translation.eem}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={8} className="spaceBet20">
                        <Form.Item className="pRelative">
                          <label className="requiredField">
                            {context.translation && context.translation.ph}
                          </label>
                          <Input
                            addonBefore={
                              <Select
                                optionLabelProp="value"
                                disabled={disableContactDetails}
                                popupClassName="myAccContactDpDown"
                                name="contactNumber.countryCode"
                                showSearch
                                // defaultValue={context.translation && context.translation.select}
                                defaultValue={context.translation && context.translation.select ? context.translation.select : "Select"}
                                value={state.contactNumber.countryCode?state.contactNumber.countryCode:undefined}
                                onChange={(value) =>
                                  handleDateFieldChange(
                                    "contactNumber",
                                    "countryCode",
                                    value
                                  )
                                }
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  (option?.label ?? "")
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                                }
                                options={stdCodes}
                              />
                            }
                            disabled={disableContactDetails}
                            // className="selectBoxShadow enterContactNumber"
                            className={
                              "selectBoxShadow enterContactNumber " +
                              (state.contactDetails && state.contactNumber.countryCode === "" ? "errorForm" : "")
                            }
                            type="number"
                            name="contactNumber.number"
                            value={state.contactNumber.number}
                            placeholder={context.translation && context.translation.eph}
                            onChange={handleChange}
                            maxLength={14}
                          />
                        </Form.Item>
                        {state.contactNumber.countryCode === "" &&
                          state.contactDetails && (
                            <div className="error">{context.translation && context.translation.plzCc}</div>
                          )}
                        {state.contactNumber.number === "" &&
                          state.contactNumber.countryCode !== "" &&
                          state.contactDetails && (
                            <div className="error">
                              {context.translation && context.translation.peph}
                            </div>
                          )}
                      </Col>
                    </Row>
                  </Form>
                </div>
                {state.enableProfileContactDetails && state.showSave && (
                  <div className="cardDetailsFooter">
                    <Button
                      type="secondary"
                      className="cancelBtn"
                      ghost
                      onClick={handleCancelContactDetails}
                    >
                      {context.translation && context.translation.cancel}
                    </Button>
                    <Button
                      type="primary"
                      className="cntBtn"
                      onClick={handleProfileSave("contact")}
                    >
                      <span>{context.translation && context.translation.save}</span>{" "}
                      {/* <i className="sprite btn-arrow-sprite"></i> */}
                    </Button>
                  </div>
                )}
              </div>
              <div className="cardDetails travelDetails">
                <div className="cardDetailsHeader">
                  <div>
                    <Title level={4}>{context.translation && context.translation.travelDoc}</Title>
                    <p>{context.translation && context.translation.addurDoc}</p>
                  </div>
                  <div>
                    {!state.enableProfileTravelDocuments && (
                      <Button
                        type="secondary"
                        className="editBtn"
                        onClick={() => editProfileTravelDocument()}
                        ghost
                      >
                        <i className="icon icon-Edit"></i> {context.translation && context.translation.edit}
                      </Button>
                    )}
                  </div>
                </div>
                <div className="cardDetailsBody">
                  <Form layout="vertical">
                    {state.travelDocuments.map((data, index) => (
                      <Row key={index} gutter={16} className="noTopMargin">
                        {/* <Col span={8}> */}
                        {/* <label>
                                  Document Type:
                                  <select
                                    name={`travelDocuments[${index}].doc_type`}
                                    value={data.doc_type || ""}
                                    onChange={(e) =>
                                      dispatch({
                                        type: "UPDATE_TRAVEL_DOCUMENT_FIELD",
                                        index,
                                        field: "doc_type",
                                        value: e.target.value
                                      })
                                    }
                                  >
                                    <Option value="">Select</Option>
                                    <Option value="passport">Passport</Option>
                                    <Option value="id_card">ID Card</Option>
                                    <Option value="driver_license">Driver's License</Option>
                                  </select>
                                </label> */}
                        {/* </Col> */}

                        <Col span={8}>
                          <Form.Item label={context.translation && context.translation.pn}>
                            <Input
                              type="text"
                              name={`travelDocuments[${index}].passport_no`}
                              value={data.passport_no || ""}
                              // className="innerBoxShadow"
                              className={
                                "innerBoxShadow " +
                                (state.travelDocument && data.passport_no === "" ? "errorForm" : "")
                              }
                              disabled={disableTravelDocs}
                              placeholder={context.translation && context.translation.epn}
                              onChange={(e) =>
                                dispatch({
                                  type: "UPDATE_TRAVEL_DOCUMENT_FIELD",
                                  index,
                                  field: "passport_no",
                                  value: e.target.value,
                                })
                              }
                            />
                          </Form.Item>
                          {data.passport_no === "" && state.travelDocument && (
                            <div className="error">
                              {context.translation && context.translation.pepn}
                            </div>
                          )}
                        </Col>

                        <Col span={8} className="spaceBet20">
                          <Form.Item label={context.translation && context.translation.issCountry}>
                            <Select
                              showSearch
                              // placeholder={context.translation && context.translation.select}
                              defaultValue={context.translation && context.translation.select ? context.translation.select : "Select"}
                              // allowClear
                              // showSearch
                              // optionFilterProp="children"
                              name={`travelDocuments[${index}].issue_cnt`}
                              value={data.issue_cnt?data.issue_cnt:undefined}
                              // className="selectBoxShadow"
                              className={
                                "selectBoxShadow " +
                                (state.travelDocument && data.issue_cnt === "" ? "errorForm" : "")
                              }
                              disabled={disableTravelDocs}
                              // defaultValue={context.translation && context.translation.select}
                              onChange={(value) =>
                                handleCountryFieldChange(
                                  index,
                                  "issue_cnt",
                                  value
                                )
                              }
                              filterOption={(input, option) =>
                                (option?.label ?? "")
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                              optionFilterProp="children"
                              options={countrieslist}
                              // name={`travelDocuments[${index}].issue_cnt`}
                              // value={data.issue_cnt || ""}
                              // className="selectBoxShadow"
                            >
                              {/* {!!countryArr?.length &&
                                countryArr.map(({ label, value }) => (
                                  <Option key={value} value={value}>
                                    {label}
                                  </Option>
                                ))} */}
                              {/* <Option value="">Select</Option>
                              <Option value="India">India</Option> */}
                            </Select>
                          </Form.Item>
                          {data.issue_cnt === "" && state.travelDocument && (
                            <div className="error">
                              {context.translation && context.translation.plzisscountry}
                            </div>
                          )}
                        </Col>

                        <Col span={8} className="addMargin">
                          <Form.Item label={context.translation && context.translation.issDate}>
                            <div className="onlyFlex">
                              <div className="dateMY">
                                <Select
                                  name={`travelDocuments[${index}].issue_date.month`}
                                  value={data.issue_date.month?data.issue_date.month:undefined}
                                  defaultValue={context.translation && context.translation.month}
                                  // className="selectBoxShadow mdy month"
                                  className={
                                    "selectBoxShadow mdy month " +
                                    (state.travelDocument && data.issue_date.month === "" ? "errorForm" : "")
                                  }
                                  disabled={disableTravelDocs}
                                  onChange={(value) =>
                                    handleIssuingDateFieldChange(
                                      index,
                                      "issue_date",
                                      "month",
                                      value
                                    )
                                  }
                                  placeholder="Month"
                                >
                                  {/* Add month options */}
                                {context.selectLang==="en"||context.selectLang=== undefined ? (
                                  <>
                                    {months.map((month) => (
                                      <Option
                                        key={month.value}
                                        value={month.value}
                                        >
                                          {month.label}
                                        </Option>
                                      ))}
                                    </>
                                  ) : (
                                    <>
                                    {monthsArabic.map((month) => (
                                      <Option
                                        key={month.value}
                                        value={month.value}
                                      >
                                        {month.label}
                                      </Option>
                                    ))}
                                    </>
                                  )}
                                </Select>
                                {data.issue_date.month === "" &&
                                  state.travelDocument && (
                                    <div className="error">{context.translation && context.translation.selectMonth}</div>
                                  )}
                              </div>
                              <div className="dateMY">
                                <Select
                                  name={`travelDocuments[${index}].issue_date.day`}
                                  value={data.issue_date.day?data.issue_date.day:undefined}
                                  defaultValue={context.translation && context.translation.day}
                                  // className="selectBoxShadow mdy day"
                                  className={
                                    "selectBoxShadow mdy day " +
                                    (state.travelDocument && data.issue_date.day === "" ? "errorForm" : "")
                                  }
                                  disabled={disableTravelDocs}
                                  onChange={(value) =>
                                    handleIssuingDateFieldChange(
                                      index,
                                      "issue_date",
                                      "day",
                                      value
                                    )
                                  }
                                  placeholder="Day"
                                >
                                  {data.issue_date.month &&
                                    Array.from(
                                      {
                                        length: getDaysInMonth(
                                          data.issue_date.month,
                                          data.issue_date.year
                                        ),
                                      },
                                      (_, i) => i + 1
                                    ).map((day) => (
                                      <Option
                                        key={day}
                                        value={
                                          day.toString().length === 1
                                            ? "0" + day.toString()
                                            : day
                                        }
                                      >
                                        {day.toString().length === 1
                                          ? "0" + day.toString()
                                          : day}
                                      </Option>
                                    ))}
                                </Select>
                                {data.issue_date.day === "" &&
                                  state.travelDocument && (
                                    <div className="error">{context.translation && context.translation.selectDay}</div>
                                  )}
                              </div>
                              <div className="dateMY">
                                <Select
                                  name={`travelDocuments[${index}].issue_date.year`}
                                  value={data.issue_date.year?data.issue_date.year:undefined}
                                  defaultValue={context.translation && context.translation.year}
                                  // className="selectBoxShadow mdy year"
                                  className={
                                    "selectBoxShadow mdy year " +
                                    (state.travelDocument && data.issue_date.year === "" ? "errorForm" : "")
                                  }
                                  disabled={disableTravelDocs}
                                  onChange={(value) =>
                                    handleIssuingDateFieldChange(
                                      index,
                                      "issue_date",
                                      "year",
                                      value
                                    )
                                  }
                                  placeholder="Year"
                                >
                                  {years.map((year) => (
                                    <Option key={year} value={year}>
                                      {year}
                                    </Option>
                                  ))}
                                </Select>
                                {data.issue_date.year === "" &&
                                  state.travelDocument && (
                                    <div className="error">{context.translation && context.translation.selectYear}</div>
                                  )}
                              </div>
                            </div>
                          </Form.Item>
                        </Col>

                        <Col span={8} className="addTopMargin">
                          <Form.Item label={context.translation && context.translation.eDate}>
                            <div className="onlyFlex">
                              <div className="dateMY">
                                <Select
                                  name={`travelDocuments[${index}].exp_date.month`}
                                  value={data.exp_date.month?data.exp_date.month:undefined}
                                  defaultValue={context.translation && context.translation.month}
                                  // className="selectBoxShadow mdy month"
                                  className={
                                    "selectBoxShadow mdy month " +
                                    (state.travelDocument && data.exp_date.month === "" ? "errorForm" : "")
                                  }
                                  disabled={disableTravelDocs}
                                  onChange={(value) =>
                                    handleIssuingDateFieldChange(
                                      index,
                                      "exp_date",
                                      "month",
                                      value
                                    )
                                  }
                                  placeholder="Month"
                                >
                                {context.selectLang==="en"||context.selectLang=== undefined ? (
                                  <>
                                    {months.map((month) => (
                                      <Option
                                        key={month.value}
                                        value={month.value}
                                        >
                                          {month.label}
                                        </Option>
                                      ))}
                                    </>
                                  ) : (
                                    <>
                                    {monthsArabic.map((month) => (
                                      <Option
                                        key={month.value}
                                        value={month.value}
                                      >
                                        {month.label}
                                      </Option>
                                    ))}
                                    </>
                                  )}
                                </Select>
                                {data.exp_date.month === "" &&
                                  state.travelDocument && (
                                    <div className="error">{context.translation && context.translation.selectMonth}</div>
                                  )}
                              </div>
                              <div className="dateMY">
                                <Select
                                  name={`travelDocuments[${index}].exp_date.day`}
                                  value={data.exp_date.day?data.exp_date.day:undefined}
                                  defaultValue={context.translation && context.translation.day}
                                  // className="selectBoxShadow mdy day"
                                  className={
                                    "selectBoxShadow mdy day " +
                                    (state.travelDocument && data.exp_date.day === "" ? "errorForm" : "")
                                  }
                                  disabled={disableTravelDocs}
                                  onChange={(value) =>
                                    handleIssuingDateFieldChange(
                                      index,
                                      "exp_date",
                                      "day",
                                      value
                                    )
                                  }
                                  placeholder="Day"
                                >
                                  {data.exp_date.month &&
                                    Array.from(
                                      {
                                        length: getDaysInMonth(
                                          data.exp_date.month,
                                          data.exp_date.year
                                        ),
                                      },
                                      (_, i) => i + 1
                                    ).map((day) => (
                                      <Option
                                        key={day}
                                        value={
                                          day.toString().length === 1
                                            ? "0" + day.toString()
                                            : day
                                        }
                                      >
                                        {day.toString().length === 1
                                          ? "0" + day.toString()
                                          : day}
                                      </Option>
                                    ))}
                                </Select>
                                {data.exp_date.day === "" &&
                                  state.travelDocument && (
                                    <div className="error">{context.translation && context.translation.selectDay}</div>
                                  )}
                              </div>
                              <div className="dateMY">
                                <Select
                                  name={`travelDocuments[${index}].exp_date.year`}
                                  value={data.exp_date.year?data.exp_date.year:undefined}
                                  defaultValue={context.translation && context.translation.year}
                                  // className="selectBoxShadow mdy year"
                                  className={
                                    "selectBoxShadow mdy year " +
                                    (state.travelDocument && data.exp_date.year === "" ? "errorForm" : "")
                                  }
                                  disabled={disableTravelDocs}
                                  onChange={(value) =>
                                    handleIssuingDateFieldChange(
                                      index,
                                      "exp_date",
                                      "year",
                                      value
                                    )
                                  }
                                  placeholder="Year"
                                >
                                  {expiryYears.map((year) => (
                                    <Option key={year} value={year}>
                                      {year}
                                    </Option>
                                  ))}
                                </Select>
                                {data.exp_date.year === "" &&
                                  state.travelDocument && (
                                    <div className="error">{context.translation && context.translation.selectYear}</div>
                                  )}
                              </div>
                            </div>
                          </Form.Item>
                        </Col>
                        {/* <button onClick={() => handleRemoveDocumentRow(index)}>Delete</button> */}

                        {/* </Col> */}
                      </Row>
                    ))}
                  </Form>
                </div>
                {state.enableProfileTravelDocuments && state.showSave && (
                  <div className="cardDetailsFooter">
                    <Button
                      type="secondary"
                      className="cancelBtn"
                      ghost
                      onClick={handleCancelProfileTravelDocuments}
                    >
                      {context.translation && context.translation.cancel}
                    </Button>
                    {/* {state.travelDocuments[0].passport_no.length !== 0 && ( */}
                      <Button
                        type="primary"
                        className="cntBtn"
                        onClick={handleProfileSave("docs")}
                      >
                        <span>{context.translation && context.translation.save}</span>{" "}
                        {/* <i className="sprite btn-arrow-sprite"></i> */}
                      </Button>
                    {/* )} */}
                  </div>
                )}
              </div>
              <div className="cardDetails contactdDetails frequentFlyerWrapper">
                <div className="cardDetailsHeader">
                  <div>
                    <Title level={4}>{context.translation && context.translation.ff}</Title>
                    <p>{context.translation && context.translation.addurDetails}</p>
                  </div>
                  <div>
                    {!state.enableProfileFrequentFlyer && (
                      <Button
                        type="secondary"
                        className="editBtn"
                        ghost
                        onClick={() => editProfileFrequentFlyer()}
                      >
                        <i className="icon icon-Edit"></i> {context.translation && context.translation.edit}
                      </Button>
                    )}
                  </div>
                </div>
                <div className="cardDetailsBody pRelative">
                  <Form layout="vertical">
                    {state.frequentFlyerData.map((data, index) => (
                      <Row gutter={16} key={index} className="noTopMargin">
                        <Col span={8} className="colWidth addSpace">
                          <Form.Item label={context.translation && context.translation.airlineNam}>
                            {/* <Select
                              name={`frequentFlyerData[${index}].airline_name`}
                              value={data.airline_name || ""}
                              className="selectBoxShadow"
                              disabled={disableFrequentFlyerData}
                              onChange={(value) =>
                                handleAirlineLines(index, "airline_name", value)
                              }
                            >
                              <Option value="">Select</Option>
                              <Option value="Qatar Airline">
                                Qatar Airline
                              </Option>
                            </Select> */}

                            {(sessionStorage.getItem("SEL_LAG") === null || sessionStorage.getItem("SEL_LAG") === "English") &&
                            <Select
                              showSearch
                              defaultValue="Select"
                              // defaultValue={context.translation && context.translation.select}
                              optionFilterProp="children"
                              name={`frequentFlyerData[${index}].airline_name`}
                              value={data.airline_name?data.airline_name:undefined}
                              // className="selectBoxShadow"
                              className={
                                "selectBoxShadow " +
                                (state.frequentFlyer && data.airline_name === "" ? "errorForm" : "")
                              }
                              disabled={disableFrequentFlyerData}
                              onChange={(value) =>
                                handleAirlineLines(index, "airline_name", value)
                              }
                              // onChange={onChange}
                              filterOption={(input, option) =>
                                (option?.label ?? "")
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                              options={airlineMaster}
                            />
                          }
                          {(sessionStorage.getItem("SEL_LAG") === "Arabic") &&
                            <Select
                              showSearch
                              defaultValue="اختر"
                              // defaultValue={context.translation && context.translation.select}
                              optionFilterProp="children"
                              name={`frequentFlyerData[${index}].airline_name`}
                              value={data.airline_name?data.airline_name:undefined}
                              // className="selectBoxShadow"
                              className={
                                "selectBoxShadow " +
                                (state.frequentFlyer && data.airline_name === "" ? "errorForm" : "")
                              }
                              disabled={disableFrequentFlyerData}
                              onChange={(value) =>
                                handleAirlineLines(index, "airline_name", value)
                              }
                              // onChange={onChange}
                              filterOption={(input, option) =>
                                (option?.label ?? "")
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                              options={airlineMaster}
                            />
                          }
                          </Form.Item>
                          {data.airline_name === "" && state.frequentFlyer && (
                            <div className="error">{context.translation && context.translation.plzseleAirline}</div>
                          )}
                        </Col>
                        <Col span={8} className="colWidth addSpace spaceBet20">
                          <Form.Item label={context.translation && context.translation.ffp}>
                            <Input
                              type="text"
                              name={`frequentFlyerData[${index}].ffp`}
                              value={data.ffp || ""}
                              // className="innerBoxShadow"
                              className={
                                "innerBoxShadow " +
                                (state.frequentFlyer && (data.ffp === "" || data.ffp === undefined) ? "errorForm" : "")
                              }
                              disabled={disableFrequentFlyerData}
                              placeholder={context.translation && context.translation.ffp}
                              onChange={(e) =>
                                dispatch({
                                  type: "UPDATE_FREQUENT_FLYER_FIELD",
                                  index,
                                  field: "ffp",
                                  value: e.target.value,
                                })
                              }
                            />
                          </Form.Item>
                          {(data.ffp === "" || data.ffp === undefined) && state.frequentFlyer && (
                            <div className="error">
                              {context.translation && context.translation.peffp}
                            </div>
                          )}
                        </Col>
                        <Col span={8} className="colWidth addSpace spaceBet20">
                          <Form.Item label={context.translation && context.translation.ffn}>
                            <Input
                              type="text"
                              name={`frequentFlyerData[${index}].ffn`}
                              value={data.ffn || ""}
                              // className="innerBoxShadow"
                              className={
                                "innerBoxShadow " +
                                (state.frequentFlyer && (data.ffn === "" || data.ffn === undefined) ? "errorForm" : "")
                              }
                              disabled={disableFrequentFlyerData}
                              placeholder={context.translation && context.translation.ffn}
                              onChange={(e) =>
                                dispatch({
                                  type: "UPDATE_FREQUENT_FLYER_FIELD",
                                  index,
                                  field: "ffn",
                                  value: e.target.value,
                                })
                              }
                            />
                          </Form.Item>
                          {(data.ffn === "" || data.ffn === undefined) && state.frequentFlyer && (
                            <div className="error">
                              {context.translation && context.translation.peffn}
                            </div>
                          )}
                          {state.frequentFlyerData.length > 1 && (
                            <i className="icon icon-cancel" onClick={() => handleRemoveFFRow(index, state.frequentFlyerData[index].id)}></i>
                          )}
                        </Col>
                      </Row>
                    ))}
                    {state.frequentFlyerData.length < 20 &&
                    <Button
                      type="primary"
                      className="addMore addProfile"
                      onClick={handleAddRow}
                      disabled={disableFrequentFlyerData}
                    >
                      +
                    </Button>
                    }
                  </Form>
                  {/* {FFD.length > 0 ? (
                    <>
                      {FFD.map((data, index) => {
                        return (
                          <div>
                            {data.airline_name}
                            {data.ffp}
                            {data.ffn}
                          </div>
                        );
                      })}
                    </>
                  ) : (
                    ""
                  )} */}
                </div>
                {state.enableProfileFrequentFlyer && state.showSave && (
                  <div className="cardDetailsFooter">
                    <Button
                      type="secondary"
                      className="cancelBtn"
                      ghost
                      onClick={handleCancelProfileFrequentFlyer}
                    >
                      {context.translation && context.translation.cancel}
                    </Button>
                    <Button
                      type="primary"
                      className="cntBtn"
                      onClick={handleProfileSave("ffp")}
                    >
                      <span>{context.translation && context.translation.save}</span>{" "}
                      {/* <i className="sprite btn-arrow-sprite"></i> */}
                    </Button>
                  </div>
                )}
              </div>
              <Form layout="vertical">
                {/* <Button className='cntBtn' onClick={() => editProfile()}>Edit</Button> */}
              </Form>
            </Col>
          </Row>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default UserProfile;
