/**
 * @ Author: Srikanth Bandaru
 * @ Create Time: 2023-04-24 12:32:04
 * @ Description: This is an service for flights api
 */

import axios from "axios";
import api from '../../components/utilities/api'; // Import the custom axios instance

const API_URL_SEARCH = process.env.REACT_APP_B2C_COMMON

class FlightService {

    async fireFlightSearch(url){
        return await api.get(`${API_URL_SEARCH}/flight/search?${url}`)
    }

    async getLongfareRules(request,token) {
        const headers = {
          headers: {
            "Content-Type": "application/json",
            'auth-token': token
          },
        };
        return await api.post(`${API_URL_SEARCH}/flight/long-rules`,request,headers);
    }

    async fireMultyCityReq(req) {
        return await api.post(`${API_URL_SEARCH}/flight/multi-search`,req)
    }

    async getBaggage(req,token) {
      const headers = {
        headers: {
          "Content-Type": "application/json",
          'auth-token': token
        },
      };
        return await api.post(`${API_URL_SEARCH}/flight/salam-air/fetch-fareop-details`,req,headers)
    }

    async fireFlightAvailability(request,token) {
        const headers = {
          headers: {
            "Content-Type": "application/json",
            'auth-token': token
          },
        };
        return await api.post(`${API_URL_SEARCH}/flight/availability`,request,headers);
    }
    async getChepestFlight(request,token) {
        const headers = {
          headers: {
            "Content-Type": "application/json",
            'auth-token': token
          },
        };
        return await api.post(`${API_URL_SEARCH}/flight/getcheapestprice`,request,headers);
    }
}

export default new FlightService()  

