import React from "react";
import Footer from "../../../common/Footer";
import Header from "../../../common/Header";
import {Spin,notification,Alert, Modal, Row, Col, Typography } from "antd";
import SecureGuarantee from "../../../homepage/SecureGuarantee";
import HotelBasicDetails from "./HotelBasicDetails";
import HotelDetailTabs from "./HotelDetailTabs";
import { useState } from "react";
import { useRef } from "react";
import { useReducer } from "react";
import { useContext } from "react";
import { Context, ExchangeRates } from "../../../../App";
import { useEffect } from "react";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import {
  getHotelReviewContent,
  getHotelRooms,
  hotelPriceCheck,
  savePriceCheck,
} from "../../../common/services/HotelOperations";
import { isArrayNotEmpty } from "../../../utilities/validators";
import Utilities from "../../../utilities/Utilities";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import HotelPredective from "../search/predective/HotelPredective";
import NoResultsFound from "../results/NoResultsFound";
import FlightGoneLoader from "../../../../assets/img/loader.gif";
import LoaderH from "../../../common/loaders/LoaderH";

const CID = process.env.REACT_APP_FLIGHT_SEARCH_CID;
const CID_NAME = process.env.REACT_APP_FLIGHT_SEARCH_CNAME;
const BASE_CURRENCY = process.env.REACT_APP_BASE_CUR;

const { Title } = Typography;

export const HTL_REQUEST = "htlRequest";
export const HTL_MARKUP = "htlMarkup";
export const HTL_CHD_AGES = "chdages";
export const HTL_CTID = "ctid";
export const SEL_PID_REQ = "pidRequest";
//export const USER_AUTH_DATA = "userAuthData";
//export const AUTH_DATA = "authData";
export const IS_PACKAGE = "isPack";

const _ = require("lodash");
let non_room_only = [
  "2194",
  "2208",
  "2205",
  "2104",
  "2193",
  "1073742786",
  "2103",
  "2197",
  "2111",
  "2206",
  "1073742618",
  "1073742857",
  "1073744459",
  "1073743287",
  "2199",
  "1073742552",
  "2108",
];

const initialState = {
  hotelContentResp: "",
  loading: false,
  hotelRoomResp: "",
  showPop: false,
  ocpsList: [],
  finalRoomsList: [],
  showRoom: false,
  notiMessage: "",
  notiMessageShow: false,
  notiVarient: "",
  selectedRooms: [],
  selRoomsSize: 0,
  roomInfo: "",
  hid: "",
  availContnt: false,
  availRooms: false,
  boardBasisList: [],
  onflyMkp: 0,
  roomOneOcpList: [],
  roomTwoOcpList: [],
  roomThreeOcpList: [],
  roomForOcpList: [],
  roomFiveOcpList: [],
  roomSixOcpList: [],
  roomSevenOcpList: [],
  roomEightOcpList: [],
  showRoomChoice: false,
  precepernight: false,
  boardBasisOne: [],
  boardBasisTwo: [],
  boardBasisThree: [],
  boardBasisFour: [],
  boardBasisFive: [],
  boardBasisSix: [],
  boardBasisSeven: [],
  boardBasisEight: [],
  bbupdated: false,
  noofNights: false,
  cin: "",
  cout: "",
  chdage: "",
  pid: "",
  updateResults: true,
  priceNote: "",
  serviceFee: [],
  showOcpPop: false,
  cityId: 0,
  roomObj: "",
  roomImages: [],
  showPop: false,
  guestReviews: [],
  reviewObj: "",
  expRating: 0,
  usrRating: 0,
  reviewShow: false,
  respAvail: false,
  roomOneOcpList_data: [],
  roomTwoOcpList_data: [],
  roomThreeOcpList_data: [],
  roomForOcpList_data: [],
  roomFiveOcpList_data: [],
  roomSixOcpList_data: [],
  roomSevenOcpList_data: [],
  roomEightOcpList_data: [],
  showClear: false,
  isRefundable: false,
  isNonRefundable: false,
  selectedBoardbasis: "",
};

//=====================================
// Reducer function for userReact Hook
// which will update the state
//=====================================
const reducer = (state, action) => {
  switch (action.type) {
    case "clear":
      return {
        ...state,
      };
    default:
      return { ...state, [action.type]: action.payload };
  }
};

function HotelDetails(props) {
  const navigate = useNavigate();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [context, setContext] = useContext(Context);
  const [htlData, setHotlData] = useState();
  const [show, setShow] = useState(false);
  const [totalPrice, setTotalPrice] = useState(0);
  const [srequest, setRequest] = useState(props.location);
  const [searchRequest, setSearchRequest] = useState();
  const [refund, setRefund] = useState(false);
  const [nonRefund, setNonRefund] = useState(false);
  const handleClose = () => setShow(false);
  const [isLoading, setIsloading] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const [modify,setModify] = useState(window.localStorage.getItem("modify")?window.localStorage.getItem("modify"):undefined);
  const [value, setValue] = useState(1);
  const [fltBookingId,setFltBookingId] = useState("");
  const [base_usr_exrate, setBaseToUserExrate] = useState(1);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    var htl = window.htl?window.htl:window.sessionStorage.getItem(SEL_PID_REQ);
    var ispkg = window.isPackage?window.isPackage:window.localStorage.getItem("ispkg");
    var cmpid = window.cmpid?window.cmpid:window.sessionStorage.getItem("CMPID");
    //Call Hotel details
    if (htl !== undefined) {
      var htldta = JSON.parse(htl);
      setHotlData(htldta);
      setRequest(htldta);
      window.sessionStorage.setItem(SEL_PID_REQ, htl);
      window.sessionStorage.setItem(IS_PACKAGE, ispkg);
      if (htldta.expediapid !== undefined && htldta.expediapid !== "") {
        callHotelDetails(htldta.expediapid, htldta.pid, ispkg);
        setLoading(true);
        dispatch({ type: "pid", payload: htldta.pid });
        dispatch({ type: "serviceFee", payload: htldta.servicefee });
        dispatch({ type: "serviceFee", payload: htldta.servicefee });
        // For Cross Sell
        if(htldta.flt_booking_id!==undefined && htldta.flt_booking_id!==""){
          setFltBookingId(htldta.flt_booking_id);
        }
      }
    } else {
      var htldta = JSON.parse(window.sessionStorage.getItem(SEL_PID_REQ));
      var isPack = window.sessionStorage.getItem(IS_PACKAGE);
      setHotlData(htldta);
      setRequest(htldta);
      if (htldta.expediapid !== undefined && htldta.expediapid !== "") {
        callHotelDetails(htldta.expediapid, htldta.pid, isPack);
        setLoading(true);
        dispatch({ type: "pid", payload: htldta.pid });
        dispatch({ type: "serviceFee", payload: htldta.servicefee });
      }
    }

    if (window.cmpid === undefined) {
      if(window.sessionStorage.getItem("CMPID")){
        window.cmpid = window.sessionStorage.getItem("CMPID");
      }
    }

    if (window.cmpid !== undefined) {
      window.sessionStorage.setItem("CMPID", window.cmpid);
    }
    //var userData = localStorage.getItem(USER_AUTH_DATA);
    //window.sessionStorage.setItem(AUTH_DATA, userData);
    //window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    var userCur = context.userCur!==undefined && context.userCur!==""?context.userCur:sessionStorage.getItem("SEL_CUR")
    var exRates = JSON.parse(localStorage.getItem("CNT_EX_RATES"))
    if(context.ex_rates === undefined || context.ex_rates === "") {
      setContext({...context, ex_rates: exRates})
    }
    if((context.ex_rates !==undefined && context.ex_rates!=="") || (exRates !== undefined && exRates !== "") && userCur!==undefined && userCur!==""){
      if(BASE_CURRENCY!==userCur){
        if(srequest !== null && srequest !== undefined) {
          srequest.cur=userCur;
        }
        let currency=BASE_CURRENCY+" - "+userCur;
        getExchangeRate(currency)
      }else if(BASE_CURRENCY===userCur){
        setBaseToUserExrate(1)
        if(srequest !== null && srequest !== undefined) {
          srequest.cur=userCur;
        }
      }
    }else{
      setBaseToUserExrate(1)
    }
  }, [context.userCur])
  
  useEffect(() => {
  if(context.ex_rates!=="" && context.ex_rates!==undefined){
    var userCur = context.userCur!==undefined && context.userCur!==""?context.userCur:sessionStorage.getItem("SEL_CUR")
    let currency=BASE_CURRENCY+" - "+userCur;
    getExchangeRate(currency)
  } 
  }, [context.ex_rates])

  const getExchangeRate=(usrCur)=>{
    if(context.ex_rates!=="" && context.ex_rates!==undefined && context.ex_rates!==null){
      if (context.ex_rates?.hasOwnProperty(usrCur)) {
        var sup = context.ex_rates[usrCur];
        setBaseToUserExrate(Number(sup))
      }
    }
  }

  useEffect(() => {
    if(modify && Boolean(modify) === true){
      localStorage.removeItem("modify");
    }
  }, [modify])
  

  useEffect(() => {
    openReviewPopup()();
  }, [state.hotelContentResp])

  const openReviewPopup = (obj) => () => {
    dispatch({ type: 'expRating', payload: state.hotelContentResp.expediareview })
    dispatch({ type: 'usrRating', payload: state.hotelContentResp.urat })
    dispatch({ type: 'reviewObj', payload: state.hotelContentResp.guestreview })
    dispatch({ type: 'reviewShow', payload: true })

  }
  //=====================================
  // Calling the Search request.state.req
  //=====================================
  const callHotelDetails = (hotelid, pid, isPack) => {
    try {
      getHotelReviewContent(hotelid).then((response) => {
        if (response && response.data && response.data.data) {
          dispatch({ type: "hotelContentResp", payload: response.data.data });
          let req = modify && window.localStorage.getItem("sreq")?window.localStorage.getItem("sreq"):null;
          callHotelPidSearch(response.data.data, pid, req, hotelid, isPack);
        } else {
          dispatch({ type: "showPop", payload: true });
          dispatch({ type: "availContnt", payload: true });
          dispatch({ type: "availRooms", payload: false });
        }
      }).catch(function (error) {
        console.error("Exception occured in handleChange function---" + error);      
      });
    } catch (e) {
      console.error("Exception occured in handleChange function---" + e);
    }
  };

  const callHotelPidSearch = (obj, hotelid, srequest, id, isPack) => {
    dispatch({ type: "respAvail", payload: false });
    try {
      let request = "";
      if (srequest === null || srequest === undefined) {
        request = JSON.parse(localStorage.getItem(HTL_REQUEST));
        setSearchRequest(localStorage.getItem(HTL_REQUEST));
      } else {
        request = JSON.parse(srequest);
        if(request.searchType === 'city' ){
          HotelPredective.retrieveHotelorCityData(request.city,true).then((response) => {
            request.cityHotelData = response.data.data.city;
          }).catch(function (error) {
            console.error("Error:" + error);      
          });
        } else {
          HotelPredective.retrieveHotelorCityData(request.hotelId,false).then((response) => {
            request.cityHotelData = response.data.data.property;
          }).catch(function (error) {
            console.error("Error" + error);      
          });
        }
        localStorage.setItem(HTL_REQUEST, JSON.stringify(request));
        setSearchRequest(request);
      }
      dispatch({ type: "noofNights", payload: request.nights });
      dispatch({ type: "cin", payload: request.cin });
      dispatch({ type: "cout", payload: request.cout });
      let chd =
        request.roomsInfo !== undefined &&
        request.roomsInfo.filter((ob) => ob.chd !== undefined && ob.chd);
      var ages = convertChildAge(request.roomsInfo);
      var adts = convertAdts(request.roomsInfo);
      var randomStr = Math.random().toString(36).slice(2);
      let isPackAvail = isPack ? 1 : 0;
      var cur = localStorage.getItem("REGION_COUNTRY") !== null &&
      localStorage.getItem("REGION_COUNTRY") === "IN"
        ? "INR"
        : "QAR";        
      var usr_cur = context.userCur!==undefined && context.userCur!==""?context.userCur:sessionStorage.getItem("SEL_CUR");
      let cntCode = localStorage.getItem("REGION_COUNTRY") !== null &&
      localStorage.getItem("REGION_COUNTRY") === "IN"
        ? "IN"
        : "QA";
      var requrl =
        "cin=" +
        request.cin +
        "&cout=" +
        request.cout +
        "&rms=" +
        request.roomsInfo.length +
        "&adt=" +
        adts +
        "&chd=" +
        chd.length +
        "&rty=" +
        "prid" +
        "&echo=" +
        randomStr +
        "&cid=" +
        CID +
        "&cha=" +
        "B2C" +
        "&cur=" +
        usr_cur +
        "&prtyp=" +
        "hotelOnely" +
        "&country_code=" +
        cntCode +
        "&pid=" +
        hotelid +
        "&chdages=" +
        ages +
        "&package=" +
        isPackAvail +
        "&_id=" +
        obj._id +
        "&req_cur=" +
        BASE_CURRENCY +
        "&region_type=" +
        request.region +
        /*  "&expediacityid=" +
        htlData !== undefined ?htlData.expediacityid:"" + */
        "&expediapid=" +
        hotelid;
      dispatch({ type: "chdage", payload: ages });
      getHotelRooms(requrl).then((response) => {
        if (response.suc) {
          dispatch({ type: "hid", payload: response?.data?.hid });
          dispatch({ type: "priceNote", payload: response?.data?.note });
        }
        dispatch({ type: "selectedRooms", payload: [] });
        dispatch({ type: "respAvail", payload: true });
        let onFly = localStorage.getItem(HTL_MARKUP);
        if (isPack === 1) {
          sessionStorage.setItem(IS_PACKAGE, true);
        } else if (isPack === 0) {
          sessionStorage.setItem(IS_PACKAGE, false);
        }
        let onFlyMarkup = onFly / request.roomsInfo.length;
        dispatch({ type: "onflyMkp", payload: onFlyMarkup });
        dispatch({ type: "cityId", payload: localStorage.getItem(HTL_CTID) });
        localStorage.removeItem(HTL_CTID);
        if (response.suc) {
          createOccupancyList(response.data, obj.roomData, request);
        } else {
          dispatch({ type: "showPop", payload: true });
          dispatch({ type: "availRooms", payload: true });
          dispatch({ type: "availContnt", payload: false });
          setLoading(false);
        }
      }).catch(function (error) {
        console.error("Exception occured in handleChange function---" + error);      
      });
    } catch (e) {
      console.error("Exception occured in handleChange function---" + e);
    }
  };

  const convertAdts = (roomsInfo) => {
    var adts = "";
    for (let val of roomsInfo) {
      if (adts === "") {
        adts = val.adt;
      } else {
        adts = adts + "," + val.adt;
      }
    }
    return adts;
  };
  //Converting Child Ages
  const convertChildAge = (roomsInfo) => {
    var ages = "";
    for (let val of roomsInfo) {
      if (val.chdArray.length !== 0) {
        if (ages === "") {
          if (val.chdArray.length === 1) {
            ages = val.chdArray.reduce((acc, curr) => `${acc}${curr.age}`, "");
          } else {
            ages = val.chdArray.reduce((acc, curr) => `${acc}${curr.age},`, "");
          }
        } else {
          if (val.chdArray.length === 1) {
            ages =
              ages +
              "|" +
              val.chdArray.reduce((acc, curr) => `${acc}${curr.age}`, "");
          } else {
            ages =
              ages +
              "|" +
              val.chdArray.reduce((acc, curr) => `${acc}${curr.age},`, "");
          }
        }
      } else {
        if (ages !== "") {
          ages = ages + "|" + "0";
        } else {
          ages = "0";
        }
      }
    }
    return ages;
  };

  //Creating Total Occupancies List
  const createOccupancyList = (resp, contRooms, requst) => {
    let ocpsList1 = [];
    let ocpsList2 = [];
    let ocpsList3 = [];
    let ocpsList4 = [];
    let ocpsList5 = [];
    let ocpsList6 = [];
    let ocpsList7 = [];
    let ocpsList8 = [];
    let boardbsis = [];
    let selRoomList = [];
    let count = 1;
    if (requst !== undefined && requst.roomsInfo !== undefined) {
      for (let room of requst.roomsInfo) {
        var ocp = "";
        var age = "";
        if (room.chdArray.length !== 0) {
          for (let val of room.chdArray) {
            if (age === "") {
              age = val.age;
            } else {
              age = age + "," + val.age;
            }
          }
          ocp = room.adt + "-" + age;
        } else {
          ocp = room.adt;
        }
        let rooms =
          resp.rooms !== undefined &&
          resp.rooms.filter((ob) => ob.ocp.includes(ocp) && ob.rmno == room.rmno);
        for (let val of rooms) {
          let rmImg;
          if(contRooms !== undefined) {
            rmImg = contRooms[val.rmid];
          }
          for (let price of val.rmpr) {
            let imge = "";
            if (
              rmImg !== undefined &&
              rmImg.images !== undefined &&
              rmImg.images.length !== 0
            ) {
              imge = rmImg.images[0].links["1000Px"];
            }
            if (price.refundable == true) {
              dispatch({ type: "isRefundable", payload: true });
            }
            if (price.refundable == false) {
              dispatch({ type: "isNonRefundable", payload: true });
            }
            //Cal GST
            var htldta = JSON.parse(window.sessionStorage.getItem(SEL_PID_REQ));
            if (
              requst.region_type !== undefined &&
              requst.region_type === "India"
            ) {
              calculateGST(price, htldta);
            } else {
              price.pr[0].gst_total = 0;
            }
            price.pr[0].tds_val = 0;
            //Cal TDS
            if (
              requst.region_type !== undefined &&
              requst.region_type === "India"
            ) {
              if (
                price.pr[0].market_fee !== undefined &&
                price.pr[0].market_fee !== 0
              ) {
                calculateTds(price, htldta);
              } else {
                price.pr[0].tds_val = 0;
              }
            } else {
              price.pr[0].tds_val = 0;
            }
            const payload = {
              count: count,
              rmindex: room.rmno,
              rmimg:
                imge !== undefined && imge.href !== undefined ? imge.href : "",
              imgecnt:
                rmImg !== undefined &&
                rmImg.images !== undefined &&
                rmImg.images.length !== 0
                  ? rmImg.images.length
                  : 0,
              adt: room.adt,
              chd: room.chd,
              chdage:
                age !== undefined && age !== "" && room.chd !== undefined
                  ? "(Age: " + age + " Yrs)"
                  : "1 ",
              rmid: val.rmid,
              rnm: val.rnm,
              hs: val.hs,
              ra: val.ra,
              ocp: val.ocp,
              note: val.note,
              rmpr: [price],
            };
            if (room.rmno === 1) {
              payload.selected1 = false;
              ocpsList1.push(payload);
            } else if (room.rmno === 2) {
              payload.selected2 = false;
              ocpsList2.push(payload);
            } else if (room.rmno === 3) {
              payload.selected3 = false;
              ocpsList3.push(payload);
            } else if (room.rmno === 4) {
              payload.selected4 = false;
              ocpsList4.push(payload);
            } else if (room.rmno === 5) {
              payload.selected5 = false;
              ocpsList5.push(payload);
            } else if (room.rmno === 6) {
              payload.selected6 = false;
              ocpsList6.push(payload);
            } else if (room.rmno === 7) {
              payload.selected7 = false;
              ocpsList7.push(payload);
            } else if (room.rmno === 8) {
              payload.selected8 = false;
              ocpsList8.push(payload);
            }
            let brdbase = [];
            if (price.ra !== undefined && price.ra.length !== 0) {
              for (let taxIndex = 0; taxIndex < price.ra.length; taxIndex++) {
                var mak = price.ra[taxIndex];
                if (
                  mak.id !== "2109" &&
                  mak.id !== "2195" &&
                  mak.id !== "2192" &&
                  mak.id !== "2191"
                ) {
                  brdbase.push(mak.name);
                  boardbsis.push(mak.name);
                }
              }
            }
            if (!isArrayNotEmpty(brdbase)) {
              brdbase.push("Room Only");
              boardbsis.push("Room Only");
            }
            count = count + 1;
          }
        }
        const payload = {
          rmindex: room.rmno,
          rst: false,
          rooms: [],
        };
        selRoomList.push(payload);
      }
      dispatch({ type: "updateResults", payload: false });
    }
    dispatch({ type: "roomOneOcpList", payload: sortPrice(ocpsList1) });
    dispatch({ type: "boardBasisOne", payload: ocpsList1 });
    dispatch({ type: "roomTwoOcpList", payload: sortPrice(ocpsList2) });
    dispatch({ type: "boardBasisTwo", payload: ocpsList2 });
    dispatch({ type: "roomThreeOcpList", payload: sortPrice(ocpsList3) });
    dispatch({ type: "boardBasisThree", payload: ocpsList3 });
    dispatch({ type: "roomForOcpList", payload: sortPrice(ocpsList4) });
    dispatch({ type: "boardBasisFour", payload: ocpsList4 });
    dispatch({ type: "roomFiveOcpList", payload: sortPrice(ocpsList5) });
    dispatch({ type: "boardBasisFive", payload: ocpsList5 });
    dispatch({ type: "roomSixOcpList", payload: sortPrice(ocpsList6) });
    dispatch({ type: "roomSevenOcpList", payload: sortPrice(ocpsList7) });
    dispatch({ type: "roomEightOcpList", payload: sortPrice(ocpsList8) });
    dispatch({ type: "selectedRooms", payload: selRoomList });
    dispatch({ type: "selRoomsSize", payload: selRoomList.length });
    var uniqueArray = Array.from(new Set(boardbsis));
    let data = Utilities.getUniqData(boardbsis);
    dispatch({ type: "boardBasisList", payload: data });
    dispatch({ type: "showRoomChoice", payload: false });
    dispatch({ type: "updateResults", payload: true });
    //All OCP List data backup
    dispatch({ type: "roomOneOcpList_data", payload: sortPrice(ocpsList1) });
    dispatch({ type: "roomTwoOcpList_data", payload: sortPrice(ocpsList2) });
    dispatch({ type: "roomThreeOcpList_data", payload: sortPrice(ocpsList3) });
    dispatch({ type: "roomForOcpList_data", payload: sortPrice(ocpsList4) });
    dispatch({ type: "roomFiveOcpList_data", payload: sortPrice(ocpsList5) });
    dispatch({ type: "roomSixOcpList_data", payload: sortPrice(ocpsList6) });
    dispatch({ type: "roomSevenOcpList_data", payload: sortPrice(ocpsList7) });
    dispatch({ type: "roomEightOcpList_data", payload: sortPrice(ocpsList8) });
    setLoading(false);
  };

  const calculateGST = (price, htldta) => {
    let total = 0;
    if (htldta.type !== "" && htldta.type !== undefined) {
      let gst = {
        type: htldta.type,
        sgst: htldta.sgst !== undefined ? htldta.sgst : 0,
        igst: htldta.igst !== undefined ? htldta.igst : 0,
        cgst: htldta.cgst !== undefined ? htldta.cgst : 0,
      };
      gst = Utilities.applyGST(
        price.servicefee,
        gst,
        price.pr[0].tax,
        null,
        "Hotel"
      );
      if (gst.gst_invoice.length !== 0) {
        for (let val of gst.gst_invoice) {
          if (gst.type === "same") {
            if (total === 0) {
              total = Number(val.cgst_val) + Number(val.sgst_val);
            } else {
              total = total + Number(val.cgst_val) + Number(val.sgst_val);
            }
          } else {
            if (total === 0) {
              total = Number(val.igst_val);
            } else {
              total = total + Number(val.igst_val);
            }
          }
        }
      }
      price.pr[0].gst = gst;
      price.pr[0].gst_total = total;
    } else {
      price.pr[0].gst_total = 0;
    }
  };

  const calculateTds = (price, htldta) => {
    price.pr[0].tds_per = htldta.tds_per;
    price.pr[0].tds_val = Utilities.applyTDS(
      htldta.tds_per,
      price.pr[0].com_disc
    );
  };

  const sortPrice = (list) => {
    let totalList = [];
    totalList = list.sort(function (a, b) {
      return a.rmpr[0].pr[0].tf - b.rmpr[0].pr[0].tf;
    });
    return totalList;
  };

  const applyBoardBasis = (bbname) => () => {
    if (bbname == "Refundable" || bbname == "Non-Refundable") {
      dispatch({ type: "selectedBoardbasis", payload: "" });
    } else {
      setRefund(false);
      setNonRefund(false);
    }
    dispatch({ type: "showClear", payload: true });
    if (bbname == "Refundable") {
      setRefund(true);
      setNonRefund(false);
    }
    if (bbname == "Non-Refundable") {
      setRefund(false);
      setNonRefund(true);
    }
    //Room 1
    let room1data = [];
    //If Room only
    if (bbname.toUpperCase() == "ROOM ONLY") {
      dispatch({ type: "selectedBoardbasis", payload: bbname });

      room1data = state.roomOneOcpList_data.filter((room) => {
        if (!isArrayNotEmpty(room.rmpr[0].ra)) {
          return room;
        } else {
          let raids = _.uniq(_.map(room.rmpr[0].ra, "id"));
          const ram_data = raids.filter((element) =>
            non_room_only.includes(element)
          );
          if (!isArrayNotEmpty(ram_data)) {
            return room;
          }
        }
      });
    }
    // refundable
    else if (bbname.toUpperCase() == "REFUNDABLE") {
      room1data = state.roomOneOcpList_data.filter((room) => {
        if (room.rmpr[0].refundable === true) {
          return room;
        }
      });
    }
    //non-refundable
    else if (bbname.toUpperCase() == "NON-REFUNDABLE") {
      room1data = state.roomOneOcpList_data.filter((room) => {
        if (room.rmpr[0].refundable === false) {
          return room;
        }
      });
    }

    //Other than Room only
    else {
      dispatch({ type: "selectedBoardbasis", payload: bbname });
      room1data = state.roomOneOcpList_data.filter((room) => {
        let data = room.rmpr[0].ra.filter((obj) =>
          obj.name.toUpperCase().includes(bbname.toUpperCase())
        );
        if (isArrayNotEmpty(data)) {
          return room;
        }
      });
    }
    dispatch({ type: "roomOneOcpList", payload: room1data });

    //Room 2
    let room2data = [];
    //If Room only
    if (bbname.toUpperCase() == "ROOM ONLY") {
      room2data = state.roomTwoOcpList_data.filter((room) => {
        if (!isArrayNotEmpty(room.rmpr[0].ra)) {
          return room;
        } else {
          let raids = _.uniq(_.map(room.rmpr[0].ra, "id"));
          const ram_data = raids.filter((element) =>
            non_room_only.includes(element)
          );
          if (!isArrayNotEmpty(ram_data)) {
            return room;
          }
        }
      });
    }
    // refundable
    else if (bbname.toUpperCase() == "REFUNDABLE") {
      room2data = state.roomTwoOcpList_data.filter((room) => {
        if (room.rmpr[0].refundable === true) {
          return room;
        }
      });
    }
    //non-refundable
    else if (bbname.toUpperCase() == "NON-REFUNDABLE") {
      room2data = state.roomTwoOcpList_data.filter((room) => {
        if (room.rmpr[0].refundable === false) {
          return room;
        }
      });
    }
    //Other than Room only
    else {
      room2data = state.roomTwoOcpList_data.filter((room) => {
        let data = room.rmpr[0].ra.filter((obj) =>
          obj.name.toUpperCase().includes(bbname.toUpperCase())
        );
        if (isArrayNotEmpty(data)) {
          return room;
        }
      });
    }
    dispatch({ type: "roomTwoOcpList", payload: room2data });

    //Room 3
    let room3data = [];
    //If Room only
    if (bbname.toUpperCase() == "ROOM ONLY") {
      room3data = state.roomThreeOcpList_data.filter((room) => {
        if (!isArrayNotEmpty(room.rmpr[0].ra)) {
          return room;
        } else {
          let raids = _.uniq(_.map(room.rmpr[0].ra, "id"));
          const ram_data = raids.filter((element) =>
            non_room_only.includes(element)
          );
          if (!isArrayNotEmpty(ram_data)) {
            return room;
          }
        }
      });
    }
    // refundable
    else if (bbname.toUpperCase() == "REFUNDABLE") {
      room3data = state.roomThreeOcpList_data.filter((room) => {
        if (room.rmpr[0].refundable === true) {
          return room;
        }
      });
    }
    //non-refundable
    else if (bbname.toUpperCase() == "NON-REFUNDABLE") {
      room3data = state.roomThreeOcpList_data.filter((room) => {
        if (room.rmpr[0].refundable === false) {
          return room;
        }
      });
    }
    //Other than Room only
    else {
      room3data = state.roomThreeOcpList_data.filter((room) => {
        let data = room.rmpr[0].ra.filter((obj) =>
          obj.name.toUpperCase().includes(bbname.toUpperCase())
        );
        if (isArrayNotEmpty(data)) {
          return room;
        }
      });
    }
    dispatch({ type: "roomThreeOcpList", payload: room3data });

    //Room 4
    let room4data = [];
    //If Room only
    if (bbname.toUpperCase() == "ROOM ONLY") {
      room4data = state.roomForOcpList_data.filter((room) => {
        if (!isArrayNotEmpty(room.rmpr[0].ra)) {
          return room;
        } else {
          let raids = _.uniq(_.map(room.rmpr[0].ra, "id"));
          const ram_data = raids.filter((element) =>
            non_room_only.includes(element)
          );
          if (!isArrayNotEmpty(ram_data)) {
            return room;
          }
        }
      });
    }
    // refundable
    else if (bbname.toUpperCase() == "REFUNDABLE") {
      room4data = state.roomForOcpList_data.filter((room) => {
        if (room.rmpr[0].refundable === true) {
          return room;
        }
      });
    }
    //non-refundable
    else if (bbname.toUpperCase() == "NON-REFUNDABLE") {
      room4data = state.roomForOcpList_data.filter((room) => {
        if (room.rmpr[0].refundable === false) {
          return room;
        }
      });
    }
    //If other than Room only
    else {
      room4data = state.roomForOcpList_data.filter((room) => {
        let data = room.rmpr[0].ra.filter((obj) =>
          obj.name.toUpperCase().includes(bbname.toUpperCase())
        );
        if (isArrayNotEmpty(data)) {
          return room;
        }
      });
    }
    dispatch({ type: "roomForOcpList", payload: room4data });

    //Room 5
    let room5data = [];
    //If Room only
    if (bbname.toUpperCase() == "ROOM ONLY") {
      room5data = state.roomFiveOcpList_data.filter((room) => {
        if (!isArrayNotEmpty(room.rmpr[0].ra)) {
          return room;
        } else {
          let raids = _.uniq(_.map(room.rmpr[0].ra, "id"));
          const ram_data = raids.filter((element) =>
            non_room_only.includes(element)
          );
          if (!isArrayNotEmpty(ram_data)) {
            return room;
          }
        }
      });
    }
    // refundable
    else if (bbname.toUpperCase() == "REFUNDABLE") {
      room5data = state.roomFiveOcpList_data.filter((room) => {
        if (room.rmpr[0].refundable === true) {
          return room;
        }
      });
    }
    //non-refundable
    else if (bbname.toUpperCase() == "NON-REFUNDABLE") {
      room5data = state.roomFiveOcpList_data.filter((room) => {
        if (room.rmpr[0].refundable === false) {
          return room;
        }
      });
    }
    //If other than Room only
    else {
      room5data = state.roomFiveOcpList_data.filter((room) => {
        let data = room.rmpr[0].ra.filter((obj) =>
          obj.name.toUpperCase().includes(bbname.toUpperCase())
        );
        if (isArrayNotEmpty(data)) {
          return room;
        }
      });
    }
    dispatch({ type: "roomFiveOcpList", payload: room5data });

    //Room 6
    let room6data = [];
    //If Room only
    if (bbname.toUpperCase() == "ROOM ONLY") {
      room6data = state.roomSixOcpList_data.filter((room) => {
        if (!isArrayNotEmpty(room.rmpr[0].ra)) {
          return room;
        } else {
          let raids = _.uniq(_.map(room.rmpr[0].ra, "id"));
          const ram_data = raids.filter((element) =>
            non_room_only.includes(element)
          );
          if (!isArrayNotEmpty(ram_data)) {
            return room;
          }
        }
      });
    }
    // refundable
    else if (bbname.toUpperCase() == "REFUNDABLE") {
      room6data = state.roomSixOcpList_data.filter((room) => {
        if (room.rmpr[0].refundable === true) {
          return room;
        }
      });
    }
    //non-refundable
    else if (bbname.toUpperCase() == "NON-REFUNDABLE") {
      room6data = state.roomSixOcpList_data.filter((room) => {
        if (room.rmpr[0].refundable === false) {
          return room;
        }
      });
    }
    //If Other than Room only
    else {
      room6data = state.roomSixOcpList_data.filter((room) => {
        let data = room.rmpr[0].ra.filter((obj) =>
          obj.name.toUpperCase().includes(bbname.toUpperCase())
        );
        if (isArrayNotEmpty(data)) {
          return room;
        }
      });
    }
    dispatch({ type: "roomSixOcpList", payload: room6data });

    //Room 7
    let room7data = [];
    //If Room only
    if (bbname.toUpperCase() == "ROOM ONLY") {
      room7data = state.roomSevenOcpList_data.filter((room) => {
        if (!isArrayNotEmpty(room.rmpr[0].ra)) {
          return room;
        } else {
          let raids = _.uniq(_.map(room.rmpr[0].ra, "id"));
          const ram_data = raids.filter((element) =>
            non_room_only.includes(element)
          );
          if (!isArrayNotEmpty(ram_data)) {
            return room;
          }
        }
      });
    }
    // refundable
    else if (bbname.toUpperCase() == "REFUNDABLE") {
      room7data = state.roomSevenOcpList_data.filter((room) => {
        if (room.rmpr[0].refundable === true) {
          return room;
        }
      });
    }
    //non-refundable
    else if (bbname.toUpperCase() == "NON-REFUNDABLE") {
      room7data = state.roomSevenOcpList_data.filter((room) => {
        if (room.rmpr[0].refundable === false) {
          return room;
        }
      });
    }
    //If Other than Room only
    else {
      room7data = state.roomSevenOcpList_data.filter((room) => {
        let data = room.rmpr[0].ra.filter((obj) =>
          obj.name.toUpperCase().includes(bbname.toUpperCase())
        );
        if (isArrayNotEmpty(data)) {
          return room;
        }
      });
    }
    dispatch({ type: "roomSevenOcpList", payload: room7data });

    //Room 8
    let room8data = [];
    //If Room only
    if (bbname.toUpperCase() == "ROOM ONLY") {
      room8data = state.roomEightOcpList_data.filter((room) => {
        if (!isArrayNotEmpty(room.rmpr[0].ra)) {
          return room;
        } else {
          let raids = _.uniq(_.map(room.rmpr[0].ra, "id"));
          const ram_data = raids.filter((element) =>
            non_room_only.includes(element)
          );
          if (!isArrayNotEmpty(ram_data)) {
            return room;
          }
        }
      });
    }
    // refundable
    else if (bbname.toUpperCase() == "REFUNDABLE") {
      room8data = state.roomEightOcpList_data.filter((room) => {
        if (room.rmpr[0].refundable === true) {
          return room;
        }
      });
    }
    //non-refundable
    else if (bbname.toUpperCase() == "NON-REFUNDABLE") {
      room8data = state.roomEightOcpList_data.filter((room) => {
        if (room.rmpr[0].refundable === false) {
          return room;
        }
      });
    }
    //If other than room only
    else {
      room8data = state.roomEightOcpList_data.filter((room) => {
        let data = room.rmpr[0].ra.filter((obj) =>
          obj.name.toUpperCase().includes(bbname.toUpperCase())
        );
        if (isArrayNotEmpty(data)) {
          return room;
        }
      });
    }
    dispatch({ type: "roomEightOcpList", payload: room8data });
  };

  //Clear Board basis filter
  const clearBoardBasis = () => {
    setRefund(false);
    setNonRefund(false);
    dispatch({ type: "selectedBoardbasis", payload: "" });
    dispatch({ type: "showClear", payload: false });

    dispatch({ type: "showClear", payload: false });
    //Room 1
    dispatch({ type: "roomOneOcpList", payload: state.roomOneOcpList_data });

    //Room 2
    dispatch({ type: "roomTwoOcpList", payload: state.roomTwoOcpList_data });

    //Room 3
    dispatch({
      type: "roomThreeOcpList",
      payload: state.roomThreeOcpList_data,
    });

    //Room 4
    dispatch({ type: "roomForOcpList", payload: state.roomForOcpList_data });

    //Room 5
    dispatch({ type: "roomFiveOcpList", payload: state.roomFiveOcpList_data });

    //Room 6
    dispatch({ type: "roomSixOcpList", payload: state.roomSixOcpList_data });

    //Room 7
    dispatch({
      type: "roomSevenOcpList",
      payload: state.roomSevenOcpList_data,
    });

    //Room 8
    dispatch({
      type: "roomEightOcpList",
      payload: state.roomEightOcpList_data,
    });
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    if (state.precepernight) {
      dispatch({ type: "precepernight", payload: false });
    } else {
      dispatch({ type: "precepernight", payload: true });
    }
    dispatch({ type: id, payload: value });
    setValue(value);
  };

  const updateItiUrl = (data, url) => {};
  //Creating the Price request
  const checkRoomPrice = useCallback((data) => {
   // debugger;
    try {
      if (data !== undefined) {
        setIsloading(true);
        // let reqData=data;
        // let req=data.rmpr;
        // delete data.rmpr;
        // let rmpr=[];
        // rmpr.push(req);
        // data.rmpr=rmpr;
        let request = createPriceRequest(data);
        let tokenKey = Utilities.generateAPIToken(request);
        hotelPriceCheck(request,tokenKey).then((response) => {
          let price = 0;
          if (response.data !== undefined && response.data.suc === true) {
            for (let val of state.selectedRooms) {
              if (val.rmindex === data.rmindex) {
                val.rst = true;
                data.rmpr[0].itiurl = response.data.data.rooms[0]?.itiurl;
                val.rooms = data;
              }
            }
            dispatch({ type: "showRoomChoice", payload: true });
            dispatch({ type: "notiMessageShow", payload: true });
            dispatch({
              type: "notiMessage",
              payload: (context.translation && context.translation.selectedRoom),
            });
            dispatch({ type: "notiVarient", payload: "success" });
            setIsloading(false);
            hidemessage();
          } else {
            dispatch({ type: "updateResults", payload: false });
            if (data.rmindex === 1) {
              state.roomOneOcpList.splice(data, 1);
              data.selected1 = false;
              showPopuopOcp(state.roomOneOcpList.length);
            } else if (data.rmindex === 2) {
              state.roomTwoOcpList.splice(data, 1);
              data.selected1 = false;
              showPopuopOcp(state.roomTwoOcpList.length);
            } else if (data.rmindex === 3) {
              state.roomThreeOcpList.splice(data, 1);
              data.selected1 = false;
              showPopuopOcp(state.roomThreeOcpList.length);
            } else if (data.rmindex === 4) {
              state.roomForOcpList.splice(data, 1);
              data.selected1 = false;
              showPopuopOcp(state.roomForOcpList.length);
            } else if (data.rmindex === 5) {
              state.roomFiveOcpList.splice(data, 1);
              data.selected1 = false;
              showPopuopOcp(state.roomFiveOcpList.length);
            } else if (data.rmindex === 6) {
              state.roomSixOcpList.splice(data, 1);
              data.selected1 = false;
              showPopuopOcp(state.roomSixOcpList.length);
            } else if (data.rmindex === 7) {
              state.roomSevenOcpList.splice(data, 1);
              data.selected1 = false;
              showPopuopOcp(state.roomSevenOcpList.length);
            } else if (data.rmindex === 8) {
              state.roomEightOcpList.splice(data, 1);
              data.selected1 = false;
              showPopuopOcp(state.roomEightOcpList.length);
            }
            dispatch({ type: "updateResults", payload: true });
            setIsloading(false);
          }
        }).catch(function (error) {
          console.error("Error:" + error);      
        });
      }
    } catch (e) {
      console.error("Exception occured in handleChange function---" + e);
    }
  });

  const showRoomInfo = (roomObj) => {
    let rmImg = "";
    let roomimge = [];
    if (roomObj !== undefined) {
      for (var key in state.hotelContentResp.roomData) {
        rmImg = state.hotelContentResp.roomData[roomObj.rmid];
        if (
          rmImg !== undefined &&
          rmImg.images !== undefined &&
          rmImg.images.length !== 0
        ) {
          for (let val of rmImg.images) {
            let imge = val.links["1000Px"];
            if (imge !== undefined && imge.href !== undefined) {
              roomimge.push(imge.href);
            }
          }
          dispatch({ type: "roomInfo", payload: rmImg });
        }
      }
      dispatch({ type: "roomObj", payload: roomObj });
      dispatch({ type: "roomImages", payload: roomimge });
      setShow(true);
    }
  };

  //Creating the Price Request
  const createPriceRequest = (selRoom) => {
    try {
      let request = createRequest();
      request.rooms = [selRoom];
      return request;
    } catch (e) {
      console.error("Exception occured in handleChange function---" + e);
    }
  };

  const hidemessage = () => {
    setTimeout(function () {
      dispatch({ type: "notiMessageShow", payload: false });
      dispatch({ type: "notiMessage", payload: "" });
      dispatch({ type: "notiVarient", payload: "" });
    }, 5000);
  };

  const showPopuopOcp = (size) => {
    if (size === 0) {
      dispatch({ type: "showPop", payload: true });
      dispatch({ type: "showOcpPop", payload: true });
    } else {
      dispatch({ type: "notiMessageShow", payload: true });
      dispatch({
        type: "notiMessage",
        payload: (context.translation && context.translation.selectedRoomnomoreavail),
      });
      dispatch({ type: "notiVarient", payload: "danger" });
      hidemessage();
    }
  };

  const createRequest = () => {
    let data = "";
    let policies_htl = "";
    try {
      policies_htl = state.hotelContentResp?.policies ? JSON.parse(state.hotelContentResp?.policies) : undefined;      
    } catch (error) {
      policies_htl = state.hotelContentResp?.policies ? state.hotelContentResp?.policies : undefined;      
    }
    if(policies_htl) {
      policies_htl = policies_htl["knowBeforeYouGo"];
    }
    // var userData = JSON.parse(localStorage.getItem(USER_AUTH_DATA));
    // if (userData && userData.cid === 0) {
    //   data = JSON.parse(window.sessionStorage.getItem(AUTH_DATA));
    //   localStorage.setItem(USER_AUTH_DATA, JSON.stringify(data));
    //   setContext({ logindata: data });
    // }
    const payload = {
      cur: context.userCur!==""&&context.userCur!==undefined?context.userCur:sessionStorage.getItem("SEL_CUR"),
      cid: CID,
      cname: CID_NAME,
      bktype: "DIRECT",
      onflymarkup:
        localStorage.getItem(HTL_MARKUP) !== null
          ? parseFloat(localStorage.getItem(HTL_MARKUP))
          : 0,
      region_type: localStorage.getItem("REGION_COUNTRY") !== null &&
          localStorage.getItem("REGION_COUNTRY") === "IN"
            ? "India"
            : "Qatar",
      htldtls: {
        cin:
          state.cin +
          " " +
          (state.hotelContentResp.cin !== undefined &&
          state.hotelContentResp.cin.beginTime !== undefined
            ? state.hotelContentResp.cin.beginTime
            : ""),
        cout:
          state.cout +
          " " +
          (state.hotelContentResp.cout !== undefined &&
          state.hotelContentResp.cout.time !== undefined
            ? state.hotelContentResp.cout.time
            : ""),
        htnm: state.hotelContentResp.htnm,
        htimg: state.hotelContentResp.hImages[0],
        pid: state.hotelContentResp.pid,
        add: state.hotelContentResp.add,
        area: state.hotelContentResp.area,
        city: state.hotelContentResp.city,
        country: state.hotelContentResp.country,
        landmark: state.hotelContentResp.landmark,
        rating: state.hotelContentResp.ratings,
        category: state.hotelContentResp.category,
        remarks: {...state.hotelContentResp.checkin, fees:state.hotelContentResp?.fees, policies: policies_htl},
        phone_no: state.hotelContentResp.phone_no,
        email: state.hotelContentResp.email,
        location: {
          latitude: state.hotelContentResp.location.lat,
          longitude: state.hotelContentResp.location.long,
        },
      },
    };
    //window.sessionStorage.removeItem(AUTH_DATA);
    return payload;
  };

  const finalSelRooms = () => {
    try {
      let roomsList = [];
      let crossSellSupEx = JSON.parse(localStorage.getItem(HTL_REQUEST));
      let request = createRequest();
      for (let val of state.selectedRooms) {
        roomsList.push(val.rooms);
      }
      request.rooms = roomsList;
      request.hid = state.hid;
      request.onFlyMarkup = parseFloat(localStorage.getItem(HTL_MARKUP));
      request.chdage = state.chdage;
      request.priceNote = state.priceNote;
      request.servicefee = state.serviceFee;
      request.nonights = state.noofNights;
      // request.hotelContentResp = state.hotelContentResp;
      request.flt_booking_id = fltBookingId;
      request.base_usr_exrate = base_usr_exrate;
      request.sup_base_exrate = srequest.sup_base_exrate !== undefined ? srequest.sup_base_exrate : crossSellSupEx.sup_base_exrate;
      request.base_sup_exrate = srequest.base_sup_exrate !== undefined ? srequest.base_sup_exrate : crossSellSupEx.base_sup_exrate;
      request.sup_cur = srequest.sup_cur !== "" && srequest.sup_cur !== undefined ? srequest.sup_cur : crossSellSupEx.sup_cur !== undefined && crossSellSupEx.sup_cur !== "" ? crossSellSupEx.sup_cur : "USD";
      localStorage.removeItem(HTL_MARKUP);
      window.sessionStorage.removeItem(IS_PACKAGE);
      if (request !== undefined) {
      let tokenKey = Utilities.generateAPIToken(request);
      savePriceCheck(request,tokenKey).then((response) => {
        if (response && response.data && response.data.suc) {
          navigate("/hotel/review", {
          state: {
            req: {...request, hotelContentResp: state.hotelContentResp},
          },
        });
        }
      }).catch(function (error) {
        console.error("Exception occured in finalSelRooms function---" + error);      
      });
    } else {
      dispatch({ type: 'showPop', payload: true });
    }
    } catch (e) {
      console.error("Exception occured in finalSelRooms function---" + e);
    }
  };

  const update=(type)=>{
  }

  const handleCancel = () => {
    setShow(false);
  };

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        onClick={onClick}
      />
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        onClick={onClick}
      />
    );
  }

  const sliderSettings = {
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    rtl: false,
    autoplaySpeed: 2000,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />
  };

  return (
    <>
      {loading && (
        <div className="backDrop">
        <div className="loader"></div>
        </div>
    )  }
    
      <Header setTab={update} page={"Details"}/>
      <div className="hotelDetailsPage">
        <HotelBasicDetails hotelContentResp={state.hotelContentResp} request={searchRequest}/>
     
      {isLoading && <div className="loader"></div>}
      {/* {!state.respAvail &&
        <NoResultsFound />
       
      } */}
      {state.notiMessageShow ? (
            <Alert
              description={state.notiMessage}
              type={state.notiVarient}
              closable
              // showIcon
              onClose={() =>  dispatch({ type: 'notiMessageShow', payload: false })}
              className="alertForm"
            />
          ) : (
            ""
          )}
{state.respAvail && state.roomOneOcpList.length > 0 ?
 <div className="hotelModifyClick">
 <HotelDetailTabs
   hotelContentResp={state.hotelContentResp}
   boardBasisList={state.boardBasisList}
   applyBoardBasis={applyBoardBasis}
   isRefundable={state.isRefundable}
   isNonRefundable={state.isNonRefundable}
   showClear={state.showClear}
   clearBoardBasis={clearBoardBasis}
   precepernight={state.precepernight}
   value={value}
   handleChange={handleChange}
   sendSelRoom={checkRoomPrice}
   sendRoomInfo={showRoomInfo}
   ocpnsList1={state.roomOneOcpList}
   noofNights={state.noofNights}
   pricePerNight={state.precepernight}
   onflyMkp={state.onflyMkp}
   updateResults={state.updateResults}
   roomOneOcpList={state.roomOneOcpList}
   roomTwoOcpList={state.roomTwoOcpList}
   roomThreeOcpList={state.roomThreeOcpList}
   roomForOcpList={state.roomForOcpList}
   roomFiveOcpList={state.roomFiveOcpList}
   roomSixOcpList={state.roomSixOcpList}
   roomSevenOcpList={state.roomSevenOcpList}
   roomEightOcpList={state.roomEightOcpList}
   checkRoomPrice={checkRoomPrice}
   showRoomInfo={showRoomInfo}
   selectedRooms={state.selectedRooms}
   showRoomChoice={state.showRoomChoice}
   finalSelRooms={finalSelRooms}
   reviewObj={state.reviewObj}
   expRating={state.expRating}
   usrRating={state.usrRating}
   pid={state.pid}
   searchRequest={!modify?window.searchRequest:searchRequest}
   selRoomsSize={state.selRoomsSize}
   base_usr_exrate={base_usr_exrate}
 />
</div>
: 
<div className="no-results-found">
<div>
  <Title level={5}>{context.translation && context.translation.noRoomsTitle}</Title>
  <span className="smallText">
  {context.translation && context.translation.noRooms}
  </span>
</div>
</div>
}
     
      <Modal 
        open={show}
        
        footer={null}
        className="showRoomInfoPopup"
      >
        <div className="commonFlex">
       <div className="showRoomInfoPopupTitle"> {context.translation && context.translation.rominfo}</div>
       <i className="icon icon-cancel" onClick={handleCancel}></i>
       </div>
        <div className="showRoomInfoInnerWrap">
          {state.roomInfo !== undefined &&
            <>
              <div className="sliderWrapper">
                <Slider {...sliderSettings} className="cntSlider Carousel">
                  {state.roomImages !== undefined && state.roomImages.map((image, index) => (
                    <img src={image} />
                  ))}
                </Slider>
              </div>
              <div>
                <div className="hotelselectedRoomInfo">
                  <div className="bedroomType">{state.roomInfo.name}</div>
                  <p className="roomOccupancy"><span>{context.translation && context.translation.romocc} - </span> {state.roomObj.adt} {context.translation && context.translation.adults}, {state.roomObj.chd} { context.translation && context.translation.chis}</p>
                  <p className="bedSize"> {state.roomObj.rmpr !== undefined && state.roomObj.rmpr[0] !== undefined && state.roomObj.rmpr[0].bed}</p>
                  <p className="roomSize"> <span>{context.translation && context.translation.romsize} </span> {state.roomInfo.area !== undefined && state.roomInfo.area.squareFeet !== undefined && state.roomInfo.area.squareFeet} { context.translation && context.translation.sqfeet}</p>
                  <div className="emnt"></div>
                </div>
                <div className="facilityListWrap">
                  {state.roomInfo.amenities !== undefined &&
                  <>
                   <div className="listTitle">{context.translation && context.translation.mostpopufacil}</div>
                   <Row className="facilityList">
                   
                     {Object.keys(state.roomInfo.amenities).map(key => (
                       <Col span={12}>
                         <ul>
                       <li value={key.name}><span title={state.roomInfo.amenities[key].name}>{state.roomInfo.amenities[key].name}</span></li>
                       </ul>
                       </Col>
                     ))}
                   
                   </Row>
                   </>
                  }

                </div>
              </div>
            </>
          }
        </div>
        </Modal>
      </div>
      <div className="marginTop50">
        <SecureGuarantee />
      </div>
      <Footer />
    </>
  );
}

export default HotelDetails;
