import React from "react";
import { Typography } from "antd";
import { useContext } from "react";
import { Context } from "../../../../App";


const { Title } = Typography;

const NoResultsFound = () => {
  const [context, setContext] = useContext(Context)
  return (
    <>
      <div className="no-results-found">
        <div>
          <Title level={5}>{context.translation && context.translation.noHotel}</Title>
          <span className="smallText">
          {context.translation && context.translation.noRooms}
          </span>
        </div>
      </div>
    </>
  );
};

export default NoResultsFound;
