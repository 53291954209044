/**
 * @ Author: Srikanth Bandaru
 * @ Create Time: 2023-06-15 18:16:47
 * @ Description: This container is for Authentication related data
 */

import React, { useState, useEffect, useContext } from "react";
import {
  Form,
  Input,
  Button,
  Alert,
  Space,
  Spin,
  notification,
  Typography,
  Tooltip,
} from "antd";
import Logo from "../../assets/svg/logo.svg";
import { useNavigate } from "react-router-dom";
import { Context } from "../../App";
import { publicIpv4 } from "public-ip";
import { GoogleOAuthProvider,GoogleLogin  } from '@react-oauth/google';
import FacebookLogin from 'react-facebook-login';
import AccountConfiguration from "../../services/myAccount/MyAccountService";
import { validateEmail } from "../utilities/FormValidations";
import Utilities from "../utilities/Utilities";
export const CLIENT_IP = "clientIp";
export const REGION_COUNTRY = "REGION_COUNTRY";
const GOOGLE_CLEINT_ID = process.env.REACT_APP_CNT_G_CLIENT_ID;
const FACEBOOK_CLEINT_ID = process.env.REACT_APP_CNT_F_APP_ID;

const { Title } = Typography;

const AuthenticationPopUp = ({
  visible,
  onCancel,
  headerSignin,
  reviewSignin,
  htlReviewSignin
}) => {
  const [context, setContext] = useContext(Context);
  const [form] = Form.useForm();
  const [signUp, setSignUp] = useState(false);
  const [alert, setAlert] = useState(false);
  const [loading, setLoading] = useState(false);

  const [email, setEmail] = useState("");
  const [loginEmail, setLoginEmail] = useState("");
  const [password, setPassword] = useState("");
  const [weakvalidationStatus, setWeakValidationStatus] = useState("");
  const [mediumvalidationStatus, setMediumValidationStatus] = useState("");
  const [strongvalidationStatus, setStrongValidationStatus] = useState("");
  const [loginPassword, setLoginPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState("");
  const [signInError, setSignInError] = useState(false);
  const [error, setError] = useState(false);
  const [errorForgot, setErrorForgot] = useState(false);
  const [invalidEmail, setInvalidEmail] = useState("");
  const [notiMessage, setNotiMessage] = useState("");
  const [notiMessageShow, setNotiMessageShow] = useState(false);
  const [notiVarient, setNotiVarient] = useState();
  const [forgotPassword, setforgotPassword] = useState(false);
  const [pwdMatch, setPwdMatch] = useState("");
  const [res, setRes] = useState([]);
  const [signinEmail, setSigninEmail] = useState("");
  const [correctEmail, setCorrectEmail] = useState(true);
  const [emailPasswordIncorrect, setEmailPasswordIncorrect] = useState(false);
  const [emailExists, setEmailExists] = useState(false);
  const [emailNotExists, setEmailNotExists] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const [SWW, setSWW] = useState(false);
  const [signinSWW, setSigninSWW] = useState(false);
  const [isOtp, setIsOtp] = useState(false);
  const [isSignInOtp, setIsSignInOtp] = useState(false);
  const [invalidOTP, setInvalidOTP] = useState(false);
  const [otpDigits, setOtpDigits] = useState(["", "", "", ""]);
  const [forgotPwdWindow, setForgotPwdWindow] = useState(false);
  const [forgotNewPassword, setForgotNewPassword] = useState("");
  const [forgotConfirmPassword, setForgotConfirmPassword] = useState("");
  const otpInputRefs = [
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef()
  ];

  const [passwordTooltip, setPasswordTooltip] = useState(false);

  const [timer, setTimer] = useState(0);

  useEffect(() => {
    let interval = null;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [timer, isOtp, timer === 0]);

  useEffect(() => {
    setEmailExists(false);
  }, [email])

  const navigate = useNavigate();

  const region = sessionStorage.getItem("REGION_COUNTRY");

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("SIGNINRESPONSE"));
    if (data !== null) {
      setSigninEmail(data.res.email);
    }
  }, [localStorage.getItem("SIGNINRESPONSE")]);

  const validateOtpForm = () => {
    setError(false);
    setInvalidOTP(false);
    if (otpDigits[0] === "" || otpDigits[1] === "" || otpDigits[2] === "" || otpDigits[3] === "") {
      setError(true);
      return false;
    }
    return true;
  };

  const handleOtpChange = (index, value) => {
    const newOtpDigits = [...otpDigits];
    newOtpDigits[index] = value;

    // Move focus to the previous input field if the current field is empty
    if (value === "" && index > 0 && newOtpDigits[index - 1] !== "") {
      otpInputRefs[index - 1].current.focus();
    }

    // Move focus to the next input field if the current field is not empty
    if (index < 3 && value.match(/^\d+$/)) {
      otpInputRefs[index + 1].current.focus();
    }

    setOtpDigits(newOtpDigits);
  };

  const handleKeyDown = (index, event) => {
    if (event.key === "Backspace" && index > 0 && !event.target.value) {
      otpInputRefs[index - 1].current.focus();
    }
  };

  // Function to validate password and set validation status
  const validatePassword = (password) => {
    // password validation checks
    let weakValidationStatus = "";
    let strongValidationStatus = "";
    let mediumValidationStatus = "";

    if (password.length < 6 || password.length > 16) {
      weakValidationStatus = (context.translation && context.translation.weak);
      setWeakValidationStatus(weakValidationStatus);
      setStrongValidationStatus("");
      setMediumValidationStatus("");
      setPasswordTooltip(true);
      return false;

    } else if (
      /[a-z]/.test(password) &&
      /[A-Z]/.test(password) &&
      /\d/.test(password) &&
      /[!@#$%^&*]/.test(password)
    ) {
      strongValidationStatus = (context.translation && context.translation.strong);
      setPasswordTooltip(false);
    } else {
      mediumValidationStatus = (context.translation && context.translation.medium);
      setMediumValidationStatus(mediumValidationStatus);
      setWeakValidationStatus("");
      setStrongValidationStatus("");
      setPasswordTooltip(true);
      return false;
    }

    setWeakValidationStatus("");
    setMediumValidationStatus("");
    setStrongValidationStatus(strongValidationStatus);
    setPasswordTooltip(false);
    return true;
  };

  const handleSigninVerifyOtp = () => {
    if (validateOtpForm()) {
      if (otpDigits[0] !== "" && otpDigits[1] !== "" && otpDigits[2] !== "" && otpDigits[3] !== "") {
        const otp = otpDigits.join("");
        try {
          const payload = {
            email: loginEmail,
            otp: otp,
            type: "SIGNIN",
          };
          let tokenKey = Utilities.generateAPIToken(payload);
          AccountConfiguration.verifyOTP(payload,tokenKey).then((response) => {
            if (response.data.suc) {
              if(localStorage.getItem(REGION_COUNTRY)){
                response.data.region_type = localStorage.getItem(REGION_COUNTRY) === 'IN'?"India":"Qatar";
              }
              localStorage.setItem(
                "SIGNINRESPONSE",
                JSON.stringify(response.data)
              );
              setContext({ ...context, user_resp: response.data, alert:"SIGNIN" });
              setEmailPasswordIncorrect(false);
              setRes("response.data");
              setNotiMessageShow(true);
              setNotiMessage(context.translation && context.translation.loginsuc);
              setNotiVarient(context.translation && context.translation.suc);
              hidemessage();
              setTimeout(function () {
                setLoginEmail("");
                setLoginPassword("");
                onCancel();
                setOtpDigits(["", "", "", ""]);
              }, 1000);
            } else if (response.data.suc === false) {
              setAlert(false);
              setInvalidOTP(true);
            } else if (
              // response.data.status === 404 ||
              response !== null &&
              response?.data?.errors?.length > 0
            ) {
              setLoading(false);
              setSigninSWW(true);
            } else {
              setSignInError(true);
              // setAlert(true);
              // navigate("/");
              setNotiMessageShow(true);
              // setNotiMessage("Email or Password Incorrect");
              setNotiVarient("error");
              hidemessage();
              setEmailPasswordIncorrect(true);
            }
          }).catch(function (error) {
            console.error("Error:" + error);      
          });
        } catch (error) {
          console.error("Error:", error);
        } finally {
          setTimeout(() => {
            // setEmail("");
            // setPassword("");
          }, 2000);
        }
      }
    } else {
    }
  };

  const handleSignupVerifyOtp = () => {
    if (validateOtpForm()) {
      if (otpDigits[0] !== "" && otpDigits[1] !== "" && otpDigits[2] !== "" && otpDigits[3] !== "") {
        const otp = otpDigits.join("");
        try {
          const payload = {
            email: email,
            otp: otp,
          };
          let tokenKey = Utilities.generateAPIToken(payload);
          AccountConfiguration.verifyOTP(payload,tokenKey).then((response) => {
            if (response.data.suc) {
              // setAlert(true);
              setNotiMessageShow(true);
              setNotiMessage(context.translation && context.translation.regiSuc);
              setNotiVarient(context.translation && context.translation.suc);
              hidemessage();
              setContext({ ...context, alert:"SIGNUP" });
              setTimeout(function () {
                onCancel();
                setIsOtp(false);
                setEmail("");
                setPassword("");
                setConfirmPassword("");
                setSignUp(!signUp);
                setOtpDigits(["", "", "", ""]);
              }, 1000);
            } else if (response.data.suc === false) {
              setAlert(false);
              setInvalidOTP(true);
            } else if (true) {
            } else {
            }
          }).catch(function (error) {
            console.error("Error:" + error);      
          });
        } catch (error) {
          console.error("Error:" + error);
        } finally {
        }
      } else {
      }
    }
  };

  const handleForgotPasswordVerifyOtp = () => {
    if (validateOtpForm()) {
      if (otpDigits[0] !== "" && otpDigits[1] !== "" && otpDigits[2] !== "" && otpDigits[3] !== "") {
        const otp = otpDigits.join("");
        try {
          const payload = {
            email: forgotPasswordEmail,
            otp: otp,
          };
          let tokenKey = Utilities.generateAPIToken(payload);
          AccountConfiguration.verifyOTP(payload,tokenKey).then((response) => {
            if (
              response.data.res?.is_OTP !== null &&
              response.data.res?.is_OTP
            ) {
              // setAlert(true);
              setNotiMessageShow(true);
              setNotiMessage(context.translation && context.translation.regiSuc);
              setNotiVarient(context.translation && context.translation.suc);
              hidemessage();
              setTimeout(function () {
                // onCancel();
                setEmail("");
                setPassword("");
                setConfirmPassword("");
                // setSignUp(!signUp);
                setOtpDigits(["", "", "", ""]);
              }, 1000);
              // navigate("../my-account/change-password");
              setForgotPwdWindow(true);
            }
            if (response.data.suc !== null && !response.data.suc) {
              setAlert(false);
              setInvalidOTP(true);
            } else if (true) {
            } else {
            }
          }).catch(function (error) {
            console.error("Error:" + error);      
          });
        } catch (error) {
          console.error("Error:" + error);
        } finally {
        }
      }
    } else {
    }
  };

  const handleResendOtp = (type) => () => {
    setLoading(true);
    setInvalidOTP(false);
    setError(false);
    setOtpDigits(["", "", "", ""]);
    otpInputRefs[0].current.focus();
    let payload;
    if (type === "signup") {
      payload = {
        email: email,
        type: "RESEND",
      };
    }
    if (type === "signin") {
      payload = {
        email: loginEmail,
        type: "RESEND",
      };
    }
    if (type === "forgotPassword") {
      payload = {
        email: forgotPasswordEmail,
        type: "RESEND",
        forgotType: "FORGOT",
      };
    }
    try { 
     let tokenKey = Utilities.generateAPIToken(payload);
      AccountConfiguration.resentOtp(payload,tokenKey).then((response) => {
        if (response.data.suc) {
          setLoading(false);
          api.open({
            message: (context.translation && context.translation.newoptSent),
            duration: 2,
          });
          setTimer(900); // 900 seconds = 15 minutes
        } else if (
          // response.data.status === 404 ||
          response !== null &&
          response?.data?.errors?.length > 0
        ) {
          setLoading(false);
          setSigninSWW(true);
        }
      }).catch(function (error) {
        console.error("Error:" + error);      
      });      
    } catch (error) {
      console.error("Error:" + error);      
    }
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  };

  //Validates and hits the sign up api
  const handleSignUpSubmit = async (e) => {
    e.preventDefault();

    if (!validateEmail(email) && email !== "") {
      setInvalidEmail(context.translation && context.translation.validem);
      setError(true);
      setEmailExists(false);
    }

    if (validateEmail(email)) {
      setInvalidEmail("");
    }

    if (
      email === "" &&
      password === "" &&
      confirmPassword === ""
    ) {
      setError(true);
    } else if (email === "") {
      setError(true);
    } else if (email !== "" &&
      password === "" &&
      confirmPassword === ""
    ) {
      setError(true);
    } else if (
      !validateEmail(email) &&
      email !== "" &&
      password !== "" &&
      confirmPassword !== ""
    ) {
      setError(false);
    }

    if (password !== confirmPassword) {
      setError(true);
      setPwdMatch(context.translation && context.translation.passDoesntmatch);
    }

    if (
      !validateEmail(email) &&
      email !== "" &&
      password !== "" &&
      confirmPassword !== ""
    ) {
      setInvalidEmail(context.translation && context.translation.validem);
      setError(true);
    }

    if (validateEmail(email) && validatePassword(password)) {
      if (
        email !== "" &&
        password !== "" &&
        confirmPassword !== "" &&
        password === confirmPassword &&
        validateEmail(email)
      ) {
        setError(false);
        setLoading(true);
        setEmailExists(false);
        try {
          const payload = {
            email: email,
            password: password,
            region_type: region === "IN" ? "India" : "Qatar",
            ip_addr: await publicIpv4(),
            // reg_country: region,
          };
          let tokenKey = Utilities.generateAPIToken(payload);
          AccountConfiguration.userSignup(payload,tokenKey).then((response) => {
            if (
              response.data.res?.is_OTP !== null &&
              response.data.res?.is_OTP
            ) {
              api.open({
                // message: `We have sent OTP to ${email}`,
                message: (context.translation && context.translation.optSent),
                duration: 2,
              });
              setTimer(900); // 900 seconds = 15 minutes
              setTimeout(() => {
                setIsOtp(true);
                setLoading(false);
              }, 100);
            }
            // else if (!response.data.res.is_OTP) {
            //   setLoading(false);
            //   setSWW(true);
            // }
            // if (response.data !== undefined && response.data.suc) {
            //   setLoading(false);
            //   setEmailExists(false);
            //   setAlert(true);
            //   navigate("/");
            //   setNotiMessageShow(true);
            //   setNotiMessage("Registered Successfully");
            //   setNotiVarient({context.translation && context.translation.suc});
            //   hidemessage();
            //   setTimeout(function () {
            //     onCancel();
            //     setEmail("");
            //     setPassword("");
            //     setConfirmPassword("");
            //     setSignUp(!signUp);
            //   }, 2000);
            // }
            else if (
              // response.data.status === 404 ||
              response !== null &&
              response?.data?.errors?.length > 0
            ) {
              setLoading(false);
              setSWW(true);
            } else {
              setLoading(false);
              setEmailExists(true);
              // navigate("/");
              setNotiMessageShow(true);
              setNotiVarient("error");
              hidemessage();
              setTimeout(function () {}, 2000);
            }
          }).catch(function (error) {
            console.error("Error:" + error);      
          });
        } catch (error) {
          console.error("Error:" + error);
          setLoading(false);
          setSWW(true);
        } finally {
          setTimeout(() => {}, 2000);
        }
      } else {
        setInvalidEmail(context.translation && context.translation.validem);
      }
    }
  };

  const validateSignIn = () => {
    if (email === "") {
      setSignInError(true);
      return false;
    } else if (password === "") {
      setSignInError(true);
      return false;
    } else if (!validateEmail(email)) {
      setInvalidEmail(true);
      return false;
    } else if (validateEmail(email)) {
      setInvalidEmail(false);
      return false;
    }
    return true;
  };

  //Validates and hits the sign in api
  const handleSignInSubmit = async (e) => {
    e.preventDefault();
    if (loginEmail === "") {
      // setSignInError('Please enter email');
      setSignInError(true);
      return;
    }

    if (!validateEmail(loginEmail)) {
      setInvalidEmail(context.translation && context.translation.validem);
      // return;
    }
    if (validateEmail(loginEmail)) {
      setInvalidEmail("");
    }

    if (loginEmail === "") {
      // setSignInError('Please enter email');
      setSignInError(true);
      // return;
    }

    if (loginEmail !== "") {
      setSignInError(false);
    }

    if (loginPassword === "") {
      // setSignInError('Please enter password');
      setSignInError(true);
      // return;
    }

    if (loginPassword !== "") {
      setSignInError(false);
      // setSignInError("");
    }

    if (validateEmail(loginEmail)) {
      if (loginEmail !== "" && loginPassword !== "") {
        setLoading(true);
        try {
          const payload = {
            email: loginEmail,
            pwd: loginPassword,
          };
          let tokenKey = Utilities.generateAPIToken(payload);
          AccountConfiguration.userLogin(payload,tokenKey).then((response) => {
            if (
              response.data.res?.is_OTP !== null &&
              response.data.res?.is_OTP
            ) {              
              api.open({
                // message: `We have sent OTP to ${loginEmail}`,
                message: (context.translation && context.translation.optSent),
                duration: 2,
              });
              setTimer(900);
              setTimeout(() => {
                setIsSignInOtp(true);
                setLoading(false);
              }, 100);
            }
            if (
              response.data.res?.is_OTP !== null &&
              !response.data.res?.is_OTP &&
              response.data !== undefined &&
              response.data.suc
            ) {
              localStorage.setItem(
                "SIGNINRESPONSE",
                JSON.stringify(response.data)
              );
              setContext({ ...context, user_resp: response.data, alert:"SIGNIN" });
              setEmailPasswordIncorrect(false);
              setRes("response.data");
              // setAlert(true);
              // Redirect to profile page after successful sign-in
              if (headerSignin) {
                // navigate("/");
              }
              if (reviewSignin) {
                setTimeout(() => {
                  onCancel();
                }, 2000);
              }
              if (htlReviewSignin) {
                setTimeout(() => {
                  onCancel();
                }, 2000);
              }
              setNotiMessageShow(true);
              setNotiMessage(context.translation && context.translation.loginsuc);
              setNotiVarient(context.translation && context.translation.suc);
              hidemessage();
              setTimeout(function () {
                setLoginEmail("");
                setLoginPassword("");
                onCancel();
              }, 1000);
            } else if (
              // response.data.status === 404 ||
              response !== null &&
              response?.data?.errors?.length > 0
            ) {
              setLoading(false);
              setSigninSWW(true);
            } else {
              setLoading(false);
              setSignInError(true);
              // setAlert(true);
              // navigate("/");
              setNotiMessageShow(true);
              // setNotiMessage("Email or Password Incorrect");
              setNotiVarient("error");
              hidemessage();
              setEmailPasswordIncorrect(true);
            }
          }).catch(function (error) {
            console.error("Error:" + error);      
          });
        } catch (error) {
          console.error("Error:", error);
        } finally {
          setTimeout(() => {
            // setEmail("");
            // setPassword("");
          }, 2000);
        }
      }
    }
  };

  const handleForgotChangePasswordSubmit = async (e) => {
    e.preventDefault();

    if (
      forgotNewPassword === "" &&
      forgotConfirmPassword === ""
    ) {
      setError(true);
    } else if (forgotNewPassword !== "" && 
      forgotConfirmPassword === "") {
      setError(true);
    } else if (forgotConfirmPassword !== "" &&
      forgotNewPassword === ""
    ) {
      setError(true);
    } else if (
      forgotConfirmPassword !== "" &&
      forgotNewPassword !== ""
    ) {
      setError(false);
    }
    if (forgotNewPassword !== forgotConfirmPassword) {
      setError(true);
      setPwdMatch(context.translation && context.translation.passDoesntmatch);
    }

    if (forgotNewPassword !== "" && forgotConfirmPassword !== "" && 
      forgotNewPassword === forgotConfirmPassword) {
      setLoading(true);
      try {
        const payload = {
          email: forgotPasswordEmail,
          pwd: forgotNewPassword,
          changepwd: true
        };
        let tokenKey = Utilities.generateAPIToken(payload);
        AccountConfiguration.userLogin(payload,tokenKey).then((response) => {
          if (
            response.data.res?.is_OTP !== null &&
            response.data.res?.is_OTP
          ) {              
            api.open({
              // message: `We have sent OTP to ${loginEmail}`,
              message: (context.translation && context.translation.optSent),
              duration: 2,
            });
            setTimer(900);
            setTimeout(() => {
              setIsSignInOtp(true);
              setLoading(false);
            }, 100);
          }
          if (
            response.data.res?.is_OTP !== null &&
            !response.data.res?.is_OTP &&
            response.data !== undefined &&
            response.data.suc
          ) {
            localStorage.setItem(
              "SIGNINRESPONSE",
              JSON.stringify(response.data)
            );
            setContext({ ...context, user_resp: response.data, alert:"SIGNIN" });
            setEmailPasswordIncorrect(false);
            setRes("response.data");
            // setAlert(true);
            // Redirect to profile page after successful sign-in
            if (headerSignin) {
              // navigate("/");
            }
            if (reviewSignin) {
              setTimeout(() => {
                onCancel();
              }, 2000);
            }
            if (htlReviewSignin) {
              setTimeout(() => {
                onCancel();
              }, 2000);
            }
            setNotiMessageShow(true);
            setNotiMessage(context.translation && context.translation.loginsuc);
            setNotiVarient(context.translation && context.translation.suc);
            hidemessage();
            setTimeout(function () {
              setLoginEmail("");
              setLoginPassword("");
              onCancel();
            }, 1000);
          } else if (
            // response.data.status === 404 ||
            response !== null &&
            response?.data?.errors?.length > 0
          ) {
            setLoading(false);
            setSigninSWW(true);
          } else {
            setLoading(false);
            setSignInError(true);
            // setAlert(true);
            // navigate("/");
            setNotiMessageShow(true);
            // setNotiMessage("Email or Password Incorrect");
            setNotiVarient("error");
            hidemessage();
            setEmailPasswordIncorrect(true);
          }
        }).catch(function (error) {
          console.error("Error:" + error);      
        });
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setTimeout(() => {
          // setEmail("");
          // setPassword("");
        }, 2000);
      }
    }

  }

  //For forgot password and email generation
  const handleForgotPasswordEmailSubmit = async (e) => {
    setEmailNotExists(false);
    e.preventDefault();

    if (forgotPasswordEmail === "") {
      setErrorForgot(true);
      // return;
    }

    if (!validateEmail(forgotPasswordEmail)) {
      setInvalidEmail(context.translation && context.translation.validem);
      // return;
    }
    if (validateEmail(forgotPasswordEmail)) {
      setInvalidEmail("");

      if (forgotPasswordEmail !== "" && validateEmail(forgotPasswordEmail)) {
        setLoading(true);
        try {
          const payload = {
            email: forgotPasswordEmail,
          };
          let tokenKey = Utilities.generateAPIToken(payload);
          AccountConfiguration.forgotPassword(payload,tokenKey).then((response) => {
            if (
              response.data.res?.is_OTP !== null &&
              response.data.res?.is_OTP
            ) {
              api.open({
                // message: `We have sent OTP to ${forgotPasswordEmail}`,
                message: (context.translation && context.translation.optSent),
                duration: 2,
              });
              setTimer(900); // 900 seconds = 15 minutes
              setTimeout(() => {
                setIsOtp(true);
                setLoading(false);
              }, 100);
            }
            if (response.data !== undefined && response.data.suc) {
              // setAlert(true);
              // setEmailNotExists(false);
              // navigate("/");
              // setNotiMessageShow(true);
              // setNotiMessage("Email Sent");
              // setNotiVarient({context.translation && context.translation.suc});
              hidemessage();
              // api.open({
              //   message: "OTP Sent to your mail. Please check",
              //   duration: 2,
              // });
              // setTimeout(function () {
              //   onCancel();
              // }, 2000);
              // setTimeout(function () {
              //   setSignUp(!signUp);
              // }, 3000);
            } else {
              setLoading(false);
              setEmailNotExists(true);
              // setAlert(true);
              // navigate("/");
              // setNotiMessageShow(true);
              // setNotiMessage("Email Not Exists");
              // setNotiVarient("error");
              // hidemessage();
              // api.open({
              //   message: "Email not exists",
              //   // description: "Profile details updated successfully..."
              //   duration: 2,
              // });
            }
          }).catch(function (error) {
            console.error("Error:" + error);      
          });
        } catch (error) {
          console.error("Error:", error);
          api.open({
            message: (context.translation && context.translation.wentWrong),
            duration: 2,
          });
        } finally {
          setTimeout(() => {
            setEmail("");
            setPassword("");
          }, 2000);
        }
      }
    }
  };

  const handleSignIn = () => {
    setPasswordTooltip(false);
    setSignUp(!signUp);
    setTimeout(function () {
      setforgotPassword(false);
    }, 1000);
    setForgotPasswordEmail("");
    setErrorForgot(false);
    setLoginEmail("");
    setLoginPassword("");
    setSignInError(false);
    setEmail("");
    setPassword("");
    setConfirmPassword("");
    setError(false);
    setEmailExists(false);
    setEmailPasswordIncorrect(false);
    setIsOtp(false);
  };

  const handleForgot = () => {
    setforgotPassword(!forgotPassword);
    setForgotPwdWindow(false);
    setSignUp(!signUp);
    setLoginEmail("");
    setLoginPassword("");
    setSignInError(false);
  };

  const hidemessage = () => {
    setTimeout(function () {
      setNotiMessageShow(false);
      setNotiMessage("");
      setNotiVarient("");
    }, 4000);
  };

  const data = JSON.parse(localStorage.getItem("SIGNINRESPONSE"));

  const onCloseAuth = () => {   
    setPasswordTooltip(false);
    setforgotPassword(false);
    setForgotPasswordEmail("");
    setErrorForgot(false);
    setLoginEmail("");
    setLoginPassword("");
    setSignInError(false);
    setEmail("");
    setPassword("");
    setConfirmPassword("");
    setError(false);
    setEmailExists(false);
    setEmailPasswordIncorrect(false);
    setEmailNotExists(false);
  }
  //REGITRING GOOGLE USER
  const gmailRegister= async (resp)=>{
    try {
      if(resp.credential!==undefined){
        const request={
          "code":resp.credential,
          "ip_addr": await publicIpv4() ,
          "region_type": region === "IN" ? "India" : "Qatar",
        }
      let tokenKey = Utilities.generateAPIToken(request);
      AccountConfiguration.saveGmailUser(request,tokenKey).then((response) => {
        if (response.data.suc){
          localStorage.setItem(
            "SIGNINRESPONSE",
            JSON.stringify(response.data)
          );
          setContext({ ...context, user_resp: response.data, alert:"SIGNIN" });
          setEmailPasswordIncorrect(false);
          setNotiMessageShow(true);
          setNotiMessage(context.translation && context.translation.loginsuc);
          setNotiVarient(context.translation && context.translation.suc);
          hidemessage();
          setTimeout(function () {
            setLoginEmail("");
            setLoginPassword("");
            onCancel();
            setLoading(false);
          }, 1000);
        }else{
          api.open({
            message: (context.translation && context.translation.utologin),
            duration: 2,
          });
        }
      }).catch(function (error) {
        console.error("Error:" + error);      
      });
      }else{
        api.open({
          message: (context.translation && context.translation.somthgWtrng),
          duration: 2,
        });
      }      
    } catch (error) {
      console.error("Error:", error);      
    }
}

//REGITRING FACE BOOK USER
const faceBookRegister= async (resp)=>{
  try {
    if(resp.credential!==undefined){
      const request={
        "code":resp.credential,
        "ip_addr": await publicIpv4() ,
        "region_type": region === "IN" ? "India" : "Qatar",
      }
    AccountConfiguration.saveFBUser(request).then((response) => {
      if (response.data.suc){
        localStorage.setItem(
          "SIGNINRESPONSE",
          JSON.stringify(response.data)
        );
        setContext({ ...context, user_resp: response.data, alert:"SIGNIN" });
        setEmailPasswordIncorrect(false);
        setNotiMessageShow(true);
        setNotiMessage(context.translation && context.translation.loginsuc);
        setNotiVarient(context.translation && context.translation.suc);
        hidemessage();
        setTimeout(function () {
          setLoginEmail("");
          setLoginPassword("");
          onCancel();
          setLoading(false);
        }, 1000);
      }else{
        api.open({
          message: (context.translation && context.translation.utologin),
          duration: 2,
        });
      }
    }).catch(function (error) {
      console.error("Error:" + error);      
    });
    }else{
      // api.open({
      //   message: (context.translation && context.translation.somthgWtrng),
      //   duration: 2,
      // });
    }    
  } catch (error) {
    console.error("Error:", error);    
  }
}

  return (
    <>
      {contextHolder}
      <div className={"ssPopup " + (visible ? "show" : "hide")} width={470}>
        <div className="ssPopup-mask">
          {alert ? (
            <Alert
              description={notiMessage}
              type={notiVarient}
              closable
              // showIcon
              onClose={() => setNotiMessageShow(false)}
            />
          ) : (
            ""
          )}
        </div>
        <div className={"signinSignup " + (signUp ? "flip" : "")}>
          <div className=" front">
            <div className=" loginSection">
              {!isSignInOtp && (
                <button type="button" className="close" onClick={() => {onCancel(); onCloseAuth()}}>
                  <i className="icon icon-cancel"></i>
                </button>
              )}
              <h2>
                <span>{context.translation && context.translation.getStartedwith}</span>{" "}
                <img src={Logo} alt="Click N Travel" height={24} />{" "}
              </h2>
              <span className="subTextSign">
                <i className="icon icon-lock"></i>
                {!isSignInOtp
                  ? context.translation && context.translation.weNever
                  : `${context.translation && context.translation.sentOtp} ${loginEmail}`}
              </span>

              <ul className="SocLoginBtns">
                <li>
                  <GoogleOAuthProvider clientId={GOOGLE_CLEINT_ID}>
                  <GoogleLogin
                    //type="icon"
                    shape="circle"
                    onSuccess={credentialResponse => {
                      gmailRegister(credentialResponse)
                    }}
                    onError={() => {
                      console.error('Login Failed');
                    }}
                  />
                   </GoogleOAuthProvider>
                </li>
                {/* <li className="facebookAuth">
                  <FacebookLogin
                  size="medium"
                  appId={FACEBOOK_CLEINT_ID}
                  autoLoad={false}
                  fields="name,email,picture"
                  cssClass="sprite sprite-facebook"
                  textButton={""}
                  //icon="fa-facebook"
                  //onClick={componentClicked}
                  callback={faceBookRegister}
                  
                  />
                </li> */}
                {/* <li>
                  <i className="sprite sprite-apple"></i> {context.translation && context.translation.apple}
                </li> */}
              </ul>

              <div className="divider">
                <strong>{context.translation && context.translation.orSign}</strong>
              </div>

              {!isSignInOtp && (
                <Form layout="vertical">
                  <Form.Item
                    label={context.translation && context.translation.emailAd}
                    // name="email"
                    rules={[{ required: true, message: context.translation && context.translation.eYe }]}
                    className="inputTitle"
                  >
                    <Input
                      type="email"
                      value={loginEmail}
                      onChange={(e) => setLoginEmail(e.target.value)}
                      className={signInError ? "formError" : ""}
                      placeholder={context.translation && context.translation.eYe}
                    />
                    <small className="errorText">
                      {loginEmail !== "" && !validateEmail(loginEmail)
                        ? invalidEmail
                        : ""}
                    </small>
                  </Form.Item>
                  <Form.Item
                    label={context.translation && context.translation.pwd}
                    // name="password"
                    rules={[{ required: true, message: context.translation && context.translation.eYp }]}
                    className="inputTitle inputpasswordTitle"
                  >
                    <Input.Password
                      // type="password"
                      value={loginPassword}
                      onChange={(e) => setLoginPassword(e.target.value)}
                      autoComplete="new-password"
                      className={signInError ? "formError" : ""}
                      placeholder={context.translation && context.translation.eYp}
                    />
                    <small className="errorText">
                      {emailPasswordIncorrect
                        ? (context.translation && context.translation.validCred)
                        : ""}
                    </small>
                    {
                      <small style={{ color: "red" }}>
                        {signinSWW
                          ? (context.translation && context.translation.wentWrong)
                          : ""}
                      </small>
                    }
                  </Form.Item>
                  <span className="forgotText" onClick={handleForgot}>
                    {context.translation && context.translation.forgotp}
                  </span>
                  
                  <Button
                    type="primary"
                    className="cntBtn"
                    onClick={handleSignInSubmit}
                    disabled={loading ? true : false}
                    block
                  >
                    {loading ? (
                      // <Space size={"middle"}>
                      //   <Spin size="large" />
                      // </Space>
                      <span className="circleLoader"></span>
                    ) : (
                      context.translation && context.translation.signin
                    )}
                    <i className="sprite btn-arrow-sprite"></i>
                  </Button>
                </Form>
              )}

              {isSignInOtp && (
                <div>
                  <Title level={5} className="otpTitle">
                    {context.translation && context.translation.eOtp}
                  </Title>
                  <Space.Compact>
                    {otpDigits.map((digit, index) => (
                      <Input
                        key={index}
                        ref={otpInputRefs[index]}
                        value={digit}
                        onChange={(e) => handleOtpChange(index, e.target.value)}
                        onKeyDown={(e) => handleKeyDown(index, e)}
                        maxLength={1}
                        className={
                          error || invalidOTP
                            ? "formError enterOTPData"
                            : "enterOTPData"
                        }
                        style={{ width: "25%" }}
                      />
                    ))}
                  </Space.Compact>
                  {timer !== 0 && error && (
                    <small className="errorText otpExpired">
                      {context.translation && context.translation.plzeOtp}
                    </small>
                  )}

                  {timer !== 0 && invalidOTP ? (
                    <small className="errorText otpExpired">{context.translation && context.translation.plzevOtp}</small>
                  ) : (
                    ""
                  )}
                  {timer === 0 ? (
                    <small className="errorText otpExpired">{context.translation && context.translation.plzresendOtp}</small>
                  ) : (
                    ""
                  )}
                  {/* {isSignInOtp && timer > 0 && ( */}
                  {/* <div className="otpExpires">
                    {context.translation && context.translation.otpExpires}{" "}
                    <span className="otpTimerRunning"> */}
                      {/* {formatTime(timer)} */}
                      {/* 00:00
                      </span>{" "}
                    sec
                  </div> */}
                  {/* )} */}

                  {timer === 0 && (
                    <div>
                      {/* {context.translation && context.translation.resendOtp} */}
                      {/* Your OTP got Expired. A new OTP is sent to your mail. */}
                    </div>
                  )}

                  <Button
                    type="primary"
                    onClick={handleSigninVerifyOtp}
                    className="cntBtn"   
                    disabled={timer === 0 ? true : false}
                    block
                  >
                    {context.translation && context.translation.verifyOtp}
                  </Button>
                  <div>
                    <span className="dontGetCode">{context.translation && context.translation.didntgetcode}</span>
                    <Button
                      onClick={handleResendOtp("signin")}
                      disabled={loading ? true : false}
                      style={{ border: "none" }}
                    >
                      {loading ? (
                        // <Space size={"middle"}>
                        //   <Spin size="large" />
                        // </Space>
                        <span className="circleLoader"></span>
                      ) : (
                        <span className="resendOTP">{context.translation && context.translation.resendOtp}</span>
                      )}
                    </Button>
                  </div>
                </div>
              )}

              {!isSignInOtp && (
                <div className="bottomNote">
                  {context.translation && context.translation.donthavAccount}
                  <span onClick={handleSignIn}>{context.translation && context.translation.signUp}</span>
                </div>
              )}
            </div>
          </div>

          <div className=" back">
            {!forgotPassword ? (
              <div className=" signupSection">
                {!isOtp && (
                  <button type="button" className="close" onClick={() => {onCancel(); onCloseAuth()}}>
                    <i className="icon icon-cancel"></i>
                  </button>
                )}

                <h2>
                  <span>{context.translation && context.translation.getStartedwith}</span>{" "}
                  <img src={Logo} alt="Click N Travel" height={24} />{" "}
                </h2>
                <span className="subTextSign">
                  <i className="icon icon-lock"></i>
                  {!isOtp
                    ? (context.translation && context.translation.weNever)
                    : `${context.translation && context.translation.sentOtp} ${email}`}
                </span>

                <ul className="SocLoginBtns">
                  {/* <li>
                    <i className="sprite sprite-google"></i> {context.translation && context.translation.google}
                  </li>
                  <li>
                    <i className="sprite sprite-facebook"></i> {context.translation && context.translation.fb}
                  </li>
                  <li>
                    <i className="sprite sprite-apple"></i> {context.translation && context.translation.apple}
                  </li> */}
                  <li>
                    <GoogleOAuthProvider clientId={GOOGLE_CLEINT_ID}>
                    <GoogleLogin
                      //type="icon"
                      shape="circle"
                      onSuccess={credentialResponse => {
                        gmailRegister(credentialResponse)
                      }}
                      onError={() => {
                        console.error('Login Failed');
                      }}
                    />
                   </GoogleOAuthProvider>
                  </li>
                  {/* <li className="facebookAuth">
                    <FacebookLogin
                    size="medium"
                    appId={FACEBOOK_CLEINT_ID}
                    autoLoad={false}
                    fields="name,email,picture"
                    cssClass="sprite sprite-facebook"
                    textButton={""}
                    //icon="fa-facebook"
                    //onClick={componentClicked}
                    callback={responseFacebook => {
                    console.log("FACE BOOK RESP : "+JSON.stringify(responseFacebook));
                    }}
                    />
                  </li> */}
                  {/* <li className="appleAuth">
                    <i className="sprite sprite-apple"></i> {context.translation && context.translation.apple}
                  </li> */}
                </ul>
                <div className="divider">
                  <strong>{context.translation && context.translation.orSignup}</strong>
                </div>

                {!isOtp && (
                  <Form layout="vertical">
                    <Form.Item
                      label={context.translation && context.translation.emailAd}
                      // name="email"
                      rules={[{ required: true, message: context.translation && context.translation.eYe }]}
                      className="inputTitle"
                    >
                      <Input
                        type="email"
                        value={email}
                        disabled={loading ? true : false}
                        onChange={(e) => setEmail(e.target.value)}
                        className={
                          error && !validateEmail(email) && "formError "
                        }
                        placeholder={context.translation && context.translation.eYe}
                      />
                      <small className="errorText">
                        {email !== "" && !validateEmail(email)
                          ? invalidEmail
                          : ""}
                      </small>

                      <small className="errorText">
                        {emailExists ? (context.translation && context.translation.alrdyAcc) : ""}
                      </small>
                    </Form.Item>

                    <Form.Item
                      label={context.translation && context.translation.pwd}
                      // name="password"
                      rules={[
                        { required: true, message: context.translation && context.translation.eYp },
                      ]}
                      className="inputTitle"
                    >
                      <Tooltip overlayClassName="layoverPassword" placement="bottomRight" open={password && passwordTooltip?true:false} title={
                        <small><i className="icon icon-Info"></i> {context.translation && context.translation.warning}</small>
                      }>
                     
                     <Input.Password
                        // type="password"
                        value={password}
                        onChange={(e) => {
                          return (
                            setPassword(e.target.value),
                            validatePassword(e.target.value)
                          );
                        }}
                        autoComplete="new-password"
                        className={error && password === "" ? "formError" : ""}
                        placeholder={context.translation && context.translation.eYp}
                        maxLength={16}
                      />
                  </Tooltip>
                      
                      
                      {false ? password : ""}
                      {/* <small className="errorText">{error}</small> */}
                      {password && (
                        <>

                        <span

                          style={{

                            
                            position: "absolute",
                            top: "-33px",
                            right: "111px",
                            borderBottom: "2px solid #ed1d24",
                            paddingBottom: "0",
                            zIndex: "99",

                            color:

                              weakvalidationStatus === (context.translation && context.translation.weak)

                                ? "#ed1d24"

                                : "#ed1d24",

                          }}

                        >

                          {weakvalidationStatus}

                        </span>

                        <span

                          style={{

                            position: "absolute",
                            top: "-33px",
                            right: "51px",
                            borderBottom: "2px solid #E6BB07",
                            paddingBottom: "0",
                            zIndex: "99",

                            color:

                              mediumvalidationStatus === (context.translation && context.translation.weak)

                                ? "#ed1d24"

                                : mediumvalidationStatus === (context.translation && context.translation.medium)

                                ? "#E6BB07"

                                : "#008D00",

                          }}

                        >

                          {mediumvalidationStatus}

                        </span>

                        <span

                          style={{

                            position: "absolute",
                            top: "-33px",
                            right: "1px",
                            borderBottom: "2px solid #008D00",
                            paddingBottom: "0",
                            zIndex: "99",

                            color:

                              strongvalidationStatus === (context.translation && context.translation.weak)

                                ? "#ed1d24"

                                : strongvalidationStatus === (context.translation && context.translation.medium)

                                ? "#E6BB07"

                                : "#008D00",

                          }}

                        >

                          {strongvalidationStatus}

                        </span>
                        <span className="weakBorderBottom"></span>
                        <span className="mediumkBorderBottom"></span>
                        <span className="strongBorderBottom"></span>

                      </>
                      )}

                    </Form.Item>
                    <Form.Item
                      label={context.translation && context.translation.confirmPass}
                      // name="confirmpassword"
                      rules={[{ required: true, message: context.translation && context.translation.confirmPass }]}
                      className="inputTitle"
                    >
                      <Input.Password
                        // type="password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        autoComplete="new-password"
                        maxLength={16}
                        className={
                          error && confirmPassword === "" ? "formError" : ""
                        }
                        placeholder={context.translation && context.translation.confirmPass}
                      />
                      {
                        <small style={{ color: "red" }}>
                          {password !== "" &&
                          confirmPassword !== "" &&
                          password !== confirmPassword
                            ? pwdMatch
                            : ""}
                        </small>
                      }
                      {
                        <small style={{ color: "red" }}>
                          {SWW
                            ? (context.translation && context.translation.wentWrong)
                            : ""}
                        </small>
                      }
                    </Form.Item>
                    {/* {error && <p style={{ color: "red" }}>{error}</p>} */}
                    <Button
                      type="primary"
                      className="cntBtn"
                      onClick={handleSignUpSubmit}
                      disabled={loading ? true : false}
                      block
                    >
                      {loading ? (
                        // <Space size={"middle"}>
                        //   <Spin size="large" />
                        // </Space>
                        <span className="circleLoader"></span>
                      ) : (
                        context.translation && context.translation.signUp
                      )}{" "}
                      <i className="sprite btn-arrow-sprite"></i>
                    </Button>
                  </Form>
                )}

                {isOtp && (
                  <div>
                    <Title level={5} className="otpTitle">
                      {context.translation && context.translation.eOtp}
                    </Title>
                    <Space.Compact>
                      {otpDigits.map((digit, index) => (
                        <Input
                          key={index}
                          ref={otpInputRefs[index]}
                          value={digit}
                          onChange={(e) => handleOtpChange(index, e.target.value)}
                          onKeyDown={(e) => handleKeyDown(index, e)}
                          maxLength={1}
                          className={
                            error || invalidOTP
                              ? "formError enterOTPData"
                              : "enterOTPData"
                          }
                          style={{ width: "25%" }}
                          autoComplete="off"
                        />
                      ))}
                    </Space.Compact>
                    {timer !== 0 && error && (
                      <small className="errorText otpExpired">
                        {context.translation && context.translation.plzeOtp}
                      </small>
                    )}

                    {timer !== 0 && invalidOTP ? (
                      <small className="errorText otpExpired">
                        {context.translation && context.translation.plzevOtp}
                      </small>
                    ) : (
                      ""
                    )}
                    {timer === 0 ? (
                      <small className="errorText otpExpired">
                        {context.translation && context.translation.plzresendOtp}
                      </small>
                    ) : (
                      ""
                    )}
                    {/* {isOtp && timer > 0 && ( */}
                    {/* <div className="otpExpires">
                      {context.translation && context.translation.otpExpires}{" "}
                      <span className="otpTimerRunning"> */}
                        {/* {formatTime(timer)} */}
                        {/* 00:00
                      </span>{" "}
                      sec
                    </div> */}
                    {/* )} */}

                    {timer === 0 && (
                      <div>
                        {/* {context.translation && context.translation.resendOtp} */}
                        {/* Your OTP got Expired. A new OTP is sent to your mail. */}
                      </div>
                    )}

                    <Button
                      type="primary"
                      onClick={handleSignupVerifyOtp}
                      className="cntBtn"   
                      disabled={timer === 0 ? true : false}
                      block
                    >
                      {context.translation && context.translation.verifyOtp}
                    </Button>
                    <div>
                      <span className="dontGetCode">{context.translation && context.translation.didntgetcode}</span>
                      <Button
                        onClick={handleResendOtp("signup")}
                        disabled={loading ? true : false}
                        style={{ border: "none" }}
                      >
                        {loading ? (
                          // <Space size={"middle"}>
                          //   <Spin size="large" />
                          // </Space>
                          <span className="circleLoader"></span>
                        ) : (
                          <span className="resendOTP">{context.translation && context.translation.resendOtp}</span>
                        )}
                      </Button>
                    </div>
                  </div>
                )}

                {!isOtp && (
                  <div className="bottomNote">
                    {context.translation && context.translation.haveAcc}
                    <span onClick={handleSignIn}>{context.translation && context.translation.signin}</span>
                  </div>
                )}
              </div>
            ) : !forgotPwdWindow ? (
              <div className=" forgotSection">
                {!isOtp && (
                  <button type="button" className="close" onClick={() => {onCancel(); onCloseAuth()}}>
                    <i className="icon icon-cancel"></i>
                  </button>
                )}
                <h2>
                     
                  <span>{context.translation && context.translation.forgotp}</span>{" "}
                  {/* <img src={Logo} alt="Click N Travel" height={24} />{" "} */}
                </h2>
                <span className="subTextSign">
                  <i className="icon icon-lock"></i>
                  {!isOtp
                    ? (context.translation && context.translation.sendOtp)
                    : `${context.translation && context.translation.sentOtp} ${forgotPasswordEmail}`}
                </span>

                {!isOtp && (
                  <Form layout="vertical">
                    <Form.Item
                      label={context.translation && context.translation.emailAd}
                      autocomplete="off"
                      // name="email"
                      rules={[{ required: true, message: context.translation && context.translation.eYe }]}
                      className="inputTitle"
                    >
                      <Input
                        type="email"
                        value={forgotPasswordEmail}
                        onChange={(e) => setForgotPasswordEmail(e.target.value)}
                        className={errorForgot && "formError"}
                        placeholder={context.translation && context.translation.eYe}
                        autocomplete="off"
                      />
                      <small className="errorText">
                        {forgotPasswordEmail !== "" &&
                        !validateEmail(forgotPasswordEmail)
                          ? invalidEmail
                          : ""}
                      </small>
                      <small className="errorText">
                        {emailNotExists ? (context.translation && context.translation.validem) : ""}
                      </small>
                    </Form.Item>
                    <Button
                      type="primary"
                      className="cntBtn"
                      block
                      onClick={handleForgotPasswordEmailSubmit}
                      disabled={loading ? true : false}
                    >
                      {context.translation && context.translation.sendEmail}
                      {loading ? (
                        // <Space size={"middle"}>
                        //   <Spin size="large" />
                        // </Space>
                        <span className="circleLoader"></span>
                      ) : (
                        ""
                      )}{" "}
                      <i className="sprite btn-arrow-sprite"></i>
                    </Button>
                  </Form>
                )}

                {isOtp && (
                  <div>
                    <Title level={5}>{context.translation && context.translation.eOtp}</Title>
                    <Space.Compact>
                      {otpDigits.map((digit, index) => (
                        <Input
                          key={index}
                          ref={otpInputRefs[index]}
                          value={digit}
                          onChange={(e) => handleOtpChange(index, e.target.value)}
                          onKeyDown={(e) => handleKeyDown(index, e)}
                          maxLength={1}
                          className={
                            error || invalidOTP
                              ? "formError enterOTPData"
                              : "enterOTPData"
                          }
                          style={{ width: "25%" }}
                          autoComplete="off"
                        />
                      ))}
                    </Space.Compact>
                    {timer !== 0 && error && (
                      <small className="errorText otpExpired">
                        {context.translation && context.translation.plzeOtp}
                      </small>
                    )}

                    {timer !== 0 && invalidOTP ? (
                      <small className="errorText otpExpired">
                        {context.translation && context.translation.plzevOtp}
                      </small>
                    ) : (
                      ""
                    )}
                    {timer === 0 ? (
                      <small className="errorText otpExpired">
                        {context.translation && context.translation.plzresendOtp}
                      </small>
                    ) : (
                      ""
                    )}
                    {/* {isOtp && timer > 0 && ( */}
                    {/* <div className="otpExpires">
                      {context.translation && context.translation.otpExpires}{" "}
                      <span className="otpTimerRunning"> */}
                        {/* {formatTime(timer)}  */}
                        {/* 00:00
                      </span>{" "}
                      sec
                    </div> */}
                    {/* )} */}

                    <Button
                      type="primary"
                      onClick={handleForgotPasswordVerifyOtp}
                      className="cntBtn"                      
                      disabled={timer === 0 ? true : false}
                      block
                    >
                      {context.translation && context.translation.verifyOtp}
                    </Button>

                    <div>
                      <span className="dontGetCode">{context.translation && context.translation.didntgetcode}</span>
                      <Button
                        onClick={handleResendOtp("forgotPassword")}
                        disabled={loading ? true : false}
                        style={{ border: "none" }}
                      >
                        {loading ? (
                          // <Space size={"middle"}>
                          //   <Spin size="large" />
                          // </Space>
                          <span className="circleLoader"></span>
                        ) : (
                          <span className="resendOTP">{context.translation && context.translation.resendOtp}</span>
                        )}
                      </Button>
                    </div>
                  </div>
                )}

                {!isOtp && (
                  <div className="bottomNote">
                    {context.translation && context.translation.remPass}
                    <span onClick={handleSignIn}>{context.translation && context.translation.signin}</span>
                  </div>
                )}
              </div>
            ) : (
              <div className=" forgotSection">
                {/* Forgot Change password window */}
                <h2>
                  <span>{context.translation && context.translation.chgPass}</span>
                </h2>
                
                <Form layout="vertical">
                  <Form.Item 
                    className="pRelative" 
                    label={context.translation && context.translation.newPass}
                  >
                    <Tooltip overlayClassName="layoverPassword" placement="bottomRight" open={forgotNewPassword && passwordTooltip?true:false} title={
                      <small><i className="icon icon-Info"></i> {context.translation && context.translation.warning}</small>
                    }>
                    <Input.Password
                      placeholder={context.translation && context.translation.enterNewpass}
                      type="password"
                      value={forgotNewPassword}
                      onChange={(e) => {
                        return (
                          setForgotNewPassword(e.target.value),
                          validatePassword(e.target.value)
                        );
                      }}
                      className={error && forgotNewPassword === "" ? "formError" : ""}
                      autoComplete="new-password"
                      maxLength={16}
                    />
                    </Tooltip>
                    {forgotNewPassword && (
                      <>
                      <span
                        style={{                            
                          position: "absolute",
                          top: "-33px",
                          right: "111px",
                          borderBottom: "2px solid #ed1d24",
                          paddingBottom: "0",
                          zIndex: "99",
                          color:
                            weakvalidationStatus === (context.translation && context.translation.weak)
                              ? "#ed1d24"
                              : "#ed1d24",
                        }}
                      >
                        {weakvalidationStatus}
                      </span>
                      <span
                        style={{
                          position: "absolute",
                          top: "-33px",
                          right: "51px",
                          borderBottom: "2px solid #E6BB07",
                          paddingBottom: "0",
                          zIndex: "99",
                          color:
                            mediumvalidationStatus === (context.translation && context.translation.weak)
                              ? "#ed1d24"
                              : mediumvalidationStatus === (context.translation && context.translation.medium)
                              ? "#E6BB07"
                              : "#008D00",
                        }}
                      >
                        {mediumvalidationStatus}
                      </span>
                      <span
                        style={{
                          position: "absolute",
                          top: "-33px",
                          right: "1px",
                          borderBottom: "2px solid #008D00",
                          paddingBottom: "0",
                          zIndex: "99",
                          color:
                            strongvalidationStatus === (context.translation && context.translation.weak)
                              ? "#ed1d24"
                              : strongvalidationStatus === (context.translation && context.translation.medium)
                              ? "#E6BB07"
                              : "#008D00",
                        }}
                      >
                        {strongvalidationStatus}
                      </span>
                      <span className="weakBorderBottom"></span>
                      <span className="mediumkBorderBottom"></span>
                      <span className="strongBorderBottom"></span>
                    </>
                    )}
                  </Form.Item>
                  
                  <Form.Item 
                    className="pRelative" 
                    label={context.translation && context.translation.confirmPass}
                  >
                    <Input.Password
                      placeholder={context.translation && context.translation.enterConfpas}
                      type="password"
                      value={forgotConfirmPassword}
                      onChange={(e) => setForgotConfirmPassword(e.target.value)}
                      className={error && forgotConfirmPassword === "" ? "formError" : ""}
                    />
                    {
                      <small style={{ color: "red" }}>
                        {forgotNewPassword !== "" &&
                        forgotConfirmPassword !== "" &&
                        forgotNewPassword !== forgotConfirmPassword
                          ? pwdMatch
                          : ""}
                      </small>
                    }
                  </Form.Item>
                  
                  <Button
                    type="primary"
                    className="cntBtn"
                    onClick={handleForgotChangePasswordSubmit}
                    disabled={loading ? true : false}
                    block
                  >
                    {loading ? (
                      // <Space size={"middle"}>
                      //   <Spin size="large" />
                      // </Space>
                      <span className="circleLoader"></span>
                    ) : (
                      context.translation && context.translation.submit
                    )}
                    <i className="sprite btn-arrow-sprite"></i>
                  </Button>
                </Form>

                <div className="bottomNote">
                  {context.translation && context.translation.remPass}
                  <span onClick={handleSignIn}>{context.translation && context.translation.signin}</span>
                </div>
              </div>)
            }
          </div>
        </div>
      </div>
    </>
  );
};

export default AuthenticationPopUp;
