import { Button, Typography } from "antd";
import React, { useEffect, useState, useContext } from "react";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import CMSService from "../../services/cms/CMSService";
import { Context } from "../../App";
import BrandHRedIcon from "../../assets/gif/Forever/Red/H_RED FOVERER.gif";
import Utilities from "../utilities/Utilities";
export const REGION_COUNTRY = "REGION_COUNTRY";

const { Title } = Typography;

function MostPopularHotels(props) {
  const [context, setContext] = useContext(Context);
  const [res, setRes] = useState([]);

  useEffect(() => {
    const payload = { 
      region:
        localStorage.getItem(REGION_COUNTRY) === "IN"
          ? "India"
          : "Qatar",
      channel: "B2C", 
      status: "Active"
     }
    let tokenKey = Utilities.generateAPIToken(payload);
    CMSService.loadPopularHotels(payload,tokenKey)
      .then((response) => {
        if (response.data.suc && response.data.res) {
          setRes(response.data.res[0].htl_list);
        }
      })
      .catch(function (error) {
        console.error("Most Popular Hotels response error ", error);
      });
  }, []);


  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", background: "#fff", boxShadow: "0px 6px 30px #00000029" }}
        onClick={onClick}
      />
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", background: "#fff", boxShadow: "0px 6px 30px #00000029" }}
        onClick={onClick}
      />
    );
  }

  var settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    rtl: false,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1270,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 999,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: "30px 20px",
          nextArrow: false,
          prevArrow: false,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "30px 20px",
          slidesToScroll: 1
        }
      }
    ]
  };

  const handleHotelCityClick = (data, hotelData) => {
    let popularHtl = {
      hotelData : hotelData.htl_info,
      e : data
    }
    
    setContext({...context, popularHotels: popularHtl})

    setTimeout(function () {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }, 100);

    if(sessionStorage.getItem("SEL_TAB") === "Flights") {
      sessionStorage.setItem("SEL_TAB", "Hotels");
    }

  };

  return (
    <>
      {res.length >= 4 && (
        <div className="mphs">
          <div className="container">
            <Title level={2} className="cust-title">
            <img src={BrandHRedIcon} alt="brandHRedIcon" className="brandHRedIcon" />
              {/* <i className="sprite mph-sprite"></i>{" "} */}
              <span>
                {/* Most Popular Hotels */}
                {context.translation && context.translation.plrHtls}
              </span>
            </Title>
            <Slider {...settings} className="cntSlider cntHomepage">
            {res.map((data, index) => {
                return (
            <div className="mph-section" key={index}>
                  <div key={index} className="mphs-block">
                    <div className="mphsb-top">
                      <img
                        src={data.img_url}
                        alt="Hilton Doha The Pearl Residency"
                      />
                      <Button type="secondary" ghost onClick={(e) => handleHotelCityClick(e, data)}>
                        {/* Book Now */}
                        {context.translation && context.translation.plrHtlsBtn}
                      </Button>
                    </div>
                    <div className="mphsb-bottom">
                      <Title
                        level={2}
                        title={context.selectLang == undefined || context.selectLang === "en" ? data.htl_info.description : data.hot_ar}
                      >
                        {context.selectLang == undefined || context.selectLang === "en" ? data.htl_info.description : data.hot_ar}
                      </Title>
                      <div className="stars">
                        ★ ★ ★ ★ ★
                        <div className="star-dynamic">
                          {[...Array(data.star_rating)].map((star,index) => {
                            return <span className="star" key={index}> &#9733;</span>;
                          })}
                        </div>
                      </div>
                      <div className="location">
                        {context.selectLang == undefined || context.selectLang === "en" ? data.hot_add_en : data.hot_add_ar}
                      </div>
                    </div>
                  </div>
            </div>
            )
          })}
            </Slider>
            {/* {res.length === 0 &&          
            <Title level={3} align="center">
              <span>No data found</span>
            </Title>  
          } */}
          </div>
        </div>
      )}
    </>
  );
}

export default MostPopularHotels;
