/**
 * @ Author: Srikanth Bandaru
 * @ Create Time: 2023-06-19 18:44:11
 * @ Description: The common results page for all the trip types of flight booking
 */

import { publicIpv4 } from "public-ip";
import React, { useEffect, useReducer, useState, useContext, useCallback } from "react";
import { Context } from "../../../../App";
import { useLocation } from "react-router-dom";
import Footer from "../../../common/Footer";
import Header from "../../../common/Header";
import FlightSearch from "../search/Search";
import FlightResultsDetails from "./FlightResultsDetails";
import {
  fireMultiCitySearch,
  fireSearchReq,
} from "../common/services/operations";
// import {
//   FLIGHT_RESPONSE_MULTI_CITY_3_LEGS,
//   FLIGHT_RESPONSE_ROUNDTRIP,
//   FLIGHT_RESPONSE_ROUNDTRIP_CONTENT_AGGREGATION,
// } from "./sampleFlightResponse";
import NoResultsFound from "../../flights/results/NoResultsFound";
import { LoadingOutlined } from "@ant-design/icons";

import FlightSearchLoader from "./FlightSearchLoader";
import LoaderN from "../../../common/loaders/LoaderN";
import PredectiveSearch from "../../flights/search/predective";
import Utilities from "../../../utilities/Utilities";
import { isArrayNotEmpty, isNotNull } from "../../../utilities/validators";
import SecureGuarantee from "../../../homepage/SecureGuarantee";
import BrandIcon from "../../../../assets/gif/Forever/White/ICON_WHITE_FOREVER.gif";
import { Button, Drawer } from "antd";
// import CntCommonService from "../../../../services/common/CntCommonService";

const FLIGHT_SEARCH_CID = process.env.REACT_APP_FLIGHT_SEARCH_CID;
const BASE_CURRENCY = process.env.REACT_APP_BASE_CUR;

const FLIGHT_SEARCH_REGION_TYPE =
  process.env.REACT_APP_FLIGHT_SEARCH_REGION_TYPE;

const initialState = {
  searchResp: "",
  initialResponse: {},
  searchReq: {},
};

//=====================================
// Reducer function for userReact Hook
// which will update the state
//=====================================
const reducer = (state, action) => {
  switch (action.type) {
    case "clear":
      return {
        ...state,
      };
    default:
      return { ...state, [action.type]: action.payload };
  }
};
/**
 *
 *
 * @param {*}
 * @return {*}
 */
/** */
function FlightResults(props) {
  const [context, setContext] = useContext(Context)
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const parseQueryString = () => {
    const payload = {};

    for (const [key, value] of searchParams.entries()) {
      payload[key] = value;
    }

    return payload;
  };

  const [state, dispatch] = useReducer(reducer, initialState);
  const [loading, setLoading] = useState(true);
  const [raw_srp_data, setRawSRPData] = useState("");
  const [request, setRequest] = useState(
    location ? parseQueryString() : ""
  );
  const [longUrl, setLongUrl] = useState(window.location.href);
  const [meshKey,setMeshKey] = useState(localStorage.getItem("meshkey"));
  const [activeClass, setActiveClass] = useState(false);
  // const antIcon = <LoadingOutlined spin />;

  /* useEffect(() => {
    setRequest({
      src: src,
      dest: dest,
      adt: adt,
    });
  }, []) */

  useEffect(() => {
    sessionStorage.setItem("FLTLONGURL", window.location.href)
  }, []);
  
  const updateCount = (req) => {
    var cnt = 0;
    if(isNotNull(req.dep1)) {cnt = 1}
    if(isNotNull(req.dep2)) {cnt = 2}
    if(isNotNull(req.dep3)) {cnt = 3}
    if(isNotNull(req.dep4)) {cnt = 4}
    if(isNotNull(req.dep5)) {cnt = 5}
    if(isNotNull(req.dep6)) {cnt = 6}
    if(isNotNull(req.arr1)) {cnt = 7}
    if(isNotNull(req.arr2)) {cnt = 8}
    if(isNotNull(req.arr3)) {cnt = 9}
    if(isNotNull(req.arr4)) {cnt = 10}
    if(isNotNull(req.arr5)) {cnt = 11}
    if(isNotNull(req.arr6)) {cnt = 12}
    return cnt;
  }

  useEffect(() => {
    if(isNotNull(meshKey)){
      localStorage.removeItem("meshkey");
    }
  }, [meshKey])
  

  useEffect(() => {
    if (request !== undefined) {
      if(isNotNull(request.ns)){
        request.ns = JSON.parse(request.ns);
      }
      var cnt = 0;
      if(cnt === 0) {
        cnt = updateCount(request)};
      if(parseInt(request.tt) !== 3) {
          loadOWRTResults(request);
      } else {
          loadMultiResults(request);
      }
    }
  }, [request]);

  const loadOWRTResults = (request) => {
      try {
        loadAirportsAndResults([request.srcCode,request.destCode],parseInt(request.tt));
    } catch (error) {
        console.error("Error in loadOWRTResults:", error);
    }
  }

  const loadMultiResults = (request) => {
    try {
      var codes = [];
        for (let i = 1; i <= 6; i++) {
            if (isNotNull(request['dep' + i])) {
                codes.push(request['dep' + i]);
            }
            if (isNotNull(request['arr' + i])) {
                codes.push(request['arr' + i]);
            }
        }
        loadAirportsAndResults(codes,parseInt(request.tt));
    } catch (error) {
      console.error("Error in loadMultiResults:", error);
    }
  }

  //Get ip address of the user logged in
  const getIpAddress = async () => {
    const ipAddress = await publicIpv4.call();
    return ipAddress;
  };

  //For loading the search results w.r.t trip types
  const loadSearchResults = async (request) => {
    var requrl = "";
    var region = localStorage.getItem("REGION_COUNTRY")!==null && localStorage.getItem("REGION_COUNTRY")==="IN"?"India":"Qatar";
    var randomStr = Math.random().toString(36).slice(2);
    var region_cur =context.regionCur!==undefined && context.regionCur!==""?context.regionCur:localStorage.getItem("REGION_CUR")
    var usr_cur =context.userCur!==undefined && context.userCur!==""?context.userCur:sessionStorage.getItem("SEL_CUR")
    if(request){
    if (Number(request.tt) === 1) {
      requrl =
        "dep=" +
        request.srcCode +
        "&arr=" +
        request.destCode +
        "&dd=" +
        request.dd +
        "&tt=" +
        1 +
        "&cl=" +
        request.cls +
        "&ns=" +
        request.ns +
        "&adt=" +
        request.adt +
        "&chd=" +
        request.chd +
        "&inf=" +
        request.inf +
        "&cid=" +
        FLIGHT_SEARCH_CID +
        "&cha=" +
        "B2C" +
        "&echo=" +
        randomStr +
        "&sty=" +
        "normal" +
        "&cur=" +
        usr_cur +
        "&pa=" +
        request.pa +
        "&src_cnt=" +
        request.src?.country +
        "&dest_cnt=" +
        request.dest?.country +
        "&client_ip=" +
        (await publicIpv4()) +
        "&req_cur=" +
        BASE_CURRENCY +
        "&region_type=" +
        region +
        "&region_cur=" +
        region_cur;
      callSearch(requrl, 1);
    } else if (Number(request.tt) === 2) {
      requrl =
        "dep=" +
        request.srcCode +
        "&arr=" +
        request.destCode +
        "&dd=" +
        request.dd +
        "&ad=" +
        request.ad +
        "&tt=" +
        2 +
        "&cl=" +
        request.cls +
        "&ns=" +
        request.ns +
        "&adt=" +
        request.adt +
        "&chd=" +
        request.chd +
        "&inf=" +
        request.inf +
        "&cid=" +
        FLIGHT_SEARCH_CID +
        "&cha=" +
        "B2C" +
        "&echo=" +
        randomStr +
        "&sty=" +
        "normal" +
        "&cur=" +
        usr_cur +
        "&pa=" +
        request.pa +
        "&src_cnt=" +
        request.src?.country +
        "&dest_cnt=" +
        request.dest?.country +
        "&client_ip=" +
        (await publicIpv4()) +
        "&req_cur=" +
        BASE_CURRENCY +
        "&region_type=" +
        region +
        "&region_cur=" +
        region_cur;
      callSearch(requrl, 2);
    } else {
      var multiRequest = createMultiCityRequest(request);
      try {
        fireMultiCitySearch(multiRequest).then((response) => {
          localStorage.setItem("MULTI", JSON.stringify(response));
          if (response !== undefined && response.status === "success") {
            localStorage.setItem("RESPONSE", JSON.stringify(response));
            dispatch({ type: "searchResp", payload: response.data[0] });
            dispatch({ type: "initialResponse", payload: response.data[0] });
            setRawSRPData({
              ...response.data[0]
            });
            sessionStorage.removeItem("ISRESET")
            sessionStorage.setItem("cnt_search", JSON.stringify(multiRequest));
          } else {
            console.error("Error in flight search response");
            dispatch({ type: "searchResp", payload: undefined });
            dispatch({ type: "initialResponse", payload: undefined });
          }
        }).catch(function (error) {
          console.error("Error:" + error);      
        });        
      } catch (error) {
        console.error("Error:", error);         
      }
      /* dispatch({ type: "searchResp", payload: FLIGHT_RESPONSE_MULTI_CITY_3_LEGS.data[0] })
      dispatch({ type: "initialResponse", payload: FLIGHT_RESPONSE_MULTI_CITY_3_LEGS.data[0] })
      setLoading(false); */
    }
  }
  };

  //For creating multi city search request
  const createMultiCityRequest = (request) => {
    var randomStr = Math.random().toString(36).slice(2);
    var region=localStorage.getItem("REGION_COUNTRY")!==null && localStorage.getItem("REGION_COUNTRY")==="IN"?"India":"Qatar";
    var region_cur =context.regionCur!==undefined && context.regionCur!==""?context.regionCur:localStorage.getItem("REGION_CUR")
    const payload = {
      tt: 3,
      cl: request.cl,
      ns: request.ns,
      cur: request.cur,
      chan: "B2C",
      adt: request.adt,
      chd: request.chd,
      inf: request.inf,
      echo: randomStr,
      sty: "normal",
      cid: FLIGHT_SEARCH_CID,
      seg: convertSegments(request),
      region_type: region,
      req_cur: BASE_CURRENCY,
      region_cur: region_cur
    };
    return payload;
  };

  //For converting multi segments captured to the format required for search api
  const convertSegments = (request) => {
    let seg = [];
    if (
      isNotNull(request.orignDataOne) &&
      isNotNull(request.destDataOne)
    ) {
    const payOne = {
      dep: request.orignDataOne.airport,
      arr: request.destDataOne.airport,
      dd: request.dateOne,
      seg: 1,
    };
    seg.push(payOne);
  }
    if (
      request.orignDataTwo !== "" &&
      request.destDataTwo !== "" &&
      request.orignDataTwo !== undefined &&
      request.destDataTwo !== undefined
    ) {
      const payTwo = {
        dep: request.orignDataTwo.airport,
        arr: request.destDataTwo.airport,
        dd: request.dateTwo,
        seg: 2,
      };
      seg.push(payTwo);
    }
    if (
      request.orignDataThree !== "" &&
      request.destDataThree !== "" &&
      request.orignDataThree !== undefined &&
      request.destDataThree !== undefined &&
      request.dateThree !== undefined &&
      request.dateThree !== ""
    ) {
      const payThree = {
        dep: request.orignDataThree.airport,
        arr: request.destDataThree.airport,
        dd: request.dateThree,
        seg: 3,
      };
      seg.push(payThree);
    }
    if (
      request.orignDataFour !== "" &&
      request.orignDataFour !== undefined &&
      request.dateFour !== undefined &&
      request.dateFour !== ""
    ) {
      const payFour = {
        dep: request.orignDataFour.airport,
        arr: request.destDataFour.airport,
        dd: request.dateFour,
        seg: 4,
      };
      seg.push(payFour);
    }
    if (
      request.orignDataFive !== "" &&
      request.orignDataFive !== undefined &&
      request.dateFive !== undefined &&
      request.dateFive !== ""
    ) {
      const payFive = {
        dep: request.orignDataFive.airport,
        arr: request.destDataFive.airport,
        dd: request.dateFive,
        seg: 5,
      };
      seg.push(payFive);
    }
    if (
      request.orignDataSix !== "" &&
      request.arr6 !== undefined &&
      request.dateSix !== undefined &&
      request.dateSix !== ""
    ) {
      const paySix = {
        dep: request.orignDataSix.airport,
        arr: request.destDataSix.airport,
        dd: request.dateSix,
        seg: 6,
      };
      seg.push(paySix);
    }
    return seg;
  };

  useEffect(() => {
    let modifiedResponse = state.searchResp;
    if (
      modifiedResponse &&
      modifiedResponse.origns &&
      modifiedResponse.origns.length > 0
    ) {
      modifiedResponse.origns[0].cheapest = true;
      dispatch({ type: "searchResp", payload: modifiedResponse });
      dispatch({ type: "initialResponse", payload: modifiedResponse });
      if (localStorage.getItem("AVAILABILITY_FAILURE") !== null) {
        dispatch({
          type: "searchResp",
          payload: checkIfReloadOnAvailabilityFailure(state.searchResp),
        });
        dispatch({
          type: "initialResponse",
          payload: checkIfReloadOnAvailabilityFailure(state.searchResp),
        });
      }
      
      if(isNotNull(meshKey)){
        var sharedItinerary = false;
        modifiedResponse.origns.map((origin) => {
          const meshKeyToBeMatched = Utilities.createMeshKey(origin);
          if (meshKeyToBeMatched === meshKey) {
            origin.shared = true; // Mark as prevselected
            if(sharedItinerary === false){
              sharedItinerary = true;
            }
          } else {
            origin.shared = false; // Not selected
          }
          return origin;
        });
        if(sharedItinerary === true){
          modifiedResponse.origns.sort((a, b) =>
          a.shared === b.shared ? 0 : a.shared ? -1 : 1
        );
        }
        dispatch({
          type: "searchResp",
          payload: modifiedResponse,
        });
        dispatch({
          type: "initialResponse",
          payload: modifiedResponse,
        });
      }
    }
  }, [state.searchResp]);

  const checkIfReloadOnAvailabilityFailure = (searchRes) => {
    let modifiedResponse = searchRes;
    if (parseInt(modifiedResponse.tt) === 1) {
      var prevSelectedFlt = localStorage.getItem("SELECTED_FLIGHT_OW")
        ? JSON.parse(localStorage.getItem("SELECTED_FLIGHT_OW"))
        : undefined;
      if (prevSelectedFlt) {
        modifiedResponse = checkMatchingFlight(
          prevSelectedFlt,
          modifiedResponse,
          true
        );
      }
    } else if (parseInt(searchRes.tt) === 2) {
      var prevSelectedFltOW = localStorage.getItem("SELECTED_FLIGHT_OW")
        ? JSON.parse(localStorage.getItem("SELECTED_FLIGHT_OW"))
        : undefined;
      var prevSelectedFltRT = localStorage.getItem("SELECTED_FLIGHT_RT")
        ? JSON.parse(localStorage.getItem("SELECTED_FLIGHT_RT"))
        : undefined;
      if (prevSelectedFltOW) {
        modifiedResponse = checkMatchingFlight(
          prevSelectedFltOW,
          modifiedResponse,
          true
        );
      }
      if (prevSelectedFltRT) {
        modifiedResponse = checkMatchingFlight(
          prevSelectedFltRT,
          modifiedResponse,
          false
        );
      }
    } else if (parseInt(searchRes.tt) === 3) {
      var prevSelectedFltOne = localStorage.getItem("SELECTED_MULTI_ONE")
        ? JSON.parse(localStorage.getItem("SELECTED_MULTI_ONE"))
        : undefined;
      var prevSelectedFltTwo = localStorage.getItem("SELECTED_MULTI_TWO")
        ? JSON.parse(localStorage.getItem("SELECTED_MULTI_TWO"))
        : undefined;
      var prevSelectedFltThree = localStorage.getItem("SELECTED_MULTI_THREE")
        ? JSON.parse(localStorage.getItem("SELECTED_MULTI_THREE"))
        : undefined;
      var prevSelectedFltFour = localStorage.getItem("SELECTED_MULTI_FOUR")
        ? JSON.parse(localStorage.getItem("SELECTED_MULTI_FOUR"))
        : undefined;
      var prevSelectedFltFive = localStorage.getItem("SELECTED_MULTI_FIVE")
        ? JSON.parse(localStorage.getItem("SELECTED_MULTI_FIVE"))
        : undefined;
      var prevSelectedFltSix = localStorage.getItem("SELECTED_MULTI_SIX")
        ? JSON.parse(localStorage.getItem("SELECTED_MULTI_SIX"))
        : undefined;
      if (prevSelectedFltOne) {
        modifiedResponse = checkMatchingFlight(
          prevSelectedFltOne,
          modifiedResponse,
          true
        );
      }
      if (prevSelectedFltTwo) {
        modifiedResponse = checkMatchingFlight(
          prevSelectedFltTwo,
          modifiedResponse,
          true
        );
      }
      if (prevSelectedFltThree) {
        modifiedResponse = checkMatchingFlight(
          prevSelectedFltThree,
          modifiedResponse,
          true
        );
      }
      if (prevSelectedFltFour) {
        modifiedResponse = checkMatchingFlight(
          prevSelectedFltFour,
          modifiedResponse,
          true
        );
      }
      if (prevSelectedFltFive) {
        modifiedResponse = checkMatchingFlight(
          prevSelectedFltFive,
          modifiedResponse,
          true
        );
      }
      if (prevSelectedFltSix) {
        modifiedResponse = checkMatchingFlight(
          prevSelectedFltSix,
          modifiedResponse,
          true
        );
      }
    }
    return modifiedResponse;
  };

  const checkMatchingFlight = (prevSelectedFlt, modifiedResponse, isSource) => {
    var foundFlt = false;
    var foundRTFlt = false;
    {
      isSource &&
        modifiedResponse &&
        modifiedResponse?.origns?.map((flights, index) => {
          flights.srcorigns?.map((flt, i) => {
            if (
              !foundFlt &&
              flt.ref === prevSelectedFlt.ref &&
              flt.sdur === prevSelectedFlt.sdur &&
              flt.stop === prevSelectedFlt.stop &&
              flt.tktair === prevSelectedFlt.tktair &&
              flt.seg[0].da === prevSelectedFlt.seg[0].da &&
              flt.seg[0].ar === prevSelectedFlt.seg[0].ar &&
              flt.seg[0].dd === prevSelectedFlt.seg[0].dd &&
              flt.seg[0].ad === prevSelectedFlt.seg[0].ad &&
              flt.seg[0].fn === prevSelectedFlt.seg[0].fn &&
              flt.seg[0].ac === prevSelectedFlt.seg[0].ac &&
              flt.seg[0].fdur === prevSelectedFlt.seg[0].fdur
            ) {
              flights.prevSelected = true;
              if (parseInt(modifiedResponse.tt) === 1) foundFlt = true;
            }
          });
        });
    }
    if (!isSource && modifiedResponse && modifiedResponse.origns) {
      for (let i = 0; i < modifiedResponse.origns.length; i++) {
        const flights = modifiedResponse.origns[i];
        if (foundRTFlt) {
          flights.prevSelected = false;
        }
        if (flights.prevSelected && flights.destorigins) {
          for (let j = 0; j < flights.destorigins.length; j++) {
            const flt = flights.destorigins[j];
            if (
              !foundRTFlt &&
              flt.ref === prevSelectedFlt.ref &&
              flt.sdur === prevSelectedFlt.sdur &&
              flt.stop === prevSelectedFlt.stop &&
              flt.tktair === prevSelectedFlt.tktair &&
              flt.seg[0].da === prevSelectedFlt.seg[0].da &&
              flt.seg[0].ar === prevSelectedFlt.seg[0].ar &&
              flt.seg[0].dd === prevSelectedFlt.seg[0].dd &&
              flt.seg[0].ad === prevSelectedFlt.seg[0].ad &&
              flt.seg[0].fn === prevSelectedFlt.seg[0].fn &&
              flt.seg[0].ac === prevSelectedFlt.seg[0].ac &&
              flt.seg[0].fdur === prevSelectedFlt.seg[0].fdur
            ) {
              foundRTFlt = true;
              break;
            }
            if (!foundRTFlt && j === flights.destorigins.length - 1) {
              flights.prevSelected = false;
            }
          }
        }
      }
    }

    /* {!isSource && modifiedResponse &&
      modifiedResponse?.origns?.map((flights, index) => {
        flights.prevSelected && flights.destorigins?.map((flt,i)=> {
          if(!foundRTFlt &&
            flt.ref === prevSelectedFlt.ref && 
            flt.sdur === prevSelectedFlt.sdur && 
            flt.stop === prevSelectedFlt.stop && 
            flt.tktair === prevSelectedFlt.tktair && 
            flt.seg[0].da === prevSelectedFlt.seg[0].da && 
            flt.seg[0].ar === prevSelectedFlt.seg[0].ar && 
            flt.seg[0].dd === prevSelectedFlt.seg[0].dd && 
            flt.seg[0].ad === prevSelectedFlt.seg[0].ad && 
            flt.seg[0].fn === prevSelectedFlt.seg[0].fn && 
            flt.seg[0].ac === prevSelectedFlt.seg[0].ac && 
            flt.seg[0].fdur === prevSelectedFlt.seg[0].fdur){
              flights.prevSelected = true; 
              foundRTFlt = true;
            } else {
              flights.prevSelected = false;
            }
            
        })
        
      }
      )
    }; */
    if (
      ((parseInt(modifiedResponse.tt) === 1 ||
        parseInt(modifiedResponse.tt) === 3) &&
        foundFlt) ||
      (parseInt(modifiedResponse.tt) === 2 && foundRTFlt)
    ) {
      modifiedResponse.origns.sort((a, b) =>
        a.prevSelected === b.prevSelected ? 0 : a.prevSelected ? -1 : 1
      );
    }
    return modifiedResponse;
  };

  //Search call for one way and round trip trip types
  const callSearch = (requrl, tripType) => {
    try {
      fireSearchReq(requrl).then((response) => {
        if (response !== undefined && response.status === "success") {
          response.data[0].tt = tripType.toString();
          //localStorage.setItem("RESPONSE", JSON.stringify(response.data[0]));
          dispatch({ type: "searchResp", payload: response.data[0] });
          dispatch({ type: "initialResponse", payload: response.data[0] });
          setRawSRPData({
            ...response.data[0]
          });
          sessionStorage.removeItem("FILTERRESET");
          sessionStorage.setItem("cnt_search", JSON.stringify(requrl));
          sessionStorage.removeItem("ISRESET")
          //setLoading(false);
        } else {
          console.error("Error in flight search response");
          dispatch({ type: "searchResp", payload: undefined });
          dispatch({ type: "initialResponse", payload: undefined });
          setLoading(false);
        }
      }).catch(function (error) {
        console.error("Error:" + error);      
      });
      /* dispatch({ type: "searchResp", payload: FLIGHT_RESPONSE_ROUNDTRIP_CONTENT_AGGREGATION.data[0] })
      dispatch({ type: "initialResponse", payload: FLIGHT_RESPONSE_ROUNDTRIP_CONTENT_AGGREGATION.data[0] })
      setLoading(false); */
    } catch (error) {
      console.error("Exception in callSearch : " + error);
    }
  };

  //Set the active tab based in the trip type
  const activeTab = () => {
    let tab = "";
    if (request) {
      if (parseInt(request.tt)) {
        if (parseInt(request.tt) === 1) {
          // tab = "OneWay";
          tab = (context.selectLang === "en" || context.selectLang === undefined) ? "OneWay" : context.translation && context.translation.oneWayTitle;
        } else if (parseInt(request.tt) === 2) {
          // tab = "RoundTrip";
          tab = (context.selectLang === "en" || context.selectLang === undefined) ? "RoundTrip" : context.translation && context.translation.roundTripTitle;
        } else if (parseInt(request.tt) === 3) {
          // tab = "MultiCity";
          tab = (context.selectLang === "en" || context.selectLang === undefined) ? "MultiCity" : context.translation && context.translation.multiCityTitle;
        }
      }
      return tab;
    }
  };
  useEffect(() => {
    if (loading) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
  }, [loading]);

  const loadAirportsAndResults = async (input,trip) => {
    const airportData = [];
    try {
      if (input && input.length > 1) { 
        const payload = {
          searchCriteria: input,
          lang: context.selectLang
        };
        let tokenKey = Utilities.generateAPIToken(payload);
        const data = await PredectiveSearch.retreieveAirportData(payload,tokenKey);
          if(isNotNull(data) && isNotNull(data.data) && data.data.res.length > 0){
            data.data.res.map((val) => {
              var cityName="";
              var cityName_ar="";
              if(val.cityName!==undefined && val.cityName!==""){
                 cityName=val.cityName;
                 cityName_ar=val.cityName_ar;
              }else{
                if(val.airportName==="London All"){
                  cityName="London All";
                  cityName_ar="لندن الكل";
                }
              }
              airportData.push({
                country: `${val.countryCode}`,
                city: `${cityName}`,
                city_ar: `${cityName_ar}`,
                airport: `${val.airportCode}`,
                display: `${cityName}(${val.airportCode}), ${val.countryName}`,
                display_ar: `${cityName_ar}(${val.airportCode}), ${val.countryName_ar}`,
                label: `${val.airportName}, ${val.countryName}`,
                label_ar: `${val.airportName_ar}, ${val.countryName_ar}`,
                value: `${val.airportName}, ${cityName} , ${val.countryName} , ${val.airportCode}`,
              });
            });
          }
          if(isArrayNotEmpty(airportData)){
              if(trip !== 3){
                let srcResult = airportData.find(airport => airport.airport === request.srcCode);
                let destResult = airportData.find(airport => airport.airport === request.destCode);
                if (srcResult) {
                    request.src = srcResult;
                    request.srcCity = srcResult.city;
                }
                if (destResult) {
                    request.dest = destResult;
                    request.destCity = destResult.city;
                }
              } else {
                    if(isNotNull(request.dep1)) {
                      let airportResult = airportData.find(airport => airport.airport === request.dep1);
                      request.orignDataOne = airportResult;
                    }
                    if(isNotNull(request.dep2)) {
                      let airportResult = airportData.find(airport => airport.airport === request.dep2);
                      request.orignDataTwo = airportResult;
                    }
                    if(isNotNull(request.dep3)) {
                      let airportResult = airportData.find(airport => airport.airport === request.dep3);
                      request.orignDataThree = airportResult;
                    }
                    if(isNotNull(request.dep4)) {
                      let airportResult = airportData.find(airport => airport.airport === request.dep4);
                      request.orignDataFour = airportResult;
                    }
                    if(isNotNull(request.dep5)) {
                      let airportResult = airportData.find(airport => airport.airport === request.dep5);
                      request.orignDataFive = airportResult;
                    }
                    if(isNotNull(request.dep6)) {
                      let airportResult = airportData.find(airport => airport.airport === request.dep6);
                      request.orignDataSix = airportResult;
                    }
          
                    if(isNotNull(request.arr1)) {
                      let airportResult = airportData.find(airport => airport.airport === request.arr1);
                      request.destDataOne = airportResult;
                    }
          
                    if(isNotNull(request.arr2)) {
                      let airportResult = airportData.find(airport => airport.airport === request.arr2);
                      request.destDataTwo = airportResult;
                    }
          
                    if(isNotNull(request.arr3)) {
                      let airportResult = airportData.find(airport => airport.airport === request.arr3);
                      request.destDataThree = airportResult;
                    }
          
                    if(isNotNull(request.arr4)) {
                      let airportResult = airportData.find(airport => airport.airport === request.arr4);
                      request.destDataFour = airportResult;
                    }
          
                    if(isNotNull(request.arr5)) {
                      let airportResult = airportData.find(airport => airport.airport === request.arr5);
                      request.destDataFive = airportResult;
                    }
          
                    if(isNotNull(request.arr6)) {
                      let airportResult = airportData.find(airport => airport.airport === request.arr6);
                      request.destDataSix = airportResult;
                    }
                    //for Dates
                    if(isNotNull(request.depDate1)) request.dateOne = request.depDate1;
                    if(isNotNull(request.depDate2)) request.dateTwo = request.depDate2;
                    if(isNotNull(request.depDate3)) request.dateThree = request.depDate3;
                    if(isNotNull(request.depDate4)) request.dateFour = request.depDate4;
                    if(isNotNull(request.depDate5)) request.dateFive = request.depDate5;
                    if(isNotNull(request.depDate6)) request.dateSix = request.depDate6;
              }
              request.paxCount= parseInt(request.adt) +(request.chd ? parseInt(request.chd) : 0) +(request.inf ? parseInt(request.inf) : 0);
              dispatch({ type: "searchReq", payload: request });
              if (!loading) setLoading(true);
              loadSearchResults(request);
          }
      }
    } catch (error) {
      console.error("Error in loadAirportsAndResults:",error);
    }
  }
  const closeLoader=()=>{
    if(loading && state.searchResp!==""){
      setLoading(false)
    }
  }

  const update=(type)=>{
  }

  // const addActiveClass=()=>{
  //   setActiveClass(true);
  // }

  // const removeActiveClass=()=>{
  //   setActiveClass(false);
  // }

  const [scrollDown, setScrollDown] = useState(false);
  // const [scrollTop, setScrollTop] = useState(false);
 
    // useEffect(() => {
    //     window.addEventListener('scroll', () => {
    //         if (window.scrollY == 0 ) {
    //             setScrollDown(false);
    //         } else 
    //          {
    //           setScrollDown(true);
    //         }
    //     });
    // }, [])

  //   const [y, setY] = useState(0);

  // const handleNavigation = (e) => {
  //   const window = e.currentTarget;
  //   if (y > window.scrollY) {
  //     setScrollDown(false);
  //   } else if (y < window.scrollY) {
  //     setScrollDown(true);
  //   }    setY(window.scrollY);
  // };
  // useEffect(() => {
  //   setY(window.scrollY);

  //   window.addEventListener("scroll", (e) => handleNavigation(e));
  // }, []);

//   const [y, setY] = useState(window.scrollY);
// const handleNavigation = useCallback(
//   e => {
//     const window = e.currentTarget;
//     if (y > window.scrollY) {
//       setScrollDown(false);
//     } else if (y < window.scrollY) {
//       setScrollDown(true);
//     }    setY(window.scrollY);
//   }, [y]
// );useEffect(() => {
//   setY(window.scrollY);
//   window.addEventListener("scroll", handleNavigation);

//   return () => {
//     window.removeEventListener("scroll", handleNavigation);
//   };
// }, [handleNavigation]);

const [open, setOpen] = useState(false);

const showDrawer = () => {
  setOpen(true);
};

const onClose = () => {
  setOpen(false);
};

  return (
    <>
      <Header posType="floating" setTab={update}/>
      {/* <div className={"modifySearch flightModifySearch " + (scrollDown ? "sticky" : "")}> */}
      {request && (request.src || request.orignDataOne) &&
      <div className="modifySearch flightModifySearch sticky displayNone-640" onMouseEnter={() => setScrollDown(true)}
        onMouseLeave={() => setScrollDown(false)}>
         <FlightSearch
          modify={true}
          data={
            request
          }
          activeTab={activeTab()}
          multiModifySearch={parseInt(request.tt) === 3 ? true : false}
          scrollDown={scrollDown}
        />
      </div>
      }
      <div className="container">
        <Button type="primary" className="cntBtn planeBtn displayNoneLG" onClick={showDrawer}>
        {context && context.translation && context.translation.modiSearch}
              <img src={BrandIcon} alt="brandIcon" className="brandIcon" />
        </Button>
      </div>
      <Drawer placement="bottom" open={open} className="modifySearchPopup">
        <div className="modifySearchTitleWrap">
            <div className="modifySearchTitle">{context.translation && context.translation.modiSearch}</div>
            <i className="icon icon-cancel" onClick={onClose}></i>
        </div>
        {request && (request.src || request.orignDataOne) && 
        <div className="modifySearch flightModifySearch sticky">
          <FlightSearch
            modify={true}
            data={
              request
            }
            activeTab={activeTab()}
            multiModifySearch={parseInt(request.tt) === 3 ? true : false}
            scrollDown={scrollDown}
          />
        </div>
        }
      </Drawer>
      {loading && (
        <>
          <FlightSearchLoader />
          <LoaderN
            title={
              <>
              <span className="destinationFrom">{context.selectLang === "en" && parseInt(request.tt) !== 3 && request && request.srcCity?request.srcCity:parseInt(request.tt) !== 3 && request.src!==undefined && request.src.city_ar!==undefined && request.src.city_ar}</span>
                {" "}
                {parseInt(request.tt) !== 3 && (
                  <i className="icon icon-next-long-arrow-right-svgrepo-com"></i>
                )}{" "}
                <span className="destinationTo">{context.selectLang === "en" && parseInt(request.tt) !== 3 && request && request.destCity?request.destCity:parseInt(request.tt) !== 3 && request.dest!==undefined && request.dest.city_ar!==undefined && request.dest.city_ar}  </span>
              </>
                }
            subtitle={context && context.translation && context.translation.bestFlight}
            notSRPLoader="NOTSRP"
          />
        </>
      )}
      <div className="container resultsPage">
        {state.searchResp !== undefined && (
          <FlightResultsDetails
            searchRes={state.searchResp}
            request={state.searchReq}
            initRes={state.initialResponse}
            longUrl={longUrl}
            closeLoader={closeLoader}
            raw_srp_data={raw_srp_data}
          />
        )}
        {!loading && state.searchResp === undefined && <NoResultsFound />}
      </div>
      <SecureGuarantee />
      <Footer />
    </>
  );
}

export default FlightResults;
