import { Alert, Button, Col, Drawer, Row, Spin, Typography, Tooltip, Modal, Space, Input } from "antd";
import HotelFilters from "./HotelFilters";
import { Context, ExchangeRates } from "../../../../App";
import HotelResultsSort from "./HotelResultsSort";
import HotelResultsBlock from "./HotelResultsBlock";
import { useEffect, useReducer, useRef, useState,useContext } from "react";
import { isNotNull } from "../../../utilities/validators";
// import LoaderH from "../../../common/loaders/LoaderH";
const BASE_CURRENCY = process.env.REACT_APP_BASE_CUR;

const initialState = {
  searchResp: "",
  loading: false,
  results: true,
  enable: true,
  load: true,
  hotelContResp: "",
  contentAvail: false,
  respAvail: false,
  showPop: false,
  hotelsearchRes: "",
  filtersearchRes: "",
  sortsearchRes: "",
  update: false,
  showMapView: false,
  hideSort: false,
  noofnights: 0,
  markupVal: 0,
  applToData: [],
  offers: [],
  clickedMap: false,
  noresults: false,
  reset: false,
  markupValue: 0,
};

//=====================================
// Reducer function for userReact Hook
// which will update the state
//=====================================
const reducer = (state, action) => {
  switch (action.type) {
    case "clear":
      return {
        ...state,
      };
    default:
      return { ...state, [action.type]: action.payload };
  }
};

function HotelResultsDetails(props) {
  const [context, setContext] = useContext(Context);
  const { checkAvail, response, request, shortUrl, initialResp } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const [showResults, setShowResults] = useState(10); // Number of results to show initially
  const EndDivRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const [base_usr_exrate, setBaseToUserExrate] = useState(1);
  // const [locationFilter, setLocationFilter] = useState(false);

  useEffect(() => {
    if (response !== undefined) {
      dispatch({ type: "hotelsearchRes", payload: response });
      dispatch({ type: "sortsearchRes", payload: response });
    }
  }, [response]);

  useEffect(() => {
    var userCur = context.userCur!==undefined && context.userCur!==""?context.userCur:sessionStorage.getItem("SEL_CUR")
    var exRates = JSON.parse(localStorage.getItem("CNT_EX_RATES"))
    if(context.ex_rates === undefined || context.ex_rates === "") {
      setContext({...context, ex_rates: exRates})
    }
    if((context.ex_rates !==undefined && context.ex_rates!=="") || (exRates !== undefined && exRates !== "") && userCur!==undefined && userCur!==""){
      if(BASE_CURRENCY!==userCur){
           request.cur=userCur;
           let currecny=BASE_CURRENCY+" - "+userCur;
           getExchangeRate(currecny)
      }else if(BASE_CURRENCY===userCur){
        setBaseToUserExrate(1)
        request.cur=userCur;
      }
    }else{
      setBaseToUserExrate(1)
    }
  }, [context.userCur])
  
  useEffect(() => {
  if(context.ex_rates!=="" && context.ex_rates!==undefined){
    let currecny=BASE_CURRENCY+" - "+request.cur;
    getExchangeRate(currecny)
  } 
  }, [context.ex_rates])

  const getExchangeRate=(usrCur)=>{
    if(context.ex_rates!=="" && context.ex_rates!==undefined && context.ex_rates!==null){
      if (context.ex_rates?.hasOwnProperty(usrCur)) {
        var sup = context.ex_rates[usrCur];
        setBaseToUserExrate(Number(sup))
      }
    }
  }

  

  useEffect(() => {
    const divElement = EndDivRef.current;
    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          if (response && response.prpts) {
            if (showResults >= response.prpts.length) {
              return;
            }

            // Update the number of results to show
            setShowResults((prevShowResults) => prevShowResults + 10);
          }
        } /* else {
          console.log('Div is not visible in the viewport');
        } */
      });
    };

    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0, // Adjust this value to define the visibility threshold
    };

    const observer = new IntersectionObserver(handleIntersection, options);

    if (divElement) {
      observer.observe(divElement);
    }

    return () => {
      if (divElement) {
        observer.unobserve(divElement);
      }
    };
  }, [response]);

  const filterResponseCallBack = (res, objec, type, reset) => {
    if (type === "Filter" && res && res.filters && res.filters.ishotdeal) {
      // setLocationFilter(!locationFilter);
      if (res.filters.ishotdeal) {
        let obj = [{ label: "Hot Deal", value: "Hot Deal" }];
        state.applToData = obj;
        //dispatch({ type: 'applToData', payload: obj });
        if (isNotNull(reset) && reset == "allclear") {
          dispatch({ type: "reset", payload: true });
        }
      } else {
        state.applToData = isNotNull(objec) ? objec : [];
      }
    } else {
      state.applToData = isNotNull(objec) ? objec : [];
      dispatch({ type: "reset", payload: false });
      //dispatch({ type: 'applToData', payload: objec });
    }
    //state.filtersearchRes=res;
    //state.sortsearchRes=res;
    dispatch({ type: "filtersearchRes", payload: res });
    dispatch({ type: "sortsearchRes", payload: res });
    if(res && res.prpts){
      dispatch({ type: "htlsSize", payload: res.prpts.length });
      if (res.prpts.length === 0) {
        dispatch({ type: "noresults", payload: true });
      } else {
        dispatch({ type: "noresults", payload: false });
      }
      // window.scrollTo(0, 0);
    }
  };

  const checkresetval = (data) => {
    if (isNotNull(data) && data == "sort") {
      dispatch({ type: "reset", payload: false });
    }
  }; //Show List View
  const showList = (res) => {
    dispatch({ type: "showMapView", payload: false });
    dispatch({ type: "clickedMap", payload: true });
  }; //Show Map View
  const showMap = (res) => {
    dispatch({ type: "showMapView", payload: true });
    dispatch({ type: "clickedMap", payload: true });
    dispatch({ type: "hideSort", payload: true });
  };
  const listview = (res) => {
    dispatch({ type: "showMapView", payload: false });
    dispatch({ type: "clickedMap", payload: true });
    dispatch({ type: "hideSort", payload: false });
  };

  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <>
      {/* {loading && (
        <LoaderH
          title={"Please wait"}
          subtitle={"We are redirecting to review details..."}
        />
      )} */}
        <Row>
        <div className="openFilter">
            <Button type="secondary" onClick={showDrawer} className="">
              {/* {context.translation && context.translation.opnFtr} */}
              <i className="icon icon-Filter"></i> {context.translation && context.translation.filter}
            </Button>
            <Drawer placement="left" open={open} className="flightFilterPopup">
            <i className="icon icon-cancel" onClick={onClose}></i>
              {state.hotelsearchRes && (
                <HotelFilters
                  hotelresponse={state.hotelsearchRes}
                  filterresponse={filterResponseCallBack}
                  response={initialResp}
                  base_usr_exrate={base_usr_exrate}
                  cur={request.cur}
                />
              )}
            </Drawer>
          </div>
          <Col xl={6} lg={0} md={0} sm={0} xs={0}>
            {state.hotelsearchRes && (
              <HotelFilters
                hotelresponse={state.hotelsearchRes}
                filterresponse={filterResponseCallBack}
                response={initialResp}
                base_usr_exrate={base_usr_exrate}
                cur={request.cur}
              />
            )}
          </Col>
          <Col xl={18} lg={24} md={24} sm={24} xs={24} className="hotelResultsBox">
            <div className="rightSideResult">
            {state.sortsearchRes && !state.hideSort && (
              <HotelResultsSort
                hotelresponse={state.sortsearchRes}
                sortresponse={filterResponseCallBack}
                shwoMapView={showMap}
                shwoListView={showList}
                applToData={state.applToData}
                offers={state.offers}
                ismapview={state.showMapView}
                isreset={state.reset}
                resetvalue={checkresetval}
              />
            )}
            {/* {(state.hotelsearchRes || state.filtersearchRes)   &&  state.showMapView &&
           <ResultsMap response={state.hotelsearchRes} nights={state.noofnights} request={request.state.req} markup={state.markupVal} shwoListViewNew={listview} mapfilterRes={state.filtersearchRes}/>
           } */}
            {(checkAvail &&
              response !== "" &&
              response.prpts &&
              response.prpts.length > 0) ?
              response.prpts.map((htl, index) => (
                <>
                  {" "}
                  {htl !== null && index < showResults && (
                    <HotelResultsBlock
                      htl={htl}
                      request={request}
                      response={response}
                      shortUrl={shortUrl}
                      base_usr_exrate={base_usr_exrate}
                    />
                  )}
                </>
              )) : (
                <>
                {checkAvail &&
                initialResp !== "" &&
                initialResp.prpts &&
                initialResp.prpts.length > 0 &&
                initialResp.prpts.map((htl, index) => (
                  <>
                    {" "}
                    {htl !== null && index < showResults && (
                      <HotelResultsBlock
                        htl={htl}
                        request={request}
                        response={initialResp}
                        shortUrl={shortUrl}
                        base_usr_exrate={base_usr_exrate}
                      />
                    )}
                  </>
                ))
                }
                </>
              )}
            <div style={{ float: "right" }} ref={EndDivRef}></div>
            </div>
          </Col>
        </Row>
      
    </>
  );
}

export default HotelResultsDetails;
