/**
 * @ Author: Ubedullah Mohammed
 * @ Create Time: 2023-05-29 02:56:45
 * @ Description: This is an service for CMS Screens api
 */

import axios from "axios";
import { apiPostHelper } from "../../components/utilities/helper";
import api from '../../components/utilities/api'; // Import the custom axios instance

const API_URL = process.env.REACT_APP_B2C_ENGINE_URL;

class CMSService {
  async loadRecommendations(data,token) {
    const headers = {
      headers: {
          'Content-Type': 'application/json',
          'auth-token': token
        }
     };
    return await api.post(`${API_URL}/cnt/cms/retrieveCntb2c`, data,headers);
  }

  async loadTopRoutes(data,token) {
    const headers = {
      headers: {
          'Content-Type': 'application/json',
          'auth-token': token
        }
     };
    return await api.post(`${API_URL}/cnt/cms/retrieveMfrb2c`, data,headers);
  }

  async loadTopDestinations(data,token) {
    const headers = {
      headers: {
          'Content-Type': 'application/json',
          'auth-token': token
        }
     };
    return await api.post(`${API_URL}/cnt/cms/retrieveloveitb2c`, data,headers);
  }

  async loadPopularHotels(data,token) {
    const headers = {
      headers: {
          'Content-Type': 'application/json',
          'auth-token': token
        }
     };
    return await api.post(`${API_URL}/cnt/cms/retrievehtlb2c`, data,headers);
  }

  async loadHighInDemand(data,token) {
    const headers = {
      headers: {
          'Content-Type': 'application/json',
          'auth-token': token
        }
     };
    return await api.post(`${API_URL}/cnt/cms/retrievepkgb2c`, data,headers);
  }

  //Send Package Email
  async sendPackageEmail(obj,token) {
    let finalRes = {};
    const headers = {
      headers: {
        "Content-Type": "application/json",
        'auth-token': token
      },
    };
    await apiPostHelper(`${API_URL}/cnt/cms/package_email`, obj,headers).then(
      (response) => {
        finalRes = response.data;
      }
    );
    return Promise.resolve(finalRes);
  }
}

export default new CMSService();
