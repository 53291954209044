import axios from 'axios';

const instance = axios.create();
sessionStorage.setItem("APIFAILED", "response");
sessionStorage.setItem("MAINTENANCE", "response");

// Interceptor for handling errors
instance.interceptors.response.use(
  (response) => response,
  (error) => {
    // Handle specific error scenarios
    if (axios.isCancel(error)) {
      // Handle cancelation
      sessionStorage.setItem("APIFAILED", "no-response");
    } else if (error.response) {
      // Handle HTTP errors
      sessionStorage.setItem("APIFAILED", "no-response");
      if (error.response.status === 500 || error.response.status === 502 || error.response.status === 503) {
        // Handle 500, 502, 503 errors
      sessionStorage.setItem("APIFAILED", "response");
      sessionStorage.setItem("MAINTENANCE", "no-response");
      }
    } else if (error.message === 'Network Error') {
      // Handle network errors
      sessionStorage.setItem("MAINTENANCE", "no-response");
    }

    return Promise.reject(error);
  }
);

export default instance;