/**
 * @ Author: Srikanth Bandaru
 * @ Create Time: 2023-05-13 14:05:26
 * @ Description: Common footer for the application
 */

import React from "react";
import { Link } from "react-router-dom";
import { Typography } from "antd";
import Header from "./Header";
import Footer from "./Footer";

const { Title } = Typography;

function About(props) {

    const update=(type)=>{
    }

    

  return (
    <>
    <Header setTab={update}/>
    <div className="breadcrumbWrap">
        <div className="container">
            <ul className="breadcrumb">
                <li className="breadcrumbMenu">
                    <Link to="/">
                        Home
                    </Link>
                </li>
                <li className="breadcrumbMenu">
                    <Link to="/about">
                        About
                    </Link>
                </li>
            </ul>
        </div>
    </div>
      <div className="aboutPage">
        <div className="container">
            <div className="contentWrap">
                <Title level="3" className="aboutPageHeading">Clickntravel is the best travel solution, specialized in facilitating unforgettable travel experiences.</Title>
                <p className="aboutPageContent">
                As a part of Tawfeeq Travel group, we leverage over a decade of experience in the travel and tourism industry to provide top-notch services.
                </p>
                <p className="aboutPageContent">
                We collaborate with travel partners worldwide to provide turnkey travel solutions to their customers. Clickntravel offers all the tools and support you need to manage and grow your travel business without hassles. Our goal is to build mutually beneficial business relationships based on professionalism, transparency, and quality service delivery.
                </p>
                <p className="aboutPageContent">
                Whether your clients are travelling on vacation, honeymoon, a business trip, or attending an event, we have a perfect package that aligns with various fun and business travel objectives. Our team is well prepared to cater to your client's unique needs ranging from accessibility, diet, and language, to provide an unforgettable travel experience.
                </p>
            </div>
            <div className="contentWrap">
                <Title level="3" className="aboutPageHeading">Business Travel</Title>
                <p className="aboutPageContent">
                Our service takes care of all your client's business travel needs, allowing them to forget about the hassles of travelling and focus on their business. We offer the best deals for single and group business travels.
                </p>
            </div>
            <div className="contentWrap">
                <Title level="3" className="aboutPageHeading">Tourism & Vacations</Title>
                <p className="aboutPageContent">
                We provide a wide range of tour and vacation packages ranging from adventurous to educational and many more. Even if you have a destination in mind, we'll help your clients get the best vacation experience.
                </p>
            </div>
            <div className="contentWrap">
                <Title level="3" className="aboutPageHeading">Meeting & Events</Title>
                <p className="aboutPageContent">
                Are your clients planning to attend meetings or organize events anywhere around the globe? We provide hassle-freetravel, from flight to event ticketing; we arrange the necessities that give your clients a pleasant travel experience.
                </p>
            </div>
            <div className="contentWrap">
                <Title level="3" className="aboutPageHeading">Visa Services</Title>
                <p className="aboutPageContent">
                Does your client’s need a unique travel product? We've got your back! Clickntravel is certified to represents and distributes world-class products like Hertz, Trafalgar, ClubMed, Rail Europe, Costa Cruises in the Qatar market.
                </p>
            </div>
        </div>
      </div>
      <Footer/>
    </>
  );
}

export default About;
