import React, { useState,useContext,useReducer,useEffect } from "react";
import Footer from "../../../common/Footer";
import Header from "../../../common/Header";
import SecureGuarantee from "../../../homepage/SecureGuarantee";
import { Row, Col, Typography,Modal,Button,Alert,Checkbox } from "antd";
import HotelBookingDetails from "../common/HotelBookingDetails";
import HotelGuestDetails from "./HotelGuestDetails";
import HotelBookingService from "../../../../services/hotels/HotelBookingService";
import HotelRoomPrice from "../common/HotelRoomPrice";
import LoaderN from "../../../common/loaders/LoaderN";
import Utilities from "../../../utilities/Utilities";
import { emailValidation, mobileNumberValidation, characterValidation } from '../../../utilities/FormValidations'
import { useLocation,useNavigate } from "react-router-dom";
import { Context } from "../../../../App";
import HotelBrandIcon from "../../../../assets/gif/Forever/White/H_WHITE_FOREVER.gif"
import CntCommonService from "../../../../services/common/CntCommonService";
import ApplyPromoCode from "../../../common/ApplyPromoCode";
import DateUtils from "../../../utilities/DateUtils";
import HotelPolicies from "../hoteldetails/HotelPolicies";
import { Link } from "react-router-dom";
const { Title } = Typography;
const FLIGHT_SEARCH_CID = process.env.REACT_APP_FLIGHT_SEARCH_CID;
const BASE_CURRENCY = process.env.REACT_APP_BASE_CUR;
const initialState = {
  notiMessage: '', notiMessageShow: false, notiVarient: '',promo_id:0,promo_disc:0,promo_code:"",promo_remarks:"",procodeList:[],appliedList:[],room_pdisc:0,
};
const reducer = (state, action) => {
  switch (action.type) {
    default:
      return { ...state, [action.type]: action.payload };
  }
};
function HotelReview() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const location = useLocation();
  const navigate = useNavigate();
  const [isAvaial, setIsAvail] = useState(false);
  const [roomsInfo,setRoomsInfo] = useState([]);
  const [prepersist,setPrepersist] = useState(false);
  const [context, setContext] = useContext(Context);
  const [open, setOpen] = useState(false);
  const [policies, setPolicies] = useState(false);
  const [agree, setAgree] = useState(false);
  const [terms, setTerms] = useState(true);
  
  const [request, setRequest] = useState(
    location.state !== null &&
      location.state.req &&
      location.state.req !== undefined
      ? location.state.req
      : ""
  );

  useEffect(() => {      
    if(context.user_resp !== "" && context.user_resp!==undefined && context.user_resp?.res !== undefined) {
      loadPromoCodes("Register");
    }else{
      loadPromoCodes("Guest");
    }
   }, [context.user_resp])

   useEffect(() => {
    request.cur = context.userCur!==undefined && context.userCur!==""?context.userCur:sessionStorage.getItem("SEL_CUR");
   }, [sessionStorage.getItem("SEL_CUR") || context.userCur])

 const calTotalPrice = (rooms) => {
  let totalFare=0;
  for(let room of rooms){
  if(totalFare===0){
    totalFare=Utilities.addPrice(room.rmpr[0].pr[0].tf - room.rmpr[0].pr[0].com_disc,0,"Add");
    if(room.rmpr[0].servicefee.length>0){
      totalFare=parseFloat(totalFare)+ parseFloat(room.rmpr[0].servicefee[0].sf);
    }
  }else{
    let fare=Utilities.addPrice(room.rmpr[0].pr[0].tf - room.rmpr[0].pr[0].com_disc,0,"Add");
    if(room.rmpr[0].servicefee.length>0){
      totalFare=parseFloat(totalFare)+ parseFloat(room.rmpr[0].servicefee[0].sf);
    }
    totalFare=parseFloat(totalFare) + parseFloat(fare);
  }
  }
  return Number(totalFare);
}
const validateTitle = (count, type,rm) => {
  dispatch({ type: 'notiMessageShow', payload: true })
  dispatch({ type: 'notiMessage', payload: (context.translation && context.translation.plzselecttitgust)  + type + "" + count + (context.translation && context.translation.forroom)+ rm })
  dispatch({ type: 'notiVarient', payload: 'error' })
  hidemessage();
  return false;
}
const validateName = (name, type, count, pax,rm) => {
  if (!name) {
    dispatch({ type: 'notiMessageShow', payload: true })
    dispatch({ type: 'notiMessage', payload: (context.translation && context.translation.plzEenter)  + type +  (context.translation && context.translation.forguest)  + pax + "" + count + (context.translation && context.translation.forroom)+ rm })
    dispatch({ type: 'notiVarient', payload: 'error' })
    hidemessage();
    return false;
  }
  else {
    dispatch({ type: 'notiMessageShow', payload: true })
    dispatch({ type: 'notiMessage', payload: (context.translation && context.translation.plzentval)  + type +  (context.translation && context.translation.forguest)  + pax + "" + count  + (context.translation && context.translation.forroom)+ rm})
    dispatch({ type: 'notiVarient', payload: 'error' })
    hidemessage();
    return false;
  }
}
const validatePax = () => {
  if (roomsInfo && roomsInfo.length !== 0) {
    var paxType = "";
    var count = 1;
    let paxName = "";
    for (let eachRoom of roomsInfo) {
      if (eachRoom.pax && eachRoom.pax.length !== 0) {
        for (let val of eachRoom.pax) {
          if (val.ty === "ADT") {
            paxType = "Adult";
          } else {
            paxType = "Child";
          }
          if (val.tl === "") {
            return validateTitle(val.sno, paxType, eachRoom.rmindex);
          }
          if (val.fn === "") {
            return validateName(
              false,
              "first name",
              val.sno,
              paxType,
              eachRoom.rmindex
            );
          } else {
            val.fn = val.fn.trim();
            if (!characterValidation(val.fn)) {
              return validateName(
                true,
                "first name",
                val.sno,
                paxType,
                eachRoom.rmindex
              );
            }
          }
          if (val.ln === "") {
            return validateName(
              false,
              "last name",
              val.sno,
              paxType,
              eachRoom.rmindex
            );
          } else {
            val.ln = val.ln.trim();
            if (!characterValidation(val.ln)) {
              return validateName(
                true,
                "last name",
                val.sno,
                paxType,
                eachRoom.rmindex
              );
            }
          }
          if (val.fn !== "" && val.fn !== undefined && val.fn.length === 1) {
            dispatch({ type: "notiMessageShow", payload: true });
            dispatch({
              type: "notiMessage",
              payload:
                (context.translation && context.translation.firstNametwo) +
                paxType +
                "" +
                val.sno +
                (context.translation && context.translation.forroom) +
                eachRoom.rmindex,
            });
            dispatch({ type: "notiVarient", payload: "error" });
            hidemessage();
            return false;
          }
          if (val.ln !== "" && val.ln !== undefined && val.ln.length === 1) {
            dispatch({ type: "notiMessageShow", payload: true });
            dispatch({
              type: "notiMessage",
              payload:
                (context.translation && context.translation.lastNametwo) +
                paxType +
                "" +
                val.sno +
                (context.translation && context.translation.forroom) +
                eachRoom.rmindex,
            });
            dispatch({ type: "notiVarient", payload: "error" });
            hidemessage();
            return false;
          }

          if (
            val.fn !== "" &&
            val.fn !== undefined &&
            val.ln !== "" &&
            val.ln !== undefined &&
            val.fn === val.ln
          ) {
            dispatch({ type: "notiMessageShow", payload: true });
            dispatch({
              type: "notiMessage",
              payload:
                (context.translation && context.translation.firstNameNlast) +
                paxType +
                "" +
                val.sno +
                (context.translation && context.translation.forroom) +
                eachRoom.rmindex,
            });
            dispatch({ type: "notiVarient", payload: "error" });
            hidemessage();
            return false;
          }
          // validating DOB
          if(val.dob_day === "" || val.dob_month === "" || val.dob_year === "") {
            dispatch({ type: "notiMessageShow", payload: true });
            dispatch({
              type: "notiMessage",
              payload:
                (context.translation && context.translation.plzselectdatebod) +
                paxType +
                "" +
                val.sno +
                (context.translation && context.translation.forroom) +
                eachRoom.rmindex,
            });
            dispatch({ type: "notiVarient", payload: "error" });
            hidemessage();
            return false;
          }
          // validating DOB for Adult
          if(val.ty === "ADT" ) {
            if(val.dob_day && val.dob_month && val.dob_year) {
              // "year-month-day" (e.g., "2021-04-3")
              const dateOfBirth = `${val.dob_year}-${val.dob_month}-${val.dob_day}`
        
              const DobString = dateOfBirth;
              const Dob = new Date(DobString);
  
              // Get the current date
              const currentDate = new Date();
              // Calculate the difference between the current date and the date of birth in milliseconds
              const differenceInMs = currentDate - Dob;
  
              // Calculate the difference in years
              const differenceInYears =
                differenceInMs / (1000 * 60 * 60 * 24 * 365.25);
  
              // Check if the date of birth is less than 18 years from the current date
              if (differenceInYears < 18) {
                dispatch({ type: "notiMessageShow", payload: true });
                dispatch({
                  type: "notiMessage",
                  payload:
                    // (context.translation && context.translation.twlyears) 
                    "Date of birth should be greater than 18 years for"
                    +
                    paxType +
                    "" +
                    val.sno +
                    (context.translation && context.translation.forroom) +
                    eachRoom.rmindex,
                });
                dispatch({ type: "notiVarient", payload: "error" });
                hidemessage();
                return false;
              }
            }
          }
          // validating DOB for Child
          if(val.ty === "CHD" ) {
            if(val.dob_day && val.dob_month && val.dob_year) {
              // "year-month-day" (e.g., "2021-04-3")
              const dateOfBirth = `${val.dob_year}-${val.dob_month}-${val.dob_day}`
        
              const DobString = dateOfBirth;
              const Dob = new Date(DobString);
  
              // Get the current date
              const currentDate = new Date();
              // Calculate the difference between the current date and the date of birth in milliseconds
              const differenceInMs = currentDate - Dob;
  
              // Calculate the difference in years
              const differenceInYears =
                differenceInMs / (1000 * 60 * 60 * 24 * 365.25);
  
              // Check if the date of birth is greater than 17 years from the current date
              if (differenceInYears > 17) {
                dispatch({ type: "notiMessageShow", payload: true });
                dispatch({
                  type: "notiMessage",
                  payload:
                    // (context.translation && context.translation.dataofbirth) 
                    "Date of birth should be less than 17 years for"
                    +
                    paxType +
                    "" +
                    val.sno +
                    (context.translation && context.translation.forroom) +
                    eachRoom.rmindex,
                });
                dispatch({ type: "notiVarient", payload: "error" });
                hidemessage();
                return false;
              }
            }
          }
          //Validate Issued date
          if(val.isd_year && val.isd_month && val.isd_day) {
            // "year-month-day" (e.g., "2021-04-3")
            const isdDate = `${val.isd_year}-${val.isd_month}-${val.isd_day}`
            const IsdString = isdDate;
            const isd = new Date(IsdString);

            // Get the current date
            const currentDate = new Date();
            // Check if the date is a past date
            if (isd > currentDate) {
              dispatch({ type: "notiMessageShow", payload: true });
              dispatch({
                type: "notiMessage",
                payload:
                  (context.translation && context.translation.issdatecnot) +
                  paxType +
                  "" +
                  val.sno +
                  (context.translation && context.translation.forroom) +
                  eachRoom.rmindex,
              });
              dispatch({ type: "notiVarient", payload: "error" });
              hidemessage();
              return false;
            }
          }
          //Validate Expiry date
          if(val.exp_year && val.exp_month && val.exp_day) {
            // "year-month-day" (e.g., "2021-04-3")
            const expDate = `${val.exp_year}-${val.exp_month}-${val.exp_day}`
            const ExpString = expDate;
            const exp = new Date(ExpString);

            // Get the current date
            const currentDate = new Date();
            // Check if the date is a past date
            if (exp < currentDate) {
              dispatch({ type: "notiMessageShow", payload: true });
              dispatch({
                type: "notiMessage",
                payload:
                  (context.translation && context.translation.expdatecnot) +
                  paxType +
                  "" +
                  val.sno +
                  (context.translation && context.translation.forroom) +
                  eachRoom.rmindex,
              });
              dispatch({ type: "notiVarient", payload: "error" });
              hidemessage();
              return false;
            }
          }
          //Validate Duplicate Pax
          if (
            val.fn !== "" &&
            val.fn !== undefined &&
            val.ln !== "" &&
            val.ln !== undefined
          ) {
            paxName = val.fn + " " + val.ln;
          }
          if (paxName !== "") {
            for (let room of roomsInfo) {
              if (room.rmindex !== eachRoom.rmindex) {
                for (let pax of room.pax) {
                  let name = pax.fn + " " + pax.ln;
                  if (paxName === name) {
                    dispatch({ type: "notiMessageShow", payload: true });
                    dispatch({
                      type: "notiMessage",
                      payload: (context.translation && context.translation.leadguestname),
                    });
                    dispatch({ type: "notiVarient", payload: "error" });
                    hidemessage();
                    return false;
                  }
                }
              }
            }
          }

          //Lead pax validations
          if (val.ty === "ADT" && val.lead && eachRoom.rmindex === 1) {
            if (val.phn !== undefined) {
              if (val.phn !== null) {
                val.phn = val.phn.trim();
              }
              if (val.phn === "") {
                dispatch({ type: "notiMessageShow", payload: true });
                dispatch({
                  type: "notiMessage",
                  payload: (context.translation && context.translation.plzenterphroom),
                });
                dispatch({ type: "notiVarient", payload: "error" });
                hidemessage();
                return false;
              } else if (!mobileNumberValidation(val.phn)) {
                dispatch({ type: "notiMessageShow", payload: true });
                dispatch({
                  type: "notiMessage",
                  payload:
                    (context.translation && context.translation.plzentvalemproom),
                });
                dispatch({ type: "notiVarient", payload: "error" });
                hidemessage();
                return false;
              }
            }
            if (val.em !== undefined) {
              if (val.em !== "") {
                val.em = val.em.trim();
              }
              if (val.em === "") {
                dispatch({ type: "notiMessageShow", payload: true });
                dispatch({
                  type: "notiMessage",
                  payload: (context.translation && context.translation.plzentvalemidleadpax),
                });
                dispatch({ type: "notiVarient", payload: "error" });
                hidemessage();
                return false;
              } else if (!emailValidation(val.em)) {
                dispatch({ type: "notiMessageShow", payload: true });
                dispatch({
                  type: "notiMessage",
                  payload:
                    (context.translation && context.translation.plzenteremroomlead),
                });
                dispatch({ type: "notiVarient", payload: "error" });
                hidemessage();
                return false;
              }
            }
            if (val.phc !== undefined) {
              if (val.phc === "") {
                dispatch({ type: "notiMessageShow", payload: true });
                dispatch({
                  type: "notiMessage",
                  payload: (context.translation && context.translation.plzselephcdldpx),
                });
                dispatch({ type: "notiVarient", payload: "error" });
                hidemessage();
                return false;
              }
            }
          }
        }
      } else {
        dispatch({ type: "notiMessageShow", payload: true });
        dispatch({
          type: "notiMessage",
          payload: (context.translation && context.translation.plzentergd),
        });
        dispatch({ type: "notiVarient", payload: "error" });
        hidemessage();
        return false;
      }
      if (state.custms !== undefined) {
        for (let val of state.custms) {
          if (val.cval === "") {
            dispatch({ type: "notiMessageShow", payload: true });
            dispatch({
              type: "notiMessage",
              payload: (context.translation && context.translation.plzentaddinfo),
            });
            dispatch({ type: "notiVarient", payload: "error" });
            hidemessage();
            return false;
          }
        }
      }
      count = count + 1;
    }
  } else {
    dispatch({ type: "notiMessageShow", payload: true });
    dispatch({ type: "notiMessage", payload: (context.translation && context.translation.plzentergd) });
    dispatch({ type: "notiVarient", payload: "error" });
    hidemessage();
    return false;
  }
  return true;
};

const openPolicies = () => {
  if (validatePax()){
    setOpen(true);
  }
};

const handleChange = () => {
  if(policies) {
    setPolicies(false);
  } else {
    setPolicies(true);
    setAgree(false);
  }
  if(terms){
    setTerms(false)
  }else{
    setTerms(true)
  }
}

const convertTotalPrice=()=>{
  request.total_price=calTotalPrice(request.rooms);
  if(state.promo_disc!==0 && state.promo_code!==""){
    request.total_price= Number(request.total_price) - Number(state.promo_disc);
  }
}

 const saveHotelBooking = async (e) => {
  if (validatePax() && (request.hotelContentResp.cin !== undefined &&
    request.hotelContentResp.cout &&
    request.hotelContentResp.policies !== undefined ? policies : true)){
    setAgree(false);
    setOpen(false);
    setPrepersist(false)
    //create prebook request
    setIsAvail(true)
    let leadPax="";
    for (let room of roomsInfo) {
      for(let pax of room.pax){
        if(leadPax===""){
          leadPax=pax.fn+" "+pax.ln;
        }
        if(pax.dob_year && pax.dob_month && pax.dob_day) {
          pax.dob = `${pax.dob_year}-${pax.dob_month}-${pax.dob_day}`;
        }
        if(pax.isd_year && pax.isd_month && pax.isd_day) {
          pax.isd = `${pax.isd_year}-${pax.isd_month}-${pax.isd_day}`;
        }
        if(pax.exp_year && pax.exp_month && pax.exp_day) {
          pax.exd = `${pax.exp_year}-${pax.exp_month}-${pax.exp_day}`;
        }
      }
    }
    if (request !== undefined && request.hid !== undefined){
      let region_cur = context?.regionCur!==undefined && context?.regionCur!==""?context?.regionCur:localStorage.getItem("REGION_CUR");
      let currency = BASE_CURRENCY+" - "+region_cur;
      let baseToRegionExRate = 1;
      if (context?.ex_rates!==undefined && context?.ex_rates?.hasOwnProperty(currency)){
        baseToRegionExRate = context.ex_rates[currency];
      }
      convertTotalPrice();
      let rq_Json = {
        "hid": request.hid,
        "cid":  Number(request.cid),
        "sup":"0",
        "roompax": roomsInfo,
        "freeCancelDate":null,
        region_type: localStorage.getItem("REGION_COUNTRY") !== null &&
        localStorage.getItem("REGION_COUNTRY") === "IN"
          ? "India"
          : "Qatar",
          utype:
          context.user_resp !== "" && context.user_resp?.res !== undefined
            ? "Registered"
            : "Guest",
          bkby:
          context.user_resp !== undefined && context.user_resp !== ""
            ? context.user_resp?.res?.id
            : 0,
          bkby_em:
            context.user_resp !== undefined && context.user_resp !== ""
              ? context.user_resp?.res?.email
              : "",
          bkby_name:context.user_resp !== "" && context.user_resp?.res !== undefined
            ? context.user_resp?.res?.usr_name
            : leadPax,
            promo_code:state.promo_code,
            promo_disc:state.promo_disc,
            promo_id:state.promo_id,
            promo_remarks:state.promo_remarks,
            room_pdisc:state.room_pdisc,
            sup_cur:request.sup_cur!=="" && request.sup_cur!==undefined ? request.sup_cur : "USD",
            cur:context.userCur!==""&&context.userCur!==undefined?context.userCur:request.cur,
            region_cur:context.regionCur!==undefined && context.regionCur!==""?context.regionCur:localStorage.getItem("REGION_CUR"),
            sup_base_exrate:request.sup_base_exrate,
            base_sup_exrate:request.base_sup_exrate,
            base_usr_exrate:request.base_usr_exrate,
            base_region_exrate:baseToRegionExRate,
            bct:Number(request.total_price.toFixed(2))
      }
      try {
        let tokenKey = Utilities.generateAPIToken(rq_Json);
        await HotelBookingService.savePrebook(rq_Json,tokenKey).then((response) => {
          if (response.data!==undefined && response.data.pbres !== undefined && response.data.pbres.status !== undefined && response.data.pbres.status === "inserted") {
            sessionStorage.setItem("BOOKING_REF_NO",response.data.pbres.bookingId)
            createPayRequest(response.data.pbres.bookingId);
          } else {
            setIsAvail(false)
            setPrepersist(true)
          }
        }).catch(function (error) {
          if(error?.response?.data==="Unauthorized"){
            setIsAvail(false)
            setPrepersist(true)
          }
          console.error("Error in Prebook" + error);      
        });        
      } catch (error) {
        console.error("Error in Prebook" + error);          
      }
  }
  } else {
    setAgree(true);
  }
}

const createPayRequest = (bookingId) => {
  request.bookingId = bookingId;
  request.roomsInfo=roomsInfo;
  request.user_type =
    context.user_resp !== "" && context.user_resp?.res !== undefined
      ? "Registered"
      : "Guest";
  request.user_id =
    context.user_resp !== undefined && context.user_resp !== ""
      ? context.user_resp?.res?.id
      : 0;
  request.region_type =
    localStorage.getItem("REGION_COUNTRY") !== null &&
    localStorage.getItem("REGION_COUNTRY") === "IN"
      ? "India"
      : "Qatar";
  request.product="Hotel";
  
  navigate("/payment", {
    state: {
      req: request,
    },
  });
};

const hidemessage = () => {
  setTimeout(function () {
    dispatch({ type: 'notiMessageShow', payload: false })
    dispatch({ type: 'notiMessage', payload: '' })
    dispatch({ type: 'notiVarient', payload: '' })
  }, 5000);
}

const travDetails = (e) => {
  if (e && e.length > 0)
  setRoomsInfo(e)
}

const updateTotalprice=(price)=>{
}

const showMoreFlights=()=>{
window.location=(context?.selectLang === undefined || context?.selectLang === "en") ? "/en" : "/ar";
}
const update=(type)=>{
}

//Load Promocode for Register
const loadPromoCodes=(type)=>{
  try {
  if(type==="Register" && context.user_resp !== "" && context.user_resp?.res !== undefined && context.user_resp?.res?.id!==undefined){
    dispatch({ type: "procodeList", payload: [] });
    let date1 = request.htldtls.cin.split(" ");
    let date2 = request.htldtls.cout.split(" ");
    let from  = date1[0].split("-").reverse().join("-");
    let to  = date2[0].split("-").reverse().join("-");
    const req={
        "user_id":context.user_resp?.res?.id,
        "region_type":localStorage.getItem("REGION_COUNTRY") !== null &&
        localStorage.getItem("REGION_COUNTRY") === "IN"
          ? "India"
          : "Qatar",
        "comp_id":FLIGHT_SEARCH_CID,
        "sup":"0",
        "product_type":"Hotel",
        "from_date":from,
        "to_date":to
    }
    try {
      let tokenKey = Utilities.generateAPIToken(req);
      CntCommonService.getRegdPromoCodes(req,tokenKey).then((response) => {
        if (response.data !== undefined && response.data.suc) {
           dispatch({ type: "procodeList", payload: response.data.res });
           }
        }).catch(function (error) {
          console.error("Error in getFlightPromo" + error);      
        });      
    } catch (error) {
      console.error("Error in getFlightPromo" + error);      
    }
  }else{
    dispatch({ type: "procodeList", payload: [] });
    const request={
      "region_type":localStorage.getItem("REGION_COUNTRY") !== null &&
      localStorage.getItem("REGION_COUNTRY") === "IN"
        ? "India"
        : "Qatar",
      "sales_chnnel":"B2C",
      "product_type":"Hotel"
   }
   try {
    let tokenKey = Utilities.generateAPIToken(request);
    CntCommonService.getPromoCodeList(request,tokenKey).then((response) => {
      if (response.data !== undefined && response.data.suc) {
          dispatch({ type: "procodeList", payload: response.data.res });
         }
      }).catch(function (error) {
        console.error("Error in getFlightPromo" + error);      
      });    
   } catch (error) {
    console.error("Error in getFlightPromo" + error);     
   }
  }
}catch (err) {
  console.error("Error in getFlightPromo" + err);
}
}

const removeAppliedData=()=>{
  dispatch({ type: "promo_disc", payload: 0 });
  dispatch({ type: "promo_remarks", payload: "" });
  dispatch({ type: "promo_code", payload: "" });
  dispatch({ type: "promo_id", payload: 0 });
  // dispatch({ type: "notiMessageShow", payload: true });
  // dispatch({ type: "notiMessage", payload: context.translation && context.translation.removedpromo});
  // dispatch({ type: "notiVarient", payload: "success" });
  // hidemessage();
}


const aplyPromoCode=(code)=>{
  try {
    let checkPromo = state.procodeList.length > 0 && state.procodeList.filter((promo) => promo.pro_code==code);
    let promoExistList = [];
      if(state.appliedList.length > 0){
        promoExistList = state.appliedList.length > 0 && state.appliedList.filter((obj) => obj.pro_code===code);
       if(promoExistList===undefined){
          promoExistList = [];
       }
      }
    if(code!=="" && checkPromo.length!==0 && promoExistList.length===0){
      let totalFare=0;
      let baseFare=0;
      for(let room of request.rooms){
      if(totalFare===0){
        totalFare=Utilities.addPrice(room.rmpr[0].pr[0].tf - room.rmpr[0].pr[0].com_disc,0,"Add");
        baseFare=parseFloat(room.rmpr[0].pr[0].bf);
        if(room.rmpr[0].servicefee.length>0){
          totalFare=parseFloat(totalFare)+ parseFloat(room.rmpr[0].servicefee[0].sf);
        }
      }else{
        let fare=Utilities.addPrice(room.rmpr[0].pr[0].tf - room.rmpr[0].pr[0].com_disc,0,"Add");
        baseFare=parseFloat(baseFare) + parseFloat(room.rmpr[0].pr[0].bf);
        if(room.rmpr[0].servicefee.length>0){
          totalFare=parseFloat(totalFare)+ parseFloat(room.rmpr[0].servicefee[0].sf);
        }
        totalFare=parseFloat(totalFare) + parseFloat(fare);
      }
      }
      const req={
        "promo_code":code,
        "user_id":context.user_resp?.res?.id,
        "promo_id":checkPromo[0].promo_id,
        "base_fare":baseFare,
        "total_fare":totalFare,
        "product_type":"Hotel"
     }
      let tokenKey = Utilities.generateAPIToken(req);
      CntCommonService.applyPromoCode(req,tokenKey).then((response) => {
      if (response.data !== undefined && response.data.suc){
          dispatch({ type: "promo_disc", payload: response.data.res.promo_discount });
          dispatch({ type: "promo_remarks", payload: response.data.res.remarks });
          dispatch({ type: "promo_code", payload: code });
          dispatch({ type: "promo_id", payload: response.data.res.promo_id });
          dispatch({ type: "room_pdisc", payload: response.data.res.promo_discount/request.rooms.length });
          dispatch({ type: "notiMessageShow", payload: true });
          dispatch({ type: "notiMessage", payload: context.translation && context.translation.sucpromo});
          dispatch({ type: "notiVarient", payload: "success" });
          response.data.res.pro_code=code;
          response.data.res.room_pdisc=response.data.res.promo_discount/request.rooms.length;
          let list = state.appliedList;
          list.push(response.data.res);
          dispatch({ type: "appliedList", payload: list });
          hidemessage();
         }else{
          if(response.data.res===500){
            dispatch({ type: "notiMessage", payload: context.translation && context.translation.promoerrmsgone});
          }else if(response.data.res===501){
            dispatch({ type: "notiMessage", payload: context.translation && context.translation.promoerrmsgtwo});
          }else if(response.data.res===502){
            dispatch({ type: "notiMessage", payload: context.translation && context.translation.promoerrmsgthree});
          }else if(response.data.res===503){
            dispatch({ type: "notiMessage", payload: context.translation && context.translation.promoerrmsgfour});
          }else if(response.data.res===504){
            dispatch({ type: "notiMessage", payload: context.translation && context.translation.promoerrmsgfive});
          }
          dispatch({ type: "notiMessageShow", payload: true });
          dispatch({ type: "notiVarient", payload: "error" });
          dispatch({ type: "promo_disc", payload: 0 });
          dispatch({ type: "room_pdisc", payload: 0 });
          dispatch({ type: "promo_code", payload: "" });
          hidemessage();
         }
      }).catch(function (error) {
        if(error?.response?.data==="Unauthorized"){
          dispatch({ type: "notiMessageShow", payload: true });
          dispatch({ type: "notiMessage", payload: context.translation && context.translation.promoerrmsgfive});
          dispatch({ type: "notiVarient", payload: "error" });
          dispatch({ type: "promo_disc", payload: 0 });
          dispatch({ type: "promo_code", payload: "" });
          hidemessage();
        }
        console.error("Error in applyPromoCode" + error);      
      });
    }else if(promoExistList.length!==0){
      dispatch({ type: "promo_disc", payload: promoExistList[0].promo_discount });
      dispatch({ type: "promo_remarks", payload: promoExistList[0].remarks });
      dispatch({ type: "promo_code", payload: code });
      dispatch({ type: "promo_id", payload: promoExistList[0].promo_id });
      dispatch({ type: "room_pdisc", payload: promoExistList[0].room_pdisc });
      dispatch({ type: "notiMessageShow", payload: true });
      dispatch({ type: "notiMessage", payload: context.translation && context.translation.sucpromo});
      dispatch({ type: "notiVarient", payload: "success" });
      hidemessage()
    }else{
      dispatch({ type: "notiMessageShow", payload: true });
      dispatch({ type: "notiMessage", payload: context.translation && context.translation.validpromo});
      dispatch({ type: "notiVarient", payload: "error" });
      dispatch({ type: "promo_disc", payload: 0 });
      dispatch({ type: "promo_code", payload: "" });
      hidemessage();
    }
  } catch (err) {
    console.error("Error in applyPromoCode" + err);
  }
}

  return (
    <>
      <Header setTab={update} page={"Review"}/>
      {isAvaial &&
        <LoaderN
          title={context.translation && context.translation.pleaseWait}
          subtitle={context.translation && context.translation.processingReq}
        />
      }
       {state.notiMessageShow ? (
        <Alert
          description={state.notiMessage}
          type={state.notiVarient}
          closable
          onClose={() => dispatch({ type: "notiMessageShow", payload: false })}
          className="alertForm"
        >
          {" "}
          {state.notiMessage}
        </Alert>
      ) : (
        ""
      )}
      <Row className="statusCheck">
        <Col span={6} className="circleWrap completed">
          <span className="circle">
            <i className="icon icon-tick"></i>
          </span>
          <span className="text">{context.translation && context.translation.reviewBok} <span className="displayNone-640"> &nbsp;{context.translation && context.translation.booking}</span></span>
        </Col>
        <Col span={6} className="circleWrap active">
          <span className="circle">{context.translation && context.translation.two}</span>
          <span className="text">{context.translation && context.translation.guestDetail}</span>
        </Col>
        <Col span={6} className="circleWrap">
          <span className="circle">{context.translation && context.translation.three}</span>
          <span className="text">{context.translation && context.translation.payment}</span>
        </Col>
        <Col span={6} className="circleWrap">
          <span className="circle">{context.translation && context.translation.four}</span>
          <span className="text">{context.translation && context.translation.confirmation}</span>
        </Col>
      </Row>
      <div className="container">
        <div className="guestDetails">
          <Row gutter={30} className="paxDetailsWrap">
            <Col span={17}>
            <div className="selectedFlights">
              <span>{context.translation && context.translation.one}</span>
              <Title level={4}>{context.translation && context.translation.reviewHotel}</Title>
            </div>
              {request.htldtls && <HotelBookingDetails htldtls={request.htldtls} />}
              <HotelGuestDetails  sendPsngersData={travDetails} srequest={request} />
            </Col>
            <Col span={7} className="marginTop30">
              <div className="rightSideSticky">
              <HotelRoomPrice srequest={request} senTotalFare={updateTotalprice}  promoDisc={state.promo_disc}/>
              {state.procodeList.length>0&&(
                <div className="applyPromoCodeMainWrap">
                <ApplyPromoCode product="Hotel" sendPromo={aplyPromoCode} promo_codes={state.procodeList} removeData={removeAppliedData}  promo_disc={state.promo_disc}/>
                </div>
              )}
              
              </div>
            </Col>
          </Row>
          <Row gutter={30}>
          <Col span={17} className="colFullWidth">
            <div className="conBtnDetails">
              {request.hotelContentResp.cin !== undefined &&
              request.hotelContentResp.cout &&
              request.hotelContentResp.policies !== undefined ? (
                      <Button type="primary" onClick={openPolicies} className="cntBtn continueBtn">
                        <span>{context.translation && context.translation.contPayment}</span>
                        <img src={HotelBrandIcon} alt="hotelBrandIcon" className="hotelBrandIcon" />
                        {/* <i className="icon icon-H-logo"></i> */}
                      </Button>
              ) : (
                  <Button type="primary" onClick={saveHotelBooking} className="cntBtn continueBtn">
                    <span>{context.translation && context.translation.contPayment}</span>
                    <img src={HotelBrandIcon} alt="hotelBrandIcon" className="hotelBrandIcon" />
                  </Button>
              )}
            </div>
          </Col>
          <Col span={7}></Col>
        </Row>
        </div>
      </div>
      <Modal
        
        centered
        open={open}
        footer={null}
        width={1000}
        top={0}
        className="hotelPolicisPopup"
      >
      {/* <i
        className="icon icon-cancel"
        onClick={() => setOpen(false)}
      ></i> */}
        {request.hotelContentResp.cin !== undefined &&
        request.hotelContentResp.cout &&
        request.hotelContentResp.policies !== undefined && (
          <>
          <div id="hotelPolicies">
          <div className="hrsTitle marginTop50" style={{marginTop: "0px"}}>{context.translation && context.translation.hotelPolicies}</div>
          <HotelPolicies
            cin={request.hotelContentResp.cin}
            cout={request.hotelContentResp.cout}
            payments={
              request.hotelContentResp.onsitePayments !== null &&
              request.hotelContentResp.onsitePayments
            }
            policy={request.hotelContentResp.policies}
            canpolicy={request.hotelContentResp.checkin}
            fees={request.hotelContentResp.fees}
            flow={"Review"}
          />
          </div>
          <div className="commonFlex">
          <div className="hotelPolicisPopCheckWrap">
          <Checkbox className="checkBoxDetails understand" 
            value={policies}
            onChange={handleChange}
            checked={policies}
            >
            {context.translation && context.translation.iudhotel}
          </Checkbox>
          <div className="error">{agree && (context.translation && context.translation.plzacchotpoli)}</div>
          </div>
          <div className="onlyFlex hotelPolicisPopupBtnWrap">
                <Button type="secondary" ghost onClick={() => setOpen(false)} className="cancelBtn">
                  <span>{context.translation && context.translation.cancel}</span>
                </Button>
                <Button type="primary" onClick={saveHotelBooking} className="cntBtn continueBtn">
                  <span>{context.translation && context.translation.contPayment}</span>
                  <img src={HotelBrandIcon} alt="hotelBrandIcon" className="hotelBrandIcon" />
                </Button>
          </div>
          </div>
          
          {/* <Checkbox className="checkBoxDetails understand" 
            value={terms}
            onChange={handleChange}
            checked={terms}
            >
              {"I Understand and agree with the "}
              <Link
                className="knowMore"
                to="/privacy-policy"
                target="_blank"
              >
                {"Privacy policy"}
              </Link>
              {", the "}

              <Link
                className="knowMore"
                to="/user-agreement"
                target="_blank"
              >
                {"User agreement"}
              </Link>
              {" and "}
              <Link
                className="knowMore"
                to="/flight/terms-conditions"
                target="_blank"
              >
                {"Terms of service "}
              </Link>
              {context.translation && context.translation.clickNtravel}
            </Checkbox> */}
          
          </>
        )}
      </Modal>
      <Modal title="" open={prepersist} onOk={""} onCancel={""} footer={null} className="wrongAlertWrapper">
      <div className="oopsTitle"><i className="icon icon-alert-popup"></i> {context.translation && context.translation.oops}</div>
      <div className="wrongContent">{context.translation && context.translation.wentWrg}</div>
      <Button
          type="primary"
          className="wrongBtn"
          onClick={showMoreFlights}
          >
           {context.translation && context.translation.backtohome}
        </Button>
      </Modal>
      <SecureGuarantee />
      <Footer />
    </>
  );
}

export default HotelReview;
