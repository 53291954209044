import React from "react";
import ReactDOM from "react-dom/client";
import "antd/dist/reset.css";
import "./index.css";
import "./assets/styles/Cnt.css";
import "./assets/fonts/icons/style.css";
import "./assets/styles/Results.css";
import "./assets/styles/MyAccount.css";
import "./assets/styles/Review.css";
import "./assets/styles/Responsive.css";
import "./assets/styles/Loader.css";
import "./assets/styles/FlightArabic.css";
import "./assets/styles/HotelArabic.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ConfigProvider } from "antd";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  //<React.StrictMode>
  <ConfigProvider
    theme={{
      token: {
        colorPrimary: "#ED1D24",
        fontFamily: "Avenir-Roman",
        colorLink: "#3156AB",
      },
    }}
  >
    <App />
  </ConfigProvider>
  //</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
