/**
 * @ Author: Ubedullah Mohammed
 * @ Create Time: 2024-01-17 05:42:53
 * @ Description: Maintenance component when the Application has down time
 */

import { Button, Typography } from 'antd';
import React from 'react';
import MaintenanceError from "../../assets/img/maintenance.png";
const { Title } = Typography;

function Maintenance(props) {
    const reload = () => {
      window.location.reload();
    }
    
  return (

    <div className='errorOuterWrap'>
      <div className='errorInnerWrap maintenance'>
          <center>
            <img src={MaintenanceError} alt='Maintenance-error' className='maintenance-img' />
            <Title level={2}>{props.context.translation && props.context.translation.websiteum}</Title>
            <p className='maintenance'>{props.context.translation && props.context.translation.wsafti}</p>
            <p>{props.context.translation && props.context.translation.oursitecsmu}</p>
            <Title level={4}>{props.context.translation && props.context.translation.tqforyp}</Title>
            {/* <Button onClick={reload}>Retry</Button> */}
          </center>
      </div>
    </div>
 )
}

export default Maintenance;