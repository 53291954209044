/**
 * @ Author: Srikanth Bandaru
 * @ Create Time: 2023-04-24 12:32:04
 * @ Description: This is an service for flights api
 */

import axios from "axios";
import api from "../../components/utilities/api"


const API_URL_SEARCH = process.env.REACT_APP_B2C_COMMON
const API_URL_B2C_ENGINE = process.env.REACT_APP_B2C_ENGINE_URL
const HOTEL_COMMON = process.env.REACT_APP_B2C_HOTEL_COMMON

class FlightBookService {

  async saveBooking(obj,token){
    const headers = {
      headers: {
          'Content-Type': 'application/json',
          'auth-token': token
        }
     };
    return await api.post(`${API_URL_B2C_ENGINE}/cnt/booking/prebook`,obj,headers)
  }

    async fireBookReq(obj,token){
      const headers = {
        headers: {
            'Content-Type': 'application/json',
            'auth-token': token
          }
       };
      return await api.post(`${API_URL_SEARCH}/flight/book`,obj,headers)
    }

    async savePayRequest(data,token){
      const headers = {
        headers: {
            'Content-Type': 'application/json',
            'auth-token': token
          }
       };
      return await api.post(`${API_URL_B2C_ENGINE}/cnt/common/payment/save`,data,headers);
      }
      
    async getPayResponse(obj,token){
      const headers = {
        headers: {
            'Content-Type': 'application/json',
            'auth-token': token
          }
       };
      return await api.post(`${API_URL_B2C_ENGINE}/cnt/common/payment/fetch`,obj,headers);
      }

    async priceCheckService(obj,token){
      const headers = {
        headers: {
            'Content-Type': 'application/json',
            'auth-token': token
          }
       };
        return await api.post(`${API_URL_SEARCH}/flight/pricecheck`,obj,headers);
      }

    async sendConfirmationMail(obj,token){
      const headers = {
          headers: {
              'Content-Type': 'application/json',
              'auth-token': token
            }
         };
        return await api.post(`${API_URL_B2C_ENGINE}/cnt/booking/share-itinerary`,obj,headers)
      }
    async loadCrossSellHotels(obj,token){
      const headers = {
          headers: {
              'Content-Type': 'application/json',
              'auth-token': token
            }
         };
        return await api.post(`${HOTEL_COMMON}/hotelavailability/crossell/check `,obj,headers)
    }
}

export default new FlightBookService()  

