import { Col, Row, Typography } from 'antd';
import { useContext, useState } from 'react';
import { Context } from '../../App';
import React from 'react';
import Link from "antd/es/typography/Link";
import Utilities from '../utilities/Utilities';
import FormatCurrency from '../utilities/FormatCurrency';
import BrandIcon from "../../assets/gif/Forever/White/H_WHITE_FOREVER.gif";
const { Title } = Typography;

function HotelPaymentInfo(props) {
    const [context, setContext] = useContext(Context)

    const handleClick = (e) => {

        let cn = e.currentTarget.parentElement.classList;
    
        if (cn.contains('active')) {
          cn.remove('active')
        } else {
          cn.add('active')
        }
      };

    return (
        <>
            {/* <div className='guestDetails'>
                <div className="bSummary">
                    <Title level={4}>{context.translation && context.translation.bokSumy}</Title>
                </div>
                <div className="innerBSSummary">
                    <div className='onlyFlex hotelInfoWeap'>
                        <div className='paymentSummaryImage'>
                            <img src={props.request.htldtls.htimg} alt='hotel-image' />
                        </div>
                        <div className='hotelInfo'>
                            <div className='hotelName'>{props.request.htldtls.htnm}</div>
                            <div className="hotelRating">
                            {Utilities.getHotelStartRating(props.request.htldtls.rating).map((star, idx) => (
                            <React.Fragment key={idx}>
                            <span className="hotelRating">
                            <i className="icon icon-star"></i>
                            </span>
                            </React.Fragment>
                            ))}
                                </div>
                            <div className='hotelAddress'></div>
                        </div>
                    </div>
                    <div className='commonFlex'>
                        <div className='checkInWrap'>
                            <div className='checkTitle'>{context.translation && context.translation.checkin}</div>
                            <div className='checkTime'>{props.request.htldtls.cin}</div>
                        </div>
                        <div className='checkInWrap'>
                            <div className='checkTitle'>{context.translation && context.translation.checkOut}</div>
                            <div className='checkTime'>{props.request.htldtls.cout}</div>
                        </div>
                    </div>
                    <div className="innerSumDivider"></div>
                    {props.request && props.request.roomsInfo.map((room, index) => (
                    <div className="bookSummary">
                        <Row>
                            <Col md={24}>
                            <div className="bookSummaryCollapse">
                                <Link>
                                <div className="commonFlex collapseHeader">
                                    <div className="rightArrow roomTitle">
                                        {context.translation && context.translation.room} {room.rmindex}
                                    </div>
                                    <div className='guestNum'>
                                     {room.adt} {context.translation && context.translation.adults} & {room.chd} {context.translation && context.translation.chis}
                                    </div>
                                </div>
                                </Link>
                                <div className="innerBody">
                                {room.pax.map((pax, index) => (
                                <div className="guestName">
                                    {pax.fn} {pax.ln} ({pax.ty})
                                </div>
                                ))}
                                </div>
                            </div>
                            </Col>
                        </Row>
                        <div className="innerSumDivider"></div>
                    </div>
                    ))}
                        <Row>
                        <Col md={24}>
                            <div className="dFlex payablePriceWrapper">
                            <div>
                                <span className="payablePrice">
                                {context.translation && context.translation.totPayPrice}
                                </span>
                                <span className="allNights">({props.request.nonights} {context.translation && context.translation.nights})</span>
                            </div>
                            <div>
                                <span className="currencyCode">{props.request.cur} </span>
                                {props.request!==undefined && props.request.rooms.length > 0 &&
                                    <span className="currencyAmount"><FormatCurrency value={props.request.total_price} /></span>
                                }
                            </div>
                            </div>
                        </Col>
                        </Row>
                </div>
            </div> */}


            <div className='outerSummary'>
                <div className='bSumWrap'>
                    <Title level={4} className='summaryMainHeading'>
                        {context.translation && context.translation.bokSumy}
                        <img src={BrandIcon} alt="brandIcon" className="hotelBrandIcon"></img>
                    </Title>
                </div>
                <div className="summaryShape"></div>
                <div className="innerSummary">
                    <div className='onlyFlex hotelInfoWrap'>
                        <div className='paymentSummaryImage'>
                            <img src={props.request.htldtls.htimg} alt='hotel-image' />
                        </div>
                        <div className='hotelInfo'>
                            <div className='innerSumTitle'>{props.request.htldtls.htnm}</div>
                            <div className="hotelRating">
                                {Utilities.getHotelStartRating(props.request.htldtls.rating).map((star, idx) => (
                                <React.Fragment key={idx}>
                                <span>
                                    <i className="icon icon-star"></i>
                                </span>
                                </React.Fragment>
                                ))}
                            </div>
                            <div className='innerSumContent'>{props.request.htldtls?.add !== undefined && props.request.htldtls?.add !== "" && props.request.htldtls?.add},&nbsp;
                            {props.request.htldtls?.city !== undefined && props.request.htldtls?.city !== "" && props.request.htldtls?.city},&nbsp;
                            {props.request.htldtls?.country !== undefined && props.request.htldtls?.country !== "" && props.request.htldtls?.country}</div>
                        </div>
                    </div>
                    <div className='commonFlex'>
                        <div className='checkInWrap'>
                            <div className='innerSumTitle'>{context.translation && context.translation.checkin}</div>
                            <div className='innerSumContent'>{props.request.htldtls.cin}</div>
                        </div>
                        <div className='checkInWrap'>
                            <div className='innerSumTitle'>{context.translation && context.translation.checkOut}</div>
                            <div className='innerSumContent'>{props.request.htldtls.cout}</div>
                        </div>
                    </div>
                    <div className="innerSumDivider"></div>
                    {/* {props.request && props.request.roomsInfo.map((room, index) => (
                    <>
                    <div className="commonFlex">
                        <div className="innerSumTitle">
                            {context.translation && context.translation.room} {room.rmindex}
                        </div>
                        <div className='guestNum'>
                            {room.adt} {context.translation && context.translation.adults} & {room.chd} {context.translation && context.translation.chis}
                        </div>
                    </div>
                    
                    </>
                    ))} */}

                    {props.request && props.request.roomsInfo.map((room, index) => (
                    <React.Fragment key={index}>
                    <div className='paxDetailWrap'>
                        <div className="fareWrap">
                            <div className={" dFlex " + (index===0 && '') } >
                                <div className="rightArrow" onClick={handleClick}>
                                    <span className="pax">
                                    {context.translation && context.translation.room} {room.rmindex}
                                    </span>
                                </div>
                                <div className='currencyWrap'>
                                    <span className='adultChildList'>
                                    {room.adt} {context.translation && context.translation.adults} {room.chd !== 0 ? (<> & {room.chd} {context.translation && context.translation.chis}</>) : ""} 
                                    </span>
                                </div>
                            </div>
                            <div className="fareDetailWrap">
                                    <span className="paxDetailList addMoreList">
                                    {room.pax.map((pax, index) => (
                                    <span className='paxDetailListItem' key={index}>
                                        {pax.fn} {pax.ln} ({pax.ty})
                                    </span>
                                    ))}
                                    </span>
                            </div>
                        </div>
                    </div>
                    <div className="innerSumDivider"></div>
                    </React.Fragment>
                    ))}

                    <div className="commonFlex payablePriceWrapper">
                        <div>
                            <span className="payablePrice">
                            {context.translation && context.translation.totPayPrice}
                            </span>
                        </div>
                        <div>
                            <span className="currencyCode">{props.request.cur} </span>
                            {props.request!==undefined && props.request.rooms.length > 0 &&
                                <span className="currencyAmount"><FormatCurrency value={props.request.total_price * props.request.base_usr_exrate} /></span>
                            }
                        </div>
                    </div>
                    <div className="allNights">({props.request.nonights} {context.translation && context.translation.nights})</div>
                    <div>
                        {props.request.priceNote!==undefined && props.request.priceNote!=="" &&
                            // <span className="priceNote"><span>Note :</span> {roundUpPriceNote(props.request.priceNote)} </span>
                            <span className="priceNote"><span>Note :</span> {props.request.priceNote} </span>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default HotelPaymentInfo;