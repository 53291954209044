import {
  Button,
  Checkbox,
  Collapse,
  Form,
  Input,
  Row,
  Slider,
  Typography,
  Affix,
} from "antd";
import Link from "antd/es/typography/Link";
import _ from "lodash";
import React, { useEffect, useReducer, useState,useContext } from "react";
import { Context } from "../../../../App";
import { isArrayNotEmpty, isNotNull } from "../../../utilities/validators";
import FormatCurrency from "../../../utilities/FormatCurrency";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';

const { Title } = Typography;
const { Panel } = Collapse;

const initialState = {
  searchresponse: "",
  price: { min: 0, max: 0 },
  expedia: { min: 0, max: 0 },
  rating: [],
  accomadation: [],
  amenities: [],
  localResponse: {},
  finalResponse: {},
  searchhtlname: [],
  hotelnameslist: "",
  hotelFilteredNamesList: "",
  boardbasis: [],
  area: [],
  landmark: [],
  arealist: "",
  areaFilteredList: "",
  searchareaname: [],
  htlname: {},
  arename: [],
  landmarklist: "",
  landmarkFilteredList: "",
  searchlandmarkname: [],
  landmarkname: [],
  strratdisplay: [],
  accomdisplay: [],
  pricedisplay: { min: 0, max: 0 },
  expediadisplay: { min: 0, max: 0 },
  amenitiesdisplay: [],
  boradbasisdisplay: [],
  isbordbasisfilter: false,
  ispricefilter: false,
  isstartratfilter: false,
  isaccomfilter: false,
  isexpratfilter: false,
  isareafilter: false,
  islandmarkfilter: false,
  isamenitifilter: false,
  showAreaList: true,
  locationName: ""
};
let accmsno = [];
let amenitesno = [];
let brdbasesno = [];
let areasno = [];
let areanamesno = [];
let landmarksno = [];
let lmarknamesno = [];

// Reducer function for userReact Hook
const reducer = (state, action) => {
  switch (action.type) {
    case "clear":
      return {
        ...state,
        rating: [],
        accomadation: [],
        amenities: [],
        boardbasis: [],
        area: [],
        landmark: [],
        arename: [],
        landmarkname: [],
        searchhtlname: [],
      };
    default:
      return { ...state, [action.type]: action.payload };
  }
};


function HotelFilters(props) {
  const [context, setContext] = useContext(Context)
  const { hotelresponse, filterresponse, response, base_usr_exrate, cur } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const [showFull, setShowfull] = useState(false);
  const [showFullamt, setShowFullamt] = useState(false);
  const [showFullBds, setShowFullBds] = useState(false);
  const [showArea, setShowArea] = useState(false);
  const [showLandmarks, setShowLandmarks] = useState(false);
  const [hotelName, setHotelName] = useState(null);
  const [prcMin, setPrcMin] = useState(0);
  const [prcMax, setPrcMax] = useState(0);
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(0);
  const [rvwMin, setRvwMin] = useState(0);
  const [rvwMax, setRvwMax] = useState(0);
  const [minReview, setMinReview] = useState(0);
  const [maxReview, setMaxReview] = useState(0);
  const [areaName, setAreaName] = useState(null);
  const [landmarkName, setLandmarkName] = useState(null);
  const [hotelNameSelected, setHotelNameSelected] = useState(false);
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [beforeLocationFilterData, setBeforeLocationFilterData] = useState([]);
  const [top, setTop] = React.useState(100);
  const [sessionCur, setSessionCur] = useState("");

  useEffect(() => {
    setSessionCur(context.userCur!==undefined && context.userCur!==""?context.userCur:sessionStorage.getItem("SEL_CUR"));
  }, [sessionStorage.getItem("SEL_CUR") || context.userCur])

  useEffect(() => {
    if (isNotNull(hotelresponse)) {
      const searcresponse = hotelresponse;
      dispatch({
        type: "finalResponse",
        payload: Object.assign({}, searcresponse),
      });
      dispatch({ type: "localResponse", payload: searcresponse });
      dispatch({
        type: "hotelnameslist",
        payload: searcresponse.filters.hotelname,
      });
      if (searcresponse.filters.area == undefined) {
        dispatch({ type: "showAreaList", payload: false });
      }
      dispatch({
        type: "arealist",
        payload: _.orderBy(searcresponse.filters.area, ["count"], ["desc"]),
      });
      dispatch({
        type: "landmarklist",
        payload: _.orderBy(searcresponse.filters.landmark, ["count"], ["desc"]),
      });
      dispatch({
        type: "price",
        payload: {
          min: hotelresponse.filters.price.minprc,
          max: hotelresponse.filters.price.maxprc,
        },
      });
      dispatch({
        type: "pricedisplay",
        payload: {
          min: hotelresponse.filters.price.minprc,
          max: hotelresponse.filters.price.maxprc,
        },
      });
      dispatch({
        type: "expedia",
        payload: {
          min: hotelresponse.filters.exprat.minprc,
          max: hotelresponse.filters.exprat.maxprc,
        },
      });
      dispatch({
        type: "expediadisplay",
        payload: {
          min: hotelresponse.filters.exprat.minprc,
          max: hotelresponse.filters.exprat.maxprc,
        },
      });
      setRvwMin(hotelresponse.filters.exprat.minprc);
      setRvwMax(hotelresponse.filters.exprat.maxprc);
      setPrcMin(hotelresponse.filters.price.minprc);
      setPrcMax(hotelresponse.filters.price.maxprc);
      dispatch({
        type: "strratdisplay",
        payload: hotelresponse.filters.strrat,
      });
      let accom = _.orderBy(hotelresponse.filters.accom, ["count"], ["desc"]);
      dispatch({ type: "accomdisplay", payload: accom });
      let boradbasis = _.orderBy(
        hotelresponse.filters.boardbasis,
        ["count"],
        ["desc"]
      );
      dispatch({ type: "boradbasisdisplay", payload: boradbasis });
      let ameni = _.orderBy(
        hotelresponse.filters.amenities,
        ["count"],
        ["desc"]
      );
      dispatch({ type: "amenitiesdisplay", payload: ameni });
      dispatch({ type: "clear", payload: true });
      accmsno = [];
      amenitesno = [];
      brdbasesno = [];
      areasno = [];
      areanamesno = [];
      landmarksno = [];
      lmarknamesno = [];

      // location filter 
      hotelresponse.prpts = hotelresponse.prpts.sort((a,b) => {
        return a.cont.nearby - b.cont.nearby;
      })
    }
  }, [hotelresponse]);

  useEffect(() => {
    if (state.arealist) {
      dispatch({ type: "areaFilteredList", payload: state.arealist });
    }
  }, [state.arealist]);

  useEffect(() => {
    if (state.landmarklist) {
      dispatch({ type: "landmarkFilteredList", payload: state.landmarklist });
    }
  }, [state.landmarklist]);

  useEffect(() => {
    if (prcMin) setMinPrice(prcMin);
    if (prcMax) setMaxPrice(prcMax);
  }, [prcMin, prcMax]);

  useEffect(() => {
    if (rvwMin) setMinReview(rvwMin);
    if (rvwMax) setMaxReview(rvwMax);
  }, [rvwMin, rvwMax]);

  /**
   *
   * @param {*} value
   * @description:priceChange Filter
   * @author: Srikanth Bandaru
   */
  const priceChange = (value) => {
    dispatch({ type: "price", payload: value });
  };

  const expediaChange = (value) => {
    dispatch({ type: "expedia", payload: value });
  };

  /**
   * @param {*} e
   * @description:Amenities Filter
   * @author: Srikanth Bandaru
   */
  const handleBoardBasis = (data) => (e) => {
    dispatch({ type: "isbordbasisfilter", payload: true });
    let boardbs = _.cloneDeep(state.finalResponse.filters.boardbasis);

    //if checked
    if (e.target.checked) {
      state.boardbasis.push(e.target.value);
      brdbasesno = brdbasesno.concat(data.sno);
    } //if unchecked
    else {
      for (var i = 0; i < state.boardbasis.length; i++) {
        if (state.boardbasis[i] === e.target.value) {
          state.boardbasis.splice(i, 1);
          i--;
        }
      }
      brdbasesno = [];
      state.boardbasis.map((obj) => {
        let brdbasee = boardbs.filter((lm) => lm.name == obj);
        brdbasesno = brdbasesno.concat(brdbasee[0].sno);
      });
    }
    applyFilter();
    if (isArrayNotEmpty(state.boardbasis)) {
      for (let brd in state.boardbasis) {
        let val = state.boardbasis[brd];
        if (e.target.checked) {
          for (let obj of boardbs) {
            if (obj.name.toUpperCase() === val.toUpperCase()) {
              obj.ischeck = true;
            }
          }
        } else {
          boardbs.map((obj) => {
            if (
              obj.name.toUpperCase() !== val.toUpperCase() &&
              (!isNotNull(obj.isselected) || !obj.isselected)
            ) {
              obj.ischeck = false;
            } else {
              obj.ischeck = true;
              obj.isselected = true; //This is dummy value for skip the existing selected value
            }
          });
        }
      }
    } else {
      boardbs.map((obj) => {
        obj.ischeck = false;
      });
    }

    //after condition is rendered isseleted made it as false
    boardbs.map((obj) => {
      obj.isselected = false;
    });
    boardbs = _.orderBy(boardbs, ["count"], ["desc"]);
    dispatch({ type: "boradbasisdisplay", payload: boardbs });
  };

  const filterListByEnteredText = (data) => {
    if (data.target.value) {
      setHotelName(data.target.value);
      var list = state.hotelnameslist.filter((htlName) =>
        htlName.hname.toUpperCase().includes(data.target.value.toUpperCase())
      );
      if (list.length > 0) {
        dispatch({ type: "hotelFilteredNamesList", payload: list });
      } else {
        dispatch({ type: "hotelFilteredNamesList", payload: [] });
      }
    } else {
      setHotelName(null);
    }
    if(!data.target.value) {
      allFiltersClear();
    }
  };

  const filterAreaListByEnteredText = (data) => {
    if (data.target.value) {
      setAreaName(data.target.value);
      var list = state.arealist.filter((area) =>
        area.name.toUpperCase().includes(data.target.value.toUpperCase())
      );
      if (list.length > 0) {
        dispatch({ type: "areaFilteredList", payload: list });
      } else {
        dispatch({ type: "areaFilteredList", payload: [] });
      }
    } else {
      setAreaName(null);
    }
  };

  const filterLandmarkListByEnteredText = (data) => {
    if (data.target.value) {
      setLandmarkName(data.target.value);
      var list = state.landmarklist.filter((landmark) =>
        landmark.name.toUpperCase().includes(data.target.value.toUpperCase())
      );
      if (list.length > 0) {
        dispatch({ type: "landmarkFilteredList", payload: list });
      } else {
        dispatch({ type: "landmarkFilteredList", payload: [] });
      }
    } else {
      setLandmarkName(null);
    }
  };

  const allFiltersClear = async () => {
    dispatch({type : "locationName", payload: ""})
    setHotelName(null);
    let filterres = state.finalResponse.prpts;
    //price filter clear
    dispatch({
      type: "price",
      payload: {
        min: state.finalResponse.filters.price.minprc,
        max: state.finalResponse.filters.price.maxprc,
      },
    });
    let value = {
      min: state.finalResponse.filters.price.minprc,
      max: state.finalResponse.filters.price.maxprc,
    };
    dispatch({ type: "ispricefilter", payload: false });
    //clear price
    setPrcMin(state.finalResponse.filters.price.minprc);
    setMinPrice(state.finalResponse.filters.price.minprc);
    state.finalResponse.filters.price.maxprc
      ? setPrcMax(state.finalResponse.filters.price.maxprc)
      : setPrcMax(state.finalResponse.filters.price.minprc);
    state.finalResponse.filters.price.maxprc
      ? setMaxPrice(state.finalResponse.filters.price.maxprc)
      : setMaxPrice(state.finalResponse.filters.price.minprc);

    //star rating clear
    let strrating = isNotNull(state.finalResponse.filters.strrat)
      ? _.cloneDeep(state.finalResponse.filters.strrat)
      : [];
    strrating.map((obj) => {
      obj.ischeck = false;
    });
    dispatch({ type: "rating", payload: [] });
    strrating = _.orderBy(strrating, ["count"], ["desc"]);
    dispatch({ type: "strratdisplay", payload: strrating });

    //accomadation clear
    let accomm = isNotNull(state.finalResponse.filters.accom)
      ? _.cloneDeep(state.finalResponse.filters.accom)
      : [];
    accmsno = [];
    accomm.map((obj) => {
      obj.isaccom = false;
    });
    dispatch({ type: "accomadation", payload: [] });
    accomm = _.orderBy(accomm, ["count"], ["desc"]);
    dispatch({ type: "accomdisplay", payload: accomm });

    //boardbasis clear
    let brdbasis = isNotNull(state.finalResponse.filters.boardbasis)
      ? _.cloneDeep(state.finalResponse.filters.boardbasis)
      : [];
    brdbasesno = [];
    brdbasis.map((obj) => {
      obj.ischeck = false;
    });
    brdbasis = _.orderBy(brdbasis, ["count"], ["desc"]);
    dispatch({ type: "boradbasisdisplay", payload: brdbasis });
    dispatch({ type: "boardbasis", payload: [] });

    //expedia rating clear
    dispatch({ type: "isexpratfilter", payload: false });
    dispatch({
      type: "expedia",
      payload: {
        min: state.finalResponse.filters.exprat.minprc,
        max: state.finalResponse.filters.exprat.maxprc,
      },
    });
    setRvwMin(state.finalResponse.filters.exprat.minprc);
    setRvwMax(state.finalResponse.filters.exprat.maxprc);
    setMinReview(state.finalResponse.filters.exprat.minprc);
    setMaxReview(state.finalResponse.filters.exprat.maxprc);
    //area filter clear
    let are = isNotNull(state.finalResponse.filters.area)
      ? _.cloneDeep(state.finalResponse.filters.area)
      : [];
    areasno = [];
    are.map((obj) => {
      obj.ischeck = false;
    });
    are = _.orderBy(are, ["count"], ["desc"]);
    dispatch({ type: "arealist", payload: are });
    dispatch({ type: "arename", payload: [] });
    dispatch({ type: "area", payload: [] });

    //landmark filter clear
    let land = isNotNull(state.finalResponse.filters.landmark)
      ? _.cloneDeep(state.finalResponse.filters.landmark)
      : [];
    landmarksno = [];
    lmarknamesno = [];
    land.map((obj) => {
      obj.ischeck = false;
    });
    land = _.orderBy(land, ["count"], ["desc"]);
    dispatch({ type: "landmarklist", payload: land });
    dispatch({ type: "landmark", payload: [] });
    dispatch({ type: "landmarkname", payload: [] });

    //amenities clear
    let ameni = isNotNull(state.finalResponse.filters.amenities)
      ? _.cloneDeep(state.finalResponse.filters.amenities)
      : [];
    amenitesno = [];
    ameni.map((obj) => {
      obj.ischeck = false;
    });
    ameni = _.orderBy(ameni, ["count"], ["desc"]);
    dispatch({ type: "amenitiesdisplay", payload: ameni });
    dispatch({ type: "amenities", payload: [] });

    //Hotel search filter
    dispatch({ type: "searchhtlname", payload: [] });
    dispatch({ type: "htlname", payload: [] });

    //contruct hotelname filters
    let htlList = [];
    filterres.map((htl) => {
      let obj = { pid: context.selectLang == undefined || context.selectLang === "en" ? htl.cont.pid : htl.cont_ar.pid, hname: context.selectLang == undefined || context.selectLang === "en" ? htl.cont.name : htl.cont_ar.name };
      htlList.push(obj);
    });
    dispatch({ type: "hotelnameslist", payload: htlList });
    state.localResponse.filters.hotelname = htlList;

    //Initial sorting for Result filter (only when we click on all filter clear)
    //let category_arr = ["HOTELHD", "HOTELNHD", "HOTDEAL", "NOHOTDEAL"];//Order of Results
    //filterres.sort((a, b) => (category_arr.indexOf(a.hdtype) - category_arr.indexOf(b.hdtype)));

    let list1 = filterres.filter((obj) => obj.hdtype == "HOTELHD");
    let list2 = filterres.filter((obj) => obj.hdtype == "HOTELNHD");
    let list3 = filterres.filter((obj) => obj.hdtype == "HOTDEAL");
    let list4 = filterres.filter((obj) => obj.hdtype == "NOHOTDEAL");
    list1.sort(function (htl1, htl2) {
      return (
        isNotNull(htl1.disc) &&
        isNotNull(htl2.disc) &&
        Number(htl2.disc) - Number(htl1.disc)
      );
    });
    list2.sort(function (htl1, htl2) {
      return (
        isNotNull(htl1.rtf) &&
        isNotNull(htl2.rtf) &&
        Number(htl2.rtf) - Number(htl1.rtf)
      );
    });
    list3.sort(function (htl1, htl2) {
      return (
        isNotNull(htl1.disc) &&
        isNotNull(htl2.disc) &&
        Number(htl2.disc) - Number(htl1.disc)
      );
    });
    list4.sort(function (htl1, htl2) {
      return (
        isNotNull(htl1.rtf) &&
        isNotNull(htl2.rtf) &&
        Number(htl2.rtf) - Number(htl1.rtf)
      );
    });

    list1 = _.concat(list1, list2);
    list1 = _.concat(list1, list3);
    list1 = _.concat(list1, list4);
    filterres = list1;

    let finalRes = []
    if(beforeLocationFilterData?.prpts?.length > 0) {
      await Promise.all(
        filterres = filterres.map((item, idx) => {
          item.cont.nearby = beforeLocationFilterData.prpts[idx].cont.nearby;
          item.cont.nearbyadd = beforeLocationFilterData.prpts[idx].cont.nearbyadd !== undefined ? beforeLocationFilterData.prpts[idx].cont.nearbyadd : undefined;
          item.cont.nearbykm = beforeLocationFilterData.prpts[idx].cont.nearbykm !== undefined ? beforeLocationFilterData.prpts[idx].cont.nearbykm : undefined;;
          return finalRes.push(item);
        })
      )
    }

    state.localResponse.filters.boardbasis = brdbasis;
    state.localResponse.filters.strrat = strrating;
    state.localResponse.filters.accom = accomm;
    state.localResponse.filters.area = are;
    state.localResponse.filters.landmark = land;
    state.localResponse.filters.amenities = ameni;
    state.localResponse.prpts = finalRes.length !== 0 ? finalRes : filterres;
    setHotelName();
    setAreaName();
    setLandmarkName();
    props.filterresponse(state.localResponse, "", "Filter", "allclear");
  };

  /**
   * @param {*} data
   * @description:Search by hotel name selected event
   * @author: Srikanth Bandaru
   */
  const handleHotelName = (data) => {
    dispatch({ type: "searchhtlname", payload: data });
    dispatch({ type: "htlname", payload: data });
    setHotelName(data?data.hname:'');
    setHotelNameSelected(true);
  };

  const handlePriceChange = (value) => {
    if (!(minPrice === value[0] && maxPrice === value[1])) {
      setMinPrice(value[0]);
      setMaxPrice(value[1]);
    }
    var prc = { min: value[0], max: value[1] };
    dispatch({ type: "price", payload: prc });
    dispatch({ type: "ispricefilter", payload: true });
  };

  /**
   *
   * @param {*} value
   * @description:Expedia Filter
   * @author: Srikanth Bandaru
   */
  const handleReviews = (value) => {
    if (!(minReview === value[0] && maxReview === value[1])) {
      setMinReview(value[0]);
      setMaxReview(value[1]);
    }
    var rev = { min: value[0], max: value[1] };
    dispatch({ type: "expedia", payload: rev });
    dispatch({ type: "isexpratfilter", payload: true });
  };

  /**
   * @param {*} e
   * @description:Star Rating Filter
   * @author: Srikanth Bandaru
   */
  const handleStarRating = (data) => (e) => {
    dispatch({ type: "isstartratfilter", payload: true });
    let strrating = _.cloneDeep(state.finalResponse.filters.strrat);

    //if checked
    if (e.target.checked) {
      state.rating.push(e.target.value);
    } //if unchecked
    else {
      for (var i = 0; i < state.rating.length; i++) {
        if (state.rating[i] === e.target.value) {
          state.rating.splice(i, 1);
          i--;
        }
      }
    }
    applyFilter();
    //Check box displaying
    if (isArrayNotEmpty(state.rating)) {
      for (let acc in state.rating) {
        let val = state.rating[acc];
        if (e.target.checked) {
          for (let obj of strrating) {
            if (obj.rat == val) {
              obj.ischeck = true;
            }
          }
        } else {
          strrating.map((obj) => {
            if (
              obj.rat != val &&
              (!isNotNull(obj.isselected) || !obj.isselected)
            ) {
              obj.ischeck = false;
            } else {
              obj.ischeck = true;
              obj.isselected = true; //This is dummy value for skip the existing selected value
            }
          });
        }
      }
    } else {
      strrating.map((obj) => {
        obj.ischeck = false;
      });
    }

    //after condition is rendered isseleted made it as false
    strrating.map((obj) => {
      obj.isselected = false;
    });
    strrating = _.orderBy(strrating, ["count"], ["desc"]);
    dispatch({ type: "strratdisplay", payload: strrating });
  };

  /**
   * @param {*} e
   * @description:Accomadation Filter
   * @author: Srikanth Bandaru
   */
  const handleAccommodationChange = (data) => (e) => {
    dispatch({ type: "isaccomfilter", payload: true });
    let accomm = _.cloneDeep(state.finalResponse.filters.accom);

    //if checked
    if (e.target.checked) {
      state.accomadation.push(e.target.value);
      accmsno = accmsno.concat(data.sno);
    } //if unchecked
    else {
      for (var i = 0; i < state.accomadation.length; i++) {
        if (state.accomadation[i] === e.target.value) {
          state.accomadation.splice(i, 1);
          i--;
          let removelist = _.remove(accmsno, function (obj) {
            return data.sno.includes(obj);
          });
        }
      }
    }
    applyFilter();
    if (isArrayNotEmpty(state.accomadation)) {
      for (let acc in state.accomadation) {
        let val = state.accomadation[acc];
        if (e.target.checked) {
          for (let obj of accomm) {
            if (obj.type.toUpperCase() === val.toUpperCase()) {
              obj.isaccom = true;
            }
          }
        } else {
          accomm.map((obj) => {
            if (
              obj.type.toUpperCase() !== val.toUpperCase() &&
              (!isNotNull(obj.isselected) || !obj.isselected)
            ) {
              obj.isaccom = false;
            } else {
              obj.isaccom = true;
              obj.isselected = true; //This is dummy value for skip the existing selected value
            }
          });
        }
      }
    } else {
      //after condition is rendered isseleted made it as false
      accomm.map((obj) => {
        obj.isaccom = false;
      });
    }
    //after condition is rendered isseleted made it as false
    accomm.map((obj) => {
      obj.isselected = false;
    });
    accomm = _.orderBy(accomm, ["count"], ["desc"]);
    dispatch({ type: "accomdisplay", payload: accomm });
  };

  /**
   * @param {*} e
   * @description:Area Filter
   * @author: Srikanth Bandaru
   */
  const handleAreaChange = (data) => (e) => {
    dispatch({ type: "isareafilter", payload: true });
    dispatch({ type: "arename", payload: [] });
    let area = _.cloneDeep(state.finalResponse.filters.area);

    //if checked
    if (e.target.checked) {
      state.area.push(e.target.value);
      areasno = areasno.concat(data.sno);
    } //if unchecked
    else {
      for (var i = 0; i < state.area.length; i++) {
        if (state.area[i] === e.target.value) {
          state.area.splice(i, 1);
          i--;
        }
      }
      areasno = [];
      state.area.map((obj) => {
        let aremark = area.filter((lm) => lm.name == obj);
        areasno = areasno.concat(aremark[0].sno);
      });
    }
    applyFilter();
    if (isArrayNotEmpty(state.area)) {
      for (let acc in state.area) {
        let val = state.area[acc];
        if (e.target.checked) {
          area.map((obj) => {
            if (obj.name.toUpperCase() === val.toUpperCase()) {
              obj.ischeck = true;
            }
          });
        } else {
          area.map((obj) => {
            if (
              obj.name.toUpperCase() !== val.toUpperCase() &&
              (!isNotNull(obj.isselected) || !obj.isselected)
            ) {
              obj.ischeck = false;
            } else {
              obj.ischeck = true;
              obj.isselected = true; //This is dummy value for skip the existing selected value
            }
          });
        }
      }
    } else {
      area.map((obj) => {
        obj.ischeck = false;
      });
    }
    //after condition is rendered isseleted made it as false
    area.map((obj) => {
      obj.isselected = false;
    });
    area = _.orderBy(area, ["count"], ["desc"]);
    dispatch({ type: "arealist", payload: area });
  };

  /**
   * @param {*} e
   * @description:Landmark check box Filter
   * @author: Srikanth Bandaru
   */
  const handleLandmarkChange = (data) => (e) => {
    dispatch({ type: "islandmarkfilter", payload: true });
    dispatch({ type: "landmarkname", payload: [] });
    let landmrk = _.cloneDeep(state.finalResponse.filters.landmark);

    //if checked
    if (e.target.checked) {
      state.landmark.push(e.target.value);
      landmarksno = landmarksno.concat(data.sno);
    } //if unchecked
    else {
      for (var i = 0; i < state.landmark.length; i++) {
        if (state.landmark[i] === e.target.value) {
          state.landmark.splice(i, 1);
          i--;
        }
      }
      landmarksno = [];
      state.landmark.map((obj) => {
        let lmark = landmrk.filter((lm) => lm.name == obj);
        landmarksno = landmarksno.concat(lmark[0].sno);
      });
    }
    applyFilter();
    if (isArrayNotEmpty(state.landmark)) {
      for (let lnd in state.landmark) {
        let val = state.landmark[lnd];
        if (e.target.checked) {
          landmrk.map((obj) => {
            if (obj.name.toUpperCase() == val.toUpperCase()) {
              obj.ischeck = true;
            }
          });
        } else {
          landmrk.map((obj) => {
            if (
              obj.name.toUpperCase() !== val.toUpperCase() &&
              (!isNotNull(obj.isselected) || !obj.isselected)
            ) {
              obj.ischeck = false;
            } else {
              obj.ischeck = true;
              obj.isselected = true; //This is dummy value for skip the existing selected value
            }
          });
        }
      }
    } else {
      landmrk.map((obj) => {
        obj.ischeck = false;
      });
    }
    //after condition is rendered isseleted made it as false
    landmrk.map((obj) => {
      obj.isselected = false;
    });
    landmrk = _.orderBy(landmrk, ["count"], ["desc"]);
    dispatch({ type: "landmarklist", payload: landmrk });
  };

  /**
   * @param {*} e
   * @description:Amenities Filter
   * @author: Srikanth Bandaru
   */
  const handleAmenities = (data) => (e) => {
    dispatch({ type: "isamenitifilter", payload: true });
    let ameniti = _.cloneDeep(state.finalResponse.filters.amenities);

    //if checked
    if (e.target.checked) {
      state.amenities.push(e.target.value);
      amenitesno = amenitesno.concat(data.sno);
    } //if unchecked
    else {
      for (var i = 0; i < state.amenities.length; i++) {
        if (state.amenities[i] === e.target.value) {
          state.amenities.splice(i, 1);
          i--;
        }
      }
      amenitesno = [];
      state.amenities.map((obj) => {
        let ameee = ameniti.filter((lm) => lm.name == obj);
        amenitesno = amenitesno.concat(ameee[0].sno);
      });
    }
    applyFilter();
    if (isArrayNotEmpty(state.amenities)) {
      for (let ame in state.amenities) {
        let val = state.amenities[ame];
        if (e.target.checked) {
          for (let obj of ameniti) {
            if (obj.name.toUpperCase() === val.toUpperCase()) {
              obj.ischeck = true;
            }
          }
        } else {
          ameniti.map((obj) => {
            if (
              obj.name.toUpperCase() !== val.toUpperCase() &&
              (!isNotNull(obj.isselected) || !obj.isselected)
            ) {
              obj.ischeck = false;
            } else {
              obj.ischeck = true;
              obj.isselected = true; //This is dummy value for skip the existing selected value
            }
          });
        }
      }
    } else {
      ameniti.map((obj) => {
        obj.ischeck = false;
      });
    }

    //after condition is rendered isseleted made it as false
    ameniti.map((obj) => {
      obj.isselected = false;
    });
    ameniti = _.orderBy(ameniti, ["count"], ["desc"]);
    dispatch({ type: "amenitiesdisplay", payload: ameniti });
  };

  useEffect(() => {
    if (state.finalResponse) {
      if (
        state.htlname ||
        state.price ||
        state.expedia ||
        state.rating ||
        state.boardbasis ||
        state.accomadation ||
        state.area ||
        state.landmark
      ) {
        applyFilter();
      }
    }
  }, [
    state.htlname,
    state.price,
    state.expedia,
    state.rating,
    state.boardbasis,
    state.accomadation,
    state.area,
    state.landmark,
  ]);

  const handleKeyDown = (e) => {
    if (e.key === "Delete") {
      handleHotelName(null);
    } else {
      setHotelNameSelected(false);
    }
  };

  const formatCurrencyForSlider = (value) => {
    return <FormatCurrency value={value * base_usr_exrate}/>;
  };

  const applyFilter = () => {
    let filterres = state.finalResponse.prpts;
    if (filterres) {
      //Hotel search filter
      if (state.htlname !== null && Object.keys(state.htlname).length !== 0) {
        let phid = state.htlname.pid;
        filterres = filterres.filter((htl) => htl.pid === phid);
      }

      //contruct hotelname filters
      let htlList = [];
      filterres.map((htl) => {
        let obj = { pid: context.selectLang == undefined || context.selectLang === "en" ? htl.cont.pid : htl.cont_ar.pid, hname: context.selectLang == undefined || context.selectLang === "en" ? htl.cont.name : htl.cont_ar.name };
        htlList.push(obj);
      });
      dispatch({ type: "hotelnameslist", payload: htlList });
      state.localResponse.filters.hotelname = htlList;

      //Price Filter
      if (
        state.price &&
        state.price.min &&
        state.price.min !== 0 &&
        state.price.max &&
        state.price.max !== 0
      ) {
        filterres = filterres.filter((htl) => {
          return (
            Math.round(htl.rtf) >= state.price.min &&
            Math.round(htl.rtf) <= state.price.max
          );
        });
      }

      //expedia rating
      if (
        state.expedia &&
        state.expedia[0] !== 0 &&
        state.expedia[1] !== 0 &&
        state.expedia.min &&
        state.expedia.min !== 0 &&
        state.expedia.max &&
        state.expedia.max !== 0
      ) {
        filterres = filterres.filter((htl) => {
          return (
            (state.expedia.min !== state.expedia.max)?
           (Number(htl.cont.urat) >= state.expedia.min &&
           Number(htl.cont.urat) <= state.expedia.max)
           : Math.ceil(Number(htl.cont.urat)) <= state.expedia.max
          );
        });
      }

      //star rating
      if (isArrayNotEmpty(state.rating)) {
        filterres = filterres.filter((htl) => {
          return (
            htl.cont &&
            state.rating &&
            state.rating.includes(Math.ceil(htl.cont.ratings))
          );
        });
      }

      //boardbasis
      if (isArrayNotEmpty(state.boardbasis)) {
        filterres = filterres.filter((htl) => {
          return htl.sno && brdbasesno.includes(htl.sno);
        });
      }

      if (isArrayNotEmpty(state.accomadation)) {
        filterres = filterres.filter((htl) => {
          return htl.sno && accmsno.includes(htl.sno);
        });
      }

      //area
      if (isArrayNotEmpty(state.area)) {
        filterres = filterres.filter((htl) => {
          return htl.sno && areasno.includes(htl.sno);
        });
      }

      //landmark
      if (isArrayNotEmpty(state.landmark)) {
        filterres = filterres.filter((htl) => {
          return htl.sno && landmarksno.includes(htl.sno);
        });
      }

      //amenities
      if (isArrayNotEmpty(state.amenities)) {
        filterres = filterres.filter((htl) => {
          return htl.sno && amenitesno.includes(htl.sno);
        });
      }
    }
    state.localResponse.prpts = filterres;
    props.filterresponse(state.localResponse, "", "Filter");
  };

  
  const locationNameChange = address => {
    setLatitude("");
    setLongitude("");
    dispatch({type: "locationName", payload: address});
    if(!address) {
      allFiltersClear();
    }
  };

  // useEffect(() => {
  //   getSortByDistance()
  // }, [state.locationName])

  const handleLocationSelect = address => {
    let lat, lng;
    dispatch({type: "locationName", payload: address});
    geocodeByAddress(address)
      .then(results => getLatLng(results[0]))
      .then(latLng => {
        setLatitude(latLng.lat);
        setLongitude(latLng.lng);
        lat = latLng.lat;
        lng = latLng.lng;
        getSortByDistance(hotelresponse, lat, lng, address);
      }
      )
      .catch(error => console.error('Error', error));
  };

  const getSortByDistance = async (hotelresponse, lat, lng, address) => {
    const properties = _.cloneDeep(response) ;
    setBeforeLocationFilterData(properties)
    let finalRes = [];
    if(hotelresponse !== undefined) {
      await Promise.all(
        hotelresponse?.prpts.map(async (obj) => {
          let latitude2 = obj.cont.location.coordinates.latitude;
          let longitude2 = obj.cont.location.coordinates.longitude;
          let distance = await distanceCalculation(lat, lng, latitude2, longitude2);
          obj.cont.nearbyadd = address + " - ";
          obj.cont.nearbykm = " Km";
          obj.cont.nearby = +distance;
          return finalRes.push(obj);
        })
      );
  
      filterLocationDistance(hotelresponse, finalRes);
    }
  }

  const filterLocationDistance = (hotelresponse, finalRes) => {
    const sortLowHighDistance = finalRes.sort((a,b) => {
      return a.cont.nearby - b.cont.nearby;
    })
    dispatch({ type: "localResponse", payload: hotelresponse });
    state.localResponse.prpts = sortLowHighDistance
    props.filterresponse(state.localResponse, "", "Filter");
  }

  const distanceCalculation = async (latitude1, longitude1, latitude2, longitude2) => {
    let payload = {
      lat1: latitude1,
      lat2: latitude2,
      lon1: longitude1,
      lon2: longitude2,
    };
    let lon1 = (payload.lon1 * Math.PI) / 180;
    let lon2 = (payload.lon2 * Math.PI) / 180;
    let lat1 = (payload.lat1 * Math.PI) / 180;
    let lat2 = (payload.lat2 * Math.PI) / 180;
    // Haversine formula
    let dlon = lon2 - lon1;
    let dlat = lat2 - lat1;
    let a =
      Math.pow(Math.sin(dlat / 2), 2) +
      Math.cos(lat1) * Math.cos(lat2) * Math.pow(Math.sin(dlon / 2), 2);
    let c = 2 * Math.asin(Math.sqrt(a));
    // Radius of earth in kilometers 6371,  for miles 3956
    let r = 6371;
    let distance = c * r;
    return distance.toFixed(2);
  }

  return (
    <>
      <Affix offsetTop={top} className="leftSideFilter">
      <div className="filterWrapper hotelFilterWrapper">
      
        <div className="filterYourResult marginTop30">
          <div className="commonFlex">
            <div>
              <Title level={4}>{context.translation && context.translation.filterResult}</Title>
            </div>
            <div className="commonFlex">
              <Link onClick={allFiltersClear}>
                <i className="icon icon-Reset"></i> {context.translation && context.translation.reset}
              </Link>
            </div>
          </div>
          {hotelresponse &&
            hotelresponse.prpts &&
            state.localResponse.prpts && (
              <span>
                {hotelresponse.prpts.length} of{" "}
                {state.localResponse.prpts.length} {context.translation && context.translation.properties}
              </span>
            )}
        </div>
        <Collapse 
          size="small" 
          defaultActiveKey={['1', '2', '3', '4', '5', '6', '7', '8', '9', '10']}
          items={[
            {
            key: '1',
            label: context.translation && context.translation.hotelName,
            className: "marginTop30",
            children: <>
              <Form.Item>
                <Input
                  type="text"
                  className="innerBoxShadow inputResults"
                  onChange={(e) => filterListByEnteredText(e)}
                  value={hotelName}
                  placeholder={context.translation && context.translation.sbhn}
                  onKeyDown={handleKeyDown}
                />
                <div className="hotelNameResult">
                {hotelName &&
                  !hotelNameSelected &&
                  state.hotelFilteredNamesList !== undefined &&
                  state.hotelFilteredNamesList.length > 0 &&
                  state.hotelFilteredNamesList.map((result) => (
                    
                    <li
                      key={result.value}
                      onClick={(e) => handleHotelName(result)}
                    >
                      <div className="airportNamecode-box">
                        <span> {result.hname}</span>
                      </div>
                    </li>
                    
                  ))}
                  </div>
              </Form.Item>
            </>,
            },
            {
              key: '2',
              label: context.translation && context.translation.locatfil,
              className: "marginTop30",
              children: <>
                <PlacesAutocomplete
                  value={state.locationName}
                  onChange={locationNameChange}
                  onSelect={handleLocationSelect}
                >
                  {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                    <div>
                      <Form.Item>
                      <Input
                        {...getInputProps({
                          type:"text",
                          placeholder:(context.translation && context.translation.searchbyloc),
                          className:"innerBoxShadow inputResults locationFilter"
                        })}
                      />
                      </Form.Item>
                      <div className="autocomplete-dropdown-container">
                        {loading && <div>Loading...</div>}
                        {suggestions.map(suggestion => {
                          const className = suggestion.active
                            ? 'suggestion-item--active'
                            : 'suggestion-item';
                          // inline style for demonstration purpose
                          const style = suggestion.active
                            ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                            : { backgroundColor: '#ffffff', cursor: 'pointer' };
                          return (
                            <div
                              {...getSuggestionItemProps(suggestion, {
                                className,
                                style,
                              })}
                            >
                              <span>{suggestion.description}</span>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </PlacesAutocomplete>
                {/* {latitude && longitude &&
                  <>
                    <p>Latitude : {latitude}</p>
                    <p>Longitude : {longitude}</p>
                </>} */}
              </>,
            },
            {
              key: '3',
              label: context.translation && context.translation.price,
              className: "marginTop30 flightTimings rangeSliders",
              children: <>
                <Slider
                  range
                  min={prcMin}
                  max={prcMax}
                  value={[minPrice, maxPrice]}
                  onChange={(e) => handlePriceChange(e)}
                  tooltip={{formatter:formatCurrencyForSlider}}
                />
                <div className="commonFlex price pLeftRight5">
                  <div>
                    <span className="currencyCode">
                      {/* {hotelresponse && hotelresponse.cur} */}
                      {cur ? cur : sessionCur}
                    </span>
                    <span className="currencyAmount">
                      {" "}
                      <FormatCurrency value={minPrice * base_usr_exrate} />
                    </span>
                  </div>
                  <div>
                    <span className="currencyCode">
                      {/* {hotelresponse && hotelresponse.cur} */}
                      {cur ? cur : sessionCur}
                    </span>
                    <span className="currencyAmount">
                      {" "}
                      <FormatCurrency value={maxPrice * base_usr_exrate} />
                    </span>
                  </div>
                </div>
              </>,
            },
            {
              key: '4',
              label: context.translation && context.translation.userReviews,
              className: "marginTop30 flightTimings rangeSliders",
              children: <>
                <Slider
                  range
                  min={rvwMin}
                  max={rvwMax}
                  value={[minReview, maxReview]}
                  onChange={(e) => handleReviews(e)}
                />
                <div className="commonFlex pLeftRight5">
                  <span>{state.expediadisplay && state.expediadisplay.min}</span>
                  <span>{state.expediadisplay && state.expediadisplay.max}</span>
                </div>
              </>,
            },
            {
              key: '5',
              label: context.translation && context.translation.starRating,
              className: "marginTop30 flightTimings rangeSliders",
              children: <>
                <div className="onlyFlex">
                  {isNotNull(state.strratdisplay) &&
                    isArrayNotEmpty(state.strratdisplay) &&
                    state.strratdisplay.map((rating, index) => (
                      <>
                        {rating.rat !== 0 && (
                          <Checkbox
                            value={rating.rat}
                            label={rating.rat}
                            checked={rating.ischeck}
                            onChange={handleStarRating(rating.rat)}
                            className="starRating"
                          >
                            <span className="ratingValue">{rating.rat}</span>
                          </Checkbox>
                        )}
                      </>
                    ))}
                </div>
              </>,
            },
            {
              key: '6',
              label: context.translation && context.translation.boardBasis,
              className: "marginTop30 custumCheckBox",
              children: <>
                <div>
                  {isArrayNotEmpty(state.boradbasisdisplay) &&
                    state.boradbasisdisplay
                      .map((ame, index) => (
                        <li key={index}>
                          <Row className="onlyFlex">
                            <div>
                              <Checkbox
                                value={ame.name}
                                label={ame.name}
                                checked={ame.ischeck}
                                onChange={handleBoardBasis(ame)}
                              >
                                {ame.name}{" "}
                              </Checkbox>{" "}
                            </div>
                            <div className="amount">
                              <span className="count"> ({ame.count})</span>
                            </div>
                          </Row>
                        </li>
                      ))
                      .slice(0, showFull ? state.boradbasisdisplay.length : "5")}
                </div>
              </>,
            },
            {
              key: '7',
              label: context.translation && context.translation.typeAccom,
              className: "marginTop30 custumCheckBox",
              children: <div className="resultAccom">
                <div>
                  {isNotNull(state.accomdisplay) &&
                    isArrayNotEmpty(state.accomdisplay) &&
                    state.accomdisplay
                      .map((acc, id) => (
                        <li key={id}>
                          <>
                            {" "}
                            <Row className="onlyFlex">
                              <Checkbox
                                value={acc.type}
                                label={acc.type}
                                checked={acc.isaccom}
                                onChange={handleAccommodationChange(acc)}
                              >
                                {acc.type}
                              </Checkbox>
                              <div>
                                <span> ({acc.count})</span>
                              </div>
                            </Row>
                          </>{" "}
                        </li>
                      ))
                      .slice(0, showFullamt ? state.accomdisplay.length : "5")}
                </div>
              </div>,
            },
            {
              key: '8',
              label: context.translation && context.translation.area,
              className: "marginTop30 custumCheckBox",
              children: <>
                <div>
                  <Form.Item>
                    <Input
                      type="text"
                      className="innerBoxShadow inputBottom"
                      onChange={(e) => filterAreaListByEnteredText(e)}
                      value={areaName}
                      placeholder={context.translation && context.translation.searchArea}
                    />
                  </Form.Item>
                  <div className="addScroll">
                    {isNotNull(state.areaFilteredList) &&
                      isArrayNotEmpty(state.areaFilteredList) &&
                      state.areaFilteredList
                        .map((ar, id) => (
                          <li key={id}>
                            {" "}
                            <Row>
                              <Checkbox
                                value={ar.name}
                                label={ar.name}
                                checked={ar.ischeck}
                                onChange={handleAreaChange(ar)}
                              >
                                {ar.name}
                              </Checkbox>
                              <div>
                                <span> ({ar.count})</span>
                              </div>
                            </Row>
                          </li>
                        ))
                        .slice(0, showArea ? state.areaFilteredList.length : "5")}
                  </div>
                </div>
                {isNotNull(state.areaFilteredList) &&
                isArrayNotEmpty(state.areaFilteredList) &&
                state.areaFilteredList.length >= 5 ? (
                  <Button
                    onClick={() => setShowArea(!showArea)}
                    className="showAllLess"
                  >
                    {showArea ? (context.translation && context.translation.showles) : (context.translation && context.translation.showAll)}
                  </Button>
                ) : (
                  ""
                )}
              </>,
            },
            {
              key: '9',
              label: context.translation && context.translation.landmark,
              className: "marginTop30 custumCheckBox",
              children: <>
                <div>
                  <Form.Item>
                    <Input
                      type="text"
                      className="innerBoxShadow inputBottom"
                      onChange={(e) => filterLandmarkListByEnteredText(e)}
                      value={landmarkName}
                      placeholder={context.translation && context.translation.searchLandmark}
                    />
                  </Form.Item>
                  <div className="addScroll">
                    {isNotNull(state.landmarkFilteredList) &&
                      isArrayNotEmpty(state.landmarkFilteredList) &&
                      state.landmarkFilteredList
                        .map((ln, id) => (
                          <li key={id}>
                            <Row className="hotelLandmark">
                              <Checkbox
                                value={ln.name}
                                label={ln.name}
                                checked={ln.ischeck}
                                onChange={handleLandmarkChange(ln)}
                              >
                                {ln.name}
                              </Checkbox>
                              <div>
                                <span className="landmarkValue"> ({ln.count})</span>
                              </div>
                            </Row>
                          </li>
                        ))
                        .slice(
                          0,
                          showLandmarks ? state.landmarkFilteredList.length : "5"
                        )}
                  </div>
                </div>
                {isNotNull(state.landmarkFilteredList) &&
                isArrayNotEmpty(state.landmarkFilteredList) &&
                state.landmarkFilteredList.length >= 5 ? (
                  <Button
                    className="showAllLess"
                    variant="link"
                    onClick={() => setShowLandmarks(!showLandmarks)}
                  >
                    {showLandmarks ?  (context.translation && context.translation.showles) : (context.translation && context.translation.showAll)}
                  </Button>
                ) : (
                  ""
                )}
              </>,
            },
            {
              key: '10',
              label: context.translation && context.translation.amenities,
              className: "marginTop30 custumCheckBox",
              children: <>
                {isNotNull(state.amenitiesdisplay) &&
                  isArrayNotEmpty(state.amenitiesdisplay) &&
                  state.amenitiesdisplay
                    .map((ame, index) => (
                      <Row key={index}>
                        {typeof ame.name !== 'object' && (
                          <li>
                            {" "}
                            <Checkbox
                              value={ame.name}
                              label={ame.name}
                              checked={ame.ischeck}
                              onChange={handleAmenities(ame)}
                              className="hotelAmenitiesFilter"
                            >
                              {ame.name}
                              <span className="hotelAmenitiesFilterValue">
                                {" "}
                                ({ame.count})
                              </span>
                            </Checkbox>
                          </li>
                        )}
                      </Row>
                    ))
                    .slice(0, showFullBds ? state.amenitiesdisplay.length : "5")}
              </>,
            }
          ]}
          >
        </Collapse>

        {/* <Collapse size="small" defaultActiveKey={["1"]} className="marginTop30">
          <Panel header="Preffered Location" key="1">
            <Form.Item>
              <Input
                type="text"
                className="innerBoxShadow inputResults"
                name=""
                value=""
                placeholder="Search Preffered Location…"
              />
            </Form.Item>
          </Panel>
        </Collapse> */}
      </div>
      </Affix>
    </>
  );
}

export default HotelFilters;
