import React, { useContext } from 'react';
import { Context } from '../../App';
import Logo from "../../assets/svg/logo.svg";
import DateUtils from "../utilities/DateUtils";
import {
  Col,
  Row,
  Typography,
} from "antd";
import Utilities from '../utilities/Utilities';
import FormatCurrency from '../utilities/FormatCurrency';
const { Title } = Typography;

const contentStyle = {
  margin: 0,
  height: "178px",
  width: "242px",
  color: "#fff",
  lineHeight: "160px",
  textAlign: "center",
  background: "#364d79",
  borderRadius: "4px",
};

const MyTripsHtlItineraryPdf = React.forwardRef(({itiData}, ref) => {
const [context, setContext] = useContext(Context)
const htlImg = itiData?.hotel_image?.replace("https://i.travelapi.com", "https://cdn-hotel.mncdn.com")
  return (
    <div ref={ref} className="mainLayoutWrap">     
        <div className="commonFlex">
            <img src={Logo} alt="Click N Travel" />
            {itiData && (
                <div className="booking">
                <div className="dateOfBooking">
                    <span className="dob">{context.translation && context.translation.dateofBok} :&nbsp;</span>
                    <span className="date">
                    {DateUtils.prettyDate(
                        itiData.bd,
                        "ddd, DD MMM, YYYY"
                    )}
                    </span>
                </div>
                <div className="bookingReference">
                    <span className="bReference">{context.translation && context.translation.bokRefer} :&nbsp;</span>
                    <span className="reference">{itiData.booking_id}</span>
                </div>
                
                </div>
            )}
        </div>
      <div className="travellerDetails">
        <Row>
          <Title level={4}>
            {context.translation && context.translation.bokSumy}
          </Title>
          <Col span={24}>
            <div className="hotelReviewDetails">
                <div className="sliderWrapper">
                  <span className="hotelTag">{itiData?.hotel_name}</span>
                    <div className="Carousel">
                      <img
                        src={htlImg ? htlImg : itiData?.hotel_image?.replace("https://i.travelapi.com", "https://cdn-hotel.mncdn.com")}
                        style={contentStyle}
                      />
                    </div>
                </div>
                <div className="fullWidth">
                    <div>
                      <div>
                        <Title level={4} className="hotelName">
                          {itiData?.hotel_name}
                        </Title>                        
                        <span className="stars">
                          {Utilities.getHotelStartRating(+itiData?.star_rating.split(".")[0]).map((star,idx) => (
                            <React.Fragment key={idx}>
                              <span className="hotelRating">
                                <i className="icon icon-star"></i>
                              </span>
                            </React.Fragment>
                          )
                          )}
                        </span>
                      </div>
                      <Title level={5} className="hotelAddress">
                        {itiData?.hotel_address}, {itiData?.hotel_city}, {itiData?.hotel_country}
                      </Title>
                      </div>
                      <div className="onlyFlex">
                      <div className="checkInBorderRight">
                        <span className="checkInOut">{context.translation && context.translation.checkin}</span>
                        <span className="dateTime">{itiData?.cin_date}, {itiData?.cin_time}</span>
                      </div>
                      <div>
                        <span className="checkInOut">{context.translation && context.translation.checkOut}</span>
                        <span className="dateTime">{itiData?.cout_date}, {itiData?.cout_time}</span>
                      </div>
                    </div>
                </div>
            </div>
          </Col>
        </Row>
      </div>
      
      <div>        
        <Title level={4} className='roomDetailTitle'>
          {context.translation && context.translation.roomDetails}
        </Title>
        {itiData && itiData?.rooms.length > 0 && itiData?.rooms.map((room, index) => (
          <React.Fragment key={index}>

              <div className="guestsWrapper">
                <div className='roomWrapr'> {context.translation && context.translation.room} {index + 1}: {room.room_name}</div> 
                <div className="travellersWrapper">
                  <span className="travellersTitle">
                    {context.translation && context.translation.guests}:
                  </span>
                  <span className="travellersDetailWrap">
                    {room.pax.map((pax, index) => (
                      <React.Fragment key={index}>
                        <span className="travellersDetail">
                          <span><i className="icon icon-tick"></i>
                          {pax.title} {pax.fname} {pax.lname} ({pax.type})</span>
                        </span>
                      </React.Fragment>
                    ))}
                  </span>
                </div>
              </div>

             

            <div className="travellerDetails">              
              <Row className="tableHeader" gutter={10}>
                <Col span={3} className="tableValue">
                {context.translation && context.translation.roomType} 
                </Col>
                <Col span={3} className="tableValue">
                {context.translation && context.translation.occupancy} 
                </Col>
                <Col span={3} className="tableValue">
                {context.translation && context.translation.boardType}
                </Col>
                <Col span={3} className="tableValue">
                {/* {context.translation && context.translation.hotelRef} */}
                {context.translation && context.translation.hotRef}#
                </Col>
                <Col span={3} className="tableValue">
                {/* {context.translation && context.translation.supConfno} */}
                {context.translation && context.translation.supConf}#
                </Col>
                <Col span={3} className="tableValue">
                {context.translation && context.translation.specialReq}
                </Col>
                <Col span={3} className="tableValue">
                  {context.translation && context.translation.status}
                </Col>
                <Col span={3} className="tableValue">
                  {context.translation && context.translation.totPrice}
                </Col>
              </Row>
          
              <Row className="tableData" gutter={10}>
                <Col span={3} className="tableValue">
                  {room.bed_type?room.bed_type:"N/A"}
                </Col>
                <Col span={3} className="tableValue">
                  {room.adults} {+room.childs.split(" ")[0] === 0 ? "" : "," + room.childs}
                </Col>
                <Col span={3} className="tableValue">
                  {room.board_basis?room.board_basis:"N/A"}
                </Col>
                <Col span={3} className="tableValue wordBreak">
                  {room.hotel_ref?room.hotel_ref:"N/A"}
                </Col>
                <Col span={3} className="tableValue wordBreak">
                  {room.supplier_ref?room.supplier_ref:"N/A"}
                </Col>
                <Col span={3} className="tableValue">
                  {room.special_request?room.special_request:"N/A "}
                </Col>
                <Col span={3} className={room.room_status.toUpperCase()==="CONFIRMED"?"tableValue active" : "tableValue primary"}>
                  {room.room_status}
                </Col>
                <Col span={3} className="tableValue wordBreak">
                  {itiData?.usr_currency} <FormatCurrency value={room.total_price * (itiData.base_usr_exrate ? itiData.base_usr_exrate : 1)} />
                </Col>
              </Row>
            </div>  

            <div className="horizontalDivider"></div>
              <div>    
                <div className='impWrap'>
                  <Title level={4} className='impTitle'>{context.translation && context.translation.canpolcy}</Title>
                  {room.cancellation_policy && room.cancellation_policy?.length!==0 &&
                    <>
                      {room.cancellation_policy.map((canp, index) => (
                        <React.Fragment key={index}>
                        <p className="impContent">{canp}</p>
                        </React.Fragment>
                      ))}
                    </>
                  }
                  {room.cancellation_policy?.length===0 &&
                    <>
                    <p className="impContent">{context.translation && context.translation.nonRefundable}</p>
                    </>
                  }
                </div>
                <div className='impWrap'>
                  <Title level={4} className='impTitle'>{context.translation && context.translation.impInf}</Title>
                  <>
                    {room.important_info && room.important_info !== undefined &&
                    <div className="toolTipCUstoom cnclInfo">
                      <p dangerouslySetInnerHTML={{__html: room.important_info.instructions}}  className="importantContent"></p>
                      <p dangerouslySetInnerHTML={{__html: room.important_info.specialInstructions}}  className="importantContent"></p>
                    </div>
                    }
                    {(room.important_info!==undefined && (room.important_info.instructions==="" || room.important_info.specialInstructions==="")) &&
                    <div className="toolTipCUstoom cnclInfo">
                      <p className="importantContent">N/A</p>
                    </div>
                    }
                  </>
                </div>
                {itiData?.note !== "" && itiData?.note !== undefined && (
                  <div className='impWrap'>
                    <Title level={4} className='impTitle'>{context.translation && context.translation.note}</Title>
                    <p className='impContent'>{itiData?.note}</p>
                  </div>
                )}
              </div>
            <div className="horizontalDivider"></div>
          </React.Fragment>
        ))}

        <div className="travellerDetails">            
          <Title level={3} className="heading">
            {context.translation && context.translation.paymentDetails}
          </Title>
          <Row className="tableHeader" justify="space-between">
            <Col span={6} className="tableValue">
              {context.translation && context.translation.modePayment}
            </Col>
            <Col span={6} className="tableValue">
              {context.translation && context.translation.cardType}
            </Col>
            <Col span={6} className="tableValue">
              {context.translation && context.translation.cardDetails}
            </Col>
            <Col span={6} sm={6} md={4} className="tableValue">
              {context.translation && context.translation.amountPaid}
            </Col>
          </Row>
          <Row className="tableData" justify="space-between">
            <Col span={6} className="tableValue">
              {itiData?.payment_mode} {context.translation && context.translation.cad}
            </Col>
            <Col span={6} className="tableValue">
              {itiData?.card_type} {context.translation && context.translation.cad}
            </Col>
            <Col span={6} className="tableValue">
            XXXXXXXXXXXX{itiData?.card_details}
            </Col>
            <Col span={6} sm={6} md={4} className="tableValue">
              {itiData?.usr_currency} <FormatCurrency value={itiData?.paid_amount} />
            </Col>
          </Row>
        </div>
      </div>
    </div>
  )
});

export default MyTripsHtlItineraryPdf