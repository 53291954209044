/**
 * @ Author: Srikanth Bandaru
 * @ Create Time: 2023-05-26 22:39:10
 * @ Description: This is component for displaying the Flight fare summary for one way and round trip flight search results page
 */

import React, { useEffect } from "react";
import FlightMatrix from "./FlightMatrix";
import { FLIGHT_FARE_SUMMARY } from "./sampleFlightResponse";

function FlightFareSummary(props) {
  const { searchRes, searchRequest, initRes, toggle,base_usr_exrate } = props;

  /* useEffect(() => {
    if (searchRes && searchRes.filter) {
      searchRes.filter.fare = FLIGHT_FARE_SUMMARY.data[0];
    }
  }, []); */

  return (
    <div className="getFareSummary">
      <FlightMatrix
        searchRes={searchRes}
        handleFilterChanges={props.handleFilterChanges}
        searchRequest={searchRequest}
        initRes={initRes}
        toggle={toggle}
        base_usr_exrate={base_usr_exrate}
      />
    </div>
  );
}

export default FlightFareSummary;
