/**
 * @ Author: Srikanth Bandaru
 * @ Create Time: 2023-05-11 22:55:33
 * @ Description: Component for formating the current to required format
 */

import React from "react";
import CurrencyFormat from "react-currency-format";
// import Utilities from "./Utilities";

/**
 * For formatting the amount sent to a specific currency format
 */
function FormatCurrency(props) {
  
  // price round of function
  // const roundCurrency = (value) => {
  //   // const decimalPart = value % 1; // Get the decimal part of the value
  //   const decimalPart = Utilities.getDecimalPart(value); // Get the decimal part of the value
  //   const numDecimalValue = +decimalPart;
    
  //   if (numDecimalValue < 0.05) {
  //     // If decimal part is less than 0.05, round down
  //     return Math.floor(value);
  //   } else {
  //     // If decimal part is greater than or equal to 0.05, round up
  //     return Math.ceil(value);
  //   }
  // };
  
  return (
    <>
      {props.value !== undefined && (
        <CurrencyFormat
          // value={roundCurrency(parseFloat(props.value))}
          value={parseFloat(props.value)}
          displayType={"text"}
          fixedDecimalScale={true}
          decimalScale={2}
          thousandSeparator={true}
          prefix={""}
          className={props.className}
        />
      )}
    </>
  );
}

export default FormatCurrency;
