/**
 * @ Author: Srikanth Bandaru
 * @ Create Time: 2023-04-27 15:42:53
 * @ Description: Component to show the Traveller counts and Class in the search panels
 */

import { Button, Col, Form, Input, Row, Select } from "antd";
import React, { useEffect, useReducer, useContext } from "react";
import { Context } from "../../../../App";

const initialState = {
  adultCount: 1,
  childCount: 0,
  infantsCount: 0,
  TravelClass: null,
  adtmax: 9,
  chdmax: 9,
  infmax: 9,
  cls: "Economy",
};
//=====================================
// Reducer function for userReact Hook
// which will update the state
//=====================================
const reducer = (state, action) => {
  switch (action.type) {
    default:
      return { ...state, [action.type]: action.payload };
  }
};

function AddTraveller(props) {
  const [context, setContext] = useContext(Context);
  const [state, dispatch] = useReducer(reducer, initialState);
  useEffect(() => {
    dispatch({ type: "adultCount", payload: props.sendCount[0] });
    dispatch({ type: "childCount", payload: props.sendCount[1] });
    dispatch({ type: "infantsCount", payload: props.sendCount[2] });
    dispatch({
      type: "TravelClass",
      payload: [{ value: props.sendCount[3], label: props.sendCount[3] }],
    });
  }, [props.sendCount]);

  useEffect(() => {
    if (props.oldCls !== undefined && props.oldCls !== "") {
      dispatch({
        type: "TravelClass",
        payload: [{ value: props.oldCls, label: props.oldCls }],
      });
    }
  }, [props.oldCls]);

  const handleIncrementAdlt = () => {
    if (
      state.adultCount < state.adtmax &&
      state.adultCount + state.childCount < 9
    ) {
      dispatch({ type: "adultCount", payload: state.adultCount + 1 });
      //totalPax("Increment", "ADT");
    }
  };
  const handleDecrementAdlt = () => {
    if (state.adultCount > 1) {
      dispatch({ type: "adultCount", payload: state.adultCount - 1 });
      if (state.adultCount === state.infantsCount) {
        dispatch({ type: "infantsCount", payload: state.adultCount - 2 });
      }
      //totalPax("Decrement", "ADT")
    }
  };

  const handleIncrementChild = () => {
    if (state.adultCount + state.childCount < 9) {
      dispatch({ type: "childCount", payload: state.childCount + 1 });
      //totalPax("Increment", "CHD")
    }
  };
  const handleDecrementChild = () => {
    if (state.childCount > 0) {
      dispatch({ type: "childCount", payload: state.childCount - 1 });
      //totalPax("Decrement", "CHD")
    }
  };

  const handleIncrementInf = () => {
    if (state.adultCount !== state.infantsCount) {
      dispatch({ type: "infantsCount", payload: state.infantsCount + 1 });
      //totalPax("Increment", "INF")
    }
  };
  const handleDecrementInf = () => {
    if (state.infantsCount > 0) {
      dispatch({ type: "infantsCount", payload: state.infantsCount - 1 });
      //totalPax("Decrement", "INF")
    }
  };

  const applyTravellerChanges = () => {
    const totalCount = state.adultCount + state.childCount + state.infantsCount;
    props.sendData([
      state.adultCount,
      state.childCount,
      state.infantsCount,
      totalCount,
    ]);
    props.sendClass(state.TravelClass);
    props.closetraveller();
  };

  const totalPax = (type, pax) => {
    if (type === "Increment") {
      const totalCount =
        state.adultCount + state.childCount + state.infantsCount + 1;
      if (pax === "ADT") {
        props.sendData([
          state.adultCount + 1,
          state.childCount,
          state.infantsCount,
          totalCount,
        ]);
      } else if (pax === "CHD") {
        props.sendData([
          state.adultCount,
          state.childCount + 1,
          state.infantsCount,
          totalCount,
        ]);
      } else if (pax === "INF") {
        props.sendData([
          state.adultCount,
          state.childCount,
          state.infantsCount + 1,
          totalCount,
        ]);
      }
    } else {
      const totalCount =
        state.adultCount + state.childCount + state.infantsCount - 1;
      if (pax === "ADT") {
        if (state.adultCount === state.infantsCount) {
          props.sendData([
            state.adultCount - 1,
            state.childCount,
            state.adultCount - 1,
            totalCount - 1,
          ]);
        } else {
          props.sendData([
            state.adultCount - 1,
            state.childCount,
            state.infantsCount,
            totalCount,
          ]);
        }
      } else if (pax === "CHD") {
        props.sendData([
          state.adultCount,
          state.childCount - 1,
          state.infantsCount,
          totalCount,
        ]);
      } else if (pax === "INF") {
        props.sendData([
          state.adultCount,
          state.childCount,
          state.infantsCount - 1,
          totalCount,
        ]);
      }
    }
  };
  // const TravelClass = [
  //   { value: "Economy", label: "Economy", title: " " },
  //   { value: "Premium", label: "Premium Economy", title: " " },
  //   { value: "Business", label: "Business", title: " " },
  //   { value: "First class", label: "First class", title: " " },
  // ];
  const TravelClass = [
    { value: `${context.translation && context.translation.economy}`, label: `${context.translation && context.translation.economy}`, title: " " },
    { value: `${context.translation && context.translation.premium}`, label: `${context.translation && context.translation.pEconomy}`, title: " " },
    { value: `${context.translation && context.translation.business}`, label: `${context.translation && context.translation.business}`, title: " " },
    { value: `${context.translation && context.translation.firstCls}`, label: `${context.translation && context.translation.firstCls}`, title: " " },
  ];

  const handleChange = (TravelClass) => {
    dispatch({ type: "TravelClass", payload: TravelClass });
    //props.sendClass(TravelClass);
  };
  const closeTrvl = () => {
    props.closetravaler("coseTrv");
  };
  return (
    <>
      <Row className="tc-title">
        <Col>
          <h5> 
            {/* Select Travellers */}
            {context.translation && context.translation.selTrs}
          </h5>
        </Col>
        <Col className="tc-apply">
          <Button onClick={applyTravellerChanges}>
            {/* Apply */}
            {context.translation && context.translation.apply}
          </Button>
        </Col>
      </Row>
      <ul>
        <li>
          <label>
            {/* Adults  */}
            {context.translation && context.translation.adts}
            <small>              
              {/* (12 yrs and above) */}
              ({context.translation && context.translation.adtYrs})
              </small>
          </label>
          <div className="count">
            <Button
              type="default"
              onClick={handleDecrementAdlt}
              disabled={state.adultCount == 1 ? true : false}
            >
              -
            </Button>
            <Input readOnly value={state.adultCount}></Input>
            <Button type="default" onClick={handleIncrementAdlt}>
              +
            </Button>
          </div>
        </li>
        <li>
          <label>
            {/* Children  */}
            {context.translation && context.translation.chds}
            <small>
              {/* (2-12 Yrs) */}
              ({context.translation && context.translation.chdYrs})
            </small>
          </label>
          <div className="count">
            <Button
              type="default"
              onClick={handleDecrementChild}
              disabled={state.childCount == 0 ? true : false}
            >
              -
            </Button>
            <Input readOnly value={state.childCount} />
            <Button type="default" onClick={handleIncrementChild}>
              +
            </Button>
          </div>
        </li>
        <li>
          <label>
            {/* Infants  */}
            {context.translation && context.translation.infs}
            <small> 
              {/* (Below 2 Yrs) */}
              ({context.translation && context.translation.infYrs})
            </small>
          </label>
          <div className="count">
            <Button
              type="default"
              onClick={handleDecrementInf}
              disabled={state.infantsCount == 0 ? true : false}
            >
              -
            </Button>
            <Input readOnly value={state.infantsCount} />
            <Button type="default" onClick={handleIncrementInf}>
              +
            </Button>
          </div>
        </li>
      </ul>
      <div className="cabin-class">
        <h5>
          {/* Cabin Class */}
          {context.translation && context.translation.cabin}
        </h5>

        <Select
          defaultValue={state.TravelClass}
          options={TravelClass}
          value={state.TravelClass}
          onChange={handleChange}
        />
      </div>
    </>
  );
}

export default AddTraveller;
