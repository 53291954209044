import React from 'react';
import { Row, Col } from "antd";
import { useState } from 'react';
import GoogleMapReact from 'google-map-react';

function HotelMapView(props) {
  // const [resp, setResp] = useState(props.locations);
    const [zoom, setZoom] = useState(11);
    const [key, setKey] = useState("AIzaSyBPnDatU8GFmaTp3-rfJAKmjLS6bPMEjrY");

     const defaultProps = {
        center: {
          lat: props.location!==undefined && props.location.lat?props.location.lat:undefined,
          lng: props.location!==undefined && props.location.long?props.location.long:undefined
        },
      };

      const renderMarkers = (map, maps) => {
        let marker = new maps.Marker({
        position: { lat: props.location!==undefined && props.location.lat, lng: props.location!==undefined && props.location.long },
        map,
        title: props.hname!==undefined?props.hname:""
        });
        return marker;
       };
  return (
    <Row gutter={30}>
      <Col span={14}>
        <div style={{ height: '100%', width: '100%', borderRadius: '8px', border: '1px solid #C6C6C6', boxShadow: '0px 3px 6px #2755B126', background: '#fff', padding: '15px' }}>
        {props.location!==undefined &&
        <GoogleMapReact
          bootstrapURLKeys={{ key: key}}
          defaultCenter={defaultProps.center}
          defaultZoom={zoom}
          onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
        >
        </GoogleMapReact>
         }
      </div>
      </Col>
      <Col span={10}>
        <div className='grandHotelWrapper hrsWrapper'>
          <div className='grandHotelName'>{props.hname}</div>
          <div className="nearbylist" dangerouslySetInnerHTML={{ __html: props.attractions }} />
        </div>
      </Col>
    </Row>
  );
}

export default HotelMapView