import React, { useContext, useEffect, useState } from "react";
import ResultSectionHeader from "./ResultSectionHeader";
import AirlinesMemo from "../common/airlines/AirlinesMemo";
import AirportMemo from "../common/airlines/AirportNames";
import DateUtils from "../../../utilities/DateUtils";
import { Context } from "../../../../App";
import { isNotNull } from "../../../utilities/validators";
import Duration from "../../../../assets/img/duration.png";
import { Tooltip } from "antd";
const _ = require("lodash");
const CDNURL = process.env.REACT_APP_CNT_CDN_URL
function FlightDetailsContent(props) {
  const [context, setContext] = useContext(Context);
  const {
    mapping,
    sectors,
    secType,
    secTypeIndex,
    trip,
    flt,
    // seg,
    refund,
    flow,
    req,
    regionType,
    pnr,
  } = props;
  const [fltLoaded, setFltLoaded] = useState(false);
  const [gccCodes, setGccCodes] = useState([
    "XNB",
    "DWC",
    "DXB",
    "AZAZ",
    "DZB",
    "DOH",
    "XSB",
    "KWI",
    "MCT",
    "DMM",
    "SHJ",
    "IST",
    "JED",
  ]);
  const [cinqn, setCinqn] = useState();
  const [cbnqn, setCbnqn] = useState();

  //For layover time calculation
  const layOverTimeCal = (one, two, deptCode, arCode) => {
    if (deptCode !== undefined && arCode !== undefined) {
      var deptCnt = mapping.filter((obj) => obj.airportCode === deptCode);
      var arCnt = mapping.filter((obj) => obj.airportCode === arCode);
      let time1 = DateUtils.getTimeZoneDate(two, arCnt[0].countryCode);
      let time2 = DateUtils.getTimeZoneDate(one, deptCnt[0].countryCode);
      let timeDiff = DateUtils.calculateHoursandMinsbetweendates(time2, time1);
      let time = _.cloneDeep(timeDiff);
      let hrs = 0;
      let mins = 0;
      if (timeDiff < 60) {
        //inital minutes calculation
        mins = timeDiff;
      } else {
        for (let i = 0; i < timeDiff; i++) {
          if (time >= 60) {
            time = time - 60;
            hrs = hrs + 1;
          } else {
            mins = time;
            break;
          }
        }
      }

      return hrs + "h:" + mins + "m";
    } else {
      let totTime = Math.abs(new Date(one) - new Date(two));
      return DateUtils.msToTime(totTime);
    }
  };

  const segFltDuration = (fdur) => {
    const [hours, minutes] = fdur.split(":").map(Number)
    return `${hours}h:${minutes}m`
  };

  const getCheckInCabinValues = (selectedFlt) => {
    var check_qun = "";
    var cabin_qun = "0";
    let selFareObj =
      selectedFlt.fareop && selectedFlt.fareop.filter((obj) => obj.selected);
    if (selFareObj) {
      if (selectedFlt.sup === "4") {
        if (
          selFareObj[0].pr.ftyp === "Regular - R" ||
          selFareObj[0].pr.ftyp === "Flexi - J"
        ) {
          if (regionType === "Domestic") {
            check_qun = "15";
          } else if (regionType === "International") {
            if (
              (req.srcCode === "JED" &&
                isNotNull(req.dest) &&
                req.dest.country === "IN") ||
              (isNotNull(req.src) &&
                req.src.country === "IN" &&
                req.destCode === "JED")
            ) {
              check_qun = "30";
            } else if (
              (gccCodes.includes(req.srcCode) ||
              gccCodes.includes(req.destCode)) || 
              gccCodes.includes(req.src_code) ||
              gccCodes.includes(req.dest_code)
            ) {
              check_qun = "30";
            } else {
              check_qun = "20";
            }
          }
          cabin_qun = "7";
        } else if (selFareObj[0].pr.ftyp.includes("Corporate")) {
          cabin_qun = "10";
          if (regionType === "Domestic") {
            check_qun = "15";
          } else {
            check_qun = "30";
          }
        } else {
          check_qun = "30";
          cabin_qun = "7";
        }
      } else {
        // Setting Check in baggage
        if (selFareObj[0].pr.cabb !== undefined) {
          if (
            selFareObj[0].pr.cabb.includes("Chargable") ||
            selFareObj[0].pr.cabb.includes("Chargeable")
          ) {
            check_qun = "0";
          } else {
            if (
              selFareObj[0].pr.cabb.includes("free") ||
              selFareObj[0].pr.cabb.includes("Free")
            ) {
              if (selFareObj[0].pr.bagage_info !== undefined) {
                if (selFareObj[0].pr.bagage_info.includes("20")) {
                  check_qun = "20";
                } else if (selFareObj[0].pr.bagage_info.includes("30")) {
                  check_qun = "30";
                } else if (selFareObj[0].pr.bagage_info.includes("40")) {
                  check_qun = "40";
                }
              }
            } else if (selFareObj[0].pr.cabb.includes("20")) {
              check_qun = "20";
            } else if (selFareObj[0].pr.cabb.includes("30")) {
              check_qun = "30";
            } else if (selFareObj[0].pr.cabb.includes("40")) {
              check_qun = "40";
            }
          }
        }
        if (
          selFareObj[0].pr.chekb !== undefined &&
          selFareObj[0].pr.chekb !== ""
        ) {
          // Setting Cabin baggage
          if (
            selFareObj[0].pr.chekb.includes("Chargable") ||
            selFareObj[0].pr.chekb.includes("Chargeable")
          ) {
            cabin_qun = "0";
            if (selectedFlt.sup === "6") {
              cabin_qun = "7";
            } else if (selectedFlt.sup === "2") {
              cabin_qun = "10";
            }
          } else {
            if (selFareObj[0].pr.chekb.includes("7")) {
              cabin_qun = "7";
            } else if (selFareObj[0].pr.chekb.includes("10")) {
              cabin_qun = "10";
            }
          }
        } else {
          if (selectedFlt.sup === "6") {
            cabin_qun = "7";
          } else if (selectedFlt.sup === "2") {
            cabin_qun = "10";
          }
        }
      }
    }
    if (check_qun) {
      selectedFlt.cinqn = check_qun;
      setCinqn(check_qun);
    }
    if (cabin_qun) {
      selectedFlt.cbnqn = cabin_qun;
      setCbnqn(cabin_qun)
    }
    setFltLoaded(true);
  };

  useEffect(() => {
    if (flt && flt.sup !== 0) {
      getCheckInCabinValues(flt);
    } else {
      setFltLoaded(true);
    }
  }, []);

  return (
    <>{fltLoaded && 
      (<>
      <div className="fdprSeprator">
      <div className="info">
      <ResultSectionHeader
        mapping={mapping}
        sectors={sectors}
        secType={secType}
        secTypeIndex={secTypeIndex}
        trip={trip}
        refund={refund}
        flow={flow}
        pnr={pnr} />
    </div>
    <div className="fdpr">
        <div className="leftSemicircle"></div>
        {flt &&
          flt.seg &&
          flt.seg.map((sege, index) => (
            <div key={index}>
              <div className="ticket__divider">
                <div className="ticket__notch"></div>
                <div className="ticket__notch ticket__notch--right"></div>
              </div>
              <div className="dFlex order">
                <div className="airlineName">
                <div className="planeInfo">
                  {(
                    <>
                      <img
                        src={CDNURL+"/airlines/1x/airline-logos/" +
                          sege.ma +
                          ".png"}
                        alt="AirLine" />
                        
                      <span className="planeName">
                        <Tooltip placement="top" title={<span><AirlinesMemo code={sege.ma} /></span>}>
                        <AirlinesMemo code={sege.ma} />
                        </Tooltip>
                      </span>
                      <div className="planeCodeCatWrap">
                      <span className="planeCode">
                        {sege.ma} - {sege.fn}
                      </span>

                  {(sessionStorage.getItem("SEL_LAG")==="English"||sessionStorage.getItem("SEL_LAG")===null)&& flt.cl==="Economy"? <span className="category">Economy</span> :sessionStorage.getItem("SEL_LAG")==="Arabic"&& flt.cl==="Economy"&&<span className="category">سياحية</span>}
                  {(sessionStorage.getItem("SEL_LAG")==="English"||sessionStorage.getItem("SEL_LAG")===null)&& flt.cl==="Premium Economy"?<span className="category">Premium Economy</span> :sessionStorage.getItem("SEL_LAG")==="Arabic"&& flt.cl==="Premium Economy"&&<span className="category">السياحية المتميزة</span>}
                  {(sessionStorage.getItem("SEL_LAG")==="English"||sessionStorage.getItem("SEL_LAG")===null)&& flt.cl==="Business"?<span className="category">Business</span> :sessionStorage.getItem("SEL_LAG")==="Arabic"&& flt.cl==="Business"&&<span className="category">رجال الأعمال</span>}
                  {(sessionStorage.getItem("SEL_LAG")==="English"||sessionStorage.getItem("SEL_LAG")===null)&& flt.cl==="First"?<span className="category">First class</span> :sessionStorage.getItem("SEL_LAG")==="Arabic"&& flt.cl==="First"&&<span className="category">الصف الأول</span>}
                  </div>
                    </>
                  )}
                  
                  

                  
                  </div>
                  <div className="operatedBy"><span>{context.translation && context.translation.operated} </span> <span><AirlinesMemo code={sege.oa} /></span></div>
                </div>
                <div className="dFlex">
                <div className="timeInfo">
                  <div>
                    <span className="time">
                      {sege.dd
                        .split("T")[1]
                        .substring(0, sege.dd.split("T")[1].length - 3)}{" "}
                    </span>
                    <span className="countryCode">{sege.da}</span>
                  </div>
                  <div className="dateMonthYearWrap">
                    {DateUtils.convertToLetterDate(sege.dd)}
                  </div>
                  <span className="airportName">
                    <AirportMemo
                      field="ResultDetails"
                      code={sege.da}
                      mapping={mapping} />
                  </span>
                  {sege.terminal && sege.terminal.dep && (
                    <span className="terminal">
                      {context.translation && context.translation.terminal} {sege.terminal.dep}
                    </span>
                  )}
                </div>
                {/* {flt.sdur !== "" && flt.sdur !== undefined && (
                  <div className="duration">
                    <span> {layOverTimeCal(sege.ad, sege.dd, sege.da, sege.ar)}</span>
                    <img src={Duration} alt="duration-icon" />
                  </div>
                )} */}
                {flt.sdur !== "" && flt.sdur !== undefined && (
                  <>
                  {sege.fdur !== "" && sege.fdur !== undefined && (
                    <div className="duration">
                        <span> {segFltDuration(sege.fdur)}</span>
                      <img src={Duration} alt="duration-icon" />
                    </div>
                  )}
                  </>
                )}

                <div className="timeInfo">
                  <div>
                    <span className="time">
                      {sege.ad
                        .split("T")[1]
                        .substring(0, sege.ad.split("T")[1].length - 3)}{" "}
                    </span>
                    <span className="countryCode">{sege.ar}</span>
                  </div>
                  <div className="dateMonthYearWrap">
                    {DateUtils.convertToLetterDate(sege.ad)}
                  </div>
                  <span className="airportName">
                    <AirportMemo
                      field="ResultDetails"
                      code={sege.ar}
                      mapping={mapping} />
                  </span>
                  {sege.terminal && sege.terminal.arr && (
                    <span className="terminal">
                      {context.translation && context.translation.terminal} {sege.terminal.arr}
                    </span>
                  )}
                </div>
                </div>
              </div>
              {flt.seg.length > 1 &&
                flt.seg[index + 1] &&
                flt.seg[index + 1].dd !== undefined && (
                  <div className="layover">
                    <span>
                      {" "}
                      {context.translation && context.translation.layover} :&nbsp;
                      <>
                        {layOverTimeCal(
                          flt.seg[index].ad,
                          flt.seg[index + 1].dd
                        )}
                      </>
                    </span>
                  </div>
                )}
            </div>
          ))}
        <div className="rightSemicircle"></div>
      </div>
      <div className="baggageAllowanceInfo">
        <div className="baggageAllowanceHeading">
          <span className="baggageAllowance">{context.translation && context.translation.baggAllow}</span>
          <span className="perPassenger">{context.translation && context.translation.perAdtChd}</span>
        </div>
        {flt &&
          flt.bgd &&
          <div className="baggageWrapper">
            <div className="baggage">
              <i className="icon icon-Baggage"></i>
              <span className="cabin">{context.translation && context.translation.cabinBag}</span>
              <span className="weight">{context.translation && context.translation.bagWgt}</span>
            </div>
            <div className="baggage">
              <i className="icon-Check-in"></i>
              <span className="checkIn">{context.translation && context.translation.checkInBag}</span>
              <span className="weight">
                {flt.bgd && flt.bgd.length === 1 && flt.bgd[0] && flt.bgd[0].qun}{flt.bgd && flt.bgd.length === 1 && flt.bgd[0] && flt.bgd[0].wt}
                {flt.bgd && flt.bgd.length > 1 && flt.bgd.filter((b) => b.type === "Checkin")[0] && flt.bgd.filter((b) => b.type === "Checkin")[0].qun}{flt.bgd && flt.bgd.length > 1 && flt.bgd.filter((b) => b.type === "Checkin")[0] && flt.bgd.filter((b) => b.type === "Checkin")[0].wt}
              </span>
            </div>
          </div>}
        {!flt.bgd && (
          <div className="baggageWrapper">
            <div className="baggage">
              <i className="icon icon-Baggage"></i>
              <span className="cabin">{context.translation && context.translation.cabinBag}</span>
              <span className="weight">{flt.cbnqn ? flt.cbnqn : cbnqn}{context.translation && context.translation.kg}</span>
            </div>
            <div className="baggage">
              <i className="icon-Check-in"></i>
              <span className="checkIn">{context.translation && context.translation.checkInBag}</span>
              <span className="weight">{flt.cinqn ? flt.cinqn : cinqn}{context.translation && context.translation.kg}</span>
            </div>
          </div>
        )}
      </div>
      </div></>)
    }
    </>
  );
}

export default FlightDetailsContent;
