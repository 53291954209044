import React, { useState, useEffect } from "react";
import { Context } from "../../../../App";
import { useContext } from "react";
import { Row, Button, Col, Typography, Tooltip } from "antd";
import Utilities from "../../../utilities/Utilities";
import FormatCurrency from "../../../utilities/FormatCurrency";
const { Title } = Typography;

function HotelRoomDetails(props) {
  const [context, setContext] = useContext(Context)
  const [cur, setCur] = useState("");

  useEffect(() => {
    setCur(context.userCur!==undefined && context.userCur!==""?context.userCur:sessionStorage.getItem("SEL_CUR"));
  }, [sessionStorage.getItem("SEL_CUR") || context.userCur])
  
  return (
    <>
    <div className="tableScroll displayNone-640">
      <div className="tableWrap">
        <Row className="tableHeader">
        <Col span={3} className="tableValue">
        {context.translation && context.translation.roomType} 
          </Col>
          <Col span={3} className="tableValue">
        {context.translation && context.translation.occupancy} 
          </Col>
          <Col span={3} className="tableValue">
          {context.translation && context.translation.boardType}
          </Col>
          <Col span={3} className="tableValue">
          {context.translation && context.translation.hotelRef}
          </Col>
          <Col span={3} className="tableValue">
          {context.translation && context.translation.supConfno}
          </Col>
          <Col span={3} className="tableValue">
          {context.translation && context.translation.specialReq}
          </Col>
          <Col span={3} className="tableValue">
            {context.translation && context.translation.status}
          </Col>
          <Col span={3} className="tableValue">
            {context.translation && context.translation.totPrice}
          </Col>
        </Row>
      
        <Row className="tableData">
          <Col span={3} className="tableValue">
          {props.room_price.rmpr[0].bed}
          </Col>
          <Col span={3} className="tableValue">
          {props.room_price.adt} {context.translation && context.translation.adults} {props.room_price.chd !== 0 && props.room_price.chd} {props.room_price.chd !== 0 && context.translation && context.translation.chis}{props.room_price.chd !== 0 && props.room_price.chdage}
          </Col>
          <Col span={3} className="tableValue">
          {props.room_price.rmpr[0].ra!==undefined && props.room_price.rmpr[0].ra[0]!==undefined?Utilities.getBoardBasisHotel(props.room_price.rmpr[0].ra):(context.translation && context.translation.roomOnly)}
          </Col>
          <Col span={3} className="tableValue">
          {props.room_price.stat==="Confirmed" && props.room_price.htlrefnum!==undefined && props.room_price.htlrefnum?props.room_price.htlrefnum:context.translation && context.translation.na}
          </Col>
          <Col span={3} className="tableValue wordBreak">
          {props.room_price.stat==="Confirmed" && props.room_price.confirmnum!=="" && props.room_price.confirmnum?props.room_price.confirmnum:context.translation && context.translation.na}
          </Col>
          <Col span={3} className="tableValue">
          {props.spr!==""?props.spr:context.translation && context.translation.na}
          </Col>
          <Col span={3} className="tableValue active">
          {props.room_price.stat}
          </Col>
          <Col span={3} className="tableValue">
          {props.currency !== undefined && props.currency !== "" ? props.currency : cur} <FormatCurrency value={(props.room_price.rmpr[0].pr[0].tf + Utilities.calHotelServiceFee(props.room_price.rmpr[0].servicefee, "CNT", "All")) * props.base_usr_exrate}/>
          </Col>
        </Row>
      </div>
    </div>
    <div className='smallScreenTable displayNoneLG'>
    <Row className="tableHeader">
    <Col span={24} className="tableData">
      <div className="tableValueWrap">
        <div className="tableValue">
          {context.translation && context.translation.roomType} 
        </div>
        <div className="tableValue">
          {props.room_price.rmpr[0].bed}
        </div>
      </div>
      <div className="tableValueWrap">
        <div className="tableValue">
          {context.translation && context.translation.occupancy} 
        </div>
        <div className="tableValue">
          {props.room_price.adt} {context.translation && context.translation.adults} {props.room_price.chd !== 0 && props.room_price.chd} {props.room_price.chd !== 0 && context.translation && context.translation.chis}
        </div>
      </div>
      <div className="tableValueWrap">
        <div className="tableValue">
          {context.translation && context.translation.boardType}
        </div>
        <div className="tableValue">
          {props.room_price.rmpr[0].ra!==undefined && props.room_price.rmpr[0].ra[0]!==undefined?Utilities.getBoardBasisHotel(props.room_price.rmpr[0].ra):(context.translation && context.translation.roomOnly)}
        </div>
      </div>
      <div className="tableValueWrap">
        <div className="tableValue">
          {context.translation && context.translation.hotelRef}
        </div>
        <div className="tableValue">
          {props.room_price.stat==="Confirmed" && props.room_price.htlrefnum!==undefined && props.room_price.htlrefnum?props.room_price.htlrefnum:context.translation && context.translation.na}
        </div>
      </div>
      <div className="tableValueWrap">
        <div className="tableValue">
          {context.translation && context.translation.supConfno}
        </div>
        <div className="tableValue wordBreak">
          {props.room_price.stat==="Confirmed" && props.room_price.confirmnum!=="" && props.room_price.confirmnum?props.room_price.confirmnum:context.translation && context.translation.na}
        </div>
      </div>
      <div className="tableValueWrap">
        <div className="tableValue">
          {context.translation && context.translation.specialReq}
        </div>
        <div className="tableValue">
          {props.spr!==""?props.spr:context.translation && context.translation.na}
        </div>
      </div>
      <div className="tableValueWrap">
        <div className="tableValue">
          {context.translation && context.translation.status}
        </div>
        <div className="tableValue active">
          {props.room_price.stat}
        </div>
      </div>
      <div className="tableValueWrap">
        <div className="tableValue">
          {context.translation && context.translation.totPrice}
        </div>
        <div className="tableValue">
          {props.currency !== undefined && props.currency !== "" ? props.currency : cur} <FormatCurrency value={props.room_price.rmpr[0].pr[0].tf * props.base_usr_exrate}/>
        </div>
      </div>
    </Col>
    </Row>
    </div>
      <div className="horizontalDivider"></div>
        <div>
          <div className='impWrap'>
            <Title level={4} className='impTitle'>{context.translation && context.translation.canpolcy}</Title>
            {props.canpolicy && props.canpolicy?.length!==0 &&
              <>
                {props.canpolicy.map((canp, index) => (
                  <React.Fragment key={index}>
                  <p className="impContent">{canp}</p>
                  </React.Fragment>
                ))}
                </>
              }
              {props.canpolicy?.length===0 &&
                <>
                <p className="impContent">{context.translation && context.translation.nonRefundable}</p>
                </>
              }
          </div>
          <div className='impWrap'>
            <Title level={4} className='impTitle'>{context.translation && context.translation.impInf}</Title>
            <div className="impContent">
              <>
                {props.imp?.remarks && props.imp?.remarks !== undefined &&
                <div className="toolTipCUstoom cnclInfo">
                  <p dangerouslySetInnerHTML={{__html: props.imp?.remarks.instructions}}  className="importantContent"></p>
                  <p dangerouslySetInnerHTML={{__html: props.imp?.remarks.specialInstructions}}  className="importantContent"></p>
                </div>
                }
                {(props.imp?.remarks!==undefined && (props.imp?.remarks.instructions==="" || props.imp?.remarks.specialInstructions==="")) &&
                <div className="toolTipCUstoom cnclInfo">
                  <p className="importantContent">N/A</p>
                </div>
                }
              </>
            </div>
          </div>
          {props.note !== "" && props.note !== undefined && (
            <div className='impWrap'>
              <Title level={4} className='impTitle'>{context.translation && context.translation.note}</Title>
              {/* <p className='impContent'>{roundUpPriceNote(props.note)}</p> */}
              <p className='impContent'>{props.note}</p>
            </div>
          )}
        </div>
    </>
  );
}

export default HotelRoomDetails;
