
import React, { useEffect } from "react";
import {
  Button,
  Col,
  Input,
  Row,
  Typography,
  Tooltip,
  Space,
  Modal,
} from "antd";
import ExpediaLogo from "../../../../assets/img/expedia-logo.png";
import { useReducer, useState ,useContext} from "react";
import { Context } from "../../../../App";
import Link from "antd/es/typography/Link";
import FormatCurrency from "../../../utilities/FormatCurrency";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import HotelBrandIcon from "../../../../assets/gif/Forever/White/H_WHITE_FOREVER.gif";
import { WhatsappShareButton } from "react-share";
import Whatsapp from "../../../../assets/img/whatsapp.png";
import HotelReviewsAndRatings from "../common/HotelReviewsAndRatings";
import Shareicon from "../../../../assets/img/shareicon.png";
export const HTL_REQUEST = "htlRequest";
export const HTL_MARKUP = "htlMarkup";
export const HTL_CHD_AGES = "chdages";
export const HTL_CTID = "ctid";
const { Title } = Typography;

const contentStyle = {
  margin: 0,
  height: "178px",
  width: "242px",
  color: "#fff",
  lineHeight: "160px",
  textAlign: "center",
  background: "#364d79",
  borderRadius: "4px",
};

const initialState = {
  notiMessage: "",
  notiMessageShow: false,
  notiVarient: "danger",
  markp: 0,
  showPop: false,
  isLoading: false,
  addMrkup: false,
  checkAvail: false,
  markupApplied: false,
  guestReviews: [],
  reviewObj: "",
  expRating: 0,
  usrRating: 0,
  response: "",
};

//=====================================
// Reducer function for userReact Hook
// which will update the state
//=====================================
const reducer = (state, action) => {
  switch (action.type) {
    case "clear":
      return {
        ...state,
      };
    default:
      return { ...state, [action.type]: action.payload };
  }
};

function HotelResultsBlock(props) {
  const [context, setContext] = useContext(Context)
  const { htl, request, response,shortUrl, base_usr_exrate } = props;
  const [searchReq, setSearchReq] = useState(request);
  const [state, dispatch] = useReducer(reducer, initialState);
  const [copied,setCopied] = useState(false);
  const [loadShortUrl, setLoadShortUrl] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [cur, setCur] = useState("");

  useEffect(() => {
    setCur(context.userCur!==undefined && context.userCur!==""?context.userCur:sessionStorage.getItem("SEL_CUR"));
  }, [sessionStorage.getItem("SEL_CUR") || context.userCur])

  const chooseRoom = (htlId, isPackage, sup) => () => {
    let selHtl = [];
    if (sup === 0) {
      selHtl = response.prpts.filter(
        (obj) => Number(obj.pid) === Number(htlId)
      );
    } else {
      selHtl = response.prpts.filter((obj) => obj.pid === htlId);
    }
    selHtl[0].type = response.type !== undefined ? response.type : "";
    selHtl[0].sgst = response.sgst !== undefined ? response.sgst : 0;
    selHtl[0].cgst = response.cgst !== undefined ? response.cgst : 0;
    selHtl[0].igst = response.igst !== undefined ? response.igst : 0;
    selHtl[0].tds_per = response.tds_per !== undefined ? response.tds_per : 0;
    selHtl[0].sup_base_exrate = response.sup_base_exrate;
    selHtl[0].base_sup_exrate = response.base_sup_exrate;
    selHtl[0].sup_cur = response.sup_cur !== "" && response.sup_cur !== undefined ? response.sup_cur : "USD";
    let htl = JSON.stringify(selHtl[0]);
    //selHtl.request=searchReq;
    localStorage.setItem(HTL_REQUEST, JSON.stringify(searchReq));
    localStorage.setItem(HTL_MARKUP, state.markp);
    localStorage.setItem(HTL_CTID, response.ctid);
    let comid = sessionStorage.getItem("CMPID");
    var w = window.open("/hotel/details");
    w.htl = htl;
    w.cmpid = comid;
    w.isPackage = isPackage;
    w.searchRequest = request;
  };

  const imageUrlCarousel = (images ) => {
    let imgs = [];
    if(images){
      let filteredImages = images.filter(img => img.links["350Px"]);
      imgs = filteredImages.map(img => img.links["350Px"].href);
    }
    return imgs;
  }

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        onClick={onClick}
      />
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        onClick={onClick}
      />
    );
  }

  const sliderSettings = {
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    rtl: false,
    autoplay: isHovered,
    autoplaySpeed: 2000,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />
  };

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(shortUrl);
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 1500); // Reset the "copied" state after 1.5 seconds
    } catch (error) {
      console.error('Copy failed:', error);
    }
  }

  const closeShare=()=>{
    setLoadShortUrl(false);
  }

  const openShare = () => {
    setLoadShortUrl(true);
  }
  
  const showReviewsPopup = () => {
    setIsModalOpen(true);
  }

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  
  return (
    <>
      <Row>
        <Col span={24}>
          <div className="hotelResultsBlockWrapper">
            <div className="hotelContLeft">
              <div className="sliderWrapper">
                <span className="hotelTag">{context.selectLang == undefined || context.selectLang === "en" ? htl.cont.catnm : htl.cont_ar.catnm}</span>
                {context.selectLang == undefined || context.selectLang === "en" ?
                  <>
                  {htl.cont !== undefined && htl.cont.image !== undefined ? (
                    <div onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
                      <Slider {...sliderSettings} className="cntSlider Carousel">
                      {imageUrlCarousel(htl.cont.image).map((img,index) => (
                        <div key={index}>
                        <img
                          src={img}
                          style={contentStyle}
                          title={htl.cont.catnm}
                          onClick={
                            searchReq &&
                            searchReq.package !== undefined &&
                            searchReq.package === 1
                              ? chooseRoom(htl.pid, true, htl.hs)
                              : chooseRoom(htl.pid, false, htl.hs)
                          }
                        />
                      </div>
                      ))}
                      </Slider>
                    </div>
                  ):(
                    <div className="empty-image">
                      {context.translation && context.translation.noimage}
                    </div>
                  )}
                  </> :
                  <>
                  {htl.cont_ar !== undefined && htl.cont_ar.image !== undefined ? (
                    <div onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
                      <Slider {...sliderSettings} className="cntSlider Carousel">
                      {imageUrlCarousel(htl.cont_ar.image).map((img,index) => (
                        <div key={index}>
                        <img
                          src={img}
                          style={contentStyle}
                          title={htl.cont_ar.catnm}
                          onClick={
                            searchReq &&
                            searchReq.package !== undefined &&
                            searchReq.package === 1
                              ? chooseRoom(htl.pid, true, htl.hs)
                              : chooseRoom(htl.pid, false, htl.hs)
                          }
                        />
                      </div>
                      ))}
                      </Slider>
                    </div>
                  ):(
                    <div className="empty-image">
                      {context.translation && context.translation.noimage}
                    </div>
                  )}
                  </>
                }
              </div>
              <div className="fullWidth">
                <div className="hotelDetails">
                {context.selectLang == undefined || context.selectLang === "en" ? 
                <>
                {htl.cont.urat !== "" &&<>
                  <div className="hotelReviews onlyFlex">
                   
                    <div className="rating">
                      <span>
                        <i className="icon icon-leaf"></i>
                      </span>
                      {htl.cont.urat}
                      <span className="right">
                        <i className="icon icon-leaf"></i>
                      </span>
                    </div>
                    
                      <Title level={5} className="excellent">
                        {htl.usrst}
                      </Title>
                      {htl.cont.expediareview !== 0 && (
                        <>
                          <Title level={5} className="reviews" onClick={showReviewsPopup}>
                            <img src={ExpediaLogo} className="expediaLogo" />{" "}
                            <span>{htl.cont.expediareview} {context.translation && context.translation.reviews}</span>
                          </Title>
                          <Modal
                            title="Reviews and Ratings"
                            style={{ top: 20 }}
                            open={isModalOpen}
                            onCancel={handleCancel}
                            footer={null}
                            className="reviewsResults"
                            // maskClosable={false}
                            width={1000}
                            centered
                          >
                            <small>
                            <i
                              className="icon icon-cancel"                              
                              style={{position: "absolute", top: "22px", right: "26px"}}
                              onClick={() => {
                                handleCancel();
                              }}
                            ></i>
                            </small>
                            <HotelReviewsAndRatings reviewObj={htl.cont.guestreview} reviewCount={htl.cont.expediareview} urat={htl.cont.urat} pid={htl.cont.pid} page={"SRP"} />
                          </Modal>
                        </>
                      )}
                  </div>
                  </>}
                  </> :
                  <>
                  {htl.cont_ar.urat !== "" &&<>
                  <div className="hotelReviews onlyFlex">
                   
                    <div className="rating">
                      <span>
                        <i className="icon icon-leaf"></i>
                      </span>
                      {htl.cont_ar.urat}
                      <span className="right">
                        <i className="icon icon-leaf"></i>
                      </span>
                    </div>
                    
                      <Title level={5} className="excellent">
                        {htl.usrst}
                      </Title>
                      {htl.cont_ar.expediareview !== 0 && (
                        <>
                          <Title level={5} className="reviews" onClick={showReviewsPopup}>
                            <img src={ExpediaLogo} className="expediaLogo" />{" "}
                            <span>{htl.cont_ar.expediareview} {context.translation && context.translation.reviews}</span>
                          </Title>
                          <Modal
                            title="Reviews and Ratings"
                            style={{ top: 20 }}
                            open={isModalOpen}
                            onCancel={handleCancel}
                            footer={null}
                            className="reviewsResults"
                            // maskClosable={false}
                            width={1000}
                            centered
                          >
                            <small>
                            <i
                              className="icon icon-cancel"                              
                              style={{position: "absolute", top: "22px", right: "26px"}}
                              onClick={() => {
                                handleCancel();
                              }}
                            ></i>
                            </small>
                            <HotelReviewsAndRatings reviewObj={htl.cont_ar.guestreview} reviewCount={htl.cont_ar.expediareview} urat={htl.cont_ar.urat} pid={htl.cont_ar.pid} page={"SRP"} />
                          </Modal>
                        </>
                      )}
                  </div>
                  </>}
                  </>}
                  <div className="">
                    <Title level={4} className="hotelName">
                      <Link
                        onClick={
                          searchReq &&
                          searchReq.package !== undefined &&
                          searchReq.package === 1
                            ? chooseRoom(htl.pid, true, htl.hs)
                            : chooseRoom(htl.pid, false, htl.hs)
                        }
                      >
                        {context.selectLang == undefined || context.selectLang === "en" ? htl.cont.name : htl.cont_ar.name}
                      </Link>
                      <span className="hotelRating">
                      {context.selectLang == undefined || context.selectLang === "en" ? <>
                        {htl.cont.ratings !== undefined &&
                          htl.cont.ratings !== 0 && (
                            <>
                            {Array(Math.ceil(htl.cont.ratings)).fill("").map((_, i) => <i key={i} className="icon icon-star"></i>)}
                            </>
                          )}
                        </> : <>
                        {htl.cont_ar.ratings !== undefined &&
                          htl.cont_ar.ratings !== 0 && (
                            <>
                            {Array(Math.ceil(htl.cont_ar.ratings)).fill("").map((_, i) => <i key={i} className="icon icon-star"></i>)}
                            </>
                          )}
                        </>
                      }
                      </span>
                    </Title>
                    {context.selectLang == undefined || context.selectLang === "en" ? <>
                      {htl.cont !== undefined && htl.cont.city !== undefined && (
                        <div className="propertyAdress">
                          <span className="ezyIcon map icon-map"></span>
                          <Title level={5} className="hotelAddress">
                            {htl.cont.line1}, {htl.cont.city},{" "}
                            {htl.cont.countryCode}
                          </Title>
                        </div>
                      )}
                    </> : <>
                      {htl.cont_ar !== undefined && htl.cont_ar.city !== undefined && (
                        <div className="propertyAdress">
                          <span className="ezyIcon map icon-map"></span>
                          <Title level={5} className="hotelAddress">
                            {htl.cont_ar.line1}, {htl.cont_ar.city},{" "}
                            {htl.cont_ar.countryCode}
                          </Title>
                        </div>
                      )}
                    </>
                    }
                    
                    {htl.cont.nearby !== undefined && (
                      <Title level={5} className="nearestLandmark">
                        <i className="icon icon-pin"></i> {htl.cont.nearbyadd !== undefined && htl.cont.nearbyadd} {htl.cont.nearby} {htl.cont.nearbykm !== undefined && htl.cont.nearbykm}
                      </Title>
                    )}
                    {/* <div className="metroTaxi">
                      <span className="metro">
                        <i className="icon icon-metro"></i> 15 Mins
                      </span>
                      <span className="taxi">
                        <i className="icon icon-taxi"></i> 8 Mins (8 Euro)
                      </span>
                    </div> */}
                  </div>
                </div>
                <div className="hotelAmenities">
                  <span className="roomOnly">
                    <i className="icon icon-room-only"></i>
                    {htl.brdbs.toString()}
                  </span>

                  {htl.cont.ha !== undefined && htl.cont.ha.length !== 0 && (
                    <>
                      <span className="hotelAmenitiesDivider"></span>
                      <span className="iconsOnly">
                        {htl.cont.ha.includes("WIFI") && (
                          <Tooltip placement="bottom" title={context.translation && context.translation.wifi}>
                            <i className="icon icon-wifi"></i>
                          </Tooltip>
                        )}
                        {htl.cont.ha.includes("GYM") && (
                          <Tooltip placement="bottom" title={context.translation && context.translation.gym}>
                            <i className="icon icon-gym"></i>
                          </Tooltip>
                        )}
                        {htl.cont.ha.includes("BAR") && (
                          <Tooltip placement="bottom" title={context.translation && context.translation.bar}>
                            <i className="icon icon-bar"></i>
                          </Tooltip>
                        )}
                        {htl.cont.ha.includes("POOL") && (
                          <Tooltip placement="bottom" title={context.translation && context.translation.swgpool}>
                            <i className="icon icon-swimming"></i>
                          </Tooltip>
                        )}
                      </span>
                    </>
                  )}

                  {htl.refundable && (
                    <>
                      <span className="hotelAmenitiesDivider"></span>
                      <span className="cancelOnly">
                        <i className="icon icon-tick1"></i>

                        <> {context.translation && context.translation.freeCan}</>
                      </span>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="hotelContRight">
              
                  <Modal title="" onOk={closeShare} onCancel={closeShare} centered open={loadShortUrl}
                      footer={null}  className="srpSharePopup itineraryPopup">
                        <small>
                         <i
                          className="icon icon-cancel"
                          onClick={() => {
                            closeShare();
                          }}
                        ></i>
                          <Title level={4} className="shareItienaryTitle">
                          {context.translation && context.translation.shrThsIti}
                        </Title>
                        <Space direction="horizontal" className="displayNone-640">
                          <Input disabled
                            className="shareItienaryInput"
                            placeholder={shortUrl}
                          />
                          <Button onClick={handleCopy} type="primary" className="cntBtn smallCntBtn">
                            {copied?(context.translation && context.translation.copied):(context.translation && context.translation.copy)}
                          </Button>
                        </Space>
                        <div className="displayNoneLG copyLinkText" onClick={handleCopy}> <img src={Shareicon}/> &nbsp;{copied?(context.translation && context.translation.copied):(context.translation && context.translation.copy)} link</div>
                        <div className="socialMediaWrap">
                          <WhatsappShareButton url={shortUrl}>
                          <img src={Whatsapp} alt="social-media-icon" />
                          <span>{context.translation && context.translation.whtap}</span>
                          </WhatsappShareButton>
                        </div>
                        </small>
                  </Modal>
                      <Tooltip placement="top" title={context.translation && context.translation.share}>
                        <i onClick={() => {
                          openShare();
                          }} 
                          className="icon icon-share">
                        </i>
                      </Tooltip>
              {htl.disc !== "0" && htl.disc !== 0 && (
                <div className="hotDealOffer">
                  <i className="icon icon-hot-deal"></i>
                  <span>{context.translation && context.translation.hotDeal}</span>
                </div>
              )}
              <div className="hpriceWrap">
                <div className="hpriceSubWrap">
                  <del>
                    <span className="countryCode">{cur} </span> <FormatCurrency value={parseFloat(htl.rdwtf) * base_usr_exrate} className="currencyAmount"/>
                  </del>
                  <Title level={5} className="finalAmount">
                    <span className="countryCode">{cur} </span> <FormatCurrency value={parseFloat(htl.rtf) * base_usr_exrate} />
                  </Title>
                  <span className="travellerPrice">
                    ({context.translation && context.translation.totalPrice} {request.nights} {context.translation && context.translation.nights})
                  </span>
                </div>
                <Button
                      onClick={searchReq.package!==undefined && searchReq.package===1?chooseRoom(htl.pid,true):chooseRoom(htl.pid,false,htl.hs)}
                      className="cntBtn hotelBtn"
                      type="primary">
                      {context.translation && context.translation.chooseRoom}
                      <img src={HotelBrandIcon} alt="hotelBrandIcon" className="hotelBrandIcon" />
                      {/* <i className="icon icon-H-logo"></i> */}
                </Button>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
}

export default HotelResultsBlock;
