import React, { useState ,useEffect, useContext } from "react";
import { Context } from "../../App";
import { Select} from "antd";

function Years(props) {
  const [context, setContext] = useContext(Context)
    const [year,setyear] = useState("")
    const [yearsAdultList,setAearsAdultList]=useState([])
    const { Option } = Select;
    const currentYear = new Date().getFullYear();
    const currentYearAdult = new Date().getFullYear() - 12;
    const currentYearHtlAdult = new Date().getFullYear() - 18;
    const currentYearChild = new Date().getFullYear() - 2;
    const yearsRange = 80;
    const infDobyearsRange = 3;
    const childDobYearsRange = 11;
    const htlChildDobYearsRange = 18;
    const htlAdultDobYearsRange = 62;
    const years = Array.from(
      { length: yearsRange },
      (_, index) => currentYear - index
    );
    const yearsAdult = Array.from(
      { length: yearsRange },
      (_, index) => currentYearAdult - index
    );
    const yearsChild = Array.from(
      { length: childDobYearsRange },
      (_, index) => currentYearChild - index
    );
    const yearsHtlAdult = Array.from(
      { length: htlAdultDobYearsRange },
      (_, index) => currentYearHtlAdult - index
    );
    const yearsHtlChild = Array.from(
      { length: htlChildDobYearsRange },
      (_, index) => currentYear - index
    );
    const expiryYears = Array.from(
      { length: yearsRange },
      (_, index) => currentYear + index
    );
    const infDobYears = Array.from(
      { length: infDobyearsRange },
      (_, index) => currentYear - index
    );

    useEffect(() => {
      setyear(props.updateYear)
    }, [props.updateYear]);

      const updateYear=(month)=>{
        setyear(month)
        props.sendYear(month)
      }

  return (
    <>
    {props.type === "ADT" && (
     <Select
        name="year"
        defaultValue={context.translation && context.translation.year}
        value={year?year:undefined}
        onChange={(value) =>updateYear(value) }
        placeholder={context.translation && context.translation.year}
        popupClassName="yearDpDown"
        >
          <Option value="Year">{context.translation && context.translation.year}</Option>
        {yearsAdult.map((year) => (
          <Option key={year} value={year}>
            {year}
          </Option>
        ))}
        
      </Select>
      
      )
      }    

      {props.type === "CHD" && (
      <Select
        name="year"
        defaultValue={context.translation && context.translation.year}
        value={year?year:undefined}
        onChange={(value) =>updateYear(value) }
        placeholder={context.translation && context.translation.year}
        popupClassName="yearDpDown"
        >
          <Option value="Year">{context.translation && context.translation.year}</Option>
        {yearsChild.map((year) => (
          <Option key={year} value={year}>
            {year}
          </Option>
        ))}
      </Select>)
      }    

      {props.type === "HTLADT" && (
       <Select
          name="year"
          defaultValue={context.translation && context.translation.year}
          value={year?year:undefined}
          onChange={(value) =>updateYear(value) }
          placeholder={context.translation && context.translation.year}
          popupClassName="yearDpDown"
          >
            <Option value="Year">{context.translation && context.translation.year}</Option>
          {yearsHtlAdult.map((year) => (
            <Option key={year} value={year}>
              {year}
            </Option>
          ))}
          
        </Select>
        
        )
        }     

      {props.type === "HTLCHD" && (
      <Select
        name="year"
        defaultValue={context.translation && context.translation.year}
        value={year?year:undefined}
        onChange={(value) =>updateYear(value) }
        placeholder={context.translation && context.translation.year}
        popupClassName="yearDpDown"
        >
          <Option value="Year">{context.translation && context.translation.year}</Option>
        {yearsHtlChild.map((year) => (
          <Option key={year} value={year}>
            {year}
          </Option>
        ))}
      </Select>)
      }   
      
      {props.type === "Inf" && (
      <Select
        name="year"
        defaultValue={context.translation && context.translation.year}
        value={year?year:undefined}
        onChange={(value) =>updateYear(value) }
        placeholder={context.translation && context.translation.year}
        popupClassName="yearDpDown"
        >
          <Option value="Year">{context.translation && context.translation.year}</Option>
        {infDobYears.map((year) => (
          <Option key={year} value={year}>
            {year}
          </Option>
        ))}
      </Select>)
      }    

      {props.type === "isuDate" && (
       <Select
        name="year"
        defaultValue={context.translation && context.translation.year}
        value={year?year:undefined}
        onChange={(value) =>updateYear(value) }
        placeholder={context.translation && context.translation.year}
        popupClassName="yearDpDown"
        >
          <Option value="Year">{context.translation && context.translation.year}</Option>
        {years.map((year) => (
          <Option key={year} value={year}>
            {year}
          </Option>
        ))}
      </Select>)
      }      
      
      {props.type === "expDate" && (
       <Select
        name="year"
        defaultValue={context.translation && context.translation.year}
        value={year?year:undefined}
        onChange={(value) =>updateYear(value) }
        placeholder={context.translation && context.translation.year}
        popupClassName="yearDpDown"
        >
          <Option value="Year">{context.translation && context.translation.year}</Option>
        {expiryYears.map((year) => (
          <Option key={year} value={year}>
            {year}
          </Option>
        ))}
      </Select>)
      }                                        
    </>
  );
}
export default Years;
