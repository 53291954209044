import React, {useState,useEffect} from "react";
import useGeoLocation from "react-ipgeolocation";
import Router from "./router/Router";
import ApiError from "./components/common/ApiError";
import Maintenance from "./components/common/Maintenance";
import NetworkError from "./components/common/NetworkError";
export const AirlinesContext = React.createContext();
export const Context = React.createContext();
export const ExchangeRates = React.createContext();
let airportsData = require("./components/bookingflow/flights/common/masterData/AirlinesMasterData.json");
export const REGION_COUNTRY = "REGION_COUNTRY";

const App = () => {
  //const location = useGeoLocation();
  const [isOffline, setIsOffline] = useState(!navigator.onLine);
  const [apiFail, setApiFail] = useState(false);
  const [maintenance, setMaintenance] = useState(false);
  const [context, setContext] = useState({user_resp:"",mostFlownRoutes:"", alert:"", selectLang:"en", translation:"", popularHotels:"",userCur:"",regionCur:"",ex_rates:"",schedular:""});
  // if (
  //   location !== undefined &&
  //   location.country !== undefined &&
  //   localStorage.getItem(REGION_COUNTRY) === null
  // ) {
  //   localStorage.setItem(REGION_COUNTRY, location.country);
  // }
  localStorage.setItem(REGION_COUNTRY, "QA");
  let flow_lng=sessionStorage.getItem("SEL_LAG");
  if (flow_lng && flow_lng === "Arabic") {
    document.body.dir = 'rtl';
    document.body.classList.add('arabic')
    document.body.classList.remove('english')
  } else {
    document.body.dir = 'ltr';
    document.body.classList.remove('arabic')
    document.body.classList.add('english')
  }

  let English = require("./lang/en.json");
  let Arabic = require("./lang/ar.json");
  useEffect(() => {
    if(window.location.pathname === "/" || window.location.pathname === "/en" || window.location.pathname === "/ar") {
      window.addEventListener("beforeunload", sessionStorage.setItem("SEL_TAB", "Flights"));
    }
    if(window.location.pathname === "/en") {
      setContext({...context, translation:English, selectLang: "en"})
      sessionStorage.setItem("SEL_LAG", "English");
    } else if(window.location.pathname === "/ar") {
      setContext({...context, translation:Arabic, selectLang: "ar"})
      sessionStorage.setItem("SEL_LAG", "Arabic");
    }
  }, []);

  useEffect(() => {
    const handleOffline = () => {
      setIsOffline(true);
    };

    const handleOnline = () => {
      setIsOffline(false);
    };

    window.addEventListener('offline', handleOffline);
    window.addEventListener('online', handleOnline);

    return () => {
      window.removeEventListener('offline', handleOffline);
      window.removeEventListener('online', handleOnline);
    };
  }, []);
  
  useEffect(() => {
    if(sessionStorage.getItem("APIFAILED") === "no-response") {
      setApiFail(true);
    } else if(sessionStorage.getItem("APIFAILED") === "response") {
      setApiFail(false);
    }
    if(sessionStorage.getItem("MAINTENANCE") === "no-response") { 
      setMaintenance(true)
    } else if(sessionStorage.getItem("MAINTENANCE") === "response") { 
      setMaintenance(false)
    }
  }, [sessionStorage.getItem("APIFAILED"), sessionStorage.getItem("MAINTENANCE")])

  return (
    <div>
      {isOffline ? (
        <NetworkError context={context} />
      ) : apiFail ? (
        <ApiError context={context} />
      ) : maintenance ? (
        <Maintenance context={context} />
      ) : (
        <div className="cnt">
          <AirlinesContext.Provider value={airportsData}>
          <Context.Provider value={[context, setContext]}>
          <Router/>
          </Context.Provider>
          </AirlinesContext.Provider>
        </div>
      )}
    </div>
  );
};

export default App;
