import { useState, useEffect,useContext } from "react";
import { Context } from "../../App";
import { Checkbox, Modal,Row,Col,Typography,Form,Input,Alert,Button} from "antd";
import { Link } from "react-router-dom";
import PaymentService from "../../services/common/PaymentService";
import FlightBookService from "../../services/flights/FlightBookService";
// import FormatCurrency from "../utilities/FormatCurrency";
import LoaderN from "../../components/common/loaders/LoaderN";
import HotelBookingService from "../../services/hotels/HotelBookingService";
import { Frames, CardNumber, ExpiryDate, Cvv } from "frames-react";
import { useNavigate, useLocation } from "react-router-dom";
// import Item from "antd/es/list/Item";
import CryptoJS from "crypto-js";
import Utilities from "../utilities/Utilities";

const { Title } = Typography;

const PK_KEY = process.env.REACT_APP_PAY_PRIVATE_KEY;
const RETURN_URL = process.env.REACT_APP_CNT_RETURN_URL;
const BASE_CURRENCY = process.env.REACT_APP_BASE_CUR;
const SECRET_KEY = process.env.REACT_APP_PAY_CRYPTO_SECRET_KEY
/**
 * @author Lakshmi
 * @description Payment gateway integration component
 * @returns Payment related things
 * @date 20-07-2023
 */
function CheckoutPay(props) {
  //Enable 3d true/false
  const [context, setContext] = useContext(Context)
  const [enable3D, setEnable3D] = useState(true);
  const [showFailed, setShowFailed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [terms, setTerms] = useState(true);
  const [save, setSave] = useState(true);
  const [showAlert, setShowAlert] = useState(false);
  const [cardHolder, setCardHolder] = useState("");
  const [isAllFilled, setIsAllFilled] = useState(false);
  const [isCardValid, setIsCardValid] = useState(false);
  const [isExpValid, setIsExpValid] = useState(false);
  const [isCvvValid, setIsCvvValid] = useState(false);
  const [errorMsg1, setErrMsg1] = useState("");
  const [errorMsg2, setErrMsg2] = useState("");
  const [errorMsg3, setErrMsg3] = useState("");
  const [product, setProduct] = useState("");
  const [paymentReq, setPaymentReq] = useState("");
  const [errorMain, setErrorMsgMain] = useState("");
  const navigate = useNavigate();
  const [buttonLoading, setButtonLoading] = useState(false);
  const [validatePay, setValidatePay] = useState(true);
  const [checkKey, setCheckKey] = useState(false);
  const [bookingId, setBookingId] = useState("");
  const [count, setCount] = useState(() => {
    return Number(localStorage.getItem("clickCount")) || 0;
  });
  const [showrestrict, setShowRestrict] = useState(false);

  useEffect(() => {
    localStorage.setItem("clickCount", count);
    if(count === 3) {
      setShowFailed(false); 
      setShowRestrict(true);
    }
  }, [count])

  useEffect(() => {
    if (
      props.location !== undefined &&
      (props.request === undefined || props.request === "")
    ) {
      setIsLoading(true)
      if(sessionStorage.getItem("cnt_pay_request")!== null){
        let req = JSON.parse(sessionStorage.getItem("cnt_pay_request"));
        getPaymentDetails(req, "PAY");
      } else {
        getPaymentDetails(null, "CKO");
      }
    }
  }, [props.request]);

  const getPaymentDetails = (req, type) => {
    if (type === "PAY") {
      try {
        PaymentService.getPaymentInfo(req.pay_id).then((resp) => {
          if (
            resp !== undefined &&
            resp.data !== undefined &&
            resp.data.approved &&
            (resp.data.status === "Authorized" || resp.data.status === "Captured")
          ) {
            if(req.product==="Flight"){
              fireFlightBook(resp.data, req);
            }else if(req.product==="Hotel"){
              fireHotelBook(resp.data, req)
            }
          } else {
            setPaymentReq(req)
            setProduct(req.product)
            setIsLoading(false)
            setShowFailed(true);
            setButtonLoading(false);
            props.sendPayRequest(req)
          }
        }).catch(function (error) {
          console.error("Error in Payment" + error);       
        });   
      } catch (error) {
        console.error("Error in Payment" + error);           
      }
    }else{
        let urlAray=window.location.href.split("=");
        try {
          let payload = {"cko_session_id" :urlAray[1]}
          let tokenKey = Utilities.generateAPIToken(payload);
          FlightBookService.getPayResponse(payload,tokenKey).then((resp) => {
              if (resp !== undefined && resp.data.suc !== undefined) {
                  PaymentService.getPaymentInfo({"pay_id":resp.data.pay_id}).then((resp) => {
                      if (
                        resp !== undefined &&
                        resp.status === "Authorized" &&
                        resp.approved
                      ) {
                        if(req.product==="Flight"){
                          fireFlightBook(resp,req);
                        }else if(req.product==="Hotel"){
                          fireHotelBook(resp,req)
                        }
                      } else {
                        setIsLoading(false)
                        setShowFailed(true);
                        setButtonLoading(false);
                      }
                    }).catch(function (error) {
                      console.error("Error:" + error);      
                    });
              } else {
                 setProduct(req.product)
                 setIsLoading(false)
                 setShowFailed(true);
                 setButtonLoading(false);
              }
            }).catch(function (error) {
              console.error("Error in Payment" + error);      
            }); 
        } catch (error) {
          console.error("Error in Payment" + error);             
        }
    }
  };

  const paymentInit = (e) => {
    setShowAlert(false)
    //if(terms){
    setShowFailed(false);
    setIsLoading(true);
    let finalPrice = 0;
    if(props.request != undefined) {
      finalPrice=(props.request?.total_price.toFixed(2) * props.request?.base_usr_exrate) * 100;
    }
    try {
      let payload = {
        source: {
          type: "token",
          token: e.token,
        },
        amount: finalPrice.toFixed(0),
        currency: props.request.cur,
        "3ds": {
          enabled: enable3D,
        },
        capture: true,
        success_url: RETURN_URL,
        failure_url: RETURN_URL,
      };

      var dataToEncrypt = props.request.bookingId+"_"+props.request.product+"_"+props.request.total_price.toFixed(2)+"_"+finalPrice.toFixed(5);
      var encryptedData = CryptoJS.AES.encrypt(dataToEncrypt, SECRET_KEY).toString();
      // validate payment amount before payment API call
      const payload1 = {
        token: encryptedData
      }
      let tokenKey = Utilities.generateAPIToken(payload1);
      PaymentService.validatePay(payload1,tokenKey).then((response) => {
        if (response?.data?.suc) {
          PaymentService.callCheckoutPay(payload).then((response) => {
            if (response && response.data) {
              let resp = response.data;
              if (
                resp._links &&
                resp._links.redirect &&
                resp._links.redirect.href
              ) {
                let req = props.request;
                if(req.pr.ttf!==undefined && req.pr.ttf.toString()==="NaN"){
                  req.pr.ttf=0;
                }
                req.pay_req = payload;
                req.pay_resp = resp;
                req.pay_id = resp.id;
                let sessionAry=resp._links.redirect.href.split("sessions-interceptor")
                req.cko_session_id=sessionAry[1]?.replace(/^\/|\/$/g, '');
                req.cname=cardHolder;
                sessionStorage.setItem("tid", req.tid);
                sessionStorage.setItem("cnt_pay_request", JSON.stringify(req));
                window.location.href = resp._links.redirect.href;
                let tokenKey = Utilities.generateAPIToken(req);
                FlightBookService.savePayRequest(req,tokenKey).then((res) => {
                  window.location.href = resp._links.redirect.href;
                }).catch(function (error) {
                  if(error?.response?.data==="Unauthorized"){
                    setButtonLoading(false);
                    setShowFailed(true) 
                    setIsLoading(false)
                  }
                  console.error("Error:" + error);      
                });
              } else {
                if(resp.approved){
                let req =props.request;
                if(req.pr.ttf!==undefined && req.pr.ttf.toString()==="NaN"){
                  req.pr.ttf=0;
                }
                req.pay_req = payload;
                req.resp=resp;
                req.cko_session_id=props.request.tid;
                req.cname=cardHolder;
                sessionStorage.setItem("tid", props.request.tid);
                let tokenKey = Utilities.generateAPIToken(req);
                FlightBookService.savePayRequest(req,tokenKey).then((res) => {
                  if(props.request && props.request.product && props.request.product==="Flight"){
                    sessionStorage.setItem("tid", props.request.tid);
                    fireFlightBook(resp,props.request);
                    }else if(props.request && props.request.product && props.request.product==="Hotel"){
                    sessionStorage.setItem("tid", props.request.hid);
                    fireHotelBook(resp,props.request)
                    } 
                }).catch(function (error) {
                  if(error?.response?.data==="Unauthorized"){
                    setButtonLoading(false);
                    setShowFailed(true) 
                    setIsLoading(false)
                  } 
                });
                }else{
                setButtonLoading(false);
                setShowFailed(true) 
                setIsLoading(false)
                }
              }
            } else {
              setButtonLoading(false);
              setShowFailed(true);
              setIsLoading(false)
            }
          }).catch(function (error) {
            console.error("ERROR IN PAYMENT FLOW : " + error);      
          });
        } else {
          setIsLoading(false);
          setShowFailed(true);
          setButtonLoading(false);
          setValidatePay(false);
        }
      }).catch(function (error) {
        console.error("Error:" + error);      
      });
    } catch (err) {
      console.error("ERROR IN PAYMENT FLOW : " + err);
    }
  // }else{
  //  setShowAlert(true)
  // }
  };

  const fireFlightBook = (payresp, request) => {
    let payload = createPayPayload(payresp, request);
    try {
      let tokenKey = Utilities.generateAPIToken(payload);
      FlightBookService.fireBookReq(payload,tokenKey).then((response) => {
        sessionStorage.removeItem("tid");
        sessionStorage.removeItem("cnt_pay_request");
        setIsLoading(false);
        if (response.data!==undefined && response.data.bookRs !== undefined && !response.data.bookRs.pay_err_status) {
          setButtonLoading(false);
          let bookResp =response.data.bookRs.data[0];
          if(props.request !== undefined) {
            bookResp.base_usr_exrate = props.request?.base_usr_exrate;
          } else if(props.base_usr_exrate !== undefined && props.base_usr_exrate !== "" && props.base_usr_exrate !== 0) {
            bookResp.base_usr_exrate = props.base_usr_exrate;
          }
          navigate("/flight/confirmation", {
            state: {
              resp: bookResp
            },
          });
        }else if(response.data.bookRs.pay_err_status){
          setButtonLoading(false);
          setShowFailed(true) 
          setIsLoading(false)
        }
      }).catch(function (error) {
        if(error?.response?.data==="Unauthorized"){
          setCheckKey(true);
          setIsLoading(false);
        }
        console.error("Error in book" + error);      
      });      
    } catch (error) {
      console.error("Error in book" + error);       
      if(error?.response?.data==="Unauthorized"){
        setCheckKey(true);
        setIsLoading(false);
      }  
    }
  };

  const fireHotelBook = (payresp, payreq) => {
    window.sessionStorage.removeItem("BOOKING_REF_NO");
    setBookingId(props.request!==undefined?props.request.bookingId:payreq.bookingId);
       let holdReq = { 
        "bookId": props.request!==undefined?props.request.bookingId:payreq.bookingId,  
        "hid": props.request!==undefined?props.request.hid:payreq.hid , 
        "region_type":props.request!==undefined?props.request.region_type:payreq.region_type,
        "req_cur":BASE_CURRENCY,
        "cur":props.request!==undefined?props.request.req_cur:payreq.req_cur,
        "pay": [
          {
            ptype: payresp.source.card_type,
            amt: payresp.amount,
            ccode: payresp.currency,
            psta: payresp.status,
            pgauthid: payresp.auth_code,
            pgtid: payresp.source.payment_account_reference,
            pay_id: payresp.id,
            pgstc: payresp.response_code,
            ctype: payresp.source.scheme,
            cno: payresp.source.last4,
            cname: cardHolder,
            cexp: payresp.source.expiry_month + "/" + payresp.source.expiry_year,
          },
      ]
      }
      try {
        let token = Utilities.generateAPIToken(holdReq);
       HotelBookingService.fireHoldBooking(holdReq,token).then((response) => {
        if(response.data.suc && response.data.data.st == "success"){
         let bookReq = createBookRequest(payresp, payreq);
         let tokenKey = Utilities.generateAPIToken(bookReq);
          HotelBookingService.fireBook(bookReq,tokenKey).then((bresp) => {
            if(bresp.data !== undefined) {
             setButtonLoading(false);
             let bookResp = bresp.data.data;
             if(props.request !== undefined) {
               bookResp.base_usr_exrate = props.request?.base_usr_exrate;
             } else if(props.base_usr_exrate !== undefined && props.base_usr_exrate !== "" && props.base_usr_exrate !== 0) {
               bookResp.base_usr_exrate = props.base_usr_exrate;
             }
             navigate("/hotel/confirmation", {
               state: {
                 resp: bookResp
               },
             });
            }
          }).catch(function (error) {
            if(error?.response?.data==="Unauthorized"){
              setCheckKey(true);
              setIsLoading(false);
            }
            console.error("Error:" + error);      
          });  
        }else{
         //show message
        }
      }).catch(function (error) {
        if(error?.response?.data==="Unauthorized"){
          setCheckKey(true);
          setIsLoading(false);
        }
        console.error("Error in Confirm" + error);      
      });
      } catch (error) {
        if(error?.response?.data==="Unauthorized"){
          setCheckKey(true);
          setIsLoading(false);
        }
        console.error("Error in Confirm" + error);           
      }
   }
 
   //Creating Book request
  const createBookRequest = (payresp, payreq) => {
      const payload = {
        "hid": payreq.hid,
        "bookId": payreq.bookingId,
        "cha": "B2C",
        "cur": payreq.cur,
        "bktype": "DIRECT",
        "uid": payreq.user_id,
        "region_type": payreq.region_type
      }
      return payload;
  }

  const createPayPayload = (payresp, request) => {
    setBookingId(request.bookingId);
    const payload = {
      tid: request.tid,
      bookId: request.bookingId,
      cha: "B2C",
      cur: request.cur,
      sty: request.sty,
      tt: request.tt,
      office_id: request.office_id,
      offline: sessionStorage.getItem("OFFLINE"),
      service_fee:request.servicefee!==undefined && request.servicefee.length >0?request.servicefee:null,
      bktype: "DIRECT",
      plb: request.plb,
      tkt_user_type: request.user_type,
      tkt_user_id: request.user_id,
      sup:request.sup,
      otCharge: request.otCharge!==undefined?request.otCharge:0,
      region_type: request.region_type,
      req_cur: BASE_CURRENCY,
      region_cur:request.region_cur,
      promo_code:request.promo_code,
      promo_disc:request.promo_disc!==undefined?request.promo_disc:0,
      promo_id:request.promo_id,
      promo_remarks:request.promo_remarks,
      pax_pdisc:request.pax_pdisc!==undefined?request.pax_pdisc:0,
      pay: [
        {
          ptype: payresp.source.card_type!==undefined?payresp.source.card_type:"",
          amt:request.total_price!==undefined && request.total_price!==0?request.total_price:payresp.amount,
          ccode: payresp.currency,
          psta: payresp.status!==undefined?payresp.status:"",
          pgauthid: payresp.auth_code!==undefined?payresp.auth_code:"",
          pgtid: payresp.source.payment_account_reference!==undefined?payresp.source.payment_account_reference:"",
          pay_id: payresp.id!==undefined?payresp.id:"",
          pgstc: payresp.response_code!==undefined?payresp.response_code:"",
          ctype: payresp.source.scheme!==undefined?payresp.source.scheme:"",
          cno: payresp.source.last4!==undefined?payresp.source.last4:"",
          cname: "",
          cexp: payresp.source.expiry_month + "/" + payresp.source.expiry_year,
        },
      ],
    };
    return payload;
  };
  
  const retryPay = () => {
    setCount(prevCount => prevCount + 1);
    setShowFailed(false)
  };

  const retryPayment = () => {
    setCount(prevCount => prevCount + 1);
    window.location = "/payment";
  };

  const getoHome = () => {
    window.location = "/";
  };

  const gotoSRP = () => {
    localStorage.removeItem("clickCount");
    if(props.request!==undefined && props.request.product && props.request.product==="Flight") {
      if(sessionStorage.getItem("FLTLONGURL") !== null){
        const url = sessionStorage.getItem("FLTLONGURL").split("?")[1];
        navigate("/flight/results?"+url);
      }
    } else if(props.request!==undefined && props.request.product && props.request.product==="Hotel") {
      if(sessionStorage.getItem("HTLLONGURL") !== null){
        const url = sessionStorage.getItem("HTLLONGURL").split("?")[1];
        navigate("/hotel/results?"+url);
      }
    }
  }

  const handleChange=()=>{
    if(terms){
      setTerms(false)
    }else{
      setTerms(true)
    }
  }

  const handleName =(e) => {
    setCardHolder(e.target.value?.replace(/[^a-z]/gi, ' '))
  }

  const handleSave=()=>{
    if(save){
      setSave(false)
    }else{
      setSave(true)
    }
  }
  const close=()=>{
    setShowAlert(false);
    setErrorMsgMain("")
  }
  
  const hidemessage = () => {
    setTimeout(function () {
      setShowAlert(false);
      setErrorMsgMain("");
    }, 3000);
  };

  return (
    <div className="App paymentWrap">
      {showAlert && <Alert message={errorMain} onClick={close} closable type="error" className="alertPayment" />}
      {isLoading && (
        <LoaderN
          title={context.translation && context.translation.pleaseWait}
          subtitle={context.translation && context.translation.notRefresh}
        />
      )}
      {props.request !== undefined && props.request !== undefined && (
        <Frames
          config={{
            debug: true,
            publicKey: PK_KEY,
            localization: {
              cardNumberPlaceholder: (context.translation && context.translation.ecn),
              expiryMonthPlaceholder: (context.translation && context.translation.mm),
              expiryYearPlaceholder: (context.translation && context.translation.yy),
              cvvPlaceholder: (context.translation && context.translation.cvv),
            },
            style: {
              // base: {
              //   fontSize: "14px",
              //   color: "#888",
              //   border: "1px solid #C6C6C6",
              //   borderRadius: "8px",
              //   padding: "12px",
              //   width: "380px",
              //   height: "43px",
              // },
            },
            // invalid: {
            //   color: "red",
            // },
          }}
          ready={(e) => {}}
          frameActivated={(e) => {}}
          frameFocus={(e) => {}}
          frameBlur={(e) => {}}
          frameValidationChanged={(e) => { 
            setShowAlert(false);
            setErrMsg1("");
            setErrMsg2("");
            setErrMsg3("");
            if(e.element === "card-number"){
                if(e.isValid){
                   setIsCardValid(true);
                }else{
                  setIsCardValid(false);
                  setErrMsg1(context.translation && context.translation.invaildCardnum)
                }
            }
            if(e.element === "expiry-date"){
              if(e.isValid){
                 setIsExpValid(true)
              }else{
                setIsExpValid(false);
                setErrMsg2(context.translation && context.translation.invalidExpirydate)
              }
            }
            if(e.element === "cvv"){
              if(e.isValid){
                 setIsCvvValid(true);
              }else{
                setIsCvvValid(false);
                setErrMsg3(context.translation && context.translation.invalidcvv)
              }
          }
          }}
          paymentMethodChanged={(e) => {}}
          cardValidationChanged={(e) => { 
            setErrMsg1("");
            setErrMsg2("");
            setErrMsg3("");
            if(e.isElementValid){
                if(e.isValid && e.isElementValid.cardNumber && e.isElementValid.expiryDate && e.isElementValid.cvv){
                  setIsAllFilled(true);
                } else {
                  setIsAllFilled(false);
                }
            }
          }}
          cardSubmitted={() => {}}
          cardTokenized={(e) => {
            paymentInit(e);
          }}
          cardTokenizationFailed={(e) => {}}
          cardBinChanged={(e) => {}}
        >
          <div className="paymentDetailsWrapper">
              <Row gutter={20} className="paymentDetailsSubWrapper">
                <Col span={5} className="paymentOptionWrap">
                  <div className="paymentOption">
                    <ul>
                      <li>
                        <Link className="active">
                          {context.translation && context.translation.cdc} <i className="icon icon-down-arrow"></i>
                        </Link>
                      </li>
                      {/* <li>
                        <Link>
                          Net Banking <i className="icon icon-down-arrow"></i>
                        </Link>
                      </li>
                      <li>
                        <Link>
                          Wallets <i className="icon icon-down-arrow"></i>
                        </Link>
                      </li>
                      <li>
                        <Link>
                          UPI <i className="icon icon-down-arrow"></i>
                        </Link>
                      </li> */}
                    </ul>
                  </div>
                </Col>
                <Col span={19} className="enterCardDetailsWrap">
                  <Row>
                    <Col span={24}>
                      <div className="enterCardDetails">{context.translation && context.translation.ecd}</div>
                    </Col>
                  </Row>
                
          <Row gutter={20}>
            <Col span={12}>
              <div className="paymentLabel">{context.translation && context.translation.cn}</div>
              <CardNumber />
              {!isCardValid &&
              <div className="error">{errorMsg1}</div>
              }
              </Col>
            <Col span={12}>
              <div className="paymentLabel">{context.translation && context.translation.chn}</div>
            <Form.Item className="cardHolder">
              <Input
                type="text"
                className="frame--activated"
                name="cardHolder"
                value={cardHolder}
                placeholder= {context.translation && context.translation.naoc}
                onChange={handleName}
              />
            </Form.Item>
            </Col>
          </Row>
          <Row gutter={20}>
            <Col span={12}>
              <div className="paymentLabel">{context.translation && context.translation.eDate}</div>
              <ExpiryDate />
              {!isExpValid &&
              <div className="error">{errorMsg2}</div>
              }
            </Col>
            <Col span={12}>
              <div className="paymentLabel">{context.translation && context.translation.cvv}</div>
              <Cvv />
              {!isCvvValid &&
              <div className="error">{errorMsg3}</div>
              }
            </Col>
          </Row>
          
          {/* <Row gutter={20} className="marginTop30">
            <Col span={24}>
            <div> */}
            
            {/* <Checkbox className="checkBoxDetails understand" 
            value={save} checked={save} onChange={handleSave}
            >
              {context.translation && context.translation.smcaptrbig} {" "}
              
            </Checkbox> */}
              {/* </div>
            </Col>
          </Row> */}
         
         
                </Col>
              </Row>
           
              </div>
              <Row justify="space-between" className="payNowbtn">
              <Col span={24} className="termsInfo">
              <div> 
            {props.request && props.request.product && props.request.product==="Flight" &&
            <Checkbox className="checkBoxDetails understand" 
            value={terms}
            onChange={handleChange}
            checked={terms}
            >
              {/* {context.translation && context.translation.iAgree} */}
              {context.translation && context.translation.Iunderstand}
              <Link
                className="knowMore"
                to={sessionStorage.getItem("SEL_LAG") === "English"||sessionStorage.getItem("SEL_LAG") === null ? "/privacy-policy":"/privacy-policy-ar"}
                 target="_blank">
                {context.translation && context.translation.ftrCmLnk2}
              </Link>
              ,&nbsp;
              {/* {context.translation && context.translation.The} */}

              <Link
                className="knowMore"
                to={sessionStorage.getItem("SEL_LAG") === "English"||sessionStorage.getItem("SEL_LAG") === null ? "/user-agreement ":"/user-agreement-ar" }
                target="_blank"
              >
                {context.translation && context.translation.Useragreement}
              </Link>
              {context.translation && context.translation.and}
              <Link
                className="knowMore"
                to={sessionStorage.getItem("SEL_LAG") === "English"||sessionStorage.getItem("SEL_LAG") === null ? "/flight/terms-conditions":"/flight/terms-conditions-ar"} 
                target="_blank">
                {context.translation && context.translation.terms}
              </Link>
              {context.translation && context.translation.clickNtravel}
            </Checkbox>
            }
            {props.request && props.request.product && props.request.product==="Hotel" &&
            <Checkbox className="checkBoxDetails understand"
            value={terms}
            onChange={handleChange}
            checked={terms}
            >
              {/* {context.translation && context.translation.iAgree} */}
              {context.translation && context.translation.Iunderstand}
              <Link
                className="knowMore"
                to={sessionStorage.getItem("SEL_LAG") === "English"||sessionStorage.getItem("SEL_LAG") === null ? "/privacy-policy":"/privacy-policy-ar"}
                 target="_blank">
                {context.translation && context.translation.ftrCmLnk2}
              </Link>
              ,&nbsp;
              {/* {context.translation && context.translation.The} */}

              <Link
                className="knowMore"
                to={sessionStorage.getItem("SEL_LAG") === "English"||sessionStorage.getItem("SEL_LAG") === null ? "/user-agreement ":"/user-agreement-ar" }
                target="_blank"
              >
                {context.translation && context.translation.Useragreement}
              </Link>
              ,&nbsp;

              <Link
                className="knowMore"
               to={sessionStorage.getItem("SEL_LAG") === "English"||sessionStorage.getItem("SEL_LAG") === null ? "/hotel/terms-conditions":"/hotel/terms-conditions-ar"}
                target="_blank">
               {context.translation && context.translation.terms}
              </Link>
              
              {context.translation && context.translation.Supp}
              {(sessionStorage.getItem("SEL_LAG") === null || sessionStorage.getItem("SEL_LAG") === "English") && (
                <a
                className="knowMore"
                // to="/supplier/terms-conditions"
                href="https://developer.expediapartnersolutions.com/terms/en"
                target="_blank"
                >
                {"Terms & Conditions"}
                </a>
              )}
              {sessionStorage.getItem("SEL_LAG") && sessionStorage.getItem("SEL_LAG") === "Arabic" && (
              <a
                className="knowMore"
                // to="/supplier/terms-conditions"
                href="https://developer.expediapartnersolutions.com/terms/ar"
                target="_blank"
              >
               {"شروط وأحكام"}
              </a>  
              )}        
              &nbsp;    
              {context.translation && context.translation.clickNtravel}
            </Checkbox>
            }
              </div>
              </Col>
              
          </Row>
          <Row>
          <Col span={24} className="paymentBtnWrap">
              <button
            type="primary"
            className="cntBtn .payBtn"
            disabled={buttonLoading}
            //disabled={true}
            onClick={(e) => {
              if(cardHolder !== "" && isAllFilled && terms){
                setIsLoading(true);
                setButtonLoading(true);
                Frames.submitCard();
              } else if (cardHolder === "") {
                setShowAlert(true)
                setErrorMsgMain(context.translation && context.translation.enterAllfields)
                hidemessage();
              } else if (!terms) {
                setShowAlert(true)
                setErrorMsgMain(context.translation && context.translation.plzSelectTnC)
                hidemessage();
              } else{
                setShowAlert(true)
                setErrorMsgMain(context.translation && context.translation.enterAllfields)
                hidemessage();
              }
              
            }}
            style={{
              background: buttonLoading ? "#ed1d24" : "#008D00",
              padding: "0 40px",
              height: "50px",
              borderRadius: "8px",
              fontSize: "16px",
              fontFamily: "var(--fontMedium)",
              color: "#fff",
              border: "none",
              cursor: "pointer",
              width: "220px"
            }}
          >
            {buttonLoading ? context?.translation?.btnProcessing : context?.translation?.payNow}
            {/* {context.translation && context.translation.payNow}  */}
            {/* {props.request.cur} */}
            {/* <FormatCurrency
              value={props.request.total_price}
              className="currencyAmount"
            /> */}
          </button>
              </Col>
          </Row>
          
        </Frames>
      )}

      {showrestrict &&
        <Modal
        title=""
        open={showrestrict}
        footer={null}
      >
        <div className="paymentFailedAlert">
            <div className="failedText">We will redirect you to the Results page since you attempted to pay up to the maximum limit but the payment did not go through.</div>
        </div>
        <div className="commonFlex">
          <Button type="primary" className="cntBtn failedBtn" onClick={gotoSRP}>
              Ok
          </Button>
        </div>
      </Modal>
      }

      {showFailed &&  (props.request && props.request.product || product!=="") &&
        <Modal
        title=""
        open={showFailed}
        footer={null}
      >
        <div className="paymentFailedAlert">
            <i className="icon icon-alert-popup"></i>
            {validatePay && (
              <div className="failedTitle">{context.translation && context.translation.payFail}</div>
            )}
            <div className="failedText">{context.translation && context.translation.plztryagain}</div>
            <div className="failedText">{context.translation && context.translation.plztry}</div>
        </div>
        <div className="commonFlex">
          <Button type="primary" className="cntBtn failedBtn" onClick={getoHome}>
              {context.translation && context.translation.backtohome}
          </Button>
          <Button type="primary" disabled={count === 3} className="cntBtn failedBtn" onClick={product && product!==""?retryPay:retryPayment}>
          {context.translation && context.translation.retrypay}  
          </Button>
        </div>
      </Modal>
      }

      {checkKey &&
        <Modal
        title=""
        open={checkKey}
        footer={null}
        className="paymentFailedPopup"
      >
        <div className="paymentFailedWrap">
          <div className="paymentFailedSubwrap">
          <Title level={3}>Booking Failed!</Title>
          <p>{context.translation && context.translation.yftwb} Ref# {bookingId!=="" && bookingId} {context.translation && context.translation.availabilityGone}</p>
          <div className="alertBookingFailed">
            {context.translation && context.translation.customerService} <a className="contactIfFailed" href="tel:+974 5572 2351"> +974 5572 2351</a> {context.translation && context.translation.orSendUs} &nbsp;
            <a className="contactIfFailed" href="mailto:support@clickntravel.com">support@clickntravel.com</a> {context.translation && context.translation.gladlyAssist}
          </div>
          <p><span className="noteText">{context.translation && context.translation.note} </span>{context.translation && context.translation.notePara}</p>
          </div>
          <div className="backToHomeBtnWrap">
          <Button type="primary" className="cntBtn backToHomeBtn" onClick={getoHome}>
              {context.translation && context.translation.backtohome}
          </Button>
          </div>
        </div>


        {/* <div className="paymentFailedAlert">
            <i className="icon icon-alert-popup"></i>
            <div>{context.translation && context.translation.bookkey}</div>
            {context.translation && context.translation.bookid}{" "+ props.request.bookingId}
        </div> */}
        
        {/* <div className="commonFlex">
          <Button type="primary" className="cntBtn failedBtn" onClick={getoHome}>
              {context.translation && context.translation.backtohome}
          </Button>
          <Button type="primary" className="cntBtn failedBtn" onClick={product && product!==""?retryPay:retryPayment}>
          {context.translation && context.translation.retrypay}  
          </Button>
        </div> */}
      </Modal>
      }
    </div>
  );
}

export default CheckoutPay;
