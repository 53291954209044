import React from 'react'
import { Context } from '../../../../App';
import { useContext } from 'react';
import HotelPaymentDetails from './HotelPaymentDetails';
import HotelRoomDetails from './HotelRoomDetails';
import HotelBookingDetails from '../common/HotelBookingDetails';
import DateUtils from "../../../utilities/DateUtils";
import Logo from '../../../../assets/svg/logo.svg'
import { Typography } from "antd";
const { Title } = Typography;

const HotelVoucherpdf = React.forwardRef((props, ref) => {
    const [context, setContext] = useContext(Context)
  return (
    <div className="pagewidth">
    <div ref={ref} className="mainLayoutWrap">        
        <div className="commonFlex">
        <img src={Logo} alt="Click N Travel" />
        {props.book_resp && (
            <div className="booking">
            <div className="dateOfBooking">
                <span className="dob">{context.translation && context.translation.dateofBok} :&nbsp;</span>
                <span className="date">
                {DateUtils.prettyDate(
                    props.book_resp.bd,
                    "ddd, DD MMM, YYYY"
                )}
                </span>
            </div>
            <div className="bookingReference">
                <span className="bReference">{context.translation && context.translation.bokRefer} :&nbsp;</span>
                <span className="reference">{props.book_resp.booking_id}</span>
            </div>
            
            </div>
        )}
        </div>
        {/* <div className="horizontalDivider"></div> */}
        <Title level={3} className='marginTop60'>{context.translation && context.translation.bokSumy}</Title>
        {props.book_resp && 
        <div className="travellerDetails">
            <HotelBookingDetails htldtls={props.book_resp.htldtls}/>
        </div>
        }
        {/* <div className="horizontalDivider"></div> */}
        <Title level={3} className="roomDetailTitle">{context.translation && context.translation.roomDetails}</Title>
        {props.book_resp && props.book_resp.rooms.map((room, index) => (
        <React.Fragment key={index}>
            {props.book_resp.roompax.map((rm, idx) => (
            <React.Fragment key={idx}>
                {room.rmindex === rm.rmindex &&
                <div>
                    <div className="guestsWrapper">
                    <div className="roomWrapr">{context.translation && context.translation.room} {room.rmindex}: {room.rnm}</div>
                    <div className="travellersWrapper">
                        <span className="travellersTitle">{context.translation && context.translation.guests} :</span>
                        {rm.pax.map((pax, index) => (
                        <React.Fragment key={index}>
                        <span className="travellersDetail">
                        <span><i className="icon icon-tick"></i>{pax.tl} {pax.fn} {pax.ln} ({pax.ty})</span>
                        </span>
                        </React.Fragment>
                        ))}
                    </div>
                    <div className="travellerDetails">
                    <HotelRoomDetails room_price={room} spr={rm.spr} canpolicy={rm.canpolicy} note={room.note} imp={props.book_resp.htldtls} currency={props.book_resp.cur}/>
                    </div>
                </div>
                </div>
                }
            </React.Fragment>
            ))}
        </React.Fragment>
        ))}
        
        <div className="horizontalDivider"></div>
        {props.book_resp && props.book_resp.pay.length > 0 && (
        <div className="travellerDetails">
            <HotelPaymentDetails payment_info={props.book_resp.pay[0]} />
        </div>
        )}
        <div className="horizontalDivider"></div>
    
        <div className="a4-screen-sized">
            <div className="copy mt-4 mb-4"> Copyright &#169; {new Date().getFullYear()} Click n Travel.</div>
        </div>
      </div>
      </div>
    );
});

export default HotelVoucherpdf