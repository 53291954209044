/**
 * @ Author: Ubedullah Mohammed
 * @ Create Time: 2023-06-12 10:45:40
 * @ Description: My Account operations
 */

import MyAccountService from "../../services/myAccount/MyAccountService";

//Loading Profile data
export const getProfile = async (obj,token) => {
  let finalRes = [];
  await MyAccountService.getProfile(obj,token).then((response) => {
    if (response.data.suc) {
      finalRes = response.data;
    }
  });
  return Promise.resolve(finalRes);
};

//update profile
export const updateProfile = async (obj,token) => {
  let finalRes = [];
  await MyAccountService.updateProfile(obj,token).then((response) => {
    finalRes = response.data;
  });
  return Promise.resolve(finalRes);
};

//delete Frequent Flyer
export const deleteFrequentFlyer = async (id) => {
  let finalRes = [];
  await MyAccountService.deleteFrequentFlyer(id).then((response) => {
    finalRes = response.data;
  });
  return Promise.resolve(finalRes);
};

//Loading Travellers data
export const getTravellers = async (obj,token) => {
  let finalRes = [];
  await MyAccountService.getTravellers(obj,token).then((response) => {
    if (response.data.suc) {
      finalRes = response.data;
    }
  });
  return Promise.resolve(finalRes);
};

//Save Travellers
export const saveTravellers = async (data,token) => {
  let finalRes = [];
  await MyAccountService.saveTravellers(data,token).then((response) => {
    finalRes = response.data;
  });
  return Promise.resolve(finalRes);
};

//update Travellers
export const updateTravellers = async (obj,token) => {
  let finalRes = [];
  await MyAccountService.updateTravellers(obj,token).then((response) => {
    finalRes = response.data;
  });
  return Promise.resolve(finalRes);
};

//delete Travellers
export const deleteTravellers = async (id) => {
  let finalRes = [];
  await MyAccountService.deleteTravellers(id).then((response) => {
    finalRes = response.data;
  });
  return Promise.resolve(finalRes);
};

//change password data
export const changePassword = async (obj,token) => {
  let finalRes = [];
  await MyAccountService.changePassword(obj,token).then((response) => {
    // if (response.data.suc) {
    finalRes = response.data;
    // }
  });
  return Promise.resolve(finalRes);
};

export const delAirline = async (id) => {
  let finalRes = [];
  await MyAccountService.delAirline(id).then((response) => {
    finalRes = response;
  });
  return Promise.resolve(finalRes);
};

// delete
export const deleteRoutes = async (obj) => {
  let finalRes = {};
  await MyAccountService.deleteRoutes(obj).then((response) => {
    finalRes = response.data;
  });
  return Promise.resolve(finalRes);
};

//================================
// Fetch All Master Countries List
//================================
export const countriesWitCode = async () => {
  let countriesList = [];
  await MyAccountService.getCountryList().then((response) => {
    if (response.data.suc) {
      countriesList = response.data.res.map((cnt) => (
        <option value={cnt.code}>{cnt.name}</option>
      ));
    }
  });
  return Promise.resolve(countriesList);
};

//Save Preferences
export const savePreferences = async (data,token) => {
  let finalRes = [];
  await MyAccountService.savePreferences(data,token).then((response) => {
    finalRes = response.data;
  });
  return Promise.resolve(finalRes);
};

//Loading Preferences data
export const getPreferences = async (obj,token) => {
  let finalRes = [];
  await MyAccountService.getPreferences(obj,token).then((response) => {
    if (response.data.suc) {
      finalRes = response.data;
    }
  });
  return Promise.resolve(finalRes);
};

//delete Preferences - Preffered Airlines
export const delPrefairlines = async (id) => {
  let finalRes = [];
  await MyAccountService.delPrefairlines(id).then((response) => {
    finalRes = response.data;
  });
  return Promise.resolve(finalRes);
};

//delete Preferences - Preffered Hotels
export const delPrefHtls = async (id) => {
  let finalRes = [];
  await MyAccountService.delPrefHtls(id).then((response) => {
    finalRes = response.data;
  });
  return Promise.resolve(finalRes);
};

//delete Preferences - Freq Visited Cities
export const delFreqVisitedCity = async (id) => {
  let finalRes = [];
  await MyAccountService.delFreqVisitedCity(id).then((response) => {
    finalRes = response.data;
  });
  return Promise.resolve(finalRes);
};

//delete Preferences - Pref Destinations
export const delPrefDest = async (id) => {
  let finalRes = [];
  await MyAccountService.delPrefDest(id).then((response) => {
    finalRes = response.data;
  });
  return Promise.resolve(finalRes);
};

//Loading My Trips data
export const getMytrips = async (obj,token) => {
  let finalRes = [];
  await MyAccountService.getMytrips(obj,token).then((response) => {
    if (response.data.suc) {
      finalRes = response.data;
    }
  });
  return Promise.resolve(finalRes);
};

//Loading Flight Itineraries data
export const getFlightItinerary = async (obj,token) => {
  let finalRes = [];
  await MyAccountService.getFlightItinerary(obj,token).then((response) => {
    if (response.data.suc) {
      finalRes = response.data;
    }
  });
  return Promise.resolve(finalRes);
};

//Loading Hotel Itineraries data
export const getHotelItinerary = async (obj,token) => {
  let finalRes = [];
  await MyAccountService.getHotelItinerary(obj,token).then((response) => {
    if (response.data.suc) {
      finalRes = response.data;
    }
  });
  return Promise.resolve(finalRes);
};

//Cancel Ticket
export const postflowOperations = async (obj,token) => {
  let finalRes = [];
  await MyAccountService.postflowOperations(obj,token).then((response) => {
    if (response.data.suc) {
      finalRes = response.data;
    }
  });
  return Promise.resolve(finalRes);
};

//Cancel Ticket
export const cancelRoom = async (obj,token) => {
  let finalRes = [];
  await MyAccountService.cancelRoom(obj,token).then((response) => {
    if (response.data.suc) {
      finalRes = response.data;
    }
  });
  return Promise.resolve(finalRes);
};
