/**
 * @ Author: Srikanth Bandaru
 * @ Create Time: 2023-06-01 18:06:08
 * @ Description: This is a component used to display the return trip details in flight search results block
 */

import { Col, Row } from "antd";
import { useContext } from "react";
import React from "react";
import { Context } from "../../../../App";
const CDNURL = process.env.REACT_APP_CNT_CDN_URL

function ReturnTripDetails(props) {
  const [context, setContext] = useContext(Context);
  const { flt, seg, sty, price, currency, sectors, serviceFee, mapping } =
    props;
  return (
    <>
      {flt.seg && (
        <div className={"fDirectionRow " + flt.class}>
          {flt.seg.filter((abc) => abc.ma !== seg.ma).length > 0 ? (
            <>
              <img
                src={
                  CDNURL+"/airlines/1x/airline-logos/multipleAirline.png"
                }
                alt="AirLine"
              />
            </>
          ) : (
            <>
              <img
                src={
                  CDNURL+"/airlines/1x/airline-logos/" +
                  seg.ma +
                  ".png"
                }
                alt="AirLine"
              />
            </>
          )}
          <div className="airlineInfo">
            <Row className="aiTop">
              <Col className="fromTime">
                {" "}
                {seg.dd
                  .split("T")[1]
                  .substring(0, seg.dd.split("T")[1].length - 3)}
              </Col>
              <Col className="duration">
                {flt.sdur !== "" && flt.sdur !== undefined && (
                  <span>
                    <i className="icon icon-time"></i>{" "}
                    {flt.sdur.split(":")[0] +
                      "h " +
                      flt.sdur.split(":")[1] +
                      "m "}
                  </span>
                )}
              </Col>
              <Col className="toTime">
                {" "}
                {flt.seg[flt.seg.length - 1].ad
                  .split("T")[1]
                  .substring(
                    0,
                    flt.seg[flt.seg.length - 1].ad.split("T")[1].length - 3
                  )}
              </Col>
            </Row>
            <Row className="aiBottom">
              <Col className="fromDest">
                <span>{seg.da}</span>
              </Col>
              <Col className="stop">
                <span>
                {flt.seg.length - 1 === 0 ?  (context.translation && context.translation.noStop) : flt.seg.length - 1 + " " + ( context.translation && context.translation.stop)}{" "}
                </span>
                {flt.seg.length !== 1}{" "}
                <span>
                  {(flt.seg.length > 1
                    ? flt.seg
                        .slice(1, flt.seg.length)
                        .map((segs) => "- " + segs.da + "")
                    : ""
                  ).toString()}
                </span>
              </Col>
              <Col className="toDest">
                <span>{flt.seg[flt.seg.length - 1].ar}</span>
              </Col>
            </Row>
          </div>
        </div>
      )}
    </>
  );
}

export default ReturnTripDetails;
