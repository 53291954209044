/**
 * @ Author: Lakshmi Narayana
 * @ Create Time: 2023-07-13
 * @ Description: This is an service for Common CNT Sevice
 */

import axios from "axios";
import api from "../../components/utilities/api"
const API_URL = process.env.REACT_APP_B2C_ENGINE_URL;
const API_B2C_COMMON = process.env.REACT_APP_B2C_COMMON;
const META_URL = process.env.REACT_APP_CNT_META_URL;


class CntCommonService {
    
  async loadTravellers(data,token){
    const headers = {
      headers: {
          'Content-Type': 'application/json',
          'auth-token': token
        }
     };
    return await api.post(`${API_URL}/cnt/user/b2cgettraveller`, data,headers);
  }

  async storeLongUrl(data,token){
    const headers = {
      headers: {
          'Content-Type': 'application/json',
          'auth-token': token
        }
     };
    return await api.post(`${API_URL}/cnt/common/tiny/save`, data,headers);
  }

  async fetchLongUrl(code,token){
    const headers = {
      headers: {
          'Content-Type': 'application/json',
          'auth-token': token
        }
     };
    return await api.post(`${API_URL}/cnt/common/tiny/fetch`, code,headers)
}

async fetchAirArabaiPrice(obj,token){
  const headers = {
    headers: {
        'Content-Type': 'application/json',
        'auth-token': token
      }
   };
  return await api.post(`${API_B2C_COMMON}/flight/aa-search-price`, obj,headers)
}

async getPromoCodeList(obj,token){
  const headers = {
    headers: {
        'Content-Type': 'application/json',
        'auth-token': token
      }
   };
  return await api.post(`${API_URL}/common/promo/list`, obj,headers)
}

async getProDetails(obj,token){
  const headers = {
    headers: {
        'Content-Type': 'application/json',
        'auth-token': token
      }
   };
  return await api.post(`${API_URL}/common/promo/details`, obj,headers)
}


async getRegdPromoCodes(obj,token){
  const headers = {
    headers: {
        'Content-Type': 'application/json',
        'auth-token': token
      }
   };
  return await api.post(`${API_URL}/common/promo/fetch_promocode`, obj,headers)
}

async applyPromoCode(obj,token){
  const headers = {
    headers: {
        'Content-Type': 'application/json',
        'auth-token': token
      }
   };
  return await api.post(`${API_URL}/common/promo/apply`, obj,headers)
}


async getExchangeRates(){
  return await api.get(`${API_URL}/common/recurrent/ex_rates`)
}
async loadMetaResp(obj,token){
  const headers = {
    headers: {
        'Content-Type': 'application/json',
        'auth-token': token
      }
   };
  return await api.post(`${META_URL}/wcnt/availability`, obj,headers)
}
}

export default new CntCommonService();
