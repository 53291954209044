import React from "react";
import { useContext } from "react";
import { Context } from "../../../../App";
import AirlinesMemo from "../common/airlines/AirlinesMemo";
import AirportMemo from "../common/airlines/AirportNames";
import DateUtils from "../../../utilities/DateUtils";
import Duration from "../../../../assets/img/duration.png";
import { Tooltip } from "antd";
const CDNURL = "https://img-clickntravel.mncdn.com";

function FlightSummary(props) {
  const [context, setContext] = useContext(Context)
  const layOverTimeCal = (one, two) => {
    let totTime = Math.abs(new Date(one) - new Date(two));
    return DateUtils.msToTime(totTime);
  };
  
  const segFltDuration = (fdur) => {
    const [hours, minutes] = fdur.split(":").map(Number)
    return `${hours}h:${minutes}m`
  };

  const isPageEnabled = window.matchMedia('(max-width:1440px)').matches;
  return (
    <>
      <div className="commonFlex">
        <div className="rb-top">
        {props.headerInfo!==undefined &&
          <div className="info">
            <div>
            <span className="smallFlight">
              <i className="icon icon-flight"></i>
            </span>
            <span className="route">
            <AirportMemo
                field="ResultSectionHeader"
                code={props.headerInfo.src_code}
                mapping={props.mapping}
              />
              </span>
            <span className="rightArrow">
              <i className="icon icon-next-long-arrow-right-svgrepo-com"></i>
            </span>
            <span className="route"><AirportMemo
                field="ResultSectionHeader"
                code={props.headerInfo.dest_code}
                mapping={props.mapping}
              /></span>
              </div>
              <div>
            <span className="date">{DateUtils.prettyDate(props.headerInfo.dd,"ddd, DD MMM, YYYY")}</span>
            {/* <span className="refund">{props.sector.re?props.sector.re:"Non Refundable"}</span> */}
            {props.sector.re==="Non-Refundable" && <span className="nonRefund">{context.translation && context.translation.nonRefund}</span>}
            {props.sector.re!=="Non-Refundable" && <span className="refund">{context.translation && context.translation.partialRefund}</span>}
            </div>
          </div>
        }
        </div>
        
        <div className="pnrWrapper">
          <span className="pnrTitle">PNR:&nbsp;</span>
          {props.sector.sup==="0" && props.sector &&
          <span className="pnrNo">{props.sector.pnr?props.sector.pnr:"N/A"}</span>
          }
          {props.sector.sup!=="0" && props.seg &&
          <span className="pnrNo">{props.seg.airpnr?props.seg.airpnr:"N/A"}</span>
          }
        </div>
        
      </div>
      {props.sector &&
          props.sector.seg &&
          props.sector.seg.map((sege, index) => (
        <div key={index}>
        <div className="dFlex fsFlightInfo">
          <div className="airlineDetailWrap">
            <div className="airlineName">
            {props.sector.seg.filter((abc) => abc.ma !== props.seg.ma).length > 0 ? (
                        <>
                        <div className="planeInfo">
                          <img
                            src={
                              CDNURL+"/airlines/1x/airline-logos/multipleAirline.png"
                            }
                            alt="AirLine"
                          />
                          <span className="planeName"><Tooltip placement="top" title={context.translation && context.translation.mulAirlines}>{context.translation && context.translation.mulAirlines}</Tooltip></span>
                          <div className="planeCodeCatWrap">
                          <span className="planeCode"> {props.seg.ma} - {props.seg.fn}</span>
                 
                          
                  {(sessionStorage.getItem("SEL_LAG")==="English"||sessionStorage.getItem("SEL_LAG")===null)&& props.sector.cl==="Economy"? <span className="category">Economy</span> :sessionStorage.getItem("SEL_LAG")==="Arabic"&& props.sector.cl==="Economy"&&<span className="category">سياحية</span>}
                  {(sessionStorage.getItem("SEL_LAG")==="English"||sessionStorage.getItem("SEL_LAG")===null)&& props.sector.cl==="Premium Economy"?<span className="category">Premium Economy</span> :sessionStorage.getItem("SEL_LAG")==="Arabic"&& props.sector.cl==="Premium Economy"&&<span className="category">السياحية المتميزة</span>}
                  {(sessionStorage.getItem("SEL_LAG")==="English"||sessionStorage.getItem("SEL_LAG")===null)&& props.sector.cl==="Business"?<span className="category">Business</span> :sessionStorage.getItem("SEL_LAG")==="Arabic"&& props.sector.cl==="Business"&&<span className="category">رجال الأعمال</span>}
                  {(sessionStorage.getItem("SEL_LAG")==="English"||sessionStorage.getItem("SEL_LAG")===null)&& props.sector.cl==="First"?<span className="category">First class</span> :sessionStorage.getItem("SEL_LAG")==="Arabic"&& props.sector.cl==="First"&&<span className="category">الصف الأول</span>}
                          </div>
                          </div>
                          <div className="operatedBy">{context.translation && context.translation.operated} <AirlinesMemo code={sege.oa} /></div>
                        </>
                      ) : (
                        <>
                        <div className="planeInfo">
                          <img
                            src={
                              CDNURL+"/airlines/1x/airline-logos/" +
                              sege.ma +
                              ".png"
                            }
                            alt="AirLine"
                          />
                          <span className="planeName"><Tooltip placement="top" title={<AirlinesMemo code={sege.ma} />}><span><AirlinesMemo code={sege.ma} /></span></Tooltip></span>
                          <div className="planeCodeCatWrap">
                          <span className="planeCode"> {sege.ma} - {sege.fn}</span>
                  

                  {(sessionStorage.getItem("SEL_LAG")==="English"||sessionStorage.getItem("SEL_LAG")===null)&& props.sector.cl==="Economy"? <span className="category">Economy</span> :sessionStorage.getItem("SEL_LAG")==="Arabic"&& props.sector.cl==="Economy"&&<span className="category">سياحية</span>}
                  {(sessionStorage.getItem("SEL_LAG")==="English"||sessionStorage.getItem("SEL_LAG")===null)&& props.sector.cl==="Premium Economy"?<span className="category">Premium Economy</span> :sessionStorage.getItem("SEL_LAG")==="Arabic"&& props.sector.cl==="Premium Economy"&&<span className="category">السياحية المتميزة</span>}
                  {(sessionStorage.getItem("SEL_LAG")==="English"||sessionStorage.getItem("SEL_LAG")===null)&& props.sector.cl==="Business"?<span className="category">Business</span> :sessionStorage.getItem("SEL_LAG")==="Arabic"&& props.sector.cl==="Business"&&<span className="category">رجال الأعمال</span>}
                  {(sessionStorage.getItem("SEL_LAG")==="English"||sessionStorage.getItem("SEL_LAG")===null)&& props.sector.cl==="First"?<span className="category">First class</span> :sessionStorage.getItem("SEL_LAG")==="Arabic"&& props.sector.cl==="First"&&<span className="category">الصف الأول</span>}
                          </div>
                          </div>
                          <div className="operatedBy">{context.translation && context.translation.operated} <AirlinesMemo code={sege.oa} /></div>
                        </>
                      )}
            </div>
            <div className="dFlex">
            <div className="timeInfo">
              <div>
                <span className="time">{sege.dd
                            .split("T")[1]
                            .substring(0, sege.dd.split("T")[1].length - 3)}{" "}</span>
                <span className="countryCode">{sege.da}</span>
              </div>
              <div className="dateMonthYearWrap">
                {DateUtils.convertToLetterDate(sege.dd)}
              </div>
              <span className="airportName">
              <AirportMemo
                          field="ResultDetails"
                          code={sege.da}
                          mapping={props.mapping}
                        />
              </span>
              {sege.terminal && sege.terminal.dep && (
                        <span className="terminal">
                          {context.translation && context.translation.terminal} {sege.terminal.dep}
                        </span>
                      )}
            </div>
            <div className="duration">
            {/* <span>{layOverTimeCal(sege.ad, sege.dd)}</span> */}
            {sege.fdur !== "" && sege.fdur !== undefined && (
              <span>{segFltDuration(sege.fdur)}</span> 
            )}
            <img src={Duration} alt="duration-icon" />
            </div>
            <div className="timeInfo">
              <div>
                <span className="time">{sege.ad
                            .split("T")[1]
                            .substring(0, sege.ad.split("T")[1].length - 3)}{" "}</span>
                <span className="countryCode">{sege.ar}</span>
              </div>
              <div className="dateMonthYearWrap">
                {DateUtils.convertToLetterDate(sege.ad)}
              </div>
              <span className="airportName">
              <AirportMemo
                          field="ResultDetails"
                          code={sege.ar}
                          mapping={props.mapping}
                        />
              </span>
              {sege.terminal && sege.terminal.arr && (
                        <span className="terminal">
                          {context.translation && context.translation.terminal} {sege.terminal.arr}
                        </span>
                      )}
            </div>
            </div>
          </div>
        {props.baggage !== "baggageDesign" && (
          <div className="baggageMainWrapper">
            <div className="baggageWrapper">
              
              {props.sector &&
              props.sector.bgd &&
              props.sector.bgd.map((bag, index) => (
                <div key={index}>
                {bag.type==="Cabin" &&
                <div className="baggage">
                <i className="icon icon-Baggage"></i>
                <span className="cabin">{context.translation && context.translation.cabinBag}</span>
                <span className="weight">{bag.qun}{bag.wt}</span>
              </div>
                }
                {bag.type==="Checkin" &&
                <div className="baggage">
                <i className="icon-Check-in"></i>
                <span className="checkIn">{context.translation && context.translation.checkInBag}</span>
                <span className="weight">{bag.qun}{bag.wt}</span>
              </div>
              }
                </div>
              ))}
            </div>
          </div>
        )}
      </div>

      

      {props.baggage === "baggageDesign" && (
        <div className="baggageInfo">
          {context.translation && context.translation.bagInfo}: 
          <div className="firstCabin">
          {props.sector &&
            props.sector.bgd &&
            props.sector.bgd.map((bag, index) => (
                <div className="baggage" key={index}>
                    {bag.type==="Cabin" &&
                      <span className="cabin"> {context.translation && context.translation.cbin} {bag.qun} {bag.wt}, </span>
                    }
                    {bag.type==="Checkin" &&
                      <span className="checkIn"> {context.translation && context.translation.checkin} {bag.qun} {bag.wt}</span>
                    }
                </div>
              )
            )}
          </div>
          <div className="baggage">
            {/* {"per Adult & Child"} */}
            {context.translation && context.translation.perAdtChd}
          </div>
        </div>
      )}

      {props.sector.seg.length > 1 &&
                props.sector.seg[index + 1] &&
                props.sector.seg[index + 1].dd !== undefined && (
                  <div className="layover">
                    <span>
                      {" "}
                      {context.translation && context.translation.layover} :&nbsp;
                      <>
                        {layOverTimeCal(
                          props.sector.seg[index].ad,
                          props.sector.seg[index + 1].dd
                        )}
                      </>
                    </span>
                  </div>
                )}
            </div>
          ))}

    </>
  );
}

export default FlightSummary;
