/**
 * @ Author: Srikanth Bandaru
 * @ Create Time: 2023-06-08 19:00:23
 * @ Description: This is a common component used for date in all the search panels
 */

import { DatePicker, Button } from "antd";
import dayjs from "dayjs";
import React, { useEffect, useRef } from "react";

const { RangePicker } = DatePicker;

const DateRangePicker = (props) => {
  const current = new Date();

  const handleToggleCalendar = () => {
    props.focus(!props.open)
  }
  const isButtonEnabled = window.matchMedia('(max-width: 767px)').matches;
  return (
    <>
      {((props.tt && props.tt === 2) || props.htl) && (
        <>
        {isButtonEnabled && (
          <>
          <div className="forSmallScreen" style={{display: props.open ? "block" : "none"}}>
            <div className="dFlex">
              <span className="forSmallScreenText">Select dates</span>
              <i className="icon icon-cancel forSmallScreenIcon" onClick={handleToggleCalendar}></i>
            </div>
          </div>
        </>
        )}
        
        <RangePicker
          value={[
            props.selDate && props.selDate[0] ? dayjs(props.selDate[0]) : null,
            props.selDate && props.selDate[1] ? dayjs(props.selDate[1]) : null,
          ]}
          open={props.open}
          onOpenChange={(e) => props.focus(props.open)}
          onClick={(e) => props.focus(!props.open)}
          showTime={false}
          placeholder={props.placeholders}
          format="DD-MM-YYYY"
          disabledDate={(d) => !d || d.isBefore(props.date,'day')}
          onCalendarChange={(e) => props.onchange(e, props.tt)}
          allowEmpty={props.allowempty}
          disabled={props.disabled}
          className={props.className}
          popupClassName={props.popupClassName}
          inputReadOnly
          placement="bottomLeft"
        />
        
        </>
      )}
      {props.tt && (props.tt === 1 || props.tt === 3) && (
        <>
        {isButtonEnabled && (
          <>
          <div className="forSmallScreen" style={{display: props.open ? "block" : "none"}}>
            <div className="dFlex">
              <span className="forSmallScreenText">Select dates</span>
              <i className="icon icon-cancel forSmallScreenIcon" onClick={handleToggleCalendar}></i>
            </div>
          </div>
        </>
        )}
        <DatePicker
          value={props.selDate ? dayjs(props.selDate) : null}
          open={props.open}
          onOpenChange={(e) => props.focus(props.open)}
          onClick={(e) => props.focus(!props.open)}
          showTime={false}
          placeholder={props.placeholders}
          format="DD-MM-YYYY"
          disabledDate={(d) => !d || d.isBefore(props.date,'day')}
          onChange={(e) => props.onchange(e, props.tt)}
          className={props.className}
          popupClassName={props.popupClassName}
          inputReadOnly
          placement="bottomLeft"
        />
        </>
      )}
    </>
  );
};

export default DateRangePicker;
