/**
 * @ Author: Srikanth Bandaru
 * @ Create Time: 2023-06-14 15:49:45
 * @ Description: The component for sorting of the flight search results which appears on the top of the results page
 */

import React, { useEffect, useRef, useState,useContext } from "react";
import FormatCurrency from "../../../utilities/FormatCurrency";
import { Col, Row, Select, Drawer } from "antd";
import DateUtils from "../../../utilities/DateUtils";
import Utilities from "../../../utilities/Utilities";
import { Context } from "../../../../App";
function FlightResultsSort(props) {
  const { searchRes, searchRequest, initRes,base_usr_exrate } = props;
  const [context, setContext] = useContext(Context)
  const [selectedDepartureSort, setSelectedDepartureSort] = useState([]);
  const [selectedArrivalSort, setSelectedArrivalSort] = useState([]);
  const [selectedTripDurationSort, setSelectedTripDurationSort] = useState([]);
  const [selectedPriceSort, setSelectedPriceSort] = useState([]);
  const [raw_srp_data, setRawSrpData] = useState("");
  useEffect(() => {
    if (sessionStorage.getItem("FILTERRESET") === "true") {
      setSelectedDepartureSort([]);
      setSelectedArrivalSort([]);
      setSelectedTripDurationSort([]);
      setSelectedPriceSort([]);
      if (document.querySelector(".quickest")) {
        document.querySelector(".quickest").classList.remove("active");
      }
      if (document.querySelector(".cheapest")) {
        document.querySelector(".cheapest").classList.remove("active");
      }
    }
  }, [sessionStorage.getItem("FILTERRESET")]);

  useEffect(() => {
    if(props?.raw_srp_data){
      setRawSrpData(props?.raw_srp_data)
    }
  },[props?.raw_srp_data]);

  //To get the cheapest while sorting the flight results
  const sortCheapest = (filter, e) => {
    let results;
    let testdata = [
      ...searchRes.origns
    ]
    if (filter === "cheapest") {
      sessionStorage.removeItem("QUICKEST");
      document.querySelector(".quickest").classList.remove("active");
      document.querySelector(".cheapest").classList.add("active");
      //document.querySelector(".cheapest").classList.add("after");
      results = testdata.sort(function (one, two) {
        return one.pr.tf - two.pr.tf;
      });
    }
    let finalResults = [];
    for (let val of results) {
      if (val) {
        finalResults.push(val);
      }
    }
    //searchRes.origns = finalResults;
    let resp = {
      ...searchRes,
      origns: finalResults
    }
    props.handleFilterChanges(resp,"filter");
    sessionStorage.setItem("FILTERRESET", false);
    sessionStorage.setItem("CHEAPEST", true);
  };

  //To get the fastest while sorting the flight results
  const sortQuickest = (filter, type) => {
    let res = [];
    sessionStorage.setItem("ISRESET",true)
    let testdata = [
      ...searchRes.origns
    ]
    if (filter === "quickest") {
      document.querySelector(".quickest").classList.add("active");
      document.querySelector(".cheapest").classList.remove("active");
      type = "shortest";
      sessionStorage.setItem("QUICKEST", true);
      testdata.sort((one, two) => {
        if (one.pr.tf === searchRes.filter.fstst && two.pr.tf !== searchRes.filter.fstst) {
          return -1; // one should come before two
        } else if (one.pr.tf !== searchRes.filter.fstst && two.pr.tf === searchRes.filter.fstst) {
          return 1; // two should come before one
        } else {
          // If pr.tf is equal or not equal to searchRes.filter.fstst, compare by toTime
          return one.toTime - two.toTime;
        }
      });
      
    } else {
      setSelectedTripDurationSort(type);
    }
    /* testdata.forEach((flights) => {
      let results = flights.srcorigns.sort(function (one, two) {
        if (DateUtils.isAfterTime(one.sdur, two.sdur)) {
          return 1;
        } else return -1;
      });

      flights.srcorigns = [];
      flights.srcorigns = results;
      res.push(flights);
    }); */
    //searchRes.origns = res;
    let resp = {
      ...searchRes,
      origns: testdata
    }
    if (type === "shortest") {
      if(filter !== "quickest") {
        resp.origns.sort((one, two) => {
          return one.srcorigns[0].sdur.localeCompare(two.srcorigns[0].sdur);
        });
      }
      if(resp.origns.length > 0) {
        resp.origns[0].quickest = true;
      }
    } else  if (type === "earliest") {
      resp.origns.sort((one, two) => {
        return two.srcorigns[0].sdur.localeCompare(one.srcorigns[0].sdur);
      });
    }

    props.handleFilterChanges(resp, "fastest");
    sessionStorage.setItem("FILTERRESET", false);
    sessionStorage.removeItem("CHEAPEST");
  };

  //To sort based on departure time
  const sortDepartureTime = (depType) => {
    let res;
    setSelectedDepartureSort(depType);
    sessionStorage.setItem("ISRESET",true)
    let testdata = [
      ...searchRes.origns
    ]
    if (depType === "earliest") {
      res = testdata.sort((one, two) => {
        if (
          DateUtils.isAfter(
            one.srcorigns[0].seg[0].dd,
            two.srcorigns[0].seg[0].dd
          )
        ) {
          return 1;
        } else return -1;
      });
    } else {
      res = testdata.sort((one, two) => {
        if (
          DateUtils.isBefore(
            one.srcorigns[0].seg[0].dd,
            two.srcorigns[0].seg[0].dd
          )
        ) {
          return 1;
        } else return -1;
      });
    }
    let resp = {
      ...searchRes,
      origns: res
    }
    props.handleFilterChanges(resp, "filter");
    sessionStorage.setItem("FILTERRESET", false);
    sessionStorage.removeItem("CHEAPEST");
  };

  //To sort based on arrival time
  const sortArrivalTime = (arrType) => {
    let res;
    setSelectedArrivalSort(arrType);
    sessionStorage.setItem("ISRESET",true)
    let testdata = [
      ...searchRes.origns
    ]
    if (arrType === "earliest") {
      res = testdata.sort((one, two) => {
        if (
          DateUtils.isAfter(
            one.srcorigns[0].seg[one.srcorigns[0].seg.length - 1].ad,
            two.srcorigns[0].seg[two.srcorigns[0].seg.length - 1].ad
          )
        ) {
          return 1;
        } else return -1;
      });
    } else {
      res = testdata.sort((one, two) => {
        if (
          DateUtils.isBefore(
            one.srcorigns[0].seg[one.srcorigns[0].seg.length - 1].ad,
            two.srcorigns[0].seg[two.srcorigns[0].seg.length - 1].ad
          )
        ) {
          return 1;
        } else return -1;
      });
    }
    //searchRes.origns = res;
    //props.handleFilterChanges(searchRes, "");
    let resp = {
      ...searchRes,
      origns: res
    }
    props.handleFilterChanges(resp, "filter");
    sessionStorage.setItem("FILTERRESET", false);
    sessionStorage.removeItem("CHEAPEST");
  };

  //To sort based on price
  const sortByPrice = (type) => {
    let results;
    setSelectedPriceSort(type);
    sessionStorage.setItem("ISRESET",true)
    let testdata = [
      ...searchRes.origns
    ]
    if (type === "lowest") {
      results = testdata.sort(function (one, two) {
        let oneprice = one.pr.tf;
        let twoprice = two.pr.tf;
        if (one.pr.tax && two.pr.tax) {
          oneprice = Utilities.addPrice(
            one.pr.tf + calcuateServiceFee(one.servicefee),
            calculateDisc(one.pr.tax),
            "Add"
          );
          twoprice = Utilities.addPrice(
            two.pr.tf + calcuateServiceFee(two.servicefee),
            calculateDisc(two.pr.tax),
            "Add"
          );
        }
        return (oneprice - twoprice);
      });
    } else {
      results = testdata.sort(function (one, two) {
        let oneprice = one.pr.tf;
        let twoprice = two.pr.tf;
        if (one.pr.tax && two.pr.tax) {
          oneprice = Utilities.addPrice(
            one.pr.tf + calcuateServiceFee(one.servicefee),
            calculateDisc(one.pr.tax),
            "Add"
          );
          twoprice = Utilities.addPrice(
            two.pr.tf + calcuateServiceFee(two.servicefee),
            calculateDisc(two.pr.tax),
            "Add"
          );
        }
        return (twoprice - oneprice);
      });
    }
    //searchRes.origns = results;
    let resp = {
      ...searchRes,
      origns: results
    }
    props.handleFilterChanges(resp, "filter");
    sessionStorage.setItem("FILTERRESET", false);
    sessionStorage.removeItem("CHEAPEST");
  };

  //For service fee calculation
  const calcuateServiceFee = (serviceFeeList) => {
    var userType = searchRequest.butype === "ta_user" ? "TA" : "STA";
    let servicefee = 0;
    if (serviceFeeList !== undefined) {
      servicefee = Utilities.calculateServiceFee(
        serviceFeeList,
        userType,
        searchRequest
      );
      if (servicefee === "NaN") {
        return 0.0;
      } else {
        return servicefee;
      }
    } else {
      return 0.0;
    }
  };

  //For discount calculation
  const calculateDisc = (taxes) => {
    if (taxes !== undefined) {
      for (let taxIndex = 0; taxIndex < taxes.length; taxIndex++) {
        var mak = taxes[taxIndex];
        if (
          mak !== undefined &&
          mak.dealmkp !== undefined &&
          mak.dealmkp !== 0
        ) {
          return mak.dealmkp;
        } else {
          return 0.0;
        }
      }
    } else {
      return 0.0;
    }
  };

const [open, setOpen] = useState(false);

const showDrawer = () => {
  setOpen(true);
};

const onClose = () => {
  setOpen(false);
};

  return (
    <>
      {searchRes && searchRes.filter && (
        <>
          <Col xl={10} lg={24} md={24} xs={24}>
            <div className="cardWithShadow sorting">
              <div className="dFlex">
                <div
                  className="cheapest active"
                  onClick={(e) => sortCheapest("cheapest", e)}
                >
                  <i className="icon icon-Cheapest"></i>
                  <div>
                    <span className="heading">{context.translation && context.translation.chpest}</span>
                    <span className="currency">
                      {searchRequest.cur}{" "}
                      <FormatCurrency value={searchRes.filter.chpst * base_usr_exrate} />
                    </span>
                  </div>
                </div>
                <div
                  className="quickest"
                  onClick={(e) => sortQuickest("quickest", e)}
                >
                  <i className="icon icon-flight-2"></i>
                  <div>
                    <span className="heading">{context.translation && context.translation.qukest}</span>
                    <span className="currency">
                      {searchRequest.cur}{" "}
                      <FormatCurrency value={searchRes.filter.fstst * base_usr_exrate} />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col xl={14} lg={24} md={24} xs={24}>
            <div className="cardWithShadow sortingOption displayNone-425">
              <div className="dFlex">
                <i className="icon icon-sorting"></i>
                <Select
                  options= {context.selectLang == undefined || context.selectLang == 'en' ?[
                    { label: "Earliest", value: "earliest" },
                    { label: "Latest", value: "latest" },
                  ]:[
                    { label: "الأقرب", value: "earliest" },
                    { label: "الأخير", value: "latest" },
                  ]}
                  placeholder={context.translation && context.translation.dept}
                  onChange={(e) => sortDepartureTime(e)}
                  value={selectedDepartureSort}
                  defaultValue=""
                  className="departure"
                />
                <Select
                  options= {context.selectLang == undefined || context.selectLang == 'en' ?[
                    { label: "Earliest", value: "earliest" },
                    { label: "Latest", value: "latest" },
                  ]:[
                    { label: "الأقرب", value: "earliest" },
                    { label: "الأخير", value: "latest" },
                  ]}
                  placeholder={context.translation && context.translation.arvl}
                  onChange={(e) => sortArrivalTime(e)}
                  value={selectedArrivalSort}
                  defaultValue=""
                  className="arrival"
                />
                <Select
                  options={context.selectLang == undefined || context.selectLang == 'en' ?[
                    { label: "Shortest", value: "shortest" },
                    { label: "Longest", value: "earliest" },
                  ]:[
                    { label: "أقصر", value: "shortest" },
                    { label: "أطول", value: "earliest" },
                  ]}
                  placeholder={context.translation && context.translation.tripDur}
                  onChange={(e) => sortQuickest(null, e)}
                  value={selectedTripDurationSort}
                  defaultValue=""
                  className="tripDuration"
                />
                <Select
                  options={context.selectLang == undefined || context.selectLang == 'en' ?[
                    { label: "Highest", value: "highest" },
                    { label: "Lowest", value: "lowest" },
                  ]:[
                    { label: "أعلى", value: "highest" },
                    { label: "أدنى", value: "lowest" },
                  ]}
                  placeholder={context.translation && context.translation.price}
                  onChange={(e) => sortByPrice(e)}
                  value={selectedPriceSort}
                  defaultValue=""
                  className="price"
                  popupClassName="priceDpDown"
                />
              </div>
            </div>
            <div className="sortByWrap displayNoneLG" onClick={showDrawer}>
                  <div className="sortBy">
                    <i className="icon icon-sorting"></i>
                    <span>{context.translation && context.translation.sort}</span>
                  </div>
            </div>
            <Drawer placement="bottom" open={open} className="sortingOptionPopup">
            <i className="icon icon-cancel" onClick={onClose}></i>
                  <div className="sortByTitle">{context.translation && context.translation.sortby}</div>
                  <div className="dFlex sortingOptionWrap">
                  <div className="sortingOption">
                    <Select
                      options= {context.selectLang == undefined || context.selectLang == 'en' ?[
                        { label: "Earliest", value: "earliest" },
                        { label: "Latest", value: "latest" },
                      ]:[
                        { label: "الأقرب", value: "earliest" },
                        { label: "الأخير", value: "latest" },
                      ]}
                      placeholder={context.translation && context.translation.dept}
                      onChange={(e) => sortDepartureTime(e)}
                      value={selectedDepartureSort}
                      defaultValue=""
                      className="departure"
                      popupClassName="departureDpDown"
                    />
                  </div>
                  <div className="sortingOption">
                    <Select
                      options= {context.selectLang == undefined || context.selectLang == 'en' ?[
                        { label: "Earliest", value: "earliest" },
                        { label: "Latest", value: "latest" },
                      ]:[
                        { label: "الأقرب", value: "earliest" },
                        { label: "الأخير", value: "latest" },
                      ]}
                      placeholder={context.translation && context.translation.arvl}
                      onChange={(e) => sortArrivalTime(e)}
                      value={selectedArrivalSort}
                      defaultValue=""
                      className="arrival"
                      popupClassName="arrivalDpDown"
                    />
                  </div>
                  </div>
                  <div className="dFlex sortingOptionWrap">
                  <div className="sortingOption">
                    <Select
                      options={context.selectLang == undefined || context.selectLang == 'en' ?[
                        { label: "Shortest", value: "shortest" },
                        { label: "Longest", value: "earliest" },
                      ]:[
                        { label: "أقصر", value: "shortest" },
                        { label: "أطول", value: "earliest" },
                      ]}
                      placeholder={context.translation && context.translation.tripDur}
                      onChange={(e) => sortQuickest(null, e)}
                      value={selectedTripDurationSort}
                      defaultValue=""
                      className="tripDuration"
                      popupClassName="tripDurationDpDown"
                    />
                  </div>
                  <div className="sortingOption">
                    <Select
                      options={context.selectLang == undefined || context.selectLang == 'en' ?[
                        { label: "Highest", value: "highest" },
                        { label: "Lowest", value: "lowest" },
                      ]:[
                        { label: "أعلى", value: "highest" },
                        { label: "أدنى", value: "lowest" },
                      ]}
                      placeholder={context.translation && context.translation.price}
                      onChange={(e) => sortByPrice(e)}
                      value={selectedPriceSort}
                      defaultValue=""
                      className="price"
                      popupClassName="priceDpDown"
                    />
                  </div>
                  </div>
            </Drawer>
          </Col>
        </>
      )}
    </>
  );
}

export default FlightResultsSort;
