import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Select,
  Typography,
  Space,
  Drawer,
  Modal,
  Table,
  Tabs,
  Spin,
} from "antd";
import React, { useEffect, useState, useReducer, useContext, useRef } from "react";
import Footer from "../common/Footer";
import Header from "../common/Header";
import Sidebar from "./Sidebar";
import { Context } from "../../App";
import { useNavigate } from "react-router-dom";
import { getFlightItinerary, getHotelItinerary, getMytrips } from "./operations";
import MyTripsItineraryPopup from "./MyTripsItineraryPopup";
import DateUtils from "../utilities/DateUtils";
import { useReactToPrint } from "react-to-print";
import MyTripsHtlItineraryPopup from "./MyTripsHtlItineraryPopup";

// import { jsPDF } from "jspdf";
// import { saveAs } from "file-saver";
// import axios from "axios";
// import html2canvas from "html2canvas";
import MyTripsItineraryPdf from "./MyTripsItineraryPdf";
import MyTripsHtlItineraryPdf from "./MyTripsHtlItineraryPdf";
import FlightItinerarypdf from "../bookingflow/flights/confirmation/FlightItinerarypdf";
import html2pdf from "html2pdf.js";
import Utilities from "../utilities/Utilities";

const { Title } = Typography;
const { Option } = Select;

const initialState = {
  id: 0,
  All: true,
  upcomingActive: true,
  cancelledActive: false,
  completedActive: false,
  upcomingTrips: [],
  cancelledTrips: [],
  completedTrips: [],
  res: [],
  itinerary: undefined,
  downloadItinerary : undefined,
  downloadHtlItinerary : undefined,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "clear":
      return {
        ...state,
      };
    default:
      return { ...state, [action.type]: action.payload };
  }
};

function MyTrips() {
  const [context, setContext] = useContext(Context);
  const navigate = useNavigate();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [showItinerary, setShowItinerary] = useState(false);
  const [showHtlItinerary, setShowHtlItinerary] = useState(false);
  const [downloadTripItinerary, setDownloadTripItinerary] = useState(false);
  const [downloadHtlTripItinerary, setDownloadHtlTripItinerary] = useState(false); 
  const [tableData, setTableData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedOption, setSelectedOption] = useState("all");  
  const [loading, setLoading] = useState(false);
  const [bkId, setBkId] = useState();

  
  // State to manage the tab title
  const [activeTitle, setActiveTitle] = useState(
    sessionStorage.getItem("activeTitle") || "Title1"
  );

  // Function to handle tab click
  const handleTitleClick = (title) => {
    setActiveTitle(title);
    // Save the active title to local storage
    sessionStorage.setItem("activeTitle", title);
  };

  useEffect(() => {
    // Load data for the active title here
    if(activeTitle === "Title1") {
      upcomingTrips();
    } else if (activeTitle === "Title2") {
      cancelledTrips();
    } else if (activeTitle === "Title3") {
      completedTrips();
    }

  }, [activeTitle]);

  const renderTabTitle = (title, icon) => (
    <div>
      {icon}
      <span>{title}</span>
    </div>
  );

  const customIcons = {
    Title1: <UpcomingIcon />,
    Title2: <CancelledIcon />,
    Title3: <CompletedIcon />
  };

  useEffect(() => {  
    const data = JSON.parse(localStorage.getItem("SIGNINRESPONSE"));
    if (data !== null) {
      const payload = {
        header_st: "Upcoming",
        usr_id: data.res.id
      };
      let tokenKey = Utilities.generateAPIToken(payload);
      getMytrips(payload,tokenKey).then((response) => {
        if (response.suc && activeTitle === "Title1") {
          setTableData(response?.res?.bk_list);
          loadResponse(response?.res?.bk_list);
        }
        if(response?.res?.bk_list.length === 0) {
          dispatch({ type: "res", payload: [] });
        }
      }).catch(function (error) {
        console.error("Error:" + error);      
      });
    }
  }, [context.translation]);


  const loadResponse = (response) => {
    let data = [];
    if (response !== undefined) {
      for (let val of response) {
        data.push({
          trip: 
            <div className="myTripList">
              <Title level={5}>
                {val.product.toUpperCase() === "FLIGHT" && (
                  <>
                    <span>
                      {val.src_city} ({val.src_code})
                    </span>
                    {(val.tt == 2 || val.flow_type?.toUpperCase() === "SPLIT") ? <i className="icon icon-roundtrip-arrow"></i> : val.tt == 3 ? <i className="icon icon-ico-multicity"></i> : <i className="icon icon-next-long-arrow-right-svgrepo-com"></i>}
                    <span>
                      {val.dest_city} ({val.dest_code})
                    </span>
                  </>
                )}
                {val.product.toUpperCase() === "HOTEL" && (
                  <>
                    <span>
                      {val.hotel_name} - {val.city}
                    </span>
                  </>
                )}
                </Title>
                <span className="date">
                  {val.product.toUpperCase() === "FLIGHT" && (
                    <>
                    {val.header_st.toUpperCase() === "COMPLETED" ?
                      DateUtils.prettyDate(val.arrival_time?.split("T")[0], "ddd, DD MMM, YYYY") :
                      DateUtils.prettyDate(val.travel_dt?.split("T")[0], "ddd, DD MMM, YYYY")
                    }
                    </>
                  )}
                  {val.product.toUpperCase() === "HOTEL" && (
                    <>
                    {DateUtils.prettyDate(val.checkin_date?.split("T")[0], "ddd, DD MMM, YYYY")} - {val.cin_time} -&nbsp;
                    {val.number_of_nights} Nights  
                    </>
                  )}             
                </span>
                  <i className={"icon" + (val.product.toUpperCase() === "FLIGHT" ? " icon-flight-product" : " icon-hotel-product")}></i>
              </div>,
          bookId: 
            <div>
              <Title level={5} className="bookingIdTitle">
                {context.translation === undefined ? "Booking Id" : context.translation.bokId}
              </Title>
              <span className="bookingId">
                {val.bk_ref_no}
              </span>
            </div>,
          bookStatus:
            <div className="bookingStatusWrap">
              <Title level={5} className="bookingStatusTitle">
                {context.translation === undefined ? "Booking Status" : context.translation.bokStatus}
              </Title>
              <span className={"bookingStatus" + (val.bk_st.toUpperCase() === "CONFIRMED" ? " confirmed" : " cancelled")}>
                {val.bk_st}
              </span>
            </div>,
          srcCity: val.src_city,
          srcCode: val.src_code,
          destCity: val.dest_city,
          destCode: val.dest_code,
          bookingId: val.bk_ref_no,
          bookingDate: val.bk_dt,
          bookingStatus: val.bk_st,
          product: val.product,
          options: (
            <>
            {val.product.toUpperCase() === "FLIGHT" && (
              <div className="actionLinks">
                <Button 
                  className={val.bk_st.toUpperCase() === "CONFIRMED" ? "download" : "download noDownload"}
                  disabled={val.bk_st.toUpperCase() === "CONFIRMED" ? false : true}
                  onClick={async () => {
                    await downloadIti(val);
                  }} 
                  >
                  <i
                  className="icon icon-Download"></i>
                </Button>
                <Button
                  type="secondary"
                  className="viewItineraryBtn"
                  ghost
                  onClick={() => viewItinerary(val)}
                >
                  {context.translation === undefined ? "View Itinerary" : context.translation.veiwItin}
                </Button>
              </div>
            )}

            
            {val.product.toUpperCase() === "HOTEL" && (
              <>
              <div className="actionLinks">
                <Button 
                  className={val.bk_st.toUpperCase() === "CONFIRMED" ? "download" : "download noDownload"}
                  disabled={val.bk_st.toUpperCase() === "CONFIRMED" ? false : true}
                  onClick={async () => {
                  await downloadIti(val);}} 
                  >
                  <i
                  className="icon icon-Download"></i>
                </Button>
                <Button
                  type="secondary"
                  className="viewItineraryBtn"
                  ghost
                  onClick={() => viewItinerary(val)}
                >
                  {context.translation === undefined ? "View Itinerary" : context.translation.veiwItin}
                </Button>
                </div>
              </>
            )}
            </>
          ),
        });
      }
      if (response.length !== 0) {
        dispatch({ type: "res", payload: data });
      }
    }
  };

  const viewItinerary = (obj) => {
    if(obj.product.toUpperCase() === "FLIGHT") {
      const payload = {
        booking_id: obj.bk_ref_no
      };
      let tokenKey = Utilities.generateAPIToken(payload);
      getFlightItinerary(payload,tokenKey).then((response) => {
        if (response.suc) {
          dispatch({ type: "itinerary", payload: response});
        }
      }).catch(function (error) {
        console.error("Error:" + error);      
      });
    }
    if(obj.product.toUpperCase() === "HOTEL") {
      const payload = {
        booking_id: obj.bk_ref_no
      };
      let tokenKey = Utilities.generateAPIToken(payload);
      getHotelItinerary(payload,tokenKey).then((response) => {
        if (response.suc) {
          dispatch({ type: "htlItinerary", payload: response});
        }
      }).catch(function (error) {
        console.error("Error:" + error);      
      });
    }
  };

  useEffect(() => {
    if(state.itinerary){
      setShowItinerary(true);
    }
  }, [state.itinerary])

  useEffect(() => {
    if(state.htlItinerary){
      setShowHtlItinerary(true);
    }
  }, [state.htlItinerary])

  const downloadIti = async(obj) => {
    if(obj.product.toUpperCase() === "FLIGHT") {
      try {
        setLoading(true);
        setDownloadTripItinerary(false);
        const payload = {
          booking_id: obj.bk_ref_no
        };
        let tokenKey = Utilities.generateAPIToken(payload);
        getFlightItinerary(payload,tokenKey).then((response) => {
          if (response.suc) {
            dispatch({ type: "downloadItinerary", payload: response});
            setBkId(obj.bk_ref_no);
          }
        }).catch(function (error) {
          console.error("Error fetching data:" + error);      
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      } 
      finally {
        setLoading(false);
      }
    }
    if(obj.product.toUpperCase() === "HOTEL") {
      try {
        setLoading(true);
        setDownloadHtlTripItinerary(false);
        const payload = {
          booking_id: obj.bk_ref_no
        };
        let tokenKey = Utilities.generateAPIToken(payload);
        getHotelItinerary(payload,tokenKey).then((response) => {
          if (response.suc) {
            dispatch({ type: "downloadHtlItinerary", payload: response});
            setBkId(obj.bk_ref_no);
          }
        }).catch(function (error) {
          console.error("Error fetching data:" + error);      
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      } 
      finally {
        setLoading(false);
      }
    }
  }

  useEffect(() => {
    if(state.downloadItinerary){
      generateFlightItineraryPDF()
      setDownloadTripItinerary(true);
    }
  }, [state.downloadItinerary])

  useEffect(() => {
    if(state.downloadHtlItinerary){
      generateHotelItineraryPDF()
      setDownloadHtlTripItinerary(true);
    }
  }, [state.downloadHtlItinerary])
  

  const closeItinerary = () => {
    setShowItinerary(false);
  };

  const closeHtlItinerary = () => {
    setShowHtlItinerary(false)
  }

  const componentRef = useRef();
  const opendlevent =
    useReactToPrint({
      content: () => componentRef.current,
      documentTitle: "FLIGHT_ITINERARY"
    }
  );

  const generateFlightItineraryPDF = () => {

    // Create HTML content for PDF
    const pdfContentFlight = componentRef.current;

    // Configure PDF options
    const pdfOptions = {
      margin: [10,5],
      filename: "FLIGHT_ITINERARY-"+bkId,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2, allowTaint: false, useCORS: true, letterRendering: true },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" }
    };

    // Generate PDF
    html2pdf(pdfContentFlight, pdfOptions);
  };

  const componentHotelRef = useRef();
  const opendleventHotel =
    useReactToPrint({
      content: () => componentHotelRef.current,
      documentTitle: "HOTEL_ITINERARY"
    }
  );

  const generateHotelItineraryPDF = () => {

    // Create HTML content for PDF
    const pdfContentHotel = componentHotelRef.current;

    // Configure PDF options
    const pdfOptions = {
      margin: [10,5],
      filename: "HOTEL_ITINERARY-"+bkId,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2, allowTaint: false, useCORS: true, letterRendering: true },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" }
    };

    // Generate PDF
    html2pdf(pdfContentHotel, pdfOptions);
  };

  const upcomingTrips = () => {
    setSearchTerm("");
    const data = JSON.parse(localStorage.getItem("SIGNINRESPONSE"));
    if (data !== null) {
      const payload = {
        header_st: "Upcoming",
        usr_id: data.res.id
      };
      let tokenKey = Utilities.generateAPIToken(payload);
      getMytrips(payload,tokenKey).then((response) => {
        if (response.suc) {
          setTableData(response.res.bk_list);
          loadResponse(response.res.bk_list);
        }
        if(response?.res?.bk_list.length === 0) {
          dispatch({ type: "res", payload: [] });
        }
      }).catch(function (error) {
        console.error("Error:" + error);      
      });
    }
    dispatch({ type: "upcomingActive", payload: true });
    dispatch({ type: "All", payload: false });
    dispatch({ type: "cancelledActive", payload: false });
    dispatch({ type: "completedActive", payload: false });
  };

  const cancelledTrips = () => {
    setSearchTerm("");
    const data = JSON.parse(localStorage.getItem("SIGNINRESPONSE"));
    if (data !== null) {
      const payload = {
        header_st: "Cancelled",
        usr_id: data.res.id
      };
      let tokenKey = Utilities.generateAPIToken(payload);
      getMytrips(payload,tokenKey).then((response) => {
        if (response.suc) {
          setTableData(response.res.bk_list);
          loadResponse(response.res.bk_list);
        }
        if(response?.res?.bk_list.length === 0) {
          dispatch({ type: "res", payload: [] });
        }
      }).catch(function (error) {
        console.error("Error:" + error);      
      });
    }
    dispatch({ type: "cancelledActive", payload: true });
    dispatch({ type: "All", payload: false });
    dispatch({ type: "upcomingActive", payload: false });
    dispatch({ type: "completedActive", payload: false });
  };

  const completedTrips = () => {
    setSearchTerm("");
    const data = JSON.parse(localStorage.getItem("SIGNINRESPONSE"));
    if (data !== null) {
      const payload = {
        header_st: "Completed",
        usr_id: data.res.id
      };
      let tokenKey = Utilities.generateAPIToken(payload);
      getMytrips(payload,tokenKey).then((response) => {
        if (response.suc) {
          setTableData(response.res.bk_list);
          loadResponse(response.res.bk_list);
        }
        if(response?.res?.bk_list.length === 0) {
          dispatch({ type: "res", payload: [] });
        }
      }).catch(function (error) {
        console.error("Error:" + error);      
      });
    }
    dispatch({ type: "completedActive", payload: true });
    dispatch({ type: "All", payload: false });
    dispatch({ type: "cancelledActive", payload: false });
    dispatch({ type: "upcomingActive", payload: false });
  };

  // Table columns
  const tripsColumn = [
    {
      dataIndex: "trip",
      key: "trip",
      id: "id",
    },
    {
      dataIndex: "bookId",
      key: "bookId",
      id: "id",
    },
    {
      dataIndex: "bookStatus",
      key: "bookStatus",
      id: "id",
    },    
    {
      dataIndex: "options",
      key: "options",
      id: "id",
    },
  ];

  const handleSearch = (value) => {
    setSearchTerm(value);
  };

  const handleSelectChange = (value) => {
    setSelectedOption(value);
  };

  // Filter the data based on the search term and selected option
  const filteredData = state.res.filter((item) => {
    const matchesSearch = item.bookingId.toString().includes(searchTerm);
    if (selectedOption === "all") {
      return matchesSearch;
    } else if (selectedOption === "flight") {
      return item.product.toUpperCase() === "FLIGHT" && matchesSearch;
    } else if (selectedOption === "hotel") {
      return item.product.toUpperCase() === "HOTEL" && matchesSearch;
    }
  });

  const homePage = () => {
    navigate("/");
  }

  if (context.user_resp === "" && localStorage.getItem("SIGNINRESPONSE") === null) {
    return ( 
        <Modal
          title=""
          open={true}
          width={460}
          footer={null}
          maskClosable={false}
        >
          <Title level={4}>
            {/* {context.translation && context.translation.plzlog} */}
            {(sessionStorage.getItem("SEL_LAG") === null || sessionStorage.getItem("SEL_LAG") === "English") &&
              "Please log in to access your profile"              
            }
            {sessionStorage.getItem("SEL_LAG") === "Arabic" &&
              "يرجى تسجيل الدخول لملفك الشخصي"              
            }
          </Title>

          <Button type="primary" className="cntBtn sendBtn" onClick={homePage}>
            {/* {context.translation && context.translation.backHg} */}
            {(sessionStorage.getItem("SEL_LAG") === null || sessionStorage.getItem("SEL_LAG") === "English") &&
              "Back to Home Page"              
            }
            {sessionStorage.getItem("SEL_LAG") === "Arabic" &&
              "عودة للصفحة الرئيسية"              
            }
          </Button>
        </Modal>
    );
  }

  const update=(type)=>{
  }

  return (
    <>
      <Header setTab={update}/>
      <div className="myAccountPage">
        <div className="myAccountBanner"></div>
        <div className="container">
          <Row>
            <Col md={5} className="firstChild">
              <Sidebar active="trips" />
            </Col>
            <Col md={19} className="lastChild">
              <div className="cardDetails myTripDetails">
                <div className="cardDetailsHeader">
                    <div>
                    <Title level={4} className="myTrip">
                      {context.translation && context.translation.mytrips}
                    </Title>
                    <p>{context.translation && context.translation.ytd}</p>
                    </div>
                    <div className="headerFilterWrapper">
                    <Form className="headerFilter">
                      <Form.Item className="allFilterWrapper">
                        <Select
                          defaultValue={context.translation && context.translation.all}
                          className="allFilter"
                          value={selectedOption}
                          onChange={handleSelectChange}
                        >
                          <Option value="all">{context.translation && context.translation.all}</Option>
                          <Option value="flight">{context.translation && context.translation.flights}</Option>
                          <Option value="hotel">{context.translation && context.translation.hotels}</Option>
                        </Select>
                        <i className="icon icon-down-arrow"></i>
                      </Form.Item>
                      <Form.Item className="searchBookingWrapper">
                        <Input
                          className="searchBooking"
                          placeholder={context.translation && context.translation.searchBok}
                          value={searchTerm}
                          onChange={(e) => handleSearch(e.target.value)}
                        />
                        <i className="icon icon-search"></i>
                      </Form.Item>
                    </Form>
                  </div>
                </div>
                <div className="cardDetailsBody tripsDetailsHeader">
                <Tabs 
                  activeKey={activeTitle} 
                  onTabClick={handleTitleClick}
                  items={[
                    {
                      key: 'Title1',
                      className: 'upcoming',
                      label: <div>{customIcons.Title1}<span>{context.translation && context.translation.upcoming}</span></div>,
                      children: 
                      <>
                        {state.res.length > 0 ? (
                          <div className="tripDetailsBody">
                            {filteredData.length === 0 ? (
                              <Space
                              direction="horizontal"
                              align="center"
                              style={{
                                width: "100%",
                                justifyContent: "center",
                                alignItems: "center",
                                minHeight: "300px",
                              }}
                            >
                              <Title level={3} style={{ marginTop: "0.5em" }}>
                              {context.translation && context.translation.norecord}
                              </Title>
                            </Space>
                            ) : (
                              
                              <Table
                                dataSource={filteredData}                        
                                columns={tripsColumn}
                              />
                            )}
                          </div>
                        ) : (
                          <Space
                            direction="horizontal"
                            align="center"
                            style={{ width: "100%", justifyContent: "center", marginTop: "15px", minHeight: "300px", }}
                          >
                            <Title level={3}>{context.translation && context.translation.nodata}</Title>
                          </Space>
                        )}
                      </>
                    },
                    {
                      key: 'Title2',
                      label: <div>{customIcons.Title2}<span>{context.translation && context.translation.cancelled}</span></div>,
                      children: 
                      <>
                        {state.res.length > 0 ? (
                          <div className="tripDetailsBody">
                            {filteredData.length === 0 ? (
                              <Space
                              direction="horizontal"
                              align="center"
                              style={{
                                width: "100%",
                                justifyContent: "center",
                                alignItems: "center",
                                minHeight: "300px",
                              }}
                            >
                              <Title level={3} style={{ marginTop: "0.5em" }}>
                              {context.translation && context.translation.norecord}
                              </Title>
                            </Space>
                            ) : (
                              
                              <Table
                                dataSource={filteredData}                        
                                columns={tripsColumn}
                              />
                            )}
                          </div>
                        ) : (
                          <Space
                            direction="horizontal"
                            align="center"
                            style={{ width: "100%", justifyContent: "center", marginTop: "15px", minHeight: "300px", }}
                          >
                            <Title level={3}>{context.translation && context.translation.nodata}</Title>
                          </Space>
                        )}
                      </>
                    },
                    {
                      key: 'Title3',
                      label: <div>{customIcons.Title3}<span>{context.translation && context.translation.completed}</span></div>,
                      children: 
                      <>
                        {state.res.length > 0 ? (
                          <div className="tripDetailsBody">
                            {filteredData.length === 0 ? (
                             <Space
                             direction="horizontal"
                             align="center"
                             style={{
                               width: "100%",
                               justifyContent: "center",
                               alignItems: "center",
                               minHeight: "300px",
                             }}
                           >
                             <Title level={3} style={{ marginTop: "0.5em" }}>
                             {context.translation && context.translation.norecord}
                             </Title>
                           </Space>
                            ) : (
                              
                              <Table
                                dataSource={filteredData}                        
                                columns={tripsColumn}
                              />
                            )}
                          </div>
                        ) : (
                          <Space
                            direction="horizontal"
                            align="center"
                            style={{ width: "100%", justifyContent: "center", marginTop: "15px", minHeight: "300px", }}
                          >
                            <Title level={3}>{context.translation && context.translation.nodata}</Title>
                          </Space>
                        )}
                      </>
                    },
                  ]}>
                </Tabs>
                </div>
                    <div className="statusWrapper">
                    </div>
              </div>
              {showItinerary && (
                <div>
                  <Drawer placement="right" open={showItinerary} className="viewItineraryDrawer">
                    <i className="icon icon-cancel" onClick={closeItinerary}></i>
                    <MyTripsItineraryPopup close={closeItinerary} itiData={state.itinerary.res[0]} active={activeTitle} />
                  </Drawer>
                </div>
              )}
              
              <div style={{ display: "none" }}>
                {loading ? <Spin size="large" /> : (
                  <FlightItinerarypdf ref={componentRef} confirmationObj={state.downloadItinerary?.res[0]} baggage="baggageDesign" />
                )}
              </div>
              
              {showHtlItinerary && (
                <div>
                  <Drawer placement="right" open={showHtlItinerary} className="viewItineraryDrawer">
                    <i className="icon icon-cancel" onClick={closeHtlItinerary}></i>
                    <MyTripsHtlItineraryPopup close={closeHtlItinerary} itiData={state.htlItinerary.res} active={activeTitle} />
                  </Drawer>
                </div>
              )}      
              
              <div style={{ display: "none" }}>
                <MyTripsHtlItineraryPdf ref={componentHotelRef} itiData={state.downloadHtlItinerary?.res} />
              </div>

            </Col>
          </Row>
        </div>
      </div>

      <Footer />
    </>
  );
}

const UpcomingIcon = () => <span className="tabIcon"><i className="icon icon-Trips"></i></span>;
const CancelledIcon = () => <span className="tabIcon"><i className="icon icon-Cancelled-Booking"></i></span>;
const CompletedIcon = () => <span className="tabIcon"><i className="icon icon-Upcomig-Booking"></i></span>;


export default MyTrips;
