import React, { useEffect } from "react";
import { Context } from "../../../../App";
import { useContext } from "react";
import { Button, Radio } from "antd";
import HotelSearch from "../search/HotelSearch";
import HotelRoomOccupancy from "./HotelRoomOccupancy";
import { useState } from "react";
import { isArrayNotEmpty } from "../../../utilities/validators";
import HotelRoomHeader from "./HotelRoomHeader";
import { Link } from "react-router-dom";
import HotelRoomSelection from "./HotelRoomSelection";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function HotelRoomsAndPrices(props) {
  const [context, setContext] = useContext(Context)
  const {
    boardBasisList,
    applyBoardBasis,
    isRefundable,
    isNonRefundable,
    showClear,
    clearBoardBasis,
    precepernight,
    value,
    handleChange,
    sendSelRoom,
    sendRoomInfo,
    ocpnsList1,
    noofNights,
    pricePerNight,
    onflyMkp,
    updateResults,
    roomOneOcpList,
    roomTwoOcpList,
  roomThreeOcpList,
  roomForOcpList,
  roomFiveOcpList,
  roomSixOcpList,
  roomSevenOcpList,
  roomEightOcpList,
    checkRoomPrice,
    showRoomInfo,
    selectedRooms,
    showRoomChoice,
    finalSelRooms,
    searchRequest,
    base_usr_exrate
  } = props;
  const [showFcList, setShowFcList] = useState(false);
  const [refund, setRefund] = useState(false);
  const [nonRefund, setNonRefund] = useState(false);
  const [roomOneOcpListshow, setRoomOneOcpList] = useState(false);
  const [roomOtwocpListshow, setRoomtwoOcpList] = useState(false);
  const [roomthreeOcpListshow, setRoomthreeOcpList] = useState(false);
  const [roomfourOcpListshow, setRoomfourOcpList] = useState(false);
  const [roomfiveOcpListshow, setRoomfiveOcpList] = useState(false);
  const [roomsixOcpListshow, setRoomsixOcpList] = useState(false);
  const [roomsvnOcpListshow, setRoomsvnOcpList] = useState(false);
  const [roomeightOcpListshow, setRoomeightOcpList] = useState(false);
  const [isPerPrice, setIsPerPrice] = useState(false);
  const [request, setRequest] = useState(props.searchRequest);
  const [cur, setCur] = useState("");

  useEffect(() => {
    setCur(context.userCur!==undefined && context.userCur!==""?context.userCur:sessionStorage.getItem("SEL_CUR"));
  }, [sessionStorage.getItem("SEL_CUR") || context.userCur])

  useEffect(() => {
    if(props.searchRequest){
      setRequest(props.searchRequest);
    }
  }, [props.searchRequest])

  useEffect(() => {
    if(!props.pricePerNight){
      setIsPerPrice(false)
    }else{
      setIsPerPrice(true)
    }
  }, [props.pricePerNight])

  var settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
    // rtl: false,
    // nextArrow: <SampleNextArrow />,
    // prevArrow: <SamplePrevArrow />
  };

  return (
    <>
      <div className="modifySearch hotelDetailsSearch">
      <div className="modifyTitle">{context.translation && context.translation.selormodifyrooms} :</div>
        <HotelSearch modify={true} data={request} isDetailsModify={true} htl={window.htl} isPackage={window.isPackage} crosssell={window.crosssell} scrollDown={true}
        />
      </div>
      {showRoomChoice && (
          <HotelRoomSelection
            selectedRooms={selectedRooms}
            onflyMkp={onflyMkp}
            noofNights={noofNights}
            finalSelRooms={finalSelRooms}
            base_usr_exrate={base_usr_exrate}
          />
      )}
      <div className="hotelRoomSelection" id="chooseRoom">
      
        <div className="hotelRoomFilter commonFlex">
          <div className="buttonFilterWrapper">
          {boardBasisList.length !== 0 && <div className="showOnlyRooms">{context.translation && context.translation.showOnlyroom}</div>}
         
               <div className="filterButtonWrap"> 
            {boardBasisList.length !== 0 &&
              boardBasisList
                .map((bb, indx) => (
                  
                    <Button
                      size="sm"
                      onClick={applyBoardBasis(bb)}
                      className={"filterButton"}
                    >
                      {bb}
                    </Button>
                 
                ))
                .slice(0, showFcList ? props.amenities.length : "5")}
                
            {isRefundable == true && (
              <Button
                size="sm"
                onClick={applyBoardBasis("Refundable")}
                className={
                  "filterButton" +
                  (refund ? "active" : "")
                }
                variant="outline-primary"
              >
                {context.translation && context.translation.penaltyApp}
              </Button>
            )}
                
                {isNonRefundable == true && (
                  <Button
                    size="sm"
                    onClick={applyBoardBasis("Non-Refundable")}
                    className={
                      "filterButton" +
                      (nonRefund ? "active" : "")
                    }
                    variant="outline-primary"
                  >
                    {context.translation && context.translation.nonRefund}
                  </Button>
                )}
                
                {isArrayNotEmpty(boardBasisList) && showClear && (
                  <Button
                    size="sm"
                    className="fliterClear ezyIcon icon-close"
                    onClick={clearBoardBasis}
                    variant="outline-primary"
                  >
                    
                  </Button>
                )}
                </div> 
                
            {/*  <span className='roomsOnly'>Show only rooms with</span>
              <span><Button className='filterButton active'>Room Only</Button></span>
              <span><Button className='filterButton'>Free Breakfast</Button></span>
              <span><Button className='filterButton'>Refundable</Button></span> */}
          </div>
          
          <div className="radioFilterWrapper">

            <Radio onChange={handleChange} value={!isPerPrice} checked={!isPerPrice} >
              <span className="chooseOne">{context.translation && context.translation.totalStay}</span>
            </Radio>
            <Radio onChange={handleChange} value={isPerPrice} checked={isPerPrice}>
              <span className="chooseOne">{context.translation && context.translation.avgPricenight}</span>
            </Radio>
          </div>
        </div>
        {updateResults && (
          <>
            <div>
              {roomOneOcpList.length !== 0 && (
                <div className="roomFilterSelection">
                  {roomOneOcpList
                    .map((ocp, index) => (
                      <>
                        {" "}
                        {index === 0 && (
                          <HotelRoomHeader rmno={ocp.rmindex} ocp={ocp} />
                        )}
                      
                      </>
                    ))
                    }
                    <div className="hotelDetailsFilter">
                      <div>
                  {roomOneOcpList
                    .map((ocp, index) => (
                      <>
                      <HotelRoomOccupancy
                          rmocp1={ocp}
                          rmpr={ocp.rmpr[0].pr[0]}
                          cur={cur}
                          idx={index + 1}
                          sendSelRoom={checkRoomPrice}
                          sendRoomInfo={sendRoomInfo}
                          ocpnsList1={roomOneOcpList}
                          nights={noofNights}
                          pricePerNight={isPerPrice}
                          onflyMkp={onflyMkp}
                          base_usr_exrate={base_usr_exrate}
                        />
                        
                      </>
                    ))
                    .slice(0, roomOneOcpListshow ? roomOneOcpList.length : "2")}
                    </div>
                  {roomOneOcpList.length >= 3 ? (
                    <Link
                      onClick={() => setRoomOneOcpList(!roomOneOcpListshow)} className={roomOneOcpListshow ? "showMoreOption active" : "showMoreOption"}
                    >
                      {roomOneOcpListshow ? (
                        <span> {context.translation && context.translation.showLessop} </span>
                      ) : (
                        <span>
                          {context.translation && context.translation.showMoreop}
                        </span>
                      )}
                    </Link>
                  ) : (
                    ""
                  )}</div>
                </div>
              )}
            </div>



            <div>
              {roomTwoOcpList.length !== 0 && (
                <div className="roomFilterSelection">
                  {roomTwoOcpList
                    .map((ocp, index) => (
                      <>
                        {" "}
                        {index === 0 && (
                          <HotelRoomHeader rmno={ocp.rmindex} ocp={ocp} />
                        )}
                        
                      </>
                    )) }
                     <div className="hotelDetailsFilter">
                      <div>

{roomTwoOcpList
                    .map((ocp, index) => (
                      <>
                       
                        <HotelRoomOccupancy
                          rmocp2={ocp}
                          rmpr={ocp.rmpr[0].pr[0]}
                          cur={cur}
                          idx={index + 1}
                          sendSelRoom={checkRoomPrice}
                          sendRoomInfo={sendRoomInfo}
                          ocpnsList2={roomTwoOcpList}
                          nights={noofNights}
                          pricePerNight={isPerPrice}
                          onflyMkp={onflyMkp}
                          base_usr_exrate={base_usr_exrate}
                        />
                      </>
                    ))
                    .slice(0, roomOtwocpListshow ? roomTwoOcpList.length : "2")}
                   </div>
                  {roomTwoOcpList.length >= 2 ? (
                    <Link
                      onClick={() => setRoomtwoOcpList(!roomOtwocpListshow)} className={roomOtwocpListshow ? "showMoreOption active" : "showMoreOption"}
                    >
                      {roomOtwocpListshow ? (
                        <span> {context.translation && context.translation.showLessop} </span>
                      ) : (
                        <span>
                          {context.translation && context.translation.showMoreop}
                        </span>
                      )}
                    </Link>
                  ) : (
                    ""
                  )}
                   
                   </div>
                </div>
              )}
            </div>
            <div>
              {roomThreeOcpList.length !== 0 && (
                <div className="roomFilterSelection">

                    {roomThreeOcpList
                    .map((ocp, index) => (
                      <>
                        {" "}
                        {index === 0 && (
                          <HotelRoomHeader rmno={ocp.rmindex} ocp={ocp} />
                        )}
                       
                      </>
                    ))
                    }
                <div className="hotelDetailsFilter">
                  <div>
                  {roomThreeOcpList
                    .map((ocp, index) => (
                      <>
                        
                        <HotelRoomOccupancy
                          rmocp3={ocp}
                          rmpr={ocp.rmpr[0].pr[0]}
                          cur={cur}
                          idx={index + 1}
                          sendSelRoom={checkRoomPrice}
                          sendRoomInfo={sendRoomInfo}
                          ocpnsList3={roomThreeOcpList}
                          nights={noofNights}
                          pricePerNight={isPerPrice}
                          onflyMkp={onflyMkp}
                          base_usr_exrate={base_usr_exrate}
                        />
                      </>
                    ))
                    .slice(0, roomthreeOcpListshow ? roomThreeOcpList.length : "2")}
                   </div>
                  {roomThreeOcpList.length >= 2 ? (
                    <Link
                      onClick={() => setRoomthreeOcpList(!roomthreeOcpListshow)} className={roomthreeOcpListshow ? "showMoreOption active" : "showMoreOption"}
                    >
                      {roomthreeOcpListshow ? (
                        <span> {context.translation && context.translation.showLessop} </span>
                      ) : (
                        <span>
                          {context.translation && context.translation.showMoreop}
                        </span>
                      )}
                    </Link>
                  ) : (
                    ""
                  )}
                   
                   </div>
                </div>
              )}
            </div>
            <div>
              {roomForOcpList.length !== 0 && (
                <div className="roomFilterSelection">
                  {roomForOcpList
                    .map((ocp, index) => (
                      <>
                        {" "}
                        {index === 0 && (
                          <HotelRoomHeader rmno={ocp.rmindex} ocp={ocp} />
                        )}
                        
                      </>
                    ))}
                     <div className="hotelDetailsFilter">
                      <div>

{roomForOcpList
                    .map((ocp, index) => (
                      <>
                        
                        <HotelRoomOccupancy
                          rmocp4={ocp}
                          rmpr={ocp.rmpr[0].pr[0]}
                          cur={cur}
                          idx={index + 1}
                          sendSelRoom={checkRoomPrice}
                          sendRoomInfo={sendRoomInfo}
                          ocpnsList4={roomForOcpList}
                          nights={noofNights}
                          pricePerNight={isPerPrice}
                          onflyMkp={onflyMkp}
                          base_usr_exrate={base_usr_exrate}
                        />
                      </>
                    ))
                    .slice(0, roomfourOcpListshow ? roomForOcpList.length : "2")}
                    </div>
                  {roomForOcpList.length >= 2 ? (
                    <Link
                      onClick={() => setRoomfourOcpList(!roomfourOcpListshow)} className={roomfourOcpListshow ? "showMoreOption active" : "showMoreOption"}
                    >
                      {roomfourOcpListshow ? (
                        <span> {context.translation && context.translation.showLessop} </span>
                      ) : (
                        <span>
                          {context.translation && context.translation.showMoreop}
                        </span>
                      )}
                    </Link>
                  ) : (
                    ""
                  )}
                
                </div>
                </div>
              )}
            </div>
            <div>
              {roomFiveOcpList.length !== 0 && (
                <div className="roomFilterSelection">
                  {roomFiveOcpList
                    .map((ocp, index) => (
                      <>
                         
                        {index === 0 && (
                          <HotelRoomHeader rmno={ocp.rmindex} ocp={ocp} />
                        )}
                         
                      </>
                    ))}
 <div className="hotelDetailsFilter">
  <div>
{roomFiveOcpList
                    .map((ocp, index) => (
                      <>
                       
                       
                        <HotelRoomOccupancy
                          rmocp5={ocp}
                          rmpr={ocp.rmpr[0].pr[0]}
                          cur={cur}
                          idx={index + 1}
                          sendSelRoom={checkRoomPrice}
                          sendRoomInfo={sendRoomInfo}
                          ocpnsList5={roomFiveOcpList}
                          nights={noofNights}
                          pricePerNight={isPerPrice}
                          onflyMkp={onflyMkp}
                          base_usr_exrate={base_usr_exrate}
                        />
                      </>
                    ))
                    .slice(0, roomfiveOcpListshow ? roomFiveOcpList.length : "2")}
                    </div>
                  {roomFiveOcpList.length >= 2 ? (
                    <Link
                      onClick={() => setRoomfiveOcpList(!roomfiveOcpListshow)} className={roomfiveOcpListshow ? "showMoreOption active" : "showMoreOption"}
                    >
                      {roomfiveOcpListshow ? (
                        <span> {context.translation && context.translation.showLessop} </span>
                      ) : (
                        <span>
                          {context.translation && context.translation.showMoreop}
                        </span>
                      )}
                    </Link>
                  ) : (
                    ""
                  )}
                  
                  </div>
                </div>
              )}
            </div>
            <div>
              {roomSixOcpList.length !== 0 && (
                <div className="roomFilterSelection">
                  {roomSixOcpList
                    .map((ocp, index) => (
                      <>
                        {" "}
                        {index === 0 && (
                          <HotelRoomHeader rmno={ocp.rmindex} ocp={ocp} />
                        )}
                        
                      </>
                    ))}
                    <div className="hotelDetailsFilter">
                      <div>

{roomSixOcpList
                    .map((ocp, index) => (
                      <>
                        
                        <HotelRoomOccupancy
                          rmocp6={ocp}
                          rmpr={ocp.rmpr[0].pr[0]}
                          cur={cur}
                          idx={index + 1}
                          sendSelRoom={checkRoomPrice}
                          sendRoomInfo={sendRoomInfo}
                          ocpnsList6={roomSixOcpList}
                          nights={noofNights}
                          pricePerNight={isPerPrice}
                          onflyMkp={onflyMkp}
                          base_usr_exrate={base_usr_exrate}
                        />
                      </>
                    ))
                    .slice(0, roomsixOcpListshow ? roomSixOcpList.length : "2")}
                    </div>
                  {roomSixOcpList.length >= 2 ? (
                    <Link
                      onClick={() => setRoomsixOcpList(!roomsixOcpListshow)} className={roomsixOcpListshow ? "showMoreOption active" : "showMoreOption"}
                    >
                      {roomsixOcpListshow ? (
                        <span> {context.translation && context.translation.showLessop} </span>
                      ) : (
                        <span>
                          {context.translation && context.translation.showMoreop}
                        </span>
                      )}
                    </Link>
                  ) : (
                    ""
                  )}
                  
                  </div>
                </div>
              )}
            </div>
            <div>
              {roomSevenOcpList.length !== 0 && (
                <div className="roomFilterSelection">
                  {roomSevenOcpList
                    .map((ocp, index) => (
                      <>
                        {" "}
                        {index === 0 && (
                          <HotelRoomHeader rmno={ocp.rmindex} ocp={ocp} />
                        )}
                        
                      </>
                    ))}
                     <div className="hotelDetailsFilter">
                      <div>
                    {roomSevenOcpList
                    .map((ocp, index) => (
                      <>
                        
                        <HotelRoomOccupancy
                          rmocp7={ocp}
                          rmpr={ocp.rmpr[0].pr[0]}
                          cur={cur}
                          idx={index + 1}
                          sendSelRoom={checkRoomPrice}
                          sendRoomInfo={sendRoomInfo}
                          ocpnsList7={roomSevenOcpList}
                          nights={noofNights}
                          pricePerNight={isPerPrice}
                          onflyMkp={onflyMkp}
                          base_usr_exrate={base_usr_exrate}
                        />
                      </>
                    ))
                    .slice(0, roomsvnOcpListshow ? roomSevenOcpList.length : "2")}
                    </div>
                  {roomSevenOcpList.length >= 2 ? (
                    <Link
                      onClick={() => setRoomsvnOcpList(!roomsvnOcpListshow)} className={roomsvnOcpListshow ? "showMoreOption active" : "showMoreOption"}
                    >
                      {roomsvnOcpListshow ? (
                        <span> {context.translation && context.translation.showLessop} </span>
                      ) : (
                        <span>
                          {context.translation && context.translation.showMoreop}
                        </span>
                      )}
                    </Link>
                  ) : (
                    ""
                  )}
                  
                  </div>
                </div>
              )}
            </div>
            <div>
              {roomEightOcpList.length !== 0 && (
                <div className="roomFilterSelection">
                  {roomEightOcpList
                    .map((ocp, index) => (
                      <>
                        {" "}
                        {index === 0 && (
                          <HotelRoomHeader rmno={ocp.rmindex} ocp={ocp} />
                        )}
                        
                      </>
                    ))
                   }
                   <div className="hotelDetailsFilter">
                    <div>
                    {roomEightOcpList
                    .map((ocp, index) => (
                      <>
                        
                        <HotelRoomOccupancy
                          rmocp8={ocp}
                          rmpr={ocp.rmpr[0].pr[0]}
                          cur={cur}
                          idx={index + 1}
                          sendSelRoom={checkRoomPrice}
                          sendRoomInfo={sendRoomInfo}
                          ocpnsList8={roomEightOcpList}
                          nights={noofNights}
                          pricePerNight={isPerPrice}
                          onflyMkp={onflyMkp}
                          base_usr_exrate={base_usr_exrate}
                        />
                      </>
                    ))
                    .slice(0, roomeightOcpListshow ? roomEightOcpList.length : "2")}
                    </div>
                  {roomEightOcpList.length >= 2 ? (
                    <Link
                      onClick={() => setRoomeightOcpList(!roomeightOcpListshow)} className={roomeightOcpListshow ? "showMoreOption active" : "showMoreOption"}
                    >
                      {roomeightOcpListshow ? (
                        <span> {context.translation && context.translation.showLessop}</span>
                      ) : (
                        <span>
                          {context.translation && context.translation.showMoreop}
                        </span>
                      )}
                    </Link>
                  ) : (
                    ""
                  )}
                  
                  </div>
                </div>
              )}
            </div>
          </>
        )}
        <div className="roomFilterSelection">
          {/* <HotelRoomHeader rmno={ocp.rmindex} ocp={ocp} /> */}
        </div>
      </div>
    </>
  );
}

export default HotelRoomsAndPrices;
