import React, { useEffect, useRef } from "react";
import Footer from "./Footer";
import Header from "./Header";
import ShowHide from "./showHide/ShowHide";
import StickyBox from "react-sticky-box";
import { Col, Row, Typography } from "antd";
import Title from "antd/es/skeleton/Title";

function PrivacyPolicy(props) {
  const tcGeneral = useRef(null);
  const tc1 = useRef(null);
  const tc2 = useRef(null);
  const tc3 = useRef(null);
  const tc4 = useRef(null);
  const tc5 = useRef(null);
  const tc6 = useRef(null);
  const tc7 = useRef(null);
  const tc8 = useRef(null);
  const tc9 = useRef(null);
  const tc10 = useRef(null);
  const tc11 = useRef(null);
  const tc12 = useRef(null);
  const executeScrolltop = () =>
    tcGeneral.current.scrollIntoView({ behavior: "smooth" });
  const executeScrolltop1 = () =>
    tc1.current.scrollIntoView({ behavior: "smooth" });
  const executeScrolltop2 = () =>
    tc2.current.scrollIntoView({ behavior: "smooth" });
  const executeScrolltop3 = () =>
    tc3.current.scrollIntoView({ behavior: "smooth" });
  const executeScrolltop4 = () =>
    tc4.current.scrollIntoView({ behavior: "smooth" });
  const executeScrolltop5 = () =>
    tc5.current.scrollIntoView({ behavior: "smooth" });
  const executeScrolltop6 = () =>
    tc6.current.scrollIntoView({ behavior: "smooth" });
  const executeScrolltop7 = () =>
    tc7.current.scrollIntoView({ behavior: "smooth" });
  const executeScrolltop8 = () =>
    tc8.current.scrollIntoView({ behavior: "smooth" });
  const executeScrolltop9 = () =>
    tc9.current.scrollIntoView({ behavior: "smooth" });
  const executeScrolltop10 = () =>
    tc10.current.scrollIntoView({ behavior: "smooth" });
  const executeScrolltop11 = () =>
    tc11.current.scrollIntoView({ behavior: "smooth" });
  const executeScrolltop12 = () =>
    tc12.current.scrollIntoView({ behavior: "smooth" });
  const general = (e) => {
    executeScrolltop();
  };
  const general1 = (e) => {
    executeScrolltop1();
  };
  const general2 = (e) => {
    executeScrolltop2();
  };
  const general3 = (e) => {
    executeScrolltop3();
  };
  const general4 = (e) => {
    executeScrolltop4();
  };
  const general5 = (e) => {
    executeScrolltop5();
  };
  const general6 = (e) => {
    executeScrolltop6();
  };
  const general7 = (e) => {
    executeScrolltop7();
  };
  const general8 = (e) => {
    executeScrolltop8();
  };
  const general9 = (e) => {
    executeScrolltop9();
  };
  const general10 = (e) => {
    executeScrolltop10();
  };
  const general11 = (e) => {
    executeScrolltop11();
  };
  const general12 = (e) => {
    executeScrolltop12();
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  const { Title } = Typography;
  const update=(type)=>{
  }
  return (
    <>
      <Header posType="floating" setTab={update} />
      <div className="container tndC">
        <Title level={4} className="tcTitle">
        سياسة الخصوصية
        </Title>
        <Row>
          <Col span={6} className="tcleftSidebar">
            <StickyBox offsetTop={100} offsetBottom={20}>
              <ul variant="pills" className="flex-column">
                <li>
                  <span eventKey="general" onClick={general} className="active" title="مقدمة">
                  مقدمة
                  </span>
                </li>
                <li>
                  <span eventKey="clientResp" onClick={general1} title="المستخدمون خارج الحدود الجغرافية">
                  المستخدمون خارج الحدود الجغرافية
                  </span>
                </li>
                <li>
                  <span eventKey="disclaimer" onClick={general2} title="نوع المعلومات التي نجمعها">
                  نوع المعلومات التي نجمعها
                  </span>
                </li>
                <li>
                  <span eventKey="disclaimer" onClick={general3} title="كيف نستخدم معلوماتك الشخصية">
                    كيف نستخدم معلوماتك الشخصية
                  </span>
                </li>
                <li>
                  <span eventKey="pvcStatement" onClick={general4} title="ما هي مدة تخزين معلوماتك الشخصية؟">
                  ما هي مدة تخزين معلوماتك الشخصية؟
                  </span>
                </li>
                <li>
                  <span eventKey="copyright" onClick={general5} title="الكوكيز وبيانات الجلسة">
                  الكوكيز وبيانات الجلسة
                  </span>
                </li>
                <li>
                  <span eventKey="agency" onClick={general6} title="من يحق له الوصول إلى معلوماتك الشخصية؟">
                  من يحق له الوصول إلى معلوماتك الشخصية؟
                  </span>
                </li>
                <li>
                  <span eventKey="confidentiality" onClick={general7} title="كيف يمكنك إلغاء الاشتراك في تلقي رسائل البريد الإلكتروني الترويجية الخاصة بنا؟">
                  كيف يمكنك إلغاء الاشتراك في تلقي رسائل البريد الإلكتروني الترويجية الخاصة بنا؟
                  </span>
                </li>
                <li>
                  <span eventKey="indemnity" onClick={general8} title="الأذونات المطلوبة لاستخدام تطبيق الهاتف المحمول الخاص بنا">
                  الأذونات المطلوبة لاستخدام تطبيق الهاتف المحمول الخاص بنا
                  </span>
                </li>
                <li>
                  <span eventKey="governingLaw" onClick={general9} title=" كيف نحمي معلوماتك الشخصية؟">
                  كيف نحمي معلوماتك الشخصية؟
                  </span>
                </li>
                <li>
                  <span eventKey="forceMajeure" onClick={general10} title="سحب الموافقة والإذن">
                  سحب الموافقة والإذن
                  </span>
                </li>
                <li>
                  <span eventKey="variations" onClick={general11} title="أهلية التعامل مع TTG">
                  أهلية التعامل مع TTG
                  </span>
                </li>
                <li>
                  <span eventKey="indiv" onClick={general12} title="التغييرات في سياسة الخصوصية">
                  التغييرات في سياسة الخصوصية
                  </span>
                </li>
              </ul>
            </StickyBox>
          </Col>
          <Col span={18} className="tcLayout">
            <div ref={tcGeneral}>
              <ShowHide visible={false} icon="" title="مقدمة">
                <div className="showHide-content">
                  <p>
                 . بأهمية خصوصية مستخدميها وضرورة الحفاظ على سرية المعلومات المقدمة من قبل المستخدمين كمسؤول عن معالجة البيانات المسؤول عنها TTG تعترف .Clickntravel.com الموقع الإلكتروني (المشار إليها فيما بعد بـ "TTG") تمتلك وتدير شركة توفيق للسفر ذ.م.م
                  </p>
                  <p>
                 . وفروعها وشركاتها التابعة TTG من قبل (التي تم تعريفها أدناه) تقدم سياسة الخصوصية  هذه للتعامل مع وتأمين معلومات المستخدمين
                  </p>
                  <p>
                 . (المشار إليها هنا جميعاً باسم "قنوات البيع") من خلال أي من قنوات التواصل مع العملاء الخاصة بها، بما في ذلك تطبيقها على الهواتف المحمولة وقنوات البيع غير المتصلة بها، مثل مراكز الاتصال والمكاتب TTG الذي يشتري أو ينوي شراء أو الاستفسار عن أي منتج أو خدمة تقدمها (المستخدم) تنطبق سياسة الخصوصية  هذه على أي شخص
                  </p>
                  <p>
                 .لغرض هذه السياسة الخصوصية .TTG في إطار سياق سياسة الخصوصية هذه، حيثما كان السياق يستدعي ذلك، فإن "أنت" أو "المستخدم" تشير إلى المستخدم، والمصطلح "نحن" أو يشير إلى
                  </p>
                  <p>
                 .باستخدام أو الوصول إلى قنوات البيع، يوافق المستخدم هنا على شروط ومحتويات سياسة الخصوصية  هذه. إذا كنت لا تتفق مع هذه السياسة الخصوصية، يرجى عدم استخدام أو الوصول إلى قنوات البيع الخاصة بنا
                  </p>
                  <p>
                 . روابط لها، قد تكون مختلفة تماماً عن سياسة الشركة. وبناءً على ذلك، يفضل على المستخدم مراجعة بيانات الخصوصية والسياسات الخاصة بهؤلاء الأطراف الثالثة التي يتعامل معها TTG والمعلنين، والرعاة، أو المواقع الأخرى التي يقدم ,TTG لا تنطبق سياسة الخصوصية  هذه على أي موقع ويب أو مواقع ويب أو تطبيقات جوالة تابعة لأطراف ثالثة، حتى لو كانت مواقعهم/منتجاتهم مرتبطة بأي من قنوات البيع لدينا. يجب على المستخدم أن يلاحظ أن معلومات وممارسات الخصوصية لشركاء الأعمال لدى
                  </p>
                  <p>
                 .وأي مصطلح قد يتم استخدامه ولكنه لم يذكر هنا يجب أن يستدل بمعناه في اتفاقية المستخدم ,TTG تعتبر سياسة الخصوصية  هذه  جزءاً لا يتجزأ من اتفاقية المستخدم الخاص بك مع
                  </p>
                </div>
              </ShowHide>
            </div>
            <div ref={tc1}>
              <ShowHide
                visible="true"
                icon=""
                title="المستخدمون خارج الحدود الجغرافية"
              >
                <div className="showHide-content">
                  <p>
                 . بمعالجة معلوماتك الشخصية للأغراض المحددة في هذه السياسة. قد تختلف لوائح حماية البيانات في قطر أو في السلطات القانونية الأخرى المذكورة أعلاه عن تلك في بلد إقامتك TTG  بمعالجة البيانات نيابة عنها. وبالموافقة على هذه السياسة، فإنك تقدم تصريحاً لـ TTG ستتم معالجتها في المقام الأول في قطر وأي سلطة قانونية أخرى قد تقوم جهة خارجية يتعاقد معها  TTG يرجى ملاحظة أن البيانات التي تتم مشاركتها مع 
                  </p>
                  <p>
                    <a href="mailto:privacy@att.qa">privacy@att.qa</a>: إذا كانت لديك أي مخاوف بشأن معالجة بياناتك وترغب في سحب موافقتك، يمكنك القيام بذلك عبر الكتابة إلى البريد الإلكتروني التالي
                  </p>
                  <p>
                 . فيمكن أن يتوجب علينا مشاركة بعض معلوماتك الشخصية مثل معلومات الاتصال، والجنس، وتفضيلات النظام الغذائي، واختيار الغرفة مع تفضيل التدخين، وأي حالة طبية قد تتطلب اهتمامًا أو مرفقاً محدداً ومعالجتها من قبل بائعينا، وقد يتم معالجة هذه المعلومات بشكل إضافي لترتيبات مناسبة خلال رحلتك ,(رحلة أو فندق أو أي منتج آخر) ومع ذلك، إذا كانت معالجة مثل هذه البيانات ضرورية لنا لتقديم خدمة لك، قد لا نكون قادرين على خدمتك أو تأكيد حجوزاتك بعد سحب موافقتك. على سبيل المثال، إذا قمت بحجز
                  </p>
                  <p>
                 : سحب موافقتك لنا في معالجة معلوماتك قد يؤدي إلى
                    <ul style={{ listStyleType: "number" }}>
                      <li>
                      ... تقييد قدرتنا بشكل كبير على خدمتك بشكل صحيح، وفي هذه الحالة قد نضطر إلى رفض الحجز بشكل كامل، أو-
                      </li>
                      <li>
                     . مما قد يؤثر أيضاً على رحلتك أو يجبرنا على إلغاء حجزك ,(إذا كان الحجز قد تم بالفعل) تقييدنا بشكل غير مبرر عن خدمة حجزك-
                      </li>
                    </ul>
                  </p>
                </div>
              </ShowHide>
            </div>
            <div ref={tc2}>
              <ShowHide
                visible="true"
                icon=""
                title="نوع المعلومات التي نجمعها"
              >
                <div className="showHide-content">
                  <p>
                 .المعلومات المفصلة أدناه تُجمع لكي نكون قادرين على تقديم الخدمات التي اخترتها، وأيضًا لنفيذ التزاماتنا القانونية وتجاه الأطراف الثالثة وفقًا لاتفاقية المستخدم
                  </p>
                  <p>
                 :للمستخدم تشمل المعلومات التي يشاركها المستخدم والتي نقوم بجمعها للأغراض التالية<strong>"المعلومات الشخصية"</strong> 
                    
                    <ul style={{ listStyleType: "number" }}>
                      <li>
                     .(كما يتم مشاركتها من قبلك) وتاريخ الدفع وغيرها (بما في ذلك بطاقة الائتمان/الخصم) قد تتضمن المعلومات أيضًا بيانات مثل تفاصيل البنك .(الجوال أو غيره) المعلومات التي تقدمها أثناء الاشتراك أو التسجيل على أي من قنوات المبيعات لدينا، بما في ذلك على سبيل المثال لا الحصر، معلومات حول هويتك الشخصية مثل الاسم، الجنس، الحالة الاجتماعية، الديانة، العمر، صورة الملف الشخصي، وبيانات الاتصال مثل عنوان البريد الإلكتروني، رقم المسافر الدائم، و/أو رقم الهاتف <strong>:التسجيل على قناة المبيعات</strong>{" "}
                       
                      </li>
                      <li>
                     : قد نقوم أيضاً بجمع بعض المعلومات والوثائق الأخرى بما في ذلك على سبيل المثال لا الحصر <strong>:معلومات أخرى</strong>
                        <ul>
                          <li>
                          . حول أنشطتك التجارية الإلكترونية وسلوكيات الشراء (باستثناء تفاصيل البنك) تاريخ المعاملات-                          </li>
                          <li>
                         .أسماء المستخدم، وكلمات المرور، وعناوين البريد الإلكتروني، ومعلومات أمان أخرى تتعلق بخدماتنا -
                          </li>
                          <li>
                         .بيانات تم إنشاؤها إما من قبلك أو من طرف ثالث وترغب في تخزينها على خوادمنا مثل ملفات الصور والوثائق وما إلى ذلك -
                          </li>
                          <li>
                         .الذين تم حجزهم بأنهم قد وافقوا على مشاركة المعلومات التي قدمتها لنا وبينتها لنا والموافقة على مشاركتها مع مقدمي الخدمة ذوي الصلة (ين) آخر(ين) الخاص بك. في مثل هذه الحالة، يجب عليك تأكيد وتمثيل كل مسافر TTG قمت بالحجز لهم من خلال حساب (ين) آخر(ين) معلومات تتعلق بأي مسافر-
                          </li>
                          <li>
                         .في حال اختيارك لتسجيل الوصول اونلاين عند الوصول إلى الفنادق، يمكن أن نقوم بجمع نسخ من وثائق الهوية الحكومية مثل بطاقة الهوية القطرية، رخصة القيادة، والتصريح الشخصي وأي معلومات أخرى مثل تاريخ الميلاد، وجهة/منشأ السفر، ومكان الإقامة التي قد تكون مطلوبة من الفندق المعني بإستضافة حجز الفندق الخاص بك -
                          </li>
                          <li>
                         .أي من المعلومات المجموعة أعلاه، بما في ذلك تفاصيل بطاقة الهوية القطرية وتفاصيل الجواز، بدون موافقتك المسبقة، إلا إذا كانت هناك حاجة لذلك من قبل أي جهاز إنفاذ للقانون لأغراض التحقيق أو بناء على أمر قضائي أو في إطار أي عملية قانونية TTG قادرة على معالجة طلب الحجز المرغوب. لن تشارك TTG قد تشارك هذه المعلومات مع مقدم الخدمة النهائي أو أي طرف ثالث آخر لتوفير الحجز المرغوب وتسهيله. في حالة عدم رغبة المستخدم في تقديم هذه المعلومات أو اختيار حذف المعلومات التي تم تقديمها بالفعل، قد لا تكون TTG سيتم استخدام مثل هذه المعلومات حصراً للأغراض المحددة والقانونية المذكورة أعلاه. يفهم المستخدم أيضًا أن
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </p>
                </div>
              </ShowHide>
            </div>
            <div ref={tc3}>
              <ShowHide
                visible="true"
                icon=""
                title="كيف نستخدم معلوماتك الشخصية"
              >
                <div className="showHide-content">
                  <p>
                    <strong>
                     {" "}: قد يتم استخدام المعلومات الشخصية التي تم جمعها بالطرق التالية
                    </strong>
                  </p>
                  <ul>
                    <li>
                      <strong>:أثناء إجراء الحجز</strong>
                      <p>
                       {" "}. مع تاريخ الانتهاء، وتفاصيل البنك وما إلى ذلك كما تم مشاركتها والمسموح لها بالتخزين من قبلك. قد نستخدم أيضًا معلومات قائمة المسافرين المتاحة في حسابك أو المرتبطة به لتمكينك من إكمال حجوزاتك بشكل سريع (بشكل مشفر) قد نستخدم المعلومات الشخصية، بما في ذلك تفاصيل الدفع مثل اسم حامل البطاقة، رقم بطاقة الائتمان/الخصم
                      </p>
                    </li>
                    <li>
                      <strong>
                        {" "}: بناءً على ذلك، يمكن استخدام معلوماتك الشخصية من قبلنا لأسباب عدة، بما في ذلك ولكن لا تقتصر على
                      </strong>
                      <ul style={{ listStyleType: "number" }}>
                        <li>
                        تأكيد حجوزاتك مع مقدمي الخدمة المعنيين-
                        </li>
                        <li>.إبلاغك بحالة المعاملة-</li>
                        <li>
                       . إرسال تأكيد الحجز عبر البريد الإلكتروني أو الرسائل النصية أو واتساب-
                        </li>
                        <li>. إرسال أي تحديثات أو تغييرات على حجوزاتك-</li>
                        <li>
                       . السماح لخدمة العملاء للاتصال بك، إذا كان ذلك ضروريًا-
                        </li>
                        <li>تخصيص محتوى تطبيقنا المحمول-</li>
                        <li>
                      . طلب تقييمات للمنتجات أو الخدمات أو أي تحسينات أخرى-
                        </li>
                        <li>
                       .إرسال رسائل التحقق أو البريد الإلكتروني أثناء التسجيل-
                        </li>
                        <li>
                          :  التحقق/مصادقة حسابك ومنع أي استخدام غير قانوني أو سوء استخدام-
                        </li>
                        <li> .التسجيل في أي برنامج مكافآت-</li>
                      </ul>
                    </li>
                    <li>
                      <strong>
                       : ترويج التسويق والأبحاث والبرامج
                      </strong>
                      <ul style={{ listStyleType: "number" }}>
                        <li>
                       . وبرامج التسويق (مثل التوفير والفوائد على تذاكر الطيران، وحجوزات الفنادق، وحزم العطلات، وتأجير السيارات، وخدمات السفر الأخرى) تساعدنا حملات الترويج التسويقي والأبحاث والبرامج في تحديد تفضيلاتك وتطوير برامج وتحسين تجربة المستخدم. قد تتضمن المعلومات الشخصية التي تم جمعها من قبلنا لهذه الأنشطة معلومات الاتصال وأسئلة الاستطلاع. نستخدم هذه المعلومات الشخصية لإعلام الفائزين في المسابقات واستخدام معلومات الاستطلاع لتطوير الحملات الترويجية وتحسين المنتجات. كمستخدم مسجل، قد تتلقى أحيانًا تحديثات منا حول عروض الأسعار في منطقتك، وعروض خاصة، وخدمات جديدة، وعناصر ملحوظة أخرى
                        </li>
                        <li>
                       .بالإضافة إلى ذلك، قد تلقى رسائل تسويقية دورية ونشرات إخبارية وعروض ترويجية حصرية تقدم صفقات خاصة
                        </li>
                        <li>
                       .المطلوبة (الخدمات) من حين لآخر، قد نضيف أو نعزز الخدمات المتاحة على التطبيق. فيما يتعلق بتقديم هذه الخدمات واستخدامك لها، سنستخدم المعلومات الشخصية التي تقدمها لتيسير الخدمة
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </ShowHide>
            </div>
            <div ref={tc4}>
              <ShowHide
                visible="true"
                icon=""
                title="ما هي مدة تخزين معلوماتك الشخصية؟"
              >
                <div className="showHide-content">
                  <p>
                 .بمعلوماتك الشخصية على خوادمها طالما كان ذلك ضرورياً بما يعتد به للأغراض المدرجة في هذه السياسة. في بعض الحالات، قد نحتفظ بمعلوماتك الشخصية لفترات أطول، على سبيل المثال، عندما يكون ذلك مطلوباً وفقاً لأي متطلبات قانونية أو تنظيمية أو ضريبية أو محاسبية TTG  ستحتفظ
                  </p>
                  <p>
                 .عندما لا يكون هناك حاجة إلى بياناتك الشخصية بعد، سنتأكد من حذفها بشكل آمن أو تخزينها بطريقة تعني عدم استخدامها بواسطة الشركة
                  </p>
                </div>
              </ShowHide>
            </div>
            <div ref={tc5}>
              <ShowHide visible="true" icon="" title="الكوكيز وبيانات الجلسة">
                <div className="showHide-content">
                  <p>
                 .للكوكيز مشابه لاستخدام أي شركات عبر الإنترنت ذات سمعة طيبة TTG يُستخدم الكوكيز لتخصيص تجربتك على الموقع الإلكتروني والإعلانات التي قد تظهر. استخدام
                  </p>
                  <p>
                 . تركز على وجهات قد تكون من اهتمامك (أو رسائل بريد إلكتروني مماثلة - بشرط أنك لم تختار عدم تلقي مثل هذه الرسائل) لك أثناء استخدامك للتطبيق أو لإرسال عروض (ات) قد نستخدم أيضًا مثل هذه الكوكيز لعرض أي إعلان ?(حيث يكفي إدخال كلمة المرور فقط) الكوكيز هي قطع صغيرة من المعلومات تُخزن من قبل متصفحك على قرص تخزين البيانات في جهازك. تسمح لنا الكوكيز بخدمتك بشكل أفضل وبشكل أكثر فعالية. تتيح الكوكيز أيضًا سهولة الوصول، من خلال تسجيل دخولك دون الحاجة إلى كتابة اسم المستخدم في كل مرة
                  </p>
                </div>
              </ShowHide>
            </div>
            <div ref={tc6}>
              <ShowHide
                visible="true"
                icon=""
                title="من يحق له الوصول إلى معلوماتك الشخصية؟"
              >
                <div className="showHide-content">
                  <p>
                 .سيتم مشاركة معلوماتك الشخصية مع مقدمي الخدمة والموردين الخاصين بنا
                  </p>
                  <p>
                 : بالكشف عن معلومات المستخدم الشخصية إذا كان ذلك ضروريًا TTG بالإضافة إلى الظروف الموضحة أعلاه، قد يقوم
                    <ul style={{ listStyleType: "number" }}>
                      <li>
                      بموجب القانون، كما هو مطلوب من قبل أي جهة تنفيذية للتحقيق، أو بناءً على أمر قضائي أو في إطار أي عملية قانونية-
                      </li>
                      <li>. لإجراء أعمالنا-</li>
                      <li>
                      .(ات) لأغراض التنظيم والامتثال الداخلي وتنفيذ التدقيق-
                      </li>
                      <li>لتأمين أنظمتنا، أو-</li>
                      <li>
                     .الشخصية ضروري لتحديد هوية أو التواصل أو رفع دعوى قانونية ضد شخص قد يتسبب في التداخل مع حقوقنا أو ممتلكاتنا، بغض النظر إن كان بشكل مقصود أو غير مقصود، أو عند إحتمال التسبب بضرر لأي شخص آخر جراء مثل هذه الأنشطة (أو المستخدمين) أو أحد توابعها أو شركائها أو موظفيها أو مديريها أو ممثليها، أو عندما يكون لدينا سبب للاعتقاد بأن الكشف عن معلومات المستخدم TTG لفرض أو حماية حقوقنا أو ممتلكات-
                      </li>
                    </ul>
                  </p>

                  <p>
                 .قد يتم الكشف عن البيانات وعمليات التخزين دون علمك. في هذه الحالة، لن نكون مسؤولين تجاهك أو أي طرف ثالث عن أية أضرار تنشأ على الإطلاق نتيجة لمثل هذا الكشف وعمليات التخزين
                  </p>
                </div>
              </ShowHide>
            </div>
            <div ref={tc7}>
              <ShowHide
                visible="true"
                icon=""
                title="كيف يمكنك إلغاء الاشتراك في تلقي رسائل البريد الإلكتروني الترويجية الخاصة بنا؟"
              >
                <div className="showHide-content">
                  <p>
                 . الجديدة، وعناصر أخرى قد تسترعي إهتمامك. نأمل أن تجد هذه التحديثات مثيرة ومفيدة. إذا كنت لا ترغب في تلقيها، يرجى النقر على رابط "إلغاء الاشتراك" أو اتباع التعليمات الموجودة في كل رسالة بريد إلكتروني TTG بين الحين والآخر، ستتلقى منا تحديثات بالبريد إلكتروني حول تخفيضات الأسعار المتوفرة في منطقتك، وعروض خاصة، وخدمات
                  </p>
                </div>
              </ShowHide>
            </div>

            <div ref={tc8}>
              <ShowHide
                visible="true"
                icon=""
                title="الأذونات المطلوبة لاستخدام تطبيق الهاتف المحمول الخاص بنا"
              >
                <div className="showHide-content">
                  <p>
                 :والبيانات التي ستتم الوصول إليها واستخدامها كما يلي TTG على هاتفك أو جهازك اللوحي، ستظهر قائمة من الأذونات وهي ضرورية لكي يعمل التطبيق بكفاءة. لا يوجد خيار لتخصيص القائمة. الأذونات التي يحتاجها تطبيق TTG عند تثبيت تطبيق
                    <ul style={{ listStyleType: "number" }}>
                      <li>
                        <strong>:Android أذونات</strong>
                        <ul>
                          <li>
                         الحصول على معلومات حول جهازك مثل نظام التشغيل، إصدار النظام، الشبكة المحمولة، نموذج الجهاز، معرف الجهاز ، اللغة المفضلة، إلخ -<strong>:سجل الجهاز وتاريخ التطبيق</strong> 
                          </li>
                          <li> تحسين تجربة حجز السفر واستخدام إمكانيات نظام التشغيل لتحقيق تجارب جيدة داخل التطبيق-</li>
                          <li>
                          تمكيننا من تقديم صفقات محددة لموقعك وتوفير تجربة شخصية داخل التطبيق.-   <strong>:الموقع</strong> 
                          </li>
                        <li>. توصية بالفنادق الأقرب في حالة التأخر والرغبة في إجراء حجز سريع. تكون الخيارات مخصصة استناداً إلى مواقعك. في السفر الدولي، يمكننا تحديد التوقيت حسب منطقتك وتوفير المعلومات وفقاً لذلك-</li>
                          <li>
                         . قراءة الرسائل النصية لملء تلقائي أو مسبق لـ 'OTP' أثناء إجراء معاملة والتحقق من رقم هاتفك -<strong>:SMS الرسائل القصيرة </strong> 
                          </li>
                          <li>توفير تجربة شراء سلسة أثناء إجراء حجز دون الحاجة لمغادرة التطبيق-</li>
                          <li>
                        . الوصول لإجراء مكالمات هاتفية مع الفنادق والشركات الجوية ومراكز خدمة العملاء مباشرة من خلال التطبيق- <strong>:الهاتف</strong> 
                          </li>
                          <li>
                          وضع خطة السفر على تقويمك <strong>:التقويم</strong> 
                          </li>
                          <li>
                         . الوصول إلى الكاميرا لتمكينك من التقاط، مراجعة وتحميل صورة الملف الشخصي <strong>: الصور ووسائط الإعلام</strong> 
                          </li>
                        </ul>
                      </li>
                      <li>
                        <strong>:IOS أذونات</strong>
                        <ul>
                          <li>
                         .  إرسال صفقات حصرية، وعروض ترويجية، وتحديثات تتعلق بالسفر، إلخ - <strong>الإشعارات</strong> 
                          </li>
                          <li>
                         . إلخ (في حالة الإلغاء) تأكيد الحجز، استرداد PNR إرسال تحديثات لحالة رحلتك مثل حالة
                          </li>
                          <li>
                         . تمكيننا من تقديم صفقات محددة لموقعك وتوفير تجربة شخصية داخل التطبيق -<strong>الموقع</strong>
                          </li>
                          <li>
                         .كشف تلقائي لموقعك لملء تلقائياً لأقرب مطار أو مدينة. توصية بأقرب الفنادق في حالة التأخر لإجراء حجز سريع
                          </li>
                          <li>
                         . الوصول إلى الكاميرا لتمكينك من التقاط، مراجعة وتحميل صورة الملف الشخصي  <strong> الكاميرا ومكتبة الصور</strong>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </p>
                </div>
              </ShowHide>
            </div>
            <div ref={tc9}>
              <ShowHide
                visible="true"
                icon=""
                title=" كيف نحمي معلوماتك الشخصية؟"
              >
                <div className="showHide-content">
                  <p>
                 .جميع المدفوعات عبر التطبيق آمنة حيث أننا لا نقوم بتخزين أي تفاصيل لبطاقة المستخدم، وسيتم توجيه المستخدم إلى بوابة الدفع
                  </p>
                </div>
              </ShowHide>
            </div>
            <div ref={tc10}>
              <ShowHide
                visible="true"
                icon=""
                title="سحب الموافقة والإذن"
              >
                <div className="showHide-content">
                  <p>
                   
                    <a href="mailto:privacy@att.qa">privacy@att.qa</a> يمكنك سحب موافقتك على تقديم أي معلومات شخصية أو جميعها، أو رفض توفير أي أذونات على تطبيقنا كما هو مشمول أعلاه في أي وقت. في حال قررت القيام بذلك، قد تكون إمكانية الوصول إلى الموقع محدودة، أو قد لا نكون قادرين على تقديم الخدمات لك كما ينبغي. يمكنك سحب موافقتك عن طريق إرسال بريد إلكتروني إلى 
                  </p>
                </div>
              </ShowHide>
            </div>
            <div ref={tc11}>
                <ShowHide
                  visible="true"
                  icon=""
                  title="أهلية التعامل مع TTG"
                >
                  <div className="showHide-content">
                    <p>
                   . وأيضاً للموافقة على معالجة بياناتك الشخصية TTG يجب أن تبلغ على الأقل سن ال 18 عاماً لإجراء معاملات مباشرة مع
                    </p>
                  </div>
                </ShowHide>
              </div>
            <div ref={tc12}>
              <ShowHide
                visible="true"
                icon=""
                title="التغييرات في سياسة الخصوصية"
              >
                <div className="showHide-content">
                  <p>
                 .نحتفظ بحقوقنا في مراجعة سياسة الخصوصية من وقت لآخر لتناسب متطلبات مختلفة قانونية وتجارية ومتطلبات العملاء. سنقوم بإعلام المستخدمين عند الضرورة
                  </p>
                  <p>
                 . للرد على جميع المخاوف والاستفسارات المعقولة  TTG ستسعى <a href="mailto:privacy@att.qa">privacy@att.qa</a> يمكنك دائمًا تقديم أي مخاوف تتعلق بهذه سياسة الخصوصية عبر البريد الإلكتروني إلينا على
                  </p>
                </div>
              </ShowHide>
            </div>
          </Col>
        </Row>
      </div>
      <Footer />
    </>
  );
}

export default PrivacyPolicy;
