/**
 * @ Author: Srikanth Bandaru
 * @ Create Time: 2023-06-15 09:47:01
 * @ Description: A common file to document all the samples of flights to re-use whenever required to reduce traffic on the test servers
 */

export const FLIGHT_RESPONSE_ONE_WAY = {
  status: "success",
  data: [
    {
      origns: [
        {
          srcorigns: [
            {
              tid: "1NUeI023am3BRCbq_1",
              ref: 1,
              office_id: "",
              sdur: "6:45",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "HYD",
                  dd: "2023-06-07T02:00:00",
                  ad: "2023-06-07T08:45:00",
                  rbd: "",
                  fn: "1318",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: {},
                  rph: "",
                  jsell:
                    "6E~1318~ ~~DOH~06/07/2023 02:00~HYD~06/07/2023 08:45~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey: "0~R~ ~6E~R0INT~5000~~0~4~~X",
                  pr: {
                    bf: 7500,
                    tf: 10876,
                    tax: [
                      { OT: 3376 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "7500_10876",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 10876,
                  },
                  paxpr: [
                    {
                      bf: 7500,
                      tf: 10876,
                      tax: [
                        { OT: 3376 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "R0INT",
                      ptype: "ADT",
                      scur: "INR",
                      sup_fare: "7500_10876",
                      psup_fare: "7500_10876",
                    },
                  ],
                  routeId: "vjvtns8h0z",
                  productClass: "R",
                  ruleNumber: "5000",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey: "0~R~ ~6E~RUINT~5003~~0~10~~X",
                  pr: {
                    bf: 8200,
                    tf: 11600,
                    tax: [
                      { OT: 3400 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "8200_11600",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 11600,
                  },
                  paxpr: [
                    {
                      bf: 8200,
                      tf: 11600,
                      tax: [
                        { OT: 3400 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "RUINT",
                      ptype: "ADT",
                      scur: "INR",
                      sup_fare: "8200_11600",
                      psup_fare: "8200_11600",
                    },
                  ],
                  routeId: "vofi2gvp2l",
                  productClass: "J",
                  ruleNumber: "5003",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "TVhZN9pTbpY=|eZCNOj6QScTYrS+yBHuJwYzi+VNwga+stKu+2KxSl4ZFuiSjKtyKcPo3p3k8rIoCi5ChABOpvQwLuJe7b8QCAeecWtenKvYQhMv+Ae1482oirole5XXQV61j7WFegG3cGH5eApkuEC4=",
              stop: 0,
            },
            {
              tid: "LwSBaohPc4zcRye9_1",
              ref: 1,
              office_id: "",
              sdur: "6:45",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "HYD",
                  dd: "2023-06-07T08:15:00",
                  ad: "2023-06-07T15:00:00",
                  rbd: "",
                  fn: "1314",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: {},
                  rph: "",
                  jsell:
                    "6E~1314~ ~~DOH~06/07/2023 08:15~HYD~06/07/2023 15:00~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
              ],
              fareop: [
                {
                  fareKey: "0~R~ ~6E~R0INT~5000~~0~4~~X",
                  pr: {
                    bf: 7500,
                    tf: 10876,
                    tax: [
                      { OT: 3376 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "7500_10876",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 10876,
                  },
                  paxpr: [
                    {
                      bf: 7500,
                      tf: 10876,
                      tax: [
                        { OT: 3376 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "R0INT",
                      ptype: "ADT",
                      scur: "INR",
                      sup_fare: "7500_10876",
                      psup_fare: "7500_10876",
                    },
                  ],
                  routeId: "pwr3z59sfd",
                  productClass: "R",
                  ruleNumber: "5000",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey: "0~R~ ~6E~RUINT~5003~~0~10~~X",
                  pr: {
                    bf: 8200,
                    tf: 11600,
                    tax: [
                      { OT: 3400 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "8200_11600",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 11600,
                  },
                  paxpr: [
                    {
                      bf: 8200,
                      tf: 11600,
                      tax: [
                        { OT: 3400 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "RUINT",
                      ptype: "ADT",
                      scur: "INR",
                      sup_fare: "8200_11600",
                      psup_fare: "8200_11600",
                    },
                  ],
                  routeId: "qfrz0oc4p3h",
                  productClass: "J",
                  ruleNumber: "5003",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "TVhZN9pTbpY=|eZCNOj6QScTYrS+yBHuJwYzi+VNwga+stKu+2KxSl4ZFuiSjKtyKcPo3p3k8rIoCi5ChABOpvQwLuJe7b8QCAeecWtenKvYQhMv+Ae1482oirole5XXQV61j7WFegG3cGH5eApkuEC4=",
              stop: 0,
            },
            {
              tid: "D8bEBS55JioIThX6_1",
              ref: 1,
              office_id: "",
              sdur: "6:45",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "HYD",
                  dd: "2023-06-07T19:45:00",
                  ad: "2023-06-08T02:30:00",
                  rbd: "",
                  fn: "1316",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: {},
                  rph: "",
                  jsell:
                    "6E~1316~ ~~DOH~06/07/2023 19:45~HYD~06/08/2023 02:30~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
              ],
              fareop: [
                {
                  fareKey: "0~R~ ~6E~R0INT~5000~~0~4~~X",
                  pr: {
                    bf: 7500,
                    tf: 10876,
                    tax: [
                      { OT: 3376 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "7500_10876",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 10876,
                  },
                  paxpr: [
                    {
                      bf: 7500,
                      tf: 10876,
                      tax: [
                        { OT: 3376 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "R0INT",
                      ptype: "ADT",
                      scur: "INR",
                      sup_fare: "7500_10876",
                      psup_fare: "7500_10876",
                    },
                  ],
                  routeId: "yjb3rnfo0v",
                  productClass: "R",
                  ruleNumber: "5000",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey: "0~R~ ~6E~RUINT~5003~~0~10~~X",
                  pr: {
                    bf: 8200,
                    tf: 11600,
                    tax: [
                      { OT: 3400 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "8200_11600",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 11600,
                  },
                  paxpr: [
                    {
                      bf: 8200,
                      tf: 11600,
                      tax: [
                        { OT: 3400 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "RUINT",
                      ptype: "ADT",
                      scur: "INR",
                      sup_fare: "8200_11600",
                      psup_fare: "8200_11600",
                    },
                  ],
                  routeId: "ueo3f88fj3",
                  productClass: "J",
                  ruleNumber: "5003",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "TVhZN9pTbpY=|eZCNOj6QScTYrS+yBHuJwYzi+VNwga+stKu+2KxSl4ZFuiSjKtyKcPo3p3k8rIoCi5ChABOpvQwLuJe7b8QCAeecWtenKvYQhMv+Ae1482oirole5XXQV61j7WFegG3cGH5eApkuEC4=",
              stop: 0,
            },
          ],
          paxpr: [
            {
              bf: 7500,
              tf: 10876,
              tax: [
                { OT: 3376 },
                { attmkp: 0 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              fbc: "R0INT",
              ptype: "ADT",
              scur: "INR",
              sup_fare: "7500_10876",
              psup_fare: "7500_10876",
            },
          ],
          pr: {
            bf: 7500,
            tf: 10876,
            tax: [
              { OT: 3376 },
              { attmkp: 0 },
              { tamkp: 0 },
              { stamkp: 0 },
              { attdisc: 0 },
              { tadisc: 0 },
              { stadisc: 0 },
            ],
            ucur: "INR",
            exchange_rate: 1,
            scur: "INR",
            sup_fare: "7500_10876",
            sup_cur_fare: "",
            cabb: "",
            chekb: "",
            seats: "",
            meals: "",
            chanb: "",
            ftyp: "Regular - R",
            markupnames: [],
            ttf: 10876,
          },
          sup: "4",
          airlineList: "6E",
          servicefee: [],
        },
        {
          srcorigns: [
            {
              tid: "QdpqzlPw39XSbrSO_1",
              ref: 1,
              office_id: "",
              sdur: "16:15",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "MAA",
                  dd: "2023-06-07T02:00:00",
                  ad: "2023-06-07T09:05:00",
                  rbd: "",
                  fn: "1322",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "3" },
                  rph: "",
                  jsell:
                    "6E~1322~ ~~DOH~06/07/2023 02:00~MAA~06/07/2023 09:05~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
                {
                  ref: 2,
                  da: "MAA",
                  ar: "HYD",
                  dd: "2023-06-07T17:00:00",
                  ad: "2023-06-07T18:15:00",
                  rbd: "",
                  fn: " 562",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "1" },
                  rph: "",
                  jsell:
                    "6E~ 562~ ~~MAA~06/07/2023 17:00~HYD~06/07/2023 18:15~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "1~Q~ ~6E~Q0INT~5000~~0~12~~^2~L~ ~6E~LC8AP~1070~~0~7~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 9313,
                      fbc: "Q0INT/LC8AP",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "9313_12802",
                      tax: [
                        { OT: 3489 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 12802,
                      psup_fare: "9313_12802",
                    },
                  ],
                  pr: {
                    bf: 9313,
                    tf: 12802,
                    tax: [
                      { OT: 3489 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "9313_12802",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 12802,
                  },
                  routeId: "tnuifnveil",
                  ruleNumber: "5000",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~R~ ~6E~RCUINT~6202~~0~4~~X^0~R~ ~6E~RCUINT~6202~~0~4~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 10519,
                      fbc: "RCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "10519_14050",
                      tax: [
                        { OT: 3531 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 14050,
                      psup_fare: "10519_14050",
                    },
                  ],
                  pr: {
                    bf: 10519,
                    tf: 14050,
                    tax: [
                      { OT: 3531 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "10519_14050",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 14050,
                  },
                  routeId: "6mipufyhpu",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "TVhZN9pTbpY=|eZCNOj6QScTYrS+yBHuJwYzi+VNwga+stKu+2KxSl4ZFuiSjKtyKcPo3p3k8rIoCi5ChABOpvQwLuJe7b8QCAeecWtenKvYQhMv+Ae1482oirole5XXQV61j7WFegG3cGH5eApkuEC4=",
              stop: 1,
            },
            {
              tid: "Jh0ugxHmQkzHn1V6_1",
              ref: 1,
              office_id: "",
              sdur: "15:10",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "MAA",
                  dd: "2023-06-07T02:00:00",
                  ad: "2023-06-07T09:05:00",
                  rbd: "",
                  fn: "1322",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "3" },
                  rph: "",
                  jsell:
                    "6E~1322~ ~~DOH~06/07/2023 02:00~MAA~06/07/2023 09:05~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
                {
                  ref: 2,
                  da: "MAA",
                  ar: "HYD",
                  dd: "2023-06-07T16:00:00",
                  ad: "2023-06-07T17:10:00",
                  rbd: "",
                  fn: "6401",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "1" },
                  rph: "",
                  jsell:
                    "6E~6401~ ~~MAA~06/07/2023 16:00~HYD~06/07/2023 17:10~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "1~Q~ ~6E~Q0INT~5000~~0~12~~^2~L~ ~6E~LC8AP~1070~~0~7~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 9313,
                      fbc: "Q0INT/LC8AP",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "9313_12802",
                      tax: [
                        { OT: 3489 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 12802,
                      psup_fare: "9313_12802",
                    },
                  ],
                  pr: {
                    bf: 9313,
                    tf: 12802,
                    tax: [
                      { OT: 3489 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "9313_12802",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 12802,
                  },
                  routeId: "94piquo762g",
                  ruleNumber: "5000",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~R~ ~6E~RCUINT~6202~~0~4~~X^0~R~ ~6E~RCUINT~6202~~0~4~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 10519,
                      fbc: "RCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "10519_14050",
                      tax: [
                        { OT: 3531 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 14050,
                      psup_fare: "10519_14050",
                    },
                  ],
                  pr: {
                    bf: 10519,
                    tf: 14050,
                    tax: [
                      { OT: 3531 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "10519_14050",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 14050,
                  },
                  routeId: "twfki5tmr8",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "TVhZN9pTbpY=|eZCNOj6QScTYrS+yBHuJwYzi+VNwga+stKu+2KxSl4ZFuiSjKtyKcPo3p3k8rIoCi5ChABOpvQwLuJe7b8QCAeecWtenKvYQhMv+Ae1482oirole5XXQV61j7WFegG3cGH5eApkuEC4=",
              stop: 1,
            },
            {
              tid: "KN2DgNMxCTpiyxlG_1",
              ref: 1,
              office_id: "",
              sdur: "11:30",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "MAA",
                  dd: "2023-06-07T02:00:00",
                  ad: "2023-06-07T09:05:00",
                  rbd: "",
                  fn: "1322",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "3" },
                  rph: "",
                  jsell:
                    "6E~1322~ ~~DOH~06/07/2023 02:00~MAA~06/07/2023 09:05~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
                {
                  ref: 2,
                  da: "MAA",
                  ar: "HYD",
                  dd: "2023-06-07T12:20:00",
                  ad: "2023-06-07T13:30:00",
                  rbd: "",
                  fn: " 604",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "1" },
                  rph: "",
                  jsell:
                    "6E~ 604~ ~~MAA~06/07/2023 12:20~HYD~06/07/2023 13:30~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "1~Q~ ~6E~Q0INT~5000~~0~12~~^2~L~ ~6E~LC8AP~1070~~0~7~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 9313,
                      fbc: "Q0INT/LC8AP",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "9313_12802",
                      tax: [
                        { OT: 3489 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 12802,
                      psup_fare: "9313_12802",
                    },
                  ],
                  pr: {
                    bf: 9313,
                    tf: 12802,
                    tax: [
                      { OT: 3489 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "9313_12802",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 12802,
                  },
                  routeId: "8n5jsqp533",
                  ruleNumber: "5000",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~R~ ~6E~RCUINT~6202~~0~4~~X^0~R~ ~6E~RCUINT~6202~~0~4~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 10519,
                      fbc: "RCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "10519_14050",
                      tax: [
                        { OT: 3531 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 14050,
                      psup_fare: "10519_14050",
                    },
                  ],
                  pr: {
                    bf: 10519,
                    tf: 14050,
                    tax: [
                      { OT: 3531 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "10519_14050",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 14050,
                  },
                  routeId: "fyqfuurilw",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "TVhZN9pTbpY=|eZCNOj6QScTYrS+yBHuJwYzi+VNwga+stKu+2KxSl4ZFuiSjKtyKcPo3p3k8rIoCi5ChABOpvQwLuJe7b8QCAeecWtenKvYQhMv+Ae1482oirole5XXQV61j7WFegG3cGH5eApkuEC4=",
              stop: 1,
            },
          ],
          paxpr: [
            {
              bf: 9313,
              fbc: "Q0INT/LC8AP",
              ptype: "ADT",
              scur: "QAR",
              sup_fare: "9313_12802",
              tax: [
                { OT: 3489 },
                { attmkp: 0 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              tf: 12802,
              psup_fare: "9313_12802",
            },
          ],
          pr: {
            bf: 9313,
            tf: 12802,
            tax: [
              { OT: 3489 },
              { attmkp: 0 },
              { tamkp: 0 },
              { stamkp: 0 },
              { attdisc: 0 },
              { tadisc: 0 },
              { stadisc: 0 },
            ],
            ucur: "INR",
            exchange_rate: 1,
            scur: "INR",
            sup_fare: "9313_12802",
            sup_cur_fare: "",
            cabb: "",
            chekb: "",
            seats: "",
            meals: "",
            chanb: "",
            ftyp: "Regular - R",
            markupnames: [],
            ttf: 12802,
          },
          sup: "4",
          airlineList: "6E",
          servicefee: [],
        },
        {
          srcorigns: [
            {
              tid: "zpbu2tEaS9xidby8_1",
              ref: 1,
              office_id: "",
              sdur: "10:15",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-07T02:00:00",
                  ad: "2023-06-07T08:20:00",
                  rbd: "",
                  fn: "1312",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "3" },
                  rph: "",
                  jsell:
                    "6E~1312~ ~~DOH~06/07/2023 02:00~DEL~06/07/2023 08:20~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-06-07T10:10:00",
                  ad: "2023-06-07T12:15:00",
                  rbd: "",
                  fn: "2005",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "2" },
                  rph: "",
                  jsell:
                    "6E~2005~ ~~DEL~06/07/2023 10:10~HYD~06/07/2023 12:15~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~R~ ~6E~RCINT~6200~~0~65~~X^0~R~ ~6E~RCINT~6200~~0~65~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 9819,
                      fbc: "RCINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "9819_13326",
                      tax: [
                        { OT: 3507 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 13326,
                      psup_fare: "9819_13326",
                    },
                  ],
                  pr: {
                    bf: 9819,
                    tf: 13326,
                    tax: [
                      { OT: 3507 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "9819_13326",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 13326,
                  },
                  routeId: "dki8bokcb0j",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~R~ ~6E~RCUINT~6202~~0~4~~X^0~R~ ~6E~RCUINT~6202~~0~4~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 10519,
                      fbc: "RCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "10519_14050",
                      tax: [
                        { OT: 3531 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 14050,
                      psup_fare: "10519_14050",
                    },
                  ],
                  pr: {
                    bf: 10519,
                    tf: 14050,
                    tax: [
                      { OT: 3531 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "10519_14050",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 14050,
                  },
                  routeId: "g7mto891pkf",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "TVhZN9pTbpY=|eZCNOj6QScTYrS+yBHuJwYzi+VNwga+stKu+2KxSl4ZFuiSjKtyKcPo3p3k8rIoCi5ChABOpvQwLuJe7b8QCAeecWtenKvYQhMv+Ae1482oirole5XXQV61j7WFegG3cGH5eApkuEC4=",
              stop: 1,
            },
            {
              tid: "sABegR1KrQvdFByS_1",
              ref: 1,
              office_id: "",
              sdur: "20:0",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-07T02:00:00",
                  ad: "2023-06-07T08:20:00",
                  rbd: "",
                  fn: "1312",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "3" },
                  rph: "",
                  jsell:
                    "6E~1312~ ~~DOH~06/07/2023 02:00~DEL~06/07/2023 08:20~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-06-07T19:50:00",
                  ad: "2023-06-07T22:00:00",
                  rbd: "",
                  fn: " 605",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "1" },
                  rph: "",
                  jsell:
                    "6E~ 605~ ~~DEL~06/07/2023 19:50~HYD~06/07/2023 22:00~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~R~ ~6E~RCINT~6200~~0~65~~X^0~R~ ~6E~RCINT~6200~~0~65~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 9819,
                      fbc: "RCINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "9819_13326",
                      tax: [
                        { OT: 3507 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 13326,
                      psup_fare: "9819_13326",
                    },
                  ],
                  pr: {
                    bf: 9819,
                    tf: 13326,
                    tax: [
                      { OT: 3507 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "9819_13326",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 13326,
                  },
                  routeId: "ysn3xpl1i",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~R~ ~6E~RCUINT~6202~~0~4~~X^0~R~ ~6E~RCUINT~6202~~0~4~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 10519,
                      fbc: "RCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "10519_14050",
                      tax: [
                        { OT: 3531 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 14050,
                      psup_fare: "10519_14050",
                    },
                  ],
                  pr: {
                    bf: 10519,
                    tf: 14050,
                    tax: [
                      { OT: 3531 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "10519_14050",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 14050,
                  },
                  routeId: "zq6l4ifmnn",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "TVhZN9pTbpY=|eZCNOj6QScTYrS+yBHuJwYzi+VNwga+stKu+2KxSl4ZFuiSjKtyKcPo3p3k8rIoCi5ChABOpvQwLuJe7b8QCAeecWtenKvYQhMv+Ae1482oirole5XXQV61j7WFegG3cGH5eApkuEC4=",
              stop: 1,
            },
            {
              tid: "giAMS6yzJh1F49nV_1",
              ref: 1,
              office_id: "",
              sdur: "19:0",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-07T02:00:00",
                  ad: "2023-06-07T08:20:00",
                  rbd: "",
                  fn: "1312",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "3" },
                  rph: "",
                  jsell:
                    "6E~1312~ ~~DOH~06/07/2023 02:00~DEL~06/07/2023 08:20~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-06-07T18:50:00",
                  ad: "2023-06-07T21:00:00",
                  rbd: "",
                  fn: "5217",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "3" },
                  rph: "",
                  jsell:
                    "6E~5217~ ~~DEL~06/07/2023 18:50~HYD~06/07/2023 21:00~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~R~ ~6E~RCINT~6200~~0~65~~X^0~R~ ~6E~RCINT~6200~~0~65~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 9819,
                      fbc: "RCINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "9819_13326",
                      tax: [
                        { OT: 3507 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 13326,
                      psup_fare: "9819_13326",
                    },
                  ],
                  pr: {
                    bf: 9819,
                    tf: 13326,
                    tax: [
                      { OT: 3507 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "9819_13326",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 13326,
                  },
                  routeId: "m8lezysimk",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~R~ ~6E~RCUINT~6202~~0~4~~X^0~R~ ~6E~RCUINT~6202~~0~4~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 10519,
                      fbc: "RCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "10519_14050",
                      tax: [
                        { OT: 3531 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 14050,
                      psup_fare: "10519_14050",
                    },
                  ],
                  pr: {
                    bf: 10519,
                    tf: 14050,
                    tax: [
                      { OT: 3531 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "10519_14050",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 14050,
                  },
                  routeId: "6pr2xrh5lu",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "TVhZN9pTbpY=|eZCNOj6QScTYrS+yBHuJwYzi+VNwga+stKu+2KxSl4ZFuiSjKtyKcPo3p3k8rIoCi5ChABOpvQwLuJe7b8QCAeecWtenKvYQhMv+Ae1482oirole5XXQV61j7WFegG3cGH5eApkuEC4=",
              stop: 1,
            },
            {
              tid: "nTvfMCvcZ1I6NOrf_1",
              ref: 1,
              office_id: "",
              sdur: "13:45",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-07T02:00:00",
                  ad: "2023-06-07T08:20:00",
                  rbd: "",
                  fn: "1312",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "3" },
                  rph: "",
                  jsell:
                    "6E~1312~ ~~DOH~06/07/2023 02:00~DEL~06/07/2023 08:20~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-06-07T13:30:00",
                  ad: "2023-06-07T15:45:00",
                  rbd: "",
                  fn: " 837",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "1" },
                  rph: "",
                  jsell:
                    "6E~ 837~ ~~DEL~06/07/2023 13:30~HYD~06/07/2023 15:45~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~R~ ~6E~RCINT~6200~~0~65~~X^0~R~ ~6E~RCINT~6200~~0~65~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 9819,
                      fbc: "RCINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "9819_13326",
                      tax: [
                        { OT: 3507 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 13326,
                      psup_fare: "9819_13326",
                    },
                  ],
                  pr: {
                    bf: 9819,
                    tf: 13326,
                    tax: [
                      { OT: 3507 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "9819_13326",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 13326,
                  },
                  routeId: "47aa0cxc4q",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~R~ ~6E~RCUINT~6202~~0~4~~X^0~R~ ~6E~RCUINT~6202~~0~4~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 10519,
                      fbc: "RCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "10519_14050",
                      tax: [
                        { OT: 3531 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 14050,
                      psup_fare: "10519_14050",
                    },
                  ],
                  pr: {
                    bf: 10519,
                    tf: 14050,
                    tax: [
                      { OT: 3531 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "10519_14050",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 14050,
                  },
                  routeId: "4mkpdjm173j",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "TVhZN9pTbpY=|eZCNOj6QScTYrS+yBHuJwYzi+VNwga+stKu+2KxSl4ZFuiSjKtyKcPo3p3k8rIoCi5ChABOpvQwLuJe7b8QCAeecWtenKvYQhMv+Ae1482oirole5XXQV61j7WFegG3cGH5eApkuEC4=",
              stop: 1,
            },
            {
              tid: "4POpSEaBSvuIcs9Z_1",
              ref: 1,
              office_id: "",
              sdur: "16:35",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-07T19:40:00",
                  ad: "2023-06-08T02:00:00",
                  rbd: "",
                  fn: "1308",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "3" },
                  rph: "",
                  jsell:
                    "6E~1308~ ~~DOH~06/07/2023 19:40~DEL~06/08/2023 02:00~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-06-08T10:10:00",
                  ad: "2023-06-08T12:15:00",
                  rbd: "",
                  fn: "2005",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "2" },
                  rph: "",
                  jsell:
                    "6E~2005~ ~~DEL~06/08/2023 10:10~HYD~06/08/2023 12:15~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~R~ ~6E~RCINT~6200~~0~65~~X^0~R~ ~6E~RCINT~6200~~0~65~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 9819,
                      fbc: "RCINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "9819_13326",
                      tax: [
                        { OT: 3507 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 13326,
                      psup_fare: "9819_13326",
                    },
                  ],
                  pr: {
                    bf: 9819,
                    tf: 13326,
                    tax: [
                      { OT: 3507 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "9819_13326",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 13326,
                  },
                  routeId: "b4372o3tig",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~R~ ~6E~RCUINT~6202~~0~4~~X^0~R~ ~6E~RCUINT~6202~~0~4~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 10519,
                      fbc: "RCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "10519_14050",
                      tax: [
                        { OT: 3531 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 14050,
                      psup_fare: "10519_14050",
                    },
                  ],
                  pr: {
                    bf: 10519,
                    tf: 14050,
                    tax: [
                      { OT: 3531 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "10519_14050",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 14050,
                  },
                  routeId: "m4myuwtbux",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "TVhZN9pTbpY=|eZCNOj6QScTYrS+yBHuJwYzi+VNwga+stKu+2KxSl4ZFuiSjKtyKcPo3p3k8rIoCi5ChABOpvQwLuJe7b8QCAeecWtenKvYQhMv+Ae1482oirole5XXQV61j7WFegG3cGH5eApkuEC4=",
              stop: 1,
            },
            {
              tid: "A2Wpg5nl5WRtEKFE_1",
              ref: 1,
              office_id: "",
              sdur: "14:40",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-07T19:40:00",
                  ad: "2023-06-08T02:00:00",
                  rbd: "",
                  fn: "1308",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "3" },
                  rph: "",
                  jsell:
                    "6E~1308~ ~~DOH~06/07/2023 19:40~DEL~06/08/2023 02:00~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-06-08T08:10:00",
                  ad: "2023-06-08T10:20:00",
                  rbd: "",
                  fn: " 774",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "1" },
                  rph: "",
                  jsell:
                    "6E~ 774~ ~~DEL~06/08/2023 08:10~HYD~06/08/2023 10:20~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~R~ ~6E~RCINT~6200~~0~65~~X^0~R~ ~6E~RCINT~6200~~0~65~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 9819,
                      fbc: "RCINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "9819_13326",
                      tax: [
                        { OT: 3507 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 13326,
                      psup_fare: "9819_13326",
                    },
                  ],
                  pr: {
                    bf: 9819,
                    tf: 13326,
                    tax: [
                      { OT: 3507 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "9819_13326",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 13326,
                  },
                  routeId: "t9qm86cuyp",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~R~ ~6E~RCUINT~6202~~0~4~~X^0~R~ ~6E~RCUINT~6202~~0~4~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 10519,
                      fbc: "RCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "10519_14050",
                      tax: [
                        { OT: 3531 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 14050,
                      psup_fare: "10519_14050",
                    },
                  ],
                  pr: {
                    bf: 10519,
                    tf: 14050,
                    tax: [
                      { OT: 3531 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "10519_14050",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 14050,
                  },
                  routeId: "3cndhbzdww",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "TVhZN9pTbpY=|eZCNOj6QScTYrS+yBHuJwYzi+VNwga+stKu+2KxSl4ZFuiSjKtyKcPo3p3k8rIoCi5ChABOpvQwLuJe7b8QCAeecWtenKvYQhMv+Ae1482oirole5XXQV61j7WFegG3cGH5eApkuEC4=",
              stop: 1,
            },
            {
              tid: "onTPNGB95NwVGdva_1",
              ref: 1,
              office_id: "",
              sdur: "20:5",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-07T19:40:00",
                  ad: "2023-06-08T02:00:00",
                  rbd: "",
                  fn: "1308",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "3" },
                  rph: "",
                  jsell:
                    "6E~1308~ ~~DOH~06/07/2023 19:40~DEL~06/08/2023 02:00~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-06-08T13:30:00",
                  ad: "2023-06-08T15:45:00",
                  rbd: "",
                  fn: " 837",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "1" },
                  rph: "",
                  jsell:
                    "6E~ 837~ ~~DEL~06/08/2023 13:30~HYD~06/08/2023 15:45~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~R~ ~6E~RCINT~6200~~0~65~~X^0~R~ ~6E~RCINT~6200~~0~65~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 9819,
                      fbc: "RCINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "9819_13326",
                      tax: [
                        { OT: 3507 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 13326,
                      psup_fare: "9819_13326",
                    },
                  ],
                  pr: {
                    bf: 9819,
                    tf: 13326,
                    tax: [
                      { OT: 3507 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "9819_13326",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 13326,
                  },
                  routeId: "71ypzz1wcr",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~R~ ~6E~RCUINT~6202~~0~4~~X^0~R~ ~6E~RCUINT~6202~~0~4~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 10519,
                      fbc: "RCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "10519_14050",
                      tax: [
                        { OT: 3531 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 14050,
                      psup_fare: "10519_14050",
                    },
                  ],
                  pr: {
                    bf: 10519,
                    tf: 14050,
                    tax: [
                      { OT: 3531 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "10519_14050",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 14050,
                  },
                  routeId: "4q69tol1g",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "TVhZN9pTbpY=|eZCNOj6QScTYrS+yBHuJwYzi+VNwga+stKu+2KxSl4ZFuiSjKtyKcPo3p3k8rIoCi5ChABOpvQwLuJe7b8QCAeecWtenKvYQhMv+Ae1482oirole5XXQV61j7WFegG3cGH5eApkuEC4=",
              stop: 1,
            },
            {
              tid: "d6fJQ3F3OxbIAuVk_1",
              ref: 1,
              office_id: "",
              sdur: "25:20",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-07T19:40:00",
                  ad: "2023-06-08T02:00:00",
                  rbd: "",
                  fn: "1308",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "3" },
                  rph: "",
                  jsell:
                    "6E~1308~ ~~DOH~06/07/2023 19:40~DEL~06/08/2023 02:00~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-06-08T18:50:00",
                  ad: "2023-06-08T21:00:00",
                  rbd: "",
                  fn: "5217",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "3" },
                  rph: "",
                  jsell:
                    "6E~5217~ ~~DEL~06/08/2023 18:50~HYD~06/08/2023 21:00~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~R~ ~6E~RCINT~6200~~0~65~~X^0~R~ ~6E~RCINT~6200~~0~65~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 9819,
                      fbc: "RCINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "9819_13326",
                      tax: [
                        { OT: 3507 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 13326,
                      psup_fare: "9819_13326",
                    },
                  ],
                  pr: {
                    bf: 9819,
                    tf: 13326,
                    tax: [
                      { OT: 3507 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "9819_13326",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 13326,
                  },
                  routeId: "9qai5r8868",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~R~ ~6E~RCUINT~6202~~0~4~~X^0~R~ ~6E~RCUINT~6202~~0~4~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 10519,
                      fbc: "RCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "10519_14050",
                      tax: [
                        { OT: 3531 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 14050,
                      psup_fare: "10519_14050",
                    },
                  ],
                  pr: {
                    bf: 10519,
                    tf: 14050,
                    tax: [
                      { OT: 3531 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "10519_14050",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 14050,
                  },
                  routeId: "cwzlmnvfe4",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "TVhZN9pTbpY=|eZCNOj6QScTYrS+yBHuJwYzi+VNwga+stKu+2KxSl4ZFuiSjKtyKcPo3p3k8rIoCi5ChABOpvQwLuJe7b8QCAeecWtenKvYQhMv+Ae1482oirole5XXQV61j7WFegG3cGH5eApkuEC4=",
              stop: 1,
            },
            {
              tid: "VntRTMtpbrBSxQDP_1",
              ref: 1,
              office_id: "",
              sdur: "28:0",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-07T19:40:00",
                  ad: "2023-06-08T02:00:00",
                  rbd: "",
                  fn: "1308",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "3" },
                  rph: "",
                  jsell:
                    "6E~1308~ ~~DOH~06/07/2023 19:40~DEL~06/08/2023 02:00~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-06-08T21:25:00",
                  ad: "2023-06-08T23:40:00",
                  rbd: "",
                  fn: "2371",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "2" },
                  rph: "",
                  jsell:
                    "6E~2371~ ~~DEL~06/08/2023 21:25~HYD~06/08/2023 23:40~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~R~ ~6E~RCINT~6200~~0~65~~X^0~R~ ~6E~RCINT~6200~~0~65~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 9819,
                      fbc: "RCINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "9819_13326",
                      tax: [
                        { OT: 3507 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 13326,
                      psup_fare: "9819_13326",
                    },
                  ],
                  pr: {
                    bf: 9819,
                    tf: 13326,
                    tax: [
                      { OT: 3507 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "9819_13326",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 13326,
                  },
                  routeId: "p9kmsjb1t7",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~R~ ~6E~RCUINT~6202~~0~4~~X^0~R~ ~6E~RCUINT~6202~~0~4~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 10519,
                      fbc: "RCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "10519_14050",
                      tax: [
                        { OT: 3531 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 14050,
                      psup_fare: "10519_14050",
                    },
                  ],
                  pr: {
                    bf: 10519,
                    tf: 14050,
                    tax: [
                      { OT: 3531 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "10519_14050",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 14050,
                  },
                  routeId: "l4ftsnwk61",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "TVhZN9pTbpY=|eZCNOj6QScTYrS+yBHuJwYzi+VNwga+stKu+2KxSl4ZFuiSjKtyKcPo3p3k8rIoCi5ChABOpvQwLuJe7b8QCAeecWtenKvYQhMv+Ae1482oirole5XXQV61j7WFegG3cGH5eApkuEC4=",
              stop: 1,
            },
          ],
          paxpr: [
            {
              bf: 9819,
              fbc: "RCINT",
              ptype: "ADT",
              scur: "QAR",
              sup_fare: "9819_13326",
              tax: [
                { OT: 3507 },
                { attmkp: 0 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              tf: 13326,
              psup_fare: "9819_13326",
            },
          ],
          pr: {
            bf: 9819,
            tf: 13326,
            tax: [
              { OT: 3507 },
              { attmkp: 0 },
              { tamkp: 0 },
              { stamkp: 0 },
              { attdisc: 0 },
              { tadisc: 0 },
              { stadisc: 0 },
            ],
            ucur: "INR",
            exchange_rate: 1,
            scur: "INR",
            sup_fare: "9819_13326",
            sup_cur_fare: "",
            cabb: "",
            chekb: "",
            seats: "",
            meals: "",
            chanb: "",
            ftyp: "Regular - R",
            markupnames: [],
            ttf: 13326,
          },
          sup: "4",
          airlineList: "6E",
          servicefee: [],
        },
        {
          srcorigns: [
            {
              tid: "oLeAtAaVV7bv4TaZ_1",
              ref: 1,
              office_id: "",
              sdur: "21:40",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-07T19:40:00",
                  ad: "2023-06-08T02:00:00",
                  rbd: "",
                  fn: "1308",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "3" },
                  rph: "",
                  jsell:
                    "6E~1308~ ~~DOH~06/07/2023 19:40~DEL~06/08/2023 02:00~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-06-08T15:05:00",
                  ad: "2023-06-08T17:20:00",
                  rbd: "",
                  fn: "2187",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "2" },
                  rph: "",
                  jsell:
                    "6E~2187~ ~~DEL~06/08/2023 15:05~HYD~06/08/2023 17:20~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~Q~ ~6E~QCINT~6200~~0~65~~X^0~Q~ ~6E~QCINT~6200~~0~65~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 10665,
                      fbc: "QCINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "10665_14201",
                      tax: [
                        { OT: 3536 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 14201,
                      psup_fare: "10665_14201",
                    },
                  ],
                  pr: {
                    bf: 10665,
                    tf: 14201,
                    tax: [
                      { OT: 3536 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "10665_14201",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 14201,
                  },
                  routeId: "0pd312n9jy",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~Q~ ~6E~QCUINT~6202~~0~5~~X^0~Q~ ~6E~QCUINT~6202~~0~5~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 11365,
                      fbc: "QCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "11365_14926",
                      tax: [
                        { OT: 3561 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 14926,
                      psup_fare: "11365_14926",
                    },
                  ],
                  pr: {
                    bf: 11365,
                    tf: 14926,
                    tax: [
                      { OT: 3561 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "11365_14926",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 14926,
                  },
                  routeId: "5mg8kqbnogh",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "TVhZN9pTbpY=|eZCNOj6QScTYrS+yBHuJwYzi+VNwga+stKu+2KxSl4ZFuiSjKtyKcPo3p3k8rIoCi5ChABOpvQwLuJe7b8QCAeecWtenKvYQhMv+Ae1482oirole5XXQV61j7WFegG3cGH5eApkuEC4=",
              stop: 1,
            },
            {
              tid: "QzqKUcd3gqKA0T4U_1",
              ref: 1,
              office_id: "",
              sdur: "23:45",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-07T19:40:00",
                  ad: "2023-06-08T02:00:00",
                  rbd: "",
                  fn: "1308",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "3" },
                  rph: "",
                  jsell:
                    "6E~1308~ ~~DOH~06/07/2023 19:40~DEL~06/08/2023 02:00~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-06-08T17:15:00",
                  ad: "2023-06-08T19:25:00",
                  rbd: "",
                  fn: "5312",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "3" },
                  rph: "",
                  jsell:
                    "6E~5312~ ~~DEL~06/08/2023 17:15~HYD~06/08/2023 19:25~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~Q~ ~6E~QCINT~6200~~0~65~~X^0~Q~ ~6E~QCINT~6200~~0~65~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 10665,
                      fbc: "QCINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "10665_14201",
                      tax: [
                        { OT: 3536 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 14201,
                      psup_fare: "10665_14201",
                    },
                  ],
                  pr: {
                    bf: 10665,
                    tf: 14201,
                    tax: [
                      { OT: 3536 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "10665_14201",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 14201,
                  },
                  routeId: "bgvgf6lzzy",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~Q~ ~6E~QCUINT~6202~~0~5~~X^0~Q~ ~6E~QCUINT~6202~~0~5~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 11365,
                      fbc: "QCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "11365_14926",
                      tax: [
                        { OT: 3561 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 14926,
                      psup_fare: "11365_14926",
                    },
                  ],
                  pr: {
                    bf: 11365,
                    tf: 14926,
                    tax: [
                      { OT: 3561 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "11365_14926",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 14926,
                  },
                  routeId: "34ag0d3k2e",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "TVhZN9pTbpY=|eZCNOj6QScTYrS+yBHuJwYzi+VNwga+stKu+2KxSl4ZFuiSjKtyKcPo3p3k8rIoCi5ChABOpvQwLuJe7b8QCAeecWtenKvYQhMv+Ae1482oirole5XXQV61j7WFegG3cGH5eApkuEC4=",
              stop: 1,
            },
          ],
          paxpr: [
            {
              bf: 10665,
              fbc: "QCINT",
              ptype: "ADT",
              scur: "QAR",
              sup_fare: "10665_14201",
              tax: [
                { OT: 3536 },
                { attmkp: 0 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              tf: 14201,
              psup_fare: "10665_14201",
            },
          ],
          pr: {
            bf: 10665,
            tf: 14201,
            tax: [
              { OT: 3536 },
              { attmkp: 0 },
              { tamkp: 0 },
              { stamkp: 0 },
              { attdisc: 0 },
              { tadisc: 0 },
              { stadisc: 0 },
            ],
            ucur: "INR",
            exchange_rate: 1,
            scur: "INR",
            sup_fare: "10665_14201",
            sup_cur_fare: "",
            cabb: "",
            chekb: "",
            seats: "",
            meals: "",
            chanb: "",
            ftyp: "Regular - R",
            markupnames: [],
            ttf: 14201,
          },
          sup: "4",
          airlineList: "6E",
          servicefee: [],
        },
        {
          srcorigns: [
            {
              tid: "Kdl1Zeo7lVOd626Q_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "09:40",
              re: "Non-Refundable",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "WY",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "MCT",
                  dd: "2023-06-07T19:00:00",
                  ad: "2023-06-07T21:35:00",
                  rbd: "L",
                  fn: "664",
                  eq: "7M8",
                  ac: "WY",
                  ma: "WY",
                  seat: "7",
                  oa: "WY",
                  fa: [],
                  fdur: "01:35",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
                {
                  ref: 2,
                  da: "MCT",
                  ar: "HYD",
                  dd: "2023-06-08T02:15:00",
                  ad: "2023-06-08T07:10:00",
                  rbd: "L",
                  fn: "235",
                  eq: "738",
                  ac: "WY",
                  ma: "WY",
                  seat: "7",
                  oa: "WY",
                  fa: [],
                  fdur: "03:25",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
              ],
              flightNumbers: "664/235",
              bgd: [{ qun: "30", wt: "kg" }],
            },
            {
              tid: "85co2CycatrqezlG_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "17:05",
              re: "Non-Refundable",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "WY",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "MCT",
                  dd: "2023-06-07T19:00:00",
                  ad: "2023-06-07T21:35:00",
                  rbd: "L",
                  fn: "664",
                  eq: "7M8",
                  ac: "WY",
                  ma: "WY",
                  seat: "7",
                  oa: "WY",
                  fa: [],
                  fdur: "01:35",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
                {
                  ref: 2,
                  da: "MCT",
                  ar: "HYD",
                  dd: "2023-06-08T09:40:00",
                  ad: "2023-06-08T14:35:00",
                  rbd: "L",
                  fn: "231",
                  eq: "738",
                  ac: "WY",
                  ma: "WY",
                  seat: "7",
                  oa: "WY",
                  fa: [],
                  fdur: "03:25",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
              ],
              flightNumbers: "664/231",
              bgd: [{ qun: "30", wt: "kg" }],
            },
            {
              tid: "scJpvUv2yZRq4uWT_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "20:50",
              re: "Non-Refundable",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "WY",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "MCT",
                  dd: "2023-06-07T07:50:00",
                  ad: "2023-06-07T10:25:00",
                  rbd: "L",
                  fn: "670",
                  eq: "7M8",
                  ac: "WY",
                  ma: "WY",
                  seat: "7",
                  oa: "WY",
                  fa: [],
                  fdur: "01:35",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
                {
                  ref: 2,
                  da: "MCT",
                  ar: "HYD",
                  dd: "2023-06-08T02:15:00",
                  ad: "2023-06-08T07:10:00",
                  rbd: "L",
                  fn: "235",
                  eq: "738",
                  ac: "WY",
                  ma: "WY",
                  seat: "7",
                  oa: "WY",
                  fa: [],
                  fdur: "03:25",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
              ],
              flightNumbers: "670/235",
              bgd: [{ qun: "30", wt: "kg" }],
            },
            {
              tid: "QesC9NraTERexTE1_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "28:15",
              re: "Non-Refundable",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "WY",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "MCT",
                  dd: "2023-06-07T07:50:00",
                  ad: "2023-06-07T10:25:00",
                  rbd: "L",
                  fn: "670",
                  eq: "7M8",
                  ac: "WY",
                  ma: "WY",
                  seat: "7",
                  oa: "WY",
                  fa: [],
                  fdur: "01:35",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
                {
                  ref: 2,
                  da: "MCT",
                  ar: "HYD",
                  dd: "2023-06-08T09:40:00",
                  ad: "2023-06-08T14:35:00",
                  rbd: "L",
                  fn: "231",
                  eq: "738",
                  ac: "WY",
                  ma: "WY",
                  seat: "7",
                  oa: "WY",
                  fa: [],
                  fdur: "03:25",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
              ],
              flightNumbers: "670/231",
              bgd: [{ qun: "30", wt: "kg" }],
            },
            {
              tid: "S9mSzwhfZSVSCuxt_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "27:20",
              re: "Non-Refundable",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "WY",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "MCT",
                  dd: "2023-06-07T01:20:00",
                  ad: "2023-06-07T03:55:00",
                  rbd: "L",
                  fn: "662",
                  eq: "7M8",
                  ac: "WY",
                  ma: "WY",
                  seat: "7",
                  oa: "WY",
                  fa: [],
                  fdur: "01:35",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
                {
                  ref: 2,
                  da: "MCT",
                  ar: "HYD",
                  dd: "2023-06-08T02:15:00",
                  ad: "2023-06-08T07:10:00",
                  rbd: "L",
                  fn: "235",
                  eq: "738",
                  ac: "WY",
                  ma: "WY",
                  seat: "7",
                  oa: "WY",
                  fa: [],
                  fdur: "03:25",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
              ],
              flightNumbers: "662/235",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          pr: {
            bf: 6899.2,
            tf: 19443.2,
            tax: [
              { YQ: 4704 },
              { YR: 2688 },
              { OT: 5152 },
              { dealmkp: 0 },
              { attmkp: 627.2 },
              { tamkp: 0 },
              { stamkp: 0 },
              { attdisc: 0 },
              { tadisc: 0 },
              { stadisc: 0 },
            ],
            scur: "QAR",
            ucur: "INR",
            exchange_rate: 22.4,
            sup_fare: "280_840",
            markupnames: [{ code: "MTA", name: "LaxmiAprilMarkup" }],
          },
          paxpr: [
            {
              tf: 19443.2,
              tax: [
                { YQ: 4704 },
                { YR: 2688 },
                { OT: 5152 },
                { attmkp: 627.2 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              bf: 6899.2,
              fbc: "LELOQA",
              fareType: "RP",
              pusup_fare: "280_840",
              psup_fare: "6272_18816",
              ptype: "ADT",
            },
          ],
          sup: "0",
          airlinesList: "WY",
          servicefee: [
            {
              type: "ATT",
              adt: 689.92,
              chd: 0,
              inf: 0,
              servicefee_name: "LaxmiServieApril",
              apl_on: "Per Ticket",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 689.92,
              sup_fee: 0,
            },
            {
              type: "TA",
              adt: 0,
              chd: 0,
              inf: 0,
              apl_on: "",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 0,
              sup_fee: 689.92,
            },
          ],
        },
        {
          srcorigns: [
            {
              tid: "rnt7uVD3Sa42qyze_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "19:25",
              re: "Refundable Before Departure",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "UL",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "CMB",
                  dd: "2023-06-07T22:30:00",
                  ad: "2023-06-08T05:45:00",
                  rbd: "N",
                  fn: "218",
                  eq: "333",
                  ac: "UL",
                  ma: "UL",
                  seat: "9",
                  oa: "UL",
                  fa: [],
                  fdur: "04:45",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
                {
                  ref: 2,
                  da: "CMB",
                  ar: "HYD",
                  dd: "2023-06-08T18:30:00",
                  ad: "2023-06-08T20:25:00",
                  rbd: "N",
                  fn: "175",
                  eq: "32A",
                  ac: "UL",
                  ma: "UL",
                  seat: "9",
                  oa: "UL",
                  fa: [],
                  fdur: "01:55",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
              ],
              flightNumbers: "218/175",
              bgd: [{ qun: "40", wt: "kg" }],
            },
            {
              tid: "dedXDOPllkNE3Jqa_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "26:35",
              re: "Refundable Before Departure",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "UL",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "CMB",
                  dd: "2023-06-07T22:30:00",
                  ad: "2023-06-08T05:45:00",
                  rbd: "N",
                  fn: "218",
                  eq: "333",
                  ac: "UL",
                  ma: "UL",
                  seat: "9",
                  oa: "UL",
                  fa: [],
                  fdur: "04:45",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
                {
                  ref: 2,
                  da: "CMB",
                  ar: "HYD",
                  dd: "2023-06-09T01:40:00",
                  ad: "2023-06-09T03:35:00",
                  rbd: "N",
                  fn: "1177",
                  eq: "320",
                  ac: "UL",
                  ma: "UL",
                  seat: "9",
                  oa: "UL",
                  fa: [],
                  fdur: "01:55",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
              ],
              flightNumbers: "218/1177",
              bgd: [{ qun: "40", wt: "kg" }],
            },
          ],
          pr: {
            bf: 11827.2,
            tf: 20339.2,
            tax: [
              { YQ: 5376 },
              { OT: 3136 },
              { dealmkp: 0 },
              { attmkp: 1075.2 },
              { tamkp: 0 },
              { stamkp: 0 },
              { attdisc: 0 },
              { tadisc: 0 },
              { stadisc: 0 },
            ],
            scur: "QAR",
            ucur: "INR",
            exchange_rate: 22.4,
            sup_fare: "480_860",
            markupnames: [{ code: "MTA", name: "LaxmiAprilMarkup" }],
          },
          paxpr: [
            {
              tf: 20339.2,
              tax: [
                { YQ: 5376 },
                { OT: 3136 },
                { attmkp: 1075.2 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              bf: 11827.2,
              fbc: "NOW1QA",
              fareType: "RP",
              pusup_fare: "480_860",
              psup_fare: "10752_19264",
              ptype: "ADT",
            },
          ],
          sup: "0",
          airlinesList: "UL",
          servicefee: [
            {
              type: "ATT",
              adt: 1182.72,
              chd: 0,
              inf: 0,
              servicefee_name: "LaxmiServieApril",
              apl_on: "Per Ticket",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 1182.72,
              sup_fee: 0,
            },
            {
              type: "TA",
              adt: 0,
              chd: 0,
              inf: 0,
              apl_on: "",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 0,
              sup_fee: 1182.72,
            },
          ],
        },
        {
          srcorigns: [
            {
              tid: "5PPFXmvbmJ6DD5dB_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "22:10",
              re: "Refundable Before Departure",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "UL",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "CMB",
                  dd: "2023-06-07T19:45:00",
                  ad: "2023-06-08T03:25:00",
                  rbd: "O",
                  fn: "3012",
                  eq: "77W",
                  ac: "UL",
                  ma: "UL",
                  seat: "9",
                  oa: "QR",
                  fa: [],
                  fdur: "05:10",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
                {
                  ref: 2,
                  da: "CMB",
                  ar: "HYD",
                  dd: "2023-06-08T18:30:00",
                  ad: "2023-06-08T20:25:00",
                  rbd: "O",
                  fn: "175",
                  eq: "32A",
                  ac: "UL",
                  ma: "UL",
                  seat: "9",
                  oa: "UL",
                  fa: [],
                  fdur: "01:55",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
              ],
              flightNumbers: "3012/175",
              bgd: [{ qun: "30", wt: "kg" }],
            },
            {
              tid: "GUqnbvwC4OTHIGOJ_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "29:20",
              re: "Refundable Before Departure",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "UL",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "CMB",
                  dd: "2023-06-07T19:45:00",
                  ad: "2023-06-08T03:25:00",
                  rbd: "O",
                  fn: "3012",
                  eq: "77W",
                  ac: "UL",
                  ma: "UL",
                  seat: "9",
                  oa: "QR",
                  fa: [],
                  fdur: "05:10",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
                {
                  ref: 2,
                  da: "CMB",
                  ar: "HYD",
                  dd: "2023-06-09T01:40:00",
                  ad: "2023-06-09T03:35:00",
                  rbd: "O",
                  fn: "1177",
                  eq: "320",
                  ac: "UL",
                  ma: "UL",
                  seat: "9",
                  oa: "UL",
                  fa: [],
                  fdur: "01:55",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
              ],
              flightNumbers: "3012/1177",
              bgd: [{ qun: "30", wt: "kg" }],
            },
            {
              tid: "q2FDWMSLybTx9htX_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "23:30",
              re: "Refundable Before Departure",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "UL",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "CMB",
                  dd: "2023-06-07T18:25:00",
                  ad: "2023-06-08T02:05:00",
                  rbd: "O",
                  fn: "3572",
                  eq: "333",
                  ac: "UL",
                  ma: "UL",
                  seat: "9",
                  oa: "QR",
                  fa: [],
                  fdur: "05:10",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
                {
                  ref: 2,
                  da: "CMB",
                  ar: "HYD",
                  dd: "2023-06-08T18:30:00",
                  ad: "2023-06-08T20:25:00",
                  rbd: "O",
                  fn: "175",
                  eq: "32A",
                  ac: "UL",
                  ma: "UL",
                  seat: "9",
                  oa: "UL",
                  fa: [],
                  fdur: "01:55",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
              ],
              flightNumbers: "3572/175",
              bgd: [{ qun: "30", wt: "kg" }],
            },
            {
              tid: "4qtIVTAdEPJXL3uR_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "30:40",
              re: "Refundable Before Departure",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "UL",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "CMB",
                  dd: "2023-06-07T18:25:00",
                  ad: "2023-06-08T02:05:00",
                  rbd: "O",
                  fn: "3572",
                  eq: "333",
                  ac: "UL",
                  ma: "UL",
                  seat: "9",
                  oa: "QR",
                  fa: [],
                  fdur: "05:10",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
                {
                  ref: 2,
                  da: "CMB",
                  ar: "HYD",
                  dd: "2023-06-09T01:40:00",
                  ad: "2023-06-09T03:35:00",
                  rbd: "O",
                  fn: "1177",
                  eq: "320",
                  ac: "UL",
                  ma: "UL",
                  seat: "9",
                  oa: "UL",
                  fa: [],
                  fdur: "01:55",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
              ],
              flightNumbers: "3572/1177",
              bgd: [{ qun: "30", wt: "kg" }],
            },
            {
              tid: "Fgxk7NMkB5uFPnZa_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "16:35",
              re: "Refundable Before Departure",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "UL",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "CMB",
                  dd: "2023-06-07T01:20:00",
                  ad: "2023-06-07T09:00:00",
                  rbd: "O",
                  fn: "3500",
                  eq: "332",
                  ac: "UL",
                  ma: "UL",
                  seat: "9",
                  oa: "QR",
                  fa: [],
                  fdur: "05:10",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
                {
                  ref: 2,
                  da: "CMB",
                  ar: "HYD",
                  dd: "2023-06-07T18:30:00",
                  ad: "2023-06-07T20:25:00",
                  rbd: "O",
                  fn: "175",
                  eq: "320",
                  ac: "UL",
                  ma: "UL",
                  seat: "9",
                  oa: "UL",
                  fa: [],
                  fdur: "01:55",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
              ],
              flightNumbers: "3500/175",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          pr: {
            bf: 17001.6,
            tf: 25513.6,
            tax: [
              { YQ: 5376 },
              { OT: 3136 },
              { dealmkp: 0 },
              { attmkp: 1545.6 },
              { tamkp: 0 },
              { stamkp: 0 },
              { attdisc: 0 },
              { tadisc: 0 },
              { stadisc: 0 },
            ],
            scur: "QAR",
            ucur: "INR",
            exchange_rate: 22.4,
            sup_fare: "690_1070",
            markupnames: [{ code: "MTA", name: "LaxmiAprilMarkup" }],
          },
          paxpr: [
            {
              tf: 25513.6,
              tax: [
                { YQ: 5376 },
                { OT: 3136 },
                { attmkp: 1545.6 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              bf: 17001.6,
              fbc: "OOWQQA",
              fareType: "RP",
              pusup_fare: "690_1070",
              psup_fare: "15456_23968",
              ptype: "ADT",
            },
          ],
          sup: "0",
          airlinesList: "UL",
          servicefee: [
            {
              type: "ATT",
              adt: 1700.16,
              chd: 0,
              inf: 0,
              servicefee_name: "LaxmiServieApril",
              apl_on: "Per Ticket",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 1700.16,
              sup_fee: 0,
            },
            {
              type: "TA",
              adt: 0,
              chd: 0,
              inf: 0,
              apl_on: "",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 0,
              sup_fee: 1700.16,
            },
          ],
        },
        {
          srcorigns: [
            {
              tid: "hZaCCwuXiWlXRAkH_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "34:05",
              re: "Refundable Before Departure",
              stop: 2,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-07T22:35:00",
                  ad: "2023-06-08T05:05:00",
                  rbd: "U",
                  fn: "972",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "5",
                  oa: "AI",
                  fa: [],
                  fdur: "04:00",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "BLR",
                  dd: "2023-06-08T13:30:00",
                  ad: "2023-06-08T16:15:00",
                  rbd: "Q",
                  fn: "502",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:45",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
                {
                  ref: 3,
                  da: "BLR",
                  ar: "HYD",
                  dd: "2023-06-09T10:00:00",
                  ad: "2023-06-09T11:10:00",
                  rbd: "M",
                  fn: "516",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "1",
                  oa: "AI",
                  fa: [],
                  fdur: "01:10",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
              ],
              flightNumbers: "972/502/516",
              bgd: [{ qun: "30", wt: "kg" }],
            },
            {
              tid: "SwnGXdiBJKgHbNcl_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "34:05",
              re: "Refundable Before Departure",
              stop: 2,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-07T22:35:00",
                  ad: "2023-06-08T05:05:00",
                  rbd: "U",
                  fn: "972",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "5",
                  oa: "AI",
                  fa: [],
                  fdur: "04:00",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "BLR",
                  dd: "2023-06-08T21:15:00",
                  ad: "2023-06-09T00:05:00",
                  rbd: "Q",
                  fn: "504",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:50",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
                {
                  ref: 3,
                  da: "BLR",
                  ar: "HYD",
                  dd: "2023-06-09T10:00:00",
                  ad: "2023-06-09T11:10:00",
                  rbd: "M",
                  fn: "516",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "1",
                  oa: "AI",
                  fa: [],
                  fdur: "01:10",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
              ],
              flightNumbers: "972/504/516",
              bgd: [{ qun: "30", wt: "kg" }],
            },
            {
              tid: "z2POkvUXJPeQWdS5_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "34:05",
              re: "Refundable Before Departure",
              stop: 2,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-07T22:35:00",
                  ad: "2023-06-08T05:05:00",
                  rbd: "U",
                  fn: "972",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "5",
                  oa: "AI",
                  fa: [],
                  fdur: "04:00",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "BLR",
                  dd: "2023-06-08T09:45:00",
                  ad: "2023-06-08T12:30:00",
                  rbd: "Q",
                  fn: "506",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:45",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
                {
                  ref: 3,
                  da: "BLR",
                  ar: "HYD",
                  dd: "2023-06-09T10:00:00",
                  ad: "2023-06-09T11:10:00",
                  rbd: "M",
                  fn: "516",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "1",
                  oa: "AI",
                  fa: [],
                  fdur: "01:10",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
              ],
              flightNumbers: "972/506/516",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          pr: {
            bf: 21190.4,
            tf: 25446.4,
            tax: [
              { YR: 672 },
              { YR: 448 },
              { OT: 3136 },
              { dealmkp: 0 },
              { attmkp: 1926.4 },
              { tamkp: 0 },
              { stamkp: 0 },
              { attdisc: 0 },
              { tadisc: 0 },
              { stadisc: 0 },
            ],
            scur: "QAR",
            ucur: "INR",
            exchange_rate: 22.4,
            sup_fare: "860_1050",
            markupnames: [{ code: "MTA", name: "LaxmiAprilMarkup" }],
          },
          paxpr: [
            {
              tf: 25446.4,
              tax: [
                { YR: 672 },
                { YR: 448 },
                { OT: 3136 },
                { attmkp: 1926.4 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              bf: 21190.4,
              fbc: "UOWDHBL",
              fareType: "RP",
              pusup_fare: "860_1050",
              psup_fare: "19264_23520",
              ptype: "ADT",
            },
          ],
          sup: "0",
          airlinesList: "AI",
          servicefee: [
            {
              type: "ATT",
              adt: 2119.04,
              chd: 0,
              inf: 0,
              servicefee_name: "LaxmiServieApril",
              apl_on: "Per Ticket",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 2119.04,
              sup_fee: 0,
            },
            {
              type: "TA",
              adt: 0,
              chd: 0,
              inf: 0,
              apl_on: "",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 0,
              sup_fee: 2119.04,
            },
          ],
        },
        {
          srcorigns: [
            {
              tid: "OvouRRT5nE1Gs74c_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "12:40",
              re: "Refundable Before Departure",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "KU",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "KWI",
                  dd: "2023-06-07T12:55:00",
                  ad: "2023-06-07T14:30:00",
                  rbd: "H",
                  fn: "618",
                  eq: "32N",
                  ac: "KU",
                  ma: "KU",
                  seat: "9",
                  oa: "KU",
                  fa: [],
                  fdur: "01:35",
                  cl: "Economy",
                  terminal: { dep: "", arr: "4" },
                },
                {
                  ref: 2,
                  da: "KWI",
                  ar: "HYD",
                  dd: "2023-06-07T21:00:00",
                  ad: "2023-06-08T04:05:00",
                  rbd: "H",
                  fn: "373",
                  trn: "4",
                  eq: "32N",
                  ac: "KU",
                  ma: "KU",
                  seat: "9",
                  oa: "KU",
                  fa: [],
                  fdur: "04:35",
                  cl: "Economy",
                  terminal: { dep: "4", arr: "" },
                },
              ],
              flightNumbers: "618/373",
              bgd: [{ qun: "2", wt: "pieces" }],
            },
          ],
          pr: {
            bf: 22668.8,
            tf: 31628.8,
            tax: [
              { YQ: 1792 },
              { YR: 1344 },
              { OT: 5824 },
              { dealmkp: 0 },
              { attmkp: 2060.8 },
              { tamkp: 0 },
              { stamkp: 0 },
              { attdisc: 0 },
              { tadisc: 0 },
              { stadisc: 0 },
            ],
            scur: "QAR",
            ucur: "INR",
            exchange_rate: 22.4,
            sup_fare: "920_1320",
            markupnames: [{ code: "MTA", name: "LaxmiAprilMarkup" }],
          },
          paxpr: [
            {
              tf: 31628.8,
              tax: [
                { YQ: 1792 },
                { YR: 1344 },
                { OT: 5824 },
                { attmkp: 2060.8 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              bf: 22668.8,
              fbc: "HLOWQA1",
              fareType: "RP",
              pusup_fare: "920_1320",
              psup_fare: "20608_29568",
              ptype: "ADT",
            },
          ],
          sup: "0",
          airlinesList: "KU",
          servicefee: [
            {
              type: "ATT",
              adt: 2266.88,
              chd: 0,
              inf: 0,
              servicefee_name: "LaxmiServieApril",
              apl_on: "Per Ticket",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 2266.88,
              sup_fee: 0,
            },
            {
              type: "TA",
              adt: 0,
              chd: 0,
              inf: 0,
              apl_on: "",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 0,
              sup_fee: 2266.88,
            },
          ],
        },
        {
          srcorigns: [
            {
              tid: "HuAUuSTP8zvkGL3e_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "08:20",
              re: "Refundable Before Departure",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-07T22:35:00",
                  ad: "2023-06-08T05:05:00",
                  rbd: "U",
                  fn: "972",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "5",
                  oa: "AI",
                  fa: [],
                  fdur: "04:00",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-06-08T07:10:00",
                  ad: "2023-06-08T09:25:00",
                  rbd: "M",
                  fn: "560",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:15",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "972/560",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          pr: {
            bf: 28089.6,
            tf: 31897.6,
            tax: [
              { YR: 448 },
              { YR: 224 },
              { OT: 3136 },
              { dealmkp: 0 },
              { attmkp: 2553.6 },
              { tamkp: 0 },
              { stamkp: 0 },
              { attdisc: 0 },
              { tadisc: 0 },
              { stadisc: 0 },
            ],
            scur: "QAR",
            ucur: "INR",
            exchange_rate: 22.4,
            sup_fare: "1140_1310",
            markupnames: [{ code: "MTA", name: "LaxmiAprilMarkup" }],
          },
          paxpr: [
            {
              tf: 31897.6,
              tax: [
                { YR: 448 },
                { YR: 224 },
                { OT: 3136 },
                { attmkp: 2553.6 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              bf: 28089.6,
              fbc: "UOWDHDL",
              fareType: "RP",
              pusup_fare: "1140_1310",
              psup_fare: "25536_29344",
              ptype: "ADT",
            },
          ],
          sup: "0",
          airlinesList: "AI",
          servicefee: [
            {
              type: "ATT",
              adt: 2808.96,
              chd: 0,
              inf: 0,
              servicefee_name: "LaxmiServieApril",
              apl_on: "Per Ticket",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 2808.96,
              sup_fee: 0,
            },
            {
              type: "TA",
              adt: 0,
              chd: 0,
              inf: 0,
              apl_on: "",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 0,
              sup_fee: 2808.96,
            },
          ],
        },
        {
          srcorigns: [
            {
              tid: "A6pFG7FezTZqEJre_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "04:15",
              re: "Refundable Before Departure",
              stop: 0,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "QR",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "HYD",
                  dd: "2023-06-07T19:45:00",
                  ad: "2023-06-08T02:30:00",
                  rbd: "V",
                  fn: "4774",
                  eq: "320",
                  ac: "QR",
                  ma: "QR",
                  seat: "9",
                  oa: "6E",
                  fa: [],
                  fdur: "04:15",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
              ],
              flightNumbers: "4774",
              bgd: [{ qun: "30", wt: "kg" }],
            },
            {
              tid: "sdRE7JnpnxTBKoRT_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "04:15",
              re: "Refundable Before Departure",
              stop: 0,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "QR",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "HYD",
                  dd: "2023-06-07T08:15:00",
                  ad: "2023-06-07T15:00:00",
                  rbd: "V",
                  fn: "4776",
                  eq: "320",
                  ac: "QR",
                  ma: "QR",
                  seat: "9",
                  oa: "6E",
                  fa: [],
                  fdur: "04:15",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
              ],
              flightNumbers: "4776",
              bgd: [{ qun: "30", wt: "kg" }],
            },
            {
              tid: "GTK3LB4kiVWwr474_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "04:15",
              re: "Refundable Before Departure",
              stop: 0,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "QR",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "HYD",
                  dd: "2023-06-07T02:00:00",
                  ad: "2023-06-07T08:45:00",
                  rbd: "V",
                  fn: "4778",
                  eq: "320",
                  ac: "QR",
                  ma: "QR",
                  seat: "9",
                  oa: "6E",
                  fa: [],
                  fdur: "04:15",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
              ],
              flightNumbers: "4778",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          pr: {
            bf: 28089.6,
            tf: 35705.6,
            tax: [
              { YQ: 3136 },
              { YR: 1344 },
              { OT: 3136 },
              { dealmkp: 0 },
              { attmkp: 2553.6 },
              { tamkp: 0 },
              { stamkp: 0 },
              { attdisc: 0 },
              { tadisc: 0 },
              { stadisc: 0 },
            ],
            scur: "QAR",
            ucur: "INR",
            exchange_rate: 22.4,
            sup_fare: "1140_1480",
            markupnames: [{ code: "MTA", name: "LaxmiAprilMarkup" }],
          },
          paxpr: [
            {
              tf: 35705.6,
              tax: [
                { YQ: 3136 },
                { YR: 1344 },
                { OT: 3136 },
                { attmkp: 2553.6 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              bf: 28089.6,
              fbc: "VLQAI1SI",
              fareType: "RP",
              pusup_fare: "1140_1480",
              psup_fare: "25536_33152",
              ptype: "ADT",
            },
          ],
          sup: "0",
          airlinesList: "QR",
          servicefee: [
            {
              type: "ATT",
              adt: 2808.96,
              chd: 0,
              inf: 0,
              servicefee_name: "LaxmiServieApril",
              apl_on: "Per Ticket",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 2808.96,
              sup_fee: 0,
            },
            {
              type: "TA",
              adt: 0,
              chd: 0,
              inf: 0,
              apl_on: "",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 0,
              sup_fee: 2808.96,
            },
          ],
        },
        {
          srcorigns: [
            {
              tid: "i2KHXRDiMgrdS0aW_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "04:15",
              re: "Refundable Before Departure",
              stop: 0,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "QR",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "HYD",
                  dd: "2023-06-07T19:15:00",
                  ad: "2023-06-08T02:00:00",
                  rbd: "V",
                  fn: "500",
                  eq: "320",
                  ac: "QR",
                  ma: "QR",
                  seat: "9",
                  oa: "QR",
                  fa: [],
                  fdur: "04:15",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
              ],
              flightNumbers: "500",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          pr: {
            bf: 28089.6,
            tf: 35705.6,
            tax: [
              { YQ: 3136 },
              { YR: 1344 },
              { OT: 3136 },
              { dealmkp: 0 },
              { attmkp: 2553.6 },
              { tamkp: 0 },
              { stamkp: 0 },
              { attdisc: 0 },
              { tadisc: 0 },
              { stadisc: 0 },
            ],
            scur: "QAR",
            ucur: "INR",
            exchange_rate: 22.4,
            sup_fare: "1140_1480",
            markupnames: [{ code: "MTA", name: "LaxmiAprilMarkup" }],
          },
          paxpr: [
            {
              tf: 35705.6,
              tax: [
                { YQ: 3136 },
                { YR: 1344 },
                { OT: 3136 },
                { attmkp: 2553.6 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              bf: 28089.6,
              fbc: "VLQAR1SI",
              fareType: "RP",
              pusup_fare: "1140_1480",
              psup_fare: "25536_33152",
              ptype: "ADT",
            },
          ],
          sup: "0",
          airlinesList: "QR",
          servicefee: [
            {
              type: "ATT",
              adt: 2808.96,
              chd: 0,
              inf: 0,
              servicefee_name: "LaxmiServieApril",
              apl_on: "Per Ticket",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 2808.96,
              sup_fee: 0,
            },
            {
              type: "TA",
              adt: 0,
              chd: 0,
              inf: 0,
              apl_on: "",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 0,
              sup_fee: 2808.96,
            },
          ],
        },
        {
          srcorigns: [
            {
              tid: "537ltfa1teW5TT2G_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "17:55",
              re: "Refundable Before Departure",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "XY",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "RUH",
                  dd: "2023-06-07T11:35:00",
                  ad: "2023-06-07T12:55:00",
                  rbd: "D",
                  fn: "224",
                  eq: "320",
                  ac: "XY",
                  ma: "XY",
                  seat: "9",
                  oa: "XY",
                  fa: [],
                  fdur: "01:20",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "RUH",
                  ar: "HYD",
                  dd: "2023-06-08T00:35:00",
                  ad: "2023-06-08T08:00:00",
                  rbd: "N",
                  fn: "325",
                  trn: "3",
                  eq: "320",
                  ac: "XY",
                  ma: "XY",
                  seat: "9",
                  oa: "XY",
                  fa: [],
                  fdur: "04:55",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "224/325",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          pr: {
            bf: 19219.2,
            tf: 38035.2,
            tax: [
              { YQ: 11648 },
              { YR: 2240 },
              { OT: 4928 },
              { dealmkp: 0 },
              { attmkp: 1747.2 },
              { tamkp: 0 },
              { stamkp: 0 },
              { attdisc: 0 },
              { tadisc: 0 },
              { stadisc: 0 },
            ],
            scur: "QAR",
            ucur: "INR",
            exchange_rate: 22.4,
            sup_fare: "780_1620",
            markupnames: [{ code: "MTA", name: "LaxmiAprilMarkup" }],
          },
          paxpr: [
            {
              tf: 38035.2,
              tax: [
                { YQ: 11648 },
                { YR: 2240 },
                { OT: 4928 },
                { attmkp: 1747.2 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              bf: 19219.2,
              fbc: "DFAREOW",
              fareType: "RP",
              pusup_fare: "780_1620",
              psup_fare: "17472_36288",
              ptype: "ADT",
            },
          ],
          sup: "0",
          airlinesList: "XY",
          servicefee: [
            {
              type: "ATT",
              adt: 1921.92,
              chd: 0,
              inf: 0,
              servicefee_name: "LaxmiServieApril",
              apl_on: "Per Ticket",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 1921.92,
              sup_fee: 0,
            },
            {
              type: "TA",
              adt: 0,
              chd: 0,
              inf: 0,
              apl_on: "",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 0,
              sup_fee: 1921.92,
            },
          ],
        },
        {
          srcorigns: [
            {
              tid: "Wkxx0WVWO31OfgfV_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "08:35",
              re: "Refundable Before Departure",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "QR",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "BOM",
                  dd: "2023-06-07T20:35:00",
                  ad: "2023-06-08T02:45:00",
                  rbd: "V",
                  fn: "556",
                  eq: "77W",
                  ac: "QR",
                  ma: "QR",
                  seat: "9",
                  oa: "QR",
                  fa: [],
                  fdur: "03:40",
                  cl: "Economy",
                  terminal: { dep: "", arr: "2" },
                },
                {
                  ref: 2,
                  da: "BOM",
                  ar: "HYD",
                  dd: "2023-06-08T06:15:00",
                  ad: "2023-06-08T07:40:00",
                  rbd: "Q",
                  fn: "873",
                  trn: "2",
                  eq: "320",
                  ac: "UK",
                  ma: "UK",
                  seat: "9",
                  oa: "UK",
                  fa: [],
                  fdur: "01:25",
                  cl: "Economy",
                  terminal: { dep: "2", arr: "" },
                },
              ],
              flightNumbers: "556/873",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          pr: {
            bf: 39424,
            tf: 47040,
            tax: [
              { YQ: 3136 },
              { YR: 1344 },
              { OT: 3136 },
              { dealmkp: 0 },
              { attmkp: 3584 },
              { tamkp: 0 },
              { stamkp: 0 },
              { attdisc: 0 },
              { tadisc: 0 },
              { stadisc: 0 },
            ],
            scur: "QAR",
            ucur: "INR",
            exchange_rate: 22.4,
            sup_fare: "1600_1940",
            markupnames: [{ code: "MTA", name: "LaxmiAprilMarkup" }],
          },
          paxpr: [
            {
              tf: 47040,
              tax: [
                { YQ: 3136 },
                { YR: 1344 },
                { OT: 3136 },
                { attmkp: 3584 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              bf: 39424,
              fbc: "VLQAR1SI",
              fareType: "RP",
              pusup_fare: "1600_1940",
              psup_fare: "35840_43456",
              ptype: "ADT",
            },
          ],
          sup: "0",
          airlinesList: "QR_UK",
          servicefee: [
            {
              type: "ATT",
              adt: 3942.4,
              chd: 0,
              inf: 0,
              servicefee_name: "LaxmiServieApril",
              apl_on: "Per Ticket",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 3942.4,
              sup_fee: 0,
            },
            {
              type: "TA",
              adt: 0,
              chd: 0,
              inf: 0,
              apl_on: "",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 0,
              sup_fee: 3942.4,
            },
          ],
        },
        {
          srcorigns: [
            {
              tid: "H7yDSnlCRpMICgAW_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "25:55",
              re: "Refundable Before Departure",
              stop: 2,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "TK",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "IST",
                  dd: "2023-06-07T06:00:00",
                  ad: "2023-06-07T10:30:00",
                  rbd: "T",
                  fn: "783",
                  trn: "1",
                  eq: "321",
                  ac: "TK",
                  ma: "TK",
                  seat: "9",
                  oa: "TK",
                  fa: [],
                  fdur: "04:30",
                  cl: "Economy",
                  terminal: { dep: "1", arr: "" },
                },
                {
                  ref: 2,
                  da: "IST",
                  ar: "BOM",
                  dd: "2023-06-07T20:05:00",
                  ad: "2023-06-08T04:55:00",
                  rbd: "T",
                  fn: "720",
                  eq: "77W",
                  ac: "TK",
                  ma: "TK",
                  seat: "9",
                  oa: "TK",
                  fa: [],
                  fdur: "06:20",
                  cl: "Economy",
                  terminal: { dep: "", arr: "2" },
                },
                {
                  ref: 3,
                  da: "BOM",
                  ar: "HYD",
                  dd: "2023-06-08T09:00:00",
                  ad: "2023-06-08T10:25:00",
                  rbd: "T",
                  fn: "4740",
                  trn: "2",
                  eq: "320",
                  ac: "TK",
                  ma: "TK",
                  seat: "4",
                  oa: "6E",
                  fa: [],
                  fdur: "01:25",
                  cl: "Economy",
                  terminal: { dep: "2", arr: "" },
                },
              ],
              flightNumbers: "783/720/4740",
              bgd: [{ qun: "30", wt: "kg" }],
            },
            {
              tid: "esnnR8zxObz0uwvK_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "28:10",
              re: "Refundable Before Departure",
              stop: 2,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "TK",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "IST",
                  dd: "2023-06-07T06:00:00",
                  ad: "2023-06-07T10:30:00",
                  rbd: "T",
                  fn: "783",
                  trn: "1",
                  eq: "321",
                  ac: "TK",
                  ma: "TK",
                  seat: "9",
                  oa: "TK",
                  fa: [],
                  fdur: "04:30",
                  cl: "Economy",
                  terminal: { dep: "1", arr: "" },
                },
                {
                  ref: 2,
                  da: "IST",
                  ar: "BOM",
                  dd: "2023-06-07T20:05:00",
                  ad: "2023-06-08T04:55:00",
                  rbd: "T",
                  fn: "720",
                  eq: "77W",
                  ac: "TK",
                  ma: "TK",
                  seat: "9",
                  oa: "TK",
                  fa: [],
                  fdur: "06:20",
                  cl: "Economy",
                  terminal: { dep: "", arr: "2" },
                },
                {
                  ref: 3,
                  da: "BOM",
                  ar: "HYD",
                  dd: "2023-06-08T11:10:00",
                  ad: "2023-06-08T12:40:00",
                  rbd: "T",
                  fn: "4741",
                  trn: "2",
                  eq: "320",
                  ac: "TK",
                  ma: "TK",
                  seat: "4",
                  oa: "6E",
                  fa: [],
                  fdur: "01:30",
                  cl: "Economy",
                  terminal: { dep: "2", arr: "" },
                },
              ],
              flightNumbers: "783/720/4741",
              bgd: [{ qun: "30", wt: "kg" }],
            },
            {
              tid: "LgZhOtnQCtdTAVTq_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "30:40",
              re: "Refundable Before Departure",
              stop: 2,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "TK",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "IST",
                  dd: "2023-06-07T01:15:00",
                  ad: "2023-06-07T05:45:00",
                  rbd: "T",
                  fn: "781",
                  trn: "1",
                  eq: "321",
                  ac: "TK",
                  ma: "TK",
                  seat: "9",
                  oa: "TK",
                  fa: [],
                  fdur: "04:30",
                  cl: "Economy",
                  terminal: { dep: "1", arr: "" },
                },
                {
                  ref: 2,
                  da: "IST",
                  ar: "BOM",
                  dd: "2023-06-07T20:05:00",
                  ad: "2023-06-08T04:55:00",
                  rbd: "T",
                  fn: "720",
                  eq: "77W",
                  ac: "TK",
                  ma: "TK",
                  seat: "9",
                  oa: "TK",
                  fa: [],
                  fdur: "06:20",
                  cl: "Economy",
                  terminal: { dep: "", arr: "2" },
                },
                {
                  ref: 3,
                  da: "BOM",
                  ar: "HYD",
                  dd: "2023-06-08T09:00:00",
                  ad: "2023-06-08T10:25:00",
                  rbd: "T",
                  fn: "4740",
                  trn: "2",
                  eq: "320",
                  ac: "TK",
                  ma: "TK",
                  seat: "4",
                  oa: "6E",
                  fa: [],
                  fdur: "01:25",
                  cl: "Economy",
                  terminal: { dep: "2", arr: "" },
                },
              ],
              flightNumbers: "781/720/4740",
              bgd: [{ qun: "30", wt: "kg" }],
            },
            {
              tid: "WZuXqp8QXl9iIxWB_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "32:55",
              re: "Refundable Before Departure",
              stop: 2,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "TK",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "IST",
                  dd: "2023-06-07T01:15:00",
                  ad: "2023-06-07T05:45:00",
                  rbd: "T",
                  fn: "781",
                  trn: "1",
                  eq: "321",
                  ac: "TK",
                  ma: "TK",
                  seat: "9",
                  oa: "TK",
                  fa: [],
                  fdur: "04:30",
                  cl: "Economy",
                  terminal: { dep: "1", arr: "" },
                },
                {
                  ref: 2,
                  da: "IST",
                  ar: "BOM",
                  dd: "2023-06-07T20:05:00",
                  ad: "2023-06-08T04:55:00",
                  rbd: "T",
                  fn: "720",
                  eq: "77W",
                  ac: "TK",
                  ma: "TK",
                  seat: "9",
                  oa: "TK",
                  fa: [],
                  fdur: "06:20",
                  cl: "Economy",
                  terminal: { dep: "", arr: "2" },
                },
                {
                  ref: 3,
                  da: "BOM",
                  ar: "HYD",
                  dd: "2023-06-08T11:10:00",
                  ad: "2023-06-08T12:40:00",
                  rbd: "T",
                  fn: "4741",
                  trn: "2",
                  eq: "320",
                  ac: "TK",
                  ma: "TK",
                  seat: "4",
                  oa: "6E",
                  fa: [],
                  fdur: "01:30",
                  cl: "Economy",
                  terminal: { dep: "2", arr: "" },
                },
              ],
              flightNumbers: "781/720/4741",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          pr: {
            bf: 46323.2,
            tf: 63571.2,
            tax: [
              { YR: 13440 },
              { OT: 3808 },
              { dealmkp: 0 },
              { attmkp: 4211.2 },
              { tamkp: 0 },
              { stamkp: 0 },
              { attdisc: 0 },
              { tadisc: 0 },
              { stadisc: 0 },
            ],
            scur: "QAR",
            ucur: "INR",
            exchange_rate: 22.4,
            sup_fare: "1880_2650",
            markupnames: [{ code: "MTA", name: "LaxmiAprilMarkup" }],
          },
          paxpr: [
            {
              tf: 63571.2,
              tax: [
                { YR: 13440 },
                { OT: 3808 },
                { attmkp: 4211.2 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              bf: 46323.2,
              fbc: "TLS2PXOW",
              fareType: "RP",
              pusup_fare: "1880_2650",
              psup_fare: "42112_59360",
              ptype: "ADT",
            },
          ],
          sup: "0",
          airlinesList: "TK",
          servicefee: [
            {
              type: "ATT",
              adt: 4632.32,
              chd: 0,
              inf: 0,
              servicefee_name: "LaxmiServieApril",
              apl_on: "Per Ticket",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 4632.32,
              sup_fee: 0,
            },
            {
              type: "TA",
              adt: 0,
              chd: 0,
              inf: 0,
              apl_on: "",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 0,
              sup_fee: 4632.32,
            },
          ],
        },
        {
          srcorigns: [
            {
              tid: "fNoBI1HEd0C8NAUC_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "25:55",
              re: "Refundable Before Departure",
              stop: 2,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "TK",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "IST",
                  dd: "2023-06-07T06:00:00",
                  ad: "2023-06-07T10:30:00",
                  rbd: "T",
                  fn: "783",
                  trn: "1",
                  eq: "321",
                  ac: "TK",
                  ma: "TK",
                  seat: "9",
                  oa: "TK",
                  fa: [],
                  fdur: "04:30",
                  cl: "Economy",
                  terminal: { dep: "1", arr: "" },
                },
                {
                  ref: 2,
                  da: "IST",
                  ar: "BOM",
                  dd: "2023-06-07T20:30:00",
                  ad: "2023-06-08T05:10:00",
                  rbd: "T",
                  fn: "4750",
                  eq: "321",
                  ac: "TK",
                  ma: "TK",
                  seat: "4",
                  oa: "6E",
                  fa: [],
                  fdur: "06:10",
                  cl: "Economy",
                  terminal: { dep: "", arr: "2" },
                },
                {
                  ref: 3,
                  da: "BOM",
                  ar: "HYD",
                  dd: "2023-06-08T09:00:00",
                  ad: "2023-06-08T10:25:00",
                  rbd: "T",
                  fn: "4740",
                  trn: "2",
                  eq: "320",
                  ac: "TK",
                  ma: "TK",
                  seat: "4",
                  oa: "6E",
                  fa: [],
                  fdur: "01:25",
                  cl: "Economy",
                  terminal: { dep: "2", arr: "" },
                },
              ],
              flightNumbers: "783/4750/4740",
              bgd: [{ qun: "30", wt: "kg" }],
            },
            {
              tid: "9rbpV5Ri90izi5ei_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "28:10",
              re: "Refundable Before Departure",
              stop: 2,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "TK",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "IST",
                  dd: "2023-06-07T06:00:00",
                  ad: "2023-06-07T10:30:00",
                  rbd: "T",
                  fn: "783",
                  trn: "1",
                  eq: "321",
                  ac: "TK",
                  ma: "TK",
                  seat: "9",
                  oa: "TK",
                  fa: [],
                  fdur: "04:30",
                  cl: "Economy",
                  terminal: { dep: "1", arr: "" },
                },
                {
                  ref: 2,
                  da: "IST",
                  ar: "BOM",
                  dd: "2023-06-07T20:30:00",
                  ad: "2023-06-08T05:10:00",
                  rbd: "T",
                  fn: "4750",
                  eq: "321",
                  ac: "TK",
                  ma: "TK",
                  seat: "4",
                  oa: "6E",
                  fa: [],
                  fdur: "06:10",
                  cl: "Economy",
                  terminal: { dep: "", arr: "2" },
                },
                {
                  ref: 3,
                  da: "BOM",
                  ar: "HYD",
                  dd: "2023-06-08T11:10:00",
                  ad: "2023-06-08T12:40:00",
                  rbd: "T",
                  fn: "4741",
                  trn: "2",
                  eq: "320",
                  ac: "TK",
                  ma: "TK",
                  seat: "4",
                  oa: "6E",
                  fa: [],
                  fdur: "01:30",
                  cl: "Economy",
                  terminal: { dep: "2", arr: "" },
                },
              ],
              flightNumbers: "783/4750/4741",
              bgd: [{ qun: "30", wt: "kg" }],
            },
            {
              tid: "U9zzFdhDgpKmxLZO_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "30:40",
              re: "Refundable Before Departure",
              stop: 2,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "TK",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "IST",
                  dd: "2023-06-07T01:15:00",
                  ad: "2023-06-07T05:45:00",
                  rbd: "T",
                  fn: "781",
                  trn: "1",
                  eq: "321",
                  ac: "TK",
                  ma: "TK",
                  seat: "9",
                  oa: "TK",
                  fa: [],
                  fdur: "04:30",
                  cl: "Economy",
                  terminal: { dep: "1", arr: "" },
                },
                {
                  ref: 2,
                  da: "IST",
                  ar: "BOM",
                  dd: "2023-06-07T20:30:00",
                  ad: "2023-06-08T05:10:00",
                  rbd: "T",
                  fn: "4750",
                  eq: "321",
                  ac: "TK",
                  ma: "TK",
                  seat: "4",
                  oa: "6E",
                  fa: [],
                  fdur: "06:10",
                  cl: "Economy",
                  terminal: { dep: "", arr: "2" },
                },
                {
                  ref: 3,
                  da: "BOM",
                  ar: "HYD",
                  dd: "2023-06-08T09:00:00",
                  ad: "2023-06-08T10:25:00",
                  rbd: "T",
                  fn: "4740",
                  trn: "2",
                  eq: "320",
                  ac: "TK",
                  ma: "TK",
                  seat: "4",
                  oa: "6E",
                  fa: [],
                  fdur: "01:25",
                  cl: "Economy",
                  terminal: { dep: "2", arr: "" },
                },
              ],
              flightNumbers: "781/4750/4740",
              bgd: [{ qun: "30", wt: "kg" }],
            },
            {
              tid: "ldegdMd9TwGKVe7J_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "32:55",
              re: "Refundable Before Departure",
              stop: 2,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "TK",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "IST",
                  dd: "2023-06-07T01:15:00",
                  ad: "2023-06-07T05:45:00",
                  rbd: "T",
                  fn: "781",
                  trn: "1",
                  eq: "321",
                  ac: "TK",
                  ma: "TK",
                  seat: "9",
                  oa: "TK",
                  fa: [],
                  fdur: "04:30",
                  cl: "Economy",
                  terminal: { dep: "1", arr: "" },
                },
                {
                  ref: 2,
                  da: "IST",
                  ar: "BOM",
                  dd: "2023-06-07T20:30:00",
                  ad: "2023-06-08T05:10:00",
                  rbd: "T",
                  fn: "4750",
                  eq: "321",
                  ac: "TK",
                  ma: "TK",
                  seat: "4",
                  oa: "6E",
                  fa: [],
                  fdur: "06:10",
                  cl: "Economy",
                  terminal: { dep: "", arr: "2" },
                },
                {
                  ref: 3,
                  da: "BOM",
                  ar: "HYD",
                  dd: "2023-06-08T11:10:00",
                  ad: "2023-06-08T12:40:00",
                  rbd: "T",
                  fn: "4741",
                  trn: "2",
                  eq: "320",
                  ac: "TK",
                  ma: "TK",
                  seat: "4",
                  oa: "6E",
                  fa: [],
                  fdur: "01:30",
                  cl: "Economy",
                  terminal: { dep: "2", arr: "" },
                },
              ],
              flightNumbers: "781/4750/4741",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          pr: {
            bf: 46323.2,
            tf: 63571.2,
            tax: [
              { YR: 13440 },
              { OT: 3808 },
              { dealmkp: 0 },
              { attmkp: 4211.2 },
              { tamkp: 0 },
              { stamkp: 0 },
              { attdisc: 0 },
              { tadisc: 0 },
              { stadisc: 0 },
            ],
            scur: "QAR",
            ucur: "INR",
            exchange_rate: 22.4,
            sup_fare: "1880_2650",
            markupnames: [{ code: "MTA", name: "LaxmiAprilMarkup" }],
          },
          paxpr: [
            {
              tf: 63571.2,
              tax: [
                { YR: 13440 },
                { OT: 3808 },
                { attmkp: 4211.2 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              bf: 46323.2,
              fbc: "TLS2PXOW",
              fareType: "RP",
              pusup_fare: "1880_2650",
              psup_fare: "42112_59360",
              ptype: "ADT",
            },
          ],
          sup: "0",
          airlinesList: "TK",
          servicefee: [
            {
              type: "ATT",
              adt: 4632.32,
              chd: 0,
              inf: 0,
              servicefee_name: "LaxmiServieApril",
              apl_on: "Per Ticket",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 4632.32,
              sup_fee: 0,
            },
            {
              type: "TA",
              adt: 0,
              chd: 0,
              inf: 0,
              apl_on: "",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 0,
              sup_fee: 4632.32,
            },
          ],
        },
        {
          srcorigns: [
            {
              tid: "AWpnz7u38x0zMlXR_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "18:50",
              re: "Refundable Before Departure",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "MH",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "KUL",
                  dd: "2023-06-07T01:50:00",
                  ad: "2023-06-07T14:40:00",
                  rbd: "N",
                  fn: "161",
                  eq: "333",
                  ac: "MH",
                  ma: "MH",
                  seat: "9",
                  oa: "MH",
                  fa: [],
                  fdur: "07:50",
                  cl: "Economy",
                  terminal: { dep: "", arr: "1" },
                },
                {
                  ref: 2,
                  da: "KUL",
                  ar: "HYD",
                  dd: "2023-06-07T21:30:00",
                  ad: "2023-06-07T23:10:00",
                  rbd: "M",
                  fn: "198",
                  trn: "1",
                  eq: "738",
                  ac: "MH",
                  ma: "MH",
                  seat: "9",
                  oa: "MH",
                  fa: [],
                  fdur: "04:10",
                  cl: "Economy",
                  terminal: { dep: "1", arr: "" },
                },
              ],
              flightNumbers: "161/198",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          pr: {
            bf: 100038.4,
            tf: 103174.4,
            tax: [
              { OT: 3136 },
              { dealmkp: 0 },
              { attmkp: 9094.4 },
              { tamkp: 0 },
              { stamkp: 0 },
              { attdisc: 0 },
              { tadisc: 0 },
              { stadisc: 0 },
            ],
            scur: "QAR",
            ucur: "INR",
            exchange_rate: 22.4,
            sup_fare: "4060_4200",
            markupnames: [{ code: "MTA", name: "LaxmiAprilMarkup" }],
          },
          paxpr: [
            {
              tf: 103174.4,
              tax: [
                { OT: 3136 },
                { attmkp: 9094.4 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              bf: 100038.4,
              fbc: "NBXOWQA",
              fareType: "RP",
              pusup_fare: "4060_4200",
              psup_fare: "90944_94080",
              ptype: "ADT",
            },
          ],
          sup: "0",
          airlinesList: "MH",
          servicefee: [
            {
              type: "ATT",
              adt: 10003.84,
              chd: 0,
              inf: 0,
              servicefee_name: "LaxmiServieApril",
              apl_on: "Per Ticket",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 10003.84,
              sup_fee: 0,
            },
            {
              type: "TA",
              adt: 0,
              chd: 0,
              inf: 0,
              apl_on: "",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 0,
              sup_fee: 10003.84,
            },
          ],
        },
        {
          srcorigns: [
            {
              tid: "AClr4kuTwLEHD37T_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "25:05",
              re: "Refundable Before Departure",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "MH",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "KUL",
                  dd: "2023-06-07T19:35:00",
                  ad: "2023-06-08T08:25:00",
                  rbd: "N",
                  fn: "9051",
                  eq: "77W",
                  ac: "MH",
                  ma: "MH",
                  seat: "4",
                  oa: "QR",
                  fa: [],
                  fdur: "07:50",
                  cl: "Economy",
                  terminal: { dep: "", arr: "1" },
                },
                {
                  ref: 2,
                  da: "KUL",
                  ar: "HYD",
                  dd: "2023-06-08T21:30:00",
                  ad: "2023-06-08T23:10:00",
                  rbd: "M",
                  fn: "198",
                  trn: "1",
                  eq: "738",
                  ac: "MH",
                  ma: "MH",
                  seat: "9",
                  oa: "MH",
                  fa: [],
                  fdur: "04:10",
                  cl: "Economy",
                  terminal: { dep: "1", arr: "" },
                },
              ],
              flightNumbers: "9051/198",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          pr: {
            bf: 100038.4,
            tf: 117510.4,
            tax: [
              { YQ: 12320 },
              { OT: 5152 },
              { dealmkp: 0 },
              { attmkp: 9094.4 },
              { tamkp: 0 },
              { stamkp: 0 },
              { attdisc: 0 },
              { tadisc: 0 },
              { stadisc: 0 },
            ],
            scur: "QAR",
            ucur: "INR",
            exchange_rate: 22.4,
            sup_fare: "4060_4840",
            markupnames: [{ code: "MTA", name: "LaxmiAprilMarkup" }],
          },
          paxpr: [
            {
              tf: 117510.4,
              tax: [
                { YQ: 12320 },
                { OT: 5152 },
                { attmkp: 9094.4 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              bf: 100038.4,
              fbc: "NBXOWQA",
              fareType: "RP",
              pusup_fare: "4060_4840",
              psup_fare: "90944_108416",
              ptype: "ADT",
            },
          ],
          sup: "0",
          airlinesList: "MH",
          servicefee: [
            {
              type: "ATT",
              adt: 10003.84,
              chd: 0,
              inf: 0,
              servicefee_name: "LaxmiServieApril",
              apl_on: "Per Ticket",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 10003.84,
              sup_fee: 0,
            },
            {
              type: "TA",
              adt: 0,
              chd: 0,
              inf: 0,
              apl_on: "",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 0,
              sup_fee: 10003.84,
            },
          ],
        },
        {
          srcorigns: [
            {
              tid: "E48ieaAU8cHBfuhT_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "08:55",
              re: "Refundable Before Departure",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "EK",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DXB",
                  dd: "2023-06-07T21:00:00",
                  ad: "2023-06-07T23:15:00",
                  rbd: "Y",
                  fn: "6",
                  eq: "7M8",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "9",
                  oa: "FZ",
                  fa: [],
                  fdur: "01:15",
                  cl: "Economy",
                  terminal: { dep: "", arr: "2" },
                },
                {
                  ref: 2,
                  da: "DXB",
                  ar: "HYD",
                  dd: "2023-06-08T03:10:00",
                  ad: "2023-06-08T08:25:00",
                  rbd: "Y",
                  fn: "526",
                  trn: "3",
                  eq: "77W",
                  ac: "EK",
                  ma: "EK",
                  seat: "9",
                  oa: "EK",
                  fa: [],
                  fdur: "03:45",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "6/526",
              bgd: [{ qun: "35", wt: "kg" }],
            },
            {
              tid: "ch4Id1HSOtH8n66n_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "07:35",
              re: "Refundable Before Departure",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "EK",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DXB",
                  dd: "2023-06-07T17:00:00",
                  ad: "2023-06-07T19:15:00",
                  rbd: "Y",
                  fn: "18",
                  eq: "7M8",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "9",
                  oa: "FZ",
                  fa: [],
                  fdur: "01:15",
                  cl: "Economy",
                  terminal: { dep: "", arr: "2" },
                },
                {
                  ref: 2,
                  da: "DXB",
                  ar: "HYD",
                  dd: "2023-06-07T22:00:00",
                  ad: "2023-06-08T03:05:00",
                  rbd: "Y",
                  fn: "524",
                  trn: "3",
                  eq: "77W",
                  ac: "EK",
                  ma: "EK",
                  seat: "9",
                  oa: "EK",
                  fa: [],
                  fdur: "03:35",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "18/524",
              bgd: [{ qun: "35", wt: "kg" }],
            },
            {
              tid: "AwfkKkSJO7oET9Ku_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "12:55",
              re: "Refundable Before Departure",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "EK",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DXB",
                  dd: "2023-06-07T17:00:00",
                  ad: "2023-06-07T19:15:00",
                  rbd: "Y",
                  fn: "18",
                  eq: "7M8",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "9",
                  oa: "FZ",
                  fa: [],
                  fdur: "01:15",
                  cl: "Economy",
                  terminal: { dep: "", arr: "2" },
                },
                {
                  ref: 2,
                  da: "DXB",
                  ar: "HYD",
                  dd: "2023-06-08T03:10:00",
                  ad: "2023-06-08T08:25:00",
                  rbd: "Y",
                  fn: "526",
                  trn: "3",
                  eq: "77W",
                  ac: "EK",
                  ma: "EK",
                  seat: "9",
                  oa: "EK",
                  fa: [],
                  fdur: "03:45",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "18/526",
              bgd: [{ qun: "35", wt: "kg" }],
            },
            {
              tid: "cPECUnzPEmppXWtG_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "07:45",
              re: "Refundable Before Departure",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "EK",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DXB",
                  dd: "2023-06-07T10:00:00",
                  ad: "2023-06-07T12:15:00",
                  rbd: "Y",
                  fn: "2",
                  eq: "7M8",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "9",
                  oa: "FZ",
                  fa: [],
                  fdur: "01:15",
                  cl: "Economy",
                  terminal: { dep: "", arr: "2" },
                },
                {
                  ref: 2,
                  da: "DXB",
                  ar: "HYD",
                  dd: "2023-06-07T15:05:00",
                  ad: "2023-06-07T20:15:00",
                  rbd: "Y",
                  fn: "528",
                  trn: "3",
                  eq: "77W",
                  ac: "EK",
                  ma: "EK",
                  seat: "9",
                  oa: "EK",
                  fa: [],
                  fdur: "03:40",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "2/528",
              bgd: [{ qun: "35", wt: "kg" }],
            },
            {
              tid: "IvQlvOyKGIosqTIf_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "14:35",
              re: "Refundable Before Departure",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "EK",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DXB",
                  dd: "2023-06-07T10:00:00",
                  ad: "2023-06-07T12:15:00",
                  rbd: "Y",
                  fn: "2",
                  eq: "7M8",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "9",
                  oa: "FZ",
                  fa: [],
                  fdur: "01:15",
                  cl: "Economy",
                  terminal: { dep: "", arr: "2" },
                },
                {
                  ref: 2,
                  da: "DXB",
                  ar: "HYD",
                  dd: "2023-06-07T22:00:00",
                  ad: "2023-06-08T03:05:00",
                  rbd: "Y",
                  fn: "524",
                  trn: "3",
                  eq: "77W",
                  ac: "EK",
                  ma: "EK",
                  seat: "9",
                  oa: "EK",
                  fa: [],
                  fdur: "03:35",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "2/524",
              bgd: [{ qun: "35", wt: "kg" }],
            },
            {
              tid: "ISIzP7QzsxRNcTdx_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "19:55",
              re: "Refundable Before Departure",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "EK",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DXB",
                  dd: "2023-06-07T10:00:00",
                  ad: "2023-06-07T12:15:00",
                  rbd: "Y",
                  fn: "2",
                  eq: "7M8",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "9",
                  oa: "FZ",
                  fa: [],
                  fdur: "01:15",
                  cl: "Economy",
                  terminal: { dep: "", arr: "2" },
                },
                {
                  ref: 2,
                  da: "DXB",
                  ar: "HYD",
                  dd: "2023-06-08T03:10:00",
                  ad: "2023-06-08T08:25:00",
                  rbd: "Y",
                  fn: "526",
                  trn: "3",
                  eq: "77W",
                  ac: "EK",
                  ma: "EK",
                  seat: "9",
                  oa: "EK",
                  fa: [],
                  fdur: "03:45",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "2/526",
              bgd: [{ qun: "35", wt: "kg" }],
            },
          ],
          pr: {
            bf: 122953.6,
            tf: 135721.6,
            tax: [
              { YQ: 8512 },
              { OT: 4256 },
              { dealmkp: 0 },
              { attmkp: 11177.6 },
              { tamkp: 0 },
              { stamkp: 0 },
              { attdisc: 0 },
              { tadisc: 0 },
              { stadisc: 0 },
            ],
            scur: "QAR",
            ucur: "INR",
            exchange_rate: 22.4,
            sup_fare: "4990_5560",
            markupnames: [{ code: "MTA", name: "LaxmiAprilMarkup" }],
          },
          paxpr: [
            {
              tf: 135721.6,
              tax: [
                { YQ: 8512 },
                { OT: 4256 },
                { attmkp: 11177.6 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              bf: 122953.6,
              fbc: "YLOWFQA1",
              fareType: "RP",
              pusup_fare: "4990_5560",
              psup_fare: "111776_124544",
              ptype: "ADT",
            },
          ],
          sup: "0",
          airlinesList: "FZ_EK",
          servicefee: [
            {
              type: "ATT",
              adt: 12295.36,
              chd: 0,
              inf: 0,
              servicefee_name: "LaxmiServieApril",
              apl_on: "Per Ticket",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 12295.36,
              sup_fee: 0,
            },
            {
              type: "TA",
              adt: 0,
              chd: 0,
              inf: 0,
              apl_on: "",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 0,
              sup_fee: 12295.36,
            },
          ],
        },
      ],
      mappings: [
        {
          airportCode: "HYD",
          airportName: "Rajiv Gandhi International Airport",
          cityName: "Hyderabad",
          countryName: "India",
          countryCode: "IN",
        },
        {
          airportCode: "IST",
          airportName: "Istanbul Ataturk Airport",
          cityName: "Istanbul",
          countryName: "Turkey",
          countryCode: "TR",
        },
        {
          airportCode: "KUL",
          airportName: "Kuala Lumpur International Airport",
          cityName: "Kuala Lumpur",
          countryName: "Malaysia",
          countryCode: "MY",
        },
        {
          airportCode: "KWI",
          airportName: "Kuwait International airport",
          cityName: "Kuwait City",
          countryName: "Kuwait",
          countryCode: "KW",
        },
        {
          airportCode: "BLR",
          airportName: "Kempegowda International Airport",
          cityName: "Bangalore",
          countryName: "India",
          countryCode: "IN",
        },
        {
          airportCode: "BOM",
          airportName: "Chhatrapati Shivaji International Airport",
          cityName: "Mumbai (Bombay)",
          countryName: "India",
          countryCode: "IN",
        },
        {
          airportCode: "RUH",
          airportName: "King Khalid International Airport",
          cityName: "Riyadh",
          countryName: "Saudi Arabia",
          countryCode: "SA",
        },
        {
          airportCode: "MCT",
          airportName: "Muscat International Airport",
          cityName: "Muscat",
          countryName: "Oman",
          countryCode: "OM",
        },
        {
          airportCode: "CMB",
          airportName: "Bandaranayake International Airport",
          cityName: "Colombo",
          countryName: "Sri Lanka",
          countryCode: "LK",
        },
        {
          airportCode: "DEL",
          airportName: "Indira Gandhi International Airport",
          cityName: "New Delhi",
          countryName: "India",
          countryCode: "IN",
        },
        {
          airportCode: "DOH",
          airportName: "Hamad International Airport",
          cityName: "Doha",
          countryName: "Qatar",
          countryCode: "QA",
        },
        {
          airportCode: "DXB",
          airportName: "Dubai International Airport",
          cityName: "Dubai",
          countryName: "United Arab Emirates",
          countryCode: "AE",
        },
        {
          airportCode: "HYD",
          airportName: "Rajiv Gandhi International Airport",
          cityName: "Hyderabad",
          countryName: "India",
          countryCode: "IN",
        },
        {
          airportCode: "HYD",
          airportName: "Rajiv Gandhi International Airport",
          cityName: "Hyderabad",
          countryName: "India",
          countryCode: "IN",
        },
        {
          airportCode: "HYD",
          airportName: "Rajiv Gandhi International Airport",
          cityName: "Hyderabad",
          countryName: "India",
          countryCode: "IN",
        },
        {
          airportCode: "MAA",
          airportName: "Chennai International Airport",
          cityName: "Chennai",
          countryName: "India",
          countryCode: "IN",
        },
        {
          airportCode: "DEL",
          airportName: "Indira Gandhi International Airport",
          cityName: "New Delhi",
          countryName: "India",
          countryCode: "IN",
        },
        {
          airportCode: "DOH",
          airportName: "Hamad International Airport",
          cityName: "Doha",
          countryName: "Qatar",
          countryCode: "QA",
        },
        {
          airportCode: "HYD",
          airportName: "Hyderabad All",
          countryName: "India",
          countryCode: "IN",
        },
      ],
      echo: "nigyjtnh2jo",
      cha: "B2B",
      cid: "327",
      tt: "1",
      sty: "normal",
      sec: [
        {
          src: "DOH",
          des: "HYD",
          dd: "2023-06-07",
          ad: "Invalid date",
          leg: 0,
        },
      ],
      filter: {
        nerby: [],
        nerbyarr: [],
        suplrs: [
          { sup_name: "Full Service", sup_id: 0 },
          { sup_name: "LCC", sup_id: 4 },
        ],
        fstst: 10876,
        chpst: 10876,
        minprc: 10876,
        maxprc: 152242.10000000003,
        best: 27565.440000000002,
        stps: [
          { count: 0, prc: 10876, airline: "6E" },
          { count: 1, prc: 12802, airline: "6E" },
          { count: 2, prc: 28293.620000000003, airline: "AI" },
        ],
        airl: [
          { count: 0, prc: 10876, airline: "6E" },
          { count: 1, prc: 20370.2, airline: "WY" },
          { count: 1, prc: 21928.34, airline: "UL" },
          { count: 2, prc: 28293.620000000003, airline: "AI" },
          { count: 1, prc: 34674.66, airline: "KU" },
          { count: 0, prc: 39479.82, airline: "QR" },
          { count: 1, prc: 40617.56, airline: "XY" },
          { count: 2, prc: 69795.35999999999, airline: "TK" },
          { count: 1, prc: 116615.91999999998, airline: "MH" },
          { count: 1, prc: 152242.10000000003, airline: "FZ" },
        ],
      },
      cur: "INR",
      adt: 1,
      chd: 0,
      inf: 0,
      region_type: "International",
      gst: { type: "same", sgst: 9, cgst: 9, igst: 0, gst_invoice: [] },
    },
  ],
};
export const FLIGHT_RESPONSE = {
  status: "success",
  data: [
    {
      origns: [
        {
          srcorigns: [
            {
              tid: "luX24FgouUkwkfy6_1",
              ref: 1,
              ofcid: "",
              sdur: "6:30",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "HYD",
                  dd: "2023-05-06T02:00:00",
                  ad: "2023-05-06T08:30:00",
                  rbd: "",
                  fn: "1726",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: {},
                  rph: "",
                  jsell:
                    "6E~1726~ ~~DOH~05/06/2023 02:00~HYD~05/06/2023 08:30~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey: "0~R~ ~6E~R0INT~5000~~0~4~~X",
                  pr: {
                    bf: 330,
                    tf: 479,
                    tax: [
                      { OT: 149 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "330_479",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 479,
                  },
                  paxpr: [
                    {
                      bf: 330,
                      tf: 479,
                      tax: [
                        { OT: 149 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "R0INT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "330_479",
                      psup_fare: "330_479",
                    },
                  ],
                  routeId: "z5ihtzwn1r",
                  productClass: "R",
                  ruleNumber: "5000",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey: "0~R~ ~6E~RUINT~5003~~0~10~~X",
                  pr: {
                    bf: 361,
                    tf: 511,
                    tax: [
                      { OT: 150 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "361_511",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 511,
                  },
                  paxpr: [
                    {
                      bf: 361,
                      tf: 511,
                      tax: [
                        { OT: 150 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "RUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "361_511",
                      psup_fare: "361_511",
                    },
                  ],
                  routeId: "s5td3fk4bp",
                  productClass: "J",
                  ruleNumber: "5003",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "gV9VlJcF4lo=|c8vLGEjg5j7NNr8U4/n4JN2DKZGLTH99mWKIwdR5uRWciziCl+wbZTrpWnMmGN+Yy9uJ4ZUTuD0HpLhkPra8oFmlLXu64hUIzZhRraKsuKvdW8dDCeFMBF8RTGVhnAM8gqCD8NTV8Ig=",
              stop: 0,
            },
            {
              tid: "r132EHCxGvmws9I7_1",
              ref: 1,
              ofcid: "",
              sdur: "6:20",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "HYD",
                  dd: "2023-05-06T20:10:00",
                  ad: "2023-05-07T02:30:00",
                  rbd: "",
                  fn: "1714",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: {},
                  rph: "",
                  jsell:
                    "6E~1714~ ~~DOH~05/06/2023 20:10~HYD~05/07/2023 02:30~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey: "0~R~ ~6E~R0INT~5000~~0~4~~X",
                  pr: {
                    bf: 330,
                    tf: 479,
                    tax: [
                      { OT: 149 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "330_479",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 479,
                  },
                  paxpr: [
                    {
                      bf: 330,
                      tf: 479,
                      tax: [
                        { OT: 149 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "R0INT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "330_479",
                      psup_fare: "330_479",
                    },
                  ],
                  routeId: "t4qxdt4cr8k",
                  productClass: "R",
                  ruleNumber: "5000",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey: "0~R~ ~6E~RUINT~5003~~0~10~~X",
                  pr: {
                    bf: 361,
                    tf: 511,
                    tax: [
                      { OT: 150 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "361_511",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 511,
                  },
                  paxpr: [
                    {
                      bf: 361,
                      tf: 511,
                      tax: [
                        { OT: 150 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "RUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "361_511",
                      psup_fare: "361_511",
                    },
                  ],
                  routeId: "wgou307ta7",
                  productClass: "J",
                  ruleNumber: "5003",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "gV9VlJcF4lo=|c8vLGEjg5j7NNr8U4/n4JN2DKZGLTH99mWKIwdR5uRWciziCl+wbZTrpWnMmGN+Yy9uJ4ZUTuD0HpLhkPra8oFmlLXu64hUIzZhRraKsuKvdW8dDCeFMBF8RTGVhnAM8gqCD8NTV8Ig=",
              stop: 0,
            },
          ],
          destorigins: [
            {
              tid: "Qnd1Q1GC5mlNtROl_2",
              ref: 2,
              ofcid: "",
              sdur: "2:30",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DOH",
                  dd: "2023-05-08T03:35:00",
                  ad: "2023-05-08T06:05:00",
                  rbd: "",
                  fn: "1713",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: {},
                  rph: "",
                  jsell:
                    "6E~1713~ ~~HYD~05/08/2023 03:35~DOH~05/08/2023 06:05~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
              ],
              fareop: [
                {
                  fareKey: "0~R~ ~6E~R0INT~5000~~0~3~~X",
                  pr: {
                    bf: 278,
                    tf: 432,
                    tax: [
                      { OT: 154 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "278_432",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 432,
                  },
                  paxpr: [
                    {
                      bf: 278,
                      tf: 432,
                      tax: [
                        { OT: 154 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "R0INT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "278_432",
                      psup_fare: "278_432",
                    },
                  ],
                  routeId: "7q29vksmvv",
                  productClass: "R",
                  ruleNumber: "5000",
                  servicefee: [],
                  selected: true,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "gV9VlJcF4lo=|c8vLGEjg5j7NNr8U4/n4JN2DKZGLTH99mWKIwdR5uRWciziCl+wbZTrpWnMmGN+Yy9uJ4ZUTuD0HpLhkPra8oFmlLXu64hUIzZhRraKsuKvdW8dDCeFMBF8RTGVhnAM8gqCD8NTV8Ig=",
              stop: 0,
            },
          ],
          paxpr: [
            {
              bf: 608,
              tf: 911,
              tax: [
                { OT: 303 },
                { attmkp: 0 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              fbc: "R0INT",
              ptype: "ADT",
              scur: "QAR",
              sup_fare: "330_479",
              psup_fare: "608_911",
            },
          ],
          pr: {
            bf: 608,
            tf: 911,
            tax: [
              { OT: 303 },
              { attmkp: 0 },
              { tamkp: 0 },
              { stamkp: 0 },
              { attdisc: 0 },
              { tadisc: 0 },
              { stadisc: 0 },
            ],
            ucur: "QAR",
            exchange_rate: 1,
            scur: "QAR",
            sup_fare: "608_911",
            sup_cur_fare: "",
            cabb: "",
            chekb: "",
            seats: "",
            meals: "",
            chanb: "",
            ftyp: "Regular - R",
            markupnames: [],
            ttf: 911,
          },
          sup: "4",
          airlineList: "6E",
          servicefee: [],
        },
        {
          srcorigns: [
            {
              tid: "sRmryRdpfqWTr7hF_1",
              ref: 1,
              ofcid: "",
              sdur: "6:30",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "HYD",
                  dd: "2023-05-06T02:00:00",
                  ad: "2023-05-06T08:30:00",
                  rbd: "",
                  fn: "1726",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: {},
                  rph: "",
                  jsell:
                    "6E~1726~ ~~DOH~05/06/2023 02:00~HYD~05/06/2023 08:30~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey: "0~R~ ~6E~R0INT~5000~~0~4~~X",
                  pr: {
                    bf: 330,
                    tf: 479,
                    tax: [
                      { OT: 149 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "330_479",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 479,
                  },
                  paxpr: [
                    {
                      bf: 330,
                      tf: 479,
                      tax: [
                        { OT: 149 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "R0INT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "330_479",
                      psup_fare: "330_479",
                    },
                  ],
                  routeId: "z5ihtzwn1r",
                  productClass: "R",
                  ruleNumber: "5000",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey: "0~R~ ~6E~RUINT~5003~~0~10~~X",
                  pr: {
                    bf: 361,
                    tf: 511,
                    tax: [
                      { OT: 150 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "361_511",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 511,
                  },
                  paxpr: [
                    {
                      bf: 361,
                      tf: 511,
                      tax: [
                        { OT: 150 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "RUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "361_511",
                      psup_fare: "361_511",
                    },
                  ],
                  routeId: "s5td3fk4bp",
                  productClass: "J",
                  ruleNumber: "5003",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "gV9VlJcF4lo=|c8vLGEjg5j7NNr8U4/n4JN2DKZGLTH99mWKIwdR5uRWciziCl+wbZTrpWnMmGN+Yy9uJ4ZUTuD0HpLhkPra8oFmlLXu64hUIzZhRraKsuKvdW8dDCeFMBF8RTGVhnAM8gqCD8NTV8Ig=",
              stop: 0,
            },
            {
              tid: "hEN6Se8L22ucECqK_1",
              ref: 1,
              ofcid: "",
              sdur: "6:20",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "HYD",
                  dd: "2023-05-06T20:10:00",
                  ad: "2023-05-07T02:30:00",
                  rbd: "",
                  fn: "1714",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: {},
                  rph: "",
                  jsell:
                    "6E~1714~ ~~DOH~05/06/2023 20:10~HYD~05/07/2023 02:30~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey: "0~R~ ~6E~R0INT~5000~~0~4~~X",
                  pr: {
                    bf: 330,
                    tf: 479,
                    tax: [
                      { OT: 149 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "330_479",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 479,
                  },
                  paxpr: [
                    {
                      bf: 330,
                      tf: 479,
                      tax: [
                        { OT: 149 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "R0INT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "330_479",
                      psup_fare: "330_479",
                    },
                  ],
                  routeId: "t4qxdt4cr8k",
                  productClass: "R",
                  ruleNumber: "5000",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey: "0~R~ ~6E~RUINT~5003~~0~10~~X",
                  pr: {
                    bf: 361,
                    tf: 511,
                    tax: [
                      { OT: 150 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "361_511",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 511,
                  },
                  paxpr: [
                    {
                      bf: 361,
                      tf: 511,
                      tax: [
                        { OT: 150 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "RUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "361_511",
                      psup_fare: "361_511",
                    },
                  ],
                  routeId: "wgou307ta7",
                  productClass: "J",
                  ruleNumber: "5003",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "gV9VlJcF4lo=|c8vLGEjg5j7NNr8U4/n4JN2DKZGLTH99mWKIwdR5uRWciziCl+wbZTrpWnMmGN+Yy9uJ4ZUTuD0HpLhkPra8oFmlLXu64hUIzZhRraKsuKvdW8dDCeFMBF8RTGVhnAM8gqCD8NTV8Ig=",
              stop: 0,
            },
          ],
          destorigins: [
            {
              tid: "N6nDRR0EVUgUc0a8_2",
              ref: 2,
              ofcid: "",
              sdur: "10:20",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "MAA",
                  dd: "2023-05-08T13:35:00",
                  ad: "2023-05-08T15:10:00",
                  rbd: "",
                  fn: " 514",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "1" },
                  rph: "",
                  jsell:
                    "6E~ 514~ ~~HYD~05/08/2023 13:35~MAA~05/08/2023 15:10~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
                {
                  ref: 2,
                  da: "MAA",
                  ar: "DOH",
                  dd: "2023-05-08T21:55:00",
                  ad: "2023-05-08T23:55:00",
                  rbd: "",
                  fn: "1775",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "4" },
                  rph: "",
                  jsell:
                    "6E~1775~ ~~MAA~05/08/2023 21:55~DOH~05/08/2023 23:55~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~R~ ~6E~RCINT~6200~~0~66~~X^0~R~ ~6E~RCINT~6200~~0~66~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 354,
                      fbc: "RCINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "354_516",
                      tax: [
                        { OT: 162 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 516,
                      psup_fare: "354_516",
                    },
                  ],
                  pr: {
                    bf: 354,
                    tf: 516,
                    tax: [
                      { OT: 162 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "354_516",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 516,
                  },
                  routeId: "1zjkr1f34q",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~R~ ~6E~RCUINT~6202~~0~5~~X^0~R~ ~6E~RCUINT~6202~~0~5~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 384,
                      fbc: "RCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "384_548",
                      tax: [
                        { OT: 164 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 548,
                      psup_fare: "384_548",
                    },
                  ],
                  pr: {
                    bf: 384,
                    tf: 548,
                    tax: [
                      { OT: 164 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "384_548",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 548,
                  },
                  routeId: "eavyhyr5wf",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "gV9VlJcF4lo=|c8vLGEjg5j7NNr8U4/n4JN2DKZGLTH99mWKIwdR5uRWciziCl+wbZTrpWnMmGN+Yy9uJ4ZUTuD0HpLhkPra8oFmlLXu64hUIzZhRraKsuKvdW8dDCeFMBF8RTGVhnAM8gqCD8NTV8Ig=",
              stop: 1,
            },
            {
              tid: "TwsFE6nP0ENCBGP4_2",
              ref: 2,
              ofcid: "",
              sdur: "7:35",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "MAA",
                  dd: "2023-05-08T16:20:00",
                  ad: "2023-05-08T17:35:00",
                  rbd: "",
                  fn: " 668",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "1" },
                  rph: "",
                  jsell:
                    "6E~ 668~ ~~HYD~05/08/2023 16:20~MAA~05/08/2023 17:35~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
                {
                  ref: 2,
                  da: "MAA",
                  ar: "DOH",
                  dd: "2023-05-08T21:55:00",
                  ad: "2023-05-08T23:55:00",
                  rbd: "",
                  fn: "1775",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "4" },
                  rph: "",
                  jsell:
                    "6E~1775~ ~~MAA~05/08/2023 21:55~DOH~05/08/2023 23:55~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~R~ ~6E~RCINT~6200~~0~66~~X^0~R~ ~6E~RCINT~6200~~0~66~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 354,
                      fbc: "RCINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "354_516",
                      tax: [
                        { OT: 162 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 516,
                      psup_fare: "354_516",
                    },
                  ],
                  pr: {
                    bf: 354,
                    tf: 516,
                    tax: [
                      { OT: 162 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "354_516",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 516,
                  },
                  routeId: "4xqmpt7wk",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~R~ ~6E~RCUINT~6202~~0~5~~X^0~R~ ~6E~RCUINT~6202~~0~5~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 384,
                      fbc: "RCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "384_548",
                      tax: [
                        { OT: 164 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 548,
                      psup_fare: "384_548",
                    },
                  ],
                  pr: {
                    bf: 384,
                    tf: 548,
                    tax: [
                      { OT: 164 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "384_548",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 548,
                  },
                  routeId: "q0tt4x0ono",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "gV9VlJcF4lo=|c8vLGEjg5j7NNr8U4/n4JN2DKZGLTH99mWKIwdR5uRWciziCl+wbZTrpWnMmGN+Yy9uJ4ZUTuD0HpLhkPra8oFmlLXu64hUIzZhRraKsuKvdW8dDCeFMBF8RTGVhnAM8gqCD8NTV8Ig=",
              stop: 1,
            },
            {
              tid: "Ix2Ict989d3cbxSQ_2",
              ref: 2,
              ofcid: "",
              sdur: "6:0",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "MAA",
                  dd: "2023-05-08T17:55:00",
                  ad: "2023-05-08T19:15:00",
                  rbd: "",
                  fn: " 102",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "1" },
                  rph: "",
                  jsell:
                    "6E~ 102~ ~~HYD~05/08/2023 17:55~MAA~05/08/2023 19:15~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
                {
                  ref: 2,
                  da: "MAA",
                  ar: "DOH",
                  dd: "2023-05-08T21:55:00",
                  ad: "2023-05-08T23:55:00",
                  rbd: "",
                  fn: "1775",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "4" },
                  rph: "",
                  jsell:
                    "6E~1775~ ~~MAA~05/08/2023 21:55~DOH~05/08/2023 23:55~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~R~ ~6E~RCINT~6200~~0~66~~X^0~R~ ~6E~RCINT~6200~~0~66~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 354,
                      fbc: "RCINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "354_516",
                      tax: [
                        { OT: 162 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 516,
                      psup_fare: "354_516",
                    },
                  ],
                  pr: {
                    bf: 354,
                    tf: 516,
                    tax: [
                      { OT: 162 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "354_516",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 516,
                  },
                  routeId: "6hjrdbzyky",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~R~ ~6E~RCUINT~6202~~0~5~~X^0~R~ ~6E~RCUINT~6202~~0~5~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 384,
                      fbc: "RCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "384_548",
                      tax: [
                        { OT: 164 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 548,
                      psup_fare: "384_548",
                    },
                  ],
                  pr: {
                    bf: 384,
                    tf: 548,
                    tax: [
                      { OT: 164 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "384_548",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 548,
                  },
                  routeId: "ne49bg4j4f",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "gV9VlJcF4lo=|c8vLGEjg5j7NNr8U4/n4JN2DKZGLTH99mWKIwdR5uRWciziCl+wbZTrpWnMmGN+Yy9uJ4ZUTuD0HpLhkPra8oFmlLXu64hUIzZhRraKsuKvdW8dDCeFMBF8RTGVhnAM8gqCD8NTV8Ig=",
              stop: 1,
            },
          ],
          paxpr: [
            {
              bf: 684,
              tf: 995,
              tax: [
                { OT: 311 },
                { attmkp: 0 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              fbc: "R0INT",
              ptype: "ADT",
              scur: "QAR",
              sup_fare: "330_479",
              psup_fare: "684_995",
            },
          ],
          pr: {
            bf: 684,
            tf: 995,
            tax: [
              { OT: 311 },
              { attmkp: 0 },
              { tamkp: 0 },
              { stamkp: 0 },
              { attdisc: 0 },
              { tadisc: 0 },
              { stadisc: 0 },
            ],
            ucur: "QAR",
            exchange_rate: 1,
            scur: "QAR",
            sup_fare: "684_995",
            sup_cur_fare: "",
            cabb: "",
            chekb: "",
            seats: "",
            meals: "",
            chanb: "",
            ftyp: "Regular - R",
            markupnames: [],
            ttf: 995,
          },
          sup: "4",
          airlineList: "6E",
          servicefee: [],
        },
        {
          srcorigns: [
            {
              tid: "T9c7aIKOEnAlTWsJ_1",
              ref: 1,
              ofcid: "",
              sdur: "15:40",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "MAA",
                  dd: "2023-05-06T02:15:00",
                  ad: "2023-05-06T09:05:00",
                  rbd: "",
                  fn: "1776",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "3" },
                  rph: "",
                  jsell:
                    "6E~1776~ ~~DOH~05/06/2023 02:15~MAA~05/06/2023 09:05~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
                {
                  ref: 2,
                  da: "MAA",
                  ar: "HYD",
                  dd: "2023-05-06T17:00:00",
                  ad: "2023-05-06T17:55:00",
                  rbd: "",
                  fn: " 562",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "1" },
                  rph: "",
                  jsell:
                    "6E~ 562~ ~~MAA~05/06/2023 17:00~HYD~05/06/2023 17:55~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~R~ ~6E~RCINT~6200~~0~65~~X^0~R~ ~6E~RCINT~6200~~0~65~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 432,
                      fbc: "RCINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "432_586",
                      tax: [
                        { OT: 154 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 586,
                      psup_fare: "432_586",
                    },
                  ],
                  pr: {
                    bf: 432,
                    tf: 586,
                    tax: [
                      { OT: 154 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "432_586",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 586,
                  },
                  routeId: "i3sgff4lfl",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~R~ ~6E~RCUINT~6202~~0~4~~X^0~R~ ~6E~RCUINT~6202~~0~4~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 463,
                      fbc: "RCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "463_618",
                      tax: [
                        { OT: 155 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 618,
                      psup_fare: "463_618",
                    },
                  ],
                  pr: {
                    bf: 463,
                    tf: 618,
                    tax: [
                      { OT: 155 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "463_618",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 618,
                  },
                  routeId: "tbwkt8z1xb",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "gV9VlJcF4lo=|c8vLGEjg5j7NNr8U4/n4JN2DKZGLTH99mWKIwdR5uRWciziCl+wbZTrpWnMmGN+Yy9uJ4ZUTuD0HpLhkPra8oFmlLXu64hUIzZhRraKsuKvdW8dDCeFMBF8RTGVhnAM8gqCD8NTV8Ig=",
              stop: 1,
            },
            {
              tid: "cP8Pw3PwWB5y6fiV_1",
              ref: 1,
              ofcid: "",
              sdur: "15:5",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "MAA",
                  dd: "2023-05-06T02:15:00",
                  ad: "2023-05-06T09:05:00",
                  rbd: "",
                  fn: "1776",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "3" },
                  rph: "",
                  jsell:
                    "6E~1776~ ~~DOH~05/06/2023 02:15~MAA~05/06/2023 09:05~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
                {
                  ref: 2,
                  da: "MAA",
                  ar: "HYD",
                  dd: "2023-05-06T16:15:00",
                  ad: "2023-05-06T17:20:00",
                  rbd: "",
                  fn: "6805",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "1" },
                  rph: "",
                  jsell:
                    "6E~6805~ ~~MAA~05/06/2023 16:15~HYD~05/06/2023 17:20~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~R~ ~6E~RCINT~6200~~0~65~~X^0~R~ ~6E~RCINT~6200~~0~65~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 432,
                      fbc: "RCINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "432_586",
                      tax: [
                        { OT: 154 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 586,
                      psup_fare: "432_586",
                    },
                  ],
                  pr: {
                    bf: 432,
                    tf: 586,
                    tax: [
                      { OT: 154 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "432_586",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 586,
                  },
                  routeId: "fh9or46xokg",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~R~ ~6E~RCUINT~6202~~0~4~~X^0~R~ ~6E~RCUINT~6202~~0~4~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 463,
                      fbc: "RCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "463_618",
                      tax: [
                        { OT: 155 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 618,
                      psup_fare: "463_618",
                    },
                  ],
                  pr: {
                    bf: 463,
                    tf: 618,
                    tax: [
                      { OT: 155 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "463_618",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 618,
                  },
                  routeId: "ldcpw4ng4q",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "gV9VlJcF4lo=|c8vLGEjg5j7NNr8U4/n4JN2DKZGLTH99mWKIwdR5uRWciziCl+wbZTrpWnMmGN+Yy9uJ4ZUTuD0HpLhkPra8oFmlLXu64hUIzZhRraKsuKvdW8dDCeFMBF8RTGVhnAM8gqCD8NTV8Ig=",
              stop: 1,
            },
            {
              tid: "cUwZGHQker269xDC_1",
              ref: 1,
              ofcid: "",
              sdur: "10:50",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "MAA",
                  dd: "2023-05-06T02:15:00",
                  ad: "2023-05-06T09:05:00",
                  rbd: "",
                  fn: "1776",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "3" },
                  rph: "",
                  jsell:
                    "6E~1776~ ~~DOH~05/06/2023 02:15~MAA~05/06/2023 09:05~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
                {
                  ref: 2,
                  da: "MAA",
                  ar: "HYD",
                  dd: "2023-05-06T12:00:00",
                  ad: "2023-05-06T13:05:00",
                  rbd: "",
                  fn: " 917",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "1" },
                  rph: "",
                  jsell:
                    "6E~ 917~ ~~MAA~05/06/2023 12:00~HYD~05/06/2023 13:05~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~R~ ~6E~RCINT~6200~~0~65~~X^0~R~ ~6E~RCINT~6200~~0~65~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 432,
                      fbc: "RCINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "432_586",
                      tax: [
                        { OT: 154 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 586,
                      psup_fare: "432_586",
                    },
                  ],
                  pr: {
                    bf: 432,
                    tf: 586,
                    tax: [
                      { OT: 154 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "432_586",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 586,
                  },
                  routeId: "aproshunj9",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~R~ ~6E~RCUINT~6202~~0~4~~X^0~R~ ~6E~RCUINT~6202~~0~4~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 463,
                      fbc: "RCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "463_618",
                      tax: [
                        { OT: 155 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 618,
                      psup_fare: "463_618",
                    },
                  ],
                  pr: {
                    bf: 463,
                    tf: 618,
                    tax: [
                      { OT: 155 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "463_618",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 618,
                  },
                  routeId: "0hpozisx7x",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "gV9VlJcF4lo=|c8vLGEjg5j7NNr8U4/n4JN2DKZGLTH99mWKIwdR5uRWciziCl+wbZTrpWnMmGN+Yy9uJ4ZUTuD0HpLhkPra8oFmlLXu64hUIzZhRraKsuKvdW8dDCeFMBF8RTGVhnAM8gqCD8NTV8Ig=",
              stop: 1,
            },
          ],
          destorigins: [
            {
              tid: "VWlW9OJQNgt9N8oZ_2",
              ref: 2,
              ofcid: "",
              sdur: "2:30",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DOH",
                  dd: "2023-05-08T03:35:00",
                  ad: "2023-05-08T06:05:00",
                  rbd: "",
                  fn: "1713",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: {},
                  rph: "",
                  jsell:
                    "6E~1713~ ~~HYD~05/08/2023 03:35~DOH~05/08/2023 06:05~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
              ],
              fareop: [
                {
                  fareKey: "0~R~ ~6E~R0INT~5000~~0~3~~X",
                  pr: {
                    bf: 278,
                    tf: 432,
                    tax: [
                      { OT: 154 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "278_432",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 432,
                  },
                  paxpr: [
                    {
                      bf: 278,
                      tf: 432,
                      tax: [
                        { OT: 154 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "R0INT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "278_432",
                      psup_fare: "278_432",
                    },
                  ],
                  routeId: "pz278sgm0e",
                  productClass: "R",
                  ruleNumber: "5000",
                  servicefee: [],
                  selected: true,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "gV9VlJcF4lo=|c8vLGEjg5j7NNr8U4/n4JN2DKZGLTH99mWKIwdR5uRWciziCl+wbZTrpWnMmGN+Yy9uJ4ZUTuD0HpLhkPra8oFmlLXu64hUIzZhRraKsuKvdW8dDCeFMBF8RTGVhnAM8gqCD8NTV8Ig=",
              stop: 0,
            },
          ],
          paxpr: [
            {
              bf: 710,
              fbc: "RCINT",
              ptype: "ADT",
              scur: "QAR",
              sup_fare: "432_586",
              tax: [
                { OT: 308 },
                { attmkp: 0 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              tf: 1018,
              psup_fare: "710_1018",
            },
          ],
          pr: {
            bf: 710,
            tf: 1018,
            tax: [
              { OT: 308 },
              { attmkp: 0 },
              { tamkp: 0 },
              { stamkp: 0 },
              { attdisc: 0 },
              { tadisc: 0 },
              { stadisc: 0 },
            ],
            ucur: "QAR",
            exchange_rate: 1,
            scur: "QAR",
            sup_fare: "710_1018",
            sup_cur_fare: "",
            cabb: "",
            chekb: "",
            seats: "",
            meals: "",
            chanb: "",
            ftyp: "Regular - R",
            markupnames: [],
            ttf: 1018,
          },
          sup: "4",
          airlineList: "6E",
          servicefee: [],
        },
        {
          srcorigns: [
            {
              tid: "dNoipTULE1WcVrwp_1",
              ref: 1,
              ofcid: "",
              sdur: "15:40",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "MAA",
                  dd: "2023-05-06T02:15:00",
                  ad: "2023-05-06T09:05:00",
                  rbd: "",
                  fn: "1776",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "3" },
                  rph: "",
                  jsell:
                    "6E~1776~ ~~DOH~05/06/2023 02:15~MAA~05/06/2023 09:05~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
                {
                  ref: 2,
                  da: "MAA",
                  ar: "HYD",
                  dd: "2023-05-06T17:00:00",
                  ad: "2023-05-06T17:55:00",
                  rbd: "",
                  fn: " 562",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "1" },
                  rph: "",
                  jsell:
                    "6E~ 562~ ~~MAA~05/06/2023 17:00~HYD~05/06/2023 17:55~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~R~ ~6E~RCINT~6200~~0~65~~X^0~R~ ~6E~RCINT~6200~~0~65~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 432,
                      fbc: "RCINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "432_586",
                      tax: [
                        { OT: 154 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 586,
                      psup_fare: "432_586",
                    },
                  ],
                  pr: {
                    bf: 432,
                    tf: 586,
                    tax: [
                      { OT: 154 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "432_586",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 586,
                  },
                  routeId: "i3sgff4lfl",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~R~ ~6E~RCUINT~6202~~0~4~~X^0~R~ ~6E~RCUINT~6202~~0~4~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 463,
                      fbc: "RCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "463_618",
                      tax: [
                        { OT: 155 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 618,
                      psup_fare: "463_618",
                    },
                  ],
                  pr: {
                    bf: 463,
                    tf: 618,
                    tax: [
                      { OT: 155 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "463_618",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 618,
                  },
                  routeId: "tbwkt8z1xb",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "gV9VlJcF4lo=|c8vLGEjg5j7NNr8U4/n4JN2DKZGLTH99mWKIwdR5uRWciziCl+wbZTrpWnMmGN+Yy9uJ4ZUTuD0HpLhkPra8oFmlLXu64hUIzZhRraKsuKvdW8dDCeFMBF8RTGVhnAM8gqCD8NTV8Ig=",
              stop: 1,
            },
            {
              tid: "oRJcTHobNVENNzFb_1",
              ref: 1,
              ofcid: "",
              sdur: "15:5",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "MAA",
                  dd: "2023-05-06T02:15:00",
                  ad: "2023-05-06T09:05:00",
                  rbd: "",
                  fn: "1776",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "3" },
                  rph: "",
                  jsell:
                    "6E~1776~ ~~DOH~05/06/2023 02:15~MAA~05/06/2023 09:05~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
                {
                  ref: 2,
                  da: "MAA",
                  ar: "HYD",
                  dd: "2023-05-06T16:15:00",
                  ad: "2023-05-06T17:20:00",
                  rbd: "",
                  fn: "6805",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "1" },
                  rph: "",
                  jsell:
                    "6E~6805~ ~~MAA~05/06/2023 16:15~HYD~05/06/2023 17:20~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~R~ ~6E~RCINT~6200~~0~65~~X^0~R~ ~6E~RCINT~6200~~0~65~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 432,
                      fbc: "RCINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "432_586",
                      tax: [
                        { OT: 154 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 586,
                      psup_fare: "432_586",
                    },
                  ],
                  pr: {
                    bf: 432,
                    tf: 586,
                    tax: [
                      { OT: 154 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "432_586",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 586,
                  },
                  routeId: "fh9or46xokg",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~R~ ~6E~RCUINT~6202~~0~4~~X^0~R~ ~6E~RCUINT~6202~~0~4~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 463,
                      fbc: "RCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "463_618",
                      tax: [
                        { OT: 155 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 618,
                      psup_fare: "463_618",
                    },
                  ],
                  pr: {
                    bf: 463,
                    tf: 618,
                    tax: [
                      { OT: 155 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "463_618",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 618,
                  },
                  routeId: "ldcpw4ng4q",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "gV9VlJcF4lo=|c8vLGEjg5j7NNr8U4/n4JN2DKZGLTH99mWKIwdR5uRWciziCl+wbZTrpWnMmGN+Yy9uJ4ZUTuD0HpLhkPra8oFmlLXu64hUIzZhRraKsuKvdW8dDCeFMBF8RTGVhnAM8gqCD8NTV8Ig=",
              stop: 1,
            },
            {
              tid: "pyZgSXT6VJJc1xxH_1",
              ref: 1,
              ofcid: "",
              sdur: "10:50",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "MAA",
                  dd: "2023-05-06T02:15:00",
                  ad: "2023-05-06T09:05:00",
                  rbd: "",
                  fn: "1776",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "3" },
                  rph: "",
                  jsell:
                    "6E~1776~ ~~DOH~05/06/2023 02:15~MAA~05/06/2023 09:05~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
                {
                  ref: 2,
                  da: "MAA",
                  ar: "HYD",
                  dd: "2023-05-06T12:00:00",
                  ad: "2023-05-06T13:05:00",
                  rbd: "",
                  fn: " 917",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "1" },
                  rph: "",
                  jsell:
                    "6E~ 917~ ~~MAA~05/06/2023 12:00~HYD~05/06/2023 13:05~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~R~ ~6E~RCINT~6200~~0~65~~X^0~R~ ~6E~RCINT~6200~~0~65~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 432,
                      fbc: "RCINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "432_586",
                      tax: [
                        { OT: 154 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 586,
                      psup_fare: "432_586",
                    },
                  ],
                  pr: {
                    bf: 432,
                    tf: 586,
                    tax: [
                      { OT: 154 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "432_586",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 586,
                  },
                  routeId: "aproshunj9",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~R~ ~6E~RCUINT~6202~~0~4~~X^0~R~ ~6E~RCUINT~6202~~0~4~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 463,
                      fbc: "RCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "463_618",
                      tax: [
                        { OT: 155 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 618,
                      psup_fare: "463_618",
                    },
                  ],
                  pr: {
                    bf: 463,
                    tf: 618,
                    tax: [
                      { OT: 155 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "463_618",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 618,
                  },
                  routeId: "0hpozisx7x",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "gV9VlJcF4lo=|c8vLGEjg5j7NNr8U4/n4JN2DKZGLTH99mWKIwdR5uRWciziCl+wbZTrpWnMmGN+Yy9uJ4ZUTuD0HpLhkPra8oFmlLXu64hUIzZhRraKsuKvdW8dDCeFMBF8RTGVhnAM8gqCD8NTV8Ig=",
              stop: 1,
            },
          ],
          destorigins: [
            {
              tid: "NryqGRhIccWtNbDk_2",
              ref: 2,
              ofcid: "",
              sdur: "10:20",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "MAA",
                  dd: "2023-05-08T13:35:00",
                  ad: "2023-05-08T15:10:00",
                  rbd: "",
                  fn: " 514",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "1" },
                  rph: "",
                  jsell:
                    "6E~ 514~ ~~HYD~05/08/2023 13:35~MAA~05/08/2023 15:10~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
                {
                  ref: 2,
                  da: "MAA",
                  ar: "DOH",
                  dd: "2023-05-08T21:55:00",
                  ad: "2023-05-08T23:55:00",
                  rbd: "",
                  fn: "1775",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "4" },
                  rph: "",
                  jsell:
                    "6E~1775~ ~~MAA~05/08/2023 21:55~DOH~05/08/2023 23:55~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~R~ ~6E~RCINT~6200~~0~66~~X^0~R~ ~6E~RCINT~6200~~0~66~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 354,
                      fbc: "RCINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "354_516",
                      tax: [
                        { OT: 162 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 516,
                      psup_fare: "354_516",
                    },
                  ],
                  pr: {
                    bf: 354,
                    tf: 516,
                    tax: [
                      { OT: 162 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "354_516",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 516,
                  },
                  routeId: "5ikofs2cw4",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~R~ ~6E~RCUINT~6202~~0~5~~X^0~R~ ~6E~RCUINT~6202~~0~5~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 384,
                      fbc: "RCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "384_548",
                      tax: [
                        { OT: 164 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 548,
                      psup_fare: "384_548",
                    },
                  ],
                  pr: {
                    bf: 384,
                    tf: 548,
                    tax: [
                      { OT: 164 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "384_548",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 548,
                  },
                  routeId: "8o3ucb7s9a",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "gV9VlJcF4lo=|c8vLGEjg5j7NNr8U4/n4JN2DKZGLTH99mWKIwdR5uRWciziCl+wbZTrpWnMmGN+Yy9uJ4ZUTuD0HpLhkPra8oFmlLXu64hUIzZhRraKsuKvdW8dDCeFMBF8RTGVhnAM8gqCD8NTV8Ig=",
              stop: 1,
            },
            {
              tid: "Efh1o7qG4na7Mgep_2",
              ref: 2,
              ofcid: "",
              sdur: "7:35",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "MAA",
                  dd: "2023-05-08T16:20:00",
                  ad: "2023-05-08T17:35:00",
                  rbd: "",
                  fn: " 668",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "1" },
                  rph: "",
                  jsell:
                    "6E~ 668~ ~~HYD~05/08/2023 16:20~MAA~05/08/2023 17:35~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
                {
                  ref: 2,
                  da: "MAA",
                  ar: "DOH",
                  dd: "2023-05-08T21:55:00",
                  ad: "2023-05-08T23:55:00",
                  rbd: "",
                  fn: "1775",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "4" },
                  rph: "",
                  jsell:
                    "6E~1775~ ~~MAA~05/08/2023 21:55~DOH~05/08/2023 23:55~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~R~ ~6E~RCINT~6200~~0~66~~X^0~R~ ~6E~RCINT~6200~~0~66~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 354,
                      fbc: "RCINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "354_516",
                      tax: [
                        { OT: 162 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 516,
                      psup_fare: "354_516",
                    },
                  ],
                  pr: {
                    bf: 354,
                    tf: 516,
                    tax: [
                      { OT: 162 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "354_516",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 516,
                  },
                  routeId: "ogjcoi49gb",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~R~ ~6E~RCUINT~6202~~0~5~~X^0~R~ ~6E~RCUINT~6202~~0~5~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 384,
                      fbc: "RCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "384_548",
                      tax: [
                        { OT: 164 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 548,
                      psup_fare: "384_548",
                    },
                  ],
                  pr: {
                    bf: 384,
                    tf: 548,
                    tax: [
                      { OT: 164 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "384_548",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 548,
                  },
                  routeId: "m57idmwg7q",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "gV9VlJcF4lo=|c8vLGEjg5j7NNr8U4/n4JN2DKZGLTH99mWKIwdR5uRWciziCl+wbZTrpWnMmGN+Yy9uJ4ZUTuD0HpLhkPra8oFmlLXu64hUIzZhRraKsuKvdW8dDCeFMBF8RTGVhnAM8gqCD8NTV8Ig=",
              stop: 1,
            },
            {
              tid: "f8DG74J2yUb5XI1O_2",
              ref: 2,
              ofcid: "",
              sdur: "6:0",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "MAA",
                  dd: "2023-05-08T17:55:00",
                  ad: "2023-05-08T19:15:00",
                  rbd: "",
                  fn: " 102",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "1" },
                  rph: "",
                  jsell:
                    "6E~ 102~ ~~HYD~05/08/2023 17:55~MAA~05/08/2023 19:15~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
                {
                  ref: 2,
                  da: "MAA",
                  ar: "DOH",
                  dd: "2023-05-08T21:55:00",
                  ad: "2023-05-08T23:55:00",
                  rbd: "",
                  fn: "1775",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "4" },
                  rph: "",
                  jsell:
                    "6E~1775~ ~~MAA~05/08/2023 21:55~DOH~05/08/2023 23:55~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~R~ ~6E~RCINT~6200~~0~66~~X^0~R~ ~6E~RCINT~6200~~0~66~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 354,
                      fbc: "RCINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "354_516",
                      tax: [
                        { OT: 162 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 516,
                      psup_fare: "354_516",
                    },
                  ],
                  pr: {
                    bf: 354,
                    tf: 516,
                    tax: [
                      { OT: 162 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "354_516",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 516,
                  },
                  routeId: "cqh1k6ifaw",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~R~ ~6E~RCUINT~6202~~0~5~~X^0~R~ ~6E~RCUINT~6202~~0~5~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 384,
                      fbc: "RCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "384_548",
                      tax: [
                        { OT: 164 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 548,
                      psup_fare: "384_548",
                    },
                  ],
                  pr: {
                    bf: 384,
                    tf: 548,
                    tax: [
                      { OT: 164 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "384_548",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 548,
                  },
                  routeId: "ttrx4i3ubai",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "gV9VlJcF4lo=|c8vLGEjg5j7NNr8U4/n4JN2DKZGLTH99mWKIwdR5uRWciziCl+wbZTrpWnMmGN+Yy9uJ4ZUTuD0HpLhkPra8oFmlLXu64hUIzZhRraKsuKvdW8dDCeFMBF8RTGVhnAM8gqCD8NTV8Ig=",
              stop: 1,
            },
          ],
          paxpr: [
            {
              bf: 786,
              fbc: "RCINT",
              ptype: "ADT",
              scur: "QAR",
              sup_fare: "432_586",
              tax: [
                { OT: 316 },
                { attmkp: 0 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              tf: 1102,
              psup_fare: "786_1102",
            },
          ],
          pr: {
            bf: 786,
            tf: 1102,
            tax: [
              { OT: 316 },
              { attmkp: 0 },
              { tamkp: 0 },
              { stamkp: 0 },
              { attdisc: 0 },
              { tadisc: 0 },
              { stadisc: 0 },
            ],
            ucur: "QAR",
            exchange_rate: 1,
            scur: "QAR",
            sup_fare: "786_1102",
            sup_cur_fare: "",
            cabb: "",
            chekb: "",
            seats: "",
            meals: "",
            chanb: "",
            ftyp: "Regular - R",
            markupnames: [],
            ttf: 1102,
          },
          sup: "4",
          airlineList: "6E",
          servicefee: [],
        },
        {
          srcorigns: [
            {
              tid: "N2UavO3HFt5sgZm3_1",
              ref: 1,
              ofcid: "",
              sdur: "26:45",
              re: "",
              sup: "5",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DXB",
                  dd: "2023-05-06T21:00:00",
                  ad: "2023-05-06T23:15:00",
                  rbd: "",
                  fn: "006",
                  trn: "",
                  eq: "73X",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "1", arr: "2" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161423,
                  flightTime: 75,
                  fdur: "1:15",
                },
                {
                  ref: 2,
                  da: "DXB",
                  ar: "HYD",
                  dd: "2023-05-07T20:50:00",
                  ad: "2023-05-08T02:15:00",
                  rbd: "",
                  fn: "435",
                  trn: "",
                  eq: "73B",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "2", arr: "" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161456,
                  flightTime: 235,
                  fdur: "3:55",
                },
              ],
              fareop: [
                {
                  fareKey: [],
                  pr: {
                    bf: 390,
                    tf: 660,
                    tax: [
                      { OT: 270 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    scur: "QAR",
                    ucur: "QAR",
                    exchange_rate: 1,
                    sup_fare: "390_660",
                    sup_cur_fare: "",
                    cabb: "Chargeable",
                    chekb: "7kg INCLUDED",
                    meals: "Chargeable",
                    chanb: "Non Refundable",
                    ftyp: "LITE",
                    extra: "11_LITE",
                    chekbcode: "",
                    chanb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Change of Flight is Non-Refundable,NOSHOW From 0 hrs before  of departure Change of Flight is Non-Refundable",
                    canb: "Non Refundable",
                    cabb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Cancellation of Flight is Non-Refundable,NOSHOW From 0 hrs before  of departure Cancellation of Flight is Non-Refundable",
                    bagage_info: "Chargeable",
                    markupnames: [],
                    ttf: 660,
                  },
                  paxpr: [
                    {
                      bf: 390,
                      tf: 660,
                      tax: [
                        { OT: 270 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "NR6QA2",
                      ptype: "ADT",
                      scur: "QAR",
                      extra:
                        "1_1_N_1_9_0_tx-1764:60:QA:0_2186:10:PZ:0_4246:80:YQ:0_8966:60:G4:0_10386:10:ZR:0_11947:40:F6:0_13230:10:R9:0_",
                      ruleid: "fQSKPzlqukzJyFIqUKGPVVsz7CyJNvmSL1Foh5/zdRg=",
                      hsc: "665b7b300896a679d9facded8f8b412f8c5bd6357b7f92a20d55cef8d9bb3e2a",
                      sup_fare: "390_660_390",
                      cabb: "Chargeable",
                      bagage_info: "Chargeable",
                      chekbcode: "",
                      psup_fare: "390_660",
                    },
                  ],
                  uniqueId: "LITE",
                  routeId: "sguzj1xb6p",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey: [],
                  pr: {
                    bf: 450,
                    tf: 720,
                    tax: [
                      { OT: 270 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    scur: "QAR",
                    ucur: "QAR",
                    exchange_rate: 1,
                    sup_fare: "450_720",
                    sup_cur_fare: "",
                    cabb: "Free",
                    chekb: "7kg INCLUDED",
                    meals: "Standard meal",
                    chanb: "Chargeable",
                    ftyp: "VALUE",
                    extra: "12_VALUE",
                    chekbcode: "BAGL",
                    chanb_info:
                      "UPTO 24 hrs of departure Change of Flight is Free,WITHIN From 0-24 hrs of departure Change of Flight is Non-Refundable,NOSHOW From 0 hrs before  of departure Change of Flight is Non-Refundable",
                    canb: "Chargeable",
                    cabb_info:
                      "UPTO 24 hrs of departure Cancellation of Flight is Free,WITHIN From 0-24 hrs of departure Cancellation of Flight is Non-Refundable,NOSHOW From 0 hrs before  of departure Cancellation of Flight is Non-Refundable",
                    bagage_info: "30kg BAG INCLUDED IN FARE",
                    markupnames: [],
                    ttf: 720,
                  },
                  paxpr: [
                    {
                      bf: 450,
                      tf: 720,
                      tax: [
                        { OT: 270 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "NRL7QA2",
                      ptype: "ADT",
                      scur: "QAR",
                      extra:
                        "1_5_N_1_9_0_tx-1764:60:QA:0_2186:10:PZ:0_4246:80:YQ:0_8947:0:BAGL:0_8966:60:G4:0_10386:10:ZR:0_11947:40:F6:0_13230:10:R9:0_",
                      ruleid: "fQSKPzlqukzJyFIqUKGPVRulOhJusUC/VbKVf2f/zG8=",
                      hsc: "89c82411589a5df461f07de405e63abcdc0398c8b299317287fece062803d9f7",
                      sup_fare: "450_720_450",
                      cabb: "Free",
                      bagage_info: "30kg BAG INCLUDED IN FARE",
                      chekbcode: "BAGL",
                      psup_fare: "450_720",
                    },
                  ],
                  uniqueId: "VALUE",
                  routeId: "jx3fp3m9cu",
                  servicefee: [],
                  selected: false,
                },
                {
                  fareKey: [],
                  pr: {
                    bf: 640,
                    tf: 910,
                    tax: [
                      { OT: 270 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    scur: "QAR",
                    ucur: "QAR",
                    exchange_rate: 1,
                    sup_fare: "640_910",
                    sup_cur_fare: "",
                    cabb: "Free",
                    chekb: "7kg INCLUDED",
                    meals: "Standard meal",
                    chanb: "Chargeable",
                    ftyp: "FLEX",
                    extra: "13_FLEX",
                    chekbcode: "BAGL",
                    chanb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Change of Flight is Free,NOSHOWUPTO 720 hrs of departure Change of Flight is Free,NOSHOWAFTER From 720 hrs before  of departure Change of Flight is Non-Refundable",
                    canb: "Chargeable",
                    cabb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Cancellation of Flight is Free,NOSHOWUPTO 720 hrs of departure Cancellation of Flight is Free,NOSHOWAFTER From 720 hrs before  of departure Cancellation of Flight is Non-Refundable",
                    bagage_info: "30kg BAG INCLUDED IN FARE",
                    markupnames: [],
                    ttf: 910,
                  },
                  paxpr: [
                    {
                      bf: 640,
                      tf: 910,
                      tax: [
                        { OT: 270 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "NRL8QA2",
                      ptype: "ADT",
                      scur: "QAR",
                      extra:
                        "1_9_N_1_9_0_tx-1764:60:QA:0_2186:10:PZ:0_4246:80:YQ:0_8947:0:BAGL:0_8966:60:G4:0_10386:10:ZR:0_11947:40:F6:0_13230:10:R9:0_",
                      ruleid: "fQSKPzlqukzJyFIqUKGPVRvH3KgBhgXSerzsKJXY+lU=",
                      hsc: "55fee15c077f6b906eab955af9f0e2c54e0d6b0e49fe5651600b44adf1e2ee19",
                      sup_fare: "640_910_640",
                      cabb: "Free",
                      bagage_info: "30kg BAG INCLUDED IN FARE",
                      chekbcode: "BAGL",
                      psup_fare: "640_910",
                    },
                  ],
                  uniqueId: "FLEX",
                  routeId: "x196pbloui",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "FZ",
              tecst: "no",
              cl: "Economy",
              extra: 13915828,
              token:
                "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiYXBpdGF3ZmVlcSIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL2dpdmVubmFtZSI6IkFQSSBUQVdGRUVRIiwiZ3JvdXBBY2Nlc3MiOiIzLDM2LDExMiIsImlzQXB0Q2xudCI6IkZhbHNlIiwibG9jYXRpb24iOiI5OTk5IiwiY2hrTGNuIjoiIiwicmVzVG9rZW4iOiJmMTYxMmE4YzM5Y3ZjNDMwZjJvOXhiYjk2Y2M5NGY2M3ZjNDAxNDhlNGI5NCIsImNsaWVudElQIjoiMTcyLjE5LjI3LjM5IiwicmVzQ2hhbm5lbElEIjoiNSIsInJlc0NoYW5uZWxOYW1lIjoiVFBBUEkiLCJkZWZhdWx0VXNlck5hbWUiOiJhcGl0YXdmZWVxIiwibmJmIjoxNjgzMTk1MTM0LCJleHAiOjE2ODMxOTc1MzQsImlzcyI6ImZseWR1YmFpLmNvbSIsImF1ZCI6IlRBV0ZFRVFUUlZMU19GWl9OUCJ9._I0ZV--svLR1OqP0XLXV17rFF4itsd_2C9QzPwezZsc",
              stop: 1,
            },
          ],
          paxpr: [
            {
              bf: 780,
              tf: 1330,
              tax: [
                { OT: 550 },
                { attmkp: 0 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              fbc: "NR6QA2",
              ptype: "ADT",
              scur: "QAR",
              extra:
                "1_1_N_1_9_0_tx-1764:60:QA:0_2186:10:PZ:0_4246:80:YQ:0_8966:60:G4:0_10386:10:ZR:0_11947:40:F6:0_13230:10:R9:0_",
              ruleid: "fQSKPzlqukzJyFIqUKGPVVsz7CyJNvmSL1Foh5/zdRg=",
              hsc: "665b7b300896a679d9facded8f8b412f8c5bd6357b7f92a20d55cef8d9bb3e2a",
              sup_fare: "390_660_390",
              cabb: "Chargeable",
              bagage_info: "Chargeable",
              chekbcode: "",
              psup_fare: "780_1330",
            },
          ],
          pr: {
            bf: 780,
            tf: 1330,
            tax: [
              { OT: 550 },
              { attmkp: 0 },
              { tamkp: 0 },
              { stamkp: 0 },
              { attdisc: 0 },
              { tadisc: 0 },
              { stadisc: 0 },
            ],
            scur: "QAR",
            ucur: "QAR",
            exchange_rate: 1,
            sup_fare: "780_1330",
            sup_cur_fare: "",
            cabb: "Chargeable",
            chekb: "7kg INCLUDED",
            meals: "Chargeable",
            chanb: "Non Refundable",
            ftyp: "LITE",
            extra: "11_LITE",
            chekbcode: "",
            chanb_info:
              "BEFOREDEPARTURE 0 hrs of departure Change of Flight is Non-Refundable,NOSHOW From 0 hrs before  of departure Change of Flight is Non-Refundable",
            canb: "Non Refundable",
            cabb_info:
              "BEFOREDEPARTURE 0 hrs of departure Cancellation of Flight is Non-Refundable,NOSHOW From 0 hrs before  of departure Cancellation of Flight is Non-Refundable",
            bagage_info: "Chargeable",
            markupnames: [],
            ttf: 1330,
          },
          sup: "5",
          airlineList: "FZ",
          destorigins: [
            {
              tid: "9zPy8ozbMOn5lW3S_2",
              ref: 2,
              ofcid: "",
              sdur: "8:25",
              re: "",
              sup: "5",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DXB",
                  dd: "2023-05-08T03:00:00",
                  ad: "2023-05-08T05:30:00",
                  rbd: "",
                  fn: "436",
                  trn: "",
                  eq: "73B",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "", arr: "2" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161457,
                  flightTime: 240,
                  fdur: "4:0",
                },
                {
                  ref: 2,
                  da: "DXB",
                  ar: "DOH",
                  dd: "2023-05-08T08:45:00",
                  ad: "2023-05-08T08:55:00",
                  rbd: "",
                  fn: "001",
                  trn: "",
                  eq: "73X",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "2", arr: "1" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161405,
                  flightTime: 70,
                  fdur: "1:10",
                },
              ],
              fareop: [
                {
                  fareKey: [],
                  pr: {
                    bf: 390,
                    tf: 670,
                    tax: [
                      { OT: 280 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    scur: "QAR",
                    ucur: "QAR",
                    exchange_rate: 1,
                    sup_fare: "390_670",
                    sup_cur_fare: "",
                    cabb: "Chargeable",
                    chekb: "7kg INCLUDED",
                    meals: "Chargeable",
                    chanb: "Non Refundable",
                    ftyp: "LITE",
                    extra: "11_LITE",
                    chekbcode: "",
                    chanb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Change of Flight is Non-Refundable,NOSHOW From 0 hrs before  of departure Change of Flight is Non-Refundable",
                    canb: "Non Refundable",
                    cabb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Cancellation of Flight is Non-Refundable,NOSHOW From 0 hrs before  of departure Cancellation of Flight is Non-Refundable",
                    bagage_info: "Chargeable",
                    markupnames: [],
                    ttf: 670,
                  },
                  paxpr: [
                    {
                      bf: 390,
                      tf: 670,
                      tax: [
                        { OT: 280 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "NR6QA2",
                      ptype: "ADT",
                      scur: "QAR",
                      extra:
                        "1_2_N_1_9_0_tx-2186:10:PZ:0_4246:80:YQ:0_10386:10:ZR:0_11947:40:F6:0_12006:60:P2:0_12434:80:IN:0_",
                      ruleid: "EI2tuphriR82jc5fk+WP8appUDvn/LNY15vA57Yy9l4=",
                      hsc: "82ba28233b54c7d0529890c46aa07db771c3a2a79bb0ac5b9caf3549f256d0fe",
                      sup_fare: "390_670_390",
                      cabb: "Chargeable",
                      bagage_info: "Chargeable",
                      chekbcode: "",
                      psup_fare: "390_670",
                    },
                  ],
                  uniqueId: "LITE",
                  routeId: "s8tbudxsxr",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey: [],
                  pr: {
                    bf: 450,
                    tf: 730,
                    tax: [
                      { OT: 280 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    scur: "QAR",
                    ucur: "QAR",
                    exchange_rate: 1,
                    sup_fare: "450_730",
                    sup_cur_fare: "",
                    cabb: "Free",
                    chekb: "7kg INCLUDED",
                    meals: "Standard meal",
                    chanb: "Chargeable",
                    ftyp: "VALUE",
                    extra: "12_VALUE",
                    chekbcode: "BAGL",
                    chanb_info:
                      "UPTO 24 hrs of departure Change of Flight is Free,WITHIN From 0-24 hrs of departure Change of Flight is Non-Refundable,NOSHOW From 0 hrs before  of departure Change of Flight is Non-Refundable",
                    canb: "Chargeable",
                    cabb_info:
                      "UPTO 24 hrs of departure Cancellation of Flight is Free,WITHIN From 0-24 hrs of departure Cancellation of Flight is Non-Refundable,NOSHOW From 0 hrs before  of departure Cancellation of Flight is Non-Refundable",
                    bagage_info: "30kg BAG INCLUDED IN FARE",
                    markupnames: [],
                    ttf: 730,
                  },
                  paxpr: [
                    {
                      bf: 450,
                      tf: 730,
                      tax: [
                        { OT: 280 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "NRL7QA2",
                      ptype: "ADT",
                      scur: "QAR",
                      extra:
                        "1_4_N_1_9_0_tx-2186:10:PZ:0_4246:80:YQ:0_8947:0:BAGL:0_10386:10:ZR:0_11947:40:F6:0_12006:60:P2:0_12434:80:IN:0_",
                      ruleid: "viIu7PonQyOlrTHXKrim8UuQq6Aizd3kV99CWR9yPM4=",
                      hsc: "2f91e7896bd10400e6e7e4edcbbd39f36afa1e950ded9fc1d8de1bac8c7e1a2b",
                      sup_fare: "450_730_450",
                      cabb: "Free",
                      bagage_info: "30kg BAG INCLUDED IN FARE",
                      chekbcode: "BAGL",
                      psup_fare: "450_730",
                    },
                  ],
                  uniqueId: "VALUE",
                  routeId: "ee0bubcm7z",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "FZ",
              tecst: "no",
              cl: "Economy",
              extra: 13919293,
              token:
                "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiYXBpdGF3ZmVlcSIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL2dpdmVubmFtZSI6IkFQSSBUQVdGRUVRIiwiZ3JvdXBBY2Nlc3MiOiIzLDM2LDExMiIsImlzQXB0Q2xudCI6IkZhbHNlIiwibG9jYXRpb24iOiI5OTk5IiwiY2hrTGNuIjoiIiwicmVzVG9rZW4iOiJmMTYxMmE4YzM5Y3ZjNDMwZjJvOXhiYjk2Y2M5NGY2M3ZjNDAxNDhlNGI5NCIsImNsaWVudElQIjoiMTcyLjE5LjI3LjM5IiwicmVzQ2hhbm5lbElEIjoiNSIsInJlc0NoYW5uZWxOYW1lIjoiVFBBUEkiLCJkZWZhdWx0VXNlck5hbWUiOiJhcGl0YXdmZWVxIiwibmJmIjoxNjgzMTk1MTM0LCJleHAiOjE2ODMxOTc1MzQsImlzcyI6ImZseWR1YmFpLmNvbSIsImF1ZCI6IlRBV0ZFRVFUUlZMU19GWl9OUCJ9._I0ZV--svLR1OqP0XLXV17rFF4itsd_2C9QzPwezZsc",
              stop: 1,
            },
            {
              tid: "6T7f0Dgk6ZLvwMh8_2",
              ref: 2,
              ofcid: "",
              sdur: "15:25",
              re: "",
              sup: "5",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DXB",
                  dd: "2023-05-08T03:00:00",
                  ad: "2023-05-08T05:30:00",
                  rbd: "",
                  fn: "436",
                  trn: "",
                  eq: "73B",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "", arr: "2" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161457,
                  flightTime: 240,
                  fdur: "4:0",
                },
                {
                  ref: 2,
                  da: "DXB",
                  ar: "DOH",
                  dd: "2023-05-08T15:45:00",
                  ad: "2023-05-08T15:55:00",
                  rbd: "",
                  fn: "017",
                  trn: "",
                  eq: "73X",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "2", arr: "1" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161400,
                  flightTime: 70,
                  fdur: "1:10",
                },
              ],
              fareop: [
                {
                  fareKey: [],
                  pr: {
                    bf: 390,
                    tf: 670,
                    tax: [
                      { OT: 280 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    scur: "QAR",
                    ucur: "QAR",
                    exchange_rate: 1,
                    sup_fare: "390_670",
                    sup_cur_fare: "",
                    cabb: "Chargeable",
                    chekb: "7kg INCLUDED",
                    meals: "Chargeable",
                    chanb: "Non Refundable",
                    ftyp: "LITE",
                    extra: "11_LITE",
                    chekbcode: "",
                    chanb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Change of Flight is Non-Refundable,NOSHOW From 0 hrs before  of departure Change of Flight is Non-Refundable",
                    canb: "Non Refundable",
                    cabb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Cancellation of Flight is Non-Refundable,NOSHOW From 0 hrs before  of departure Cancellation of Flight is Non-Refundable",
                    bagage_info: "Chargeable",
                    markupnames: [],
                    ttf: 670,
                  },
                  paxpr: [
                    {
                      bf: 390,
                      tf: 670,
                      tax: [
                        { OT: 280 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "NR6QA2",
                      ptype: "ADT",
                      scur: "QAR",
                      extra:
                        "1_34_N_1_9_0_tx-2186:10:PZ:0_4246:80:YQ:0_10386:10:ZR:0_11947:40:F6:0_12006:60:P2:0_12434:80:IN:0_",
                      ruleid: "AifZUhSiNwYM5YmIgNsJycW4oeVijvMb+J1x1oeNxQs=",
                      hsc: "4780b0c7efb4c0432ea8ff5f6a37eb9432aef415f7992a4f7884757f881a8f38",
                      sup_fare: "390_670_390",
                      cabb: "Chargeable",
                      bagage_info: "Chargeable",
                      chekbcode: "",
                      psup_fare: "390_670",
                    },
                  ],
                  uniqueId: "LITE",
                  routeId: "viz6w1yj1s",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey: [],
                  pr: {
                    bf: 450,
                    tf: 730,
                    tax: [
                      { OT: 280 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    scur: "QAR",
                    ucur: "QAR",
                    exchange_rate: 1,
                    sup_fare: "450_730",
                    sup_cur_fare: "",
                    cabb: "Free",
                    chekb: "7kg INCLUDED",
                    meals: "Standard meal",
                    chanb: "Chargeable",
                    ftyp: "VALUE",
                    extra: "12_VALUE",
                    chekbcode: "BAGL",
                    chanb_info:
                      "UPTO 24 hrs of departure Change of Flight is Free,WITHIN From 0-24 hrs of departure Change of Flight is Non-Refundable,NOSHOW From 0 hrs before  of departure Change of Flight is Non-Refundable",
                    canb: "Chargeable",
                    cabb_info:
                      "UPTO 24 hrs of departure Cancellation of Flight is Free,WITHIN From 0-24 hrs of departure Cancellation of Flight is Non-Refundable,NOSHOW From 0 hrs before  of departure Cancellation of Flight is Non-Refundable",
                    bagage_info: "30kg BAG INCLUDED IN FARE",
                    markupnames: [],
                    ttf: 730,
                  },
                  paxpr: [
                    {
                      bf: 450,
                      tf: 730,
                      tax: [
                        { OT: 280 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "NRL7QA2",
                      ptype: "ADT",
                      scur: "QAR",
                      extra:
                        "1_36_N_1_9_0_tx-2186:10:PZ:0_4246:80:YQ:0_8947:0:BAGL:0_10386:10:ZR:0_11947:40:F6:0_12006:60:P2:0_12434:80:IN:0_",
                      ruleid: "Lp0JvPpWdLGOR/opvojU4ivi1EMF9FKmpVoWsx/xVFw=",
                      hsc: "96df155f0721e8f1a212f8395bb29ced060395b26b448df8cadcd4499b57d31d",
                      sup_fare: "450_730_450",
                      cabb: "Free",
                      bagage_info: "30kg BAG INCLUDED IN FARE",
                      chekbcode: "BAGL",
                      psup_fare: "450_730",
                    },
                  ],
                  uniqueId: "VALUE",
                  routeId: "pn314wc955",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "FZ",
              tecst: "no",
              cl: "Economy",
              extra: 13919296,
              token:
                "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiYXBpdGF3ZmVlcSIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL2dpdmVubmFtZSI6IkFQSSBUQVdGRUVRIiwiZ3JvdXBBY2Nlc3MiOiIzLDM2LDExMiIsImlzQXB0Q2xudCI6IkZhbHNlIiwibG9jYXRpb24iOiI5OTk5IiwiY2hrTGNuIjoiIiwicmVzVG9rZW4iOiJmMTYxMmE4YzM5Y3ZjNDMwZjJvOXhiYjk2Y2M5NGY2M3ZjNDAxNDhlNGI5NCIsImNsaWVudElQIjoiMTcyLjE5LjI3LjM5IiwicmVzQ2hhbm5lbElEIjoiNSIsInJlc0NoYW5uZWxOYW1lIjoiVFBBUEkiLCJkZWZhdWx0VXNlck5hbWUiOiJhcGl0YXdmZWVxIiwibmJmIjoxNjgzMTk1MTM0LCJleHAiOjE2ODMxOTc1MzQsImlzcyI6ImZseWR1YmFpLmNvbSIsImF1ZCI6IlRBV0ZFRVFUUlZMU19GWl9OUCJ9._I0ZV--svLR1OqP0XLXV17rFF4itsd_2C9QzPwezZsc",
              stop: 1,
            },
            {
              tid: "TUUt05Si6Kf9DUPo_2",
              ref: 2,
              ofcid: "",
              sdur: "19:25",
              re: "",
              sup: "5",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DXB",
                  dd: "2023-05-08T03:00:00",
                  ad: "2023-05-08T05:30:00",
                  rbd: "",
                  fn: "436",
                  trn: "",
                  eq: "73B",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "", arr: "2" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161457,
                  flightTime: 240,
                  fdur: "4:0",
                },
                {
                  ref: 2,
                  da: "DXB",
                  ar: "DOH",
                  dd: "2023-05-08T19:45:00",
                  ad: "2023-05-08T19:55:00",
                  rbd: "",
                  fn: "005",
                  trn: "",
                  eq: "73X",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "2", arr: "1" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161381,
                  flightTime: 70,
                  fdur: "1:10",
                },
              ],
              fareop: [
                {
                  fareKey: [],
                  pr: {
                    bf: 390,
                    tf: 670,
                    tax: [
                      { OT: 280 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    scur: "QAR",
                    ucur: "QAR",
                    exchange_rate: 1,
                    sup_fare: "390_670",
                    sup_cur_fare: "",
                    cabb: "Chargeable",
                    chekb: "7kg INCLUDED",
                    meals: "Chargeable",
                    chanb: "Non Refundable",
                    ftyp: "LITE",
                    extra: "11_LITE",
                    chekbcode: "",
                    chanb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Change of Flight is Non-Refundable,NOSHOW From 0 hrs before  of departure Change of Flight is Non-Refundable",
                    canb: "Non Refundable",
                    cabb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Cancellation of Flight is Non-Refundable,NOSHOW From 0 hrs before  of departure Cancellation of Flight is Non-Refundable",
                    bagage_info: "Chargeable",
                    markupnames: [],
                    ttf: 670,
                  },
                  paxpr: [
                    {
                      bf: 390,
                      tf: 670,
                      tax: [
                        { OT: 280 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "NR6QA2",
                      ptype: "ADT",
                      scur: "QAR",
                      extra:
                        "1_66_N_1_9_0_tx-2186:10:PZ:0_4246:80:YQ:0_10386:10:ZR:0_11947:40:F6:0_12006:60:P2:0_12434:80:IN:0_",
                      ruleid: "hVqWXk6X+z8B6xFVinIqqcW4oeVijvMb+J1x1oeNxQs=",
                      hsc: "ebb5fbbe7e891ab322e2572971c9307e0e72e5d877214bf45f37991ce5af0318",
                      sup_fare: "390_670_390",
                      cabb: "Chargeable",
                      bagage_info: "Chargeable",
                      chekbcode: "",
                      psup_fare: "390_670",
                    },
                  ],
                  uniqueId: "LITE",
                  routeId: "la00u36y0h",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey: [],
                  pr: {
                    bf: 450,
                    tf: 730,
                    tax: [
                      { OT: 280 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    scur: "QAR",
                    ucur: "QAR",
                    exchange_rate: 1,
                    sup_fare: "450_730",
                    sup_cur_fare: "",
                    cabb: "Free",
                    chekb: "7kg INCLUDED",
                    meals: "Standard meal",
                    chanb: "Chargeable",
                    ftyp: "VALUE",
                    extra: "12_VALUE",
                    chekbcode: "BAGL",
                    chanb_info:
                      "UPTO 24 hrs of departure Change of Flight is Free,WITHIN From 0-24 hrs of departure Change of Flight is Non-Refundable,NOSHOW From 0 hrs before  of departure Change of Flight is Non-Refundable",
                    canb: "Chargeable",
                    cabb_info:
                      "UPTO 24 hrs of departure Cancellation of Flight is Free,WITHIN From 0-24 hrs of departure Cancellation of Flight is Non-Refundable,NOSHOW From 0 hrs before  of departure Cancellation of Flight is Non-Refundable",
                    bagage_info: "30kg BAG INCLUDED IN FARE",
                    markupnames: [],
                    ttf: 730,
                  },
                  paxpr: [
                    {
                      bf: 450,
                      tf: 730,
                      tax: [
                        { OT: 280 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "NRL7QA2",
                      ptype: "ADT",
                      scur: "QAR",
                      extra:
                        "1_68_N_1_9_0_tx-2186:10:PZ:0_4246:80:YQ:0_8947:0:BAGL:0_10386:10:ZR:0_11947:40:F6:0_12006:60:P2:0_12434:80:IN:0_",
                      ruleid: "Jqo7TLhy+WkcPxW2Fs2wUivi1EMF9FKmpVoWsx/xVFw=",
                      hsc: "be66c15c780e55d71d94206623f1eb66a9ba84fe04d6f6a2286876b8b7ad0bc4",
                      sup_fare: "450_730_450",
                      cabb: "Free",
                      bagage_info: "30kg BAG INCLUDED IN FARE",
                      chekbcode: "BAGL",
                      psup_fare: "450_730",
                    },
                  ],
                  uniqueId: "VALUE",
                  routeId: "ufj65z1wauj",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "FZ",
              tecst: "no",
              cl: "Economy",
              extra: 13919294,
              token:
                "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiYXBpdGF3ZmVlcSIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL2dpdmVubmFtZSI6IkFQSSBUQVdGRUVRIiwiZ3JvdXBBY2Nlc3MiOiIzLDM2LDExMiIsImlzQXB0Q2xudCI6IkZhbHNlIiwibG9jYXRpb24iOiI5OTk5IiwiY2hrTGNuIjoiIiwicmVzVG9rZW4iOiJmMTYxMmE4YzM5Y3ZjNDMwZjJvOXhiYjk2Y2M5NGY2M3ZjNDAxNDhlNGI5NCIsImNsaWVudElQIjoiMTcyLjE5LjI3LjM5IiwicmVzQ2hhbm5lbElEIjoiNSIsInJlc0NoYW5uZWxOYW1lIjoiVFBBUEkiLCJkZWZhdWx0VXNlck5hbWUiOiJhcGl0YXdmZWVxIiwibmJmIjoxNjgzMTk1MTM0LCJleHAiOjE2ODMxOTc1MzQsImlzcyI6ImZseWR1YmFpLmNvbSIsImF1ZCI6IlRBV0ZFRVFUUlZMU19GWl9OUCJ9._I0ZV--svLR1OqP0XLXV17rFF4itsd_2C9QzPwezZsc",
              stop: 1,
            },
          ],
          servicefee: [],
        },
        {
          srcorigns: [
            {
              tid: "wCUqGVrU2TgJ49nN_1",
              ref: 1,
              ofcid: "",
              sdur: "26:45",
              re: "",
              sup: "5",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DXB",
                  dd: "2023-05-06T21:00:00",
                  ad: "2023-05-06T23:15:00",
                  rbd: "",
                  fn: "006",
                  trn: "",
                  eq: "73X",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "1", arr: "2" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161423,
                  flightTime: 75,
                  fdur: "1:15",
                },
                {
                  ref: 2,
                  da: "DXB",
                  ar: "HYD",
                  dd: "2023-05-07T20:50:00",
                  ad: "2023-05-08T02:15:00",
                  rbd: "",
                  fn: "435",
                  trn: "",
                  eq: "73B",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "2", arr: "" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161456,
                  flightTime: 235,
                  fdur: "3:55",
                },
              ],
              fareop: [
                {
                  fareKey: [],
                  pr: {
                    bf: 395,
                    tf: 665,
                    tax: [
                      { OT: 270 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    scur: "QAR",
                    ucur: "QAR",
                    exchange_rate: 1,
                    sup_fare: "395_665",
                    sup_cur_fare: "",
                    cabb: "Chargeable",
                    chekb: "7kg INCLUDED",
                    meals: "Chargeable",
                    chanb: "Non Refundable",
                    ftyp: "LITE",
                    extra: "11_LITE",
                    chekbcode: "",
                    chanb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Change of Flight is Non-Refundable,NOSHOW From 0 hrs before  of departure Change of Flight is Non-Refundable",
                    canb: "Non Refundable",
                    cabb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Cancellation of Flight is Non-Refundable,NOSHOW From 0 hrs before  of departure Cancellation of Flight is Non-Refundable",
                    bagage_info: "Chargeable",
                    markupnames: [],
                    ttf: 665,
                  },
                  paxpr: [
                    {
                      bf: 395,
                      tf: 665,
                      tax: [
                        { OT: 270 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "NR6QA2",
                      ptype: "ADT",
                      scur: "QAR",
                      extra:
                        "1_11_N_1_9_0_tx-1764:60:QA:0_2186:10:PZ:0_4246:80:YQ:0_8966:60:G4:0_10386:10:ZR:0_11947:40:F6:0_13230:10:R9:0_",
                      ruleid:
                        "n3AykHBAEvDdYTU7vw6z09zgIrn3mpY1rkE4lXJQXDQFAYegzeWphyy6sJGrc+VT",
                      hsc: "cc1aabae7f5bbff983390e46e27fd4499aa0f451c7fd3a169b911a34f0abdefd",
                      sup_fare: "395_665_395",
                      cabb: "Chargeable",
                      bagage_info: "Chargeable",
                      chekbcode: "",
                      psup_fare: "395_665",
                    },
                  ],
                  uniqueId: "LITE",
                  routeId: "dvnujd7g7b",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey: [],
                  pr: {
                    bf: 455,
                    tf: 725,
                    tax: [
                      { OT: 270 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    scur: "QAR",
                    ucur: "QAR",
                    exchange_rate: 1,
                    sup_fare: "455_725",
                    sup_cur_fare: "",
                    cabb: "Free",
                    chekb: "7kg INCLUDED",
                    meals: "Standard meal",
                    chanb: "Chargeable",
                    ftyp: "VALUE",
                    extra: "12_VALUE",
                    chekbcode: "BAGL",
                    chanb_info:
                      "UPTO 24 hrs of departure Change of Flight is Free,WITHIN From 0-24 hrs of departure Change of Flight is Non-Refundable,NOSHOW From 0 hrs before  of departure Change of Flight is Non-Refundable",
                    canb: "Chargeable",
                    cabb_info:
                      "UPTO 24 hrs of departure Cancellation of Flight is Free,WITHIN From 0-24 hrs of departure Cancellation of Flight is Non-Refundable,NOSHOW From 0 hrs before  of departure Cancellation of Flight is Non-Refundable",
                    bagage_info: "30kg BAG INCLUDED IN FARE",
                    markupnames: [],
                    ttf: 725,
                  },
                  paxpr: [
                    {
                      bf: 455,
                      tf: 725,
                      tax: [
                        { OT: 270 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "NRL7QA2",
                      ptype: "ADT",
                      scur: "QAR",
                      extra:
                        "1_15_N_1_9_0_tx-1764:60:QA:0_2186:10:PZ:0_4246:80:YQ:0_8947:0:BAGL:0_8966:60:G4:0_10386:10:ZR:0_11947:40:F6:0_13230:10:R9:0_",
                      ruleid:
                        "n3AykHBAEvDdYTU7vw6z0/0euqNkVOAYE/b5K3s+nFkFAYegzeWphyy6sJGrc+VT",
                      hsc: "40d9bc19839e4e7cfa23649cdce1b85deb3dde72e58e854e5694141ca15854f6",
                      sup_fare: "455_725_455",
                      cabb: "Free",
                      bagage_info: "30kg BAG INCLUDED IN FARE",
                      chekbcode: "BAGL",
                      psup_fare: "455_725",
                    },
                  ],
                  uniqueId: "VALUE",
                  routeId: "08ow87n4lxg",
                  servicefee: [],
                  selected: false,
                },
                {
                  fareKey: [],
                  pr: {
                    bf: 635,
                    tf: 905,
                    tax: [
                      { OT: 270 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    scur: "QAR",
                    ucur: "QAR",
                    exchange_rate: 1,
                    sup_fare: "635_905",
                    sup_cur_fare: "",
                    cabb: "Free",
                    chekb: "7kg INCLUDED",
                    meals: "Standard meal",
                    chanb: "Chargeable",
                    ftyp: "FLEX",
                    extra: "13_FLEX",
                    chekbcode: "BAGL",
                    chanb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Change of Flight is Free,NOSHOWUPTO 720 hrs of departure Change of Flight is Free,NOSHOWAFTER From 720 hrs before  of departure Change of Flight is Non-Refundable",
                    canb: "Chargeable",
                    cabb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Cancellation of Flight is Free,NOSHOWUPTO 720 hrs of departure Cancellation of Flight is Free,NOSHOWAFTER From 720 hrs before  of departure Cancellation of Flight is Non-Refundable",
                    bagage_info: "30kg BAG INCLUDED IN FARE",
                    markupnames: [],
                    ttf: 905,
                  },
                  paxpr: [
                    {
                      bf: 635,
                      tf: 905,
                      tax: [
                        { OT: 270 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "NRL8QA2",
                      ptype: "ADT",
                      scur: "QAR",
                      extra:
                        "1_17_N_1_9_0_tx-1764:60:QA:0_2186:10:PZ:0_4246:80:YQ:0_8947:0:BAGL:0_8966:60:G4:0_10386:10:ZR:0_11947:40:F6:0_13230:10:R9:0_",
                      ruleid:
                        "n3AykHBAEvDdYTU7vw6z0//d7qWzHoZkT9LynU1TT/IFAYegzeWphyy6sJGrc+VT",
                      hsc: "4da43cf36a59fcbcaf37c8da6d1ba4d16cc63037f2c74388884b6fbe5beee65c",
                      sup_fare: "635_905_635",
                      cabb: "Free",
                      bagage_info: "30kg BAG INCLUDED IN FARE",
                      chekbcode: "BAGL",
                      psup_fare: "635_905",
                    },
                  ],
                  uniqueId: "FLEX",
                  routeId: "dza39c7rqtg",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "FZ",
              tecst: "no",
              cl: "Economy",
              extra: 13915828,
              token:
                "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiYXBpdGF3ZmVlcSIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL2dpdmVubmFtZSI6IkFQSSBUQVdGRUVRIiwiZ3JvdXBBY2Nlc3MiOiIzLDM2LDExMiIsImlzQXB0Q2xudCI6IkZhbHNlIiwibG9jYXRpb24iOiI5OTk5IiwiY2hrTGNuIjoiIiwicmVzVG9rZW4iOiJmMTYxMmE4YzM5Y3ZjNDMwZjJvOXhiYjk2Y2M5NGY2M3ZjNDAxNDhlNGI5NCIsImNsaWVudElQIjoiMTcyLjE5LjI3LjM5IiwicmVzQ2hhbm5lbElEIjoiNSIsInJlc0NoYW5uZWxOYW1lIjoiVFBBUEkiLCJkZWZhdWx0VXNlck5hbWUiOiJhcGl0YXdmZWVxIiwibmJmIjoxNjgzMTk1MTM0LCJleHAiOjE2ODMxOTc1MzQsImlzcyI6ImZseWR1YmFpLmNvbSIsImF1ZCI6IlRBV0ZFRVFUUlZMU19GWl9OUCJ9._I0ZV--svLR1OqP0XLXV17rFF4itsd_2C9QzPwezZsc",
              stop: 1,
            },
          ],
          paxpr: [
            {
              bf: 1030,
              tf: 1580,
              tax: [
                { OT: 550 },
                { attmkp: 0 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              fbc: "NR6QA2",
              ptype: "ADT",
              scur: "QAR",
              extra:
                "1_11_N_1_9_0_tx-1764:60:QA:0_2186:10:PZ:0_4246:80:YQ:0_8966:60:G4:0_10386:10:ZR:0_11947:40:F6:0_13230:10:R9:0_",
              ruleid:
                "n3AykHBAEvDdYTU7vw6z09zgIrn3mpY1rkE4lXJQXDQFAYegzeWphyy6sJGrc+VT",
              hsc: "cc1aabae7f5bbff983390e46e27fd4499aa0f451c7fd3a169b911a34f0abdefd",
              sup_fare: "395_665_395",
              cabb: "Chargeable",
              bagage_info: "Chargeable",
              chekbcode: "",
              psup_fare: "1030_1580",
            },
          ],
          pr: {
            bf: 1030,
            tf: 1580,
            tax: [
              { OT: 550 },
              { attmkp: 0 },
              { tamkp: 0 },
              { stamkp: 0 },
              { attdisc: 0 },
              { tadisc: 0 },
              { stadisc: 0 },
            ],
            scur: "QAR",
            ucur: "QAR",
            exchange_rate: 1,
            sup_fare: "1030_1580",
            sup_cur_fare: "",
            cabb: "Chargeable",
            chekb: "7kg INCLUDED",
            meals: "Chargeable",
            chanb: "Non Refundable",
            ftyp: "LITE",
            extra: "11_LITE",
            chekbcode: "",
            chanb_info:
              "BEFOREDEPARTURE 0 hrs of departure Change of Flight is Non-Refundable,NOSHOW From 0 hrs before  of departure Change of Flight is Non-Refundable",
            canb: "Non Refundable",
            cabb_info:
              "BEFOREDEPARTURE 0 hrs of departure Cancellation of Flight is Non-Refundable,NOSHOW From 0 hrs before  of departure Cancellation of Flight is Non-Refundable",
            bagage_info: "Chargeable",
            markupnames: [],
            ttf: 1580,
          },
          sup: "5",
          airlineList: "FZ",
          destorigins: [
            {
              tid: "2praOvFoTlnDw1Tp_2",
              ref: 2,
              ofcid: "",
              sdur: "8:25",
              re: "",
              sup: "5",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DXB",
                  dd: "2023-05-08T03:00:00",
                  ad: "2023-05-08T05:30:00",
                  rbd: "",
                  fn: "436",
                  trn: "",
                  eq: "73B",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "", arr: "2" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161457,
                  flightTime: 240,
                  fdur: "4:0",
                },
                {
                  ref: 2,
                  da: "DXB",
                  ar: "DOH",
                  dd: "2023-05-08T08:45:00",
                  ad: "2023-05-08T08:55:00",
                  rbd: "",
                  fn: "001",
                  trn: "",
                  eq: "73X",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "2", arr: "1" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161405,
                  flightTime: 70,
                  fdur: "1:10",
                },
              ],
              fareop: [
                {
                  fareKey: [],
                  pr: {
                    bf: 635,
                    tf: 915,
                    tax: [
                      { OT: 280 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    scur: "QAR",
                    ucur: "QAR",
                    exchange_rate: 1,
                    sup_fare: "635_915",
                    sup_cur_fare: "",
                    cabb: "Free",
                    chekb: "7kg INCLUDED",
                    meals: "Standard meal",
                    chanb: "Chargeable",
                    ftyp: "FLEX",
                    extra: "13_FLEX",
                    chekbcode: "BAGL",
                    chanb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Change of Flight is Free,NOSHOWUPTO 720 hrs of departure Change of Flight is Free,NOSHOWAFTER From 720 hrs before  of departure Change of Flight is Non-Refundable",
                    canb: "Chargeable",
                    cabb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Cancellation of Flight is Free,NOSHOWUPTO 720 hrs of departure Cancellation of Flight is Free,NOSHOWAFTER From 720 hrs before  of departure Cancellation of Flight is Non-Refundable",
                    bagage_info: "30kg BAG INCLUDED IN FARE",
                    markupnames: [],
                    ttf: 915,
                  },
                  paxpr: [
                    {
                      bf: 635,
                      tf: 915,
                      tax: [
                        { OT: 280 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "NRL8QA2",
                      ptype: "ADT",
                      scur: "QAR",
                      extra:
                        "1_12_N_1_9_0_tx-2186:10:PZ:0_4246:80:YQ:0_8947:0:BAGL:0_10386:10:ZR:0_11947:40:F6:0_12006:60:P2:0_12434:80:IN:0_",
                      ruleid: "9N+dTkCMVJ3ZO9vm7FIXlirh0x6swSbrCL0pg69gHUU=",
                      hsc: "01348e97190563789688633dae362bfa721129d7c099705fe357f93753f918bd",
                      sup_fare: "635_915_635",
                      cabb: "Free",
                      bagage_info: "30kg BAG INCLUDED IN FARE",
                      chekbcode: "BAGL",
                      psup_fare: "635_915",
                    },
                  ],
                  uniqueId: "FLEX",
                  routeId: "hxwg5l6q7f",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey: [],
                  pr: {
                    bf: 1595,
                    tf: 2715,
                    tax: [
                      { OT: 1120 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    scur: "QAR",
                    ucur: "QAR",
                    exchange_rate: 1,
                    sup_fare: "1595_2715",
                    sup_cur_fare: "",
                    cabb: "Free",
                    chekb: "14kg INCLUDED",
                    meals: "Chargeable",
                    chanb: "Chargeable",
                    ftyp: "Business",
                    extra: "15_Business",
                    chekbcode: "JBAG",
                    chanb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Change of Flight is Free,NOSHOWUPTO 720 hrs of departure Change of Flight is Free,NOSHOWAFTER From 720 hrs before  of departure Change of Flight is Non-Refundable",
                    canb: "Chargeable",
                    cabb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Cancellation of Flight is Free,NOSHOWUPTO 720 hrs of departure Cancellation of Flight is Free,NOSHOWAFTER From 720 hrs before  of departure Cancellation of Flight is Non-Refundable",
                    bagage_info: "40kg Baggage allowance Business",
                    markupnames: [],
                    ttf: 2715,
                  },
                  paxpr: [
                    {
                      bf: 1595,
                      tf: 2715,
                      tax: [
                        { OT: 1120 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "ZR9QA2",
                      ptype: "ADT",
                      scur: "QAR",
                      extra:
                        "1_20_Z_1_7_0_tx-2186:10:PZ:0_4246:920:YQ:0_6544:0:JBAG:0_10386:10:ZR:0_11947:40:F6:0_12006:60:P2:0_12434:80:IN:0_",
                      ruleid: "JrsaQ40CW4Yu1s/6YuRSFAt4fLJrC0i89TPad4f+u4k=",
                      hsc: "3a9557bf80168ca0af3fe7fa404e7d4a9bd9401b6301088c482c2de02a71d5e5",
                      sup_fare: "1595_2715_1595",
                      cabb: "Free",
                      bagage_info: "40kg Baggage allowance Business",
                      chekbcode: "JBAG",
                      psup_fare: "1595_2715",
                    },
                  ],
                  uniqueId: "Business",
                  routeId: "ynbbf1vv8f",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "FZ",
              tecst: "no",
              cl: "Economy",
              extra: 13919293,
              token:
                "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiYXBpdGF3ZmVlcSIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL2dpdmVubmFtZSI6IkFQSSBUQVdGRUVRIiwiZ3JvdXBBY2Nlc3MiOiIzLDM2LDExMiIsImlzQXB0Q2xudCI6IkZhbHNlIiwibG9jYXRpb24iOiI5OTk5IiwiY2hrTGNuIjoiIiwicmVzVG9rZW4iOiJmMTYxMmE4YzM5Y3ZjNDMwZjJvOXhiYjk2Y2M5NGY2M3ZjNDAxNDhlNGI5NCIsImNsaWVudElQIjoiMTcyLjE5LjI3LjM5IiwicmVzQ2hhbm5lbElEIjoiNSIsInJlc0NoYW5uZWxOYW1lIjoiVFBBUEkiLCJkZWZhdWx0VXNlck5hbWUiOiJhcGl0YXdmZWVxIiwibmJmIjoxNjgzMTk1MTM0LCJleHAiOjE2ODMxOTc1MzQsImlzcyI6ImZseWR1YmFpLmNvbSIsImF1ZCI6IlRBV0ZFRVFUUlZMU19GWl9OUCJ9._I0ZV--svLR1OqP0XLXV17rFF4itsd_2C9QzPwezZsc",
              stop: 1,
            },
            {
              tid: "xTbe5zlwA5kHfqpZ_2",
              ref: 2,
              ofcid: "",
              sdur: "15:25",
              re: "",
              sup: "5",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DXB",
                  dd: "2023-05-08T03:00:00",
                  ad: "2023-05-08T05:30:00",
                  rbd: "",
                  fn: "436",
                  trn: "",
                  eq: "73B",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "", arr: "2" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161457,
                  flightTime: 240,
                  fdur: "4:0",
                },
                {
                  ref: 2,
                  da: "DXB",
                  ar: "DOH",
                  dd: "2023-05-08T15:45:00",
                  ad: "2023-05-08T15:55:00",
                  rbd: "",
                  fn: "017",
                  trn: "",
                  eq: "73X",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "2", arr: "1" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161400,
                  flightTime: 70,
                  fdur: "1:10",
                },
              ],
              fareop: [
                {
                  fareKey: [],
                  pr: {
                    bf: 635,
                    tf: 915,
                    tax: [
                      { OT: 280 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    scur: "QAR",
                    ucur: "QAR",
                    exchange_rate: 1,
                    sup_fare: "635_915",
                    sup_cur_fare: "",
                    cabb: "Free",
                    chekb: "7kg INCLUDED",
                    meals: "Standard meal",
                    chanb: "Chargeable",
                    ftyp: "FLEX",
                    extra: "13_FLEX",
                    chekbcode: "BAGL",
                    chanb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Change of Flight is Free,NOSHOWUPTO 720 hrs of departure Change of Flight is Free,NOSHOWAFTER From 720 hrs before  of departure Change of Flight is Non-Refundable",
                    canb: "Chargeable",
                    cabb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Cancellation of Flight is Free,NOSHOWUPTO 720 hrs of departure Cancellation of Flight is Free,NOSHOWAFTER From 720 hrs before  of departure Cancellation of Flight is Non-Refundable",
                    bagage_info: "30kg BAG INCLUDED IN FARE",
                    markupnames: [],
                    ttf: 915,
                  },
                  paxpr: [
                    {
                      bf: 635,
                      tf: 915,
                      tax: [
                        { OT: 280 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "NRL8QA2",
                      ptype: "ADT",
                      scur: "QAR",
                      extra:
                        "1_44_N_1_9_0_tx-2186:10:PZ:0_4246:80:YQ:0_8947:0:BAGL:0_10386:10:ZR:0_11947:40:F6:0_12006:60:P2:0_12434:80:IN:0_",
                      ruleid: "3dhX2jDNbrZeg1CXOLzo5irh0x6swSbrCL0pg69gHUU=",
                      hsc: "f11ddf518116ba055f7420049e846f520069690007370935e50f34d5b9b88917",
                      sup_fare: "635_915_635",
                      cabb: "Free",
                      bagage_info: "30kg BAG INCLUDED IN FARE",
                      chekbcode: "BAGL",
                      psup_fare: "635_915",
                    },
                  ],
                  uniqueId: "FLEX",
                  routeId: "reqllpexzn",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey: [],
                  pr: {
                    bf: 1595,
                    tf: 2715,
                    tax: [
                      { OT: 1120 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    scur: "QAR",
                    ucur: "QAR",
                    exchange_rate: 1,
                    sup_fare: "1595_2715",
                    sup_cur_fare: "",
                    cabb: "Free",
                    chekb: "14kg INCLUDED",
                    meals: "Chargeable",
                    chanb: "Chargeable",
                    ftyp: "Business",
                    extra: "15_Business",
                    chekbcode: "JBAG",
                    chanb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Change of Flight is Free,NOSHOWUPTO 720 hrs of departure Change of Flight is Free,NOSHOWAFTER From 720 hrs before  of departure Change of Flight is Non-Refundable",
                    canb: "Chargeable",
                    cabb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Cancellation of Flight is Free,NOSHOWUPTO 720 hrs of departure Cancellation of Flight is Free,NOSHOWAFTER From 720 hrs before  of departure Cancellation of Flight is Non-Refundable",
                    bagage_info: "40kg Baggage allowance Business",
                    markupnames: [],
                    ttf: 2715,
                  },
                  paxpr: [
                    {
                      bf: 1595,
                      tf: 2715,
                      tax: [
                        { OT: 1120 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "ZR9QA2",
                      ptype: "ADT",
                      scur: "QAR",
                      extra:
                        "1_52_Z_1_9_0_tx-2186:10:PZ:0_4246:920:YQ:0_6544:0:JBAG:0_10386:10:ZR:0_11947:40:F6:0_12006:60:P2:0_12434:80:IN:0_",
                      ruleid: "DyZRPuNo5HGjhVTqSbTxcwt4fLJrC0i89TPad4f+u4k=",
                      hsc: "c627e4754653b97600ab8b7504dd975302e792141a6914c478e1488b2feb6ba0",
                      sup_fare: "1595_2715_1595",
                      cabb: "Free",
                      bagage_info: "40kg Baggage allowance Business",
                      chekbcode: "JBAG",
                      psup_fare: "1595_2715",
                    },
                  ],
                  uniqueId: "Business",
                  routeId: "hiew5e9do3",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "FZ",
              tecst: "no",
              cl: "Economy",
              extra: 13919296,
              token:
                "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiYXBpdGF3ZmVlcSIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL2dpdmVubmFtZSI6IkFQSSBUQVdGRUVRIiwiZ3JvdXBBY2Nlc3MiOiIzLDM2LDExMiIsImlzQXB0Q2xudCI6IkZhbHNlIiwibG9jYXRpb24iOiI5OTk5IiwiY2hrTGNuIjoiIiwicmVzVG9rZW4iOiJmMTYxMmE4YzM5Y3ZjNDMwZjJvOXhiYjk2Y2M5NGY2M3ZjNDAxNDhlNGI5NCIsImNsaWVudElQIjoiMTcyLjE5LjI3LjM5IiwicmVzQ2hhbm5lbElEIjoiNSIsInJlc0NoYW5uZWxOYW1lIjoiVFBBUEkiLCJkZWZhdWx0VXNlck5hbWUiOiJhcGl0YXdmZWVxIiwibmJmIjoxNjgzMTk1MTM0LCJleHAiOjE2ODMxOTc1MzQsImlzcyI6ImZseWR1YmFpLmNvbSIsImF1ZCI6IlRBV0ZFRVFUUlZMU19GWl9OUCJ9._I0ZV--svLR1OqP0XLXV17rFF4itsd_2C9QzPwezZsc",
              stop: 1,
            },
            {
              tid: "L9SbNKisixtinxDk_2",
              ref: 2,
              ofcid: "",
              sdur: "19:25",
              re: "",
              sup: "5",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DXB",
                  dd: "2023-05-08T03:00:00",
                  ad: "2023-05-08T05:30:00",
                  rbd: "",
                  fn: "436",
                  trn: "",
                  eq: "73B",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "", arr: "2" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161457,
                  flightTime: 240,
                  fdur: "4:0",
                },
                {
                  ref: 2,
                  da: "DXB",
                  ar: "DOH",
                  dd: "2023-05-08T19:45:00",
                  ad: "2023-05-08T19:55:00",
                  rbd: "",
                  fn: "005",
                  trn: "",
                  eq: "73X",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "2", arr: "1" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161381,
                  flightTime: 70,
                  fdur: "1:10",
                },
              ],
              fareop: [
                {
                  fareKey: [],
                  pr: {
                    bf: 635,
                    tf: 915,
                    tax: [
                      { OT: 280 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    scur: "QAR",
                    ucur: "QAR",
                    exchange_rate: 1,
                    sup_fare: "635_915",
                    sup_cur_fare: "",
                    cabb: "Free",
                    chekb: "7kg INCLUDED",
                    meals: "Standard meal",
                    chanb: "Chargeable",
                    ftyp: "FLEX",
                    extra: "13_FLEX",
                    chekbcode: "BAGL",
                    chanb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Change of Flight is Free,NOSHOWUPTO 720 hrs of departure Change of Flight is Free,NOSHOWAFTER From 720 hrs before  of departure Change of Flight is Non-Refundable",
                    canb: "Chargeable",
                    cabb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Cancellation of Flight is Free,NOSHOWUPTO 720 hrs of departure Cancellation of Flight is Free,NOSHOWAFTER From 720 hrs before  of departure Cancellation of Flight is Non-Refundable",
                    bagage_info: "30kg BAG INCLUDED IN FARE",
                    markupnames: [],
                    ttf: 915,
                  },
                  paxpr: [
                    {
                      bf: 635,
                      tf: 915,
                      tax: [
                        { OT: 280 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "NRL8QA2",
                      ptype: "ADT",
                      scur: "QAR",
                      extra:
                        "1_76_N_1_9_0_tx-2186:10:PZ:0_4246:80:YQ:0_8947:0:BAGL:0_10386:10:ZR:0_11947:40:F6:0_12006:60:P2:0_12434:80:IN:0_",
                      ruleid: "i8SxjJOEO/FEzXcvATN2Hyrh0x6swSbrCL0pg69gHUU=",
                      hsc: "3ca0c3e0d2806c74028852ab6ff136bfe3dd055188385414f3ac0a625ba9025b",
                      sup_fare: "635_915_635",
                      cabb: "Free",
                      bagage_info: "30kg BAG INCLUDED IN FARE",
                      chekbcode: "BAGL",
                      psup_fare: "635_915",
                    },
                  ],
                  uniqueId: "FLEX",
                  routeId: "ucfuxmbgwy",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey: [],
                  pr: {
                    bf: 1595,
                    tf: 2715,
                    tax: [
                      { OT: 1120 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    scur: "QAR",
                    ucur: "QAR",
                    exchange_rate: 1,
                    sup_fare: "1595_2715",
                    sup_cur_fare: "",
                    cabb: "Free",
                    chekb: "14kg INCLUDED",
                    meals: "Chargeable",
                    chanb: "Chargeable",
                    ftyp: "Business",
                    extra: "15_Business",
                    chekbcode: "JBAG",
                    chanb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Change of Flight is Free,NOSHOWUPTO 720 hrs of departure Change of Flight is Free,NOSHOWAFTER From 720 hrs before  of departure Change of Flight is Non-Refundable",
                    canb: "Chargeable",
                    cabb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Cancellation of Flight is Free,NOSHOWUPTO 720 hrs of departure Cancellation of Flight is Free,NOSHOWAFTER From 720 hrs before  of departure Cancellation of Flight is Non-Refundable",
                    bagage_info: "40kg Baggage allowance Business",
                    markupnames: [],
                    ttf: 2715,
                  },
                  paxpr: [
                    {
                      bf: 1595,
                      tf: 2715,
                      tax: [
                        { OT: 1120 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "ZR9QA2",
                      ptype: "ADT",
                      scur: "QAR",
                      extra:
                        "1_84_Z_1_9_0_tx-2186:10:PZ:0_4246:920:YQ:0_6544:0:JBAG:0_10386:10:ZR:0_11947:40:F6:0_12006:60:P2:0_12434:80:IN:0_",
                      ruleid: "gziYdA6HKSkGuxiubH8k2At4fLJrC0i89TPad4f+u4k=",
                      hsc: "d96bcc415effba457da916de22588003e44d644acb62e416b349d81b45ed3a71",
                      sup_fare: "1595_2715_1595",
                      cabb: "Free",
                      bagage_info: "40kg Baggage allowance Business",
                      chekbcode: "JBAG",
                      psup_fare: "1595_2715",
                    },
                  ],
                  uniqueId: "Business",
                  routeId: "qvnl2c5zzs",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "FZ",
              tecst: "no",
              cl: "Economy",
              extra: 13919294,
              token:
                "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiYXBpdGF3ZmVlcSIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL2dpdmVubmFtZSI6IkFQSSBUQVdGRUVRIiwiZ3JvdXBBY2Nlc3MiOiIzLDM2LDExMiIsImlzQXB0Q2xudCI6IkZhbHNlIiwibG9jYXRpb24iOiI5OTk5IiwiY2hrTGNuIjoiIiwicmVzVG9rZW4iOiJmMTYxMmE4YzM5Y3ZjNDMwZjJvOXhiYjk2Y2M5NGY2M3ZjNDAxNDhlNGI5NCIsImNsaWVudElQIjoiMTcyLjE5LjI3LjM5IiwicmVzQ2hhbm5lbElEIjoiNSIsInJlc0NoYW5uZWxOYW1lIjoiVFBBUEkiLCJkZWZhdWx0VXNlck5hbWUiOiJhcGl0YXdmZWVxIiwibmJmIjoxNjgzMTk1MTM0LCJleHAiOjE2ODMxOTc1MzQsImlzcyI6ImZseWR1YmFpLmNvbSIsImF1ZCI6IlRBV0ZFRVFUUlZMU19GWl9OUCJ9._I0ZV--svLR1OqP0XLXV17rFF4itsd_2C9QzPwezZsc",
              stop: 1,
            },
          ],
          servicefee: [],
        },
        {
          srcorigns: [
            {
              tid: "eg80T7aZhal2T8sN_1",
              ref: 1,
              ofcid: "",
              sdur: "6:30",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "HYD",
                  dd: "2023-05-06T02:00:00",
                  ad: "2023-05-06T08:30:00",
                  rbd: "",
                  fn: "1726",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: {},
                  rph: "",
                  jsell:
                    "6E~1726~ ~~DOH~05/06/2023 02:00~HYD~05/06/2023 08:30~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey: "0~R~ ~6E~R0INT~5000~~0~4~~X",
                  pr: {
                    bf: 330,
                    tf: 479,
                    tax: [
                      { OT: 149 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "330_479",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 479,
                  },
                  paxpr: [
                    {
                      bf: 330,
                      tf: 479,
                      tax: [
                        { OT: 149 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "R0INT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "330_479",
                      psup_fare: "330_479",
                    },
                  ],
                  routeId: "z5ihtzwn1r",
                  productClass: "R",
                  ruleNumber: "5000",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey: "0~R~ ~6E~RUINT~5003~~0~10~~X",
                  pr: {
                    bf: 361,
                    tf: 511,
                    tax: [
                      { OT: 150 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "361_511",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 511,
                  },
                  paxpr: [
                    {
                      bf: 361,
                      tf: 511,
                      tax: [
                        { OT: 150 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "RUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "361_511",
                      psup_fare: "361_511",
                    },
                  ],
                  routeId: "s5td3fk4bp",
                  productClass: "J",
                  ruleNumber: "5003",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "gV9VlJcF4lo=|c8vLGEjg5j7NNr8U4/n4JN2DKZGLTH99mWKIwdR5uRWciziCl+wbZTrpWnMmGN+Yy9uJ4ZUTuD0HpLhkPra8oFmlLXu64hUIzZhRraKsuKvdW8dDCeFMBF8RTGVhnAM8gqCD8NTV8Ig=",
              stop: 0,
            },
            {
              tid: "C3yQaNuOxuNQEi7l_1",
              ref: 1,
              ofcid: "",
              sdur: "6:20",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "HYD",
                  dd: "2023-05-06T20:10:00",
                  ad: "2023-05-07T02:30:00",
                  rbd: "",
                  fn: "1714",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: {},
                  rph: "",
                  jsell:
                    "6E~1714~ ~~DOH~05/06/2023 20:10~HYD~05/07/2023 02:30~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey: "0~R~ ~6E~R0INT~5000~~0~4~~X",
                  pr: {
                    bf: 330,
                    tf: 479,
                    tax: [
                      { OT: 149 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "330_479",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 479,
                  },
                  paxpr: [
                    {
                      bf: 330,
                      tf: 479,
                      tax: [
                        { OT: 149 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "R0INT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "330_479",
                      psup_fare: "330_479",
                    },
                  ],
                  routeId: "t4qxdt4cr8k",
                  productClass: "R",
                  ruleNumber: "5000",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey: "0~R~ ~6E~RUINT~5003~~0~10~~X",
                  pr: {
                    bf: 361,
                    tf: 511,
                    tax: [
                      { OT: 150 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "361_511",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 511,
                  },
                  paxpr: [
                    {
                      bf: 361,
                      tf: 511,
                      tax: [
                        { OT: 150 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "RUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "361_511",
                      psup_fare: "361_511",
                    },
                  ],
                  routeId: "wgou307ta7",
                  productClass: "J",
                  ruleNumber: "5003",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "gV9VlJcF4lo=|c8vLGEjg5j7NNr8U4/n4JN2DKZGLTH99mWKIwdR5uRWciziCl+wbZTrpWnMmGN+Yy9uJ4ZUTuD0HpLhkPra8oFmlLXu64hUIzZhRraKsuKvdW8dDCeFMBF8RTGVhnAM8gqCD8NTV8Ig=",
              stop: 0,
            },
          ],
          destorigins: [
            {
              tid: "3L5ttr6qFhfPlXbN_2",
              ref: 2,
              ofcid: "",
              sdur: "13:40",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DEL",
                  dd: "2023-05-08T09:55:00",
                  ad: "2023-05-08T12:10:00",
                  rbd: "",
                  fn: "2511",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "2" },
                  rph: "",
                  jsell:
                    "6E~2511~ ~~HYD~05/08/2023 09:55~DEL~05/08/2023 12:10~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-05-08T22:00:00",
                  ad: "2023-05-08T23:35:00",
                  rbd: "",
                  fn: "1736",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "3" },
                  rph: "",
                  jsell:
                    "6E~1736~ ~~DEL~05/08/2023 22:00~DOH~05/08/2023 23:35~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~A~ ~6E~ACINT~6200~~0~66~~X^0~A~ ~6E~ACINT~6200~~0~66~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 1180,
                      fbc: "ACINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1180_1413",
                      tax: [
                        { OT: 233 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 1413,
                      psup_fare: "1180_1413",
                    },
                  ],
                  pr: {
                    bf: 1180,
                    tf: 1413,
                    tax: [
                      { OT: 233 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1180_1413",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 1413,
                  },
                  routeId: "kqii9f7cve",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~A~ ~6E~ACUINT~6202~~0~4~~X^0~A~ ~6E~ACUINT~6202~~0~4~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 1210,
                      fbc: "ACUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1210_1446",
                      tax: [
                        { OT: 236 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 1446,
                      psup_fare: "1210_1446",
                    },
                  ],
                  pr: {
                    bf: 1210,
                    tf: 1446,
                    tax: [
                      { OT: 236 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1210_1446",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 1446,
                  },
                  routeId: "jck5gc0qnt",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "gV9VlJcF4lo=|c8vLGEjg5j7NNr8U4/n4JN2DKZGLTH99mWKIwdR5uRWciziCl+wbZTrpWnMmGN+Yy9uJ4ZUTuD0HpLhkPra8oFmlLXu64hUIzZhRraKsuKvdW8dDCeFMBF8RTGVhnAM8gqCD8NTV8Ig=",
              stop: 1,
            },
            {
              tid: "ZfJVoFxx1sdookIh_2",
              ref: 2,
              ofcid: "",
              sdur: "11:55",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DEL",
                  dd: "2023-05-08T11:40:00",
                  ad: "2023-05-08T13:55:00",
                  rbd: "",
                  fn: "2171",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "2" },
                  rph: "",
                  jsell:
                    "6E~2171~ ~~HYD~05/08/2023 11:40~DEL~05/08/2023 13:55~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-05-08T22:00:00",
                  ad: "2023-05-08T23:35:00",
                  rbd: "",
                  fn: "1736",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "3" },
                  rph: "",
                  jsell:
                    "6E~1736~ ~~DEL~05/08/2023 22:00~DOH~05/08/2023 23:35~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~A~ ~6E~ACINT~6200~~0~66~~X^0~A~ ~6E~ACINT~6200~~0~66~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 1180,
                      fbc: "ACINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1180_1413",
                      tax: [
                        { OT: 233 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 1413,
                      psup_fare: "1180_1413",
                    },
                  ],
                  pr: {
                    bf: 1180,
                    tf: 1413,
                    tax: [
                      { OT: 233 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1180_1413",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 1413,
                  },
                  routeId: "azby7iojsv",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~A~ ~6E~ACUINT~6202~~0~4~~X^0~A~ ~6E~ACUINT~6202~~0~4~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 1210,
                      fbc: "ACUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1210_1446",
                      tax: [
                        { OT: 236 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 1446,
                      psup_fare: "1210_1446",
                    },
                  ],
                  pr: {
                    bf: 1210,
                    tf: 1446,
                    tax: [
                      { OT: 236 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1210_1446",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 1446,
                  },
                  routeId: "dtpwqa9fd",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "gV9VlJcF4lo=|c8vLGEjg5j7NNr8U4/n4JN2DKZGLTH99mWKIwdR5uRWciziCl+wbZTrpWnMmGN+Yy9uJ4ZUTuD0HpLhkPra8oFmlLXu64hUIzZhRraKsuKvdW8dDCeFMBF8RTGVhnAM8gqCD8NTV8Ig=",
              stop: 1,
            },
            {
              tid: "NAOXV9KLNreH2AyP_2",
              ref: 2,
              ofcid: "",
              sdur: "9:35",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DEL",
                  dd: "2023-05-08T14:00:00",
                  ad: "2023-05-08T16:15:00",
                  rbd: "",
                  fn: "2063",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "2" },
                  rph: "",
                  jsell:
                    "6E~2063~ ~~HYD~05/08/2023 14:00~DEL~05/08/2023 16:15~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-05-08T22:00:00",
                  ad: "2023-05-08T23:35:00",
                  rbd: "",
                  fn: "1736",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "3" },
                  rph: "",
                  jsell:
                    "6E~1736~ ~~DEL~05/08/2023 22:00~DOH~05/08/2023 23:35~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~A~ ~6E~ACINT~6200~~0~66~~X^0~A~ ~6E~ACINT~6200~~0~66~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 1180,
                      fbc: "ACINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1180_1413",
                      tax: [
                        { OT: 233 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 1413,
                      psup_fare: "1180_1413",
                    },
                  ],
                  pr: {
                    bf: 1180,
                    tf: 1413,
                    tax: [
                      { OT: 233 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1180_1413",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 1413,
                  },
                  routeId: "vaix7o94fj",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~A~ ~6E~ACUINT~6202~~0~4~~X^0~A~ ~6E~ACUINT~6202~~0~4~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 1210,
                      fbc: "ACUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1210_1446",
                      tax: [
                        { OT: 236 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 1446,
                      psup_fare: "1210_1446",
                    },
                  ],
                  pr: {
                    bf: 1210,
                    tf: 1446,
                    tax: [
                      { OT: 236 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1210_1446",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 1446,
                  },
                  routeId: "xogffg5i52",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "gV9VlJcF4lo=|c8vLGEjg5j7NNr8U4/n4JN2DKZGLTH99mWKIwdR5uRWciziCl+wbZTrpWnMmGN+Yy9uJ4ZUTuD0HpLhkPra8oFmlLXu64hUIzZhRraKsuKvdW8dDCeFMBF8RTGVhnAM8gqCD8NTV8Ig=",
              stop: 1,
            },
            {
              tid: "5eJmJUx78SLdZiBF_2",
              ref: 2,
              ofcid: "",
              sdur: "13:25",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DEL",
                  dd: "2023-05-08T16:20:00",
                  ad: "2023-05-08T18:35:00",
                  rbd: "",
                  fn: "2069",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "2" },
                  rph: "",
                  jsell:
                    "6E~2069~ ~~HYD~05/08/2023 16:20~DEL~05/08/2023 18:35~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-05-09T04:10:00",
                  ad: "2023-05-09T05:45:00",
                  rbd: "",
                  fn: "1701",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "3" },
                  rph: "",
                  jsell:
                    "6E~1701~ ~~DEL~05/09/2023 04:10~DOH~05/09/2023 05:45~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~A~ ~6E~ACINT~6200~~0~66~~X^0~A~ ~6E~ACINT~6200~~0~66~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 1180,
                      fbc: "ACINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1180_1413",
                      tax: [
                        { OT: 233 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 1413,
                      psup_fare: "1180_1413",
                    },
                  ],
                  pr: {
                    bf: 1180,
                    tf: 1413,
                    tax: [
                      { OT: 233 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1180_1413",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 1413,
                  },
                  routeId: "ctr5nmrlbt",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~A~ ~6E~ACUINT~6202~~0~4~~X^0~A~ ~6E~ACUINT~6202~~0~4~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 1210,
                      fbc: "ACUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1210_1446",
                      tax: [
                        { OT: 236 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 1446,
                      psup_fare: "1210_1446",
                    },
                  ],
                  pr: {
                    bf: 1210,
                    tf: 1446,
                    tax: [
                      { OT: 236 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1210_1446",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 1446,
                  },
                  routeId: "4za2hd45px",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "gV9VlJcF4lo=|c8vLGEjg5j7NNr8U4/n4JN2DKZGLTH99mWKIwdR5uRWciziCl+wbZTrpWnMmGN+Yy9uJ4ZUTuD0HpLhkPra8oFmlLXu64hUIzZhRraKsuKvdW8dDCeFMBF8RTGVhnAM8gqCD8NTV8Ig=",
              stop: 1,
            },
            {
              tid: "l26MxB5blVexNZWN_2",
              ref: 2,
              ofcid: "",
              sdur: "7:15",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DEL",
                  dd: "2023-05-08T16:20:00",
                  ad: "2023-05-08T18:35:00",
                  rbd: "",
                  fn: "2069",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "2" },
                  rph: "",
                  jsell:
                    "6E~2069~ ~~HYD~05/08/2023 16:20~DEL~05/08/2023 18:35~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-05-08T22:00:00",
                  ad: "2023-05-08T23:35:00",
                  rbd: "",
                  fn: "1736",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "3" },
                  rph: "",
                  jsell:
                    "6E~1736~ ~~DEL~05/08/2023 22:00~DOH~05/08/2023 23:35~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~A~ ~6E~ACINT~6200~~0~66~~X^0~A~ ~6E~ACINT~6200~~0~66~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 1180,
                      fbc: "ACINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1180_1413",
                      tax: [
                        { OT: 233 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 1413,
                      psup_fare: "1180_1413",
                    },
                  ],
                  pr: {
                    bf: 1180,
                    tf: 1413,
                    tax: [
                      { OT: 233 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1180_1413",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 1413,
                  },
                  routeId: "bwnxlwieae",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~A~ ~6E~ACUINT~6202~~0~4~~X^0~A~ ~6E~ACUINT~6202~~0~4~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 1210,
                      fbc: "ACUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1210_1446",
                      tax: [
                        { OT: 236 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 1446,
                      psup_fare: "1210_1446",
                    },
                  ],
                  pr: {
                    bf: 1210,
                    tf: 1446,
                    tax: [
                      { OT: 236 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1210_1446",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 1446,
                  },
                  routeId: "u00fmddlmr",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "gV9VlJcF4lo=|c8vLGEjg5j7NNr8U4/n4JN2DKZGLTH99mWKIwdR5uRWciziCl+wbZTrpWnMmGN+Yy9uJ4ZUTuD0HpLhkPra8oFmlLXu64hUIzZhRraKsuKvdW8dDCeFMBF8RTGVhnAM8gqCD8NTV8Ig=",
              stop: 1,
            },
            {
              tid: "LhT7S5GsJGAyl2Ec_2",
              ref: 2,
              ofcid: "",
              sdur: "7:5",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DEL",
                  dd: "2023-05-08T16:30:00",
                  ad: "2023-05-08T18:55:00",
                  rbd: "",
                  fn: "6606",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: {},
                  rph: "",
                  jsell:
                    "6E~6606~ ~~HYD~05/08/2023 16:30~DEL~05/08/2023 18:55~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-05-08T22:00:00",
                  ad: "2023-05-08T23:35:00",
                  rbd: "",
                  fn: "1736",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "3" },
                  rph: "",
                  jsell:
                    "6E~1736~ ~~DEL~05/08/2023 22:00~DOH~05/08/2023 23:35~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~A~ ~6E~ACINT~6200~~0~66~~X^0~A~ ~6E~ACINT~6200~~0~66~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 1180,
                      fbc: "ACINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1180_1413",
                      tax: [
                        { OT: 233 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 1413,
                      psup_fare: "1180_1413",
                    },
                  ],
                  pr: {
                    bf: 1180,
                    tf: 1413,
                    tax: [
                      { OT: 233 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1180_1413",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 1413,
                  },
                  routeId: "skztmcmokd",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~A~ ~6E~ACUINT~6202~~0~4~~X^0~A~ ~6E~ACUINT~6202~~0~4~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 1210,
                      fbc: "ACUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1210_1446",
                      tax: [
                        { OT: 236 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 1446,
                      psup_fare: "1210_1446",
                    },
                  ],
                  pr: {
                    bf: 1210,
                    tf: 1446,
                    tax: [
                      { OT: 236 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1210_1446",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 1446,
                  },
                  routeId: "1d5japu0upi",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "gV9VlJcF4lo=|c8vLGEjg5j7NNr8U4/n4JN2DKZGLTH99mWKIwdR5uRWciziCl+wbZTrpWnMmGN+Yy9uJ4ZUTuD0HpLhkPra8oFmlLXu64hUIzZhRraKsuKvdW8dDCeFMBF8RTGVhnAM8gqCD8NTV8Ig=",
              stop: 1,
            },
            {
              tid: "wcCMaoIIPTwOOOtW_2",
              ref: 2,
              ofcid: "",
              sdur: "13:15",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DEL",
                  dd: "2023-05-08T16:30:00",
                  ad: "2023-05-08T18:55:00",
                  rbd: "",
                  fn: "6606",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: {},
                  rph: "",
                  jsell:
                    "6E~6606~ ~~HYD~05/08/2023 16:30~DEL~05/08/2023 18:55~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-05-09T04:10:00",
                  ad: "2023-05-09T05:45:00",
                  rbd: "",
                  fn: "1701",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "3" },
                  rph: "",
                  jsell:
                    "6E~1701~ ~~DEL~05/09/2023 04:10~DOH~05/09/2023 05:45~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~A~ ~6E~ACINT~6200~~0~66~~X^0~A~ ~6E~ACINT~6200~~0~66~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 1180,
                      fbc: "ACINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1180_1413",
                      tax: [
                        { OT: 233 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 1413,
                      psup_fare: "1180_1413",
                    },
                  ],
                  pr: {
                    bf: 1180,
                    tf: 1413,
                    tax: [
                      { OT: 233 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1180_1413",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 1413,
                  },
                  routeId: "eh0nrctzmd",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~A~ ~6E~ACUINT~6202~~0~4~~X^0~A~ ~6E~ACUINT~6202~~0~4~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 1210,
                      fbc: "ACUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1210_1446",
                      tax: [
                        { OT: 236 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 1446,
                      psup_fare: "1210_1446",
                    },
                  ],
                  pr: {
                    bf: 1210,
                    tf: 1446,
                    tax: [
                      { OT: 236 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1210_1446",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 1446,
                  },
                  routeId: "avydmfylrr",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "gV9VlJcF4lo=|c8vLGEjg5j7NNr8U4/n4JN2DKZGLTH99mWKIwdR5uRWciziCl+wbZTrpWnMmGN+Yy9uJ4ZUTuD0HpLhkPra8oFmlLXu64hUIzZhRraKsuKvdW8dDCeFMBF8RTGVhnAM8gqCD8NTV8Ig=",
              stop: 1,
            },
            {
              tid: "T8XHQgD0MQseWHFv_2",
              ref: 2,
              ofcid: "",
              sdur: "12:0",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DEL",
                  dd: "2023-05-08T17:45:00",
                  ad: "2023-05-08T20:00:00",
                  rbd: "",
                  fn: "2135",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "2" },
                  rph: "",
                  jsell:
                    "6E~2135~ ~~HYD~05/08/2023 17:45~DEL~05/08/2023 20:00~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-05-09T04:10:00",
                  ad: "2023-05-09T05:45:00",
                  rbd: "",
                  fn: "1701",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "3" },
                  rph: "",
                  jsell:
                    "6E~1701~ ~~DEL~05/09/2023 04:10~DOH~05/09/2023 05:45~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~A~ ~6E~ACINT~6200~~0~66~~X^0~A~ ~6E~ACINT~6200~~0~66~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 1180,
                      fbc: "ACINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1180_1413",
                      tax: [
                        { OT: 233 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 1413,
                      psup_fare: "1180_1413",
                    },
                  ],
                  pr: {
                    bf: 1180,
                    tf: 1413,
                    tax: [
                      { OT: 233 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1180_1413",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 1413,
                  },
                  routeId: "wqqhph1kyf",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~A~ ~6E~ACUINT~6202~~0~4~~X^0~A~ ~6E~ACUINT~6202~~0~4~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 1210,
                      fbc: "ACUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1210_1446",
                      tax: [
                        { OT: 236 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 1446,
                      psup_fare: "1210_1446",
                    },
                  ],
                  pr: {
                    bf: 1210,
                    tf: 1446,
                    tax: [
                      { OT: 236 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1210_1446",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 1446,
                  },
                  routeId: "n7xtf3aiql",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "gV9VlJcF4lo=|c8vLGEjg5j7NNr8U4/n4JN2DKZGLTH99mWKIwdR5uRWciziCl+wbZTrpWnMmGN+Yy9uJ4ZUTuD0HpLhkPra8oFmlLXu64hUIzZhRraKsuKvdW8dDCeFMBF8RTGVhnAM8gqCD8NTV8Ig=",
              stop: 1,
            },
            {
              tid: "yxeDTCc5r3qh60If_2",
              ref: 2,
              ofcid: "",
              sdur: "5:50",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DEL",
                  dd: "2023-05-08T17:45:00",
                  ad: "2023-05-08T20:00:00",
                  rbd: "",
                  fn: "2135",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "2" },
                  rph: "",
                  jsell:
                    "6E~2135~ ~~HYD~05/08/2023 17:45~DEL~05/08/2023 20:00~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-05-08T22:00:00",
                  ad: "2023-05-08T23:35:00",
                  rbd: "",
                  fn: "1736",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "3" },
                  rph: "",
                  jsell:
                    "6E~1736~ ~~DEL~05/08/2023 22:00~DOH~05/08/2023 23:35~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~A~ ~6E~ACINT~6200~~0~66~~X^0~A~ ~6E~ACINT~6200~~0~66~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 1180,
                      fbc: "ACINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1180_1413",
                      tax: [
                        { OT: 233 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 1413,
                      psup_fare: "1180_1413",
                    },
                  ],
                  pr: {
                    bf: 1180,
                    tf: 1413,
                    tax: [
                      { OT: 233 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1180_1413",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 1413,
                  },
                  routeId: "97y2stlmqkj",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~A~ ~6E~ACUINT~6202~~0~4~~X^0~A~ ~6E~ACUINT~6202~~0~4~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 1210,
                      fbc: "ACUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1210_1446",
                      tax: [
                        { OT: 236 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 1446,
                      psup_fare: "1210_1446",
                    },
                  ],
                  pr: {
                    bf: 1210,
                    tf: 1446,
                    tax: [
                      { OT: 236 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1210_1446",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 1446,
                  },
                  routeId: "97y7obuqr8",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "gV9VlJcF4lo=|c8vLGEjg5j7NNr8U4/n4JN2DKZGLTH99mWKIwdR5uRWciziCl+wbZTrpWnMmGN+Yy9uJ4ZUTuD0HpLhkPra8oFmlLXu64hUIzZhRraKsuKvdW8dDCeFMBF8RTGVhnAM8gqCD8NTV8Ig=",
              stop: 1,
            },
            {
              tid: "dfXS5GBAJVQMitvI_2",
              ref: 2,
              ofcid: "",
              sdur: "9:40",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DEL",
                  dd: "2023-05-08T20:05:00",
                  ad: "2023-05-08T22:20:00",
                  rbd: "",
                  fn: "2106",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "2" },
                  rph: "",
                  jsell:
                    "6E~2106~ ~~HYD~05/08/2023 20:05~DEL~05/08/2023 22:20~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-05-09T04:10:00",
                  ad: "2023-05-09T05:45:00",
                  rbd: "",
                  fn: "1701",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "3" },
                  rph: "",
                  jsell:
                    "6E~1701~ ~~DEL~05/09/2023 04:10~DOH~05/09/2023 05:45~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~A~ ~6E~ACINT~6200~~0~66~~X^0~A~ ~6E~ACINT~6200~~0~66~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 1180,
                      fbc: "ACINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1180_1413",
                      tax: [
                        { OT: 233 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 1413,
                      psup_fare: "1180_1413",
                    },
                  ],
                  pr: {
                    bf: 1180,
                    tf: 1413,
                    tax: [
                      { OT: 233 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1180_1413",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 1413,
                  },
                  routeId: "r056yao6xih",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~A~ ~6E~ACUINT~6202~~0~4~~X^0~A~ ~6E~ACUINT~6202~~0~4~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 1210,
                      fbc: "ACUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1210_1446",
                      tax: [
                        { OT: 236 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 1446,
                      psup_fare: "1210_1446",
                    },
                  ],
                  pr: {
                    bf: 1210,
                    tf: 1446,
                    tax: [
                      { OT: 236 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1210_1446",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 1446,
                  },
                  routeId: "4gvvm7hk7d",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "gV9VlJcF4lo=|c8vLGEjg5j7NNr8U4/n4JN2DKZGLTH99mWKIwdR5uRWciziCl+wbZTrpWnMmGN+Yy9uJ4ZUTuD0HpLhkPra8oFmlLXu64hUIzZhRraKsuKvdW8dDCeFMBF8RTGVhnAM8gqCD8NTV8Ig=",
              stop: 1,
            },
            {
              tid: "cxMAPtggnypvB51E_2",
              ref: 2,
              ofcid: "",
              sdur: "9:30",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DEL",
                  dd: "2023-05-08T20:15:00",
                  ad: "2023-05-08T22:40:00",
                  rbd: "",
                  fn: "2213",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "2" },
                  rph: "",
                  jsell:
                    "6E~2213~ ~~HYD~05/08/2023 20:15~DEL~05/08/2023 22:40~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-05-09T04:10:00",
                  ad: "2023-05-09T05:45:00",
                  rbd: "",
                  fn: "1701",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "3" },
                  rph: "",
                  jsell:
                    "6E~1701~ ~~DEL~05/09/2023 04:10~DOH~05/09/2023 05:45~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~A~ ~6E~ACINT~6200~~0~66~~X^0~A~ ~6E~ACINT~6200~~0~66~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 1180,
                      fbc: "ACINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1180_1413",
                      tax: [
                        { OT: 233 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 1413,
                      psup_fare: "1180_1413",
                    },
                  ],
                  pr: {
                    bf: 1180,
                    tf: 1413,
                    tax: [
                      { OT: 233 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1180_1413",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 1413,
                  },
                  routeId: "nyxhqa6zrb",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~A~ ~6E~ACUINT~6202~~0~4~~X^0~A~ ~6E~ACUINT~6202~~0~4~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 1210,
                      fbc: "ACUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1210_1446",
                      tax: [
                        { OT: 236 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 1446,
                      psup_fare: "1210_1446",
                    },
                  ],
                  pr: {
                    bf: 1210,
                    tf: 1446,
                    tax: [
                      { OT: 236 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1210_1446",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 1446,
                  },
                  routeId: "ia2ent36f6",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "gV9VlJcF4lo=|c8vLGEjg5j7NNr8U4/n4JN2DKZGLTH99mWKIwdR5uRWciziCl+wbZTrpWnMmGN+Yy9uJ4ZUTuD0HpLhkPra8oFmlLXu64hUIzZhRraKsuKvdW8dDCeFMBF8RTGVhnAM8gqCD8NTV8Ig=",
              stop: 1,
            },
            {
              tid: "uZaFP2eyaLGKHy6D_2",
              ref: 2,
              ofcid: "",
              sdur: "8:25",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DEL",
                  dd: "2023-05-08T21:20:00",
                  ad: "2023-05-08T23:30:00",
                  rbd: "",
                  fn: "6684",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "1" },
                  rph: "",
                  jsell:
                    "6E~6684~ ~~HYD~05/08/2023 21:20~DEL~05/08/2023 23:30~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-05-09T04:10:00",
                  ad: "2023-05-09T05:45:00",
                  rbd: "",
                  fn: "1701",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "3" },
                  rph: "",
                  jsell:
                    "6E~1701~ ~~DEL~05/09/2023 04:10~DOH~05/09/2023 05:45~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~A~ ~6E~ACINT~6200~~0~66~~X^0~A~ ~6E~ACINT~6200~~0~66~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 1180,
                      fbc: "ACINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1180_1413",
                      tax: [
                        { OT: 233 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 1413,
                      psup_fare: "1180_1413",
                    },
                  ],
                  pr: {
                    bf: 1180,
                    tf: 1413,
                    tax: [
                      { OT: 233 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1180_1413",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 1413,
                  },
                  routeId: "e9ec6yydrx",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~A~ ~6E~ACUINT~6202~~0~4~~X^0~A~ ~6E~ACUINT~6202~~0~4~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 1210,
                      fbc: "ACUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1210_1446",
                      tax: [
                        { OT: 236 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 1446,
                      psup_fare: "1210_1446",
                    },
                  ],
                  pr: {
                    bf: 1210,
                    tf: 1446,
                    tax: [
                      { OT: 236 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1210_1446",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 1446,
                  },
                  routeId: "btibg06re7",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "gV9VlJcF4lo=|c8vLGEjg5j7NNr8U4/n4JN2DKZGLTH99mWKIwdR5uRWciziCl+wbZTrpWnMmGN+Yy9uJ4ZUTuD0HpLhkPra8oFmlLXu64hUIzZhRraKsuKvdW8dDCeFMBF8RTGVhnAM8gqCD8NTV8Ig=",
              stop: 1,
            },
            {
              tid: "BBApytxhwvUhiXME_2",
              ref: 2,
              ofcid: "",
              sdur: "7:10",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DEL",
                  dd: "2023-05-08T22:35:00",
                  ad: "2023-05-09T00:45:00",
                  rbd: "",
                  fn: "2324",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "2" },
                  rph: "",
                  jsell:
                    "6E~2324~ ~~HYD~05/08/2023 22:35~DEL~05/09/2023 00:45~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-05-09T04:10:00",
                  ad: "2023-05-09T05:45:00",
                  rbd: "",
                  fn: "1701",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "3" },
                  rph: "",
                  jsell:
                    "6E~1701~ ~~DEL~05/09/2023 04:10~DOH~05/09/2023 05:45~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~A~ ~6E~ACINT~6200~~0~66~~X^0~A~ ~6E~ACINT~6200~~0~66~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 1180,
                      fbc: "ACINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1180_1413",
                      tax: [
                        { OT: 233 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 1413,
                      psup_fare: "1180_1413",
                    },
                  ],
                  pr: {
                    bf: 1180,
                    tf: 1413,
                    tax: [
                      { OT: 233 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1180_1413",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 1413,
                  },
                  routeId: "fgpblegoe9l",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~A~ ~6E~ACUINT~6202~~0~4~~X^0~A~ ~6E~ACUINT~6202~~0~4~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 1210,
                      fbc: "ACUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1210_1446",
                      tax: [
                        { OT: 236 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 1446,
                      psup_fare: "1210_1446",
                    },
                  ],
                  pr: {
                    bf: 1210,
                    tf: 1446,
                    tax: [
                      { OT: 236 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1210_1446",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 1446,
                  },
                  routeId: "dj020pat4c",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "gV9VlJcF4lo=|c8vLGEjg5j7NNr8U4/n4JN2DKZGLTH99mWKIwdR5uRWciziCl+wbZTrpWnMmGN+Yy9uJ4ZUTuD0HpLhkPra8oFmlLXu64hUIzZhRraKsuKvdW8dDCeFMBF8RTGVhnAM8gqCD8NTV8Ig=",
              stop: 1,
            },
          ],
          paxpr: [
            {
              bf: 1510,
              tf: 1892,
              tax: [
                { OT: 382 },
                { attmkp: 0 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              fbc: "R0INT",
              ptype: "ADT",
              scur: "QAR",
              sup_fare: "330_479",
              psup_fare: "1510_1892",
            },
          ],
          pr: {
            bf: 1510,
            tf: 1892,
            tax: [
              { OT: 382 },
              { attmkp: 0 },
              { tamkp: 0 },
              { stamkp: 0 },
              { attdisc: 0 },
              { tadisc: 0 },
              { stadisc: 0 },
            ],
            ucur: "QAR",
            exchange_rate: 1,
            scur: "QAR",
            sup_fare: "1510_1892",
            sup_cur_fare: "",
            cabb: "",
            chekb: "",
            seats: "",
            meals: "",
            chanb: "",
            ftyp: "Regular - R",
            markupnames: [],
            ttf: 1892,
          },
          sup: "4",
          airlineList: "6E",
          servicefee: [],
        },
        {
          srcorigns: [
            {
              tid: "65n5BWWRlSW2eH7C_1",
              ref: 1,
              ofcid: "",
              sdur: "15:40",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "MAA",
                  dd: "2023-05-06T02:15:00",
                  ad: "2023-05-06T09:05:00",
                  rbd: "",
                  fn: "1776",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "3" },
                  rph: "",
                  jsell:
                    "6E~1776~ ~~DOH~05/06/2023 02:15~MAA~05/06/2023 09:05~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
                {
                  ref: 2,
                  da: "MAA",
                  ar: "HYD",
                  dd: "2023-05-06T17:00:00",
                  ad: "2023-05-06T17:55:00",
                  rbd: "",
                  fn: " 562",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "1" },
                  rph: "",
                  jsell:
                    "6E~ 562~ ~~MAA~05/06/2023 17:00~HYD~05/06/2023 17:55~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~R~ ~6E~RCINT~6200~~0~65~~X^0~R~ ~6E~RCINT~6200~~0~65~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 432,
                      fbc: "RCINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "432_586",
                      tax: [
                        { OT: 154 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 586,
                      psup_fare: "432_586",
                    },
                  ],
                  pr: {
                    bf: 432,
                    tf: 586,
                    tax: [
                      { OT: 154 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "432_586",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 586,
                  },
                  routeId: "i3sgff4lfl",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~R~ ~6E~RCUINT~6202~~0~4~~X^0~R~ ~6E~RCUINT~6202~~0~4~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 463,
                      fbc: "RCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "463_618",
                      tax: [
                        { OT: 155 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 618,
                      psup_fare: "463_618",
                    },
                  ],
                  pr: {
                    bf: 463,
                    tf: 618,
                    tax: [
                      { OT: 155 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "463_618",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 618,
                  },
                  routeId: "tbwkt8z1xb",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "gV9VlJcF4lo=|c8vLGEjg5j7NNr8U4/n4JN2DKZGLTH99mWKIwdR5uRWciziCl+wbZTrpWnMmGN+Yy9uJ4ZUTuD0HpLhkPra8oFmlLXu64hUIzZhRraKsuKvdW8dDCeFMBF8RTGVhnAM8gqCD8NTV8Ig=",
              stop: 1,
            },
            {
              tid: "z6SsftBsxJEJDaSz_1",
              ref: 1,
              ofcid: "",
              sdur: "15:5",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "MAA",
                  dd: "2023-05-06T02:15:00",
                  ad: "2023-05-06T09:05:00",
                  rbd: "",
                  fn: "1776",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "3" },
                  rph: "",
                  jsell:
                    "6E~1776~ ~~DOH~05/06/2023 02:15~MAA~05/06/2023 09:05~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
                {
                  ref: 2,
                  da: "MAA",
                  ar: "HYD",
                  dd: "2023-05-06T16:15:00",
                  ad: "2023-05-06T17:20:00",
                  rbd: "",
                  fn: "6805",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "1" },
                  rph: "",
                  jsell:
                    "6E~6805~ ~~MAA~05/06/2023 16:15~HYD~05/06/2023 17:20~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~R~ ~6E~RCINT~6200~~0~65~~X^0~R~ ~6E~RCINT~6200~~0~65~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 432,
                      fbc: "RCINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "432_586",
                      tax: [
                        { OT: 154 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 586,
                      psup_fare: "432_586",
                    },
                  ],
                  pr: {
                    bf: 432,
                    tf: 586,
                    tax: [
                      { OT: 154 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "432_586",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 586,
                  },
                  routeId: "fh9or46xokg",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~R~ ~6E~RCUINT~6202~~0~4~~X^0~R~ ~6E~RCUINT~6202~~0~4~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 463,
                      fbc: "RCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "463_618",
                      tax: [
                        { OT: 155 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 618,
                      psup_fare: "463_618",
                    },
                  ],
                  pr: {
                    bf: 463,
                    tf: 618,
                    tax: [
                      { OT: 155 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "463_618",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 618,
                  },
                  routeId: "ldcpw4ng4q",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "gV9VlJcF4lo=|c8vLGEjg5j7NNr8U4/n4JN2DKZGLTH99mWKIwdR5uRWciziCl+wbZTrpWnMmGN+Yy9uJ4ZUTuD0HpLhkPra8oFmlLXu64hUIzZhRraKsuKvdW8dDCeFMBF8RTGVhnAM8gqCD8NTV8Ig=",
              stop: 1,
            },
            {
              tid: "kRbolmpMV251O6LA_1",
              ref: 1,
              ofcid: "",
              sdur: "10:50",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "MAA",
                  dd: "2023-05-06T02:15:00",
                  ad: "2023-05-06T09:05:00",
                  rbd: "",
                  fn: "1776",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "3" },
                  rph: "",
                  jsell:
                    "6E~1776~ ~~DOH~05/06/2023 02:15~MAA~05/06/2023 09:05~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
                {
                  ref: 2,
                  da: "MAA",
                  ar: "HYD",
                  dd: "2023-05-06T12:00:00",
                  ad: "2023-05-06T13:05:00",
                  rbd: "",
                  fn: " 917",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "1" },
                  rph: "",
                  jsell:
                    "6E~ 917~ ~~MAA~05/06/2023 12:00~HYD~05/06/2023 13:05~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~R~ ~6E~RCINT~6200~~0~65~~X^0~R~ ~6E~RCINT~6200~~0~65~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 432,
                      fbc: "RCINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "432_586",
                      tax: [
                        { OT: 154 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 586,
                      psup_fare: "432_586",
                    },
                  ],
                  pr: {
                    bf: 432,
                    tf: 586,
                    tax: [
                      { OT: 154 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "432_586",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 586,
                  },
                  routeId: "aproshunj9",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~R~ ~6E~RCUINT~6202~~0~4~~X^0~R~ ~6E~RCUINT~6202~~0~4~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 463,
                      fbc: "RCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "463_618",
                      tax: [
                        { OT: 155 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 618,
                      psup_fare: "463_618",
                    },
                  ],
                  pr: {
                    bf: 463,
                    tf: 618,
                    tax: [
                      { OT: 155 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "463_618",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 618,
                  },
                  routeId: "0hpozisx7x",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "gV9VlJcF4lo=|c8vLGEjg5j7NNr8U4/n4JN2DKZGLTH99mWKIwdR5uRWciziCl+wbZTrpWnMmGN+Yy9uJ4ZUTuD0HpLhkPra8oFmlLXu64hUIzZhRraKsuKvdW8dDCeFMBF8RTGVhnAM8gqCD8NTV8Ig=",
              stop: 1,
            },
          ],
          destorigins: [
            {
              tid: "GctLoM2XOnelgEXc_2",
              ref: 2,
              ofcid: "",
              sdur: "13:40",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DEL",
                  dd: "2023-05-08T09:55:00",
                  ad: "2023-05-08T12:10:00",
                  rbd: "",
                  fn: "2511",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "2" },
                  rph: "",
                  jsell:
                    "6E~2511~ ~~HYD~05/08/2023 09:55~DEL~05/08/2023 12:10~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-05-08T22:00:00",
                  ad: "2023-05-08T23:35:00",
                  rbd: "",
                  fn: "1736",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "3" },
                  rph: "",
                  jsell:
                    "6E~1736~ ~~DEL~05/08/2023 22:00~DOH~05/08/2023 23:35~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~A~ ~6E~ACINT~6200~~0~66~~X^0~A~ ~6E~ACINT~6200~~0~66~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 1180,
                      fbc: "ACINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1180_1413",
                      tax: [
                        { OT: 233 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 1413,
                      psup_fare: "1180_1413",
                    },
                  ],
                  pr: {
                    bf: 1180,
                    tf: 1413,
                    tax: [
                      { OT: 233 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1180_1413",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 1413,
                  },
                  routeId: "jphm08ypg6",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~A~ ~6E~ACUINT~6202~~0~4~~X^0~A~ ~6E~ACUINT~6202~~0~4~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 1210,
                      fbc: "ACUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1210_1446",
                      tax: [
                        { OT: 236 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 1446,
                      psup_fare: "1210_1446",
                    },
                  ],
                  pr: {
                    bf: 1210,
                    tf: 1446,
                    tax: [
                      { OT: 236 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1210_1446",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 1446,
                  },
                  routeId: "lryzhto5js",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "gV9VlJcF4lo=|c8vLGEjg5j7NNr8U4/n4JN2DKZGLTH99mWKIwdR5uRWciziCl+wbZTrpWnMmGN+Yy9uJ4ZUTuD0HpLhkPra8oFmlLXu64hUIzZhRraKsuKvdW8dDCeFMBF8RTGVhnAM8gqCD8NTV8Ig=",
              stop: 1,
            },
            {
              tid: "8gIrTVt8vT2Hgtd3_2",
              ref: 2,
              ofcid: "",
              sdur: "11:55",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DEL",
                  dd: "2023-05-08T11:40:00",
                  ad: "2023-05-08T13:55:00",
                  rbd: "",
                  fn: "2171",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "2" },
                  rph: "",
                  jsell:
                    "6E~2171~ ~~HYD~05/08/2023 11:40~DEL~05/08/2023 13:55~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-05-08T22:00:00",
                  ad: "2023-05-08T23:35:00",
                  rbd: "",
                  fn: "1736",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "3" },
                  rph: "",
                  jsell:
                    "6E~1736~ ~~DEL~05/08/2023 22:00~DOH~05/08/2023 23:35~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~A~ ~6E~ACINT~6200~~0~66~~X^0~A~ ~6E~ACINT~6200~~0~66~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 1180,
                      fbc: "ACINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1180_1413",
                      tax: [
                        { OT: 233 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 1413,
                      psup_fare: "1180_1413",
                    },
                  ],
                  pr: {
                    bf: 1180,
                    tf: 1413,
                    tax: [
                      { OT: 233 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1180_1413",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 1413,
                  },
                  routeId: "w4ambftz0u",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~A~ ~6E~ACUINT~6202~~0~4~~X^0~A~ ~6E~ACUINT~6202~~0~4~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 1210,
                      fbc: "ACUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1210_1446",
                      tax: [
                        { OT: 236 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 1446,
                      psup_fare: "1210_1446",
                    },
                  ],
                  pr: {
                    bf: 1210,
                    tf: 1446,
                    tax: [
                      { OT: 236 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1210_1446",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 1446,
                  },
                  routeId: "dz6xgrtsfa",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "gV9VlJcF4lo=|c8vLGEjg5j7NNr8U4/n4JN2DKZGLTH99mWKIwdR5uRWciziCl+wbZTrpWnMmGN+Yy9uJ4ZUTuD0HpLhkPra8oFmlLXu64hUIzZhRraKsuKvdW8dDCeFMBF8RTGVhnAM8gqCD8NTV8Ig=",
              stop: 1,
            },
            {
              tid: "ne2PLXcCO2WNKWy8_2",
              ref: 2,
              ofcid: "",
              sdur: "9:35",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DEL",
                  dd: "2023-05-08T14:00:00",
                  ad: "2023-05-08T16:15:00",
                  rbd: "",
                  fn: "2063",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "2" },
                  rph: "",
                  jsell:
                    "6E~2063~ ~~HYD~05/08/2023 14:00~DEL~05/08/2023 16:15~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-05-08T22:00:00",
                  ad: "2023-05-08T23:35:00",
                  rbd: "",
                  fn: "1736",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "3" },
                  rph: "",
                  jsell:
                    "6E~1736~ ~~DEL~05/08/2023 22:00~DOH~05/08/2023 23:35~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~A~ ~6E~ACINT~6200~~0~66~~X^0~A~ ~6E~ACINT~6200~~0~66~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 1180,
                      fbc: "ACINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1180_1413",
                      tax: [
                        { OT: 233 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 1413,
                      psup_fare: "1180_1413",
                    },
                  ],
                  pr: {
                    bf: 1180,
                    tf: 1413,
                    tax: [
                      { OT: 233 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1180_1413",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 1413,
                  },
                  routeId: "u6biuppmxb",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~A~ ~6E~ACUINT~6202~~0~4~~X^0~A~ ~6E~ACUINT~6202~~0~4~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 1210,
                      fbc: "ACUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1210_1446",
                      tax: [
                        { OT: 236 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 1446,
                      psup_fare: "1210_1446",
                    },
                  ],
                  pr: {
                    bf: 1210,
                    tf: 1446,
                    tax: [
                      { OT: 236 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1210_1446",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 1446,
                  },
                  routeId: "na4zy1rt1t",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "gV9VlJcF4lo=|c8vLGEjg5j7NNr8U4/n4JN2DKZGLTH99mWKIwdR5uRWciziCl+wbZTrpWnMmGN+Yy9uJ4ZUTuD0HpLhkPra8oFmlLXu64hUIzZhRraKsuKvdW8dDCeFMBF8RTGVhnAM8gqCD8NTV8Ig=",
              stop: 1,
            },
            {
              tid: "ylImTGzHTHELvIWT_2",
              ref: 2,
              ofcid: "",
              sdur: "13:25",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DEL",
                  dd: "2023-05-08T16:20:00",
                  ad: "2023-05-08T18:35:00",
                  rbd: "",
                  fn: "2069",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "2" },
                  rph: "",
                  jsell:
                    "6E~2069~ ~~HYD~05/08/2023 16:20~DEL~05/08/2023 18:35~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-05-09T04:10:00",
                  ad: "2023-05-09T05:45:00",
                  rbd: "",
                  fn: "1701",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "3" },
                  rph: "",
                  jsell:
                    "6E~1701~ ~~DEL~05/09/2023 04:10~DOH~05/09/2023 05:45~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~A~ ~6E~ACINT~6200~~0~66~~X^0~A~ ~6E~ACINT~6200~~0~66~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 1180,
                      fbc: "ACINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1180_1413",
                      tax: [
                        { OT: 233 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 1413,
                      psup_fare: "1180_1413",
                    },
                  ],
                  pr: {
                    bf: 1180,
                    tf: 1413,
                    tax: [
                      { OT: 233 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1180_1413",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 1413,
                  },
                  routeId: "nsuc87mb6xk",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~A~ ~6E~ACUINT~6202~~0~4~~X^0~A~ ~6E~ACUINT~6202~~0~4~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 1210,
                      fbc: "ACUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1210_1446",
                      tax: [
                        { OT: 236 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 1446,
                      psup_fare: "1210_1446",
                    },
                  ],
                  pr: {
                    bf: 1210,
                    tf: 1446,
                    tax: [
                      { OT: 236 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1210_1446",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 1446,
                  },
                  routeId: "8ioliw8atd",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "gV9VlJcF4lo=|c8vLGEjg5j7NNr8U4/n4JN2DKZGLTH99mWKIwdR5uRWciziCl+wbZTrpWnMmGN+Yy9uJ4ZUTuD0HpLhkPra8oFmlLXu64hUIzZhRraKsuKvdW8dDCeFMBF8RTGVhnAM8gqCD8NTV8Ig=",
              stop: 1,
            },
            {
              tid: "QHM4mvBLKDTyUv7B_2",
              ref: 2,
              ofcid: "",
              sdur: "7:15",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DEL",
                  dd: "2023-05-08T16:20:00",
                  ad: "2023-05-08T18:35:00",
                  rbd: "",
                  fn: "2069",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "2" },
                  rph: "",
                  jsell:
                    "6E~2069~ ~~HYD~05/08/2023 16:20~DEL~05/08/2023 18:35~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-05-08T22:00:00",
                  ad: "2023-05-08T23:35:00",
                  rbd: "",
                  fn: "1736",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "3" },
                  rph: "",
                  jsell:
                    "6E~1736~ ~~DEL~05/08/2023 22:00~DOH~05/08/2023 23:35~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~A~ ~6E~ACINT~6200~~0~66~~X^0~A~ ~6E~ACINT~6200~~0~66~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 1180,
                      fbc: "ACINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1180_1413",
                      tax: [
                        { OT: 233 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 1413,
                      psup_fare: "1180_1413",
                    },
                  ],
                  pr: {
                    bf: 1180,
                    tf: 1413,
                    tax: [
                      { OT: 233 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1180_1413",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 1413,
                  },
                  routeId: "1rehw79m7hi",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~A~ ~6E~ACUINT~6202~~0~4~~X^0~A~ ~6E~ACUINT~6202~~0~4~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 1210,
                      fbc: "ACUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1210_1446",
                      tax: [
                        { OT: 236 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 1446,
                      psup_fare: "1210_1446",
                    },
                  ],
                  pr: {
                    bf: 1210,
                    tf: 1446,
                    tax: [
                      { OT: 236 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1210_1446",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 1446,
                  },
                  routeId: "72lpt3alnp",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "gV9VlJcF4lo=|c8vLGEjg5j7NNr8U4/n4JN2DKZGLTH99mWKIwdR5uRWciziCl+wbZTrpWnMmGN+Yy9uJ4ZUTuD0HpLhkPra8oFmlLXu64hUIzZhRraKsuKvdW8dDCeFMBF8RTGVhnAM8gqCD8NTV8Ig=",
              stop: 1,
            },
            {
              tid: "KQ5HbTPg9EzzFFlN_2",
              ref: 2,
              ofcid: "",
              sdur: "7:5",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DEL",
                  dd: "2023-05-08T16:30:00",
                  ad: "2023-05-08T18:55:00",
                  rbd: "",
                  fn: "6606",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: {},
                  rph: "",
                  jsell:
                    "6E~6606~ ~~HYD~05/08/2023 16:30~DEL~05/08/2023 18:55~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-05-08T22:00:00",
                  ad: "2023-05-08T23:35:00",
                  rbd: "",
                  fn: "1736",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "3" },
                  rph: "",
                  jsell:
                    "6E~1736~ ~~DEL~05/08/2023 22:00~DOH~05/08/2023 23:35~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~A~ ~6E~ACINT~6200~~0~66~~X^0~A~ ~6E~ACINT~6200~~0~66~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 1180,
                      fbc: "ACINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1180_1413",
                      tax: [
                        { OT: 233 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 1413,
                      psup_fare: "1180_1413",
                    },
                  ],
                  pr: {
                    bf: 1180,
                    tf: 1413,
                    tax: [
                      { OT: 233 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1180_1413",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 1413,
                  },
                  routeId: "frnvrsvemk",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~A~ ~6E~ACUINT~6202~~0~4~~X^0~A~ ~6E~ACUINT~6202~~0~4~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 1210,
                      fbc: "ACUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1210_1446",
                      tax: [
                        { OT: 236 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 1446,
                      psup_fare: "1210_1446",
                    },
                  ],
                  pr: {
                    bf: 1210,
                    tf: 1446,
                    tax: [
                      { OT: 236 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1210_1446",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 1446,
                  },
                  routeId: "0baharwfsh",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "gV9VlJcF4lo=|c8vLGEjg5j7NNr8U4/n4JN2DKZGLTH99mWKIwdR5uRWciziCl+wbZTrpWnMmGN+Yy9uJ4ZUTuD0HpLhkPra8oFmlLXu64hUIzZhRraKsuKvdW8dDCeFMBF8RTGVhnAM8gqCD8NTV8Ig=",
              stop: 1,
            },
            {
              tid: "DNp6tog8flFuQR2W_2",
              ref: 2,
              ofcid: "",
              sdur: "13:15",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DEL",
                  dd: "2023-05-08T16:30:00",
                  ad: "2023-05-08T18:55:00",
                  rbd: "",
                  fn: "6606",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: {},
                  rph: "",
                  jsell:
                    "6E~6606~ ~~HYD~05/08/2023 16:30~DEL~05/08/2023 18:55~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-05-09T04:10:00",
                  ad: "2023-05-09T05:45:00",
                  rbd: "",
                  fn: "1701",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "3" },
                  rph: "",
                  jsell:
                    "6E~1701~ ~~DEL~05/09/2023 04:10~DOH~05/09/2023 05:45~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~A~ ~6E~ACINT~6200~~0~66~~X^0~A~ ~6E~ACINT~6200~~0~66~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 1180,
                      fbc: "ACINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1180_1413",
                      tax: [
                        { OT: 233 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 1413,
                      psup_fare: "1180_1413",
                    },
                  ],
                  pr: {
                    bf: 1180,
                    tf: 1413,
                    tax: [
                      { OT: 233 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1180_1413",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 1413,
                  },
                  routeId: "pv771oggxa",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~A~ ~6E~ACUINT~6202~~0~4~~X^0~A~ ~6E~ACUINT~6202~~0~4~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 1210,
                      fbc: "ACUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1210_1446",
                      tax: [
                        { OT: 236 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 1446,
                      psup_fare: "1210_1446",
                    },
                  ],
                  pr: {
                    bf: 1210,
                    tf: 1446,
                    tax: [
                      { OT: 236 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1210_1446",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 1446,
                  },
                  routeId: "qsbchqmfln",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "gV9VlJcF4lo=|c8vLGEjg5j7NNr8U4/n4JN2DKZGLTH99mWKIwdR5uRWciziCl+wbZTrpWnMmGN+Yy9uJ4ZUTuD0HpLhkPra8oFmlLXu64hUIzZhRraKsuKvdW8dDCeFMBF8RTGVhnAM8gqCD8NTV8Ig=",
              stop: 1,
            },
            {
              tid: "f3Hm2Laul1zwmUQK_2",
              ref: 2,
              ofcid: "",
              sdur: "12:0",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DEL",
                  dd: "2023-05-08T17:45:00",
                  ad: "2023-05-08T20:00:00",
                  rbd: "",
                  fn: "2135",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "2" },
                  rph: "",
                  jsell:
                    "6E~2135~ ~~HYD~05/08/2023 17:45~DEL~05/08/2023 20:00~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-05-09T04:10:00",
                  ad: "2023-05-09T05:45:00",
                  rbd: "",
                  fn: "1701",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "3" },
                  rph: "",
                  jsell:
                    "6E~1701~ ~~DEL~05/09/2023 04:10~DOH~05/09/2023 05:45~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~A~ ~6E~ACINT~6200~~0~66~~X^0~A~ ~6E~ACINT~6200~~0~66~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 1180,
                      fbc: "ACINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1180_1413",
                      tax: [
                        { OT: 233 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 1413,
                      psup_fare: "1180_1413",
                    },
                  ],
                  pr: {
                    bf: 1180,
                    tf: 1413,
                    tax: [
                      { OT: 233 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1180_1413",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 1413,
                  },
                  routeId: "gyis3l6nkj",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~A~ ~6E~ACUINT~6202~~0~4~~X^0~A~ ~6E~ACUINT~6202~~0~4~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 1210,
                      fbc: "ACUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1210_1446",
                      tax: [
                        { OT: 236 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 1446,
                      psup_fare: "1210_1446",
                    },
                  ],
                  pr: {
                    bf: 1210,
                    tf: 1446,
                    tax: [
                      { OT: 236 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1210_1446",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 1446,
                  },
                  routeId: "24d9fs6kdm",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "gV9VlJcF4lo=|c8vLGEjg5j7NNr8U4/n4JN2DKZGLTH99mWKIwdR5uRWciziCl+wbZTrpWnMmGN+Yy9uJ4ZUTuD0HpLhkPra8oFmlLXu64hUIzZhRraKsuKvdW8dDCeFMBF8RTGVhnAM8gqCD8NTV8Ig=",
              stop: 1,
            },
            {
              tid: "ea4JnWUsoTl7EaFW_2",
              ref: 2,
              ofcid: "",
              sdur: "5:50",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DEL",
                  dd: "2023-05-08T17:45:00",
                  ad: "2023-05-08T20:00:00",
                  rbd: "",
                  fn: "2135",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "2" },
                  rph: "",
                  jsell:
                    "6E~2135~ ~~HYD~05/08/2023 17:45~DEL~05/08/2023 20:00~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-05-08T22:00:00",
                  ad: "2023-05-08T23:35:00",
                  rbd: "",
                  fn: "1736",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "3" },
                  rph: "",
                  jsell:
                    "6E~1736~ ~~DEL~05/08/2023 22:00~DOH~05/08/2023 23:35~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~A~ ~6E~ACINT~6200~~0~66~~X^0~A~ ~6E~ACINT~6200~~0~66~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 1180,
                      fbc: "ACINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1180_1413",
                      tax: [
                        { OT: 233 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 1413,
                      psup_fare: "1180_1413",
                    },
                  ],
                  pr: {
                    bf: 1180,
                    tf: 1413,
                    tax: [
                      { OT: 233 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1180_1413",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 1413,
                  },
                  routeId: "gdrx9ttioh",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~A~ ~6E~ACUINT~6202~~0~4~~X^0~A~ ~6E~ACUINT~6202~~0~4~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 1210,
                      fbc: "ACUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1210_1446",
                      tax: [
                        { OT: 236 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 1446,
                      psup_fare: "1210_1446",
                    },
                  ],
                  pr: {
                    bf: 1210,
                    tf: 1446,
                    tax: [
                      { OT: 236 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1210_1446",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 1446,
                  },
                  routeId: "jgwtd50x57",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "gV9VlJcF4lo=|c8vLGEjg5j7NNr8U4/n4JN2DKZGLTH99mWKIwdR5uRWciziCl+wbZTrpWnMmGN+Yy9uJ4ZUTuD0HpLhkPra8oFmlLXu64hUIzZhRraKsuKvdW8dDCeFMBF8RTGVhnAM8gqCD8NTV8Ig=",
              stop: 1,
            },
            {
              tid: "aEdJvdz7Tb9qwLEN_2",
              ref: 2,
              ofcid: "",
              sdur: "9:40",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DEL",
                  dd: "2023-05-08T20:05:00",
                  ad: "2023-05-08T22:20:00",
                  rbd: "",
                  fn: "2106",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "2" },
                  rph: "",
                  jsell:
                    "6E~2106~ ~~HYD~05/08/2023 20:05~DEL~05/08/2023 22:20~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-05-09T04:10:00",
                  ad: "2023-05-09T05:45:00",
                  rbd: "",
                  fn: "1701",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "3" },
                  rph: "",
                  jsell:
                    "6E~1701~ ~~DEL~05/09/2023 04:10~DOH~05/09/2023 05:45~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~A~ ~6E~ACINT~6200~~0~66~~X^0~A~ ~6E~ACINT~6200~~0~66~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 1180,
                      fbc: "ACINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1180_1413",
                      tax: [
                        { OT: 233 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 1413,
                      psup_fare: "1180_1413",
                    },
                  ],
                  pr: {
                    bf: 1180,
                    tf: 1413,
                    tax: [
                      { OT: 233 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1180_1413",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 1413,
                  },
                  routeId: "4u69r8l5mw",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~A~ ~6E~ACUINT~6202~~0~4~~X^0~A~ ~6E~ACUINT~6202~~0~4~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 1210,
                      fbc: "ACUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1210_1446",
                      tax: [
                        { OT: 236 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 1446,
                      psup_fare: "1210_1446",
                    },
                  ],
                  pr: {
                    bf: 1210,
                    tf: 1446,
                    tax: [
                      { OT: 236 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1210_1446",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 1446,
                  },
                  routeId: "xtm6kd1idu",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "gV9VlJcF4lo=|c8vLGEjg5j7NNr8U4/n4JN2DKZGLTH99mWKIwdR5uRWciziCl+wbZTrpWnMmGN+Yy9uJ4ZUTuD0HpLhkPra8oFmlLXu64hUIzZhRraKsuKvdW8dDCeFMBF8RTGVhnAM8gqCD8NTV8Ig=",
              stop: 1,
            },
            {
              tid: "5ItmMNBL6ZKKz8QC_2",
              ref: 2,
              ofcid: "",
              sdur: "9:30",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DEL",
                  dd: "2023-05-08T20:15:00",
                  ad: "2023-05-08T22:40:00",
                  rbd: "",
                  fn: "2213",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "2" },
                  rph: "",
                  jsell:
                    "6E~2213~ ~~HYD~05/08/2023 20:15~DEL~05/08/2023 22:40~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-05-09T04:10:00",
                  ad: "2023-05-09T05:45:00",
                  rbd: "",
                  fn: "1701",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "3" },
                  rph: "",
                  jsell:
                    "6E~1701~ ~~DEL~05/09/2023 04:10~DOH~05/09/2023 05:45~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~A~ ~6E~ACINT~6200~~0~66~~X^0~A~ ~6E~ACINT~6200~~0~66~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 1180,
                      fbc: "ACINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1180_1413",
                      tax: [
                        { OT: 233 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 1413,
                      psup_fare: "1180_1413",
                    },
                  ],
                  pr: {
                    bf: 1180,
                    tf: 1413,
                    tax: [
                      { OT: 233 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1180_1413",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 1413,
                  },
                  routeId: "be8929x24s",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~A~ ~6E~ACUINT~6202~~0~4~~X^0~A~ ~6E~ACUINT~6202~~0~4~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 1210,
                      fbc: "ACUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1210_1446",
                      tax: [
                        { OT: 236 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 1446,
                      psup_fare: "1210_1446",
                    },
                  ],
                  pr: {
                    bf: 1210,
                    tf: 1446,
                    tax: [
                      { OT: 236 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1210_1446",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 1446,
                  },
                  routeId: "cprz6ppzqz",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "gV9VlJcF4lo=|c8vLGEjg5j7NNr8U4/n4JN2DKZGLTH99mWKIwdR5uRWciziCl+wbZTrpWnMmGN+Yy9uJ4ZUTuD0HpLhkPra8oFmlLXu64hUIzZhRraKsuKvdW8dDCeFMBF8RTGVhnAM8gqCD8NTV8Ig=",
              stop: 1,
            },
            {
              tid: "d5OesAsHeBwiraS6_2",
              ref: 2,
              ofcid: "",
              sdur: "8:25",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DEL",
                  dd: "2023-05-08T21:20:00",
                  ad: "2023-05-08T23:30:00",
                  rbd: "",
                  fn: "6684",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "1" },
                  rph: "",
                  jsell:
                    "6E~6684~ ~~HYD~05/08/2023 21:20~DEL~05/08/2023 23:30~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-05-09T04:10:00",
                  ad: "2023-05-09T05:45:00",
                  rbd: "",
                  fn: "1701",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "3" },
                  rph: "",
                  jsell:
                    "6E~1701~ ~~DEL~05/09/2023 04:10~DOH~05/09/2023 05:45~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~A~ ~6E~ACINT~6200~~0~66~~X^0~A~ ~6E~ACINT~6200~~0~66~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 1180,
                      fbc: "ACINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1180_1413",
                      tax: [
                        { OT: 233 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 1413,
                      psup_fare: "1180_1413",
                    },
                  ],
                  pr: {
                    bf: 1180,
                    tf: 1413,
                    tax: [
                      { OT: 233 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1180_1413",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 1413,
                  },
                  routeId: "rz9eve1qef",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~A~ ~6E~ACUINT~6202~~0~4~~X^0~A~ ~6E~ACUINT~6202~~0~4~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 1210,
                      fbc: "ACUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1210_1446",
                      tax: [
                        { OT: 236 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 1446,
                      psup_fare: "1210_1446",
                    },
                  ],
                  pr: {
                    bf: 1210,
                    tf: 1446,
                    tax: [
                      { OT: 236 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1210_1446",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 1446,
                  },
                  routeId: "uk0a4lib1i",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "gV9VlJcF4lo=|c8vLGEjg5j7NNr8U4/n4JN2DKZGLTH99mWKIwdR5uRWciziCl+wbZTrpWnMmGN+Yy9uJ4ZUTuD0HpLhkPra8oFmlLXu64hUIzZhRraKsuKvdW8dDCeFMBF8RTGVhnAM8gqCD8NTV8Ig=",
              stop: 1,
            },
            {
              tid: "UfCVJWm5D0LgKvMQ_2",
              ref: 2,
              ofcid: "",
              sdur: "7:10",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DEL",
                  dd: "2023-05-08T22:35:00",
                  ad: "2023-05-09T00:45:00",
                  rbd: "",
                  fn: "2324",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "2" },
                  rph: "",
                  jsell:
                    "6E~2324~ ~~HYD~05/08/2023 22:35~DEL~05/09/2023 00:45~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-05-09T04:10:00",
                  ad: "2023-05-09T05:45:00",
                  rbd: "",
                  fn: "1701",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "3" },
                  rph: "",
                  jsell:
                    "6E~1701~ ~~DEL~05/09/2023 04:10~DOH~05/09/2023 05:45~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~A~ ~6E~ACINT~6200~~0~66~~X^0~A~ ~6E~ACINT~6200~~0~66~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 1180,
                      fbc: "ACINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1180_1413",
                      tax: [
                        { OT: 233 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 1413,
                      psup_fare: "1180_1413",
                    },
                  ],
                  pr: {
                    bf: 1180,
                    tf: 1413,
                    tax: [
                      { OT: 233 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1180_1413",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 1413,
                  },
                  routeId: "vj9izlyh4w",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~A~ ~6E~ACUINT~6202~~0~4~~X^0~A~ ~6E~ACUINT~6202~~0~4~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 1210,
                      fbc: "ACUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1210_1446",
                      tax: [
                        { OT: 236 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 1446,
                      psup_fare: "1210_1446",
                    },
                  ],
                  pr: {
                    bf: 1210,
                    tf: 1446,
                    tax: [
                      { OT: 236 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1210_1446",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 1446,
                  },
                  routeId: "kovm5ez85qf",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "gV9VlJcF4lo=|c8vLGEjg5j7NNr8U4/n4JN2DKZGLTH99mWKIwdR5uRWciziCl+wbZTrpWnMmGN+Yy9uJ4ZUTuD0HpLhkPra8oFmlLXu64hUIzZhRraKsuKvdW8dDCeFMBF8RTGVhnAM8gqCD8NTV8Ig=",
              stop: 1,
            },
          ],
          paxpr: [
            {
              bf: 1612,
              fbc: "RCINT",
              ptype: "ADT",
              scur: "QAR",
              sup_fare: "432_586",
              tax: [
                { OT: 387 },
                { attmkp: 0 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              tf: 1999,
              psup_fare: "1612_1999",
            },
          ],
          pr: {
            bf: 1612,
            tf: 1999,
            tax: [
              { OT: 387 },
              { attmkp: 0 },
              { tamkp: 0 },
              { stamkp: 0 },
              { attdisc: 0 },
              { tadisc: 0 },
              { stadisc: 0 },
            ],
            ucur: "QAR",
            exchange_rate: 1,
            scur: "QAR",
            sup_fare: "1612_1999",
            sup_cur_fare: "",
            cabb: "",
            chekb: "",
            seats: "",
            meals: "",
            chanb: "",
            ftyp: "Regular - R",
            markupnames: [],
            ttf: 1999,
          },
          sup: "4",
          airlineList: "6E",
          servicefee: [],
        },
        {
          srcorigns: [
            {
              tid: "MlHyFs7sLza1lsQW_1",
              ref: 1,
              ofcid: "",
              sdur: "6:30",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "HYD",
                  dd: "2023-05-06T02:00:00",
                  ad: "2023-05-06T08:30:00",
                  rbd: "",
                  fn: "1726",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: {},
                  rph: "",
                  jsell:
                    "6E~1726~ ~~DOH~05/06/2023 02:00~HYD~05/06/2023 08:30~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey: "0~R~ ~6E~R0INT~5000~~0~4~~X",
                  pr: {
                    bf: 330,
                    tf: 479,
                    tax: [
                      { OT: 149 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "330_479",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 479,
                  },
                  paxpr: [
                    {
                      bf: 330,
                      tf: 479,
                      tax: [
                        { OT: 149 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "R0INT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "330_479",
                      psup_fare: "330_479",
                    },
                  ],
                  routeId: "z5ihtzwn1r",
                  productClass: "R",
                  ruleNumber: "5000",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey: "0~R~ ~6E~RUINT~5003~~0~10~~X",
                  pr: {
                    bf: 361,
                    tf: 511,
                    tax: [
                      { OT: 150 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "361_511",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 511,
                  },
                  paxpr: [
                    {
                      bf: 361,
                      tf: 511,
                      tax: [
                        { OT: 150 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "RUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "361_511",
                      psup_fare: "361_511",
                    },
                  ],
                  routeId: "s5td3fk4bp",
                  productClass: "J",
                  ruleNumber: "5003",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "gV9VlJcF4lo=|c8vLGEjg5j7NNr8U4/n4JN2DKZGLTH99mWKIwdR5uRWciziCl+wbZTrpWnMmGN+Yy9uJ4ZUTuD0HpLhkPra8oFmlLXu64hUIzZhRraKsuKvdW8dDCeFMBF8RTGVhnAM8gqCD8NTV8Ig=",
              stop: 0,
            },
            {
              tid: "dbUKLSlTRZh3bX0E_1",
              ref: 1,
              ofcid: "",
              sdur: "6:20",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "HYD",
                  dd: "2023-05-06T20:10:00",
                  ad: "2023-05-07T02:30:00",
                  rbd: "",
                  fn: "1714",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: {},
                  rph: "",
                  jsell:
                    "6E~1714~ ~~DOH~05/06/2023 20:10~HYD~05/07/2023 02:30~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey: "0~R~ ~6E~R0INT~5000~~0~4~~X",
                  pr: {
                    bf: 330,
                    tf: 479,
                    tax: [
                      { OT: 149 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "330_479",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 479,
                  },
                  paxpr: [
                    {
                      bf: 330,
                      tf: 479,
                      tax: [
                        { OT: 149 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "R0INT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "330_479",
                      psup_fare: "330_479",
                    },
                  ],
                  routeId: "t4qxdt4cr8k",
                  productClass: "R",
                  ruleNumber: "5000",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey: "0~R~ ~6E~RUINT~5003~~0~10~~X",
                  pr: {
                    bf: 361,
                    tf: 511,
                    tax: [
                      { OT: 150 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "361_511",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 511,
                  },
                  paxpr: [
                    {
                      bf: 361,
                      tf: 511,
                      tax: [
                        { OT: 150 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "RUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "361_511",
                      psup_fare: "361_511",
                    },
                  ],
                  routeId: "wgou307ta7",
                  productClass: "J",
                  ruleNumber: "5003",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "gV9VlJcF4lo=|c8vLGEjg5j7NNr8U4/n4JN2DKZGLTH99mWKIwdR5uRWciziCl+wbZTrpWnMmGN+Yy9uJ4ZUTuD0HpLhkPra8oFmlLXu64hUIzZhRraKsuKvdW8dDCeFMBF8RTGVhnAM8gqCD8NTV8Ig=",
              stop: 0,
            },
          ],
          destorigins: [
            {
              tid: "ZrLUAnDHpAnUcoHK_2",
              ref: 2,
              ofcid: "",
              sdur: "7:50",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DEL",
                  dd: "2023-05-08T05:10:00",
                  ad: "2023-05-08T07:15:00",
                  rbd: "",
                  fn: "2773",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "2" },
                  rph: "",
                  jsell:
                    "6E~2773~ ~~HYD~05/08/2023 05:10~DEL~05/08/2023 07:15~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-05-08T10:00:00",
                  ad: "2023-05-08T13:00:00",
                  rbd: "",
                  fn: "1307",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "3" },
                  rph: "",
                  jsell:
                    "6E~1307~ ~~DEL~05/08/2023 10:00~DOH~05/08/2023 13:00~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 1347,
                      fbc: "WCINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1347_1595",
                      tax: [
                        { OT: 248 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 1595,
                      psup_fare: "1347_1595",
                    },
                  ],
                  pr: {
                    bf: 1347,
                    tf: 1595,
                    tax: [
                      { OT: 248 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1347_1595",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 1595,
                  },
                  routeId: "66amiemp8n",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 1378,
                      fbc: "WCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1378_1629",
                      tax: [
                        { OT: 251 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 1629,
                      psup_fare: "1378_1629",
                    },
                  ],
                  pr: {
                    bf: 1378,
                    tf: 1629,
                    tax: [
                      { OT: 251 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1378_1629",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 1629,
                  },
                  routeId: "ywdk04d6cq",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "gV9VlJcF4lo=|c8vLGEjg5j7NNr8U4/n4JN2DKZGLTH99mWKIwdR5uRWciziCl+wbZTrpWnMmGN+Yy9uJ4ZUTuD0HpLhkPra8oFmlLXu64hUIzZhRraKsuKvdW8dDCeFMBF8RTGVhnAM8gqCD8NTV8Ig=",
              stop: 1,
            },
            {
              tid: "ptCh18e64uue1ACT_2",
              ref: 2,
              ofcid: "",
              sdur: "6:55",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DEL",
                  dd: "2023-05-08T07:05:00",
                  ad: "2023-05-08T09:15:00",
                  rbd: "",
                  fn: "2011",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "2" },
                  rph: "",
                  jsell:
                    "6E~2011~ ~~HYD~05/08/2023 07:05~DEL~05/08/2023 09:15~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-05-08T11:00:00",
                  ad: "2023-05-08T14:00:00",
                  rbd: "",
                  fn: "1311",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "3" },
                  rph: "",
                  jsell:
                    "6E~1311~ ~~DEL~05/08/2023 11:00~DOH~05/08/2023 14:00~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 1347,
                      fbc: "WCINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1347_1595",
                      tax: [
                        { OT: 248 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 1595,
                      psup_fare: "1347_1595",
                    },
                  ],
                  pr: {
                    bf: 1347,
                    tf: 1595,
                    tax: [
                      { OT: 248 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1347_1595",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 1595,
                  },
                  routeId: "quy6gnxxky",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 1378,
                      fbc: "WCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1378_1629",
                      tax: [
                        { OT: 251 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 1629,
                      psup_fare: "1378_1629",
                    },
                  ],
                  pr: {
                    bf: 1378,
                    tf: 1629,
                    tax: [
                      { OT: 251 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1378_1629",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 1629,
                  },
                  routeId: "0zo0d9nf3ql",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "gV9VlJcF4lo=|c8vLGEjg5j7NNr8U4/n4JN2DKZGLTH99mWKIwdR5uRWciziCl+wbZTrpWnMmGN+Yy9uJ4ZUTuD0HpLhkPra8oFmlLXu64hUIzZhRraKsuKvdW8dDCeFMBF8RTGVhnAM8gqCD8NTV8Ig=",
              stop: 1,
            },
            {
              tid: "QszvKIEAxEKcvb0u_2",
              ref: 2,
              ofcid: "",
              sdur: "14:25",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DEL",
                  dd: "2023-05-08T22:35:00",
                  ad: "2023-05-09T00:45:00",
                  rbd: "",
                  fn: "2324",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "2" },
                  rph: "",
                  jsell:
                    "6E~2324~ ~~HYD~05/08/2023 22:35~DEL~05/09/2023 00:45~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-05-09T10:00:00",
                  ad: "2023-05-09T13:00:00",
                  rbd: "",
                  fn: "1307",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "3" },
                  rph: "",
                  jsell:
                    "6E~1307~ ~~DEL~05/09/2023 10:00~DOH~05/09/2023 13:00~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 1347,
                      fbc: "WCINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1347_1595",
                      tax: [
                        { OT: 248 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 1595,
                      psup_fare: "1347_1595",
                    },
                  ],
                  pr: {
                    bf: 1347,
                    tf: 1595,
                    tax: [
                      { OT: 248 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1347_1595",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 1595,
                  },
                  routeId: "vdnymtmwds",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 1378,
                      fbc: "WCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1378_1629",
                      tax: [
                        { OT: 251 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 1629,
                      psup_fare: "1378_1629",
                    },
                  ],
                  pr: {
                    bf: 1378,
                    tf: 1629,
                    tax: [
                      { OT: 251 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1378_1629",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 1629,
                  },
                  routeId: "e3etv60cv1",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "gV9VlJcF4lo=|c8vLGEjg5j7NNr8U4/n4JN2DKZGLTH99mWKIwdR5uRWciziCl+wbZTrpWnMmGN+Yy9uJ4ZUTuD0HpLhkPra8oFmlLXu64hUIzZhRraKsuKvdW8dDCeFMBF8RTGVhnAM8gqCD8NTV8Ig=",
              stop: 1,
            },
          ],
          paxpr: [
            {
              bf: 1677,
              tf: 2074,
              tax: [
                { OT: 397 },
                { attmkp: 0 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              fbc: "R0INT",
              ptype: "ADT",
              scur: "QAR",
              sup_fare: "330_479",
              psup_fare: "1677_2074",
            },
          ],
          pr: {
            bf: 1677,
            tf: 2074,
            tax: [
              { OT: 397 },
              { attmkp: 0 },
              { tamkp: 0 },
              { stamkp: 0 },
              { attdisc: 0 },
              { tadisc: 0 },
              { stadisc: 0 },
            ],
            ucur: "QAR",
            exchange_rate: 1,
            scur: "QAR",
            sup_fare: "1677_2074",
            sup_cur_fare: "",
            cabb: "",
            chekb: "",
            seats: "",
            meals: "",
            chanb: "",
            ftyp: "Regular - R",
            markupnames: [],
            ttf: 2074,
          },
          sup: "4",
          airlineList: "6E",
          servicefee: [],
        },
        {
          srcorigns: [
            {
              tid: "lEckXcQ60DoViBB4_1",
              ref: 1,
              ofcid: "",
              sdur: "15:40",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "MAA",
                  dd: "2023-05-06T02:15:00",
                  ad: "2023-05-06T09:05:00",
                  rbd: "",
                  fn: "1776",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "3" },
                  rph: "",
                  jsell:
                    "6E~1776~ ~~DOH~05/06/2023 02:15~MAA~05/06/2023 09:05~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
                {
                  ref: 2,
                  da: "MAA",
                  ar: "HYD",
                  dd: "2023-05-06T17:00:00",
                  ad: "2023-05-06T17:55:00",
                  rbd: "",
                  fn: " 562",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "1" },
                  rph: "",
                  jsell:
                    "6E~ 562~ ~~MAA~05/06/2023 17:00~HYD~05/06/2023 17:55~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~R~ ~6E~RCINT~6200~~0~65~~X^0~R~ ~6E~RCINT~6200~~0~65~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 432,
                      fbc: "RCINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "432_586",
                      tax: [
                        { OT: 154 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 586,
                      psup_fare: "432_586",
                    },
                  ],
                  pr: {
                    bf: 432,
                    tf: 586,
                    tax: [
                      { OT: 154 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "432_586",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 586,
                  },
                  routeId: "i3sgff4lfl",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~R~ ~6E~RCUINT~6202~~0~4~~X^0~R~ ~6E~RCUINT~6202~~0~4~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 463,
                      fbc: "RCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "463_618",
                      tax: [
                        { OT: 155 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 618,
                      psup_fare: "463_618",
                    },
                  ],
                  pr: {
                    bf: 463,
                    tf: 618,
                    tax: [
                      { OT: 155 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "463_618",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 618,
                  },
                  routeId: "tbwkt8z1xb",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "gV9VlJcF4lo=|c8vLGEjg5j7NNr8U4/n4JN2DKZGLTH99mWKIwdR5uRWciziCl+wbZTrpWnMmGN+Yy9uJ4ZUTuD0HpLhkPra8oFmlLXu64hUIzZhRraKsuKvdW8dDCeFMBF8RTGVhnAM8gqCD8NTV8Ig=",
              stop: 1,
            },
            {
              tid: "IV1umqCqA3dpDvaV_1",
              ref: 1,
              ofcid: "",
              sdur: "15:5",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "MAA",
                  dd: "2023-05-06T02:15:00",
                  ad: "2023-05-06T09:05:00",
                  rbd: "",
                  fn: "1776",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "3" },
                  rph: "",
                  jsell:
                    "6E~1776~ ~~DOH~05/06/2023 02:15~MAA~05/06/2023 09:05~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
                {
                  ref: 2,
                  da: "MAA",
                  ar: "HYD",
                  dd: "2023-05-06T16:15:00",
                  ad: "2023-05-06T17:20:00",
                  rbd: "",
                  fn: "6805",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "1" },
                  rph: "",
                  jsell:
                    "6E~6805~ ~~MAA~05/06/2023 16:15~HYD~05/06/2023 17:20~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~R~ ~6E~RCINT~6200~~0~65~~X^0~R~ ~6E~RCINT~6200~~0~65~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 432,
                      fbc: "RCINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "432_586",
                      tax: [
                        { OT: 154 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 586,
                      psup_fare: "432_586",
                    },
                  ],
                  pr: {
                    bf: 432,
                    tf: 586,
                    tax: [
                      { OT: 154 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "432_586",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 586,
                  },
                  routeId: "fh9or46xokg",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~R~ ~6E~RCUINT~6202~~0~4~~X^0~R~ ~6E~RCUINT~6202~~0~4~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 463,
                      fbc: "RCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "463_618",
                      tax: [
                        { OT: 155 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 618,
                      psup_fare: "463_618",
                    },
                  ],
                  pr: {
                    bf: 463,
                    tf: 618,
                    tax: [
                      { OT: 155 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "463_618",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 618,
                  },
                  routeId: "ldcpw4ng4q",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "gV9VlJcF4lo=|c8vLGEjg5j7NNr8U4/n4JN2DKZGLTH99mWKIwdR5uRWciziCl+wbZTrpWnMmGN+Yy9uJ4ZUTuD0HpLhkPra8oFmlLXu64hUIzZhRraKsuKvdW8dDCeFMBF8RTGVhnAM8gqCD8NTV8Ig=",
              stop: 1,
            },
            {
              tid: "grlgZkuJiuKCGwkf_1",
              ref: 1,
              ofcid: "",
              sdur: "10:50",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "MAA",
                  dd: "2023-05-06T02:15:00",
                  ad: "2023-05-06T09:05:00",
                  rbd: "",
                  fn: "1776",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "3" },
                  rph: "",
                  jsell:
                    "6E~1776~ ~~DOH~05/06/2023 02:15~MAA~05/06/2023 09:05~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
                {
                  ref: 2,
                  da: "MAA",
                  ar: "HYD",
                  dd: "2023-05-06T12:00:00",
                  ad: "2023-05-06T13:05:00",
                  rbd: "",
                  fn: " 917",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "1" },
                  rph: "",
                  jsell:
                    "6E~ 917~ ~~MAA~05/06/2023 12:00~HYD~05/06/2023 13:05~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~R~ ~6E~RCINT~6200~~0~65~~X^0~R~ ~6E~RCINT~6200~~0~65~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 432,
                      fbc: "RCINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "432_586",
                      tax: [
                        { OT: 154 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 586,
                      psup_fare: "432_586",
                    },
                  ],
                  pr: {
                    bf: 432,
                    tf: 586,
                    tax: [
                      { OT: 154 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "432_586",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 586,
                  },
                  routeId: "aproshunj9",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~R~ ~6E~RCUINT~6202~~0~4~~X^0~R~ ~6E~RCUINT~6202~~0~4~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 463,
                      fbc: "RCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "463_618",
                      tax: [
                        { OT: 155 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 618,
                      psup_fare: "463_618",
                    },
                  ],
                  pr: {
                    bf: 463,
                    tf: 618,
                    tax: [
                      { OT: 155 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "463_618",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 618,
                  },
                  routeId: "0hpozisx7x",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "gV9VlJcF4lo=|c8vLGEjg5j7NNr8U4/n4JN2DKZGLTH99mWKIwdR5uRWciziCl+wbZTrpWnMmGN+Yy9uJ4ZUTuD0HpLhkPra8oFmlLXu64hUIzZhRraKsuKvdW8dDCeFMBF8RTGVhnAM8gqCD8NTV8Ig=",
              stop: 1,
            },
          ],
          destorigins: [
            {
              tid: "ZQJOookn35lPKHG1_2",
              ref: 2,
              ofcid: "",
              sdur: "7:50",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DEL",
                  dd: "2023-05-08T05:10:00",
                  ad: "2023-05-08T07:15:00",
                  rbd: "",
                  fn: "2773",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "2" },
                  rph: "",
                  jsell:
                    "6E~2773~ ~~HYD~05/08/2023 05:10~DEL~05/08/2023 07:15~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-05-08T10:00:00",
                  ad: "2023-05-08T13:00:00",
                  rbd: "",
                  fn: "1307",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "3" },
                  rph: "",
                  jsell:
                    "6E~1307~ ~~DEL~05/08/2023 10:00~DOH~05/08/2023 13:00~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 1347,
                      fbc: "WCINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1347_1595",
                      tax: [
                        { OT: 248 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 1595,
                      psup_fare: "1347_1595",
                    },
                  ],
                  pr: {
                    bf: 1347,
                    tf: 1595,
                    tax: [
                      { OT: 248 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1347_1595",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 1595,
                  },
                  routeId: "azrcx3z5zs",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 1378,
                      fbc: "WCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1378_1629",
                      tax: [
                        { OT: 251 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 1629,
                      psup_fare: "1378_1629",
                    },
                  ],
                  pr: {
                    bf: 1378,
                    tf: 1629,
                    tax: [
                      { OT: 251 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1378_1629",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 1629,
                  },
                  routeId: "9rw9wllodkk",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "gV9VlJcF4lo=|c8vLGEjg5j7NNr8U4/n4JN2DKZGLTH99mWKIwdR5uRWciziCl+wbZTrpWnMmGN+Yy9uJ4ZUTuD0HpLhkPra8oFmlLXu64hUIzZhRraKsuKvdW8dDCeFMBF8RTGVhnAM8gqCD8NTV8Ig=",
              stop: 1,
            },
            {
              tid: "s3HwsBkMB0fboTXm_2",
              ref: 2,
              ofcid: "",
              sdur: "6:55",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DEL",
                  dd: "2023-05-08T07:05:00",
                  ad: "2023-05-08T09:15:00",
                  rbd: "",
                  fn: "2011",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "2" },
                  rph: "",
                  jsell:
                    "6E~2011~ ~~HYD~05/08/2023 07:05~DEL~05/08/2023 09:15~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-05-08T11:00:00",
                  ad: "2023-05-08T14:00:00",
                  rbd: "",
                  fn: "1311",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "3" },
                  rph: "",
                  jsell:
                    "6E~1311~ ~~DEL~05/08/2023 11:00~DOH~05/08/2023 14:00~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 1347,
                      fbc: "WCINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1347_1595",
                      tax: [
                        { OT: 248 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 1595,
                      psup_fare: "1347_1595",
                    },
                  ],
                  pr: {
                    bf: 1347,
                    tf: 1595,
                    tax: [
                      { OT: 248 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1347_1595",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 1595,
                  },
                  routeId: "bkp1le4pp0g",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 1378,
                      fbc: "WCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1378_1629",
                      tax: [
                        { OT: 251 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 1629,
                      psup_fare: "1378_1629",
                    },
                  ],
                  pr: {
                    bf: 1378,
                    tf: 1629,
                    tax: [
                      { OT: 251 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1378_1629",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 1629,
                  },
                  routeId: "rneroeiym6",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "gV9VlJcF4lo=|c8vLGEjg5j7NNr8U4/n4JN2DKZGLTH99mWKIwdR5uRWciziCl+wbZTrpWnMmGN+Yy9uJ4ZUTuD0HpLhkPra8oFmlLXu64hUIzZhRraKsuKvdW8dDCeFMBF8RTGVhnAM8gqCD8NTV8Ig=",
              stop: 1,
            },
            {
              tid: "tvWDP4UrIwtPLPpK_2",
              ref: 2,
              ofcid: "",
              sdur: "14:25",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DEL",
                  dd: "2023-05-08T22:35:00",
                  ad: "2023-05-09T00:45:00",
                  rbd: "",
                  fn: "2324",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "2" },
                  rph: "",
                  jsell:
                    "6E~2324~ ~~HYD~05/08/2023 22:35~DEL~05/09/2023 00:45~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-05-09T10:00:00",
                  ad: "2023-05-09T13:00:00",
                  rbd: "",
                  fn: "1307",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "3" },
                  rph: "",
                  jsell:
                    "6E~1307~ ~~DEL~05/09/2023 10:00~DOH~05/09/2023 13:00~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 1347,
                      fbc: "WCINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1347_1595",
                      tax: [
                        { OT: 248 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 1595,
                      psup_fare: "1347_1595",
                    },
                  ],
                  pr: {
                    bf: 1347,
                    tf: 1595,
                    tax: [
                      { OT: 248 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1347_1595",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 1595,
                  },
                  routeId: "j5rvhjz7tq",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 1378,
                      fbc: "WCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1378_1629",
                      tax: [
                        { OT: 251 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 1629,
                      psup_fare: "1378_1629",
                    },
                  ],
                  pr: {
                    bf: 1378,
                    tf: 1629,
                    tax: [
                      { OT: 251 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1378_1629",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 1629,
                  },
                  routeId: "ehipna1jbsj",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "gV9VlJcF4lo=|c8vLGEjg5j7NNr8U4/n4JN2DKZGLTH99mWKIwdR5uRWciziCl+wbZTrpWnMmGN+Yy9uJ4ZUTuD0HpLhkPra8oFmlLXu64hUIzZhRraKsuKvdW8dDCeFMBF8RTGVhnAM8gqCD8NTV8Ig=",
              stop: 1,
            },
          ],
          paxpr: [
            {
              bf: 1779,
              fbc: "RCINT",
              ptype: "ADT",
              scur: "QAR",
              sup_fare: "432_586",
              tax: [
                { OT: 402 },
                { attmkp: 0 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              tf: 2181,
              psup_fare: "1779_2181",
            },
          ],
          pr: {
            bf: 1779,
            tf: 2181,
            tax: [
              { OT: 402 },
              { attmkp: 0 },
              { tamkp: 0 },
              { stamkp: 0 },
              { attdisc: 0 },
              { tadisc: 0 },
              { stadisc: 0 },
            ],
            ucur: "QAR",
            exchange_rate: 1,
            scur: "QAR",
            sup_fare: "1779_2181",
            sup_cur_fare: "",
            cabb: "",
            chekb: "",
            seats: "",
            meals: "",
            chanb: "",
            ftyp: "Regular - R",
            markupnames: [],
            ttf: 2181,
          },
          sup: "4",
          airlineList: "6E",
          servicefee: [],
        },
        {
          srcorigns: [
            {
              tid: "VLPfnLzOFPcHmvxo_1",
              ref: 1,
              ofcid: "",
              sdur: "19:30",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-05-06T02:10:00",
                  ad: "2023-05-06T08:40:00",
                  rbd: "",
                  fn: "1737",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "3" },
                  rph: "",
                  jsell:
                    "6E~1737~ ~~DOH~05/06/2023 02:10~DEL~05/06/2023 08:40~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-05-06T19:30:00",
                  ad: "2023-05-06T21:40:00",
                  rbd: "",
                  fn: "2104",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "2" },
                  rph: "",
                  jsell:
                    "6E~2104~ ~~DEL~05/06/2023 19:30~HYD~05/06/2023 21:40~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~A~ ~6E~ACINT~6200~~0~65~~X^0~A~ ~6E~ACINT~6200~~0~65~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 1802,
                      fbc: "ACINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1802_2004",
                      tax: [
                        { OT: 202 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 2004,
                      psup_fare: "1802_2004",
                    },
                  ],
                  pr: {
                    bf: 1802,
                    tf: 2004,
                    tax: [
                      { OT: 202 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1802_2004",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 2004,
                  },
                  routeId: "fvxktneksp",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~A~ ~6E~ACUINT~6202~~0~5~~X^0~A~ ~6E~ACUINT~6202~~0~5~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 1833,
                      fbc: "ACUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1833_2036",
                      tax: [
                        { OT: 203 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 2036,
                      psup_fare: "1833_2036",
                    },
                  ],
                  pr: {
                    bf: 1833,
                    tf: 2036,
                    tax: [
                      { OT: 203 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1833_2036",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 2036,
                  },
                  routeId: "maw6rrjced",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "gV9VlJcF4lo=|c8vLGEjg5j7NNr8U4/n4JN2DKZGLTH99mWKIwdR5uRWciziCl+wbZTrpWnMmGN+Yy9uJ4ZUTuD0HpLhkPra8oFmlLXu64hUIzZhRraKsuKvdW8dDCeFMBF8RTGVhnAM8gqCD8NTV8Ig=",
              stop: 1,
            },
            {
              tid: "W7G9BJCmp2IseTrH_1",
              ref: 1,
              ofcid: "",
              sdur: "12:40",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-05-06T02:10:00",
                  ad: "2023-05-06T08:40:00",
                  rbd: "",
                  fn: "1737",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "3" },
                  rph: "",
                  jsell:
                    "6E~1737~ ~~DOH~05/06/2023 02:10~DEL~05/06/2023 08:40~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-05-06T12:45:00",
                  ad: "2023-05-06T14:50:00",
                  rbd: "",
                  fn: "2004",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "2" },
                  rph: "",
                  jsell:
                    "6E~2004~ ~~DEL~05/06/2023 12:45~HYD~05/06/2023 14:50~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~A~ ~6E~ACINT~6200~~0~65~~X^0~A~ ~6E~ACINT~6200~~0~65~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 1802,
                      fbc: "ACINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1802_2004",
                      tax: [
                        { OT: 202 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 2004,
                      psup_fare: "1802_2004",
                    },
                  ],
                  pr: {
                    bf: 1802,
                    tf: 2004,
                    tax: [
                      { OT: 202 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1802_2004",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 2004,
                  },
                  routeId: "4tow7l2ohj",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~A~ ~6E~ACUINT~6202~~0~5~~X^0~A~ ~6E~ACUINT~6202~~0~5~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 1833,
                      fbc: "ACUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1833_2036",
                      tax: [
                        { OT: 203 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 2036,
                      psup_fare: "1833_2036",
                    },
                  ],
                  pr: {
                    bf: 1833,
                    tf: 2036,
                    tax: [
                      { OT: 203 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1833_2036",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 2036,
                  },
                  routeId: "2b5ov8si39i",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "gV9VlJcF4lo=|c8vLGEjg5j7NNr8U4/n4JN2DKZGLTH99mWKIwdR5uRWciziCl+wbZTrpWnMmGN+Yy9uJ4ZUTuD0HpLhkPra8oFmlLXu64hUIzZhRraKsuKvdW8dDCeFMBF8RTGVhnAM8gqCD8NTV8Ig=",
              stop: 1,
            },
            {
              tid: "ByVKGEyr8LmnST9t_1",
              ref: 1,
              ofcid: "",
              sdur: "13:20",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-05-06T02:10:00",
                  ad: "2023-05-06T08:40:00",
                  rbd: "",
                  fn: "1737",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "3" },
                  rph: "",
                  jsell:
                    "6E~1737~ ~~DOH~05/06/2023 02:10~DEL~05/06/2023 08:40~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-05-06T13:25:00",
                  ad: "2023-05-06T15:30:00",
                  rbd: "",
                  fn: "2014",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "2" },
                  rph: "",
                  jsell:
                    "6E~2014~ ~~DEL~05/06/2023 13:25~HYD~05/06/2023 15:30~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~A~ ~6E~ACINT~6200~~0~65~~X^0~A~ ~6E~ACINT~6200~~0~65~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 1802,
                      fbc: "ACINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1802_2004",
                      tax: [
                        { OT: 202 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 2004,
                      psup_fare: "1802_2004",
                    },
                  ],
                  pr: {
                    bf: 1802,
                    tf: 2004,
                    tax: [
                      { OT: 202 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1802_2004",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 2004,
                  },
                  routeId: "srugv0sqyii",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~A~ ~6E~ACUINT~6202~~0~5~~X^0~A~ ~6E~ACUINT~6202~~0~5~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 1833,
                      fbc: "ACUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1833_2036",
                      tax: [
                        { OT: 203 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 2036,
                      psup_fare: "1833_2036",
                    },
                  ],
                  pr: {
                    bf: 1833,
                    tf: 2036,
                    tax: [
                      { OT: 203 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1833_2036",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 2036,
                  },
                  routeId: "kqc5aavv7i",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "gV9VlJcF4lo=|c8vLGEjg5j7NNr8U4/n4JN2DKZGLTH99mWKIwdR5uRWciziCl+wbZTrpWnMmGN+Yy9uJ4ZUTuD0HpLhkPra8oFmlLXu64hUIzZhRraKsuKvdW8dDCeFMBF8RTGVhnAM8gqCD8NTV8Ig=",
              stop: 1,
            },
            {
              tid: "CMpnGNWBAcNKRADW_1",
              ref: 1,
              ofcid: "",
              sdur: "18:0",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-05-06T02:10:00",
                  ad: "2023-05-06T08:40:00",
                  rbd: "",
                  fn: "1737",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "3" },
                  rph: "",
                  jsell:
                    "6E~1737~ ~~DOH~05/06/2023 02:10~DEL~05/06/2023 08:40~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-05-06T18:00:00",
                  ad: "2023-05-06T20:10:00",
                  rbd: "",
                  fn: "6823",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "1" },
                  rph: "",
                  jsell:
                    "6E~6823~ ~~DEL~05/06/2023 18:00~HYD~05/06/2023 20:10~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~A~ ~6E~ACINT~6200~~0~65~~X^0~A~ ~6E~ACINT~6200~~0~65~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 1802,
                      fbc: "ACINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1802_2004",
                      tax: [
                        { OT: 202 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 2004,
                      psup_fare: "1802_2004",
                    },
                  ],
                  pr: {
                    bf: 1802,
                    tf: 2004,
                    tax: [
                      { OT: 202 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1802_2004",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 2004,
                  },
                  routeId: "eraa7jkap",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~A~ ~6E~ACUINT~6202~~0~5~~X^0~A~ ~6E~ACUINT~6202~~0~5~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 1833,
                      fbc: "ACUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1833_2036",
                      tax: [
                        { OT: 203 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 2036,
                      psup_fare: "1833_2036",
                    },
                  ],
                  pr: {
                    bf: 1833,
                    tf: 2036,
                    tax: [
                      { OT: 203 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1833_2036",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 2036,
                  },
                  routeId: "umx7fa6qje",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "gV9VlJcF4lo=|c8vLGEjg5j7NNr8U4/n4JN2DKZGLTH99mWKIwdR5uRWciziCl+wbZTrpWnMmGN+Yy9uJ4ZUTuD0HpLhkPra8oFmlLXu64hUIzZhRraKsuKvdW8dDCeFMBF8RTGVhnAM8gqCD8NTV8Ig=",
              stop: 1,
            },
            {
              tid: "ofKULpixkWFrlUx1_1",
              ref: 1,
              ofcid: "",
              sdur: "14:45",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-05-06T02:10:00",
                  ad: "2023-05-06T08:40:00",
                  rbd: "",
                  fn: "1737",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "3" },
                  rph: "",
                  jsell:
                    "6E~1737~ ~~DOH~05/06/2023 02:10~DEL~05/06/2023 08:40~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-05-06T15:00:00",
                  ad: "2023-05-06T16:55:00",
                  rbd: "",
                  fn: "2187",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "2" },
                  rph: "",
                  jsell:
                    "6E~2187~ ~~DEL~05/06/2023 15:00~HYD~05/06/2023 16:55~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~A~ ~6E~ACINT~6200~~0~65~~X^0~A~ ~6E~ACINT~6200~~0~65~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 1802,
                      fbc: "ACINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1802_2004",
                      tax: [
                        { OT: 202 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 2004,
                      psup_fare: "1802_2004",
                    },
                  ],
                  pr: {
                    bf: 1802,
                    tf: 2004,
                    tax: [
                      { OT: 202 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1802_2004",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 2004,
                  },
                  routeId: "175txao85rf",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~A~ ~6E~ACUINT~6202~~0~5~~X^0~A~ ~6E~ACUINT~6202~~0~5~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 1833,
                      fbc: "ACUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1833_2036",
                      tax: [
                        { OT: 203 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 2036,
                      psup_fare: "1833_2036",
                    },
                  ],
                  pr: {
                    bf: 1833,
                    tf: 2036,
                    tax: [
                      { OT: 203 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1833_2036",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 2036,
                  },
                  routeId: "cf8bnuuyszk",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "gV9VlJcF4lo=|c8vLGEjg5j7NNr8U4/n4JN2DKZGLTH99mWKIwdR5uRWciziCl+wbZTrpWnMmGN+Yy9uJ4ZUTuD0HpLhkPra8oFmlLXu64hUIzZhRraKsuKvdW8dDCeFMBF8RTGVhnAM8gqCD8NTV8Ig=",
              stop: 1,
            },
            {
              tid: "HApV2OZNhEHzrTUC_1",
              ref: 1,
              ofcid: "",
              sdur: "25:50",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-05-06T02:10:00",
                  ad: "2023-05-06T08:40:00",
                  rbd: "",
                  fn: "1737",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "3" },
                  rph: "",
                  jsell:
                    "6E~1737~ ~~DOH~05/06/2023 02:10~DEL~05/06/2023 08:40~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-05-07T01:50:00",
                  ad: "2023-05-07T04:00:00",
                  rbd: "",
                  fn: "2325",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "2" },
                  rph: "",
                  jsell:
                    "6E~2325~ ~~DEL~05/07/2023 01:50~HYD~05/07/2023 04:00~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~A~ ~6E~ACINT~6200~~0~65~~X^0~A~ ~6E~ACINT~6200~~0~65~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 1802,
                      fbc: "ACINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1802_2004",
                      tax: [
                        { OT: 202 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 2004,
                      psup_fare: "1802_2004",
                    },
                  ],
                  pr: {
                    bf: 1802,
                    tf: 2004,
                    tax: [
                      { OT: 202 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1802_2004",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 2004,
                  },
                  routeId: "gvjs9o5gfr",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~A~ ~6E~ACUINT~6202~~0~5~~X^0~A~ ~6E~ACUINT~6202~~0~5~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 1833,
                      fbc: "ACUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1833_2036",
                      tax: [
                        { OT: 203 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 2036,
                      psup_fare: "1833_2036",
                    },
                  ],
                  pr: {
                    bf: 1833,
                    tf: 2036,
                    tax: [
                      { OT: 203 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1833_2036",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 2036,
                  },
                  routeId: "a7uvvezvfw",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "gV9VlJcF4lo=|c8vLGEjg5j7NNr8U4/n4JN2DKZGLTH99mWKIwdR5uRWciziCl+wbZTrpWnMmGN+Yy9uJ4ZUTuD0HpLhkPra8oFmlLXu64hUIzZhRraKsuKvdW8dDCeFMBF8RTGVhnAM8gqCD8NTV8Ig=",
              stop: 1,
            },
            {
              tid: "7tGbNwTgLa9Cnhgm_1",
              ref: 1,
              ofcid: "",
              sdur: "13:45",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-05-06T20:30:00",
                  ad: "2023-05-07T03:15:00",
                  rbd: "",
                  fn: "1774",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "3" },
                  rph: "",
                  jsell:
                    "6E~1774~ ~~DOH~05/06/2023 20:30~DEL~05/07/2023 03:15~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-05-07T07:55:00",
                  ad: "2023-05-07T10:15:00",
                  rbd: "",
                  fn: "2774",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "2" },
                  rph: "",
                  jsell:
                    "6E~2774~ ~~DEL~05/07/2023 07:55~HYD~05/07/2023 10:15~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~A~ ~6E~ACINT~6200~~0~65~~X^0~A~ ~6E~ACINT~6200~~0~65~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 1802,
                      fbc: "ACINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1802_2004",
                      tax: [
                        { OT: 202 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 2004,
                      psup_fare: "1802_2004",
                    },
                  ],
                  pr: {
                    bf: 1802,
                    tf: 2004,
                    tax: [
                      { OT: 202 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1802_2004",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 2004,
                  },
                  routeId: "kn44afx98a",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~A~ ~6E~ACUINT~6202~~0~5~~X^0~A~ ~6E~ACUINT~6202~~0~5~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 1833,
                      fbc: "ACUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1833_2036",
                      tax: [
                        { OT: 203 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 2036,
                      psup_fare: "1833_2036",
                    },
                  ],
                  pr: {
                    bf: 1833,
                    tf: 2036,
                    tax: [
                      { OT: 203 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1833_2036",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 2036,
                  },
                  routeId: "nrxikj38vm",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "gV9VlJcF4lo=|c8vLGEjg5j7NNr8U4/n4JN2DKZGLTH99mWKIwdR5uRWciziCl+wbZTrpWnMmGN+Yy9uJ4ZUTuD0HpLhkPra8oFmlLXu64hUIzZhRraKsuKvdW8dDCeFMBF8RTGVhnAM8gqCD8NTV8Ig=",
              stop: 1,
            },
            {
              tid: "yqGbvPwQ9kFWbcMT_1",
              ref: 1,
              ofcid: "",
              sdur: "18:20",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-05-06T20:30:00",
                  ad: "2023-05-07T03:15:00",
                  rbd: "",
                  fn: "1774",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "3" },
                  rph: "",
                  jsell:
                    "6E~1774~ ~~DOH~05/06/2023 20:30~DEL~05/07/2023 03:15~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-05-07T12:45:00",
                  ad: "2023-05-07T14:50:00",
                  rbd: "",
                  fn: "2004",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "2" },
                  rph: "",
                  jsell:
                    "6E~2004~ ~~DEL~05/07/2023 12:45~HYD~05/07/2023 14:50~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~A~ ~6E~ACINT~6200~~0~65~~X^0~A~ ~6E~ACINT~6200~~0~65~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 1802,
                      fbc: "ACINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1802_2004",
                      tax: [
                        { OT: 202 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 2004,
                      psup_fare: "1802_2004",
                    },
                  ],
                  pr: {
                    bf: 1802,
                    tf: 2004,
                    tax: [
                      { OT: 202 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1802_2004",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 2004,
                  },
                  routeId: "5m79dpcadu",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~A~ ~6E~ACUINT~6202~~0~5~~X^0~A~ ~6E~ACUINT~6202~~0~5~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 1833,
                      fbc: "ACUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1833_2036",
                      tax: [
                        { OT: 203 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 2036,
                      psup_fare: "1833_2036",
                    },
                  ],
                  pr: {
                    bf: 1833,
                    tf: 2036,
                    tax: [
                      { OT: 203 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1833_2036",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 2036,
                  },
                  routeId: "1gmm6scn7f",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "gV9VlJcF4lo=|c8vLGEjg5j7NNr8U4/n4JN2DKZGLTH99mWKIwdR5uRWciziCl+wbZTrpWnMmGN+Yy9uJ4ZUTuD0HpLhkPra8oFmlLXu64hUIzZhRraKsuKvdW8dDCeFMBF8RTGVhnAM8gqCD8NTV8Ig=",
              stop: 1,
            },
            {
              tid: "XgOdqr7msSHEuqRN_1",
              ref: 1,
              ofcid: "",
              sdur: "19:0",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-05-06T20:30:00",
                  ad: "2023-05-07T03:15:00",
                  rbd: "",
                  fn: "1774",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "3" },
                  rph: "",
                  jsell:
                    "6E~1774~ ~~DOH~05/06/2023 20:30~DEL~05/07/2023 03:15~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-05-07T13:25:00",
                  ad: "2023-05-07T15:30:00",
                  rbd: "",
                  fn: "2014",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "2" },
                  rph: "",
                  jsell:
                    "6E~2014~ ~~DEL~05/07/2023 13:25~HYD~05/07/2023 15:30~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~A~ ~6E~ACINT~6200~~0~65~~X^0~A~ ~6E~ACINT~6200~~0~65~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 1802,
                      fbc: "ACINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1802_2004",
                      tax: [
                        { OT: 202 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 2004,
                      psup_fare: "1802_2004",
                    },
                  ],
                  pr: {
                    bf: 1802,
                    tf: 2004,
                    tax: [
                      { OT: 202 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1802_2004",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 2004,
                  },
                  routeId: "aepk857s4i",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~A~ ~6E~ACUINT~6202~~0~5~~X^0~A~ ~6E~ACUINT~6202~~0~5~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 1833,
                      fbc: "ACUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1833_2036",
                      tax: [
                        { OT: 203 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 2036,
                      psup_fare: "1833_2036",
                    },
                  ],
                  pr: {
                    bf: 1833,
                    tf: 2036,
                    tax: [
                      { OT: 203 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1833_2036",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 2036,
                  },
                  routeId: "dwaxlpyk7u",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "gV9VlJcF4lo=|c8vLGEjg5j7NNr8U4/n4JN2DKZGLTH99mWKIwdR5uRWciziCl+wbZTrpWnMmGN+Yy9uJ4ZUTuD0HpLhkPra8oFmlLXu64hUIzZhRraKsuKvdW8dDCeFMBF8RTGVhnAM8gqCD8NTV8Ig=",
              stop: 1,
            },
            {
              tid: "DF0TlGzQBUy711AC_1",
              ref: 1,
              ofcid: "",
              sdur: "12:30",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-05-06T20:30:00",
                  ad: "2023-05-07T03:15:00",
                  rbd: "",
                  fn: "1774",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "3" },
                  rph: "",
                  jsell:
                    "6E~1774~ ~~DOH~05/06/2023 20:30~DEL~05/07/2023 03:15~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-05-07T06:40:00",
                  ad: "2023-05-07T09:00:00",
                  rbd: "",
                  fn: "2331",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "2" },
                  rph: "",
                  jsell:
                    "6E~2331~ ~~DEL~05/07/2023 06:40~HYD~05/07/2023 09:00~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~A~ ~6E~ACINT~6200~~0~65~~X^0~A~ ~6E~ACINT~6200~~0~65~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 1802,
                      fbc: "ACINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1802_2004",
                      tax: [
                        { OT: 202 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 2004,
                      psup_fare: "1802_2004",
                    },
                  ],
                  pr: {
                    bf: 1802,
                    tf: 2004,
                    tax: [
                      { OT: 202 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1802_2004",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 2004,
                  },
                  routeId: "ezj4xudid7",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~A~ ~6E~ACUINT~6202~~0~5~~X^0~A~ ~6E~ACUINT~6202~~0~5~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 1833,
                      fbc: "ACUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1833_2036",
                      tax: [
                        { OT: 203 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 2036,
                      psup_fare: "1833_2036",
                    },
                  ],
                  pr: {
                    bf: 1833,
                    tf: 2036,
                    tax: [
                      { OT: 203 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1833_2036",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 2036,
                  },
                  routeId: "8bu08ruoeu",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "gV9VlJcF4lo=|c8vLGEjg5j7NNr8U4/n4JN2DKZGLTH99mWKIwdR5uRWciziCl+wbZTrpWnMmGN+Yy9uJ4ZUTuD0HpLhkPra8oFmlLXu64hUIzZhRraKsuKvdW8dDCeFMBF8RTGVhnAM8gqCD8NTV8Ig=",
              stop: 1,
            },
            {
              tid: "mPGZ5o80JxDPu4It_1",
              ref: 1,
              ofcid: "",
              sdur: "20:25",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-05-06T20:30:00",
                  ad: "2023-05-07T03:15:00",
                  rbd: "",
                  fn: "1774",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "3" },
                  rph: "",
                  jsell:
                    "6E~1774~ ~~DOH~05/06/2023 20:30~DEL~05/07/2023 03:15~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-05-07T15:00:00",
                  ad: "2023-05-07T16:55:00",
                  rbd: "",
                  fn: "2187",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "2" },
                  rph: "",
                  jsell:
                    "6E~2187~ ~~DEL~05/07/2023 15:00~HYD~05/07/2023 16:55~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~A~ ~6E~ACINT~6200~~0~65~~X^0~A~ ~6E~ACINT~6200~~0~65~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 1802,
                      fbc: "ACINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1802_2004",
                      tax: [
                        { OT: 202 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 2004,
                      psup_fare: "1802_2004",
                    },
                  ],
                  pr: {
                    bf: 1802,
                    tf: 2004,
                    tax: [
                      { OT: 202 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1802_2004",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 2004,
                  },
                  routeId: "kemzj23hrn",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~A~ ~6E~ACUINT~6202~~0~5~~X^0~A~ ~6E~ACUINT~6202~~0~5~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 1833,
                      fbc: "ACUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1833_2036",
                      tax: [
                        { OT: 203 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 2036,
                      psup_fare: "1833_2036",
                    },
                  ],
                  pr: {
                    bf: 1833,
                    tf: 2036,
                    tax: [
                      { OT: 203 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1833_2036",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 2036,
                  },
                  routeId: "21xutw51bhi",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "gV9VlJcF4lo=|c8vLGEjg5j7NNr8U4/n4JN2DKZGLTH99mWKIwdR5uRWciziCl+wbZTrpWnMmGN+Yy9uJ4ZUTuD0HpLhkPra8oFmlLXu64hUIzZhRraKsuKvdW8dDCeFMBF8RTGVhnAM8gqCD8NTV8Ig=",
              stop: 1,
            },
            {
              tid: "W9gnyGhBkLXa1KnB_1",
              ref: 1,
              ofcid: "",
              sdur: "23:40",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-05-06T20:30:00",
                  ad: "2023-05-07T03:15:00",
                  rbd: "",
                  fn: "1774",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "3" },
                  rph: "",
                  jsell:
                    "6E~1774~ ~~DOH~05/06/2023 20:30~DEL~05/07/2023 03:15~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-05-07T18:00:00",
                  ad: "2023-05-07T20:10:00",
                  rbd: "",
                  fn: "6823",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "1" },
                  rph: "",
                  jsell:
                    "6E~6823~ ~~DEL~05/07/2023 18:00~HYD~05/07/2023 20:10~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~A~ ~6E~ACINT~6200~~0~65~~X^0~A~ ~6E~ACINT~6200~~0~65~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 1802,
                      fbc: "ACINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1802_2004",
                      tax: [
                        { OT: 202 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 2004,
                      psup_fare: "1802_2004",
                    },
                  ],
                  pr: {
                    bf: 1802,
                    tf: 2004,
                    tax: [
                      { OT: 202 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1802_2004",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 2004,
                  },
                  routeId: "riajf49wjqk",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~A~ ~6E~ACUINT~6202~~0~5~~X^0~A~ ~6E~ACUINT~6202~~0~5~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 1833,
                      fbc: "ACUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1833_2036",
                      tax: [
                        { OT: 203 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 2036,
                      psup_fare: "1833_2036",
                    },
                  ],
                  pr: {
                    bf: 1833,
                    tf: 2036,
                    tax: [
                      { OT: 203 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1833_2036",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 2036,
                  },
                  routeId: "b4g8fgyf4t",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "gV9VlJcF4lo=|c8vLGEjg5j7NNr8U4/n4JN2DKZGLTH99mWKIwdR5uRWciziCl+wbZTrpWnMmGN+Yy9uJ4ZUTuD0HpLhkPra8oFmlLXu64hUIzZhRraKsuKvdW8dDCeFMBF8RTGVhnAM8gqCD8NTV8Ig=",
              stop: 1,
            },
            {
              tid: "NvRx2mNd3JhlrfG9_1",
              ref: 1,
              ofcid: "",
              sdur: "25:10",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-05-06T20:30:00",
                  ad: "2023-05-07T03:15:00",
                  rbd: "",
                  fn: "1774",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "3" },
                  rph: "",
                  jsell:
                    "6E~1774~ ~~DOH~05/06/2023 20:30~DEL~05/07/2023 03:15~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-05-07T19:30:00",
                  ad: "2023-05-07T21:40:00",
                  rbd: "",
                  fn: "2104",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "2" },
                  rph: "",
                  jsell:
                    "6E~2104~ ~~DEL~05/07/2023 19:30~HYD~05/07/2023 21:40~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~A~ ~6E~ACINT~6200~~0~65~~X^0~A~ ~6E~ACINT~6200~~0~65~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 1802,
                      fbc: "ACINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1802_2004",
                      tax: [
                        { OT: 202 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 2004,
                      psup_fare: "1802_2004",
                    },
                  ],
                  pr: {
                    bf: 1802,
                    tf: 2004,
                    tax: [
                      { OT: 202 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1802_2004",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 2004,
                  },
                  routeId: "z48rbyu7zt",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~A~ ~6E~ACUINT~6202~~0~5~~X^0~A~ ~6E~ACUINT~6202~~0~5~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 1833,
                      fbc: "ACUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1833_2036",
                      tax: [
                        { OT: 203 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 2036,
                      psup_fare: "1833_2036",
                    },
                  ],
                  pr: {
                    bf: 1833,
                    tf: 2036,
                    tax: [
                      { OT: 203 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1833_2036",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 2036,
                  },
                  routeId: "67q9qh6v5r",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "gV9VlJcF4lo=|c8vLGEjg5j7NNr8U4/n4JN2DKZGLTH99mWKIwdR5uRWciziCl+wbZTrpWnMmGN+Yy9uJ4ZUTuD0HpLhkPra8oFmlLXu64hUIzZhRraKsuKvdW8dDCeFMBF8RTGVhnAM8gqCD8NTV8Ig=",
              stop: 1,
            },
            {
              tid: "qdF3cRklWyhM5cip_1",
              ref: 1,
              ofcid: "",
              sdur: "15:35",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-05-06T20:30:00",
                  ad: "2023-05-07T03:15:00",
                  rbd: "",
                  fn: "1774",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "3" },
                  rph: "",
                  jsell:
                    "6E~1774~ ~~DOH~05/06/2023 20:30~DEL~05/07/2023 03:15~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-05-07T09:55:00",
                  ad: "2023-05-07T12:05:00",
                  rbd: "",
                  fn: "2005",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "2" },
                  rph: "",
                  jsell:
                    "6E~2005~ ~~DEL~05/07/2023 09:55~HYD~05/07/2023 12:05~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~A~ ~6E~ACINT~6200~~0~65~~X^0~A~ ~6E~ACINT~6200~~0~65~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 1802,
                      fbc: "ACINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1802_2004",
                      tax: [
                        { OT: 202 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 2004,
                      psup_fare: "1802_2004",
                    },
                  ],
                  pr: {
                    bf: 1802,
                    tf: 2004,
                    tax: [
                      { OT: 202 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1802_2004",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 2004,
                  },
                  routeId: "lm2204ycgx",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~A~ ~6E~ACUINT~6202~~0~5~~X^0~A~ ~6E~ACUINT~6202~~0~5~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 1833,
                      fbc: "ACUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1833_2036",
                      tax: [
                        { OT: 203 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 2036,
                      psup_fare: "1833_2036",
                    },
                  ],
                  pr: {
                    bf: 1833,
                    tf: 2036,
                    tax: [
                      { OT: 203 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1833_2036",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 2036,
                  },
                  routeId: "ngmc2gp7yz",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "gV9VlJcF4lo=|c8vLGEjg5j7NNr8U4/n4JN2DKZGLTH99mWKIwdR5uRWciziCl+wbZTrpWnMmGN+Yy9uJ4ZUTuD0HpLhkPra8oFmlLXu64hUIzZhRraKsuKvdW8dDCeFMBF8RTGVhnAM8gqCD8NTV8Ig=",
              stop: 1,
            },
            {
              tid: "WpmSb0QyBF5Seoiv_1",
              ref: 1,
              ofcid: "",
              sdur: "26:55",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-05-06T20:30:00",
                  ad: "2023-05-07T03:15:00",
                  rbd: "",
                  fn: "1774",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "3" },
                  rph: "",
                  jsell:
                    "6E~1774~ ~~DOH~05/06/2023 20:30~DEL~05/07/2023 03:15~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-05-07T21:15:00",
                  ad: "2023-05-07T23:25:00",
                  rbd: "",
                  fn: "2136",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "2" },
                  rph: "",
                  jsell:
                    "6E~2136~ ~~DEL~05/07/2023 21:15~HYD~05/07/2023 23:25~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~A~ ~6E~ACINT~6200~~0~65~~X^0~A~ ~6E~ACINT~6200~~0~65~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 1802,
                      fbc: "ACINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1802_2004",
                      tax: [
                        { OT: 202 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 2004,
                      psup_fare: "1802_2004",
                    },
                  ],
                  pr: {
                    bf: 1802,
                    tf: 2004,
                    tax: [
                      { OT: 202 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1802_2004",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 2004,
                  },
                  routeId: "7eeag6wke3",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~A~ ~6E~ACUINT~6202~~0~5~~X^0~A~ ~6E~ACUINT~6202~~0~5~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 1833,
                      fbc: "ACUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1833_2036",
                      tax: [
                        { OT: 203 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 2036,
                      psup_fare: "1833_2036",
                    },
                  ],
                  pr: {
                    bf: 1833,
                    tf: 2036,
                    tax: [
                      { OT: 203 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1833_2036",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 2036,
                  },
                  routeId: "x3unv1m6ua",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "gV9VlJcF4lo=|c8vLGEjg5j7NNr8U4/n4JN2DKZGLTH99mWKIwdR5uRWciziCl+wbZTrpWnMmGN+Yy9uJ4ZUTuD0HpLhkPra8oFmlLXu64hUIzZhRraKsuKvdW8dDCeFMBF8RTGVhnAM8gqCD8NTV8Ig=",
              stop: 1,
            },
          ],
          destorigins: [
            {
              tid: "RWV69rXZB2lS0MHR_2",
              ref: 2,
              ofcid: "",
              sdur: "2:30",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DOH",
                  dd: "2023-05-08T03:35:00",
                  ad: "2023-05-08T06:05:00",
                  rbd: "",
                  fn: "1713",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: {},
                  rph: "",
                  jsell:
                    "6E~1713~ ~~HYD~05/08/2023 03:35~DOH~05/08/2023 06:05~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
              ],
              fareop: [
                {
                  fareKey: "0~R~ ~6E~R0INT~5000~~0~3~~X",
                  pr: {
                    bf: 278,
                    tf: 432,
                    tax: [
                      { OT: 154 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "278_432",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 432,
                  },
                  paxpr: [
                    {
                      bf: 278,
                      tf: 432,
                      tax: [
                        { OT: 154 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "R0INT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "278_432",
                      psup_fare: "278_432",
                    },
                  ],
                  routeId: "1x0nue0gn5l",
                  productClass: "R",
                  ruleNumber: "5000",
                  servicefee: [],
                  selected: true,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "gV9VlJcF4lo=|c8vLGEjg5j7NNr8U4/n4JN2DKZGLTH99mWKIwdR5uRWciziCl+wbZTrpWnMmGN+Yy9uJ4ZUTuD0HpLhkPra8oFmlLXu64hUIzZhRraKsuKvdW8dDCeFMBF8RTGVhnAM8gqCD8NTV8Ig=",
              stop: 0,
            },
          ],
          paxpr: [
            {
              bf: 2080,
              fbc: "ACINT",
              ptype: "ADT",
              scur: "QAR",
              sup_fare: "1802_2004",
              tax: [
                { OT: 356 },
                { attmkp: 0 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              tf: 2436,
              psup_fare: "2080_2436",
            },
          ],
          pr: {
            bf: 2080,
            tf: 2436,
            tax: [
              { OT: 356 },
              { attmkp: 0 },
              { tamkp: 0 },
              { stamkp: 0 },
              { attdisc: 0 },
              { tadisc: 0 },
              { stadisc: 0 },
            ],
            ucur: "QAR",
            exchange_rate: 1,
            scur: "QAR",
            sup_fare: "2080_2436",
            sup_cur_fare: "",
            cabb: "",
            chekb: "",
            seats: "",
            meals: "",
            chanb: "",
            ftyp: "Regular - R",
            markupnames: [],
            ttf: 2436,
          },
          sup: "4",
          airlineList: "6E",
          servicefee: [],
        },
        {
          srcorigns: [
            {
              tid: "ATGd6Ul6DFXI4hQT_1",
              ref: 1,
              ofcid: "",
              sdur: "19:30",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-05-06T02:10:00",
                  ad: "2023-05-06T08:40:00",
                  rbd: "",
                  fn: "1737",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "3" },
                  rph: "",
                  jsell:
                    "6E~1737~ ~~DOH~05/06/2023 02:10~DEL~05/06/2023 08:40~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-05-06T19:30:00",
                  ad: "2023-05-06T21:40:00",
                  rbd: "",
                  fn: "2104",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "2" },
                  rph: "",
                  jsell:
                    "6E~2104~ ~~DEL~05/06/2023 19:30~HYD~05/06/2023 21:40~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~A~ ~6E~ACINT~6200~~0~65~~X^0~A~ ~6E~ACINT~6200~~0~65~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 1802,
                      fbc: "ACINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1802_2004",
                      tax: [
                        { OT: 202 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 2004,
                      psup_fare: "1802_2004",
                    },
                  ],
                  pr: {
                    bf: 1802,
                    tf: 2004,
                    tax: [
                      { OT: 202 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1802_2004",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 2004,
                  },
                  routeId: "fvxktneksp",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~A~ ~6E~ACUINT~6202~~0~5~~X^0~A~ ~6E~ACUINT~6202~~0~5~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 1833,
                      fbc: "ACUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1833_2036",
                      tax: [
                        { OT: 203 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 2036,
                      psup_fare: "1833_2036",
                    },
                  ],
                  pr: {
                    bf: 1833,
                    tf: 2036,
                    tax: [
                      { OT: 203 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1833_2036",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 2036,
                  },
                  routeId: "maw6rrjced",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "gV9VlJcF4lo=|c8vLGEjg5j7NNr8U4/n4JN2DKZGLTH99mWKIwdR5uRWciziCl+wbZTrpWnMmGN+Yy9uJ4ZUTuD0HpLhkPra8oFmlLXu64hUIzZhRraKsuKvdW8dDCeFMBF8RTGVhnAM8gqCD8NTV8Ig=",
              stop: 1,
            },
            {
              tid: "QO7qaNgssH6sMva1_1",
              ref: 1,
              ofcid: "",
              sdur: "12:40",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-05-06T02:10:00",
                  ad: "2023-05-06T08:40:00",
                  rbd: "",
                  fn: "1737",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "3" },
                  rph: "",
                  jsell:
                    "6E~1737~ ~~DOH~05/06/2023 02:10~DEL~05/06/2023 08:40~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-05-06T12:45:00",
                  ad: "2023-05-06T14:50:00",
                  rbd: "",
                  fn: "2004",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "2" },
                  rph: "",
                  jsell:
                    "6E~2004~ ~~DEL~05/06/2023 12:45~HYD~05/06/2023 14:50~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~A~ ~6E~ACINT~6200~~0~65~~X^0~A~ ~6E~ACINT~6200~~0~65~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 1802,
                      fbc: "ACINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1802_2004",
                      tax: [
                        { OT: 202 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 2004,
                      psup_fare: "1802_2004",
                    },
                  ],
                  pr: {
                    bf: 1802,
                    tf: 2004,
                    tax: [
                      { OT: 202 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1802_2004",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 2004,
                  },
                  routeId: "4tow7l2ohj",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~A~ ~6E~ACUINT~6202~~0~5~~X^0~A~ ~6E~ACUINT~6202~~0~5~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 1833,
                      fbc: "ACUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1833_2036",
                      tax: [
                        { OT: 203 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 2036,
                      psup_fare: "1833_2036",
                    },
                  ],
                  pr: {
                    bf: 1833,
                    tf: 2036,
                    tax: [
                      { OT: 203 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1833_2036",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 2036,
                  },
                  routeId: "2b5ov8si39i",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "gV9VlJcF4lo=|c8vLGEjg5j7NNr8U4/n4JN2DKZGLTH99mWKIwdR5uRWciziCl+wbZTrpWnMmGN+Yy9uJ4ZUTuD0HpLhkPra8oFmlLXu64hUIzZhRraKsuKvdW8dDCeFMBF8RTGVhnAM8gqCD8NTV8Ig=",
              stop: 1,
            },
            {
              tid: "ZzxACwPCbdTpAkp1_1",
              ref: 1,
              ofcid: "",
              sdur: "13:20",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-05-06T02:10:00",
                  ad: "2023-05-06T08:40:00",
                  rbd: "",
                  fn: "1737",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "3" },
                  rph: "",
                  jsell:
                    "6E~1737~ ~~DOH~05/06/2023 02:10~DEL~05/06/2023 08:40~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-05-06T13:25:00",
                  ad: "2023-05-06T15:30:00",
                  rbd: "",
                  fn: "2014",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "2" },
                  rph: "",
                  jsell:
                    "6E~2014~ ~~DEL~05/06/2023 13:25~HYD~05/06/2023 15:30~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~A~ ~6E~ACINT~6200~~0~65~~X^0~A~ ~6E~ACINT~6200~~0~65~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 1802,
                      fbc: "ACINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1802_2004",
                      tax: [
                        { OT: 202 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 2004,
                      psup_fare: "1802_2004",
                    },
                  ],
                  pr: {
                    bf: 1802,
                    tf: 2004,
                    tax: [
                      { OT: 202 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1802_2004",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 2004,
                  },
                  routeId: "srugv0sqyii",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~A~ ~6E~ACUINT~6202~~0~5~~X^0~A~ ~6E~ACUINT~6202~~0~5~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 1833,
                      fbc: "ACUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1833_2036",
                      tax: [
                        { OT: 203 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 2036,
                      psup_fare: "1833_2036",
                    },
                  ],
                  pr: {
                    bf: 1833,
                    tf: 2036,
                    tax: [
                      { OT: 203 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1833_2036",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 2036,
                  },
                  routeId: "kqc5aavv7i",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "gV9VlJcF4lo=|c8vLGEjg5j7NNr8U4/n4JN2DKZGLTH99mWKIwdR5uRWciziCl+wbZTrpWnMmGN+Yy9uJ4ZUTuD0HpLhkPra8oFmlLXu64hUIzZhRraKsuKvdW8dDCeFMBF8RTGVhnAM8gqCD8NTV8Ig=",
              stop: 1,
            },
            {
              tid: "3nKpAHfCund7SMnq_1",
              ref: 1,
              ofcid: "",
              sdur: "18:0",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-05-06T02:10:00",
                  ad: "2023-05-06T08:40:00",
                  rbd: "",
                  fn: "1737",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "3" },
                  rph: "",
                  jsell:
                    "6E~1737~ ~~DOH~05/06/2023 02:10~DEL~05/06/2023 08:40~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-05-06T18:00:00",
                  ad: "2023-05-06T20:10:00",
                  rbd: "",
                  fn: "6823",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "1" },
                  rph: "",
                  jsell:
                    "6E~6823~ ~~DEL~05/06/2023 18:00~HYD~05/06/2023 20:10~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~A~ ~6E~ACINT~6200~~0~65~~X^0~A~ ~6E~ACINT~6200~~0~65~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 1802,
                      fbc: "ACINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1802_2004",
                      tax: [
                        { OT: 202 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 2004,
                      psup_fare: "1802_2004",
                    },
                  ],
                  pr: {
                    bf: 1802,
                    tf: 2004,
                    tax: [
                      { OT: 202 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1802_2004",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 2004,
                  },
                  routeId: "eraa7jkap",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~A~ ~6E~ACUINT~6202~~0~5~~X^0~A~ ~6E~ACUINT~6202~~0~5~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 1833,
                      fbc: "ACUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1833_2036",
                      tax: [
                        { OT: 203 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 2036,
                      psup_fare: "1833_2036",
                    },
                  ],
                  pr: {
                    bf: 1833,
                    tf: 2036,
                    tax: [
                      { OT: 203 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1833_2036",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 2036,
                  },
                  routeId: "umx7fa6qje",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "gV9VlJcF4lo=|c8vLGEjg5j7NNr8U4/n4JN2DKZGLTH99mWKIwdR5uRWciziCl+wbZTrpWnMmGN+Yy9uJ4ZUTuD0HpLhkPra8oFmlLXu64hUIzZhRraKsuKvdW8dDCeFMBF8RTGVhnAM8gqCD8NTV8Ig=",
              stop: 1,
            },
            {
              tid: "WBAib6VAlwfeiUrx_1",
              ref: 1,
              ofcid: "",
              sdur: "14:45",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-05-06T02:10:00",
                  ad: "2023-05-06T08:40:00",
                  rbd: "",
                  fn: "1737",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "3" },
                  rph: "",
                  jsell:
                    "6E~1737~ ~~DOH~05/06/2023 02:10~DEL~05/06/2023 08:40~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-05-06T15:00:00",
                  ad: "2023-05-06T16:55:00",
                  rbd: "",
                  fn: "2187",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "2" },
                  rph: "",
                  jsell:
                    "6E~2187~ ~~DEL~05/06/2023 15:00~HYD~05/06/2023 16:55~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~A~ ~6E~ACINT~6200~~0~65~~X^0~A~ ~6E~ACINT~6200~~0~65~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 1802,
                      fbc: "ACINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1802_2004",
                      tax: [
                        { OT: 202 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 2004,
                      psup_fare: "1802_2004",
                    },
                  ],
                  pr: {
                    bf: 1802,
                    tf: 2004,
                    tax: [
                      { OT: 202 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1802_2004",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 2004,
                  },
                  routeId: "175txao85rf",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~A~ ~6E~ACUINT~6202~~0~5~~X^0~A~ ~6E~ACUINT~6202~~0~5~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 1833,
                      fbc: "ACUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1833_2036",
                      tax: [
                        { OT: 203 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 2036,
                      psup_fare: "1833_2036",
                    },
                  ],
                  pr: {
                    bf: 1833,
                    tf: 2036,
                    tax: [
                      { OT: 203 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1833_2036",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 2036,
                  },
                  routeId: "cf8bnuuyszk",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "gV9VlJcF4lo=|c8vLGEjg5j7NNr8U4/n4JN2DKZGLTH99mWKIwdR5uRWciziCl+wbZTrpWnMmGN+Yy9uJ4ZUTuD0HpLhkPra8oFmlLXu64hUIzZhRraKsuKvdW8dDCeFMBF8RTGVhnAM8gqCD8NTV8Ig=",
              stop: 1,
            },
            {
              tid: "iq8vFgy1n7lvMw7z_1",
              ref: 1,
              ofcid: "",
              sdur: "25:50",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-05-06T02:10:00",
                  ad: "2023-05-06T08:40:00",
                  rbd: "",
                  fn: "1737",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "3" },
                  rph: "",
                  jsell:
                    "6E~1737~ ~~DOH~05/06/2023 02:10~DEL~05/06/2023 08:40~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-05-07T01:50:00",
                  ad: "2023-05-07T04:00:00",
                  rbd: "",
                  fn: "2325",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "2" },
                  rph: "",
                  jsell:
                    "6E~2325~ ~~DEL~05/07/2023 01:50~HYD~05/07/2023 04:00~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~A~ ~6E~ACINT~6200~~0~65~~X^0~A~ ~6E~ACINT~6200~~0~65~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 1802,
                      fbc: "ACINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1802_2004",
                      tax: [
                        { OT: 202 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 2004,
                      psup_fare: "1802_2004",
                    },
                  ],
                  pr: {
                    bf: 1802,
                    tf: 2004,
                    tax: [
                      { OT: 202 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1802_2004",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 2004,
                  },
                  routeId: "gvjs9o5gfr",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~A~ ~6E~ACUINT~6202~~0~5~~X^0~A~ ~6E~ACUINT~6202~~0~5~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 1833,
                      fbc: "ACUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1833_2036",
                      tax: [
                        { OT: 203 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 2036,
                      psup_fare: "1833_2036",
                    },
                  ],
                  pr: {
                    bf: 1833,
                    tf: 2036,
                    tax: [
                      { OT: 203 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1833_2036",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 2036,
                  },
                  routeId: "a7uvvezvfw",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "gV9VlJcF4lo=|c8vLGEjg5j7NNr8U4/n4JN2DKZGLTH99mWKIwdR5uRWciziCl+wbZTrpWnMmGN+Yy9uJ4ZUTuD0HpLhkPra8oFmlLXu64hUIzZhRraKsuKvdW8dDCeFMBF8RTGVhnAM8gqCD8NTV8Ig=",
              stop: 1,
            },
            {
              tid: "8ymeHO1nugVsJ2Zn_1",
              ref: 1,
              ofcid: "",
              sdur: "13:45",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-05-06T20:30:00",
                  ad: "2023-05-07T03:15:00",
                  rbd: "",
                  fn: "1774",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "3" },
                  rph: "",
                  jsell:
                    "6E~1774~ ~~DOH~05/06/2023 20:30~DEL~05/07/2023 03:15~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-05-07T07:55:00",
                  ad: "2023-05-07T10:15:00",
                  rbd: "",
                  fn: "2774",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "2" },
                  rph: "",
                  jsell:
                    "6E~2774~ ~~DEL~05/07/2023 07:55~HYD~05/07/2023 10:15~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~A~ ~6E~ACINT~6200~~0~65~~X^0~A~ ~6E~ACINT~6200~~0~65~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 1802,
                      fbc: "ACINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1802_2004",
                      tax: [
                        { OT: 202 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 2004,
                      psup_fare: "1802_2004",
                    },
                  ],
                  pr: {
                    bf: 1802,
                    tf: 2004,
                    tax: [
                      { OT: 202 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1802_2004",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 2004,
                  },
                  routeId: "kn44afx98a",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~A~ ~6E~ACUINT~6202~~0~5~~X^0~A~ ~6E~ACUINT~6202~~0~5~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 1833,
                      fbc: "ACUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1833_2036",
                      tax: [
                        { OT: 203 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 2036,
                      psup_fare: "1833_2036",
                    },
                  ],
                  pr: {
                    bf: 1833,
                    tf: 2036,
                    tax: [
                      { OT: 203 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1833_2036",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 2036,
                  },
                  routeId: "nrxikj38vm",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "gV9VlJcF4lo=|c8vLGEjg5j7NNr8U4/n4JN2DKZGLTH99mWKIwdR5uRWciziCl+wbZTrpWnMmGN+Yy9uJ4ZUTuD0HpLhkPra8oFmlLXu64hUIzZhRraKsuKvdW8dDCeFMBF8RTGVhnAM8gqCD8NTV8Ig=",
              stop: 1,
            },
            {
              tid: "KkcViH5vac3uJR4K_1",
              ref: 1,
              ofcid: "",
              sdur: "18:20",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-05-06T20:30:00",
                  ad: "2023-05-07T03:15:00",
                  rbd: "",
                  fn: "1774",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "3" },
                  rph: "",
                  jsell:
                    "6E~1774~ ~~DOH~05/06/2023 20:30~DEL~05/07/2023 03:15~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-05-07T12:45:00",
                  ad: "2023-05-07T14:50:00",
                  rbd: "",
                  fn: "2004",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "2" },
                  rph: "",
                  jsell:
                    "6E~2004~ ~~DEL~05/07/2023 12:45~HYD~05/07/2023 14:50~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~A~ ~6E~ACINT~6200~~0~65~~X^0~A~ ~6E~ACINT~6200~~0~65~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 1802,
                      fbc: "ACINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1802_2004",
                      tax: [
                        { OT: 202 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 2004,
                      psup_fare: "1802_2004",
                    },
                  ],
                  pr: {
                    bf: 1802,
                    tf: 2004,
                    tax: [
                      { OT: 202 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1802_2004",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 2004,
                  },
                  routeId: "5m79dpcadu",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~A~ ~6E~ACUINT~6202~~0~5~~X^0~A~ ~6E~ACUINT~6202~~0~5~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 1833,
                      fbc: "ACUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1833_2036",
                      tax: [
                        { OT: 203 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 2036,
                      psup_fare: "1833_2036",
                    },
                  ],
                  pr: {
                    bf: 1833,
                    tf: 2036,
                    tax: [
                      { OT: 203 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1833_2036",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 2036,
                  },
                  routeId: "1gmm6scn7f",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "gV9VlJcF4lo=|c8vLGEjg5j7NNr8U4/n4JN2DKZGLTH99mWKIwdR5uRWciziCl+wbZTrpWnMmGN+Yy9uJ4ZUTuD0HpLhkPra8oFmlLXu64hUIzZhRraKsuKvdW8dDCeFMBF8RTGVhnAM8gqCD8NTV8Ig=",
              stop: 1,
            },
            {
              tid: "3Qggny4zFAzdI0qy_1",
              ref: 1,
              ofcid: "",
              sdur: "19:0",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-05-06T20:30:00",
                  ad: "2023-05-07T03:15:00",
                  rbd: "",
                  fn: "1774",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "3" },
                  rph: "",
                  jsell:
                    "6E~1774~ ~~DOH~05/06/2023 20:30~DEL~05/07/2023 03:15~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-05-07T13:25:00",
                  ad: "2023-05-07T15:30:00",
                  rbd: "",
                  fn: "2014",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "2" },
                  rph: "",
                  jsell:
                    "6E~2014~ ~~DEL~05/07/2023 13:25~HYD~05/07/2023 15:30~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~A~ ~6E~ACINT~6200~~0~65~~X^0~A~ ~6E~ACINT~6200~~0~65~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 1802,
                      fbc: "ACINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1802_2004",
                      tax: [
                        { OT: 202 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 2004,
                      psup_fare: "1802_2004",
                    },
                  ],
                  pr: {
                    bf: 1802,
                    tf: 2004,
                    tax: [
                      { OT: 202 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1802_2004",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 2004,
                  },
                  routeId: "aepk857s4i",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~A~ ~6E~ACUINT~6202~~0~5~~X^0~A~ ~6E~ACUINT~6202~~0~5~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 1833,
                      fbc: "ACUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1833_2036",
                      tax: [
                        { OT: 203 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 2036,
                      psup_fare: "1833_2036",
                    },
                  ],
                  pr: {
                    bf: 1833,
                    tf: 2036,
                    tax: [
                      { OT: 203 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1833_2036",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 2036,
                  },
                  routeId: "dwaxlpyk7u",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "gV9VlJcF4lo=|c8vLGEjg5j7NNr8U4/n4JN2DKZGLTH99mWKIwdR5uRWciziCl+wbZTrpWnMmGN+Yy9uJ4ZUTuD0HpLhkPra8oFmlLXu64hUIzZhRraKsuKvdW8dDCeFMBF8RTGVhnAM8gqCD8NTV8Ig=",
              stop: 1,
            },
            {
              tid: "c03NLrGqD0fXVWRU_1",
              ref: 1,
              ofcid: "",
              sdur: "12:30",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-05-06T20:30:00",
                  ad: "2023-05-07T03:15:00",
                  rbd: "",
                  fn: "1774",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "3" },
                  rph: "",
                  jsell:
                    "6E~1774~ ~~DOH~05/06/2023 20:30~DEL~05/07/2023 03:15~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-05-07T06:40:00",
                  ad: "2023-05-07T09:00:00",
                  rbd: "",
                  fn: "2331",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "2" },
                  rph: "",
                  jsell:
                    "6E~2331~ ~~DEL~05/07/2023 06:40~HYD~05/07/2023 09:00~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~A~ ~6E~ACINT~6200~~0~65~~X^0~A~ ~6E~ACINT~6200~~0~65~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 1802,
                      fbc: "ACINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1802_2004",
                      tax: [
                        { OT: 202 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 2004,
                      psup_fare: "1802_2004",
                    },
                  ],
                  pr: {
                    bf: 1802,
                    tf: 2004,
                    tax: [
                      { OT: 202 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1802_2004",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 2004,
                  },
                  routeId: "ezj4xudid7",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~A~ ~6E~ACUINT~6202~~0~5~~X^0~A~ ~6E~ACUINT~6202~~0~5~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 1833,
                      fbc: "ACUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1833_2036",
                      tax: [
                        { OT: 203 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 2036,
                      psup_fare: "1833_2036",
                    },
                  ],
                  pr: {
                    bf: 1833,
                    tf: 2036,
                    tax: [
                      { OT: 203 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1833_2036",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 2036,
                  },
                  routeId: "8bu08ruoeu",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "gV9VlJcF4lo=|c8vLGEjg5j7NNr8U4/n4JN2DKZGLTH99mWKIwdR5uRWciziCl+wbZTrpWnMmGN+Yy9uJ4ZUTuD0HpLhkPra8oFmlLXu64hUIzZhRraKsuKvdW8dDCeFMBF8RTGVhnAM8gqCD8NTV8Ig=",
              stop: 1,
            },
            {
              tid: "oL5gcHOabpIeM3PW_1",
              ref: 1,
              ofcid: "",
              sdur: "20:25",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-05-06T20:30:00",
                  ad: "2023-05-07T03:15:00",
                  rbd: "",
                  fn: "1774",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "3" },
                  rph: "",
                  jsell:
                    "6E~1774~ ~~DOH~05/06/2023 20:30~DEL~05/07/2023 03:15~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-05-07T15:00:00",
                  ad: "2023-05-07T16:55:00",
                  rbd: "",
                  fn: "2187",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "2" },
                  rph: "",
                  jsell:
                    "6E~2187~ ~~DEL~05/07/2023 15:00~HYD~05/07/2023 16:55~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~A~ ~6E~ACINT~6200~~0~65~~X^0~A~ ~6E~ACINT~6200~~0~65~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 1802,
                      fbc: "ACINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1802_2004",
                      tax: [
                        { OT: 202 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 2004,
                      psup_fare: "1802_2004",
                    },
                  ],
                  pr: {
                    bf: 1802,
                    tf: 2004,
                    tax: [
                      { OT: 202 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1802_2004",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 2004,
                  },
                  routeId: "kemzj23hrn",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~A~ ~6E~ACUINT~6202~~0~5~~X^0~A~ ~6E~ACUINT~6202~~0~5~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 1833,
                      fbc: "ACUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1833_2036",
                      tax: [
                        { OT: 203 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 2036,
                      psup_fare: "1833_2036",
                    },
                  ],
                  pr: {
                    bf: 1833,
                    tf: 2036,
                    tax: [
                      { OT: 203 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1833_2036",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 2036,
                  },
                  routeId: "21xutw51bhi",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "gV9VlJcF4lo=|c8vLGEjg5j7NNr8U4/n4JN2DKZGLTH99mWKIwdR5uRWciziCl+wbZTrpWnMmGN+Yy9uJ4ZUTuD0HpLhkPra8oFmlLXu64hUIzZhRraKsuKvdW8dDCeFMBF8RTGVhnAM8gqCD8NTV8Ig=",
              stop: 1,
            },
            {
              tid: "A5K5ZRaOnILKqCVg_1",
              ref: 1,
              ofcid: "",
              sdur: "23:40",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-05-06T20:30:00",
                  ad: "2023-05-07T03:15:00",
                  rbd: "",
                  fn: "1774",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "3" },
                  rph: "",
                  jsell:
                    "6E~1774~ ~~DOH~05/06/2023 20:30~DEL~05/07/2023 03:15~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-05-07T18:00:00",
                  ad: "2023-05-07T20:10:00",
                  rbd: "",
                  fn: "6823",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "1" },
                  rph: "",
                  jsell:
                    "6E~6823~ ~~DEL~05/07/2023 18:00~HYD~05/07/2023 20:10~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~A~ ~6E~ACINT~6200~~0~65~~X^0~A~ ~6E~ACINT~6200~~0~65~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 1802,
                      fbc: "ACINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1802_2004",
                      tax: [
                        { OT: 202 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 2004,
                      psup_fare: "1802_2004",
                    },
                  ],
                  pr: {
                    bf: 1802,
                    tf: 2004,
                    tax: [
                      { OT: 202 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1802_2004",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 2004,
                  },
                  routeId: "riajf49wjqk",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~A~ ~6E~ACUINT~6202~~0~5~~X^0~A~ ~6E~ACUINT~6202~~0~5~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 1833,
                      fbc: "ACUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1833_2036",
                      tax: [
                        { OT: 203 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 2036,
                      psup_fare: "1833_2036",
                    },
                  ],
                  pr: {
                    bf: 1833,
                    tf: 2036,
                    tax: [
                      { OT: 203 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1833_2036",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 2036,
                  },
                  routeId: "b4g8fgyf4t",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "gV9VlJcF4lo=|c8vLGEjg5j7NNr8U4/n4JN2DKZGLTH99mWKIwdR5uRWciziCl+wbZTrpWnMmGN+Yy9uJ4ZUTuD0HpLhkPra8oFmlLXu64hUIzZhRraKsuKvdW8dDCeFMBF8RTGVhnAM8gqCD8NTV8Ig=",
              stop: 1,
            },
            {
              tid: "XJMQgQTGR1Nd52pk_1",
              ref: 1,
              ofcid: "",
              sdur: "25:10",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-05-06T20:30:00",
                  ad: "2023-05-07T03:15:00",
                  rbd: "",
                  fn: "1774",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "3" },
                  rph: "",
                  jsell:
                    "6E~1774~ ~~DOH~05/06/2023 20:30~DEL~05/07/2023 03:15~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-05-07T19:30:00",
                  ad: "2023-05-07T21:40:00",
                  rbd: "",
                  fn: "2104",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "2" },
                  rph: "",
                  jsell:
                    "6E~2104~ ~~DEL~05/07/2023 19:30~HYD~05/07/2023 21:40~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~A~ ~6E~ACINT~6200~~0~65~~X^0~A~ ~6E~ACINT~6200~~0~65~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 1802,
                      fbc: "ACINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1802_2004",
                      tax: [
                        { OT: 202 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 2004,
                      psup_fare: "1802_2004",
                    },
                  ],
                  pr: {
                    bf: 1802,
                    tf: 2004,
                    tax: [
                      { OT: 202 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1802_2004",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 2004,
                  },
                  routeId: "z48rbyu7zt",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~A~ ~6E~ACUINT~6202~~0~5~~X^0~A~ ~6E~ACUINT~6202~~0~5~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 1833,
                      fbc: "ACUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1833_2036",
                      tax: [
                        { OT: 203 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 2036,
                      psup_fare: "1833_2036",
                    },
                  ],
                  pr: {
                    bf: 1833,
                    tf: 2036,
                    tax: [
                      { OT: 203 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1833_2036",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 2036,
                  },
                  routeId: "67q9qh6v5r",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "gV9VlJcF4lo=|c8vLGEjg5j7NNr8U4/n4JN2DKZGLTH99mWKIwdR5uRWciziCl+wbZTrpWnMmGN+Yy9uJ4ZUTuD0HpLhkPra8oFmlLXu64hUIzZhRraKsuKvdW8dDCeFMBF8RTGVhnAM8gqCD8NTV8Ig=",
              stop: 1,
            },
            {
              tid: "r1yD4AWRCdVzhtyx_1",
              ref: 1,
              ofcid: "",
              sdur: "15:35",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-05-06T20:30:00",
                  ad: "2023-05-07T03:15:00",
                  rbd: "",
                  fn: "1774",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "3" },
                  rph: "",
                  jsell:
                    "6E~1774~ ~~DOH~05/06/2023 20:30~DEL~05/07/2023 03:15~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-05-07T09:55:00",
                  ad: "2023-05-07T12:05:00",
                  rbd: "",
                  fn: "2005",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "2" },
                  rph: "",
                  jsell:
                    "6E~2005~ ~~DEL~05/07/2023 09:55~HYD~05/07/2023 12:05~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~A~ ~6E~ACINT~6200~~0~65~~X^0~A~ ~6E~ACINT~6200~~0~65~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 1802,
                      fbc: "ACINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1802_2004",
                      tax: [
                        { OT: 202 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 2004,
                      psup_fare: "1802_2004",
                    },
                  ],
                  pr: {
                    bf: 1802,
                    tf: 2004,
                    tax: [
                      { OT: 202 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1802_2004",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 2004,
                  },
                  routeId: "lm2204ycgx",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~A~ ~6E~ACUINT~6202~~0~5~~X^0~A~ ~6E~ACUINT~6202~~0~5~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 1833,
                      fbc: "ACUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1833_2036",
                      tax: [
                        { OT: 203 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 2036,
                      psup_fare: "1833_2036",
                    },
                  ],
                  pr: {
                    bf: 1833,
                    tf: 2036,
                    tax: [
                      { OT: 203 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1833_2036",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 2036,
                  },
                  routeId: "ngmc2gp7yz",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "gV9VlJcF4lo=|c8vLGEjg5j7NNr8U4/n4JN2DKZGLTH99mWKIwdR5uRWciziCl+wbZTrpWnMmGN+Yy9uJ4ZUTuD0HpLhkPra8oFmlLXu64hUIzZhRraKsuKvdW8dDCeFMBF8RTGVhnAM8gqCD8NTV8Ig=",
              stop: 1,
            },
            {
              tid: "w86H3HTkxhTOLXWr_1",
              ref: 1,
              ofcid: "",
              sdur: "26:55",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-05-06T20:30:00",
                  ad: "2023-05-07T03:15:00",
                  rbd: "",
                  fn: "1774",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "3" },
                  rph: "",
                  jsell:
                    "6E~1774~ ~~DOH~05/06/2023 20:30~DEL~05/07/2023 03:15~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-05-07T21:15:00",
                  ad: "2023-05-07T23:25:00",
                  rbd: "",
                  fn: "2136",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "2" },
                  rph: "",
                  jsell:
                    "6E~2136~ ~~DEL~05/07/2023 21:15~HYD~05/07/2023 23:25~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~A~ ~6E~ACINT~6200~~0~65~~X^0~A~ ~6E~ACINT~6200~~0~65~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 1802,
                      fbc: "ACINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1802_2004",
                      tax: [
                        { OT: 202 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 2004,
                      psup_fare: "1802_2004",
                    },
                  ],
                  pr: {
                    bf: 1802,
                    tf: 2004,
                    tax: [
                      { OT: 202 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1802_2004",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 2004,
                  },
                  routeId: "7eeag6wke3",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~A~ ~6E~ACUINT~6202~~0~5~~X^0~A~ ~6E~ACUINT~6202~~0~5~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 1833,
                      fbc: "ACUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1833_2036",
                      tax: [
                        { OT: 203 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 2036,
                      psup_fare: "1833_2036",
                    },
                  ],
                  pr: {
                    bf: 1833,
                    tf: 2036,
                    tax: [
                      { OT: 203 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1833_2036",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 2036,
                  },
                  routeId: "x3unv1m6ua",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "gV9VlJcF4lo=|c8vLGEjg5j7NNr8U4/n4JN2DKZGLTH99mWKIwdR5uRWciziCl+wbZTrpWnMmGN+Yy9uJ4ZUTuD0HpLhkPra8oFmlLXu64hUIzZhRraKsuKvdW8dDCeFMBF8RTGVhnAM8gqCD8NTV8Ig=",
              stop: 1,
            },
          ],
          destorigins: [
            {
              tid: "gDpRWNTNXoeDQM7t_2",
              ref: 2,
              ofcid: "",
              sdur: "10:20",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "MAA",
                  dd: "2023-05-08T13:35:00",
                  ad: "2023-05-08T15:10:00",
                  rbd: "",
                  fn: " 514",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "1" },
                  rph: "",
                  jsell:
                    "6E~ 514~ ~~HYD~05/08/2023 13:35~MAA~05/08/2023 15:10~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
                {
                  ref: 2,
                  da: "MAA",
                  ar: "DOH",
                  dd: "2023-05-08T21:55:00",
                  ad: "2023-05-08T23:55:00",
                  rbd: "",
                  fn: "1775",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "4" },
                  rph: "",
                  jsell:
                    "6E~1775~ ~~MAA~05/08/2023 21:55~DOH~05/08/2023 23:55~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~R~ ~6E~RCINT~6200~~0~66~~X^0~R~ ~6E~RCINT~6200~~0~66~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 354,
                      fbc: "RCINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "354_516",
                      tax: [
                        { OT: 162 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 516,
                      psup_fare: "354_516",
                    },
                  ],
                  pr: {
                    bf: 354,
                    tf: 516,
                    tax: [
                      { OT: 162 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "354_516",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 516,
                  },
                  routeId: "jwxwmroowm",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~R~ ~6E~RCUINT~6202~~0~5~~X^0~R~ ~6E~RCUINT~6202~~0~5~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 384,
                      fbc: "RCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "384_548",
                      tax: [
                        { OT: 164 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 548,
                      psup_fare: "384_548",
                    },
                  ],
                  pr: {
                    bf: 384,
                    tf: 548,
                    tax: [
                      { OT: 164 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "384_548",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 548,
                  },
                  routeId: "tj9m9sm5itl",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "gV9VlJcF4lo=|c8vLGEjg5j7NNr8U4/n4JN2DKZGLTH99mWKIwdR5uRWciziCl+wbZTrpWnMmGN+Yy9uJ4ZUTuD0HpLhkPra8oFmlLXu64hUIzZhRraKsuKvdW8dDCeFMBF8RTGVhnAM8gqCD8NTV8Ig=",
              stop: 1,
            },
            {
              tid: "TbWtL1dzXfwJKzrr_2",
              ref: 2,
              ofcid: "",
              sdur: "7:35",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "MAA",
                  dd: "2023-05-08T16:20:00",
                  ad: "2023-05-08T17:35:00",
                  rbd: "",
                  fn: " 668",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "1" },
                  rph: "",
                  jsell:
                    "6E~ 668~ ~~HYD~05/08/2023 16:20~MAA~05/08/2023 17:35~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
                {
                  ref: 2,
                  da: "MAA",
                  ar: "DOH",
                  dd: "2023-05-08T21:55:00",
                  ad: "2023-05-08T23:55:00",
                  rbd: "",
                  fn: "1775",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "4" },
                  rph: "",
                  jsell:
                    "6E~1775~ ~~MAA~05/08/2023 21:55~DOH~05/08/2023 23:55~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~R~ ~6E~RCINT~6200~~0~66~~X^0~R~ ~6E~RCINT~6200~~0~66~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 354,
                      fbc: "RCINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "354_516",
                      tax: [
                        { OT: 162 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 516,
                      psup_fare: "354_516",
                    },
                  ],
                  pr: {
                    bf: 354,
                    tf: 516,
                    tax: [
                      { OT: 162 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "354_516",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 516,
                  },
                  routeId: "8w49kelgtn",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~R~ ~6E~RCUINT~6202~~0~5~~X^0~R~ ~6E~RCUINT~6202~~0~5~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 384,
                      fbc: "RCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "384_548",
                      tax: [
                        { OT: 164 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 548,
                      psup_fare: "384_548",
                    },
                  ],
                  pr: {
                    bf: 384,
                    tf: 548,
                    tax: [
                      { OT: 164 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "384_548",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 548,
                  },
                  routeId: "fhib07l78u",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "gV9VlJcF4lo=|c8vLGEjg5j7NNr8U4/n4JN2DKZGLTH99mWKIwdR5uRWciziCl+wbZTrpWnMmGN+Yy9uJ4ZUTuD0HpLhkPra8oFmlLXu64hUIzZhRraKsuKvdW8dDCeFMBF8RTGVhnAM8gqCD8NTV8Ig=",
              stop: 1,
            },
            {
              tid: "PM9PU3TsbbW193XO_2",
              ref: 2,
              ofcid: "",
              sdur: "6:0",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "MAA",
                  dd: "2023-05-08T17:55:00",
                  ad: "2023-05-08T19:15:00",
                  rbd: "",
                  fn: " 102",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "1" },
                  rph: "",
                  jsell:
                    "6E~ 102~ ~~HYD~05/08/2023 17:55~MAA~05/08/2023 19:15~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
                {
                  ref: 2,
                  da: "MAA",
                  ar: "DOH",
                  dd: "2023-05-08T21:55:00",
                  ad: "2023-05-08T23:55:00",
                  rbd: "",
                  fn: "1775",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "4" },
                  rph: "",
                  jsell:
                    "6E~1775~ ~~MAA~05/08/2023 21:55~DOH~05/08/2023 23:55~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~R~ ~6E~RCINT~6200~~0~66~~X^0~R~ ~6E~RCINT~6200~~0~66~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 354,
                      fbc: "RCINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "354_516",
                      tax: [
                        { OT: 162 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 516,
                      psup_fare: "354_516",
                    },
                  ],
                  pr: {
                    bf: 354,
                    tf: 516,
                    tax: [
                      { OT: 162 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "354_516",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 516,
                  },
                  routeId: "uid6c42kvw",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~R~ ~6E~RCUINT~6202~~0~5~~X^0~R~ ~6E~RCUINT~6202~~0~5~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 384,
                      fbc: "RCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "384_548",
                      tax: [
                        { OT: 164 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 548,
                      psup_fare: "384_548",
                    },
                  ],
                  pr: {
                    bf: 384,
                    tf: 548,
                    tax: [
                      { OT: 164 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "384_548",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 548,
                  },
                  routeId: "1i3tk563qfj",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "gV9VlJcF4lo=|c8vLGEjg5j7NNr8U4/n4JN2DKZGLTH99mWKIwdR5uRWciziCl+wbZTrpWnMmGN+Yy9uJ4ZUTuD0HpLhkPra8oFmlLXu64hUIzZhRraKsuKvdW8dDCeFMBF8RTGVhnAM8gqCD8NTV8Ig=",
              stop: 1,
            },
          ],
          paxpr: [
            {
              bf: 2156,
              fbc: "ACINT",
              ptype: "ADT",
              scur: "QAR",
              sup_fare: "1802_2004",
              tax: [
                { OT: 364 },
                { attmkp: 0 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              tf: 2520,
              psup_fare: "2156_2520",
            },
          ],
          pr: {
            bf: 2156,
            tf: 2520,
            tax: [
              { OT: 364 },
              { attmkp: 0 },
              { tamkp: 0 },
              { stamkp: 0 },
              { attdisc: 0 },
              { tadisc: 0 },
              { stadisc: 0 },
            ],
            ucur: "QAR",
            exchange_rate: 1,
            scur: "QAR",
            sup_fare: "2156_2520",
            sup_cur_fare: "",
            cabb: "",
            chekb: "",
            seats: "",
            meals: "",
            chanb: "",
            ftyp: "Regular - R",
            markupnames: [],
            ttf: 2520,
          },
          sup: "4",
          airlineList: "6E",
          servicefee: [],
        },
        {
          srcorigns: [
            {
              tid: "TCMABVHiMhD44ALJ_1",
              ref: 1,
              ofcid: "",
              sdur: "19:30",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-05-06T02:10:00",
                  ad: "2023-05-06T08:40:00",
                  rbd: "",
                  fn: "1737",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "3" },
                  rph: "",
                  jsell:
                    "6E~1737~ ~~DOH~05/06/2023 02:10~DEL~05/06/2023 08:40~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-05-06T19:30:00",
                  ad: "2023-05-06T21:40:00",
                  rbd: "",
                  fn: "2104",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "2" },
                  rph: "",
                  jsell:
                    "6E~2104~ ~~DEL~05/06/2023 19:30~HYD~05/06/2023 21:40~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~A~ ~6E~ACINT~6200~~0~65~~X^0~A~ ~6E~ACINT~6200~~0~65~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 1802,
                      fbc: "ACINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1802_2004",
                      tax: [
                        { OT: 202 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 2004,
                      psup_fare: "1802_2004",
                    },
                  ],
                  pr: {
                    bf: 1802,
                    tf: 2004,
                    tax: [
                      { OT: 202 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1802_2004",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 2004,
                  },
                  routeId: "fvxktneksp",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~A~ ~6E~ACUINT~6202~~0~5~~X^0~A~ ~6E~ACUINT~6202~~0~5~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 1833,
                      fbc: "ACUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1833_2036",
                      tax: [
                        { OT: 203 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 2036,
                      psup_fare: "1833_2036",
                    },
                  ],
                  pr: {
                    bf: 1833,
                    tf: 2036,
                    tax: [
                      { OT: 203 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1833_2036",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 2036,
                  },
                  routeId: "maw6rrjced",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "gV9VlJcF4lo=|c8vLGEjg5j7NNr8U4/n4JN2DKZGLTH99mWKIwdR5uRWciziCl+wbZTrpWnMmGN+Yy9uJ4ZUTuD0HpLhkPra8oFmlLXu64hUIzZhRraKsuKvdW8dDCeFMBF8RTGVhnAM8gqCD8NTV8Ig=",
              stop: 1,
            },
            {
              tid: "4Dd30sOqTMJIzq13_1",
              ref: 1,
              ofcid: "",
              sdur: "12:40",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-05-06T02:10:00",
                  ad: "2023-05-06T08:40:00",
                  rbd: "",
                  fn: "1737",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "3" },
                  rph: "",
                  jsell:
                    "6E~1737~ ~~DOH~05/06/2023 02:10~DEL~05/06/2023 08:40~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-05-06T12:45:00",
                  ad: "2023-05-06T14:50:00",
                  rbd: "",
                  fn: "2004",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "2" },
                  rph: "",
                  jsell:
                    "6E~2004~ ~~DEL~05/06/2023 12:45~HYD~05/06/2023 14:50~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~A~ ~6E~ACINT~6200~~0~65~~X^0~A~ ~6E~ACINT~6200~~0~65~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 1802,
                      fbc: "ACINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1802_2004",
                      tax: [
                        { OT: 202 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 2004,
                      psup_fare: "1802_2004",
                    },
                  ],
                  pr: {
                    bf: 1802,
                    tf: 2004,
                    tax: [
                      { OT: 202 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1802_2004",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 2004,
                  },
                  routeId: "4tow7l2ohj",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~A~ ~6E~ACUINT~6202~~0~5~~X^0~A~ ~6E~ACUINT~6202~~0~5~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 1833,
                      fbc: "ACUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1833_2036",
                      tax: [
                        { OT: 203 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 2036,
                      psup_fare: "1833_2036",
                    },
                  ],
                  pr: {
                    bf: 1833,
                    tf: 2036,
                    tax: [
                      { OT: 203 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1833_2036",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 2036,
                  },
                  routeId: "2b5ov8si39i",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "gV9VlJcF4lo=|c8vLGEjg5j7NNr8U4/n4JN2DKZGLTH99mWKIwdR5uRWciziCl+wbZTrpWnMmGN+Yy9uJ4ZUTuD0HpLhkPra8oFmlLXu64hUIzZhRraKsuKvdW8dDCeFMBF8RTGVhnAM8gqCD8NTV8Ig=",
              stop: 1,
            },
            {
              tid: "Rc6V0dlaftwIvwuc_1",
              ref: 1,
              ofcid: "",
              sdur: "13:20",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-05-06T02:10:00",
                  ad: "2023-05-06T08:40:00",
                  rbd: "",
                  fn: "1737",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "3" },
                  rph: "",
                  jsell:
                    "6E~1737~ ~~DOH~05/06/2023 02:10~DEL~05/06/2023 08:40~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-05-06T13:25:00",
                  ad: "2023-05-06T15:30:00",
                  rbd: "",
                  fn: "2014",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "2" },
                  rph: "",
                  jsell:
                    "6E~2014~ ~~DEL~05/06/2023 13:25~HYD~05/06/2023 15:30~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~A~ ~6E~ACINT~6200~~0~65~~X^0~A~ ~6E~ACINT~6200~~0~65~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 1802,
                      fbc: "ACINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1802_2004",
                      tax: [
                        { OT: 202 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 2004,
                      psup_fare: "1802_2004",
                    },
                  ],
                  pr: {
                    bf: 1802,
                    tf: 2004,
                    tax: [
                      { OT: 202 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1802_2004",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 2004,
                  },
                  routeId: "srugv0sqyii",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~A~ ~6E~ACUINT~6202~~0~5~~X^0~A~ ~6E~ACUINT~6202~~0~5~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 1833,
                      fbc: "ACUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1833_2036",
                      tax: [
                        { OT: 203 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 2036,
                      psup_fare: "1833_2036",
                    },
                  ],
                  pr: {
                    bf: 1833,
                    tf: 2036,
                    tax: [
                      { OT: 203 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1833_2036",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 2036,
                  },
                  routeId: "kqc5aavv7i",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "gV9VlJcF4lo=|c8vLGEjg5j7NNr8U4/n4JN2DKZGLTH99mWKIwdR5uRWciziCl+wbZTrpWnMmGN+Yy9uJ4ZUTuD0HpLhkPra8oFmlLXu64hUIzZhRraKsuKvdW8dDCeFMBF8RTGVhnAM8gqCD8NTV8Ig=",
              stop: 1,
            },
            {
              tid: "BiS84RQT4KJJsZh9_1",
              ref: 1,
              ofcid: "",
              sdur: "18:0",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-05-06T02:10:00",
                  ad: "2023-05-06T08:40:00",
                  rbd: "",
                  fn: "1737",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "3" },
                  rph: "",
                  jsell:
                    "6E~1737~ ~~DOH~05/06/2023 02:10~DEL~05/06/2023 08:40~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-05-06T18:00:00",
                  ad: "2023-05-06T20:10:00",
                  rbd: "",
                  fn: "6823",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "1" },
                  rph: "",
                  jsell:
                    "6E~6823~ ~~DEL~05/06/2023 18:00~HYD~05/06/2023 20:10~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~A~ ~6E~ACINT~6200~~0~65~~X^0~A~ ~6E~ACINT~6200~~0~65~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 1802,
                      fbc: "ACINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1802_2004",
                      tax: [
                        { OT: 202 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 2004,
                      psup_fare: "1802_2004",
                    },
                  ],
                  pr: {
                    bf: 1802,
                    tf: 2004,
                    tax: [
                      { OT: 202 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1802_2004",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 2004,
                  },
                  routeId: "eraa7jkap",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~A~ ~6E~ACUINT~6202~~0~5~~X^0~A~ ~6E~ACUINT~6202~~0~5~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 1833,
                      fbc: "ACUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1833_2036",
                      tax: [
                        { OT: 203 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 2036,
                      psup_fare: "1833_2036",
                    },
                  ],
                  pr: {
                    bf: 1833,
                    tf: 2036,
                    tax: [
                      { OT: 203 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1833_2036",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 2036,
                  },
                  routeId: "umx7fa6qje",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "gV9VlJcF4lo=|c8vLGEjg5j7NNr8U4/n4JN2DKZGLTH99mWKIwdR5uRWciziCl+wbZTrpWnMmGN+Yy9uJ4ZUTuD0HpLhkPra8oFmlLXu64hUIzZhRraKsuKvdW8dDCeFMBF8RTGVhnAM8gqCD8NTV8Ig=",
              stop: 1,
            },
            {
              tid: "hduBi6DLqKANHLTu_1",
              ref: 1,
              ofcid: "",
              sdur: "14:45",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-05-06T02:10:00",
                  ad: "2023-05-06T08:40:00",
                  rbd: "",
                  fn: "1737",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "3" },
                  rph: "",
                  jsell:
                    "6E~1737~ ~~DOH~05/06/2023 02:10~DEL~05/06/2023 08:40~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-05-06T15:00:00",
                  ad: "2023-05-06T16:55:00",
                  rbd: "",
                  fn: "2187",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "2" },
                  rph: "",
                  jsell:
                    "6E~2187~ ~~DEL~05/06/2023 15:00~HYD~05/06/2023 16:55~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~A~ ~6E~ACINT~6200~~0~65~~X^0~A~ ~6E~ACINT~6200~~0~65~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 1802,
                      fbc: "ACINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1802_2004",
                      tax: [
                        { OT: 202 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 2004,
                      psup_fare: "1802_2004",
                    },
                  ],
                  pr: {
                    bf: 1802,
                    tf: 2004,
                    tax: [
                      { OT: 202 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1802_2004",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 2004,
                  },
                  routeId: "175txao85rf",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~A~ ~6E~ACUINT~6202~~0~5~~X^0~A~ ~6E~ACUINT~6202~~0~5~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 1833,
                      fbc: "ACUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1833_2036",
                      tax: [
                        { OT: 203 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 2036,
                      psup_fare: "1833_2036",
                    },
                  ],
                  pr: {
                    bf: 1833,
                    tf: 2036,
                    tax: [
                      { OT: 203 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1833_2036",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 2036,
                  },
                  routeId: "cf8bnuuyszk",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "gV9VlJcF4lo=|c8vLGEjg5j7NNr8U4/n4JN2DKZGLTH99mWKIwdR5uRWciziCl+wbZTrpWnMmGN+Yy9uJ4ZUTuD0HpLhkPra8oFmlLXu64hUIzZhRraKsuKvdW8dDCeFMBF8RTGVhnAM8gqCD8NTV8Ig=",
              stop: 1,
            },
            {
              tid: "4g1EkJtlgxGkDETA_1",
              ref: 1,
              ofcid: "",
              sdur: "25:50",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-05-06T02:10:00",
                  ad: "2023-05-06T08:40:00",
                  rbd: "",
                  fn: "1737",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "3" },
                  rph: "",
                  jsell:
                    "6E~1737~ ~~DOH~05/06/2023 02:10~DEL~05/06/2023 08:40~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-05-07T01:50:00",
                  ad: "2023-05-07T04:00:00",
                  rbd: "",
                  fn: "2325",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "2" },
                  rph: "",
                  jsell:
                    "6E~2325~ ~~DEL~05/07/2023 01:50~HYD~05/07/2023 04:00~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~A~ ~6E~ACINT~6200~~0~65~~X^0~A~ ~6E~ACINT~6200~~0~65~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 1802,
                      fbc: "ACINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1802_2004",
                      tax: [
                        { OT: 202 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 2004,
                      psup_fare: "1802_2004",
                    },
                  ],
                  pr: {
                    bf: 1802,
                    tf: 2004,
                    tax: [
                      { OT: 202 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1802_2004",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 2004,
                  },
                  routeId: "gvjs9o5gfr",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~A~ ~6E~ACUINT~6202~~0~5~~X^0~A~ ~6E~ACUINT~6202~~0~5~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 1833,
                      fbc: "ACUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1833_2036",
                      tax: [
                        { OT: 203 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 2036,
                      psup_fare: "1833_2036",
                    },
                  ],
                  pr: {
                    bf: 1833,
                    tf: 2036,
                    tax: [
                      { OT: 203 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1833_2036",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 2036,
                  },
                  routeId: "a7uvvezvfw",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "gV9VlJcF4lo=|c8vLGEjg5j7NNr8U4/n4JN2DKZGLTH99mWKIwdR5uRWciziCl+wbZTrpWnMmGN+Yy9uJ4ZUTuD0HpLhkPra8oFmlLXu64hUIzZhRraKsuKvdW8dDCeFMBF8RTGVhnAM8gqCD8NTV8Ig=",
              stop: 1,
            },
            {
              tid: "AQMhCq6He87ke1cH_1",
              ref: 1,
              ofcid: "",
              sdur: "13:45",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-05-06T20:30:00",
                  ad: "2023-05-07T03:15:00",
                  rbd: "",
                  fn: "1774",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "3" },
                  rph: "",
                  jsell:
                    "6E~1774~ ~~DOH~05/06/2023 20:30~DEL~05/07/2023 03:15~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-05-07T07:55:00",
                  ad: "2023-05-07T10:15:00",
                  rbd: "",
                  fn: "2774",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "2" },
                  rph: "",
                  jsell:
                    "6E~2774~ ~~DEL~05/07/2023 07:55~HYD~05/07/2023 10:15~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~A~ ~6E~ACINT~6200~~0~65~~X^0~A~ ~6E~ACINT~6200~~0~65~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 1802,
                      fbc: "ACINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1802_2004",
                      tax: [
                        { OT: 202 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 2004,
                      psup_fare: "1802_2004",
                    },
                  ],
                  pr: {
                    bf: 1802,
                    tf: 2004,
                    tax: [
                      { OT: 202 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1802_2004",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 2004,
                  },
                  routeId: "kn44afx98a",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~A~ ~6E~ACUINT~6202~~0~5~~X^0~A~ ~6E~ACUINT~6202~~0~5~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 1833,
                      fbc: "ACUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1833_2036",
                      tax: [
                        { OT: 203 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 2036,
                      psup_fare: "1833_2036",
                    },
                  ],
                  pr: {
                    bf: 1833,
                    tf: 2036,
                    tax: [
                      { OT: 203 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1833_2036",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 2036,
                  },
                  routeId: "nrxikj38vm",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "gV9VlJcF4lo=|c8vLGEjg5j7NNr8U4/n4JN2DKZGLTH99mWKIwdR5uRWciziCl+wbZTrpWnMmGN+Yy9uJ4ZUTuD0HpLhkPra8oFmlLXu64hUIzZhRraKsuKvdW8dDCeFMBF8RTGVhnAM8gqCD8NTV8Ig=",
              stop: 1,
            },
            {
              tid: "zVUWrkdlJDigdmq4_1",
              ref: 1,
              ofcid: "",
              sdur: "18:20",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-05-06T20:30:00",
                  ad: "2023-05-07T03:15:00",
                  rbd: "",
                  fn: "1774",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "3" },
                  rph: "",
                  jsell:
                    "6E~1774~ ~~DOH~05/06/2023 20:30~DEL~05/07/2023 03:15~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-05-07T12:45:00",
                  ad: "2023-05-07T14:50:00",
                  rbd: "",
                  fn: "2004",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "2" },
                  rph: "",
                  jsell:
                    "6E~2004~ ~~DEL~05/07/2023 12:45~HYD~05/07/2023 14:50~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~A~ ~6E~ACINT~6200~~0~65~~X^0~A~ ~6E~ACINT~6200~~0~65~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 1802,
                      fbc: "ACINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1802_2004",
                      tax: [
                        { OT: 202 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 2004,
                      psup_fare: "1802_2004",
                    },
                  ],
                  pr: {
                    bf: 1802,
                    tf: 2004,
                    tax: [
                      { OT: 202 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1802_2004",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 2004,
                  },
                  routeId: "5m79dpcadu",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~A~ ~6E~ACUINT~6202~~0~5~~X^0~A~ ~6E~ACUINT~6202~~0~5~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 1833,
                      fbc: "ACUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1833_2036",
                      tax: [
                        { OT: 203 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 2036,
                      psup_fare: "1833_2036",
                    },
                  ],
                  pr: {
                    bf: 1833,
                    tf: 2036,
                    tax: [
                      { OT: 203 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1833_2036",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 2036,
                  },
                  routeId: "1gmm6scn7f",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "gV9VlJcF4lo=|c8vLGEjg5j7NNr8U4/n4JN2DKZGLTH99mWKIwdR5uRWciziCl+wbZTrpWnMmGN+Yy9uJ4ZUTuD0HpLhkPra8oFmlLXu64hUIzZhRraKsuKvdW8dDCeFMBF8RTGVhnAM8gqCD8NTV8Ig=",
              stop: 1,
            },
            {
              tid: "wAnhHE6v50aPUsFX_1",
              ref: 1,
              ofcid: "",
              sdur: "19:0",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-05-06T20:30:00",
                  ad: "2023-05-07T03:15:00",
                  rbd: "",
                  fn: "1774",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "3" },
                  rph: "",
                  jsell:
                    "6E~1774~ ~~DOH~05/06/2023 20:30~DEL~05/07/2023 03:15~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-05-07T13:25:00",
                  ad: "2023-05-07T15:30:00",
                  rbd: "",
                  fn: "2014",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "2" },
                  rph: "",
                  jsell:
                    "6E~2014~ ~~DEL~05/07/2023 13:25~HYD~05/07/2023 15:30~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~A~ ~6E~ACINT~6200~~0~65~~X^0~A~ ~6E~ACINT~6200~~0~65~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 1802,
                      fbc: "ACINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1802_2004",
                      tax: [
                        { OT: 202 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 2004,
                      psup_fare: "1802_2004",
                    },
                  ],
                  pr: {
                    bf: 1802,
                    tf: 2004,
                    tax: [
                      { OT: 202 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1802_2004",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 2004,
                  },
                  routeId: "aepk857s4i",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~A~ ~6E~ACUINT~6202~~0~5~~X^0~A~ ~6E~ACUINT~6202~~0~5~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 1833,
                      fbc: "ACUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1833_2036",
                      tax: [
                        { OT: 203 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 2036,
                      psup_fare: "1833_2036",
                    },
                  ],
                  pr: {
                    bf: 1833,
                    tf: 2036,
                    tax: [
                      { OT: 203 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1833_2036",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 2036,
                  },
                  routeId: "dwaxlpyk7u",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "gV9VlJcF4lo=|c8vLGEjg5j7NNr8U4/n4JN2DKZGLTH99mWKIwdR5uRWciziCl+wbZTrpWnMmGN+Yy9uJ4ZUTuD0HpLhkPra8oFmlLXu64hUIzZhRraKsuKvdW8dDCeFMBF8RTGVhnAM8gqCD8NTV8Ig=",
              stop: 1,
            },
            {
              tid: "EOpQKCq8W5i4vTrv_1",
              ref: 1,
              ofcid: "",
              sdur: "12:30",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-05-06T20:30:00",
                  ad: "2023-05-07T03:15:00",
                  rbd: "",
                  fn: "1774",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "3" },
                  rph: "",
                  jsell:
                    "6E~1774~ ~~DOH~05/06/2023 20:30~DEL~05/07/2023 03:15~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-05-07T06:40:00",
                  ad: "2023-05-07T09:00:00",
                  rbd: "",
                  fn: "2331",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "2" },
                  rph: "",
                  jsell:
                    "6E~2331~ ~~DEL~05/07/2023 06:40~HYD~05/07/2023 09:00~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~A~ ~6E~ACINT~6200~~0~65~~X^0~A~ ~6E~ACINT~6200~~0~65~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 1802,
                      fbc: "ACINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1802_2004",
                      tax: [
                        { OT: 202 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 2004,
                      psup_fare: "1802_2004",
                    },
                  ],
                  pr: {
                    bf: 1802,
                    tf: 2004,
                    tax: [
                      { OT: 202 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1802_2004",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 2004,
                  },
                  routeId: "ezj4xudid7",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~A~ ~6E~ACUINT~6202~~0~5~~X^0~A~ ~6E~ACUINT~6202~~0~5~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 1833,
                      fbc: "ACUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1833_2036",
                      tax: [
                        { OT: 203 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 2036,
                      psup_fare: "1833_2036",
                    },
                  ],
                  pr: {
                    bf: 1833,
                    tf: 2036,
                    tax: [
                      { OT: 203 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1833_2036",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 2036,
                  },
                  routeId: "8bu08ruoeu",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "gV9VlJcF4lo=|c8vLGEjg5j7NNr8U4/n4JN2DKZGLTH99mWKIwdR5uRWciziCl+wbZTrpWnMmGN+Yy9uJ4ZUTuD0HpLhkPra8oFmlLXu64hUIzZhRraKsuKvdW8dDCeFMBF8RTGVhnAM8gqCD8NTV8Ig=",
              stop: 1,
            },
            {
              tid: "3TcHDKEbrXLVoa4I_1",
              ref: 1,
              ofcid: "",
              sdur: "20:25",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-05-06T20:30:00",
                  ad: "2023-05-07T03:15:00",
                  rbd: "",
                  fn: "1774",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "3" },
                  rph: "",
                  jsell:
                    "6E~1774~ ~~DOH~05/06/2023 20:30~DEL~05/07/2023 03:15~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-05-07T15:00:00",
                  ad: "2023-05-07T16:55:00",
                  rbd: "",
                  fn: "2187",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "2" },
                  rph: "",
                  jsell:
                    "6E~2187~ ~~DEL~05/07/2023 15:00~HYD~05/07/2023 16:55~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~A~ ~6E~ACINT~6200~~0~65~~X^0~A~ ~6E~ACINT~6200~~0~65~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 1802,
                      fbc: "ACINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1802_2004",
                      tax: [
                        { OT: 202 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 2004,
                      psup_fare: "1802_2004",
                    },
                  ],
                  pr: {
                    bf: 1802,
                    tf: 2004,
                    tax: [
                      { OT: 202 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1802_2004",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 2004,
                  },
                  routeId: "kemzj23hrn",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~A~ ~6E~ACUINT~6202~~0~5~~X^0~A~ ~6E~ACUINT~6202~~0~5~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 1833,
                      fbc: "ACUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1833_2036",
                      tax: [
                        { OT: 203 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 2036,
                      psup_fare: "1833_2036",
                    },
                  ],
                  pr: {
                    bf: 1833,
                    tf: 2036,
                    tax: [
                      { OT: 203 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1833_2036",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 2036,
                  },
                  routeId: "21xutw51bhi",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "gV9VlJcF4lo=|c8vLGEjg5j7NNr8U4/n4JN2DKZGLTH99mWKIwdR5uRWciziCl+wbZTrpWnMmGN+Yy9uJ4ZUTuD0HpLhkPra8oFmlLXu64hUIzZhRraKsuKvdW8dDCeFMBF8RTGVhnAM8gqCD8NTV8Ig=",
              stop: 1,
            },
            {
              tid: "3NFOBeBXKTHr45kl_1",
              ref: 1,
              ofcid: "",
              sdur: "23:40",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-05-06T20:30:00",
                  ad: "2023-05-07T03:15:00",
                  rbd: "",
                  fn: "1774",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "3" },
                  rph: "",
                  jsell:
                    "6E~1774~ ~~DOH~05/06/2023 20:30~DEL~05/07/2023 03:15~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-05-07T18:00:00",
                  ad: "2023-05-07T20:10:00",
                  rbd: "",
                  fn: "6823",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "1" },
                  rph: "",
                  jsell:
                    "6E~6823~ ~~DEL~05/07/2023 18:00~HYD~05/07/2023 20:10~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~A~ ~6E~ACINT~6200~~0~65~~X^0~A~ ~6E~ACINT~6200~~0~65~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 1802,
                      fbc: "ACINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1802_2004",
                      tax: [
                        { OT: 202 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 2004,
                      psup_fare: "1802_2004",
                    },
                  ],
                  pr: {
                    bf: 1802,
                    tf: 2004,
                    tax: [
                      { OT: 202 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1802_2004",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 2004,
                  },
                  routeId: "riajf49wjqk",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~A~ ~6E~ACUINT~6202~~0~5~~X^0~A~ ~6E~ACUINT~6202~~0~5~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 1833,
                      fbc: "ACUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1833_2036",
                      tax: [
                        { OT: 203 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 2036,
                      psup_fare: "1833_2036",
                    },
                  ],
                  pr: {
                    bf: 1833,
                    tf: 2036,
                    tax: [
                      { OT: 203 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1833_2036",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 2036,
                  },
                  routeId: "b4g8fgyf4t",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "gV9VlJcF4lo=|c8vLGEjg5j7NNr8U4/n4JN2DKZGLTH99mWKIwdR5uRWciziCl+wbZTrpWnMmGN+Yy9uJ4ZUTuD0HpLhkPra8oFmlLXu64hUIzZhRraKsuKvdW8dDCeFMBF8RTGVhnAM8gqCD8NTV8Ig=",
              stop: 1,
            },
            {
              tid: "2KIarB2e9FWZtxkX_1",
              ref: 1,
              ofcid: "",
              sdur: "25:10",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-05-06T20:30:00",
                  ad: "2023-05-07T03:15:00",
                  rbd: "",
                  fn: "1774",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "3" },
                  rph: "",
                  jsell:
                    "6E~1774~ ~~DOH~05/06/2023 20:30~DEL~05/07/2023 03:15~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-05-07T19:30:00",
                  ad: "2023-05-07T21:40:00",
                  rbd: "",
                  fn: "2104",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "2" },
                  rph: "",
                  jsell:
                    "6E~2104~ ~~DEL~05/07/2023 19:30~HYD~05/07/2023 21:40~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~A~ ~6E~ACINT~6200~~0~65~~X^0~A~ ~6E~ACINT~6200~~0~65~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 1802,
                      fbc: "ACINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1802_2004",
                      tax: [
                        { OT: 202 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 2004,
                      psup_fare: "1802_2004",
                    },
                  ],
                  pr: {
                    bf: 1802,
                    tf: 2004,
                    tax: [
                      { OT: 202 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1802_2004",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 2004,
                  },
                  routeId: "z48rbyu7zt",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~A~ ~6E~ACUINT~6202~~0~5~~X^0~A~ ~6E~ACUINT~6202~~0~5~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 1833,
                      fbc: "ACUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1833_2036",
                      tax: [
                        { OT: 203 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 2036,
                      psup_fare: "1833_2036",
                    },
                  ],
                  pr: {
                    bf: 1833,
                    tf: 2036,
                    tax: [
                      { OT: 203 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1833_2036",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 2036,
                  },
                  routeId: "67q9qh6v5r",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "gV9VlJcF4lo=|c8vLGEjg5j7NNr8U4/n4JN2DKZGLTH99mWKIwdR5uRWciziCl+wbZTrpWnMmGN+Yy9uJ4ZUTuD0HpLhkPra8oFmlLXu64hUIzZhRraKsuKvdW8dDCeFMBF8RTGVhnAM8gqCD8NTV8Ig=",
              stop: 1,
            },
            {
              tid: "yu7KhJ4xtMZV9WLZ_1",
              ref: 1,
              ofcid: "",
              sdur: "15:35",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-05-06T20:30:00",
                  ad: "2023-05-07T03:15:00",
                  rbd: "",
                  fn: "1774",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "3" },
                  rph: "",
                  jsell:
                    "6E~1774~ ~~DOH~05/06/2023 20:30~DEL~05/07/2023 03:15~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-05-07T09:55:00",
                  ad: "2023-05-07T12:05:00",
                  rbd: "",
                  fn: "2005",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "2" },
                  rph: "",
                  jsell:
                    "6E~2005~ ~~DEL~05/07/2023 09:55~HYD~05/07/2023 12:05~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~A~ ~6E~ACINT~6200~~0~65~~X^0~A~ ~6E~ACINT~6200~~0~65~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 1802,
                      fbc: "ACINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1802_2004",
                      tax: [
                        { OT: 202 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 2004,
                      psup_fare: "1802_2004",
                    },
                  ],
                  pr: {
                    bf: 1802,
                    tf: 2004,
                    tax: [
                      { OT: 202 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1802_2004",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 2004,
                  },
                  routeId: "lm2204ycgx",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~A~ ~6E~ACUINT~6202~~0~5~~X^0~A~ ~6E~ACUINT~6202~~0~5~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 1833,
                      fbc: "ACUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1833_2036",
                      tax: [
                        { OT: 203 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 2036,
                      psup_fare: "1833_2036",
                    },
                  ],
                  pr: {
                    bf: 1833,
                    tf: 2036,
                    tax: [
                      { OT: 203 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1833_2036",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 2036,
                  },
                  routeId: "ngmc2gp7yz",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "gV9VlJcF4lo=|c8vLGEjg5j7NNr8U4/n4JN2DKZGLTH99mWKIwdR5uRWciziCl+wbZTrpWnMmGN+Yy9uJ4ZUTuD0HpLhkPra8oFmlLXu64hUIzZhRraKsuKvdW8dDCeFMBF8RTGVhnAM8gqCD8NTV8Ig=",
              stop: 1,
            },
            {
              tid: "kC72u0Nap4bTqoQQ_1",
              ref: 1,
              ofcid: "",
              sdur: "26:55",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-05-06T20:30:00",
                  ad: "2023-05-07T03:15:00",
                  rbd: "",
                  fn: "1774",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "3" },
                  rph: "",
                  jsell:
                    "6E~1774~ ~~DOH~05/06/2023 20:30~DEL~05/07/2023 03:15~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-05-07T21:15:00",
                  ad: "2023-05-07T23:25:00",
                  rbd: "",
                  fn: "2136",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "2" },
                  rph: "",
                  jsell:
                    "6E~2136~ ~~DEL~05/07/2023 21:15~HYD~05/07/2023 23:25~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~A~ ~6E~ACINT~6200~~0~65~~X^0~A~ ~6E~ACINT~6200~~0~65~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 1802,
                      fbc: "ACINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1802_2004",
                      tax: [
                        { OT: 202 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 2004,
                      psup_fare: "1802_2004",
                    },
                  ],
                  pr: {
                    bf: 1802,
                    tf: 2004,
                    tax: [
                      { OT: 202 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1802_2004",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 2004,
                  },
                  routeId: "7eeag6wke3",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~A~ ~6E~ACUINT~6202~~0~5~~X^0~A~ ~6E~ACUINT~6202~~0~5~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 1833,
                      fbc: "ACUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1833_2036",
                      tax: [
                        { OT: 203 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 2036,
                      psup_fare: "1833_2036",
                    },
                  ],
                  pr: {
                    bf: 1833,
                    tf: 2036,
                    tax: [
                      { OT: 203 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1833_2036",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 2036,
                  },
                  routeId: "x3unv1m6ua",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "gV9VlJcF4lo=|c8vLGEjg5j7NNr8U4/n4JN2DKZGLTH99mWKIwdR5uRWciziCl+wbZTrpWnMmGN+Yy9uJ4ZUTuD0HpLhkPra8oFmlLXu64hUIzZhRraKsuKvdW8dDCeFMBF8RTGVhnAM8gqCD8NTV8Ig=",
              stop: 1,
            },
          ],
          destorigins: [
            {
              tid: "3kCRNzC8KpVd7iBv_2",
              ref: 2,
              ofcid: "",
              sdur: "13:40",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DEL",
                  dd: "2023-05-08T09:55:00",
                  ad: "2023-05-08T12:10:00",
                  rbd: "",
                  fn: "2511",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "2" },
                  rph: "",
                  jsell:
                    "6E~2511~ ~~HYD~05/08/2023 09:55~DEL~05/08/2023 12:10~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-05-08T22:00:00",
                  ad: "2023-05-08T23:35:00",
                  rbd: "",
                  fn: "1736",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "3" },
                  rph: "",
                  jsell:
                    "6E~1736~ ~~DEL~05/08/2023 22:00~DOH~05/08/2023 23:35~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~A~ ~6E~ACINT~6200~~0~66~~X^0~A~ ~6E~ACINT~6200~~0~66~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 1180,
                      fbc: "ACINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1180_1413",
                      tax: [
                        { OT: 233 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 1413,
                      psup_fare: "1180_1413",
                    },
                  ],
                  pr: {
                    bf: 1180,
                    tf: 1413,
                    tax: [
                      { OT: 233 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1180_1413",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 1413,
                  },
                  routeId: "t8dp771r65",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~A~ ~6E~ACUINT~6202~~0~4~~X^0~A~ ~6E~ACUINT~6202~~0~4~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 1210,
                      fbc: "ACUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1210_1446",
                      tax: [
                        { OT: 236 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 1446,
                      psup_fare: "1210_1446",
                    },
                  ],
                  pr: {
                    bf: 1210,
                    tf: 1446,
                    tax: [
                      { OT: 236 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1210_1446",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 1446,
                  },
                  routeId: "6svjryqai6l",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "gV9VlJcF4lo=|c8vLGEjg5j7NNr8U4/n4JN2DKZGLTH99mWKIwdR5uRWciziCl+wbZTrpWnMmGN+Yy9uJ4ZUTuD0HpLhkPra8oFmlLXu64hUIzZhRraKsuKvdW8dDCeFMBF8RTGVhnAM8gqCD8NTV8Ig=",
              stop: 1,
            },
            {
              tid: "DupVb8tL3TiO5IX1_2",
              ref: 2,
              ofcid: "",
              sdur: "11:55",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DEL",
                  dd: "2023-05-08T11:40:00",
                  ad: "2023-05-08T13:55:00",
                  rbd: "",
                  fn: "2171",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "2" },
                  rph: "",
                  jsell:
                    "6E~2171~ ~~HYD~05/08/2023 11:40~DEL~05/08/2023 13:55~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-05-08T22:00:00",
                  ad: "2023-05-08T23:35:00",
                  rbd: "",
                  fn: "1736",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "3" },
                  rph: "",
                  jsell:
                    "6E~1736~ ~~DEL~05/08/2023 22:00~DOH~05/08/2023 23:35~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~A~ ~6E~ACINT~6200~~0~66~~X^0~A~ ~6E~ACINT~6200~~0~66~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 1180,
                      fbc: "ACINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1180_1413",
                      tax: [
                        { OT: 233 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 1413,
                      psup_fare: "1180_1413",
                    },
                  ],
                  pr: {
                    bf: 1180,
                    tf: 1413,
                    tax: [
                      { OT: 233 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1180_1413",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 1413,
                  },
                  routeId: "yr2ppcd4r5i",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~A~ ~6E~ACUINT~6202~~0~4~~X^0~A~ ~6E~ACUINT~6202~~0~4~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 1210,
                      fbc: "ACUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1210_1446",
                      tax: [
                        { OT: 236 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 1446,
                      psup_fare: "1210_1446",
                    },
                  ],
                  pr: {
                    bf: 1210,
                    tf: 1446,
                    tax: [
                      { OT: 236 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1210_1446",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 1446,
                  },
                  routeId: "uf0bdgyn3k",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "gV9VlJcF4lo=|c8vLGEjg5j7NNr8U4/n4JN2DKZGLTH99mWKIwdR5uRWciziCl+wbZTrpWnMmGN+Yy9uJ4ZUTuD0HpLhkPra8oFmlLXu64hUIzZhRraKsuKvdW8dDCeFMBF8RTGVhnAM8gqCD8NTV8Ig=",
              stop: 1,
            },
            {
              tid: "7g0xZWwfaDW157sQ_2",
              ref: 2,
              ofcid: "",
              sdur: "9:35",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DEL",
                  dd: "2023-05-08T14:00:00",
                  ad: "2023-05-08T16:15:00",
                  rbd: "",
                  fn: "2063",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "2" },
                  rph: "",
                  jsell:
                    "6E~2063~ ~~HYD~05/08/2023 14:00~DEL~05/08/2023 16:15~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-05-08T22:00:00",
                  ad: "2023-05-08T23:35:00",
                  rbd: "",
                  fn: "1736",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "3" },
                  rph: "",
                  jsell:
                    "6E~1736~ ~~DEL~05/08/2023 22:00~DOH~05/08/2023 23:35~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~A~ ~6E~ACINT~6200~~0~66~~X^0~A~ ~6E~ACINT~6200~~0~66~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 1180,
                      fbc: "ACINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1180_1413",
                      tax: [
                        { OT: 233 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 1413,
                      psup_fare: "1180_1413",
                    },
                  ],
                  pr: {
                    bf: 1180,
                    tf: 1413,
                    tax: [
                      { OT: 233 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1180_1413",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 1413,
                  },
                  routeId: "ljnwksw1sl",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~A~ ~6E~ACUINT~6202~~0~4~~X^0~A~ ~6E~ACUINT~6202~~0~4~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 1210,
                      fbc: "ACUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1210_1446",
                      tax: [
                        { OT: 236 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 1446,
                      psup_fare: "1210_1446",
                    },
                  ],
                  pr: {
                    bf: 1210,
                    tf: 1446,
                    tax: [
                      { OT: 236 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1210_1446",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 1446,
                  },
                  routeId: "94uh9ht7swj",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "gV9VlJcF4lo=|c8vLGEjg5j7NNr8U4/n4JN2DKZGLTH99mWKIwdR5uRWciziCl+wbZTrpWnMmGN+Yy9uJ4ZUTuD0HpLhkPra8oFmlLXu64hUIzZhRraKsuKvdW8dDCeFMBF8RTGVhnAM8gqCD8NTV8Ig=",
              stop: 1,
            },
            {
              tid: "kafXizeSzLM0T1WD_2",
              ref: 2,
              ofcid: "",
              sdur: "13:25",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DEL",
                  dd: "2023-05-08T16:20:00",
                  ad: "2023-05-08T18:35:00",
                  rbd: "",
                  fn: "2069",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "2" },
                  rph: "",
                  jsell:
                    "6E~2069~ ~~HYD~05/08/2023 16:20~DEL~05/08/2023 18:35~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-05-09T04:10:00",
                  ad: "2023-05-09T05:45:00",
                  rbd: "",
                  fn: "1701",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "3" },
                  rph: "",
                  jsell:
                    "6E~1701~ ~~DEL~05/09/2023 04:10~DOH~05/09/2023 05:45~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~A~ ~6E~ACINT~6200~~0~66~~X^0~A~ ~6E~ACINT~6200~~0~66~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 1180,
                      fbc: "ACINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1180_1413",
                      tax: [
                        { OT: 233 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 1413,
                      psup_fare: "1180_1413",
                    },
                  ],
                  pr: {
                    bf: 1180,
                    tf: 1413,
                    tax: [
                      { OT: 233 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1180_1413",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 1413,
                  },
                  routeId: "zlwwfgtapp",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~A~ ~6E~ACUINT~6202~~0~4~~X^0~A~ ~6E~ACUINT~6202~~0~4~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 1210,
                      fbc: "ACUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1210_1446",
                      tax: [
                        { OT: 236 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 1446,
                      psup_fare: "1210_1446",
                    },
                  ],
                  pr: {
                    bf: 1210,
                    tf: 1446,
                    tax: [
                      { OT: 236 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1210_1446",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 1446,
                  },
                  routeId: "d99xy45x3s",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "gV9VlJcF4lo=|c8vLGEjg5j7NNr8U4/n4JN2DKZGLTH99mWKIwdR5uRWciziCl+wbZTrpWnMmGN+Yy9uJ4ZUTuD0HpLhkPra8oFmlLXu64hUIzZhRraKsuKvdW8dDCeFMBF8RTGVhnAM8gqCD8NTV8Ig=",
              stop: 1,
            },
            {
              tid: "5NJ5TqQqgW5tbRli_2",
              ref: 2,
              ofcid: "",
              sdur: "7:15",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DEL",
                  dd: "2023-05-08T16:20:00",
                  ad: "2023-05-08T18:35:00",
                  rbd: "",
                  fn: "2069",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "2" },
                  rph: "",
                  jsell:
                    "6E~2069~ ~~HYD~05/08/2023 16:20~DEL~05/08/2023 18:35~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-05-08T22:00:00",
                  ad: "2023-05-08T23:35:00",
                  rbd: "",
                  fn: "1736",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "3" },
                  rph: "",
                  jsell:
                    "6E~1736~ ~~DEL~05/08/2023 22:00~DOH~05/08/2023 23:35~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~A~ ~6E~ACINT~6200~~0~66~~X^0~A~ ~6E~ACINT~6200~~0~66~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 1180,
                      fbc: "ACINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1180_1413",
                      tax: [
                        { OT: 233 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 1413,
                      psup_fare: "1180_1413",
                    },
                  ],
                  pr: {
                    bf: 1180,
                    tf: 1413,
                    tax: [
                      { OT: 233 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1180_1413",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 1413,
                  },
                  routeId: "m8w0c0rn4n",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~A~ ~6E~ACUINT~6202~~0~4~~X^0~A~ ~6E~ACUINT~6202~~0~4~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 1210,
                      fbc: "ACUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1210_1446",
                      tax: [
                        { OT: 236 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 1446,
                      psup_fare: "1210_1446",
                    },
                  ],
                  pr: {
                    bf: 1210,
                    tf: 1446,
                    tax: [
                      { OT: 236 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1210_1446",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 1446,
                  },
                  routeId: "ayvp83y5si",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "gV9VlJcF4lo=|c8vLGEjg5j7NNr8U4/n4JN2DKZGLTH99mWKIwdR5uRWciziCl+wbZTrpWnMmGN+Yy9uJ4ZUTuD0HpLhkPra8oFmlLXu64hUIzZhRraKsuKvdW8dDCeFMBF8RTGVhnAM8gqCD8NTV8Ig=",
              stop: 1,
            },
            {
              tid: "IArU4iumFJnaSi7f_2",
              ref: 2,
              ofcid: "",
              sdur: "7:5",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DEL",
                  dd: "2023-05-08T16:30:00",
                  ad: "2023-05-08T18:55:00",
                  rbd: "",
                  fn: "6606",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: {},
                  rph: "",
                  jsell:
                    "6E~6606~ ~~HYD~05/08/2023 16:30~DEL~05/08/2023 18:55~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-05-08T22:00:00",
                  ad: "2023-05-08T23:35:00",
                  rbd: "",
                  fn: "1736",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "3" },
                  rph: "",
                  jsell:
                    "6E~1736~ ~~DEL~05/08/2023 22:00~DOH~05/08/2023 23:35~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~A~ ~6E~ACINT~6200~~0~66~~X^0~A~ ~6E~ACINT~6200~~0~66~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 1180,
                      fbc: "ACINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1180_1413",
                      tax: [
                        { OT: 233 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 1413,
                      psup_fare: "1180_1413",
                    },
                  ],
                  pr: {
                    bf: 1180,
                    tf: 1413,
                    tax: [
                      { OT: 233 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1180_1413",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 1413,
                  },
                  routeId: "ihgtbma3vr",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~A~ ~6E~ACUINT~6202~~0~4~~X^0~A~ ~6E~ACUINT~6202~~0~4~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 1210,
                      fbc: "ACUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1210_1446",
                      tax: [
                        { OT: 236 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 1446,
                      psup_fare: "1210_1446",
                    },
                  ],
                  pr: {
                    bf: 1210,
                    tf: 1446,
                    tax: [
                      { OT: 236 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1210_1446",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 1446,
                  },
                  routeId: "rz4m4bz411",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "gV9VlJcF4lo=|c8vLGEjg5j7NNr8U4/n4JN2DKZGLTH99mWKIwdR5uRWciziCl+wbZTrpWnMmGN+Yy9uJ4ZUTuD0HpLhkPra8oFmlLXu64hUIzZhRraKsuKvdW8dDCeFMBF8RTGVhnAM8gqCD8NTV8Ig=",
              stop: 1,
            },
            {
              tid: "rI8MfyJ8gc1aMANv_2",
              ref: 2,
              ofcid: "",
              sdur: "13:15",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DEL",
                  dd: "2023-05-08T16:30:00",
                  ad: "2023-05-08T18:55:00",
                  rbd: "",
                  fn: "6606",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: {},
                  rph: "",
                  jsell:
                    "6E~6606~ ~~HYD~05/08/2023 16:30~DEL~05/08/2023 18:55~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-05-09T04:10:00",
                  ad: "2023-05-09T05:45:00",
                  rbd: "",
                  fn: "1701",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "3" },
                  rph: "",
                  jsell:
                    "6E~1701~ ~~DEL~05/09/2023 04:10~DOH~05/09/2023 05:45~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~A~ ~6E~ACINT~6200~~0~66~~X^0~A~ ~6E~ACINT~6200~~0~66~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 1180,
                      fbc: "ACINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1180_1413",
                      tax: [
                        { OT: 233 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 1413,
                      psup_fare: "1180_1413",
                    },
                  ],
                  pr: {
                    bf: 1180,
                    tf: 1413,
                    tax: [
                      { OT: 233 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1180_1413",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 1413,
                  },
                  routeId: "xj5u0j4zxsg",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~A~ ~6E~ACUINT~6202~~0~4~~X^0~A~ ~6E~ACUINT~6202~~0~4~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 1210,
                      fbc: "ACUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1210_1446",
                      tax: [
                        { OT: 236 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 1446,
                      psup_fare: "1210_1446",
                    },
                  ],
                  pr: {
                    bf: 1210,
                    tf: 1446,
                    tax: [
                      { OT: 236 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1210_1446",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 1446,
                  },
                  routeId: "j4y0n6vaou",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "gV9VlJcF4lo=|c8vLGEjg5j7NNr8U4/n4JN2DKZGLTH99mWKIwdR5uRWciziCl+wbZTrpWnMmGN+Yy9uJ4ZUTuD0HpLhkPra8oFmlLXu64hUIzZhRraKsuKvdW8dDCeFMBF8RTGVhnAM8gqCD8NTV8Ig=",
              stop: 1,
            },
            {
              tid: "UIR6fOsNLtxpVV4C_2",
              ref: 2,
              ofcid: "",
              sdur: "12:0",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DEL",
                  dd: "2023-05-08T17:45:00",
                  ad: "2023-05-08T20:00:00",
                  rbd: "",
                  fn: "2135",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "2" },
                  rph: "",
                  jsell:
                    "6E~2135~ ~~HYD~05/08/2023 17:45~DEL~05/08/2023 20:00~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-05-09T04:10:00",
                  ad: "2023-05-09T05:45:00",
                  rbd: "",
                  fn: "1701",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "3" },
                  rph: "",
                  jsell:
                    "6E~1701~ ~~DEL~05/09/2023 04:10~DOH~05/09/2023 05:45~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~A~ ~6E~ACINT~6200~~0~66~~X^0~A~ ~6E~ACINT~6200~~0~66~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 1180,
                      fbc: "ACINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1180_1413",
                      tax: [
                        { OT: 233 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 1413,
                      psup_fare: "1180_1413",
                    },
                  ],
                  pr: {
                    bf: 1180,
                    tf: 1413,
                    tax: [
                      { OT: 233 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1180_1413",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 1413,
                  },
                  routeId: "k7msjcil5zh",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~A~ ~6E~ACUINT~6202~~0~4~~X^0~A~ ~6E~ACUINT~6202~~0~4~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 1210,
                      fbc: "ACUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1210_1446",
                      tax: [
                        { OT: 236 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 1446,
                      psup_fare: "1210_1446",
                    },
                  ],
                  pr: {
                    bf: 1210,
                    tf: 1446,
                    tax: [
                      { OT: 236 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1210_1446",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 1446,
                  },
                  routeId: "6gp2kofaet",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "gV9VlJcF4lo=|c8vLGEjg5j7NNr8U4/n4JN2DKZGLTH99mWKIwdR5uRWciziCl+wbZTrpWnMmGN+Yy9uJ4ZUTuD0HpLhkPra8oFmlLXu64hUIzZhRraKsuKvdW8dDCeFMBF8RTGVhnAM8gqCD8NTV8Ig=",
              stop: 1,
            },
            {
              tid: "Is9qMdgI8Fat4ZZN_2",
              ref: 2,
              ofcid: "",
              sdur: "5:50",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DEL",
                  dd: "2023-05-08T17:45:00",
                  ad: "2023-05-08T20:00:00",
                  rbd: "",
                  fn: "2135",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "2" },
                  rph: "",
                  jsell:
                    "6E~2135~ ~~HYD~05/08/2023 17:45~DEL~05/08/2023 20:00~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-05-08T22:00:00",
                  ad: "2023-05-08T23:35:00",
                  rbd: "",
                  fn: "1736",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "3" },
                  rph: "",
                  jsell:
                    "6E~1736~ ~~DEL~05/08/2023 22:00~DOH~05/08/2023 23:35~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~A~ ~6E~ACINT~6200~~0~66~~X^0~A~ ~6E~ACINT~6200~~0~66~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 1180,
                      fbc: "ACINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1180_1413",
                      tax: [
                        { OT: 233 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 1413,
                      psup_fare: "1180_1413",
                    },
                  ],
                  pr: {
                    bf: 1180,
                    tf: 1413,
                    tax: [
                      { OT: 233 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1180_1413",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 1413,
                  },
                  routeId: "wn3vxlvbc4",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~A~ ~6E~ACUINT~6202~~0~4~~X^0~A~ ~6E~ACUINT~6202~~0~4~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 1210,
                      fbc: "ACUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1210_1446",
                      tax: [
                        { OT: 236 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 1446,
                      psup_fare: "1210_1446",
                    },
                  ],
                  pr: {
                    bf: 1210,
                    tf: 1446,
                    tax: [
                      { OT: 236 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1210_1446",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 1446,
                  },
                  routeId: "obkyrbsc3j",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "gV9VlJcF4lo=|c8vLGEjg5j7NNr8U4/n4JN2DKZGLTH99mWKIwdR5uRWciziCl+wbZTrpWnMmGN+Yy9uJ4ZUTuD0HpLhkPra8oFmlLXu64hUIzZhRraKsuKvdW8dDCeFMBF8RTGVhnAM8gqCD8NTV8Ig=",
              stop: 1,
            },
            {
              tid: "ap7yDk05mrFGv3oF_2",
              ref: 2,
              ofcid: "",
              sdur: "9:40",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DEL",
                  dd: "2023-05-08T20:05:00",
                  ad: "2023-05-08T22:20:00",
                  rbd: "",
                  fn: "2106",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "2" },
                  rph: "",
                  jsell:
                    "6E~2106~ ~~HYD~05/08/2023 20:05~DEL~05/08/2023 22:20~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-05-09T04:10:00",
                  ad: "2023-05-09T05:45:00",
                  rbd: "",
                  fn: "1701",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "3" },
                  rph: "",
                  jsell:
                    "6E~1701~ ~~DEL~05/09/2023 04:10~DOH~05/09/2023 05:45~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~A~ ~6E~ACINT~6200~~0~66~~X^0~A~ ~6E~ACINT~6200~~0~66~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 1180,
                      fbc: "ACINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1180_1413",
                      tax: [
                        { OT: 233 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 1413,
                      psup_fare: "1180_1413",
                    },
                  ],
                  pr: {
                    bf: 1180,
                    tf: 1413,
                    tax: [
                      { OT: 233 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1180_1413",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 1413,
                  },
                  routeId: "j1x10fswx",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~A~ ~6E~ACUINT~6202~~0~4~~X^0~A~ ~6E~ACUINT~6202~~0~4~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 1210,
                      fbc: "ACUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1210_1446",
                      tax: [
                        { OT: 236 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 1446,
                      psup_fare: "1210_1446",
                    },
                  ],
                  pr: {
                    bf: 1210,
                    tf: 1446,
                    tax: [
                      { OT: 236 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1210_1446",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 1446,
                  },
                  routeId: "omqa96k0w9",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "gV9VlJcF4lo=|c8vLGEjg5j7NNr8U4/n4JN2DKZGLTH99mWKIwdR5uRWciziCl+wbZTrpWnMmGN+Yy9uJ4ZUTuD0HpLhkPra8oFmlLXu64hUIzZhRraKsuKvdW8dDCeFMBF8RTGVhnAM8gqCD8NTV8Ig=",
              stop: 1,
            },
            {
              tid: "h8lDPwysGNWWQ1mr_2",
              ref: 2,
              ofcid: "",
              sdur: "9:30",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DEL",
                  dd: "2023-05-08T20:15:00",
                  ad: "2023-05-08T22:40:00",
                  rbd: "",
                  fn: "2213",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "2" },
                  rph: "",
                  jsell:
                    "6E~2213~ ~~HYD~05/08/2023 20:15~DEL~05/08/2023 22:40~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-05-09T04:10:00",
                  ad: "2023-05-09T05:45:00",
                  rbd: "",
                  fn: "1701",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "3" },
                  rph: "",
                  jsell:
                    "6E~1701~ ~~DEL~05/09/2023 04:10~DOH~05/09/2023 05:45~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~A~ ~6E~ACINT~6200~~0~66~~X^0~A~ ~6E~ACINT~6200~~0~66~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 1180,
                      fbc: "ACINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1180_1413",
                      tax: [
                        { OT: 233 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 1413,
                      psup_fare: "1180_1413",
                    },
                  ],
                  pr: {
                    bf: 1180,
                    tf: 1413,
                    tax: [
                      { OT: 233 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1180_1413",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 1413,
                  },
                  routeId: "5ub7qhjreeh",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~A~ ~6E~ACUINT~6202~~0~4~~X^0~A~ ~6E~ACUINT~6202~~0~4~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 1210,
                      fbc: "ACUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1210_1446",
                      tax: [
                        { OT: 236 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 1446,
                      psup_fare: "1210_1446",
                    },
                  ],
                  pr: {
                    bf: 1210,
                    tf: 1446,
                    tax: [
                      { OT: 236 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1210_1446",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 1446,
                  },
                  routeId: "9kk1933t1n",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "gV9VlJcF4lo=|c8vLGEjg5j7NNr8U4/n4JN2DKZGLTH99mWKIwdR5uRWciziCl+wbZTrpWnMmGN+Yy9uJ4ZUTuD0HpLhkPra8oFmlLXu64hUIzZhRraKsuKvdW8dDCeFMBF8RTGVhnAM8gqCD8NTV8Ig=",
              stop: 1,
            },
            {
              tid: "duGylblKMGufwn39_2",
              ref: 2,
              ofcid: "",
              sdur: "8:25",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DEL",
                  dd: "2023-05-08T21:20:00",
                  ad: "2023-05-08T23:30:00",
                  rbd: "",
                  fn: "6684",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "1" },
                  rph: "",
                  jsell:
                    "6E~6684~ ~~HYD~05/08/2023 21:20~DEL~05/08/2023 23:30~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-05-09T04:10:00",
                  ad: "2023-05-09T05:45:00",
                  rbd: "",
                  fn: "1701",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "3" },
                  rph: "",
                  jsell:
                    "6E~1701~ ~~DEL~05/09/2023 04:10~DOH~05/09/2023 05:45~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~A~ ~6E~ACINT~6200~~0~66~~X^0~A~ ~6E~ACINT~6200~~0~66~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 1180,
                      fbc: "ACINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1180_1413",
                      tax: [
                        { OT: 233 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 1413,
                      psup_fare: "1180_1413",
                    },
                  ],
                  pr: {
                    bf: 1180,
                    tf: 1413,
                    tax: [
                      { OT: 233 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1180_1413",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 1413,
                  },
                  routeId: "f2b1n471j4",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~A~ ~6E~ACUINT~6202~~0~4~~X^0~A~ ~6E~ACUINT~6202~~0~4~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 1210,
                      fbc: "ACUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1210_1446",
                      tax: [
                        { OT: 236 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 1446,
                      psup_fare: "1210_1446",
                    },
                  ],
                  pr: {
                    bf: 1210,
                    tf: 1446,
                    tax: [
                      { OT: 236 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1210_1446",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 1446,
                  },
                  routeId: "hpyggvj0f",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "gV9VlJcF4lo=|c8vLGEjg5j7NNr8U4/n4JN2DKZGLTH99mWKIwdR5uRWciziCl+wbZTrpWnMmGN+Yy9uJ4ZUTuD0HpLhkPra8oFmlLXu64hUIzZhRraKsuKvdW8dDCeFMBF8RTGVhnAM8gqCD8NTV8Ig=",
              stop: 1,
            },
            {
              tid: "VDLOURWZltsgDn4W_2",
              ref: 2,
              ofcid: "",
              sdur: "7:10",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DEL",
                  dd: "2023-05-08T22:35:00",
                  ad: "2023-05-09T00:45:00",
                  rbd: "",
                  fn: "2324",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "2" },
                  rph: "",
                  jsell:
                    "6E~2324~ ~~HYD~05/08/2023 22:35~DEL~05/09/2023 00:45~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-05-09T04:10:00",
                  ad: "2023-05-09T05:45:00",
                  rbd: "",
                  fn: "1701",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "3" },
                  rph: "",
                  jsell:
                    "6E~1701~ ~~DEL~05/09/2023 04:10~DOH~05/09/2023 05:45~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~A~ ~6E~ACINT~6200~~0~66~~X^0~A~ ~6E~ACINT~6200~~0~66~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 1180,
                      fbc: "ACINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1180_1413",
                      tax: [
                        { OT: 233 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 1413,
                      psup_fare: "1180_1413",
                    },
                  ],
                  pr: {
                    bf: 1180,
                    tf: 1413,
                    tax: [
                      { OT: 233 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1180_1413",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 1413,
                  },
                  routeId: "y0bf0huaq8",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~A~ ~6E~ACUINT~6202~~0~4~~X^0~A~ ~6E~ACUINT~6202~~0~4~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 1210,
                      fbc: "ACUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1210_1446",
                      tax: [
                        { OT: 236 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 1446,
                      psup_fare: "1210_1446",
                    },
                  ],
                  pr: {
                    bf: 1210,
                    tf: 1446,
                    tax: [
                      { OT: 236 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1210_1446",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 1446,
                  },
                  routeId: "a16ckfus7b",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "gV9VlJcF4lo=|c8vLGEjg5j7NNr8U4/n4JN2DKZGLTH99mWKIwdR5uRWciziCl+wbZTrpWnMmGN+Yy9uJ4ZUTuD0HpLhkPra8oFmlLXu64hUIzZhRraKsuKvdW8dDCeFMBF8RTGVhnAM8gqCD8NTV8Ig=",
              stop: 1,
            },
          ],
          paxpr: [
            {
              bf: 2982,
              fbc: "ACINT",
              ptype: "ADT",
              scur: "QAR",
              sup_fare: "1802_2004",
              tax: [
                { OT: 435 },
                { attmkp: 0 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              tf: 3417,
              psup_fare: "2982_3417",
            },
          ],
          pr: {
            bf: 2982,
            tf: 3417,
            tax: [
              { OT: 435 },
              { attmkp: 0 },
              { tamkp: 0 },
              { stamkp: 0 },
              { attdisc: 0 },
              { tadisc: 0 },
              { stadisc: 0 },
            ],
            ucur: "QAR",
            exchange_rate: 1,
            scur: "QAR",
            sup_fare: "2982_3417",
            sup_cur_fare: "",
            cabb: "",
            chekb: "",
            seats: "",
            meals: "",
            chanb: "",
            ftyp: "Regular - R",
            markupnames: [],
            ttf: 3417,
          },
          sup: "4",
          airlineList: "6E",
          servicefee: [],
        },
        {
          srcorigns: [
            {
              tid: "7rr83w0yWTITKUu7_1",
              ref: 1,
              ofcid: "",
              sdur: "19:30",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-05-06T02:10:00",
                  ad: "2023-05-06T08:40:00",
                  rbd: "",
                  fn: "1737",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "3" },
                  rph: "",
                  jsell:
                    "6E~1737~ ~~DOH~05/06/2023 02:10~DEL~05/06/2023 08:40~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-05-06T19:30:00",
                  ad: "2023-05-06T21:40:00",
                  rbd: "",
                  fn: "2104",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "2" },
                  rph: "",
                  jsell:
                    "6E~2104~ ~~DEL~05/06/2023 19:30~HYD~05/06/2023 21:40~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~A~ ~6E~ACINT~6200~~0~65~~X^0~A~ ~6E~ACINT~6200~~0~65~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 1802,
                      fbc: "ACINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1802_2004",
                      tax: [
                        { OT: 202 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 2004,
                      psup_fare: "1802_2004",
                    },
                  ],
                  pr: {
                    bf: 1802,
                    tf: 2004,
                    tax: [
                      { OT: 202 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1802_2004",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 2004,
                  },
                  routeId: "fvxktneksp",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~A~ ~6E~ACUINT~6202~~0~5~~X^0~A~ ~6E~ACUINT~6202~~0~5~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 1833,
                      fbc: "ACUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1833_2036",
                      tax: [
                        { OT: 203 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 2036,
                      psup_fare: "1833_2036",
                    },
                  ],
                  pr: {
                    bf: 1833,
                    tf: 2036,
                    tax: [
                      { OT: 203 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1833_2036",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 2036,
                  },
                  routeId: "maw6rrjced",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "gV9VlJcF4lo=|c8vLGEjg5j7NNr8U4/n4JN2DKZGLTH99mWKIwdR5uRWciziCl+wbZTrpWnMmGN+Yy9uJ4ZUTuD0HpLhkPra8oFmlLXu64hUIzZhRraKsuKvdW8dDCeFMBF8RTGVhnAM8gqCD8NTV8Ig=",
              stop: 1,
            },
            {
              tid: "lJIqa5SHUDnRJ8A9_1",
              ref: 1,
              ofcid: "",
              sdur: "12:40",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-05-06T02:10:00",
                  ad: "2023-05-06T08:40:00",
                  rbd: "",
                  fn: "1737",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "3" },
                  rph: "",
                  jsell:
                    "6E~1737~ ~~DOH~05/06/2023 02:10~DEL~05/06/2023 08:40~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-05-06T12:45:00",
                  ad: "2023-05-06T14:50:00",
                  rbd: "",
                  fn: "2004",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "2" },
                  rph: "",
                  jsell:
                    "6E~2004~ ~~DEL~05/06/2023 12:45~HYD~05/06/2023 14:50~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~A~ ~6E~ACINT~6200~~0~65~~X^0~A~ ~6E~ACINT~6200~~0~65~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 1802,
                      fbc: "ACINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1802_2004",
                      tax: [
                        { OT: 202 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 2004,
                      psup_fare: "1802_2004",
                    },
                  ],
                  pr: {
                    bf: 1802,
                    tf: 2004,
                    tax: [
                      { OT: 202 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1802_2004",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 2004,
                  },
                  routeId: "4tow7l2ohj",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~A~ ~6E~ACUINT~6202~~0~5~~X^0~A~ ~6E~ACUINT~6202~~0~5~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 1833,
                      fbc: "ACUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1833_2036",
                      tax: [
                        { OT: 203 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 2036,
                      psup_fare: "1833_2036",
                    },
                  ],
                  pr: {
                    bf: 1833,
                    tf: 2036,
                    tax: [
                      { OT: 203 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1833_2036",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 2036,
                  },
                  routeId: "2b5ov8si39i",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "gV9VlJcF4lo=|c8vLGEjg5j7NNr8U4/n4JN2DKZGLTH99mWKIwdR5uRWciziCl+wbZTrpWnMmGN+Yy9uJ4ZUTuD0HpLhkPra8oFmlLXu64hUIzZhRraKsuKvdW8dDCeFMBF8RTGVhnAM8gqCD8NTV8Ig=",
              stop: 1,
            },
            {
              tid: "NAkMJ5pAzskpWi0N_1",
              ref: 1,
              ofcid: "",
              sdur: "13:20",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-05-06T02:10:00",
                  ad: "2023-05-06T08:40:00",
                  rbd: "",
                  fn: "1737",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "3" },
                  rph: "",
                  jsell:
                    "6E~1737~ ~~DOH~05/06/2023 02:10~DEL~05/06/2023 08:40~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-05-06T13:25:00",
                  ad: "2023-05-06T15:30:00",
                  rbd: "",
                  fn: "2014",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "2" },
                  rph: "",
                  jsell:
                    "6E~2014~ ~~DEL~05/06/2023 13:25~HYD~05/06/2023 15:30~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~A~ ~6E~ACINT~6200~~0~65~~X^0~A~ ~6E~ACINT~6200~~0~65~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 1802,
                      fbc: "ACINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1802_2004",
                      tax: [
                        { OT: 202 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 2004,
                      psup_fare: "1802_2004",
                    },
                  ],
                  pr: {
                    bf: 1802,
                    tf: 2004,
                    tax: [
                      { OT: 202 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1802_2004",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 2004,
                  },
                  routeId: "srugv0sqyii",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~A~ ~6E~ACUINT~6202~~0~5~~X^0~A~ ~6E~ACUINT~6202~~0~5~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 1833,
                      fbc: "ACUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1833_2036",
                      tax: [
                        { OT: 203 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 2036,
                      psup_fare: "1833_2036",
                    },
                  ],
                  pr: {
                    bf: 1833,
                    tf: 2036,
                    tax: [
                      { OT: 203 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1833_2036",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 2036,
                  },
                  routeId: "kqc5aavv7i",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "gV9VlJcF4lo=|c8vLGEjg5j7NNr8U4/n4JN2DKZGLTH99mWKIwdR5uRWciziCl+wbZTrpWnMmGN+Yy9uJ4ZUTuD0HpLhkPra8oFmlLXu64hUIzZhRraKsuKvdW8dDCeFMBF8RTGVhnAM8gqCD8NTV8Ig=",
              stop: 1,
            },
            {
              tid: "EIsKVMeKBpVa6mUI_1",
              ref: 1,
              ofcid: "",
              sdur: "18:0",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-05-06T02:10:00",
                  ad: "2023-05-06T08:40:00",
                  rbd: "",
                  fn: "1737",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "3" },
                  rph: "",
                  jsell:
                    "6E~1737~ ~~DOH~05/06/2023 02:10~DEL~05/06/2023 08:40~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-05-06T18:00:00",
                  ad: "2023-05-06T20:10:00",
                  rbd: "",
                  fn: "6823",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "1" },
                  rph: "",
                  jsell:
                    "6E~6823~ ~~DEL~05/06/2023 18:00~HYD~05/06/2023 20:10~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~A~ ~6E~ACINT~6200~~0~65~~X^0~A~ ~6E~ACINT~6200~~0~65~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 1802,
                      fbc: "ACINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1802_2004",
                      tax: [
                        { OT: 202 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 2004,
                      psup_fare: "1802_2004",
                    },
                  ],
                  pr: {
                    bf: 1802,
                    tf: 2004,
                    tax: [
                      { OT: 202 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1802_2004",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 2004,
                  },
                  routeId: "eraa7jkap",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~A~ ~6E~ACUINT~6202~~0~5~~X^0~A~ ~6E~ACUINT~6202~~0~5~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 1833,
                      fbc: "ACUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1833_2036",
                      tax: [
                        { OT: 203 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 2036,
                      psup_fare: "1833_2036",
                    },
                  ],
                  pr: {
                    bf: 1833,
                    tf: 2036,
                    tax: [
                      { OT: 203 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1833_2036",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 2036,
                  },
                  routeId: "umx7fa6qje",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "gV9VlJcF4lo=|c8vLGEjg5j7NNr8U4/n4JN2DKZGLTH99mWKIwdR5uRWciziCl+wbZTrpWnMmGN+Yy9uJ4ZUTuD0HpLhkPra8oFmlLXu64hUIzZhRraKsuKvdW8dDCeFMBF8RTGVhnAM8gqCD8NTV8Ig=",
              stop: 1,
            },
            {
              tid: "GchR4MFEzEe8KZLx_1",
              ref: 1,
              ofcid: "",
              sdur: "14:45",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-05-06T02:10:00",
                  ad: "2023-05-06T08:40:00",
                  rbd: "",
                  fn: "1737",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "3" },
                  rph: "",
                  jsell:
                    "6E~1737~ ~~DOH~05/06/2023 02:10~DEL~05/06/2023 08:40~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-05-06T15:00:00",
                  ad: "2023-05-06T16:55:00",
                  rbd: "",
                  fn: "2187",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "2" },
                  rph: "",
                  jsell:
                    "6E~2187~ ~~DEL~05/06/2023 15:00~HYD~05/06/2023 16:55~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~A~ ~6E~ACINT~6200~~0~65~~X^0~A~ ~6E~ACINT~6200~~0~65~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 1802,
                      fbc: "ACINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1802_2004",
                      tax: [
                        { OT: 202 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 2004,
                      psup_fare: "1802_2004",
                    },
                  ],
                  pr: {
                    bf: 1802,
                    tf: 2004,
                    tax: [
                      { OT: 202 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1802_2004",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 2004,
                  },
                  routeId: "175txao85rf",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~A~ ~6E~ACUINT~6202~~0~5~~X^0~A~ ~6E~ACUINT~6202~~0~5~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 1833,
                      fbc: "ACUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1833_2036",
                      tax: [
                        { OT: 203 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 2036,
                      psup_fare: "1833_2036",
                    },
                  ],
                  pr: {
                    bf: 1833,
                    tf: 2036,
                    tax: [
                      { OT: 203 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1833_2036",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 2036,
                  },
                  routeId: "cf8bnuuyszk",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "gV9VlJcF4lo=|c8vLGEjg5j7NNr8U4/n4JN2DKZGLTH99mWKIwdR5uRWciziCl+wbZTrpWnMmGN+Yy9uJ4ZUTuD0HpLhkPra8oFmlLXu64hUIzZhRraKsuKvdW8dDCeFMBF8RTGVhnAM8gqCD8NTV8Ig=",
              stop: 1,
            },
            {
              tid: "obpc8ahNbWfTBNsq_1",
              ref: 1,
              ofcid: "",
              sdur: "25:50",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-05-06T02:10:00",
                  ad: "2023-05-06T08:40:00",
                  rbd: "",
                  fn: "1737",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "3" },
                  rph: "",
                  jsell:
                    "6E~1737~ ~~DOH~05/06/2023 02:10~DEL~05/06/2023 08:40~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-05-07T01:50:00",
                  ad: "2023-05-07T04:00:00",
                  rbd: "",
                  fn: "2325",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "2" },
                  rph: "",
                  jsell:
                    "6E~2325~ ~~DEL~05/07/2023 01:50~HYD~05/07/2023 04:00~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~A~ ~6E~ACINT~6200~~0~65~~X^0~A~ ~6E~ACINT~6200~~0~65~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 1802,
                      fbc: "ACINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1802_2004",
                      tax: [
                        { OT: 202 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 2004,
                      psup_fare: "1802_2004",
                    },
                  ],
                  pr: {
                    bf: 1802,
                    tf: 2004,
                    tax: [
                      { OT: 202 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1802_2004",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 2004,
                  },
                  routeId: "gvjs9o5gfr",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~A~ ~6E~ACUINT~6202~~0~5~~X^0~A~ ~6E~ACUINT~6202~~0~5~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 1833,
                      fbc: "ACUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1833_2036",
                      tax: [
                        { OT: 203 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 2036,
                      psup_fare: "1833_2036",
                    },
                  ],
                  pr: {
                    bf: 1833,
                    tf: 2036,
                    tax: [
                      { OT: 203 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1833_2036",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 2036,
                  },
                  routeId: "a7uvvezvfw",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "gV9VlJcF4lo=|c8vLGEjg5j7NNr8U4/n4JN2DKZGLTH99mWKIwdR5uRWciziCl+wbZTrpWnMmGN+Yy9uJ4ZUTuD0HpLhkPra8oFmlLXu64hUIzZhRraKsuKvdW8dDCeFMBF8RTGVhnAM8gqCD8NTV8Ig=",
              stop: 1,
            },
            {
              tid: "vM2Eex2LhIPsFEaE_1",
              ref: 1,
              ofcid: "",
              sdur: "13:45",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-05-06T20:30:00",
                  ad: "2023-05-07T03:15:00",
                  rbd: "",
                  fn: "1774",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "3" },
                  rph: "",
                  jsell:
                    "6E~1774~ ~~DOH~05/06/2023 20:30~DEL~05/07/2023 03:15~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-05-07T07:55:00",
                  ad: "2023-05-07T10:15:00",
                  rbd: "",
                  fn: "2774",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "2" },
                  rph: "",
                  jsell:
                    "6E~2774~ ~~DEL~05/07/2023 07:55~HYD~05/07/2023 10:15~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~A~ ~6E~ACINT~6200~~0~65~~X^0~A~ ~6E~ACINT~6200~~0~65~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 1802,
                      fbc: "ACINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1802_2004",
                      tax: [
                        { OT: 202 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 2004,
                      psup_fare: "1802_2004",
                    },
                  ],
                  pr: {
                    bf: 1802,
                    tf: 2004,
                    tax: [
                      { OT: 202 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1802_2004",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 2004,
                  },
                  routeId: "kn44afx98a",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~A~ ~6E~ACUINT~6202~~0~5~~X^0~A~ ~6E~ACUINT~6202~~0~5~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 1833,
                      fbc: "ACUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1833_2036",
                      tax: [
                        { OT: 203 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 2036,
                      psup_fare: "1833_2036",
                    },
                  ],
                  pr: {
                    bf: 1833,
                    tf: 2036,
                    tax: [
                      { OT: 203 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1833_2036",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 2036,
                  },
                  routeId: "nrxikj38vm",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "gV9VlJcF4lo=|c8vLGEjg5j7NNr8U4/n4JN2DKZGLTH99mWKIwdR5uRWciziCl+wbZTrpWnMmGN+Yy9uJ4ZUTuD0HpLhkPra8oFmlLXu64hUIzZhRraKsuKvdW8dDCeFMBF8RTGVhnAM8gqCD8NTV8Ig=",
              stop: 1,
            },
            {
              tid: "SQR10nfLEidhxOAA_1",
              ref: 1,
              ofcid: "",
              sdur: "18:20",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-05-06T20:30:00",
                  ad: "2023-05-07T03:15:00",
                  rbd: "",
                  fn: "1774",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "3" },
                  rph: "",
                  jsell:
                    "6E~1774~ ~~DOH~05/06/2023 20:30~DEL~05/07/2023 03:15~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-05-07T12:45:00",
                  ad: "2023-05-07T14:50:00",
                  rbd: "",
                  fn: "2004",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "2" },
                  rph: "",
                  jsell:
                    "6E~2004~ ~~DEL~05/07/2023 12:45~HYD~05/07/2023 14:50~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~A~ ~6E~ACINT~6200~~0~65~~X^0~A~ ~6E~ACINT~6200~~0~65~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 1802,
                      fbc: "ACINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1802_2004",
                      tax: [
                        { OT: 202 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 2004,
                      psup_fare: "1802_2004",
                    },
                  ],
                  pr: {
                    bf: 1802,
                    tf: 2004,
                    tax: [
                      { OT: 202 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1802_2004",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 2004,
                  },
                  routeId: "5m79dpcadu",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~A~ ~6E~ACUINT~6202~~0~5~~X^0~A~ ~6E~ACUINT~6202~~0~5~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 1833,
                      fbc: "ACUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1833_2036",
                      tax: [
                        { OT: 203 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 2036,
                      psup_fare: "1833_2036",
                    },
                  ],
                  pr: {
                    bf: 1833,
                    tf: 2036,
                    tax: [
                      { OT: 203 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1833_2036",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 2036,
                  },
                  routeId: "1gmm6scn7f",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "gV9VlJcF4lo=|c8vLGEjg5j7NNr8U4/n4JN2DKZGLTH99mWKIwdR5uRWciziCl+wbZTrpWnMmGN+Yy9uJ4ZUTuD0HpLhkPra8oFmlLXu64hUIzZhRraKsuKvdW8dDCeFMBF8RTGVhnAM8gqCD8NTV8Ig=",
              stop: 1,
            },
            {
              tid: "yOW4eULaf0fwui6K_1",
              ref: 1,
              ofcid: "",
              sdur: "19:0",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-05-06T20:30:00",
                  ad: "2023-05-07T03:15:00",
                  rbd: "",
                  fn: "1774",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "3" },
                  rph: "",
                  jsell:
                    "6E~1774~ ~~DOH~05/06/2023 20:30~DEL~05/07/2023 03:15~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-05-07T13:25:00",
                  ad: "2023-05-07T15:30:00",
                  rbd: "",
                  fn: "2014",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "2" },
                  rph: "",
                  jsell:
                    "6E~2014~ ~~DEL~05/07/2023 13:25~HYD~05/07/2023 15:30~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~A~ ~6E~ACINT~6200~~0~65~~X^0~A~ ~6E~ACINT~6200~~0~65~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 1802,
                      fbc: "ACINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1802_2004",
                      tax: [
                        { OT: 202 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 2004,
                      psup_fare: "1802_2004",
                    },
                  ],
                  pr: {
                    bf: 1802,
                    tf: 2004,
                    tax: [
                      { OT: 202 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1802_2004",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 2004,
                  },
                  routeId: "aepk857s4i",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~A~ ~6E~ACUINT~6202~~0~5~~X^0~A~ ~6E~ACUINT~6202~~0~5~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 1833,
                      fbc: "ACUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1833_2036",
                      tax: [
                        { OT: 203 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 2036,
                      psup_fare: "1833_2036",
                    },
                  ],
                  pr: {
                    bf: 1833,
                    tf: 2036,
                    tax: [
                      { OT: 203 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1833_2036",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 2036,
                  },
                  routeId: "dwaxlpyk7u",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "gV9VlJcF4lo=|c8vLGEjg5j7NNr8U4/n4JN2DKZGLTH99mWKIwdR5uRWciziCl+wbZTrpWnMmGN+Yy9uJ4ZUTuD0HpLhkPra8oFmlLXu64hUIzZhRraKsuKvdW8dDCeFMBF8RTGVhnAM8gqCD8NTV8Ig=",
              stop: 1,
            },
            {
              tid: "XQkwS1uIKp8viLEO_1",
              ref: 1,
              ofcid: "",
              sdur: "12:30",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-05-06T20:30:00",
                  ad: "2023-05-07T03:15:00",
                  rbd: "",
                  fn: "1774",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "3" },
                  rph: "",
                  jsell:
                    "6E~1774~ ~~DOH~05/06/2023 20:30~DEL~05/07/2023 03:15~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-05-07T06:40:00",
                  ad: "2023-05-07T09:00:00",
                  rbd: "",
                  fn: "2331",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "2" },
                  rph: "",
                  jsell:
                    "6E~2331~ ~~DEL~05/07/2023 06:40~HYD~05/07/2023 09:00~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~A~ ~6E~ACINT~6200~~0~65~~X^0~A~ ~6E~ACINT~6200~~0~65~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 1802,
                      fbc: "ACINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1802_2004",
                      tax: [
                        { OT: 202 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 2004,
                      psup_fare: "1802_2004",
                    },
                  ],
                  pr: {
                    bf: 1802,
                    tf: 2004,
                    tax: [
                      { OT: 202 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1802_2004",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 2004,
                  },
                  routeId: "ezj4xudid7",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~A~ ~6E~ACUINT~6202~~0~5~~X^0~A~ ~6E~ACUINT~6202~~0~5~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 1833,
                      fbc: "ACUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1833_2036",
                      tax: [
                        { OT: 203 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 2036,
                      psup_fare: "1833_2036",
                    },
                  ],
                  pr: {
                    bf: 1833,
                    tf: 2036,
                    tax: [
                      { OT: 203 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1833_2036",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 2036,
                  },
                  routeId: "8bu08ruoeu",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "gV9VlJcF4lo=|c8vLGEjg5j7NNr8U4/n4JN2DKZGLTH99mWKIwdR5uRWciziCl+wbZTrpWnMmGN+Yy9uJ4ZUTuD0HpLhkPra8oFmlLXu64hUIzZhRraKsuKvdW8dDCeFMBF8RTGVhnAM8gqCD8NTV8Ig=",
              stop: 1,
            },
            {
              tid: "TDtWhBKGo5dUUrca_1",
              ref: 1,
              ofcid: "",
              sdur: "20:25",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-05-06T20:30:00",
                  ad: "2023-05-07T03:15:00",
                  rbd: "",
                  fn: "1774",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "3" },
                  rph: "",
                  jsell:
                    "6E~1774~ ~~DOH~05/06/2023 20:30~DEL~05/07/2023 03:15~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-05-07T15:00:00",
                  ad: "2023-05-07T16:55:00",
                  rbd: "",
                  fn: "2187",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "2" },
                  rph: "",
                  jsell:
                    "6E~2187~ ~~DEL~05/07/2023 15:00~HYD~05/07/2023 16:55~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~A~ ~6E~ACINT~6200~~0~65~~X^0~A~ ~6E~ACINT~6200~~0~65~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 1802,
                      fbc: "ACINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1802_2004",
                      tax: [
                        { OT: 202 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 2004,
                      psup_fare: "1802_2004",
                    },
                  ],
                  pr: {
                    bf: 1802,
                    tf: 2004,
                    tax: [
                      { OT: 202 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1802_2004",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 2004,
                  },
                  routeId: "kemzj23hrn",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~A~ ~6E~ACUINT~6202~~0~5~~X^0~A~ ~6E~ACUINT~6202~~0~5~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 1833,
                      fbc: "ACUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1833_2036",
                      tax: [
                        { OT: 203 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 2036,
                      psup_fare: "1833_2036",
                    },
                  ],
                  pr: {
                    bf: 1833,
                    tf: 2036,
                    tax: [
                      { OT: 203 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1833_2036",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 2036,
                  },
                  routeId: "21xutw51bhi",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "gV9VlJcF4lo=|c8vLGEjg5j7NNr8U4/n4JN2DKZGLTH99mWKIwdR5uRWciziCl+wbZTrpWnMmGN+Yy9uJ4ZUTuD0HpLhkPra8oFmlLXu64hUIzZhRraKsuKvdW8dDCeFMBF8RTGVhnAM8gqCD8NTV8Ig=",
              stop: 1,
            },
            {
              tid: "5v4fooeMNSlSor5e_1",
              ref: 1,
              ofcid: "",
              sdur: "23:40",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-05-06T20:30:00",
                  ad: "2023-05-07T03:15:00",
                  rbd: "",
                  fn: "1774",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "3" },
                  rph: "",
                  jsell:
                    "6E~1774~ ~~DOH~05/06/2023 20:30~DEL~05/07/2023 03:15~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-05-07T18:00:00",
                  ad: "2023-05-07T20:10:00",
                  rbd: "",
                  fn: "6823",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "1" },
                  rph: "",
                  jsell:
                    "6E~6823~ ~~DEL~05/07/2023 18:00~HYD~05/07/2023 20:10~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~A~ ~6E~ACINT~6200~~0~65~~X^0~A~ ~6E~ACINT~6200~~0~65~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 1802,
                      fbc: "ACINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1802_2004",
                      tax: [
                        { OT: 202 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 2004,
                      psup_fare: "1802_2004",
                    },
                  ],
                  pr: {
                    bf: 1802,
                    tf: 2004,
                    tax: [
                      { OT: 202 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1802_2004",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 2004,
                  },
                  routeId: "riajf49wjqk",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~A~ ~6E~ACUINT~6202~~0~5~~X^0~A~ ~6E~ACUINT~6202~~0~5~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 1833,
                      fbc: "ACUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1833_2036",
                      tax: [
                        { OT: 203 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 2036,
                      psup_fare: "1833_2036",
                    },
                  ],
                  pr: {
                    bf: 1833,
                    tf: 2036,
                    tax: [
                      { OT: 203 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1833_2036",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 2036,
                  },
                  routeId: "b4g8fgyf4t",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "gV9VlJcF4lo=|c8vLGEjg5j7NNr8U4/n4JN2DKZGLTH99mWKIwdR5uRWciziCl+wbZTrpWnMmGN+Yy9uJ4ZUTuD0HpLhkPra8oFmlLXu64hUIzZhRraKsuKvdW8dDCeFMBF8RTGVhnAM8gqCD8NTV8Ig=",
              stop: 1,
            },
            {
              tid: "vXJKxxDzslDLNne5_1",
              ref: 1,
              ofcid: "",
              sdur: "25:10",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-05-06T20:30:00",
                  ad: "2023-05-07T03:15:00",
                  rbd: "",
                  fn: "1774",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "3" },
                  rph: "",
                  jsell:
                    "6E~1774~ ~~DOH~05/06/2023 20:30~DEL~05/07/2023 03:15~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-05-07T19:30:00",
                  ad: "2023-05-07T21:40:00",
                  rbd: "",
                  fn: "2104",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "2" },
                  rph: "",
                  jsell:
                    "6E~2104~ ~~DEL~05/07/2023 19:30~HYD~05/07/2023 21:40~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~A~ ~6E~ACINT~6200~~0~65~~X^0~A~ ~6E~ACINT~6200~~0~65~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 1802,
                      fbc: "ACINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1802_2004",
                      tax: [
                        { OT: 202 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 2004,
                      psup_fare: "1802_2004",
                    },
                  ],
                  pr: {
                    bf: 1802,
                    tf: 2004,
                    tax: [
                      { OT: 202 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1802_2004",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 2004,
                  },
                  routeId: "z48rbyu7zt",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~A~ ~6E~ACUINT~6202~~0~5~~X^0~A~ ~6E~ACUINT~6202~~0~5~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 1833,
                      fbc: "ACUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1833_2036",
                      tax: [
                        { OT: 203 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 2036,
                      psup_fare: "1833_2036",
                    },
                  ],
                  pr: {
                    bf: 1833,
                    tf: 2036,
                    tax: [
                      { OT: 203 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1833_2036",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 2036,
                  },
                  routeId: "67q9qh6v5r",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "gV9VlJcF4lo=|c8vLGEjg5j7NNr8U4/n4JN2DKZGLTH99mWKIwdR5uRWciziCl+wbZTrpWnMmGN+Yy9uJ4ZUTuD0HpLhkPra8oFmlLXu64hUIzZhRraKsuKvdW8dDCeFMBF8RTGVhnAM8gqCD8NTV8Ig=",
              stop: 1,
            },
            {
              tid: "wgSkEs3zbCfNqHsF_1",
              ref: 1,
              ofcid: "",
              sdur: "15:35",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-05-06T20:30:00",
                  ad: "2023-05-07T03:15:00",
                  rbd: "",
                  fn: "1774",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "3" },
                  rph: "",
                  jsell:
                    "6E~1774~ ~~DOH~05/06/2023 20:30~DEL~05/07/2023 03:15~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-05-07T09:55:00",
                  ad: "2023-05-07T12:05:00",
                  rbd: "",
                  fn: "2005",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "2" },
                  rph: "",
                  jsell:
                    "6E~2005~ ~~DEL~05/07/2023 09:55~HYD~05/07/2023 12:05~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~A~ ~6E~ACINT~6200~~0~65~~X^0~A~ ~6E~ACINT~6200~~0~65~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 1802,
                      fbc: "ACINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1802_2004",
                      tax: [
                        { OT: 202 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 2004,
                      psup_fare: "1802_2004",
                    },
                  ],
                  pr: {
                    bf: 1802,
                    tf: 2004,
                    tax: [
                      { OT: 202 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1802_2004",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 2004,
                  },
                  routeId: "lm2204ycgx",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~A~ ~6E~ACUINT~6202~~0~5~~X^0~A~ ~6E~ACUINT~6202~~0~5~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 1833,
                      fbc: "ACUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1833_2036",
                      tax: [
                        { OT: 203 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 2036,
                      psup_fare: "1833_2036",
                    },
                  ],
                  pr: {
                    bf: 1833,
                    tf: 2036,
                    tax: [
                      { OT: 203 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1833_2036",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 2036,
                  },
                  routeId: "ngmc2gp7yz",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "gV9VlJcF4lo=|c8vLGEjg5j7NNr8U4/n4JN2DKZGLTH99mWKIwdR5uRWciziCl+wbZTrpWnMmGN+Yy9uJ4ZUTuD0HpLhkPra8oFmlLXu64hUIzZhRraKsuKvdW8dDCeFMBF8RTGVhnAM8gqCD8NTV8Ig=",
              stop: 1,
            },
            {
              tid: "MMhDEEfdQlOI8leq_1",
              ref: 1,
              ofcid: "",
              sdur: "26:55",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-05-06T20:30:00",
                  ad: "2023-05-07T03:15:00",
                  rbd: "",
                  fn: "1774",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "3" },
                  rph: "",
                  jsell:
                    "6E~1774~ ~~DOH~05/06/2023 20:30~DEL~05/07/2023 03:15~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-05-07T21:15:00",
                  ad: "2023-05-07T23:25:00",
                  rbd: "",
                  fn: "2136",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "2" },
                  rph: "",
                  jsell:
                    "6E~2136~ ~~DEL~05/07/2023 21:15~HYD~05/07/2023 23:25~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~A~ ~6E~ACINT~6200~~0~65~~X^0~A~ ~6E~ACINT~6200~~0~65~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 1802,
                      fbc: "ACINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1802_2004",
                      tax: [
                        { OT: 202 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 2004,
                      psup_fare: "1802_2004",
                    },
                  ],
                  pr: {
                    bf: 1802,
                    tf: 2004,
                    tax: [
                      { OT: 202 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1802_2004",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 2004,
                  },
                  routeId: "7eeag6wke3",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~A~ ~6E~ACUINT~6202~~0~5~~X^0~A~ ~6E~ACUINT~6202~~0~5~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 1833,
                      fbc: "ACUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1833_2036",
                      tax: [
                        { OT: 203 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 2036,
                      psup_fare: "1833_2036",
                    },
                  ],
                  pr: {
                    bf: 1833,
                    tf: 2036,
                    tax: [
                      { OT: 203 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1833_2036",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 2036,
                  },
                  routeId: "x3unv1m6ua",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "gV9VlJcF4lo=|c8vLGEjg5j7NNr8U4/n4JN2DKZGLTH99mWKIwdR5uRWciziCl+wbZTrpWnMmGN+Yy9uJ4ZUTuD0HpLhkPra8oFmlLXu64hUIzZhRraKsuKvdW8dDCeFMBF8RTGVhnAM8gqCD8NTV8Ig=",
              stop: 1,
            },
          ],
          destorigins: [
            {
              tid: "ohwT5LMDTOvoSLTP_2",
              ref: 2,
              ofcid: "",
              sdur: "7:50",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DEL",
                  dd: "2023-05-08T05:10:00",
                  ad: "2023-05-08T07:15:00",
                  rbd: "",
                  fn: "2773",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "2" },
                  rph: "",
                  jsell:
                    "6E~2773~ ~~HYD~05/08/2023 05:10~DEL~05/08/2023 07:15~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-05-08T10:00:00",
                  ad: "2023-05-08T13:00:00",
                  rbd: "",
                  fn: "1307",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "3" },
                  rph: "",
                  jsell:
                    "6E~1307~ ~~DEL~05/08/2023 10:00~DOH~05/08/2023 13:00~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 1347,
                      fbc: "WCINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1347_1595",
                      tax: [
                        { OT: 248 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 1595,
                      psup_fare: "1347_1595",
                    },
                  ],
                  pr: {
                    bf: 1347,
                    tf: 1595,
                    tax: [
                      { OT: 248 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1347_1595",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 1595,
                  },
                  routeId: "6e2cbw071x",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 1378,
                      fbc: "WCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1378_1629",
                      tax: [
                        { OT: 251 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 1629,
                      psup_fare: "1378_1629",
                    },
                  ],
                  pr: {
                    bf: 1378,
                    tf: 1629,
                    tax: [
                      { OT: 251 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1378_1629",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 1629,
                  },
                  routeId: "hd4gfmonbk",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "gV9VlJcF4lo=|c8vLGEjg5j7NNr8U4/n4JN2DKZGLTH99mWKIwdR5uRWciziCl+wbZTrpWnMmGN+Yy9uJ4ZUTuD0HpLhkPra8oFmlLXu64hUIzZhRraKsuKvdW8dDCeFMBF8RTGVhnAM8gqCD8NTV8Ig=",
              stop: 1,
            },
            {
              tid: "vHxhAbq7gdWSHQx5_2",
              ref: 2,
              ofcid: "",
              sdur: "6:55",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DEL",
                  dd: "2023-05-08T07:05:00",
                  ad: "2023-05-08T09:15:00",
                  rbd: "",
                  fn: "2011",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "2" },
                  rph: "",
                  jsell:
                    "6E~2011~ ~~HYD~05/08/2023 07:05~DEL~05/08/2023 09:15~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-05-08T11:00:00",
                  ad: "2023-05-08T14:00:00",
                  rbd: "",
                  fn: "1311",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "3" },
                  rph: "",
                  jsell:
                    "6E~1311~ ~~DEL~05/08/2023 11:00~DOH~05/08/2023 14:00~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 1347,
                      fbc: "WCINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1347_1595",
                      tax: [
                        { OT: 248 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 1595,
                      psup_fare: "1347_1595",
                    },
                  ],
                  pr: {
                    bf: 1347,
                    tf: 1595,
                    tax: [
                      { OT: 248 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1347_1595",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 1595,
                  },
                  routeId: "ellfa16d3y",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 1378,
                      fbc: "WCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1378_1629",
                      tax: [
                        { OT: 251 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 1629,
                      psup_fare: "1378_1629",
                    },
                  ],
                  pr: {
                    bf: 1378,
                    tf: 1629,
                    tax: [
                      { OT: 251 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1378_1629",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 1629,
                  },
                  routeId: "iabtb6oj96",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "gV9VlJcF4lo=|c8vLGEjg5j7NNr8U4/n4JN2DKZGLTH99mWKIwdR5uRWciziCl+wbZTrpWnMmGN+Yy9uJ4ZUTuD0HpLhkPra8oFmlLXu64hUIzZhRraKsuKvdW8dDCeFMBF8RTGVhnAM8gqCD8NTV8Ig=",
              stop: 1,
            },
            {
              tid: "qCd90fKKrk1iUoDH_2",
              ref: 2,
              ofcid: "",
              sdur: "14:25",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DEL",
                  dd: "2023-05-08T22:35:00",
                  ad: "2023-05-09T00:45:00",
                  rbd: "",
                  fn: "2324",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "2" },
                  rph: "",
                  jsell:
                    "6E~2324~ ~~HYD~05/08/2023 22:35~DEL~05/09/2023 00:45~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-05-09T10:00:00",
                  ad: "2023-05-09T13:00:00",
                  rbd: "",
                  fn: "1307",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "3" },
                  rph: "",
                  jsell:
                    "6E~1307~ ~~DEL~05/09/2023 10:00~DOH~05/09/2023 13:00~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 1347,
                      fbc: "WCINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1347_1595",
                      tax: [
                        { OT: 248 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 1595,
                      psup_fare: "1347_1595",
                    },
                  ],
                  pr: {
                    bf: 1347,
                    tf: 1595,
                    tax: [
                      { OT: 248 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1347_1595",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 1595,
                  },
                  routeId: "7syw9robkr",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 1378,
                      fbc: "WCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "1378_1629",
                      tax: [
                        { OT: 251 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 1629,
                      psup_fare: "1378_1629",
                    },
                  ],
                  pr: {
                    bf: 1378,
                    tf: 1629,
                    tax: [
                      { OT: 251 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "QAR",
                    exchange_rate: 1,
                    scur: "QAR",
                    sup_fare: "1378_1629",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 1629,
                  },
                  routeId: "j9wklpl11o",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "gV9VlJcF4lo=|c8vLGEjg5j7NNr8U4/n4JN2DKZGLTH99mWKIwdR5uRWciziCl+wbZTrpWnMmGN+Yy9uJ4ZUTuD0HpLhkPra8oFmlLXu64hUIzZhRraKsuKvdW8dDCeFMBF8RTGVhnAM8gqCD8NTV8Ig=",
              stop: 1,
            },
          ],
          paxpr: [
            {
              bf: 3149,
              fbc: "ACINT",
              ptype: "ADT",
              scur: "QAR",
              sup_fare: "1802_2004",
              tax: [
                { OT: 450 },
                { attmkp: 0 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              tf: 3599,
              psup_fare: "3149_3599",
            },
          ],
          pr: {
            bf: 3149,
            tf: 3599,
            tax: [
              { OT: 450 },
              { attmkp: 0 },
              { tamkp: 0 },
              { stamkp: 0 },
              { attdisc: 0 },
              { tadisc: 0 },
              { stadisc: 0 },
            ],
            ucur: "QAR",
            exchange_rate: 1,
            scur: "QAR",
            sup_fare: "3149_3599",
            sup_cur_fare: "",
            cabb: "",
            chekb: "",
            seats: "",
            meals: "",
            chanb: "",
            ftyp: "Regular - R",
            markupnames: [],
            ttf: 3599,
          },
          sup: "4",
          airlineList: "6E",
          servicefee: [],
        },
        {
          srcorigns: [
            {
              tid: "9Fybf7GEUPFUQiRh_1",
              ref: 1,
              ofcid: "",
              sdur: "26:45",
              re: "",
              sup: "5",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DXB",
                  dd: "2023-05-06T21:00:00",
                  ad: "2023-05-06T23:15:00",
                  rbd: "",
                  fn: "006",
                  trn: "",
                  eq: "73X",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "1", arr: "2" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161423,
                  flightTime: 75,
                  fdur: "1:15",
                },
                {
                  ref: 2,
                  da: "DXB",
                  ar: "HYD",
                  dd: "2023-05-07T20:50:00",
                  ad: "2023-05-08T02:15:00",
                  rbd: "",
                  fn: "435",
                  trn: "",
                  eq: "73B",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "2", arr: "" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161456,
                  flightTime: 235,
                  fdur: "3:55",
                },
              ],
              fareop: [
                {
                  fareKey: [],
                  pr: {
                    bf: 2200,
                    tf: 3310,
                    tax: [
                      { OT: 1110 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    scur: "QAR",
                    ucur: "QAR",
                    exchange_rate: 1,
                    sup_fare: "2200_3310",
                    sup_cur_fare: "",
                    cabb: "Free",
                    chekb: "14kg INCLUDED",
                    meals: "Chargeable",
                    chanb: "Chargeable",
                    ftyp: "Business",
                    extra: "15_Business",
                    chekbcode: "JBAG",
                    chanb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Change of Flight is Free,NOSHOWUPTO 720 hrs of departure Change of Flight is Free,NOSHOWAFTER From 720 hrs before  of departure Change of Flight is Non-Refundable",
                    canb: "Chargeable",
                    cabb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Cancellation of Flight is Free,NOSHOWUPTO 720 hrs of departure Cancellation of Flight is Free,NOSHOWAFTER From 720 hrs before  of departure Cancellation of Flight is Non-Refundable",
                    bagage_info: "40kg Baggage allowance Business",
                    markupnames: [],
                    ttf: 3310,
                  },
                  paxpr: [
                    {
                      bf: 2200,
                      tf: 3310,
                      tax: [
                        { OT: 1110 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "CR9QA2",
                      ptype: "ADT",
                      scur: "QAR",
                      extra:
                        "1_25_C_1_4_0_tx-1764:60:QA:0_2186:10:PZ:0_4246:920:YQ:0_6544:0:JBAG:0_8966:60:G4:0_10386:10:ZR:0_11947:40:F6:0_13230:10:R9:0_",
                      ruleid:
                        "800po/gB9fgXxT1js04AG8sy5WVLI/Wmz7q05FnWmKwjGtyO+YZSYBV7pLwvravI",
                      hsc: "50860ecf4234a9b6b21beb5364347687dd190330e7a02b4bf4b702411f3af043",
                      sup_fare: "2200_3310_2200",
                      cabb: "Free",
                      bagage_info: "40kg Baggage allowance Business",
                      chekbcode: "JBAG",
                      psup_fare: "2200_3310",
                    },
                  ],
                  uniqueId: "Business",
                  routeId: "zlyyfkl6ye",
                  servicefee: [],
                  selected: true,
                },
              ],
              tktair: "FZ",
              tecst: "no",
              cl: "Economy",
              extra: 13915828,
              token:
                "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiYXBpdGF3ZmVlcSIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL2dpdmVubmFtZSI6IkFQSSBUQVdGRUVRIiwiZ3JvdXBBY2Nlc3MiOiIzLDM2LDExMiIsImlzQXB0Q2xudCI6IkZhbHNlIiwibG9jYXRpb24iOiI5OTk5IiwiY2hrTGNuIjoiIiwicmVzVG9rZW4iOiJmMTYxMmE4YzM5Y3ZjNDMwZjJvOXhiYjk2Y2M5NGY2M3ZjNDAxNDhlNGI5NCIsImNsaWVudElQIjoiMTcyLjE5LjI3LjM5IiwicmVzQ2hhbm5lbElEIjoiNSIsInJlc0NoYW5uZWxOYW1lIjoiVFBBUEkiLCJkZWZhdWx0VXNlck5hbWUiOiJhcGl0YXdmZWVxIiwibmJmIjoxNjgzMTk1MTM0LCJleHAiOjE2ODMxOTc1MzQsImlzcyI6ImZseWR1YmFpLmNvbSIsImF1ZCI6IlRBV0ZFRVFUUlZMU19GWl9OUCJ9._I0ZV--svLR1OqP0XLXV17rFF4itsd_2C9QzPwezZsc",
              stop: 1,
            },
          ],
          paxpr: [
            {
              bf: 2590,
              tf: 3980,
              tax: [
                { OT: 1390 },
                { attmkp: 0 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              fbc: "CR9QA2",
              ptype: "ADT",
              scur: "QAR",
              extra:
                "1_25_C_1_4_0_tx-1764:60:QA:0_2186:10:PZ:0_4246:920:YQ:0_6544:0:JBAG:0_8966:60:G4:0_10386:10:ZR:0_11947:40:F6:0_13230:10:R9:0_",
              ruleid:
                "800po/gB9fgXxT1js04AG8sy5WVLI/Wmz7q05FnWmKwjGtyO+YZSYBV7pLwvravI",
              hsc: "50860ecf4234a9b6b21beb5364347687dd190330e7a02b4bf4b702411f3af043",
              sup_fare: "2200_3310_2200",
              cabb: "Free",
              bagage_info: "40kg Baggage allowance Business",
              chekbcode: "JBAG",
              psup_fare: "2590_3980",
            },
          ],
          pr: {
            bf: 2590,
            tf: 3980,
            tax: [
              { OT: 1390 },
              { attmkp: 0 },
              { tamkp: 0 },
              { stamkp: 0 },
              { attdisc: 0 },
              { tadisc: 0 },
              { stadisc: 0 },
            ],
            scur: "QAR",
            ucur: "QAR",
            exchange_rate: 1,
            sup_fare: "2590_3980",
            sup_cur_fare: "",
            cabb: "Free",
            chekb: "14kg INCLUDED",
            meals: "Chargeable",
            chanb: "Chargeable",
            ftyp: "Business",
            extra: "15_Business",
            chekbcode: "JBAG",
            chanb_info:
              "BEFOREDEPARTURE 0 hrs of departure Change of Flight is Free,NOSHOWUPTO 720 hrs of departure Change of Flight is Free,NOSHOWAFTER From 720 hrs before  of departure Change of Flight is Non-Refundable",
            canb: "Chargeable",
            cabb_info:
              "BEFOREDEPARTURE 0 hrs of departure Cancellation of Flight is Free,NOSHOWUPTO 720 hrs of departure Cancellation of Flight is Free,NOSHOWAFTER From 720 hrs before  of departure Cancellation of Flight is Non-Refundable",
            bagage_info: "40kg Baggage allowance Business",
            markupnames: [],
            ttf: 3980,
          },
          sup: "5",
          airlineList: "FZ",
          destorigins: [
            {
              tid: "vBGs5ygsWim8Qrwa_2",
              ref: 2,
              ofcid: "",
              sdur: "8:25",
              re: "",
              sup: "5",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DXB",
                  dd: "2023-05-08T03:00:00",
                  ad: "2023-05-08T05:30:00",
                  rbd: "",
                  fn: "436",
                  trn: "",
                  eq: "73B",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "", arr: "2" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161457,
                  flightTime: 240,
                  fdur: "4:0",
                },
                {
                  ref: 2,
                  da: "DXB",
                  ar: "DOH",
                  dd: "2023-05-08T08:45:00",
                  ad: "2023-05-08T08:55:00",
                  rbd: "",
                  fn: "001",
                  trn: "",
                  eq: "73X",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "2", arr: "1" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161405,
                  flightTime: 70,
                  fdur: "1:10",
                },
              ],
              fareop: [
                {
                  fareKey: [],
                  pr: {
                    bf: 390,
                    tf: 670,
                    tax: [
                      { OT: 280 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    scur: "QAR",
                    ucur: "QAR",
                    exchange_rate: 1,
                    sup_fare: "390_670",
                    sup_cur_fare: "",
                    cabb: "Chargeable",
                    chekb: "7kg INCLUDED",
                    meals: "Chargeable",
                    chanb: "Non Refundable",
                    ftyp: "LITE",
                    extra: "11_LITE",
                    chekbcode: "",
                    chanb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Change of Flight is Non-Refundable,NOSHOW From 0 hrs before  of departure Change of Flight is Non-Refundable",
                    canb: "Non Refundable",
                    cabb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Cancellation of Flight is Non-Refundable,NOSHOW From 0 hrs before  of departure Cancellation of Flight is Non-Refundable",
                    bagage_info: "Chargeable",
                    markupnames: [],
                    ttf: 670,
                  },
                  paxpr: [
                    {
                      bf: 390,
                      tf: 670,
                      tax: [
                        { OT: 280 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "NR6QA2",
                      ptype: "ADT",
                      scur: "QAR",
                      extra:
                        "1_2_N_1_9_0_tx-2186:10:PZ:0_4246:80:YQ:0_10386:10:ZR:0_11947:40:F6:0_12006:60:P2:0_12434:80:IN:0_",
                      ruleid: "EI2tuphriR82jc5fk+WP8appUDvn/LNY15vA57Yy9l4=",
                      hsc: "82ba28233b54c7d0529890c46aa07db771c3a2a79bb0ac5b9caf3549f256d0fe",
                      sup_fare: "390_670_390",
                      cabb: "Chargeable",
                      bagage_info: "Chargeable",
                      chekbcode: "",
                      psup_fare: "390_670",
                    },
                  ],
                  uniqueId: "LITE",
                  routeId: "i8zh29dmjt",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey: [],
                  pr: {
                    bf: 450,
                    tf: 730,
                    tax: [
                      { OT: 280 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    scur: "QAR",
                    ucur: "QAR",
                    exchange_rate: 1,
                    sup_fare: "450_730",
                    sup_cur_fare: "",
                    cabb: "Free",
                    chekb: "7kg INCLUDED",
                    meals: "Standard meal",
                    chanb: "Chargeable",
                    ftyp: "VALUE",
                    extra: "12_VALUE",
                    chekbcode: "BAGL",
                    chanb_info:
                      "UPTO 24 hrs of departure Change of Flight is Free,WITHIN From 0-24 hrs of departure Change of Flight is Non-Refundable,NOSHOW From 0 hrs before  of departure Change of Flight is Non-Refundable",
                    canb: "Chargeable",
                    cabb_info:
                      "UPTO 24 hrs of departure Cancellation of Flight is Free,WITHIN From 0-24 hrs of departure Cancellation of Flight is Non-Refundable,NOSHOW From 0 hrs before  of departure Cancellation of Flight is Non-Refundable",
                    bagage_info: "30kg BAG INCLUDED IN FARE",
                    markupnames: [],
                    ttf: 730,
                  },
                  paxpr: [
                    {
                      bf: 450,
                      tf: 730,
                      tax: [
                        { OT: 280 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "NRL7QA2",
                      ptype: "ADT",
                      scur: "QAR",
                      extra:
                        "1_4_N_1_9_0_tx-2186:10:PZ:0_4246:80:YQ:0_8947:0:BAGL:0_10386:10:ZR:0_11947:40:F6:0_12006:60:P2:0_12434:80:IN:0_",
                      ruleid: "viIu7PonQyOlrTHXKrim8UuQq6Aizd3kV99CWR9yPM4=",
                      hsc: "2f91e7896bd10400e6e7e4edcbbd39f36afa1e950ded9fc1d8de1bac8c7e1a2b",
                      sup_fare: "450_730_450",
                      cabb: "Free",
                      bagage_info: "30kg BAG INCLUDED IN FARE",
                      chekbcode: "BAGL",
                      psup_fare: "450_730",
                    },
                  ],
                  uniqueId: "VALUE",
                  routeId: "6kmlckz8t",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "FZ",
              tecst: "no",
              cl: "Economy",
              extra: 13919293,
              token:
                "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiYXBpdGF3ZmVlcSIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL2dpdmVubmFtZSI6IkFQSSBUQVdGRUVRIiwiZ3JvdXBBY2Nlc3MiOiIzLDM2LDExMiIsImlzQXB0Q2xudCI6IkZhbHNlIiwibG9jYXRpb24iOiI5OTk5IiwiY2hrTGNuIjoiIiwicmVzVG9rZW4iOiJmMTYxMmE4YzM5Y3ZjNDMwZjJvOXhiYjk2Y2M5NGY2M3ZjNDAxNDhlNGI5NCIsImNsaWVudElQIjoiMTcyLjE5LjI3LjM5IiwicmVzQ2hhbm5lbElEIjoiNSIsInJlc0NoYW5uZWxOYW1lIjoiVFBBUEkiLCJkZWZhdWx0VXNlck5hbWUiOiJhcGl0YXdmZWVxIiwibmJmIjoxNjgzMTk1MTM0LCJleHAiOjE2ODMxOTc1MzQsImlzcyI6ImZseWR1YmFpLmNvbSIsImF1ZCI6IlRBV0ZFRVFUUlZMU19GWl9OUCJ9._I0ZV--svLR1OqP0XLXV17rFF4itsd_2C9QzPwezZsc",
              stop: 1,
            },
            {
              tid: "i0XDxS2mbVtqGOu8_2",
              ref: 2,
              ofcid: "",
              sdur: "15:25",
              re: "",
              sup: "5",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DXB",
                  dd: "2023-05-08T03:00:00",
                  ad: "2023-05-08T05:30:00",
                  rbd: "",
                  fn: "436",
                  trn: "",
                  eq: "73B",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "", arr: "2" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161457,
                  flightTime: 240,
                  fdur: "4:0",
                },
                {
                  ref: 2,
                  da: "DXB",
                  ar: "DOH",
                  dd: "2023-05-08T15:45:00",
                  ad: "2023-05-08T15:55:00",
                  rbd: "",
                  fn: "017",
                  trn: "",
                  eq: "73X",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "2", arr: "1" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161400,
                  flightTime: 70,
                  fdur: "1:10",
                },
              ],
              fareop: [
                {
                  fareKey: [],
                  pr: {
                    bf: 390,
                    tf: 670,
                    tax: [
                      { OT: 280 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    scur: "QAR",
                    ucur: "QAR",
                    exchange_rate: 1,
                    sup_fare: "390_670",
                    sup_cur_fare: "",
                    cabb: "Chargeable",
                    chekb: "7kg INCLUDED",
                    meals: "Chargeable",
                    chanb: "Non Refundable",
                    ftyp: "LITE",
                    extra: "11_LITE",
                    chekbcode: "",
                    chanb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Change of Flight is Non-Refundable,NOSHOW From 0 hrs before  of departure Change of Flight is Non-Refundable",
                    canb: "Non Refundable",
                    cabb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Cancellation of Flight is Non-Refundable,NOSHOW From 0 hrs before  of departure Cancellation of Flight is Non-Refundable",
                    bagage_info: "Chargeable",
                    markupnames: [],
                    ttf: 670,
                  },
                  paxpr: [
                    {
                      bf: 390,
                      tf: 670,
                      tax: [
                        { OT: 280 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "NR6QA2",
                      ptype: "ADT",
                      scur: "QAR",
                      extra:
                        "1_34_N_1_9_0_tx-2186:10:PZ:0_4246:80:YQ:0_10386:10:ZR:0_11947:40:F6:0_12006:60:P2:0_12434:80:IN:0_",
                      ruleid: "AifZUhSiNwYM5YmIgNsJycW4oeVijvMb+J1x1oeNxQs=",
                      hsc: "4780b0c7efb4c0432ea8ff5f6a37eb9432aef415f7992a4f7884757f881a8f38",
                      sup_fare: "390_670_390",
                      cabb: "Chargeable",
                      bagage_info: "Chargeable",
                      chekbcode: "",
                      psup_fare: "390_670",
                    },
                  ],
                  uniqueId: "LITE",
                  routeId: "3whh3c5lce",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey: [],
                  pr: {
                    bf: 450,
                    tf: 730,
                    tax: [
                      { OT: 280 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    scur: "QAR",
                    ucur: "QAR",
                    exchange_rate: 1,
                    sup_fare: "450_730",
                    sup_cur_fare: "",
                    cabb: "Free",
                    chekb: "7kg INCLUDED",
                    meals: "Standard meal",
                    chanb: "Chargeable",
                    ftyp: "VALUE",
                    extra: "12_VALUE",
                    chekbcode: "BAGL",
                    chanb_info:
                      "UPTO 24 hrs of departure Change of Flight is Free,WITHIN From 0-24 hrs of departure Change of Flight is Non-Refundable,NOSHOW From 0 hrs before  of departure Change of Flight is Non-Refundable",
                    canb: "Chargeable",
                    cabb_info:
                      "UPTO 24 hrs of departure Cancellation of Flight is Free,WITHIN From 0-24 hrs of departure Cancellation of Flight is Non-Refundable,NOSHOW From 0 hrs before  of departure Cancellation of Flight is Non-Refundable",
                    bagage_info: "30kg BAG INCLUDED IN FARE",
                    markupnames: [],
                    ttf: 730,
                  },
                  paxpr: [
                    {
                      bf: 450,
                      tf: 730,
                      tax: [
                        { OT: 280 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "NRL7QA2",
                      ptype: "ADT",
                      scur: "QAR",
                      extra:
                        "1_36_N_1_9_0_tx-2186:10:PZ:0_4246:80:YQ:0_8947:0:BAGL:0_10386:10:ZR:0_11947:40:F6:0_12006:60:P2:0_12434:80:IN:0_",
                      ruleid: "Lp0JvPpWdLGOR/opvojU4ivi1EMF9FKmpVoWsx/xVFw=",
                      hsc: "96df155f0721e8f1a212f8395bb29ced060395b26b448df8cadcd4499b57d31d",
                      sup_fare: "450_730_450",
                      cabb: "Free",
                      bagage_info: "30kg BAG INCLUDED IN FARE",
                      chekbcode: "BAGL",
                      psup_fare: "450_730",
                    },
                  ],
                  uniqueId: "VALUE",
                  routeId: "35vcvj5v85g",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "FZ",
              tecst: "no",
              cl: "Economy",
              extra: 13919296,
              token:
                "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiYXBpdGF3ZmVlcSIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL2dpdmVubmFtZSI6IkFQSSBUQVdGRUVRIiwiZ3JvdXBBY2Nlc3MiOiIzLDM2LDExMiIsImlzQXB0Q2xudCI6IkZhbHNlIiwibG9jYXRpb24iOiI5OTk5IiwiY2hrTGNuIjoiIiwicmVzVG9rZW4iOiJmMTYxMmE4YzM5Y3ZjNDMwZjJvOXhiYjk2Y2M5NGY2M3ZjNDAxNDhlNGI5NCIsImNsaWVudElQIjoiMTcyLjE5LjI3LjM5IiwicmVzQ2hhbm5lbElEIjoiNSIsInJlc0NoYW5uZWxOYW1lIjoiVFBBUEkiLCJkZWZhdWx0VXNlck5hbWUiOiJhcGl0YXdmZWVxIiwibmJmIjoxNjgzMTk1MTM0LCJleHAiOjE2ODMxOTc1MzQsImlzcyI6ImZseWR1YmFpLmNvbSIsImF1ZCI6IlRBV0ZFRVFUUlZMU19GWl9OUCJ9._I0ZV--svLR1OqP0XLXV17rFF4itsd_2C9QzPwezZsc",
              stop: 1,
            },
            {
              tid: "DydXdhTmffH2kyyQ_2",
              ref: 2,
              ofcid: "",
              sdur: "19:25",
              re: "",
              sup: "5",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DXB",
                  dd: "2023-05-08T03:00:00",
                  ad: "2023-05-08T05:30:00",
                  rbd: "",
                  fn: "436",
                  trn: "",
                  eq: "73B",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "", arr: "2" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161457,
                  flightTime: 240,
                  fdur: "4:0",
                },
                {
                  ref: 2,
                  da: "DXB",
                  ar: "DOH",
                  dd: "2023-05-08T19:45:00",
                  ad: "2023-05-08T19:55:00",
                  rbd: "",
                  fn: "005",
                  trn: "",
                  eq: "73X",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "2", arr: "1" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161381,
                  flightTime: 70,
                  fdur: "1:10",
                },
              ],
              fareop: [
                {
                  fareKey: [],
                  pr: {
                    bf: 390,
                    tf: 670,
                    tax: [
                      { OT: 280 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    scur: "QAR",
                    ucur: "QAR",
                    exchange_rate: 1,
                    sup_fare: "390_670",
                    sup_cur_fare: "",
                    cabb: "Chargeable",
                    chekb: "7kg INCLUDED",
                    meals: "Chargeable",
                    chanb: "Non Refundable",
                    ftyp: "LITE",
                    extra: "11_LITE",
                    chekbcode: "",
                    chanb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Change of Flight is Non-Refundable,NOSHOW From 0 hrs before  of departure Change of Flight is Non-Refundable",
                    canb: "Non Refundable",
                    cabb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Cancellation of Flight is Non-Refundable,NOSHOW From 0 hrs before  of departure Cancellation of Flight is Non-Refundable",
                    bagage_info: "Chargeable",
                    markupnames: [],
                    ttf: 670,
                  },
                  paxpr: [
                    {
                      bf: 390,
                      tf: 670,
                      tax: [
                        { OT: 280 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "NR6QA2",
                      ptype: "ADT",
                      scur: "QAR",
                      extra:
                        "1_66_N_1_9_0_tx-2186:10:PZ:0_4246:80:YQ:0_10386:10:ZR:0_11947:40:F6:0_12006:60:P2:0_12434:80:IN:0_",
                      ruleid: "hVqWXk6X+z8B6xFVinIqqcW4oeVijvMb+J1x1oeNxQs=",
                      hsc: "ebb5fbbe7e891ab322e2572971c9307e0e72e5d877214bf45f37991ce5af0318",
                      sup_fare: "390_670_390",
                      cabb: "Chargeable",
                      bagage_info: "Chargeable",
                      chekbcode: "",
                      psup_fare: "390_670",
                    },
                  ],
                  uniqueId: "LITE",
                  routeId: "sveu378jr7",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey: [],
                  pr: {
                    bf: 450,
                    tf: 730,
                    tax: [
                      { OT: 280 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    scur: "QAR",
                    ucur: "QAR",
                    exchange_rate: 1,
                    sup_fare: "450_730",
                    sup_cur_fare: "",
                    cabb: "Free",
                    chekb: "7kg INCLUDED",
                    meals: "Standard meal",
                    chanb: "Chargeable",
                    ftyp: "VALUE",
                    extra: "12_VALUE",
                    chekbcode: "BAGL",
                    chanb_info:
                      "UPTO 24 hrs of departure Change of Flight is Free,WITHIN From 0-24 hrs of departure Change of Flight is Non-Refundable,NOSHOW From 0 hrs before  of departure Change of Flight is Non-Refundable",
                    canb: "Chargeable",
                    cabb_info:
                      "UPTO 24 hrs of departure Cancellation of Flight is Free,WITHIN From 0-24 hrs of departure Cancellation of Flight is Non-Refundable,NOSHOW From 0 hrs before  of departure Cancellation of Flight is Non-Refundable",
                    bagage_info: "30kg BAG INCLUDED IN FARE",
                    markupnames: [],
                    ttf: 730,
                  },
                  paxpr: [
                    {
                      bf: 450,
                      tf: 730,
                      tax: [
                        { OT: 280 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "NRL7QA2",
                      ptype: "ADT",
                      scur: "QAR",
                      extra:
                        "1_68_N_1_9_0_tx-2186:10:PZ:0_4246:80:YQ:0_8947:0:BAGL:0_10386:10:ZR:0_11947:40:F6:0_12006:60:P2:0_12434:80:IN:0_",
                      ruleid: "Jqo7TLhy+WkcPxW2Fs2wUivi1EMF9FKmpVoWsx/xVFw=",
                      hsc: "be66c15c780e55d71d94206623f1eb66a9ba84fe04d6f6a2286876b8b7ad0bc4",
                      sup_fare: "450_730_450",
                      cabb: "Free",
                      bagage_info: "30kg BAG INCLUDED IN FARE",
                      chekbcode: "BAGL",
                      psup_fare: "450_730",
                    },
                  ],
                  uniqueId: "VALUE",
                  routeId: "1mnkwfe59w",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "FZ",
              tecst: "no",
              cl: "Economy",
              extra: 13919294,
              token:
                "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiYXBpdGF3ZmVlcSIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL2dpdmVubmFtZSI6IkFQSSBUQVdGRUVRIiwiZ3JvdXBBY2Nlc3MiOiIzLDM2LDExMiIsImlzQXB0Q2xudCI6IkZhbHNlIiwibG9jYXRpb24iOiI5OTk5IiwiY2hrTGNuIjoiIiwicmVzVG9rZW4iOiJmMTYxMmE4YzM5Y3ZjNDMwZjJvOXhiYjk2Y2M5NGY2M3ZjNDAxNDhlNGI5NCIsImNsaWVudElQIjoiMTcyLjE5LjI3LjM5IiwicmVzQ2hhbm5lbElEIjoiNSIsInJlc0NoYW5uZWxOYW1lIjoiVFBBUEkiLCJkZWZhdWx0VXNlck5hbWUiOiJhcGl0YXdmZWVxIiwibmJmIjoxNjgzMTk1MTM0LCJleHAiOjE2ODMxOTc1MzQsImlzcyI6ImZseWR1YmFpLmNvbSIsImF1ZCI6IlRBV0ZFRVFUUlZMU19GWl9OUCJ9._I0ZV--svLR1OqP0XLXV17rFF4itsd_2C9QzPwezZsc",
              stop: 1,
            },
            {
              tid: "uFZANg13si8CUwu0_2",
              ref: 2,
              ofcid: "",
              sdur: "8:25",
              re: "",
              sup: "5",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DXB",
                  dd: "2023-05-08T03:00:00",
                  ad: "2023-05-08T05:30:00",
                  rbd: "",
                  fn: "436",
                  trn: "",
                  eq: "73B",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "", arr: "2" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161457,
                  flightTime: 240,
                  fdur: "4:0",
                },
                {
                  ref: 2,
                  da: "DXB",
                  ar: "DOH",
                  dd: "2023-05-08T08:45:00",
                  ad: "2023-05-08T08:55:00",
                  rbd: "",
                  fn: "001",
                  trn: "",
                  eq: "73X",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "2", arr: "1" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161405,
                  flightTime: 70,
                  fdur: "1:10",
                },
              ],
              fareop: [
                {
                  fareKey: [],
                  pr: {
                    bf: 390,
                    tf: 670,
                    tax: [
                      { OT: 280 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    scur: "QAR",
                    ucur: "QAR",
                    exchange_rate: 1,
                    sup_fare: "390_670",
                    sup_cur_fare: "",
                    cabb: "Chargeable",
                    chekb: "7kg INCLUDED",
                    meals: "Chargeable",
                    chanb: "Non Refundable",
                    ftyp: "LITE",
                    extra: "11_LITE",
                    chekbcode: "",
                    chanb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Change of Flight is Non-Refundable,NOSHOW From 0 hrs before  of departure Change of Flight is Non-Refundable",
                    canb: "Non Refundable",
                    cabb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Cancellation of Flight is Non-Refundable,NOSHOW From 0 hrs before  of departure Cancellation of Flight is Non-Refundable",
                    bagage_info: "Chargeable",
                    markupnames: [],
                    ttf: 670,
                  },
                  paxpr: [
                    {
                      bf: 390,
                      tf: 670,
                      tax: [
                        { OT: 280 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "NR6QA2",
                      ptype: "ADT",
                      scur: "QAR",
                      extra:
                        "1_2_N_1_9_0_tx-2186:10:PZ:0_4246:80:YQ:0_10386:10:ZR:0_11947:40:F6:0_12006:60:P2:0_12434:80:IN:0_",
                      ruleid: "EI2tuphriR82jc5fk+WP8appUDvn/LNY15vA57Yy9l4=",
                      hsc: "82ba28233b54c7d0529890c46aa07db771c3a2a79bb0ac5b9caf3549f256d0fe",
                      sup_fare: "390_670_390",
                      cabb: "Chargeable",
                      bagage_info: "Chargeable",
                      chekbcode: "",
                      psup_fare: "390_670",
                    },
                  ],
                  uniqueId: "LITE",
                  routeId: "egh0pqglnf",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey: [],
                  pr: {
                    bf: 450,
                    tf: 730,
                    tax: [
                      { OT: 280 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    scur: "QAR",
                    ucur: "QAR",
                    exchange_rate: 1,
                    sup_fare: "450_730",
                    sup_cur_fare: "",
                    cabb: "Free",
                    chekb: "7kg INCLUDED",
                    meals: "Standard meal",
                    chanb: "Chargeable",
                    ftyp: "VALUE",
                    extra: "12_VALUE",
                    chekbcode: "BAGL",
                    chanb_info:
                      "UPTO 24 hrs of departure Change of Flight is Free,WITHIN From 0-24 hrs of departure Change of Flight is Non-Refundable,NOSHOW From 0 hrs before  of departure Change of Flight is Non-Refundable",
                    canb: "Chargeable",
                    cabb_info:
                      "UPTO 24 hrs of departure Cancellation of Flight is Free,WITHIN From 0-24 hrs of departure Cancellation of Flight is Non-Refundable,NOSHOW From 0 hrs before  of departure Cancellation of Flight is Non-Refundable",
                    bagage_info: "30kg BAG INCLUDED IN FARE",
                    markupnames: [],
                    ttf: 730,
                  },
                  paxpr: [
                    {
                      bf: 450,
                      tf: 730,
                      tax: [
                        { OT: 280 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "NRL7QA2",
                      ptype: "ADT",
                      scur: "QAR",
                      extra:
                        "1_4_N_1_9_0_tx-2186:10:PZ:0_4246:80:YQ:0_8947:0:BAGL:0_10386:10:ZR:0_11947:40:F6:0_12006:60:P2:0_12434:80:IN:0_",
                      ruleid: "viIu7PonQyOlrTHXKrim8UuQq6Aizd3kV99CWR9yPM4=",
                      hsc: "2f91e7896bd10400e6e7e4edcbbd39f36afa1e950ded9fc1d8de1bac8c7e1a2b",
                      sup_fare: "450_730_450",
                      cabb: "Free",
                      bagage_info: "30kg BAG INCLUDED IN FARE",
                      chekbcode: "BAGL",
                      psup_fare: "450_730",
                    },
                  ],
                  uniqueId: "VALUE",
                  routeId: "cfs1deuvw9",
                  servicefee: [],
                  selected: false,
                },
                {
                  fareKey: [],
                  pr: {
                    bf: 1330,
                    tf: 2450,
                    tax: [
                      { OT: 1120 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    scur: "QAR",
                    ucur: "QAR",
                    exchange_rate: 1,
                    sup_fare: "1330_2450",
                    sup_cur_fare: "",
                    cabb: "Free",
                    chekb: "14kg INCLUDED",
                    meals: "Chargeable",
                    chanb: "Chargeable",
                    ftyp: "Business",
                    extra: "15_Business",
                    chekbcode: "JBAG",
                    chanb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Change of Flight is Free,NOSHOWUPTO 720 hrs of departure Change of Flight is Free,NOSHOWAFTER From 720 hrs before  of departure Change of Flight is Non-Refundable",
                    canb: "Chargeable",
                    cabb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Cancellation of Flight is Free,NOSHOWUPTO 720 hrs of departure Cancellation of Flight is Free,NOSHOWAFTER From 720 hrs before  of departure Cancellation of Flight is Non-Refundable",
                    bagage_info: "40kg Baggage allowance Business",
                    markupnames: [],
                    ttf: 2450,
                  },
                  paxpr: [
                    {
                      bf: 1330,
                      tf: 2450,
                      tax: [
                        { OT: 1120 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "ZR9QA2",
                      ptype: "ADT",
                      scur: "QAR",
                      extra:
                        "1_32_Z_1_7_0_tx-2186:10:PZ:0_4246:920:YQ:0_6544:0:JBAG:0_10386:10:ZR:0_11947:40:F6:0_12006:60:P2:0_12434:80:IN:0_",
                      ruleid: "iNsTqup4uTSlAkFK/5kNa1d1seULJqBK+3lX41TTPDo=",
                      hsc: "3a5d3117dc594ed72c496ba148685bfc749cb6200068cb91d84ed286a2e8753b",
                      sup_fare: "1330_2450_1330",
                      cabb: "Free",
                      bagage_info: "40kg Baggage allowance Business",
                      chekbcode: "JBAG",
                      psup_fare: "1330_2450",
                    },
                  ],
                  uniqueId: "Business",
                  routeId: "m0cj4hapfx",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "FZ",
              tecst: "no",
              cl: "Economy",
              extra: 13919293,
              token:
                "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiYXBpdGF3ZmVlcSIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL2dpdmVubmFtZSI6IkFQSSBUQVdGRUVRIiwiZ3JvdXBBY2Nlc3MiOiIzLDM2LDExMiIsImlzQXB0Q2xudCI6IkZhbHNlIiwibG9jYXRpb24iOiI5OTk5IiwiY2hrTGNuIjoiIiwicmVzVG9rZW4iOiJmMTYxMmE4YzM5Y3ZjNDMwZjJvOXhiYjk2Y2M5NGY2M3ZjNDAxNDhlNGI5NCIsImNsaWVudElQIjoiMTcyLjE5LjI3LjM5IiwicmVzQ2hhbm5lbElEIjoiNSIsInJlc0NoYW5uZWxOYW1lIjoiVFBBUEkiLCJkZWZhdWx0VXNlck5hbWUiOiJhcGl0YXdmZWVxIiwibmJmIjoxNjgzMTk1MTM0LCJleHAiOjE2ODMxOTc1MzQsImlzcyI6ImZseWR1YmFpLmNvbSIsImF1ZCI6IlRBV0ZFRVFUUlZMU19GWl9OUCJ9._I0ZV--svLR1OqP0XLXV17rFF4itsd_2C9QzPwezZsc",
              stop: 1,
            },
            {
              tid: "KstemxMwCUD22ihb_2",
              ref: 2,
              ofcid: "",
              sdur: "15:25",
              re: "",
              sup: "5",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DXB",
                  dd: "2023-05-08T03:00:00",
                  ad: "2023-05-08T05:30:00",
                  rbd: "",
                  fn: "436",
                  trn: "",
                  eq: "73B",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "", arr: "2" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161457,
                  flightTime: 240,
                  fdur: "4:0",
                },
                {
                  ref: 2,
                  da: "DXB",
                  ar: "DOH",
                  dd: "2023-05-08T15:45:00",
                  ad: "2023-05-08T15:55:00",
                  rbd: "",
                  fn: "017",
                  trn: "",
                  eq: "73X",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "2", arr: "1" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161400,
                  flightTime: 70,
                  fdur: "1:10",
                },
              ],
              fareop: [
                {
                  fareKey: [],
                  pr: {
                    bf: 390,
                    tf: 670,
                    tax: [
                      { OT: 280 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    scur: "QAR",
                    ucur: "QAR",
                    exchange_rate: 1,
                    sup_fare: "390_670",
                    sup_cur_fare: "",
                    cabb: "Chargeable",
                    chekb: "7kg INCLUDED",
                    meals: "Chargeable",
                    chanb: "Non Refundable",
                    ftyp: "LITE",
                    extra: "11_LITE",
                    chekbcode: "",
                    chanb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Change of Flight is Non-Refundable,NOSHOW From 0 hrs before  of departure Change of Flight is Non-Refundable",
                    canb: "Non Refundable",
                    cabb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Cancellation of Flight is Non-Refundable,NOSHOW From 0 hrs before  of departure Cancellation of Flight is Non-Refundable",
                    bagage_info: "Chargeable",
                    markupnames: [],
                    ttf: 670,
                  },
                  paxpr: [
                    {
                      bf: 390,
                      tf: 670,
                      tax: [
                        { OT: 280 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "NR6QA2",
                      ptype: "ADT",
                      scur: "QAR",
                      extra:
                        "1_34_N_1_9_0_tx-2186:10:PZ:0_4246:80:YQ:0_10386:10:ZR:0_11947:40:F6:0_12006:60:P2:0_12434:80:IN:0_",
                      ruleid: "AifZUhSiNwYM5YmIgNsJycW4oeVijvMb+J1x1oeNxQs=",
                      hsc: "4780b0c7efb4c0432ea8ff5f6a37eb9432aef415f7992a4f7884757f881a8f38",
                      sup_fare: "390_670_390",
                      cabb: "Chargeable",
                      bagage_info: "Chargeable",
                      chekbcode: "",
                      psup_fare: "390_670",
                    },
                  ],
                  uniqueId: "LITE",
                  routeId: "rxcb4zrzxc",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey: [],
                  pr: {
                    bf: 450,
                    tf: 730,
                    tax: [
                      { OT: 280 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    scur: "QAR",
                    ucur: "QAR",
                    exchange_rate: 1,
                    sup_fare: "450_730",
                    sup_cur_fare: "",
                    cabb: "Free",
                    chekb: "7kg INCLUDED",
                    meals: "Standard meal",
                    chanb: "Chargeable",
                    ftyp: "VALUE",
                    extra: "12_VALUE",
                    chekbcode: "BAGL",
                    chanb_info:
                      "UPTO 24 hrs of departure Change of Flight is Free,WITHIN From 0-24 hrs of departure Change of Flight is Non-Refundable,NOSHOW From 0 hrs before  of departure Change of Flight is Non-Refundable",
                    canb: "Chargeable",
                    cabb_info:
                      "UPTO 24 hrs of departure Cancellation of Flight is Free,WITHIN From 0-24 hrs of departure Cancellation of Flight is Non-Refundable,NOSHOW From 0 hrs before  of departure Cancellation of Flight is Non-Refundable",
                    bagage_info: "30kg BAG INCLUDED IN FARE",
                    markupnames: [],
                    ttf: 730,
                  },
                  paxpr: [
                    {
                      bf: 450,
                      tf: 730,
                      tax: [
                        { OT: 280 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "NRL7QA2",
                      ptype: "ADT",
                      scur: "QAR",
                      extra:
                        "1_36_N_1_9_0_tx-2186:10:PZ:0_4246:80:YQ:0_8947:0:BAGL:0_10386:10:ZR:0_11947:40:F6:0_12006:60:P2:0_12434:80:IN:0_",
                      ruleid: "Lp0JvPpWdLGOR/opvojU4ivi1EMF9FKmpVoWsx/xVFw=",
                      hsc: "96df155f0721e8f1a212f8395bb29ced060395b26b448df8cadcd4499b57d31d",
                      sup_fare: "450_730_450",
                      cabb: "Free",
                      bagage_info: "30kg BAG INCLUDED IN FARE",
                      chekbcode: "BAGL",
                      psup_fare: "450_730",
                    },
                  ],
                  uniqueId: "VALUE",
                  routeId: "pzwttqqflv",
                  servicefee: [],
                  selected: false,
                },
                {
                  fareKey: [],
                  pr: {
                    bf: 1330,
                    tf: 2450,
                    tax: [
                      { OT: 1120 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    scur: "QAR",
                    ucur: "QAR",
                    exchange_rate: 1,
                    sup_fare: "1330_2450",
                    sup_cur_fare: "",
                    cabb: "Free",
                    chekb: "14kg INCLUDED",
                    meals: "Chargeable",
                    chanb: "Chargeable",
                    ftyp: "Business",
                    extra: "15_Business",
                    chekbcode: "JBAG",
                    chanb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Change of Flight is Free,NOSHOWUPTO 720 hrs of departure Change of Flight is Free,NOSHOWAFTER From 720 hrs before  of departure Change of Flight is Non-Refundable",
                    canb: "Chargeable",
                    cabb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Cancellation of Flight is Free,NOSHOWUPTO 720 hrs of departure Cancellation of Flight is Free,NOSHOWAFTER From 720 hrs before  of departure Cancellation of Flight is Non-Refundable",
                    bagage_info: "40kg Baggage allowance Business",
                    markupnames: [],
                    ttf: 2450,
                  },
                  paxpr: [
                    {
                      bf: 1330,
                      tf: 2450,
                      tax: [
                        { OT: 1120 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "ZR9QA2",
                      ptype: "ADT",
                      scur: "QAR",
                      extra:
                        "1_64_Z_1_9_0_tx-2186:10:PZ:0_4246:920:YQ:0_6544:0:JBAG:0_10386:10:ZR:0_11947:40:F6:0_12006:60:P2:0_12434:80:IN:0_",
                      ruleid: "HIjTH2ePBfNwXga/nyA8a1d1seULJqBK+3lX41TTPDo=",
                      hsc: "f65b296a945aa19223449bcce82df4fd98bcd5c7b621b4d200d242a04445e925",
                      sup_fare: "1330_2450_1330",
                      cabb: "Free",
                      bagage_info: "40kg Baggage allowance Business",
                      chekbcode: "JBAG",
                      psup_fare: "1330_2450",
                    },
                  ],
                  uniqueId: "Business",
                  routeId: "m3f0v0rzih",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "FZ",
              tecst: "no",
              cl: "Economy",
              extra: 13919296,
              token:
                "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiYXBpdGF3ZmVlcSIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL2dpdmVubmFtZSI6IkFQSSBUQVdGRUVRIiwiZ3JvdXBBY2Nlc3MiOiIzLDM2LDExMiIsImlzQXB0Q2xudCI6IkZhbHNlIiwibG9jYXRpb24iOiI5OTk5IiwiY2hrTGNuIjoiIiwicmVzVG9rZW4iOiJmMTYxMmE4YzM5Y3ZjNDMwZjJvOXhiYjk2Y2M5NGY2M3ZjNDAxNDhlNGI5NCIsImNsaWVudElQIjoiMTcyLjE5LjI3LjM5IiwicmVzQ2hhbm5lbElEIjoiNSIsInJlc0NoYW5uZWxOYW1lIjoiVFBBUEkiLCJkZWZhdWx0VXNlck5hbWUiOiJhcGl0YXdmZWVxIiwibmJmIjoxNjgzMTk1MTM0LCJleHAiOjE2ODMxOTc1MzQsImlzcyI6ImZseWR1YmFpLmNvbSIsImF1ZCI6IlRBV0ZFRVFUUlZMU19GWl9OUCJ9._I0ZV--svLR1OqP0XLXV17rFF4itsd_2C9QzPwezZsc",
              stop: 1,
            },
            {
              tid: "sy4oJ8Jfv8xBz5b5_2",
              ref: 2,
              ofcid: "",
              sdur: "19:25",
              re: "",
              sup: "5",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DXB",
                  dd: "2023-05-08T03:00:00",
                  ad: "2023-05-08T05:30:00",
                  rbd: "",
                  fn: "436",
                  trn: "",
                  eq: "73B",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "", arr: "2" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161457,
                  flightTime: 240,
                  fdur: "4:0",
                },
                {
                  ref: 2,
                  da: "DXB",
                  ar: "DOH",
                  dd: "2023-05-08T19:45:00",
                  ad: "2023-05-08T19:55:00",
                  rbd: "",
                  fn: "005",
                  trn: "",
                  eq: "73X",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "2", arr: "1" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161381,
                  flightTime: 70,
                  fdur: "1:10",
                },
              ],
              fareop: [
                {
                  fareKey: [],
                  pr: {
                    bf: 390,
                    tf: 670,
                    tax: [
                      { OT: 280 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    scur: "QAR",
                    ucur: "QAR",
                    exchange_rate: 1,
                    sup_fare: "390_670",
                    sup_cur_fare: "",
                    cabb: "Chargeable",
                    chekb: "7kg INCLUDED",
                    meals: "Chargeable",
                    chanb: "Non Refundable",
                    ftyp: "LITE",
                    extra: "11_LITE",
                    chekbcode: "",
                    chanb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Change of Flight is Non-Refundable,NOSHOW From 0 hrs before  of departure Change of Flight is Non-Refundable",
                    canb: "Non Refundable",
                    cabb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Cancellation of Flight is Non-Refundable,NOSHOW From 0 hrs before  of departure Cancellation of Flight is Non-Refundable",
                    bagage_info: "Chargeable",
                    markupnames: [],
                    ttf: 670,
                  },
                  paxpr: [
                    {
                      bf: 390,
                      tf: 670,
                      tax: [
                        { OT: 280 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "NR6QA2",
                      ptype: "ADT",
                      scur: "QAR",
                      extra:
                        "1_66_N_1_9_0_tx-2186:10:PZ:0_4246:80:YQ:0_10386:10:ZR:0_11947:40:F6:0_12006:60:P2:0_12434:80:IN:0_",
                      ruleid: "hVqWXk6X+z8B6xFVinIqqcW4oeVijvMb+J1x1oeNxQs=",
                      hsc: "ebb5fbbe7e891ab322e2572971c9307e0e72e5d877214bf45f37991ce5af0318",
                      sup_fare: "390_670_390",
                      cabb: "Chargeable",
                      bagage_info: "Chargeable",
                      chekbcode: "",
                      psup_fare: "390_670",
                    },
                  ],
                  uniqueId: "LITE",
                  routeId: "ja93xydywz",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey: [],
                  pr: {
                    bf: 450,
                    tf: 730,
                    tax: [
                      { OT: 280 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    scur: "QAR",
                    ucur: "QAR",
                    exchange_rate: 1,
                    sup_fare: "450_730",
                    sup_cur_fare: "",
                    cabb: "Free",
                    chekb: "7kg INCLUDED",
                    meals: "Standard meal",
                    chanb: "Chargeable",
                    ftyp: "VALUE",
                    extra: "12_VALUE",
                    chekbcode: "BAGL",
                    chanb_info:
                      "UPTO 24 hrs of departure Change of Flight is Free,WITHIN From 0-24 hrs of departure Change of Flight is Non-Refundable,NOSHOW From 0 hrs before  of departure Change of Flight is Non-Refundable",
                    canb: "Chargeable",
                    cabb_info:
                      "UPTO 24 hrs of departure Cancellation of Flight is Free,WITHIN From 0-24 hrs of departure Cancellation of Flight is Non-Refundable,NOSHOW From 0 hrs before  of departure Cancellation of Flight is Non-Refundable",
                    bagage_info: "30kg BAG INCLUDED IN FARE",
                    markupnames: [],
                    ttf: 730,
                  },
                  paxpr: [
                    {
                      bf: 450,
                      tf: 730,
                      tax: [
                        { OT: 280 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "NRL7QA2",
                      ptype: "ADT",
                      scur: "QAR",
                      extra:
                        "1_68_N_1_9_0_tx-2186:10:PZ:0_4246:80:YQ:0_8947:0:BAGL:0_10386:10:ZR:0_11947:40:F6:0_12006:60:P2:0_12434:80:IN:0_",
                      ruleid: "Jqo7TLhy+WkcPxW2Fs2wUivi1EMF9FKmpVoWsx/xVFw=",
                      hsc: "be66c15c780e55d71d94206623f1eb66a9ba84fe04d6f6a2286876b8b7ad0bc4",
                      sup_fare: "450_730_450",
                      cabb: "Free",
                      bagage_info: "30kg BAG INCLUDED IN FARE",
                      chekbcode: "BAGL",
                      psup_fare: "450_730",
                    },
                  ],
                  uniqueId: "VALUE",
                  routeId: "ag3goopgbf",
                  servicefee: [],
                  selected: false,
                },
                {
                  fareKey: [],
                  pr: {
                    bf: 1330,
                    tf: 2450,
                    tax: [
                      { OT: 1120 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    scur: "QAR",
                    ucur: "QAR",
                    exchange_rate: 1,
                    sup_fare: "1330_2450",
                    sup_cur_fare: "",
                    cabb: "Free",
                    chekb: "14kg INCLUDED",
                    meals: "Chargeable",
                    chanb: "Chargeable",
                    ftyp: "Business",
                    extra: "15_Business",
                    chekbcode: "JBAG",
                    chanb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Change of Flight is Free,NOSHOWUPTO 720 hrs of departure Change of Flight is Free,NOSHOWAFTER From 720 hrs before  of departure Change of Flight is Non-Refundable",
                    canb: "Chargeable",
                    cabb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Cancellation of Flight is Free,NOSHOWUPTO 720 hrs of departure Cancellation of Flight is Free,NOSHOWAFTER From 720 hrs before  of departure Cancellation of Flight is Non-Refundable",
                    bagage_info: "40kg Baggage allowance Business",
                    markupnames: [],
                    ttf: 2450,
                  },
                  paxpr: [
                    {
                      bf: 1330,
                      tf: 2450,
                      tax: [
                        { OT: 1120 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "ZR9QA2",
                      ptype: "ADT",
                      scur: "QAR",
                      extra:
                        "1_96_Z_1_9_0_tx-2186:10:PZ:0_4246:920:YQ:0_6544:0:JBAG:0_10386:10:ZR:0_11947:40:F6:0_12006:60:P2:0_12434:80:IN:0_",
                      ruleid: "0JVIG4duLjSbZP2hYMFXH1d1seULJqBK+3lX41TTPDo=",
                      hsc: "cec0e274282db237e376b0398b09c924a42678df93c4540491b34655e6089f6f",
                      sup_fare: "1330_2450_1330",
                      cabb: "Free",
                      bagage_info: "40kg Baggage allowance Business",
                      chekbcode: "JBAG",
                      psup_fare: "1330_2450",
                    },
                  ],
                  uniqueId: "Business",
                  routeId: "zkvkqr7l1u",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "FZ",
              tecst: "no",
              cl: "Economy",
              extra: 13919294,
              token:
                "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiYXBpdGF3ZmVlcSIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL2dpdmVubmFtZSI6IkFQSSBUQVdGRUVRIiwiZ3JvdXBBY2Nlc3MiOiIzLDM2LDExMiIsImlzQXB0Q2xudCI6IkZhbHNlIiwibG9jYXRpb24iOiI5OTk5IiwiY2hrTGNuIjoiIiwicmVzVG9rZW4iOiJmMTYxMmE4YzM5Y3ZjNDMwZjJvOXhiYjk2Y2M5NGY2M3ZjNDAxNDhlNGI5NCIsImNsaWVudElQIjoiMTcyLjE5LjI3LjM5IiwicmVzQ2hhbm5lbElEIjoiNSIsInJlc0NoYW5uZWxOYW1lIjoiVFBBUEkiLCJkZWZhdWx0VXNlck5hbWUiOiJhcGl0YXdmZWVxIiwibmJmIjoxNjgzMTk1MTM0LCJleHAiOjE2ODMxOTc1MzQsImlzcyI6ImZseWR1YmFpLmNvbSIsImF1ZCI6IlRBV0ZFRVFUUlZMU19GWl9OUCJ9._I0ZV--svLR1OqP0XLXV17rFF4itsd_2C9QzPwezZsc",
              stop: 1,
            },
          ],
          servicefee: [],
        },
        {
          srcorigns: [
            {
              tid: "IyRHWgesgouE4HHr_1",
              ref: 1,
              ofcid: "",
              sdur: "26:45",
              re: "",
              sup: "5",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DXB",
                  dd: "2023-05-06T21:00:00",
                  ad: "2023-05-06T23:15:00",
                  rbd: "",
                  fn: "006",
                  trn: "",
                  eq: "73X",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "1", arr: "2" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161423,
                  flightTime: 75,
                  fdur: "1:15",
                },
                {
                  ref: 2,
                  da: "DXB",
                  ar: "HYD",
                  dd: "2023-05-07T20:50:00",
                  ad: "2023-05-08T02:15:00",
                  rbd: "",
                  fn: "435",
                  trn: "",
                  eq: "73B",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "2", arr: "" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161456,
                  flightTime: 235,
                  fdur: "3:55",
                },
              ],
              fareop: [
                {
                  fareKey: [],
                  pr: {
                    bf: 2195,
                    tf: 3305,
                    tax: [
                      { OT: 1110 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    scur: "QAR",
                    ucur: "QAR",
                    exchange_rate: 1,
                    sup_fare: "2195_3305",
                    sup_cur_fare: "",
                    cabb: "Free",
                    chekb: "14kg INCLUDED",
                    meals: "Chargeable",
                    chanb: "Chargeable",
                    ftyp: "Business",
                    extra: "15_Business",
                    chekbcode: "JBAG",
                    chanb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Change of Flight is Free,NOSHOWUPTO 720 hrs of departure Change of Flight is Free,NOSHOWAFTER From 720 hrs before  of departure Change of Flight is Non-Refundable",
                    canb: "Chargeable",
                    cabb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Cancellation of Flight is Free,NOSHOWUPTO 720 hrs of departure Cancellation of Flight is Free,NOSHOWAFTER From 720 hrs before  of departure Cancellation of Flight is Non-Refundable",
                    bagage_info: "40kg Baggage allowance Business",
                    markupnames: [],
                    ttf: 3305,
                  },
                  paxpr: [
                    {
                      bf: 2195,
                      tf: 3305,
                      tax: [
                        { OT: 1110 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "CR9QA2",
                      ptype: "ADT",
                      scur: "QAR",
                      extra:
                        "1_29_C_1_4_0_tx-1764:60:QA:0_2186:10:PZ:0_4246:920:YQ:0_6544:0:JBAG:0_8966:60:G4:0_10386:10:ZR:0_11947:40:F6:0_13230:10:R9:0_",
                      ruleid: "MX7r/e3TFLQRkRYxh08kFA7miyWeoYiaDEwGhTUG78c=",
                      hsc: "bd06294b866516d4a3275f764bcac5b36c59f6bbe2afc8f21a94efc5f0349267",
                      sup_fare: "2195_3305_2195",
                      cabb: "Free",
                      bagage_info: "40kg Baggage allowance Business",
                      chekbcode: "JBAG",
                      psup_fare: "2195_3305",
                    },
                  ],
                  uniqueId: "Business",
                  routeId: "y2pq8szv4u",
                  servicefee: [],
                  selected: true,
                },
              ],
              tktair: "FZ",
              tecst: "no",
              cl: "Economy",
              extra: 13915828,
              token:
                "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiYXBpdGF3ZmVlcSIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL2dpdmVubmFtZSI6IkFQSSBUQVdGRUVRIiwiZ3JvdXBBY2Nlc3MiOiIzLDM2LDExMiIsImlzQXB0Q2xudCI6IkZhbHNlIiwibG9jYXRpb24iOiI5OTk5IiwiY2hrTGNuIjoiIiwicmVzVG9rZW4iOiJmMTYxMmE4YzM5Y3ZjNDMwZjJvOXhiYjk2Y2M5NGY2M3ZjNDAxNDhlNGI5NCIsImNsaWVudElQIjoiMTcyLjE5LjI3LjM5IiwicmVzQ2hhbm5lbElEIjoiNSIsInJlc0NoYW5uZWxOYW1lIjoiVFBBUEkiLCJkZWZhdWx0VXNlck5hbWUiOiJhcGl0YXdmZWVxIiwibmJmIjoxNjgzMTk1MTM0LCJleHAiOjE2ODMxOTc1MzQsImlzcyI6ImZseWR1YmFpLmNvbSIsImF1ZCI6IlRBV0ZFRVFUUlZMU19GWl9OUCJ9._I0ZV--svLR1OqP0XLXV17rFF4itsd_2C9QzPwezZsc",
              stop: 1,
            },
          ],
          paxpr: [
            {
              bf: 2830,
              tf: 4220,
              tax: [
                { OT: 1390 },
                { attmkp: 0 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              fbc: "CR9QA2",
              ptype: "ADT",
              scur: "QAR",
              extra:
                "1_29_C_1_4_0_tx-1764:60:QA:0_2186:10:PZ:0_4246:920:YQ:0_6544:0:JBAG:0_8966:60:G4:0_10386:10:ZR:0_11947:40:F6:0_13230:10:R9:0_",
              ruleid: "MX7r/e3TFLQRkRYxh08kFA7miyWeoYiaDEwGhTUG78c=",
              hsc: "bd06294b866516d4a3275f764bcac5b36c59f6bbe2afc8f21a94efc5f0349267",
              sup_fare: "2195_3305_2195",
              cabb: "Free",
              bagage_info: "40kg Baggage allowance Business",
              chekbcode: "JBAG",
              psup_fare: "2830_4220",
            },
          ],
          pr: {
            bf: 2830,
            tf: 4220,
            tax: [
              { OT: 1390 },
              { attmkp: 0 },
              { tamkp: 0 },
              { stamkp: 0 },
              { attdisc: 0 },
              { tadisc: 0 },
              { stadisc: 0 },
            ],
            scur: "QAR",
            ucur: "QAR",
            exchange_rate: 1,
            sup_fare: "2830_4220",
            sup_cur_fare: "",
            cabb: "Free",
            chekb: "14kg INCLUDED",
            meals: "Chargeable",
            chanb: "Chargeable",
            ftyp: "Business",
            extra: "15_Business",
            chekbcode: "JBAG",
            chanb_info:
              "BEFOREDEPARTURE 0 hrs of departure Change of Flight is Free,NOSHOWUPTO 720 hrs of departure Change of Flight is Free,NOSHOWAFTER From 720 hrs before  of departure Change of Flight is Non-Refundable",
            canb: "Chargeable",
            cabb_info:
              "BEFOREDEPARTURE 0 hrs of departure Cancellation of Flight is Free,NOSHOWUPTO 720 hrs of departure Cancellation of Flight is Free,NOSHOWAFTER From 720 hrs before  of departure Cancellation of Flight is Non-Refundable",
            bagage_info: "40kg Baggage allowance Business",
            markupnames: [],
            ttf: 4220,
          },
          sup: "5",
          airlineList: "FZ",
          destorigins: [
            {
              tid: "7FXC4GyAXCR7RwPH_2",
              ref: 2,
              ofcid: "",
              sdur: "8:25",
              re: "",
              sup: "5",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DXB",
                  dd: "2023-05-08T03:00:00",
                  ad: "2023-05-08T05:30:00",
                  rbd: "",
                  fn: "436",
                  trn: "",
                  eq: "73B",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "", arr: "2" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161457,
                  flightTime: 240,
                  fdur: "4:0",
                },
                {
                  ref: 2,
                  da: "DXB",
                  ar: "DOH",
                  dd: "2023-05-08T08:45:00",
                  ad: "2023-05-08T08:55:00",
                  rbd: "",
                  fn: "001",
                  trn: "",
                  eq: "73X",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "2", arr: "1" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161405,
                  flightTime: 70,
                  fdur: "1:10",
                },
              ],
              fareop: [
                {
                  fareKey: [],
                  pr: {
                    bf: 635,
                    tf: 915,
                    tax: [
                      { OT: 280 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    scur: "QAR",
                    ucur: "QAR",
                    exchange_rate: 1,
                    sup_fare: "635_915",
                    sup_cur_fare: "",
                    cabb: "Free",
                    chekb: "7kg INCLUDED",
                    meals: "Standard meal",
                    chanb: "Chargeable",
                    ftyp: "FLEX",
                    extra: "13_FLEX",
                    chekbcode: "BAGL",
                    chanb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Change of Flight is Free,NOSHOWUPTO 720 hrs of departure Change of Flight is Free,NOSHOWAFTER From 720 hrs before  of departure Change of Flight is Non-Refundable",
                    canb: "Chargeable",
                    cabb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Cancellation of Flight is Free,NOSHOWUPTO 720 hrs of departure Cancellation of Flight is Free,NOSHOWAFTER From 720 hrs before  of departure Cancellation of Flight is Non-Refundable",
                    bagage_info: "30kg BAG INCLUDED IN FARE",
                    markupnames: [],
                    ttf: 915,
                  },
                  paxpr: [
                    {
                      bf: 635,
                      tf: 915,
                      tax: [
                        { OT: 280 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "NRL8QA2",
                      ptype: "ADT",
                      scur: "QAR",
                      extra:
                        "1_12_N_1_9_0_tx-2186:10:PZ:0_4246:80:YQ:0_8947:0:BAGL:0_10386:10:ZR:0_11947:40:F6:0_12006:60:P2:0_12434:80:IN:0_",
                      ruleid: "9N+dTkCMVJ3ZO9vm7FIXlirh0x6swSbrCL0pg69gHUU=",
                      hsc: "01348e97190563789688633dae362bfa721129d7c099705fe357f93753f918bd",
                      sup_fare: "635_915_635",
                      cabb: "Free",
                      bagage_info: "30kg BAG INCLUDED IN FARE",
                      chekbcode: "BAGL",
                      psup_fare: "635_915",
                    },
                  ],
                  uniqueId: "FLEX",
                  routeId: "vz4krrih4b",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey: [],
                  pr: {
                    bf: 1595,
                    tf: 2715,
                    tax: [
                      { OT: 1120 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    scur: "QAR",
                    ucur: "QAR",
                    exchange_rate: 1,
                    sup_fare: "1595_2715",
                    sup_cur_fare: "",
                    cabb: "Free",
                    chekb: "14kg INCLUDED",
                    meals: "Chargeable",
                    chanb: "Chargeable",
                    ftyp: "Business",
                    extra: "15_Business",
                    chekbcode: "JBAG",
                    chanb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Change of Flight is Free,NOSHOWUPTO 720 hrs of departure Change of Flight is Free,NOSHOWAFTER From 720 hrs before  of departure Change of Flight is Non-Refundable",
                    canb: "Chargeable",
                    cabb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Cancellation of Flight is Free,NOSHOWUPTO 720 hrs of departure Cancellation of Flight is Free,NOSHOWAFTER From 720 hrs before  of departure Cancellation of Flight is Non-Refundable",
                    bagage_info: "40kg Baggage allowance Business",
                    markupnames: [],
                    ttf: 2715,
                  },
                  paxpr: [
                    {
                      bf: 1595,
                      tf: 2715,
                      tax: [
                        { OT: 1120 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "ZR9QA2",
                      ptype: "ADT",
                      scur: "QAR",
                      extra:
                        "1_20_Z_1_7_0_tx-2186:10:PZ:0_4246:920:YQ:0_6544:0:JBAG:0_10386:10:ZR:0_11947:40:F6:0_12006:60:P2:0_12434:80:IN:0_",
                      ruleid: "JrsaQ40CW4Yu1s/6YuRSFAt4fLJrC0i89TPad4f+u4k=",
                      hsc: "3a9557bf80168ca0af3fe7fa404e7d4a9bd9401b6301088c482c2de02a71d5e5",
                      sup_fare: "1595_2715_1595",
                      cabb: "Free",
                      bagage_info: "40kg Baggage allowance Business",
                      chekbcode: "JBAG",
                      psup_fare: "1595_2715",
                    },
                  ],
                  uniqueId: "Business",
                  routeId: "mc575ignme",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "FZ",
              tecst: "no",
              cl: "Economy",
              extra: 13919293,
              token:
                "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiYXBpdGF3ZmVlcSIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL2dpdmVubmFtZSI6IkFQSSBUQVdGRUVRIiwiZ3JvdXBBY2Nlc3MiOiIzLDM2LDExMiIsImlzQXB0Q2xudCI6IkZhbHNlIiwibG9jYXRpb24iOiI5OTk5IiwiY2hrTGNuIjoiIiwicmVzVG9rZW4iOiJmMTYxMmE4YzM5Y3ZjNDMwZjJvOXhiYjk2Y2M5NGY2M3ZjNDAxNDhlNGI5NCIsImNsaWVudElQIjoiMTcyLjE5LjI3LjM5IiwicmVzQ2hhbm5lbElEIjoiNSIsInJlc0NoYW5uZWxOYW1lIjoiVFBBUEkiLCJkZWZhdWx0VXNlck5hbWUiOiJhcGl0YXdmZWVxIiwibmJmIjoxNjgzMTk1MTM0LCJleHAiOjE2ODMxOTc1MzQsImlzcyI6ImZseWR1YmFpLmNvbSIsImF1ZCI6IlRBV0ZFRVFUUlZMU19GWl9OUCJ9._I0ZV--svLR1OqP0XLXV17rFF4itsd_2C9QzPwezZsc",
              stop: 1,
            },
            {
              tid: "asN6Pp3lqFbGoFTl_2",
              ref: 2,
              ofcid: "",
              sdur: "15:25",
              re: "",
              sup: "5",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DXB",
                  dd: "2023-05-08T03:00:00",
                  ad: "2023-05-08T05:30:00",
                  rbd: "",
                  fn: "436",
                  trn: "",
                  eq: "73B",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "", arr: "2" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161457,
                  flightTime: 240,
                  fdur: "4:0",
                },
                {
                  ref: 2,
                  da: "DXB",
                  ar: "DOH",
                  dd: "2023-05-08T15:45:00",
                  ad: "2023-05-08T15:55:00",
                  rbd: "",
                  fn: "017",
                  trn: "",
                  eq: "73X",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "2", arr: "1" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161400,
                  flightTime: 70,
                  fdur: "1:10",
                },
              ],
              fareop: [
                {
                  fareKey: [],
                  pr: {
                    bf: 635,
                    tf: 915,
                    tax: [
                      { OT: 280 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    scur: "QAR",
                    ucur: "QAR",
                    exchange_rate: 1,
                    sup_fare: "635_915",
                    sup_cur_fare: "",
                    cabb: "Free",
                    chekb: "7kg INCLUDED",
                    meals: "Standard meal",
                    chanb: "Chargeable",
                    ftyp: "FLEX",
                    extra: "13_FLEX",
                    chekbcode: "BAGL",
                    chanb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Change of Flight is Free,NOSHOWUPTO 720 hrs of departure Change of Flight is Free,NOSHOWAFTER From 720 hrs before  of departure Change of Flight is Non-Refundable",
                    canb: "Chargeable",
                    cabb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Cancellation of Flight is Free,NOSHOWUPTO 720 hrs of departure Cancellation of Flight is Free,NOSHOWAFTER From 720 hrs before  of departure Cancellation of Flight is Non-Refundable",
                    bagage_info: "30kg BAG INCLUDED IN FARE",
                    markupnames: [],
                    ttf: 915,
                  },
                  paxpr: [
                    {
                      bf: 635,
                      tf: 915,
                      tax: [
                        { OT: 280 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "NRL8QA2",
                      ptype: "ADT",
                      scur: "QAR",
                      extra:
                        "1_44_N_1_9_0_tx-2186:10:PZ:0_4246:80:YQ:0_8947:0:BAGL:0_10386:10:ZR:0_11947:40:F6:0_12006:60:P2:0_12434:80:IN:0_",
                      ruleid: "3dhX2jDNbrZeg1CXOLzo5irh0x6swSbrCL0pg69gHUU=",
                      hsc: "f11ddf518116ba055f7420049e846f520069690007370935e50f34d5b9b88917",
                      sup_fare: "635_915_635",
                      cabb: "Free",
                      bagage_info: "30kg BAG INCLUDED IN FARE",
                      chekbcode: "BAGL",
                      psup_fare: "635_915",
                    },
                  ],
                  uniqueId: "FLEX",
                  routeId: "6eo0u2wiou",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey: [],
                  pr: {
                    bf: 1595,
                    tf: 2715,
                    tax: [
                      { OT: 1120 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    scur: "QAR",
                    ucur: "QAR",
                    exchange_rate: 1,
                    sup_fare: "1595_2715",
                    sup_cur_fare: "",
                    cabb: "Free",
                    chekb: "14kg INCLUDED",
                    meals: "Chargeable",
                    chanb: "Chargeable",
                    ftyp: "Business",
                    extra: "15_Business",
                    chekbcode: "JBAG",
                    chanb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Change of Flight is Free,NOSHOWUPTO 720 hrs of departure Change of Flight is Free,NOSHOWAFTER From 720 hrs before  of departure Change of Flight is Non-Refundable",
                    canb: "Chargeable",
                    cabb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Cancellation of Flight is Free,NOSHOWUPTO 720 hrs of departure Cancellation of Flight is Free,NOSHOWAFTER From 720 hrs before  of departure Cancellation of Flight is Non-Refundable",
                    bagage_info: "40kg Baggage allowance Business",
                    markupnames: [],
                    ttf: 2715,
                  },
                  paxpr: [
                    {
                      bf: 1595,
                      tf: 2715,
                      tax: [
                        { OT: 1120 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "ZR9QA2",
                      ptype: "ADT",
                      scur: "QAR",
                      extra:
                        "1_52_Z_1_9_0_tx-2186:10:PZ:0_4246:920:YQ:0_6544:0:JBAG:0_10386:10:ZR:0_11947:40:F6:0_12006:60:P2:0_12434:80:IN:0_",
                      ruleid: "DyZRPuNo5HGjhVTqSbTxcwt4fLJrC0i89TPad4f+u4k=",
                      hsc: "c627e4754653b97600ab8b7504dd975302e792141a6914c478e1488b2feb6ba0",
                      sup_fare: "1595_2715_1595",
                      cabb: "Free",
                      bagage_info: "40kg Baggage allowance Business",
                      chekbcode: "JBAG",
                      psup_fare: "1595_2715",
                    },
                  ],
                  uniqueId: "Business",
                  routeId: "gdz6cbr1b1",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "FZ",
              tecst: "no",
              cl: "Economy",
              extra: 13919296,
              token:
                "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiYXBpdGF3ZmVlcSIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL2dpdmVubmFtZSI6IkFQSSBUQVdGRUVRIiwiZ3JvdXBBY2Nlc3MiOiIzLDM2LDExMiIsImlzQXB0Q2xudCI6IkZhbHNlIiwibG9jYXRpb24iOiI5OTk5IiwiY2hrTGNuIjoiIiwicmVzVG9rZW4iOiJmMTYxMmE4YzM5Y3ZjNDMwZjJvOXhiYjk2Y2M5NGY2M3ZjNDAxNDhlNGI5NCIsImNsaWVudElQIjoiMTcyLjE5LjI3LjM5IiwicmVzQ2hhbm5lbElEIjoiNSIsInJlc0NoYW5uZWxOYW1lIjoiVFBBUEkiLCJkZWZhdWx0VXNlck5hbWUiOiJhcGl0YXdmZWVxIiwibmJmIjoxNjgzMTk1MTM0LCJleHAiOjE2ODMxOTc1MzQsImlzcyI6ImZseWR1YmFpLmNvbSIsImF1ZCI6IlRBV0ZFRVFUUlZMU19GWl9OUCJ9._I0ZV--svLR1OqP0XLXV17rFF4itsd_2C9QzPwezZsc",
              stop: 1,
            },
            {
              tid: "vWHaWq7MRFs7s46u_2",
              ref: 2,
              ofcid: "",
              sdur: "19:25",
              re: "",
              sup: "5",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DXB",
                  dd: "2023-05-08T03:00:00",
                  ad: "2023-05-08T05:30:00",
                  rbd: "",
                  fn: "436",
                  trn: "",
                  eq: "73B",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "", arr: "2" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161457,
                  flightTime: 240,
                  fdur: "4:0",
                },
                {
                  ref: 2,
                  da: "DXB",
                  ar: "DOH",
                  dd: "2023-05-08T19:45:00",
                  ad: "2023-05-08T19:55:00",
                  rbd: "",
                  fn: "005",
                  trn: "",
                  eq: "73X",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "2", arr: "1" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161381,
                  flightTime: 70,
                  fdur: "1:10",
                },
              ],
              fareop: [
                {
                  fareKey: [],
                  pr: {
                    bf: 635,
                    tf: 915,
                    tax: [
                      { OT: 280 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    scur: "QAR",
                    ucur: "QAR",
                    exchange_rate: 1,
                    sup_fare: "635_915",
                    sup_cur_fare: "",
                    cabb: "Free",
                    chekb: "7kg INCLUDED",
                    meals: "Standard meal",
                    chanb: "Chargeable",
                    ftyp: "FLEX",
                    extra: "13_FLEX",
                    chekbcode: "BAGL",
                    chanb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Change of Flight is Free,NOSHOWUPTO 720 hrs of departure Change of Flight is Free,NOSHOWAFTER From 720 hrs before  of departure Change of Flight is Non-Refundable",
                    canb: "Chargeable",
                    cabb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Cancellation of Flight is Free,NOSHOWUPTO 720 hrs of departure Cancellation of Flight is Free,NOSHOWAFTER From 720 hrs before  of departure Cancellation of Flight is Non-Refundable",
                    bagage_info: "30kg BAG INCLUDED IN FARE",
                    markupnames: [],
                    ttf: 915,
                  },
                  paxpr: [
                    {
                      bf: 635,
                      tf: 915,
                      tax: [
                        { OT: 280 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "NRL8QA2",
                      ptype: "ADT",
                      scur: "QAR",
                      extra:
                        "1_76_N_1_9_0_tx-2186:10:PZ:0_4246:80:YQ:0_8947:0:BAGL:0_10386:10:ZR:0_11947:40:F6:0_12006:60:P2:0_12434:80:IN:0_",
                      ruleid: "i8SxjJOEO/FEzXcvATN2Hyrh0x6swSbrCL0pg69gHUU=",
                      hsc: "3ca0c3e0d2806c74028852ab6ff136bfe3dd055188385414f3ac0a625ba9025b",
                      sup_fare: "635_915_635",
                      cabb: "Free",
                      bagage_info: "30kg BAG INCLUDED IN FARE",
                      chekbcode: "BAGL",
                      psup_fare: "635_915",
                    },
                  ],
                  uniqueId: "FLEX",
                  routeId: "z6215otmxcl",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey: [],
                  pr: {
                    bf: 1595,
                    tf: 2715,
                    tax: [
                      { OT: 1120 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    scur: "QAR",
                    ucur: "QAR",
                    exchange_rate: 1,
                    sup_fare: "1595_2715",
                    sup_cur_fare: "",
                    cabb: "Free",
                    chekb: "14kg INCLUDED",
                    meals: "Chargeable",
                    chanb: "Chargeable",
                    ftyp: "Business",
                    extra: "15_Business",
                    chekbcode: "JBAG",
                    chanb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Change of Flight is Free,NOSHOWUPTO 720 hrs of departure Change of Flight is Free,NOSHOWAFTER From 720 hrs before  of departure Change of Flight is Non-Refundable",
                    canb: "Chargeable",
                    cabb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Cancellation of Flight is Free,NOSHOWUPTO 720 hrs of departure Cancellation of Flight is Free,NOSHOWAFTER From 720 hrs before  of departure Cancellation of Flight is Non-Refundable",
                    bagage_info: "40kg Baggage allowance Business",
                    markupnames: [],
                    ttf: 2715,
                  },
                  paxpr: [
                    {
                      bf: 1595,
                      tf: 2715,
                      tax: [
                        { OT: 1120 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "ZR9QA2",
                      ptype: "ADT",
                      scur: "QAR",
                      extra:
                        "1_84_Z_1_9_0_tx-2186:10:PZ:0_4246:920:YQ:0_6544:0:JBAG:0_10386:10:ZR:0_11947:40:F6:0_12006:60:P2:0_12434:80:IN:0_",
                      ruleid: "gziYdA6HKSkGuxiubH8k2At4fLJrC0i89TPad4f+u4k=",
                      hsc: "d96bcc415effba457da916de22588003e44d644acb62e416b349d81b45ed3a71",
                      sup_fare: "1595_2715_1595",
                      cabb: "Free",
                      bagage_info: "40kg Baggage allowance Business",
                      chekbcode: "JBAG",
                      psup_fare: "1595_2715",
                    },
                  ],
                  uniqueId: "Business",
                  routeId: "tefvmlx3f1",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "FZ",
              tecst: "no",
              cl: "Economy",
              extra: 13919294,
              token:
                "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiYXBpdGF3ZmVlcSIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL2dpdmVubmFtZSI6IkFQSSBUQVdGRUVRIiwiZ3JvdXBBY2Nlc3MiOiIzLDM2LDExMiIsImlzQXB0Q2xudCI6IkZhbHNlIiwibG9jYXRpb24iOiI5OTk5IiwiY2hrTGNuIjoiIiwicmVzVG9rZW4iOiJmMTYxMmE4YzM5Y3ZjNDMwZjJvOXhiYjk2Y2M5NGY2M3ZjNDAxNDhlNGI5NCIsImNsaWVudElQIjoiMTcyLjE5LjI3LjM5IiwicmVzQ2hhbm5lbElEIjoiNSIsInJlc0NoYW5uZWxOYW1lIjoiVFBBUEkiLCJkZWZhdWx0VXNlck5hbWUiOiJhcGl0YXdmZWVxIiwibmJmIjoxNjgzMTk1MTM0LCJleHAiOjE2ODMxOTc1MzQsImlzcyI6ImZseWR1YmFpLmNvbSIsImF1ZCI6IlRBV0ZFRVFUUlZMU19GWl9OUCJ9._I0ZV--svLR1OqP0XLXV17rFF4itsd_2C9QzPwezZsc",
              stop: 1,
            },
          ],
          servicefee: [],
        },
      ],
      mappings: [
        {
          airportCode: "HYD",
          airportName: "Rajiv Gandhi International Airport",
          cityName: "Hyderabad",
          countryName: "India",
          countryCode: "IN",
        },
        {
          airportCode: "HYD",
          airportName: "Rajiv Gandhi International Airport",
          cityName: "Hyderabad",
          countryName: "India",
          countryCode: "IN",
        },
        {
          airportCode: "HYD",
          airportName: "Rajiv Gandhi International Airport",
          cityName: "Hyderabad",
          countryName: "India",
          countryCode: "IN",
        },
        {
          airportCode: "MAA",
          airportName: "Chennai International Airport",
          cityName: "Chennai",
          countryName: "India",
          countryCode: "IN",
        },
        {
          airportCode: "DEL",
          airportName: "Indira Gandhi International Airport",
          cityName: "New Delhi",
          countryName: "India",
          countryCode: "IN",
        },
        {
          airportCode: "DOH",
          airportName: "Hamad International Airport",
          cityName: "Doha",
          countryName: "Qatar",
          countryCode: "QA",
        },
        {
          airportCode: "HYD",
          airportName: "Hyderabad All",
          countryName: "India",
          countryCode: "IN",
        },
      ],
      filter: {
        nerby: [],
        nerbyarr: [],
        suplrs: [
          { sup_name: "LCC", sup_id: 4 },
          { sup_name: "Full Service", sup_id: 4 },
        ],
        fstst: 911,
        chpst: 911,
        minprc: 911,
        maxprc: 4220,
        best: 911,
        stps: [
          { count: 0, prc: 911, airline: "6E" },
          { count: 1, prc: 1018, airline: "6E" },
        ],
        airl: [
          { count: 0, prc: 911, airline: "6E" },
          { count: 1, prc: 1330, airline: "FZ" },
        ],
      },
      sec: [
        { src: "DOH", des: "HYD", dd: "2023-05-06", ad: "2023-05-08", leg: 0 },
      ],
      echo: "96mz2o0jhhr",
      cha: "B2B",
      cid: "347",
      tt: "2",
      sty: "normal",
      cur: "QAR",
      adt: 1,
      chd: 0,
      inf: 0,
      region_type: "International",
    },
  ],
};
export const FLIGHT_FARE_SUMMARY = {
  status: "success",
  data: [
    {
      totalCount: {
        nonstop: 28,
        stops: 386,
      },
      fareSummary: [
        {
          airlines: ["EY"],
          price: {
            nonstop: null,
            stop: 1086.0,
            stopcount: 1,
            tid: ["1NUeI023am3BRCbq_1"],
          },
          options: ["QdpqzlPw39XSbrSO_1", "9rbpV5Ri90izi5ei_1"],
          currency: "QAR",
        },
        {
          airlines: ["EY/RJ"],
          price: {
            nonstop: null,
            stop: 1100.0,
            stopcount: 2,
            tid: ["izgT85UdcKVDIMDK_1"],
          },
          options: [
            "qweT85UdcASDFGHK_1",
            "ghtT85UdcQWERTGY_1",
            "asdT85UdcVNBMKFJ_1",
            "loiT85UdcKHJLGKH_1",
            "asmT85UdcALSKFHG_1",
          ],
          currency: "QAR",
        },
        {
          airlines: ["QR"],
          price: {
            nonstop: 1245.0,
            stop: null,
            stopcount: null,
            tid: ["izgT85UdcKVDIMDK_1"],
          },
          options: [
            "qweT85UdcASDFGHK_1",
            "ghtT85UdcQWERTGY_1",
            "asdT85UdcVNBMKFJ_1",
            "loiT85UdcKHJLGKH_1",
            "asmT85UdcALSKFHG_1",
          ],
          currency: "QAR",
        },
        {
          airlines: ["QR"],
          price: {
            nonstop: null,
            stop: 1514.0,
            stopcount: 3,
            tid: ["izgT85UdcKVDIMDK_1"],
          },
          options: [
            "qweT85UdcASDFGHK_1",
            "ghtT85UdcQWERTGY_1",
            "asdT85UdcVNBMKFJ_1",
            "loiT85UdcKHJLGKH_1",
            "asmT85UdcALSKFHG_1",
          ],
          currency: "QAR",
        },
      ],
    },
  ],
};

export const FLIGHT_RESPONSE_ONE_WAY_1A1C1I = {
  status: "success",
  data: [
    {
      origns: [
        {
          srcorigns: [
            {
              tid: "gkzKOUyZ7ydMqdHS_1",
              ref: 1,
              office_id: "",
              sdur: "6:45",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "HYD",
                  dd: "2023-06-07T02:00:00",
                  ad: "2023-06-07T08:45:00",
                  rbd: "",
                  fn: "1318",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: {},
                  rph: "",
                  jsell:
                    "6E~1318~ ~~DOH~06/07/2023 02:00~HYD~06/07/2023 08:45~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey: "0~R~ ~6E~R0INT~5000~~0~4~~X",
                  pr: {
                    bf: 15100,
                    tf: 21852,
                    tax: [
                      { OT: 6752 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "15100_21852",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 21852,
                  },
                  paxpr: [
                    {
                      bf: 7500,
                      tf: 10876,
                      tax: [
                        { OT: 3376 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "R0INT",
                      ptype: "ADT",
                      scur: "INR",
                      sup_fare: "7500_10876",
                      psup_fare: "7500_10876",
                    },
                    {
                      bf: 7500,
                      tf: 10876,
                      tax: [
                        { OT: 3376 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "R0INT",
                      ptype: "CHD",
                      scur: "INR",
                      sup_fare: "7500_10876",
                      psup_fare: "7500_10876",
                    },
                    {
                      bf: 100,
                      tf: 100,
                      tax: [
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "",
                      ptype: "INF",
                      scur: "INR",
                      sup_fare: "100_100",
                      psup_fare: "100_100",
                    },
                  ],
                  routeId: "2imvlf8dsw",
                  productClass: "R",
                  ruleNumber: "5000",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey: "0~R~ ~6E~RUINT~5003~~0~10~~X",
                  pr: {
                    bf: 16500,
                    tf: 23300,
                    tax: [
                      { OT: 6800 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "16500_23300",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 23300,
                  },
                  paxpr: [
                    {
                      bf: 8200,
                      tf: 11600,
                      tax: [
                        { OT: 3400 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "RUINT",
                      ptype: "ADT",
                      scur: "INR",
                      sup_fare: "8200_11600",
                      psup_fare: "8200_11600",
                    },
                    {
                      bf: 8200,
                      tf: 11600,
                      tax: [
                        { OT: 3400 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "RUINT",
                      ptype: "CHD",
                      scur: "INR",
                      sup_fare: "8200_11600",
                      psup_fare: "8200_11600",
                    },
                    {
                      bf: 100,
                      tf: 100,
                      tax: [
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "",
                      ptype: "INF",
                      scur: "INR",
                      sup_fare: "100_100",
                      psup_fare: "100_100",
                    },
                  ],
                  routeId: "erm05txm32",
                  productClass: "J",
                  ruleNumber: "5003",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "2Md+/gTLRZs=|pmb3XiYBQAFk5e/+oNh0iipySW228P0PPoH24B4iI+TZYp8IoVbadzEKuR6MvaENzPyle9UGiPHh8zqyVJHwLs4bsluY9c4XMAnXqMSUEXPVy/zQpBI1bTDFPS4sxqZqtca/2uKVEtU=",
              stop: 0,
            },
            {
              tid: "4glqzS7qiJXFOA8H_1",
              ref: 1,
              office_id: "",
              sdur: "6:45",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "HYD",
                  dd: "2023-06-07T08:15:00",
                  ad: "2023-06-07T15:00:00",
                  rbd: "",
                  fn: "1314",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: {},
                  rph: "",
                  jsell:
                    "6E~1314~ ~~DOH~06/07/2023 08:15~HYD~06/07/2023 15:00~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
              ],
              fareop: [
                {
                  fareKey: "0~R~ ~6E~R0INT~5000~~0~4~~X",
                  pr: {
                    bf: 15100,
                    tf: 21852,
                    tax: [
                      { OT: 6752 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "15100_21852",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 21852,
                  },
                  paxpr: [
                    {
                      bf: 7500,
                      tf: 10876,
                      tax: [
                        { OT: 3376 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "R0INT",
                      ptype: "ADT",
                      scur: "INR",
                      sup_fare: "7500_10876",
                      psup_fare: "7500_10876",
                    },
                    {
                      bf: 7500,
                      tf: 10876,
                      tax: [
                        { OT: 3376 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "R0INT",
                      ptype: "CHD",
                      scur: "INR",
                      sup_fare: "7500_10876",
                      psup_fare: "7500_10876",
                    },
                    {
                      bf: 100,
                      tf: 100,
                      tax: [
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "",
                      ptype: "INF",
                      scur: "INR",
                      sup_fare: "100_100",
                      psup_fare: "100_100",
                    },
                  ],
                  routeId: "zhgu7q2xrp",
                  productClass: "R",
                  ruleNumber: "5000",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey: "0~R~ ~6E~RUINT~5003~~0~10~~X",
                  pr: {
                    bf: 16500,
                    tf: 23300,
                    tax: [
                      { OT: 6800 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "16500_23300",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 23300,
                  },
                  paxpr: [
                    {
                      bf: 8200,
                      tf: 11600,
                      tax: [
                        { OT: 3400 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "RUINT",
                      ptype: "ADT",
                      scur: "INR",
                      sup_fare: "8200_11600",
                      psup_fare: "8200_11600",
                    },
                    {
                      bf: 8200,
                      tf: 11600,
                      tax: [
                        { OT: 3400 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "RUINT",
                      ptype: "CHD",
                      scur: "INR",
                      sup_fare: "8200_11600",
                      psup_fare: "8200_11600",
                    },
                    {
                      bf: 100,
                      tf: 100,
                      tax: [
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "",
                      ptype: "INF",
                      scur: "INR",
                      sup_fare: "100_100",
                      psup_fare: "100_100",
                    },
                  ],
                  routeId: "jdi9dzggilh",
                  productClass: "J",
                  ruleNumber: "5003",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "2Md+/gTLRZs=|pmb3XiYBQAFk5e/+oNh0iipySW228P0PPoH24B4iI+TZYp8IoVbadzEKuR6MvaENzPyle9UGiPHh8zqyVJHwLs4bsluY9c4XMAnXqMSUEXPVy/zQpBI1bTDFPS4sxqZqtca/2uKVEtU=",
              stop: 0,
            },
            {
              tid: "HIpBt0pGUg1mgiCm_1",
              ref: 1,
              office_id: "",
              sdur: "6:45",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "HYD",
                  dd: "2023-06-07T19:45:00",
                  ad: "2023-06-08T02:30:00",
                  rbd: "",
                  fn: "1316",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: {},
                  rph: "",
                  jsell:
                    "6E~1316~ ~~DOH~06/07/2023 19:45~HYD~06/08/2023 02:30~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
              ],
              fareop: [
                {
                  fareKey: "0~R~ ~6E~R0INT~5000~~0~4~~X",
                  pr: {
                    bf: 15100,
                    tf: 21852,
                    tax: [
                      { OT: 6752 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "15100_21852",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 21852,
                  },
                  paxpr: [
                    {
                      bf: 7500,
                      tf: 10876,
                      tax: [
                        { OT: 3376 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "R0INT",
                      ptype: "ADT",
                      scur: "INR",
                      sup_fare: "7500_10876",
                      psup_fare: "7500_10876",
                    },
                    {
                      bf: 7500,
                      tf: 10876,
                      tax: [
                        { OT: 3376 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "R0INT",
                      ptype: "CHD",
                      scur: "INR",
                      sup_fare: "7500_10876",
                      psup_fare: "7500_10876",
                    },
                    {
                      bf: 100,
                      tf: 100,
                      tax: [
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "",
                      ptype: "INF",
                      scur: "INR",
                      sup_fare: "100_100",
                      psup_fare: "100_100",
                    },
                  ],
                  routeId: "jljnbcopkm",
                  productClass: "R",
                  ruleNumber: "5000",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey: "0~R~ ~6E~RUINT~5003~~0~10~~X",
                  pr: {
                    bf: 16500,
                    tf: 23300,
                    tax: [
                      { OT: 6800 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "16500_23300",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 23300,
                  },
                  paxpr: [
                    {
                      bf: 8200,
                      tf: 11600,
                      tax: [
                        { OT: 3400 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "RUINT",
                      ptype: "ADT",
                      scur: "INR",
                      sup_fare: "8200_11600",
                      psup_fare: "8200_11600",
                    },
                    {
                      bf: 8200,
                      tf: 11600,
                      tax: [
                        { OT: 3400 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "RUINT",
                      ptype: "CHD",
                      scur: "INR",
                      sup_fare: "8200_11600",
                      psup_fare: "8200_11600",
                    },
                    {
                      bf: 100,
                      tf: 100,
                      tax: [
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "",
                      ptype: "INF",
                      scur: "INR",
                      sup_fare: "100_100",
                      psup_fare: "100_100",
                    },
                  ],
                  routeId: "yvqaxl22we",
                  productClass: "J",
                  ruleNumber: "5003",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "2Md+/gTLRZs=|pmb3XiYBQAFk5e/+oNh0iipySW228P0PPoH24B4iI+TZYp8IoVbadzEKuR6MvaENzPyle9UGiPHh8zqyVJHwLs4bsluY9c4XMAnXqMSUEXPVy/zQpBI1bTDFPS4sxqZqtca/2uKVEtU=",
              stop: 0,
            },
          ],
          paxpr: [
            {
              bf: 7500,
              tf: 10876,
              tax: [
                { OT: 3376 },
                { attmkp: 0 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              fbc: "R0INT",
              ptype: "ADT",
              scur: "INR",
              sup_fare: "7500_10876",
              psup_fare: "7500_10876",
            },
            {
              bf: 7500,
              tf: 10876,
              tax: [
                { OT: 3376 },
                { attmkp: 0 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              fbc: "R0INT",
              ptype: "CHD",
              scur: "INR",
              sup_fare: "7500_10876",
              psup_fare: "7500_10876",
            },
            {
              bf: 100,
              tf: 100,
              tax: [
                { attmkp: 0 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              fbc: "",
              ptype: "INF",
              scur: "INR",
              sup_fare: "100_100",
              psup_fare: "100_100",
            },
          ],
          pr: {
            bf: 15100,
            tf: 21852,
            tax: [
              { OT: 6752 },
              { attmkp: 0 },
              { tamkp: 0 },
              { stamkp: 0 },
              { attdisc: 0 },
              { tadisc: 0 },
              { stadisc: 0 },
            ],
            ucur: "INR",
            exchange_rate: 1,
            scur: "INR",
            sup_fare: "15100_21852",
            sup_cur_fare: "",
            cabb: "",
            chekb: "",
            seats: "",
            meals: "",
            chanb: "",
            ftyp: "Regular - R",
            markupnames: [],
            ttf: 21852,
          },
          sup: "4",
          airlineList: "6E",
          servicefee: [],
        },
        {
          srcorigns: [
            {
              tid: "X4BUBTwNz7byE23r_1",
              ref: 1,
              office_id: "",
              sdur: "16:15",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "MAA",
                  dd: "2023-06-07T02:00:00",
                  ad: "2023-06-07T09:05:00",
                  rbd: "",
                  fn: "1322",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "3" },
                  rph: "",
                  jsell:
                    "6E~1322~ ~~DOH~06/07/2023 02:00~MAA~06/07/2023 09:05~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
                {
                  ref: 2,
                  da: "MAA",
                  ar: "HYD",
                  dd: "2023-06-07T17:00:00",
                  ad: "2023-06-07T18:15:00",
                  rbd: "",
                  fn: " 562",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "1" },
                  rph: "",
                  jsell:
                    "6E~ 562~ ~~MAA~06/07/2023 17:00~HYD~06/07/2023 18:15~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "1~Q~ ~6E~Q0INT~5000~~0~12~~^2~L~ ~6E~LC8AP~1070~~0~7~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 9313,
                      fbc: "Q0INT/LC8AP",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "9313_12802",
                      tax: [
                        { OT: 3489 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 12802,
                      psup_fare: "9313_12802",
                    },
                    {
                      bf: 9313,
                      fbc: "Q0INT/LC8AP",
                      ptype: "CHD",
                      scur: "QAR",
                      sup_fare: "9313_12802",
                      tax: [
                        { OT: 3489 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 12802,
                      psup_fare: "9313_12802",
                    },
                    {
                      bf: 200,
                      fbc: "",
                      ptype: "INF",
                      scur: "QAR",
                      sup_fare: "200_200",
                      tax: [
                        { OT: 0 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 200,
                      psup_fare: "200_200",
                    },
                  ],
                  pr: {
                    bf: 18826,
                    tf: 25804,
                    tax: [
                      { OT: 6978 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "18826_25804",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 25804,
                  },
                  routeId: "clhzifmxzm",
                  ruleNumber: "5000",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~R~ ~6E~RCUINT~6202~~0~4~~X^0~R~ ~6E~RCUINT~6202~~0~4~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 10519,
                      fbc: "RCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "10519_14050",
                      tax: [
                        { OT: 3531 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 14050,
                      psup_fare: "10519_14050",
                    },
                    {
                      bf: 10519,
                      fbc: "RCUINT",
                      ptype: "CHD",
                      scur: "QAR",
                      sup_fare: "10519_14050",
                      tax: [
                        { OT: 3531 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 14050,
                      psup_fare: "10519_14050",
                    },
                    {
                      bf: 100,
                      fbc: "",
                      ptype: "INF",
                      scur: "QAR",
                      sup_fare: "100_100",
                      tax: [
                        { OT: 0 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 100,
                      psup_fare: "100_100",
                    },
                  ],
                  pr: {
                    bf: 21138,
                    tf: 28200,
                    tax: [
                      { OT: 7062 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "21138_28200",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 28200,
                  },
                  routeId: "ezebpevwhh",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "2Md+/gTLRZs=|pmb3XiYBQAFk5e/+oNh0iipySW228P0PPoH24B4iI+TZYp8IoVbadzEKuR6MvaENzPyle9UGiPHh8zqyVJHwLs4bsluY9c4XMAnXqMSUEXPVy/zQpBI1bTDFPS4sxqZqtca/2uKVEtU=",
              stop: 1,
            },
            {
              tid: "0CTJDtTytaftSDiJ_1",
              ref: 1,
              office_id: "",
              sdur: "15:10",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "MAA",
                  dd: "2023-06-07T02:00:00",
                  ad: "2023-06-07T09:05:00",
                  rbd: "",
                  fn: "1322",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "3" },
                  rph: "",
                  jsell:
                    "6E~1322~ ~~DOH~06/07/2023 02:00~MAA~06/07/2023 09:05~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
                {
                  ref: 2,
                  da: "MAA",
                  ar: "HYD",
                  dd: "2023-06-07T16:00:00",
                  ad: "2023-06-07T17:10:00",
                  rbd: "",
                  fn: "6401",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "1" },
                  rph: "",
                  jsell:
                    "6E~6401~ ~~MAA~06/07/2023 16:00~HYD~06/07/2023 17:10~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "1~Q~ ~6E~Q0INT~5000~~0~12~~^2~L~ ~6E~LC8AP~1070~~0~7~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 9313,
                      fbc: "Q0INT/LC8AP",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "9313_12802",
                      tax: [
                        { OT: 3489 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 12802,
                      psup_fare: "9313_12802",
                    },
                    {
                      bf: 9313,
                      fbc: "Q0INT/LC8AP",
                      ptype: "CHD",
                      scur: "QAR",
                      sup_fare: "9313_12802",
                      tax: [
                        { OT: 3489 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 12802,
                      psup_fare: "9313_12802",
                    },
                    {
                      bf: 200,
                      fbc: "",
                      ptype: "INF",
                      scur: "QAR",
                      sup_fare: "200_200",
                      tax: [
                        { OT: 0 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 200,
                      psup_fare: "200_200",
                    },
                  ],
                  pr: {
                    bf: 18826,
                    tf: 25804,
                    tax: [
                      { OT: 6978 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "18826_25804",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 25804,
                  },
                  routeId: "0yjxoeavvk",
                  ruleNumber: "5000",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~R~ ~6E~RCUINT~6202~~0~4~~X^0~R~ ~6E~RCUINT~6202~~0~4~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 10519,
                      fbc: "RCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "10519_14050",
                      tax: [
                        { OT: 3531 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 14050,
                      psup_fare: "10519_14050",
                    },
                    {
                      bf: 10519,
                      fbc: "RCUINT",
                      ptype: "CHD",
                      scur: "QAR",
                      sup_fare: "10519_14050",
                      tax: [
                        { OT: 3531 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 14050,
                      psup_fare: "10519_14050",
                    },
                    {
                      bf: 100,
                      fbc: "",
                      ptype: "INF",
                      scur: "QAR",
                      sup_fare: "100_100",
                      tax: [
                        { OT: 0 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 100,
                      psup_fare: "100_100",
                    },
                  ],
                  pr: {
                    bf: 21138,
                    tf: 28200,
                    tax: [
                      { OT: 7062 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "21138_28200",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 28200,
                  },
                  routeId: "85s8ujxnbwl",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "2Md+/gTLRZs=|pmb3XiYBQAFk5e/+oNh0iipySW228P0PPoH24B4iI+TZYp8IoVbadzEKuR6MvaENzPyle9UGiPHh8zqyVJHwLs4bsluY9c4XMAnXqMSUEXPVy/zQpBI1bTDFPS4sxqZqtca/2uKVEtU=",
              stop: 1,
            },
            {
              tid: "HkUTUmVsnu0GfWrE_1",
              ref: 1,
              office_id: "",
              sdur: "11:30",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "MAA",
                  dd: "2023-06-07T02:00:00",
                  ad: "2023-06-07T09:05:00",
                  rbd: "",
                  fn: "1322",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "3" },
                  rph: "",
                  jsell:
                    "6E~1322~ ~~DOH~06/07/2023 02:00~MAA~06/07/2023 09:05~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
                {
                  ref: 2,
                  da: "MAA",
                  ar: "HYD",
                  dd: "2023-06-07T12:20:00",
                  ad: "2023-06-07T13:30:00",
                  rbd: "",
                  fn: " 604",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "1" },
                  rph: "",
                  jsell:
                    "6E~ 604~ ~~MAA~06/07/2023 12:20~HYD~06/07/2023 13:30~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "1~Q~ ~6E~Q0INT~5000~~0~12~~^2~L~ ~6E~LC8AP~1070~~0~7~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 9313,
                      fbc: "Q0INT/LC8AP",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "9313_12802",
                      tax: [
                        { OT: 3489 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 12802,
                      psup_fare: "9313_12802",
                    },
                    {
                      bf: 9313,
                      fbc: "Q0INT/LC8AP",
                      ptype: "CHD",
                      scur: "QAR",
                      sup_fare: "9313_12802",
                      tax: [
                        { OT: 3489 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 12802,
                      psup_fare: "9313_12802",
                    },
                    {
                      bf: 200,
                      fbc: "",
                      ptype: "INF",
                      scur: "QAR",
                      sup_fare: "200_200",
                      tax: [
                        { OT: 0 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 200,
                      psup_fare: "200_200",
                    },
                  ],
                  pr: {
                    bf: 18826,
                    tf: 25804,
                    tax: [
                      { OT: 6978 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "18826_25804",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 25804,
                  },
                  routeId: "ht4aw25121",
                  ruleNumber: "5000",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~R~ ~6E~RCUINT~6202~~0~4~~X^0~R~ ~6E~RCUINT~6202~~0~4~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 10519,
                      fbc: "RCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "10519_14050",
                      tax: [
                        { OT: 3531 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 14050,
                      psup_fare: "10519_14050",
                    },
                    {
                      bf: 10519,
                      fbc: "RCUINT",
                      ptype: "CHD",
                      scur: "QAR",
                      sup_fare: "10519_14050",
                      tax: [
                        { OT: 3531 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 14050,
                      psup_fare: "10519_14050",
                    },
                    {
                      bf: 100,
                      fbc: "",
                      ptype: "INF",
                      scur: "QAR",
                      sup_fare: "100_100",
                      tax: [
                        { OT: 0 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 100,
                      psup_fare: "100_100",
                    },
                  ],
                  pr: {
                    bf: 21138,
                    tf: 28200,
                    tax: [
                      { OT: 7062 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "21138_28200",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 28200,
                  },
                  routeId: "lfuqhv0r1a",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "2Md+/gTLRZs=|pmb3XiYBQAFk5e/+oNh0iipySW228P0PPoH24B4iI+TZYp8IoVbadzEKuR6MvaENzPyle9UGiPHh8zqyVJHwLs4bsluY9c4XMAnXqMSUEXPVy/zQpBI1bTDFPS4sxqZqtca/2uKVEtU=",
              stop: 1,
            },
          ],
          paxpr: [
            {
              bf: 9313,
              fbc: "Q0INT/LC8AP",
              ptype: "ADT",
              scur: "QAR",
              sup_fare: "9313_12802",
              tax: [
                { OT: 3489 },
                { attmkp: 0 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              tf: 12802,
              psup_fare: "9313_12802",
            },
            {
              bf: 9313,
              fbc: "Q0INT/LC8AP",
              ptype: "CHD",
              scur: "QAR",
              sup_fare: "9313_12802",
              tax: [
                { OT: 3489 },
                { attmkp: 0 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              tf: 12802,
              psup_fare: "9313_12802",
            },
            {
              bf: 200,
              fbc: "",
              ptype: "INF",
              scur: "QAR",
              sup_fare: "200_200",
              tax: [
                { OT: 0 },
                { attmkp: 0 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              tf: 200,
              psup_fare: "200_200",
            },
          ],
          pr: {
            bf: 18826,
            tf: 25804,
            tax: [
              { OT: 6978 },
              { attmkp: 0 },
              { tamkp: 0 },
              { stamkp: 0 },
              { attdisc: 0 },
              { tadisc: 0 },
              { stadisc: 0 },
            ],
            ucur: "INR",
            exchange_rate: 1,
            scur: "INR",
            sup_fare: "18826_25804",
            sup_cur_fare: "",
            cabb: "",
            chekb: "",
            seats: "",
            meals: "",
            chanb: "",
            ftyp: "Regular - R",
            markupnames: [],
            ttf: 25804,
          },
          sup: "4",
          airlineList: "6E",
          servicefee: [],
        },
        {
          srcorigns: [
            {
              tid: "d7VzNHNQyk27bZGh_1",
              ref: 1,
              office_id: "",
              sdur: "10:15",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-07T02:00:00",
                  ad: "2023-06-07T08:20:00",
                  rbd: "",
                  fn: "1312",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "3" },
                  rph: "",
                  jsell:
                    "6E~1312~ ~~DOH~06/07/2023 02:00~DEL~06/07/2023 08:20~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-06-07T10:10:00",
                  ad: "2023-06-07T12:15:00",
                  rbd: "",
                  fn: "2005",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "2" },
                  rph: "",
                  jsell:
                    "6E~2005~ ~~DEL~06/07/2023 10:10~HYD~06/07/2023 12:15~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~R~ ~6E~RCINT~6200~~0~65~~X^0~R~ ~6E~RCINT~6200~~0~65~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 9819,
                      fbc: "RCINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "9819_13326",
                      tax: [
                        { OT: 3507 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 13326,
                      psup_fare: "9819_13326",
                    },
                    {
                      bf: 9819,
                      fbc: "RCINT",
                      ptype: "CHD",
                      scur: "QAR",
                      sup_fare: "9819_13326",
                      tax: [
                        { OT: 3507 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 13326,
                      psup_fare: "9819_13326",
                    },
                    {
                      bf: 100,
                      fbc: "",
                      ptype: "INF",
                      scur: "QAR",
                      sup_fare: "100_100",
                      tax: [
                        { OT: 0 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 100,
                      psup_fare: "100_100",
                    },
                  ],
                  pr: {
                    bf: 19738,
                    tf: 26752,
                    tax: [
                      { OT: 7014 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "19738_26752",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 26752,
                  },
                  routeId: "6m0867tzgh",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~R~ ~6E~RCUINT~6202~~0~4~~X^0~R~ ~6E~RCUINT~6202~~0~4~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 10519,
                      fbc: "RCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "10519_14050",
                      tax: [
                        { OT: 3531 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 14050,
                      psup_fare: "10519_14050",
                    },
                    {
                      bf: 10519,
                      fbc: "RCUINT",
                      ptype: "CHD",
                      scur: "QAR",
                      sup_fare: "10519_14050",
                      tax: [
                        { OT: 3531 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 14050,
                      psup_fare: "10519_14050",
                    },
                    {
                      bf: 100,
                      fbc: "",
                      ptype: "INF",
                      scur: "QAR",
                      sup_fare: "100_100",
                      tax: [
                        { OT: 0 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 100,
                      psup_fare: "100_100",
                    },
                  ],
                  pr: {
                    bf: 21138,
                    tf: 28200,
                    tax: [
                      { OT: 7062 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "21138_28200",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 28200,
                  },
                  routeId: "dusp1pv66a",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "2Md+/gTLRZs=|pmb3XiYBQAFk5e/+oNh0iipySW228P0PPoH24B4iI+TZYp8IoVbadzEKuR6MvaENzPyle9UGiPHh8zqyVJHwLs4bsluY9c4XMAnXqMSUEXPVy/zQpBI1bTDFPS4sxqZqtca/2uKVEtU=",
              stop: 1,
            },
            {
              tid: "9aJQzOeBklgeBsUX_1",
              ref: 1,
              office_id: "",
              sdur: "20:0",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-07T02:00:00",
                  ad: "2023-06-07T08:20:00",
                  rbd: "",
                  fn: "1312",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "3" },
                  rph: "",
                  jsell:
                    "6E~1312~ ~~DOH~06/07/2023 02:00~DEL~06/07/2023 08:20~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-06-07T19:50:00",
                  ad: "2023-06-07T22:00:00",
                  rbd: "",
                  fn: " 605",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "1" },
                  rph: "",
                  jsell:
                    "6E~ 605~ ~~DEL~06/07/2023 19:50~HYD~06/07/2023 22:00~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~R~ ~6E~RCINT~6200~~0~65~~X^0~R~ ~6E~RCINT~6200~~0~65~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 9819,
                      fbc: "RCINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "9819_13326",
                      tax: [
                        { OT: 3507 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 13326,
                      psup_fare: "9819_13326",
                    },
                    {
                      bf: 9819,
                      fbc: "RCINT",
                      ptype: "CHD",
                      scur: "QAR",
                      sup_fare: "9819_13326",
                      tax: [
                        { OT: 3507 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 13326,
                      psup_fare: "9819_13326",
                    },
                    {
                      bf: 100,
                      fbc: "",
                      ptype: "INF",
                      scur: "QAR",
                      sup_fare: "100_100",
                      tax: [
                        { OT: 0 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 100,
                      psup_fare: "100_100",
                    },
                  ],
                  pr: {
                    bf: 19738,
                    tf: 26752,
                    tax: [
                      { OT: 7014 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "19738_26752",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 26752,
                  },
                  routeId: "nfsx9tc3fyl",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~R~ ~6E~RCUINT~6202~~0~4~~X^0~R~ ~6E~RCUINT~6202~~0~4~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 10519,
                      fbc: "RCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "10519_14050",
                      tax: [
                        { OT: 3531 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 14050,
                      psup_fare: "10519_14050",
                    },
                    {
                      bf: 10519,
                      fbc: "RCUINT",
                      ptype: "CHD",
                      scur: "QAR",
                      sup_fare: "10519_14050",
                      tax: [
                        { OT: 3531 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 14050,
                      psup_fare: "10519_14050",
                    },
                    {
                      bf: 100,
                      fbc: "",
                      ptype: "INF",
                      scur: "QAR",
                      sup_fare: "100_100",
                      tax: [
                        { OT: 0 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 100,
                      psup_fare: "100_100",
                    },
                  ],
                  pr: {
                    bf: 21138,
                    tf: 28200,
                    tax: [
                      { OT: 7062 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "21138_28200",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 28200,
                  },
                  routeId: "zapomcshb9",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "2Md+/gTLRZs=|pmb3XiYBQAFk5e/+oNh0iipySW228P0PPoH24B4iI+TZYp8IoVbadzEKuR6MvaENzPyle9UGiPHh8zqyVJHwLs4bsluY9c4XMAnXqMSUEXPVy/zQpBI1bTDFPS4sxqZqtca/2uKVEtU=",
              stop: 1,
            },
            {
              tid: "vL6psobOXdEv0AsS_1",
              ref: 1,
              office_id: "",
              sdur: "19:0",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-07T02:00:00",
                  ad: "2023-06-07T08:20:00",
                  rbd: "",
                  fn: "1312",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "3" },
                  rph: "",
                  jsell:
                    "6E~1312~ ~~DOH~06/07/2023 02:00~DEL~06/07/2023 08:20~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-06-07T18:50:00",
                  ad: "2023-06-07T21:00:00",
                  rbd: "",
                  fn: "5217",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "3" },
                  rph: "",
                  jsell:
                    "6E~5217~ ~~DEL~06/07/2023 18:50~HYD~06/07/2023 21:00~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~R~ ~6E~RCINT~6200~~0~65~~X^0~R~ ~6E~RCINT~6200~~0~65~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 9819,
                      fbc: "RCINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "9819_13326",
                      tax: [
                        { OT: 3507 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 13326,
                      psup_fare: "9819_13326",
                    },
                    {
                      bf: 9819,
                      fbc: "RCINT",
                      ptype: "CHD",
                      scur: "QAR",
                      sup_fare: "9819_13326",
                      tax: [
                        { OT: 3507 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 13326,
                      psup_fare: "9819_13326",
                    },
                    {
                      bf: 100,
                      fbc: "",
                      ptype: "INF",
                      scur: "QAR",
                      sup_fare: "100_100",
                      tax: [
                        { OT: 0 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 100,
                      psup_fare: "100_100",
                    },
                  ],
                  pr: {
                    bf: 19738,
                    tf: 26752,
                    tax: [
                      { OT: 7014 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "19738_26752",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 26752,
                  },
                  routeId: "sjmpwhcvfr",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~R~ ~6E~RCUINT~6202~~0~4~~X^0~R~ ~6E~RCUINT~6202~~0~4~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 10519,
                      fbc: "RCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "10519_14050",
                      tax: [
                        { OT: 3531 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 14050,
                      psup_fare: "10519_14050",
                    },
                    {
                      bf: 10519,
                      fbc: "RCUINT",
                      ptype: "CHD",
                      scur: "QAR",
                      sup_fare: "10519_14050",
                      tax: [
                        { OT: 3531 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 14050,
                      psup_fare: "10519_14050",
                    },
                    {
                      bf: 100,
                      fbc: "",
                      ptype: "INF",
                      scur: "QAR",
                      sup_fare: "100_100",
                      tax: [
                        { OT: 0 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 100,
                      psup_fare: "100_100",
                    },
                  ],
                  pr: {
                    bf: 21138,
                    tf: 28200,
                    tax: [
                      { OT: 7062 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "21138_28200",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 28200,
                  },
                  routeId: "8lwvfy73md",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "2Md+/gTLRZs=|pmb3XiYBQAFk5e/+oNh0iipySW228P0PPoH24B4iI+TZYp8IoVbadzEKuR6MvaENzPyle9UGiPHh8zqyVJHwLs4bsluY9c4XMAnXqMSUEXPVy/zQpBI1bTDFPS4sxqZqtca/2uKVEtU=",
              stop: 1,
            },
            {
              tid: "WT1g1Avb3A71aVBH_1",
              ref: 1,
              office_id: "",
              sdur: "13:45",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-07T02:00:00",
                  ad: "2023-06-07T08:20:00",
                  rbd: "",
                  fn: "1312",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "3" },
                  rph: "",
                  jsell:
                    "6E~1312~ ~~DOH~06/07/2023 02:00~DEL~06/07/2023 08:20~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-06-07T13:30:00",
                  ad: "2023-06-07T15:45:00",
                  rbd: "",
                  fn: " 837",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "1" },
                  rph: "",
                  jsell:
                    "6E~ 837~ ~~DEL~06/07/2023 13:30~HYD~06/07/2023 15:45~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~R~ ~6E~RCINT~6200~~0~65~~X^0~R~ ~6E~RCINT~6200~~0~65~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 9819,
                      fbc: "RCINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "9819_13326",
                      tax: [
                        { OT: 3507 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 13326,
                      psup_fare: "9819_13326",
                    },
                    {
                      bf: 9819,
                      fbc: "RCINT",
                      ptype: "CHD",
                      scur: "QAR",
                      sup_fare: "9819_13326",
                      tax: [
                        { OT: 3507 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 13326,
                      psup_fare: "9819_13326",
                    },
                    {
                      bf: 100,
                      fbc: "",
                      ptype: "INF",
                      scur: "QAR",
                      sup_fare: "100_100",
                      tax: [
                        { OT: 0 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 100,
                      psup_fare: "100_100",
                    },
                  ],
                  pr: {
                    bf: 19738,
                    tf: 26752,
                    tax: [
                      { OT: 7014 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "19738_26752",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 26752,
                  },
                  routeId: "tc052nqs1f",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~R~ ~6E~RCUINT~6202~~0~4~~X^0~R~ ~6E~RCUINT~6202~~0~4~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 10519,
                      fbc: "RCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "10519_14050",
                      tax: [
                        { OT: 3531 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 14050,
                      psup_fare: "10519_14050",
                    },
                    {
                      bf: 10519,
                      fbc: "RCUINT",
                      ptype: "CHD",
                      scur: "QAR",
                      sup_fare: "10519_14050",
                      tax: [
                        { OT: 3531 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 14050,
                      psup_fare: "10519_14050",
                    },
                    {
                      bf: 100,
                      fbc: "",
                      ptype: "INF",
                      scur: "QAR",
                      sup_fare: "100_100",
                      tax: [
                        { OT: 0 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 100,
                      psup_fare: "100_100",
                    },
                  ],
                  pr: {
                    bf: 21138,
                    tf: 28200,
                    tax: [
                      { OT: 7062 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "21138_28200",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 28200,
                  },
                  routeId: "bua5k8c6mp",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "2Md+/gTLRZs=|pmb3XiYBQAFk5e/+oNh0iipySW228P0PPoH24B4iI+TZYp8IoVbadzEKuR6MvaENzPyle9UGiPHh8zqyVJHwLs4bsluY9c4XMAnXqMSUEXPVy/zQpBI1bTDFPS4sxqZqtca/2uKVEtU=",
              stop: 1,
            },
            {
              tid: "yzNsHQuA3iz03xQH_1",
              ref: 1,
              office_id: "",
              sdur: "14:40",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-07T19:40:00",
                  ad: "2023-06-08T02:00:00",
                  rbd: "",
                  fn: "1308",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "3" },
                  rph: "",
                  jsell:
                    "6E~1308~ ~~DOH~06/07/2023 19:40~DEL~06/08/2023 02:00~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-06-08T08:10:00",
                  ad: "2023-06-08T10:20:00",
                  rbd: "",
                  fn: " 774",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "1" },
                  rph: "",
                  jsell:
                    "6E~ 774~ ~~DEL~06/08/2023 08:10~HYD~06/08/2023 10:20~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~R~ ~6E~RCINT~6200~~0~65~~X^0~R~ ~6E~RCINT~6200~~0~65~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 9819,
                      fbc: "RCINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "9819_13326",
                      tax: [
                        { OT: 3507 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 13326,
                      psup_fare: "9819_13326",
                    },
                    {
                      bf: 9819,
                      fbc: "RCINT",
                      ptype: "CHD",
                      scur: "QAR",
                      sup_fare: "9819_13326",
                      tax: [
                        { OT: 3507 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 13326,
                      psup_fare: "9819_13326",
                    },
                    {
                      bf: 100,
                      fbc: "",
                      ptype: "INF",
                      scur: "QAR",
                      sup_fare: "100_100",
                      tax: [
                        { OT: 0 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 100,
                      psup_fare: "100_100",
                    },
                  ],
                  pr: {
                    bf: 19738,
                    tf: 26752,
                    tax: [
                      { OT: 7014 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "19738_26752",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 26752,
                  },
                  routeId: "nxlre7g23w",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~R~ ~6E~RCUINT~6202~~0~4~~X^0~R~ ~6E~RCUINT~6202~~0~4~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 10519,
                      fbc: "RCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "10519_14050",
                      tax: [
                        { OT: 3531 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 14050,
                      psup_fare: "10519_14050",
                    },
                    {
                      bf: 10519,
                      fbc: "RCUINT",
                      ptype: "CHD",
                      scur: "QAR",
                      sup_fare: "10519_14050",
                      tax: [
                        { OT: 3531 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 14050,
                      psup_fare: "10519_14050",
                    },
                    {
                      bf: 100,
                      fbc: "",
                      ptype: "INF",
                      scur: "QAR",
                      sup_fare: "100_100",
                      tax: [
                        { OT: 0 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 100,
                      psup_fare: "100_100",
                    },
                  ],
                  pr: {
                    bf: 21138,
                    tf: 28200,
                    tax: [
                      { OT: 7062 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "21138_28200",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 28200,
                  },
                  routeId: "khdmlcp76w",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "2Md+/gTLRZs=|pmb3XiYBQAFk5e/+oNh0iipySW228P0PPoH24B4iI+TZYp8IoVbadzEKuR6MvaENzPyle9UGiPHh8zqyVJHwLs4bsluY9c4XMAnXqMSUEXPVy/zQpBI1bTDFPS4sxqZqtca/2uKVEtU=",
              stop: 1,
            },
            {
              tid: "tGbQH5o76WNeqzVH_1",
              ref: 1,
              office_id: "",
              sdur: "28:0",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-07T19:40:00",
                  ad: "2023-06-08T02:00:00",
                  rbd: "",
                  fn: "1308",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "3" },
                  rph: "",
                  jsell:
                    "6E~1308~ ~~DOH~06/07/2023 19:40~DEL~06/08/2023 02:00~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-06-08T21:25:00",
                  ad: "2023-06-08T23:40:00",
                  rbd: "",
                  fn: "2371",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "2" },
                  rph: "",
                  jsell:
                    "6E~2371~ ~~DEL~06/08/2023 21:25~HYD~06/08/2023 23:40~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~R~ ~6E~RCINT~6200~~0~65~~X^0~R~ ~6E~RCINT~6200~~0~65~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 9819,
                      fbc: "RCINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "9819_13326",
                      tax: [
                        { OT: 3507 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 13326,
                      psup_fare: "9819_13326",
                    },
                    {
                      bf: 9819,
                      fbc: "RCINT",
                      ptype: "CHD",
                      scur: "QAR",
                      sup_fare: "9819_13326",
                      tax: [
                        { OT: 3507 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 13326,
                      psup_fare: "9819_13326",
                    },
                    {
                      bf: 100,
                      fbc: "",
                      ptype: "INF",
                      scur: "QAR",
                      sup_fare: "100_100",
                      tax: [
                        { OT: 0 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 100,
                      psup_fare: "100_100",
                    },
                  ],
                  pr: {
                    bf: 19738,
                    tf: 26752,
                    tax: [
                      { OT: 7014 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "19738_26752",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 26752,
                  },
                  routeId: "yhn7626obu",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~R~ ~6E~RCUINT~6202~~0~4~~X^0~R~ ~6E~RCUINT~6202~~0~4~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 10519,
                      fbc: "RCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "10519_14050",
                      tax: [
                        { OT: 3531 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 14050,
                      psup_fare: "10519_14050",
                    },
                    {
                      bf: 10519,
                      fbc: "RCUINT",
                      ptype: "CHD",
                      scur: "QAR",
                      sup_fare: "10519_14050",
                      tax: [
                        { OT: 3531 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 14050,
                      psup_fare: "10519_14050",
                    },
                    {
                      bf: 100,
                      fbc: "",
                      ptype: "INF",
                      scur: "QAR",
                      sup_fare: "100_100",
                      tax: [
                        { OT: 0 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 100,
                      psup_fare: "100_100",
                    },
                  ],
                  pr: {
                    bf: 21138,
                    tf: 28200,
                    tax: [
                      { OT: 7062 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "21138_28200",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 28200,
                  },
                  routeId: "blcelxskgk",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "2Md+/gTLRZs=|pmb3XiYBQAFk5e/+oNh0iipySW228P0PPoH24B4iI+TZYp8IoVbadzEKuR6MvaENzPyle9UGiPHh8zqyVJHwLs4bsluY9c4XMAnXqMSUEXPVy/zQpBI1bTDFPS4sxqZqtca/2uKVEtU=",
              stop: 1,
            },
          ],
          paxpr: [
            {
              bf: 9819,
              fbc: "RCINT",
              ptype: "ADT",
              scur: "QAR",
              sup_fare: "9819_13326",
              tax: [
                { OT: 3507 },
                { attmkp: 0 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              tf: 13326,
              psup_fare: "9819_13326",
            },
            {
              bf: 9819,
              fbc: "RCINT",
              ptype: "CHD",
              scur: "QAR",
              sup_fare: "9819_13326",
              tax: [
                { OT: 3507 },
                { attmkp: 0 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              tf: 13326,
              psup_fare: "9819_13326",
            },
            {
              bf: 100,
              fbc: "",
              ptype: "INF",
              scur: "QAR",
              sup_fare: "100_100",
              tax: [
                { OT: 0 },
                { attmkp: 0 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              tf: 100,
              psup_fare: "100_100",
            },
          ],
          pr: {
            bf: 19738,
            tf: 26752,
            tax: [
              { OT: 7014 },
              { attmkp: 0 },
              { tamkp: 0 },
              { stamkp: 0 },
              { attdisc: 0 },
              { tadisc: 0 },
              { stadisc: 0 },
            ],
            ucur: "INR",
            exchange_rate: 1,
            scur: "INR",
            sup_fare: "19738_26752",
            sup_cur_fare: "",
            cabb: "",
            chekb: "",
            seats: "",
            meals: "",
            chanb: "",
            ftyp: "Regular - R",
            markupnames: [],
            ttf: 26752,
          },
          sup: "4",
          airlineList: "6E",
          servicefee: [],
        },
        {
          srcorigns: [
            {
              tid: "fFHed6Nf2KTNiu3t_1",
              ref: 1,
              office_id: "",
              sdur: "16:35",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-07T19:40:00",
                  ad: "2023-06-08T02:00:00",
                  rbd: "",
                  fn: "1308",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "3" },
                  rph: "",
                  jsell:
                    "6E~1308~ ~~DOH~06/07/2023 19:40~DEL~06/08/2023 02:00~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-06-08T10:10:00",
                  ad: "2023-06-08T12:15:00",
                  rbd: "",
                  fn: "2005",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "2" },
                  rph: "",
                  jsell:
                    "6E~2005~ ~~DEL~06/08/2023 10:10~HYD~06/08/2023 12:15~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~Q~ ~6E~QCINT~6200~~0~65~~X^0~Q~ ~6E~QCINT~6200~~0~65~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 10665,
                      fbc: "QCINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "10665_14201",
                      tax: [
                        { OT: 3536 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 14201,
                      psup_fare: "10665_14201",
                    },
                    {
                      bf: 10665,
                      fbc: "QCINT",
                      ptype: "CHD",
                      scur: "QAR",
                      sup_fare: "10665_14201",
                      tax: [
                        { OT: 3536 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 14201,
                      psup_fare: "10665_14201",
                    },
                    {
                      bf: 100,
                      fbc: "",
                      ptype: "INF",
                      scur: "QAR",
                      sup_fare: "100_100",
                      tax: [
                        { OT: 0 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 100,
                      psup_fare: "100_100",
                    },
                  ],
                  pr: {
                    bf: 21430,
                    tf: 28502,
                    tax: [
                      { OT: 7072 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "21430_28502",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 28502,
                  },
                  routeId: "4wgpheeafi",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~Q~ ~6E~QCUINT~6202~~0~5~~X^0~Q~ ~6E~QCUINT~6202~~0~5~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 11365,
                      fbc: "QCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "11365_14926",
                      tax: [
                        { OT: 3561 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 14926,
                      psup_fare: "11365_14926",
                    },
                    {
                      bf: 11365,
                      fbc: "QCUINT",
                      ptype: "CHD",
                      scur: "QAR",
                      sup_fare: "11365_14926",
                      tax: [
                        { OT: 3561 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 14926,
                      psup_fare: "11365_14926",
                    },
                    {
                      bf: 100,
                      fbc: "",
                      ptype: "INF",
                      scur: "QAR",
                      sup_fare: "100_100",
                      tax: [
                        { OT: 0 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 100,
                      psup_fare: "100_100",
                    },
                  ],
                  pr: {
                    bf: 22830,
                    tf: 29952,
                    tax: [
                      { OT: 7122 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "22830_29952",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 29952,
                  },
                  routeId: "otdzctpvd",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "2Md+/gTLRZs=|pmb3XiYBQAFk5e/+oNh0iipySW228P0PPoH24B4iI+TZYp8IoVbadzEKuR6MvaENzPyle9UGiPHh8zqyVJHwLs4bsluY9c4XMAnXqMSUEXPVy/zQpBI1bTDFPS4sxqZqtca/2uKVEtU=",
              stop: 1,
            },
          ],
          paxpr: [
            {
              bf: 10665,
              fbc: "QCINT",
              ptype: "ADT",
              scur: "QAR",
              sup_fare: "10665_14201",
              tax: [
                { OT: 3536 },
                { attmkp: 0 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              tf: 14201,
              psup_fare: "10665_14201",
            },
            {
              bf: 10665,
              fbc: "QCINT",
              ptype: "CHD",
              scur: "QAR",
              sup_fare: "10665_14201",
              tax: [
                { OT: 3536 },
                { attmkp: 0 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              tf: 14201,
              psup_fare: "10665_14201",
            },
            {
              bf: 100,
              fbc: "",
              ptype: "INF",
              scur: "QAR",
              sup_fare: "100_100",
              tax: [
                { OT: 0 },
                { attmkp: 0 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              tf: 100,
              psup_fare: "100_100",
            },
          ],
          pr: {
            bf: 21430,
            tf: 28502,
            tax: [
              { OT: 7072 },
              { attmkp: 0 },
              { tamkp: 0 },
              { stamkp: 0 },
              { attdisc: 0 },
              { tadisc: 0 },
              { stadisc: 0 },
            ],
            ucur: "INR",
            exchange_rate: 1,
            scur: "INR",
            sup_fare: "21430_28502",
            sup_cur_fare: "",
            cabb: "",
            chekb: "",
            seats: "",
            meals: "",
            chanb: "",
            ftyp: "Regular - R",
            markupnames: [],
            ttf: 28502,
          },
          sup: "4",
          airlineList: "6E",
          servicefee: [],
        },
        {
          srcorigns: [
            {
              tid: "Eottdm8Zyn9CEryi_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "09:40",
              re: "Non-Refundable",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "WY",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "MCT",
                  dd: "2023-06-07T19:00:00",
                  ad: "2023-06-07T21:35:00",
                  rbd: "L",
                  fn: "664",
                  eq: "7M8",
                  ac: "WY",
                  ma: "WY",
                  seat: "7",
                  oa: "WY",
                  fa: [],
                  fdur: "01:35",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
                {
                  ref: 2,
                  da: "MCT",
                  ar: "HYD",
                  dd: "2023-06-08T02:15:00",
                  ad: "2023-06-08T07:10:00",
                  rbd: "L",
                  fn: "235",
                  eq: "738",
                  ac: "WY",
                  ma: "WY",
                  seat: "7",
                  oa: "WY",
                  fa: [],
                  fdur: "03:25",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
              ],
              flightNumbers: "664/235",
              bgd: [{ qun: "30", wt: "kg" }],
            },
            {
              tid: "gFqyfTMrGWNzA7rv_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "17:05",
              re: "Non-Refundable",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "WY",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "MCT",
                  dd: "2023-06-07T19:00:00",
                  ad: "2023-06-07T21:35:00",
                  rbd: "L",
                  fn: "664",
                  eq: "7M8",
                  ac: "WY",
                  ma: "WY",
                  seat: "7",
                  oa: "WY",
                  fa: [],
                  fdur: "01:35",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
                {
                  ref: 2,
                  da: "MCT",
                  ar: "HYD",
                  dd: "2023-06-08T09:40:00",
                  ad: "2023-06-08T14:35:00",
                  rbd: "L",
                  fn: "231",
                  eq: "738",
                  ac: "WY",
                  ma: "WY",
                  seat: "7",
                  oa: "WY",
                  fa: [],
                  fdur: "03:25",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
              ],
              flightNumbers: "664/231",
              bgd: [{ qun: "30", wt: "kg" }],
            },
            {
              tid: "a0ghTAvsUoVrM2zi_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "20:50",
              re: "Non-Refundable",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "WY",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "MCT",
                  dd: "2023-06-07T07:50:00",
                  ad: "2023-06-07T10:25:00",
                  rbd: "L",
                  fn: "670",
                  eq: "7M8",
                  ac: "WY",
                  ma: "WY",
                  seat: "7",
                  oa: "WY",
                  fa: [],
                  fdur: "01:35",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
                {
                  ref: 2,
                  da: "MCT",
                  ar: "HYD",
                  dd: "2023-06-08T02:15:00",
                  ad: "2023-06-08T07:10:00",
                  rbd: "L",
                  fn: "235",
                  eq: "738",
                  ac: "WY",
                  ma: "WY",
                  seat: "7",
                  oa: "WY",
                  fa: [],
                  fdur: "03:25",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
              ],
              flightNumbers: "670/235",
              bgd: [{ qun: "30", wt: "kg" }],
            },
            {
              tid: "6EpzTIz8GixLegaN_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "28:15",
              re: "Non-Refundable",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "WY",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "MCT",
                  dd: "2023-06-07T07:50:00",
                  ad: "2023-06-07T10:25:00",
                  rbd: "L",
                  fn: "670",
                  eq: "7M8",
                  ac: "WY",
                  ma: "WY",
                  seat: "7",
                  oa: "WY",
                  fa: [],
                  fdur: "01:35",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
                {
                  ref: 2,
                  da: "MCT",
                  ar: "HYD",
                  dd: "2023-06-08T09:40:00",
                  ad: "2023-06-08T14:35:00",
                  rbd: "L",
                  fn: "231",
                  eq: "738",
                  ac: "WY",
                  ma: "WY",
                  seat: "7",
                  oa: "WY",
                  fa: [],
                  fdur: "03:25",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
              ],
              flightNumbers: "670/231",
              bgd: [{ qun: "30", wt: "kg" }],
            },
            {
              tid: "JIJ7Axxf5MVBGZ6M_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "27:20",
              re: "Non-Refundable",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "WY",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "MCT",
                  dd: "2023-06-07T01:20:00",
                  ad: "2023-06-07T03:55:00",
                  rbd: "L",
                  fn: "662",
                  eq: "7M8",
                  ac: "WY",
                  ma: "WY",
                  seat: "7",
                  oa: "WY",
                  fa: [],
                  fdur: "01:35",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
                {
                  ref: 2,
                  da: "MCT",
                  ar: "HYD",
                  dd: "2023-06-08T02:15:00",
                  ad: "2023-06-08T07:10:00",
                  rbd: "L",
                  fn: "235",
                  eq: "738",
                  ac: "WY",
                  ma: "WY",
                  seat: "7",
                  oa: "WY",
                  fa: [],
                  fdur: "03:25",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
              ],
              flightNumbers: "662/235",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          pr: {
            bf: 13552,
            tf: 39760,
            tax: [
              { OT: 26208, YQ: 0, YR: 0 },
              { dealmkp: 0 },
              { attmkp: 1232 },
              { tamkp: 0 },
              { stamkp: 0 },
              { attdisc: 0 },
              { tadisc: 0 },
              { stadisc: 0 },
            ],
            scur: "QAR",
            ucur: "INR",
            exchange_rate: 22.4,
            sup_fare: "550_1720",
            markupnames: [{ code: "MTA", name: "LaxmiAprilMarkup" }],
          },
          paxpr: [
            {
              tf: 19443.2,
              tax: [
                { YQ: 4704 },
                { YR: 2688 },
                { OT: 5152 },
                { attmkp: 627.2 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              bf: 6899.2,
              fbc: "LELOQA",
              fareType: "RP",
              pusup_fare: "280_840",
              psup_fare: "6272_18816",
              ptype: "ADT",
            },
            {
              tf: 17718.4,
              tax: [
                { YQ: 4704 },
                { YR: 2688 },
                { OT: 5152 },
                { attmkp: 470.4 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              bf: 5174.4,
              fbc: "LELOQACH",
              fareType: "RP",
              pusup_fare: "210_770",
              psup_fare: "4704_17248",
              ptype: "CHD",
            },
            {
              tf: 2598.4,
              tax: [
                { YR: 448 },
                { OT: 672 },
                { attmkp: 134.4 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              bf: 1478.4,
              fbc: "LELOQAIN",
              fareType: "RP",
              pusup_fare: "60_110",
              psup_fare: "1344_2464",
              ptype: "INF",
            },
          ],
          sup: "0",
          airlinesList: "WY",
          servicefee: [
            {
              type: "ATT",
              adt: 689.92,
              chd: 517.44,
              inf: 147.84,
              servicefee_name: "LaxmiServieApril",
              apl_on: "Per Ticket",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 1355.2,
              sup_fee: 0,
            },
            {
              type: "TA",
              adt: 0,
              chd: 0,
              inf: 0,
              apl_on: "",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 0,
              sup_fee: 1355.2,
            },
          ],
        },
        {
          srcorigns: [
            {
              tid: "VJbSuB8gplbQ2TrH_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "19:25",
              re: "Refundable Before Departure",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "UL",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "CMB",
                  dd: "2023-06-07T22:30:00",
                  ad: "2023-06-08T05:45:00",
                  rbd: "N",
                  fn: "218",
                  eq: "333",
                  ac: "UL",
                  ma: "UL",
                  seat: "9",
                  oa: "UL",
                  fa: [],
                  fdur: "04:45",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
                {
                  ref: 2,
                  da: "CMB",
                  ar: "HYD",
                  dd: "2023-06-08T18:30:00",
                  ad: "2023-06-08T20:25:00",
                  rbd: "N",
                  fn: "175",
                  eq: "32A",
                  ac: "UL",
                  ma: "UL",
                  seat: "9",
                  oa: "UL",
                  fa: [],
                  fdur: "01:55",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
              ],
              flightNumbers: "218/175",
              bgd: [{ qun: "40", wt: "kg" }],
            },
            {
              tid: "mqMdkOwhxRFmVnpW_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "26:35",
              re: "Refundable Before Departure",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "UL",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "CMB",
                  dd: "2023-06-07T22:30:00",
                  ad: "2023-06-08T05:45:00",
                  rbd: "N",
                  fn: "218",
                  eq: "333",
                  ac: "UL",
                  ma: "UL",
                  seat: "9",
                  oa: "UL",
                  fa: [],
                  fdur: "04:45",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
                {
                  ref: 2,
                  da: "CMB",
                  ar: "HYD",
                  dd: "2023-06-09T01:40:00",
                  ad: "2023-06-09T03:35:00",
                  rbd: "N",
                  fn: "1177",
                  eq: "320",
                  ac: "UL",
                  ma: "UL",
                  seat: "9",
                  oa: "UL",
                  fa: [],
                  fdur: "01:55",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
              ],
              flightNumbers: "218/1177",
              bgd: [{ qun: "40", wt: "kg" }],
            },
          ],
          pr: {
            bf: 22915.2,
            tf: 39939.2,
            tax: [
              { OT: 17024, YQ: 0, YR: 0 },
              { dealmkp: 0 },
              { attmkp: 2083.2 },
              { tamkp: 0 },
              { stamkp: 0 },
              { attdisc: 0 },
              { tadisc: 0 },
              { stadisc: 0 },
            ],
            scur: "QAR",
            ucur: "INR",
            exchange_rate: 22.4,
            sup_fare: "930_1690",
            markupnames: [{ code: "MTA", name: "LaxmiAprilMarkup" }],
          },
          paxpr: [
            {
              tf: 20339.2,
              tax: [
                { YQ: 5376 },
                { OT: 3136 },
                { attmkp: 1075.2 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              bf: 11827.2,
              fbc: "NOW1QA",
              fareType: "RP",
              pusup_fare: "480_860",
              psup_fare: "10752_19264",
              ptype: "ADT",
            },
            {
              tf: 17382.4,
              tax: [
                { YQ: 5376 },
                { OT: 3136 },
                { attmkp: 806.4 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              bf: 8870.4,
              fbc: "NOW1QACH25",
              fareType: "RP",
              pusup_fare: "360_740",
              psup_fare: "8064_16576",
              ptype: "CHD",
            },
            {
              tf: 2217.6,
              tax: [
                { OT: 0, YQ: 0, YR: 0 },
                { attmkp: 201.6 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              bf: 2217.6,
              fbc: "NOW1QAIN80",
              fareType: "RP",
              pusup_fare: "90_90",
              psup_fare: "2016_2016",
              ptype: "INF",
            },
          ],
          sup: "0",
          airlinesList: "UL",
          servicefee: [
            {
              type: "ATT",
              adt: 1182.72,
              chd: 887.04,
              inf: 221.76,
              servicefee_name: "LaxmiServieApril",
              apl_on: "Per Ticket",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 2291.52,
              sup_fee: 0,
            },
            {
              type: "TA",
              adt: 0,
              chd: 0,
              inf: 0,
              apl_on: "",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 0,
              sup_fee: 2291.52,
            },
          ],
        },
        {
          srcorigns: [
            {
              tid: "3pIQdzKXrEn6C8mk_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "22:10",
              re: "Refundable Before Departure",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "UL",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "CMB",
                  dd: "2023-06-07T19:45:00",
                  ad: "2023-06-08T03:25:00",
                  rbd: "O",
                  fn: "3012",
                  eq: "77W",
                  ac: "UL",
                  ma: "UL",
                  seat: "9",
                  oa: "QR",
                  fa: [],
                  fdur: "05:10",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
                {
                  ref: 2,
                  da: "CMB",
                  ar: "HYD",
                  dd: "2023-06-08T18:30:00",
                  ad: "2023-06-08T20:25:00",
                  rbd: "O",
                  fn: "175",
                  eq: "32A",
                  ac: "UL",
                  ma: "UL",
                  seat: "9",
                  oa: "UL",
                  fa: [],
                  fdur: "01:55",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
              ],
              flightNumbers: "3012/175",
              bgd: [{ qun: "30", wt: "kg" }],
            },
            {
              tid: "fZfHwzhLlD7HDhOb_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "29:20",
              re: "Refundable Before Departure",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "UL",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "CMB",
                  dd: "2023-06-07T19:45:00",
                  ad: "2023-06-08T03:25:00",
                  rbd: "O",
                  fn: "3012",
                  eq: "77W",
                  ac: "UL",
                  ma: "UL",
                  seat: "9",
                  oa: "QR",
                  fa: [],
                  fdur: "05:10",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
                {
                  ref: 2,
                  da: "CMB",
                  ar: "HYD",
                  dd: "2023-06-09T01:40:00",
                  ad: "2023-06-09T03:35:00",
                  rbd: "O",
                  fn: "1177",
                  eq: "320",
                  ac: "UL",
                  ma: "UL",
                  seat: "9",
                  oa: "UL",
                  fa: [],
                  fdur: "01:55",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
              ],
              flightNumbers: "3012/1177",
              bgd: [{ qun: "30", wt: "kg" }],
            },
            {
              tid: "bfpZLAw0Z4hWwUa1_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "23:30",
              re: "Refundable Before Departure",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "UL",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "CMB",
                  dd: "2023-06-07T18:25:00",
                  ad: "2023-06-08T02:05:00",
                  rbd: "O",
                  fn: "3572",
                  eq: "333",
                  ac: "UL",
                  ma: "UL",
                  seat: "9",
                  oa: "QR",
                  fa: [],
                  fdur: "05:10",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
                {
                  ref: 2,
                  da: "CMB",
                  ar: "HYD",
                  dd: "2023-06-08T18:30:00",
                  ad: "2023-06-08T20:25:00",
                  rbd: "O",
                  fn: "175",
                  eq: "32A",
                  ac: "UL",
                  ma: "UL",
                  seat: "9",
                  oa: "UL",
                  fa: [],
                  fdur: "01:55",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
              ],
              flightNumbers: "3572/175",
              bgd: [{ qun: "30", wt: "kg" }],
            },
            {
              tid: "tv4PpNXirAbuckxU_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "30:40",
              re: "Refundable Before Departure",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "UL",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "CMB",
                  dd: "2023-06-07T18:25:00",
                  ad: "2023-06-08T02:05:00",
                  rbd: "O",
                  fn: "3572",
                  eq: "333",
                  ac: "UL",
                  ma: "UL",
                  seat: "9",
                  oa: "QR",
                  fa: [],
                  fdur: "05:10",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
                {
                  ref: 2,
                  da: "CMB",
                  ar: "HYD",
                  dd: "2023-06-09T01:40:00",
                  ad: "2023-06-09T03:35:00",
                  rbd: "O",
                  fn: "1177",
                  eq: "320",
                  ac: "UL",
                  ma: "UL",
                  seat: "9",
                  oa: "UL",
                  fa: [],
                  fdur: "01:55",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
              ],
              flightNumbers: "3572/1177",
              bgd: [{ qun: "30", wt: "kg" }],
            },
            {
              tid: "PtiB3Xvres5uheBz_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "16:35",
              re: "Refundable Before Departure",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "UL",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "CMB",
                  dd: "2023-06-07T01:20:00",
                  ad: "2023-06-07T09:00:00",
                  rbd: "O",
                  fn: "3500",
                  eq: "332",
                  ac: "UL",
                  ma: "UL",
                  seat: "9",
                  oa: "QR",
                  fa: [],
                  fdur: "05:10",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
                {
                  ref: 2,
                  da: "CMB",
                  ar: "HYD",
                  dd: "2023-06-07T18:30:00",
                  ad: "2023-06-07T20:25:00",
                  rbd: "O",
                  fn: "175",
                  eq: "320",
                  ac: "UL",
                  ma: "UL",
                  seat: "9",
                  oa: "UL",
                  fa: [],
                  fdur: "01:55",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
              ],
              flightNumbers: "3500/175",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          pr: {
            bf: 32771.2,
            tf: 49795.2,
            tax: [
              { OT: 17024, YQ: 0, YR: 0 },
              { dealmkp: 0 },
              { attmkp: 2979.2 },
              { tamkp: 0 },
              { stamkp: 0 },
              { attdisc: 0 },
              { tadisc: 0 },
              { stadisc: 0 },
            ],
            scur: "QAR",
            ucur: "INR",
            exchange_rate: 22.4,
            sup_fare: "1330_2090",
            markupnames: [{ code: "MTA", name: "LaxmiAprilMarkup" }],
          },
          paxpr: [
            {
              tf: 25513.6,
              tax: [
                { YQ: 5376 },
                { OT: 3136 },
                { attmkp: 1545.6 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              bf: 17001.6,
              fbc: "OOWQQA",
              fareType: "RP",
              pusup_fare: "690_1070",
              psup_fare: "15456_23968",
              ptype: "ADT",
            },
            {
              tf: 21324.8,
              tax: [
                { YQ: 5376 },
                { OT: 3136 },
                { attmkp: 1164.8 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              bf: 12812.8,
              fbc: "OOWQQACH25",
              fareType: "RP",
              pusup_fare: "520_900",
              psup_fare: "11648_20160",
              ptype: "CHD",
            },
            {
              tf: 2956.8,
              tax: [
                { OT: 0, YQ: 0, YR: 0 },
                { attmkp: 268.8 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              bf: 2956.8,
              fbc: "OOWQQAIN80",
              fareType: "RP",
              pusup_fare: "120_120",
              psup_fare: "2688_2688",
              ptype: "INF",
            },
          ],
          sup: "0",
          airlinesList: "UL",
          servicefee: [
            {
              type: "ATT",
              adt: 1700.16,
              chd: 1281.28,
              inf: 295.68,
              servicefee_name: "LaxmiServieApril",
              apl_on: "Per Ticket",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 3277.12,
              sup_fee: 0,
            },
            {
              type: "TA",
              adt: 0,
              chd: 0,
              inf: 0,
              apl_on: "",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 0,
              sup_fee: 3277.12,
            },
          ],
        },
        {
          srcorigns: [
            {
              tid: "s4zc7Wlha32BlAes_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "08:20",
              re: "Refundable Before Departure",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-07T22:35:00",
                  ad: "2023-06-08T05:05:00",
                  rbd: "U",
                  fn: "972",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "5",
                  oa: "AI",
                  fa: [],
                  fdur: "04:00",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-06-08T07:10:00",
                  ad: "2023-06-08T09:25:00",
                  rbd: "M",
                  fn: "560",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:15",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "972/560",
              bgd: [{ qun: "30", wt: "kg" }],
            },
            {
              tid: "9lnhfLOk4DUPgnvz_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "10:45",
              re: "Refundable Before Departure",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-07T22:35:00",
                  ad: "2023-06-08T05:05:00",
                  rbd: "U",
                  fn: "972",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "5",
                  oa: "AI",
                  fa: [],
                  fdur: "04:00",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-06-08T09:45:00",
                  ad: "2023-06-08T11:50:00",
                  rbd: "M",
                  fn: "542",
                  trn: "3",
                  eq: "321",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:05",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "972/542",
              bgd: [{ qun: "30", wt: "kg" }],
            },
            {
              tid: "vFCuJLbRyS7fafbU_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "18:05",
              re: "Refundable Before Departure",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-07T22:35:00",
                  ad: "2023-06-08T05:05:00",
                  rbd: "U",
                  fn: "972",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "5",
                  oa: "AI",
                  fa: [],
                  fdur: "04:00",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-06-08T16:50:00",
                  ad: "2023-06-08T19:10:00",
                  rbd: "M",
                  fn: "544",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:20",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "972/544",
              bgd: [{ qun: "30", wt: "kg" }],
            },
            {
              tid: "MZ3ZJnSkVx3LwVpZ_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "20:40",
              re: "Refundable Before Departure",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-07T22:35:00",
                  ad: "2023-06-08T05:05:00",
                  rbd: "U",
                  fn: "972",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "5",
                  oa: "AI",
                  fa: [],
                  fdur: "04:00",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-06-08T19:25:00",
                  ad: "2023-06-08T21:45:00",
                  rbd: "M",
                  fn: "524",
                  trn: "3",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:20",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "972/524",
              bgd: [{ qun: "30", wt: "kg" }],
            },
            {
              tid: "aQfd9zXqvWdNguGP_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "22:40",
              re: "Refundable Before Departure",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-07T22:35:00",
                  ad: "2023-06-08T05:05:00",
                  rbd: "U",
                  fn: "972",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "5",
                  oa: "AI",
                  fa: [],
                  fdur: "04:00",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-06-08T21:30:00",
                  ad: "2023-06-08T23:45:00",
                  rbd: "M",
                  fn: "839",
                  trn: "3",
                  eq: "321",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:15",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "972/839",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          pr: {
            bf: 56425.6,
            tf: 64713.6,
            tax: [
              { OT: 8288, YQ: 0, YR: 0 },
              { dealmkp: 0 },
              { attmkp: 5129.6 },
              { tamkp: 0 },
              { stamkp: 0 },
              { attdisc: 0 },
              { tadisc: 0 },
              { stadisc: 0 },
            ],
            scur: "QAR",
            ucur: "INR",
            exchange_rate: 22.4,
            sup_fare: "2290_2660",
            markupnames: [{ code: "MTA", name: "LaxmiAprilMarkup" }],
          },
          paxpr: [
            {
              tf: 31897.6,
              tax: [
                { YR: 448 },
                { YR: 224 },
                { OT: 3136 },
                { attmkp: 2553.6 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              bf: 28089.6,
              fbc: "UOWDHDL",
              fareType: "RP",
              pusup_fare: "1140_1310",
              psup_fare: "25536_29344",
              ptype: "ADT",
            },
            {
              tf: 29680,
              tax: [
                { YR: 448 },
                { YR: 224 },
                { OT: 3136 },
                { attmkp: 2352 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              bf: 25872,
              fbc: "UOWDHDL",
              fareType: "RP",
              pusup_fare: "1050_1220",
              psup_fare: "23520_27328",
              ptype: "CHD",
            },
            {
              tf: 3136,
              tax: [
                { YR: 448 },
                { YR: 224 },
                { OT: 0 },
                { attmkp: 224 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              bf: 2464,
              fbc: "UOWDHDL",
              fareType: "RP",
              pusup_fare: "100_130",
              psup_fare: "2240_2912",
              ptype: "INF",
            },
          ],
          sup: "0",
          airlinesList: "AI",
          servicefee: [
            {
              type: "ATT",
              adt: 2808.96,
              chd: 2587.2,
              inf: 246.4,
              servicefee_name: "LaxmiServieApril",
              apl_on: "Per Ticket",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 5642.56,
              sup_fee: 0,
            },
            {
              type: "TA",
              adt: 0,
              chd: 0,
              inf: 0,
              apl_on: "",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 0,
              sup_fee: 5642.56,
            },
          ],
        },
        {
          srcorigns: [
            {
              tid: "bgdbTFzxORRaTxEo_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "12:40",
              re: "Refundable Before Departure",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "KU",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "KWI",
                  dd: "2023-06-07T12:55:00",
                  ad: "2023-06-07T14:30:00",
                  rbd: "H",
                  fn: "618",
                  eq: "32N",
                  ac: "KU",
                  ma: "KU",
                  seat: "9",
                  oa: "KU",
                  fa: [],
                  fdur: "01:35",
                  cl: "Economy",
                  terminal: { dep: "", arr: "4" },
                },
                {
                  ref: 2,
                  da: "KWI",
                  ar: "HYD",
                  dd: "2023-06-07T21:00:00",
                  ad: "2023-06-08T04:05:00",
                  rbd: "H",
                  fn: "373",
                  trn: "4",
                  eq: "32N",
                  ac: "KU",
                  ma: "KU",
                  seat: "9",
                  oa: "KU",
                  fa: [],
                  fdur: "04:35",
                  cl: "Economy",
                  terminal: { dep: "4", arr: "" },
                },
              ],
              flightNumbers: "618/373",
              bgd: [{ qun: "2", wt: "pieces" }],
            },
          ],
          pr: {
            bf: 45584,
            tf: 67760,
            tax: [
              { OT: 22176, YQ: 0, YR: 0 },
              { dealmkp: 0 },
              { attmkp: 4144 },
              { tamkp: 0 },
              { stamkp: 0 },
              { attdisc: 0 },
              { tadisc: 0 },
              { stadisc: 0 },
            ],
            scur: "QAR",
            ucur: "INR",
            exchange_rate: 22.4,
            sup_fare: "1850_2840",
            markupnames: [{ code: "MTA", name: "LaxmiAprilMarkup" }],
          },
          paxpr: [
            {
              tf: 31628.8,
              tax: [
                { YQ: 1792 },
                { YR: 1344 },
                { OT: 5824 },
                { attmkp: 2060.8 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              bf: 22668.8,
              fbc: "HLOWQA1",
              fareType: "RP",
              pusup_fare: "920_1320",
              psup_fare: "20608_29568",
              ptype: "ADT",
            },
            {
              tf: 27193.6,
              tax: [
                { YQ: 1792 },
                { YR: 1344 },
                { OT: 5824 },
                { attmkp: 1657.6 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              bf: 18233.6,
              fbc: "HLOWQA1",
              fareType: "RP",
              pusup_fare: "740_1140",
              psup_fare: "16576_25536",
              ptype: "CHD",
            },
            {
              tf: 8937.6,
              tax: [
                { YQ: 1792 },
                { YR: 1344 },
                { OT: 1120 },
                { attmkp: 425.6 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              bf: 4681.6,
              fbc: "HLOWQA1",
              fareType: "RP",
              pusup_fare: "190_380",
              psup_fare: "4256_8512",
              ptype: "INF",
            },
          ],
          sup: "0",
          airlinesList: "KU",
          servicefee: [
            {
              type: "ATT",
              adt: 2266.88,
              chd: 1823.36,
              inf: 468.16,
              servicefee_name: "LaxmiServieApril",
              apl_on: "Per Ticket",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 4558.4,
              sup_fee: 0,
            },
            {
              type: "TA",
              adt: 0,
              chd: 0,
              inf: 0,
              apl_on: "",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 0,
              sup_fee: 4558.4,
            },
          ],
        },
        {
          srcorigns: [
            {
              tid: "KluBKwWTUNzn4CR2_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "04:15",
              re: "Refundable Before Departure",
              stop: 0,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "QR",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "HYD",
                  dd: "2023-06-07T19:45:00",
                  ad: "2023-06-08T02:30:00",
                  rbd: "V",
                  fn: "4774",
                  eq: "320",
                  ac: "QR",
                  ma: "QR",
                  seat: "9",
                  oa: "6E",
                  fa: [],
                  fdur: "04:15",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
              ],
              flightNumbers: "4774",
              bgd: [{ qun: "30", wt: "kg" }],
            },
            {
              tid: "8BsSQorEKg5ok9nU_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "04:15",
              re: "Refundable Before Departure",
              stop: 0,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "QR",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "HYD",
                  dd: "2023-06-07T08:15:00",
                  ad: "2023-06-07T15:00:00",
                  rbd: "V",
                  fn: "4776",
                  eq: "320",
                  ac: "QR",
                  ma: "QR",
                  seat: "9",
                  oa: "6E",
                  fa: [],
                  fdur: "04:15",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
              ],
              flightNumbers: "4776",
              bgd: [{ qun: "30", wt: "kg" }],
            },
            {
              tid: "WOqW1UPUxatm1sI6_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "04:15",
              re: "Refundable Before Departure",
              stop: 0,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "QR",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "HYD",
                  dd: "2023-06-07T02:00:00",
                  ad: "2023-06-07T08:45:00",
                  rbd: "V",
                  fn: "4778",
                  eq: "320",
                  ac: "QR",
                  ma: "QR",
                  seat: "9",
                  oa: "6E",
                  fa: [],
                  fdur: "04:15",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
              ],
              flightNumbers: "4778",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          pr: {
            bf: 53715.2,
            tf: 71187.2,
            tax: [
              { OT: 17472, YQ: 0, YR: 0 },
              { dealmkp: 0 },
              { attmkp: 4883.2 },
              { tamkp: 0 },
              { stamkp: 0 },
              { attdisc: 0 },
              { tadisc: 0 },
              { stadisc: 0 },
            ],
            scur: "QAR",
            ucur: "INR",
            exchange_rate: 22.4,
            sup_fare: "2180_2960",
            markupnames: [{ code: "MTA", name: "LaxmiAprilMarkup" }],
          },
          paxpr: [
            {
              tf: 35705.6,
              tax: [
                { YQ: 3136 },
                { YR: 1344 },
                { OT: 3136 },
                { attmkp: 2553.6 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              bf: 28089.6,
              fbc: "VLQAI1SI",
              fareType: "RP",
              pusup_fare: "1140_1480",
              psup_fare: "25536_33152",
              ptype: "ADT",
            },
            {
              tf: 28806.4,
              tax: [
                { YQ: 3136 },
                { YR: 1344 },
                { OT: 3136 },
                { attmkp: 1926.4 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              bf: 21190.4,
              fbc: "VLQAI1SICH",
              fareType: "RP",
              pusup_fare: "860_1200",
              psup_fare: "19264_26880",
              ptype: "CHD",
            },
            {
              tf: 6675.2,
              tax: [
                { YQ: 896 },
                { YR: 1344 },
                { OT: 0 },
                { attmkp: 403.2 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              bf: 4435.2,
              fbc: "VLQAI1SIIN",
              fareType: "RP",
              pusup_fare: "180_280",
              psup_fare: "4032_6272",
              ptype: "INF",
            },
          ],
          sup: "0",
          airlinesList: "QR",
          servicefee: [
            {
              type: "ATT",
              adt: 2808.96,
              chd: 2119.04,
              inf: 443.52,
              servicefee_name: "LaxmiServieApril",
              apl_on: "Per Ticket",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 5371.52,
              sup_fee: 0,
            },
            {
              type: "TA",
              adt: 0,
              chd: 0,
              inf: 0,
              apl_on: "",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 0,
              sup_fee: 5371.52,
            },
          ],
        },
        {
          srcorigns: [
            {
              tid: "mnCd0nki8dnaExTa_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "04:15",
              re: "Refundable Before Departure",
              stop: 0,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "QR",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "HYD",
                  dd: "2023-06-07T19:15:00",
                  ad: "2023-06-08T02:00:00",
                  rbd: "V",
                  fn: "500",
                  eq: "320",
                  ac: "QR",
                  ma: "QR",
                  seat: "9",
                  oa: "QR",
                  fa: [],
                  fdur: "04:15",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
              ],
              flightNumbers: "500",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          pr: {
            bf: 53715.2,
            tf: 71187.2,
            tax: [
              { OT: 17472, YQ: 0, YR: 0 },
              { dealmkp: 0 },
              { attmkp: 4883.2 },
              { tamkp: 0 },
              { stamkp: 0 },
              { attdisc: 0 },
              { tadisc: 0 },
              { stadisc: 0 },
            ],
            scur: "QAR",
            ucur: "INR",
            exchange_rate: 22.4,
            sup_fare: "2180_2960",
            markupnames: [{ code: "MTA", name: "LaxmiAprilMarkup" }],
          },
          paxpr: [
            {
              tf: 35705.6,
              tax: [
                { YQ: 3136 },
                { YR: 1344 },
                { OT: 3136 },
                { attmkp: 2553.6 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              bf: 28089.6,
              fbc: "VLQAR1SI",
              fareType: "RP",
              pusup_fare: "1140_1480",
              psup_fare: "25536_33152",
              ptype: "ADT",
            },
            {
              tf: 28806.4,
              tax: [
                { YQ: 3136 },
                { YR: 1344 },
                { OT: 3136 },
                { attmkp: 1926.4 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              bf: 21190.4,
              fbc: "VLQAR1SICH",
              fareType: "RP",
              pusup_fare: "860_1200",
              psup_fare: "19264_26880",
              ptype: "CHD",
            },
            {
              tf: 6675.2,
              tax: [
                { YQ: 896 },
                { YR: 1344 },
                { OT: 0 },
                { attmkp: 403.2 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              bf: 4435.2,
              fbc: "VLQAR1SIIN",
              fareType: "RP",
              pusup_fare: "180_280",
              psup_fare: "4032_6272",
              ptype: "INF",
            },
          ],
          sup: "0",
          airlinesList: "QR",
          servicefee: [
            {
              type: "ATT",
              adt: 2808.96,
              chd: 2119.04,
              inf: 443.52,
              servicefee_name: "LaxmiServieApril",
              apl_on: "Per Ticket",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 5371.52,
              sup_fee: 0,
            },
            {
              type: "TA",
              adt: 0,
              chd: 0,
              inf: 0,
              apl_on: "",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 0,
              sup_fee: 5371.52,
            },
          ],
        },
        {
          srcorigns: [
            {
              tid: "3TfzlcoB2V1LXGk1_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "20:10",
              re: "Refundable Before Departure",
              stop: 2,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-07T22:35:00",
                  ad: "2023-06-08T05:05:00",
                  rbd: "L",
                  fn: "972",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "04:00",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "CCU",
                  dd: "2023-06-08T06:50:00",
                  ad: "2023-06-08T09:00:00",
                  rbd: "L",
                  fn: "401",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:10",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
                {
                  ref: 3,
                  da: "CCU",
                  ar: "HYD",
                  dd: "2023-06-08T19:00:00",
                  ad: "2023-06-08T21:15:00",
                  rbd: "M",
                  fn: "526",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:15",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
              ],
              flightNumbers: "972/401/526",
              bgd: [{ qun: "30", wt: "kg" }],
            },
            {
              tid: "WbBTu349z9zSz0wb_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "20:10",
              re: "Refundable Before Departure",
              stop: 2,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-07T22:35:00",
                  ad: "2023-06-08T05:05:00",
                  rbd: "L",
                  fn: "972",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "04:00",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "CCU",
                  dd: "2023-06-08T12:10:00",
                  ad: "2023-06-08T14:30:00",
                  rbd: "L",
                  fn: "767",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:20",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
                {
                  ref: 3,
                  da: "CCU",
                  ar: "HYD",
                  dd: "2023-06-08T19:00:00",
                  ad: "2023-06-08T21:15:00",
                  rbd: "M",
                  fn: "526",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:15",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
              ],
              flightNumbers: "972/767/526",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          pr: {
            bf: 63078.4,
            tf: 72710.4,
            tax: [
              { OT: 9632, YQ: 0, YR: 0 },
              { dealmkp: 0 },
              { attmkp: 5734.4 },
              { tamkp: 0 },
              { stamkp: 0 },
              { attdisc: 0 },
              { tadisc: 0 },
              { stadisc: 0 },
            ],
            scur: "QAR",
            ucur: "INR",
            exchange_rate: 22.4,
            sup_fare: "2560_2990",
            markupnames: [{ code: "MTA", name: "LaxmiAprilMarkup" }],
          },
          paxpr: [
            {
              tf: 35795.2,
              tax: [
                { YR: 672 },
                { YR: 448 },
                { OT: 3136 },
                { attmkp: 2867.2 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              bf: 31539.2,
              fbc: "LOWDHCU",
              fareType: "RP",
              pusup_fare: "1280_1470",
              psup_fare: "28672_32928",
              ptype: "ADT",
            },
            {
              tf: 32838.4,
              tax: [
                { YR: 672 },
                { YR: 448 },
                { OT: 3136 },
                { attmkp: 2598.4 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              bf: 28582.4,
              fbc: "LOWDHCU",
              fareType: "RP",
              pusup_fare: "1160_1350",
              psup_fare: "25984_30240",
              ptype: "CHD",
            },
            {
              tf: 4076.8,
              tax: [
                { YR: 672 },
                { YR: 448 },
                { OT: 0 },
                { attmkp: 268.8 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              bf: 2956.8,
              fbc: "LOWDHCU",
              fareType: "RP",
              pusup_fare: "120_170",
              psup_fare: "2688_3808",
              ptype: "INF",
            },
          ],
          sup: "0",
          airlinesList: "AI",
          servicefee: [
            {
              type: "ATT",
              adt: 3153.92,
              chd: 2858.24,
              inf: 295.68,
              servicefee_name: "LaxmiServieApril",
              apl_on: "Per Ticket",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 6307.84,
              sup_fee: 0,
            },
            {
              type: "TA",
              adt: 0,
              chd: 0,
              inf: 0,
              apl_on: "",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 0,
              sup_fee: 6307.84,
            },
          ],
        },
        {
          srcorigns: [
            {
              tid: "16XeX9z8h5XRbzWt_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "17:55",
              re: "Refundable Before Departure",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "XY",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "RUH",
                  dd: "2023-06-07T11:35:00",
                  ad: "2023-06-07T12:55:00",
                  rbd: "D",
                  fn: "224",
                  eq: "320",
                  ac: "XY",
                  ma: "XY",
                  seat: "9",
                  oa: "XY",
                  fa: [],
                  fdur: "01:20",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "RUH",
                  ar: "HYD",
                  dd: "2023-06-08T00:35:00",
                  ad: "2023-06-08T08:00:00",
                  rbd: "N",
                  fn: "325",
                  trn: "3",
                  eq: "320",
                  ac: "XY",
                  ma: "XY",
                  seat: "9",
                  oa: "XY",
                  fa: [],
                  fdur: "04:55",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "224/325",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          pr: {
            bf: 44352,
            tf: 84672,
            tax: [
              { OT: 40320, YQ: 0, YR: 0 },
              { dealmkp: 0 },
              { attmkp: 4032 },
              { tamkp: 0 },
              { stamkp: 0 },
              { attdisc: 0 },
              { tadisc: 0 },
              { stadisc: 0 },
            ],
            scur: "QAR",
            ucur: "INR",
            exchange_rate: 22.4,
            sup_fare: "1800_3600",
            markupnames: [{ code: "MTA", name: "LaxmiAprilMarkup" }],
          },
          paxpr: [
            {
              tf: 38035.2,
              tax: [
                { YQ: 11648 },
                { YR: 2240 },
                { OT: 4928 },
                { attmkp: 1747.2 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              bf: 19219.2,
              fbc: "DFAREOW",
              fareType: "RP",
              pusup_fare: "780_1620",
              psup_fare: "17472_36288",
              ptype: "ADT",
            },
            {
              tf: 38035.2,
              tax: [
                { YQ: 11648 },
                { YR: 2240 },
                { OT: 4928 },
                { attmkp: 1747.2 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              bf: 19219.2,
              fbc: "DFAREOW",
              fareType: "RP",
              pusup_fare: "780_1620",
              psup_fare: "17472_36288",
              ptype: "CHD",
            },
            {
              tf: 8601.6,
              tax: [
                { YR: 2240 },
                { OT: 448 },
                { attmkp: 537.6 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              bf: 5913.6,
              fbc: "DFAREOW",
              fareType: "RP",
              pusup_fare: "240_360",
              psup_fare: "5376_8064",
              ptype: "INF",
            },
          ],
          sup: "0",
          airlinesList: "XY",
          servicefee: [
            {
              type: "ATT",
              adt: 1921.92,
              chd: 1921.92,
              inf: 591.36,
              servicefee_name: "LaxmiServieApril",
              apl_on: "Per Ticket",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 4435.2,
              sup_fee: 0,
            },
            {
              type: "TA",
              adt: 0,
              chd: 0,
              inf: 0,
              apl_on: "",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 0,
              sup_fee: 4435.2,
            },
          ],
        },
        {
          srcorigns: [
            {
              tid: "RZ5ZRtXeaTf5MyMw_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "07:35",
              re: "Refundable Before Departure",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "EK",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DXB",
                  dd: "2023-06-07T17:00:00",
                  ad: "2023-06-07T19:15:00",
                  rbd: "W",
                  fn: "18",
                  eq: "7M8",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "9",
                  oa: "FZ",
                  fa: [],
                  fdur: "01:15",
                  cl: "Economy",
                  terminal: { dep: "", arr: "2" },
                },
                {
                  ref: 2,
                  da: "DXB",
                  ar: "HYD",
                  dd: "2023-06-07T22:00:00",
                  ad: "2023-06-08T03:05:00",
                  rbd: "M",
                  fn: "524",
                  trn: "3",
                  eq: "77W",
                  ac: "EK",
                  ma: "EK",
                  seat: "9",
                  oa: "EK",
                  fa: [],
                  fdur: "03:35",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "18/524",
              bgd: [{ qun: "30", wt: "kg" }],
            },
            {
              tid: "7GNT43W4CCpvhkIr_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "14:35",
              re: "Refundable Before Departure",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "EK",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DXB",
                  dd: "2023-06-07T10:00:00",
                  ad: "2023-06-07T12:15:00",
                  rbd: "W",
                  fn: "2",
                  eq: "7M8",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "9",
                  oa: "FZ",
                  fa: [],
                  fdur: "01:15",
                  cl: "Economy",
                  terminal: { dep: "", arr: "2" },
                },
                {
                  ref: 2,
                  da: "DXB",
                  ar: "HYD",
                  dd: "2023-06-07T22:00:00",
                  ad: "2023-06-08T03:05:00",
                  rbd: "M",
                  fn: "524",
                  trn: "3",
                  eq: "77W",
                  ac: "EK",
                  ma: "EK",
                  seat: "9",
                  oa: "EK",
                  fa: [],
                  fdur: "03:35",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "2/524",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          pr: {
            bf: 60860.8,
            tf: 86396.8,
            tax: [
              { OT: 25536, YQ: 0, YR: 0 },
              { dealmkp: 0 },
              { attmkp: 5532.8 },
              { tamkp: 0 },
              { stamkp: 0 },
              { attdisc: 0 },
              { tadisc: 0 },
              { stadisc: 0 },
            ],
            scur: "QAR",
            ucur: "INR",
            exchange_rate: 22.4,
            sup_fare: "2470_3610",
            markupnames: [{ code: "MTA", name: "LaxmiAprilMarkup" }],
          },
          paxpr: [
            {
              tf: 45539.2,
              tax: [
                { YQ: 8512 },
                { OT: 4256 },
                { attmkp: 2979.2 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              bf: 32771.2,
              fbc: "MLSOSQA1",
              fareType: "RP",
              pusup_fare: "1330_1900",
              psup_fare: "29792_42560",
              ptype: "ADT",
            },
            {
              tf: 37408,
              tax: [
                { YQ: 8512 },
                { OT: 4256 },
                { attmkp: 2240 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              bf: 24640,
              fbc: "MLSOSQA1CH",
              fareType: "RP",
              pusup_fare: "1000_1570",
              psup_fare: "22400_35168",
              ptype: "CHD",
            },
            {
              tf: 3449.6,
              tax: [
                { OT: 0, YQ: 0, YR: 0 },
                { attmkp: 313.6 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              bf: 3449.6,
              fbc: "MLSOSQA1IN",
              fareType: "RP",
              pusup_fare: "140_140",
              psup_fare: "3136_3136",
              ptype: "INF",
            },
          ],
          sup: "0",
          airlinesList: "FZ_EK",
          servicefee: [
            {
              type: "ATT",
              adt: 3277.12,
              chd: 2464,
              inf: 344.96,
              servicefee_name: "LaxmiServieApril",
              apl_on: "Per Ticket",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 6086.08,
              sup_fee: 0,
            },
            {
              type: "TA",
              adt: 0,
              chd: 0,
              inf: 0,
              apl_on: "",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 0,
              sup_fee: 6086.08,
            },
          ],
        },
        {
          srcorigns: [
            {
              tid: "iCfSRSel3m0Sfk3h_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "08:35",
              re: "Refundable Before Departure",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "QR",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "BOM",
                  dd: "2023-06-07T20:35:00",
                  ad: "2023-06-08T02:45:00",
                  rbd: "V",
                  fn: "556",
                  eq: "77W",
                  ac: "QR",
                  ma: "QR",
                  seat: "9",
                  oa: "QR",
                  fa: [],
                  fdur: "03:40",
                  cl: "Economy",
                  terminal: { dep: "", arr: "2" },
                },
                {
                  ref: 2,
                  da: "BOM",
                  ar: "HYD",
                  dd: "2023-06-08T06:15:00",
                  ad: "2023-06-08T07:40:00",
                  rbd: "Q",
                  fn: "873",
                  trn: "2",
                  eq: "320",
                  ac: "UK",
                  ma: "UK",
                  seat: "9",
                  oa: "UK",
                  fa: [],
                  fdur: "01:25",
                  cl: "Economy",
                  terminal: { dep: "2", arr: "" },
                },
              ],
              flightNumbers: "556/873",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          pr: {
            bf: 74905.6,
            tf: 92377.6,
            tax: [
              { OT: 17472, YQ: 0, YR: 0 },
              { dealmkp: 0 },
              { attmkp: 6809.6 },
              { tamkp: 0 },
              { stamkp: 0 },
              { attdisc: 0 },
              { tadisc: 0 },
              { stadisc: 0 },
            ],
            scur: "QAR",
            ucur: "INR",
            exchange_rate: 22.4,
            sup_fare: "3040_3820",
            markupnames: [{ code: "MTA", name: "LaxmiAprilMarkup" }],
          },
          paxpr: [
            {
              tf: 47040,
              tax: [
                { YQ: 3136 },
                { YR: 1344 },
                { OT: 3136 },
                { attmkp: 3584 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              bf: 39424,
              fbc: "VLQAR1SI",
              fareType: "RP",
              pusup_fare: "1600_1940",
              psup_fare: "35840_43456",
              ptype: "ADT",
            },
            {
              tf: 37184,
              tax: [
                { YQ: 3136 },
                { YR: 1344 },
                { OT: 3136 },
                { attmkp: 2688 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              bf: 29568,
              fbc: "VLQAR1SICH",
              fareType: "RP",
              pusup_fare: "1200_1540",
              psup_fare: "26880_34496",
              ptype: "CHD",
            },
            {
              tf: 8153.6,
              tax: [
                { YQ: 896 },
                { YR: 1344 },
                { OT: 0 },
                { attmkp: 537.6 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              bf: 5913.6,
              fbc: "VLQAR1SIIN",
              fareType: "RP",
              pusup_fare: "240_340",
              psup_fare: "5376_7616",
              ptype: "INF",
            },
          ],
          sup: "0",
          airlinesList: "QR_UK",
          servicefee: [
            {
              type: "ATT",
              adt: 3942.4,
              chd: 2956.8,
              inf: 591.36,
              servicefee_name: "LaxmiServieApril",
              apl_on: "Per Ticket",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 7490.56,
              sup_fee: 0,
            },
            {
              type: "TA",
              adt: 0,
              chd: 0,
              inf: 0,
              apl_on: "",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 0,
              sup_fee: 7490.56,
            },
          ],
        },
        {
          srcorigns: [
            {
              tid: "XsQrNzHNcPCBxVNH_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "25:55",
              re: "Refundable Before Departure",
              stop: 2,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "TK",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "IST",
                  dd: "2023-06-07T06:00:00",
                  ad: "2023-06-07T10:30:00",
                  rbd: "T",
                  fn: "783",
                  trn: "1",
                  eq: "321",
                  ac: "TK",
                  ma: "TK",
                  seat: "9",
                  oa: "TK",
                  fa: [],
                  fdur: "04:30",
                  cl: "Economy",
                  terminal: { dep: "1", arr: "" },
                },
                {
                  ref: 2,
                  da: "IST",
                  ar: "BOM",
                  dd: "2023-06-07T20:05:00",
                  ad: "2023-06-08T04:55:00",
                  rbd: "T",
                  fn: "720",
                  eq: "77W",
                  ac: "TK",
                  ma: "TK",
                  seat: "9",
                  oa: "TK",
                  fa: [],
                  fdur: "06:20",
                  cl: "Economy",
                  terminal: { dep: "", arr: "2" },
                },
                {
                  ref: 3,
                  da: "BOM",
                  ar: "HYD",
                  dd: "2023-06-08T09:00:00",
                  ad: "2023-06-08T10:25:00",
                  rbd: "T",
                  fn: "4740",
                  trn: "2",
                  eq: "320",
                  ac: "TK",
                  ma: "TK",
                  seat: "4",
                  oa: "6E",
                  fa: [],
                  fdur: "01:25",
                  cl: "Economy",
                  terminal: { dep: "2", arr: "" },
                },
              ],
              flightNumbers: "783/720/4740",
              bgd: [{ qun: "30", wt: "kg" }],
            },
            {
              tid: "2eUkhIvfdN8HlrJt_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "28:10",
              re: "Refundable Before Departure",
              stop: 2,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "TK",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "IST",
                  dd: "2023-06-07T06:00:00",
                  ad: "2023-06-07T10:30:00",
                  rbd: "T",
                  fn: "783",
                  trn: "1",
                  eq: "321",
                  ac: "TK",
                  ma: "TK",
                  seat: "9",
                  oa: "TK",
                  fa: [],
                  fdur: "04:30",
                  cl: "Economy",
                  terminal: { dep: "1", arr: "" },
                },
                {
                  ref: 2,
                  da: "IST",
                  ar: "BOM",
                  dd: "2023-06-07T20:05:00",
                  ad: "2023-06-08T04:55:00",
                  rbd: "T",
                  fn: "720",
                  eq: "77W",
                  ac: "TK",
                  ma: "TK",
                  seat: "9",
                  oa: "TK",
                  fa: [],
                  fdur: "06:20",
                  cl: "Economy",
                  terminal: { dep: "", arr: "2" },
                },
                {
                  ref: 3,
                  da: "BOM",
                  ar: "HYD",
                  dd: "2023-06-08T11:10:00",
                  ad: "2023-06-08T12:40:00",
                  rbd: "T",
                  fn: "4741",
                  trn: "2",
                  eq: "320",
                  ac: "TK",
                  ma: "TK",
                  seat: "4",
                  oa: "6E",
                  fa: [],
                  fdur: "01:30",
                  cl: "Economy",
                  terminal: { dep: "2", arr: "" },
                },
              ],
              flightNumbers: "783/720/4741",
              bgd: [{ qun: "30", wt: "kg" }],
            },
            {
              tid: "MoBTPPFgOtTZfylB_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "30:40",
              re: "Refundable Before Departure",
              stop: 2,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "TK",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "IST",
                  dd: "2023-06-07T01:15:00",
                  ad: "2023-06-07T05:45:00",
                  rbd: "T",
                  fn: "781",
                  trn: "1",
                  eq: "321",
                  ac: "TK",
                  ma: "TK",
                  seat: "9",
                  oa: "TK",
                  fa: [],
                  fdur: "04:30",
                  cl: "Economy",
                  terminal: { dep: "1", arr: "" },
                },
                {
                  ref: 2,
                  da: "IST",
                  ar: "BOM",
                  dd: "2023-06-07T20:05:00",
                  ad: "2023-06-08T04:55:00",
                  rbd: "T",
                  fn: "720",
                  eq: "77W",
                  ac: "TK",
                  ma: "TK",
                  seat: "9",
                  oa: "TK",
                  fa: [],
                  fdur: "06:20",
                  cl: "Economy",
                  terminal: { dep: "", arr: "2" },
                },
                {
                  ref: 3,
                  da: "BOM",
                  ar: "HYD",
                  dd: "2023-06-08T09:00:00",
                  ad: "2023-06-08T10:25:00",
                  rbd: "T",
                  fn: "4740",
                  trn: "2",
                  eq: "320",
                  ac: "TK",
                  ma: "TK",
                  seat: "4",
                  oa: "6E",
                  fa: [],
                  fdur: "01:25",
                  cl: "Economy",
                  terminal: { dep: "2", arr: "" },
                },
              ],
              flightNumbers: "781/720/4740",
              bgd: [{ qun: "30", wt: "kg" }],
            },
            {
              tid: "HFIO30w97JaTtFVt_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "32:55",
              re: "Refundable Before Departure",
              stop: 2,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "TK",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "IST",
                  dd: "2023-06-07T01:15:00",
                  ad: "2023-06-07T05:45:00",
                  rbd: "T",
                  fn: "781",
                  trn: "1",
                  eq: "321",
                  ac: "TK",
                  ma: "TK",
                  seat: "9",
                  oa: "TK",
                  fa: [],
                  fdur: "04:30",
                  cl: "Economy",
                  terminal: { dep: "1", arr: "" },
                },
                {
                  ref: 2,
                  da: "IST",
                  ar: "BOM",
                  dd: "2023-06-07T20:05:00",
                  ad: "2023-06-08T04:55:00",
                  rbd: "T",
                  fn: "720",
                  eq: "77W",
                  ac: "TK",
                  ma: "TK",
                  seat: "9",
                  oa: "TK",
                  fa: [],
                  fdur: "06:20",
                  cl: "Economy",
                  terminal: { dep: "", arr: "2" },
                },
                {
                  ref: 3,
                  da: "BOM",
                  ar: "HYD",
                  dd: "2023-06-08T11:10:00",
                  ad: "2023-06-08T12:40:00",
                  rbd: "T",
                  fn: "4741",
                  trn: "2",
                  eq: "320",
                  ac: "TK",
                  ma: "TK",
                  seat: "4",
                  oa: "6E",
                  fa: [],
                  fdur: "01:30",
                  cl: "Economy",
                  terminal: { dep: "2", arr: "" },
                },
              ],
              flightNumbers: "781/720/4741",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          pr: {
            bf: 85747.2,
            tf: 120243.2,
            tax: [
              { OT: 34496, YQ: 0, YR: 0 },
              { dealmkp: 0 },
              { attmkp: 7795.2 },
              { tamkp: 0 },
              { stamkp: 0 },
              { attdisc: 0 },
              { tadisc: 0 },
              { stadisc: 0 },
            ],
            scur: "QAR",
            ucur: "INR",
            exchange_rate: 22.4,
            sup_fare: "3480_5020",
            markupnames: [{ code: "MTA", name: "LaxmiAprilMarkup" }],
          },
          paxpr: [
            {
              tf: 63571.2,
              tax: [
                { YR: 13440 },
                { OT: 3808 },
                { attmkp: 4211.2 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              bf: 46323.2,
              fbc: "TLS2PXOW",
              fareType: "RP",
              pusup_fare: "1880_2650",
              psup_fare: "42112_59360",
              ptype: "ADT",
            },
            {
              tf: 51990.4,
              tax: [
                { YR: 13440 },
                { OT: 3808 },
                { attmkp: 3158.4 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              bf: 34742.4,
              fbc: "TLS2PXOWCH",
              fareType: "RP",
              pusup_fare: "1410_2180",
              psup_fare: "31584_48832",
              ptype: "CHD",
            },
            {
              tf: 4681.6,
              tax: [
                { OT: 0, YQ: 0, YR: 0 },
                { attmkp: 425.6 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              bf: 4681.6,
              fbc: "TLS2PXOWIN",
              fareType: "RP",
              pusup_fare: "190_190",
              psup_fare: "4256_4256",
              ptype: "INF",
            },
          ],
          sup: "0",
          airlinesList: "TK",
          servicefee: [
            {
              type: "ATT",
              adt: 4632.32,
              chd: 3474.24,
              inf: 468.16,
              servicefee_name: "LaxmiServieApril",
              apl_on: "Per Ticket",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 8574.72,
              sup_fee: 0,
            },
            {
              type: "TA",
              adt: 0,
              chd: 0,
              inf: 0,
              apl_on: "",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 0,
              sup_fee: 8574.72,
            },
          ],
        },
        {
          srcorigns: [
            {
              tid: "449FDTBBc0ZZE1Se_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "25:55",
              re: "Refundable Before Departure",
              stop: 2,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "TK",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "IST",
                  dd: "2023-06-07T06:00:00",
                  ad: "2023-06-07T10:30:00",
                  rbd: "T",
                  fn: "783",
                  trn: "1",
                  eq: "321",
                  ac: "TK",
                  ma: "TK",
                  seat: "9",
                  oa: "TK",
                  fa: [],
                  fdur: "04:30",
                  cl: "Economy",
                  terminal: { dep: "1", arr: "" },
                },
                {
                  ref: 2,
                  da: "IST",
                  ar: "BOM",
                  dd: "2023-06-07T20:30:00",
                  ad: "2023-06-08T05:10:00",
                  rbd: "T",
                  fn: "4750",
                  eq: "321",
                  ac: "TK",
                  ma: "TK",
                  seat: "4",
                  oa: "6E",
                  fa: [],
                  fdur: "06:10",
                  cl: "Economy",
                  terminal: { dep: "", arr: "2" },
                },
                {
                  ref: 3,
                  da: "BOM",
                  ar: "HYD",
                  dd: "2023-06-08T09:00:00",
                  ad: "2023-06-08T10:25:00",
                  rbd: "T",
                  fn: "4740",
                  trn: "2",
                  eq: "320",
                  ac: "TK",
                  ma: "TK",
                  seat: "4",
                  oa: "6E",
                  fa: [],
                  fdur: "01:25",
                  cl: "Economy",
                  terminal: { dep: "2", arr: "" },
                },
              ],
              flightNumbers: "783/4750/4740",
              bgd: [{ qun: "30", wt: "kg" }],
            },
            {
              tid: "Tp1PSzKW4PHHTJ8l_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "28:10",
              re: "Refundable Before Departure",
              stop: 2,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "TK",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "IST",
                  dd: "2023-06-07T06:00:00",
                  ad: "2023-06-07T10:30:00",
                  rbd: "T",
                  fn: "783",
                  trn: "1",
                  eq: "321",
                  ac: "TK",
                  ma: "TK",
                  seat: "9",
                  oa: "TK",
                  fa: [],
                  fdur: "04:30",
                  cl: "Economy",
                  terminal: { dep: "1", arr: "" },
                },
                {
                  ref: 2,
                  da: "IST",
                  ar: "BOM",
                  dd: "2023-06-07T20:30:00",
                  ad: "2023-06-08T05:10:00",
                  rbd: "T",
                  fn: "4750",
                  eq: "321",
                  ac: "TK",
                  ma: "TK",
                  seat: "4",
                  oa: "6E",
                  fa: [],
                  fdur: "06:10",
                  cl: "Economy",
                  terminal: { dep: "", arr: "2" },
                },
                {
                  ref: 3,
                  da: "BOM",
                  ar: "HYD",
                  dd: "2023-06-08T11:10:00",
                  ad: "2023-06-08T12:40:00",
                  rbd: "T",
                  fn: "4741",
                  trn: "2",
                  eq: "320",
                  ac: "TK",
                  ma: "TK",
                  seat: "4",
                  oa: "6E",
                  fa: [],
                  fdur: "01:30",
                  cl: "Economy",
                  terminal: { dep: "2", arr: "" },
                },
              ],
              flightNumbers: "783/4750/4741",
              bgd: [{ qun: "30", wt: "kg" }],
            },
            {
              tid: "MOS4VPDTkRaNKfaW_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "30:40",
              re: "Refundable Before Departure",
              stop: 2,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "TK",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "IST",
                  dd: "2023-06-07T01:15:00",
                  ad: "2023-06-07T05:45:00",
                  rbd: "T",
                  fn: "781",
                  trn: "1",
                  eq: "321",
                  ac: "TK",
                  ma: "TK",
                  seat: "9",
                  oa: "TK",
                  fa: [],
                  fdur: "04:30",
                  cl: "Economy",
                  terminal: { dep: "1", arr: "" },
                },
                {
                  ref: 2,
                  da: "IST",
                  ar: "BOM",
                  dd: "2023-06-07T20:30:00",
                  ad: "2023-06-08T05:10:00",
                  rbd: "T",
                  fn: "4750",
                  eq: "321",
                  ac: "TK",
                  ma: "TK",
                  seat: "4",
                  oa: "6E",
                  fa: [],
                  fdur: "06:10",
                  cl: "Economy",
                  terminal: { dep: "", arr: "2" },
                },
                {
                  ref: 3,
                  da: "BOM",
                  ar: "HYD",
                  dd: "2023-06-08T09:00:00",
                  ad: "2023-06-08T10:25:00",
                  rbd: "T",
                  fn: "4740",
                  trn: "2",
                  eq: "320",
                  ac: "TK",
                  ma: "TK",
                  seat: "4",
                  oa: "6E",
                  fa: [],
                  fdur: "01:25",
                  cl: "Economy",
                  terminal: { dep: "2", arr: "" },
                },
              ],
              flightNumbers: "781/4750/4740",
              bgd: [{ qun: "30", wt: "kg" }],
            },
            {
              tid: "Nh14tbW6FDDvw0uS_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "32:55",
              re: "Refundable Before Departure",
              stop: 2,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "TK",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "IST",
                  dd: "2023-06-07T01:15:00",
                  ad: "2023-06-07T05:45:00",
                  rbd: "T",
                  fn: "781",
                  trn: "1",
                  eq: "321",
                  ac: "TK",
                  ma: "TK",
                  seat: "9",
                  oa: "TK",
                  fa: [],
                  fdur: "04:30",
                  cl: "Economy",
                  terminal: { dep: "1", arr: "" },
                },
                {
                  ref: 2,
                  da: "IST",
                  ar: "BOM",
                  dd: "2023-06-07T20:30:00",
                  ad: "2023-06-08T05:10:00",
                  rbd: "T",
                  fn: "4750",
                  eq: "321",
                  ac: "TK",
                  ma: "TK",
                  seat: "4",
                  oa: "6E",
                  fa: [],
                  fdur: "06:10",
                  cl: "Economy",
                  terminal: { dep: "", arr: "2" },
                },
                {
                  ref: 3,
                  da: "BOM",
                  ar: "HYD",
                  dd: "2023-06-08T11:10:00",
                  ad: "2023-06-08T12:40:00",
                  rbd: "T",
                  fn: "4741",
                  trn: "2",
                  eq: "320",
                  ac: "TK",
                  ma: "TK",
                  seat: "4",
                  oa: "6E",
                  fa: [],
                  fdur: "01:30",
                  cl: "Economy",
                  terminal: { dep: "2", arr: "" },
                },
              ],
              flightNumbers: "781/4750/4741",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          pr: {
            bf: 85747.2,
            tf: 120243.2,
            tax: [
              { OT: 34496, YQ: 0, YR: 0 },
              { dealmkp: 0 },
              { attmkp: 7795.2 },
              { tamkp: 0 },
              { stamkp: 0 },
              { attdisc: 0 },
              { tadisc: 0 },
              { stadisc: 0 },
            ],
            scur: "QAR",
            ucur: "INR",
            exchange_rate: 22.4,
            sup_fare: "3480_5020",
            markupnames: [{ code: "MTA", name: "LaxmiAprilMarkup" }],
          },
          paxpr: [
            {
              tf: 63571.2,
              tax: [
                { YR: 13440 },
                { OT: 3808 },
                { attmkp: 4211.2 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              bf: 46323.2,
              fbc: "TLS2PXOW",
              fareType: "RP",
              pusup_fare: "1880_2650",
              psup_fare: "42112_59360",
              ptype: "ADT",
            },
            {
              tf: 51990.4,
              tax: [
                { YR: 13440 },
                { OT: 3808 },
                { attmkp: 3158.4 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              bf: 34742.4,
              fbc: "TLS2PXOWCH",
              fareType: "RP",
              pusup_fare: "1410_2180",
              psup_fare: "31584_48832",
              ptype: "CHD",
            },
            {
              tf: 4681.6,
              tax: [
                { OT: 0, YQ: 0, YR: 0 },
                { attmkp: 425.6 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              bf: 4681.6,
              fbc: "TLS2PXOWIN",
              fareType: "RP",
              pusup_fare: "190_190",
              psup_fare: "4256_4256",
              ptype: "INF",
            },
          ],
          sup: "0",
          airlinesList: "TK",
          servicefee: [
            {
              type: "ATT",
              adt: 4632.32,
              chd: 3474.24,
              inf: 468.16,
              servicefee_name: "LaxmiServieApril",
              apl_on: "Per Ticket",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 8574.72,
              sup_fee: 0,
            },
            {
              type: "TA",
              adt: 0,
              chd: 0,
              inf: 0,
              apl_on: "",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 0,
              sup_fee: 8574.72,
            },
          ],
        },
        {
          srcorigns: [
            {
              tid: "oViV1kJUfPVeLOst_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "18:50",
              re: "Refundable Before Departure",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "MH",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "KUL",
                  dd: "2023-06-07T01:50:00",
                  ad: "2023-06-07T14:40:00",
                  rbd: "N",
                  fn: "161",
                  eq: "333",
                  ac: "MH",
                  ma: "MH",
                  seat: "9",
                  oa: "MH",
                  fa: [],
                  fdur: "07:50",
                  cl: "Economy",
                  terminal: { dep: "", arr: "1" },
                },
                {
                  ref: 2,
                  da: "KUL",
                  ar: "HYD",
                  dd: "2023-06-07T21:30:00",
                  ad: "2023-06-07T23:10:00",
                  rbd: "M",
                  fn: "198",
                  trn: "1",
                  eq: "738",
                  ac: "MH",
                  ma: "MH",
                  seat: "9",
                  oa: "MH",
                  fa: [],
                  fdur: "04:10",
                  cl: "Economy",
                  terminal: { dep: "1", arr: "" },
                },
              ],
              flightNumbers: "161/198",
              bgd: [{ qun: "35", wt: "kg" }],
            },
          ],
          pr: {
            bf: 194902.4,
            tf: 201174.4,
            tax: [
              { OT: 6272, YQ: 0, YR: 0 },
              { dealmkp: 0 },
              { attmkp: 17718.4 },
              { tamkp: 0 },
              { stamkp: 0 },
              { attdisc: 0 },
              { tadisc: 0 },
              { stadisc: 0 },
            ],
            scur: "QAR",
            ucur: "INR",
            exchange_rate: 22.4,
            sup_fare: "7910_8190",
            markupnames: [{ code: "MTA", name: "LaxmiAprilMarkup" }],
          },
          paxpr: [
            {
              tf: 107856,
              tax: [
                { OT: 3136 },
                { attmkp: 9520 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              bf: 104720,
              fbc: "NFLOWQA",
              fareType: "RP",
              pusup_fare: "4250_4390",
              psup_fare: "95200_98336",
              ptype: "ADT",
            },
            {
              tf: 81984,
              tax: [
                { OT: 3136 },
                { attmkp: 7168 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              bf: 78848,
              fbc: "NFLOWQACH",
              fareType: "RP",
              pusup_fare: "3200_3340",
              psup_fare: "71680_74816",
              ptype: "CHD",
            },
            {
              tf: 11334.4,
              tax: [
                { OT: 0, YQ: 0, YR: 0 },
                { attmkp: 1030.4 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              bf: 11334.4,
              fbc: "NFLOWQAIN",
              fareType: "RP",
              pusup_fare: "460_460",
              psup_fare: "10304_10304",
              ptype: "INF",
            },
          ],
          sup: "0",
          airlinesList: "MH",
          servicefee: [
            {
              type: "ATT",
              adt: 10472,
              chd: 7884.8,
              inf: 1133.44,
              servicefee_name: "LaxmiServieApril",
              apl_on: "Per Ticket",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 19490.24,
              sup_fee: 0,
            },
            {
              type: "TA",
              adt: 0,
              chd: 0,
              inf: 0,
              apl_on: "",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 0,
              sup_fee: 19490.24,
            },
          ],
        },
        {
          srcorigns: [
            {
              tid: "Snmnv6uABzSmGgGg_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "25:05",
              re: "Refundable Before Departure",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "MH",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "KUL",
                  dd: "2023-06-07T19:35:00",
                  ad: "2023-06-08T08:25:00",
                  rbd: "N",
                  fn: "9051",
                  eq: "77W",
                  ac: "MH",
                  ma: "MH",
                  seat: "4",
                  oa: "QR",
                  fa: [],
                  fdur: "07:50",
                  cl: "Economy",
                  terminal: { dep: "", arr: "1" },
                },
                {
                  ref: 2,
                  da: "KUL",
                  ar: "HYD",
                  dd: "2023-06-08T21:30:00",
                  ad: "2023-06-08T23:10:00",
                  rbd: "M",
                  fn: "198",
                  trn: "1",
                  eq: "738",
                  ac: "MH",
                  ma: "MH",
                  seat: "9",
                  oa: "MH",
                  fa: [],
                  fdur: "04:10",
                  cl: "Economy",
                  terminal: { dep: "1", arr: "" },
                },
              ],
              flightNumbers: "9051/198",
              bgd: [{ qun: "35", wt: "kg" }],
            },
          ],
          pr: {
            bf: 194902.4,
            tf: 229846.4,
            tax: [
              { OT: 34944, YQ: 0, YR: 0 },
              { dealmkp: 0 },
              { attmkp: 17718.4 },
              { tamkp: 0 },
              { stamkp: 0 },
              { attdisc: 0 },
              { tadisc: 0 },
              { stadisc: 0 },
            ],
            scur: "QAR",
            ucur: "INR",
            exchange_rate: 22.4,
            sup_fare: "7910_9470",
            markupnames: [{ code: "MTA", name: "LaxmiAprilMarkup" }],
          },
          paxpr: [
            {
              tf: 122192,
              tax: [
                { YQ: 12320 },
                { OT: 5152 },
                { attmkp: 9520 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              bf: 104720,
              fbc: "NFLOWQA",
              fareType: "RP",
              pusup_fare: "4250_5030",
              psup_fare: "95200_112672",
              ptype: "ADT",
            },
            {
              tf: 96320,
              tax: [
                { YQ: 12320 },
                { OT: 5152 },
                { attmkp: 7168 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              bf: 78848,
              fbc: "NFLOWQACH",
              fareType: "RP",
              pusup_fare: "3200_3980",
              psup_fare: "71680_89152",
              ptype: "CHD",
            },
            {
              tf: 11334.4,
              tax: [
                { OT: 0, YQ: 0, YR: 0 },
                { attmkp: 1030.4 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              bf: 11334.4,
              fbc: "NFLOWQAIN",
              fareType: "RP",
              pusup_fare: "460_460",
              psup_fare: "10304_10304",
              ptype: "INF",
            },
          ],
          sup: "0",
          airlinesList: "MH",
          servicefee: [
            {
              type: "ATT",
              adt: 10472,
              chd: 7884.8,
              inf: 1133.44,
              servicefee_name: "LaxmiServieApril",
              apl_on: "Per Ticket",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 19490.24,
              sup_fee: 0,
            },
            {
              type: "TA",
              adt: 0,
              chd: 0,
              inf: 0,
              apl_on: "",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 0,
              sup_fee: 19490.24,
            },
          ],
        },
        {
          srcorigns: [
            {
              tid: "Vi7hl9lPM7h09U9n_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "08:55",
              re: "Refundable Before Departure",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "EK",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DXB",
                  dd: "2023-06-07T21:00:00",
                  ad: "2023-06-07T23:15:00",
                  rbd: "Y",
                  fn: "6",
                  eq: "7M8",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "9",
                  oa: "FZ",
                  fa: [],
                  fdur: "01:15",
                  cl: "Economy",
                  terminal: { dep: "", arr: "2" },
                },
                {
                  ref: 2,
                  da: "DXB",
                  ar: "HYD",
                  dd: "2023-06-08T03:10:00",
                  ad: "2023-06-08T08:25:00",
                  rbd: "Y",
                  fn: "526",
                  trn: "3",
                  eq: "77W",
                  ac: "EK",
                  ma: "EK",
                  seat: "9",
                  oa: "EK",
                  fa: [],
                  fdur: "03:45",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "6/526",
              bgd: [{ qun: "35", wt: "kg" }],
            },
            {
              tid: "UECb8FXTb0cG5x0i_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "07:45",
              re: "Refundable Before Departure",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "EK",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DXB",
                  dd: "2023-06-07T10:00:00",
                  ad: "2023-06-07T12:15:00",
                  rbd: "Y",
                  fn: "2",
                  eq: "7M8",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "9",
                  oa: "FZ",
                  fa: [],
                  fdur: "01:15",
                  cl: "Economy",
                  terminal: { dep: "", arr: "2" },
                },
                {
                  ref: 2,
                  da: "DXB",
                  ar: "HYD",
                  dd: "2023-06-07T15:05:00",
                  ad: "2023-06-07T20:15:00",
                  rbd: "Y",
                  fn: "528",
                  trn: "3",
                  eq: "77W",
                  ac: "EK",
                  ma: "EK",
                  seat: "9",
                  oa: "EK",
                  fa: [],
                  fdur: "03:40",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "2/528",
              bgd: [{ qun: "35", wt: "kg" }],
            },
          ],
          pr: {
            bf: 227427.2,
            tf: 252963.2,
            tax: [
              { OT: 25536, YQ: 0, YR: 0 },
              { dealmkp: 0 },
              { attmkp: 20675.2 },
              { tamkp: 0 },
              { stamkp: 0 },
              { attdisc: 0 },
              { tadisc: 0 },
              { stadisc: 0 },
            ],
            scur: "QAR",
            ucur: "INR",
            exchange_rate: 22.4,
            sup_fare: "9230_10370",
            markupnames: [{ code: "MTA", name: "LaxmiAprilMarkup" }],
          },
          paxpr: [
            {
              tf: 135721.6,
              tax: [
                { YQ: 8512 },
                { OT: 4256 },
                { attmkp: 11177.6 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              bf: 122953.6,
              fbc: "YLOWFQA1",
              fareType: "RP",
              pusup_fare: "4990_5560",
              psup_fare: "111776_124544",
              ptype: "ADT",
            },
            {
              tf: 104921.6,
              tax: [
                { YQ: 8512 },
                { OT: 4256 },
                { attmkp: 8377.6 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              bf: 92153.6,
              fbc: "YLOWFQA1CH",
              fareType: "RP",
              pusup_fare: "3740_4310",
              psup_fare: "83776_96544",
              ptype: "CHD",
            },
            {
              tf: 12320,
              tax: [
                { OT: 0, YQ: 0, YR: 0 },
                { attmkp: 1120 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              bf: 12320,
              fbc: "YLOWFQA1IN",
              fareType: "RP",
              pusup_fare: "500_500",
              psup_fare: "11200_11200",
              ptype: "INF",
            },
          ],
          sup: "0",
          airlinesList: "FZ_EK",
          servicefee: [
            {
              type: "ATT",
              adt: 12295.36,
              chd: 9215.36,
              inf: 1232,
              servicefee_name: "LaxmiServieApril",
              apl_on: "Per Ticket",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 22742.72,
              sup_fee: 0,
            },
            {
              type: "TA",
              adt: 0,
              chd: 0,
              inf: 0,
              apl_on: "",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 0,
              sup_fee: 22742.72,
            },
          ],
        },
      ],
      mappings: [
        {
          airportCode: "CCU",
          airportName: "Netaji Subhas Chandra Bose International Airport",
          cityName: "Kolkata",
          countryName: "India",
          countryCode: "IN",
        },
        {
          airportCode: "HYD",
          airportName: "Rajiv Gandhi International Airport",
          cityName: "Hyderabad",
          countryName: "India",
          countryCode: "IN",
        },
        {
          airportCode: "IST",
          airportName: "Istanbul Ataturk Airport",
          cityName: "Istanbul",
          countryName: "Turkey",
          countryCode: "TR",
        },
        {
          airportCode: "KUL",
          airportName: "Kuala Lumpur International Airport",
          cityName: "Kuala Lumpur",
          countryName: "Malaysia",
          countryCode: "MY",
        },
        {
          airportCode: "KWI",
          airportName: "Kuwait International airport",
          cityName: "Kuwait City",
          countryName: "Kuwait",
          countryCode: "KW",
        },
        {
          airportCode: "BOM",
          airportName: "Chhatrapati Shivaji International Airport",
          cityName: "Mumbai (Bombay)",
          countryName: "India",
          countryCode: "IN",
        },
        {
          airportCode: "RUH",
          airportName: "King Khalid International Airport",
          cityName: "Riyadh",
          countryName: "Saudi Arabia",
          countryCode: "SA",
        },
        {
          airportCode: "MCT",
          airportName: "Muscat International Airport",
          cityName: "Muscat",
          countryName: "Oman",
          countryCode: "OM",
        },
        {
          airportCode: "CMB",
          airportName: "Bandaranayake International Airport",
          cityName: "Colombo",
          countryName: "Sri Lanka",
          countryCode: "LK",
        },
        {
          airportCode: "DEL",
          airportName: "Indira Gandhi International Airport",
          cityName: "New Delhi",
          countryName: "India",
          countryCode: "IN",
        },
        {
          airportCode: "DOH",
          airportName: "Hamad International Airport",
          cityName: "Doha",
          countryName: "Qatar",
          countryCode: "QA",
        },
        {
          airportCode: "DXB",
          airportName: "Dubai International Airport",
          cityName: "Dubai",
          countryName: "United Arab Emirates",
          countryCode: "AE",
        },
        {
          airportCode: "HYD",
          airportName: "Rajiv Gandhi International Airport",
          cityName: "Hyderabad",
          countryName: "India",
          countryCode: "IN",
        },
        {
          airportCode: "HYD",
          airportName: "Rajiv Gandhi International Airport",
          cityName: "Hyderabad",
          countryName: "India",
          countryCode: "IN",
        },
        {
          airportCode: "HYD",
          airportName: "Rajiv Gandhi International Airport",
          cityName: "Hyderabad",
          countryName: "India",
          countryCode: "IN",
        },
        {
          airportCode: "MAA",
          airportName: "Chennai International Airport",
          cityName: "Chennai",
          countryName: "India",
          countryCode: "IN",
        },
        {
          airportCode: "DEL",
          airportName: "Indira Gandhi International Airport",
          cityName: "New Delhi",
          countryName: "India",
          countryCode: "IN",
        },
        {
          airportCode: "DOH",
          airportName: "Hamad International Airport",
          cityName: "Doha",
          countryName: "Qatar",
          countryCode: "QA",
        },
        {
          airportCode: "HYD",
          airportName: "Hyderabad All",
          countryName: "India",
          countryCode: "IN",
        },
      ],
      echo: "6mnzuvdsyze",
      cha: "B2B",
      cid: "327",
      tt: "1",
      sty: "normal",
      sec: [
        {
          src: "DOH",
          des: "HYD",
          dd: "2023-06-07",
          ad: "Invalid date",
          leg: 0,
        },
      ],
      filter: {
        nerby: [],
        nerbyarr: [],
        suplrs: [
          { sup_name: "Full Service", sup_id: 0 },
          { sup_name: "LCC", sup_id: 4 },
        ],
        fstst: 21852,
        chpst: 21852,
        minprc: 21852,
        maxprc: 283521.14,
        best: 53072.32,
        stps: [
          { count: 0, prc: 21852, airline: "6E" },
          { count: 1, prc: 25804, airline: "6E" },
          { count: 2, prc: 81185.84, airline: "AI" },
        ],
        airl: [
          { count: 0, prc: 21852, airline: "6E" },
          { count: 1, prc: 41580.899999999994, airline: "WY" },
          { count: 1, prc: 43018.159999999996, airline: "UL" },
          { count: 1, prc: 72295.14, airline: "AI" },
          { count: 1, prc: 73884.84, airline: "KU" },
          { count: 0, prc: 78404.56, airline: "QR" },
          { count: 1, prc: 90631.3, airline: "XY" },
          { count: 1, prc: 94574.28, airline: "FZ" },
          { count: 2, prc: 131764.5, airline: "TK" },
          { count: 1, prc: 227362.19999999998, airline: "MH" },
        ],
      },
      cur: "INR",
      adt: 1,
      chd: 1,
      inf: 1,
      region_type: "International",
      gst: { type: "same", sgst: 9, cgst: 9, igst: 0, gst_invoice: [] },
    },
  ],
};

export const FLIGHT_RESPONSE_ROUNDTRIP = {
  status: "success",
  data: [
    {
      origns: [
        {
          srcorigns: [
            {
              tid: "cOm9NENptEkW3Ti5_1",
              ref: 1,
              office_id: "",
              sdur: "1:40",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DOH",
                  dd: "2023-06-07T04:10:00",
                  ad: "2023-06-07T05:50:00",
                  rbd: "",
                  fn: "1313",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: {},
                  rph: "",
                  jsell:
                    "6E~1313~ ~~HYD~06/07/2023 04:10~DOH~06/07/2023 05:50~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey: "0~R~ ~6E~R0INT~5000~~0~3~~X",
                  pr: {
                    bf: 6311,
                    tf: 9799,
                    tax: [
                      { OT: 3488 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "6311_9799",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 9799,
                  },
                  paxpr: [
                    {
                      bf: 6311,
                      tf: 9799,
                      tax: [
                        { OT: 3488 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "R0INT",
                      ptype: "ADT",
                      scur: "INR",
                      sup_fare: "6311_9799",
                      psup_fare: "6311_9799",
                    },
                  ],
                  routeId: "vi7m7haemi",
                  productClass: "R",
                  ruleNumber: "5000",
                  servicefee: [],
                  selected: true,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "G79e0AHvUTs=|pfN7IeMVY9crZtLzdrvIzm0oZntQ6f3741aeYn1CvdMLxX16D05jLOkRUUokspRUNBaad/QhuW2NmBov2F0uAOs5rQ6LmyiTG2kKghIPh0qz95QB/MyFDxPn3z3uu1Sj7jxXZQa3gsE=",
              stop: 0,
            },
            {
              tid: "9frzLRW0gFeTRkWy_1",
              ref: 1,
              office_id: "",
              sdur: "1:40",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DOH",
                  dd: "2023-06-07T17:05:00",
                  ad: "2023-06-07T18:45:00",
                  rbd: "",
                  fn: "1315",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: {},
                  rph: "",
                  jsell:
                    "6E~1315~ ~~HYD~06/07/2023 17:05~DOH~06/07/2023 18:45~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey: "0~R~ ~6E~R0INT~5000~~0~3~~X",
                  pr: {
                    bf: 6311,
                    tf: 9799,
                    tax: [
                      { OT: 3488 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "6311_9799",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 9799,
                  },
                  paxpr: [
                    {
                      bf: 6311,
                      tf: 9799,
                      tax: [
                        { OT: 3488 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "R0INT",
                      ptype: "ADT",
                      scur: "INR",
                      sup_fare: "6311_9799",
                      psup_fare: "6311_9799",
                    },
                  ],
                  routeId: "7c2epr89b2",
                  productClass: "R",
                  ruleNumber: "5000",
                  servicefee: [],
                  selected: true,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "G79e0AHvUTs=|pfN7IeMVY9crZtLzdrvIzm0oZntQ6f3741aeYn1CvdMLxX16D05jLOkRUUokspRUNBaad/QhuW2NmBov2F0uAOs5rQ6LmyiTG2kKghIPh0qz95QB/MyFDxPn3z3uu1Sj7jxXZQa3gsE=",
              stop: 0,
            },
            {
              tid: "2RDakFcqAE4vLxI5_1",
              ref: 1,
              office_id: "",
              sdur: "1:40",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DOH",
                  dd: "2023-06-07T21:55:00",
                  ad: "2023-06-07T23:35:00",
                  rbd: "",
                  fn: "1317",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: {},
                  rph: "",
                  jsell:
                    "6E~1317~ ~~HYD~06/07/2023 21:55~DOH~06/07/2023 23:35~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
              ],
              fareop: [
                {
                  fareKey: "0~R~ ~6E~R0INT~5000~~0~3~~X",
                  pr: {
                    bf: 6311,
                    tf: 9799,
                    tax: [
                      { OT: 3488 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "6311_9799",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 9799,
                  },
                  paxpr: [
                    {
                      bf: 6311,
                      tf: 9799,
                      tax: [
                        { OT: 3488 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "R0INT",
                      ptype: "ADT",
                      scur: "INR",
                      sup_fare: "6311_9799",
                      psup_fare: "6311_9799",
                    },
                  ],
                  routeId: "0xsfr81m9y",
                  productClass: "R",
                  ruleNumber: "5000",
                  servicefee: [],
                  selected: true,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "G79e0AHvUTs=|pfN7IeMVY9crZtLzdrvIzm0oZntQ6f3741aeYn1CvdMLxX16D05jLOkRUUokspRUNBaad/QhuW2NmBov2F0uAOs5rQ6LmyiTG2kKghIPh0qz95QB/MyFDxPn3z3uu1Sj7jxXZQa3gsE=",
              stop: 0,
            },
          ],
          destorigins: [
            {
              tid: "FXTO4TeqzxVhT6dQ_2",
              ref: 2,
              office_id: "",
              sdur: "6:45",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "HYD",
                  dd: "2023-06-09T02:00:00",
                  ad: "2023-06-09T08:45:00",
                  rbd: "",
                  fn: "1318",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: {},
                  rph: "",
                  jsell:
                    "6E~1318~ ~~DOH~06/09/2023 02:00~HYD~06/09/2023 08:45~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey: "0~R~ ~6E~R0INT~5000~~0~4~~X",
                  pr: {
                    bf: 7500,
                    tf: 10876,
                    tax: [
                      { OT: 3376 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "7500_10876",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 10876,
                  },
                  paxpr: [
                    {
                      bf: 7500,
                      tf: 10876,
                      tax: [
                        { OT: 3376 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "R0INT",
                      ptype: "ADT",
                      scur: "INR",
                      sup_fare: "7500_10876",
                      psup_fare: "7500_10876",
                    },
                  ],
                  routeId: "42ovuevjkd",
                  productClass: "R",
                  ruleNumber: "5000",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey: "0~R~ ~6E~RUINT~5003~~0~10~~X",
                  pr: {
                    bf: 8200,
                    tf: 11600,
                    tax: [
                      { OT: 3400 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "8200_11600",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 11600,
                  },
                  paxpr: [
                    {
                      bf: 8200,
                      tf: 11600,
                      tax: [
                        { OT: 3400 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "RUINT",
                      ptype: "ADT",
                      scur: "INR",
                      sup_fare: "8200_11600",
                      psup_fare: "8200_11600",
                    },
                  ],
                  routeId: "7uyglite0i",
                  productClass: "J",
                  ruleNumber: "5003",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "G79e0AHvUTs=|pfN7IeMVY9crZtLzdrvIzm0oZntQ6f3741aeYn1CvdMLxX16D05jLOkRUUokspRUNBaad/QhuW2NmBov2F0uAOs5rQ6LmyiTG2kKghIPh0qz95QB/MyFDxPn3z3uu1Sj7jxXZQa3gsE=",
              stop: 0,
            },
            {
              tid: "JTLaV9UM2HGgN4TE_2",
              ref: 2,
              office_id: "",
              sdur: "6:45",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "HYD",
                  dd: "2023-06-09T08:15:00",
                  ad: "2023-06-09T15:00:00",
                  rbd: "",
                  fn: "1314",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: {},
                  rph: "",
                  jsell:
                    "6E~1314~ ~~DOH~06/09/2023 08:15~HYD~06/09/2023 15:00~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
              ],
              fareop: [
                {
                  fareKey: "0~R~ ~6E~R0INT~5000~~0~4~~X",
                  pr: {
                    bf: 7500,
                    tf: 10876,
                    tax: [
                      { OT: 3376 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "7500_10876",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 10876,
                  },
                  paxpr: [
                    {
                      bf: 7500,
                      tf: 10876,
                      tax: [
                        { OT: 3376 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "R0INT",
                      ptype: "ADT",
                      scur: "INR",
                      sup_fare: "7500_10876",
                      psup_fare: "7500_10876",
                    },
                  ],
                  routeId: "sj1f11kloc",
                  productClass: "R",
                  ruleNumber: "5000",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey: "0~R~ ~6E~RUINT~5003~~0~10~~X",
                  pr: {
                    bf: 8200,
                    tf: 11600,
                    tax: [
                      { OT: 3400 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "8200_11600",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 11600,
                  },
                  paxpr: [
                    {
                      bf: 8200,
                      tf: 11600,
                      tax: [
                        { OT: 3400 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "RUINT",
                      ptype: "ADT",
                      scur: "INR",
                      sup_fare: "8200_11600",
                      psup_fare: "8200_11600",
                    },
                  ],
                  routeId: "x9lvl9xmjt",
                  productClass: "J",
                  ruleNumber: "5003",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "G79e0AHvUTs=|pfN7IeMVY9crZtLzdrvIzm0oZntQ6f3741aeYn1CvdMLxX16D05jLOkRUUokspRUNBaad/QhuW2NmBov2F0uAOs5rQ6LmyiTG2kKghIPh0qz95QB/MyFDxPn3z3uu1Sj7jxXZQa3gsE=",
              stop: 0,
            },
            {
              tid: "CyXxza4B1ZQ3y3mr_2",
              ref: 2,
              office_id: "",
              sdur: "6:45",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "HYD",
                  dd: "2023-06-09T19:45:00",
                  ad: "2023-06-10T02:30:00",
                  rbd: "",
                  fn: "1316",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: {},
                  rph: "",
                  jsell:
                    "6E~1316~ ~~DOH~06/09/2023 19:45~HYD~06/10/2023 02:30~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
              ],
              fareop: [
                {
                  fareKey: "0~R~ ~6E~R0INT~5000~~0~4~~X",
                  pr: {
                    bf: 7500,
                    tf: 10876,
                    tax: [
                      { OT: 3376 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "7500_10876",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 10876,
                  },
                  paxpr: [
                    {
                      bf: 7500,
                      tf: 10876,
                      tax: [
                        { OT: 3376 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "R0INT",
                      ptype: "ADT",
                      scur: "INR",
                      sup_fare: "7500_10876",
                      psup_fare: "7500_10876",
                    },
                  ],
                  routeId: "eb3cz9mmhq",
                  productClass: "R",
                  ruleNumber: "5000",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey: "0~R~ ~6E~RUINT~5003~~0~10~~X",
                  pr: {
                    bf: 8200,
                    tf: 11600,
                    tax: [
                      { OT: 3400 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "8200_11600",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 11600,
                  },
                  paxpr: [
                    {
                      bf: 8200,
                      tf: 11600,
                      tax: [
                        { OT: 3400 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "RUINT",
                      ptype: "ADT",
                      scur: "INR",
                      sup_fare: "8200_11600",
                      psup_fare: "8200_11600",
                    },
                  ],
                  routeId: "qtfhu9beihg",
                  productClass: "J",
                  ruleNumber: "5003",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "G79e0AHvUTs=|pfN7IeMVY9crZtLzdrvIzm0oZntQ6f3741aeYn1CvdMLxX16D05jLOkRUUokspRUNBaad/QhuW2NmBov2F0uAOs5rQ6LmyiTG2kKghIPh0qz95QB/MyFDxPn3z3uu1Sj7jxXZQa3gsE=",
              stop: 0,
            },
          ],
          paxpr: [
            {
              bf: 13811,
              tf: 20675,
              tax: [
                { OT: 6864 },
                { attmkp: 0 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              fbc: "R0INT",
              ptype: "ADT",
              scur: "INR",
              sup_fare: "6311_9799",
              psup_fare: "13811_20675",
            },
          ],
          pr: {
            bf: 13811,
            tf: 20675,
            tax: [
              { OT: 6864 },
              { attmkp: 0 },
              { tamkp: 0 },
              { stamkp: 0 },
              { attdisc: 0 },
              { tadisc: 0 },
              { stadisc: 0 },
            ],
            ucur: "INR",
            exchange_rate: 1,
            scur: "INR",
            sup_fare: "13811_20675",
            sup_cur_fare: "",
            cabb: "",
            chekb: "",
            seats: "",
            meals: "",
            chanb: "",
            ftyp: "Regular - R",
            markupnames: [],
            ttf: 20675,
          },
          sup: "4",
          airlineList: "6E",
          servicefee: [],
        },
        {
          srcorigns: [
            {
              tid: "dNRUxn4eP5IvEDNi_1",
              ref: 1,
              office_id: "",
              sdur: "1:40",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DOH",
                  dd: "2023-06-07T04:10:00",
                  ad: "2023-06-07T05:50:00",
                  rbd: "",
                  fn: "1313",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: {},
                  rph: "",
                  jsell:
                    "6E~1313~ ~~HYD~06/07/2023 04:10~DOH~06/07/2023 05:50~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey: "0~R~ ~6E~R0INT~5000~~0~3~~X",
                  pr: {
                    bf: 6311,
                    tf: 9799,
                    tax: [
                      { OT: 3488 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "6311_9799",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 9799,
                  },
                  paxpr: [
                    {
                      bf: 6311,
                      tf: 9799,
                      tax: [
                        { OT: 3488 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "R0INT",
                      ptype: "ADT",
                      scur: "INR",
                      sup_fare: "6311_9799",
                      psup_fare: "6311_9799",
                    },
                  ],
                  routeId: "vi7m7haemi",
                  productClass: "R",
                  ruleNumber: "5000",
                  servicefee: [],
                  selected: true,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "G79e0AHvUTs=|pfN7IeMVY9crZtLzdrvIzm0oZntQ6f3741aeYn1CvdMLxX16D05jLOkRUUokspRUNBaad/QhuW2NmBov2F0uAOs5rQ6LmyiTG2kKghIPh0qz95QB/MyFDxPn3z3uu1Sj7jxXZQa3gsE=",
              stop: 0,
            },
            {
              tid: "wGyFgVzgTl8kttpI_1",
              ref: 1,
              office_id: "",
              sdur: "1:40",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DOH",
                  dd: "2023-06-07T17:05:00",
                  ad: "2023-06-07T18:45:00",
                  rbd: "",
                  fn: "1315",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: {},
                  rph: "",
                  jsell:
                    "6E~1315~ ~~HYD~06/07/2023 17:05~DOH~06/07/2023 18:45~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey: "0~R~ ~6E~R0INT~5000~~0~3~~X",
                  pr: {
                    bf: 6311,
                    tf: 9799,
                    tax: [
                      { OT: 3488 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "6311_9799",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 9799,
                  },
                  paxpr: [
                    {
                      bf: 6311,
                      tf: 9799,
                      tax: [
                        { OT: 3488 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "R0INT",
                      ptype: "ADT",
                      scur: "INR",
                      sup_fare: "6311_9799",
                      psup_fare: "6311_9799",
                    },
                  ],
                  routeId: "7c2epr89b2",
                  productClass: "R",
                  ruleNumber: "5000",
                  servicefee: [],
                  selected: true,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "G79e0AHvUTs=|pfN7IeMVY9crZtLzdrvIzm0oZntQ6f3741aeYn1CvdMLxX16D05jLOkRUUokspRUNBaad/QhuW2NmBov2F0uAOs5rQ6LmyiTG2kKghIPh0qz95QB/MyFDxPn3z3uu1Sj7jxXZQa3gsE=",
              stop: 0,
            },
            {
              tid: "JulgHv6a0vrRVukZ_1",
              ref: 1,
              office_id: "",
              sdur: "1:40",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DOH",
                  dd: "2023-06-07T21:55:00",
                  ad: "2023-06-07T23:35:00",
                  rbd: "",
                  fn: "1317",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: {},
                  rph: "",
                  jsell:
                    "6E~1317~ ~~HYD~06/07/2023 21:55~DOH~06/07/2023 23:35~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
              ],
              fareop: [
                {
                  fareKey: "0~R~ ~6E~R0INT~5000~~0~3~~X",
                  pr: {
                    bf: 6311,
                    tf: 9799,
                    tax: [
                      { OT: 3488 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "6311_9799",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 9799,
                  },
                  paxpr: [
                    {
                      bf: 6311,
                      tf: 9799,
                      tax: [
                        { OT: 3488 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "R0INT",
                      ptype: "ADT",
                      scur: "INR",
                      sup_fare: "6311_9799",
                      psup_fare: "6311_9799",
                    },
                  ],
                  routeId: "0xsfr81m9y",
                  productClass: "R",
                  ruleNumber: "5000",
                  servicefee: [],
                  selected: true,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "G79e0AHvUTs=|pfN7IeMVY9crZtLzdrvIzm0oZntQ6f3741aeYn1CvdMLxX16D05jLOkRUUokspRUNBaad/QhuW2NmBov2F0uAOs5rQ6LmyiTG2kKghIPh0qz95QB/MyFDxPn3z3uu1Sj7jxXZQa3gsE=",
              stop: 0,
            },
          ],
          destorigins: [
            {
              tid: "f3mfNRNK4T5oGqq2_2",
              ref: 2,
              office_id: "",
              sdur: "16:15",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "MAA",
                  dd: "2023-06-09T02:00:00",
                  ad: "2023-06-09T09:05:00",
                  rbd: "",
                  fn: "1322",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "3" },
                  rph: "",
                  jsell:
                    "6E~1322~ ~~DOH~06/09/2023 02:00~MAA~06/09/2023 09:05~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
                {
                  ref: 2,
                  da: "MAA",
                  ar: "HYD",
                  dd: "2023-06-09T17:00:00",
                  ad: "2023-06-09T18:15:00",
                  rbd: "",
                  fn: " 562",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "1" },
                  rph: "",
                  jsell:
                    "6E~ 562~ ~~MAA~06/09/2023 17:00~HYD~06/09/2023 18:15~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "1~Q~ ~6E~Q0INT~5000~~0~12~~^2~L~ ~6E~LC8AP~1070~~0~7~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 9313,
                      fbc: "Q0INT/LC8AP",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "9313_12802",
                      tax: [
                        { OT: 3489 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 12802,
                      psup_fare: "9313_12802",
                    },
                  ],
                  pr: {
                    bf: 9313,
                    tf: 12802,
                    tax: [
                      { OT: 3489 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "9313_12802",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 12802,
                  },
                  routeId: "fpd1vnk5fi",
                  ruleNumber: "5000",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~R~ ~6E~RCUINT~6202~~0~4~~X^0~R~ ~6E~RCUINT~6202~~0~4~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 10519,
                      fbc: "RCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "10519_14050",
                      tax: [
                        { OT: 3531 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 14050,
                      psup_fare: "10519_14050",
                    },
                  ],
                  pr: {
                    bf: 10519,
                    tf: 14050,
                    tax: [
                      { OT: 3531 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "10519_14050",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 14050,
                  },
                  routeId: "ic81ng5kzsj",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "G79e0AHvUTs=|pfN7IeMVY9crZtLzdrvIzm0oZntQ6f3741aeYn1CvdMLxX16D05jLOkRUUokspRUNBaad/QhuW2NmBov2F0uAOs5rQ6LmyiTG2kKghIPh0qz95QB/MyFDxPn3z3uu1Sj7jxXZQa3gsE=",
              stop: 1,
            },
            {
              tid: "TAdr1iyQCCgeMuxN_2",
              ref: 2,
              office_id: "",
              sdur: "15:10",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "MAA",
                  dd: "2023-06-09T02:00:00",
                  ad: "2023-06-09T09:05:00",
                  rbd: "",
                  fn: "1322",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "3" },
                  rph: "",
                  jsell:
                    "6E~1322~ ~~DOH~06/09/2023 02:00~MAA~06/09/2023 09:05~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
                {
                  ref: 2,
                  da: "MAA",
                  ar: "HYD",
                  dd: "2023-06-09T16:00:00",
                  ad: "2023-06-09T17:10:00",
                  rbd: "",
                  fn: "6401",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "1" },
                  rph: "",
                  jsell:
                    "6E~6401~ ~~MAA~06/09/2023 16:00~HYD~06/09/2023 17:10~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "1~Q~ ~6E~Q0INT~5000~~0~12~~^2~L~ ~6E~LC8AP~1070~~0~7~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 9313,
                      fbc: "Q0INT/LC8AP",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "9313_12802",
                      tax: [
                        { OT: 3489 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 12802,
                      psup_fare: "9313_12802",
                    },
                  ],
                  pr: {
                    bf: 9313,
                    tf: 12802,
                    tax: [
                      { OT: 3489 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "9313_12802",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 12802,
                  },
                  routeId: "zemipdtmgt",
                  ruleNumber: "5000",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~R~ ~6E~RCUINT~6202~~0~4~~X^0~R~ ~6E~RCUINT~6202~~0~4~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 10519,
                      fbc: "RCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "10519_14050",
                      tax: [
                        { OT: 3531 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 14050,
                      psup_fare: "10519_14050",
                    },
                  ],
                  pr: {
                    bf: 10519,
                    tf: 14050,
                    tax: [
                      { OT: 3531 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "10519_14050",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 14050,
                  },
                  routeId: "mn2j4in5m3j",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "G79e0AHvUTs=|pfN7IeMVY9crZtLzdrvIzm0oZntQ6f3741aeYn1CvdMLxX16D05jLOkRUUokspRUNBaad/QhuW2NmBov2F0uAOs5rQ6LmyiTG2kKghIPh0qz95QB/MyFDxPn3z3uu1Sj7jxXZQa3gsE=",
              stop: 1,
            },
            {
              tid: "970TsxWZFTZVRqKq_2",
              ref: 2,
              office_id: "",
              sdur: "11:30",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "MAA",
                  dd: "2023-06-09T02:00:00",
                  ad: "2023-06-09T09:05:00",
                  rbd: "",
                  fn: "1322",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "3" },
                  rph: "",
                  jsell:
                    "6E~1322~ ~~DOH~06/09/2023 02:00~MAA~06/09/2023 09:05~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
                {
                  ref: 2,
                  da: "MAA",
                  ar: "HYD",
                  dd: "2023-06-09T12:20:00",
                  ad: "2023-06-09T13:30:00",
                  rbd: "",
                  fn: " 604",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "1" },
                  rph: "",
                  jsell:
                    "6E~ 604~ ~~MAA~06/09/2023 12:20~HYD~06/09/2023 13:30~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "1~Q~ ~6E~Q0INT~5000~~0~12~~^2~L~ ~6E~LC8AP~1070~~0~7~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 9313,
                      fbc: "Q0INT/LC8AP",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "9313_12802",
                      tax: [
                        { OT: 3489 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 12802,
                      psup_fare: "9313_12802",
                    },
                  ],
                  pr: {
                    bf: 9313,
                    tf: 12802,
                    tax: [
                      { OT: 3489 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "9313_12802",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 12802,
                  },
                  routeId: "0vpm4dffcs",
                  ruleNumber: "5000",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~R~ ~6E~RCUINT~6202~~0~4~~X^0~R~ ~6E~RCUINT~6202~~0~4~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 10519,
                      fbc: "RCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "10519_14050",
                      tax: [
                        { OT: 3531 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 14050,
                      psup_fare: "10519_14050",
                    },
                  ],
                  pr: {
                    bf: 10519,
                    tf: 14050,
                    tax: [
                      { OT: 3531 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "10519_14050",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 14050,
                  },
                  routeId: "k70wah8cimi",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "G79e0AHvUTs=|pfN7IeMVY9crZtLzdrvIzm0oZntQ6f3741aeYn1CvdMLxX16D05jLOkRUUokspRUNBaad/QhuW2NmBov2F0uAOs5rQ6LmyiTG2kKghIPh0qz95QB/MyFDxPn3z3uu1Sj7jxXZQa3gsE=",
              stop: 1,
            },
          ],
          paxpr: [
            {
              bf: 15624,
              tf: 22601,
              tax: [
                { OT: 6977 },
                { attmkp: 0 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              fbc: "R0INT",
              ptype: "ADT",
              scur: "INR",
              sup_fare: "6311_9799",
              psup_fare: "15624_22601",
            },
          ],
          pr: {
            bf: 15624,
            tf: 22601,
            tax: [
              { OT: 6977 },
              { attmkp: 0 },
              { tamkp: 0 },
              { stamkp: 0 },
              { attdisc: 0 },
              { tadisc: 0 },
              { stadisc: 0 },
            ],
            ucur: "INR",
            exchange_rate: 1,
            scur: "INR",
            sup_fare: "15624_22601",
            sup_cur_fare: "",
            cabb: "",
            chekb: "",
            seats: "",
            meals: "",
            chanb: "",
            ftyp: "Regular - R",
            markupnames: [],
            ttf: 22601,
          },
          sup: "4",
          airlineList: "6E",
          servicefee: [],
        },
        {
          srcorigns: [
            {
              tid: "Ad6Qz9TC1i5ahMIX_1",
              ref: 1,
              office_id: "",
              sdur: "9:30",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "MAA",
                  dd: "2023-06-07T14:05:00",
                  ad: "2023-06-07T15:20:00",
                  rbd: "",
                  fn: " 514",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "1" },
                  rph: "",
                  jsell:
                    "6E~ 514~ ~~HYD~06/07/2023 14:05~MAA~06/07/2023 15:20~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
                {
                  ref: 2,
                  da: "MAA",
                  ar: "DOH",
                  dd: "2023-06-07T21:30:00",
                  ad: "2023-06-07T23:35:00",
                  rbd: "",
                  fn: "1321",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "4" },
                  rph: "",
                  jsell:
                    "6E~1321~ ~~MAA~06/07/2023 21:30~DOH~06/07/2023 23:35~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~R~ ~6E~RCINT~6200~~0~66~~X^0~R~ ~6E~RCINT~6200~~0~66~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 8036,
                      fbc: "RCINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "8036_11726",
                      tax: [
                        { OT: 3690 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 11726,
                      psup_fare: "8036_11726",
                    },
                  ],
                  pr: {
                    bf: 8036,
                    tf: 11726,
                    tax: [
                      { OT: 3690 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "8036_11726",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 11726,
                  },
                  routeId: "gxpr30defql",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~R~ ~6E~RCUINT~6202~~0~5~~X^0~R~ ~6E~RCUINT~6202~~0~5~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 8736,
                      fbc: "RCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "8736_12487",
                      tax: [
                        { OT: 3751 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 12487,
                      psup_fare: "8736_12487",
                    },
                  ],
                  pr: {
                    bf: 8736,
                    tf: 12487,
                    tax: [
                      { OT: 3751 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "8736_12487",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 12487,
                  },
                  routeId: "infg1fvif4",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "G79e0AHvUTs=|pfN7IeMVY9crZtLzdrvIzm0oZntQ6f3741aeYn1CvdMLxX16D05jLOkRUUokspRUNBaad/QhuW2NmBov2F0uAOs5rQ6LmyiTG2kKghIPh0qz95QB/MyFDxPn3z3uu1Sj7jxXZQa3gsE=",
              stop: 1,
            },
            {
              tid: "dWV6RLcLdgopaSX8_1",
              ref: 1,
              office_id: "",
              sdur: "7:0",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "MAA",
                  dd: "2023-06-07T16:35:00",
                  ad: "2023-06-07T17:45:00",
                  rbd: "",
                  fn: " 668",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "1" },
                  rph: "",
                  jsell:
                    "6E~ 668~ ~~HYD~06/07/2023 16:35~MAA~06/07/2023 17:45~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
                {
                  ref: 2,
                  da: "MAA",
                  ar: "DOH",
                  dd: "2023-06-07T21:30:00",
                  ad: "2023-06-07T23:35:00",
                  rbd: "",
                  fn: "1321",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "4" },
                  rph: "",
                  jsell:
                    "6E~1321~ ~~MAA~06/07/2023 21:30~DOH~06/07/2023 23:35~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~R~ ~6E~RCINT~6200~~0~66~~X^0~R~ ~6E~RCINT~6200~~0~66~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 8036,
                      fbc: "RCINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "8036_11726",
                      tax: [
                        { OT: 3690 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 11726,
                      psup_fare: "8036_11726",
                    },
                  ],
                  pr: {
                    bf: 8036,
                    tf: 11726,
                    tax: [
                      { OT: 3690 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "8036_11726",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 11726,
                  },
                  routeId: "4fpdu4xzwq",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~R~ ~6E~RCUINT~6202~~0~5~~X^0~R~ ~6E~RCUINT~6202~~0~5~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 8736,
                      fbc: "RCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "8736_12487",
                      tax: [
                        { OT: 3751 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 12487,
                      psup_fare: "8736_12487",
                    },
                  ],
                  pr: {
                    bf: 8736,
                    tf: 12487,
                    tax: [
                      { OT: 3751 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "8736_12487",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 12487,
                  },
                  routeId: "svhkk6aht6",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "G79e0AHvUTs=|pfN7IeMVY9crZtLzdrvIzm0oZntQ6f3741aeYn1CvdMLxX16D05jLOkRUUokspRUNBaad/QhuW2NmBov2F0uAOs5rQ6LmyiTG2kKghIPh0qz95QB/MyFDxPn3z3uu1Sj7jxXZQa3gsE=",
              stop: 1,
            },
            {
              tid: "nWC1qhXTU3wN5X9d_1",
              ref: 1,
              office_id: "",
              sdur: "5:55",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "MAA",
                  dd: "2023-06-07T17:40:00",
                  ad: "2023-06-07T18:55:00",
                  rbd: "",
                  fn: " 102",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "1" },
                  rph: "",
                  jsell:
                    "6E~ 102~ ~~HYD~06/07/2023 17:40~MAA~06/07/2023 18:55~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
                {
                  ref: 2,
                  da: "MAA",
                  ar: "DOH",
                  dd: "2023-06-07T21:30:00",
                  ad: "2023-06-07T23:35:00",
                  rbd: "",
                  fn: "1321",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "4" },
                  rph: "",
                  jsell:
                    "6E~1321~ ~~MAA~06/07/2023 21:30~DOH~06/07/2023 23:35~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~R~ ~6E~RCINT~6200~~0~66~~X^0~R~ ~6E~RCINT~6200~~0~66~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 8036,
                      fbc: "RCINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "8036_11726",
                      tax: [
                        { OT: 3690 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 11726,
                      psup_fare: "8036_11726",
                    },
                  ],
                  pr: {
                    bf: 8036,
                    tf: 11726,
                    tax: [
                      { OT: 3690 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "8036_11726",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 11726,
                  },
                  routeId: "apa6a5thw2",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~R~ ~6E~RCUINT~6202~~0~5~~X^0~R~ ~6E~RCUINT~6202~~0~5~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 8736,
                      fbc: "RCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "8736_12487",
                      tax: [
                        { OT: 3751 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 12487,
                      psup_fare: "8736_12487",
                    },
                  ],
                  pr: {
                    bf: 8736,
                    tf: 12487,
                    tax: [
                      { OT: 3751 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "8736_12487",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 12487,
                  },
                  routeId: "ok6xy6rr06",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "G79e0AHvUTs=|pfN7IeMVY9crZtLzdrvIzm0oZntQ6f3741aeYn1CvdMLxX16D05jLOkRUUokspRUNBaad/QhuW2NmBov2F0uAOs5rQ6LmyiTG2kKghIPh0qz95QB/MyFDxPn3z3uu1Sj7jxXZQa3gsE=",
              stop: 1,
            },
          ],
          destorigins: [
            {
              tid: "7inl44kAAx2PrFNc_2",
              ref: 2,
              office_id: "",
              sdur: "6:45",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "HYD",
                  dd: "2023-06-09T02:00:00",
                  ad: "2023-06-09T08:45:00",
                  rbd: "",
                  fn: "1318",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: {},
                  rph: "",
                  jsell:
                    "6E~1318~ ~~DOH~06/09/2023 02:00~HYD~06/09/2023 08:45~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey: "0~R~ ~6E~R0INT~5000~~0~4~~X",
                  pr: {
                    bf: 7500,
                    tf: 10876,
                    tax: [
                      { OT: 3376 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "7500_10876",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 10876,
                  },
                  paxpr: [
                    {
                      bf: 7500,
                      tf: 10876,
                      tax: [
                        { OT: 3376 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "R0INT",
                      ptype: "ADT",
                      scur: "INR",
                      sup_fare: "7500_10876",
                      psup_fare: "7500_10876",
                    },
                  ],
                  routeId: "byalyp6dhni",
                  productClass: "R",
                  ruleNumber: "5000",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey: "0~R~ ~6E~RUINT~5003~~0~10~~X",
                  pr: {
                    bf: 8200,
                    tf: 11600,
                    tax: [
                      { OT: 3400 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "8200_11600",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 11600,
                  },
                  paxpr: [
                    {
                      bf: 8200,
                      tf: 11600,
                      tax: [
                        { OT: 3400 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "RUINT",
                      ptype: "ADT",
                      scur: "INR",
                      sup_fare: "8200_11600",
                      psup_fare: "8200_11600",
                    },
                  ],
                  routeId: "xuhronqo2q",
                  productClass: "J",
                  ruleNumber: "5003",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "G79e0AHvUTs=|pfN7IeMVY9crZtLzdrvIzm0oZntQ6f3741aeYn1CvdMLxX16D05jLOkRUUokspRUNBaad/QhuW2NmBov2F0uAOs5rQ6LmyiTG2kKghIPh0qz95QB/MyFDxPn3z3uu1Sj7jxXZQa3gsE=",
              stop: 0,
            },
            {
              tid: "tkKMUerwcWWLqAOd_2",
              ref: 2,
              office_id: "",
              sdur: "6:45",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "HYD",
                  dd: "2023-06-09T08:15:00",
                  ad: "2023-06-09T15:00:00",
                  rbd: "",
                  fn: "1314",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: {},
                  rph: "",
                  jsell:
                    "6E~1314~ ~~DOH~06/09/2023 08:15~HYD~06/09/2023 15:00~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
              ],
              fareop: [
                {
                  fareKey: "0~R~ ~6E~R0INT~5000~~0~4~~X",
                  pr: {
                    bf: 7500,
                    tf: 10876,
                    tax: [
                      { OT: 3376 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "7500_10876",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 10876,
                  },
                  paxpr: [
                    {
                      bf: 7500,
                      tf: 10876,
                      tax: [
                        { OT: 3376 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "R0INT",
                      ptype: "ADT",
                      scur: "INR",
                      sup_fare: "7500_10876",
                      psup_fare: "7500_10876",
                    },
                  ],
                  routeId: "h8cfy8cbq7",
                  productClass: "R",
                  ruleNumber: "5000",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey: "0~R~ ~6E~RUINT~5003~~0~10~~X",
                  pr: {
                    bf: 8200,
                    tf: 11600,
                    tax: [
                      { OT: 3400 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "8200_11600",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 11600,
                  },
                  paxpr: [
                    {
                      bf: 8200,
                      tf: 11600,
                      tax: [
                        { OT: 3400 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "RUINT",
                      ptype: "ADT",
                      scur: "INR",
                      sup_fare: "8200_11600",
                      psup_fare: "8200_11600",
                    },
                  ],
                  routeId: "8ofs1k6ajb",
                  productClass: "J",
                  ruleNumber: "5003",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "G79e0AHvUTs=|pfN7IeMVY9crZtLzdrvIzm0oZntQ6f3741aeYn1CvdMLxX16D05jLOkRUUokspRUNBaad/QhuW2NmBov2F0uAOs5rQ6LmyiTG2kKghIPh0qz95QB/MyFDxPn3z3uu1Sj7jxXZQa3gsE=",
              stop: 0,
            },
            {
              tid: "0wIH9J7sUCvBTXqx_2",
              ref: 2,
              office_id: "",
              sdur: "6:45",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "HYD",
                  dd: "2023-06-09T19:45:00",
                  ad: "2023-06-10T02:30:00",
                  rbd: "",
                  fn: "1316",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: {},
                  rph: "",
                  jsell:
                    "6E~1316~ ~~DOH~06/09/2023 19:45~HYD~06/10/2023 02:30~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
              ],
              fareop: [
                {
                  fareKey: "0~R~ ~6E~R0INT~5000~~0~4~~X",
                  pr: {
                    bf: 7500,
                    tf: 10876,
                    tax: [
                      { OT: 3376 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "7500_10876",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 10876,
                  },
                  paxpr: [
                    {
                      bf: 7500,
                      tf: 10876,
                      tax: [
                        { OT: 3376 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "R0INT",
                      ptype: "ADT",
                      scur: "INR",
                      sup_fare: "7500_10876",
                      psup_fare: "7500_10876",
                    },
                  ],
                  routeId: "wvxyc8lq5q",
                  productClass: "R",
                  ruleNumber: "5000",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey: "0~R~ ~6E~RUINT~5003~~0~10~~X",
                  pr: {
                    bf: 8200,
                    tf: 11600,
                    tax: [
                      { OT: 3400 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "8200_11600",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 11600,
                  },
                  paxpr: [
                    {
                      bf: 8200,
                      tf: 11600,
                      tax: [
                        { OT: 3400 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "RUINT",
                      ptype: "ADT",
                      scur: "INR",
                      sup_fare: "8200_11600",
                      psup_fare: "8200_11600",
                    },
                  ],
                  routeId: "4tmm6tr2ucj",
                  productClass: "J",
                  ruleNumber: "5003",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "G79e0AHvUTs=|pfN7IeMVY9crZtLzdrvIzm0oZntQ6f3741aeYn1CvdMLxX16D05jLOkRUUokspRUNBaad/QhuW2NmBov2F0uAOs5rQ6LmyiTG2kKghIPh0qz95QB/MyFDxPn3z3uu1Sj7jxXZQa3gsE=",
              stop: 0,
            },
          ],
          paxpr: [
            {
              bf: 15536,
              fbc: "RCINT",
              ptype: "ADT",
              scur: "QAR",
              sup_fare: "8036_11726",
              tax: [
                { OT: 7066 },
                { attmkp: 0 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              tf: 22602,
              psup_fare: "15536_22602",
            },
          ],
          pr: {
            bf: 15536,
            tf: 22602,
            tax: [
              { OT: 7066 },
              { attmkp: 0 },
              { tamkp: 0 },
              { stamkp: 0 },
              { attdisc: 0 },
              { tadisc: 0 },
              { stadisc: 0 },
            ],
            ucur: "INR",
            exchange_rate: 1,
            scur: "INR",
            sup_fare: "15536_22602",
            sup_cur_fare: "",
            cabb: "",
            chekb: "",
            seats: "",
            meals: "",
            chanb: "",
            ftyp: "Regular - R",
            markupnames: [],
            ttf: 22602,
          },
          sup: "4",
          airlineList: "6E",
          servicefee: [],
        },
        {
          srcorigns: [
            {
              tid: "mnOrLGm2sTZ08FsU_1",
              ref: 1,
              office_id: "",
              sdur: "1:40",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DOH",
                  dd: "2023-06-07T04:10:00",
                  ad: "2023-06-07T05:50:00",
                  rbd: "",
                  fn: "1313",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: {},
                  rph: "",
                  jsell:
                    "6E~1313~ ~~HYD~06/07/2023 04:10~DOH~06/07/2023 05:50~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey: "0~R~ ~6E~R0INT~5000~~0~3~~X",
                  pr: {
                    bf: 6311,
                    tf: 9799,
                    tax: [
                      { OT: 3488 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "6311_9799",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 9799,
                  },
                  paxpr: [
                    {
                      bf: 6311,
                      tf: 9799,
                      tax: [
                        { OT: 3488 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "R0INT",
                      ptype: "ADT",
                      scur: "INR",
                      sup_fare: "6311_9799",
                      psup_fare: "6311_9799",
                    },
                  ],
                  routeId: "vi7m7haemi",
                  productClass: "R",
                  ruleNumber: "5000",
                  servicefee: [],
                  selected: true,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "G79e0AHvUTs=|pfN7IeMVY9crZtLzdrvIzm0oZntQ6f3741aeYn1CvdMLxX16D05jLOkRUUokspRUNBaad/QhuW2NmBov2F0uAOs5rQ6LmyiTG2kKghIPh0qz95QB/MyFDxPn3z3uu1Sj7jxXZQa3gsE=",
              stop: 0,
            },
            {
              tid: "iIVdEQERvVphWErp_1",
              ref: 1,
              office_id: "",
              sdur: "1:40",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DOH",
                  dd: "2023-06-07T17:05:00",
                  ad: "2023-06-07T18:45:00",
                  rbd: "",
                  fn: "1315",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: {},
                  rph: "",
                  jsell:
                    "6E~1315~ ~~HYD~06/07/2023 17:05~DOH~06/07/2023 18:45~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey: "0~R~ ~6E~R0INT~5000~~0~3~~X",
                  pr: {
                    bf: 6311,
                    tf: 9799,
                    tax: [
                      { OT: 3488 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "6311_9799",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 9799,
                  },
                  paxpr: [
                    {
                      bf: 6311,
                      tf: 9799,
                      tax: [
                        { OT: 3488 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "R0INT",
                      ptype: "ADT",
                      scur: "INR",
                      sup_fare: "6311_9799",
                      psup_fare: "6311_9799",
                    },
                  ],
                  routeId: "7c2epr89b2",
                  productClass: "R",
                  ruleNumber: "5000",
                  servicefee: [],
                  selected: true,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "G79e0AHvUTs=|pfN7IeMVY9crZtLzdrvIzm0oZntQ6f3741aeYn1CvdMLxX16D05jLOkRUUokspRUNBaad/QhuW2NmBov2F0uAOs5rQ6LmyiTG2kKghIPh0qz95QB/MyFDxPn3z3uu1Sj7jxXZQa3gsE=",
              stop: 0,
            },
            {
              tid: "GghNg1k2vDedh7bf_1",
              ref: 1,
              office_id: "",
              sdur: "1:40",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DOH",
                  dd: "2023-06-07T21:55:00",
                  ad: "2023-06-07T23:35:00",
                  rbd: "",
                  fn: "1317",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: {},
                  rph: "",
                  jsell:
                    "6E~1317~ ~~HYD~06/07/2023 21:55~DOH~06/07/2023 23:35~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
              ],
              fareop: [
                {
                  fareKey: "0~R~ ~6E~R0INT~5000~~0~3~~X",
                  pr: {
                    bf: 6311,
                    tf: 9799,
                    tax: [
                      { OT: 3488 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "6311_9799",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 9799,
                  },
                  paxpr: [
                    {
                      bf: 6311,
                      tf: 9799,
                      tax: [
                        { OT: 3488 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "R0INT",
                      ptype: "ADT",
                      scur: "INR",
                      sup_fare: "6311_9799",
                      psup_fare: "6311_9799",
                    },
                  ],
                  routeId: "0xsfr81m9y",
                  productClass: "R",
                  ruleNumber: "5000",
                  servicefee: [],
                  selected: true,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "G79e0AHvUTs=|pfN7IeMVY9crZtLzdrvIzm0oZntQ6f3741aeYn1CvdMLxX16D05jLOkRUUokspRUNBaad/QhuW2NmBov2F0uAOs5rQ6LmyiTG2kKghIPh0qz95QB/MyFDxPn3z3uu1Sj7jxXZQa3gsE=",
              stop: 0,
            },
          ],
          destorigins: [
            {
              tid: "uGybwUghINCG0gNh_2",
              ref: 2,
              office_id: "",
              sdur: "10:15",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T02:00:00",
                  ad: "2023-06-09T08:20:00",
                  rbd: "",
                  fn: "1312",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "3" },
                  rph: "",
                  jsell:
                    "6E~1312~ ~~DOH~06/09/2023 02:00~DEL~06/09/2023 08:20~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-06-09T10:10:00",
                  ad: "2023-06-09T12:15:00",
                  rbd: "",
                  fn: "2005",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "2" },
                  rph: "",
                  jsell:
                    "6E~2005~ ~~DEL~06/09/2023 10:10~HYD~06/09/2023 12:15~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~R~ ~6E~RCINT~6200~~0~65~~X^0~R~ ~6E~RCINT~6200~~0~65~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 9819,
                      fbc: "RCINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "9819_13326",
                      tax: [
                        { OT: 3507 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 13326,
                      psup_fare: "9819_13326",
                    },
                  ],
                  pr: {
                    bf: 9819,
                    tf: 13326,
                    tax: [
                      { OT: 3507 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "9819_13326",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 13326,
                  },
                  routeId: "wbev8qq8vv",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~R~ ~6E~RCUINT~6202~~0~4~~X^0~R~ ~6E~RCUINT~6202~~0~4~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 10519,
                      fbc: "RCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "10519_14050",
                      tax: [
                        { OT: 3531 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 14050,
                      psup_fare: "10519_14050",
                    },
                  ],
                  pr: {
                    bf: 10519,
                    tf: 14050,
                    tax: [
                      { OT: 3531 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "10519_14050",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 14050,
                  },
                  routeId: "7zzm1fnvqx",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "G79e0AHvUTs=|pfN7IeMVY9crZtLzdrvIzm0oZntQ6f3741aeYn1CvdMLxX16D05jLOkRUUokspRUNBaad/QhuW2NmBov2F0uAOs5rQ6LmyiTG2kKghIPh0qz95QB/MyFDxPn3z3uu1Sj7jxXZQa3gsE=",
              stop: 1,
            },
            {
              tid: "SnyDbOw4C93PoxPF_2",
              ref: 2,
              office_id: "",
              sdur: "21:40",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T02:00:00",
                  ad: "2023-06-09T08:20:00",
                  rbd: "",
                  fn: "1312",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "3" },
                  rph: "",
                  jsell:
                    "6E~1312~ ~~DOH~06/09/2023 02:00~DEL~06/09/2023 08:20~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-06-09T21:25:00",
                  ad: "2023-06-09T23:40:00",
                  rbd: "",
                  fn: "2371",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "2" },
                  rph: "",
                  jsell:
                    "6E~2371~ ~~DEL~06/09/2023 21:25~HYD~06/09/2023 23:40~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~R~ ~6E~RCINT~6200~~0~65~~X^0~R~ ~6E~RCINT~6200~~0~65~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 9819,
                      fbc: "RCINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "9819_13326",
                      tax: [
                        { OT: 3507 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 13326,
                      psup_fare: "9819_13326",
                    },
                  ],
                  pr: {
                    bf: 9819,
                    tf: 13326,
                    tax: [
                      { OT: 3507 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "9819_13326",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 13326,
                  },
                  routeId: "a91fnpk9om",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~R~ ~6E~RCUINT~6202~~0~4~~X^0~R~ ~6E~RCUINT~6202~~0~4~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 10519,
                      fbc: "RCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "10519_14050",
                      tax: [
                        { OT: 3531 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 14050,
                      psup_fare: "10519_14050",
                    },
                  ],
                  pr: {
                    bf: 10519,
                    tf: 14050,
                    tax: [
                      { OT: 3531 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "10519_14050",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 14050,
                  },
                  routeId: "cmqxo3htyxl",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "G79e0AHvUTs=|pfN7IeMVY9crZtLzdrvIzm0oZntQ6f3741aeYn1CvdMLxX16D05jLOkRUUokspRUNBaad/QhuW2NmBov2F0uAOs5rQ6LmyiTG2kKghIPh0qz95QB/MyFDxPn3z3uu1Sj7jxXZQa3gsE=",
              stop: 1,
            },
            {
              tid: "0C6eELvMTTsdg3kg_2",
              ref: 2,
              office_id: "",
              sdur: "20:0",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T02:00:00",
                  ad: "2023-06-09T08:20:00",
                  rbd: "",
                  fn: "1312",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "3" },
                  rph: "",
                  jsell:
                    "6E~1312~ ~~DOH~06/09/2023 02:00~DEL~06/09/2023 08:20~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-06-09T19:50:00",
                  ad: "2023-06-09T22:00:00",
                  rbd: "",
                  fn: " 605",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "1" },
                  rph: "",
                  jsell:
                    "6E~ 605~ ~~DEL~06/09/2023 19:50~HYD~06/09/2023 22:00~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~R~ ~6E~RCINT~6200~~0~65~~X^0~R~ ~6E~RCINT~6200~~0~65~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 9819,
                      fbc: "RCINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "9819_13326",
                      tax: [
                        { OT: 3507 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 13326,
                      psup_fare: "9819_13326",
                    },
                  ],
                  pr: {
                    bf: 9819,
                    tf: 13326,
                    tax: [
                      { OT: 3507 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "9819_13326",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 13326,
                  },
                  routeId: "39j6obm8xd",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~R~ ~6E~RCUINT~6202~~0~4~~X^0~R~ ~6E~RCUINT~6202~~0~4~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 10519,
                      fbc: "RCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "10519_14050",
                      tax: [
                        { OT: 3531 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 14050,
                      psup_fare: "10519_14050",
                    },
                  ],
                  pr: {
                    bf: 10519,
                    tf: 14050,
                    tax: [
                      { OT: 3531 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "10519_14050",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 14050,
                  },
                  routeId: "7nfgm6a5c6",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "G79e0AHvUTs=|pfN7IeMVY9crZtLzdrvIzm0oZntQ6f3741aeYn1CvdMLxX16D05jLOkRUUokspRUNBaad/QhuW2NmBov2F0uAOs5rQ6LmyiTG2kKghIPh0qz95QB/MyFDxPn3z3uu1Sj7jxXZQa3gsE=",
              stop: 1,
            },
            {
              tid: "x31OWqCseUxlGnhN_2",
              ref: 2,
              office_id: "",
              sdur: "19:0",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T02:00:00",
                  ad: "2023-06-09T08:20:00",
                  rbd: "",
                  fn: "1312",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "3" },
                  rph: "",
                  jsell:
                    "6E~1312~ ~~DOH~06/09/2023 02:00~DEL~06/09/2023 08:20~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-06-09T18:50:00",
                  ad: "2023-06-09T21:00:00",
                  rbd: "",
                  fn: "5217",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "3" },
                  rph: "",
                  jsell:
                    "6E~5217~ ~~DEL~06/09/2023 18:50~HYD~06/09/2023 21:00~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~R~ ~6E~RCINT~6200~~0~65~~X^0~R~ ~6E~RCINT~6200~~0~65~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 9819,
                      fbc: "RCINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "9819_13326",
                      tax: [
                        { OT: 3507 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 13326,
                      psup_fare: "9819_13326",
                    },
                  ],
                  pr: {
                    bf: 9819,
                    tf: 13326,
                    tax: [
                      { OT: 3507 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "9819_13326",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 13326,
                  },
                  routeId: "npgg3dywl4",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~R~ ~6E~RCUINT~6202~~0~4~~X^0~R~ ~6E~RCUINT~6202~~0~4~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 10519,
                      fbc: "RCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "10519_14050",
                      tax: [
                        { OT: 3531 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 14050,
                      psup_fare: "10519_14050",
                    },
                  ],
                  pr: {
                    bf: 10519,
                    tf: 14050,
                    tax: [
                      { OT: 3531 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "10519_14050",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 14050,
                  },
                  routeId: "jqeqz14po7",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "G79e0AHvUTs=|pfN7IeMVY9crZtLzdrvIzm0oZntQ6f3741aeYn1CvdMLxX16D05jLOkRUUokspRUNBaad/QhuW2NmBov2F0uAOs5rQ6LmyiTG2kKghIPh0qz95QB/MyFDxPn3z3uu1Sj7jxXZQa3gsE=",
              stop: 1,
            },
            {
              tid: "sw3UZ4pMLmFslNah_2",
              ref: 2,
              office_id: "",
              sdur: "13:45",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T02:00:00",
                  ad: "2023-06-09T08:20:00",
                  rbd: "",
                  fn: "1312",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "3" },
                  rph: "",
                  jsell:
                    "6E~1312~ ~~DOH~06/09/2023 02:00~DEL~06/09/2023 08:20~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-06-09T13:30:00",
                  ad: "2023-06-09T15:45:00",
                  rbd: "",
                  fn: " 837",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "1" },
                  rph: "",
                  jsell:
                    "6E~ 837~ ~~DEL~06/09/2023 13:30~HYD~06/09/2023 15:45~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~R~ ~6E~RCINT~6200~~0~65~~X^0~R~ ~6E~RCINT~6200~~0~65~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 9819,
                      fbc: "RCINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "9819_13326",
                      tax: [
                        { OT: 3507 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 13326,
                      psup_fare: "9819_13326",
                    },
                  ],
                  pr: {
                    bf: 9819,
                    tf: 13326,
                    tax: [
                      { OT: 3507 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "9819_13326",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 13326,
                  },
                  routeId: "kh27czrarw",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~R~ ~6E~RCUINT~6202~~0~4~~X^0~R~ ~6E~RCUINT~6202~~0~4~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 10519,
                      fbc: "RCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "10519_14050",
                      tax: [
                        { OT: 3531 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 14050,
                      psup_fare: "10519_14050",
                    },
                  ],
                  pr: {
                    bf: 10519,
                    tf: 14050,
                    tax: [
                      { OT: 3531 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "10519_14050",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 14050,
                  },
                  routeId: "9nqwkw25sc",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "G79e0AHvUTs=|pfN7IeMVY9crZtLzdrvIzm0oZntQ6f3741aeYn1CvdMLxX16D05jLOkRUUokspRUNBaad/QhuW2NmBov2F0uAOs5rQ6LmyiTG2kKghIPh0qz95QB/MyFDxPn3z3uu1Sj7jxXZQa3gsE=",
              stop: 1,
            },
            {
              tid: "meZ44xwTIxGCISQz_2",
              ref: 2,
              office_id: "",
              sdur: "16:35",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T19:40:00",
                  ad: "2023-06-10T02:00:00",
                  rbd: "",
                  fn: "1308",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "3" },
                  rph: "",
                  jsell:
                    "6E~1308~ ~~DOH~06/09/2023 19:40~DEL~06/10/2023 02:00~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-06-10T10:10:00",
                  ad: "2023-06-10T12:15:00",
                  rbd: "",
                  fn: "2005",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "2" },
                  rph: "",
                  jsell:
                    "6E~2005~ ~~DEL~06/10/2023 10:10~HYD~06/10/2023 12:15~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~R~ ~6E~RCINT~6200~~0~65~~X^0~R~ ~6E~RCINT~6200~~0~65~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 9819,
                      fbc: "RCINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "9819_13326",
                      tax: [
                        { OT: 3507 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 13326,
                      psup_fare: "9819_13326",
                    },
                  ],
                  pr: {
                    bf: 9819,
                    tf: 13326,
                    tax: [
                      { OT: 3507 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "9819_13326",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 13326,
                  },
                  routeId: "atcbms5338k",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~R~ ~6E~RCUINT~6202~~0~4~~X^0~R~ ~6E~RCUINT~6202~~0~4~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 10519,
                      fbc: "RCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "10519_14050",
                      tax: [
                        { OT: 3531 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 14050,
                      psup_fare: "10519_14050",
                    },
                  ],
                  pr: {
                    bf: 10519,
                    tf: 14050,
                    tax: [
                      { OT: 3531 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "10519_14050",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 14050,
                  },
                  routeId: "m9gazohygtk",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "G79e0AHvUTs=|pfN7IeMVY9crZtLzdrvIzm0oZntQ6f3741aeYn1CvdMLxX16D05jLOkRUUokspRUNBaad/QhuW2NmBov2F0uAOs5rQ6LmyiTG2kKghIPh0qz95QB/MyFDxPn3z3uu1Sj7jxXZQa3gsE=",
              stop: 1,
            },
            {
              tid: "zwp77PIHoofe307s_2",
              ref: 2,
              office_id: "",
              sdur: "14:40",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T19:40:00",
                  ad: "2023-06-10T02:00:00",
                  rbd: "",
                  fn: "1308",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "3" },
                  rph: "",
                  jsell:
                    "6E~1308~ ~~DOH~06/09/2023 19:40~DEL~06/10/2023 02:00~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-06-10T08:10:00",
                  ad: "2023-06-10T10:20:00",
                  rbd: "",
                  fn: " 774",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "1" },
                  rph: "",
                  jsell:
                    "6E~ 774~ ~~DEL~06/10/2023 08:10~HYD~06/10/2023 10:20~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~R~ ~6E~RCINT~6200~~0~65~~X^0~R~ ~6E~RCINT~6200~~0~65~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 9819,
                      fbc: "RCINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "9819_13326",
                      tax: [
                        { OT: 3507 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 13326,
                      psup_fare: "9819_13326",
                    },
                  ],
                  pr: {
                    bf: 9819,
                    tf: 13326,
                    tax: [
                      { OT: 3507 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "9819_13326",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 13326,
                  },
                  routeId: "v3fwe5ydh7",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~R~ ~6E~RCUINT~6202~~0~4~~X^0~R~ ~6E~RCUINT~6202~~0~4~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 10519,
                      fbc: "RCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "10519_14050",
                      tax: [
                        { OT: 3531 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 14050,
                      psup_fare: "10519_14050",
                    },
                  ],
                  pr: {
                    bf: 10519,
                    tf: 14050,
                    tax: [
                      { OT: 3531 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "10519_14050",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 14050,
                  },
                  routeId: "ec98u1m8v1i",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "G79e0AHvUTs=|pfN7IeMVY9crZtLzdrvIzm0oZntQ6f3741aeYn1CvdMLxX16D05jLOkRUUokspRUNBaad/QhuW2NmBov2F0uAOs5rQ6LmyiTG2kKghIPh0qz95QB/MyFDxPn3z3uu1Sj7jxXZQa3gsE=",
              stop: 1,
            },
            {
              tid: "7WAVysF9U86zZR2v_2",
              ref: 2,
              office_id: "",
              sdur: "20:5",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T19:40:00",
                  ad: "2023-06-10T02:00:00",
                  rbd: "",
                  fn: "1308",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "3" },
                  rph: "",
                  jsell:
                    "6E~1308~ ~~DOH~06/09/2023 19:40~DEL~06/10/2023 02:00~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-06-10T13:30:00",
                  ad: "2023-06-10T15:45:00",
                  rbd: "",
                  fn: " 837",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "1" },
                  rph: "",
                  jsell:
                    "6E~ 837~ ~~DEL~06/10/2023 13:30~HYD~06/10/2023 15:45~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~R~ ~6E~RCINT~6200~~0~65~~X^0~R~ ~6E~RCINT~6200~~0~65~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 9819,
                      fbc: "RCINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "9819_13326",
                      tax: [
                        { OT: 3507 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 13326,
                      psup_fare: "9819_13326",
                    },
                  ],
                  pr: {
                    bf: 9819,
                    tf: 13326,
                    tax: [
                      { OT: 3507 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "9819_13326",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 13326,
                  },
                  routeId: "tlwdnyitko",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~R~ ~6E~RCUINT~6202~~0~4~~X^0~R~ ~6E~RCUINT~6202~~0~4~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 10519,
                      fbc: "RCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "10519_14050",
                      tax: [
                        { OT: 3531 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 14050,
                      psup_fare: "10519_14050",
                    },
                  ],
                  pr: {
                    bf: 10519,
                    tf: 14050,
                    tax: [
                      { OT: 3531 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "10519_14050",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 14050,
                  },
                  routeId: "zoirsp5xur",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "G79e0AHvUTs=|pfN7IeMVY9crZtLzdrvIzm0oZntQ6f3741aeYn1CvdMLxX16D05jLOkRUUokspRUNBaad/QhuW2NmBov2F0uAOs5rQ6LmyiTG2kKghIPh0qz95QB/MyFDxPn3z3uu1Sj7jxXZQa3gsE=",
              stop: 1,
            },
            {
              tid: "a5PyFRsP93KRtpzC_2",
              ref: 2,
              office_id: "",
              sdur: "21:40",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T19:40:00",
                  ad: "2023-06-10T02:00:00",
                  rbd: "",
                  fn: "1308",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "3" },
                  rph: "",
                  jsell:
                    "6E~1308~ ~~DOH~06/09/2023 19:40~DEL~06/10/2023 02:00~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-06-10T15:05:00",
                  ad: "2023-06-10T17:20:00",
                  rbd: "",
                  fn: "2187",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "2" },
                  rph: "",
                  jsell:
                    "6E~2187~ ~~DEL~06/10/2023 15:05~HYD~06/10/2023 17:20~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~R~ ~6E~RCINT~6200~~0~65~~X^0~R~ ~6E~RCINT~6200~~0~65~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 9819,
                      fbc: "RCINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "9819_13326",
                      tax: [
                        { OT: 3507 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 13326,
                      psup_fare: "9819_13326",
                    },
                  ],
                  pr: {
                    bf: 9819,
                    tf: 13326,
                    tax: [
                      { OT: 3507 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "9819_13326",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 13326,
                  },
                  routeId: "4nncfyhya9",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~R~ ~6E~RCUINT~6202~~0~4~~X^0~R~ ~6E~RCUINT~6202~~0~4~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 10519,
                      fbc: "RCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "10519_14050",
                      tax: [
                        { OT: 3531 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 14050,
                      psup_fare: "10519_14050",
                    },
                  ],
                  pr: {
                    bf: 10519,
                    tf: 14050,
                    tax: [
                      { OT: 3531 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "10519_14050",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 14050,
                  },
                  routeId: "0mbf260f1a",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "G79e0AHvUTs=|pfN7IeMVY9crZtLzdrvIzm0oZntQ6f3741aeYn1CvdMLxX16D05jLOkRUUokspRUNBaad/QhuW2NmBov2F0uAOs5rQ6LmyiTG2kKghIPh0qz95QB/MyFDxPn3z3uu1Sj7jxXZQa3gsE=",
              stop: 1,
            },
            {
              tid: "5bDfyeObWLvTaqG3_2",
              ref: 2,
              office_id: "",
              sdur: "23:45",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T19:40:00",
                  ad: "2023-06-10T02:00:00",
                  rbd: "",
                  fn: "1308",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "3" },
                  rph: "",
                  jsell:
                    "6E~1308~ ~~DOH~06/09/2023 19:40~DEL~06/10/2023 02:00~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-06-10T17:15:00",
                  ad: "2023-06-10T19:25:00",
                  rbd: "",
                  fn: "5312",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "3" },
                  rph: "",
                  jsell:
                    "6E~5312~ ~~DEL~06/10/2023 17:15~HYD~06/10/2023 19:25~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~R~ ~6E~RCINT~6200~~0~65~~X^0~R~ ~6E~RCINT~6200~~0~65~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 9819,
                      fbc: "RCINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "9819_13326",
                      tax: [
                        { OT: 3507 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 13326,
                      psup_fare: "9819_13326",
                    },
                  ],
                  pr: {
                    bf: 9819,
                    tf: 13326,
                    tax: [
                      { OT: 3507 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "9819_13326",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 13326,
                  },
                  routeId: "dvkycpjaba",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~R~ ~6E~RCUINT~6202~~0~4~~X^0~R~ ~6E~RCUINT~6202~~0~4~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 10519,
                      fbc: "RCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "10519_14050",
                      tax: [
                        { OT: 3531 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 14050,
                      psup_fare: "10519_14050",
                    },
                  ],
                  pr: {
                    bf: 10519,
                    tf: 14050,
                    tax: [
                      { OT: 3531 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "10519_14050",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 14050,
                  },
                  routeId: "msg0ojs0vj",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "G79e0AHvUTs=|pfN7IeMVY9crZtLzdrvIzm0oZntQ6f3741aeYn1CvdMLxX16D05jLOkRUUokspRUNBaad/QhuW2NmBov2F0uAOs5rQ6LmyiTG2kKghIPh0qz95QB/MyFDxPn3z3uu1Sj7jxXZQa3gsE=",
              stop: 1,
            },
            {
              tid: "JCuBxHcIKvH0ydJs_2",
              ref: 2,
              office_id: "",
              sdur: "25:20",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T19:40:00",
                  ad: "2023-06-10T02:00:00",
                  rbd: "",
                  fn: "1308",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "3" },
                  rph: "",
                  jsell:
                    "6E~1308~ ~~DOH~06/09/2023 19:40~DEL~06/10/2023 02:00~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-06-10T18:50:00",
                  ad: "2023-06-10T21:00:00",
                  rbd: "",
                  fn: "5217",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "3" },
                  rph: "",
                  jsell:
                    "6E~5217~ ~~DEL~06/10/2023 18:50~HYD~06/10/2023 21:00~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~R~ ~6E~RCINT~6200~~0~65~~X^0~R~ ~6E~RCINT~6200~~0~65~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 9819,
                      fbc: "RCINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "9819_13326",
                      tax: [
                        { OT: 3507 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 13326,
                      psup_fare: "9819_13326",
                    },
                  ],
                  pr: {
                    bf: 9819,
                    tf: 13326,
                    tax: [
                      { OT: 3507 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "9819_13326",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 13326,
                  },
                  routeId: "fcb29an23b",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~R~ ~6E~RCUINT~6202~~0~4~~X^0~R~ ~6E~RCUINT~6202~~0~4~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 10519,
                      fbc: "RCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "10519_14050",
                      tax: [
                        { OT: 3531 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 14050,
                      psup_fare: "10519_14050",
                    },
                  ],
                  pr: {
                    bf: 10519,
                    tf: 14050,
                    tax: [
                      { OT: 3531 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "10519_14050",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 14050,
                  },
                  routeId: "u9xxfybu1ti",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "G79e0AHvUTs=|pfN7IeMVY9crZtLzdrvIzm0oZntQ6f3741aeYn1CvdMLxX16D05jLOkRUUokspRUNBaad/QhuW2NmBov2F0uAOs5rQ6LmyiTG2kKghIPh0qz95QB/MyFDxPn3z3uu1Sj7jxXZQa3gsE=",
              stop: 1,
            },
            {
              tid: "BmNAlQyTH7pfKoql_2",
              ref: 2,
              office_id: "",
              sdur: "28:0",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T19:40:00",
                  ad: "2023-06-10T02:00:00",
                  rbd: "",
                  fn: "1308",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "3" },
                  rph: "",
                  jsell:
                    "6E~1308~ ~~DOH~06/09/2023 19:40~DEL~06/10/2023 02:00~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-06-10T21:25:00",
                  ad: "2023-06-10T23:40:00",
                  rbd: "",
                  fn: "2371",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "2" },
                  rph: "",
                  jsell:
                    "6E~2371~ ~~DEL~06/10/2023 21:25~HYD~06/10/2023 23:40~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~R~ ~6E~RCINT~6200~~0~65~~X^0~R~ ~6E~RCINT~6200~~0~65~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 9819,
                      fbc: "RCINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "9819_13326",
                      tax: [
                        { OT: 3507 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 13326,
                      psup_fare: "9819_13326",
                    },
                  ],
                  pr: {
                    bf: 9819,
                    tf: 13326,
                    tax: [
                      { OT: 3507 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "9819_13326",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 13326,
                  },
                  routeId: "9jf4hnqb32",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~R~ ~6E~RCUINT~6202~~0~4~~X^0~R~ ~6E~RCUINT~6202~~0~4~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 10519,
                      fbc: "RCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "10519_14050",
                      tax: [
                        { OT: 3531 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 14050,
                      psup_fare: "10519_14050",
                    },
                  ],
                  pr: {
                    bf: 10519,
                    tf: 14050,
                    tax: [
                      { OT: 3531 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "10519_14050",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 14050,
                  },
                  routeId: "bujk3djf6z",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "G79e0AHvUTs=|pfN7IeMVY9crZtLzdrvIzm0oZntQ6f3741aeYn1CvdMLxX16D05jLOkRUUokspRUNBaad/QhuW2NmBov2F0uAOs5rQ6LmyiTG2kKghIPh0qz95QB/MyFDxPn3z3uu1Sj7jxXZQa3gsE=",
              stop: 1,
            },
          ],
          paxpr: [
            {
              bf: 16130,
              tf: 23125,
              tax: [
                { OT: 6995 },
                { attmkp: 0 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              fbc: "R0INT",
              ptype: "ADT",
              scur: "INR",
              sup_fare: "6311_9799",
              psup_fare: "16130_23125",
            },
          ],
          pr: {
            bf: 16130,
            tf: 23125,
            tax: [
              { OT: 6995 },
              { attmkp: 0 },
              { tamkp: 0 },
              { stamkp: 0 },
              { attdisc: 0 },
              { tadisc: 0 },
              { stadisc: 0 },
            ],
            ucur: "INR",
            exchange_rate: 1,
            scur: "INR",
            sup_fare: "16130_23125",
            sup_cur_fare: "",
            cabb: "",
            chekb: "",
            seats: "",
            meals: "",
            chanb: "",
            ftyp: "Regular - R",
            markupnames: [],
            ttf: 23125,
          },
          sup: "4",
          airlineList: "6E",
          servicefee: [],
        },
        {
          srcorigns: [
            {
              tid: "iTk5FgKzvtnqiZTH_1",
              ref: 1,
              office_id: "",
              sdur: "9:30",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "MAA",
                  dd: "2023-06-07T14:05:00",
                  ad: "2023-06-07T15:20:00",
                  rbd: "",
                  fn: " 514",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "1" },
                  rph: "",
                  jsell:
                    "6E~ 514~ ~~HYD~06/07/2023 14:05~MAA~06/07/2023 15:20~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
                {
                  ref: 2,
                  da: "MAA",
                  ar: "DOH",
                  dd: "2023-06-07T21:30:00",
                  ad: "2023-06-07T23:35:00",
                  rbd: "",
                  fn: "1321",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "4" },
                  rph: "",
                  jsell:
                    "6E~1321~ ~~MAA~06/07/2023 21:30~DOH~06/07/2023 23:35~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~R~ ~6E~RCINT~6200~~0~66~~X^0~R~ ~6E~RCINT~6200~~0~66~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 8036,
                      fbc: "RCINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "8036_11726",
                      tax: [
                        { OT: 3690 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 11726,
                      psup_fare: "8036_11726",
                    },
                  ],
                  pr: {
                    bf: 8036,
                    tf: 11726,
                    tax: [
                      { OT: 3690 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "8036_11726",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 11726,
                  },
                  routeId: "gxpr30defql",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~R~ ~6E~RCUINT~6202~~0~5~~X^0~R~ ~6E~RCUINT~6202~~0~5~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 8736,
                      fbc: "RCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "8736_12487",
                      tax: [
                        { OT: 3751 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 12487,
                      psup_fare: "8736_12487",
                    },
                  ],
                  pr: {
                    bf: 8736,
                    tf: 12487,
                    tax: [
                      { OT: 3751 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "8736_12487",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 12487,
                  },
                  routeId: "infg1fvif4",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "G79e0AHvUTs=|pfN7IeMVY9crZtLzdrvIzm0oZntQ6f3741aeYn1CvdMLxX16D05jLOkRUUokspRUNBaad/QhuW2NmBov2F0uAOs5rQ6LmyiTG2kKghIPh0qz95QB/MyFDxPn3z3uu1Sj7jxXZQa3gsE=",
              stop: 1,
            },
            {
              tid: "O08aakAD1CR1IiJ5_1",
              ref: 1,
              office_id: "",
              sdur: "7:0",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "MAA",
                  dd: "2023-06-07T16:35:00",
                  ad: "2023-06-07T17:45:00",
                  rbd: "",
                  fn: " 668",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "1" },
                  rph: "",
                  jsell:
                    "6E~ 668~ ~~HYD~06/07/2023 16:35~MAA~06/07/2023 17:45~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
                {
                  ref: 2,
                  da: "MAA",
                  ar: "DOH",
                  dd: "2023-06-07T21:30:00",
                  ad: "2023-06-07T23:35:00",
                  rbd: "",
                  fn: "1321",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "4" },
                  rph: "",
                  jsell:
                    "6E~1321~ ~~MAA~06/07/2023 21:30~DOH~06/07/2023 23:35~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~R~ ~6E~RCINT~6200~~0~66~~X^0~R~ ~6E~RCINT~6200~~0~66~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 8036,
                      fbc: "RCINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "8036_11726",
                      tax: [
                        { OT: 3690 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 11726,
                      psup_fare: "8036_11726",
                    },
                  ],
                  pr: {
                    bf: 8036,
                    tf: 11726,
                    tax: [
                      { OT: 3690 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "8036_11726",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 11726,
                  },
                  routeId: "4fpdu4xzwq",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~R~ ~6E~RCUINT~6202~~0~5~~X^0~R~ ~6E~RCUINT~6202~~0~5~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 8736,
                      fbc: "RCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "8736_12487",
                      tax: [
                        { OT: 3751 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 12487,
                      psup_fare: "8736_12487",
                    },
                  ],
                  pr: {
                    bf: 8736,
                    tf: 12487,
                    tax: [
                      { OT: 3751 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "8736_12487",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 12487,
                  },
                  routeId: "svhkk6aht6",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "G79e0AHvUTs=|pfN7IeMVY9crZtLzdrvIzm0oZntQ6f3741aeYn1CvdMLxX16D05jLOkRUUokspRUNBaad/QhuW2NmBov2F0uAOs5rQ6LmyiTG2kKghIPh0qz95QB/MyFDxPn3z3uu1Sj7jxXZQa3gsE=",
              stop: 1,
            },
            {
              tid: "rMbOyuTBW2C4ulga_1",
              ref: 1,
              office_id: "",
              sdur: "5:55",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "MAA",
                  dd: "2023-06-07T17:40:00",
                  ad: "2023-06-07T18:55:00",
                  rbd: "",
                  fn: " 102",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "1" },
                  rph: "",
                  jsell:
                    "6E~ 102~ ~~HYD~06/07/2023 17:40~MAA~06/07/2023 18:55~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
                {
                  ref: 2,
                  da: "MAA",
                  ar: "DOH",
                  dd: "2023-06-07T21:30:00",
                  ad: "2023-06-07T23:35:00",
                  rbd: "",
                  fn: "1321",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "4" },
                  rph: "",
                  jsell:
                    "6E~1321~ ~~MAA~06/07/2023 21:30~DOH~06/07/2023 23:35~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~R~ ~6E~RCINT~6200~~0~66~~X^0~R~ ~6E~RCINT~6200~~0~66~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 8036,
                      fbc: "RCINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "8036_11726",
                      tax: [
                        { OT: 3690 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 11726,
                      psup_fare: "8036_11726",
                    },
                  ],
                  pr: {
                    bf: 8036,
                    tf: 11726,
                    tax: [
                      { OT: 3690 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "8036_11726",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 11726,
                  },
                  routeId: "apa6a5thw2",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~R~ ~6E~RCUINT~6202~~0~5~~X^0~R~ ~6E~RCUINT~6202~~0~5~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 8736,
                      fbc: "RCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "8736_12487",
                      tax: [
                        { OT: 3751 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 12487,
                      psup_fare: "8736_12487",
                    },
                  ],
                  pr: {
                    bf: 8736,
                    tf: 12487,
                    tax: [
                      { OT: 3751 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "8736_12487",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 12487,
                  },
                  routeId: "ok6xy6rr06",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "G79e0AHvUTs=|pfN7IeMVY9crZtLzdrvIzm0oZntQ6f3741aeYn1CvdMLxX16D05jLOkRUUokspRUNBaad/QhuW2NmBov2F0uAOs5rQ6LmyiTG2kKghIPh0qz95QB/MyFDxPn3z3uu1Sj7jxXZQa3gsE=",
              stop: 1,
            },
          ],
          destorigins: [
            {
              tid: "h4sGL4d88Im0gCt3_2",
              ref: 2,
              office_id: "",
              sdur: "16:15",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "MAA",
                  dd: "2023-06-09T02:00:00",
                  ad: "2023-06-09T09:05:00",
                  rbd: "",
                  fn: "1322",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "3" },
                  rph: "",
                  jsell:
                    "6E~1322~ ~~DOH~06/09/2023 02:00~MAA~06/09/2023 09:05~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
                {
                  ref: 2,
                  da: "MAA",
                  ar: "HYD",
                  dd: "2023-06-09T17:00:00",
                  ad: "2023-06-09T18:15:00",
                  rbd: "",
                  fn: " 562",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "1" },
                  rph: "",
                  jsell:
                    "6E~ 562~ ~~MAA~06/09/2023 17:00~HYD~06/09/2023 18:15~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "1~Q~ ~6E~Q0INT~5000~~0~12~~^2~L~ ~6E~LC8AP~1070~~0~7~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 9313,
                      fbc: "Q0INT/LC8AP",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "9313_12802",
                      tax: [
                        { OT: 3489 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 12802,
                      psup_fare: "9313_12802",
                    },
                  ],
                  pr: {
                    bf: 9313,
                    tf: 12802,
                    tax: [
                      { OT: 3489 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "9313_12802",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 12802,
                  },
                  routeId: "kzx5yctwtg",
                  ruleNumber: "5000",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~R~ ~6E~RCUINT~6202~~0~4~~X^0~R~ ~6E~RCUINT~6202~~0~4~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 10519,
                      fbc: "RCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "10519_14050",
                      tax: [
                        { OT: 3531 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 14050,
                      psup_fare: "10519_14050",
                    },
                  ],
                  pr: {
                    bf: 10519,
                    tf: 14050,
                    tax: [
                      { OT: 3531 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "10519_14050",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 14050,
                  },
                  routeId: "cb13okd6fq",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "G79e0AHvUTs=|pfN7IeMVY9crZtLzdrvIzm0oZntQ6f3741aeYn1CvdMLxX16D05jLOkRUUokspRUNBaad/QhuW2NmBov2F0uAOs5rQ6LmyiTG2kKghIPh0qz95QB/MyFDxPn3z3uu1Sj7jxXZQa3gsE=",
              stop: 1,
            },
            {
              tid: "c0gxLbD8xM8xmSun_2",
              ref: 2,
              office_id: "",
              sdur: "15:10",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "MAA",
                  dd: "2023-06-09T02:00:00",
                  ad: "2023-06-09T09:05:00",
                  rbd: "",
                  fn: "1322",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "3" },
                  rph: "",
                  jsell:
                    "6E~1322~ ~~DOH~06/09/2023 02:00~MAA~06/09/2023 09:05~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
                {
                  ref: 2,
                  da: "MAA",
                  ar: "HYD",
                  dd: "2023-06-09T16:00:00",
                  ad: "2023-06-09T17:10:00",
                  rbd: "",
                  fn: "6401",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "1" },
                  rph: "",
                  jsell:
                    "6E~6401~ ~~MAA~06/09/2023 16:00~HYD~06/09/2023 17:10~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "1~Q~ ~6E~Q0INT~5000~~0~12~~^2~L~ ~6E~LC8AP~1070~~0~7~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 9313,
                      fbc: "Q0INT/LC8AP",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "9313_12802",
                      tax: [
                        { OT: 3489 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 12802,
                      psup_fare: "9313_12802",
                    },
                  ],
                  pr: {
                    bf: 9313,
                    tf: 12802,
                    tax: [
                      { OT: 3489 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "9313_12802",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 12802,
                  },
                  routeId: "zqclf64pa5",
                  ruleNumber: "5000",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~R~ ~6E~RCUINT~6202~~0~4~~X^0~R~ ~6E~RCUINT~6202~~0~4~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 10519,
                      fbc: "RCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "10519_14050",
                      tax: [
                        { OT: 3531 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 14050,
                      psup_fare: "10519_14050",
                    },
                  ],
                  pr: {
                    bf: 10519,
                    tf: 14050,
                    tax: [
                      { OT: 3531 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "10519_14050",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 14050,
                  },
                  routeId: "h101w7pt5s",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "G79e0AHvUTs=|pfN7IeMVY9crZtLzdrvIzm0oZntQ6f3741aeYn1CvdMLxX16D05jLOkRUUokspRUNBaad/QhuW2NmBov2F0uAOs5rQ6LmyiTG2kKghIPh0qz95QB/MyFDxPn3z3uu1Sj7jxXZQa3gsE=",
              stop: 1,
            },
            {
              tid: "zxFcT8RU2gGRvvh5_2",
              ref: 2,
              office_id: "",
              sdur: "11:30",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "MAA",
                  dd: "2023-06-09T02:00:00",
                  ad: "2023-06-09T09:05:00",
                  rbd: "",
                  fn: "1322",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "3" },
                  rph: "",
                  jsell:
                    "6E~1322~ ~~DOH~06/09/2023 02:00~MAA~06/09/2023 09:05~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
                {
                  ref: 2,
                  da: "MAA",
                  ar: "HYD",
                  dd: "2023-06-09T12:20:00",
                  ad: "2023-06-09T13:30:00",
                  rbd: "",
                  fn: " 604",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "1" },
                  rph: "",
                  jsell:
                    "6E~ 604~ ~~MAA~06/09/2023 12:20~HYD~06/09/2023 13:30~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "1~Q~ ~6E~Q0INT~5000~~0~12~~^2~L~ ~6E~LC8AP~1070~~0~7~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 9313,
                      fbc: "Q0INT/LC8AP",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "9313_12802",
                      tax: [
                        { OT: 3489 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 12802,
                      psup_fare: "9313_12802",
                    },
                  ],
                  pr: {
                    bf: 9313,
                    tf: 12802,
                    tax: [
                      { OT: 3489 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "9313_12802",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 12802,
                  },
                  routeId: "ncy3zpibub",
                  ruleNumber: "5000",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~R~ ~6E~RCUINT~6202~~0~4~~X^0~R~ ~6E~RCUINT~6202~~0~4~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 10519,
                      fbc: "RCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "10519_14050",
                      tax: [
                        { OT: 3531 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 14050,
                      psup_fare: "10519_14050",
                    },
                  ],
                  pr: {
                    bf: 10519,
                    tf: 14050,
                    tax: [
                      { OT: 3531 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "10519_14050",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 14050,
                  },
                  routeId: "lcyuupi428f",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "G79e0AHvUTs=|pfN7IeMVY9crZtLzdrvIzm0oZntQ6f3741aeYn1CvdMLxX16D05jLOkRUUokspRUNBaad/QhuW2NmBov2F0uAOs5rQ6LmyiTG2kKghIPh0qz95QB/MyFDxPn3z3uu1Sj7jxXZQa3gsE=",
              stop: 1,
            },
          ],
          paxpr: [
            {
              bf: 17349,
              fbc: "RCINT",
              ptype: "ADT",
              scur: "QAR",
              sup_fare: "8036_11726",
              tax: [
                { OT: 7179 },
                { attmkp: 0 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              tf: 24528,
              psup_fare: "17349_24528",
            },
          ],
          pr: {
            bf: 17349,
            tf: 24528,
            tax: [
              { OT: 7179 },
              { attmkp: 0 },
              { tamkp: 0 },
              { stamkp: 0 },
              { attdisc: 0 },
              { tadisc: 0 },
              { stadisc: 0 },
            ],
            ucur: "INR",
            exchange_rate: 1,
            scur: "INR",
            sup_fare: "17349_24528",
            sup_cur_fare: "",
            cabb: "",
            chekb: "",
            seats: "",
            meals: "",
            chanb: "",
            ftyp: "Regular - R",
            markupnames: [],
            ttf: 24528,
          },
          sup: "4",
          airlineList: "6E",
          servicefee: [],
        },
        {
          srcorigns: [
            {
              tid: "UU42xT8HvuRJ31S2_1",
              ref: 1,
              office_id: "",
              sdur: "9:30",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "MAA",
                  dd: "2023-06-07T14:05:00",
                  ad: "2023-06-07T15:20:00",
                  rbd: "",
                  fn: " 514",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "1" },
                  rph: "",
                  jsell:
                    "6E~ 514~ ~~HYD~06/07/2023 14:05~MAA~06/07/2023 15:20~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
                {
                  ref: 2,
                  da: "MAA",
                  ar: "DOH",
                  dd: "2023-06-07T21:30:00",
                  ad: "2023-06-07T23:35:00",
                  rbd: "",
                  fn: "1321",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "4" },
                  rph: "",
                  jsell:
                    "6E~1321~ ~~MAA~06/07/2023 21:30~DOH~06/07/2023 23:35~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~R~ ~6E~RCINT~6200~~0~66~~X^0~R~ ~6E~RCINT~6200~~0~66~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 8036,
                      fbc: "RCINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "8036_11726",
                      tax: [
                        { OT: 3690 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 11726,
                      psup_fare: "8036_11726",
                    },
                  ],
                  pr: {
                    bf: 8036,
                    tf: 11726,
                    tax: [
                      { OT: 3690 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "8036_11726",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 11726,
                  },
                  routeId: "gxpr30defql",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~R~ ~6E~RCUINT~6202~~0~5~~X^0~R~ ~6E~RCUINT~6202~~0~5~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 8736,
                      fbc: "RCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "8736_12487",
                      tax: [
                        { OT: 3751 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 12487,
                      psup_fare: "8736_12487",
                    },
                  ],
                  pr: {
                    bf: 8736,
                    tf: 12487,
                    tax: [
                      { OT: 3751 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "8736_12487",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 12487,
                  },
                  routeId: "infg1fvif4",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "G79e0AHvUTs=|pfN7IeMVY9crZtLzdrvIzm0oZntQ6f3741aeYn1CvdMLxX16D05jLOkRUUokspRUNBaad/QhuW2NmBov2F0uAOs5rQ6LmyiTG2kKghIPh0qz95QB/MyFDxPn3z3uu1Sj7jxXZQa3gsE=",
              stop: 1,
            },
            {
              tid: "muwIyceltKLMfgDX_1",
              ref: 1,
              office_id: "",
              sdur: "7:0",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "MAA",
                  dd: "2023-06-07T16:35:00",
                  ad: "2023-06-07T17:45:00",
                  rbd: "",
                  fn: " 668",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "1" },
                  rph: "",
                  jsell:
                    "6E~ 668~ ~~HYD~06/07/2023 16:35~MAA~06/07/2023 17:45~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
                {
                  ref: 2,
                  da: "MAA",
                  ar: "DOH",
                  dd: "2023-06-07T21:30:00",
                  ad: "2023-06-07T23:35:00",
                  rbd: "",
                  fn: "1321",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "4" },
                  rph: "",
                  jsell:
                    "6E~1321~ ~~MAA~06/07/2023 21:30~DOH~06/07/2023 23:35~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~R~ ~6E~RCINT~6200~~0~66~~X^0~R~ ~6E~RCINT~6200~~0~66~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 8036,
                      fbc: "RCINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "8036_11726",
                      tax: [
                        { OT: 3690 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 11726,
                      psup_fare: "8036_11726",
                    },
                  ],
                  pr: {
                    bf: 8036,
                    tf: 11726,
                    tax: [
                      { OT: 3690 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "8036_11726",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 11726,
                  },
                  routeId: "4fpdu4xzwq",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~R~ ~6E~RCUINT~6202~~0~5~~X^0~R~ ~6E~RCUINT~6202~~0~5~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 8736,
                      fbc: "RCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "8736_12487",
                      tax: [
                        { OT: 3751 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 12487,
                      psup_fare: "8736_12487",
                    },
                  ],
                  pr: {
                    bf: 8736,
                    tf: 12487,
                    tax: [
                      { OT: 3751 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "8736_12487",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 12487,
                  },
                  routeId: "svhkk6aht6",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "G79e0AHvUTs=|pfN7IeMVY9crZtLzdrvIzm0oZntQ6f3741aeYn1CvdMLxX16D05jLOkRUUokspRUNBaad/QhuW2NmBov2F0uAOs5rQ6LmyiTG2kKghIPh0qz95QB/MyFDxPn3z3uu1Sj7jxXZQa3gsE=",
              stop: 1,
            },
            {
              tid: "bfVaG1o9Unv97uDK_1",
              ref: 1,
              office_id: "",
              sdur: "5:55",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "MAA",
                  dd: "2023-06-07T17:40:00",
                  ad: "2023-06-07T18:55:00",
                  rbd: "",
                  fn: " 102",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "1" },
                  rph: "",
                  jsell:
                    "6E~ 102~ ~~HYD~06/07/2023 17:40~MAA~06/07/2023 18:55~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
                {
                  ref: 2,
                  da: "MAA",
                  ar: "DOH",
                  dd: "2023-06-07T21:30:00",
                  ad: "2023-06-07T23:35:00",
                  rbd: "",
                  fn: "1321",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "4" },
                  rph: "",
                  jsell:
                    "6E~1321~ ~~MAA~06/07/2023 21:30~DOH~06/07/2023 23:35~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~R~ ~6E~RCINT~6200~~0~66~~X^0~R~ ~6E~RCINT~6200~~0~66~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 8036,
                      fbc: "RCINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "8036_11726",
                      tax: [
                        { OT: 3690 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 11726,
                      psup_fare: "8036_11726",
                    },
                  ],
                  pr: {
                    bf: 8036,
                    tf: 11726,
                    tax: [
                      { OT: 3690 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "8036_11726",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 11726,
                  },
                  routeId: "apa6a5thw2",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~R~ ~6E~RCUINT~6202~~0~5~~X^0~R~ ~6E~RCUINT~6202~~0~5~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 8736,
                      fbc: "RCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "8736_12487",
                      tax: [
                        { OT: 3751 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 12487,
                      psup_fare: "8736_12487",
                    },
                  ],
                  pr: {
                    bf: 8736,
                    tf: 12487,
                    tax: [
                      { OT: 3751 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "8736_12487",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 12487,
                  },
                  routeId: "ok6xy6rr06",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "G79e0AHvUTs=|pfN7IeMVY9crZtLzdrvIzm0oZntQ6f3741aeYn1CvdMLxX16D05jLOkRUUokspRUNBaad/QhuW2NmBov2F0uAOs5rQ6LmyiTG2kKghIPh0qz95QB/MyFDxPn3z3uu1Sj7jxXZQa3gsE=",
              stop: 1,
            },
          ],
          destorigins: [
            {
              tid: "B8TeTTO8qqMWAKRk_2",
              ref: 2,
              office_id: "",
              sdur: "10:15",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T02:00:00",
                  ad: "2023-06-09T08:20:00",
                  rbd: "",
                  fn: "1312",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "3" },
                  rph: "",
                  jsell:
                    "6E~1312~ ~~DOH~06/09/2023 02:00~DEL~06/09/2023 08:20~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-06-09T10:10:00",
                  ad: "2023-06-09T12:15:00",
                  rbd: "",
                  fn: "2005",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "2" },
                  rph: "",
                  jsell:
                    "6E~2005~ ~~DEL~06/09/2023 10:10~HYD~06/09/2023 12:15~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~R~ ~6E~RCINT~6200~~0~65~~X^0~R~ ~6E~RCINT~6200~~0~65~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 9819,
                      fbc: "RCINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "9819_13326",
                      tax: [
                        { OT: 3507 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 13326,
                      psup_fare: "9819_13326",
                    },
                  ],
                  pr: {
                    bf: 9819,
                    tf: 13326,
                    tax: [
                      { OT: 3507 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "9819_13326",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 13326,
                  },
                  routeId: "lde6x173c7",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~R~ ~6E~RCUINT~6202~~0~4~~X^0~R~ ~6E~RCUINT~6202~~0~4~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 10519,
                      fbc: "RCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "10519_14050",
                      tax: [
                        { OT: 3531 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 14050,
                      psup_fare: "10519_14050",
                    },
                  ],
                  pr: {
                    bf: 10519,
                    tf: 14050,
                    tax: [
                      { OT: 3531 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "10519_14050",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 14050,
                  },
                  routeId: "9l12xfw3ne",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "G79e0AHvUTs=|pfN7IeMVY9crZtLzdrvIzm0oZntQ6f3741aeYn1CvdMLxX16D05jLOkRUUokspRUNBaad/QhuW2NmBov2F0uAOs5rQ6LmyiTG2kKghIPh0qz95QB/MyFDxPn3z3uu1Sj7jxXZQa3gsE=",
              stop: 1,
            },
            {
              tid: "ERmdzPz4RrxtUTvy_2",
              ref: 2,
              office_id: "",
              sdur: "21:40",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T02:00:00",
                  ad: "2023-06-09T08:20:00",
                  rbd: "",
                  fn: "1312",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "3" },
                  rph: "",
                  jsell:
                    "6E~1312~ ~~DOH~06/09/2023 02:00~DEL~06/09/2023 08:20~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-06-09T21:25:00",
                  ad: "2023-06-09T23:40:00",
                  rbd: "",
                  fn: "2371",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "2" },
                  rph: "",
                  jsell:
                    "6E~2371~ ~~DEL~06/09/2023 21:25~HYD~06/09/2023 23:40~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~R~ ~6E~RCINT~6200~~0~65~~X^0~R~ ~6E~RCINT~6200~~0~65~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 9819,
                      fbc: "RCINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "9819_13326",
                      tax: [
                        { OT: 3507 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 13326,
                      psup_fare: "9819_13326",
                    },
                  ],
                  pr: {
                    bf: 9819,
                    tf: 13326,
                    tax: [
                      { OT: 3507 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "9819_13326",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 13326,
                  },
                  routeId: "0lkecx4j5i",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~R~ ~6E~RCUINT~6202~~0~4~~X^0~R~ ~6E~RCUINT~6202~~0~4~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 10519,
                      fbc: "RCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "10519_14050",
                      tax: [
                        { OT: 3531 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 14050,
                      psup_fare: "10519_14050",
                    },
                  ],
                  pr: {
                    bf: 10519,
                    tf: 14050,
                    tax: [
                      { OT: 3531 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "10519_14050",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 14050,
                  },
                  routeId: "epdpjfyrbxl",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "G79e0AHvUTs=|pfN7IeMVY9crZtLzdrvIzm0oZntQ6f3741aeYn1CvdMLxX16D05jLOkRUUokspRUNBaad/QhuW2NmBov2F0uAOs5rQ6LmyiTG2kKghIPh0qz95QB/MyFDxPn3z3uu1Sj7jxXZQa3gsE=",
              stop: 1,
            },
            {
              tid: "qctQ6NLlRvJDPkFi_2",
              ref: 2,
              office_id: "",
              sdur: "20:0",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T02:00:00",
                  ad: "2023-06-09T08:20:00",
                  rbd: "",
                  fn: "1312",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "3" },
                  rph: "",
                  jsell:
                    "6E~1312~ ~~DOH~06/09/2023 02:00~DEL~06/09/2023 08:20~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-06-09T19:50:00",
                  ad: "2023-06-09T22:00:00",
                  rbd: "",
                  fn: " 605",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "1" },
                  rph: "",
                  jsell:
                    "6E~ 605~ ~~DEL~06/09/2023 19:50~HYD~06/09/2023 22:00~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~R~ ~6E~RCINT~6200~~0~65~~X^0~R~ ~6E~RCINT~6200~~0~65~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 9819,
                      fbc: "RCINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "9819_13326",
                      tax: [
                        { OT: 3507 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 13326,
                      psup_fare: "9819_13326",
                    },
                  ],
                  pr: {
                    bf: 9819,
                    tf: 13326,
                    tax: [
                      { OT: 3507 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "9819_13326",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 13326,
                  },
                  routeId: "fag9imze7d",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~R~ ~6E~RCUINT~6202~~0~4~~X^0~R~ ~6E~RCUINT~6202~~0~4~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 10519,
                      fbc: "RCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "10519_14050",
                      tax: [
                        { OT: 3531 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 14050,
                      psup_fare: "10519_14050",
                    },
                  ],
                  pr: {
                    bf: 10519,
                    tf: 14050,
                    tax: [
                      { OT: 3531 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "10519_14050",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 14050,
                  },
                  routeId: "e9zcrpl9lo",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "G79e0AHvUTs=|pfN7IeMVY9crZtLzdrvIzm0oZntQ6f3741aeYn1CvdMLxX16D05jLOkRUUokspRUNBaad/QhuW2NmBov2F0uAOs5rQ6LmyiTG2kKghIPh0qz95QB/MyFDxPn3z3uu1Sj7jxXZQa3gsE=",
              stop: 1,
            },
            {
              tid: "wPDusk8gafUuCh2u_2",
              ref: 2,
              office_id: "",
              sdur: "19:0",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T02:00:00",
                  ad: "2023-06-09T08:20:00",
                  rbd: "",
                  fn: "1312",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "3" },
                  rph: "",
                  jsell:
                    "6E~1312~ ~~DOH~06/09/2023 02:00~DEL~06/09/2023 08:20~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-06-09T18:50:00",
                  ad: "2023-06-09T21:00:00",
                  rbd: "",
                  fn: "5217",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "3" },
                  rph: "",
                  jsell:
                    "6E~5217~ ~~DEL~06/09/2023 18:50~HYD~06/09/2023 21:00~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~R~ ~6E~RCINT~6200~~0~65~~X^0~R~ ~6E~RCINT~6200~~0~65~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 9819,
                      fbc: "RCINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "9819_13326",
                      tax: [
                        { OT: 3507 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 13326,
                      psup_fare: "9819_13326",
                    },
                  ],
                  pr: {
                    bf: 9819,
                    tf: 13326,
                    tax: [
                      { OT: 3507 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "9819_13326",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 13326,
                  },
                  routeId: "zonpurcmcy",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~R~ ~6E~RCUINT~6202~~0~4~~X^0~R~ ~6E~RCUINT~6202~~0~4~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 10519,
                      fbc: "RCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "10519_14050",
                      tax: [
                        { OT: 3531 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 14050,
                      psup_fare: "10519_14050",
                    },
                  ],
                  pr: {
                    bf: 10519,
                    tf: 14050,
                    tax: [
                      { OT: 3531 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "10519_14050",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 14050,
                  },
                  routeId: "wiliex3jdw",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "G79e0AHvUTs=|pfN7IeMVY9crZtLzdrvIzm0oZntQ6f3741aeYn1CvdMLxX16D05jLOkRUUokspRUNBaad/QhuW2NmBov2F0uAOs5rQ6LmyiTG2kKghIPh0qz95QB/MyFDxPn3z3uu1Sj7jxXZQa3gsE=",
              stop: 1,
            },
            {
              tid: "7dR1UiwuD2rWywUz_2",
              ref: 2,
              office_id: "",
              sdur: "13:45",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T02:00:00",
                  ad: "2023-06-09T08:20:00",
                  rbd: "",
                  fn: "1312",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "3" },
                  rph: "",
                  jsell:
                    "6E~1312~ ~~DOH~06/09/2023 02:00~DEL~06/09/2023 08:20~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-06-09T13:30:00",
                  ad: "2023-06-09T15:45:00",
                  rbd: "",
                  fn: " 837",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "1" },
                  rph: "",
                  jsell:
                    "6E~ 837~ ~~DEL~06/09/2023 13:30~HYD~06/09/2023 15:45~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~R~ ~6E~RCINT~6200~~0~65~~X^0~R~ ~6E~RCINT~6200~~0~65~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 9819,
                      fbc: "RCINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "9819_13326",
                      tax: [
                        { OT: 3507 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 13326,
                      psup_fare: "9819_13326",
                    },
                  ],
                  pr: {
                    bf: 9819,
                    tf: 13326,
                    tax: [
                      { OT: 3507 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "9819_13326",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 13326,
                  },
                  routeId: "89weoni4bqg",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~R~ ~6E~RCUINT~6202~~0~4~~X^0~R~ ~6E~RCUINT~6202~~0~4~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 10519,
                      fbc: "RCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "10519_14050",
                      tax: [
                        { OT: 3531 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 14050,
                      psup_fare: "10519_14050",
                    },
                  ],
                  pr: {
                    bf: 10519,
                    tf: 14050,
                    tax: [
                      { OT: 3531 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "10519_14050",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 14050,
                  },
                  routeId: "mqg349cvkb",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "G79e0AHvUTs=|pfN7IeMVY9crZtLzdrvIzm0oZntQ6f3741aeYn1CvdMLxX16D05jLOkRUUokspRUNBaad/QhuW2NmBov2F0uAOs5rQ6LmyiTG2kKghIPh0qz95QB/MyFDxPn3z3uu1Sj7jxXZQa3gsE=",
              stop: 1,
            },
            {
              tid: "6K47e8z3eEBiQlgd_2",
              ref: 2,
              office_id: "",
              sdur: "16:35",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T19:40:00",
                  ad: "2023-06-10T02:00:00",
                  rbd: "",
                  fn: "1308",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "3" },
                  rph: "",
                  jsell:
                    "6E~1308~ ~~DOH~06/09/2023 19:40~DEL~06/10/2023 02:00~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-06-10T10:10:00",
                  ad: "2023-06-10T12:15:00",
                  rbd: "",
                  fn: "2005",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "2" },
                  rph: "",
                  jsell:
                    "6E~2005~ ~~DEL~06/10/2023 10:10~HYD~06/10/2023 12:15~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~R~ ~6E~RCINT~6200~~0~65~~X^0~R~ ~6E~RCINT~6200~~0~65~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 9819,
                      fbc: "RCINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "9819_13326",
                      tax: [
                        { OT: 3507 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 13326,
                      psup_fare: "9819_13326",
                    },
                  ],
                  pr: {
                    bf: 9819,
                    tf: 13326,
                    tax: [
                      { OT: 3507 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "9819_13326",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 13326,
                  },
                  routeId: "1wkopqtkmgl",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~R~ ~6E~RCUINT~6202~~0~4~~X^0~R~ ~6E~RCUINT~6202~~0~4~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 10519,
                      fbc: "RCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "10519_14050",
                      tax: [
                        { OT: 3531 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 14050,
                      psup_fare: "10519_14050",
                    },
                  ],
                  pr: {
                    bf: 10519,
                    tf: 14050,
                    tax: [
                      { OT: 3531 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "10519_14050",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 14050,
                  },
                  routeId: "oslerp1bxug",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "G79e0AHvUTs=|pfN7IeMVY9crZtLzdrvIzm0oZntQ6f3741aeYn1CvdMLxX16D05jLOkRUUokspRUNBaad/QhuW2NmBov2F0uAOs5rQ6LmyiTG2kKghIPh0qz95QB/MyFDxPn3z3uu1Sj7jxXZQa3gsE=",
              stop: 1,
            },
            {
              tid: "GGHrXEBGA7I9f72l_2",
              ref: 2,
              office_id: "",
              sdur: "14:40",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T19:40:00",
                  ad: "2023-06-10T02:00:00",
                  rbd: "",
                  fn: "1308",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "3" },
                  rph: "",
                  jsell:
                    "6E~1308~ ~~DOH~06/09/2023 19:40~DEL~06/10/2023 02:00~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-06-10T08:10:00",
                  ad: "2023-06-10T10:20:00",
                  rbd: "",
                  fn: " 774",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "1" },
                  rph: "",
                  jsell:
                    "6E~ 774~ ~~DEL~06/10/2023 08:10~HYD~06/10/2023 10:20~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~R~ ~6E~RCINT~6200~~0~65~~X^0~R~ ~6E~RCINT~6200~~0~65~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 9819,
                      fbc: "RCINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "9819_13326",
                      tax: [
                        { OT: 3507 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 13326,
                      psup_fare: "9819_13326",
                    },
                  ],
                  pr: {
                    bf: 9819,
                    tf: 13326,
                    tax: [
                      { OT: 3507 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "9819_13326",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 13326,
                  },
                  routeId: "z5xnzq05t9",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~R~ ~6E~RCUINT~6202~~0~4~~X^0~R~ ~6E~RCUINT~6202~~0~4~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 10519,
                      fbc: "RCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "10519_14050",
                      tax: [
                        { OT: 3531 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 14050,
                      psup_fare: "10519_14050",
                    },
                  ],
                  pr: {
                    bf: 10519,
                    tf: 14050,
                    tax: [
                      { OT: 3531 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "10519_14050",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 14050,
                  },
                  routeId: "ocmq5nl77e",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "G79e0AHvUTs=|pfN7IeMVY9crZtLzdrvIzm0oZntQ6f3741aeYn1CvdMLxX16D05jLOkRUUokspRUNBaad/QhuW2NmBov2F0uAOs5rQ6LmyiTG2kKghIPh0qz95QB/MyFDxPn3z3uu1Sj7jxXZQa3gsE=",
              stop: 1,
            },
            {
              tid: "buPE53Dfyt6bRp20_2",
              ref: 2,
              office_id: "",
              sdur: "20:5",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T19:40:00",
                  ad: "2023-06-10T02:00:00",
                  rbd: "",
                  fn: "1308",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "3" },
                  rph: "",
                  jsell:
                    "6E~1308~ ~~DOH~06/09/2023 19:40~DEL~06/10/2023 02:00~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-06-10T13:30:00",
                  ad: "2023-06-10T15:45:00",
                  rbd: "",
                  fn: " 837",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "1" },
                  rph: "",
                  jsell:
                    "6E~ 837~ ~~DEL~06/10/2023 13:30~HYD~06/10/2023 15:45~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~R~ ~6E~RCINT~6200~~0~65~~X^0~R~ ~6E~RCINT~6200~~0~65~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 9819,
                      fbc: "RCINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "9819_13326",
                      tax: [
                        { OT: 3507 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 13326,
                      psup_fare: "9819_13326",
                    },
                  ],
                  pr: {
                    bf: 9819,
                    tf: 13326,
                    tax: [
                      { OT: 3507 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "9819_13326",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 13326,
                  },
                  routeId: "asz1p47zlb",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~R~ ~6E~RCUINT~6202~~0~4~~X^0~R~ ~6E~RCUINT~6202~~0~4~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 10519,
                      fbc: "RCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "10519_14050",
                      tax: [
                        { OT: 3531 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 14050,
                      psup_fare: "10519_14050",
                    },
                  ],
                  pr: {
                    bf: 10519,
                    tf: 14050,
                    tax: [
                      { OT: 3531 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "10519_14050",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 14050,
                  },
                  routeId: "9fp5l2bkbsg",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "G79e0AHvUTs=|pfN7IeMVY9crZtLzdrvIzm0oZntQ6f3741aeYn1CvdMLxX16D05jLOkRUUokspRUNBaad/QhuW2NmBov2F0uAOs5rQ6LmyiTG2kKghIPh0qz95QB/MyFDxPn3z3uu1Sj7jxXZQa3gsE=",
              stop: 1,
            },
            {
              tid: "QanHZVSXl4kp0lP1_2",
              ref: 2,
              office_id: "",
              sdur: "21:40",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T19:40:00",
                  ad: "2023-06-10T02:00:00",
                  rbd: "",
                  fn: "1308",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "3" },
                  rph: "",
                  jsell:
                    "6E~1308~ ~~DOH~06/09/2023 19:40~DEL~06/10/2023 02:00~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-06-10T15:05:00",
                  ad: "2023-06-10T17:20:00",
                  rbd: "",
                  fn: "2187",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "2" },
                  rph: "",
                  jsell:
                    "6E~2187~ ~~DEL~06/10/2023 15:05~HYD~06/10/2023 17:20~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~R~ ~6E~RCINT~6200~~0~65~~X^0~R~ ~6E~RCINT~6200~~0~65~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 9819,
                      fbc: "RCINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "9819_13326",
                      tax: [
                        { OT: 3507 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 13326,
                      psup_fare: "9819_13326",
                    },
                  ],
                  pr: {
                    bf: 9819,
                    tf: 13326,
                    tax: [
                      { OT: 3507 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "9819_13326",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 13326,
                  },
                  routeId: "9rs9u10t2z",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~R~ ~6E~RCUINT~6202~~0~4~~X^0~R~ ~6E~RCUINT~6202~~0~4~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 10519,
                      fbc: "RCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "10519_14050",
                      tax: [
                        { OT: 3531 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 14050,
                      psup_fare: "10519_14050",
                    },
                  ],
                  pr: {
                    bf: 10519,
                    tf: 14050,
                    tax: [
                      { OT: 3531 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "10519_14050",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 14050,
                  },
                  routeId: "5322v4md1v",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "G79e0AHvUTs=|pfN7IeMVY9crZtLzdrvIzm0oZntQ6f3741aeYn1CvdMLxX16D05jLOkRUUokspRUNBaad/QhuW2NmBov2F0uAOs5rQ6LmyiTG2kKghIPh0qz95QB/MyFDxPn3z3uu1Sj7jxXZQa3gsE=",
              stop: 1,
            },
            {
              tid: "VrCV4Ffh1B57UMf8_2",
              ref: 2,
              office_id: "",
              sdur: "23:45",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T19:40:00",
                  ad: "2023-06-10T02:00:00",
                  rbd: "",
                  fn: "1308",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "3" },
                  rph: "",
                  jsell:
                    "6E~1308~ ~~DOH~06/09/2023 19:40~DEL~06/10/2023 02:00~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-06-10T17:15:00",
                  ad: "2023-06-10T19:25:00",
                  rbd: "",
                  fn: "5312",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "3" },
                  rph: "",
                  jsell:
                    "6E~5312~ ~~DEL~06/10/2023 17:15~HYD~06/10/2023 19:25~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~R~ ~6E~RCINT~6200~~0~65~~X^0~R~ ~6E~RCINT~6200~~0~65~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 9819,
                      fbc: "RCINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "9819_13326",
                      tax: [
                        { OT: 3507 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 13326,
                      psup_fare: "9819_13326",
                    },
                  ],
                  pr: {
                    bf: 9819,
                    tf: 13326,
                    tax: [
                      { OT: 3507 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "9819_13326",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 13326,
                  },
                  routeId: "k7jjn53dpe",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~R~ ~6E~RCUINT~6202~~0~4~~X^0~R~ ~6E~RCUINT~6202~~0~4~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 10519,
                      fbc: "RCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "10519_14050",
                      tax: [
                        { OT: 3531 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 14050,
                      psup_fare: "10519_14050",
                    },
                  ],
                  pr: {
                    bf: 10519,
                    tf: 14050,
                    tax: [
                      { OT: 3531 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "10519_14050",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 14050,
                  },
                  routeId: "au8avfez8ch",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "G79e0AHvUTs=|pfN7IeMVY9crZtLzdrvIzm0oZntQ6f3741aeYn1CvdMLxX16D05jLOkRUUokspRUNBaad/QhuW2NmBov2F0uAOs5rQ6LmyiTG2kKghIPh0qz95QB/MyFDxPn3z3uu1Sj7jxXZQa3gsE=",
              stop: 1,
            },
            {
              tid: "EqArVhQaayd7EyOc_2",
              ref: 2,
              office_id: "",
              sdur: "25:20",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T19:40:00",
                  ad: "2023-06-10T02:00:00",
                  rbd: "",
                  fn: "1308",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "3" },
                  rph: "",
                  jsell:
                    "6E~1308~ ~~DOH~06/09/2023 19:40~DEL~06/10/2023 02:00~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-06-10T18:50:00",
                  ad: "2023-06-10T21:00:00",
                  rbd: "",
                  fn: "5217",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "3" },
                  rph: "",
                  jsell:
                    "6E~5217~ ~~DEL~06/10/2023 18:50~HYD~06/10/2023 21:00~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~R~ ~6E~RCINT~6200~~0~65~~X^0~R~ ~6E~RCINT~6200~~0~65~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 9819,
                      fbc: "RCINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "9819_13326",
                      tax: [
                        { OT: 3507 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 13326,
                      psup_fare: "9819_13326",
                    },
                  ],
                  pr: {
                    bf: 9819,
                    tf: 13326,
                    tax: [
                      { OT: 3507 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "9819_13326",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 13326,
                  },
                  routeId: "geha6bswzi",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~R~ ~6E~RCUINT~6202~~0~4~~X^0~R~ ~6E~RCUINT~6202~~0~4~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 10519,
                      fbc: "RCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "10519_14050",
                      tax: [
                        { OT: 3531 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 14050,
                      psup_fare: "10519_14050",
                    },
                  ],
                  pr: {
                    bf: 10519,
                    tf: 14050,
                    tax: [
                      { OT: 3531 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "10519_14050",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 14050,
                  },
                  routeId: "ikgz0nx908",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "G79e0AHvUTs=|pfN7IeMVY9crZtLzdrvIzm0oZntQ6f3741aeYn1CvdMLxX16D05jLOkRUUokspRUNBaad/QhuW2NmBov2F0uAOs5rQ6LmyiTG2kKghIPh0qz95QB/MyFDxPn3z3uu1Sj7jxXZQa3gsE=",
              stop: 1,
            },
            {
              tid: "mAv7go9wxTCANhuz_2",
              ref: 2,
              office_id: "",
              sdur: "28:0",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T19:40:00",
                  ad: "2023-06-10T02:00:00",
                  rbd: "",
                  fn: "1308",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "3" },
                  rph: "",
                  jsell:
                    "6E~1308~ ~~DOH~06/09/2023 19:40~DEL~06/10/2023 02:00~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-06-10T21:25:00",
                  ad: "2023-06-10T23:40:00",
                  rbd: "",
                  fn: "2371",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "2" },
                  rph: "",
                  jsell:
                    "6E~2371~ ~~DEL~06/10/2023 21:25~HYD~06/10/2023 23:40~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~R~ ~6E~RCINT~6200~~0~65~~X^0~R~ ~6E~RCINT~6200~~0~65~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 9819,
                      fbc: "RCINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "9819_13326",
                      tax: [
                        { OT: 3507 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 13326,
                      psup_fare: "9819_13326",
                    },
                  ],
                  pr: {
                    bf: 9819,
                    tf: 13326,
                    tax: [
                      { OT: 3507 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "9819_13326",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 13326,
                  },
                  routeId: "jkebdagv97",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~R~ ~6E~RCUINT~6202~~0~4~~X^0~R~ ~6E~RCUINT~6202~~0~4~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 10519,
                      fbc: "RCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "10519_14050",
                      tax: [
                        { OT: 3531 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 14050,
                      psup_fare: "10519_14050",
                    },
                  ],
                  pr: {
                    bf: 10519,
                    tf: 14050,
                    tax: [
                      { OT: 3531 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "10519_14050",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 14050,
                  },
                  routeId: "upnnt6tgcgf",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "G79e0AHvUTs=|pfN7IeMVY9crZtLzdrvIzm0oZntQ6f3741aeYn1CvdMLxX16D05jLOkRUUokspRUNBaad/QhuW2NmBov2F0uAOs5rQ6LmyiTG2kKghIPh0qz95QB/MyFDxPn3z3uu1Sj7jxXZQa3gsE=",
              stop: 1,
            },
          ],
          paxpr: [
            {
              bf: 17855,
              fbc: "RCINT",
              ptype: "ADT",
              scur: "QAR",
              sup_fare: "8036_11726",
              tax: [
                { OT: 7197 },
                { attmkp: 0 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              tf: 25052,
              psup_fare: "17855_25052",
            },
          ],
          pr: {
            bf: 17855,
            tf: 25052,
            tax: [
              { OT: 7197 },
              { attmkp: 0 },
              { tamkp: 0 },
              { stamkp: 0 },
              { attdisc: 0 },
              { tadisc: 0 },
              { stadisc: 0 },
            ],
            ucur: "INR",
            exchange_rate: 1,
            scur: "INR",
            sup_fare: "17855_25052",
            sup_cur_fare: "",
            cabb: "",
            chekb: "",
            seats: "",
            meals: "",
            chanb: "",
            ftyp: "Regular - R",
            markupnames: [],
            ttf: 25052,
          },
          sup: "4",
          airlineList: "6E",
          servicefee: [],
        },
        {
          srcorigns: [
            {
              tid: "vqQMZsgIXBE9OFGa_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "09:25",
              re: "Refundable Before Departure",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DEL",
                  dd: "2023-06-07T14:30:00",
                  ad: "2023-06-07T16:55:00",
                  rbd: "Q",
                  fn: "523",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "1",
                  oa: "AI",
                  fa: [],
                  fdur: "02:25",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-06-07T19:50:00",
                  ad: "2023-06-07T21:25:00",
                  rbd: "U",
                  fn: "971",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:05",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "523/971",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          destorigins: [
            {
              tid: "vqQMZsgIXBE9OFGa_2",
              ref: 2,
              office_id: "DOHQR28IH",
              sdur: "08:20",
              re: "Refundable Before Departure",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T22:35:00",
                  ad: "2023-06-10T05:05:00",
                  rbd: "U",
                  fn: "972",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:00",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-06-10T07:10:00",
                  ad: "2023-06-10T09:25:00",
                  rbd: "Q",
                  fn: "560",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:15",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "972/560",
              bgd: [{ qun: "30", wt: "kg" }],
            },
            {
              tid: "9IGlJfpLRnIxA4U9_2",
              ref: 2,
              office_id: "DOHQR28IH",
              sdur: "10:45",
              re: "Refundable Before Departure",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T22:35:00",
                  ad: "2023-06-10T05:05:00",
                  rbd: "U",
                  fn: "972",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:00",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-06-10T09:45:00",
                  ad: "2023-06-10T11:50:00",
                  rbd: "Q",
                  fn: "542",
                  trn: "3",
                  eq: "321",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:05",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "972/542",
              bgd: [{ qun: "30", wt: "kg" }],
            },
            {
              tid: "8W4yI9xk7TN0tDRb_2",
              ref: 2,
              office_id: "DOHQR28IH",
              sdur: "18:05",
              re: "Refundable Before Departure",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T22:35:00",
                  ad: "2023-06-10T05:05:00",
                  rbd: "U",
                  fn: "972",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:00",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-06-10T16:50:00",
                  ad: "2023-06-10T19:10:00",
                  rbd: "Q",
                  fn: "544",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:20",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "972/544",
              bgd: [{ qun: "30", wt: "kg" }],
            },
            {
              tid: "HRpBiNSyVaExfV9k_2",
              ref: 2,
              office_id: "DOHQR28IH",
              sdur: "22:40",
              re: "Refundable Before Departure",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T22:35:00",
                  ad: "2023-06-10T05:05:00",
                  rbd: "U",
                  fn: "972",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:00",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-06-10T21:30:00",
                  ad: "2023-06-10T23:45:00",
                  rbd: "Q",
                  fn: "839",
                  trn: "3",
                  eq: "321",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:15",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "972/839",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          pr: {
            bf: 21190.4,
            tf: 30150.4,
            tax: [
              { YR: 896 },
              { YR: 448 },
              { OT: 7616 },
              { dealmkp: 0 },
              { attmkp: 1926.4 },
              { tamkp: 0 },
              { stamkp: 0 },
              { attdisc: 0 },
              { tadisc: 0 },
              { stadisc: 0 },
            ],
            scur: "QAR",
            ucur: "INR",
            exchange_rate: 22.4,
            sup_fare: "860_1260",
            markupnames: [{ code: "MTA", name: "LaxmiAprilMarkup" }],
          },
          paxpr: [
            {
              tf: 30150.4,
              tax: [
                { YR: 896 },
                { YR: 448 },
                { OT: 7616 },
                { attmkp: 1926.4 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              bf: 21190.4,
              fbc: "U6MDLDH",
              fareType: "RP",
              pusup_fare: "860_1260",
              psup_fare: "19264_28224",
              ptype: "ADT",
            },
          ],
          sup: "0",
          airlinesList: "AI",
          servicefee: [
            {
              type: "ATT",
              adt: 2119.04,
              chd: 0,
              inf: 0,
              servicefee_name: "LaxmiServieApril",
              apl_on: "Per Ticket",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 2119.04,
              sup_fee: 0,
            },
            {
              type: "TA",
              adt: 0,
              chd: 0,
              inf: 0,
              apl_on: "",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 0,
              sup_fee: 2119.04,
            },
          ],
        },
        {
          srcorigns: [
            {
              tid: "LFrqOGTLBsxAnUny_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "09:25",
              re: "Refundable Before Departure",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DEL",
                  dd: "2023-06-07T14:30:00",
                  ad: "2023-06-07T16:55:00",
                  rbd: "Q",
                  fn: "523",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "1",
                  oa: "AI",
                  fa: [],
                  fdur: "02:25",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-06-07T19:50:00",
                  ad: "2023-06-07T21:25:00",
                  rbd: "U",
                  fn: "971",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:05",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "523/971",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          destorigins: [
            {
              tid: "LFrqOGTLBsxAnUny_2",
              ref: 2,
              office_id: "DOHQR28IH",
              sdur: "12:35",
              re: "Refundable Before Departure",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T22:35:00",
                  ad: "2023-06-10T05:05:00",
                  rbd: "U",
                  fn: "972",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:00",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-06-10T11:25:00",
                  ad: "2023-06-10T13:40:00",
                  rbd: "Q",
                  fn: "522",
                  trn: "3",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "1",
                  oa: "AI",
                  fa: [],
                  fdur: "02:15",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "972/522",
              bgd: [{ qun: "30", wt: "kg" }],
            },
            {
              tid: "ryXcWOupOsnHkTxa_2",
              ref: 2,
              office_id: "DOHQR28IH",
              sdur: "20:40",
              re: "Refundable Before Departure",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T22:35:00",
                  ad: "2023-06-10T05:05:00",
                  rbd: "U",
                  fn: "972",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:00",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-06-10T19:25:00",
                  ad: "2023-06-10T21:45:00",
                  rbd: "Q",
                  fn: "524",
                  trn: "3",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "1",
                  oa: "AI",
                  fa: [],
                  fdur: "02:20",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "972/524",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          pr: {
            bf: 21190.4,
            tf: 30150.4,
            tax: [
              { YR: 896 },
              { YR: 448 },
              { OT: 7616 },
              { dealmkp: 0 },
              { attmkp: 1926.4 },
              { tamkp: 0 },
              { stamkp: 0 },
              { attdisc: 0 },
              { tadisc: 0 },
              { stadisc: 0 },
            ],
            scur: "QAR",
            ucur: "INR",
            exchange_rate: 22.4,
            sup_fare: "860_1260",
            markupnames: [{ code: "MTA", name: "LaxmiAprilMarkup" }],
          },
          paxpr: [
            {
              tf: 30150.4,
              tax: [
                { YR: 896 },
                { YR: 448 },
                { OT: 7616 },
                { attmkp: 1926.4 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              bf: 21190.4,
              fbc: "U6MDLDH",
              fareType: "RP",
              pusup_fare: "860_1260",
              psup_fare: "19264_28224",
              ptype: "ADT",
            },
          ],
          sup: "0",
          airlinesList: "AI",
          servicefee: [
            {
              type: "ATT",
              adt: 2119.04,
              chd: 0,
              inf: 0,
              servicefee_name: "LaxmiServieApril",
              apl_on: "Per Ticket",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 2119.04,
              sup_fee: 0,
            },
            {
              type: "TA",
              adt: 0,
              chd: 0,
              inf: 0,
              apl_on: "",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 0,
              sup_fee: 2119.04,
            },
          ],
        },
        {
          srcorigns: [
            {
              tid: "nT23fZE3HqAPz47V_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "14:00",
              re: "Refundable Before Departure",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DEL",
                  dd: "2023-06-07T09:55:00",
                  ad: "2023-06-07T12:20:00",
                  rbd: "Q",
                  fn: "543",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:25",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-06-07T19:50:00",
                  ad: "2023-06-07T21:25:00",
                  rbd: "U",
                  fn: "971",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:05",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "543/971",
              bgd: [{ qun: "30", wt: "kg" }],
            },
            {
              tid: "00zIVwpU0W1tOmIR_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "17:15",
              re: "Refundable Before Departure",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DEL",
                  dd: "2023-06-07T06:40:00",
                  ad: "2023-06-07T08:55:00",
                  rbd: "Q",
                  fn: "559",
                  eq: "321",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:15",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-06-07T19:50:00",
                  ad: "2023-06-07T21:25:00",
                  rbd: "U",
                  fn: "971",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:05",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "559/971",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          destorigins: [
            {
              tid: "nT23fZE3HqAPz47V_2",
              ref: 2,
              office_id: "DOHQR28IH",
              sdur: "08:20",
              re: "Refundable Before Departure",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T22:35:00",
                  ad: "2023-06-10T05:05:00",
                  rbd: "U",
                  fn: "972",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:00",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-06-10T07:10:00",
                  ad: "2023-06-10T09:25:00",
                  rbd: "Q",
                  fn: "560",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:15",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "972/560",
              bgd: [{ qun: "30", wt: "kg" }],
            },
            {
              tid: "wMyWVBzW7XTC7sDB_2",
              ref: 2,
              office_id: "DOHQR28IH",
              sdur: "10:45",
              re: "Refundable Before Departure",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T22:35:00",
                  ad: "2023-06-10T05:05:00",
                  rbd: "U",
                  fn: "972",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:00",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-06-10T09:45:00",
                  ad: "2023-06-10T11:50:00",
                  rbd: "Q",
                  fn: "542",
                  trn: "3",
                  eq: "321",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:05",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "972/542",
              bgd: [{ qun: "30", wt: "kg" }],
            },
            {
              tid: "16PgJkDU55uwxski_2",
              ref: 2,
              office_id: "DOHQR28IH",
              sdur: "18:05",
              re: "Refundable Before Departure",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T22:35:00",
                  ad: "2023-06-10T05:05:00",
                  rbd: "U",
                  fn: "972",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:00",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-06-10T16:50:00",
                  ad: "2023-06-10T19:10:00",
                  rbd: "Q",
                  fn: "544",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:20",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "972/544",
              bgd: [{ qun: "30", wt: "kg" }],
            },
            {
              tid: "afTlIPs3Zl5N1CnA_2",
              ref: 2,
              office_id: "DOHQR28IH",
              sdur: "22:40",
              re: "Refundable Before Departure",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T22:35:00",
                  ad: "2023-06-10T05:05:00",
                  rbd: "U",
                  fn: "972",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:00",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-06-10T21:30:00",
                  ad: "2023-06-10T23:45:00",
                  rbd: "Q",
                  fn: "839",
                  trn: "3",
                  eq: "321",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:15",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "972/839",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          pr: {
            bf: 21190.4,
            tf: 30150.4,
            tax: [
              { YR: 896 },
              { YR: 448 },
              { OT: 7616 },
              { dealmkp: 0 },
              { attmkp: 1926.4 },
              { tamkp: 0 },
              { stamkp: 0 },
              { attdisc: 0 },
              { tadisc: 0 },
              { stadisc: 0 },
            ],
            scur: "QAR",
            ucur: "INR",
            exchange_rate: 22.4,
            sup_fare: "860_1260",
            markupnames: [{ code: "MTA", name: "LaxmiAprilMarkup" }],
          },
          paxpr: [
            {
              tf: 30150.4,
              tax: [
                { YR: 896 },
                { YR: 448 },
                { OT: 7616 },
                { attmkp: 1926.4 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              bf: 21190.4,
              fbc: "U6MDLDH",
              fareType: "RP",
              pusup_fare: "860_1260",
              psup_fare: "19264_28224",
              ptype: "ADT",
            },
          ],
          sup: "0",
          airlinesList: "AI",
          servicefee: [
            {
              type: "ATT",
              adt: 2119.04,
              chd: 0,
              inf: 0,
              servicefee_name: "LaxmiServieApril",
              apl_on: "Per Ticket",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 2119.04,
              sup_fee: 0,
            },
            {
              type: "TA",
              adt: 0,
              chd: 0,
              inf: 0,
              apl_on: "",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 0,
              sup_fee: 2119.04,
            },
          ],
        },
        {
          srcorigns: [
            {
              tid: "VnLHoxei3iAW9vZi_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "14:00",
              re: "Refundable Before Departure",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DEL",
                  dd: "2023-06-07T09:55:00",
                  ad: "2023-06-07T12:20:00",
                  rbd: "Q",
                  fn: "543",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:25",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-06-07T19:50:00",
                  ad: "2023-06-07T21:25:00",
                  rbd: "U",
                  fn: "971",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:05",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "543/971",
              bgd: [{ qun: "30", wt: "kg" }],
            },
            {
              tid: "oOtEKGm2qK5Jfwid_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "17:15",
              re: "Refundable Before Departure",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DEL",
                  dd: "2023-06-07T06:40:00",
                  ad: "2023-06-07T08:55:00",
                  rbd: "Q",
                  fn: "559",
                  eq: "321",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:15",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-06-07T19:50:00",
                  ad: "2023-06-07T21:25:00",
                  rbd: "U",
                  fn: "971",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:05",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "559/971",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          destorigins: [
            {
              tid: "VnLHoxei3iAW9vZi_2",
              ref: 2,
              office_id: "DOHQR28IH",
              sdur: "12:35",
              re: "Refundable Before Departure",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T22:35:00",
                  ad: "2023-06-10T05:05:00",
                  rbd: "U",
                  fn: "972",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:00",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-06-10T11:25:00",
                  ad: "2023-06-10T13:40:00",
                  rbd: "Q",
                  fn: "522",
                  trn: "3",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "1",
                  oa: "AI",
                  fa: [],
                  fdur: "02:15",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "972/522",
              bgd: [{ qun: "30", wt: "kg" }],
            },
            {
              tid: "G6oMXEEQAR0lwqQf_2",
              ref: 2,
              office_id: "DOHQR28IH",
              sdur: "20:40",
              re: "Refundable Before Departure",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T22:35:00",
                  ad: "2023-06-10T05:05:00",
                  rbd: "U",
                  fn: "972",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:00",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-06-10T19:25:00",
                  ad: "2023-06-10T21:45:00",
                  rbd: "Q",
                  fn: "524",
                  trn: "3",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "1",
                  oa: "AI",
                  fa: [],
                  fdur: "02:20",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "972/524",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          pr: {
            bf: 21190.4,
            tf: 30150.4,
            tax: [
              { YR: 896 },
              { YR: 448 },
              { OT: 7616 },
              { dealmkp: 0 },
              { attmkp: 1926.4 },
              { tamkp: 0 },
              { stamkp: 0 },
              { attdisc: 0 },
              { tadisc: 0 },
              { stadisc: 0 },
            ],
            scur: "QAR",
            ucur: "INR",
            exchange_rate: 22.4,
            sup_fare: "860_1260",
            markupnames: [{ code: "MTA", name: "LaxmiAprilMarkup" }],
          },
          paxpr: [
            {
              tf: 30150.4,
              tax: [
                { YR: 896 },
                { YR: 448 },
                { OT: 7616 },
                { attmkp: 1926.4 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              bf: 21190.4,
              fbc: "U6MDLDH",
              fareType: "RP",
              pusup_fare: "860_1260",
              psup_fare: "19264_28224",
              ptype: "ADT",
            },
          ],
          sup: "0",
          airlinesList: "AI",
          servicefee: [
            {
              type: "ATT",
              adt: 2119.04,
              chd: 0,
              inf: 0,
              servicefee_name: "LaxmiServieApril",
              apl_on: "Per Ticket",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 2119.04,
              sup_fee: 0,
            },
            {
              type: "TA",
              adt: 0,
              chd: 0,
              inf: 0,
              apl_on: "",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 0,
              sup_fee: 2119.04,
            },
          ],
        },
        {
          srcorigns: [
            {
              tid: "KdiywzIyVWF4t75X_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "27:15",
              re: "Refundable Before Departure",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DEL",
                  dd: "2023-06-07T20:40:00",
                  ad: "2023-06-07T23:00:00",
                  rbd: "Q",
                  fn: "840",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:20",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-06-08T19:50:00",
                  ad: "2023-06-08T21:25:00",
                  rbd: "U",
                  fn: "971",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "5",
                  oa: "AI",
                  fa: [],
                  fdur: "04:05",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "840/971",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          destorigins: [
            {
              tid: "KdiywzIyVWF4t75X_2",
              ref: 2,
              office_id: "DOHQR28IH",
              sdur: "08:20",
              re: "Refundable Before Departure",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T22:35:00",
                  ad: "2023-06-10T05:05:00",
                  rbd: "U",
                  fn: "972",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:00",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-06-10T07:10:00",
                  ad: "2023-06-10T09:25:00",
                  rbd: "Q",
                  fn: "560",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:15",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "972/560",
              bgd: [{ qun: "30", wt: "kg" }],
            },
            {
              tid: "ibClbSBfvTQdP5Xx_2",
              ref: 2,
              office_id: "DOHQR28IH",
              sdur: "10:45",
              re: "Refundable Before Departure",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T22:35:00",
                  ad: "2023-06-10T05:05:00",
                  rbd: "U",
                  fn: "972",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:00",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-06-10T09:45:00",
                  ad: "2023-06-10T11:50:00",
                  rbd: "Q",
                  fn: "542",
                  trn: "3",
                  eq: "321",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:05",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "972/542",
              bgd: [{ qun: "30", wt: "kg" }],
            },
            {
              tid: "9Ow0QTCqKT04ViI0_2",
              ref: 2,
              office_id: "DOHQR28IH",
              sdur: "18:05",
              re: "Refundable Before Departure",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T22:35:00",
                  ad: "2023-06-10T05:05:00",
                  rbd: "U",
                  fn: "972",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:00",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-06-10T16:50:00",
                  ad: "2023-06-10T19:10:00",
                  rbd: "Q",
                  fn: "544",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:20",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "972/544",
              bgd: [{ qun: "30", wt: "kg" }],
            },
            {
              tid: "fW7mVXym8GTqaKW9_2",
              ref: 2,
              office_id: "DOHQR28IH",
              sdur: "22:40",
              re: "Refundable Before Departure",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T22:35:00",
                  ad: "2023-06-10T05:05:00",
                  rbd: "U",
                  fn: "972",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:00",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-06-10T21:30:00",
                  ad: "2023-06-10T23:45:00",
                  rbd: "Q",
                  fn: "839",
                  trn: "3",
                  eq: "321",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:15",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "972/839",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          pr: {
            bf: 21190.4,
            tf: 30150.4,
            tax: [
              { YR: 896 },
              { YR: 448 },
              { OT: 7616 },
              { dealmkp: 0 },
              { attmkp: 1926.4 },
              { tamkp: 0 },
              { stamkp: 0 },
              { attdisc: 0 },
              { tadisc: 0 },
              { stadisc: 0 },
            ],
            scur: "QAR",
            ucur: "INR",
            exchange_rate: 22.4,
            sup_fare: "860_1260",
            markupnames: [{ code: "MTA", name: "LaxmiAprilMarkup" }],
          },
          paxpr: [
            {
              tf: 30150.4,
              tax: [
                { YR: 896 },
                { YR: 448 },
                { OT: 7616 },
                { attmkp: 1926.4 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              bf: 21190.4,
              fbc: "U6MDLDH",
              fareType: "RP",
              pusup_fare: "860_1260",
              psup_fare: "19264_28224",
              ptype: "ADT",
            },
          ],
          sup: "0",
          airlinesList: "AI",
          servicefee: [
            {
              type: "ATT",
              adt: 2119.04,
              chd: 0,
              inf: 0,
              servicefee_name: "LaxmiServieApril",
              apl_on: "Per Ticket",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 2119.04,
              sup_fee: 0,
            },
            {
              type: "TA",
              adt: 0,
              chd: 0,
              inf: 0,
              apl_on: "",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 0,
              sup_fee: 2119.04,
            },
          ],
        },
        {
          srcorigns: [
            {
              tid: "0e8pR7tJU7bXgisg_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "27:15",
              re: "Refundable Before Departure",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DEL",
                  dd: "2023-06-07T20:40:00",
                  ad: "2023-06-07T23:00:00",
                  rbd: "Q",
                  fn: "840",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:20",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-06-08T19:50:00",
                  ad: "2023-06-08T21:25:00",
                  rbd: "U",
                  fn: "971",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "5",
                  oa: "AI",
                  fa: [],
                  fdur: "04:05",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "840/971",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          destorigins: [
            {
              tid: "0e8pR7tJU7bXgisg_2",
              ref: 2,
              office_id: "DOHQR28IH",
              sdur: "12:35",
              re: "Refundable Before Departure",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T22:35:00",
                  ad: "2023-06-10T05:05:00",
                  rbd: "U",
                  fn: "972",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:00",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-06-10T11:25:00",
                  ad: "2023-06-10T13:40:00",
                  rbd: "Q",
                  fn: "522",
                  trn: "3",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "1",
                  oa: "AI",
                  fa: [],
                  fdur: "02:15",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "972/522",
              bgd: [{ qun: "30", wt: "kg" }],
            },
            {
              tid: "h6ygsmmp6noBLMt9_2",
              ref: 2,
              office_id: "DOHQR28IH",
              sdur: "20:40",
              re: "Refundable Before Departure",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T22:35:00",
                  ad: "2023-06-10T05:05:00",
                  rbd: "U",
                  fn: "972",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:00",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-06-10T19:25:00",
                  ad: "2023-06-10T21:45:00",
                  rbd: "Q",
                  fn: "524",
                  trn: "3",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "1",
                  oa: "AI",
                  fa: [],
                  fdur: "02:20",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "972/524",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          pr: {
            bf: 21190.4,
            tf: 30150.4,
            tax: [
              { YR: 896 },
              { YR: 448 },
              { OT: 7616 },
              { dealmkp: 0 },
              { attmkp: 1926.4 },
              { tamkp: 0 },
              { stamkp: 0 },
              { attdisc: 0 },
              { tadisc: 0 },
              { stadisc: 0 },
            ],
            scur: "QAR",
            ucur: "INR",
            exchange_rate: 22.4,
            sup_fare: "860_1260",
            markupnames: [{ code: "MTA", name: "LaxmiAprilMarkup" }],
          },
          paxpr: [
            {
              tf: 30150.4,
              tax: [
                { YR: 896 },
                { YR: 448 },
                { OT: 7616 },
                { attmkp: 1926.4 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              bf: 21190.4,
              fbc: "U6MDLDH",
              fareType: "RP",
              pusup_fare: "860_1260",
              psup_fare: "19264_28224",
              ptype: "ADT",
            },
          ],
          sup: "0",
          airlinesList: "AI",
          servicefee: [
            {
              type: "ATT",
              adt: 2119.04,
              chd: 0,
              inf: 0,
              servicefee_name: "LaxmiServieApril",
              apl_on: "Per Ticket",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 2119.04,
              sup_fee: 0,
            },
            {
              type: "TA",
              adt: 0,
              chd: 0,
              inf: 0,
              apl_on: "",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 0,
              sup_fee: 2119.04,
            },
          ],
        },
        {
          srcorigns: [
            {
              tid: "2qVxDo0ZAmgUtEf9_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "15:55",
              re: "Non-Refundable",
              stop: 2,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "BLR",
                  dd: "2023-06-07T08:00:00",
                  ad: "2023-06-07T09:15:00",
                  rbd: "U",
                  fn: "515",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "01:15",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
                {
                  ref: 2,
                  da: "BLR",
                  ar: "DEL",
                  dd: "2023-06-07T13:20:00",
                  ad: "2023-06-07T15:40:00",
                  rbd: "Q",
                  fn: "501",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:20",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 3,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-06-07T19:50:00",
                  ad: "2023-06-07T21:25:00",
                  rbd: "U",
                  fn: "971",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:05",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "515/501/971",
              bgd: [{ qun: "30", wt: "kg" }],
            },
            {
              tid: "BoCSQETRC2naallG_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "15:55",
              re: "Non-Refundable",
              stop: 2,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "BLR",
                  dd: "2023-06-07T08:00:00",
                  ad: "2023-06-07T09:15:00",
                  rbd: "U",
                  fn: "515",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "01:15",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
                {
                  ref: 2,
                  da: "BLR",
                  ar: "DEL",
                  dd: "2023-06-07T10:00:00",
                  ad: "2023-06-07T12:50:00",
                  rbd: "Q",
                  fn: "505",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:50",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 3,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-06-07T19:50:00",
                  ad: "2023-06-07T21:25:00",
                  rbd: "U",
                  fn: "971",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:05",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "515/505/971",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          destorigins: [
            {
              tid: "2qVxDo0ZAmgUtEf9_2",
              ref: 2,
              office_id: "DOHQR28IH",
              sdur: "08:20",
              re: "Non-Refundable",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T22:35:00",
                  ad: "2023-06-10T05:05:00",
                  rbd: "U",
                  fn: "972",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:00",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-06-10T07:10:00",
                  ad: "2023-06-10T09:25:00",
                  rbd: "Q",
                  fn: "560",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:15",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "972/560",
              bgd: [{ qun: "30", wt: "kg" }],
            },
            {
              tid: "7JZrEtvmPWIuV6Iv_2",
              ref: 2,
              office_id: "DOHQR28IH",
              sdur: "10:45",
              re: "Non-Refundable",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T22:35:00",
                  ad: "2023-06-10T05:05:00",
                  rbd: "U",
                  fn: "972",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:00",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-06-10T09:45:00",
                  ad: "2023-06-10T11:50:00",
                  rbd: "Q",
                  fn: "542",
                  trn: "3",
                  eq: "321",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:05",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "972/542",
              bgd: [{ qun: "30", wt: "kg" }],
            },
            {
              tid: "I1ttwM9S1hPrTEHt_2",
              ref: 2,
              office_id: "DOHQR28IH",
              sdur: "18:05",
              re: "Non-Refundable",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T22:35:00",
                  ad: "2023-06-10T05:05:00",
                  rbd: "U",
                  fn: "972",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:00",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-06-10T16:50:00",
                  ad: "2023-06-10T19:10:00",
                  rbd: "Q",
                  fn: "544",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:20",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "972/544",
              bgd: [{ qun: "30", wt: "kg" }],
            },
            {
              tid: "Hx6MLd27O1ZVkq2c_2",
              ref: 2,
              office_id: "DOHQR28IH",
              sdur: "22:40",
              re: "Non-Refundable",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T22:35:00",
                  ad: "2023-06-10T05:05:00",
                  rbd: "U",
                  fn: "972",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:00",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-06-10T21:30:00",
                  ad: "2023-06-10T23:45:00",
                  rbd: "Q",
                  fn: "839",
                  trn: "3",
                  eq: "321",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:15",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "972/839",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          pr: {
            bf: 24640,
            tf: 34272,
            tax: [
              { YR: 1120 },
              { YR: 672 },
              { OT: 7840 },
              { dealmkp: 0 },
              { attmkp: 2240 },
              { tamkp: 0 },
              { stamkp: 0 },
              { attdisc: 0 },
              { tadisc: 0 },
              { stadisc: 0 },
            ],
            scur: "QAR",
            ucur: "INR",
            exchange_rate: 22.4,
            sup_fare: "1000_1430",
            markupnames: [{ code: "MTA", name: "LaxmiAprilMarkup" }],
          },
          paxpr: [
            {
              tf: 34272,
              tax: [
                { YR: 1120 },
                { YR: 672 },
                { OT: 7840 },
                { attmkp: 2240 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              bf: 24640,
              fbc: "UIP",
              fareType: "RP",
              pusup_fare: "1000_1430",
              psup_fare: "22400_32032",
              ptype: "ADT",
            },
          ],
          sup: "0",
          airlinesList: "AI",
          servicefee: [
            {
              type: "ATT",
              adt: 2464,
              chd: 0,
              inf: 0,
              servicefee_name: "LaxmiServieApril",
              apl_on: "Per Ticket",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 2464,
              sup_fee: 0,
            },
            {
              type: "TA",
              adt: 0,
              chd: 0,
              inf: 0,
              apl_on: "",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 0,
              sup_fee: 2464,
            },
          ],
        },
        {
          srcorigns: [
            {
              tid: "tuJs3o9ORv5eFWIG_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "15:55",
              re: "Non-Refundable",
              stop: 2,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "BLR",
                  dd: "2023-06-07T08:00:00",
                  ad: "2023-06-07T09:15:00",
                  rbd: "U",
                  fn: "515",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "01:15",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
                {
                  ref: 2,
                  da: "BLR",
                  ar: "DEL",
                  dd: "2023-06-07T11:15:00",
                  ad: "2023-06-07T14:20:00",
                  rbd: "Q",
                  fn: "507",
                  eq: "321",
                  ac: "AI",
                  ma: "AI",
                  seat: "1",
                  oa: "AI",
                  fa: [],
                  fdur: "03:05",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 3,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-06-07T19:50:00",
                  ad: "2023-06-07T21:25:00",
                  rbd: "U",
                  fn: "971",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:05",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "515/507/971",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          destorigins: [
            {
              tid: "tuJs3o9ORv5eFWIG_2",
              ref: 2,
              office_id: "DOHQR28IH",
              sdur: "08:20",
              re: "Non-Refundable",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T22:35:00",
                  ad: "2023-06-10T05:05:00",
                  rbd: "U",
                  fn: "972",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:00",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-06-10T07:10:00",
                  ad: "2023-06-10T09:25:00",
                  rbd: "Q",
                  fn: "560",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:15",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "972/560",
              bgd: [{ qun: "30", wt: "kg" }],
            },
            {
              tid: "PqB1yyrzWTNpZwPo_2",
              ref: 2,
              office_id: "DOHQR28IH",
              sdur: "10:45",
              re: "Non-Refundable",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T22:35:00",
                  ad: "2023-06-10T05:05:00",
                  rbd: "U",
                  fn: "972",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:00",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-06-10T09:45:00",
                  ad: "2023-06-10T11:50:00",
                  rbd: "Q",
                  fn: "542",
                  trn: "3",
                  eq: "321",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:05",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "972/542",
              bgd: [{ qun: "30", wt: "kg" }],
            },
            {
              tid: "0WIehXTf0PAqSuTk_2",
              ref: 2,
              office_id: "DOHQR28IH",
              sdur: "18:05",
              re: "Non-Refundable",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T22:35:00",
                  ad: "2023-06-10T05:05:00",
                  rbd: "U",
                  fn: "972",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:00",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-06-10T16:50:00",
                  ad: "2023-06-10T19:10:00",
                  rbd: "Q",
                  fn: "544",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:20",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "972/544",
              bgd: [{ qun: "30", wt: "kg" }],
            },
            {
              tid: "vDAUx0bBD6aQoJDb_2",
              ref: 2,
              office_id: "DOHQR28IH",
              sdur: "22:40",
              re: "Non-Refundable",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T22:35:00",
                  ad: "2023-06-10T05:05:00",
                  rbd: "U",
                  fn: "972",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:00",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-06-10T21:30:00",
                  ad: "2023-06-10T23:45:00",
                  rbd: "Q",
                  fn: "839",
                  trn: "3",
                  eq: "321",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:15",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "972/839",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          pr: {
            bf: 24640,
            tf: 34272,
            tax: [
              { YR: 1120 },
              { YR: 672 },
              { OT: 7840 },
              { dealmkp: 0 },
              { attmkp: 2240 },
              { tamkp: 0 },
              { stamkp: 0 },
              { attdisc: 0 },
              { tadisc: 0 },
              { stadisc: 0 },
            ],
            scur: "QAR",
            ucur: "INR",
            exchange_rate: 22.4,
            sup_fare: "1000_1430",
            markupnames: [{ code: "MTA", name: "LaxmiAprilMarkup" }],
          },
          paxpr: [
            {
              tf: 34272,
              tax: [
                { YR: 1120 },
                { YR: 672 },
                { OT: 7840 },
                { attmkp: 2240 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              bf: 24640,
              fbc: "UIP",
              fareType: "RP",
              pusup_fare: "1000_1430",
              psup_fare: "22400_32032",
              ptype: "ADT",
            },
          ],
          sup: "0",
          airlinesList: "AI",
          servicefee: [
            {
              type: "ATT",
              adt: 2464,
              chd: 0,
              inf: 0,
              servicefee_name: "LaxmiServieApril",
              apl_on: "Per Ticket",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 2464,
              sup_fee: 0,
            },
            {
              type: "TA",
              adt: 0,
              chd: 0,
              inf: 0,
              apl_on: "",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 0,
              sup_fee: 2464,
            },
          ],
        },
        {
          srcorigns: [
            {
              tid: "uC0zQRCoKoigQosr_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "15:55",
              re: "Non-Refundable",
              stop: 2,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "BLR",
                  dd: "2023-06-07T08:00:00",
                  ad: "2023-06-07T09:15:00",
                  rbd: "U",
                  fn: "515",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "01:15",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
                {
                  ref: 2,
                  da: "BLR",
                  ar: "DEL",
                  dd: "2023-06-07T13:20:00",
                  ad: "2023-06-07T15:40:00",
                  rbd: "Q",
                  fn: "501",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:20",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 3,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-06-07T19:50:00",
                  ad: "2023-06-07T21:25:00",
                  rbd: "U",
                  fn: "971",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:05",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "515/501/971",
              bgd: [{ qun: "30", wt: "kg" }],
            },
            {
              tid: "U7TFzAbsPoLGpMuH_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "15:55",
              re: "Non-Refundable",
              stop: 2,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "BLR",
                  dd: "2023-06-07T08:00:00",
                  ad: "2023-06-07T09:15:00",
                  rbd: "U",
                  fn: "515",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "01:15",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
                {
                  ref: 2,
                  da: "BLR",
                  ar: "DEL",
                  dd: "2023-06-07T10:00:00",
                  ad: "2023-06-07T12:50:00",
                  rbd: "Q",
                  fn: "505",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:50",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 3,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-06-07T19:50:00",
                  ad: "2023-06-07T21:25:00",
                  rbd: "U",
                  fn: "971",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:05",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "515/505/971",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          destorigins: [
            {
              tid: "uC0zQRCoKoigQosr_2",
              ref: 2,
              office_id: "DOHQR28IH",
              sdur: "12:35",
              re: "Non-Refundable",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T22:35:00",
                  ad: "2023-06-10T05:05:00",
                  rbd: "U",
                  fn: "972",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:00",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-06-10T11:25:00",
                  ad: "2023-06-10T13:40:00",
                  rbd: "Q",
                  fn: "522",
                  trn: "3",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "1",
                  oa: "AI",
                  fa: [],
                  fdur: "02:15",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "972/522",
              bgd: [{ qun: "30", wt: "kg" }],
            },
            {
              tid: "SWzD8n17rBunaIOP_2",
              ref: 2,
              office_id: "DOHQR28IH",
              sdur: "20:40",
              re: "Non-Refundable",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T22:35:00",
                  ad: "2023-06-10T05:05:00",
                  rbd: "U",
                  fn: "972",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:00",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-06-10T19:25:00",
                  ad: "2023-06-10T21:45:00",
                  rbd: "Q",
                  fn: "524",
                  trn: "3",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "1",
                  oa: "AI",
                  fa: [],
                  fdur: "02:20",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "972/524",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          pr: {
            bf: 24640,
            tf: 34272,
            tax: [
              { YR: 1120 },
              { YR: 672 },
              { OT: 7840 },
              { dealmkp: 0 },
              { attmkp: 2240 },
              { tamkp: 0 },
              { stamkp: 0 },
              { attdisc: 0 },
              { tadisc: 0 },
              { stadisc: 0 },
            ],
            scur: "QAR",
            ucur: "INR",
            exchange_rate: 22.4,
            sup_fare: "1000_1430",
            markupnames: [{ code: "MTA", name: "LaxmiAprilMarkup" }],
          },
          paxpr: [
            {
              tf: 34272,
              tax: [
                { YR: 1120 },
                { YR: 672 },
                { OT: 7840 },
                { attmkp: 2240 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              bf: 24640,
              fbc: "UIP",
              fareType: "RP",
              pusup_fare: "1000_1430",
              psup_fare: "22400_32032",
              ptype: "ADT",
            },
          ],
          sup: "0",
          airlinesList: "AI",
          servicefee: [
            {
              type: "ATT",
              adt: 2464,
              chd: 0,
              inf: 0,
              servicefee_name: "LaxmiServieApril",
              apl_on: "Per Ticket",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 2464,
              sup_fee: 0,
            },
            {
              type: "TA",
              adt: 0,
              chd: 0,
              inf: 0,
              apl_on: "",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 0,
              sup_fee: 2464,
            },
          ],
        },
        {
          srcorigns: [
            {
              tid: "0Rnf7WcgXw1TIFhe_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "15:55",
              re: "Non-Refundable",
              stop: 2,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "BLR",
                  dd: "2023-06-07T08:00:00",
                  ad: "2023-06-07T09:15:00",
                  rbd: "U",
                  fn: "515",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "01:15",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
                {
                  ref: 2,
                  da: "BLR",
                  ar: "DEL",
                  dd: "2023-06-07T11:15:00",
                  ad: "2023-06-07T14:20:00",
                  rbd: "Q",
                  fn: "507",
                  eq: "321",
                  ac: "AI",
                  ma: "AI",
                  seat: "1",
                  oa: "AI",
                  fa: [],
                  fdur: "03:05",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 3,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-06-07T19:50:00",
                  ad: "2023-06-07T21:25:00",
                  rbd: "U",
                  fn: "971",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:05",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "515/507/971",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          destorigins: [
            {
              tid: "0Rnf7WcgXw1TIFhe_2",
              ref: 2,
              office_id: "DOHQR28IH",
              sdur: "12:35",
              re: "Non-Refundable",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T22:35:00",
                  ad: "2023-06-10T05:05:00",
                  rbd: "U",
                  fn: "972",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:00",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-06-10T11:25:00",
                  ad: "2023-06-10T13:40:00",
                  rbd: "Q",
                  fn: "522",
                  trn: "3",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "1",
                  oa: "AI",
                  fa: [],
                  fdur: "02:15",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "972/522",
              bgd: [{ qun: "30", wt: "kg" }],
            },
            {
              tid: "bIeUGuZDE4NtX8n7_2",
              ref: 2,
              office_id: "DOHQR28IH",
              sdur: "20:40",
              re: "Non-Refundable",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T22:35:00",
                  ad: "2023-06-10T05:05:00",
                  rbd: "U",
                  fn: "972",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:00",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-06-10T19:25:00",
                  ad: "2023-06-10T21:45:00",
                  rbd: "Q",
                  fn: "524",
                  trn: "3",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "1",
                  oa: "AI",
                  fa: [],
                  fdur: "02:20",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "972/524",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          pr: {
            bf: 24640,
            tf: 34272,
            tax: [
              { YR: 1120 },
              { YR: 672 },
              { OT: 7840 },
              { dealmkp: 0 },
              { attmkp: 2240 },
              { tamkp: 0 },
              { stamkp: 0 },
              { attdisc: 0 },
              { tadisc: 0 },
              { stadisc: 0 },
            ],
            scur: "QAR",
            ucur: "INR",
            exchange_rate: 22.4,
            sup_fare: "1000_1430",
            markupnames: [{ code: "MTA", name: "LaxmiAprilMarkup" }],
          },
          paxpr: [
            {
              tf: 34272,
              tax: [
                { YR: 1120 },
                { YR: 672 },
                { OT: 7840 },
                { attmkp: 2240 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              bf: 24640,
              fbc: "UIP",
              fareType: "RP",
              pusup_fare: "1000_1430",
              psup_fare: "22400_32032",
              ptype: "ADT",
            },
          ],
          sup: "0",
          airlinesList: "AI",
          servicefee: [
            {
              type: "ATT",
              adt: 2464,
              chd: 0,
              inf: 0,
              servicefee_name: "LaxmiServieApril",
              apl_on: "Per Ticket",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 2464,
              sup_fee: 0,
            },
            {
              type: "TA",
              adt: 0,
              chd: 0,
              inf: 0,
              apl_on: "",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 0,
              sup_fee: 2464,
            },
          ],
        },
        {
          srcorigns: [
            {
              tid: "ucse1Idesi3O6KX9_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "09:25",
              re: "Non-Refundable",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DEL",
                  dd: "2023-06-07T14:30:00",
                  ad: "2023-06-07T16:55:00",
                  rbd: "Q",
                  fn: "523",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "1",
                  oa: "AI",
                  fa: [],
                  fdur: "02:25",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-06-07T19:50:00",
                  ad: "2023-06-07T21:25:00",
                  rbd: "U",
                  fn: "971",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:05",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "523/971",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          destorigins: [
            {
              tid: "ucse1Idesi3O6KX9_2",
              ref: 2,
              office_id: "DOHQR28IH",
              sdur: "34:05",
              re: "Non-Refundable",
              stop: 2,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T22:35:00",
                  ad: "2023-06-10T05:05:00",
                  rbd: "U",
                  fn: "972",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:00",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "BLR",
                  dd: "2023-06-10T13:30:00",
                  ad: "2023-06-10T16:15:00",
                  rbd: "Q",
                  fn: "502",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:45",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
                {
                  ref: 3,
                  da: "BLR",
                  ar: "HYD",
                  dd: "2023-06-11T10:00:00",
                  ad: "2023-06-11T11:10:00",
                  rbd: "U",
                  fn: "516",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "8",
                  oa: "AI",
                  fa: [],
                  fdur: "01:10",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
              ],
              flightNumbers: "972/502/516",
              bgd: [{ qun: "30", wt: "kg" }],
            },
            {
              tid: "aZdxWrnUC8GOkXOT_2",
              ref: 2,
              office_id: "DOHQR28IH",
              sdur: "34:05",
              re: "Non-Refundable",
              stop: 2,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T22:35:00",
                  ad: "2023-06-10T05:05:00",
                  rbd: "U",
                  fn: "972",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:00",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "BLR",
                  dd: "2023-06-10T09:45:00",
                  ad: "2023-06-10T12:30:00",
                  rbd: "Q",
                  fn: "506",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:45",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
                {
                  ref: 3,
                  da: "BLR",
                  ar: "HYD",
                  dd: "2023-06-11T10:00:00",
                  ad: "2023-06-11T11:10:00",
                  rbd: "U",
                  fn: "516",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "8",
                  oa: "AI",
                  fa: [],
                  fdur: "01:10",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
              ],
              flightNumbers: "972/506/516",
              bgd: [{ qun: "30", wt: "kg" }],
            },
            {
              tid: "EWQ3ydxmR9B7KdZ6_2",
              ref: 2,
              office_id: "DOHQR28IH",
              sdur: "34:05",
              re: "Non-Refundable",
              stop: 2,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T22:35:00",
                  ad: "2023-06-10T05:05:00",
                  rbd: "U",
                  fn: "972",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:00",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "BLR",
                  dd: "2023-06-10T17:15:00",
                  ad: "2023-06-10T20:15:00",
                  rbd: "Q",
                  fn: "807",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "03:00",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
                {
                  ref: 3,
                  da: "BLR",
                  ar: "HYD",
                  dd: "2023-06-11T10:00:00",
                  ad: "2023-06-11T11:10:00",
                  rbd: "U",
                  fn: "516",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "8",
                  oa: "AI",
                  fa: [],
                  fdur: "01:10",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
              ],
              flightNumbers: "972/807/516",
              bgd: [{ qun: "30", wt: "kg" }],
            },
            {
              tid: "cgCuRI8vKDtNOS3Z_2",
              ref: 2,
              office_id: "DOHQR28IH",
              sdur: "34:05",
              re: "Non-Refundable",
              stop: 2,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T22:35:00",
                  ad: "2023-06-10T05:05:00",
                  rbd: "U",
                  fn: "972",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:00",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "BLR",
                  dd: "2023-06-10T14:55:00",
                  ad: "2023-06-10T18:20:00",
                  rbd: "Q",
                  fn: "815",
                  trn: "3",
                  eq: "321",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "03:25",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
                {
                  ref: 3,
                  da: "BLR",
                  ar: "HYD",
                  dd: "2023-06-11T10:00:00",
                  ad: "2023-06-11T11:10:00",
                  rbd: "U",
                  fn: "516",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "8",
                  oa: "AI",
                  fa: [],
                  fdur: "01:10",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
              ],
              flightNumbers: "972/815/516",
              bgd: [{ qun: "30", wt: "kg" }],
            },
            {
              tid: "cbnnrhTqKbPtoBZF_2",
              ref: 2,
              office_id: "DOHQR28IH",
              sdur: "34:05",
              re: "Non-Refundable",
              stop: 2,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T22:35:00",
                  ad: "2023-06-10T05:05:00",
                  rbd: "U",
                  fn: "972",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:00",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "BLR",
                  dd: "2023-06-10T21:15:00",
                  ad: "2023-06-11T00:05:00",
                  rbd: "Q",
                  fn: "504",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:50",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
                {
                  ref: 3,
                  da: "BLR",
                  ar: "HYD",
                  dd: "2023-06-11T10:00:00",
                  ad: "2023-06-11T11:10:00",
                  rbd: "U",
                  fn: "516",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "8",
                  oa: "AI",
                  fa: [],
                  fdur: "01:10",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
              ],
              flightNumbers: "972/504/516",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          pr: {
            bf: 24640,
            tf: 34272,
            tax: [
              { YR: 1120 },
              { YR: 672 },
              { OT: 7840 },
              { dealmkp: 0 },
              { attmkp: 2240 },
              { tamkp: 0 },
              { stamkp: 0 },
              { attdisc: 0 },
              { tadisc: 0 },
              { stadisc: 0 },
            ],
            scur: "QAR",
            ucur: "INR",
            exchange_rate: 22.4,
            sup_fare: "1000_1430",
            markupnames: [{ code: "MTA", name: "LaxmiAprilMarkup" }],
          },
          paxpr: [
            {
              tf: 34272,
              tax: [
                { YR: 1120 },
                { YR: 672 },
                { OT: 7840 },
                { attmkp: 2240 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              bf: 24640,
              fbc: "U6MDLDH",
              fareType: "RP",
              pusup_fare: "1000_1430",
              psup_fare: "22400_32032",
              ptype: "ADT",
            },
          ],
          sup: "0",
          airlinesList: "AI",
          servicefee: [
            {
              type: "ATT",
              adt: 2464,
              chd: 0,
              inf: 0,
              servicefee_name: "LaxmiServieApril",
              apl_on: "Per Ticket",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 2464,
              sup_fee: 0,
            },
            {
              type: "TA",
              adt: 0,
              chd: 0,
              inf: 0,
              apl_on: "",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 0,
              sup_fee: 2464,
            },
          ],
        },
        {
          srcorigns: [
            {
              tid: "3psp7X5cvuociQsf_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "09:25",
              re: "Non-Refundable",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DEL",
                  dd: "2023-06-07T14:30:00",
                  ad: "2023-06-07T16:55:00",
                  rbd: "Q",
                  fn: "523",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "1",
                  oa: "AI",
                  fa: [],
                  fdur: "02:25",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-06-07T19:50:00",
                  ad: "2023-06-07T21:25:00",
                  rbd: "U",
                  fn: "971",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:05",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "523/971",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          destorigins: [
            {
              tid: "3psp7X5cvuociQsf_2",
              ref: 2,
              office_id: "DOHQR28IH",
              sdur: "34:05",
              re: "Non-Refundable",
              stop: 2,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T22:35:00",
                  ad: "2023-06-10T05:05:00",
                  rbd: "U",
                  fn: "972",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:00",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "BLR",
                  dd: "2023-06-10T07:30:00",
                  ad: "2023-06-10T10:30:00",
                  rbd: "Q",
                  fn: "813",
                  trn: "3",
                  eq: "321",
                  ac: "AI",
                  ma: "AI",
                  seat: "1",
                  oa: "AI",
                  fa: [],
                  fdur: "03:00",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
                {
                  ref: 3,
                  da: "BLR",
                  ar: "HYD",
                  dd: "2023-06-11T10:00:00",
                  ad: "2023-06-11T11:10:00",
                  rbd: "U",
                  fn: "516",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "8",
                  oa: "AI",
                  fa: [],
                  fdur: "01:10",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
              ],
              flightNumbers: "972/813/516",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          pr: {
            bf: 24640,
            tf: 34272,
            tax: [
              { YR: 1120 },
              { YR: 672 },
              { OT: 7840 },
              { dealmkp: 0 },
              { attmkp: 2240 },
              { tamkp: 0 },
              { stamkp: 0 },
              { attdisc: 0 },
              { tadisc: 0 },
              { stadisc: 0 },
            ],
            scur: "QAR",
            ucur: "INR",
            exchange_rate: 22.4,
            sup_fare: "1000_1430",
            markupnames: [{ code: "MTA", name: "LaxmiAprilMarkup" }],
          },
          paxpr: [
            {
              tf: 34272,
              tax: [
                { YR: 1120 },
                { YR: 672 },
                { OT: 7840 },
                { attmkp: 2240 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              bf: 24640,
              fbc: "U6MDLDH",
              fareType: "RP",
              pusup_fare: "1000_1430",
              psup_fare: "22400_32032",
              ptype: "ADT",
            },
          ],
          sup: "0",
          airlinesList: "AI",
          servicefee: [
            {
              type: "ATT",
              adt: 2464,
              chd: 0,
              inf: 0,
              servicefee_name: "LaxmiServieApril",
              apl_on: "Per Ticket",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 2464,
              sup_fee: 0,
            },
            {
              type: "TA",
              adt: 0,
              chd: 0,
              inf: 0,
              apl_on: "",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 0,
              sup_fee: 2464,
            },
          ],
        },
        {
          srcorigns: [
            {
              tid: "8kf1TdZUea7xfT83_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "14:00",
              re: "Non-Refundable",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DEL",
                  dd: "2023-06-07T09:55:00",
                  ad: "2023-06-07T12:20:00",
                  rbd: "Q",
                  fn: "543",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:25",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-06-07T19:50:00",
                  ad: "2023-06-07T21:25:00",
                  rbd: "U",
                  fn: "971",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:05",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "543/971",
              bgd: [{ qun: "30", wt: "kg" }],
            },
            {
              tid: "31Km5JfTiJ5S8r5o_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "17:15",
              re: "Non-Refundable",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DEL",
                  dd: "2023-06-07T06:40:00",
                  ad: "2023-06-07T08:55:00",
                  rbd: "Q",
                  fn: "559",
                  eq: "321",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:15",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-06-07T19:50:00",
                  ad: "2023-06-07T21:25:00",
                  rbd: "U",
                  fn: "971",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:05",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "559/971",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          destorigins: [
            {
              tid: "8kf1TdZUea7xfT83_2",
              ref: 2,
              office_id: "DOHQR28IH",
              sdur: "34:05",
              re: "Non-Refundable",
              stop: 2,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T22:35:00",
                  ad: "2023-06-10T05:05:00",
                  rbd: "U",
                  fn: "972",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:00",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "BLR",
                  dd: "2023-06-10T21:15:00",
                  ad: "2023-06-11T00:05:00",
                  rbd: "Q",
                  fn: "504",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:50",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
                {
                  ref: 3,
                  da: "BLR",
                  ar: "HYD",
                  dd: "2023-06-11T10:00:00",
                  ad: "2023-06-11T11:10:00",
                  rbd: "U",
                  fn: "516",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "8",
                  oa: "AI",
                  fa: [],
                  fdur: "01:10",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
              ],
              flightNumbers: "972/504/516",
              bgd: [{ qun: "30", wt: "kg" }],
            },
            {
              tid: "94w9yxyd6qVG7C6C_2",
              ref: 2,
              office_id: "DOHQR28IH",
              sdur: "34:05",
              re: "Non-Refundable",
              stop: 2,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T22:35:00",
                  ad: "2023-06-10T05:05:00",
                  rbd: "U",
                  fn: "972",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:00",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "BLR",
                  dd: "2023-06-10T13:30:00",
                  ad: "2023-06-10T16:15:00",
                  rbd: "Q",
                  fn: "502",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:45",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
                {
                  ref: 3,
                  da: "BLR",
                  ar: "HYD",
                  dd: "2023-06-11T10:00:00",
                  ad: "2023-06-11T11:10:00",
                  rbd: "U",
                  fn: "516",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "8",
                  oa: "AI",
                  fa: [],
                  fdur: "01:10",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
              ],
              flightNumbers: "972/502/516",
              bgd: [{ qun: "30", wt: "kg" }],
            },
            {
              tid: "LkDJzmTbJlJ40yOf_2",
              ref: 2,
              office_id: "DOHQR28IH",
              sdur: "34:05",
              re: "Non-Refundable",
              stop: 2,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T22:35:00",
                  ad: "2023-06-10T05:05:00",
                  rbd: "U",
                  fn: "972",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:00",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "BLR",
                  dd: "2023-06-10T09:45:00",
                  ad: "2023-06-10T12:30:00",
                  rbd: "Q",
                  fn: "506",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:45",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
                {
                  ref: 3,
                  da: "BLR",
                  ar: "HYD",
                  dd: "2023-06-11T10:00:00",
                  ad: "2023-06-11T11:10:00",
                  rbd: "U",
                  fn: "516",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "8",
                  oa: "AI",
                  fa: [],
                  fdur: "01:10",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
              ],
              flightNumbers: "972/506/516",
              bgd: [{ qun: "30", wt: "kg" }],
            },
            {
              tid: "FcTnvZGOaD32OfxH_2",
              ref: 2,
              office_id: "DOHQR28IH",
              sdur: "34:05",
              re: "Non-Refundable",
              stop: 2,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T22:35:00",
                  ad: "2023-06-10T05:05:00",
                  rbd: "U",
                  fn: "972",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:00",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "BLR",
                  dd: "2023-06-10T17:15:00",
                  ad: "2023-06-10T20:15:00",
                  rbd: "Q",
                  fn: "807",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "03:00",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
                {
                  ref: 3,
                  da: "BLR",
                  ar: "HYD",
                  dd: "2023-06-11T10:00:00",
                  ad: "2023-06-11T11:10:00",
                  rbd: "U",
                  fn: "516",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "8",
                  oa: "AI",
                  fa: [],
                  fdur: "01:10",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
              ],
              flightNumbers: "972/807/516",
              bgd: [{ qun: "30", wt: "kg" }],
            },
            {
              tid: "2bd0izwRkAFVr0ar_2",
              ref: 2,
              office_id: "DOHQR28IH",
              sdur: "34:05",
              re: "Non-Refundable",
              stop: 2,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T22:35:00",
                  ad: "2023-06-10T05:05:00",
                  rbd: "U",
                  fn: "972",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:00",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "BLR",
                  dd: "2023-06-10T14:55:00",
                  ad: "2023-06-10T18:20:00",
                  rbd: "Q",
                  fn: "815",
                  trn: "3",
                  eq: "321",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "03:25",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
                {
                  ref: 3,
                  da: "BLR",
                  ar: "HYD",
                  dd: "2023-06-11T10:00:00",
                  ad: "2023-06-11T11:10:00",
                  rbd: "U",
                  fn: "516",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "8",
                  oa: "AI",
                  fa: [],
                  fdur: "01:10",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
              ],
              flightNumbers: "972/815/516",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          pr: {
            bf: 24640,
            tf: 34272,
            tax: [
              { YR: 1120 },
              { YR: 672 },
              { OT: 7840 },
              { dealmkp: 0 },
              { attmkp: 2240 },
              { tamkp: 0 },
              { stamkp: 0 },
              { attdisc: 0 },
              { tadisc: 0 },
              { stadisc: 0 },
            ],
            scur: "QAR",
            ucur: "INR",
            exchange_rate: 22.4,
            sup_fare: "1000_1430",
            markupnames: [{ code: "MTA", name: "LaxmiAprilMarkup" }],
          },
          paxpr: [
            {
              tf: 34272,
              tax: [
                { YR: 1120 },
                { YR: 672 },
                { OT: 7840 },
                { attmkp: 2240 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              bf: 24640,
              fbc: "U6MDLDH",
              fareType: "RP",
              pusup_fare: "1000_1430",
              psup_fare: "22400_32032",
              ptype: "ADT",
            },
          ],
          sup: "0",
          airlinesList: "AI",
          servicefee: [
            {
              type: "ATT",
              adt: 2464,
              chd: 0,
              inf: 0,
              servicefee_name: "LaxmiServieApril",
              apl_on: "Per Ticket",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 2464,
              sup_fee: 0,
            },
            {
              type: "TA",
              adt: 0,
              chd: 0,
              inf: 0,
              apl_on: "",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 0,
              sup_fee: 2464,
            },
          ],
        },
        {
          srcorigns: [
            {
              tid: "4DtWm21wRZkbCwTU_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "14:00",
              re: "Non-Refundable",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DEL",
                  dd: "2023-06-07T09:55:00",
                  ad: "2023-06-07T12:20:00",
                  rbd: "Q",
                  fn: "543",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:25",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-06-07T19:50:00",
                  ad: "2023-06-07T21:25:00",
                  rbd: "U",
                  fn: "971",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:05",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "543/971",
              bgd: [{ qun: "30", wt: "kg" }],
            },
            {
              tid: "C7BT9sy0fW0MTEgt_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "17:15",
              re: "Non-Refundable",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DEL",
                  dd: "2023-06-07T06:40:00",
                  ad: "2023-06-07T08:55:00",
                  rbd: "Q",
                  fn: "559",
                  eq: "321",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:15",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-06-07T19:50:00",
                  ad: "2023-06-07T21:25:00",
                  rbd: "U",
                  fn: "971",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:05",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "559/971",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          destorigins: [
            {
              tid: "4DtWm21wRZkbCwTU_2",
              ref: 2,
              office_id: "DOHQR28IH",
              sdur: "34:05",
              re: "Non-Refundable",
              stop: 2,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T22:35:00",
                  ad: "2023-06-10T05:05:00",
                  rbd: "U",
                  fn: "972",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:00",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "BLR",
                  dd: "2023-06-10T07:30:00",
                  ad: "2023-06-10T10:30:00",
                  rbd: "Q",
                  fn: "813",
                  trn: "3",
                  eq: "321",
                  ac: "AI",
                  ma: "AI",
                  seat: "1",
                  oa: "AI",
                  fa: [],
                  fdur: "03:00",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
                {
                  ref: 3,
                  da: "BLR",
                  ar: "HYD",
                  dd: "2023-06-11T10:00:00",
                  ad: "2023-06-11T11:10:00",
                  rbd: "U",
                  fn: "516",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "8",
                  oa: "AI",
                  fa: [],
                  fdur: "01:10",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
              ],
              flightNumbers: "972/813/516",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          pr: {
            bf: 24640,
            tf: 34272,
            tax: [
              { YR: 1120 },
              { YR: 672 },
              { OT: 7840 },
              { dealmkp: 0 },
              { attmkp: 2240 },
              { tamkp: 0 },
              { stamkp: 0 },
              { attdisc: 0 },
              { tadisc: 0 },
              { stadisc: 0 },
            ],
            scur: "QAR",
            ucur: "INR",
            exchange_rate: 22.4,
            sup_fare: "1000_1430",
            markupnames: [{ code: "MTA", name: "LaxmiAprilMarkup" }],
          },
          paxpr: [
            {
              tf: 34272,
              tax: [
                { YR: 1120 },
                { YR: 672 },
                { OT: 7840 },
                { attmkp: 2240 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              bf: 24640,
              fbc: "U6MDLDH",
              fareType: "RP",
              pusup_fare: "1000_1430",
              psup_fare: "22400_32032",
              ptype: "ADT",
            },
          ],
          sup: "0",
          airlinesList: "AI",
          servicefee: [
            {
              type: "ATT",
              adt: 2464,
              chd: 0,
              inf: 0,
              servicefee_name: "LaxmiServieApril",
              apl_on: "Per Ticket",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 2464,
              sup_fee: 0,
            },
            {
              type: "TA",
              adt: 0,
              chd: 0,
              inf: 0,
              apl_on: "",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 0,
              sup_fee: 2464,
            },
          ],
        },
        {
          srcorigns: [
            {
              tid: "xo4NtAzOhvbubUJl_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "39:55",
              re: "Non-Refundable",
              stop: 2,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "BLR",
                  dd: "2023-06-07T08:00:00",
                  ad: "2023-06-07T09:15:00",
                  rbd: "U",
                  fn: "515",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "01:15",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
                {
                  ref: 2,
                  da: "BLR",
                  ar: "DEL",
                  dd: "2023-06-07T17:00:00",
                  ad: "2023-06-07T19:50:00",
                  rbd: "Q",
                  fn: "503",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:50",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 3,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-06-08T19:50:00",
                  ad: "2023-06-08T21:25:00",
                  rbd: "U",
                  fn: "971",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "5",
                  oa: "AI",
                  fa: [],
                  fdur: "04:05",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "515/503/971",
              bgd: [{ qun: "30", wt: "kg" }],
            },
            {
              tid: "sN2F91hLt588iRv1_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "39:55",
              re: "Non-Refundable",
              stop: 2,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "BLR",
                  dd: "2023-06-07T08:00:00",
                  ad: "2023-06-07T09:15:00",
                  rbd: "U",
                  fn: "515",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "01:15",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
                {
                  ref: 2,
                  da: "BLR",
                  ar: "DEL",
                  dd: "2023-06-07T19:15:00",
                  ad: "2023-06-07T22:15:00",
                  rbd: "Q",
                  fn: "509",
                  eq: "321",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "03:00",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 3,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-06-08T19:50:00",
                  ad: "2023-06-08T21:25:00",
                  rbd: "U",
                  fn: "971",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "5",
                  oa: "AI",
                  fa: [],
                  fdur: "04:05",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "515/509/971",
              bgd: [{ qun: "30", wt: "kg" }],
            },
            {
              tid: "iRd8t9XTV8JHsnBa_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "39:55",
              re: "Non-Refundable",
              stop: 2,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "BLR",
                  dd: "2023-06-07T08:00:00",
                  ad: "2023-06-07T09:15:00",
                  rbd: "U",
                  fn: "515",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "01:15",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
                {
                  ref: 2,
                  da: "BLR",
                  ar: "DEL",
                  dd: "2023-06-08T06:10:00",
                  ad: "2023-06-08T08:55:00",
                  rbd: "Q",
                  fn: "804",
                  trn: "1",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:45",
                  cl: "Economy",
                  terminal: { dep: "1", arr: "3" },
                },
                {
                  ref: 3,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-06-08T19:50:00",
                  ad: "2023-06-08T21:25:00",
                  rbd: "U",
                  fn: "971",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "5",
                  oa: "AI",
                  fa: [],
                  fdur: "04:05",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "515/804/971",
              bgd: [{ qun: "30", wt: "kg" }],
            },
            {
              tid: "7fKKugMUsTJoaghQ_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "39:55",
              re: "Non-Refundable",
              stop: 2,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "BLR",
                  dd: "2023-06-07T08:00:00",
                  ad: "2023-06-07T09:15:00",
                  rbd: "U",
                  fn: "515",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "01:15",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
                {
                  ref: 2,
                  da: "BLR",
                  ar: "DEL",
                  dd: "2023-06-07T21:00:00",
                  ad: "2023-06-08T00:05:00",
                  rbd: "Q",
                  fn: "808",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "03:05",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 3,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-06-08T19:50:00",
                  ad: "2023-06-08T21:25:00",
                  rbd: "U",
                  fn: "971",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "5",
                  oa: "AI",
                  fa: [],
                  fdur: "04:05",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "515/808/971",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          destorigins: [
            {
              tid: "xo4NtAzOhvbubUJl_2",
              ref: 2,
              office_id: "DOHQR28IH",
              sdur: "08:20",
              re: "Non-Refundable",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T22:35:00",
                  ad: "2023-06-10T05:05:00",
                  rbd: "U",
                  fn: "972",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:00",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-06-10T07:10:00",
                  ad: "2023-06-10T09:25:00",
                  rbd: "Q",
                  fn: "560",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:15",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "972/560",
              bgd: [{ qun: "30", wt: "kg" }],
            },
            {
              tid: "mG3OV5pncNxIn3nT_2",
              ref: 2,
              office_id: "DOHQR28IH",
              sdur: "10:45",
              re: "Non-Refundable",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T22:35:00",
                  ad: "2023-06-10T05:05:00",
                  rbd: "U",
                  fn: "972",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:00",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-06-10T09:45:00",
                  ad: "2023-06-10T11:50:00",
                  rbd: "Q",
                  fn: "542",
                  trn: "3",
                  eq: "321",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:05",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "972/542",
              bgd: [{ qun: "30", wt: "kg" }],
            },
            {
              tid: "SuxDDpyxUCXgyAAO_2",
              ref: 2,
              office_id: "DOHQR28IH",
              sdur: "18:05",
              re: "Non-Refundable",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T22:35:00",
                  ad: "2023-06-10T05:05:00",
                  rbd: "U",
                  fn: "972",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:00",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-06-10T16:50:00",
                  ad: "2023-06-10T19:10:00",
                  rbd: "Q",
                  fn: "544",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:20",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "972/544",
              bgd: [{ qun: "30", wt: "kg" }],
            },
            {
              tid: "7JRTPm5nx3WdA5aT_2",
              ref: 2,
              office_id: "DOHQR28IH",
              sdur: "22:40",
              re: "Non-Refundable",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T22:35:00",
                  ad: "2023-06-10T05:05:00",
                  rbd: "U",
                  fn: "972",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:00",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-06-10T21:30:00",
                  ad: "2023-06-10T23:45:00",
                  rbd: "Q",
                  fn: "839",
                  trn: "3",
                  eq: "321",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:15",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "972/839",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          pr: {
            bf: 24640,
            tf: 34272,
            tax: [
              { YR: 1120 },
              { YR: 672 },
              { OT: 7840 },
              { dealmkp: 0 },
              { attmkp: 2240 },
              { tamkp: 0 },
              { stamkp: 0 },
              { attdisc: 0 },
              { tadisc: 0 },
              { stadisc: 0 },
            ],
            scur: "QAR",
            ucur: "INR",
            exchange_rate: 22.4,
            sup_fare: "1000_1430",
            markupnames: [{ code: "MTA", name: "LaxmiAprilMarkup" }],
          },
          paxpr: [
            {
              tf: 34272,
              tax: [
                { YR: 1120 },
                { YR: 672 },
                { OT: 7840 },
                { attmkp: 2240 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              bf: 24640,
              fbc: "UIP",
              fareType: "RP",
              pusup_fare: "1000_1430",
              psup_fare: "22400_32032",
              ptype: "ADT",
            },
          ],
          sup: "0",
          airlinesList: "AI",
          servicefee: [
            {
              type: "ATT",
              adt: 2464,
              chd: 0,
              inf: 0,
              servicefee_name: "LaxmiServieApril",
              apl_on: "Per Ticket",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 2464,
              sup_fee: 0,
            },
            {
              type: "TA",
              adt: 0,
              chd: 0,
              inf: 0,
              apl_on: "",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 0,
              sup_fee: 2464,
            },
          ],
        },
        {
          srcorigns: [
            {
              tid: "agtunHBaP3uLRyux_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "39:55",
              re: "Non-Refundable",
              stop: 2,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "BLR",
                  dd: "2023-06-07T08:00:00",
                  ad: "2023-06-07T09:15:00",
                  rbd: "U",
                  fn: "515",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "01:15",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
                {
                  ref: 2,
                  da: "BLR",
                  ar: "DEL",
                  dd: "2023-06-07T17:00:00",
                  ad: "2023-06-07T19:50:00",
                  rbd: "Q",
                  fn: "503",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:50",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 3,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-06-08T19:50:00",
                  ad: "2023-06-08T21:25:00",
                  rbd: "U",
                  fn: "971",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "5",
                  oa: "AI",
                  fa: [],
                  fdur: "04:05",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "515/503/971",
              bgd: [{ qun: "30", wt: "kg" }],
            },
            {
              tid: "G4y6Pu7FGSoaJNuK_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "39:55",
              re: "Non-Refundable",
              stop: 2,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "BLR",
                  dd: "2023-06-07T08:00:00",
                  ad: "2023-06-07T09:15:00",
                  rbd: "U",
                  fn: "515",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "01:15",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
                {
                  ref: 2,
                  da: "BLR",
                  ar: "DEL",
                  dd: "2023-06-07T19:15:00",
                  ad: "2023-06-07T22:15:00",
                  rbd: "Q",
                  fn: "509",
                  eq: "321",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "03:00",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 3,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-06-08T19:50:00",
                  ad: "2023-06-08T21:25:00",
                  rbd: "U",
                  fn: "971",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "5",
                  oa: "AI",
                  fa: [],
                  fdur: "04:05",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "515/509/971",
              bgd: [{ qun: "30", wt: "kg" }],
            },
            {
              tid: "zutZABNzCQowMZhr_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "39:55",
              re: "Non-Refundable",
              stop: 2,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "BLR",
                  dd: "2023-06-07T08:00:00",
                  ad: "2023-06-07T09:15:00",
                  rbd: "U",
                  fn: "515",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "01:15",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
                {
                  ref: 2,
                  da: "BLR",
                  ar: "DEL",
                  dd: "2023-06-08T06:10:00",
                  ad: "2023-06-08T08:55:00",
                  rbd: "Q",
                  fn: "804",
                  trn: "1",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:45",
                  cl: "Economy",
                  terminal: { dep: "1", arr: "3" },
                },
                {
                  ref: 3,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-06-08T19:50:00",
                  ad: "2023-06-08T21:25:00",
                  rbd: "U",
                  fn: "971",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "5",
                  oa: "AI",
                  fa: [],
                  fdur: "04:05",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "515/804/971",
              bgd: [{ qun: "30", wt: "kg" }],
            },
            {
              tid: "N9TesOhoMVEiRZGH_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "39:55",
              re: "Non-Refundable",
              stop: 2,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "BLR",
                  dd: "2023-06-07T08:00:00",
                  ad: "2023-06-07T09:15:00",
                  rbd: "U",
                  fn: "515",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "01:15",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
                {
                  ref: 2,
                  da: "BLR",
                  ar: "DEL",
                  dd: "2023-06-07T21:00:00",
                  ad: "2023-06-08T00:05:00",
                  rbd: "Q",
                  fn: "808",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "03:05",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 3,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-06-08T19:50:00",
                  ad: "2023-06-08T21:25:00",
                  rbd: "U",
                  fn: "971",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "5",
                  oa: "AI",
                  fa: [],
                  fdur: "04:05",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "515/808/971",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          destorigins: [
            {
              tid: "agtunHBaP3uLRyux_2",
              ref: 2,
              office_id: "DOHQR28IH",
              sdur: "12:35",
              re: "Non-Refundable",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T22:35:00",
                  ad: "2023-06-10T05:05:00",
                  rbd: "U",
                  fn: "972",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:00",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-06-10T11:25:00",
                  ad: "2023-06-10T13:40:00",
                  rbd: "Q",
                  fn: "522",
                  trn: "3",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "1",
                  oa: "AI",
                  fa: [],
                  fdur: "02:15",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "972/522",
              bgd: [{ qun: "30", wt: "kg" }],
            },
            {
              tid: "O9ef6vZyESMo1FhE_2",
              ref: 2,
              office_id: "DOHQR28IH",
              sdur: "20:40",
              re: "Non-Refundable",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T22:35:00",
                  ad: "2023-06-10T05:05:00",
                  rbd: "U",
                  fn: "972",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:00",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-06-10T19:25:00",
                  ad: "2023-06-10T21:45:00",
                  rbd: "Q",
                  fn: "524",
                  trn: "3",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "1",
                  oa: "AI",
                  fa: [],
                  fdur: "02:20",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "972/524",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          pr: {
            bf: 24640,
            tf: 34272,
            tax: [
              { YR: 1120 },
              { YR: 672 },
              { OT: 7840 },
              { dealmkp: 0 },
              { attmkp: 2240 },
              { tamkp: 0 },
              { stamkp: 0 },
              { attdisc: 0 },
              { tadisc: 0 },
              { stadisc: 0 },
            ],
            scur: "QAR",
            ucur: "INR",
            exchange_rate: 22.4,
            sup_fare: "1000_1430",
            markupnames: [{ code: "MTA", name: "LaxmiAprilMarkup" }],
          },
          paxpr: [
            {
              tf: 34272,
              tax: [
                { YR: 1120 },
                { YR: 672 },
                { OT: 7840 },
                { attmkp: 2240 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              bf: 24640,
              fbc: "UIP",
              fareType: "RP",
              pusup_fare: "1000_1430",
              psup_fare: "22400_32032",
              ptype: "ADT",
            },
          ],
          sup: "0",
          airlinesList: "AI",
          servicefee: [
            {
              type: "ATT",
              adt: 2464,
              chd: 0,
              inf: 0,
              servicefee_name: "LaxmiServieApril",
              apl_on: "Per Ticket",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 2464,
              sup_fee: 0,
            },
            {
              type: "TA",
              adt: 0,
              chd: 0,
              inf: 0,
              apl_on: "",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 0,
              sup_fee: 2464,
            },
          ],
        },
        {
          srcorigns: [
            {
              tid: "8uOnhz52KPEODBT6_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "27:15",
              re: "Non-Refundable",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DEL",
                  dd: "2023-06-07T20:40:00",
                  ad: "2023-06-07T23:00:00",
                  rbd: "Q",
                  fn: "840",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:20",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-06-08T19:50:00",
                  ad: "2023-06-08T21:25:00",
                  rbd: "U",
                  fn: "971",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "5",
                  oa: "AI",
                  fa: [],
                  fdur: "04:05",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "840/971",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          destorigins: [
            {
              tid: "8uOnhz52KPEODBT6_2",
              ref: 2,
              office_id: "DOHQR28IH",
              sdur: "34:05",
              re: "Non-Refundable",
              stop: 2,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T22:35:00",
                  ad: "2023-06-10T05:05:00",
                  rbd: "U",
                  fn: "972",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:00",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "BLR",
                  dd: "2023-06-10T21:15:00",
                  ad: "2023-06-11T00:05:00",
                  rbd: "Q",
                  fn: "504",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:50",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
                {
                  ref: 3,
                  da: "BLR",
                  ar: "HYD",
                  dd: "2023-06-11T10:00:00",
                  ad: "2023-06-11T11:10:00",
                  rbd: "U",
                  fn: "516",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "8",
                  oa: "AI",
                  fa: [],
                  fdur: "01:10",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
              ],
              flightNumbers: "972/504/516",
              bgd: [{ qun: "30", wt: "kg" }],
            },
            {
              tid: "olS2B3sfM2xFpumM_2",
              ref: 2,
              office_id: "DOHQR28IH",
              sdur: "34:05",
              re: "Non-Refundable",
              stop: 2,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T22:35:00",
                  ad: "2023-06-10T05:05:00",
                  rbd: "U",
                  fn: "972",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:00",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "BLR",
                  dd: "2023-06-10T13:30:00",
                  ad: "2023-06-10T16:15:00",
                  rbd: "Q",
                  fn: "502",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:45",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
                {
                  ref: 3,
                  da: "BLR",
                  ar: "HYD",
                  dd: "2023-06-11T10:00:00",
                  ad: "2023-06-11T11:10:00",
                  rbd: "U",
                  fn: "516",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "8",
                  oa: "AI",
                  fa: [],
                  fdur: "01:10",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
              ],
              flightNumbers: "972/502/516",
              bgd: [{ qun: "30", wt: "kg" }],
            },
            {
              tid: "LgSNKOF3JGSChGnZ_2",
              ref: 2,
              office_id: "DOHQR28IH",
              sdur: "34:05",
              re: "Non-Refundable",
              stop: 2,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T22:35:00",
                  ad: "2023-06-10T05:05:00",
                  rbd: "U",
                  fn: "972",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:00",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "BLR",
                  dd: "2023-06-10T09:45:00",
                  ad: "2023-06-10T12:30:00",
                  rbd: "Q",
                  fn: "506",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:45",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
                {
                  ref: 3,
                  da: "BLR",
                  ar: "HYD",
                  dd: "2023-06-11T10:00:00",
                  ad: "2023-06-11T11:10:00",
                  rbd: "U",
                  fn: "516",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "8",
                  oa: "AI",
                  fa: [],
                  fdur: "01:10",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
              ],
              flightNumbers: "972/506/516",
              bgd: [{ qun: "30", wt: "kg" }],
            },
            {
              tid: "Cwr0o3o4tOtMVQC6_2",
              ref: 2,
              office_id: "DOHQR28IH",
              sdur: "34:05",
              re: "Non-Refundable",
              stop: 2,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T22:35:00",
                  ad: "2023-06-10T05:05:00",
                  rbd: "U",
                  fn: "972",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:00",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "BLR",
                  dd: "2023-06-10T17:15:00",
                  ad: "2023-06-10T20:15:00",
                  rbd: "Q",
                  fn: "807",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "03:00",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
                {
                  ref: 3,
                  da: "BLR",
                  ar: "HYD",
                  dd: "2023-06-11T10:00:00",
                  ad: "2023-06-11T11:10:00",
                  rbd: "U",
                  fn: "516",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "8",
                  oa: "AI",
                  fa: [],
                  fdur: "01:10",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
              ],
              flightNumbers: "972/807/516",
              bgd: [{ qun: "30", wt: "kg" }],
            },
            {
              tid: "3JMnfbnRUTMeQD6z_2",
              ref: 2,
              office_id: "DOHQR28IH",
              sdur: "34:05",
              re: "Non-Refundable",
              stop: 2,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T22:35:00",
                  ad: "2023-06-10T05:05:00",
                  rbd: "U",
                  fn: "972",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:00",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "BLR",
                  dd: "2023-06-10T14:55:00",
                  ad: "2023-06-10T18:20:00",
                  rbd: "Q",
                  fn: "815",
                  trn: "3",
                  eq: "321",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "03:25",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
                {
                  ref: 3,
                  da: "BLR",
                  ar: "HYD",
                  dd: "2023-06-11T10:00:00",
                  ad: "2023-06-11T11:10:00",
                  rbd: "U",
                  fn: "516",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "8",
                  oa: "AI",
                  fa: [],
                  fdur: "01:10",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
              ],
              flightNumbers: "972/815/516",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          pr: {
            bf: 24640,
            tf: 34272,
            tax: [
              { YR: 1120 },
              { YR: 672 },
              { OT: 7840 },
              { dealmkp: 0 },
              { attmkp: 2240 },
              { tamkp: 0 },
              { stamkp: 0 },
              { attdisc: 0 },
              { tadisc: 0 },
              { stadisc: 0 },
            ],
            scur: "QAR",
            ucur: "INR",
            exchange_rate: 22.4,
            sup_fare: "1000_1430",
            markupnames: [{ code: "MTA", name: "LaxmiAprilMarkup" }],
          },
          paxpr: [
            {
              tf: 34272,
              tax: [
                { YR: 1120 },
                { YR: 672 },
                { OT: 7840 },
                { attmkp: 2240 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              bf: 24640,
              fbc: "U6MDLDH",
              fareType: "RP",
              pusup_fare: "1000_1430",
              psup_fare: "22400_32032",
              ptype: "ADT",
            },
          ],
          sup: "0",
          airlinesList: "AI",
          servicefee: [
            {
              type: "ATT",
              adt: 2464,
              chd: 0,
              inf: 0,
              servicefee_name: "LaxmiServieApril",
              apl_on: "Per Ticket",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 2464,
              sup_fee: 0,
            },
            {
              type: "TA",
              adt: 0,
              chd: 0,
              inf: 0,
              apl_on: "",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 0,
              sup_fee: 2464,
            },
          ],
        },
        {
          srcorigns: [
            {
              tid: "CdJJqFHQZnizu6ID_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "27:15",
              re: "Non-Refundable",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DEL",
                  dd: "2023-06-07T20:40:00",
                  ad: "2023-06-07T23:00:00",
                  rbd: "Q",
                  fn: "840",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:20",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-06-08T19:50:00",
                  ad: "2023-06-08T21:25:00",
                  rbd: "U",
                  fn: "971",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "5",
                  oa: "AI",
                  fa: [],
                  fdur: "04:05",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "840/971",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          destorigins: [
            {
              tid: "CdJJqFHQZnizu6ID_2",
              ref: 2,
              office_id: "DOHQR28IH",
              sdur: "34:05",
              re: "Non-Refundable",
              stop: 2,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T22:35:00",
                  ad: "2023-06-10T05:05:00",
                  rbd: "U",
                  fn: "972",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:00",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "BLR",
                  dd: "2023-06-10T07:30:00",
                  ad: "2023-06-10T10:30:00",
                  rbd: "Q",
                  fn: "813",
                  trn: "3",
                  eq: "321",
                  ac: "AI",
                  ma: "AI",
                  seat: "1",
                  oa: "AI",
                  fa: [],
                  fdur: "03:00",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
                {
                  ref: 3,
                  da: "BLR",
                  ar: "HYD",
                  dd: "2023-06-11T10:00:00",
                  ad: "2023-06-11T11:10:00",
                  rbd: "U",
                  fn: "516",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "8",
                  oa: "AI",
                  fa: [],
                  fdur: "01:10",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
              ],
              flightNumbers: "972/813/516",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          pr: {
            bf: 24640,
            tf: 34272,
            tax: [
              { YR: 1120 },
              { YR: 672 },
              { OT: 7840 },
              { dealmkp: 0 },
              { attmkp: 2240 },
              { tamkp: 0 },
              { stamkp: 0 },
              { attdisc: 0 },
              { tadisc: 0 },
              { stadisc: 0 },
            ],
            scur: "QAR",
            ucur: "INR",
            exchange_rate: 22.4,
            sup_fare: "1000_1430",
            markupnames: [{ code: "MTA", name: "LaxmiAprilMarkup" }],
          },
          paxpr: [
            {
              tf: 34272,
              tax: [
                { YR: 1120 },
                { YR: 672 },
                { OT: 7840 },
                { attmkp: 2240 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              bf: 24640,
              fbc: "U6MDLDH",
              fareType: "RP",
              pusup_fare: "1000_1430",
              psup_fare: "22400_32032",
              ptype: "ADT",
            },
          ],
          sup: "0",
          airlinesList: "AI",
          servicefee: [
            {
              type: "ATT",
              adt: 2464,
              chd: 0,
              inf: 0,
              servicefee_name: "LaxmiServieApril",
              apl_on: "Per Ticket",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 2464,
              sup_fee: 0,
            },
            {
              type: "TA",
              adt: 0,
              chd: 0,
              inf: 0,
              apl_on: "",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 0,
              sup_fee: 2464,
            },
          ],
        },
        {
          srcorigns: [
            {
              tid: "rodaXFB0SvCIqmd3_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "09:25",
              re: "Non-Refundable",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DEL",
                  dd: "2023-06-07T14:30:00",
                  ad: "2023-06-07T16:55:00",
                  rbd: "Q",
                  fn: "523",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "1",
                  oa: "AI",
                  fa: [],
                  fdur: "02:25",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-06-07T19:50:00",
                  ad: "2023-06-07T21:25:00",
                  rbd: "U",
                  fn: "971",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:05",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "523/971",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          destorigins: [
            {
              tid: "rodaXFB0SvCIqmd3_2",
              ref: 2,
              office_id: "DOHQR28IH",
              sdur: "11:20",
              re: "Non-Refundable",
              stop: 2,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T22:35:00",
                  ad: "2023-06-10T05:05:00",
                  rbd: "U",
                  fn: "972",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:00",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "BOM",
                  dd: "2023-06-10T08:00:00",
                  ad: "2023-06-10T10:10:00",
                  rbd: "U",
                  fn: "665",
                  trn: "3",
                  eq: "321",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:10",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "2" },
                },
                {
                  ref: 3,
                  da: "BOM",
                  ar: "HYD",
                  dd: "2023-06-10T11:00:00",
                  ad: "2023-06-10T12:25:00",
                  rbd: "U",
                  fn: "617",
                  trn: "2",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "1",
                  oa: "AI",
                  fa: [],
                  fdur: "01:25",
                  cl: "Economy",
                  terminal: { dep: "2", arr: "" },
                },
              ],
              flightNumbers: "972/665/617",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          pr: {
            bf: 26364.8,
            tf: 35996.8,
            tax: [
              { YR: 1120 },
              { YR: 672 },
              { OT: 7840 },
              { dealmkp: 0 },
              { attmkp: 2396.8 },
              { tamkp: 0 },
              { stamkp: 0 },
              { attdisc: 0 },
              { tadisc: 0 },
              { stadisc: 0 },
            ],
            scur: "QAR",
            ucur: "INR",
            exchange_rate: 22.4,
            sup_fare: "1070_1500",
            markupnames: [{ code: "MTA", name: "LaxmiAprilMarkup" }],
          },
          paxpr: [
            {
              tf: 35996.8,
              tax: [
                { YR: 1120 },
                { YR: 672 },
                { OT: 7840 },
                { attmkp: 2396.8 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              bf: 26364.8,
              fbc: "U6MDLDH",
              fareType: "RP",
              pusup_fare: "1070_1500",
              psup_fare: "23968_33600",
              ptype: "ADT",
            },
          ],
          sup: "0",
          airlinesList: "AI",
          servicefee: [
            {
              type: "ATT",
              adt: 2636.48,
              chd: 0,
              inf: 0,
              servicefee_name: "LaxmiServieApril",
              apl_on: "Per Ticket",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 2636.48,
              sup_fee: 0,
            },
            {
              type: "TA",
              adt: 0,
              chd: 0,
              inf: 0,
              apl_on: "",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 0,
              sup_fee: 2636.48,
            },
          ],
        },
        {
          srcorigns: [
            {
              tid: "6uZRGzZqf6gxv6ug_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "14:00",
              re: "Non-Refundable",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DEL",
                  dd: "2023-06-07T09:55:00",
                  ad: "2023-06-07T12:20:00",
                  rbd: "Q",
                  fn: "543",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:25",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-06-07T19:50:00",
                  ad: "2023-06-07T21:25:00",
                  rbd: "U",
                  fn: "971",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:05",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "543/971",
              bgd: [{ qun: "30", wt: "kg" }],
            },
            {
              tid: "Jhl4lKQahZ38gt6z_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "17:15",
              re: "Non-Refundable",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DEL",
                  dd: "2023-06-07T06:40:00",
                  ad: "2023-06-07T08:55:00",
                  rbd: "Q",
                  fn: "559",
                  eq: "321",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:15",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-06-07T19:50:00",
                  ad: "2023-06-07T21:25:00",
                  rbd: "U",
                  fn: "971",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:05",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "559/971",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          destorigins: [
            {
              tid: "6uZRGzZqf6gxv6ug_2",
              ref: 2,
              office_id: "DOHQR28IH",
              sdur: "11:20",
              re: "Non-Refundable",
              stop: 2,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T22:35:00",
                  ad: "2023-06-10T05:05:00",
                  rbd: "U",
                  fn: "972",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:00",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "BOM",
                  dd: "2023-06-10T08:00:00",
                  ad: "2023-06-10T10:10:00",
                  rbd: "U",
                  fn: "665",
                  trn: "3",
                  eq: "321",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:10",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "2" },
                },
                {
                  ref: 3,
                  da: "BOM",
                  ar: "HYD",
                  dd: "2023-06-10T11:00:00",
                  ad: "2023-06-10T12:25:00",
                  rbd: "U",
                  fn: "617",
                  trn: "2",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "1",
                  oa: "AI",
                  fa: [],
                  fdur: "01:25",
                  cl: "Economy",
                  terminal: { dep: "2", arr: "" },
                },
              ],
              flightNumbers: "972/665/617",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          pr: {
            bf: 26364.8,
            tf: 35996.8,
            tax: [
              { YR: 1120 },
              { YR: 672 },
              { OT: 7840 },
              { dealmkp: 0 },
              { attmkp: 2396.8 },
              { tamkp: 0 },
              { stamkp: 0 },
              { attdisc: 0 },
              { tadisc: 0 },
              { stadisc: 0 },
            ],
            scur: "QAR",
            ucur: "INR",
            exchange_rate: 22.4,
            sup_fare: "1070_1500",
            markupnames: [{ code: "MTA", name: "LaxmiAprilMarkup" }],
          },
          paxpr: [
            {
              tf: 35996.8,
              tax: [
                { YR: 1120 },
                { YR: 672 },
                { OT: 7840 },
                { attmkp: 2396.8 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              bf: 26364.8,
              fbc: "U6MDLDH",
              fareType: "RP",
              pusup_fare: "1070_1500",
              psup_fare: "23968_33600",
              ptype: "ADT",
            },
          ],
          sup: "0",
          airlinesList: "AI",
          servicefee: [
            {
              type: "ATT",
              adt: 2636.48,
              chd: 0,
              inf: 0,
              servicefee_name: "LaxmiServieApril",
              apl_on: "Per Ticket",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 2636.48,
              sup_fee: 0,
            },
            {
              type: "TA",
              adt: 0,
              chd: 0,
              inf: 0,
              apl_on: "",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 0,
              sup_fee: 2636.48,
            },
          ],
        },
        {
          srcorigns: [
            {
              tid: "cCakemdEQh48DJeo_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "09:25",
              re: "Non-Refundable",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DEL",
                  dd: "2023-06-07T14:30:00",
                  ad: "2023-06-07T16:55:00",
                  rbd: "Q",
                  fn: "523",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "1",
                  oa: "AI",
                  fa: [],
                  fdur: "02:25",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-06-07T19:50:00",
                  ad: "2023-06-07T21:25:00",
                  rbd: "U",
                  fn: "971",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:05",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "523/971",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          destorigins: [
            {
              tid: "cCakemdEQh48DJeo_2",
              ref: 2,
              office_id: "DOHQR28IH",
              sdur: "20:35",
              re: "Non-Refundable",
              stop: 2,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T22:35:00",
                  ad: "2023-06-10T05:05:00",
                  rbd: "U",
                  fn: "972",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:00",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "BOM",
                  dd: "2023-06-10T15:05:00",
                  ad: "2023-06-10T18:10:00",
                  rbd: "U",
                  fn: "636",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "5",
                  oa: "AI",
                  fa: [],
                  fdur: "03:05",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "2" },
                },
                {
                  ref: 3,
                  da: "BOM",
                  ar: "HYD",
                  dd: "2023-06-10T20:10:00",
                  ad: "2023-06-10T21:40:00",
                  rbd: "U",
                  fn: "619",
                  trn: "2",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "01:30",
                  cl: "Economy",
                  terminal: { dep: "2", arr: "" },
                },
              ],
              flightNumbers: "972/636/619",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          pr: {
            bf: 26364.8,
            tf: 35996.8,
            tax: [
              { YR: 1120 },
              { YR: 672 },
              { OT: 7840 },
              { dealmkp: 0 },
              { attmkp: 2396.8 },
              { tamkp: 0 },
              { stamkp: 0 },
              { attdisc: 0 },
              { tadisc: 0 },
              { stadisc: 0 },
            ],
            scur: "QAR",
            ucur: "INR",
            exchange_rate: 22.4,
            sup_fare: "1070_1500",
            markupnames: [{ code: "MTA", name: "LaxmiAprilMarkup" }],
          },
          paxpr: [
            {
              tf: 35996.8,
              tax: [
                { YR: 1120 },
                { YR: 672 },
                { OT: 7840 },
                { attmkp: 2396.8 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              bf: 26364.8,
              fbc: "U6MDLDH",
              fareType: "RP",
              pusup_fare: "1070_1500",
              psup_fare: "23968_33600",
              ptype: "ADT",
            },
          ],
          sup: "0",
          airlinesList: "AI",
          servicefee: [
            {
              type: "ATT",
              adt: 2636.48,
              chd: 0,
              inf: 0,
              servicefee_name: "LaxmiServieApril",
              apl_on: "Per Ticket",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 2636.48,
              sup_fee: 0,
            },
            {
              type: "TA",
              adt: 0,
              chd: 0,
              inf: 0,
              apl_on: "",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 0,
              sup_fee: 2636.48,
            },
          ],
        },
        {
          srcorigns: [
            {
              tid: "E6M8tI3ohEMEugNx_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "09:25",
              re: "Non-Refundable",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DEL",
                  dd: "2023-06-07T14:30:00",
                  ad: "2023-06-07T16:55:00",
                  rbd: "Q",
                  fn: "523",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "1",
                  oa: "AI",
                  fa: [],
                  fdur: "02:25",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-06-07T19:50:00",
                  ad: "2023-06-07T21:25:00",
                  rbd: "U",
                  fn: "971",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:05",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "523/971",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          destorigins: [
            {
              tid: "E6M8tI3ohEMEugNx_2",
              ref: 2,
              office_id: "DOHQR28IH",
              sdur: "20:35",
              re: "Non-Refundable",
              stop: 2,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T22:35:00",
                  ad: "2023-06-10T05:05:00",
                  rbd: "U",
                  fn: "972",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:00",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "BOM",
                  dd: "2023-06-10T14:00:00",
                  ad: "2023-06-10T16:15:00",
                  rbd: "U",
                  fn: "863",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:15",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "2" },
                },
                {
                  ref: 3,
                  da: "BOM",
                  ar: "HYD",
                  dd: "2023-06-10T20:10:00",
                  ad: "2023-06-10T21:40:00",
                  rbd: "U",
                  fn: "619",
                  trn: "2",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "01:30",
                  cl: "Economy",
                  terminal: { dep: "2", arr: "" },
                },
              ],
              flightNumbers: "972/863/619",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          pr: {
            bf: 26364.8,
            tf: 35996.8,
            tax: [
              { YR: 1120 },
              { YR: 672 },
              { OT: 7840 },
              { dealmkp: 0 },
              { attmkp: 2396.8 },
              { tamkp: 0 },
              { stamkp: 0 },
              { attdisc: 0 },
              { tadisc: 0 },
              { stadisc: 0 },
            ],
            scur: "QAR",
            ucur: "INR",
            exchange_rate: 22.4,
            sup_fare: "1070_1500",
            markupnames: [{ code: "MTA", name: "LaxmiAprilMarkup" }],
          },
          paxpr: [
            {
              tf: 35996.8,
              tax: [
                { YR: 1120 },
                { YR: 672 },
                { OT: 7840 },
                { attmkp: 2396.8 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              bf: 26364.8,
              fbc: "U6MDLDH",
              fareType: "RP",
              pusup_fare: "1070_1500",
              psup_fare: "23968_33600",
              ptype: "ADT",
            },
          ],
          sup: "0",
          airlinesList: "AI",
          servicefee: [
            {
              type: "ATT",
              adt: 2636.48,
              chd: 0,
              inf: 0,
              servicefee_name: "LaxmiServieApril",
              apl_on: "Per Ticket",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 2636.48,
              sup_fee: 0,
            },
            {
              type: "TA",
              adt: 0,
              chd: 0,
              inf: 0,
              apl_on: "",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 0,
              sup_fee: 2636.48,
            },
          ],
        },
        {
          srcorigns: [
            {
              tid: "ayuJRT71NRB1nmks_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "14:00",
              re: "Non-Refundable",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DEL",
                  dd: "2023-06-07T09:55:00",
                  ad: "2023-06-07T12:20:00",
                  rbd: "Q",
                  fn: "543",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:25",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-06-07T19:50:00",
                  ad: "2023-06-07T21:25:00",
                  rbd: "U",
                  fn: "971",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:05",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "543/971",
              bgd: [{ qun: "30", wt: "kg" }],
            },
            {
              tid: "CqJBGvusrVvhbu1O_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "17:15",
              re: "Non-Refundable",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DEL",
                  dd: "2023-06-07T06:40:00",
                  ad: "2023-06-07T08:55:00",
                  rbd: "Q",
                  fn: "559",
                  eq: "321",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:15",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-06-07T19:50:00",
                  ad: "2023-06-07T21:25:00",
                  rbd: "U",
                  fn: "971",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:05",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "559/971",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          destorigins: [
            {
              tid: "ayuJRT71NRB1nmks_2",
              ref: 2,
              office_id: "DOHQR28IH",
              sdur: "20:35",
              re: "Non-Refundable",
              stop: 2,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T22:35:00",
                  ad: "2023-06-10T05:05:00",
                  rbd: "U",
                  fn: "972",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:00",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "BOM",
                  dd: "2023-06-10T15:05:00",
                  ad: "2023-06-10T18:10:00",
                  rbd: "U",
                  fn: "636",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "5",
                  oa: "AI",
                  fa: [],
                  fdur: "03:05",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "2" },
                },
                {
                  ref: 3,
                  da: "BOM",
                  ar: "HYD",
                  dd: "2023-06-10T20:10:00",
                  ad: "2023-06-10T21:40:00",
                  rbd: "U",
                  fn: "619",
                  trn: "2",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "01:30",
                  cl: "Economy",
                  terminal: { dep: "2", arr: "" },
                },
              ],
              flightNumbers: "972/636/619",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          pr: {
            bf: 26364.8,
            tf: 35996.8,
            tax: [
              { YR: 1120 },
              { YR: 672 },
              { OT: 7840 },
              { dealmkp: 0 },
              { attmkp: 2396.8 },
              { tamkp: 0 },
              { stamkp: 0 },
              { attdisc: 0 },
              { tadisc: 0 },
              { stadisc: 0 },
            ],
            scur: "QAR",
            ucur: "INR",
            exchange_rate: 22.4,
            sup_fare: "1070_1500",
            markupnames: [{ code: "MTA", name: "LaxmiAprilMarkup" }],
          },
          paxpr: [
            {
              tf: 35996.8,
              tax: [
                { YR: 1120 },
                { YR: 672 },
                { OT: 7840 },
                { attmkp: 2396.8 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              bf: 26364.8,
              fbc: "U6MDLDH",
              fareType: "RP",
              pusup_fare: "1070_1500",
              psup_fare: "23968_33600",
              ptype: "ADT",
            },
          ],
          sup: "0",
          airlinesList: "AI",
          servicefee: [
            {
              type: "ATT",
              adt: 2636.48,
              chd: 0,
              inf: 0,
              servicefee_name: "LaxmiServieApril",
              apl_on: "Per Ticket",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 2636.48,
              sup_fee: 0,
            },
            {
              type: "TA",
              adt: 0,
              chd: 0,
              inf: 0,
              apl_on: "",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 0,
              sup_fee: 2636.48,
            },
          ],
        },
        {
          srcorigns: [
            {
              tid: "UHJS3wRq9Oqn7Cmd_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "14:00",
              re: "Non-Refundable",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DEL",
                  dd: "2023-06-07T09:55:00",
                  ad: "2023-06-07T12:20:00",
                  rbd: "Q",
                  fn: "543",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:25",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-06-07T19:50:00",
                  ad: "2023-06-07T21:25:00",
                  rbd: "U",
                  fn: "971",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:05",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "543/971",
              bgd: [{ qun: "30", wt: "kg" }],
            },
            {
              tid: "7n9Ok27iVC7qO21m_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "17:15",
              re: "Non-Refundable",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DEL",
                  dd: "2023-06-07T06:40:00",
                  ad: "2023-06-07T08:55:00",
                  rbd: "Q",
                  fn: "559",
                  eq: "321",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:15",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-06-07T19:50:00",
                  ad: "2023-06-07T21:25:00",
                  rbd: "U",
                  fn: "971",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:05",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "559/971",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          destorigins: [
            {
              tid: "UHJS3wRq9Oqn7Cmd_2",
              ref: 2,
              office_id: "DOHQR28IH",
              sdur: "20:35",
              re: "Non-Refundable",
              stop: 2,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T22:35:00",
                  ad: "2023-06-10T05:05:00",
                  rbd: "U",
                  fn: "972",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:00",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "BOM",
                  dd: "2023-06-10T14:00:00",
                  ad: "2023-06-10T16:15:00",
                  rbd: "U",
                  fn: "863",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:15",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "2" },
                },
                {
                  ref: 3,
                  da: "BOM",
                  ar: "HYD",
                  dd: "2023-06-10T20:10:00",
                  ad: "2023-06-10T21:40:00",
                  rbd: "U",
                  fn: "619",
                  trn: "2",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "01:30",
                  cl: "Economy",
                  terminal: { dep: "2", arr: "" },
                },
              ],
              flightNumbers: "972/863/619",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          pr: {
            bf: 26364.8,
            tf: 35996.8,
            tax: [
              { YR: 1120 },
              { YR: 672 },
              { OT: 7840 },
              { dealmkp: 0 },
              { attmkp: 2396.8 },
              { tamkp: 0 },
              { stamkp: 0 },
              { attdisc: 0 },
              { tadisc: 0 },
              { stadisc: 0 },
            ],
            scur: "QAR",
            ucur: "INR",
            exchange_rate: 22.4,
            sup_fare: "1070_1500",
            markupnames: [{ code: "MTA", name: "LaxmiAprilMarkup" }],
          },
          paxpr: [
            {
              tf: 35996.8,
              tax: [
                { YR: 1120 },
                { YR: 672 },
                { OT: 7840 },
                { attmkp: 2396.8 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              bf: 26364.8,
              fbc: "U6MDLDH",
              fareType: "RP",
              pusup_fare: "1070_1500",
              psup_fare: "23968_33600",
              ptype: "ADT",
            },
          ],
          sup: "0",
          airlinesList: "AI",
          servicefee: [
            {
              type: "ATT",
              adt: 2636.48,
              chd: 0,
              inf: 0,
              servicefee_name: "LaxmiServieApril",
              apl_on: "Per Ticket",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 2636.48,
              sup_fee: 0,
            },
            {
              type: "TA",
              adt: 0,
              chd: 0,
              inf: 0,
              apl_on: "",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 0,
              sup_fee: 2636.48,
            },
          ],
        },
        {
          srcorigns: [
            {
              tid: "OD6e6i2NwR1HhSua_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "27:15",
              re: "Non-Refundable",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DEL",
                  dd: "2023-06-07T20:40:00",
                  ad: "2023-06-07T23:00:00",
                  rbd: "Q",
                  fn: "840",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:20",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-06-08T19:50:00",
                  ad: "2023-06-08T21:25:00",
                  rbd: "U",
                  fn: "971",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "5",
                  oa: "AI",
                  fa: [],
                  fdur: "04:05",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "840/971",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          destorigins: [
            {
              tid: "OD6e6i2NwR1HhSua_2",
              ref: 2,
              office_id: "DOHQR28IH",
              sdur: "11:20",
              re: "Non-Refundable",
              stop: 2,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T22:35:00",
                  ad: "2023-06-10T05:05:00",
                  rbd: "U",
                  fn: "972",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:00",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "BOM",
                  dd: "2023-06-10T08:00:00",
                  ad: "2023-06-10T10:10:00",
                  rbd: "U",
                  fn: "665",
                  trn: "3",
                  eq: "321",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:10",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "2" },
                },
                {
                  ref: 3,
                  da: "BOM",
                  ar: "HYD",
                  dd: "2023-06-10T11:00:00",
                  ad: "2023-06-10T12:25:00",
                  rbd: "U",
                  fn: "617",
                  trn: "2",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "1",
                  oa: "AI",
                  fa: [],
                  fdur: "01:25",
                  cl: "Economy",
                  terminal: { dep: "2", arr: "" },
                },
              ],
              flightNumbers: "972/665/617",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          pr: {
            bf: 26364.8,
            tf: 35996.8,
            tax: [
              { YR: 1120 },
              { YR: 672 },
              { OT: 7840 },
              { dealmkp: 0 },
              { attmkp: 2396.8 },
              { tamkp: 0 },
              { stamkp: 0 },
              { attdisc: 0 },
              { tadisc: 0 },
              { stadisc: 0 },
            ],
            scur: "QAR",
            ucur: "INR",
            exchange_rate: 22.4,
            sup_fare: "1070_1500",
            markupnames: [{ code: "MTA", name: "LaxmiAprilMarkup" }],
          },
          paxpr: [
            {
              tf: 35996.8,
              tax: [
                { YR: 1120 },
                { YR: 672 },
                { OT: 7840 },
                { attmkp: 2396.8 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              bf: 26364.8,
              fbc: "U6MDLDH",
              fareType: "RP",
              pusup_fare: "1070_1500",
              psup_fare: "23968_33600",
              ptype: "ADT",
            },
          ],
          sup: "0",
          airlinesList: "AI",
          servicefee: [
            {
              type: "ATT",
              adt: 2636.48,
              chd: 0,
              inf: 0,
              servicefee_name: "LaxmiServieApril",
              apl_on: "Per Ticket",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 2636.48,
              sup_fee: 0,
            },
            {
              type: "TA",
              adt: 0,
              chd: 0,
              inf: 0,
              apl_on: "",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 0,
              sup_fee: 2636.48,
            },
          ],
        },
        {
          srcorigns: [
            {
              tid: "sk4PrQh3gOolypIE_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "27:15",
              re: "Non-Refundable",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DEL",
                  dd: "2023-06-07T20:40:00",
                  ad: "2023-06-07T23:00:00",
                  rbd: "Q",
                  fn: "840",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:20",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-06-08T19:50:00",
                  ad: "2023-06-08T21:25:00",
                  rbd: "U",
                  fn: "971",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "5",
                  oa: "AI",
                  fa: [],
                  fdur: "04:05",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "840/971",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          destorigins: [
            {
              tid: "sk4PrQh3gOolypIE_2",
              ref: 2,
              office_id: "DOHQR28IH",
              sdur: "20:35",
              re: "Non-Refundable",
              stop: 2,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T22:35:00",
                  ad: "2023-06-10T05:05:00",
                  rbd: "U",
                  fn: "972",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:00",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "BOM",
                  dd: "2023-06-10T15:05:00",
                  ad: "2023-06-10T18:10:00",
                  rbd: "U",
                  fn: "636",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "5",
                  oa: "AI",
                  fa: [],
                  fdur: "03:05",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "2" },
                },
                {
                  ref: 3,
                  da: "BOM",
                  ar: "HYD",
                  dd: "2023-06-10T20:10:00",
                  ad: "2023-06-10T21:40:00",
                  rbd: "U",
                  fn: "619",
                  trn: "2",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "01:30",
                  cl: "Economy",
                  terminal: { dep: "2", arr: "" },
                },
              ],
              flightNumbers: "972/636/619",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          pr: {
            bf: 26364.8,
            tf: 35996.8,
            tax: [
              { YR: 1120 },
              { YR: 672 },
              { OT: 7840 },
              { dealmkp: 0 },
              { attmkp: 2396.8 },
              { tamkp: 0 },
              { stamkp: 0 },
              { attdisc: 0 },
              { tadisc: 0 },
              { stadisc: 0 },
            ],
            scur: "QAR",
            ucur: "INR",
            exchange_rate: 22.4,
            sup_fare: "1070_1500",
            markupnames: [{ code: "MTA", name: "LaxmiAprilMarkup" }],
          },
          paxpr: [
            {
              tf: 35996.8,
              tax: [
                { YR: 1120 },
                { YR: 672 },
                { OT: 7840 },
                { attmkp: 2396.8 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              bf: 26364.8,
              fbc: "U6MDLDH",
              fareType: "RP",
              pusup_fare: "1070_1500",
              psup_fare: "23968_33600",
              ptype: "ADT",
            },
          ],
          sup: "0",
          airlinesList: "AI",
          servicefee: [
            {
              type: "ATT",
              adt: 2636.48,
              chd: 0,
              inf: 0,
              servicefee_name: "LaxmiServieApril",
              apl_on: "Per Ticket",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 2636.48,
              sup_fee: 0,
            },
            {
              type: "TA",
              adt: 0,
              chd: 0,
              inf: 0,
              apl_on: "",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 0,
              sup_fee: 2636.48,
            },
          ],
        },
        {
          srcorigns: [
            {
              tid: "07S5mNTCaPC8fy2z_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "27:15",
              re: "Non-Refundable",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DEL",
                  dd: "2023-06-07T20:40:00",
                  ad: "2023-06-07T23:00:00",
                  rbd: "Q",
                  fn: "840",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:20",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-06-08T19:50:00",
                  ad: "2023-06-08T21:25:00",
                  rbd: "U",
                  fn: "971",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "5",
                  oa: "AI",
                  fa: [],
                  fdur: "04:05",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "840/971",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          destorigins: [
            {
              tid: "07S5mNTCaPC8fy2z_2",
              ref: 2,
              office_id: "DOHQR28IH",
              sdur: "20:35",
              re: "Non-Refundable",
              stop: 2,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T22:35:00",
                  ad: "2023-06-10T05:05:00",
                  rbd: "U",
                  fn: "972",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:00",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "BOM",
                  dd: "2023-06-10T14:00:00",
                  ad: "2023-06-10T16:15:00",
                  rbd: "U",
                  fn: "863",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:15",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "2" },
                },
                {
                  ref: 3,
                  da: "BOM",
                  ar: "HYD",
                  dd: "2023-06-10T20:10:00",
                  ad: "2023-06-10T21:40:00",
                  rbd: "U",
                  fn: "619",
                  trn: "2",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "01:30",
                  cl: "Economy",
                  terminal: { dep: "2", arr: "" },
                },
              ],
              flightNumbers: "972/863/619",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          pr: {
            bf: 26364.8,
            tf: 35996.8,
            tax: [
              { YR: 1120 },
              { YR: 672 },
              { OT: 7840 },
              { dealmkp: 0 },
              { attmkp: 2396.8 },
              { tamkp: 0 },
              { stamkp: 0 },
              { attdisc: 0 },
              { tadisc: 0 },
              { stadisc: 0 },
            ],
            scur: "QAR",
            ucur: "INR",
            exchange_rate: 22.4,
            sup_fare: "1070_1500",
            markupnames: [{ code: "MTA", name: "LaxmiAprilMarkup" }],
          },
          paxpr: [
            {
              tf: 35996.8,
              tax: [
                { YR: 1120 },
                { YR: 672 },
                { OT: 7840 },
                { attmkp: 2396.8 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              bf: 26364.8,
              fbc: "U6MDLDH",
              fareType: "RP",
              pusup_fare: "1070_1500",
              psup_fare: "23968_33600",
              ptype: "ADT",
            },
          ],
          sup: "0",
          airlinesList: "AI",
          servicefee: [
            {
              type: "ATT",
              adt: 2636.48,
              chd: 0,
              inf: 0,
              servicefee_name: "LaxmiServieApril",
              apl_on: "Per Ticket",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 2636.48,
              sup_fee: 0,
            },
            {
              type: "TA",
              adt: 0,
              chd: 0,
              inf: 0,
              apl_on: "",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 0,
              sup_fee: 2636.48,
            },
          ],
        },
        {
          srcorigns: [
            {
              tid: "PDGo1AMcog1TgxM6_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "18:00",
              re: "Non-Refundable",
              stop: 2,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "CCU",
                  dd: "2023-06-07T05:55:00",
                  ad: "2023-06-07T08:15:00",
                  rbd: "L",
                  fn: "525",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:20",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
                {
                  ref: 2,
                  da: "CCU",
                  ar: "DEL",
                  dd: "2023-06-07T10:30:00",
                  ad: "2023-06-07T13:30:00",
                  rbd: "Q",
                  fn: "402",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "03:00",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 3,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-06-07T19:50:00",
                  ad: "2023-06-07T21:25:00",
                  rbd: "U",
                  fn: "971",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:05",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "525/402/971",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          destorigins: [
            {
              tid: "PDGo1AMcog1TgxM6_2",
              ref: 2,
              office_id: "DOHQR28IH",
              sdur: "08:20",
              re: "Non-Refundable",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T22:35:00",
                  ad: "2023-06-10T05:05:00",
                  rbd: "U",
                  fn: "972",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:00",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-06-10T07:10:00",
                  ad: "2023-06-10T09:25:00",
                  rbd: "Q",
                  fn: "560",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:15",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "972/560",
              bgd: [{ qun: "30", wt: "kg" }],
            },
            {
              tid: "6SPhMLmmGhe2pQVV_2",
              ref: 2,
              office_id: "DOHQR28IH",
              sdur: "10:45",
              re: "Non-Refundable",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T22:35:00",
                  ad: "2023-06-10T05:05:00",
                  rbd: "U",
                  fn: "972",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:00",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-06-10T09:45:00",
                  ad: "2023-06-10T11:50:00",
                  rbd: "Q",
                  fn: "542",
                  trn: "3",
                  eq: "321",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:05",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "972/542",
              bgd: [{ qun: "30", wt: "kg" }],
            },
            {
              tid: "K4azDap2g5ZvW46r_2",
              ref: 2,
              office_id: "DOHQR28IH",
              sdur: "18:05",
              re: "Non-Refundable",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T22:35:00",
                  ad: "2023-06-10T05:05:00",
                  rbd: "U",
                  fn: "972",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:00",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-06-10T16:50:00",
                  ad: "2023-06-10T19:10:00",
                  rbd: "Q",
                  fn: "544",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:20",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "972/544",
              bgd: [{ qun: "30", wt: "kg" }],
            },
            {
              tid: "d2AkDpLOtydRga0o_2",
              ref: 2,
              office_id: "DOHQR28IH",
              sdur: "22:40",
              re: "Non-Refundable",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T22:35:00",
                  ad: "2023-06-10T05:05:00",
                  rbd: "U",
                  fn: "972",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:00",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-06-10T21:30:00",
                  ad: "2023-06-10T23:45:00",
                  rbd: "Q",
                  fn: "839",
                  trn: "3",
                  eq: "321",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:15",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "972/839",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          pr: {
            bf: 27843.2,
            tf: 37699.2,
            tax: [
              { YR: 1120 },
              { YR: 672 },
              { OT: 8064 },
              { dealmkp: 0 },
              { attmkp: 2531.2 },
              { tamkp: 0 },
              { stamkp: 0 },
              { attdisc: 0 },
              { tadisc: 0 },
              { stadisc: 0 },
            ],
            scur: "QAR",
            ucur: "INR",
            exchange_rate: 22.4,
            sup_fare: "1130_1570",
            markupnames: [{ code: "MTA", name: "LaxmiAprilMarkup" }],
          },
          paxpr: [
            {
              tf: 37699.2,
              tax: [
                { YR: 1120 },
                { YR: 672 },
                { OT: 8064 },
                { attmkp: 2531.2 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              bf: 27843.2,
              fbc: "LIP",
              fareType: "RP",
              pusup_fare: "1130_1570",
              psup_fare: "25312_35168",
              ptype: "ADT",
            },
          ],
          sup: "0",
          airlinesList: "AI",
          servicefee: [
            {
              type: "ATT",
              adt: 2784.32,
              chd: 0,
              inf: 0,
              servicefee_name: "LaxmiServieApril",
              apl_on: "Per Ticket",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 2784.32,
              sup_fee: 0,
            },
            {
              type: "TA",
              adt: 0,
              chd: 0,
              inf: 0,
              apl_on: "",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 0,
              sup_fee: 2784.32,
            },
          ],
        },
        {
          srcorigns: [
            {
              tid: "sP4xBLGM7tJNRaE3_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "09:25",
              re: "Non-Refundable",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DEL",
                  dd: "2023-06-07T14:30:00",
                  ad: "2023-06-07T16:55:00",
                  rbd: "Q",
                  fn: "523",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "1",
                  oa: "AI",
                  fa: [],
                  fdur: "02:25",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-06-07T19:50:00",
                  ad: "2023-06-07T21:25:00",
                  rbd: "U",
                  fn: "971",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:05",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "523/971",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          destorigins: [
            {
              tid: "sP4xBLGM7tJNRaE3_2",
              ref: 2,
              office_id: "DOHQR28IH",
              sdur: "20:10",
              re: "Non-Refundable",
              stop: 2,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T22:35:00",
                  ad: "2023-06-10T05:05:00",
                  rbd: "U",
                  fn: "972",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:00",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "CCU",
                  dd: "2023-06-10T06:50:00",
                  ad: "2023-06-10T09:00:00",
                  rbd: "Q",
                  fn: "401",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:10",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
                {
                  ref: 3,
                  da: "CCU",
                  ar: "HYD",
                  dd: "2023-06-10T19:00:00",
                  ad: "2023-06-10T21:15:00",
                  rbd: "L",
                  fn: "526",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:15",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
              ],
              flightNumbers: "972/401/526",
              bgd: [{ qun: "30", wt: "kg" }],
            },
            {
              tid: "ROM5sdpTeLeFvG8V_2",
              ref: 2,
              office_id: "DOHQR28IH",
              sdur: "20:10",
              re: "Non-Refundable",
              stop: 2,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T22:35:00",
                  ad: "2023-06-10T05:05:00",
                  rbd: "U",
                  fn: "972",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:00",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "CCU",
                  dd: "2023-06-10T12:10:00",
                  ad: "2023-06-10T14:30:00",
                  rbd: "Q",
                  fn: "767",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:20",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
                {
                  ref: 3,
                  da: "CCU",
                  ar: "HYD",
                  dd: "2023-06-10T19:00:00",
                  ad: "2023-06-10T21:15:00",
                  rbd: "L",
                  fn: "526",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:15",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
              ],
              flightNumbers: "972/767/526",
              bgd: [{ qun: "30", wt: "kg" }],
            },
            {
              tid: "e3gUl3rhyOURnMGC_2",
              ref: 2,
              office_id: "DOHQR28IH",
              sdur: "44:10",
              re: "Non-Refundable",
              stop: 2,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T22:35:00",
                  ad: "2023-06-10T05:05:00",
                  rbd: "U",
                  fn: "972",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:00",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "CCU",
                  dd: "2023-06-10T20:15:00",
                  ad: "2023-06-10T22:25:00",
                  rbd: "Q",
                  fn: "762",
                  trn: "3",
                  eq: "321",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:10",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
                {
                  ref: 3,
                  da: "CCU",
                  ar: "HYD",
                  dd: "2023-06-11T19:00:00",
                  ad: "2023-06-11T21:15:00",
                  rbd: "L",
                  fn: "526",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:15",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
              ],
              flightNumbers: "972/762/526",
              bgd: [{ qun: "30", wt: "kg" }],
            },
            {
              tid: "KWN65kSfxovg8qe8_2",
              ref: 2,
              office_id: "DOHQR28IH",
              sdur: "44:10",
              re: "Non-Refundable",
              stop: 2,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T22:35:00",
                  ad: "2023-06-10T05:05:00",
                  rbd: "U",
                  fn: "972",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:00",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "CCU",
                  dd: "2023-06-10T16:45:00",
                  ad: "2023-06-10T19:10:00",
                  rbd: "Q",
                  fn: "764",
                  trn: "3",
                  eq: "321",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:25",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
                {
                  ref: 3,
                  da: "CCU",
                  ar: "HYD",
                  dd: "2023-06-11T19:00:00",
                  ad: "2023-06-11T21:15:00",
                  rbd: "L",
                  fn: "526",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:15",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
              ],
              flightNumbers: "972/764/526",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          pr: {
            bf: 27843.2,
            tf: 37699.2,
            tax: [
              { YR: 1120 },
              { YR: 672 },
              { OT: 8064 },
              { dealmkp: 0 },
              { attmkp: 2531.2 },
              { tamkp: 0 },
              { stamkp: 0 },
              { attdisc: 0 },
              { tadisc: 0 },
              { stadisc: 0 },
            ],
            scur: "QAR",
            ucur: "INR",
            exchange_rate: 22.4,
            sup_fare: "1130_1570",
            markupnames: [{ code: "MTA", name: "LaxmiAprilMarkup" }],
          },
          paxpr: [
            {
              tf: 37699.2,
              tax: [
                { YR: 1120 },
                { YR: 672 },
                { OT: 8064 },
                { attmkp: 2531.2 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              bf: 27843.2,
              fbc: "U6MDLDH",
              fareType: "RP",
              pusup_fare: "1130_1570",
              psup_fare: "25312_35168",
              ptype: "ADT",
            },
          ],
          sup: "0",
          airlinesList: "AI",
          servicefee: [
            {
              type: "ATT",
              adt: 2784.32,
              chd: 0,
              inf: 0,
              servicefee_name: "LaxmiServieApril",
              apl_on: "Per Ticket",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 2784.32,
              sup_fee: 0,
            },
            {
              type: "TA",
              adt: 0,
              chd: 0,
              inf: 0,
              apl_on: "",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 0,
              sup_fee: 2784.32,
            },
          ],
        },
        {
          srcorigns: [
            {
              tid: "rhTh3DuAImx223Sn_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "18:00",
              re: "Non-Refundable",
              stop: 2,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "CCU",
                  dd: "2023-06-07T05:55:00",
                  ad: "2023-06-07T08:15:00",
                  rbd: "L",
                  fn: "525",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:20",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
                {
                  ref: 2,
                  da: "CCU",
                  ar: "DEL",
                  dd: "2023-06-07T10:30:00",
                  ad: "2023-06-07T13:30:00",
                  rbd: "Q",
                  fn: "402",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "03:00",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 3,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-06-07T19:50:00",
                  ad: "2023-06-07T21:25:00",
                  rbd: "U",
                  fn: "971",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:05",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "525/402/971",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          destorigins: [
            {
              tid: "rhTh3DuAImx223Sn_2",
              ref: 2,
              office_id: "DOHQR28IH",
              sdur: "12:35",
              re: "Non-Refundable",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T22:35:00",
                  ad: "2023-06-10T05:05:00",
                  rbd: "U",
                  fn: "972",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:00",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-06-10T11:25:00",
                  ad: "2023-06-10T13:40:00",
                  rbd: "Q",
                  fn: "522",
                  trn: "3",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "1",
                  oa: "AI",
                  fa: [],
                  fdur: "02:15",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "972/522",
              bgd: [{ qun: "30", wt: "kg" }],
            },
            {
              tid: "bdFQB4tWeDVLg3TJ_2",
              ref: 2,
              office_id: "DOHQR28IH",
              sdur: "20:40",
              re: "Non-Refundable",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T22:35:00",
                  ad: "2023-06-10T05:05:00",
                  rbd: "U",
                  fn: "972",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:00",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-06-10T19:25:00",
                  ad: "2023-06-10T21:45:00",
                  rbd: "Q",
                  fn: "524",
                  trn: "3",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "1",
                  oa: "AI",
                  fa: [],
                  fdur: "02:20",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "972/524",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          pr: {
            bf: 27843.2,
            tf: 37699.2,
            tax: [
              { YR: 1120 },
              { YR: 672 },
              { OT: 8064 },
              { dealmkp: 0 },
              { attmkp: 2531.2 },
              { tamkp: 0 },
              { stamkp: 0 },
              { attdisc: 0 },
              { tadisc: 0 },
              { stadisc: 0 },
            ],
            scur: "QAR",
            ucur: "INR",
            exchange_rate: 22.4,
            sup_fare: "1130_1570",
            markupnames: [{ code: "MTA", name: "LaxmiAprilMarkup" }],
          },
          paxpr: [
            {
              tf: 37699.2,
              tax: [
                { YR: 1120 },
                { YR: 672 },
                { OT: 8064 },
                { attmkp: 2531.2 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              bf: 27843.2,
              fbc: "LIP",
              fareType: "RP",
              pusup_fare: "1130_1570",
              psup_fare: "25312_35168",
              ptype: "ADT",
            },
          ],
          sup: "0",
          airlinesList: "AI",
          servicefee: [
            {
              type: "ATT",
              adt: 2784.32,
              chd: 0,
              inf: 0,
              servicefee_name: "LaxmiServieApril",
              apl_on: "Per Ticket",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 2784.32,
              sup_fee: 0,
            },
            {
              type: "TA",
              adt: 0,
              chd: 0,
              inf: 0,
              apl_on: "",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 0,
              sup_fee: 2784.32,
            },
          ],
        },
        {
          srcorigns: [
            {
              tid: "mkXJP9l5SV5twSf7_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "14:00",
              re: "Non-Refundable",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DEL",
                  dd: "2023-06-07T09:55:00",
                  ad: "2023-06-07T12:20:00",
                  rbd: "Q",
                  fn: "543",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:25",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-06-07T19:50:00",
                  ad: "2023-06-07T21:25:00",
                  rbd: "U",
                  fn: "971",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:05",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "543/971",
              bgd: [{ qun: "30", wt: "kg" }],
            },
            {
              tid: "nx1CuTNTiEW0maBB_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "17:15",
              re: "Non-Refundable",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DEL",
                  dd: "2023-06-07T06:40:00",
                  ad: "2023-06-07T08:55:00",
                  rbd: "Q",
                  fn: "559",
                  eq: "321",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:15",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-06-07T19:50:00",
                  ad: "2023-06-07T21:25:00",
                  rbd: "U",
                  fn: "971",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:05",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "559/971",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          destorigins: [
            {
              tid: "mkXJP9l5SV5twSf7_2",
              ref: 2,
              office_id: "DOHQR28IH",
              sdur: "20:10",
              re: "Non-Refundable",
              stop: 2,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T22:35:00",
                  ad: "2023-06-10T05:05:00",
                  rbd: "U",
                  fn: "972",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:00",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "CCU",
                  dd: "2023-06-10T06:50:00",
                  ad: "2023-06-10T09:00:00",
                  rbd: "Q",
                  fn: "401",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:10",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
                {
                  ref: 3,
                  da: "CCU",
                  ar: "HYD",
                  dd: "2023-06-10T19:00:00",
                  ad: "2023-06-10T21:15:00",
                  rbd: "L",
                  fn: "526",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:15",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
              ],
              flightNumbers: "972/401/526",
              bgd: [{ qun: "30", wt: "kg" }],
            },
            {
              tid: "xWWMINfLRg1XThEe_2",
              ref: 2,
              office_id: "DOHQR28IH",
              sdur: "20:10",
              re: "Non-Refundable",
              stop: 2,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T22:35:00",
                  ad: "2023-06-10T05:05:00",
                  rbd: "U",
                  fn: "972",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:00",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "CCU",
                  dd: "2023-06-10T12:10:00",
                  ad: "2023-06-10T14:30:00",
                  rbd: "Q",
                  fn: "767",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:20",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
                {
                  ref: 3,
                  da: "CCU",
                  ar: "HYD",
                  dd: "2023-06-10T19:00:00",
                  ad: "2023-06-10T21:15:00",
                  rbd: "L",
                  fn: "526",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:15",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
              ],
              flightNumbers: "972/767/526",
              bgd: [{ qun: "30", wt: "kg" }],
            },
            {
              tid: "ZIZRMXbi5v82ny6T_2",
              ref: 2,
              office_id: "DOHQR28IH",
              sdur: "44:10",
              re: "Non-Refundable",
              stop: 2,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T22:35:00",
                  ad: "2023-06-10T05:05:00",
                  rbd: "U",
                  fn: "972",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:00",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "CCU",
                  dd: "2023-06-10T20:15:00",
                  ad: "2023-06-10T22:25:00",
                  rbd: "Q",
                  fn: "762",
                  trn: "3",
                  eq: "321",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:10",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
                {
                  ref: 3,
                  da: "CCU",
                  ar: "HYD",
                  dd: "2023-06-11T19:00:00",
                  ad: "2023-06-11T21:15:00",
                  rbd: "L",
                  fn: "526",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:15",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
              ],
              flightNumbers: "972/762/526",
              bgd: [{ qun: "30", wt: "kg" }],
            },
            {
              tid: "89zsVCgD1sZ8d1pP_2",
              ref: 2,
              office_id: "DOHQR28IH",
              sdur: "44:10",
              re: "Non-Refundable",
              stop: 2,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T22:35:00",
                  ad: "2023-06-10T05:05:00",
                  rbd: "U",
                  fn: "972",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:00",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "CCU",
                  dd: "2023-06-10T16:45:00",
                  ad: "2023-06-10T19:10:00",
                  rbd: "Q",
                  fn: "764",
                  trn: "3",
                  eq: "321",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:25",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
                {
                  ref: 3,
                  da: "CCU",
                  ar: "HYD",
                  dd: "2023-06-11T19:00:00",
                  ad: "2023-06-11T21:15:00",
                  rbd: "L",
                  fn: "526",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:15",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
              ],
              flightNumbers: "972/764/526",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          pr: {
            bf: 27843.2,
            tf: 37699.2,
            tax: [
              { YR: 1120 },
              { YR: 672 },
              { OT: 8064 },
              { dealmkp: 0 },
              { attmkp: 2531.2 },
              { tamkp: 0 },
              { stamkp: 0 },
              { attdisc: 0 },
              { tadisc: 0 },
              { stadisc: 0 },
            ],
            scur: "QAR",
            ucur: "INR",
            exchange_rate: 22.4,
            sup_fare: "1130_1570",
            markupnames: [{ code: "MTA", name: "LaxmiAprilMarkup" }],
          },
          paxpr: [
            {
              tf: 37699.2,
              tax: [
                { YR: 1120 },
                { YR: 672 },
                { OT: 8064 },
                { attmkp: 2531.2 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              bf: 27843.2,
              fbc: "U6MDLDH",
              fareType: "RP",
              pusup_fare: "1130_1570",
              psup_fare: "25312_35168",
              ptype: "ADT",
            },
          ],
          sup: "0",
          airlinesList: "AI",
          servicefee: [
            {
              type: "ATT",
              adt: 2784.32,
              chd: 0,
              inf: 0,
              servicefee_name: "LaxmiServieApril",
              apl_on: "Per Ticket",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 2784.32,
              sup_fee: 0,
            },
            {
              type: "TA",
              adt: 0,
              chd: 0,
              inf: 0,
              apl_on: "",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 0,
              sup_fee: 2784.32,
            },
          ],
        },
        {
          srcorigns: [
            {
              tid: "4dcBAJ0EIp745Os9_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "27:15",
              re: "Non-Refundable",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DEL",
                  dd: "2023-06-07T20:40:00",
                  ad: "2023-06-07T23:00:00",
                  rbd: "Q",
                  fn: "840",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:20",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-06-08T19:50:00",
                  ad: "2023-06-08T21:25:00",
                  rbd: "U",
                  fn: "971",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "5",
                  oa: "AI",
                  fa: [],
                  fdur: "04:05",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "840/971",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          destorigins: [
            {
              tid: "4dcBAJ0EIp745Os9_2",
              ref: 2,
              office_id: "DOHQR28IH",
              sdur: "20:10",
              re: "Non-Refundable",
              stop: 2,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T22:35:00",
                  ad: "2023-06-10T05:05:00",
                  rbd: "U",
                  fn: "972",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:00",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "CCU",
                  dd: "2023-06-10T06:50:00",
                  ad: "2023-06-10T09:00:00",
                  rbd: "Q",
                  fn: "401",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:10",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
                {
                  ref: 3,
                  da: "CCU",
                  ar: "HYD",
                  dd: "2023-06-10T19:00:00",
                  ad: "2023-06-10T21:15:00",
                  rbd: "L",
                  fn: "526",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:15",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
              ],
              flightNumbers: "972/401/526",
              bgd: [{ qun: "30", wt: "kg" }],
            },
            {
              tid: "EnVBUqbR0RSLbhBy_2",
              ref: 2,
              office_id: "DOHQR28IH",
              sdur: "20:10",
              re: "Non-Refundable",
              stop: 2,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T22:35:00",
                  ad: "2023-06-10T05:05:00",
                  rbd: "U",
                  fn: "972",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:00",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "CCU",
                  dd: "2023-06-10T12:10:00",
                  ad: "2023-06-10T14:30:00",
                  rbd: "Q",
                  fn: "767",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:20",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
                {
                  ref: 3,
                  da: "CCU",
                  ar: "HYD",
                  dd: "2023-06-10T19:00:00",
                  ad: "2023-06-10T21:15:00",
                  rbd: "L",
                  fn: "526",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:15",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
              ],
              flightNumbers: "972/767/526",
              bgd: [{ qun: "30", wt: "kg" }],
            },
            {
              tid: "R2mdWzSOzSTxbids_2",
              ref: 2,
              office_id: "DOHQR28IH",
              sdur: "44:10",
              re: "Non-Refundable",
              stop: 2,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T22:35:00",
                  ad: "2023-06-10T05:05:00",
                  rbd: "U",
                  fn: "972",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:00",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "CCU",
                  dd: "2023-06-10T20:15:00",
                  ad: "2023-06-10T22:25:00",
                  rbd: "Q",
                  fn: "762",
                  trn: "3",
                  eq: "321",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:10",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
                {
                  ref: 3,
                  da: "CCU",
                  ar: "HYD",
                  dd: "2023-06-11T19:00:00",
                  ad: "2023-06-11T21:15:00",
                  rbd: "L",
                  fn: "526",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:15",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
              ],
              flightNumbers: "972/762/526",
              bgd: [{ qun: "30", wt: "kg" }],
            },
            {
              tid: "JIt5anyWTwtXzVZO_2",
              ref: 2,
              office_id: "DOHQR28IH",
              sdur: "44:10",
              re: "Non-Refundable",
              stop: 2,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T22:35:00",
                  ad: "2023-06-10T05:05:00",
                  rbd: "U",
                  fn: "972",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:00",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "CCU",
                  dd: "2023-06-10T16:45:00",
                  ad: "2023-06-10T19:10:00",
                  rbd: "Q",
                  fn: "764",
                  trn: "3",
                  eq: "321",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:25",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
                {
                  ref: 3,
                  da: "CCU",
                  ar: "HYD",
                  dd: "2023-06-11T19:00:00",
                  ad: "2023-06-11T21:15:00",
                  rbd: "L",
                  fn: "526",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:15",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
              ],
              flightNumbers: "972/764/526",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          pr: {
            bf: 27843.2,
            tf: 37699.2,
            tax: [
              { YR: 1120 },
              { YR: 672 },
              { OT: 8064 },
              { dealmkp: 0 },
              { attmkp: 2531.2 },
              { tamkp: 0 },
              { stamkp: 0 },
              { attdisc: 0 },
              { tadisc: 0 },
              { stadisc: 0 },
            ],
            scur: "QAR",
            ucur: "INR",
            exchange_rate: 22.4,
            sup_fare: "1130_1570",
            markupnames: [{ code: "MTA", name: "LaxmiAprilMarkup" }],
          },
          paxpr: [
            {
              tf: 37699.2,
              tax: [
                { YR: 1120 },
                { YR: 672 },
                { OT: 8064 },
                { attmkp: 2531.2 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              bf: 27843.2,
              fbc: "U6MDLDH",
              fareType: "RP",
              pusup_fare: "1130_1570",
              psup_fare: "25312_35168",
              ptype: "ADT",
            },
          ],
          sup: "0",
          airlinesList: "AI",
          servicefee: [
            {
              type: "ATT",
              adt: 2784.32,
              chd: 0,
              inf: 0,
              servicefee_name: "LaxmiServieApril",
              apl_on: "Per Ticket",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 2784.32,
              sup_fee: 0,
            },
            {
              type: "TA",
              adt: 0,
              chd: 0,
              inf: 0,
              apl_on: "",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 0,
              sup_fee: 2784.32,
            },
          ],
        },
        {
          srcorigns: [
            {
              tid: "MVdXWF9Ui2vvpsdl_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "42:00",
              re: "Non-Refundable",
              stop: 2,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "CCU",
                  dd: "2023-06-07T05:55:00",
                  ad: "2023-06-07T08:15:00",
                  rbd: "L",
                  fn: "525",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:20",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
                {
                  ref: 2,
                  da: "CCU",
                  ar: "DEL",
                  dd: "2023-06-08T06:50:00",
                  ad: "2023-06-08T09:15:00",
                  rbd: "Q",
                  fn: "763",
                  eq: "321",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:25",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 3,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-06-08T19:50:00",
                  ad: "2023-06-08T21:25:00",
                  rbd: "U",
                  fn: "971",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "5",
                  oa: "AI",
                  fa: [],
                  fdur: "04:05",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "525/763/971",
              bgd: [{ qun: "30", wt: "kg" }],
            },
            {
              tid: "XTftJBNK5PwrOwvb_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "42:00",
              re: "Non-Refundable",
              stop: 2,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "CCU",
                  dd: "2023-06-07T05:55:00",
                  ad: "2023-06-07T08:15:00",
                  rbd: "L",
                  fn: "525",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:20",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
                {
                  ref: 2,
                  da: "CCU",
                  ar: "DEL",
                  dd: "2023-06-07T20:15:00",
                  ad: "2023-06-07T22:15:00",
                  rbd: "Q",
                  fn: "770",
                  eq: "321",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:00",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 3,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-06-08T19:50:00",
                  ad: "2023-06-08T21:25:00",
                  rbd: "U",
                  fn: "971",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "5",
                  oa: "AI",
                  fa: [],
                  fdur: "04:05",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "525/770/971",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          destorigins: [
            {
              tid: "MVdXWF9Ui2vvpsdl_2",
              ref: 2,
              office_id: "DOHQR28IH",
              sdur: "08:20",
              re: "Non-Refundable",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T22:35:00",
                  ad: "2023-06-10T05:05:00",
                  rbd: "U",
                  fn: "972",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:00",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-06-10T07:10:00",
                  ad: "2023-06-10T09:25:00",
                  rbd: "Q",
                  fn: "560",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:15",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "972/560",
              bgd: [{ qun: "30", wt: "kg" }],
            },
            {
              tid: "wpFrC0dCSHp5Zz0R_2",
              ref: 2,
              office_id: "DOHQR28IH",
              sdur: "10:45",
              re: "Non-Refundable",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T22:35:00",
                  ad: "2023-06-10T05:05:00",
                  rbd: "U",
                  fn: "972",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:00",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-06-10T09:45:00",
                  ad: "2023-06-10T11:50:00",
                  rbd: "Q",
                  fn: "542",
                  trn: "3",
                  eq: "321",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:05",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "972/542",
              bgd: [{ qun: "30", wt: "kg" }],
            },
            {
              tid: "fFdTU4TSi8qcq22Q_2",
              ref: 2,
              office_id: "DOHQR28IH",
              sdur: "18:05",
              re: "Non-Refundable",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T22:35:00",
                  ad: "2023-06-10T05:05:00",
                  rbd: "U",
                  fn: "972",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:00",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-06-10T16:50:00",
                  ad: "2023-06-10T19:10:00",
                  rbd: "Q",
                  fn: "544",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:20",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "972/544",
              bgd: [{ qun: "30", wt: "kg" }],
            },
            {
              tid: "u86b5wNIKfMmeT29_2",
              ref: 2,
              office_id: "DOHQR28IH",
              sdur: "22:40",
              re: "Non-Refundable",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T22:35:00",
                  ad: "2023-06-10T05:05:00",
                  rbd: "U",
                  fn: "972",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:00",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-06-10T21:30:00",
                  ad: "2023-06-10T23:45:00",
                  rbd: "Q",
                  fn: "839",
                  trn: "3",
                  eq: "321",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:15",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "972/839",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          pr: {
            bf: 27843.2,
            tf: 37699.2,
            tax: [
              { YR: 1120 },
              { YR: 672 },
              { OT: 8064 },
              { dealmkp: 0 },
              { attmkp: 2531.2 },
              { tamkp: 0 },
              { stamkp: 0 },
              { attdisc: 0 },
              { tadisc: 0 },
              { stadisc: 0 },
            ],
            scur: "QAR",
            ucur: "INR",
            exchange_rate: 22.4,
            sup_fare: "1130_1570",
            markupnames: [{ code: "MTA", name: "LaxmiAprilMarkup" }],
          },
          paxpr: [
            {
              tf: 37699.2,
              tax: [
                { YR: 1120 },
                { YR: 672 },
                { OT: 8064 },
                { attmkp: 2531.2 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              bf: 27843.2,
              fbc: "LIP",
              fareType: "RP",
              pusup_fare: "1130_1570",
              psup_fare: "25312_35168",
              ptype: "ADT",
            },
          ],
          sup: "0",
          airlinesList: "AI",
          servicefee: [
            {
              type: "ATT",
              adt: 2784.32,
              chd: 0,
              inf: 0,
              servicefee_name: "LaxmiServieApril",
              apl_on: "Per Ticket",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 2784.32,
              sup_fee: 0,
            },
            {
              type: "TA",
              adt: 0,
              chd: 0,
              inf: 0,
              apl_on: "",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 0,
              sup_fee: 2784.32,
            },
          ],
        },
        {
          srcorigns: [
            {
              tid: "8ES5mk8hHX5QKTHu_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "42:00",
              re: "Non-Refundable",
              stop: 2,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "CCU",
                  dd: "2023-06-07T05:55:00",
                  ad: "2023-06-07T08:15:00",
                  rbd: "L",
                  fn: "525",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:20",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
                {
                  ref: 2,
                  da: "CCU",
                  ar: "DEL",
                  dd: "2023-06-08T06:50:00",
                  ad: "2023-06-08T09:15:00",
                  rbd: "Q",
                  fn: "763",
                  eq: "321",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:25",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 3,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-06-08T19:50:00",
                  ad: "2023-06-08T21:25:00",
                  rbd: "U",
                  fn: "971",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "5",
                  oa: "AI",
                  fa: [],
                  fdur: "04:05",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "525/763/971",
              bgd: [{ qun: "30", wt: "kg" }],
            },
            {
              tid: "urQmNo70iuAREAvT_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "42:00",
              re: "Non-Refundable",
              stop: 2,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "CCU",
                  dd: "2023-06-07T05:55:00",
                  ad: "2023-06-07T08:15:00",
                  rbd: "L",
                  fn: "525",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:20",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
                {
                  ref: 2,
                  da: "CCU",
                  ar: "DEL",
                  dd: "2023-06-07T20:15:00",
                  ad: "2023-06-07T22:15:00",
                  rbd: "Q",
                  fn: "770",
                  eq: "321",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:00",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 3,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-06-08T19:50:00",
                  ad: "2023-06-08T21:25:00",
                  rbd: "U",
                  fn: "971",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "5",
                  oa: "AI",
                  fa: [],
                  fdur: "04:05",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "525/770/971",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          destorigins: [
            {
              tid: "8ES5mk8hHX5QKTHu_2",
              ref: 2,
              office_id: "DOHQR28IH",
              sdur: "12:35",
              re: "Non-Refundable",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T22:35:00",
                  ad: "2023-06-10T05:05:00",
                  rbd: "U",
                  fn: "972",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:00",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-06-10T11:25:00",
                  ad: "2023-06-10T13:40:00",
                  rbd: "Q",
                  fn: "522",
                  trn: "3",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "1",
                  oa: "AI",
                  fa: [],
                  fdur: "02:15",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "972/522",
              bgd: [{ qun: "30", wt: "kg" }],
            },
            {
              tid: "6O1UTw8DsT1koD54_2",
              ref: 2,
              office_id: "DOHQR28IH",
              sdur: "20:40",
              re: "Non-Refundable",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T22:35:00",
                  ad: "2023-06-10T05:05:00",
                  rbd: "U",
                  fn: "972",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:00",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-06-10T19:25:00",
                  ad: "2023-06-10T21:45:00",
                  rbd: "Q",
                  fn: "524",
                  trn: "3",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "1",
                  oa: "AI",
                  fa: [],
                  fdur: "02:20",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "972/524",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          pr: {
            bf: 27843.2,
            tf: 37699.2,
            tax: [
              { YR: 1120 },
              { YR: 672 },
              { OT: 8064 },
              { dealmkp: 0 },
              { attmkp: 2531.2 },
              { tamkp: 0 },
              { stamkp: 0 },
              { attdisc: 0 },
              { tadisc: 0 },
              { stadisc: 0 },
            ],
            scur: "QAR",
            ucur: "INR",
            exchange_rate: 22.4,
            sup_fare: "1130_1570",
            markupnames: [{ code: "MTA", name: "LaxmiAprilMarkup" }],
          },
          paxpr: [
            {
              tf: 37699.2,
              tax: [
                { YR: 1120 },
                { YR: 672 },
                { OT: 8064 },
                { attmkp: 2531.2 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              bf: 27843.2,
              fbc: "LIP",
              fareType: "RP",
              pusup_fare: "1130_1570",
              psup_fare: "25312_35168",
              ptype: "ADT",
            },
          ],
          sup: "0",
          airlinesList: "AI",
          servicefee: [
            {
              type: "ATT",
              adt: 2784.32,
              chd: 0,
              inf: 0,
              servicefee_name: "LaxmiServieApril",
              apl_on: "Per Ticket",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 2784.32,
              sup_fee: 0,
            },
            {
              type: "TA",
              adt: 0,
              chd: 0,
              inf: 0,
              apl_on: "",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 0,
              sup_fee: 2784.32,
            },
          ],
        },
        {
          srcorigns: [
            {
              tid: "c17VTc6xnKpcy4wh_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "10:40",
              re: "Non-Refundable",
              stop: 2,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "BOM",
                  dd: "2023-06-07T13:15:00",
                  ad: "2023-06-07T14:55:00",
                  rbd: "S",
                  fn: "618",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "1",
                  oa: "AI",
                  fa: [],
                  fdur: "01:40",
                  cl: "Economy",
                  terminal: { dep: "", arr: "2" },
                },
                {
                  ref: 2,
                  da: "BOM",
                  ar: "DEL",
                  dd: "2023-06-07T16:00:00",
                  ad: "2023-06-07T18:20:00",
                  rbd: "U",
                  fn: "687",
                  trn: "2",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:20",
                  cl: "Economy",
                  terminal: { dep: "2", arr: "3" },
                },
                {
                  ref: 3,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-06-07T19:50:00",
                  ad: "2023-06-07T21:25:00",
                  rbd: "U",
                  fn: "971",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:05",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "618/687/971",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          destorigins: [
            {
              tid: "c17VTc6xnKpcy4wh_2",
              ref: 2,
              office_id: "DOHQR28IH",
              sdur: "08:20",
              re: "Non-Refundable",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T22:35:00",
                  ad: "2023-06-10T05:05:00",
                  rbd: "U",
                  fn: "972",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:00",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-06-10T07:10:00",
                  ad: "2023-06-10T09:25:00",
                  rbd: "Q",
                  fn: "560",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:15",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "972/560",
              bgd: [{ qun: "30", wt: "kg" }],
            },
            {
              tid: "cin63baD2CtSx0iL_2",
              ref: 2,
              office_id: "DOHQR28IH",
              sdur: "10:45",
              re: "Non-Refundable",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T22:35:00",
                  ad: "2023-06-10T05:05:00",
                  rbd: "U",
                  fn: "972",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:00",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-06-10T09:45:00",
                  ad: "2023-06-10T11:50:00",
                  rbd: "Q",
                  fn: "542",
                  trn: "3",
                  eq: "321",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:05",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "972/542",
              bgd: [{ qun: "30", wt: "kg" }],
            },
            {
              tid: "Z5pOCiuAHLTJ00JL_2",
              ref: 2,
              office_id: "DOHQR28IH",
              sdur: "18:05",
              re: "Non-Refundable",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T22:35:00",
                  ad: "2023-06-10T05:05:00",
                  rbd: "U",
                  fn: "972",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:00",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-06-10T16:50:00",
                  ad: "2023-06-10T19:10:00",
                  rbd: "Q",
                  fn: "544",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:20",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "972/544",
              bgd: [{ qun: "30", wt: "kg" }],
            },
            {
              tid: "b7utTugw21xnbrA7_2",
              ref: 2,
              office_id: "DOHQR28IH",
              sdur: "22:40",
              re: "Non-Refundable",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T22:35:00",
                  ad: "2023-06-10T05:05:00",
                  rbd: "U",
                  fn: "972",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:00",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-06-10T21:30:00",
                  ad: "2023-06-10T23:45:00",
                  rbd: "Q",
                  fn: "839",
                  trn: "3",
                  eq: "321",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:15",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "972/839",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          pr: {
            bf: 28089.6,
            tf: 37945.6,
            tax: [
              { YR: 1120 },
              { YR: 672 },
              { OT: 8064 },
              { dealmkp: 0 },
              { attmkp: 2553.6 },
              { tamkp: 0 },
              { stamkp: 0 },
              { attdisc: 0 },
              { tadisc: 0 },
              { stadisc: 0 },
            ],
            scur: "QAR",
            ucur: "INR",
            exchange_rate: 22.4,
            sup_fare: "1140_1580",
            markupnames: [{ code: "MTA", name: "LaxmiAprilMarkup" }],
          },
          paxpr: [
            {
              tf: 37945.6,
              tax: [
                { YR: 1120 },
                { YR: 672 },
                { OT: 8064 },
                { attmkp: 2553.6 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              bf: 28089.6,
              fbc: "SIP",
              fareType: "RP",
              pusup_fare: "1140_1580",
              psup_fare: "25536_35392",
              ptype: "ADT",
            },
          ],
          sup: "0",
          airlinesList: "AI",
          servicefee: [
            {
              type: "ATT",
              adt: 2808.96,
              chd: 0,
              inf: 0,
              servicefee_name: "LaxmiServieApril",
              apl_on: "Per Ticket",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 2808.96,
              sup_fee: 0,
            },
            {
              type: "TA",
              adt: 0,
              chd: 0,
              inf: 0,
              apl_on: "",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 0,
              sup_fee: 2808.96,
            },
          ],
        },
        {
          srcorigns: [
            {
              tid: "QIlKizEF0naiTtXt_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "10:40",
              re: "Non-Refundable",
              stop: 2,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "BOM",
                  dd: "2023-06-07T13:15:00",
                  ad: "2023-06-07T14:55:00",
                  rbd: "S",
                  fn: "618",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "1",
                  oa: "AI",
                  fa: [],
                  fdur: "01:40",
                  cl: "Economy",
                  terminal: { dep: "", arr: "2" },
                },
                {
                  ref: 2,
                  da: "BOM",
                  ar: "DEL",
                  dd: "2023-06-07T16:00:00",
                  ad: "2023-06-07T18:20:00",
                  rbd: "U",
                  fn: "687",
                  trn: "2",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:20",
                  cl: "Economy",
                  terminal: { dep: "2", arr: "3" },
                },
                {
                  ref: 3,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-06-07T19:50:00",
                  ad: "2023-06-07T21:25:00",
                  rbd: "U",
                  fn: "971",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:05",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "618/687/971",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          destorigins: [
            {
              tid: "QIlKizEF0naiTtXt_2",
              ref: 2,
              office_id: "DOHQR28IH",
              sdur: "12:35",
              re: "Non-Refundable",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T22:35:00",
                  ad: "2023-06-10T05:05:00",
                  rbd: "U",
                  fn: "972",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:00",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-06-10T11:25:00",
                  ad: "2023-06-10T13:40:00",
                  rbd: "Q",
                  fn: "522",
                  trn: "3",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "1",
                  oa: "AI",
                  fa: [],
                  fdur: "02:15",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "972/522",
              bgd: [{ qun: "30", wt: "kg" }],
            },
            {
              tid: "n0PMcGQiTywBzNxv_2",
              ref: 2,
              office_id: "DOHQR28IH",
              sdur: "20:40",
              re: "Non-Refundable",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T22:35:00",
                  ad: "2023-06-10T05:05:00",
                  rbd: "U",
                  fn: "972",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:00",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-06-10T19:25:00",
                  ad: "2023-06-10T21:45:00",
                  rbd: "Q",
                  fn: "524",
                  trn: "3",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "1",
                  oa: "AI",
                  fa: [],
                  fdur: "02:20",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "972/524",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          pr: {
            bf: 28089.6,
            tf: 37945.6,
            tax: [
              { YR: 1120 },
              { YR: 672 },
              { OT: 8064 },
              { dealmkp: 0 },
              { attmkp: 2553.6 },
              { tamkp: 0 },
              { stamkp: 0 },
              { attdisc: 0 },
              { tadisc: 0 },
              { stadisc: 0 },
            ],
            scur: "QAR",
            ucur: "INR",
            exchange_rate: 22.4,
            sup_fare: "1140_1580",
            markupnames: [{ code: "MTA", name: "LaxmiAprilMarkup" }],
          },
          paxpr: [
            {
              tf: 37945.6,
              tax: [
                { YR: 1120 },
                { YR: 672 },
                { OT: 8064 },
                { attmkp: 2553.6 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              bf: 28089.6,
              fbc: "SIP",
              fareType: "RP",
              pusup_fare: "1140_1580",
              psup_fare: "25536_35392",
              ptype: "ADT",
            },
          ],
          sup: "0",
          airlinesList: "AI",
          servicefee: [
            {
              type: "ATT",
              adt: 2808.96,
              chd: 0,
              inf: 0,
              servicefee_name: "LaxmiServieApril",
              apl_on: "Per Ticket",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 2808.96,
              sup_fee: 0,
            },
            {
              type: "TA",
              adt: 0,
              chd: 0,
              inf: 0,
              apl_on: "",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 0,
              sup_fee: 2808.96,
            },
          ],
        },
        {
          srcorigns: [
            {
              tid: "a3aclVuoKGJ6bTpx_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "09:25",
              re: "Non-Refundable",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DEL",
                  dd: "2023-06-07T14:30:00",
                  ad: "2023-06-07T16:55:00",
                  rbd: "Q",
                  fn: "523",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "1",
                  oa: "AI",
                  fa: [],
                  fdur: "02:25",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-06-07T19:50:00",
                  ad: "2023-06-07T21:25:00",
                  rbd: "U",
                  fn: "971",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:05",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "523/971",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          destorigins: [
            {
              tid: "a3aclVuoKGJ6bTpx_2",
              ref: 2,
              office_id: "DOHQR28IH",
              sdur: "20:35",
              re: "Non-Refundable",
              stop: 2,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T22:35:00",
                  ad: "2023-06-10T05:05:00",
                  rbd: "U",
                  fn: "972",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:00",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "BOM",
                  dd: "2023-06-10T10:00:00",
                  ad: "2023-06-10T12:35:00",
                  rbd: "G",
                  fn: "865",
                  trn: "3",
                  eq: "77L",
                  ac: "AI",
                  ma: "AI",
                  seat: "5",
                  oa: "AI",
                  fa: [],
                  fdur: "02:35",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "2" },
                },
                {
                  ref: 3,
                  da: "BOM",
                  ar: "HYD",
                  dd: "2023-06-10T20:10:00",
                  ad: "2023-06-10T21:40:00",
                  rbd: "G",
                  fn: "619",
                  trn: "2",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "01:30",
                  cl: "Economy",
                  terminal: { dep: "2", arr: "" },
                },
              ],
              flightNumbers: "972/865/619",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          pr: {
            bf: 28089.6,
            tf: 37945.6,
            tax: [
              { YR: 1120 },
              { YR: 672 },
              { OT: 8064 },
              { dealmkp: 0 },
              { attmkp: 2553.6 },
              { tamkp: 0 },
              { stamkp: 0 },
              { attdisc: 0 },
              { tadisc: 0 },
              { stadisc: 0 },
            ],
            scur: "QAR",
            ucur: "INR",
            exchange_rate: 22.4,
            sup_fare: "1140_1580",
            markupnames: [{ code: "MTA", name: "LaxmiAprilMarkup" }],
          },
          paxpr: [
            {
              tf: 37945.6,
              tax: [
                { YR: 1120 },
                { YR: 672 },
                { OT: 8064 },
                { attmkp: 2553.6 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              bf: 28089.6,
              fbc: "U6MDLDH",
              fareType: "RP",
              pusup_fare: "1140_1580",
              psup_fare: "25536_35392",
              ptype: "ADT",
            },
          ],
          sup: "0",
          airlinesList: "AI",
          servicefee: [
            {
              type: "ATT",
              adt: 2808.96,
              chd: 0,
              inf: 0,
              servicefee_name: "LaxmiServieApril",
              apl_on: "Per Ticket",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 2808.96,
              sup_fee: 0,
            },
            {
              type: "TA",
              adt: 0,
              chd: 0,
              inf: 0,
              apl_on: "",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 0,
              sup_fee: 2808.96,
            },
          ],
        },
        {
          srcorigns: [
            {
              tid: "1VCIyiLbnbXTCLdf_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "14:00",
              re: "Non-Refundable",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DEL",
                  dd: "2023-06-07T09:55:00",
                  ad: "2023-06-07T12:20:00",
                  rbd: "Q",
                  fn: "543",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:25",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-06-07T19:50:00",
                  ad: "2023-06-07T21:25:00",
                  rbd: "U",
                  fn: "971",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:05",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "543/971",
              bgd: [{ qun: "30", wt: "kg" }],
            },
            {
              tid: "KZ60TWTi88CSFyAF_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "17:15",
              re: "Non-Refundable",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DEL",
                  dd: "2023-06-07T06:40:00",
                  ad: "2023-06-07T08:55:00",
                  rbd: "Q",
                  fn: "559",
                  eq: "321",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:15",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-06-07T19:50:00",
                  ad: "2023-06-07T21:25:00",
                  rbd: "U",
                  fn: "971",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:05",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "559/971",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          destorigins: [
            {
              tid: "1VCIyiLbnbXTCLdf_2",
              ref: 2,
              office_id: "DOHQR28IH",
              sdur: "20:35",
              re: "Non-Refundable",
              stop: 2,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T22:35:00",
                  ad: "2023-06-10T05:05:00",
                  rbd: "U",
                  fn: "972",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:00",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "BOM",
                  dd: "2023-06-10T10:00:00",
                  ad: "2023-06-10T12:35:00",
                  rbd: "G",
                  fn: "865",
                  trn: "3",
                  eq: "77L",
                  ac: "AI",
                  ma: "AI",
                  seat: "5",
                  oa: "AI",
                  fa: [],
                  fdur: "02:35",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "2" },
                },
                {
                  ref: 3,
                  da: "BOM",
                  ar: "HYD",
                  dd: "2023-06-10T20:10:00",
                  ad: "2023-06-10T21:40:00",
                  rbd: "G",
                  fn: "619",
                  trn: "2",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "01:30",
                  cl: "Economy",
                  terminal: { dep: "2", arr: "" },
                },
              ],
              flightNumbers: "972/865/619",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          pr: {
            bf: 28089.6,
            tf: 37945.6,
            tax: [
              { YR: 1120 },
              { YR: 672 },
              { OT: 8064 },
              { dealmkp: 0 },
              { attmkp: 2553.6 },
              { tamkp: 0 },
              { stamkp: 0 },
              { attdisc: 0 },
              { tadisc: 0 },
              { stadisc: 0 },
            ],
            scur: "QAR",
            ucur: "INR",
            exchange_rate: 22.4,
            sup_fare: "1140_1580",
            markupnames: [{ code: "MTA", name: "LaxmiAprilMarkup" }],
          },
          paxpr: [
            {
              tf: 37945.6,
              tax: [
                { YR: 1120 },
                { YR: 672 },
                { OT: 8064 },
                { attmkp: 2553.6 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              bf: 28089.6,
              fbc: "U6MDLDH",
              fareType: "RP",
              pusup_fare: "1140_1580",
              psup_fare: "25536_35392",
              ptype: "ADT",
            },
          ],
          sup: "0",
          airlinesList: "AI",
          servicefee: [
            {
              type: "ATT",
              adt: 2808.96,
              chd: 0,
              inf: 0,
              servicefee_name: "LaxmiServieApril",
              apl_on: "Per Ticket",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 2808.96,
              sup_fee: 0,
            },
            {
              type: "TA",
              adt: 0,
              chd: 0,
              inf: 0,
              apl_on: "",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 0,
              sup_fee: 2808.96,
            },
          ],
        },
        {
          srcorigns: [
            {
              tid: "GTnyia0lZXxPTzyS_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "09:25",
              re: "Non-Refundable",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DEL",
                  dd: "2023-06-07T14:30:00",
                  ad: "2023-06-07T16:55:00",
                  rbd: "Q",
                  fn: "523",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "1",
                  oa: "AI",
                  fa: [],
                  fdur: "02:25",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-06-07T19:50:00",
                  ad: "2023-06-07T21:25:00",
                  rbd: "U",
                  fn: "971",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:05",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "523/971",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          destorigins: [
            {
              tid: "GTnyia0lZXxPTzyS_2",
              ref: 2,
              office_id: "DOHQR28IH",
              sdur: "35:20",
              re: "Non-Refundable",
              stop: 2,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T22:35:00",
                  ad: "2023-06-10T05:05:00",
                  rbd: "U",
                  fn: "972",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:00",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "BOM",
                  dd: "2023-06-10T21:15:00",
                  ad: "2023-06-10T23:30:00",
                  rbd: "U",
                  fn: "605",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "8",
                  oa: "AI",
                  fa: [],
                  fdur: "02:15",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "2" },
                },
                {
                  ref: 3,
                  da: "BOM",
                  ar: "HYD",
                  dd: "2023-06-11T11:00:00",
                  ad: "2023-06-11T12:25:00",
                  rbd: "S",
                  fn: "617",
                  trn: "2",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "3",
                  oa: "AI",
                  fa: [],
                  fdur: "01:25",
                  cl: "Economy",
                  terminal: { dep: "2", arr: "" },
                },
              ],
              flightNumbers: "972/605/617",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          pr: {
            bf: 28089.6,
            tf: 37945.6,
            tax: [
              { YR: 1120 },
              { YR: 672 },
              { OT: 8064 },
              { dealmkp: 0 },
              { attmkp: 2553.6 },
              { tamkp: 0 },
              { stamkp: 0 },
              { attdisc: 0 },
              { tadisc: 0 },
              { stadisc: 0 },
            ],
            scur: "QAR",
            ucur: "INR",
            exchange_rate: 22.4,
            sup_fare: "1140_1580",
            markupnames: [{ code: "MTA", name: "LaxmiAprilMarkup" }],
          },
          paxpr: [
            {
              tf: 37945.6,
              tax: [
                { YR: 1120 },
                { YR: 672 },
                { OT: 8064 },
                { attmkp: 2553.6 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              bf: 28089.6,
              fbc: "U6MDLDH",
              fareType: "RP",
              pusup_fare: "1140_1580",
              psup_fare: "25536_35392",
              ptype: "ADT",
            },
          ],
          sup: "0",
          airlinesList: "AI",
          servicefee: [
            {
              type: "ATT",
              adt: 2808.96,
              chd: 0,
              inf: 0,
              servicefee_name: "LaxmiServieApril",
              apl_on: "Per Ticket",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 2808.96,
              sup_fee: 0,
            },
            {
              type: "TA",
              adt: 0,
              chd: 0,
              inf: 0,
              apl_on: "",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 0,
              sup_fee: 2808.96,
            },
          ],
        },
        {
          srcorigns: [
            {
              tid: "ATdyP25hb9EzvtKH_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "09:25",
              re: "Non-Refundable",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DEL",
                  dd: "2023-06-07T14:30:00",
                  ad: "2023-06-07T16:55:00",
                  rbd: "Q",
                  fn: "523",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "1",
                  oa: "AI",
                  fa: [],
                  fdur: "02:25",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-06-07T19:50:00",
                  ad: "2023-06-07T21:25:00",
                  rbd: "U",
                  fn: "971",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:05",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "523/971",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          destorigins: [
            {
              tid: "ATdyP25hb9EzvtKH_2",
              ref: 2,
              office_id: "DOHQR28IH",
              sdur: "35:20",
              re: "Non-Refundable",
              stop: 2,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T22:35:00",
                  ad: "2023-06-10T05:05:00",
                  rbd: "U",
                  fn: "972",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:00",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "BOM",
                  dd: "2023-06-10T19:00:00",
                  ad: "2023-06-10T21:15:00",
                  rbd: "U",
                  fn: "624",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:15",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "2" },
                },
                {
                  ref: 3,
                  da: "BOM",
                  ar: "HYD",
                  dd: "2023-06-11T11:00:00",
                  ad: "2023-06-11T12:25:00",
                  rbd: "S",
                  fn: "617",
                  trn: "2",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "3",
                  oa: "AI",
                  fa: [],
                  fdur: "01:25",
                  cl: "Economy",
                  terminal: { dep: "2", arr: "" },
                },
              ],
              flightNumbers: "972/624/617",
              bgd: [{ qun: "30", wt: "kg" }],
            },
            {
              tid: "8h1SmOLcexyDqOTA_2",
              ref: 2,
              office_id: "DOHQR28IH",
              sdur: "35:20",
              re: "Non-Refundable",
              stop: 2,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T22:35:00",
                  ad: "2023-06-10T05:05:00",
                  rbd: "U",
                  fn: "972",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:00",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "BOM",
                  dd: "2023-06-10T09:00:00",
                  ad: "2023-06-10T11:15:00",
                  rbd: "U",
                  fn: "678",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:15",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "2" },
                },
                {
                  ref: 3,
                  da: "BOM",
                  ar: "HYD",
                  dd: "2023-06-11T11:00:00",
                  ad: "2023-06-11T12:25:00",
                  rbd: "S",
                  fn: "617",
                  trn: "2",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "3",
                  oa: "AI",
                  fa: [],
                  fdur: "01:25",
                  cl: "Economy",
                  terminal: { dep: "2", arr: "" },
                },
              ],
              flightNumbers: "972/678/617",
              bgd: [{ qun: "30", wt: "kg" }],
            },
            {
              tid: "SfWl32kaWUdhlTLG_2",
              ref: 2,
              office_id: "DOHQR28IH",
              sdur: "35:20",
              re: "Non-Refundable",
              stop: 2,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T22:35:00",
                  ad: "2023-06-10T05:05:00",
                  rbd: "U",
                  fn: "972",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:00",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "BOM",
                  dd: "2023-06-10T22:30:00",
                  ad: "2023-06-11T00:45:00",
                  rbd: "U",
                  fn: "814",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:15",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "2" },
                },
                {
                  ref: 3,
                  da: "BOM",
                  ar: "HYD",
                  dd: "2023-06-11T11:00:00",
                  ad: "2023-06-11T12:25:00",
                  rbd: "S",
                  fn: "617",
                  trn: "2",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "3",
                  oa: "AI",
                  fa: [],
                  fdur: "01:25",
                  cl: "Economy",
                  terminal: { dep: "2", arr: "" },
                },
              ],
              flightNumbers: "972/814/617",
              bgd: [{ qun: "30", wt: "kg" }],
            },
            {
              tid: "R6u5dFzKieqRirEL_2",
              ref: 2,
              office_id: "DOHQR28IH",
              sdur: "35:20",
              re: "Non-Refundable",
              stop: 2,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T22:35:00",
                  ad: "2023-06-10T05:05:00",
                  rbd: "U",
                  fn: "972",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:00",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "BOM",
                  dd: "2023-06-10T14:00:00",
                  ad: "2023-06-10T16:15:00",
                  rbd: "U",
                  fn: "863",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:15",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "2" },
                },
                {
                  ref: 3,
                  da: "BOM",
                  ar: "HYD",
                  dd: "2023-06-11T11:00:00",
                  ad: "2023-06-11T12:25:00",
                  rbd: "S",
                  fn: "617",
                  trn: "2",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "3",
                  oa: "AI",
                  fa: [],
                  fdur: "01:25",
                  cl: "Economy",
                  terminal: { dep: "2", arr: "" },
                },
              ],
              flightNumbers: "972/863/617",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          pr: {
            bf: 28089.6,
            tf: 37945.6,
            tax: [
              { YR: 1120 },
              { YR: 672 },
              { OT: 8064 },
              { dealmkp: 0 },
              { attmkp: 2553.6 },
              { tamkp: 0 },
              { stamkp: 0 },
              { attdisc: 0 },
              { tadisc: 0 },
              { stadisc: 0 },
            ],
            scur: "QAR",
            ucur: "INR",
            exchange_rate: 22.4,
            sup_fare: "1140_1580",
            markupnames: [{ code: "MTA", name: "LaxmiAprilMarkup" }],
          },
          paxpr: [
            {
              tf: 37945.6,
              tax: [
                { YR: 1120 },
                { YR: 672 },
                { OT: 8064 },
                { attmkp: 2553.6 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              bf: 28089.6,
              fbc: "U6MDLDH",
              fareType: "RP",
              pusup_fare: "1140_1580",
              psup_fare: "25536_35392",
              ptype: "ADT",
            },
          ],
          sup: "0",
          airlinesList: "AI",
          servicefee: [
            {
              type: "ATT",
              adt: 2808.96,
              chd: 0,
              inf: 0,
              servicefee_name: "LaxmiServieApril",
              apl_on: "Per Ticket",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 2808.96,
              sup_fee: 0,
            },
            {
              type: "TA",
              adt: 0,
              chd: 0,
              inf: 0,
              apl_on: "",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 0,
              sup_fee: 2808.96,
            },
          ],
        },
        {
          srcorigns: [
            {
              tid: "3bN0PedX1TPTDsPI_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "09:25",
              re: "Non-Refundable",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DEL",
                  dd: "2023-06-07T14:30:00",
                  ad: "2023-06-07T16:55:00",
                  rbd: "Q",
                  fn: "523",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "1",
                  oa: "AI",
                  fa: [],
                  fdur: "02:25",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-06-07T19:50:00",
                  ad: "2023-06-07T21:25:00",
                  rbd: "U",
                  fn: "971",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:05",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "523/971",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          destorigins: [
            {
              tid: "3bN0PedX1TPTDsPI_2",
              ref: 2,
              office_id: "DOHQR28IH",
              sdur: "35:20",
              re: "Non-Refundable",
              stop: 2,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T22:35:00",
                  ad: "2023-06-10T05:05:00",
                  rbd: "U",
                  fn: "972",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:00",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "BOM",
                  dd: "2023-06-10T20:00:00",
                  ad: "2023-06-10T22:10:00",
                  rbd: "U",
                  fn: "805",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "5",
                  oa: "AI",
                  fa: [],
                  fdur: "02:10",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "2" },
                },
                {
                  ref: 3,
                  da: "BOM",
                  ar: "HYD",
                  dd: "2023-06-11T11:00:00",
                  ad: "2023-06-11T12:25:00",
                  rbd: "S",
                  fn: "617",
                  trn: "2",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "3",
                  oa: "AI",
                  fa: [],
                  fdur: "01:25",
                  cl: "Economy",
                  terminal: { dep: "2", arr: "" },
                },
              ],
              flightNumbers: "972/805/617",
              bgd: [{ qun: "30", wt: "kg" }],
            },
            {
              tid: "4Ed3fBJcRAOael5p_2",
              ref: 2,
              office_id: "DOHQR28IH",
              sdur: "35:20",
              re: "Non-Refundable",
              stop: 2,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T22:35:00",
                  ad: "2023-06-10T05:05:00",
                  rbd: "U",
                  fn: "972",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:00",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "BOM",
                  dd: "2023-06-10T15:05:00",
                  ad: "2023-06-10T18:10:00",
                  rbd: "U",
                  fn: "636",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "5",
                  oa: "AI",
                  fa: [],
                  fdur: "03:05",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "2" },
                },
                {
                  ref: 3,
                  da: "BOM",
                  ar: "HYD",
                  dd: "2023-06-11T11:00:00",
                  ad: "2023-06-11T12:25:00",
                  rbd: "S",
                  fn: "617",
                  trn: "2",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "3",
                  oa: "AI",
                  fa: [],
                  fdur: "01:25",
                  cl: "Economy",
                  terminal: { dep: "2", arr: "" },
                },
              ],
              flightNumbers: "972/636/617",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          pr: {
            bf: 28089.6,
            tf: 37945.6,
            tax: [
              { YR: 1120 },
              { YR: 672 },
              { OT: 8064 },
              { dealmkp: 0 },
              { attmkp: 2553.6 },
              { tamkp: 0 },
              { stamkp: 0 },
              { attdisc: 0 },
              { tadisc: 0 },
              { stadisc: 0 },
            ],
            scur: "QAR",
            ucur: "INR",
            exchange_rate: 22.4,
            sup_fare: "1140_1580",
            markupnames: [{ code: "MTA", name: "LaxmiAprilMarkup" }],
          },
          paxpr: [
            {
              tf: 37945.6,
              tax: [
                { YR: 1120 },
                { YR: 672 },
                { OT: 8064 },
                { attmkp: 2553.6 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              bf: 28089.6,
              fbc: "U6MDLDH",
              fareType: "RP",
              pusup_fare: "1140_1580",
              psup_fare: "25536_35392",
              ptype: "ADT",
            },
          ],
          sup: "0",
          airlinesList: "AI",
          servicefee: [
            {
              type: "ATT",
              adt: 2808.96,
              chd: 0,
              inf: 0,
              servicefee_name: "LaxmiServieApril",
              apl_on: "Per Ticket",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 2808.96,
              sup_fee: 0,
            },
            {
              type: "TA",
              adt: 0,
              chd: 0,
              inf: 0,
              apl_on: "",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 0,
              sup_fee: 2808.96,
            },
          ],
        },
        {
          srcorigns: [
            {
              tid: "IgHRcQ72ITXk3Idl_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "27:15",
              re: "Non-Refundable",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DEL",
                  dd: "2023-06-07T20:40:00",
                  ad: "2023-06-07T23:00:00",
                  rbd: "Q",
                  fn: "840",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:20",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-06-08T19:50:00",
                  ad: "2023-06-08T21:25:00",
                  rbd: "U",
                  fn: "971",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "5",
                  oa: "AI",
                  fa: [],
                  fdur: "04:05",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "840/971",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          destorigins: [
            {
              tid: "IgHRcQ72ITXk3Idl_2",
              ref: 2,
              office_id: "DOHQR28IH",
              sdur: "20:35",
              re: "Non-Refundable",
              stop: 2,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T22:35:00",
                  ad: "2023-06-10T05:05:00",
                  rbd: "U",
                  fn: "972",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:00",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "BOM",
                  dd: "2023-06-10T10:00:00",
                  ad: "2023-06-10T12:35:00",
                  rbd: "G",
                  fn: "865",
                  trn: "3",
                  eq: "77L",
                  ac: "AI",
                  ma: "AI",
                  seat: "5",
                  oa: "AI",
                  fa: [],
                  fdur: "02:35",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "2" },
                },
                {
                  ref: 3,
                  da: "BOM",
                  ar: "HYD",
                  dd: "2023-06-10T20:10:00",
                  ad: "2023-06-10T21:40:00",
                  rbd: "G",
                  fn: "619",
                  trn: "2",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "01:30",
                  cl: "Economy",
                  terminal: { dep: "2", arr: "" },
                },
              ],
              flightNumbers: "972/865/619",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          pr: {
            bf: 28089.6,
            tf: 37945.6,
            tax: [
              { YR: 1120 },
              { YR: 672 },
              { OT: 8064 },
              { dealmkp: 0 },
              { attmkp: 2553.6 },
              { tamkp: 0 },
              { stamkp: 0 },
              { attdisc: 0 },
              { tadisc: 0 },
              { stadisc: 0 },
            ],
            scur: "QAR",
            ucur: "INR",
            exchange_rate: 22.4,
            sup_fare: "1140_1580",
            markupnames: [{ code: "MTA", name: "LaxmiAprilMarkup" }],
          },
          paxpr: [
            {
              tf: 37945.6,
              tax: [
                { YR: 1120 },
                { YR: 672 },
                { OT: 8064 },
                { attmkp: 2553.6 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              bf: 28089.6,
              fbc: "U6MDLDH",
              fareType: "RP",
              pusup_fare: "1140_1580",
              psup_fare: "25536_35392",
              ptype: "ADT",
            },
          ],
          sup: "0",
          airlinesList: "AI",
          servicefee: [
            {
              type: "ATT",
              adt: 2808.96,
              chd: 0,
              inf: 0,
              servicefee_name: "LaxmiServieApril",
              apl_on: "Per Ticket",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 2808.96,
              sup_fee: 0,
            },
            {
              type: "TA",
              adt: 0,
              chd: 0,
              inf: 0,
              apl_on: "",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 0,
              sup_fee: 2808.96,
            },
          ],
        },
        {
          srcorigns: [
            {
              tid: "4D4q9WAPvndtrPK3_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "14:00",
              re: "Non-Refundable",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DEL",
                  dd: "2023-06-07T09:55:00",
                  ad: "2023-06-07T12:20:00",
                  rbd: "Q",
                  fn: "543",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:25",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-06-07T19:50:00",
                  ad: "2023-06-07T21:25:00",
                  rbd: "U",
                  fn: "971",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:05",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "543/971",
              bgd: [{ qun: "30", wt: "kg" }],
            },
            {
              tid: "IZI5vuGnJmcGa8m6_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "17:15",
              re: "Non-Refundable",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DEL",
                  dd: "2023-06-07T06:40:00",
                  ad: "2023-06-07T08:55:00",
                  rbd: "Q",
                  fn: "559",
                  eq: "321",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:15",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-06-07T19:50:00",
                  ad: "2023-06-07T21:25:00",
                  rbd: "U",
                  fn: "971",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:05",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "559/971",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          destorigins: [
            {
              tid: "4D4q9WAPvndtrPK3_2",
              ref: 2,
              office_id: "DOHQR28IH",
              sdur: "35:20",
              re: "Non-Refundable",
              stop: 2,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T22:35:00",
                  ad: "2023-06-10T05:05:00",
                  rbd: "U",
                  fn: "972",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:00",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "BOM",
                  dd: "2023-06-10T21:15:00",
                  ad: "2023-06-10T23:30:00",
                  rbd: "U",
                  fn: "605",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "8",
                  oa: "AI",
                  fa: [],
                  fdur: "02:15",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "2" },
                },
                {
                  ref: 3,
                  da: "BOM",
                  ar: "HYD",
                  dd: "2023-06-11T11:00:00",
                  ad: "2023-06-11T12:25:00",
                  rbd: "S",
                  fn: "617",
                  trn: "2",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "3",
                  oa: "AI",
                  fa: [],
                  fdur: "01:25",
                  cl: "Economy",
                  terminal: { dep: "2", arr: "" },
                },
              ],
              flightNumbers: "972/605/617",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          pr: {
            bf: 28089.6,
            tf: 37945.6,
            tax: [
              { YR: 1120 },
              { YR: 672 },
              { OT: 8064 },
              { dealmkp: 0 },
              { attmkp: 2553.6 },
              { tamkp: 0 },
              { stamkp: 0 },
              { attdisc: 0 },
              { tadisc: 0 },
              { stadisc: 0 },
            ],
            scur: "QAR",
            ucur: "INR",
            exchange_rate: 22.4,
            sup_fare: "1140_1580",
            markupnames: [{ code: "MTA", name: "LaxmiAprilMarkup" }],
          },
          paxpr: [
            {
              tf: 37945.6,
              tax: [
                { YR: 1120 },
                { YR: 672 },
                { OT: 8064 },
                { attmkp: 2553.6 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              bf: 28089.6,
              fbc: "U6MDLDH",
              fareType: "RP",
              pusup_fare: "1140_1580",
              psup_fare: "25536_35392",
              ptype: "ADT",
            },
          ],
          sup: "0",
          airlinesList: "AI",
          servicefee: [
            {
              type: "ATT",
              adt: 2808.96,
              chd: 0,
              inf: 0,
              servicefee_name: "LaxmiServieApril",
              apl_on: "Per Ticket",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 2808.96,
              sup_fee: 0,
            },
            {
              type: "TA",
              adt: 0,
              chd: 0,
              inf: 0,
              apl_on: "",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 0,
              sup_fee: 2808.96,
            },
          ],
        },
        {
          srcorigns: [
            {
              tid: "TVGukdUrrCTAN9en_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "27:15",
              re: "Non-Refundable",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DEL",
                  dd: "2023-06-07T20:40:00",
                  ad: "2023-06-07T23:00:00",
                  rbd: "Q",
                  fn: "840",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:20",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-06-08T19:50:00",
                  ad: "2023-06-08T21:25:00",
                  rbd: "U",
                  fn: "971",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "5",
                  oa: "AI",
                  fa: [],
                  fdur: "04:05",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "840/971",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          destorigins: [
            {
              tid: "TVGukdUrrCTAN9en_2",
              ref: 2,
              office_id: "DOHQR28IH",
              sdur: "35:20",
              re: "Non-Refundable",
              stop: 2,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T22:35:00",
                  ad: "2023-06-10T05:05:00",
                  rbd: "U",
                  fn: "972",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:00",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "BOM",
                  dd: "2023-06-10T21:15:00",
                  ad: "2023-06-10T23:30:00",
                  rbd: "U",
                  fn: "605",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "8",
                  oa: "AI",
                  fa: [],
                  fdur: "02:15",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "2" },
                },
                {
                  ref: 3,
                  da: "BOM",
                  ar: "HYD",
                  dd: "2023-06-11T11:00:00",
                  ad: "2023-06-11T12:25:00",
                  rbd: "S",
                  fn: "617",
                  trn: "2",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "3",
                  oa: "AI",
                  fa: [],
                  fdur: "01:25",
                  cl: "Economy",
                  terminal: { dep: "2", arr: "" },
                },
              ],
              flightNumbers: "972/605/617",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          pr: {
            bf: 28089.6,
            tf: 37945.6,
            tax: [
              { YR: 1120 },
              { YR: 672 },
              { OT: 8064 },
              { dealmkp: 0 },
              { attmkp: 2553.6 },
              { tamkp: 0 },
              { stamkp: 0 },
              { attdisc: 0 },
              { tadisc: 0 },
              { stadisc: 0 },
            ],
            scur: "QAR",
            ucur: "INR",
            exchange_rate: 22.4,
            sup_fare: "1140_1580",
            markupnames: [{ code: "MTA", name: "LaxmiAprilMarkup" }],
          },
          paxpr: [
            {
              tf: 37945.6,
              tax: [
                { YR: 1120 },
                { YR: 672 },
                { OT: 8064 },
                { attmkp: 2553.6 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              bf: 28089.6,
              fbc: "U6MDLDH",
              fareType: "RP",
              pusup_fare: "1140_1580",
              psup_fare: "25536_35392",
              ptype: "ADT",
            },
          ],
          sup: "0",
          airlinesList: "AI",
          servicefee: [
            {
              type: "ATT",
              adt: 2808.96,
              chd: 0,
              inf: 0,
              servicefee_name: "LaxmiServieApril",
              apl_on: "Per Ticket",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 2808.96,
              sup_fee: 0,
            },
            {
              type: "TA",
              adt: 0,
              chd: 0,
              inf: 0,
              apl_on: "",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 0,
              sup_fee: 2808.96,
            },
          ],
        },
        {
          srcorigns: [
            {
              tid: "KnNXQ47bXgg0ZCPo_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "15:55",
              re: "Non-Refundable",
              stop: 2,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "BLR",
                  dd: "2023-06-07T08:00:00",
                  ad: "2023-06-07T09:15:00",
                  rbd: "U",
                  fn: "515",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "01:15",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
                {
                  ref: 2,
                  da: "BLR",
                  ar: "DEL",
                  dd: "2023-06-07T13:20:00",
                  ad: "2023-06-07T15:40:00",
                  rbd: "Q",
                  fn: "501",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:20",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 3,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-06-07T19:50:00",
                  ad: "2023-06-07T21:25:00",
                  rbd: "U",
                  fn: "971",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:05",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "515/501/971",
              bgd: [{ qun: "30", wt: "kg" }],
            },
            {
              tid: "N2BViL5wTfkfBgfI_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "15:55",
              re: "Non-Refundable",
              stop: 2,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "BLR",
                  dd: "2023-06-07T08:00:00",
                  ad: "2023-06-07T09:15:00",
                  rbd: "U",
                  fn: "515",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "01:15",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
                {
                  ref: 2,
                  da: "BLR",
                  ar: "DEL",
                  dd: "2023-06-07T10:00:00",
                  ad: "2023-06-07T12:50:00",
                  rbd: "Q",
                  fn: "505",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:50",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 3,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-06-07T19:50:00",
                  ad: "2023-06-07T21:25:00",
                  rbd: "U",
                  fn: "971",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:05",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "515/505/971",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          destorigins: [
            {
              tid: "KnNXQ47bXgg0ZCPo_2",
              ref: 2,
              office_id: "DOHQR28IH",
              sdur: "34:05",
              re: "Non-Refundable",
              stop: 2,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T22:35:00",
                  ad: "2023-06-10T05:05:00",
                  rbd: "U",
                  fn: "972",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:00",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "BLR",
                  dd: "2023-06-10T13:30:00",
                  ad: "2023-06-10T16:15:00",
                  rbd: "Q",
                  fn: "502",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:45",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
                {
                  ref: 3,
                  da: "BLR",
                  ar: "HYD",
                  dd: "2023-06-11T10:00:00",
                  ad: "2023-06-11T11:10:00",
                  rbd: "U",
                  fn: "516",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "8",
                  oa: "AI",
                  fa: [],
                  fdur: "01:10",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
              ],
              flightNumbers: "972/502/516",
              bgd: [{ qun: "30", wt: "kg" }],
            },
            {
              tid: "k8IlGa0xG0DL8Qk3_2",
              ref: 2,
              office_id: "DOHQR28IH",
              sdur: "34:05",
              re: "Non-Refundable",
              stop: 2,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T22:35:00",
                  ad: "2023-06-10T05:05:00",
                  rbd: "U",
                  fn: "972",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:00",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "BLR",
                  dd: "2023-06-10T21:15:00",
                  ad: "2023-06-11T00:05:00",
                  rbd: "Q",
                  fn: "504",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:50",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
                {
                  ref: 3,
                  da: "BLR",
                  ar: "HYD",
                  dd: "2023-06-11T10:00:00",
                  ad: "2023-06-11T11:10:00",
                  rbd: "U",
                  fn: "516",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "8",
                  oa: "AI",
                  fa: [],
                  fdur: "01:10",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
              ],
              flightNumbers: "972/504/516",
              bgd: [{ qun: "30", wt: "kg" }],
            },
            {
              tid: "MVy0V4qbdOzyQS2b_2",
              ref: 2,
              office_id: "DOHQR28IH",
              sdur: "34:05",
              re: "Non-Refundable",
              stop: 2,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T22:35:00",
                  ad: "2023-06-10T05:05:00",
                  rbd: "U",
                  fn: "972",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:00",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "BLR",
                  dd: "2023-06-10T09:45:00",
                  ad: "2023-06-10T12:30:00",
                  rbd: "Q",
                  fn: "506",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:45",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
                {
                  ref: 3,
                  da: "BLR",
                  ar: "HYD",
                  dd: "2023-06-11T10:00:00",
                  ad: "2023-06-11T11:10:00",
                  rbd: "U",
                  fn: "516",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "8",
                  oa: "AI",
                  fa: [],
                  fdur: "01:10",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
              ],
              flightNumbers: "972/506/516",
              bgd: [{ qun: "30", wt: "kg" }],
            },
            {
              tid: "d2txGfmLdFvAKS3J_2",
              ref: 2,
              office_id: "DOHQR28IH",
              sdur: "34:05",
              re: "Non-Refundable",
              stop: 2,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T22:35:00",
                  ad: "2023-06-10T05:05:00",
                  rbd: "U",
                  fn: "972",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:00",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "BLR",
                  dd: "2023-06-10T17:15:00",
                  ad: "2023-06-10T20:15:00",
                  rbd: "Q",
                  fn: "807",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "03:00",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
                {
                  ref: 3,
                  da: "BLR",
                  ar: "HYD",
                  dd: "2023-06-11T10:00:00",
                  ad: "2023-06-11T11:10:00",
                  rbd: "U",
                  fn: "516",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "8",
                  oa: "AI",
                  fa: [],
                  fdur: "01:10",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
              ],
              flightNumbers: "972/807/516",
              bgd: [{ qun: "30", wt: "kg" }],
            },
            {
              tid: "LlQzh9pgSdZoOiu9_2",
              ref: 2,
              office_id: "DOHQR28IH",
              sdur: "34:05",
              re: "Non-Refundable",
              stop: 2,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T22:35:00",
                  ad: "2023-06-10T05:05:00",
                  rbd: "U",
                  fn: "972",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:00",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "BLR",
                  dd: "2023-06-10T14:55:00",
                  ad: "2023-06-10T18:20:00",
                  rbd: "Q",
                  fn: "815",
                  trn: "3",
                  eq: "321",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "03:25",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
                {
                  ref: 3,
                  da: "BLR",
                  ar: "HYD",
                  dd: "2023-06-11T10:00:00",
                  ad: "2023-06-11T11:10:00",
                  rbd: "U",
                  fn: "516",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "8",
                  oa: "AI",
                  fa: [],
                  fdur: "01:10",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
              ],
              flightNumbers: "972/815/516",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          pr: {
            bf: 27843.2,
            tf: 38147.2,
            tax: [
              { YR: 1344 },
              { YR: 896 },
              { OT: 8064 },
              { dealmkp: 0 },
              { attmkp: 2531.2 },
              { tamkp: 0 },
              { stamkp: 0 },
              { attdisc: 0 },
              { tadisc: 0 },
              { stadisc: 0 },
            ],
            scur: "QAR",
            ucur: "INR",
            exchange_rate: 22.4,
            sup_fare: "1130_1590",
            markupnames: [{ code: "MTA", name: "LaxmiAprilMarkup" }],
          },
          paxpr: [
            {
              tf: 38147.2,
              tax: [
                { YR: 1344 },
                { YR: 896 },
                { OT: 8064 },
                { attmkp: 2531.2 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              bf: 27843.2,
              fbc: "UIP",
              fareType: "RP",
              pusup_fare: "1130_1590",
              psup_fare: "25312_35616",
              ptype: "ADT",
            },
          ],
          sup: "0",
          airlinesList: "AI",
          servicefee: [
            {
              type: "ATT",
              adt: 2784.32,
              chd: 0,
              inf: 0,
              servicefee_name: "LaxmiServieApril",
              apl_on: "Per Ticket",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 2784.32,
              sup_fee: 0,
            },
            {
              type: "TA",
              adt: 0,
              chd: 0,
              inf: 0,
              apl_on: "",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 0,
              sup_fee: 2784.32,
            },
          ],
        },
        {
          srcorigns: [
            {
              tid: "F9o3HwKNq3y6Brvv_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "15:55",
              re: "Non-Refundable",
              stop: 2,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "BLR",
                  dd: "2023-06-07T08:00:00",
                  ad: "2023-06-07T09:15:00",
                  rbd: "U",
                  fn: "515",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "01:15",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
                {
                  ref: 2,
                  da: "BLR",
                  ar: "DEL",
                  dd: "2023-06-07T13:20:00",
                  ad: "2023-06-07T15:40:00",
                  rbd: "Q",
                  fn: "501",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:20",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 3,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-06-07T19:50:00",
                  ad: "2023-06-07T21:25:00",
                  rbd: "U",
                  fn: "971",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:05",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "515/501/971",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          destorigins: [
            {
              tid: "F9o3HwKNq3y6Brvv_2",
              ref: 2,
              office_id: "DOHQR28IH",
              sdur: "34:05",
              re: "Non-Refundable",
              stop: 2,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T22:35:00",
                  ad: "2023-06-10T05:05:00",
                  rbd: "U",
                  fn: "972",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:00",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "BLR",
                  dd: "2023-06-10T07:30:00",
                  ad: "2023-06-10T10:30:00",
                  rbd: "Q",
                  fn: "813",
                  trn: "3",
                  eq: "321",
                  ac: "AI",
                  ma: "AI",
                  seat: "1",
                  oa: "AI",
                  fa: [],
                  fdur: "03:00",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
                {
                  ref: 3,
                  da: "BLR",
                  ar: "HYD",
                  dd: "2023-06-11T10:00:00",
                  ad: "2023-06-11T11:10:00",
                  rbd: "U",
                  fn: "516",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "8",
                  oa: "AI",
                  fa: [],
                  fdur: "01:10",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
              ],
              flightNumbers: "972/813/516",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          pr: {
            bf: 27843.2,
            tf: 38147.2,
            tax: [
              { YR: 1344 },
              { YR: 896 },
              { OT: 8064 },
              { dealmkp: 0 },
              { attmkp: 2531.2 },
              { tamkp: 0 },
              { stamkp: 0 },
              { attdisc: 0 },
              { tadisc: 0 },
              { stadisc: 0 },
            ],
            scur: "QAR",
            ucur: "INR",
            exchange_rate: 22.4,
            sup_fare: "1130_1590",
            markupnames: [{ code: "MTA", name: "LaxmiAprilMarkup" }],
          },
          paxpr: [
            {
              tf: 38147.2,
              tax: [
                { YR: 1344 },
                { YR: 896 },
                { OT: 8064 },
                { attmkp: 2531.2 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              bf: 27843.2,
              fbc: "UIP",
              fareType: "RP",
              pusup_fare: "1130_1590",
              psup_fare: "25312_35616",
              ptype: "ADT",
            },
          ],
          sup: "0",
          airlinesList: "AI",
          servicefee: [
            {
              type: "ATT",
              adt: 2784.32,
              chd: 0,
              inf: 0,
              servicefee_name: "LaxmiServieApril",
              apl_on: "Per Ticket",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 2784.32,
              sup_fee: 0,
            },
            {
              type: "TA",
              adt: 0,
              chd: 0,
              inf: 0,
              apl_on: "",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 0,
              sup_fee: 2784.32,
            },
          ],
        },
        {
          srcorigns: [
            {
              tid: "AKgJ9fxz5N1Gc0Cl_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "15:55",
              re: "Non-Refundable",
              stop: 2,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "BLR",
                  dd: "2023-06-07T08:00:00",
                  ad: "2023-06-07T09:15:00",
                  rbd: "U",
                  fn: "515",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "01:15",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
                {
                  ref: 2,
                  da: "BLR",
                  ar: "DEL",
                  dd: "2023-06-07T11:15:00",
                  ad: "2023-06-07T14:20:00",
                  rbd: "Q",
                  fn: "507",
                  eq: "321",
                  ac: "AI",
                  ma: "AI",
                  seat: "1",
                  oa: "AI",
                  fa: [],
                  fdur: "03:05",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 3,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-06-07T19:50:00",
                  ad: "2023-06-07T21:25:00",
                  rbd: "U",
                  fn: "971",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:05",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "515/507/971",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          destorigins: [
            {
              tid: "AKgJ9fxz5N1Gc0Cl_2",
              ref: 2,
              office_id: "DOHQR28IH",
              sdur: "34:05",
              re: "Non-Refundable",
              stop: 2,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T22:35:00",
                  ad: "2023-06-10T05:05:00",
                  rbd: "U",
                  fn: "972",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:00",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "BLR",
                  dd: "2023-06-10T21:15:00",
                  ad: "2023-06-11T00:05:00",
                  rbd: "Q",
                  fn: "504",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:50",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
                {
                  ref: 3,
                  da: "BLR",
                  ar: "HYD",
                  dd: "2023-06-11T10:00:00",
                  ad: "2023-06-11T11:10:00",
                  rbd: "U",
                  fn: "516",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "8",
                  oa: "AI",
                  fa: [],
                  fdur: "01:10",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
              ],
              flightNumbers: "972/504/516",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          pr: {
            bf: 27843.2,
            tf: 38147.2,
            tax: [
              { YR: 1344 },
              { YR: 896 },
              { OT: 8064 },
              { dealmkp: 0 },
              { attmkp: 2531.2 },
              { tamkp: 0 },
              { stamkp: 0 },
              { attdisc: 0 },
              { tadisc: 0 },
              { stadisc: 0 },
            ],
            scur: "QAR",
            ucur: "INR",
            exchange_rate: 22.4,
            sup_fare: "1130_1590",
            markupnames: [{ code: "MTA", name: "LaxmiAprilMarkup" }],
          },
          paxpr: [
            {
              tf: 38147.2,
              tax: [
                { YR: 1344 },
                { YR: 896 },
                { OT: 8064 },
                { attmkp: 2531.2 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              bf: 27843.2,
              fbc: "UIP",
              fareType: "RP",
              pusup_fare: "1130_1590",
              psup_fare: "25312_35616",
              ptype: "ADT",
            },
          ],
          sup: "0",
          airlinesList: "AI",
          servicefee: [
            {
              type: "ATT",
              adt: 2784.32,
              chd: 0,
              inf: 0,
              servicefee_name: "LaxmiServieApril",
              apl_on: "Per Ticket",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 2784.32,
              sup_fee: 0,
            },
            {
              type: "TA",
              adt: 0,
              chd: 0,
              inf: 0,
              apl_on: "",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 0,
              sup_fee: 2784.32,
            },
          ],
        },
        {
          srcorigns: [
            {
              tid: "gbDNpTqDySK6La76_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "39:55",
              re: "Non-Refundable",
              stop: 2,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "BLR",
                  dd: "2023-06-07T08:00:00",
                  ad: "2023-06-07T09:15:00",
                  rbd: "U",
                  fn: "515",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "01:15",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
                {
                  ref: 2,
                  da: "BLR",
                  ar: "DEL",
                  dd: "2023-06-07T17:00:00",
                  ad: "2023-06-07T19:50:00",
                  rbd: "Q",
                  fn: "503",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:50",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 3,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-06-08T19:50:00",
                  ad: "2023-06-08T21:25:00",
                  rbd: "U",
                  fn: "971",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "5",
                  oa: "AI",
                  fa: [],
                  fdur: "04:05",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "515/503/971",
              bgd: [{ qun: "30", wt: "kg" }],
            },
            {
              tid: "Q1gkFAZDoVtuI9NK_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "39:55",
              re: "Non-Refundable",
              stop: 2,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "BLR",
                  dd: "2023-06-07T08:00:00",
                  ad: "2023-06-07T09:15:00",
                  rbd: "U",
                  fn: "515",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "01:15",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
                {
                  ref: 2,
                  da: "BLR",
                  ar: "DEL",
                  dd: "2023-06-07T19:15:00",
                  ad: "2023-06-07T22:15:00",
                  rbd: "Q",
                  fn: "509",
                  eq: "321",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "03:00",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 3,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-06-08T19:50:00",
                  ad: "2023-06-08T21:25:00",
                  rbd: "U",
                  fn: "971",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "5",
                  oa: "AI",
                  fa: [],
                  fdur: "04:05",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "515/509/971",
              bgd: [{ qun: "30", wt: "kg" }],
            },
            {
              tid: "6PxANacaTPzNuSqM_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "39:55",
              re: "Non-Refundable",
              stop: 2,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "BLR",
                  dd: "2023-06-07T08:00:00",
                  ad: "2023-06-07T09:15:00",
                  rbd: "U",
                  fn: "515",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "01:15",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
                {
                  ref: 2,
                  da: "BLR",
                  ar: "DEL",
                  dd: "2023-06-08T06:10:00",
                  ad: "2023-06-08T08:55:00",
                  rbd: "Q",
                  fn: "804",
                  trn: "1",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:45",
                  cl: "Economy",
                  terminal: { dep: "1", arr: "3" },
                },
                {
                  ref: 3,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-06-08T19:50:00",
                  ad: "2023-06-08T21:25:00",
                  rbd: "U",
                  fn: "971",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "5",
                  oa: "AI",
                  fa: [],
                  fdur: "04:05",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "515/804/971",
              bgd: [{ qun: "30", wt: "kg" }],
            },
            {
              tid: "Tx9DMlpDKdwpvdd8_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "39:55",
              re: "Non-Refundable",
              stop: 2,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "BLR",
                  dd: "2023-06-07T08:00:00",
                  ad: "2023-06-07T09:15:00",
                  rbd: "U",
                  fn: "515",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "01:15",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
                {
                  ref: 2,
                  da: "BLR",
                  ar: "DEL",
                  dd: "2023-06-07T21:00:00",
                  ad: "2023-06-08T00:05:00",
                  rbd: "Q",
                  fn: "808",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "03:05",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 3,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-06-08T19:50:00",
                  ad: "2023-06-08T21:25:00",
                  rbd: "U",
                  fn: "971",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "5",
                  oa: "AI",
                  fa: [],
                  fdur: "04:05",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "515/808/971",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          destorigins: [
            {
              tid: "gbDNpTqDySK6La76_2",
              ref: 2,
              office_id: "DOHQR28IH",
              sdur: "34:05",
              re: "Non-Refundable",
              stop: 2,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T22:35:00",
                  ad: "2023-06-10T05:05:00",
                  rbd: "U",
                  fn: "972",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:00",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "BLR",
                  dd: "2023-06-10T21:15:00",
                  ad: "2023-06-11T00:05:00",
                  rbd: "Q",
                  fn: "504",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:50",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
                {
                  ref: 3,
                  da: "BLR",
                  ar: "HYD",
                  dd: "2023-06-11T10:00:00",
                  ad: "2023-06-11T11:10:00",
                  rbd: "U",
                  fn: "516",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "8",
                  oa: "AI",
                  fa: [],
                  fdur: "01:10",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
              ],
              flightNumbers: "972/504/516",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          pr: {
            bf: 27843.2,
            tf: 38147.2,
            tax: [
              { YR: 1344 },
              { YR: 896 },
              { OT: 8064 },
              { dealmkp: 0 },
              { attmkp: 2531.2 },
              { tamkp: 0 },
              { stamkp: 0 },
              { attdisc: 0 },
              { tadisc: 0 },
              { stadisc: 0 },
            ],
            scur: "QAR",
            ucur: "INR",
            exchange_rate: 22.4,
            sup_fare: "1130_1590",
            markupnames: [{ code: "MTA", name: "LaxmiAprilMarkup" }],
          },
          paxpr: [
            {
              tf: 38147.2,
              tax: [
                { YR: 1344 },
                { YR: 896 },
                { OT: 8064 },
                { attmkp: 2531.2 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              bf: 27843.2,
              fbc: "UIP",
              fareType: "RP",
              pusup_fare: "1130_1590",
              psup_fare: "25312_35616",
              ptype: "ADT",
            },
          ],
          sup: "0",
          airlinesList: "AI",
          servicefee: [
            {
              type: "ATT",
              adt: 2784.32,
              chd: 0,
              inf: 0,
              servicefee_name: "LaxmiServieApril",
              apl_on: "Per Ticket",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 2784.32,
              sup_fee: 0,
            },
            {
              type: "TA",
              adt: 0,
              chd: 0,
              inf: 0,
              apl_on: "",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 0,
              sup_fee: 2784.32,
            },
          ],
        },
        {
          srcorigns: [
            {
              tid: "T9U2wE95JqNTnnQq_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "09:25",
              re: "Non-Refundable",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DEL",
                  dd: "2023-06-07T14:30:00",
                  ad: "2023-06-07T16:55:00",
                  rbd: "Q",
                  fn: "523",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "1",
                  oa: "AI",
                  fa: [],
                  fdur: "02:25",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-06-07T19:50:00",
                  ad: "2023-06-07T21:25:00",
                  rbd: "U",
                  fn: "971",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:05",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "523/971",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          destorigins: [
            {
              tid: "T9U2wE95JqNTnnQq_2",
              ref: 2,
              office_id: "DOHQR28IH",
              sdur: "20:35",
              re: "Non-Refundable",
              stop: 2,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T22:35:00",
                  ad: "2023-06-10T05:05:00",
                  rbd: "U",
                  fn: "972",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:00",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "BOM",
                  dd: "2023-06-10T08:00:00",
                  ad: "2023-06-10T10:10:00",
                  rbd: "U",
                  fn: "665",
                  trn: "3",
                  eq: "321",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:10",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "2" },
                },
                {
                  ref: 3,
                  da: "BOM",
                  ar: "HYD",
                  dd: "2023-06-10T20:10:00",
                  ad: "2023-06-10T21:40:00",
                  rbd: "U",
                  fn: "619",
                  trn: "2",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "01:30",
                  cl: "Economy",
                  terminal: { dep: "2", arr: "" },
                },
              ],
              flightNumbers: "972/665/619",
              bgd: [{ qun: "30", wt: "kg" }],
            },
            {
              tid: "gnIWpk3kz7TTg4TN_2",
              ref: 2,
              office_id: "DOHQR28IH",
              sdur: "20:35",
              re: "Non-Refundable",
              stop: 2,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T22:35:00",
                  ad: "2023-06-10T05:05:00",
                  rbd: "U",
                  fn: "972",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:00",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "BOM",
                  dd: "2023-06-10T09:00:00",
                  ad: "2023-06-10T11:15:00",
                  rbd: "U",
                  fn: "678",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:15",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "2" },
                },
                {
                  ref: 3,
                  da: "BOM",
                  ar: "HYD",
                  dd: "2023-06-10T20:10:00",
                  ad: "2023-06-10T21:40:00",
                  rbd: "U",
                  fn: "619",
                  trn: "2",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "01:30",
                  cl: "Economy",
                  terminal: { dep: "2", arr: "" },
                },
              ],
              flightNumbers: "972/678/619",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          pr: {
            bf: 29075.2,
            tf: 38931.2,
            tax: [
              { YR: 1120 },
              { YR: 672 },
              { OT: 8064 },
              { dealmkp: 0 },
              { attmkp: 2643.2 },
              { tamkp: 0 },
              { stamkp: 0 },
              { attdisc: 0 },
              { tadisc: 0 },
              { stadisc: 0 },
            ],
            scur: "QAR",
            ucur: "INR",
            exchange_rate: 22.4,
            sup_fare: "1180_1620",
            markupnames: [{ code: "MTA", name: "LaxmiAprilMarkup" }],
          },
          paxpr: [
            {
              tf: 38931.2,
              tax: [
                { YR: 1120 },
                { YR: 672 },
                { OT: 8064 },
                { attmkp: 2643.2 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              bf: 29075.2,
              fbc: "U6MDLDH",
              fareType: "RP",
              pusup_fare: "1180_1620",
              psup_fare: "26432_36288",
              ptype: "ADT",
            },
          ],
          sup: "0",
          airlinesList: "AI",
          servicefee: [
            {
              type: "ATT",
              adt: 2907.52,
              chd: 0,
              inf: 0,
              servicefee_name: "LaxmiServieApril",
              apl_on: "Per Ticket",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 2907.52,
              sup_fee: 0,
            },
            {
              type: "TA",
              adt: 0,
              chd: 0,
              inf: 0,
              apl_on: "",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 0,
              sup_fee: 2907.52,
            },
          ],
        },
        {
          srcorigns: [
            {
              tid: "b0zH5Km7QVfI9CLh_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "25:30",
              re: "Non-Refundable",
              stop: 2,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "BOM",
                  dd: "2023-06-07T22:25:00",
                  ad: "2023-06-07T23:50:00",
                  rbd: "U",
                  fn: "698",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "01:25",
                  cl: "Economy",
                  terminal: { dep: "", arr: "2" },
                },
                {
                  ref: 2,
                  da: "BOM",
                  ar: "DEL",
                  dd: "2023-06-08T13:00:00",
                  ad: "2023-06-08T15:15:00",
                  rbd: "U",
                  fn: "677",
                  trn: "2",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:15",
                  cl: "Economy",
                  terminal: { dep: "2", arr: "3" },
                },
                {
                  ref: 3,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-06-08T19:50:00",
                  ad: "2023-06-08T21:25:00",
                  rbd: "U",
                  fn: "971",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "5",
                  oa: "AI",
                  fa: [],
                  fdur: "04:05",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "698/677/971",
              bgd: [{ qun: "30", wt: "kg" }],
            },
            {
              tid: "pT8Udsug3m7SGrTe_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "25:30",
              re: "Non-Refundable",
              stop: 2,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "BOM",
                  dd: "2023-06-07T22:25:00",
                  ad: "2023-06-07T23:50:00",
                  rbd: "U",
                  fn: "698",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "01:25",
                  cl: "Economy",
                  terminal: { dep: "", arr: "2" },
                },
                {
                  ref: 2,
                  da: "BOM",
                  ar: "DEL",
                  dd: "2023-06-08T16:00:00",
                  ad: "2023-06-08T18:20:00",
                  rbd: "U",
                  fn: "687",
                  trn: "2",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:20",
                  cl: "Economy",
                  terminal: { dep: "2", arr: "3" },
                },
                {
                  ref: 3,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-06-08T19:50:00",
                  ad: "2023-06-08T21:25:00",
                  rbd: "U",
                  fn: "971",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "5",
                  oa: "AI",
                  fa: [],
                  fdur: "04:05",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "698/687/971",
              bgd: [{ qun: "30", wt: "kg" }],
            },
            {
              tid: "wBQOVSaTJzWpxp4z_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "25:30",
              re: "Non-Refundable",
              stop: 2,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "BOM",
                  dd: "2023-06-07T22:25:00",
                  ad: "2023-06-07T23:50:00",
                  rbd: "U",
                  fn: "698",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "01:25",
                  cl: "Economy",
                  terminal: { dep: "", arr: "2" },
                },
                {
                  ref: 2,
                  da: "BOM",
                  ar: "DEL",
                  dd: "2023-06-08T08:00:00",
                  ad: "2023-06-08T10:15:00",
                  rbd: "U",
                  fn: "806",
                  trn: "2",
                  eq: "321",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:15",
                  cl: "Economy",
                  terminal: { dep: "2", arr: "3" },
                },
                {
                  ref: 3,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-06-08T19:50:00",
                  ad: "2023-06-08T21:25:00",
                  rbd: "U",
                  fn: "971",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "5",
                  oa: "AI",
                  fa: [],
                  fdur: "04:05",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "698/806/971",
              bgd: [{ qun: "30", wt: "kg" }],
            },
            {
              tid: "Zr8HG4VEk8u0WlBT_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "25:30",
              re: "Non-Refundable",
              stop: 2,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "BOM",
                  dd: "2023-06-07T22:25:00",
                  ad: "2023-06-07T23:50:00",
                  rbd: "U",
                  fn: "698",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "01:25",
                  cl: "Economy",
                  terminal: { dep: "", arr: "2" },
                },
                {
                  ref: 2,
                  da: "BOM",
                  ar: "DEL",
                  dd: "2023-06-08T10:00:00",
                  ad: "2023-06-08T12:20:00",
                  rbd: "U",
                  fn: "809",
                  trn: "2",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:20",
                  cl: "Economy",
                  terminal: { dep: "2", arr: "3" },
                },
                {
                  ref: 3,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-06-08T19:50:00",
                  ad: "2023-06-08T21:25:00",
                  rbd: "U",
                  fn: "971",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "5",
                  oa: "AI",
                  fa: [],
                  fdur: "04:05",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "698/809/971",
              bgd: [{ qun: "30", wt: "kg" }],
            },
            {
              tid: "EovAISf36hFNSqpP_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "25:30",
              re: "Non-Refundable",
              stop: 2,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "BOM",
                  dd: "2023-06-07T22:25:00",
                  ad: "2023-06-07T23:50:00",
                  rbd: "U",
                  fn: "698",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "01:25",
                  cl: "Economy",
                  terminal: { dep: "", arr: "2" },
                },
                {
                  ref: 2,
                  da: "BOM",
                  ar: "DEL",
                  dd: "2023-06-08T07:00:00",
                  ad: "2023-06-08T09:05:00",
                  rbd: "U",
                  fn: "864",
                  trn: "2",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:05",
                  cl: "Economy",
                  terminal: { dep: "2", arr: "3" },
                },
                {
                  ref: 3,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-06-08T19:50:00",
                  ad: "2023-06-08T21:25:00",
                  rbd: "U",
                  fn: "971",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "5",
                  oa: "AI",
                  fa: [],
                  fdur: "04:05",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "698/864/971",
              bgd: [{ qun: "30", wt: "kg" }],
            },
            {
              tid: "ZbIQQTotDKagsElA_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "25:30",
              re: "Non-Refundable",
              stop: 2,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "BOM",
                  dd: "2023-06-07T22:25:00",
                  ad: "2023-06-07T23:50:00",
                  rbd: "U",
                  fn: "698",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "01:25",
                  cl: "Economy",
                  terminal: { dep: "", arr: "2" },
                },
                {
                  ref: 2,
                  da: "BOM",
                  ar: "DEL",
                  dd: "2023-06-08T09:00:00",
                  ad: "2023-06-08T11:20:00",
                  rbd: "U",
                  fn: "866",
                  trn: "2",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:20",
                  cl: "Economy",
                  terminal: { dep: "2", arr: "3" },
                },
                {
                  ref: 3,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-06-08T19:50:00",
                  ad: "2023-06-08T21:25:00",
                  rbd: "U",
                  fn: "971",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "5",
                  oa: "AI",
                  fa: [],
                  fdur: "04:05",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "698/866/971",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          destorigins: [
            {
              tid: "b0zH5Km7QVfI9CLh_2",
              ref: 2,
              office_id: "DOHQR28IH",
              sdur: "08:20",
              re: "Non-Refundable",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T22:35:00",
                  ad: "2023-06-10T05:05:00",
                  rbd: "U",
                  fn: "972",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:00",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-06-10T07:10:00",
                  ad: "2023-06-10T09:25:00",
                  rbd: "Q",
                  fn: "560",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:15",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "972/560",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          pr: {
            bf: 29075.2,
            tf: 38931.2,
            tax: [
              { YR: 1120 },
              { YR: 672 },
              { OT: 8064 },
              { dealmkp: 0 },
              { attmkp: 2643.2 },
              { tamkp: 0 },
              { stamkp: 0 },
              { attdisc: 0 },
              { tadisc: 0 },
              { stadisc: 0 },
            ],
            scur: "QAR",
            ucur: "INR",
            exchange_rate: 22.4,
            sup_fare: "1180_1620",
            markupnames: [{ code: "MTA", name: "LaxmiAprilMarkup" }],
          },
          paxpr: [
            {
              tf: 38931.2,
              tax: [
                { YR: 1120 },
                { YR: 672 },
                { OT: 8064 },
                { attmkp: 2643.2 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              bf: 29075.2,
              fbc: "UIP",
              fareType: "RP",
              pusup_fare: "1180_1620",
              psup_fare: "26432_36288",
              ptype: "ADT",
            },
          ],
          sup: "0",
          airlinesList: "AI",
          servicefee: [
            {
              type: "ATT",
              adt: 2907.52,
              chd: 0,
              inf: 0,
              servicefee_name: "LaxmiServieApril",
              apl_on: "Per Ticket",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 2907.52,
              sup_fee: 0,
            },
            {
              type: "TA",
              adt: 0,
              chd: 0,
              inf: 0,
              apl_on: "",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 0,
              sup_fee: 2907.52,
            },
          ],
        },
        {
          srcorigns: [
            {
              tid: "SZF3TDSmAx1HfWPt_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "14:00",
              re: "Non-Refundable",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DEL",
                  dd: "2023-06-07T09:55:00",
                  ad: "2023-06-07T12:20:00",
                  rbd: "Q",
                  fn: "543",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:25",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-06-07T19:50:00",
                  ad: "2023-06-07T21:25:00",
                  rbd: "U",
                  fn: "971",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:05",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "543/971",
              bgd: [{ qun: "30", wt: "kg" }],
            },
            {
              tid: "oF7rg0Nz1InEbtwC_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "17:15",
              re: "Non-Refundable",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DEL",
                  dd: "2023-06-07T06:40:00",
                  ad: "2023-06-07T08:55:00",
                  rbd: "Q",
                  fn: "559",
                  eq: "321",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:15",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-06-07T19:50:00",
                  ad: "2023-06-07T21:25:00",
                  rbd: "U",
                  fn: "971",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:05",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "559/971",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          destorigins: [
            {
              tid: "SZF3TDSmAx1HfWPt_2",
              ref: 2,
              office_id: "DOHQR28IH",
              sdur: "20:35",
              re: "Non-Refundable",
              stop: 2,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T22:35:00",
                  ad: "2023-06-10T05:05:00",
                  rbd: "U",
                  fn: "972",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:00",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "BOM",
                  dd: "2023-06-10T08:00:00",
                  ad: "2023-06-10T10:10:00",
                  rbd: "U",
                  fn: "665",
                  trn: "3",
                  eq: "321",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:10",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "2" },
                },
                {
                  ref: 3,
                  da: "BOM",
                  ar: "HYD",
                  dd: "2023-06-10T20:10:00",
                  ad: "2023-06-10T21:40:00",
                  rbd: "U",
                  fn: "619",
                  trn: "2",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "01:30",
                  cl: "Economy",
                  terminal: { dep: "2", arr: "" },
                },
              ],
              flightNumbers: "972/665/619",
              bgd: [{ qun: "30", wt: "kg" }],
            },
            {
              tid: "SKZ7lcOHLDRITbHx_2",
              ref: 2,
              office_id: "DOHQR28IH",
              sdur: "20:35",
              re: "Non-Refundable",
              stop: 2,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T22:35:00",
                  ad: "2023-06-10T05:05:00",
                  rbd: "U",
                  fn: "972",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:00",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "BOM",
                  dd: "2023-06-10T09:00:00",
                  ad: "2023-06-10T11:15:00",
                  rbd: "U",
                  fn: "678",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:15",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "2" },
                },
                {
                  ref: 3,
                  da: "BOM",
                  ar: "HYD",
                  dd: "2023-06-10T20:10:00",
                  ad: "2023-06-10T21:40:00",
                  rbd: "U",
                  fn: "619",
                  trn: "2",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "01:30",
                  cl: "Economy",
                  terminal: { dep: "2", arr: "" },
                },
              ],
              flightNumbers: "972/678/619",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          pr: {
            bf: 29075.2,
            tf: 38931.2,
            tax: [
              { YR: 1120 },
              { YR: 672 },
              { OT: 8064 },
              { dealmkp: 0 },
              { attmkp: 2643.2 },
              { tamkp: 0 },
              { stamkp: 0 },
              { attdisc: 0 },
              { tadisc: 0 },
              { stadisc: 0 },
            ],
            scur: "QAR",
            ucur: "INR",
            exchange_rate: 22.4,
            sup_fare: "1180_1620",
            markupnames: [{ code: "MTA", name: "LaxmiAprilMarkup" }],
          },
          paxpr: [
            {
              tf: 38931.2,
              tax: [
                { YR: 1120 },
                { YR: 672 },
                { OT: 8064 },
                { attmkp: 2643.2 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              bf: 29075.2,
              fbc: "U6MDLDH",
              fareType: "RP",
              pusup_fare: "1180_1620",
              psup_fare: "26432_36288",
              ptype: "ADT",
            },
          ],
          sup: "0",
          airlinesList: "AI",
          servicefee: [
            {
              type: "ATT",
              adt: 2907.52,
              chd: 0,
              inf: 0,
              servicefee_name: "LaxmiServieApril",
              apl_on: "Per Ticket",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 2907.52,
              sup_fee: 0,
            },
            {
              type: "TA",
              adt: 0,
              chd: 0,
              inf: 0,
              apl_on: "",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 0,
              sup_fee: 2907.52,
            },
          ],
        },
        {
          srcorigns: [
            {
              tid: "5r9qaO6tMacHOGFI_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "27:15",
              re: "Non-Refundable",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DEL",
                  dd: "2023-06-07T20:40:00",
                  ad: "2023-06-07T23:00:00",
                  rbd: "Q",
                  fn: "840",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:20",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-06-08T19:50:00",
                  ad: "2023-06-08T21:25:00",
                  rbd: "U",
                  fn: "971",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "5",
                  oa: "AI",
                  fa: [],
                  fdur: "04:05",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "840/971",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          destorigins: [
            {
              tid: "5r9qaO6tMacHOGFI_2",
              ref: 2,
              office_id: "DOHQR28IH",
              sdur: "20:35",
              re: "Non-Refundable",
              stop: 2,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T22:35:00",
                  ad: "2023-06-10T05:05:00",
                  rbd: "U",
                  fn: "972",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:00",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "BOM",
                  dd: "2023-06-10T08:00:00",
                  ad: "2023-06-10T10:10:00",
                  rbd: "U",
                  fn: "665",
                  trn: "3",
                  eq: "321",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:10",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "2" },
                },
                {
                  ref: 3,
                  da: "BOM",
                  ar: "HYD",
                  dd: "2023-06-10T20:10:00",
                  ad: "2023-06-10T21:40:00",
                  rbd: "U",
                  fn: "619",
                  trn: "2",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "01:30",
                  cl: "Economy",
                  terminal: { dep: "2", arr: "" },
                },
              ],
              flightNumbers: "972/665/619",
              bgd: [{ qun: "30", wt: "kg" }],
            },
            {
              tid: "wtG5r0u9poeATTbR_2",
              ref: 2,
              office_id: "DOHQR28IH",
              sdur: "20:35",
              re: "Non-Refundable",
              stop: 2,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T22:35:00",
                  ad: "2023-06-10T05:05:00",
                  rbd: "U",
                  fn: "972",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:00",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "BOM",
                  dd: "2023-06-10T09:00:00",
                  ad: "2023-06-10T11:15:00",
                  rbd: "U",
                  fn: "678",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:15",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "2" },
                },
                {
                  ref: 3,
                  da: "BOM",
                  ar: "HYD",
                  dd: "2023-06-10T20:10:00",
                  ad: "2023-06-10T21:40:00",
                  rbd: "U",
                  fn: "619",
                  trn: "2",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "01:30",
                  cl: "Economy",
                  terminal: { dep: "2", arr: "" },
                },
              ],
              flightNumbers: "972/678/619",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          pr: {
            bf: 29075.2,
            tf: 38931.2,
            tax: [
              { YR: 1120 },
              { YR: 672 },
              { OT: 8064 },
              { dealmkp: 0 },
              { attmkp: 2643.2 },
              { tamkp: 0 },
              { stamkp: 0 },
              { attdisc: 0 },
              { tadisc: 0 },
              { stadisc: 0 },
            ],
            scur: "QAR",
            ucur: "INR",
            exchange_rate: 22.4,
            sup_fare: "1180_1620",
            markupnames: [{ code: "MTA", name: "LaxmiAprilMarkup" }],
          },
          paxpr: [
            {
              tf: 38931.2,
              tax: [
                { YR: 1120 },
                { YR: 672 },
                { OT: 8064 },
                { attmkp: 2643.2 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              bf: 29075.2,
              fbc: "U6MDLDH",
              fareType: "RP",
              pusup_fare: "1180_1620",
              psup_fare: "26432_36288",
              ptype: "ADT",
            },
          ],
          sup: "0",
          airlinesList: "AI",
          servicefee: [
            {
              type: "ATT",
              adt: 2907.52,
              chd: 0,
              inf: 0,
              servicefee_name: "LaxmiServieApril",
              apl_on: "Per Ticket",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 2907.52,
              sup_fee: 0,
            },
            {
              type: "TA",
              adt: 0,
              chd: 0,
              inf: 0,
              apl_on: "",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 0,
              sup_fee: 2907.52,
            },
          ],
        },
        {
          srcorigns: [
            {
              tid: "tnrKXPhA72oWq8bR_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "15:55",
              re: "Non-Refundable",
              stop: 2,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "BLR",
                  dd: "2023-06-07T08:00:00",
                  ad: "2023-06-07T09:15:00",
                  rbd: "U",
                  fn: "515",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "01:15",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
                {
                  ref: 2,
                  da: "BLR",
                  ar: "DEL",
                  dd: "2023-06-07T13:20:00",
                  ad: "2023-06-07T15:40:00",
                  rbd: "Q",
                  fn: "501",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:20",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 3,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-06-07T19:50:00",
                  ad: "2023-06-07T21:25:00",
                  rbd: "U",
                  fn: "971",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:05",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "515/501/971",
              bgd: [{ qun: "30", wt: "kg" }],
            },
            {
              tid: "P3798tnGHOmBIztd_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "15:55",
              re: "Non-Refundable",
              stop: 2,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "BLR",
                  dd: "2023-06-07T08:00:00",
                  ad: "2023-06-07T09:15:00",
                  rbd: "U",
                  fn: "515",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "01:15",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
                {
                  ref: 2,
                  da: "BLR",
                  ar: "DEL",
                  dd: "2023-06-07T10:00:00",
                  ad: "2023-06-07T12:50:00",
                  rbd: "Q",
                  fn: "505",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:50",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 3,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-06-07T19:50:00",
                  ad: "2023-06-07T21:25:00",
                  rbd: "U",
                  fn: "971",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:05",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "515/505/971",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          destorigins: [
            {
              tid: "tnrKXPhA72oWq8bR_2",
              ref: 2,
              office_id: "DOHQR28IH",
              sdur: "11:20",
              re: "Non-Refundable",
              stop: 2,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T22:35:00",
                  ad: "2023-06-10T05:05:00",
                  rbd: "U",
                  fn: "972",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:00",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "BOM",
                  dd: "2023-06-10T08:00:00",
                  ad: "2023-06-10T10:10:00",
                  rbd: "U",
                  fn: "665",
                  trn: "3",
                  eq: "321",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:10",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "2" },
                },
                {
                  ref: 3,
                  da: "BOM",
                  ar: "HYD",
                  dd: "2023-06-10T11:00:00",
                  ad: "2023-06-10T12:25:00",
                  rbd: "U",
                  fn: "617",
                  trn: "2",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "1",
                  oa: "AI",
                  fa: [],
                  fdur: "01:25",
                  cl: "Economy",
                  terminal: { dep: "2", arr: "" },
                },
              ],
              flightNumbers: "972/665/617",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          pr: {
            bf: 29568,
            tf: 39872,
            tax: [
              { YR: 1344 },
              { YR: 896 },
              { OT: 8064 },
              { dealmkp: 0 },
              { attmkp: 2688 },
              { tamkp: 0 },
              { stamkp: 0 },
              { attdisc: 0 },
              { tadisc: 0 },
              { stadisc: 0 },
            ],
            scur: "QAR",
            ucur: "INR",
            exchange_rate: 22.4,
            sup_fare: "1200_1660",
            markupnames: [{ code: "MTA", name: "LaxmiAprilMarkup" }],
          },
          paxpr: [
            {
              tf: 39872,
              tax: [
                { YR: 1344 },
                { YR: 896 },
                { OT: 8064 },
                { attmkp: 2688 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              bf: 29568,
              fbc: "UIP",
              fareType: "RP",
              pusup_fare: "1200_1660",
              psup_fare: "26880_37184",
              ptype: "ADT",
            },
          ],
          sup: "0",
          airlinesList: "AI",
          servicefee: [
            {
              type: "ATT",
              adt: 2956.8,
              chd: 0,
              inf: 0,
              servicefee_name: "LaxmiServieApril",
              apl_on: "Per Ticket",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 2956.8,
              sup_fee: 0,
            },
            {
              type: "TA",
              adt: 0,
              chd: 0,
              inf: 0,
              apl_on: "",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 0,
              sup_fee: 2956.8,
            },
          ],
        },
        {
          srcorigns: [
            {
              tid: "9D7NmG9FftVe1uqw_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "15:55",
              re: "Non-Refundable",
              stop: 2,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "BLR",
                  dd: "2023-06-07T08:00:00",
                  ad: "2023-06-07T09:15:00",
                  rbd: "U",
                  fn: "515",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "01:15",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
                {
                  ref: 2,
                  da: "BLR",
                  ar: "DEL",
                  dd: "2023-06-07T11:15:00",
                  ad: "2023-06-07T14:20:00",
                  rbd: "Q",
                  fn: "507",
                  eq: "321",
                  ac: "AI",
                  ma: "AI",
                  seat: "1",
                  oa: "AI",
                  fa: [],
                  fdur: "03:05",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 3,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-06-07T19:50:00",
                  ad: "2023-06-07T21:25:00",
                  rbd: "U",
                  fn: "971",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:05",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "515/507/971",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          destorigins: [
            {
              tid: "9D7NmG9FftVe1uqw_2",
              ref: 2,
              office_id: "DOHQR28IH",
              sdur: "11:20",
              re: "Non-Refundable",
              stop: 2,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T22:35:00",
                  ad: "2023-06-10T05:05:00",
                  rbd: "U",
                  fn: "972",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:00",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "BOM",
                  dd: "2023-06-10T08:00:00",
                  ad: "2023-06-10T10:10:00",
                  rbd: "U",
                  fn: "665",
                  trn: "3",
                  eq: "321",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:10",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "2" },
                },
                {
                  ref: 3,
                  da: "BOM",
                  ar: "HYD",
                  dd: "2023-06-10T11:00:00",
                  ad: "2023-06-10T12:25:00",
                  rbd: "U",
                  fn: "617",
                  trn: "2",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "1",
                  oa: "AI",
                  fa: [],
                  fdur: "01:25",
                  cl: "Economy",
                  terminal: { dep: "2", arr: "" },
                },
              ],
              flightNumbers: "972/665/617",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          pr: {
            bf: 29568,
            tf: 39872,
            tax: [
              { YR: 1344 },
              { YR: 896 },
              { OT: 8064 },
              { dealmkp: 0 },
              { attmkp: 2688 },
              { tamkp: 0 },
              { stamkp: 0 },
              { attdisc: 0 },
              { tadisc: 0 },
              { stadisc: 0 },
            ],
            scur: "QAR",
            ucur: "INR",
            exchange_rate: 22.4,
            sup_fare: "1200_1660",
            markupnames: [{ code: "MTA", name: "LaxmiAprilMarkup" }],
          },
          paxpr: [
            {
              tf: 39872,
              tax: [
                { YR: 1344 },
                { YR: 896 },
                { OT: 8064 },
                { attmkp: 2688 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              bf: 29568,
              fbc: "UIP",
              fareType: "RP",
              pusup_fare: "1200_1660",
              psup_fare: "26880_37184",
              ptype: "ADT",
            },
          ],
          sup: "0",
          airlinesList: "AI",
          servicefee: [
            {
              type: "ATT",
              adt: 2956.8,
              chd: 0,
              inf: 0,
              servicefee_name: "LaxmiServieApril",
              apl_on: "Per Ticket",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 2956.8,
              sup_fee: 0,
            },
            {
              type: "TA",
              adt: 0,
              chd: 0,
              inf: 0,
              apl_on: "",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 0,
              sup_fee: 2956.8,
            },
          ],
        },
        {
          srcorigns: [
            {
              tid: "fsTNf7B5pEDq6x4A_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "15:55",
              re: "Non-Refundable",
              stop: 2,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "BLR",
                  dd: "2023-06-07T08:00:00",
                  ad: "2023-06-07T09:15:00",
                  rbd: "U",
                  fn: "515",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "01:15",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
                {
                  ref: 2,
                  da: "BLR",
                  ar: "DEL",
                  dd: "2023-06-07T13:20:00",
                  ad: "2023-06-07T15:40:00",
                  rbd: "Q",
                  fn: "501",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:20",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 3,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-06-07T19:50:00",
                  ad: "2023-06-07T21:25:00",
                  rbd: "U",
                  fn: "971",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:05",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "515/501/971",
              bgd: [{ qun: "30", wt: "kg" }],
            },
            {
              tid: "xN00a0Ghkrpl2fQO_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "15:55",
              re: "Non-Refundable",
              stop: 2,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "BLR",
                  dd: "2023-06-07T08:00:00",
                  ad: "2023-06-07T09:15:00",
                  rbd: "U",
                  fn: "515",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "01:15",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
                {
                  ref: 2,
                  da: "BLR",
                  ar: "DEL",
                  dd: "2023-06-07T10:00:00",
                  ad: "2023-06-07T12:50:00",
                  rbd: "Q",
                  fn: "505",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:50",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 3,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-06-07T19:50:00",
                  ad: "2023-06-07T21:25:00",
                  rbd: "U",
                  fn: "971",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:05",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "515/505/971",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          destorigins: [
            {
              tid: "fsTNf7B5pEDq6x4A_2",
              ref: 2,
              office_id: "DOHQR28IH",
              sdur: "20:35",
              re: "Non-Refundable",
              stop: 2,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T22:35:00",
                  ad: "2023-06-10T05:05:00",
                  rbd: "U",
                  fn: "972",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:00",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "BOM",
                  dd: "2023-06-10T15:05:00",
                  ad: "2023-06-10T18:10:00",
                  rbd: "U",
                  fn: "636",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "5",
                  oa: "AI",
                  fa: [],
                  fdur: "03:05",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "2" },
                },
                {
                  ref: 3,
                  da: "BOM",
                  ar: "HYD",
                  dd: "2023-06-10T20:10:00",
                  ad: "2023-06-10T21:40:00",
                  rbd: "U",
                  fn: "619",
                  trn: "2",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "01:30",
                  cl: "Economy",
                  terminal: { dep: "2", arr: "" },
                },
              ],
              flightNumbers: "972/636/619",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          pr: {
            bf: 29568,
            tf: 39872,
            tax: [
              { YR: 1344 },
              { YR: 896 },
              { OT: 8064 },
              { dealmkp: 0 },
              { attmkp: 2688 },
              { tamkp: 0 },
              { stamkp: 0 },
              { attdisc: 0 },
              { tadisc: 0 },
              { stadisc: 0 },
            ],
            scur: "QAR",
            ucur: "INR",
            exchange_rate: 22.4,
            sup_fare: "1200_1660",
            markupnames: [{ code: "MTA", name: "LaxmiAprilMarkup" }],
          },
          paxpr: [
            {
              tf: 39872,
              tax: [
                { YR: 1344 },
                { YR: 896 },
                { OT: 8064 },
                { attmkp: 2688 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              bf: 29568,
              fbc: "UIP",
              fareType: "RP",
              pusup_fare: "1200_1660",
              psup_fare: "26880_37184",
              ptype: "ADT",
            },
          ],
          sup: "0",
          airlinesList: "AI",
          servicefee: [
            {
              type: "ATT",
              adt: 2956.8,
              chd: 0,
              inf: 0,
              servicefee_name: "LaxmiServieApril",
              apl_on: "Per Ticket",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 2956.8,
              sup_fee: 0,
            },
            {
              type: "TA",
              adt: 0,
              chd: 0,
              inf: 0,
              apl_on: "",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 0,
              sup_fee: 2956.8,
            },
          ],
        },
        {
          srcorigns: [
            {
              tid: "gexv1XxBBGn49az1_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "15:55",
              re: "Non-Refundable",
              stop: 2,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "BLR",
                  dd: "2023-06-07T08:00:00",
                  ad: "2023-06-07T09:15:00",
                  rbd: "U",
                  fn: "515",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "01:15",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
                {
                  ref: 2,
                  da: "BLR",
                  ar: "DEL",
                  dd: "2023-06-07T11:15:00",
                  ad: "2023-06-07T14:20:00",
                  rbd: "Q",
                  fn: "507",
                  eq: "321",
                  ac: "AI",
                  ma: "AI",
                  seat: "1",
                  oa: "AI",
                  fa: [],
                  fdur: "03:05",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 3,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-06-07T19:50:00",
                  ad: "2023-06-07T21:25:00",
                  rbd: "U",
                  fn: "971",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:05",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "515/507/971",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          destorigins: [
            {
              tid: "gexv1XxBBGn49az1_2",
              ref: 2,
              office_id: "DOHQR28IH",
              sdur: "20:35",
              re: "Non-Refundable",
              stop: 2,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T22:35:00",
                  ad: "2023-06-10T05:05:00",
                  rbd: "U",
                  fn: "972",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:00",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "BOM",
                  dd: "2023-06-10T15:05:00",
                  ad: "2023-06-10T18:10:00",
                  rbd: "U",
                  fn: "636",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "5",
                  oa: "AI",
                  fa: [],
                  fdur: "03:05",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "2" },
                },
                {
                  ref: 3,
                  da: "BOM",
                  ar: "HYD",
                  dd: "2023-06-10T20:10:00",
                  ad: "2023-06-10T21:40:00",
                  rbd: "U",
                  fn: "619",
                  trn: "2",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "01:30",
                  cl: "Economy",
                  terminal: { dep: "2", arr: "" },
                },
              ],
              flightNumbers: "972/636/619",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          pr: {
            bf: 29568,
            tf: 39872,
            tax: [
              { YR: 1344 },
              { YR: 896 },
              { OT: 8064 },
              { dealmkp: 0 },
              { attmkp: 2688 },
              { tamkp: 0 },
              { stamkp: 0 },
              { attdisc: 0 },
              { tadisc: 0 },
              { stadisc: 0 },
            ],
            scur: "QAR",
            ucur: "INR",
            exchange_rate: 22.4,
            sup_fare: "1200_1660",
            markupnames: [{ code: "MTA", name: "LaxmiAprilMarkup" }],
          },
          paxpr: [
            {
              tf: 39872,
              tax: [
                { YR: 1344 },
                { YR: 896 },
                { OT: 8064 },
                { attmkp: 2688 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              bf: 29568,
              fbc: "UIP",
              fareType: "RP",
              pusup_fare: "1200_1660",
              psup_fare: "26880_37184",
              ptype: "ADT",
            },
          ],
          sup: "0",
          airlinesList: "AI",
          servicefee: [
            {
              type: "ATT",
              adt: 2956.8,
              chd: 0,
              inf: 0,
              servicefee_name: "LaxmiServieApril",
              apl_on: "Per Ticket",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 2956.8,
              sup_fee: 0,
            },
            {
              type: "TA",
              adt: 0,
              chd: 0,
              inf: 0,
              apl_on: "",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 0,
              sup_fee: 2956.8,
            },
          ],
        },
        {
          srcorigns: [
            {
              tid: "e7BhuO68C74mPOTs_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "15:55",
              re: "Non-Refundable",
              stop: 2,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "BLR",
                  dd: "2023-06-07T08:00:00",
                  ad: "2023-06-07T09:15:00",
                  rbd: "U",
                  fn: "515",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "01:15",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
                {
                  ref: 2,
                  da: "BLR",
                  ar: "DEL",
                  dd: "2023-06-07T13:20:00",
                  ad: "2023-06-07T15:40:00",
                  rbd: "Q",
                  fn: "501",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:20",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 3,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-06-07T19:50:00",
                  ad: "2023-06-07T21:25:00",
                  rbd: "U",
                  fn: "971",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:05",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "515/501/971",
              bgd: [{ qun: "30", wt: "kg" }],
            },
            {
              tid: "WbWnroDVtEbpbp15_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "15:55",
              re: "Non-Refundable",
              stop: 2,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "BLR",
                  dd: "2023-06-07T08:00:00",
                  ad: "2023-06-07T09:15:00",
                  rbd: "U",
                  fn: "515",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "01:15",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
                {
                  ref: 2,
                  da: "BLR",
                  ar: "DEL",
                  dd: "2023-06-07T10:00:00",
                  ad: "2023-06-07T12:50:00",
                  rbd: "Q",
                  fn: "505",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:50",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 3,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-06-07T19:50:00",
                  ad: "2023-06-07T21:25:00",
                  rbd: "U",
                  fn: "971",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:05",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "515/505/971",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          destorigins: [
            {
              tid: "e7BhuO68C74mPOTs_2",
              ref: 2,
              office_id: "DOHQR28IH",
              sdur: "20:35",
              re: "Non-Refundable",
              stop: 2,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T22:35:00",
                  ad: "2023-06-10T05:05:00",
                  rbd: "U",
                  fn: "972",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:00",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "BOM",
                  dd: "2023-06-10T14:00:00",
                  ad: "2023-06-10T16:15:00",
                  rbd: "U",
                  fn: "863",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:15",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "2" },
                },
                {
                  ref: 3,
                  da: "BOM",
                  ar: "HYD",
                  dd: "2023-06-10T20:10:00",
                  ad: "2023-06-10T21:40:00",
                  rbd: "U",
                  fn: "619",
                  trn: "2",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "01:30",
                  cl: "Economy",
                  terminal: { dep: "2", arr: "" },
                },
              ],
              flightNumbers: "972/863/619",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          pr: {
            bf: 29568,
            tf: 39872,
            tax: [
              { YR: 1344 },
              { YR: 896 },
              { OT: 8064 },
              { dealmkp: 0 },
              { attmkp: 2688 },
              { tamkp: 0 },
              { stamkp: 0 },
              { attdisc: 0 },
              { tadisc: 0 },
              { stadisc: 0 },
            ],
            scur: "QAR",
            ucur: "INR",
            exchange_rate: 22.4,
            sup_fare: "1200_1660",
            markupnames: [{ code: "MTA", name: "LaxmiAprilMarkup" }],
          },
          paxpr: [
            {
              tf: 39872,
              tax: [
                { YR: 1344 },
                { YR: 896 },
                { OT: 8064 },
                { attmkp: 2688 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              bf: 29568,
              fbc: "UIP",
              fareType: "RP",
              pusup_fare: "1200_1660",
              psup_fare: "26880_37184",
              ptype: "ADT",
            },
          ],
          sup: "0",
          airlinesList: "AI",
          servicefee: [
            {
              type: "ATT",
              adt: 2956.8,
              chd: 0,
              inf: 0,
              servicefee_name: "LaxmiServieApril",
              apl_on: "Per Ticket",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 2956.8,
              sup_fee: 0,
            },
            {
              type: "TA",
              adt: 0,
              chd: 0,
              inf: 0,
              apl_on: "",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 0,
              sup_fee: 2956.8,
            },
          ],
        },
        {
          srcorigns: [
            {
              tid: "TcX7vHUFBwkGzD89_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "15:55",
              re: "Non-Refundable",
              stop: 2,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "BLR",
                  dd: "2023-06-07T08:00:00",
                  ad: "2023-06-07T09:15:00",
                  rbd: "U",
                  fn: "515",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "01:15",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
                {
                  ref: 2,
                  da: "BLR",
                  ar: "DEL",
                  dd: "2023-06-07T11:15:00",
                  ad: "2023-06-07T14:20:00",
                  rbd: "Q",
                  fn: "507",
                  eq: "321",
                  ac: "AI",
                  ma: "AI",
                  seat: "1",
                  oa: "AI",
                  fa: [],
                  fdur: "03:05",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 3,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-06-07T19:50:00",
                  ad: "2023-06-07T21:25:00",
                  rbd: "U",
                  fn: "971",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:05",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "515/507/971",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          destorigins: [
            {
              tid: "TcX7vHUFBwkGzD89_2",
              ref: 2,
              office_id: "DOHQR28IH",
              sdur: "20:35",
              re: "Non-Refundable",
              stop: 2,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T22:35:00",
                  ad: "2023-06-10T05:05:00",
                  rbd: "U",
                  fn: "972",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:00",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "BOM",
                  dd: "2023-06-10T14:00:00",
                  ad: "2023-06-10T16:15:00",
                  rbd: "U",
                  fn: "863",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:15",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "2" },
                },
                {
                  ref: 3,
                  da: "BOM",
                  ar: "HYD",
                  dd: "2023-06-10T20:10:00",
                  ad: "2023-06-10T21:40:00",
                  rbd: "U",
                  fn: "619",
                  trn: "2",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "01:30",
                  cl: "Economy",
                  terminal: { dep: "2", arr: "" },
                },
              ],
              flightNumbers: "972/863/619",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          pr: {
            bf: 29568,
            tf: 39872,
            tax: [
              { YR: 1344 },
              { YR: 896 },
              { OT: 8064 },
              { dealmkp: 0 },
              { attmkp: 2688 },
              { tamkp: 0 },
              { stamkp: 0 },
              { attdisc: 0 },
              { tadisc: 0 },
              { stadisc: 0 },
            ],
            scur: "QAR",
            ucur: "INR",
            exchange_rate: 22.4,
            sup_fare: "1200_1660",
            markupnames: [{ code: "MTA", name: "LaxmiAprilMarkup" }],
          },
          paxpr: [
            {
              tf: 39872,
              tax: [
                { YR: 1344 },
                { YR: 896 },
                { OT: 8064 },
                { attmkp: 2688 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              bf: 29568,
              fbc: "UIP",
              fareType: "RP",
              pusup_fare: "1200_1660",
              psup_fare: "26880_37184",
              ptype: "ADT",
            },
          ],
          sup: "0",
          airlinesList: "AI",
          servicefee: [
            {
              type: "ATT",
              adt: 2956.8,
              chd: 0,
              inf: 0,
              servicefee_name: "LaxmiServieApril",
              apl_on: "Per Ticket",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 2956.8,
              sup_fee: 0,
            },
            {
              type: "TA",
              adt: 0,
              chd: 0,
              inf: 0,
              apl_on: "",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 0,
              sup_fee: 2956.8,
            },
          ],
        },
        {
          srcorigns: [
            {
              tid: "p9vsxIPTzNfoHRBn_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "25:30",
              re: "Non-Refundable",
              stop: 2,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "BOM",
                  dd: "2023-06-07T22:25:00",
                  ad: "2023-06-07T23:50:00",
                  rbd: "L",
                  fn: "698",
                  eq: "319",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "01:25",
                  cl: "Economy",
                  terminal: { dep: "", arr: "2" },
                },
                {
                  ref: 2,
                  da: "BOM",
                  ar: "DEL",
                  dd: "2023-06-08T13:00:00",
                  ad: "2023-06-08T15:15:00",
                  rbd: "L",
                  fn: "677",
                  trn: "2",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:15",
                  cl: "Economy",
                  terminal: { dep: "2", arr: "3" },
                },
                {
                  ref: 3,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-06-08T19:50:00",
                  ad: "2023-06-08T21:25:00",
                  rbd: "U",
                  fn: "971",
                  trn: "3",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "5",
                  oa: "AI",
                  fa: [],
                  fdur: "04:05",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "698/677/971",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          destorigins: [
            {
              tid: "p9vsxIPTzNfoHRBn_2",
              ref: 2,
              office_id: "DOHQR28IH",
              sdur: "10:45",
              re: "Non-Refundable",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "AI",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T22:35:00",
                  ad: "2023-06-10T05:05:00",
                  rbd: "U",
                  fn: "972",
                  eq: "32N",
                  ac: "AI",
                  ma: "AI",
                  seat: "2",
                  oa: "AI",
                  fa: [],
                  fdur: "04:00",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-06-10T09:45:00",
                  ad: "2023-06-10T11:50:00",
                  rbd: "Q",
                  fn: "542",
                  trn: "3",
                  eq: "321",
                  ac: "AI",
                  ma: "AI",
                  seat: "9",
                  oa: "AI",
                  fa: [],
                  fdur: "02:05",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "972/542",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          pr: {
            bf: 30060.8,
            tf: 39916.8,
            tax: [
              { YR: 1120 },
              { YR: 672 },
              { OT: 8064 },
              { dealmkp: 0 },
              { attmkp: 2732.8 },
              { tamkp: 0 },
              { stamkp: 0 },
              { attdisc: 0 },
              { tadisc: 0 },
              { stadisc: 0 },
            ],
            scur: "QAR",
            ucur: "INR",
            exchange_rate: 22.4,
            sup_fare: "1220_1660",
            markupnames: [{ code: "MTA", name: "LaxmiAprilMarkup" }],
          },
          paxpr: [
            {
              tf: 39916.8,
              tax: [
                { YR: 1120 },
                { YR: 672 },
                { OT: 8064 },
                { attmkp: 2732.8 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              bf: 30060.8,
              fbc: "LIP",
              fareType: "RP",
              pusup_fare: "1220_1660",
              psup_fare: "27328_37184",
              ptype: "ADT",
            },
          ],
          sup: "0",
          airlinesList: "AI",
          servicefee: [
            {
              type: "ATT",
              adt: 3006.08,
              chd: 0,
              inf: 0,
              servicefee_name: "LaxmiServieApril",
              apl_on: "Per Ticket",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 3006.08,
              sup_fee: 0,
            },
            {
              type: "TA",
              adt: 0,
              chd: 0,
              inf: 0,
              apl_on: "",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 0,
              sup_fee: 3006.08,
            },
          ],
        },
        {
          srcorigns: [
            {
              tid: "CvxdtRAd4o1FR1dX_1",
              ref: 1,
              office_id: "",
              sdur: "12:0",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DEL",
                  dd: "2023-06-07T11:45:00",
                  ad: "2023-06-07T14:05:00",
                  rbd: "",
                  fn: "2171",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "2" },
                  rph: "",
                  jsell:
                    "6E~2171~ ~~HYD~06/07/2023 11:45~DEL~06/07/2023 14:05~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-06-07T22:10:00",
                  ad: "2023-06-07T23:45:00",
                  rbd: "",
                  fn: "1311",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "3" },
                  rph: "",
                  jsell:
                    "6E~1311~ ~~DEL~06/07/2023 22:10~DOH~06/07/2023 23:45~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 30621,
                      fbc: "WCINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "30621_36271",
                      tax: [
                        { OT: 5650 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 36271,
                      psup_fare: "30621_36271",
                    },
                  ],
                  pr: {
                    bf: 30621,
                    tf: 36271,
                    tax: [
                      { OT: 5650 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "30621_36271",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 36271,
                  },
                  routeId: "30wp3zl9au",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 31321,
                      fbc: "WCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "31321_37031",
                      tax: [
                        { OT: 5710 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 37031,
                      psup_fare: "31321_37031",
                    },
                  ],
                  pr: {
                    bf: 31321,
                    tf: 37031,
                    tax: [
                      { OT: 5710 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "31321_37031",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 37031,
                  },
                  routeId: "npm5huw8bc",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "G79e0AHvUTs=|pfN7IeMVY9crZtLzdrvIzm0oZntQ6f3741aeYn1CvdMLxX16D05jLOkRUUokspRUNBaad/QhuW2NmBov2F0uAOs5rQ6LmyiTG2kKghIPh0qz95QB/MyFDxPn3z3uu1Sj7jxXZQa3gsE=",
              stop: 1,
            },
            {
              tid: "kdqnHNdZNAilAnDU_1",
              ref: 1,
              office_id: "",
              sdur: "5:15",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "BOM",
                  dd: "2023-06-07T13:30:00",
                  ad: "2023-06-07T15:05:00",
                  rbd: "",
                  fn: "6699",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "2" },
                  rph: "",
                  jsell:
                    "6E~6699~ ~~HYD~06/07/2023 13:30~BOM~06/07/2023 15:05~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
                {
                  ref: 2,
                  da: "BOM",
                  ar: "DOH",
                  dd: "2023-06-07T17:50:00",
                  ad: "2023-06-07T18:45:00",
                  rbd: "",
                  fn: "1305",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "2" },
                  rph: "",
                  jsell:
                    "6E~1305~ ~~BOM~06/07/2023 17:50~DOH~06/07/2023 18:45~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 30621,
                      fbc: "WCINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "30621_36271",
                      tax: [
                        { OT: 5650 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 36271,
                      psup_fare: "30621_36271",
                    },
                  ],
                  pr: {
                    bf: 30621,
                    tf: 36271,
                    tax: [
                      { OT: 5650 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "30621_36271",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 36271,
                  },
                  routeId: "ufbh0pozdm",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 31321,
                      fbc: "WCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "31321_37031",
                      tax: [
                        { OT: 5710 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 37031,
                      psup_fare: "31321_37031",
                    },
                  ],
                  pr: {
                    bf: 31321,
                    tf: 37031,
                    tax: [
                      { OT: 5710 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "31321_37031",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 37031,
                  },
                  routeId: "dsz2bxobkj",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "G79e0AHvUTs=|pfN7IeMVY9crZtLzdrvIzm0oZntQ6f3741aeYn1CvdMLxX16D05jLOkRUUokspRUNBaad/QhuW2NmBov2F0uAOs5rQ6LmyiTG2kKghIPh0qz95QB/MyFDxPn3z3uu1Sj7jxXZQa3gsE=",
              stop: 1,
            },
            {
              tid: "QqgKnTQDAU0Mzkm6_1",
              ref: 1,
              office_id: "",
              sdur: "15:15",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DEL",
                  dd: "2023-06-07T14:25:00",
                  ad: "2023-06-07T16:45:00",
                  rbd: "",
                  fn: "2063",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "2" },
                  rph: "",
                  jsell:
                    "6E~2063~ ~~HYD~06/07/2023 14:25~DEL~06/07/2023 16:45~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-06-08T04:05:00",
                  ad: "2023-06-08T05:40:00",
                  rbd: "",
                  fn: "1307",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "3" },
                  rph: "",
                  jsell:
                    "6E~1307~ ~~DEL~06/08/2023 04:05~DOH~06/08/2023 05:40~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 30621,
                      fbc: "WCINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "30621_36271",
                      tax: [
                        { OT: 5650 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 36271,
                      psup_fare: "30621_36271",
                    },
                  ],
                  pr: {
                    bf: 30621,
                    tf: 36271,
                    tax: [
                      { OT: 5650 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "30621_36271",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 36271,
                  },
                  routeId: "y3wvo2pjcj",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 31321,
                      fbc: "WCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "31321_37031",
                      tax: [
                        { OT: 5710 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 37031,
                      psup_fare: "31321_37031",
                    },
                  ],
                  pr: {
                    bf: 31321,
                    tf: 37031,
                    tax: [
                      { OT: 5710 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "31321_37031",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 37031,
                  },
                  routeId: "c8umvb2jge",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "G79e0AHvUTs=|pfN7IeMVY9crZtLzdrvIzm0oZntQ6f3741aeYn1CvdMLxX16D05jLOkRUUokspRUNBaad/QhuW2NmBov2F0uAOs5rQ6LmyiTG2kKghIPh0qz95QB/MyFDxPn3z3uu1Sj7jxXZQa3gsE=",
              stop: 1,
            },
            {
              tid: "imxBRerKi7TTTTtO_1",
              ref: 1,
              office_id: "",
              sdur: "9:20",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DEL",
                  dd: "2023-06-07T14:25:00",
                  ad: "2023-06-07T16:45:00",
                  rbd: "",
                  fn: "2063",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "2" },
                  rph: "",
                  jsell:
                    "6E~2063~ ~~HYD~06/07/2023 14:25~DEL~06/07/2023 16:45~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-06-07T22:10:00",
                  ad: "2023-06-07T23:45:00",
                  rbd: "",
                  fn: "1311",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "3" },
                  rph: "",
                  jsell:
                    "6E~1311~ ~~DEL~06/07/2023 22:10~DOH~06/07/2023 23:45~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 30621,
                      fbc: "WCINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "30621_36271",
                      tax: [
                        { OT: 5650 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 36271,
                      psup_fare: "30621_36271",
                    },
                  ],
                  pr: {
                    bf: 30621,
                    tf: 36271,
                    tax: [
                      { OT: 5650 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "30621_36271",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 36271,
                  },
                  routeId: "7bki6fw2b8",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 31321,
                      fbc: "WCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "31321_37031",
                      tax: [
                        { OT: 5710 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 37031,
                      psup_fare: "31321_37031",
                    },
                  ],
                  pr: {
                    bf: 31321,
                    tf: 37031,
                    tax: [
                      { OT: 5710 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "31321_37031",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 37031,
                  },
                  routeId: "vv9xd2zv01",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "G79e0AHvUTs=|pfN7IeMVY9crZtLzdrvIzm0oZntQ6f3741aeYn1CvdMLxX16D05jLOkRUUokspRUNBaad/QhuW2NmBov2F0uAOs5rQ6LmyiTG2kKghIPh0qz95QB/MyFDxPn3z3uu1Sj7jxXZQa3gsE=",
              stop: 1,
            },
            {
              tid: "MZDvw6OR8xMbuxRI_1",
              ref: 1,
              office_id: "",
              sdur: "7:15",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DEL",
                  dd: "2023-06-07T16:30:00",
                  ad: "2023-06-07T18:50:00",
                  rbd: "",
                  fn: "6606",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "1" },
                  rph: "",
                  jsell:
                    "6E~6606~ ~~HYD~06/07/2023 16:30~DEL~06/07/2023 18:50~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-06-07T22:10:00",
                  ad: "2023-06-07T23:45:00",
                  rbd: "",
                  fn: "1311",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "3" },
                  rph: "",
                  jsell:
                    "6E~1311~ ~~DEL~06/07/2023 22:10~DOH~06/07/2023 23:45~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 30621,
                      fbc: "WCINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "30621_36271",
                      tax: [
                        { OT: 5650 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 36271,
                      psup_fare: "30621_36271",
                    },
                  ],
                  pr: {
                    bf: 30621,
                    tf: 36271,
                    tax: [
                      { OT: 5650 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "30621_36271",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 36271,
                  },
                  routeId: "o7ivp9n7ss",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 31321,
                      fbc: "WCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "31321_37031",
                      tax: [
                        { OT: 5710 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 37031,
                      psup_fare: "31321_37031",
                    },
                  ],
                  pr: {
                    bf: 31321,
                    tf: 37031,
                    tax: [
                      { OT: 5710 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "31321_37031",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 37031,
                  },
                  routeId: "3u8045ri6c",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "G79e0AHvUTs=|pfN7IeMVY9crZtLzdrvIzm0oZntQ6f3741aeYn1CvdMLxX16D05jLOkRUUokspRUNBaad/QhuW2NmBov2F0uAOs5rQ6LmyiTG2kKghIPh0qz95QB/MyFDxPn3z3uu1Sj7jxXZQa3gsE=",
              stop: 1,
            },
            {
              tid: "kCaa50kQfh1uZve4_1",
              ref: 1,
              office_id: "",
              sdur: "13:10",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DEL",
                  dd: "2023-06-07T16:30:00",
                  ad: "2023-06-07T18:50:00",
                  rbd: "",
                  fn: "6606",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "1" },
                  rph: "",
                  jsell:
                    "6E~6606~ ~~HYD~06/07/2023 16:30~DEL~06/07/2023 18:50~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-06-08T04:05:00",
                  ad: "2023-06-08T05:40:00",
                  rbd: "",
                  fn: "1307",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "3" },
                  rph: "",
                  jsell:
                    "6E~1307~ ~~DEL~06/08/2023 04:05~DOH~06/08/2023 05:40~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 30621,
                      fbc: "WCINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "30621_36271",
                      tax: [
                        { OT: 5650 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 36271,
                      psup_fare: "30621_36271",
                    },
                  ],
                  pr: {
                    bf: 30621,
                    tf: 36271,
                    tax: [
                      { OT: 5650 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "30621_36271",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 36271,
                  },
                  routeId: "f4wnkj6q3r",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 31321,
                      fbc: "WCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "31321_37031",
                      tax: [
                        { OT: 5710 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 37031,
                      psup_fare: "31321_37031",
                    },
                  ],
                  pr: {
                    bf: 31321,
                    tf: 37031,
                    tax: [
                      { OT: 5710 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "31321_37031",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 37031,
                  },
                  routeId: "lz0egg7p01",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "G79e0AHvUTs=|pfN7IeMVY9crZtLzdrvIzm0oZntQ6f3741aeYn1CvdMLxX16D05jLOkRUUokspRUNBaad/QhuW2NmBov2F0uAOs5rQ6LmyiTG2kKghIPh0qz95QB/MyFDxPn3z3uu1Sj7jxXZQa3gsE=",
              stop: 1,
            },
            {
              tid: "N7bdfq98aUNHXHWH_1",
              ref: 1,
              office_id: "",
              sdur: "11:25",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DEL",
                  dd: "2023-06-07T18:15:00",
                  ad: "2023-06-07T20:30:00",
                  rbd: "",
                  fn: "2003",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "2" },
                  rph: "",
                  jsell:
                    "6E~2003~ ~~HYD~06/07/2023 18:15~DEL~06/07/2023 20:30~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-06-08T04:05:00",
                  ad: "2023-06-08T05:40:00",
                  rbd: "",
                  fn: "1307",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "3" },
                  rph: "",
                  jsell:
                    "6E~1307~ ~~DEL~06/08/2023 04:05~DOH~06/08/2023 05:40~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 30621,
                      fbc: "WCINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "30621_36271",
                      tax: [
                        { OT: 5650 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 36271,
                      psup_fare: "30621_36271",
                    },
                  ],
                  pr: {
                    bf: 30621,
                    tf: 36271,
                    tax: [
                      { OT: 5650 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "30621_36271",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 36271,
                  },
                  routeId: "5t6toxlx0s",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 31321,
                      fbc: "WCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "31321_37031",
                      tax: [
                        { OT: 5710 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 37031,
                      psup_fare: "31321_37031",
                    },
                  ],
                  pr: {
                    bf: 31321,
                    tf: 37031,
                    tax: [
                      { OT: 5710 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "31321_37031",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 37031,
                  },
                  routeId: "0j2nwjf37r",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "G79e0AHvUTs=|pfN7IeMVY9crZtLzdrvIzm0oZntQ6f3741aeYn1CvdMLxX16D05jLOkRUUokspRUNBaad/QhuW2NmBov2F0uAOs5rQ6LmyiTG2kKghIPh0qz95QB/MyFDxPn3z3uu1Sj7jxXZQa3gsE=",
              stop: 1,
            },
            {
              tid: "5AH8E7JS6LxXJepx_1",
              ref: 1,
              office_id: "",
              sdur: "5:30",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DEL",
                  dd: "2023-06-07T18:15:00",
                  ad: "2023-06-07T20:30:00",
                  rbd: "",
                  fn: "2003",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "2" },
                  rph: "",
                  jsell:
                    "6E~2003~ ~~HYD~06/07/2023 18:15~DEL~06/07/2023 20:30~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-06-07T22:10:00",
                  ad: "2023-06-07T23:45:00",
                  rbd: "",
                  fn: "1311",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "3" },
                  rph: "",
                  jsell:
                    "6E~1311~ ~~DEL~06/07/2023 22:10~DOH~06/07/2023 23:45~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 30621,
                      fbc: "WCINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "30621_36271",
                      tax: [
                        { OT: 5650 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 36271,
                      psup_fare: "30621_36271",
                    },
                  ],
                  pr: {
                    bf: 30621,
                    tf: 36271,
                    tax: [
                      { OT: 5650 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "30621_36271",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 36271,
                  },
                  routeId: "0uf2fscask",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 31321,
                      fbc: "WCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "31321_37031",
                      tax: [
                        { OT: 5710 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 37031,
                      psup_fare: "31321_37031",
                    },
                  ],
                  pr: {
                    bf: 31321,
                    tf: 37031,
                    tax: [
                      { OT: 5710 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "31321_37031",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 37031,
                  },
                  routeId: "jhzb7sndp8g",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "G79e0AHvUTs=|pfN7IeMVY9crZtLzdrvIzm0oZntQ6f3741aeYn1CvdMLxX16D05jLOkRUUokspRUNBaad/QhuW2NmBov2F0uAOs5rQ6LmyiTG2kKghIPh0qz95QB/MyFDxPn3z3uu1Sj7jxXZQa3gsE=",
              stop: 1,
            },
            {
              tid: "WVzT5CR441tEL27E_1",
              ref: 1,
              office_id: "",
              sdur: "9:20",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DEL",
                  dd: "2023-06-07T20:20:00",
                  ad: "2023-06-07T22:30:00",
                  rbd: "",
                  fn: "2213",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "2" },
                  rph: "",
                  jsell:
                    "6E~2213~ ~~HYD~06/07/2023 20:20~DEL~06/07/2023 22:30~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-06-08T04:05:00",
                  ad: "2023-06-08T05:40:00",
                  rbd: "",
                  fn: "1307",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "3" },
                  rph: "",
                  jsell:
                    "6E~1307~ ~~DEL~06/08/2023 04:05~DOH~06/08/2023 05:40~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 30621,
                      fbc: "WCINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "30621_36271",
                      tax: [
                        { OT: 5650 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 36271,
                      psup_fare: "30621_36271",
                    },
                  ],
                  pr: {
                    bf: 30621,
                    tf: 36271,
                    tax: [
                      { OT: 5650 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "30621_36271",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 36271,
                  },
                  routeId: "qsmog2w3kpg",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 31321,
                      fbc: "WCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "31321_37031",
                      tax: [
                        { OT: 5710 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 37031,
                      psup_fare: "31321_37031",
                    },
                  ],
                  pr: {
                    bf: 31321,
                    tf: 37031,
                    tax: [
                      { OT: 5710 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "31321_37031",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 37031,
                  },
                  routeId: "t7roy5hp1e",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "G79e0AHvUTs=|pfN7IeMVY9crZtLzdrvIzm0oZntQ6f3741aeYn1CvdMLxX16D05jLOkRUUokspRUNBaad/QhuW2NmBov2F0uAOs5rQ6LmyiTG2kKghIPh0qz95QB/MyFDxPn3z3uu1Sj7jxXZQa3gsE=",
              stop: 1,
            },
            {
              tid: "U0ESSxe2vKn74aFH_1",
              ref: 1,
              office_id: "",
              sdur: "8:55",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "BOM",
                  dd: "2023-06-07T20:50:00",
                  ad: "2023-06-07T22:05:00",
                  rbd: "",
                  fn: "5389",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "1" },
                  rph: "",
                  jsell:
                    "6E~5389~ ~~HYD~06/07/2023 20:50~BOM~06/07/2023 22:05~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
                {
                  ref: 2,
                  da: "BOM",
                  ar: "DOH",
                  dd: "2023-06-08T04:50:00",
                  ad: "2023-06-08T05:45:00",
                  rbd: "",
                  fn: "1303",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "2" },
                  rph: "",
                  jsell:
                    "6E~1303~ ~~BOM~06/08/2023 04:50~DOH~06/08/2023 05:45~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 30621,
                      fbc: "WCINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "30621_36271",
                      tax: [
                        { OT: 5650 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 36271,
                      psup_fare: "30621_36271",
                    },
                  ],
                  pr: {
                    bf: 30621,
                    tf: 36271,
                    tax: [
                      { OT: 5650 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "30621_36271",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 36271,
                  },
                  routeId: "dt7pfurjx5f",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 31321,
                      fbc: "WCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "31321_37031",
                      tax: [
                        { OT: 5710 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 37031,
                      psup_fare: "31321_37031",
                    },
                  ],
                  pr: {
                    bf: 31321,
                    tf: 37031,
                    tax: [
                      { OT: 5710 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "31321_37031",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 37031,
                  },
                  routeId: "qmrzv7q8xu",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "G79e0AHvUTs=|pfN7IeMVY9crZtLzdrvIzm0oZntQ6f3741aeYn1CvdMLxX16D05jLOkRUUokspRUNBaad/QhuW2NmBov2F0uAOs5rQ6LmyiTG2kKghIPh0qz95QB/MyFDxPn3z3uu1Sj7jxXZQa3gsE=",
              stop: 1,
            },
            {
              tid: "ioNoiXlUcdD8cN5g_1",
              ref: 1,
              office_id: "",
              sdur: "8:10",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "BOM",
                  dd: "2023-06-07T21:35:00",
                  ad: "2023-06-07T23:05:00",
                  rbd: "",
                  fn: "6952",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "2" },
                  rph: "",
                  jsell:
                    "6E~6952~ ~~HYD~06/07/2023 21:35~BOM~06/07/2023 23:05~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
                {
                  ref: 2,
                  da: "BOM",
                  ar: "DOH",
                  dd: "2023-06-08T04:50:00",
                  ad: "2023-06-08T05:45:00",
                  rbd: "",
                  fn: "1303",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "2" },
                  rph: "",
                  jsell:
                    "6E~1303~ ~~BOM~06/08/2023 04:50~DOH~06/08/2023 05:45~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 30621,
                      fbc: "WCINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "30621_36271",
                      tax: [
                        { OT: 5650 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 36271,
                      psup_fare: "30621_36271",
                    },
                  ],
                  pr: {
                    bf: 30621,
                    tf: 36271,
                    tax: [
                      { OT: 5650 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "30621_36271",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 36271,
                  },
                  routeId: "b95c6hcuzc",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 31321,
                      fbc: "WCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "31321_37031",
                      tax: [
                        { OT: 5710 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 37031,
                      psup_fare: "31321_37031",
                    },
                  ],
                  pr: {
                    bf: 31321,
                    tf: 37031,
                    tax: [
                      { OT: 5710 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "31321_37031",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 37031,
                  },
                  routeId: "qzlsdrsrjlj",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "G79e0AHvUTs=|pfN7IeMVY9crZtLzdrvIzm0oZntQ6f3741aeYn1CvdMLxX16D05jLOkRUUokspRUNBaad/QhuW2NmBov2F0uAOs5rQ6LmyiTG2kKghIPh0qz95QB/MyFDxPn3z3uu1Sj7jxXZQa3gsE=",
              stop: 1,
            },
            {
              tid: "39HOZwDZ27Mhk7mI_1",
              ref: 1,
              office_id: "",
              sdur: "7:45",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DEL",
                  dd: "2023-06-07T21:55:00",
                  ad: "2023-06-08T00:05:00",
                  rbd: "",
                  fn: "5605",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "3" },
                  rph: "",
                  jsell:
                    "6E~5605~ ~~HYD~06/07/2023 21:55~DEL~06/08/2023 00:05~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-06-08T04:05:00",
                  ad: "2023-06-08T05:40:00",
                  rbd: "",
                  fn: "1307",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "3" },
                  rph: "",
                  jsell:
                    "6E~1307~ ~~DEL~06/08/2023 04:05~DOH~06/08/2023 05:40~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 30621,
                      fbc: "WCINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "30621_36271",
                      tax: [
                        { OT: 5650 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 36271,
                      psup_fare: "30621_36271",
                    },
                  ],
                  pr: {
                    bf: 30621,
                    tf: 36271,
                    tax: [
                      { OT: 5650 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "30621_36271",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 36271,
                  },
                  routeId: "tgvm7n5rx2g",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 31321,
                      fbc: "WCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "31321_37031",
                      tax: [
                        { OT: 5710 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 37031,
                      psup_fare: "31321_37031",
                    },
                  ],
                  pr: {
                    bf: 31321,
                    tf: 37031,
                    tax: [
                      { OT: 5710 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "31321_37031",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 37031,
                  },
                  routeId: "hkdho3kbue",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "G79e0AHvUTs=|pfN7IeMVY9crZtLzdrvIzm0oZntQ6f3741aeYn1CvdMLxX16D05jLOkRUUokspRUNBaad/QhuW2NmBov2F0uAOs5rQ6LmyiTG2kKghIPh0qz95QB/MyFDxPn3z3uu1Sj7jxXZQa3gsE=",
              stop: 1,
            },
            {
              tid: "hv0HHJPiaVCqB4lM_1",
              ref: 1,
              office_id: "",
              sdur: "7:5",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "BOM",
                  dd: "2023-06-07T22:40:00",
                  ad: "2023-06-08T00:15:00",
                  rbd: "",
                  fn: " 267",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "2" },
                  rph: "",
                  jsell:
                    "6E~ 267~ ~~HYD~06/07/2023 22:40~BOM~06/08/2023 00:15~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
                {
                  ref: 2,
                  da: "BOM",
                  ar: "DOH",
                  dd: "2023-06-08T04:50:00",
                  ad: "2023-06-08T05:45:00",
                  rbd: "",
                  fn: "1303",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "2" },
                  rph: "",
                  jsell:
                    "6E~1303~ ~~BOM~06/08/2023 04:50~DOH~06/08/2023 05:45~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 30621,
                      fbc: "WCINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "30621_36271",
                      tax: [
                        { OT: 5650 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 36271,
                      psup_fare: "30621_36271",
                    },
                  ],
                  pr: {
                    bf: 30621,
                    tf: 36271,
                    tax: [
                      { OT: 5650 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "30621_36271",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 36271,
                  },
                  routeId: "zgnk3vqibb",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 31321,
                      fbc: "WCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "31321_37031",
                      tax: [
                        { OT: 5710 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 37031,
                      psup_fare: "31321_37031",
                    },
                  ],
                  pr: {
                    bf: 31321,
                    tf: 37031,
                    tax: [
                      { OT: 5710 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "31321_37031",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 37031,
                  },
                  routeId: "op0yg8zeuq",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "G79e0AHvUTs=|pfN7IeMVY9crZtLzdrvIzm0oZntQ6f3741aeYn1CvdMLxX16D05jLOkRUUokspRUNBaad/QhuW2NmBov2F0uAOs5rQ6LmyiTG2kKghIPh0qz95QB/MyFDxPn3z3uu1Sj7jxXZQa3gsE=",
              stop: 1,
            },
            {
              tid: "NTAMvdlyUAzG5PZn_1",
              ref: 1,
              office_id: "",
              sdur: "6:55",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DEL",
                  dd: "2023-06-07T22:45:00",
                  ad: "2023-06-08T01:00:00",
                  rbd: "",
                  fn: "6146",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "1" },
                  rph: "",
                  jsell:
                    "6E~6146~ ~~HYD~06/07/2023 22:45~DEL~06/08/2023 01:00~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-06-08T04:05:00",
                  ad: "2023-06-08T05:40:00",
                  rbd: "",
                  fn: "1307",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "3" },
                  rph: "",
                  jsell:
                    "6E~1307~ ~~DEL~06/08/2023 04:05~DOH~06/08/2023 05:40~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 30621,
                      fbc: "WCINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "30621_36271",
                      tax: [
                        { OT: 5650 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 36271,
                      psup_fare: "30621_36271",
                    },
                  ],
                  pr: {
                    bf: 30621,
                    tf: 36271,
                    tax: [
                      { OT: 5650 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "30621_36271",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 36271,
                  },
                  routeId: "4ox6jqn82v",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 31321,
                      fbc: "WCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "31321_37031",
                      tax: [
                        { OT: 5710 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 37031,
                      psup_fare: "31321_37031",
                    },
                  ],
                  pr: {
                    bf: 31321,
                    tf: 37031,
                    tax: [
                      { OT: 5710 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "31321_37031",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 37031,
                  },
                  routeId: "xgthxlzsgz",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "G79e0AHvUTs=|pfN7IeMVY9crZtLzdrvIzm0oZntQ6f3741aeYn1CvdMLxX16D05jLOkRUUokspRUNBaad/QhuW2NmBov2F0uAOs5rQ6LmyiTG2kKghIPh0qz95QB/MyFDxPn3z3uu1Sj7jxXZQa3gsE=",
              stop: 1,
            },
          ],
          destorigins: [
            {
              tid: "fxduPvZgyGu4sDeh_2",
              ref: 2,
              office_id: "",
              sdur: "6:45",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "HYD",
                  dd: "2023-06-09T02:00:00",
                  ad: "2023-06-09T08:45:00",
                  rbd: "",
                  fn: "1318",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: {},
                  rph: "",
                  jsell:
                    "6E~1318~ ~~DOH~06/09/2023 02:00~HYD~06/09/2023 08:45~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey: "0~R~ ~6E~R0INT~5000~~0~4~~X",
                  pr: {
                    bf: 7500,
                    tf: 10876,
                    tax: [
                      { OT: 3376 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "7500_10876",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 10876,
                  },
                  paxpr: [
                    {
                      bf: 7500,
                      tf: 10876,
                      tax: [
                        { OT: 3376 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "R0INT",
                      ptype: "ADT",
                      scur: "INR",
                      sup_fare: "7500_10876",
                      psup_fare: "7500_10876",
                    },
                  ],
                  routeId: "0yvq4h7odu",
                  productClass: "R",
                  ruleNumber: "5000",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey: "0~R~ ~6E~RUINT~5003~~0~10~~X",
                  pr: {
                    bf: 8200,
                    tf: 11600,
                    tax: [
                      { OT: 3400 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "8200_11600",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 11600,
                  },
                  paxpr: [
                    {
                      bf: 8200,
                      tf: 11600,
                      tax: [
                        { OT: 3400 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "RUINT",
                      ptype: "ADT",
                      scur: "INR",
                      sup_fare: "8200_11600",
                      psup_fare: "8200_11600",
                    },
                  ],
                  routeId: "j5bpe2l8q8",
                  productClass: "J",
                  ruleNumber: "5003",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "G79e0AHvUTs=|pfN7IeMVY9crZtLzdrvIzm0oZntQ6f3741aeYn1CvdMLxX16D05jLOkRUUokspRUNBaad/QhuW2NmBov2F0uAOs5rQ6LmyiTG2kKghIPh0qz95QB/MyFDxPn3z3uu1Sj7jxXZQa3gsE=",
              stop: 0,
            },
            {
              tid: "HDot1C5AxisZ765N_2",
              ref: 2,
              office_id: "",
              sdur: "6:45",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "HYD",
                  dd: "2023-06-09T08:15:00",
                  ad: "2023-06-09T15:00:00",
                  rbd: "",
                  fn: "1314",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: {},
                  rph: "",
                  jsell:
                    "6E~1314~ ~~DOH~06/09/2023 08:15~HYD~06/09/2023 15:00~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
              ],
              fareop: [
                {
                  fareKey: "0~R~ ~6E~R0INT~5000~~0~4~~X",
                  pr: {
                    bf: 7500,
                    tf: 10876,
                    tax: [
                      { OT: 3376 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "7500_10876",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 10876,
                  },
                  paxpr: [
                    {
                      bf: 7500,
                      tf: 10876,
                      tax: [
                        { OT: 3376 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "R0INT",
                      ptype: "ADT",
                      scur: "INR",
                      sup_fare: "7500_10876",
                      psup_fare: "7500_10876",
                    },
                  ],
                  routeId: "di7nhwfsws",
                  productClass: "R",
                  ruleNumber: "5000",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey: "0~R~ ~6E~RUINT~5003~~0~10~~X",
                  pr: {
                    bf: 8200,
                    tf: 11600,
                    tax: [
                      { OT: 3400 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "8200_11600",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 11600,
                  },
                  paxpr: [
                    {
                      bf: 8200,
                      tf: 11600,
                      tax: [
                        { OT: 3400 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "RUINT",
                      ptype: "ADT",
                      scur: "INR",
                      sup_fare: "8200_11600",
                      psup_fare: "8200_11600",
                    },
                  ],
                  routeId: "ox29qcuq7d",
                  productClass: "J",
                  ruleNumber: "5003",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "G79e0AHvUTs=|pfN7IeMVY9crZtLzdrvIzm0oZntQ6f3741aeYn1CvdMLxX16D05jLOkRUUokspRUNBaad/QhuW2NmBov2F0uAOs5rQ6LmyiTG2kKghIPh0qz95QB/MyFDxPn3z3uu1Sj7jxXZQa3gsE=",
              stop: 0,
            },
            {
              tid: "UbP3RL6pcmDnpnUw_2",
              ref: 2,
              office_id: "",
              sdur: "6:45",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "HYD",
                  dd: "2023-06-09T19:45:00",
                  ad: "2023-06-10T02:30:00",
                  rbd: "",
                  fn: "1316",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: {},
                  rph: "",
                  jsell:
                    "6E~1316~ ~~DOH~06/09/2023 19:45~HYD~06/10/2023 02:30~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
              ],
              fareop: [
                {
                  fareKey: "0~R~ ~6E~R0INT~5000~~0~4~~X",
                  pr: {
                    bf: 7500,
                    tf: 10876,
                    tax: [
                      { OT: 3376 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "7500_10876",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 10876,
                  },
                  paxpr: [
                    {
                      bf: 7500,
                      tf: 10876,
                      tax: [
                        { OT: 3376 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "R0INT",
                      ptype: "ADT",
                      scur: "INR",
                      sup_fare: "7500_10876",
                      psup_fare: "7500_10876",
                    },
                  ],
                  routeId: "459fqnfb4v",
                  productClass: "R",
                  ruleNumber: "5000",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey: "0~R~ ~6E~RUINT~5003~~0~10~~X",
                  pr: {
                    bf: 8200,
                    tf: 11600,
                    tax: [
                      { OT: 3400 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "8200_11600",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 11600,
                  },
                  paxpr: [
                    {
                      bf: 8200,
                      tf: 11600,
                      tax: [
                        { OT: 3400 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "RUINT",
                      ptype: "ADT",
                      scur: "INR",
                      sup_fare: "8200_11600",
                      psup_fare: "8200_11600",
                    },
                  ],
                  routeId: "ik54voxxai",
                  productClass: "J",
                  ruleNumber: "5003",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "G79e0AHvUTs=|pfN7IeMVY9crZtLzdrvIzm0oZntQ6f3741aeYn1CvdMLxX16D05jLOkRUUokspRUNBaad/QhuW2NmBov2F0uAOs5rQ6LmyiTG2kKghIPh0qz95QB/MyFDxPn3z3uu1Sj7jxXZQa3gsE=",
              stop: 0,
            },
          ],
          paxpr: [
            {
              bf: 38121,
              fbc: "WCINT",
              ptype: "ADT",
              scur: "QAR",
              sup_fare: "30621_36271",
              tax: [
                { OT: 9026 },
                { attmkp: 0 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              tf: 47147,
              psup_fare: "38121_47147",
            },
          ],
          pr: {
            bf: 38121,
            tf: 47147,
            tax: [
              { OT: 9026 },
              { attmkp: 0 },
              { tamkp: 0 },
              { stamkp: 0 },
              { attdisc: 0 },
              { tadisc: 0 },
              { stadisc: 0 },
            ],
            ucur: "INR",
            exchange_rate: 1,
            scur: "INR",
            sup_fare: "38121_47147",
            sup_cur_fare: "",
            cabb: "",
            chekb: "",
            seats: "",
            meals: "",
            chanb: "",
            ftyp: "Regular - R",
            markupnames: [],
            ttf: 47147,
          },
          sup: "4",
          airlineList: "6E",
          servicefee: [],
        },
        {
          srcorigns: [
            {
              tid: "1BTQmGdpgiyKwBFe_1",
              ref: 1,
              office_id: "",
              sdur: "12:0",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DEL",
                  dd: "2023-06-07T11:45:00",
                  ad: "2023-06-07T14:05:00",
                  rbd: "",
                  fn: "2171",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "2" },
                  rph: "",
                  jsell:
                    "6E~2171~ ~~HYD~06/07/2023 11:45~DEL~06/07/2023 14:05~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-06-07T22:10:00",
                  ad: "2023-06-07T23:45:00",
                  rbd: "",
                  fn: "1311",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "3" },
                  rph: "",
                  jsell:
                    "6E~1311~ ~~DEL~06/07/2023 22:10~DOH~06/07/2023 23:45~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 30621,
                      fbc: "WCINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "30621_36271",
                      tax: [
                        { OT: 5650 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 36271,
                      psup_fare: "30621_36271",
                    },
                  ],
                  pr: {
                    bf: 30621,
                    tf: 36271,
                    tax: [
                      { OT: 5650 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "30621_36271",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 36271,
                  },
                  routeId: "30wp3zl9au",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 31321,
                      fbc: "WCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "31321_37031",
                      tax: [
                        { OT: 5710 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 37031,
                      psup_fare: "31321_37031",
                    },
                  ],
                  pr: {
                    bf: 31321,
                    tf: 37031,
                    tax: [
                      { OT: 5710 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "31321_37031",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 37031,
                  },
                  routeId: "npm5huw8bc",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "G79e0AHvUTs=|pfN7IeMVY9crZtLzdrvIzm0oZntQ6f3741aeYn1CvdMLxX16D05jLOkRUUokspRUNBaad/QhuW2NmBov2F0uAOs5rQ6LmyiTG2kKghIPh0qz95QB/MyFDxPn3z3uu1Sj7jxXZQa3gsE=",
              stop: 1,
            },
            {
              tid: "Dd8DXQTggnurSdQH_1",
              ref: 1,
              office_id: "",
              sdur: "5:15",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "BOM",
                  dd: "2023-06-07T13:30:00",
                  ad: "2023-06-07T15:05:00",
                  rbd: "",
                  fn: "6699",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "2" },
                  rph: "",
                  jsell:
                    "6E~6699~ ~~HYD~06/07/2023 13:30~BOM~06/07/2023 15:05~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
                {
                  ref: 2,
                  da: "BOM",
                  ar: "DOH",
                  dd: "2023-06-07T17:50:00",
                  ad: "2023-06-07T18:45:00",
                  rbd: "",
                  fn: "1305",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "2" },
                  rph: "",
                  jsell:
                    "6E~1305~ ~~BOM~06/07/2023 17:50~DOH~06/07/2023 18:45~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 30621,
                      fbc: "WCINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "30621_36271",
                      tax: [
                        { OT: 5650 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 36271,
                      psup_fare: "30621_36271",
                    },
                  ],
                  pr: {
                    bf: 30621,
                    tf: 36271,
                    tax: [
                      { OT: 5650 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "30621_36271",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 36271,
                  },
                  routeId: "ufbh0pozdm",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 31321,
                      fbc: "WCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "31321_37031",
                      tax: [
                        { OT: 5710 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 37031,
                      psup_fare: "31321_37031",
                    },
                  ],
                  pr: {
                    bf: 31321,
                    tf: 37031,
                    tax: [
                      { OT: 5710 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "31321_37031",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 37031,
                  },
                  routeId: "dsz2bxobkj",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "G79e0AHvUTs=|pfN7IeMVY9crZtLzdrvIzm0oZntQ6f3741aeYn1CvdMLxX16D05jLOkRUUokspRUNBaad/QhuW2NmBov2F0uAOs5rQ6LmyiTG2kKghIPh0qz95QB/MyFDxPn3z3uu1Sj7jxXZQa3gsE=",
              stop: 1,
            },
            {
              tid: "fzNteV5ZxThQthiw_1",
              ref: 1,
              office_id: "",
              sdur: "15:15",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DEL",
                  dd: "2023-06-07T14:25:00",
                  ad: "2023-06-07T16:45:00",
                  rbd: "",
                  fn: "2063",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "2" },
                  rph: "",
                  jsell:
                    "6E~2063~ ~~HYD~06/07/2023 14:25~DEL~06/07/2023 16:45~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-06-08T04:05:00",
                  ad: "2023-06-08T05:40:00",
                  rbd: "",
                  fn: "1307",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "3" },
                  rph: "",
                  jsell:
                    "6E~1307~ ~~DEL~06/08/2023 04:05~DOH~06/08/2023 05:40~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 30621,
                      fbc: "WCINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "30621_36271",
                      tax: [
                        { OT: 5650 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 36271,
                      psup_fare: "30621_36271",
                    },
                  ],
                  pr: {
                    bf: 30621,
                    tf: 36271,
                    tax: [
                      { OT: 5650 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "30621_36271",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 36271,
                  },
                  routeId: "y3wvo2pjcj",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 31321,
                      fbc: "WCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "31321_37031",
                      tax: [
                        { OT: 5710 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 37031,
                      psup_fare: "31321_37031",
                    },
                  ],
                  pr: {
                    bf: 31321,
                    tf: 37031,
                    tax: [
                      { OT: 5710 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "31321_37031",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 37031,
                  },
                  routeId: "c8umvb2jge",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "G79e0AHvUTs=|pfN7IeMVY9crZtLzdrvIzm0oZntQ6f3741aeYn1CvdMLxX16D05jLOkRUUokspRUNBaad/QhuW2NmBov2F0uAOs5rQ6LmyiTG2kKghIPh0qz95QB/MyFDxPn3z3uu1Sj7jxXZQa3gsE=",
              stop: 1,
            },
            {
              tid: "7q2XHtpAfPXasovH_1",
              ref: 1,
              office_id: "",
              sdur: "9:20",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DEL",
                  dd: "2023-06-07T14:25:00",
                  ad: "2023-06-07T16:45:00",
                  rbd: "",
                  fn: "2063",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "2" },
                  rph: "",
                  jsell:
                    "6E~2063~ ~~HYD~06/07/2023 14:25~DEL~06/07/2023 16:45~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-06-07T22:10:00",
                  ad: "2023-06-07T23:45:00",
                  rbd: "",
                  fn: "1311",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "3" },
                  rph: "",
                  jsell:
                    "6E~1311~ ~~DEL~06/07/2023 22:10~DOH~06/07/2023 23:45~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 30621,
                      fbc: "WCINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "30621_36271",
                      tax: [
                        { OT: 5650 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 36271,
                      psup_fare: "30621_36271",
                    },
                  ],
                  pr: {
                    bf: 30621,
                    tf: 36271,
                    tax: [
                      { OT: 5650 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "30621_36271",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 36271,
                  },
                  routeId: "7bki6fw2b8",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 31321,
                      fbc: "WCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "31321_37031",
                      tax: [
                        { OT: 5710 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 37031,
                      psup_fare: "31321_37031",
                    },
                  ],
                  pr: {
                    bf: 31321,
                    tf: 37031,
                    tax: [
                      { OT: 5710 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "31321_37031",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 37031,
                  },
                  routeId: "vv9xd2zv01",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "G79e0AHvUTs=|pfN7IeMVY9crZtLzdrvIzm0oZntQ6f3741aeYn1CvdMLxX16D05jLOkRUUokspRUNBaad/QhuW2NmBov2F0uAOs5rQ6LmyiTG2kKghIPh0qz95QB/MyFDxPn3z3uu1Sj7jxXZQa3gsE=",
              stop: 1,
            },
            {
              tid: "NQokoGKlVB3owmfu_1",
              ref: 1,
              office_id: "",
              sdur: "7:15",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DEL",
                  dd: "2023-06-07T16:30:00",
                  ad: "2023-06-07T18:50:00",
                  rbd: "",
                  fn: "6606",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "1" },
                  rph: "",
                  jsell:
                    "6E~6606~ ~~HYD~06/07/2023 16:30~DEL~06/07/2023 18:50~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-06-07T22:10:00",
                  ad: "2023-06-07T23:45:00",
                  rbd: "",
                  fn: "1311",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "3" },
                  rph: "",
                  jsell:
                    "6E~1311~ ~~DEL~06/07/2023 22:10~DOH~06/07/2023 23:45~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 30621,
                      fbc: "WCINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "30621_36271",
                      tax: [
                        { OT: 5650 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 36271,
                      psup_fare: "30621_36271",
                    },
                  ],
                  pr: {
                    bf: 30621,
                    tf: 36271,
                    tax: [
                      { OT: 5650 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "30621_36271",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 36271,
                  },
                  routeId: "o7ivp9n7ss",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 31321,
                      fbc: "WCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "31321_37031",
                      tax: [
                        { OT: 5710 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 37031,
                      psup_fare: "31321_37031",
                    },
                  ],
                  pr: {
                    bf: 31321,
                    tf: 37031,
                    tax: [
                      { OT: 5710 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "31321_37031",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 37031,
                  },
                  routeId: "3u8045ri6c",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "G79e0AHvUTs=|pfN7IeMVY9crZtLzdrvIzm0oZntQ6f3741aeYn1CvdMLxX16D05jLOkRUUokspRUNBaad/QhuW2NmBov2F0uAOs5rQ6LmyiTG2kKghIPh0qz95QB/MyFDxPn3z3uu1Sj7jxXZQa3gsE=",
              stop: 1,
            },
            {
              tid: "mBWu5gckJ1NDgbNf_1",
              ref: 1,
              office_id: "",
              sdur: "13:10",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DEL",
                  dd: "2023-06-07T16:30:00",
                  ad: "2023-06-07T18:50:00",
                  rbd: "",
                  fn: "6606",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "1" },
                  rph: "",
                  jsell:
                    "6E~6606~ ~~HYD~06/07/2023 16:30~DEL~06/07/2023 18:50~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-06-08T04:05:00",
                  ad: "2023-06-08T05:40:00",
                  rbd: "",
                  fn: "1307",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "3" },
                  rph: "",
                  jsell:
                    "6E~1307~ ~~DEL~06/08/2023 04:05~DOH~06/08/2023 05:40~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 30621,
                      fbc: "WCINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "30621_36271",
                      tax: [
                        { OT: 5650 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 36271,
                      psup_fare: "30621_36271",
                    },
                  ],
                  pr: {
                    bf: 30621,
                    tf: 36271,
                    tax: [
                      { OT: 5650 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "30621_36271",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 36271,
                  },
                  routeId: "f4wnkj6q3r",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 31321,
                      fbc: "WCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "31321_37031",
                      tax: [
                        { OT: 5710 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 37031,
                      psup_fare: "31321_37031",
                    },
                  ],
                  pr: {
                    bf: 31321,
                    tf: 37031,
                    tax: [
                      { OT: 5710 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "31321_37031",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 37031,
                  },
                  routeId: "lz0egg7p01",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "G79e0AHvUTs=|pfN7IeMVY9crZtLzdrvIzm0oZntQ6f3741aeYn1CvdMLxX16D05jLOkRUUokspRUNBaad/QhuW2NmBov2F0uAOs5rQ6LmyiTG2kKghIPh0qz95QB/MyFDxPn3z3uu1Sj7jxXZQa3gsE=",
              stop: 1,
            },
            {
              tid: "rwGTCK3E7fIQZtbg_1",
              ref: 1,
              office_id: "",
              sdur: "11:25",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DEL",
                  dd: "2023-06-07T18:15:00",
                  ad: "2023-06-07T20:30:00",
                  rbd: "",
                  fn: "2003",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "2" },
                  rph: "",
                  jsell:
                    "6E~2003~ ~~HYD~06/07/2023 18:15~DEL~06/07/2023 20:30~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-06-08T04:05:00",
                  ad: "2023-06-08T05:40:00",
                  rbd: "",
                  fn: "1307",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "3" },
                  rph: "",
                  jsell:
                    "6E~1307~ ~~DEL~06/08/2023 04:05~DOH~06/08/2023 05:40~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 30621,
                      fbc: "WCINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "30621_36271",
                      tax: [
                        { OT: 5650 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 36271,
                      psup_fare: "30621_36271",
                    },
                  ],
                  pr: {
                    bf: 30621,
                    tf: 36271,
                    tax: [
                      { OT: 5650 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "30621_36271",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 36271,
                  },
                  routeId: "5t6toxlx0s",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 31321,
                      fbc: "WCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "31321_37031",
                      tax: [
                        { OT: 5710 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 37031,
                      psup_fare: "31321_37031",
                    },
                  ],
                  pr: {
                    bf: 31321,
                    tf: 37031,
                    tax: [
                      { OT: 5710 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "31321_37031",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 37031,
                  },
                  routeId: "0j2nwjf37r",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "G79e0AHvUTs=|pfN7IeMVY9crZtLzdrvIzm0oZntQ6f3741aeYn1CvdMLxX16D05jLOkRUUokspRUNBaad/QhuW2NmBov2F0uAOs5rQ6LmyiTG2kKghIPh0qz95QB/MyFDxPn3z3uu1Sj7jxXZQa3gsE=",
              stop: 1,
            },
            {
              tid: "eJouqRFWHDvGQJJD_1",
              ref: 1,
              office_id: "",
              sdur: "5:30",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DEL",
                  dd: "2023-06-07T18:15:00",
                  ad: "2023-06-07T20:30:00",
                  rbd: "",
                  fn: "2003",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "2" },
                  rph: "",
                  jsell:
                    "6E~2003~ ~~HYD~06/07/2023 18:15~DEL~06/07/2023 20:30~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-06-07T22:10:00",
                  ad: "2023-06-07T23:45:00",
                  rbd: "",
                  fn: "1311",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "3" },
                  rph: "",
                  jsell:
                    "6E~1311~ ~~DEL~06/07/2023 22:10~DOH~06/07/2023 23:45~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 30621,
                      fbc: "WCINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "30621_36271",
                      tax: [
                        { OT: 5650 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 36271,
                      psup_fare: "30621_36271",
                    },
                  ],
                  pr: {
                    bf: 30621,
                    tf: 36271,
                    tax: [
                      { OT: 5650 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "30621_36271",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 36271,
                  },
                  routeId: "0uf2fscask",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 31321,
                      fbc: "WCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "31321_37031",
                      tax: [
                        { OT: 5710 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 37031,
                      psup_fare: "31321_37031",
                    },
                  ],
                  pr: {
                    bf: 31321,
                    tf: 37031,
                    tax: [
                      { OT: 5710 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "31321_37031",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 37031,
                  },
                  routeId: "jhzb7sndp8g",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "G79e0AHvUTs=|pfN7IeMVY9crZtLzdrvIzm0oZntQ6f3741aeYn1CvdMLxX16D05jLOkRUUokspRUNBaad/QhuW2NmBov2F0uAOs5rQ6LmyiTG2kKghIPh0qz95QB/MyFDxPn3z3uu1Sj7jxXZQa3gsE=",
              stop: 1,
            },
            {
              tid: "2Aql4W17rrpA0ufP_1",
              ref: 1,
              office_id: "",
              sdur: "9:20",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DEL",
                  dd: "2023-06-07T20:20:00",
                  ad: "2023-06-07T22:30:00",
                  rbd: "",
                  fn: "2213",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "2" },
                  rph: "",
                  jsell:
                    "6E~2213~ ~~HYD~06/07/2023 20:20~DEL~06/07/2023 22:30~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-06-08T04:05:00",
                  ad: "2023-06-08T05:40:00",
                  rbd: "",
                  fn: "1307",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "3" },
                  rph: "",
                  jsell:
                    "6E~1307~ ~~DEL~06/08/2023 04:05~DOH~06/08/2023 05:40~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 30621,
                      fbc: "WCINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "30621_36271",
                      tax: [
                        { OT: 5650 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 36271,
                      psup_fare: "30621_36271",
                    },
                  ],
                  pr: {
                    bf: 30621,
                    tf: 36271,
                    tax: [
                      { OT: 5650 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "30621_36271",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 36271,
                  },
                  routeId: "qsmog2w3kpg",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 31321,
                      fbc: "WCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "31321_37031",
                      tax: [
                        { OT: 5710 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 37031,
                      psup_fare: "31321_37031",
                    },
                  ],
                  pr: {
                    bf: 31321,
                    tf: 37031,
                    tax: [
                      { OT: 5710 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "31321_37031",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 37031,
                  },
                  routeId: "t7roy5hp1e",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "G79e0AHvUTs=|pfN7IeMVY9crZtLzdrvIzm0oZntQ6f3741aeYn1CvdMLxX16D05jLOkRUUokspRUNBaad/QhuW2NmBov2F0uAOs5rQ6LmyiTG2kKghIPh0qz95QB/MyFDxPn3z3uu1Sj7jxXZQa3gsE=",
              stop: 1,
            },
            {
              tid: "lSWC3IXZqczgxlM4_1",
              ref: 1,
              office_id: "",
              sdur: "8:55",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "BOM",
                  dd: "2023-06-07T20:50:00",
                  ad: "2023-06-07T22:05:00",
                  rbd: "",
                  fn: "5389",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "1" },
                  rph: "",
                  jsell:
                    "6E~5389~ ~~HYD~06/07/2023 20:50~BOM~06/07/2023 22:05~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
                {
                  ref: 2,
                  da: "BOM",
                  ar: "DOH",
                  dd: "2023-06-08T04:50:00",
                  ad: "2023-06-08T05:45:00",
                  rbd: "",
                  fn: "1303",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "2" },
                  rph: "",
                  jsell:
                    "6E~1303~ ~~BOM~06/08/2023 04:50~DOH~06/08/2023 05:45~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 30621,
                      fbc: "WCINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "30621_36271",
                      tax: [
                        { OT: 5650 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 36271,
                      psup_fare: "30621_36271",
                    },
                  ],
                  pr: {
                    bf: 30621,
                    tf: 36271,
                    tax: [
                      { OT: 5650 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "30621_36271",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 36271,
                  },
                  routeId: "dt7pfurjx5f",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 31321,
                      fbc: "WCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "31321_37031",
                      tax: [
                        { OT: 5710 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 37031,
                      psup_fare: "31321_37031",
                    },
                  ],
                  pr: {
                    bf: 31321,
                    tf: 37031,
                    tax: [
                      { OT: 5710 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "31321_37031",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 37031,
                  },
                  routeId: "qmrzv7q8xu",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "G79e0AHvUTs=|pfN7IeMVY9crZtLzdrvIzm0oZntQ6f3741aeYn1CvdMLxX16D05jLOkRUUokspRUNBaad/QhuW2NmBov2F0uAOs5rQ6LmyiTG2kKghIPh0qz95QB/MyFDxPn3z3uu1Sj7jxXZQa3gsE=",
              stop: 1,
            },
            {
              tid: "B60KNopqXqoK1sGx_1",
              ref: 1,
              office_id: "",
              sdur: "8:10",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "BOM",
                  dd: "2023-06-07T21:35:00",
                  ad: "2023-06-07T23:05:00",
                  rbd: "",
                  fn: "6952",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "2" },
                  rph: "",
                  jsell:
                    "6E~6952~ ~~HYD~06/07/2023 21:35~BOM~06/07/2023 23:05~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
                {
                  ref: 2,
                  da: "BOM",
                  ar: "DOH",
                  dd: "2023-06-08T04:50:00",
                  ad: "2023-06-08T05:45:00",
                  rbd: "",
                  fn: "1303",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "2" },
                  rph: "",
                  jsell:
                    "6E~1303~ ~~BOM~06/08/2023 04:50~DOH~06/08/2023 05:45~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 30621,
                      fbc: "WCINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "30621_36271",
                      tax: [
                        { OT: 5650 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 36271,
                      psup_fare: "30621_36271",
                    },
                  ],
                  pr: {
                    bf: 30621,
                    tf: 36271,
                    tax: [
                      { OT: 5650 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "30621_36271",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 36271,
                  },
                  routeId: "b95c6hcuzc",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 31321,
                      fbc: "WCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "31321_37031",
                      tax: [
                        { OT: 5710 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 37031,
                      psup_fare: "31321_37031",
                    },
                  ],
                  pr: {
                    bf: 31321,
                    tf: 37031,
                    tax: [
                      { OT: 5710 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "31321_37031",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 37031,
                  },
                  routeId: "qzlsdrsrjlj",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "G79e0AHvUTs=|pfN7IeMVY9crZtLzdrvIzm0oZntQ6f3741aeYn1CvdMLxX16D05jLOkRUUokspRUNBaad/QhuW2NmBov2F0uAOs5rQ6LmyiTG2kKghIPh0qz95QB/MyFDxPn3z3uu1Sj7jxXZQa3gsE=",
              stop: 1,
            },
            {
              tid: "T2ztFbLOTxBxMWtn_1",
              ref: 1,
              office_id: "",
              sdur: "7:45",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DEL",
                  dd: "2023-06-07T21:55:00",
                  ad: "2023-06-08T00:05:00",
                  rbd: "",
                  fn: "5605",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "3" },
                  rph: "",
                  jsell:
                    "6E~5605~ ~~HYD~06/07/2023 21:55~DEL~06/08/2023 00:05~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-06-08T04:05:00",
                  ad: "2023-06-08T05:40:00",
                  rbd: "",
                  fn: "1307",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "3" },
                  rph: "",
                  jsell:
                    "6E~1307~ ~~DEL~06/08/2023 04:05~DOH~06/08/2023 05:40~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 30621,
                      fbc: "WCINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "30621_36271",
                      tax: [
                        { OT: 5650 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 36271,
                      psup_fare: "30621_36271",
                    },
                  ],
                  pr: {
                    bf: 30621,
                    tf: 36271,
                    tax: [
                      { OT: 5650 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "30621_36271",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 36271,
                  },
                  routeId: "tgvm7n5rx2g",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 31321,
                      fbc: "WCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "31321_37031",
                      tax: [
                        { OT: 5710 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 37031,
                      psup_fare: "31321_37031",
                    },
                  ],
                  pr: {
                    bf: 31321,
                    tf: 37031,
                    tax: [
                      { OT: 5710 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "31321_37031",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 37031,
                  },
                  routeId: "hkdho3kbue",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "G79e0AHvUTs=|pfN7IeMVY9crZtLzdrvIzm0oZntQ6f3741aeYn1CvdMLxX16D05jLOkRUUokspRUNBaad/QhuW2NmBov2F0uAOs5rQ6LmyiTG2kKghIPh0qz95QB/MyFDxPn3z3uu1Sj7jxXZQa3gsE=",
              stop: 1,
            },
            {
              tid: "8AObTR9NrEQd2zTt_1",
              ref: 1,
              office_id: "",
              sdur: "7:5",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "BOM",
                  dd: "2023-06-07T22:40:00",
                  ad: "2023-06-08T00:15:00",
                  rbd: "",
                  fn: " 267",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "2" },
                  rph: "",
                  jsell:
                    "6E~ 267~ ~~HYD~06/07/2023 22:40~BOM~06/08/2023 00:15~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
                {
                  ref: 2,
                  da: "BOM",
                  ar: "DOH",
                  dd: "2023-06-08T04:50:00",
                  ad: "2023-06-08T05:45:00",
                  rbd: "",
                  fn: "1303",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "2" },
                  rph: "",
                  jsell:
                    "6E~1303~ ~~BOM~06/08/2023 04:50~DOH~06/08/2023 05:45~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 30621,
                      fbc: "WCINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "30621_36271",
                      tax: [
                        { OT: 5650 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 36271,
                      psup_fare: "30621_36271",
                    },
                  ],
                  pr: {
                    bf: 30621,
                    tf: 36271,
                    tax: [
                      { OT: 5650 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "30621_36271",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 36271,
                  },
                  routeId: "zgnk3vqibb",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 31321,
                      fbc: "WCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "31321_37031",
                      tax: [
                        { OT: 5710 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 37031,
                      psup_fare: "31321_37031",
                    },
                  ],
                  pr: {
                    bf: 31321,
                    tf: 37031,
                    tax: [
                      { OT: 5710 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "31321_37031",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 37031,
                  },
                  routeId: "op0yg8zeuq",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "G79e0AHvUTs=|pfN7IeMVY9crZtLzdrvIzm0oZntQ6f3741aeYn1CvdMLxX16D05jLOkRUUokspRUNBaad/QhuW2NmBov2F0uAOs5rQ6LmyiTG2kKghIPh0qz95QB/MyFDxPn3z3uu1Sj7jxXZQa3gsE=",
              stop: 1,
            },
            {
              tid: "nLJeacxLHknk7NoF_1",
              ref: 1,
              office_id: "",
              sdur: "6:55",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DEL",
                  dd: "2023-06-07T22:45:00",
                  ad: "2023-06-08T01:00:00",
                  rbd: "",
                  fn: "6146",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "1" },
                  rph: "",
                  jsell:
                    "6E~6146~ ~~HYD~06/07/2023 22:45~DEL~06/08/2023 01:00~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-06-08T04:05:00",
                  ad: "2023-06-08T05:40:00",
                  rbd: "",
                  fn: "1307",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "3" },
                  rph: "",
                  jsell:
                    "6E~1307~ ~~DEL~06/08/2023 04:05~DOH~06/08/2023 05:40~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 30621,
                      fbc: "WCINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "30621_36271",
                      tax: [
                        { OT: 5650 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 36271,
                      psup_fare: "30621_36271",
                    },
                  ],
                  pr: {
                    bf: 30621,
                    tf: 36271,
                    tax: [
                      { OT: 5650 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "30621_36271",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 36271,
                  },
                  routeId: "4ox6jqn82v",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 31321,
                      fbc: "WCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "31321_37031",
                      tax: [
                        { OT: 5710 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 37031,
                      psup_fare: "31321_37031",
                    },
                  ],
                  pr: {
                    bf: 31321,
                    tf: 37031,
                    tax: [
                      { OT: 5710 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "31321_37031",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 37031,
                  },
                  routeId: "xgthxlzsgz",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "G79e0AHvUTs=|pfN7IeMVY9crZtLzdrvIzm0oZntQ6f3741aeYn1CvdMLxX16D05jLOkRUUokspRUNBaad/QhuW2NmBov2F0uAOs5rQ6LmyiTG2kKghIPh0qz95QB/MyFDxPn3z3uu1Sj7jxXZQa3gsE=",
              stop: 1,
            },
          ],
          destorigins: [
            {
              tid: "JiAONHfwGAstLMPx_2",
              ref: 2,
              office_id: "",
              sdur: "16:15",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "MAA",
                  dd: "2023-06-09T02:00:00",
                  ad: "2023-06-09T09:05:00",
                  rbd: "",
                  fn: "1322",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "3" },
                  rph: "",
                  jsell:
                    "6E~1322~ ~~DOH~06/09/2023 02:00~MAA~06/09/2023 09:05~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
                {
                  ref: 2,
                  da: "MAA",
                  ar: "HYD",
                  dd: "2023-06-09T17:00:00",
                  ad: "2023-06-09T18:15:00",
                  rbd: "",
                  fn: " 562",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "1" },
                  rph: "",
                  jsell:
                    "6E~ 562~ ~~MAA~06/09/2023 17:00~HYD~06/09/2023 18:15~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "1~Q~ ~6E~Q0INT~5000~~0~12~~^2~L~ ~6E~LC8AP~1070~~0~7~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 9313,
                      fbc: "Q0INT/LC8AP",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "9313_12802",
                      tax: [
                        { OT: 3489 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 12802,
                      psup_fare: "9313_12802",
                    },
                  ],
                  pr: {
                    bf: 9313,
                    tf: 12802,
                    tax: [
                      { OT: 3489 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "9313_12802",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 12802,
                  },
                  routeId: "z6gjqkgwnn",
                  ruleNumber: "5000",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~R~ ~6E~RCUINT~6202~~0~4~~X^0~R~ ~6E~RCUINT~6202~~0~4~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 10519,
                      fbc: "RCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "10519_14050",
                      tax: [
                        { OT: 3531 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 14050,
                      psup_fare: "10519_14050",
                    },
                  ],
                  pr: {
                    bf: 10519,
                    tf: 14050,
                    tax: [
                      { OT: 3531 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "10519_14050",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 14050,
                  },
                  routeId: "9qn7q94t6n",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "G79e0AHvUTs=|pfN7IeMVY9crZtLzdrvIzm0oZntQ6f3741aeYn1CvdMLxX16D05jLOkRUUokspRUNBaad/QhuW2NmBov2F0uAOs5rQ6LmyiTG2kKghIPh0qz95QB/MyFDxPn3z3uu1Sj7jxXZQa3gsE=",
              stop: 1,
            },
            {
              tid: "60R9CW2vDISBmxKV_2",
              ref: 2,
              office_id: "",
              sdur: "15:10",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "MAA",
                  dd: "2023-06-09T02:00:00",
                  ad: "2023-06-09T09:05:00",
                  rbd: "",
                  fn: "1322",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "3" },
                  rph: "",
                  jsell:
                    "6E~1322~ ~~DOH~06/09/2023 02:00~MAA~06/09/2023 09:05~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
                {
                  ref: 2,
                  da: "MAA",
                  ar: "HYD",
                  dd: "2023-06-09T16:00:00",
                  ad: "2023-06-09T17:10:00",
                  rbd: "",
                  fn: "6401",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "1" },
                  rph: "",
                  jsell:
                    "6E~6401~ ~~MAA~06/09/2023 16:00~HYD~06/09/2023 17:10~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "1~Q~ ~6E~Q0INT~5000~~0~12~~^2~L~ ~6E~LC8AP~1070~~0~7~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 9313,
                      fbc: "Q0INT/LC8AP",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "9313_12802",
                      tax: [
                        { OT: 3489 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 12802,
                      psup_fare: "9313_12802",
                    },
                  ],
                  pr: {
                    bf: 9313,
                    tf: 12802,
                    tax: [
                      { OT: 3489 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "9313_12802",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 12802,
                  },
                  routeId: "v4jyyrbuej",
                  ruleNumber: "5000",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~R~ ~6E~RCUINT~6202~~0~4~~X^0~R~ ~6E~RCUINT~6202~~0~4~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 10519,
                      fbc: "RCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "10519_14050",
                      tax: [
                        { OT: 3531 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 14050,
                      psup_fare: "10519_14050",
                    },
                  ],
                  pr: {
                    bf: 10519,
                    tf: 14050,
                    tax: [
                      { OT: 3531 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "10519_14050",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 14050,
                  },
                  routeId: "z0bb48uejv",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "G79e0AHvUTs=|pfN7IeMVY9crZtLzdrvIzm0oZntQ6f3741aeYn1CvdMLxX16D05jLOkRUUokspRUNBaad/QhuW2NmBov2F0uAOs5rQ6LmyiTG2kKghIPh0qz95QB/MyFDxPn3z3uu1Sj7jxXZQa3gsE=",
              stop: 1,
            },
            {
              tid: "zT5ACL4O9R1NTw6M_2",
              ref: 2,
              office_id: "",
              sdur: "11:30",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "MAA",
                  dd: "2023-06-09T02:00:00",
                  ad: "2023-06-09T09:05:00",
                  rbd: "",
                  fn: "1322",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "3" },
                  rph: "",
                  jsell:
                    "6E~1322~ ~~DOH~06/09/2023 02:00~MAA~06/09/2023 09:05~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
                {
                  ref: 2,
                  da: "MAA",
                  ar: "HYD",
                  dd: "2023-06-09T12:20:00",
                  ad: "2023-06-09T13:30:00",
                  rbd: "",
                  fn: " 604",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "1" },
                  rph: "",
                  jsell:
                    "6E~ 604~ ~~MAA~06/09/2023 12:20~HYD~06/09/2023 13:30~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "1~Q~ ~6E~Q0INT~5000~~0~12~~^2~L~ ~6E~LC8AP~1070~~0~7~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 9313,
                      fbc: "Q0INT/LC8AP",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "9313_12802",
                      tax: [
                        { OT: 3489 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 12802,
                      psup_fare: "9313_12802",
                    },
                  ],
                  pr: {
                    bf: 9313,
                    tf: 12802,
                    tax: [
                      { OT: 3489 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "9313_12802",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 12802,
                  },
                  routeId: "4vzifa0aqb",
                  ruleNumber: "5000",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~R~ ~6E~RCUINT~6202~~0~4~~X^0~R~ ~6E~RCUINT~6202~~0~4~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 10519,
                      fbc: "RCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "10519_14050",
                      tax: [
                        { OT: 3531 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 14050,
                      psup_fare: "10519_14050",
                    },
                  ],
                  pr: {
                    bf: 10519,
                    tf: 14050,
                    tax: [
                      { OT: 3531 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "10519_14050",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 14050,
                  },
                  routeId: "sk2z8wf20k",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "G79e0AHvUTs=|pfN7IeMVY9crZtLzdrvIzm0oZntQ6f3741aeYn1CvdMLxX16D05jLOkRUUokspRUNBaad/QhuW2NmBov2F0uAOs5rQ6LmyiTG2kKghIPh0qz95QB/MyFDxPn3z3uu1Sj7jxXZQa3gsE=",
              stop: 1,
            },
          ],
          paxpr: [
            {
              bf: 39934,
              fbc: "WCINT",
              ptype: "ADT",
              scur: "QAR",
              sup_fare: "30621_36271",
              tax: [
                { OT: 9139 },
                { attmkp: 0 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              tf: 49073,
              psup_fare: "39934_49073",
            },
          ],
          pr: {
            bf: 39934,
            tf: 49073,
            tax: [
              { OT: 9139 },
              { attmkp: 0 },
              { tamkp: 0 },
              { stamkp: 0 },
              { attdisc: 0 },
              { tadisc: 0 },
              { stadisc: 0 },
            ],
            ucur: "INR",
            exchange_rate: 1,
            scur: "INR",
            sup_fare: "39934_49073",
            sup_cur_fare: "",
            cabb: "",
            chekb: "",
            seats: "",
            meals: "",
            chanb: "",
            ftyp: "Regular - R",
            markupnames: [],
            ttf: 49073,
          },
          sup: "4",
          airlineList: "6E",
          servicefee: [],
        },
        {
          srcorigns: [
            {
              tid: "1lzALKSDxiCOz0r5_1",
              ref: 1,
              office_id: "",
              sdur: "8:25",
              re: "",
              sup: "5",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DXB",
                  dd: "2023-06-07T03:00:00",
                  ad: "2023-06-07T05:30:00",
                  rbd: "",
                  fn: "436",
                  trn: "",
                  eq: "73B",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "", arr: "2" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161457,
                  flightTime: 240,
                  fdur: "4:0",
                },
                {
                  ref: 2,
                  da: "DXB",
                  ar: "DOH",
                  dd: "2023-06-07T08:45:00",
                  ad: "2023-06-07T08:55:00",
                  rbd: "",
                  fn: "001",
                  trn: "",
                  eq: "73X",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "2", arr: "1" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161405,
                  flightTime: 70,
                  fdur: "1:10",
                },
              ],
              fareop: [
                {
                  fareKey: [],
                  pr: {
                    bf: 13255,
                    tf: 24530,
                    tax: [
                      { OT: 11275 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    scur: "INR",
                    ucur: "INR",
                    exchange_rate: 1,
                    sup_fare: "13255_24530",
                    sup_cur_fare: "",
                    cabb: "Chargeable",
                    chekb: "7kg INCLUDED",
                    meals: "Chargeable",
                    chanb: "Non Refundable",
                    ftyp: "LITE",
                    extra: "11_LITE",
                    chekbcode: "",
                    chanb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Change of Flight is Non-Refundable,NOSHOW From 0 hrs before  of departure Change of Flight is Non-Refundable",
                    canb: "Non Refundable",
                    cabb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Cancellation of Flight is Non-Refundable,NOSHOW From 0 hrs before  of departure Cancellation of Flight is Non-Refundable",
                    bagage_info: "Chargeable",
                    markupnames: [],
                    ttf: 24530,
                  },
                  paxpr: [
                    {
                      bf: 13255,
                      tf: 24530,
                      tax: [
                        { OT: 11275 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "OR6IN5",
                      ptype: "ADT",
                      scur: "INR",
                      extra:
                        "1_1_O_1_9_0_tx-2186:170:PZ:0_4246:6450:YQ:0_9327:970:K3:0_10386:120:ZR:0_11947:790:F6:0_12006:1170:P2:0_12434:1605:IN:0_",
                      ruleid:
                        "LSX/QbpvmKR/hVWmjOqJj4CMSUE+oAXNhfzC1AvPpsdXVy0HLLTzOxy6vo4KsNKyH4iOhL3qCTzeoIR/oeSzY4TEtPO2CThrAiJMBJQHPOaxndbba/gOeDLph5SLjZ1F",
                      hsc: "1fbde0529fa8278a164e6190fc1fd47a2cd0ea34e01ac12c2a8368e8eeed3cf2",
                      sup_fare: "13255_24530_12805",
                      cabb: "Chargeable",
                      bagage_info: "Chargeable",
                      chekbcode: "",
                      psup_fare: "13255_24530",
                    },
                  ],
                  uniqueId: "LITE",
                  routeId: "skoo1myxfv",
                  servicefee: [],
                  selected: true,
                },
              ],
              tktair: "FZ",
              tecst: "no",
              cl: "Economy",
              extra: 13919293,
              token:
                "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiYXBpdGF3ZmVlcSIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL2dpdmVubmFtZSI6IkFQSSBUQVdGRUVRIiwiZ3JvdXBBY2Nlc3MiOiIzLDM2LDExMiIsImlzQXB0Q2xudCI6IkZhbHNlIiwibG9jYXRpb24iOiI5OTk5IiwiY2hrTGNuIjoiIiwicmVzVG9rZW4iOiJkZWRhZDdkeWY4N2FuOTc0dGJxYjY0YmJ6ZjZlOTYwYzA5ZTE5NmRhZTg2MCIsImNsaWVudElQIjoiMTcyLjE5LjIzLjU0IiwicmVzQ2hhbm5lbElEIjoiNSIsInJlc0NoYW5uZWxOYW1lIjoiVFBBUEkiLCJkZWZhdWx0VXNlck5hbWUiOiJhcGl0YXdmZWVxIiwibmJmIjoxNjg1NTE1MjM3LCJleHAiOjE2ODU1MTc2MzcsImlzcyI6ImZseWR1YmFpLmNvbSIsImF1ZCI6IlRBV0ZFRVFUUlZMU19GWl9OUCJ9.rAW3YT-bX_-yADfvg3QkuPAbOJhkhRUN6HwoS_6PyiM",
              stop: 1,
            },
            {
              tid: "f64nX0U0btsdLM88_1",
              ref: 1,
              office_id: "",
              sdur: "15:25",
              re: "",
              sup: "5",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DXB",
                  dd: "2023-06-07T03:00:00",
                  ad: "2023-06-07T05:30:00",
                  rbd: "",
                  fn: "436",
                  trn: "",
                  eq: "73B",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "", arr: "2" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161457,
                  flightTime: 240,
                  fdur: "4:0",
                },
                {
                  ref: 2,
                  da: "DXB",
                  ar: "DOH",
                  dd: "2023-06-07T15:45:00",
                  ad: "2023-06-07T15:55:00",
                  rbd: "",
                  fn: "017",
                  trn: "",
                  eq: "73X",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "2", arr: "1" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161400,
                  flightTime: 70,
                  fdur: "1:10",
                },
              ],
              fareop: [
                {
                  fareKey: [],
                  pr: {
                    bf: 13255,
                    tf: 24530,
                    tax: [
                      { OT: 11275 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    scur: "INR",
                    ucur: "INR",
                    exchange_rate: 1,
                    sup_fare: "13255_24530",
                    sup_cur_fare: "",
                    cabb: "Chargeable",
                    chekb: "7kg INCLUDED",
                    meals: "Chargeable",
                    chanb: "Non Refundable",
                    ftyp: "LITE",
                    extra: "11_LITE",
                    chekbcode: "",
                    chanb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Change of Flight is Non-Refundable,NOSHOW From 0 hrs before  of departure Change of Flight is Non-Refundable",
                    canb: "Non Refundable",
                    cabb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Cancellation of Flight is Non-Refundable,NOSHOW From 0 hrs before  of departure Cancellation of Flight is Non-Refundable",
                    bagage_info: "Chargeable",
                    markupnames: [],
                    ttf: 24530,
                  },
                  paxpr: [
                    {
                      bf: 13255,
                      tf: 24530,
                      tax: [
                        { OT: 11275 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "OR6IN5",
                      ptype: "ADT",
                      scur: "INR",
                      extra:
                        "1_65_O_1_9_0_tx-2186:170:PZ:0_4246:6450:YQ:0_9327:970:K3:0_10386:120:ZR:0_11947:790:F6:0_12006:1170:P2:0_12434:1605:IN:0_",
                      ruleid:
                        "Qg+eKTd4dPZd9s8TS/BEVxM1YiBqo5VUngE+f6ZTlrcWhTrLfc+1HjPslNbsK6Ma+NzJ9vs0yTvfIGzHK+7a6QYdbLMNnyuqz+GQYY8X4ucqhXNHUPmc5WpT//+vZgmt",
                      hsc: "623ded28a2dab6d57fbc01182a2bedbbe24bc2044d19ddcb9e68c0ab534d0bc8",
                      sup_fare: "13255_24530_12805",
                      cabb: "Chargeable",
                      bagage_info: "Chargeable",
                      chekbcode: "",
                      psup_fare: "13255_24530",
                    },
                  ],
                  uniqueId: "LITE",
                  routeId: "3gobq4sg9oh",
                  servicefee: [],
                  selected: true,
                },
              ],
              tktair: "FZ",
              tecst: "no",
              cl: "Economy",
              extra: 13919296,
              token:
                "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiYXBpdGF3ZmVlcSIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL2dpdmVubmFtZSI6IkFQSSBUQVdGRUVRIiwiZ3JvdXBBY2Nlc3MiOiIzLDM2LDExMiIsImlzQXB0Q2xudCI6IkZhbHNlIiwibG9jYXRpb24iOiI5OTk5IiwiY2hrTGNuIjoiIiwicmVzVG9rZW4iOiJkZWRhZDdkeWY4N2FuOTc0dGJxYjY0YmJ6ZjZlOTYwYzA5ZTE5NmRhZTg2MCIsImNsaWVudElQIjoiMTcyLjE5LjIzLjU0IiwicmVzQ2hhbm5lbElEIjoiNSIsInJlc0NoYW5uZWxOYW1lIjoiVFBBUEkiLCJkZWZhdWx0VXNlck5hbWUiOiJhcGl0YXdmZWVxIiwibmJmIjoxNjg1NTE1MjM3LCJleHAiOjE2ODU1MTc2MzcsImlzcyI6ImZseWR1YmFpLmNvbSIsImF1ZCI6IlRBV0ZFRVFUUlZMU19GWl9OUCJ9.rAW3YT-bX_-yADfvg3QkuPAbOJhkhRUN6HwoS_6PyiM",
              stop: 1,
            },
            {
              tid: "tn4FQ8oMTUFcRpm9_1",
              ref: 1,
              office_id: "",
              sdur: "19:25",
              re: "",
              sup: "5",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DXB",
                  dd: "2023-06-07T03:00:00",
                  ad: "2023-06-07T05:30:00",
                  rbd: "",
                  fn: "436",
                  trn: "",
                  eq: "73B",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "", arr: "2" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161457,
                  flightTime: 240,
                  fdur: "4:0",
                },
                {
                  ref: 2,
                  da: "DXB",
                  ar: "DOH",
                  dd: "2023-06-07T19:45:00",
                  ad: "2023-06-07T19:55:00",
                  rbd: "",
                  fn: "005",
                  trn: "",
                  eq: "73X",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "2", arr: "1" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161381,
                  flightTime: 70,
                  fdur: "1:10",
                },
              ],
              fareop: [
                {
                  fareKey: [],
                  pr: {
                    bf: 13255,
                    tf: 24530,
                    tax: [
                      { OT: 11275 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    scur: "INR",
                    ucur: "INR",
                    exchange_rate: 1,
                    sup_fare: "13255_24530",
                    sup_cur_fare: "",
                    cabb: "Chargeable",
                    chekb: "7kg INCLUDED",
                    meals: "Chargeable",
                    chanb: "Non Refundable",
                    ftyp: "LITE",
                    extra: "11_LITE",
                    chekbcode: "",
                    chanb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Change of Flight is Non-Refundable,NOSHOW From 0 hrs before  of departure Change of Flight is Non-Refundable",
                    canb: "Non Refundable",
                    cabb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Cancellation of Flight is Non-Refundable,NOSHOW From 0 hrs before  of departure Cancellation of Flight is Non-Refundable",
                    bagage_info: "Chargeable",
                    markupnames: [],
                    ttf: 24530,
                  },
                  paxpr: [
                    {
                      bf: 13255,
                      tf: 24530,
                      tax: [
                        { OT: 11275 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "OR6IN5",
                      ptype: "ADT",
                      scur: "INR",
                      extra:
                        "1_97_O_1_9_0_tx-2186:170:PZ:0_4246:6450:YQ:0_9327:970:K3:0_10386:120:ZR:0_11947:790:F6:0_12006:1170:P2:0_12434:1605:IN:0_",
                      ruleid:
                        "KWAFkSsUmN9gGB7kqa69aBM1YiBqo5VUngE+f6ZTlrcWhTrLfc+1HjPslNbsK6Ma+NzJ9vs0yTvfIGzHK+7a6QYdbLMNnyuqz+GQYY8X4ucqhXNHUPmc5WpT//+vZgmt",
                      hsc: "ba480dc32b1abb4319ee1b67ecd7f9bf0702e8206a5265a815e937d2e7d77b68",
                      sup_fare: "13255_24530_12805",
                      cabb: "Chargeable",
                      bagage_info: "Chargeable",
                      chekbcode: "",
                      psup_fare: "13255_24530",
                    },
                  ],
                  uniqueId: "LITE",
                  routeId: "uup0qowzn5",
                  servicefee: [],
                  selected: true,
                },
              ],
              tktair: "FZ",
              tecst: "no",
              cl: "Economy",
              extra: 13919294,
              token:
                "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiYXBpdGF3ZmVlcSIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL2dpdmVubmFtZSI6IkFQSSBUQVdGRUVRIiwiZ3JvdXBBY2Nlc3MiOiIzLDM2LDExMiIsImlzQXB0Q2xudCI6IkZhbHNlIiwibG9jYXRpb24iOiI5OTk5IiwiY2hrTGNuIjoiIiwicmVzVG9rZW4iOiJkZWRhZDdkeWY4N2FuOTc0dGJxYjY0YmJ6ZjZlOTYwYzA5ZTE5NmRhZTg2MCIsImNsaWVudElQIjoiMTcyLjE5LjIzLjU0IiwicmVzQ2hhbm5lbElEIjoiNSIsInJlc0NoYW5uZWxOYW1lIjoiVFBBUEkiLCJkZWZhdWx0VXNlck5hbWUiOiJhcGl0YXdmZWVxIiwibmJmIjoxNjg1NTE1MjM3LCJleHAiOjE2ODU1MTc2MzcsImlzcyI6ImZseWR1YmFpLmNvbSIsImF1ZCI6IlRBV0ZFRVFUUlZMU19GWl9OUCJ9.rAW3YT-bX_-yADfvg3QkuPAbOJhkhRUN6HwoS_6PyiM",
              stop: 1,
            },
          ],
          paxpr: [
            {
              bf: 26510,
              tf: 49255,
              tax: [
                { OT: 22745 },
                { attmkp: 0 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              fbc: "OR6IN5",
              ptype: "ADT",
              scur: "INR",
              extra:
                "1_1_O_1_9_0_tx-2186:170:PZ:0_4246:6450:YQ:0_9327:970:K3:0_10386:120:ZR:0_11947:790:F6:0_12006:1170:P2:0_12434:1605:IN:0_",
              ruleid:
                "LSX/QbpvmKR/hVWmjOqJj4CMSUE+oAXNhfzC1AvPpsdXVy0HLLTzOxy6vo4KsNKyH4iOhL3qCTzeoIR/oeSzY4TEtPO2CThrAiJMBJQHPOaxndbba/gOeDLph5SLjZ1F",
              hsc: "1fbde0529fa8278a164e6190fc1fd47a2cd0ea34e01ac12c2a8368e8eeed3cf2",
              sup_fare: "13255_24530_12805",
              cabb: "Chargeable",
              bagage_info: "Chargeable",
              chekbcode: "",
              psup_fare: "26510_49255",
            },
          ],
          pr: {
            bf: 26510,
            tf: 49255,
            tax: [
              { OT: 22745 },
              { attmkp: 0 },
              { tamkp: 0 },
              { stamkp: 0 },
              { attdisc: 0 },
              { tadisc: 0 },
              { stadisc: 0 },
            ],
            scur: "INR",
            ucur: "INR",
            exchange_rate: 1,
            sup_fare: "26510_49255",
            sup_cur_fare: "",
            cabb: "Chargeable",
            chekb: "7kg INCLUDED",
            meals: "Chargeable",
            chanb: "Non Refundable",
            ftyp: "LITE",
            extra: "11_LITE",
            chekbcode: "",
            chanb_info:
              "BEFOREDEPARTURE 0 hrs of departure Change of Flight is Non-Refundable,NOSHOW From 0 hrs before  of departure Change of Flight is Non-Refundable",
            canb: "Non Refundable",
            cabb_info:
              "BEFOREDEPARTURE 0 hrs of departure Cancellation of Flight is Non-Refundable,NOSHOW From 0 hrs before  of departure Cancellation of Flight is Non-Refundable",
            bagage_info: "Chargeable",
            markupnames: [],
            ttf: 49255,
          },
          sup: "5",
          airlineList: "FZ",
          destorigins: [
            {
              tid: "DzZLUGdrloHT2qIh_2",
              ref: 2,
              office_id: "",
              sdur: "6:45",
              re: "",
              sup: "5",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DXB",
                  dd: "2023-06-09T17:00:00",
                  ad: "2023-06-09T19:15:00",
                  rbd: "",
                  fn: "018",
                  trn: "",
                  eq: "73X",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "1", arr: "2" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161399,
                  flightTime: 75,
                  fdur: "1:15",
                },
                {
                  ref: 2,
                  da: "DXB",
                  ar: "HYD",
                  dd: "2023-06-09T20:50:00",
                  ad: "2023-06-10T02:15:00",
                  rbd: "",
                  fn: "435",
                  trn: "",
                  eq: "73B",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "2", arr: "" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161456,
                  flightTime: 235,
                  fdur: "3:55",
                },
              ],
              fareop: [
                {
                  fareKey: [],
                  pr: {
                    bf: 13255,
                    tf: 24725,
                    tax: [
                      { OT: 11470 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    scur: "INR",
                    ucur: "INR",
                    exchange_rate: 1,
                    sup_fare: "13255_24725",
                    sup_cur_fare: "",
                    cabb: "Chargeable",
                    chekb: "7kg INCLUDED",
                    meals: "Chargeable",
                    chanb: "Non Refundable",
                    ftyp: "LITE",
                    extra: "11_LITE",
                    chekbcode: "",
                    chanb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Change of Flight is Non-Refundable,NOSHOW From 0 hrs before  of departure Change of Flight is Non-Refundable",
                    canb: "Non Refundable",
                    cabb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Cancellation of Flight is Non-Refundable,NOSHOW From 0 hrs before  of departure Cancellation of Flight is Non-Refundable",
                    bagage_info: "Chargeable",
                    markupnames: [],
                    ttf: 24725,
                  },
                  paxpr: [
                    {
                      bf: 13255,
                      tf: 24725,
                      tax: [
                        { OT: 11470 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "OR6IN5",
                      ptype: "ADT",
                      scur: "INR",
                      extra:
                        "1_2_O_1_9_0_tx-1764:1370:QA:0_2186:170:PZ:0_4246:6450:YQ:0_8966:1370:G4:0_9327:970:K3:0_10386:120:ZR:0_11947:790:F6:0_13230:230:R9:0_",
                      ruleid:
                        "bh7G8Z5aRG9G3eIfP4gSaSH5cdRtjzMqmtc6Ydt53ymhQozXlONozKDeF7aO/DbGAO8K6tXzJ16Rfs5fUX41PoAzc0DAexpKMcT6yt4vWi67ueiX+7Xh5u3qyxSJhMOZBQGHoM3lqYcsurCRq3PlUw==",
                      hsc: "c2f2853cbb12147494dff4134ba9572224989b4adf96db59caffcaa5a4f38def",
                      sup_fare: "13255_24725_12805",
                      cabb: "Chargeable",
                      bagage_info: "Chargeable",
                      chekbcode: "",
                      psup_fare: "13255_24725",
                    },
                  ],
                  uniqueId: "LITE",
                  routeId: "co2frmft9gl",
                  servicefee: [],
                  selected: true,
                },
              ],
              tktair: "FZ",
              tecst: "no",
              cl: "Economy",
              extra: 13916393,
              token:
                "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiYXBpdGF3ZmVlcSIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL2dpdmVubmFtZSI6IkFQSSBUQVdGRUVRIiwiZ3JvdXBBY2Nlc3MiOiIzLDM2LDExMiIsImlzQXB0Q2xudCI6IkZhbHNlIiwibG9jYXRpb24iOiI5OTk5IiwiY2hrTGNuIjoiIiwicmVzVG9rZW4iOiJkZWRhZDdkeWY4N2FuOTc0dGJxYjY0YmJ6ZjZlOTYwYzA5ZTE5NmRhZTg2MCIsImNsaWVudElQIjoiMTcyLjE5LjIzLjU0IiwicmVzQ2hhbm5lbElEIjoiNSIsInJlc0NoYW5uZWxOYW1lIjoiVFBBUEkiLCJkZWZhdWx0VXNlck5hbWUiOiJhcGl0YXdmZWVxIiwibmJmIjoxNjg1NTE1MjM3LCJleHAiOjE2ODU1MTc2MzcsImlzcyI6ImZseWR1YmFpLmNvbSIsImF1ZCI6IlRBV0ZFRVFUUlZMU19GWl9OUCJ9.rAW3YT-bX_-yADfvg3QkuPAbOJhkhRUN6HwoS_6PyiM",
              stop: 1,
            },
            {
              tid: "S8t3EyTcABVMmTCg_2",
              ref: 2,
              office_id: "",
              sdur: "13:45",
              re: "",
              sup: "5",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DXB",
                  dd: "2023-06-09T10:00:00",
                  ad: "2023-06-09T12:15:00",
                  rbd: "",
                  fn: "002",
                  trn: "",
                  eq: "73X",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "1", arr: "2" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161402,
                  flightTime: 75,
                  fdur: "1:15",
                },
                {
                  ref: 2,
                  da: "DXB",
                  ar: "HYD",
                  dd: "2023-06-09T20:50:00",
                  ad: "2023-06-10T02:15:00",
                  rbd: "",
                  fn: "435",
                  trn: "",
                  eq: "73B",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "2", arr: "" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161456,
                  flightTime: 235,
                  fdur: "3:55",
                },
              ],
              fareop: [
                {
                  fareKey: [],
                  pr: {
                    bf: 13255,
                    tf: 24725,
                    tax: [
                      { OT: 11470 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    scur: "INR",
                    ucur: "INR",
                    exchange_rate: 1,
                    sup_fare: "13255_24725",
                    sup_cur_fare: "",
                    cabb: "Chargeable",
                    chekb: "7kg INCLUDED",
                    meals: "Chargeable",
                    chanb: "Non Refundable",
                    ftyp: "LITE",
                    extra: "11_LITE",
                    chekbcode: "",
                    chanb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Change of Flight is Non-Refundable,NOSHOW From 0 hrs before  of departure Change of Flight is Non-Refundable",
                    canb: "Non Refundable",
                    cabb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Cancellation of Flight is Non-Refundable,NOSHOW From 0 hrs before  of departure Cancellation of Flight is Non-Refundable",
                    bagage_info: "Chargeable",
                    markupnames: [],
                    ttf: 24725,
                  },
                  paxpr: [
                    {
                      bf: 13255,
                      tf: 24725,
                      tax: [
                        { OT: 11470 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "OR6IN5",
                      ptype: "ADT",
                      scur: "INR",
                      extra:
                        "1_34_O_1_9_0_tx-1764:1370:QA:0_2186:170:PZ:0_4246:6450:YQ:0_8966:1370:G4:0_9327:970:K3:0_10386:120:ZR:0_11947:790:F6:0_13230:230:R9:0_",
                      ruleid:
                        "Fks/Sl8AzgbFVpq/bLTLmGDGJNH37aAsxUou/hEUve1ewJ9aVhK57wibWY2PIn24f4tv7xijw4oXgcRHgmFw0E3Dg72VJaVpJZuTCj214MZgK98nMtGteAZSZm1qHO8JeXH1hDuwDQ+MlHme53FHEA==",
                      hsc: "ab4b91a4fd56bb6ad04b38f7b92de2808353863da925a1b478e90343a80800af",
                      sup_fare: "13255_24725_12805",
                      cabb: "Chargeable",
                      bagage_info: "Chargeable",
                      chekbcode: "",
                      psup_fare: "13255_24725",
                    },
                  ],
                  uniqueId: "LITE",
                  routeId: "o7ew7eh358",
                  servicefee: [],
                  selected: true,
                },
              ],
              tktair: "FZ",
              tecst: "no",
              cl: "Economy",
              extra: 13915297,
              token:
                "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiYXBpdGF3ZmVlcSIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL2dpdmVubmFtZSI6IkFQSSBUQVdGRUVRIiwiZ3JvdXBBY2Nlc3MiOiIzLDM2LDExMiIsImlzQXB0Q2xudCI6IkZhbHNlIiwibG9jYXRpb24iOiI5OTk5IiwiY2hrTGNuIjoiIiwicmVzVG9rZW4iOiJkZWRhZDdkeWY4N2FuOTc0dGJxYjY0YmJ6ZjZlOTYwYzA5ZTE5NmRhZTg2MCIsImNsaWVudElQIjoiMTcyLjE5LjIzLjU0IiwicmVzQ2hhbm5lbElEIjoiNSIsInJlc0NoYW5uZWxOYW1lIjoiVFBBUEkiLCJkZWZhdWx0VXNlck5hbWUiOiJhcGl0YXdmZWVxIiwibmJmIjoxNjg1NTE1MjM3LCJleHAiOjE2ODU1MTc2MzcsImlzcyI6ImZseWR1YmFpLmNvbSIsImF1ZCI6IlRBV0ZFRVFUUlZMU19GWl9OUCJ9.rAW3YT-bX_-yADfvg3QkuPAbOJhkhRUN6HwoS_6PyiM",
              stop: 1,
            },
          ],
          servicefee: [],
        },
        {
          srcorigns: [
            {
              tid: "a5CesP1zKNvGsfBx_1",
              ref: 1,
              office_id: "",
              sdur: "12:0",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DEL",
                  dd: "2023-06-07T11:45:00",
                  ad: "2023-06-07T14:05:00",
                  rbd: "",
                  fn: "2171",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "2" },
                  rph: "",
                  jsell:
                    "6E~2171~ ~~HYD~06/07/2023 11:45~DEL~06/07/2023 14:05~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-06-07T22:10:00",
                  ad: "2023-06-07T23:45:00",
                  rbd: "",
                  fn: "1311",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "3" },
                  rph: "",
                  jsell:
                    "6E~1311~ ~~DEL~06/07/2023 22:10~DOH~06/07/2023 23:45~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 30621,
                      fbc: "WCINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "30621_36271",
                      tax: [
                        { OT: 5650 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 36271,
                      psup_fare: "30621_36271",
                    },
                  ],
                  pr: {
                    bf: 30621,
                    tf: 36271,
                    tax: [
                      { OT: 5650 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "30621_36271",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 36271,
                  },
                  routeId: "30wp3zl9au",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 31321,
                      fbc: "WCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "31321_37031",
                      tax: [
                        { OT: 5710 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 37031,
                      psup_fare: "31321_37031",
                    },
                  ],
                  pr: {
                    bf: 31321,
                    tf: 37031,
                    tax: [
                      { OT: 5710 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "31321_37031",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 37031,
                  },
                  routeId: "npm5huw8bc",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "G79e0AHvUTs=|pfN7IeMVY9crZtLzdrvIzm0oZntQ6f3741aeYn1CvdMLxX16D05jLOkRUUokspRUNBaad/QhuW2NmBov2F0uAOs5rQ6LmyiTG2kKghIPh0qz95QB/MyFDxPn3z3uu1Sj7jxXZQa3gsE=",
              stop: 1,
            },
            {
              tid: "TIfAqJu4CesTyXNT_1",
              ref: 1,
              office_id: "",
              sdur: "5:15",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "BOM",
                  dd: "2023-06-07T13:30:00",
                  ad: "2023-06-07T15:05:00",
                  rbd: "",
                  fn: "6699",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "2" },
                  rph: "",
                  jsell:
                    "6E~6699~ ~~HYD~06/07/2023 13:30~BOM~06/07/2023 15:05~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
                {
                  ref: 2,
                  da: "BOM",
                  ar: "DOH",
                  dd: "2023-06-07T17:50:00",
                  ad: "2023-06-07T18:45:00",
                  rbd: "",
                  fn: "1305",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "2" },
                  rph: "",
                  jsell:
                    "6E~1305~ ~~BOM~06/07/2023 17:50~DOH~06/07/2023 18:45~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 30621,
                      fbc: "WCINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "30621_36271",
                      tax: [
                        { OT: 5650 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 36271,
                      psup_fare: "30621_36271",
                    },
                  ],
                  pr: {
                    bf: 30621,
                    tf: 36271,
                    tax: [
                      { OT: 5650 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "30621_36271",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 36271,
                  },
                  routeId: "ufbh0pozdm",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 31321,
                      fbc: "WCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "31321_37031",
                      tax: [
                        { OT: 5710 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 37031,
                      psup_fare: "31321_37031",
                    },
                  ],
                  pr: {
                    bf: 31321,
                    tf: 37031,
                    tax: [
                      { OT: 5710 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "31321_37031",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 37031,
                  },
                  routeId: "dsz2bxobkj",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "G79e0AHvUTs=|pfN7IeMVY9crZtLzdrvIzm0oZntQ6f3741aeYn1CvdMLxX16D05jLOkRUUokspRUNBaad/QhuW2NmBov2F0uAOs5rQ6LmyiTG2kKghIPh0qz95QB/MyFDxPn3z3uu1Sj7jxXZQa3gsE=",
              stop: 1,
            },
            {
              tid: "tToTwzxfdFH59p9E_1",
              ref: 1,
              office_id: "",
              sdur: "15:15",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DEL",
                  dd: "2023-06-07T14:25:00",
                  ad: "2023-06-07T16:45:00",
                  rbd: "",
                  fn: "2063",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "2" },
                  rph: "",
                  jsell:
                    "6E~2063~ ~~HYD~06/07/2023 14:25~DEL~06/07/2023 16:45~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-06-08T04:05:00",
                  ad: "2023-06-08T05:40:00",
                  rbd: "",
                  fn: "1307",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "3" },
                  rph: "",
                  jsell:
                    "6E~1307~ ~~DEL~06/08/2023 04:05~DOH~06/08/2023 05:40~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 30621,
                      fbc: "WCINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "30621_36271",
                      tax: [
                        { OT: 5650 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 36271,
                      psup_fare: "30621_36271",
                    },
                  ],
                  pr: {
                    bf: 30621,
                    tf: 36271,
                    tax: [
                      { OT: 5650 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "30621_36271",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 36271,
                  },
                  routeId: "y3wvo2pjcj",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 31321,
                      fbc: "WCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "31321_37031",
                      tax: [
                        { OT: 5710 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 37031,
                      psup_fare: "31321_37031",
                    },
                  ],
                  pr: {
                    bf: 31321,
                    tf: 37031,
                    tax: [
                      { OT: 5710 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "31321_37031",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 37031,
                  },
                  routeId: "c8umvb2jge",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "G79e0AHvUTs=|pfN7IeMVY9crZtLzdrvIzm0oZntQ6f3741aeYn1CvdMLxX16D05jLOkRUUokspRUNBaad/QhuW2NmBov2F0uAOs5rQ6LmyiTG2kKghIPh0qz95QB/MyFDxPn3z3uu1Sj7jxXZQa3gsE=",
              stop: 1,
            },
            {
              tid: "1DQMyx5FiT2cltWN_1",
              ref: 1,
              office_id: "",
              sdur: "9:20",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DEL",
                  dd: "2023-06-07T14:25:00",
                  ad: "2023-06-07T16:45:00",
                  rbd: "",
                  fn: "2063",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "2" },
                  rph: "",
                  jsell:
                    "6E~2063~ ~~HYD~06/07/2023 14:25~DEL~06/07/2023 16:45~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-06-07T22:10:00",
                  ad: "2023-06-07T23:45:00",
                  rbd: "",
                  fn: "1311",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "3" },
                  rph: "",
                  jsell:
                    "6E~1311~ ~~DEL~06/07/2023 22:10~DOH~06/07/2023 23:45~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 30621,
                      fbc: "WCINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "30621_36271",
                      tax: [
                        { OT: 5650 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 36271,
                      psup_fare: "30621_36271",
                    },
                  ],
                  pr: {
                    bf: 30621,
                    tf: 36271,
                    tax: [
                      { OT: 5650 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "30621_36271",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 36271,
                  },
                  routeId: "7bki6fw2b8",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 31321,
                      fbc: "WCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "31321_37031",
                      tax: [
                        { OT: 5710 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 37031,
                      psup_fare: "31321_37031",
                    },
                  ],
                  pr: {
                    bf: 31321,
                    tf: 37031,
                    tax: [
                      { OT: 5710 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "31321_37031",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 37031,
                  },
                  routeId: "vv9xd2zv01",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "G79e0AHvUTs=|pfN7IeMVY9crZtLzdrvIzm0oZntQ6f3741aeYn1CvdMLxX16D05jLOkRUUokspRUNBaad/QhuW2NmBov2F0uAOs5rQ6LmyiTG2kKghIPh0qz95QB/MyFDxPn3z3uu1Sj7jxXZQa3gsE=",
              stop: 1,
            },
            {
              tid: "D6SWXoHLx9bJAKcT_1",
              ref: 1,
              office_id: "",
              sdur: "7:15",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DEL",
                  dd: "2023-06-07T16:30:00",
                  ad: "2023-06-07T18:50:00",
                  rbd: "",
                  fn: "6606",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "1" },
                  rph: "",
                  jsell:
                    "6E~6606~ ~~HYD~06/07/2023 16:30~DEL~06/07/2023 18:50~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-06-07T22:10:00",
                  ad: "2023-06-07T23:45:00",
                  rbd: "",
                  fn: "1311",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "3" },
                  rph: "",
                  jsell:
                    "6E~1311~ ~~DEL~06/07/2023 22:10~DOH~06/07/2023 23:45~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 30621,
                      fbc: "WCINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "30621_36271",
                      tax: [
                        { OT: 5650 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 36271,
                      psup_fare: "30621_36271",
                    },
                  ],
                  pr: {
                    bf: 30621,
                    tf: 36271,
                    tax: [
                      { OT: 5650 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "30621_36271",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 36271,
                  },
                  routeId: "o7ivp9n7ss",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 31321,
                      fbc: "WCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "31321_37031",
                      tax: [
                        { OT: 5710 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 37031,
                      psup_fare: "31321_37031",
                    },
                  ],
                  pr: {
                    bf: 31321,
                    tf: 37031,
                    tax: [
                      { OT: 5710 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "31321_37031",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 37031,
                  },
                  routeId: "3u8045ri6c",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "G79e0AHvUTs=|pfN7IeMVY9crZtLzdrvIzm0oZntQ6f3741aeYn1CvdMLxX16D05jLOkRUUokspRUNBaad/QhuW2NmBov2F0uAOs5rQ6LmyiTG2kKghIPh0qz95QB/MyFDxPn3z3uu1Sj7jxXZQa3gsE=",
              stop: 1,
            },
            {
              tid: "pP6F0HF53D272D6A_1",
              ref: 1,
              office_id: "",
              sdur: "13:10",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DEL",
                  dd: "2023-06-07T16:30:00",
                  ad: "2023-06-07T18:50:00",
                  rbd: "",
                  fn: "6606",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "1" },
                  rph: "",
                  jsell:
                    "6E~6606~ ~~HYD~06/07/2023 16:30~DEL~06/07/2023 18:50~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-06-08T04:05:00",
                  ad: "2023-06-08T05:40:00",
                  rbd: "",
                  fn: "1307",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "3" },
                  rph: "",
                  jsell:
                    "6E~1307~ ~~DEL~06/08/2023 04:05~DOH~06/08/2023 05:40~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 30621,
                      fbc: "WCINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "30621_36271",
                      tax: [
                        { OT: 5650 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 36271,
                      psup_fare: "30621_36271",
                    },
                  ],
                  pr: {
                    bf: 30621,
                    tf: 36271,
                    tax: [
                      { OT: 5650 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "30621_36271",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 36271,
                  },
                  routeId: "f4wnkj6q3r",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 31321,
                      fbc: "WCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "31321_37031",
                      tax: [
                        { OT: 5710 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 37031,
                      psup_fare: "31321_37031",
                    },
                  ],
                  pr: {
                    bf: 31321,
                    tf: 37031,
                    tax: [
                      { OT: 5710 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "31321_37031",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 37031,
                  },
                  routeId: "lz0egg7p01",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "G79e0AHvUTs=|pfN7IeMVY9crZtLzdrvIzm0oZntQ6f3741aeYn1CvdMLxX16D05jLOkRUUokspRUNBaad/QhuW2NmBov2F0uAOs5rQ6LmyiTG2kKghIPh0qz95QB/MyFDxPn3z3uu1Sj7jxXZQa3gsE=",
              stop: 1,
            },
            {
              tid: "2CGM5Huo1gA36C2C_1",
              ref: 1,
              office_id: "",
              sdur: "11:25",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DEL",
                  dd: "2023-06-07T18:15:00",
                  ad: "2023-06-07T20:30:00",
                  rbd: "",
                  fn: "2003",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "2" },
                  rph: "",
                  jsell:
                    "6E~2003~ ~~HYD~06/07/2023 18:15~DEL~06/07/2023 20:30~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-06-08T04:05:00",
                  ad: "2023-06-08T05:40:00",
                  rbd: "",
                  fn: "1307",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "3" },
                  rph: "",
                  jsell:
                    "6E~1307~ ~~DEL~06/08/2023 04:05~DOH~06/08/2023 05:40~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 30621,
                      fbc: "WCINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "30621_36271",
                      tax: [
                        { OT: 5650 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 36271,
                      psup_fare: "30621_36271",
                    },
                  ],
                  pr: {
                    bf: 30621,
                    tf: 36271,
                    tax: [
                      { OT: 5650 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "30621_36271",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 36271,
                  },
                  routeId: "5t6toxlx0s",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 31321,
                      fbc: "WCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "31321_37031",
                      tax: [
                        { OT: 5710 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 37031,
                      psup_fare: "31321_37031",
                    },
                  ],
                  pr: {
                    bf: 31321,
                    tf: 37031,
                    tax: [
                      { OT: 5710 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "31321_37031",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 37031,
                  },
                  routeId: "0j2nwjf37r",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "G79e0AHvUTs=|pfN7IeMVY9crZtLzdrvIzm0oZntQ6f3741aeYn1CvdMLxX16D05jLOkRUUokspRUNBaad/QhuW2NmBov2F0uAOs5rQ6LmyiTG2kKghIPh0qz95QB/MyFDxPn3z3uu1Sj7jxXZQa3gsE=",
              stop: 1,
            },
            {
              tid: "ocWP46f6GnW3GLfd_1",
              ref: 1,
              office_id: "",
              sdur: "5:30",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DEL",
                  dd: "2023-06-07T18:15:00",
                  ad: "2023-06-07T20:30:00",
                  rbd: "",
                  fn: "2003",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "2" },
                  rph: "",
                  jsell:
                    "6E~2003~ ~~HYD~06/07/2023 18:15~DEL~06/07/2023 20:30~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-06-07T22:10:00",
                  ad: "2023-06-07T23:45:00",
                  rbd: "",
                  fn: "1311",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "3" },
                  rph: "",
                  jsell:
                    "6E~1311~ ~~DEL~06/07/2023 22:10~DOH~06/07/2023 23:45~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 30621,
                      fbc: "WCINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "30621_36271",
                      tax: [
                        { OT: 5650 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 36271,
                      psup_fare: "30621_36271",
                    },
                  ],
                  pr: {
                    bf: 30621,
                    tf: 36271,
                    tax: [
                      { OT: 5650 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "30621_36271",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 36271,
                  },
                  routeId: "0uf2fscask",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 31321,
                      fbc: "WCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "31321_37031",
                      tax: [
                        { OT: 5710 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 37031,
                      psup_fare: "31321_37031",
                    },
                  ],
                  pr: {
                    bf: 31321,
                    tf: 37031,
                    tax: [
                      { OT: 5710 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "31321_37031",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 37031,
                  },
                  routeId: "jhzb7sndp8g",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "G79e0AHvUTs=|pfN7IeMVY9crZtLzdrvIzm0oZntQ6f3741aeYn1CvdMLxX16D05jLOkRUUokspRUNBaad/QhuW2NmBov2F0uAOs5rQ6LmyiTG2kKghIPh0qz95QB/MyFDxPn3z3uu1Sj7jxXZQa3gsE=",
              stop: 1,
            },
            {
              tid: "WRuQLhvRQVO0HFpL_1",
              ref: 1,
              office_id: "",
              sdur: "9:20",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DEL",
                  dd: "2023-06-07T20:20:00",
                  ad: "2023-06-07T22:30:00",
                  rbd: "",
                  fn: "2213",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "2" },
                  rph: "",
                  jsell:
                    "6E~2213~ ~~HYD~06/07/2023 20:20~DEL~06/07/2023 22:30~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-06-08T04:05:00",
                  ad: "2023-06-08T05:40:00",
                  rbd: "",
                  fn: "1307",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "3" },
                  rph: "",
                  jsell:
                    "6E~1307~ ~~DEL~06/08/2023 04:05~DOH~06/08/2023 05:40~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 30621,
                      fbc: "WCINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "30621_36271",
                      tax: [
                        { OT: 5650 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 36271,
                      psup_fare: "30621_36271",
                    },
                  ],
                  pr: {
                    bf: 30621,
                    tf: 36271,
                    tax: [
                      { OT: 5650 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "30621_36271",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 36271,
                  },
                  routeId: "qsmog2w3kpg",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 31321,
                      fbc: "WCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "31321_37031",
                      tax: [
                        { OT: 5710 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 37031,
                      psup_fare: "31321_37031",
                    },
                  ],
                  pr: {
                    bf: 31321,
                    tf: 37031,
                    tax: [
                      { OT: 5710 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "31321_37031",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 37031,
                  },
                  routeId: "t7roy5hp1e",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "G79e0AHvUTs=|pfN7IeMVY9crZtLzdrvIzm0oZntQ6f3741aeYn1CvdMLxX16D05jLOkRUUokspRUNBaad/QhuW2NmBov2F0uAOs5rQ6LmyiTG2kKghIPh0qz95QB/MyFDxPn3z3uu1Sj7jxXZQa3gsE=",
              stop: 1,
            },
            {
              tid: "CT1UOwDdbTcAgBsp_1",
              ref: 1,
              office_id: "",
              sdur: "8:55",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "BOM",
                  dd: "2023-06-07T20:50:00",
                  ad: "2023-06-07T22:05:00",
                  rbd: "",
                  fn: "5389",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "1" },
                  rph: "",
                  jsell:
                    "6E~5389~ ~~HYD~06/07/2023 20:50~BOM~06/07/2023 22:05~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
                {
                  ref: 2,
                  da: "BOM",
                  ar: "DOH",
                  dd: "2023-06-08T04:50:00",
                  ad: "2023-06-08T05:45:00",
                  rbd: "",
                  fn: "1303",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "2" },
                  rph: "",
                  jsell:
                    "6E~1303~ ~~BOM~06/08/2023 04:50~DOH~06/08/2023 05:45~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 30621,
                      fbc: "WCINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "30621_36271",
                      tax: [
                        { OT: 5650 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 36271,
                      psup_fare: "30621_36271",
                    },
                  ],
                  pr: {
                    bf: 30621,
                    tf: 36271,
                    tax: [
                      { OT: 5650 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "30621_36271",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 36271,
                  },
                  routeId: "dt7pfurjx5f",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 31321,
                      fbc: "WCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "31321_37031",
                      tax: [
                        { OT: 5710 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 37031,
                      psup_fare: "31321_37031",
                    },
                  ],
                  pr: {
                    bf: 31321,
                    tf: 37031,
                    tax: [
                      { OT: 5710 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "31321_37031",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 37031,
                  },
                  routeId: "qmrzv7q8xu",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "G79e0AHvUTs=|pfN7IeMVY9crZtLzdrvIzm0oZntQ6f3741aeYn1CvdMLxX16D05jLOkRUUokspRUNBaad/QhuW2NmBov2F0uAOs5rQ6LmyiTG2kKghIPh0qz95QB/MyFDxPn3z3uu1Sj7jxXZQa3gsE=",
              stop: 1,
            },
            {
              tid: "VKRZXNeNDeRF7kxH_1",
              ref: 1,
              office_id: "",
              sdur: "8:10",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "BOM",
                  dd: "2023-06-07T21:35:00",
                  ad: "2023-06-07T23:05:00",
                  rbd: "",
                  fn: "6952",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "2" },
                  rph: "",
                  jsell:
                    "6E~6952~ ~~HYD~06/07/2023 21:35~BOM~06/07/2023 23:05~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
                {
                  ref: 2,
                  da: "BOM",
                  ar: "DOH",
                  dd: "2023-06-08T04:50:00",
                  ad: "2023-06-08T05:45:00",
                  rbd: "",
                  fn: "1303",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "2" },
                  rph: "",
                  jsell:
                    "6E~1303~ ~~BOM~06/08/2023 04:50~DOH~06/08/2023 05:45~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 30621,
                      fbc: "WCINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "30621_36271",
                      tax: [
                        { OT: 5650 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 36271,
                      psup_fare: "30621_36271",
                    },
                  ],
                  pr: {
                    bf: 30621,
                    tf: 36271,
                    tax: [
                      { OT: 5650 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "30621_36271",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 36271,
                  },
                  routeId: "b95c6hcuzc",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 31321,
                      fbc: "WCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "31321_37031",
                      tax: [
                        { OT: 5710 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 37031,
                      psup_fare: "31321_37031",
                    },
                  ],
                  pr: {
                    bf: 31321,
                    tf: 37031,
                    tax: [
                      { OT: 5710 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "31321_37031",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 37031,
                  },
                  routeId: "qzlsdrsrjlj",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "G79e0AHvUTs=|pfN7IeMVY9crZtLzdrvIzm0oZntQ6f3741aeYn1CvdMLxX16D05jLOkRUUokspRUNBaad/QhuW2NmBov2F0uAOs5rQ6LmyiTG2kKghIPh0qz95QB/MyFDxPn3z3uu1Sj7jxXZQa3gsE=",
              stop: 1,
            },
            {
              tid: "70lO61PBk7vNpiUI_1",
              ref: 1,
              office_id: "",
              sdur: "7:45",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DEL",
                  dd: "2023-06-07T21:55:00",
                  ad: "2023-06-08T00:05:00",
                  rbd: "",
                  fn: "5605",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "3" },
                  rph: "",
                  jsell:
                    "6E~5605~ ~~HYD~06/07/2023 21:55~DEL~06/08/2023 00:05~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-06-08T04:05:00",
                  ad: "2023-06-08T05:40:00",
                  rbd: "",
                  fn: "1307",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "3" },
                  rph: "",
                  jsell:
                    "6E~1307~ ~~DEL~06/08/2023 04:05~DOH~06/08/2023 05:40~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 30621,
                      fbc: "WCINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "30621_36271",
                      tax: [
                        { OT: 5650 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 36271,
                      psup_fare: "30621_36271",
                    },
                  ],
                  pr: {
                    bf: 30621,
                    tf: 36271,
                    tax: [
                      { OT: 5650 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "30621_36271",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 36271,
                  },
                  routeId: "tgvm7n5rx2g",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 31321,
                      fbc: "WCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "31321_37031",
                      tax: [
                        { OT: 5710 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 37031,
                      psup_fare: "31321_37031",
                    },
                  ],
                  pr: {
                    bf: 31321,
                    tf: 37031,
                    tax: [
                      { OT: 5710 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "31321_37031",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 37031,
                  },
                  routeId: "hkdho3kbue",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "G79e0AHvUTs=|pfN7IeMVY9crZtLzdrvIzm0oZntQ6f3741aeYn1CvdMLxX16D05jLOkRUUokspRUNBaad/QhuW2NmBov2F0uAOs5rQ6LmyiTG2kKghIPh0qz95QB/MyFDxPn3z3uu1Sj7jxXZQa3gsE=",
              stop: 1,
            },
            {
              tid: "K7agCFQLRc0RQ21e_1",
              ref: 1,
              office_id: "",
              sdur: "7:5",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "BOM",
                  dd: "2023-06-07T22:40:00",
                  ad: "2023-06-08T00:15:00",
                  rbd: "",
                  fn: " 267",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "2" },
                  rph: "",
                  jsell:
                    "6E~ 267~ ~~HYD~06/07/2023 22:40~BOM~06/08/2023 00:15~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
                {
                  ref: 2,
                  da: "BOM",
                  ar: "DOH",
                  dd: "2023-06-08T04:50:00",
                  ad: "2023-06-08T05:45:00",
                  rbd: "",
                  fn: "1303",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "2" },
                  rph: "",
                  jsell:
                    "6E~1303~ ~~BOM~06/08/2023 04:50~DOH~06/08/2023 05:45~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 30621,
                      fbc: "WCINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "30621_36271",
                      tax: [
                        { OT: 5650 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 36271,
                      psup_fare: "30621_36271",
                    },
                  ],
                  pr: {
                    bf: 30621,
                    tf: 36271,
                    tax: [
                      { OT: 5650 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "30621_36271",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 36271,
                  },
                  routeId: "zgnk3vqibb",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 31321,
                      fbc: "WCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "31321_37031",
                      tax: [
                        { OT: 5710 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 37031,
                      psup_fare: "31321_37031",
                    },
                  ],
                  pr: {
                    bf: 31321,
                    tf: 37031,
                    tax: [
                      { OT: 5710 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "31321_37031",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 37031,
                  },
                  routeId: "op0yg8zeuq",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "G79e0AHvUTs=|pfN7IeMVY9crZtLzdrvIzm0oZntQ6f3741aeYn1CvdMLxX16D05jLOkRUUokspRUNBaad/QhuW2NmBov2F0uAOs5rQ6LmyiTG2kKghIPh0qz95QB/MyFDxPn3z3uu1Sj7jxXZQa3gsE=",
              stop: 1,
            },
            {
              tid: "5hotdkTEUhd3ta0F_1",
              ref: 1,
              office_id: "",
              sdur: "6:55",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DEL",
                  dd: "2023-06-07T22:45:00",
                  ad: "2023-06-08T01:00:00",
                  rbd: "",
                  fn: "6146",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "1" },
                  rph: "",
                  jsell:
                    "6E~6146~ ~~HYD~06/07/2023 22:45~DEL~06/08/2023 01:00~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "DOH",
                  dd: "2023-06-08T04:05:00",
                  ad: "2023-06-08T05:40:00",
                  rbd: "",
                  fn: "1307",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "3" },
                  rph: "",
                  jsell:
                    "6E~1307~ ~~DEL~06/08/2023 04:05~DOH~06/08/2023 05:40~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 30621,
                      fbc: "WCINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "30621_36271",
                      tax: [
                        { OT: 5650 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 36271,
                      psup_fare: "30621_36271",
                    },
                  ],
                  pr: {
                    bf: 30621,
                    tf: 36271,
                    tax: [
                      { OT: 5650 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "30621_36271",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 36271,
                  },
                  routeId: "4ox6jqn82v",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 31321,
                      fbc: "WCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "31321_37031",
                      tax: [
                        { OT: 5710 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 37031,
                      psup_fare: "31321_37031",
                    },
                  ],
                  pr: {
                    bf: 31321,
                    tf: 37031,
                    tax: [
                      { OT: 5710 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "31321_37031",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 37031,
                  },
                  routeId: "xgthxlzsgz",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "G79e0AHvUTs=|pfN7IeMVY9crZtLzdrvIzm0oZntQ6f3741aeYn1CvdMLxX16D05jLOkRUUokspRUNBaad/QhuW2NmBov2F0uAOs5rQ6LmyiTG2kKghIPh0qz95QB/MyFDxPn3z3uu1Sj7jxXZQa3gsE=",
              stop: 1,
            },
          ],
          destorigins: [
            {
              tid: "R7n8QuTV0CUDUoX7_2",
              ref: 2,
              office_id: "",
              sdur: "10:15",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T02:00:00",
                  ad: "2023-06-09T08:20:00",
                  rbd: "",
                  fn: "1312",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "3" },
                  rph: "",
                  jsell:
                    "6E~1312~ ~~DOH~06/09/2023 02:00~DEL~06/09/2023 08:20~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-06-09T10:10:00",
                  ad: "2023-06-09T12:15:00",
                  rbd: "",
                  fn: "2005",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "2" },
                  rph: "",
                  jsell:
                    "6E~2005~ ~~DEL~06/09/2023 10:10~HYD~06/09/2023 12:15~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~R~ ~6E~RCINT~6200~~0~65~~X^0~R~ ~6E~RCINT~6200~~0~65~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 9819,
                      fbc: "RCINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "9819_13326",
                      tax: [
                        { OT: 3507 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 13326,
                      psup_fare: "9819_13326",
                    },
                  ],
                  pr: {
                    bf: 9819,
                    tf: 13326,
                    tax: [
                      { OT: 3507 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "9819_13326",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 13326,
                  },
                  routeId: "ksx0iuwatc",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~R~ ~6E~RCUINT~6202~~0~4~~X^0~R~ ~6E~RCUINT~6202~~0~4~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 10519,
                      fbc: "RCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "10519_14050",
                      tax: [
                        { OT: 3531 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 14050,
                      psup_fare: "10519_14050",
                    },
                  ],
                  pr: {
                    bf: 10519,
                    tf: 14050,
                    tax: [
                      { OT: 3531 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "10519_14050",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 14050,
                  },
                  routeId: "0e4enomgq1",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "G79e0AHvUTs=|pfN7IeMVY9crZtLzdrvIzm0oZntQ6f3741aeYn1CvdMLxX16D05jLOkRUUokspRUNBaad/QhuW2NmBov2F0uAOs5rQ6LmyiTG2kKghIPh0qz95QB/MyFDxPn3z3uu1Sj7jxXZQa3gsE=",
              stop: 1,
            },
            {
              tid: "GrT3dScP9BnzVy5q_2",
              ref: 2,
              office_id: "",
              sdur: "21:40",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T02:00:00",
                  ad: "2023-06-09T08:20:00",
                  rbd: "",
                  fn: "1312",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "3" },
                  rph: "",
                  jsell:
                    "6E~1312~ ~~DOH~06/09/2023 02:00~DEL~06/09/2023 08:20~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-06-09T21:25:00",
                  ad: "2023-06-09T23:40:00",
                  rbd: "",
                  fn: "2371",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "2" },
                  rph: "",
                  jsell:
                    "6E~2371~ ~~DEL~06/09/2023 21:25~HYD~06/09/2023 23:40~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~R~ ~6E~RCINT~6200~~0~65~~X^0~R~ ~6E~RCINT~6200~~0~65~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 9819,
                      fbc: "RCINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "9819_13326",
                      tax: [
                        { OT: 3507 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 13326,
                      psup_fare: "9819_13326",
                    },
                  ],
                  pr: {
                    bf: 9819,
                    tf: 13326,
                    tax: [
                      { OT: 3507 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "9819_13326",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 13326,
                  },
                  routeId: "p66byvkxmh",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~R~ ~6E~RCUINT~6202~~0~4~~X^0~R~ ~6E~RCUINT~6202~~0~4~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 10519,
                      fbc: "RCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "10519_14050",
                      tax: [
                        { OT: 3531 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 14050,
                      psup_fare: "10519_14050",
                    },
                  ],
                  pr: {
                    bf: 10519,
                    tf: 14050,
                    tax: [
                      { OT: 3531 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "10519_14050",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 14050,
                  },
                  routeId: "jrjcbbham8",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "G79e0AHvUTs=|pfN7IeMVY9crZtLzdrvIzm0oZntQ6f3741aeYn1CvdMLxX16D05jLOkRUUokspRUNBaad/QhuW2NmBov2F0uAOs5rQ6LmyiTG2kKghIPh0qz95QB/MyFDxPn3z3uu1Sj7jxXZQa3gsE=",
              stop: 1,
            },
            {
              tid: "9QJzoSOGkiAic6z1_2",
              ref: 2,
              office_id: "",
              sdur: "20:0",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T02:00:00",
                  ad: "2023-06-09T08:20:00",
                  rbd: "",
                  fn: "1312",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "3" },
                  rph: "",
                  jsell:
                    "6E~1312~ ~~DOH~06/09/2023 02:00~DEL~06/09/2023 08:20~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-06-09T19:50:00",
                  ad: "2023-06-09T22:00:00",
                  rbd: "",
                  fn: " 605",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "1" },
                  rph: "",
                  jsell:
                    "6E~ 605~ ~~DEL~06/09/2023 19:50~HYD~06/09/2023 22:00~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~R~ ~6E~RCINT~6200~~0~65~~X^0~R~ ~6E~RCINT~6200~~0~65~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 9819,
                      fbc: "RCINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "9819_13326",
                      tax: [
                        { OT: 3507 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 13326,
                      psup_fare: "9819_13326",
                    },
                  ],
                  pr: {
                    bf: 9819,
                    tf: 13326,
                    tax: [
                      { OT: 3507 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "9819_13326",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 13326,
                  },
                  routeId: "p264cndmec",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~R~ ~6E~RCUINT~6202~~0~4~~X^0~R~ ~6E~RCUINT~6202~~0~4~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 10519,
                      fbc: "RCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "10519_14050",
                      tax: [
                        { OT: 3531 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 14050,
                      psup_fare: "10519_14050",
                    },
                  ],
                  pr: {
                    bf: 10519,
                    tf: 14050,
                    tax: [
                      { OT: 3531 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "10519_14050",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 14050,
                  },
                  routeId: "uizgr1v397f",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "G79e0AHvUTs=|pfN7IeMVY9crZtLzdrvIzm0oZntQ6f3741aeYn1CvdMLxX16D05jLOkRUUokspRUNBaad/QhuW2NmBov2F0uAOs5rQ6LmyiTG2kKghIPh0qz95QB/MyFDxPn3z3uu1Sj7jxXZQa3gsE=",
              stop: 1,
            },
            {
              tid: "8JtDdIDpfyvTTvs6_2",
              ref: 2,
              office_id: "",
              sdur: "19:0",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T02:00:00",
                  ad: "2023-06-09T08:20:00",
                  rbd: "",
                  fn: "1312",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "3" },
                  rph: "",
                  jsell:
                    "6E~1312~ ~~DOH~06/09/2023 02:00~DEL~06/09/2023 08:20~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-06-09T18:50:00",
                  ad: "2023-06-09T21:00:00",
                  rbd: "",
                  fn: "5217",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "3" },
                  rph: "",
                  jsell:
                    "6E~5217~ ~~DEL~06/09/2023 18:50~HYD~06/09/2023 21:00~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~R~ ~6E~RCINT~6200~~0~65~~X^0~R~ ~6E~RCINT~6200~~0~65~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 9819,
                      fbc: "RCINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "9819_13326",
                      tax: [
                        { OT: 3507 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 13326,
                      psup_fare: "9819_13326",
                    },
                  ],
                  pr: {
                    bf: 9819,
                    tf: 13326,
                    tax: [
                      { OT: 3507 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "9819_13326",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 13326,
                  },
                  routeId: "u7m8fw3s1u",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~R~ ~6E~RCUINT~6202~~0~4~~X^0~R~ ~6E~RCUINT~6202~~0~4~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 10519,
                      fbc: "RCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "10519_14050",
                      tax: [
                        { OT: 3531 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 14050,
                      psup_fare: "10519_14050",
                    },
                  ],
                  pr: {
                    bf: 10519,
                    tf: 14050,
                    tax: [
                      { OT: 3531 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "10519_14050",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 14050,
                  },
                  routeId: "v8rntsgbzs",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "G79e0AHvUTs=|pfN7IeMVY9crZtLzdrvIzm0oZntQ6f3741aeYn1CvdMLxX16D05jLOkRUUokspRUNBaad/QhuW2NmBov2F0uAOs5rQ6LmyiTG2kKghIPh0qz95QB/MyFDxPn3z3uu1Sj7jxXZQa3gsE=",
              stop: 1,
            },
            {
              tid: "X4yDf5J3Lzs3yqqF_2",
              ref: 2,
              office_id: "",
              sdur: "13:45",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T02:00:00",
                  ad: "2023-06-09T08:20:00",
                  rbd: "",
                  fn: "1312",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "3" },
                  rph: "",
                  jsell:
                    "6E~1312~ ~~DOH~06/09/2023 02:00~DEL~06/09/2023 08:20~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-06-09T13:30:00",
                  ad: "2023-06-09T15:45:00",
                  rbd: "",
                  fn: " 837",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "1" },
                  rph: "",
                  jsell:
                    "6E~ 837~ ~~DEL~06/09/2023 13:30~HYD~06/09/2023 15:45~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~R~ ~6E~RCINT~6200~~0~65~~X^0~R~ ~6E~RCINT~6200~~0~65~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 9819,
                      fbc: "RCINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "9819_13326",
                      tax: [
                        { OT: 3507 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 13326,
                      psup_fare: "9819_13326",
                    },
                  ],
                  pr: {
                    bf: 9819,
                    tf: 13326,
                    tax: [
                      { OT: 3507 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "9819_13326",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 13326,
                  },
                  routeId: "519on9relii",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~R~ ~6E~RCUINT~6202~~0~4~~X^0~R~ ~6E~RCUINT~6202~~0~4~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 10519,
                      fbc: "RCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "10519_14050",
                      tax: [
                        { OT: 3531 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 14050,
                      psup_fare: "10519_14050",
                    },
                  ],
                  pr: {
                    bf: 10519,
                    tf: 14050,
                    tax: [
                      { OT: 3531 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "10519_14050",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 14050,
                  },
                  routeId: "pud98jglrx",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "G79e0AHvUTs=|pfN7IeMVY9crZtLzdrvIzm0oZntQ6f3741aeYn1CvdMLxX16D05jLOkRUUokspRUNBaad/QhuW2NmBov2F0uAOs5rQ6LmyiTG2kKghIPh0qz95QB/MyFDxPn3z3uu1Sj7jxXZQa3gsE=",
              stop: 1,
            },
            {
              tid: "MEEhBewQ73eTiofe_2",
              ref: 2,
              office_id: "",
              sdur: "16:35",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T19:40:00",
                  ad: "2023-06-10T02:00:00",
                  rbd: "",
                  fn: "1308",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "3" },
                  rph: "",
                  jsell:
                    "6E~1308~ ~~DOH~06/09/2023 19:40~DEL~06/10/2023 02:00~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-06-10T10:10:00",
                  ad: "2023-06-10T12:15:00",
                  rbd: "",
                  fn: "2005",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "2" },
                  rph: "",
                  jsell:
                    "6E~2005~ ~~DEL~06/10/2023 10:10~HYD~06/10/2023 12:15~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~R~ ~6E~RCINT~6200~~0~65~~X^0~R~ ~6E~RCINT~6200~~0~65~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 9819,
                      fbc: "RCINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "9819_13326",
                      tax: [
                        { OT: 3507 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 13326,
                      psup_fare: "9819_13326",
                    },
                  ],
                  pr: {
                    bf: 9819,
                    tf: 13326,
                    tax: [
                      { OT: 3507 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "9819_13326",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 13326,
                  },
                  routeId: "kat26b9y2q",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~R~ ~6E~RCUINT~6202~~0~4~~X^0~R~ ~6E~RCUINT~6202~~0~4~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 10519,
                      fbc: "RCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "10519_14050",
                      tax: [
                        { OT: 3531 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 14050,
                      psup_fare: "10519_14050",
                    },
                  ],
                  pr: {
                    bf: 10519,
                    tf: 14050,
                    tax: [
                      { OT: 3531 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "10519_14050",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 14050,
                  },
                  routeId: "ag60ezkjk5l",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "G79e0AHvUTs=|pfN7IeMVY9crZtLzdrvIzm0oZntQ6f3741aeYn1CvdMLxX16D05jLOkRUUokspRUNBaad/QhuW2NmBov2F0uAOs5rQ6LmyiTG2kKghIPh0qz95QB/MyFDxPn3z3uu1Sj7jxXZQa3gsE=",
              stop: 1,
            },
            {
              tid: "bpa8QoopB8PKaEwB_2",
              ref: 2,
              office_id: "",
              sdur: "14:40",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T19:40:00",
                  ad: "2023-06-10T02:00:00",
                  rbd: "",
                  fn: "1308",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "3" },
                  rph: "",
                  jsell:
                    "6E~1308~ ~~DOH~06/09/2023 19:40~DEL~06/10/2023 02:00~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-06-10T08:10:00",
                  ad: "2023-06-10T10:20:00",
                  rbd: "",
                  fn: " 774",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "1" },
                  rph: "",
                  jsell:
                    "6E~ 774~ ~~DEL~06/10/2023 08:10~HYD~06/10/2023 10:20~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~R~ ~6E~RCINT~6200~~0~65~~X^0~R~ ~6E~RCINT~6200~~0~65~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 9819,
                      fbc: "RCINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "9819_13326",
                      tax: [
                        { OT: 3507 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 13326,
                      psup_fare: "9819_13326",
                    },
                  ],
                  pr: {
                    bf: 9819,
                    tf: 13326,
                    tax: [
                      { OT: 3507 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "9819_13326",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 13326,
                  },
                  routeId: "9pereigfvrg",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~R~ ~6E~RCUINT~6202~~0~4~~X^0~R~ ~6E~RCUINT~6202~~0~4~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 10519,
                      fbc: "RCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "10519_14050",
                      tax: [
                        { OT: 3531 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 14050,
                      psup_fare: "10519_14050",
                    },
                  ],
                  pr: {
                    bf: 10519,
                    tf: 14050,
                    tax: [
                      { OT: 3531 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "10519_14050",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 14050,
                  },
                  routeId: "r3e65noljoi",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "G79e0AHvUTs=|pfN7IeMVY9crZtLzdrvIzm0oZntQ6f3741aeYn1CvdMLxX16D05jLOkRUUokspRUNBaad/QhuW2NmBov2F0uAOs5rQ6LmyiTG2kKghIPh0qz95QB/MyFDxPn3z3uu1Sj7jxXZQa3gsE=",
              stop: 1,
            },
            {
              tid: "HeSwDnr4iTyll5Ms_2",
              ref: 2,
              office_id: "",
              sdur: "20:5",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T19:40:00",
                  ad: "2023-06-10T02:00:00",
                  rbd: "",
                  fn: "1308",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "3" },
                  rph: "",
                  jsell:
                    "6E~1308~ ~~DOH~06/09/2023 19:40~DEL~06/10/2023 02:00~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-06-10T13:30:00",
                  ad: "2023-06-10T15:45:00",
                  rbd: "",
                  fn: " 837",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "1" },
                  rph: "",
                  jsell:
                    "6E~ 837~ ~~DEL~06/10/2023 13:30~HYD~06/10/2023 15:45~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~R~ ~6E~RCINT~6200~~0~65~~X^0~R~ ~6E~RCINT~6200~~0~65~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 9819,
                      fbc: "RCINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "9819_13326",
                      tax: [
                        { OT: 3507 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 13326,
                      psup_fare: "9819_13326",
                    },
                  ],
                  pr: {
                    bf: 9819,
                    tf: 13326,
                    tax: [
                      { OT: 3507 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "9819_13326",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 13326,
                  },
                  routeId: "ts6lh5kaa",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~R~ ~6E~RCUINT~6202~~0~4~~X^0~R~ ~6E~RCUINT~6202~~0~4~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 10519,
                      fbc: "RCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "10519_14050",
                      tax: [
                        { OT: 3531 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 14050,
                      psup_fare: "10519_14050",
                    },
                  ],
                  pr: {
                    bf: 10519,
                    tf: 14050,
                    tax: [
                      { OT: 3531 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "10519_14050",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 14050,
                  },
                  routeId: "46wpe0fprhi",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "G79e0AHvUTs=|pfN7IeMVY9crZtLzdrvIzm0oZntQ6f3741aeYn1CvdMLxX16D05jLOkRUUokspRUNBaad/QhuW2NmBov2F0uAOs5rQ6LmyiTG2kKghIPh0qz95QB/MyFDxPn3z3uu1Sj7jxXZQa3gsE=",
              stop: 1,
            },
            {
              tid: "mcI5POfUBvbdeNvl_2",
              ref: 2,
              office_id: "",
              sdur: "21:40",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T19:40:00",
                  ad: "2023-06-10T02:00:00",
                  rbd: "",
                  fn: "1308",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "3" },
                  rph: "",
                  jsell:
                    "6E~1308~ ~~DOH~06/09/2023 19:40~DEL~06/10/2023 02:00~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-06-10T15:05:00",
                  ad: "2023-06-10T17:20:00",
                  rbd: "",
                  fn: "2187",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "2" },
                  rph: "",
                  jsell:
                    "6E~2187~ ~~DEL~06/10/2023 15:05~HYD~06/10/2023 17:20~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~R~ ~6E~RCINT~6200~~0~65~~X^0~R~ ~6E~RCINT~6200~~0~65~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 9819,
                      fbc: "RCINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "9819_13326",
                      tax: [
                        { OT: 3507 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 13326,
                      psup_fare: "9819_13326",
                    },
                  ],
                  pr: {
                    bf: 9819,
                    tf: 13326,
                    tax: [
                      { OT: 3507 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "9819_13326",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 13326,
                  },
                  routeId: "llm5h69mnaf",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~R~ ~6E~RCUINT~6202~~0~4~~X^0~R~ ~6E~RCUINT~6202~~0~4~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 10519,
                      fbc: "RCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "10519_14050",
                      tax: [
                        { OT: 3531 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 14050,
                      psup_fare: "10519_14050",
                    },
                  ],
                  pr: {
                    bf: 10519,
                    tf: 14050,
                    tax: [
                      { OT: 3531 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "10519_14050",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 14050,
                  },
                  routeId: "7z9q9igxv",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "G79e0AHvUTs=|pfN7IeMVY9crZtLzdrvIzm0oZntQ6f3741aeYn1CvdMLxX16D05jLOkRUUokspRUNBaad/QhuW2NmBov2F0uAOs5rQ6LmyiTG2kKghIPh0qz95QB/MyFDxPn3z3uu1Sj7jxXZQa3gsE=",
              stop: 1,
            },
            {
              tid: "OLSukEVbiiu6hOV7_2",
              ref: 2,
              office_id: "",
              sdur: "23:45",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T19:40:00",
                  ad: "2023-06-10T02:00:00",
                  rbd: "",
                  fn: "1308",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "3" },
                  rph: "",
                  jsell:
                    "6E~1308~ ~~DOH~06/09/2023 19:40~DEL~06/10/2023 02:00~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-06-10T17:15:00",
                  ad: "2023-06-10T19:25:00",
                  rbd: "",
                  fn: "5312",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "3" },
                  rph: "",
                  jsell:
                    "6E~5312~ ~~DEL~06/10/2023 17:15~HYD~06/10/2023 19:25~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~R~ ~6E~RCINT~6200~~0~65~~X^0~R~ ~6E~RCINT~6200~~0~65~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 9819,
                      fbc: "RCINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "9819_13326",
                      tax: [
                        { OT: 3507 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 13326,
                      psup_fare: "9819_13326",
                    },
                  ],
                  pr: {
                    bf: 9819,
                    tf: 13326,
                    tax: [
                      { OT: 3507 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "9819_13326",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 13326,
                  },
                  routeId: "hsqzzddpdg",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~R~ ~6E~RCUINT~6202~~0~4~~X^0~R~ ~6E~RCUINT~6202~~0~4~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 10519,
                      fbc: "RCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "10519_14050",
                      tax: [
                        { OT: 3531 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 14050,
                      psup_fare: "10519_14050",
                    },
                  ],
                  pr: {
                    bf: 10519,
                    tf: 14050,
                    tax: [
                      { OT: 3531 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "10519_14050",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 14050,
                  },
                  routeId: "t4kc6nijdo",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "G79e0AHvUTs=|pfN7IeMVY9crZtLzdrvIzm0oZntQ6f3741aeYn1CvdMLxX16D05jLOkRUUokspRUNBaad/QhuW2NmBov2F0uAOs5rQ6LmyiTG2kKghIPh0qz95QB/MyFDxPn3z3uu1Sj7jxXZQa3gsE=",
              stop: 1,
            },
            {
              tid: "7J449kfTitBT46l3_2",
              ref: 2,
              office_id: "",
              sdur: "25:20",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T19:40:00",
                  ad: "2023-06-10T02:00:00",
                  rbd: "",
                  fn: "1308",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "3" },
                  rph: "",
                  jsell:
                    "6E~1308~ ~~DOH~06/09/2023 19:40~DEL~06/10/2023 02:00~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-06-10T18:50:00",
                  ad: "2023-06-10T21:00:00",
                  rbd: "",
                  fn: "5217",
                  trn: "",
                  eq: "321",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "3" },
                  rph: "",
                  jsell:
                    "6E~5217~ ~~DEL~06/10/2023 18:50~HYD~06/10/2023 21:00~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~R~ ~6E~RCINT~6200~~0~65~~X^0~R~ ~6E~RCINT~6200~~0~65~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 9819,
                      fbc: "RCINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "9819_13326",
                      tax: [
                        { OT: 3507 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 13326,
                      psup_fare: "9819_13326",
                    },
                  ],
                  pr: {
                    bf: 9819,
                    tf: 13326,
                    tax: [
                      { OT: 3507 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "9819_13326",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 13326,
                  },
                  routeId: "icj250zpz7",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~R~ ~6E~RCUINT~6202~~0~4~~X^0~R~ ~6E~RCUINT~6202~~0~4~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 10519,
                      fbc: "RCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "10519_14050",
                      tax: [
                        { OT: 3531 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 14050,
                      psup_fare: "10519_14050",
                    },
                  ],
                  pr: {
                    bf: 10519,
                    tf: 14050,
                    tax: [
                      { OT: 3531 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "10519_14050",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 14050,
                  },
                  routeId: "7s87r550bs",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "G79e0AHvUTs=|pfN7IeMVY9crZtLzdrvIzm0oZntQ6f3741aeYn1CvdMLxX16D05jLOkRUUokspRUNBaad/QhuW2NmBov2F0uAOs5rQ6LmyiTG2kKghIPh0qz95QB/MyFDxPn3z3uu1Sj7jxXZQa3gsE=",
              stop: 1,
            },
            {
              tid: "ma3S88WPuSHRwv28_2",
              ref: 2,
              office_id: "",
              sdur: "28:0",
              re: "",
              sup: "4",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DEL",
                  dd: "2023-06-09T19:40:00",
                  ad: "2023-06-10T02:00:00",
                  rbd: "",
                  fn: "1308",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { arr: "3" },
                  rph: "",
                  jsell:
                    "6E~1308~ ~~DOH~06/09/2023 19:40~DEL~06/10/2023 02:00~~",
                  cl: "Economy",
                  flightTime: -1,
                  fdur: "0:-1",
                },
                {
                  ref: 2,
                  da: "DEL",
                  ar: "HYD",
                  dd: "2023-06-10T21:25:00",
                  ad: "2023-06-10T23:40:00",
                  rbd: "",
                  fn: "2371",
                  trn: "",
                  eq: "320",
                  ac: "6E",
                  ma: "6E",
                  seat: "",
                  oa: "6E",
                  fa: [],
                  terminal: { dep: "2" },
                  rph: "",
                  jsell:
                    "6E~2371~ ~~DEL~06/10/2023 21:25~HYD~06/10/2023 23:40~~",
                  cl: "Economy",
                  flightTime: 0,
                  fdur: "0:0",
                },
              ],
              fareop: [
                {
                  fareKey:
                    "0~R~ ~6E~RCINT~6200~~0~65~~X^0~R~ ~6E~RCINT~6200~~0~65~~X",
                  index_id: 1,
                  paxpr: [
                    {
                      bf: 9819,
                      fbc: "RCINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "9819_13326",
                      tax: [
                        { OT: 3507 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 13326,
                      psup_fare: "9819_13326",
                    },
                  ],
                  pr: {
                    bf: 9819,
                    tf: 13326,
                    tax: [
                      { OT: 3507 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "9819_13326",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Regular - R",
                    markupnames: [],
                    ttf: 13326,
                  },
                  routeId: "8u5id55fjvk",
                  ruleNumber: "6200",
                  productClass: "R",
                  servicefee: [],
                  selected: true,
                },
                {
                  fareKey:
                    "0~R~ ~6E~RCUINT~6202~~0~4~~X^0~R~ ~6E~RCUINT~6202~~0~4~~X",
                  index_id: 2,
                  paxpr: [
                    {
                      bf: 10519,
                      fbc: "RCUINT",
                      ptype: "ADT",
                      scur: "QAR",
                      sup_fare: "10519_14050",
                      tax: [
                        { OT: 3531 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      tf: 14050,
                      psup_fare: "10519_14050",
                    },
                  ],
                  pr: {
                    bf: 10519,
                    tf: 14050,
                    tax: [
                      { OT: 3531 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    ucur: "INR",
                    exchange_rate: 1,
                    scur: "INR",
                    sup_fare: "10519_14050",
                    sup_cur_fare: "",
                    cabb: "",
                    chekb: "",
                    seats: "",
                    meals: "",
                    chanb: "",
                    ftyp: "Flexi - J",
                    markupnames: [],
                    ttf: 14050,
                  },
                  routeId: "awewtdxqt9",
                  ruleNumber: "6202",
                  productClass: "J",
                  servicefee: [],
                  selected: false,
                },
              ],
              tktair: "6E",
              tecst: "no",
              cl: "Economy",
              indigo_ugkey:
                "G79e0AHvUTs=|pfN7IeMVY9crZtLzdrvIzm0oZntQ6f3741aeYn1CvdMLxX16D05jLOkRUUokspRUNBaad/QhuW2NmBov2F0uAOs5rQ6LmyiTG2kKghIPh0qz95QB/MyFDxPn3z3uu1Sj7jxXZQa3gsE=",
              stop: 1,
            },
          ],
          paxpr: [
            {
              bf: 40440,
              fbc: "WCINT",
              ptype: "ADT",
              scur: "QAR",
              sup_fare: "30621_36271",
              tax: [
                { OT: 9157 },
                { attmkp: 0 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              tf: 49597,
              psup_fare: "40440_49597",
            },
          ],
          pr: {
            bf: 40440,
            tf: 49597,
            tax: [
              { OT: 9157 },
              { attmkp: 0 },
              { tamkp: 0 },
              { stamkp: 0 },
              { attdisc: 0 },
              { tadisc: 0 },
              { stadisc: 0 },
            ],
            ucur: "INR",
            exchange_rate: 1,
            scur: "INR",
            sup_fare: "40440_49597",
            sup_cur_fare: "",
            cabb: "",
            chekb: "",
            seats: "",
            meals: "",
            chanb: "",
            ftyp: "Regular - R",
            markupnames: [],
            ttf: 49597,
          },
          sup: "4",
          airlineList: "6E",
          servicefee: [],
        },
        {
          srcorigns: [
            {
              tid: "TOE8dATIwLvIMuV6_1",
              ref: 1,
              office_id: "",
              sdur: "8:25",
              re: "",
              sup: "5",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DXB",
                  dd: "2023-06-07T03:00:00",
                  ad: "2023-06-07T05:30:00",
                  rbd: "",
                  fn: "436",
                  trn: "",
                  eq: "73B",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "", arr: "2" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161457,
                  flightTime: 240,
                  fdur: "4:0",
                },
                {
                  ref: 2,
                  da: "DXB",
                  ar: "DOH",
                  dd: "2023-06-07T08:45:00",
                  ad: "2023-06-07T08:55:00",
                  rbd: "",
                  fn: "001",
                  trn: "",
                  eq: "73X",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "2", arr: "1" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161405,
                  flightTime: 70,
                  fdur: "1:10",
                },
              ],
              fareop: [
                {
                  fareKey: [],
                  pr: {
                    bf: 13255,
                    tf: 24529.79,
                    tax: [
                      { OT: 11274.79 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    scur: "INR",
                    ucur: "INR",
                    exchange_rate: 1,
                    sup_fare: "13255_24529.79",
                    sup_cur_fare: "",
                    cabb: "Chargeable",
                    chekb: "7kg INCLUDED",
                    meals: "Chargeable",
                    chanb: "Non Refundable",
                    ftyp: "LITE",
                    extra: "11_LITE",
                    chekbcode: "",
                    chanb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Change of Flight is Non-Refundable,NOSHOW From 0 hrs before  of departure Change of Flight is Non-Refundable",
                    canb: "Non Refundable",
                    cabb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Cancellation of Flight is Non-Refundable,NOSHOW From 0 hrs before  of departure Cancellation of Flight is Non-Refundable",
                    bagage_info: "Chargeable",
                    markupnames: [],
                    ttf: 24529.79,
                  },
                  paxpr: [
                    {
                      bf: 13255,
                      tf: 24529.79,
                      tax: [
                        { OT: 11274.79 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "OR6IN5",
                      ptype: "ADT",
                      scur: "INR",
                      extra:
                        "1_3_O_1_9_0_tx-2186:170:PZ:0_4246:6450:YQ:0_9327:969.79:K3:0_10386:120:ZR:0_11947:790:F6:0_12006:1170:P2:0_12434:1605:IN:0_",
                      ruleid:
                        "Hs1Ucwi64E3biz2I+Wj4LYCMSUE+oAXNhfzC1AvPpsdXVy0HLLTzOxy6vo4KsNKyH4iOhL3qCTzeoIR/oeSzY4TEtPO2CThrAiJMBJQHPOaxndbba/gOeDLph5SLjZ1F",
                      hsc: "8eb8eca70ccd89c69b7d6639f9173cec5713f4353ee70794cfaeba89b90b4273",
                      sup_fare: "13255_24529.79_12805",
                      cabb: "Chargeable",
                      bagage_info: "Chargeable",
                      chekbcode: "",
                      psup_fare: "13255_24529.79",
                    },
                  ],
                  uniqueId: "LITE",
                  routeId: "43oyo622us",
                  servicefee: [],
                  selected: true,
                },
              ],
              tktair: "FZ",
              tecst: "no",
              cl: "Economy",
              extra: 13919293,
              token:
                "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiYXBpdGF3ZmVlcSIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL2dpdmVubmFtZSI6IkFQSSBUQVdGRUVRIiwiZ3JvdXBBY2Nlc3MiOiIzLDM2LDExMiIsImlzQXB0Q2xudCI6IkZhbHNlIiwibG9jYXRpb24iOiI5OTk5IiwiY2hrTGNuIjoiIiwicmVzVG9rZW4iOiJkZWRhZDdkeWY4N2FuOTc0dGJxYjY0YmJ6ZjZlOTYwYzA5ZTE5NmRhZTg2MCIsImNsaWVudElQIjoiMTcyLjE5LjIzLjU0IiwicmVzQ2hhbm5lbElEIjoiNSIsInJlc0NoYW5uZWxOYW1lIjoiVFBBUEkiLCJkZWZhdWx0VXNlck5hbWUiOiJhcGl0YXdmZWVxIiwibmJmIjoxNjg1NTE1MjM3LCJleHAiOjE2ODU1MTc2MzcsImlzcyI6ImZseWR1YmFpLmNvbSIsImF1ZCI6IlRBV0ZFRVFUUlZMU19GWl9OUCJ9.rAW3YT-bX_-yADfvg3QkuPAbOJhkhRUN6HwoS_6PyiM",
              stop: 1,
            },
            {
              tid: "nPiX2zoUavOloQDW_1",
              ref: 1,
              office_id: "",
              sdur: "15:25",
              re: "",
              sup: "5",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DXB",
                  dd: "2023-06-07T03:00:00",
                  ad: "2023-06-07T05:30:00",
                  rbd: "",
                  fn: "436",
                  trn: "",
                  eq: "73B",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "", arr: "2" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161457,
                  flightTime: 240,
                  fdur: "4:0",
                },
                {
                  ref: 2,
                  da: "DXB",
                  ar: "DOH",
                  dd: "2023-06-07T15:45:00",
                  ad: "2023-06-07T15:55:00",
                  rbd: "",
                  fn: "017",
                  trn: "",
                  eq: "73X",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "2", arr: "1" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161400,
                  flightTime: 70,
                  fdur: "1:10",
                },
              ],
              fareop: [
                {
                  fareKey: [],
                  pr: {
                    bf: 13255,
                    tf: 24529.79,
                    tax: [
                      { OT: 11274.79 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    scur: "INR",
                    ucur: "INR",
                    exchange_rate: 1,
                    sup_fare: "13255_24529.79",
                    sup_cur_fare: "",
                    cabb: "Chargeable",
                    chekb: "7kg INCLUDED",
                    meals: "Chargeable",
                    chanb: "Non Refundable",
                    ftyp: "LITE",
                    extra: "11_LITE",
                    chekbcode: "",
                    chanb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Change of Flight is Non-Refundable,NOSHOW From 0 hrs before  of departure Change of Flight is Non-Refundable",
                    canb: "Non Refundable",
                    cabb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Cancellation of Flight is Non-Refundable,NOSHOW From 0 hrs before  of departure Cancellation of Flight is Non-Refundable",
                    bagage_info: "Chargeable",
                    markupnames: [],
                    ttf: 24529.79,
                  },
                  paxpr: [
                    {
                      bf: 13255,
                      tf: 24529.79,
                      tax: [
                        { OT: 11274.79 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "OR6IN5",
                      ptype: "ADT",
                      scur: "INR",
                      extra:
                        "1_69_O_1_9_0_tx-2186:170:PZ:0_4246:6450:YQ:0_9327:969.79:K3:0_10386:120:ZR:0_11947:790:F6:0_12006:1170:P2:0_12434:1605:IN:0_",
                      ruleid:
                        "bBi8qKg8xUmY7+yga3ARsxM1YiBqo5VUngE+f6ZTlrcWhTrLfc+1HjPslNbsK6Ma+NzJ9vs0yTvfIGzHK+7a6QYdbLMNnyuqz+GQYY8X4ucqhXNHUPmc5WpT//+vZgmt",
                      hsc: "c2ebe336ea9d004eef336470bf8035c279a3c8d2d29651c33c8118e4ed6e17c7",
                      sup_fare: "13255_24529.79_12805",
                      cabb: "Chargeable",
                      bagage_info: "Chargeable",
                      chekbcode: "",
                      psup_fare: "13255_24529.79",
                    },
                  ],
                  uniqueId: "LITE",
                  routeId: "1e7h4u7u1pi",
                  servicefee: [],
                  selected: true,
                },
              ],
              tktair: "FZ",
              tecst: "no",
              cl: "Economy",
              extra: 13919296,
              token:
                "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiYXBpdGF3ZmVlcSIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL2dpdmVubmFtZSI6IkFQSSBUQVdGRUVRIiwiZ3JvdXBBY2Nlc3MiOiIzLDM2LDExMiIsImlzQXB0Q2xudCI6IkZhbHNlIiwibG9jYXRpb24iOiI5OTk5IiwiY2hrTGNuIjoiIiwicmVzVG9rZW4iOiJkZWRhZDdkeWY4N2FuOTc0dGJxYjY0YmJ6ZjZlOTYwYzA5ZTE5NmRhZTg2MCIsImNsaWVudElQIjoiMTcyLjE5LjIzLjU0IiwicmVzQ2hhbm5lbElEIjoiNSIsInJlc0NoYW5uZWxOYW1lIjoiVFBBUEkiLCJkZWZhdWx0VXNlck5hbWUiOiJhcGl0YXdmZWVxIiwibmJmIjoxNjg1NTE1MjM3LCJleHAiOjE2ODU1MTc2MzcsImlzcyI6ImZseWR1YmFpLmNvbSIsImF1ZCI6IlRBV0ZFRVFUUlZMU19GWl9OUCJ9.rAW3YT-bX_-yADfvg3QkuPAbOJhkhRUN6HwoS_6PyiM",
              stop: 1,
            },
            {
              tid: "NH9KZMxfcDZBZgoL_1",
              ref: 1,
              office_id: "",
              sdur: "19:25",
              re: "",
              sup: "5",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DXB",
                  dd: "2023-06-07T03:00:00",
                  ad: "2023-06-07T05:30:00",
                  rbd: "",
                  fn: "436",
                  trn: "",
                  eq: "73B",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "", arr: "2" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161457,
                  flightTime: 240,
                  fdur: "4:0",
                },
                {
                  ref: 2,
                  da: "DXB",
                  ar: "DOH",
                  dd: "2023-06-07T19:45:00",
                  ad: "2023-06-07T19:55:00",
                  rbd: "",
                  fn: "005",
                  trn: "",
                  eq: "73X",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "2", arr: "1" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161381,
                  flightTime: 70,
                  fdur: "1:10",
                },
              ],
              fareop: [
                {
                  fareKey: [],
                  pr: {
                    bf: 13255,
                    tf: 24529.79,
                    tax: [
                      { OT: 11274.79 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    scur: "INR",
                    ucur: "INR",
                    exchange_rate: 1,
                    sup_fare: "13255_24529.79",
                    sup_cur_fare: "",
                    cabb: "Chargeable",
                    chekb: "7kg INCLUDED",
                    meals: "Chargeable",
                    chanb: "Non Refundable",
                    ftyp: "LITE",
                    extra: "11_LITE",
                    chekbcode: "",
                    chanb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Change of Flight is Non-Refundable,NOSHOW From 0 hrs before  of departure Change of Flight is Non-Refundable",
                    canb: "Non Refundable",
                    cabb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Cancellation of Flight is Non-Refundable,NOSHOW From 0 hrs before  of departure Cancellation of Flight is Non-Refundable",
                    bagage_info: "Chargeable",
                    markupnames: [],
                    ttf: 24529.79,
                  },
                  paxpr: [
                    {
                      bf: 13255,
                      tf: 24529.79,
                      tax: [
                        { OT: 11274.79 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "OR6IN5",
                      ptype: "ADT",
                      scur: "INR",
                      extra:
                        "1_101_O_1_9_0_tx-2186:170:PZ:0_4246:6450:YQ:0_9327:969.79:K3:0_10386:120:ZR:0_11947:790:F6:0_12006:1170:P2:0_12434:1605:IN:0_",
                      ruleid:
                        "m2buSyX7A6qiFIqHDzzZ4xZ+CHIn9HNL+12RdyPuUypN3Vrd+8Of0oA7a4N7g8CvZItP8LZIcrAqzBeCOoIuvjLwhemGenh8Kvds7rAxHg7lu4lkJ9t+YgS8Pv3j40Na",
                      hsc: "087bc73b8f8e12793fa077dc23ce5d61fa8d2dcebac3fb5026e70cb71a3bb400",
                      sup_fare: "13255_24529.79_12805",
                      cabb: "Chargeable",
                      bagage_info: "Chargeable",
                      chekbcode: "",
                      psup_fare: "13255_24529.79",
                    },
                  ],
                  uniqueId: "LITE",
                  routeId: "24elpf08lp",
                  servicefee: [],
                  selected: true,
                },
              ],
              tktair: "FZ",
              tecst: "no",
              cl: "Economy",
              extra: 13919294,
              token:
                "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiYXBpdGF3ZmVlcSIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL2dpdmVubmFtZSI6IkFQSSBUQVdGRUVRIiwiZ3JvdXBBY2Nlc3MiOiIzLDM2LDExMiIsImlzQXB0Q2xudCI6IkZhbHNlIiwibG9jYXRpb24iOiI5OTk5IiwiY2hrTGNuIjoiIiwicmVzVG9rZW4iOiJkZWRhZDdkeWY4N2FuOTc0dGJxYjY0YmJ6ZjZlOTYwYzA5ZTE5NmRhZTg2MCIsImNsaWVudElQIjoiMTcyLjE5LjIzLjU0IiwicmVzQ2hhbm5lbElEIjoiNSIsInJlc0NoYW5uZWxOYW1lIjoiVFBBUEkiLCJkZWZhdWx0VXNlck5hbWUiOiJhcGl0YXdmZWVxIiwibmJmIjoxNjg1NTE1MjM3LCJleHAiOjE2ODU1MTc2MzcsImlzcyI6ImZseWR1YmFpLmNvbSIsImF1ZCI6IlRBV0ZFRVFUUlZMU19GWl9OUCJ9.rAW3YT-bX_-yADfvg3QkuPAbOJhkhRUN6HwoS_6PyiM",
              stop: 1,
            },
          ],
          paxpr: [
            {
              bf: 27710,
              tf: 50515,
              tax: [
                { OT: 22805 },
                { attmkp: 0 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              fbc: "OR6IN5",
              ptype: "ADT",
              scur: "INR",
              extra:
                "1_3_O_1_9_0_tx-2186:170:PZ:0_4246:6450:YQ:0_9327:969.79:K3:0_10386:120:ZR:0_11947:790:F6:0_12006:1170:P2:0_12434:1605:IN:0_",
              ruleid:
                "Hs1Ucwi64E3biz2I+Wj4LYCMSUE+oAXNhfzC1AvPpsdXVy0HLLTzOxy6vo4KsNKyH4iOhL3qCTzeoIR/oeSzY4TEtPO2CThrAiJMBJQHPOaxndbba/gOeDLph5SLjZ1F",
              hsc: "8eb8eca70ccd89c69b7d6639f9173cec5713f4353ee70794cfaeba89b90b4273",
              sup_fare: "13255_24529.79_12805",
              cabb: "Chargeable",
              bagage_info: "Chargeable",
              chekbcode: "",
              psup_fare: "27710_50515",
            },
          ],
          pr: {
            bf: 27710,
            tf: 50515,
            tax: [
              { OT: 22805 },
              { attmkp: 0 },
              { tamkp: 0 },
              { stamkp: 0 },
              { attdisc: 0 },
              { tadisc: 0 },
              { stadisc: 0 },
            ],
            scur: "INR",
            ucur: "INR",
            exchange_rate: 1,
            sup_fare: "27710_50515",
            sup_cur_fare: "",
            cabb: "Chargeable",
            chekb: "7kg INCLUDED",
            meals: "Chargeable",
            chanb: "Non Refundable",
            ftyp: "LITE",
            extra: "11_LITE",
            chekbcode: "",
            chanb_info:
              "BEFOREDEPARTURE 0 hrs of departure Change of Flight is Non-Refundable,NOSHOW From 0 hrs before  of departure Change of Flight is Non-Refundable",
            canb: "Non Refundable",
            cabb_info:
              "BEFOREDEPARTURE 0 hrs of departure Cancellation of Flight is Non-Refundable,NOSHOW From 0 hrs before  of departure Cancellation of Flight is Non-Refundable",
            bagage_info: "Chargeable",
            markupnames: [],
            ttf: 50515,
          },
          sup: "5",
          airlineList: "FZ",
          destorigins: [
            {
              tid: "NIvXybgVJJEkUpye_2",
              ref: 2,
              office_id: "",
              sdur: "6:45",
              re: "",
              sup: "5",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DXB",
                  dd: "2023-06-09T17:00:00",
                  ad: "2023-06-09T19:15:00",
                  rbd: "",
                  fn: "018",
                  trn: "",
                  eq: "73X",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "1", arr: "2" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161399,
                  flightTime: 75,
                  fdur: "1:15",
                },
                {
                  ref: 2,
                  da: "DXB",
                  ar: "HYD",
                  dd: "2023-06-09T20:50:00",
                  ad: "2023-06-10T02:15:00",
                  rbd: "",
                  fn: "435",
                  trn: "",
                  eq: "73B",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "2", arr: "" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161456,
                  flightTime: 235,
                  fdur: "3:55",
                },
              ],
              fareop: [
                {
                  fareKey: [],
                  pr: {
                    bf: 14455,
                    tf: 25985.21,
                    tax: [
                      { OT: 11530.21 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    scur: "INR",
                    ucur: "INR",
                    exchange_rate: 1,
                    sup_fare: "14455_25985.21",
                    sup_cur_fare: "",
                    cabb: "Free",
                    chekb: "7kg INCLUDED",
                    meals: "Standard meal",
                    chanb: "Chargeable",
                    ftyp: "VALUE",
                    extra: "12_VALUE",
                    chekbcode: "BAGL",
                    chanb_info:
                      "UPTO 24 hrs of departure Change of Flight is Free,WITHIN From 0-24 hrs of departure Change of Flight is Non-Refundable,NOSHOW From 0 hrs before  of departure Change of Flight is Non-Refundable",
                    canb: "Chargeable",
                    cabb_info:
                      "UPTO 24 hrs of departure Cancellation of Flight is Free,WITHIN From 0-24 hrs of departure Cancellation of Flight is Non-Refundable,NOSHOW From 0 hrs before  of departure Cancellation of Flight is Non-Refundable",
                    bagage_info: "30kg BAG INCLUDED IN FARE",
                    markupnames: [],
                    ttf: 25985.21,
                  },
                  paxpr: [
                    {
                      bf: 14455,
                      tf: 25985.21,
                      tax: [
                        { OT: 11530.21 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "ORL7IN5",
                      ptype: "ADT",
                      scur: "INR",
                      extra:
                        "1_4_O_1_9_0_tx-1764:1370:QA:0_2186:170:PZ:0_4246:6450:YQ:0_8947:0:BAGL:0_8966:1370:G4:0_9327:1030.21:K3:0_10386:120:ZR:0_11947:790:F6:0_13230:230:R9:0_",
                      ruleid:
                        "boFxhdrLOviTx/n1F2TaiWDGJNH37aAsxUou/hEUve1ewJ9aVhK57wibWY2PIn24f4tv7xijw4oXgcRHgmFw0E3Dg72VJaVpJZuTCj214MbAKCkVAJmRzsz4wlYfVzNweXH1hDuwDQ+MlHme53FHEA==",
                      hsc: "571565837a5547a5884894496b6f416093db6638b08292985f0c076f66400574",
                      sup_fare: "14455_25985.21_14005",
                      cabb: "Free",
                      bagage_info: "30kg BAG INCLUDED IN FARE",
                      chekbcode: "BAGL",
                      psup_fare: "14455_25985.21",
                    },
                  ],
                  uniqueId: "VALUE",
                  routeId: "zwnli84zlr",
                  servicefee: [],
                  selected: true,
                },
              ],
              tktair: "FZ",
              tecst: "no",
              cl: "Economy",
              extra: 13916393,
              token:
                "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiYXBpdGF3ZmVlcSIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL2dpdmVubmFtZSI6IkFQSSBUQVdGRUVRIiwiZ3JvdXBBY2Nlc3MiOiIzLDM2LDExMiIsImlzQXB0Q2xudCI6IkZhbHNlIiwibG9jYXRpb24iOiI5OTk5IiwiY2hrTGNuIjoiIiwicmVzVG9rZW4iOiJkZWRhZDdkeWY4N2FuOTc0dGJxYjY0YmJ6ZjZlOTYwYzA5ZTE5NmRhZTg2MCIsImNsaWVudElQIjoiMTcyLjE5LjIzLjU0IiwicmVzQ2hhbm5lbElEIjoiNSIsInJlc0NoYW5uZWxOYW1lIjoiVFBBUEkiLCJkZWZhdWx0VXNlck5hbWUiOiJhcGl0YXdmZWVxIiwibmJmIjoxNjg1NTE1MjM3LCJleHAiOjE2ODU1MTc2MzcsImlzcyI6ImZseWR1YmFpLmNvbSIsImF1ZCI6IlRBV0ZFRVFUUlZMU19GWl9OUCJ9.rAW3YT-bX_-yADfvg3QkuPAbOJhkhRUN6HwoS_6PyiM",
              stop: 1,
            },
            {
              tid: "zt3XvX0AuPhi7KEc_2",
              ref: 2,
              office_id: "",
              sdur: "13:45",
              re: "",
              sup: "5",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DXB",
                  dd: "2023-06-09T10:00:00",
                  ad: "2023-06-09T12:15:00",
                  rbd: "",
                  fn: "002",
                  trn: "",
                  eq: "73X",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "1", arr: "2" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161402,
                  flightTime: 75,
                  fdur: "1:15",
                },
                {
                  ref: 2,
                  da: "DXB",
                  ar: "HYD",
                  dd: "2023-06-09T20:50:00",
                  ad: "2023-06-10T02:15:00",
                  rbd: "",
                  fn: "435",
                  trn: "",
                  eq: "73B",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "2", arr: "" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161456,
                  flightTime: 235,
                  fdur: "3:55",
                },
              ],
              fareop: [
                {
                  fareKey: [],
                  pr: {
                    bf: 14455,
                    tf: 25985.21,
                    tax: [
                      { OT: 11530.21 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    scur: "INR",
                    ucur: "INR",
                    exchange_rate: 1,
                    sup_fare: "14455_25985.21",
                    sup_cur_fare: "",
                    cabb: "Free",
                    chekb: "7kg INCLUDED",
                    meals: "Standard meal",
                    chanb: "Chargeable",
                    ftyp: "VALUE",
                    extra: "12_VALUE",
                    chekbcode: "BAGL",
                    chanb_info:
                      "UPTO 24 hrs of departure Change of Flight is Free,WITHIN From 0-24 hrs of departure Change of Flight is Non-Refundable,NOSHOW From 0 hrs before  of departure Change of Flight is Non-Refundable",
                    canb: "Chargeable",
                    cabb_info:
                      "UPTO 24 hrs of departure Cancellation of Flight is Free,WITHIN From 0-24 hrs of departure Cancellation of Flight is Non-Refundable,NOSHOW From 0 hrs before  of departure Cancellation of Flight is Non-Refundable",
                    bagage_info: "30kg BAG INCLUDED IN FARE",
                    markupnames: [],
                    ttf: 25985.21,
                  },
                  paxpr: [
                    {
                      bf: 14455,
                      tf: 25985.21,
                      tax: [
                        { OT: 11530.21 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "ORL7IN5",
                      ptype: "ADT",
                      scur: "INR",
                      extra:
                        "1_36_O_1_9_0_tx-1764:1370:QA:0_2186:170:PZ:0_4246:6450:YQ:0_8947:0:BAGL:0_8966:1370:G4:0_9327:1030.21:K3:0_10386:120:ZR:0_11947:790:F6:0_13230:230:R9:0_",
                      ruleid:
                        "EmLSC55c2tuHQvDhNEMob24CvdfP67Yp1QfNWPSmae6JwVGRH27geGUyNcdqoz42Q+cpWZGlzHEqLD5ipHbUE/fms9aActHf3dUySn1KUD/cT1Te8NsMYkH9iH9Rtc9pV3Wx5QsmoEr7eVfjVNM8Og==",
                      hsc: "6e9ddfe661ff538e35dc616d7226d0eac30d942fe59f280b00c8ad81f6b2b9a1",
                      sup_fare: "14455_25985.21_14005",
                      cabb: "Free",
                      bagage_info: "30kg BAG INCLUDED IN FARE",
                      chekbcode: "BAGL",
                      psup_fare: "14455_25985.21",
                    },
                  ],
                  uniqueId: "VALUE",
                  routeId: "1hri6mznik",
                  servicefee: [],
                  selected: true,
                },
              ],
              tktair: "FZ",
              tecst: "no",
              cl: "Economy",
              extra: 13915297,
              token:
                "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiYXBpdGF3ZmVlcSIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL2dpdmVubmFtZSI6IkFQSSBUQVdGRUVRIiwiZ3JvdXBBY2Nlc3MiOiIzLDM2LDExMiIsImlzQXB0Q2xudCI6IkZhbHNlIiwibG9jYXRpb24iOiI5OTk5IiwiY2hrTGNuIjoiIiwicmVzVG9rZW4iOiJkZWRhZDdkeWY4N2FuOTc0dGJxYjY0YmJ6ZjZlOTYwYzA5ZTE5NmRhZTg2MCIsImNsaWVudElQIjoiMTcyLjE5LjIzLjU0IiwicmVzQ2hhbm5lbElEIjoiNSIsInJlc0NoYW5uZWxOYW1lIjoiVFBBUEkiLCJkZWZhdWx0VXNlck5hbWUiOiJhcGl0YXdmZWVxIiwibmJmIjoxNjg1NTE1MjM3LCJleHAiOjE2ODU1MTc2MzcsImlzcyI6ImZseWR1YmFpLmNvbSIsImF1ZCI6IlRBV0ZFRVFUUlZMU19GWl9OUCJ9.rAW3YT-bX_-yADfvg3QkuPAbOJhkhRUN6HwoS_6PyiM",
              stop: 1,
            },
          ],
          servicefee: [],
        },
        {
          srcorigns: [
            {
              tid: "NEpzR9t6F5ZIHEbG_1",
              ref: 1,
              office_id: "",
              sdur: "8:25",
              re: "",
              sup: "5",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DXB",
                  dd: "2023-06-07T03:00:00",
                  ad: "2023-06-07T05:30:00",
                  rbd: "",
                  fn: "436",
                  trn: "",
                  eq: "73B",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "", arr: "2" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161457,
                  flightTime: 240,
                  fdur: "4:0",
                },
                {
                  ref: 2,
                  da: "DXB",
                  ar: "DOH",
                  dd: "2023-06-07T08:45:00",
                  ad: "2023-06-07T08:55:00",
                  rbd: "",
                  fn: "001",
                  trn: "",
                  eq: "73X",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "2", arr: "1" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161405,
                  flightTime: 70,
                  fdur: "1:10",
                },
              ],
              fareop: [
                {
                  fareKey: [],
                  pr: {
                    bf: 14455,
                    tf: 25790.21,
                    tax: [
                      { OT: 11335.21 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    scur: "INR",
                    ucur: "INR",
                    exchange_rate: 1,
                    sup_fare: "14455_25790.21",
                    sup_cur_fare: "",
                    cabb: "Free",
                    chekb: "7kg INCLUDED",
                    meals: "Standard meal",
                    chanb: "Chargeable",
                    ftyp: "VALUE",
                    extra: "12_VALUE",
                    chekbcode: "BAGL",
                    chanb_info:
                      "UPTO 24 hrs of departure Change of Flight is Free,WITHIN From 0-24 hrs of departure Change of Flight is Non-Refundable,NOSHOW From 0 hrs before  of departure Change of Flight is Non-Refundable",
                    canb: "Chargeable",
                    cabb_info:
                      "UPTO 24 hrs of departure Cancellation of Flight is Free,WITHIN From 0-24 hrs of departure Cancellation of Flight is Non-Refundable,NOSHOW From 0 hrs before  of departure Cancellation of Flight is Non-Refundable",
                    bagage_info: "30kg BAG INCLUDED IN FARE",
                    markupnames: [],
                    ttf: 25790.21,
                  },
                  paxpr: [
                    {
                      bf: 14455,
                      tf: 25790.21,
                      tax: [
                        { OT: 11335.21 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "ORL7IN5",
                      ptype: "ADT",
                      scur: "INR",
                      extra:
                        "1_5_O_1_9_0_tx-2186:170:PZ:0_4246:6450:YQ:0_8947:0:BAGL:0_9327:1030.21:K3:0_10386:120:ZR:0_11947:790:F6:0_12006:1170:P2:0_12434:1605:IN:0_",
                      ruleid:
                        "ppnSmG7YUmp/G7Gg/9ZZboCMSUE+oAXNhfzC1AvPpsdXVy0HLLTzOxy6vo4KsNKyH4iOhL3qCTzeoIR/oeSzY4TEtPO2CThrAiJMBJQHPOYWhaoIILXy8P1UbN4ahWTL",
                      hsc: "0fc37105182aa8cd9e1f888fc12216fb400020272fdbdb1ebc0bea027e24a0ef",
                      sup_fare: "14455_25790.21_14005",
                      cabb: "Free",
                      bagage_info: "30kg BAG INCLUDED IN FARE",
                      chekbcode: "BAGL",
                      psup_fare: "14455_25790.21",
                    },
                  ],
                  uniqueId: "VALUE",
                  routeId: "r7mbkur5vg",
                  servicefee: [],
                  selected: true,
                },
              ],
              tktair: "FZ",
              tecst: "no",
              cl: "Economy",
              extra: 13919293,
              token:
                "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiYXBpdGF3ZmVlcSIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL2dpdmVubmFtZSI6IkFQSSBUQVdGRUVRIiwiZ3JvdXBBY2Nlc3MiOiIzLDM2LDExMiIsImlzQXB0Q2xudCI6IkZhbHNlIiwibG9jYXRpb24iOiI5OTk5IiwiY2hrTGNuIjoiIiwicmVzVG9rZW4iOiJkZWRhZDdkeWY4N2FuOTc0dGJxYjY0YmJ6ZjZlOTYwYzA5ZTE5NmRhZTg2MCIsImNsaWVudElQIjoiMTcyLjE5LjIzLjU0IiwicmVzQ2hhbm5lbElEIjoiNSIsInJlc0NoYW5uZWxOYW1lIjoiVFBBUEkiLCJkZWZhdWx0VXNlck5hbWUiOiJhcGl0YXdmZWVxIiwibmJmIjoxNjg1NTE1MjM3LCJleHAiOjE2ODU1MTc2MzcsImlzcyI6ImZseWR1YmFpLmNvbSIsImF1ZCI6IlRBV0ZFRVFUUlZMU19GWl9OUCJ9.rAW3YT-bX_-yADfvg3QkuPAbOJhkhRUN6HwoS_6PyiM",
              stop: 1,
            },
            {
              tid: "ZqxoxDE4QQrfEI5D_1",
              ref: 1,
              office_id: "",
              sdur: "15:25",
              re: "",
              sup: "5",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DXB",
                  dd: "2023-06-07T03:00:00",
                  ad: "2023-06-07T05:30:00",
                  rbd: "",
                  fn: "436",
                  trn: "",
                  eq: "73B",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "", arr: "2" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161457,
                  flightTime: 240,
                  fdur: "4:0",
                },
                {
                  ref: 2,
                  da: "DXB",
                  ar: "DOH",
                  dd: "2023-06-07T15:45:00",
                  ad: "2023-06-07T15:55:00",
                  rbd: "",
                  fn: "017",
                  trn: "",
                  eq: "73X",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "2", arr: "1" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161400,
                  flightTime: 70,
                  fdur: "1:10",
                },
              ],
              fareop: [
                {
                  fareKey: [],
                  pr: {
                    bf: 14455,
                    tf: 25790.21,
                    tax: [
                      { OT: 11335.21 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    scur: "INR",
                    ucur: "INR",
                    exchange_rate: 1,
                    sup_fare: "14455_25790.21",
                    sup_cur_fare: "",
                    cabb: "Free",
                    chekb: "7kg INCLUDED",
                    meals: "Standard meal",
                    chanb: "Chargeable",
                    ftyp: "VALUE",
                    extra: "12_VALUE",
                    chekbcode: "BAGL",
                    chanb_info:
                      "UPTO 24 hrs of departure Change of Flight is Free,WITHIN From 0-24 hrs of departure Change of Flight is Non-Refundable,NOSHOW From 0 hrs before  of departure Change of Flight is Non-Refundable",
                    canb: "Chargeable",
                    cabb_info:
                      "UPTO 24 hrs of departure Cancellation of Flight is Free,WITHIN From 0-24 hrs of departure Cancellation of Flight is Non-Refundable,NOSHOW From 0 hrs before  of departure Cancellation of Flight is Non-Refundable",
                    bagage_info: "30kg BAG INCLUDED IN FARE",
                    markupnames: [],
                    ttf: 25790.21,
                  },
                  paxpr: [
                    {
                      bf: 14455,
                      tf: 25790.21,
                      tax: [
                        { OT: 11335.21 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "ORL7IN5",
                      ptype: "ADT",
                      scur: "INR",
                      extra:
                        "1_67_O_1_9_0_tx-2186:170:PZ:0_4246:6450:YQ:0_8947:0:BAGL:0_9327:1030.21:K3:0_10386:120:ZR:0_11947:790:F6:0_12006:1170:P2:0_12434:1605:IN:0_",
                      ruleid:
                        "VMxwIwhcRuItDXje30rZxRM1YiBqo5VUngE+f6ZTlrcWhTrLfc+1HjPslNbsK6Ma+NzJ9vs0yTvfIGzHK+7a6QYdbLMNnyuqz+GQYY8X4uc35QrFzNp0saGa5iSUexZp",
                      hsc: "5e8fee944600c143fe4d745f2a484bd9896c5efe948795ef903e52ffeb6d6144",
                      sup_fare: "14455_25790.21_14005",
                      cabb: "Free",
                      bagage_info: "30kg BAG INCLUDED IN FARE",
                      chekbcode: "BAGL",
                      psup_fare: "14455_25790.21",
                    },
                  ],
                  uniqueId: "VALUE",
                  routeId: "mb1ghscop6k",
                  servicefee: [],
                  selected: true,
                },
              ],
              tktair: "FZ",
              tecst: "no",
              cl: "Economy",
              extra: 13919296,
              token:
                "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiYXBpdGF3ZmVlcSIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL2dpdmVubmFtZSI6IkFQSSBUQVdGRUVRIiwiZ3JvdXBBY2Nlc3MiOiIzLDM2LDExMiIsImlzQXB0Q2xudCI6IkZhbHNlIiwibG9jYXRpb24iOiI5OTk5IiwiY2hrTGNuIjoiIiwicmVzVG9rZW4iOiJkZWRhZDdkeWY4N2FuOTc0dGJxYjY0YmJ6ZjZlOTYwYzA5ZTE5NmRhZTg2MCIsImNsaWVudElQIjoiMTcyLjE5LjIzLjU0IiwicmVzQ2hhbm5lbElEIjoiNSIsInJlc0NoYW5uZWxOYW1lIjoiVFBBUEkiLCJkZWZhdWx0VXNlck5hbWUiOiJhcGl0YXdmZWVxIiwibmJmIjoxNjg1NTE1MjM3LCJleHAiOjE2ODU1MTc2MzcsImlzcyI6ImZseWR1YmFpLmNvbSIsImF1ZCI6IlRBV0ZFRVFUUlZMU19GWl9OUCJ9.rAW3YT-bX_-yADfvg3QkuPAbOJhkhRUN6HwoS_6PyiM",
              stop: 1,
            },
            {
              tid: "SD3qJmnlONlkzx6q_1",
              ref: 1,
              office_id: "",
              sdur: "19:25",
              re: "",
              sup: "5",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DXB",
                  dd: "2023-06-07T03:00:00",
                  ad: "2023-06-07T05:30:00",
                  rbd: "",
                  fn: "436",
                  trn: "",
                  eq: "73B",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "", arr: "2" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161457,
                  flightTime: 240,
                  fdur: "4:0",
                },
                {
                  ref: 2,
                  da: "DXB",
                  ar: "DOH",
                  dd: "2023-06-07T19:45:00",
                  ad: "2023-06-07T19:55:00",
                  rbd: "",
                  fn: "005",
                  trn: "",
                  eq: "73X",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "2", arr: "1" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161381,
                  flightTime: 70,
                  fdur: "1:10",
                },
              ],
              fareop: [
                {
                  fareKey: [],
                  pr: {
                    bf: 14455,
                    tf: 25790.21,
                    tax: [
                      { OT: 11335.21 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    scur: "INR",
                    ucur: "INR",
                    exchange_rate: 1,
                    sup_fare: "14455_25790.21",
                    sup_cur_fare: "",
                    cabb: "Free",
                    chekb: "7kg INCLUDED",
                    meals: "Standard meal",
                    chanb: "Chargeable",
                    ftyp: "VALUE",
                    extra: "12_VALUE",
                    chekbcode: "BAGL",
                    chanb_info:
                      "UPTO 24 hrs of departure Change of Flight is Free,WITHIN From 0-24 hrs of departure Change of Flight is Non-Refundable,NOSHOW From 0 hrs before  of departure Change of Flight is Non-Refundable",
                    canb: "Chargeable",
                    cabb_info:
                      "UPTO 24 hrs of departure Cancellation of Flight is Free,WITHIN From 0-24 hrs of departure Cancellation of Flight is Non-Refundable,NOSHOW From 0 hrs before  of departure Cancellation of Flight is Non-Refundable",
                    bagage_info: "30kg BAG INCLUDED IN FARE",
                    markupnames: [],
                    ttf: 25790.21,
                  },
                  paxpr: [
                    {
                      bf: 14455,
                      tf: 25790.21,
                      tax: [
                        { OT: 11335.21 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "ORL7IN5",
                      ptype: "ADT",
                      scur: "INR",
                      extra:
                        "1_99_O_1_9_0_tx-2186:170:PZ:0_4246:6450:YQ:0_8947:0:BAGL:0_9327:1030.21:K3:0_10386:120:ZR:0_11947:790:F6:0_12006:1170:P2:0_12434:1605:IN:0_",
                      ruleid:
                        "TOFXEs65WehISL3jQ+TZqRM1YiBqo5VUngE+f6ZTlrcWhTrLfc+1HjPslNbsK6Ma+NzJ9vs0yTvfIGzHK+7a6QYdbLMNnyuqz+GQYY8X4uc35QrFzNp0saGa5iSUexZp",
                      hsc: "eba8be33629ed85a625ab6fdf799fd8f7f410b705b44f9bcd95f9c5bb49694cc",
                      sup_fare: "14455_25790.21_14005",
                      cabb: "Free",
                      bagage_info: "30kg BAG INCLUDED IN FARE",
                      chekbcode: "BAGL",
                      psup_fare: "14455_25790.21",
                    },
                  ],
                  uniqueId: "VALUE",
                  routeId: "bfyc6c12vn",
                  servicefee: [],
                  selected: true,
                },
              ],
              tktair: "FZ",
              tecst: "no",
              cl: "Economy",
              extra: 13919294,
              token:
                "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiYXBpdGF3ZmVlcSIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL2dpdmVubmFtZSI6IkFQSSBUQVdGRUVRIiwiZ3JvdXBBY2Nlc3MiOiIzLDM2LDExMiIsImlzQXB0Q2xudCI6IkZhbHNlIiwibG9jYXRpb24iOiI5OTk5IiwiY2hrTGNuIjoiIiwicmVzVG9rZW4iOiJkZWRhZDdkeWY4N2FuOTc0dGJxYjY0YmJ6ZjZlOTYwYzA5ZTE5NmRhZTg2MCIsImNsaWVudElQIjoiMTcyLjE5LjIzLjU0IiwicmVzQ2hhbm5lbElEIjoiNSIsInJlc0NoYW5uZWxOYW1lIjoiVFBBUEkiLCJkZWZhdWx0VXNlck5hbWUiOiJhcGl0YXdmZWVxIiwibmJmIjoxNjg1NTE1MjM3LCJleHAiOjE2ODU1MTc2MzcsImlzcyI6ImZseWR1YmFpLmNvbSIsImF1ZCI6IlRBV0ZFRVFUUlZMU19GWl9OUCJ9.rAW3YT-bX_-yADfvg3QkuPAbOJhkhRUN6HwoS_6PyiM",
              stop: 1,
            },
          ],
          paxpr: [
            {
              bf: 27710,
              tf: 50515,
              tax: [
                { OT: 22805 },
                { attmkp: 0 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              fbc: "ORL7IN5",
              ptype: "ADT",
              scur: "INR",
              extra:
                "1_5_O_1_9_0_tx-2186:170:PZ:0_4246:6450:YQ:0_8947:0:BAGL:0_9327:1030.21:K3:0_10386:120:ZR:0_11947:790:F6:0_12006:1170:P2:0_12434:1605:IN:0_",
              ruleid:
                "ppnSmG7YUmp/G7Gg/9ZZboCMSUE+oAXNhfzC1AvPpsdXVy0HLLTzOxy6vo4KsNKyH4iOhL3qCTzeoIR/oeSzY4TEtPO2CThrAiJMBJQHPOYWhaoIILXy8P1UbN4ahWTL",
              hsc: "0fc37105182aa8cd9e1f888fc12216fb400020272fdbdb1ebc0bea027e24a0ef",
              sup_fare: "14455_25790.21_14005",
              cabb: "Free",
              bagage_info: "30kg BAG INCLUDED IN FARE",
              chekbcode: "BAGL",
              psup_fare: "27710_50515",
            },
          ],
          pr: {
            bf: 27710,
            tf: 50515,
            tax: [
              { OT: 22805 },
              { attmkp: 0 },
              { tamkp: 0 },
              { stamkp: 0 },
              { attdisc: 0 },
              { tadisc: 0 },
              { stadisc: 0 },
            ],
            scur: "INR",
            ucur: "INR",
            exchange_rate: 1,
            sup_fare: "27710_50515",
            sup_cur_fare: "",
            cabb: "Free",
            chekb: "7kg INCLUDED",
            meals: "Standard meal",
            chanb: "Chargeable",
            ftyp: "VALUE",
            extra: "12_VALUE",
            chekbcode: "BAGL",
            chanb_info:
              "UPTO 24 hrs of departure Change of Flight is Free,WITHIN From 0-24 hrs of departure Change of Flight is Non-Refundable,NOSHOW From 0 hrs before  of departure Change of Flight is Non-Refundable",
            canb: "Chargeable",
            cabb_info:
              "UPTO 24 hrs of departure Cancellation of Flight is Free,WITHIN From 0-24 hrs of departure Cancellation of Flight is Non-Refundable,NOSHOW From 0 hrs before  of departure Cancellation of Flight is Non-Refundable",
            bagage_info: "30kg BAG INCLUDED IN FARE",
            markupnames: [],
            ttf: 50515,
          },
          sup: "5",
          airlineList: "FZ",
          destorigins: [
            {
              tid: "68CH2MlWAKCkgfQO_2",
              ref: 2,
              office_id: "",
              sdur: "6:45",
              re: "",
              sup: "5",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DXB",
                  dd: "2023-06-09T17:00:00",
                  ad: "2023-06-09T19:15:00",
                  rbd: "",
                  fn: "018",
                  trn: "",
                  eq: "73X",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "1", arr: "2" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161399,
                  flightTime: 75,
                  fdur: "1:15",
                },
                {
                  ref: 2,
                  da: "DXB",
                  ar: "HYD",
                  dd: "2023-06-09T20:50:00",
                  ad: "2023-06-10T02:15:00",
                  rbd: "",
                  fn: "435",
                  trn: "",
                  eq: "73B",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "2", arr: "" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161456,
                  flightTime: 235,
                  fdur: "3:55",
                },
              ],
              fareop: [
                {
                  fareKey: [],
                  pr: {
                    bf: 13255,
                    tf: 24724.79,
                    tax: [
                      { OT: 11469.79 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    scur: "INR",
                    ucur: "INR",
                    exchange_rate: 1,
                    sup_fare: "13255_24724.79",
                    sup_cur_fare: "",
                    cabb: "Chargeable",
                    chekb: "7kg INCLUDED",
                    meals: "Chargeable",
                    chanb: "Non Refundable",
                    ftyp: "LITE",
                    extra: "11_LITE",
                    chekbcode: "",
                    chanb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Change of Flight is Non-Refundable,NOSHOW From 0 hrs before  of departure Change of Flight is Non-Refundable",
                    canb: "Non Refundable",
                    cabb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Cancellation of Flight is Non-Refundable,NOSHOW From 0 hrs before  of departure Cancellation of Flight is Non-Refundable",
                    bagage_info: "Chargeable",
                    markupnames: [],
                    ttf: 24724.79,
                  },
                  paxpr: [
                    {
                      bf: 13255,
                      tf: 24724.79,
                      tax: [
                        { OT: 11469.79 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "OR6IN5",
                      ptype: "ADT",
                      scur: "INR",
                      extra:
                        "1_6_O_1_9_0_tx-1764:1370:QA:0_2186:170:PZ:0_4246:6450:YQ:0_8966:1370:G4:0_9327:969.79:K3:0_10386:120:ZR:0_11947:790:F6:0_13230:230:R9:0_",
                      ruleid:
                        "Gus40UKmi3VwUR8Oyzr4lyH5cdRtjzMqmtc6Ydt53ymhQozXlONozKDeF7aO/DbGAO8K6tXzJ16Rfs5fUX41PoAzc0DAexpKMcT6yt4vWi67ueiX+7Xh5u3qyxSJhMOZBQGHoM3lqYcsurCRq3PlUw==",
                      hsc: "07c2192155c9fe63f78e4c95582734d3b8ff1e38f0efe86a0b2423ae0941b527",
                      sup_fare: "13255_24724.79_12805",
                      cabb: "Chargeable",
                      bagage_info: "Chargeable",
                      chekbcode: "",
                      psup_fare: "13255_24724.79",
                    },
                  ],
                  uniqueId: "LITE",
                  routeId: "campbp6j6i",
                  servicefee: [],
                  selected: true,
                },
              ],
              tktair: "FZ",
              tecst: "no",
              cl: "Economy",
              extra: 13916393,
              token:
                "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiYXBpdGF3ZmVlcSIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL2dpdmVubmFtZSI6IkFQSSBUQVdGRUVRIiwiZ3JvdXBBY2Nlc3MiOiIzLDM2LDExMiIsImlzQXB0Q2xudCI6IkZhbHNlIiwibG9jYXRpb24iOiI5OTk5IiwiY2hrTGNuIjoiIiwicmVzVG9rZW4iOiJkZWRhZDdkeWY4N2FuOTc0dGJxYjY0YmJ6ZjZlOTYwYzA5ZTE5NmRhZTg2MCIsImNsaWVudElQIjoiMTcyLjE5LjIzLjU0IiwicmVzQ2hhbm5lbElEIjoiNSIsInJlc0NoYW5uZWxOYW1lIjoiVFBBUEkiLCJkZWZhdWx0VXNlck5hbWUiOiJhcGl0YXdmZWVxIiwibmJmIjoxNjg1NTE1MjM3LCJleHAiOjE2ODU1MTc2MzcsImlzcyI6ImZseWR1YmFpLmNvbSIsImF1ZCI6IlRBV0ZFRVFUUlZMU19GWl9OUCJ9.rAW3YT-bX_-yADfvg3QkuPAbOJhkhRUN6HwoS_6PyiM",
              stop: 1,
            },
            {
              tid: "KJCKCNLMWDfszU8M_2",
              ref: 2,
              office_id: "",
              sdur: "13:45",
              re: "",
              sup: "5",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DXB",
                  dd: "2023-06-09T10:00:00",
                  ad: "2023-06-09T12:15:00",
                  rbd: "",
                  fn: "002",
                  trn: "",
                  eq: "73X",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "1", arr: "2" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161402,
                  flightTime: 75,
                  fdur: "1:15",
                },
                {
                  ref: 2,
                  da: "DXB",
                  ar: "HYD",
                  dd: "2023-06-09T20:50:00",
                  ad: "2023-06-10T02:15:00",
                  rbd: "",
                  fn: "435",
                  trn: "",
                  eq: "73B",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "2", arr: "" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161456,
                  flightTime: 235,
                  fdur: "3:55",
                },
              ],
              fareop: [
                {
                  fareKey: [],
                  pr: {
                    bf: 13255,
                    tf: 24724.79,
                    tax: [
                      { OT: 11469.79 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    scur: "INR",
                    ucur: "INR",
                    exchange_rate: 1,
                    sup_fare: "13255_24724.79",
                    sup_cur_fare: "",
                    cabb: "Chargeable",
                    chekb: "7kg INCLUDED",
                    meals: "Chargeable",
                    chanb: "Non Refundable",
                    ftyp: "LITE",
                    extra: "11_LITE",
                    chekbcode: "",
                    chanb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Change of Flight is Non-Refundable,NOSHOW From 0 hrs before  of departure Change of Flight is Non-Refundable",
                    canb: "Non Refundable",
                    cabb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Cancellation of Flight is Non-Refundable,NOSHOW From 0 hrs before  of departure Cancellation of Flight is Non-Refundable",
                    bagage_info: "Chargeable",
                    markupnames: [],
                    ttf: 24724.79,
                  },
                  paxpr: [
                    {
                      bf: 13255,
                      tf: 24724.79,
                      tax: [
                        { OT: 11469.79 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "OR6IN5",
                      ptype: "ADT",
                      scur: "INR",
                      extra:
                        "1_38_O_1_9_0_tx-1764:1370:QA:0_2186:170:PZ:0_4246:6450:YQ:0_8966:1370:G4:0_9327:969.79:K3:0_10386:120:ZR:0_11947:790:F6:0_13230:230:R9:0_",
                      ruleid:
                        "sMjYSkPEAsHAOFItkuEOumDGJNH37aAsxUou/hEUve1ewJ9aVhK57wibWY2PIn24f4tv7xijw4oXgcRHgmFw0E3Dg72VJaVpJZuTCj214MZgK98nMtGteAZSZm1qHO8JeXH1hDuwDQ+MlHme53FHEA==",
                      hsc: "5a0eb312e4215e283789d03ec36c8b65e91e4db9d2238779a425dc08b25cf60b",
                      sup_fare: "13255_24724.79_12805",
                      cabb: "Chargeable",
                      bagage_info: "Chargeable",
                      chekbcode: "",
                      psup_fare: "13255_24724.79",
                    },
                  ],
                  uniqueId: "LITE",
                  routeId: "y8gr4d4hm3",
                  servicefee: [],
                  selected: true,
                },
              ],
              tktair: "FZ",
              tecst: "no",
              cl: "Economy",
              extra: 13915297,
              token:
                "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiYXBpdGF3ZmVlcSIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL2dpdmVubmFtZSI6IkFQSSBUQVdGRUVRIiwiZ3JvdXBBY2Nlc3MiOiIzLDM2LDExMiIsImlzQXB0Q2xudCI6IkZhbHNlIiwibG9jYXRpb24iOiI5OTk5IiwiY2hrTGNuIjoiIiwicmVzVG9rZW4iOiJkZWRhZDdkeWY4N2FuOTc0dGJxYjY0YmJ6ZjZlOTYwYzA5ZTE5NmRhZTg2MCIsImNsaWVudElQIjoiMTcyLjE5LjIzLjU0IiwicmVzQ2hhbm5lbElEIjoiNSIsInJlc0NoYW5uZWxOYW1lIjoiVFBBUEkiLCJkZWZhdWx0VXNlck5hbWUiOiJhcGl0YXdmZWVxIiwibmJmIjoxNjg1NTE1MjM3LCJleHAiOjE2ODU1MTc2MzcsImlzcyI6ImZseWR1YmFpLmNvbSIsImF1ZCI6IlRBV0ZFRVFUUlZMU19GWl9OUCJ9.rAW3YT-bX_-yADfvg3QkuPAbOJhkhRUN6HwoS_6PyiM",
              stop: 1,
            },
          ],
          servicefee: [],
        },
        {
          srcorigns: [
            {
              tid: "PWgRa3CkTWmKQF92_1",
              ref: 1,
              office_id: "",
              sdur: "8:25",
              re: "",
              sup: "5",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DXB",
                  dd: "2023-06-07T03:00:00",
                  ad: "2023-06-07T05:30:00",
                  rbd: "",
                  fn: "436",
                  trn: "",
                  eq: "73B",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "", arr: "2" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161457,
                  flightTime: 240,
                  fdur: "4:0",
                },
                {
                  ref: 2,
                  da: "DXB",
                  ar: "DOH",
                  dd: "2023-06-07T08:45:00",
                  ad: "2023-06-07T08:55:00",
                  rbd: "",
                  fn: "001",
                  trn: "",
                  eq: "73X",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "2", arr: "1" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161405,
                  flightTime: 70,
                  fdur: "1:10",
                },
              ],
              fareop: [
                {
                  fareKey: [],
                  pr: {
                    bf: 14455,
                    tf: 25790,
                    tax: [
                      { OT: 11335 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    scur: "INR",
                    ucur: "INR",
                    exchange_rate: 1,
                    sup_fare: "14455_25790",
                    sup_cur_fare: "",
                    cabb: "Free",
                    chekb: "7kg INCLUDED",
                    meals: "Standard meal",
                    chanb: "Chargeable",
                    ftyp: "VALUE",
                    extra: "12_VALUE",
                    chekbcode: "BAGL",
                    chanb_info:
                      "UPTO 24 hrs of departure Change of Flight is Free,WITHIN From 0-24 hrs of departure Change of Flight is Non-Refundable,NOSHOW From 0 hrs before  of departure Change of Flight is Non-Refundable",
                    canb: "Chargeable",
                    cabb_info:
                      "UPTO 24 hrs of departure Cancellation of Flight is Free,WITHIN From 0-24 hrs of departure Cancellation of Flight is Non-Refundable,NOSHOW From 0 hrs before  of departure Cancellation of Flight is Non-Refundable",
                    bagage_info: "30kg BAG INCLUDED IN FARE",
                    markupnames: [],
                    ttf: 25790,
                  },
                  paxpr: [
                    {
                      bf: 14455,
                      tf: 25790,
                      tax: [
                        { OT: 11335 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "ORL7IN5",
                      ptype: "ADT",
                      scur: "INR",
                      extra:
                        "1_7_O_1_9_0_tx-2186:170:PZ:0_4246:6450:YQ:0_8947:0:BAGL:0_9327:1030:K3:0_10386:120:ZR:0_11947:790:F6:0_12006:1170:P2:0_12434:1605:IN:0_",
                      ruleid:
                        "FNGlyt2zJiHzBOC2OtX1TICMSUE+oAXNhfzC1AvPpsdXVy0HLLTzOxy6vo4KsNKyH4iOhL3qCTzeoIR/oeSzY4TEtPO2CThrAiJMBJQHPOYWhaoIILXy8P1UbN4ahWTL",
                      hsc: "e5c9e26718cec240afe56a1593c43808b4f7d222eb4ddae72243508c42dda489",
                      sup_fare: "14455_25790_14005",
                      cabb: "Free",
                      bagage_info: "30kg BAG INCLUDED IN FARE",
                      chekbcode: "BAGL",
                      psup_fare: "14455_25790",
                    },
                  ],
                  uniqueId: "VALUE",
                  routeId: "sk0ecy8cpi",
                  servicefee: [],
                  selected: true,
                },
              ],
              tktair: "FZ",
              tecst: "no",
              cl: "Economy",
              extra: 13919293,
              token:
                "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiYXBpdGF3ZmVlcSIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL2dpdmVubmFtZSI6IkFQSSBUQVdGRUVRIiwiZ3JvdXBBY2Nlc3MiOiIzLDM2LDExMiIsImlzQXB0Q2xudCI6IkZhbHNlIiwibG9jYXRpb24iOiI5OTk5IiwiY2hrTGNuIjoiIiwicmVzVG9rZW4iOiJkZWRhZDdkeWY4N2FuOTc0dGJxYjY0YmJ6ZjZlOTYwYzA5ZTE5NmRhZTg2MCIsImNsaWVudElQIjoiMTcyLjE5LjIzLjU0IiwicmVzQ2hhbm5lbElEIjoiNSIsInJlc0NoYW5uZWxOYW1lIjoiVFBBUEkiLCJkZWZhdWx0VXNlck5hbWUiOiJhcGl0YXdmZWVxIiwibmJmIjoxNjg1NTE1MjM3LCJleHAiOjE2ODU1MTc2MzcsImlzcyI6ImZseWR1YmFpLmNvbSIsImF1ZCI6IlRBV0ZFRVFUUlZMU19GWl9OUCJ9.rAW3YT-bX_-yADfvg3QkuPAbOJhkhRUN6HwoS_6PyiM",
              stop: 1,
            },
            {
              tid: "ZWNdKd8tAWeLIhNR_1",
              ref: 1,
              office_id: "",
              sdur: "15:25",
              re: "",
              sup: "5",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DXB",
                  dd: "2023-06-07T03:00:00",
                  ad: "2023-06-07T05:30:00",
                  rbd: "",
                  fn: "436",
                  trn: "",
                  eq: "73B",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "", arr: "2" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161457,
                  flightTime: 240,
                  fdur: "4:0",
                },
                {
                  ref: 2,
                  da: "DXB",
                  ar: "DOH",
                  dd: "2023-06-07T15:45:00",
                  ad: "2023-06-07T15:55:00",
                  rbd: "",
                  fn: "017",
                  trn: "",
                  eq: "73X",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "2", arr: "1" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161400,
                  flightTime: 70,
                  fdur: "1:10",
                },
              ],
              fareop: [
                {
                  fareKey: [],
                  pr: {
                    bf: 14455,
                    tf: 25790,
                    tax: [
                      { OT: 11335 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    scur: "INR",
                    ucur: "INR",
                    exchange_rate: 1,
                    sup_fare: "14455_25790",
                    sup_cur_fare: "",
                    cabb: "Free",
                    chekb: "7kg INCLUDED",
                    meals: "Standard meal",
                    chanb: "Chargeable",
                    ftyp: "VALUE",
                    extra: "12_VALUE",
                    chekbcode: "BAGL",
                    chanb_info:
                      "UPTO 24 hrs of departure Change of Flight is Free,WITHIN From 0-24 hrs of departure Change of Flight is Non-Refundable,NOSHOW From 0 hrs before  of departure Change of Flight is Non-Refundable",
                    canb: "Chargeable",
                    cabb_info:
                      "UPTO 24 hrs of departure Cancellation of Flight is Free,WITHIN From 0-24 hrs of departure Cancellation of Flight is Non-Refundable,NOSHOW From 0 hrs before  of departure Cancellation of Flight is Non-Refundable",
                    bagage_info: "30kg BAG INCLUDED IN FARE",
                    markupnames: [],
                    ttf: 25790,
                  },
                  paxpr: [
                    {
                      bf: 14455,
                      tf: 25790,
                      tax: [
                        { OT: 11335 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "ORL7IN5",
                      ptype: "ADT",
                      scur: "INR",
                      extra:
                        "1_71_O_1_9_0_tx-2186:170:PZ:0_4246:6450:YQ:0_8947:0:BAGL:0_9327:1030:K3:0_10386:120:ZR:0_11947:790:F6:0_12006:1170:P2:0_12434:1605:IN:0_",
                      ruleid:
                        "+QdJYPL5xbDx5W0oKV5MWxM1YiBqo5VUngE+f6ZTlrcWhTrLfc+1HjPslNbsK6Ma+NzJ9vs0yTvfIGzHK+7a6QYdbLMNnyuqz+GQYY8X4uc35QrFzNp0saGa5iSUexZp",
                      hsc: "ebf122e7daa202f595f3cb1450eaf76867d0f00c1a99bfc66573bed0503805b7",
                      sup_fare: "14455_25790_14005",
                      cabb: "Free",
                      bagage_info: "30kg BAG INCLUDED IN FARE",
                      chekbcode: "BAGL",
                      psup_fare: "14455_25790",
                    },
                  ],
                  uniqueId: "VALUE",
                  routeId: "fcmm9bs4vr",
                  servicefee: [],
                  selected: true,
                },
              ],
              tktair: "FZ",
              tecst: "no",
              cl: "Economy",
              extra: 13919296,
              token:
                "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiYXBpdGF3ZmVlcSIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL2dpdmVubmFtZSI6IkFQSSBUQVdGRUVRIiwiZ3JvdXBBY2Nlc3MiOiIzLDM2LDExMiIsImlzQXB0Q2xudCI6IkZhbHNlIiwibG9jYXRpb24iOiI5OTk5IiwiY2hrTGNuIjoiIiwicmVzVG9rZW4iOiJkZWRhZDdkeWY4N2FuOTc0dGJxYjY0YmJ6ZjZlOTYwYzA5ZTE5NmRhZTg2MCIsImNsaWVudElQIjoiMTcyLjE5LjIzLjU0IiwicmVzQ2hhbm5lbElEIjoiNSIsInJlc0NoYW5uZWxOYW1lIjoiVFBBUEkiLCJkZWZhdWx0VXNlck5hbWUiOiJhcGl0YXdmZWVxIiwibmJmIjoxNjg1NTE1MjM3LCJleHAiOjE2ODU1MTc2MzcsImlzcyI6ImZseWR1YmFpLmNvbSIsImF1ZCI6IlRBV0ZFRVFUUlZMU19GWl9OUCJ9.rAW3YT-bX_-yADfvg3QkuPAbOJhkhRUN6HwoS_6PyiM",
              stop: 1,
            },
            {
              tid: "tcs7QVi6FE24TQiy_1",
              ref: 1,
              office_id: "",
              sdur: "19:25",
              re: "",
              sup: "5",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DXB",
                  dd: "2023-06-07T03:00:00",
                  ad: "2023-06-07T05:30:00",
                  rbd: "",
                  fn: "436",
                  trn: "",
                  eq: "73B",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "", arr: "2" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161457,
                  flightTime: 240,
                  fdur: "4:0",
                },
                {
                  ref: 2,
                  da: "DXB",
                  ar: "DOH",
                  dd: "2023-06-07T19:45:00",
                  ad: "2023-06-07T19:55:00",
                  rbd: "",
                  fn: "005",
                  trn: "",
                  eq: "73X",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "2", arr: "1" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161381,
                  flightTime: 70,
                  fdur: "1:10",
                },
              ],
              fareop: [
                {
                  fareKey: [],
                  pr: {
                    bf: 14455,
                    tf: 25790,
                    tax: [
                      { OT: 11335 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    scur: "INR",
                    ucur: "INR",
                    exchange_rate: 1,
                    sup_fare: "14455_25790",
                    sup_cur_fare: "",
                    cabb: "Free",
                    chekb: "7kg INCLUDED",
                    meals: "Standard meal",
                    chanb: "Chargeable",
                    ftyp: "VALUE",
                    extra: "12_VALUE",
                    chekbcode: "BAGL",
                    chanb_info:
                      "UPTO 24 hrs of departure Change of Flight is Free,WITHIN From 0-24 hrs of departure Change of Flight is Non-Refundable,NOSHOW From 0 hrs before  of departure Change of Flight is Non-Refundable",
                    canb: "Chargeable",
                    cabb_info:
                      "UPTO 24 hrs of departure Cancellation of Flight is Free,WITHIN From 0-24 hrs of departure Cancellation of Flight is Non-Refundable,NOSHOW From 0 hrs before  of departure Cancellation of Flight is Non-Refundable",
                    bagage_info: "30kg BAG INCLUDED IN FARE",
                    markupnames: [],
                    ttf: 25790,
                  },
                  paxpr: [
                    {
                      bf: 14455,
                      tf: 25790,
                      tax: [
                        { OT: 11335 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "ORL7IN5",
                      ptype: "ADT",
                      scur: "INR",
                      extra:
                        "1_103_O_1_9_0_tx-2186:170:PZ:0_4246:6450:YQ:0_8947:0:BAGL:0_9327:1030:K3:0_10386:120:ZR:0_11947:790:F6:0_12006:1170:P2:0_12434:1605:IN:0_",
                      ruleid:
                        "Oqb6AkQdMBUaidSlhHne6BZ+CHIn9HNL+12RdyPuUypN3Vrd+8Of0oA7a4N7g8CvZItP8LZIcrAqzBeCOoIuvjLwhemGenh8Kvds7rAxHg6X710uw+p5f6/y1zghrpgJ",
                      hsc: "f92ea6e8957c16429ef32ddc5e70c7f3b5b7f26e339e825e6b4edfd86626b43b",
                      sup_fare: "14455_25790_14005",
                      cabb: "Free",
                      bagage_info: "30kg BAG INCLUDED IN FARE",
                      chekbcode: "BAGL",
                      psup_fare: "14455_25790",
                    },
                  ],
                  uniqueId: "VALUE",
                  routeId: "o6zrriao76",
                  servicefee: [],
                  selected: true,
                },
              ],
              tktair: "FZ",
              tecst: "no",
              cl: "Economy",
              extra: 13919294,
              token:
                "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiYXBpdGF3ZmVlcSIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL2dpdmVubmFtZSI6IkFQSSBUQVdGRUVRIiwiZ3JvdXBBY2Nlc3MiOiIzLDM2LDExMiIsImlzQXB0Q2xudCI6IkZhbHNlIiwibG9jYXRpb24iOiI5OTk5IiwiY2hrTGNuIjoiIiwicmVzVG9rZW4iOiJkZWRhZDdkeWY4N2FuOTc0dGJxYjY0YmJ6ZjZlOTYwYzA5ZTE5NmRhZTg2MCIsImNsaWVudElQIjoiMTcyLjE5LjIzLjU0IiwicmVzQ2hhbm5lbElEIjoiNSIsInJlc0NoYW5uZWxOYW1lIjoiVFBBUEkiLCJkZWZhdWx0VXNlck5hbWUiOiJhcGl0YXdmZWVxIiwibmJmIjoxNjg1NTE1MjM3LCJleHAiOjE2ODU1MTc2MzcsImlzcyI6ImZseWR1YmFpLmNvbSIsImF1ZCI6IlRBV0ZFRVFUUlZMU19GWl9OUCJ9.rAW3YT-bX_-yADfvg3QkuPAbOJhkhRUN6HwoS_6PyiM",
              stop: 1,
            },
          ],
          paxpr: [
            {
              bf: 28910,
              tf: 51775,
              tax: [
                { OT: 22865 },
                { attmkp: 0 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              fbc: "ORL7IN5",
              ptype: "ADT",
              scur: "INR",
              extra:
                "1_7_O_1_9_0_tx-2186:170:PZ:0_4246:6450:YQ:0_8947:0:BAGL:0_9327:1030:K3:0_10386:120:ZR:0_11947:790:F6:0_12006:1170:P2:0_12434:1605:IN:0_",
              ruleid:
                "FNGlyt2zJiHzBOC2OtX1TICMSUE+oAXNhfzC1AvPpsdXVy0HLLTzOxy6vo4KsNKyH4iOhL3qCTzeoIR/oeSzY4TEtPO2CThrAiJMBJQHPOYWhaoIILXy8P1UbN4ahWTL",
              hsc: "e5c9e26718cec240afe56a1593c43808b4f7d222eb4ddae72243508c42dda489",
              sup_fare: "14455_25790_14005",
              cabb: "Free",
              bagage_info: "30kg BAG INCLUDED IN FARE",
              chekbcode: "BAGL",
              psup_fare: "28910_51775",
            },
          ],
          pr: {
            bf: 28910,
            tf: 51775,
            tax: [
              { OT: 22865 },
              { attmkp: 0 },
              { tamkp: 0 },
              { stamkp: 0 },
              { attdisc: 0 },
              { tadisc: 0 },
              { stadisc: 0 },
            ],
            scur: "INR",
            ucur: "INR",
            exchange_rate: 1,
            sup_fare: "28910_51775",
            sup_cur_fare: "",
            cabb: "Free",
            chekb: "7kg INCLUDED",
            meals: "Standard meal",
            chanb: "Chargeable",
            ftyp: "VALUE",
            extra: "12_VALUE",
            chekbcode: "BAGL",
            chanb_info:
              "UPTO 24 hrs of departure Change of Flight is Free,WITHIN From 0-24 hrs of departure Change of Flight is Non-Refundable,NOSHOW From 0 hrs before  of departure Change of Flight is Non-Refundable",
            canb: "Chargeable",
            cabb_info:
              "UPTO 24 hrs of departure Cancellation of Flight is Free,WITHIN From 0-24 hrs of departure Cancellation of Flight is Non-Refundable,NOSHOW From 0 hrs before  of departure Cancellation of Flight is Non-Refundable",
            bagage_info: "30kg BAG INCLUDED IN FARE",
            markupnames: [],
            ttf: 51775,
          },
          sup: "5",
          airlineList: "FZ",
          destorigins: [
            {
              tid: "zlutlkGmEW5FTNTw_2",
              ref: 2,
              office_id: "",
              sdur: "6:45",
              re: "",
              sup: "5",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DXB",
                  dd: "2023-06-09T17:00:00",
                  ad: "2023-06-09T19:15:00",
                  rbd: "",
                  fn: "018",
                  trn: "",
                  eq: "73X",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "1", arr: "2" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161399,
                  flightTime: 75,
                  fdur: "1:15",
                },
                {
                  ref: 2,
                  da: "DXB",
                  ar: "HYD",
                  dd: "2023-06-09T20:50:00",
                  ad: "2023-06-10T02:15:00",
                  rbd: "",
                  fn: "435",
                  trn: "",
                  eq: "73B",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "2", arr: "" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161456,
                  flightTime: 235,
                  fdur: "3:55",
                },
              ],
              fareop: [
                {
                  fareKey: [],
                  pr: {
                    bf: 14455,
                    tf: 25985,
                    tax: [
                      { OT: 11530 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    scur: "INR",
                    ucur: "INR",
                    exchange_rate: 1,
                    sup_fare: "14455_25985",
                    sup_cur_fare: "",
                    cabb: "Free",
                    chekb: "7kg INCLUDED",
                    meals: "Standard meal",
                    chanb: "Chargeable",
                    ftyp: "VALUE",
                    extra: "12_VALUE",
                    chekbcode: "BAGL",
                    chanb_info:
                      "UPTO 24 hrs of departure Change of Flight is Free,WITHIN From 0-24 hrs of departure Change of Flight is Non-Refundable,NOSHOW From 0 hrs before  of departure Change of Flight is Non-Refundable",
                    canb: "Chargeable",
                    cabb_info:
                      "UPTO 24 hrs of departure Cancellation of Flight is Free,WITHIN From 0-24 hrs of departure Cancellation of Flight is Non-Refundable,NOSHOW From 0 hrs before  of departure Cancellation of Flight is Non-Refundable",
                    bagage_info: "30kg BAG INCLUDED IN FARE",
                    markupnames: [],
                    ttf: 25985,
                  },
                  paxpr: [
                    {
                      bf: 14455,
                      tf: 25985,
                      tax: [
                        { OT: 11530 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "ORL7IN5",
                      ptype: "ADT",
                      scur: "INR",
                      extra:
                        "1_8_O_1_9_0_tx-1764:1370:QA:0_2186:170:PZ:0_4246:6450:YQ:0_8947:0:BAGL:0_8966:1370:G4:0_9327:1030:K3:0_10386:120:ZR:0_11947:790:F6:0_13230:230:R9:0_",
                      ruleid:
                        "EMOpLXEl1ijumxvbv0gYOmDGJNH37aAsxUou/hEUve1ewJ9aVhK57wibWY2PIn24f4tv7xijw4oXgcRHgmFw0E3Dg72VJaVpJZuTCj214MbAKCkVAJmRzsz4wlYfVzNweXH1hDuwDQ+MlHme53FHEA==",
                      hsc: "b047e953531eebaf9fcffb6b9deba97b396fd532c4180fc8b904bd554902a062",
                      sup_fare: "14455_25985_14005",
                      cabb: "Free",
                      bagage_info: "30kg BAG INCLUDED IN FARE",
                      chekbcode: "BAGL",
                      psup_fare: "14455_25985",
                    },
                  ],
                  uniqueId: "VALUE",
                  routeId: "gnqs7f0sjg",
                  servicefee: [],
                  selected: true,
                },
              ],
              tktair: "FZ",
              tecst: "no",
              cl: "Economy",
              extra: 13916393,
              token:
                "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiYXBpdGF3ZmVlcSIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL2dpdmVubmFtZSI6IkFQSSBUQVdGRUVRIiwiZ3JvdXBBY2Nlc3MiOiIzLDM2LDExMiIsImlzQXB0Q2xudCI6IkZhbHNlIiwibG9jYXRpb24iOiI5OTk5IiwiY2hrTGNuIjoiIiwicmVzVG9rZW4iOiJkZWRhZDdkeWY4N2FuOTc0dGJxYjY0YmJ6ZjZlOTYwYzA5ZTE5NmRhZTg2MCIsImNsaWVudElQIjoiMTcyLjE5LjIzLjU0IiwicmVzQ2hhbm5lbElEIjoiNSIsInJlc0NoYW5uZWxOYW1lIjoiVFBBUEkiLCJkZWZhdWx0VXNlck5hbWUiOiJhcGl0YXdmZWVxIiwibmJmIjoxNjg1NTE1MjM3LCJleHAiOjE2ODU1MTc2MzcsImlzcyI6ImZseWR1YmFpLmNvbSIsImF1ZCI6IlRBV0ZFRVFUUlZMU19GWl9OUCJ9.rAW3YT-bX_-yADfvg3QkuPAbOJhkhRUN6HwoS_6PyiM",
              stop: 1,
            },
            {
              tid: "0hLI6rVGsgXByQ4V_2",
              ref: 2,
              office_id: "",
              sdur: "13:45",
              re: "",
              sup: "5",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DXB",
                  dd: "2023-06-09T10:00:00",
                  ad: "2023-06-09T12:15:00",
                  rbd: "",
                  fn: "002",
                  trn: "",
                  eq: "73X",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "1", arr: "2" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161402,
                  flightTime: 75,
                  fdur: "1:15",
                },
                {
                  ref: 2,
                  da: "DXB",
                  ar: "HYD",
                  dd: "2023-06-09T20:50:00",
                  ad: "2023-06-10T02:15:00",
                  rbd: "",
                  fn: "435",
                  trn: "",
                  eq: "73B",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "2", arr: "" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161456,
                  flightTime: 235,
                  fdur: "3:55",
                },
              ],
              fareop: [
                {
                  fareKey: [],
                  pr: {
                    bf: 14455,
                    tf: 25985,
                    tax: [
                      { OT: 11530 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    scur: "INR",
                    ucur: "INR",
                    exchange_rate: 1,
                    sup_fare: "14455_25985",
                    sup_cur_fare: "",
                    cabb: "Free",
                    chekb: "7kg INCLUDED",
                    meals: "Standard meal",
                    chanb: "Chargeable",
                    ftyp: "VALUE",
                    extra: "12_VALUE",
                    chekbcode: "BAGL",
                    chanb_info:
                      "UPTO 24 hrs of departure Change of Flight is Free,WITHIN From 0-24 hrs of departure Change of Flight is Non-Refundable,NOSHOW From 0 hrs before  of departure Change of Flight is Non-Refundable",
                    canb: "Chargeable",
                    cabb_info:
                      "UPTO 24 hrs of departure Cancellation of Flight is Free,WITHIN From 0-24 hrs of departure Cancellation of Flight is Non-Refundable,NOSHOW From 0 hrs before  of departure Cancellation of Flight is Non-Refundable",
                    bagage_info: "30kg BAG INCLUDED IN FARE",
                    markupnames: [],
                    ttf: 25985,
                  },
                  paxpr: [
                    {
                      bf: 14455,
                      tf: 25985,
                      tax: [
                        { OT: 11530 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "ORL7IN5",
                      ptype: "ADT",
                      scur: "INR",
                      extra:
                        "1_40_O_1_9_0_tx-1764:1370:QA:0_2186:170:PZ:0_4246:6450:YQ:0_8947:0:BAGL:0_8966:1370:G4:0_9327:1030:K3:0_10386:120:ZR:0_11947:790:F6:0_13230:230:R9:0_",
                      ruleid:
                        "0aEbqqTxdToALynEDRVSp24CvdfP67Yp1QfNWPSmae6JwVGRH27geGUyNcdqoz42Q+cpWZGlzHEqLD5ipHbUE/fms9aActHf3dUySn1KUD/cT1Te8NsMYkH9iH9Rtc9pV3Wx5QsmoEr7eVfjVNM8Og==",
                      hsc: "a8af7756a792e7530ed515e3b194d458559050b8d830488f5ab0784ff793183f",
                      sup_fare: "14455_25985_14005",
                      cabb: "Free",
                      bagage_info: "30kg BAG INCLUDED IN FARE",
                      chekbcode: "BAGL",
                      psup_fare: "14455_25985",
                    },
                  ],
                  uniqueId: "VALUE",
                  routeId: "2rfbovl972",
                  servicefee: [],
                  selected: true,
                },
              ],
              tktair: "FZ",
              tecst: "no",
              cl: "Economy",
              extra: 13915297,
              token:
                "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiYXBpdGF3ZmVlcSIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL2dpdmVubmFtZSI6IkFQSSBUQVdGRUVRIiwiZ3JvdXBBY2Nlc3MiOiIzLDM2LDExMiIsImlzQXB0Q2xudCI6IkZhbHNlIiwibG9jYXRpb24iOiI5OTk5IiwiY2hrTGNuIjoiIiwicmVzVG9rZW4iOiJkZWRhZDdkeWY4N2FuOTc0dGJxYjY0YmJ6ZjZlOTYwYzA5ZTE5NmRhZTg2MCIsImNsaWVudElQIjoiMTcyLjE5LjIzLjU0IiwicmVzQ2hhbm5lbElEIjoiNSIsInJlc0NoYW5uZWxOYW1lIjoiVFBBUEkiLCJkZWZhdWx0VXNlck5hbWUiOiJhcGl0YXdmZWVxIiwibmJmIjoxNjg1NTE1MjM3LCJleHAiOjE2ODU1MTc2MzcsImlzcyI6ImZseWR1YmFpLmNvbSIsImF1ZCI6IlRBV0ZFRVFUUlZMU19GWl9OUCJ9.rAW3YT-bX_-yADfvg3QkuPAbOJhkhRUN6HwoS_6PyiM",
              stop: 1,
            },
          ],
          servicefee: [],
        },
        {
          srcorigns: [
            {
              tid: "Jw7VUu5Zprllziwu_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "26:25",
              re: "Non-Refundable",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "UL",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "CMB",
                  dd: "2023-06-07T21:20:00",
                  ad: "2023-06-07T23:15:00",
                  rbd: "V",
                  fn: "176",
                  eq: "320",
                  ac: "UL",
                  ma: "UL",
                  seat: "9",
                  oa: "UL",
                  fa: [],
                  fdur: "01:55",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
                {
                  ref: 2,
                  da: "CMB",
                  ar: "DOH",
                  dd: "2023-06-08T18:40:00",
                  ad: "2023-06-08T21:15:00",
                  rbd: "V",
                  fn: "217",
                  eq: "333",
                  ac: "UL",
                  ma: "UL",
                  seat: "9",
                  oa: "UL",
                  fa: [],
                  fdur: "05:05",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
              ],
              flightNumbers: "176/217",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          destorigins: [
            {
              tid: "Jw7VUu5Zprllziwu_2",
              ref: 2,
              office_id: "DOHQR28IH",
              sdur: "08:20",
              re: "Non-Refundable",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "UL",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "CMB",
                  dd: "2023-06-09T22:30:00",
                  ad: "2023-06-10T05:45:00",
                  rbd: "L",
                  fn: "218",
                  eq: "333",
                  ac: "UL",
                  ma: "UL",
                  seat: "9",
                  oa: "UL",
                  fa: [],
                  fdur: "04:45",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
                {
                  ref: 2,
                  da: "CMB",
                  ar: "HYD",
                  dd: "2023-06-10T07:25:00",
                  ad: "2023-06-10T09:20:00",
                  rbd: "L",
                  fn: "177",
                  eq: "32B",
                  ac: "UL",
                  ma: "UL",
                  seat: "9",
                  oa: "UL",
                  fa: [],
                  fdur: "01:55",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
              ],
              flightNumbers: "218/177",
              bgd: [{ qun: "30", wt: "kg" }],
            },
            {
              tid: "2EbydrSO3ibtkuZP_2",
              ref: 2,
              office_id: "DOHQR28IH",
              sdur: "26:35",
              re: "Non-Refundable",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "UL",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "CMB",
                  dd: "2023-06-09T22:30:00",
                  ad: "2023-06-10T05:45:00",
                  rbd: "L",
                  fn: "218",
                  eq: "333",
                  ac: "UL",
                  ma: "UL",
                  seat: "9",
                  oa: "UL",
                  fa: [],
                  fdur: "04:45",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
                {
                  ref: 2,
                  da: "CMB",
                  ar: "HYD",
                  dd: "2023-06-11T01:40:00",
                  ad: "2023-06-11T03:35:00",
                  rbd: "L",
                  fn: "1177",
                  eq: "32B",
                  ac: "UL",
                  ma: "UL",
                  seat: "9",
                  oa: "UL",
                  fa: [],
                  fdur: "01:55",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
              ],
              flightNumbers: "218/1177",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          pr: {
            bf: 24147.2,
            tf: 50131.2,
            tax: [
              { YQ: 17472 },
              { OT: 8512 },
              { dealmkp: 0 },
              { attmkp: 2195.2 },
              { tamkp: 0 },
              { stamkp: 0 },
              { attdisc: 0 },
              { tadisc: 0 },
              { stadisc: 0 },
            ],
            scur: "QAR",
            ucur: "INR",
            exchange_rate: 22.4,
            sup_fare: "980_2140",
            markupnames: [{ code: "MTA", name: "LaxmiAprilMarkup" }],
          },
          paxpr: [
            {
              tf: 50131.2,
              tax: [
                { YQ: 17472 },
                { OT: 8512 },
                { attmkp: 2195.2 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              bf: 24147.2,
              fbc: "V1YIZ",
              fareType: "RP",
              pusup_fare: "980_2140",
              psup_fare: "21952_47936",
              ptype: "ADT",
            },
          ],
          sup: "0",
          airlinesList: "UL",
          servicefee: [
            {
              type: "ATT",
              adt: 2414.72,
              chd: 0,
              inf: 0,
              servicefee_name: "LaxmiServieApril",
              apl_on: "Per Ticket",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 2414.72,
              sup_fee: 0,
            },
            {
              type: "TA",
              adt: 0,
              chd: 0,
              inf: 0,
              apl_on: "",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 0,
              sup_fee: 2414.72,
            },
          ],
        },
        {
          srcorigns: [
            {
              tid: "diTAvrFwVzOgAWy6_1",
              ref: 1,
              office_id: "",
              sdur: "8:25",
              re: "",
              sup: "5",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DXB",
                  dd: "2023-06-07T03:00:00",
                  ad: "2023-06-07T05:30:00",
                  rbd: "",
                  fn: "436",
                  trn: "",
                  eq: "73B",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "", arr: "2" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161457,
                  flightTime: 240,
                  fdur: "4:0",
                },
                {
                  ref: 2,
                  da: "DXB",
                  ar: "DOH",
                  dd: "2023-06-07T08:45:00",
                  ad: "2023-06-07T08:55:00",
                  rbd: "",
                  fn: "001",
                  trn: "",
                  eq: "73X",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "2", arr: "1" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161405,
                  flightTime: 70,
                  fdur: "1:10",
                },
              ],
              fareop: [
                {
                  fareKey: [],
                  pr: {
                    bf: 13255,
                    tf: 24526.44,
                    tax: [
                      { OT: 11271.44 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    scur: "INR",
                    ucur: "INR",
                    exchange_rate: 1,
                    sup_fare: "13255_24526.44",
                    sup_cur_fare: "",
                    cabb: "Chargeable",
                    chekb: "7kg INCLUDED",
                    meals: "Chargeable",
                    chanb: "Non Refundable",
                    ftyp: "LITE",
                    extra: "11_LITE",
                    chekbcode: "",
                    chanb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Change of Flight is Non-Refundable,NOSHOW From 0 hrs before  of departure Change of Flight is Non-Refundable",
                    canb: "Non Refundable",
                    cabb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Cancellation of Flight is Non-Refundable,NOSHOW From 0 hrs before  of departure Cancellation of Flight is Non-Refundable",
                    bagage_info: "Chargeable",
                    markupnames: [],
                    ttf: 24526.44,
                  },
                  paxpr: [
                    {
                      bf: 13255,
                      tf: 24526.44,
                      tax: [
                        { OT: 11271.44 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "OR6IN5",
                      ptype: "ADT",
                      scur: "INR",
                      extra:
                        "1_9_O_1_9_0_tx-2186:170:PZ:0_4246:6450:YQ:0_9327:966.44:K3:0_10386:120:ZR:0_11947:790:F6:0_12006:1170:P2:0_12434:1605:IN:0_",
                      ruleid:
                        "JswS+I/5j1L2eBRI1cPMphM1YiBqo5VUngE+f6ZTlrcWhTrLfc+1HjPslNbsK6Ma+NzJ9vs0yTvfIGzHK+7a6QYdbLMNnyuqz+GQYY8X4ucqhXNHUPmc5WpT//+vZgmt",
                      hsc: "ac105cb315848594ff1102f464626a92183f5104e62e896876c5673973778340",
                      sup_fare: "13255_24526.44_12805",
                      cabb: "Chargeable",
                      bagage_info: "Chargeable",
                      chekbcode: "",
                      psup_fare: "13255_24526.44",
                    },
                  ],
                  uniqueId: "LITE",
                  routeId: "qtlptk5tvgi",
                  servicefee: [],
                  selected: true,
                },
              ],
              tktair: "FZ",
              tecst: "no",
              cl: "Economy",
              extra: 13919293,
              token:
                "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiYXBpdGF3ZmVlcSIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL2dpdmVubmFtZSI6IkFQSSBUQVdGRUVRIiwiZ3JvdXBBY2Nlc3MiOiIzLDM2LDExMiIsImlzQXB0Q2xudCI6IkZhbHNlIiwibG9jYXRpb24iOiI5OTk5IiwiY2hrTGNuIjoiIiwicmVzVG9rZW4iOiJkZWRhZDdkeWY4N2FuOTc0dGJxYjY0YmJ6ZjZlOTYwYzA5ZTE5NmRhZTg2MCIsImNsaWVudElQIjoiMTcyLjE5LjIzLjU0IiwicmVzQ2hhbm5lbElEIjoiNSIsInJlc0NoYW5uZWxOYW1lIjoiVFBBUEkiLCJkZWZhdWx0VXNlck5hbWUiOiJhcGl0YXdmZWVxIiwibmJmIjoxNjg1NTE1MjM3LCJleHAiOjE2ODU1MTc2MzcsImlzcyI6ImZseWR1YmFpLmNvbSIsImF1ZCI6IlRBV0ZFRVFUUlZMU19GWl9OUCJ9.rAW3YT-bX_-yADfvg3QkuPAbOJhkhRUN6HwoS_6PyiM",
              stop: 1,
            },
            {
              tid: "s2DCOICtv09PPTmf_1",
              ref: 1,
              office_id: "",
              sdur: "15:25",
              re: "",
              sup: "5",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DXB",
                  dd: "2023-06-07T03:00:00",
                  ad: "2023-06-07T05:30:00",
                  rbd: "",
                  fn: "436",
                  trn: "",
                  eq: "73B",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "", arr: "2" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161457,
                  flightTime: 240,
                  fdur: "4:0",
                },
                {
                  ref: 2,
                  da: "DXB",
                  ar: "DOH",
                  dd: "2023-06-07T15:45:00",
                  ad: "2023-06-07T15:55:00",
                  rbd: "",
                  fn: "017",
                  trn: "",
                  eq: "73X",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "2", arr: "1" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161400,
                  flightTime: 70,
                  fdur: "1:10",
                },
              ],
              fareop: [
                {
                  fareKey: [],
                  pr: {
                    bf: 13255,
                    tf: 24526.44,
                    tax: [
                      { OT: 11271.44 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    scur: "INR",
                    ucur: "INR",
                    exchange_rate: 1,
                    sup_fare: "13255_24526.44",
                    sup_cur_fare: "",
                    cabb: "Chargeable",
                    chekb: "7kg INCLUDED",
                    meals: "Chargeable",
                    chanb: "Non Refundable",
                    ftyp: "LITE",
                    extra: "11_LITE",
                    chekbcode: "",
                    chanb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Change of Flight is Non-Refundable,NOSHOW From 0 hrs before  of departure Change of Flight is Non-Refundable",
                    canb: "Non Refundable",
                    cabb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Cancellation of Flight is Non-Refundable,NOSHOW From 0 hrs before  of departure Cancellation of Flight is Non-Refundable",
                    bagage_info: "Chargeable",
                    markupnames: [],
                    ttf: 24526.44,
                  },
                  paxpr: [
                    {
                      bf: 13255,
                      tf: 24526.44,
                      tax: [
                        { OT: 11271.44 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "OR6IN5",
                      ptype: "ADT",
                      scur: "INR",
                      extra:
                        "1_73_O_1_9_0_tx-2186:170:PZ:0_4246:6450:YQ:0_9327:966.44:K3:0_10386:120:ZR:0_11947:790:F6:0_12006:1170:P2:0_12434:1605:IN:0_",
                      ruleid:
                        "D9wC5pkYoUPRSTHSKFjzlRZ+CHIn9HNL+12RdyPuUypN3Vrd+8Of0oA7a4N7g8CvZItP8LZIcrAqzBeCOoIuvjLwhemGenh8Kvds7rAxHg7lu4lkJ9t+YgS8Pv3j40Na",
                      hsc: "fa083a819d056aac47c5896a3479e66d8e261c8c54b1eb409f4d040f809b5b4c",
                      sup_fare: "13255_24526.44_12805",
                      cabb: "Chargeable",
                      bagage_info: "Chargeable",
                      chekbcode: "",
                      psup_fare: "13255_24526.44",
                    },
                  ],
                  uniqueId: "LITE",
                  routeId: "1yh55529rf",
                  servicefee: [],
                  selected: true,
                },
              ],
              tktair: "FZ",
              tecst: "no",
              cl: "Economy",
              extra: 13919296,
              token:
                "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiYXBpdGF3ZmVlcSIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL2dpdmVubmFtZSI6IkFQSSBUQVdGRUVRIiwiZ3JvdXBBY2Nlc3MiOiIzLDM2LDExMiIsImlzQXB0Q2xudCI6IkZhbHNlIiwibG9jYXRpb24iOiI5OTk5IiwiY2hrTGNuIjoiIiwicmVzVG9rZW4iOiJkZWRhZDdkeWY4N2FuOTc0dGJxYjY0YmJ6ZjZlOTYwYzA5ZTE5NmRhZTg2MCIsImNsaWVudElQIjoiMTcyLjE5LjIzLjU0IiwicmVzQ2hhbm5lbElEIjoiNSIsInJlc0NoYW5uZWxOYW1lIjoiVFBBUEkiLCJkZWZhdWx0VXNlck5hbWUiOiJhcGl0YXdmZWVxIiwibmJmIjoxNjg1NTE1MjM3LCJleHAiOjE2ODU1MTc2MzcsImlzcyI6ImZseWR1YmFpLmNvbSIsImF1ZCI6IlRBV0ZFRVFUUlZMU19GWl9OUCJ9.rAW3YT-bX_-yADfvg3QkuPAbOJhkhRUN6HwoS_6PyiM",
              stop: 1,
            },
            {
              tid: "Jlo1QvTDoh6RcU0h_1",
              ref: 1,
              office_id: "",
              sdur: "19:25",
              re: "",
              sup: "5",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DXB",
                  dd: "2023-06-07T03:00:00",
                  ad: "2023-06-07T05:30:00",
                  rbd: "",
                  fn: "436",
                  trn: "",
                  eq: "73B",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "", arr: "2" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161457,
                  flightTime: 240,
                  fdur: "4:0",
                },
                {
                  ref: 2,
                  da: "DXB",
                  ar: "DOH",
                  dd: "2023-06-07T19:45:00",
                  ad: "2023-06-07T19:55:00",
                  rbd: "",
                  fn: "005",
                  trn: "",
                  eq: "73X",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "2", arr: "1" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161381,
                  flightTime: 70,
                  fdur: "1:10",
                },
              ],
              fareop: [
                {
                  fareKey: [],
                  pr: {
                    bf: 13255,
                    tf: 24526.44,
                    tax: [
                      { OT: 11271.44 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    scur: "INR",
                    ucur: "INR",
                    exchange_rate: 1,
                    sup_fare: "13255_24526.44",
                    sup_cur_fare: "",
                    cabb: "Chargeable",
                    chekb: "7kg INCLUDED",
                    meals: "Chargeable",
                    chanb: "Non Refundable",
                    ftyp: "LITE",
                    extra: "11_LITE",
                    chekbcode: "",
                    chanb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Change of Flight is Non-Refundable,NOSHOW From 0 hrs before  of departure Change of Flight is Non-Refundable",
                    canb: "Non Refundable",
                    cabb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Cancellation of Flight is Non-Refundable,NOSHOW From 0 hrs before  of departure Cancellation of Flight is Non-Refundable",
                    bagage_info: "Chargeable",
                    markupnames: [],
                    ttf: 24526.44,
                  },
                  paxpr: [
                    {
                      bf: 13255,
                      tf: 24526.44,
                      tax: [
                        { OT: 11271.44 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "OR6IN5",
                      ptype: "ADT",
                      scur: "INR",
                      extra:
                        "1_105_O_1_9_0_tx-2186:170:PZ:0_4246:6450:YQ:0_9327:966.44:K3:0_10386:120:ZR:0_11947:790:F6:0_12006:1170:P2:0_12434:1605:IN:0_",
                      ruleid:
                        "oniY5M743imT+l86L4ZTRCH5cdRtjzMqmtc6Ydt53ymhQozXlONozKDeF7aO/DbGAO8K6tXzJ16Rfs5fUX41PoAzc0DAexpKMcT6yt4vWi67ueiX+7Xh5u3qyxSJhMOZBQGHoM3lqYcsurCRq3PlUw==",
                      hsc: "dd7172573d3e0ad961211c527ddeee3986c3ac2f73ef5b094a07412a8bccd44d",
                      sup_fare: "13255_24526.44_12805",
                      cabb: "Chargeable",
                      bagage_info: "Chargeable",
                      chekbcode: "",
                      psup_fare: "13255_24526.44",
                    },
                  ],
                  uniqueId: "LITE",
                  routeId: "i2urta5c1zl",
                  servicefee: [],
                  selected: true,
                },
              ],
              tktair: "FZ",
              tecst: "no",
              cl: "Economy",
              extra: 13919294,
              token:
                "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiYXBpdGF3ZmVlcSIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL2dpdmVubmFtZSI6IkFQSSBUQVdGRUVRIiwiZ3JvdXBBY2Nlc3MiOiIzLDM2LDExMiIsImlzQXB0Q2xudCI6IkZhbHNlIiwibG9jYXRpb24iOiI5OTk5IiwiY2hrTGNuIjoiIiwicmVzVG9rZW4iOiJkZWRhZDdkeWY4N2FuOTc0dGJxYjY0YmJ6ZjZlOTYwYzA5ZTE5NmRhZTg2MCIsImNsaWVudElQIjoiMTcyLjE5LjIzLjU0IiwicmVzQ2hhbm5lbElEIjoiNSIsInJlc0NoYW5uZWxOYW1lIjoiVFBBUEkiLCJkZWZhdWx0VXNlck5hbWUiOiJhcGl0YXdmZWVxIiwibmJmIjoxNjg1NTE1MjM3LCJleHAiOjE2ODU1MTc2MzcsImlzcyI6ImZseWR1YmFpLmNvbSIsImF1ZCI6IlRBV0ZFRVFUUlZMU19GWl9OUCJ9.rAW3YT-bX_-yADfvg3QkuPAbOJhkhRUN6HwoS_6PyiM",
              stop: 1,
            },
          ],
          paxpr: [
            {
              bf: 33240,
              tf: 56315,
              tax: [
                { OT: 23075 },
                { attmkp: 0 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              fbc: "OR6IN5",
              ptype: "ADT",
              scur: "INR",
              extra:
                "1_9_O_1_9_0_tx-2186:170:PZ:0_4246:6450:YQ:0_9327:966.44:K3:0_10386:120:ZR:0_11947:790:F6:0_12006:1170:P2:0_12434:1605:IN:0_",
              ruleid:
                "JswS+I/5j1L2eBRI1cPMphM1YiBqo5VUngE+f6ZTlrcWhTrLfc+1HjPslNbsK6Ma+NzJ9vs0yTvfIGzHK+7a6QYdbLMNnyuqz+GQYY8X4ucqhXNHUPmc5WpT//+vZgmt",
              hsc: "ac105cb315848594ff1102f464626a92183f5104e62e896876c5673973778340",
              sup_fare: "13255_24526.44_12805",
              cabb: "Chargeable",
              bagage_info: "Chargeable",
              chekbcode: "",
              psup_fare: "33240_56315",
            },
          ],
          pr: {
            bf: 33240,
            tf: 56315,
            tax: [
              { OT: 23075 },
              { attmkp: 0 },
              { tamkp: 0 },
              { stamkp: 0 },
              { attdisc: 0 },
              { tadisc: 0 },
              { stadisc: 0 },
            ],
            scur: "INR",
            ucur: "INR",
            exchange_rate: 1,
            sup_fare: "33240_56315",
            sup_cur_fare: "",
            cabb: "Chargeable",
            chekb: "7kg INCLUDED",
            meals: "Chargeable",
            chanb: "Non Refundable",
            ftyp: "LITE",
            extra: "11_LITE",
            chekbcode: "",
            chanb_info:
              "BEFOREDEPARTURE 0 hrs of departure Change of Flight is Non-Refundable,NOSHOW From 0 hrs before  of departure Change of Flight is Non-Refundable",
            canb: "Non Refundable",
            cabb_info:
              "BEFOREDEPARTURE 0 hrs of departure Cancellation of Flight is Non-Refundable,NOSHOW From 0 hrs before  of departure Cancellation of Flight is Non-Refundable",
            bagage_info: "Chargeable",
            markupnames: [],
            ttf: 56315,
          },
          sup: "5",
          airlineList: "FZ",
          destorigins: [
            {
              tid: "dwkUlWbOwlu6ca6Q_2",
              ref: 2,
              office_id: "",
              sdur: "6:45",
              re: "",
              sup: "5",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DXB",
                  dd: "2023-06-09T17:00:00",
                  ad: "2023-06-09T19:15:00",
                  rbd: "",
                  fn: "018",
                  trn: "",
                  eq: "73X",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "1", arr: "2" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161399,
                  flightTime: 75,
                  fdur: "1:15",
                },
                {
                  ref: 2,
                  da: "DXB",
                  ar: "HYD",
                  dd: "2023-06-09T20:50:00",
                  ad: "2023-06-10T02:15:00",
                  rbd: "",
                  fn: "435",
                  trn: "",
                  eq: "73B",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "2", arr: "" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161456,
                  flightTime: 235,
                  fdur: "3:55",
                },
              ],
              fareop: [
                {
                  fareKey: [],
                  pr: {
                    bf: 19985,
                    tf: 31788.56,
                    tax: [
                      { OT: 11803.56 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    scur: "INR",
                    ucur: "INR",
                    exchange_rate: 1,
                    sup_fare: "19985_31788.56",
                    sup_cur_fare: "",
                    cabb: "Free",
                    chekb: "7kg INCLUDED",
                    meals: "Standard meal",
                    chanb: "Chargeable",
                    ftyp: "FLEX",
                    extra: "13_FLEX",
                    chekbcode: "BAGL",
                    chanb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Change of Flight is Free,NOSHOWUPTO 720 hrs of departure Change of Flight is Free,NOSHOWAFTER From 720 hrs before  of departure Change of Flight is Non-Refundable",
                    canb: "Chargeable",
                    cabb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Cancellation of Flight is Free,NOSHOWUPTO 720 hrs of departure Cancellation of Flight is Free,NOSHOWAFTER From 720 hrs before  of departure Cancellation of Flight is Non-Refundable",
                    bagage_info: "30kg BAG INCLUDED IN FARE",
                    markupnames: [],
                    ttf: 31788.56,
                  },
                  paxpr: [
                    {
                      bf: 19985,
                      tf: 31788.56,
                      tax: [
                        { OT: 11803.56 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "ORML8IN5",
                      ptype: "ADT",
                      scur: "INR",
                      extra:
                        "1_10_O_1_9_0_tx-1764:1370:QA:0_2186:170:PZ:0_4246:6450:YQ:0_8947:0:BAGL:0_8966:1370:G4:0_9327:1303.56:K3:0_10386:120:ZR:0_11947:790:F6:0_13230:230:R9:0_",
                      ruleid:
                        "1CW6RqVXVjksoy0eCywJg24CvdfP67Yp1QfNWPSmae6JwVGRH27geGUyNcdqoz42Q+cpWZGlzHEqLD5ipHbUE/fms9aActHf3dUySn1KUD/7SM/8nKhpHhnVpzpaBJniV3Wx5QsmoEr7eVfjVNM8Og==",
                      hsc: "dc7117356c43a68dc79e951eda48a2d82f6b0c11f609110b0e4b840b15b2f867",
                      sup_fare: "19985_31788.56_19535",
                      cabb: "Free",
                      bagage_info: "30kg BAG INCLUDED IN FARE",
                      chekbcode: "BAGL",
                      psup_fare: "19985_31788.56",
                    },
                  ],
                  uniqueId: "FLEX",
                  routeId: "77h1hmr8oh",
                  servicefee: [],
                  selected: true,
                },
              ],
              tktair: "FZ",
              tecst: "no",
              cl: "Economy",
              extra: 13916393,
              token:
                "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiYXBpdGF3ZmVlcSIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL2dpdmVubmFtZSI6IkFQSSBUQVdGRUVRIiwiZ3JvdXBBY2Nlc3MiOiIzLDM2LDExMiIsImlzQXB0Q2xudCI6IkZhbHNlIiwibG9jYXRpb24iOiI5OTk5IiwiY2hrTGNuIjoiIiwicmVzVG9rZW4iOiJkZWRhZDdkeWY4N2FuOTc0dGJxYjY0YmJ6ZjZlOTYwYzA5ZTE5NmRhZTg2MCIsImNsaWVudElQIjoiMTcyLjE5LjIzLjU0IiwicmVzQ2hhbm5lbElEIjoiNSIsInJlc0NoYW5uZWxOYW1lIjoiVFBBUEkiLCJkZWZhdWx0VXNlck5hbWUiOiJhcGl0YXdmZWVxIiwibmJmIjoxNjg1NTE1MjM3LCJleHAiOjE2ODU1MTc2MzcsImlzcyI6ImZseWR1YmFpLmNvbSIsImF1ZCI6IlRBV0ZFRVFUUlZMU19GWl9OUCJ9.rAW3YT-bX_-yADfvg3QkuPAbOJhkhRUN6HwoS_6PyiM",
              stop: 1,
            },
            {
              tid: "xzgWexrRtFCWUVKc_2",
              ref: 2,
              office_id: "",
              sdur: "13:45",
              re: "",
              sup: "5",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DXB",
                  dd: "2023-06-09T10:00:00",
                  ad: "2023-06-09T12:15:00",
                  rbd: "",
                  fn: "002",
                  trn: "",
                  eq: "73X",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "1", arr: "2" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161402,
                  flightTime: 75,
                  fdur: "1:15",
                },
                {
                  ref: 2,
                  da: "DXB",
                  ar: "HYD",
                  dd: "2023-06-09T20:50:00",
                  ad: "2023-06-10T02:15:00",
                  rbd: "",
                  fn: "435",
                  trn: "",
                  eq: "73B",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "2", arr: "" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161456,
                  flightTime: 235,
                  fdur: "3:55",
                },
              ],
              fareop: [
                {
                  fareKey: [],
                  pr: {
                    bf: 19985,
                    tf: 31788.56,
                    tax: [
                      { OT: 11803.56 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    scur: "INR",
                    ucur: "INR",
                    exchange_rate: 1,
                    sup_fare: "19985_31788.56",
                    sup_cur_fare: "",
                    cabb: "Free",
                    chekb: "7kg INCLUDED",
                    meals: "Standard meal",
                    chanb: "Chargeable",
                    ftyp: "FLEX",
                    extra: "13_FLEX",
                    chekbcode: "BAGL",
                    chanb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Change of Flight is Free,NOSHOWUPTO 720 hrs of departure Change of Flight is Free,NOSHOWAFTER From 720 hrs before  of departure Change of Flight is Non-Refundable",
                    canb: "Chargeable",
                    cabb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Cancellation of Flight is Free,NOSHOWUPTO 720 hrs of departure Cancellation of Flight is Free,NOSHOWAFTER From 720 hrs before  of departure Cancellation of Flight is Non-Refundable",
                    bagage_info: "30kg BAG INCLUDED IN FARE",
                    markupnames: [],
                    ttf: 31788.56,
                  },
                  paxpr: [
                    {
                      bf: 19985,
                      tf: 31788.56,
                      tax: [
                        { OT: 11803.56 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "ORML8IN5",
                      ptype: "ADT",
                      scur: "INR",
                      extra:
                        "1_42_O_1_9_0_tx-1764:1370:QA:0_2186:170:PZ:0_4246:6450:YQ:0_8947:0:BAGL:0_8966:1370:G4:0_9327:1303.56:K3:0_10386:120:ZR:0_11947:790:F6:0_13230:230:R9:0_",
                      ruleid:
                        "sWoQMlvyfhUn59LrXfR/D24CvdfP67Yp1QfNWPSmae6JwVGRH27geGUyNcdqoz42Q+cpWZGlzHEqLD5ipHbUE/fms9aActHf3dUySn1KUD/7SM/8nKhpHhnVpzpaBJniV3Wx5QsmoEr7eVfjVNM8Og==",
                      hsc: "9a12d97bb24281138f86ff295ddcc3dd8e59a71c220c07aa86b5708c0ff24283",
                      sup_fare: "19985_31788.56_19535",
                      cabb: "Free",
                      bagage_info: "30kg BAG INCLUDED IN FARE",
                      chekbcode: "BAGL",
                      psup_fare: "19985_31788.56",
                    },
                  ],
                  uniqueId: "FLEX",
                  routeId: "qyde19hc3s",
                  servicefee: [],
                  selected: true,
                },
              ],
              tktair: "FZ",
              tecst: "no",
              cl: "Economy",
              extra: 13915297,
              token:
                "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiYXBpdGF3ZmVlcSIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL2dpdmVubmFtZSI6IkFQSSBUQVdGRUVRIiwiZ3JvdXBBY2Nlc3MiOiIzLDM2LDExMiIsImlzQXB0Q2xudCI6IkZhbHNlIiwibG9jYXRpb24iOiI5OTk5IiwiY2hrTGNuIjoiIiwicmVzVG9rZW4iOiJkZWRhZDdkeWY4N2FuOTc0dGJxYjY0YmJ6ZjZlOTYwYzA5ZTE5NmRhZTg2MCIsImNsaWVudElQIjoiMTcyLjE5LjIzLjU0IiwicmVzQ2hhbm5lbElEIjoiNSIsInJlc0NoYW5uZWxOYW1lIjoiVFBBUEkiLCJkZWZhdWx0VXNlck5hbWUiOiJhcGl0YXdmZWVxIiwibmJmIjoxNjg1NTE1MjM3LCJleHAiOjE2ODU1MTc2MzcsImlzcyI6ImZseWR1YmFpLmNvbSIsImF1ZCI6IlRBV0ZFRVFUUlZMU19GWl9OUCJ9.rAW3YT-bX_-yADfvg3QkuPAbOJhkhRUN6HwoS_6PyiM",
              stop: 1,
            },
          ],
          servicefee: [],
        },
        {
          srcorigns: [
            {
              tid: "GPFLD29ZAPoB6XqS_1",
              ref: 1,
              office_id: "",
              sdur: "8:25",
              re: "",
              sup: "5",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DXB",
                  dd: "2023-06-07T03:00:00",
                  ad: "2023-06-07T05:30:00",
                  rbd: "",
                  fn: "436",
                  trn: "",
                  eq: "73B",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "", arr: "2" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161457,
                  flightTime: 240,
                  fdur: "4:0",
                },
                {
                  ref: 2,
                  da: "DXB",
                  ar: "DOH",
                  dd: "2023-06-07T08:45:00",
                  ad: "2023-06-07T08:55:00",
                  rbd: "",
                  fn: "001",
                  trn: "",
                  eq: "73X",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "2", arr: "1" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161405,
                  flightTime: 70,
                  fdur: "1:10",
                },
              ],
              fareop: [
                {
                  fareKey: [],
                  pr: {
                    bf: 19985,
                    tf: 31593.56,
                    tax: [
                      { OT: 11608.56 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    scur: "INR",
                    ucur: "INR",
                    exchange_rate: 1,
                    sup_fare: "19985_31593.56",
                    sup_cur_fare: "",
                    cabb: "Free",
                    chekb: "7kg INCLUDED",
                    meals: "Standard meal",
                    chanb: "Chargeable",
                    ftyp: "FLEX",
                    extra: "13_FLEX",
                    chekbcode: "BAGL",
                    chanb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Change of Flight is Free,NOSHOWUPTO 720 hrs of departure Change of Flight is Free,NOSHOWAFTER From 720 hrs before  of departure Change of Flight is Non-Refundable",
                    canb: "Chargeable",
                    cabb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Cancellation of Flight is Free,NOSHOWUPTO 720 hrs of departure Cancellation of Flight is Free,NOSHOWAFTER From 720 hrs before  of departure Cancellation of Flight is Non-Refundable",
                    bagage_info: "30kg BAG INCLUDED IN FARE",
                    markupnames: [],
                    ttf: 31593.56,
                  },
                  paxpr: [
                    {
                      bf: 19985,
                      tf: 31593.56,
                      tax: [
                        { OT: 11608.56 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "ORML8IN5",
                      ptype: "ADT",
                      scur: "INR",
                      extra:
                        "1_11_O_1_9_0_tx-2186:170:PZ:0_4246:6450:YQ:0_8947:0:BAGL:0_9327:1303.56:K3:0_10386:120:ZR:0_11947:790:F6:0_12006:1170:P2:0_12434:1605:IN:0_",
                      ruleid:
                        "oEikHKk6W6jo2++FrYo/JBZ+CHIn9HNL+12RdyPuUypN3Vrd+8Of0oA7a4N7g8CvZItP8LZIcrAqzBeCOoIuvjLwhemGenh8Kvds7rAxHg77sBOV2+bcsWuISEqTM1NI",
                      hsc: "82e6391c25439d234e368af6280ed748364bc14abb02d1800f87db5ae9479631",
                      sup_fare: "19985_31593.56_19535",
                      cabb: "Free",
                      bagage_info: "30kg BAG INCLUDED IN FARE",
                      chekbcode: "BAGL",
                      psup_fare: "19985_31593.56",
                    },
                  ],
                  uniqueId: "FLEX",
                  routeId: "e4n5ohb1g9",
                  servicefee: [],
                  selected: true,
                },
              ],
              tktair: "FZ",
              tecst: "no",
              cl: "Economy",
              extra: 13919293,
              token:
                "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiYXBpdGF3ZmVlcSIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL2dpdmVubmFtZSI6IkFQSSBUQVdGRUVRIiwiZ3JvdXBBY2Nlc3MiOiIzLDM2LDExMiIsImlzQXB0Q2xudCI6IkZhbHNlIiwibG9jYXRpb24iOiI5OTk5IiwiY2hrTGNuIjoiIiwicmVzVG9rZW4iOiJkZWRhZDdkeWY4N2FuOTc0dGJxYjY0YmJ6ZjZlOTYwYzA5ZTE5NmRhZTg2MCIsImNsaWVudElQIjoiMTcyLjE5LjIzLjU0IiwicmVzQ2hhbm5lbElEIjoiNSIsInJlc0NoYW5uZWxOYW1lIjoiVFBBUEkiLCJkZWZhdWx0VXNlck5hbWUiOiJhcGl0YXdmZWVxIiwibmJmIjoxNjg1NTE1MjM3LCJleHAiOjE2ODU1MTc2MzcsImlzcyI6ImZseWR1YmFpLmNvbSIsImF1ZCI6IlRBV0ZFRVFUUlZMU19GWl9OUCJ9.rAW3YT-bX_-yADfvg3QkuPAbOJhkhRUN6HwoS_6PyiM",
              stop: 1,
            },
            {
              tid: "cVsqKzzHLpZy1erQ_1",
              ref: 1,
              office_id: "",
              sdur: "15:25",
              re: "",
              sup: "5",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DXB",
                  dd: "2023-06-07T03:00:00",
                  ad: "2023-06-07T05:30:00",
                  rbd: "",
                  fn: "436",
                  trn: "",
                  eq: "73B",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "", arr: "2" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161457,
                  flightTime: 240,
                  fdur: "4:0",
                },
                {
                  ref: 2,
                  da: "DXB",
                  ar: "DOH",
                  dd: "2023-06-07T15:45:00",
                  ad: "2023-06-07T15:55:00",
                  rbd: "",
                  fn: "017",
                  trn: "",
                  eq: "73X",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "2", arr: "1" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161400,
                  flightTime: 70,
                  fdur: "1:10",
                },
              ],
              fareop: [
                {
                  fareKey: [],
                  pr: {
                    bf: 19985,
                    tf: 31593.56,
                    tax: [
                      { OT: 11608.56 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    scur: "INR",
                    ucur: "INR",
                    exchange_rate: 1,
                    sup_fare: "19985_31593.56",
                    sup_cur_fare: "",
                    cabb: "Free",
                    chekb: "7kg INCLUDED",
                    meals: "Standard meal",
                    chanb: "Chargeable",
                    ftyp: "FLEX",
                    extra: "13_FLEX",
                    chekbcode: "BAGL",
                    chanb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Change of Flight is Free,NOSHOWUPTO 720 hrs of departure Change of Flight is Free,NOSHOWAFTER From 720 hrs before  of departure Change of Flight is Non-Refundable",
                    canb: "Chargeable",
                    cabb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Cancellation of Flight is Free,NOSHOWUPTO 720 hrs of departure Cancellation of Flight is Free,NOSHOWAFTER From 720 hrs before  of departure Cancellation of Flight is Non-Refundable",
                    bagage_info: "30kg BAG INCLUDED IN FARE",
                    markupnames: [],
                    ttf: 31593.56,
                  },
                  paxpr: [
                    {
                      bf: 19985,
                      tf: 31593.56,
                      tax: [
                        { OT: 11608.56 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "ORML8IN5",
                      ptype: "ADT",
                      scur: "INR",
                      extra:
                        "1_75_O_1_9_0_tx-2186:170:PZ:0_4246:6450:YQ:0_8947:0:BAGL:0_9327:1303.56:K3:0_10386:120:ZR:0_11947:790:F6:0_12006:1170:P2:0_12434:1605:IN:0_",
                      ruleid:
                        "jNT+FsV7nQXdquLQ3wCa/RZ+CHIn9HNL+12RdyPuUypN3Vrd+8Of0oA7a4N7g8CvZItP8LZIcrAqzBeCOoIuvjLwhemGenh8Kvds7rAxHg77sBOV2+bcsWuISEqTM1NI",
                      hsc: "d04003b491310a7ea2fb40b1f72706d44f713fcfb55185a54506c991c3a35f7f",
                      sup_fare: "19985_31593.56_19535",
                      cabb: "Free",
                      bagage_info: "30kg BAG INCLUDED IN FARE",
                      chekbcode: "BAGL",
                      psup_fare: "19985_31593.56",
                    },
                  ],
                  uniqueId: "FLEX",
                  routeId: "yjaregbh1k",
                  servicefee: [],
                  selected: true,
                },
              ],
              tktair: "FZ",
              tecst: "no",
              cl: "Economy",
              extra: 13919296,
              token:
                "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiYXBpdGF3ZmVlcSIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL2dpdmVubmFtZSI6IkFQSSBUQVdGRUVRIiwiZ3JvdXBBY2Nlc3MiOiIzLDM2LDExMiIsImlzQXB0Q2xudCI6IkZhbHNlIiwibG9jYXRpb24iOiI5OTk5IiwiY2hrTGNuIjoiIiwicmVzVG9rZW4iOiJkZWRhZDdkeWY4N2FuOTc0dGJxYjY0YmJ6ZjZlOTYwYzA5ZTE5NmRhZTg2MCIsImNsaWVudElQIjoiMTcyLjE5LjIzLjU0IiwicmVzQ2hhbm5lbElEIjoiNSIsInJlc0NoYW5uZWxOYW1lIjoiVFBBUEkiLCJkZWZhdWx0VXNlck5hbWUiOiJhcGl0YXdmZWVxIiwibmJmIjoxNjg1NTE1MjM3LCJleHAiOjE2ODU1MTc2MzcsImlzcyI6ImZseWR1YmFpLmNvbSIsImF1ZCI6IlRBV0ZFRVFUUlZMU19GWl9OUCJ9.rAW3YT-bX_-yADfvg3QkuPAbOJhkhRUN6HwoS_6PyiM",
              stop: 1,
            },
            {
              tid: "7CxQUJS6snPWxdfb_1",
              ref: 1,
              office_id: "",
              sdur: "19:25",
              re: "",
              sup: "5",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DXB",
                  dd: "2023-06-07T03:00:00",
                  ad: "2023-06-07T05:30:00",
                  rbd: "",
                  fn: "436",
                  trn: "",
                  eq: "73B",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "", arr: "2" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161457,
                  flightTime: 240,
                  fdur: "4:0",
                },
                {
                  ref: 2,
                  da: "DXB",
                  ar: "DOH",
                  dd: "2023-06-07T19:45:00",
                  ad: "2023-06-07T19:55:00",
                  rbd: "",
                  fn: "005",
                  trn: "",
                  eq: "73X",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "2", arr: "1" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161381,
                  flightTime: 70,
                  fdur: "1:10",
                },
              ],
              fareop: [
                {
                  fareKey: [],
                  pr: {
                    bf: 19985,
                    tf: 31593.56,
                    tax: [
                      { OT: 11608.56 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    scur: "INR",
                    ucur: "INR",
                    exchange_rate: 1,
                    sup_fare: "19985_31593.56",
                    sup_cur_fare: "",
                    cabb: "Free",
                    chekb: "7kg INCLUDED",
                    meals: "Standard meal",
                    chanb: "Chargeable",
                    ftyp: "FLEX",
                    extra: "13_FLEX",
                    chekbcode: "BAGL",
                    chanb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Change of Flight is Free,NOSHOWUPTO 720 hrs of departure Change of Flight is Free,NOSHOWAFTER From 720 hrs before  of departure Change of Flight is Non-Refundable",
                    canb: "Chargeable",
                    cabb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Cancellation of Flight is Free,NOSHOWUPTO 720 hrs of departure Cancellation of Flight is Free,NOSHOWAFTER From 720 hrs before  of departure Cancellation of Flight is Non-Refundable",
                    bagage_info: "30kg BAG INCLUDED IN FARE",
                    markupnames: [],
                    ttf: 31593.56,
                  },
                  paxpr: [
                    {
                      bf: 19985,
                      tf: 31593.56,
                      tax: [
                        { OT: 11608.56 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "ORML8IN5",
                      ptype: "ADT",
                      scur: "INR",
                      extra:
                        "1_107_O_1_9_0_tx-2186:170:PZ:0_4246:6450:YQ:0_8947:0:BAGL:0_9327:1303.56:K3:0_10386:120:ZR:0_11947:790:F6:0_12006:1170:P2:0_12434:1605:IN:0_",
                      ruleid:
                        "1PD+4oR/2CoxlrZyU/LUZiH5cdRtjzMqmtc6Ydt53ymhQozXlONozKDeF7aO/DbGAO8K6tXzJ16Rfs5fUX41PoAzc0DAexpKMcT6yt4vWi5QaY31vw17VrswRPIWnfWdBQGHoM3lqYcsurCRq3PlUw==",
                      hsc: "1c1215e6e28e43addfbc2ed594f06a2b28c358a0cb5bd9e4f69d91400c3abe56",
                      sup_fare: "19985_31593.56_19535",
                      cabb: "Free",
                      bagage_info: "30kg BAG INCLUDED IN FARE",
                      chekbcode: "BAGL",
                      psup_fare: "19985_31593.56",
                    },
                  ],
                  uniqueId: "FLEX",
                  routeId: "hh95l1kfpih",
                  servicefee: [],
                  selected: true,
                },
              ],
              tktair: "FZ",
              tecst: "no",
              cl: "Economy",
              extra: 13919294,
              token:
                "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiYXBpdGF3ZmVlcSIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL2dpdmVubmFtZSI6IkFQSSBUQVdGRUVRIiwiZ3JvdXBBY2Nlc3MiOiIzLDM2LDExMiIsImlzQXB0Q2xudCI6IkZhbHNlIiwibG9jYXRpb24iOiI5OTk5IiwiY2hrTGNuIjoiIiwicmVzVG9rZW4iOiJkZWRhZDdkeWY4N2FuOTc0dGJxYjY0YmJ6ZjZlOTYwYzA5ZTE5NmRhZTg2MCIsImNsaWVudElQIjoiMTcyLjE5LjIzLjU0IiwicmVzQ2hhbm5lbElEIjoiNSIsInJlc0NoYW5uZWxOYW1lIjoiVFBBUEkiLCJkZWZhdWx0VXNlck5hbWUiOiJhcGl0YXdmZWVxIiwibmJmIjoxNjg1NTE1MjM3LCJleHAiOjE2ODU1MTc2MzcsImlzcyI6ImZseWR1YmFpLmNvbSIsImF1ZCI6IlRBV0ZFRVFUUlZMU19GWl9OUCJ9.rAW3YT-bX_-yADfvg3QkuPAbOJhkhRUN6HwoS_6PyiM",
              stop: 1,
            },
          ],
          paxpr: [
            {
              bf: 33240,
              tf: 56315,
              tax: [
                { OT: 23075 },
                { attmkp: 0 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              fbc: "ORML8IN5",
              ptype: "ADT",
              scur: "INR",
              extra:
                "1_11_O_1_9_0_tx-2186:170:PZ:0_4246:6450:YQ:0_8947:0:BAGL:0_9327:1303.56:K3:0_10386:120:ZR:0_11947:790:F6:0_12006:1170:P2:0_12434:1605:IN:0_",
              ruleid:
                "oEikHKk6W6jo2++FrYo/JBZ+CHIn9HNL+12RdyPuUypN3Vrd+8Of0oA7a4N7g8CvZItP8LZIcrAqzBeCOoIuvjLwhemGenh8Kvds7rAxHg77sBOV2+bcsWuISEqTM1NI",
              hsc: "82e6391c25439d234e368af6280ed748364bc14abb02d1800f87db5ae9479631",
              sup_fare: "19985_31593.56_19535",
              cabb: "Free",
              bagage_info: "30kg BAG INCLUDED IN FARE",
              chekbcode: "BAGL",
              psup_fare: "33240_56315",
            },
          ],
          pr: {
            bf: 33240,
            tf: 56315,
            tax: [
              { OT: 23075 },
              { attmkp: 0 },
              { tamkp: 0 },
              { stamkp: 0 },
              { attdisc: 0 },
              { tadisc: 0 },
              { stadisc: 0 },
            ],
            scur: "INR",
            ucur: "INR",
            exchange_rate: 1,
            sup_fare: "33240_56315",
            sup_cur_fare: "",
            cabb: "Free",
            chekb: "7kg INCLUDED",
            meals: "Standard meal",
            chanb: "Chargeable",
            ftyp: "FLEX",
            extra: "13_FLEX",
            chekbcode: "BAGL",
            chanb_info:
              "BEFOREDEPARTURE 0 hrs of departure Change of Flight is Free,NOSHOWUPTO 720 hrs of departure Change of Flight is Free,NOSHOWAFTER From 720 hrs before  of departure Change of Flight is Non-Refundable",
            canb: "Chargeable",
            cabb_info:
              "BEFOREDEPARTURE 0 hrs of departure Cancellation of Flight is Free,NOSHOWUPTO 720 hrs of departure Cancellation of Flight is Free,NOSHOWAFTER From 720 hrs before  of departure Cancellation of Flight is Non-Refundable",
            bagage_info: "30kg BAG INCLUDED IN FARE",
            markupnames: [],
            ttf: 56315,
          },
          sup: "5",
          airlineList: "FZ",
          destorigins: [
            {
              tid: "zDcVSzmu4DiF2Qps_2",
              ref: 2,
              office_id: "",
              sdur: "6:45",
              re: "",
              sup: "5",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DXB",
                  dd: "2023-06-09T17:00:00",
                  ad: "2023-06-09T19:15:00",
                  rbd: "",
                  fn: "018",
                  trn: "",
                  eq: "73X",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "1", arr: "2" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161399,
                  flightTime: 75,
                  fdur: "1:15",
                },
                {
                  ref: 2,
                  da: "DXB",
                  ar: "HYD",
                  dd: "2023-06-09T20:50:00",
                  ad: "2023-06-10T02:15:00",
                  rbd: "",
                  fn: "435",
                  trn: "",
                  eq: "73B",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "2", arr: "" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161456,
                  flightTime: 235,
                  fdur: "3:55",
                },
              ],
              fareop: [
                {
                  fareKey: [],
                  pr: {
                    bf: 13255,
                    tf: 24721.44,
                    tax: [
                      { OT: 11466.44 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    scur: "INR",
                    ucur: "INR",
                    exchange_rate: 1,
                    sup_fare: "13255_24721.44",
                    sup_cur_fare: "",
                    cabb: "Chargeable",
                    chekb: "7kg INCLUDED",
                    meals: "Chargeable",
                    chanb: "Non Refundable",
                    ftyp: "LITE",
                    extra: "11_LITE",
                    chekbcode: "",
                    chanb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Change of Flight is Non-Refundable,NOSHOW From 0 hrs before  of departure Change of Flight is Non-Refundable",
                    canb: "Non Refundable",
                    cabb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Cancellation of Flight is Non-Refundable,NOSHOW From 0 hrs before  of departure Cancellation of Flight is Non-Refundable",
                    bagage_info: "Chargeable",
                    markupnames: [],
                    ttf: 24721.44,
                  },
                  paxpr: [
                    {
                      bf: 13255,
                      tf: 24721.44,
                      tax: [
                        { OT: 11466.44 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "OR6IN5",
                      ptype: "ADT",
                      scur: "INR",
                      extra:
                        "1_12_O_1_9_0_tx-1764:1370:QA:0_2186:170:PZ:0_4246:6450:YQ:0_8966:1370:G4:0_9327:966.44:K3:0_10386:120:ZR:0_11947:790:F6:0_13230:230:R9:0_",
                      ruleid:
                        "jnRS+c0Ik0Pk1zj/MBmZf1ynIfJ/XB/vnt5lWewxSALdEVWgu3w8400pbatJN8v+dXRVoM0HZ8U9jxms9hE/Ws/RUwZbLq3+/lDxX34TkElcuOqD7NHphJpbWpAalKe5nFFh9Gsw/dpcQuWfjFtRfA==",
                      hsc: "e0d15a51cbcb5ce3bfb9fea0917bb765368d132034f69420cfa280abbe74ce33",
                      sup_fare: "13255_24721.44_12805",
                      cabb: "Chargeable",
                      bagage_info: "Chargeable",
                      chekbcode: "",
                      psup_fare: "13255_24721.44",
                    },
                  ],
                  uniqueId: "LITE",
                  routeId: "w20p592ffgi",
                  servicefee: [],
                  selected: true,
                },
              ],
              tktair: "FZ",
              tecst: "no",
              cl: "Economy",
              extra: 13916393,
              token:
                "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiYXBpdGF3ZmVlcSIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL2dpdmVubmFtZSI6IkFQSSBUQVdGRUVRIiwiZ3JvdXBBY2Nlc3MiOiIzLDM2LDExMiIsImlzQXB0Q2xudCI6IkZhbHNlIiwibG9jYXRpb24iOiI5OTk5IiwiY2hrTGNuIjoiIiwicmVzVG9rZW4iOiJkZWRhZDdkeWY4N2FuOTc0dGJxYjY0YmJ6ZjZlOTYwYzA5ZTE5NmRhZTg2MCIsImNsaWVudElQIjoiMTcyLjE5LjIzLjU0IiwicmVzQ2hhbm5lbElEIjoiNSIsInJlc0NoYW5uZWxOYW1lIjoiVFBBUEkiLCJkZWZhdWx0VXNlck5hbWUiOiJhcGl0YXdmZWVxIiwibmJmIjoxNjg1NTE1MjM3LCJleHAiOjE2ODU1MTc2MzcsImlzcyI6ImZseWR1YmFpLmNvbSIsImF1ZCI6IlRBV0ZFRVFUUlZMU19GWl9OUCJ9.rAW3YT-bX_-yADfvg3QkuPAbOJhkhRUN6HwoS_6PyiM",
              stop: 1,
            },
            {
              tid: "lNBTzT4WPv31fqT9_2",
              ref: 2,
              office_id: "",
              sdur: "13:45",
              re: "",
              sup: "5",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DXB",
                  dd: "2023-06-09T10:00:00",
                  ad: "2023-06-09T12:15:00",
                  rbd: "",
                  fn: "002",
                  trn: "",
                  eq: "73X",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "1", arr: "2" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161402,
                  flightTime: 75,
                  fdur: "1:15",
                },
                {
                  ref: 2,
                  da: "DXB",
                  ar: "HYD",
                  dd: "2023-06-09T20:50:00",
                  ad: "2023-06-10T02:15:00",
                  rbd: "",
                  fn: "435",
                  trn: "",
                  eq: "73B",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "2", arr: "" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161456,
                  flightTime: 235,
                  fdur: "3:55",
                },
              ],
              fareop: [
                {
                  fareKey: [],
                  pr: {
                    bf: 13255,
                    tf: 24721.44,
                    tax: [
                      { OT: 11466.44 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    scur: "INR",
                    ucur: "INR",
                    exchange_rate: 1,
                    sup_fare: "13255_24721.44",
                    sup_cur_fare: "",
                    cabb: "Chargeable",
                    chekb: "7kg INCLUDED",
                    meals: "Chargeable",
                    chanb: "Non Refundable",
                    ftyp: "LITE",
                    extra: "11_LITE",
                    chekbcode: "",
                    chanb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Change of Flight is Non-Refundable,NOSHOW From 0 hrs before  of departure Change of Flight is Non-Refundable",
                    canb: "Non Refundable",
                    cabb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Cancellation of Flight is Non-Refundable,NOSHOW From 0 hrs before  of departure Cancellation of Flight is Non-Refundable",
                    bagage_info: "Chargeable",
                    markupnames: [],
                    ttf: 24721.44,
                  },
                  paxpr: [
                    {
                      bf: 13255,
                      tf: 24721.44,
                      tax: [
                        { OT: 11466.44 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "OR6IN5",
                      ptype: "ADT",
                      scur: "INR",
                      extra:
                        "1_44_O_1_9_0_tx-1764:1370:QA:0_2186:170:PZ:0_4246:6450:YQ:0_8966:1370:G4:0_9327:966.44:K3:0_10386:120:ZR:0_11947:790:F6:0_13230:230:R9:0_",
                      ruleid:
                        "BcKgIclIZKrJbSabD3vhmlynIfJ/XB/vnt5lWewxSALdEVWgu3w8400pbatJN8v+dXRVoM0HZ8U9jxms9hE/Ws/RUwZbLq3+/lDxX34TkElcuOqD7NHphJpbWpAalKe5nFFh9Gsw/dpcQuWfjFtRfA==",
                      hsc: "890429f59992677c15b9cd0fe49bd4744b495fca1f20ee7fc5b2a4088df7323a",
                      sup_fare: "13255_24721.44_12805",
                      cabb: "Chargeable",
                      bagage_info: "Chargeable",
                      chekbcode: "",
                      psup_fare: "13255_24721.44",
                    },
                  ],
                  uniqueId: "LITE",
                  routeId: "easu3ekjlg",
                  servicefee: [],
                  selected: true,
                },
              ],
              tktair: "FZ",
              tecst: "no",
              cl: "Economy",
              extra: 13915297,
              token:
                "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiYXBpdGF3ZmVlcSIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL2dpdmVubmFtZSI6IkFQSSBUQVdGRUVRIiwiZ3JvdXBBY2Nlc3MiOiIzLDM2LDExMiIsImlzQXB0Q2xudCI6IkZhbHNlIiwibG9jYXRpb24iOiI5OTk5IiwiY2hrTGNuIjoiIiwicmVzVG9rZW4iOiJkZWRhZDdkeWY4N2FuOTc0dGJxYjY0YmJ6ZjZlOTYwYzA5ZTE5NmRhZTg2MCIsImNsaWVudElQIjoiMTcyLjE5LjIzLjU0IiwicmVzQ2hhbm5lbElEIjoiNSIsInJlc0NoYW5uZWxOYW1lIjoiVFBBUEkiLCJkZWZhdWx0VXNlck5hbWUiOiJhcGl0YXdmZWVxIiwibmJmIjoxNjg1NTE1MjM3LCJleHAiOjE2ODU1MTc2MzcsImlzcyI6ImZseWR1YmFpLmNvbSIsImF1ZCI6IlRBV0ZFRVFUUlZMU19GWl9OUCJ9.rAW3YT-bX_-yADfvg3QkuPAbOJhkhRUN6HwoS_6PyiM",
              stop: 1,
            },
          ],
          servicefee: [],
        },
        {
          srcorigns: [
            {
              tid: "aeDT2xWa3IZrev8d_1",
              ref: 1,
              office_id: "",
              sdur: "8:25",
              re: "",
              sup: "5",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DXB",
                  dd: "2023-06-07T03:00:00",
                  ad: "2023-06-07T05:30:00",
                  rbd: "",
                  fn: "436",
                  trn: "",
                  eq: "73B",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "", arr: "2" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161457,
                  flightTime: 240,
                  fdur: "4:0",
                },
                {
                  ref: 2,
                  da: "DXB",
                  ar: "DOH",
                  dd: "2023-06-07T08:45:00",
                  ad: "2023-06-07T08:55:00",
                  rbd: "",
                  fn: "001",
                  trn: "",
                  eq: "73X",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "2", arr: "1" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161405,
                  flightTime: 70,
                  fdur: "1:10",
                },
              ],
              fareop: [
                {
                  fareKey: [],
                  pr: {
                    bf: 14455,
                    tf: 25786.5,
                    tax: [
                      { OT: 11331.5 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    scur: "INR",
                    ucur: "INR",
                    exchange_rate: 1,
                    sup_fare: "14455_25786.5",
                    sup_cur_fare: "",
                    cabb: "Free",
                    chekb: "7kg INCLUDED",
                    meals: "Standard meal",
                    chanb: "Chargeable",
                    ftyp: "VALUE",
                    extra: "12_VALUE",
                    chekbcode: "BAGL",
                    chanb_info:
                      "UPTO 24 hrs of departure Change of Flight is Free,WITHIN From 0-24 hrs of departure Change of Flight is Non-Refundable,NOSHOW From 0 hrs before  of departure Change of Flight is Non-Refundable",
                    canb: "Chargeable",
                    cabb_info:
                      "UPTO 24 hrs of departure Cancellation of Flight is Free,WITHIN From 0-24 hrs of departure Cancellation of Flight is Non-Refundable,NOSHOW From 0 hrs before  of departure Cancellation of Flight is Non-Refundable",
                    bagage_info: "30kg BAG INCLUDED IN FARE",
                    markupnames: [],
                    ttf: 25786.5,
                  },
                  paxpr: [
                    {
                      bf: 14455,
                      tf: 25786.5,
                      tax: [
                        { OT: 11331.5 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "ORL7IN5",
                      ptype: "ADT",
                      scur: "INR",
                      extra:
                        "1_13_O_1_9_0_tx-2186:170:PZ:0_4246:6450:YQ:0_8947:0:BAGL:0_9327:1026.5:K3:0_10386:120:ZR:0_11947:790:F6:0_12006:1170:P2:0_12434:1605:IN:0_",
                      ruleid:
                        "n+l3Ydkz/JUtglH+vvnEdBZ+CHIn9HNL+12RdyPuUypN3Vrd+8Of0oA7a4N7g8CvZItP8LZIcrAqzBeCOoIuvjLwhemGenh8Kvds7rAxHg6X710uw+p5f6/y1zghrpgJ",
                      hsc: "f24c437f6224e2c5726a01759eee45874bab71966d3aa1e53e23e6f9cdb162c2",
                      sup_fare: "14455_25786.5_14005",
                      cabb: "Free",
                      bagage_info: "30kg BAG INCLUDED IN FARE",
                      chekbcode: "BAGL",
                      psup_fare: "14455_25786.5",
                    },
                  ],
                  uniqueId: "VALUE",
                  routeId: "s7k7qit4g5",
                  servicefee: [],
                  selected: true,
                },
              ],
              tktair: "FZ",
              tecst: "no",
              cl: "Economy",
              extra: 13919293,
              token:
                "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiYXBpdGF3ZmVlcSIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL2dpdmVubmFtZSI6IkFQSSBUQVdGRUVRIiwiZ3JvdXBBY2Nlc3MiOiIzLDM2LDExMiIsImlzQXB0Q2xudCI6IkZhbHNlIiwibG9jYXRpb24iOiI5OTk5IiwiY2hrTGNuIjoiIiwicmVzVG9rZW4iOiJkZWRhZDdkeWY4N2FuOTc0dGJxYjY0YmJ6ZjZlOTYwYzA5ZTE5NmRhZTg2MCIsImNsaWVudElQIjoiMTcyLjE5LjIzLjU0IiwicmVzQ2hhbm5lbElEIjoiNSIsInJlc0NoYW5uZWxOYW1lIjoiVFBBUEkiLCJkZWZhdWx0VXNlck5hbWUiOiJhcGl0YXdmZWVxIiwibmJmIjoxNjg1NTE1MjM3LCJleHAiOjE2ODU1MTc2MzcsImlzcyI6ImZseWR1YmFpLmNvbSIsImF1ZCI6IlRBV0ZFRVFUUlZMU19GWl9OUCJ9.rAW3YT-bX_-yADfvg3QkuPAbOJhkhRUN6HwoS_6PyiM",
              stop: 1,
            },
            {
              tid: "soqbDkLcP8fPX5iR_1",
              ref: 1,
              office_id: "",
              sdur: "15:25",
              re: "",
              sup: "5",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DXB",
                  dd: "2023-06-07T03:00:00",
                  ad: "2023-06-07T05:30:00",
                  rbd: "",
                  fn: "436",
                  trn: "",
                  eq: "73B",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "", arr: "2" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161457,
                  flightTime: 240,
                  fdur: "4:0",
                },
                {
                  ref: 2,
                  da: "DXB",
                  ar: "DOH",
                  dd: "2023-06-07T15:45:00",
                  ad: "2023-06-07T15:55:00",
                  rbd: "",
                  fn: "017",
                  trn: "",
                  eq: "73X",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "2", arr: "1" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161400,
                  flightTime: 70,
                  fdur: "1:10",
                },
              ],
              fareop: [
                {
                  fareKey: [],
                  pr: {
                    bf: 14455,
                    tf: 25786.5,
                    tax: [
                      { OT: 11331.5 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    scur: "INR",
                    ucur: "INR",
                    exchange_rate: 1,
                    sup_fare: "14455_25786.5",
                    sup_cur_fare: "",
                    cabb: "Free",
                    chekb: "7kg INCLUDED",
                    meals: "Standard meal",
                    chanb: "Chargeable",
                    ftyp: "VALUE",
                    extra: "12_VALUE",
                    chekbcode: "BAGL",
                    chanb_info:
                      "UPTO 24 hrs of departure Change of Flight is Free,WITHIN From 0-24 hrs of departure Change of Flight is Non-Refundable,NOSHOW From 0 hrs before  of departure Change of Flight is Non-Refundable",
                    canb: "Chargeable",
                    cabb_info:
                      "UPTO 24 hrs of departure Cancellation of Flight is Free,WITHIN From 0-24 hrs of departure Cancellation of Flight is Non-Refundable,NOSHOW From 0 hrs before  of departure Cancellation of Flight is Non-Refundable",
                    bagage_info: "30kg BAG INCLUDED IN FARE",
                    markupnames: [],
                    ttf: 25786.5,
                  },
                  paxpr: [
                    {
                      bf: 14455,
                      tf: 25786.5,
                      tax: [
                        { OT: 11331.5 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "ORL7IN5",
                      ptype: "ADT",
                      scur: "INR",
                      extra:
                        "1_79_O_1_9_0_tx-2186:170:PZ:0_4246:6450:YQ:0_8947:0:BAGL:0_9327:1026.5:K3:0_10386:120:ZR:0_11947:790:F6:0_12006:1170:P2:0_12434:1605:IN:0_",
                      ruleid:
                        "GTcmka9Wy7MzjHmDKhL+GxZ+CHIn9HNL+12RdyPuUypN3Vrd+8Of0oA7a4N7g8CvZItP8LZIcrAqzBeCOoIuvjLwhemGenh8Kvds7rAxHg6X710uw+p5f6/y1zghrpgJ",
                      hsc: "d005c72fec2818bcd279135822c158a48898d65960d0805a0f7e6183f93d3964",
                      sup_fare: "14455_25786.5_14005",
                      cabb: "Free",
                      bagage_info: "30kg BAG INCLUDED IN FARE",
                      chekbcode: "BAGL",
                      psup_fare: "14455_25786.5",
                    },
                  ],
                  uniqueId: "VALUE",
                  routeId: "bavnceuag2",
                  servicefee: [],
                  selected: true,
                },
              ],
              tktair: "FZ",
              tecst: "no",
              cl: "Economy",
              extra: 13919296,
              token:
                "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiYXBpdGF3ZmVlcSIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL2dpdmVubmFtZSI6IkFQSSBUQVdGRUVRIiwiZ3JvdXBBY2Nlc3MiOiIzLDM2LDExMiIsImlzQXB0Q2xudCI6IkZhbHNlIiwibG9jYXRpb24iOiI5OTk5IiwiY2hrTGNuIjoiIiwicmVzVG9rZW4iOiJkZWRhZDdkeWY4N2FuOTc0dGJxYjY0YmJ6ZjZlOTYwYzA5ZTE5NmRhZTg2MCIsImNsaWVudElQIjoiMTcyLjE5LjIzLjU0IiwicmVzQ2hhbm5lbElEIjoiNSIsInJlc0NoYW5uZWxOYW1lIjoiVFBBUEkiLCJkZWZhdWx0VXNlck5hbWUiOiJhcGl0YXdmZWVxIiwibmJmIjoxNjg1NTE1MjM3LCJleHAiOjE2ODU1MTc2MzcsImlzcyI6ImZseWR1YmFpLmNvbSIsImF1ZCI6IlRBV0ZFRVFUUlZMU19GWl9OUCJ9.rAW3YT-bX_-yADfvg3QkuPAbOJhkhRUN6HwoS_6PyiM",
              stop: 1,
            },
            {
              tid: "CM4EV5s3onyrJA0b_1",
              ref: 1,
              office_id: "",
              sdur: "19:25",
              re: "",
              sup: "5",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DXB",
                  dd: "2023-06-07T03:00:00",
                  ad: "2023-06-07T05:30:00",
                  rbd: "",
                  fn: "436",
                  trn: "",
                  eq: "73B",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "", arr: "2" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161457,
                  flightTime: 240,
                  fdur: "4:0",
                },
                {
                  ref: 2,
                  da: "DXB",
                  ar: "DOH",
                  dd: "2023-06-07T19:45:00",
                  ad: "2023-06-07T19:55:00",
                  rbd: "",
                  fn: "005",
                  trn: "",
                  eq: "73X",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "2", arr: "1" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161381,
                  flightTime: 70,
                  fdur: "1:10",
                },
              ],
              fareop: [
                {
                  fareKey: [],
                  pr: {
                    bf: 14455,
                    tf: 25786.5,
                    tax: [
                      { OT: 11331.5 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    scur: "INR",
                    ucur: "INR",
                    exchange_rate: 1,
                    sup_fare: "14455_25786.5",
                    sup_cur_fare: "",
                    cabb: "Free",
                    chekb: "7kg INCLUDED",
                    meals: "Standard meal",
                    chanb: "Chargeable",
                    ftyp: "VALUE",
                    extra: "12_VALUE",
                    chekbcode: "BAGL",
                    chanb_info:
                      "UPTO 24 hrs of departure Change of Flight is Free,WITHIN From 0-24 hrs of departure Change of Flight is Non-Refundable,NOSHOW From 0 hrs before  of departure Change of Flight is Non-Refundable",
                    canb: "Chargeable",
                    cabb_info:
                      "UPTO 24 hrs of departure Cancellation of Flight is Free,WITHIN From 0-24 hrs of departure Cancellation of Flight is Non-Refundable,NOSHOW From 0 hrs before  of departure Cancellation of Flight is Non-Refundable",
                    bagage_info: "30kg BAG INCLUDED IN FARE",
                    markupnames: [],
                    ttf: 25786.5,
                  },
                  paxpr: [
                    {
                      bf: 14455,
                      tf: 25786.5,
                      tax: [
                        { OT: 11331.5 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "ORL7IN5",
                      ptype: "ADT",
                      scur: "INR",
                      extra:
                        "1_109_O_1_9_0_tx-2186:170:PZ:0_4246:6450:YQ:0_8947:0:BAGL:0_9327:1026.5:K3:0_10386:120:ZR:0_11947:790:F6:0_12006:1170:P2:0_12434:1605:IN:0_",
                      ruleid:
                        "b7Epj4q8RrciHN+t33+j6CH5cdRtjzMqmtc6Ydt53ymhQozXlONozKDeF7aO/DbGAO8K6tXzJ16Rfs5fUX41PoAzc0DAexpKMcT6yt4vWi6DnyG3nWxSFLQ/cUO1570IBQGHoM3lqYcsurCRq3PlUw==",
                      hsc: "980316815cda9b8659648ecf52248d4a191d25fe000972ece1a03cb3a6ba9fda",
                      sup_fare: "14455_25786.5_14005",
                      cabb: "Free",
                      bagage_info: "30kg BAG INCLUDED IN FARE",
                      chekbcode: "BAGL",
                      psup_fare: "14455_25786.5",
                    },
                  ],
                  uniqueId: "VALUE",
                  routeId: "5izg0ebkgw",
                  servicefee: [],
                  selected: true,
                },
              ],
              tktair: "FZ",
              tecst: "no",
              cl: "Economy",
              extra: 13919294,
              token:
                "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiYXBpdGF3ZmVlcSIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL2dpdmVubmFtZSI6IkFQSSBUQVdGRUVRIiwiZ3JvdXBBY2Nlc3MiOiIzLDM2LDExMiIsImlzQXB0Q2xudCI6IkZhbHNlIiwibG9jYXRpb24iOiI5OTk5IiwiY2hrTGNuIjoiIiwicmVzVG9rZW4iOiJkZWRhZDdkeWY4N2FuOTc0dGJxYjY0YmJ6ZjZlOTYwYzA5ZTE5NmRhZTg2MCIsImNsaWVudElQIjoiMTcyLjE5LjIzLjU0IiwicmVzQ2hhbm5lbElEIjoiNSIsInJlc0NoYW5uZWxOYW1lIjoiVFBBUEkiLCJkZWZhdWx0VXNlck5hbWUiOiJhcGl0YXdmZWVxIiwibmJmIjoxNjg1NTE1MjM3LCJleHAiOjE2ODU1MTc2MzcsImlzcyI6ImZseWR1YmFpLmNvbSIsImF1ZCI6IlRBV0ZFRVFUUlZMU19GWl9OUCJ9.rAW3YT-bX_-yADfvg3QkuPAbOJhkhRUN6HwoS_6PyiM",
              stop: 1,
            },
          ],
          paxpr: [
            {
              bf: 34440,
              tf: 57575,
              tax: [
                { OT: 23135 },
                { attmkp: 0 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              fbc: "ORL7IN5",
              ptype: "ADT",
              scur: "INR",
              extra:
                "1_13_O_1_9_0_tx-2186:170:PZ:0_4246:6450:YQ:0_8947:0:BAGL:0_9327:1026.5:K3:0_10386:120:ZR:0_11947:790:F6:0_12006:1170:P2:0_12434:1605:IN:0_",
              ruleid:
                "n+l3Ydkz/JUtglH+vvnEdBZ+CHIn9HNL+12RdyPuUypN3Vrd+8Of0oA7a4N7g8CvZItP8LZIcrAqzBeCOoIuvjLwhemGenh8Kvds7rAxHg6X710uw+p5f6/y1zghrpgJ",
              hsc: "f24c437f6224e2c5726a01759eee45874bab71966d3aa1e53e23e6f9cdb162c2",
              sup_fare: "14455_25786.5_14005",
              cabb: "Free",
              bagage_info: "30kg BAG INCLUDED IN FARE",
              chekbcode: "BAGL",
              psup_fare: "34440_57575",
            },
          ],
          pr: {
            bf: 34440,
            tf: 57575,
            tax: [
              { OT: 23135 },
              { attmkp: 0 },
              { tamkp: 0 },
              { stamkp: 0 },
              { attdisc: 0 },
              { tadisc: 0 },
              { stadisc: 0 },
            ],
            scur: "INR",
            ucur: "INR",
            exchange_rate: 1,
            sup_fare: "34440_57575",
            sup_cur_fare: "",
            cabb: "Free",
            chekb: "7kg INCLUDED",
            meals: "Standard meal",
            chanb: "Chargeable",
            ftyp: "VALUE",
            extra: "12_VALUE",
            chekbcode: "BAGL",
            chanb_info:
              "UPTO 24 hrs of departure Change of Flight is Free,WITHIN From 0-24 hrs of departure Change of Flight is Non-Refundable,NOSHOW From 0 hrs before  of departure Change of Flight is Non-Refundable",
            canb: "Chargeable",
            cabb_info:
              "UPTO 24 hrs of departure Cancellation of Flight is Free,WITHIN From 0-24 hrs of departure Cancellation of Flight is Non-Refundable,NOSHOW From 0 hrs before  of departure Cancellation of Flight is Non-Refundable",
            bagage_info: "30kg BAG INCLUDED IN FARE",
            markupnames: [],
            ttf: 57575,
          },
          sup: "5",
          airlineList: "FZ",
          destorigins: [
            {
              tid: "2IK9xr8pc5JeGbVy_2",
              ref: 2,
              office_id: "",
              sdur: "6:45",
              re: "",
              sup: "5",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DXB",
                  dd: "2023-06-09T17:00:00",
                  ad: "2023-06-09T19:15:00",
                  rbd: "",
                  fn: "018",
                  trn: "",
                  eq: "73X",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "1", arr: "2" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161399,
                  flightTime: 75,
                  fdur: "1:15",
                },
                {
                  ref: 2,
                  da: "DXB",
                  ar: "HYD",
                  dd: "2023-06-09T20:50:00",
                  ad: "2023-06-10T02:15:00",
                  rbd: "",
                  fn: "435",
                  trn: "",
                  eq: "73B",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "2", arr: "" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161456,
                  flightTime: 235,
                  fdur: "3:55",
                },
              ],
              fareop: [
                {
                  fareKey: [],
                  pr: {
                    bf: 19985,
                    tf: 31788.5,
                    tax: [
                      { OT: 11803.5 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    scur: "INR",
                    ucur: "INR",
                    exchange_rate: 1,
                    sup_fare: "19985_31788.5",
                    sup_cur_fare: "",
                    cabb: "Free",
                    chekb: "7kg INCLUDED",
                    meals: "Standard meal",
                    chanb: "Chargeable",
                    ftyp: "FLEX",
                    extra: "13_FLEX",
                    chekbcode: "BAGL",
                    chanb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Change of Flight is Free,NOSHOWUPTO 720 hrs of departure Change of Flight is Free,NOSHOWAFTER From 720 hrs before  of departure Change of Flight is Non-Refundable",
                    canb: "Chargeable",
                    cabb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Cancellation of Flight is Free,NOSHOWUPTO 720 hrs of departure Cancellation of Flight is Free,NOSHOWAFTER From 720 hrs before  of departure Cancellation of Flight is Non-Refundable",
                    bagage_info: "30kg BAG INCLUDED IN FARE",
                    markupnames: [],
                    ttf: 31788.5,
                  },
                  paxpr: [
                    {
                      bf: 19985,
                      tf: 31788.5,
                      tax: [
                        { OT: 11803.5 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "ORML8IN5",
                      ptype: "ADT",
                      scur: "INR",
                      extra:
                        "1_14_O_1_9_0_tx-1764:1370:QA:0_2186:170:PZ:0_4246:6450:YQ:0_8947:0:BAGL:0_8966:1370:G4:0_9327:1303.5:K3:0_10386:120:ZR:0_11947:790:F6:0_13230:230:R9:0_",
                      ruleid:
                        "vcHnfuALAHFc7rb9Qj4/wVynIfJ/XB/vnt5lWewxSALdEVWgu3w8400pbatJN8v+dXRVoM0HZ8U9jxms9hE/Ws/RUwZbLq3+/lDxX34TkEnJ9351zGkK7QqSS78PWRvgnFFh9Gsw/dpcQuWfjFtRfA==",
                      hsc: "f6adabb4d84c8f5019ebe9ca55702cbbc3bdbf562eaf62730d959b6de3050456",
                      sup_fare: "19985_31788.5_19535",
                      cabb: "Free",
                      bagage_info: "30kg BAG INCLUDED IN FARE",
                      chekbcode: "BAGL",
                      psup_fare: "19985_31788.5",
                    },
                  ],
                  uniqueId: "FLEX",
                  routeId: "z6yqvapyiq",
                  servicefee: [],
                  selected: true,
                },
              ],
              tktair: "FZ",
              tecst: "no",
              cl: "Economy",
              extra: 13916393,
              token:
                "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiYXBpdGF3ZmVlcSIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL2dpdmVubmFtZSI6IkFQSSBUQVdGRUVRIiwiZ3JvdXBBY2Nlc3MiOiIzLDM2LDExMiIsImlzQXB0Q2xudCI6IkZhbHNlIiwibG9jYXRpb24iOiI5OTk5IiwiY2hrTGNuIjoiIiwicmVzVG9rZW4iOiJkZWRhZDdkeWY4N2FuOTc0dGJxYjY0YmJ6ZjZlOTYwYzA5ZTE5NmRhZTg2MCIsImNsaWVudElQIjoiMTcyLjE5LjIzLjU0IiwicmVzQ2hhbm5lbElEIjoiNSIsInJlc0NoYW5uZWxOYW1lIjoiVFBBUEkiLCJkZWZhdWx0VXNlck5hbWUiOiJhcGl0YXdmZWVxIiwibmJmIjoxNjg1NTE1MjM3LCJleHAiOjE2ODU1MTc2MzcsImlzcyI6ImZseWR1YmFpLmNvbSIsImF1ZCI6IlRBV0ZFRVFUUlZMU19GWl9OUCJ9.rAW3YT-bX_-yADfvg3QkuPAbOJhkhRUN6HwoS_6PyiM",
              stop: 1,
            },
            {
              tid: "wWD4g5DeHawRLnMe_2",
              ref: 2,
              office_id: "",
              sdur: "13:45",
              re: "",
              sup: "5",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DXB",
                  dd: "2023-06-09T10:00:00",
                  ad: "2023-06-09T12:15:00",
                  rbd: "",
                  fn: "002",
                  trn: "",
                  eq: "73X",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "1", arr: "2" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161402,
                  flightTime: 75,
                  fdur: "1:15",
                },
                {
                  ref: 2,
                  da: "DXB",
                  ar: "HYD",
                  dd: "2023-06-09T20:50:00",
                  ad: "2023-06-10T02:15:00",
                  rbd: "",
                  fn: "435",
                  trn: "",
                  eq: "73B",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "2", arr: "" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161456,
                  flightTime: 235,
                  fdur: "3:55",
                },
              ],
              fareop: [
                {
                  fareKey: [],
                  pr: {
                    bf: 19985,
                    tf: 31788.5,
                    tax: [
                      { OT: 11803.5 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    scur: "INR",
                    ucur: "INR",
                    exchange_rate: 1,
                    sup_fare: "19985_31788.5",
                    sup_cur_fare: "",
                    cabb: "Free",
                    chekb: "7kg INCLUDED",
                    meals: "Standard meal",
                    chanb: "Chargeable",
                    ftyp: "FLEX",
                    extra: "13_FLEX",
                    chekbcode: "BAGL",
                    chanb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Change of Flight is Free,NOSHOWUPTO 720 hrs of departure Change of Flight is Free,NOSHOWAFTER From 720 hrs before  of departure Change of Flight is Non-Refundable",
                    canb: "Chargeable",
                    cabb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Cancellation of Flight is Free,NOSHOWUPTO 720 hrs of departure Cancellation of Flight is Free,NOSHOWAFTER From 720 hrs before  of departure Cancellation of Flight is Non-Refundable",
                    bagage_info: "30kg BAG INCLUDED IN FARE",
                    markupnames: [],
                    ttf: 31788.5,
                  },
                  paxpr: [
                    {
                      bf: 19985,
                      tf: 31788.5,
                      tax: [
                        { OT: 11803.5 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "ORML8IN5",
                      ptype: "ADT",
                      scur: "INR",
                      extra:
                        "1_46_O_1_9_0_tx-1764:1370:QA:0_2186:170:PZ:0_4246:6450:YQ:0_8947:0:BAGL:0_8966:1370:G4:0_9327:1303.5:K3:0_10386:120:ZR:0_11947:790:F6:0_13230:230:R9:0_",
                      ruleid:
                        "kidKlbGzGUZPQwIWuapNd1ynIfJ/XB/vnt5lWewxSALdEVWgu3w8400pbatJN8v+dXRVoM0HZ8U9jxms9hE/Ws/RUwZbLq3+/lDxX34TkEnJ9351zGkK7QqSS78PWRvgnFFh9Gsw/dpcQuWfjFtRfA==",
                      hsc: "358756fcc4adab781b74de8e64cf9d1fa1a84cb9e575b8f96e08dd833bb690f0",
                      sup_fare: "19985_31788.5_19535",
                      cabb: "Free",
                      bagage_info: "30kg BAG INCLUDED IN FARE",
                      chekbcode: "BAGL",
                      psup_fare: "19985_31788.5",
                    },
                  ],
                  uniqueId: "FLEX",
                  routeId: "ej1eopzg7j",
                  servicefee: [],
                  selected: true,
                },
              ],
              tktair: "FZ",
              tecst: "no",
              cl: "Economy",
              extra: 13915297,
              token:
                "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiYXBpdGF3ZmVlcSIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL2dpdmVubmFtZSI6IkFQSSBUQVdGRUVRIiwiZ3JvdXBBY2Nlc3MiOiIzLDM2LDExMiIsImlzQXB0Q2xudCI6IkZhbHNlIiwibG9jYXRpb24iOiI5OTk5IiwiY2hrTGNuIjoiIiwicmVzVG9rZW4iOiJkZWRhZDdkeWY4N2FuOTc0dGJxYjY0YmJ6ZjZlOTYwYzA5ZTE5NmRhZTg2MCIsImNsaWVudElQIjoiMTcyLjE5LjIzLjU0IiwicmVzQ2hhbm5lbElEIjoiNSIsInJlc0NoYW5uZWxOYW1lIjoiVFBBUEkiLCJkZWZhdWx0VXNlck5hbWUiOiJhcGl0YXdmZWVxIiwibmJmIjoxNjg1NTE1MjM3LCJleHAiOjE2ODU1MTc2MzcsImlzcyI6ImZseWR1YmFpLmNvbSIsImF1ZCI6IlRBV0ZFRVFUUlZMU19GWl9OUCJ9.rAW3YT-bX_-yADfvg3QkuPAbOJhkhRUN6HwoS_6PyiM",
              stop: 1,
            },
          ],
          servicefee: [],
        },
        {
          srcorigns: [
            {
              tid: "CiqwF0HR3m8u853l_1",
              ref: 1,
              office_id: "",
              sdur: "8:25",
              re: "",
              sup: "5",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DXB",
                  dd: "2023-06-07T03:00:00",
                  ad: "2023-06-07T05:30:00",
                  rbd: "",
                  fn: "436",
                  trn: "",
                  eq: "73B",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "", arr: "2" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161457,
                  flightTime: 240,
                  fdur: "4:0",
                },
                {
                  ref: 2,
                  da: "DXB",
                  ar: "DOH",
                  dd: "2023-06-07T08:45:00",
                  ad: "2023-06-07T08:55:00",
                  rbd: "",
                  fn: "001",
                  trn: "",
                  eq: "73X",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "2", arr: "1" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161405,
                  flightTime: 70,
                  fdur: "1:10",
                },
              ],
              fareop: [
                {
                  fareKey: [],
                  pr: {
                    bf: 19985,
                    tf: 31593.5,
                    tax: [
                      { OT: 11608.5 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    scur: "INR",
                    ucur: "INR",
                    exchange_rate: 1,
                    sup_fare: "19985_31593.5",
                    sup_cur_fare: "",
                    cabb: "Free",
                    chekb: "7kg INCLUDED",
                    meals: "Standard meal",
                    chanb: "Chargeable",
                    ftyp: "FLEX",
                    extra: "13_FLEX",
                    chekbcode: "BAGL",
                    chanb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Change of Flight is Free,NOSHOWUPTO 720 hrs of departure Change of Flight is Free,NOSHOWAFTER From 720 hrs before  of departure Change of Flight is Non-Refundable",
                    canb: "Chargeable",
                    cabb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Cancellation of Flight is Free,NOSHOWUPTO 720 hrs of departure Cancellation of Flight is Free,NOSHOWAFTER From 720 hrs before  of departure Cancellation of Flight is Non-Refundable",
                    bagage_info: "30kg BAG INCLUDED IN FARE",
                    markupnames: [],
                    ttf: 31593.5,
                  },
                  paxpr: [
                    {
                      bf: 19985,
                      tf: 31593.5,
                      tax: [
                        { OT: 11608.5 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "ORML8IN5",
                      ptype: "ADT",
                      scur: "INR",
                      extra:
                        "1_15_O_1_9_0_tx-2186:170:PZ:0_4246:6450:YQ:0_8947:0:BAGL:0_9327:1303.5:K3:0_10386:120:ZR:0_11947:790:F6:0_12006:1170:P2:0_12434:1605:IN:0_",
                      ruleid:
                        "L2fFm1OvZrZSEJNT4qaTbhZ+CHIn9HNL+12RdyPuUypN3Vrd+8Of0oA7a4N7g8CvZItP8LZIcrAqzBeCOoIuvjLwhemGenh8Kvds7rAxHg77sBOV2+bcsWuISEqTM1NI",
                      hsc: "9b341c92b5eccaa44d70c0d0c5bc8c495296cac362423d0bf763af17d716f435",
                      sup_fare: "19985_31593.5_19535",
                      cabb: "Free",
                      bagage_info: "30kg BAG INCLUDED IN FARE",
                      chekbcode: "BAGL",
                      psup_fare: "19985_31593.5",
                    },
                  ],
                  uniqueId: "FLEX",
                  routeId: "4m42cyz23e",
                  servicefee: [],
                  selected: true,
                },
              ],
              tktair: "FZ",
              tecst: "no",
              cl: "Economy",
              extra: 13919293,
              token:
                "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiYXBpdGF3ZmVlcSIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL2dpdmVubmFtZSI6IkFQSSBUQVdGRUVRIiwiZ3JvdXBBY2Nlc3MiOiIzLDM2LDExMiIsImlzQXB0Q2xudCI6IkZhbHNlIiwibG9jYXRpb24iOiI5OTk5IiwiY2hrTGNuIjoiIiwicmVzVG9rZW4iOiJkZWRhZDdkeWY4N2FuOTc0dGJxYjY0YmJ6ZjZlOTYwYzA5ZTE5NmRhZTg2MCIsImNsaWVudElQIjoiMTcyLjE5LjIzLjU0IiwicmVzQ2hhbm5lbElEIjoiNSIsInJlc0NoYW5uZWxOYW1lIjoiVFBBUEkiLCJkZWZhdWx0VXNlck5hbWUiOiJhcGl0YXdmZWVxIiwibmJmIjoxNjg1NTE1MjM3LCJleHAiOjE2ODU1MTc2MzcsImlzcyI6ImZseWR1YmFpLmNvbSIsImF1ZCI6IlRBV0ZFRVFUUlZMU19GWl9OUCJ9.rAW3YT-bX_-yADfvg3QkuPAbOJhkhRUN6HwoS_6PyiM",
              stop: 1,
            },
            {
              tid: "LkHBC8qNW413G1av_1",
              ref: 1,
              office_id: "",
              sdur: "15:25",
              re: "",
              sup: "5",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DXB",
                  dd: "2023-06-07T03:00:00",
                  ad: "2023-06-07T05:30:00",
                  rbd: "",
                  fn: "436",
                  trn: "",
                  eq: "73B",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "", arr: "2" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161457,
                  flightTime: 240,
                  fdur: "4:0",
                },
                {
                  ref: 2,
                  da: "DXB",
                  ar: "DOH",
                  dd: "2023-06-07T15:45:00",
                  ad: "2023-06-07T15:55:00",
                  rbd: "",
                  fn: "017",
                  trn: "",
                  eq: "73X",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "2", arr: "1" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161400,
                  flightTime: 70,
                  fdur: "1:10",
                },
              ],
              fareop: [
                {
                  fareKey: [],
                  pr: {
                    bf: 19985,
                    tf: 31593.5,
                    tax: [
                      { OT: 11608.5 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    scur: "INR",
                    ucur: "INR",
                    exchange_rate: 1,
                    sup_fare: "19985_31593.5",
                    sup_cur_fare: "",
                    cabb: "Free",
                    chekb: "7kg INCLUDED",
                    meals: "Standard meal",
                    chanb: "Chargeable",
                    ftyp: "FLEX",
                    extra: "13_FLEX",
                    chekbcode: "BAGL",
                    chanb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Change of Flight is Free,NOSHOWUPTO 720 hrs of departure Change of Flight is Free,NOSHOWAFTER From 720 hrs before  of departure Change of Flight is Non-Refundable",
                    canb: "Chargeable",
                    cabb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Cancellation of Flight is Free,NOSHOWUPTO 720 hrs of departure Cancellation of Flight is Free,NOSHOWAFTER From 720 hrs before  of departure Cancellation of Flight is Non-Refundable",
                    bagage_info: "30kg BAG INCLUDED IN FARE",
                    markupnames: [],
                    ttf: 31593.5,
                  },
                  paxpr: [
                    {
                      bf: 19985,
                      tf: 31593.5,
                      tax: [
                        { OT: 11608.5 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "ORML8IN5",
                      ptype: "ADT",
                      scur: "INR",
                      extra:
                        "1_77_O_1_9_0_tx-2186:170:PZ:0_4246:6450:YQ:0_8947:0:BAGL:0_9327:1303.5:K3:0_10386:120:ZR:0_11947:790:F6:0_12006:1170:P2:0_12434:1605:IN:0_",
                      ruleid:
                        "n/qlhkyIHxyd4GdDFf4w7BZ+CHIn9HNL+12RdyPuUypN3Vrd+8Of0oA7a4N7g8CvZItP8LZIcrAqzBeCOoIuvjLwhemGenh8Kvds7rAxHg77sBOV2+bcsWuISEqTM1NI",
                      hsc: "ac12899784824771f13bfdd1c01a49ccf1f4338ef71613d14a3d692f76299d71",
                      sup_fare: "19985_31593.5_19535",
                      cabb: "Free",
                      bagage_info: "30kg BAG INCLUDED IN FARE",
                      chekbcode: "BAGL",
                      psup_fare: "19985_31593.5",
                    },
                  ],
                  uniqueId: "FLEX",
                  routeId: "35bgwkywz6",
                  servicefee: [],
                  selected: true,
                },
              ],
              tktair: "FZ",
              tecst: "no",
              cl: "Economy",
              extra: 13919296,
              token:
                "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiYXBpdGF3ZmVlcSIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL2dpdmVubmFtZSI6IkFQSSBUQVdGRUVRIiwiZ3JvdXBBY2Nlc3MiOiIzLDM2LDExMiIsImlzQXB0Q2xudCI6IkZhbHNlIiwibG9jYXRpb24iOiI5OTk5IiwiY2hrTGNuIjoiIiwicmVzVG9rZW4iOiJkZWRhZDdkeWY4N2FuOTc0dGJxYjY0YmJ6ZjZlOTYwYzA5ZTE5NmRhZTg2MCIsImNsaWVudElQIjoiMTcyLjE5LjIzLjU0IiwicmVzQ2hhbm5lbElEIjoiNSIsInJlc0NoYW5uZWxOYW1lIjoiVFBBUEkiLCJkZWZhdWx0VXNlck5hbWUiOiJhcGl0YXdmZWVxIiwibmJmIjoxNjg1NTE1MjM3LCJleHAiOjE2ODU1MTc2MzcsImlzcyI6ImZseWR1YmFpLmNvbSIsImF1ZCI6IlRBV0ZFRVFUUlZMU19GWl9OUCJ9.rAW3YT-bX_-yADfvg3QkuPAbOJhkhRUN6HwoS_6PyiM",
              stop: 1,
            },
            {
              tid: "usAXzR6W45MBtBLp_1",
              ref: 1,
              office_id: "",
              sdur: "19:25",
              re: "",
              sup: "5",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DXB",
                  dd: "2023-06-07T03:00:00",
                  ad: "2023-06-07T05:30:00",
                  rbd: "",
                  fn: "436",
                  trn: "",
                  eq: "73B",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "", arr: "2" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161457,
                  flightTime: 240,
                  fdur: "4:0",
                },
                {
                  ref: 2,
                  da: "DXB",
                  ar: "DOH",
                  dd: "2023-06-07T19:45:00",
                  ad: "2023-06-07T19:55:00",
                  rbd: "",
                  fn: "005",
                  trn: "",
                  eq: "73X",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "2", arr: "1" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161381,
                  flightTime: 70,
                  fdur: "1:10",
                },
              ],
              fareop: [
                {
                  fareKey: [],
                  pr: {
                    bf: 19985,
                    tf: 31593.5,
                    tax: [
                      { OT: 11608.5 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    scur: "INR",
                    ucur: "INR",
                    exchange_rate: 1,
                    sup_fare: "19985_31593.5",
                    sup_cur_fare: "",
                    cabb: "Free",
                    chekb: "7kg INCLUDED",
                    meals: "Standard meal",
                    chanb: "Chargeable",
                    ftyp: "FLEX",
                    extra: "13_FLEX",
                    chekbcode: "BAGL",
                    chanb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Change of Flight is Free,NOSHOWUPTO 720 hrs of departure Change of Flight is Free,NOSHOWAFTER From 720 hrs before  of departure Change of Flight is Non-Refundable",
                    canb: "Chargeable",
                    cabb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Cancellation of Flight is Free,NOSHOWUPTO 720 hrs of departure Cancellation of Flight is Free,NOSHOWAFTER From 720 hrs before  of departure Cancellation of Flight is Non-Refundable",
                    bagage_info: "30kg BAG INCLUDED IN FARE",
                    markupnames: [],
                    ttf: 31593.5,
                  },
                  paxpr: [
                    {
                      bf: 19985,
                      tf: 31593.5,
                      tax: [
                        { OT: 11608.5 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "ORML8IN5",
                      ptype: "ADT",
                      scur: "INR",
                      extra:
                        "1_111_O_1_9_0_tx-2186:170:PZ:0_4246:6450:YQ:0_8947:0:BAGL:0_9327:1303.5:K3:0_10386:120:ZR:0_11947:790:F6:0_12006:1170:P2:0_12434:1605:IN:0_",
                      ruleid:
                        "OPwUyGqIJAJUenJP1ccQ9SH5cdRtjzMqmtc6Ydt53ymhQozXlONozKDeF7aO/DbGAO8K6tXzJ16Rfs5fUX41PoAzc0DAexpKMcT6yt4vWi5QaY31vw17VrswRPIWnfWdBQGHoM3lqYcsurCRq3PlUw==",
                      hsc: "a22e9bd6b2bde9e66a388b3ca941838ce7bf8882e67f7ce49e7e9aa26916900d",
                      sup_fare: "19985_31593.5_19535",
                      cabb: "Free",
                      bagage_info: "30kg BAG INCLUDED IN FARE",
                      chekbcode: "BAGL",
                      psup_fare: "19985_31593.5",
                    },
                  ],
                  uniqueId: "FLEX",
                  routeId: "xpqcwzn56j",
                  servicefee: [],
                  selected: true,
                },
              ],
              tktair: "FZ",
              tecst: "no",
              cl: "Economy",
              extra: 13919294,
              token:
                "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiYXBpdGF3ZmVlcSIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL2dpdmVubmFtZSI6IkFQSSBUQVdGRUVRIiwiZ3JvdXBBY2Nlc3MiOiIzLDM2LDExMiIsImlzQXB0Q2xudCI6IkZhbHNlIiwibG9jYXRpb24iOiI5OTk5IiwiY2hrTGNuIjoiIiwicmVzVG9rZW4iOiJkZWRhZDdkeWY4N2FuOTc0dGJxYjY0YmJ6ZjZlOTYwYzA5ZTE5NmRhZTg2MCIsImNsaWVudElQIjoiMTcyLjE5LjIzLjU0IiwicmVzQ2hhbm5lbElEIjoiNSIsInJlc0NoYW5uZWxOYW1lIjoiVFBBUEkiLCJkZWZhdWx0VXNlck5hbWUiOiJhcGl0YXdmZWVxIiwibmJmIjoxNjg1NTE1MjM3LCJleHAiOjE2ODU1MTc2MzcsImlzcyI6ImZseWR1YmFpLmNvbSIsImF1ZCI6IlRBV0ZFRVFUUlZMU19GWl9OUCJ9.rAW3YT-bX_-yADfvg3QkuPAbOJhkhRUN6HwoS_6PyiM",
              stop: 1,
            },
          ],
          paxpr: [
            {
              bf: 34440,
              tf: 57575,
              tax: [
                { OT: 23135 },
                { attmkp: 0 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              fbc: "ORML8IN5",
              ptype: "ADT",
              scur: "INR",
              extra:
                "1_15_O_1_9_0_tx-2186:170:PZ:0_4246:6450:YQ:0_8947:0:BAGL:0_9327:1303.5:K3:0_10386:120:ZR:0_11947:790:F6:0_12006:1170:P2:0_12434:1605:IN:0_",
              ruleid:
                "L2fFm1OvZrZSEJNT4qaTbhZ+CHIn9HNL+12RdyPuUypN3Vrd+8Of0oA7a4N7g8CvZItP8LZIcrAqzBeCOoIuvjLwhemGenh8Kvds7rAxHg77sBOV2+bcsWuISEqTM1NI",
              hsc: "9b341c92b5eccaa44d70c0d0c5bc8c495296cac362423d0bf763af17d716f435",
              sup_fare: "19985_31593.5_19535",
              cabb: "Free",
              bagage_info: "30kg BAG INCLUDED IN FARE",
              chekbcode: "BAGL",
              psup_fare: "34440_57575",
            },
          ],
          pr: {
            bf: 34440,
            tf: 57575,
            tax: [
              { OT: 23135 },
              { attmkp: 0 },
              { tamkp: 0 },
              { stamkp: 0 },
              { attdisc: 0 },
              { tadisc: 0 },
              { stadisc: 0 },
            ],
            scur: "INR",
            ucur: "INR",
            exchange_rate: 1,
            sup_fare: "34440_57575",
            sup_cur_fare: "",
            cabb: "Free",
            chekb: "7kg INCLUDED",
            meals: "Standard meal",
            chanb: "Chargeable",
            ftyp: "FLEX",
            extra: "13_FLEX",
            chekbcode: "BAGL",
            chanb_info:
              "BEFOREDEPARTURE 0 hrs of departure Change of Flight is Free,NOSHOWUPTO 720 hrs of departure Change of Flight is Free,NOSHOWAFTER From 720 hrs before  of departure Change of Flight is Non-Refundable",
            canb: "Chargeable",
            cabb_info:
              "BEFOREDEPARTURE 0 hrs of departure Cancellation of Flight is Free,NOSHOWUPTO 720 hrs of departure Cancellation of Flight is Free,NOSHOWAFTER From 720 hrs before  of departure Cancellation of Flight is Non-Refundable",
            bagage_info: "30kg BAG INCLUDED IN FARE",
            markupnames: [],
            ttf: 57575,
          },
          sup: "5",
          airlineList: "FZ",
          destorigins: [
            {
              tid: "cFVr5JDDqWde1dsl_2",
              ref: 2,
              office_id: "",
              sdur: "6:45",
              re: "",
              sup: "5",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DXB",
                  dd: "2023-06-09T17:00:00",
                  ad: "2023-06-09T19:15:00",
                  rbd: "",
                  fn: "018",
                  trn: "",
                  eq: "73X",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "1", arr: "2" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161399,
                  flightTime: 75,
                  fdur: "1:15",
                },
                {
                  ref: 2,
                  da: "DXB",
                  ar: "HYD",
                  dd: "2023-06-09T20:50:00",
                  ad: "2023-06-10T02:15:00",
                  rbd: "",
                  fn: "435",
                  trn: "",
                  eq: "73B",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "2", arr: "" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161456,
                  flightTime: 235,
                  fdur: "3:55",
                },
              ],
              fareop: [
                {
                  fareKey: [],
                  pr: {
                    bf: 14455,
                    tf: 25981.5,
                    tax: [
                      { OT: 11526.5 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    scur: "INR",
                    ucur: "INR",
                    exchange_rate: 1,
                    sup_fare: "14455_25981.5",
                    sup_cur_fare: "",
                    cabb: "Free",
                    chekb: "7kg INCLUDED",
                    meals: "Standard meal",
                    chanb: "Chargeable",
                    ftyp: "VALUE",
                    extra: "12_VALUE",
                    chekbcode: "BAGL",
                    chanb_info:
                      "UPTO 24 hrs of departure Change of Flight is Free,WITHIN From 0-24 hrs of departure Change of Flight is Non-Refundable,NOSHOW From 0 hrs before  of departure Change of Flight is Non-Refundable",
                    canb: "Chargeable",
                    cabb_info:
                      "UPTO 24 hrs of departure Cancellation of Flight is Free,WITHIN From 0-24 hrs of departure Cancellation of Flight is Non-Refundable,NOSHOW From 0 hrs before  of departure Cancellation of Flight is Non-Refundable",
                    bagage_info: "30kg BAG INCLUDED IN FARE",
                    markupnames: [],
                    ttf: 25981.5,
                  },
                  paxpr: [
                    {
                      bf: 14455,
                      tf: 25981.5,
                      tax: [
                        { OT: 11526.5 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "ORL7IN5",
                      ptype: "ADT",
                      scur: "INR",
                      extra:
                        "1_16_O_1_9_0_tx-1764:1370:QA:0_2186:170:PZ:0_4246:6450:YQ:0_8947:0:BAGL:0_8966:1370:G4:0_9327:1026.5:K3:0_10386:120:ZR:0_11947:790:F6:0_13230:230:R9:0_",
                      ruleid:
                        "kYqIxDO8JvMZaHRD/DTYk1ynIfJ/XB/vnt5lWewxSALdEVWgu3w8400pbatJN8v+dXRVoM0HZ8U9jxms9hE/Ws/RUwZbLq3+/lDxX34TkEkpnt2f57bucsArrJ1QaV3YnFFh9Gsw/dpcQuWfjFtRfA==",
                      hsc: "05c5f7cde6bc1207beef4f6abf7a72257a86490010c15c0d7156e12b535d5366",
                      sup_fare: "14455_25981.5_14005",
                      cabb: "Free",
                      bagage_info: "30kg BAG INCLUDED IN FARE",
                      chekbcode: "BAGL",
                      psup_fare: "14455_25981.5",
                    },
                  ],
                  uniqueId: "VALUE",
                  routeId: "knl7eq2mpof",
                  servicefee: [],
                  selected: true,
                },
              ],
              tktair: "FZ",
              tecst: "no",
              cl: "Economy",
              extra: 13916393,
              token:
                "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiYXBpdGF3ZmVlcSIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL2dpdmVubmFtZSI6IkFQSSBUQVdGRUVRIiwiZ3JvdXBBY2Nlc3MiOiIzLDM2LDExMiIsImlzQXB0Q2xudCI6IkZhbHNlIiwibG9jYXRpb24iOiI5OTk5IiwiY2hrTGNuIjoiIiwicmVzVG9rZW4iOiJkZWRhZDdkeWY4N2FuOTc0dGJxYjY0YmJ6ZjZlOTYwYzA5ZTE5NmRhZTg2MCIsImNsaWVudElQIjoiMTcyLjE5LjIzLjU0IiwicmVzQ2hhbm5lbElEIjoiNSIsInJlc0NoYW5uZWxOYW1lIjoiVFBBUEkiLCJkZWZhdWx0VXNlck5hbWUiOiJhcGl0YXdmZWVxIiwibmJmIjoxNjg1NTE1MjM3LCJleHAiOjE2ODU1MTc2MzcsImlzcyI6ImZseWR1YmFpLmNvbSIsImF1ZCI6IlRBV0ZFRVFUUlZMU19GWl9OUCJ9.rAW3YT-bX_-yADfvg3QkuPAbOJhkhRUN6HwoS_6PyiM",
              stop: 1,
            },
            {
              tid: "EmMmTDLJfg9rS6pa_2",
              ref: 2,
              office_id: "",
              sdur: "13:45",
              re: "",
              sup: "5",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DXB",
                  dd: "2023-06-09T10:00:00",
                  ad: "2023-06-09T12:15:00",
                  rbd: "",
                  fn: "002",
                  trn: "",
                  eq: "73X",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "1", arr: "2" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161402,
                  flightTime: 75,
                  fdur: "1:15",
                },
                {
                  ref: 2,
                  da: "DXB",
                  ar: "HYD",
                  dd: "2023-06-09T20:50:00",
                  ad: "2023-06-10T02:15:00",
                  rbd: "",
                  fn: "435",
                  trn: "",
                  eq: "73B",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "2", arr: "" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161456,
                  flightTime: 235,
                  fdur: "3:55",
                },
              ],
              fareop: [
                {
                  fareKey: [],
                  pr: {
                    bf: 14455,
                    tf: 25981.5,
                    tax: [
                      { OT: 11526.5 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    scur: "INR",
                    ucur: "INR",
                    exchange_rate: 1,
                    sup_fare: "14455_25981.5",
                    sup_cur_fare: "",
                    cabb: "Free",
                    chekb: "7kg INCLUDED",
                    meals: "Standard meal",
                    chanb: "Chargeable",
                    ftyp: "VALUE",
                    extra: "12_VALUE",
                    chekbcode: "BAGL",
                    chanb_info:
                      "UPTO 24 hrs of departure Change of Flight is Free,WITHIN From 0-24 hrs of departure Change of Flight is Non-Refundable,NOSHOW From 0 hrs before  of departure Change of Flight is Non-Refundable",
                    canb: "Chargeable",
                    cabb_info:
                      "UPTO 24 hrs of departure Cancellation of Flight is Free,WITHIN From 0-24 hrs of departure Cancellation of Flight is Non-Refundable,NOSHOW From 0 hrs before  of departure Cancellation of Flight is Non-Refundable",
                    bagage_info: "30kg BAG INCLUDED IN FARE",
                    markupnames: [],
                    ttf: 25981.5,
                  },
                  paxpr: [
                    {
                      bf: 14455,
                      tf: 25981.5,
                      tax: [
                        { OT: 11526.5 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "ORL7IN5",
                      ptype: "ADT",
                      scur: "INR",
                      extra:
                        "1_48_O_1_9_0_tx-1764:1370:QA:0_2186:170:PZ:0_4246:6450:YQ:0_8947:0:BAGL:0_8966:1370:G4:0_9327:1026.5:K3:0_10386:120:ZR:0_11947:790:F6:0_13230:230:R9:0_",
                      ruleid:
                        "jiWbshowbC+aktGmQ5fVY1ynIfJ/XB/vnt5lWewxSALdEVWgu3w8400pbatJN8v+dXRVoM0HZ8U9jxms9hE/Ws/RUwZbLq3+/lDxX34TkEkpnt2f57bucsArrJ1QaV3YnFFh9Gsw/dpcQuWfjFtRfA==",
                      hsc: "c23a2494f34f9d59e523964da1d8a6dea748001403bd466f3942cfef0e6b7391",
                      sup_fare: "14455_25981.5_14005",
                      cabb: "Free",
                      bagage_info: "30kg BAG INCLUDED IN FARE",
                      chekbcode: "BAGL",
                      psup_fare: "14455_25981.5",
                    },
                  ],
                  uniqueId: "VALUE",
                  routeId: "np9peyg11j",
                  servicefee: [],
                  selected: true,
                },
              ],
              tktair: "FZ",
              tecst: "no",
              cl: "Economy",
              extra: 13915297,
              token:
                "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiYXBpdGF3ZmVlcSIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL2dpdmVubmFtZSI6IkFQSSBUQVdGRUVRIiwiZ3JvdXBBY2Nlc3MiOiIzLDM2LDExMiIsImlzQXB0Q2xudCI6IkZhbHNlIiwibG9jYXRpb24iOiI5OTk5IiwiY2hrTGNuIjoiIiwicmVzVG9rZW4iOiJkZWRhZDdkeWY4N2FuOTc0dGJxYjY0YmJ6ZjZlOTYwYzA5ZTE5NmRhZTg2MCIsImNsaWVudElQIjoiMTcyLjE5LjIzLjU0IiwicmVzQ2hhbm5lbElEIjoiNSIsInJlc0NoYW5uZWxOYW1lIjoiVFBBUEkiLCJkZWZhdWx0VXNlck5hbWUiOiJhcGl0YXdmZWVxIiwibmJmIjoxNjg1NTE1MjM3LCJleHAiOjE2ODU1MTc2MzcsImlzcyI6ImZseWR1YmFpLmNvbSIsImF1ZCI6IlRBV0ZFRVFUUlZMU19GWl9OUCJ9.rAW3YT-bX_-yADfvg3QkuPAbOJhkhRUN6HwoS_6PyiM",
              stop: 1,
            },
          ],
          servicefee: [],
        },
        {
          srcorigns: [
            {
              tid: "uzbDKH3HqC8KmWwS_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "09:30",
              re: "Refundable Before Departure",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "WY",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "MCT",
                  dd: "2023-06-07T10:20:00",
                  ad: "2023-06-07T12:20:00",
                  rbd: "L",
                  fn: "236",
                  eq: "7M8",
                  ac: "WY",
                  ma: "WY",
                  seat: "3",
                  oa: "WY",
                  fa: [],
                  fdur: "03:30",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
                {
                  ref: 2,
                  da: "MCT",
                  ar: "DOH",
                  dd: "2023-06-07T16:45:00",
                  ad: "2023-06-07T17:20:00",
                  rbd: "L",
                  fn: "663",
                  eq: "7M8",
                  ac: "WY",
                  ma: "WY",
                  seat: "3",
                  oa: "WY",
                  fa: [],
                  fdur: "01:35",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
              ],
              flightNumbers: "236/663",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          destorigins: [
            {
              tid: "uzbDKH3HqC8KmWwS_2",
              ref: 2,
              office_id: "DOHQR28IH",
              sdur: "09:40",
              re: "Refundable Before Departure",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "WY",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "MCT",
                  dd: "2023-06-09T19:00:00",
                  ad: "2023-06-09T21:35:00",
                  rbd: "L",
                  fn: "664",
                  eq: "7M8",
                  ac: "WY",
                  ma: "WY",
                  seat: "3",
                  oa: "WY",
                  fa: [],
                  fdur: "01:35",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
                {
                  ref: 2,
                  da: "MCT",
                  ar: "HYD",
                  dd: "2023-06-10T02:15:00",
                  ad: "2023-06-10T07:10:00",
                  rbd: "L",
                  fn: "235",
                  eq: "738",
                  ac: "WY",
                  ma: "WY",
                  seat: "3",
                  oa: "WY",
                  fa: [],
                  fdur: "03:25",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
              ],
              flightNumbers: "664/235",
              bgd: [{ qun: "30", wt: "kg" }],
            },
            {
              tid: "CDAQupf9ptM32SlF_2",
              ref: 2,
              office_id: "DOHQR28IH",
              sdur: "17:05",
              re: "Refundable Before Departure",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "WY",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "MCT",
                  dd: "2023-06-09T19:00:00",
                  ad: "2023-06-09T21:35:00",
                  rbd: "L",
                  fn: "664",
                  eq: "7M8",
                  ac: "WY",
                  ma: "WY",
                  seat: "3",
                  oa: "WY",
                  fa: [],
                  fdur: "01:35",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
                {
                  ref: 2,
                  da: "MCT",
                  ar: "HYD",
                  dd: "2023-06-10T09:40:00",
                  ad: "2023-06-10T14:35:00",
                  rbd: "L",
                  fn: "231",
                  eq: "738",
                  ac: "WY",
                  ma: "WY",
                  seat: "3",
                  oa: "WY",
                  fa: [],
                  fdur: "03:25",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
              ],
              flightNumbers: "664/231",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          pr: {
            bf: 34988.8,
            tf: 55596.8,
            tax: [
              { YQ: 7168 },
              { YR: 896 },
              { OT: 12544 },
              { dealmkp: 0 },
              { attmkp: 3180.8 },
              { tamkp: 0 },
              { stamkp: 0 },
              { attdisc: 0 },
              { tadisc: 0 },
              { stadisc: 0 },
            ],
            scur: "QAR",
            ucur: "INR",
            exchange_rate: 22.4,
            sup_fare: "1420_2340",
            markupnames: [{ code: "MTA", name: "LaxmiAprilMarkup" }],
          },
          paxpr: [
            {
              tf: 55596.8,
              tax: [
                { YQ: 7168 },
                { YR: 896 },
                { OT: 12544 },
                { attmkp: 3180.8 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              bf: 34988.8,
              fbc: "LEL6IA",
              fareType: "RP",
              pusup_fare: "1420_2340",
              psup_fare: "31808_52416",
              ptype: "ADT",
            },
          ],
          sup: "0",
          airlinesList: "WY",
          servicefee: [
            {
              type: "ATT",
              adt: 3498.88,
              chd: 0,
              inf: 0,
              servicefee_name: "LaxmiServieApril",
              apl_on: "Per Ticket",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 3498.88,
              sup_fee: 0,
            },
            {
              type: "TA",
              adt: 0,
              chd: 0,
              inf: 0,
              apl_on: "",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 0,
              sup_fee: 3498.88,
            },
          ],
        },
        {
          srcorigns: [
            {
              tid: "pHhfuh9geH86usHG_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "15:40",
              re: "Refundable Before Departure",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "WY",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "MCT",
                  dd: "2023-06-07T10:20:00",
                  ad: "2023-06-07T12:20:00",
                  rbd: "L",
                  fn: "236",
                  eq: "7M8",
                  ac: "WY",
                  ma: "WY",
                  seat: "1",
                  oa: "WY",
                  fa: [],
                  fdur: "03:30",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
                {
                  ref: 2,
                  da: "MCT",
                  ar: "DOH",
                  dd: "2023-06-07T22:55:00",
                  ad: "2023-06-07T23:30:00",
                  rbd: "L",
                  fn: "661",
                  eq: "7M8",
                  ac: "WY",
                  ma: "WY",
                  seat: "1",
                  oa: "WY",
                  fa: [],
                  fdur: "01:35",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
              ],
              flightNumbers: "236/661",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          destorigins: [
            {
              tid: "pHhfuh9geH86usHG_2",
              ref: 2,
              office_id: "DOHQR28IH",
              sdur: "09:40",
              re: "Refundable Before Departure",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "WY",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "MCT",
                  dd: "2023-06-09T19:00:00",
                  ad: "2023-06-09T21:35:00",
                  rbd: "L",
                  fn: "664",
                  eq: "7M8",
                  ac: "WY",
                  ma: "WY",
                  seat: "3",
                  oa: "WY",
                  fa: [],
                  fdur: "01:35",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
                {
                  ref: 2,
                  da: "MCT",
                  ar: "HYD",
                  dd: "2023-06-10T02:15:00",
                  ad: "2023-06-10T07:10:00",
                  rbd: "L",
                  fn: "235",
                  eq: "738",
                  ac: "WY",
                  ma: "WY",
                  seat: "3",
                  oa: "WY",
                  fa: [],
                  fdur: "03:25",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
              ],
              flightNumbers: "664/235",
              bgd: [{ qun: "30", wt: "kg" }],
            },
            {
              tid: "8ePMw9CQ7cA8mI0T_2",
              ref: 2,
              office_id: "DOHQR28IH",
              sdur: "17:05",
              re: "Refundable Before Departure",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "WY",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "MCT",
                  dd: "2023-06-09T19:00:00",
                  ad: "2023-06-09T21:35:00",
                  rbd: "L",
                  fn: "664",
                  eq: "7M8",
                  ac: "WY",
                  ma: "WY",
                  seat: "3",
                  oa: "WY",
                  fa: [],
                  fdur: "01:35",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
                {
                  ref: 2,
                  da: "MCT",
                  ar: "HYD",
                  dd: "2023-06-10T09:40:00",
                  ad: "2023-06-10T14:35:00",
                  rbd: "L",
                  fn: "231",
                  eq: "738",
                  ac: "WY",
                  ma: "WY",
                  seat: "3",
                  oa: "WY",
                  fa: [],
                  fdur: "03:25",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
              ],
              flightNumbers: "664/231",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          pr: {
            bf: 34988.8,
            tf: 55596.8,
            tax: [
              { YQ: 7168 },
              { YR: 896 },
              { OT: 12544 },
              { dealmkp: 0 },
              { attmkp: 3180.8 },
              { tamkp: 0 },
              { stamkp: 0 },
              { attdisc: 0 },
              { tadisc: 0 },
              { stadisc: 0 },
            ],
            scur: "QAR",
            ucur: "INR",
            exchange_rate: 22.4,
            sup_fare: "1420_2340",
            markupnames: [{ code: "MTA", name: "LaxmiAprilMarkup" }],
          },
          paxpr: [
            {
              tf: 55596.8,
              tax: [
                { YQ: 7168 },
                { YR: 896 },
                { OT: 12544 },
                { attmkp: 3180.8 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              bf: 34988.8,
              fbc: "LEL6IA",
              fareType: "RP",
              pusup_fare: "1420_2340",
              psup_fare: "31808_52416",
              ptype: "ADT",
            },
          ],
          sup: "0",
          airlinesList: "WY",
          servicefee: [
            {
              type: "ATT",
              adt: 3498.88,
              chd: 0,
              inf: 0,
              servicefee_name: "LaxmiServieApril",
              apl_on: "Per Ticket",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 3498.88,
              sup_fee: 0,
            },
            {
              type: "TA",
              adt: 0,
              chd: 0,
              inf: 0,
              apl_on: "",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 0,
              sup_fee: 3498.88,
            },
          ],
        },
        {
          srcorigns: [
            {
              tid: "ltAi3Sm2rT5pNCHG_1",
              ref: 1,
              office_id: "",
              sdur: "8:25",
              re: "",
              sup: "5",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DXB",
                  dd: "2023-06-07T03:00:00",
                  ad: "2023-06-07T05:30:00",
                  rbd: "",
                  fn: "436",
                  trn: "",
                  eq: "73B",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "", arr: "2" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161457,
                  flightTime: 240,
                  fdur: "4:0",
                },
                {
                  ref: 2,
                  da: "DXB",
                  ar: "DOH",
                  dd: "2023-06-07T08:45:00",
                  ad: "2023-06-07T08:55:00",
                  rbd: "",
                  fn: "001",
                  trn: "",
                  eq: "73X",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "2", arr: "1" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161405,
                  flightTime: 70,
                  fdur: "1:10",
                },
              ],
              fareop: [
                {
                  fareKey: [],
                  pr: {
                    bf: 19985,
                    tf: 31595,
                    tax: [
                      { OT: 11610 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    scur: "INR",
                    ucur: "INR",
                    exchange_rate: 1,
                    sup_fare: "19985_31595",
                    sup_cur_fare: "",
                    cabb: "Free",
                    chekb: "7kg INCLUDED",
                    meals: "Standard meal",
                    chanb: "Chargeable",
                    ftyp: "FLEX",
                    extra: "13_FLEX",
                    chekbcode: "BAGL",
                    chanb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Change of Flight is Free,NOSHOWUPTO 720 hrs of departure Change of Flight is Free,NOSHOWAFTER From 720 hrs before  of departure Change of Flight is Non-Refundable",
                    canb: "Chargeable",
                    cabb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Cancellation of Flight is Free,NOSHOWUPTO 720 hrs of departure Cancellation of Flight is Free,NOSHOWAFTER From 720 hrs before  of departure Cancellation of Flight is Non-Refundable",
                    bagage_info: "30kg BAG INCLUDED IN FARE",
                    markupnames: [],
                    ttf: 31595,
                  },
                  paxpr: [
                    {
                      bf: 19985,
                      tf: 31595,
                      tax: [
                        { OT: 11610 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "ORML8IN5",
                      ptype: "ADT",
                      scur: "INR",
                      extra:
                        "1_17_O_1_9_0_tx-2186:170:PZ:0_4246:6450:YQ:0_8947:0:BAGL:0_9327:1305:K3:0_10386:120:ZR:0_11947:790:F6:0_12006:1170:P2:0_12434:1605:IN:0_",
                      ruleid:
                        "dlA2ZevswHsVu35AqBqmRBZ+CHIn9HNL+12RdyPuUypN3Vrd+8Of0oA7a4N7g8CvZItP8LZIcrAqzBeCOoIuvjLwhemGenh8Kvds7rAxHg77sBOV2+bcsWuISEqTM1NI",
                      hsc: "525d89c0ec4f0be785e9ac1d7ee2203e320736aab1ee473c7b82d5128bd1c606",
                      sup_fare: "19985_31595_19535",
                      cabb: "Free",
                      bagage_info: "30kg BAG INCLUDED IN FARE",
                      chekbcode: "BAGL",
                      psup_fare: "19985_31595",
                    },
                  ],
                  uniqueId: "FLEX",
                  routeId: "d8592oellv",
                  servicefee: [],
                  selected: true,
                },
              ],
              tktair: "FZ",
              tecst: "no",
              cl: "Economy",
              extra: 13919293,
              token:
                "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiYXBpdGF3ZmVlcSIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL2dpdmVubmFtZSI6IkFQSSBUQVdGRUVRIiwiZ3JvdXBBY2Nlc3MiOiIzLDM2LDExMiIsImlzQXB0Q2xudCI6IkZhbHNlIiwibG9jYXRpb24iOiI5OTk5IiwiY2hrTGNuIjoiIiwicmVzVG9rZW4iOiJkZWRhZDdkeWY4N2FuOTc0dGJxYjY0YmJ6ZjZlOTYwYzA5ZTE5NmRhZTg2MCIsImNsaWVudElQIjoiMTcyLjE5LjIzLjU0IiwicmVzQ2hhbm5lbElEIjoiNSIsInJlc0NoYW5uZWxOYW1lIjoiVFBBUEkiLCJkZWZhdWx0VXNlck5hbWUiOiJhcGl0YXdmZWVxIiwibmJmIjoxNjg1NTE1MjM3LCJleHAiOjE2ODU1MTc2MzcsImlzcyI6ImZseWR1YmFpLmNvbSIsImF1ZCI6IlRBV0ZFRVFUUlZMU19GWl9OUCJ9.rAW3YT-bX_-yADfvg3QkuPAbOJhkhRUN6HwoS_6PyiM",
              stop: 1,
            },
            {
              tid: "GTGEKhpvMiOeSrT7_1",
              ref: 1,
              office_id: "",
              sdur: "15:25",
              re: "",
              sup: "5",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DXB",
                  dd: "2023-06-07T03:00:00",
                  ad: "2023-06-07T05:30:00",
                  rbd: "",
                  fn: "436",
                  trn: "",
                  eq: "73B",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "", arr: "2" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161457,
                  flightTime: 240,
                  fdur: "4:0",
                },
                {
                  ref: 2,
                  da: "DXB",
                  ar: "DOH",
                  dd: "2023-06-07T15:45:00",
                  ad: "2023-06-07T15:55:00",
                  rbd: "",
                  fn: "017",
                  trn: "",
                  eq: "73X",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "2", arr: "1" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161400,
                  flightTime: 70,
                  fdur: "1:10",
                },
              ],
              fareop: [
                {
                  fareKey: [],
                  pr: {
                    bf: 19985,
                    tf: 31595,
                    tax: [
                      { OT: 11610 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    scur: "INR",
                    ucur: "INR",
                    exchange_rate: 1,
                    sup_fare: "19985_31595",
                    sup_cur_fare: "",
                    cabb: "Free",
                    chekb: "7kg INCLUDED",
                    meals: "Standard meal",
                    chanb: "Chargeable",
                    ftyp: "FLEX",
                    extra: "13_FLEX",
                    chekbcode: "BAGL",
                    chanb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Change of Flight is Free,NOSHOWUPTO 720 hrs of departure Change of Flight is Free,NOSHOWAFTER From 720 hrs before  of departure Change of Flight is Non-Refundable",
                    canb: "Chargeable",
                    cabb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Cancellation of Flight is Free,NOSHOWUPTO 720 hrs of departure Cancellation of Flight is Free,NOSHOWAFTER From 720 hrs before  of departure Cancellation of Flight is Non-Refundable",
                    bagage_info: "30kg BAG INCLUDED IN FARE",
                    markupnames: [],
                    ttf: 31595,
                  },
                  paxpr: [
                    {
                      bf: 19985,
                      tf: 31595,
                      tax: [
                        { OT: 11610 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "ORML8IN5",
                      ptype: "ADT",
                      scur: "INR",
                      extra:
                        "1_81_O_1_9_0_tx-2186:170:PZ:0_4246:6450:YQ:0_8947:0:BAGL:0_9327:1305:K3:0_10386:120:ZR:0_11947:790:F6:0_12006:1170:P2:0_12434:1605:IN:0_",
                      ruleid:
                        "ZHcEwMmzG/ncGS/WCnj9dBZ+CHIn9HNL+12RdyPuUypN3Vrd+8Of0oA7a4N7g8CvZItP8LZIcrAqzBeCOoIuvjLwhemGenh8Kvds7rAxHg77sBOV2+bcsWuISEqTM1NI",
                      hsc: "f4cc659876c9ff2406ad84b995e4f26a75a02433b97d57cff6dbaee7c9d70e1e",
                      sup_fare: "19985_31595_19535",
                      cabb: "Free",
                      bagage_info: "30kg BAG INCLUDED IN FARE",
                      chekbcode: "BAGL",
                      psup_fare: "19985_31595",
                    },
                  ],
                  uniqueId: "FLEX",
                  routeId: "7nmin1vvg5",
                  servicefee: [],
                  selected: true,
                },
              ],
              tktair: "FZ",
              tecst: "no",
              cl: "Economy",
              extra: 13919296,
              token:
                "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiYXBpdGF3ZmVlcSIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL2dpdmVubmFtZSI6IkFQSSBUQVdGRUVRIiwiZ3JvdXBBY2Nlc3MiOiIzLDM2LDExMiIsImlzQXB0Q2xudCI6IkZhbHNlIiwibG9jYXRpb24iOiI5OTk5IiwiY2hrTGNuIjoiIiwicmVzVG9rZW4iOiJkZWRhZDdkeWY4N2FuOTc0dGJxYjY0YmJ6ZjZlOTYwYzA5ZTE5NmRhZTg2MCIsImNsaWVudElQIjoiMTcyLjE5LjIzLjU0IiwicmVzQ2hhbm5lbElEIjoiNSIsInJlc0NoYW5uZWxOYW1lIjoiVFBBUEkiLCJkZWZhdWx0VXNlck5hbWUiOiJhcGl0YXdmZWVxIiwibmJmIjoxNjg1NTE1MjM3LCJleHAiOjE2ODU1MTc2MzcsImlzcyI6ImZseWR1YmFpLmNvbSIsImF1ZCI6IlRBV0ZFRVFUUlZMU19GWl9OUCJ9.rAW3YT-bX_-yADfvg3QkuPAbOJhkhRUN6HwoS_6PyiM",
              stop: 1,
            },
            {
              tid: "RVqQk2W0meLWi3tV_1",
              ref: 1,
              office_id: "",
              sdur: "19:25",
              re: "",
              sup: "5",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DXB",
                  dd: "2023-06-07T03:00:00",
                  ad: "2023-06-07T05:30:00",
                  rbd: "",
                  fn: "436",
                  trn: "",
                  eq: "73B",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "", arr: "2" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161457,
                  flightTime: 240,
                  fdur: "4:0",
                },
                {
                  ref: 2,
                  da: "DXB",
                  ar: "DOH",
                  dd: "2023-06-07T19:45:00",
                  ad: "2023-06-07T19:55:00",
                  rbd: "",
                  fn: "005",
                  trn: "",
                  eq: "73X",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "2", arr: "1" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161381,
                  flightTime: 70,
                  fdur: "1:10",
                },
              ],
              fareop: [
                {
                  fareKey: [],
                  pr: {
                    bf: 19985,
                    tf: 31595,
                    tax: [
                      { OT: 11610 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    scur: "INR",
                    ucur: "INR",
                    exchange_rate: 1,
                    sup_fare: "19985_31595",
                    sup_cur_fare: "",
                    cabb: "Free",
                    chekb: "7kg INCLUDED",
                    meals: "Standard meal",
                    chanb: "Chargeable",
                    ftyp: "FLEX",
                    extra: "13_FLEX",
                    chekbcode: "BAGL",
                    chanb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Change of Flight is Free,NOSHOWUPTO 720 hrs of departure Change of Flight is Free,NOSHOWAFTER From 720 hrs before  of departure Change of Flight is Non-Refundable",
                    canb: "Chargeable",
                    cabb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Cancellation of Flight is Free,NOSHOWUPTO 720 hrs of departure Cancellation of Flight is Free,NOSHOWAFTER From 720 hrs before  of departure Cancellation of Flight is Non-Refundable",
                    bagage_info: "30kg BAG INCLUDED IN FARE",
                    markupnames: [],
                    ttf: 31595,
                  },
                  paxpr: [
                    {
                      bf: 19985,
                      tf: 31595,
                      tax: [
                        { OT: 11610 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "ORML8IN5",
                      ptype: "ADT",
                      scur: "INR",
                      extra:
                        "1_113_O_1_9_0_tx-2186:170:PZ:0_4246:6450:YQ:0_8947:0:BAGL:0_9327:1305:K3:0_10386:120:ZR:0_11947:790:F6:0_12006:1170:P2:0_12434:1605:IN:0_",
                      ruleid:
                        "JC7XHeA484+RqBNnS3uUoCH5cdRtjzMqmtc6Ydt53ymhQozXlONozKDeF7aO/DbGAO8K6tXzJ16Rfs5fUX41PoAzc0DAexpKMcT6yt4vWi5QaY31vw17VrswRPIWnfWdBQGHoM3lqYcsurCRq3PlUw==",
                      hsc: "3e67af758fc5e74e5ec6f1e4e65c0627f609aa3e0c5fe510f814de0248a33810",
                      sup_fare: "19985_31595_19535",
                      cabb: "Free",
                      bagage_info: "30kg BAG INCLUDED IN FARE",
                      chekbcode: "BAGL",
                      psup_fare: "19985_31595",
                    },
                  ],
                  uniqueId: "FLEX",
                  routeId: "t9exr0rc46",
                  servicefee: [],
                  selected: true,
                },
              ],
              tktair: "FZ",
              tecst: "no",
              cl: "Economy",
              extra: 13919294,
              token:
                "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiYXBpdGF3ZmVlcSIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL2dpdmVubmFtZSI6IkFQSSBUQVdGRUVRIiwiZ3JvdXBBY2Nlc3MiOiIzLDM2LDExMiIsImlzQXB0Q2xudCI6IkZhbHNlIiwibG9jYXRpb24iOiI5OTk5IiwiY2hrTGNuIjoiIiwicmVzVG9rZW4iOiJkZWRhZDdkeWY4N2FuOTc0dGJxYjY0YmJ6ZjZlOTYwYzA5ZTE5NmRhZTg2MCIsImNsaWVudElQIjoiMTcyLjE5LjIzLjU0IiwicmVzQ2hhbm5lbElEIjoiNSIsInJlc0NoYW5uZWxOYW1lIjoiVFBBUEkiLCJkZWZhdWx0VXNlck5hbWUiOiJhcGl0YXdmZWVxIiwibmJmIjoxNjg1NTE1MjM3LCJleHAiOjE2ODU1MTc2MzcsImlzcyI6ImZseWR1YmFpLmNvbSIsImF1ZCI6IlRBV0ZFRVFUUlZMU19GWl9OUCJ9.rAW3YT-bX_-yADfvg3QkuPAbOJhkhRUN6HwoS_6PyiM",
              stop: 1,
            },
          ],
          paxpr: [
            {
              bf: 39970,
              tf: 63385,
              tax: [
                { OT: 23415 },
                { attmkp: 0 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              fbc: "ORML8IN5",
              ptype: "ADT",
              scur: "INR",
              extra:
                "1_17_O_1_9_0_tx-2186:170:PZ:0_4246:6450:YQ:0_8947:0:BAGL:0_9327:1305:K3:0_10386:120:ZR:0_11947:790:F6:0_12006:1170:P2:0_12434:1605:IN:0_",
              ruleid:
                "dlA2ZevswHsVu35AqBqmRBZ+CHIn9HNL+12RdyPuUypN3Vrd+8Of0oA7a4N7g8CvZItP8LZIcrAqzBeCOoIuvjLwhemGenh8Kvds7rAxHg77sBOV2+bcsWuISEqTM1NI",
              hsc: "525d89c0ec4f0be785e9ac1d7ee2203e320736aab1ee473c7b82d5128bd1c606",
              sup_fare: "19985_31595_19535",
              cabb: "Free",
              bagage_info: "30kg BAG INCLUDED IN FARE",
              chekbcode: "BAGL",
              psup_fare: "39970_63385",
            },
          ],
          pr: {
            bf: 39970,
            tf: 63385,
            tax: [
              { OT: 23415 },
              { attmkp: 0 },
              { tamkp: 0 },
              { stamkp: 0 },
              { attdisc: 0 },
              { tadisc: 0 },
              { stadisc: 0 },
            ],
            scur: "INR",
            ucur: "INR",
            exchange_rate: 1,
            sup_fare: "39970_63385",
            sup_cur_fare: "",
            cabb: "Free",
            chekb: "7kg INCLUDED",
            meals: "Standard meal",
            chanb: "Chargeable",
            ftyp: "FLEX",
            extra: "13_FLEX",
            chekbcode: "BAGL",
            chanb_info:
              "BEFOREDEPARTURE 0 hrs of departure Change of Flight is Free,NOSHOWUPTO 720 hrs of departure Change of Flight is Free,NOSHOWAFTER From 720 hrs before  of departure Change of Flight is Non-Refundable",
            canb: "Chargeable",
            cabb_info:
              "BEFOREDEPARTURE 0 hrs of departure Cancellation of Flight is Free,NOSHOWUPTO 720 hrs of departure Cancellation of Flight is Free,NOSHOWAFTER From 720 hrs before  of departure Cancellation of Flight is Non-Refundable",
            bagage_info: "30kg BAG INCLUDED IN FARE",
            markupnames: [],
            ttf: 63385,
          },
          sup: "5",
          airlineList: "FZ",
          destorigins: [
            {
              tid: "a1zrVocb7fET14vf_2",
              ref: 2,
              office_id: "",
              sdur: "6:45",
              re: "",
              sup: "5",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DXB",
                  dd: "2023-06-09T17:00:00",
                  ad: "2023-06-09T19:15:00",
                  rbd: "",
                  fn: "018",
                  trn: "",
                  eq: "73X",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "1", arr: "2" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161399,
                  flightTime: 75,
                  fdur: "1:15",
                },
                {
                  ref: 2,
                  da: "DXB",
                  ar: "HYD",
                  dd: "2023-06-09T20:50:00",
                  ad: "2023-06-10T02:15:00",
                  rbd: "",
                  fn: "435",
                  trn: "",
                  eq: "73B",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "2", arr: "" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161456,
                  flightTime: 235,
                  fdur: "3:55",
                },
              ],
              fareop: [
                {
                  fareKey: [],
                  pr: {
                    bf: 19985,
                    tf: 31790,
                    tax: [
                      { OT: 11805 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    scur: "INR",
                    ucur: "INR",
                    exchange_rate: 1,
                    sup_fare: "19985_31790",
                    sup_cur_fare: "",
                    cabb: "Free",
                    chekb: "7kg INCLUDED",
                    meals: "Standard meal",
                    chanb: "Chargeable",
                    ftyp: "FLEX",
                    extra: "13_FLEX",
                    chekbcode: "BAGL",
                    chanb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Change of Flight is Free,NOSHOWUPTO 720 hrs of departure Change of Flight is Free,NOSHOWAFTER From 720 hrs before  of departure Change of Flight is Non-Refundable",
                    canb: "Chargeable",
                    cabb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Cancellation of Flight is Free,NOSHOWUPTO 720 hrs of departure Cancellation of Flight is Free,NOSHOWAFTER From 720 hrs before  of departure Cancellation of Flight is Non-Refundable",
                    bagage_info: "30kg BAG INCLUDED IN FARE",
                    markupnames: [],
                    ttf: 31790,
                  },
                  paxpr: [
                    {
                      bf: 19985,
                      tf: 31790,
                      tax: [
                        { OT: 11805 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "ORML8IN5",
                      ptype: "ADT",
                      scur: "INR",
                      extra:
                        "1_18_O_1_9_0_tx-1764:1370:QA:0_2186:170:PZ:0_4246:6450:YQ:0_8947:0:BAGL:0_8966:1370:G4:0_9327:1305:K3:0_10386:120:ZR:0_11947:790:F6:0_13230:230:R9:0_",
                      ruleid:
                        "Qd7sjjitfSt5Gh7Km+ehBlynIfJ/XB/vnt5lWewxSALdEVWgu3w8400pbatJN8v+dXRVoM0HZ8U9jxms9hE/Ws/RUwZbLq3+/lDxX34TkEnJ9351zGkK7QqSS78PWRvgnFFh9Gsw/dpcQuWfjFtRfA==",
                      hsc: "8934c2a32703e0a1d2b46cd7f8bee026e74e2430344d7c13f9995b0a831fe5da",
                      sup_fare: "19985_31790_19535",
                      cabb: "Free",
                      bagage_info: "30kg BAG INCLUDED IN FARE",
                      chekbcode: "BAGL",
                      psup_fare: "19985_31790",
                    },
                  ],
                  uniqueId: "FLEX",
                  routeId: "j2o1zyg8l2",
                  servicefee: [],
                  selected: true,
                },
              ],
              tktair: "FZ",
              tecst: "no",
              cl: "Economy",
              extra: 13916393,
              token:
                "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiYXBpdGF3ZmVlcSIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL2dpdmVubmFtZSI6IkFQSSBUQVdGRUVRIiwiZ3JvdXBBY2Nlc3MiOiIzLDM2LDExMiIsImlzQXB0Q2xudCI6IkZhbHNlIiwibG9jYXRpb24iOiI5OTk5IiwiY2hrTGNuIjoiIiwicmVzVG9rZW4iOiJkZWRhZDdkeWY4N2FuOTc0dGJxYjY0YmJ6ZjZlOTYwYzA5ZTE5NmRhZTg2MCIsImNsaWVudElQIjoiMTcyLjE5LjIzLjU0IiwicmVzQ2hhbm5lbElEIjoiNSIsInJlc0NoYW5uZWxOYW1lIjoiVFBBUEkiLCJkZWZhdWx0VXNlck5hbWUiOiJhcGl0YXdmZWVxIiwibmJmIjoxNjg1NTE1MjM3LCJleHAiOjE2ODU1MTc2MzcsImlzcyI6ImZseWR1YmFpLmNvbSIsImF1ZCI6IlRBV0ZFRVFUUlZMU19GWl9OUCJ9.rAW3YT-bX_-yADfvg3QkuPAbOJhkhRUN6HwoS_6PyiM",
              stop: 1,
            },
            {
              tid: "vrTBmHUsTzyFiAbw_2",
              ref: 2,
              office_id: "",
              sdur: "13:45",
              re: "",
              sup: "5",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DXB",
                  dd: "2023-06-09T10:00:00",
                  ad: "2023-06-09T12:15:00",
                  rbd: "",
                  fn: "002",
                  trn: "",
                  eq: "73X",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "1", arr: "2" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161402,
                  flightTime: 75,
                  fdur: "1:15",
                },
                {
                  ref: 2,
                  da: "DXB",
                  ar: "HYD",
                  dd: "2023-06-09T20:50:00",
                  ad: "2023-06-10T02:15:00",
                  rbd: "",
                  fn: "435",
                  trn: "",
                  eq: "73B",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "2", arr: "" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161456,
                  flightTime: 235,
                  fdur: "3:55",
                },
              ],
              fareop: [
                {
                  fareKey: [],
                  pr: {
                    bf: 19985,
                    tf: 31790,
                    tax: [
                      { OT: 11805 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    scur: "INR",
                    ucur: "INR",
                    exchange_rate: 1,
                    sup_fare: "19985_31790",
                    sup_cur_fare: "",
                    cabb: "Free",
                    chekb: "7kg INCLUDED",
                    meals: "Standard meal",
                    chanb: "Chargeable",
                    ftyp: "FLEX",
                    extra: "13_FLEX",
                    chekbcode: "BAGL",
                    chanb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Change of Flight is Free,NOSHOWUPTO 720 hrs of departure Change of Flight is Free,NOSHOWAFTER From 720 hrs before  of departure Change of Flight is Non-Refundable",
                    canb: "Chargeable",
                    cabb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Cancellation of Flight is Free,NOSHOWUPTO 720 hrs of departure Cancellation of Flight is Free,NOSHOWAFTER From 720 hrs before  of departure Cancellation of Flight is Non-Refundable",
                    bagage_info: "30kg BAG INCLUDED IN FARE",
                    markupnames: [],
                    ttf: 31790,
                  },
                  paxpr: [
                    {
                      bf: 19985,
                      tf: 31790,
                      tax: [
                        { OT: 11805 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "ORML8IN5",
                      ptype: "ADT",
                      scur: "INR",
                      extra:
                        "1_50_O_1_9_0_tx-1764:1370:QA:0_2186:170:PZ:0_4246:6450:YQ:0_8947:0:BAGL:0_8966:1370:G4:0_9327:1305:K3:0_10386:120:ZR:0_11947:790:F6:0_13230:230:R9:0_",
                      ruleid:
                        "lfu7QoacsPpGA0ypedTnfVynIfJ/XB/vnt5lWewxSALdEVWgu3w8400pbatJN8v+dXRVoM0HZ8U9jxms9hE/Ws/RUwZbLq3+/lDxX34TkEnJ9351zGkK7QqSS78PWRvgnFFh9Gsw/dpcQuWfjFtRfA==",
                      hsc: "776b23cf0a2f0450d468a536af60ed36dd571e9aa680eb3719ccebc233075507",
                      sup_fare: "19985_31790_19535",
                      cabb: "Free",
                      bagage_info: "30kg BAG INCLUDED IN FARE",
                      chekbcode: "BAGL",
                      psup_fare: "19985_31790",
                    },
                  ],
                  uniqueId: "FLEX",
                  routeId: "6idbvprjme",
                  servicefee: [],
                  selected: true,
                },
              ],
              tktair: "FZ",
              tecst: "no",
              cl: "Economy",
              extra: 13915297,
              token:
                "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiYXBpdGF3ZmVlcSIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL2dpdmVubmFtZSI6IkFQSSBUQVdGRUVRIiwiZ3JvdXBBY2Nlc3MiOiIzLDM2LDExMiIsImlzQXB0Q2xudCI6IkZhbHNlIiwibG9jYXRpb24iOiI5OTk5IiwiY2hrTGNuIjoiIiwicmVzVG9rZW4iOiJkZWRhZDdkeWY4N2FuOTc0dGJxYjY0YmJ6ZjZlOTYwYzA5ZTE5NmRhZTg2MCIsImNsaWVudElQIjoiMTcyLjE5LjIzLjU0IiwicmVzQ2hhbm5lbElEIjoiNSIsInJlc0NoYW5uZWxOYW1lIjoiVFBBUEkiLCJkZWZhdWx0VXNlck5hbWUiOiJhcGl0YXdmZWVxIiwibmJmIjoxNjg1NTE1MjM3LCJleHAiOjE2ODU1MTc2MzcsImlzcyI6ImZseWR1YmFpLmNvbSIsImF1ZCI6IlRBV0ZFRVFUUlZMU19GWl9OUCJ9.rAW3YT-bX_-yADfvg3QkuPAbOJhkhRUN6HwoS_6PyiM",
              stop: 1,
            },
          ],
          servicefee: [],
        },
        {
          srcorigns: [
            {
              tid: "0DzT07XmqlK4Xooi_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "08:25",
              re: "Refundable Before Departure",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "FZ",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DXB",
                  dd: "2023-06-07T03:00:00",
                  ad: "2023-06-07T05:30:00",
                  rbd: "O",
                  fn: "436",
                  eq: "73H",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "9",
                  oa: "FZ",
                  fa: [],
                  fdur: "04:00",
                  cl: "Economy",
                  terminal: { dep: "", arr: "2" },
                },
                {
                  ref: 2,
                  da: "DXB",
                  ar: "DOH",
                  dd: "2023-06-07T08:45:00",
                  ad: "2023-06-07T08:55:00",
                  rbd: "O",
                  fn: "1",
                  trn: "2",
                  eq: "7M8",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "4",
                  oa: "FZ",
                  fa: [],
                  fdur: "01:10",
                  cl: "Economy",
                  terminal: { dep: "2", arr: "" },
                },
              ],
              flightNumbers: "436/1",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          destorigins: [
            {
              tid: "0DzT07XmqlK4Xooi_2",
              ref: 2,
              office_id: "DOHQR28IH",
              sdur: "06:45",
              re: "Refundable Before Departure",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "FZ",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DXB",
                  dd: "2023-06-09T17:00:00",
                  ad: "2023-06-09T19:15:00",
                  rbd: "O",
                  fn: "18",
                  eq: "7M8",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "9",
                  oa: "FZ",
                  fa: [],
                  fdur: "01:15",
                  cl: "Economy",
                  terminal: { dep: "", arr: "2" },
                },
                {
                  ref: 2,
                  da: "DXB",
                  ar: "HYD",
                  dd: "2023-06-09T20:50:00",
                  ad: "2023-06-10T02:15:00",
                  rbd: "O",
                  fn: "435",
                  trn: "2",
                  eq: "73H",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "9",
                  oa: "FZ",
                  fa: [],
                  fdur: "03:55",
                  cl: "Economy",
                  terminal: { dep: "2", arr: "" },
                },
              ],
              flightNumbers: "18/435",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          pr: {
            bf: 34988.8,
            tf: 61196.8,
            tax: [
              { YQ: 12992 },
              { YR: 2016 },
              { OT: 11200 },
              { dealmkp: 0 },
              { attmkp: 3180.8 },
              { tamkp: 0 },
              { stamkp: 0 },
              { attdisc: 0 },
              { tadisc: 0 },
              { stadisc: 0 },
            ],
            scur: "QAR",
            ucur: "INR",
            exchange_rate: 22.4,
            sup_fare: "1420_2590",
            markupnames: [{ code: "MTA", name: "LaxmiAprilMarkup" }],
          },
          paxpr: [
            {
              tf: 61196.8,
              tax: [
                { YQ: 12992 },
                { YR: 2016 },
                { OT: 11200 },
                { attmkp: 3180.8 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              bf: 34988.8,
              fbc: "ORLP7IN1",
              fareType: "RP",
              pusup_fare: "1420_2590",
              psup_fare: "31808_58016",
              ptype: "ADT",
            },
          ],
          sup: "0",
          airlinesList: "FZ",
          servicefee: [
            {
              type: "ATT",
              adt: 3498.88,
              chd: 0,
              inf: 0,
              servicefee_name: "LaxmiServieApril",
              apl_on: "Per Ticket",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 3498.88,
              sup_fee: 0,
            },
            {
              type: "TA",
              adt: 0,
              chd: 0,
              inf: 0,
              apl_on: "",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 0,
              sup_fee: 3498.88,
            },
          ],
        },
        {
          srcorigns: [
            {
              tid: "dTxba1T80aH8PL40_1",
              ref: 1,
              office_id: "",
              sdur: "8:25",
              re: "",
              sup: "5",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DXB",
                  dd: "2023-06-07T03:00:00",
                  ad: "2023-06-07T05:30:00",
                  rbd: "",
                  fn: "436",
                  trn: "",
                  eq: "73B",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "", arr: "2" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161457,
                  flightTime: 240,
                  fdur: "4:0",
                },
                {
                  ref: 2,
                  da: "DXB",
                  ar: "DOH",
                  dd: "2023-06-07T08:45:00",
                  ad: "2023-06-07T08:55:00",
                  rbd: "",
                  fn: "001",
                  trn: "",
                  eq: "73X",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "2", arr: "1" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161405,
                  flightTime: 70,
                  fdur: "1:10",
                },
              ],
              fareop: [
                {
                  fareKey: [],
                  pr: {
                    bf: 14165,
                    tf: 45036.65,
                    tax: [
                      { OT: 30871.65 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    scur: "INR",
                    ucur: "INR",
                    exchange_rate: 1,
                    sup_fare: "14165_45036.65",
                    sup_cur_fare: "",
                    cabb: "Free",
                    chekb: "14kg INCLUDED",
                    meals: "Chargeable",
                    chanb: "Chargeable",
                    ftyp: "Business",
                    extra: "15_Business",
                    chekbcode: "JBAG",
                    chanb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Change of Flight is Free,NOSHOWUPTO 720 hrs of departure Change of Flight is Free,NOSHOWAFTER From 720 hrs before  of departure Change of Flight is Non-Refundable",
                    canb: "Chargeable",
                    cabb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Cancellation of Flight is Free,NOSHOWUPTO 720 hrs of departure Cancellation of Flight is Free,NOSHOWAFTER From 720 hrs before  of departure Cancellation of Flight is Non-Refundable",
                    bagage_info: "40kg Baggage allowance Business",
                    markupnames: [],
                    ttf: 45036.65,
                  },
                  paxpr: [
                    {
                      bf: 14165,
                      tf: 45036.65,
                      tax: [
                        { OT: 30871.65 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "ZRM9IN2",
                      ptype: "ADT",
                      scur: "INR",
                      extra:
                        "1_19_Z_1_5_0_tx-2186:170:PZ:0_4246:22970:YQ:0_6544:0:JBAG:0_9327:4046.65:K3:0_10386:120:ZR:0_11947:790:F6:0_12006:1170:P2:0_12434:1605:IN:0_",
                      ruleid:
                        "dm4Q/oMxDET1eyRMgvUNNTWE439NribcBOUajw99xT+o8LotKkPr9+rRgWI1VO0yvTml2i2hCEBikqdUYpBdQICMSUE+oAXNhfzC1AvPpsdXVy0HLLTzOxy6vo4KsNKyH4iOhL3qCTzeoIR/oeSzY4TEtPO2CThrAiJMBJQHPOZVOijsN/0s5eElrsthvOXj",
                      hsc: "80200a73d3b77870b57b86545c0e733252b43e0d5e5d690be9c85f5d3b0038f6",
                      sup_fare: "14165_45036.65_10685",
                      cabb: "Free",
                      bagage_info: "40kg Baggage allowance Business",
                      chekbcode: "JBAG",
                      psup_fare: "14165_45036.65",
                    },
                  ],
                  uniqueId: "Business",
                  routeId: "7b5vsks9sl",
                  servicefee: [],
                  selected: true,
                },
              ],
              tktair: "FZ",
              tecst: "no",
              cl: "Economy",
              extra: 13919293,
              token:
                "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiYXBpdGF3ZmVlcSIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL2dpdmVubmFtZSI6IkFQSSBUQVdGRUVRIiwiZ3JvdXBBY2Nlc3MiOiIzLDM2LDExMiIsImlzQXB0Q2xudCI6IkZhbHNlIiwibG9jYXRpb24iOiI5OTk5IiwiY2hrTGNuIjoiIiwicmVzVG9rZW4iOiJkZWRhZDdkeWY4N2FuOTc0dGJxYjY0YmJ6ZjZlOTYwYzA5ZTE5NmRhZTg2MCIsImNsaWVudElQIjoiMTcyLjE5LjIzLjU0IiwicmVzQ2hhbm5lbElEIjoiNSIsInJlc0NoYW5uZWxOYW1lIjoiVFBBUEkiLCJkZWZhdWx0VXNlck5hbWUiOiJhcGl0YXdmZWVxIiwibmJmIjoxNjg1NTE1MjM3LCJleHAiOjE2ODU1MTc2MzcsImlzcyI6ImZseWR1YmFpLmNvbSIsImF1ZCI6IlRBV0ZFRVFUUlZMU19GWl9OUCJ9.rAW3YT-bX_-yADfvg3QkuPAbOJhkhRUN6HwoS_6PyiM",
              stop: 1,
            },
            {
              tid: "DOsqgHkCzealXTrX_1",
              ref: 1,
              office_id: "",
              sdur: "15:25",
              re: "",
              sup: "5",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DXB",
                  dd: "2023-06-07T03:00:00",
                  ad: "2023-06-07T05:30:00",
                  rbd: "",
                  fn: "436",
                  trn: "",
                  eq: "73B",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "", arr: "2" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161457,
                  flightTime: 240,
                  fdur: "4:0",
                },
                {
                  ref: 2,
                  da: "DXB",
                  ar: "DOH",
                  dd: "2023-06-07T15:45:00",
                  ad: "2023-06-07T15:55:00",
                  rbd: "",
                  fn: "017",
                  trn: "",
                  eq: "73X",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "2", arr: "1" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161400,
                  flightTime: 70,
                  fdur: "1:10",
                },
              ],
              fareop: [
                {
                  fareKey: [],
                  pr: {
                    bf: 14165,
                    tf: 45036.65,
                    tax: [
                      { OT: 30871.65 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    scur: "INR",
                    ucur: "INR",
                    exchange_rate: 1,
                    sup_fare: "14165_45036.65",
                    sup_cur_fare: "",
                    cabb: "Free",
                    chekb: "14kg INCLUDED",
                    meals: "Chargeable",
                    chanb: "Chargeable",
                    ftyp: "Business",
                    extra: "15_Business",
                    chekbcode: "JBAG",
                    chanb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Change of Flight is Free,NOSHOWUPTO 720 hrs of departure Change of Flight is Free,NOSHOWAFTER From 720 hrs before  of departure Change of Flight is Non-Refundable",
                    canb: "Chargeable",
                    cabb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Cancellation of Flight is Free,NOSHOWUPTO 720 hrs of departure Cancellation of Flight is Free,NOSHOWAFTER From 720 hrs before  of departure Cancellation of Flight is Non-Refundable",
                    bagage_info: "40kg Baggage allowance Business",
                    markupnames: [],
                    ttf: 45036.65,
                  },
                  paxpr: [
                    {
                      bf: 14165,
                      tf: 45036.65,
                      tax: [
                        { OT: 30871.65 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "ZRM9IN2",
                      ptype: "ADT",
                      scur: "INR",
                      extra:
                        "1_83_Z_1_5_0_tx-2186:170:PZ:0_4246:22970:YQ:0_6544:0:JBAG:0_9327:4046.65:K3:0_10386:120:ZR:0_11947:790:F6:0_12006:1170:P2:0_12434:1605:IN:0_",
                      ruleid:
                        "M7jXvL7ey0RZo9AozqJocDWE439NribcBOUajw99xT+o8LotKkPr9+rRgWI1VO0yvTml2i2hCEBikqdUYpBdQICMSUE+oAXNhfzC1AvPpsdXVy0HLLTzOxy6vo4KsNKyH4iOhL3qCTzeoIR/oeSzY4TEtPO2CThrAiJMBJQHPOZVOijsN/0s5eElrsthvOXj",
                      hsc: "ec8c55821c12ff9664fe1bd150850d6ebce65f3dbe8277e6588b5f671883c285",
                      sup_fare: "14165_45036.65_10685",
                      cabb: "Free",
                      bagage_info: "40kg Baggage allowance Business",
                      chekbcode: "JBAG",
                      psup_fare: "14165_45036.65",
                    },
                  ],
                  uniqueId: "Business",
                  routeId: "my19zl0de5",
                  servicefee: [],
                  selected: true,
                },
              ],
              tktair: "FZ",
              tecst: "no",
              cl: "Economy",
              extra: 13919296,
              token:
                "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiYXBpdGF3ZmVlcSIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL2dpdmVubmFtZSI6IkFQSSBUQVdGRUVRIiwiZ3JvdXBBY2Nlc3MiOiIzLDM2LDExMiIsImlzQXB0Q2xudCI6IkZhbHNlIiwibG9jYXRpb24iOiI5OTk5IiwiY2hrTGNuIjoiIiwicmVzVG9rZW4iOiJkZWRhZDdkeWY4N2FuOTc0dGJxYjY0YmJ6ZjZlOTYwYzA5ZTE5NmRhZTg2MCIsImNsaWVudElQIjoiMTcyLjE5LjIzLjU0IiwicmVzQ2hhbm5lbElEIjoiNSIsInJlc0NoYW5uZWxOYW1lIjoiVFBBUEkiLCJkZWZhdWx0VXNlck5hbWUiOiJhcGl0YXdmZWVxIiwibmJmIjoxNjg1NTE1MjM3LCJleHAiOjE2ODU1MTc2MzcsImlzcyI6ImZseWR1YmFpLmNvbSIsImF1ZCI6IlRBV0ZFRVFUUlZMU19GWl9OUCJ9.rAW3YT-bX_-yADfvg3QkuPAbOJhkhRUN6HwoS_6PyiM",
              stop: 1,
            },
            {
              tid: "e9G7AXCARLGGBT7W_1",
              ref: 1,
              office_id: "",
              sdur: "19:25",
              re: "",
              sup: "5",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DXB",
                  dd: "2023-06-07T03:00:00",
                  ad: "2023-06-07T05:30:00",
                  rbd: "",
                  fn: "436",
                  trn: "",
                  eq: "73B",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "", arr: "2" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161457,
                  flightTime: 240,
                  fdur: "4:0",
                },
                {
                  ref: 2,
                  da: "DXB",
                  ar: "DOH",
                  dd: "2023-06-07T19:45:00",
                  ad: "2023-06-07T19:55:00",
                  rbd: "",
                  fn: "005",
                  trn: "",
                  eq: "73X",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "2", arr: "1" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161381,
                  flightTime: 70,
                  fdur: "1:10",
                },
              ],
              fareop: [
                {
                  fareKey: [],
                  pr: {
                    bf: 14165,
                    tf: 45036.65,
                    tax: [
                      { OT: 30871.65 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    scur: "INR",
                    ucur: "INR",
                    exchange_rate: 1,
                    sup_fare: "14165_45036.65",
                    sup_cur_fare: "",
                    cabb: "Free",
                    chekb: "14kg INCLUDED",
                    meals: "Chargeable",
                    chanb: "Chargeable",
                    ftyp: "Business",
                    extra: "15_Business",
                    chekbcode: "JBAG",
                    chanb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Change of Flight is Free,NOSHOWUPTO 720 hrs of departure Change of Flight is Free,NOSHOWAFTER From 720 hrs before  of departure Change of Flight is Non-Refundable",
                    canb: "Chargeable",
                    cabb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Cancellation of Flight is Free,NOSHOWUPTO 720 hrs of departure Cancellation of Flight is Free,NOSHOWAFTER From 720 hrs before  of departure Cancellation of Flight is Non-Refundable",
                    bagage_info: "40kg Baggage allowance Business",
                    markupnames: [],
                    ttf: 45036.65,
                  },
                  paxpr: [
                    {
                      bf: 14165,
                      tf: 45036.65,
                      tax: [
                        { OT: 30871.65 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "ZRM9IN2",
                      ptype: "ADT",
                      scur: "INR",
                      extra:
                        "1_115_Z_1_5_0_tx-2186:170:PZ:0_4246:22970:YQ:0_6544:0:JBAG:0_9327:4046.65:K3:0_10386:120:ZR:0_11947:790:F6:0_12006:1170:P2:0_12434:1605:IN:0_",
                      ruleid:
                        "XszDLxhkN1Kh8WhphuwpoaNXn6aA9MtcY0N9tFO6QOwKxrc4UYGO2IzDMPt/j9KfyZrzxNnMdcOJwgpzMIdukRM1YiBqo5VUngE+f6ZTlrcWhTrLfc+1HjPslNbsK6Ma+NzJ9vs0yTvfIGzHK+7a6QYdbLMNnyuqz+GQYY8X4udk3IvNdcymNm5f/d40hxVF",
                      hsc: "3c791b4a0caab8c75dd703a490589969fedfdd6fdb5aa028da76769c7379f274",
                      sup_fare: "14165_45036.65_10685",
                      cabb: "Free",
                      bagage_info: "40kg Baggage allowance Business",
                      chekbcode: "JBAG",
                      psup_fare: "14165_45036.65",
                    },
                  ],
                  uniqueId: "Business",
                  routeId: "dnq4yish68",
                  servicefee: [],
                  selected: true,
                },
              ],
              tktair: "FZ",
              tecst: "no",
              cl: "Economy",
              extra: 13919294,
              token:
                "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiYXBpdGF3ZmVlcSIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL2dpdmVubmFtZSI6IkFQSSBUQVdGRUVRIiwiZ3JvdXBBY2Nlc3MiOiIzLDM2LDExMiIsImlzQXB0Q2xudCI6IkZhbHNlIiwibG9jYXRpb24iOiI5OTk5IiwiY2hrTGNuIjoiIiwicmVzVG9rZW4iOiJkZWRhZDdkeWY4N2FuOTc0dGJxYjY0YmJ6ZjZlOTYwYzA5ZTE5NmRhZTg2MCIsImNsaWVudElQIjoiMTcyLjE5LjIzLjU0IiwicmVzQ2hhbm5lbElEIjoiNSIsInJlc0NoYW5uZWxOYW1lIjoiVFBBUEkiLCJkZWZhdWx0VXNlck5hbWUiOiJhcGl0YXdmZWVxIiwibmJmIjoxNjg1NTE1MjM3LCJleHAiOjE2ODU1MTc2MzcsImlzcyI6ImZseWR1YmFpLmNvbSIsImF1ZCI6IlRBV0ZFRVFUUlZMU19GWl9OUCJ9.rAW3YT-bX_-yADfvg3QkuPAbOJhkhRUN6HwoS_6PyiM",
              stop: 1,
            },
          ],
          paxpr: [
            {
              bf: 27420,
              tf: 71105,
              tax: [
                { OT: 43685 },
                { attmkp: 0 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              fbc: "ZRM9IN2",
              ptype: "ADT",
              scur: "INR",
              extra:
                "1_19_Z_1_5_0_tx-2186:170:PZ:0_4246:22970:YQ:0_6544:0:JBAG:0_9327:4046.65:K3:0_10386:120:ZR:0_11947:790:F6:0_12006:1170:P2:0_12434:1605:IN:0_",
              ruleid:
                "dm4Q/oMxDET1eyRMgvUNNTWE439NribcBOUajw99xT+o8LotKkPr9+rRgWI1VO0yvTml2i2hCEBikqdUYpBdQICMSUE+oAXNhfzC1AvPpsdXVy0HLLTzOxy6vo4KsNKyH4iOhL3qCTzeoIR/oeSzY4TEtPO2CThrAiJMBJQHPOZVOijsN/0s5eElrsthvOXj",
              hsc: "80200a73d3b77870b57b86545c0e733252b43e0d5e5d690be9c85f5d3b0038f6",
              sup_fare: "14165_45036.65_10685",
              cabb: "Free",
              bagage_info: "40kg Baggage allowance Business",
              chekbcode: "JBAG",
              psup_fare: "27420_71105",
            },
          ],
          pr: {
            bf: 27420,
            tf: 71105,
            tax: [
              { OT: 43685 },
              { attmkp: 0 },
              { tamkp: 0 },
              { stamkp: 0 },
              { attdisc: 0 },
              { tadisc: 0 },
              { stadisc: 0 },
            ],
            scur: "INR",
            ucur: "INR",
            exchange_rate: 1,
            sup_fare: "27420_71105",
            sup_cur_fare: "",
            cabb: "Free",
            chekb: "14kg INCLUDED",
            meals: "Chargeable",
            chanb: "Chargeable",
            ftyp: "Business",
            extra: "15_Business",
            chekbcode: "JBAG",
            chanb_info:
              "BEFOREDEPARTURE 0 hrs of departure Change of Flight is Free,NOSHOWUPTO 720 hrs of departure Change of Flight is Free,NOSHOWAFTER From 720 hrs before  of departure Change of Flight is Non-Refundable",
            canb: "Chargeable",
            cabb_info:
              "BEFOREDEPARTURE 0 hrs of departure Cancellation of Flight is Free,NOSHOWUPTO 720 hrs of departure Cancellation of Flight is Free,NOSHOWAFTER From 720 hrs before  of departure Cancellation of Flight is Non-Refundable",
            bagage_info: "40kg Baggage allowance Business",
            markupnames: [],
            ttf: 71105,
          },
          sup: "5",
          airlineList: "FZ",
          destorigins: [
            {
              tid: "9k6XVKful8BcGX4U_2",
              ref: 2,
              office_id: "",
              sdur: "6:45",
              re: "",
              sup: "5",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DXB",
                  dd: "2023-06-09T17:00:00",
                  ad: "2023-06-09T19:15:00",
                  rbd: "",
                  fn: "018",
                  trn: "",
                  eq: "73X",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "1", arr: "2" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161399,
                  flightTime: 75,
                  fdur: "1:15",
                },
                {
                  ref: 2,
                  da: "DXB",
                  ar: "HYD",
                  dd: "2023-06-09T20:50:00",
                  ad: "2023-06-10T02:15:00",
                  rbd: "",
                  fn: "435",
                  trn: "",
                  eq: "73B",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "2", arr: "" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161456,
                  flightTime: 235,
                  fdur: "3:55",
                },
              ],
              fareop: [
                {
                  fareKey: [],
                  pr: {
                    bf: 13255,
                    tf: 26068.35,
                    tax: [
                      { OT: 12813.35 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    scur: "INR",
                    ucur: "INR",
                    exchange_rate: 1,
                    sup_fare: "13255_26068.35",
                    sup_cur_fare: "",
                    cabb: "Chargeable",
                    chekb: "7kg INCLUDED",
                    meals: "Chargeable",
                    chanb: "Non Refundable",
                    ftyp: "LITE",
                    extra: "11_LITE",
                    chekbcode: "",
                    chanb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Change of Flight is Non-Refundable,NOSHOW From 0 hrs before  of departure Change of Flight is Non-Refundable",
                    canb: "Non Refundable",
                    cabb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Cancellation of Flight is Non-Refundable,NOSHOW From 0 hrs before  of departure Cancellation of Flight is Non-Refundable",
                    bagage_info: "Chargeable",
                    markupnames: [],
                    ttf: 26068.35,
                  },
                  paxpr: [
                    {
                      bf: 13255,
                      tf: 26068.35,
                      tax: [
                        { OT: 12813.35 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "OR6IN5",
                      ptype: "ADT",
                      scur: "INR",
                      extra:
                        "1_20_O_1_9_0_tx-1764:1370:QA:0_2186:170:PZ:0_4246:6450:YQ:0_8966:1370:G4:0_9327:2313.35:K3:0_10386:120:ZR:0_11947:790:F6:0_13230:230:R9:0_",
                      ruleid:
                        "pFchWdgz+JANpI1BGZ6Le1ynIfJ/XB/vnt5lWewxSALdEVWgu3w8400pbatJN8v+dXRVoM0HZ8U9jxms9hE/Ws/RUwZbLq3+/lDxX34TkElcuOqD7NHphJpbWpAalKe5nFFh9Gsw/dpcQuWfjFtRfA==",
                      hsc: "adc04a5d62a4e3801b96f27e97eef5e940d59424488c387a378c315df3b37d9d",
                      sup_fare: "13255_26068.35_12805",
                      cabb: "Chargeable",
                      bagage_info: "Chargeable",
                      chekbcode: "",
                      psup_fare: "13255_26068.35",
                    },
                  ],
                  uniqueId: "LITE",
                  routeId: "hiy6mj3a5u",
                  servicefee: [],
                  selected: true,
                },
              ],
              tktair: "FZ",
              tecst: "no",
              cl: "Economy",
              extra: 13916393,
              token:
                "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiYXBpdGF3ZmVlcSIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL2dpdmVubmFtZSI6IkFQSSBUQVdGRUVRIiwiZ3JvdXBBY2Nlc3MiOiIzLDM2LDExMiIsImlzQXB0Q2xudCI6IkZhbHNlIiwibG9jYXRpb24iOiI5OTk5IiwiY2hrTGNuIjoiIiwicmVzVG9rZW4iOiJkZWRhZDdkeWY4N2FuOTc0dGJxYjY0YmJ6ZjZlOTYwYzA5ZTE5NmRhZTg2MCIsImNsaWVudElQIjoiMTcyLjE5LjIzLjU0IiwicmVzQ2hhbm5lbElEIjoiNSIsInJlc0NoYW5uZWxOYW1lIjoiVFBBUEkiLCJkZWZhdWx0VXNlck5hbWUiOiJhcGl0YXdmZWVxIiwibmJmIjoxNjg1NTE1MjM3LCJleHAiOjE2ODU1MTc2MzcsImlzcyI6ImZseWR1YmFpLmNvbSIsImF1ZCI6IlRBV0ZFRVFUUlZMU19GWl9OUCJ9.rAW3YT-bX_-yADfvg3QkuPAbOJhkhRUN6HwoS_6PyiM",
              stop: 1,
            },
            {
              tid: "B9PpvTmCN5MhNgmf_2",
              ref: 2,
              office_id: "",
              sdur: "13:45",
              re: "",
              sup: "5",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DXB",
                  dd: "2023-06-09T10:00:00",
                  ad: "2023-06-09T12:15:00",
                  rbd: "",
                  fn: "002",
                  trn: "",
                  eq: "73X",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "1", arr: "2" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161402,
                  flightTime: 75,
                  fdur: "1:15",
                },
                {
                  ref: 2,
                  da: "DXB",
                  ar: "HYD",
                  dd: "2023-06-09T20:50:00",
                  ad: "2023-06-10T02:15:00",
                  rbd: "",
                  fn: "435",
                  trn: "",
                  eq: "73B",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "2", arr: "" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161456,
                  flightTime: 235,
                  fdur: "3:55",
                },
              ],
              fareop: [
                {
                  fareKey: [],
                  pr: {
                    bf: 13255,
                    tf: 26068.35,
                    tax: [
                      { OT: 12813.35 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    scur: "INR",
                    ucur: "INR",
                    exchange_rate: 1,
                    sup_fare: "13255_26068.35",
                    sup_cur_fare: "",
                    cabb: "Chargeable",
                    chekb: "7kg INCLUDED",
                    meals: "Chargeable",
                    chanb: "Non Refundable",
                    ftyp: "LITE",
                    extra: "11_LITE",
                    chekbcode: "",
                    chanb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Change of Flight is Non-Refundable,NOSHOW From 0 hrs before  of departure Change of Flight is Non-Refundable",
                    canb: "Non Refundable",
                    cabb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Cancellation of Flight is Non-Refundable,NOSHOW From 0 hrs before  of departure Cancellation of Flight is Non-Refundable",
                    bagage_info: "Chargeable",
                    markupnames: [],
                    ttf: 26068.35,
                  },
                  paxpr: [
                    {
                      bf: 13255,
                      tf: 26068.35,
                      tax: [
                        { OT: 12813.35 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "OR6IN5",
                      ptype: "ADT",
                      scur: "INR",
                      extra:
                        "1_52_O_1_9_0_tx-1764:1370:QA:0_2186:170:PZ:0_4246:6450:YQ:0_8966:1370:G4:0_9327:2313.35:K3:0_10386:120:ZR:0_11947:790:F6:0_13230:230:R9:0_",
                      ruleid:
                        "U36SfsRO/K/cl6hnfQziTlynIfJ/XB/vnt5lWewxSALdEVWgu3w8400pbatJN8v+dXRVoM0HZ8U9jxms9hE/Ws/RUwZbLq3+/lDxX34TkElcuOqD7NHphJpbWpAalKe5nFFh9Gsw/dpcQuWfjFtRfA==",
                      hsc: "d6a12b9bb8fae6f3eb0e1bc6f356d52e7de8fb38462d82988da1f2704f8655a7",
                      sup_fare: "13255_26068.35_12805",
                      cabb: "Chargeable",
                      bagage_info: "Chargeable",
                      chekbcode: "",
                      psup_fare: "13255_26068.35",
                    },
                  ],
                  uniqueId: "LITE",
                  routeId: "7v2f00ildd",
                  servicefee: [],
                  selected: true,
                },
              ],
              tktair: "FZ",
              tecst: "no",
              cl: "Economy",
              extra: 13915297,
              token:
                "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiYXBpdGF3ZmVlcSIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL2dpdmVubmFtZSI6IkFQSSBUQVdGRUVRIiwiZ3JvdXBBY2Nlc3MiOiIzLDM2LDExMiIsImlzQXB0Q2xudCI6IkZhbHNlIiwibG9jYXRpb24iOiI5OTk5IiwiY2hrTGNuIjoiIiwicmVzVG9rZW4iOiJkZWRhZDdkeWY4N2FuOTc0dGJxYjY0YmJ6ZjZlOTYwYzA5ZTE5NmRhZTg2MCIsImNsaWVudElQIjoiMTcyLjE5LjIzLjU0IiwicmVzQ2hhbm5lbElEIjoiNSIsInJlc0NoYW5uZWxOYW1lIjoiVFBBUEkiLCJkZWZhdWx0VXNlck5hbWUiOiJhcGl0YXdmZWVxIiwibmJmIjoxNjg1NTE1MjM3LCJleHAiOjE2ODU1MTc2MzcsImlzcyI6ImZseWR1YmFpLmNvbSIsImF1ZCI6IlRBV0ZFRVFUUlZMU19GWl9OUCJ9.rAW3YT-bX_-yADfvg3QkuPAbOJhkhRUN6HwoS_6PyiM",
              stop: 1,
            },
          ],
          servicefee: [],
        },
        {
          srcorigns: [
            {
              tid: "qMq9Tw2LN3wuG6oI_1",
              ref: 1,
              office_id: "",
              sdur: "8:25",
              re: "",
              sup: "5",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DXB",
                  dd: "2023-06-07T03:00:00",
                  ad: "2023-06-07T05:30:00",
                  rbd: "",
                  fn: "436",
                  trn: "",
                  eq: "73B",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "", arr: "2" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161457,
                  flightTime: 240,
                  fdur: "4:0",
                },
                {
                  ref: 2,
                  da: "DXB",
                  ar: "DOH",
                  dd: "2023-06-07T08:45:00",
                  ad: "2023-06-07T08:55:00",
                  rbd: "",
                  fn: "001",
                  trn: "",
                  eq: "73X",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "2", arr: "1" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161405,
                  flightTime: 70,
                  fdur: "1:10",
                },
              ],
              fareop: [
                {
                  fareKey: [],
                  pr: {
                    bf: 14165,
                    tf: 45039.22,
                    tax: [
                      { OT: 30874.22 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    scur: "INR",
                    ucur: "INR",
                    exchange_rate: 1,
                    sup_fare: "14165_45039.22",
                    sup_cur_fare: "",
                    cabb: "Free",
                    chekb: "14kg INCLUDED",
                    meals: "Chargeable",
                    chanb: "Chargeable",
                    ftyp: "Business",
                    extra: "15_Business",
                    chekbcode: "JBAG",
                    chanb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Change of Flight is Free,NOSHOWUPTO 720 hrs of departure Change of Flight is Free,NOSHOWAFTER From 720 hrs before  of departure Change of Flight is Non-Refundable",
                    canb: "Chargeable",
                    cabb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Cancellation of Flight is Free,NOSHOWUPTO 720 hrs of departure Cancellation of Flight is Free,NOSHOWAFTER From 720 hrs before  of departure Cancellation of Flight is Non-Refundable",
                    bagage_info: "40kg Baggage allowance Business",
                    markupnames: [],
                    ttf: 45039.22,
                  },
                  paxpr: [
                    {
                      bf: 14165,
                      tf: 45039.22,
                      tax: [
                        { OT: 30874.22 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "ZRM9IN2",
                      ptype: "ADT",
                      scur: "INR",
                      extra:
                        "1_21_Z_1_5_0_tx-2186:170:PZ:0_4246:22970:YQ:0_6544:0:JBAG:0_9327:4049.22:K3:0_10386:120:ZR:0_11947:790:F6:0_12006:1170:P2:0_12434:1605:IN:0_",
                      ruleid:
                        "4d/VHng1YZsBvUV5QAj/ZzWE439NribcBOUajw99xT+o8LotKkPr9+rRgWI1VO0yvTml2i2hCEBikqdUYpBdQICMSUE+oAXNhfzC1AvPpsdXVy0HLLTzOxy6vo4KsNKyH4iOhL3qCTzeoIR/oeSzY4TEtPO2CThrAiJMBJQHPOZVOijsN/0s5eElrsthvOXj",
                      hsc: "ef487d4dc52528410077f2cf28bd8085bf627f21672fab7722a8a5dcfe96ea2c",
                      sup_fare: "14165_45039.22_10685",
                      cabb: "Free",
                      bagage_info: "40kg Baggage allowance Business",
                      chekbcode: "JBAG",
                      psup_fare: "14165_45039.22",
                    },
                  ],
                  uniqueId: "Business",
                  routeId: "jjw21orgql",
                  servicefee: [],
                  selected: true,
                },
              ],
              tktair: "FZ",
              tecst: "no",
              cl: "Economy",
              extra: 13919293,
              token:
                "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiYXBpdGF3ZmVlcSIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL2dpdmVubmFtZSI6IkFQSSBUQVdGRUVRIiwiZ3JvdXBBY2Nlc3MiOiIzLDM2LDExMiIsImlzQXB0Q2xudCI6IkZhbHNlIiwibG9jYXRpb24iOiI5OTk5IiwiY2hrTGNuIjoiIiwicmVzVG9rZW4iOiJkZWRhZDdkeWY4N2FuOTc0dGJxYjY0YmJ6ZjZlOTYwYzA5ZTE5NmRhZTg2MCIsImNsaWVudElQIjoiMTcyLjE5LjIzLjU0IiwicmVzQ2hhbm5lbElEIjoiNSIsInJlc0NoYW5uZWxOYW1lIjoiVFBBUEkiLCJkZWZhdWx0VXNlck5hbWUiOiJhcGl0YXdmZWVxIiwibmJmIjoxNjg1NTE1MjM3LCJleHAiOjE2ODU1MTc2MzcsImlzcyI6ImZseWR1YmFpLmNvbSIsImF1ZCI6IlRBV0ZFRVFUUlZMU19GWl9OUCJ9.rAW3YT-bX_-yADfvg3QkuPAbOJhkhRUN6HwoS_6PyiM",
              stop: 1,
            },
            {
              tid: "IrAJcJF16gKWsvLL_1",
              ref: 1,
              office_id: "",
              sdur: "15:25",
              re: "",
              sup: "5",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DXB",
                  dd: "2023-06-07T03:00:00",
                  ad: "2023-06-07T05:30:00",
                  rbd: "",
                  fn: "436",
                  trn: "",
                  eq: "73B",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "", arr: "2" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161457,
                  flightTime: 240,
                  fdur: "4:0",
                },
                {
                  ref: 2,
                  da: "DXB",
                  ar: "DOH",
                  dd: "2023-06-07T15:45:00",
                  ad: "2023-06-07T15:55:00",
                  rbd: "",
                  fn: "017",
                  trn: "",
                  eq: "73X",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "2", arr: "1" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161400,
                  flightTime: 70,
                  fdur: "1:10",
                },
              ],
              fareop: [
                {
                  fareKey: [],
                  pr: {
                    bf: 14165,
                    tf: 45039.22,
                    tax: [
                      { OT: 30874.22 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    scur: "INR",
                    ucur: "INR",
                    exchange_rate: 1,
                    sup_fare: "14165_45039.22",
                    sup_cur_fare: "",
                    cabb: "Free",
                    chekb: "14kg INCLUDED",
                    meals: "Chargeable",
                    chanb: "Chargeable",
                    ftyp: "Business",
                    extra: "15_Business",
                    chekbcode: "JBAG",
                    chanb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Change of Flight is Free,NOSHOWUPTO 720 hrs of departure Change of Flight is Free,NOSHOWAFTER From 720 hrs before  of departure Change of Flight is Non-Refundable",
                    canb: "Chargeable",
                    cabb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Cancellation of Flight is Free,NOSHOWUPTO 720 hrs of departure Cancellation of Flight is Free,NOSHOWAFTER From 720 hrs before  of departure Cancellation of Flight is Non-Refundable",
                    bagage_info: "40kg Baggage allowance Business",
                    markupnames: [],
                    ttf: 45039.22,
                  },
                  paxpr: [
                    {
                      bf: 14165,
                      tf: 45039.22,
                      tax: [
                        { OT: 30874.22 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "ZRM9IN2",
                      ptype: "ADT",
                      scur: "INR",
                      extra:
                        "1_85_Z_1_5_0_tx-2186:170:PZ:0_4246:22970:YQ:0_6544:0:JBAG:0_9327:4049.22:K3:0_10386:120:ZR:0_11947:790:F6:0_12006:1170:P2:0_12434:1605:IN:0_",
                      ruleid:
                        "yQPzJvqVHp8PZfHhP/n1JDWE439NribcBOUajw99xT+o8LotKkPr9+rRgWI1VO0yvTml2i2hCEBikqdUYpBdQICMSUE+oAXNhfzC1AvPpsdXVy0HLLTzOxy6vo4KsNKyH4iOhL3qCTzeoIR/oeSzY4TEtPO2CThrAiJMBJQHPOZVOijsN/0s5eElrsthvOXj",
                      hsc: "82a5b978f64e3085665f872020ee04c4f0cb245332add21e0b9f3b80c630f7d9",
                      sup_fare: "14165_45039.22_10685",
                      cabb: "Free",
                      bagage_info: "40kg Baggage allowance Business",
                      chekbcode: "JBAG",
                      psup_fare: "14165_45039.22",
                    },
                  ],
                  uniqueId: "Business",
                  routeId: "4dpjuuelli",
                  servicefee: [],
                  selected: true,
                },
              ],
              tktair: "FZ",
              tecst: "no",
              cl: "Economy",
              extra: 13919296,
              token:
                "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiYXBpdGF3ZmVlcSIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL2dpdmVubmFtZSI6IkFQSSBUQVdGRUVRIiwiZ3JvdXBBY2Nlc3MiOiIzLDM2LDExMiIsImlzQXB0Q2xudCI6IkZhbHNlIiwibG9jYXRpb24iOiI5OTk5IiwiY2hrTGNuIjoiIiwicmVzVG9rZW4iOiJkZWRhZDdkeWY4N2FuOTc0dGJxYjY0YmJ6ZjZlOTYwYzA5ZTE5NmRhZTg2MCIsImNsaWVudElQIjoiMTcyLjE5LjIzLjU0IiwicmVzQ2hhbm5lbElEIjoiNSIsInJlc0NoYW5uZWxOYW1lIjoiVFBBUEkiLCJkZWZhdWx0VXNlck5hbWUiOiJhcGl0YXdmZWVxIiwibmJmIjoxNjg1NTE1MjM3LCJleHAiOjE2ODU1MTc2MzcsImlzcyI6ImZseWR1YmFpLmNvbSIsImF1ZCI6IlRBV0ZFRVFUUlZMU19GWl9OUCJ9.rAW3YT-bX_-yADfvg3QkuPAbOJhkhRUN6HwoS_6PyiM",
              stop: 1,
            },
            {
              tid: "Eyt4XrWOsLy8db2S_1",
              ref: 1,
              office_id: "",
              sdur: "19:25",
              re: "",
              sup: "5",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DXB",
                  dd: "2023-06-07T03:00:00",
                  ad: "2023-06-07T05:30:00",
                  rbd: "",
                  fn: "436",
                  trn: "",
                  eq: "73B",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "", arr: "2" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161457,
                  flightTime: 240,
                  fdur: "4:0",
                },
                {
                  ref: 2,
                  da: "DXB",
                  ar: "DOH",
                  dd: "2023-06-07T19:45:00",
                  ad: "2023-06-07T19:55:00",
                  rbd: "",
                  fn: "005",
                  trn: "",
                  eq: "73X",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "2", arr: "1" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161381,
                  flightTime: 70,
                  fdur: "1:10",
                },
              ],
              fareop: [
                {
                  fareKey: [],
                  pr: {
                    bf: 14165,
                    tf: 45039.22,
                    tax: [
                      { OT: 30874.22 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    scur: "INR",
                    ucur: "INR",
                    exchange_rate: 1,
                    sup_fare: "14165_45039.22",
                    sup_cur_fare: "",
                    cabb: "Free",
                    chekb: "14kg INCLUDED",
                    meals: "Chargeable",
                    chanb: "Chargeable",
                    ftyp: "Business",
                    extra: "15_Business",
                    chekbcode: "JBAG",
                    chanb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Change of Flight is Free,NOSHOWUPTO 720 hrs of departure Change of Flight is Free,NOSHOWAFTER From 720 hrs before  of departure Change of Flight is Non-Refundable",
                    canb: "Chargeable",
                    cabb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Cancellation of Flight is Free,NOSHOWUPTO 720 hrs of departure Cancellation of Flight is Free,NOSHOWAFTER From 720 hrs before  of departure Cancellation of Flight is Non-Refundable",
                    bagage_info: "40kg Baggage allowance Business",
                    markupnames: [],
                    ttf: 45039.22,
                  },
                  paxpr: [
                    {
                      bf: 14165,
                      tf: 45039.22,
                      tax: [
                        { OT: 30874.22 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "ZRM9IN2",
                      ptype: "ADT",
                      scur: "INR",
                      extra:
                        "1_117_Z_1_5_0_tx-2186:170:PZ:0_4246:22970:YQ:0_6544:0:JBAG:0_9327:4049.22:K3:0_10386:120:ZR:0_11947:790:F6:0_12006:1170:P2:0_12434:1605:IN:0_",
                      ruleid:
                        "ksPMmYQjY3l8daBWeQn3AqNXn6aA9MtcY0N9tFO6QOwKxrc4UYGO2IzDMPt/j9KfyZrzxNnMdcOJwgpzMIdukRM1YiBqo5VUngE+f6ZTlrcWhTrLfc+1HjPslNbsK6Ma+NzJ9vs0yTvfIGzHK+7a6QYdbLMNnyuqz+GQYY8X4udk3IvNdcymNm5f/d40hxVF",
                      hsc: "7bce5a3866fc2f3670b9f71c3f4d5b885a815ed99406a1f6aedd747c2de15eca",
                      sup_fare: "14165_45039.22_10685",
                      cabb: "Free",
                      bagage_info: "40kg Baggage allowance Business",
                      chekbcode: "JBAG",
                      psup_fare: "14165_45039.22",
                    },
                  ],
                  uniqueId: "Business",
                  routeId: "0ie0zytdyp",
                  servicefee: [],
                  selected: true,
                },
              ],
              tktair: "FZ",
              tecst: "no",
              cl: "Economy",
              extra: 13919294,
              token:
                "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiYXBpdGF3ZmVlcSIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL2dpdmVubmFtZSI6IkFQSSBUQVdGRUVRIiwiZ3JvdXBBY2Nlc3MiOiIzLDM2LDExMiIsImlzQXB0Q2xudCI6IkZhbHNlIiwibG9jYXRpb24iOiI5OTk5IiwiY2hrTGNuIjoiIiwicmVzVG9rZW4iOiJkZWRhZDdkeWY4N2FuOTc0dGJxYjY0YmJ6ZjZlOTYwYzA5ZTE5NmRhZTg2MCIsImNsaWVudElQIjoiMTcyLjE5LjIzLjU0IiwicmVzQ2hhbm5lbElEIjoiNSIsInJlc0NoYW5uZWxOYW1lIjoiVFBBUEkiLCJkZWZhdWx0VXNlck5hbWUiOiJhcGl0YXdmZWVxIiwibmJmIjoxNjg1NTE1MjM3LCJleHAiOjE2ODU1MTc2MzcsImlzcyI6ImZseWR1YmFpLmNvbSIsImF1ZCI6IlRBV0ZFRVFUUlZMU19GWl9OUCJ9.rAW3YT-bX_-yADfvg3QkuPAbOJhkhRUN6HwoS_6PyiM",
              stop: 1,
            },
          ],
          paxpr: [
            {
              bf: 28620,
              tf: 72455,
              tax: [
                { OT: 43835 },
                { attmkp: 0 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              fbc: "ZRM9IN2",
              ptype: "ADT",
              scur: "INR",
              extra:
                "1_21_Z_1_5_0_tx-2186:170:PZ:0_4246:22970:YQ:0_6544:0:JBAG:0_9327:4049.22:K3:0_10386:120:ZR:0_11947:790:F6:0_12006:1170:P2:0_12434:1605:IN:0_",
              ruleid:
                "4d/VHng1YZsBvUV5QAj/ZzWE439NribcBOUajw99xT+o8LotKkPr9+rRgWI1VO0yvTml2i2hCEBikqdUYpBdQICMSUE+oAXNhfzC1AvPpsdXVy0HLLTzOxy6vo4KsNKyH4iOhL3qCTzeoIR/oeSzY4TEtPO2CThrAiJMBJQHPOZVOijsN/0s5eElrsthvOXj",
              hsc: "ef487d4dc52528410077f2cf28bd8085bf627f21672fab7722a8a5dcfe96ea2c",
              sup_fare: "14165_45039.22_10685",
              cabb: "Free",
              bagage_info: "40kg Baggage allowance Business",
              chekbcode: "JBAG",
              psup_fare: "28620_72455",
            },
          ],
          pr: {
            bf: 28620,
            tf: 72455,
            tax: [
              { OT: 43835 },
              { attmkp: 0 },
              { tamkp: 0 },
              { stamkp: 0 },
              { attdisc: 0 },
              { tadisc: 0 },
              { stadisc: 0 },
            ],
            scur: "INR",
            ucur: "INR",
            exchange_rate: 1,
            sup_fare: "28620_72455",
            sup_cur_fare: "",
            cabb: "Free",
            chekb: "14kg INCLUDED",
            meals: "Chargeable",
            chanb: "Chargeable",
            ftyp: "Business",
            extra: "15_Business",
            chekbcode: "JBAG",
            chanb_info:
              "BEFOREDEPARTURE 0 hrs of departure Change of Flight is Free,NOSHOWUPTO 720 hrs of departure Change of Flight is Free,NOSHOWAFTER From 720 hrs before  of departure Change of Flight is Non-Refundable",
            canb: "Chargeable",
            cabb_info:
              "BEFOREDEPARTURE 0 hrs of departure Cancellation of Flight is Free,NOSHOWUPTO 720 hrs of departure Cancellation of Flight is Free,NOSHOWAFTER From 720 hrs before  of departure Cancellation of Flight is Non-Refundable",
            bagage_info: "40kg Baggage allowance Business",
            markupnames: [],
            ttf: 72455,
          },
          sup: "5",
          airlineList: "FZ",
          destorigins: [
            {
              tid: "aqoXm4V41pkDL7Qq_2",
              ref: 2,
              office_id: "",
              sdur: "6:45",
              re: "",
              sup: "5",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DXB",
                  dd: "2023-06-09T17:00:00",
                  ad: "2023-06-09T19:15:00",
                  rbd: "",
                  fn: "018",
                  trn: "",
                  eq: "73X",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "1", arr: "2" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161399,
                  flightTime: 75,
                  fdur: "1:15",
                },
                {
                  ref: 2,
                  da: "DXB",
                  ar: "HYD",
                  dd: "2023-06-09T20:50:00",
                  ad: "2023-06-10T02:15:00",
                  rbd: "",
                  fn: "435",
                  trn: "",
                  eq: "73B",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "2", arr: "" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161456,
                  flightTime: 235,
                  fdur: "3:55",
                },
              ],
              fareop: [
                {
                  fareKey: [],
                  pr: {
                    bf: 14455,
                    tf: 27415.78,
                    tax: [
                      { OT: 12960.78 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    scur: "INR",
                    ucur: "INR",
                    exchange_rate: 1,
                    sup_fare: "14455_27415.78",
                    sup_cur_fare: "",
                    cabb: "Free",
                    chekb: "7kg INCLUDED",
                    meals: "Standard meal",
                    chanb: "Chargeable",
                    ftyp: "VALUE",
                    extra: "12_VALUE",
                    chekbcode: "BAGL",
                    chanb_info:
                      "UPTO 24 hrs of departure Change of Flight is Free,WITHIN From 0-24 hrs of departure Change of Flight is Non-Refundable,NOSHOW From 0 hrs before  of departure Change of Flight is Non-Refundable",
                    canb: "Chargeable",
                    cabb_info:
                      "UPTO 24 hrs of departure Cancellation of Flight is Free,WITHIN From 0-24 hrs of departure Cancellation of Flight is Non-Refundable,NOSHOW From 0 hrs before  of departure Cancellation of Flight is Non-Refundable",
                    bagage_info: "30kg BAG INCLUDED IN FARE",
                    markupnames: [],
                    ttf: 27415.78,
                  },
                  paxpr: [
                    {
                      bf: 14455,
                      tf: 27415.78,
                      tax: [
                        { OT: 12960.78 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "ORL7IN5",
                      ptype: "ADT",
                      scur: "INR",
                      extra:
                        "1_22_O_1_9_0_tx-1764:1370:QA:0_2186:170:PZ:0_4246:6450:YQ:0_8947:0:BAGL:0_8966:1370:G4:0_9327:2460.78:K3:0_10386:120:ZR:0_11947:790:F6:0_13230:230:R9:0_",
                      ruleid:
                        "zDKnH/aeX92j1ptjStBuRVynIfJ/XB/vnt5lWewxSALdEVWgu3w8400pbatJN8v+dXRVoM0HZ8U9jxms9hE/Ws/RUwZbLq3+/lDxX34TkEkpnt2f57bucsArrJ1QaV3YnFFh9Gsw/dpcQuWfjFtRfA==",
                      hsc: "8e1886763452e574ac8f400ab563fcf196faf2b150195eb094297af4bb5b6600",
                      sup_fare: "14455_27415.78_14005",
                      cabb: "Free",
                      bagage_info: "30kg BAG INCLUDED IN FARE",
                      chekbcode: "BAGL",
                      psup_fare: "14455_27415.78",
                    },
                  ],
                  uniqueId: "VALUE",
                  routeId: "5gag62g2pck",
                  servicefee: [],
                  selected: true,
                },
              ],
              tktair: "FZ",
              tecst: "no",
              cl: "Economy",
              extra: 13916393,
              token:
                "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiYXBpdGF3ZmVlcSIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL2dpdmVubmFtZSI6IkFQSSBUQVdGRUVRIiwiZ3JvdXBBY2Nlc3MiOiIzLDM2LDExMiIsImlzQXB0Q2xudCI6IkZhbHNlIiwibG9jYXRpb24iOiI5OTk5IiwiY2hrTGNuIjoiIiwicmVzVG9rZW4iOiJkZWRhZDdkeWY4N2FuOTc0dGJxYjY0YmJ6ZjZlOTYwYzA5ZTE5NmRhZTg2MCIsImNsaWVudElQIjoiMTcyLjE5LjIzLjU0IiwicmVzQ2hhbm5lbElEIjoiNSIsInJlc0NoYW5uZWxOYW1lIjoiVFBBUEkiLCJkZWZhdWx0VXNlck5hbWUiOiJhcGl0YXdmZWVxIiwibmJmIjoxNjg1NTE1MjM3LCJleHAiOjE2ODU1MTc2MzcsImlzcyI6ImZseWR1YmFpLmNvbSIsImF1ZCI6IlRBV0ZFRVFUUlZMU19GWl9OUCJ9.rAW3YT-bX_-yADfvg3QkuPAbOJhkhRUN6HwoS_6PyiM",
              stop: 1,
            },
            {
              tid: "FzH6bhN7OkG26T8q_2",
              ref: 2,
              office_id: "",
              sdur: "13:45",
              re: "",
              sup: "5",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DXB",
                  dd: "2023-06-09T10:00:00",
                  ad: "2023-06-09T12:15:00",
                  rbd: "",
                  fn: "002",
                  trn: "",
                  eq: "73X",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "1", arr: "2" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161402,
                  flightTime: 75,
                  fdur: "1:15",
                },
                {
                  ref: 2,
                  da: "DXB",
                  ar: "HYD",
                  dd: "2023-06-09T20:50:00",
                  ad: "2023-06-10T02:15:00",
                  rbd: "",
                  fn: "435",
                  trn: "",
                  eq: "73B",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "2", arr: "" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161456,
                  flightTime: 235,
                  fdur: "3:55",
                },
              ],
              fareop: [
                {
                  fareKey: [],
                  pr: {
                    bf: 14455,
                    tf: 27415.78,
                    tax: [
                      { OT: 12960.78 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    scur: "INR",
                    ucur: "INR",
                    exchange_rate: 1,
                    sup_fare: "14455_27415.78",
                    sup_cur_fare: "",
                    cabb: "Free",
                    chekb: "7kg INCLUDED",
                    meals: "Standard meal",
                    chanb: "Chargeable",
                    ftyp: "VALUE",
                    extra: "12_VALUE",
                    chekbcode: "BAGL",
                    chanb_info:
                      "UPTO 24 hrs of departure Change of Flight is Free,WITHIN From 0-24 hrs of departure Change of Flight is Non-Refundable,NOSHOW From 0 hrs before  of departure Change of Flight is Non-Refundable",
                    canb: "Chargeable",
                    cabb_info:
                      "UPTO 24 hrs of departure Cancellation of Flight is Free,WITHIN From 0-24 hrs of departure Cancellation of Flight is Non-Refundable,NOSHOW From 0 hrs before  of departure Cancellation of Flight is Non-Refundable",
                    bagage_info: "30kg BAG INCLUDED IN FARE",
                    markupnames: [],
                    ttf: 27415.78,
                  },
                  paxpr: [
                    {
                      bf: 14455,
                      tf: 27415.78,
                      tax: [
                        { OT: 12960.78 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "ORL7IN5",
                      ptype: "ADT",
                      scur: "INR",
                      extra:
                        "1_54_O_1_9_0_tx-1764:1370:QA:0_2186:170:PZ:0_4246:6450:YQ:0_8947:0:BAGL:0_8966:1370:G4:0_9327:2460.78:K3:0_10386:120:ZR:0_11947:790:F6:0_13230:230:R9:0_",
                      ruleid:
                        "DzIV50ii6he6n6SeZeersVynIfJ/XB/vnt5lWewxSALdEVWgu3w8400pbatJN8v+dXRVoM0HZ8U9jxms9hE/Ws/RUwZbLq3+/lDxX34TkEkpnt2f57bucsArrJ1QaV3YnFFh9Gsw/dpcQuWfjFtRfA==",
                      hsc: "5525742d62d3bec751f24cb62d285882449d5725b5fe13d4406614bcd664bf64",
                      sup_fare: "14455_27415.78_14005",
                      cabb: "Free",
                      bagage_info: "30kg BAG INCLUDED IN FARE",
                      chekbcode: "BAGL",
                      psup_fare: "14455_27415.78",
                    },
                  ],
                  uniqueId: "VALUE",
                  routeId: "rnz0m8y5qq",
                  servicefee: [],
                  selected: true,
                },
              ],
              tktair: "FZ",
              tecst: "no",
              cl: "Economy",
              extra: 13915297,
              token:
                "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiYXBpdGF3ZmVlcSIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL2dpdmVubmFtZSI6IkFQSSBUQVdGRUVRIiwiZ3JvdXBBY2Nlc3MiOiIzLDM2LDExMiIsImlzQXB0Q2xudCI6IkZhbHNlIiwibG9jYXRpb24iOiI5OTk5IiwiY2hrTGNuIjoiIiwicmVzVG9rZW4iOiJkZWRhZDdkeWY4N2FuOTc0dGJxYjY0YmJ6ZjZlOTYwYzA5ZTE5NmRhZTg2MCIsImNsaWVudElQIjoiMTcyLjE5LjIzLjU0IiwicmVzQ2hhbm5lbElEIjoiNSIsInJlc0NoYW5uZWxOYW1lIjoiVFBBUEkiLCJkZWZhdWx0VXNlck5hbWUiOiJhcGl0YXdmZWVxIiwibmJmIjoxNjg1NTE1MjM3LCJleHAiOjE2ODU1MTc2MzcsImlzcyI6ImZseWR1YmFpLmNvbSIsImF1ZCI6IlRBV0ZFRVFUUlZMU19GWl9OUCJ9.rAW3YT-bX_-yADfvg3QkuPAbOJhkhRUN6HwoS_6PyiM",
              stop: 1,
            },
          ],
          servicefee: [],
        },
        {
          srcorigns: [
            {
              tid: "l3JRyW3JbWGUX9mL_1",
              ref: 1,
              office_id: "",
              sdur: "8:25",
              re: "",
              sup: "5",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DXB",
                  dd: "2023-06-07T03:00:00",
                  ad: "2023-06-07T05:30:00",
                  rbd: "",
                  fn: "436",
                  trn: "",
                  eq: "73B",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "", arr: "2" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161457,
                  flightTime: 240,
                  fdur: "4:0",
                },
                {
                  ref: 2,
                  da: "DXB",
                  ar: "DOH",
                  dd: "2023-06-07T08:45:00",
                  ad: "2023-06-07T08:55:00",
                  rbd: "",
                  fn: "001",
                  trn: "",
                  eq: "73X",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "2", arr: "1" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161405,
                  flightTime: 70,
                  fdur: "1:10",
                },
              ],
              fareop: [
                {
                  fareKey: [],
                  pr: {
                    bf: 14165,
                    tf: 45037.37,
                    tax: [
                      { OT: 30872.37 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    scur: "INR",
                    ucur: "INR",
                    exchange_rate: 1,
                    sup_fare: "14165_45037.37",
                    sup_cur_fare: "",
                    cabb: "Free",
                    chekb: "14kg INCLUDED",
                    meals: "Chargeable",
                    chanb: "Chargeable",
                    ftyp: "Business",
                    extra: "15_Business",
                    chekbcode: "JBAG",
                    chanb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Change of Flight is Free,NOSHOWUPTO 720 hrs of departure Change of Flight is Free,NOSHOWAFTER From 720 hrs before  of departure Change of Flight is Non-Refundable",
                    canb: "Chargeable",
                    cabb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Cancellation of Flight is Free,NOSHOWUPTO 720 hrs of departure Cancellation of Flight is Free,NOSHOWAFTER From 720 hrs before  of departure Cancellation of Flight is Non-Refundable",
                    bagage_info: "40kg Baggage allowance Business",
                    markupnames: [],
                    ttf: 45037.37,
                  },
                  paxpr: [
                    {
                      bf: 14165,
                      tf: 45037.37,
                      tax: [
                        { OT: 30872.37 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "ZRM9IN2",
                      ptype: "ADT",
                      scur: "INR",
                      extra:
                        "1_23_Z_1_5_0_tx-2186:170:PZ:0_4246:22970:YQ:0_6544:0:JBAG:0_9327:4047.37:K3:0_10386:120:ZR:0_11947:790:F6:0_12006:1170:P2:0_12434:1605:IN:0_",
                      ruleid:
                        "WRKDcE0Bbq85gommPPxt1TWE439NribcBOUajw99xT+o8LotKkPr9+rRgWI1VO0yvTml2i2hCEBikqdUYpBdQICMSUE+oAXNhfzC1AvPpsdXVy0HLLTzOxy6vo4KsNKyH4iOhL3qCTzeoIR/oeSzY4TEtPO2CThrAiJMBJQHPOZVOijsN/0s5eElrsthvOXj",
                      hsc: "2dbb2dd0cfc6e93e1ebec53a3e1257717140312f9ef95de0de61aacb797ba8db",
                      sup_fare: "14165_45037.37_10685",
                      cabb: "Free",
                      bagage_info: "40kg Baggage allowance Business",
                      chekbcode: "JBAG",
                      psup_fare: "14165_45037.37",
                    },
                  ],
                  uniqueId: "Business",
                  routeId: "586d7w2gae",
                  servicefee: [],
                  selected: true,
                },
              ],
              tktair: "FZ",
              tecst: "no",
              cl: "Economy",
              extra: 13919293,
              token:
                "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiYXBpdGF3ZmVlcSIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL2dpdmVubmFtZSI6IkFQSSBUQVdGRUVRIiwiZ3JvdXBBY2Nlc3MiOiIzLDM2LDExMiIsImlzQXB0Q2xudCI6IkZhbHNlIiwibG9jYXRpb24iOiI5OTk5IiwiY2hrTGNuIjoiIiwicmVzVG9rZW4iOiJkZWRhZDdkeWY4N2FuOTc0dGJxYjY0YmJ6ZjZlOTYwYzA5ZTE5NmRhZTg2MCIsImNsaWVudElQIjoiMTcyLjE5LjIzLjU0IiwicmVzQ2hhbm5lbElEIjoiNSIsInJlc0NoYW5uZWxOYW1lIjoiVFBBUEkiLCJkZWZhdWx0VXNlck5hbWUiOiJhcGl0YXdmZWVxIiwibmJmIjoxNjg1NTE1MjM3LCJleHAiOjE2ODU1MTc2MzcsImlzcyI6ImZseWR1YmFpLmNvbSIsImF1ZCI6IlRBV0ZFRVFUUlZMU19GWl9OUCJ9.rAW3YT-bX_-yADfvg3QkuPAbOJhkhRUN6HwoS_6PyiM",
              stop: 1,
            },
            {
              tid: "vMeusiiD5PvKnSWX_1",
              ref: 1,
              office_id: "",
              sdur: "15:25",
              re: "",
              sup: "5",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DXB",
                  dd: "2023-06-07T03:00:00",
                  ad: "2023-06-07T05:30:00",
                  rbd: "",
                  fn: "436",
                  trn: "",
                  eq: "73B",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "", arr: "2" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161457,
                  flightTime: 240,
                  fdur: "4:0",
                },
                {
                  ref: 2,
                  da: "DXB",
                  ar: "DOH",
                  dd: "2023-06-07T15:45:00",
                  ad: "2023-06-07T15:55:00",
                  rbd: "",
                  fn: "017",
                  trn: "",
                  eq: "73X",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "2", arr: "1" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161400,
                  flightTime: 70,
                  fdur: "1:10",
                },
              ],
              fareop: [
                {
                  fareKey: [],
                  pr: {
                    bf: 14165,
                    tf: 45037.37,
                    tax: [
                      { OT: 30872.37 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    scur: "INR",
                    ucur: "INR",
                    exchange_rate: 1,
                    sup_fare: "14165_45037.37",
                    sup_cur_fare: "",
                    cabb: "Free",
                    chekb: "14kg INCLUDED",
                    meals: "Chargeable",
                    chanb: "Chargeable",
                    ftyp: "Business",
                    extra: "15_Business",
                    chekbcode: "JBAG",
                    chanb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Change of Flight is Free,NOSHOWUPTO 720 hrs of departure Change of Flight is Free,NOSHOWAFTER From 720 hrs before  of departure Change of Flight is Non-Refundable",
                    canb: "Chargeable",
                    cabb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Cancellation of Flight is Free,NOSHOWUPTO 720 hrs of departure Cancellation of Flight is Free,NOSHOWAFTER From 720 hrs before  of departure Cancellation of Flight is Non-Refundable",
                    bagage_info: "40kg Baggage allowance Business",
                    markupnames: [],
                    ttf: 45037.37,
                  },
                  paxpr: [
                    {
                      bf: 14165,
                      tf: 45037.37,
                      tax: [
                        { OT: 30872.37 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "ZRM9IN2",
                      ptype: "ADT",
                      scur: "INR",
                      extra:
                        "1_87_Z_1_5_0_tx-2186:170:PZ:0_4246:22970:YQ:0_6544:0:JBAG:0_9327:4047.37:K3:0_10386:120:ZR:0_11947:790:F6:0_12006:1170:P2:0_12434:1605:IN:0_",
                      ruleid:
                        "4jF9t3I2TB4o4fj3nNmGIzWE439NribcBOUajw99xT+o8LotKkPr9+rRgWI1VO0yvTml2i2hCEBikqdUYpBdQICMSUE+oAXNhfzC1AvPpsdXVy0HLLTzOxy6vo4KsNKyH4iOhL3qCTzeoIR/oeSzY4TEtPO2CThrAiJMBJQHPOZVOijsN/0s5eElrsthvOXj",
                      hsc: "8816c39d38c9a3afdb7e26d6395ef71b3ddb22c239093fe00498f62581ddc1f1",
                      sup_fare: "14165_45037.37_10685",
                      cabb: "Free",
                      bagage_info: "40kg Baggage allowance Business",
                      chekbcode: "JBAG",
                      psup_fare: "14165_45037.37",
                    },
                  ],
                  uniqueId: "Business",
                  routeId: "i5wdwddm4ml",
                  servicefee: [],
                  selected: true,
                },
              ],
              tktair: "FZ",
              tecst: "no",
              cl: "Economy",
              extra: 13919296,
              token:
                "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiYXBpdGF3ZmVlcSIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL2dpdmVubmFtZSI6IkFQSSBUQVdGRUVRIiwiZ3JvdXBBY2Nlc3MiOiIzLDM2LDExMiIsImlzQXB0Q2xudCI6IkZhbHNlIiwibG9jYXRpb24iOiI5OTk5IiwiY2hrTGNuIjoiIiwicmVzVG9rZW4iOiJkZWRhZDdkeWY4N2FuOTc0dGJxYjY0YmJ6ZjZlOTYwYzA5ZTE5NmRhZTg2MCIsImNsaWVudElQIjoiMTcyLjE5LjIzLjU0IiwicmVzQ2hhbm5lbElEIjoiNSIsInJlc0NoYW5uZWxOYW1lIjoiVFBBUEkiLCJkZWZhdWx0VXNlck5hbWUiOiJhcGl0YXdmZWVxIiwibmJmIjoxNjg1NTE1MjM3LCJleHAiOjE2ODU1MTc2MzcsImlzcyI6ImZseWR1YmFpLmNvbSIsImF1ZCI6IlRBV0ZFRVFUUlZMU19GWl9OUCJ9.rAW3YT-bX_-yADfvg3QkuPAbOJhkhRUN6HwoS_6PyiM",
              stop: 1,
            },
            {
              tid: "iJ157Q7p4ZlaVmln_1",
              ref: 1,
              office_id: "",
              sdur: "19:25",
              re: "",
              sup: "5",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DXB",
                  dd: "2023-06-07T03:00:00",
                  ad: "2023-06-07T05:30:00",
                  rbd: "",
                  fn: "436",
                  trn: "",
                  eq: "73B",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "", arr: "2" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161457,
                  flightTime: 240,
                  fdur: "4:0",
                },
                {
                  ref: 2,
                  da: "DXB",
                  ar: "DOH",
                  dd: "2023-06-07T19:45:00",
                  ad: "2023-06-07T19:55:00",
                  rbd: "",
                  fn: "005",
                  trn: "",
                  eq: "73X",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "2", arr: "1" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161381,
                  flightTime: 70,
                  fdur: "1:10",
                },
              ],
              fareop: [
                {
                  fareKey: [],
                  pr: {
                    bf: 14165,
                    tf: 45037.37,
                    tax: [
                      { OT: 30872.37 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    scur: "INR",
                    ucur: "INR",
                    exchange_rate: 1,
                    sup_fare: "14165_45037.37",
                    sup_cur_fare: "",
                    cabb: "Free",
                    chekb: "14kg INCLUDED",
                    meals: "Chargeable",
                    chanb: "Chargeable",
                    ftyp: "Business",
                    extra: "15_Business",
                    chekbcode: "JBAG",
                    chanb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Change of Flight is Free,NOSHOWUPTO 720 hrs of departure Change of Flight is Free,NOSHOWAFTER From 720 hrs before  of departure Change of Flight is Non-Refundable",
                    canb: "Chargeable",
                    cabb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Cancellation of Flight is Free,NOSHOWUPTO 720 hrs of departure Cancellation of Flight is Free,NOSHOWAFTER From 720 hrs before  of departure Cancellation of Flight is Non-Refundable",
                    bagage_info: "40kg Baggage allowance Business",
                    markupnames: [],
                    ttf: 45037.37,
                  },
                  paxpr: [
                    {
                      bf: 14165,
                      tf: 45037.37,
                      tax: [
                        { OT: 30872.37 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "ZRM9IN2",
                      ptype: "ADT",
                      scur: "INR",
                      extra:
                        "1_119_Z_1_5_0_tx-2186:170:PZ:0_4246:22970:YQ:0_6544:0:JBAG:0_9327:4047.37:K3:0_10386:120:ZR:0_11947:790:F6:0_12006:1170:P2:0_12434:1605:IN:0_",
                      ruleid:
                        "7JaQVLSoNUxveaGXec0MxqNXn6aA9MtcY0N9tFO6QOwKxrc4UYGO2IzDMPt/j9KfyZrzxNnMdcOJwgpzMIdukRM1YiBqo5VUngE+f6ZTlrcWhTrLfc+1HjPslNbsK6Ma+NzJ9vs0yTvfIGzHK+7a6QYdbLMNnyuqz+GQYY8X4udk3IvNdcymNm5f/d40hxVF",
                      hsc: "1c87596a8cb0b0fb8b7c0b15576db2a74adeaec7e7a37e2deb1cf7d2b1bb1be0",
                      sup_fare: "14165_45037.37_10685",
                      cabb: "Free",
                      bagage_info: "40kg Baggage allowance Business",
                      chekbcode: "JBAG",
                      psup_fare: "14165_45037.37",
                    },
                  ],
                  uniqueId: "Business",
                  routeId: "1llax505th",
                  servicefee: [],
                  selected: true,
                },
              ],
              tktair: "FZ",
              tecst: "no",
              cl: "Economy",
              extra: 13919294,
              token:
                "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiYXBpdGF3ZmVlcSIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL2dpdmVubmFtZSI6IkFQSSBUQVdGRUVRIiwiZ3JvdXBBY2Nlc3MiOiIzLDM2LDExMiIsImlzQXB0Q2xudCI6IkZhbHNlIiwibG9jYXRpb24iOiI5OTk5IiwiY2hrTGNuIjoiIiwicmVzVG9rZW4iOiJkZWRhZDdkeWY4N2FuOTc0dGJxYjY0YmJ6ZjZlOTYwYzA5ZTE5NmRhZTg2MCIsImNsaWVudElQIjoiMTcyLjE5LjIzLjU0IiwicmVzQ2hhbm5lbElEIjoiNSIsInJlc0NoYW5uZWxOYW1lIjoiVFBBUEkiLCJkZWZhdWx0VXNlck5hbWUiOiJhcGl0YXdmZWVxIiwibmJmIjoxNjg1NTE1MjM3LCJleHAiOjE2ODU1MTc2MzcsImlzcyI6ImZseWR1YmFpLmNvbSIsImF1ZCI6IlRBV0ZFRVFUUlZMU19GWl9OUCJ9.rAW3YT-bX_-yADfvg3QkuPAbOJhkhRUN6HwoS_6PyiM",
              stop: 1,
            },
          ],
          paxpr: [
            {
              bf: 34150,
              tf: 78645,
              tax: [
                { OT: 44495 },
                { attmkp: 0 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              fbc: "ZRM9IN2",
              ptype: "ADT",
              scur: "INR",
              extra:
                "1_23_Z_1_5_0_tx-2186:170:PZ:0_4246:22970:YQ:0_6544:0:JBAG:0_9327:4047.37:K3:0_10386:120:ZR:0_11947:790:F6:0_12006:1170:P2:0_12434:1605:IN:0_",
              ruleid:
                "WRKDcE0Bbq85gommPPxt1TWE439NribcBOUajw99xT+o8LotKkPr9+rRgWI1VO0yvTml2i2hCEBikqdUYpBdQICMSUE+oAXNhfzC1AvPpsdXVy0HLLTzOxy6vo4KsNKyH4iOhL3qCTzeoIR/oeSzY4TEtPO2CThrAiJMBJQHPOZVOijsN/0s5eElrsthvOXj",
              hsc: "2dbb2dd0cfc6e93e1ebec53a3e1257717140312f9ef95de0de61aacb797ba8db",
              sup_fare: "14165_45037.37_10685",
              cabb: "Free",
              bagage_info: "40kg Baggage allowance Business",
              chekbcode: "JBAG",
              psup_fare: "34150_78645",
            },
          ],
          pr: {
            bf: 34150,
            tf: 78645,
            tax: [
              { OT: 44495 },
              { attmkp: 0 },
              { tamkp: 0 },
              { stamkp: 0 },
              { attdisc: 0 },
              { tadisc: 0 },
              { stadisc: 0 },
            ],
            scur: "INR",
            ucur: "INR",
            exchange_rate: 1,
            sup_fare: "34150_78645",
            sup_cur_fare: "",
            cabb: "Free",
            chekb: "14kg INCLUDED",
            meals: "Chargeable",
            chanb: "Chargeable",
            ftyp: "Business",
            extra: "15_Business",
            chekbcode: "JBAG",
            chanb_info:
              "BEFOREDEPARTURE 0 hrs of departure Change of Flight is Free,NOSHOWUPTO 720 hrs of departure Change of Flight is Free,NOSHOWAFTER From 720 hrs before  of departure Change of Flight is Non-Refundable",
            canb: "Chargeable",
            cabb_info:
              "BEFOREDEPARTURE 0 hrs of departure Cancellation of Flight is Free,NOSHOWUPTO 720 hrs of departure Cancellation of Flight is Free,NOSHOWAFTER From 720 hrs before  of departure Cancellation of Flight is Non-Refundable",
            bagage_info: "40kg Baggage allowance Business",
            markupnames: [],
            ttf: 78645,
          },
          sup: "5",
          airlineList: "FZ",
          destorigins: [
            {
              tid: "L4uiV2F3vNtzIJXF_2",
              ref: 2,
              office_id: "",
              sdur: "6:45",
              re: "",
              sup: "5",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DXB",
                  dd: "2023-06-09T17:00:00",
                  ad: "2023-06-09T19:15:00",
                  rbd: "",
                  fn: "018",
                  trn: "",
                  eq: "73X",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "1", arr: "2" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161399,
                  flightTime: 75,
                  fdur: "1:15",
                },
                {
                  ref: 2,
                  da: "DXB",
                  ar: "HYD",
                  dd: "2023-06-09T20:50:00",
                  ad: "2023-06-10T02:15:00",
                  rbd: "",
                  fn: "435",
                  trn: "",
                  eq: "73B",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "2", arr: "" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161456,
                  flightTime: 235,
                  fdur: "3:55",
                },
              ],
              fareop: [
                {
                  fareKey: [],
                  pr: {
                    bf: 19985,
                    tf: 33607.63,
                    tax: [
                      { OT: 13622.630000000001 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    scur: "INR",
                    ucur: "INR",
                    exchange_rate: 1,
                    sup_fare: "19985_33607.63",
                    sup_cur_fare: "",
                    cabb: "Free",
                    chekb: "7kg INCLUDED",
                    meals: "Standard meal",
                    chanb: "Chargeable",
                    ftyp: "FLEX",
                    extra: "13_FLEX",
                    chekbcode: "BAGL",
                    chanb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Change of Flight is Free,NOSHOWUPTO 720 hrs of departure Change of Flight is Free,NOSHOWAFTER From 720 hrs before  of departure Change of Flight is Non-Refundable",
                    canb: "Chargeable",
                    cabb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Cancellation of Flight is Free,NOSHOWUPTO 720 hrs of departure Cancellation of Flight is Free,NOSHOWAFTER From 720 hrs before  of departure Cancellation of Flight is Non-Refundable",
                    bagage_info: "30kg BAG INCLUDED IN FARE",
                    markupnames: [],
                    ttf: 33607.63,
                  },
                  paxpr: [
                    {
                      bf: 19985,
                      tf: 33607.63,
                      tax: [
                        { OT: 13622.630000000001 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "ORML8IN5",
                      ptype: "ADT",
                      scur: "INR",
                      extra:
                        "1_24_O_1_9_0_tx-1764:1370:QA:0_2186:170:PZ:0_4246:6450:YQ:0_8947:0:BAGL:0_8966:1370:G4:0_9327:3122.63:K3:0_10386:120:ZR:0_11947:790:F6:0_13230:230:R9:0_",
                      ruleid:
                        "UmZa8+TnHpsso6JnVL8BflynIfJ/XB/vnt5lWewxSALdEVWgu3w8400pbatJN8v+dXRVoM0HZ8U9jxms9hE/Ws/RUwZbLq3+/lDxX34TkEnJ9351zGkK7QqSS78PWRvgnFFh9Gsw/dpcQuWfjFtRfA==",
                      hsc: "37cf71cffe1e0cb5a6c0e3171551608e1182be54fb041c3af5560a0fba807c3e",
                      sup_fare: "19985_33607.63_19535",
                      cabb: "Free",
                      bagage_info: "30kg BAG INCLUDED IN FARE",
                      chekbcode: "BAGL",
                      psup_fare: "19985_33607.63",
                    },
                  ],
                  uniqueId: "FLEX",
                  routeId: "c1b1r2gtzhh",
                  servicefee: [],
                  selected: true,
                },
              ],
              tktair: "FZ",
              tecst: "no",
              cl: "Economy",
              extra: 13916393,
              token:
                "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiYXBpdGF3ZmVlcSIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL2dpdmVubmFtZSI6IkFQSSBUQVdGRUVRIiwiZ3JvdXBBY2Nlc3MiOiIzLDM2LDExMiIsImlzQXB0Q2xudCI6IkZhbHNlIiwibG9jYXRpb24iOiI5OTk5IiwiY2hrTGNuIjoiIiwicmVzVG9rZW4iOiJkZWRhZDdkeWY4N2FuOTc0dGJxYjY0YmJ6ZjZlOTYwYzA5ZTE5NmRhZTg2MCIsImNsaWVudElQIjoiMTcyLjE5LjIzLjU0IiwicmVzQ2hhbm5lbElEIjoiNSIsInJlc0NoYW5uZWxOYW1lIjoiVFBBUEkiLCJkZWZhdWx0VXNlck5hbWUiOiJhcGl0YXdmZWVxIiwibmJmIjoxNjg1NTE1MjM3LCJleHAiOjE2ODU1MTc2MzcsImlzcyI6ImZseWR1YmFpLmNvbSIsImF1ZCI6IlRBV0ZFRVFUUlZMU19GWl9OUCJ9.rAW3YT-bX_-yADfvg3QkuPAbOJhkhRUN6HwoS_6PyiM",
              stop: 1,
            },
            {
              tid: "MO3ykUCKv9umuypx_2",
              ref: 2,
              office_id: "",
              sdur: "13:45",
              re: "",
              sup: "5",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DXB",
                  dd: "2023-06-09T10:00:00",
                  ad: "2023-06-09T12:15:00",
                  rbd: "",
                  fn: "002",
                  trn: "",
                  eq: "73X",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "1", arr: "2" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161402,
                  flightTime: 75,
                  fdur: "1:15",
                },
                {
                  ref: 2,
                  da: "DXB",
                  ar: "HYD",
                  dd: "2023-06-09T20:50:00",
                  ad: "2023-06-10T02:15:00",
                  rbd: "",
                  fn: "435",
                  trn: "",
                  eq: "73B",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "2", arr: "" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161456,
                  flightTime: 235,
                  fdur: "3:55",
                },
              ],
              fareop: [
                {
                  fareKey: [],
                  pr: {
                    bf: 19985,
                    tf: 33607.63,
                    tax: [
                      { OT: 13622.630000000001 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    scur: "INR",
                    ucur: "INR",
                    exchange_rate: 1,
                    sup_fare: "19985_33607.63",
                    sup_cur_fare: "",
                    cabb: "Free",
                    chekb: "7kg INCLUDED",
                    meals: "Standard meal",
                    chanb: "Chargeable",
                    ftyp: "FLEX",
                    extra: "13_FLEX",
                    chekbcode: "BAGL",
                    chanb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Change of Flight is Free,NOSHOWUPTO 720 hrs of departure Change of Flight is Free,NOSHOWAFTER From 720 hrs before  of departure Change of Flight is Non-Refundable",
                    canb: "Chargeable",
                    cabb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Cancellation of Flight is Free,NOSHOWUPTO 720 hrs of departure Cancellation of Flight is Free,NOSHOWAFTER From 720 hrs before  of departure Cancellation of Flight is Non-Refundable",
                    bagage_info: "30kg BAG INCLUDED IN FARE",
                    markupnames: [],
                    ttf: 33607.63,
                  },
                  paxpr: [
                    {
                      bf: 19985,
                      tf: 33607.63,
                      tax: [
                        { OT: 13622.630000000001 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "ORML8IN5",
                      ptype: "ADT",
                      scur: "INR",
                      extra:
                        "1_56_O_1_9_0_tx-1764:1370:QA:0_2186:170:PZ:0_4246:6450:YQ:0_8947:0:BAGL:0_8966:1370:G4:0_9327:3122.63:K3:0_10386:120:ZR:0_11947:790:F6:0_13230:230:R9:0_",
                      ruleid:
                        "3kYytqDPo+4Xj2W/0k/Af1ynIfJ/XB/vnt5lWewxSALdEVWgu3w8400pbatJN8v+dXRVoM0HZ8U9jxms9hE/Ws/RUwZbLq3+/lDxX34TkEnJ9351zGkK7QqSS78PWRvgnFFh9Gsw/dpcQuWfjFtRfA==",
                      hsc: "4623ae2d2204f287b04acffd743f9be0006ebab168dc1b1d14fea9d09134555c",
                      sup_fare: "19985_33607.63_19535",
                      cabb: "Free",
                      bagage_info: "30kg BAG INCLUDED IN FARE",
                      chekbcode: "BAGL",
                      psup_fare: "19985_33607.63",
                    },
                  ],
                  uniqueId: "FLEX",
                  routeId: "ahejof0i2e",
                  servicefee: [],
                  selected: true,
                },
              ],
              tktair: "FZ",
              tecst: "no",
              cl: "Economy",
              extra: 13915297,
              token:
                "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiYXBpdGF3ZmVlcSIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL2dpdmVubmFtZSI6IkFQSSBUQVdGRUVRIiwiZ3JvdXBBY2Nlc3MiOiIzLDM2LDExMiIsImlzQXB0Q2xudCI6IkZhbHNlIiwibG9jYXRpb24iOiI5OTk5IiwiY2hrTGNuIjoiIiwicmVzVG9rZW4iOiJkZWRhZDdkeWY4N2FuOTc0dGJxYjY0YmJ6ZjZlOTYwYzA5ZTE5NmRhZTg2MCIsImNsaWVudElQIjoiMTcyLjE5LjIzLjU0IiwicmVzQ2hhbm5lbElEIjoiNSIsInJlc0NoYW5uZWxOYW1lIjoiVFBBUEkiLCJkZWZhdWx0VXNlck5hbWUiOiJhcGl0YXdmZWVxIiwibmJmIjoxNjg1NTE1MjM3LCJleHAiOjE2ODU1MTc2MzcsImlzcyI6ImZseWR1YmFpLmNvbSIsImF1ZCI6IlRBV0ZFRVFUUlZMU19GWl9OUCJ9.rAW3YT-bX_-yADfvg3QkuPAbOJhkhRUN6HwoS_6PyiM",
              stop: 1,
            },
          ],
          servicefee: [],
        },
        {
          srcorigns: [
            {
              tid: "GAFLsEF42tMMWFWz_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "04:10",
              re: "Refundable Before Departure",
              stop: 0,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "QR",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DOH",
                  dd: "2023-06-07T21:55:00",
                  ad: "2023-06-07T23:35:00",
                  rbd: "N",
                  fn: "4779",
                  eq: "320",
                  ac: "QR",
                  ma: "QR",
                  seat: "9",
                  oa: "6E",
                  fa: [],
                  fdur: "04:10",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
              ],
              flightNumbers: "4779",
              bgd: [{ qun: "25", wt: "kg" }],
            },
            {
              tid: "MZUmiT8eXfMmWZUP_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "04:10",
              re: "Refundable Before Departure",
              stop: 0,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "QR",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DOH",
                  dd: "2023-06-07T17:05:00",
                  ad: "2023-06-07T18:45:00",
                  rbd: "N",
                  fn: "4777",
                  eq: "320",
                  ac: "QR",
                  ma: "QR",
                  seat: "9",
                  oa: "6E",
                  fa: [],
                  fdur: "04:10",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
              ],
              flightNumbers: "4777",
              bgd: [{ qun: "25", wt: "kg" }],
            },
            {
              tid: "I7hvnZdE5JLmulpD_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "04:10",
              re: "Refundable Before Departure",
              stop: 0,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "QR",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DOH",
                  dd: "2023-06-07T04:10:00",
                  ad: "2023-06-07T05:50:00",
                  rbd: "N",
                  fn: "4775",
                  eq: "320",
                  ac: "QR",
                  ma: "QR",
                  seat: "9",
                  oa: "6E",
                  fa: [],
                  fdur: "04:10",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
              ],
              flightNumbers: "4775",
              bgd: [{ qun: "25", wt: "kg" }],
            },
          ],
          destorigins: [
            {
              tid: "aFZR2voXDvGBwBDx_2",
              ref: 2,
              office_id: "DOHQR28IH",
              sdur: "04:15",
              re: "Refundable Before Departure",
              stop: 0,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "QR",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "HYD",
                  dd: "2023-06-09T19:45:00",
                  ad: "2023-06-10T02:30:00",
                  rbd: "V",
                  fn: "4774",
                  eq: "320",
                  ac: "QR",
                  ma: "QR",
                  seat: "9",
                  oa: "6E",
                  fa: [],
                  fdur: "04:15",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
              ],
              flightNumbers: "4774",
              bgd: [{ qun: "30", wt: "kg" }],
            },
            {
              tid: "0i0cd9XNvdGxXg3t_2",
              ref: 2,
              office_id: "DOHQR28IH",
              sdur: "04:15",
              re: "Refundable Before Departure",
              stop: 0,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "QR",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "HYD",
                  dd: "2023-06-09T08:15:00",
                  ad: "2023-06-09T15:00:00",
                  rbd: "V",
                  fn: "4776",
                  eq: "320",
                  ac: "QR",
                  ma: "QR",
                  seat: "9",
                  oa: "6E",
                  fa: [],
                  fdur: "04:15",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
              ],
              flightNumbers: "4776",
              bgd: [{ qun: "30", wt: "kg" }],
            },
            {
              tid: "I7hvnZdE5JLmulpD_2",
              ref: 2,
              office_id: "DOHQR28IH",
              sdur: "04:15",
              re: "Refundable Before Departure",
              stop: 0,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "QR",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "HYD",
                  dd: "2023-06-09T02:00:00",
                  ad: "2023-06-09T08:45:00",
                  rbd: "V",
                  fn: "4778",
                  eq: "320",
                  ac: "QR",
                  ma: "QR",
                  seat: "9",
                  oa: "6E",
                  fa: [],
                  fdur: "04:15",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
              ],
              flightNumbers: "4778",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          pr: {
            bf: 44105.6,
            tf: 75241.6,
            tax: [
              { YQ: 19712 },
              { YR: 1792 },
              { OT: 9632 },
              { dealmkp: 0 },
              { attmkp: 4009.6 },
              { tamkp: 0 },
              { stamkp: 0 },
              { attdisc: 0 },
              { tadisc: 0 },
              { stadisc: 0 },
            ],
            scur: "QAR",
            ucur: "INR",
            exchange_rate: 22.4,
            sup_fare: "1790_3180",
            markupnames: [{ code: "MTA", name: "LaxmiAprilMarkup" }],
          },
          paxpr: [
            {
              tf: 75241.6,
              tax: [
                { YQ: 19712 },
                { YR: 1792 },
                { OT: 9632 },
                { attmkp: 4009.6 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              bf: 44105.6,
              fbc: "NLR4I1RX",
              fareType: "RP",
              pusup_fare: "1790_3180",
              psup_fare: "40096_71232",
              ptype: "ADT",
            },
          ],
          sup: "0",
          airlinesList: "QR",
          servicefee: [
            {
              type: "ATT",
              adt: 4410.56,
              chd: 0,
              inf: 0,
              servicefee_name: "LaxmiServieApril",
              apl_on: "Per Ticket",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 4410.56,
              sup_fee: 0,
            },
            {
              type: "TA",
              adt: 0,
              chd: 0,
              inf: 0,
              apl_on: "",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 0,
              sup_fee: 4410.56,
            },
          ],
        },
        {
          srcorigns: [
            {
              tid: "sKDrvf5mTpmDWeSt_1",
              ref: 1,
              office_id: "",
              sdur: "8:25",
              re: "",
              sup: "5",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DXB",
                  dd: "2023-06-07T03:00:00",
                  ad: "2023-06-07T05:30:00",
                  rbd: "",
                  fn: "436",
                  trn: "",
                  eq: "73B",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "", arr: "2" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161457,
                  flightTime: 240,
                  fdur: "4:0",
                },
                {
                  ref: 2,
                  da: "DXB",
                  ar: "DOH",
                  dd: "2023-06-07T08:45:00",
                  ad: "2023-06-07T08:55:00",
                  rbd: "",
                  fn: "001",
                  trn: "",
                  eq: "73X",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "2", arr: "1" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161405,
                  flightTime: 70,
                  fdur: "1:10",
                },
              ],
              fareop: [
                {
                  fareKey: [],
                  pr: {
                    bf: 13255,
                    tf: 24524.88,
                    tax: [
                      { OT: 11269.880000000001 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    scur: "INR",
                    ucur: "INR",
                    exchange_rate: 1,
                    sup_fare: "13255_24524.88",
                    sup_cur_fare: "",
                    cabb: "Chargeable",
                    chekb: "7kg INCLUDED",
                    meals: "Chargeable",
                    chanb: "Non Refundable",
                    ftyp: "LITE",
                    extra: "11_LITE",
                    chekbcode: "",
                    chanb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Change of Flight is Non-Refundable,NOSHOW From 0 hrs before  of departure Change of Flight is Non-Refundable",
                    canb: "Non Refundable",
                    cabb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Cancellation of Flight is Non-Refundable,NOSHOW From 0 hrs before  of departure Cancellation of Flight is Non-Refundable",
                    bagage_info: "Chargeable",
                    markupnames: [],
                    ttf: 24524.88,
                  },
                  paxpr: [
                    {
                      bf: 13255,
                      tf: 24524.88,
                      tax: [
                        { OT: 11269.880000000001 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "OR6IN5",
                      ptype: "ADT",
                      scur: "INR",
                      extra:
                        "1_25_O_1_9_0_tx-2186:170:PZ:0_4246:6450:YQ:0_9327:964.88:K3:0_10386:120:ZR:0_11947:790:F6:0_12006:1170:P2:0_12434:1605:IN:0_",
                      ruleid:
                        "7wW4m8cFUAwner539uExLxZ+CHIn9HNL+12RdyPuUypN3Vrd+8Of0oA7a4N7g8CvZItP8LZIcrAqzBeCOoIuvjLwhemGenh8Kvds7rAxHg7lu4lkJ9t+YgS8Pv3j40Na",
                      hsc: "3cbfda3861fcc38c6783a5b4596cbdcebee3172b10be89f8f70975957b001d70",
                      sup_fare: "13255_24524.88_12805",
                      cabb: "Chargeable",
                      bagage_info: "Chargeable",
                      chekbcode: "",
                      psup_fare: "13255_24524.88",
                    },
                  ],
                  uniqueId: "LITE",
                  routeId: "sizbqg6kz9",
                  servicefee: [],
                  selected: true,
                },
              ],
              tktair: "FZ",
              tecst: "no",
              cl: "Economy",
              extra: 13919293,
              token:
                "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiYXBpdGF3ZmVlcSIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL2dpdmVubmFtZSI6IkFQSSBUQVdGRUVRIiwiZ3JvdXBBY2Nlc3MiOiIzLDM2LDExMiIsImlzQXB0Q2xudCI6IkZhbHNlIiwibG9jYXRpb24iOiI5OTk5IiwiY2hrTGNuIjoiIiwicmVzVG9rZW4iOiJkZWRhZDdkeWY4N2FuOTc0dGJxYjY0YmJ6ZjZlOTYwYzA5ZTE5NmRhZTg2MCIsImNsaWVudElQIjoiMTcyLjE5LjIzLjU0IiwicmVzQ2hhbm5lbElEIjoiNSIsInJlc0NoYW5uZWxOYW1lIjoiVFBBUEkiLCJkZWZhdWx0VXNlck5hbWUiOiJhcGl0YXdmZWVxIiwibmJmIjoxNjg1NTE1MjM3LCJleHAiOjE2ODU1MTc2MzcsImlzcyI6ImZseWR1YmFpLmNvbSIsImF1ZCI6IlRBV0ZFRVFUUlZMU19GWl9OUCJ9.rAW3YT-bX_-yADfvg3QkuPAbOJhkhRUN6HwoS_6PyiM",
              stop: 1,
            },
            {
              tid: "XoNpQxsWOTOx0iw4_1",
              ref: 1,
              office_id: "",
              sdur: "15:25",
              re: "",
              sup: "5",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DXB",
                  dd: "2023-06-07T03:00:00",
                  ad: "2023-06-07T05:30:00",
                  rbd: "",
                  fn: "436",
                  trn: "",
                  eq: "73B",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "", arr: "2" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161457,
                  flightTime: 240,
                  fdur: "4:0",
                },
                {
                  ref: 2,
                  da: "DXB",
                  ar: "DOH",
                  dd: "2023-06-07T15:45:00",
                  ad: "2023-06-07T15:55:00",
                  rbd: "",
                  fn: "017",
                  trn: "",
                  eq: "73X",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "2", arr: "1" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161400,
                  flightTime: 70,
                  fdur: "1:10",
                },
              ],
              fareop: [
                {
                  fareKey: [],
                  pr: {
                    bf: 13255,
                    tf: 24524.88,
                    tax: [
                      { OT: 11269.880000000001 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    scur: "INR",
                    ucur: "INR",
                    exchange_rate: 1,
                    sup_fare: "13255_24524.88",
                    sup_cur_fare: "",
                    cabb: "Chargeable",
                    chekb: "7kg INCLUDED",
                    meals: "Chargeable",
                    chanb: "Non Refundable",
                    ftyp: "LITE",
                    extra: "11_LITE",
                    chekbcode: "",
                    chanb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Change of Flight is Non-Refundable,NOSHOW From 0 hrs before  of departure Change of Flight is Non-Refundable",
                    canb: "Non Refundable",
                    cabb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Cancellation of Flight is Non-Refundable,NOSHOW From 0 hrs before  of departure Cancellation of Flight is Non-Refundable",
                    bagage_info: "Chargeable",
                    markupnames: [],
                    ttf: 24524.88,
                  },
                  paxpr: [
                    {
                      bf: 13255,
                      tf: 24524.88,
                      tax: [
                        { OT: 11269.880000000001 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "OR6IN5",
                      ptype: "ADT",
                      scur: "INR",
                      extra:
                        "1_89_O_1_9_0_tx-2186:170:PZ:0_4246:6450:YQ:0_9327:964.88:K3:0_10386:120:ZR:0_11947:790:F6:0_12006:1170:P2:0_12434:1605:IN:0_",
                      ruleid:
                        "D3LgeqAyCWdMQu3fveBkGBZ+CHIn9HNL+12RdyPuUypN3Vrd+8Of0oA7a4N7g8CvZItP8LZIcrAqzBeCOoIuvjLwhemGenh8Kvds7rAxHg7lu4lkJ9t+YgS8Pv3j40Na",
                      hsc: "f09273c293965c367ce0a73f41398eb79d568df775488757fbf8def6ae2c71df",
                      sup_fare: "13255_24524.88_12805",
                      cabb: "Chargeable",
                      bagage_info: "Chargeable",
                      chekbcode: "",
                      psup_fare: "13255_24524.88",
                    },
                  ],
                  uniqueId: "LITE",
                  routeId: "wahwj9cpfpg",
                  servicefee: [],
                  selected: true,
                },
              ],
              tktair: "FZ",
              tecst: "no",
              cl: "Economy",
              extra: 13919296,
              token:
                "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiYXBpdGF3ZmVlcSIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL2dpdmVubmFtZSI6IkFQSSBUQVdGRUVRIiwiZ3JvdXBBY2Nlc3MiOiIzLDM2LDExMiIsImlzQXB0Q2xudCI6IkZhbHNlIiwibG9jYXRpb24iOiI5OTk5IiwiY2hrTGNuIjoiIiwicmVzVG9rZW4iOiJkZWRhZDdkeWY4N2FuOTc0dGJxYjY0YmJ6ZjZlOTYwYzA5ZTE5NmRhZTg2MCIsImNsaWVudElQIjoiMTcyLjE5LjIzLjU0IiwicmVzQ2hhbm5lbElEIjoiNSIsInJlc0NoYW5uZWxOYW1lIjoiVFBBUEkiLCJkZWZhdWx0VXNlck5hbWUiOiJhcGl0YXdmZWVxIiwibmJmIjoxNjg1NTE1MjM3LCJleHAiOjE2ODU1MTc2MzcsImlzcyI6ImZseWR1YmFpLmNvbSIsImF1ZCI6IlRBV0ZFRVFUUlZMU19GWl9OUCJ9.rAW3YT-bX_-yADfvg3QkuPAbOJhkhRUN6HwoS_6PyiM",
              stop: 1,
            },
            {
              tid: "P0fMAUh2QmKGFT5w_1",
              ref: 1,
              office_id: "",
              sdur: "19:25",
              re: "",
              sup: "5",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DXB",
                  dd: "2023-06-07T03:00:00",
                  ad: "2023-06-07T05:30:00",
                  rbd: "",
                  fn: "436",
                  trn: "",
                  eq: "73B",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "", arr: "2" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161457,
                  flightTime: 240,
                  fdur: "4:0",
                },
                {
                  ref: 2,
                  da: "DXB",
                  ar: "DOH",
                  dd: "2023-06-07T19:45:00",
                  ad: "2023-06-07T19:55:00",
                  rbd: "",
                  fn: "005",
                  trn: "",
                  eq: "73X",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "2", arr: "1" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161381,
                  flightTime: 70,
                  fdur: "1:10",
                },
              ],
              fareop: [
                {
                  fareKey: [],
                  pr: {
                    bf: 13255,
                    tf: 24524.88,
                    tax: [
                      { OT: 11269.880000000001 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    scur: "INR",
                    ucur: "INR",
                    exchange_rate: 1,
                    sup_fare: "13255_24524.88",
                    sup_cur_fare: "",
                    cabb: "Chargeable",
                    chekb: "7kg INCLUDED",
                    meals: "Chargeable",
                    chanb: "Non Refundable",
                    ftyp: "LITE",
                    extra: "11_LITE",
                    chekbcode: "",
                    chanb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Change of Flight is Non-Refundable,NOSHOW From 0 hrs before  of departure Change of Flight is Non-Refundable",
                    canb: "Non Refundable",
                    cabb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Cancellation of Flight is Non-Refundable,NOSHOW From 0 hrs before  of departure Cancellation of Flight is Non-Refundable",
                    bagage_info: "Chargeable",
                    markupnames: [],
                    ttf: 24524.88,
                  },
                  paxpr: [
                    {
                      bf: 13255,
                      tf: 24524.88,
                      tax: [
                        { OT: 11269.880000000001 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "OR6IN5",
                      ptype: "ADT",
                      scur: "INR",
                      extra:
                        "1_121_O_1_9_0_tx-2186:170:PZ:0_4246:6450:YQ:0_9327:964.88:K3:0_10386:120:ZR:0_11947:790:F6:0_12006:1170:P2:0_12434:1605:IN:0_",
                      ruleid:
                        "yBIO+tiPN4N48psjFt8xdCH5cdRtjzMqmtc6Ydt53ymhQozXlONozKDeF7aO/DbGAO8K6tXzJ16Rfs5fUX41PoAzc0DAexpKMcT6yt4vWi67ueiX+7Xh5u3qyxSJhMOZBQGHoM3lqYcsurCRq3PlUw==",
                      hsc: "05f809c8fec7c08a9a035856fbaa224915883a1649206ed7831eb9734cce2d0c",
                      sup_fare: "13255_24524.88_12805",
                      cabb: "Chargeable",
                      bagage_info: "Chargeable",
                      chekbcode: "",
                      psup_fare: "13255_24524.88",
                    },
                  ],
                  uniqueId: "LITE",
                  routeId: "445r7ij9gy",
                  servicefee: [],
                  selected: true,
                },
              ],
              tktair: "FZ",
              tecst: "no",
              cl: "Economy",
              extra: 13919294,
              token:
                "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiYXBpdGF3ZmVlcSIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL2dpdmVubmFtZSI6IkFQSSBUQVdGRUVRIiwiZ3JvdXBBY2Nlc3MiOiIzLDM2LDExMiIsImlzQXB0Q2xudCI6IkZhbHNlIiwibG9jYXRpb24iOiI5OTk5IiwiY2hrTGNuIjoiIiwicmVzVG9rZW4iOiJkZWRhZDdkeWY4N2FuOTc0dGJxYjY0YmJ6ZjZlOTYwYzA5ZTE5NmRhZTg2MCIsImNsaWVudElQIjoiMTcyLjE5LjIzLjU0IiwicmVzQ2hhbm5lbElEIjoiNSIsInJlc0NoYW5uZWxOYW1lIjoiVFBBUEkiLCJkZWZhdWx0VXNlck5hbWUiOiJhcGl0YXdmZWVxIiwibmJmIjoxNjg1NTE1MjM3LCJleHAiOjE2ODU1MTc2MzcsImlzcyI6ImZseWR1YmFpLmNvbSIsImF1ZCI6IlRBV0ZFRVFUUlZMU19GWl9OUCJ9.rAW3YT-bX_-yADfvg3QkuPAbOJhkhRUN6HwoS_6PyiM",
              stop: 1,
            },
          ],
          paxpr: [
            {
              bf: 44750,
              tf: 85525,
              tax: [
                { OT: 40775 },
                { attmkp: 0 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              fbc: "OR6IN5",
              ptype: "ADT",
              scur: "INR",
              extra:
                "1_25_O_1_9_0_tx-2186:170:PZ:0_4246:6450:YQ:0_9327:964.88:K3:0_10386:120:ZR:0_11947:790:F6:0_12006:1170:P2:0_12434:1605:IN:0_",
              ruleid:
                "7wW4m8cFUAwner539uExLxZ+CHIn9HNL+12RdyPuUypN3Vrd+8Of0oA7a4N7g8CvZItP8LZIcrAqzBeCOoIuvjLwhemGenh8Kvds7rAxHg7lu4lkJ9t+YgS8Pv3j40Na",
              hsc: "3cbfda3861fcc38c6783a5b4596cbdcebee3172b10be89f8f70975957b001d70",
              sup_fare: "13255_24524.88_12805",
              cabb: "Chargeable",
              bagage_info: "Chargeable",
              chekbcode: "",
              psup_fare: "44750_85525",
            },
          ],
          pr: {
            bf: 44750,
            tf: 85525,
            tax: [
              { OT: 40775 },
              { attmkp: 0 },
              { tamkp: 0 },
              { stamkp: 0 },
              { attdisc: 0 },
              { tadisc: 0 },
              { stadisc: 0 },
            ],
            scur: "INR",
            ucur: "INR",
            exchange_rate: 1,
            sup_fare: "44750_85525",
            sup_cur_fare: "",
            cabb: "Chargeable",
            chekb: "7kg INCLUDED",
            meals: "Chargeable",
            chanb: "Non Refundable",
            ftyp: "LITE",
            extra: "11_LITE",
            chekbcode: "",
            chanb_info:
              "BEFOREDEPARTURE 0 hrs of departure Change of Flight is Non-Refundable,NOSHOW From 0 hrs before  of departure Change of Flight is Non-Refundable",
            canb: "Non Refundable",
            cabb_info:
              "BEFOREDEPARTURE 0 hrs of departure Cancellation of Flight is Non-Refundable,NOSHOW From 0 hrs before  of departure Cancellation of Flight is Non-Refundable",
            bagage_info: "Chargeable",
            markupnames: [],
            ttf: 85525,
          },
          sup: "5",
          airlineList: "FZ",
          destorigins: [
            {
              tid: "y1UobvbTZQeQObJk_2",
              ref: 2,
              office_id: "",
              sdur: "6:45",
              re: "",
              sup: "5",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DXB",
                  dd: "2023-06-09T17:00:00",
                  ad: "2023-06-09T19:15:00",
                  rbd: "",
                  fn: "018",
                  trn: "",
                  eq: "73X",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "1", arr: "2" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161399,
                  flightTime: 75,
                  fdur: "1:15",
                },
                {
                  ref: 2,
                  da: "DXB",
                  ar: "HYD",
                  dd: "2023-06-09T20:50:00",
                  ad: "2023-06-10T02:15:00",
                  rbd: "",
                  fn: "435",
                  trn: "",
                  eq: "73B",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "2", arr: "" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161456,
                  flightTime: 235,
                  fdur: "3:55",
                },
              ],
              fareop: [
                {
                  fareKey: [],
                  pr: {
                    bf: 31495,
                    tf: 61000.12,
                    tax: [
                      { OT: 29505.12 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    scur: "INR",
                    ucur: "INR",
                    exchange_rate: 1,
                    sup_fare: "31495_61000.12",
                    sup_cur_fare: "",
                    cabb: "Free",
                    chekb: "14kg INCLUDED",
                    meals: "Chargeable",
                    chanb: "Chargeable",
                    ftyp: "Business",
                    extra: "15_Business",
                    chekbcode: "JBAG",
                    chanb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Change of Flight is Free,NOSHOWUPTO 720 hrs of departure Change of Flight is Free,NOSHOWAFTER From 720 hrs before  of departure Change of Flight is Non-Refundable",
                    canb: "Chargeable",
                    cabb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Cancellation of Flight is Free,NOSHOWUPTO 720 hrs of departure Cancellation of Flight is Free,NOSHOWAFTER From 720 hrs before  of departure Cancellation of Flight is Non-Refundable",
                    bagage_info: "40kg Baggage allowance Business",
                    markupnames: [],
                    ttf: 61000.12,
                  },
                  paxpr: [
                    {
                      bf: 31495,
                      tf: 61000.12,
                      tax: [
                        { OT: 29505.12 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "CRM9IN2",
                      ptype: "ADT",
                      scur: "INR",
                      extra:
                        "1_26_C_1_3_0_tx-1764:1370:QA:0_2186:170:PZ:0_4246:22970:YQ:0_6544:0:JBAG:0_8966:1370:G4:0_9327:2485.12:K3:0_10386:120:ZR:0_11947:790:F6:0_13230:230:R9:0_",
                      ruleid:
                        "vSBv8UMSs8nB4Rg4OBG4vJMUYXFX0odry/AcSKbL2hBXbD3FLZ5ZejAuNYMbABAoyAFAYCcKjnEbjqUoRC2w3mDGJNH37aAsxUou/hEUve1ewJ9aVhK57wibWY2PIn24f4tv7xijw4oXgcRHgmFw0E3Dg72VJaVpJZuTCj214Mb6KesHnLDJHQwOhfTxZtbdeXH1hDuwDQ+MlHme53FHEA==",
                      hsc: "521db9a9545c28d0c31d1c8badf6495415b95f5e263cee90e792d0b8071bcce6",
                      sup_fare: "31495_61000.12_26585",
                      cabb: "Free",
                      bagage_info: "40kg Baggage allowance Business",
                      chekbcode: "JBAG",
                      psup_fare: "31495_61000.12",
                    },
                  ],
                  uniqueId: "Business",
                  routeId: "35ue71ss3d",
                  servicefee: [],
                  selected: true,
                },
              ],
              tktair: "FZ",
              tecst: "no",
              cl: "Economy",
              extra: 13916393,
              token:
                "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiYXBpdGF3ZmVlcSIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL2dpdmVubmFtZSI6IkFQSSBUQVdGRUVRIiwiZ3JvdXBBY2Nlc3MiOiIzLDM2LDExMiIsImlzQXB0Q2xudCI6IkZhbHNlIiwibG9jYXRpb24iOiI5OTk5IiwiY2hrTGNuIjoiIiwicmVzVG9rZW4iOiJkZWRhZDdkeWY4N2FuOTc0dGJxYjY0YmJ6ZjZlOTYwYzA5ZTE5NmRhZTg2MCIsImNsaWVudElQIjoiMTcyLjE5LjIzLjU0IiwicmVzQ2hhbm5lbElEIjoiNSIsInJlc0NoYW5uZWxOYW1lIjoiVFBBUEkiLCJkZWZhdWx0VXNlck5hbWUiOiJhcGl0YXdmZWVxIiwibmJmIjoxNjg1NTE1MjM3LCJleHAiOjE2ODU1MTc2MzcsImlzcyI6ImZseWR1YmFpLmNvbSIsImF1ZCI6IlRBV0ZFRVFUUlZMU19GWl9OUCJ9.rAW3YT-bX_-yADfvg3QkuPAbOJhkhRUN6HwoS_6PyiM",
              stop: 1,
            },
            {
              tid: "JVokmZSV6t4ez1kf_2",
              ref: 2,
              office_id: "",
              sdur: "13:45",
              re: "",
              sup: "5",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DXB",
                  dd: "2023-06-09T10:00:00",
                  ad: "2023-06-09T12:15:00",
                  rbd: "",
                  fn: "002",
                  trn: "",
                  eq: "73X",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "1", arr: "2" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161402,
                  flightTime: 75,
                  fdur: "1:15",
                },
                {
                  ref: 2,
                  da: "DXB",
                  ar: "HYD",
                  dd: "2023-06-09T20:50:00",
                  ad: "2023-06-10T02:15:00",
                  rbd: "",
                  fn: "435",
                  trn: "",
                  eq: "73B",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "2", arr: "" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161456,
                  flightTime: 235,
                  fdur: "3:55",
                },
              ],
              fareop: [
                {
                  fareKey: [],
                  pr: {
                    bf: 31495,
                    tf: 61000.12,
                    tax: [
                      { OT: 29505.12 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    scur: "INR",
                    ucur: "INR",
                    exchange_rate: 1,
                    sup_fare: "31495_61000.12",
                    sup_cur_fare: "",
                    cabb: "Free",
                    chekb: "14kg INCLUDED",
                    meals: "Chargeable",
                    chanb: "Chargeable",
                    ftyp: "Business",
                    extra: "15_Business",
                    chekbcode: "JBAG",
                    chanb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Change of Flight is Free,NOSHOWUPTO 720 hrs of departure Change of Flight is Free,NOSHOWAFTER From 720 hrs before  of departure Change of Flight is Non-Refundable",
                    canb: "Chargeable",
                    cabb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Cancellation of Flight is Free,NOSHOWUPTO 720 hrs of departure Cancellation of Flight is Free,NOSHOWAFTER From 720 hrs before  of departure Cancellation of Flight is Non-Refundable",
                    bagage_info: "40kg Baggage allowance Business",
                    markupnames: [],
                    ttf: 61000.12,
                  },
                  paxpr: [
                    {
                      bf: 31495,
                      tf: 61000.12,
                      tax: [
                        { OT: 29505.12 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "CRM9IN2",
                      ptype: "ADT",
                      scur: "INR",
                      extra:
                        "1_58_C_1_3_0_tx-1764:1370:QA:0_2186:170:PZ:0_4246:22970:YQ:0_6544:0:JBAG:0_8966:1370:G4:0_9327:2485.12:K3:0_10386:120:ZR:0_11947:790:F6:0_13230:230:R9:0_",
                      ruleid:
                        "jybkg7elXjxhWUAyAkKH65MUYXFX0odry/AcSKbL2hBXbD3FLZ5ZejAuNYMbABAoyAFAYCcKjnEbjqUoRC2w3mDGJNH37aAsxUou/hEUve1ewJ9aVhK57wibWY2PIn24f4tv7xijw4oXgcRHgmFw0E3Dg72VJaVpJZuTCj214Mb6KesHnLDJHQwOhfTxZtbdeXH1hDuwDQ+MlHme53FHEA==",
                      hsc: "b3ff0705c246bb77e097bab3c0610e236d352fad62f0de3282ffc729b592f912",
                      sup_fare: "31495_61000.12_26585",
                      cabb: "Free",
                      bagage_info: "40kg Baggage allowance Business",
                      chekbcode: "JBAG",
                      psup_fare: "31495_61000.12",
                    },
                  ],
                  uniqueId: "Business",
                  routeId: "2blofb8y9e",
                  servicefee: [],
                  selected: true,
                },
              ],
              tktair: "FZ",
              tecst: "no",
              cl: "Economy",
              extra: 13915297,
              token:
                "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiYXBpdGF3ZmVlcSIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL2dpdmVubmFtZSI6IkFQSSBUQVdGRUVRIiwiZ3JvdXBBY2Nlc3MiOiIzLDM2LDExMiIsImlzQXB0Q2xudCI6IkZhbHNlIiwibG9jYXRpb24iOiI5OTk5IiwiY2hrTGNuIjoiIiwicmVzVG9rZW4iOiJkZWRhZDdkeWY4N2FuOTc0dGJxYjY0YmJ6ZjZlOTYwYzA5ZTE5NmRhZTg2MCIsImNsaWVudElQIjoiMTcyLjE5LjIzLjU0IiwicmVzQ2hhbm5lbElEIjoiNSIsInJlc0NoYW5uZWxOYW1lIjoiVFBBUEkiLCJkZWZhdWx0VXNlck5hbWUiOiJhcGl0YXdmZWVxIiwibmJmIjoxNjg1NTE1MjM3LCJleHAiOjE2ODU1MTc2MzcsImlzcyI6ImZseWR1YmFpLmNvbSIsImF1ZCI6IlRBV0ZFRVFUUlZMU19GWl9OUCJ9.rAW3YT-bX_-yADfvg3QkuPAbOJhkhRUN6HwoS_6PyiM",
              stop: 1,
            },
          ],
          servicefee: [],
        },
        {
          srcorigns: [
            {
              tid: "hM7rzkwNLqALdrip_1",
              ref: 1,
              office_id: "",
              sdur: "8:25",
              re: "",
              sup: "5",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DXB",
                  dd: "2023-06-07T03:00:00",
                  ad: "2023-06-07T05:30:00",
                  rbd: "",
                  fn: "436",
                  trn: "",
                  eq: "73B",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "", arr: "2" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161457,
                  flightTime: 240,
                  fdur: "4:0",
                },
                {
                  ref: 2,
                  da: "DXB",
                  ar: "DOH",
                  dd: "2023-06-07T08:45:00",
                  ad: "2023-06-07T08:55:00",
                  rbd: "",
                  fn: "001",
                  trn: "",
                  eq: "73X",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "2", arr: "1" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161405,
                  flightTime: 70,
                  fdur: "1:10",
                },
              ],
              fareop: [
                {
                  fareKey: [],
                  pr: {
                    bf: 14455,
                    tf: 25784.89,
                    tax: [
                      { OT: 11329.89 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    scur: "INR",
                    ucur: "INR",
                    exchange_rate: 1,
                    sup_fare: "14455_25784.89",
                    sup_cur_fare: "",
                    cabb: "Free",
                    chekb: "7kg INCLUDED",
                    meals: "Standard meal",
                    chanb: "Chargeable",
                    ftyp: "VALUE",
                    extra: "12_VALUE",
                    chekbcode: "BAGL",
                    chanb_info:
                      "UPTO 24 hrs of departure Change of Flight is Free,WITHIN From 0-24 hrs of departure Change of Flight is Non-Refundable,NOSHOW From 0 hrs before  of departure Change of Flight is Non-Refundable",
                    canb: "Chargeable",
                    cabb_info:
                      "UPTO 24 hrs of departure Cancellation of Flight is Free,WITHIN From 0-24 hrs of departure Cancellation of Flight is Non-Refundable,NOSHOW From 0 hrs before  of departure Cancellation of Flight is Non-Refundable",
                    bagage_info: "30kg BAG INCLUDED IN FARE",
                    markupnames: [],
                    ttf: 25784.89,
                  },
                  paxpr: [
                    {
                      bf: 14455,
                      tf: 25784.89,
                      tax: [
                        { OT: 11329.89 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "ORL7IN5",
                      ptype: "ADT",
                      scur: "INR",
                      extra:
                        "1_27_O_1_9_0_tx-2186:170:PZ:0_4246:6450:YQ:0_8947:0:BAGL:0_9327:1024.89:K3:0_10386:120:ZR:0_11947:790:F6:0_12006:1170:P2:0_12434:1605:IN:0_",
                      ruleid:
                        "HlEtW4LGcjpnjrD06hKmbBZ+CHIn9HNL+12RdyPuUypN3Vrd+8Of0oA7a4N7g8CvZItP8LZIcrAqzBeCOoIuvjLwhemGenh8Kvds7rAxHg6X710uw+p5f6/y1zghrpgJ",
                      hsc: "32f1fcf48f3e7d92eb134ca240ccbbb6da9d994f7a9f4ea01977aa5c67702595",
                      sup_fare: "14455_25784.89_14005",
                      cabb: "Free",
                      bagage_info: "30kg BAG INCLUDED IN FARE",
                      chekbcode: "BAGL",
                      psup_fare: "14455_25784.89",
                    },
                  ],
                  uniqueId: "VALUE",
                  routeId: "m2uudx0ujdh",
                  servicefee: [],
                  selected: true,
                },
              ],
              tktair: "FZ",
              tecst: "no",
              cl: "Economy",
              extra: 13919293,
              token:
                "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiYXBpdGF3ZmVlcSIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL2dpdmVubmFtZSI6IkFQSSBUQVdGRUVRIiwiZ3JvdXBBY2Nlc3MiOiIzLDM2LDExMiIsImlzQXB0Q2xudCI6IkZhbHNlIiwibG9jYXRpb24iOiI5OTk5IiwiY2hrTGNuIjoiIiwicmVzVG9rZW4iOiJkZWRhZDdkeWY4N2FuOTc0dGJxYjY0YmJ6ZjZlOTYwYzA5ZTE5NmRhZTg2MCIsImNsaWVudElQIjoiMTcyLjE5LjIzLjU0IiwicmVzQ2hhbm5lbElEIjoiNSIsInJlc0NoYW5uZWxOYW1lIjoiVFBBUEkiLCJkZWZhdWx0VXNlck5hbWUiOiJhcGl0YXdmZWVxIiwibmJmIjoxNjg1NTE1MjM3LCJleHAiOjE2ODU1MTc2MzcsImlzcyI6ImZseWR1YmFpLmNvbSIsImF1ZCI6IlRBV0ZFRVFUUlZMU19GWl9OUCJ9.rAW3YT-bX_-yADfvg3QkuPAbOJhkhRUN6HwoS_6PyiM",
              stop: 1,
            },
            {
              tid: "wT7TwC2aNPvnZ4mP_1",
              ref: 1,
              office_id: "",
              sdur: "15:25",
              re: "",
              sup: "5",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DXB",
                  dd: "2023-06-07T03:00:00",
                  ad: "2023-06-07T05:30:00",
                  rbd: "",
                  fn: "436",
                  trn: "",
                  eq: "73B",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "", arr: "2" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161457,
                  flightTime: 240,
                  fdur: "4:0",
                },
                {
                  ref: 2,
                  da: "DXB",
                  ar: "DOH",
                  dd: "2023-06-07T15:45:00",
                  ad: "2023-06-07T15:55:00",
                  rbd: "",
                  fn: "017",
                  trn: "",
                  eq: "73X",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "2", arr: "1" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161400,
                  flightTime: 70,
                  fdur: "1:10",
                },
              ],
              fareop: [
                {
                  fareKey: [],
                  pr: {
                    bf: 14455,
                    tf: 25784.89,
                    tax: [
                      { OT: 11329.89 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    scur: "INR",
                    ucur: "INR",
                    exchange_rate: 1,
                    sup_fare: "14455_25784.89",
                    sup_cur_fare: "",
                    cabb: "Free",
                    chekb: "7kg INCLUDED",
                    meals: "Standard meal",
                    chanb: "Chargeable",
                    ftyp: "VALUE",
                    extra: "12_VALUE",
                    chekbcode: "BAGL",
                    chanb_info:
                      "UPTO 24 hrs of departure Change of Flight is Free,WITHIN From 0-24 hrs of departure Change of Flight is Non-Refundable,NOSHOW From 0 hrs before  of departure Change of Flight is Non-Refundable",
                    canb: "Chargeable",
                    cabb_info:
                      "UPTO 24 hrs of departure Cancellation of Flight is Free,WITHIN From 0-24 hrs of departure Cancellation of Flight is Non-Refundable,NOSHOW From 0 hrs before  of departure Cancellation of Flight is Non-Refundable",
                    bagage_info: "30kg BAG INCLUDED IN FARE",
                    markupnames: [],
                    ttf: 25784.89,
                  },
                  paxpr: [
                    {
                      bf: 14455,
                      tf: 25784.89,
                      tax: [
                        { OT: 11329.89 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "ORL7IN5",
                      ptype: "ADT",
                      scur: "INR",
                      extra:
                        "1_91_O_1_9_0_tx-2186:170:PZ:0_4246:6450:YQ:0_8947:0:BAGL:0_9327:1024.89:K3:0_10386:120:ZR:0_11947:790:F6:0_12006:1170:P2:0_12434:1605:IN:0_",
                      ruleid:
                        "Z7b/uf/uTfSbQGgIy/xQaBZ+CHIn9HNL+12RdyPuUypN3Vrd+8Of0oA7a4N7g8CvZItP8LZIcrAqzBeCOoIuvjLwhemGenh8Kvds7rAxHg6X710uw+p5f6/y1zghrpgJ",
                      hsc: "13bd8f088cc22292e4088fa30ada50526db4598734cd392f7f5a3a6427b70d06",
                      sup_fare: "14455_25784.89_14005",
                      cabb: "Free",
                      bagage_info: "30kg BAG INCLUDED IN FARE",
                      chekbcode: "BAGL",
                      psup_fare: "14455_25784.89",
                    },
                  ],
                  uniqueId: "VALUE",
                  routeId: "knie8hczbo",
                  servicefee: [],
                  selected: true,
                },
              ],
              tktair: "FZ",
              tecst: "no",
              cl: "Economy",
              extra: 13919296,
              token:
                "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiYXBpdGF3ZmVlcSIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL2dpdmVubmFtZSI6IkFQSSBUQVdGRUVRIiwiZ3JvdXBBY2Nlc3MiOiIzLDM2LDExMiIsImlzQXB0Q2xudCI6IkZhbHNlIiwibG9jYXRpb24iOiI5OTk5IiwiY2hrTGNuIjoiIiwicmVzVG9rZW4iOiJkZWRhZDdkeWY4N2FuOTc0dGJxYjY0YmJ6ZjZlOTYwYzA5ZTE5NmRhZTg2MCIsImNsaWVudElQIjoiMTcyLjE5LjIzLjU0IiwicmVzQ2hhbm5lbElEIjoiNSIsInJlc0NoYW5uZWxOYW1lIjoiVFBBUEkiLCJkZWZhdWx0VXNlck5hbWUiOiJhcGl0YXdmZWVxIiwibmJmIjoxNjg1NTE1MjM3LCJleHAiOjE2ODU1MTc2MzcsImlzcyI6ImZseWR1YmFpLmNvbSIsImF1ZCI6IlRBV0ZFRVFUUlZMU19GWl9OUCJ9.rAW3YT-bX_-yADfvg3QkuPAbOJhkhRUN6HwoS_6PyiM",
              stop: 1,
            },
            {
              tid: "shGyiMQXO69CyBoJ_1",
              ref: 1,
              office_id: "",
              sdur: "19:25",
              re: "",
              sup: "5",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DXB",
                  dd: "2023-06-07T03:00:00",
                  ad: "2023-06-07T05:30:00",
                  rbd: "",
                  fn: "436",
                  trn: "",
                  eq: "73B",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "", arr: "2" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161457,
                  flightTime: 240,
                  fdur: "4:0",
                },
                {
                  ref: 2,
                  da: "DXB",
                  ar: "DOH",
                  dd: "2023-06-07T19:45:00",
                  ad: "2023-06-07T19:55:00",
                  rbd: "",
                  fn: "005",
                  trn: "",
                  eq: "73X",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "2", arr: "1" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161381,
                  flightTime: 70,
                  fdur: "1:10",
                },
              ],
              fareop: [
                {
                  fareKey: [],
                  pr: {
                    bf: 14455,
                    tf: 25784.89,
                    tax: [
                      { OT: 11329.89 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    scur: "INR",
                    ucur: "INR",
                    exchange_rate: 1,
                    sup_fare: "14455_25784.89",
                    sup_cur_fare: "",
                    cabb: "Free",
                    chekb: "7kg INCLUDED",
                    meals: "Standard meal",
                    chanb: "Chargeable",
                    ftyp: "VALUE",
                    extra: "12_VALUE",
                    chekbcode: "BAGL",
                    chanb_info:
                      "UPTO 24 hrs of departure Change of Flight is Free,WITHIN From 0-24 hrs of departure Change of Flight is Non-Refundable,NOSHOW From 0 hrs before  of departure Change of Flight is Non-Refundable",
                    canb: "Chargeable",
                    cabb_info:
                      "UPTO 24 hrs of departure Cancellation of Flight is Free,WITHIN From 0-24 hrs of departure Cancellation of Flight is Non-Refundable,NOSHOW From 0 hrs before  of departure Cancellation of Flight is Non-Refundable",
                    bagage_info: "30kg BAG INCLUDED IN FARE",
                    markupnames: [],
                    ttf: 25784.89,
                  },
                  paxpr: [
                    {
                      bf: 14455,
                      tf: 25784.89,
                      tax: [
                        { OT: 11329.89 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "ORL7IN5",
                      ptype: "ADT",
                      scur: "INR",
                      extra:
                        "1_123_O_1_9_0_tx-2186:170:PZ:0_4246:6450:YQ:0_8947:0:BAGL:0_9327:1024.89:K3:0_10386:120:ZR:0_11947:790:F6:0_12006:1170:P2:0_12434:1605:IN:0_",
                      ruleid:
                        "A3aARx+d1pbRRN3nzH+oCCH5cdRtjzMqmtc6Ydt53ymhQozXlONozKDeF7aO/DbGAO8K6tXzJ16Rfs5fUX41PoAzc0DAexpKMcT6yt4vWi6DnyG3nWxSFLQ/cUO1570IBQGHoM3lqYcsurCRq3PlUw==",
                      hsc: "2d9ecc7cece4611f825f9f84790a5c7f3093559fcf514f37dcdabcab671a4c93",
                      sup_fare: "14455_25784.89_14005",
                      cabb: "Free",
                      bagage_info: "30kg BAG INCLUDED IN FARE",
                      chekbcode: "BAGL",
                      psup_fare: "14455_25784.89",
                    },
                  ],
                  uniqueId: "VALUE",
                  routeId: "p7xvidh7x2k",
                  servicefee: [],
                  selected: true,
                },
              ],
              tktair: "FZ",
              tecst: "no",
              cl: "Economy",
              extra: 13919294,
              token:
                "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiYXBpdGF3ZmVlcSIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL2dpdmVubmFtZSI6IkFQSSBUQVdGRUVRIiwiZ3JvdXBBY2Nlc3MiOiIzLDM2LDExMiIsImlzQXB0Q2xudCI6IkZhbHNlIiwibG9jYXRpb24iOiI5OTk5IiwiY2hrTGNuIjoiIiwicmVzVG9rZW4iOiJkZWRhZDdkeWY4N2FuOTc0dGJxYjY0YmJ6ZjZlOTYwYzA5ZTE5NmRhZTg2MCIsImNsaWVudElQIjoiMTcyLjE5LjIzLjU0IiwicmVzQ2hhbm5lbElEIjoiNSIsInJlc0NoYW5uZWxOYW1lIjoiVFBBUEkiLCJkZWZhdWx0VXNlck5hbWUiOiJhcGl0YXdmZWVxIiwibmJmIjoxNjg1NTE1MjM3LCJleHAiOjE2ODU1MTc2MzcsImlzcyI6ImZseWR1YmFpLmNvbSIsImF1ZCI6IlRBV0ZFRVFUUlZMU19GWl9OUCJ9.rAW3YT-bX_-yADfvg3QkuPAbOJhkhRUN6HwoS_6PyiM",
              stop: 1,
            },
          ],
          paxpr: [
            {
              bf: 45950,
              tf: 86785,
              tax: [
                { OT: 40835 },
                { attmkp: 0 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              fbc: "ORL7IN5",
              ptype: "ADT",
              scur: "INR",
              extra:
                "1_27_O_1_9_0_tx-2186:170:PZ:0_4246:6450:YQ:0_8947:0:BAGL:0_9327:1024.89:K3:0_10386:120:ZR:0_11947:790:F6:0_12006:1170:P2:0_12434:1605:IN:0_",
              ruleid:
                "HlEtW4LGcjpnjrD06hKmbBZ+CHIn9HNL+12RdyPuUypN3Vrd+8Of0oA7a4N7g8CvZItP8LZIcrAqzBeCOoIuvjLwhemGenh8Kvds7rAxHg6X710uw+p5f6/y1zghrpgJ",
              hsc: "32f1fcf48f3e7d92eb134ca240ccbbb6da9d994f7a9f4ea01977aa5c67702595",
              sup_fare: "14455_25784.89_14005",
              cabb: "Free",
              bagage_info: "30kg BAG INCLUDED IN FARE",
              chekbcode: "BAGL",
              psup_fare: "45950_86785",
            },
          ],
          pr: {
            bf: 45950,
            tf: 86785,
            tax: [
              { OT: 40835 },
              { attmkp: 0 },
              { tamkp: 0 },
              { stamkp: 0 },
              { attdisc: 0 },
              { tadisc: 0 },
              { stadisc: 0 },
            ],
            scur: "INR",
            ucur: "INR",
            exchange_rate: 1,
            sup_fare: "45950_86785",
            sup_cur_fare: "",
            cabb: "Free",
            chekb: "7kg INCLUDED",
            meals: "Standard meal",
            chanb: "Chargeable",
            ftyp: "VALUE",
            extra: "12_VALUE",
            chekbcode: "BAGL",
            chanb_info:
              "UPTO 24 hrs of departure Change of Flight is Free,WITHIN From 0-24 hrs of departure Change of Flight is Non-Refundable,NOSHOW From 0 hrs before  of departure Change of Flight is Non-Refundable",
            canb: "Chargeable",
            cabb_info:
              "UPTO 24 hrs of departure Cancellation of Flight is Free,WITHIN From 0-24 hrs of departure Cancellation of Flight is Non-Refundable,NOSHOW From 0 hrs before  of departure Cancellation of Flight is Non-Refundable",
            bagage_info: "30kg BAG INCLUDED IN FARE",
            markupnames: [],
            ttf: 86785,
          },
          sup: "5",
          airlineList: "FZ",
          destorigins: [
            {
              tid: "kMs7HB34JTlOHd8w_2",
              ref: 2,
              office_id: "",
              sdur: "6:45",
              re: "",
              sup: "5",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DXB",
                  dd: "2023-06-09T17:00:00",
                  ad: "2023-06-09T19:15:00",
                  rbd: "",
                  fn: "018",
                  trn: "",
                  eq: "73X",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "1", arr: "2" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161399,
                  flightTime: 75,
                  fdur: "1:15",
                },
                {
                  ref: 2,
                  da: "DXB",
                  ar: "HYD",
                  dd: "2023-06-09T20:50:00",
                  ad: "2023-06-10T02:15:00",
                  rbd: "",
                  fn: "435",
                  trn: "",
                  eq: "73B",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "2", arr: "" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161456,
                  flightTime: 235,
                  fdur: "3:55",
                },
              ],
              fareop: [
                {
                  fareKey: [],
                  pr: {
                    bf: 31495,
                    tf: 61000.11,
                    tax: [
                      { OT: 29505.11 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    scur: "INR",
                    ucur: "INR",
                    exchange_rate: 1,
                    sup_fare: "31495_61000.11",
                    sup_cur_fare: "",
                    cabb: "Free",
                    chekb: "14kg INCLUDED",
                    meals: "Chargeable",
                    chanb: "Chargeable",
                    ftyp: "Business",
                    extra: "15_Business",
                    chekbcode: "JBAG",
                    chanb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Change of Flight is Free,NOSHOWUPTO 720 hrs of departure Change of Flight is Free,NOSHOWAFTER From 720 hrs before  of departure Change of Flight is Non-Refundable",
                    canb: "Chargeable",
                    cabb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Cancellation of Flight is Free,NOSHOWUPTO 720 hrs of departure Cancellation of Flight is Free,NOSHOWAFTER From 720 hrs before  of departure Cancellation of Flight is Non-Refundable",
                    bagage_info: "40kg Baggage allowance Business",
                    markupnames: [],
                    ttf: 61000.11,
                  },
                  paxpr: [
                    {
                      bf: 31495,
                      tf: 61000.11,
                      tax: [
                        { OT: 29505.11 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "CRM9IN2",
                      ptype: "ADT",
                      scur: "INR",
                      extra:
                        "1_28_C_1_3_0_tx-1764:1370:QA:0_2186:170:PZ:0_4246:22970:YQ:0_6544:0:JBAG:0_8966:1370:G4:0_9327:2485.11:K3:0_10386:120:ZR:0_11947:790:F6:0_13230:230:R9:0_",
                      ruleid:
                        "A7di6waern5AedTZ59cmyZMUYXFX0odry/AcSKbL2hBXbD3FLZ5ZejAuNYMbABAoyAFAYCcKjnEbjqUoRC2w3mDGJNH37aAsxUou/hEUve1ewJ9aVhK57wibWY2PIn24f4tv7xijw4oXgcRHgmFw0E3Dg72VJaVpJZuTCj214Mb6KesHnLDJHQwOhfTxZtbdeXH1hDuwDQ+MlHme53FHEA==",
                      hsc: "cba87dbb6cfabf7875d49b9d7f108b08a13644ed52449ba5aeb87de315a8aef3",
                      sup_fare: "31495_61000.11_26585",
                      cabb: "Free",
                      bagage_info: "40kg Baggage allowance Business",
                      chekbcode: "JBAG",
                      psup_fare: "31495_61000.11",
                    },
                  ],
                  uniqueId: "Business",
                  routeId: "a86dnnujjt",
                  servicefee: [],
                  selected: true,
                },
              ],
              tktair: "FZ",
              tecst: "no",
              cl: "Economy",
              extra: 13916393,
              token:
                "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiYXBpdGF3ZmVlcSIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL2dpdmVubmFtZSI6IkFQSSBUQVdGRUVRIiwiZ3JvdXBBY2Nlc3MiOiIzLDM2LDExMiIsImlzQXB0Q2xudCI6IkZhbHNlIiwibG9jYXRpb24iOiI5OTk5IiwiY2hrTGNuIjoiIiwicmVzVG9rZW4iOiJkZWRhZDdkeWY4N2FuOTc0dGJxYjY0YmJ6ZjZlOTYwYzA5ZTE5NmRhZTg2MCIsImNsaWVudElQIjoiMTcyLjE5LjIzLjU0IiwicmVzQ2hhbm5lbElEIjoiNSIsInJlc0NoYW5uZWxOYW1lIjoiVFBBUEkiLCJkZWZhdWx0VXNlck5hbWUiOiJhcGl0YXdmZWVxIiwibmJmIjoxNjg1NTE1MjM3LCJleHAiOjE2ODU1MTc2MzcsImlzcyI6ImZseWR1YmFpLmNvbSIsImF1ZCI6IlRBV0ZFRVFUUlZMU19GWl9OUCJ9.rAW3YT-bX_-yADfvg3QkuPAbOJhkhRUN6HwoS_6PyiM",
              stop: 1,
            },
            {
              tid: "bA8DFzFu8i7bWTnM_2",
              ref: 2,
              office_id: "",
              sdur: "13:45",
              re: "",
              sup: "5",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DXB",
                  dd: "2023-06-09T10:00:00",
                  ad: "2023-06-09T12:15:00",
                  rbd: "",
                  fn: "002",
                  trn: "",
                  eq: "73X",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "1", arr: "2" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161402,
                  flightTime: 75,
                  fdur: "1:15",
                },
                {
                  ref: 2,
                  da: "DXB",
                  ar: "HYD",
                  dd: "2023-06-09T20:50:00",
                  ad: "2023-06-10T02:15:00",
                  rbd: "",
                  fn: "435",
                  trn: "",
                  eq: "73B",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "2", arr: "" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161456,
                  flightTime: 235,
                  fdur: "3:55",
                },
              ],
              fareop: [
                {
                  fareKey: [],
                  pr: {
                    bf: 31495,
                    tf: 61000.11,
                    tax: [
                      { OT: 29505.11 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    scur: "INR",
                    ucur: "INR",
                    exchange_rate: 1,
                    sup_fare: "31495_61000.11",
                    sup_cur_fare: "",
                    cabb: "Free",
                    chekb: "14kg INCLUDED",
                    meals: "Chargeable",
                    chanb: "Chargeable",
                    ftyp: "Business",
                    extra: "15_Business",
                    chekbcode: "JBAG",
                    chanb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Change of Flight is Free,NOSHOWUPTO 720 hrs of departure Change of Flight is Free,NOSHOWAFTER From 720 hrs before  of departure Change of Flight is Non-Refundable",
                    canb: "Chargeable",
                    cabb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Cancellation of Flight is Free,NOSHOWUPTO 720 hrs of departure Cancellation of Flight is Free,NOSHOWAFTER From 720 hrs before  of departure Cancellation of Flight is Non-Refundable",
                    bagage_info: "40kg Baggage allowance Business",
                    markupnames: [],
                    ttf: 61000.11,
                  },
                  paxpr: [
                    {
                      bf: 31495,
                      tf: 61000.11,
                      tax: [
                        { OT: 29505.11 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "CRM9IN2",
                      ptype: "ADT",
                      scur: "INR",
                      extra:
                        "1_60_C_1_3_0_tx-1764:1370:QA:0_2186:170:PZ:0_4246:22970:YQ:0_6544:0:JBAG:0_8966:1370:G4:0_9327:2485.11:K3:0_10386:120:ZR:0_11947:790:F6:0_13230:230:R9:0_",
                      ruleid:
                        "micBxAYm6zVyp5qkj4Mt9pMUYXFX0odry/AcSKbL2hBXbD3FLZ5ZejAuNYMbABAoyAFAYCcKjnEbjqUoRC2w3mDGJNH37aAsxUou/hEUve1ewJ9aVhK57wibWY2PIn24f4tv7xijw4oXgcRHgmFw0E3Dg72VJaVpJZuTCj214Mb6KesHnLDJHQwOhfTxZtbdeXH1hDuwDQ+MlHme53FHEA==",
                      hsc: "3543236ddcee6371b8cc2fa5f36b3043e4ff3532ddad36b371ad9a608a3dc793",
                      sup_fare: "31495_61000.11_26585",
                      cabb: "Free",
                      bagage_info: "40kg Baggage allowance Business",
                      chekbcode: "JBAG",
                      psup_fare: "31495_61000.11",
                    },
                  ],
                  uniqueId: "Business",
                  routeId: "o1zni7ohi4",
                  servicefee: [],
                  selected: true,
                },
              ],
              tktair: "FZ",
              tecst: "no",
              cl: "Economy",
              extra: 13915297,
              token:
                "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiYXBpdGF3ZmVlcSIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL2dpdmVubmFtZSI6IkFQSSBUQVdGRUVRIiwiZ3JvdXBBY2Nlc3MiOiIzLDM2LDExMiIsImlzQXB0Q2xudCI6IkZhbHNlIiwibG9jYXRpb24iOiI5OTk5IiwiY2hrTGNuIjoiIiwicmVzVG9rZW4iOiJkZWRhZDdkeWY4N2FuOTc0dGJxYjY0YmJ6ZjZlOTYwYzA5ZTE5NmRhZTg2MCIsImNsaWVudElQIjoiMTcyLjE5LjIzLjU0IiwicmVzQ2hhbm5lbElEIjoiNSIsInJlc0NoYW5uZWxOYW1lIjoiVFBBUEkiLCJkZWZhdWx0VXNlck5hbWUiOiJhcGl0YXdmZWVxIiwibmJmIjoxNjg1NTE1MjM3LCJleHAiOjE2ODU1MTc2MzcsImlzcyI6ImZseWR1YmFpLmNvbSIsImF1ZCI6IlRBV0ZFRVFUUlZMU19GWl9OUCJ9.rAW3YT-bX_-yADfvg3QkuPAbOJhkhRUN6HwoS_6PyiM",
              stop: 1,
            },
          ],
          servicefee: [],
        },
        {
          srcorigns: [
            {
              tid: "0PkvSPKonWv3T8F0_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "04:10",
              re: "Refundable Before Departure",
              stop: 0,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "QR",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DOH",
                  dd: "2023-06-07T21:55:00",
                  ad: "2023-06-07T23:35:00",
                  rbd: "N",
                  fn: "4779",
                  eq: "320",
                  ac: "QR",
                  ma: "QR",
                  seat: "9",
                  oa: "6E",
                  fa: [],
                  fdur: "04:10",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
              ],
              flightNumbers: "4779",
              bgd: [{ qun: "25", wt: "kg" }],
            },
            {
              tid: "BKSHLnJkxldX8PmH_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "04:10",
              re: "Refundable Before Departure",
              stop: 0,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "QR",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DOH",
                  dd: "2023-06-07T17:05:00",
                  ad: "2023-06-07T18:45:00",
                  rbd: "N",
                  fn: "4777",
                  eq: "320",
                  ac: "QR",
                  ma: "QR",
                  seat: "9",
                  oa: "6E",
                  fa: [],
                  fdur: "04:10",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
              ],
              flightNumbers: "4777",
              bgd: [{ qun: "25", wt: "kg" }],
            },
            {
              tid: "QGZGebxWVhZ0mr2f_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "04:10",
              re: "Refundable Before Departure",
              stop: 0,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "QR",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DOH",
                  dd: "2023-06-07T04:10:00",
                  ad: "2023-06-07T05:50:00",
                  rbd: "N",
                  fn: "4775",
                  eq: "320",
                  ac: "QR",
                  ma: "QR",
                  seat: "9",
                  oa: "6E",
                  fa: [],
                  fdur: "04:10",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
              ],
              flightNumbers: "4775",
              bgd: [{ qun: "25", wt: "kg" }],
            },
          ],
          destorigins: [
            {
              tid: "QGZGebxWVhZ0mr2f_2",
              ref: 2,
              office_id: "DOHQR28IH",
              sdur: "04:15",
              re: "Refundable Before Departure",
              stop: 0,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "QR",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "HYD",
                  dd: "2023-06-09T19:15:00",
                  ad: "2023-06-10T02:00:00",
                  rbd: "V",
                  fn: "500",
                  eq: "320",
                  ac: "QR",
                  ma: "QR",
                  seat: "9",
                  oa: "QR",
                  fa: [],
                  fdur: "04:15",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
              ],
              flightNumbers: "500",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          pr: {
            bf: 53222.4,
            tf: 84806.4,
            tax: [
              { YQ: 19712 },
              { YR: 1792 },
              { OT: 10080 },
              { dealmkp: 0 },
              { attmkp: 4838.4 },
              { tamkp: 0 },
              { stamkp: 0 },
              { attdisc: 0 },
              { tadisc: 0 },
              { stadisc: 0 },
            ],
            scur: "QAR",
            ucur: "INR",
            exchange_rate: 22.4,
            sup_fare: "2160_3570",
            markupnames: [{ code: "MTA", name: "LaxmiAprilMarkup" }],
          },
          paxpr: [
            {
              tf: 84806.4,
              tax: [
                { YQ: 19712 },
                { YR: 1792 },
                { OT: 10080 },
                { attmkp: 4838.4 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              bf: 53222.4,
              fbc: "NLR4I1RX",
              fareType: "RP",
              pusup_fare: "2160_3570",
              psup_fare: "48384_79968",
              ptype: "ADT",
            },
          ],
          sup: "0",
          airlinesList: "QR",
          servicefee: [
            {
              type: "ATT",
              adt: 5322.24,
              chd: 0,
              inf: 0,
              servicefee_name: "LaxmiServieApril",
              apl_on: "Per Ticket",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 5322.24,
              sup_fee: 0,
            },
            {
              type: "TA",
              adt: 0,
              chd: 0,
              inf: 0,
              apl_on: "",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 0,
              sup_fee: 5322.24,
            },
          ],
        },
        {
          srcorigns: [
            {
              tid: "pXVmTwqGZDBf6fyT_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "04:15",
              re: "Refundable Before Departure",
              stop: 0,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "QR",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DOH",
                  dd: "2023-06-07T03:35:00",
                  ad: "2023-06-07T05:20:00",
                  rbd: "N",
                  fn: "501",
                  eq: "788",
                  ac: "QR",
                  ma: "QR",
                  seat: "9",
                  oa: "QR",
                  fa: [],
                  fdur: "04:15",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
              ],
              flightNumbers: "501",
              bgd: [{ qun: "25", wt: "kg" }],
            },
          ],
          destorigins: [
            {
              tid: "xnTPnn4bZEIW9zR8_2",
              ref: 2,
              office_id: "DOHQR28IH",
              sdur: "04:15",
              re: "Refundable Before Departure",
              stop: 0,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "QR",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "HYD",
                  dd: "2023-06-09T19:45:00",
                  ad: "2023-06-10T02:30:00",
                  rbd: "V",
                  fn: "4774",
                  eq: "320",
                  ac: "QR",
                  ma: "QR",
                  seat: "9",
                  oa: "6E",
                  fa: [],
                  fdur: "04:15",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
              ],
              flightNumbers: "4774",
              bgd: [{ qun: "30", wt: "kg" }],
            },
            {
              tid: "eT00l4UiLPvQycTC_2",
              ref: 2,
              office_id: "DOHQR28IH",
              sdur: "04:15",
              re: "Refundable Before Departure",
              stop: 0,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "QR",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "HYD",
                  dd: "2023-06-09T08:15:00",
                  ad: "2023-06-09T15:00:00",
                  rbd: "V",
                  fn: "4776",
                  eq: "320",
                  ac: "QR",
                  ma: "QR",
                  seat: "9",
                  oa: "6E",
                  fa: [],
                  fdur: "04:15",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
              ],
              flightNumbers: "4776",
              bgd: [{ qun: "30", wt: "kg" }],
            },
            {
              tid: "pXVmTwqGZDBf6fyT_2",
              ref: 2,
              office_id: "DOHQR28IH",
              sdur: "04:15",
              re: "Refundable Before Departure",
              stop: 0,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "QR",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "HYD",
                  dd: "2023-06-09T02:00:00",
                  ad: "2023-06-09T08:45:00",
                  rbd: "V",
                  fn: "4778",
                  eq: "320",
                  ac: "QR",
                  ma: "QR",
                  seat: "9",
                  oa: "6E",
                  fa: [],
                  fdur: "04:15",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
              ],
              flightNumbers: "4778",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          pr: {
            bf: 54700.8,
            tf: 86284.8,
            tax: [
              { YQ: 19712 },
              { YR: 1792 },
              { OT: 10080 },
              { dealmkp: 0 },
              { attmkp: 4972.8 },
              { tamkp: 0 },
              { stamkp: 0 },
              { attdisc: 0 },
              { tadisc: 0 },
              { stadisc: 0 },
            ],
            scur: "QAR",
            ucur: "INR",
            exchange_rate: 22.4,
            sup_fare: "2220_3630",
            markupnames: [{ code: "MTA", name: "LaxmiAprilMarkup" }],
          },
          paxpr: [
            {
              tf: 86284.8,
              tax: [
                { YQ: 19712 },
                { YR: 1792 },
                { OT: 10080 },
                { attmkp: 4972.8 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              bf: 54700.8,
              fbc: "NLR4R1RX",
              fareType: "RP",
              pusup_fare: "2220_3630",
              psup_fare: "49728_81312",
              ptype: "ADT",
            },
          ],
          sup: "0",
          airlinesList: "QR",
          servicefee: [
            {
              type: "ATT",
              adt: 5470.08,
              chd: 0,
              inf: 0,
              servicefee_name: "LaxmiServieApril",
              apl_on: "Per Ticket",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 5470.08,
              sup_fee: 0,
            },
            {
              type: "TA",
              adt: 0,
              chd: 0,
              inf: 0,
              apl_on: "",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 0,
              sup_fee: 5470.08,
            },
          ],
        },
        {
          srcorigns: [
            {
              tid: "cr6SIksCzL1hUu1n_1",
              ref: 1,
              office_id: "",
              sdur: "8:25",
              re: "",
              sup: "5",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DXB",
                  dd: "2023-06-07T03:00:00",
                  ad: "2023-06-07T05:30:00",
                  rbd: "",
                  fn: "436",
                  trn: "",
                  eq: "73B",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "", arr: "2" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161457,
                  flightTime: 240,
                  fdur: "4:0",
                },
                {
                  ref: 2,
                  da: "DXB",
                  ar: "DOH",
                  dd: "2023-06-07T08:45:00",
                  ad: "2023-06-07T08:55:00",
                  rbd: "",
                  fn: "001",
                  trn: "",
                  eq: "73X",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "2", arr: "1" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161405,
                  flightTime: 70,
                  fdur: "1:10",
                },
              ],
              fareop: [
                {
                  fareKey: [],
                  pr: {
                    bf: 19985,
                    tf: 31592.91,
                    tax: [
                      { OT: 11607.91 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    scur: "INR",
                    ucur: "INR",
                    exchange_rate: 1,
                    sup_fare: "19985_31592.91",
                    sup_cur_fare: "",
                    cabb: "Free",
                    chekb: "7kg INCLUDED",
                    meals: "Standard meal",
                    chanb: "Chargeable",
                    ftyp: "FLEX",
                    extra: "13_FLEX",
                    chekbcode: "BAGL",
                    chanb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Change of Flight is Free,NOSHOWUPTO 720 hrs of departure Change of Flight is Free,NOSHOWAFTER From 720 hrs before  of departure Change of Flight is Non-Refundable",
                    canb: "Chargeable",
                    cabb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Cancellation of Flight is Free,NOSHOWUPTO 720 hrs of departure Cancellation of Flight is Free,NOSHOWAFTER From 720 hrs before  of departure Cancellation of Flight is Non-Refundable",
                    bagage_info: "30kg BAG INCLUDED IN FARE",
                    markupnames: [],
                    ttf: 31592.91,
                  },
                  paxpr: [
                    {
                      bf: 19985,
                      tf: 31592.91,
                      tax: [
                        { OT: 11607.91 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "ORML8IN5",
                      ptype: "ADT",
                      scur: "INR",
                      extra:
                        "1_29_O_1_9_0_tx-2186:170:PZ:0_4246:6450:YQ:0_8947:0:BAGL:0_9327:1302.91:K3:0_10386:120:ZR:0_11947:790:F6:0_12006:1170:P2:0_12434:1605:IN:0_",
                      ruleid:
                        "zfhTL0Wu/a85FbUucA1f4BZ+CHIn9HNL+12RdyPuUypN3Vrd+8Of0oA7a4N7g8CvZItP8LZIcrAqzBeCOoIuvjLwhemGenh8Kvds7rAxHg77sBOV2+bcsWuISEqTM1NI",
                      hsc: "f7315a777a10fc5ce36b0b12a149b89d2de33c9bc244b55af9bc98c133487f70",
                      sup_fare: "19985_31592.91_19535",
                      cabb: "Free",
                      bagage_info: "30kg BAG INCLUDED IN FARE",
                      chekbcode: "BAGL",
                      psup_fare: "19985_31592.91",
                    },
                  ],
                  uniqueId: "FLEX",
                  routeId: "mkpb04ivij",
                  servicefee: [],
                  selected: true,
                },
              ],
              tktair: "FZ",
              tecst: "no",
              cl: "Economy",
              extra: 13919293,
              token:
                "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiYXBpdGF3ZmVlcSIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL2dpdmVubmFtZSI6IkFQSSBUQVdGRUVRIiwiZ3JvdXBBY2Nlc3MiOiIzLDM2LDExMiIsImlzQXB0Q2xudCI6IkZhbHNlIiwibG9jYXRpb24iOiI5OTk5IiwiY2hrTGNuIjoiIiwicmVzVG9rZW4iOiJkZWRhZDdkeWY4N2FuOTc0dGJxYjY0YmJ6ZjZlOTYwYzA5ZTE5NmRhZTg2MCIsImNsaWVudElQIjoiMTcyLjE5LjIzLjU0IiwicmVzQ2hhbm5lbElEIjoiNSIsInJlc0NoYW5uZWxOYW1lIjoiVFBBUEkiLCJkZWZhdWx0VXNlck5hbWUiOiJhcGl0YXdmZWVxIiwibmJmIjoxNjg1NTE1MjM3LCJleHAiOjE2ODU1MTc2MzcsImlzcyI6ImZseWR1YmFpLmNvbSIsImF1ZCI6IlRBV0ZFRVFUUlZMU19GWl9OUCJ9.rAW3YT-bX_-yADfvg3QkuPAbOJhkhRUN6HwoS_6PyiM",
              stop: 1,
            },
            {
              tid: "dJLxg9KwMDRIslgA_1",
              ref: 1,
              office_id: "",
              sdur: "15:25",
              re: "",
              sup: "5",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DXB",
                  dd: "2023-06-07T03:00:00",
                  ad: "2023-06-07T05:30:00",
                  rbd: "",
                  fn: "436",
                  trn: "",
                  eq: "73B",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "", arr: "2" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161457,
                  flightTime: 240,
                  fdur: "4:0",
                },
                {
                  ref: 2,
                  da: "DXB",
                  ar: "DOH",
                  dd: "2023-06-07T15:45:00",
                  ad: "2023-06-07T15:55:00",
                  rbd: "",
                  fn: "017",
                  trn: "",
                  eq: "73X",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "2", arr: "1" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161400,
                  flightTime: 70,
                  fdur: "1:10",
                },
              ],
              fareop: [
                {
                  fareKey: [],
                  pr: {
                    bf: 19985,
                    tf: 31592.91,
                    tax: [
                      { OT: 11607.91 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    scur: "INR",
                    ucur: "INR",
                    exchange_rate: 1,
                    sup_fare: "19985_31592.91",
                    sup_cur_fare: "",
                    cabb: "Free",
                    chekb: "7kg INCLUDED",
                    meals: "Standard meal",
                    chanb: "Chargeable",
                    ftyp: "FLEX",
                    extra: "13_FLEX",
                    chekbcode: "BAGL",
                    chanb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Change of Flight is Free,NOSHOWUPTO 720 hrs of departure Change of Flight is Free,NOSHOWAFTER From 720 hrs before  of departure Change of Flight is Non-Refundable",
                    canb: "Chargeable",
                    cabb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Cancellation of Flight is Free,NOSHOWUPTO 720 hrs of departure Cancellation of Flight is Free,NOSHOWAFTER From 720 hrs before  of departure Cancellation of Flight is Non-Refundable",
                    bagage_info: "30kg BAG INCLUDED IN FARE",
                    markupnames: [],
                    ttf: 31592.91,
                  },
                  paxpr: [
                    {
                      bf: 19985,
                      tf: 31592.91,
                      tax: [
                        { OT: 11607.91 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "ORML8IN5",
                      ptype: "ADT",
                      scur: "INR",
                      extra:
                        "1_93_O_1_9_0_tx-2186:170:PZ:0_4246:6450:YQ:0_8947:0:BAGL:0_9327:1302.91:K3:0_10386:120:ZR:0_11947:790:F6:0_12006:1170:P2:0_12434:1605:IN:0_",
                      ruleid:
                        "92WK0NUh//mBE3gaii/0fhZ+CHIn9HNL+12RdyPuUypN3Vrd+8Of0oA7a4N7g8CvZItP8LZIcrAqzBeCOoIuvjLwhemGenh8Kvds7rAxHg77sBOV2+bcsWuISEqTM1NI",
                      hsc: "9ebed8cf646c04c380bb96193a91045cadbe2018735c5d8b1106ac71b6eadd97",
                      sup_fare: "19985_31592.91_19535",
                      cabb: "Free",
                      bagage_info: "30kg BAG INCLUDED IN FARE",
                      chekbcode: "BAGL",
                      psup_fare: "19985_31592.91",
                    },
                  ],
                  uniqueId: "FLEX",
                  routeId: "hg6pxji42g",
                  servicefee: [],
                  selected: true,
                },
              ],
              tktair: "FZ",
              tecst: "no",
              cl: "Economy",
              extra: 13919296,
              token:
                "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiYXBpdGF3ZmVlcSIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL2dpdmVubmFtZSI6IkFQSSBUQVdGRUVRIiwiZ3JvdXBBY2Nlc3MiOiIzLDM2LDExMiIsImlzQXB0Q2xudCI6IkZhbHNlIiwibG9jYXRpb24iOiI5OTk5IiwiY2hrTGNuIjoiIiwicmVzVG9rZW4iOiJkZWRhZDdkeWY4N2FuOTc0dGJxYjY0YmJ6ZjZlOTYwYzA5ZTE5NmRhZTg2MCIsImNsaWVudElQIjoiMTcyLjE5LjIzLjU0IiwicmVzQ2hhbm5lbElEIjoiNSIsInJlc0NoYW5uZWxOYW1lIjoiVFBBUEkiLCJkZWZhdWx0VXNlck5hbWUiOiJhcGl0YXdmZWVxIiwibmJmIjoxNjg1NTE1MjM3LCJleHAiOjE2ODU1MTc2MzcsImlzcyI6ImZseWR1YmFpLmNvbSIsImF1ZCI6IlRBV0ZFRVFUUlZMU19GWl9OUCJ9.rAW3YT-bX_-yADfvg3QkuPAbOJhkhRUN6HwoS_6PyiM",
              stop: 1,
            },
            {
              tid: "LVqJitGDKFOJmDVB_1",
              ref: 1,
              office_id: "",
              sdur: "19:25",
              re: "",
              sup: "5",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DXB",
                  dd: "2023-06-07T03:00:00",
                  ad: "2023-06-07T05:30:00",
                  rbd: "",
                  fn: "436",
                  trn: "",
                  eq: "73B",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "", arr: "2" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161457,
                  flightTime: 240,
                  fdur: "4:0",
                },
                {
                  ref: 2,
                  da: "DXB",
                  ar: "DOH",
                  dd: "2023-06-07T19:45:00",
                  ad: "2023-06-07T19:55:00",
                  rbd: "",
                  fn: "005",
                  trn: "",
                  eq: "73X",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "2", arr: "1" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161381,
                  flightTime: 70,
                  fdur: "1:10",
                },
              ],
              fareop: [
                {
                  fareKey: [],
                  pr: {
                    bf: 19985,
                    tf: 31592.91,
                    tax: [
                      { OT: 11607.91 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    scur: "INR",
                    ucur: "INR",
                    exchange_rate: 1,
                    sup_fare: "19985_31592.91",
                    sup_cur_fare: "",
                    cabb: "Free",
                    chekb: "7kg INCLUDED",
                    meals: "Standard meal",
                    chanb: "Chargeable",
                    ftyp: "FLEX",
                    extra: "13_FLEX",
                    chekbcode: "BAGL",
                    chanb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Change of Flight is Free,NOSHOWUPTO 720 hrs of departure Change of Flight is Free,NOSHOWAFTER From 720 hrs before  of departure Change of Flight is Non-Refundable",
                    canb: "Chargeable",
                    cabb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Cancellation of Flight is Free,NOSHOWUPTO 720 hrs of departure Cancellation of Flight is Free,NOSHOWAFTER From 720 hrs before  of departure Cancellation of Flight is Non-Refundable",
                    bagage_info: "30kg BAG INCLUDED IN FARE",
                    markupnames: [],
                    ttf: 31592.91,
                  },
                  paxpr: [
                    {
                      bf: 19985,
                      tf: 31592.91,
                      tax: [
                        { OT: 11607.91 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "ORML8IN5",
                      ptype: "ADT",
                      scur: "INR",
                      extra:
                        "1_125_O_1_9_0_tx-2186:170:PZ:0_4246:6450:YQ:0_8947:0:BAGL:0_9327:1302.91:K3:0_10386:120:ZR:0_11947:790:F6:0_12006:1170:P2:0_12434:1605:IN:0_",
                      ruleid:
                        "9macWyEhyPC5I2M1NMI8zyH5cdRtjzMqmtc6Ydt53ymhQozXlONozKDeF7aO/DbGAO8K6tXzJ16Rfs5fUX41PoAzc0DAexpKMcT6yt4vWi5QaY31vw17VrswRPIWnfWdBQGHoM3lqYcsurCRq3PlUw==",
                      hsc: "71ecf1db4038c6e269d00c85fed2ecc077a62b75dd3759a17c4b96d83bddc1eb",
                      sup_fare: "19985_31592.91_19535",
                      cabb: "Free",
                      bagage_info: "30kg BAG INCLUDED IN FARE",
                      chekbcode: "BAGL",
                      psup_fare: "19985_31592.91",
                    },
                  ],
                  uniqueId: "FLEX",
                  routeId: "qczpulksxw",
                  servicefee: [],
                  selected: true,
                },
              ],
              tktair: "FZ",
              tecst: "no",
              cl: "Economy",
              extra: 13919294,
              token:
                "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiYXBpdGF3ZmVlcSIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL2dpdmVubmFtZSI6IkFQSSBUQVdGRUVRIiwiZ3JvdXBBY2Nlc3MiOiIzLDM2LDExMiIsImlzQXB0Q2xudCI6IkZhbHNlIiwibG9jYXRpb24iOiI5OTk5IiwiY2hrTGNuIjoiIiwicmVzVG9rZW4iOiJkZWRhZDdkeWY4N2FuOTc0dGJxYjY0YmJ6ZjZlOTYwYzA5ZTE5NmRhZTg2MCIsImNsaWVudElQIjoiMTcyLjE5LjIzLjU0IiwicmVzQ2hhbm5lbElEIjoiNSIsInJlc0NoYW5uZWxOYW1lIjoiVFBBUEkiLCJkZWZhdWx0VXNlck5hbWUiOiJhcGl0YXdmZWVxIiwibmJmIjoxNjg1NTE1MjM3LCJleHAiOjE2ODU1MTc2MzcsImlzcyI6ImZseWR1YmFpLmNvbSIsImF1ZCI6IlRBV0ZFRVFUUlZMU19GWl9OUCJ9.rAW3YT-bX_-yADfvg3QkuPAbOJhkhRUN6HwoS_6PyiM",
              stop: 1,
            },
          ],
          paxpr: [
            {
              bf: 51480,
              tf: 92595,
              tax: [
                { OT: 41115 },
                { attmkp: 0 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              fbc: "ORML8IN5",
              ptype: "ADT",
              scur: "INR",
              extra:
                "1_29_O_1_9_0_tx-2186:170:PZ:0_4246:6450:YQ:0_8947:0:BAGL:0_9327:1302.91:K3:0_10386:120:ZR:0_11947:790:F6:0_12006:1170:P2:0_12434:1605:IN:0_",
              ruleid:
                "zfhTL0Wu/a85FbUucA1f4BZ+CHIn9HNL+12RdyPuUypN3Vrd+8Of0oA7a4N7g8CvZItP8LZIcrAqzBeCOoIuvjLwhemGenh8Kvds7rAxHg77sBOV2+bcsWuISEqTM1NI",
              hsc: "f7315a777a10fc5ce36b0b12a149b89d2de33c9bc244b55af9bc98c133487f70",
              sup_fare: "19985_31592.91_19535",
              cabb: "Free",
              bagage_info: "30kg BAG INCLUDED IN FARE",
              chekbcode: "BAGL",
              psup_fare: "51480_92595",
            },
          ],
          pr: {
            bf: 51480,
            tf: 92595,
            tax: [
              { OT: 41115 },
              { attmkp: 0 },
              { tamkp: 0 },
              { stamkp: 0 },
              { attdisc: 0 },
              { tadisc: 0 },
              { stadisc: 0 },
            ],
            scur: "INR",
            ucur: "INR",
            exchange_rate: 1,
            sup_fare: "51480_92595",
            sup_cur_fare: "",
            cabb: "Free",
            chekb: "7kg INCLUDED",
            meals: "Standard meal",
            chanb: "Chargeable",
            ftyp: "FLEX",
            extra: "13_FLEX",
            chekbcode: "BAGL",
            chanb_info:
              "BEFOREDEPARTURE 0 hrs of departure Change of Flight is Free,NOSHOWUPTO 720 hrs of departure Change of Flight is Free,NOSHOWAFTER From 720 hrs before  of departure Change of Flight is Non-Refundable",
            canb: "Chargeable",
            cabb_info:
              "BEFOREDEPARTURE 0 hrs of departure Cancellation of Flight is Free,NOSHOWUPTO 720 hrs of departure Cancellation of Flight is Free,NOSHOWAFTER From 720 hrs before  of departure Cancellation of Flight is Non-Refundable",
            bagage_info: "30kg BAG INCLUDED IN FARE",
            markupnames: [],
            ttf: 92595,
          },
          sup: "5",
          airlineList: "FZ",
          destorigins: [
            {
              tid: "ICp9k8ddCtCyJeeV_2",
              ref: 2,
              office_id: "",
              sdur: "6:45",
              re: "",
              sup: "5",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DXB",
                  dd: "2023-06-09T17:00:00",
                  ad: "2023-06-09T19:15:00",
                  rbd: "",
                  fn: "018",
                  trn: "",
                  eq: "73X",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "1", arr: "2" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161399,
                  flightTime: 75,
                  fdur: "1:15",
                },
                {
                  ref: 2,
                  da: "DXB",
                  ar: "HYD",
                  dd: "2023-06-09T20:50:00",
                  ad: "2023-06-10T02:15:00",
                  rbd: "",
                  fn: "435",
                  trn: "",
                  eq: "73B",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "2", arr: "" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161456,
                  flightTime: 235,
                  fdur: "3:55",
                },
              ],
              fareop: [
                {
                  fareKey: [],
                  pr: {
                    bf: 31495,
                    tf: 61002.09,
                    tax: [
                      { OT: 29507.09 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    scur: "INR",
                    ucur: "INR",
                    exchange_rate: 1,
                    sup_fare: "31495_61002.09",
                    sup_cur_fare: "",
                    cabb: "Free",
                    chekb: "14kg INCLUDED",
                    meals: "Chargeable",
                    chanb: "Chargeable",
                    ftyp: "Business",
                    extra: "15_Business",
                    chekbcode: "JBAG",
                    chanb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Change of Flight is Free,NOSHOWUPTO 720 hrs of departure Change of Flight is Free,NOSHOWAFTER From 720 hrs before  of departure Change of Flight is Non-Refundable",
                    canb: "Chargeable",
                    cabb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Cancellation of Flight is Free,NOSHOWUPTO 720 hrs of departure Cancellation of Flight is Free,NOSHOWAFTER From 720 hrs before  of departure Cancellation of Flight is Non-Refundable",
                    bagage_info: "40kg Baggage allowance Business",
                    markupnames: [],
                    ttf: 61002.09,
                  },
                  paxpr: [
                    {
                      bf: 31495,
                      tf: 61002.09,
                      tax: [
                        { OT: 29507.09 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "CRM9IN2",
                      ptype: "ADT",
                      scur: "INR",
                      extra:
                        "1_30_C_1_3_0_tx-1764:1370:QA:0_2186:170:PZ:0_4246:22970:YQ:0_6544:0:JBAG:0_8966:1370:G4:0_9327:2487.09:K3:0_10386:120:ZR:0_11947:790:F6:0_13230:230:R9:0_",
                      ruleid:
                        "w/U2JrmJ9Mu0NxzPxE1DBZMUYXFX0odry/AcSKbL2hBXbD3FLZ5ZejAuNYMbABAoyAFAYCcKjnEbjqUoRC2w3mDGJNH37aAsxUou/hEUve1ewJ9aVhK57wibWY2PIn24f4tv7xijw4oXgcRHgmFw0E3Dg72VJaVpJZuTCj214Mb6KesHnLDJHQwOhfTxZtbdeXH1hDuwDQ+MlHme53FHEA==",
                      hsc: "e883555905462272202043e870fcff9465b4a60f9b657d576def0d6951cba7db",
                      sup_fare: "31495_61002.09_26585",
                      cabb: "Free",
                      bagage_info: "40kg Baggage allowance Business",
                      chekbcode: "JBAG",
                      psup_fare: "31495_61002.09",
                    },
                  ],
                  uniqueId: "Business",
                  routeId: "pdnztllxeg",
                  servicefee: [],
                  selected: true,
                },
              ],
              tktair: "FZ",
              tecst: "no",
              cl: "Economy",
              extra: 13916393,
              token:
                "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiYXBpdGF3ZmVlcSIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL2dpdmVubmFtZSI6IkFQSSBUQVdGRUVRIiwiZ3JvdXBBY2Nlc3MiOiIzLDM2LDExMiIsImlzQXB0Q2xudCI6IkZhbHNlIiwibG9jYXRpb24iOiI5OTk5IiwiY2hrTGNuIjoiIiwicmVzVG9rZW4iOiJkZWRhZDdkeWY4N2FuOTc0dGJxYjY0YmJ6ZjZlOTYwYzA5ZTE5NmRhZTg2MCIsImNsaWVudElQIjoiMTcyLjE5LjIzLjU0IiwicmVzQ2hhbm5lbElEIjoiNSIsInJlc0NoYW5uZWxOYW1lIjoiVFBBUEkiLCJkZWZhdWx0VXNlck5hbWUiOiJhcGl0YXdmZWVxIiwibmJmIjoxNjg1NTE1MjM3LCJleHAiOjE2ODU1MTc2MzcsImlzcyI6ImZseWR1YmFpLmNvbSIsImF1ZCI6IlRBV0ZFRVFUUlZMU19GWl9OUCJ9.rAW3YT-bX_-yADfvg3QkuPAbOJhkhRUN6HwoS_6PyiM",
              stop: 1,
            },
            {
              tid: "ezp0QP5NtovTfqr0_2",
              ref: 2,
              office_id: "",
              sdur: "13:45",
              re: "",
              sup: "5",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DXB",
                  dd: "2023-06-09T10:00:00",
                  ad: "2023-06-09T12:15:00",
                  rbd: "",
                  fn: "002",
                  trn: "",
                  eq: "73X",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "1", arr: "2" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161402,
                  flightTime: 75,
                  fdur: "1:15",
                },
                {
                  ref: 2,
                  da: "DXB",
                  ar: "HYD",
                  dd: "2023-06-09T20:50:00",
                  ad: "2023-06-10T02:15:00",
                  rbd: "",
                  fn: "435",
                  trn: "",
                  eq: "73B",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "2", arr: "" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161456,
                  flightTime: 235,
                  fdur: "3:55",
                },
              ],
              fareop: [
                {
                  fareKey: [],
                  pr: {
                    bf: 31495,
                    tf: 61002.09,
                    tax: [
                      { OT: 29507.09 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    scur: "INR",
                    ucur: "INR",
                    exchange_rate: 1,
                    sup_fare: "31495_61002.09",
                    sup_cur_fare: "",
                    cabb: "Free",
                    chekb: "14kg INCLUDED",
                    meals: "Chargeable",
                    chanb: "Chargeable",
                    ftyp: "Business",
                    extra: "15_Business",
                    chekbcode: "JBAG",
                    chanb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Change of Flight is Free,NOSHOWUPTO 720 hrs of departure Change of Flight is Free,NOSHOWAFTER From 720 hrs before  of departure Change of Flight is Non-Refundable",
                    canb: "Chargeable",
                    cabb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Cancellation of Flight is Free,NOSHOWUPTO 720 hrs of departure Cancellation of Flight is Free,NOSHOWAFTER From 720 hrs before  of departure Cancellation of Flight is Non-Refundable",
                    bagage_info: "40kg Baggage allowance Business",
                    markupnames: [],
                    ttf: 61002.09,
                  },
                  paxpr: [
                    {
                      bf: 31495,
                      tf: 61002.09,
                      tax: [
                        { OT: 29507.09 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "CRM9IN2",
                      ptype: "ADT",
                      scur: "INR",
                      extra:
                        "1_62_C_1_3_0_tx-1764:1370:QA:0_2186:170:PZ:0_4246:22970:YQ:0_6544:0:JBAG:0_8966:1370:G4:0_9327:2487.09:K3:0_10386:120:ZR:0_11947:790:F6:0_13230:230:R9:0_",
                      ruleid:
                        "V3SroXFnNB8NBoMNZToukJMUYXFX0odry/AcSKbL2hBXbD3FLZ5ZejAuNYMbABAoyAFAYCcKjnEbjqUoRC2w3mDGJNH37aAsxUou/hEUve1ewJ9aVhK57wibWY2PIn24f4tv7xijw4oXgcRHgmFw0E3Dg72VJaVpJZuTCj214Mb6KesHnLDJHQwOhfTxZtbdeXH1hDuwDQ+MlHme53FHEA==",
                      hsc: "571d852b2120a1a102658a93a5056fee4e5ded8573dd47a9b7cf4a23ae86231c",
                      sup_fare: "31495_61002.09_26585",
                      cabb: "Free",
                      bagage_info: "40kg Baggage allowance Business",
                      chekbcode: "JBAG",
                      psup_fare: "31495_61002.09",
                    },
                  ],
                  uniqueId: "Business",
                  routeId: "9jzla0fg4q",
                  servicefee: [],
                  selected: true,
                },
              ],
              tktair: "FZ",
              tecst: "no",
              cl: "Economy",
              extra: 13915297,
              token:
                "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiYXBpdGF3ZmVlcSIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL2dpdmVubmFtZSI6IkFQSSBUQVdGRUVRIiwiZ3JvdXBBY2Nlc3MiOiIzLDM2LDExMiIsImlzQXB0Q2xudCI6IkZhbHNlIiwibG9jYXRpb24iOiI5OTk5IiwiY2hrTGNuIjoiIiwicmVzVG9rZW4iOiJkZWRhZDdkeWY4N2FuOTc0dGJxYjY0YmJ6ZjZlOTYwYzA5ZTE5NmRhZTg2MCIsImNsaWVudElQIjoiMTcyLjE5LjIzLjU0IiwicmVzQ2hhbm5lbElEIjoiNSIsInJlc0NoYW5uZWxOYW1lIjoiVFBBUEkiLCJkZWZhdWx0VXNlck5hbWUiOiJhcGl0YXdmZWVxIiwibmJmIjoxNjg1NTE1MjM3LCJleHAiOjE2ODU1MTc2MzcsImlzcyI6ImZseWR1YmFpLmNvbSIsImF1ZCI6IlRBV0ZFRVFUUlZMU19GWl9OUCJ9.rAW3YT-bX_-yADfvg3QkuPAbOJhkhRUN6HwoS_6PyiM",
              stop: 1,
            },
          ],
          servicefee: [],
        },
        {
          srcorigns: [
            {
              tid: "BXMJ7TTJ6CSxUJXV_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "04:15",
              re: "Refundable Before Departure",
              stop: 0,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "QR",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DOH",
                  dd: "2023-06-07T03:35:00",
                  ad: "2023-06-07T05:20:00",
                  rbd: "N",
                  fn: "501",
                  eq: "788",
                  ac: "QR",
                  ma: "QR",
                  seat: "9",
                  oa: "QR",
                  fa: [],
                  fdur: "04:15",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
              ],
              flightNumbers: "501",
              bgd: [{ qun: "25", wt: "kg" }],
            },
          ],
          destorigins: [
            {
              tid: "BXMJ7TTJ6CSxUJXV_2",
              ref: 2,
              office_id: "DOHQR28IH",
              sdur: "04:15",
              re: "Refundable Before Departure",
              stop: 0,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "QR",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "HYD",
                  dd: "2023-06-09T19:15:00",
                  ad: "2023-06-10T02:00:00",
                  rbd: "V",
                  fn: "500",
                  eq: "320",
                  ac: "QR",
                  ma: "QR",
                  seat: "9",
                  oa: "QR",
                  fa: [],
                  fdur: "04:15",
                  cl: "Economy",
                  terminal: { dep: "", arr: "" },
                },
              ],
              flightNumbers: "500",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          pr: {
            bf: 63817.6,
            tf: 95849.6,
            tax: [
              { YQ: 19712 },
              { YR: 1792 },
              { OT: 10528 },
              { dealmkp: 0 },
              { attmkp: 5801.6 },
              { tamkp: 0 },
              { stamkp: 0 },
              { attdisc: 0 },
              { tadisc: 0 },
              { stadisc: 0 },
            ],
            scur: "QAR",
            ucur: "INR",
            exchange_rate: 22.4,
            sup_fare: "2590_4020",
            markupnames: [{ code: "MTA", name: "LaxmiAprilMarkup" }],
          },
          paxpr: [
            {
              tf: 95849.6,
              tax: [
                { YQ: 19712 },
                { YR: 1792 },
                { OT: 10528 },
                { attmkp: 5801.6 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              bf: 63817.6,
              fbc: "NLR4R1RX",
              fareType: "RP",
              pusup_fare: "2590_4020",
              psup_fare: "58016_90048",
              ptype: "ADT",
            },
          ],
          sup: "0",
          airlinesList: "QR",
          servicefee: [
            {
              type: "ATT",
              adt: 6381.76,
              chd: 0,
              inf: 0,
              servicefee_name: "LaxmiServieApril",
              apl_on: "Per Ticket",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 6381.76,
              sup_fee: 0,
            },
            {
              type: "TA",
              adt: 0,
              chd: 0,
              inf: 0,
              apl_on: "",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 0,
              sup_fee: 6381.76,
            },
          ],
        },
        {
          srcorigns: [
            {
              tid: "txcvgiB8NfIuOADm_1",
              ref: 1,
              office_id: "",
              sdur: "8:25",
              re: "",
              sup: "5",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DXB",
                  dd: "2023-06-07T03:00:00",
                  ad: "2023-06-07T05:30:00",
                  rbd: "",
                  fn: "436",
                  trn: "",
                  eq: "73B",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "", arr: "2" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161457,
                  flightTime: 240,
                  fdur: "4:0",
                },
                {
                  ref: 2,
                  da: "DXB",
                  ar: "DOH",
                  dd: "2023-06-07T08:45:00",
                  ad: "2023-06-07T08:55:00",
                  rbd: "",
                  fn: "001",
                  trn: "",
                  eq: "73X",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "2", arr: "1" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161405,
                  flightTime: 70,
                  fdur: "1:10",
                },
              ],
              fareop: [
                {
                  fareKey: [],
                  pr: {
                    bf: 14165,
                    tf: 45034.38,
                    tax: [
                      { OT: 30869.38 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    scur: "INR",
                    ucur: "INR",
                    exchange_rate: 1,
                    sup_fare: "14165_45034.38",
                    sup_cur_fare: "",
                    cabb: "Free",
                    chekb: "14kg INCLUDED",
                    meals: "Chargeable",
                    chanb: "Chargeable",
                    ftyp: "Business",
                    extra: "15_Business",
                    chekbcode: "JBAG",
                    chanb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Change of Flight is Free,NOSHOWUPTO 720 hrs of departure Change of Flight is Free,NOSHOWAFTER From 720 hrs before  of departure Change of Flight is Non-Refundable",
                    canb: "Chargeable",
                    cabb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Cancellation of Flight is Free,NOSHOWUPTO 720 hrs of departure Cancellation of Flight is Free,NOSHOWAFTER From 720 hrs before  of departure Cancellation of Flight is Non-Refundable",
                    bagage_info: "40kg Baggage allowance Business",
                    markupnames: [],
                    ttf: 45034.38,
                  },
                  paxpr: [
                    {
                      bf: 14165,
                      tf: 45034.38,
                      tax: [
                        { OT: 30869.38 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "ZRM9IN2",
                      ptype: "ADT",
                      scur: "INR",
                      extra:
                        "1_31_Z_1_5_0_tx-2186:170:PZ:0_4246:22970:YQ:0_6544:0:JBAG:0_9327:4044.38:K3:0_10386:120:ZR:0_11947:790:F6:0_12006:1170:P2:0_12434:1605:IN:0_",
                      ruleid:
                        "9GCDccPgntE4zOCyJ4gwljWE439NribcBOUajw99xT+o8LotKkPr9+rRgWI1VO0yvTml2i2hCEBikqdUYpBdQICMSUE+oAXNhfzC1AvPpsdXVy0HLLTzOxy6vo4KsNKyH4iOhL3qCTzeoIR/oeSzY4TEtPO2CThrAiJMBJQHPOZVOijsN/0s5eElrsthvOXj",
                      hsc: "f9e3c305add75d5cf6912b8c8b772a74efd12e61318267e350a026c78144b5db",
                      sup_fare: "14165_45034.38_10685",
                      cabb: "Free",
                      bagage_info: "40kg Baggage allowance Business",
                      chekbcode: "JBAG",
                      psup_fare: "14165_45034.38",
                    },
                  ],
                  uniqueId: "Business",
                  routeId: "w561a2jqrvg",
                  servicefee: [],
                  selected: true,
                },
              ],
              tktair: "FZ",
              tecst: "no",
              cl: "Economy",
              extra: 13919293,
              token:
                "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiYXBpdGF3ZmVlcSIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL2dpdmVubmFtZSI6IkFQSSBUQVdGRUVRIiwiZ3JvdXBBY2Nlc3MiOiIzLDM2LDExMiIsImlzQXB0Q2xudCI6IkZhbHNlIiwibG9jYXRpb24iOiI5OTk5IiwiY2hrTGNuIjoiIiwicmVzVG9rZW4iOiJkZWRhZDdkeWY4N2FuOTc0dGJxYjY0YmJ6ZjZlOTYwYzA5ZTE5NmRhZTg2MCIsImNsaWVudElQIjoiMTcyLjE5LjIzLjU0IiwicmVzQ2hhbm5lbElEIjoiNSIsInJlc0NoYW5uZWxOYW1lIjoiVFBBUEkiLCJkZWZhdWx0VXNlck5hbWUiOiJhcGl0YXdmZWVxIiwibmJmIjoxNjg1NTE1MjM3LCJleHAiOjE2ODU1MTc2MzcsImlzcyI6ImZseWR1YmFpLmNvbSIsImF1ZCI6IlRBV0ZFRVFUUlZMU19GWl9OUCJ9.rAW3YT-bX_-yADfvg3QkuPAbOJhkhRUN6HwoS_6PyiM",
              stop: 1,
            },
            {
              tid: "58KhCTqGTMivdzdg_1",
              ref: 1,
              office_id: "",
              sdur: "15:25",
              re: "",
              sup: "5",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DXB",
                  dd: "2023-06-07T03:00:00",
                  ad: "2023-06-07T05:30:00",
                  rbd: "",
                  fn: "436",
                  trn: "",
                  eq: "73B",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "", arr: "2" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161457,
                  flightTime: 240,
                  fdur: "4:0",
                },
                {
                  ref: 2,
                  da: "DXB",
                  ar: "DOH",
                  dd: "2023-06-07T15:45:00",
                  ad: "2023-06-07T15:55:00",
                  rbd: "",
                  fn: "017",
                  trn: "",
                  eq: "73X",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "2", arr: "1" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161400,
                  flightTime: 70,
                  fdur: "1:10",
                },
              ],
              fareop: [
                {
                  fareKey: [],
                  pr: {
                    bf: 14165,
                    tf: 45034.38,
                    tax: [
                      { OT: 30869.38 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    scur: "INR",
                    ucur: "INR",
                    exchange_rate: 1,
                    sup_fare: "14165_45034.38",
                    sup_cur_fare: "",
                    cabb: "Free",
                    chekb: "14kg INCLUDED",
                    meals: "Chargeable",
                    chanb: "Chargeable",
                    ftyp: "Business",
                    extra: "15_Business",
                    chekbcode: "JBAG",
                    chanb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Change of Flight is Free,NOSHOWUPTO 720 hrs of departure Change of Flight is Free,NOSHOWAFTER From 720 hrs before  of departure Change of Flight is Non-Refundable",
                    canb: "Chargeable",
                    cabb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Cancellation of Flight is Free,NOSHOWUPTO 720 hrs of departure Cancellation of Flight is Free,NOSHOWAFTER From 720 hrs before  of departure Cancellation of Flight is Non-Refundable",
                    bagage_info: "40kg Baggage allowance Business",
                    markupnames: [],
                    ttf: 45034.38,
                  },
                  paxpr: [
                    {
                      bf: 14165,
                      tf: 45034.38,
                      tax: [
                        { OT: 30869.38 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "ZRM9IN2",
                      ptype: "ADT",
                      scur: "INR",
                      extra:
                        "1_95_Z_1_5_0_tx-2186:170:PZ:0_4246:22970:YQ:0_6544:0:JBAG:0_9327:4044.38:K3:0_10386:120:ZR:0_11947:790:F6:0_12006:1170:P2:0_12434:1605:IN:0_",
                      ruleid:
                        "eLEAVySC6hIKyMd8n4SoxzWE439NribcBOUajw99xT+o8LotKkPr9+rRgWI1VO0yvTml2i2hCEBikqdUYpBdQICMSUE+oAXNhfzC1AvPpsdXVy0HLLTzOxy6vo4KsNKyH4iOhL3qCTzeoIR/oeSzY4TEtPO2CThrAiJMBJQHPOZVOijsN/0s5eElrsthvOXj",
                      hsc: "0006d7d277ba524b30470a95c048fadd892d1a9ab30f5e4bc21242fa1c4f598e",
                      sup_fare: "14165_45034.38_10685",
                      cabb: "Free",
                      bagage_info: "40kg Baggage allowance Business",
                      chekbcode: "JBAG",
                      psup_fare: "14165_45034.38",
                    },
                  ],
                  uniqueId: "Business",
                  routeId: "3n8ue753qh",
                  servicefee: [],
                  selected: true,
                },
              ],
              tktair: "FZ",
              tecst: "no",
              cl: "Economy",
              extra: 13919296,
              token:
                "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiYXBpdGF3ZmVlcSIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL2dpdmVubmFtZSI6IkFQSSBUQVdGRUVRIiwiZ3JvdXBBY2Nlc3MiOiIzLDM2LDExMiIsImlzQXB0Q2xudCI6IkZhbHNlIiwibG9jYXRpb24iOiI5OTk5IiwiY2hrTGNuIjoiIiwicmVzVG9rZW4iOiJkZWRhZDdkeWY4N2FuOTc0dGJxYjY0YmJ6ZjZlOTYwYzA5ZTE5NmRhZTg2MCIsImNsaWVudElQIjoiMTcyLjE5LjIzLjU0IiwicmVzQ2hhbm5lbElEIjoiNSIsInJlc0NoYW5uZWxOYW1lIjoiVFBBUEkiLCJkZWZhdWx0VXNlck5hbWUiOiJhcGl0YXdmZWVxIiwibmJmIjoxNjg1NTE1MjM3LCJleHAiOjE2ODU1MTc2MzcsImlzcyI6ImZseWR1YmFpLmNvbSIsImF1ZCI6IlRBV0ZFRVFUUlZMU19GWl9OUCJ9.rAW3YT-bX_-yADfvg3QkuPAbOJhkhRUN6HwoS_6PyiM",
              stop: 1,
            },
            {
              tid: "NR7JqelPzTyEnw8X_1",
              ref: 1,
              office_id: "",
              sdur: "19:25",
              re: "",
              sup: "5",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DXB",
                  dd: "2023-06-07T03:00:00",
                  ad: "2023-06-07T05:30:00",
                  rbd: "",
                  fn: "436",
                  trn: "",
                  eq: "73B",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "", arr: "2" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161457,
                  flightTime: 240,
                  fdur: "4:0",
                },
                {
                  ref: 2,
                  da: "DXB",
                  ar: "DOH",
                  dd: "2023-06-07T19:45:00",
                  ad: "2023-06-07T19:55:00",
                  rbd: "",
                  fn: "005",
                  trn: "",
                  eq: "73X",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "2", arr: "1" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161381,
                  flightTime: 70,
                  fdur: "1:10",
                },
              ],
              fareop: [
                {
                  fareKey: [],
                  pr: {
                    bf: 14165,
                    tf: 45034.38,
                    tax: [
                      { OT: 30869.38 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    scur: "INR",
                    ucur: "INR",
                    exchange_rate: 1,
                    sup_fare: "14165_45034.38",
                    sup_cur_fare: "",
                    cabb: "Free",
                    chekb: "14kg INCLUDED",
                    meals: "Chargeable",
                    chanb: "Chargeable",
                    ftyp: "Business",
                    extra: "15_Business",
                    chekbcode: "JBAG",
                    chanb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Change of Flight is Free,NOSHOWUPTO 720 hrs of departure Change of Flight is Free,NOSHOWAFTER From 720 hrs before  of departure Change of Flight is Non-Refundable",
                    canb: "Chargeable",
                    cabb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Cancellation of Flight is Free,NOSHOWUPTO 720 hrs of departure Cancellation of Flight is Free,NOSHOWAFTER From 720 hrs before  of departure Cancellation of Flight is Non-Refundable",
                    bagage_info: "40kg Baggage allowance Business",
                    markupnames: [],
                    ttf: 45034.38,
                  },
                  paxpr: [
                    {
                      bf: 14165,
                      tf: 45034.38,
                      tax: [
                        { OT: 30869.38 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "ZRM9IN2",
                      ptype: "ADT",
                      scur: "INR",
                      extra:
                        "1_127_Z_1_5_0_tx-2186:170:PZ:0_4246:22970:YQ:0_6544:0:JBAG:0_9327:4044.38:K3:0_10386:120:ZR:0_11947:790:F6:0_12006:1170:P2:0_12434:1605:IN:0_",
                      ruleid:
                        "/ruFSazPtauzldzDLxPomKNXn6aA9MtcY0N9tFO6QOwKxrc4UYGO2IzDMPt/j9KfyZrzxNnMdcOJwgpzMIdukRM1YiBqo5VUngE+f6ZTlrcWhTrLfc+1HjPslNbsK6Ma+NzJ9vs0yTvfIGzHK+7a6QYdbLMNnyuqz+GQYY8X4udk3IvNdcymNm5f/d40hxVF",
                      hsc: "987e254959b934a71fe9c77980cebc2e1ba6592c494ac16fa11ea129b87a7f58",
                      sup_fare: "14165_45034.38_10685",
                      cabb: "Free",
                      bagage_info: "40kg Baggage allowance Business",
                      chekbcode: "JBAG",
                      psup_fare: "14165_45034.38",
                    },
                  ],
                  uniqueId: "Business",
                  routeId: "mjtcq5cslu",
                  servicefee: [],
                  selected: true,
                },
              ],
              tktair: "FZ",
              tecst: "no",
              cl: "Economy",
              extra: 13919294,
              token:
                "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiYXBpdGF3ZmVlcSIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL2dpdmVubmFtZSI6IkFQSSBUQVdGRUVRIiwiZ3JvdXBBY2Nlc3MiOiIzLDM2LDExMiIsImlzQXB0Q2xudCI6IkZhbHNlIiwibG9jYXRpb24iOiI5OTk5IiwiY2hrTGNuIjoiIiwicmVzVG9rZW4iOiJkZWRhZDdkeWY4N2FuOTc0dGJxYjY0YmJ6ZjZlOTYwYzA5ZTE5NmRhZTg2MCIsImNsaWVudElQIjoiMTcyLjE5LjIzLjU0IiwicmVzQ2hhbm5lbElEIjoiNSIsInJlc0NoYW5uZWxOYW1lIjoiVFBBUEkiLCJkZWZhdWx0VXNlck5hbWUiOiJhcGl0YXdmZWVxIiwibmJmIjoxNjg1NTE1MjM3LCJleHAiOjE2ODU1MTc2MzcsImlzcyI6ImZseWR1YmFpLmNvbSIsImF1ZCI6IlRBV0ZFRVFUUlZMU19GWl9OUCJ9.rAW3YT-bX_-yADfvg3QkuPAbOJhkhRUN6HwoS_6PyiM",
              stop: 1,
            },
          ],
          paxpr: [
            {
              bf: 45660,
              tf: 109505,
              tax: [
                { OT: 63845 },
                { attmkp: 0 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              fbc: "ZRM9IN2",
              ptype: "ADT",
              scur: "INR",
              extra:
                "1_31_Z_1_5_0_tx-2186:170:PZ:0_4246:22970:YQ:0_6544:0:JBAG:0_9327:4044.38:K3:0_10386:120:ZR:0_11947:790:F6:0_12006:1170:P2:0_12434:1605:IN:0_",
              ruleid:
                "9GCDccPgntE4zOCyJ4gwljWE439NribcBOUajw99xT+o8LotKkPr9+rRgWI1VO0yvTml2i2hCEBikqdUYpBdQICMSUE+oAXNhfzC1AvPpsdXVy0HLLTzOxy6vo4KsNKyH4iOhL3qCTzeoIR/oeSzY4TEtPO2CThrAiJMBJQHPOZVOijsN/0s5eElrsthvOXj",
              hsc: "f9e3c305add75d5cf6912b8c8b772a74efd12e61318267e350a026c78144b5db",
              sup_fare: "14165_45034.38_10685",
              cabb: "Free",
              bagage_info: "40kg Baggage allowance Business",
              chekbcode: "JBAG",
              psup_fare: "45660_109505",
            },
          ],
          pr: {
            bf: 45660,
            tf: 109505,
            tax: [
              { OT: 63845 },
              { attmkp: 0 },
              { tamkp: 0 },
              { stamkp: 0 },
              { attdisc: 0 },
              { tadisc: 0 },
              { stadisc: 0 },
            ],
            scur: "INR",
            ucur: "INR",
            exchange_rate: 1,
            sup_fare: "45660_109505",
            sup_cur_fare: "",
            cabb: "Free",
            chekb: "14kg INCLUDED",
            meals: "Chargeable",
            chanb: "Chargeable",
            ftyp: "Business",
            extra: "15_Business",
            chekbcode: "JBAG",
            chanb_info:
              "BEFOREDEPARTURE 0 hrs of departure Change of Flight is Free,NOSHOWUPTO 720 hrs of departure Change of Flight is Free,NOSHOWAFTER From 720 hrs before  of departure Change of Flight is Non-Refundable",
            canb: "Chargeable",
            cabb_info:
              "BEFOREDEPARTURE 0 hrs of departure Cancellation of Flight is Free,NOSHOWUPTO 720 hrs of departure Cancellation of Flight is Free,NOSHOWAFTER From 720 hrs before  of departure Cancellation of Flight is Non-Refundable",
            bagage_info: "40kg Baggage allowance Business",
            markupnames: [],
            ttf: 109505,
          },
          sup: "5",
          airlineList: "FZ",
          destorigins: [
            {
              tid: "KU7qv3OVpfOnrZOK_2",
              ref: 2,
              office_id: "",
              sdur: "6:45",
              re: "",
              sup: "5",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DXB",
                  dd: "2023-06-09T17:00:00",
                  ad: "2023-06-09T19:15:00",
                  rbd: "",
                  fn: "018",
                  trn: "",
                  eq: "73X",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "1", arr: "2" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161399,
                  flightTime: 75,
                  fdur: "1:15",
                },
                {
                  ref: 2,
                  da: "DXB",
                  ar: "HYD",
                  dd: "2023-06-09T20:50:00",
                  ad: "2023-06-10T02:15:00",
                  rbd: "",
                  fn: "435",
                  trn: "",
                  eq: "73B",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "2", arr: "" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161456,
                  flightTime: 235,
                  fdur: "3:55",
                },
              ],
              fareop: [
                {
                  fareKey: [],
                  pr: {
                    bf: 31495,
                    tf: 64470.62,
                    tax: [
                      { OT: 32975.619999999995 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    scur: "INR",
                    ucur: "INR",
                    exchange_rate: 1,
                    sup_fare: "31495_64470.62",
                    sup_cur_fare: "",
                    cabb: "Free",
                    chekb: "14kg INCLUDED",
                    meals: "Chargeable",
                    chanb: "Chargeable",
                    ftyp: "Business",
                    extra: "15_Business",
                    chekbcode: "JBAG",
                    chanb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Change of Flight is Free,NOSHOWUPTO 720 hrs of departure Change of Flight is Free,NOSHOWAFTER From 720 hrs before  of departure Change of Flight is Non-Refundable",
                    canb: "Chargeable",
                    cabb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Cancellation of Flight is Free,NOSHOWUPTO 720 hrs of departure Cancellation of Flight is Free,NOSHOWAFTER From 720 hrs before  of departure Cancellation of Flight is Non-Refundable",
                    bagage_info: "40kg Baggage allowance Business",
                    markupnames: [],
                    ttf: 64470.62,
                  },
                  paxpr: [
                    {
                      bf: 31495,
                      tf: 64470.62,
                      tax: [
                        { OT: 32975.619999999995 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "CRM9IN2",
                      ptype: "ADT",
                      scur: "INR",
                      extra:
                        "1_32_C_1_3_0_tx-1764:1370:QA:0_2186:170:PZ:0_4246:22970:YQ:0_6544:0:JBAG:0_8966:1370:G4:0_9327:5955.62:K3:0_10386:120:ZR:0_11947:790:F6:0_13230:230:R9:0_",
                      ruleid:
                        "9mUh6F95gSrwqBMJbDABBpMUYXFX0odry/AcSKbL2hBXbD3FLZ5ZejAuNYMbABAoyAFAYCcKjnEbjqUoRC2w3mDGJNH37aAsxUou/hEUve1ewJ9aVhK57wibWY2PIn24f4tv7xijw4oXgcRHgmFw0E3Dg72VJaVpJZuTCj214Mb6KesHnLDJHQwOhfTxZtbdeXH1hDuwDQ+MlHme53FHEA==",
                      hsc: "b96b700c37cf5609508ed8a2b9c4e7e0788bf12ec3cce80896a1624588ee255b",
                      sup_fare: "31495_64470.62_26585",
                      cabb: "Free",
                      bagage_info: "40kg Baggage allowance Business",
                      chekbcode: "JBAG",
                      psup_fare: "31495_64470.62",
                    },
                  ],
                  uniqueId: "Business",
                  routeId: "zldsorjuvf",
                  servicefee: [],
                  selected: true,
                },
              ],
              tktair: "FZ",
              tecst: "no",
              cl: "Economy",
              extra: 13916393,
              token:
                "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiYXBpdGF3ZmVlcSIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL2dpdmVubmFtZSI6IkFQSSBUQVdGRUVRIiwiZ3JvdXBBY2Nlc3MiOiIzLDM2LDExMiIsImlzQXB0Q2xudCI6IkZhbHNlIiwibG9jYXRpb24iOiI5OTk5IiwiY2hrTGNuIjoiIiwicmVzVG9rZW4iOiJkZWRhZDdkeWY4N2FuOTc0dGJxYjY0YmJ6ZjZlOTYwYzA5ZTE5NmRhZTg2MCIsImNsaWVudElQIjoiMTcyLjE5LjIzLjU0IiwicmVzQ2hhbm5lbElEIjoiNSIsInJlc0NoYW5uZWxOYW1lIjoiVFBBUEkiLCJkZWZhdWx0VXNlck5hbWUiOiJhcGl0YXdmZWVxIiwibmJmIjoxNjg1NTE1MjM3LCJleHAiOjE2ODU1MTc2MzcsImlzcyI6ImZseWR1YmFpLmNvbSIsImF1ZCI6IlRBV0ZFRVFUUlZMU19GWl9OUCJ9.rAW3YT-bX_-yADfvg3QkuPAbOJhkhRUN6HwoS_6PyiM",
              stop: 1,
            },
            {
              tid: "1QubLH0WyzS0hmze_2",
              ref: 2,
              office_id: "",
              sdur: "13:45",
              re: "",
              sup: "5",
              ft: "",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DXB",
                  dd: "2023-06-09T10:00:00",
                  ad: "2023-06-09T12:15:00",
                  rbd: "",
                  fn: "002",
                  trn: "",
                  eq: "73X",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "1", arr: "2" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161402,
                  flightTime: 75,
                  fdur: "1:15",
                },
                {
                  ref: 2,
                  da: "DXB",
                  ar: "HYD",
                  dd: "2023-06-09T20:50:00",
                  ad: "2023-06-10T02:15:00",
                  rbd: "",
                  fn: "435",
                  trn: "",
                  eq: "73B",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "",
                  oa: "FZ",
                  fa: [],
                  terminal: { dep: "2", arr: "" },
                  rph: "",
                  jsell: "",
                  cl: "Economy",
                  segmentID: 161456,
                  flightTime: 235,
                  fdur: "3:55",
                },
              ],
              fareop: [
                {
                  fareKey: [],
                  pr: {
                    bf: 31495,
                    tf: 64470.62,
                    tax: [
                      { OT: 32975.619999999995 },
                      { attmkp: 0 },
                      { tamkp: 0 },
                      { stamkp: 0 },
                      { attdisc: 0 },
                      { tadisc: 0 },
                      { stadisc: 0 },
                    ],
                    scur: "INR",
                    ucur: "INR",
                    exchange_rate: 1,
                    sup_fare: "31495_64470.62",
                    sup_cur_fare: "",
                    cabb: "Free",
                    chekb: "14kg INCLUDED",
                    meals: "Chargeable",
                    chanb: "Chargeable",
                    ftyp: "Business",
                    extra: "15_Business",
                    chekbcode: "JBAG",
                    chanb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Change of Flight is Free,NOSHOWUPTO 720 hrs of departure Change of Flight is Free,NOSHOWAFTER From 720 hrs before  of departure Change of Flight is Non-Refundable",
                    canb: "Chargeable",
                    cabb_info:
                      "BEFOREDEPARTURE 0 hrs of departure Cancellation of Flight is Free,NOSHOWUPTO 720 hrs of departure Cancellation of Flight is Free,NOSHOWAFTER From 720 hrs before  of departure Cancellation of Flight is Non-Refundable",
                    bagage_info: "40kg Baggage allowance Business",
                    markupnames: [],
                    ttf: 64470.62,
                  },
                  paxpr: [
                    {
                      bf: 31495,
                      tf: 64470.62,
                      tax: [
                        { OT: 32975.619999999995 },
                        { attmkp: 0 },
                        { tamkp: 0 },
                        { stamkp: 0 },
                        { attdisc: 0 },
                        { tadisc: 0 },
                        { stadisc: 0 },
                      ],
                      fbc: "CRM9IN2",
                      ptype: "ADT",
                      scur: "INR",
                      extra:
                        "1_64_C_1_3_0_tx-1764:1370:QA:0_2186:170:PZ:0_4246:22970:YQ:0_6544:0:JBAG:0_8966:1370:G4:0_9327:5955.62:K3:0_10386:120:ZR:0_11947:790:F6:0_13230:230:R9:0_",
                      ruleid:
                        "oNTrr4T4Vo9l2jvGNPfU55MUYXFX0odry/AcSKbL2hBXbD3FLZ5ZejAuNYMbABAoyAFAYCcKjnEbjqUoRC2w3mDGJNH37aAsxUou/hEUve1ewJ9aVhK57wibWY2PIn24f4tv7xijw4oXgcRHgmFw0E3Dg72VJaVpJZuTCj214Mb6KesHnLDJHQwOhfTxZtbdeXH1hDuwDQ+MlHme53FHEA==",
                      hsc: "a79d9fccfff8422dcc222c3729aa95cc0c70842f3320a6d15e2d78aeb61939b4",
                      sup_fare: "31495_64470.62_26585",
                      cabb: "Free",
                      bagage_info: "40kg Baggage allowance Business",
                      chekbcode: "JBAG",
                      psup_fare: "31495_64470.62",
                    },
                  ],
                  uniqueId: "Business",
                  routeId: "a70r3ni016",
                  servicefee: [],
                  selected: true,
                },
              ],
              tktair: "FZ",
              tecst: "no",
              cl: "Economy",
              extra: 13915297,
              token:
                "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiYXBpdGF3ZmVlcSIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL2dpdmVubmFtZSI6IkFQSSBUQVdGRUVRIiwiZ3JvdXBBY2Nlc3MiOiIzLDM2LDExMiIsImlzQXB0Q2xudCI6IkZhbHNlIiwibG9jYXRpb24iOiI5OTk5IiwiY2hrTGNuIjoiIiwicmVzVG9rZW4iOiJkZWRhZDdkeWY4N2FuOTc0dGJxYjY0YmJ6ZjZlOTYwYzA5ZTE5NmRhZTg2MCIsImNsaWVudElQIjoiMTcyLjE5LjIzLjU0IiwicmVzQ2hhbm5lbElEIjoiNSIsInJlc0NoYW5uZWxOYW1lIjoiVFBBUEkiLCJkZWZhdWx0VXNlck5hbWUiOiJhcGl0YXdmZWVxIiwibmJmIjoxNjg1NTE1MjM3LCJleHAiOjE2ODU1MTc2MzcsImlzcyI6ImZseWR1YmFpLmNvbSIsImF1ZCI6IlRBV0ZFRVFUUlZMU19GWl9OUCJ9.rAW3YT-bX_-yADfvg3QkuPAbOJhkhRUN6HwoS_6PyiM",
              stop: 1,
            },
          ],
          servicefee: [],
        },
        {
          srcorigns: [
            {
              tid: "z2brKNvt0mL70m52_1",
              ref: 1,
              office_id: "DOHQR28IH",
              sdur: "06:50",
              re: "Refundable Before Departure",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "EK",
              seg: [
                {
                  ref: 1,
                  da: "HYD",
                  ar: "DXB",
                  dd: "2023-06-07T04:35:00",
                  ad: "2023-06-07T06:40:00",
                  rbd: "U",
                  fn: "525",
                  eq: "77W",
                  ac: "EK",
                  ma: "EK",
                  seat: "9",
                  oa: "EK",
                  fa: [],
                  fdur: "03:35",
                  cl: "Economy",
                  terminal: { dep: "", arr: "3" },
                },
                {
                  ref: 2,
                  da: "DXB",
                  ar: "DOH",
                  dd: "2023-06-07T08:45:00",
                  ad: "2023-06-07T08:55:00",
                  rbd: "M",
                  fn: "1",
                  trn: "2",
                  eq: "7M8",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "4",
                  oa: "FZ",
                  fa: [],
                  fdur: "01:10",
                  cl: "Economy",
                  terminal: { dep: "2", arr: "" },
                },
              ],
              flightNumbers: "525/1",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          destorigins: [
            {
              tid: "z2brKNvt0mL70m52_2",
              ref: 2,
              office_id: "DOHQR28IH",
              sdur: "07:35",
              re: "Refundable Before Departure",
              stop: 1,
              sup: "0",
              cl: "Economy",
              ft: "GDS",
              tecst: "yes",
              dc: "no",
              dealcode: [],
              tktair: "EK",
              seg: [
                {
                  ref: 1,
                  da: "DOH",
                  ar: "DXB",
                  dd: "2023-06-09T17:00:00",
                  ad: "2023-06-09T19:15:00",
                  rbd: "M",
                  fn: "18",
                  eq: "7M8",
                  ac: "FZ",
                  ma: "FZ",
                  seat: "9",
                  oa: "FZ",
                  fa: [],
                  fdur: "01:15",
                  cl: "Economy",
                  terminal: { dep: "", arr: "2" },
                },
                {
                  ref: 2,
                  da: "DXB",
                  ar: "HYD",
                  dd: "2023-06-09T22:00:00",
                  ad: "2023-06-10T03:05:00",
                  rbd: "U",
                  fn: "524",
                  trn: "3",
                  eq: "77W",
                  ac: "EK",
                  ma: "EK",
                  seat: "6",
                  oa: "EK",
                  fa: [],
                  fdur: "03:35",
                  cl: "Economy",
                  terminal: { dep: "3", arr: "" },
                },
              ],
              flightNumbers: "18/524",
              bgd: [{ qun: "30", wt: "kg" }],
            },
          ],
          pr: {
            bf: 72441.6,
            tf: 102457.6,
            tax: [
              { YQ: 17024 },
              { OT: 12992 },
              { dealmkp: 0 },
              { attmkp: 6585.6 },
              { tamkp: 0 },
              { stamkp: 0 },
              { attdisc: 0 },
              { tadisc: 0 },
              { stadisc: 0 },
            ],
            scur: "QAR",
            ucur: "INR",
            exchange_rate: 22.4,
            sup_fare: "2940_4280",
            markupnames: [{ code: "MTA", name: "LaxmiAprilMarkup" }],
          },
          paxpr: [
            {
              tf: 102457.6,
              tax: [
                { YQ: 17024 },
                { OT: 12992 },
                { attmkp: 6585.6 },
                { tamkp: 0 },
                { stamkp: 0 },
                { attdisc: 0 },
                { tadisc: 0 },
                { stadisc: 0 },
              ],
              bf: 72441.6,
              fbc: "ULXESIN1",
              fareType: "RP",
              pusup_fare: "2940_4280",
              psup_fare: "65856_95872",
              ptype: "ADT",
            },
          ],
          sup: "0",
          airlinesList: "EK_FZ",
          servicefee: [
            {
              type: "ATT",
              adt: 7244.16,
              chd: 0,
              inf: 0,
              servicefee_name: "LaxmiServieApril",
              apl_on: "Per Ticket",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 7244.16,
              sup_fee: 0,
            },
            {
              type: "TA",
              adt: 0,
              chd: 0,
              inf: 0,
              apl_on: "",
              sf: 0,
              sf_disc: 0,
              adt_sf_disc: 0,
              chd_sf_disc: 0,
              inf_sf_disc: 0,
              complete_sfvalue: 0,
              sup_fee: 7244.16,
            },
          ],
        },
      ],
      mappings: [
        {
          airportCode: "HYD",
          airportName: "Rajiv Gandhi International Airport",
          cityName: "Hyderabad",
          countryName: "India",
          countryCode: "IN",
        },
        {
          airportCode: "HYD",
          airportName: "Rajiv Gandhi International Airport",
          cityName: "Hyderabad",
          countryName: "India",
          countryCode: "IN",
        },
        {
          airportCode: "HYD",
          airportName: "Rajiv Gandhi International Airport",
          cityName: "Hyderabad",
          countryName: "India",
          countryCode: "IN",
        },
        {
          airportCode: "BOM",
          airportName: "Chhatrapati Shivaji International Airport",
          cityName: "Mumbai (Bombay)",
          countryName: "India",
          countryCode: "IN",
        },
        {
          airportCode: "MAA",
          airportName: "Chennai International Airport",
          cityName: "Chennai",
          countryName: "India",
          countryCode: "IN",
        },
        {
          airportCode: "DEL",
          airportName: "Indira Gandhi International Airport",
          cityName: "New Delhi",
          countryName: "India",
          countryCode: "IN",
        },
        {
          airportCode: "DOH",
          airportName: "Hamad International Airport",
          cityName: "Doha",
          countryName: "Qatar",
          countryCode: "QA",
        },
        {
          airportCode: "HYD",
          airportName: "Hyderabad All",
          countryName: "India",
          countryCode: "IN",
        },
        {
          airportCode: "CCU",
          airportName: "Netaji Subhas Chandra Bose International Airport",
          cityName: "Kolkata",
          countryName: "India",
          countryCode: "IN",
        },
        {
          airportCode: "HYD",
          airportName: "Rajiv Gandhi International Airport",
          cityName: "Hyderabad",
          countryName: "India",
          countryCode: "IN",
        },
        {
          airportCode: "BLR",
          airportName: "Kempegowda International Airport",
          cityName: "Bangalore",
          countryName: "India",
          countryCode: "IN",
        },
        {
          airportCode: "BOM",
          airportName: "Chhatrapati Shivaji International Airport",
          cityName: "Mumbai (Bombay)",
          countryName: "India",
          countryCode: "IN",
        },
        {
          airportCode: "MCT",
          airportName: "Muscat International Airport",
          cityName: "Muscat",
          countryName: "Oman",
          countryCode: "OM",
        },
        {
          airportCode: "CMB",
          airportName: "Bandaranayake International Airport",
          cityName: "Colombo",
          countryName: "Sri Lanka",
          countryCode: "LK",
        },
        {
          airportCode: "DEL",
          airportName: "Indira Gandhi International Airport",
          cityName: "New Delhi",
          countryName: "India",
          countryCode: "IN",
        },
        {
          airportCode: "DOH",
          airportName: "Hamad International Airport",
          cityName: "Doha",
          countryName: "Qatar",
          countryCode: "QA",
        },
        {
          airportCode: "DXB",
          airportName: "Dubai International Airport",
          cityName: "Dubai",
          countryName: "United Arab Emirates",
          countryCode: "AE",
        },
      ],
      filter: {
        nerby: [],
        nerbyarr: [],
        suplrs: [
          { sup_name: "LCC", sup_id: 4 },
          { sup_name: "Full Service", sup_id: 4 },
        ],
        fstst: 20675,
        chpst: 20675,
        minprc: 20675,
        maxprc: 112191.12000000001,
        best: 32269.440000000002,
        stps: [
          { count: 0, prc: 20675, airline: "6E" },
          { count: 1, prc: 22602, airline: "6E" },
          { count: 2, prc: 37582.72, airline: "AI" },
        ],
        airl: [
          { count: 0, prc: 20675, airline: "6E" },
          { count: 1, prc: 32997.62, airline: "AI" },
          { count: 1, prc: 49255, airline: "FZ" },
          { count: 1, prc: 53375.7, airline: "UL" },
          { count: 1, prc: 60298.02, airline: "WY" },
          { count: 0, prc: 81167.78, airline: "QR" },
          { count: 1, prc: 112191.12000000001, airline: "EK" },
        ],
      },
      sec: [
        { src: "HYD", des: "DOH", dd: "2023-06-07", ad: "2023-06-09", leg: 0 },
      ],
      echo: "ge3aw9no91",
      cha: "B2B",
      cid: "327",
      tt: "2",
      sty: "normal",
      cur: "INR",
      adt: 1,
      chd: 0,
      inf: 0,
      region_type: "International",
      gst: { type: "same", sgst: 9, cgst: 9, igst: 0, gst_invoice: [] },
    },
  ],
};

export const FLIGHT_RESPONSE_MULTI_CITY = {
  "status": "success",
  "data": [
      {
          "origns": [
              {
                  "srcorigns": [
                      {
                          "tid": "XiXErvNIvezsOCrc_1",
                          "ref": 1,
                          "office_id": "DOHQR28IH",
                          "sdur": "08:20",
                          "re": "Refundable Before Departure",
                          "stop": 1,
                          "sup": "0",
                          "cl": "Economy",
                          "ft": "GDS",
                          "tecst": "yes",
                          "dc": "no",
                          "dealcode": [],
                          "tktair": "UL",
                          "seg": [
                              {
                                  "ref": 1,
                                  "da": "DOH",
                                  "ar": "CMB",
                                  "dd": "2023-06-16T22:30:00",
                                  "ad": "2023-06-17T05:45:00",
                                  "rbd": "L",
                                  "fn": "218",
                                  "eq": "333",
                                  "ac": "UL",
                                  "ma": "UL",
                                  "seat": "9",
                                  "oa": "UL",
                                  "fa": [],
                                  "fdur": "04:45",
                                  "cl": "Economy",
                                  "terminal": {
                                      "dep": "",
                                      "arr": ""
                                  }
                              },
                              {
                                  "ref": 2,
                                  "da": "CMB",
                                  "ar": "HYD",
                                  "dd": "2023-06-17T07:25:00",
                                  "ad": "2023-06-17T09:20:00",
                                  "rbd": "L",
                                  "fn": "177",
                                  "eq": "32B",
                                  "ac": "UL",
                                  "ma": "UL",
                                  "seat": "9",
                                  "oa": "UL",
                                  "fa": [],
                                  "fdur": "01:55",
                                  "cl": "Economy",
                                  "terminal": {
                                      "dep": "",
                                      "arr": ""
                                  }
                              }
                          ],
                          "flightNumbers": "218/177",
                          "bgd": [
                              {
                                  "qun": "40",
                                  "wt": "kg"
                              }
                          ]
                      },
                      {
                          "tid": "XiXErvNIvezsOCrc_3",
                          "ref": 3,
                          "office_id": "DOHQR28IH",
                          "sdur": "18:50",
                          "re": "Refundable Before Departure",
                          "stop": 1,
                          "sup": "0",
                          "cl": "Economy",
                          "ft": "GDS",
                          "tecst": "yes",
                          "dc": "no",
                          "dealcode": [],
                          "tktair": "UL",
                          "seg": [
                              {
                                  "ref": 1,
                                  "da": "DOH",
                                  "ar": "KUL",
                                  "dd": "2023-06-21T01:50:00",
                                  "ad": "2023-06-21T14:40:00",
                                  "rbd": "Y",
                                  "fn": "161",
                                  "eq": "333",
                                  "ac": "MH",
                                  "ma": "MH",
                                  "seat": "9",
                                  "oa": "MH",
                                  "fa": [],
                                  "fdur": "07:50",
                                  "cl": "Economy",
                                  "terminal": {
                                      "dep": "",
                                      "arr": "1"
                                  }
                              },
                              {
                                  "ref": 2,
                                  "da": "KUL",
                                  "ar": "HYD",
                                  "dd": "2023-06-21T21:30:00",
                                  "ad": "2023-06-21T23:10:00",
                                  "rbd": "Y",
                                  "fn": "198",
                                  "trn": "1",
                                  "eq": "738",
                                  "ac": "MH",
                                  "ma": "MH",
                                  "seat": "9",
                                  "oa": "MH",
                                  "fa": [],
                                  "fdur": "04:10",
                                  "cl": "Economy",
                                  "terminal": {
                                      "dep": "1",
                                      "arr": ""
                                  }
                              }
                          ],
                          "flightNumbers": "161/198",
                          "bgd": [
                              {
                                  "qun": "35",
                                  "wt": "kg"
                              }
                          ]
                      },
                      {
                          "tid": "XiXErvNIvezsOCrc_2",
                          "ref": 2,
                          "office_id": "DOHQR28IH",
                          "sdur": "31:30",
                          "re": "Refundable Before Departure",
                          "stop": 1,
                          "sup": "0",
                          "cl": "Economy",
                          "ft": "GDS",
                          "tecst": "yes",
                          "dc": "no",
                          "dealcode": [],
                          "tktair": "UL",
                          "seg": [
                              {
                                  "ref": 1,
                                  "da": "HYD",
                                  "ar": "KUL",
                                  "dd": "2023-06-19T00:15:00",
                                  "ad": "2023-06-19T07:10:00",
                                  "rbd": "Y",
                                  "fn": "199",
                                  "eq": "738",
                                  "ac": "MH",
                                  "ma": "MH",
                                  "seat": "9",
                                  "oa": "MH",
                                  "fa": [],
                                  "fdur": "04:25",
                                  "cl": "Economy",
                                  "terminal": {
                                      "dep": "",
                                      "arr": "1"
                                  }
                              },
                              {
                                  "ref": 2,
                                  "da": "KUL",
                                  "ar": "DOH",
                                  "dd": "2023-06-20T02:55:00",
                                  "ad": "2023-06-20T05:15:00",
                                  "rbd": "Y",
                                  "fn": "164",
                                  "trn": "1",
                                  "eq": "333",
                                  "ac": "MH",
                                  "ma": "MH",
                                  "seat": "9",
                                  "oa": "MH",
                                  "fa": [],
                                  "fdur": "07:20",
                                  "cl": "Economy",
                                  "terminal": {
                                      "dep": "1",
                                      "arr": ""
                                  }
                              }
                          ],
                          "flightNumbers": "199/164",
                          "bgd": [
                              {
                                  "qun": "35",
                                  "wt": "kg"
                              }
                          ]
                      }
                  ],
                  "pr": {
                      "bf": 550950.4,
                      "tf": 564838.4,
                      "tax": [
                          {
                              "YQ": 5376
                          },
                          {
                              "OT": 8512
                          },
                          {
                              "dealmkp": 0
                          },
                          {
                              "attmkp": 50086.4
                          },
                          {
                              "tamkp": 0
                          },
                          {
                              "stamkp": 0
                          },
                          {
                              "attdisc": 0
                          },
                          {
                              "tadisc": 0
                          },
                          {
                              "stadisc": 0
                          }
                      ],
                      "scur": "QAR",
                      "ucur": "INR",
                      "exchange_rate": 22.4,
                      "sup_fare": "22360_22980",
                      "markupnames": [
                          {
                              "code": "MTA",
                              "name": "LaxmiAprilMarkup"
                          }
                      ]
                  },
                  "paxpr": [
                      {
                          "tf": 564838.4,
                          "tax": [
                              {
                                  "YQ": 5376
                              },
                              {
                                  "OT": 8512
                              },
                              {
                                  "attmkp": 50086.4
                              },
                              {
                                  "tamkp": 0
                              },
                              {
                                  "stamkp": 0
                              },
                              {
                                  "attdisc": 0
                              },
                              {
                                  "tadisc": 0
                              },
                              {
                                  "stadisc": 0
                              }
                          ],
                          "bf": 550950.4,
                          "fbc": "LOWQA",
                          "fareType": "RP",
                          "pusup_fare": "22360_22980",
                          "psup_fare": "500864_514752",
                          "ptype": "ADT"
                      }
                  ],
                  "sup": "0",
                  "airlinesList": "UL_MH",
                  "servicefee": [
                      {
                          "type": "ATT",
                          "adt": 55095.04,
                          "chd": 0,
                          "inf": 0,
                          "servicefee_name": "LaxmiServieApril",
                          "apl_on": "Per Ticket",
                          "sf": 0,
                          "sf_disc": 0,
                          "adt_sf_disc": 0,
                          "chd_sf_disc": 0,
                          "inf_sf_disc": 0,
                          "complete_sfvalue": 55095.04,
                          "sup_fee": 0
                      }
                  ]
              },
              {
                  "srcorigns": [
                      {
                          "tid": "BslnNZnFXIRtKNmO_1",
                          "ref": 1,
                          "office_id": "DOHQR28IH",
                          "sdur": "26:35",
                          "re": "Refundable Before Departure",
                          "stop": 1,
                          "sup": "0",
                          "cl": "Economy",
                          "ft": "GDS",
                          "tecst": "yes",
                          "dc": "no",
                          "dealcode": [],
                          "tktair": "UL",
                          "seg": [
                              {
                                  "ref": 1,
                                  "da": "DOH",
                                  "ar": "CMB",
                                  "dd": "2023-06-16T22:30:00",
                                  "ad": "2023-06-17T05:45:00",
                                  "rbd": "L",
                                  "fn": "218",
                                  "eq": "333",
                                  "ac": "UL",
                                  "ma": "UL",
                                  "seat": "9",
                                  "oa": "UL",
                                  "fa": [],
                                  "fdur": "04:45",
                                  "cl": "Economy",
                                  "terminal": {
                                      "dep": "",
                                      "arr": ""
                                  }
                              },
                              {
                                  "ref": 2,
                                  "da": "CMB",
                                  "ar": "HYD",
                                  "dd": "2023-06-18T01:40:00",
                                  "ad": "2023-06-18T03:35:00",
                                  "rbd": "L",
                                  "fn": "1177",
                                  "eq": "32B",
                                  "ac": "UL",
                                  "ma": "UL",
                                  "seat": "9",
                                  "oa": "UL",
                                  "fa": [],
                                  "fdur": "01:55",
                                  "cl": "Economy",
                                  "terminal": {
                                      "dep": "",
                                      "arr": ""
                                  }
                              }
                          ],
                          "flightNumbers": "218/1177",
                          "bgd": [
                              {
                                  "qun": "30",
                                  "wt": "kg"
                              }
                          ]
                      },
                      {
                          "tid": "BslnNZnFXIRtKNmO_3",
                          "ref": 3,
                          "office_id": "DOHQR28IH",
                          "sdur": "18:50",
                          "re": "Refundable Before Departure",
                          "stop": 1,
                          "sup": "0",
                          "cl": "Economy",
                          "ft": "GDS",
                          "tecst": "yes",
                          "dc": "no",
                          "dealcode": [],
                          "tktair": "UL",
                          "seg": [
                              {
                                  "ref": 1,
                                  "da": "DOH",
                                  "ar": "KUL",
                                  "dd": "2023-06-21T01:50:00",
                                  "ad": "2023-06-21T14:40:00",
                                  "rbd": "Y",
                                  "fn": "161",
                                  "eq": "333",
                                  "ac": "MH",
                                  "ma": "MH",
                                  "seat": "9",
                                  "oa": "MH",
                                  "fa": [],
                                  "fdur": "07:50",
                                  "cl": "Economy",
                                  "terminal": {
                                      "dep": "",
                                      "arr": "1"
                                  }
                              },
                              {
                                  "ref": 2,
                                  "da": "KUL",
                                  "ar": "HYD",
                                  "dd": "2023-06-21T21:30:00",
                                  "ad": "2023-06-21T23:10:00",
                                  "rbd": "Y",
                                  "fn": "198",
                                  "trn": "1",
                                  "eq": "738",
                                  "ac": "MH",
                                  "ma": "MH",
                                  "seat": "9",
                                  "oa": "MH",
                                  "fa": [],
                                  "fdur": "04:10",
                                  "cl": "Economy",
                                  "terminal": {
                                      "dep": "1",
                                      "arr": ""
                                  }
                              }
                          ],
                          "flightNumbers": "161/198",
                          "bgd": [
                              {
                                  "qun": "35",
                                  "wt": "kg"
                              }
                          ]
                      },
                      {
                          "tid": "BslnNZnFXIRtKNmO_2",
                          "ref": 2,
                          "office_id": "DOHQR28IH",
                          "sdur": "26:05",
                          "re": "Refundable Before Departure",
                          "stop": 1,
                          "sup": "0",
                          "cl": "Economy",
                          "ft": "GDS",
                          "tecst": "yes",
                          "dc": "no",
                          "dealcode": [],
                          "tktair": "UL",
                          "seg": [
                              {
                                  "ref": 1,
                                  "da": "HYD",
                                  "ar": "KUL",
                                  "dd": "2023-06-19T00:15:00",
                                  "ad": "2023-06-19T07:10:00",
                                  "rbd": "Y",
                                  "fn": "199",
                                  "eq": "738",
                                  "ac": "MH",
                                  "ma": "MH",
                                  "seat": "9",
                                  "oa": "MH",
                                  "fa": [],
                                  "fdur": "04:25",
                                  "cl": "Economy",
                                  "terminal": {
                                      "dep": "",
                                      "arr": "1"
                                  }
                              },
                              {
                                  "ref": 2,
                                  "da": "KUL",
                                  "ar": "DOH",
                                  "dd": "2023-06-19T21:20:00",
                                  "ad": "2023-06-19T23:50:00",
                                  "rbd": "Y",
                                  "fn": "160",
                                  "trn": "1",
                                  "eq": "333",
                                  "ac": "MH",
                                  "ma": "MH",
                                  "seat": "9",
                                  "oa": "MH",
                                  "fa": [],
                                  "fdur": "07:30",
                                  "cl": "Economy",
                                  "terminal": {
                                      "dep": "1",
                                      "arr": ""
                                  }
                              }
                          ],
                          "flightNumbers": "199/160",
                          "bgd": [
                              {
                                  "qun": "30",
                                  "wt": "kg"
                              }
                          ]
                      }
                  ],
                  "pr": {
                      "bf": 550950.4,
                      "tf": 564838.4,
                      "tax": [
                          {
                              "YQ": 5376
                          },
                          {
                              "OT": 8512
                          },
                          {
                              "dealmkp": 0
                          },
                          {
                              "attmkp": 50086.4
                          },
                          {
                              "tamkp": 0
                          },
                          {
                              "stamkp": 0
                          },
                          {
                              "attdisc": 0
                          },
                          {
                              "tadisc": 0
                          },
                          {
                              "stadisc": 0
                          }
                      ],
                      "scur": "QAR",
                      "ucur": "INR",
                      "exchange_rate": 22.4,
                      "sup_fare": "22360_22980",
                      "markupnames": [
                          {
                              "code": "MTA",
                              "name": "LaxmiAprilMarkup"
                          }
                      ]
                  },
                  "paxpr": [
                      {
                          "tf": 564838.4,
                          "tax": [
                              {
                                  "YQ": 5376
                              },
                              {
                                  "OT": 8512
                              },
                              {
                                  "attmkp": 50086.4
                              },
                              {
                                  "tamkp": 0
                              },
                              {
                                  "stamkp": 0
                              },
                              {
                                  "attdisc": 0
                              },
                              {
                                  "tadisc": 0
                              },
                              {
                                  "stadisc": 0
                              }
                          ],
                          "bf": 550950.4,
                          "fbc": "LOWQA",
                          "fareType": "RP",
                          "pusup_fare": "22360_22980",
                          "psup_fare": "500864_514752",
                          "ptype": "ADT"
                      }
                  ],
                  "sup": "0",
                  "airlinesList": "UL_MH",
                  "servicefee": [
                      {
                          "type": "ATT",
                          "adt": 55095.04,
                          "chd": 0,
                          "inf": 0,
                          "servicefee_name": "LaxmiServieApril",
                          "apl_on": "Per Ticket",
                          "sf": 0,
                          "sf_disc": 0,
                          "adt_sf_disc": 0,
                          "chd_sf_disc": 0,
                          "inf_sf_disc": 0,
                          "complete_sfvalue": 55095.04,
                          "sup_fee": 0
                      }
                  ]
              },
              {
                  "srcorigns": [
                      {
                          "tid": "D4BdPeLFQOrgJq2y_1",
                          "ref": 1,
                          "office_id": "DOHQR28IH",
                          "sdur": "26:35",
                          "re": "Refundable Before Departure",
                          "stop": 1,
                          "sup": "0",
                          "cl": "Economy",
                          "ft": "GDS",
                          "tecst": "yes",
                          "dc": "no",
                          "dealcode": [],
                          "tktair": "UL",
                          "seg": [
                              {
                                  "ref": 1,
                                  "da": "DOH",
                                  "ar": "CMB",
                                  "dd": "2023-06-16T22:30:00",
                                  "ad": "2023-06-17T05:45:00",
                                  "rbd": "L",
                                  "fn": "218",
                                  "eq": "333",
                                  "ac": "UL",
                                  "ma": "UL",
                                  "seat": "9",
                                  "oa": "UL",
                                  "fa": [],
                                  "fdur": "04:45",
                                  "cl": "Economy",
                                  "terminal": {
                                      "dep": "",
                                      "arr": ""
                                  }
                              },
                              {
                                  "ref": 2,
                                  "da": "CMB",
                                  "ar": "HYD",
                                  "dd": "2023-06-18T01:40:00",
                                  "ad": "2023-06-18T03:35:00",
                                  "rbd": "L",
                                  "fn": "1177",
                                  "eq": "32B",
                                  "ac": "UL",
                                  "ma": "UL",
                                  "seat": "9",
                                  "oa": "UL",
                                  "fa": [],
                                  "fdur": "01:55",
                                  "cl": "Economy",
                                  "terminal": {
                                      "dep": "",
                                      "arr": ""
                                  }
                              }
                          ],
                          "flightNumbers": "218/1177",
                          "bgd": [
                              {
                                  "qun": "30",
                                  "wt": "kg"
                              }
                          ]
                      },
                      {
                          "tid": "D4BdPeLFQOrgJq2y_3",
                          "ref": 3,
                          "office_id": "DOHQR28IH",
                          "sdur": "18:50",
                          "re": "Refundable Before Departure",
                          "stop": 1,
                          "sup": "0",
                          "cl": "Economy",
                          "ft": "GDS",
                          "tecst": "yes",
                          "dc": "no",
                          "dealcode": [],
                          "tktair": "UL",
                          "seg": [
                              {
                                  "ref": 1,
                                  "da": "DOH",
                                  "ar": "KUL",
                                  "dd": "2023-06-21T01:50:00",
                                  "ad": "2023-06-21T14:40:00",
                                  "rbd": "Y",
                                  "fn": "161",
                                  "eq": "333",
                                  "ac": "MH",
                                  "ma": "MH",
                                  "seat": "9",
                                  "oa": "MH",
                                  "fa": [],
                                  "fdur": "07:50",
                                  "cl": "Economy",
                                  "terminal": {
                                      "dep": "",
                                      "arr": "1"
                                  }
                              },
                              {
                                  "ref": 2,
                                  "da": "KUL",
                                  "ar": "HYD",
                                  "dd": "2023-06-21T21:30:00",
                                  "ad": "2023-06-21T23:10:00",
                                  "rbd": "Y",
                                  "fn": "198",
                                  "trn": "1",
                                  "eq": "738",
                                  "ac": "MH",
                                  "ma": "MH",
                                  "seat": "9",
                                  "oa": "MH",
                                  "fa": [],
                                  "fdur": "04:10",
                                  "cl": "Economy",
                                  "terminal": {
                                      "dep": "1",
                                      "arr": ""
                                  }
                              }
                          ],
                          "flightNumbers": "161/198",
                          "bgd": [
                              {
                                  "qun": "30",
                                  "wt": "kg"
                              }
                          ]
                      },
                      {
                          "tid": "D4BdPeLFQOrgJq2y_2",
                          "ref": 2,
                          "office_id": "DOHQR28IH",
                          "sdur": "31:30",
                          "re": "Refundable Before Departure",
                          "stop": 1,
                          "sup": "0",
                          "cl": "Economy",
                          "ft": "GDS",
                          "tecst": "yes",
                          "dc": "no",
                          "dealcode": [],
                          "tktair": "UL",
                          "seg": [
                              {
                                  "ref": 1,
                                  "da": "HYD",
                                  "ar": "KUL",
                                  "dd": "2023-06-19T00:15:00",
                                  "ad": "2023-06-19T07:10:00",
                                  "rbd": "Y",
                                  "fn": "199",
                                  "eq": "738",
                                  "ac": "MH",
                                  "ma": "MH",
                                  "seat": "9",
                                  "oa": "MH",
                                  "fa": [],
                                  "fdur": "04:25",
                                  "cl": "Economy",
                                  "terminal": {
                                      "dep": "",
                                      "arr": "1"
                                  }
                              },
                              {
                                  "ref": 2,
                                  "da": "KUL",
                                  "ar": "DOH",
                                  "dd": "2023-06-20T02:55:00",
                                  "ad": "2023-06-20T05:15:00",
                                  "rbd": "Y",
                                  "fn": "164",
                                  "trn": "1",
                                  "eq": "333",
                                  "ac": "MH",
                                  "ma": "MH",
                                  "seat": "9",
                                  "oa": "MH",
                                  "fa": [],
                                  "fdur": "07:20",
                                  "cl": "Economy",
                                  "terminal": {
                                      "dep": "1",
                                      "arr": ""
                                  }
                              }
                          ],
                          "flightNumbers": "199/164",
                          "bgd": [
                              {
                                  "qun": "30",
                                  "wt": "kg"
                              }
                          ]
                      }
                  ],
                  "pr": {
                      "bf": 550950.4,
                      "tf": 564838.4,
                      "tax": [
                          {
                              "YQ": 5376
                          },
                          {
                              "OT": 8512
                          },
                          {
                              "dealmkp": 0
                          },
                          {
                              "attmkp": 50086.4
                          },
                          {
                              "tamkp": 0
                          },
                          {
                              "stamkp": 0
                          },
                          {
                              "attdisc": 0
                          },
                          {
                              "tadisc": 0
                          },
                          {
                              "stadisc": 0
                          }
                      ],
                      "scur": "QAR",
                      "ucur": "INR",
                      "exchange_rate": 22.4,
                      "sup_fare": "22360_22980",
                      "markupnames": [
                          {
                              "code": "MTA",
                              "name": "LaxmiAprilMarkup"
                          }
                      ]
                  },
                  "paxpr": [
                      {
                          "tf": 564838.4,
                          "tax": [
                              {
                                  "YQ": 5376
                              },
                              {
                                  "OT": 8512
                              },
                              {
                                  "attmkp": 50086.4
                              },
                              {
                                  "tamkp": 0
                              },
                              {
                                  "stamkp": 0
                              },
                              {
                                  "attdisc": 0
                              },
                              {
                                  "tadisc": 0
                              },
                              {
                                  "stadisc": 0
                              }
                          ],
                          "bf": 550950.4,
                          "fbc": "LOWQA",
                          "fareType": "RP",
                          "pusup_fare": "22360_22980",
                          "psup_fare": "500864_514752",
                          "ptype": "ADT"
                      }
                  ],
                  "sup": "0",
                  "airlinesList": "UL_MH",
                  "servicefee": [
                      {
                          "type": "ATT",
                          "adt": 55095.04,
                          "chd": 0,
                          "inf": 0,
                          "servicefee_name": "LaxmiServieApril",
                          "apl_on": "Per Ticket",
                          "sf": 0,
                          "sf_disc": 0,
                          "adt_sf_disc": 0,
                          "chd_sf_disc": 0,
                          "inf_sf_disc": 0,
                          "complete_sfvalue": 55095.04,
                          "sup_fee": 0
                      }
                  ]
              },
              {
                  "srcorigns": [
                      {
                          "tid": "I5AQXhnTdXWnVpQX_1",
                          "ref": 1,
                          "office_id": "DOHQR28IH",
                          "sdur": "08:20",
                          "re": "Refundable Before Departure",
                          "stop": 1,
                          "sup": "0",
                          "cl": "Economy",
                          "ft": "GDS",
                          "tecst": "yes",
                          "dc": "no",
                          "dealcode": [],
                          "tktair": "UL",
                          "seg": [
                              {
                                  "ref": 1,
                                  "da": "DOH",
                                  "ar": "CMB",
                                  "dd": "2023-06-16T22:30:00",
                                  "ad": "2023-06-17T05:45:00",
                                  "rbd": "L",
                                  "fn": "218",
                                  "eq": "333",
                                  "ac": "UL",
                                  "ma": "UL",
                                  "seat": "9",
                                  "oa": "UL",
                                  "fa": [],
                                  "fdur": "04:45",
                                  "cl": "Economy",
                                  "terminal": {
                                      "dep": "",
                                      "arr": ""
                                  }
                              },
                              {
                                  "ref": 2,
                                  "da": "CMB",
                                  "ar": "HYD",
                                  "dd": "2023-06-17T07:25:00",
                                  "ad": "2023-06-17T09:20:00",
                                  "rbd": "L",
                                  "fn": "177",
                                  "eq": "32B",
                                  "ac": "UL",
                                  "ma": "UL",
                                  "seat": "9",
                                  "oa": "UL",
                                  "fa": [],
                                  "fdur": "01:55",
                                  "cl": "Economy",
                                  "terminal": {
                                      "dep": "",
                                      "arr": ""
                                  }
                              }
                          ],
                          "flightNumbers": "218/177",
                          "bgd": [
                              {
                                  "qun": "40",
                                  "wt": "kg"
                              }
                          ]
                      },
                      {
                          "tid": "I5AQXhnTdXWnVpQX_3",
                          "ref": 3,
                          "office_id": "DOHQR28IH",
                          "sdur": "18:50",
                          "re": "Refundable Before Departure",
                          "stop": 1,
                          "sup": "0",
                          "cl": "Economy",
                          "ft": "GDS",
                          "tecst": "yes",
                          "dc": "no",
                          "dealcode": [],
                          "tktair": "UL",
                          "seg": [
                              {
                                  "ref": 1,
                                  "da": "DOH",
                                  "ar": "KUL",
                                  "dd": "2023-06-21T01:50:00",
                                  "ad": "2023-06-21T14:40:00",
                                  "rbd": "Y",
                                  "fn": "161",
                                  "eq": "333",
                                  "ac": "MH",
                                  "ma": "MH",
                                  "seat": "9",
                                  "oa": "MH",
                                  "fa": [],
                                  "fdur": "07:50",
                                  "cl": "Economy",
                                  "terminal": {
                                      "dep": "",
                                      "arr": "1"
                                  }
                              },
                              {
                                  "ref": 2,
                                  "da": "KUL",
                                  "ar": "HYD",
                                  "dd": "2023-06-21T21:30:00",
                                  "ad": "2023-06-21T23:10:00",
                                  "rbd": "Y",
                                  "fn": "198",
                                  "trn": "1",
                                  "eq": "738",
                                  "ac": "MH",
                                  "ma": "MH",
                                  "seat": "9",
                                  "oa": "MH",
                                  "fa": [],
                                  "fdur": "04:10",
                                  "cl": "Economy",
                                  "terminal": {
                                      "dep": "1",
                                      "arr": ""
                                  }
                              }
                          ],
                          "flightNumbers": "161/198",
                          "bgd": [
                              {
                                  "qun": "35",
                                  "wt": "kg"
                              }
                          ]
                      },
                      {
                          "tid": "I5AQXhnTdXWnVpQX_2",
                          "ref": 2,
                          "office_id": "DOHQR28IH",
                          "sdur": "26:05",
                          "re": "Refundable Before Departure",
                          "stop": 1,
                          "sup": "0",
                          "cl": "Economy",
                          "ft": "GDS",
                          "tecst": "yes",
                          "dc": "no",
                          "dealcode": [],
                          "tktair": "UL",
                          "seg": [
                              {
                                  "ref": 1,
                                  "da": "HYD",
                                  "ar": "KUL",
                                  "dd": "2023-06-19T00:15:00",
                                  "ad": "2023-06-19T07:10:00",
                                  "rbd": "Y",
                                  "fn": "199",
                                  "eq": "738",
                                  "ac": "MH",
                                  "ma": "MH",
                                  "seat": "9",
                                  "oa": "MH",
                                  "fa": [],
                                  "fdur": "04:25",
                                  "cl": "Economy",
                                  "terminal": {
                                      "dep": "",
                                      "arr": "1"
                                  }
                              },
                              {
                                  "ref": 2,
                                  "da": "KUL",
                                  "ar": "DOH",
                                  "dd": "2023-06-19T21:20:00",
                                  "ad": "2023-06-19T23:50:00",
                                  "rbd": "Y",
                                  "fn": "160",
                                  "trn": "1",
                                  "eq": "333",
                                  "ac": "MH",
                                  "ma": "MH",
                                  "seat": "9",
                                  "oa": "MH",
                                  "fa": [],
                                  "fdur": "07:30",
                                  "cl": "Economy",
                                  "terminal": {
                                      "dep": "1",
                                      "arr": ""
                                  }
                              }
                          ],
                          "flightNumbers": "199/160",
                          "bgd": [
                              {
                                  "qun": "35",
                                  "wt": "kg"
                              }
                          ]
                      }
                  ],
                  "pr": {
                      "bf": 550950.4,
                      "tf": 567974.4,
                      "tax": [
                          {
                              "YQ": 5376
                          },
                          {
                              "OT": 11648
                          },
                          {
                              "dealmkp": 0
                          },
                          {
                              "attmkp": 50086.4
                          },
                          {
                              "tamkp": 0
                          },
                          {
                              "stamkp": 0
                          },
                          {
                              "attdisc": 0
                          },
                          {
                              "tadisc": 0
                          },
                          {
                              "stadisc": 0
                          }
                      ],
                      "scur": "QAR",
                      "ucur": "INR",
                      "exchange_rate": 22.4,
                      "sup_fare": "22360_23120",
                      "markupnames": [
                          {
                              "code": "MTA",
                              "name": "LaxmiAprilMarkup"
                          }
                      ]
                  },
                  "paxpr": [
                      {
                          "tf": 567974.4,
                          "tax": [
                              {
                                  "YQ": 5376
                              },
                              {
                                  "OT": 11648
                              },
                              {
                                  "attmkp": 50086.4
                              },
                              {
                                  "tamkp": 0
                              },
                              {
                                  "stamkp": 0
                              },
                              {
                                  "attdisc": 0
                              },
                              {
                                  "tadisc": 0
                              },
                              {
                                  "stadisc": 0
                              }
                          ],
                          "bf": 550950.4,
                          "fbc": "LOWQA",
                          "fareType": "RP",
                          "pusup_fare": "22360_23120",
                          "psup_fare": "500864_517888",
                          "ptype": "ADT"
                      }
                  ],
                  "sup": "0",
                  "airlinesList": "UL_MH",
                  "servicefee": [
                      {
                          "type": "ATT",
                          "adt": 55095.04,
                          "chd": 0,
                          "inf": 0,
                          "servicefee_name": "LaxmiServieApril",
                          "apl_on": "Per Ticket",
                          "sf": 0,
                          "sf_disc": 0,
                          "adt_sf_disc": 0,
                          "chd_sf_disc": 0,
                          "inf_sf_disc": 0,
                          "complete_sfvalue": 55095.04,
                          "sup_fee": 0
                      }
                  ]
              },
              {
                  "srcorigns": [
                      {
                          "tid": "TNd2H8ia9ns9X2ix_1",
                          "ref": 1,
                          "office_id": "BOMVS39GM",
                          "sdur": "08:20",
                          "re": "Refundable Before Departure",
                          "stop": 1,
                          "sup": "0",
                          "cl": "Economy",
                          "ft": "GDS",
                          "tecst": "yes",
                          "dc": "no",
                          "dealcode": [],
                          "tktair": "UL",
                          "seg": [
                              {
                                  "ref": 1,
                                  "da": "DOH",
                                  "ar": "CMB",
                                  "dd": "2023-06-16T22:30:00",
                                  "ad": "2023-06-17T05:45:00",
                                  "rbd": "L",
                                  "fn": "218",
                                  "eq": "333",
                                  "ac": "UL",
                                  "ma": "UL",
                                  "seat": "9",
                                  "oa": "UL",
                                  "fa": [],
                                  "fdur": "",
                                  "cl": "Economy",
                                  "terminal": {
                                      "dep": "",
                                      "arr": ""
                                  }
                              },
                              {
                                  "ref": 2,
                                  "da": "CMB",
                                  "ar": "HYD",
                                  "dd": "2023-06-17T07:25:00",
                                  "ad": "2023-06-17T09:20:00",
                                  "rbd": "L",
                                  "fn": "177",
                                  "eq": "32B",
                                  "ac": "UL",
                                  "ma": "UL",
                                  "seat": "9",
                                  "oa": "UL",
                                  "fa": [],
                                  "fdur": "",
                                  "cl": "Economy",
                                  "terminal": {
                                      "dep": "",
                                      "arr": ""
                                  }
                              }
                          ],
                          "flightNumbers": "218/177",
                          "bgd": [
                              {
                                  "qun": "30",
                                  "wt": "kg"
                              }
                          ]
                      },
                      {
                          "tid": "TNd2H8ia9ns9X2ix_3",
                          "ref": 3,
                          "office_id": "BOMVS39GM",
                          "sdur": "18:50",
                          "re": "Refundable Before Departure",
                          "stop": 1,
                          "sup": "0",
                          "cl": "Economy",
                          "ft": "GDS",
                          "tecst": "yes",
                          "dc": "no",
                          "dealcode": [],
                          "tktair": "UL",
                          "seg": [
                              {
                                  "ref": 1,
                                  "da": "DOH",
                                  "ar": "KUL",
                                  "dd": "2023-06-21T01:50:00",
                                  "ad": "2023-06-21T14:40:00",
                                  "rbd": "Y",
                                  "fn": "161",
                                  "eq": "333",
                                  "ac": "MH",
                                  "ma": "MH",
                                  "seat": "9",
                                  "oa": "MH",
                                  "fa": [],
                                  "fdur": "",
                                  "cl": "Economy",
                                  "terminal": {
                                      "dep": "",
                                      "arr": "1"
                                  }
                              },
                              {
                                  "ref": 2,
                                  "da": "KUL",
                                  "ar": "HYD",
                                  "dd": "2023-06-21T21:30:00",
                                  "ad": "2023-06-21T23:10:00",
                                  "rbd": "Y",
                                  "fn": "198",
                                  "trn": "1",
                                  "eq": "738",
                                  "ac": "MH",
                                  "ma": "MH",
                                  "seat": "9",
                                  "oa": "MH",
                                  "fa": [],
                                  "fdur": "",
                                  "cl": "Economy",
                                  "terminal": {
                                      "dep": "1",
                                      "arr": ""
                                  }
                              }
                          ],
                          "flightNumbers": "161/198",
                          "bgd": [
                              {
                                  "qun": "35",
                                  "wt": "kg"
                              }
                          ]
                      },
                      {
                          "tid": "TNd2H8ia9ns9X2ix_2",
                          "ref": 2,
                          "office_id": "BOMVS39GM",
                          "sdur": "31:30",
                          "re": "Refundable Before Departure",
                          "stop": 1,
                          "sup": "0",
                          "cl": "Economy",
                          "ft": "GDS",
                          "tecst": "yes",
                          "dc": "no",
                          "dealcode": [],
                          "tktair": "UL",
                          "seg": [
                              {
                                  "ref": 1,
                                  "da": "HYD",
                                  "ar": "KUL",
                                  "dd": "2023-06-19T00:15:00",
                                  "ad": "2023-06-19T07:10:00",
                                  "rbd": "Y",
                                  "fn": "199",
                                  "eq": "738",
                                  "ac": "MH",
                                  "ma": "MH",
                                  "seat": "9",
                                  "oa": "MH",
                                  "fa": [],
                                  "fdur": "",
                                  "cl": "Economy",
                                  "terminal": {
                                      "dep": "",
                                      "arr": "1"
                                  }
                              },
                              {
                                  "ref": 2,
                                  "da": "KUL",
                                  "ar": "DOH",
                                  "dd": "2023-06-20T02:55:00",
                                  "ad": "2023-06-20T05:15:00",
                                  "rbd": "Y",
                                  "fn": "164",
                                  "trn": "1",
                                  "eq": "333",
                                  "ac": "MH",
                                  "ma": "MH",
                                  "seat": "9",
                                  "oa": "MH",
                                  "fa": [],
                                  "fdur": "",
                                  "cl": "Economy",
                                  "terminal": {
                                      "dep": "1",
                                      "arr": ""
                                  }
                              }
                          ],
                          "flightNumbers": "199/164",
                          "bgd": [
                              {
                                  "qun": "35",
                                  "wt": "kg"
                              }
                          ]
                      }
                  ],
                  "pr": {
                      "bf": 557045.5,
                      "tf": 570555.5,
                      "tax": [
                          {
                              "OT": 13510,
                              "YQ": 0,
                              "YR": 0
                          },
                          {
                              "dealmkp": 0
                          },
                          {
                              "attmkp": 50640.5
                          },
                          {
                              "tamkp": 0
                          },
                          {
                              "stamkp": 0
                          },
                          {
                              "attdisc": 0
                          },
                          {
                              "tadisc": 0
                          },
                          {
                              "stadisc": 0
                          }
                      ],
                      "scur": "QAR",
                      "ucur": "INR",
                      "exchange_rate": 1,
                      "sup_fare": "506405_519915",
                      "markupnames": [
                          {
                              "code": "MTA",
                              "name": "LaxmiAprilMarkup"
                          }
                      ]
                  },
                  "paxpr": [
                      {
                          "tf": 570555.5,
                          "tax": [
                              {
                                  "OT": 13510,
                                  "YQ": 0,
                                  "YR": 0
                              },
                              {
                                  "attmkp": 50640.5
                              },
                              {
                                  "tamkp": 0
                              },
                              {
                                  "stamkp": 0
                              },
                              {
                                  "attdisc": 0
                              },
                              {
                                  "tadisc": 0
                              },
                              {
                                  "stadisc": 0
                              }
                          ],
                          "bf": 557045.5,
                          "fbc": "LOWQA",
                          "fareType": "RP",
                          "pusup_fare": "506405_519915",
                          "psup_fare": "506405_519915",
                          "ptype": "ADT"
                      }
                  ],
                  "sup": "0",
                  "airlinesList": "UL_MH",
                  "servicefee": [
                      {
                          "type": "ATT",
                          "adt": 55704.55,
                          "chd": 0,
                          "inf": 0,
                          "servicefee_name": "LaxmiServieApril",
                          "apl_on": "Per Ticket",
                          "sf": 0,
                          "sf_disc": 0,
                          "adt_sf_disc": 0,
                          "chd_sf_disc": 0,
                          "inf_sf_disc": 0,
                          "complete_sfvalue": 55704.55,
                          "sup_fee": 0
                      }
                  ]
              },
              {
                  "srcorigns": [
                      {
                          "tid": "DsO8ZDofRl54tFQU_1",
                          "ref": 1,
                          "office_id": "BOMVS39GM",
                          "sdur": "26:35",
                          "re": "Refundable Before Departure",
                          "stop": 1,
                          "sup": "0",
                          "cl": "Economy",
                          "ft": "GDS",
                          "tecst": "yes",
                          "dc": "no",
                          "dealcode": [],
                          "tktair": "UL",
                          "seg": [
                              {
                                  "ref": 1,
                                  "da": "DOH",
                                  "ar": "CMB",
                                  "dd": "2023-06-16T22:30:00",
                                  "ad": "2023-06-17T05:45:00",
                                  "rbd": "L",
                                  "fn": "218",
                                  "eq": "333",
                                  "ac": "UL",
                                  "ma": "UL",
                                  "seat": "9",
                                  "oa": "UL",
                                  "fa": [],
                                  "fdur": "",
                                  "cl": "Economy",
                                  "terminal": {
                                      "dep": "",
                                      "arr": ""
                                  }
                              },
                              {
                                  "ref": 2,
                                  "da": "CMB",
                                  "ar": "HYD",
                                  "dd": "2023-06-18T01:40:00",
                                  "ad": "2023-06-18T03:35:00",
                                  "rbd": "L",
                                  "fn": "1177",
                                  "eq": "32B",
                                  "ac": "UL",
                                  "ma": "UL",
                                  "seat": "9",
                                  "oa": "UL",
                                  "fa": [],
                                  "fdur": "",
                                  "cl": "Economy",
                                  "terminal": {
                                      "dep": "",
                                      "arr": ""
                                  }
                              }
                          ],
                          "flightNumbers": "218/1177",
                          "bgd": [
                              {
                                  "qun": "30",
                                  "wt": "kg"
                              }
                          ]
                      },
                      {
                          "tid": "DsO8ZDofRl54tFQU_3",
                          "ref": 3,
                          "office_id": "BOMVS39GM",
                          "sdur": "18:50",
                          "re": "Refundable Before Departure",
                          "stop": 1,
                          "sup": "0",
                          "cl": "Economy",
                          "ft": "GDS",
                          "tecst": "yes",
                          "dc": "no",
                          "dealcode": [],
                          "tktair": "UL",
                          "seg": [
                              {
                                  "ref": 1,
                                  "da": "DOH",
                                  "ar": "KUL",
                                  "dd": "2023-06-21T01:50:00",
                                  "ad": "2023-06-21T14:40:00",
                                  "rbd": "Y",
                                  "fn": "161",
                                  "eq": "333",
                                  "ac": "MH",
                                  "ma": "MH",
                                  "seat": "9",
                                  "oa": "MH",
                                  "fa": [],
                                  "fdur": "",
                                  "cl": "Economy",
                                  "terminal": {
                                      "dep": "",
                                      "arr": "1"
                                  }
                              },
                              {
                                  "ref": 2,
                                  "da": "KUL",
                                  "ar": "HYD",
                                  "dd": "2023-06-21T21:30:00",
                                  "ad": "2023-06-21T23:10:00",
                                  "rbd": "Y",
                                  "fn": "198",
                                  "trn": "1",
                                  "eq": "738",
                                  "ac": "MH",
                                  "ma": "MH",
                                  "seat": "9",
                                  "oa": "MH",
                                  "fa": [],
                                  "fdur": "",
                                  "cl": "Economy",
                                  "terminal": {
                                      "dep": "1",
                                      "arr": ""
                                  }
                              }
                          ],
                          "flightNumbers": "161/198",
                          "bgd": [
                              {
                                  "qun": "35",
                                  "wt": "kg"
                              }
                          ]
                      },
                      {
                          "tid": "DsO8ZDofRl54tFQU_2",
                          "ref": 2,
                          "office_id": "BOMVS39GM",
                          "sdur": "26:05",
                          "re": "Refundable Before Departure",
                          "stop": 1,
                          "sup": "0",
                          "cl": "Economy",
                          "ft": "GDS",
                          "tecst": "yes",
                          "dc": "no",
                          "dealcode": [],
                          "tktair": "UL",
                          "seg": [
                              {
                                  "ref": 1,
                                  "da": "HYD",
                                  "ar": "KUL",
                                  "dd": "2023-06-19T00:15:00",
                                  "ad": "2023-06-19T07:10:00",
                                  "rbd": "Y",
                                  "fn": "199",
                                  "eq": "738",
                                  "ac": "MH",
                                  "ma": "MH",
                                  "seat": "9",
                                  "oa": "MH",
                                  "fa": [],
                                  "fdur": "",
                                  "cl": "Economy",
                                  "terminal": {
                                      "dep": "",
                                      "arr": "1"
                                  }
                              },
                              {
                                  "ref": 2,
                                  "da": "KUL",
                                  "ar": "DOH",
                                  "dd": "2023-06-19T21:20:00",
                                  "ad": "2023-06-19T23:50:00",
                                  "rbd": "Y",
                                  "fn": "160",
                                  "trn": "1",
                                  "eq": "333",
                                  "ac": "MH",
                                  "ma": "MH",
                                  "seat": "9",
                                  "oa": "MH",
                                  "fa": [],
                                  "fdur": "",
                                  "cl": "Economy",
                                  "terminal": {
                                      "dep": "1",
                                      "arr": ""
                                  }
                              }
                          ],
                          "flightNumbers": "199/160",
                          "bgd": [
                              {
                                  "qun": "30",
                                  "wt": "kg"
                              }
                          ]
                      }
                  ],
                  "pr": {
                      "bf": 557045.5,
                      "tf": 570555.5,
                      "tax": [
                          {
                              "OT": 13510,
                              "YQ": 0,
                              "YR": 0
                          },
                          {
                              "dealmkp": 0
                          },
                          {
                              "attmkp": 50640.5
                          },
                          {
                              "tamkp": 0
                          },
                          {
                              "stamkp": 0
                          },
                          {
                              "attdisc": 0
                          },
                          {
                              "tadisc": 0
                          },
                          {
                              "stadisc": 0
                          }
                      ],
                      "scur": "QAR",
                      "ucur": "INR",
                      "exchange_rate": 1,
                      "sup_fare": "506405_519915",
                      "markupnames": [
                          {
                              "code": "MTA",
                              "name": "LaxmiAprilMarkup"
                          }
                      ]
                  },
                  "paxpr": [
                      {
                          "tf": 570555.5,
                          "tax": [
                              {
                                  "OT": 13510,
                                  "YQ": 0,
                                  "YR": 0
                              },
                              {
                                  "attmkp": 50640.5
                              },
                              {
                                  "tamkp": 0
                              },
                              {
                                  "stamkp": 0
                              },
                              {
                                  "attdisc": 0
                              },
                              {
                                  "tadisc": 0
                              },
                              {
                                  "stadisc": 0
                              }
                          ],
                          "bf": 557045.5,
                          "fbc": "LOWQA",
                          "fareType": "RP",
                          "pusup_fare": "506405_519915",
                          "psup_fare": "506405_519915",
                          "ptype": "ADT"
                      }
                  ],
                  "sup": "0",
                  "airlinesList": "UL_MH",
                  "servicefee": [
                      {
                          "type": "ATT",
                          "adt": 55704.55,
                          "chd": 0,
                          "inf": 0,
                          "servicefee_name": "LaxmiServieApril",
                          "apl_on": "Per Ticket",
                          "sf": 0,
                          "sf_disc": 0,
                          "adt_sf_disc": 0,
                          "chd_sf_disc": 0,
                          "inf_sf_disc": 0,
                          "complete_sfvalue": 55704.55,
                          "sup_fee": 0
                      }
                  ]
              },
              {
                  "srcorigns": [
                      {
                          "tid": "fEpaBRyTU5dPzLtq_1",
                          "ref": 1,
                          "office_id": "BOMVS39GM",
                          "sdur": "26:35",
                          "re": "Refundable Before Departure",
                          "stop": 1,
                          "sup": "0",
                          "cl": "Economy",
                          "ft": "GDS",
                          "tecst": "yes",
                          "dc": "no",
                          "dealcode": [],
                          "tktair": "UL",
                          "seg": [
                              {
                                  "ref": 1,
                                  "da": "DOH",
                                  "ar": "CMB",
                                  "dd": "2023-06-16T22:30:00",
                                  "ad": "2023-06-17T05:45:00",
                                  "rbd": "L",
                                  "fn": "218",
                                  "eq": "333",
                                  "ac": "UL",
                                  "ma": "UL",
                                  "seat": "9",
                                  "oa": "UL",
                                  "fa": [],
                                  "fdur": "",
                                  "cl": "Economy",
                                  "terminal": {
                                      "dep": "",
                                      "arr": ""
                                  }
                              },
                              {
                                  "ref": 2,
                                  "da": "CMB",
                                  "ar": "HYD",
                                  "dd": "2023-06-18T01:40:00",
                                  "ad": "2023-06-18T03:35:00",
                                  "rbd": "L",
                                  "fn": "1177",
                                  "eq": "32B",
                                  "ac": "UL",
                                  "ma": "UL",
                                  "seat": "9",
                                  "oa": "UL",
                                  "fa": [],
                                  "fdur": "",
                                  "cl": "Economy",
                                  "terminal": {
                                      "dep": "",
                                      "arr": ""
                                  }
                              }
                          ],
                          "flightNumbers": "218/1177",
                          "bgd": [
                              {
                                  "qun": "30",
                                  "wt": "kg"
                              }
                          ]
                      },
                      {
                          "tid": "fEpaBRyTU5dPzLtq_3",
                          "ref": 3,
                          "office_id": "BOMVS39GM",
                          "sdur": "18:50",
                          "re": "Refundable Before Departure",
                          "stop": 1,
                          "sup": "0",
                          "cl": "Economy",
                          "ft": "GDS",
                          "tecst": "yes",
                          "dc": "no",
                          "dealcode": [],
                          "tktair": "UL",
                          "seg": [
                              {
                                  "ref": 1,
                                  "da": "DOH",
                                  "ar": "KUL",
                                  "dd": "2023-06-21T01:50:00",
                                  "ad": "2023-06-21T14:40:00",
                                  "rbd": "Y",
                                  "fn": "161",
                                  "eq": "333",
                                  "ac": "MH",
                                  "ma": "MH",
                                  "seat": "9",
                                  "oa": "MH",
                                  "fa": [],
                                  "fdur": "",
                                  "cl": "Economy",
                                  "terminal": {
                                      "dep": "",
                                      "arr": "1"
                                  }
                              },
                              {
                                  "ref": 2,
                                  "da": "KUL",
                                  "ar": "HYD",
                                  "dd": "2023-06-21T21:30:00",
                                  "ad": "2023-06-21T23:10:00",
                                  "rbd": "Y",
                                  "fn": "198",
                                  "trn": "1",
                                  "eq": "738",
                                  "ac": "MH",
                                  "ma": "MH",
                                  "seat": "9",
                                  "oa": "MH",
                                  "fa": [],
                                  "fdur": "",
                                  "cl": "Economy",
                                  "terminal": {
                                      "dep": "1",
                                      "arr": ""
                                  }
                              }
                          ],
                          "flightNumbers": "161/198",
                          "bgd": [
                              {
                                  "qun": "30",
                                  "wt": "kg"
                              }
                          ]
                      },
                      {
                          "tid": "fEpaBRyTU5dPzLtq_2",
                          "ref": 2,
                          "office_id": "BOMVS39GM",
                          "sdur": "31:30",
                          "re": "Refundable Before Departure",
                          "stop": 1,
                          "sup": "0",
                          "cl": "Economy",
                          "ft": "GDS",
                          "tecst": "yes",
                          "dc": "no",
                          "dealcode": [],
                          "tktair": "UL",
                          "seg": [
                              {
                                  "ref": 1,
                                  "da": "HYD",
                                  "ar": "KUL",
                                  "dd": "2023-06-19T00:15:00",
                                  "ad": "2023-06-19T07:10:00",
                                  "rbd": "Y",
                                  "fn": "199",
                                  "eq": "738",
                                  "ac": "MH",
                                  "ma": "MH",
                                  "seat": "9",
                                  "oa": "MH",
                                  "fa": [],
                                  "fdur": "",
                                  "cl": "Economy",
                                  "terminal": {
                                      "dep": "",
                                      "arr": "1"
                                  }
                              },
                              {
                                  "ref": 2,
                                  "da": "KUL",
                                  "ar": "DOH",
                                  "dd": "2023-06-20T02:55:00",
                                  "ad": "2023-06-20T05:15:00",
                                  "rbd": "Y",
                                  "fn": "164",
                                  "trn": "1",
                                  "eq": "333",
                                  "ac": "MH",
                                  "ma": "MH",
                                  "seat": "9",
                                  "oa": "MH",
                                  "fa": [],
                                  "fdur": "",
                                  "cl": "Economy",
                                  "terminal": {
                                      "dep": "1",
                                      "arr": ""
                                  }
                              }
                          ],
                          "flightNumbers": "199/164",
                          "bgd": [
                              {
                                  "qun": "30",
                                  "wt": "kg"
                              }
                          ]
                      }
                  ],
                  "pr": {
                      "bf": 557045.5,
                      "tf": 570555.5,
                      "tax": [
                          {
                              "OT": 13510,
                              "YQ": 0,
                              "YR": 0
                          },
                          {
                              "dealmkp": 0
                          },
                          {
                              "attmkp": 50640.5
                          },
                          {
                              "tamkp": 0
                          },
                          {
                              "stamkp": 0
                          },
                          {
                              "attdisc": 0
                          },
                          {
                              "tadisc": 0
                          },
                          {
                              "stadisc": 0
                          }
                      ],
                      "scur": "QAR",
                      "ucur": "INR",
                      "exchange_rate": 1,
                      "sup_fare": "506405_519915",
                      "markupnames": [
                          {
                              "code": "MTA",
                              "name": "LaxmiAprilMarkup"
                          }
                      ]
                  },
                  "paxpr": [
                      {
                          "tf": 570555.5,
                          "tax": [
                              {
                                  "OT": 13510,
                                  "YQ": 0,
                                  "YR": 0
                              },
                              {
                                  "attmkp": 50640.5
                              },
                              {
                                  "tamkp": 0
                              },
                              {
                                  "stamkp": 0
                              },
                              {
                                  "attdisc": 0
                              },
                              {
                                  "tadisc": 0
                              },
                              {
                                  "stadisc": 0
                              }
                          ],
                          "bf": 557045.5,
                          "fbc": "LOWQA",
                          "fareType": "RP",
                          "pusup_fare": "506405_519915",
                          "psup_fare": "506405_519915",
                          "ptype": "ADT"
                      }
                  ],
                  "sup": "0",
                  "airlinesList": "UL_MH",
                  "servicefee": [
                      {
                          "type": "ATT",
                          "adt": 55704.55,
                          "chd": 0,
                          "inf": 0,
                          "servicefee_name": "LaxmiServieApril",
                          "apl_on": "Per Ticket",
                          "sf": 0,
                          "sf_disc": 0,
                          "adt_sf_disc": 0,
                          "chd_sf_disc": 0,
                          "inf_sf_disc": 0,
                          "complete_sfvalue": 55704.55,
                          "sup_fee": 0
                      }
                  ]
              },
              {
                  "srcorigns": [
                      {
                          "tid": "6BOQ3fX7ammDduO0_1",
                          "ref": 1,
                          "office_id": "BOMVS39GM",
                          "sdur": "08:20",
                          "re": "Refundable Before Departure",
                          "stop": 1,
                          "sup": "0",
                          "cl": "Economy",
                          "ft": "GDS",
                          "tecst": "yes",
                          "dc": "no",
                          "dealcode": [],
                          "tktair": "UL",
                          "seg": [
                              {
                                  "ref": 1,
                                  "da": "DOH",
                                  "ar": "CMB",
                                  "dd": "2023-06-16T22:30:00",
                                  "ad": "2023-06-17T05:45:00",
                                  "rbd": "L",
                                  "fn": "218",
                                  "eq": "333",
                                  "ac": "UL",
                                  "ma": "UL",
                                  "seat": "9",
                                  "oa": "UL",
                                  "fa": [],
                                  "fdur": "",
                                  "cl": "Economy",
                                  "terminal": {
                                      "dep": "",
                                      "arr": ""
                                  }
                              },
                              {
                                  "ref": 2,
                                  "da": "CMB",
                                  "ar": "HYD",
                                  "dd": "2023-06-17T07:25:00",
                                  "ad": "2023-06-17T09:20:00",
                                  "rbd": "L",
                                  "fn": "177",
                                  "eq": "32B",
                                  "ac": "UL",
                                  "ma": "UL",
                                  "seat": "9",
                                  "oa": "UL",
                                  "fa": [],
                                  "fdur": "",
                                  "cl": "Economy",
                                  "terminal": {
                                      "dep": "",
                                      "arr": ""
                                  }
                              }
                          ],
                          "flightNumbers": "218/177",
                          "bgd": [
                              {
                                  "qun": "30",
                                  "wt": "kg"
                              }
                          ]
                      },
                      {
                          "tid": "6BOQ3fX7ammDduO0_3",
                          "ref": 3,
                          "office_id": "BOMVS39GM",
                          "sdur": "18:50",
                          "re": "Refundable Before Departure",
                          "stop": 1,
                          "sup": "0",
                          "cl": "Economy",
                          "ft": "GDS",
                          "tecst": "yes",
                          "dc": "no",
                          "dealcode": [],
                          "tktair": "UL",
                          "seg": [
                              {
                                  "ref": 1,
                                  "da": "DOH",
                                  "ar": "KUL",
                                  "dd": "2023-06-21T01:50:00",
                                  "ad": "2023-06-21T14:40:00",
                                  "rbd": "Y",
                                  "fn": "161",
                                  "eq": "333",
                                  "ac": "MH",
                                  "ma": "MH",
                                  "seat": "9",
                                  "oa": "MH",
                                  "fa": [],
                                  "fdur": "",
                                  "cl": "Economy",
                                  "terminal": {
                                      "dep": "",
                                      "arr": "1"
                                  }
                              },
                              {
                                  "ref": 2,
                                  "da": "KUL",
                                  "ar": "HYD",
                                  "dd": "2023-06-21T21:30:00",
                                  "ad": "2023-06-21T23:10:00",
                                  "rbd": "Y",
                                  "fn": "198",
                                  "trn": "1",
                                  "eq": "738",
                                  "ac": "MH",
                                  "ma": "MH",
                                  "seat": "9",
                                  "oa": "MH",
                                  "fa": [],
                                  "fdur": "",
                                  "cl": "Economy",
                                  "terminal": {
                                      "dep": "1",
                                      "arr": ""
                                  }
                              }
                          ],
                          "flightNumbers": "161/198",
                          "bgd": [
                              {
                                  "qun": "35",
                                  "wt": "kg"
                              }
                          ]
                      },
                      {
                          "tid": "6BOQ3fX7ammDduO0_2",
                          "ref": 2,
                          "office_id": "BOMVS39GM",
                          "sdur": "26:05",
                          "re": "Refundable Before Departure",
                          "stop": 1,
                          "sup": "0",
                          "cl": "Economy",
                          "ft": "GDS",
                          "tecst": "yes",
                          "dc": "no",
                          "dealcode": [],
                          "tktair": "UL",
                          "seg": [
                              {
                                  "ref": 1,
                                  "da": "HYD",
                                  "ar": "KUL",
                                  "dd": "2023-06-19T00:15:00",
                                  "ad": "2023-06-19T07:10:00",
                                  "rbd": "Y",
                                  "fn": "199",
                                  "eq": "738",
                                  "ac": "MH",
                                  "ma": "MH",
                                  "seat": "9",
                                  "oa": "MH",
                                  "fa": [],
                                  "fdur": "",
                                  "cl": "Economy",
                                  "terminal": {
                                      "dep": "",
                                      "arr": "1"
                                  }
                              },
                              {
                                  "ref": 2,
                                  "da": "KUL",
                                  "ar": "DOH",
                                  "dd": "2023-06-19T21:20:00",
                                  "ad": "2023-06-19T23:50:00",
                                  "rbd": "Y",
                                  "fn": "160",
                                  "trn": "1",
                                  "eq": "333",
                                  "ac": "MH",
                                  "ma": "MH",
                                  "seat": "9",
                                  "oa": "MH",
                                  "fa": [],
                                  "fdur": "",
                                  "cl": "Economy",
                                  "terminal": {
                                      "dep": "1",
                                      "arr": ""
                                  }
                              }
                          ],
                          "flightNumbers": "199/160",
                          "bgd": [
                              {
                                  "qun": "35",
                                  "wt": "kg"
                              }
                          ]
                      }
                  ],
                  "pr": {
                      "bf": 557045.5,
                      "tf": 573328.5,
                      "tax": [
                          {
                              "OT": 16283,
                              "YQ": 0,
                              "YR": 0
                          },
                          {
                              "dealmkp": 0
                          },
                          {
                              "attmkp": 50640.5
                          },
                          {
                              "tamkp": 0
                          },
                          {
                              "stamkp": 0
                          },
                          {
                              "attdisc": 0
                          },
                          {
                              "tadisc": 0
                          },
                          {
                              "stadisc": 0
                          }
                      ],
                      "scur": "QAR",
                      "ucur": "INR",
                      "exchange_rate": 1,
                      "sup_fare": "506405_522688",
                      "markupnames": [
                          {
                              "code": "MTA",
                              "name": "LaxmiAprilMarkup"
                          }
                      ]
                  },
                  "paxpr": [
                      {
                          "tf": 573328.5,
                          "tax": [
                              {
                                  "OT": 16283,
                                  "YQ": 0,
                                  "YR": 0
                              },
                              {
                                  "attmkp": 50640.5
                              },
                              {
                                  "tamkp": 0
                              },
                              {
                                  "stamkp": 0
                              },
                              {
                                  "attdisc": 0
                              },
                              {
                                  "tadisc": 0
                              },
                              {
                                  "stadisc": 0
                              }
                          ],
                          "bf": 557045.5,
                          "fbc": "LOWQA",
                          "fareType": "RP",
                          "pusup_fare": "506405_522688",
                          "psup_fare": "506405_522688",
                          "ptype": "ADT"
                      }
                  ],
                  "sup": "0",
                  "airlinesList": "UL_MH",
                  "servicefee": [
                      {
                          "type": "ATT",
                          "adt": 55704.55,
                          "chd": 0,
                          "inf": 0,
                          "servicefee_name": "LaxmiServieApril",
                          "apl_on": "Per Ticket",
                          "sf": 0,
                          "sf_disc": 0,
                          "adt_sf_disc": 0,
                          "chd_sf_disc": 0,
                          "inf_sf_disc": 0,
                          "complete_sfvalue": 55704.55,
                          "sup_fee": 0
                      }
                  ]
              },
              {
                  "srcorigns": [
                      {
                          "tid": "NCQyrWWcrGMC3kwe_3",
                          "ref": 3,
                          "office_id": "DOHQR28IH",
                          "sdur": "19:25",
                          "re": "Refundable Before Departure",
                          "stop": 1,
                          "sup": "0",
                          "cl": "Economy",
                          "ft": "GDS",
                          "tecst": "yes",
                          "dc": "no",
                          "dealcode": [],
                          "tktair": "HR",
                          "seg": [
                              {
                                  "ref": 1,
                                  "da": "DOH",
                                  "ar": "CMB",
                                  "dd": "2023-06-21T22:30:00",
                                  "ad": "2023-06-22T05:45:00",
                                  "rbd": "N",
                                  "fn": "218",
                                  "eq": "333",
                                  "ac": "UL",
                                  "ma": "UL",
                                  "seat": "9",
                                  "oa": "UL",
                                  "fa": [],
                                  "fdur": "04:45",
                                  "cl": "Economy",
                                  "terminal": {
                                      "dep": "",
                                      "arr": ""
                                  }
                              },
                              {
                                  "ref": 2,
                                  "da": "CMB",
                                  "ar": "HYD",
                                  "dd": "2023-06-22T18:30:00",
                                  "ad": "2023-06-22T20:25:00",
                                  "rbd": "N",
                                  "fn": "175",
                                  "eq": "32A",
                                  "ac": "UL",
                                  "ma": "UL",
                                  "seat": "9",
                                  "oa": "UL",
                                  "fa": [],
                                  "fdur": "01:55",
                                  "cl": "Economy",
                                  "terminal": {
                                      "dep": "",
                                      "arr": ""
                                  }
                              }
                          ],
                          "flightNumbers": "218/175",
                          "bgd": [
                              {
                                  "qun": "40",
                                  "wt": "kg"
                              }
                          ]
                      },
                      {
                          "tid": "v7ws13U2aiSBOmTd_3",
                          "ref": 3,
                          "office_id": "DOHQR28IH",
                          "sdur": "26:35",
                          "re": "Refundable Before Departure",
                          "stop": 1,
                          "sup": "0",
                          "cl": "Economy",
                          "ft": "GDS",
                          "tecst": "yes",
                          "dc": "no",
                          "dealcode": [],
                          "tktair": "HR",
                          "seg": [
                              {
                                  "ref": 1,
                                  "da": "DOH",
                                  "ar": "CMB",
                                  "dd": "2023-06-21T22:30:00",
                                  "ad": "2023-06-22T05:45:00",
                                  "rbd": "N",
                                  "fn": "218",
                                  "eq": "333",
                                  "ac": "UL",
                                  "ma": "UL",
                                  "seat": "9",
                                  "oa": "UL",
                                  "fa": [],
                                  "fdur": "04:45",
                                  "cl": "Economy",
                                  "terminal": {
                                      "dep": "",
                                      "arr": ""
                                  }
                              },
                              {
                                  "ref": 2,
                                  "da": "CMB",
                                  "ar": "HYD",
                                  "dd": "2023-06-23T01:40:00",
                                  "ad": "2023-06-23T03:35:00",
                                  "rbd": "N",
                                  "fn": "1177",
                                  "eq": "320",
                                  "ac": "UL",
                                  "ma": "UL",
                                  "seat": "9",
                                  "oa": "UL",
                                  "fa": [],
                                  "fdur": "01:55",
                                  "cl": "Economy",
                                  "terminal": {
                                      "dep": "",
                                      "arr": ""
                                  }
                              }
                          ],
                          "flightNumbers": "218/1177",
                          "bgd": [
                              {
                                  "qun": "40",
                                  "wt": "kg"
                              }
                          ]
                      },
                      {
                          "tid": "NCQyrWWcrGMC3kwe_1",
                          "ref": 1,
                          "office_id": "DOHQR28IH",
                          "sdur": "18:50",
                          "re": "Refundable Before Departure",
                          "stop": 1,
                          "sup": "0",
                          "cl": "Economy",
                          "ft": "GDS",
                          "tecst": "yes",
                          "dc": "no",
                          "dealcode": [],
                          "tktair": "HR",
                          "seg": [
                              {
                                  "ref": 1,
                                  "da": "DOH",
                                  "ar": "KUL",
                                  "dd": "2023-06-16T01:50:00",
                                  "ad": "2023-06-16T14:40:00",
                                  "rbd": "Y",
                                  "fn": "161",
                                  "eq": "333",
                                  "ac": "MH",
                                  "ma": "MH",
                                  "seat": "9",
                                  "oa": "MH",
                                  "fa": [],
                                  "fdur": "07:50",
                                  "cl": "Economy",
                                  "terminal": {
                                      "dep": "",
                                      "arr": "1"
                                  }
                              },
                              {
                                  "ref": 2,
                                  "da": "KUL",
                                  "ar": "HYD",
                                  "dd": "2023-06-16T21:30:00",
                                  "ad": "2023-06-16T23:10:00",
                                  "rbd": "Y",
                                  "fn": "198",
                                  "trn": "1",
                                  "eq": "738",
                                  "ac": "MH",
                                  "ma": "MH",
                                  "seat": "9",
                                  "oa": "MH",
                                  "fa": [],
                                  "fdur": "04:10",
                                  "cl": "Economy",
                                  "terminal": {
                                      "dep": "1",
                                      "arr": ""
                                  }
                              }
                          ],
                          "flightNumbers": "161/198",
                          "bgd": [
                              {
                                  "qun": "35",
                                  "wt": "kg"
                              }
                          ]
                      },
                      {
                          "tid": "NCQyrWWcrGMC3kwe_2",
                          "ref": 2,
                          "office_id": "DOHQR28IH",
                          "sdur": "26:05",
                          "re": "Refundable Before Departure",
                          "stop": 1,
                          "sup": "0",
                          "cl": "Economy",
                          "ft": "GDS",
                          "tecst": "yes",
                          "dc": "no",
                          "dealcode": [],
                          "tktair": "HR",
                          "seg": [
                              {
                                  "ref": 1,
                                  "da": "HYD",
                                  "ar": "KUL",
                                  "dd": "2023-06-19T00:15:00",
                                  "ad": "2023-06-19T07:10:00",
                                  "rbd": "Y",
                                  "fn": "199",
                                  "eq": "738",
                                  "ac": "MH",
                                  "ma": "MH",
                                  "seat": "9",
                                  "oa": "MH",
                                  "fa": [],
                                  "fdur": "04:25",
                                  "cl": "Economy",
                                  "terminal": {
                                      "dep": "",
                                      "arr": "1"
                                  }
                              },
                              {
                                  "ref": 2,
                                  "da": "KUL",
                                  "ar": "DOH",
                                  "dd": "2023-06-19T21:20:00",
                                  "ad": "2023-06-19T23:50:00",
                                  "rbd": "Y",
                                  "fn": "160",
                                  "trn": "1",
                                  "eq": "333",
                                  "ac": "MH",
                                  "ma": "MH",
                                  "seat": "9",
                                  "oa": "MH",
                                  "fa": [],
                                  "fdur": "07:30",
                                  "cl": "Economy",
                                  "terminal": {
                                      "dep": "1",
                                      "arr": ""
                                  }
                              }
                          ],
                          "flightNumbers": "199/160",
                          "bgd": [
                              {
                                  "qun": "35",
                                  "wt": "kg"
                              }
                          ]
                      },
                      {
                          "tid": "7EOutlKUQXDyHnNO_2",
                          "ref": 2,
                          "office_id": "DOHQR28IH",
                          "sdur": "31:30",
                          "re": "Refundable Before Departure",
                          "stop": 1,
                          "sup": "0",
                          "cl": "Economy",
                          "ft": "GDS",
                          "tecst": "yes",
                          "dc": "no",
                          "dealcode": [],
                          "tktair": "HR",
                          "seg": [
                              {
                                  "ref": 1,
                                  "da": "HYD",
                                  "ar": "KUL",
                                  "dd": "2023-06-19T00:15:00",
                                  "ad": "2023-06-19T07:10:00",
                                  "rbd": "Y",
                                  "fn": "199",
                                  "eq": "738",
                                  "ac": "MH",
                                  "ma": "MH",
                                  "seat": "9",
                                  "oa": "MH",
                                  "fa": [],
                                  "fdur": "04:25",
                                  "cl": "Economy",
                                  "terminal": {
                                      "dep": "",
                                      "arr": "1"
                                  }
                              },
                              {
                                  "ref": 2,
                                  "da": "KUL",
                                  "ar": "DOH",
                                  "dd": "2023-06-20T02:55:00",
                                  "ad": "2023-06-20T05:15:00",
                                  "rbd": "Y",
                                  "fn": "164",
                                  "trn": "1",
                                  "eq": "333",
                                  "ac": "MH",
                                  "ma": "MH",
                                  "seat": "9",
                                  "oa": "MH",
                                  "fa": [],
                                  "fdur": "07:20",
                                  "cl": "Economy",
                                  "terminal": {
                                      "dep": "1",
                                      "arr": ""
                                  }
                              }
                          ],
                          "flightNumbers": "199/164",
                          "bgd": [
                              {
                                  "qun": "35",
                                  "wt": "kg"
                              }
                          ]
                      }
                  ],
                  "pr": {
                      "bf": 568444.8,
                      "tf": 586812.8,
                      "tax": [
                          {
                              "YQ": 5376
                          },
                          {
                              "YR": 1344
                          },
                          {
                              "OT": 11648
                          },
                          {
                              "dealmkp": 0
                          },
                          {
                              "attmkp": 51676.8
                          },
                          {
                              "tamkp": 0
                          },
                          {
                              "stamkp": 0
                          },
                          {
                              "attdisc": 0
                          },
                          {
                              "tadisc": 0
                          },
                          {
                              "stadisc": 0
                          }
                      ],
                      "scur": "QAR",
                      "ucur": "INR",
                      "exchange_rate": 22.4,
                      "sup_fare": "23070_23890",
                      "markupnames": [
                          {
                              "code": "MTA",
                              "name": "LaxmiAprilMarkup"
                          }
                      ]
                  },
                  "paxpr": [
                      {
                          "tf": 586812.8,
                          "tax": [
                              {
                                  "YQ": 5376
                              },
                              {
                                  "YR": 1344
                              },
                              {
                                  "OT": 11648
                              },
                              {
                                  "attmkp": 51676.8
                              },
                              {
                                  "tamkp": 0
                              },
                              {
                                  "stamkp": 0
                              },
                              {
                                  "attdisc": 0
                              },
                              {
                                  "tadisc": 0
                              },
                              {
                                  "stadisc": 0
                              }
                          ],
                          "bf": 568444.8,
                          "fbc": "YIFMH",
                          "fareType": "RP",
                          "pusup_fare": "23070_23890",
                          "psup_fare": "516768_535136",
                          "ptype": "ADT"
                      }
                  ],
                  "sup": "0",
                  "airlinesList": "UL_MH",
                  "servicefee": [
                      {
                          "type": "ATT",
                          "adt": 56844.48,
                          "chd": 0,
                          "inf": 0,
                          "servicefee_name": "LaxmiServieApril",
                          "apl_on": "Per Ticket",
                          "sf": 0,
                          "sf_disc": 0,
                          "adt_sf_disc": 0,
                          "chd_sf_disc": 0,
                          "inf_sf_disc": 0,
                          "complete_sfvalue": 56844.48,
                          "sup_fee": 0
                      }
                  ]
              },
              {
                  "srcorigns": [
                      {
                          "tid": "z8zxhsrCTTEPJFof_3",
                          "ref": 3,
                          "office_id": "BOMVS39GM",
                          "sdur": "19:25",
                          "re": "Refundable Before Departure",
                          "stop": 1,
                          "sup": "0",
                          "cl": "Economy",
                          "ft": "GDS",
                          "tecst": "yes",
                          "dc": "no",
                          "dealcode": [],
                          "tktair": "HR",
                          "seg": [
                              {
                                  "ref": 1,
                                  "da": "DOH",
                                  "ar": "CMB",
                                  "dd": "2023-06-21T22:30:00",
                                  "ad": "2023-06-22T05:45:00",
                                  "rbd": "N",
                                  "fn": "218",
                                  "eq": "333",
                                  "ac": "UL",
                                  "ma": "UL",
                                  "seat": "9",
                                  "oa": "UL",
                                  "fa": [],
                                  "fdur": "",
                                  "cl": "Economy",
                                  "terminal": {
                                      "dep": "",
                                      "arr": ""
                                  }
                              },
                              {
                                  "ref": 2,
                                  "da": "CMB",
                                  "ar": "HYD",
                                  "dd": "2023-06-22T18:30:00",
                                  "ad": "2023-06-22T20:25:00",
                                  "rbd": "N",
                                  "fn": "175",
                                  "eq": "32A",
                                  "ac": "UL",
                                  "ma": "UL",
                                  "seat": "9",
                                  "oa": "UL",
                                  "fa": [],
                                  "fdur": "",
                                  "cl": "Economy",
                                  "terminal": {
                                      "dep": "",
                                      "arr": ""
                                  }
                              }
                          ],
                          "flightNumbers": "218/175",
                          "bgd": [
                              {
                                  "qun": "30",
                                  "wt": "kg"
                              }
                          ]
                      },
                      {
                          "tid": "x3M3xp3DT0ObzgUk_3",
                          "ref": 3,
                          "office_id": "BOMVS39GM",
                          "sdur": "26:35",
                          "re": "Refundable Before Departure",
                          "stop": 1,
                          "sup": "0",
                          "cl": "Economy",
                          "ft": "GDS",
                          "tecst": "yes",
                          "dc": "no",
                          "dealcode": [],
                          "tktair": "HR",
                          "seg": [
                              {
                                  "ref": 1,
                                  "da": "DOH",
                                  "ar": "CMB",
                                  "dd": "2023-06-21T22:30:00",
                                  "ad": "2023-06-22T05:45:00",
                                  "rbd": "N",
                                  "fn": "218",
                                  "eq": "333",
                                  "ac": "UL",
                                  "ma": "UL",
                                  "seat": "9",
                                  "oa": "UL",
                                  "fa": [],
                                  "fdur": "",
                                  "cl": "Economy",
                                  "terminal": {
                                      "dep": "",
                                      "arr": ""
                                  }
                              },
                              {
                                  "ref": 2,
                                  "da": "CMB",
                                  "ar": "HYD",
                                  "dd": "2023-06-23T01:40:00",
                                  "ad": "2023-06-23T03:35:00",
                                  "rbd": "N",
                                  "fn": "1177",
                                  "eq": "320",
                                  "ac": "UL",
                                  "ma": "UL",
                                  "seat": "9",
                                  "oa": "UL",
                                  "fa": [],
                                  "fdur": "",
                                  "cl": "Economy",
                                  "terminal": {
                                      "dep": "",
                                      "arr": ""
                                  }
                              }
                          ],
                          "flightNumbers": "218/1177",
                          "bgd": [
                              {
                                  "qun": "30",
                                  "wt": "kg"
                              }
                          ]
                      },
                      {
                          "tid": "z8zxhsrCTTEPJFof_1",
                          "ref": 1,
                          "office_id": "BOMVS39GM",
                          "sdur": "18:50",
                          "re": "Refundable Before Departure",
                          "stop": 1,
                          "sup": "0",
                          "cl": "Economy",
                          "ft": "GDS",
                          "tecst": "yes",
                          "dc": "no",
                          "dealcode": [],
                          "tktair": "HR",
                          "seg": [
                              {
                                  "ref": 1,
                                  "da": "DOH",
                                  "ar": "KUL",
                                  "dd": "2023-06-16T01:50:00",
                                  "ad": "2023-06-16T14:40:00",
                                  "rbd": "Y",
                                  "fn": "161",
                                  "eq": "333",
                                  "ac": "MH",
                                  "ma": "MH",
                                  "seat": "9",
                                  "oa": "MH",
                                  "fa": [],
                                  "fdur": "",
                                  "cl": "Economy",
                                  "terminal": {
                                      "dep": "",
                                      "arr": "1"
                                  }
                              },
                              {
                                  "ref": 2,
                                  "da": "KUL",
                                  "ar": "HYD",
                                  "dd": "2023-06-16T21:30:00",
                                  "ad": "2023-06-16T23:10:00",
                                  "rbd": "Y",
                                  "fn": "198",
                                  "trn": "1",
                                  "eq": "738",
                                  "ac": "MH",
                                  "ma": "MH",
                                  "seat": "9",
                                  "oa": "MH",
                                  "fa": [],
                                  "fdur": "",
                                  "cl": "Economy",
                                  "terminal": {
                                      "dep": "1",
                                      "arr": ""
                                  }
                              }
                          ],
                          "flightNumbers": "161/198",
                          "bgd": [
                              {
                                  "qun": "35",
                                  "wt": "kg"
                              }
                          ]
                      },
                      {
                          "tid": "z8zxhsrCTTEPJFof_2",
                          "ref": 2,
                          "office_id": "BOMVS39GM",
                          "sdur": "26:05",
                          "re": "Refundable Before Departure",
                          "stop": 1,
                          "sup": "0",
                          "cl": "Economy",
                          "ft": "GDS",
                          "tecst": "yes",
                          "dc": "no",
                          "dealcode": [],
                          "tktair": "HR",
                          "seg": [
                              {
                                  "ref": 1,
                                  "da": "HYD",
                                  "ar": "KUL",
                                  "dd": "2023-06-19T00:15:00",
                                  "ad": "2023-06-19T07:10:00",
                                  "rbd": "Y",
                                  "fn": "199",
                                  "eq": "738",
                                  "ac": "MH",
                                  "ma": "MH",
                                  "seat": "9",
                                  "oa": "MH",
                                  "fa": [],
                                  "fdur": "",
                                  "cl": "Economy",
                                  "terminal": {
                                      "dep": "",
                                      "arr": "1"
                                  }
                              },
                              {
                                  "ref": 2,
                                  "da": "KUL",
                                  "ar": "DOH",
                                  "dd": "2023-06-19T21:20:00",
                                  "ad": "2023-06-19T23:50:00",
                                  "rbd": "Y",
                                  "fn": "160",
                                  "trn": "1",
                                  "eq": "333",
                                  "ac": "MH",
                                  "ma": "MH",
                                  "seat": "9",
                                  "oa": "MH",
                                  "fa": [],
                                  "fdur": "",
                                  "cl": "Economy",
                                  "terminal": {
                                      "dep": "1",
                                      "arr": ""
                                  }
                              }
                          ],
                          "flightNumbers": "199/160",
                          "bgd": [
                              {
                                  "qun": "35",
                                  "wt": "kg"
                              }
                          ]
                      },
                      {
                          "tid": "1t6buPpQaEL5FG0h_2",
                          "ref": 2,
                          "office_id": "BOMVS39GM",
                          "sdur": "31:30",
                          "re": "Refundable Before Departure",
                          "stop": 1,
                          "sup": "0",
                          "cl": "Economy",
                          "ft": "GDS",
                          "tecst": "yes",
                          "dc": "no",
                          "dealcode": [],
                          "tktair": "HR",
                          "seg": [
                              {
                                  "ref": 1,
                                  "da": "HYD",
                                  "ar": "KUL",
                                  "dd": "2023-06-19T00:15:00",
                                  "ad": "2023-06-19T07:10:00",
                                  "rbd": "Y",
                                  "fn": "199",
                                  "eq": "738",
                                  "ac": "MH",
                                  "ma": "MH",
                                  "seat": "9",
                                  "oa": "MH",
                                  "fa": [],
                                  "fdur": "",
                                  "cl": "Economy",
                                  "terminal": {
                                      "dep": "",
                                      "arr": "1"
                                  }
                              },
                              {
                                  "ref": 2,
                                  "da": "KUL",
                                  "ar": "DOH",
                                  "dd": "2023-06-20T02:55:00",
                                  "ad": "2023-06-20T05:15:00",
                                  "rbd": "Y",
                                  "fn": "164",
                                  "trn": "1",
                                  "eq": "333",
                                  "ac": "MH",
                                  "ma": "MH",
                                  "seat": "9",
                                  "oa": "MH",
                                  "fa": [],
                                  "fdur": "",
                                  "cl": "Economy",
                                  "terminal": {
                                      "dep": "1",
                                      "arr": ""
                                  }
                              }
                          ],
                          "flightNumbers": "199/164",
                          "bgd": [
                              {
                                  "qun": "35",
                                  "wt": "kg"
                              }
                          ]
                      }
                  ],
                  "pr": {
                      "bf": 574722.5,
                      "tf": 591995.5,
                      "tax": [
                          {
                              "OT": 17273,
                              "YQ": 0,
                              "YR": 0
                          },
                          {
                              "dealmkp": 0
                          },
                          {
                              "attmkp": 52247.5
                          },
                          {
                              "tamkp": 0
                          },
                          {
                              "stamkp": 0
                          },
                          {
                              "attdisc": 0
                          },
                          {
                              "tadisc": 0
                          },
                          {
                              "stadisc": 0
                          }
                      ],
                      "scur": "QAR",
                      "ucur": "INR",
                      "exchange_rate": 1,
                      "sup_fare": "522475_539748",
                      "markupnames": [
                          {
                              "code": "MTA",
                              "name": "LaxmiAprilMarkup"
                          }
                      ]
                  },
                  "paxpr": [
                      {
                          "tf": 591995.5,
                          "tax": [
                              {
                                  "OT": 17273,
                                  "YQ": 0,
                                  "YR": 0
                              },
                              {
                                  "attmkp": 52247.5
                              },
                              {
                                  "tamkp": 0
                              },
                              {
                                  "stamkp": 0
                              },
                              {
                                  "attdisc": 0
                              },
                              {
                                  "tadisc": 0
                              },
                              {
                                  "stadisc": 0
                              }
                          ],
                          "bf": 574722.5,
                          "fbc": "YIFMH",
                          "fareType": "RP",
                          "pusup_fare": "522475_539748",
                          "psup_fare": "522475_539748",
                          "ptype": "ADT"
                      }
                  ],
                  "sup": "0",
                  "airlinesList": "UL_MH",
                  "servicefee": [
                      {
                          "type": "ATT",
                          "adt": 57472.25,
                          "chd": 0,
                          "inf": 0,
                          "servicefee_name": "LaxmiServieApril",
                          "apl_on": "Per Ticket",
                          "sf": 0,
                          "sf_disc": 0,
                          "adt_sf_disc": 0,
                          "chd_sf_disc": 0,
                          "inf_sf_disc": 0,
                          "complete_sfvalue": 57472.25,
                          "sup_fee": 0
                      }
                  ]
              }
          ],
          "mappings": [
              {
                  "airportCode": "HYD",
                  "airportName": "Rajiv Gandhi International Airport",
                  "cityName": "Hyderabad",
                  "countryName": "India",
                  "countryCode": "IN"
              },
              {
                  "airportCode": "KUL",
                  "airportName": "Kuala Lumpur International Airport",
                  "cityName": "Kuala Lumpur",
                  "countryName": "Malaysia",
                  "countryCode": "MY"
              },
              {
                  "airportCode": "CMB",
                  "airportName": "Bandaranayake International Airport",
                  "cityName": "Colombo",
                  "countryName": "Sri Lanka",
                  "countryCode": "LK"
              },
              {
                  "airportCode": "DOH",
                  "airportName": "Hamad International Airport",
                  "cityName": "Doha",
                  "countryName": "Qatar",
                  "countryCode": "QA"
              },
              {
                  "airportCode": "HYD",
                  "airportName": "Rajiv Gandhi International Airport",
                  "cityName": "Hyderabad",
                  "countryName": "India",
                  "countryCode": "IN"
              },
              {
                  "airportCode": "KUL",
                  "airportName": "Kuala Lumpur International Airport",
                  "cityName": "Kuala Lumpur",
                  "countryName": "Malaysia",
                  "countryCode": "MY"
              },
              {
                  "airportCode": "CMB",
                  "airportName": "Bandaranayake International Airport",
                  "cityName": "Colombo",
                  "countryName": "Sri Lanka",
                  "countryCode": "LK"
              },
              {
                  "airportCode": "DOH",
                  "airportName": "Hamad International Airport",
                  "cityName": "Doha",
                  "countryName": "Qatar",
                  "countryCode": "QA"
              }
          ],
          "echo": "8upeqniv57o",
          "cid": "327",
          "tt": 3,
          "sty": "normal",
          "sec": [
              {
                  "src": "DOH",
                  "des": "HYD",
                  "dd": "2023-06-16",
                  "ad": "",
                  "leg": 1
              },
              {
                  "src": "HYD",
                  "des": "DOH",
                  "dd": "2023-06-19",
                  "ad": "",
                  "leg": 2
              },
              {
                  "src": "DOH",
                  "des": "HYD",
                  "dd": "2023-06-21",
                  "ad": "",
                  "leg": 3
              }
          ],
          "filter": {
              "nerby": [],
              "nerbyarr": [],
              "suplrs": [
                  {
                      "sup_name": "Full Service",
                      "sup_id": 0
                  }
              ],
              "fstst": 638866.1000000001,
              "chpst": 638866.1000000001,
              "minprc": 638866.1000000001,
              "maxprc": 678232.85,
              "best": 638866.1000000001,
              "stps": [
                  {
                      "count": 1,
                      "prc": 638866.1000000001,
                      "airline": "UL"
                  }
              ],
              "airl": [
                  {
                      "count": 1,
                      "prc": 638866.1000000001,
                      "airline": "UL"
                  }
              ]
          },
          "cur": "INR",
          "adt": 1,
          "chd": 0,
          "inf": 0,
          "gst": {
              "type": "same",
              "sgst": 9,
              "cgst": 9,
              "igst": 0,
              "gst_invoice": []
          }
      }
  ]
};

export const FLIGHT_RESPONSE_ROUNDTRIP_CONTENT_AGGREGATION = {
  "status": "success",
  "data": [
    {
      "origns": [
        {
          "srcorigns": [
            {
              "tid": "T6JHTocz9nTVgOHr_1",
              "ref": 1,
              "ofcid": "",
              "sdur": "14:30",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "DOH",
                  "ar": "MAA",
                  "dd": "2023-06-29T02:00:00",
                  "ad": "2023-06-29T09:05:00",
                  "rbd": "",
                  "fn": "1322",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "3"
                  },
                  "rph": "",
                  "jsell": "6E~1322~ ~~DOH~06/29/2023 02:00~MAA~06/29/2023 09:05~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                },
                {
                  "ref": 2,
                  "da": "MAA",
                  "ar": "HYD",
                  "dd": "2023-06-29T12:20:00",
                  "ad": "2023-06-29T13:30:00",
                  "rbd": "",
                  "fn": " 604",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "1"
                  },
                  "rph": "",
                  "jsell": "6E~ 604~ ~~MAA~06/29/2023 12:20~HYD~06/29/2023 13:30~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~R~ ~6E~RCINT~6200~~0~65~~X^0~R~ ~6E~RCINT~6200~~0~65~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 9819,
                      "fbc": "RCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "9819_13326",
                      "tax": [
                        {
                          "OT": 3507
                        }
                      ],
                      "tf": 13326
                    }
                  ],
                  "pr": {
                    "bf": 9819,
                    "tf": 13326,
                    "tax": [
                      {
                        "OT": 3507
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "9819_13326",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "wtv0hxxtci",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~R~ ~6E~RCUINT~6202~~0~4~~X^0~R~ ~6E~RCUINT~6202~~0~4~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 10519,
                      "fbc": "RCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "10519_14050",
                      "tax": [
                        {
                          "OT": 3531
                        }
                      ],
                      "tf": 14050
                    }
                  ],
                  "pr": {
                    "bf": 10519,
                    "tf": 14050,
                    "tax": [
                      {
                        "OT": 3531
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "10519_14050",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "bi6l59bbwo",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            },
            {
              "tid": "toowEfWmKBJCpqwr_1",
              "ref": 1,
              "ofcid": "",
              "sdur": "19:15",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "DOH",
                  "ar": "MAA",
                  "dd": "2023-06-29T02:00:00",
                  "ad": "2023-06-29T09:05:00",
                  "rbd": "",
                  "fn": "1322",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "3"
                  },
                  "rph": "",
                  "jsell": "6E~1322~ ~~DOH~06/29/2023 02:00~MAA~06/29/2023 09:05~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                },
                {
                  "ref": 2,
                  "da": "MAA",
                  "ar": "HYD",
                  "dd": "2023-06-29T17:00:00",
                  "ad": "2023-06-29T18:15:00",
                  "rbd": "",
                  "fn": " 562",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "1"
                  },
                  "rph": "",
                  "jsell": "6E~ 562~ ~~MAA~06/29/2023 17:00~HYD~06/29/2023 18:15~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~R~ ~6E~RCINT~6200~~0~65~~X^0~R~ ~6E~RCINT~6200~~0~65~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 9819,
                      "fbc": "RCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "9819_13326",
                      "tax": [
                        {
                          "OT": 3507
                        }
                      ],
                      "tf": 13326
                    }
                  ],
                  "pr": {
                    "bf": 9819,
                    "tf": 13326,
                    "tax": [
                      {
                        "OT": 3507
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "9819_13326",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "jjjmqc10fb",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~R~ ~6E~RCUINT~6202~~0~4~~X^0~R~ ~6E~RCUINT~6202~~0~4~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 10519,
                      "fbc": "RCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "10519_14050",
                      "tax": [
                        {
                          "OT": 3531
                        }
                      ],
                      "tf": 14050
                    }
                  ],
                  "pr": {
                    "bf": 10519,
                    "tf": 14050,
                    "tax": [
                      {
                        "OT": 3531
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "10519_14050",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "h0u2fbwnj7h",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            },
            {
              "tid": "xU1hRSJngRADaTkh_1",
              "ref": 1,
              "ofcid": "",
              "sdur": "18:10",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "DOH",
                  "ar": "MAA",
                  "dd": "2023-06-29T02:00:00",
                  "ad": "2023-06-29T09:05:00",
                  "rbd": "",
                  "fn": "1322",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "3"
                  },
                  "rph": "",
                  "jsell": "6E~1322~ ~~DOH~06/29/2023 02:00~MAA~06/29/2023 09:05~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                },
                {
                  "ref": 2,
                  "da": "MAA",
                  "ar": "HYD",
                  "dd": "2023-06-29T16:00:00",
                  "ad": "2023-06-29T17:10:00",
                  "rbd": "",
                  "fn": "6401",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "1"
                  },
                  "rph": "",
                  "jsell": "6E~6401~ ~~MAA~06/29/2023 16:00~HYD~06/29/2023 17:10~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~R~ ~6E~RCINT~6200~~0~65~~X^0~R~ ~6E~RCINT~6200~~0~65~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 9819,
                      "fbc": "RCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "9819_13326",
                      "tax": [
                        {
                          "OT": 3507
                        }
                      ],
                      "tf": 13326
                    }
                  ],
                  "pr": {
                    "bf": 9819,
                    "tf": 13326,
                    "tax": [
                      {
                        "OT": 3507
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "9819_13326",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "e8p21trnlz",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~R~ ~6E~RCUINT~6202~~0~4~~X^0~R~ ~6E~RCUINT~6202~~0~4~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 10519,
                      "fbc": "RCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "10519_14050",
                      "tax": [
                        {
                          "OT": 3531
                        }
                      ],
                      "tf": 14050
                    }
                  ],
                  "pr": {
                    "bf": 10519,
                    "tf": 14050,
                    "tax": [
                      {
                        "OT": 3531
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "10519_14050",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "5s6ibwmnfk",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            },
            {
              "tid": "HwUFBSpGvP1nVgDH_1",
              "ref": 1,
              "ofcid": "",
              "sdur": "16:0",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "DOH",
                  "ar": "DEL",
                  "dd": "2023-06-29T19:40:00",
                  "ad": "2023-06-30T02:00:00",
                  "rbd": "",
                  "fn": "1308",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "3"
                  },
                  "rph": "",
                  "jsell": "6E~1308~ ~~DOH~06/29/2023 19:40~DEL~06/30/2023 02:00~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                },
                {
                  "ref": 2,
                  "da": "DEL",
                  "ar": "HYD",
                  "dd": "2023-06-30T06:30:00",
                  "ad": "2023-06-30T08:40:00",
                  "rbd": "",
                  "fn": "6203",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "1"
                  },
                  "rph": "",
                  "jsell": "6E~6203~ ~~DEL~06/30/2023 06:30~HYD~06/30/2023 08:40~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~R~ ~6E~RCINT~6200~~0~65~~X^0~R~ ~6E~RCINT~6200~~0~65~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 9819,
                      "fbc": "RCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "9819_13326",
                      "tax": [
                        {
                          "OT": 3507
                        }
                      ],
                      "tf": 13326
                    }
                  ],
                  "pr": {
                    "bf": 9819,
                    "tf": 13326,
                    "tax": [
                      {
                        "OT": 3507
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "9819_13326",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "9uvk9x9fso",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~R~ ~6E~RCUINT~6202~~0~4~~X^0~R~ ~6E~RCUINT~6202~~0~4~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 10519,
                      "fbc": "RCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "10519_14050",
                      "tax": [
                        {
                          "OT": 3531
                        }
                      ],
                      "tf": 14050
                    }
                  ],
                  "pr": {
                    "bf": 10519,
                    "tf": 14050,
                    "tax": [
                      {
                        "OT": 3531
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "10519_14050",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "vvkhvwghpd",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            }
          ],
          "destorigins": [
            {
              "tid": "SFsWXvK7zi0Thzd2_2",
              "ref": 2,
              "ofcid": "",
              "sdur": "6:30",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "MAA",
                  "dd": "2023-06-30T14:05:00",
                  "ad": "2023-06-30T15:20:00",
                  "rbd": "",
                  "fn": " 514",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "1"
                  },
                  "rph": "",
                  "jsell": "6E~ 514~ ~~HYD~06/30/2023 14:05~MAA~06/30/2023 15:20~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                },
                {
                  "ref": 2,
                  "da": "MAA",
                  "ar": "DOH",
                  "dd": "2023-06-30T21:30:00",
                  "ad": "2023-06-30T23:35:00",
                  "rbd": "",
                  "fn": "1321",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "4"
                  },
                  "rph": "",
                  "jsell": "6E~1321~ ~~MAA~06/30/2023 21:30~DOH~06/30/2023 23:35~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~R~ ~6E~RCINT~6200~~0~66~~X^0~R~ ~6E~RCINT~6200~~0~66~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 8036,
                      "fbc": "RCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "8036_11726",
                      "tax": [
                        {
                          "OT": 3690
                        }
                      ],
                      "tf": 11726
                    }
                  ],
                  "pr": {
                    "bf": 8036,
                    "tf": 11726,
                    "tax": [
                      {
                        "OT": 3690
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "8036_11726",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "7iwdw24ackg",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~R~ ~6E~RCUINT~6202~~0~5~~X^0~R~ ~6E~RCUINT~6202~~0~5~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 8736,
                      "fbc": "RCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "8736_12487",
                      "tax": [
                        {
                          "OT": 3751
                        }
                      ],
                      "tf": 12487
                    }
                  ],
                  "pr": {
                    "bf": 8736,
                    "tf": 12487,
                    "tax": [
                      {
                        "OT": 3751
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "8736_12487",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "cdt7wlaxp5f",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            },
            {
              "tid": "rTwDcfy8aMyPrmG6_2",
              "ref": 2,
              "ofcid": "",
              "sdur": "4:0",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "MAA",
                  "dd": "2023-06-30T16:35:00",
                  "ad": "2023-06-30T17:45:00",
                  "rbd": "",
                  "fn": " 668",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "1"
                  },
                  "rph": "",
                  "jsell": "6E~ 668~ ~~HYD~06/30/2023 16:35~MAA~06/30/2023 17:45~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                },
                {
                  "ref": 2,
                  "da": "MAA",
                  "ar": "DOH",
                  "dd": "2023-06-30T21:30:00",
                  "ad": "2023-06-30T23:35:00",
                  "rbd": "",
                  "fn": "1321",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "4"
                  },
                  "rph": "",
                  "jsell": "6E~1321~ ~~MAA~06/30/2023 21:30~DOH~06/30/2023 23:35~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~R~ ~6E~RCINT~6200~~0~66~~X^0~R~ ~6E~RCINT~6200~~0~66~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 8036,
                      "fbc": "RCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "8036_11726",
                      "tax": [
                        {
                          "OT": 3690
                        }
                      ],
                      "tf": 11726
                    }
                  ],
                  "pr": {
                    "bf": 8036,
                    "tf": 11726,
                    "tax": [
                      {
                        "OT": 3690
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "8036_11726",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "nchtj8ehc3",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~R~ ~6E~RCUINT~6202~~0~5~~X^0~R~ ~6E~RCUINT~6202~~0~5~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 8736,
                      "fbc": "RCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "8736_12487",
                      "tax": [
                        {
                          "OT": 3751
                        }
                      ],
                      "tf": 12487
                    }
                  ],
                  "pr": {
                    "bf": 8736,
                    "tf": 12487,
                    "tax": [
                      {
                        "OT": 3751
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "8736_12487",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "3nvwucgiqo",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            },
            {
              "tid": "xqhTVePoZ5kRbs5G_2",
              "ref": 2,
              "ofcid": "",
              "sdur": "2:55",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "MAA",
                  "dd": "2023-06-30T17:40:00",
                  "ad": "2023-06-30T18:55:00",
                  "rbd": "",
                  "fn": " 102",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "1"
                  },
                  "rph": "",
                  "jsell": "6E~ 102~ ~~HYD~06/30/2023 17:40~MAA~06/30/2023 18:55~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                },
                {
                  "ref": 2,
                  "da": "MAA",
                  "ar": "DOH",
                  "dd": "2023-06-30T21:30:00",
                  "ad": "2023-06-30T23:35:00",
                  "rbd": "",
                  "fn": "1321",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "4"
                  },
                  "rph": "",
                  "jsell": "6E~1321~ ~~MAA~06/30/2023 21:30~DOH~06/30/2023 23:35~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~R~ ~6E~RCINT~6200~~0~66~~X^0~R~ ~6E~RCINT~6200~~0~66~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 8036,
                      "fbc": "RCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "8036_11726",
                      "tax": [
                        {
                          "OT": 3690
                        }
                      ],
                      "tf": 11726
                    }
                  ],
                  "pr": {
                    "bf": 8036,
                    "tf": 11726,
                    "tax": [
                      {
                        "OT": 3690
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "8036_11726",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "vqtnp7d1v5",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~R~ ~6E~RCUINT~6202~~0~5~~X^0~R~ ~6E~RCUINT~6202~~0~5~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 8736,
                      "fbc": "RCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "8736_12487",
                      "tax": [
                        {
                          "OT": 3751
                        }
                      ],
                      "tf": 12487
                    }
                  ],
                  "pr": {
                    "bf": 8736,
                    "tf": 12487,
                    "tax": [
                      {
                        "OT": 3751
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "8736_12487",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "4isxtt2tng",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            }
          ],
          "paxpr": [
            {
              "bf": 17855,
              "tf": 25052,
              "fbc": "",
              "psup_fare": "",
              "tax": [
                [
                  {
                    "OT": 3507
                  }
                ],
                [
                  {
                    "OT": 3690
                  }
                ]
              ],
              "ptype": "ADT",
              "scur": "INR",
              "sup_cur_fare": ""
            }
          ],
          "pr": {
            "bf": 9819,
            "tf": 13326,
            "tax": [
              {
                "OT": 3507
              },
              {
                "OT": 3690
              }
            ],
            "scur": "INR",
            "sup_fare": "",
            "sup_cur_fare": "",
            "cabb": "",
            "chekb": "",
            "seats": "",
            "meals": "",
            "chanb": "",
            "ftyp": ""
          },
          "sup": "4",
          "airlineList": "6E"
        },
        {
          "srcorigns": [
            {
              "tid": "UbFZaW8WzJUb8rX5_1",
              "ref": 1,
              "ofcid": "",
              "sdur": "14:30",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "DOH",
                  "ar": "MAA",
                  "dd": "2023-06-29T02:00:00",
                  "ad": "2023-06-29T09:05:00",
                  "rbd": "",
                  "fn": "1322",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "3"
                  },
                  "rph": "",
                  "jsell": "6E~1322~ ~~DOH~06/29/2023 02:00~MAA~06/29/2023 09:05~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                },
                {
                  "ref": 2,
                  "da": "MAA",
                  "ar": "HYD",
                  "dd": "2023-06-29T12:20:00",
                  "ad": "2023-06-29T13:30:00",
                  "rbd": "",
                  "fn": " 604",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "1"
                  },
                  "rph": "",
                  "jsell": "6E~ 604~ ~~MAA~06/29/2023 12:20~HYD~06/29/2023 13:30~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~R~ ~6E~RCINT~6200~~0~65~~X^0~R~ ~6E~RCINT~6200~~0~65~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 9819,
                      "fbc": "RCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "9819_13326",
                      "tax": [
                        {
                          "OT": 3507
                        }
                      ],
                      "tf": 13326
                    }
                  ],
                  "pr": {
                    "bf": 9819,
                    "tf": 13326,
                    "tax": [
                      {
                        "OT": 3507
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "9819_13326",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "wtv0hxxtci",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~R~ ~6E~RCUINT~6202~~0~4~~X^0~R~ ~6E~RCUINT~6202~~0~4~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 10519,
                      "fbc": "RCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "10519_14050",
                      "tax": [
                        {
                          "OT": 3531
                        }
                      ],
                      "tf": 14050
                    }
                  ],
                  "pr": {
                    "bf": 10519,
                    "tf": 14050,
                    "tax": [
                      {
                        "OT": 3531
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "10519_14050",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "bi6l59bbwo",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            },
            {
              "tid": "OxV7xDoU7GkHOSiW_1",
              "ref": 1,
              "ofcid": "",
              "sdur": "19:15",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "DOH",
                  "ar": "MAA",
                  "dd": "2023-06-29T02:00:00",
                  "ad": "2023-06-29T09:05:00",
                  "rbd": "",
                  "fn": "1322",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "3"
                  },
                  "rph": "",
                  "jsell": "6E~1322~ ~~DOH~06/29/2023 02:00~MAA~06/29/2023 09:05~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                },
                {
                  "ref": 2,
                  "da": "MAA",
                  "ar": "HYD",
                  "dd": "2023-06-29T17:00:00",
                  "ad": "2023-06-29T18:15:00",
                  "rbd": "",
                  "fn": " 562",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "1"
                  },
                  "rph": "",
                  "jsell": "6E~ 562~ ~~MAA~06/29/2023 17:00~HYD~06/29/2023 18:15~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~R~ ~6E~RCINT~6200~~0~65~~X^0~R~ ~6E~RCINT~6200~~0~65~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 9819,
                      "fbc": "RCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "9819_13326",
                      "tax": [
                        {
                          "OT": 3507
                        }
                      ],
                      "tf": 13326
                    }
                  ],
                  "pr": {
                    "bf": 9819,
                    "tf": 13326,
                    "tax": [
                      {
                        "OT": 3507
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "9819_13326",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "jjjmqc10fb",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~R~ ~6E~RCUINT~6202~~0~4~~X^0~R~ ~6E~RCUINT~6202~~0~4~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 10519,
                      "fbc": "RCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "10519_14050",
                      "tax": [
                        {
                          "OT": 3531
                        }
                      ],
                      "tf": 14050
                    }
                  ],
                  "pr": {
                    "bf": 10519,
                    "tf": 14050,
                    "tax": [
                      {
                        "OT": 3531
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "10519_14050",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "h0u2fbwnj7h",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            },
            {
              "tid": "4bGuNMxpI7gT74I4_1",
              "ref": 1,
              "ofcid": "",
              "sdur": "18:10",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "DOH",
                  "ar": "MAA",
                  "dd": "2023-06-29T02:00:00",
                  "ad": "2023-06-29T09:05:00",
                  "rbd": "",
                  "fn": "1322",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "3"
                  },
                  "rph": "",
                  "jsell": "6E~1322~ ~~DOH~06/29/2023 02:00~MAA~06/29/2023 09:05~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                },
                {
                  "ref": 2,
                  "da": "MAA",
                  "ar": "HYD",
                  "dd": "2023-06-29T16:00:00",
                  "ad": "2023-06-29T17:10:00",
                  "rbd": "",
                  "fn": "6401",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "1"
                  },
                  "rph": "",
                  "jsell": "6E~6401~ ~~MAA~06/29/2023 16:00~HYD~06/29/2023 17:10~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~R~ ~6E~RCINT~6200~~0~65~~X^0~R~ ~6E~RCINT~6200~~0~65~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 9819,
                      "fbc": "RCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "9819_13326",
                      "tax": [
                        {
                          "OT": 3507
                        }
                      ],
                      "tf": 13326
                    }
                  ],
                  "pr": {
                    "bf": 9819,
                    "tf": 13326,
                    "tax": [
                      {
                        "OT": 3507
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "9819_13326",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "e8p21trnlz",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~R~ ~6E~RCUINT~6202~~0~4~~X^0~R~ ~6E~RCUINT~6202~~0~4~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 10519,
                      "fbc": "RCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "10519_14050",
                      "tax": [
                        {
                          "OT": 3531
                        }
                      ],
                      "tf": 14050
                    }
                  ],
                  "pr": {
                    "bf": 10519,
                    "tf": 14050,
                    "tax": [
                      {
                        "OT": 3531
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "10519_14050",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "5s6ibwmnfk",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            },
            {
              "tid": "ZuzGZkRV0ZJ2E61M_1",
              "ref": 1,
              "ofcid": "",
              "sdur": "16:0",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "DOH",
                  "ar": "DEL",
                  "dd": "2023-06-29T19:40:00",
                  "ad": "2023-06-30T02:00:00",
                  "rbd": "",
                  "fn": "1308",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "3"
                  },
                  "rph": "",
                  "jsell": "6E~1308~ ~~DOH~06/29/2023 19:40~DEL~06/30/2023 02:00~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                },
                {
                  "ref": 2,
                  "da": "DEL",
                  "ar": "HYD",
                  "dd": "2023-06-30T06:30:00",
                  "ad": "2023-06-30T08:40:00",
                  "rbd": "",
                  "fn": "6203",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "1"
                  },
                  "rph": "",
                  "jsell": "6E~6203~ ~~DEL~06/30/2023 06:30~HYD~06/30/2023 08:40~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~R~ ~6E~RCINT~6200~~0~65~~X^0~R~ ~6E~RCINT~6200~~0~65~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 9819,
                      "fbc": "RCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "9819_13326",
                      "tax": [
                        {
                          "OT": 3507
                        }
                      ],
                      "tf": 13326
                    }
                  ],
                  "pr": {
                    "bf": 9819,
                    "tf": 13326,
                    "tax": [
                      {
                        "OT": 3507
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "9819_13326",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "9uvk9x9fso",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~R~ ~6E~RCUINT~6202~~0~4~~X^0~R~ ~6E~RCUINT~6202~~0~4~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 10519,
                      "fbc": "RCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "10519_14050",
                      "tax": [
                        {
                          "OT": 3531
                        }
                      ],
                      "tf": 14050
                    }
                  ],
                  "pr": {
                    "bf": 10519,
                    "tf": 14050,
                    "tax": [
                      {
                        "OT": 3531
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "10519_14050",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "vvkhvwghpd",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            }
          ],
          "destorigins": [
            {
              "tid": "4mThbu1XIms6FKqb_2",
              "ref": 2,
              "ofcid": "",
              "sdur": "0:-80",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DOH",
                  "dd": "2023-06-30T04:10:00",
                  "ad": "2023-06-30T05:50:00",
                  "rbd": "",
                  "fn": "1313",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {},
                  "rph": "",
                  "jsell": "6E~1313~ ~~HYD~06/30/2023 04:10~DOH~06/30/2023 05:50~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~A~ ~6E~A0INT~5000~~0~2~~X",
                  "pr": {
                    "bf": 26311,
                    "tf": 31534,
                    "tax": [
                      {
                        "OT": 5223
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "26311_31534",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "paxpr": [
                    {
                      "bf": 26311,
                      "tf": 31534,
                      "tax": [
                        {
                          "OT": 5223
                        }
                      ],
                      "fbc": "A0INT",
                      "ptype": "ADT",
                      "scur": "INR",
                      "sup_fare": "26311_31534"
                    }
                  ],
                  "routeId": "r3tu02nuvl",
                  "productClass": "R",
                  "ruleNumber": "5000"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 0
            },
            {
              "tid": "hlEiKvOkh1XORiav_2",
              "ref": 2,
              "ofcid": "",
              "sdur": "0:-80",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DOH",
                  "dd": "2023-06-30T17:05:00",
                  "ad": "2023-06-30T18:45:00",
                  "rbd": "",
                  "fn": "1315",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {},
                  "rph": "",
                  "jsell": "6E~1315~ ~~HYD~06/30/2023 17:05~DOH~06/30/2023 18:45~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~A~ ~6E~A0INT~5000~~0~2~~X",
                  "pr": {
                    "bf": 26311,
                    "tf": 31534,
                    "tax": [
                      {
                        "OT": 5223
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "26311_31534",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "paxpr": [
                    {
                      "bf": 26311,
                      "tf": 31534,
                      "tax": [
                        {
                          "OT": 5223
                        }
                      ],
                      "fbc": "A0INT",
                      "ptype": "ADT",
                      "scur": "INR",
                      "sup_fare": "26311_31534"
                    }
                  ],
                  "routeId": "plmhhnxmfz",
                  "productClass": "R",
                  "ruleNumber": "5000"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 0
            },
            {
              "tid": "daHyR0VhGN9Jkif4_2",
              "ref": 2,
              "ofcid": "",
              "sdur": "0:-80",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DOH",
                  "dd": "2023-06-30T21:55:00",
                  "ad": "2023-06-30T23:35:00",
                  "rbd": "",
                  "fn": "1317",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {},
                  "rph": "",
                  "jsell": "6E~1317~ ~~HYD~06/30/2023 21:55~DOH~06/30/2023 23:35~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~A~ ~6E~A0INT~5000~~0~2~~X",
                  "pr": {
                    "bf": 26311,
                    "tf": 31534,
                    "tax": [
                      {
                        "OT": 5223
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "26311_31534",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "paxpr": [
                    {
                      "bf": 26311,
                      "tf": 31534,
                      "tax": [
                        {
                          "OT": 5223
                        }
                      ],
                      "fbc": "A0INT",
                      "ptype": "ADT",
                      "scur": "INR",
                      "sup_fare": "26311_31534"
                    }
                  ],
                  "routeId": "50mlhtyd6c",
                  "productClass": "R",
                  "ruleNumber": "5000"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 0
            }
          ],
          "paxpr": [
            {
              "bf": 36130,
              "tf": 44860,
              "fbc": "",
              "psup_fare": "",
              "tax": [
                [
                  {
                    "OT": 3507
                  }
                ],
                [
                  {
                    "OT": 5223
                  }
                ]
              ],
              "ptype": "ADT",
              "scur": "INR",
              "sup_cur_fare": ""
            }
          ],
          "pr": {
            "bf": 9819,
            "tf": 13326,
            "tax": [
              {
                "OT": 3507
              },
              {
                "OT": 5223
              }
            ],
            "scur": "INR",
            "sup_fare": "",
            "sup_cur_fare": "",
            "cabb": "",
            "chekb": "",
            "seats": "",
            "meals": "",
            "chanb": "",
            "ftyp": ""
          },
          "sup": "4",
          "airlineList": "6E"
        },
        {
          "srcorigns": [
            {
              "tid": "QNqWWU6tvx4xcAfv_1",
              "ref": 1,
              "ofcid": "",
              "sdur": "14:30",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "DOH",
                  "ar": "MAA",
                  "dd": "2023-06-29T02:00:00",
                  "ad": "2023-06-29T09:05:00",
                  "rbd": "",
                  "fn": "1322",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "3"
                  },
                  "rph": "",
                  "jsell": "6E~1322~ ~~DOH~06/29/2023 02:00~MAA~06/29/2023 09:05~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                },
                {
                  "ref": 2,
                  "da": "MAA",
                  "ar": "HYD",
                  "dd": "2023-06-29T12:20:00",
                  "ad": "2023-06-29T13:30:00",
                  "rbd": "",
                  "fn": " 604",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "1"
                  },
                  "rph": "",
                  "jsell": "6E~ 604~ ~~MAA~06/29/2023 12:20~HYD~06/29/2023 13:30~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~R~ ~6E~RCINT~6200~~0~65~~X^0~R~ ~6E~RCINT~6200~~0~65~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 9819,
                      "fbc": "RCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "9819_13326",
                      "tax": [
                        {
                          "OT": 3507
                        }
                      ],
                      "tf": 13326
                    }
                  ],
                  "pr": {
                    "bf": 9819,
                    "tf": 13326,
                    "tax": [
                      {
                        "OT": 3507
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "9819_13326",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "wtv0hxxtci",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~R~ ~6E~RCUINT~6202~~0~4~~X^0~R~ ~6E~RCUINT~6202~~0~4~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 10519,
                      "fbc": "RCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "10519_14050",
                      "tax": [
                        {
                          "OT": 3531
                        }
                      ],
                      "tf": 14050
                    }
                  ],
                  "pr": {
                    "bf": 10519,
                    "tf": 14050,
                    "tax": [
                      {
                        "OT": 3531
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "10519_14050",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "bi6l59bbwo",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            },
            {
              "tid": "rd7y0R8BULz7DZ3G_1",
              "ref": 1,
              "ofcid": "",
              "sdur": "19:15",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "DOH",
                  "ar": "MAA",
                  "dd": "2023-06-29T02:00:00",
                  "ad": "2023-06-29T09:05:00",
                  "rbd": "",
                  "fn": "1322",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "3"
                  },
                  "rph": "",
                  "jsell": "6E~1322~ ~~DOH~06/29/2023 02:00~MAA~06/29/2023 09:05~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                },
                {
                  "ref": 2,
                  "da": "MAA",
                  "ar": "HYD",
                  "dd": "2023-06-29T17:00:00",
                  "ad": "2023-06-29T18:15:00",
                  "rbd": "",
                  "fn": " 562",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "1"
                  },
                  "rph": "",
                  "jsell": "6E~ 562~ ~~MAA~06/29/2023 17:00~HYD~06/29/2023 18:15~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~R~ ~6E~RCINT~6200~~0~65~~X^0~R~ ~6E~RCINT~6200~~0~65~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 9819,
                      "fbc": "RCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "9819_13326",
                      "tax": [
                        {
                          "OT": 3507
                        }
                      ],
                      "tf": 13326
                    }
                  ],
                  "pr": {
                    "bf": 9819,
                    "tf": 13326,
                    "tax": [
                      {
                        "OT": 3507
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "9819_13326",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "jjjmqc10fb",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~R~ ~6E~RCUINT~6202~~0~4~~X^0~R~ ~6E~RCUINT~6202~~0~4~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 10519,
                      "fbc": "RCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "10519_14050",
                      "tax": [
                        {
                          "OT": 3531
                        }
                      ],
                      "tf": 14050
                    }
                  ],
                  "pr": {
                    "bf": 10519,
                    "tf": 14050,
                    "tax": [
                      {
                        "OT": 3531
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "10519_14050",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "h0u2fbwnj7h",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            },
            {
              "tid": "UTFl3aEeETDFlFN1_1",
              "ref": 1,
              "ofcid": "",
              "sdur": "18:10",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "DOH",
                  "ar": "MAA",
                  "dd": "2023-06-29T02:00:00",
                  "ad": "2023-06-29T09:05:00",
                  "rbd": "",
                  "fn": "1322",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "3"
                  },
                  "rph": "",
                  "jsell": "6E~1322~ ~~DOH~06/29/2023 02:00~MAA~06/29/2023 09:05~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                },
                {
                  "ref": 2,
                  "da": "MAA",
                  "ar": "HYD",
                  "dd": "2023-06-29T16:00:00",
                  "ad": "2023-06-29T17:10:00",
                  "rbd": "",
                  "fn": "6401",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "1"
                  },
                  "rph": "",
                  "jsell": "6E~6401~ ~~MAA~06/29/2023 16:00~HYD~06/29/2023 17:10~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~R~ ~6E~RCINT~6200~~0~65~~X^0~R~ ~6E~RCINT~6200~~0~65~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 9819,
                      "fbc": "RCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "9819_13326",
                      "tax": [
                        {
                          "OT": 3507
                        }
                      ],
                      "tf": 13326
                    }
                  ],
                  "pr": {
                    "bf": 9819,
                    "tf": 13326,
                    "tax": [
                      {
                        "OT": 3507
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "9819_13326",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "e8p21trnlz",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~R~ ~6E~RCUINT~6202~~0~4~~X^0~R~ ~6E~RCUINT~6202~~0~4~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 10519,
                      "fbc": "RCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "10519_14050",
                      "tax": [
                        {
                          "OT": 3531
                        }
                      ],
                      "tf": 14050
                    }
                  ],
                  "pr": {
                    "bf": 10519,
                    "tf": 14050,
                    "tax": [
                      {
                        "OT": 3531
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "10519_14050",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "5s6ibwmnfk",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            },
            {
              "tid": "lGvfkDkNQLeQF7JI_1",
              "ref": 1,
              "ofcid": "",
              "sdur": "16:0",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "DOH",
                  "ar": "DEL",
                  "dd": "2023-06-29T19:40:00",
                  "ad": "2023-06-30T02:00:00",
                  "rbd": "",
                  "fn": "1308",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "3"
                  },
                  "rph": "",
                  "jsell": "6E~1308~ ~~DOH~06/29/2023 19:40~DEL~06/30/2023 02:00~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                },
                {
                  "ref": 2,
                  "da": "DEL",
                  "ar": "HYD",
                  "dd": "2023-06-30T06:30:00",
                  "ad": "2023-06-30T08:40:00",
                  "rbd": "",
                  "fn": "6203",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "1"
                  },
                  "rph": "",
                  "jsell": "6E~6203~ ~~DEL~06/30/2023 06:30~HYD~06/30/2023 08:40~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~R~ ~6E~RCINT~6200~~0~65~~X^0~R~ ~6E~RCINT~6200~~0~65~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 9819,
                      "fbc": "RCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "9819_13326",
                      "tax": [
                        {
                          "OT": 3507
                        }
                      ],
                      "tf": 13326
                    }
                  ],
                  "pr": {
                    "bf": 9819,
                    "tf": 13326,
                    "tax": [
                      {
                        "OT": 3507
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "9819_13326",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "9uvk9x9fso",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~R~ ~6E~RCUINT~6202~~0~4~~X^0~R~ ~6E~RCUINT~6202~~0~4~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 10519,
                      "fbc": "RCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "10519_14050",
                      "tax": [
                        {
                          "OT": 3531
                        }
                      ],
                      "tf": 14050
                    }
                  ],
                  "pr": {
                    "bf": 10519,
                    "tf": 14050,
                    "tax": [
                      {
                        "OT": 3531
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "10519_14050",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "vvkhvwghpd",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            }
          ],
          "destorigins": [
            {
              "tid": "w85ZKv2EL3mZtO0e_2",
              "ref": 2,
              "ofcid": "",
              "sdur": "9:0",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "BOM",
                  "dd": "2023-06-30T06:45:00",
                  "ad": "2023-06-30T08:05:00",
                  "rbd": "",
                  "fn": " 286",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "2"
                  },
                  "rph": "",
                  "jsell": "6E~ 286~ ~~HYD~06/30/2023 06:45~BOM~06/30/2023 08:05~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                },
                {
                  "ref": 2,
                  "da": "BOM",
                  "ar": "DOH",
                  "dd": "2023-06-30T17:50:00",
                  "ad": "2023-06-30T18:45:00",
                  "rbd": "",
                  "fn": "1305",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "2"
                  },
                  "rph": "",
                  "jsell": "6E~1305~ ~~BOM~06/30/2023 17:50~DOH~06/30/2023 18:45~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 30621,
                      "fbc": "WCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "30621_36271",
                      "tax": [
                        {
                          "OT": 5650
                        }
                      ],
                      "tf": 36271
                    }
                  ],
                  "pr": {
                    "bf": 30621,
                    "tf": 36271,
                    "tax": [
                      {
                        "OT": 5650
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "30621_36271",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "qfv8ek4z62",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 31321,
                      "fbc": "WCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "31321_37031",
                      "tax": [
                        {
                          "OT": 5710
                        }
                      ],
                      "tf": 37031
                    }
                  ],
                  "pr": {
                    "bf": 31321,
                    "tf": 37031,
                    "tax": [
                      {
                        "OT": 5710
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "31321_37031",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "jbau5qe56w",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            },
            {
              "tid": "2PKBo3TrfRHvAkcc_2",
              "ref": 2,
              "ofcid": "",
              "sdur": "8:10",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "BOM",
                  "dd": "2023-06-30T07:35:00",
                  "ad": "2023-06-30T09:10:00",
                  "rbd": "",
                  "fn": " 163",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "2"
                  },
                  "rph": "",
                  "jsell": "6E~ 163~ ~~HYD~06/30/2023 07:35~BOM~06/30/2023 09:10~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                },
                {
                  "ref": 2,
                  "da": "BOM",
                  "ar": "DOH",
                  "dd": "2023-06-30T17:50:00",
                  "ad": "2023-06-30T18:45:00",
                  "rbd": "",
                  "fn": "1305",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "2"
                  },
                  "rph": "",
                  "jsell": "6E~1305~ ~~BOM~06/30/2023 17:50~DOH~06/30/2023 18:45~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 30621,
                      "fbc": "WCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "30621_36271",
                      "tax": [
                        {
                          "OT": 5650
                        }
                      ],
                      "tf": 36271
                    }
                  ],
                  "pr": {
                    "bf": 30621,
                    "tf": 36271,
                    "tax": [
                      {
                        "OT": 5650
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "30621_36271",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "dkhfoqjgrp",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 31321,
                      "fbc": "WCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "31321_37031",
                      "tax": [
                        {
                          "OT": 5710
                        }
                      ],
                      "tf": 37031
                    }
                  ],
                  "pr": {
                    "bf": 31321,
                    "tf": 37031,
                    "tax": [
                      {
                        "OT": 5710
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "31321_37031",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "xv6tvhyvur",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            },
            {
              "tid": "O9fUHESXvuvFPrss_2",
              "ref": 2,
              "ofcid": "",
              "sdur": "11:15",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DEL",
                  "dd": "2023-06-30T09:30:00",
                  "ad": "2023-06-30T11:50:00",
                  "rbd": "",
                  "fn": "6204",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "1"
                  },
                  "rph": "",
                  "jsell": "6E~6204~ ~~HYD~06/30/2023 09:30~DEL~06/30/2023 11:50~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                },
                {
                  "ref": 2,
                  "da": "DEL",
                  "ar": "DOH",
                  "dd": "2023-06-30T22:10:00",
                  "ad": "2023-06-30T23:45:00",
                  "rbd": "",
                  "fn": "1311",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "3"
                  },
                  "rph": "",
                  "jsell": "6E~1311~ ~~DEL~06/30/2023 22:10~DOH~06/30/2023 23:45~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 30621,
                      "fbc": "WCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "30621_36271",
                      "tax": [
                        {
                          "OT": 5650
                        }
                      ],
                      "tf": 36271
                    }
                  ],
                  "pr": {
                    "bf": 30621,
                    "tf": 36271,
                    "tax": [
                      {
                        "OT": 5650
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "30621_36271",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "y0lntd8l5l",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 31321,
                      "fbc": "WCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "31321_37031",
                      "tax": [
                        {
                          "OT": 5710
                        }
                      ],
                      "tf": 37031
                    }
                  ],
                  "pr": {
                    "bf": 31321,
                    "tf": 37031,
                    "tax": [
                      {
                        "OT": 5710
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "31321_37031",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "nh1sx7l0cm",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            },
            {
              "tid": "kAoa9UIpF8KcWR6T_2",
              "ref": 2,
              "ofcid": "",
              "sdur": "4:40",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "BOM",
                  "dd": "2023-06-30T11:05:00",
                  "ad": "2023-06-30T12:40:00",
                  "rbd": "",
                  "fn": "5384",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "1"
                  },
                  "rph": "",
                  "jsell": "6E~5384~ ~~HYD~06/30/2023 11:05~BOM~06/30/2023 12:40~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                },
                {
                  "ref": 2,
                  "da": "BOM",
                  "ar": "DOH",
                  "dd": "2023-06-30T17:50:00",
                  "ad": "2023-06-30T18:45:00",
                  "rbd": "",
                  "fn": "1305",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "2"
                  },
                  "rph": "",
                  "jsell": "6E~1305~ ~~BOM~06/30/2023 17:50~DOH~06/30/2023 18:45~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 30621,
                      "fbc": "WCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "30621_36271",
                      "tax": [
                        {
                          "OT": 5650
                        }
                      ],
                      "tf": 36271
                    }
                  ],
                  "pr": {
                    "bf": 30621,
                    "tf": 36271,
                    "tax": [
                      {
                        "OT": 5650
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "30621_36271",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "fhqs7v54r6",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 31321,
                      "fbc": "WCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "31321_37031",
                      "tax": [
                        {
                          "OT": 5710
                        }
                      ],
                      "tf": 37031
                    }
                  ],
                  "pr": {
                    "bf": 31321,
                    "tf": 37031,
                    "tax": [
                      {
                        "OT": 5710
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "31321_37031",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "52ji5iyt2v",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            },
            {
              "tid": "SVKle2cUBrbnETOs_2",
              "ref": 2,
              "ofcid": "",
              "sdur": "9:0",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DEL",
                  "dd": "2023-06-30T11:45:00",
                  "ad": "2023-06-30T14:05:00",
                  "rbd": "",
                  "fn": "2171",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "2"
                  },
                  "rph": "",
                  "jsell": "6E~2171~ ~~HYD~06/30/2023 11:45~DEL~06/30/2023 14:05~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                },
                {
                  "ref": 2,
                  "da": "DEL",
                  "ar": "DOH",
                  "dd": "2023-06-30T22:10:00",
                  "ad": "2023-06-30T23:45:00",
                  "rbd": "",
                  "fn": "1311",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "3"
                  },
                  "rph": "",
                  "jsell": "6E~1311~ ~~DEL~06/30/2023 22:10~DOH~06/30/2023 23:45~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 30621,
                      "fbc": "WCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "30621_36271",
                      "tax": [
                        {
                          "OT": 5650
                        }
                      ],
                      "tf": 36271
                    }
                  ],
                  "pr": {
                    "bf": 30621,
                    "tf": 36271,
                    "tax": [
                      {
                        "OT": 5650
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "30621_36271",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "onmy3b52ht",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 31321,
                      "fbc": "WCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "31321_37031",
                      "tax": [
                        {
                          "OT": 5710
                        }
                      ],
                      "tf": 37031
                    }
                  ],
                  "pr": {
                    "bf": 31321,
                    "tf": 37031,
                    "tax": [
                      {
                        "OT": 5710
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "31321_37031",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "qkyzb1av4v",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            },
            {
              "tid": "sTuJZuQk62nygy1b_2",
              "ref": 2,
              "ofcid": "",
              "sdur": "2:15",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "BOM",
                  "dd": "2023-06-30T13:30:00",
                  "ad": "2023-06-30T15:05:00",
                  "rbd": "",
                  "fn": "6699",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "2"
                  },
                  "rph": "",
                  "jsell": "6E~6699~ ~~HYD~06/30/2023 13:30~BOM~06/30/2023 15:05~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                },
                {
                  "ref": 2,
                  "da": "BOM",
                  "ar": "DOH",
                  "dd": "2023-06-30T17:50:00",
                  "ad": "2023-06-30T18:45:00",
                  "rbd": "",
                  "fn": "1305",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "2"
                  },
                  "rph": "",
                  "jsell": "6E~1305~ ~~BOM~06/30/2023 17:50~DOH~06/30/2023 18:45~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 30621,
                      "fbc": "WCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "30621_36271",
                      "tax": [
                        {
                          "OT": 5650
                        }
                      ],
                      "tf": 36271
                    }
                  ],
                  "pr": {
                    "bf": 30621,
                    "tf": 36271,
                    "tax": [
                      {
                        "OT": 5650
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "30621_36271",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "aspwzhohes",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 31321,
                      "fbc": "WCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "31321_37031",
                      "tax": [
                        {
                          "OT": 5710
                        }
                      ],
                      "tf": 37031
                    }
                  ],
                  "pr": {
                    "bf": 31321,
                    "tf": 37031,
                    "tax": [
                      {
                        "OT": 5710
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "31321_37031",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "w6fvz86x8c",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            },
            {
              "tid": "MJDcgxJSg1AQzg5T_2",
              "ref": 2,
              "ofcid": "",
              "sdur": "10:10",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DEL",
                  "dd": "2023-06-30T16:30:00",
                  "ad": "2023-06-30T18:50:00",
                  "rbd": "",
                  "fn": "6606",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "1"
                  },
                  "rph": "",
                  "jsell": "6E~6606~ ~~HYD~06/30/2023 16:30~DEL~06/30/2023 18:50~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                },
                {
                  "ref": 2,
                  "da": "DEL",
                  "ar": "DOH",
                  "dd": "2023-07-01T04:05:00",
                  "ad": "2023-07-01T05:40:00",
                  "rbd": "",
                  "fn": "1307",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "3"
                  },
                  "rph": "",
                  "jsell": "6E~1307~ ~~DEL~07/01/2023 04:05~DOH~07/01/2023 05:40~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 30621,
                      "fbc": "WCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "30621_36271",
                      "tax": [
                        {
                          "OT": 5650
                        }
                      ],
                      "tf": 36271
                    }
                  ],
                  "pr": {
                    "bf": 30621,
                    "tf": 36271,
                    "tax": [
                      {
                        "OT": 5650
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "30621_36271",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "qgaf52onpn",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 31321,
                      "fbc": "WCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "31321_37031",
                      "tax": [
                        {
                          "OT": 5710
                        }
                      ],
                      "tf": 37031
                    }
                  ],
                  "pr": {
                    "bf": 31321,
                    "tf": 37031,
                    "tax": [
                      {
                        "OT": 5710
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "31321_37031",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "erf0sd00qdj",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            },
            {
              "tid": "g8IyIvqXqoHQbkbK_2",
              "ref": 2,
              "ofcid": "",
              "sdur": "4:15",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DEL",
                  "dd": "2023-06-30T16:30:00",
                  "ad": "2023-06-30T18:50:00",
                  "rbd": "",
                  "fn": "6606",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "1"
                  },
                  "rph": "",
                  "jsell": "6E~6606~ ~~HYD~06/30/2023 16:30~DEL~06/30/2023 18:50~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                },
                {
                  "ref": 2,
                  "da": "DEL",
                  "ar": "DOH",
                  "dd": "2023-06-30T22:10:00",
                  "ad": "2023-06-30T23:45:00",
                  "rbd": "",
                  "fn": "1311",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "3"
                  },
                  "rph": "",
                  "jsell": "6E~1311~ ~~DEL~06/30/2023 22:10~DOH~06/30/2023 23:45~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 30621,
                      "fbc": "WCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "30621_36271",
                      "tax": [
                        {
                          "OT": 5650
                        }
                      ],
                      "tf": 36271
                    }
                  ],
                  "pr": {
                    "bf": 30621,
                    "tf": 36271,
                    "tax": [
                      {
                        "OT": 5650
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "30621_36271",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "lzcwuhv2ud",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 31321,
                      "fbc": "WCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "31321_37031",
                      "tax": [
                        {
                          "OT": 5710
                        }
                      ],
                      "tf": 37031
                    }
                  ],
                  "pr": {
                    "bf": 31321,
                    "tf": 37031,
                    "tax": [
                      {
                        "OT": 5710
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "31321_37031",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "h8d2eomxh7h",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            },
            {
              "tid": "wBm1VkgSZ1hihgxb_2",
              "ref": 2,
              "ofcid": "",
              "sdur": "8:25",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DEL",
                  "dd": "2023-06-30T18:15:00",
                  "ad": "2023-06-30T20:30:00",
                  "rbd": "",
                  "fn": "2003",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "2"
                  },
                  "rph": "",
                  "jsell": "6E~2003~ ~~HYD~06/30/2023 18:15~DEL~06/30/2023 20:30~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                },
                {
                  "ref": 2,
                  "da": "DEL",
                  "ar": "DOH",
                  "dd": "2023-07-01T04:05:00",
                  "ad": "2023-07-01T05:40:00",
                  "rbd": "",
                  "fn": "1307",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "3"
                  },
                  "rph": "",
                  "jsell": "6E~1307~ ~~DEL~07/01/2023 04:05~DOH~07/01/2023 05:40~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 30621,
                      "fbc": "WCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "30621_36271",
                      "tax": [
                        {
                          "OT": 5650
                        }
                      ],
                      "tf": 36271
                    }
                  ],
                  "pr": {
                    "bf": 30621,
                    "tf": 36271,
                    "tax": [
                      {
                        "OT": 5650
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "30621_36271",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "76frvkowh",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 31321,
                      "fbc": "WCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "31321_37031",
                      "tax": [
                        {
                          "OT": 5710
                        }
                      ],
                      "tf": 37031
                    }
                  ],
                  "pr": {
                    "bf": 31321,
                    "tf": 37031,
                    "tax": [
                      {
                        "OT": 5710
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "31321_37031",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "1wqz5bn99f",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            },
            {
              "tid": "2yv6y1QHDuIcDWJh_2",
              "ref": 2,
              "ofcid": "",
              "sdur": "5:55",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "BOM",
                  "dd": "2023-06-30T20:50:00",
                  "ad": "2023-06-30T22:05:00",
                  "rbd": "",
                  "fn": "5389",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "1"
                  },
                  "rph": "",
                  "jsell": "6E~5389~ ~~HYD~06/30/2023 20:50~BOM~06/30/2023 22:05~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                },
                {
                  "ref": 2,
                  "da": "BOM",
                  "ar": "DOH",
                  "dd": "2023-07-01T04:50:00",
                  "ad": "2023-07-01T05:45:00",
                  "rbd": "",
                  "fn": "1303",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "2"
                  },
                  "rph": "",
                  "jsell": "6E~1303~ ~~BOM~07/01/2023 04:50~DOH~07/01/2023 05:45~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 30621,
                      "fbc": "WCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "30621_36271",
                      "tax": [
                        {
                          "OT": 5650
                        }
                      ],
                      "tf": 36271
                    }
                  ],
                  "pr": {
                    "bf": 30621,
                    "tf": 36271,
                    "tax": [
                      {
                        "OT": 5650
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "30621_36271",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "ct0syelch1",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 31321,
                      "fbc": "WCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "31321_37031",
                      "tax": [
                        {
                          "OT": 5710
                        }
                      ],
                      "tf": 37031
                    }
                  ],
                  "pr": {
                    "bf": 31321,
                    "tf": 37031,
                    "tax": [
                      {
                        "OT": 5710
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "31321_37031",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "kyrfs3mfil",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            },
            {
              "tid": "KvH09FLALUF3fyco_2",
              "ref": 2,
              "ofcid": "",
              "sdur": "5:10",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "BOM",
                  "dd": "2023-06-30T21:35:00",
                  "ad": "2023-06-30T23:05:00",
                  "rbd": "",
                  "fn": "6952",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "2"
                  },
                  "rph": "",
                  "jsell": "6E~6952~ ~~HYD~06/30/2023 21:35~BOM~06/30/2023 23:05~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                },
                {
                  "ref": 2,
                  "da": "BOM",
                  "ar": "DOH",
                  "dd": "2023-07-01T04:50:00",
                  "ad": "2023-07-01T05:45:00",
                  "rbd": "",
                  "fn": "1303",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "2"
                  },
                  "rph": "",
                  "jsell": "6E~1303~ ~~BOM~07/01/2023 04:50~DOH~07/01/2023 05:45~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 30621,
                      "fbc": "WCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "30621_36271",
                      "tax": [
                        {
                          "OT": 5650
                        }
                      ],
                      "tf": 36271
                    }
                  ],
                  "pr": {
                    "bf": 30621,
                    "tf": 36271,
                    "tax": [
                      {
                        "OT": 5650
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "30621_36271",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "5ep5pfbwai",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 31321,
                      "fbc": "WCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "31321_37031",
                      "tax": [
                        {
                          "OT": 5710
                        }
                      ],
                      "tf": 37031
                    }
                  ],
                  "pr": {
                    "bf": 31321,
                    "tf": 37031,
                    "tax": [
                      {
                        "OT": 5710
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "31321_37031",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "xi84adfke3j",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            },
            {
              "tid": "PtPV6Lymbscodq3S_2",
              "ref": 2,
              "ofcid": "",
              "sdur": "4:45",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DEL",
                  "dd": "2023-06-30T21:55:00",
                  "ad": "2023-07-01T00:05:00",
                  "rbd": "",
                  "fn": "5605",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "3"
                  },
                  "rph": "",
                  "jsell": "6E~5605~ ~~HYD~06/30/2023 21:55~DEL~07/01/2023 00:05~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                },
                {
                  "ref": 2,
                  "da": "DEL",
                  "ar": "DOH",
                  "dd": "2023-07-01T04:05:00",
                  "ad": "2023-07-01T05:40:00",
                  "rbd": "",
                  "fn": "1307",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "3"
                  },
                  "rph": "",
                  "jsell": "6E~1307~ ~~DEL~07/01/2023 04:05~DOH~07/01/2023 05:40~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 30621,
                      "fbc": "WCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "30621_36271",
                      "tax": [
                        {
                          "OT": 5650
                        }
                      ],
                      "tf": 36271
                    }
                  ],
                  "pr": {
                    "bf": 30621,
                    "tf": 36271,
                    "tax": [
                      {
                        "OT": 5650
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "30621_36271",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "kczc7dp3hd",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 31321,
                      "fbc": "WCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "31321_37031",
                      "tax": [
                        {
                          "OT": 5710
                        }
                      ],
                      "tf": 37031
                    }
                  ],
                  "pr": {
                    "bf": 31321,
                    "tf": 37031,
                    "tax": [
                      {
                        "OT": 5710
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "31321_37031",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "ss1tjdebe5",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            },
            {
              "tid": "9HSLctLH0vF1cLvR_2",
              "ref": 2,
              "ofcid": "",
              "sdur": "4:5",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "BOM",
                  "dd": "2023-06-30T22:40:00",
                  "ad": "2023-07-01T00:15:00",
                  "rbd": "",
                  "fn": " 267",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "2"
                  },
                  "rph": "",
                  "jsell": "6E~ 267~ ~~HYD~06/30/2023 22:40~BOM~07/01/2023 00:15~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                },
                {
                  "ref": 2,
                  "da": "BOM",
                  "ar": "DOH",
                  "dd": "2023-07-01T04:50:00",
                  "ad": "2023-07-01T05:45:00",
                  "rbd": "",
                  "fn": "1303",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "2"
                  },
                  "rph": "",
                  "jsell": "6E~1303~ ~~BOM~07/01/2023 04:50~DOH~07/01/2023 05:45~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 30621,
                      "fbc": "WCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "30621_36271",
                      "tax": [
                        {
                          "OT": 5650
                        }
                      ],
                      "tf": 36271
                    }
                  ],
                  "pr": {
                    "bf": 30621,
                    "tf": 36271,
                    "tax": [
                      {
                        "OT": 5650
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "30621_36271",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "cy2txzrzpk",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 31321,
                      "fbc": "WCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "31321_37031",
                      "tax": [
                        {
                          "OT": 5710
                        }
                      ],
                      "tf": 37031
                    }
                  ],
                  "pr": {
                    "bf": 31321,
                    "tf": 37031,
                    "tax": [
                      {
                        "OT": 5710
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "31321_37031",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "aorpv02rro",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            },
            {
              "tid": "pRcDAIwqvT5RZBvW_2",
              "ref": 2,
              "ofcid": "",
              "sdur": "3:55",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DEL",
                  "dd": "2023-06-30T22:45:00",
                  "ad": "2023-07-01T01:00:00",
                  "rbd": "",
                  "fn": "6146",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "1"
                  },
                  "rph": "",
                  "jsell": "6E~6146~ ~~HYD~06/30/2023 22:45~DEL~07/01/2023 01:00~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                },
                {
                  "ref": 2,
                  "da": "DEL",
                  "ar": "DOH",
                  "dd": "2023-07-01T04:05:00",
                  "ad": "2023-07-01T05:40:00",
                  "rbd": "",
                  "fn": "1307",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "3"
                  },
                  "rph": "",
                  "jsell": "6E~1307~ ~~DEL~07/01/2023 04:05~DOH~07/01/2023 05:40~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 30621,
                      "fbc": "WCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "30621_36271",
                      "tax": [
                        {
                          "OT": 5650
                        }
                      ],
                      "tf": 36271
                    }
                  ],
                  "pr": {
                    "bf": 30621,
                    "tf": 36271,
                    "tax": [
                      {
                        "OT": 5650
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "30621_36271",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "761561uumf",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 31321,
                      "fbc": "WCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "31321_37031",
                      "tax": [
                        {
                          "OT": 5710
                        }
                      ],
                      "tf": 37031
                    }
                  ],
                  "pr": {
                    "bf": 31321,
                    "tf": 37031,
                    "tax": [
                      {
                        "OT": 5710
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "31321_37031",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "9yxi7wzmk5",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            }
          ],
          "paxpr": [
            {
              "bf": 40440,
              "tf": 49597,
              "fbc": "",
              "psup_fare": "",
              "tax": [
                [
                  {
                    "OT": 3507
                  }
                ],
                [
                  {
                    "OT": 5650
                  }
                ]
              ],
              "ptype": "ADT",
              "scur": "INR",
              "sup_cur_fare": ""
            }
          ],
          "pr": {
            "bf": 9819,
            "tf": 13326,
            "tax": [
              {
                "OT": 3507
              },
              {
                "OT": 5650
              }
            ],
            "scur": "INR",
            "sup_fare": "",
            "sup_cur_fare": "",
            "cabb": "",
            "chekb": "",
            "seats": "",
            "meals": "",
            "chanb": "",
            "ftyp": ""
          },
          "sup": "4",
          "airlineList": "6E"
        },
        {
          "srcorigns": [
            {
              "tid": "PfaBqU066BzaILTt_1",
              "ref": 1,
              "ofcid": "",
              "sdur": "23:0",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "DOH",
                  "ar": "DEL",
                  "dd": "2023-06-29T02:00:00",
                  "ad": "2023-06-29T08:20:00",
                  "rbd": "",
                  "fn": "1312",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "3"
                  },
                  "rph": "",
                  "jsell": "6E~1312~ ~~DOH~06/29/2023 02:00~DEL~06/29/2023 08:20~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                },
                {
                  "ref": 2,
                  "da": "DEL",
                  "ar": "HYD",
                  "dd": "2023-06-29T19:50:00",
                  "ad": "2023-06-29T22:00:00",
                  "rbd": "",
                  "fn": " 605",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "1"
                  },
                  "rph": "",
                  "jsell": "6E~ 605~ ~~DEL~06/29/2023 19:50~HYD~06/29/2023 22:00~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~Q~ ~6E~QCINT~6200~~0~65~~X^0~Q~ ~6E~QCINT~6200~~0~65~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 10665,
                      "fbc": "QCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "10665_14201",
                      "tax": [
                        {
                          "OT": 3536
                        }
                      ],
                      "tf": 14201
                    }
                  ],
                  "pr": {
                    "bf": 10665,
                    "tf": 14201,
                    "tax": [
                      {
                        "OT": 3536
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "10665_14201",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "an2eyv782o",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~Q~ ~6E~QCUINT~6202~~0~5~~X^0~Q~ ~6E~QCUINT~6202~~0~5~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 11365,
                      "fbc": "QCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "11365_14926",
                      "tax": [
                        {
                          "OT": 3561
                        }
                      ],
                      "tf": 14926
                    }
                  ],
                  "pr": {
                    "bf": 11365,
                    "tf": 14926,
                    "tax": [
                      {
                        "OT": 3561
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "11365_14926",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "iwq4alv3gx",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            }
          ],
          "destorigins": [
            {
              "tid": "CgUeAQFe6GfQbWmq_2",
              "ref": 2,
              "ofcid": "",
              "sdur": "6:30",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "MAA",
                  "dd": "2023-06-30T14:05:00",
                  "ad": "2023-06-30T15:20:00",
                  "rbd": "",
                  "fn": " 514",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "1"
                  },
                  "rph": "",
                  "jsell": "6E~ 514~ ~~HYD~06/30/2023 14:05~MAA~06/30/2023 15:20~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                },
                {
                  "ref": 2,
                  "da": "MAA",
                  "ar": "DOH",
                  "dd": "2023-06-30T21:30:00",
                  "ad": "2023-06-30T23:35:00",
                  "rbd": "",
                  "fn": "1321",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "4"
                  },
                  "rph": "",
                  "jsell": "6E~1321~ ~~MAA~06/30/2023 21:30~DOH~06/30/2023 23:35~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~R~ ~6E~RCINT~6200~~0~66~~X^0~R~ ~6E~RCINT~6200~~0~66~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 8036,
                      "fbc": "RCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "8036_11726",
                      "tax": [
                        {
                          "OT": 3690
                        }
                      ],
                      "tf": 11726
                    }
                  ],
                  "pr": {
                    "bf": 8036,
                    "tf": 11726,
                    "tax": [
                      {
                        "OT": 3690
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "8036_11726",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "sjokl93m7fh",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~R~ ~6E~RCUINT~6202~~0~5~~X^0~R~ ~6E~RCUINT~6202~~0~5~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 8736,
                      "fbc": "RCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "8736_12487",
                      "tax": [
                        {
                          "OT": 3751
                        }
                      ],
                      "tf": 12487
                    }
                  ],
                  "pr": {
                    "bf": 8736,
                    "tf": 12487,
                    "tax": [
                      {
                        "OT": 3751
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "8736_12487",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "0y97sxl9th",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            },
            {
              "tid": "IqeewhTKTUxVwPUF_2",
              "ref": 2,
              "ofcid": "",
              "sdur": "4:0",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "MAA",
                  "dd": "2023-06-30T16:35:00",
                  "ad": "2023-06-30T17:45:00",
                  "rbd": "",
                  "fn": " 668",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "1"
                  },
                  "rph": "",
                  "jsell": "6E~ 668~ ~~HYD~06/30/2023 16:35~MAA~06/30/2023 17:45~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                },
                {
                  "ref": 2,
                  "da": "MAA",
                  "ar": "DOH",
                  "dd": "2023-06-30T21:30:00",
                  "ad": "2023-06-30T23:35:00",
                  "rbd": "",
                  "fn": "1321",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "4"
                  },
                  "rph": "",
                  "jsell": "6E~1321~ ~~MAA~06/30/2023 21:30~DOH~06/30/2023 23:35~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~R~ ~6E~RCINT~6200~~0~66~~X^0~R~ ~6E~RCINT~6200~~0~66~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 8036,
                      "fbc": "RCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "8036_11726",
                      "tax": [
                        {
                          "OT": 3690
                        }
                      ],
                      "tf": 11726
                    }
                  ],
                  "pr": {
                    "bf": 8036,
                    "tf": 11726,
                    "tax": [
                      {
                        "OT": 3690
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "8036_11726",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "75fa2ueka9",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~R~ ~6E~RCUINT~6202~~0~5~~X^0~R~ ~6E~RCUINT~6202~~0~5~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 8736,
                      "fbc": "RCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "8736_12487",
                      "tax": [
                        {
                          "OT": 3751
                        }
                      ],
                      "tf": 12487
                    }
                  ],
                  "pr": {
                    "bf": 8736,
                    "tf": 12487,
                    "tax": [
                      {
                        "OT": 3751
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "8736_12487",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "2yberahl09",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            },
            {
              "tid": "JRKQ0SzAL4FpTtwi_2",
              "ref": 2,
              "ofcid": "",
              "sdur": "2:55",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "MAA",
                  "dd": "2023-06-30T17:40:00",
                  "ad": "2023-06-30T18:55:00",
                  "rbd": "",
                  "fn": " 102",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "1"
                  },
                  "rph": "",
                  "jsell": "6E~ 102~ ~~HYD~06/30/2023 17:40~MAA~06/30/2023 18:55~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                },
                {
                  "ref": 2,
                  "da": "MAA",
                  "ar": "DOH",
                  "dd": "2023-06-30T21:30:00",
                  "ad": "2023-06-30T23:35:00",
                  "rbd": "",
                  "fn": "1321",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "4"
                  },
                  "rph": "",
                  "jsell": "6E~1321~ ~~MAA~06/30/2023 21:30~DOH~06/30/2023 23:35~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~R~ ~6E~RCINT~6200~~0~66~~X^0~R~ ~6E~RCINT~6200~~0~66~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 8036,
                      "fbc": "RCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "8036_11726",
                      "tax": [
                        {
                          "OT": 3690
                        }
                      ],
                      "tf": 11726
                    }
                  ],
                  "pr": {
                    "bf": 8036,
                    "tf": 11726,
                    "tax": [
                      {
                        "OT": 3690
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "8036_11726",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "ytvt5ey88b",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~R~ ~6E~RCUINT~6202~~0~5~~X^0~R~ ~6E~RCUINT~6202~~0~5~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 8736,
                      "fbc": "RCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "8736_12487",
                      "tax": [
                        {
                          "OT": 3751
                        }
                      ],
                      "tf": 12487
                    }
                  ],
                  "pr": {
                    "bf": 8736,
                    "tf": 12487,
                    "tax": [
                      {
                        "OT": 3751
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "8736_12487",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "32qio67ha9f",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            }
          ],
          "paxpr": [
            {
              "bf": 18701,
              "tf": 25927,
              "fbc": "",
              "psup_fare": "",
              "tax": [
                [
                  {
                    "OT": 3536
                  }
                ],
                [
                  {
                    "OT": 3690
                  }
                ]
              ],
              "ptype": "ADT",
              "scur": "INR",
              "sup_cur_fare": ""
            }
          ],
          "pr": {
            "bf": 10665,
            "tf": 14201,
            "tax": [
              {
                "OT": 3536
              },
              {
                "OT": 3690
              }
            ],
            "scur": "INR",
            "sup_fare": "",
            "sup_cur_fare": "",
            "cabb": "",
            "chekb": "",
            "seats": "",
            "meals": "",
            "chanb": "",
            "ftyp": ""
          },
          "sup": "4",
          "airlineList": "6E"
        },
        {
          "srcorigns": [
            {
              "tid": "BVXg22ArEd57HnNt_1",
              "ref": 1,
              "ofcid": "",
              "sdur": "23:0",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "DOH",
                  "ar": "DEL",
                  "dd": "2023-06-29T02:00:00",
                  "ad": "2023-06-29T08:20:00",
                  "rbd": "",
                  "fn": "1312",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "3"
                  },
                  "rph": "",
                  "jsell": "6E~1312~ ~~DOH~06/29/2023 02:00~DEL~06/29/2023 08:20~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                },
                {
                  "ref": 2,
                  "da": "DEL",
                  "ar": "HYD",
                  "dd": "2023-06-29T19:50:00",
                  "ad": "2023-06-29T22:00:00",
                  "rbd": "",
                  "fn": " 605",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "1"
                  },
                  "rph": "",
                  "jsell": "6E~ 605~ ~~DEL~06/29/2023 19:50~HYD~06/29/2023 22:00~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~Q~ ~6E~QCINT~6200~~0~65~~X^0~Q~ ~6E~QCINT~6200~~0~65~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 10665,
                      "fbc": "QCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "10665_14201",
                      "tax": [
                        {
                          "OT": 3536
                        }
                      ],
                      "tf": 14201
                    }
                  ],
                  "pr": {
                    "bf": 10665,
                    "tf": 14201,
                    "tax": [
                      {
                        "OT": 3536
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "10665_14201",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "an2eyv782o",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~Q~ ~6E~QCUINT~6202~~0~5~~X^0~Q~ ~6E~QCUINT~6202~~0~5~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 11365,
                      "fbc": "QCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "11365_14926",
                      "tax": [
                        {
                          "OT": 3561
                        }
                      ],
                      "tf": 14926
                    }
                  ],
                  "pr": {
                    "bf": 11365,
                    "tf": 14926,
                    "tax": [
                      {
                        "OT": 3561
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "11365_14926",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "iwq4alv3gx",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            }
          ],
          "destorigins": [
            {
              "tid": "ZvCQNX4Fb0lvmxLf_2",
              "ref": 2,
              "ofcid": "",
              "sdur": "0:-80",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DOH",
                  "dd": "2023-06-30T04:10:00",
                  "ad": "2023-06-30T05:50:00",
                  "rbd": "",
                  "fn": "1313",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {},
                  "rph": "",
                  "jsell": "6E~1313~ ~~HYD~06/30/2023 04:10~DOH~06/30/2023 05:50~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~A~ ~6E~A0INT~5000~~0~2~~X",
                  "pr": {
                    "bf": 26311,
                    "tf": 31534,
                    "tax": [
                      {
                        "OT": 5223
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "26311_31534",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "paxpr": [
                    {
                      "bf": 26311,
                      "tf": 31534,
                      "tax": [
                        {
                          "OT": 5223
                        }
                      ],
                      "fbc": "A0INT",
                      "ptype": "ADT",
                      "scur": "INR",
                      "sup_fare": "26311_31534"
                    }
                  ],
                  "routeId": "gdgt7repejj",
                  "productClass": "R",
                  "ruleNumber": "5000"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 0
            },
            {
              "tid": "OKwqxtxUyRfizGGX_2",
              "ref": 2,
              "ofcid": "",
              "sdur": "0:-80",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DOH",
                  "dd": "2023-06-30T17:05:00",
                  "ad": "2023-06-30T18:45:00",
                  "rbd": "",
                  "fn": "1315",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {},
                  "rph": "",
                  "jsell": "6E~1315~ ~~HYD~06/30/2023 17:05~DOH~06/30/2023 18:45~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~A~ ~6E~A0INT~5000~~0~2~~X",
                  "pr": {
                    "bf": 26311,
                    "tf": 31534,
                    "tax": [
                      {
                        "OT": 5223
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "26311_31534",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "paxpr": [
                    {
                      "bf": 26311,
                      "tf": 31534,
                      "tax": [
                        {
                          "OT": 5223
                        }
                      ],
                      "fbc": "A0INT",
                      "ptype": "ADT",
                      "scur": "INR",
                      "sup_fare": "26311_31534"
                    }
                  ],
                  "routeId": "0wmqjl8frn",
                  "productClass": "R",
                  "ruleNumber": "5000"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 0
            },
            {
              "tid": "xBcluRSPuccxuEwl_2",
              "ref": 2,
              "ofcid": "",
              "sdur": "0:-80",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DOH",
                  "dd": "2023-06-30T21:55:00",
                  "ad": "2023-06-30T23:35:00",
                  "rbd": "",
                  "fn": "1317",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {},
                  "rph": "",
                  "jsell": "6E~1317~ ~~HYD~06/30/2023 21:55~DOH~06/30/2023 23:35~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~A~ ~6E~A0INT~5000~~0~2~~X",
                  "pr": {
                    "bf": 26311,
                    "tf": 31534,
                    "tax": [
                      {
                        "OT": 5223
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "26311_31534",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "paxpr": [
                    {
                      "bf": 26311,
                      "tf": 31534,
                      "tax": [
                        {
                          "OT": 5223
                        }
                      ],
                      "fbc": "A0INT",
                      "ptype": "ADT",
                      "scur": "INR",
                      "sup_fare": "26311_31534"
                    }
                  ],
                  "routeId": "hg9grdzg6g",
                  "productClass": "R",
                  "ruleNumber": "5000"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 0
            }
          ],
          "paxpr": [
            {
              "bf": 36976,
              "tf": 45735,
              "fbc": "",
              "psup_fare": "",
              "tax": [
                [
                  {
                    "OT": 3536
                  }
                ],
                [
                  {
                    "OT": 5223
                  }
                ]
              ],
              "ptype": "ADT",
              "scur": "INR",
              "sup_cur_fare": ""
            }
          ],
          "pr": {
            "bf": 10665,
            "tf": 14201,
            "tax": [
              {
                "OT": 3536
              },
              {
                "OT": 5223
              }
            ],
            "scur": "INR",
            "sup_fare": "",
            "sup_cur_fare": "",
            "cabb": "",
            "chekb": "",
            "seats": "",
            "meals": "",
            "chanb": "",
            "ftyp": ""
          },
          "sup": "4",
          "airlineList": "6E"
        },
        {
          "srcorigns": [
            {
              "tid": "tnmndgWyk1K3RFpb_1",
              "ref": 1,
              "ofcid": "",
              "sdur": "23:0",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "DOH",
                  "ar": "DEL",
                  "dd": "2023-06-29T02:00:00",
                  "ad": "2023-06-29T08:20:00",
                  "rbd": "",
                  "fn": "1312",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "3"
                  },
                  "rph": "",
                  "jsell": "6E~1312~ ~~DOH~06/29/2023 02:00~DEL~06/29/2023 08:20~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                },
                {
                  "ref": 2,
                  "da": "DEL",
                  "ar": "HYD",
                  "dd": "2023-06-29T19:50:00",
                  "ad": "2023-06-29T22:00:00",
                  "rbd": "",
                  "fn": " 605",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "1"
                  },
                  "rph": "",
                  "jsell": "6E~ 605~ ~~DEL~06/29/2023 19:50~HYD~06/29/2023 22:00~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~Q~ ~6E~QCINT~6200~~0~65~~X^0~Q~ ~6E~QCINT~6200~~0~65~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 10665,
                      "fbc": "QCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "10665_14201",
                      "tax": [
                        {
                          "OT": 3536
                        }
                      ],
                      "tf": 14201
                    }
                  ],
                  "pr": {
                    "bf": 10665,
                    "tf": 14201,
                    "tax": [
                      {
                        "OT": 3536
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "10665_14201",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "an2eyv782o",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~Q~ ~6E~QCUINT~6202~~0~5~~X^0~Q~ ~6E~QCUINT~6202~~0~5~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 11365,
                      "fbc": "QCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "11365_14926",
                      "tax": [
                        {
                          "OT": 3561
                        }
                      ],
                      "tf": 14926
                    }
                  ],
                  "pr": {
                    "bf": 11365,
                    "tf": 14926,
                    "tax": [
                      {
                        "OT": 3561
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "11365_14926",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "iwq4alv3gx",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            }
          ],
          "destorigins": [
            {
              "tid": "7xAnbG8hObJdOBTV_2",
              "ref": 2,
              "ofcid": "",
              "sdur": "9:0",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "BOM",
                  "dd": "2023-06-30T06:45:00",
                  "ad": "2023-06-30T08:05:00",
                  "rbd": "",
                  "fn": " 286",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "2"
                  },
                  "rph": "",
                  "jsell": "6E~ 286~ ~~HYD~06/30/2023 06:45~BOM~06/30/2023 08:05~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                },
                {
                  "ref": 2,
                  "da": "BOM",
                  "ar": "DOH",
                  "dd": "2023-06-30T17:50:00",
                  "ad": "2023-06-30T18:45:00",
                  "rbd": "",
                  "fn": "1305",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "2"
                  },
                  "rph": "",
                  "jsell": "6E~1305~ ~~BOM~06/30/2023 17:50~DOH~06/30/2023 18:45~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 30621,
                      "fbc": "WCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "30621_36271",
                      "tax": [
                        {
                          "OT": 5650
                        }
                      ],
                      "tf": 36271
                    }
                  ],
                  "pr": {
                    "bf": 30621,
                    "tf": 36271,
                    "tax": [
                      {
                        "OT": 5650
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "30621_36271",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "mzvs3yl9om",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 31321,
                      "fbc": "WCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "31321_37031",
                      "tax": [
                        {
                          "OT": 5710
                        }
                      ],
                      "tf": 37031
                    }
                  ],
                  "pr": {
                    "bf": 31321,
                    "tf": 37031,
                    "tax": [
                      {
                        "OT": 5710
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "31321_37031",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "ony30wqs4m",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            },
            {
              "tid": "40NrIA4zJodZCobv_2",
              "ref": 2,
              "ofcid": "",
              "sdur": "8:10",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "BOM",
                  "dd": "2023-06-30T07:35:00",
                  "ad": "2023-06-30T09:10:00",
                  "rbd": "",
                  "fn": " 163",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "2"
                  },
                  "rph": "",
                  "jsell": "6E~ 163~ ~~HYD~06/30/2023 07:35~BOM~06/30/2023 09:10~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                },
                {
                  "ref": 2,
                  "da": "BOM",
                  "ar": "DOH",
                  "dd": "2023-06-30T17:50:00",
                  "ad": "2023-06-30T18:45:00",
                  "rbd": "",
                  "fn": "1305",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "2"
                  },
                  "rph": "",
                  "jsell": "6E~1305~ ~~BOM~06/30/2023 17:50~DOH~06/30/2023 18:45~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 30621,
                      "fbc": "WCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "30621_36271",
                      "tax": [
                        {
                          "OT": 5650
                        }
                      ],
                      "tf": 36271
                    }
                  ],
                  "pr": {
                    "bf": 30621,
                    "tf": 36271,
                    "tax": [
                      {
                        "OT": 5650
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "30621_36271",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "12wvz6ndho",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 31321,
                      "fbc": "WCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "31321_37031",
                      "tax": [
                        {
                          "OT": 5710
                        }
                      ],
                      "tf": 37031
                    }
                  ],
                  "pr": {
                    "bf": 31321,
                    "tf": 37031,
                    "tax": [
                      {
                        "OT": 5710
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "31321_37031",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "vpusru4veh",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            },
            {
              "tid": "KHNw8JTQRLeOSeOH_2",
              "ref": 2,
              "ofcid": "",
              "sdur": "11:15",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DEL",
                  "dd": "2023-06-30T09:30:00",
                  "ad": "2023-06-30T11:50:00",
                  "rbd": "",
                  "fn": "6204",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "1"
                  },
                  "rph": "",
                  "jsell": "6E~6204~ ~~HYD~06/30/2023 09:30~DEL~06/30/2023 11:50~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                },
                {
                  "ref": 2,
                  "da": "DEL",
                  "ar": "DOH",
                  "dd": "2023-06-30T22:10:00",
                  "ad": "2023-06-30T23:45:00",
                  "rbd": "",
                  "fn": "1311",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "3"
                  },
                  "rph": "",
                  "jsell": "6E~1311~ ~~DEL~06/30/2023 22:10~DOH~06/30/2023 23:45~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 30621,
                      "fbc": "WCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "30621_36271",
                      "tax": [
                        {
                          "OT": 5650
                        }
                      ],
                      "tf": 36271
                    }
                  ],
                  "pr": {
                    "bf": 30621,
                    "tf": 36271,
                    "tax": [
                      {
                        "OT": 5650
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "30621_36271",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "f7bv5vhc8j",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 31321,
                      "fbc": "WCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "31321_37031",
                      "tax": [
                        {
                          "OT": 5710
                        }
                      ],
                      "tf": 37031
                    }
                  ],
                  "pr": {
                    "bf": 31321,
                    "tf": 37031,
                    "tax": [
                      {
                        "OT": 5710
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "31321_37031",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "674d1nivvj",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            },
            {
              "tid": "6ghKlSAMZA5HTrkV_2",
              "ref": 2,
              "ofcid": "",
              "sdur": "4:40",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "BOM",
                  "dd": "2023-06-30T11:05:00",
                  "ad": "2023-06-30T12:40:00",
                  "rbd": "",
                  "fn": "5384",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "1"
                  },
                  "rph": "",
                  "jsell": "6E~5384~ ~~HYD~06/30/2023 11:05~BOM~06/30/2023 12:40~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                },
                {
                  "ref": 2,
                  "da": "BOM",
                  "ar": "DOH",
                  "dd": "2023-06-30T17:50:00",
                  "ad": "2023-06-30T18:45:00",
                  "rbd": "",
                  "fn": "1305",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "2"
                  },
                  "rph": "",
                  "jsell": "6E~1305~ ~~BOM~06/30/2023 17:50~DOH~06/30/2023 18:45~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 30621,
                      "fbc": "WCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "30621_36271",
                      "tax": [
                        {
                          "OT": 5650
                        }
                      ],
                      "tf": 36271
                    }
                  ],
                  "pr": {
                    "bf": 30621,
                    "tf": 36271,
                    "tax": [
                      {
                        "OT": 5650
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "30621_36271",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "ocgb51h1iw",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 31321,
                      "fbc": "WCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "31321_37031",
                      "tax": [
                        {
                          "OT": 5710
                        }
                      ],
                      "tf": 37031
                    }
                  ],
                  "pr": {
                    "bf": 31321,
                    "tf": 37031,
                    "tax": [
                      {
                        "OT": 5710
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "31321_37031",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "l99rswgdhe",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            },
            {
              "tid": "rt7OifGfvF2eg6gh_2",
              "ref": 2,
              "ofcid": "",
              "sdur": "9:0",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DEL",
                  "dd": "2023-06-30T11:45:00",
                  "ad": "2023-06-30T14:05:00",
                  "rbd": "",
                  "fn": "2171",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "2"
                  },
                  "rph": "",
                  "jsell": "6E~2171~ ~~HYD~06/30/2023 11:45~DEL~06/30/2023 14:05~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                },
                {
                  "ref": 2,
                  "da": "DEL",
                  "ar": "DOH",
                  "dd": "2023-06-30T22:10:00",
                  "ad": "2023-06-30T23:45:00",
                  "rbd": "",
                  "fn": "1311",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "3"
                  },
                  "rph": "",
                  "jsell": "6E~1311~ ~~DEL~06/30/2023 22:10~DOH~06/30/2023 23:45~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 30621,
                      "fbc": "WCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "30621_36271",
                      "tax": [
                        {
                          "OT": 5650
                        }
                      ],
                      "tf": 36271
                    }
                  ],
                  "pr": {
                    "bf": 30621,
                    "tf": 36271,
                    "tax": [
                      {
                        "OT": 5650
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "30621_36271",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "3sagiha79bg",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 31321,
                      "fbc": "WCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "31321_37031",
                      "tax": [
                        {
                          "OT": 5710
                        }
                      ],
                      "tf": 37031
                    }
                  ],
                  "pr": {
                    "bf": 31321,
                    "tf": 37031,
                    "tax": [
                      {
                        "OT": 5710
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "31321_37031",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "zo9nw0r4mz",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            },
            {
              "tid": "VdAzoM5s2DTZs4Ck_2",
              "ref": 2,
              "ofcid": "",
              "sdur": "2:15",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "BOM",
                  "dd": "2023-06-30T13:30:00",
                  "ad": "2023-06-30T15:05:00",
                  "rbd": "",
                  "fn": "6699",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "2"
                  },
                  "rph": "",
                  "jsell": "6E~6699~ ~~HYD~06/30/2023 13:30~BOM~06/30/2023 15:05~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                },
                {
                  "ref": 2,
                  "da": "BOM",
                  "ar": "DOH",
                  "dd": "2023-06-30T17:50:00",
                  "ad": "2023-06-30T18:45:00",
                  "rbd": "",
                  "fn": "1305",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "2"
                  },
                  "rph": "",
                  "jsell": "6E~1305~ ~~BOM~06/30/2023 17:50~DOH~06/30/2023 18:45~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 30621,
                      "fbc": "WCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "30621_36271",
                      "tax": [
                        {
                          "OT": 5650
                        }
                      ],
                      "tf": 36271
                    }
                  ],
                  "pr": {
                    "bf": 30621,
                    "tf": 36271,
                    "tax": [
                      {
                        "OT": 5650
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "30621_36271",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "l5soa0i05",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 31321,
                      "fbc": "WCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "31321_37031",
                      "tax": [
                        {
                          "OT": 5710
                        }
                      ],
                      "tf": 37031
                    }
                  ],
                  "pr": {
                    "bf": 31321,
                    "tf": 37031,
                    "tax": [
                      {
                        "OT": 5710
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "31321_37031",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "s5q56ii7be",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            },
            {
              "tid": "dR3EfdFDxgcer7GV_2",
              "ref": 2,
              "ofcid": "",
              "sdur": "10:10",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DEL",
                  "dd": "2023-06-30T16:30:00",
                  "ad": "2023-06-30T18:50:00",
                  "rbd": "",
                  "fn": "6606",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "1"
                  },
                  "rph": "",
                  "jsell": "6E~6606~ ~~HYD~06/30/2023 16:30~DEL~06/30/2023 18:50~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                },
                {
                  "ref": 2,
                  "da": "DEL",
                  "ar": "DOH",
                  "dd": "2023-07-01T04:05:00",
                  "ad": "2023-07-01T05:40:00",
                  "rbd": "",
                  "fn": "1307",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "3"
                  },
                  "rph": "",
                  "jsell": "6E~1307~ ~~DEL~07/01/2023 04:05~DOH~07/01/2023 05:40~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 30621,
                      "fbc": "WCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "30621_36271",
                      "tax": [
                        {
                          "OT": 5650
                        }
                      ],
                      "tf": 36271
                    }
                  ],
                  "pr": {
                    "bf": 30621,
                    "tf": 36271,
                    "tax": [
                      {
                        "OT": 5650
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "30621_36271",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "r2re88hemmi",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 31321,
                      "fbc": "WCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "31321_37031",
                      "tax": [
                        {
                          "OT": 5710
                        }
                      ],
                      "tf": 37031
                    }
                  ],
                  "pr": {
                    "bf": 31321,
                    "tf": 37031,
                    "tax": [
                      {
                        "OT": 5710
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "31321_37031",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "zfdryxn11w",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            },
            {
              "tid": "F4hPd6R3feZO8DvP_2",
              "ref": 2,
              "ofcid": "",
              "sdur": "4:15",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DEL",
                  "dd": "2023-06-30T16:30:00",
                  "ad": "2023-06-30T18:50:00",
                  "rbd": "",
                  "fn": "6606",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "1"
                  },
                  "rph": "",
                  "jsell": "6E~6606~ ~~HYD~06/30/2023 16:30~DEL~06/30/2023 18:50~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                },
                {
                  "ref": 2,
                  "da": "DEL",
                  "ar": "DOH",
                  "dd": "2023-06-30T22:10:00",
                  "ad": "2023-06-30T23:45:00",
                  "rbd": "",
                  "fn": "1311",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "3"
                  },
                  "rph": "",
                  "jsell": "6E~1311~ ~~DEL~06/30/2023 22:10~DOH~06/30/2023 23:45~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 30621,
                      "fbc": "WCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "30621_36271",
                      "tax": [
                        {
                          "OT": 5650
                        }
                      ],
                      "tf": 36271
                    }
                  ],
                  "pr": {
                    "bf": 30621,
                    "tf": 36271,
                    "tax": [
                      {
                        "OT": 5650
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "30621_36271",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "7693aqou09k",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 31321,
                      "fbc": "WCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "31321_37031",
                      "tax": [
                        {
                          "OT": 5710
                        }
                      ],
                      "tf": 37031
                    }
                  ],
                  "pr": {
                    "bf": 31321,
                    "tf": 37031,
                    "tax": [
                      {
                        "OT": 5710
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "31321_37031",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "pmr2s1hlew",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            },
            {
              "tid": "IiD1cakf13sGQ9Hy_2",
              "ref": 2,
              "ofcid": "",
              "sdur": "8:25",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DEL",
                  "dd": "2023-06-30T18:15:00",
                  "ad": "2023-06-30T20:30:00",
                  "rbd": "",
                  "fn": "2003",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "2"
                  },
                  "rph": "",
                  "jsell": "6E~2003~ ~~HYD~06/30/2023 18:15~DEL~06/30/2023 20:30~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                },
                {
                  "ref": 2,
                  "da": "DEL",
                  "ar": "DOH",
                  "dd": "2023-07-01T04:05:00",
                  "ad": "2023-07-01T05:40:00",
                  "rbd": "",
                  "fn": "1307",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "3"
                  },
                  "rph": "",
                  "jsell": "6E~1307~ ~~DEL~07/01/2023 04:05~DOH~07/01/2023 05:40~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 30621,
                      "fbc": "WCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "30621_36271",
                      "tax": [
                        {
                          "OT": 5650
                        }
                      ],
                      "tf": 36271
                    }
                  ],
                  "pr": {
                    "bf": 30621,
                    "tf": 36271,
                    "tax": [
                      {
                        "OT": 5650
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "30621_36271",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "r6m9a9816t",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 31321,
                      "fbc": "WCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "31321_37031",
                      "tax": [
                        {
                          "OT": 5710
                        }
                      ],
                      "tf": 37031
                    }
                  ],
                  "pr": {
                    "bf": 31321,
                    "tf": 37031,
                    "tax": [
                      {
                        "OT": 5710
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "31321_37031",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "oumt8c4f1j",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            },
            {
              "tid": "TT8vLTb2FuKoi0B4_2",
              "ref": 2,
              "ofcid": "",
              "sdur": "5:55",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "BOM",
                  "dd": "2023-06-30T20:50:00",
                  "ad": "2023-06-30T22:05:00",
                  "rbd": "",
                  "fn": "5389",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "1"
                  },
                  "rph": "",
                  "jsell": "6E~5389~ ~~HYD~06/30/2023 20:50~BOM~06/30/2023 22:05~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                },
                {
                  "ref": 2,
                  "da": "BOM",
                  "ar": "DOH",
                  "dd": "2023-07-01T04:50:00",
                  "ad": "2023-07-01T05:45:00",
                  "rbd": "",
                  "fn": "1303",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "2"
                  },
                  "rph": "",
                  "jsell": "6E~1303~ ~~BOM~07/01/2023 04:50~DOH~07/01/2023 05:45~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 30621,
                      "fbc": "WCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "30621_36271",
                      "tax": [
                        {
                          "OT": 5650
                        }
                      ],
                      "tf": 36271
                    }
                  ],
                  "pr": {
                    "bf": 30621,
                    "tf": 36271,
                    "tax": [
                      {
                        "OT": 5650
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "30621_36271",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "rt95xj33iwh",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 31321,
                      "fbc": "WCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "31321_37031",
                      "tax": [
                        {
                          "OT": 5710
                        }
                      ],
                      "tf": 37031
                    }
                  ],
                  "pr": {
                    "bf": 31321,
                    "tf": 37031,
                    "tax": [
                      {
                        "OT": 5710
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "31321_37031",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "fq1o4nd93b",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            },
            {
              "tid": "rMuEciCaPoReuFZp_2",
              "ref": 2,
              "ofcid": "",
              "sdur": "5:10",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "BOM",
                  "dd": "2023-06-30T21:35:00",
                  "ad": "2023-06-30T23:05:00",
                  "rbd": "",
                  "fn": "6952",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "2"
                  },
                  "rph": "",
                  "jsell": "6E~6952~ ~~HYD~06/30/2023 21:35~BOM~06/30/2023 23:05~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                },
                {
                  "ref": 2,
                  "da": "BOM",
                  "ar": "DOH",
                  "dd": "2023-07-01T04:50:00",
                  "ad": "2023-07-01T05:45:00",
                  "rbd": "",
                  "fn": "1303",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "2"
                  },
                  "rph": "",
                  "jsell": "6E~1303~ ~~BOM~07/01/2023 04:50~DOH~07/01/2023 05:45~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 30621,
                      "fbc": "WCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "30621_36271",
                      "tax": [
                        {
                          "OT": 5650
                        }
                      ],
                      "tf": 36271
                    }
                  ],
                  "pr": {
                    "bf": 30621,
                    "tf": 36271,
                    "tax": [
                      {
                        "OT": 5650
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "30621_36271",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "ombb6mwet5",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 31321,
                      "fbc": "WCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "31321_37031",
                      "tax": [
                        {
                          "OT": 5710
                        }
                      ],
                      "tf": 37031
                    }
                  ],
                  "pr": {
                    "bf": 31321,
                    "tf": 37031,
                    "tax": [
                      {
                        "OT": 5710
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "31321_37031",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "69dwstpyfp",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            },
            {
              "tid": "Xt9PmLeugXkbkPos_2",
              "ref": 2,
              "ofcid": "",
              "sdur": "4:45",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DEL",
                  "dd": "2023-06-30T21:55:00",
                  "ad": "2023-07-01T00:05:00",
                  "rbd": "",
                  "fn": "5605",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "3"
                  },
                  "rph": "",
                  "jsell": "6E~5605~ ~~HYD~06/30/2023 21:55~DEL~07/01/2023 00:05~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                },
                {
                  "ref": 2,
                  "da": "DEL",
                  "ar": "DOH",
                  "dd": "2023-07-01T04:05:00",
                  "ad": "2023-07-01T05:40:00",
                  "rbd": "",
                  "fn": "1307",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "3"
                  },
                  "rph": "",
                  "jsell": "6E~1307~ ~~DEL~07/01/2023 04:05~DOH~07/01/2023 05:40~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 30621,
                      "fbc": "WCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "30621_36271",
                      "tax": [
                        {
                          "OT": 5650
                        }
                      ],
                      "tf": 36271
                    }
                  ],
                  "pr": {
                    "bf": 30621,
                    "tf": 36271,
                    "tax": [
                      {
                        "OT": 5650
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "30621_36271",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "vhjbfn0a31",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 31321,
                      "fbc": "WCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "31321_37031",
                      "tax": [
                        {
                          "OT": 5710
                        }
                      ],
                      "tf": 37031
                    }
                  ],
                  "pr": {
                    "bf": 31321,
                    "tf": 37031,
                    "tax": [
                      {
                        "OT": 5710
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "31321_37031",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "i1ymt3oceu",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            },
            {
              "tid": "kypgsCWHisQWFbay_2",
              "ref": 2,
              "ofcid": "",
              "sdur": "4:5",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "BOM",
                  "dd": "2023-06-30T22:40:00",
                  "ad": "2023-07-01T00:15:00",
                  "rbd": "",
                  "fn": " 267",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "2"
                  },
                  "rph": "",
                  "jsell": "6E~ 267~ ~~HYD~06/30/2023 22:40~BOM~07/01/2023 00:15~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                },
                {
                  "ref": 2,
                  "da": "BOM",
                  "ar": "DOH",
                  "dd": "2023-07-01T04:50:00",
                  "ad": "2023-07-01T05:45:00",
                  "rbd": "",
                  "fn": "1303",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "2"
                  },
                  "rph": "",
                  "jsell": "6E~1303~ ~~BOM~07/01/2023 04:50~DOH~07/01/2023 05:45~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 30621,
                      "fbc": "WCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "30621_36271",
                      "tax": [
                        {
                          "OT": 5650
                        }
                      ],
                      "tf": 36271
                    }
                  ],
                  "pr": {
                    "bf": 30621,
                    "tf": 36271,
                    "tax": [
                      {
                        "OT": 5650
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "30621_36271",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "fj18r2dm7w",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 31321,
                      "fbc": "WCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "31321_37031",
                      "tax": [
                        {
                          "OT": 5710
                        }
                      ],
                      "tf": 37031
                    }
                  ],
                  "pr": {
                    "bf": 31321,
                    "tf": 37031,
                    "tax": [
                      {
                        "OT": 5710
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "31321_37031",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "n9r7nizdyr",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            },
            {
              "tid": "auTkAf5J63aPex81_2",
              "ref": 2,
              "ofcid": "",
              "sdur": "3:55",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DEL",
                  "dd": "2023-06-30T22:45:00",
                  "ad": "2023-07-01T01:00:00",
                  "rbd": "",
                  "fn": "6146",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "1"
                  },
                  "rph": "",
                  "jsell": "6E~6146~ ~~HYD~06/30/2023 22:45~DEL~07/01/2023 01:00~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                },
                {
                  "ref": 2,
                  "da": "DEL",
                  "ar": "DOH",
                  "dd": "2023-07-01T04:05:00",
                  "ad": "2023-07-01T05:40:00",
                  "rbd": "",
                  "fn": "1307",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "3"
                  },
                  "rph": "",
                  "jsell": "6E~1307~ ~~DEL~07/01/2023 04:05~DOH~07/01/2023 05:40~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 30621,
                      "fbc": "WCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "30621_36271",
                      "tax": [
                        {
                          "OT": 5650
                        }
                      ],
                      "tf": 36271
                    }
                  ],
                  "pr": {
                    "bf": 30621,
                    "tf": 36271,
                    "tax": [
                      {
                        "OT": 5650
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "30621_36271",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "97y0gpxro4",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 31321,
                      "fbc": "WCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "31321_37031",
                      "tax": [
                        {
                          "OT": 5710
                        }
                      ],
                      "tf": 37031
                    }
                  ],
                  "pr": {
                    "bf": 31321,
                    "tf": 37031,
                    "tax": [
                      {
                        "OT": 5710
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "31321_37031",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "1n7zb2rnmf",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            }
          ],
          "paxpr": [
            {
              "bf": 41286,
              "tf": 50472,
              "fbc": "",
              "psup_fare": "",
              "tax": [
                [
                  {
                    "OT": 3536
                  }
                ],
                [
                  {
                    "OT": 5650
                  }
                ]
              ],
              "ptype": "ADT",
              "scur": "INR",
              "sup_cur_fare": ""
            }
          ],
          "pr": {
            "bf": 10665,
            "tf": 14201,
            "tax": [
              {
                "OT": 3536
              },
              {
                "OT": 5650
              }
            ],
            "scur": "INR",
            "sup_fare": "",
            "sup_cur_fare": "",
            "cabb": "",
            "chekb": "",
            "seats": "",
            "meals": "",
            "chanb": "",
            "ftyp": ""
          },
          "sup": "4",
          "airlineList": "6E"
        },
        {
          "srcorigns": [
            {
              "tid": "og86WTFQzNSBaNQD_1",
              "ref": 1,
              "ofcid": "",
              "sdur": "22:0",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "DOH",
                  "ar": "DEL",
                  "dd": "2023-06-29T02:00:00",
                  "ad": "2023-06-29T08:20:00",
                  "rbd": "",
                  "fn": "1312",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "3"
                  },
                  "rph": "",
                  "jsell": "6E~1312~ ~~DOH~06/29/2023 02:00~DEL~06/29/2023 08:20~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                },
                {
                  "ref": 2,
                  "da": "DEL",
                  "ar": "HYD",
                  "dd": "2023-06-29T18:50:00",
                  "ad": "2023-06-29T21:00:00",
                  "rbd": "",
                  "fn": "5217",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "3"
                  },
                  "rph": "",
                  "jsell": "6E~5217~ ~~DEL~06/29/2023 18:50~HYD~06/29/2023 21:00~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~P~ ~6E~PCINT~6200~~0~65~~X^0~P~ ~6E~PCINT~6200~~0~65~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 12151,
                      "fbc": "PCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "12151_15739",
                      "tax": [
                        {
                          "OT": 3588
                        }
                      ],
                      "tf": 15739
                    }
                  ],
                  "pr": {
                    "bf": 12151,
                    "tf": 15739,
                    "tax": [
                      {
                        "OT": 3588
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "12151_15739",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "baobfeycqo",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~P~ ~6E~PCUINT~6202~~0~5~~X^0~P~ ~6E~PCUINT~6202~~0~5~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 12851,
                      "fbc": "PCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "12851_16464",
                      "tax": [
                        {
                          "OT": 3613
                        }
                      ],
                      "tf": 16464
                    }
                  ],
                  "pr": {
                    "bf": 12851,
                    "tf": 16464,
                    "tax": [
                      {
                        "OT": 3613
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "12851_16464",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "rz99ybnetk",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            },
            {
              "tid": "LPxXpw53e62l4X8S_1",
              "ref": 1,
              "ofcid": "",
              "sdur": "20:25",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "DOH",
                  "ar": "DEL",
                  "dd": "2023-06-29T02:00:00",
                  "ad": "2023-06-29T08:20:00",
                  "rbd": "",
                  "fn": "1312",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "3"
                  },
                  "rph": "",
                  "jsell": "6E~1312~ ~~DOH~06/29/2023 02:00~DEL~06/29/2023 08:20~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                },
                {
                  "ref": 2,
                  "da": "DEL",
                  "ar": "HYD",
                  "dd": "2023-06-29T17:15:00",
                  "ad": "2023-06-29T19:25:00",
                  "rbd": "",
                  "fn": "5312",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "3"
                  },
                  "rph": "",
                  "jsell": "6E~5312~ ~~DEL~06/29/2023 17:15~HYD~06/29/2023 19:25~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~P~ ~6E~PCINT~6200~~0~65~~X^0~P~ ~6E~PCINT~6200~~0~65~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 12151,
                      "fbc": "PCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "12151_15739",
                      "tax": [
                        {
                          "OT": 3588
                        }
                      ],
                      "tf": 15739
                    }
                  ],
                  "pr": {
                    "bf": 12151,
                    "tf": 15739,
                    "tax": [
                      {
                        "OT": 3588
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "12151_15739",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "64swbss04q",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~P~ ~6E~PCUINT~6202~~0~5~~X^0~P~ ~6E~PCUINT~6202~~0~5~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 12851,
                      "fbc": "PCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "12851_16464",
                      "tax": [
                        {
                          "OT": 3613
                        }
                      ],
                      "tf": 16464
                    }
                  ],
                  "pr": {
                    "bf": 12851,
                    "tf": 16464,
                    "tax": [
                      {
                        "OT": 3613
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "12851_16464",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "64qscqgovj",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            },
            {
              "tid": "dLiZK26PuFRo5SOp_1",
              "ref": 1,
              "ofcid": "",
              "sdur": "19:35",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "DOH",
                  "ar": "DEL",
                  "dd": "2023-06-29T19:40:00",
                  "ad": "2023-06-30T02:00:00",
                  "rbd": "",
                  "fn": "1308",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "3"
                  },
                  "rph": "",
                  "jsell": "6E~1308~ ~~DOH~06/29/2023 19:40~DEL~06/30/2023 02:00~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                },
                {
                  "ref": 2,
                  "da": "DEL",
                  "ar": "HYD",
                  "dd": "2023-06-30T10:10:00",
                  "ad": "2023-06-30T12:15:00",
                  "rbd": "",
                  "fn": "2005",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "2"
                  },
                  "rph": "",
                  "jsell": "6E~2005~ ~~DEL~06/30/2023 10:10~HYD~06/30/2023 12:15~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~P~ ~6E~PCINT~6200~~0~65~~X^0~P~ ~6E~PCINT~6200~~0~65~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 12151,
                      "fbc": "PCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "12151_15739",
                      "tax": [
                        {
                          "OT": 3588
                        }
                      ],
                      "tf": 15739
                    }
                  ],
                  "pr": {
                    "bf": 12151,
                    "tf": 15739,
                    "tax": [
                      {
                        "OT": 3588
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "12151_15739",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "j1jfb8lzrm",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~P~ ~6E~PCUINT~6202~~0~5~~X^0~P~ ~6E~PCUINT~6202~~0~5~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 12851,
                      "fbc": "PCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "12851_16464",
                      "tax": [
                        {
                          "OT": 3613
                        }
                      ],
                      "tf": 16464
                    }
                  ],
                  "pr": {
                    "bf": 12851,
                    "tf": 16464,
                    "tax": [
                      {
                        "OT": 3613
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "12851_16464",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "xxn4rb26rr",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            },
            {
              "tid": "ioiNuPoGSLehZFMN_1",
              "ref": 1,
              "ofcid": "",
              "sdur": "17:40",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "DOH",
                  "ar": "DEL",
                  "dd": "2023-06-29T19:40:00",
                  "ad": "2023-06-30T02:00:00",
                  "rbd": "",
                  "fn": "1308",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "3"
                  },
                  "rph": "",
                  "jsell": "6E~1308~ ~~DOH~06/29/2023 19:40~DEL~06/30/2023 02:00~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                },
                {
                  "ref": 2,
                  "da": "DEL",
                  "ar": "HYD",
                  "dd": "2023-06-30T08:10:00",
                  "ad": "2023-06-30T10:20:00",
                  "rbd": "",
                  "fn": " 774",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "1"
                  },
                  "rph": "",
                  "jsell": "6E~ 774~ ~~DEL~06/30/2023 08:10~HYD~06/30/2023 10:20~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~P~ ~6E~PCINT~6200~~0~65~~X^0~P~ ~6E~PCINT~6200~~0~65~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 12151,
                      "fbc": "PCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "12151_15739",
                      "tax": [
                        {
                          "OT": 3588
                        }
                      ],
                      "tf": 15739
                    }
                  ],
                  "pr": {
                    "bf": 12151,
                    "tf": 15739,
                    "tax": [
                      {
                        "OT": 3588
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "12151_15739",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "9ikqoo777w",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~P~ ~6E~PCUINT~6202~~0~5~~X^0~P~ ~6E~PCUINT~6202~~0~5~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 12851,
                      "fbc": "PCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "12851_16464",
                      "tax": [
                        {
                          "OT": 3613
                        }
                      ],
                      "tf": 16464
                    }
                  ],
                  "pr": {
                    "bf": 12851,
                    "tf": 16464,
                    "tax": [
                      {
                        "OT": 3613
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "12851_16464",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "m1tum1b7qx",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            }
          ],
          "destorigins": [
            {
              "tid": "E1pKW95ht002Znsu_2",
              "ref": 2,
              "ofcid": "",
              "sdur": "6:30",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "MAA",
                  "dd": "2023-06-30T14:05:00",
                  "ad": "2023-06-30T15:20:00",
                  "rbd": "",
                  "fn": " 514",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "1"
                  },
                  "rph": "",
                  "jsell": "6E~ 514~ ~~HYD~06/30/2023 14:05~MAA~06/30/2023 15:20~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                },
                {
                  "ref": 2,
                  "da": "MAA",
                  "ar": "DOH",
                  "dd": "2023-06-30T21:30:00",
                  "ad": "2023-06-30T23:35:00",
                  "rbd": "",
                  "fn": "1321",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "4"
                  },
                  "rph": "",
                  "jsell": "6E~1321~ ~~MAA~06/30/2023 21:30~DOH~06/30/2023 23:35~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~R~ ~6E~RCINT~6200~~0~66~~X^0~R~ ~6E~RCINT~6200~~0~66~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 8036,
                      "fbc": "RCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "8036_11726",
                      "tax": [
                        {
                          "OT": 3690
                        }
                      ],
                      "tf": 11726
                    }
                  ],
                  "pr": {
                    "bf": 8036,
                    "tf": 11726,
                    "tax": [
                      {
                        "OT": 3690
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "8036_11726",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "w3zmh221br",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~R~ ~6E~RCUINT~6202~~0~5~~X^0~R~ ~6E~RCUINT~6202~~0~5~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 8736,
                      "fbc": "RCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "8736_12487",
                      "tax": [
                        {
                          "OT": 3751
                        }
                      ],
                      "tf": 12487
                    }
                  ],
                  "pr": {
                    "bf": 8736,
                    "tf": 12487,
                    "tax": [
                      {
                        "OT": 3751
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "8736_12487",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "lwnfmplut3",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            },
            {
              "tid": "dKwKDp8xlNC1rHUb_2",
              "ref": 2,
              "ofcid": "",
              "sdur": "4:0",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "MAA",
                  "dd": "2023-06-30T16:35:00",
                  "ad": "2023-06-30T17:45:00",
                  "rbd": "",
                  "fn": " 668",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "1"
                  },
                  "rph": "",
                  "jsell": "6E~ 668~ ~~HYD~06/30/2023 16:35~MAA~06/30/2023 17:45~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                },
                {
                  "ref": 2,
                  "da": "MAA",
                  "ar": "DOH",
                  "dd": "2023-06-30T21:30:00",
                  "ad": "2023-06-30T23:35:00",
                  "rbd": "",
                  "fn": "1321",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "4"
                  },
                  "rph": "",
                  "jsell": "6E~1321~ ~~MAA~06/30/2023 21:30~DOH~06/30/2023 23:35~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~R~ ~6E~RCINT~6200~~0~66~~X^0~R~ ~6E~RCINT~6200~~0~66~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 8036,
                      "fbc": "RCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "8036_11726",
                      "tax": [
                        {
                          "OT": 3690
                        }
                      ],
                      "tf": 11726
                    }
                  ],
                  "pr": {
                    "bf": 8036,
                    "tf": 11726,
                    "tax": [
                      {
                        "OT": 3690
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "8036_11726",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "dr2o6k94sdi",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~R~ ~6E~RCUINT~6202~~0~5~~X^0~R~ ~6E~RCUINT~6202~~0~5~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 8736,
                      "fbc": "RCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "8736_12487",
                      "tax": [
                        {
                          "OT": 3751
                        }
                      ],
                      "tf": 12487
                    }
                  ],
                  "pr": {
                    "bf": 8736,
                    "tf": 12487,
                    "tax": [
                      {
                        "OT": 3751
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "8736_12487",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "lgqpa1gnmz",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            },
            {
              "tid": "I1tRD00WXs372vvW_2",
              "ref": 2,
              "ofcid": "",
              "sdur": "2:55",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "MAA",
                  "dd": "2023-06-30T17:40:00",
                  "ad": "2023-06-30T18:55:00",
                  "rbd": "",
                  "fn": " 102",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "1"
                  },
                  "rph": "",
                  "jsell": "6E~ 102~ ~~HYD~06/30/2023 17:40~MAA~06/30/2023 18:55~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                },
                {
                  "ref": 2,
                  "da": "MAA",
                  "ar": "DOH",
                  "dd": "2023-06-30T21:30:00",
                  "ad": "2023-06-30T23:35:00",
                  "rbd": "",
                  "fn": "1321",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "4"
                  },
                  "rph": "",
                  "jsell": "6E~1321~ ~~MAA~06/30/2023 21:30~DOH~06/30/2023 23:35~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~R~ ~6E~RCINT~6200~~0~66~~X^0~R~ ~6E~RCINT~6200~~0~66~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 8036,
                      "fbc": "RCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "8036_11726",
                      "tax": [
                        {
                          "OT": 3690
                        }
                      ],
                      "tf": 11726
                    }
                  ],
                  "pr": {
                    "bf": 8036,
                    "tf": 11726,
                    "tax": [
                      {
                        "OT": 3690
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "8036_11726",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "l0ukl05angh",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~R~ ~6E~RCUINT~6202~~0~5~~X^0~R~ ~6E~RCUINT~6202~~0~5~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 8736,
                      "fbc": "RCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "8736_12487",
                      "tax": [
                        {
                          "OT": 3751
                        }
                      ],
                      "tf": 12487
                    }
                  ],
                  "pr": {
                    "bf": 8736,
                    "tf": 12487,
                    "tax": [
                      {
                        "OT": 3751
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "8736_12487",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "m384yepey8j",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            }
          ],
          "paxpr": [
            {
              "bf": 20187,
              "tf": 27465,
              "fbc": "",
              "psup_fare": "",
              "tax": [
                [
                  {
                    "OT": 3588
                  }
                ],
                [
                  {
                    "OT": 3690
                  }
                ]
              ],
              "ptype": "ADT",
              "scur": "INR",
              "sup_cur_fare": ""
            }
          ],
          "pr": {
            "bf": 12151,
            "tf": 15739,
            "tax": [
              {
                "OT": 3588
              },
              {
                "OT": 3690
              }
            ],
            "scur": "INR",
            "sup_fare": "",
            "sup_cur_fare": "",
            "cabb": "",
            "chekb": "",
            "seats": "",
            "meals": "",
            "chanb": "",
            "ftyp": ""
          },
          "sup": "4",
          "airlineList": "6E"
        },
        {
          "srcorigns": [
            {
              "tid": "6vodlWV31KpQsFCh_1",
              "ref": 1,
              "ofcid": "",
              "sdur": "22:0",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "DOH",
                  "ar": "DEL",
                  "dd": "2023-06-29T02:00:00",
                  "ad": "2023-06-29T08:20:00",
                  "rbd": "",
                  "fn": "1312",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "3"
                  },
                  "rph": "",
                  "jsell": "6E~1312~ ~~DOH~06/29/2023 02:00~DEL~06/29/2023 08:20~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                },
                {
                  "ref": 2,
                  "da": "DEL",
                  "ar": "HYD",
                  "dd": "2023-06-29T18:50:00",
                  "ad": "2023-06-29T21:00:00",
                  "rbd": "",
                  "fn": "5217",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "3"
                  },
                  "rph": "",
                  "jsell": "6E~5217~ ~~DEL~06/29/2023 18:50~HYD~06/29/2023 21:00~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~P~ ~6E~PCINT~6200~~0~65~~X^0~P~ ~6E~PCINT~6200~~0~65~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 12151,
                      "fbc": "PCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "12151_15739",
                      "tax": [
                        {
                          "OT": 3588
                        }
                      ],
                      "tf": 15739
                    }
                  ],
                  "pr": {
                    "bf": 12151,
                    "tf": 15739,
                    "tax": [
                      {
                        "OT": 3588
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "12151_15739",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "baobfeycqo",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~P~ ~6E~PCUINT~6202~~0~5~~X^0~P~ ~6E~PCUINT~6202~~0~5~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 12851,
                      "fbc": "PCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "12851_16464",
                      "tax": [
                        {
                          "OT": 3613
                        }
                      ],
                      "tf": 16464
                    }
                  ],
                  "pr": {
                    "bf": 12851,
                    "tf": 16464,
                    "tax": [
                      {
                        "OT": 3613
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "12851_16464",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "rz99ybnetk",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            },
            {
              "tid": "7UwimEdzSirXnqSa_1",
              "ref": 1,
              "ofcid": "",
              "sdur": "20:25",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "DOH",
                  "ar": "DEL",
                  "dd": "2023-06-29T02:00:00",
                  "ad": "2023-06-29T08:20:00",
                  "rbd": "",
                  "fn": "1312",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "3"
                  },
                  "rph": "",
                  "jsell": "6E~1312~ ~~DOH~06/29/2023 02:00~DEL~06/29/2023 08:20~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                },
                {
                  "ref": 2,
                  "da": "DEL",
                  "ar": "HYD",
                  "dd": "2023-06-29T17:15:00",
                  "ad": "2023-06-29T19:25:00",
                  "rbd": "",
                  "fn": "5312",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "3"
                  },
                  "rph": "",
                  "jsell": "6E~5312~ ~~DEL~06/29/2023 17:15~HYD~06/29/2023 19:25~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~P~ ~6E~PCINT~6200~~0~65~~X^0~P~ ~6E~PCINT~6200~~0~65~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 12151,
                      "fbc": "PCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "12151_15739",
                      "tax": [
                        {
                          "OT": 3588
                        }
                      ],
                      "tf": 15739
                    }
                  ],
                  "pr": {
                    "bf": 12151,
                    "tf": 15739,
                    "tax": [
                      {
                        "OT": 3588
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "12151_15739",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "64swbss04q",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~P~ ~6E~PCUINT~6202~~0~5~~X^0~P~ ~6E~PCUINT~6202~~0~5~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 12851,
                      "fbc": "PCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "12851_16464",
                      "tax": [
                        {
                          "OT": 3613
                        }
                      ],
                      "tf": 16464
                    }
                  ],
                  "pr": {
                    "bf": 12851,
                    "tf": 16464,
                    "tax": [
                      {
                        "OT": 3613
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "12851_16464",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "64qscqgovj",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            },
            {
              "tid": "EBVJ4bTtb43dLTHF_1",
              "ref": 1,
              "ofcid": "",
              "sdur": "19:35",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "DOH",
                  "ar": "DEL",
                  "dd": "2023-06-29T19:40:00",
                  "ad": "2023-06-30T02:00:00",
                  "rbd": "",
                  "fn": "1308",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "3"
                  },
                  "rph": "",
                  "jsell": "6E~1308~ ~~DOH~06/29/2023 19:40~DEL~06/30/2023 02:00~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                },
                {
                  "ref": 2,
                  "da": "DEL",
                  "ar": "HYD",
                  "dd": "2023-06-30T10:10:00",
                  "ad": "2023-06-30T12:15:00",
                  "rbd": "",
                  "fn": "2005",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "2"
                  },
                  "rph": "",
                  "jsell": "6E~2005~ ~~DEL~06/30/2023 10:10~HYD~06/30/2023 12:15~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~P~ ~6E~PCINT~6200~~0~65~~X^0~P~ ~6E~PCINT~6200~~0~65~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 12151,
                      "fbc": "PCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "12151_15739",
                      "tax": [
                        {
                          "OT": 3588
                        }
                      ],
                      "tf": 15739
                    }
                  ],
                  "pr": {
                    "bf": 12151,
                    "tf": 15739,
                    "tax": [
                      {
                        "OT": 3588
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "12151_15739",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "j1jfb8lzrm",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~P~ ~6E~PCUINT~6202~~0~5~~X^0~P~ ~6E~PCUINT~6202~~0~5~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 12851,
                      "fbc": "PCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "12851_16464",
                      "tax": [
                        {
                          "OT": 3613
                        }
                      ],
                      "tf": 16464
                    }
                  ],
                  "pr": {
                    "bf": 12851,
                    "tf": 16464,
                    "tax": [
                      {
                        "OT": 3613
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "12851_16464",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "xxn4rb26rr",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            },
            {
              "tid": "wwUUOBPJk17qk8V5_1",
              "ref": 1,
              "ofcid": "",
              "sdur": "17:40",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "DOH",
                  "ar": "DEL",
                  "dd": "2023-06-29T19:40:00",
                  "ad": "2023-06-30T02:00:00",
                  "rbd": "",
                  "fn": "1308",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "3"
                  },
                  "rph": "",
                  "jsell": "6E~1308~ ~~DOH~06/29/2023 19:40~DEL~06/30/2023 02:00~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                },
                {
                  "ref": 2,
                  "da": "DEL",
                  "ar": "HYD",
                  "dd": "2023-06-30T08:10:00",
                  "ad": "2023-06-30T10:20:00",
                  "rbd": "",
                  "fn": " 774",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "1"
                  },
                  "rph": "",
                  "jsell": "6E~ 774~ ~~DEL~06/30/2023 08:10~HYD~06/30/2023 10:20~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~P~ ~6E~PCINT~6200~~0~65~~X^0~P~ ~6E~PCINT~6200~~0~65~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 12151,
                      "fbc": "PCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "12151_15739",
                      "tax": [
                        {
                          "OT": 3588
                        }
                      ],
                      "tf": 15739
                    }
                  ],
                  "pr": {
                    "bf": 12151,
                    "tf": 15739,
                    "tax": [
                      {
                        "OT": 3588
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "12151_15739",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "9ikqoo777w",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~P~ ~6E~PCUINT~6202~~0~5~~X^0~P~ ~6E~PCUINT~6202~~0~5~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 12851,
                      "fbc": "PCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "12851_16464",
                      "tax": [
                        {
                          "OT": 3613
                        }
                      ],
                      "tf": 16464
                    }
                  ],
                  "pr": {
                    "bf": 12851,
                    "tf": 16464,
                    "tax": [
                      {
                        "OT": 3613
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "12851_16464",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "m1tum1b7qx",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            }
          ],
          "destorigins": [
            {
              "tid": "l4GkOVLTBOFSNL8W_2",
              "ref": 2,
              "ofcid": "",
              "sdur": "0:-80",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DOH",
                  "dd": "2023-06-30T04:10:00",
                  "ad": "2023-06-30T05:50:00",
                  "rbd": "",
                  "fn": "1313",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {},
                  "rph": "",
                  "jsell": "6E~1313~ ~~HYD~06/30/2023 04:10~DOH~06/30/2023 05:50~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~A~ ~6E~A0INT~5000~~0~2~~X",
                  "pr": {
                    "bf": 26311,
                    "tf": 31534,
                    "tax": [
                      {
                        "OT": 5223
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "26311_31534",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "paxpr": [
                    {
                      "bf": 26311,
                      "tf": 31534,
                      "tax": [
                        {
                          "OT": 5223
                        }
                      ],
                      "fbc": "A0INT",
                      "ptype": "ADT",
                      "scur": "INR",
                      "sup_fare": "26311_31534"
                    }
                  ],
                  "routeId": "jqwe69dgev",
                  "productClass": "R",
                  "ruleNumber": "5000"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 0
            },
            {
              "tid": "LPy6eASFc8vB9u4h_2",
              "ref": 2,
              "ofcid": "",
              "sdur": "0:-80",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DOH",
                  "dd": "2023-06-30T17:05:00",
                  "ad": "2023-06-30T18:45:00",
                  "rbd": "",
                  "fn": "1315",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {},
                  "rph": "",
                  "jsell": "6E~1315~ ~~HYD~06/30/2023 17:05~DOH~06/30/2023 18:45~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~A~ ~6E~A0INT~5000~~0~2~~X",
                  "pr": {
                    "bf": 26311,
                    "tf": 31534,
                    "tax": [
                      {
                        "OT": 5223
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "26311_31534",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "paxpr": [
                    {
                      "bf": 26311,
                      "tf": 31534,
                      "tax": [
                        {
                          "OT": 5223
                        }
                      ],
                      "fbc": "A0INT",
                      "ptype": "ADT",
                      "scur": "INR",
                      "sup_fare": "26311_31534"
                    }
                  ],
                  "routeId": "r60c9ia2e8g",
                  "productClass": "R",
                  "ruleNumber": "5000"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 0
            },
            {
              "tid": "NbTyZWcnGW8HeriJ_2",
              "ref": 2,
              "ofcid": "",
              "sdur": "0:-80",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DOH",
                  "dd": "2023-06-30T21:55:00",
                  "ad": "2023-06-30T23:35:00",
                  "rbd": "",
                  "fn": "1317",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {},
                  "rph": "",
                  "jsell": "6E~1317~ ~~HYD~06/30/2023 21:55~DOH~06/30/2023 23:35~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~A~ ~6E~A0INT~5000~~0~2~~X",
                  "pr": {
                    "bf": 26311,
                    "tf": 31534,
                    "tax": [
                      {
                        "OT": 5223
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "26311_31534",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "paxpr": [
                    {
                      "bf": 26311,
                      "tf": 31534,
                      "tax": [
                        {
                          "OT": 5223
                        }
                      ],
                      "fbc": "A0INT",
                      "ptype": "ADT",
                      "scur": "INR",
                      "sup_fare": "26311_31534"
                    }
                  ],
                  "routeId": "zkch9a6jnrh",
                  "productClass": "R",
                  "ruleNumber": "5000"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 0
            }
          ],
          "paxpr": [
            {
              "bf": 38462,
              "tf": 47273,
              "fbc": "",
              "psup_fare": "",
              "tax": [
                [
                  {
                    "OT": 3588
                  }
                ],
                [
                  {
                    "OT": 5223
                  }
                ]
              ],
              "ptype": "ADT",
              "scur": "INR",
              "sup_cur_fare": ""
            }
          ],
          "pr": {
            "bf": 12151,
            "tf": 15739,
            "tax": [
              {
                "OT": 3588
              },
              {
                "OT": 5223
              }
            ],
            "scur": "INR",
            "sup_fare": "",
            "sup_cur_fare": "",
            "cabb": "",
            "chekb": "",
            "seats": "",
            "meals": "",
            "chanb": "",
            "ftyp": ""
          },
          "sup": "4",
          "airlineList": "6E"
        },
        {
          "srcorigns": [
            {
              "tid": "X2FvBXRndBtP3xTw_1",
              "ref": 1,
              "ofcid": "",
              "sdur": "22:0",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "DOH",
                  "ar": "DEL",
                  "dd": "2023-06-29T02:00:00",
                  "ad": "2023-06-29T08:20:00",
                  "rbd": "",
                  "fn": "1312",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "3"
                  },
                  "rph": "",
                  "jsell": "6E~1312~ ~~DOH~06/29/2023 02:00~DEL~06/29/2023 08:20~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                },
                {
                  "ref": 2,
                  "da": "DEL",
                  "ar": "HYD",
                  "dd": "2023-06-29T18:50:00",
                  "ad": "2023-06-29T21:00:00",
                  "rbd": "",
                  "fn": "5217",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "3"
                  },
                  "rph": "",
                  "jsell": "6E~5217~ ~~DEL~06/29/2023 18:50~HYD~06/29/2023 21:00~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~P~ ~6E~PCINT~6200~~0~65~~X^0~P~ ~6E~PCINT~6200~~0~65~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 12151,
                      "fbc": "PCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "12151_15739",
                      "tax": [
                        {
                          "OT": 3588
                        }
                      ],
                      "tf": 15739
                    }
                  ],
                  "pr": {
                    "bf": 12151,
                    "tf": 15739,
                    "tax": [
                      {
                        "OT": 3588
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "12151_15739",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "baobfeycqo",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~P~ ~6E~PCUINT~6202~~0~5~~X^0~P~ ~6E~PCUINT~6202~~0~5~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 12851,
                      "fbc": "PCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "12851_16464",
                      "tax": [
                        {
                          "OT": 3613
                        }
                      ],
                      "tf": 16464
                    }
                  ],
                  "pr": {
                    "bf": 12851,
                    "tf": 16464,
                    "tax": [
                      {
                        "OT": 3613
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "12851_16464",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "rz99ybnetk",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            },
            {
              "tid": "wxAgJeltmAGrz7Lf_1",
              "ref": 1,
              "ofcid": "",
              "sdur": "20:25",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "DOH",
                  "ar": "DEL",
                  "dd": "2023-06-29T02:00:00",
                  "ad": "2023-06-29T08:20:00",
                  "rbd": "",
                  "fn": "1312",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "3"
                  },
                  "rph": "",
                  "jsell": "6E~1312~ ~~DOH~06/29/2023 02:00~DEL~06/29/2023 08:20~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                },
                {
                  "ref": 2,
                  "da": "DEL",
                  "ar": "HYD",
                  "dd": "2023-06-29T17:15:00",
                  "ad": "2023-06-29T19:25:00",
                  "rbd": "",
                  "fn": "5312",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "3"
                  },
                  "rph": "",
                  "jsell": "6E~5312~ ~~DEL~06/29/2023 17:15~HYD~06/29/2023 19:25~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~P~ ~6E~PCINT~6200~~0~65~~X^0~P~ ~6E~PCINT~6200~~0~65~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 12151,
                      "fbc": "PCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "12151_15739",
                      "tax": [
                        {
                          "OT": 3588
                        }
                      ],
                      "tf": 15739
                    }
                  ],
                  "pr": {
                    "bf": 12151,
                    "tf": 15739,
                    "tax": [
                      {
                        "OT": 3588
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "12151_15739",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "64swbss04q",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~P~ ~6E~PCUINT~6202~~0~5~~X^0~P~ ~6E~PCUINT~6202~~0~5~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 12851,
                      "fbc": "PCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "12851_16464",
                      "tax": [
                        {
                          "OT": 3613
                        }
                      ],
                      "tf": 16464
                    }
                  ],
                  "pr": {
                    "bf": 12851,
                    "tf": 16464,
                    "tax": [
                      {
                        "OT": 3613
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "12851_16464",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "64qscqgovj",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            },
            {
              "tid": "f1iNLThw2A8pf43Q_1",
              "ref": 1,
              "ofcid": "",
              "sdur": "19:35",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "DOH",
                  "ar": "DEL",
                  "dd": "2023-06-29T19:40:00",
                  "ad": "2023-06-30T02:00:00",
                  "rbd": "",
                  "fn": "1308",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "3"
                  },
                  "rph": "",
                  "jsell": "6E~1308~ ~~DOH~06/29/2023 19:40~DEL~06/30/2023 02:00~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                },
                {
                  "ref": 2,
                  "da": "DEL",
                  "ar": "HYD",
                  "dd": "2023-06-30T10:10:00",
                  "ad": "2023-06-30T12:15:00",
                  "rbd": "",
                  "fn": "2005",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "2"
                  },
                  "rph": "",
                  "jsell": "6E~2005~ ~~DEL~06/30/2023 10:10~HYD~06/30/2023 12:15~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~P~ ~6E~PCINT~6200~~0~65~~X^0~P~ ~6E~PCINT~6200~~0~65~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 12151,
                      "fbc": "PCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "12151_15739",
                      "tax": [
                        {
                          "OT": 3588
                        }
                      ],
                      "tf": 15739
                    }
                  ],
                  "pr": {
                    "bf": 12151,
                    "tf": 15739,
                    "tax": [
                      {
                        "OT": 3588
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "12151_15739",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "j1jfb8lzrm",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~P~ ~6E~PCUINT~6202~~0~5~~X^0~P~ ~6E~PCUINT~6202~~0~5~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 12851,
                      "fbc": "PCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "12851_16464",
                      "tax": [
                        {
                          "OT": 3613
                        }
                      ],
                      "tf": 16464
                    }
                  ],
                  "pr": {
                    "bf": 12851,
                    "tf": 16464,
                    "tax": [
                      {
                        "OT": 3613
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "12851_16464",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "xxn4rb26rr",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            },
            {
              "tid": "3Q082cvkLCWS2LVi_1",
              "ref": 1,
              "ofcid": "",
              "sdur": "17:40",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "DOH",
                  "ar": "DEL",
                  "dd": "2023-06-29T19:40:00",
                  "ad": "2023-06-30T02:00:00",
                  "rbd": "",
                  "fn": "1308",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "3"
                  },
                  "rph": "",
                  "jsell": "6E~1308~ ~~DOH~06/29/2023 19:40~DEL~06/30/2023 02:00~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                },
                {
                  "ref": 2,
                  "da": "DEL",
                  "ar": "HYD",
                  "dd": "2023-06-30T08:10:00",
                  "ad": "2023-06-30T10:20:00",
                  "rbd": "",
                  "fn": " 774",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "1"
                  },
                  "rph": "",
                  "jsell": "6E~ 774~ ~~DEL~06/30/2023 08:10~HYD~06/30/2023 10:20~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~P~ ~6E~PCINT~6200~~0~65~~X^0~P~ ~6E~PCINT~6200~~0~65~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 12151,
                      "fbc": "PCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "12151_15739",
                      "tax": [
                        {
                          "OT": 3588
                        }
                      ],
                      "tf": 15739
                    }
                  ],
                  "pr": {
                    "bf": 12151,
                    "tf": 15739,
                    "tax": [
                      {
                        "OT": 3588
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "12151_15739",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "9ikqoo777w",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~P~ ~6E~PCUINT~6202~~0~5~~X^0~P~ ~6E~PCUINT~6202~~0~5~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 12851,
                      "fbc": "PCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "12851_16464",
                      "tax": [
                        {
                          "OT": 3613
                        }
                      ],
                      "tf": 16464
                    }
                  ],
                  "pr": {
                    "bf": 12851,
                    "tf": 16464,
                    "tax": [
                      {
                        "OT": 3613
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "12851_16464",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "m1tum1b7qx",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            }
          ],
          "destorigins": [
            {
              "tid": "aQlQDEl2dOd2A1Dx_2",
              "ref": 2,
              "ofcid": "",
              "sdur": "9:0",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "BOM",
                  "dd": "2023-06-30T06:45:00",
                  "ad": "2023-06-30T08:05:00",
                  "rbd": "",
                  "fn": " 286",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "2"
                  },
                  "rph": "",
                  "jsell": "6E~ 286~ ~~HYD~06/30/2023 06:45~BOM~06/30/2023 08:05~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                },
                {
                  "ref": 2,
                  "da": "BOM",
                  "ar": "DOH",
                  "dd": "2023-06-30T17:50:00",
                  "ad": "2023-06-30T18:45:00",
                  "rbd": "",
                  "fn": "1305",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "2"
                  },
                  "rph": "",
                  "jsell": "6E~1305~ ~~BOM~06/30/2023 17:50~DOH~06/30/2023 18:45~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 30621,
                      "fbc": "WCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "30621_36271",
                      "tax": [
                        {
                          "OT": 5650
                        }
                      ],
                      "tf": 36271
                    }
                  ],
                  "pr": {
                    "bf": 30621,
                    "tf": 36271,
                    "tax": [
                      {
                        "OT": 5650
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "30621_36271",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "zmjgocrshz",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 31321,
                      "fbc": "WCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "31321_37031",
                      "tax": [
                        {
                          "OT": 5710
                        }
                      ],
                      "tf": 37031
                    }
                  ],
                  "pr": {
                    "bf": 31321,
                    "tf": 37031,
                    "tax": [
                      {
                        "OT": 5710
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "31321_37031",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "n0q23g4cc2",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            },
            {
              "tid": "D1mzkOOTfMewGiXq_2",
              "ref": 2,
              "ofcid": "",
              "sdur": "8:10",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "BOM",
                  "dd": "2023-06-30T07:35:00",
                  "ad": "2023-06-30T09:10:00",
                  "rbd": "",
                  "fn": " 163",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "2"
                  },
                  "rph": "",
                  "jsell": "6E~ 163~ ~~HYD~06/30/2023 07:35~BOM~06/30/2023 09:10~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                },
                {
                  "ref": 2,
                  "da": "BOM",
                  "ar": "DOH",
                  "dd": "2023-06-30T17:50:00",
                  "ad": "2023-06-30T18:45:00",
                  "rbd": "",
                  "fn": "1305",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "2"
                  },
                  "rph": "",
                  "jsell": "6E~1305~ ~~BOM~06/30/2023 17:50~DOH~06/30/2023 18:45~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 30621,
                      "fbc": "WCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "30621_36271",
                      "tax": [
                        {
                          "OT": 5650
                        }
                      ],
                      "tf": 36271
                    }
                  ],
                  "pr": {
                    "bf": 30621,
                    "tf": 36271,
                    "tax": [
                      {
                        "OT": 5650
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "30621_36271",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "4hzw62ff59",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 31321,
                      "fbc": "WCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "31321_37031",
                      "tax": [
                        {
                          "OT": 5710
                        }
                      ],
                      "tf": 37031
                    }
                  ],
                  "pr": {
                    "bf": 31321,
                    "tf": 37031,
                    "tax": [
                      {
                        "OT": 5710
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "31321_37031",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "ou4xz33zd6k",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            },
            {
              "tid": "CRCMkxW7xfv2rkgv_2",
              "ref": 2,
              "ofcid": "",
              "sdur": "11:15",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DEL",
                  "dd": "2023-06-30T09:30:00",
                  "ad": "2023-06-30T11:50:00",
                  "rbd": "",
                  "fn": "6204",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "1"
                  },
                  "rph": "",
                  "jsell": "6E~6204~ ~~HYD~06/30/2023 09:30~DEL~06/30/2023 11:50~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                },
                {
                  "ref": 2,
                  "da": "DEL",
                  "ar": "DOH",
                  "dd": "2023-06-30T22:10:00",
                  "ad": "2023-06-30T23:45:00",
                  "rbd": "",
                  "fn": "1311",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "3"
                  },
                  "rph": "",
                  "jsell": "6E~1311~ ~~DEL~06/30/2023 22:10~DOH~06/30/2023 23:45~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 30621,
                      "fbc": "WCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "30621_36271",
                      "tax": [
                        {
                          "OT": 5650
                        }
                      ],
                      "tf": 36271
                    }
                  ],
                  "pr": {
                    "bf": 30621,
                    "tf": 36271,
                    "tax": [
                      {
                        "OT": 5650
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "30621_36271",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "r14trqzvx2",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 31321,
                      "fbc": "WCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "31321_37031",
                      "tax": [
                        {
                          "OT": 5710
                        }
                      ],
                      "tf": 37031
                    }
                  ],
                  "pr": {
                    "bf": 31321,
                    "tf": 37031,
                    "tax": [
                      {
                        "OT": 5710
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "31321_37031",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "5scrxkntd2",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            },
            {
              "tid": "OUG8k3rD1auGgOp6_2",
              "ref": 2,
              "ofcid": "",
              "sdur": "4:40",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "BOM",
                  "dd": "2023-06-30T11:05:00",
                  "ad": "2023-06-30T12:40:00",
                  "rbd": "",
                  "fn": "5384",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "1"
                  },
                  "rph": "",
                  "jsell": "6E~5384~ ~~HYD~06/30/2023 11:05~BOM~06/30/2023 12:40~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                },
                {
                  "ref": 2,
                  "da": "BOM",
                  "ar": "DOH",
                  "dd": "2023-06-30T17:50:00",
                  "ad": "2023-06-30T18:45:00",
                  "rbd": "",
                  "fn": "1305",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "2"
                  },
                  "rph": "",
                  "jsell": "6E~1305~ ~~BOM~06/30/2023 17:50~DOH~06/30/2023 18:45~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 30621,
                      "fbc": "WCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "30621_36271",
                      "tax": [
                        {
                          "OT": 5650
                        }
                      ],
                      "tf": 36271
                    }
                  ],
                  "pr": {
                    "bf": 30621,
                    "tf": 36271,
                    "tax": [
                      {
                        "OT": 5650
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "30621_36271",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "t5vkadkl3v",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 31321,
                      "fbc": "WCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "31321_37031",
                      "tax": [
                        {
                          "OT": 5710
                        }
                      ],
                      "tf": 37031
                    }
                  ],
                  "pr": {
                    "bf": 31321,
                    "tf": 37031,
                    "tax": [
                      {
                        "OT": 5710
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "31321_37031",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "85ru4rzu7w",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            },
            {
              "tid": "bCI9XTGzA33OKA0W_2",
              "ref": 2,
              "ofcid": "",
              "sdur": "9:0",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DEL",
                  "dd": "2023-06-30T11:45:00",
                  "ad": "2023-06-30T14:05:00",
                  "rbd": "",
                  "fn": "2171",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "2"
                  },
                  "rph": "",
                  "jsell": "6E~2171~ ~~HYD~06/30/2023 11:45~DEL~06/30/2023 14:05~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                },
                {
                  "ref": 2,
                  "da": "DEL",
                  "ar": "DOH",
                  "dd": "2023-06-30T22:10:00",
                  "ad": "2023-06-30T23:45:00",
                  "rbd": "",
                  "fn": "1311",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "3"
                  },
                  "rph": "",
                  "jsell": "6E~1311~ ~~DEL~06/30/2023 22:10~DOH~06/30/2023 23:45~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 30621,
                      "fbc": "WCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "30621_36271",
                      "tax": [
                        {
                          "OT": 5650
                        }
                      ],
                      "tf": 36271
                    }
                  ],
                  "pr": {
                    "bf": 30621,
                    "tf": 36271,
                    "tax": [
                      {
                        "OT": 5650
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "30621_36271",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "akx6smio4z",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 31321,
                      "fbc": "WCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "31321_37031",
                      "tax": [
                        {
                          "OT": 5710
                        }
                      ],
                      "tf": 37031
                    }
                  ],
                  "pr": {
                    "bf": 31321,
                    "tf": 37031,
                    "tax": [
                      {
                        "OT": 5710
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "31321_37031",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "dkgj90lj86k",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            },
            {
              "tid": "M03hK5CyMma6CkEf_2",
              "ref": 2,
              "ofcid": "",
              "sdur": "2:15",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "BOM",
                  "dd": "2023-06-30T13:30:00",
                  "ad": "2023-06-30T15:05:00",
                  "rbd": "",
                  "fn": "6699",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "2"
                  },
                  "rph": "",
                  "jsell": "6E~6699~ ~~HYD~06/30/2023 13:30~BOM~06/30/2023 15:05~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                },
                {
                  "ref": 2,
                  "da": "BOM",
                  "ar": "DOH",
                  "dd": "2023-06-30T17:50:00",
                  "ad": "2023-06-30T18:45:00",
                  "rbd": "",
                  "fn": "1305",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "2"
                  },
                  "rph": "",
                  "jsell": "6E~1305~ ~~BOM~06/30/2023 17:50~DOH~06/30/2023 18:45~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 30621,
                      "fbc": "WCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "30621_36271",
                      "tax": [
                        {
                          "OT": 5650
                        }
                      ],
                      "tf": 36271
                    }
                  ],
                  "pr": {
                    "bf": 30621,
                    "tf": 36271,
                    "tax": [
                      {
                        "OT": 5650
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "30621_36271",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "awamkecmyo",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 31321,
                      "fbc": "WCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "31321_37031",
                      "tax": [
                        {
                          "OT": 5710
                        }
                      ],
                      "tf": 37031
                    }
                  ],
                  "pr": {
                    "bf": 31321,
                    "tf": 37031,
                    "tax": [
                      {
                        "OT": 5710
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "31321_37031",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "j0vqcizq8x",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            },
            {
              "tid": "FCgIcxq99dGbD6qi_2",
              "ref": 2,
              "ofcid": "",
              "sdur": "10:10",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DEL",
                  "dd": "2023-06-30T16:30:00",
                  "ad": "2023-06-30T18:50:00",
                  "rbd": "",
                  "fn": "6606",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "1"
                  },
                  "rph": "",
                  "jsell": "6E~6606~ ~~HYD~06/30/2023 16:30~DEL~06/30/2023 18:50~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                },
                {
                  "ref": 2,
                  "da": "DEL",
                  "ar": "DOH",
                  "dd": "2023-07-01T04:05:00",
                  "ad": "2023-07-01T05:40:00",
                  "rbd": "",
                  "fn": "1307",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "3"
                  },
                  "rph": "",
                  "jsell": "6E~1307~ ~~DEL~07/01/2023 04:05~DOH~07/01/2023 05:40~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 30621,
                      "fbc": "WCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "30621_36271",
                      "tax": [
                        {
                          "OT": 5650
                        }
                      ],
                      "tf": 36271
                    }
                  ],
                  "pr": {
                    "bf": 30621,
                    "tf": 36271,
                    "tax": [
                      {
                        "OT": 5650
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "30621_36271",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "ezfrbanrdu",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 31321,
                      "fbc": "WCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "31321_37031",
                      "tax": [
                        {
                          "OT": 5710
                        }
                      ],
                      "tf": 37031
                    }
                  ],
                  "pr": {
                    "bf": 31321,
                    "tf": 37031,
                    "tax": [
                      {
                        "OT": 5710
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "31321_37031",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "0mjlumflhs",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            },
            {
              "tid": "rCor8sHizsK6Zvr5_2",
              "ref": 2,
              "ofcid": "",
              "sdur": "4:15",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DEL",
                  "dd": "2023-06-30T16:30:00",
                  "ad": "2023-06-30T18:50:00",
                  "rbd": "",
                  "fn": "6606",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "1"
                  },
                  "rph": "",
                  "jsell": "6E~6606~ ~~HYD~06/30/2023 16:30~DEL~06/30/2023 18:50~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                },
                {
                  "ref": 2,
                  "da": "DEL",
                  "ar": "DOH",
                  "dd": "2023-06-30T22:10:00",
                  "ad": "2023-06-30T23:45:00",
                  "rbd": "",
                  "fn": "1311",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "3"
                  },
                  "rph": "",
                  "jsell": "6E~1311~ ~~DEL~06/30/2023 22:10~DOH~06/30/2023 23:45~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 30621,
                      "fbc": "WCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "30621_36271",
                      "tax": [
                        {
                          "OT": 5650
                        }
                      ],
                      "tf": 36271
                    }
                  ],
                  "pr": {
                    "bf": 30621,
                    "tf": 36271,
                    "tax": [
                      {
                        "OT": 5650
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "30621_36271",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "57wr90guq5",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 31321,
                      "fbc": "WCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "31321_37031",
                      "tax": [
                        {
                          "OT": 5710
                        }
                      ],
                      "tf": 37031
                    }
                  ],
                  "pr": {
                    "bf": 31321,
                    "tf": 37031,
                    "tax": [
                      {
                        "OT": 5710
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "31321_37031",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "gdaj8w8cwgj",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            },
            {
              "tid": "1f8d8DfSVEMu1Kpm_2",
              "ref": 2,
              "ofcid": "",
              "sdur": "8:25",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DEL",
                  "dd": "2023-06-30T18:15:00",
                  "ad": "2023-06-30T20:30:00",
                  "rbd": "",
                  "fn": "2003",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "2"
                  },
                  "rph": "",
                  "jsell": "6E~2003~ ~~HYD~06/30/2023 18:15~DEL~06/30/2023 20:30~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                },
                {
                  "ref": 2,
                  "da": "DEL",
                  "ar": "DOH",
                  "dd": "2023-07-01T04:05:00",
                  "ad": "2023-07-01T05:40:00",
                  "rbd": "",
                  "fn": "1307",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "3"
                  },
                  "rph": "",
                  "jsell": "6E~1307~ ~~DEL~07/01/2023 04:05~DOH~07/01/2023 05:40~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 30621,
                      "fbc": "WCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "30621_36271",
                      "tax": [
                        {
                          "OT": 5650
                        }
                      ],
                      "tf": 36271
                    }
                  ],
                  "pr": {
                    "bf": 30621,
                    "tf": 36271,
                    "tax": [
                      {
                        "OT": 5650
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "30621_36271",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "4w045brimtf",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 31321,
                      "fbc": "WCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "31321_37031",
                      "tax": [
                        {
                          "OT": 5710
                        }
                      ],
                      "tf": 37031
                    }
                  ],
                  "pr": {
                    "bf": 31321,
                    "tf": 37031,
                    "tax": [
                      {
                        "OT": 5710
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "31321_37031",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "szqnb4iqai",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            },
            {
              "tid": "AHINMXv0eQLXSTeX_2",
              "ref": 2,
              "ofcid": "",
              "sdur": "5:55",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "BOM",
                  "dd": "2023-06-30T20:50:00",
                  "ad": "2023-06-30T22:05:00",
                  "rbd": "",
                  "fn": "5389",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "1"
                  },
                  "rph": "",
                  "jsell": "6E~5389~ ~~HYD~06/30/2023 20:50~BOM~06/30/2023 22:05~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                },
                {
                  "ref": 2,
                  "da": "BOM",
                  "ar": "DOH",
                  "dd": "2023-07-01T04:50:00",
                  "ad": "2023-07-01T05:45:00",
                  "rbd": "",
                  "fn": "1303",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "2"
                  },
                  "rph": "",
                  "jsell": "6E~1303~ ~~BOM~07/01/2023 04:50~DOH~07/01/2023 05:45~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 30621,
                      "fbc": "WCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "30621_36271",
                      "tax": [
                        {
                          "OT": 5650
                        }
                      ],
                      "tf": 36271
                    }
                  ],
                  "pr": {
                    "bf": 30621,
                    "tf": 36271,
                    "tax": [
                      {
                        "OT": 5650
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "30621_36271",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "v7u0if3akt",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 31321,
                      "fbc": "WCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "31321_37031",
                      "tax": [
                        {
                          "OT": 5710
                        }
                      ],
                      "tf": 37031
                    }
                  ],
                  "pr": {
                    "bf": 31321,
                    "tf": 37031,
                    "tax": [
                      {
                        "OT": 5710
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "31321_37031",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "q24q0tdz1c",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            },
            {
              "tid": "d6BPOotheoiG9EcR_2",
              "ref": 2,
              "ofcid": "",
              "sdur": "5:10",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "BOM",
                  "dd": "2023-06-30T21:35:00",
                  "ad": "2023-06-30T23:05:00",
                  "rbd": "",
                  "fn": "6952",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "2"
                  },
                  "rph": "",
                  "jsell": "6E~6952~ ~~HYD~06/30/2023 21:35~BOM~06/30/2023 23:05~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                },
                {
                  "ref": 2,
                  "da": "BOM",
                  "ar": "DOH",
                  "dd": "2023-07-01T04:50:00",
                  "ad": "2023-07-01T05:45:00",
                  "rbd": "",
                  "fn": "1303",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "2"
                  },
                  "rph": "",
                  "jsell": "6E~1303~ ~~BOM~07/01/2023 04:50~DOH~07/01/2023 05:45~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 30621,
                      "fbc": "WCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "30621_36271",
                      "tax": [
                        {
                          "OT": 5650
                        }
                      ],
                      "tf": 36271
                    }
                  ],
                  "pr": {
                    "bf": 30621,
                    "tf": 36271,
                    "tax": [
                      {
                        "OT": 5650
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "30621_36271",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "cp4uom8uqv",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 31321,
                      "fbc": "WCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "31321_37031",
                      "tax": [
                        {
                          "OT": 5710
                        }
                      ],
                      "tf": 37031
                    }
                  ],
                  "pr": {
                    "bf": 31321,
                    "tf": 37031,
                    "tax": [
                      {
                        "OT": 5710
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "31321_37031",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "tolwso6o7sh",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            },
            {
              "tid": "sN7oEatRXRM61lEu_2",
              "ref": 2,
              "ofcid": "",
              "sdur": "4:45",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DEL",
                  "dd": "2023-06-30T21:55:00",
                  "ad": "2023-07-01T00:05:00",
                  "rbd": "",
                  "fn": "5605",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "3"
                  },
                  "rph": "",
                  "jsell": "6E~5605~ ~~HYD~06/30/2023 21:55~DEL~07/01/2023 00:05~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                },
                {
                  "ref": 2,
                  "da": "DEL",
                  "ar": "DOH",
                  "dd": "2023-07-01T04:05:00",
                  "ad": "2023-07-01T05:40:00",
                  "rbd": "",
                  "fn": "1307",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "3"
                  },
                  "rph": "",
                  "jsell": "6E~1307~ ~~DEL~07/01/2023 04:05~DOH~07/01/2023 05:40~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 30621,
                      "fbc": "WCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "30621_36271",
                      "tax": [
                        {
                          "OT": 5650
                        }
                      ],
                      "tf": 36271
                    }
                  ],
                  "pr": {
                    "bf": 30621,
                    "tf": 36271,
                    "tax": [
                      {
                        "OT": 5650
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "30621_36271",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "ptc5a83mtz",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 31321,
                      "fbc": "WCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "31321_37031",
                      "tax": [
                        {
                          "OT": 5710
                        }
                      ],
                      "tf": 37031
                    }
                  ],
                  "pr": {
                    "bf": 31321,
                    "tf": 37031,
                    "tax": [
                      {
                        "OT": 5710
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "31321_37031",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "lmh22zryxp",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            },
            {
              "tid": "gMOaA7VwgL1tVrXy_2",
              "ref": 2,
              "ofcid": "",
              "sdur": "4:5",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "BOM",
                  "dd": "2023-06-30T22:40:00",
                  "ad": "2023-07-01T00:15:00",
                  "rbd": "",
                  "fn": " 267",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "2"
                  },
                  "rph": "",
                  "jsell": "6E~ 267~ ~~HYD~06/30/2023 22:40~BOM~07/01/2023 00:15~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                },
                {
                  "ref": 2,
                  "da": "BOM",
                  "ar": "DOH",
                  "dd": "2023-07-01T04:50:00",
                  "ad": "2023-07-01T05:45:00",
                  "rbd": "",
                  "fn": "1303",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "2"
                  },
                  "rph": "",
                  "jsell": "6E~1303~ ~~BOM~07/01/2023 04:50~DOH~07/01/2023 05:45~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 30621,
                      "fbc": "WCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "30621_36271",
                      "tax": [
                        {
                          "OT": 5650
                        }
                      ],
                      "tf": 36271
                    }
                  ],
                  "pr": {
                    "bf": 30621,
                    "tf": 36271,
                    "tax": [
                      {
                        "OT": 5650
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "30621_36271",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "b27huk2xxch",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 31321,
                      "fbc": "WCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "31321_37031",
                      "tax": [
                        {
                          "OT": 5710
                        }
                      ],
                      "tf": 37031
                    }
                  ],
                  "pr": {
                    "bf": 31321,
                    "tf": 37031,
                    "tax": [
                      {
                        "OT": 5710
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "31321_37031",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "owdwv8hot9g",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            },
            {
              "tid": "hXgOz1VNcmwcO6Hg_2",
              "ref": 2,
              "ofcid": "",
              "sdur": "3:55",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DEL",
                  "dd": "2023-06-30T22:45:00",
                  "ad": "2023-07-01T01:00:00",
                  "rbd": "",
                  "fn": "6146",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "1"
                  },
                  "rph": "",
                  "jsell": "6E~6146~ ~~HYD~06/30/2023 22:45~DEL~07/01/2023 01:00~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                },
                {
                  "ref": 2,
                  "da": "DEL",
                  "ar": "DOH",
                  "dd": "2023-07-01T04:05:00",
                  "ad": "2023-07-01T05:40:00",
                  "rbd": "",
                  "fn": "1307",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "3"
                  },
                  "rph": "",
                  "jsell": "6E~1307~ ~~DEL~07/01/2023 04:05~DOH~07/01/2023 05:40~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 30621,
                      "fbc": "WCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "30621_36271",
                      "tax": [
                        {
                          "OT": 5650
                        }
                      ],
                      "tf": 36271
                    }
                  ],
                  "pr": {
                    "bf": 30621,
                    "tf": 36271,
                    "tax": [
                      {
                        "OT": 5650
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "30621_36271",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "ayf5ovuxid",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 31321,
                      "fbc": "WCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "31321_37031",
                      "tax": [
                        {
                          "OT": 5710
                        }
                      ],
                      "tf": 37031
                    }
                  ],
                  "pr": {
                    "bf": 31321,
                    "tf": 37031,
                    "tax": [
                      {
                        "OT": 5710
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "31321_37031",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "mbosrht5dpj",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            }
          ],
          "paxpr": [
            {
              "bf": 42772,
              "tf": 52010,
              "fbc": "",
              "psup_fare": "",
              "tax": [
                [
                  {
                    "OT": 3588
                  }
                ],
                [
                  {
                    "OT": 5650
                  }
                ]
              ],
              "ptype": "ADT",
              "scur": "INR",
              "sup_cur_fare": ""
            }
          ],
          "pr": {
            "bf": 12151,
            "tf": 15739,
            "tax": [
              {
                "OT": 3588
              },
              {
                "OT": 5650
              }
            ],
            "scur": "INR",
            "sup_fare": "",
            "sup_cur_fare": "",
            "cabb": "",
            "chekb": "",
            "seats": "",
            "meals": "",
            "chanb": "",
            "ftyp": ""
          },
          "sup": "4",
          "airlineList": "6E"
        },
        {
          "srcorigns": [
            {
              "tid": "aXIxfefBaCLntnST_1",
              "ref": 1,
              "ofcid": "",
              "sdur": "18:20",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "DOH",
                  "ar": "DEL",
                  "dd": "2023-06-29T02:00:00",
                  "ad": "2023-06-29T08:20:00",
                  "rbd": "",
                  "fn": "1312",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "3"
                  },
                  "rph": "",
                  "jsell": "6E~1312~ ~~DOH~06/29/2023 02:00~DEL~06/29/2023 08:20~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                },
                {
                  "ref": 2,
                  "da": "DEL",
                  "ar": "HYD",
                  "dd": "2023-06-29T15:05:00",
                  "ad": "2023-06-29T17:20:00",
                  "rbd": "",
                  "fn": "2187",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "2"
                  },
                  "rph": "",
                  "jsell": "6E~2187~ ~~DEL~06/29/2023 15:05~HYD~06/29/2023 17:20~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "1~R~ ~6E~R0INT~5000~~0~13~~^2~M~ ~6E~MCIP~1070~~0~15~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 17529,
                      "fbc": "R0INT/MCIP",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "17529_21306",
                      "tax": [
                        {
                          "OT": 3777
                        }
                      ],
                      "tf": 21306
                    }
                  ],
                  "pr": {
                    "bf": 17529,
                    "tf": 21306,
                    "tax": [
                      {
                        "OT": 3777
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "17529_21306",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "2rpsqatc8gf",
                  "ruleNumber": "5000",
                  "productClass": "R"
                },
                {
                  "fareKey": "1~R~ ~6E~RUINT~5003~~0~10~~^2~M~ ~6E~MUIP~2002~~0~62~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 17940,
                      "fbc": "RUINT/MUIP",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "17940_21731",
                      "tax": [
                        {
                          "OT": 3791
                        }
                      ],
                      "tf": 21731
                    }
                  ],
                  "pr": {
                    "bf": 17940,
                    "tf": 21731,
                    "tax": [
                      {
                        "OT": 3791
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "17940_21731",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "1ent26p37z",
                  "ruleNumber": "5003",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            }
          ],
          "destorigins": [
            {
              "tid": "O2KqWeeW5pil6c3G_2",
              "ref": 2,
              "ofcid": "",
              "sdur": "6:30",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "MAA",
                  "dd": "2023-06-30T14:05:00",
                  "ad": "2023-06-30T15:20:00",
                  "rbd": "",
                  "fn": " 514",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "1"
                  },
                  "rph": "",
                  "jsell": "6E~ 514~ ~~HYD~06/30/2023 14:05~MAA~06/30/2023 15:20~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                },
                {
                  "ref": 2,
                  "da": "MAA",
                  "ar": "DOH",
                  "dd": "2023-06-30T21:30:00",
                  "ad": "2023-06-30T23:35:00",
                  "rbd": "",
                  "fn": "1321",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "4"
                  },
                  "rph": "",
                  "jsell": "6E~1321~ ~~MAA~06/30/2023 21:30~DOH~06/30/2023 23:35~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~R~ ~6E~RCINT~6200~~0~66~~X^0~R~ ~6E~RCINT~6200~~0~66~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 8036,
                      "fbc": "RCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "8036_11726",
                      "tax": [
                        {
                          "OT": 3690
                        }
                      ],
                      "tf": 11726
                    }
                  ],
                  "pr": {
                    "bf": 8036,
                    "tf": 11726,
                    "tax": [
                      {
                        "OT": 3690
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "8036_11726",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "f5uhmjkxsg",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~R~ ~6E~RCUINT~6202~~0~5~~X^0~R~ ~6E~RCUINT~6202~~0~5~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 8736,
                      "fbc": "RCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "8736_12487",
                      "tax": [
                        {
                          "OT": 3751
                        }
                      ],
                      "tf": 12487
                    }
                  ],
                  "pr": {
                    "bf": 8736,
                    "tf": 12487,
                    "tax": [
                      {
                        "OT": 3751
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "8736_12487",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "ojc6ou3y3wj",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            },
            {
              "tid": "TcwwhsAWbHqyzQ4k_2",
              "ref": 2,
              "ofcid": "",
              "sdur": "4:0",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "MAA",
                  "dd": "2023-06-30T16:35:00",
                  "ad": "2023-06-30T17:45:00",
                  "rbd": "",
                  "fn": " 668",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "1"
                  },
                  "rph": "",
                  "jsell": "6E~ 668~ ~~HYD~06/30/2023 16:35~MAA~06/30/2023 17:45~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                },
                {
                  "ref": 2,
                  "da": "MAA",
                  "ar": "DOH",
                  "dd": "2023-06-30T21:30:00",
                  "ad": "2023-06-30T23:35:00",
                  "rbd": "",
                  "fn": "1321",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "4"
                  },
                  "rph": "",
                  "jsell": "6E~1321~ ~~MAA~06/30/2023 21:30~DOH~06/30/2023 23:35~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~R~ ~6E~RCINT~6200~~0~66~~X^0~R~ ~6E~RCINT~6200~~0~66~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 8036,
                      "fbc": "RCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "8036_11726",
                      "tax": [
                        {
                          "OT": 3690
                        }
                      ],
                      "tf": 11726
                    }
                  ],
                  "pr": {
                    "bf": 8036,
                    "tf": 11726,
                    "tax": [
                      {
                        "OT": 3690
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "8036_11726",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "qnl5c3z82u",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~R~ ~6E~RCUINT~6202~~0~5~~X^0~R~ ~6E~RCUINT~6202~~0~5~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 8736,
                      "fbc": "RCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "8736_12487",
                      "tax": [
                        {
                          "OT": 3751
                        }
                      ],
                      "tf": 12487
                    }
                  ],
                  "pr": {
                    "bf": 8736,
                    "tf": 12487,
                    "tax": [
                      {
                        "OT": 3751
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "8736_12487",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "v7ebwjhhg6",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            },
            {
              "tid": "9DsMa50o3T6mxLkt_2",
              "ref": 2,
              "ofcid": "",
              "sdur": "2:55",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "MAA",
                  "dd": "2023-06-30T17:40:00",
                  "ad": "2023-06-30T18:55:00",
                  "rbd": "",
                  "fn": " 102",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "1"
                  },
                  "rph": "",
                  "jsell": "6E~ 102~ ~~HYD~06/30/2023 17:40~MAA~06/30/2023 18:55~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                },
                {
                  "ref": 2,
                  "da": "MAA",
                  "ar": "DOH",
                  "dd": "2023-06-30T21:30:00",
                  "ad": "2023-06-30T23:35:00",
                  "rbd": "",
                  "fn": "1321",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "4"
                  },
                  "rph": "",
                  "jsell": "6E~1321~ ~~MAA~06/30/2023 21:30~DOH~06/30/2023 23:35~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~R~ ~6E~RCINT~6200~~0~66~~X^0~R~ ~6E~RCINT~6200~~0~66~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 8036,
                      "fbc": "RCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "8036_11726",
                      "tax": [
                        {
                          "OT": 3690
                        }
                      ],
                      "tf": 11726
                    }
                  ],
                  "pr": {
                    "bf": 8036,
                    "tf": 11726,
                    "tax": [
                      {
                        "OT": 3690
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "8036_11726",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "j45e7psbdq",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~R~ ~6E~RCUINT~6202~~0~5~~X^0~R~ ~6E~RCUINT~6202~~0~5~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 8736,
                      "fbc": "RCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "8736_12487",
                      "tax": [
                        {
                          "OT": 3751
                        }
                      ],
                      "tf": 12487
                    }
                  ],
                  "pr": {
                    "bf": 8736,
                    "tf": 12487,
                    "tax": [
                      {
                        "OT": 3751
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "8736_12487",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "002o1mmnb7",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            }
          ],
          "paxpr": [
            {
              "bf": 25565,
              "tf": 33032,
              "fbc": "",
              "psup_fare": "",
              "tax": [
                [
                  {
                    "OT": 3777
                  }
                ],
                [
                  {
                    "OT": 3690
                  }
                ]
              ],
              "ptype": "ADT",
              "scur": "INR",
              "sup_cur_fare": ""
            }
          ],
          "pr": {
            "bf": 17529,
            "tf": 21306,
            "tax": [
              {
                "OT": 3777
              },
              {
                "OT": 3690
              }
            ],
            "scur": "INR",
            "sup_fare": "",
            "sup_cur_fare": "",
            "cabb": "",
            "chekb": "",
            "seats": "",
            "meals": "",
            "chanb": "",
            "ftyp": ""
          },
          "sup": "4",
          "airlineList": "6E"
        },
        {
          "srcorigns": [
            {
              "tid": "hXFHdVpTWLJFPxnk_1",
              "ref": 1,
              "ofcid": "",
              "sdur": "18:20",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "DOH",
                  "ar": "DEL",
                  "dd": "2023-06-29T02:00:00",
                  "ad": "2023-06-29T08:20:00",
                  "rbd": "",
                  "fn": "1312",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "3"
                  },
                  "rph": "",
                  "jsell": "6E~1312~ ~~DOH~06/29/2023 02:00~DEL~06/29/2023 08:20~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                },
                {
                  "ref": 2,
                  "da": "DEL",
                  "ar": "HYD",
                  "dd": "2023-06-29T15:05:00",
                  "ad": "2023-06-29T17:20:00",
                  "rbd": "",
                  "fn": "2187",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "2"
                  },
                  "rph": "",
                  "jsell": "6E~2187~ ~~DEL~06/29/2023 15:05~HYD~06/29/2023 17:20~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "1~R~ ~6E~R0INT~5000~~0~13~~^2~M~ ~6E~MCIP~1070~~0~15~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 17529,
                      "fbc": "R0INT/MCIP",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "17529_21306",
                      "tax": [
                        {
                          "OT": 3777
                        }
                      ],
                      "tf": 21306
                    }
                  ],
                  "pr": {
                    "bf": 17529,
                    "tf": 21306,
                    "tax": [
                      {
                        "OT": 3777
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "17529_21306",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "2rpsqatc8gf",
                  "ruleNumber": "5000",
                  "productClass": "R"
                },
                {
                  "fareKey": "1~R~ ~6E~RUINT~5003~~0~10~~^2~M~ ~6E~MUIP~2002~~0~62~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 17940,
                      "fbc": "RUINT/MUIP",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "17940_21731",
                      "tax": [
                        {
                          "OT": 3791
                        }
                      ],
                      "tf": 21731
                    }
                  ],
                  "pr": {
                    "bf": 17940,
                    "tf": 21731,
                    "tax": [
                      {
                        "OT": 3791
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "17940_21731",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "1ent26p37z",
                  "ruleNumber": "5003",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            }
          ],
          "destorigins": [
            {
              "tid": "srXf3We5WGetxkqn_2",
              "ref": 2,
              "ofcid": "",
              "sdur": "0:-80",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DOH",
                  "dd": "2023-06-30T04:10:00",
                  "ad": "2023-06-30T05:50:00",
                  "rbd": "",
                  "fn": "1313",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {},
                  "rph": "",
                  "jsell": "6E~1313~ ~~HYD~06/30/2023 04:10~DOH~06/30/2023 05:50~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~A~ ~6E~A0INT~5000~~0~2~~X",
                  "pr": {
                    "bf": 26311,
                    "tf": 31534,
                    "tax": [
                      {
                        "OT": 5223
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "26311_31534",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "paxpr": [
                    {
                      "bf": 26311,
                      "tf": 31534,
                      "tax": [
                        {
                          "OT": 5223
                        }
                      ],
                      "fbc": "A0INT",
                      "ptype": "ADT",
                      "scur": "INR",
                      "sup_fare": "26311_31534"
                    }
                  ],
                  "routeId": "jsd2afjcmt",
                  "productClass": "R",
                  "ruleNumber": "5000"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 0
            },
            {
              "tid": "920EOn8PhQAWHnTO_2",
              "ref": 2,
              "ofcid": "",
              "sdur": "0:-80",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DOH",
                  "dd": "2023-06-30T17:05:00",
                  "ad": "2023-06-30T18:45:00",
                  "rbd": "",
                  "fn": "1315",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {},
                  "rph": "",
                  "jsell": "6E~1315~ ~~HYD~06/30/2023 17:05~DOH~06/30/2023 18:45~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~A~ ~6E~A0INT~5000~~0~2~~X",
                  "pr": {
                    "bf": 26311,
                    "tf": 31534,
                    "tax": [
                      {
                        "OT": 5223
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "26311_31534",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "paxpr": [
                    {
                      "bf": 26311,
                      "tf": 31534,
                      "tax": [
                        {
                          "OT": 5223
                        }
                      ],
                      "fbc": "A0INT",
                      "ptype": "ADT",
                      "scur": "INR",
                      "sup_fare": "26311_31534"
                    }
                  ],
                  "routeId": "299k2irwj5",
                  "productClass": "R",
                  "ruleNumber": "5000"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 0
            },
            {
              "tid": "wvelEgdRBFBLIvJp_2",
              "ref": 2,
              "ofcid": "",
              "sdur": "0:-80",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DOH",
                  "dd": "2023-06-30T21:55:00",
                  "ad": "2023-06-30T23:35:00",
                  "rbd": "",
                  "fn": "1317",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {},
                  "rph": "",
                  "jsell": "6E~1317~ ~~HYD~06/30/2023 21:55~DOH~06/30/2023 23:35~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~A~ ~6E~A0INT~5000~~0~2~~X",
                  "pr": {
                    "bf": 26311,
                    "tf": 31534,
                    "tax": [
                      {
                        "OT": 5223
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "26311_31534",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "paxpr": [
                    {
                      "bf": 26311,
                      "tf": 31534,
                      "tax": [
                        {
                          "OT": 5223
                        }
                      ],
                      "fbc": "A0INT",
                      "ptype": "ADT",
                      "scur": "INR",
                      "sup_fare": "26311_31534"
                    }
                  ],
                  "routeId": "lrk8cyfjor",
                  "productClass": "R",
                  "ruleNumber": "5000"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 0
            }
          ],
          "paxpr": [
            {
              "bf": 43840,
              "tf": 52840,
              "fbc": "",
              "psup_fare": "",
              "tax": [
                [
                  {
                    "OT": 3777
                  }
                ],
                [
                  {
                    "OT": 5223
                  }
                ]
              ],
              "ptype": "ADT",
              "scur": "INR",
              "sup_cur_fare": ""
            }
          ],
          "pr": {
            "bf": 17529,
            "tf": 21306,
            "tax": [
              {
                "OT": 3777
              },
              {
                "OT": 5223
              }
            ],
            "scur": "INR",
            "sup_fare": "",
            "sup_cur_fare": "",
            "cabb": "",
            "chekb": "",
            "seats": "",
            "meals": "",
            "chanb": "",
            "ftyp": ""
          },
          "sup": "4",
          "airlineList": "6E"
        },
        {
          "srcorigns": [
            {
              "tid": "bLlJxO3uiT1hD1vL_1",
              "ref": 1,
              "ofcid": "",
              "sdur": "18:20",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "DOH",
                  "ar": "DEL",
                  "dd": "2023-06-29T02:00:00",
                  "ad": "2023-06-29T08:20:00",
                  "rbd": "",
                  "fn": "1312",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "3"
                  },
                  "rph": "",
                  "jsell": "6E~1312~ ~~DOH~06/29/2023 02:00~DEL~06/29/2023 08:20~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                },
                {
                  "ref": 2,
                  "da": "DEL",
                  "ar": "HYD",
                  "dd": "2023-06-29T15:05:00",
                  "ad": "2023-06-29T17:20:00",
                  "rbd": "",
                  "fn": "2187",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "2"
                  },
                  "rph": "",
                  "jsell": "6E~2187~ ~~DEL~06/29/2023 15:05~HYD~06/29/2023 17:20~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "1~R~ ~6E~R0INT~5000~~0~13~~^2~M~ ~6E~MCIP~1070~~0~15~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 17529,
                      "fbc": "R0INT/MCIP",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "17529_21306",
                      "tax": [
                        {
                          "OT": 3777
                        }
                      ],
                      "tf": 21306
                    }
                  ],
                  "pr": {
                    "bf": 17529,
                    "tf": 21306,
                    "tax": [
                      {
                        "OT": 3777
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "17529_21306",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "2rpsqatc8gf",
                  "ruleNumber": "5000",
                  "productClass": "R"
                },
                {
                  "fareKey": "1~R~ ~6E~RUINT~5003~~0~10~~^2~M~ ~6E~MUIP~2002~~0~62~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 17940,
                      "fbc": "RUINT/MUIP",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "17940_21731",
                      "tax": [
                        {
                          "OT": 3791
                        }
                      ],
                      "tf": 21731
                    }
                  ],
                  "pr": {
                    "bf": 17940,
                    "tf": 21731,
                    "tax": [
                      {
                        "OT": 3791
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "17940_21731",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "1ent26p37z",
                  "ruleNumber": "5003",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            }
          ],
          "destorigins": [
            {
              "tid": "4sZoWPumWrrK3oS5_2",
              "ref": 2,
              "ofcid": "",
              "sdur": "9:0",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "BOM",
                  "dd": "2023-06-30T06:45:00",
                  "ad": "2023-06-30T08:05:00",
                  "rbd": "",
                  "fn": " 286",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "2"
                  },
                  "rph": "",
                  "jsell": "6E~ 286~ ~~HYD~06/30/2023 06:45~BOM~06/30/2023 08:05~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                },
                {
                  "ref": 2,
                  "da": "BOM",
                  "ar": "DOH",
                  "dd": "2023-06-30T17:50:00",
                  "ad": "2023-06-30T18:45:00",
                  "rbd": "",
                  "fn": "1305",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "2"
                  },
                  "rph": "",
                  "jsell": "6E~1305~ ~~BOM~06/30/2023 17:50~DOH~06/30/2023 18:45~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 30621,
                      "fbc": "WCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "30621_36271",
                      "tax": [
                        {
                          "OT": 5650
                        }
                      ],
                      "tf": 36271
                    }
                  ],
                  "pr": {
                    "bf": 30621,
                    "tf": 36271,
                    "tax": [
                      {
                        "OT": 5650
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "30621_36271",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "ft47w26o4if",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 31321,
                      "fbc": "WCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "31321_37031",
                      "tax": [
                        {
                          "OT": 5710
                        }
                      ],
                      "tf": 37031
                    }
                  ],
                  "pr": {
                    "bf": 31321,
                    "tf": 37031,
                    "tax": [
                      {
                        "OT": 5710
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "31321_37031",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "9chmzljugw",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            },
            {
              "tid": "a1cBRcaSRFhcGiVQ_2",
              "ref": 2,
              "ofcid": "",
              "sdur": "8:10",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "BOM",
                  "dd": "2023-06-30T07:35:00",
                  "ad": "2023-06-30T09:10:00",
                  "rbd": "",
                  "fn": " 163",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "2"
                  },
                  "rph": "",
                  "jsell": "6E~ 163~ ~~HYD~06/30/2023 07:35~BOM~06/30/2023 09:10~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                },
                {
                  "ref": 2,
                  "da": "BOM",
                  "ar": "DOH",
                  "dd": "2023-06-30T17:50:00",
                  "ad": "2023-06-30T18:45:00",
                  "rbd": "",
                  "fn": "1305",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "2"
                  },
                  "rph": "",
                  "jsell": "6E~1305~ ~~BOM~06/30/2023 17:50~DOH~06/30/2023 18:45~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 30621,
                      "fbc": "WCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "30621_36271",
                      "tax": [
                        {
                          "OT": 5650
                        }
                      ],
                      "tf": 36271
                    }
                  ],
                  "pr": {
                    "bf": 30621,
                    "tf": 36271,
                    "tax": [
                      {
                        "OT": 5650
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "30621_36271",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "n99ikdvcwq",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 31321,
                      "fbc": "WCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "31321_37031",
                      "tax": [
                        {
                          "OT": 5710
                        }
                      ],
                      "tf": 37031
                    }
                  ],
                  "pr": {
                    "bf": 31321,
                    "tf": 37031,
                    "tax": [
                      {
                        "OT": 5710
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "31321_37031",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "zkr7lzq5r7",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            },
            {
              "tid": "7Zvspl8BpKVUKezV_2",
              "ref": 2,
              "ofcid": "",
              "sdur": "11:15",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DEL",
                  "dd": "2023-06-30T09:30:00",
                  "ad": "2023-06-30T11:50:00",
                  "rbd": "",
                  "fn": "6204",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "1"
                  },
                  "rph": "",
                  "jsell": "6E~6204~ ~~HYD~06/30/2023 09:30~DEL~06/30/2023 11:50~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                },
                {
                  "ref": 2,
                  "da": "DEL",
                  "ar": "DOH",
                  "dd": "2023-06-30T22:10:00",
                  "ad": "2023-06-30T23:45:00",
                  "rbd": "",
                  "fn": "1311",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "3"
                  },
                  "rph": "",
                  "jsell": "6E~1311~ ~~DEL~06/30/2023 22:10~DOH~06/30/2023 23:45~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 30621,
                      "fbc": "WCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "30621_36271",
                      "tax": [
                        {
                          "OT": 5650
                        }
                      ],
                      "tf": 36271
                    }
                  ],
                  "pr": {
                    "bf": 30621,
                    "tf": 36271,
                    "tax": [
                      {
                        "OT": 5650
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "30621_36271",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "bpsq492l8il",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 31321,
                      "fbc": "WCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "31321_37031",
                      "tax": [
                        {
                          "OT": 5710
                        }
                      ],
                      "tf": 37031
                    }
                  ],
                  "pr": {
                    "bf": 31321,
                    "tf": 37031,
                    "tax": [
                      {
                        "OT": 5710
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "31321_37031",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "b60fheuocx",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            },
            {
              "tid": "regFiVNUvh3VZrDx_2",
              "ref": 2,
              "ofcid": "",
              "sdur": "4:40",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "BOM",
                  "dd": "2023-06-30T11:05:00",
                  "ad": "2023-06-30T12:40:00",
                  "rbd": "",
                  "fn": "5384",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "1"
                  },
                  "rph": "",
                  "jsell": "6E~5384~ ~~HYD~06/30/2023 11:05~BOM~06/30/2023 12:40~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                },
                {
                  "ref": 2,
                  "da": "BOM",
                  "ar": "DOH",
                  "dd": "2023-06-30T17:50:00",
                  "ad": "2023-06-30T18:45:00",
                  "rbd": "",
                  "fn": "1305",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "2"
                  },
                  "rph": "",
                  "jsell": "6E~1305~ ~~BOM~06/30/2023 17:50~DOH~06/30/2023 18:45~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 30621,
                      "fbc": "WCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "30621_36271",
                      "tax": [
                        {
                          "OT": 5650
                        }
                      ],
                      "tf": 36271
                    }
                  ],
                  "pr": {
                    "bf": 30621,
                    "tf": 36271,
                    "tax": [
                      {
                        "OT": 5650
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "30621_36271",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "8fv11u888o",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 31321,
                      "fbc": "WCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "31321_37031",
                      "tax": [
                        {
                          "OT": 5710
                        }
                      ],
                      "tf": 37031
                    }
                  ],
                  "pr": {
                    "bf": 31321,
                    "tf": 37031,
                    "tax": [
                      {
                        "OT": 5710
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "31321_37031",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "n6oub987un",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            },
            {
              "tid": "FVeslzStFtVtWWWz_2",
              "ref": 2,
              "ofcid": "",
              "sdur": "9:0",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DEL",
                  "dd": "2023-06-30T11:45:00",
                  "ad": "2023-06-30T14:05:00",
                  "rbd": "",
                  "fn": "2171",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "2"
                  },
                  "rph": "",
                  "jsell": "6E~2171~ ~~HYD~06/30/2023 11:45~DEL~06/30/2023 14:05~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                },
                {
                  "ref": 2,
                  "da": "DEL",
                  "ar": "DOH",
                  "dd": "2023-06-30T22:10:00",
                  "ad": "2023-06-30T23:45:00",
                  "rbd": "",
                  "fn": "1311",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "3"
                  },
                  "rph": "",
                  "jsell": "6E~1311~ ~~DEL~06/30/2023 22:10~DOH~06/30/2023 23:45~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 30621,
                      "fbc": "WCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "30621_36271",
                      "tax": [
                        {
                          "OT": 5650
                        }
                      ],
                      "tf": 36271
                    }
                  ],
                  "pr": {
                    "bf": 30621,
                    "tf": 36271,
                    "tax": [
                      {
                        "OT": 5650
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "30621_36271",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "a8ulwgtd1",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 31321,
                      "fbc": "WCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "31321_37031",
                      "tax": [
                        {
                          "OT": 5710
                        }
                      ],
                      "tf": 37031
                    }
                  ],
                  "pr": {
                    "bf": 31321,
                    "tf": 37031,
                    "tax": [
                      {
                        "OT": 5710
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "31321_37031",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "uvpjtks66lf",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            },
            {
              "tid": "irSsxlQCkeDb8rmW_2",
              "ref": 2,
              "ofcid": "",
              "sdur": "2:15",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "BOM",
                  "dd": "2023-06-30T13:30:00",
                  "ad": "2023-06-30T15:05:00",
                  "rbd": "",
                  "fn": "6699",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "2"
                  },
                  "rph": "",
                  "jsell": "6E~6699~ ~~HYD~06/30/2023 13:30~BOM~06/30/2023 15:05~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                },
                {
                  "ref": 2,
                  "da": "BOM",
                  "ar": "DOH",
                  "dd": "2023-06-30T17:50:00",
                  "ad": "2023-06-30T18:45:00",
                  "rbd": "",
                  "fn": "1305",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "2"
                  },
                  "rph": "",
                  "jsell": "6E~1305~ ~~BOM~06/30/2023 17:50~DOH~06/30/2023 18:45~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 30621,
                      "fbc": "WCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "30621_36271",
                      "tax": [
                        {
                          "OT": 5650
                        }
                      ],
                      "tf": 36271
                    }
                  ],
                  "pr": {
                    "bf": 30621,
                    "tf": 36271,
                    "tax": [
                      {
                        "OT": 5650
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "30621_36271",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "wrspz9syux",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 31321,
                      "fbc": "WCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "31321_37031",
                      "tax": [
                        {
                          "OT": 5710
                        }
                      ],
                      "tf": 37031
                    }
                  ],
                  "pr": {
                    "bf": 31321,
                    "tf": 37031,
                    "tax": [
                      {
                        "OT": 5710
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "31321_37031",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "6c76hco2qb",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            },
            {
              "tid": "5cLetcvP6rSXc5T6_2",
              "ref": 2,
              "ofcid": "",
              "sdur": "10:10",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DEL",
                  "dd": "2023-06-30T16:30:00",
                  "ad": "2023-06-30T18:50:00",
                  "rbd": "",
                  "fn": "6606",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "1"
                  },
                  "rph": "",
                  "jsell": "6E~6606~ ~~HYD~06/30/2023 16:30~DEL~06/30/2023 18:50~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                },
                {
                  "ref": 2,
                  "da": "DEL",
                  "ar": "DOH",
                  "dd": "2023-07-01T04:05:00",
                  "ad": "2023-07-01T05:40:00",
                  "rbd": "",
                  "fn": "1307",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "3"
                  },
                  "rph": "",
                  "jsell": "6E~1307~ ~~DEL~07/01/2023 04:05~DOH~07/01/2023 05:40~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 30621,
                      "fbc": "WCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "30621_36271",
                      "tax": [
                        {
                          "OT": 5650
                        }
                      ],
                      "tf": 36271
                    }
                  ],
                  "pr": {
                    "bf": 30621,
                    "tf": 36271,
                    "tax": [
                      {
                        "OT": 5650
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "30621_36271",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "bx7al6uav9",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 31321,
                      "fbc": "WCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "31321_37031",
                      "tax": [
                        {
                          "OT": 5710
                        }
                      ],
                      "tf": 37031
                    }
                  ],
                  "pr": {
                    "bf": 31321,
                    "tf": 37031,
                    "tax": [
                      {
                        "OT": 5710
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "31321_37031",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "dsnuqh0ts9",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            },
            {
              "tid": "G7TZECWTzlCsOmH2_2",
              "ref": 2,
              "ofcid": "",
              "sdur": "4:15",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DEL",
                  "dd": "2023-06-30T16:30:00",
                  "ad": "2023-06-30T18:50:00",
                  "rbd": "",
                  "fn": "6606",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "1"
                  },
                  "rph": "",
                  "jsell": "6E~6606~ ~~HYD~06/30/2023 16:30~DEL~06/30/2023 18:50~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                },
                {
                  "ref": 2,
                  "da": "DEL",
                  "ar": "DOH",
                  "dd": "2023-06-30T22:10:00",
                  "ad": "2023-06-30T23:45:00",
                  "rbd": "",
                  "fn": "1311",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "3"
                  },
                  "rph": "",
                  "jsell": "6E~1311~ ~~DEL~06/30/2023 22:10~DOH~06/30/2023 23:45~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 30621,
                      "fbc": "WCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "30621_36271",
                      "tax": [
                        {
                          "OT": 5650
                        }
                      ],
                      "tf": 36271
                    }
                  ],
                  "pr": {
                    "bf": 30621,
                    "tf": 36271,
                    "tax": [
                      {
                        "OT": 5650
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "30621_36271",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "x4tdannuuwj",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 31321,
                      "fbc": "WCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "31321_37031",
                      "tax": [
                        {
                          "OT": 5710
                        }
                      ],
                      "tf": 37031
                    }
                  ],
                  "pr": {
                    "bf": 31321,
                    "tf": 37031,
                    "tax": [
                      {
                        "OT": 5710
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "31321_37031",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "tx8jp6umxy",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            },
            {
              "tid": "FHsGP92DckNF3r1k_2",
              "ref": 2,
              "ofcid": "",
              "sdur": "8:25",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DEL",
                  "dd": "2023-06-30T18:15:00",
                  "ad": "2023-06-30T20:30:00",
                  "rbd": "",
                  "fn": "2003",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "2"
                  },
                  "rph": "",
                  "jsell": "6E~2003~ ~~HYD~06/30/2023 18:15~DEL~06/30/2023 20:30~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                },
                {
                  "ref": 2,
                  "da": "DEL",
                  "ar": "DOH",
                  "dd": "2023-07-01T04:05:00",
                  "ad": "2023-07-01T05:40:00",
                  "rbd": "",
                  "fn": "1307",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "3"
                  },
                  "rph": "",
                  "jsell": "6E~1307~ ~~DEL~07/01/2023 04:05~DOH~07/01/2023 05:40~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 30621,
                      "fbc": "WCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "30621_36271",
                      "tax": [
                        {
                          "OT": 5650
                        }
                      ],
                      "tf": 36271
                    }
                  ],
                  "pr": {
                    "bf": 30621,
                    "tf": 36271,
                    "tax": [
                      {
                        "OT": 5650
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "30621_36271",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "9rbya39di8",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 31321,
                      "fbc": "WCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "31321_37031",
                      "tax": [
                        {
                          "OT": 5710
                        }
                      ],
                      "tf": 37031
                    }
                  ],
                  "pr": {
                    "bf": 31321,
                    "tf": 37031,
                    "tax": [
                      {
                        "OT": 5710
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "31321_37031",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "smrr4ibvsg",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            },
            {
              "tid": "94FG1l4BOBiPDfsP_2",
              "ref": 2,
              "ofcid": "",
              "sdur": "5:55",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "BOM",
                  "dd": "2023-06-30T20:50:00",
                  "ad": "2023-06-30T22:05:00",
                  "rbd": "",
                  "fn": "5389",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "1"
                  },
                  "rph": "",
                  "jsell": "6E~5389~ ~~HYD~06/30/2023 20:50~BOM~06/30/2023 22:05~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                },
                {
                  "ref": 2,
                  "da": "BOM",
                  "ar": "DOH",
                  "dd": "2023-07-01T04:50:00",
                  "ad": "2023-07-01T05:45:00",
                  "rbd": "",
                  "fn": "1303",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "2"
                  },
                  "rph": "",
                  "jsell": "6E~1303~ ~~BOM~07/01/2023 04:50~DOH~07/01/2023 05:45~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 30621,
                      "fbc": "WCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "30621_36271",
                      "tax": [
                        {
                          "OT": 5650
                        }
                      ],
                      "tf": 36271
                    }
                  ],
                  "pr": {
                    "bf": 30621,
                    "tf": 36271,
                    "tax": [
                      {
                        "OT": 5650
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "30621_36271",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "hrc9z5qgsd",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 31321,
                      "fbc": "WCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "31321_37031",
                      "tax": [
                        {
                          "OT": 5710
                        }
                      ],
                      "tf": 37031
                    }
                  ],
                  "pr": {
                    "bf": 31321,
                    "tf": 37031,
                    "tax": [
                      {
                        "OT": 5710
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "31321_37031",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "vdpt42nhoc",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            },
            {
              "tid": "r0RHiQDPyFihMWRI_2",
              "ref": 2,
              "ofcid": "",
              "sdur": "5:10",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "BOM",
                  "dd": "2023-06-30T21:35:00",
                  "ad": "2023-06-30T23:05:00",
                  "rbd": "",
                  "fn": "6952",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "2"
                  },
                  "rph": "",
                  "jsell": "6E~6952~ ~~HYD~06/30/2023 21:35~BOM~06/30/2023 23:05~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                },
                {
                  "ref": 2,
                  "da": "BOM",
                  "ar": "DOH",
                  "dd": "2023-07-01T04:50:00",
                  "ad": "2023-07-01T05:45:00",
                  "rbd": "",
                  "fn": "1303",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "2"
                  },
                  "rph": "",
                  "jsell": "6E~1303~ ~~BOM~07/01/2023 04:50~DOH~07/01/2023 05:45~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 30621,
                      "fbc": "WCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "30621_36271",
                      "tax": [
                        {
                          "OT": 5650
                        }
                      ],
                      "tf": 36271
                    }
                  ],
                  "pr": {
                    "bf": 30621,
                    "tf": 36271,
                    "tax": [
                      {
                        "OT": 5650
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "30621_36271",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "acsa38mu2n",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 31321,
                      "fbc": "WCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "31321_37031",
                      "tax": [
                        {
                          "OT": 5710
                        }
                      ],
                      "tf": 37031
                    }
                  ],
                  "pr": {
                    "bf": 31321,
                    "tf": 37031,
                    "tax": [
                      {
                        "OT": 5710
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "31321_37031",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "xnj81kzckz",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            },
            {
              "tid": "2ZqOOvA8C9tVVEGR_2",
              "ref": 2,
              "ofcid": "",
              "sdur": "4:45",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DEL",
                  "dd": "2023-06-30T21:55:00",
                  "ad": "2023-07-01T00:05:00",
                  "rbd": "",
                  "fn": "5605",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "3"
                  },
                  "rph": "",
                  "jsell": "6E~5605~ ~~HYD~06/30/2023 21:55~DEL~07/01/2023 00:05~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                },
                {
                  "ref": 2,
                  "da": "DEL",
                  "ar": "DOH",
                  "dd": "2023-07-01T04:05:00",
                  "ad": "2023-07-01T05:40:00",
                  "rbd": "",
                  "fn": "1307",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "3"
                  },
                  "rph": "",
                  "jsell": "6E~1307~ ~~DEL~07/01/2023 04:05~DOH~07/01/2023 05:40~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 30621,
                      "fbc": "WCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "30621_36271",
                      "tax": [
                        {
                          "OT": 5650
                        }
                      ],
                      "tf": 36271
                    }
                  ],
                  "pr": {
                    "bf": 30621,
                    "tf": 36271,
                    "tax": [
                      {
                        "OT": 5650
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "30621_36271",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "k2um3wdlw0g",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 31321,
                      "fbc": "WCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "31321_37031",
                      "tax": [
                        {
                          "OT": 5710
                        }
                      ],
                      "tf": 37031
                    }
                  ],
                  "pr": {
                    "bf": 31321,
                    "tf": 37031,
                    "tax": [
                      {
                        "OT": 5710
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "31321_37031",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "xfd5lskfg6k",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            },
            {
              "tid": "hnz93iaROIqsCc81_2",
              "ref": 2,
              "ofcid": "",
              "sdur": "4:5",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "BOM",
                  "dd": "2023-06-30T22:40:00",
                  "ad": "2023-07-01T00:15:00",
                  "rbd": "",
                  "fn": " 267",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "2"
                  },
                  "rph": "",
                  "jsell": "6E~ 267~ ~~HYD~06/30/2023 22:40~BOM~07/01/2023 00:15~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                },
                {
                  "ref": 2,
                  "da": "BOM",
                  "ar": "DOH",
                  "dd": "2023-07-01T04:50:00",
                  "ad": "2023-07-01T05:45:00",
                  "rbd": "",
                  "fn": "1303",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "2"
                  },
                  "rph": "",
                  "jsell": "6E~1303~ ~~BOM~07/01/2023 04:50~DOH~07/01/2023 05:45~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 30621,
                      "fbc": "WCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "30621_36271",
                      "tax": [
                        {
                          "OT": 5650
                        }
                      ],
                      "tf": 36271
                    }
                  ],
                  "pr": {
                    "bf": 30621,
                    "tf": 36271,
                    "tax": [
                      {
                        "OT": 5650
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "30621_36271",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "gax2scrrbr",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 31321,
                      "fbc": "WCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "31321_37031",
                      "tax": [
                        {
                          "OT": 5710
                        }
                      ],
                      "tf": 37031
                    }
                  ],
                  "pr": {
                    "bf": 31321,
                    "tf": 37031,
                    "tax": [
                      {
                        "OT": 5710
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "31321_37031",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "7m99xd36mb",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            },
            {
              "tid": "paq5yRei8DmRr584_2",
              "ref": 2,
              "ofcid": "",
              "sdur": "3:55",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DEL",
                  "dd": "2023-06-30T22:45:00",
                  "ad": "2023-07-01T01:00:00",
                  "rbd": "",
                  "fn": "6146",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "1"
                  },
                  "rph": "",
                  "jsell": "6E~6146~ ~~HYD~06/30/2023 22:45~DEL~07/01/2023 01:00~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                },
                {
                  "ref": 2,
                  "da": "DEL",
                  "ar": "DOH",
                  "dd": "2023-07-01T04:05:00",
                  "ad": "2023-07-01T05:40:00",
                  "rbd": "",
                  "fn": "1307",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "3"
                  },
                  "rph": "",
                  "jsell": "6E~1307~ ~~DEL~07/01/2023 04:05~DOH~07/01/2023 05:40~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 30621,
                      "fbc": "WCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "30621_36271",
                      "tax": [
                        {
                          "OT": 5650
                        }
                      ],
                      "tf": 36271
                    }
                  ],
                  "pr": {
                    "bf": 30621,
                    "tf": 36271,
                    "tax": [
                      {
                        "OT": 5650
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "30621_36271",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "gwxk0onvtt",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 31321,
                      "fbc": "WCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "31321_37031",
                      "tax": [
                        {
                          "OT": 5710
                        }
                      ],
                      "tf": 37031
                    }
                  ],
                  "pr": {
                    "bf": 31321,
                    "tf": 37031,
                    "tax": [
                      {
                        "OT": 5710
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "31321_37031",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "2rn5ocw0eo",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            }
          ],
          "paxpr": [
            {
              "bf": 48150,
              "tf": 57577,
              "fbc": "",
              "psup_fare": "",
              "tax": [
                [
                  {
                    "OT": 3777
                  }
                ],
                [
                  {
                    "OT": 5650
                  }
                ]
              ],
              "ptype": "ADT",
              "scur": "INR",
              "sup_cur_fare": ""
            }
          ],
          "pr": {
            "bf": 17529,
            "tf": 21306,
            "tax": [
              {
                "OT": 3777
              },
              {
                "OT": 5650
              }
            ],
            "scur": "INR",
            "sup_fare": "",
            "sup_cur_fare": "",
            "cabb": "",
            "chekb": "",
            "seats": "",
            "meals": "",
            "chanb": "",
            "ftyp": ""
          },
          "sup": "4",
          "airlineList": "6E"
        },
        {
          "srcorigns": [
            {
              "tid": "4fx2rHrt8eiu6dlO_1",
              "ref": 1,
              "ofcid": "",
              "sdur": "08:15",
              "re": "",
              "sup": "3",
              "ft": "",
              "cl": "Economy",
              "tecst": "no",
              "tktair": "J9",
              "seg": [
                {
                  "ref": 1,
                  "da": "DOH",
                  "ar": "KWI",
                  "dd": "2023-06-29T15:15:00",
                  "ad": "2023-06-29T16:40:00",
                  "rbd": "",
                  "fn": "116",
                  "eq": "",
                  "ac": "J9",
                  "ma": "J9",
                  "seat": "",
                  "oa": "J9",
                  "fa": [],
                  "segmentKey": "Sjl_IDExNn4gfn5ET0h_MDYvMjkvMjAyMyAxNToxNX5LV0l_MDYvMjkvMjAyMyAxNjo0MH5_",
                  "terminal": {
                    "dep": null,
                    "arr": "5"
                  },
                  "durationHrs": "1",
                  "durationMns": "25",
                  "cl": "Economy"
                },
                {
                  "ref": 2,
                  "da": "KWI",
                  "ar": "HYD",
                  "dd": "2023-06-29T18:45:00",
                  "ad": "2023-06-30T02:00:00",
                  "rbd": "",
                  "fn": "403",
                  "eq": "",
                  "ac": "J9",
                  "ma": "J9",
                  "seat": "",
                  "oa": "J9",
                  "fa": [],
                  "segmentKey": "Sjl_IDQwM34gfn5LV0l_MDYvMjkvMjAyMyAxODo0NX5IWUR_MDYvMzAvMjAyMyAwMjowMH5_",
                  "terminal": {
                    "dep": "5",
                    "arr": null
                  },
                  "durationHrs": "7",
                  "durationMns": "15",
                  "cl": "Economy"
                }
              ],
              "fareop": [
                {
                  "pr": {
                    "tf": 9623.04,
                    "bf": 7273.28,
                    "tax": [
                      {
                        "OT": 2349.760000000001
                      }
                    ],
                    "scur": "QAR",
                    "sup_fare": "7273.28_9623.04",
                    "sup_cur_fare": "324.7_104.9_429.6",
                    "ucur": "INR",
                    "exchange_rate": 22.4,
                    "ftyp": "Light",
                    "cabb": "NIL",
                    "chekb": "7 KG/1 PIECE ",
                    "meals": "For a Fee",
                    "puBag": "Available",
                    "puBag_info": "Only available at Check-in",
                    "chanb": "Available up to 8 hours prior to\n            scheduled departure.",
                    "chanb_info": "Online: INR 5409 (or equivalent) fee\n            per sector per Guest up to 24 hours of\n            flight departure.\n             Online: INR 4868 (or equivalent) fee\n            per sector per Guest within 1-3 days of\n            flight departure.\n             Online: INR 4057 (or equivalent) fee\n            per sector per Guest if modified more\n            than 4 days of flight departure.\n             Plus any Fare Difference ",
                    "canb": "Available up to 24 hours prior to\n             scheduled departure.",
                    "cabb_info": "Non-refundable fare. Only applicable\n            taxes are refunded.\n             Remaining balance held in\n            Credit for 1 year to be used by the\n            booking holder only."
                  },
                  "paxpr": [
                    {
                      "bf": 7273.28,
                      "tf": 9623.04,
                      "fbc": "P",
                      "psup_fare": "7273.28_9623.04",
                      "pusup_fare": "324.7_429.6",
                      "tax": [
                        {
                          "OT": 2349.76
                        }
                      ],
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_cur_fare": "324.7_104.9_429.6"
                    }
                  ],
                  "routeId": "20ztxm98n0h",
                  "servicefee": [],
                  "selected": true,
                  "seats_avail": 9,
                  "refundable": false,
                  "aj_fare_key": "MH5FfiB_Sjl_RUxTUE9XUUF_TEUwMH5_MH45fn5YITA-"
                },
                {
                  "pr": {
                    "tf": 10743.04,
                    "bf": 8393.28,
                    "tax": [
                      {
                        "OT": 2349.76
                      }
                    ],
                    "scur": "QAR",
                    "sup_fare": "8393.28_10743.04",
                    "sup_cur_fare": "374.7_104.9_479.6",
                    "ucur": "INR",
                    "exchange_rate": 22.4,
                    "ftyp": "Value",
                    "cabb": "20 Kg / 1 Piece",
                    "chekb": "7 KG/1 PIECE ",
                    "meals": "For a Fee",
                    "puBag": "Available",
                    "puBag_info": "Pre-purchase 10KG online",
                    "chanb": "Available up to 8 hours prior to\n            scheduled departure.",
                    "chanb_info": "Online: INR 5409 (or equivalent) fee\n            per sector per Guest up to 24 hours of\n            flight departure.\n             Online: INR 4868 (or equivalent) fee\n            per sector per Guest within 1-3 days of\n            flight departure.\n             Online: INR 4057 (or equivalent) fee\n            per sector per Guest if modified more\n            than 4 days of flight departure.\n             Plus any Fare Difference ",
                    "canb": "Available up to 24 hours prior to\n             scheduled departure.",
                    "cabb_info": "Online: INR 5950 (or equivalent)\n             fee per sector per Guest up to 24\n             hours of flight departure.\n              Online: INR 5409 (or equivalent)\n             fee per sector per Guest within 1-3\n             days of flight departure.\n              Online: INR 4598 (or equivalent)\n             fee per sector per Guest if modified\n             more than 4 days of flight departure."
                  },
                  "paxpr": [
                    {
                      "bf": 8393.28,
                      "tf": 10743.04,
                      "fbc": "P",
                      "psup_fare": "8393.28_10743.04",
                      "pusup_fare": "374.7_479.6",
                      "tax": [
                        {
                          "OT": 2349.76
                        }
                      ],
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_cur_fare": "374.7_104.9_479.6"
                    }
                  ],
                  "routeId": "9yl6aj8z4v",
                  "servicefee": [],
                  "selected": false,
                  "seats_avail": 9,
                  "refundable": true,
                  "aj_fare_key": "MH5CfiB_Sjl_QkxTUE9XUUF_VkUyMH5_MH4zfn5YITE-"
                },
                {
                  "pr": {
                    "tf": 11863.04,
                    "bf": 9513.28,
                    "tax": [
                      {
                        "OT": 2349.76
                      }
                    ],
                    "scur": "QAR",
                    "sup_fare": "9513.28_11863.04",
                    "sup_cur_fare": "424.7_104.9_529.6",
                    "ucur": "INR",
                    "exchange_rate": 22.4,
                    "ftyp": "Extra",
                    "cabb": "30 Kg / 2 Pieces",
                    "chekb": "7 KG/1 PIECE ",
                    "meals": "For a Fee",
                    "puBag": "Available",
                    "puBag_info": "Pre-purchase 10KG online",
                    "chanb": "Available up to 8 hours prior to\n            scheduled departure.",
                    "chanb_info": "Online: INR 4868 (or equivalent) fee\n            per sector per Guest up to 24 hours of\n            flight departure.\n             Online: INR 4328 (or equivalent) fee\n            per sector per Guest within 1-3 days of\n            flight departure.\n             Online: INR 3516 (or equivalent) fee\n            per sector per Guest if modified more\n            than 4 days of flight departure.\n             Plus any Fare Difference ",
                    "canb": "Available up to 24 hours prior to\n             scheduled departure.",
                    "cabb_info": "Online: INR 5409 (or equivalent)\n             fee per sector per Guest up to 24 hours\n             of flight departure.\n             Online: INR 4868 (or equivalent)\n             fee per sector per Guest within 1-3\n             days of flight departure.\n             Online: INR 4057 (or equivalent)\n             fee per sector per Guest if modified\n             more than 4 days of flight departure"
                  },
                  "paxpr": [
                    {
                      "bf": 9513.28,
                      "tf": 11863.04,
                      "fbc": "P",
                      "psup_fare": "9513.28_11863.04",
                      "pusup_fare": "424.7_529.6",
                      "tax": [
                        {
                          "OT": 2349.76
                        }
                      ],
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_cur_fare": "424.7_104.9_529.6"
                    }
                  ],
                  "routeId": "6x6j4p53dc",
                  "servicefee": [],
                  "selected": false,
                  "seats_avail": 9,
                  "refundable": true,
                  "aj_fare_key": "MH5LfiB_Sjl_S0xTUE9XUUF_WEUzMH5_MH40fn5YITI-"
                }
              ],
              "prouteId": "20ztxm98n0h",
              "stop": 1,
              "journeyKey": "Sjl_IDExNn4gfn5ET0h_MDYvMjkvMjAyMyAxNToxNX5LV0l_MDYvMjkvMjAyMyAxNjo0MH5_Xko5fiA0MDN_IH5_S1dJfjA2LzI5LzIwMjMgMTg6NDV_SFlEfjA2LzMwLzIwMjMgMDI6MDB_fg--",
              "paxpr": [
                {
                  "bf": 7273.28,
                  "tf": 9623.04,
                  "fbc": "P",
                  "psup_fare": "7273.28_9623.04",
                  "pusup_fare": "324.7_429.6",
                  "tax": [
                    {
                      "OT": 2349.76
                    }
                  ],
                  "ptype": "ADT",
                  "scur": "QAR",
                  "sup_cur_fare": "324.7_104.9_429.6"
                }
              ],
              "pr": {
                "tf": 9623.04,
                "bf": 7273.28,
                "tax": [
                  {
                    "OT": 2349.760000000001
                  }
                ],
                "scur": "QAR",
                "sup_fare": "7273.28_9623.04",
                "sup_cur_fare": "324.7_104.9_429.6",
                "ucur": "INR",
                "exchange_rate": 22.4,
                "ftyp": "Light",
                "cabb": "NIL",
                "chekb": "7 KG/1 PIECE ",
                "meals": "For a Fee",
                "puBag": "Available",
                "puBag_info": "Only available at Check-in",
                "chanb": "Available up to 8 hours prior to\n            scheduled departure.",
                "chanb_info": "Online: INR 5409 (or equivalent) fee\n            per sector per Guest up to 24 hours of\n            flight departure.\n             Online: INR 4868 (or equivalent) fee\n            per sector per Guest within 1-3 days of\n            flight departure.\n             Online: INR 4057 (or equivalent) fee\n            per sector per Guest if modified more\n            than 4 days of flight departure.\n             Plus any Fare Difference ",
                "canb": "Available up to 24 hours prior to\n             scheduled departure.",
                "cabb_info": "Non-refundable fare. Only applicable\n            taxes are refunded.\n             Remaining balance held in\n            Credit for 1 year to be used by the\n            booking holder only."
              }
            }
          ],
          "destorigins": [
            {
              "tid": "PLBA29xGTttzyO4y_1",
              "ref": 1,
              "sdur": "09:25",
              "re": "Refundable Before Departure",
              "stop": 1,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "AI",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DEL",
                  "dd": "2023-06-30T14:30:00",
                  "ad": "2023-06-30T16:55:00",
                  "rbd": "Q",
                  "fn": "523",
                  "eq": "319",
                  "ac": "AI",
                  "ma": "AI",
                  "seat": "1",
                  "oa": "AI",
                  "fa": [],
                  "fdur": "02:25",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": "3"
                  }
                },
                {
                  "ref": 2,
                  "da": "DEL",
                  "ar": "DOH",
                  "dd": "2023-06-30T19:50:00",
                  "ad": "2023-06-30T21:25:00",
                  "rbd": "U",
                  "fn": "971",
                  "trn": "3",
                  "eq": "32N",
                  "ac": "AI",
                  "ma": "AI",
                  "seat": "2",
                  "oa": "AI",
                  "fa": [],
                  "fdur": "04:05",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "3",
                    "arr": ""
                  }
                }
              ],
              "flightNumbers": "523/971",
              "bgd": [
                {
                  "qun": "30",
                  "wt": "kg"
                }
              ],
              "paxpr": [
                {
                  "tf": 16576,
                  "tax": [
                    {
                      "YR": 448
                    },
                    {
                      "YR": 224
                    },
                    {
                      "OT": 4032
                    }
                  ],
                  "bf": 11872,
                  "fbc": "UOWDLDH",
                  "fareType": "RP",
                  "pusup_fare": "530_740",
                  "psup_fare": "11872_16576",
                  "ptype": "ADT"
                }
              ],
              "pr": {
                "bf": 11872,
                "tf": 16576,
                "tax": [
                  {
                    "YR": 448
                  },
                  {
                    "YR": 224
                  },
                  {
                    "OT": 4032
                  }
                ],
                "scur": "QAR",
                "ucur": "INR",
                "exchange_rate": 22.4,
                "sup_fare": "530_740"
              }
            }
          ],
          "pr": {
            "bf": 19145,
            "tf": 26199,
            "tax": [
              {
                "OT": 2349.760000000001
              },
              {
                "YR": 448
              },
              {
                "YR": 224
              },
              {
                "OT": 4032
              }
            ]
          },
          "paxpr": [
            {
              "bf": 19145.28,
              "tf": 26199.04,
              "tax": [
                {
                  "OT": 2349.76
                },
                {
                  "YR": 448
                },
                {
                  "YR": 224
                },
                {
                  "OT": 4032
                }
              ]
            }
          ],
          "sty": "split",
          "sup": "3/0",
          "airlineList": "J9_AI",
          "mix_carrier": true
        },
        {
          "srcorigns": [
            {
              "tid": "CVG55qoXJGs2sUmS_1",
              "ref": 1,
              "sdur": "08:20",
              "re": "Refundable Before Departure",
              "stop": 1,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "AI",
              "seg": [
                {
                  "ref": 1,
                  "da": "DOH",
                  "ar": "DEL",
                  "dd": "2023-06-29T22:35:00",
                  "ad": "2023-06-30T05:05:00",
                  "rbd": "L",
                  "fn": "972",
                  "eq": "32N",
                  "ac": "AI",
                  "ma": "AI",
                  "seat": "1",
                  "oa": "AI",
                  "fa": [],
                  "fdur": "04:00",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": "3"
                  }
                },
                {
                  "ref": 2,
                  "da": "DEL",
                  "ar": "HYD",
                  "dd": "2023-06-30T07:10:00",
                  "ad": "2023-06-30T09:25:00",
                  "rbd": "Q",
                  "fn": "560",
                  "trn": "3",
                  "eq": "32N",
                  "ac": "AI",
                  "ma": "AI",
                  "seat": "9",
                  "oa": "AI",
                  "fa": [],
                  "fdur": "02:15",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "3",
                    "arr": ""
                  }
                }
              ],
              "flightNumbers": "972/560",
              "bgd": [
                {
                  "qun": "30",
                  "wt": "kg"
                }
              ]
            },
            {
              "tid": "8i2Q6kh5Ab7OVEpo_1",
              "ref": 1,
              "sdur": "10:45",
              "re": "Refundable Before Departure",
              "stop": 1,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "AI",
              "seg": [
                {
                  "ref": 1,
                  "da": "DOH",
                  "ar": "DEL",
                  "dd": "2023-06-29T22:35:00",
                  "ad": "2023-06-30T05:05:00",
                  "rbd": "L",
                  "fn": "972",
                  "eq": "32N",
                  "ac": "AI",
                  "ma": "AI",
                  "seat": "1",
                  "oa": "AI",
                  "fa": [],
                  "fdur": "04:00",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": "3"
                  }
                },
                {
                  "ref": 2,
                  "da": "DEL",
                  "ar": "HYD",
                  "dd": "2023-06-30T09:45:00",
                  "ad": "2023-06-30T11:50:00",
                  "rbd": "Q",
                  "fn": "542",
                  "trn": "3",
                  "eq": "321",
                  "ac": "AI",
                  "ma": "AI",
                  "seat": "9",
                  "oa": "AI",
                  "fa": [],
                  "fdur": "02:05",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "3",
                    "arr": ""
                  }
                }
              ],
              "flightNumbers": "972/542",
              "bgd": [
                {
                  "qun": "30",
                  "wt": "kg"
                }
              ]
            }
          ],
          "destorigins": [
            {
              "tid": "CVG55qoXJGs2sUmS_2",
              "ref": 2,
              "sdur": "09:25",
              "re": "Refundable Before Departure",
              "stop": 1,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "AI",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DEL",
                  "dd": "2023-06-30T14:30:00",
                  "ad": "2023-06-30T16:55:00",
                  "rbd": "Q",
                  "fn": "523",
                  "eq": "319",
                  "ac": "AI",
                  "ma": "AI",
                  "seat": "1",
                  "oa": "AI",
                  "fa": [],
                  "fdur": "02:25",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": "3"
                  }
                },
                {
                  "ref": 2,
                  "da": "DEL",
                  "ar": "DOH",
                  "dd": "2023-06-30T19:50:00",
                  "ad": "2023-06-30T21:25:00",
                  "rbd": "U",
                  "fn": "971",
                  "trn": "3",
                  "eq": "32N",
                  "ac": "AI",
                  "ma": "AI",
                  "seat": "2",
                  "oa": "AI",
                  "fa": [],
                  "fdur": "04:05",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "3",
                    "arr": ""
                  }
                }
              ],
              "flightNumbers": "523/971",
              "bgd": [
                {
                  "qun": "30",
                  "wt": "kg"
                }
              ]
            }
          ],
          "pr": {
            "bf": 21056,
            "tf": 28896,
            "tax": [
              {
                "YR": 896
              },
              {
                "YR": 448
              },
              {
                "OT": 6496
              }
            ],
            "scur": "QAR",
            "ucur": "INR",
            "exchange_rate": 22.4,
            "sup_fare": "940_1290"
          },
          "paxpr": [
            {
              "tf": 28896,
              "tax": [
                {
                  "YR": 896
                },
                {
                  "YR": 448
                },
                {
                  "OT": 6496
                }
              ],
              "bf": 21056,
              "fbc": "L6MDHDL",
              "fareType": "RP",
              "pusup_fare": "940_1290",
              "psup_fare": "21056_28896",
              "ptype": "ADT"
            }
          ],
          "sup": "0",
          "airlinesList": "AI"
        },
        {
          "srcorigns": [
            {
              "tid": "nJdFQaXcn3uJroUd_1",
              "ref": 1,
              "sdur": "08:20",
              "re": "Refundable Before Departure",
              "stop": 1,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "AI",
              "seg": [
                {
                  "ref": 1,
                  "da": "DOH",
                  "ar": "DEL",
                  "dd": "2023-06-29T22:35:00",
                  "ad": "2023-06-30T05:05:00",
                  "rbd": "L",
                  "fn": "972",
                  "eq": "32N",
                  "ac": "AI",
                  "ma": "AI",
                  "seat": "1",
                  "oa": "AI",
                  "fa": [],
                  "fdur": "04:00",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": "3"
                  }
                },
                {
                  "ref": 2,
                  "da": "DEL",
                  "ar": "HYD",
                  "dd": "2023-06-30T07:10:00",
                  "ad": "2023-06-30T09:25:00",
                  "rbd": "Q",
                  "fn": "560",
                  "trn": "3",
                  "eq": "32N",
                  "ac": "AI",
                  "ma": "AI",
                  "seat": "9",
                  "oa": "AI",
                  "fa": [],
                  "fdur": "02:15",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "3",
                    "arr": ""
                  }
                }
              ],
              "flightNumbers": "972/560",
              "bgd": [
                {
                  "qun": "30",
                  "wt": "kg"
                }
              ]
            },
            {
              "tid": "skntV4fTndZoDsI6_1",
              "ref": 1,
              "sdur": "10:45",
              "re": "Refundable Before Departure",
              "stop": 1,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "AI",
              "seg": [
                {
                  "ref": 1,
                  "da": "DOH",
                  "ar": "DEL",
                  "dd": "2023-06-29T22:35:00",
                  "ad": "2023-06-30T05:05:00",
                  "rbd": "L",
                  "fn": "972",
                  "eq": "32N",
                  "ac": "AI",
                  "ma": "AI",
                  "seat": "1",
                  "oa": "AI",
                  "fa": [],
                  "fdur": "04:00",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": "3"
                  }
                },
                {
                  "ref": 2,
                  "da": "DEL",
                  "ar": "HYD",
                  "dd": "2023-06-30T09:45:00",
                  "ad": "2023-06-30T11:50:00",
                  "rbd": "Q",
                  "fn": "542",
                  "trn": "3",
                  "eq": "321",
                  "ac": "AI",
                  "ma": "AI",
                  "seat": "9",
                  "oa": "AI",
                  "fa": [],
                  "fdur": "02:05",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "3",
                    "arr": ""
                  }
                }
              ],
              "flightNumbers": "972/542",
              "bgd": [
                {
                  "qun": "30",
                  "wt": "kg"
                }
              ]
            }
          ],
          "destorigins": [
            {
              "tid": "nJdFQaXcn3uJroUd_2",
              "ref": 2,
              "sdur": "27:15",
              "re": "Refundable Before Departure",
              "stop": 1,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "AI",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DEL",
                  "dd": "2023-06-30T20:40:00",
                  "ad": "2023-06-30T23:00:00",
                  "rbd": "Q",
                  "fn": "840",
                  "eq": "32N",
                  "ac": "AI",
                  "ma": "AI",
                  "seat": "9",
                  "oa": "AI",
                  "fa": [],
                  "fdur": "02:20",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": "3"
                  }
                },
                {
                  "ref": 2,
                  "da": "DEL",
                  "ar": "DOH",
                  "dd": "2023-07-01T19:50:00",
                  "ad": "2023-07-01T21:25:00",
                  "rbd": "U",
                  "fn": "971",
                  "trn": "3",
                  "eq": "32N",
                  "ac": "AI",
                  "ma": "AI",
                  "seat": "1",
                  "oa": "AI",
                  "fa": [],
                  "fdur": "04:05",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "3",
                    "arr": ""
                  }
                }
              ],
              "flightNumbers": "840/971",
              "bgd": [
                {
                  "qun": "30",
                  "wt": "kg"
                }
              ]
            }
          ],
          "pr": {
            "bf": 21056,
            "tf": 28896,
            "tax": [
              {
                "YR": 896
              },
              {
                "YR": 448
              },
              {
                "OT": 6496
              }
            ],
            "scur": "QAR",
            "ucur": "INR",
            "exchange_rate": 22.4,
            "sup_fare": "940_1290"
          },
          "paxpr": [
            {
              "tf": 28896,
              "tax": [
                {
                  "YR": 896
                },
                {
                  "YR": 448
                },
                {
                  "OT": 6496
                }
              ],
              "bf": 21056,
              "fbc": "L6MDHDL",
              "fareType": "RP",
              "pusup_fare": "940_1290",
              "psup_fare": "21056_28896",
              "ptype": "ADT"
            }
          ],
          "sup": "0",
          "airlinesList": "AI"
        },
        {
          "srcorigns": [
            {
              "tid": "kSWD1R3LvfRyNlz6_1",
              "ref": 1,
              "ofcid": "",
              "sdur": "14:30",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "DOH",
                  "ar": "MAA",
                  "dd": "2023-06-29T02:00:00",
                  "ad": "2023-06-29T09:05:00",
                  "rbd": "",
                  "fn": "1322",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "3"
                  },
                  "rph": "",
                  "jsell": "6E~1322~ ~~DOH~06/29/2023 02:00~MAA~06/29/2023 09:05~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                },
                {
                  "ref": 2,
                  "da": "MAA",
                  "ar": "HYD",
                  "dd": "2023-06-29T12:20:00",
                  "ad": "2023-06-29T13:30:00",
                  "rbd": "",
                  "fn": " 604",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "1"
                  },
                  "rph": "",
                  "jsell": "6E~ 604~ ~~MAA~06/29/2023 12:20~HYD~06/29/2023 13:30~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~R~ ~6E~RCINT~6200~~0~65~~X^0~R~ ~6E~RCINT~6200~~0~65~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 9819,
                      "fbc": "RCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "9819_13326",
                      "tax": [
                        {
                          "OT": 3507
                        }
                      ],
                      "tf": 13326
                    }
                  ],
                  "pr": {
                    "bf": 9819,
                    "tf": 13326,
                    "tax": [
                      {
                        "OT": 3507
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "9819_13326",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "3ejfgmtsn3",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~R~ ~6E~RCUINT~6202~~0~4~~X^0~R~ ~6E~RCUINT~6202~~0~4~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 10519,
                      "fbc": "RCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "10519_14050",
                      "tax": [
                        {
                          "OT": 3531
                        }
                      ],
                      "tf": 14050
                    }
                  ],
                  "pr": {
                    "bf": 10519,
                    "tf": 14050,
                    "tax": [
                      {
                        "OT": 3531
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "10519_14050",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "asnla9i2l0l",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "Qjaaeqrl1XE=|aQFezKSWQkTEFuzy1c/XiE8E1gCe1mp7wL3TnlV5DA+x56hxCT+7ZWP6pBbfk21i1VpV7qcOGfUZINz5cX0sMPO7zDGGtcxXeJ5RNOP3ZplH1FG3CBzrsOYD796VyuQ29DfNwKuxQhE=",
              "stop": 1,
              "paxpr": [
                {
                  "bf": 9819,
                  "tf": 13326,
                  "fbc": "",
                  "psup_fare": "",
                  "tax": [
                    [
                      {
                        "OT": 3507
                      }
                    ]
                  ],
                  "ptype": "ADT",
                  "scur": "INR",
                  "sup_cur_fare": ""
                }
              ],
              "pr": {
                "bf": 9819,
                "tf": 13326,
                "tax": [
                  {
                    "OT": 3507
                  }
                ],
                "scur": "INR",
                "sup_fare": "",
                "sup_cur_fare": "",
                "cabb": "",
                "chekb": "",
                "seats": "",
                "meals": "",
                "chanb": "",
                "ftyp": ""
              }
            },
            {
              "tid": "T23thK491dFfh7nV_1",
              "ref": 1,
              "ofcid": "",
              "sdur": "19:15",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "DOH",
                  "ar": "MAA",
                  "dd": "2023-06-29T02:00:00",
                  "ad": "2023-06-29T09:05:00",
                  "rbd": "",
                  "fn": "1322",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "3"
                  },
                  "rph": "",
                  "jsell": "6E~1322~ ~~DOH~06/29/2023 02:00~MAA~06/29/2023 09:05~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                },
                {
                  "ref": 2,
                  "da": "MAA",
                  "ar": "HYD",
                  "dd": "2023-06-29T17:00:00",
                  "ad": "2023-06-29T18:15:00",
                  "rbd": "",
                  "fn": " 562",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "1"
                  },
                  "rph": "",
                  "jsell": "6E~ 562~ ~~MAA~06/29/2023 17:00~HYD~06/29/2023 18:15~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~R~ ~6E~RCINT~6200~~0~65~~X^0~R~ ~6E~RCINT~6200~~0~65~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 9819,
                      "fbc": "RCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "9819_13326",
                      "tax": [
                        {
                          "OT": 3507
                        }
                      ],
                      "tf": 13326
                    }
                  ],
                  "pr": {
                    "bf": 9819,
                    "tf": 13326,
                    "tax": [
                      {
                        "OT": 3507
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "9819_13326",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "iuz56zp12fi",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~R~ ~6E~RCUINT~6202~~0~4~~X^0~R~ ~6E~RCUINT~6202~~0~4~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 10519,
                      "fbc": "RCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "10519_14050",
                      "tax": [
                        {
                          "OT": 3531
                        }
                      ],
                      "tf": 14050
                    }
                  ],
                  "pr": {
                    "bf": 10519,
                    "tf": 14050,
                    "tax": [
                      {
                        "OT": 3531
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "10519_14050",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "fixyzs51cr",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "Qjaaeqrl1XE=|aQFezKSWQkTEFuzy1c/XiE8E1gCe1mp7wL3TnlV5DA+x56hxCT+7ZWP6pBbfk21i1VpV7qcOGfUZINz5cX0sMPO7zDGGtcxXeJ5RNOP3ZplH1FG3CBzrsOYD796VyuQ29DfNwKuxQhE=",
              "stop": 1,
              "paxpr": [
                {
                  "bf": 9819,
                  "tf": 13326,
                  "fbc": "",
                  "psup_fare": "",
                  "tax": [
                    [
                      {
                        "OT": 3507
                      }
                    ]
                  ],
                  "ptype": "ADT",
                  "scur": "INR",
                  "sup_cur_fare": ""
                }
              ],
              "pr": {
                "bf": 9819,
                "tf": 13326,
                "tax": [
                  {
                    "OT": 3507
                  }
                ],
                "scur": "INR",
                "sup_fare": "",
                "sup_cur_fare": "",
                "cabb": "",
                "chekb": "",
                "seats": "",
                "meals": "",
                "chanb": "",
                "ftyp": ""
              }
            },
            {
              "tid": "SiX2sy67k87wvIc4_1",
              "ref": 1,
              "ofcid": "",
              "sdur": "18:10",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "DOH",
                  "ar": "MAA",
                  "dd": "2023-06-29T02:00:00",
                  "ad": "2023-06-29T09:05:00",
                  "rbd": "",
                  "fn": "1322",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "3"
                  },
                  "rph": "",
                  "jsell": "6E~1322~ ~~DOH~06/29/2023 02:00~MAA~06/29/2023 09:05~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                },
                {
                  "ref": 2,
                  "da": "MAA",
                  "ar": "HYD",
                  "dd": "2023-06-29T16:00:00",
                  "ad": "2023-06-29T17:10:00",
                  "rbd": "",
                  "fn": "6401",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "1"
                  },
                  "rph": "",
                  "jsell": "6E~6401~ ~~MAA~06/29/2023 16:00~HYD~06/29/2023 17:10~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~R~ ~6E~RCINT~6200~~0~65~~X^0~R~ ~6E~RCINT~6200~~0~65~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 9819,
                      "fbc": "RCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "9819_13326",
                      "tax": [
                        {
                          "OT": 3507
                        }
                      ],
                      "tf": 13326
                    }
                  ],
                  "pr": {
                    "bf": 9819,
                    "tf": 13326,
                    "tax": [
                      {
                        "OT": 3507
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "9819_13326",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "ouof5d7tea",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~R~ ~6E~RCUINT~6202~~0~4~~X^0~R~ ~6E~RCUINT~6202~~0~4~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 10519,
                      "fbc": "RCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "10519_14050",
                      "tax": [
                        {
                          "OT": 3531
                        }
                      ],
                      "tf": 14050
                    }
                  ],
                  "pr": {
                    "bf": 10519,
                    "tf": 14050,
                    "tax": [
                      {
                        "OT": 3531
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "10519_14050",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "1b289r59s",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "Qjaaeqrl1XE=|aQFezKSWQkTEFuzy1c/XiE8E1gCe1mp7wL3TnlV5DA+x56hxCT+7ZWP6pBbfk21i1VpV7qcOGfUZINz5cX0sMPO7zDGGtcxXeJ5RNOP3ZplH1FG3CBzrsOYD796VyuQ29DfNwKuxQhE=",
              "stop": 1,
              "paxpr": [
                {
                  "bf": 9819,
                  "tf": 13326,
                  "fbc": "",
                  "psup_fare": "",
                  "tax": [
                    [
                      {
                        "OT": 3507
                      }
                    ]
                  ],
                  "ptype": "ADT",
                  "scur": "INR",
                  "sup_cur_fare": ""
                }
              ],
              "pr": {
                "bf": 9819,
                "tf": 13326,
                "tax": [
                  {
                    "OT": 3507
                  }
                ],
                "scur": "INR",
                "sup_fare": "",
                "sup_cur_fare": "",
                "cabb": "",
                "chekb": "",
                "seats": "",
                "meals": "",
                "chanb": "",
                "ftyp": ""
              }
            },
            {
              "tid": "FU3drl2wGMBptpID_1",
              "ref": 1,
              "ofcid": "",
              "sdur": "16:0",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "DOH",
                  "ar": "DEL",
                  "dd": "2023-06-29T19:40:00",
                  "ad": "2023-06-30T02:00:00",
                  "rbd": "",
                  "fn": "1308",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "3"
                  },
                  "rph": "",
                  "jsell": "6E~1308~ ~~DOH~06/29/2023 19:40~DEL~06/30/2023 02:00~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                },
                {
                  "ref": 2,
                  "da": "DEL",
                  "ar": "HYD",
                  "dd": "2023-06-30T06:30:00",
                  "ad": "2023-06-30T08:40:00",
                  "rbd": "",
                  "fn": "6203",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "1"
                  },
                  "rph": "",
                  "jsell": "6E~6203~ ~~DEL~06/30/2023 06:30~HYD~06/30/2023 08:40~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~R~ ~6E~RCINT~6200~~0~65~~X^0~R~ ~6E~RCINT~6200~~0~65~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 9819,
                      "fbc": "RCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "9819_13326",
                      "tax": [
                        {
                          "OT": 3507
                        }
                      ],
                      "tf": 13326
                    }
                  ],
                  "pr": {
                    "bf": 9819,
                    "tf": 13326,
                    "tax": [
                      {
                        "OT": 3507
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "9819_13326",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "1ce4t0ev0o",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~R~ ~6E~RCUINT~6202~~0~4~~X^0~R~ ~6E~RCUINT~6202~~0~4~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 10519,
                      "fbc": "RCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "10519_14050",
                      "tax": [
                        {
                          "OT": 3531
                        }
                      ],
                      "tf": 14050
                    }
                  ],
                  "pr": {
                    "bf": 10519,
                    "tf": 14050,
                    "tax": [
                      {
                        "OT": 3531
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "10519_14050",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "1aosvbrps9g",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "Qjaaeqrl1XE=|aQFezKSWQkTEFuzy1c/XiE8E1gCe1mp7wL3TnlV5DA+x56hxCT+7ZWP6pBbfk21i1VpV7qcOGfUZINz5cX0sMPO7zDGGtcxXeJ5RNOP3ZplH1FG3CBzrsOYD796VyuQ29DfNwKuxQhE=",
              "stop": 1,
              "paxpr": [
                {
                  "bf": 9819,
                  "tf": 13326,
                  "fbc": "",
                  "psup_fare": "",
                  "tax": [
                    [
                      {
                        "OT": 3507
                      }
                    ]
                  ],
                  "ptype": "ADT",
                  "scur": "INR",
                  "sup_cur_fare": ""
                }
              ],
              "pr": {
                "bf": 9819,
                "tf": 13326,
                "tax": [
                  {
                    "OT": 3507
                  }
                ],
                "scur": "INR",
                "sup_fare": "",
                "sup_cur_fare": "",
                "cabb": "",
                "chekb": "",
                "seats": "",
                "meals": "",
                "chanb": "",
                "ftyp": ""
              }
            }
          ],
          "destorigins": [
            {
              "tid": "PLBA29xGTttzyO4y_1",
              "ref": 1,
              "sdur": "09:25",
              "re": "Refundable Before Departure",
              "stop": 1,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "AI",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DEL",
                  "dd": "2023-06-30T14:30:00",
                  "ad": "2023-06-30T16:55:00",
                  "rbd": "Q",
                  "fn": "523",
                  "eq": "319",
                  "ac": "AI",
                  "ma": "AI",
                  "seat": "1",
                  "oa": "AI",
                  "fa": [],
                  "fdur": "02:25",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": "3"
                  }
                },
                {
                  "ref": 2,
                  "da": "DEL",
                  "ar": "DOH",
                  "dd": "2023-06-30T19:50:00",
                  "ad": "2023-06-30T21:25:00",
                  "rbd": "U",
                  "fn": "971",
                  "trn": "3",
                  "eq": "32N",
                  "ac": "AI",
                  "ma": "AI",
                  "seat": "2",
                  "oa": "AI",
                  "fa": [],
                  "fdur": "04:05",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "3",
                    "arr": ""
                  }
                }
              ],
              "flightNumbers": "523/971",
              "bgd": [
                {
                  "qun": "30",
                  "wt": "kg"
                }
              ],
              "paxpr": [
                {
                  "tf": 16576,
                  "tax": [
                    {
                      "YR": 448
                    },
                    {
                      "YR": 224
                    },
                    {
                      "OT": 4032
                    }
                  ],
                  "bf": 11872,
                  "fbc": "UOWDLDH",
                  "fareType": "RP",
                  "pusup_fare": "530_740",
                  "psup_fare": "11872_16576",
                  "ptype": "ADT"
                }
              ],
              "pr": {
                "bf": 11872,
                "tf": 16576,
                "tax": [
                  {
                    "YR": 448
                  },
                  {
                    "YR": 224
                  },
                  {
                    "OT": 4032
                  }
                ],
                "scur": "QAR",
                "ucur": "INR",
                "exchange_rate": 22.4,
                "sup_fare": "530_740"
              }
            }
          ],
          "pr": {
            "bf": 21691,
            "tf": 29902,
            "tax": [
              {
                "OT": 3507
              },
              {
                "YR": 448
              },
              {
                "YR": 224
              },
              {
                "OT": 4032
              }
            ]
          },
          "paxpr": [
            {
              "bf": 21691,
              "tf": 29902,
              "tax": [
                [
                  {
                    "OT": 3507
                  }
                ],
                {
                  "YR": 448
                },
                {
                  "YR": 224
                },
                {
                  "OT": 4032
                }
              ]
            }
          ],
          "sty": "split",
          "sup": "4/0",
          "airlineList": "6E_AI",
          "mix_carrier": true
        },
        {
          "srcorigns": [
            {
              "tid": "khftrfkzfIPv6GzA_1",
              "ref": 1,
              "sdur": "08:20",
              "re": "Non-Refundable",
              "stop": 1,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "AI",
              "seg": [
                {
                  "ref": 1,
                  "da": "DOH",
                  "ar": "DEL",
                  "dd": "2023-06-29T22:35:00",
                  "ad": "2023-06-30T05:05:00",
                  "rbd": "L",
                  "fn": "972",
                  "eq": "32N",
                  "ac": "AI",
                  "ma": "AI",
                  "seat": "1",
                  "oa": "AI",
                  "fa": [],
                  "fdur": "04:00",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": "3"
                  }
                },
                {
                  "ref": 2,
                  "da": "DEL",
                  "ar": "HYD",
                  "dd": "2023-06-30T07:10:00",
                  "ad": "2023-06-30T09:25:00",
                  "rbd": "Q",
                  "fn": "560",
                  "trn": "3",
                  "eq": "32N",
                  "ac": "AI",
                  "ma": "AI",
                  "seat": "9",
                  "oa": "AI",
                  "fa": [],
                  "fdur": "02:15",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "3",
                    "arr": ""
                  }
                }
              ],
              "flightNumbers": "972/560",
              "bgd": [
                {
                  "qun": "30",
                  "wt": "kg"
                }
              ]
            },
            {
              "tid": "aCUuIW1gR6SeaWLi_1",
              "ref": 1,
              "sdur": "10:45",
              "re": "Non-Refundable",
              "stop": 1,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "AI",
              "seg": [
                {
                  "ref": 1,
                  "da": "DOH",
                  "ar": "DEL",
                  "dd": "2023-06-29T22:35:00",
                  "ad": "2023-06-30T05:05:00",
                  "rbd": "L",
                  "fn": "972",
                  "eq": "32N",
                  "ac": "AI",
                  "ma": "AI",
                  "seat": "1",
                  "oa": "AI",
                  "fa": [],
                  "fdur": "04:00",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": "3"
                  }
                },
                {
                  "ref": 2,
                  "da": "DEL",
                  "ar": "HYD",
                  "dd": "2023-06-30T09:45:00",
                  "ad": "2023-06-30T11:50:00",
                  "rbd": "Q",
                  "fn": "542",
                  "trn": "3",
                  "eq": "321",
                  "ac": "AI",
                  "ma": "AI",
                  "seat": "9",
                  "oa": "AI",
                  "fa": [],
                  "fdur": "02:05",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "3",
                    "arr": ""
                  }
                }
              ],
              "flightNumbers": "972/542",
              "bgd": [
                {
                  "qun": "30",
                  "wt": "kg"
                }
              ]
            },
            {
              "tid": "5HP3z4s0qqze6QQi_1",
              "ref": 1,
              "sdur": "18:05",
              "re": "Non-Refundable",
              "stop": 1,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "AI",
              "seg": [
                {
                  "ref": 1,
                  "da": "DOH",
                  "ar": "DEL",
                  "dd": "2023-06-29T22:35:00",
                  "ad": "2023-06-30T05:05:00",
                  "rbd": "L",
                  "fn": "972",
                  "eq": "32N",
                  "ac": "AI",
                  "ma": "AI",
                  "seat": "1",
                  "oa": "AI",
                  "fa": [],
                  "fdur": "04:00",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": "3"
                  }
                },
                {
                  "ref": 2,
                  "da": "DEL",
                  "ar": "HYD",
                  "dd": "2023-06-30T16:50:00",
                  "ad": "2023-06-30T19:10:00",
                  "rbd": "Q",
                  "fn": "544",
                  "trn": "3",
                  "eq": "32N",
                  "ac": "AI",
                  "ma": "AI",
                  "seat": "9",
                  "oa": "AI",
                  "fa": [],
                  "fdur": "02:20",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "3",
                    "arr": ""
                  }
                }
              ],
              "flightNumbers": "972/544",
              "bgd": [
                {
                  "qun": "30",
                  "wt": "kg"
                }
              ]
            }
          ],
          "destorigins": [
            {
              "tid": "khftrfkzfIPv6GzA_2",
              "ref": 2,
              "sdur": "25:30",
              "re": "Non-Refundable",
              "stop": 2,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "AI",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "BOM",
                  "dd": "2023-06-30T22:25:00",
                  "ad": "2023-06-30T23:50:00",
                  "rbd": "U",
                  "fn": "698",
                  "eq": "319",
                  "ac": "AI",
                  "ma": "AI",
                  "seat": "9",
                  "oa": "AI",
                  "fa": [],
                  "fdur": "01:25",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": "2"
                  }
                },
                {
                  "ref": 2,
                  "da": "BOM",
                  "ar": "DEL",
                  "dd": "2023-07-01T13:00:00",
                  "ad": "2023-07-01T15:15:00",
                  "rbd": "Q",
                  "fn": "677",
                  "trn": "2",
                  "eq": "32N",
                  "ac": "AI",
                  "ma": "AI",
                  "seat": "9",
                  "oa": "AI",
                  "fa": [],
                  "fdur": "02:15",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "2",
                    "arr": "3"
                  }
                },
                {
                  "ref": 3,
                  "da": "DEL",
                  "ar": "DOH",
                  "dd": "2023-07-01T19:50:00",
                  "ad": "2023-07-01T21:25:00",
                  "rbd": "U",
                  "fn": "971",
                  "trn": "3",
                  "eq": "32N",
                  "ac": "AI",
                  "ma": "AI",
                  "seat": "1",
                  "oa": "AI",
                  "fa": [],
                  "fdur": "04:05",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "3",
                    "arr": ""
                  }
                }
              ],
              "flightNumbers": "698/677/971",
              "bgd": [
                {
                  "qun": "30",
                  "wt": "kg"
                }
              ]
            },
            {
              "tid": "28g91cvcFaoXO7T1_2",
              "ref": 2,
              "sdur": "25:30",
              "re": "Non-Refundable",
              "stop": 2,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "AI",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "BOM",
                  "dd": "2023-06-30T22:25:00",
                  "ad": "2023-06-30T23:50:00",
                  "rbd": "U",
                  "fn": "698",
                  "eq": "319",
                  "ac": "AI",
                  "ma": "AI",
                  "seat": "9",
                  "oa": "AI",
                  "fa": [],
                  "fdur": "01:25",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": "2"
                  }
                },
                {
                  "ref": 2,
                  "da": "BOM",
                  "ar": "DEL",
                  "dd": "2023-07-01T16:00:00",
                  "ad": "2023-07-01T18:20:00",
                  "rbd": "Q",
                  "fn": "687",
                  "trn": "2",
                  "eq": "32N",
                  "ac": "AI",
                  "ma": "AI",
                  "seat": "9",
                  "oa": "AI",
                  "fa": [],
                  "fdur": "02:20",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "2",
                    "arr": "3"
                  }
                },
                {
                  "ref": 3,
                  "da": "DEL",
                  "ar": "DOH",
                  "dd": "2023-07-01T19:50:00",
                  "ad": "2023-07-01T21:25:00",
                  "rbd": "U",
                  "fn": "971",
                  "trn": "3",
                  "eq": "32N",
                  "ac": "AI",
                  "ma": "AI",
                  "seat": "1",
                  "oa": "AI",
                  "fa": [],
                  "fdur": "04:05",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "3",
                    "arr": ""
                  }
                }
              ],
              "flightNumbers": "698/687/971",
              "bgd": [
                {
                  "qun": "30",
                  "wt": "kg"
                }
              ]
            },
            {
              "tid": "hQByZhXvaB3IlmPo_2",
              "ref": 2,
              "sdur": "25:30",
              "re": "Non-Refundable",
              "stop": 2,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "AI",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "BOM",
                  "dd": "2023-06-30T22:25:00",
                  "ad": "2023-06-30T23:50:00",
                  "rbd": "U",
                  "fn": "698",
                  "eq": "319",
                  "ac": "AI",
                  "ma": "AI",
                  "seat": "9",
                  "oa": "AI",
                  "fa": [],
                  "fdur": "01:25",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": "2"
                  }
                },
                {
                  "ref": 2,
                  "da": "BOM",
                  "ar": "DEL",
                  "dd": "2023-07-01T08:00:00",
                  "ad": "2023-07-01T10:15:00",
                  "rbd": "Q",
                  "fn": "806",
                  "trn": "2",
                  "eq": "321",
                  "ac": "AI",
                  "ma": "AI",
                  "seat": "9",
                  "oa": "AI",
                  "fa": [],
                  "fdur": "02:15",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "2",
                    "arr": "3"
                  }
                },
                {
                  "ref": 3,
                  "da": "DEL",
                  "ar": "DOH",
                  "dd": "2023-07-01T19:50:00",
                  "ad": "2023-07-01T21:25:00",
                  "rbd": "U",
                  "fn": "971",
                  "trn": "3",
                  "eq": "32N",
                  "ac": "AI",
                  "ma": "AI",
                  "seat": "1",
                  "oa": "AI",
                  "fa": [],
                  "fdur": "04:05",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "3",
                    "arr": ""
                  }
                }
              ],
              "flightNumbers": "698/806/971",
              "bgd": [
                {
                  "qun": "30",
                  "wt": "kg"
                }
              ]
            },
            {
              "tid": "GFHceS4XORZ0AH6a_2",
              "ref": 2,
              "sdur": "25:30",
              "re": "Non-Refundable",
              "stop": 2,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "AI",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "BOM",
                  "dd": "2023-06-30T22:25:00",
                  "ad": "2023-06-30T23:50:00",
                  "rbd": "U",
                  "fn": "698",
                  "eq": "319",
                  "ac": "AI",
                  "ma": "AI",
                  "seat": "9",
                  "oa": "AI",
                  "fa": [],
                  "fdur": "01:25",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": "2"
                  }
                },
                {
                  "ref": 2,
                  "da": "BOM",
                  "ar": "DEL",
                  "dd": "2023-07-01T10:00:00",
                  "ad": "2023-07-01T12:30:00",
                  "rbd": "Q",
                  "fn": "809",
                  "trn": "2",
                  "eq": "32N",
                  "ac": "AI",
                  "ma": "AI",
                  "seat": "9",
                  "oa": "AI",
                  "fa": [],
                  "fdur": "02:30",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "2",
                    "arr": "3"
                  }
                },
                {
                  "ref": 3,
                  "da": "DEL",
                  "ar": "DOH",
                  "dd": "2023-07-01T19:50:00",
                  "ad": "2023-07-01T21:25:00",
                  "rbd": "U",
                  "fn": "971",
                  "trn": "3",
                  "eq": "32N",
                  "ac": "AI",
                  "ma": "AI",
                  "seat": "1",
                  "oa": "AI",
                  "fa": [],
                  "fdur": "04:05",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "3",
                    "arr": ""
                  }
                }
              ],
              "flightNumbers": "698/809/971",
              "bgd": [
                {
                  "qun": "30",
                  "wt": "kg"
                }
              ]
            },
            {
              "tid": "74tNwUOaFgeB7Sm7_2",
              "ref": 2,
              "sdur": "25:30",
              "re": "Non-Refundable",
              "stop": 2,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "AI",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "BOM",
                  "dd": "2023-06-30T22:25:00",
                  "ad": "2023-06-30T23:50:00",
                  "rbd": "U",
                  "fn": "698",
                  "eq": "319",
                  "ac": "AI",
                  "ma": "AI",
                  "seat": "9",
                  "oa": "AI",
                  "fa": [],
                  "fdur": "01:25",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": "2"
                  }
                },
                {
                  "ref": 2,
                  "da": "BOM",
                  "ar": "DEL",
                  "dd": "2023-07-01T07:00:00",
                  "ad": "2023-07-01T09:05:00",
                  "rbd": "Q",
                  "fn": "864",
                  "trn": "2",
                  "eq": "32N",
                  "ac": "AI",
                  "ma": "AI",
                  "seat": "9",
                  "oa": "AI",
                  "fa": [],
                  "fdur": "02:05",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "2",
                    "arr": "3"
                  }
                },
                {
                  "ref": 3,
                  "da": "DEL",
                  "ar": "DOH",
                  "dd": "2023-07-01T19:50:00",
                  "ad": "2023-07-01T21:25:00",
                  "rbd": "U",
                  "fn": "971",
                  "trn": "3",
                  "eq": "32N",
                  "ac": "AI",
                  "ma": "AI",
                  "seat": "1",
                  "oa": "AI",
                  "fa": [],
                  "fdur": "04:05",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "3",
                    "arr": ""
                  }
                }
              ],
              "flightNumbers": "698/864/971",
              "bgd": [
                {
                  "qun": "30",
                  "wt": "kg"
                }
              ]
            },
            {
              "tid": "zaSdWMl3lyTdgdur_2",
              "ref": 2,
              "sdur": "25:30",
              "re": "Non-Refundable",
              "stop": 2,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "AI",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "BOM",
                  "dd": "2023-06-30T22:25:00",
                  "ad": "2023-06-30T23:50:00",
                  "rbd": "U",
                  "fn": "698",
                  "eq": "319",
                  "ac": "AI",
                  "ma": "AI",
                  "seat": "9",
                  "oa": "AI",
                  "fa": [],
                  "fdur": "01:25",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": "2"
                  }
                },
                {
                  "ref": 2,
                  "da": "BOM",
                  "ar": "DEL",
                  "dd": "2023-07-01T09:00:00",
                  "ad": "2023-07-01T11:20:00",
                  "rbd": "Q",
                  "fn": "866",
                  "trn": "2",
                  "eq": "32N",
                  "ac": "AI",
                  "ma": "AI",
                  "seat": "9",
                  "oa": "AI",
                  "fa": [],
                  "fdur": "02:20",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "2",
                    "arr": "3"
                  }
                },
                {
                  "ref": 3,
                  "da": "DEL",
                  "ar": "DOH",
                  "dd": "2023-07-01T19:50:00",
                  "ad": "2023-07-01T21:25:00",
                  "rbd": "U",
                  "fn": "971",
                  "trn": "3",
                  "eq": "32N",
                  "ac": "AI",
                  "ma": "AI",
                  "seat": "1",
                  "oa": "AI",
                  "fa": [],
                  "fdur": "04:05",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "3",
                    "arr": ""
                  }
                }
              ],
              "flightNumbers": "698/866/971",
              "bgd": [
                {
                  "qun": "30",
                  "wt": "kg"
                }
              ]
            }
          ],
          "pr": {
            "bf": 22624,
            "tf": 30912,
            "tax": [
              {
                "YR": 1120
              },
              {
                "YR": 672
              },
              {
                "OT": 6496
              }
            ],
            "scur": "QAR",
            "ucur": "INR",
            "exchange_rate": 22.4,
            "sup_fare": "1010_1380"
          },
          "paxpr": [
            {
              "tf": 30912,
              "tax": [
                {
                  "YR": 1120
                },
                {
                  "YR": 672
                },
                {
                  "OT": 6496
                }
              ],
              "bf": 22624,
              "fbc": "L6MDHDL",
              "fareType": "RP",
              "pusup_fare": "1010_1380",
              "psup_fare": "22624_30912",
              "ptype": "ADT"
            }
          ],
          "sup": "0",
          "airlinesList": "AI"
        },
        {
          "srcorigns": [
            {
              "tid": "3iR0mRAhxSHrX0BD_1",
              "ref": 1,
              "sdur": "08:20",
              "re": "Non-Refundable",
              "stop": 1,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "AI",
              "seg": [
                {
                  "ref": 1,
                  "da": "DOH",
                  "ar": "DEL",
                  "dd": "2023-06-29T22:35:00",
                  "ad": "2023-06-30T05:05:00",
                  "rbd": "L",
                  "fn": "972",
                  "eq": "32N",
                  "ac": "AI",
                  "ma": "AI",
                  "seat": "1",
                  "oa": "AI",
                  "fa": [],
                  "fdur": "04:00",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": "3"
                  }
                },
                {
                  "ref": 2,
                  "da": "DEL",
                  "ar": "HYD",
                  "dd": "2023-06-30T07:10:00",
                  "ad": "2023-06-30T09:25:00",
                  "rbd": "Q",
                  "fn": "560",
                  "trn": "3",
                  "eq": "32N",
                  "ac": "AI",
                  "ma": "AI",
                  "seat": "9",
                  "oa": "AI",
                  "fa": [],
                  "fdur": "02:15",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "3",
                    "arr": ""
                  }
                }
              ],
              "flightNumbers": "972/560",
              "bgd": [
                {
                  "qun": "30",
                  "wt": "kg"
                }
              ]
            }
          ],
          "destorigins": [
            {
              "tid": "3iR0mRAhxSHrX0BD_2",
              "ref": 2,
              "sdur": "10:40",
              "re": "Non-Refundable",
              "stop": 2,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "AI",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "BOM",
                  "dd": "2023-06-30T13:15:00",
                  "ad": "2023-06-30T14:55:00",
                  "rbd": "S",
                  "fn": "618",
                  "eq": "319",
                  "ac": "AI",
                  "ma": "AI",
                  "seat": "1",
                  "oa": "AI",
                  "fa": [],
                  "fdur": "01:40",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": "2"
                  }
                },
                {
                  "ref": 2,
                  "da": "BOM",
                  "ar": "DEL",
                  "dd": "2023-06-30T16:00:00",
                  "ad": "2023-06-30T18:20:00",
                  "rbd": "Q",
                  "fn": "687",
                  "trn": "2",
                  "eq": "32N",
                  "ac": "AI",
                  "ma": "AI",
                  "seat": "9",
                  "oa": "AI",
                  "fa": [],
                  "fdur": "02:20",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "2",
                    "arr": "3"
                  }
                },
                {
                  "ref": 3,
                  "da": "DEL",
                  "ar": "DOH",
                  "dd": "2023-06-30T19:50:00",
                  "ad": "2023-06-30T21:25:00",
                  "rbd": "U",
                  "fn": "971",
                  "trn": "3",
                  "eq": "32N",
                  "ac": "AI",
                  "ma": "AI",
                  "seat": "2",
                  "oa": "AI",
                  "fa": [],
                  "fdur": "04:05",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "3",
                    "arr": ""
                  }
                }
              ],
              "flightNumbers": "618/687/971",
              "bgd": [
                {
                  "qun": "30",
                  "wt": "kg"
                }
              ]
            }
          ],
          "pr": {
            "bf": 23744,
            "tf": 32032,
            "tax": [
              {
                "YR": 1120
              },
              {
                "YR": 672
              },
              {
                "OT": 6496
              }
            ],
            "scur": "QAR",
            "ucur": "INR",
            "exchange_rate": 22.4,
            "sup_fare": "1060_1430"
          },
          "paxpr": [
            {
              "tf": 32032,
              "tax": [
                {
                  "YR": 1120
                },
                {
                  "YR": 672
                },
                {
                  "OT": 6496
                }
              ],
              "bf": 23744,
              "fbc": "L6MDHDL",
              "fareType": "RP",
              "pusup_fare": "1060_1430",
              "psup_fare": "23744_32032",
              "ptype": "ADT"
            }
          ],
          "sup": "0",
          "airlinesList": "AI"
        },
        {
          "srcorigns": [
            {
              "tid": "TW1Cq6FL1ytGgVPH_1",
              "ref": 1,
              "ofcid": "",
              "sdur": "9:45",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "DOH",
                  "ar": "HYD",
                  "dd": "2023-06-29T02:00:00",
                  "ad": "2023-06-29T08:45:00",
                  "rbd": "",
                  "fn": "1318",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {},
                  "rph": "",
                  "jsell": "6E~1318~ ~~DOH~06/29/2023 02:00~HYD~06/29/2023 08:45~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~A~ ~6E~A0INT~5000~~0~1~~X",
                  "pr": {
                    "bf": 28373,
                    "tf": 32479,
                    "tax": [
                      {
                        "OT": 4106
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "28373_32479",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "paxpr": [
                    {
                      "bf": 28373,
                      "tf": 32479,
                      "tax": [
                        {
                          "OT": 4106
                        }
                      ],
                      "fbc": "A0INT",
                      "ptype": "ADT",
                      "scur": "INR",
                      "sup_fare": "28373_32479"
                    }
                  ],
                  "routeId": "yg0717f0cb",
                  "productClass": "R",
                  "ruleNumber": "5000"
                },
                {
                  "fareKey": "0~A~ ~6E~AUINT~5003~~0~7~~X",
                  "pr": {
                    "bf": 29073,
                    "tf": 33204,
                    "tax": [
                      {
                        "OT": 4131
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "29073_33204",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "paxpr": [
                    {
                      "bf": 29073,
                      "tf": 33204,
                      "tax": [
                        {
                          "OT": 4131
                        }
                      ],
                      "fbc": "AUINT",
                      "ptype": "ADT",
                      "scur": "INR",
                      "sup_fare": "29073_33204"
                    }
                  ],
                  "routeId": "x5xd6gxh1bj",
                  "productClass": "J",
                  "ruleNumber": "5003"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 0
            },
            {
              "tid": "6PaGAGkIGT5vKr2A_1",
              "ref": 1,
              "ofcid": "",
              "sdur": "9:45",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "DOH",
                  "ar": "HYD",
                  "dd": "2023-06-29T08:15:00",
                  "ad": "2023-06-29T15:00:00",
                  "rbd": "",
                  "fn": "1314",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {},
                  "rph": "",
                  "jsell": "6E~1314~ ~~DOH~06/29/2023 08:15~HYD~06/29/2023 15:00~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~A~ ~6E~A0INT~5000~~0~1~~X",
                  "pr": {
                    "bf": 28373,
                    "tf": 32479,
                    "tax": [
                      {
                        "OT": 4106
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "28373_32479",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "paxpr": [
                    {
                      "bf": 28373,
                      "tf": 32479,
                      "tax": [
                        {
                          "OT": 4106
                        }
                      ],
                      "fbc": "A0INT",
                      "ptype": "ADT",
                      "scur": "INR",
                      "sup_fare": "28373_32479"
                    }
                  ],
                  "routeId": "92nijwnfik",
                  "productClass": "R",
                  "ruleNumber": "5000"
                },
                {
                  "fareKey": "0~A~ ~6E~AUINT~5003~~0~7~~X",
                  "pr": {
                    "bf": 29073,
                    "tf": 33204,
                    "tax": [
                      {
                        "OT": 4131
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "29073_33204",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "paxpr": [
                    {
                      "bf": 29073,
                      "tf": 33204,
                      "tax": [
                        {
                          "OT": 4131
                        }
                      ],
                      "fbc": "AUINT",
                      "ptype": "ADT",
                      "scur": "INR",
                      "sup_fare": "29073_33204"
                    }
                  ],
                  "routeId": "dzscr9t0wg",
                  "productClass": "J",
                  "ruleNumber": "5003"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 0
            },
            {
              "tid": "pzNkwH4bZf19OshV_1",
              "ref": 1,
              "ofcid": "",
              "sdur": "9:45",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "DOH",
                  "ar": "HYD",
                  "dd": "2023-06-29T19:45:00",
                  "ad": "2023-06-30T02:30:00",
                  "rbd": "",
                  "fn": "1316",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {},
                  "rph": "",
                  "jsell": "6E~1316~ ~~DOH~06/29/2023 19:45~HYD~06/30/2023 02:30~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~A~ ~6E~A0INT~5000~~0~1~~X",
                  "pr": {
                    "bf": 28373,
                    "tf": 32479,
                    "tax": [
                      {
                        "OT": 4106
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "28373_32479",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "paxpr": [
                    {
                      "bf": 28373,
                      "tf": 32479,
                      "tax": [
                        {
                          "OT": 4106
                        }
                      ],
                      "fbc": "A0INT",
                      "ptype": "ADT",
                      "scur": "INR",
                      "sup_fare": "28373_32479"
                    }
                  ],
                  "routeId": "zp4voyh55v",
                  "productClass": "R",
                  "ruleNumber": "5000"
                },
                {
                  "fareKey": "0~A~ ~6E~AUINT~5003~~0~7~~X",
                  "pr": {
                    "bf": 29073,
                    "tf": 33204,
                    "tax": [
                      {
                        "OT": 4131
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "29073_33204",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "paxpr": [
                    {
                      "bf": 29073,
                      "tf": 33204,
                      "tax": [
                        {
                          "OT": 4131
                        }
                      ],
                      "fbc": "AUINT",
                      "ptype": "ADT",
                      "scur": "INR",
                      "sup_fare": "29073_33204"
                    }
                  ],
                  "routeId": "ep8dzk4wah",
                  "productClass": "J",
                  "ruleNumber": "5003"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 0
            }
          ],
          "destorigins": [
            {
              "tid": "15xv82sim3yUrTmi_2",
              "ref": 2,
              "ofcid": "",
              "sdur": "6:30",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "MAA",
                  "dd": "2023-06-30T14:05:00",
                  "ad": "2023-06-30T15:20:00",
                  "rbd": "",
                  "fn": " 514",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "1"
                  },
                  "rph": "",
                  "jsell": "6E~ 514~ ~~HYD~06/30/2023 14:05~MAA~06/30/2023 15:20~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                },
                {
                  "ref": 2,
                  "da": "MAA",
                  "ar": "DOH",
                  "dd": "2023-06-30T21:30:00",
                  "ad": "2023-06-30T23:35:00",
                  "rbd": "",
                  "fn": "1321",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "4"
                  },
                  "rph": "",
                  "jsell": "6E~1321~ ~~MAA~06/30/2023 21:30~DOH~06/30/2023 23:35~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~R~ ~6E~RCINT~6200~~0~66~~X^0~R~ ~6E~RCINT~6200~~0~66~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 8036,
                      "fbc": "RCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "8036_11726",
                      "tax": [
                        {
                          "OT": 3690
                        }
                      ],
                      "tf": 11726
                    }
                  ],
                  "pr": {
                    "bf": 8036,
                    "tf": 11726,
                    "tax": [
                      {
                        "OT": 3690
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "8036_11726",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "hr6kalt75o",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~R~ ~6E~RCUINT~6202~~0~5~~X^0~R~ ~6E~RCUINT~6202~~0~5~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 8736,
                      "fbc": "RCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "8736_12487",
                      "tax": [
                        {
                          "OT": 3751
                        }
                      ],
                      "tf": 12487
                    }
                  ],
                  "pr": {
                    "bf": 8736,
                    "tf": 12487,
                    "tax": [
                      {
                        "OT": 3751
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "8736_12487",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "z08138i00b",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            },
            {
              "tid": "JWrPVD8xRWaakETA_2",
              "ref": 2,
              "ofcid": "",
              "sdur": "4:0",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "MAA",
                  "dd": "2023-06-30T16:35:00",
                  "ad": "2023-06-30T17:45:00",
                  "rbd": "",
                  "fn": " 668",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "1"
                  },
                  "rph": "",
                  "jsell": "6E~ 668~ ~~HYD~06/30/2023 16:35~MAA~06/30/2023 17:45~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                },
                {
                  "ref": 2,
                  "da": "MAA",
                  "ar": "DOH",
                  "dd": "2023-06-30T21:30:00",
                  "ad": "2023-06-30T23:35:00",
                  "rbd": "",
                  "fn": "1321",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "4"
                  },
                  "rph": "",
                  "jsell": "6E~1321~ ~~MAA~06/30/2023 21:30~DOH~06/30/2023 23:35~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~R~ ~6E~RCINT~6200~~0~66~~X^0~R~ ~6E~RCINT~6200~~0~66~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 8036,
                      "fbc": "RCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "8036_11726",
                      "tax": [
                        {
                          "OT": 3690
                        }
                      ],
                      "tf": 11726
                    }
                  ],
                  "pr": {
                    "bf": 8036,
                    "tf": 11726,
                    "tax": [
                      {
                        "OT": 3690
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "8036_11726",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "09n6ot67xef",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~R~ ~6E~RCUINT~6202~~0~5~~X^0~R~ ~6E~RCUINT~6202~~0~5~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 8736,
                      "fbc": "RCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "8736_12487",
                      "tax": [
                        {
                          "OT": 3751
                        }
                      ],
                      "tf": 12487
                    }
                  ],
                  "pr": {
                    "bf": 8736,
                    "tf": 12487,
                    "tax": [
                      {
                        "OT": 3751
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "8736_12487",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "3tk0om1cij",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            },
            {
              "tid": "h9AmA2QlMZeoC8Wu_2",
              "ref": 2,
              "ofcid": "",
              "sdur": "2:55",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "MAA",
                  "dd": "2023-06-30T17:40:00",
                  "ad": "2023-06-30T18:55:00",
                  "rbd": "",
                  "fn": " 102",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "1"
                  },
                  "rph": "",
                  "jsell": "6E~ 102~ ~~HYD~06/30/2023 17:40~MAA~06/30/2023 18:55~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                },
                {
                  "ref": 2,
                  "da": "MAA",
                  "ar": "DOH",
                  "dd": "2023-06-30T21:30:00",
                  "ad": "2023-06-30T23:35:00",
                  "rbd": "",
                  "fn": "1321",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "4"
                  },
                  "rph": "",
                  "jsell": "6E~1321~ ~~MAA~06/30/2023 21:30~DOH~06/30/2023 23:35~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~R~ ~6E~RCINT~6200~~0~66~~X^0~R~ ~6E~RCINT~6200~~0~66~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 8036,
                      "fbc": "RCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "8036_11726",
                      "tax": [
                        {
                          "OT": 3690
                        }
                      ],
                      "tf": 11726
                    }
                  ],
                  "pr": {
                    "bf": 8036,
                    "tf": 11726,
                    "tax": [
                      {
                        "OT": 3690
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "8036_11726",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "mcet5t1oavl",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~R~ ~6E~RCUINT~6202~~0~5~~X^0~R~ ~6E~RCUINT~6202~~0~5~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 8736,
                      "fbc": "RCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "8736_12487",
                      "tax": [
                        {
                          "OT": 3751
                        }
                      ],
                      "tf": 12487
                    }
                  ],
                  "pr": {
                    "bf": 8736,
                    "tf": 12487,
                    "tax": [
                      {
                        "OT": 3751
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "8736_12487",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "3nhbkw7tb8",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            }
          ],
          "paxpr": [
            {
              "bf": 36409,
              "tf": 44205,
              "fbc": "",
              "psup_fare": "",
              "tax": [
                [
                  {
                    "OT": 4106
                  }
                ],
                [
                  {
                    "OT": 3690
                  }
                ]
              ],
              "ptype": "ADT",
              "scur": "INR",
              "sup_cur_fare": ""
            }
          ],
          "pr": {
            "bf": 28373,
            "tf": 32479,
            "tax": [
              {
                "OT": 4106
              },
              {
                "OT": 3690
              }
            ],
            "scur": "INR",
            "sup_fare": "",
            "sup_cur_fare": "",
            "cabb": "",
            "chekb": "",
            "seats": "",
            "meals": "",
            "chanb": "",
            "ftyp": ""
          },
          "sup": "4",
          "airlineList": "6E"
        },
        {
          "srcorigns": [
            {
              "tid": "AZBRn1wOsCGSufS0_1",
              "ref": 1,
              "ofcid": "",
              "sdur": "9:45",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "DOH",
                  "ar": "HYD",
                  "dd": "2023-06-29T02:00:00",
                  "ad": "2023-06-29T08:45:00",
                  "rbd": "",
                  "fn": "1318",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {},
                  "rph": "",
                  "jsell": "6E~1318~ ~~DOH~06/29/2023 02:00~HYD~06/29/2023 08:45~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~A~ ~6E~A0INT~5000~~0~1~~X",
                  "pr": {
                    "bf": 28373,
                    "tf": 32479,
                    "tax": [
                      {
                        "OT": 4106
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "28373_32479",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "paxpr": [
                    {
                      "bf": 28373,
                      "tf": 32479,
                      "tax": [
                        {
                          "OT": 4106
                        }
                      ],
                      "fbc": "A0INT",
                      "ptype": "ADT",
                      "scur": "INR",
                      "sup_fare": "28373_32479"
                    }
                  ],
                  "routeId": "yg0717f0cb",
                  "productClass": "R",
                  "ruleNumber": "5000"
                },
                {
                  "fareKey": "0~A~ ~6E~AUINT~5003~~0~7~~X",
                  "pr": {
                    "bf": 29073,
                    "tf": 33204,
                    "tax": [
                      {
                        "OT": 4131
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "29073_33204",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "paxpr": [
                    {
                      "bf": 29073,
                      "tf": 33204,
                      "tax": [
                        {
                          "OT": 4131
                        }
                      ],
                      "fbc": "AUINT",
                      "ptype": "ADT",
                      "scur": "INR",
                      "sup_fare": "29073_33204"
                    }
                  ],
                  "routeId": "x5xd6gxh1bj",
                  "productClass": "J",
                  "ruleNumber": "5003"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 0
            },
            {
              "tid": "gwtNW4A0g9bxml2k_1",
              "ref": 1,
              "ofcid": "",
              "sdur": "9:45",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "DOH",
                  "ar": "HYD",
                  "dd": "2023-06-29T08:15:00",
                  "ad": "2023-06-29T15:00:00",
                  "rbd": "",
                  "fn": "1314",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {},
                  "rph": "",
                  "jsell": "6E~1314~ ~~DOH~06/29/2023 08:15~HYD~06/29/2023 15:00~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~A~ ~6E~A0INT~5000~~0~1~~X",
                  "pr": {
                    "bf": 28373,
                    "tf": 32479,
                    "tax": [
                      {
                        "OT": 4106
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "28373_32479",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "paxpr": [
                    {
                      "bf": 28373,
                      "tf": 32479,
                      "tax": [
                        {
                          "OT": 4106
                        }
                      ],
                      "fbc": "A0INT",
                      "ptype": "ADT",
                      "scur": "INR",
                      "sup_fare": "28373_32479"
                    }
                  ],
                  "routeId": "92nijwnfik",
                  "productClass": "R",
                  "ruleNumber": "5000"
                },
                {
                  "fareKey": "0~A~ ~6E~AUINT~5003~~0~7~~X",
                  "pr": {
                    "bf": 29073,
                    "tf": 33204,
                    "tax": [
                      {
                        "OT": 4131
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "29073_33204",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "paxpr": [
                    {
                      "bf": 29073,
                      "tf": 33204,
                      "tax": [
                        {
                          "OT": 4131
                        }
                      ],
                      "fbc": "AUINT",
                      "ptype": "ADT",
                      "scur": "INR",
                      "sup_fare": "29073_33204"
                    }
                  ],
                  "routeId": "dzscr9t0wg",
                  "productClass": "J",
                  "ruleNumber": "5003"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 0
            },
            {
              "tid": "0t9DISWhKD55KlLD_1",
              "ref": 1,
              "ofcid": "",
              "sdur": "9:45",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "DOH",
                  "ar": "HYD",
                  "dd": "2023-06-29T19:45:00",
                  "ad": "2023-06-30T02:30:00",
                  "rbd": "",
                  "fn": "1316",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {},
                  "rph": "",
                  "jsell": "6E~1316~ ~~DOH~06/29/2023 19:45~HYD~06/30/2023 02:30~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~A~ ~6E~A0INT~5000~~0~1~~X",
                  "pr": {
                    "bf": 28373,
                    "tf": 32479,
                    "tax": [
                      {
                        "OT": 4106
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "28373_32479",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "paxpr": [
                    {
                      "bf": 28373,
                      "tf": 32479,
                      "tax": [
                        {
                          "OT": 4106
                        }
                      ],
                      "fbc": "A0INT",
                      "ptype": "ADT",
                      "scur": "INR",
                      "sup_fare": "28373_32479"
                    }
                  ],
                  "routeId": "zp4voyh55v",
                  "productClass": "R",
                  "ruleNumber": "5000"
                },
                {
                  "fareKey": "0~A~ ~6E~AUINT~5003~~0~7~~X",
                  "pr": {
                    "bf": 29073,
                    "tf": 33204,
                    "tax": [
                      {
                        "OT": 4131
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "29073_33204",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "paxpr": [
                    {
                      "bf": 29073,
                      "tf": 33204,
                      "tax": [
                        {
                          "OT": 4131
                        }
                      ],
                      "fbc": "AUINT",
                      "ptype": "ADT",
                      "scur": "INR",
                      "sup_fare": "29073_33204"
                    }
                  ],
                  "routeId": "ep8dzk4wah",
                  "productClass": "J",
                  "ruleNumber": "5003"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 0
            }
          ],
          "destorigins": [
            {
              "tid": "sIV7aoupMpCkm8T3_2",
              "ref": 2,
              "ofcid": "",
              "sdur": "0:-80",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DOH",
                  "dd": "2023-06-30T04:10:00",
                  "ad": "2023-06-30T05:50:00",
                  "rbd": "",
                  "fn": "1313",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {},
                  "rph": "",
                  "jsell": "6E~1313~ ~~HYD~06/30/2023 04:10~DOH~06/30/2023 05:50~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~A~ ~6E~A0INT~5000~~0~2~~X",
                  "pr": {
                    "bf": 26311,
                    "tf": 31534,
                    "tax": [
                      {
                        "OT": 5223
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "26311_31534",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "paxpr": [
                    {
                      "bf": 26311,
                      "tf": 31534,
                      "tax": [
                        {
                          "OT": 5223
                        }
                      ],
                      "fbc": "A0INT",
                      "ptype": "ADT",
                      "scur": "INR",
                      "sup_fare": "26311_31534"
                    }
                  ],
                  "routeId": "vipx9ao09t",
                  "productClass": "R",
                  "ruleNumber": "5000"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 0
            },
            {
              "tid": "bBQfiztAyJgaxpg4_2",
              "ref": 2,
              "ofcid": "",
              "sdur": "0:-80",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DOH",
                  "dd": "2023-06-30T17:05:00",
                  "ad": "2023-06-30T18:45:00",
                  "rbd": "",
                  "fn": "1315",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {},
                  "rph": "",
                  "jsell": "6E~1315~ ~~HYD~06/30/2023 17:05~DOH~06/30/2023 18:45~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~A~ ~6E~A0INT~5000~~0~2~~X",
                  "pr": {
                    "bf": 26311,
                    "tf": 31534,
                    "tax": [
                      {
                        "OT": 5223
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "26311_31534",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "paxpr": [
                    {
                      "bf": 26311,
                      "tf": 31534,
                      "tax": [
                        {
                          "OT": 5223
                        }
                      ],
                      "fbc": "A0INT",
                      "ptype": "ADT",
                      "scur": "INR",
                      "sup_fare": "26311_31534"
                    }
                  ],
                  "routeId": "2h2rzt1vs6g",
                  "productClass": "R",
                  "ruleNumber": "5000"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 0
            },
            {
              "tid": "UbJZgGSDgn0CKyJa_2",
              "ref": 2,
              "ofcid": "",
              "sdur": "0:-80",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DOH",
                  "dd": "2023-06-30T21:55:00",
                  "ad": "2023-06-30T23:35:00",
                  "rbd": "",
                  "fn": "1317",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {},
                  "rph": "",
                  "jsell": "6E~1317~ ~~HYD~06/30/2023 21:55~DOH~06/30/2023 23:35~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~A~ ~6E~A0INT~5000~~0~2~~X",
                  "pr": {
                    "bf": 26311,
                    "tf": 31534,
                    "tax": [
                      {
                        "OT": 5223
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "26311_31534",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "paxpr": [
                    {
                      "bf": 26311,
                      "tf": 31534,
                      "tax": [
                        {
                          "OT": 5223
                        }
                      ],
                      "fbc": "A0INT",
                      "ptype": "ADT",
                      "scur": "INR",
                      "sup_fare": "26311_31534"
                    }
                  ],
                  "routeId": "v58hks09rf",
                  "productClass": "R",
                  "ruleNumber": "5000"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 0
            }
          ],
          "paxpr": [
            {
              "bf": 54684,
              "tf": 64013,
              "fbc": "",
              "psup_fare": "",
              "tax": [
                [
                  {
                    "OT": 4106
                  }
                ],
                [
                  {
                    "OT": 5223
                  }
                ]
              ],
              "ptype": "ADT",
              "scur": "INR",
              "sup_cur_fare": ""
            }
          ],
          "pr": {
            "bf": 28373,
            "tf": 32479,
            "tax": [
              {
                "OT": 4106
              },
              {
                "OT": 5223
              }
            ],
            "scur": "INR",
            "sup_fare": "",
            "sup_cur_fare": "",
            "cabb": "",
            "chekb": "",
            "seats": "",
            "meals": "",
            "chanb": "",
            "ftyp": ""
          },
          "sup": "4",
          "airlineList": "6E"
        },
        {
          "srcorigns": [
            {
              "tid": "g8AuuuoewvqWX2KG_1",
              "ref": 1,
              "ofcid": "",
              "sdur": "9:45",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "DOH",
                  "ar": "HYD",
                  "dd": "2023-06-29T02:00:00",
                  "ad": "2023-06-29T08:45:00",
                  "rbd": "",
                  "fn": "1318",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {},
                  "rph": "",
                  "jsell": "6E~1318~ ~~DOH~06/29/2023 02:00~HYD~06/29/2023 08:45~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~A~ ~6E~A0INT~5000~~0~1~~X",
                  "pr": {
                    "bf": 28373,
                    "tf": 32479,
                    "tax": [
                      {
                        "OT": 4106
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "28373_32479",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "paxpr": [
                    {
                      "bf": 28373,
                      "tf": 32479,
                      "tax": [
                        {
                          "OT": 4106
                        }
                      ],
                      "fbc": "A0INT",
                      "ptype": "ADT",
                      "scur": "INR",
                      "sup_fare": "28373_32479"
                    }
                  ],
                  "routeId": "yg0717f0cb",
                  "productClass": "R",
                  "ruleNumber": "5000"
                },
                {
                  "fareKey": "0~A~ ~6E~AUINT~5003~~0~7~~X",
                  "pr": {
                    "bf": 29073,
                    "tf": 33204,
                    "tax": [
                      {
                        "OT": 4131
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "29073_33204",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "paxpr": [
                    {
                      "bf": 29073,
                      "tf": 33204,
                      "tax": [
                        {
                          "OT": 4131
                        }
                      ],
                      "fbc": "AUINT",
                      "ptype": "ADT",
                      "scur": "INR",
                      "sup_fare": "29073_33204"
                    }
                  ],
                  "routeId": "x5xd6gxh1bj",
                  "productClass": "J",
                  "ruleNumber": "5003"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 0
            },
            {
              "tid": "xCfAfrX3LbF7DX1K_1",
              "ref": 1,
              "ofcid": "",
              "sdur": "9:45",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "DOH",
                  "ar": "HYD",
                  "dd": "2023-06-29T08:15:00",
                  "ad": "2023-06-29T15:00:00",
                  "rbd": "",
                  "fn": "1314",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {},
                  "rph": "",
                  "jsell": "6E~1314~ ~~DOH~06/29/2023 08:15~HYD~06/29/2023 15:00~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~A~ ~6E~A0INT~5000~~0~1~~X",
                  "pr": {
                    "bf": 28373,
                    "tf": 32479,
                    "tax": [
                      {
                        "OT": 4106
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "28373_32479",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "paxpr": [
                    {
                      "bf": 28373,
                      "tf": 32479,
                      "tax": [
                        {
                          "OT": 4106
                        }
                      ],
                      "fbc": "A0INT",
                      "ptype": "ADT",
                      "scur": "INR",
                      "sup_fare": "28373_32479"
                    }
                  ],
                  "routeId": "92nijwnfik",
                  "productClass": "R",
                  "ruleNumber": "5000"
                },
                {
                  "fareKey": "0~A~ ~6E~AUINT~5003~~0~7~~X",
                  "pr": {
                    "bf": 29073,
                    "tf": 33204,
                    "tax": [
                      {
                        "OT": 4131
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "29073_33204",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "paxpr": [
                    {
                      "bf": 29073,
                      "tf": 33204,
                      "tax": [
                        {
                          "OT": 4131
                        }
                      ],
                      "fbc": "AUINT",
                      "ptype": "ADT",
                      "scur": "INR",
                      "sup_fare": "29073_33204"
                    }
                  ],
                  "routeId": "dzscr9t0wg",
                  "productClass": "J",
                  "ruleNumber": "5003"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 0
            },
            {
              "tid": "nKk2iSSdknTSbhTS_1",
              "ref": 1,
              "ofcid": "",
              "sdur": "9:45",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "DOH",
                  "ar": "HYD",
                  "dd": "2023-06-29T19:45:00",
                  "ad": "2023-06-30T02:30:00",
                  "rbd": "",
                  "fn": "1316",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {},
                  "rph": "",
                  "jsell": "6E~1316~ ~~DOH~06/29/2023 19:45~HYD~06/30/2023 02:30~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~A~ ~6E~A0INT~5000~~0~1~~X",
                  "pr": {
                    "bf": 28373,
                    "tf": 32479,
                    "tax": [
                      {
                        "OT": 4106
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "28373_32479",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "paxpr": [
                    {
                      "bf": 28373,
                      "tf": 32479,
                      "tax": [
                        {
                          "OT": 4106
                        }
                      ],
                      "fbc": "A0INT",
                      "ptype": "ADT",
                      "scur": "INR",
                      "sup_fare": "28373_32479"
                    }
                  ],
                  "routeId": "zp4voyh55v",
                  "productClass": "R",
                  "ruleNumber": "5000"
                },
                {
                  "fareKey": "0~A~ ~6E~AUINT~5003~~0~7~~X",
                  "pr": {
                    "bf": 29073,
                    "tf": 33204,
                    "tax": [
                      {
                        "OT": 4131
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "29073_33204",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "paxpr": [
                    {
                      "bf": 29073,
                      "tf": 33204,
                      "tax": [
                        {
                          "OT": 4131
                        }
                      ],
                      "fbc": "AUINT",
                      "ptype": "ADT",
                      "scur": "INR",
                      "sup_fare": "29073_33204"
                    }
                  ],
                  "routeId": "ep8dzk4wah",
                  "productClass": "J",
                  "ruleNumber": "5003"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 0
            }
          ],
          "destorigins": [
            {
              "tid": "Ryta3accVrrCbB7Z_2",
              "ref": 2,
              "ofcid": "",
              "sdur": "9:0",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "BOM",
                  "dd": "2023-06-30T06:45:00",
                  "ad": "2023-06-30T08:05:00",
                  "rbd": "",
                  "fn": " 286",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "2"
                  },
                  "rph": "",
                  "jsell": "6E~ 286~ ~~HYD~06/30/2023 06:45~BOM~06/30/2023 08:05~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                },
                {
                  "ref": 2,
                  "da": "BOM",
                  "ar": "DOH",
                  "dd": "2023-06-30T17:50:00",
                  "ad": "2023-06-30T18:45:00",
                  "rbd": "",
                  "fn": "1305",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "2"
                  },
                  "rph": "",
                  "jsell": "6E~1305~ ~~BOM~06/30/2023 17:50~DOH~06/30/2023 18:45~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 30621,
                      "fbc": "WCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "30621_36271",
                      "tax": [
                        {
                          "OT": 5650
                        }
                      ],
                      "tf": 36271
                    }
                  ],
                  "pr": {
                    "bf": 30621,
                    "tf": 36271,
                    "tax": [
                      {
                        "OT": 5650
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "30621_36271",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "mll44ksoaq",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 31321,
                      "fbc": "WCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "31321_37031",
                      "tax": [
                        {
                          "OT": 5710
                        }
                      ],
                      "tf": 37031
                    }
                  ],
                  "pr": {
                    "bf": 31321,
                    "tf": 37031,
                    "tax": [
                      {
                        "OT": 5710
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "31321_37031",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "fcdimwd714",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            },
            {
              "tid": "LgaIOf4DXBc6Kz3L_2",
              "ref": 2,
              "ofcid": "",
              "sdur": "8:10",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "BOM",
                  "dd": "2023-06-30T07:35:00",
                  "ad": "2023-06-30T09:10:00",
                  "rbd": "",
                  "fn": " 163",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "2"
                  },
                  "rph": "",
                  "jsell": "6E~ 163~ ~~HYD~06/30/2023 07:35~BOM~06/30/2023 09:10~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                },
                {
                  "ref": 2,
                  "da": "BOM",
                  "ar": "DOH",
                  "dd": "2023-06-30T17:50:00",
                  "ad": "2023-06-30T18:45:00",
                  "rbd": "",
                  "fn": "1305",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "2"
                  },
                  "rph": "",
                  "jsell": "6E~1305~ ~~BOM~06/30/2023 17:50~DOH~06/30/2023 18:45~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 30621,
                      "fbc": "WCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "30621_36271",
                      "tax": [
                        {
                          "OT": 5650
                        }
                      ],
                      "tf": 36271
                    }
                  ],
                  "pr": {
                    "bf": 30621,
                    "tf": 36271,
                    "tax": [
                      {
                        "OT": 5650
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "30621_36271",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "aiz5s1lj2l",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 31321,
                      "fbc": "WCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "31321_37031",
                      "tax": [
                        {
                          "OT": 5710
                        }
                      ],
                      "tf": 37031
                    }
                  ],
                  "pr": {
                    "bf": 31321,
                    "tf": 37031,
                    "tax": [
                      {
                        "OT": 5710
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "31321_37031",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "bsahoip4n8",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            },
            {
              "tid": "zVSydKtSSC149Isw_2",
              "ref": 2,
              "ofcid": "",
              "sdur": "11:15",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DEL",
                  "dd": "2023-06-30T09:30:00",
                  "ad": "2023-06-30T11:50:00",
                  "rbd": "",
                  "fn": "6204",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "1"
                  },
                  "rph": "",
                  "jsell": "6E~6204~ ~~HYD~06/30/2023 09:30~DEL~06/30/2023 11:50~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                },
                {
                  "ref": 2,
                  "da": "DEL",
                  "ar": "DOH",
                  "dd": "2023-06-30T22:10:00",
                  "ad": "2023-06-30T23:45:00",
                  "rbd": "",
                  "fn": "1311",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "3"
                  },
                  "rph": "",
                  "jsell": "6E~1311~ ~~DEL~06/30/2023 22:10~DOH~06/30/2023 23:45~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 30621,
                      "fbc": "WCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "30621_36271",
                      "tax": [
                        {
                          "OT": 5650
                        }
                      ],
                      "tf": 36271
                    }
                  ],
                  "pr": {
                    "bf": 30621,
                    "tf": 36271,
                    "tax": [
                      {
                        "OT": 5650
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "30621_36271",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "hnj4hemi9y",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 31321,
                      "fbc": "WCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "31321_37031",
                      "tax": [
                        {
                          "OT": 5710
                        }
                      ],
                      "tf": 37031
                    }
                  ],
                  "pr": {
                    "bf": 31321,
                    "tf": 37031,
                    "tax": [
                      {
                        "OT": 5710
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "31321_37031",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "fuiiwizun1",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            },
            {
              "tid": "rdELH59enOMP05TQ_2",
              "ref": 2,
              "ofcid": "",
              "sdur": "4:40",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "BOM",
                  "dd": "2023-06-30T11:05:00",
                  "ad": "2023-06-30T12:40:00",
                  "rbd": "",
                  "fn": "5384",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "1"
                  },
                  "rph": "",
                  "jsell": "6E~5384~ ~~HYD~06/30/2023 11:05~BOM~06/30/2023 12:40~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                },
                {
                  "ref": 2,
                  "da": "BOM",
                  "ar": "DOH",
                  "dd": "2023-06-30T17:50:00",
                  "ad": "2023-06-30T18:45:00",
                  "rbd": "",
                  "fn": "1305",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "2"
                  },
                  "rph": "",
                  "jsell": "6E~1305~ ~~BOM~06/30/2023 17:50~DOH~06/30/2023 18:45~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 30621,
                      "fbc": "WCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "30621_36271",
                      "tax": [
                        {
                          "OT": 5650
                        }
                      ],
                      "tf": 36271
                    }
                  ],
                  "pr": {
                    "bf": 30621,
                    "tf": 36271,
                    "tax": [
                      {
                        "OT": 5650
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "30621_36271",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "q9yf092yru",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 31321,
                      "fbc": "WCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "31321_37031",
                      "tax": [
                        {
                          "OT": 5710
                        }
                      ],
                      "tf": 37031
                    }
                  ],
                  "pr": {
                    "bf": 31321,
                    "tf": 37031,
                    "tax": [
                      {
                        "OT": 5710
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "31321_37031",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "h1ye82etiw",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            },
            {
              "tid": "kVyMOKkcno80ZwWC_2",
              "ref": 2,
              "ofcid": "",
              "sdur": "9:0",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DEL",
                  "dd": "2023-06-30T11:45:00",
                  "ad": "2023-06-30T14:05:00",
                  "rbd": "",
                  "fn": "2171",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "2"
                  },
                  "rph": "",
                  "jsell": "6E~2171~ ~~HYD~06/30/2023 11:45~DEL~06/30/2023 14:05~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                },
                {
                  "ref": 2,
                  "da": "DEL",
                  "ar": "DOH",
                  "dd": "2023-06-30T22:10:00",
                  "ad": "2023-06-30T23:45:00",
                  "rbd": "",
                  "fn": "1311",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "3"
                  },
                  "rph": "",
                  "jsell": "6E~1311~ ~~DEL~06/30/2023 22:10~DOH~06/30/2023 23:45~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 30621,
                      "fbc": "WCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "30621_36271",
                      "tax": [
                        {
                          "OT": 5650
                        }
                      ],
                      "tf": 36271
                    }
                  ],
                  "pr": {
                    "bf": 30621,
                    "tf": 36271,
                    "tax": [
                      {
                        "OT": 5650
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "30621_36271",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "fzyj5qtkulh",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 31321,
                      "fbc": "WCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "31321_37031",
                      "tax": [
                        {
                          "OT": 5710
                        }
                      ],
                      "tf": 37031
                    }
                  ],
                  "pr": {
                    "bf": 31321,
                    "tf": 37031,
                    "tax": [
                      {
                        "OT": 5710
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "31321_37031",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "ilubvyddge",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            },
            {
              "tid": "9bVXnDXZJGnZZmFf_2",
              "ref": 2,
              "ofcid": "",
              "sdur": "2:15",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "BOM",
                  "dd": "2023-06-30T13:30:00",
                  "ad": "2023-06-30T15:05:00",
                  "rbd": "",
                  "fn": "6699",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "2"
                  },
                  "rph": "",
                  "jsell": "6E~6699~ ~~HYD~06/30/2023 13:30~BOM~06/30/2023 15:05~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                },
                {
                  "ref": 2,
                  "da": "BOM",
                  "ar": "DOH",
                  "dd": "2023-06-30T17:50:00",
                  "ad": "2023-06-30T18:45:00",
                  "rbd": "",
                  "fn": "1305",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "2"
                  },
                  "rph": "",
                  "jsell": "6E~1305~ ~~BOM~06/30/2023 17:50~DOH~06/30/2023 18:45~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 30621,
                      "fbc": "WCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "30621_36271",
                      "tax": [
                        {
                          "OT": 5650
                        }
                      ],
                      "tf": 36271
                    }
                  ],
                  "pr": {
                    "bf": 30621,
                    "tf": 36271,
                    "tax": [
                      {
                        "OT": 5650
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "30621_36271",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "vyugso05q8l",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 31321,
                      "fbc": "WCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "31321_37031",
                      "tax": [
                        {
                          "OT": 5710
                        }
                      ],
                      "tf": 37031
                    }
                  ],
                  "pr": {
                    "bf": 31321,
                    "tf": 37031,
                    "tax": [
                      {
                        "OT": 5710
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "31321_37031",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "7i57dzs4gg",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            },
            {
              "tid": "7k2QdyTeDZTK5Qxt_2",
              "ref": 2,
              "ofcid": "",
              "sdur": "10:10",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DEL",
                  "dd": "2023-06-30T16:30:00",
                  "ad": "2023-06-30T18:50:00",
                  "rbd": "",
                  "fn": "6606",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "1"
                  },
                  "rph": "",
                  "jsell": "6E~6606~ ~~HYD~06/30/2023 16:30~DEL~06/30/2023 18:50~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                },
                {
                  "ref": 2,
                  "da": "DEL",
                  "ar": "DOH",
                  "dd": "2023-07-01T04:05:00",
                  "ad": "2023-07-01T05:40:00",
                  "rbd": "",
                  "fn": "1307",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "3"
                  },
                  "rph": "",
                  "jsell": "6E~1307~ ~~DEL~07/01/2023 04:05~DOH~07/01/2023 05:40~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 30621,
                      "fbc": "WCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "30621_36271",
                      "tax": [
                        {
                          "OT": 5650
                        }
                      ],
                      "tf": 36271
                    }
                  ],
                  "pr": {
                    "bf": 30621,
                    "tf": 36271,
                    "tax": [
                      {
                        "OT": 5650
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "30621_36271",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "ym2854yqvb",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 31321,
                      "fbc": "WCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "31321_37031",
                      "tax": [
                        {
                          "OT": 5710
                        }
                      ],
                      "tf": 37031
                    }
                  ],
                  "pr": {
                    "bf": 31321,
                    "tf": 37031,
                    "tax": [
                      {
                        "OT": 5710
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "31321_37031",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "cob6hf82ix",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            },
            {
              "tid": "yllnqiFXxRDvDVSm_2",
              "ref": 2,
              "ofcid": "",
              "sdur": "4:15",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DEL",
                  "dd": "2023-06-30T16:30:00",
                  "ad": "2023-06-30T18:50:00",
                  "rbd": "",
                  "fn": "6606",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "1"
                  },
                  "rph": "",
                  "jsell": "6E~6606~ ~~HYD~06/30/2023 16:30~DEL~06/30/2023 18:50~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                },
                {
                  "ref": 2,
                  "da": "DEL",
                  "ar": "DOH",
                  "dd": "2023-06-30T22:10:00",
                  "ad": "2023-06-30T23:45:00",
                  "rbd": "",
                  "fn": "1311",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "3"
                  },
                  "rph": "",
                  "jsell": "6E~1311~ ~~DEL~06/30/2023 22:10~DOH~06/30/2023 23:45~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 30621,
                      "fbc": "WCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "30621_36271",
                      "tax": [
                        {
                          "OT": 5650
                        }
                      ],
                      "tf": 36271
                    }
                  ],
                  "pr": {
                    "bf": 30621,
                    "tf": 36271,
                    "tax": [
                      {
                        "OT": 5650
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "30621_36271",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "weolhswn1z",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 31321,
                      "fbc": "WCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "31321_37031",
                      "tax": [
                        {
                          "OT": 5710
                        }
                      ],
                      "tf": 37031
                    }
                  ],
                  "pr": {
                    "bf": 31321,
                    "tf": 37031,
                    "tax": [
                      {
                        "OT": 5710
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "31321_37031",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "3q0qg6wdyh",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            },
            {
              "tid": "nC3HQl622XIAStnQ_2",
              "ref": 2,
              "ofcid": "",
              "sdur": "8:25",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DEL",
                  "dd": "2023-06-30T18:15:00",
                  "ad": "2023-06-30T20:30:00",
                  "rbd": "",
                  "fn": "2003",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "2"
                  },
                  "rph": "",
                  "jsell": "6E~2003~ ~~HYD~06/30/2023 18:15~DEL~06/30/2023 20:30~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                },
                {
                  "ref": 2,
                  "da": "DEL",
                  "ar": "DOH",
                  "dd": "2023-07-01T04:05:00",
                  "ad": "2023-07-01T05:40:00",
                  "rbd": "",
                  "fn": "1307",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "3"
                  },
                  "rph": "",
                  "jsell": "6E~1307~ ~~DEL~07/01/2023 04:05~DOH~07/01/2023 05:40~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 30621,
                      "fbc": "WCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "30621_36271",
                      "tax": [
                        {
                          "OT": 5650
                        }
                      ],
                      "tf": 36271
                    }
                  ],
                  "pr": {
                    "bf": 30621,
                    "tf": 36271,
                    "tax": [
                      {
                        "OT": 5650
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "30621_36271",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "pelw51opuv",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 31321,
                      "fbc": "WCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "31321_37031",
                      "tax": [
                        {
                          "OT": 5710
                        }
                      ],
                      "tf": 37031
                    }
                  ],
                  "pr": {
                    "bf": 31321,
                    "tf": 37031,
                    "tax": [
                      {
                        "OT": 5710
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "31321_37031",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "np6rmi1ar1",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            },
            {
              "tid": "MrOpLiUfpFlTb9NA_2",
              "ref": 2,
              "ofcid": "",
              "sdur": "5:55",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "BOM",
                  "dd": "2023-06-30T20:50:00",
                  "ad": "2023-06-30T22:05:00",
                  "rbd": "",
                  "fn": "5389",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "1"
                  },
                  "rph": "",
                  "jsell": "6E~5389~ ~~HYD~06/30/2023 20:50~BOM~06/30/2023 22:05~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                },
                {
                  "ref": 2,
                  "da": "BOM",
                  "ar": "DOH",
                  "dd": "2023-07-01T04:50:00",
                  "ad": "2023-07-01T05:45:00",
                  "rbd": "",
                  "fn": "1303",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "2"
                  },
                  "rph": "",
                  "jsell": "6E~1303~ ~~BOM~07/01/2023 04:50~DOH~07/01/2023 05:45~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 30621,
                      "fbc": "WCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "30621_36271",
                      "tax": [
                        {
                          "OT": 5650
                        }
                      ],
                      "tf": 36271
                    }
                  ],
                  "pr": {
                    "bf": 30621,
                    "tf": 36271,
                    "tax": [
                      {
                        "OT": 5650
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "30621_36271",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "2m91jg6dyx",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 31321,
                      "fbc": "WCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "31321_37031",
                      "tax": [
                        {
                          "OT": 5710
                        }
                      ],
                      "tf": 37031
                    }
                  ],
                  "pr": {
                    "bf": 31321,
                    "tf": 37031,
                    "tax": [
                      {
                        "OT": 5710
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "31321_37031",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "lvcaamkwwl",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            },
            {
              "tid": "gf7g7N5ckmcTeIFU_2",
              "ref": 2,
              "ofcid": "",
              "sdur": "5:10",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "BOM",
                  "dd": "2023-06-30T21:35:00",
                  "ad": "2023-06-30T23:05:00",
                  "rbd": "",
                  "fn": "6952",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "2"
                  },
                  "rph": "",
                  "jsell": "6E~6952~ ~~HYD~06/30/2023 21:35~BOM~06/30/2023 23:05~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                },
                {
                  "ref": 2,
                  "da": "BOM",
                  "ar": "DOH",
                  "dd": "2023-07-01T04:50:00",
                  "ad": "2023-07-01T05:45:00",
                  "rbd": "",
                  "fn": "1303",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "2"
                  },
                  "rph": "",
                  "jsell": "6E~1303~ ~~BOM~07/01/2023 04:50~DOH~07/01/2023 05:45~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 30621,
                      "fbc": "WCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "30621_36271",
                      "tax": [
                        {
                          "OT": 5650
                        }
                      ],
                      "tf": 36271
                    }
                  ],
                  "pr": {
                    "bf": 30621,
                    "tf": 36271,
                    "tax": [
                      {
                        "OT": 5650
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "30621_36271",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "30ybxn6h06",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 31321,
                      "fbc": "WCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "31321_37031",
                      "tax": [
                        {
                          "OT": 5710
                        }
                      ],
                      "tf": 37031
                    }
                  ],
                  "pr": {
                    "bf": 31321,
                    "tf": 37031,
                    "tax": [
                      {
                        "OT": 5710
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "31321_37031",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "pxpbbq7fp2",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            },
            {
              "tid": "7SwLvout64LkkEXi_2",
              "ref": 2,
              "ofcid": "",
              "sdur": "4:45",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DEL",
                  "dd": "2023-06-30T21:55:00",
                  "ad": "2023-07-01T00:05:00",
                  "rbd": "",
                  "fn": "5605",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "3"
                  },
                  "rph": "",
                  "jsell": "6E~5605~ ~~HYD~06/30/2023 21:55~DEL~07/01/2023 00:05~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                },
                {
                  "ref": 2,
                  "da": "DEL",
                  "ar": "DOH",
                  "dd": "2023-07-01T04:05:00",
                  "ad": "2023-07-01T05:40:00",
                  "rbd": "",
                  "fn": "1307",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "3"
                  },
                  "rph": "",
                  "jsell": "6E~1307~ ~~DEL~07/01/2023 04:05~DOH~07/01/2023 05:40~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 30621,
                      "fbc": "WCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "30621_36271",
                      "tax": [
                        {
                          "OT": 5650
                        }
                      ],
                      "tf": 36271
                    }
                  ],
                  "pr": {
                    "bf": 30621,
                    "tf": 36271,
                    "tax": [
                      {
                        "OT": 5650
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "30621_36271",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "wcp7u01z53",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 31321,
                      "fbc": "WCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "31321_37031",
                      "tax": [
                        {
                          "OT": 5710
                        }
                      ],
                      "tf": 37031
                    }
                  ],
                  "pr": {
                    "bf": 31321,
                    "tf": 37031,
                    "tax": [
                      {
                        "OT": 5710
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "31321_37031",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "d30wshf2nc",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            },
            {
              "tid": "TWsIBUsIZxIedt1v_2",
              "ref": 2,
              "ofcid": "",
              "sdur": "4:5",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "BOM",
                  "dd": "2023-06-30T22:40:00",
                  "ad": "2023-07-01T00:15:00",
                  "rbd": "",
                  "fn": " 267",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "2"
                  },
                  "rph": "",
                  "jsell": "6E~ 267~ ~~HYD~06/30/2023 22:40~BOM~07/01/2023 00:15~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                },
                {
                  "ref": 2,
                  "da": "BOM",
                  "ar": "DOH",
                  "dd": "2023-07-01T04:50:00",
                  "ad": "2023-07-01T05:45:00",
                  "rbd": "",
                  "fn": "1303",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "2"
                  },
                  "rph": "",
                  "jsell": "6E~1303~ ~~BOM~07/01/2023 04:50~DOH~07/01/2023 05:45~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 30621,
                      "fbc": "WCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "30621_36271",
                      "tax": [
                        {
                          "OT": 5650
                        }
                      ],
                      "tf": 36271
                    }
                  ],
                  "pr": {
                    "bf": 30621,
                    "tf": 36271,
                    "tax": [
                      {
                        "OT": 5650
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "30621_36271",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "ahyd5aqur7",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 31321,
                      "fbc": "WCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "31321_37031",
                      "tax": [
                        {
                          "OT": 5710
                        }
                      ],
                      "tf": 37031
                    }
                  ],
                  "pr": {
                    "bf": 31321,
                    "tf": 37031,
                    "tax": [
                      {
                        "OT": 5710
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "31321_37031",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "clknhqhjqh",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            },
            {
              "tid": "mwUe8lzF8x6sR18Z_2",
              "ref": 2,
              "ofcid": "",
              "sdur": "3:55",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DEL",
                  "dd": "2023-06-30T22:45:00",
                  "ad": "2023-07-01T01:00:00",
                  "rbd": "",
                  "fn": "6146",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "1"
                  },
                  "rph": "",
                  "jsell": "6E~6146~ ~~HYD~06/30/2023 22:45~DEL~07/01/2023 01:00~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                },
                {
                  "ref": 2,
                  "da": "DEL",
                  "ar": "DOH",
                  "dd": "2023-07-01T04:05:00",
                  "ad": "2023-07-01T05:40:00",
                  "rbd": "",
                  "fn": "1307",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "3"
                  },
                  "rph": "",
                  "jsell": "6E~1307~ ~~DEL~07/01/2023 04:05~DOH~07/01/2023 05:40~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 30621,
                      "fbc": "WCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "30621_36271",
                      "tax": [
                        {
                          "OT": 5650
                        }
                      ],
                      "tf": 36271
                    }
                  ],
                  "pr": {
                    "bf": 30621,
                    "tf": 36271,
                    "tax": [
                      {
                        "OT": 5650
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "30621_36271",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "wijagrv12y",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 31321,
                      "fbc": "WCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "31321_37031",
                      "tax": [
                        {
                          "OT": 5710
                        }
                      ],
                      "tf": 37031
                    }
                  ],
                  "pr": {
                    "bf": 31321,
                    "tf": 37031,
                    "tax": [
                      {
                        "OT": 5710
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "31321_37031",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "gsjobm20p1",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            }
          ],
          "paxpr": [
            {
              "bf": 58994,
              "tf": 68750,
              "fbc": "",
              "psup_fare": "",
              "tax": [
                [
                  {
                    "OT": 4106
                  }
                ],
                [
                  {
                    "OT": 5650
                  }
                ]
              ],
              "ptype": "ADT",
              "scur": "INR",
              "sup_cur_fare": ""
            }
          ],
          "pr": {
            "bf": 28373,
            "tf": 32479,
            "tax": [
              {
                "OT": 4106
              },
              {
                "OT": 5650
              }
            ],
            "scur": "INR",
            "sup_fare": "",
            "sup_cur_fare": "",
            "cabb": "",
            "chekb": "",
            "seats": "",
            "meals": "",
            "chanb": "",
            "ftyp": ""
          },
          "sup": "4",
          "airlineList": "6E"
        },
        {
          "srcorigns": [
            {
              "tid": "lRWpmC3uuZWJSDun_1",
              "ref": 1,
              "ofcid": "",
              "sdur": "13:35",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "DOH",
                  "ar": "BOM",
                  "dd": "2023-06-29T08:15:00",
                  "ad": "2023-06-29T14:20:00",
                  "rbd": "",
                  "fn": "1304",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "2"
                  },
                  "rph": "",
                  "jsell": "6E~1304~ ~~DOH~06/29/2023 08:15~BOM~06/29/2023 14:20~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                },
                {
                  "ref": 2,
                  "da": "BOM",
                  "ar": "HYD",
                  "dd": "2023-06-29T17:20:00",
                  "ad": "2023-06-29T18:50:00",
                  "rbd": "",
                  "fn": "6571",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "2"
                  },
                  "rph": "",
                  "jsell": "6E~6571~ ~~BOM~06/29/2023 17:20~HYD~06/29/2023 18:50~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "1~W~ ~6E~W0INT~5000~~0~7~~^2~R~ ~6E~RCIP~1070~~0~8~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 28729,
                      "fbc": "W0INT/RCIP",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "28729_32898",
                      "tax": [
                        {
                          "OT": 4169
                        }
                      ],
                      "tf": 32898
                    }
                  ],
                  "pr": {
                    "bf": 28729,
                    "tf": 32898,
                    "tax": [
                      {
                        "OT": 4169
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "28729_32898",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "2frjimgjm3",
                  "ruleNumber": "5000",
                  "productClass": "R"
                },
                {
                  "fareKey": "1~W~ ~6E~WUINT~5003~~0~12~~^2~R~ ~6E~RUIP~2009~~0~4~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 30029,
                      "fbc": "WUINT/RUIP",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "30029_34243",
                      "tax": [
                        {
                          "OT": 4214
                        }
                      ],
                      "tf": 34243
                    }
                  ],
                  "pr": {
                    "bf": 30029,
                    "tf": 34243,
                    "tax": [
                      {
                        "OT": 4214
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "30029_34243",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "rephs3z9jd",
                  "ruleNumber": "5003",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            }
          ],
          "destorigins": [
            {
              "tid": "gW2sCyWkksgR3X2C_2",
              "ref": 2,
              "ofcid": "",
              "sdur": "6:30",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "MAA",
                  "dd": "2023-06-30T14:05:00",
                  "ad": "2023-06-30T15:20:00",
                  "rbd": "",
                  "fn": " 514",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "1"
                  },
                  "rph": "",
                  "jsell": "6E~ 514~ ~~HYD~06/30/2023 14:05~MAA~06/30/2023 15:20~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                },
                {
                  "ref": 2,
                  "da": "MAA",
                  "ar": "DOH",
                  "dd": "2023-06-30T21:30:00",
                  "ad": "2023-06-30T23:35:00",
                  "rbd": "",
                  "fn": "1321",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "4"
                  },
                  "rph": "",
                  "jsell": "6E~1321~ ~~MAA~06/30/2023 21:30~DOH~06/30/2023 23:35~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~R~ ~6E~RCINT~6200~~0~66~~X^0~R~ ~6E~RCINT~6200~~0~66~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 8036,
                      "fbc": "RCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "8036_11726",
                      "tax": [
                        {
                          "OT": 3690
                        }
                      ],
                      "tf": 11726
                    }
                  ],
                  "pr": {
                    "bf": 8036,
                    "tf": 11726,
                    "tax": [
                      {
                        "OT": 3690
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "8036_11726",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "cpv7g67803",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~R~ ~6E~RCUINT~6202~~0~5~~X^0~R~ ~6E~RCUINT~6202~~0~5~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 8736,
                      "fbc": "RCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "8736_12487",
                      "tax": [
                        {
                          "OT": 3751
                        }
                      ],
                      "tf": 12487
                    }
                  ],
                  "pr": {
                    "bf": 8736,
                    "tf": 12487,
                    "tax": [
                      {
                        "OT": 3751
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "8736_12487",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "kmu9zlj9ca",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            },
            {
              "tid": "PgzUDhFcCPvplree_2",
              "ref": 2,
              "ofcid": "",
              "sdur": "4:0",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "MAA",
                  "dd": "2023-06-30T16:35:00",
                  "ad": "2023-06-30T17:45:00",
                  "rbd": "",
                  "fn": " 668",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "1"
                  },
                  "rph": "",
                  "jsell": "6E~ 668~ ~~HYD~06/30/2023 16:35~MAA~06/30/2023 17:45~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                },
                {
                  "ref": 2,
                  "da": "MAA",
                  "ar": "DOH",
                  "dd": "2023-06-30T21:30:00",
                  "ad": "2023-06-30T23:35:00",
                  "rbd": "",
                  "fn": "1321",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "4"
                  },
                  "rph": "",
                  "jsell": "6E~1321~ ~~MAA~06/30/2023 21:30~DOH~06/30/2023 23:35~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~R~ ~6E~RCINT~6200~~0~66~~X^0~R~ ~6E~RCINT~6200~~0~66~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 8036,
                      "fbc": "RCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "8036_11726",
                      "tax": [
                        {
                          "OT": 3690
                        }
                      ],
                      "tf": 11726
                    }
                  ],
                  "pr": {
                    "bf": 8036,
                    "tf": 11726,
                    "tax": [
                      {
                        "OT": 3690
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "8036_11726",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "8h45gtsr9rh",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~R~ ~6E~RCUINT~6202~~0~5~~X^0~R~ ~6E~RCUINT~6202~~0~5~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 8736,
                      "fbc": "RCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "8736_12487",
                      "tax": [
                        {
                          "OT": 3751
                        }
                      ],
                      "tf": 12487
                    }
                  ],
                  "pr": {
                    "bf": 8736,
                    "tf": 12487,
                    "tax": [
                      {
                        "OT": 3751
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "8736_12487",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "rq2ddqsack",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            },
            {
              "tid": "45RylliJJQGAnRmh_2",
              "ref": 2,
              "ofcid": "",
              "sdur": "2:55",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "MAA",
                  "dd": "2023-06-30T17:40:00",
                  "ad": "2023-06-30T18:55:00",
                  "rbd": "",
                  "fn": " 102",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "1"
                  },
                  "rph": "",
                  "jsell": "6E~ 102~ ~~HYD~06/30/2023 17:40~MAA~06/30/2023 18:55~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                },
                {
                  "ref": 2,
                  "da": "MAA",
                  "ar": "DOH",
                  "dd": "2023-06-30T21:30:00",
                  "ad": "2023-06-30T23:35:00",
                  "rbd": "",
                  "fn": "1321",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "4"
                  },
                  "rph": "",
                  "jsell": "6E~1321~ ~~MAA~06/30/2023 21:30~DOH~06/30/2023 23:35~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~R~ ~6E~RCINT~6200~~0~66~~X^0~R~ ~6E~RCINT~6200~~0~66~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 8036,
                      "fbc": "RCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "8036_11726",
                      "tax": [
                        {
                          "OT": 3690
                        }
                      ],
                      "tf": 11726
                    }
                  ],
                  "pr": {
                    "bf": 8036,
                    "tf": 11726,
                    "tax": [
                      {
                        "OT": 3690
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "8036_11726",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "vx4qi8nxsf",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~R~ ~6E~RCUINT~6202~~0~5~~X^0~R~ ~6E~RCUINT~6202~~0~5~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 8736,
                      "fbc": "RCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "8736_12487",
                      "tax": [
                        {
                          "OT": 3751
                        }
                      ],
                      "tf": 12487
                    }
                  ],
                  "pr": {
                    "bf": 8736,
                    "tf": 12487,
                    "tax": [
                      {
                        "OT": 3751
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "8736_12487",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "1po3qdgj1t",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            }
          ],
          "paxpr": [
            {
              "bf": 36765,
              "tf": 44624,
              "fbc": "",
              "psup_fare": "",
              "tax": [
                [
                  {
                    "OT": 4169
                  }
                ],
                [
                  {
                    "OT": 3690
                  }
                ]
              ],
              "ptype": "ADT",
              "scur": "INR",
              "sup_cur_fare": ""
            }
          ],
          "pr": {
            "bf": 28729,
            "tf": 32898,
            "tax": [
              {
                "OT": 4169
              },
              {
                "OT": 3690
              }
            ],
            "scur": "INR",
            "sup_fare": "",
            "sup_cur_fare": "",
            "cabb": "",
            "chekb": "",
            "seats": "",
            "meals": "",
            "chanb": "",
            "ftyp": ""
          },
          "sup": "4",
          "airlineList": "6E"
        },
        {
          "srcorigns": [
            {
              "tid": "LgGVcTuch393ET7v_1",
              "ref": 1,
              "ofcid": "",
              "sdur": "13:35",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "DOH",
                  "ar": "BOM",
                  "dd": "2023-06-29T08:15:00",
                  "ad": "2023-06-29T14:20:00",
                  "rbd": "",
                  "fn": "1304",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "2"
                  },
                  "rph": "",
                  "jsell": "6E~1304~ ~~DOH~06/29/2023 08:15~BOM~06/29/2023 14:20~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                },
                {
                  "ref": 2,
                  "da": "BOM",
                  "ar": "HYD",
                  "dd": "2023-06-29T17:20:00",
                  "ad": "2023-06-29T18:50:00",
                  "rbd": "",
                  "fn": "6571",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "2"
                  },
                  "rph": "",
                  "jsell": "6E~6571~ ~~BOM~06/29/2023 17:20~HYD~06/29/2023 18:50~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "1~W~ ~6E~W0INT~5000~~0~7~~^2~R~ ~6E~RCIP~1070~~0~8~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 28729,
                      "fbc": "W0INT/RCIP",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "28729_32898",
                      "tax": [
                        {
                          "OT": 4169
                        }
                      ],
                      "tf": 32898
                    }
                  ],
                  "pr": {
                    "bf": 28729,
                    "tf": 32898,
                    "tax": [
                      {
                        "OT": 4169
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "28729_32898",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "2frjimgjm3",
                  "ruleNumber": "5000",
                  "productClass": "R"
                },
                {
                  "fareKey": "1~W~ ~6E~WUINT~5003~~0~12~~^2~R~ ~6E~RUIP~2009~~0~4~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 30029,
                      "fbc": "WUINT/RUIP",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "30029_34243",
                      "tax": [
                        {
                          "OT": 4214
                        }
                      ],
                      "tf": 34243
                    }
                  ],
                  "pr": {
                    "bf": 30029,
                    "tf": 34243,
                    "tax": [
                      {
                        "OT": 4214
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "30029_34243",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "rephs3z9jd",
                  "ruleNumber": "5003",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            }
          ],
          "destorigins": [
            {
              "tid": "AuTsIWPKm4t8ReVK_2",
              "ref": 2,
              "ofcid": "",
              "sdur": "0:-80",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DOH",
                  "dd": "2023-06-30T04:10:00",
                  "ad": "2023-06-30T05:50:00",
                  "rbd": "",
                  "fn": "1313",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {},
                  "rph": "",
                  "jsell": "6E~1313~ ~~HYD~06/30/2023 04:10~DOH~06/30/2023 05:50~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~A~ ~6E~A0INT~5000~~0~2~~X",
                  "pr": {
                    "bf": 26311,
                    "tf": 31534,
                    "tax": [
                      {
                        "OT": 5223
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "26311_31534",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "paxpr": [
                    {
                      "bf": 26311,
                      "tf": 31534,
                      "tax": [
                        {
                          "OT": 5223
                        }
                      ],
                      "fbc": "A0INT",
                      "ptype": "ADT",
                      "scur": "INR",
                      "sup_fare": "26311_31534"
                    }
                  ],
                  "routeId": "7kzvxglzg1",
                  "productClass": "R",
                  "ruleNumber": "5000"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 0
            },
            {
              "tid": "lp3SkOHNA8ETK8K0_2",
              "ref": 2,
              "ofcid": "",
              "sdur": "0:-80",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DOH",
                  "dd": "2023-06-30T17:05:00",
                  "ad": "2023-06-30T18:45:00",
                  "rbd": "",
                  "fn": "1315",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {},
                  "rph": "",
                  "jsell": "6E~1315~ ~~HYD~06/30/2023 17:05~DOH~06/30/2023 18:45~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~A~ ~6E~A0INT~5000~~0~2~~X",
                  "pr": {
                    "bf": 26311,
                    "tf": 31534,
                    "tax": [
                      {
                        "OT": 5223
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "26311_31534",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "paxpr": [
                    {
                      "bf": 26311,
                      "tf": 31534,
                      "tax": [
                        {
                          "OT": 5223
                        }
                      ],
                      "fbc": "A0INT",
                      "ptype": "ADT",
                      "scur": "INR",
                      "sup_fare": "26311_31534"
                    }
                  ],
                  "routeId": "nqfya6n6vu",
                  "productClass": "R",
                  "ruleNumber": "5000"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 0
            },
            {
              "tid": "lPZJzFGVf5L8Nwts_2",
              "ref": 2,
              "ofcid": "",
              "sdur": "0:-80",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DOH",
                  "dd": "2023-06-30T21:55:00",
                  "ad": "2023-06-30T23:35:00",
                  "rbd": "",
                  "fn": "1317",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {},
                  "rph": "",
                  "jsell": "6E~1317~ ~~HYD~06/30/2023 21:55~DOH~06/30/2023 23:35~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~A~ ~6E~A0INT~5000~~0~2~~X",
                  "pr": {
                    "bf": 26311,
                    "tf": 31534,
                    "tax": [
                      {
                        "OT": 5223
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "26311_31534",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "paxpr": [
                    {
                      "bf": 26311,
                      "tf": 31534,
                      "tax": [
                        {
                          "OT": 5223
                        }
                      ],
                      "fbc": "A0INT",
                      "ptype": "ADT",
                      "scur": "INR",
                      "sup_fare": "26311_31534"
                    }
                  ],
                  "routeId": "z4xenre0na",
                  "productClass": "R",
                  "ruleNumber": "5000"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 0
            }
          ],
          "paxpr": [
            {
              "bf": 55040,
              "tf": 64432,
              "fbc": "",
              "psup_fare": "",
              "tax": [
                [
                  {
                    "OT": 4169
                  }
                ],
                [
                  {
                    "OT": 5223
                  }
                ]
              ],
              "ptype": "ADT",
              "scur": "INR",
              "sup_cur_fare": ""
            }
          ],
          "pr": {
            "bf": 28729,
            "tf": 32898,
            "tax": [
              {
                "OT": 4169
              },
              {
                "OT": 5223
              }
            ],
            "scur": "INR",
            "sup_fare": "",
            "sup_cur_fare": "",
            "cabb": "",
            "chekb": "",
            "seats": "",
            "meals": "",
            "chanb": "",
            "ftyp": ""
          },
          "sup": "4",
          "airlineList": "6E"
        },
        {
          "srcorigns": [
            {
              "tid": "aJsck7cEuafL9Ve8_1",
              "ref": 1,
              "ofcid": "",
              "sdur": "13:35",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "DOH",
                  "ar": "BOM",
                  "dd": "2023-06-29T08:15:00",
                  "ad": "2023-06-29T14:20:00",
                  "rbd": "",
                  "fn": "1304",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "2"
                  },
                  "rph": "",
                  "jsell": "6E~1304~ ~~DOH~06/29/2023 08:15~BOM~06/29/2023 14:20~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                },
                {
                  "ref": 2,
                  "da": "BOM",
                  "ar": "HYD",
                  "dd": "2023-06-29T17:20:00",
                  "ad": "2023-06-29T18:50:00",
                  "rbd": "",
                  "fn": "6571",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "2"
                  },
                  "rph": "",
                  "jsell": "6E~6571~ ~~BOM~06/29/2023 17:20~HYD~06/29/2023 18:50~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "1~W~ ~6E~W0INT~5000~~0~7~~^2~R~ ~6E~RCIP~1070~~0~8~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 28729,
                      "fbc": "W0INT/RCIP",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "28729_32898",
                      "tax": [
                        {
                          "OT": 4169
                        }
                      ],
                      "tf": 32898
                    }
                  ],
                  "pr": {
                    "bf": 28729,
                    "tf": 32898,
                    "tax": [
                      {
                        "OT": 4169
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "28729_32898",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "2frjimgjm3",
                  "ruleNumber": "5000",
                  "productClass": "R"
                },
                {
                  "fareKey": "1~W~ ~6E~WUINT~5003~~0~12~~^2~R~ ~6E~RUIP~2009~~0~4~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 30029,
                      "fbc": "WUINT/RUIP",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "30029_34243",
                      "tax": [
                        {
                          "OT": 4214
                        }
                      ],
                      "tf": 34243
                    }
                  ],
                  "pr": {
                    "bf": 30029,
                    "tf": 34243,
                    "tax": [
                      {
                        "OT": 4214
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "30029_34243",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "rephs3z9jd",
                  "ruleNumber": "5003",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            }
          ],
          "destorigins": [
            {
              "tid": "KpUEGZH1ETHo95ZL_2",
              "ref": 2,
              "ofcid": "",
              "sdur": "9:0",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "BOM",
                  "dd": "2023-06-30T06:45:00",
                  "ad": "2023-06-30T08:05:00",
                  "rbd": "",
                  "fn": " 286",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "2"
                  },
                  "rph": "",
                  "jsell": "6E~ 286~ ~~HYD~06/30/2023 06:45~BOM~06/30/2023 08:05~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                },
                {
                  "ref": 2,
                  "da": "BOM",
                  "ar": "DOH",
                  "dd": "2023-06-30T17:50:00",
                  "ad": "2023-06-30T18:45:00",
                  "rbd": "",
                  "fn": "1305",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "2"
                  },
                  "rph": "",
                  "jsell": "6E~1305~ ~~BOM~06/30/2023 17:50~DOH~06/30/2023 18:45~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 30621,
                      "fbc": "WCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "30621_36271",
                      "tax": [
                        {
                          "OT": 5650
                        }
                      ],
                      "tf": 36271
                    }
                  ],
                  "pr": {
                    "bf": 30621,
                    "tf": 36271,
                    "tax": [
                      {
                        "OT": 5650
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "30621_36271",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "xp4dlpvwci",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 31321,
                      "fbc": "WCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "31321_37031",
                      "tax": [
                        {
                          "OT": 5710
                        }
                      ],
                      "tf": 37031
                    }
                  ],
                  "pr": {
                    "bf": 31321,
                    "tf": 37031,
                    "tax": [
                      {
                        "OT": 5710
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "31321_37031",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "lool981fvt",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            },
            {
              "tid": "qJ84UPOzC2JAAxW5_2",
              "ref": 2,
              "ofcid": "",
              "sdur": "8:10",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "BOM",
                  "dd": "2023-06-30T07:35:00",
                  "ad": "2023-06-30T09:10:00",
                  "rbd": "",
                  "fn": " 163",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "2"
                  },
                  "rph": "",
                  "jsell": "6E~ 163~ ~~HYD~06/30/2023 07:35~BOM~06/30/2023 09:10~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                },
                {
                  "ref": 2,
                  "da": "BOM",
                  "ar": "DOH",
                  "dd": "2023-06-30T17:50:00",
                  "ad": "2023-06-30T18:45:00",
                  "rbd": "",
                  "fn": "1305",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "2"
                  },
                  "rph": "",
                  "jsell": "6E~1305~ ~~BOM~06/30/2023 17:50~DOH~06/30/2023 18:45~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 30621,
                      "fbc": "WCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "30621_36271",
                      "tax": [
                        {
                          "OT": 5650
                        }
                      ],
                      "tf": 36271
                    }
                  ],
                  "pr": {
                    "bf": 30621,
                    "tf": 36271,
                    "tax": [
                      {
                        "OT": 5650
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "30621_36271",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "nng8isz12d",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 31321,
                      "fbc": "WCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "31321_37031",
                      "tax": [
                        {
                          "OT": 5710
                        }
                      ],
                      "tf": 37031
                    }
                  ],
                  "pr": {
                    "bf": 31321,
                    "tf": 37031,
                    "tax": [
                      {
                        "OT": 5710
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "31321_37031",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "4c9a3sjvf2j",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            },
            {
              "tid": "UsS1Py0vUlpmzzNm_2",
              "ref": 2,
              "ofcid": "",
              "sdur": "11:15",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DEL",
                  "dd": "2023-06-30T09:30:00",
                  "ad": "2023-06-30T11:50:00",
                  "rbd": "",
                  "fn": "6204",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "1"
                  },
                  "rph": "",
                  "jsell": "6E~6204~ ~~HYD~06/30/2023 09:30~DEL~06/30/2023 11:50~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                },
                {
                  "ref": 2,
                  "da": "DEL",
                  "ar": "DOH",
                  "dd": "2023-06-30T22:10:00",
                  "ad": "2023-06-30T23:45:00",
                  "rbd": "",
                  "fn": "1311",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "3"
                  },
                  "rph": "",
                  "jsell": "6E~1311~ ~~DEL~06/30/2023 22:10~DOH~06/30/2023 23:45~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 30621,
                      "fbc": "WCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "30621_36271",
                      "tax": [
                        {
                          "OT": 5650
                        }
                      ],
                      "tf": 36271
                    }
                  ],
                  "pr": {
                    "bf": 30621,
                    "tf": 36271,
                    "tax": [
                      {
                        "OT": 5650
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "30621_36271",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "vdri9aaww4",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 31321,
                      "fbc": "WCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "31321_37031",
                      "tax": [
                        {
                          "OT": 5710
                        }
                      ],
                      "tf": 37031
                    }
                  ],
                  "pr": {
                    "bf": 31321,
                    "tf": 37031,
                    "tax": [
                      {
                        "OT": 5710
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "31321_37031",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "chbss2lj5i",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            },
            {
              "tid": "bNWxLEZ72EesKCnu_2",
              "ref": 2,
              "ofcid": "",
              "sdur": "4:40",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "BOM",
                  "dd": "2023-06-30T11:05:00",
                  "ad": "2023-06-30T12:40:00",
                  "rbd": "",
                  "fn": "5384",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "1"
                  },
                  "rph": "",
                  "jsell": "6E~5384~ ~~HYD~06/30/2023 11:05~BOM~06/30/2023 12:40~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                },
                {
                  "ref": 2,
                  "da": "BOM",
                  "ar": "DOH",
                  "dd": "2023-06-30T17:50:00",
                  "ad": "2023-06-30T18:45:00",
                  "rbd": "",
                  "fn": "1305",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "2"
                  },
                  "rph": "",
                  "jsell": "6E~1305~ ~~BOM~06/30/2023 17:50~DOH~06/30/2023 18:45~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 30621,
                      "fbc": "WCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "30621_36271",
                      "tax": [
                        {
                          "OT": 5650
                        }
                      ],
                      "tf": 36271
                    }
                  ],
                  "pr": {
                    "bf": 30621,
                    "tf": 36271,
                    "tax": [
                      {
                        "OT": 5650
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "30621_36271",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "33l49azwdw",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 31321,
                      "fbc": "WCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "31321_37031",
                      "tax": [
                        {
                          "OT": 5710
                        }
                      ],
                      "tf": 37031
                    }
                  ],
                  "pr": {
                    "bf": 31321,
                    "tf": 37031,
                    "tax": [
                      {
                        "OT": 5710
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "31321_37031",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "4mt86jjc2l",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            },
            {
              "tid": "zRip5TGdIFFLMcep_2",
              "ref": 2,
              "ofcid": "",
              "sdur": "9:0",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DEL",
                  "dd": "2023-06-30T11:45:00",
                  "ad": "2023-06-30T14:05:00",
                  "rbd": "",
                  "fn": "2171",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "2"
                  },
                  "rph": "",
                  "jsell": "6E~2171~ ~~HYD~06/30/2023 11:45~DEL~06/30/2023 14:05~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                },
                {
                  "ref": 2,
                  "da": "DEL",
                  "ar": "DOH",
                  "dd": "2023-06-30T22:10:00",
                  "ad": "2023-06-30T23:45:00",
                  "rbd": "",
                  "fn": "1311",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "3"
                  },
                  "rph": "",
                  "jsell": "6E~1311~ ~~DEL~06/30/2023 22:10~DOH~06/30/2023 23:45~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 30621,
                      "fbc": "WCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "30621_36271",
                      "tax": [
                        {
                          "OT": 5650
                        }
                      ],
                      "tf": 36271
                    }
                  ],
                  "pr": {
                    "bf": 30621,
                    "tf": 36271,
                    "tax": [
                      {
                        "OT": 5650
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "30621_36271",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "pb0xgpru6d",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 31321,
                      "fbc": "WCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "31321_37031",
                      "tax": [
                        {
                          "OT": 5710
                        }
                      ],
                      "tf": 37031
                    }
                  ],
                  "pr": {
                    "bf": 31321,
                    "tf": 37031,
                    "tax": [
                      {
                        "OT": 5710
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "31321_37031",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "odm4zt588t",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            },
            {
              "tid": "FyOXvGH4WbLvhflX_2",
              "ref": 2,
              "ofcid": "",
              "sdur": "2:15",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "BOM",
                  "dd": "2023-06-30T13:30:00",
                  "ad": "2023-06-30T15:05:00",
                  "rbd": "",
                  "fn": "6699",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "2"
                  },
                  "rph": "",
                  "jsell": "6E~6699~ ~~HYD~06/30/2023 13:30~BOM~06/30/2023 15:05~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                },
                {
                  "ref": 2,
                  "da": "BOM",
                  "ar": "DOH",
                  "dd": "2023-06-30T17:50:00",
                  "ad": "2023-06-30T18:45:00",
                  "rbd": "",
                  "fn": "1305",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "2"
                  },
                  "rph": "",
                  "jsell": "6E~1305~ ~~BOM~06/30/2023 17:50~DOH~06/30/2023 18:45~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 30621,
                      "fbc": "WCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "30621_36271",
                      "tax": [
                        {
                          "OT": 5650
                        }
                      ],
                      "tf": 36271
                    }
                  ],
                  "pr": {
                    "bf": 30621,
                    "tf": 36271,
                    "tax": [
                      {
                        "OT": 5650
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "30621_36271",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "dr6wl3gdwc",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 31321,
                      "fbc": "WCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "31321_37031",
                      "tax": [
                        {
                          "OT": 5710
                        }
                      ],
                      "tf": 37031
                    }
                  ],
                  "pr": {
                    "bf": 31321,
                    "tf": 37031,
                    "tax": [
                      {
                        "OT": 5710
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "31321_37031",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "u24a9xh3px",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            },
            {
              "tid": "PRxUGyvknPhTT74d_2",
              "ref": 2,
              "ofcid": "",
              "sdur": "10:10",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DEL",
                  "dd": "2023-06-30T16:30:00",
                  "ad": "2023-06-30T18:50:00",
                  "rbd": "",
                  "fn": "6606",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "1"
                  },
                  "rph": "",
                  "jsell": "6E~6606~ ~~HYD~06/30/2023 16:30~DEL~06/30/2023 18:50~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                },
                {
                  "ref": 2,
                  "da": "DEL",
                  "ar": "DOH",
                  "dd": "2023-07-01T04:05:00",
                  "ad": "2023-07-01T05:40:00",
                  "rbd": "",
                  "fn": "1307",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "3"
                  },
                  "rph": "",
                  "jsell": "6E~1307~ ~~DEL~07/01/2023 04:05~DOH~07/01/2023 05:40~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 30621,
                      "fbc": "WCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "30621_36271",
                      "tax": [
                        {
                          "OT": 5650
                        }
                      ],
                      "tf": 36271
                    }
                  ],
                  "pr": {
                    "bf": 30621,
                    "tf": 36271,
                    "tax": [
                      {
                        "OT": 5650
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "30621_36271",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "n5pli6hjka",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 31321,
                      "fbc": "WCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "31321_37031",
                      "tax": [
                        {
                          "OT": 5710
                        }
                      ],
                      "tf": 37031
                    }
                  ],
                  "pr": {
                    "bf": 31321,
                    "tf": 37031,
                    "tax": [
                      {
                        "OT": 5710
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "31321_37031",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "lqh4t0aywc",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            },
            {
              "tid": "oBREi9MbokJxDiPB_2",
              "ref": 2,
              "ofcid": "",
              "sdur": "4:15",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DEL",
                  "dd": "2023-06-30T16:30:00",
                  "ad": "2023-06-30T18:50:00",
                  "rbd": "",
                  "fn": "6606",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "1"
                  },
                  "rph": "",
                  "jsell": "6E~6606~ ~~HYD~06/30/2023 16:30~DEL~06/30/2023 18:50~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                },
                {
                  "ref": 2,
                  "da": "DEL",
                  "ar": "DOH",
                  "dd": "2023-06-30T22:10:00",
                  "ad": "2023-06-30T23:45:00",
                  "rbd": "",
                  "fn": "1311",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "3"
                  },
                  "rph": "",
                  "jsell": "6E~1311~ ~~DEL~06/30/2023 22:10~DOH~06/30/2023 23:45~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 30621,
                      "fbc": "WCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "30621_36271",
                      "tax": [
                        {
                          "OT": 5650
                        }
                      ],
                      "tf": 36271
                    }
                  ],
                  "pr": {
                    "bf": 30621,
                    "tf": 36271,
                    "tax": [
                      {
                        "OT": 5650
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "30621_36271",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "96k14mp97f",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 31321,
                      "fbc": "WCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "31321_37031",
                      "tax": [
                        {
                          "OT": 5710
                        }
                      ],
                      "tf": 37031
                    }
                  ],
                  "pr": {
                    "bf": 31321,
                    "tf": 37031,
                    "tax": [
                      {
                        "OT": 5710
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "31321_37031",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "3fnnk4aftm",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            },
            {
              "tid": "8R1CxgqpslPbaCD0_2",
              "ref": 2,
              "ofcid": "",
              "sdur": "8:25",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DEL",
                  "dd": "2023-06-30T18:15:00",
                  "ad": "2023-06-30T20:30:00",
                  "rbd": "",
                  "fn": "2003",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "2"
                  },
                  "rph": "",
                  "jsell": "6E~2003~ ~~HYD~06/30/2023 18:15~DEL~06/30/2023 20:30~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                },
                {
                  "ref": 2,
                  "da": "DEL",
                  "ar": "DOH",
                  "dd": "2023-07-01T04:05:00",
                  "ad": "2023-07-01T05:40:00",
                  "rbd": "",
                  "fn": "1307",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "3"
                  },
                  "rph": "",
                  "jsell": "6E~1307~ ~~DEL~07/01/2023 04:05~DOH~07/01/2023 05:40~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 30621,
                      "fbc": "WCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "30621_36271",
                      "tax": [
                        {
                          "OT": 5650
                        }
                      ],
                      "tf": 36271
                    }
                  ],
                  "pr": {
                    "bf": 30621,
                    "tf": 36271,
                    "tax": [
                      {
                        "OT": 5650
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "30621_36271",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "zq5tcib4fz",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 31321,
                      "fbc": "WCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "31321_37031",
                      "tax": [
                        {
                          "OT": 5710
                        }
                      ],
                      "tf": 37031
                    }
                  ],
                  "pr": {
                    "bf": 31321,
                    "tf": 37031,
                    "tax": [
                      {
                        "OT": 5710
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "31321_37031",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "pnf2othouo",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            },
            {
              "tid": "K0FhD8e97ceWyIgu_2",
              "ref": 2,
              "ofcid": "",
              "sdur": "5:55",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "BOM",
                  "dd": "2023-06-30T20:50:00",
                  "ad": "2023-06-30T22:05:00",
                  "rbd": "",
                  "fn": "5389",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "1"
                  },
                  "rph": "",
                  "jsell": "6E~5389~ ~~HYD~06/30/2023 20:50~BOM~06/30/2023 22:05~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                },
                {
                  "ref": 2,
                  "da": "BOM",
                  "ar": "DOH",
                  "dd": "2023-07-01T04:50:00",
                  "ad": "2023-07-01T05:45:00",
                  "rbd": "",
                  "fn": "1303",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "2"
                  },
                  "rph": "",
                  "jsell": "6E~1303~ ~~BOM~07/01/2023 04:50~DOH~07/01/2023 05:45~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 30621,
                      "fbc": "WCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "30621_36271",
                      "tax": [
                        {
                          "OT": 5650
                        }
                      ],
                      "tf": 36271
                    }
                  ],
                  "pr": {
                    "bf": 30621,
                    "tf": 36271,
                    "tax": [
                      {
                        "OT": 5650
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "30621_36271",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "1kb4te8hjz",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 31321,
                      "fbc": "WCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "31321_37031",
                      "tax": [
                        {
                          "OT": 5710
                        }
                      ],
                      "tf": 37031
                    }
                  ],
                  "pr": {
                    "bf": 31321,
                    "tf": 37031,
                    "tax": [
                      {
                        "OT": 5710
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "31321_37031",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "vvrt7290oy",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            },
            {
              "tid": "KlKpCuppBnQgH3fJ_2",
              "ref": 2,
              "ofcid": "",
              "sdur": "5:10",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "BOM",
                  "dd": "2023-06-30T21:35:00",
                  "ad": "2023-06-30T23:05:00",
                  "rbd": "",
                  "fn": "6952",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "2"
                  },
                  "rph": "",
                  "jsell": "6E~6952~ ~~HYD~06/30/2023 21:35~BOM~06/30/2023 23:05~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                },
                {
                  "ref": 2,
                  "da": "BOM",
                  "ar": "DOH",
                  "dd": "2023-07-01T04:50:00",
                  "ad": "2023-07-01T05:45:00",
                  "rbd": "",
                  "fn": "1303",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "2"
                  },
                  "rph": "",
                  "jsell": "6E~1303~ ~~BOM~07/01/2023 04:50~DOH~07/01/2023 05:45~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 30621,
                      "fbc": "WCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "30621_36271",
                      "tax": [
                        {
                          "OT": 5650
                        }
                      ],
                      "tf": 36271
                    }
                  ],
                  "pr": {
                    "bf": 30621,
                    "tf": 36271,
                    "tax": [
                      {
                        "OT": 5650
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "30621_36271",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "gtdnzbtu3q",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 31321,
                      "fbc": "WCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "31321_37031",
                      "tax": [
                        {
                          "OT": 5710
                        }
                      ],
                      "tf": 37031
                    }
                  ],
                  "pr": {
                    "bf": 31321,
                    "tf": 37031,
                    "tax": [
                      {
                        "OT": 5710
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "31321_37031",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "5ghl2xdy2b",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            },
            {
              "tid": "depNZL0fxA3JvXLA_2",
              "ref": 2,
              "ofcid": "",
              "sdur": "4:45",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DEL",
                  "dd": "2023-06-30T21:55:00",
                  "ad": "2023-07-01T00:05:00",
                  "rbd": "",
                  "fn": "5605",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "3"
                  },
                  "rph": "",
                  "jsell": "6E~5605~ ~~HYD~06/30/2023 21:55~DEL~07/01/2023 00:05~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                },
                {
                  "ref": 2,
                  "da": "DEL",
                  "ar": "DOH",
                  "dd": "2023-07-01T04:05:00",
                  "ad": "2023-07-01T05:40:00",
                  "rbd": "",
                  "fn": "1307",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "3"
                  },
                  "rph": "",
                  "jsell": "6E~1307~ ~~DEL~07/01/2023 04:05~DOH~07/01/2023 05:40~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 30621,
                      "fbc": "WCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "30621_36271",
                      "tax": [
                        {
                          "OT": 5650
                        }
                      ],
                      "tf": 36271
                    }
                  ],
                  "pr": {
                    "bf": 30621,
                    "tf": 36271,
                    "tax": [
                      {
                        "OT": 5650
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "30621_36271",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "8vu3tq8vy4",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 31321,
                      "fbc": "WCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "31321_37031",
                      "tax": [
                        {
                          "OT": 5710
                        }
                      ],
                      "tf": 37031
                    }
                  ],
                  "pr": {
                    "bf": 31321,
                    "tf": 37031,
                    "tax": [
                      {
                        "OT": 5710
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "31321_37031",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "5ph8e9qdp7",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            },
            {
              "tid": "sGT8ieJ7GaZNTGeH_2",
              "ref": 2,
              "ofcid": "",
              "sdur": "4:5",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "BOM",
                  "dd": "2023-06-30T22:40:00",
                  "ad": "2023-07-01T00:15:00",
                  "rbd": "",
                  "fn": " 267",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "2"
                  },
                  "rph": "",
                  "jsell": "6E~ 267~ ~~HYD~06/30/2023 22:40~BOM~07/01/2023 00:15~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                },
                {
                  "ref": 2,
                  "da": "BOM",
                  "ar": "DOH",
                  "dd": "2023-07-01T04:50:00",
                  "ad": "2023-07-01T05:45:00",
                  "rbd": "",
                  "fn": "1303",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "2"
                  },
                  "rph": "",
                  "jsell": "6E~1303~ ~~BOM~07/01/2023 04:50~DOH~07/01/2023 05:45~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 30621,
                      "fbc": "WCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "30621_36271",
                      "tax": [
                        {
                          "OT": 5650
                        }
                      ],
                      "tf": 36271
                    }
                  ],
                  "pr": {
                    "bf": 30621,
                    "tf": 36271,
                    "tax": [
                      {
                        "OT": 5650
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "30621_36271",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "zer0qd4aqi",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 31321,
                      "fbc": "WCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "31321_37031",
                      "tax": [
                        {
                          "OT": 5710
                        }
                      ],
                      "tf": 37031
                    }
                  ],
                  "pr": {
                    "bf": 31321,
                    "tf": 37031,
                    "tax": [
                      {
                        "OT": 5710
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "31321_37031",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "fnc5ahernm",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            },
            {
              "tid": "HWAt13e4AD3bXRoB_2",
              "ref": 2,
              "ofcid": "",
              "sdur": "3:55",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DEL",
                  "dd": "2023-06-30T22:45:00",
                  "ad": "2023-07-01T01:00:00",
                  "rbd": "",
                  "fn": "6146",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "1"
                  },
                  "rph": "",
                  "jsell": "6E~6146~ ~~HYD~06/30/2023 22:45~DEL~07/01/2023 01:00~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                },
                {
                  "ref": 2,
                  "da": "DEL",
                  "ar": "DOH",
                  "dd": "2023-07-01T04:05:00",
                  "ad": "2023-07-01T05:40:00",
                  "rbd": "",
                  "fn": "1307",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "3"
                  },
                  "rph": "",
                  "jsell": "6E~1307~ ~~DEL~07/01/2023 04:05~DOH~07/01/2023 05:40~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 30621,
                      "fbc": "WCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "30621_36271",
                      "tax": [
                        {
                          "OT": 5650
                        }
                      ],
                      "tf": 36271
                    }
                  ],
                  "pr": {
                    "bf": 30621,
                    "tf": 36271,
                    "tax": [
                      {
                        "OT": 5650
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "30621_36271",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "0klehe0j72",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 31321,
                      "fbc": "WCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "31321_37031",
                      "tax": [
                        {
                          "OT": 5710
                        }
                      ],
                      "tf": 37031
                    }
                  ],
                  "pr": {
                    "bf": 31321,
                    "tf": 37031,
                    "tax": [
                      {
                        "OT": 5710
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "31321_37031",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "2pcxkgeo0r",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            }
          ],
          "paxpr": [
            {
              "bf": 59350,
              "tf": 69169,
              "fbc": "",
              "psup_fare": "",
              "tax": [
                [
                  {
                    "OT": 4169
                  }
                ],
                [
                  {
                    "OT": 5650
                  }
                ]
              ],
              "ptype": "ADT",
              "scur": "INR",
              "sup_cur_fare": ""
            }
          ],
          "pr": {
            "bf": 28729,
            "tf": 32898,
            "tax": [
              {
                "OT": 4169
              },
              {
                "OT": 5650
              }
            ],
            "scur": "INR",
            "sup_fare": "",
            "sup_cur_fare": "",
            "cabb": "",
            "chekb": "",
            "seats": "",
            "meals": "",
            "chanb": "",
            "ftyp": ""
          },
          "sup": "4",
          "airlineList": "6E"
        },
        {
          "srcorigns": [
            {
              "tid": "v1kPzTRTv4IsnsuD_1",
              "ref": 1,
              "sdur": "08:20",
              "re": "Refundable Before Departure",
              "stop": 1,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "AI",
              "seg": [
                {
                  "ref": 1,
                  "da": "DOH",
                  "ar": "DEL",
                  "dd": "2023-06-29T22:35:00",
                  "ad": "2023-06-30T05:05:00",
                  "rbd": "L",
                  "fn": "972",
                  "eq": "32N",
                  "ac": "AI",
                  "ma": "AI",
                  "seat": "1",
                  "oa": "AI",
                  "fa": [],
                  "fdur": "04:00",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": "3"
                  }
                },
                {
                  "ref": 2,
                  "da": "DEL",
                  "ar": "HYD",
                  "dd": "2023-06-30T07:10:00",
                  "ad": "2023-06-30T09:25:00",
                  "rbd": "Q",
                  "fn": "560",
                  "trn": "3",
                  "eq": "32N",
                  "ac": "AI",
                  "ma": "AI",
                  "seat": "9",
                  "oa": "AI",
                  "fa": [],
                  "fdur": "02:15",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "3",
                    "arr": ""
                  }
                }
              ],
              "flightNumbers": "972/560",
              "bgd": [
                {
                  "qun": "30",
                  "wt": "kg"
                }
              ],
              "paxpr": [
                {
                  "tf": 17248,
                  "tax": [
                    {
                      "YR": 448
                    },
                    {
                      "YR": 224
                    },
                    {
                      "OT": 3136
                    }
                  ],
                  "bf": 13440,
                  "fbc": "LOWDHDL",
                  "fareType": "RP",
                  "pusup_fare": "600_770",
                  "psup_fare": "13440_17248",
                  "ptype": "ADT"
                }
              ],
              "pr": {
                "bf": 13440,
                "tf": 17248,
                "tax": [
                  {
                    "YR": 448
                  },
                  {
                    "YR": 224
                  },
                  {
                    "OT": 3136
                  }
                ],
                "scur": "QAR",
                "ucur": "INR",
                "exchange_rate": 22.4,
                "sup_fare": "600_770"
              }
            },
            {
              "tid": "QKlLpgcNOFScR7vm_1",
              "ref": 1,
              "sdur": "10:45",
              "re": "Refundable Before Departure",
              "stop": 1,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "AI",
              "seg": [
                {
                  "ref": 1,
                  "da": "DOH",
                  "ar": "DEL",
                  "dd": "2023-06-29T22:35:00",
                  "ad": "2023-06-30T05:05:00",
                  "rbd": "L",
                  "fn": "972",
                  "eq": "32N",
                  "ac": "AI",
                  "ma": "AI",
                  "seat": "1",
                  "oa": "AI",
                  "fa": [],
                  "fdur": "04:00",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": "3"
                  }
                },
                {
                  "ref": 2,
                  "da": "DEL",
                  "ar": "HYD",
                  "dd": "2023-06-30T09:45:00",
                  "ad": "2023-06-30T11:50:00",
                  "rbd": "Q",
                  "fn": "542",
                  "trn": "3",
                  "eq": "321",
                  "ac": "AI",
                  "ma": "AI",
                  "seat": "9",
                  "oa": "AI",
                  "fa": [],
                  "fdur": "02:05",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "3",
                    "arr": ""
                  }
                }
              ],
              "flightNumbers": "972/542",
              "bgd": [
                {
                  "qun": "30",
                  "wt": "kg"
                }
              ],
              "paxpr": [
                {
                  "tf": 17248,
                  "tax": [
                    {
                      "YR": 448
                    },
                    {
                      "YR": 224
                    },
                    {
                      "OT": 3136
                    }
                  ],
                  "bf": 13440,
                  "fbc": "LOWDHDL",
                  "fareType": "RP",
                  "pusup_fare": "600_770",
                  "psup_fare": "13440_17248",
                  "ptype": "ADT"
                }
              ],
              "pr": {
                "bf": 13440,
                "tf": 17248,
                "tax": [
                  {
                    "YR": 448
                  },
                  {
                    "YR": 224
                  },
                  {
                    "OT": 3136
                  }
                ],
                "scur": "QAR",
                "ucur": "INR",
                "exchange_rate": 22.4,
                "sup_fare": "600_770"
              }
            },
            {
              "tid": "1JQP8kNJKeRxapn8_1",
              "ref": 1,
              "sdur": "18:05",
              "re": "Refundable Before Departure",
              "stop": 1,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "AI",
              "seg": [
                {
                  "ref": 1,
                  "da": "DOH",
                  "ar": "DEL",
                  "dd": "2023-06-29T22:35:00",
                  "ad": "2023-06-30T05:05:00",
                  "rbd": "L",
                  "fn": "972",
                  "eq": "32N",
                  "ac": "AI",
                  "ma": "AI",
                  "seat": "1",
                  "oa": "AI",
                  "fa": [],
                  "fdur": "04:00",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": "3"
                  }
                },
                {
                  "ref": 2,
                  "da": "DEL",
                  "ar": "HYD",
                  "dd": "2023-06-30T16:50:00",
                  "ad": "2023-06-30T19:10:00",
                  "rbd": "Q",
                  "fn": "544",
                  "trn": "3",
                  "eq": "32N",
                  "ac": "AI",
                  "ma": "AI",
                  "seat": "9",
                  "oa": "AI",
                  "fa": [],
                  "fdur": "02:20",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "3",
                    "arr": ""
                  }
                }
              ],
              "flightNumbers": "972/544",
              "bgd": [
                {
                  "qun": "30",
                  "wt": "kg"
                }
              ],
              "paxpr": [
                {
                  "tf": 17248,
                  "tax": [
                    {
                      "YR": 448
                    },
                    {
                      "YR": 224
                    },
                    {
                      "OT": 3136
                    }
                  ],
                  "bf": 13440,
                  "fbc": "LOWDHDL",
                  "fareType": "RP",
                  "pusup_fare": "600_770",
                  "psup_fare": "13440_17248",
                  "ptype": "ADT"
                }
              ],
              "pr": {
                "bf": 13440,
                "tf": 17248,
                "tax": [
                  {
                    "YR": 448
                  },
                  {
                    "YR": 224
                  },
                  {
                    "OT": 3136
                  }
                ],
                "scur": "QAR",
                "ucur": "INR",
                "exchange_rate": 22.4,
                "sup_fare": "600_770"
              }
            },
            {
              "tid": "7NBv1RUInLrzLcP9_1",
              "ref": 1,
              "sdur": "22:40",
              "re": "Refundable Before Departure",
              "stop": 1,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "AI",
              "seg": [
                {
                  "ref": 1,
                  "da": "DOH",
                  "ar": "DEL",
                  "dd": "2023-06-29T22:35:00",
                  "ad": "2023-06-30T05:05:00",
                  "rbd": "L",
                  "fn": "972",
                  "eq": "32N",
                  "ac": "AI",
                  "ma": "AI",
                  "seat": "1",
                  "oa": "AI",
                  "fa": [],
                  "fdur": "04:00",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": "3"
                  }
                },
                {
                  "ref": 2,
                  "da": "DEL",
                  "ar": "HYD",
                  "dd": "2023-06-30T21:30:00",
                  "ad": "2023-06-30T23:45:00",
                  "rbd": "Q",
                  "fn": "839",
                  "trn": "3",
                  "eq": "321",
                  "ac": "AI",
                  "ma": "AI",
                  "seat": "9",
                  "oa": "AI",
                  "fa": [],
                  "fdur": "02:15",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "3",
                    "arr": ""
                  }
                }
              ],
              "flightNumbers": "972/839",
              "bgd": [
                {
                  "qun": "30",
                  "wt": "kg"
                }
              ],
              "paxpr": [
                {
                  "tf": 17248,
                  "tax": [
                    {
                      "YR": 448
                    },
                    {
                      "YR": 224
                    },
                    {
                      "OT": 3136
                    }
                  ],
                  "bf": 13440,
                  "fbc": "LOWDHDL",
                  "fareType": "RP",
                  "pusup_fare": "600_770",
                  "psup_fare": "13440_17248",
                  "ptype": "ADT"
                }
              ],
              "pr": {
                "bf": 13440,
                "tf": 17248,
                "tax": [
                  {
                    "YR": 448
                  },
                  {
                    "YR": 224
                  },
                  {
                    "OT": 3136
                  }
                ],
                "scur": "QAR",
                "ucur": "INR",
                "exchange_rate": 22.4,
                "sup_fare": "600_770"
              }
            }
          ],
          "destorigins": [
            {
              "tid": "PLBA29xGTttzyO4y_1",
              "ref": 1,
              "sdur": "09:25",
              "re": "Refundable Before Departure",
              "stop": 1,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "AI",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DEL",
                  "dd": "2023-06-30T14:30:00",
                  "ad": "2023-06-30T16:55:00",
                  "rbd": "Q",
                  "fn": "523",
                  "eq": "319",
                  "ac": "AI",
                  "ma": "AI",
                  "seat": "1",
                  "oa": "AI",
                  "fa": [],
                  "fdur": "02:25",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": "3"
                  }
                },
                {
                  "ref": 2,
                  "da": "DEL",
                  "ar": "DOH",
                  "dd": "2023-06-30T19:50:00",
                  "ad": "2023-06-30T21:25:00",
                  "rbd": "U",
                  "fn": "971",
                  "trn": "3",
                  "eq": "32N",
                  "ac": "AI",
                  "ma": "AI",
                  "seat": "2",
                  "oa": "AI",
                  "fa": [],
                  "fdur": "04:05",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "3",
                    "arr": ""
                  }
                }
              ],
              "flightNumbers": "523/971",
              "bgd": [
                {
                  "qun": "30",
                  "wt": "kg"
                }
              ],
              "paxpr": [
                {
                  "tf": 16576,
                  "tax": [
                    {
                      "YR": 448
                    },
                    {
                      "YR": 224
                    },
                    {
                      "OT": 4032
                    }
                  ],
                  "bf": 11872,
                  "fbc": "UOWDLDH",
                  "fareType": "RP",
                  "pusup_fare": "530_740",
                  "psup_fare": "11872_16576",
                  "ptype": "ADT"
                }
              ],
              "pr": {
                "bf": 11872,
                "tf": 16576,
                "tax": [
                  {
                    "YR": 448
                  },
                  {
                    "YR": 224
                  },
                  {
                    "OT": 4032
                  }
                ],
                "scur": "QAR",
                "ucur": "INR",
                "exchange_rate": 22.4,
                "sup_fare": "530_740"
              }
            }
          ],
          "pr": {
            "bf": 25312,
            "tf": 33824,
            "tax": [
              {
                "YR": 448
              },
              {
                "YR": 224
              },
              {
                "OT": 3136
              },
              {
                "YR": 448
              },
              {
                "YR": 224
              },
              {
                "OT": 4032
              }
            ]
          },
          "paxpr": [
            {
              "bf": 25312,
              "tf": 33824,
              "tax": [
                {
                  "YR": 448
                },
                {
                  "YR": 224
                },
                {
                  "OT": 3136
                },
                {
                  "YR": 448
                },
                {
                  "YR": 224
                },
                {
                  "OT": 4032
                }
              ]
            }
          ],
          "sty": "split",
          "sup": "0/0",
          "airlineList": "AI_AI",
          "mix_carrier": true
        },
        {
          "srcorigns": [
            {
              "tid": "DMtfeo3xKdTh0K3d_1",
              "ref": 1,
              "ofcid": "",
              "sdur": "14:10",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "DOH",
                  "ar": "BOM",
                  "dd": "2023-06-29T19:45:00",
                  "ad": "2023-06-30T01:55:00",
                  "rbd": "",
                  "fn": "1306",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "2"
                  },
                  "rph": "",
                  "jsell": "6E~1306~ ~~DOH~06/29/2023 19:45~BOM~06/30/2023 01:55~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                },
                {
                  "ref": 2,
                  "da": "BOM",
                  "ar": "HYD",
                  "dd": "2023-06-30T05:20:00",
                  "ad": "2023-06-30T06:55:00",
                  "rbd": "",
                  "fn": " 461",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "2"
                  },
                  "rph": "",
                  "jsell": "6E~ 461~ ~~BOM~06/30/2023 05:20~HYD~06/30/2023 06:55~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "1~W~ ~6E~W0INT~5000~~0~7~~^2~L~ ~6E~LCIP~1070~~0~12~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 29825,
                      "fbc": "W0INT/LCIP",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "29825_34032",
                      "tax": [
                        {
                          "OT": 4207
                        }
                      ],
                      "tf": 34032
                    }
                  ],
                  "pr": {
                    "bf": 29825,
                    "tf": 34032,
                    "tax": [
                      {
                        "OT": 4207
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "29825_34032",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "yn8v424vz8",
                  "ruleNumber": "5000",
                  "productClass": "R"
                },
                {
                  "fareKey": "1~W~ ~6E~WUINT~5003~~0~12~~^2~L~ ~6E~LUIP~2009~~0~5~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 31125,
                      "fbc": "WUINT/LUIP",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "31125_35377",
                      "tax": [
                        {
                          "OT": 4252
                        }
                      ],
                      "tf": 35377
                    }
                  ],
                  "pr": {
                    "bf": 31125,
                    "tf": 35377,
                    "tax": [
                      {
                        "OT": 4252
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "31125_35377",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "zwm7n6jg5ui",
                  "ruleNumber": "5003",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            }
          ],
          "destorigins": [
            {
              "tid": "yOwyvKp2V1JvywOh_2",
              "ref": 2,
              "ofcid": "",
              "sdur": "6:30",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "MAA",
                  "dd": "2023-06-30T14:05:00",
                  "ad": "2023-06-30T15:20:00",
                  "rbd": "",
                  "fn": " 514",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "1"
                  },
                  "rph": "",
                  "jsell": "6E~ 514~ ~~HYD~06/30/2023 14:05~MAA~06/30/2023 15:20~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                },
                {
                  "ref": 2,
                  "da": "MAA",
                  "ar": "DOH",
                  "dd": "2023-06-30T21:30:00",
                  "ad": "2023-06-30T23:35:00",
                  "rbd": "",
                  "fn": "1321",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "4"
                  },
                  "rph": "",
                  "jsell": "6E~1321~ ~~MAA~06/30/2023 21:30~DOH~06/30/2023 23:35~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~R~ ~6E~RCINT~6200~~0~66~~X^0~R~ ~6E~RCINT~6200~~0~66~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 8036,
                      "fbc": "RCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "8036_11726",
                      "tax": [
                        {
                          "OT": 3690
                        }
                      ],
                      "tf": 11726
                    }
                  ],
                  "pr": {
                    "bf": 8036,
                    "tf": 11726,
                    "tax": [
                      {
                        "OT": 3690
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "8036_11726",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "2dn6qlguzy",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~R~ ~6E~RCUINT~6202~~0~5~~X^0~R~ ~6E~RCUINT~6202~~0~5~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 8736,
                      "fbc": "RCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "8736_12487",
                      "tax": [
                        {
                          "OT": 3751
                        }
                      ],
                      "tf": 12487
                    }
                  ],
                  "pr": {
                    "bf": 8736,
                    "tf": 12487,
                    "tax": [
                      {
                        "OT": 3751
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "8736_12487",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "fa33pakwzs",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            },
            {
              "tid": "elaSsV87lEPghy7g_2",
              "ref": 2,
              "ofcid": "",
              "sdur": "4:0",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "MAA",
                  "dd": "2023-06-30T16:35:00",
                  "ad": "2023-06-30T17:45:00",
                  "rbd": "",
                  "fn": " 668",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "1"
                  },
                  "rph": "",
                  "jsell": "6E~ 668~ ~~HYD~06/30/2023 16:35~MAA~06/30/2023 17:45~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                },
                {
                  "ref": 2,
                  "da": "MAA",
                  "ar": "DOH",
                  "dd": "2023-06-30T21:30:00",
                  "ad": "2023-06-30T23:35:00",
                  "rbd": "",
                  "fn": "1321",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "4"
                  },
                  "rph": "",
                  "jsell": "6E~1321~ ~~MAA~06/30/2023 21:30~DOH~06/30/2023 23:35~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~R~ ~6E~RCINT~6200~~0~66~~X^0~R~ ~6E~RCINT~6200~~0~66~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 8036,
                      "fbc": "RCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "8036_11726",
                      "tax": [
                        {
                          "OT": 3690
                        }
                      ],
                      "tf": 11726
                    }
                  ],
                  "pr": {
                    "bf": 8036,
                    "tf": 11726,
                    "tax": [
                      {
                        "OT": 3690
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "8036_11726",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "9g9w4k9lgs",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~R~ ~6E~RCUINT~6202~~0~5~~X^0~R~ ~6E~RCUINT~6202~~0~5~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 8736,
                      "fbc": "RCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "8736_12487",
                      "tax": [
                        {
                          "OT": 3751
                        }
                      ],
                      "tf": 12487
                    }
                  ],
                  "pr": {
                    "bf": 8736,
                    "tf": 12487,
                    "tax": [
                      {
                        "OT": 3751
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "8736_12487",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "sal9z6ww2zf",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            },
            {
              "tid": "A4BB6OZ1BUi93rz0_2",
              "ref": 2,
              "ofcid": "",
              "sdur": "2:55",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "MAA",
                  "dd": "2023-06-30T17:40:00",
                  "ad": "2023-06-30T18:55:00",
                  "rbd": "",
                  "fn": " 102",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "1"
                  },
                  "rph": "",
                  "jsell": "6E~ 102~ ~~HYD~06/30/2023 17:40~MAA~06/30/2023 18:55~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                },
                {
                  "ref": 2,
                  "da": "MAA",
                  "ar": "DOH",
                  "dd": "2023-06-30T21:30:00",
                  "ad": "2023-06-30T23:35:00",
                  "rbd": "",
                  "fn": "1321",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "4"
                  },
                  "rph": "",
                  "jsell": "6E~1321~ ~~MAA~06/30/2023 21:30~DOH~06/30/2023 23:35~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~R~ ~6E~RCINT~6200~~0~66~~X^0~R~ ~6E~RCINT~6200~~0~66~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 8036,
                      "fbc": "RCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "8036_11726",
                      "tax": [
                        {
                          "OT": 3690
                        }
                      ],
                      "tf": 11726
                    }
                  ],
                  "pr": {
                    "bf": 8036,
                    "tf": 11726,
                    "tax": [
                      {
                        "OT": 3690
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "8036_11726",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "v3ki7fp2wo",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~R~ ~6E~RCUINT~6202~~0~5~~X^0~R~ ~6E~RCUINT~6202~~0~5~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 8736,
                      "fbc": "RCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "8736_12487",
                      "tax": [
                        {
                          "OT": 3751
                        }
                      ],
                      "tf": 12487
                    }
                  ],
                  "pr": {
                    "bf": 8736,
                    "tf": 12487,
                    "tax": [
                      {
                        "OT": 3751
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "8736_12487",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "zvmwqypjzg",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            }
          ],
          "paxpr": [
            {
              "bf": 37861,
              "tf": 45758,
              "fbc": "",
              "psup_fare": "",
              "tax": [
                [
                  {
                    "OT": 4207
                  }
                ],
                [
                  {
                    "OT": 3690
                  }
                ]
              ],
              "ptype": "ADT",
              "scur": "INR",
              "sup_cur_fare": ""
            }
          ],
          "pr": {
            "bf": 29825,
            "tf": 34032,
            "tax": [
              {
                "OT": 4207
              },
              {
                "OT": 3690
              }
            ],
            "scur": "INR",
            "sup_fare": "",
            "sup_cur_fare": "",
            "cabb": "",
            "chekb": "",
            "seats": "",
            "meals": "",
            "chanb": "",
            "ftyp": ""
          },
          "sup": "4",
          "airlineList": "6E"
        },
        {
          "srcorigns": [
            {
              "tid": "A2QWiQbx6GkvtdUq_1",
              "ref": 1,
              "ofcid": "",
              "sdur": "14:10",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "DOH",
                  "ar": "BOM",
                  "dd": "2023-06-29T19:45:00",
                  "ad": "2023-06-30T01:55:00",
                  "rbd": "",
                  "fn": "1306",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "2"
                  },
                  "rph": "",
                  "jsell": "6E~1306~ ~~DOH~06/29/2023 19:45~BOM~06/30/2023 01:55~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                },
                {
                  "ref": 2,
                  "da": "BOM",
                  "ar": "HYD",
                  "dd": "2023-06-30T05:20:00",
                  "ad": "2023-06-30T06:55:00",
                  "rbd": "",
                  "fn": " 461",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "2"
                  },
                  "rph": "",
                  "jsell": "6E~ 461~ ~~BOM~06/30/2023 05:20~HYD~06/30/2023 06:55~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "1~W~ ~6E~W0INT~5000~~0~7~~^2~L~ ~6E~LCIP~1070~~0~12~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 29825,
                      "fbc": "W0INT/LCIP",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "29825_34032",
                      "tax": [
                        {
                          "OT": 4207
                        }
                      ],
                      "tf": 34032
                    }
                  ],
                  "pr": {
                    "bf": 29825,
                    "tf": 34032,
                    "tax": [
                      {
                        "OT": 4207
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "29825_34032",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "yn8v424vz8",
                  "ruleNumber": "5000",
                  "productClass": "R"
                },
                {
                  "fareKey": "1~W~ ~6E~WUINT~5003~~0~12~~^2~L~ ~6E~LUIP~2009~~0~5~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 31125,
                      "fbc": "WUINT/LUIP",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "31125_35377",
                      "tax": [
                        {
                          "OT": 4252
                        }
                      ],
                      "tf": 35377
                    }
                  ],
                  "pr": {
                    "bf": 31125,
                    "tf": 35377,
                    "tax": [
                      {
                        "OT": 4252
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "31125_35377",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "zwm7n6jg5ui",
                  "ruleNumber": "5003",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            }
          ],
          "destorigins": [
            {
              "tid": "xH4NqZs0d0S4eGhc_2",
              "ref": 2,
              "ofcid": "",
              "sdur": "0:-80",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DOH",
                  "dd": "2023-06-30T04:10:00",
                  "ad": "2023-06-30T05:50:00",
                  "rbd": "",
                  "fn": "1313",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {},
                  "rph": "",
                  "jsell": "6E~1313~ ~~HYD~06/30/2023 04:10~DOH~06/30/2023 05:50~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~A~ ~6E~A0INT~5000~~0~2~~X",
                  "pr": {
                    "bf": 26311,
                    "tf": 31534,
                    "tax": [
                      {
                        "OT": 5223
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "26311_31534",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "paxpr": [
                    {
                      "bf": 26311,
                      "tf": 31534,
                      "tax": [
                        {
                          "OT": 5223
                        }
                      ],
                      "fbc": "A0INT",
                      "ptype": "ADT",
                      "scur": "INR",
                      "sup_fare": "26311_31534"
                    }
                  ],
                  "routeId": "trq9v8j26a",
                  "productClass": "R",
                  "ruleNumber": "5000"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 0
            },
            {
              "tid": "yFxzXQkcvZQVBW4m_2",
              "ref": 2,
              "ofcid": "",
              "sdur": "0:-80",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DOH",
                  "dd": "2023-06-30T17:05:00",
                  "ad": "2023-06-30T18:45:00",
                  "rbd": "",
                  "fn": "1315",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {},
                  "rph": "",
                  "jsell": "6E~1315~ ~~HYD~06/30/2023 17:05~DOH~06/30/2023 18:45~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~A~ ~6E~A0INT~5000~~0~2~~X",
                  "pr": {
                    "bf": 26311,
                    "tf": 31534,
                    "tax": [
                      {
                        "OT": 5223
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "26311_31534",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "paxpr": [
                    {
                      "bf": 26311,
                      "tf": 31534,
                      "tax": [
                        {
                          "OT": 5223
                        }
                      ],
                      "fbc": "A0INT",
                      "ptype": "ADT",
                      "scur": "INR",
                      "sup_fare": "26311_31534"
                    }
                  ],
                  "routeId": "j0c0nzrshs",
                  "productClass": "R",
                  "ruleNumber": "5000"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 0
            },
            {
              "tid": "lVh4lIglb8OHqb5R_2",
              "ref": 2,
              "ofcid": "",
              "sdur": "0:-80",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DOH",
                  "dd": "2023-06-30T21:55:00",
                  "ad": "2023-06-30T23:35:00",
                  "rbd": "",
                  "fn": "1317",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {},
                  "rph": "",
                  "jsell": "6E~1317~ ~~HYD~06/30/2023 21:55~DOH~06/30/2023 23:35~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~A~ ~6E~A0INT~5000~~0~2~~X",
                  "pr": {
                    "bf": 26311,
                    "tf": 31534,
                    "tax": [
                      {
                        "OT": 5223
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "26311_31534",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "paxpr": [
                    {
                      "bf": 26311,
                      "tf": 31534,
                      "tax": [
                        {
                          "OT": 5223
                        }
                      ],
                      "fbc": "A0INT",
                      "ptype": "ADT",
                      "scur": "INR",
                      "sup_fare": "26311_31534"
                    }
                  ],
                  "routeId": "rqkxkh7e05l",
                  "productClass": "R",
                  "ruleNumber": "5000"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 0
            }
          ],
          "paxpr": [
            {
              "bf": 56136,
              "tf": 65566,
              "fbc": "",
              "psup_fare": "",
              "tax": [
                [
                  {
                    "OT": 4207
                  }
                ],
                [
                  {
                    "OT": 5223
                  }
                ]
              ],
              "ptype": "ADT",
              "scur": "INR",
              "sup_cur_fare": ""
            }
          ],
          "pr": {
            "bf": 29825,
            "tf": 34032,
            "tax": [
              {
                "OT": 4207
              },
              {
                "OT": 5223
              }
            ],
            "scur": "INR",
            "sup_fare": "",
            "sup_cur_fare": "",
            "cabb": "",
            "chekb": "",
            "seats": "",
            "meals": "",
            "chanb": "",
            "ftyp": ""
          },
          "sup": "4",
          "airlineList": "6E"
        },
        {
          "srcorigns": [
            {
              "tid": "WqSKzTOubu2gt9rI_1",
              "ref": 1,
              "ofcid": "",
              "sdur": "14:10",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "DOH",
                  "ar": "BOM",
                  "dd": "2023-06-29T19:45:00",
                  "ad": "2023-06-30T01:55:00",
                  "rbd": "",
                  "fn": "1306",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "2"
                  },
                  "rph": "",
                  "jsell": "6E~1306~ ~~DOH~06/29/2023 19:45~BOM~06/30/2023 01:55~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                },
                {
                  "ref": 2,
                  "da": "BOM",
                  "ar": "HYD",
                  "dd": "2023-06-30T05:20:00",
                  "ad": "2023-06-30T06:55:00",
                  "rbd": "",
                  "fn": " 461",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "2"
                  },
                  "rph": "",
                  "jsell": "6E~ 461~ ~~BOM~06/30/2023 05:20~HYD~06/30/2023 06:55~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "1~W~ ~6E~W0INT~5000~~0~7~~^2~L~ ~6E~LCIP~1070~~0~12~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 29825,
                      "fbc": "W0INT/LCIP",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "29825_34032",
                      "tax": [
                        {
                          "OT": 4207
                        }
                      ],
                      "tf": 34032
                    }
                  ],
                  "pr": {
                    "bf": 29825,
                    "tf": 34032,
                    "tax": [
                      {
                        "OT": 4207
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "29825_34032",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "yn8v424vz8",
                  "ruleNumber": "5000",
                  "productClass": "R"
                },
                {
                  "fareKey": "1~W~ ~6E~WUINT~5003~~0~12~~^2~L~ ~6E~LUIP~2009~~0~5~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 31125,
                      "fbc": "WUINT/LUIP",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "31125_35377",
                      "tax": [
                        {
                          "OT": 4252
                        }
                      ],
                      "tf": 35377
                    }
                  ],
                  "pr": {
                    "bf": 31125,
                    "tf": 35377,
                    "tax": [
                      {
                        "OT": 4252
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "31125_35377",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "zwm7n6jg5ui",
                  "ruleNumber": "5003",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            }
          ],
          "destorigins": [
            {
              "tid": "LTqqpUwE2EISH3tK_2",
              "ref": 2,
              "ofcid": "",
              "sdur": "9:0",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "BOM",
                  "dd": "2023-06-30T06:45:00",
                  "ad": "2023-06-30T08:05:00",
                  "rbd": "",
                  "fn": " 286",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "2"
                  },
                  "rph": "",
                  "jsell": "6E~ 286~ ~~HYD~06/30/2023 06:45~BOM~06/30/2023 08:05~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                },
                {
                  "ref": 2,
                  "da": "BOM",
                  "ar": "DOH",
                  "dd": "2023-06-30T17:50:00",
                  "ad": "2023-06-30T18:45:00",
                  "rbd": "",
                  "fn": "1305",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "2"
                  },
                  "rph": "",
                  "jsell": "6E~1305~ ~~BOM~06/30/2023 17:50~DOH~06/30/2023 18:45~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 30621,
                      "fbc": "WCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "30621_36271",
                      "tax": [
                        {
                          "OT": 5650
                        }
                      ],
                      "tf": 36271
                    }
                  ],
                  "pr": {
                    "bf": 30621,
                    "tf": 36271,
                    "tax": [
                      {
                        "OT": 5650
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "30621_36271",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "jmh1fh2loa",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 31321,
                      "fbc": "WCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "31321_37031",
                      "tax": [
                        {
                          "OT": 5710
                        }
                      ],
                      "tf": 37031
                    }
                  ],
                  "pr": {
                    "bf": 31321,
                    "tf": 37031,
                    "tax": [
                      {
                        "OT": 5710
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "31321_37031",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "v11jbsbhmp",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            },
            {
              "tid": "cqxpeN4F1SHfLPvW_2",
              "ref": 2,
              "ofcid": "",
              "sdur": "8:10",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "BOM",
                  "dd": "2023-06-30T07:35:00",
                  "ad": "2023-06-30T09:10:00",
                  "rbd": "",
                  "fn": " 163",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "2"
                  },
                  "rph": "",
                  "jsell": "6E~ 163~ ~~HYD~06/30/2023 07:35~BOM~06/30/2023 09:10~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                },
                {
                  "ref": 2,
                  "da": "BOM",
                  "ar": "DOH",
                  "dd": "2023-06-30T17:50:00",
                  "ad": "2023-06-30T18:45:00",
                  "rbd": "",
                  "fn": "1305",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "2"
                  },
                  "rph": "",
                  "jsell": "6E~1305~ ~~BOM~06/30/2023 17:50~DOH~06/30/2023 18:45~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 30621,
                      "fbc": "WCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "30621_36271",
                      "tax": [
                        {
                          "OT": 5650
                        }
                      ],
                      "tf": 36271
                    }
                  ],
                  "pr": {
                    "bf": 30621,
                    "tf": 36271,
                    "tax": [
                      {
                        "OT": 5650
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "30621_36271",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "85d8owvl1h",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 31321,
                      "fbc": "WCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "31321_37031",
                      "tax": [
                        {
                          "OT": 5710
                        }
                      ],
                      "tf": 37031
                    }
                  ],
                  "pr": {
                    "bf": 31321,
                    "tf": 37031,
                    "tax": [
                      {
                        "OT": 5710
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "31321_37031",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "ledw2cxmii",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            },
            {
              "tid": "LTEt8sm2EqwSE4e4_2",
              "ref": 2,
              "ofcid": "",
              "sdur": "11:15",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DEL",
                  "dd": "2023-06-30T09:30:00",
                  "ad": "2023-06-30T11:50:00",
                  "rbd": "",
                  "fn": "6204",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "1"
                  },
                  "rph": "",
                  "jsell": "6E~6204~ ~~HYD~06/30/2023 09:30~DEL~06/30/2023 11:50~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                },
                {
                  "ref": 2,
                  "da": "DEL",
                  "ar": "DOH",
                  "dd": "2023-06-30T22:10:00",
                  "ad": "2023-06-30T23:45:00",
                  "rbd": "",
                  "fn": "1311",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "3"
                  },
                  "rph": "",
                  "jsell": "6E~1311~ ~~DEL~06/30/2023 22:10~DOH~06/30/2023 23:45~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 30621,
                      "fbc": "WCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "30621_36271",
                      "tax": [
                        {
                          "OT": 5650
                        }
                      ],
                      "tf": 36271
                    }
                  ],
                  "pr": {
                    "bf": 30621,
                    "tf": 36271,
                    "tax": [
                      {
                        "OT": 5650
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "30621_36271",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "lnj955g0kzk",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 31321,
                      "fbc": "WCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "31321_37031",
                      "tax": [
                        {
                          "OT": 5710
                        }
                      ],
                      "tf": 37031
                    }
                  ],
                  "pr": {
                    "bf": 31321,
                    "tf": 37031,
                    "tax": [
                      {
                        "OT": 5710
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "31321_37031",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "i9b56yydh6",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            },
            {
              "tid": "Nqu18FNrBRIopWuT_2",
              "ref": 2,
              "ofcid": "",
              "sdur": "4:40",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "BOM",
                  "dd": "2023-06-30T11:05:00",
                  "ad": "2023-06-30T12:40:00",
                  "rbd": "",
                  "fn": "5384",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "1"
                  },
                  "rph": "",
                  "jsell": "6E~5384~ ~~HYD~06/30/2023 11:05~BOM~06/30/2023 12:40~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                },
                {
                  "ref": 2,
                  "da": "BOM",
                  "ar": "DOH",
                  "dd": "2023-06-30T17:50:00",
                  "ad": "2023-06-30T18:45:00",
                  "rbd": "",
                  "fn": "1305",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "2"
                  },
                  "rph": "",
                  "jsell": "6E~1305~ ~~BOM~06/30/2023 17:50~DOH~06/30/2023 18:45~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 30621,
                      "fbc": "WCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "30621_36271",
                      "tax": [
                        {
                          "OT": 5650
                        }
                      ],
                      "tf": 36271
                    }
                  ],
                  "pr": {
                    "bf": 30621,
                    "tf": 36271,
                    "tax": [
                      {
                        "OT": 5650
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "30621_36271",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "r0jrupqbwx",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 31321,
                      "fbc": "WCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "31321_37031",
                      "tax": [
                        {
                          "OT": 5710
                        }
                      ],
                      "tf": 37031
                    }
                  ],
                  "pr": {
                    "bf": 31321,
                    "tf": 37031,
                    "tax": [
                      {
                        "OT": 5710
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "31321_37031",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "a8342nrr8y",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            },
            {
              "tid": "oGC5zTC56vhOahpd_2",
              "ref": 2,
              "ofcid": "",
              "sdur": "9:0",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DEL",
                  "dd": "2023-06-30T11:45:00",
                  "ad": "2023-06-30T14:05:00",
                  "rbd": "",
                  "fn": "2171",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "2"
                  },
                  "rph": "",
                  "jsell": "6E~2171~ ~~HYD~06/30/2023 11:45~DEL~06/30/2023 14:05~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                },
                {
                  "ref": 2,
                  "da": "DEL",
                  "ar": "DOH",
                  "dd": "2023-06-30T22:10:00",
                  "ad": "2023-06-30T23:45:00",
                  "rbd": "",
                  "fn": "1311",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "3"
                  },
                  "rph": "",
                  "jsell": "6E~1311~ ~~DEL~06/30/2023 22:10~DOH~06/30/2023 23:45~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 30621,
                      "fbc": "WCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "30621_36271",
                      "tax": [
                        {
                          "OT": 5650
                        }
                      ],
                      "tf": 36271
                    }
                  ],
                  "pr": {
                    "bf": 30621,
                    "tf": 36271,
                    "tax": [
                      {
                        "OT": 5650
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "30621_36271",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "tfc6ostarch",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 31321,
                      "fbc": "WCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "31321_37031",
                      "tax": [
                        {
                          "OT": 5710
                        }
                      ],
                      "tf": 37031
                    }
                  ],
                  "pr": {
                    "bf": 31321,
                    "tf": 37031,
                    "tax": [
                      {
                        "OT": 5710
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "31321_37031",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "9f9t2telbrg",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            },
            {
              "tid": "z40OoEoL5hpIevwp_2",
              "ref": 2,
              "ofcid": "",
              "sdur": "2:15",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "BOM",
                  "dd": "2023-06-30T13:30:00",
                  "ad": "2023-06-30T15:05:00",
                  "rbd": "",
                  "fn": "6699",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "2"
                  },
                  "rph": "",
                  "jsell": "6E~6699~ ~~HYD~06/30/2023 13:30~BOM~06/30/2023 15:05~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                },
                {
                  "ref": 2,
                  "da": "BOM",
                  "ar": "DOH",
                  "dd": "2023-06-30T17:50:00",
                  "ad": "2023-06-30T18:45:00",
                  "rbd": "",
                  "fn": "1305",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "2"
                  },
                  "rph": "",
                  "jsell": "6E~1305~ ~~BOM~06/30/2023 17:50~DOH~06/30/2023 18:45~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 30621,
                      "fbc": "WCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "30621_36271",
                      "tax": [
                        {
                          "OT": 5650
                        }
                      ],
                      "tf": 36271
                    }
                  ],
                  "pr": {
                    "bf": 30621,
                    "tf": 36271,
                    "tax": [
                      {
                        "OT": 5650
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "30621_36271",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "jitc20hq3r",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 31321,
                      "fbc": "WCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "31321_37031",
                      "tax": [
                        {
                          "OT": 5710
                        }
                      ],
                      "tf": 37031
                    }
                  ],
                  "pr": {
                    "bf": 31321,
                    "tf": 37031,
                    "tax": [
                      {
                        "OT": 5710
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "31321_37031",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "c4jk80dmsp",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            },
            {
              "tid": "MaI4hiwZSNLVCNVp_2",
              "ref": 2,
              "ofcid": "",
              "sdur": "10:10",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DEL",
                  "dd": "2023-06-30T16:30:00",
                  "ad": "2023-06-30T18:50:00",
                  "rbd": "",
                  "fn": "6606",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "1"
                  },
                  "rph": "",
                  "jsell": "6E~6606~ ~~HYD~06/30/2023 16:30~DEL~06/30/2023 18:50~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                },
                {
                  "ref": 2,
                  "da": "DEL",
                  "ar": "DOH",
                  "dd": "2023-07-01T04:05:00",
                  "ad": "2023-07-01T05:40:00",
                  "rbd": "",
                  "fn": "1307",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "3"
                  },
                  "rph": "",
                  "jsell": "6E~1307~ ~~DEL~07/01/2023 04:05~DOH~07/01/2023 05:40~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 30621,
                      "fbc": "WCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "30621_36271",
                      "tax": [
                        {
                          "OT": 5650
                        }
                      ],
                      "tf": 36271
                    }
                  ],
                  "pr": {
                    "bf": 30621,
                    "tf": 36271,
                    "tax": [
                      {
                        "OT": 5650
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "30621_36271",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "o3pzijqk34",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 31321,
                      "fbc": "WCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "31321_37031",
                      "tax": [
                        {
                          "OT": 5710
                        }
                      ],
                      "tf": 37031
                    }
                  ],
                  "pr": {
                    "bf": 31321,
                    "tf": 37031,
                    "tax": [
                      {
                        "OT": 5710
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "31321_37031",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "zmqehr6h4s",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            },
            {
              "tid": "TuTqo2u44dczct9p_2",
              "ref": 2,
              "ofcid": "",
              "sdur": "4:15",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DEL",
                  "dd": "2023-06-30T16:30:00",
                  "ad": "2023-06-30T18:50:00",
                  "rbd": "",
                  "fn": "6606",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "1"
                  },
                  "rph": "",
                  "jsell": "6E~6606~ ~~HYD~06/30/2023 16:30~DEL~06/30/2023 18:50~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                },
                {
                  "ref": 2,
                  "da": "DEL",
                  "ar": "DOH",
                  "dd": "2023-06-30T22:10:00",
                  "ad": "2023-06-30T23:45:00",
                  "rbd": "",
                  "fn": "1311",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "3"
                  },
                  "rph": "",
                  "jsell": "6E~1311~ ~~DEL~06/30/2023 22:10~DOH~06/30/2023 23:45~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 30621,
                      "fbc": "WCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "30621_36271",
                      "tax": [
                        {
                          "OT": 5650
                        }
                      ],
                      "tf": 36271
                    }
                  ],
                  "pr": {
                    "bf": 30621,
                    "tf": 36271,
                    "tax": [
                      {
                        "OT": 5650
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "30621_36271",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "e2knei1by8",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 31321,
                      "fbc": "WCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "31321_37031",
                      "tax": [
                        {
                          "OT": 5710
                        }
                      ],
                      "tf": 37031
                    }
                  ],
                  "pr": {
                    "bf": 31321,
                    "tf": 37031,
                    "tax": [
                      {
                        "OT": 5710
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "31321_37031",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "bnt4dzo1wy",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            },
            {
              "tid": "qtlIBbBAMCMmRs4g_2",
              "ref": 2,
              "ofcid": "",
              "sdur": "8:25",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DEL",
                  "dd": "2023-06-30T18:15:00",
                  "ad": "2023-06-30T20:30:00",
                  "rbd": "",
                  "fn": "2003",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "2"
                  },
                  "rph": "",
                  "jsell": "6E~2003~ ~~HYD~06/30/2023 18:15~DEL~06/30/2023 20:30~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                },
                {
                  "ref": 2,
                  "da": "DEL",
                  "ar": "DOH",
                  "dd": "2023-07-01T04:05:00",
                  "ad": "2023-07-01T05:40:00",
                  "rbd": "",
                  "fn": "1307",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "3"
                  },
                  "rph": "",
                  "jsell": "6E~1307~ ~~DEL~07/01/2023 04:05~DOH~07/01/2023 05:40~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 30621,
                      "fbc": "WCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "30621_36271",
                      "tax": [
                        {
                          "OT": 5650
                        }
                      ],
                      "tf": 36271
                    }
                  ],
                  "pr": {
                    "bf": 30621,
                    "tf": 36271,
                    "tax": [
                      {
                        "OT": 5650
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "30621_36271",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "hkux36qqsk",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 31321,
                      "fbc": "WCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "31321_37031",
                      "tax": [
                        {
                          "OT": 5710
                        }
                      ],
                      "tf": 37031
                    }
                  ],
                  "pr": {
                    "bf": 31321,
                    "tf": 37031,
                    "tax": [
                      {
                        "OT": 5710
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "31321_37031",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "2kzd0x4ewxj",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            },
            {
              "tid": "k45cKbgWgc9kGPxh_2",
              "ref": 2,
              "ofcid": "",
              "sdur": "5:55",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "BOM",
                  "dd": "2023-06-30T20:50:00",
                  "ad": "2023-06-30T22:05:00",
                  "rbd": "",
                  "fn": "5389",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "1"
                  },
                  "rph": "",
                  "jsell": "6E~5389~ ~~HYD~06/30/2023 20:50~BOM~06/30/2023 22:05~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                },
                {
                  "ref": 2,
                  "da": "BOM",
                  "ar": "DOH",
                  "dd": "2023-07-01T04:50:00",
                  "ad": "2023-07-01T05:45:00",
                  "rbd": "",
                  "fn": "1303",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "2"
                  },
                  "rph": "",
                  "jsell": "6E~1303~ ~~BOM~07/01/2023 04:50~DOH~07/01/2023 05:45~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 30621,
                      "fbc": "WCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "30621_36271",
                      "tax": [
                        {
                          "OT": 5650
                        }
                      ],
                      "tf": 36271
                    }
                  ],
                  "pr": {
                    "bf": 30621,
                    "tf": 36271,
                    "tax": [
                      {
                        "OT": 5650
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "30621_36271",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "n14eadwnwb",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 31321,
                      "fbc": "WCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "31321_37031",
                      "tax": [
                        {
                          "OT": 5710
                        }
                      ],
                      "tf": 37031
                    }
                  ],
                  "pr": {
                    "bf": 31321,
                    "tf": 37031,
                    "tax": [
                      {
                        "OT": 5710
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "31321_37031",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "5pq9mokl2bj",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            },
            {
              "tid": "SRMO0LTsThusqW3T_2",
              "ref": 2,
              "ofcid": "",
              "sdur": "5:10",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "BOM",
                  "dd": "2023-06-30T21:35:00",
                  "ad": "2023-06-30T23:05:00",
                  "rbd": "",
                  "fn": "6952",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "2"
                  },
                  "rph": "",
                  "jsell": "6E~6952~ ~~HYD~06/30/2023 21:35~BOM~06/30/2023 23:05~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                },
                {
                  "ref": 2,
                  "da": "BOM",
                  "ar": "DOH",
                  "dd": "2023-07-01T04:50:00",
                  "ad": "2023-07-01T05:45:00",
                  "rbd": "",
                  "fn": "1303",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "2"
                  },
                  "rph": "",
                  "jsell": "6E~1303~ ~~BOM~07/01/2023 04:50~DOH~07/01/2023 05:45~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 30621,
                      "fbc": "WCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "30621_36271",
                      "tax": [
                        {
                          "OT": 5650
                        }
                      ],
                      "tf": 36271
                    }
                  ],
                  "pr": {
                    "bf": 30621,
                    "tf": 36271,
                    "tax": [
                      {
                        "OT": 5650
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "30621_36271",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "ghfaaiv38j",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 31321,
                      "fbc": "WCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "31321_37031",
                      "tax": [
                        {
                          "OT": 5710
                        }
                      ],
                      "tf": 37031
                    }
                  ],
                  "pr": {
                    "bf": 31321,
                    "tf": 37031,
                    "tax": [
                      {
                        "OT": 5710
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "31321_37031",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "rs8x90q2x5f",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            },
            {
              "tid": "RqBmWDSqX3oXz4Sr_2",
              "ref": 2,
              "ofcid": "",
              "sdur": "4:45",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DEL",
                  "dd": "2023-06-30T21:55:00",
                  "ad": "2023-07-01T00:05:00",
                  "rbd": "",
                  "fn": "5605",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "3"
                  },
                  "rph": "",
                  "jsell": "6E~5605~ ~~HYD~06/30/2023 21:55~DEL~07/01/2023 00:05~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                },
                {
                  "ref": 2,
                  "da": "DEL",
                  "ar": "DOH",
                  "dd": "2023-07-01T04:05:00",
                  "ad": "2023-07-01T05:40:00",
                  "rbd": "",
                  "fn": "1307",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "3"
                  },
                  "rph": "",
                  "jsell": "6E~1307~ ~~DEL~07/01/2023 04:05~DOH~07/01/2023 05:40~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 30621,
                      "fbc": "WCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "30621_36271",
                      "tax": [
                        {
                          "OT": 5650
                        }
                      ],
                      "tf": 36271
                    }
                  ],
                  "pr": {
                    "bf": 30621,
                    "tf": 36271,
                    "tax": [
                      {
                        "OT": 5650
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "30621_36271",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "zmqbccm3y3",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 31321,
                      "fbc": "WCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "31321_37031",
                      "tax": [
                        {
                          "OT": 5710
                        }
                      ],
                      "tf": 37031
                    }
                  ],
                  "pr": {
                    "bf": 31321,
                    "tf": 37031,
                    "tax": [
                      {
                        "OT": 5710
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "31321_37031",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "l9curh0nvy",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            },
            {
              "tid": "J8rzni07LHDJqUBE_2",
              "ref": 2,
              "ofcid": "",
              "sdur": "4:5",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "BOM",
                  "dd": "2023-06-30T22:40:00",
                  "ad": "2023-07-01T00:15:00",
                  "rbd": "",
                  "fn": " 267",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "2"
                  },
                  "rph": "",
                  "jsell": "6E~ 267~ ~~HYD~06/30/2023 22:40~BOM~07/01/2023 00:15~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                },
                {
                  "ref": 2,
                  "da": "BOM",
                  "ar": "DOH",
                  "dd": "2023-07-01T04:50:00",
                  "ad": "2023-07-01T05:45:00",
                  "rbd": "",
                  "fn": "1303",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "2"
                  },
                  "rph": "",
                  "jsell": "6E~1303~ ~~BOM~07/01/2023 04:50~DOH~07/01/2023 05:45~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 30621,
                      "fbc": "WCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "30621_36271",
                      "tax": [
                        {
                          "OT": 5650
                        }
                      ],
                      "tf": 36271
                    }
                  ],
                  "pr": {
                    "bf": 30621,
                    "tf": 36271,
                    "tax": [
                      {
                        "OT": 5650
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "30621_36271",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "n8vjolkede",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 31321,
                      "fbc": "WCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "31321_37031",
                      "tax": [
                        {
                          "OT": 5710
                        }
                      ],
                      "tf": 37031
                    }
                  ],
                  "pr": {
                    "bf": 31321,
                    "tf": 37031,
                    "tax": [
                      {
                        "OT": 5710
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "31321_37031",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "oqjk6pcdsxk",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            },
            {
              "tid": "Utd0ehSvQT70mpKI_2",
              "ref": 2,
              "ofcid": "",
              "sdur": "3:55",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DEL",
                  "dd": "2023-06-30T22:45:00",
                  "ad": "2023-07-01T01:00:00",
                  "rbd": "",
                  "fn": "6146",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "1"
                  },
                  "rph": "",
                  "jsell": "6E~6146~ ~~HYD~06/30/2023 22:45~DEL~07/01/2023 01:00~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                },
                {
                  "ref": 2,
                  "da": "DEL",
                  "ar": "DOH",
                  "dd": "2023-07-01T04:05:00",
                  "ad": "2023-07-01T05:40:00",
                  "rbd": "",
                  "fn": "1307",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "3"
                  },
                  "rph": "",
                  "jsell": "6E~1307~ ~~DEL~07/01/2023 04:05~DOH~07/01/2023 05:40~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~W~ ~6E~WCINT~6200~~0~66~~X^0~W~ ~6E~WCINT~6200~~0~66~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 30621,
                      "fbc": "WCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "30621_36271",
                      "tax": [
                        {
                          "OT": 5650
                        }
                      ],
                      "tf": 36271
                    }
                  ],
                  "pr": {
                    "bf": 30621,
                    "tf": 36271,
                    "tax": [
                      {
                        "OT": 5650
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "30621_36271",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "ltkrea9v0y",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~W~ ~6E~WCUINT~6202~~0~5~~X^0~W~ ~6E~WCUINT~6202~~0~5~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 31321,
                      "fbc": "WCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "31321_37031",
                      "tax": [
                        {
                          "OT": 5710
                        }
                      ],
                      "tf": 37031
                    }
                  ],
                  "pr": {
                    "bf": 31321,
                    "tf": 37031,
                    "tax": [
                      {
                        "OT": 5710
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "31321_37031",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "3k92csgat9",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "DSCvzwTymOQ=|Ow+Nc2YULKiKQ22cqafKtCNGKuwBgXXJvMpKmPcPfxExRwkrUsB1bro1DIQj7pkUDwdHK5LvdjQDUFPI0/+t/Cl2kAkrNwyyzQC5JzyBTlC/Nu4WObB8daQ1GsG957ziHwioEezc7uQ=",
              "stop": 1
            }
          ],
          "paxpr": [
            {
              "bf": 60446,
              "tf": 70303,
              "fbc": "",
              "psup_fare": "",
              "tax": [
                [
                  {
                    "OT": 4207
                  }
                ],
                [
                  {
                    "OT": 5650
                  }
                ]
              ],
              "ptype": "ADT",
              "scur": "INR",
              "sup_cur_fare": ""
            }
          ],
          "pr": {
            "bf": 29825,
            "tf": 34032,
            "tax": [
              {
                "OT": 4207
              },
              {
                "OT": 5650
              }
            ],
            "scur": "INR",
            "sup_fare": "",
            "sup_cur_fare": "",
            "cabb": "",
            "chekb": "",
            "seats": "",
            "meals": "",
            "chanb": "",
            "ftyp": ""
          },
          "sup": "4",
          "airlineList": "6E"
        },
        {
          "srcorigns": [
            {
              "tid": "LKo17E2EJlzQNDak_1",
              "ref": 1,
              "sdur": "11:20",
              "re": "Non-Refundable",
              "stop": 2,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "AI",
              "seg": [
                {
                  "ref": 1,
                  "da": "DOH",
                  "ar": "DEL",
                  "dd": "2023-06-29T22:35:00",
                  "ad": "2023-06-30T05:05:00",
                  "rbd": "L",
                  "fn": "972",
                  "eq": "32N",
                  "ac": "AI",
                  "ma": "AI",
                  "seat": "1",
                  "oa": "AI",
                  "fa": [],
                  "fdur": "04:00",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": "3"
                  }
                },
                {
                  "ref": 2,
                  "da": "DEL",
                  "ar": "BOM",
                  "dd": "2023-06-30T08:00:00",
                  "ad": "2023-06-30T10:10:00",
                  "rbd": "Q",
                  "fn": "665",
                  "trn": "3",
                  "eq": "321",
                  "ac": "AI",
                  "ma": "AI",
                  "seat": "9",
                  "oa": "AI",
                  "fa": [],
                  "fdur": "02:10",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "3",
                    "arr": "2"
                  }
                },
                {
                  "ref": 3,
                  "da": "BOM",
                  "ar": "HYD",
                  "dd": "2023-06-30T11:00:00",
                  "ad": "2023-06-30T12:25:00",
                  "rbd": "K",
                  "fn": "617",
                  "trn": "2",
                  "eq": "319",
                  "ac": "AI",
                  "ma": "AI",
                  "seat": "1",
                  "oa": "AI",
                  "fa": [],
                  "fdur": "01:25",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "2",
                    "arr": ""
                  }
                }
              ],
              "flightNumbers": "972/665/617",
              "bgd": [
                {
                  "qun": "30",
                  "wt": "kg"
                }
              ]
            },
            {
              "tid": "6u1IDeesRIhlnFm2_1",
              "ref": 1,
              "sdur": "11:20",
              "re": "Non-Refundable",
              "stop": 2,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "AI",
              "seg": [
                {
                  "ref": 1,
                  "da": "DOH",
                  "ar": "DEL",
                  "dd": "2023-06-29T22:35:00",
                  "ad": "2023-06-30T05:05:00",
                  "rbd": "L",
                  "fn": "972",
                  "eq": "32N",
                  "ac": "AI",
                  "ma": "AI",
                  "seat": "1",
                  "oa": "AI",
                  "fa": [],
                  "fdur": "04:00",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": "3"
                  }
                },
                {
                  "ref": 2,
                  "da": "DEL",
                  "ar": "BOM",
                  "dd": "2023-06-30T07:00:00",
                  "ad": "2023-06-30T09:05:00",
                  "rbd": "Q",
                  "fn": "887",
                  "trn": "3",
                  "eq": "32N",
                  "ac": "AI",
                  "ma": "AI",
                  "seat": "9",
                  "oa": "AI",
                  "fa": [],
                  "fdur": "02:05",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "3",
                    "arr": "2"
                  }
                },
                {
                  "ref": 3,
                  "da": "BOM",
                  "ar": "HYD",
                  "dd": "2023-06-30T11:00:00",
                  "ad": "2023-06-30T12:25:00",
                  "rbd": "K",
                  "fn": "617",
                  "trn": "2",
                  "eq": "319",
                  "ac": "AI",
                  "ma": "AI",
                  "seat": "1",
                  "oa": "AI",
                  "fa": [],
                  "fdur": "01:25",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "2",
                    "arr": ""
                  }
                }
              ],
              "flightNumbers": "972/887/617",
              "bgd": [
                {
                  "qun": "30",
                  "wt": "kg"
                }
              ]
            }
          ],
          "destorigins": [
            {
              "tid": "LKo17E2EJlzQNDak_2",
              "ref": 2,
              "sdur": "09:25",
              "re": "Non-Refundable",
              "stop": 1,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "AI",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DEL",
                  "dd": "2023-06-30T14:30:00",
                  "ad": "2023-06-30T16:55:00",
                  "rbd": "Q",
                  "fn": "523",
                  "eq": "319",
                  "ac": "AI",
                  "ma": "AI",
                  "seat": "1",
                  "oa": "AI",
                  "fa": [],
                  "fdur": "02:25",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": "3"
                  }
                },
                {
                  "ref": 2,
                  "da": "DEL",
                  "ar": "DOH",
                  "dd": "2023-06-30T19:50:00",
                  "ad": "2023-06-30T21:25:00",
                  "rbd": "U",
                  "fn": "971",
                  "trn": "3",
                  "eq": "32N",
                  "ac": "AI",
                  "ma": "AI",
                  "seat": "2",
                  "oa": "AI",
                  "fa": [],
                  "fdur": "04:05",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "3",
                    "arr": ""
                  }
                }
              ],
              "flightNumbers": "523/971",
              "bgd": [
                {
                  "qun": "30",
                  "wt": "kg"
                }
              ]
            }
          ],
          "pr": {
            "bf": 25984,
            "tf": 34272,
            "tax": [
              {
                "YR": 1120
              },
              {
                "YR": 672
              },
              {
                "OT": 6496
              }
            ],
            "scur": "QAR",
            "ucur": "INR",
            "exchange_rate": 22.4,
            "sup_fare": "1160_1530"
          },
          "paxpr": [
            {
              "tf": 34272,
              "tax": [
                {
                  "YR": 1120
                },
                {
                  "YR": 672
                },
                {
                  "OT": 6496
                }
              ],
              "bf": 25984,
              "fbc": "L6MDHDL",
              "fareType": "RP",
              "pusup_fare": "1160_1530",
              "psup_fare": "25984_34272",
              "ptype": "ADT"
            }
          ],
          "sup": "0",
          "airlinesList": "AI"
        },
        {
          "srcorigns": [
            {
              "tid": "Syrs12zDW5d616Ch_1",
              "ref": 1,
              "sdur": "11:20",
              "re": "Non-Refundable",
              "stop": 2,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "AI",
              "seg": [
                {
                  "ref": 1,
                  "da": "DOH",
                  "ar": "DEL",
                  "dd": "2023-06-29T22:35:00",
                  "ad": "2023-06-30T05:05:00",
                  "rbd": "L",
                  "fn": "972",
                  "eq": "32N",
                  "ac": "AI",
                  "ma": "AI",
                  "seat": "1",
                  "oa": "AI",
                  "fa": [],
                  "fdur": "04:00",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": "3"
                  }
                },
                {
                  "ref": 2,
                  "da": "DEL",
                  "ar": "BOM",
                  "dd": "2023-06-30T08:00:00",
                  "ad": "2023-06-30T10:10:00",
                  "rbd": "Q",
                  "fn": "665",
                  "trn": "3",
                  "eq": "321",
                  "ac": "AI",
                  "ma": "AI",
                  "seat": "9",
                  "oa": "AI",
                  "fa": [],
                  "fdur": "02:10",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "3",
                    "arr": "2"
                  }
                },
                {
                  "ref": 3,
                  "da": "BOM",
                  "ar": "HYD",
                  "dd": "2023-06-30T11:00:00",
                  "ad": "2023-06-30T12:25:00",
                  "rbd": "K",
                  "fn": "617",
                  "trn": "2",
                  "eq": "319",
                  "ac": "AI",
                  "ma": "AI",
                  "seat": "1",
                  "oa": "AI",
                  "fa": [],
                  "fdur": "01:25",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "2",
                    "arr": ""
                  }
                }
              ],
              "flightNumbers": "972/665/617",
              "bgd": [
                {
                  "qun": "30",
                  "wt": "kg"
                }
              ]
            },
            {
              "tid": "etiDcQT6hCfvPDDM_1",
              "ref": 1,
              "sdur": "11:20",
              "re": "Non-Refundable",
              "stop": 2,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "AI",
              "seg": [
                {
                  "ref": 1,
                  "da": "DOH",
                  "ar": "DEL",
                  "dd": "2023-06-29T22:35:00",
                  "ad": "2023-06-30T05:05:00",
                  "rbd": "L",
                  "fn": "972",
                  "eq": "32N",
                  "ac": "AI",
                  "ma": "AI",
                  "seat": "1",
                  "oa": "AI",
                  "fa": [],
                  "fdur": "04:00",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": "3"
                  }
                },
                {
                  "ref": 2,
                  "da": "DEL",
                  "ar": "BOM",
                  "dd": "2023-06-30T07:00:00",
                  "ad": "2023-06-30T09:05:00",
                  "rbd": "Q",
                  "fn": "887",
                  "trn": "3",
                  "eq": "32N",
                  "ac": "AI",
                  "ma": "AI",
                  "seat": "9",
                  "oa": "AI",
                  "fa": [],
                  "fdur": "02:05",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "3",
                    "arr": "2"
                  }
                },
                {
                  "ref": 3,
                  "da": "BOM",
                  "ar": "HYD",
                  "dd": "2023-06-30T11:00:00",
                  "ad": "2023-06-30T12:25:00",
                  "rbd": "K",
                  "fn": "617",
                  "trn": "2",
                  "eq": "319",
                  "ac": "AI",
                  "ma": "AI",
                  "seat": "1",
                  "oa": "AI",
                  "fa": [],
                  "fdur": "01:25",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "2",
                    "arr": ""
                  }
                }
              ],
              "flightNumbers": "972/887/617",
              "bgd": [
                {
                  "qun": "30",
                  "wt": "kg"
                }
              ]
            }
          ],
          "destorigins": [
            {
              "tid": "Syrs12zDW5d616Ch_2",
              "ref": 2,
              "sdur": "27:15",
              "re": "Non-Refundable",
              "stop": 1,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "AI",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DEL",
                  "dd": "2023-06-30T20:40:00",
                  "ad": "2023-06-30T23:00:00",
                  "rbd": "Q",
                  "fn": "840",
                  "eq": "32N",
                  "ac": "AI",
                  "ma": "AI",
                  "seat": "9",
                  "oa": "AI",
                  "fa": [],
                  "fdur": "02:20",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": "3"
                  }
                },
                {
                  "ref": 2,
                  "da": "DEL",
                  "ar": "DOH",
                  "dd": "2023-07-01T19:50:00",
                  "ad": "2023-07-01T21:25:00",
                  "rbd": "U",
                  "fn": "971",
                  "trn": "3",
                  "eq": "32N",
                  "ac": "AI",
                  "ma": "AI",
                  "seat": "1",
                  "oa": "AI",
                  "fa": [],
                  "fdur": "04:05",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "3",
                    "arr": ""
                  }
                }
              ],
              "flightNumbers": "840/971",
              "bgd": [
                {
                  "qun": "30",
                  "wt": "kg"
                }
              ]
            }
          ],
          "pr": {
            "bf": 25984,
            "tf": 34272,
            "tax": [
              {
                "YR": 1120
              },
              {
                "YR": 672
              },
              {
                "OT": 6496
              }
            ],
            "scur": "QAR",
            "ucur": "INR",
            "exchange_rate": 22.4,
            "sup_fare": "1160_1530"
          },
          "paxpr": [
            {
              "tf": 34272,
              "tax": [
                {
                  "YR": 1120
                },
                {
                  "YR": 672
                },
                {
                  "OT": 6496
                }
              ],
              "bf": 25984,
              "fbc": "L6MDHDL",
              "fareType": "RP",
              "pusup_fare": "1160_1530",
              "psup_fare": "25984_34272",
              "ptype": "ADT"
            }
          ],
          "sup": "0",
          "airlinesList": "AI"
        },
        {
          "srcorigns": [
            {
              "tid": "6pVolKhsMyN17TqK_1",
              "ref": 1,
              "sdur": "11:20",
              "re": "Non-Refundable",
              "stop": 2,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "AI",
              "seg": [
                {
                  "ref": 1,
                  "da": "DOH",
                  "ar": "DEL",
                  "dd": "2023-06-29T22:35:00",
                  "ad": "2023-06-30T05:05:00",
                  "rbd": "L",
                  "fn": "972",
                  "eq": "32N",
                  "ac": "AI",
                  "ma": "AI",
                  "seat": "1",
                  "oa": "AI",
                  "fa": [],
                  "fdur": "04:00",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": "3"
                  }
                },
                {
                  "ref": 2,
                  "da": "DEL",
                  "ar": "BOM",
                  "dd": "2023-06-30T08:00:00",
                  "ad": "2023-06-30T10:10:00",
                  "rbd": "Q",
                  "fn": "665",
                  "trn": "3",
                  "eq": "321",
                  "ac": "AI",
                  "ma": "AI",
                  "seat": "9",
                  "oa": "AI",
                  "fa": [],
                  "fdur": "02:10",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "3",
                    "arr": "2"
                  }
                },
                {
                  "ref": 3,
                  "da": "BOM",
                  "ar": "HYD",
                  "dd": "2023-06-30T11:00:00",
                  "ad": "2023-06-30T12:25:00",
                  "rbd": "K",
                  "fn": "617",
                  "trn": "2",
                  "eq": "319",
                  "ac": "AI",
                  "ma": "AI",
                  "seat": "1",
                  "oa": "AI",
                  "fa": [],
                  "fdur": "01:25",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "2",
                    "arr": ""
                  }
                }
              ],
              "flightNumbers": "972/665/617",
              "bgd": [
                {
                  "qun": "30",
                  "wt": "kg"
                }
              ]
            },
            {
              "tid": "1MFqUqaRJRr3uJ3a_1",
              "ref": 1,
              "sdur": "11:20",
              "re": "Non-Refundable",
              "stop": 2,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "AI",
              "seg": [
                {
                  "ref": 1,
                  "da": "DOH",
                  "ar": "DEL",
                  "dd": "2023-06-29T22:35:00",
                  "ad": "2023-06-30T05:05:00",
                  "rbd": "L",
                  "fn": "972",
                  "eq": "32N",
                  "ac": "AI",
                  "ma": "AI",
                  "seat": "1",
                  "oa": "AI",
                  "fa": [],
                  "fdur": "04:00",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": "3"
                  }
                },
                {
                  "ref": 2,
                  "da": "DEL",
                  "ar": "BOM",
                  "dd": "2023-06-30T07:00:00",
                  "ad": "2023-06-30T09:05:00",
                  "rbd": "Q",
                  "fn": "887",
                  "trn": "3",
                  "eq": "32N",
                  "ac": "AI",
                  "ma": "AI",
                  "seat": "9",
                  "oa": "AI",
                  "fa": [],
                  "fdur": "02:05",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "3",
                    "arr": "2"
                  }
                },
                {
                  "ref": 3,
                  "da": "BOM",
                  "ar": "HYD",
                  "dd": "2023-06-30T11:00:00",
                  "ad": "2023-06-30T12:25:00",
                  "rbd": "K",
                  "fn": "617",
                  "trn": "2",
                  "eq": "319",
                  "ac": "AI",
                  "ma": "AI",
                  "seat": "1",
                  "oa": "AI",
                  "fa": [],
                  "fdur": "01:25",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "2",
                    "arr": ""
                  }
                }
              ],
              "flightNumbers": "972/887/617",
              "bgd": [
                {
                  "qun": "30",
                  "wt": "kg"
                }
              ]
            }
          ],
          "destorigins": [
            {
              "tid": "6pVolKhsMyN17TqK_2",
              "ref": 2,
              "sdur": "25:30",
              "re": "Non-Refundable",
              "stop": 2,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "AI",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "BOM",
                  "dd": "2023-06-30T22:25:00",
                  "ad": "2023-06-30T23:50:00",
                  "rbd": "U",
                  "fn": "698",
                  "eq": "319",
                  "ac": "AI",
                  "ma": "AI",
                  "seat": "9",
                  "oa": "AI",
                  "fa": [],
                  "fdur": "01:25",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": "2"
                  }
                },
                {
                  "ref": 2,
                  "da": "BOM",
                  "ar": "DEL",
                  "dd": "2023-07-01T13:00:00",
                  "ad": "2023-07-01T15:15:00",
                  "rbd": "Q",
                  "fn": "677",
                  "trn": "2",
                  "eq": "32N",
                  "ac": "AI",
                  "ma": "AI",
                  "seat": "9",
                  "oa": "AI",
                  "fa": [],
                  "fdur": "02:15",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "2",
                    "arr": "3"
                  }
                },
                {
                  "ref": 3,
                  "da": "DEL",
                  "ar": "DOH",
                  "dd": "2023-07-01T19:50:00",
                  "ad": "2023-07-01T21:25:00",
                  "rbd": "U",
                  "fn": "971",
                  "trn": "3",
                  "eq": "32N",
                  "ac": "AI",
                  "ma": "AI",
                  "seat": "1",
                  "oa": "AI",
                  "fa": [],
                  "fdur": "04:05",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "3",
                    "arr": ""
                  }
                }
              ],
              "flightNumbers": "698/677/971",
              "bgd": [
                {
                  "qun": "30",
                  "wt": "kg"
                }
              ]
            },
            {
              "tid": "5XQ8MQcpkWfJ9uPe_2",
              "ref": 2,
              "sdur": "25:30",
              "re": "Non-Refundable",
              "stop": 2,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "AI",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "BOM",
                  "dd": "2023-06-30T22:25:00",
                  "ad": "2023-06-30T23:50:00",
                  "rbd": "U",
                  "fn": "698",
                  "eq": "319",
                  "ac": "AI",
                  "ma": "AI",
                  "seat": "9",
                  "oa": "AI",
                  "fa": [],
                  "fdur": "01:25",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": "2"
                  }
                },
                {
                  "ref": 2,
                  "da": "BOM",
                  "ar": "DEL",
                  "dd": "2023-07-01T16:00:00",
                  "ad": "2023-07-01T18:20:00",
                  "rbd": "Q",
                  "fn": "687",
                  "trn": "2",
                  "eq": "32N",
                  "ac": "AI",
                  "ma": "AI",
                  "seat": "9",
                  "oa": "AI",
                  "fa": [],
                  "fdur": "02:20",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "2",
                    "arr": "3"
                  }
                },
                {
                  "ref": 3,
                  "da": "DEL",
                  "ar": "DOH",
                  "dd": "2023-07-01T19:50:00",
                  "ad": "2023-07-01T21:25:00",
                  "rbd": "U",
                  "fn": "971",
                  "trn": "3",
                  "eq": "32N",
                  "ac": "AI",
                  "ma": "AI",
                  "seat": "1",
                  "oa": "AI",
                  "fa": [],
                  "fdur": "04:05",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "3",
                    "arr": ""
                  }
                }
              ],
              "flightNumbers": "698/687/971",
              "bgd": [
                {
                  "qun": "30",
                  "wt": "kg"
                }
              ]
            },
            {
              "tid": "2oGNvPdsBHqAuXKn_2",
              "ref": 2,
              "sdur": "25:30",
              "re": "Non-Refundable",
              "stop": 2,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "AI",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "BOM",
                  "dd": "2023-06-30T22:25:00",
                  "ad": "2023-06-30T23:50:00",
                  "rbd": "U",
                  "fn": "698",
                  "eq": "319",
                  "ac": "AI",
                  "ma": "AI",
                  "seat": "9",
                  "oa": "AI",
                  "fa": [],
                  "fdur": "01:25",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": "2"
                  }
                },
                {
                  "ref": 2,
                  "da": "BOM",
                  "ar": "DEL",
                  "dd": "2023-07-01T08:00:00",
                  "ad": "2023-07-01T10:15:00",
                  "rbd": "Q",
                  "fn": "806",
                  "trn": "2",
                  "eq": "321",
                  "ac": "AI",
                  "ma": "AI",
                  "seat": "9",
                  "oa": "AI",
                  "fa": [],
                  "fdur": "02:15",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "2",
                    "arr": "3"
                  }
                },
                {
                  "ref": 3,
                  "da": "DEL",
                  "ar": "DOH",
                  "dd": "2023-07-01T19:50:00",
                  "ad": "2023-07-01T21:25:00",
                  "rbd": "U",
                  "fn": "971",
                  "trn": "3",
                  "eq": "32N",
                  "ac": "AI",
                  "ma": "AI",
                  "seat": "1",
                  "oa": "AI",
                  "fa": [],
                  "fdur": "04:05",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "3",
                    "arr": ""
                  }
                }
              ],
              "flightNumbers": "698/806/971",
              "bgd": [
                {
                  "qun": "30",
                  "wt": "kg"
                }
              ]
            },
            {
              "tid": "zh42luIJWKlyrTfo_2",
              "ref": 2,
              "sdur": "25:30",
              "re": "Non-Refundable",
              "stop": 2,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "AI",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "BOM",
                  "dd": "2023-06-30T22:25:00",
                  "ad": "2023-06-30T23:50:00",
                  "rbd": "U",
                  "fn": "698",
                  "eq": "319",
                  "ac": "AI",
                  "ma": "AI",
                  "seat": "9",
                  "oa": "AI",
                  "fa": [],
                  "fdur": "01:25",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": "2"
                  }
                },
                {
                  "ref": 2,
                  "da": "BOM",
                  "ar": "DEL",
                  "dd": "2023-07-01T10:00:00",
                  "ad": "2023-07-01T12:30:00",
                  "rbd": "Q",
                  "fn": "809",
                  "trn": "2",
                  "eq": "32N",
                  "ac": "AI",
                  "ma": "AI",
                  "seat": "9",
                  "oa": "AI",
                  "fa": [],
                  "fdur": "02:30",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "2",
                    "arr": "3"
                  }
                },
                {
                  "ref": 3,
                  "da": "DEL",
                  "ar": "DOH",
                  "dd": "2023-07-01T19:50:00",
                  "ad": "2023-07-01T21:25:00",
                  "rbd": "U",
                  "fn": "971",
                  "trn": "3",
                  "eq": "32N",
                  "ac": "AI",
                  "ma": "AI",
                  "seat": "1",
                  "oa": "AI",
                  "fa": [],
                  "fdur": "04:05",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "3",
                    "arr": ""
                  }
                }
              ],
              "flightNumbers": "698/809/971",
              "bgd": [
                {
                  "qun": "30",
                  "wt": "kg"
                }
              ]
            },
            {
              "tid": "kk8slhFkTuKxsgsk_2",
              "ref": 2,
              "sdur": "25:30",
              "re": "Non-Refundable",
              "stop": 2,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "AI",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "BOM",
                  "dd": "2023-06-30T22:25:00",
                  "ad": "2023-06-30T23:50:00",
                  "rbd": "U",
                  "fn": "698",
                  "eq": "319",
                  "ac": "AI",
                  "ma": "AI",
                  "seat": "9",
                  "oa": "AI",
                  "fa": [],
                  "fdur": "01:25",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": "2"
                  }
                },
                {
                  "ref": 2,
                  "da": "BOM",
                  "ar": "DEL",
                  "dd": "2023-07-01T07:00:00",
                  "ad": "2023-07-01T09:05:00",
                  "rbd": "Q",
                  "fn": "864",
                  "trn": "2",
                  "eq": "32N",
                  "ac": "AI",
                  "ma": "AI",
                  "seat": "9",
                  "oa": "AI",
                  "fa": [],
                  "fdur": "02:05",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "2",
                    "arr": "3"
                  }
                },
                {
                  "ref": 3,
                  "da": "DEL",
                  "ar": "DOH",
                  "dd": "2023-07-01T19:50:00",
                  "ad": "2023-07-01T21:25:00",
                  "rbd": "U",
                  "fn": "971",
                  "trn": "3",
                  "eq": "32N",
                  "ac": "AI",
                  "ma": "AI",
                  "seat": "1",
                  "oa": "AI",
                  "fa": [],
                  "fdur": "04:05",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "3",
                    "arr": ""
                  }
                }
              ],
              "flightNumbers": "698/864/971",
              "bgd": [
                {
                  "qun": "30",
                  "wt": "kg"
                }
              ]
            },
            {
              "tid": "wk8E1frVG7W9car2_2",
              "ref": 2,
              "sdur": "25:30",
              "re": "Non-Refundable",
              "stop": 2,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "AI",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "BOM",
                  "dd": "2023-06-30T22:25:00",
                  "ad": "2023-06-30T23:50:00",
                  "rbd": "U",
                  "fn": "698",
                  "eq": "319",
                  "ac": "AI",
                  "ma": "AI",
                  "seat": "9",
                  "oa": "AI",
                  "fa": [],
                  "fdur": "01:25",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": "2"
                  }
                },
                {
                  "ref": 2,
                  "da": "BOM",
                  "ar": "DEL",
                  "dd": "2023-07-01T09:00:00",
                  "ad": "2023-07-01T11:20:00",
                  "rbd": "Q",
                  "fn": "866",
                  "trn": "2",
                  "eq": "32N",
                  "ac": "AI",
                  "ma": "AI",
                  "seat": "9",
                  "oa": "AI",
                  "fa": [],
                  "fdur": "02:20",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "2",
                    "arr": "3"
                  }
                },
                {
                  "ref": 3,
                  "da": "DEL",
                  "ar": "DOH",
                  "dd": "2023-07-01T19:50:00",
                  "ad": "2023-07-01T21:25:00",
                  "rbd": "U",
                  "fn": "971",
                  "trn": "3",
                  "eq": "32N",
                  "ac": "AI",
                  "ma": "AI",
                  "seat": "1",
                  "oa": "AI",
                  "fa": [],
                  "fdur": "04:05",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "3",
                    "arr": ""
                  }
                }
              ],
              "flightNumbers": "698/866/971",
              "bgd": [
                {
                  "qun": "30",
                  "wt": "kg"
                }
              ]
            }
          ],
          "pr": {
            "bf": 27552,
            "tf": 36288,
            "tax": [
              {
                "YR": 1344
              },
              {
                "YR": 896
              },
              {
                "OT": 6496
              }
            ],
            "scur": "QAR",
            "ucur": "INR",
            "exchange_rate": 22.4,
            "sup_fare": "1230_1620"
          },
          "paxpr": [
            {
              "tf": 36288,
              "tax": [
                {
                  "YR": 1344
                },
                {
                  "YR": 896
                },
                {
                  "OT": 6496
                }
              ],
              "bf": 27552,
              "fbc": "L6MDHDL",
              "fareType": "RP",
              "pusup_fare": "1230_1620",
              "psup_fare": "27552_36288",
              "ptype": "ADT"
            }
          ],
          "sup": "0",
          "airlinesList": "AI"
        },
        {
          "srcorigns": [
            {
              "tid": "4fx2rHrt8eiu6dlO_1",
              "ref": 1,
              "ofcid": "",
              "sdur": "08:15",
              "re": "",
              "sup": "3",
              "ft": "",
              "cl": "Economy",
              "tecst": "no",
              "tktair": "J9",
              "seg": [
                {
                  "ref": 1,
                  "da": "DOH",
                  "ar": "KWI",
                  "dd": "2023-06-29T15:15:00",
                  "ad": "2023-06-29T16:40:00",
                  "rbd": "",
                  "fn": "116",
                  "eq": "",
                  "ac": "J9",
                  "ma": "J9",
                  "seat": "",
                  "oa": "J9",
                  "fa": [],
                  "segmentKey": "Sjl_IDExNn4gfn5ET0h_MDYvMjkvMjAyMyAxNToxNX5LV0l_MDYvMjkvMjAyMyAxNjo0MH5_",
                  "terminal": {
                    "dep": null,
                    "arr": "5"
                  },
                  "durationHrs": "1",
                  "durationMns": "25",
                  "cl": "Economy"
                },
                {
                  "ref": 2,
                  "da": "KWI",
                  "ar": "HYD",
                  "dd": "2023-06-29T18:45:00",
                  "ad": "2023-06-30T02:00:00",
                  "rbd": "",
                  "fn": "403",
                  "eq": "",
                  "ac": "J9",
                  "ma": "J9",
                  "seat": "",
                  "oa": "J9",
                  "fa": [],
                  "segmentKey": "Sjl_IDQwM34gfn5LV0l_MDYvMjkvMjAyMyAxODo0NX5IWUR_MDYvMzAvMjAyMyAwMjowMH5_",
                  "terminal": {
                    "dep": "5",
                    "arr": null
                  },
                  "durationHrs": "7",
                  "durationMns": "15",
                  "cl": "Economy"
                }
              ],
              "fareop": [
                {
                  "pr": {
                    "tf": 9623.04,
                    "bf": 7273.28,
                    "tax": [
                      {
                        "OT": 2349.760000000001
                      }
                    ],
                    "scur": "QAR",
                    "sup_fare": "7273.28_9623.04",
                    "sup_cur_fare": "324.7_104.9_429.6",
                    "ucur": "INR",
                    "exchange_rate": 22.4,
                    "ftyp": "Light",
                    "cabb": "NIL",
                    "chekb": "7 KG/1 PIECE ",
                    "meals": "For a Fee",
                    "puBag": "Available",
                    "puBag_info": "Only available at Check-in",
                    "chanb": "Available up to 8 hours prior to\n            scheduled departure.",
                    "chanb_info": "Online: INR 5409 (or equivalent) fee\n            per sector per Guest up to 24 hours of\n            flight departure.\n             Online: INR 4868 (or equivalent) fee\n            per sector per Guest within 1-3 days of\n            flight departure.\n             Online: INR 4057 (or equivalent) fee\n            per sector per Guest if modified more\n            than 4 days of flight departure.\n             Plus any Fare Difference ",
                    "canb": "Available up to 24 hours prior to\n             scheduled departure.",
                    "cabb_info": "Non-refundable fare. Only applicable\n            taxes are refunded.\n             Remaining balance held in\n            Credit for 1 year to be used by the\n            booking holder only."
                  },
                  "paxpr": [
                    {
                      "bf": 7273.28,
                      "tf": 9623.04,
                      "fbc": "P",
                      "psup_fare": "7273.28_9623.04",
                      "pusup_fare": "324.7_429.6",
                      "tax": [
                        {
                          "OT": 2349.76
                        }
                      ],
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_cur_fare": "324.7_104.9_429.6"
                    }
                  ],
                  "routeId": "20ztxm98n0h",
                  "servicefee": [],
                  "selected": true,
                  "seats_avail": 9,
                  "refundable": false,
                  "aj_fare_key": "MH5FfiB_Sjl_RUxTUE9XUUF_TEUwMH5_MH45fn5YITA-"
                },
                {
                  "pr": {
                    "tf": 10743.04,
                    "bf": 8393.28,
                    "tax": [
                      {
                        "OT": 2349.76
                      }
                    ],
                    "scur": "QAR",
                    "sup_fare": "8393.28_10743.04",
                    "sup_cur_fare": "374.7_104.9_479.6",
                    "ucur": "INR",
                    "exchange_rate": 22.4,
                    "ftyp": "Value",
                    "cabb": "20 Kg / 1 Piece",
                    "chekb": "7 KG/1 PIECE ",
                    "meals": "For a Fee",
                    "puBag": "Available",
                    "puBag_info": "Pre-purchase 10KG online",
                    "chanb": "Available up to 8 hours prior to\n            scheduled departure.",
                    "chanb_info": "Online: INR 5409 (or equivalent) fee\n            per sector per Guest up to 24 hours of\n            flight departure.\n             Online: INR 4868 (or equivalent) fee\n            per sector per Guest within 1-3 days of\n            flight departure.\n             Online: INR 4057 (or equivalent) fee\n            per sector per Guest if modified more\n            than 4 days of flight departure.\n             Plus any Fare Difference ",
                    "canb": "Available up to 24 hours prior to\n             scheduled departure.",
                    "cabb_info": "Online: INR 5950 (or equivalent)\n             fee per sector per Guest up to 24\n             hours of flight departure.\n              Online: INR 5409 (or equivalent)\n             fee per sector per Guest within 1-3\n             days of flight departure.\n              Online: INR 4598 (or equivalent)\n             fee per sector per Guest if modified\n             more than 4 days of flight departure."
                  },
                  "paxpr": [
                    {
                      "bf": 8393.28,
                      "tf": 10743.04,
                      "fbc": "P",
                      "psup_fare": "8393.28_10743.04",
                      "pusup_fare": "374.7_479.6",
                      "tax": [
                        {
                          "OT": 2349.76
                        }
                      ],
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_cur_fare": "374.7_104.9_479.6"
                    }
                  ],
                  "routeId": "9yl6aj8z4v",
                  "servicefee": [],
                  "selected": false,
                  "seats_avail": 9,
                  "refundable": true,
                  "aj_fare_key": "MH5CfiB_Sjl_QkxTUE9XUUF_VkUyMH5_MH4zfn5YITE-"
                },
                {
                  "pr": {
                    "tf": 11863.04,
                    "bf": 9513.28,
                    "tax": [
                      {
                        "OT": 2349.76
                      }
                    ],
                    "scur": "QAR",
                    "sup_fare": "9513.28_11863.04",
                    "sup_cur_fare": "424.7_104.9_529.6",
                    "ucur": "INR",
                    "exchange_rate": 22.4,
                    "ftyp": "Extra",
                    "cabb": "30 Kg / 2 Pieces",
                    "chekb": "7 KG/1 PIECE ",
                    "meals": "For a Fee",
                    "puBag": "Available",
                    "puBag_info": "Pre-purchase 10KG online",
                    "chanb": "Available up to 8 hours prior to\n            scheduled departure.",
                    "chanb_info": "Online: INR 4868 (or equivalent) fee\n            per sector per Guest up to 24 hours of\n            flight departure.\n             Online: INR 4328 (or equivalent) fee\n            per sector per Guest within 1-3 days of\n            flight departure.\n             Online: INR 3516 (or equivalent) fee\n            per sector per Guest if modified more\n            than 4 days of flight departure.\n             Plus any Fare Difference ",
                    "canb": "Available up to 24 hours prior to\n             scheduled departure.",
                    "cabb_info": "Online: INR 5409 (or equivalent)\n             fee per sector per Guest up to 24 hours\n             of flight departure.\n             Online: INR 4868 (or equivalent)\n             fee per sector per Guest within 1-3\n             days of flight departure.\n             Online: INR 4057 (or equivalent)\n             fee per sector per Guest if modified\n             more than 4 days of flight departure"
                  },
                  "paxpr": [
                    {
                      "bf": 9513.28,
                      "tf": 11863.04,
                      "fbc": "P",
                      "psup_fare": "9513.28_11863.04",
                      "pusup_fare": "424.7_529.6",
                      "tax": [
                        {
                          "OT": 2349.76
                        }
                      ],
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_cur_fare": "424.7_104.9_529.6"
                    }
                  ],
                  "routeId": "6x6j4p53dc",
                  "servicefee": [],
                  "selected": false,
                  "seats_avail": 9,
                  "refundable": true,
                  "aj_fare_key": "MH5LfiB_Sjl_S0xTUE9XUUF_WEUzMH5_MH40fn5YITI-"
                }
              ],
              "prouteId": "20ztxm98n0h",
              "stop": 1,
              "journeyKey": "Sjl_IDExNn4gfn5ET0h_MDYvMjkvMjAyMyAxNToxNX5LV0l_MDYvMjkvMjAyMyAxNjo0MH5_Xko5fiA0MDN_IH5_S1dJfjA2LzI5LzIwMjMgMTg6NDV_SFlEfjA2LzMwLzIwMjMgMDI6MDB_fg--",
              "paxpr": [
                {
                  "bf": 7273.28,
                  "tf": 9623.04,
                  "fbc": "P",
                  "psup_fare": "7273.28_9623.04",
                  "pusup_fare": "324.7_429.6",
                  "tax": [
                    {
                      "OT": 2349.76
                    }
                  ],
                  "ptype": "ADT",
                  "scur": "QAR",
                  "sup_cur_fare": "324.7_104.9_429.6"
                }
              ],
              "pr": {
                "tf": 9623.04,
                "bf": 7273.28,
                "tax": [
                  {
                    "OT": 2349.760000000001
                  }
                ],
                "scur": "QAR",
                "sup_fare": "7273.28_9623.04",
                "sup_cur_fare": "324.7_104.9_429.6",
                "ucur": "INR",
                "exchange_rate": 22.4,
                "ftyp": "Light",
                "cabb": "NIL",
                "chekb": "7 KG/1 PIECE ",
                "meals": "For a Fee",
                "puBag": "Available",
                "puBag_info": "Only available at Check-in",
                "chanb": "Available up to 8 hours prior to\n            scheduled departure.",
                "chanb_info": "Online: INR 5409 (or equivalent) fee\n            per sector per Guest up to 24 hours of\n            flight departure.\n             Online: INR 4868 (or equivalent) fee\n            per sector per Guest within 1-3 days of\n            flight departure.\n             Online: INR 4057 (or equivalent) fee\n            per sector per Guest if modified more\n            than 4 days of flight departure.\n             Plus any Fare Difference ",
                "canb": "Available up to 24 hours prior to\n             scheduled departure.",
                "cabb_info": "Non-refundable fare. Only applicable\n            taxes are refunded.\n             Remaining balance held in\n            Credit for 1 year to be used by the\n            booking holder only."
              }
            }
          ],
          "destorigins": [
            {
              "tid": "MUS2TLIH8VyQcv3C_1",
              "ref": 1,
              "ofcid": "",
              "sdur": "4:40",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DOH",
                  "dd": "2023-06-30T04:10:00",
                  "ad": "2023-06-30T05:50:00",
                  "rbd": "",
                  "fn": "1313",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {},
                  "rph": "",
                  "jsell": "6E~1313~ ~~HYD~06/30/2023 04:10~DOH~06/30/2023 05:50~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~A~ ~6E~A0INT~5000~~0~2~~X",
                  "pr": {
                    "bf": 26311,
                    "tf": 31534,
                    "tax": [
                      {
                        "OT": 5223
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "26311_31534",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "paxpr": [
                    {
                      "bf": 26311,
                      "tf": 31534,
                      "tax": [
                        {
                          "OT": 5223
                        }
                      ],
                      "fbc": "A0INT",
                      "ptype": "ADT",
                      "scur": "INR",
                      "sup_fare": "26311_31534"
                    }
                  ],
                  "routeId": "12r3muxt5lj",
                  "productClass": "R",
                  "ruleNumber": "5000"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "Q2c3c3fETtY=|A6KkIU40nZ4asiRAAWjEJn2YrIKBv2dQgqCTOYx11mnNyuaFYnXhwI5GuIilBz4V/oaFsdOsaNpO2v1OoLoyDKxVlrz4az4ufHZUNCPPoEmLz/Jk3DD2LYWM4DcZOuhPrHhGYGKemHY=",
              "stop": 0,
              "paxpr": [
                {
                  "bf": 26311,
                  "tf": 31534,
                  "fbc": "",
                  "psup_fare": "",
                  "tax": [
                    [
                      {
                        "OT": 5223
                      }
                    ]
                  ],
                  "ptype": "ADT",
                  "scur": "INR",
                  "sup_cur_fare": ""
                }
              ],
              "pr": {
                "bf": 26311,
                "tf": 31534,
                "tax": [
                  {
                    "OT": 5223
                  }
                ],
                "scur": "INR",
                "sup_fare": "",
                "sup_cur_fare": "",
                "cabb": "",
                "chekb": "",
                "seats": "",
                "meals": "",
                "chanb": "",
                "ftyp": ""
              }
            },
            {
              "tid": "M9ZZHyNdeQxDFR68_1",
              "ref": 1,
              "ofcid": "",
              "sdur": "4:40",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DOH",
                  "dd": "2023-06-30T17:05:00",
                  "ad": "2023-06-30T18:45:00",
                  "rbd": "",
                  "fn": "1315",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {},
                  "rph": "",
                  "jsell": "6E~1315~ ~~HYD~06/30/2023 17:05~DOH~06/30/2023 18:45~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~A~ ~6E~A0INT~5000~~0~2~~X",
                  "pr": {
                    "bf": 26311,
                    "tf": 31534,
                    "tax": [
                      {
                        "OT": 5223
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "26311_31534",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "paxpr": [
                    {
                      "bf": 26311,
                      "tf": 31534,
                      "tax": [
                        {
                          "OT": 5223
                        }
                      ],
                      "fbc": "A0INT",
                      "ptype": "ADT",
                      "scur": "INR",
                      "sup_fare": "26311_31534"
                    }
                  ],
                  "routeId": "by2fv3b5vl",
                  "productClass": "R",
                  "ruleNumber": "5000"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "Q2c3c3fETtY=|A6KkIU40nZ4asiRAAWjEJn2YrIKBv2dQgqCTOYx11mnNyuaFYnXhwI5GuIilBz4V/oaFsdOsaNpO2v1OoLoyDKxVlrz4az4ufHZUNCPPoEmLz/Jk3DD2LYWM4DcZOuhPrHhGYGKemHY=",
              "stop": 0,
              "paxpr": [
                {
                  "bf": 26311,
                  "tf": 31534,
                  "fbc": "",
                  "psup_fare": "",
                  "tax": [
                    [
                      {
                        "OT": 5223
                      }
                    ]
                  ],
                  "ptype": "ADT",
                  "scur": "INR",
                  "sup_cur_fare": ""
                }
              ],
              "pr": {
                "bf": 26311,
                "tf": 31534,
                "tax": [
                  {
                    "OT": 5223
                  }
                ],
                "scur": "INR",
                "sup_fare": "",
                "sup_cur_fare": "",
                "cabb": "",
                "chekb": "",
                "seats": "",
                "meals": "",
                "chanb": "",
                "ftyp": ""
              }
            },
            {
              "tid": "V8CRhFZrBR0MrXE1_1",
              "ref": 1,
              "ofcid": "",
              "sdur": "4:40",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DOH",
                  "dd": "2023-06-30T21:55:00",
                  "ad": "2023-06-30T23:35:00",
                  "rbd": "",
                  "fn": "1317",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {},
                  "rph": "",
                  "jsell": "6E~1317~ ~~HYD~06/30/2023 21:55~DOH~06/30/2023 23:35~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~A~ ~6E~A0INT~5000~~0~2~~X",
                  "pr": {
                    "bf": 26311,
                    "tf": 31534,
                    "tax": [
                      {
                        "OT": 5223
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "26311_31534",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "paxpr": [
                    {
                      "bf": 26311,
                      "tf": 31534,
                      "tax": [
                        {
                          "OT": 5223
                        }
                      ],
                      "fbc": "A0INT",
                      "ptype": "ADT",
                      "scur": "INR",
                      "sup_fare": "26311_31534"
                    }
                  ],
                  "routeId": "59fmlda90s",
                  "productClass": "R",
                  "ruleNumber": "5000"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "Q2c3c3fETtY=|A6KkIU40nZ4asiRAAWjEJn2YrIKBv2dQgqCTOYx11mnNyuaFYnXhwI5GuIilBz4V/oaFsdOsaNpO2v1OoLoyDKxVlrz4az4ufHZUNCPPoEmLz/Jk3DD2LYWM4DcZOuhPrHhGYGKemHY=",
              "stop": 0,
              "paxpr": [
                {
                  "bf": 26311,
                  "tf": 31534,
                  "fbc": "",
                  "psup_fare": "",
                  "tax": [
                    [
                      {
                        "OT": 5223
                      }
                    ]
                  ],
                  "ptype": "ADT",
                  "scur": "INR",
                  "sup_cur_fare": ""
                }
              ],
              "pr": {
                "bf": 26311,
                "tf": 31534,
                "tax": [
                  {
                    "OT": 5223
                  }
                ],
                "scur": "INR",
                "sup_fare": "",
                "sup_cur_fare": "",
                "cabb": "",
                "chekb": "",
                "seats": "",
                "meals": "",
                "chanb": "",
                "ftyp": ""
              }
            }
          ],
          "pr": {
            "bf": 33584,
            "tf": 41157,
            "tax": [
              {
                "OT": 2349.760000000001
              },
              {
                "OT": 5223
              }
            ]
          },
          "paxpr": [
            {
              "bf": 33584.28,
              "tf": 41157.04,
              "tax": [
                {
                  "OT": 2349.76
                },
                [
                  {
                    "OT": 5223
                  }
                ]
              ]
            }
          ],
          "sty": "split",
          "sup": "3/4",
          "airlineList": "J9_6E",
          "mix_carrier": true
        },
        {
          "srcorigns": [
            {
              "tid": "kSWD1R3LvfRyNlz6_1",
              "ref": 1,
              "ofcid": "",
              "sdur": "14:30",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "DOH",
                  "ar": "MAA",
                  "dd": "2023-06-29T02:00:00",
                  "ad": "2023-06-29T09:05:00",
                  "rbd": "",
                  "fn": "1322",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "3"
                  },
                  "rph": "",
                  "jsell": "6E~1322~ ~~DOH~06/29/2023 02:00~MAA~06/29/2023 09:05~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                },
                {
                  "ref": 2,
                  "da": "MAA",
                  "ar": "HYD",
                  "dd": "2023-06-29T12:20:00",
                  "ad": "2023-06-29T13:30:00",
                  "rbd": "",
                  "fn": " 604",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "1"
                  },
                  "rph": "",
                  "jsell": "6E~ 604~ ~~MAA~06/29/2023 12:20~HYD~06/29/2023 13:30~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~R~ ~6E~RCINT~6200~~0~65~~X^0~R~ ~6E~RCINT~6200~~0~65~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 9819,
                      "fbc": "RCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "9819_13326",
                      "tax": [
                        {
                          "OT": 3507
                        }
                      ],
                      "tf": 13326
                    }
                  ],
                  "pr": {
                    "bf": 9819,
                    "tf": 13326,
                    "tax": [
                      {
                        "OT": 3507
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "9819_13326",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "3ejfgmtsn3",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~R~ ~6E~RCUINT~6202~~0~4~~X^0~R~ ~6E~RCUINT~6202~~0~4~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 10519,
                      "fbc": "RCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "10519_14050",
                      "tax": [
                        {
                          "OT": 3531
                        }
                      ],
                      "tf": 14050
                    }
                  ],
                  "pr": {
                    "bf": 10519,
                    "tf": 14050,
                    "tax": [
                      {
                        "OT": 3531
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "10519_14050",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "asnla9i2l0l",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "Qjaaeqrl1XE=|aQFezKSWQkTEFuzy1c/XiE8E1gCe1mp7wL3TnlV5DA+x56hxCT+7ZWP6pBbfk21i1VpV7qcOGfUZINz5cX0sMPO7zDGGtcxXeJ5RNOP3ZplH1FG3CBzrsOYD796VyuQ29DfNwKuxQhE=",
              "stop": 1,
              "paxpr": [
                {
                  "bf": 9819,
                  "tf": 13326,
                  "fbc": "",
                  "psup_fare": "",
                  "tax": [
                    [
                      {
                        "OT": 3507
                      }
                    ]
                  ],
                  "ptype": "ADT",
                  "scur": "INR",
                  "sup_cur_fare": ""
                }
              ],
              "pr": {
                "bf": 9819,
                "tf": 13326,
                "tax": [
                  {
                    "OT": 3507
                  }
                ],
                "scur": "INR",
                "sup_fare": "",
                "sup_cur_fare": "",
                "cabb": "",
                "chekb": "",
                "seats": "",
                "meals": "",
                "chanb": "",
                "ftyp": ""
              }
            },
            {
              "tid": "T23thK491dFfh7nV_1",
              "ref": 1,
              "ofcid": "",
              "sdur": "19:15",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "DOH",
                  "ar": "MAA",
                  "dd": "2023-06-29T02:00:00",
                  "ad": "2023-06-29T09:05:00",
                  "rbd": "",
                  "fn": "1322",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "3"
                  },
                  "rph": "",
                  "jsell": "6E~1322~ ~~DOH~06/29/2023 02:00~MAA~06/29/2023 09:05~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                },
                {
                  "ref": 2,
                  "da": "MAA",
                  "ar": "HYD",
                  "dd": "2023-06-29T17:00:00",
                  "ad": "2023-06-29T18:15:00",
                  "rbd": "",
                  "fn": " 562",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "1"
                  },
                  "rph": "",
                  "jsell": "6E~ 562~ ~~MAA~06/29/2023 17:00~HYD~06/29/2023 18:15~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~R~ ~6E~RCINT~6200~~0~65~~X^0~R~ ~6E~RCINT~6200~~0~65~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 9819,
                      "fbc": "RCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "9819_13326",
                      "tax": [
                        {
                          "OT": 3507
                        }
                      ],
                      "tf": 13326
                    }
                  ],
                  "pr": {
                    "bf": 9819,
                    "tf": 13326,
                    "tax": [
                      {
                        "OT": 3507
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "9819_13326",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "iuz56zp12fi",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~R~ ~6E~RCUINT~6202~~0~4~~X^0~R~ ~6E~RCUINT~6202~~0~4~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 10519,
                      "fbc": "RCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "10519_14050",
                      "tax": [
                        {
                          "OT": 3531
                        }
                      ],
                      "tf": 14050
                    }
                  ],
                  "pr": {
                    "bf": 10519,
                    "tf": 14050,
                    "tax": [
                      {
                        "OT": 3531
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "10519_14050",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "fixyzs51cr",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "Qjaaeqrl1XE=|aQFezKSWQkTEFuzy1c/XiE8E1gCe1mp7wL3TnlV5DA+x56hxCT+7ZWP6pBbfk21i1VpV7qcOGfUZINz5cX0sMPO7zDGGtcxXeJ5RNOP3ZplH1FG3CBzrsOYD796VyuQ29DfNwKuxQhE=",
              "stop": 1,
              "paxpr": [
                {
                  "bf": 9819,
                  "tf": 13326,
                  "fbc": "",
                  "psup_fare": "",
                  "tax": [
                    [
                      {
                        "OT": 3507
                      }
                    ]
                  ],
                  "ptype": "ADT",
                  "scur": "INR",
                  "sup_cur_fare": ""
                }
              ],
              "pr": {
                "bf": 9819,
                "tf": 13326,
                "tax": [
                  {
                    "OT": 3507
                  }
                ],
                "scur": "INR",
                "sup_fare": "",
                "sup_cur_fare": "",
                "cabb": "",
                "chekb": "",
                "seats": "",
                "meals": "",
                "chanb": "",
                "ftyp": ""
              }
            },
            {
              "tid": "SiX2sy67k87wvIc4_1",
              "ref": 1,
              "ofcid": "",
              "sdur": "18:10",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "DOH",
                  "ar": "MAA",
                  "dd": "2023-06-29T02:00:00",
                  "ad": "2023-06-29T09:05:00",
                  "rbd": "",
                  "fn": "1322",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "3"
                  },
                  "rph": "",
                  "jsell": "6E~1322~ ~~DOH~06/29/2023 02:00~MAA~06/29/2023 09:05~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                },
                {
                  "ref": 2,
                  "da": "MAA",
                  "ar": "HYD",
                  "dd": "2023-06-29T16:00:00",
                  "ad": "2023-06-29T17:10:00",
                  "rbd": "",
                  "fn": "6401",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "1"
                  },
                  "rph": "",
                  "jsell": "6E~6401~ ~~MAA~06/29/2023 16:00~HYD~06/29/2023 17:10~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~R~ ~6E~RCINT~6200~~0~65~~X^0~R~ ~6E~RCINT~6200~~0~65~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 9819,
                      "fbc": "RCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "9819_13326",
                      "tax": [
                        {
                          "OT": 3507
                        }
                      ],
                      "tf": 13326
                    }
                  ],
                  "pr": {
                    "bf": 9819,
                    "tf": 13326,
                    "tax": [
                      {
                        "OT": 3507
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "9819_13326",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "ouof5d7tea",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~R~ ~6E~RCUINT~6202~~0~4~~X^0~R~ ~6E~RCUINT~6202~~0~4~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 10519,
                      "fbc": "RCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "10519_14050",
                      "tax": [
                        {
                          "OT": 3531
                        }
                      ],
                      "tf": 14050
                    }
                  ],
                  "pr": {
                    "bf": 10519,
                    "tf": 14050,
                    "tax": [
                      {
                        "OT": 3531
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "10519_14050",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "1b289r59s",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "Qjaaeqrl1XE=|aQFezKSWQkTEFuzy1c/XiE8E1gCe1mp7wL3TnlV5DA+x56hxCT+7ZWP6pBbfk21i1VpV7qcOGfUZINz5cX0sMPO7zDGGtcxXeJ5RNOP3ZplH1FG3CBzrsOYD796VyuQ29DfNwKuxQhE=",
              "stop": 1,
              "paxpr": [
                {
                  "bf": 9819,
                  "tf": 13326,
                  "fbc": "",
                  "psup_fare": "",
                  "tax": [
                    [
                      {
                        "OT": 3507
                      }
                    ]
                  ],
                  "ptype": "ADT",
                  "scur": "INR",
                  "sup_cur_fare": ""
                }
              ],
              "pr": {
                "bf": 9819,
                "tf": 13326,
                "tax": [
                  {
                    "OT": 3507
                  }
                ],
                "scur": "INR",
                "sup_fare": "",
                "sup_cur_fare": "",
                "cabb": "",
                "chekb": "",
                "seats": "",
                "meals": "",
                "chanb": "",
                "ftyp": ""
              }
            },
            {
              "tid": "FU3drl2wGMBptpID_1",
              "ref": 1,
              "ofcid": "",
              "sdur": "16:0",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "DOH",
                  "ar": "DEL",
                  "dd": "2023-06-29T19:40:00",
                  "ad": "2023-06-30T02:00:00",
                  "rbd": "",
                  "fn": "1308",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "3"
                  },
                  "rph": "",
                  "jsell": "6E~1308~ ~~DOH~06/29/2023 19:40~DEL~06/30/2023 02:00~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                },
                {
                  "ref": 2,
                  "da": "DEL",
                  "ar": "HYD",
                  "dd": "2023-06-30T06:30:00",
                  "ad": "2023-06-30T08:40:00",
                  "rbd": "",
                  "fn": "6203",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "1"
                  },
                  "rph": "",
                  "jsell": "6E~6203~ ~~DEL~06/30/2023 06:30~HYD~06/30/2023 08:40~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~R~ ~6E~RCINT~6200~~0~65~~X^0~R~ ~6E~RCINT~6200~~0~65~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 9819,
                      "fbc": "RCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "9819_13326",
                      "tax": [
                        {
                          "OT": 3507
                        }
                      ],
                      "tf": 13326
                    }
                  ],
                  "pr": {
                    "bf": 9819,
                    "tf": 13326,
                    "tax": [
                      {
                        "OT": 3507
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "9819_13326",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "1ce4t0ev0o",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~R~ ~6E~RCUINT~6202~~0~4~~X^0~R~ ~6E~RCUINT~6202~~0~4~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 10519,
                      "fbc": "RCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "10519_14050",
                      "tax": [
                        {
                          "OT": 3531
                        }
                      ],
                      "tf": 14050
                    }
                  ],
                  "pr": {
                    "bf": 10519,
                    "tf": 14050,
                    "tax": [
                      {
                        "OT": 3531
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "10519_14050",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "1aosvbrps9g",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "Qjaaeqrl1XE=|aQFezKSWQkTEFuzy1c/XiE8E1gCe1mp7wL3TnlV5DA+x56hxCT+7ZWP6pBbfk21i1VpV7qcOGfUZINz5cX0sMPO7zDGGtcxXeJ5RNOP3ZplH1FG3CBzrsOYD796VyuQ29DfNwKuxQhE=",
              "stop": 1,
              "paxpr": [
                {
                  "bf": 9819,
                  "tf": 13326,
                  "fbc": "",
                  "psup_fare": "",
                  "tax": [
                    [
                      {
                        "OT": 3507
                      }
                    ]
                  ],
                  "ptype": "ADT",
                  "scur": "INR",
                  "sup_cur_fare": ""
                }
              ],
              "pr": {
                "bf": 9819,
                "tf": 13326,
                "tax": [
                  {
                    "OT": 3507
                  }
                ],
                "scur": "INR",
                "sup_fare": "",
                "sup_cur_fare": "",
                "cabb": "",
                "chekb": "",
                "seats": "",
                "meals": "",
                "chanb": "",
                "ftyp": ""
              }
            }
          ],
          "destorigins": [
            {
              "tid": "MUS2TLIH8VyQcv3C_1",
              "ref": 1,
              "ofcid": "",
              "sdur": "4:40",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DOH",
                  "dd": "2023-06-30T04:10:00",
                  "ad": "2023-06-30T05:50:00",
                  "rbd": "",
                  "fn": "1313",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {},
                  "rph": "",
                  "jsell": "6E~1313~ ~~HYD~06/30/2023 04:10~DOH~06/30/2023 05:50~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~A~ ~6E~A0INT~5000~~0~2~~X",
                  "pr": {
                    "bf": 26311,
                    "tf": 31534,
                    "tax": [
                      {
                        "OT": 5223
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "26311_31534",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "paxpr": [
                    {
                      "bf": 26311,
                      "tf": 31534,
                      "tax": [
                        {
                          "OT": 5223
                        }
                      ],
                      "fbc": "A0INT",
                      "ptype": "ADT",
                      "scur": "INR",
                      "sup_fare": "26311_31534"
                    }
                  ],
                  "routeId": "12r3muxt5lj",
                  "productClass": "R",
                  "ruleNumber": "5000"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "Q2c3c3fETtY=|A6KkIU40nZ4asiRAAWjEJn2YrIKBv2dQgqCTOYx11mnNyuaFYnXhwI5GuIilBz4V/oaFsdOsaNpO2v1OoLoyDKxVlrz4az4ufHZUNCPPoEmLz/Jk3DD2LYWM4DcZOuhPrHhGYGKemHY=",
              "stop": 0,
              "paxpr": [
                {
                  "bf": 26311,
                  "tf": 31534,
                  "fbc": "",
                  "psup_fare": "",
                  "tax": [
                    [
                      {
                        "OT": 5223
                      }
                    ]
                  ],
                  "ptype": "ADT",
                  "scur": "INR",
                  "sup_cur_fare": ""
                }
              ],
              "pr": {
                "bf": 26311,
                "tf": 31534,
                "tax": [
                  {
                    "OT": 5223
                  }
                ],
                "scur": "INR",
                "sup_fare": "",
                "sup_cur_fare": "",
                "cabb": "",
                "chekb": "",
                "seats": "",
                "meals": "",
                "chanb": "",
                "ftyp": ""
              }
            },
            {
              "tid": "M9ZZHyNdeQxDFR68_1",
              "ref": 1,
              "ofcid": "",
              "sdur": "4:40",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DOH",
                  "dd": "2023-06-30T17:05:00",
                  "ad": "2023-06-30T18:45:00",
                  "rbd": "",
                  "fn": "1315",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {},
                  "rph": "",
                  "jsell": "6E~1315~ ~~HYD~06/30/2023 17:05~DOH~06/30/2023 18:45~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~A~ ~6E~A0INT~5000~~0~2~~X",
                  "pr": {
                    "bf": 26311,
                    "tf": 31534,
                    "tax": [
                      {
                        "OT": 5223
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "26311_31534",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "paxpr": [
                    {
                      "bf": 26311,
                      "tf": 31534,
                      "tax": [
                        {
                          "OT": 5223
                        }
                      ],
                      "fbc": "A0INT",
                      "ptype": "ADT",
                      "scur": "INR",
                      "sup_fare": "26311_31534"
                    }
                  ],
                  "routeId": "by2fv3b5vl",
                  "productClass": "R",
                  "ruleNumber": "5000"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "Q2c3c3fETtY=|A6KkIU40nZ4asiRAAWjEJn2YrIKBv2dQgqCTOYx11mnNyuaFYnXhwI5GuIilBz4V/oaFsdOsaNpO2v1OoLoyDKxVlrz4az4ufHZUNCPPoEmLz/Jk3DD2LYWM4DcZOuhPrHhGYGKemHY=",
              "stop": 0,
              "paxpr": [
                {
                  "bf": 26311,
                  "tf": 31534,
                  "fbc": "",
                  "psup_fare": "",
                  "tax": [
                    [
                      {
                        "OT": 5223
                      }
                    ]
                  ],
                  "ptype": "ADT",
                  "scur": "INR",
                  "sup_cur_fare": ""
                }
              ],
              "pr": {
                "bf": 26311,
                "tf": 31534,
                "tax": [
                  {
                    "OT": 5223
                  }
                ],
                "scur": "INR",
                "sup_fare": "",
                "sup_cur_fare": "",
                "cabb": "",
                "chekb": "",
                "seats": "",
                "meals": "",
                "chanb": "",
                "ftyp": ""
              }
            },
            {
              "tid": "V8CRhFZrBR0MrXE1_1",
              "ref": 1,
              "ofcid": "",
              "sdur": "4:40",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DOH",
                  "dd": "2023-06-30T21:55:00",
                  "ad": "2023-06-30T23:35:00",
                  "rbd": "",
                  "fn": "1317",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {},
                  "rph": "",
                  "jsell": "6E~1317~ ~~HYD~06/30/2023 21:55~DOH~06/30/2023 23:35~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~A~ ~6E~A0INT~5000~~0~2~~X",
                  "pr": {
                    "bf": 26311,
                    "tf": 31534,
                    "tax": [
                      {
                        "OT": 5223
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "26311_31534",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "paxpr": [
                    {
                      "bf": 26311,
                      "tf": 31534,
                      "tax": [
                        {
                          "OT": 5223
                        }
                      ],
                      "fbc": "A0INT",
                      "ptype": "ADT",
                      "scur": "INR",
                      "sup_fare": "26311_31534"
                    }
                  ],
                  "routeId": "59fmlda90s",
                  "productClass": "R",
                  "ruleNumber": "5000"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "Q2c3c3fETtY=|A6KkIU40nZ4asiRAAWjEJn2YrIKBv2dQgqCTOYx11mnNyuaFYnXhwI5GuIilBz4V/oaFsdOsaNpO2v1OoLoyDKxVlrz4az4ufHZUNCPPoEmLz/Jk3DD2LYWM4DcZOuhPrHhGYGKemHY=",
              "stop": 0,
              "paxpr": [
                {
                  "bf": 26311,
                  "tf": 31534,
                  "fbc": "",
                  "psup_fare": "",
                  "tax": [
                    [
                      {
                        "OT": 5223
                      }
                    ]
                  ],
                  "ptype": "ADT",
                  "scur": "INR",
                  "sup_cur_fare": ""
                }
              ],
              "pr": {
                "bf": 26311,
                "tf": 31534,
                "tax": [
                  {
                    "OT": 5223
                  }
                ],
                "scur": "INR",
                "sup_fare": "",
                "sup_cur_fare": "",
                "cabb": "",
                "chekb": "",
                "seats": "",
                "meals": "",
                "chanb": "",
                "ftyp": ""
              }
            }
          ],
          "pr": {
            "bf": 36130,
            "tf": 44860,
            "tax": [
              {
                "OT": 3507
              },
              {
                "OT": 5223
              }
            ]
          },
          "paxpr": [
            {
              "bf": 36130,
              "tf": 44860,
              "tax": [
                [
                  {
                    "OT": 3507
                  }
                ],
                [
                  {
                    "OT": 5223
                  }
                ]
              ]
            }
          ],
          "sty": "split",
          "sup": "4/4",
          "airlineList": "6E_6E",
          "mix_carrier": true
        },
        {
          "srcorigns": [
            {
              "tid": "v1kPzTRTv4IsnsuD_1",
              "ref": 1,
              "sdur": "08:20",
              "re": "Refundable Before Departure",
              "stop": 1,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "AI",
              "seg": [
                {
                  "ref": 1,
                  "da": "DOH",
                  "ar": "DEL",
                  "dd": "2023-06-29T22:35:00",
                  "ad": "2023-06-30T05:05:00",
                  "rbd": "L",
                  "fn": "972",
                  "eq": "32N",
                  "ac": "AI",
                  "ma": "AI",
                  "seat": "1",
                  "oa": "AI",
                  "fa": [],
                  "fdur": "04:00",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": "3"
                  }
                },
                {
                  "ref": 2,
                  "da": "DEL",
                  "ar": "HYD",
                  "dd": "2023-06-30T07:10:00",
                  "ad": "2023-06-30T09:25:00",
                  "rbd": "Q",
                  "fn": "560",
                  "trn": "3",
                  "eq": "32N",
                  "ac": "AI",
                  "ma": "AI",
                  "seat": "9",
                  "oa": "AI",
                  "fa": [],
                  "fdur": "02:15",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "3",
                    "arr": ""
                  }
                }
              ],
              "flightNumbers": "972/560",
              "bgd": [
                {
                  "qun": "30",
                  "wt": "kg"
                }
              ],
              "paxpr": [
                {
                  "tf": 17248,
                  "tax": [
                    {
                      "YR": 448
                    },
                    {
                      "YR": 224
                    },
                    {
                      "OT": 3136
                    }
                  ],
                  "bf": 13440,
                  "fbc": "LOWDHDL",
                  "fareType": "RP",
                  "pusup_fare": "600_770",
                  "psup_fare": "13440_17248",
                  "ptype": "ADT"
                }
              ],
              "pr": {
                "bf": 13440,
                "tf": 17248,
                "tax": [
                  {
                    "YR": 448
                  },
                  {
                    "YR": 224
                  },
                  {
                    "OT": 3136
                  }
                ],
                "scur": "QAR",
                "ucur": "INR",
                "exchange_rate": 22.4,
                "sup_fare": "600_770"
              }
            },
            {
              "tid": "QKlLpgcNOFScR7vm_1",
              "ref": 1,
              "sdur": "10:45",
              "re": "Refundable Before Departure",
              "stop": 1,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "AI",
              "seg": [
                {
                  "ref": 1,
                  "da": "DOH",
                  "ar": "DEL",
                  "dd": "2023-06-29T22:35:00",
                  "ad": "2023-06-30T05:05:00",
                  "rbd": "L",
                  "fn": "972",
                  "eq": "32N",
                  "ac": "AI",
                  "ma": "AI",
                  "seat": "1",
                  "oa": "AI",
                  "fa": [],
                  "fdur": "04:00",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": "3"
                  }
                },
                {
                  "ref": 2,
                  "da": "DEL",
                  "ar": "HYD",
                  "dd": "2023-06-30T09:45:00",
                  "ad": "2023-06-30T11:50:00",
                  "rbd": "Q",
                  "fn": "542",
                  "trn": "3",
                  "eq": "321",
                  "ac": "AI",
                  "ma": "AI",
                  "seat": "9",
                  "oa": "AI",
                  "fa": [],
                  "fdur": "02:05",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "3",
                    "arr": ""
                  }
                }
              ],
              "flightNumbers": "972/542",
              "bgd": [
                {
                  "qun": "30",
                  "wt": "kg"
                }
              ],
              "paxpr": [
                {
                  "tf": 17248,
                  "tax": [
                    {
                      "YR": 448
                    },
                    {
                      "YR": 224
                    },
                    {
                      "OT": 3136
                    }
                  ],
                  "bf": 13440,
                  "fbc": "LOWDHDL",
                  "fareType": "RP",
                  "pusup_fare": "600_770",
                  "psup_fare": "13440_17248",
                  "ptype": "ADT"
                }
              ],
              "pr": {
                "bf": 13440,
                "tf": 17248,
                "tax": [
                  {
                    "YR": 448
                  },
                  {
                    "YR": 224
                  },
                  {
                    "OT": 3136
                  }
                ],
                "scur": "QAR",
                "ucur": "INR",
                "exchange_rate": 22.4,
                "sup_fare": "600_770"
              }
            },
            {
              "tid": "1JQP8kNJKeRxapn8_1",
              "ref": 1,
              "sdur": "18:05",
              "re": "Refundable Before Departure",
              "stop": 1,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "AI",
              "seg": [
                {
                  "ref": 1,
                  "da": "DOH",
                  "ar": "DEL",
                  "dd": "2023-06-29T22:35:00",
                  "ad": "2023-06-30T05:05:00",
                  "rbd": "L",
                  "fn": "972",
                  "eq": "32N",
                  "ac": "AI",
                  "ma": "AI",
                  "seat": "1",
                  "oa": "AI",
                  "fa": [],
                  "fdur": "04:00",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": "3"
                  }
                },
                {
                  "ref": 2,
                  "da": "DEL",
                  "ar": "HYD",
                  "dd": "2023-06-30T16:50:00",
                  "ad": "2023-06-30T19:10:00",
                  "rbd": "Q",
                  "fn": "544",
                  "trn": "3",
                  "eq": "32N",
                  "ac": "AI",
                  "ma": "AI",
                  "seat": "9",
                  "oa": "AI",
                  "fa": [],
                  "fdur": "02:20",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "3",
                    "arr": ""
                  }
                }
              ],
              "flightNumbers": "972/544",
              "bgd": [
                {
                  "qun": "30",
                  "wt": "kg"
                }
              ],
              "paxpr": [
                {
                  "tf": 17248,
                  "tax": [
                    {
                      "YR": 448
                    },
                    {
                      "YR": 224
                    },
                    {
                      "OT": 3136
                    }
                  ],
                  "bf": 13440,
                  "fbc": "LOWDHDL",
                  "fareType": "RP",
                  "pusup_fare": "600_770",
                  "psup_fare": "13440_17248",
                  "ptype": "ADT"
                }
              ],
              "pr": {
                "bf": 13440,
                "tf": 17248,
                "tax": [
                  {
                    "YR": 448
                  },
                  {
                    "YR": 224
                  },
                  {
                    "OT": 3136
                  }
                ],
                "scur": "QAR",
                "ucur": "INR",
                "exchange_rate": 22.4,
                "sup_fare": "600_770"
              }
            },
            {
              "tid": "7NBv1RUInLrzLcP9_1",
              "ref": 1,
              "sdur": "22:40",
              "re": "Refundable Before Departure",
              "stop": 1,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "AI",
              "seg": [
                {
                  "ref": 1,
                  "da": "DOH",
                  "ar": "DEL",
                  "dd": "2023-06-29T22:35:00",
                  "ad": "2023-06-30T05:05:00",
                  "rbd": "L",
                  "fn": "972",
                  "eq": "32N",
                  "ac": "AI",
                  "ma": "AI",
                  "seat": "1",
                  "oa": "AI",
                  "fa": [],
                  "fdur": "04:00",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": "3"
                  }
                },
                {
                  "ref": 2,
                  "da": "DEL",
                  "ar": "HYD",
                  "dd": "2023-06-30T21:30:00",
                  "ad": "2023-06-30T23:45:00",
                  "rbd": "Q",
                  "fn": "839",
                  "trn": "3",
                  "eq": "321",
                  "ac": "AI",
                  "ma": "AI",
                  "seat": "9",
                  "oa": "AI",
                  "fa": [],
                  "fdur": "02:15",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "3",
                    "arr": ""
                  }
                }
              ],
              "flightNumbers": "972/839",
              "bgd": [
                {
                  "qun": "30",
                  "wt": "kg"
                }
              ],
              "paxpr": [
                {
                  "tf": 17248,
                  "tax": [
                    {
                      "YR": 448
                    },
                    {
                      "YR": 224
                    },
                    {
                      "OT": 3136
                    }
                  ],
                  "bf": 13440,
                  "fbc": "LOWDHDL",
                  "fareType": "RP",
                  "pusup_fare": "600_770",
                  "psup_fare": "13440_17248",
                  "ptype": "ADT"
                }
              ],
              "pr": {
                "bf": 13440,
                "tf": 17248,
                "tax": [
                  {
                    "YR": 448
                  },
                  {
                    "YR": 224
                  },
                  {
                    "OT": 3136
                  }
                ],
                "scur": "QAR",
                "ucur": "INR",
                "exchange_rate": 22.4,
                "sup_fare": "600_770"
              }
            }
          ],
          "destorigins": [
            {
              "tid": "MUS2TLIH8VyQcv3C_1",
              "ref": 1,
              "ofcid": "",
              "sdur": "4:40",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DOH",
                  "dd": "2023-06-30T04:10:00",
                  "ad": "2023-06-30T05:50:00",
                  "rbd": "",
                  "fn": "1313",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {},
                  "rph": "",
                  "jsell": "6E~1313~ ~~HYD~06/30/2023 04:10~DOH~06/30/2023 05:50~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~A~ ~6E~A0INT~5000~~0~2~~X",
                  "pr": {
                    "bf": 26311,
                    "tf": 31534,
                    "tax": [
                      {
                        "OT": 5223
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "26311_31534",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "paxpr": [
                    {
                      "bf": 26311,
                      "tf": 31534,
                      "tax": [
                        {
                          "OT": 5223
                        }
                      ],
                      "fbc": "A0INT",
                      "ptype": "ADT",
                      "scur": "INR",
                      "sup_fare": "26311_31534"
                    }
                  ],
                  "routeId": "12r3muxt5lj",
                  "productClass": "R",
                  "ruleNumber": "5000"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "Q2c3c3fETtY=|A6KkIU40nZ4asiRAAWjEJn2YrIKBv2dQgqCTOYx11mnNyuaFYnXhwI5GuIilBz4V/oaFsdOsaNpO2v1OoLoyDKxVlrz4az4ufHZUNCPPoEmLz/Jk3DD2LYWM4DcZOuhPrHhGYGKemHY=",
              "stop": 0,
              "paxpr": [
                {
                  "bf": 26311,
                  "tf": 31534,
                  "fbc": "",
                  "psup_fare": "",
                  "tax": [
                    [
                      {
                        "OT": 5223
                      }
                    ]
                  ],
                  "ptype": "ADT",
                  "scur": "INR",
                  "sup_cur_fare": ""
                }
              ],
              "pr": {
                "bf": 26311,
                "tf": 31534,
                "tax": [
                  {
                    "OT": 5223
                  }
                ],
                "scur": "INR",
                "sup_fare": "",
                "sup_cur_fare": "",
                "cabb": "",
                "chekb": "",
                "seats": "",
                "meals": "",
                "chanb": "",
                "ftyp": ""
              }
            },
            {
              "tid": "M9ZZHyNdeQxDFR68_1",
              "ref": 1,
              "ofcid": "",
              "sdur": "4:40",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DOH",
                  "dd": "2023-06-30T17:05:00",
                  "ad": "2023-06-30T18:45:00",
                  "rbd": "",
                  "fn": "1315",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {},
                  "rph": "",
                  "jsell": "6E~1315~ ~~HYD~06/30/2023 17:05~DOH~06/30/2023 18:45~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~A~ ~6E~A0INT~5000~~0~2~~X",
                  "pr": {
                    "bf": 26311,
                    "tf": 31534,
                    "tax": [
                      {
                        "OT": 5223
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "26311_31534",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "paxpr": [
                    {
                      "bf": 26311,
                      "tf": 31534,
                      "tax": [
                        {
                          "OT": 5223
                        }
                      ],
                      "fbc": "A0INT",
                      "ptype": "ADT",
                      "scur": "INR",
                      "sup_fare": "26311_31534"
                    }
                  ],
                  "routeId": "by2fv3b5vl",
                  "productClass": "R",
                  "ruleNumber": "5000"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "Q2c3c3fETtY=|A6KkIU40nZ4asiRAAWjEJn2YrIKBv2dQgqCTOYx11mnNyuaFYnXhwI5GuIilBz4V/oaFsdOsaNpO2v1OoLoyDKxVlrz4az4ufHZUNCPPoEmLz/Jk3DD2LYWM4DcZOuhPrHhGYGKemHY=",
              "stop": 0,
              "paxpr": [
                {
                  "bf": 26311,
                  "tf": 31534,
                  "fbc": "",
                  "psup_fare": "",
                  "tax": [
                    [
                      {
                        "OT": 5223
                      }
                    ]
                  ],
                  "ptype": "ADT",
                  "scur": "INR",
                  "sup_cur_fare": ""
                }
              ],
              "pr": {
                "bf": 26311,
                "tf": 31534,
                "tax": [
                  {
                    "OT": 5223
                  }
                ],
                "scur": "INR",
                "sup_fare": "",
                "sup_cur_fare": "",
                "cabb": "",
                "chekb": "",
                "seats": "",
                "meals": "",
                "chanb": "",
                "ftyp": ""
              }
            },
            {
              "tid": "V8CRhFZrBR0MrXE1_1",
              "ref": 1,
              "ofcid": "",
              "sdur": "4:40",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DOH",
                  "dd": "2023-06-30T21:55:00",
                  "ad": "2023-06-30T23:35:00",
                  "rbd": "",
                  "fn": "1317",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {},
                  "rph": "",
                  "jsell": "6E~1317~ ~~HYD~06/30/2023 21:55~DOH~06/30/2023 23:35~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~A~ ~6E~A0INT~5000~~0~2~~X",
                  "pr": {
                    "bf": 26311,
                    "tf": 31534,
                    "tax": [
                      {
                        "OT": 5223
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "26311_31534",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "paxpr": [
                    {
                      "bf": 26311,
                      "tf": 31534,
                      "tax": [
                        {
                          "OT": 5223
                        }
                      ],
                      "fbc": "A0INT",
                      "ptype": "ADT",
                      "scur": "INR",
                      "sup_fare": "26311_31534"
                    }
                  ],
                  "routeId": "59fmlda90s",
                  "productClass": "R",
                  "ruleNumber": "5000"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "Q2c3c3fETtY=|A6KkIU40nZ4asiRAAWjEJn2YrIKBv2dQgqCTOYx11mnNyuaFYnXhwI5GuIilBz4V/oaFsdOsaNpO2v1OoLoyDKxVlrz4az4ufHZUNCPPoEmLz/Jk3DD2LYWM4DcZOuhPrHhGYGKemHY=",
              "stop": 0,
              "paxpr": [
                {
                  "bf": 26311,
                  "tf": 31534,
                  "fbc": "",
                  "psup_fare": "",
                  "tax": [
                    [
                      {
                        "OT": 5223
                      }
                    ]
                  ],
                  "ptype": "ADT",
                  "scur": "INR",
                  "sup_cur_fare": ""
                }
              ],
              "pr": {
                "bf": 26311,
                "tf": 31534,
                "tax": [
                  {
                    "OT": 5223
                  }
                ],
                "scur": "INR",
                "sup_fare": "",
                "sup_cur_fare": "",
                "cabb": "",
                "chekb": "",
                "seats": "",
                "meals": "",
                "chanb": "",
                "ftyp": ""
              }
            }
          ],
          "pr": {
            "bf": 39751,
            "tf": 48782,
            "tax": [
              {
                "YR": 448
              },
              {
                "YR": 224
              },
              {
                "OT": 3136
              },
              {
                "OT": 5223
              }
            ]
          },
          "paxpr": [
            {
              "bf": 39751,
              "tf": 48782,
              "tax": [
                {
                  "YR": 448
                },
                {
                  "YR": 224
                },
                {
                  "OT": 3136
                },
                [
                  {
                    "OT": 5223
                  }
                ]
              ]
            }
          ],
          "sty": "split",
          "sup": "0/4",
          "airlineList": "AI_6E",
          "mix_carrier": true
        },
        {
          "srcorigns": [
            {
              "tid": "4fx2rHrt8eiu6dlO_1",
              "ref": 1,
              "ofcid": "",
              "sdur": "08:15",
              "re": "",
              "sup": "3",
              "ft": "",
              "cl": "Economy",
              "tecst": "no",
              "tktair": "J9",
              "seg": [
                {
                  "ref": 1,
                  "da": "DOH",
                  "ar": "KWI",
                  "dd": "2023-06-29T15:15:00",
                  "ad": "2023-06-29T16:40:00",
                  "rbd": "",
                  "fn": "116",
                  "eq": "",
                  "ac": "J9",
                  "ma": "J9",
                  "seat": "",
                  "oa": "J9",
                  "fa": [],
                  "segmentKey": "Sjl_IDExNn4gfn5ET0h_MDYvMjkvMjAyMyAxNToxNX5LV0l_MDYvMjkvMjAyMyAxNjo0MH5_",
                  "terminal": {
                    "dep": null,
                    "arr": "5"
                  },
                  "durationHrs": "1",
                  "durationMns": "25",
                  "cl": "Economy"
                },
                {
                  "ref": 2,
                  "da": "KWI",
                  "ar": "HYD",
                  "dd": "2023-06-29T18:45:00",
                  "ad": "2023-06-30T02:00:00",
                  "rbd": "",
                  "fn": "403",
                  "eq": "",
                  "ac": "J9",
                  "ma": "J9",
                  "seat": "",
                  "oa": "J9",
                  "fa": [],
                  "segmentKey": "Sjl_IDQwM34gfn5LV0l_MDYvMjkvMjAyMyAxODo0NX5IWUR_MDYvMzAvMjAyMyAwMjowMH5_",
                  "terminal": {
                    "dep": "5",
                    "arr": null
                  },
                  "durationHrs": "7",
                  "durationMns": "15",
                  "cl": "Economy"
                }
              ],
              "fareop": [
                {
                  "pr": {
                    "tf": 9623.04,
                    "bf": 7273.28,
                    "tax": [
                      {
                        "OT": 2349.760000000001
                      }
                    ],
                    "scur": "QAR",
                    "sup_fare": "7273.28_9623.04",
                    "sup_cur_fare": "324.7_104.9_429.6",
                    "ucur": "INR",
                    "exchange_rate": 22.4,
                    "ftyp": "Light",
                    "cabb": "NIL",
                    "chekb": "7 KG/1 PIECE ",
                    "meals": "For a Fee",
                    "puBag": "Available",
                    "puBag_info": "Only available at Check-in",
                    "chanb": "Available up to 8 hours prior to\n            scheduled departure.",
                    "chanb_info": "Online: INR 5409 (or equivalent) fee\n            per sector per Guest up to 24 hours of\n            flight departure.\n             Online: INR 4868 (or equivalent) fee\n            per sector per Guest within 1-3 days of\n            flight departure.\n             Online: INR 4057 (or equivalent) fee\n            per sector per Guest if modified more\n            than 4 days of flight departure.\n             Plus any Fare Difference ",
                    "canb": "Available up to 24 hours prior to\n             scheduled departure.",
                    "cabb_info": "Non-refundable fare. Only applicable\n            taxes are refunded.\n             Remaining balance held in\n            Credit for 1 year to be used by the\n            booking holder only."
                  },
                  "paxpr": [
                    {
                      "bf": 7273.28,
                      "tf": 9623.04,
                      "fbc": "P",
                      "psup_fare": "7273.28_9623.04",
                      "pusup_fare": "324.7_429.6",
                      "tax": [
                        {
                          "OT": 2349.76
                        }
                      ],
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_cur_fare": "324.7_104.9_429.6"
                    }
                  ],
                  "routeId": "20ztxm98n0h",
                  "servicefee": [],
                  "selected": true,
                  "seats_avail": 9,
                  "refundable": false,
                  "aj_fare_key": "MH5FfiB_Sjl_RUxTUE9XUUF_TEUwMH5_MH45fn5YITA-"
                },
                {
                  "pr": {
                    "tf": 10743.04,
                    "bf": 8393.28,
                    "tax": [
                      {
                        "OT": 2349.76
                      }
                    ],
                    "scur": "QAR",
                    "sup_fare": "8393.28_10743.04",
                    "sup_cur_fare": "374.7_104.9_479.6",
                    "ucur": "INR",
                    "exchange_rate": 22.4,
                    "ftyp": "Value",
                    "cabb": "20 Kg / 1 Piece",
                    "chekb": "7 KG/1 PIECE ",
                    "meals": "For a Fee",
                    "puBag": "Available",
                    "puBag_info": "Pre-purchase 10KG online",
                    "chanb": "Available up to 8 hours prior to\n            scheduled departure.",
                    "chanb_info": "Online: INR 5409 (or equivalent) fee\n            per sector per Guest up to 24 hours of\n            flight departure.\n             Online: INR 4868 (or equivalent) fee\n            per sector per Guest within 1-3 days of\n            flight departure.\n             Online: INR 4057 (or equivalent) fee\n            per sector per Guest if modified more\n            than 4 days of flight departure.\n             Plus any Fare Difference ",
                    "canb": "Available up to 24 hours prior to\n             scheduled departure.",
                    "cabb_info": "Online: INR 5950 (or equivalent)\n             fee per sector per Guest up to 24\n             hours of flight departure.\n              Online: INR 5409 (or equivalent)\n             fee per sector per Guest within 1-3\n             days of flight departure.\n              Online: INR 4598 (or equivalent)\n             fee per sector per Guest if modified\n             more than 4 days of flight departure."
                  },
                  "paxpr": [
                    {
                      "bf": 8393.28,
                      "tf": 10743.04,
                      "fbc": "P",
                      "psup_fare": "8393.28_10743.04",
                      "pusup_fare": "374.7_479.6",
                      "tax": [
                        {
                          "OT": 2349.76
                        }
                      ],
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_cur_fare": "374.7_104.9_479.6"
                    }
                  ],
                  "routeId": "9yl6aj8z4v",
                  "servicefee": [],
                  "selected": false,
                  "seats_avail": 9,
                  "refundable": true,
                  "aj_fare_key": "MH5CfiB_Sjl_QkxTUE9XUUF_VkUyMH5_MH4zfn5YITE-"
                },
                {
                  "pr": {
                    "tf": 11863.04,
                    "bf": 9513.28,
                    "tax": [
                      {
                        "OT": 2349.76
                      }
                    ],
                    "scur": "QAR",
                    "sup_fare": "9513.28_11863.04",
                    "sup_cur_fare": "424.7_104.9_529.6",
                    "ucur": "INR",
                    "exchange_rate": 22.4,
                    "ftyp": "Extra",
                    "cabb": "30 Kg / 2 Pieces",
                    "chekb": "7 KG/1 PIECE ",
                    "meals": "For a Fee",
                    "puBag": "Available",
                    "puBag_info": "Pre-purchase 10KG online",
                    "chanb": "Available up to 8 hours prior to\n            scheduled departure.",
                    "chanb_info": "Online: INR 4868 (or equivalent) fee\n            per sector per Guest up to 24 hours of\n            flight departure.\n             Online: INR 4328 (or equivalent) fee\n            per sector per Guest within 1-3 days of\n            flight departure.\n             Online: INR 3516 (or equivalent) fee\n            per sector per Guest if modified more\n            than 4 days of flight departure.\n             Plus any Fare Difference ",
                    "canb": "Available up to 24 hours prior to\n             scheduled departure.",
                    "cabb_info": "Online: INR 5409 (or equivalent)\n             fee per sector per Guest up to 24 hours\n             of flight departure.\n             Online: INR 4868 (or equivalent)\n             fee per sector per Guest within 1-3\n             days of flight departure.\n             Online: INR 4057 (or equivalent)\n             fee per sector per Guest if modified\n             more than 4 days of flight departure"
                  },
                  "paxpr": [
                    {
                      "bf": 9513.28,
                      "tf": 11863.04,
                      "fbc": "P",
                      "psup_fare": "9513.28_11863.04",
                      "pusup_fare": "424.7_529.6",
                      "tax": [
                        {
                          "OT": 2349.76
                        }
                      ],
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_cur_fare": "424.7_104.9_529.6"
                    }
                  ],
                  "routeId": "6x6j4p53dc",
                  "servicefee": [],
                  "selected": false,
                  "seats_avail": 9,
                  "refundable": true,
                  "aj_fare_key": "MH5LfiB_Sjl_S0xTUE9XUUF_WEUzMH5_MH40fn5YITI-"
                }
              ],
              "prouteId": "20ztxm98n0h",
              "stop": 1,
              "journeyKey": "Sjl_IDExNn4gfn5ET0h_MDYvMjkvMjAyMyAxNToxNX5LV0l_MDYvMjkvMjAyMyAxNjo0MH5_Xko5fiA0MDN_IH5_S1dJfjA2LzI5LzIwMjMgMTg6NDV_SFlEfjA2LzMwLzIwMjMgMDI6MDB_fg--",
              "paxpr": [
                {
                  "bf": 7273.28,
                  "tf": 9623.04,
                  "fbc": "P",
                  "psup_fare": "7273.28_9623.04",
                  "pusup_fare": "324.7_429.6",
                  "tax": [
                    {
                      "OT": 2349.76
                    }
                  ],
                  "ptype": "ADT",
                  "scur": "QAR",
                  "sup_cur_fare": "324.7_104.9_429.6"
                }
              ],
              "pr": {
                "tf": 9623.04,
                "bf": 7273.28,
                "tax": [
                  {
                    "OT": 2349.760000000001
                  }
                ],
                "scur": "QAR",
                "sup_fare": "7273.28_9623.04",
                "sup_cur_fare": "324.7_104.9_429.6",
                "ucur": "INR",
                "exchange_rate": 22.4,
                "ftyp": "Light",
                "cabb": "NIL",
                "chekb": "7 KG/1 PIECE ",
                "meals": "For a Fee",
                "puBag": "Available",
                "puBag_info": "Only available at Check-in",
                "chanb": "Available up to 8 hours prior to\n            scheduled departure.",
                "chanb_info": "Online: INR 5409 (or equivalent) fee\n            per sector per Guest up to 24 hours of\n            flight departure.\n             Online: INR 4868 (or equivalent) fee\n            per sector per Guest within 1-3 days of\n            flight departure.\n             Online: INR 4057 (or equivalent) fee\n            per sector per Guest if modified more\n            than 4 days of flight departure.\n             Plus any Fare Difference ",
                "canb": "Available up to 24 hours prior to\n             scheduled departure.",
                "cabb_info": "Non-refundable fare. Only applicable\n            taxes are refunded.\n             Remaining balance held in\n            Credit for 1 year to be used by the\n            booking holder only."
              }
            }
          ],
          "destorigins": [
            {
              "tid": "o4dGVuQJgCyaGMOJ_1",
              "ref": 1,
              "sdur": "04:10",
              "re": "Refundable Before Departure",
              "stop": 0,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "QR",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DOH",
                  "dd": "2023-06-30T21:55:00",
                  "ad": "2023-06-30T23:35:00",
                  "rbd": "N",
                  "fn": "4779",
                  "eq": "320",
                  "ac": "QR",
                  "ma": "QR",
                  "seat": "9",
                  "oa": "6E",
                  "fa": [],
                  "fdur": "04:10",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": ""
                  }
                }
              ],
              "flightNumbers": "4779",
              "bgd": [
                {
                  "qun": "25",
                  "wt": "kg"
                }
              ],
              "paxpr": [
                {
                  "tf": 39200,
                  "tax": [
                    {
                      "YQ": 9856
                    },
                    {
                      "YR": 896
                    },
                    {
                      "OT": 5152
                    }
                  ],
                  "bf": 23296,
                  "fbc": "NLR4I1RQ",
                  "fareType": "RP",
                  "pusup_fare": "1040_1750",
                  "psup_fare": "23296_39200",
                  "ptype": "ADT"
                }
              ],
              "pr": {
                "bf": 23296,
                "tf": 39200,
                "tax": [
                  {
                    "YQ": 9856
                  },
                  {
                    "YR": 896
                  },
                  {
                    "OT": 5152
                  }
                ],
                "scur": "QAR",
                "ucur": "INR",
                "exchange_rate": 22.4,
                "sup_fare": "1040_1750"
              }
            },
            {
              "tid": "LithwaiTgVo0zTSn_1",
              "ref": 1,
              "sdur": "04:10",
              "re": "Refundable Before Departure",
              "stop": 0,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "QR",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DOH",
                  "dd": "2023-06-30T17:05:00",
                  "ad": "2023-06-30T18:45:00",
                  "rbd": "N",
                  "fn": "4777",
                  "eq": "320",
                  "ac": "QR",
                  "ma": "QR",
                  "seat": "9",
                  "oa": "6E",
                  "fa": [],
                  "fdur": "04:10",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": ""
                  }
                }
              ],
              "flightNumbers": "4777",
              "bgd": [
                {
                  "qun": "25",
                  "wt": "kg"
                }
              ],
              "paxpr": [
                {
                  "tf": 39200,
                  "tax": [
                    {
                      "YQ": 9856
                    },
                    {
                      "YR": 896
                    },
                    {
                      "OT": 5152
                    }
                  ],
                  "bf": 23296,
                  "fbc": "NLR4I1RQ",
                  "fareType": "RP",
                  "pusup_fare": "1040_1750",
                  "psup_fare": "23296_39200",
                  "ptype": "ADT"
                }
              ],
              "pr": {
                "bf": 23296,
                "tf": 39200,
                "tax": [
                  {
                    "YQ": 9856
                  },
                  {
                    "YR": 896
                  },
                  {
                    "OT": 5152
                  }
                ],
                "scur": "QAR",
                "ucur": "INR",
                "exchange_rate": 22.4,
                "sup_fare": "1040_1750"
              }
            },
            {
              "tid": "VtTRT4CTTnmiBqt3_1",
              "ref": 1,
              "sdur": "04:10",
              "re": "Refundable Before Departure",
              "stop": 0,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "QR",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DOH",
                  "dd": "2023-06-30T04:10:00",
                  "ad": "2023-06-30T05:50:00",
                  "rbd": "N",
                  "fn": "4775",
                  "eq": "320",
                  "ac": "QR",
                  "ma": "QR",
                  "seat": "9",
                  "oa": "6E",
                  "fa": [],
                  "fdur": "04:10",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": ""
                  }
                }
              ],
              "flightNumbers": "4775",
              "bgd": [
                {
                  "qun": "25",
                  "wt": "kg"
                }
              ],
              "paxpr": [
                {
                  "tf": 39200,
                  "tax": [
                    {
                      "YQ": 9856
                    },
                    {
                      "YR": 896
                    },
                    {
                      "OT": 5152
                    }
                  ],
                  "bf": 23296,
                  "fbc": "NLR4I1RQ",
                  "fareType": "RP",
                  "pusup_fare": "1040_1750",
                  "psup_fare": "23296_39200",
                  "ptype": "ADT"
                }
              ],
              "pr": {
                "bf": 23296,
                "tf": 39200,
                "tax": [
                  {
                    "YQ": 9856
                  },
                  {
                    "YR": 896
                  },
                  {
                    "OT": 5152
                  }
                ],
                "scur": "QAR",
                "ucur": "INR",
                "exchange_rate": 22.4,
                "sup_fare": "1040_1750"
              }
            }
          ],
          "pr": {
            "bf": 30569,
            "tf": 48823,
            "tax": [
              {
                "OT": 2349.760000000001
              },
              {
                "YQ": 9856
              },
              {
                "YR": 896
              },
              {
                "OT": 5152
              }
            ]
          },
          "paxpr": [
            {
              "bf": 30569.28,
              "tf": 48823.04,
              "tax": [
                {
                  "OT": 2349.76
                },
                {
                  "YQ": 9856
                },
                {
                  "YR": 896
                },
                {
                  "OT": 5152
                }
              ]
            }
          ],
          "sty": "split",
          "sup": "3/0",
          "airlineList": "J9_6E",
          "mix_carrier": true
        },
        {
          "srcorigns": [
            {
              "tid": "UC8pMN2hvMQGa489_1",
              "ref": 1,
              "ofcid": "",
              "sdur": "9:45",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "DOH",
                  "ar": "HYD",
                  "dd": "2023-06-29T02:00:00",
                  "ad": "2023-06-29T08:45:00",
                  "rbd": "",
                  "fn": "1318",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {},
                  "rph": "",
                  "jsell": "6E~1318~ ~~DOH~06/29/2023 02:00~HYD~06/29/2023 08:45~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~A~ ~6E~A0INT~5000~~0~1~~X",
                  "pr": {
                    "bf": 28373,
                    "tf": 32479,
                    "tax": [
                      {
                        "OT": 4106
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "28373_32479",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "paxpr": [
                    {
                      "bf": 28373,
                      "tf": 32479,
                      "tax": [
                        {
                          "OT": 4106
                        }
                      ],
                      "fbc": "A0INT",
                      "ptype": "ADT",
                      "scur": "INR",
                      "sup_fare": "28373_32479"
                    }
                  ],
                  "routeId": "lh3rsvezlv",
                  "productClass": "R",
                  "ruleNumber": "5000"
                },
                {
                  "fareKey": "0~A~ ~6E~AUINT~5003~~0~7~~X",
                  "pr": {
                    "bf": 29073,
                    "tf": 33204,
                    "tax": [
                      {
                        "OT": 4131
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "29073_33204",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "paxpr": [
                    {
                      "bf": 29073,
                      "tf": 33204,
                      "tax": [
                        {
                          "OT": 4131
                        }
                      ],
                      "fbc": "AUINT",
                      "ptype": "ADT",
                      "scur": "INR",
                      "sup_fare": "29073_33204"
                    }
                  ],
                  "routeId": "56adr4fn8o",
                  "productClass": "J",
                  "ruleNumber": "5003"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "Qjaaeqrl1XE=|aQFezKSWQkTEFuzy1c/XiE8E1gCe1mp7wL3TnlV5DA+x56hxCT+7ZWP6pBbfk21i1VpV7qcOGfUZINz5cX0sMPO7zDGGtcxXeJ5RNOP3ZplH1FG3CBzrsOYD796VyuQ29DfNwKuxQhE=",
              "stop": 0,
              "paxpr": [
                {
                  "bf": 28373,
                  "tf": 32479,
                  "fbc": "",
                  "psup_fare": "",
                  "tax": [
                    [
                      {
                        "OT": 4106
                      }
                    ]
                  ],
                  "ptype": "ADT",
                  "scur": "INR",
                  "sup_cur_fare": ""
                }
              ],
              "pr": {
                "bf": 28373,
                "tf": 32479,
                "tax": [
                  {
                    "OT": 4106
                  }
                ],
                "scur": "INR",
                "sup_fare": "",
                "sup_cur_fare": "",
                "cabb": "",
                "chekb": "",
                "seats": "",
                "meals": "",
                "chanb": "",
                "ftyp": ""
              }
            },
            {
              "tid": "9szQBnvh8kM9vfqy_1",
              "ref": 1,
              "ofcid": "",
              "sdur": "9:45",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "DOH",
                  "ar": "HYD",
                  "dd": "2023-06-29T08:15:00",
                  "ad": "2023-06-29T15:00:00",
                  "rbd": "",
                  "fn": "1314",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {},
                  "rph": "",
                  "jsell": "6E~1314~ ~~DOH~06/29/2023 08:15~HYD~06/29/2023 15:00~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~A~ ~6E~A0INT~5000~~0~1~~X",
                  "pr": {
                    "bf": 28373,
                    "tf": 32479,
                    "tax": [
                      {
                        "OT": 4106
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "28373_32479",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "paxpr": [
                    {
                      "bf": 28373,
                      "tf": 32479,
                      "tax": [
                        {
                          "OT": 4106
                        }
                      ],
                      "fbc": "A0INT",
                      "ptype": "ADT",
                      "scur": "INR",
                      "sup_fare": "28373_32479"
                    }
                  ],
                  "routeId": "ivcua3jvq",
                  "productClass": "R",
                  "ruleNumber": "5000"
                },
                {
                  "fareKey": "0~A~ ~6E~AUINT~5003~~0~7~~X",
                  "pr": {
                    "bf": 29073,
                    "tf": 33204,
                    "tax": [
                      {
                        "OT": 4131
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "29073_33204",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "paxpr": [
                    {
                      "bf": 29073,
                      "tf": 33204,
                      "tax": [
                        {
                          "OT": 4131
                        }
                      ],
                      "fbc": "AUINT",
                      "ptype": "ADT",
                      "scur": "INR",
                      "sup_fare": "29073_33204"
                    }
                  ],
                  "routeId": "romovrv2la",
                  "productClass": "J",
                  "ruleNumber": "5003"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "Qjaaeqrl1XE=|aQFezKSWQkTEFuzy1c/XiE8E1gCe1mp7wL3TnlV5DA+x56hxCT+7ZWP6pBbfk21i1VpV7qcOGfUZINz5cX0sMPO7zDGGtcxXeJ5RNOP3ZplH1FG3CBzrsOYD796VyuQ29DfNwKuxQhE=",
              "stop": 0,
              "paxpr": [
                {
                  "bf": 28373,
                  "tf": 32479,
                  "fbc": "",
                  "psup_fare": "",
                  "tax": [
                    [
                      {
                        "OT": 4106
                      }
                    ]
                  ],
                  "ptype": "ADT",
                  "scur": "INR",
                  "sup_cur_fare": ""
                }
              ],
              "pr": {
                "bf": 28373,
                "tf": 32479,
                "tax": [
                  {
                    "OT": 4106
                  }
                ],
                "scur": "INR",
                "sup_fare": "",
                "sup_cur_fare": "",
                "cabb": "",
                "chekb": "",
                "seats": "",
                "meals": "",
                "chanb": "",
                "ftyp": ""
              }
            },
            {
              "tid": "CHBJNQO3ROTFP5Tq_1",
              "ref": 1,
              "ofcid": "",
              "sdur": "9:45",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "DOH",
                  "ar": "HYD",
                  "dd": "2023-06-29T19:45:00",
                  "ad": "2023-06-30T02:30:00",
                  "rbd": "",
                  "fn": "1316",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {},
                  "rph": "",
                  "jsell": "6E~1316~ ~~DOH~06/29/2023 19:45~HYD~06/30/2023 02:30~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~A~ ~6E~A0INT~5000~~0~1~~X",
                  "pr": {
                    "bf": 28373,
                    "tf": 32479,
                    "tax": [
                      {
                        "OT": 4106
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "28373_32479",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "paxpr": [
                    {
                      "bf": 28373,
                      "tf": 32479,
                      "tax": [
                        {
                          "OT": 4106
                        }
                      ],
                      "fbc": "A0INT",
                      "ptype": "ADT",
                      "scur": "INR",
                      "sup_fare": "28373_32479"
                    }
                  ],
                  "routeId": "7uvfkpd73g",
                  "productClass": "R",
                  "ruleNumber": "5000"
                },
                {
                  "fareKey": "0~A~ ~6E~AUINT~5003~~0~7~~X",
                  "pr": {
                    "bf": 29073,
                    "tf": 33204,
                    "tax": [
                      {
                        "OT": 4131
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "29073_33204",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "paxpr": [
                    {
                      "bf": 29073,
                      "tf": 33204,
                      "tax": [
                        {
                          "OT": 4131
                        }
                      ],
                      "fbc": "AUINT",
                      "ptype": "ADT",
                      "scur": "INR",
                      "sup_fare": "29073_33204"
                    }
                  ],
                  "routeId": "g0dit1py42i",
                  "productClass": "J",
                  "ruleNumber": "5003"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "Qjaaeqrl1XE=|aQFezKSWQkTEFuzy1c/XiE8E1gCe1mp7wL3TnlV5DA+x56hxCT+7ZWP6pBbfk21i1VpV7qcOGfUZINz5cX0sMPO7zDGGtcxXeJ5RNOP3ZplH1FG3CBzrsOYD796VyuQ29DfNwKuxQhE=",
              "stop": 0,
              "paxpr": [
                {
                  "bf": 28373,
                  "tf": 32479,
                  "fbc": "",
                  "psup_fare": "",
                  "tax": [
                    [
                      {
                        "OT": 4106
                      }
                    ]
                  ],
                  "ptype": "ADT",
                  "scur": "INR",
                  "sup_cur_fare": ""
                }
              ],
              "pr": {
                "bf": 28373,
                "tf": 32479,
                "tax": [
                  {
                    "OT": 4106
                  }
                ],
                "scur": "INR",
                "sup_fare": "",
                "sup_cur_fare": "",
                "cabb": "",
                "chekb": "",
                "seats": "",
                "meals": "",
                "chanb": "",
                "ftyp": ""
              }
            }
          ],
          "destorigins": [
            {
              "tid": "PLBA29xGTttzyO4y_1",
              "ref": 1,
              "sdur": "09:25",
              "re": "Refundable Before Departure",
              "stop": 1,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "AI",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DEL",
                  "dd": "2023-06-30T14:30:00",
                  "ad": "2023-06-30T16:55:00",
                  "rbd": "Q",
                  "fn": "523",
                  "eq": "319",
                  "ac": "AI",
                  "ma": "AI",
                  "seat": "1",
                  "oa": "AI",
                  "fa": [],
                  "fdur": "02:25",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": "3"
                  }
                },
                {
                  "ref": 2,
                  "da": "DEL",
                  "ar": "DOH",
                  "dd": "2023-06-30T19:50:00",
                  "ad": "2023-06-30T21:25:00",
                  "rbd": "U",
                  "fn": "971",
                  "trn": "3",
                  "eq": "32N",
                  "ac": "AI",
                  "ma": "AI",
                  "seat": "2",
                  "oa": "AI",
                  "fa": [],
                  "fdur": "04:05",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "3",
                    "arr": ""
                  }
                }
              ],
              "flightNumbers": "523/971",
              "bgd": [
                {
                  "qun": "30",
                  "wt": "kg"
                }
              ],
              "paxpr": [
                {
                  "tf": 16576,
                  "tax": [
                    {
                      "YR": 448
                    },
                    {
                      "YR": 224
                    },
                    {
                      "OT": 4032
                    }
                  ],
                  "bf": 11872,
                  "fbc": "UOWDLDH",
                  "fareType": "RP",
                  "pusup_fare": "530_740",
                  "psup_fare": "11872_16576",
                  "ptype": "ADT"
                }
              ],
              "pr": {
                "bf": 11872,
                "tf": 16576,
                "tax": [
                  {
                    "YR": 448
                  },
                  {
                    "YR": 224
                  },
                  {
                    "OT": 4032
                  }
                ],
                "scur": "QAR",
                "ucur": "INR",
                "exchange_rate": 22.4,
                "sup_fare": "530_740"
              }
            }
          ],
          "pr": {
            "bf": 40245,
            "tf": 49055,
            "tax": [
              {
                "OT": 4106
              },
              {
                "YR": 448
              },
              {
                "YR": 224
              },
              {
                "OT": 4032
              }
            ]
          },
          "paxpr": [
            {
              "bf": 40245,
              "tf": 49055,
              "tax": [
                [
                  {
                    "OT": 4106
                  }
                ],
                {
                  "YR": 448
                },
                {
                  "YR": 224
                },
                {
                  "OT": 4032
                }
              ]
            }
          ],
          "sty": "split",
          "sup": "4/0",
          "airlineList": "6E_AI",
          "mix_carrier": true
        },
        {
          "srcorigns": [
            {
              "tid": "IhMrTnLaWeMUB4Dh_1",
              "ref": 1,
              "ofcid": "",
              "sdur": "13:35",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "DOH",
                  "ar": "BOM",
                  "dd": "2023-06-29T08:15:00",
                  "ad": "2023-06-29T14:20:00",
                  "rbd": "",
                  "fn": "1304",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "2"
                  },
                  "rph": "",
                  "jsell": "6E~1304~ ~~DOH~06/29/2023 08:15~BOM~06/29/2023 14:20~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                },
                {
                  "ref": 2,
                  "da": "BOM",
                  "ar": "HYD",
                  "dd": "2023-06-29T17:20:00",
                  "ad": "2023-06-29T18:50:00",
                  "rbd": "",
                  "fn": "6571",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "2"
                  },
                  "rph": "",
                  "jsell": "6E~6571~ ~~BOM~06/29/2023 17:20~HYD~06/29/2023 18:50~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "1~W~ ~6E~W0INT~5000~~0~7~~^2~R~ ~6E~RCIP~1070~~0~8~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 28729,
                      "fbc": "W0INT/RCIP",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "28729_32898",
                      "tax": [
                        {
                          "OT": 4169
                        }
                      ],
                      "tf": 32898
                    }
                  ],
                  "pr": {
                    "bf": 28729,
                    "tf": 32898,
                    "tax": [
                      {
                        "OT": 4169
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "28729_32898",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "c0i0ja0ypni",
                  "ruleNumber": "5000",
                  "productClass": "R"
                },
                {
                  "fareKey": "1~W~ ~6E~WUINT~5003~~0~12~~^2~R~ ~6E~RUIP~2009~~0~4~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 30029,
                      "fbc": "WUINT/RUIP",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "30029_34243",
                      "tax": [
                        {
                          "OT": 4214
                        }
                      ],
                      "tf": 34243
                    }
                  ],
                  "pr": {
                    "bf": 30029,
                    "tf": 34243,
                    "tax": [
                      {
                        "OT": 4214
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "30029_34243",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "0qfapdtxv6",
                  "ruleNumber": "5003",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "Qjaaeqrl1XE=|aQFezKSWQkTEFuzy1c/XiE8E1gCe1mp7wL3TnlV5DA+x56hxCT+7ZWP6pBbfk21i1VpV7qcOGfUZINz5cX0sMPO7zDGGtcxXeJ5RNOP3ZplH1FG3CBzrsOYD796VyuQ29DfNwKuxQhE=",
              "stop": 1,
              "paxpr": [
                {
                  "bf": 28729,
                  "tf": 32898,
                  "fbc": "",
                  "psup_fare": "",
                  "tax": [
                    [
                      {
                        "OT": 4169
                      }
                    ]
                  ],
                  "ptype": "ADT",
                  "scur": "INR",
                  "sup_cur_fare": ""
                }
              ],
              "pr": {
                "bf": 28729,
                "tf": 32898,
                "tax": [
                  {
                    "OT": 4169
                  }
                ],
                "scur": "INR",
                "sup_fare": "",
                "sup_cur_fare": "",
                "cabb": "",
                "chekb": "",
                "seats": "",
                "meals": "",
                "chanb": "",
                "ftyp": ""
              }
            }
          ],
          "destorigins": [
            {
              "tid": "PLBA29xGTttzyO4y_1",
              "ref": 1,
              "sdur": "09:25",
              "re": "Refundable Before Departure",
              "stop": 1,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "AI",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DEL",
                  "dd": "2023-06-30T14:30:00",
                  "ad": "2023-06-30T16:55:00",
                  "rbd": "Q",
                  "fn": "523",
                  "eq": "319",
                  "ac": "AI",
                  "ma": "AI",
                  "seat": "1",
                  "oa": "AI",
                  "fa": [],
                  "fdur": "02:25",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": "3"
                  }
                },
                {
                  "ref": 2,
                  "da": "DEL",
                  "ar": "DOH",
                  "dd": "2023-06-30T19:50:00",
                  "ad": "2023-06-30T21:25:00",
                  "rbd": "U",
                  "fn": "971",
                  "trn": "3",
                  "eq": "32N",
                  "ac": "AI",
                  "ma": "AI",
                  "seat": "2",
                  "oa": "AI",
                  "fa": [],
                  "fdur": "04:05",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "3",
                    "arr": ""
                  }
                }
              ],
              "flightNumbers": "523/971",
              "bgd": [
                {
                  "qun": "30",
                  "wt": "kg"
                }
              ],
              "paxpr": [
                {
                  "tf": 16576,
                  "tax": [
                    {
                      "YR": 448
                    },
                    {
                      "YR": 224
                    },
                    {
                      "OT": 4032
                    }
                  ],
                  "bf": 11872,
                  "fbc": "UOWDLDH",
                  "fareType": "RP",
                  "pusup_fare": "530_740",
                  "psup_fare": "11872_16576",
                  "ptype": "ADT"
                }
              ],
              "pr": {
                "bf": 11872,
                "tf": 16576,
                "tax": [
                  {
                    "YR": 448
                  },
                  {
                    "YR": 224
                  },
                  {
                    "OT": 4032
                  }
                ],
                "scur": "QAR",
                "ucur": "INR",
                "exchange_rate": 22.4,
                "sup_fare": "530_740"
              }
            }
          ],
          "pr": {
            "bf": 40601,
            "tf": 49474,
            "tax": [
              {
                "OT": 4169
              },
              {
                "YR": 448
              },
              {
                "YR": 224
              },
              {
                "OT": 4032
              }
            ]
          },
          "paxpr": [
            {
              "bf": 40601,
              "tf": 49474,
              "tax": [
                [
                  {
                    "OT": 4169
                  }
                ],
                {
                  "YR": 448
                },
                {
                  "YR": 224
                },
                {
                  "OT": 4032
                }
              ]
            }
          ],
          "sty": "split",
          "sup": "4/0",
          "airlineList": "6E_AI",
          "mix_carrier": true
        },
        {
          "srcorigns": [
            {
              "tid": "4B7yO6HNNcmNlngc_1",
              "ref": 1,
              "ofcid": "",
              "sdur": "14:10",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "DOH",
                  "ar": "BOM",
                  "dd": "2023-06-29T19:45:00",
                  "ad": "2023-06-30T01:55:00",
                  "rbd": "",
                  "fn": "1306",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "2"
                  },
                  "rph": "",
                  "jsell": "6E~1306~ ~~DOH~06/29/2023 19:45~BOM~06/30/2023 01:55~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                },
                {
                  "ref": 2,
                  "da": "BOM",
                  "ar": "HYD",
                  "dd": "2023-06-30T05:20:00",
                  "ad": "2023-06-30T06:55:00",
                  "rbd": "",
                  "fn": " 461",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "2"
                  },
                  "rph": "",
                  "jsell": "6E~ 461~ ~~BOM~06/30/2023 05:20~HYD~06/30/2023 06:55~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "1~W~ ~6E~W0INT~5000~~0~7~~^2~L~ ~6E~LCIP~1070~~0~12~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 29825,
                      "fbc": "W0INT/LCIP",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "29825_34032",
                      "tax": [
                        {
                          "OT": 4207
                        }
                      ],
                      "tf": 34032
                    }
                  ],
                  "pr": {
                    "bf": 29825,
                    "tf": 34032,
                    "tax": [
                      {
                        "OT": 4207
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "29825_34032",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "mjfsn2pkpp",
                  "ruleNumber": "5000",
                  "productClass": "R"
                },
                {
                  "fareKey": "1~W~ ~6E~WUINT~5003~~0~12~~^2~L~ ~6E~LUIP~2009~~0~5~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 31125,
                      "fbc": "WUINT/LUIP",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "31125_35377",
                      "tax": [
                        {
                          "OT": 4252
                        }
                      ],
                      "tf": 35377
                    }
                  ],
                  "pr": {
                    "bf": 31125,
                    "tf": 35377,
                    "tax": [
                      {
                        "OT": 4252
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "31125_35377",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "09r1p3tt7ij",
                  "ruleNumber": "5003",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "Qjaaeqrl1XE=|aQFezKSWQkTEFuzy1c/XiE8E1gCe1mp7wL3TnlV5DA+x56hxCT+7ZWP6pBbfk21i1VpV7qcOGfUZINz5cX0sMPO7zDGGtcxXeJ5RNOP3ZplH1FG3CBzrsOYD796VyuQ29DfNwKuxQhE=",
              "stop": 1,
              "paxpr": [
                {
                  "bf": 29825,
                  "tf": 34032,
                  "fbc": "",
                  "psup_fare": "",
                  "tax": [
                    [
                      {
                        "OT": 4207
                      }
                    ]
                  ],
                  "ptype": "ADT",
                  "scur": "INR",
                  "sup_cur_fare": ""
                }
              ],
              "pr": {
                "bf": 29825,
                "tf": 34032,
                "tax": [
                  {
                    "OT": 4207
                  }
                ],
                "scur": "INR",
                "sup_fare": "",
                "sup_cur_fare": "",
                "cabb": "",
                "chekb": "",
                "seats": "",
                "meals": "",
                "chanb": "",
                "ftyp": ""
              }
            }
          ],
          "destorigins": [
            {
              "tid": "PLBA29xGTttzyO4y_1",
              "ref": 1,
              "sdur": "09:25",
              "re": "Refundable Before Departure",
              "stop": 1,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "AI",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DEL",
                  "dd": "2023-06-30T14:30:00",
                  "ad": "2023-06-30T16:55:00",
                  "rbd": "Q",
                  "fn": "523",
                  "eq": "319",
                  "ac": "AI",
                  "ma": "AI",
                  "seat": "1",
                  "oa": "AI",
                  "fa": [],
                  "fdur": "02:25",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": "3"
                  }
                },
                {
                  "ref": 2,
                  "da": "DEL",
                  "ar": "DOH",
                  "dd": "2023-06-30T19:50:00",
                  "ad": "2023-06-30T21:25:00",
                  "rbd": "U",
                  "fn": "971",
                  "trn": "3",
                  "eq": "32N",
                  "ac": "AI",
                  "ma": "AI",
                  "seat": "2",
                  "oa": "AI",
                  "fa": [],
                  "fdur": "04:05",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "3",
                    "arr": ""
                  }
                }
              ],
              "flightNumbers": "523/971",
              "bgd": [
                {
                  "qun": "30",
                  "wt": "kg"
                }
              ],
              "paxpr": [
                {
                  "tf": 16576,
                  "tax": [
                    {
                      "YR": 448
                    },
                    {
                      "YR": 224
                    },
                    {
                      "OT": 4032
                    }
                  ],
                  "bf": 11872,
                  "fbc": "UOWDLDH",
                  "fareType": "RP",
                  "pusup_fare": "530_740",
                  "psup_fare": "11872_16576",
                  "ptype": "ADT"
                }
              ],
              "pr": {
                "bf": 11872,
                "tf": 16576,
                "tax": [
                  {
                    "YR": 448
                  },
                  {
                    "YR": 224
                  },
                  {
                    "OT": 4032
                  }
                ],
                "scur": "QAR",
                "ucur": "INR",
                "exchange_rate": 22.4,
                "sup_fare": "530_740"
              }
            }
          ],
          "pr": {
            "bf": 41697,
            "tf": 50608,
            "tax": [
              {
                "OT": 4207
              },
              {
                "YR": 448
              },
              {
                "YR": 224
              },
              {
                "OT": 4032
              }
            ]
          },
          "paxpr": [
            {
              "bf": 41697,
              "tf": 50608,
              "tax": [
                [
                  {
                    "OT": 4207
                  }
                ],
                {
                  "YR": 448
                },
                {
                  "YR": 224
                },
                {
                  "OT": 4032
                }
              ]
            }
          ],
          "sty": "split",
          "sup": "4/0",
          "airlineList": "6E_AI",
          "mix_carrier": true
        },
        {
          "srcorigns": [
            {
              "tid": "kSWD1R3LvfRyNlz6_1",
              "ref": 1,
              "ofcid": "",
              "sdur": "14:30",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "DOH",
                  "ar": "MAA",
                  "dd": "2023-06-29T02:00:00",
                  "ad": "2023-06-29T09:05:00",
                  "rbd": "",
                  "fn": "1322",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "3"
                  },
                  "rph": "",
                  "jsell": "6E~1322~ ~~DOH~06/29/2023 02:00~MAA~06/29/2023 09:05~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                },
                {
                  "ref": 2,
                  "da": "MAA",
                  "ar": "HYD",
                  "dd": "2023-06-29T12:20:00",
                  "ad": "2023-06-29T13:30:00",
                  "rbd": "",
                  "fn": " 604",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "1"
                  },
                  "rph": "",
                  "jsell": "6E~ 604~ ~~MAA~06/29/2023 12:20~HYD~06/29/2023 13:30~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~R~ ~6E~RCINT~6200~~0~65~~X^0~R~ ~6E~RCINT~6200~~0~65~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 9819,
                      "fbc": "RCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "9819_13326",
                      "tax": [
                        {
                          "OT": 3507
                        }
                      ],
                      "tf": 13326
                    }
                  ],
                  "pr": {
                    "bf": 9819,
                    "tf": 13326,
                    "tax": [
                      {
                        "OT": 3507
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "9819_13326",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "3ejfgmtsn3",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~R~ ~6E~RCUINT~6202~~0~4~~X^0~R~ ~6E~RCUINT~6202~~0~4~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 10519,
                      "fbc": "RCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "10519_14050",
                      "tax": [
                        {
                          "OT": 3531
                        }
                      ],
                      "tf": 14050
                    }
                  ],
                  "pr": {
                    "bf": 10519,
                    "tf": 14050,
                    "tax": [
                      {
                        "OT": 3531
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "10519_14050",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "asnla9i2l0l",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "Qjaaeqrl1XE=|aQFezKSWQkTEFuzy1c/XiE8E1gCe1mp7wL3TnlV5DA+x56hxCT+7ZWP6pBbfk21i1VpV7qcOGfUZINz5cX0sMPO7zDGGtcxXeJ5RNOP3ZplH1FG3CBzrsOYD796VyuQ29DfNwKuxQhE=",
              "stop": 1,
              "paxpr": [
                {
                  "bf": 9819,
                  "tf": 13326,
                  "fbc": "",
                  "psup_fare": "",
                  "tax": [
                    [
                      {
                        "OT": 3507
                      }
                    ]
                  ],
                  "ptype": "ADT",
                  "scur": "INR",
                  "sup_cur_fare": ""
                }
              ],
              "pr": {
                "bf": 9819,
                "tf": 13326,
                "tax": [
                  {
                    "OT": 3507
                  }
                ],
                "scur": "INR",
                "sup_fare": "",
                "sup_cur_fare": "",
                "cabb": "",
                "chekb": "",
                "seats": "",
                "meals": "",
                "chanb": "",
                "ftyp": ""
              }
            },
            {
              "tid": "T23thK491dFfh7nV_1",
              "ref": 1,
              "ofcid": "",
              "sdur": "19:15",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "DOH",
                  "ar": "MAA",
                  "dd": "2023-06-29T02:00:00",
                  "ad": "2023-06-29T09:05:00",
                  "rbd": "",
                  "fn": "1322",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "3"
                  },
                  "rph": "",
                  "jsell": "6E~1322~ ~~DOH~06/29/2023 02:00~MAA~06/29/2023 09:05~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                },
                {
                  "ref": 2,
                  "da": "MAA",
                  "ar": "HYD",
                  "dd": "2023-06-29T17:00:00",
                  "ad": "2023-06-29T18:15:00",
                  "rbd": "",
                  "fn": " 562",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "1"
                  },
                  "rph": "",
                  "jsell": "6E~ 562~ ~~MAA~06/29/2023 17:00~HYD~06/29/2023 18:15~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~R~ ~6E~RCINT~6200~~0~65~~X^0~R~ ~6E~RCINT~6200~~0~65~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 9819,
                      "fbc": "RCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "9819_13326",
                      "tax": [
                        {
                          "OT": 3507
                        }
                      ],
                      "tf": 13326
                    }
                  ],
                  "pr": {
                    "bf": 9819,
                    "tf": 13326,
                    "tax": [
                      {
                        "OT": 3507
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "9819_13326",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "iuz56zp12fi",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~R~ ~6E~RCUINT~6202~~0~4~~X^0~R~ ~6E~RCUINT~6202~~0~4~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 10519,
                      "fbc": "RCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "10519_14050",
                      "tax": [
                        {
                          "OT": 3531
                        }
                      ],
                      "tf": 14050
                    }
                  ],
                  "pr": {
                    "bf": 10519,
                    "tf": 14050,
                    "tax": [
                      {
                        "OT": 3531
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "10519_14050",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "fixyzs51cr",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "Qjaaeqrl1XE=|aQFezKSWQkTEFuzy1c/XiE8E1gCe1mp7wL3TnlV5DA+x56hxCT+7ZWP6pBbfk21i1VpV7qcOGfUZINz5cX0sMPO7zDGGtcxXeJ5RNOP3ZplH1FG3CBzrsOYD796VyuQ29DfNwKuxQhE=",
              "stop": 1,
              "paxpr": [
                {
                  "bf": 9819,
                  "tf": 13326,
                  "fbc": "",
                  "psup_fare": "",
                  "tax": [
                    [
                      {
                        "OT": 3507
                      }
                    ]
                  ],
                  "ptype": "ADT",
                  "scur": "INR",
                  "sup_cur_fare": ""
                }
              ],
              "pr": {
                "bf": 9819,
                "tf": 13326,
                "tax": [
                  {
                    "OT": 3507
                  }
                ],
                "scur": "INR",
                "sup_fare": "",
                "sup_cur_fare": "",
                "cabb": "",
                "chekb": "",
                "seats": "",
                "meals": "",
                "chanb": "",
                "ftyp": ""
              }
            },
            {
              "tid": "SiX2sy67k87wvIc4_1",
              "ref": 1,
              "ofcid": "",
              "sdur": "18:10",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "DOH",
                  "ar": "MAA",
                  "dd": "2023-06-29T02:00:00",
                  "ad": "2023-06-29T09:05:00",
                  "rbd": "",
                  "fn": "1322",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "3"
                  },
                  "rph": "",
                  "jsell": "6E~1322~ ~~DOH~06/29/2023 02:00~MAA~06/29/2023 09:05~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                },
                {
                  "ref": 2,
                  "da": "MAA",
                  "ar": "HYD",
                  "dd": "2023-06-29T16:00:00",
                  "ad": "2023-06-29T17:10:00",
                  "rbd": "",
                  "fn": "6401",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "1"
                  },
                  "rph": "",
                  "jsell": "6E~6401~ ~~MAA~06/29/2023 16:00~HYD~06/29/2023 17:10~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~R~ ~6E~RCINT~6200~~0~65~~X^0~R~ ~6E~RCINT~6200~~0~65~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 9819,
                      "fbc": "RCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "9819_13326",
                      "tax": [
                        {
                          "OT": 3507
                        }
                      ],
                      "tf": 13326
                    }
                  ],
                  "pr": {
                    "bf": 9819,
                    "tf": 13326,
                    "tax": [
                      {
                        "OT": 3507
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "9819_13326",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "ouof5d7tea",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~R~ ~6E~RCUINT~6202~~0~4~~X^0~R~ ~6E~RCUINT~6202~~0~4~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 10519,
                      "fbc": "RCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "10519_14050",
                      "tax": [
                        {
                          "OT": 3531
                        }
                      ],
                      "tf": 14050
                    }
                  ],
                  "pr": {
                    "bf": 10519,
                    "tf": 14050,
                    "tax": [
                      {
                        "OT": 3531
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "10519_14050",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "1b289r59s",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "Qjaaeqrl1XE=|aQFezKSWQkTEFuzy1c/XiE8E1gCe1mp7wL3TnlV5DA+x56hxCT+7ZWP6pBbfk21i1VpV7qcOGfUZINz5cX0sMPO7zDGGtcxXeJ5RNOP3ZplH1FG3CBzrsOYD796VyuQ29DfNwKuxQhE=",
              "stop": 1,
              "paxpr": [
                {
                  "bf": 9819,
                  "tf": 13326,
                  "fbc": "",
                  "psup_fare": "",
                  "tax": [
                    [
                      {
                        "OT": 3507
                      }
                    ]
                  ],
                  "ptype": "ADT",
                  "scur": "INR",
                  "sup_cur_fare": ""
                }
              ],
              "pr": {
                "bf": 9819,
                "tf": 13326,
                "tax": [
                  {
                    "OT": 3507
                  }
                ],
                "scur": "INR",
                "sup_fare": "",
                "sup_cur_fare": "",
                "cabb": "",
                "chekb": "",
                "seats": "",
                "meals": "",
                "chanb": "",
                "ftyp": ""
              }
            },
            {
              "tid": "FU3drl2wGMBptpID_1",
              "ref": 1,
              "ofcid": "",
              "sdur": "16:0",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "DOH",
                  "ar": "DEL",
                  "dd": "2023-06-29T19:40:00",
                  "ad": "2023-06-30T02:00:00",
                  "rbd": "",
                  "fn": "1308",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "3"
                  },
                  "rph": "",
                  "jsell": "6E~1308~ ~~DOH~06/29/2023 19:40~DEL~06/30/2023 02:00~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                },
                {
                  "ref": 2,
                  "da": "DEL",
                  "ar": "HYD",
                  "dd": "2023-06-30T06:30:00",
                  "ad": "2023-06-30T08:40:00",
                  "rbd": "",
                  "fn": "6203",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "1"
                  },
                  "rph": "",
                  "jsell": "6E~6203~ ~~DEL~06/30/2023 06:30~HYD~06/30/2023 08:40~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~R~ ~6E~RCINT~6200~~0~65~~X^0~R~ ~6E~RCINT~6200~~0~65~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 9819,
                      "fbc": "RCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "9819_13326",
                      "tax": [
                        {
                          "OT": 3507
                        }
                      ],
                      "tf": 13326
                    }
                  ],
                  "pr": {
                    "bf": 9819,
                    "tf": 13326,
                    "tax": [
                      {
                        "OT": 3507
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "9819_13326",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "1ce4t0ev0o",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~R~ ~6E~RCUINT~6202~~0~4~~X^0~R~ ~6E~RCUINT~6202~~0~4~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 10519,
                      "fbc": "RCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "10519_14050",
                      "tax": [
                        {
                          "OT": 3531
                        }
                      ],
                      "tf": 14050
                    }
                  ],
                  "pr": {
                    "bf": 10519,
                    "tf": 14050,
                    "tax": [
                      {
                        "OT": 3531
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "10519_14050",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "1aosvbrps9g",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "Qjaaeqrl1XE=|aQFezKSWQkTEFuzy1c/XiE8E1gCe1mp7wL3TnlV5DA+x56hxCT+7ZWP6pBbfk21i1VpV7qcOGfUZINz5cX0sMPO7zDGGtcxXeJ5RNOP3ZplH1FG3CBzrsOYD796VyuQ29DfNwKuxQhE=",
              "stop": 1,
              "paxpr": [
                {
                  "bf": 9819,
                  "tf": 13326,
                  "fbc": "",
                  "psup_fare": "",
                  "tax": [
                    [
                      {
                        "OT": 3507
                      }
                    ]
                  ],
                  "ptype": "ADT",
                  "scur": "INR",
                  "sup_cur_fare": ""
                }
              ],
              "pr": {
                "bf": 9819,
                "tf": 13326,
                "tax": [
                  {
                    "OT": 3507
                  }
                ],
                "scur": "INR",
                "sup_fare": "",
                "sup_cur_fare": "",
                "cabb": "",
                "chekb": "",
                "seats": "",
                "meals": "",
                "chanb": "",
                "ftyp": ""
              }
            }
          ],
          "destorigins": [
            {
              "tid": "o4dGVuQJgCyaGMOJ_1",
              "ref": 1,
              "sdur": "04:10",
              "re": "Refundable Before Departure",
              "stop": 0,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "QR",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DOH",
                  "dd": "2023-06-30T21:55:00",
                  "ad": "2023-06-30T23:35:00",
                  "rbd": "N",
                  "fn": "4779",
                  "eq": "320",
                  "ac": "QR",
                  "ma": "QR",
                  "seat": "9",
                  "oa": "6E",
                  "fa": [],
                  "fdur": "04:10",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": ""
                  }
                }
              ],
              "flightNumbers": "4779",
              "bgd": [
                {
                  "qun": "25",
                  "wt": "kg"
                }
              ],
              "paxpr": [
                {
                  "tf": 39200,
                  "tax": [
                    {
                      "YQ": 9856
                    },
                    {
                      "YR": 896
                    },
                    {
                      "OT": 5152
                    }
                  ],
                  "bf": 23296,
                  "fbc": "NLR4I1RQ",
                  "fareType": "RP",
                  "pusup_fare": "1040_1750",
                  "psup_fare": "23296_39200",
                  "ptype": "ADT"
                }
              ],
              "pr": {
                "bf": 23296,
                "tf": 39200,
                "tax": [
                  {
                    "YQ": 9856
                  },
                  {
                    "YR": 896
                  },
                  {
                    "OT": 5152
                  }
                ],
                "scur": "QAR",
                "ucur": "INR",
                "exchange_rate": 22.4,
                "sup_fare": "1040_1750"
              }
            },
            {
              "tid": "LithwaiTgVo0zTSn_1",
              "ref": 1,
              "sdur": "04:10",
              "re": "Refundable Before Departure",
              "stop": 0,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "QR",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DOH",
                  "dd": "2023-06-30T17:05:00",
                  "ad": "2023-06-30T18:45:00",
                  "rbd": "N",
                  "fn": "4777",
                  "eq": "320",
                  "ac": "QR",
                  "ma": "QR",
                  "seat": "9",
                  "oa": "6E",
                  "fa": [],
                  "fdur": "04:10",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": ""
                  }
                }
              ],
              "flightNumbers": "4777",
              "bgd": [
                {
                  "qun": "25",
                  "wt": "kg"
                }
              ],
              "paxpr": [
                {
                  "tf": 39200,
                  "tax": [
                    {
                      "YQ": 9856
                    },
                    {
                      "YR": 896
                    },
                    {
                      "OT": 5152
                    }
                  ],
                  "bf": 23296,
                  "fbc": "NLR4I1RQ",
                  "fareType": "RP",
                  "pusup_fare": "1040_1750",
                  "psup_fare": "23296_39200",
                  "ptype": "ADT"
                }
              ],
              "pr": {
                "bf": 23296,
                "tf": 39200,
                "tax": [
                  {
                    "YQ": 9856
                  },
                  {
                    "YR": 896
                  },
                  {
                    "OT": 5152
                  }
                ],
                "scur": "QAR",
                "ucur": "INR",
                "exchange_rate": 22.4,
                "sup_fare": "1040_1750"
              }
            },
            {
              "tid": "VtTRT4CTTnmiBqt3_1",
              "ref": 1,
              "sdur": "04:10",
              "re": "Refundable Before Departure",
              "stop": 0,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "QR",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DOH",
                  "dd": "2023-06-30T04:10:00",
                  "ad": "2023-06-30T05:50:00",
                  "rbd": "N",
                  "fn": "4775",
                  "eq": "320",
                  "ac": "QR",
                  "ma": "QR",
                  "seat": "9",
                  "oa": "6E",
                  "fa": [],
                  "fdur": "04:10",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": ""
                  }
                }
              ],
              "flightNumbers": "4775",
              "bgd": [
                {
                  "qun": "25",
                  "wt": "kg"
                }
              ],
              "paxpr": [
                {
                  "tf": 39200,
                  "tax": [
                    {
                      "YQ": 9856
                    },
                    {
                      "YR": 896
                    },
                    {
                      "OT": 5152
                    }
                  ],
                  "bf": 23296,
                  "fbc": "NLR4I1RQ",
                  "fareType": "RP",
                  "pusup_fare": "1040_1750",
                  "psup_fare": "23296_39200",
                  "ptype": "ADT"
                }
              ],
              "pr": {
                "bf": 23296,
                "tf": 39200,
                "tax": [
                  {
                    "YQ": 9856
                  },
                  {
                    "YR": 896
                  },
                  {
                    "OT": 5152
                  }
                ],
                "scur": "QAR",
                "ucur": "INR",
                "exchange_rate": 22.4,
                "sup_fare": "1040_1750"
              }
            }
          ],
          "pr": {
            "bf": 33115,
            "tf": 52526,
            "tax": [
              {
                "OT": 3507
              },
              {
                "YQ": 9856
              },
              {
                "YR": 896
              },
              {
                "OT": 5152
              }
            ]
          },
          "paxpr": [
            {
              "bf": 33115,
              "tf": 52526,
              "tax": [
                [
                  {
                    "OT": 3507
                  }
                ],
                {
                  "YQ": 9856
                },
                {
                  "YR": 896
                },
                {
                  "OT": 5152
                }
              ]
            }
          ],
          "sty": "split",
          "sup": "4/0",
          "airlineList": "6E_6E",
          "mix_carrier": true
        },
        {
          "srcorigns": [
            {
              "tid": "eu5XDxVL1EJHQb6o_1",
              "ref": 1,
              "sdur": "04:15",
              "re": "Refundable Before Departure",
              "stop": 0,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "QR",
              "seg": [
                {
                  "ref": 1,
                  "da": "DOH",
                  "ar": "HYD",
                  "dd": "2023-06-29T08:15:00",
                  "ad": "2023-06-29T15:00:00",
                  "rbd": "V",
                  "fn": "4776",
                  "eq": "320",
                  "ac": "QR",
                  "ma": "QR",
                  "seat": "9",
                  "oa": "6E",
                  "fa": [],
                  "fdur": "04:15",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": ""
                  }
                }
              ],
              "flightNumbers": "4776",
              "bgd": [
                {
                  "qun": "30",
                  "wt": "kg"
                }
              ],
              "paxpr": [
                {
                  "tf": 36064,
                  "tax": [
                    {
                      "YQ": 3136
                    },
                    {
                      "YR": 1344
                    },
                    {
                      "OT": 3136
                    }
                  ],
                  "bf": 28448,
                  "fbc": "VHQAI1SI",
                  "fareType": "RP",
                  "pusup_fare": "1270_1610",
                  "psup_fare": "28448_36064",
                  "ptype": "ADT"
                }
              ],
              "pr": {
                "bf": 28448,
                "tf": 36064,
                "tax": [
                  {
                    "YQ": 3136
                  },
                  {
                    "YR": 1344
                  },
                  {
                    "OT": 3136
                  }
                ],
                "scur": "QAR",
                "ucur": "INR",
                "exchange_rate": 22.4,
                "sup_fare": "1270_1610"
              }
            },
            {
              "tid": "50tefIVt11B2ZJT3_1",
              "ref": 1,
              "sdur": "04:15",
              "re": "Refundable Before Departure",
              "stop": 0,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "QR",
              "seg": [
                {
                  "ref": 1,
                  "da": "DOH",
                  "ar": "HYD",
                  "dd": "2023-06-29T02:00:00",
                  "ad": "2023-06-29T08:45:00",
                  "rbd": "V",
                  "fn": "4778",
                  "eq": "320",
                  "ac": "QR",
                  "ma": "QR",
                  "seat": "9",
                  "oa": "6E",
                  "fa": [],
                  "fdur": "04:15",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": ""
                  }
                }
              ],
              "flightNumbers": "4778",
              "bgd": [
                {
                  "qun": "30",
                  "wt": "kg"
                }
              ],
              "paxpr": [
                {
                  "tf": 36064,
                  "tax": [
                    {
                      "YQ": 3136
                    },
                    {
                      "YR": 1344
                    },
                    {
                      "OT": 3136
                    }
                  ],
                  "bf": 28448,
                  "fbc": "VHQAI1SI",
                  "fareType": "RP",
                  "pusup_fare": "1270_1610",
                  "psup_fare": "28448_36064",
                  "ptype": "ADT"
                }
              ],
              "pr": {
                "bf": 28448,
                "tf": 36064,
                "tax": [
                  {
                    "YQ": 3136
                  },
                  {
                    "YR": 1344
                  },
                  {
                    "OT": 3136
                  }
                ],
                "scur": "QAR",
                "ucur": "INR",
                "exchange_rate": 22.4,
                "sup_fare": "1270_1610"
              }
            }
          ],
          "destorigins": [
            {
              "tid": "PLBA29xGTttzyO4y_1",
              "ref": 1,
              "sdur": "09:25",
              "re": "Refundable Before Departure",
              "stop": 1,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "AI",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DEL",
                  "dd": "2023-06-30T14:30:00",
                  "ad": "2023-06-30T16:55:00",
                  "rbd": "Q",
                  "fn": "523",
                  "eq": "319",
                  "ac": "AI",
                  "ma": "AI",
                  "seat": "1",
                  "oa": "AI",
                  "fa": [],
                  "fdur": "02:25",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": "3"
                  }
                },
                {
                  "ref": 2,
                  "da": "DEL",
                  "ar": "DOH",
                  "dd": "2023-06-30T19:50:00",
                  "ad": "2023-06-30T21:25:00",
                  "rbd": "U",
                  "fn": "971",
                  "trn": "3",
                  "eq": "32N",
                  "ac": "AI",
                  "ma": "AI",
                  "seat": "2",
                  "oa": "AI",
                  "fa": [],
                  "fdur": "04:05",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "3",
                    "arr": ""
                  }
                }
              ],
              "flightNumbers": "523/971",
              "bgd": [
                {
                  "qun": "30",
                  "wt": "kg"
                }
              ],
              "paxpr": [
                {
                  "tf": 16576,
                  "tax": [
                    {
                      "YR": 448
                    },
                    {
                      "YR": 224
                    },
                    {
                      "OT": 4032
                    }
                  ],
                  "bf": 11872,
                  "fbc": "UOWDLDH",
                  "fareType": "RP",
                  "pusup_fare": "530_740",
                  "psup_fare": "11872_16576",
                  "ptype": "ADT"
                }
              ],
              "pr": {
                "bf": 11872,
                "tf": 16576,
                "tax": [
                  {
                    "YR": 448
                  },
                  {
                    "YR": 224
                  },
                  {
                    "OT": 4032
                  }
                ],
                "scur": "QAR",
                "ucur": "INR",
                "exchange_rate": 22.4,
                "sup_fare": "530_740"
              }
            }
          ],
          "pr": {
            "bf": 40320,
            "tf": 52640,
            "tax": [
              {
                "YQ": 3136
              },
              {
                "YR": 1344
              },
              {
                "OT": 3136
              },
              {
                "YR": 448
              },
              {
                "YR": 224
              },
              {
                "OT": 4032
              }
            ]
          },
          "paxpr": [
            {
              "bf": 40320,
              "tf": 52640,
              "tax": [
                {
                  "YQ": 3136
                },
                {
                  "YR": 1344
                },
                {
                  "OT": 3136
                },
                {
                  "YR": 448
                },
                {
                  "YR": 224
                },
                {
                  "OT": 4032
                }
              ]
            }
          ],
          "sty": "split",
          "sup": "0/0",
          "airlineList": "6E_AI",
          "mix_carrier": true
        },
        {
          "srcorigns": [
            {
              "tid": "1RSk9SieyUL0ZAPu_1",
              "ref": 1,
              "sdur": "04:15",
              "re": "Refundable Before Departure",
              "stop": 0,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "QR",
              "seg": [
                {
                  "ref": 1,
                  "da": "DOH",
                  "ar": "HYD",
                  "dd": "2023-06-29T19:45:00",
                  "ad": "2023-06-30T02:30:00",
                  "rbd": "V",
                  "fn": "4774",
                  "eq": "320",
                  "ac": "QR",
                  "ma": "QR",
                  "seat": "9",
                  "oa": "6E",
                  "fa": [],
                  "fdur": "04:15",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": ""
                  }
                }
              ],
              "flightNumbers": "4774",
              "bgd": [
                {
                  "qun": "30",
                  "wt": "kg"
                }
              ],
              "paxpr": [
                {
                  "tf": 37856,
                  "tax": [
                    {
                      "YQ": 3136
                    },
                    {
                      "YR": 1344
                    },
                    {
                      "OT": 3136
                    }
                  ],
                  "bf": 30240,
                  "fbc": "VHQAI1SI",
                  "fareType": "RP",
                  "pusup_fare": "1350_1690",
                  "psup_fare": "30240_37856",
                  "ptype": "ADT"
                }
              ],
              "pr": {
                "bf": 30240,
                "tf": 37856,
                "tax": [
                  {
                    "YQ": 3136
                  },
                  {
                    "YR": 1344
                  },
                  {
                    "OT": 3136
                  }
                ],
                "scur": "QAR",
                "ucur": "INR",
                "exchange_rate": 22.4,
                "sup_fare": "1350_1690"
              }
            }
          ],
          "destorigins": [
            {
              "tid": "PLBA29xGTttzyO4y_1",
              "ref": 1,
              "sdur": "09:25",
              "re": "Refundable Before Departure",
              "stop": 1,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "AI",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DEL",
                  "dd": "2023-06-30T14:30:00",
                  "ad": "2023-06-30T16:55:00",
                  "rbd": "Q",
                  "fn": "523",
                  "eq": "319",
                  "ac": "AI",
                  "ma": "AI",
                  "seat": "1",
                  "oa": "AI",
                  "fa": [],
                  "fdur": "02:25",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": "3"
                  }
                },
                {
                  "ref": 2,
                  "da": "DEL",
                  "ar": "DOH",
                  "dd": "2023-06-30T19:50:00",
                  "ad": "2023-06-30T21:25:00",
                  "rbd": "U",
                  "fn": "971",
                  "trn": "3",
                  "eq": "32N",
                  "ac": "AI",
                  "ma": "AI",
                  "seat": "2",
                  "oa": "AI",
                  "fa": [],
                  "fdur": "04:05",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "3",
                    "arr": ""
                  }
                }
              ],
              "flightNumbers": "523/971",
              "bgd": [
                {
                  "qun": "30",
                  "wt": "kg"
                }
              ],
              "paxpr": [
                {
                  "tf": 16576,
                  "tax": [
                    {
                      "YR": 448
                    },
                    {
                      "YR": 224
                    },
                    {
                      "OT": 4032
                    }
                  ],
                  "bf": 11872,
                  "fbc": "UOWDLDH",
                  "fareType": "RP",
                  "pusup_fare": "530_740",
                  "psup_fare": "11872_16576",
                  "ptype": "ADT"
                }
              ],
              "pr": {
                "bf": 11872,
                "tf": 16576,
                "tax": [
                  {
                    "YR": 448
                  },
                  {
                    "YR": 224
                  },
                  {
                    "OT": 4032
                  }
                ],
                "scur": "QAR",
                "ucur": "INR",
                "exchange_rate": 22.4,
                "sup_fare": "530_740"
              }
            }
          ],
          "pr": {
            "bf": 42112,
            "tf": 54432,
            "tax": [
              {
                "YQ": 3136
              },
              {
                "YR": 1344
              },
              {
                "OT": 3136
              },
              {
                "YR": 448
              },
              {
                "YR": 224
              },
              {
                "OT": 4032
              }
            ]
          },
          "paxpr": [
            {
              "bf": 42112,
              "tf": 54432,
              "tax": [
                {
                  "YQ": 3136
                },
                {
                  "YR": 1344
                },
                {
                  "OT": 3136
                },
                {
                  "YR": 448
                },
                {
                  "YR": 224
                },
                {
                  "OT": 4032
                }
              ]
            }
          ],
          "sty": "split",
          "sup": "0/0",
          "airlineList": "6E_AI",
          "mix_carrier": true
        },
        {
          "srcorigns": [
            {
              "tid": "v1kPzTRTv4IsnsuD_1",
              "ref": 1,
              "sdur": "08:20",
              "re": "Refundable Before Departure",
              "stop": 1,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "AI",
              "seg": [
                {
                  "ref": 1,
                  "da": "DOH",
                  "ar": "DEL",
                  "dd": "2023-06-29T22:35:00",
                  "ad": "2023-06-30T05:05:00",
                  "rbd": "L",
                  "fn": "972",
                  "eq": "32N",
                  "ac": "AI",
                  "ma": "AI",
                  "seat": "1",
                  "oa": "AI",
                  "fa": [],
                  "fdur": "04:00",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": "3"
                  }
                },
                {
                  "ref": 2,
                  "da": "DEL",
                  "ar": "HYD",
                  "dd": "2023-06-30T07:10:00",
                  "ad": "2023-06-30T09:25:00",
                  "rbd": "Q",
                  "fn": "560",
                  "trn": "3",
                  "eq": "32N",
                  "ac": "AI",
                  "ma": "AI",
                  "seat": "9",
                  "oa": "AI",
                  "fa": [],
                  "fdur": "02:15",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "3",
                    "arr": ""
                  }
                }
              ],
              "flightNumbers": "972/560",
              "bgd": [
                {
                  "qun": "30",
                  "wt": "kg"
                }
              ],
              "paxpr": [
                {
                  "tf": 17248,
                  "tax": [
                    {
                      "YR": 448
                    },
                    {
                      "YR": 224
                    },
                    {
                      "OT": 3136
                    }
                  ],
                  "bf": 13440,
                  "fbc": "LOWDHDL",
                  "fareType": "RP",
                  "pusup_fare": "600_770",
                  "psup_fare": "13440_17248",
                  "ptype": "ADT"
                }
              ],
              "pr": {
                "bf": 13440,
                "tf": 17248,
                "tax": [
                  {
                    "YR": 448
                  },
                  {
                    "YR": 224
                  },
                  {
                    "OT": 3136
                  }
                ],
                "scur": "QAR",
                "ucur": "INR",
                "exchange_rate": 22.4,
                "sup_fare": "600_770"
              }
            },
            {
              "tid": "QKlLpgcNOFScR7vm_1",
              "ref": 1,
              "sdur": "10:45",
              "re": "Refundable Before Departure",
              "stop": 1,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "AI",
              "seg": [
                {
                  "ref": 1,
                  "da": "DOH",
                  "ar": "DEL",
                  "dd": "2023-06-29T22:35:00",
                  "ad": "2023-06-30T05:05:00",
                  "rbd": "L",
                  "fn": "972",
                  "eq": "32N",
                  "ac": "AI",
                  "ma": "AI",
                  "seat": "1",
                  "oa": "AI",
                  "fa": [],
                  "fdur": "04:00",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": "3"
                  }
                },
                {
                  "ref": 2,
                  "da": "DEL",
                  "ar": "HYD",
                  "dd": "2023-06-30T09:45:00",
                  "ad": "2023-06-30T11:50:00",
                  "rbd": "Q",
                  "fn": "542",
                  "trn": "3",
                  "eq": "321",
                  "ac": "AI",
                  "ma": "AI",
                  "seat": "9",
                  "oa": "AI",
                  "fa": [],
                  "fdur": "02:05",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "3",
                    "arr": ""
                  }
                }
              ],
              "flightNumbers": "972/542",
              "bgd": [
                {
                  "qun": "30",
                  "wt": "kg"
                }
              ],
              "paxpr": [
                {
                  "tf": 17248,
                  "tax": [
                    {
                      "YR": 448
                    },
                    {
                      "YR": 224
                    },
                    {
                      "OT": 3136
                    }
                  ],
                  "bf": 13440,
                  "fbc": "LOWDHDL",
                  "fareType": "RP",
                  "pusup_fare": "600_770",
                  "psup_fare": "13440_17248",
                  "ptype": "ADT"
                }
              ],
              "pr": {
                "bf": 13440,
                "tf": 17248,
                "tax": [
                  {
                    "YR": 448
                  },
                  {
                    "YR": 224
                  },
                  {
                    "OT": 3136
                  }
                ],
                "scur": "QAR",
                "ucur": "INR",
                "exchange_rate": 22.4,
                "sup_fare": "600_770"
              }
            },
            {
              "tid": "1JQP8kNJKeRxapn8_1",
              "ref": 1,
              "sdur": "18:05",
              "re": "Refundable Before Departure",
              "stop": 1,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "AI",
              "seg": [
                {
                  "ref": 1,
                  "da": "DOH",
                  "ar": "DEL",
                  "dd": "2023-06-29T22:35:00",
                  "ad": "2023-06-30T05:05:00",
                  "rbd": "L",
                  "fn": "972",
                  "eq": "32N",
                  "ac": "AI",
                  "ma": "AI",
                  "seat": "1",
                  "oa": "AI",
                  "fa": [],
                  "fdur": "04:00",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": "3"
                  }
                },
                {
                  "ref": 2,
                  "da": "DEL",
                  "ar": "HYD",
                  "dd": "2023-06-30T16:50:00",
                  "ad": "2023-06-30T19:10:00",
                  "rbd": "Q",
                  "fn": "544",
                  "trn": "3",
                  "eq": "32N",
                  "ac": "AI",
                  "ma": "AI",
                  "seat": "9",
                  "oa": "AI",
                  "fa": [],
                  "fdur": "02:20",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "3",
                    "arr": ""
                  }
                }
              ],
              "flightNumbers": "972/544",
              "bgd": [
                {
                  "qun": "30",
                  "wt": "kg"
                }
              ],
              "paxpr": [
                {
                  "tf": 17248,
                  "tax": [
                    {
                      "YR": 448
                    },
                    {
                      "YR": 224
                    },
                    {
                      "OT": 3136
                    }
                  ],
                  "bf": 13440,
                  "fbc": "LOWDHDL",
                  "fareType": "RP",
                  "pusup_fare": "600_770",
                  "psup_fare": "13440_17248",
                  "ptype": "ADT"
                }
              ],
              "pr": {
                "bf": 13440,
                "tf": 17248,
                "tax": [
                  {
                    "YR": 448
                  },
                  {
                    "YR": 224
                  },
                  {
                    "OT": 3136
                  }
                ],
                "scur": "QAR",
                "ucur": "INR",
                "exchange_rate": 22.4,
                "sup_fare": "600_770"
              }
            },
            {
              "tid": "7NBv1RUInLrzLcP9_1",
              "ref": 1,
              "sdur": "22:40",
              "re": "Refundable Before Departure",
              "stop": 1,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "AI",
              "seg": [
                {
                  "ref": 1,
                  "da": "DOH",
                  "ar": "DEL",
                  "dd": "2023-06-29T22:35:00",
                  "ad": "2023-06-30T05:05:00",
                  "rbd": "L",
                  "fn": "972",
                  "eq": "32N",
                  "ac": "AI",
                  "ma": "AI",
                  "seat": "1",
                  "oa": "AI",
                  "fa": [],
                  "fdur": "04:00",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": "3"
                  }
                },
                {
                  "ref": 2,
                  "da": "DEL",
                  "ar": "HYD",
                  "dd": "2023-06-30T21:30:00",
                  "ad": "2023-06-30T23:45:00",
                  "rbd": "Q",
                  "fn": "839",
                  "trn": "3",
                  "eq": "321",
                  "ac": "AI",
                  "ma": "AI",
                  "seat": "9",
                  "oa": "AI",
                  "fa": [],
                  "fdur": "02:15",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "3",
                    "arr": ""
                  }
                }
              ],
              "flightNumbers": "972/839",
              "bgd": [
                {
                  "qun": "30",
                  "wt": "kg"
                }
              ],
              "paxpr": [
                {
                  "tf": 17248,
                  "tax": [
                    {
                      "YR": 448
                    },
                    {
                      "YR": 224
                    },
                    {
                      "OT": 3136
                    }
                  ],
                  "bf": 13440,
                  "fbc": "LOWDHDL",
                  "fareType": "RP",
                  "pusup_fare": "600_770",
                  "psup_fare": "13440_17248",
                  "ptype": "ADT"
                }
              ],
              "pr": {
                "bf": 13440,
                "tf": 17248,
                "tax": [
                  {
                    "YR": 448
                  },
                  {
                    "YR": 224
                  },
                  {
                    "OT": 3136
                  }
                ],
                "scur": "QAR",
                "ucur": "INR",
                "exchange_rate": 22.4,
                "sup_fare": "600_770"
              }
            }
          ],
          "destorigins": [
            {
              "tid": "o4dGVuQJgCyaGMOJ_1",
              "ref": 1,
              "sdur": "04:10",
              "re": "Refundable Before Departure",
              "stop": 0,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "QR",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DOH",
                  "dd": "2023-06-30T21:55:00",
                  "ad": "2023-06-30T23:35:00",
                  "rbd": "N",
                  "fn": "4779",
                  "eq": "320",
                  "ac": "QR",
                  "ma": "QR",
                  "seat": "9",
                  "oa": "6E",
                  "fa": [],
                  "fdur": "04:10",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": ""
                  }
                }
              ],
              "flightNumbers": "4779",
              "bgd": [
                {
                  "qun": "25",
                  "wt": "kg"
                }
              ],
              "paxpr": [
                {
                  "tf": 39200,
                  "tax": [
                    {
                      "YQ": 9856
                    },
                    {
                      "YR": 896
                    },
                    {
                      "OT": 5152
                    }
                  ],
                  "bf": 23296,
                  "fbc": "NLR4I1RQ",
                  "fareType": "RP",
                  "pusup_fare": "1040_1750",
                  "psup_fare": "23296_39200",
                  "ptype": "ADT"
                }
              ],
              "pr": {
                "bf": 23296,
                "tf": 39200,
                "tax": [
                  {
                    "YQ": 9856
                  },
                  {
                    "YR": 896
                  },
                  {
                    "OT": 5152
                  }
                ],
                "scur": "QAR",
                "ucur": "INR",
                "exchange_rate": 22.4,
                "sup_fare": "1040_1750"
              }
            },
            {
              "tid": "LithwaiTgVo0zTSn_1",
              "ref": 1,
              "sdur": "04:10",
              "re": "Refundable Before Departure",
              "stop": 0,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "QR",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DOH",
                  "dd": "2023-06-30T17:05:00",
                  "ad": "2023-06-30T18:45:00",
                  "rbd": "N",
                  "fn": "4777",
                  "eq": "320",
                  "ac": "QR",
                  "ma": "QR",
                  "seat": "9",
                  "oa": "6E",
                  "fa": [],
                  "fdur": "04:10",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": ""
                  }
                }
              ],
              "flightNumbers": "4777",
              "bgd": [
                {
                  "qun": "25",
                  "wt": "kg"
                }
              ],
              "paxpr": [
                {
                  "tf": 39200,
                  "tax": [
                    {
                      "YQ": 9856
                    },
                    {
                      "YR": 896
                    },
                    {
                      "OT": 5152
                    }
                  ],
                  "bf": 23296,
                  "fbc": "NLR4I1RQ",
                  "fareType": "RP",
                  "pusup_fare": "1040_1750",
                  "psup_fare": "23296_39200",
                  "ptype": "ADT"
                }
              ],
              "pr": {
                "bf": 23296,
                "tf": 39200,
                "tax": [
                  {
                    "YQ": 9856
                  },
                  {
                    "YR": 896
                  },
                  {
                    "OT": 5152
                  }
                ],
                "scur": "QAR",
                "ucur": "INR",
                "exchange_rate": 22.4,
                "sup_fare": "1040_1750"
              }
            },
            {
              "tid": "VtTRT4CTTnmiBqt3_1",
              "ref": 1,
              "sdur": "04:10",
              "re": "Refundable Before Departure",
              "stop": 0,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "QR",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DOH",
                  "dd": "2023-06-30T04:10:00",
                  "ad": "2023-06-30T05:50:00",
                  "rbd": "N",
                  "fn": "4775",
                  "eq": "320",
                  "ac": "QR",
                  "ma": "QR",
                  "seat": "9",
                  "oa": "6E",
                  "fa": [],
                  "fdur": "04:10",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": ""
                  }
                }
              ],
              "flightNumbers": "4775",
              "bgd": [
                {
                  "qun": "25",
                  "wt": "kg"
                }
              ],
              "paxpr": [
                {
                  "tf": 39200,
                  "tax": [
                    {
                      "YQ": 9856
                    },
                    {
                      "YR": 896
                    },
                    {
                      "OT": 5152
                    }
                  ],
                  "bf": 23296,
                  "fbc": "NLR4I1RQ",
                  "fareType": "RP",
                  "pusup_fare": "1040_1750",
                  "psup_fare": "23296_39200",
                  "ptype": "ADT"
                }
              ],
              "pr": {
                "bf": 23296,
                "tf": 39200,
                "tax": [
                  {
                    "YQ": 9856
                  },
                  {
                    "YR": 896
                  },
                  {
                    "OT": 5152
                  }
                ],
                "scur": "QAR",
                "ucur": "INR",
                "exchange_rate": 22.4,
                "sup_fare": "1040_1750"
              }
            }
          ],
          "pr": {
            "bf": 36736,
            "tf": 56448,
            "tax": [
              {
                "YR": 448
              },
              {
                "YR": 224
              },
              {
                "OT": 3136
              },
              {
                "YQ": 9856
              },
              {
                "YR": 896
              },
              {
                "OT": 5152
              }
            ]
          },
          "paxpr": [
            {
              "bf": 36736,
              "tf": 56448,
              "tax": [
                {
                  "YR": 448
                },
                {
                  "YR": 224
                },
                {
                  "OT": 3136
                },
                {
                  "YQ": 9856
                },
                {
                  "YR": 896
                },
                {
                  "OT": 5152
                }
              ]
            }
          ],
          "sty": "split",
          "sup": "0/0",
          "airlineList": "AI_6E",
          "mix_carrier": true
        },
        {
          "srcorigns": [
            {
              "tid": "UC8pMN2hvMQGa489_1",
              "ref": 1,
              "ofcid": "",
              "sdur": "9:45",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "DOH",
                  "ar": "HYD",
                  "dd": "2023-06-29T02:00:00",
                  "ad": "2023-06-29T08:45:00",
                  "rbd": "",
                  "fn": "1318",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {},
                  "rph": "",
                  "jsell": "6E~1318~ ~~DOH~06/29/2023 02:00~HYD~06/29/2023 08:45~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~A~ ~6E~A0INT~5000~~0~1~~X",
                  "pr": {
                    "bf": 28373,
                    "tf": 32479,
                    "tax": [
                      {
                        "OT": 4106
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "28373_32479",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "paxpr": [
                    {
                      "bf": 28373,
                      "tf": 32479,
                      "tax": [
                        {
                          "OT": 4106
                        }
                      ],
                      "fbc": "A0INT",
                      "ptype": "ADT",
                      "scur": "INR",
                      "sup_fare": "28373_32479"
                    }
                  ],
                  "routeId": "lh3rsvezlv",
                  "productClass": "R",
                  "ruleNumber": "5000"
                },
                {
                  "fareKey": "0~A~ ~6E~AUINT~5003~~0~7~~X",
                  "pr": {
                    "bf": 29073,
                    "tf": 33204,
                    "tax": [
                      {
                        "OT": 4131
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "29073_33204",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "paxpr": [
                    {
                      "bf": 29073,
                      "tf": 33204,
                      "tax": [
                        {
                          "OT": 4131
                        }
                      ],
                      "fbc": "AUINT",
                      "ptype": "ADT",
                      "scur": "INR",
                      "sup_fare": "29073_33204"
                    }
                  ],
                  "routeId": "56adr4fn8o",
                  "productClass": "J",
                  "ruleNumber": "5003"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "Qjaaeqrl1XE=|aQFezKSWQkTEFuzy1c/XiE8E1gCe1mp7wL3TnlV5DA+x56hxCT+7ZWP6pBbfk21i1VpV7qcOGfUZINz5cX0sMPO7zDGGtcxXeJ5RNOP3ZplH1FG3CBzrsOYD796VyuQ29DfNwKuxQhE=",
              "stop": 0,
              "paxpr": [
                {
                  "bf": 28373,
                  "tf": 32479,
                  "fbc": "",
                  "psup_fare": "",
                  "tax": [
                    [
                      {
                        "OT": 4106
                      }
                    ]
                  ],
                  "ptype": "ADT",
                  "scur": "INR",
                  "sup_cur_fare": ""
                }
              ],
              "pr": {
                "bf": 28373,
                "tf": 32479,
                "tax": [
                  {
                    "OT": 4106
                  }
                ],
                "scur": "INR",
                "sup_fare": "",
                "sup_cur_fare": "",
                "cabb": "",
                "chekb": "",
                "seats": "",
                "meals": "",
                "chanb": "",
                "ftyp": ""
              }
            },
            {
              "tid": "9szQBnvh8kM9vfqy_1",
              "ref": 1,
              "ofcid": "",
              "sdur": "9:45",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "DOH",
                  "ar": "HYD",
                  "dd": "2023-06-29T08:15:00",
                  "ad": "2023-06-29T15:00:00",
                  "rbd": "",
                  "fn": "1314",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {},
                  "rph": "",
                  "jsell": "6E~1314~ ~~DOH~06/29/2023 08:15~HYD~06/29/2023 15:00~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~A~ ~6E~A0INT~5000~~0~1~~X",
                  "pr": {
                    "bf": 28373,
                    "tf": 32479,
                    "tax": [
                      {
                        "OT": 4106
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "28373_32479",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "paxpr": [
                    {
                      "bf": 28373,
                      "tf": 32479,
                      "tax": [
                        {
                          "OT": 4106
                        }
                      ],
                      "fbc": "A0INT",
                      "ptype": "ADT",
                      "scur": "INR",
                      "sup_fare": "28373_32479"
                    }
                  ],
                  "routeId": "ivcua3jvq",
                  "productClass": "R",
                  "ruleNumber": "5000"
                },
                {
                  "fareKey": "0~A~ ~6E~AUINT~5003~~0~7~~X",
                  "pr": {
                    "bf": 29073,
                    "tf": 33204,
                    "tax": [
                      {
                        "OT": 4131
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "29073_33204",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "paxpr": [
                    {
                      "bf": 29073,
                      "tf": 33204,
                      "tax": [
                        {
                          "OT": 4131
                        }
                      ],
                      "fbc": "AUINT",
                      "ptype": "ADT",
                      "scur": "INR",
                      "sup_fare": "29073_33204"
                    }
                  ],
                  "routeId": "romovrv2la",
                  "productClass": "J",
                  "ruleNumber": "5003"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "Qjaaeqrl1XE=|aQFezKSWQkTEFuzy1c/XiE8E1gCe1mp7wL3TnlV5DA+x56hxCT+7ZWP6pBbfk21i1VpV7qcOGfUZINz5cX0sMPO7zDGGtcxXeJ5RNOP3ZplH1FG3CBzrsOYD796VyuQ29DfNwKuxQhE=",
              "stop": 0,
              "paxpr": [
                {
                  "bf": 28373,
                  "tf": 32479,
                  "fbc": "",
                  "psup_fare": "",
                  "tax": [
                    [
                      {
                        "OT": 4106
                      }
                    ]
                  ],
                  "ptype": "ADT",
                  "scur": "INR",
                  "sup_cur_fare": ""
                }
              ],
              "pr": {
                "bf": 28373,
                "tf": 32479,
                "tax": [
                  {
                    "OT": 4106
                  }
                ],
                "scur": "INR",
                "sup_fare": "",
                "sup_cur_fare": "",
                "cabb": "",
                "chekb": "",
                "seats": "",
                "meals": "",
                "chanb": "",
                "ftyp": ""
              }
            },
            {
              "tid": "CHBJNQO3ROTFP5Tq_1",
              "ref": 1,
              "ofcid": "",
              "sdur": "9:45",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "DOH",
                  "ar": "HYD",
                  "dd": "2023-06-29T19:45:00",
                  "ad": "2023-06-30T02:30:00",
                  "rbd": "",
                  "fn": "1316",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {},
                  "rph": "",
                  "jsell": "6E~1316~ ~~DOH~06/29/2023 19:45~HYD~06/30/2023 02:30~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~A~ ~6E~A0INT~5000~~0~1~~X",
                  "pr": {
                    "bf": 28373,
                    "tf": 32479,
                    "tax": [
                      {
                        "OT": 4106
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "28373_32479",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "paxpr": [
                    {
                      "bf": 28373,
                      "tf": 32479,
                      "tax": [
                        {
                          "OT": 4106
                        }
                      ],
                      "fbc": "A0INT",
                      "ptype": "ADT",
                      "scur": "INR",
                      "sup_fare": "28373_32479"
                    }
                  ],
                  "routeId": "7uvfkpd73g",
                  "productClass": "R",
                  "ruleNumber": "5000"
                },
                {
                  "fareKey": "0~A~ ~6E~AUINT~5003~~0~7~~X",
                  "pr": {
                    "bf": 29073,
                    "tf": 33204,
                    "tax": [
                      {
                        "OT": 4131
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "29073_33204",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "paxpr": [
                    {
                      "bf": 29073,
                      "tf": 33204,
                      "tax": [
                        {
                          "OT": 4131
                        }
                      ],
                      "fbc": "AUINT",
                      "ptype": "ADT",
                      "scur": "INR",
                      "sup_fare": "29073_33204"
                    }
                  ],
                  "routeId": "g0dit1py42i",
                  "productClass": "J",
                  "ruleNumber": "5003"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "Qjaaeqrl1XE=|aQFezKSWQkTEFuzy1c/XiE8E1gCe1mp7wL3TnlV5DA+x56hxCT+7ZWP6pBbfk21i1VpV7qcOGfUZINz5cX0sMPO7zDGGtcxXeJ5RNOP3ZplH1FG3CBzrsOYD796VyuQ29DfNwKuxQhE=",
              "stop": 0,
              "paxpr": [
                {
                  "bf": 28373,
                  "tf": 32479,
                  "fbc": "",
                  "psup_fare": "",
                  "tax": [
                    [
                      {
                        "OT": 4106
                      }
                    ]
                  ],
                  "ptype": "ADT",
                  "scur": "INR",
                  "sup_cur_fare": ""
                }
              ],
              "pr": {
                "bf": 28373,
                "tf": 32479,
                "tax": [
                  {
                    "OT": 4106
                  }
                ],
                "scur": "INR",
                "sup_fare": "",
                "sup_cur_fare": "",
                "cabb": "",
                "chekb": "",
                "seats": "",
                "meals": "",
                "chanb": "",
                "ftyp": ""
              }
            }
          ],
          "destorigins": [
            {
              "tid": "MUS2TLIH8VyQcv3C_1",
              "ref": 1,
              "ofcid": "",
              "sdur": "4:40",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DOH",
                  "dd": "2023-06-30T04:10:00",
                  "ad": "2023-06-30T05:50:00",
                  "rbd": "",
                  "fn": "1313",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {},
                  "rph": "",
                  "jsell": "6E~1313~ ~~HYD~06/30/2023 04:10~DOH~06/30/2023 05:50~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~A~ ~6E~A0INT~5000~~0~2~~X",
                  "pr": {
                    "bf": 26311,
                    "tf": 31534,
                    "tax": [
                      {
                        "OT": 5223
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "26311_31534",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "paxpr": [
                    {
                      "bf": 26311,
                      "tf": 31534,
                      "tax": [
                        {
                          "OT": 5223
                        }
                      ],
                      "fbc": "A0INT",
                      "ptype": "ADT",
                      "scur": "INR",
                      "sup_fare": "26311_31534"
                    }
                  ],
                  "routeId": "12r3muxt5lj",
                  "productClass": "R",
                  "ruleNumber": "5000"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "Q2c3c3fETtY=|A6KkIU40nZ4asiRAAWjEJn2YrIKBv2dQgqCTOYx11mnNyuaFYnXhwI5GuIilBz4V/oaFsdOsaNpO2v1OoLoyDKxVlrz4az4ufHZUNCPPoEmLz/Jk3DD2LYWM4DcZOuhPrHhGYGKemHY=",
              "stop": 0,
              "paxpr": [
                {
                  "bf": 26311,
                  "tf": 31534,
                  "fbc": "",
                  "psup_fare": "",
                  "tax": [
                    [
                      {
                        "OT": 5223
                      }
                    ]
                  ],
                  "ptype": "ADT",
                  "scur": "INR",
                  "sup_cur_fare": ""
                }
              ],
              "pr": {
                "bf": 26311,
                "tf": 31534,
                "tax": [
                  {
                    "OT": 5223
                  }
                ],
                "scur": "INR",
                "sup_fare": "",
                "sup_cur_fare": "",
                "cabb": "",
                "chekb": "",
                "seats": "",
                "meals": "",
                "chanb": "",
                "ftyp": ""
              }
            },
            {
              "tid": "M9ZZHyNdeQxDFR68_1",
              "ref": 1,
              "ofcid": "",
              "sdur": "4:40",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DOH",
                  "dd": "2023-06-30T17:05:00",
                  "ad": "2023-06-30T18:45:00",
                  "rbd": "",
                  "fn": "1315",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {},
                  "rph": "",
                  "jsell": "6E~1315~ ~~HYD~06/30/2023 17:05~DOH~06/30/2023 18:45~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~A~ ~6E~A0INT~5000~~0~2~~X",
                  "pr": {
                    "bf": 26311,
                    "tf": 31534,
                    "tax": [
                      {
                        "OT": 5223
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "26311_31534",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "paxpr": [
                    {
                      "bf": 26311,
                      "tf": 31534,
                      "tax": [
                        {
                          "OT": 5223
                        }
                      ],
                      "fbc": "A0INT",
                      "ptype": "ADT",
                      "scur": "INR",
                      "sup_fare": "26311_31534"
                    }
                  ],
                  "routeId": "by2fv3b5vl",
                  "productClass": "R",
                  "ruleNumber": "5000"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "Q2c3c3fETtY=|A6KkIU40nZ4asiRAAWjEJn2YrIKBv2dQgqCTOYx11mnNyuaFYnXhwI5GuIilBz4V/oaFsdOsaNpO2v1OoLoyDKxVlrz4az4ufHZUNCPPoEmLz/Jk3DD2LYWM4DcZOuhPrHhGYGKemHY=",
              "stop": 0,
              "paxpr": [
                {
                  "bf": 26311,
                  "tf": 31534,
                  "fbc": "",
                  "psup_fare": "",
                  "tax": [
                    [
                      {
                        "OT": 5223
                      }
                    ]
                  ],
                  "ptype": "ADT",
                  "scur": "INR",
                  "sup_cur_fare": ""
                }
              ],
              "pr": {
                "bf": 26311,
                "tf": 31534,
                "tax": [
                  {
                    "OT": 5223
                  }
                ],
                "scur": "INR",
                "sup_fare": "",
                "sup_cur_fare": "",
                "cabb": "",
                "chekb": "",
                "seats": "",
                "meals": "",
                "chanb": "",
                "ftyp": ""
              }
            },
            {
              "tid": "V8CRhFZrBR0MrXE1_1",
              "ref": 1,
              "ofcid": "",
              "sdur": "4:40",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DOH",
                  "dd": "2023-06-30T21:55:00",
                  "ad": "2023-06-30T23:35:00",
                  "rbd": "",
                  "fn": "1317",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {},
                  "rph": "",
                  "jsell": "6E~1317~ ~~HYD~06/30/2023 21:55~DOH~06/30/2023 23:35~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~A~ ~6E~A0INT~5000~~0~2~~X",
                  "pr": {
                    "bf": 26311,
                    "tf": 31534,
                    "tax": [
                      {
                        "OT": 5223
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "26311_31534",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "paxpr": [
                    {
                      "bf": 26311,
                      "tf": 31534,
                      "tax": [
                        {
                          "OT": 5223
                        }
                      ],
                      "fbc": "A0INT",
                      "ptype": "ADT",
                      "scur": "INR",
                      "sup_fare": "26311_31534"
                    }
                  ],
                  "routeId": "59fmlda90s",
                  "productClass": "R",
                  "ruleNumber": "5000"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "Q2c3c3fETtY=|A6KkIU40nZ4asiRAAWjEJn2YrIKBv2dQgqCTOYx11mnNyuaFYnXhwI5GuIilBz4V/oaFsdOsaNpO2v1OoLoyDKxVlrz4az4ufHZUNCPPoEmLz/Jk3DD2LYWM4DcZOuhPrHhGYGKemHY=",
              "stop": 0,
              "paxpr": [
                {
                  "bf": 26311,
                  "tf": 31534,
                  "fbc": "",
                  "psup_fare": "",
                  "tax": [
                    [
                      {
                        "OT": 5223
                      }
                    ]
                  ],
                  "ptype": "ADT",
                  "scur": "INR",
                  "sup_cur_fare": ""
                }
              ],
              "pr": {
                "bf": 26311,
                "tf": 31534,
                "tax": [
                  {
                    "OT": 5223
                  }
                ],
                "scur": "INR",
                "sup_fare": "",
                "sup_cur_fare": "",
                "cabb": "",
                "chekb": "",
                "seats": "",
                "meals": "",
                "chanb": "",
                "ftyp": ""
              }
            }
          ],
          "pr": {
            "bf": 54684,
            "tf": 64013,
            "tax": [
              {
                "OT": 4106
              },
              {
                "OT": 5223
              }
            ]
          },
          "paxpr": [
            {
              "bf": 54684,
              "tf": 64013,
              "tax": [
                [
                  {
                    "OT": 4106
                  }
                ],
                [
                  {
                    "OT": 5223
                  }
                ]
              ]
            }
          ],
          "sty": "split",
          "sup": "4/4",
          "airlineList": "6E_6E",
          "mix_carrier": true
        },
        {
          "srcorigns": [
            {
              "tid": "IhMrTnLaWeMUB4Dh_1",
              "ref": 1,
              "ofcid": "",
              "sdur": "13:35",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "DOH",
                  "ar": "BOM",
                  "dd": "2023-06-29T08:15:00",
                  "ad": "2023-06-29T14:20:00",
                  "rbd": "",
                  "fn": "1304",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "2"
                  },
                  "rph": "",
                  "jsell": "6E~1304~ ~~DOH~06/29/2023 08:15~BOM~06/29/2023 14:20~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                },
                {
                  "ref": 2,
                  "da": "BOM",
                  "ar": "HYD",
                  "dd": "2023-06-29T17:20:00",
                  "ad": "2023-06-29T18:50:00",
                  "rbd": "",
                  "fn": "6571",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "2"
                  },
                  "rph": "",
                  "jsell": "6E~6571~ ~~BOM~06/29/2023 17:20~HYD~06/29/2023 18:50~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "1~W~ ~6E~W0INT~5000~~0~7~~^2~R~ ~6E~RCIP~1070~~0~8~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 28729,
                      "fbc": "W0INT/RCIP",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "28729_32898",
                      "tax": [
                        {
                          "OT": 4169
                        }
                      ],
                      "tf": 32898
                    }
                  ],
                  "pr": {
                    "bf": 28729,
                    "tf": 32898,
                    "tax": [
                      {
                        "OT": 4169
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "28729_32898",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "c0i0ja0ypni",
                  "ruleNumber": "5000",
                  "productClass": "R"
                },
                {
                  "fareKey": "1~W~ ~6E~WUINT~5003~~0~12~~^2~R~ ~6E~RUIP~2009~~0~4~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 30029,
                      "fbc": "WUINT/RUIP",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "30029_34243",
                      "tax": [
                        {
                          "OT": 4214
                        }
                      ],
                      "tf": 34243
                    }
                  ],
                  "pr": {
                    "bf": 30029,
                    "tf": 34243,
                    "tax": [
                      {
                        "OT": 4214
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "30029_34243",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "0qfapdtxv6",
                  "ruleNumber": "5003",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "Qjaaeqrl1XE=|aQFezKSWQkTEFuzy1c/XiE8E1gCe1mp7wL3TnlV5DA+x56hxCT+7ZWP6pBbfk21i1VpV7qcOGfUZINz5cX0sMPO7zDGGtcxXeJ5RNOP3ZplH1FG3CBzrsOYD796VyuQ29DfNwKuxQhE=",
              "stop": 1,
              "paxpr": [
                {
                  "bf": 28729,
                  "tf": 32898,
                  "fbc": "",
                  "psup_fare": "",
                  "tax": [
                    [
                      {
                        "OT": 4169
                      }
                    ]
                  ],
                  "ptype": "ADT",
                  "scur": "INR",
                  "sup_cur_fare": ""
                }
              ],
              "pr": {
                "bf": 28729,
                "tf": 32898,
                "tax": [
                  {
                    "OT": 4169
                  }
                ],
                "scur": "INR",
                "sup_fare": "",
                "sup_cur_fare": "",
                "cabb": "",
                "chekb": "",
                "seats": "",
                "meals": "",
                "chanb": "",
                "ftyp": ""
              }
            }
          ],
          "destorigins": [
            {
              "tid": "MUS2TLIH8VyQcv3C_1",
              "ref": 1,
              "ofcid": "",
              "sdur": "4:40",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DOH",
                  "dd": "2023-06-30T04:10:00",
                  "ad": "2023-06-30T05:50:00",
                  "rbd": "",
                  "fn": "1313",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {},
                  "rph": "",
                  "jsell": "6E~1313~ ~~HYD~06/30/2023 04:10~DOH~06/30/2023 05:50~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~A~ ~6E~A0INT~5000~~0~2~~X",
                  "pr": {
                    "bf": 26311,
                    "tf": 31534,
                    "tax": [
                      {
                        "OT": 5223
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "26311_31534",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "paxpr": [
                    {
                      "bf": 26311,
                      "tf": 31534,
                      "tax": [
                        {
                          "OT": 5223
                        }
                      ],
                      "fbc": "A0INT",
                      "ptype": "ADT",
                      "scur": "INR",
                      "sup_fare": "26311_31534"
                    }
                  ],
                  "routeId": "12r3muxt5lj",
                  "productClass": "R",
                  "ruleNumber": "5000"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "Q2c3c3fETtY=|A6KkIU40nZ4asiRAAWjEJn2YrIKBv2dQgqCTOYx11mnNyuaFYnXhwI5GuIilBz4V/oaFsdOsaNpO2v1OoLoyDKxVlrz4az4ufHZUNCPPoEmLz/Jk3DD2LYWM4DcZOuhPrHhGYGKemHY=",
              "stop": 0,
              "paxpr": [
                {
                  "bf": 26311,
                  "tf": 31534,
                  "fbc": "",
                  "psup_fare": "",
                  "tax": [
                    [
                      {
                        "OT": 5223
                      }
                    ]
                  ],
                  "ptype": "ADT",
                  "scur": "INR",
                  "sup_cur_fare": ""
                }
              ],
              "pr": {
                "bf": 26311,
                "tf": 31534,
                "tax": [
                  {
                    "OT": 5223
                  }
                ],
                "scur": "INR",
                "sup_fare": "",
                "sup_cur_fare": "",
                "cabb": "",
                "chekb": "",
                "seats": "",
                "meals": "",
                "chanb": "",
                "ftyp": ""
              }
            },
            {
              "tid": "M9ZZHyNdeQxDFR68_1",
              "ref": 1,
              "ofcid": "",
              "sdur": "4:40",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DOH",
                  "dd": "2023-06-30T17:05:00",
                  "ad": "2023-06-30T18:45:00",
                  "rbd": "",
                  "fn": "1315",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {},
                  "rph": "",
                  "jsell": "6E~1315~ ~~HYD~06/30/2023 17:05~DOH~06/30/2023 18:45~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~A~ ~6E~A0INT~5000~~0~2~~X",
                  "pr": {
                    "bf": 26311,
                    "tf": 31534,
                    "tax": [
                      {
                        "OT": 5223
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "26311_31534",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "paxpr": [
                    {
                      "bf": 26311,
                      "tf": 31534,
                      "tax": [
                        {
                          "OT": 5223
                        }
                      ],
                      "fbc": "A0INT",
                      "ptype": "ADT",
                      "scur": "INR",
                      "sup_fare": "26311_31534"
                    }
                  ],
                  "routeId": "by2fv3b5vl",
                  "productClass": "R",
                  "ruleNumber": "5000"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "Q2c3c3fETtY=|A6KkIU40nZ4asiRAAWjEJn2YrIKBv2dQgqCTOYx11mnNyuaFYnXhwI5GuIilBz4V/oaFsdOsaNpO2v1OoLoyDKxVlrz4az4ufHZUNCPPoEmLz/Jk3DD2LYWM4DcZOuhPrHhGYGKemHY=",
              "stop": 0,
              "paxpr": [
                {
                  "bf": 26311,
                  "tf": 31534,
                  "fbc": "",
                  "psup_fare": "",
                  "tax": [
                    [
                      {
                        "OT": 5223
                      }
                    ]
                  ],
                  "ptype": "ADT",
                  "scur": "INR",
                  "sup_cur_fare": ""
                }
              ],
              "pr": {
                "bf": 26311,
                "tf": 31534,
                "tax": [
                  {
                    "OT": 5223
                  }
                ],
                "scur": "INR",
                "sup_fare": "",
                "sup_cur_fare": "",
                "cabb": "",
                "chekb": "",
                "seats": "",
                "meals": "",
                "chanb": "",
                "ftyp": ""
              }
            },
            {
              "tid": "V8CRhFZrBR0MrXE1_1",
              "ref": 1,
              "ofcid": "",
              "sdur": "4:40",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DOH",
                  "dd": "2023-06-30T21:55:00",
                  "ad": "2023-06-30T23:35:00",
                  "rbd": "",
                  "fn": "1317",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {},
                  "rph": "",
                  "jsell": "6E~1317~ ~~HYD~06/30/2023 21:55~DOH~06/30/2023 23:35~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~A~ ~6E~A0INT~5000~~0~2~~X",
                  "pr": {
                    "bf": 26311,
                    "tf": 31534,
                    "tax": [
                      {
                        "OT": 5223
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "26311_31534",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "paxpr": [
                    {
                      "bf": 26311,
                      "tf": 31534,
                      "tax": [
                        {
                          "OT": 5223
                        }
                      ],
                      "fbc": "A0INT",
                      "ptype": "ADT",
                      "scur": "INR",
                      "sup_fare": "26311_31534"
                    }
                  ],
                  "routeId": "59fmlda90s",
                  "productClass": "R",
                  "ruleNumber": "5000"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "Q2c3c3fETtY=|A6KkIU40nZ4asiRAAWjEJn2YrIKBv2dQgqCTOYx11mnNyuaFYnXhwI5GuIilBz4V/oaFsdOsaNpO2v1OoLoyDKxVlrz4az4ufHZUNCPPoEmLz/Jk3DD2LYWM4DcZOuhPrHhGYGKemHY=",
              "stop": 0,
              "paxpr": [
                {
                  "bf": 26311,
                  "tf": 31534,
                  "fbc": "",
                  "psup_fare": "",
                  "tax": [
                    [
                      {
                        "OT": 5223
                      }
                    ]
                  ],
                  "ptype": "ADT",
                  "scur": "INR",
                  "sup_cur_fare": ""
                }
              ],
              "pr": {
                "bf": 26311,
                "tf": 31534,
                "tax": [
                  {
                    "OT": 5223
                  }
                ],
                "scur": "INR",
                "sup_fare": "",
                "sup_cur_fare": "",
                "cabb": "",
                "chekb": "",
                "seats": "",
                "meals": "",
                "chanb": "",
                "ftyp": ""
              }
            }
          ],
          "pr": {
            "bf": 55040,
            "tf": 64432,
            "tax": [
              {
                "OT": 4169
              },
              {
                "OT": 5223
              }
            ]
          },
          "paxpr": [
            {
              "bf": 55040,
              "tf": 64432,
              "tax": [
                [
                  {
                    "OT": 4169
                  }
                ],
                [
                  {
                    "OT": 5223
                  }
                ]
              ]
            }
          ],
          "sty": "split",
          "sup": "4/4",
          "airlineList": "6E_6E",
          "mix_carrier": true
        },
        {
          "srcorigns": [
            {
              "tid": "4B7yO6HNNcmNlngc_1",
              "ref": 1,
              "ofcid": "",
              "sdur": "14:10",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "DOH",
                  "ar": "BOM",
                  "dd": "2023-06-29T19:45:00",
                  "ad": "2023-06-30T01:55:00",
                  "rbd": "",
                  "fn": "1306",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "2"
                  },
                  "rph": "",
                  "jsell": "6E~1306~ ~~DOH~06/29/2023 19:45~BOM~06/30/2023 01:55~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                },
                {
                  "ref": 2,
                  "da": "BOM",
                  "ar": "HYD",
                  "dd": "2023-06-30T05:20:00",
                  "ad": "2023-06-30T06:55:00",
                  "rbd": "",
                  "fn": " 461",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "2"
                  },
                  "rph": "",
                  "jsell": "6E~ 461~ ~~BOM~06/30/2023 05:20~HYD~06/30/2023 06:55~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "1~W~ ~6E~W0INT~5000~~0~7~~^2~L~ ~6E~LCIP~1070~~0~12~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 29825,
                      "fbc": "W0INT/LCIP",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "29825_34032",
                      "tax": [
                        {
                          "OT": 4207
                        }
                      ],
                      "tf": 34032
                    }
                  ],
                  "pr": {
                    "bf": 29825,
                    "tf": 34032,
                    "tax": [
                      {
                        "OT": 4207
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "29825_34032",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "mjfsn2pkpp",
                  "ruleNumber": "5000",
                  "productClass": "R"
                },
                {
                  "fareKey": "1~W~ ~6E~WUINT~5003~~0~12~~^2~L~ ~6E~LUIP~2009~~0~5~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 31125,
                      "fbc": "WUINT/LUIP",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "31125_35377",
                      "tax": [
                        {
                          "OT": 4252
                        }
                      ],
                      "tf": 35377
                    }
                  ],
                  "pr": {
                    "bf": 31125,
                    "tf": 35377,
                    "tax": [
                      {
                        "OT": 4252
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "31125_35377",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "09r1p3tt7ij",
                  "ruleNumber": "5003",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "Qjaaeqrl1XE=|aQFezKSWQkTEFuzy1c/XiE8E1gCe1mp7wL3TnlV5DA+x56hxCT+7ZWP6pBbfk21i1VpV7qcOGfUZINz5cX0sMPO7zDGGtcxXeJ5RNOP3ZplH1FG3CBzrsOYD796VyuQ29DfNwKuxQhE=",
              "stop": 1,
              "paxpr": [
                {
                  "bf": 29825,
                  "tf": 34032,
                  "fbc": "",
                  "psup_fare": "",
                  "tax": [
                    [
                      {
                        "OT": 4207
                      }
                    ]
                  ],
                  "ptype": "ADT",
                  "scur": "INR",
                  "sup_cur_fare": ""
                }
              ],
              "pr": {
                "bf": 29825,
                "tf": 34032,
                "tax": [
                  {
                    "OT": 4207
                  }
                ],
                "scur": "INR",
                "sup_fare": "",
                "sup_cur_fare": "",
                "cabb": "",
                "chekb": "",
                "seats": "",
                "meals": "",
                "chanb": "",
                "ftyp": ""
              }
            }
          ],
          "destorigins": [
            {
              "tid": "MUS2TLIH8VyQcv3C_1",
              "ref": 1,
              "ofcid": "",
              "sdur": "4:40",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DOH",
                  "dd": "2023-06-30T04:10:00",
                  "ad": "2023-06-30T05:50:00",
                  "rbd": "",
                  "fn": "1313",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {},
                  "rph": "",
                  "jsell": "6E~1313~ ~~HYD~06/30/2023 04:10~DOH~06/30/2023 05:50~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~A~ ~6E~A0INT~5000~~0~2~~X",
                  "pr": {
                    "bf": 26311,
                    "tf": 31534,
                    "tax": [
                      {
                        "OT": 5223
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "26311_31534",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "paxpr": [
                    {
                      "bf": 26311,
                      "tf": 31534,
                      "tax": [
                        {
                          "OT": 5223
                        }
                      ],
                      "fbc": "A0INT",
                      "ptype": "ADT",
                      "scur": "INR",
                      "sup_fare": "26311_31534"
                    }
                  ],
                  "routeId": "12r3muxt5lj",
                  "productClass": "R",
                  "ruleNumber": "5000"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "Q2c3c3fETtY=|A6KkIU40nZ4asiRAAWjEJn2YrIKBv2dQgqCTOYx11mnNyuaFYnXhwI5GuIilBz4V/oaFsdOsaNpO2v1OoLoyDKxVlrz4az4ufHZUNCPPoEmLz/Jk3DD2LYWM4DcZOuhPrHhGYGKemHY=",
              "stop": 0,
              "paxpr": [
                {
                  "bf": 26311,
                  "tf": 31534,
                  "fbc": "",
                  "psup_fare": "",
                  "tax": [
                    [
                      {
                        "OT": 5223
                      }
                    ]
                  ],
                  "ptype": "ADT",
                  "scur": "INR",
                  "sup_cur_fare": ""
                }
              ],
              "pr": {
                "bf": 26311,
                "tf": 31534,
                "tax": [
                  {
                    "OT": 5223
                  }
                ],
                "scur": "INR",
                "sup_fare": "",
                "sup_cur_fare": "",
                "cabb": "",
                "chekb": "",
                "seats": "",
                "meals": "",
                "chanb": "",
                "ftyp": ""
              }
            },
            {
              "tid": "M9ZZHyNdeQxDFR68_1",
              "ref": 1,
              "ofcid": "",
              "sdur": "4:40",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DOH",
                  "dd": "2023-06-30T17:05:00",
                  "ad": "2023-06-30T18:45:00",
                  "rbd": "",
                  "fn": "1315",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {},
                  "rph": "",
                  "jsell": "6E~1315~ ~~HYD~06/30/2023 17:05~DOH~06/30/2023 18:45~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~A~ ~6E~A0INT~5000~~0~2~~X",
                  "pr": {
                    "bf": 26311,
                    "tf": 31534,
                    "tax": [
                      {
                        "OT": 5223
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "26311_31534",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "paxpr": [
                    {
                      "bf": 26311,
                      "tf": 31534,
                      "tax": [
                        {
                          "OT": 5223
                        }
                      ],
                      "fbc": "A0INT",
                      "ptype": "ADT",
                      "scur": "INR",
                      "sup_fare": "26311_31534"
                    }
                  ],
                  "routeId": "by2fv3b5vl",
                  "productClass": "R",
                  "ruleNumber": "5000"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "Q2c3c3fETtY=|A6KkIU40nZ4asiRAAWjEJn2YrIKBv2dQgqCTOYx11mnNyuaFYnXhwI5GuIilBz4V/oaFsdOsaNpO2v1OoLoyDKxVlrz4az4ufHZUNCPPoEmLz/Jk3DD2LYWM4DcZOuhPrHhGYGKemHY=",
              "stop": 0,
              "paxpr": [
                {
                  "bf": 26311,
                  "tf": 31534,
                  "fbc": "",
                  "psup_fare": "",
                  "tax": [
                    [
                      {
                        "OT": 5223
                      }
                    ]
                  ],
                  "ptype": "ADT",
                  "scur": "INR",
                  "sup_cur_fare": ""
                }
              ],
              "pr": {
                "bf": 26311,
                "tf": 31534,
                "tax": [
                  {
                    "OT": 5223
                  }
                ],
                "scur": "INR",
                "sup_fare": "",
                "sup_cur_fare": "",
                "cabb": "",
                "chekb": "",
                "seats": "",
                "meals": "",
                "chanb": "",
                "ftyp": ""
              }
            },
            {
              "tid": "V8CRhFZrBR0MrXE1_1",
              "ref": 1,
              "ofcid": "",
              "sdur": "4:40",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DOH",
                  "dd": "2023-06-30T21:55:00",
                  "ad": "2023-06-30T23:35:00",
                  "rbd": "",
                  "fn": "1317",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {},
                  "rph": "",
                  "jsell": "6E~1317~ ~~HYD~06/30/2023 21:55~DOH~06/30/2023 23:35~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~A~ ~6E~A0INT~5000~~0~2~~X",
                  "pr": {
                    "bf": 26311,
                    "tf": 31534,
                    "tax": [
                      {
                        "OT": 5223
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "26311_31534",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "paxpr": [
                    {
                      "bf": 26311,
                      "tf": 31534,
                      "tax": [
                        {
                          "OT": 5223
                        }
                      ],
                      "fbc": "A0INT",
                      "ptype": "ADT",
                      "scur": "INR",
                      "sup_fare": "26311_31534"
                    }
                  ],
                  "routeId": "59fmlda90s",
                  "productClass": "R",
                  "ruleNumber": "5000"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "Q2c3c3fETtY=|A6KkIU40nZ4asiRAAWjEJn2YrIKBv2dQgqCTOYx11mnNyuaFYnXhwI5GuIilBz4V/oaFsdOsaNpO2v1OoLoyDKxVlrz4az4ufHZUNCPPoEmLz/Jk3DD2LYWM4DcZOuhPrHhGYGKemHY=",
              "stop": 0,
              "paxpr": [
                {
                  "bf": 26311,
                  "tf": 31534,
                  "fbc": "",
                  "psup_fare": "",
                  "tax": [
                    [
                      {
                        "OT": 5223
                      }
                    ]
                  ],
                  "ptype": "ADT",
                  "scur": "INR",
                  "sup_cur_fare": ""
                }
              ],
              "pr": {
                "bf": 26311,
                "tf": 31534,
                "tax": [
                  {
                    "OT": 5223
                  }
                ],
                "scur": "INR",
                "sup_fare": "",
                "sup_cur_fare": "",
                "cabb": "",
                "chekb": "",
                "seats": "",
                "meals": "",
                "chanb": "",
                "ftyp": ""
              }
            }
          ],
          "pr": {
            "bf": 56136,
            "tf": 65566,
            "tax": [
              {
                "OT": 4207
              },
              {
                "OT": 5223
              }
            ]
          },
          "paxpr": [
            {
              "bf": 56136,
              "tf": 65566,
              "tax": [
                [
                  {
                    "OT": 4207
                  }
                ],
                [
                  {
                    "OT": 5223
                  }
                ]
              ]
            }
          ],
          "sty": "split",
          "sup": "4/4",
          "airlineList": "6E_6E",
          "mix_carrier": true
        },
        {
          "srcorigns": [
            {
              "tid": "eu5XDxVL1EJHQb6o_1",
              "ref": 1,
              "sdur": "04:15",
              "re": "Refundable Before Departure",
              "stop": 0,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "QR",
              "seg": [
                {
                  "ref": 1,
                  "da": "DOH",
                  "ar": "HYD",
                  "dd": "2023-06-29T08:15:00",
                  "ad": "2023-06-29T15:00:00",
                  "rbd": "V",
                  "fn": "4776",
                  "eq": "320",
                  "ac": "QR",
                  "ma": "QR",
                  "seat": "9",
                  "oa": "6E",
                  "fa": [],
                  "fdur": "04:15",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": ""
                  }
                }
              ],
              "flightNumbers": "4776",
              "bgd": [
                {
                  "qun": "30",
                  "wt": "kg"
                }
              ],
              "paxpr": [
                {
                  "tf": 36064,
                  "tax": [
                    {
                      "YQ": 3136
                    },
                    {
                      "YR": 1344
                    },
                    {
                      "OT": 3136
                    }
                  ],
                  "bf": 28448,
                  "fbc": "VHQAI1SI",
                  "fareType": "RP",
                  "pusup_fare": "1270_1610",
                  "psup_fare": "28448_36064",
                  "ptype": "ADT"
                }
              ],
              "pr": {
                "bf": 28448,
                "tf": 36064,
                "tax": [
                  {
                    "YQ": 3136
                  },
                  {
                    "YR": 1344
                  },
                  {
                    "OT": 3136
                  }
                ],
                "scur": "QAR",
                "ucur": "INR",
                "exchange_rate": 22.4,
                "sup_fare": "1270_1610"
              }
            },
            {
              "tid": "50tefIVt11B2ZJT3_1",
              "ref": 1,
              "sdur": "04:15",
              "re": "Refundable Before Departure",
              "stop": 0,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "QR",
              "seg": [
                {
                  "ref": 1,
                  "da": "DOH",
                  "ar": "HYD",
                  "dd": "2023-06-29T02:00:00",
                  "ad": "2023-06-29T08:45:00",
                  "rbd": "V",
                  "fn": "4778",
                  "eq": "320",
                  "ac": "QR",
                  "ma": "QR",
                  "seat": "9",
                  "oa": "6E",
                  "fa": [],
                  "fdur": "04:15",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": ""
                  }
                }
              ],
              "flightNumbers": "4778",
              "bgd": [
                {
                  "qun": "30",
                  "wt": "kg"
                }
              ],
              "paxpr": [
                {
                  "tf": 36064,
                  "tax": [
                    {
                      "YQ": 3136
                    },
                    {
                      "YR": 1344
                    },
                    {
                      "OT": 3136
                    }
                  ],
                  "bf": 28448,
                  "fbc": "VHQAI1SI",
                  "fareType": "RP",
                  "pusup_fare": "1270_1610",
                  "psup_fare": "28448_36064",
                  "ptype": "ADT"
                }
              ],
              "pr": {
                "bf": 28448,
                "tf": 36064,
                "tax": [
                  {
                    "YQ": 3136
                  },
                  {
                    "YR": 1344
                  },
                  {
                    "OT": 3136
                  }
                ],
                "scur": "QAR",
                "ucur": "INR",
                "exchange_rate": 22.4,
                "sup_fare": "1270_1610"
              }
            }
          ],
          "destorigins": [
            {
              "tid": "MUS2TLIH8VyQcv3C_1",
              "ref": 1,
              "ofcid": "",
              "sdur": "4:40",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DOH",
                  "dd": "2023-06-30T04:10:00",
                  "ad": "2023-06-30T05:50:00",
                  "rbd": "",
                  "fn": "1313",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {},
                  "rph": "",
                  "jsell": "6E~1313~ ~~HYD~06/30/2023 04:10~DOH~06/30/2023 05:50~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~A~ ~6E~A0INT~5000~~0~2~~X",
                  "pr": {
                    "bf": 26311,
                    "tf": 31534,
                    "tax": [
                      {
                        "OT": 5223
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "26311_31534",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "paxpr": [
                    {
                      "bf": 26311,
                      "tf": 31534,
                      "tax": [
                        {
                          "OT": 5223
                        }
                      ],
                      "fbc": "A0INT",
                      "ptype": "ADT",
                      "scur": "INR",
                      "sup_fare": "26311_31534"
                    }
                  ],
                  "routeId": "12r3muxt5lj",
                  "productClass": "R",
                  "ruleNumber": "5000"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "Q2c3c3fETtY=|A6KkIU40nZ4asiRAAWjEJn2YrIKBv2dQgqCTOYx11mnNyuaFYnXhwI5GuIilBz4V/oaFsdOsaNpO2v1OoLoyDKxVlrz4az4ufHZUNCPPoEmLz/Jk3DD2LYWM4DcZOuhPrHhGYGKemHY=",
              "stop": 0,
              "paxpr": [
                {
                  "bf": 26311,
                  "tf": 31534,
                  "fbc": "",
                  "psup_fare": "",
                  "tax": [
                    [
                      {
                        "OT": 5223
                      }
                    ]
                  ],
                  "ptype": "ADT",
                  "scur": "INR",
                  "sup_cur_fare": ""
                }
              ],
              "pr": {
                "bf": 26311,
                "tf": 31534,
                "tax": [
                  {
                    "OT": 5223
                  }
                ],
                "scur": "INR",
                "sup_fare": "",
                "sup_cur_fare": "",
                "cabb": "",
                "chekb": "",
                "seats": "",
                "meals": "",
                "chanb": "",
                "ftyp": ""
              }
            },
            {
              "tid": "M9ZZHyNdeQxDFR68_1",
              "ref": 1,
              "ofcid": "",
              "sdur": "4:40",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DOH",
                  "dd": "2023-06-30T17:05:00",
                  "ad": "2023-06-30T18:45:00",
                  "rbd": "",
                  "fn": "1315",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {},
                  "rph": "",
                  "jsell": "6E~1315~ ~~HYD~06/30/2023 17:05~DOH~06/30/2023 18:45~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~A~ ~6E~A0INT~5000~~0~2~~X",
                  "pr": {
                    "bf": 26311,
                    "tf": 31534,
                    "tax": [
                      {
                        "OT": 5223
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "26311_31534",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "paxpr": [
                    {
                      "bf": 26311,
                      "tf": 31534,
                      "tax": [
                        {
                          "OT": 5223
                        }
                      ],
                      "fbc": "A0INT",
                      "ptype": "ADT",
                      "scur": "INR",
                      "sup_fare": "26311_31534"
                    }
                  ],
                  "routeId": "by2fv3b5vl",
                  "productClass": "R",
                  "ruleNumber": "5000"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "Q2c3c3fETtY=|A6KkIU40nZ4asiRAAWjEJn2YrIKBv2dQgqCTOYx11mnNyuaFYnXhwI5GuIilBz4V/oaFsdOsaNpO2v1OoLoyDKxVlrz4az4ufHZUNCPPoEmLz/Jk3DD2LYWM4DcZOuhPrHhGYGKemHY=",
              "stop": 0,
              "paxpr": [
                {
                  "bf": 26311,
                  "tf": 31534,
                  "fbc": "",
                  "psup_fare": "",
                  "tax": [
                    [
                      {
                        "OT": 5223
                      }
                    ]
                  ],
                  "ptype": "ADT",
                  "scur": "INR",
                  "sup_cur_fare": ""
                }
              ],
              "pr": {
                "bf": 26311,
                "tf": 31534,
                "tax": [
                  {
                    "OT": 5223
                  }
                ],
                "scur": "INR",
                "sup_fare": "",
                "sup_cur_fare": "",
                "cabb": "",
                "chekb": "",
                "seats": "",
                "meals": "",
                "chanb": "",
                "ftyp": ""
              }
            },
            {
              "tid": "V8CRhFZrBR0MrXE1_1",
              "ref": 1,
              "ofcid": "",
              "sdur": "4:40",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DOH",
                  "dd": "2023-06-30T21:55:00",
                  "ad": "2023-06-30T23:35:00",
                  "rbd": "",
                  "fn": "1317",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {},
                  "rph": "",
                  "jsell": "6E~1317~ ~~HYD~06/30/2023 21:55~DOH~06/30/2023 23:35~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~A~ ~6E~A0INT~5000~~0~2~~X",
                  "pr": {
                    "bf": 26311,
                    "tf": 31534,
                    "tax": [
                      {
                        "OT": 5223
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "26311_31534",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "paxpr": [
                    {
                      "bf": 26311,
                      "tf": 31534,
                      "tax": [
                        {
                          "OT": 5223
                        }
                      ],
                      "fbc": "A0INT",
                      "ptype": "ADT",
                      "scur": "INR",
                      "sup_fare": "26311_31534"
                    }
                  ],
                  "routeId": "59fmlda90s",
                  "productClass": "R",
                  "ruleNumber": "5000"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "Q2c3c3fETtY=|A6KkIU40nZ4asiRAAWjEJn2YrIKBv2dQgqCTOYx11mnNyuaFYnXhwI5GuIilBz4V/oaFsdOsaNpO2v1OoLoyDKxVlrz4az4ufHZUNCPPoEmLz/Jk3DD2LYWM4DcZOuhPrHhGYGKemHY=",
              "stop": 0,
              "paxpr": [
                {
                  "bf": 26311,
                  "tf": 31534,
                  "fbc": "",
                  "psup_fare": "",
                  "tax": [
                    [
                      {
                        "OT": 5223
                      }
                    ]
                  ],
                  "ptype": "ADT",
                  "scur": "INR",
                  "sup_cur_fare": ""
                }
              ],
              "pr": {
                "bf": 26311,
                "tf": 31534,
                "tax": [
                  {
                    "OT": 5223
                  }
                ],
                "scur": "INR",
                "sup_fare": "",
                "sup_cur_fare": "",
                "cabb": "",
                "chekb": "",
                "seats": "",
                "meals": "",
                "chanb": "",
                "ftyp": ""
              }
            }
          ],
          "pr": {
            "bf": 54759,
            "tf": 67598,
            "tax": [
              {
                "YQ": 3136
              },
              {
                "YR": 1344
              },
              {
                "OT": 3136
              },
              {
                "OT": 5223
              }
            ]
          },
          "paxpr": [
            {
              "bf": 54759,
              "tf": 67598,
              "tax": [
                {
                  "YQ": 3136
                },
                {
                  "YR": 1344
                },
                {
                  "OT": 3136
                },
                [
                  {
                    "OT": 5223
                  }
                ]
              ]
            }
          ],
          "sty": "split",
          "sup": "0/4",
          "airlineList": "6E_6E",
          "mix_carrier": true
        },
        {
          "srcorigns": [
            {
              "tid": "1RSk9SieyUL0ZAPu_1",
              "ref": 1,
              "sdur": "04:15",
              "re": "Refundable Before Departure",
              "stop": 0,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "QR",
              "seg": [
                {
                  "ref": 1,
                  "da": "DOH",
                  "ar": "HYD",
                  "dd": "2023-06-29T19:45:00",
                  "ad": "2023-06-30T02:30:00",
                  "rbd": "V",
                  "fn": "4774",
                  "eq": "320",
                  "ac": "QR",
                  "ma": "QR",
                  "seat": "9",
                  "oa": "6E",
                  "fa": [],
                  "fdur": "04:15",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": ""
                  }
                }
              ],
              "flightNumbers": "4774",
              "bgd": [
                {
                  "qun": "30",
                  "wt": "kg"
                }
              ],
              "paxpr": [
                {
                  "tf": 37856,
                  "tax": [
                    {
                      "YQ": 3136
                    },
                    {
                      "YR": 1344
                    },
                    {
                      "OT": 3136
                    }
                  ],
                  "bf": 30240,
                  "fbc": "VHQAI1SI",
                  "fareType": "RP",
                  "pusup_fare": "1350_1690",
                  "psup_fare": "30240_37856",
                  "ptype": "ADT"
                }
              ],
              "pr": {
                "bf": 30240,
                "tf": 37856,
                "tax": [
                  {
                    "YQ": 3136
                  },
                  {
                    "YR": 1344
                  },
                  {
                    "OT": 3136
                  }
                ],
                "scur": "QAR",
                "ucur": "INR",
                "exchange_rate": 22.4,
                "sup_fare": "1350_1690"
              }
            }
          ],
          "destorigins": [
            {
              "tid": "MUS2TLIH8VyQcv3C_1",
              "ref": 1,
              "ofcid": "",
              "sdur": "4:40",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DOH",
                  "dd": "2023-06-30T04:10:00",
                  "ad": "2023-06-30T05:50:00",
                  "rbd": "",
                  "fn": "1313",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {},
                  "rph": "",
                  "jsell": "6E~1313~ ~~HYD~06/30/2023 04:10~DOH~06/30/2023 05:50~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~A~ ~6E~A0INT~5000~~0~2~~X",
                  "pr": {
                    "bf": 26311,
                    "tf": 31534,
                    "tax": [
                      {
                        "OT": 5223
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "26311_31534",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "paxpr": [
                    {
                      "bf": 26311,
                      "tf": 31534,
                      "tax": [
                        {
                          "OT": 5223
                        }
                      ],
                      "fbc": "A0INT",
                      "ptype": "ADT",
                      "scur": "INR",
                      "sup_fare": "26311_31534"
                    }
                  ],
                  "routeId": "12r3muxt5lj",
                  "productClass": "R",
                  "ruleNumber": "5000"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "Q2c3c3fETtY=|A6KkIU40nZ4asiRAAWjEJn2YrIKBv2dQgqCTOYx11mnNyuaFYnXhwI5GuIilBz4V/oaFsdOsaNpO2v1OoLoyDKxVlrz4az4ufHZUNCPPoEmLz/Jk3DD2LYWM4DcZOuhPrHhGYGKemHY=",
              "stop": 0,
              "paxpr": [
                {
                  "bf": 26311,
                  "tf": 31534,
                  "fbc": "",
                  "psup_fare": "",
                  "tax": [
                    [
                      {
                        "OT": 5223
                      }
                    ]
                  ],
                  "ptype": "ADT",
                  "scur": "INR",
                  "sup_cur_fare": ""
                }
              ],
              "pr": {
                "bf": 26311,
                "tf": 31534,
                "tax": [
                  {
                    "OT": 5223
                  }
                ],
                "scur": "INR",
                "sup_fare": "",
                "sup_cur_fare": "",
                "cabb": "",
                "chekb": "",
                "seats": "",
                "meals": "",
                "chanb": "",
                "ftyp": ""
              }
            },
            {
              "tid": "M9ZZHyNdeQxDFR68_1",
              "ref": 1,
              "ofcid": "",
              "sdur": "4:40",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DOH",
                  "dd": "2023-06-30T17:05:00",
                  "ad": "2023-06-30T18:45:00",
                  "rbd": "",
                  "fn": "1315",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {},
                  "rph": "",
                  "jsell": "6E~1315~ ~~HYD~06/30/2023 17:05~DOH~06/30/2023 18:45~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~A~ ~6E~A0INT~5000~~0~2~~X",
                  "pr": {
                    "bf": 26311,
                    "tf": 31534,
                    "tax": [
                      {
                        "OT": 5223
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "26311_31534",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "paxpr": [
                    {
                      "bf": 26311,
                      "tf": 31534,
                      "tax": [
                        {
                          "OT": 5223
                        }
                      ],
                      "fbc": "A0INT",
                      "ptype": "ADT",
                      "scur": "INR",
                      "sup_fare": "26311_31534"
                    }
                  ],
                  "routeId": "by2fv3b5vl",
                  "productClass": "R",
                  "ruleNumber": "5000"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "Q2c3c3fETtY=|A6KkIU40nZ4asiRAAWjEJn2YrIKBv2dQgqCTOYx11mnNyuaFYnXhwI5GuIilBz4V/oaFsdOsaNpO2v1OoLoyDKxVlrz4az4ufHZUNCPPoEmLz/Jk3DD2LYWM4DcZOuhPrHhGYGKemHY=",
              "stop": 0,
              "paxpr": [
                {
                  "bf": 26311,
                  "tf": 31534,
                  "fbc": "",
                  "psup_fare": "",
                  "tax": [
                    [
                      {
                        "OT": 5223
                      }
                    ]
                  ],
                  "ptype": "ADT",
                  "scur": "INR",
                  "sup_cur_fare": ""
                }
              ],
              "pr": {
                "bf": 26311,
                "tf": 31534,
                "tax": [
                  {
                    "OT": 5223
                  }
                ],
                "scur": "INR",
                "sup_fare": "",
                "sup_cur_fare": "",
                "cabb": "",
                "chekb": "",
                "seats": "",
                "meals": "",
                "chanb": "",
                "ftyp": ""
              }
            },
            {
              "tid": "V8CRhFZrBR0MrXE1_1",
              "ref": 1,
              "ofcid": "",
              "sdur": "4:40",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DOH",
                  "dd": "2023-06-30T21:55:00",
                  "ad": "2023-06-30T23:35:00",
                  "rbd": "",
                  "fn": "1317",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {},
                  "rph": "",
                  "jsell": "6E~1317~ ~~HYD~06/30/2023 21:55~DOH~06/30/2023 23:35~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~A~ ~6E~A0INT~5000~~0~2~~X",
                  "pr": {
                    "bf": 26311,
                    "tf": 31534,
                    "tax": [
                      {
                        "OT": 5223
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "26311_31534",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "paxpr": [
                    {
                      "bf": 26311,
                      "tf": 31534,
                      "tax": [
                        {
                          "OT": 5223
                        }
                      ],
                      "fbc": "A0INT",
                      "ptype": "ADT",
                      "scur": "INR",
                      "sup_fare": "26311_31534"
                    }
                  ],
                  "routeId": "59fmlda90s",
                  "productClass": "R",
                  "ruleNumber": "5000"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "Q2c3c3fETtY=|A6KkIU40nZ4asiRAAWjEJn2YrIKBv2dQgqCTOYx11mnNyuaFYnXhwI5GuIilBz4V/oaFsdOsaNpO2v1OoLoyDKxVlrz4az4ufHZUNCPPoEmLz/Jk3DD2LYWM4DcZOuhPrHhGYGKemHY=",
              "stop": 0,
              "paxpr": [
                {
                  "bf": 26311,
                  "tf": 31534,
                  "fbc": "",
                  "psup_fare": "",
                  "tax": [
                    [
                      {
                        "OT": 5223
                      }
                    ]
                  ],
                  "ptype": "ADT",
                  "scur": "INR",
                  "sup_cur_fare": ""
                }
              ],
              "pr": {
                "bf": 26311,
                "tf": 31534,
                "tax": [
                  {
                    "OT": 5223
                  }
                ],
                "scur": "INR",
                "sup_fare": "",
                "sup_cur_fare": "",
                "cabb": "",
                "chekb": "",
                "seats": "",
                "meals": "",
                "chanb": "",
                "ftyp": ""
              }
            }
          ],
          "pr": {
            "bf": 56551,
            "tf": 69390,
            "tax": [
              {
                "YQ": 3136
              },
              {
                "YR": 1344
              },
              {
                "OT": 3136
              },
              {
                "OT": 5223
              }
            ]
          },
          "paxpr": [
            {
              "bf": 56551,
              "tf": 69390,
              "tax": [
                {
                  "YQ": 3136
                },
                {
                  "YR": 1344
                },
                {
                  "OT": 3136
                },
                [
                  {
                    "OT": 5223
                  }
                ]
              ]
            }
          ],
          "sty": "split",
          "sup": "0/4",
          "airlineList": "6E_6E",
          "mix_carrier": true
        },
        {
          "srcorigns": [
            {
              "tid": "UC8pMN2hvMQGa489_1",
              "ref": 1,
              "ofcid": "",
              "sdur": "9:45",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "DOH",
                  "ar": "HYD",
                  "dd": "2023-06-29T02:00:00",
                  "ad": "2023-06-29T08:45:00",
                  "rbd": "",
                  "fn": "1318",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {},
                  "rph": "",
                  "jsell": "6E~1318~ ~~DOH~06/29/2023 02:00~HYD~06/29/2023 08:45~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~A~ ~6E~A0INT~5000~~0~1~~X",
                  "pr": {
                    "bf": 28373,
                    "tf": 32479,
                    "tax": [
                      {
                        "OT": 4106
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "28373_32479",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "paxpr": [
                    {
                      "bf": 28373,
                      "tf": 32479,
                      "tax": [
                        {
                          "OT": 4106
                        }
                      ],
                      "fbc": "A0INT",
                      "ptype": "ADT",
                      "scur": "INR",
                      "sup_fare": "28373_32479"
                    }
                  ],
                  "routeId": "lh3rsvezlv",
                  "productClass": "R",
                  "ruleNumber": "5000"
                },
                {
                  "fareKey": "0~A~ ~6E~AUINT~5003~~0~7~~X",
                  "pr": {
                    "bf": 29073,
                    "tf": 33204,
                    "tax": [
                      {
                        "OT": 4131
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "29073_33204",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "paxpr": [
                    {
                      "bf": 29073,
                      "tf": 33204,
                      "tax": [
                        {
                          "OT": 4131
                        }
                      ],
                      "fbc": "AUINT",
                      "ptype": "ADT",
                      "scur": "INR",
                      "sup_fare": "29073_33204"
                    }
                  ],
                  "routeId": "56adr4fn8o",
                  "productClass": "J",
                  "ruleNumber": "5003"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "Qjaaeqrl1XE=|aQFezKSWQkTEFuzy1c/XiE8E1gCe1mp7wL3TnlV5DA+x56hxCT+7ZWP6pBbfk21i1VpV7qcOGfUZINz5cX0sMPO7zDGGtcxXeJ5RNOP3ZplH1FG3CBzrsOYD796VyuQ29DfNwKuxQhE=",
              "stop": 0,
              "paxpr": [
                {
                  "bf": 28373,
                  "tf": 32479,
                  "fbc": "",
                  "psup_fare": "",
                  "tax": [
                    [
                      {
                        "OT": 4106
                      }
                    ]
                  ],
                  "ptype": "ADT",
                  "scur": "INR",
                  "sup_cur_fare": ""
                }
              ],
              "pr": {
                "bf": 28373,
                "tf": 32479,
                "tax": [
                  {
                    "OT": 4106
                  }
                ],
                "scur": "INR",
                "sup_fare": "",
                "sup_cur_fare": "",
                "cabb": "",
                "chekb": "",
                "seats": "",
                "meals": "",
                "chanb": "",
                "ftyp": ""
              }
            },
            {
              "tid": "9szQBnvh8kM9vfqy_1",
              "ref": 1,
              "ofcid": "",
              "sdur": "9:45",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "DOH",
                  "ar": "HYD",
                  "dd": "2023-06-29T08:15:00",
                  "ad": "2023-06-29T15:00:00",
                  "rbd": "",
                  "fn": "1314",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {},
                  "rph": "",
                  "jsell": "6E~1314~ ~~DOH~06/29/2023 08:15~HYD~06/29/2023 15:00~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~A~ ~6E~A0INT~5000~~0~1~~X",
                  "pr": {
                    "bf": 28373,
                    "tf": 32479,
                    "tax": [
                      {
                        "OT": 4106
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "28373_32479",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "paxpr": [
                    {
                      "bf": 28373,
                      "tf": 32479,
                      "tax": [
                        {
                          "OT": 4106
                        }
                      ],
                      "fbc": "A0INT",
                      "ptype": "ADT",
                      "scur": "INR",
                      "sup_fare": "28373_32479"
                    }
                  ],
                  "routeId": "ivcua3jvq",
                  "productClass": "R",
                  "ruleNumber": "5000"
                },
                {
                  "fareKey": "0~A~ ~6E~AUINT~5003~~0~7~~X",
                  "pr": {
                    "bf": 29073,
                    "tf": 33204,
                    "tax": [
                      {
                        "OT": 4131
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "29073_33204",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "paxpr": [
                    {
                      "bf": 29073,
                      "tf": 33204,
                      "tax": [
                        {
                          "OT": 4131
                        }
                      ],
                      "fbc": "AUINT",
                      "ptype": "ADT",
                      "scur": "INR",
                      "sup_fare": "29073_33204"
                    }
                  ],
                  "routeId": "romovrv2la",
                  "productClass": "J",
                  "ruleNumber": "5003"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "Qjaaeqrl1XE=|aQFezKSWQkTEFuzy1c/XiE8E1gCe1mp7wL3TnlV5DA+x56hxCT+7ZWP6pBbfk21i1VpV7qcOGfUZINz5cX0sMPO7zDGGtcxXeJ5RNOP3ZplH1FG3CBzrsOYD796VyuQ29DfNwKuxQhE=",
              "stop": 0,
              "paxpr": [
                {
                  "bf": 28373,
                  "tf": 32479,
                  "fbc": "",
                  "psup_fare": "",
                  "tax": [
                    [
                      {
                        "OT": 4106
                      }
                    ]
                  ],
                  "ptype": "ADT",
                  "scur": "INR",
                  "sup_cur_fare": ""
                }
              ],
              "pr": {
                "bf": 28373,
                "tf": 32479,
                "tax": [
                  {
                    "OT": 4106
                  }
                ],
                "scur": "INR",
                "sup_fare": "",
                "sup_cur_fare": "",
                "cabb": "",
                "chekb": "",
                "seats": "",
                "meals": "",
                "chanb": "",
                "ftyp": ""
              }
            },
            {
              "tid": "CHBJNQO3ROTFP5Tq_1",
              "ref": 1,
              "ofcid": "",
              "sdur": "9:45",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "DOH",
                  "ar": "HYD",
                  "dd": "2023-06-29T19:45:00",
                  "ad": "2023-06-30T02:30:00",
                  "rbd": "",
                  "fn": "1316",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {},
                  "rph": "",
                  "jsell": "6E~1316~ ~~DOH~06/29/2023 19:45~HYD~06/30/2023 02:30~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~A~ ~6E~A0INT~5000~~0~1~~X",
                  "pr": {
                    "bf": 28373,
                    "tf": 32479,
                    "tax": [
                      {
                        "OT": 4106
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "28373_32479",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "paxpr": [
                    {
                      "bf": 28373,
                      "tf": 32479,
                      "tax": [
                        {
                          "OT": 4106
                        }
                      ],
                      "fbc": "A0INT",
                      "ptype": "ADT",
                      "scur": "INR",
                      "sup_fare": "28373_32479"
                    }
                  ],
                  "routeId": "7uvfkpd73g",
                  "productClass": "R",
                  "ruleNumber": "5000"
                },
                {
                  "fareKey": "0~A~ ~6E~AUINT~5003~~0~7~~X",
                  "pr": {
                    "bf": 29073,
                    "tf": 33204,
                    "tax": [
                      {
                        "OT": 4131
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "29073_33204",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "paxpr": [
                    {
                      "bf": 29073,
                      "tf": 33204,
                      "tax": [
                        {
                          "OT": 4131
                        }
                      ],
                      "fbc": "AUINT",
                      "ptype": "ADT",
                      "scur": "INR",
                      "sup_fare": "29073_33204"
                    }
                  ],
                  "routeId": "g0dit1py42i",
                  "productClass": "J",
                  "ruleNumber": "5003"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "Qjaaeqrl1XE=|aQFezKSWQkTEFuzy1c/XiE8E1gCe1mp7wL3TnlV5DA+x56hxCT+7ZWP6pBbfk21i1VpV7qcOGfUZINz5cX0sMPO7zDGGtcxXeJ5RNOP3ZplH1FG3CBzrsOYD796VyuQ29DfNwKuxQhE=",
              "stop": 0,
              "paxpr": [
                {
                  "bf": 28373,
                  "tf": 32479,
                  "fbc": "",
                  "psup_fare": "",
                  "tax": [
                    [
                      {
                        "OT": 4106
                      }
                    ]
                  ],
                  "ptype": "ADT",
                  "scur": "INR",
                  "sup_cur_fare": ""
                }
              ],
              "pr": {
                "bf": 28373,
                "tf": 32479,
                "tax": [
                  {
                    "OT": 4106
                  }
                ],
                "scur": "INR",
                "sup_fare": "",
                "sup_cur_fare": "",
                "cabb": "",
                "chekb": "",
                "seats": "",
                "meals": "",
                "chanb": "",
                "ftyp": ""
              }
            }
          ],
          "destorigins": [
            {
              "tid": "o4dGVuQJgCyaGMOJ_1",
              "ref": 1,
              "sdur": "04:10",
              "re": "Refundable Before Departure",
              "stop": 0,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "QR",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DOH",
                  "dd": "2023-06-30T21:55:00",
                  "ad": "2023-06-30T23:35:00",
                  "rbd": "N",
                  "fn": "4779",
                  "eq": "320",
                  "ac": "QR",
                  "ma": "QR",
                  "seat": "9",
                  "oa": "6E",
                  "fa": [],
                  "fdur": "04:10",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": ""
                  }
                }
              ],
              "flightNumbers": "4779",
              "bgd": [
                {
                  "qun": "25",
                  "wt": "kg"
                }
              ],
              "paxpr": [
                {
                  "tf": 39200,
                  "tax": [
                    {
                      "YQ": 9856
                    },
                    {
                      "YR": 896
                    },
                    {
                      "OT": 5152
                    }
                  ],
                  "bf": 23296,
                  "fbc": "NLR4I1RQ",
                  "fareType": "RP",
                  "pusup_fare": "1040_1750",
                  "psup_fare": "23296_39200",
                  "ptype": "ADT"
                }
              ],
              "pr": {
                "bf": 23296,
                "tf": 39200,
                "tax": [
                  {
                    "YQ": 9856
                  },
                  {
                    "YR": 896
                  },
                  {
                    "OT": 5152
                  }
                ],
                "scur": "QAR",
                "ucur": "INR",
                "exchange_rate": 22.4,
                "sup_fare": "1040_1750"
              }
            },
            {
              "tid": "LithwaiTgVo0zTSn_1",
              "ref": 1,
              "sdur": "04:10",
              "re": "Refundable Before Departure",
              "stop": 0,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "QR",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DOH",
                  "dd": "2023-06-30T17:05:00",
                  "ad": "2023-06-30T18:45:00",
                  "rbd": "N",
                  "fn": "4777",
                  "eq": "320",
                  "ac": "QR",
                  "ma": "QR",
                  "seat": "9",
                  "oa": "6E",
                  "fa": [],
                  "fdur": "04:10",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": ""
                  }
                }
              ],
              "flightNumbers": "4777",
              "bgd": [
                {
                  "qun": "25",
                  "wt": "kg"
                }
              ],
              "paxpr": [
                {
                  "tf": 39200,
                  "tax": [
                    {
                      "YQ": 9856
                    },
                    {
                      "YR": 896
                    },
                    {
                      "OT": 5152
                    }
                  ],
                  "bf": 23296,
                  "fbc": "NLR4I1RQ",
                  "fareType": "RP",
                  "pusup_fare": "1040_1750",
                  "psup_fare": "23296_39200",
                  "ptype": "ADT"
                }
              ],
              "pr": {
                "bf": 23296,
                "tf": 39200,
                "tax": [
                  {
                    "YQ": 9856
                  },
                  {
                    "YR": 896
                  },
                  {
                    "OT": 5152
                  }
                ],
                "scur": "QAR",
                "ucur": "INR",
                "exchange_rate": 22.4,
                "sup_fare": "1040_1750"
              }
            },
            {
              "tid": "VtTRT4CTTnmiBqt3_1",
              "ref": 1,
              "sdur": "04:10",
              "re": "Refundable Before Departure",
              "stop": 0,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "QR",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DOH",
                  "dd": "2023-06-30T04:10:00",
                  "ad": "2023-06-30T05:50:00",
                  "rbd": "N",
                  "fn": "4775",
                  "eq": "320",
                  "ac": "QR",
                  "ma": "QR",
                  "seat": "9",
                  "oa": "6E",
                  "fa": [],
                  "fdur": "04:10",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": ""
                  }
                }
              ],
              "flightNumbers": "4775",
              "bgd": [
                {
                  "qun": "25",
                  "wt": "kg"
                }
              ],
              "paxpr": [
                {
                  "tf": 39200,
                  "tax": [
                    {
                      "YQ": 9856
                    },
                    {
                      "YR": 896
                    },
                    {
                      "OT": 5152
                    }
                  ],
                  "bf": 23296,
                  "fbc": "NLR4I1RQ",
                  "fareType": "RP",
                  "pusup_fare": "1040_1750",
                  "psup_fare": "23296_39200",
                  "ptype": "ADT"
                }
              ],
              "pr": {
                "bf": 23296,
                "tf": 39200,
                "tax": [
                  {
                    "YQ": 9856
                  },
                  {
                    "YR": 896
                  },
                  {
                    "OT": 5152
                  }
                ],
                "scur": "QAR",
                "ucur": "INR",
                "exchange_rate": 22.4,
                "sup_fare": "1040_1750"
              }
            }
          ],
          "pr": {
            "bf": 51669,
            "tf": 71679,
            "tax": [
              {
                "OT": 4106
              },
              {
                "YQ": 9856
              },
              {
                "YR": 896
              },
              {
                "OT": 5152
              }
            ]
          },
          "paxpr": [
            {
              "bf": 51669,
              "tf": 71679,
              "tax": [
                [
                  {
                    "OT": 4106
                  }
                ],
                {
                  "YQ": 9856
                },
                {
                  "YR": 896
                },
                {
                  "OT": 5152
                }
              ]
            }
          ],
          "sty": "split",
          "sup": "4/0",
          "airlineList": "6E_6E",
          "mix_carrier": true
        },
        {
          "srcorigns": [
            {
              "tid": "IhMrTnLaWeMUB4Dh_1",
              "ref": 1,
              "ofcid": "",
              "sdur": "13:35",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "DOH",
                  "ar": "BOM",
                  "dd": "2023-06-29T08:15:00",
                  "ad": "2023-06-29T14:20:00",
                  "rbd": "",
                  "fn": "1304",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "2"
                  },
                  "rph": "",
                  "jsell": "6E~1304~ ~~DOH~06/29/2023 08:15~BOM~06/29/2023 14:20~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                },
                {
                  "ref": 2,
                  "da": "BOM",
                  "ar": "HYD",
                  "dd": "2023-06-29T17:20:00",
                  "ad": "2023-06-29T18:50:00",
                  "rbd": "",
                  "fn": "6571",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "2"
                  },
                  "rph": "",
                  "jsell": "6E~6571~ ~~BOM~06/29/2023 17:20~HYD~06/29/2023 18:50~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "1~W~ ~6E~W0INT~5000~~0~7~~^2~R~ ~6E~RCIP~1070~~0~8~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 28729,
                      "fbc": "W0INT/RCIP",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "28729_32898",
                      "tax": [
                        {
                          "OT": 4169
                        }
                      ],
                      "tf": 32898
                    }
                  ],
                  "pr": {
                    "bf": 28729,
                    "tf": 32898,
                    "tax": [
                      {
                        "OT": 4169
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "28729_32898",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "c0i0ja0ypni",
                  "ruleNumber": "5000",
                  "productClass": "R"
                },
                {
                  "fareKey": "1~W~ ~6E~WUINT~5003~~0~12~~^2~R~ ~6E~RUIP~2009~~0~4~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 30029,
                      "fbc": "WUINT/RUIP",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "30029_34243",
                      "tax": [
                        {
                          "OT": 4214
                        }
                      ],
                      "tf": 34243
                    }
                  ],
                  "pr": {
                    "bf": 30029,
                    "tf": 34243,
                    "tax": [
                      {
                        "OT": 4214
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "30029_34243",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "0qfapdtxv6",
                  "ruleNumber": "5003",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "Qjaaeqrl1XE=|aQFezKSWQkTEFuzy1c/XiE8E1gCe1mp7wL3TnlV5DA+x56hxCT+7ZWP6pBbfk21i1VpV7qcOGfUZINz5cX0sMPO7zDGGtcxXeJ5RNOP3ZplH1FG3CBzrsOYD796VyuQ29DfNwKuxQhE=",
              "stop": 1,
              "paxpr": [
                {
                  "bf": 28729,
                  "tf": 32898,
                  "fbc": "",
                  "psup_fare": "",
                  "tax": [
                    [
                      {
                        "OT": 4169
                      }
                    ]
                  ],
                  "ptype": "ADT",
                  "scur": "INR",
                  "sup_cur_fare": ""
                }
              ],
              "pr": {
                "bf": 28729,
                "tf": 32898,
                "tax": [
                  {
                    "OT": 4169
                  }
                ],
                "scur": "INR",
                "sup_fare": "",
                "sup_cur_fare": "",
                "cabb": "",
                "chekb": "",
                "seats": "",
                "meals": "",
                "chanb": "",
                "ftyp": ""
              }
            }
          ],
          "destorigins": [
            {
              "tid": "o4dGVuQJgCyaGMOJ_1",
              "ref": 1,
              "sdur": "04:10",
              "re": "Refundable Before Departure",
              "stop": 0,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "QR",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DOH",
                  "dd": "2023-06-30T21:55:00",
                  "ad": "2023-06-30T23:35:00",
                  "rbd": "N",
                  "fn": "4779",
                  "eq": "320",
                  "ac": "QR",
                  "ma": "QR",
                  "seat": "9",
                  "oa": "6E",
                  "fa": [],
                  "fdur": "04:10",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": ""
                  }
                }
              ],
              "flightNumbers": "4779",
              "bgd": [
                {
                  "qun": "25",
                  "wt": "kg"
                }
              ],
              "paxpr": [
                {
                  "tf": 39200,
                  "tax": [
                    {
                      "YQ": 9856
                    },
                    {
                      "YR": 896
                    },
                    {
                      "OT": 5152
                    }
                  ],
                  "bf": 23296,
                  "fbc": "NLR4I1RQ",
                  "fareType": "RP",
                  "pusup_fare": "1040_1750",
                  "psup_fare": "23296_39200",
                  "ptype": "ADT"
                }
              ],
              "pr": {
                "bf": 23296,
                "tf": 39200,
                "tax": [
                  {
                    "YQ": 9856
                  },
                  {
                    "YR": 896
                  },
                  {
                    "OT": 5152
                  }
                ],
                "scur": "QAR",
                "ucur": "INR",
                "exchange_rate": 22.4,
                "sup_fare": "1040_1750"
              }
            },
            {
              "tid": "LithwaiTgVo0zTSn_1",
              "ref": 1,
              "sdur": "04:10",
              "re": "Refundable Before Departure",
              "stop": 0,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "QR",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DOH",
                  "dd": "2023-06-30T17:05:00",
                  "ad": "2023-06-30T18:45:00",
                  "rbd": "N",
                  "fn": "4777",
                  "eq": "320",
                  "ac": "QR",
                  "ma": "QR",
                  "seat": "9",
                  "oa": "6E",
                  "fa": [],
                  "fdur": "04:10",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": ""
                  }
                }
              ],
              "flightNumbers": "4777",
              "bgd": [
                {
                  "qun": "25",
                  "wt": "kg"
                }
              ],
              "paxpr": [
                {
                  "tf": 39200,
                  "tax": [
                    {
                      "YQ": 9856
                    },
                    {
                      "YR": 896
                    },
                    {
                      "OT": 5152
                    }
                  ],
                  "bf": 23296,
                  "fbc": "NLR4I1RQ",
                  "fareType": "RP",
                  "pusup_fare": "1040_1750",
                  "psup_fare": "23296_39200",
                  "ptype": "ADT"
                }
              ],
              "pr": {
                "bf": 23296,
                "tf": 39200,
                "tax": [
                  {
                    "YQ": 9856
                  },
                  {
                    "YR": 896
                  },
                  {
                    "OT": 5152
                  }
                ],
                "scur": "QAR",
                "ucur": "INR",
                "exchange_rate": 22.4,
                "sup_fare": "1040_1750"
              }
            },
            {
              "tid": "VtTRT4CTTnmiBqt3_1",
              "ref": 1,
              "sdur": "04:10",
              "re": "Refundable Before Departure",
              "stop": 0,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "QR",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DOH",
                  "dd": "2023-06-30T04:10:00",
                  "ad": "2023-06-30T05:50:00",
                  "rbd": "N",
                  "fn": "4775",
                  "eq": "320",
                  "ac": "QR",
                  "ma": "QR",
                  "seat": "9",
                  "oa": "6E",
                  "fa": [],
                  "fdur": "04:10",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": ""
                  }
                }
              ],
              "flightNumbers": "4775",
              "bgd": [
                {
                  "qun": "25",
                  "wt": "kg"
                }
              ],
              "paxpr": [
                {
                  "tf": 39200,
                  "tax": [
                    {
                      "YQ": 9856
                    },
                    {
                      "YR": 896
                    },
                    {
                      "OT": 5152
                    }
                  ],
                  "bf": 23296,
                  "fbc": "NLR4I1RQ",
                  "fareType": "RP",
                  "pusup_fare": "1040_1750",
                  "psup_fare": "23296_39200",
                  "ptype": "ADT"
                }
              ],
              "pr": {
                "bf": 23296,
                "tf": 39200,
                "tax": [
                  {
                    "YQ": 9856
                  },
                  {
                    "YR": 896
                  },
                  {
                    "OT": 5152
                  }
                ],
                "scur": "QAR",
                "ucur": "INR",
                "exchange_rate": 22.4,
                "sup_fare": "1040_1750"
              }
            }
          ],
          "pr": {
            "bf": 52025,
            "tf": 72098,
            "tax": [
              {
                "OT": 4169
              },
              {
                "YQ": 9856
              },
              {
                "YR": 896
              },
              {
                "OT": 5152
              }
            ]
          },
          "paxpr": [
            {
              "bf": 52025,
              "tf": 72098,
              "tax": [
                [
                  {
                    "OT": 4169
                  }
                ],
                {
                  "YQ": 9856
                },
                {
                  "YR": 896
                },
                {
                  "OT": 5152
                }
              ]
            }
          ],
          "sty": "split",
          "sup": "4/0",
          "airlineList": "6E_6E",
          "mix_carrier": true
        },
        {
          "srcorigns": [
            {
              "tid": "4B7yO6HNNcmNlngc_1",
              "ref": 1,
              "ofcid": "",
              "sdur": "14:10",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "DOH",
                  "ar": "BOM",
                  "dd": "2023-06-29T19:45:00",
                  "ad": "2023-06-30T01:55:00",
                  "rbd": "",
                  "fn": "1306",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "2"
                  },
                  "rph": "",
                  "jsell": "6E~1306~ ~~DOH~06/29/2023 19:45~BOM~06/30/2023 01:55~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                },
                {
                  "ref": 2,
                  "da": "BOM",
                  "ar": "HYD",
                  "dd": "2023-06-30T05:20:00",
                  "ad": "2023-06-30T06:55:00",
                  "rbd": "",
                  "fn": " 461",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "2"
                  },
                  "rph": "",
                  "jsell": "6E~ 461~ ~~BOM~06/30/2023 05:20~HYD~06/30/2023 06:55~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "1~W~ ~6E~W0INT~5000~~0~7~~^2~L~ ~6E~LCIP~1070~~0~12~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 29825,
                      "fbc": "W0INT/LCIP",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "29825_34032",
                      "tax": [
                        {
                          "OT": 4207
                        }
                      ],
                      "tf": 34032
                    }
                  ],
                  "pr": {
                    "bf": 29825,
                    "tf": 34032,
                    "tax": [
                      {
                        "OT": 4207
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "29825_34032",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "mjfsn2pkpp",
                  "ruleNumber": "5000",
                  "productClass": "R"
                },
                {
                  "fareKey": "1~W~ ~6E~WUINT~5003~~0~12~~^2~L~ ~6E~LUIP~2009~~0~5~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 31125,
                      "fbc": "WUINT/LUIP",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "31125_35377",
                      "tax": [
                        {
                          "OT": 4252
                        }
                      ],
                      "tf": 35377
                    }
                  ],
                  "pr": {
                    "bf": 31125,
                    "tf": 35377,
                    "tax": [
                      {
                        "OT": 4252
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "31125_35377",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "09r1p3tt7ij",
                  "ruleNumber": "5003",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "Qjaaeqrl1XE=|aQFezKSWQkTEFuzy1c/XiE8E1gCe1mp7wL3TnlV5DA+x56hxCT+7ZWP6pBbfk21i1VpV7qcOGfUZINz5cX0sMPO7zDGGtcxXeJ5RNOP3ZplH1FG3CBzrsOYD796VyuQ29DfNwKuxQhE=",
              "stop": 1,
              "paxpr": [
                {
                  "bf": 29825,
                  "tf": 34032,
                  "fbc": "",
                  "psup_fare": "",
                  "tax": [
                    [
                      {
                        "OT": 4207
                      }
                    ]
                  ],
                  "ptype": "ADT",
                  "scur": "INR",
                  "sup_cur_fare": ""
                }
              ],
              "pr": {
                "bf": 29825,
                "tf": 34032,
                "tax": [
                  {
                    "OT": 4207
                  }
                ],
                "scur": "INR",
                "sup_fare": "",
                "sup_cur_fare": "",
                "cabb": "",
                "chekb": "",
                "seats": "",
                "meals": "",
                "chanb": "",
                "ftyp": ""
              }
            }
          ],
          "destorigins": [
            {
              "tid": "o4dGVuQJgCyaGMOJ_1",
              "ref": 1,
              "sdur": "04:10",
              "re": "Refundable Before Departure",
              "stop": 0,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "QR",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DOH",
                  "dd": "2023-06-30T21:55:00",
                  "ad": "2023-06-30T23:35:00",
                  "rbd": "N",
                  "fn": "4779",
                  "eq": "320",
                  "ac": "QR",
                  "ma": "QR",
                  "seat": "9",
                  "oa": "6E",
                  "fa": [],
                  "fdur": "04:10",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": ""
                  }
                }
              ],
              "flightNumbers": "4779",
              "bgd": [
                {
                  "qun": "25",
                  "wt": "kg"
                }
              ],
              "paxpr": [
                {
                  "tf": 39200,
                  "tax": [
                    {
                      "YQ": 9856
                    },
                    {
                      "YR": 896
                    },
                    {
                      "OT": 5152
                    }
                  ],
                  "bf": 23296,
                  "fbc": "NLR4I1RQ",
                  "fareType": "RP",
                  "pusup_fare": "1040_1750",
                  "psup_fare": "23296_39200",
                  "ptype": "ADT"
                }
              ],
              "pr": {
                "bf": 23296,
                "tf": 39200,
                "tax": [
                  {
                    "YQ": 9856
                  },
                  {
                    "YR": 896
                  },
                  {
                    "OT": 5152
                  }
                ],
                "scur": "QAR",
                "ucur": "INR",
                "exchange_rate": 22.4,
                "sup_fare": "1040_1750"
              }
            },
            {
              "tid": "LithwaiTgVo0zTSn_1",
              "ref": 1,
              "sdur": "04:10",
              "re": "Refundable Before Departure",
              "stop": 0,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "QR",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DOH",
                  "dd": "2023-06-30T17:05:00",
                  "ad": "2023-06-30T18:45:00",
                  "rbd": "N",
                  "fn": "4777",
                  "eq": "320",
                  "ac": "QR",
                  "ma": "QR",
                  "seat": "9",
                  "oa": "6E",
                  "fa": [],
                  "fdur": "04:10",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": ""
                  }
                }
              ],
              "flightNumbers": "4777",
              "bgd": [
                {
                  "qun": "25",
                  "wt": "kg"
                }
              ],
              "paxpr": [
                {
                  "tf": 39200,
                  "tax": [
                    {
                      "YQ": 9856
                    },
                    {
                      "YR": 896
                    },
                    {
                      "OT": 5152
                    }
                  ],
                  "bf": 23296,
                  "fbc": "NLR4I1RQ",
                  "fareType": "RP",
                  "pusup_fare": "1040_1750",
                  "psup_fare": "23296_39200",
                  "ptype": "ADT"
                }
              ],
              "pr": {
                "bf": 23296,
                "tf": 39200,
                "tax": [
                  {
                    "YQ": 9856
                  },
                  {
                    "YR": 896
                  },
                  {
                    "OT": 5152
                  }
                ],
                "scur": "QAR",
                "ucur": "INR",
                "exchange_rate": 22.4,
                "sup_fare": "1040_1750"
              }
            },
            {
              "tid": "VtTRT4CTTnmiBqt3_1",
              "ref": 1,
              "sdur": "04:10",
              "re": "Refundable Before Departure",
              "stop": 0,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "QR",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DOH",
                  "dd": "2023-06-30T04:10:00",
                  "ad": "2023-06-30T05:50:00",
                  "rbd": "N",
                  "fn": "4775",
                  "eq": "320",
                  "ac": "QR",
                  "ma": "QR",
                  "seat": "9",
                  "oa": "6E",
                  "fa": [],
                  "fdur": "04:10",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": ""
                  }
                }
              ],
              "flightNumbers": "4775",
              "bgd": [
                {
                  "qun": "25",
                  "wt": "kg"
                }
              ],
              "paxpr": [
                {
                  "tf": 39200,
                  "tax": [
                    {
                      "YQ": 9856
                    },
                    {
                      "YR": 896
                    },
                    {
                      "OT": 5152
                    }
                  ],
                  "bf": 23296,
                  "fbc": "NLR4I1RQ",
                  "fareType": "RP",
                  "pusup_fare": "1040_1750",
                  "psup_fare": "23296_39200",
                  "ptype": "ADT"
                }
              ],
              "pr": {
                "bf": 23296,
                "tf": 39200,
                "tax": [
                  {
                    "YQ": 9856
                  },
                  {
                    "YR": 896
                  },
                  {
                    "OT": 5152
                  }
                ],
                "scur": "QAR",
                "ucur": "INR",
                "exchange_rate": 22.4,
                "sup_fare": "1040_1750"
              }
            }
          ],
          "pr": {
            "bf": 53121,
            "tf": 73232,
            "tax": [
              {
                "OT": 4207
              },
              {
                "YQ": 9856
              },
              {
                "YR": 896
              },
              {
                "OT": 5152
              }
            ]
          },
          "paxpr": [
            {
              "bf": 53121,
              "tf": 73232,
              "tax": [
                [
                  {
                    "OT": 4207
                  }
                ],
                {
                  "YQ": 9856
                },
                {
                  "YR": 896
                },
                {
                  "OT": 5152
                }
              ]
            }
          ],
          "sty": "split",
          "sup": "4/0",
          "airlineList": "6E_6E",
          "mix_carrier": true
        },
        {
          "srcorigns": [
            {
              "tid": "LJJ0ZvMJvJ151glA_1",
              "ref": 1,
              "sdur": "04:15",
              "re": "Refundable Before Departure",
              "stop": 0,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "QR",
              "seg": [
                {
                  "ref": 1,
                  "da": "DOH",
                  "ar": "HYD",
                  "dd": "2023-06-29T08:15:00",
                  "ad": "2023-06-29T15:00:00",
                  "rbd": "M",
                  "fn": "4776",
                  "eq": "320",
                  "ac": "QR",
                  "ma": "QR",
                  "seat": "9",
                  "oa": "6E",
                  "fa": [],
                  "fdur": "04:15",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": ""
                  }
                }
              ],
              "flightNumbers": "4776",
              "bgd": [
                {
                  "qun": "30",
                  "wt": "kg"
                }
              ]
            },
            {
              "tid": "LxD9pR88vlfs0rJo_1",
              "ref": 1,
              "sdur": "04:15",
              "re": "Refundable Before Departure",
              "stop": 0,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "QR",
              "seg": [
                {
                  "ref": 1,
                  "da": "DOH",
                  "ar": "HYD",
                  "dd": "2023-06-29T02:00:00",
                  "ad": "2023-06-29T08:45:00",
                  "rbd": "M",
                  "fn": "4778",
                  "eq": "320",
                  "ac": "QR",
                  "ma": "QR",
                  "seat": "9",
                  "oa": "6E",
                  "fa": [],
                  "fdur": "04:15",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": ""
                  }
                }
              ],
              "flightNumbers": "4778",
              "bgd": [
                {
                  "qun": "30",
                  "wt": "kg"
                }
              ]
            }
          ],
          "destorigins": [
            {
              "tid": "cQUMofS8thvTdLu7_2",
              "ref": 2,
              "sdur": "04:10",
              "re": "Refundable Before Departure",
              "stop": 0,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "QR",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DOH",
                  "dd": "2023-06-30T21:55:00",
                  "ad": "2023-06-30T23:35:00",
                  "rbd": "M",
                  "fn": "4779",
                  "eq": "320",
                  "ac": "QR",
                  "ma": "QR",
                  "seat": "9",
                  "oa": "6E",
                  "fa": [],
                  "fdur": "04:10",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": ""
                  }
                }
              ],
              "flightNumbers": "4779",
              "bgd": [
                {
                  "qun": "30",
                  "wt": "kg"
                }
              ]
            },
            {
              "tid": "8Zq3XIqMZMH3vmxi_2",
              "ref": 2,
              "sdur": "04:10",
              "re": "Refundable Before Departure",
              "stop": 0,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "QR",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DOH",
                  "dd": "2023-06-30T17:05:00",
                  "ad": "2023-06-30T18:45:00",
                  "rbd": "M",
                  "fn": "4777",
                  "eq": "320",
                  "ac": "QR",
                  "ma": "QR",
                  "seat": "9",
                  "oa": "6E",
                  "fa": [],
                  "fdur": "04:10",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": ""
                  }
                }
              ],
              "flightNumbers": "4777",
              "bgd": [
                {
                  "qun": "30",
                  "wt": "kg"
                }
              ]
            },
            {
              "tid": "LxD9pR88vlfs0rJo_2",
              "ref": 2,
              "sdur": "04:10",
              "re": "Refundable Before Departure",
              "stop": 0,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "QR",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DOH",
                  "dd": "2023-06-30T04:10:00",
                  "ad": "2023-06-30T05:50:00",
                  "rbd": "M",
                  "fn": "4775",
                  "eq": "320",
                  "ac": "QR",
                  "ma": "QR",
                  "seat": "9",
                  "oa": "6E",
                  "fa": [],
                  "fdur": "04:10",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": ""
                  }
                }
              ],
              "flightNumbers": "4775",
              "bgd": [
                {
                  "qun": "30",
                  "wt": "kg"
                }
              ]
            }
          ],
          "pr": {
            "bf": 58016,
            "tf": 73472,
            "tax": [
              {
                "YQ": 6272
              },
              {
                "YR": 2688
              },
              {
                "OT": 6496
              }
            ],
            "scur": "QAR",
            "ucur": "INR",
            "exchange_rate": 22.4,
            "sup_fare": "2590_3280"
          },
          "paxpr": [
            {
              "tf": 73472,
              "tax": [
                {
                  "YQ": 6272
                },
                {
                  "YR": 2688
                },
                {
                  "OT": 6496
                }
              ],
              "bf": 58016,
              "fbc": "MHQAI1SE",
              "fareType": "RP",
              "pusup_fare": "2590_3280",
              "psup_fare": "58016_73472",
              "ptype": "ADT"
            }
          ],
          "sup": "0",
          "airlinesList": "QR"
        },
        {
          "srcorigns": [
            {
              "tid": "b4CR3lldvwa41Pfa_1",
              "ref": 1,
              "sdur": "04:15",
              "re": "Refundable Before Departure",
              "stop": 0,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "QR",
              "seg": [
                {
                  "ref": 1,
                  "da": "DOH",
                  "ar": "HYD",
                  "dd": "2023-06-29T19:45:00",
                  "ad": "2023-06-30T02:30:00",
                  "rbd": "M",
                  "fn": "4774",
                  "eq": "320",
                  "ac": "QR",
                  "ma": "QR",
                  "seat": "9",
                  "oa": "6E",
                  "fa": [],
                  "fdur": "04:15",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": ""
                  }
                }
              ],
              "flightNumbers": "4774",
              "bgd": [
                {
                  "qun": "30",
                  "wt": "kg"
                }
              ]
            }
          ],
          "destorigins": [
            {
              "tid": "NCo44Gkr67QopOwx_2",
              "ref": 2,
              "sdur": "04:10",
              "re": "Refundable Before Departure",
              "stop": 0,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "QR",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DOH",
                  "dd": "2023-06-30T21:55:00",
                  "ad": "2023-06-30T23:35:00",
                  "rbd": "M",
                  "fn": "4779",
                  "eq": "320",
                  "ac": "QR",
                  "ma": "QR",
                  "seat": "9",
                  "oa": "6E",
                  "fa": [],
                  "fdur": "04:10",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": ""
                  }
                }
              ],
              "flightNumbers": "4779",
              "bgd": [
                {
                  "qun": "30",
                  "wt": "kg"
                }
              ]
            },
            {
              "tid": "b4CR3lldvwa41Pfa_2",
              "ref": 2,
              "sdur": "04:10",
              "re": "Refundable Before Departure",
              "stop": 0,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "QR",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DOH",
                  "dd": "2023-06-30T17:05:00",
                  "ad": "2023-06-30T18:45:00",
                  "rbd": "M",
                  "fn": "4777",
                  "eq": "320",
                  "ac": "QR",
                  "ma": "QR",
                  "seat": "9",
                  "oa": "6E",
                  "fa": [],
                  "fdur": "04:10",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": ""
                  }
                }
              ],
              "flightNumbers": "4777",
              "bgd": [
                {
                  "qun": "30",
                  "wt": "kg"
                }
              ]
            }
          ],
          "pr": {
            "bf": 59808,
            "tf": 75264,
            "tax": [
              {
                "YQ": 6272
              },
              {
                "YR": 2688
              },
              {
                "OT": 6496
              }
            ],
            "scur": "QAR",
            "ucur": "INR",
            "exchange_rate": 22.4,
            "sup_fare": "2670_3360"
          },
          "paxpr": [
            {
              "tf": 75264,
              "tax": [
                {
                  "YQ": 6272
                },
                {
                  "YR": 2688
                },
                {
                  "OT": 6496
                }
              ],
              "bf": 59808,
              "fbc": "MHQAI1SE",
              "fareType": "RP",
              "pusup_fare": "2670_3360",
              "psup_fare": "59808_75264",
              "ptype": "ADT"
            }
          ],
          "sup": "0",
          "airlinesList": "QR"
        },
        {
          "srcorigns": [
            {
              "tid": "eu5XDxVL1EJHQb6o_1",
              "ref": 1,
              "sdur": "04:15",
              "re": "Refundable Before Departure",
              "stop": 0,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "QR",
              "seg": [
                {
                  "ref": 1,
                  "da": "DOH",
                  "ar": "HYD",
                  "dd": "2023-06-29T08:15:00",
                  "ad": "2023-06-29T15:00:00",
                  "rbd": "V",
                  "fn": "4776",
                  "eq": "320",
                  "ac": "QR",
                  "ma": "QR",
                  "seat": "9",
                  "oa": "6E",
                  "fa": [],
                  "fdur": "04:15",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": ""
                  }
                }
              ],
              "flightNumbers": "4776",
              "bgd": [
                {
                  "qun": "30",
                  "wt": "kg"
                }
              ],
              "paxpr": [
                {
                  "tf": 36064,
                  "tax": [
                    {
                      "YQ": 3136
                    },
                    {
                      "YR": 1344
                    },
                    {
                      "OT": 3136
                    }
                  ],
                  "bf": 28448,
                  "fbc": "VHQAI1SI",
                  "fareType": "RP",
                  "pusup_fare": "1270_1610",
                  "psup_fare": "28448_36064",
                  "ptype": "ADT"
                }
              ],
              "pr": {
                "bf": 28448,
                "tf": 36064,
                "tax": [
                  {
                    "YQ": 3136
                  },
                  {
                    "YR": 1344
                  },
                  {
                    "OT": 3136
                  }
                ],
                "scur": "QAR",
                "ucur": "INR",
                "exchange_rate": 22.4,
                "sup_fare": "1270_1610"
              }
            },
            {
              "tid": "50tefIVt11B2ZJT3_1",
              "ref": 1,
              "sdur": "04:15",
              "re": "Refundable Before Departure",
              "stop": 0,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "QR",
              "seg": [
                {
                  "ref": 1,
                  "da": "DOH",
                  "ar": "HYD",
                  "dd": "2023-06-29T02:00:00",
                  "ad": "2023-06-29T08:45:00",
                  "rbd": "V",
                  "fn": "4778",
                  "eq": "320",
                  "ac": "QR",
                  "ma": "QR",
                  "seat": "9",
                  "oa": "6E",
                  "fa": [],
                  "fdur": "04:15",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": ""
                  }
                }
              ],
              "flightNumbers": "4778",
              "bgd": [
                {
                  "qun": "30",
                  "wt": "kg"
                }
              ],
              "paxpr": [
                {
                  "tf": 36064,
                  "tax": [
                    {
                      "YQ": 3136
                    },
                    {
                      "YR": 1344
                    },
                    {
                      "OT": 3136
                    }
                  ],
                  "bf": 28448,
                  "fbc": "VHQAI1SI",
                  "fareType": "RP",
                  "pusup_fare": "1270_1610",
                  "psup_fare": "28448_36064",
                  "ptype": "ADT"
                }
              ],
              "pr": {
                "bf": 28448,
                "tf": 36064,
                "tax": [
                  {
                    "YQ": 3136
                  },
                  {
                    "YR": 1344
                  },
                  {
                    "OT": 3136
                  }
                ],
                "scur": "QAR",
                "ucur": "INR",
                "exchange_rate": 22.4,
                "sup_fare": "1270_1610"
              }
            }
          ],
          "destorigins": [
            {
              "tid": "o4dGVuQJgCyaGMOJ_1",
              "ref": 1,
              "sdur": "04:10",
              "re": "Refundable Before Departure",
              "stop": 0,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "QR",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DOH",
                  "dd": "2023-06-30T21:55:00",
                  "ad": "2023-06-30T23:35:00",
                  "rbd": "N",
                  "fn": "4779",
                  "eq": "320",
                  "ac": "QR",
                  "ma": "QR",
                  "seat": "9",
                  "oa": "6E",
                  "fa": [],
                  "fdur": "04:10",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": ""
                  }
                }
              ],
              "flightNumbers": "4779",
              "bgd": [
                {
                  "qun": "25",
                  "wt": "kg"
                }
              ],
              "paxpr": [
                {
                  "tf": 39200,
                  "tax": [
                    {
                      "YQ": 9856
                    },
                    {
                      "YR": 896
                    },
                    {
                      "OT": 5152
                    }
                  ],
                  "bf": 23296,
                  "fbc": "NLR4I1RQ",
                  "fareType": "RP",
                  "pusup_fare": "1040_1750",
                  "psup_fare": "23296_39200",
                  "ptype": "ADT"
                }
              ],
              "pr": {
                "bf": 23296,
                "tf": 39200,
                "tax": [
                  {
                    "YQ": 9856
                  },
                  {
                    "YR": 896
                  },
                  {
                    "OT": 5152
                  }
                ],
                "scur": "QAR",
                "ucur": "INR",
                "exchange_rate": 22.4,
                "sup_fare": "1040_1750"
              }
            },
            {
              "tid": "LithwaiTgVo0zTSn_1",
              "ref": 1,
              "sdur": "04:10",
              "re": "Refundable Before Departure",
              "stop": 0,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "QR",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DOH",
                  "dd": "2023-06-30T17:05:00",
                  "ad": "2023-06-30T18:45:00",
                  "rbd": "N",
                  "fn": "4777",
                  "eq": "320",
                  "ac": "QR",
                  "ma": "QR",
                  "seat": "9",
                  "oa": "6E",
                  "fa": [],
                  "fdur": "04:10",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": ""
                  }
                }
              ],
              "flightNumbers": "4777",
              "bgd": [
                {
                  "qun": "25",
                  "wt": "kg"
                }
              ],
              "paxpr": [
                {
                  "tf": 39200,
                  "tax": [
                    {
                      "YQ": 9856
                    },
                    {
                      "YR": 896
                    },
                    {
                      "OT": 5152
                    }
                  ],
                  "bf": 23296,
                  "fbc": "NLR4I1RQ",
                  "fareType": "RP",
                  "pusup_fare": "1040_1750",
                  "psup_fare": "23296_39200",
                  "ptype": "ADT"
                }
              ],
              "pr": {
                "bf": 23296,
                "tf": 39200,
                "tax": [
                  {
                    "YQ": 9856
                  },
                  {
                    "YR": 896
                  },
                  {
                    "OT": 5152
                  }
                ],
                "scur": "QAR",
                "ucur": "INR",
                "exchange_rate": 22.4,
                "sup_fare": "1040_1750"
              }
            },
            {
              "tid": "VtTRT4CTTnmiBqt3_1",
              "ref": 1,
              "sdur": "04:10",
              "re": "Refundable Before Departure",
              "stop": 0,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "QR",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DOH",
                  "dd": "2023-06-30T04:10:00",
                  "ad": "2023-06-30T05:50:00",
                  "rbd": "N",
                  "fn": "4775",
                  "eq": "320",
                  "ac": "QR",
                  "ma": "QR",
                  "seat": "9",
                  "oa": "6E",
                  "fa": [],
                  "fdur": "04:10",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": ""
                  }
                }
              ],
              "flightNumbers": "4775",
              "bgd": [
                {
                  "qun": "25",
                  "wt": "kg"
                }
              ],
              "paxpr": [
                {
                  "tf": 39200,
                  "tax": [
                    {
                      "YQ": 9856
                    },
                    {
                      "YR": 896
                    },
                    {
                      "OT": 5152
                    }
                  ],
                  "bf": 23296,
                  "fbc": "NLR4I1RQ",
                  "fareType": "RP",
                  "pusup_fare": "1040_1750",
                  "psup_fare": "23296_39200",
                  "ptype": "ADT"
                }
              ],
              "pr": {
                "bf": 23296,
                "tf": 39200,
                "tax": [
                  {
                    "YQ": 9856
                  },
                  {
                    "YR": 896
                  },
                  {
                    "OT": 5152
                  }
                ],
                "scur": "QAR",
                "ucur": "INR",
                "exchange_rate": 22.4,
                "sup_fare": "1040_1750"
              }
            }
          ],
          "pr": {
            "bf": 51744,
            "tf": 75264,
            "tax": [
              {
                "YQ": 3136
              },
              {
                "YR": 1344
              },
              {
                "OT": 3136
              },
              {
                "YQ": 9856
              },
              {
                "YR": 896
              },
              {
                "OT": 5152
              }
            ]
          },
          "paxpr": [
            {
              "bf": 51744,
              "tf": 75264,
              "tax": [
                {
                  "YQ": 3136
                },
                {
                  "YR": 1344
                },
                {
                  "OT": 3136
                },
                {
                  "YQ": 9856
                },
                {
                  "YR": 896
                },
                {
                  "OT": 5152
                }
              ]
            }
          ],
          "sty": "split",
          "sup": "0/0",
          "airlineList": "6E_6E",
          "mix_carrier": true
        },
        {
          "srcorigns": [
            {
              "tid": "1RSk9SieyUL0ZAPu_1",
              "ref": 1,
              "sdur": "04:15",
              "re": "Refundable Before Departure",
              "stop": 0,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "QR",
              "seg": [
                {
                  "ref": 1,
                  "da": "DOH",
                  "ar": "HYD",
                  "dd": "2023-06-29T19:45:00",
                  "ad": "2023-06-30T02:30:00",
                  "rbd": "V",
                  "fn": "4774",
                  "eq": "320",
                  "ac": "QR",
                  "ma": "QR",
                  "seat": "9",
                  "oa": "6E",
                  "fa": [],
                  "fdur": "04:15",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": ""
                  }
                }
              ],
              "flightNumbers": "4774",
              "bgd": [
                {
                  "qun": "30",
                  "wt": "kg"
                }
              ],
              "paxpr": [
                {
                  "tf": 37856,
                  "tax": [
                    {
                      "YQ": 3136
                    },
                    {
                      "YR": 1344
                    },
                    {
                      "OT": 3136
                    }
                  ],
                  "bf": 30240,
                  "fbc": "VHQAI1SI",
                  "fareType": "RP",
                  "pusup_fare": "1350_1690",
                  "psup_fare": "30240_37856",
                  "ptype": "ADT"
                }
              ],
              "pr": {
                "bf": 30240,
                "tf": 37856,
                "tax": [
                  {
                    "YQ": 3136
                  },
                  {
                    "YR": 1344
                  },
                  {
                    "OT": 3136
                  }
                ],
                "scur": "QAR",
                "ucur": "INR",
                "exchange_rate": 22.4,
                "sup_fare": "1350_1690"
              }
            }
          ],
          "destorigins": [
            {
              "tid": "o4dGVuQJgCyaGMOJ_1",
              "ref": 1,
              "sdur": "04:10",
              "re": "Refundable Before Departure",
              "stop": 0,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "QR",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DOH",
                  "dd": "2023-06-30T21:55:00",
                  "ad": "2023-06-30T23:35:00",
                  "rbd": "N",
                  "fn": "4779",
                  "eq": "320",
                  "ac": "QR",
                  "ma": "QR",
                  "seat": "9",
                  "oa": "6E",
                  "fa": [],
                  "fdur": "04:10",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": ""
                  }
                }
              ],
              "flightNumbers": "4779",
              "bgd": [
                {
                  "qun": "25",
                  "wt": "kg"
                }
              ],
              "paxpr": [
                {
                  "tf": 39200,
                  "tax": [
                    {
                      "YQ": 9856
                    },
                    {
                      "YR": 896
                    },
                    {
                      "OT": 5152
                    }
                  ],
                  "bf": 23296,
                  "fbc": "NLR4I1RQ",
                  "fareType": "RP",
                  "pusup_fare": "1040_1750",
                  "psup_fare": "23296_39200",
                  "ptype": "ADT"
                }
              ],
              "pr": {
                "bf": 23296,
                "tf": 39200,
                "tax": [
                  {
                    "YQ": 9856
                  },
                  {
                    "YR": 896
                  },
                  {
                    "OT": 5152
                  }
                ],
                "scur": "QAR",
                "ucur": "INR",
                "exchange_rate": 22.4,
                "sup_fare": "1040_1750"
              }
            },
            {
              "tid": "LithwaiTgVo0zTSn_1",
              "ref": 1,
              "sdur": "04:10",
              "re": "Refundable Before Departure",
              "stop": 0,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "QR",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DOH",
                  "dd": "2023-06-30T17:05:00",
                  "ad": "2023-06-30T18:45:00",
                  "rbd": "N",
                  "fn": "4777",
                  "eq": "320",
                  "ac": "QR",
                  "ma": "QR",
                  "seat": "9",
                  "oa": "6E",
                  "fa": [],
                  "fdur": "04:10",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": ""
                  }
                }
              ],
              "flightNumbers": "4777",
              "bgd": [
                {
                  "qun": "25",
                  "wt": "kg"
                }
              ],
              "paxpr": [
                {
                  "tf": 39200,
                  "tax": [
                    {
                      "YQ": 9856
                    },
                    {
                      "YR": 896
                    },
                    {
                      "OT": 5152
                    }
                  ],
                  "bf": 23296,
                  "fbc": "NLR4I1RQ",
                  "fareType": "RP",
                  "pusup_fare": "1040_1750",
                  "psup_fare": "23296_39200",
                  "ptype": "ADT"
                }
              ],
              "pr": {
                "bf": 23296,
                "tf": 39200,
                "tax": [
                  {
                    "YQ": 9856
                  },
                  {
                    "YR": 896
                  },
                  {
                    "OT": 5152
                  }
                ],
                "scur": "QAR",
                "ucur": "INR",
                "exchange_rate": 22.4,
                "sup_fare": "1040_1750"
              }
            },
            {
              "tid": "VtTRT4CTTnmiBqt3_1",
              "ref": 1,
              "sdur": "04:10",
              "re": "Refundable Before Departure",
              "stop": 0,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "QR",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DOH",
                  "dd": "2023-06-30T04:10:00",
                  "ad": "2023-06-30T05:50:00",
                  "rbd": "N",
                  "fn": "4775",
                  "eq": "320",
                  "ac": "QR",
                  "ma": "QR",
                  "seat": "9",
                  "oa": "6E",
                  "fa": [],
                  "fdur": "04:10",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": ""
                  }
                }
              ],
              "flightNumbers": "4775",
              "bgd": [
                {
                  "qun": "25",
                  "wt": "kg"
                }
              ],
              "paxpr": [
                {
                  "tf": 39200,
                  "tax": [
                    {
                      "YQ": 9856
                    },
                    {
                      "YR": 896
                    },
                    {
                      "OT": 5152
                    }
                  ],
                  "bf": 23296,
                  "fbc": "NLR4I1RQ",
                  "fareType": "RP",
                  "pusup_fare": "1040_1750",
                  "psup_fare": "23296_39200",
                  "ptype": "ADT"
                }
              ],
              "pr": {
                "bf": 23296,
                "tf": 39200,
                "tax": [
                  {
                    "YQ": 9856
                  },
                  {
                    "YR": 896
                  },
                  {
                    "OT": 5152
                  }
                ],
                "scur": "QAR",
                "ucur": "INR",
                "exchange_rate": 22.4,
                "sup_fare": "1040_1750"
              }
            }
          ],
          "pr": {
            "bf": 53536,
            "tf": 77056,
            "tax": [
              {
                "YQ": 3136
              },
              {
                "YR": 1344
              },
              {
                "OT": 3136
              },
              {
                "YQ": 9856
              },
              {
                "YR": 896
              },
              {
                "OT": 5152
              }
            ]
          },
          "paxpr": [
            {
              "bf": 53536,
              "tf": 77056,
              "tax": [
                {
                  "YQ": 3136
                },
                {
                  "YR": 1344
                },
                {
                  "OT": 3136
                },
                {
                  "YQ": 9856
                },
                {
                  "YR": 896
                },
                {
                  "OT": 5152
                }
              ]
            }
          ],
          "sty": "split",
          "sup": "0/0",
          "airlineList": "6E_6E",
          "mix_carrier": true
        },
        {
          "srcorigns": [
            {
              "tid": "2qbfE1nLQLfVShip_1",
              "ref": 1,
              "sdur": "08:55",
              "re": "Refundable Before Departure",
              "stop": 1,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "EK",
              "seg": [
                {
                  "ref": 1,
                  "da": "DOH",
                  "ar": "DXB",
                  "dd": "2023-06-29T21:00:00",
                  "ad": "2023-06-29T23:15:00",
                  "rbd": "I",
                  "fn": "6",
                  "eq": "7M8",
                  "ac": "FZ",
                  "ma": "FZ",
                  "seat": "4",
                  "oa": "FZ",
                  "fa": [],
                  "fdur": "01:15",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": "2"
                  }
                },
                {
                  "ref": 2,
                  "da": "DXB",
                  "ar": "HYD",
                  "dd": "2023-06-30T03:10:00",
                  "ad": "2023-06-30T08:25:00",
                  "rbd": "R",
                  "fn": "526",
                  "trn": "3",
                  "eq": "77W",
                  "ac": "EK",
                  "ma": "EK",
                  "seat": "9",
                  "oa": "EK",
                  "fa": [],
                  "fdur": "03:45",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "3",
                    "arr": ""
                  }
                }
              ],
              "flightNumbers": "6/526",
              "bgd": [
                {
                  "qun": "35",
                  "wt": "kg"
                }
              ],
              "paxpr": [
                {
                  "tf": 107072,
                  "tax": [
                    {
                      "YQ": 8512
                    },
                    {
                      "OT": 4256
                    }
                  ],
                  "bf": 94304,
                  "fbc": "RHOWFQA1",
                  "fareType": "RP",
                  "pusup_fare": "4210_4780",
                  "psup_fare": "94304_107072",
                  "ptype": "ADT"
                }
              ],
              "pr": {
                "bf": 94304,
                "tf": 107072,
                "tax": [
                  {
                    "YQ": 8512
                  },
                  {
                    "OT": 4256
                  }
                ],
                "scur": "QAR",
                "ucur": "INR",
                "exchange_rate": 22.4,
                "sup_fare": "4210_4780"
              }
            },
            {
              "tid": "kJPqCCgcmPQpFQbv_1",
              "ref": 1,
              "sdur": "07:35",
              "re": "Refundable Before Departure",
              "stop": 1,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "EK",
              "seg": [
                {
                  "ref": 1,
                  "da": "DOH",
                  "ar": "DXB",
                  "dd": "2023-06-29T17:00:00",
                  "ad": "2023-06-29T19:15:00",
                  "rbd": "I",
                  "fn": "18",
                  "eq": "7M8",
                  "ac": "FZ",
                  "ma": "FZ",
                  "seat": "4",
                  "oa": "FZ",
                  "fa": [],
                  "fdur": "01:15",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": "2"
                  }
                },
                {
                  "ref": 2,
                  "da": "DXB",
                  "ar": "HYD",
                  "dd": "2023-06-29T22:00:00",
                  "ad": "2023-06-30T03:05:00",
                  "rbd": "R",
                  "fn": "524",
                  "trn": "3",
                  "eq": "77W",
                  "ac": "EK",
                  "ma": "EK",
                  "seat": "9",
                  "oa": "EK",
                  "fa": [],
                  "fdur": "03:35",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "3",
                    "arr": ""
                  }
                }
              ],
              "flightNumbers": "18/524",
              "bgd": [
                {
                  "qun": "35",
                  "wt": "kg"
                }
              ],
              "paxpr": [
                {
                  "tf": 107072,
                  "tax": [
                    {
                      "YQ": 8512
                    },
                    {
                      "OT": 4256
                    }
                  ],
                  "bf": 94304,
                  "fbc": "RHOWFQA1",
                  "fareType": "RP",
                  "pusup_fare": "4210_4780",
                  "psup_fare": "94304_107072",
                  "ptype": "ADT"
                }
              ],
              "pr": {
                "bf": 94304,
                "tf": 107072,
                "tax": [
                  {
                    "YQ": 8512
                  },
                  {
                    "OT": 4256
                  }
                ],
                "scur": "QAR",
                "ucur": "INR",
                "exchange_rate": 22.4,
                "sup_fare": "4210_4780"
              }
            },
            {
              "tid": "JuWgWGhTrV9sFgNX_1",
              "ref": 1,
              "sdur": "12:55",
              "re": "Refundable Before Departure",
              "stop": 1,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "EK",
              "seg": [
                {
                  "ref": 1,
                  "da": "DOH",
                  "ar": "DXB",
                  "dd": "2023-06-29T17:00:00",
                  "ad": "2023-06-29T19:15:00",
                  "rbd": "I",
                  "fn": "18",
                  "eq": "7M8",
                  "ac": "FZ",
                  "ma": "FZ",
                  "seat": "4",
                  "oa": "FZ",
                  "fa": [],
                  "fdur": "01:15",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": "2"
                  }
                },
                {
                  "ref": 2,
                  "da": "DXB",
                  "ar": "HYD",
                  "dd": "2023-06-30T03:10:00",
                  "ad": "2023-06-30T08:25:00",
                  "rbd": "R",
                  "fn": "526",
                  "trn": "3",
                  "eq": "77W",
                  "ac": "EK",
                  "ma": "EK",
                  "seat": "9",
                  "oa": "EK",
                  "fa": [],
                  "fdur": "03:45",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "3",
                    "arr": ""
                  }
                }
              ],
              "flightNumbers": "18/526",
              "bgd": [
                {
                  "qun": "35",
                  "wt": "kg"
                }
              ],
              "paxpr": [
                {
                  "tf": 107072,
                  "tax": [
                    {
                      "YQ": 8512
                    },
                    {
                      "OT": 4256
                    }
                  ],
                  "bf": 94304,
                  "fbc": "RHOWFQA1",
                  "fareType": "RP",
                  "pusup_fare": "4210_4780",
                  "psup_fare": "94304_107072",
                  "ptype": "ADT"
                }
              ],
              "pr": {
                "bf": 94304,
                "tf": 107072,
                "tax": [
                  {
                    "YQ": 8512
                  },
                  {
                    "OT": 4256
                  }
                ],
                "scur": "QAR",
                "ucur": "INR",
                "exchange_rate": 22.4,
                "sup_fare": "4210_4780"
              }
            },
            {
              "tid": "620D0bxh9FgS6Tyc_1",
              "ref": 1,
              "sdur": "07:45",
              "re": "Refundable Before Departure",
              "stop": 1,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "EK",
              "seg": [
                {
                  "ref": 1,
                  "da": "DOH",
                  "ar": "DXB",
                  "dd": "2023-06-29T10:00:00",
                  "ad": "2023-06-29T12:15:00",
                  "rbd": "I",
                  "fn": "2",
                  "eq": "7M8",
                  "ac": "FZ",
                  "ma": "FZ",
                  "seat": "4",
                  "oa": "FZ",
                  "fa": [],
                  "fdur": "01:15",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": "2"
                  }
                },
                {
                  "ref": 2,
                  "da": "DXB",
                  "ar": "HYD",
                  "dd": "2023-06-29T15:05:00",
                  "ad": "2023-06-29T20:15:00",
                  "rbd": "R",
                  "fn": "528",
                  "trn": "3",
                  "eq": "77W",
                  "ac": "EK",
                  "ma": "EK",
                  "seat": "9",
                  "oa": "EK",
                  "fa": [],
                  "fdur": "03:40",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "3",
                    "arr": ""
                  }
                }
              ],
              "flightNumbers": "2/528",
              "bgd": [
                {
                  "qun": "35",
                  "wt": "kg"
                }
              ],
              "paxpr": [
                {
                  "tf": 107072,
                  "tax": [
                    {
                      "YQ": 8512
                    },
                    {
                      "OT": 4256
                    }
                  ],
                  "bf": 94304,
                  "fbc": "RHOWFQA1",
                  "fareType": "RP",
                  "pusup_fare": "4210_4780",
                  "psup_fare": "94304_107072",
                  "ptype": "ADT"
                }
              ],
              "pr": {
                "bf": 94304,
                "tf": 107072,
                "tax": [
                  {
                    "YQ": 8512
                  },
                  {
                    "OT": 4256
                  }
                ],
                "scur": "QAR",
                "ucur": "INR",
                "exchange_rate": 22.4,
                "sup_fare": "4210_4780"
              }
            },
            {
              "tid": "VCsXhWnrHwpO1fzr_1",
              "ref": 1,
              "sdur": "14:35",
              "re": "Refundable Before Departure",
              "stop": 1,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "EK",
              "seg": [
                {
                  "ref": 1,
                  "da": "DOH",
                  "ar": "DXB",
                  "dd": "2023-06-29T10:00:00",
                  "ad": "2023-06-29T12:15:00",
                  "rbd": "I",
                  "fn": "2",
                  "eq": "7M8",
                  "ac": "FZ",
                  "ma": "FZ",
                  "seat": "4",
                  "oa": "FZ",
                  "fa": [],
                  "fdur": "01:15",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": "2"
                  }
                },
                {
                  "ref": 2,
                  "da": "DXB",
                  "ar": "HYD",
                  "dd": "2023-06-29T22:00:00",
                  "ad": "2023-06-30T03:05:00",
                  "rbd": "R",
                  "fn": "524",
                  "trn": "3",
                  "eq": "77W",
                  "ac": "EK",
                  "ma": "EK",
                  "seat": "9",
                  "oa": "EK",
                  "fa": [],
                  "fdur": "03:35",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "3",
                    "arr": ""
                  }
                }
              ],
              "flightNumbers": "2/524",
              "bgd": [
                {
                  "qun": "35",
                  "wt": "kg"
                }
              ],
              "paxpr": [
                {
                  "tf": 107072,
                  "tax": [
                    {
                      "YQ": 8512
                    },
                    {
                      "OT": 4256
                    }
                  ],
                  "bf": 94304,
                  "fbc": "RHOWFQA1",
                  "fareType": "RP",
                  "pusup_fare": "4210_4780",
                  "psup_fare": "94304_107072",
                  "ptype": "ADT"
                }
              ],
              "pr": {
                "bf": 94304,
                "tf": 107072,
                "tax": [
                  {
                    "YQ": 8512
                  },
                  {
                    "OT": 4256
                  }
                ],
                "scur": "QAR",
                "ucur": "INR",
                "exchange_rate": 22.4,
                "sup_fare": "4210_4780"
              }
            },
            {
              "tid": "kKqoT4KLo5DG4794_1",
              "ref": 1,
              "sdur": "19:55",
              "re": "Refundable Before Departure",
              "stop": 1,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "EK",
              "seg": [
                {
                  "ref": 1,
                  "da": "DOH",
                  "ar": "DXB",
                  "dd": "2023-06-29T10:00:00",
                  "ad": "2023-06-29T12:15:00",
                  "rbd": "I",
                  "fn": "2",
                  "eq": "7M8",
                  "ac": "FZ",
                  "ma": "FZ",
                  "seat": "4",
                  "oa": "FZ",
                  "fa": [],
                  "fdur": "01:15",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": "2"
                  }
                },
                {
                  "ref": 2,
                  "da": "DXB",
                  "ar": "HYD",
                  "dd": "2023-06-30T03:10:00",
                  "ad": "2023-06-30T08:25:00",
                  "rbd": "R",
                  "fn": "526",
                  "trn": "3",
                  "eq": "77W",
                  "ac": "EK",
                  "ma": "EK",
                  "seat": "9",
                  "oa": "EK",
                  "fa": [],
                  "fdur": "03:45",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "3",
                    "arr": ""
                  }
                }
              ],
              "flightNumbers": "2/526",
              "bgd": [
                {
                  "qun": "35",
                  "wt": "kg"
                }
              ],
              "paxpr": [
                {
                  "tf": 107072,
                  "tax": [
                    {
                      "YQ": 8512
                    },
                    {
                      "OT": 4256
                    }
                  ],
                  "bf": 94304,
                  "fbc": "RHOWFQA1",
                  "fareType": "RP",
                  "pusup_fare": "4210_4780",
                  "psup_fare": "94304_107072",
                  "ptype": "ADT"
                }
              ],
              "pr": {
                "bf": 94304,
                "tf": 107072,
                "tax": [
                  {
                    "YQ": 8512
                  },
                  {
                    "OT": 4256
                  }
                ],
                "scur": "QAR",
                "ucur": "INR",
                "exchange_rate": 22.4,
                "sup_fare": "4210_4780"
              }
            }
          ],
          "destorigins": [
            {
              "tid": "PLBA29xGTttzyO4y_1",
              "ref": 1,
              "sdur": "09:25",
              "re": "Refundable Before Departure",
              "stop": 1,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "AI",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DEL",
                  "dd": "2023-06-30T14:30:00",
                  "ad": "2023-06-30T16:55:00",
                  "rbd": "Q",
                  "fn": "523",
                  "eq": "319",
                  "ac": "AI",
                  "ma": "AI",
                  "seat": "1",
                  "oa": "AI",
                  "fa": [],
                  "fdur": "02:25",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": "3"
                  }
                },
                {
                  "ref": 2,
                  "da": "DEL",
                  "ar": "DOH",
                  "dd": "2023-06-30T19:50:00",
                  "ad": "2023-06-30T21:25:00",
                  "rbd": "U",
                  "fn": "971",
                  "trn": "3",
                  "eq": "32N",
                  "ac": "AI",
                  "ma": "AI",
                  "seat": "2",
                  "oa": "AI",
                  "fa": [],
                  "fdur": "04:05",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "3",
                    "arr": ""
                  }
                }
              ],
              "flightNumbers": "523/971",
              "bgd": [
                {
                  "qun": "30",
                  "wt": "kg"
                }
              ],
              "paxpr": [
                {
                  "tf": 16576,
                  "tax": [
                    {
                      "YR": 448
                    },
                    {
                      "YR": 224
                    },
                    {
                      "OT": 4032
                    }
                  ],
                  "bf": 11872,
                  "fbc": "UOWDLDH",
                  "fareType": "RP",
                  "pusup_fare": "530_740",
                  "psup_fare": "11872_16576",
                  "ptype": "ADT"
                }
              ],
              "pr": {
                "bf": 11872,
                "tf": 16576,
                "tax": [
                  {
                    "YR": 448
                  },
                  {
                    "YR": 224
                  },
                  {
                    "OT": 4032
                  }
                ],
                "scur": "QAR",
                "ucur": "INR",
                "exchange_rate": 22.4,
                "sup_fare": "530_740"
              }
            }
          ],
          "pr": {
            "bf": 106176,
            "tf": 123648,
            "tax": [
              {
                "YQ": 8512
              },
              {
                "OT": 4256
              },
              {
                "YR": 448
              },
              {
                "YR": 224
              },
              {
                "OT": 4032
              }
            ]
          },
          "paxpr": [
            {
              "bf": 106176,
              "tf": 123648,
              "tax": [
                {
                  "YQ": 8512
                },
                {
                  "OT": 4256
                },
                {
                  "YR": 448
                },
                {
                  "YR": 224
                },
                {
                  "OT": 4032
                }
              ]
            }
          ],
          "sty": "split",
          "sup": "0/0",
          "airlineList": "FZ_AI",
          "mix_carrier": true
        },
        {
          "srcorigns": [
            {
              "tid": "2qbfE1nLQLfVShip_1",
              "ref": 1,
              "sdur": "08:55",
              "re": "Refundable Before Departure",
              "stop": 1,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "EK",
              "seg": [
                {
                  "ref": 1,
                  "da": "DOH",
                  "ar": "DXB",
                  "dd": "2023-06-29T21:00:00",
                  "ad": "2023-06-29T23:15:00",
                  "rbd": "I",
                  "fn": "6",
                  "eq": "7M8",
                  "ac": "FZ",
                  "ma": "FZ",
                  "seat": "4",
                  "oa": "FZ",
                  "fa": [],
                  "fdur": "01:15",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": "2"
                  }
                },
                {
                  "ref": 2,
                  "da": "DXB",
                  "ar": "HYD",
                  "dd": "2023-06-30T03:10:00",
                  "ad": "2023-06-30T08:25:00",
                  "rbd": "R",
                  "fn": "526",
                  "trn": "3",
                  "eq": "77W",
                  "ac": "EK",
                  "ma": "EK",
                  "seat": "9",
                  "oa": "EK",
                  "fa": [],
                  "fdur": "03:45",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "3",
                    "arr": ""
                  }
                }
              ],
              "flightNumbers": "6/526",
              "bgd": [
                {
                  "qun": "35",
                  "wt": "kg"
                }
              ],
              "paxpr": [
                {
                  "tf": 107072,
                  "tax": [
                    {
                      "YQ": 8512
                    },
                    {
                      "OT": 4256
                    }
                  ],
                  "bf": 94304,
                  "fbc": "RHOWFQA1",
                  "fareType": "RP",
                  "pusup_fare": "4210_4780",
                  "psup_fare": "94304_107072",
                  "ptype": "ADT"
                }
              ],
              "pr": {
                "bf": 94304,
                "tf": 107072,
                "tax": [
                  {
                    "YQ": 8512
                  },
                  {
                    "OT": 4256
                  }
                ],
                "scur": "QAR",
                "ucur": "INR",
                "exchange_rate": 22.4,
                "sup_fare": "4210_4780"
              }
            },
            {
              "tid": "kJPqCCgcmPQpFQbv_1",
              "ref": 1,
              "sdur": "07:35",
              "re": "Refundable Before Departure",
              "stop": 1,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "EK",
              "seg": [
                {
                  "ref": 1,
                  "da": "DOH",
                  "ar": "DXB",
                  "dd": "2023-06-29T17:00:00",
                  "ad": "2023-06-29T19:15:00",
                  "rbd": "I",
                  "fn": "18",
                  "eq": "7M8",
                  "ac": "FZ",
                  "ma": "FZ",
                  "seat": "4",
                  "oa": "FZ",
                  "fa": [],
                  "fdur": "01:15",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": "2"
                  }
                },
                {
                  "ref": 2,
                  "da": "DXB",
                  "ar": "HYD",
                  "dd": "2023-06-29T22:00:00",
                  "ad": "2023-06-30T03:05:00",
                  "rbd": "R",
                  "fn": "524",
                  "trn": "3",
                  "eq": "77W",
                  "ac": "EK",
                  "ma": "EK",
                  "seat": "9",
                  "oa": "EK",
                  "fa": [],
                  "fdur": "03:35",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "3",
                    "arr": ""
                  }
                }
              ],
              "flightNumbers": "18/524",
              "bgd": [
                {
                  "qun": "35",
                  "wt": "kg"
                }
              ],
              "paxpr": [
                {
                  "tf": 107072,
                  "tax": [
                    {
                      "YQ": 8512
                    },
                    {
                      "OT": 4256
                    }
                  ],
                  "bf": 94304,
                  "fbc": "RHOWFQA1",
                  "fareType": "RP",
                  "pusup_fare": "4210_4780",
                  "psup_fare": "94304_107072",
                  "ptype": "ADT"
                }
              ],
              "pr": {
                "bf": 94304,
                "tf": 107072,
                "tax": [
                  {
                    "YQ": 8512
                  },
                  {
                    "OT": 4256
                  }
                ],
                "scur": "QAR",
                "ucur": "INR",
                "exchange_rate": 22.4,
                "sup_fare": "4210_4780"
              }
            },
            {
              "tid": "JuWgWGhTrV9sFgNX_1",
              "ref": 1,
              "sdur": "12:55",
              "re": "Refundable Before Departure",
              "stop": 1,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "EK",
              "seg": [
                {
                  "ref": 1,
                  "da": "DOH",
                  "ar": "DXB",
                  "dd": "2023-06-29T17:00:00",
                  "ad": "2023-06-29T19:15:00",
                  "rbd": "I",
                  "fn": "18",
                  "eq": "7M8",
                  "ac": "FZ",
                  "ma": "FZ",
                  "seat": "4",
                  "oa": "FZ",
                  "fa": [],
                  "fdur": "01:15",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": "2"
                  }
                },
                {
                  "ref": 2,
                  "da": "DXB",
                  "ar": "HYD",
                  "dd": "2023-06-30T03:10:00",
                  "ad": "2023-06-30T08:25:00",
                  "rbd": "R",
                  "fn": "526",
                  "trn": "3",
                  "eq": "77W",
                  "ac": "EK",
                  "ma": "EK",
                  "seat": "9",
                  "oa": "EK",
                  "fa": [],
                  "fdur": "03:45",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "3",
                    "arr": ""
                  }
                }
              ],
              "flightNumbers": "18/526",
              "bgd": [
                {
                  "qun": "35",
                  "wt": "kg"
                }
              ],
              "paxpr": [
                {
                  "tf": 107072,
                  "tax": [
                    {
                      "YQ": 8512
                    },
                    {
                      "OT": 4256
                    }
                  ],
                  "bf": 94304,
                  "fbc": "RHOWFQA1",
                  "fareType": "RP",
                  "pusup_fare": "4210_4780",
                  "psup_fare": "94304_107072",
                  "ptype": "ADT"
                }
              ],
              "pr": {
                "bf": 94304,
                "tf": 107072,
                "tax": [
                  {
                    "YQ": 8512
                  },
                  {
                    "OT": 4256
                  }
                ],
                "scur": "QAR",
                "ucur": "INR",
                "exchange_rate": 22.4,
                "sup_fare": "4210_4780"
              }
            },
            {
              "tid": "620D0bxh9FgS6Tyc_1",
              "ref": 1,
              "sdur": "07:45",
              "re": "Refundable Before Departure",
              "stop": 1,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "EK",
              "seg": [
                {
                  "ref": 1,
                  "da": "DOH",
                  "ar": "DXB",
                  "dd": "2023-06-29T10:00:00",
                  "ad": "2023-06-29T12:15:00",
                  "rbd": "I",
                  "fn": "2",
                  "eq": "7M8",
                  "ac": "FZ",
                  "ma": "FZ",
                  "seat": "4",
                  "oa": "FZ",
                  "fa": [],
                  "fdur": "01:15",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": "2"
                  }
                },
                {
                  "ref": 2,
                  "da": "DXB",
                  "ar": "HYD",
                  "dd": "2023-06-29T15:05:00",
                  "ad": "2023-06-29T20:15:00",
                  "rbd": "R",
                  "fn": "528",
                  "trn": "3",
                  "eq": "77W",
                  "ac": "EK",
                  "ma": "EK",
                  "seat": "9",
                  "oa": "EK",
                  "fa": [],
                  "fdur": "03:40",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "3",
                    "arr": ""
                  }
                }
              ],
              "flightNumbers": "2/528",
              "bgd": [
                {
                  "qun": "35",
                  "wt": "kg"
                }
              ],
              "paxpr": [
                {
                  "tf": 107072,
                  "tax": [
                    {
                      "YQ": 8512
                    },
                    {
                      "OT": 4256
                    }
                  ],
                  "bf": 94304,
                  "fbc": "RHOWFQA1",
                  "fareType": "RP",
                  "pusup_fare": "4210_4780",
                  "psup_fare": "94304_107072",
                  "ptype": "ADT"
                }
              ],
              "pr": {
                "bf": 94304,
                "tf": 107072,
                "tax": [
                  {
                    "YQ": 8512
                  },
                  {
                    "OT": 4256
                  }
                ],
                "scur": "QAR",
                "ucur": "INR",
                "exchange_rate": 22.4,
                "sup_fare": "4210_4780"
              }
            },
            {
              "tid": "VCsXhWnrHwpO1fzr_1",
              "ref": 1,
              "sdur": "14:35",
              "re": "Refundable Before Departure",
              "stop": 1,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "EK",
              "seg": [
                {
                  "ref": 1,
                  "da": "DOH",
                  "ar": "DXB",
                  "dd": "2023-06-29T10:00:00",
                  "ad": "2023-06-29T12:15:00",
                  "rbd": "I",
                  "fn": "2",
                  "eq": "7M8",
                  "ac": "FZ",
                  "ma": "FZ",
                  "seat": "4",
                  "oa": "FZ",
                  "fa": [],
                  "fdur": "01:15",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": "2"
                  }
                },
                {
                  "ref": 2,
                  "da": "DXB",
                  "ar": "HYD",
                  "dd": "2023-06-29T22:00:00",
                  "ad": "2023-06-30T03:05:00",
                  "rbd": "R",
                  "fn": "524",
                  "trn": "3",
                  "eq": "77W",
                  "ac": "EK",
                  "ma": "EK",
                  "seat": "9",
                  "oa": "EK",
                  "fa": [],
                  "fdur": "03:35",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "3",
                    "arr": ""
                  }
                }
              ],
              "flightNumbers": "2/524",
              "bgd": [
                {
                  "qun": "35",
                  "wt": "kg"
                }
              ],
              "paxpr": [
                {
                  "tf": 107072,
                  "tax": [
                    {
                      "YQ": 8512
                    },
                    {
                      "OT": 4256
                    }
                  ],
                  "bf": 94304,
                  "fbc": "RHOWFQA1",
                  "fareType": "RP",
                  "pusup_fare": "4210_4780",
                  "psup_fare": "94304_107072",
                  "ptype": "ADT"
                }
              ],
              "pr": {
                "bf": 94304,
                "tf": 107072,
                "tax": [
                  {
                    "YQ": 8512
                  },
                  {
                    "OT": 4256
                  }
                ],
                "scur": "QAR",
                "ucur": "INR",
                "exchange_rate": 22.4,
                "sup_fare": "4210_4780"
              }
            },
            {
              "tid": "kKqoT4KLo5DG4794_1",
              "ref": 1,
              "sdur": "19:55",
              "re": "Refundable Before Departure",
              "stop": 1,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "EK",
              "seg": [
                {
                  "ref": 1,
                  "da": "DOH",
                  "ar": "DXB",
                  "dd": "2023-06-29T10:00:00",
                  "ad": "2023-06-29T12:15:00",
                  "rbd": "I",
                  "fn": "2",
                  "eq": "7M8",
                  "ac": "FZ",
                  "ma": "FZ",
                  "seat": "4",
                  "oa": "FZ",
                  "fa": [],
                  "fdur": "01:15",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": "2"
                  }
                },
                {
                  "ref": 2,
                  "da": "DXB",
                  "ar": "HYD",
                  "dd": "2023-06-30T03:10:00",
                  "ad": "2023-06-30T08:25:00",
                  "rbd": "R",
                  "fn": "526",
                  "trn": "3",
                  "eq": "77W",
                  "ac": "EK",
                  "ma": "EK",
                  "seat": "9",
                  "oa": "EK",
                  "fa": [],
                  "fdur": "03:45",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "3",
                    "arr": ""
                  }
                }
              ],
              "flightNumbers": "2/526",
              "bgd": [
                {
                  "qun": "35",
                  "wt": "kg"
                }
              ],
              "paxpr": [
                {
                  "tf": 107072,
                  "tax": [
                    {
                      "YQ": 8512
                    },
                    {
                      "OT": 4256
                    }
                  ],
                  "bf": 94304,
                  "fbc": "RHOWFQA1",
                  "fareType": "RP",
                  "pusup_fare": "4210_4780",
                  "psup_fare": "94304_107072",
                  "ptype": "ADT"
                }
              ],
              "pr": {
                "bf": 94304,
                "tf": 107072,
                "tax": [
                  {
                    "YQ": 8512
                  },
                  {
                    "OT": 4256
                  }
                ],
                "scur": "QAR",
                "ucur": "INR",
                "exchange_rate": 22.4,
                "sup_fare": "4210_4780"
              }
            }
          ],
          "destorigins": [
            {
              "tid": "MUS2TLIH8VyQcv3C_1",
              "ref": 1,
              "ofcid": "",
              "sdur": "4:40",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DOH",
                  "dd": "2023-06-30T04:10:00",
                  "ad": "2023-06-30T05:50:00",
                  "rbd": "",
                  "fn": "1313",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {},
                  "rph": "",
                  "jsell": "6E~1313~ ~~HYD~06/30/2023 04:10~DOH~06/30/2023 05:50~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~A~ ~6E~A0INT~5000~~0~2~~X",
                  "pr": {
                    "bf": 26311,
                    "tf": 31534,
                    "tax": [
                      {
                        "OT": 5223
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "26311_31534",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "paxpr": [
                    {
                      "bf": 26311,
                      "tf": 31534,
                      "tax": [
                        {
                          "OT": 5223
                        }
                      ],
                      "fbc": "A0INT",
                      "ptype": "ADT",
                      "scur": "INR",
                      "sup_fare": "26311_31534"
                    }
                  ],
                  "routeId": "12r3muxt5lj",
                  "productClass": "R",
                  "ruleNumber": "5000"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "Q2c3c3fETtY=|A6KkIU40nZ4asiRAAWjEJn2YrIKBv2dQgqCTOYx11mnNyuaFYnXhwI5GuIilBz4V/oaFsdOsaNpO2v1OoLoyDKxVlrz4az4ufHZUNCPPoEmLz/Jk3DD2LYWM4DcZOuhPrHhGYGKemHY=",
              "stop": 0,
              "paxpr": [
                {
                  "bf": 26311,
                  "tf": 31534,
                  "fbc": "",
                  "psup_fare": "",
                  "tax": [
                    [
                      {
                        "OT": 5223
                      }
                    ]
                  ],
                  "ptype": "ADT",
                  "scur": "INR",
                  "sup_cur_fare": ""
                }
              ],
              "pr": {
                "bf": 26311,
                "tf": 31534,
                "tax": [
                  {
                    "OT": 5223
                  }
                ],
                "scur": "INR",
                "sup_fare": "",
                "sup_cur_fare": "",
                "cabb": "",
                "chekb": "",
                "seats": "",
                "meals": "",
                "chanb": "",
                "ftyp": ""
              }
            },
            {
              "tid": "M9ZZHyNdeQxDFR68_1",
              "ref": 1,
              "ofcid": "",
              "sdur": "4:40",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DOH",
                  "dd": "2023-06-30T17:05:00",
                  "ad": "2023-06-30T18:45:00",
                  "rbd": "",
                  "fn": "1315",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {},
                  "rph": "",
                  "jsell": "6E~1315~ ~~HYD~06/30/2023 17:05~DOH~06/30/2023 18:45~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~A~ ~6E~A0INT~5000~~0~2~~X",
                  "pr": {
                    "bf": 26311,
                    "tf": 31534,
                    "tax": [
                      {
                        "OT": 5223
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "26311_31534",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "paxpr": [
                    {
                      "bf": 26311,
                      "tf": 31534,
                      "tax": [
                        {
                          "OT": 5223
                        }
                      ],
                      "fbc": "A0INT",
                      "ptype": "ADT",
                      "scur": "INR",
                      "sup_fare": "26311_31534"
                    }
                  ],
                  "routeId": "by2fv3b5vl",
                  "productClass": "R",
                  "ruleNumber": "5000"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "Q2c3c3fETtY=|A6KkIU40nZ4asiRAAWjEJn2YrIKBv2dQgqCTOYx11mnNyuaFYnXhwI5GuIilBz4V/oaFsdOsaNpO2v1OoLoyDKxVlrz4az4ufHZUNCPPoEmLz/Jk3DD2LYWM4DcZOuhPrHhGYGKemHY=",
              "stop": 0,
              "paxpr": [
                {
                  "bf": 26311,
                  "tf": 31534,
                  "fbc": "",
                  "psup_fare": "",
                  "tax": [
                    [
                      {
                        "OT": 5223
                      }
                    ]
                  ],
                  "ptype": "ADT",
                  "scur": "INR",
                  "sup_cur_fare": ""
                }
              ],
              "pr": {
                "bf": 26311,
                "tf": 31534,
                "tax": [
                  {
                    "OT": 5223
                  }
                ],
                "scur": "INR",
                "sup_fare": "",
                "sup_cur_fare": "",
                "cabb": "",
                "chekb": "",
                "seats": "",
                "meals": "",
                "chanb": "",
                "ftyp": ""
              }
            },
            {
              "tid": "V8CRhFZrBR0MrXE1_1",
              "ref": 1,
              "ofcid": "",
              "sdur": "4:40",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DOH",
                  "dd": "2023-06-30T21:55:00",
                  "ad": "2023-06-30T23:35:00",
                  "rbd": "",
                  "fn": "1317",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {},
                  "rph": "",
                  "jsell": "6E~1317~ ~~HYD~06/30/2023 21:55~DOH~06/30/2023 23:35~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~A~ ~6E~A0INT~5000~~0~2~~X",
                  "pr": {
                    "bf": 26311,
                    "tf": 31534,
                    "tax": [
                      {
                        "OT": 5223
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "26311_31534",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "paxpr": [
                    {
                      "bf": 26311,
                      "tf": 31534,
                      "tax": [
                        {
                          "OT": 5223
                        }
                      ],
                      "fbc": "A0INT",
                      "ptype": "ADT",
                      "scur": "INR",
                      "sup_fare": "26311_31534"
                    }
                  ],
                  "routeId": "59fmlda90s",
                  "productClass": "R",
                  "ruleNumber": "5000"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "Q2c3c3fETtY=|A6KkIU40nZ4asiRAAWjEJn2YrIKBv2dQgqCTOYx11mnNyuaFYnXhwI5GuIilBz4V/oaFsdOsaNpO2v1OoLoyDKxVlrz4az4ufHZUNCPPoEmLz/Jk3DD2LYWM4DcZOuhPrHhGYGKemHY=",
              "stop": 0,
              "paxpr": [
                {
                  "bf": 26311,
                  "tf": 31534,
                  "fbc": "",
                  "psup_fare": "",
                  "tax": [
                    [
                      {
                        "OT": 5223
                      }
                    ]
                  ],
                  "ptype": "ADT",
                  "scur": "INR",
                  "sup_cur_fare": ""
                }
              ],
              "pr": {
                "bf": 26311,
                "tf": 31534,
                "tax": [
                  {
                    "OT": 5223
                  }
                ],
                "scur": "INR",
                "sup_fare": "",
                "sup_cur_fare": "",
                "cabb": "",
                "chekb": "",
                "seats": "",
                "meals": "",
                "chanb": "",
                "ftyp": ""
              }
            }
          ],
          "pr": {
            "bf": 120615,
            "tf": 138606,
            "tax": [
              {
                "YQ": 8512
              },
              {
                "OT": 4256
              },
              {
                "OT": 5223
              }
            ]
          },
          "paxpr": [
            {
              "bf": 120615,
              "tf": 138606,
              "tax": [
                {
                  "YQ": 8512
                },
                {
                  "OT": 4256
                },
                [
                  {
                    "OT": 5223
                  }
                ]
              ]
            }
          ],
          "sty": "split",
          "sup": "0/4",
          "airlineList": "FZ_6E",
          "mix_carrier": true
        },
        {
          "srcorigns": [
            {
              "tid": "4fx2rHrt8eiu6dlO_1",
              "ref": 1,
              "ofcid": "",
              "sdur": "08:15",
              "re": "",
              "sup": "3",
              "ft": "",
              "cl": "Economy",
              "tecst": "no",
              "tktair": "J9",
              "seg": [
                {
                  "ref": 1,
                  "da": "DOH",
                  "ar": "KWI",
                  "dd": "2023-06-29T15:15:00",
                  "ad": "2023-06-29T16:40:00",
                  "rbd": "",
                  "fn": "116",
                  "eq": "",
                  "ac": "J9",
                  "ma": "J9",
                  "seat": "",
                  "oa": "J9",
                  "fa": [],
                  "segmentKey": "Sjl_IDExNn4gfn5ET0h_MDYvMjkvMjAyMyAxNToxNX5LV0l_MDYvMjkvMjAyMyAxNjo0MH5_",
                  "terminal": {
                    "dep": null,
                    "arr": "5"
                  },
                  "durationHrs": "1",
                  "durationMns": "25",
                  "cl": "Economy"
                },
                {
                  "ref": 2,
                  "da": "KWI",
                  "ar": "HYD",
                  "dd": "2023-06-29T18:45:00",
                  "ad": "2023-06-30T02:00:00",
                  "rbd": "",
                  "fn": "403",
                  "eq": "",
                  "ac": "J9",
                  "ma": "J9",
                  "seat": "",
                  "oa": "J9",
                  "fa": [],
                  "segmentKey": "Sjl_IDQwM34gfn5LV0l_MDYvMjkvMjAyMyAxODo0NX5IWUR_MDYvMzAvMjAyMyAwMjowMH5_",
                  "terminal": {
                    "dep": "5",
                    "arr": null
                  },
                  "durationHrs": "7",
                  "durationMns": "15",
                  "cl": "Economy"
                }
              ],
              "fareop": [
                {
                  "pr": {
                    "tf": 9623.04,
                    "bf": 7273.28,
                    "tax": [
                      {
                        "OT": 2349.760000000001
                      }
                    ],
                    "scur": "QAR",
                    "sup_fare": "7273.28_9623.04",
                    "sup_cur_fare": "324.7_104.9_429.6",
                    "ucur": "INR",
                    "exchange_rate": 22.4,
                    "ftyp": "Light",
                    "cabb": "NIL",
                    "chekb": "7 KG/1 PIECE ",
                    "meals": "For a Fee",
                    "puBag": "Available",
                    "puBag_info": "Only available at Check-in",
                    "chanb": "Available up to 8 hours prior to\n            scheduled departure.",
                    "chanb_info": "Online: INR 5409 (or equivalent) fee\n            per sector per Guest up to 24 hours of\n            flight departure.\n             Online: INR 4868 (or equivalent) fee\n            per sector per Guest within 1-3 days of\n            flight departure.\n             Online: INR 4057 (or equivalent) fee\n            per sector per Guest if modified more\n            than 4 days of flight departure.\n             Plus any Fare Difference ",
                    "canb": "Available up to 24 hours prior to\n             scheduled departure.",
                    "cabb_info": "Non-refundable fare. Only applicable\n            taxes are refunded.\n             Remaining balance held in\n            Credit for 1 year to be used by the\n            booking holder only."
                  },
                  "paxpr": [
                    {
                      "bf": 7273.28,
                      "tf": 9623.04,
                      "fbc": "P",
                      "psup_fare": "7273.28_9623.04",
                      "pusup_fare": "324.7_429.6",
                      "tax": [
                        {
                          "OT": 2349.76
                        }
                      ],
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_cur_fare": "324.7_104.9_429.6"
                    }
                  ],
                  "routeId": "20ztxm98n0h",
                  "servicefee": [],
                  "selected": true,
                  "seats_avail": 9,
                  "refundable": false,
                  "aj_fare_key": "MH5FfiB_Sjl_RUxTUE9XUUF_TEUwMH5_MH45fn5YITA-"
                },
                {
                  "pr": {
                    "tf": 10743.04,
                    "bf": 8393.28,
                    "tax": [
                      {
                        "OT": 2349.76
                      }
                    ],
                    "scur": "QAR",
                    "sup_fare": "8393.28_10743.04",
                    "sup_cur_fare": "374.7_104.9_479.6",
                    "ucur": "INR",
                    "exchange_rate": 22.4,
                    "ftyp": "Value",
                    "cabb": "20 Kg / 1 Piece",
                    "chekb": "7 KG/1 PIECE ",
                    "meals": "For a Fee",
                    "puBag": "Available",
                    "puBag_info": "Pre-purchase 10KG online",
                    "chanb": "Available up to 8 hours prior to\n            scheduled departure.",
                    "chanb_info": "Online: INR 5409 (or equivalent) fee\n            per sector per Guest up to 24 hours of\n            flight departure.\n             Online: INR 4868 (or equivalent) fee\n            per sector per Guest within 1-3 days of\n            flight departure.\n             Online: INR 4057 (or equivalent) fee\n            per sector per Guest if modified more\n            than 4 days of flight departure.\n             Plus any Fare Difference ",
                    "canb": "Available up to 24 hours prior to\n             scheduled departure.",
                    "cabb_info": "Online: INR 5950 (or equivalent)\n             fee per sector per Guest up to 24\n             hours of flight departure.\n              Online: INR 5409 (or equivalent)\n             fee per sector per Guest within 1-3\n             days of flight departure.\n              Online: INR 4598 (or equivalent)\n             fee per sector per Guest if modified\n             more than 4 days of flight departure."
                  },
                  "paxpr": [
                    {
                      "bf": 8393.28,
                      "tf": 10743.04,
                      "fbc": "P",
                      "psup_fare": "8393.28_10743.04",
                      "pusup_fare": "374.7_479.6",
                      "tax": [
                        {
                          "OT": 2349.76
                        }
                      ],
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_cur_fare": "374.7_104.9_479.6"
                    }
                  ],
                  "routeId": "9yl6aj8z4v",
                  "servicefee": [],
                  "selected": false,
                  "seats_avail": 9,
                  "refundable": true,
                  "aj_fare_key": "MH5CfiB_Sjl_QkxTUE9XUUF_VkUyMH5_MH4zfn5YITE-"
                },
                {
                  "pr": {
                    "tf": 11863.04,
                    "bf": 9513.28,
                    "tax": [
                      {
                        "OT": 2349.76
                      }
                    ],
                    "scur": "QAR",
                    "sup_fare": "9513.28_11863.04",
                    "sup_cur_fare": "424.7_104.9_529.6",
                    "ucur": "INR",
                    "exchange_rate": 22.4,
                    "ftyp": "Extra",
                    "cabb": "30 Kg / 2 Pieces",
                    "chekb": "7 KG/1 PIECE ",
                    "meals": "For a Fee",
                    "puBag": "Available",
                    "puBag_info": "Pre-purchase 10KG online",
                    "chanb": "Available up to 8 hours prior to\n            scheduled departure.",
                    "chanb_info": "Online: INR 4868 (or equivalent) fee\n            per sector per Guest up to 24 hours of\n            flight departure.\n             Online: INR 4328 (or equivalent) fee\n            per sector per Guest within 1-3 days of\n            flight departure.\n             Online: INR 3516 (or equivalent) fee\n            per sector per Guest if modified more\n            than 4 days of flight departure.\n             Plus any Fare Difference ",
                    "canb": "Available up to 24 hours prior to\n             scheduled departure.",
                    "cabb_info": "Online: INR 5409 (or equivalent)\n             fee per sector per Guest up to 24 hours\n             of flight departure.\n             Online: INR 4868 (or equivalent)\n             fee per sector per Guest within 1-3\n             days of flight departure.\n             Online: INR 4057 (or equivalent)\n             fee per sector per Guest if modified\n             more than 4 days of flight departure"
                  },
                  "paxpr": [
                    {
                      "bf": 9513.28,
                      "tf": 11863.04,
                      "fbc": "P",
                      "psup_fare": "9513.28_11863.04",
                      "pusup_fare": "424.7_529.6",
                      "tax": [
                        {
                          "OT": 2349.76
                        }
                      ],
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_cur_fare": "424.7_104.9_529.6"
                    }
                  ],
                  "routeId": "6x6j4p53dc",
                  "servicefee": [],
                  "selected": false,
                  "seats_avail": 9,
                  "refundable": true,
                  "aj_fare_key": "MH5LfiB_Sjl_S0xTUE9XUUF_WEUzMH5_MH40fn5YITI-"
                }
              ],
              "prouteId": "20ztxm98n0h",
              "stop": 1,
              "journeyKey": "Sjl_IDExNn4gfn5ET0h_MDYvMjkvMjAyMyAxNToxNX5LV0l_MDYvMjkvMjAyMyAxNjo0MH5_Xko5fiA0MDN_IH5_S1dJfjA2LzI5LzIwMjMgMTg6NDV_SFlEfjA2LzMwLzIwMjMgMDI6MDB_fg--",
              "paxpr": [
                {
                  "bf": 7273.28,
                  "tf": 9623.04,
                  "fbc": "P",
                  "psup_fare": "7273.28_9623.04",
                  "pusup_fare": "324.7_429.6",
                  "tax": [
                    {
                      "OT": 2349.76
                    }
                  ],
                  "ptype": "ADT",
                  "scur": "QAR",
                  "sup_cur_fare": "324.7_104.9_429.6"
                }
              ],
              "pr": {
                "tf": 9623.04,
                "bf": 7273.28,
                "tax": [
                  {
                    "OT": 2349.760000000001
                  }
                ],
                "scur": "QAR",
                "sup_fare": "7273.28_9623.04",
                "sup_cur_fare": "324.7_104.9_429.6",
                "ucur": "INR",
                "exchange_rate": 22.4,
                "ftyp": "Light",
                "cabb": "NIL",
                "chekb": "7 KG/1 PIECE ",
                "meals": "For a Fee",
                "puBag": "Available",
                "puBag_info": "Only available at Check-in",
                "chanb": "Available up to 8 hours prior to\n            scheduled departure.",
                "chanb_info": "Online: INR 5409 (or equivalent) fee\n            per sector per Guest up to 24 hours of\n            flight departure.\n             Online: INR 4868 (or equivalent) fee\n            per sector per Guest within 1-3 days of\n            flight departure.\n             Online: INR 4057 (or equivalent) fee\n            per sector per Guest if modified more\n            than 4 days of flight departure.\n             Plus any Fare Difference ",
                "canb": "Available up to 24 hours prior to\n             scheduled departure.",
                "cabb_info": "Non-refundable fare. Only applicable\n            taxes are refunded.\n             Remaining balance held in\n            Credit for 1 year to be used by the\n            booking holder only."
              }
            }
          ],
          "destorigins": [
            {
              "tid": "o61yNszIpTEIaQwV_1",
              "ref": 1,
              "sdur": "15:15",
              "re": "Refundable Before Departure",
              "stop": 1,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "MH",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "KUL",
                  "dd": "2023-06-30T00:15:00",
                  "ad": "2023-06-30T07:10:00",
                  "rbd": "Y",
                  "fn": "199",
                  "eq": "738",
                  "ac": "MH",
                  "ma": "MH",
                  "seat": "9",
                  "oa": "MH",
                  "fa": [],
                  "fdur": "04:25",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": "1"
                  }
                },
                {
                  "ref": 2,
                  "da": "KUL",
                  "ar": "DOH",
                  "dd": "2023-06-30T10:20:00",
                  "ad": "2023-06-30T13:00:00",
                  "rbd": "Q",
                  "fn": "9052",
                  "trn": "1",
                  "eq": "77W",
                  "ac": "MH",
                  "ma": "MH",
                  "seat": "4",
                  "oa": "QR",
                  "fa": [],
                  "fdur": "07:40",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "1",
                    "arr": ""
                  }
                }
              ],
              "flightNumbers": "199/9052",
              "bgd": [
                {
                  "qun": "30",
                  "wt": "kg"
                }
              ],
              "paxpr": [
                {
                  "tf": 130144,
                  "tax": [
                    {
                      "YQ": 12320
                    },
                    {
                      "OT": 9408
                    }
                  ],
                  "bf": 108416,
                  "fbc": "YIFMH",
                  "fareType": "RP",
                  "pusup_fare": "4840_5810",
                  "psup_fare": "108416_130144",
                  "ptype": "ADT"
                }
              ],
              "pr": {
                "bf": 108416,
                "tf": 130144,
                "tax": [
                  {
                    "YQ": 12320
                  },
                  {
                    "OT": 9408
                  }
                ],
                "scur": "QAR",
                "ucur": "INR",
                "exchange_rate": 22.4,
                "sup_fare": "4840_5810"
              }
            }
          ],
          "pr": {
            "bf": 115689,
            "tf": 139767,
            "tax": [
              {
                "OT": 2349.760000000001
              },
              {
                "YQ": 12320
              },
              {
                "OT": 9408
              }
            ]
          },
          "paxpr": [
            {
              "bf": 115689.28,
              "tf": 139767.04,
              "tax": [
                {
                  "OT": 2349.76
                },
                {
                  "YQ": 12320
                },
                {
                  "OT": 9408
                }
              ]
            }
          ],
          "sty": "split",
          "sup": "3/0",
          "airlineList": "J9_MH",
          "mix_carrier": true
        },
        {
          "srcorigns": [
            {
              "tid": "kSWD1R3LvfRyNlz6_1",
              "ref": 1,
              "ofcid": "",
              "sdur": "14:30",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "DOH",
                  "ar": "MAA",
                  "dd": "2023-06-29T02:00:00",
                  "ad": "2023-06-29T09:05:00",
                  "rbd": "",
                  "fn": "1322",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "3"
                  },
                  "rph": "",
                  "jsell": "6E~1322~ ~~DOH~06/29/2023 02:00~MAA~06/29/2023 09:05~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                },
                {
                  "ref": 2,
                  "da": "MAA",
                  "ar": "HYD",
                  "dd": "2023-06-29T12:20:00",
                  "ad": "2023-06-29T13:30:00",
                  "rbd": "",
                  "fn": " 604",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "1"
                  },
                  "rph": "",
                  "jsell": "6E~ 604~ ~~MAA~06/29/2023 12:20~HYD~06/29/2023 13:30~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~R~ ~6E~RCINT~6200~~0~65~~X^0~R~ ~6E~RCINT~6200~~0~65~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 9819,
                      "fbc": "RCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "9819_13326",
                      "tax": [
                        {
                          "OT": 3507
                        }
                      ],
                      "tf": 13326
                    }
                  ],
                  "pr": {
                    "bf": 9819,
                    "tf": 13326,
                    "tax": [
                      {
                        "OT": 3507
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "9819_13326",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "3ejfgmtsn3",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~R~ ~6E~RCUINT~6202~~0~4~~X^0~R~ ~6E~RCUINT~6202~~0~4~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 10519,
                      "fbc": "RCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "10519_14050",
                      "tax": [
                        {
                          "OT": 3531
                        }
                      ],
                      "tf": 14050
                    }
                  ],
                  "pr": {
                    "bf": 10519,
                    "tf": 14050,
                    "tax": [
                      {
                        "OT": 3531
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "10519_14050",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "asnla9i2l0l",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "Qjaaeqrl1XE=|aQFezKSWQkTEFuzy1c/XiE8E1gCe1mp7wL3TnlV5DA+x56hxCT+7ZWP6pBbfk21i1VpV7qcOGfUZINz5cX0sMPO7zDGGtcxXeJ5RNOP3ZplH1FG3CBzrsOYD796VyuQ29DfNwKuxQhE=",
              "stop": 1,
              "paxpr": [
                {
                  "bf": 9819,
                  "tf": 13326,
                  "fbc": "",
                  "psup_fare": "",
                  "tax": [
                    [
                      {
                        "OT": 3507
                      }
                    ]
                  ],
                  "ptype": "ADT",
                  "scur": "INR",
                  "sup_cur_fare": ""
                }
              ],
              "pr": {
                "bf": 9819,
                "tf": 13326,
                "tax": [
                  {
                    "OT": 3507
                  }
                ],
                "scur": "INR",
                "sup_fare": "",
                "sup_cur_fare": "",
                "cabb": "",
                "chekb": "",
                "seats": "",
                "meals": "",
                "chanb": "",
                "ftyp": ""
              }
            },
            {
              "tid": "T23thK491dFfh7nV_1",
              "ref": 1,
              "ofcid": "",
              "sdur": "19:15",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "DOH",
                  "ar": "MAA",
                  "dd": "2023-06-29T02:00:00",
                  "ad": "2023-06-29T09:05:00",
                  "rbd": "",
                  "fn": "1322",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "3"
                  },
                  "rph": "",
                  "jsell": "6E~1322~ ~~DOH~06/29/2023 02:00~MAA~06/29/2023 09:05~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                },
                {
                  "ref": 2,
                  "da": "MAA",
                  "ar": "HYD",
                  "dd": "2023-06-29T17:00:00",
                  "ad": "2023-06-29T18:15:00",
                  "rbd": "",
                  "fn": " 562",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "1"
                  },
                  "rph": "",
                  "jsell": "6E~ 562~ ~~MAA~06/29/2023 17:00~HYD~06/29/2023 18:15~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~R~ ~6E~RCINT~6200~~0~65~~X^0~R~ ~6E~RCINT~6200~~0~65~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 9819,
                      "fbc": "RCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "9819_13326",
                      "tax": [
                        {
                          "OT": 3507
                        }
                      ],
                      "tf": 13326
                    }
                  ],
                  "pr": {
                    "bf": 9819,
                    "tf": 13326,
                    "tax": [
                      {
                        "OT": 3507
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "9819_13326",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "iuz56zp12fi",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~R~ ~6E~RCUINT~6202~~0~4~~X^0~R~ ~6E~RCUINT~6202~~0~4~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 10519,
                      "fbc": "RCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "10519_14050",
                      "tax": [
                        {
                          "OT": 3531
                        }
                      ],
                      "tf": 14050
                    }
                  ],
                  "pr": {
                    "bf": 10519,
                    "tf": 14050,
                    "tax": [
                      {
                        "OT": 3531
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "10519_14050",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "fixyzs51cr",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "Qjaaeqrl1XE=|aQFezKSWQkTEFuzy1c/XiE8E1gCe1mp7wL3TnlV5DA+x56hxCT+7ZWP6pBbfk21i1VpV7qcOGfUZINz5cX0sMPO7zDGGtcxXeJ5RNOP3ZplH1FG3CBzrsOYD796VyuQ29DfNwKuxQhE=",
              "stop": 1,
              "paxpr": [
                {
                  "bf": 9819,
                  "tf": 13326,
                  "fbc": "",
                  "psup_fare": "",
                  "tax": [
                    [
                      {
                        "OT": 3507
                      }
                    ]
                  ],
                  "ptype": "ADT",
                  "scur": "INR",
                  "sup_cur_fare": ""
                }
              ],
              "pr": {
                "bf": 9819,
                "tf": 13326,
                "tax": [
                  {
                    "OT": 3507
                  }
                ],
                "scur": "INR",
                "sup_fare": "",
                "sup_cur_fare": "",
                "cabb": "",
                "chekb": "",
                "seats": "",
                "meals": "",
                "chanb": "",
                "ftyp": ""
              }
            },
            {
              "tid": "SiX2sy67k87wvIc4_1",
              "ref": 1,
              "ofcid": "",
              "sdur": "18:10",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "DOH",
                  "ar": "MAA",
                  "dd": "2023-06-29T02:00:00",
                  "ad": "2023-06-29T09:05:00",
                  "rbd": "",
                  "fn": "1322",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "3"
                  },
                  "rph": "",
                  "jsell": "6E~1322~ ~~DOH~06/29/2023 02:00~MAA~06/29/2023 09:05~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                },
                {
                  "ref": 2,
                  "da": "MAA",
                  "ar": "HYD",
                  "dd": "2023-06-29T16:00:00",
                  "ad": "2023-06-29T17:10:00",
                  "rbd": "",
                  "fn": "6401",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "1"
                  },
                  "rph": "",
                  "jsell": "6E~6401~ ~~MAA~06/29/2023 16:00~HYD~06/29/2023 17:10~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~R~ ~6E~RCINT~6200~~0~65~~X^0~R~ ~6E~RCINT~6200~~0~65~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 9819,
                      "fbc": "RCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "9819_13326",
                      "tax": [
                        {
                          "OT": 3507
                        }
                      ],
                      "tf": 13326
                    }
                  ],
                  "pr": {
                    "bf": 9819,
                    "tf": 13326,
                    "tax": [
                      {
                        "OT": 3507
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "9819_13326",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "ouof5d7tea",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~R~ ~6E~RCUINT~6202~~0~4~~X^0~R~ ~6E~RCUINT~6202~~0~4~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 10519,
                      "fbc": "RCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "10519_14050",
                      "tax": [
                        {
                          "OT": 3531
                        }
                      ],
                      "tf": 14050
                    }
                  ],
                  "pr": {
                    "bf": 10519,
                    "tf": 14050,
                    "tax": [
                      {
                        "OT": 3531
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "10519_14050",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "1b289r59s",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "Qjaaeqrl1XE=|aQFezKSWQkTEFuzy1c/XiE8E1gCe1mp7wL3TnlV5DA+x56hxCT+7ZWP6pBbfk21i1VpV7qcOGfUZINz5cX0sMPO7zDGGtcxXeJ5RNOP3ZplH1FG3CBzrsOYD796VyuQ29DfNwKuxQhE=",
              "stop": 1,
              "paxpr": [
                {
                  "bf": 9819,
                  "tf": 13326,
                  "fbc": "",
                  "psup_fare": "",
                  "tax": [
                    [
                      {
                        "OT": 3507
                      }
                    ]
                  ],
                  "ptype": "ADT",
                  "scur": "INR",
                  "sup_cur_fare": ""
                }
              ],
              "pr": {
                "bf": 9819,
                "tf": 13326,
                "tax": [
                  {
                    "OT": 3507
                  }
                ],
                "scur": "INR",
                "sup_fare": "",
                "sup_cur_fare": "",
                "cabb": "",
                "chekb": "",
                "seats": "",
                "meals": "",
                "chanb": "",
                "ftyp": ""
              }
            },
            {
              "tid": "FU3drl2wGMBptpID_1",
              "ref": 1,
              "ofcid": "",
              "sdur": "16:0",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "DOH",
                  "ar": "DEL",
                  "dd": "2023-06-29T19:40:00",
                  "ad": "2023-06-30T02:00:00",
                  "rbd": "",
                  "fn": "1308",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "3"
                  },
                  "rph": "",
                  "jsell": "6E~1308~ ~~DOH~06/29/2023 19:40~DEL~06/30/2023 02:00~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                },
                {
                  "ref": 2,
                  "da": "DEL",
                  "ar": "HYD",
                  "dd": "2023-06-30T06:30:00",
                  "ad": "2023-06-30T08:40:00",
                  "rbd": "",
                  "fn": "6203",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "1"
                  },
                  "rph": "",
                  "jsell": "6E~6203~ ~~DEL~06/30/2023 06:30~HYD~06/30/2023 08:40~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~R~ ~6E~RCINT~6200~~0~65~~X^0~R~ ~6E~RCINT~6200~~0~65~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 9819,
                      "fbc": "RCINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "9819_13326",
                      "tax": [
                        {
                          "OT": 3507
                        }
                      ],
                      "tf": 13326
                    }
                  ],
                  "pr": {
                    "bf": 9819,
                    "tf": 13326,
                    "tax": [
                      {
                        "OT": 3507
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "9819_13326",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "1ce4t0ev0o",
                  "ruleNumber": "6200",
                  "productClass": "R"
                },
                {
                  "fareKey": "0~R~ ~6E~RCUINT~6202~~0~4~~X^0~R~ ~6E~RCUINT~6202~~0~4~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 10519,
                      "fbc": "RCUINT",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "10519_14050",
                      "tax": [
                        {
                          "OT": 3531
                        }
                      ],
                      "tf": 14050
                    }
                  ],
                  "pr": {
                    "bf": 10519,
                    "tf": 14050,
                    "tax": [
                      {
                        "OT": 3531
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "10519_14050",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "1aosvbrps9g",
                  "ruleNumber": "6202",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "Qjaaeqrl1XE=|aQFezKSWQkTEFuzy1c/XiE8E1gCe1mp7wL3TnlV5DA+x56hxCT+7ZWP6pBbfk21i1VpV7qcOGfUZINz5cX0sMPO7zDGGtcxXeJ5RNOP3ZplH1FG3CBzrsOYD796VyuQ29DfNwKuxQhE=",
              "stop": 1,
              "paxpr": [
                {
                  "bf": 9819,
                  "tf": 13326,
                  "fbc": "",
                  "psup_fare": "",
                  "tax": [
                    [
                      {
                        "OT": 3507
                      }
                    ]
                  ],
                  "ptype": "ADT",
                  "scur": "INR",
                  "sup_cur_fare": ""
                }
              ],
              "pr": {
                "bf": 9819,
                "tf": 13326,
                "tax": [
                  {
                    "OT": 3507
                  }
                ],
                "scur": "INR",
                "sup_fare": "",
                "sup_cur_fare": "",
                "cabb": "",
                "chekb": "",
                "seats": "",
                "meals": "",
                "chanb": "",
                "ftyp": ""
              }
            }
          ],
          "destorigins": [
            {
              "tid": "o61yNszIpTEIaQwV_1",
              "ref": 1,
              "sdur": "15:15",
              "re": "Refundable Before Departure",
              "stop": 1,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "MH",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "KUL",
                  "dd": "2023-06-30T00:15:00",
                  "ad": "2023-06-30T07:10:00",
                  "rbd": "Y",
                  "fn": "199",
                  "eq": "738",
                  "ac": "MH",
                  "ma": "MH",
                  "seat": "9",
                  "oa": "MH",
                  "fa": [],
                  "fdur": "04:25",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": "1"
                  }
                },
                {
                  "ref": 2,
                  "da": "KUL",
                  "ar": "DOH",
                  "dd": "2023-06-30T10:20:00",
                  "ad": "2023-06-30T13:00:00",
                  "rbd": "Q",
                  "fn": "9052",
                  "trn": "1",
                  "eq": "77W",
                  "ac": "MH",
                  "ma": "MH",
                  "seat": "4",
                  "oa": "QR",
                  "fa": [],
                  "fdur": "07:40",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "1",
                    "arr": ""
                  }
                }
              ],
              "flightNumbers": "199/9052",
              "bgd": [
                {
                  "qun": "30",
                  "wt": "kg"
                }
              ],
              "paxpr": [
                {
                  "tf": 130144,
                  "tax": [
                    {
                      "YQ": 12320
                    },
                    {
                      "OT": 9408
                    }
                  ],
                  "bf": 108416,
                  "fbc": "YIFMH",
                  "fareType": "RP",
                  "pusup_fare": "4840_5810",
                  "psup_fare": "108416_130144",
                  "ptype": "ADT"
                }
              ],
              "pr": {
                "bf": 108416,
                "tf": 130144,
                "tax": [
                  {
                    "YQ": 12320
                  },
                  {
                    "OT": 9408
                  }
                ],
                "scur": "QAR",
                "ucur": "INR",
                "exchange_rate": 22.4,
                "sup_fare": "4840_5810"
              }
            }
          ],
          "pr": {
            "bf": 118235,
            "tf": 143470,
            "tax": [
              {
                "OT": 3507
              },
              {
                "YQ": 12320
              },
              {
                "OT": 9408
              }
            ]
          },
          "paxpr": [
            {
              "bf": 118235,
              "tf": 143470,
              "tax": [
                [
                  {
                    "OT": 3507
                  }
                ],
                {
                  "YQ": 12320
                },
                {
                  "OT": 9408
                }
              ]
            }
          ],
          "sty": "split",
          "sup": "4/0",
          "airlineList": "6E_MH",
          "mix_carrier": true
        },
        {
          "srcorigns": [
            {
              "tid": "2qbfE1nLQLfVShip_1",
              "ref": 1,
              "sdur": "08:55",
              "re": "Refundable Before Departure",
              "stop": 1,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "EK",
              "seg": [
                {
                  "ref": 1,
                  "da": "DOH",
                  "ar": "DXB",
                  "dd": "2023-06-29T21:00:00",
                  "ad": "2023-06-29T23:15:00",
                  "rbd": "I",
                  "fn": "6",
                  "eq": "7M8",
                  "ac": "FZ",
                  "ma": "FZ",
                  "seat": "4",
                  "oa": "FZ",
                  "fa": [],
                  "fdur": "01:15",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": "2"
                  }
                },
                {
                  "ref": 2,
                  "da": "DXB",
                  "ar": "HYD",
                  "dd": "2023-06-30T03:10:00",
                  "ad": "2023-06-30T08:25:00",
                  "rbd": "R",
                  "fn": "526",
                  "trn": "3",
                  "eq": "77W",
                  "ac": "EK",
                  "ma": "EK",
                  "seat": "9",
                  "oa": "EK",
                  "fa": [],
                  "fdur": "03:45",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "3",
                    "arr": ""
                  }
                }
              ],
              "flightNumbers": "6/526",
              "bgd": [
                {
                  "qun": "35",
                  "wt": "kg"
                }
              ],
              "paxpr": [
                {
                  "tf": 107072,
                  "tax": [
                    {
                      "YQ": 8512
                    },
                    {
                      "OT": 4256
                    }
                  ],
                  "bf": 94304,
                  "fbc": "RHOWFQA1",
                  "fareType": "RP",
                  "pusup_fare": "4210_4780",
                  "psup_fare": "94304_107072",
                  "ptype": "ADT"
                }
              ],
              "pr": {
                "bf": 94304,
                "tf": 107072,
                "tax": [
                  {
                    "YQ": 8512
                  },
                  {
                    "OT": 4256
                  }
                ],
                "scur": "QAR",
                "ucur": "INR",
                "exchange_rate": 22.4,
                "sup_fare": "4210_4780"
              }
            },
            {
              "tid": "kJPqCCgcmPQpFQbv_1",
              "ref": 1,
              "sdur": "07:35",
              "re": "Refundable Before Departure",
              "stop": 1,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "EK",
              "seg": [
                {
                  "ref": 1,
                  "da": "DOH",
                  "ar": "DXB",
                  "dd": "2023-06-29T17:00:00",
                  "ad": "2023-06-29T19:15:00",
                  "rbd": "I",
                  "fn": "18",
                  "eq": "7M8",
                  "ac": "FZ",
                  "ma": "FZ",
                  "seat": "4",
                  "oa": "FZ",
                  "fa": [],
                  "fdur": "01:15",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": "2"
                  }
                },
                {
                  "ref": 2,
                  "da": "DXB",
                  "ar": "HYD",
                  "dd": "2023-06-29T22:00:00",
                  "ad": "2023-06-30T03:05:00",
                  "rbd": "R",
                  "fn": "524",
                  "trn": "3",
                  "eq": "77W",
                  "ac": "EK",
                  "ma": "EK",
                  "seat": "9",
                  "oa": "EK",
                  "fa": [],
                  "fdur": "03:35",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "3",
                    "arr": ""
                  }
                }
              ],
              "flightNumbers": "18/524",
              "bgd": [
                {
                  "qun": "35",
                  "wt": "kg"
                }
              ],
              "paxpr": [
                {
                  "tf": 107072,
                  "tax": [
                    {
                      "YQ": 8512
                    },
                    {
                      "OT": 4256
                    }
                  ],
                  "bf": 94304,
                  "fbc": "RHOWFQA1",
                  "fareType": "RP",
                  "pusup_fare": "4210_4780",
                  "psup_fare": "94304_107072",
                  "ptype": "ADT"
                }
              ],
              "pr": {
                "bf": 94304,
                "tf": 107072,
                "tax": [
                  {
                    "YQ": 8512
                  },
                  {
                    "OT": 4256
                  }
                ],
                "scur": "QAR",
                "ucur": "INR",
                "exchange_rate": 22.4,
                "sup_fare": "4210_4780"
              }
            },
            {
              "tid": "JuWgWGhTrV9sFgNX_1",
              "ref": 1,
              "sdur": "12:55",
              "re": "Refundable Before Departure",
              "stop": 1,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "EK",
              "seg": [
                {
                  "ref": 1,
                  "da": "DOH",
                  "ar": "DXB",
                  "dd": "2023-06-29T17:00:00",
                  "ad": "2023-06-29T19:15:00",
                  "rbd": "I",
                  "fn": "18",
                  "eq": "7M8",
                  "ac": "FZ",
                  "ma": "FZ",
                  "seat": "4",
                  "oa": "FZ",
                  "fa": [],
                  "fdur": "01:15",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": "2"
                  }
                },
                {
                  "ref": 2,
                  "da": "DXB",
                  "ar": "HYD",
                  "dd": "2023-06-30T03:10:00",
                  "ad": "2023-06-30T08:25:00",
                  "rbd": "R",
                  "fn": "526",
                  "trn": "3",
                  "eq": "77W",
                  "ac": "EK",
                  "ma": "EK",
                  "seat": "9",
                  "oa": "EK",
                  "fa": [],
                  "fdur": "03:45",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "3",
                    "arr": ""
                  }
                }
              ],
              "flightNumbers": "18/526",
              "bgd": [
                {
                  "qun": "35",
                  "wt": "kg"
                }
              ],
              "paxpr": [
                {
                  "tf": 107072,
                  "tax": [
                    {
                      "YQ": 8512
                    },
                    {
                      "OT": 4256
                    }
                  ],
                  "bf": 94304,
                  "fbc": "RHOWFQA1",
                  "fareType": "RP",
                  "pusup_fare": "4210_4780",
                  "psup_fare": "94304_107072",
                  "ptype": "ADT"
                }
              ],
              "pr": {
                "bf": 94304,
                "tf": 107072,
                "tax": [
                  {
                    "YQ": 8512
                  },
                  {
                    "OT": 4256
                  }
                ],
                "scur": "QAR",
                "ucur": "INR",
                "exchange_rate": 22.4,
                "sup_fare": "4210_4780"
              }
            },
            {
              "tid": "620D0bxh9FgS6Tyc_1",
              "ref": 1,
              "sdur": "07:45",
              "re": "Refundable Before Departure",
              "stop": 1,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "EK",
              "seg": [
                {
                  "ref": 1,
                  "da": "DOH",
                  "ar": "DXB",
                  "dd": "2023-06-29T10:00:00",
                  "ad": "2023-06-29T12:15:00",
                  "rbd": "I",
                  "fn": "2",
                  "eq": "7M8",
                  "ac": "FZ",
                  "ma": "FZ",
                  "seat": "4",
                  "oa": "FZ",
                  "fa": [],
                  "fdur": "01:15",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": "2"
                  }
                },
                {
                  "ref": 2,
                  "da": "DXB",
                  "ar": "HYD",
                  "dd": "2023-06-29T15:05:00",
                  "ad": "2023-06-29T20:15:00",
                  "rbd": "R",
                  "fn": "528",
                  "trn": "3",
                  "eq": "77W",
                  "ac": "EK",
                  "ma": "EK",
                  "seat": "9",
                  "oa": "EK",
                  "fa": [],
                  "fdur": "03:40",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "3",
                    "arr": ""
                  }
                }
              ],
              "flightNumbers": "2/528",
              "bgd": [
                {
                  "qun": "35",
                  "wt": "kg"
                }
              ],
              "paxpr": [
                {
                  "tf": 107072,
                  "tax": [
                    {
                      "YQ": 8512
                    },
                    {
                      "OT": 4256
                    }
                  ],
                  "bf": 94304,
                  "fbc": "RHOWFQA1",
                  "fareType": "RP",
                  "pusup_fare": "4210_4780",
                  "psup_fare": "94304_107072",
                  "ptype": "ADT"
                }
              ],
              "pr": {
                "bf": 94304,
                "tf": 107072,
                "tax": [
                  {
                    "YQ": 8512
                  },
                  {
                    "OT": 4256
                  }
                ],
                "scur": "QAR",
                "ucur": "INR",
                "exchange_rate": 22.4,
                "sup_fare": "4210_4780"
              }
            },
            {
              "tid": "VCsXhWnrHwpO1fzr_1",
              "ref": 1,
              "sdur": "14:35",
              "re": "Refundable Before Departure",
              "stop": 1,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "EK",
              "seg": [
                {
                  "ref": 1,
                  "da": "DOH",
                  "ar": "DXB",
                  "dd": "2023-06-29T10:00:00",
                  "ad": "2023-06-29T12:15:00",
                  "rbd": "I",
                  "fn": "2",
                  "eq": "7M8",
                  "ac": "FZ",
                  "ma": "FZ",
                  "seat": "4",
                  "oa": "FZ",
                  "fa": [],
                  "fdur": "01:15",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": "2"
                  }
                },
                {
                  "ref": 2,
                  "da": "DXB",
                  "ar": "HYD",
                  "dd": "2023-06-29T22:00:00",
                  "ad": "2023-06-30T03:05:00",
                  "rbd": "R",
                  "fn": "524",
                  "trn": "3",
                  "eq": "77W",
                  "ac": "EK",
                  "ma": "EK",
                  "seat": "9",
                  "oa": "EK",
                  "fa": [],
                  "fdur": "03:35",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "3",
                    "arr": ""
                  }
                }
              ],
              "flightNumbers": "2/524",
              "bgd": [
                {
                  "qun": "35",
                  "wt": "kg"
                }
              ],
              "paxpr": [
                {
                  "tf": 107072,
                  "tax": [
                    {
                      "YQ": 8512
                    },
                    {
                      "OT": 4256
                    }
                  ],
                  "bf": 94304,
                  "fbc": "RHOWFQA1",
                  "fareType": "RP",
                  "pusup_fare": "4210_4780",
                  "psup_fare": "94304_107072",
                  "ptype": "ADT"
                }
              ],
              "pr": {
                "bf": 94304,
                "tf": 107072,
                "tax": [
                  {
                    "YQ": 8512
                  },
                  {
                    "OT": 4256
                  }
                ],
                "scur": "QAR",
                "ucur": "INR",
                "exchange_rate": 22.4,
                "sup_fare": "4210_4780"
              }
            },
            {
              "tid": "kKqoT4KLo5DG4794_1",
              "ref": 1,
              "sdur": "19:55",
              "re": "Refundable Before Departure",
              "stop": 1,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "EK",
              "seg": [
                {
                  "ref": 1,
                  "da": "DOH",
                  "ar": "DXB",
                  "dd": "2023-06-29T10:00:00",
                  "ad": "2023-06-29T12:15:00",
                  "rbd": "I",
                  "fn": "2",
                  "eq": "7M8",
                  "ac": "FZ",
                  "ma": "FZ",
                  "seat": "4",
                  "oa": "FZ",
                  "fa": [],
                  "fdur": "01:15",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": "2"
                  }
                },
                {
                  "ref": 2,
                  "da": "DXB",
                  "ar": "HYD",
                  "dd": "2023-06-30T03:10:00",
                  "ad": "2023-06-30T08:25:00",
                  "rbd": "R",
                  "fn": "526",
                  "trn": "3",
                  "eq": "77W",
                  "ac": "EK",
                  "ma": "EK",
                  "seat": "9",
                  "oa": "EK",
                  "fa": [],
                  "fdur": "03:45",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "3",
                    "arr": ""
                  }
                }
              ],
              "flightNumbers": "2/526",
              "bgd": [
                {
                  "qun": "35",
                  "wt": "kg"
                }
              ],
              "paxpr": [
                {
                  "tf": 107072,
                  "tax": [
                    {
                      "YQ": 8512
                    },
                    {
                      "OT": 4256
                    }
                  ],
                  "bf": 94304,
                  "fbc": "RHOWFQA1",
                  "fareType": "RP",
                  "pusup_fare": "4210_4780",
                  "psup_fare": "94304_107072",
                  "ptype": "ADT"
                }
              ],
              "pr": {
                "bf": 94304,
                "tf": 107072,
                "tax": [
                  {
                    "YQ": 8512
                  },
                  {
                    "OT": 4256
                  }
                ],
                "scur": "QAR",
                "ucur": "INR",
                "exchange_rate": 22.4,
                "sup_fare": "4210_4780"
              }
            }
          ],
          "destorigins": [
            {
              "tid": "o4dGVuQJgCyaGMOJ_1",
              "ref": 1,
              "sdur": "04:10",
              "re": "Refundable Before Departure",
              "stop": 0,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "QR",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DOH",
                  "dd": "2023-06-30T21:55:00",
                  "ad": "2023-06-30T23:35:00",
                  "rbd": "N",
                  "fn": "4779",
                  "eq": "320",
                  "ac": "QR",
                  "ma": "QR",
                  "seat": "9",
                  "oa": "6E",
                  "fa": [],
                  "fdur": "04:10",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": ""
                  }
                }
              ],
              "flightNumbers": "4779",
              "bgd": [
                {
                  "qun": "25",
                  "wt": "kg"
                }
              ],
              "paxpr": [
                {
                  "tf": 39200,
                  "tax": [
                    {
                      "YQ": 9856
                    },
                    {
                      "YR": 896
                    },
                    {
                      "OT": 5152
                    }
                  ],
                  "bf": 23296,
                  "fbc": "NLR4I1RQ",
                  "fareType": "RP",
                  "pusup_fare": "1040_1750",
                  "psup_fare": "23296_39200",
                  "ptype": "ADT"
                }
              ],
              "pr": {
                "bf": 23296,
                "tf": 39200,
                "tax": [
                  {
                    "YQ": 9856
                  },
                  {
                    "YR": 896
                  },
                  {
                    "OT": 5152
                  }
                ],
                "scur": "QAR",
                "ucur": "INR",
                "exchange_rate": 22.4,
                "sup_fare": "1040_1750"
              }
            },
            {
              "tid": "LithwaiTgVo0zTSn_1",
              "ref": 1,
              "sdur": "04:10",
              "re": "Refundable Before Departure",
              "stop": 0,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "QR",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DOH",
                  "dd": "2023-06-30T17:05:00",
                  "ad": "2023-06-30T18:45:00",
                  "rbd": "N",
                  "fn": "4777",
                  "eq": "320",
                  "ac": "QR",
                  "ma": "QR",
                  "seat": "9",
                  "oa": "6E",
                  "fa": [],
                  "fdur": "04:10",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": ""
                  }
                }
              ],
              "flightNumbers": "4777",
              "bgd": [
                {
                  "qun": "25",
                  "wt": "kg"
                }
              ],
              "paxpr": [
                {
                  "tf": 39200,
                  "tax": [
                    {
                      "YQ": 9856
                    },
                    {
                      "YR": 896
                    },
                    {
                      "OT": 5152
                    }
                  ],
                  "bf": 23296,
                  "fbc": "NLR4I1RQ",
                  "fareType": "RP",
                  "pusup_fare": "1040_1750",
                  "psup_fare": "23296_39200",
                  "ptype": "ADT"
                }
              ],
              "pr": {
                "bf": 23296,
                "tf": 39200,
                "tax": [
                  {
                    "YQ": 9856
                  },
                  {
                    "YR": 896
                  },
                  {
                    "OT": 5152
                  }
                ],
                "scur": "QAR",
                "ucur": "INR",
                "exchange_rate": 22.4,
                "sup_fare": "1040_1750"
              }
            },
            {
              "tid": "VtTRT4CTTnmiBqt3_1",
              "ref": 1,
              "sdur": "04:10",
              "re": "Refundable Before Departure",
              "stop": 0,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "QR",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DOH",
                  "dd": "2023-06-30T04:10:00",
                  "ad": "2023-06-30T05:50:00",
                  "rbd": "N",
                  "fn": "4775",
                  "eq": "320",
                  "ac": "QR",
                  "ma": "QR",
                  "seat": "9",
                  "oa": "6E",
                  "fa": [],
                  "fdur": "04:10",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": ""
                  }
                }
              ],
              "flightNumbers": "4775",
              "bgd": [
                {
                  "qun": "25",
                  "wt": "kg"
                }
              ],
              "paxpr": [
                {
                  "tf": 39200,
                  "tax": [
                    {
                      "YQ": 9856
                    },
                    {
                      "YR": 896
                    },
                    {
                      "OT": 5152
                    }
                  ],
                  "bf": 23296,
                  "fbc": "NLR4I1RQ",
                  "fareType": "RP",
                  "pusup_fare": "1040_1750",
                  "psup_fare": "23296_39200",
                  "ptype": "ADT"
                }
              ],
              "pr": {
                "bf": 23296,
                "tf": 39200,
                "tax": [
                  {
                    "YQ": 9856
                  },
                  {
                    "YR": 896
                  },
                  {
                    "OT": 5152
                  }
                ],
                "scur": "QAR",
                "ucur": "INR",
                "exchange_rate": 22.4,
                "sup_fare": "1040_1750"
              }
            }
          ],
          "pr": {
            "bf": 117600,
            "tf": 146272,
            "tax": [
              {
                "YQ": 8512
              },
              {
                "OT": 4256
              },
              {
                "YQ": 9856
              },
              {
                "YR": 896
              },
              {
                "OT": 5152
              }
            ]
          },
          "paxpr": [
            {
              "bf": 117600,
              "tf": 146272,
              "tax": [
                {
                  "YQ": 8512
                },
                {
                  "OT": 4256
                },
                {
                  "YQ": 9856
                },
                {
                  "YR": 896
                },
                {
                  "OT": 5152
                }
              ]
            }
          ],
          "sty": "split",
          "sup": "0/0",
          "airlineList": "FZ_6E",
          "mix_carrier": true
        },
        {
          "srcorigns": [
            {
              "tid": "UC8pMN2hvMQGa489_1",
              "ref": 1,
              "ofcid": "",
              "sdur": "9:45",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "DOH",
                  "ar": "HYD",
                  "dd": "2023-06-29T02:00:00",
                  "ad": "2023-06-29T08:45:00",
                  "rbd": "",
                  "fn": "1318",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {},
                  "rph": "",
                  "jsell": "6E~1318~ ~~DOH~06/29/2023 02:00~HYD~06/29/2023 08:45~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~A~ ~6E~A0INT~5000~~0~1~~X",
                  "pr": {
                    "bf": 28373,
                    "tf": 32479,
                    "tax": [
                      {
                        "OT": 4106
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "28373_32479",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "paxpr": [
                    {
                      "bf": 28373,
                      "tf": 32479,
                      "tax": [
                        {
                          "OT": 4106
                        }
                      ],
                      "fbc": "A0INT",
                      "ptype": "ADT",
                      "scur": "INR",
                      "sup_fare": "28373_32479"
                    }
                  ],
                  "routeId": "lh3rsvezlv",
                  "productClass": "R",
                  "ruleNumber": "5000"
                },
                {
                  "fareKey": "0~A~ ~6E~AUINT~5003~~0~7~~X",
                  "pr": {
                    "bf": 29073,
                    "tf": 33204,
                    "tax": [
                      {
                        "OT": 4131
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "29073_33204",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "paxpr": [
                    {
                      "bf": 29073,
                      "tf": 33204,
                      "tax": [
                        {
                          "OT": 4131
                        }
                      ],
                      "fbc": "AUINT",
                      "ptype": "ADT",
                      "scur": "INR",
                      "sup_fare": "29073_33204"
                    }
                  ],
                  "routeId": "56adr4fn8o",
                  "productClass": "J",
                  "ruleNumber": "5003"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "Qjaaeqrl1XE=|aQFezKSWQkTEFuzy1c/XiE8E1gCe1mp7wL3TnlV5DA+x56hxCT+7ZWP6pBbfk21i1VpV7qcOGfUZINz5cX0sMPO7zDGGtcxXeJ5RNOP3ZplH1FG3CBzrsOYD796VyuQ29DfNwKuxQhE=",
              "stop": 0,
              "paxpr": [
                {
                  "bf": 28373,
                  "tf": 32479,
                  "fbc": "",
                  "psup_fare": "",
                  "tax": [
                    [
                      {
                        "OT": 4106
                      }
                    ]
                  ],
                  "ptype": "ADT",
                  "scur": "INR",
                  "sup_cur_fare": ""
                }
              ],
              "pr": {
                "bf": 28373,
                "tf": 32479,
                "tax": [
                  {
                    "OT": 4106
                  }
                ],
                "scur": "INR",
                "sup_fare": "",
                "sup_cur_fare": "",
                "cabb": "",
                "chekb": "",
                "seats": "",
                "meals": "",
                "chanb": "",
                "ftyp": ""
              }
            },
            {
              "tid": "9szQBnvh8kM9vfqy_1",
              "ref": 1,
              "ofcid": "",
              "sdur": "9:45",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "DOH",
                  "ar": "HYD",
                  "dd": "2023-06-29T08:15:00",
                  "ad": "2023-06-29T15:00:00",
                  "rbd": "",
                  "fn": "1314",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {},
                  "rph": "",
                  "jsell": "6E~1314~ ~~DOH~06/29/2023 08:15~HYD~06/29/2023 15:00~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~A~ ~6E~A0INT~5000~~0~1~~X",
                  "pr": {
                    "bf": 28373,
                    "tf": 32479,
                    "tax": [
                      {
                        "OT": 4106
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "28373_32479",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "paxpr": [
                    {
                      "bf": 28373,
                      "tf": 32479,
                      "tax": [
                        {
                          "OT": 4106
                        }
                      ],
                      "fbc": "A0INT",
                      "ptype": "ADT",
                      "scur": "INR",
                      "sup_fare": "28373_32479"
                    }
                  ],
                  "routeId": "ivcua3jvq",
                  "productClass": "R",
                  "ruleNumber": "5000"
                },
                {
                  "fareKey": "0~A~ ~6E~AUINT~5003~~0~7~~X",
                  "pr": {
                    "bf": 29073,
                    "tf": 33204,
                    "tax": [
                      {
                        "OT": 4131
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "29073_33204",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "paxpr": [
                    {
                      "bf": 29073,
                      "tf": 33204,
                      "tax": [
                        {
                          "OT": 4131
                        }
                      ],
                      "fbc": "AUINT",
                      "ptype": "ADT",
                      "scur": "INR",
                      "sup_fare": "29073_33204"
                    }
                  ],
                  "routeId": "romovrv2la",
                  "productClass": "J",
                  "ruleNumber": "5003"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "Qjaaeqrl1XE=|aQFezKSWQkTEFuzy1c/XiE8E1gCe1mp7wL3TnlV5DA+x56hxCT+7ZWP6pBbfk21i1VpV7qcOGfUZINz5cX0sMPO7zDGGtcxXeJ5RNOP3ZplH1FG3CBzrsOYD796VyuQ29DfNwKuxQhE=",
              "stop": 0,
              "paxpr": [
                {
                  "bf": 28373,
                  "tf": 32479,
                  "fbc": "",
                  "psup_fare": "",
                  "tax": [
                    [
                      {
                        "OT": 4106
                      }
                    ]
                  ],
                  "ptype": "ADT",
                  "scur": "INR",
                  "sup_cur_fare": ""
                }
              ],
              "pr": {
                "bf": 28373,
                "tf": 32479,
                "tax": [
                  {
                    "OT": 4106
                  }
                ],
                "scur": "INR",
                "sup_fare": "",
                "sup_cur_fare": "",
                "cabb": "",
                "chekb": "",
                "seats": "",
                "meals": "",
                "chanb": "",
                "ftyp": ""
              }
            },
            {
              "tid": "CHBJNQO3ROTFP5Tq_1",
              "ref": 1,
              "ofcid": "",
              "sdur": "9:45",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "DOH",
                  "ar": "HYD",
                  "dd": "2023-06-29T19:45:00",
                  "ad": "2023-06-30T02:30:00",
                  "rbd": "",
                  "fn": "1316",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {},
                  "rph": "",
                  "jsell": "6E~1316~ ~~DOH~06/29/2023 19:45~HYD~06/30/2023 02:30~~",
                  "cl": "Economy",
                  "flightTime": -1,
                  "fdur": "0:-1"
                }
              ],
              "fareop": [
                {
                  "fareKey": "0~A~ ~6E~A0INT~5000~~0~1~~X",
                  "pr": {
                    "bf": 28373,
                    "tf": 32479,
                    "tax": [
                      {
                        "OT": 4106
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "28373_32479",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "paxpr": [
                    {
                      "bf": 28373,
                      "tf": 32479,
                      "tax": [
                        {
                          "OT": 4106
                        }
                      ],
                      "fbc": "A0INT",
                      "ptype": "ADT",
                      "scur": "INR",
                      "sup_fare": "28373_32479"
                    }
                  ],
                  "routeId": "7uvfkpd73g",
                  "productClass": "R",
                  "ruleNumber": "5000"
                },
                {
                  "fareKey": "0~A~ ~6E~AUINT~5003~~0~7~~X",
                  "pr": {
                    "bf": 29073,
                    "tf": 33204,
                    "tax": [
                      {
                        "OT": 4131
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "29073_33204",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "paxpr": [
                    {
                      "bf": 29073,
                      "tf": 33204,
                      "tax": [
                        {
                          "OT": 4131
                        }
                      ],
                      "fbc": "AUINT",
                      "ptype": "ADT",
                      "scur": "INR",
                      "sup_fare": "29073_33204"
                    }
                  ],
                  "routeId": "g0dit1py42i",
                  "productClass": "J",
                  "ruleNumber": "5003"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "Qjaaeqrl1XE=|aQFezKSWQkTEFuzy1c/XiE8E1gCe1mp7wL3TnlV5DA+x56hxCT+7ZWP6pBbfk21i1VpV7qcOGfUZINz5cX0sMPO7zDGGtcxXeJ5RNOP3ZplH1FG3CBzrsOYD796VyuQ29DfNwKuxQhE=",
              "stop": 0,
              "paxpr": [
                {
                  "bf": 28373,
                  "tf": 32479,
                  "fbc": "",
                  "psup_fare": "",
                  "tax": [
                    [
                      {
                        "OT": 4106
                      }
                    ]
                  ],
                  "ptype": "ADT",
                  "scur": "INR",
                  "sup_cur_fare": ""
                }
              ],
              "pr": {
                "bf": 28373,
                "tf": 32479,
                "tax": [
                  {
                    "OT": 4106
                  }
                ],
                "scur": "INR",
                "sup_fare": "",
                "sup_cur_fare": "",
                "cabb": "",
                "chekb": "",
                "seats": "",
                "meals": "",
                "chanb": "",
                "ftyp": ""
              }
            }
          ],
          "destorigins": [
            {
              "tid": "o61yNszIpTEIaQwV_1",
              "ref": 1,
              "sdur": "15:15",
              "re": "Refundable Before Departure",
              "stop": 1,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "MH",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "KUL",
                  "dd": "2023-06-30T00:15:00",
                  "ad": "2023-06-30T07:10:00",
                  "rbd": "Y",
                  "fn": "199",
                  "eq": "738",
                  "ac": "MH",
                  "ma": "MH",
                  "seat": "9",
                  "oa": "MH",
                  "fa": [],
                  "fdur": "04:25",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": "1"
                  }
                },
                {
                  "ref": 2,
                  "da": "KUL",
                  "ar": "DOH",
                  "dd": "2023-06-30T10:20:00",
                  "ad": "2023-06-30T13:00:00",
                  "rbd": "Q",
                  "fn": "9052",
                  "trn": "1",
                  "eq": "77W",
                  "ac": "MH",
                  "ma": "MH",
                  "seat": "4",
                  "oa": "QR",
                  "fa": [],
                  "fdur": "07:40",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "1",
                    "arr": ""
                  }
                }
              ],
              "flightNumbers": "199/9052",
              "bgd": [
                {
                  "qun": "30",
                  "wt": "kg"
                }
              ],
              "paxpr": [
                {
                  "tf": 130144,
                  "tax": [
                    {
                      "YQ": 12320
                    },
                    {
                      "OT": 9408
                    }
                  ],
                  "bf": 108416,
                  "fbc": "YIFMH",
                  "fareType": "RP",
                  "pusup_fare": "4840_5810",
                  "psup_fare": "108416_130144",
                  "ptype": "ADT"
                }
              ],
              "pr": {
                "bf": 108416,
                "tf": 130144,
                "tax": [
                  {
                    "YQ": 12320
                  },
                  {
                    "OT": 9408
                  }
                ],
                "scur": "QAR",
                "ucur": "INR",
                "exchange_rate": 22.4,
                "sup_fare": "4840_5810"
              }
            }
          ],
          "pr": {
            "bf": 136789,
            "tf": 162623,
            "tax": [
              {
                "OT": 4106
              },
              {
                "YQ": 12320
              },
              {
                "OT": 9408
              }
            ]
          },
          "paxpr": [
            {
              "bf": 136789,
              "tf": 162623,
              "tax": [
                [
                  {
                    "OT": 4106
                  }
                ],
                {
                  "YQ": 12320
                },
                {
                  "OT": 9408
                }
              ]
            }
          ],
          "sty": "split",
          "sup": "4/0",
          "airlineList": "6E_MH",
          "mix_carrier": true
        },
        {
          "srcorigns": [
            {
              "tid": "IhMrTnLaWeMUB4Dh_1",
              "ref": 1,
              "ofcid": "",
              "sdur": "13:35",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "DOH",
                  "ar": "BOM",
                  "dd": "2023-06-29T08:15:00",
                  "ad": "2023-06-29T14:20:00",
                  "rbd": "",
                  "fn": "1304",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "2"
                  },
                  "rph": "",
                  "jsell": "6E~1304~ ~~DOH~06/29/2023 08:15~BOM~06/29/2023 14:20~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                },
                {
                  "ref": 2,
                  "da": "BOM",
                  "ar": "HYD",
                  "dd": "2023-06-29T17:20:00",
                  "ad": "2023-06-29T18:50:00",
                  "rbd": "",
                  "fn": "6571",
                  "trn": "",
                  "eq": "320",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "2"
                  },
                  "rph": "",
                  "jsell": "6E~6571~ ~~BOM~06/29/2023 17:20~HYD~06/29/2023 18:50~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "1~W~ ~6E~W0INT~5000~~0~7~~^2~R~ ~6E~RCIP~1070~~0~8~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 28729,
                      "fbc": "W0INT/RCIP",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "28729_32898",
                      "tax": [
                        {
                          "OT": 4169
                        }
                      ],
                      "tf": 32898
                    }
                  ],
                  "pr": {
                    "bf": 28729,
                    "tf": 32898,
                    "tax": [
                      {
                        "OT": 4169
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "28729_32898",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "c0i0ja0ypni",
                  "ruleNumber": "5000",
                  "productClass": "R"
                },
                {
                  "fareKey": "1~W~ ~6E~WUINT~5003~~0~12~~^2~R~ ~6E~RUIP~2009~~0~4~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 30029,
                      "fbc": "WUINT/RUIP",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "30029_34243",
                      "tax": [
                        {
                          "OT": 4214
                        }
                      ],
                      "tf": 34243
                    }
                  ],
                  "pr": {
                    "bf": 30029,
                    "tf": 34243,
                    "tax": [
                      {
                        "OT": 4214
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "30029_34243",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "0qfapdtxv6",
                  "ruleNumber": "5003",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "Qjaaeqrl1XE=|aQFezKSWQkTEFuzy1c/XiE8E1gCe1mp7wL3TnlV5DA+x56hxCT+7ZWP6pBbfk21i1VpV7qcOGfUZINz5cX0sMPO7zDGGtcxXeJ5RNOP3ZplH1FG3CBzrsOYD796VyuQ29DfNwKuxQhE=",
              "stop": 1,
              "paxpr": [
                {
                  "bf": 28729,
                  "tf": 32898,
                  "fbc": "",
                  "psup_fare": "",
                  "tax": [
                    [
                      {
                        "OT": 4169
                      }
                    ]
                  ],
                  "ptype": "ADT",
                  "scur": "INR",
                  "sup_cur_fare": ""
                }
              ],
              "pr": {
                "bf": 28729,
                "tf": 32898,
                "tax": [
                  {
                    "OT": 4169
                  }
                ],
                "scur": "INR",
                "sup_fare": "",
                "sup_cur_fare": "",
                "cabb": "",
                "chekb": "",
                "seats": "",
                "meals": "",
                "chanb": "",
                "ftyp": ""
              }
            }
          ],
          "destorigins": [
            {
              "tid": "o61yNszIpTEIaQwV_1",
              "ref": 1,
              "sdur": "15:15",
              "re": "Refundable Before Departure",
              "stop": 1,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "MH",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "KUL",
                  "dd": "2023-06-30T00:15:00",
                  "ad": "2023-06-30T07:10:00",
                  "rbd": "Y",
                  "fn": "199",
                  "eq": "738",
                  "ac": "MH",
                  "ma": "MH",
                  "seat": "9",
                  "oa": "MH",
                  "fa": [],
                  "fdur": "04:25",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": "1"
                  }
                },
                {
                  "ref": 2,
                  "da": "KUL",
                  "ar": "DOH",
                  "dd": "2023-06-30T10:20:00",
                  "ad": "2023-06-30T13:00:00",
                  "rbd": "Q",
                  "fn": "9052",
                  "trn": "1",
                  "eq": "77W",
                  "ac": "MH",
                  "ma": "MH",
                  "seat": "4",
                  "oa": "QR",
                  "fa": [],
                  "fdur": "07:40",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "1",
                    "arr": ""
                  }
                }
              ],
              "flightNumbers": "199/9052",
              "bgd": [
                {
                  "qun": "30",
                  "wt": "kg"
                }
              ],
              "paxpr": [
                {
                  "tf": 130144,
                  "tax": [
                    {
                      "YQ": 12320
                    },
                    {
                      "OT": 9408
                    }
                  ],
                  "bf": 108416,
                  "fbc": "YIFMH",
                  "fareType": "RP",
                  "pusup_fare": "4840_5810",
                  "psup_fare": "108416_130144",
                  "ptype": "ADT"
                }
              ],
              "pr": {
                "bf": 108416,
                "tf": 130144,
                "tax": [
                  {
                    "YQ": 12320
                  },
                  {
                    "OT": 9408
                  }
                ],
                "scur": "QAR",
                "ucur": "INR",
                "exchange_rate": 22.4,
                "sup_fare": "4840_5810"
              }
            }
          ],
          "pr": {
            "bf": 137145,
            "tf": 163042,
            "tax": [
              {
                "OT": 4169
              },
              {
                "YQ": 12320
              },
              {
                "OT": 9408
              }
            ]
          },
          "paxpr": [
            {
              "bf": 137145,
              "tf": 163042,
              "tax": [
                [
                  {
                    "OT": 4169
                  }
                ],
                {
                  "YQ": 12320
                },
                {
                  "OT": 9408
                }
              ]
            }
          ],
          "sty": "split",
          "sup": "4/0",
          "airlineList": "6E_MH",
          "mix_carrier": true
        },
        {
          "srcorigns": [
            {
              "tid": "4B7yO6HNNcmNlngc_1",
              "ref": 1,
              "ofcid": "",
              "sdur": "14:10",
              "re": "",
              "sup": "4",
              "ft": "",
              "seg": [
                {
                  "ref": 1,
                  "da": "DOH",
                  "ar": "BOM",
                  "dd": "2023-06-29T19:45:00",
                  "ad": "2023-06-30T01:55:00",
                  "rbd": "",
                  "fn": "1306",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "arr": "2"
                  },
                  "rph": "",
                  "jsell": "6E~1306~ ~~DOH~06/29/2023 19:45~BOM~06/30/2023 01:55~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                },
                {
                  "ref": 2,
                  "da": "BOM",
                  "ar": "HYD",
                  "dd": "2023-06-30T05:20:00",
                  "ad": "2023-06-30T06:55:00",
                  "rbd": "",
                  "fn": " 461",
                  "trn": "",
                  "eq": "321",
                  "ac": "6E",
                  "ma": "6E",
                  "seat": "",
                  "oa": "6E",
                  "fa": [],
                  "terminal": {
                    "dep": "2"
                  },
                  "rph": "",
                  "jsell": "6E~ 461~ ~~BOM~06/30/2023 05:20~HYD~06/30/2023 06:55~~",
                  "cl": "Economy",
                  "flightTime": 0,
                  "fdur": "0:0"
                }
              ],
              "fareop": [
                {
                  "fareKey": "1~W~ ~6E~W0INT~5000~~0~7~~^2~L~ ~6E~LCIP~1070~~0~12~~X",
                  "index_id": 1,
                  "paxpr": [
                    {
                      "bf": 29825,
                      "fbc": "W0INT/LCIP",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "29825_34032",
                      "tax": [
                        {
                          "OT": 4207
                        }
                      ],
                      "tf": 34032
                    }
                  ],
                  "pr": {
                    "bf": 29825,
                    "tf": 34032,
                    "tax": [
                      {
                        "OT": 4207
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "29825_34032",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Regular - R"
                  },
                  "routeId": "mjfsn2pkpp",
                  "ruleNumber": "5000",
                  "productClass": "R"
                },
                {
                  "fareKey": "1~W~ ~6E~WUINT~5003~~0~12~~^2~L~ ~6E~LUIP~2009~~0~5~~X",
                  "index_id": 2,
                  "paxpr": [
                    {
                      "bf": 31125,
                      "fbc": "WUINT/LUIP",
                      "ptype": "ADT",
                      "scur": "QAR",
                      "sup_fare": "31125_35377",
                      "tax": [
                        {
                          "OT": 4252
                        }
                      ],
                      "tf": 35377
                    }
                  ],
                  "pr": {
                    "bf": 31125,
                    "tf": 35377,
                    "tax": [
                      {
                        "OT": 4252
                      }
                    ],
                    "ucur": "INR",
                    "exchange_rate": 1,
                    "scur": "INR",
                    "sup_fare": "31125_35377",
                    "sup_cur_fare": "",
                    "cabb": "",
                    "chekb": "",
                    "seats": "",
                    "meals": "",
                    "chanb": "",
                    "ftyp": "Flexi - J"
                  },
                  "routeId": "09r1p3tt7ij",
                  "ruleNumber": "5003",
                  "productClass": "J"
                }
              ],
              "tktair": "6E",
              "tecst": "no",
              "cl": "Economy",
              "indigo_ugkey": "Qjaaeqrl1XE=|aQFezKSWQkTEFuzy1c/XiE8E1gCe1mp7wL3TnlV5DA+x56hxCT+7ZWP6pBbfk21i1VpV7qcOGfUZINz5cX0sMPO7zDGGtcxXeJ5RNOP3ZplH1FG3CBzrsOYD796VyuQ29DfNwKuxQhE=",
              "stop": 1,
              "paxpr": [
                {
                  "bf": 29825,
                  "tf": 34032,
                  "fbc": "",
                  "psup_fare": "",
                  "tax": [
                    [
                      {
                        "OT": 4207
                      }
                    ]
                  ],
                  "ptype": "ADT",
                  "scur": "INR",
                  "sup_cur_fare": ""
                }
              ],
              "pr": {
                "bf": 29825,
                "tf": 34032,
                "tax": [
                  {
                    "OT": 4207
                  }
                ],
                "scur": "INR",
                "sup_fare": "",
                "sup_cur_fare": "",
                "cabb": "",
                "chekb": "",
                "seats": "",
                "meals": "",
                "chanb": "",
                "ftyp": ""
              }
            }
          ],
          "destorigins": [
            {
              "tid": "o61yNszIpTEIaQwV_1",
              "ref": 1,
              "sdur": "15:15",
              "re": "Refundable Before Departure",
              "stop": 1,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "MH",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "KUL",
                  "dd": "2023-06-30T00:15:00",
                  "ad": "2023-06-30T07:10:00",
                  "rbd": "Y",
                  "fn": "199",
                  "eq": "738",
                  "ac": "MH",
                  "ma": "MH",
                  "seat": "9",
                  "oa": "MH",
                  "fa": [],
                  "fdur": "04:25",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": "1"
                  }
                },
                {
                  "ref": 2,
                  "da": "KUL",
                  "ar": "DOH",
                  "dd": "2023-06-30T10:20:00",
                  "ad": "2023-06-30T13:00:00",
                  "rbd": "Q",
                  "fn": "9052",
                  "trn": "1",
                  "eq": "77W",
                  "ac": "MH",
                  "ma": "MH",
                  "seat": "4",
                  "oa": "QR",
                  "fa": [],
                  "fdur": "07:40",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "1",
                    "arr": ""
                  }
                }
              ],
              "flightNumbers": "199/9052",
              "bgd": [
                {
                  "qun": "30",
                  "wt": "kg"
                }
              ],
              "paxpr": [
                {
                  "tf": 130144,
                  "tax": [
                    {
                      "YQ": 12320
                    },
                    {
                      "OT": 9408
                    }
                  ],
                  "bf": 108416,
                  "fbc": "YIFMH",
                  "fareType": "RP",
                  "pusup_fare": "4840_5810",
                  "psup_fare": "108416_130144",
                  "ptype": "ADT"
                }
              ],
              "pr": {
                "bf": 108416,
                "tf": 130144,
                "tax": [
                  {
                    "YQ": 12320
                  },
                  {
                    "OT": 9408
                  }
                ],
                "scur": "QAR",
                "ucur": "INR",
                "exchange_rate": 22.4,
                "sup_fare": "4840_5810"
              }
            }
          ],
          "pr": {
            "bf": 138241,
            "tf": 164176,
            "tax": [
              {
                "OT": 4207
              },
              {
                "YQ": 12320
              },
              {
                "OT": 9408
              }
            ]
          },
          "paxpr": [
            {
              "bf": 138241,
              "tf": 164176,
              "tax": [
                [
                  {
                    "OT": 4207
                  }
                ],
                {
                  "YQ": 12320
                },
                {
                  "OT": 9408
                }
              ]
            }
          ],
          "sty": "split",
          "sup": "4/0",
          "airlineList": "6E_MH",
          "mix_carrier": true
        },
        {
          "srcorigns": [
            {
              "tid": "eu5XDxVL1EJHQb6o_1",
              "ref": 1,
              "sdur": "04:15",
              "re": "Refundable Before Departure",
              "stop": 0,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "QR",
              "seg": [
                {
                  "ref": 1,
                  "da": "DOH",
                  "ar": "HYD",
                  "dd": "2023-06-29T08:15:00",
                  "ad": "2023-06-29T15:00:00",
                  "rbd": "V",
                  "fn": "4776",
                  "eq": "320",
                  "ac": "QR",
                  "ma": "QR",
                  "seat": "9",
                  "oa": "6E",
                  "fa": [],
                  "fdur": "04:15",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": ""
                  }
                }
              ],
              "flightNumbers": "4776",
              "bgd": [
                {
                  "qun": "30",
                  "wt": "kg"
                }
              ],
              "paxpr": [
                {
                  "tf": 36064,
                  "tax": [
                    {
                      "YQ": 3136
                    },
                    {
                      "YR": 1344
                    },
                    {
                      "OT": 3136
                    }
                  ],
                  "bf": 28448,
                  "fbc": "VHQAI1SI",
                  "fareType": "RP",
                  "pusup_fare": "1270_1610",
                  "psup_fare": "28448_36064",
                  "ptype": "ADT"
                }
              ],
              "pr": {
                "bf": 28448,
                "tf": 36064,
                "tax": [
                  {
                    "YQ": 3136
                  },
                  {
                    "YR": 1344
                  },
                  {
                    "OT": 3136
                  }
                ],
                "scur": "QAR",
                "ucur": "INR",
                "exchange_rate": 22.4,
                "sup_fare": "1270_1610"
              }
            },
            {
              "tid": "50tefIVt11B2ZJT3_1",
              "ref": 1,
              "sdur": "04:15",
              "re": "Refundable Before Departure",
              "stop": 0,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "QR",
              "seg": [
                {
                  "ref": 1,
                  "da": "DOH",
                  "ar": "HYD",
                  "dd": "2023-06-29T02:00:00",
                  "ad": "2023-06-29T08:45:00",
                  "rbd": "V",
                  "fn": "4778",
                  "eq": "320",
                  "ac": "QR",
                  "ma": "QR",
                  "seat": "9",
                  "oa": "6E",
                  "fa": [],
                  "fdur": "04:15",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": ""
                  }
                }
              ],
              "flightNumbers": "4778",
              "bgd": [
                {
                  "qun": "30",
                  "wt": "kg"
                }
              ],
              "paxpr": [
                {
                  "tf": 36064,
                  "tax": [
                    {
                      "YQ": 3136
                    },
                    {
                      "YR": 1344
                    },
                    {
                      "OT": 3136
                    }
                  ],
                  "bf": 28448,
                  "fbc": "VHQAI1SI",
                  "fareType": "RP",
                  "pusup_fare": "1270_1610",
                  "psup_fare": "28448_36064",
                  "ptype": "ADT"
                }
              ],
              "pr": {
                "bf": 28448,
                "tf": 36064,
                "tax": [
                  {
                    "YQ": 3136
                  },
                  {
                    "YR": 1344
                  },
                  {
                    "OT": 3136
                  }
                ],
                "scur": "QAR",
                "ucur": "INR",
                "exchange_rate": 22.4,
                "sup_fare": "1270_1610"
              }
            }
          ],
          "destorigins": [
            {
              "tid": "o61yNszIpTEIaQwV_1",
              "ref": 1,
              "sdur": "15:15",
              "re": "Refundable Before Departure",
              "stop": 1,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "MH",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "KUL",
                  "dd": "2023-06-30T00:15:00",
                  "ad": "2023-06-30T07:10:00",
                  "rbd": "Y",
                  "fn": "199",
                  "eq": "738",
                  "ac": "MH",
                  "ma": "MH",
                  "seat": "9",
                  "oa": "MH",
                  "fa": [],
                  "fdur": "04:25",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": "1"
                  }
                },
                {
                  "ref": 2,
                  "da": "KUL",
                  "ar": "DOH",
                  "dd": "2023-06-30T10:20:00",
                  "ad": "2023-06-30T13:00:00",
                  "rbd": "Q",
                  "fn": "9052",
                  "trn": "1",
                  "eq": "77W",
                  "ac": "MH",
                  "ma": "MH",
                  "seat": "4",
                  "oa": "QR",
                  "fa": [],
                  "fdur": "07:40",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "1",
                    "arr": ""
                  }
                }
              ],
              "flightNumbers": "199/9052",
              "bgd": [
                {
                  "qun": "30",
                  "wt": "kg"
                }
              ],
              "paxpr": [
                {
                  "tf": 130144,
                  "tax": [
                    {
                      "YQ": 12320
                    },
                    {
                      "OT": 9408
                    }
                  ],
                  "bf": 108416,
                  "fbc": "YIFMH",
                  "fareType": "RP",
                  "pusup_fare": "4840_5810",
                  "psup_fare": "108416_130144",
                  "ptype": "ADT"
                }
              ],
              "pr": {
                "bf": 108416,
                "tf": 130144,
                "tax": [
                  {
                    "YQ": 12320
                  },
                  {
                    "OT": 9408
                  }
                ],
                "scur": "QAR",
                "ucur": "INR",
                "exchange_rate": 22.4,
                "sup_fare": "4840_5810"
              }
            }
          ],
          "pr": {
            "bf": 136864,
            "tf": 166208,
            "tax": [
              {
                "YQ": 3136
              },
              {
                "YR": 1344
              },
              {
                "OT": 3136
              },
              {
                "YQ": 12320
              },
              {
                "OT": 9408
              }
            ]
          },
          "paxpr": [
            {
              "bf": 136864,
              "tf": 166208,
              "tax": [
                {
                  "YQ": 3136
                },
                {
                  "YR": 1344
                },
                {
                  "OT": 3136
                },
                {
                  "YQ": 12320
                },
                {
                  "OT": 9408
                }
              ]
            }
          ],
          "sty": "split",
          "sup": "0/0",
          "airlineList": "6E_MH",
          "mix_carrier": true
        },
        {
          "srcorigns": [
            {
              "tid": "1RSk9SieyUL0ZAPu_1",
              "ref": 1,
              "sdur": "04:15",
              "re": "Refundable Before Departure",
              "stop": 0,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "QR",
              "seg": [
                {
                  "ref": 1,
                  "da": "DOH",
                  "ar": "HYD",
                  "dd": "2023-06-29T19:45:00",
                  "ad": "2023-06-30T02:30:00",
                  "rbd": "V",
                  "fn": "4774",
                  "eq": "320",
                  "ac": "QR",
                  "ma": "QR",
                  "seat": "9",
                  "oa": "6E",
                  "fa": [],
                  "fdur": "04:15",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": ""
                  }
                }
              ],
              "flightNumbers": "4774",
              "bgd": [
                {
                  "qun": "30",
                  "wt": "kg"
                }
              ],
              "paxpr": [
                {
                  "tf": 37856,
                  "tax": [
                    {
                      "YQ": 3136
                    },
                    {
                      "YR": 1344
                    },
                    {
                      "OT": 3136
                    }
                  ],
                  "bf": 30240,
                  "fbc": "VHQAI1SI",
                  "fareType": "RP",
                  "pusup_fare": "1350_1690",
                  "psup_fare": "30240_37856",
                  "ptype": "ADT"
                }
              ],
              "pr": {
                "bf": 30240,
                "tf": 37856,
                "tax": [
                  {
                    "YQ": 3136
                  },
                  {
                    "YR": 1344
                  },
                  {
                    "OT": 3136
                  }
                ],
                "scur": "QAR",
                "ucur": "INR",
                "exchange_rate": 22.4,
                "sup_fare": "1350_1690"
              }
            }
          ],
          "destorigins": [
            {
              "tid": "o61yNszIpTEIaQwV_1",
              "ref": 1,
              "sdur": "15:15",
              "re": "Refundable Before Departure",
              "stop": 1,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "MH",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "KUL",
                  "dd": "2023-06-30T00:15:00",
                  "ad": "2023-06-30T07:10:00",
                  "rbd": "Y",
                  "fn": "199",
                  "eq": "738",
                  "ac": "MH",
                  "ma": "MH",
                  "seat": "9",
                  "oa": "MH",
                  "fa": [],
                  "fdur": "04:25",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": "1"
                  }
                },
                {
                  "ref": 2,
                  "da": "KUL",
                  "ar": "DOH",
                  "dd": "2023-06-30T10:20:00",
                  "ad": "2023-06-30T13:00:00",
                  "rbd": "Q",
                  "fn": "9052",
                  "trn": "1",
                  "eq": "77W",
                  "ac": "MH",
                  "ma": "MH",
                  "seat": "4",
                  "oa": "QR",
                  "fa": [],
                  "fdur": "07:40",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "1",
                    "arr": ""
                  }
                }
              ],
              "flightNumbers": "199/9052",
              "bgd": [
                {
                  "qun": "30",
                  "wt": "kg"
                }
              ],
              "paxpr": [
                {
                  "tf": 130144,
                  "tax": [
                    {
                      "YQ": 12320
                    },
                    {
                      "OT": 9408
                    }
                  ],
                  "bf": 108416,
                  "fbc": "YIFMH",
                  "fareType": "RP",
                  "pusup_fare": "4840_5810",
                  "psup_fare": "108416_130144",
                  "ptype": "ADT"
                }
              ],
              "pr": {
                "bf": 108416,
                "tf": 130144,
                "tax": [
                  {
                    "YQ": 12320
                  },
                  {
                    "OT": 9408
                  }
                ],
                "scur": "QAR",
                "ucur": "INR",
                "exchange_rate": 22.4,
                "sup_fare": "4840_5810"
              }
            }
          ],
          "pr": {
            "bf": 138656,
            "tf": 168000,
            "tax": [
              {
                "YQ": 3136
              },
              {
                "YR": 1344
              },
              {
                "OT": 3136
              },
              {
                "YQ": 12320
              },
              {
                "OT": 9408
              }
            ]
          },
          "paxpr": [
            {
              "bf": 138656,
              "tf": 168000,
              "tax": [
                {
                  "YQ": 3136
                },
                {
                  "YR": 1344
                },
                {
                  "OT": 3136
                },
                {
                  "YQ": 12320
                },
                {
                  "OT": 9408
                }
              ]
            }
          ],
          "sty": "split",
          "sup": "0/0",
          "airlineList": "6E_MH",
          "mix_carrier": true
        },
        {
          "srcorigns": [
            {
              "tid": "nRCFoJF1c1hAMtiL_1",
              "ref": 1,
              "sdur": "08:55",
              "re": "Refundable Before Departure",
              "stop": 1,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "EK",
              "seg": [
                {
                  "ref": 1,
                  "da": "DOH",
                  "ar": "DXB",
                  "dd": "2023-06-29T21:00:00",
                  "ad": "2023-06-29T23:15:00",
                  "rbd": "I",
                  "fn": "6",
                  "eq": "7M8",
                  "ac": "FZ",
                  "ma": "FZ",
                  "seat": "4",
                  "oa": "FZ",
                  "fa": [],
                  "fdur": "01:15",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": "2"
                  }
                },
                {
                  "ref": 2,
                  "da": "DXB",
                  "ar": "HYD",
                  "dd": "2023-06-30T03:10:00",
                  "ad": "2023-06-30T08:25:00",
                  "rbd": "R",
                  "fn": "526",
                  "trn": "3",
                  "eq": "77W",
                  "ac": "EK",
                  "ma": "EK",
                  "seat": "9",
                  "oa": "EK",
                  "fa": [],
                  "fdur": "03:45",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "3",
                    "arr": ""
                  }
                }
              ],
              "flightNumbers": "6/526",
              "bgd": [
                {
                  "qun": "35",
                  "wt": "kg"
                }
              ]
            },
            {
              "tid": "AUGUphRL4ZS1s966_1",
              "ref": 1,
              "sdur": "07:35",
              "re": "Refundable Before Departure",
              "stop": 1,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "EK",
              "seg": [
                {
                  "ref": 1,
                  "da": "DOH",
                  "ar": "DXB",
                  "dd": "2023-06-29T17:00:00",
                  "ad": "2023-06-29T19:15:00",
                  "rbd": "I",
                  "fn": "18",
                  "eq": "7M8",
                  "ac": "FZ",
                  "ma": "FZ",
                  "seat": "4",
                  "oa": "FZ",
                  "fa": [],
                  "fdur": "01:15",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": "2"
                  }
                },
                {
                  "ref": 2,
                  "da": "DXB",
                  "ar": "HYD",
                  "dd": "2023-06-29T22:00:00",
                  "ad": "2023-06-30T03:05:00",
                  "rbd": "R",
                  "fn": "524",
                  "trn": "3",
                  "eq": "77W",
                  "ac": "EK",
                  "ma": "EK",
                  "seat": "9",
                  "oa": "EK",
                  "fa": [],
                  "fdur": "03:35",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "3",
                    "arr": ""
                  }
                }
              ],
              "flightNumbers": "18/524",
              "bgd": [
                {
                  "qun": "35",
                  "wt": "kg"
                }
              ]
            },
            {
              "tid": "JXw3R3VGiXn1MsLx_1",
              "ref": 1,
              "sdur": "07:45",
              "re": "Refundable Before Departure",
              "stop": 1,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "EK",
              "seg": [
                {
                  "ref": 1,
                  "da": "DOH",
                  "ar": "DXB",
                  "dd": "2023-06-29T10:00:00",
                  "ad": "2023-06-29T12:15:00",
                  "rbd": "I",
                  "fn": "2",
                  "eq": "7M8",
                  "ac": "FZ",
                  "ma": "FZ",
                  "seat": "4",
                  "oa": "FZ",
                  "fa": [],
                  "fdur": "01:15",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": "2"
                  }
                },
                {
                  "ref": 2,
                  "da": "DXB",
                  "ar": "HYD",
                  "dd": "2023-06-29T15:05:00",
                  "ad": "2023-06-29T20:15:00",
                  "rbd": "R",
                  "fn": "528",
                  "trn": "3",
                  "eq": "77W",
                  "ac": "EK",
                  "ma": "EK",
                  "seat": "9",
                  "oa": "EK",
                  "fa": [],
                  "fdur": "03:40",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "3",
                    "arr": ""
                  }
                }
              ],
              "flightNumbers": "2/528",
              "bgd": [
                {
                  "qun": "35",
                  "wt": "kg"
                }
              ]
            }
          ],
          "destorigins": [
            {
              "tid": "Ezb3x7iDx9z5LvuX_2",
              "ref": 2,
              "sdur": "13:35",
              "re": "Refundable Before Departure",
              "stop": 1,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "EK",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DXB",
                  "dd": "2023-06-30T21:50:00",
                  "ad": "2023-07-01T00:10:00",
                  "rbd": "R",
                  "fn": "529",
                  "eq": "77W",
                  "ac": "EK",
                  "ma": "EK",
                  "seat": "9",
                  "oa": "EK",
                  "fa": [],
                  "fdur": "03:50",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": "3"
                  }
                },
                {
                  "ref": 2,
                  "da": "DXB",
                  "ar": "DOH",
                  "dd": "2023-07-01T08:45:00",
                  "ad": "2023-07-01T08:55:00",
                  "rbd": "I",
                  "fn": "1",
                  "trn": "2",
                  "eq": "7M8",
                  "ac": "FZ",
                  "ma": "FZ",
                  "seat": "4",
                  "oa": "FZ",
                  "fa": [],
                  "fdur": "01:10",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "2",
                    "arr": ""
                  }
                }
              ],
              "flightNumbers": "529/1",
              "bgd": [
                {
                  "qun": "35",
                  "wt": "kg"
                }
              ]
            },
            {
              "tid": "lfeqEMZAlJMgDkwh_2",
              "ref": 2,
              "sdur": "20:35",
              "re": "Refundable Before Departure",
              "stop": 1,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "EK",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DXB",
                  "dd": "2023-06-30T21:50:00",
                  "ad": "2023-07-01T00:10:00",
                  "rbd": "R",
                  "fn": "529",
                  "eq": "77W",
                  "ac": "EK",
                  "ma": "EK",
                  "seat": "9",
                  "oa": "EK",
                  "fa": [],
                  "fdur": "03:50",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": "3"
                  }
                },
                {
                  "ref": 2,
                  "da": "DXB",
                  "ar": "DOH",
                  "dd": "2023-07-01T15:45:00",
                  "ad": "2023-07-01T15:55:00",
                  "rbd": "I",
                  "fn": "17",
                  "trn": "2",
                  "eq": "7M8",
                  "ac": "FZ",
                  "ma": "FZ",
                  "seat": "4",
                  "oa": "FZ",
                  "fa": [],
                  "fdur": "01:10",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "2",
                    "arr": ""
                  }
                }
              ],
              "flightNumbers": "529/17",
              "bgd": [
                {
                  "qun": "35",
                  "wt": "kg"
                }
              ]
            },
            {
              "tid": "AUGUphRL4ZS1s966_2",
              "ref": 2,
              "sdur": "08:25",
              "re": "Refundable Before Departure",
              "stop": 1,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "EK",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DXB",
                  "dd": "2023-06-30T10:00:00",
                  "ad": "2023-06-30T12:10:00",
                  "rbd": "R",
                  "fn": "527",
                  "eq": "77W",
                  "ac": "EK",
                  "ma": "EK",
                  "seat": "9",
                  "oa": "EK",
                  "fa": [],
                  "fdur": "03:40",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": "3"
                  }
                },
                {
                  "ref": 2,
                  "da": "DXB",
                  "ar": "DOH",
                  "dd": "2023-06-30T15:45:00",
                  "ad": "2023-06-30T15:55:00",
                  "rbd": "I",
                  "fn": "17",
                  "trn": "2",
                  "eq": "7M8",
                  "ac": "FZ",
                  "ma": "FZ",
                  "seat": "4",
                  "oa": "FZ",
                  "fa": [],
                  "fdur": "01:10",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "2",
                    "arr": ""
                  }
                }
              ],
              "flightNumbers": "527/17",
              "bgd": [
                {
                  "qun": "35",
                  "wt": "kg"
                }
              ]
            },
            {
              "tid": "G0kontIRVoreCdga_2",
              "ref": 2,
              "sdur": "12:25",
              "re": "Refundable Before Departure",
              "stop": 1,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "EK",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DXB",
                  "dd": "2023-06-30T10:00:00",
                  "ad": "2023-06-30T12:10:00",
                  "rbd": "R",
                  "fn": "527",
                  "eq": "77W",
                  "ac": "EK",
                  "ma": "EK",
                  "seat": "9",
                  "oa": "EK",
                  "fa": [],
                  "fdur": "03:40",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": "3"
                  }
                },
                {
                  "ref": 2,
                  "da": "DXB",
                  "ar": "DOH",
                  "dd": "2023-06-30T19:45:00",
                  "ad": "2023-06-30T19:55:00",
                  "rbd": "I",
                  "fn": "5",
                  "trn": "2",
                  "eq": "7M8",
                  "ac": "FZ",
                  "ma": "FZ",
                  "seat": "4",
                  "oa": "FZ",
                  "fa": [],
                  "fdur": "01:10",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "2",
                    "arr": ""
                  }
                }
              ],
              "flightNumbers": "527/5",
              "bgd": [
                {
                  "qun": "35",
                  "wt": "kg"
                }
              ]
            },
            {
              "tid": "JXw3R3VGiXn1MsLx_2",
              "ref": 2,
              "sdur": "06:50",
              "re": "Refundable Before Departure",
              "stop": 1,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "EK",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DXB",
                  "dd": "2023-06-30T04:35:00",
                  "ad": "2023-06-30T06:40:00",
                  "rbd": "R",
                  "fn": "525",
                  "eq": "77W",
                  "ac": "EK",
                  "ma": "EK",
                  "seat": "9",
                  "oa": "EK",
                  "fa": [],
                  "fdur": "03:35",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": "3"
                  }
                },
                {
                  "ref": 2,
                  "da": "DXB",
                  "ar": "DOH",
                  "dd": "2023-06-30T08:45:00",
                  "ad": "2023-06-30T08:55:00",
                  "rbd": "I",
                  "fn": "1",
                  "trn": "2",
                  "eq": "7M8",
                  "ac": "FZ",
                  "ma": "FZ",
                  "seat": "4",
                  "oa": "FZ",
                  "fa": [],
                  "fdur": "01:10",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "2",
                    "arr": ""
                  }
                }
              ],
              "flightNumbers": "525/1",
              "bgd": [
                {
                  "qun": "35",
                  "wt": "kg"
                }
              ]
            },
            {
              "tid": "ZM1FIhDQoL1RNZmP_2",
              "ref": 2,
              "sdur": "13:50",
              "re": "Refundable Before Departure",
              "stop": 1,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "EK",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DXB",
                  "dd": "2023-06-30T04:35:00",
                  "ad": "2023-06-30T06:40:00",
                  "rbd": "R",
                  "fn": "525",
                  "eq": "77W",
                  "ac": "EK",
                  "ma": "EK",
                  "seat": "9",
                  "oa": "EK",
                  "fa": [],
                  "fdur": "03:35",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": "3"
                  }
                },
                {
                  "ref": 2,
                  "da": "DXB",
                  "ar": "DOH",
                  "dd": "2023-06-30T15:45:00",
                  "ad": "2023-06-30T15:55:00",
                  "rbd": "I",
                  "fn": "17",
                  "trn": "2",
                  "eq": "7M8",
                  "ac": "FZ",
                  "ma": "FZ",
                  "seat": "4",
                  "oa": "FZ",
                  "fa": [],
                  "fdur": "01:10",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "2",
                    "arr": ""
                  }
                }
              ],
              "flightNumbers": "525/17",
              "bgd": [
                {
                  "qun": "35",
                  "wt": "kg"
                }
              ]
            },
            {
              "tid": "60a2HMay4A8yMhZi_2",
              "ref": 2,
              "sdur": "17:50",
              "re": "Refundable Before Departure",
              "stop": 1,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "EK",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DXB",
                  "dd": "2023-06-30T04:35:00",
                  "ad": "2023-06-30T06:40:00",
                  "rbd": "R",
                  "fn": "525",
                  "eq": "77W",
                  "ac": "EK",
                  "ma": "EK",
                  "seat": "9",
                  "oa": "EK",
                  "fa": [],
                  "fdur": "03:35",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": "3"
                  }
                },
                {
                  "ref": 2,
                  "da": "DXB",
                  "ar": "DOH",
                  "dd": "2023-06-30T19:45:00",
                  "ad": "2023-06-30T19:55:00",
                  "rbd": "I",
                  "fn": "5",
                  "trn": "2",
                  "eq": "7M8",
                  "ac": "FZ",
                  "ma": "FZ",
                  "seat": "4",
                  "oa": "FZ",
                  "fa": [],
                  "fdur": "01:10",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "2",
                    "arr": ""
                  }
                }
              ],
              "flightNumbers": "525/5",
              "bgd": [
                {
                  "qun": "35",
                  "wt": "kg"
                }
              ]
            }
          ],
          "pr": {
            "bf": 165088,
            "tf": 190848,
            "tax": [
              {
                "YQ": 17024
              },
              {
                "OT": 8736
              }
            ],
            "scur": "QAR",
            "ucur": "INR",
            "exchange_rate": 22.4,
            "sup_fare": "7370_8520"
          },
          "paxpr": [
            {
              "tf": 190848,
              "tax": [
                {
                  "YQ": 17024
                },
                {
                  "OT": 8736
                }
              ],
              "bf": 165088,
              "fbc": "RHXRFQA1",
              "fareType": "RP",
              "pusup_fare": "7370_8520",
              "psup_fare": "165088_190848",
              "ptype": "ADT"
            }
          ],
          "sup": "0",
          "airlinesList": "FZ_EK"
        },
        {
          "srcorigns": [
            {
              "tid": "byMKL17RhOlzUIXl_1",
              "ref": 1,
              "sdur": "07:45",
              "re": "Refundable Before Departure",
              "stop": 1,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "EK",
              "seg": [
                {
                  "ref": 1,
                  "da": "DOH",
                  "ar": "DXB",
                  "dd": "2023-06-29T10:00:00",
                  "ad": "2023-06-29T12:15:00",
                  "rbd": "I",
                  "fn": "2",
                  "eq": "7M8",
                  "ac": "FZ",
                  "ma": "FZ",
                  "seat": "4",
                  "oa": "FZ",
                  "fa": [],
                  "fdur": "01:15",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": "2"
                  }
                },
                {
                  "ref": 2,
                  "da": "DXB",
                  "ar": "HYD",
                  "dd": "2023-06-29T15:05:00",
                  "ad": "2023-06-29T20:15:00",
                  "rbd": "R",
                  "fn": "528",
                  "trn": "3",
                  "eq": "77W",
                  "ac": "EK",
                  "ma": "EK",
                  "seat": "9",
                  "oa": "EK",
                  "fa": [],
                  "fdur": "03:40",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "3",
                    "arr": ""
                  }
                }
              ],
              "flightNumbers": "2/528",
              "bgd": [
                {
                  "qun": "35",
                  "wt": "kg"
                }
              ]
            }
          ],
          "destorigins": [
            {
              "tid": "byMKL17RhOlzUIXl_2",
              "ref": 2,
              "sdur": "13:35",
              "re": "Refundable Before Departure",
              "stop": 1,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "EK",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DXB",
                  "dd": "2023-06-30T21:50:00",
                  "ad": "2023-07-01T00:10:00",
                  "rbd": "R",
                  "fn": "529",
                  "eq": "77W",
                  "ac": "EK",
                  "ma": "EK",
                  "seat": "9",
                  "oa": "EK",
                  "fa": [],
                  "fdur": "03:50",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": "3"
                  }
                },
                {
                  "ref": 2,
                  "da": "DXB",
                  "ar": "DOH",
                  "dd": "2023-07-01T08:45:00",
                  "ad": "2023-07-01T08:55:00",
                  "rbd": "I",
                  "fn": "1",
                  "trn": "2",
                  "eq": "7M8",
                  "ac": "FZ",
                  "ma": "FZ",
                  "seat": "4",
                  "oa": "FZ",
                  "fa": [],
                  "fdur": "01:10",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "2",
                    "arr": ""
                  }
                }
              ],
              "flightNumbers": "529/1",
              "bgd": [
                {
                  "qun": "35",
                  "wt": "kg"
                }
              ]
            },
            {
              "tid": "ThxZfDmclJS7qrDt_2",
              "ref": 2,
              "sdur": "20:35",
              "re": "Refundable Before Departure",
              "stop": 1,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "EK",
              "seg": [
                {
                  "ref": 1,
                  "da": "HYD",
                  "ar": "DXB",
                  "dd": "2023-06-30T21:50:00",
                  "ad": "2023-07-01T00:10:00",
                  "rbd": "R",
                  "fn": "529",
                  "eq": "77W",
                  "ac": "EK",
                  "ma": "EK",
                  "seat": "9",
                  "oa": "EK",
                  "fa": [],
                  "fdur": "03:50",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": "3"
                  }
                },
                {
                  "ref": 2,
                  "da": "DXB",
                  "ar": "DOH",
                  "dd": "2023-07-01T15:45:00",
                  "ad": "2023-07-01T15:55:00",
                  "rbd": "I",
                  "fn": "17",
                  "trn": "2",
                  "eq": "7M8",
                  "ac": "FZ",
                  "ma": "FZ",
                  "seat": "4",
                  "oa": "FZ",
                  "fa": [],
                  "fdur": "01:10",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "2",
                    "arr": ""
                  }
                }
              ],
              "flightNumbers": "529/17",
              "bgd": [
                {
                  "qun": "35",
                  "wt": "kg"
                }
              ]
            }
          ],
          "pr": {
            "bf": 165088,
            "tf": 190848,
            "tax": [
              {
                "YQ": 17024
              },
              {
                "OT": 8736
              }
            ],
            "scur": "QAR",
            "ucur": "INR",
            "exchange_rate": 22.4,
            "sup_fare": "7370_8520"
          },
          "paxpr": [
            {
              "tf": 190848,
              "tax": [
                {
                  "YQ": 17024
                },
                {
                  "OT": 8736
                }
              ],
              "bf": 165088,
              "fbc": "RHXRFQA1",
              "fareType": "RP",
              "pusup_fare": "7370_8520",
              "psup_fare": "165088_190848",
              "ptype": "ADT"
            }
          ],
          "sup": "0",
          "airlinesList": "FZ_EK"
        }
      ],
      "airports": [
        "MAA",
        "DOH",
        "HYD",
        "DEL",
        "BOM",
        "DOH",
        "DXB",
        "DXB",
        "HYD",
        "DOH",
        "HYD",
        "DEL",
        "JED",
        "BOM",
        "DXB",
        "KWI",
        "IST",
        "KUL",
        "DOH",
        "KWI",
        "HYD"
      ],
      "filter": {
        "nerby": [],
        "nerbyarr": [],
        "suplrs": [
          {
            "sup_name": "LCC",
            "sup_id": 4
          },
          {
            "sup_name": "Full Service",
            "sup_id": 4
          }
        ],
        "fstst": 13326,
        "chpst": 13326,
        "minprc": 13326,
        "maxprc": 190848,
        "best": 56448,
        "stps": [
          {
            "count": 0,
            "prc": 32479,
            "airline": "6E"
          },
          {
            "count": 1,
            "prc": 13326,
            "airline": "6E"
          },
          {
            "count": 2,
            "prc": 34272,
            "airline": "AI"
          }
        ],
        "airl": [
          {
            "count": 1,
            "prc": 13326,
            "airline": "6E"
          },
          {
            "count": 1,
            "prc": 26199,
            "airline": "J9"
          },
          {
            "count": 1,
            "prc": 28896,
            "airline": "AI"
          },
          {
            "count": 0,
            "prc": 52640,
            "airline": "QR"
          },
          {
            "count": 1,
            "prc": 123648,
            "airline": "FZ"
          }
        ]
      },
      "sec": [
        {
          "src": "DOH",
          "des": "HYD",
          "dd": "2023-06-29",
          "ad": "2023-06-30",
          "leg": 0
        }
      ],
      "echo": "rtrvbcrued",
      "cha": "B2C",
      "cid": "327",
      "tt": "2",
      "sty": "normal",
      "cur": "INR",
      "adt": 1,
      "chd": 0,
      "inf": 0,
      "mappings": [
        {
          "airportCode": "CCU",
          "airportName": "Netaji Subhas Chandra Bose International Airport",
          "cityName": "Kolkata",
          "countryName": "India",
          "countryCode": "IN"
        },
        {
          "airportCode": "HYD",
          "airportName": "Rajiv Gandhi International Airport",
          "cityName": "Hyderabad",
          "countryName": "India",
          "countryCode": "IN"
        },
        {
          "airportCode": "HYD",
          "airportName": "Rajiv Gandhi International Airport",
          "cityName": "Hyderabad",
          "countryName": "India",
          "countryCode": "IN"
        },
        {
          "airportCode": "HYD",
          "airportName": "Rajiv Gandhi International Airport",
          "cityName": "Hyderabad",
          "countryName": "India",
          "countryCode": "IN"
        },
        {
          "airportCode": "IST",
          "airportName": "Istanbul Ataturk Airport",
          "cityName": "Istanbul",
          "countryName": "Turkey",
          "countryCode": "TR"
        },
        {
          "airportCode": "IST",
          "airportName": "Istanbul Ataturk Airport",
          "cityName": "Istanbul",
          "countryName": "Turkey",
          "countryCode": "TR"
        },
        {
          "airportCode": "IST",
          "airportName": "Istanbul Ataturk Airport",
          "cityName": "Istanbul",
          "countryName": "Turkey",
          "countryCode": "TR"
        },
        {
          "airportCode": "JED",
          "airportName": "Jeddah King Abdul Aziz International Airport",
          "cityName": "Jeddah",
          "countryName": "Saudi Arabia",
          "countryCode": "SA"
        },
        {
          "airportCode": "KUL",
          "airportName": "Kuala Lumpur International Airport",
          "cityName": "Kuala Lumpur",
          "countryName": "Malaysia",
          "countryCode": "MY"
        },
        {
          "airportCode": "KUL",
          "airportName": "Kuala Lumpur International Airport",
          "cityName": "Kuala Lumpur",
          "countryName": "Malaysia",
          "countryCode": "MY"
        },
        {
          "airportCode": "KWI",
          "airportName": "Kuwait International airport",
          "cityName": "Kuwait City",
          "countryName": "Kuwait",
          "countryCode": "KW"
        },
        {
          "airportCode": "BLR",
          "airportName": "Kempegowda International Airport",
          "cityName": "Bangalore",
          "countryName": "India",
          "countryCode": "IN"
        },
        {
          "airportCode": "BOM",
          "airportName": "Chhatrapati Shivaji International Airport",
          "cityName": "Mumbai (Bombay)",
          "countryName": "India",
          "countryCode": "IN"
        },
        {
          "airportCode": "MAA",
          "airportName": "Chennai International Airport",
          "cityName": "Chennai",
          "countryName": "India",
          "countryCode": "IN"
        },
        {
          "airportCode": "MCT",
          "airportName": "Muscat International Airport",
          "cityName": "Muscat",
          "countryName": "Oman",
          "countryCode": "OM"
        },
        {
          "airportCode": "CMB",
          "airportName": "Bandaranayake International Airport",
          "cityName": "Colombo",
          "countryName": "Sri Lanka",
          "countryCode": "LK"
        },
        {
          "airportCode": "CMB",
          "airportName": "Bandaranayake International Airport",
          "cityName": "Colombo",
          "countryName": "Sri Lanka",
          "countryCode": "LK"
        },
        {
          "airportCode": "DEL",
          "airportName": "Indira Gandhi International Airport",
          "cityName": "New Delhi",
          "countryName": "India",
          "countryCode": "IN"
        },
        {
          "airportCode": "DOH",
          "airportName": "Hamad International Airport",
          "cityName": "Doha",
          "countryName": "Qatar",
          "countryCode": "QA"
        },
        {
          "airportCode": "DXB",
          "airportName": "Dubai International Airport",
          "cityName": "Dubai",
          "countryName": "United Arab Emirates",
          "countryCode": "AE"
        },
        {
          "airportCode": "DXB",
          "airportName": "Dubai International Airport",
          "cityName": "Dubai",
          "countryName": "United Arab Emirates",
          "countryCode": "AE"
        },
        {
          "airportCode": "DXB",
          "airportName": "Dubai International Airport",
          "cityName": "Dubai",
          "countryName": "United Arab Emirates",
          "countryCode": "AE"
        },
        {
          "airportCode": "DXB",
          "airportName": "Dubai International Airport",
          "cityName": "Dubai",
          "countryName": "United Arab Emirates",
          "countryCode": "AE"
        },
        {
          "airportCode": "DXB",
          "airportName": "Dubai International Airport",
          "cityName": "Dubai",
          "countryName": "United Arab Emirates",
          "countryCode": "AE"
        },
        {
          "airportCode": "IST",
          "airportName": "Istambul All",
          "countryName": "Turkey",
          "countryCode": "TU"
        },
        {
          "airportCode": "CMB",
          "airportName": "Colombo All",
          "countryName": "Sri Lanka",
          "countryCode": "LK"
        },
        {
          "airportCode": "KUL",
          "airportName": "Kuala Lumpur All",
          "countryName": "Malaysia",
          "countryCode": "MY"
        },
        {
          "airportCode": "HYD",
          "airportName": "Hyderabad All",
          "countryName": "India",
          "countryCode": "IN"
        }
      ]
    }
  ]
}
export const FLIGHT_RESPONSE_MULTI_CITY_3_LEGS = {
  "status": "success",
  "data": [
    {
      "origns": [
        {
          "srcorigns": [
            {
              "tid": "KtpZEeKraCQaroJ4_3",
              "ref": 3,
              "sdur": "11:45",
              "re": "Non-Refundable",
              "stop": 1,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "TK",
              "seg": [
                {
                  "ref": 1,
                  "da": "DXB",
                  "ar": "IST",
                  "dd": "2023-07-25T13:35:00",
                  "ad": "2023-07-25T17:20:00",
                  "rbd": "L",
                  "fn": "765",
                  "trn": "1",
                  "eq": "333",
                  "ac": "TK",
                  "ma": "TK",
                  "seat": "9",
                  "oa": "TK",
                  "fa": [],
                  "fdur": "04:45",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "1",
                    "arr": ""
                  }
                },
                {
                  "ref": 2,
                  "da": "IST",
                  "ar": "RUH",
                  "dd": "2023-07-25T20:15:00",
                  "ad": "2023-07-26T00:20:00",
                  "rbd": "L",
                  "fn": "140",
                  "eq": "321",
                  "ac": "TK",
                  "ma": "TK",
                  "seat": "9",
                  "oa": "TK",
                  "fa": [],
                  "fdur": "04:05",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": "2"
                  }
                }
              ],
              "flightNumbers": "765/140",
              "bgd": [
                {
                  "qun": "30",
                  "wt": "kg"
                }
              ]
            },
            {
              "tid": "KtpZEeKraCQaroJ4_2",
              "ref": 2,
              "sdur": "09:55",
              "re": "Non-Refundable",
              "stop": 1,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "TK",
              "seg": [
                {
                  "ref": 1,
                  "da": "LHR",
                  "ar": "IST",
                  "dd": "2023-07-21T11:25:00",
                  "ad": "2023-07-21T17:05:00",
                  "rbd": "Q",
                  "fn": "1980",
                  "trn": "2",
                  "eq": "77W",
                  "ac": "TK",
                  "ma": "TK",
                  "seat": "7",
                  "oa": "TK",
                  "fa": [],
                  "fdur": "03:40",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "2",
                    "arr": ""
                  }
                },
                {
                  "ref": 2,
                  "da": "IST",
                  "ar": "DXB",
                  "dd": "2023-07-21T19:00:00",
                  "ad": "2023-07-22T00:20:00",
                  "rbd": "Q",
                  "fn": "760",
                  "eq": "77W",
                  "ac": "TK",
                  "ma": "TK",
                  "seat": "9",
                  "oa": "TK",
                  "fa": [],
                  "fdur": "04:20",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": "1"
                  }
                }
              ],
              "flightNumbers": "1980/760",
              "bgd": [
                {
                  "qun": "30",
                  "wt": "kg"
                }
              ]
            },
            {
              "tid": "UTXKBSWoM5K6k2UM_2",
              "ref": 2,
              "sdur": "12:00",
              "re": "Non-Refundable",
              "stop": 1,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "TK",
              "seg": [
                {
                  "ref": 1,
                  "da": "LHR",
                  "ar": "IST",
                  "dd": "2023-07-21T11:25:00",
                  "ad": "2023-07-21T17:05:00",
                  "rbd": "Q",
                  "fn": "1980",
                  "trn": "2",
                  "eq": "77W",
                  "ac": "TK",
                  "ma": "TK",
                  "seat": "7",
                  "oa": "TK",
                  "fa": [],
                  "fdur": "03:40",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "2",
                    "arr": ""
                  }
                },
                {
                  "ref": 2,
                  "da": "IST",
                  "ar": "DXB",
                  "dd": "2023-07-21T21:00:00",
                  "ad": "2023-07-22T02:25:00",
                  "rbd": "Q",
                  "fn": "758",
                  "eq": "333",
                  "ac": "TK",
                  "ma": "TK",
                  "seat": "9",
                  "oa": "TK",
                  "fa": [],
                  "fdur": "04:25",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": "1"
                  }
                }
              ],
              "flightNumbers": "1980/758",
              "bgd": [
                {
                  "qun": "30",
                  "wt": "kg"
                }
              ]
            },
            {
              "tid": "JZqZgZZXKG8DPUiR_2",
              "ref": 2,
              "sdur": "15:30",
              "re": "Non-Refundable",
              "stop": 1,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "TK",
              "seg": [
                {
                  "ref": 1,
                  "da": "LHR",
                  "ar": "IST",
                  "dd": "2023-07-21T11:25:00",
                  "ad": "2023-07-21T17:05:00",
                  "rbd": "Q",
                  "fn": "1980",
                  "trn": "2",
                  "eq": "77W",
                  "ac": "TK",
                  "ma": "TK",
                  "seat": "7",
                  "oa": "TK",
                  "fa": [],
                  "fdur": "03:40",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "2",
                    "arr": ""
                  }
                },
                {
                  "ref": 2,
                  "da": "IST",
                  "ar": "DXB",
                  "dd": "2023-07-22T00:40:00",
                  "ad": "2023-07-22T05:55:00",
                  "rbd": "Q",
                  "fn": "762",
                  "eq": "333",
                  "ac": "TK",
                  "ma": "TK",
                  "seat": "9",
                  "oa": "TK",
                  "fa": [],
                  "fdur": "04:15",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": "1"
                  }
                }
              ],
              "flightNumbers": "1980/762",
              "bgd": [
                {
                  "qun": "30",
                  "wt": "kg"
                }
              ]
            },
            {
              "tid": "KtpZEeKraCQaroJ4_1",
              "ref": 1,
              "sdur": "09:55",
              "re": "Non-Refundable",
              "stop": 1,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "TK",
              "seg": [
                {
                  "ref": 1,
                  "da": "RUH",
                  "ar": "IST",
                  "dd": "2023-07-17T01:10:00",
                  "ad": "2023-07-17T05:30:00",
                  "rbd": "L",
                  "fn": "141",
                  "trn": "2",
                  "eq": "321",
                  "ac": "TK",
                  "ma": "TK",
                  "seat": "9",
                  "oa": "TK",
                  "fa": [],
                  "fdur": "04:20",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "2",
                    "arr": ""
                  }
                },
                {
                  "ref": 2,
                  "da": "IST",
                  "ar": "DXB",
                  "dd": "2023-07-17T06:40:00",
                  "ad": "2023-07-17T12:05:00",
                  "rbd": "L",
                  "fn": "764",
                  "eq": "333",
                  "ac": "TK",
                  "ma": "TK",
                  "seat": "9",
                  "oa": "TK",
                  "fa": [],
                  "fdur": "04:25",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": "1"
                  }
                }
              ],
              "flightNumbers": "141/764",
              "bgd": [
                {
                  "qun": "30",
                  "wt": "kg"
                }
              ]
            }
          ],
          "pr": {
            "bf": 61600,
            "tf": 116928,
            "tax": [
              {
                "YR": 31584
              },
              {
                "OT": 23744
              },
              {
                "dealmkp": 0
              },
              {
                "attmkp": 0
              },
              {
                "tamkp": 0
              },
              {
                "stamkp": 0
              },
              {
                "attdisc": 0
              },
              {
                "tadisc": 0
              },
              {
                "stadisc": 0
              }
            ],
            "scur": "QAR",
            "ucur": "INR",
            "exchange_rate": 22.4,
            "sup_fare": "2750_5220",
            "markupnames": []
          },
          "paxpr": [
            {
              "tf": 116928,
              "tax": [
                {
                  "YR": 31584
                },
                {
                  "OT": 23744
                },
                {
                  "attmkp": 0
                },
                {
                  "tamkp": 0
                },
                {
                  "stamkp": 0
                },
                {
                  "attdisc": 0
                },
                {
                  "tadisc": 0
                },
                {
                  "stadisc": 0
                }
              ],
              "bf": 61600,
              "fbc": "LLS2XPX",
              "fareType": "RP",
              "pusup_fare": "2750_5220",
              "psup_fare": "61600_116928",
              "ptype": "ADT"
            }
          ],
          "sup": "0",
          "airlinesList": "TK",
          "oaairlineList": "TK",
          "servicefee": []
        },
        {
          "srcorigns": [
            {
              "tid": "HixpADJCSEQoJFQt_2",
              "ref": 2,
              "sdur": "10:55",
              "re": "Non-Refundable",
              "stop": 1,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "TK",
              "seg": [
                {
                  "ref": 1,
                  "da": "LHR",
                  "ar": "IST",
                  "dd": "2023-07-21T22:10:00",
                  "ad": "2023-07-22T03:55:00",
                  "rbd": "Q",
                  "fn": "1984",
                  "trn": "2",
                  "eq": "321",
                  "ac": "TK",
                  "ma": "TK",
                  "seat": "9",
                  "oa": "TK",
                  "fa": [],
                  "fdur": "03:45",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "2",
                    "arr": ""
                  }
                },
                {
                  "ref": 2,
                  "da": "IST",
                  "ar": "DXB",
                  "dd": "2023-07-22T06:40:00",
                  "ad": "2023-07-22T12:05:00",
                  "rbd": "Q",
                  "fn": "764",
                  "eq": "333",
                  "ac": "TK",
                  "ma": "TK",
                  "seat": "9",
                  "oa": "TK",
                  "fa": [],
                  "fdur": "04:25",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": "1"
                  }
                }
              ],
              "flightNumbers": "1984/764",
              "bgd": [
                {
                  "qun": "30",
                  "wt": "kg"
                }
              ]
            },
            {
              "tid": "C0dgWWTz58boRBbp_2",
              "ref": 2,
              "sdur": "14:35",
              "re": "Non-Refundable",
              "stop": 1,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "TK",
              "seg": [
                {
                  "ref": 1,
                  "da": "LHR",
                  "ar": "IST",
                  "dd": "2023-07-21T18:30:00",
                  "ad": "2023-07-22T00:10:00",
                  "rbd": "Q",
                  "fn": "1972",
                  "trn": "2",
                  "eq": "333",
                  "ac": "TK",
                  "ma": "TK",
                  "seat": "9",
                  "oa": "TK",
                  "fa": [],
                  "fdur": "03:40",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "2",
                    "arr": ""
                  }
                },
                {
                  "ref": 2,
                  "da": "IST",
                  "ar": "DXB",
                  "dd": "2023-07-22T06:40:00",
                  "ad": "2023-07-22T12:05:00",
                  "rbd": "Q",
                  "fn": "764",
                  "eq": "333",
                  "ac": "TK",
                  "ma": "TK",
                  "seat": "9",
                  "oa": "TK",
                  "fa": [],
                  "fdur": "04:25",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": "1"
                  }
                }
              ],
              "flightNumbers": "1972/764",
              "bgd": [
                {
                  "qun": "30",
                  "wt": "kg"
                }
              ]
            },
            {
              "tid": "yP8T7UQkE5XBAh1y_2",
              "ref": 2,
              "sdur": "10:15",
              "re": "Non-Refundable",
              "stop": 1,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "TK",
              "seg": [
                {
                  "ref": 1,
                  "da": "LHR",
                  "ar": "IST",
                  "dd": "2023-07-21T16:40:00",
                  "ad": "2023-07-21T22:30:00",
                  "rbd": "Q",
                  "fn": "1986",
                  "trn": "2",
                  "eq": "333",
                  "ac": "TK",
                  "ma": "TK",
                  "seat": "9",
                  "oa": "TK",
                  "fa": [],
                  "fdur": "03:50",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "2",
                    "arr": ""
                  }
                },
                {
                  "ref": 2,
                  "da": "IST",
                  "ar": "DXB",
                  "dd": "2023-07-22T00:40:00",
                  "ad": "2023-07-22T05:55:00",
                  "rbd": "Q",
                  "fn": "762",
                  "eq": "333",
                  "ac": "TK",
                  "ma": "TK",
                  "seat": "9",
                  "oa": "TK",
                  "fa": [],
                  "fdur": "04:15",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": "1"
                  }
                }
              ],
              "flightNumbers": "1986/762",
              "bgd": [
                {
                  "qun": "30",
                  "wt": "kg"
                }
              ]
            },
            {
              "tid": "yP8T7UQkE5XBAh1y_3",
              "ref": 3,
              "sdur": "11:45",
              "re": "Non-Refundable",
              "stop": 1,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "TK",
              "seg": [
                {
                  "ref": 1,
                  "da": "DXB",
                  "ar": "IST",
                  "dd": "2023-07-25T13:35:00",
                  "ad": "2023-07-25T17:20:00",
                  "rbd": "L",
                  "fn": "765",
                  "trn": "1",
                  "eq": "333",
                  "ac": "TK",
                  "ma": "TK",
                  "seat": "9",
                  "oa": "TK",
                  "fa": [],
                  "fdur": "04:45",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "1",
                    "arr": ""
                  }
                },
                {
                  "ref": 2,
                  "da": "IST",
                  "ar": "RUH",
                  "dd": "2023-07-25T20:15:00",
                  "ad": "2023-07-26T00:20:00",
                  "rbd": "L",
                  "fn": "140",
                  "eq": "321",
                  "ac": "TK",
                  "ma": "TK",
                  "seat": "9",
                  "oa": "TK",
                  "fa": [],
                  "fdur": "04:05",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": "2"
                  }
                }
              ],
              "flightNumbers": "765/140",
              "bgd": [
                {
                  "qun": "30",
                  "wt": "kg"
                }
              ]
            },
            {
              "tid": "yP8T7UQkE5XBAh1y_1",
              "ref": 1,
              "sdur": "09:55",
              "re": "Non-Refundable",
              "stop": 1,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "TK",
              "seg": [
                {
                  "ref": 1,
                  "da": "RUH",
                  "ar": "IST",
                  "dd": "2023-07-17T01:10:00",
                  "ad": "2023-07-17T05:30:00",
                  "rbd": "L",
                  "fn": "141",
                  "trn": "2",
                  "eq": "321",
                  "ac": "TK",
                  "ma": "TK",
                  "seat": "9",
                  "oa": "TK",
                  "fa": [],
                  "fdur": "04:20",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "2",
                    "arr": ""
                  }
                },
                {
                  "ref": 2,
                  "da": "IST",
                  "ar": "DXB",
                  "dd": "2023-07-17T06:40:00",
                  "ad": "2023-07-17T12:05:00",
                  "rbd": "L",
                  "fn": "764",
                  "eq": "333",
                  "ac": "TK",
                  "ma": "TK",
                  "seat": "9",
                  "oa": "TK",
                  "fa": [],
                  "fdur": "04:25",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": "1"
                  }
                }
              ],
              "flightNumbers": "141/764",
              "bgd": [
                {
                  "qun": "30",
                  "wt": "kg"
                }
              ]
            }
          ],
          "pr": {
            "bf": 61600,
            "tf": 116928,
            "tax": [
              {
                "YR": 31584
              },
              {
                "OT": 23744
              },
              {
                "dealmkp": 0
              },
              {
                "attmkp": 0
              },
              {
                "tamkp": 0
              },
              {
                "stamkp": 0
              },
              {
                "attdisc": 0
              },
              {
                "tadisc": 0
              },
              {
                "stadisc": 0
              }
            ],
            "scur": "QAR",
            "ucur": "INR",
            "exchange_rate": 22.4,
            "sup_fare": "2750_5220",
            "markupnames": []
          },
          "paxpr": [
            {
              "tf": 116928,
              "tax": [
                {
                  "YR": 31584
                },
                {
                  "OT": 23744
                },
                {
                  "attmkp": 0
                },
                {
                  "tamkp": 0
                },
                {
                  "stamkp": 0
                },
                {
                  "attdisc": 0
                },
                {
                  "tadisc": 0
                },
                {
                  "stadisc": 0
                }
              ],
              "bf": 61600,
              "fbc": "LLS2XPX",
              "fareType": "RP",
              "pusup_fare": "2750_5220",
              "psup_fare": "61600_116928",
              "ptype": "ADT"
            }
          ],
          "sup": "0",
          "airlinesList": "TK",
          "oaairlineList": "TK",
          "servicefee": []
        },
        {
          "srcorigns": [
            {
              "tid": "VvTwT0zCHe7kPeze_2",
              "ref": 2,
              "sdur": "09:55",
              "re": "Non-Refundable",
              "stop": 1,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "TK",
              "seg": [
                {
                  "ref": 1,
                  "da": "LHR",
                  "ar": "IST",
                  "dd": "2023-07-21T11:25:00",
                  "ad": "2023-07-21T17:05:00",
                  "rbd": "Q",
                  "fn": "1980",
                  "trn": "2",
                  "eq": "77W",
                  "ac": "TK",
                  "ma": "TK",
                  "seat": "7",
                  "oa": "TK",
                  "fa": [],
                  "fdur": "03:40",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "2",
                    "arr": ""
                  }
                },
                {
                  "ref": 2,
                  "da": "IST",
                  "ar": "DXB",
                  "dd": "2023-07-21T19:00:00",
                  "ad": "2023-07-22T00:20:00",
                  "rbd": "Q",
                  "fn": "760",
                  "eq": "77W",
                  "ac": "TK",
                  "ma": "TK",
                  "seat": "9",
                  "oa": "TK",
                  "fa": [],
                  "fdur": "04:20",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": "1"
                  }
                }
              ],
              "flightNumbers": "1980/760",
              "bgd": [
                {
                  "qun": "30",
                  "wt": "kg"
                }
              ]
            },
            {
              "tid": "hrPQyOOquUTXvKyJ_2",
              "ref": 2,
              "sdur": "12:00",
              "re": "Non-Refundable",
              "stop": 1,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "TK",
              "seg": [
                {
                  "ref": 1,
                  "da": "LHR",
                  "ar": "IST",
                  "dd": "2023-07-21T11:25:00",
                  "ad": "2023-07-21T17:05:00",
                  "rbd": "Q",
                  "fn": "1980",
                  "trn": "2",
                  "eq": "77W",
                  "ac": "TK",
                  "ma": "TK",
                  "seat": "7",
                  "oa": "TK",
                  "fa": [],
                  "fdur": "03:40",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "2",
                    "arr": ""
                  }
                },
                {
                  "ref": 2,
                  "da": "IST",
                  "ar": "DXB",
                  "dd": "2023-07-21T21:00:00",
                  "ad": "2023-07-22T02:25:00",
                  "rbd": "Q",
                  "fn": "758",
                  "eq": "333",
                  "ac": "TK",
                  "ma": "TK",
                  "seat": "9",
                  "oa": "TK",
                  "fa": [],
                  "fdur": "04:25",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": "1"
                  }
                }
              ],
              "flightNumbers": "1980/758",
              "bgd": [
                {
                  "qun": "30",
                  "wt": "kg"
                }
              ]
            },
            {
              "tid": "VvTwT0zCHe7kPeze_3",
              "ref": 3,
              "sdur": "15:10",
              "re": "Non-Refundable",
              "stop": 1,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "TK",
              "seg": [
                {
                  "ref": 1,
                  "da": "DXB",
                  "ar": "IST",
                  "dd": "2023-07-25T10:10:00",
                  "ad": "2023-07-25T14:00:00",
                  "rbd": "L",
                  "fn": "759",
                  "trn": "1",
                  "eq": "333",
                  "ac": "TK",
                  "ma": "TK",
                  "seat": "9",
                  "oa": "TK",
                  "fa": [],
                  "fdur": "04:50",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "1",
                    "arr": ""
                  }
                },
                {
                  "ref": 2,
                  "da": "IST",
                  "ar": "RUH",
                  "dd": "2023-07-25T20:15:00",
                  "ad": "2023-07-26T00:20:00",
                  "rbd": "L",
                  "fn": "140",
                  "eq": "321",
                  "ac": "TK",
                  "ma": "TK",
                  "seat": "1",
                  "oa": "TK",
                  "fa": [],
                  "fdur": "04:05",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": "2"
                  }
                }
              ],
              "flightNumbers": "759/140",
              "bgd": [
                {
                  "qun": "30",
                  "wt": "kg"
                }
              ]
            },
            {
              "tid": "VvTwT0zCHe7kPeze_1",
              "ref": 1,
              "sdur": "09:55",
              "re": "Non-Refundable",
              "stop": 1,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "TK",
              "seg": [
                {
                  "ref": 1,
                  "da": "RUH",
                  "ar": "IST",
                  "dd": "2023-07-17T01:10:00",
                  "ad": "2023-07-17T05:30:00",
                  "rbd": "L",
                  "fn": "141",
                  "trn": "2",
                  "eq": "321",
                  "ac": "TK",
                  "ma": "TK",
                  "seat": "9",
                  "oa": "TK",
                  "fa": [],
                  "fdur": "04:20",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "2",
                    "arr": ""
                  }
                },
                {
                  "ref": 2,
                  "da": "IST",
                  "ar": "DXB",
                  "dd": "2023-07-17T06:40:00",
                  "ad": "2023-07-17T12:05:00",
                  "rbd": "L",
                  "fn": "764",
                  "eq": "333",
                  "ac": "TK",
                  "ma": "TK",
                  "seat": "9",
                  "oa": "TK",
                  "fa": [],
                  "fdur": "04:25",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": "1"
                  }
                }
              ],
              "flightNumbers": "141/764",
              "bgd": [
                {
                  "qun": "30",
                  "wt": "kg"
                }
              ]
            }
          ],
          "pr": {
            "bf": 61600,
            "tf": 116928,
            "tax": [
              {
                "YR": 31584
              },
              {
                "OT": 23744
              },
              {
                "dealmkp": 0
              },
              {
                "attmkp": 0
              },
              {
                "tamkp": 0
              },
              {
                "stamkp": 0
              },
              {
                "attdisc": 0
              },
              {
                "tadisc": 0
              },
              {
                "stadisc": 0
              }
            ],
            "scur": "QAR",
            "ucur": "INR",
            "exchange_rate": 22.4,
            "sup_fare": "2750_5220",
            "markupnames": []
          },
          "paxpr": [
            {
              "tf": 116928,
              "tax": [
                {
                  "YR": 31584
                },
                {
                  "OT": 23744
                },
                {
                  "attmkp": 0
                },
                {
                  "tamkp": 0
                },
                {
                  "stamkp": 0
                },
                {
                  "attdisc": 0
                },
                {
                  "tadisc": 0
                },
                {
                  "stadisc": 0
                }
              ],
              "bf": 61600,
              "fbc": "LLS2XPX",
              "fareType": "RP",
              "pusup_fare": "2750_5220",
              "psup_fare": "61600_116928",
              "ptype": "ADT"
            }
          ],
          "sup": "0",
          "airlinesList": "TK",
          "oaairlineList": "TK",
          "servicefee": []
        },
        {
          "srcorigns": [
            {
              "tid": "M5Gt0vQpplKi9N3L_2",
              "ref": 2,
              "sdur": "10:55",
              "re": "Non-Refundable",
              "stop": 1,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "TK",
              "seg": [
                {
                  "ref": 1,
                  "da": "LHR",
                  "ar": "IST",
                  "dd": "2023-07-21T22:10:00",
                  "ad": "2023-07-22T03:55:00",
                  "rbd": "Q",
                  "fn": "1984",
                  "trn": "2",
                  "eq": "321",
                  "ac": "TK",
                  "ma": "TK",
                  "seat": "9",
                  "oa": "TK",
                  "fa": [],
                  "fdur": "03:45",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "2",
                    "arr": ""
                  }
                },
                {
                  "ref": 2,
                  "da": "IST",
                  "ar": "DXB",
                  "dd": "2023-07-22T06:40:00",
                  "ad": "2023-07-22T12:05:00",
                  "rbd": "Q",
                  "fn": "764",
                  "eq": "333",
                  "ac": "TK",
                  "ma": "TK",
                  "seat": "9",
                  "oa": "TK",
                  "fa": [],
                  "fdur": "04:25",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": "1"
                  }
                }
              ],
              "flightNumbers": "1984/764",
              "bgd": [
                {
                  "qun": "30",
                  "wt": "kg"
                }
              ]
            },
            {
              "tid": "FOsZA355s8mqLo4T_2",
              "ref": 2,
              "sdur": "10:15",
              "re": "Non-Refundable",
              "stop": 1,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "TK",
              "seg": [
                {
                  "ref": 1,
                  "da": "LHR",
                  "ar": "IST",
                  "dd": "2023-07-21T16:40:00",
                  "ad": "2023-07-21T22:30:00",
                  "rbd": "Q",
                  "fn": "1986",
                  "trn": "2",
                  "eq": "333",
                  "ac": "TK",
                  "ma": "TK",
                  "seat": "9",
                  "oa": "TK",
                  "fa": [],
                  "fdur": "03:50",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "2",
                    "arr": ""
                  }
                },
                {
                  "ref": 2,
                  "da": "IST",
                  "ar": "DXB",
                  "dd": "2023-07-22T00:40:00",
                  "ad": "2023-07-22T05:55:00",
                  "rbd": "Q",
                  "fn": "762",
                  "eq": "333",
                  "ac": "TK",
                  "ma": "TK",
                  "seat": "9",
                  "oa": "TK",
                  "fa": [],
                  "fdur": "04:15",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": "1"
                  }
                }
              ],
              "flightNumbers": "1986/762",
              "bgd": [
                {
                  "qun": "30",
                  "wt": "kg"
                }
              ]
            },
            {
              "tid": "FOsZA355s8mqLo4T_3",
              "ref": 3,
              "sdur": "15:10",
              "re": "Non-Refundable",
              "stop": 1,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "TK",
              "seg": [
                {
                  "ref": 1,
                  "da": "DXB",
                  "ar": "IST",
                  "dd": "2023-07-25T10:10:00",
                  "ad": "2023-07-25T14:00:00",
                  "rbd": "L",
                  "fn": "759",
                  "trn": "1",
                  "eq": "333",
                  "ac": "TK",
                  "ma": "TK",
                  "seat": "9",
                  "oa": "TK",
                  "fa": [],
                  "fdur": "04:50",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "1",
                    "arr": ""
                  }
                },
                {
                  "ref": 2,
                  "da": "IST",
                  "ar": "RUH",
                  "dd": "2023-07-25T20:15:00",
                  "ad": "2023-07-26T00:20:00",
                  "rbd": "L",
                  "fn": "140",
                  "eq": "321",
                  "ac": "TK",
                  "ma": "TK",
                  "seat": "1",
                  "oa": "TK",
                  "fa": [],
                  "fdur": "04:05",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": "2"
                  }
                }
              ],
              "flightNumbers": "759/140",
              "bgd": [
                {
                  "qun": "30",
                  "wt": "kg"
                }
              ]
            },
            {
              "tid": "FOsZA355s8mqLo4T_1",
              "ref": 1,
              "sdur": "09:55",
              "re": "Non-Refundable",
              "stop": 1,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "TK",
              "seg": [
                {
                  "ref": 1,
                  "da": "RUH",
                  "ar": "IST",
                  "dd": "2023-07-17T01:10:00",
                  "ad": "2023-07-17T05:30:00",
                  "rbd": "L",
                  "fn": "141",
                  "trn": "2",
                  "eq": "321",
                  "ac": "TK",
                  "ma": "TK",
                  "seat": "9",
                  "oa": "TK",
                  "fa": [],
                  "fdur": "04:20",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "2",
                    "arr": ""
                  }
                },
                {
                  "ref": 2,
                  "da": "IST",
                  "ar": "DXB",
                  "dd": "2023-07-17T06:40:00",
                  "ad": "2023-07-17T12:05:00",
                  "rbd": "L",
                  "fn": "764",
                  "eq": "333",
                  "ac": "TK",
                  "ma": "TK",
                  "seat": "9",
                  "oa": "TK",
                  "fa": [],
                  "fdur": "04:25",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": "1"
                  }
                }
              ],
              "flightNumbers": "141/764",
              "bgd": [
                {
                  "qun": "30",
                  "wt": "kg"
                }
              ]
            }
          ],
          "pr": {
            "bf": 61600,
            "tf": 116928,
            "tax": [
              {
                "YR": 31584
              },
              {
                "OT": 23744
              },
              {
                "dealmkp": 0
              },
              {
                "attmkp": 0
              },
              {
                "tamkp": 0
              },
              {
                "stamkp": 0
              },
              {
                "attdisc": 0
              },
              {
                "tadisc": 0
              },
              {
                "stadisc": 0
              }
            ],
            "scur": "QAR",
            "ucur": "INR",
            "exchange_rate": 22.4,
            "sup_fare": "2750_5220",
            "markupnames": []
          },
          "paxpr": [
            {
              "tf": 116928,
              "tax": [
                {
                  "YR": 31584
                },
                {
                  "OT": 23744
                },
                {
                  "attmkp": 0
                },
                {
                  "tamkp": 0
                },
                {
                  "stamkp": 0
                },
                {
                  "attdisc": 0
                },
                {
                  "tadisc": 0
                },
                {
                  "stadisc": 0
                }
              ],
              "bf": 61600,
              "fbc": "LLS2XPX",
              "fareType": "RP",
              "pusup_fare": "2750_5220",
              "psup_fare": "61600_116928",
              "ptype": "ADT"
            }
          ],
          "sup": "0",
          "airlinesList": "TK",
          "oaairlineList": "TK",
          "servicefee": []
        },
        {
          "srcorigns": [
            {
              "tid": "7HdkE859nfWMHEDe_1",
              "ref": 1,
              "sdur": "02:10",
              "re": "Refundable Before Departure",
              "stop": 0,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "EK",
              "seg": [
                {
                  "ref": 1,
                  "da": "RUH",
                  "ar": "DXB",
                  "dd": "2023-07-17T21:25:00",
                  "ad": "2023-07-18T00:35:00",
                  "rbd": "L",
                  "fn": "818",
                  "trn": "2",
                  "eq": "77W",
                  "ac": "EK",
                  "ma": "EK",
                  "seat": "9",
                  "oa": "EK",
                  "fa": [],
                  "fdur": "02:10",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "2",
                    "arr": "3"
                  }
                }
              ],
              "flightNumbers": "818",
              "bgd": [
                {
                  "qun": "25",
                  "wt": "kg"
                }
              ]
            },
            {
              "tid": "eBNVOyooepWT2X60_1",
              "ref": 1,
              "sdur": "02:00",
              "re": "Refundable Before Departure",
              "stop": 0,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "EK",
              "seg": [
                {
                  "ref": 1,
                  "da": "RUH",
                  "ar": "DXB",
                  "dd": "2023-07-17T21:10:00",
                  "ad": "2023-07-18T00:10:00",
                  "rbd": "L",
                  "fn": "2265",
                  "trn": "2",
                  "eq": "7M9",
                  "ac": "EK",
                  "ma": "EK",
                  "seat": "9",
                  "oa": "FZ",
                  "fa": [],
                  "fdur": "02:00",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "2",
                    "arr": "2"
                  }
                }
              ],
              "flightNumbers": "2265",
              "bgd": [
                {
                  "qun": "25",
                  "wt": "kg"
                }
              ]
            },
            {
              "tid": "ZIyzRG38eCG3OhGx_2",
              "ref": 2,
              "sdur": "07:00",
              "re": "Refundable Before Departure",
              "stop": 0,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "EK",
              "seg": [
                {
                  "ref": 1,
                  "da": "LHR",
                  "ar": "DXB",
                  "dd": "2023-07-21T19:50:00",
                  "ad": "2023-07-22T05:50:00",
                  "rbd": "Y",
                  "fn": "32",
                  "trn": "3",
                  "eq": "388",
                  "ac": "EK",
                  "ma": "EK",
                  "seat": "9",
                  "oa": "EK",
                  "fa": [],
                  "fdur": "07:00",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "3",
                    "arr": "3"
                  }
                }
              ],
              "flightNumbers": "32",
              "bgd": [
                {
                  "qun": "35",
                  "wt": "kg"
                }
              ]
            },
            {
              "tid": "CNfqbVNoyRqXT4yo_3",
              "ref": 3,
              "sdur": "02:00",
              "re": "Refundable Before Departure",
              "stop": 0,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "EK",
              "seg": [
                {
                  "ref": 1,
                  "da": "DXB",
                  "ar": "RUH",
                  "dd": "2023-07-25T18:35:00",
                  "ad": "2023-07-25T19:35:00",
                  "rbd": "Y",
                  "fn": "817",
                  "trn": "3",
                  "eq": "77W",
                  "ac": "EK",
                  "ma": "EK",
                  "seat": "9",
                  "oa": "EK",
                  "fa": [],
                  "fdur": "02:00",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "3",
                    "arr": "2"
                  }
                }
              ],
              "flightNumbers": "817",
              "bgd": [
                {
                  "qun": "35",
                  "wt": "kg"
                }
              ]
            },
            {
              "tid": "xNFIEUTHBWkn0bvK_1",
              "ref": 1,
              "sdur": "02:00",
              "re": "Refundable Before Departure",
              "stop": 0,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "EK",
              "seg": [
                {
                  "ref": 1,
                  "da": "RUH",
                  "ar": "DXB",
                  "dd": "2023-07-17T09:50:00",
                  "ad": "2023-07-17T12:50:00",
                  "rbd": "L",
                  "fn": "2267",
                  "trn": "2",
                  "eq": "7M8",
                  "ac": "EK",
                  "ma": "EK",
                  "seat": "9",
                  "oa": "FZ",
                  "fa": [],
                  "fdur": "02:00",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "2",
                    "arr": "2"
                  }
                }
              ],
              "flightNumbers": "2267",
              "bgd": [
                {
                  "qun": "25",
                  "wt": "kg"
                }
              ]
            },
            {
              "tid": "ZIyzRG38eCG3OhGx_1",
              "ref": 1,
              "sdur": "01:55",
              "re": "Refundable Before Departure",
              "stop": 0,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "EK",
              "seg": [
                {
                  "ref": 1,
                  "da": "RUH",
                  "ar": "DXB",
                  "dd": "2023-07-17T09:35:00",
                  "ad": "2023-07-17T12:30:00",
                  "rbd": "L",
                  "fn": "820",
                  "trn": "2",
                  "eq": "77W",
                  "ac": "EK",
                  "ma": "EK",
                  "seat": "9",
                  "oa": "EK",
                  "fa": [],
                  "fdur": "01:55",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "2",
                    "arr": "3"
                  }
                }
              ],
              "flightNumbers": "820",
              "bgd": [
                {
                  "qun": "25",
                  "wt": "kg"
                }
              ]
            },
            {
              "tid": "ZIyzRG38eCG3OhGx_3",
              "ref": 3,
              "sdur": "01:55",
              "re": "Refundable Before Departure",
              "stop": 0,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "EK",
              "seg": [
                {
                  "ref": 1,
                  "da": "DXB",
                  "ar": "RUH",
                  "dd": "2023-07-25T06:55:00",
                  "ad": "2023-07-25T07:50:00",
                  "rbd": "Y",
                  "fn": "819",
                  "trn": "3",
                  "eq": "77W",
                  "ac": "EK",
                  "ma": "EK",
                  "seat": "9",
                  "oa": "EK",
                  "fa": [],
                  "fdur": "01:55",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "3",
                    "arr": "2"
                  }
                }
              ],
              "flightNumbers": "819",
              "bgd": [
                {
                  "qun": "35",
                  "wt": "kg"
                }
              ]
            },
            {
              "tid": "g2zTp4bpfVIb6G2i_1",
              "ref": 1,
              "sdur": "02:05",
              "re": "Refundable Before Departure",
              "stop": 0,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "EK",
              "seg": [
                {
                  "ref": 1,
                  "da": "RUH",
                  "ar": "DXB",
                  "dd": "2023-07-17T03:55:00",
                  "ad": "2023-07-17T07:00:00",
                  "rbd": "L",
                  "fn": "816",
                  "trn": "2",
                  "eq": "77W",
                  "ac": "EK",
                  "ma": "EK",
                  "seat": "9",
                  "oa": "EK",
                  "fa": [],
                  "fdur": "02:05",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "2",
                    "arr": "3"
                  }
                }
              ],
              "flightNumbers": "816",
              "bgd": [
                {
                  "qun": "25",
                  "wt": "kg"
                }
              ]
            },
            {
              "tid": "D1QrEKeLh6Vfa8Sy_3",
              "ref": 3,
              "sdur": "02:00",
              "re": "Refundable Before Departure",
              "stop": 0,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "EK",
              "seg": [
                {
                  "ref": 1,
                  "da": "DXB",
                  "ar": "RUH",
                  "dd": "2023-07-25T01:25:00",
                  "ad": "2023-07-25T02:25:00",
                  "rbd": "Y",
                  "fn": "815",
                  "trn": "3",
                  "eq": "77W",
                  "ac": "EK",
                  "ma": "EK",
                  "seat": "9",
                  "oa": "EK",
                  "fa": [],
                  "fdur": "02:00",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "3",
                    "arr": "2"
                  }
                }
              ],
              "flightNumbers": "815",
              "bgd": [
                {
                  "qun": "35",
                  "wt": "kg"
                }
              ]
            }
          ],
          "pr": {
            "bf": 81536,
            "tf": 118272,
            "tax": [
              {
                "YQ": 15008
              },
              {
                "OT": 21728
              },
              {
                "dealmkp": 0
              },
              {
                "attmkp": 0
              },
              {
                "tamkp": 0
              },
              {
                "stamkp": 0
              },
              {
                "attdisc": 0
              },
              {
                "tadisc": 0
              },
              {
                "stadisc": 0
              }
            ],
            "scur": "QAR",
            "ucur": "INR",
            "exchange_rate": 22.4,
            "sup_fare": "3640_5280",
            "markupnames": []
          },
          "paxpr": [
            {
              "tf": 118272,
              "tax": [
                {
                  "YQ": 15008
                },
                {
                  "OT": 21728
                },
                {
                  "attmkp": 0
                },
                {
                  "tamkp": 0
                },
                {
                  "stamkp": 0
                },
                {
                  "attdisc": 0
                },
                {
                  "tadisc": 0
                },
                {
                  "stadisc": 0
                }
              ],
              "bf": 81536,
              "fbc": "LLXEPSA1",
              "fareType": "RP",
              "pusup_fare": "3640_5280",
              "psup_fare": "81536_118272",
              "ptype": "ADT"
            }
          ],
          "sup": "0",
          "airlinesList": "EK",
          "oaairlineList": "EK",
          "servicefee": []
        },
        {
          "srcorigns": [
            {
              "tid": "0cqVN9Ra6THuNAOB_2",
              "ref": 2,
              "sdur": "06:50",
              "re": "Refundable Before Departure",
              "stop": 0,
              "sup": "0",
              "cl": "Premium Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "EK",
              "seg": [
                {
                  "ref": 1,
                  "da": "LHR",
                  "ar": "DXB",
                  "dd": "2023-07-21T22:15:00",
                  "ad": "2023-07-22T08:05:00",
                  "rbd": "W",
                  "fn": "6",
                  "trn": "3",
                  "eq": "388",
                  "ac": "EK",
                  "ma": "EK",
                  "seat": "7",
                  "oa": "EK",
                  "fa": [],
                  "fdur": "06:50",
                  "cl": "Premium Economy",
                  "terminal": {
                    "dep": "3",
                    "arr": "3"
                  }
                }
              ],
              "flightNumbers": "6",
              "bgd": [
                {
                  "qun": "35",
                  "wt": "kg"
                }
              ]
            },
            {
              "tid": "GHTOnrz3QLMSROuS_1",
              "ref": 1,
              "sdur": "02:10",
              "re": "Refundable Before Departure",
              "stop": 0,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "EK",
              "seg": [
                {
                  "ref": 1,
                  "da": "RUH",
                  "ar": "DXB",
                  "dd": "2023-07-17T21:25:00",
                  "ad": "2023-07-18T00:35:00",
                  "rbd": "L",
                  "fn": "818",
                  "trn": "2",
                  "eq": "77W",
                  "ac": "EK",
                  "ma": "EK",
                  "seat": "9",
                  "oa": "EK",
                  "fa": [],
                  "fdur": "02:10",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "2",
                    "arr": "3"
                  }
                }
              ],
              "flightNumbers": "818",
              "bgd": [
                {
                  "qun": "25",
                  "wt": "kg"
                }
              ]
            },
            {
              "tid": "9Mb5lhBTnSzDSG00_1",
              "ref": 1,
              "sdur": "02:00",
              "re": "Refundable Before Departure",
              "stop": 0,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "EK",
              "seg": [
                {
                  "ref": 1,
                  "da": "RUH",
                  "ar": "DXB",
                  "dd": "2023-07-17T21:10:00",
                  "ad": "2023-07-18T00:10:00",
                  "rbd": "L",
                  "fn": "2265",
                  "trn": "2",
                  "eq": "7M9",
                  "ac": "EK",
                  "ma": "EK",
                  "seat": "9",
                  "oa": "FZ",
                  "fa": [],
                  "fdur": "02:00",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "2",
                    "arr": "2"
                  }
                }
              ],
              "flightNumbers": "2265",
              "bgd": [
                {
                  "qun": "25",
                  "wt": "kg"
                }
              ]
            },
            {
              "tid": "CRNvbIgD5OcUwvwD_2",
              "ref": 2,
              "sdur": "06:55",
              "re": "Refundable Before Departure",
              "stop": 0,
              "sup": "0",
              "cl": "Premium Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "EK",
              "seg": [
                {
                  "ref": 1,
                  "da": "LHR",
                  "ar": "DXB",
                  "dd": "2023-07-21T20:40:00",
                  "ad": "2023-07-22T06:35:00",
                  "rbd": "W",
                  "fn": "4",
                  "trn": "3",
                  "eq": "388",
                  "ac": "EK",
                  "ma": "EK",
                  "seat": "7",
                  "oa": "EK",
                  "fa": [],
                  "fdur": "06:55",
                  "cl": "Premium Economy",
                  "terminal": {
                    "dep": "3",
                    "arr": "3"
                  }
                }
              ],
              "flightNumbers": "4",
              "bgd": [
                {
                  "qun": "35",
                  "wt": "kg"
                }
              ]
            },
            {
              "tid": "4ooMCToOyKdiBf4v_3",
              "ref": 3,
              "sdur": "02:00",
              "re": "Refundable Before Departure",
              "stop": 0,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "EK",
              "seg": [
                {
                  "ref": 1,
                  "da": "DXB",
                  "ar": "RUH",
                  "dd": "2023-07-25T18:35:00",
                  "ad": "2023-07-25T19:35:00",
                  "rbd": "Y",
                  "fn": "817",
                  "trn": "3",
                  "eq": "77W",
                  "ac": "EK",
                  "ma": "EK",
                  "seat": "9",
                  "oa": "EK",
                  "fa": [],
                  "fdur": "02:00",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "3",
                    "arr": "2"
                  }
                }
              ],
              "flightNumbers": "817",
              "bgd": [
                {
                  "qun": "35",
                  "wt": "kg"
                }
              ]
            },
            {
              "tid": "NA7AlTnzt3unAaNC_2",
              "ref": 2,
              "sdur": "07:00",
              "re": "Refundable Before Departure",
              "stop": 0,
              "sup": "0",
              "cl": "Premium Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "EK",
              "seg": [
                {
                  "ref": 1,
                  "da": "LHR",
                  "ar": "DXB",
                  "dd": "2023-07-21T14:20:00",
                  "ad": "2023-07-22T00:20:00",
                  "rbd": "W",
                  "fn": "2",
                  "trn": "3",
                  "eq": "388",
                  "ac": "EK",
                  "ma": "EK",
                  "seat": "7",
                  "oa": "EK",
                  "fa": [],
                  "fdur": "07:00",
                  "cl": "Premium Economy",
                  "terminal": {
                    "dep": "3",
                    "arr": "3"
                  }
                }
              ],
              "flightNumbers": "2",
              "bgd": [
                {
                  "qun": "35",
                  "wt": "kg"
                }
              ]
            },
            {
              "tid": "ntAveqmnCawbTz9s_1",
              "ref": 1,
              "sdur": "02:00",
              "re": "Refundable Before Departure",
              "stop": 0,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "EK",
              "seg": [
                {
                  "ref": 1,
                  "da": "RUH",
                  "ar": "DXB",
                  "dd": "2023-07-17T09:50:00",
                  "ad": "2023-07-17T12:50:00",
                  "rbd": "L",
                  "fn": "2267",
                  "trn": "2",
                  "eq": "7M8",
                  "ac": "EK",
                  "ma": "EK",
                  "seat": "9",
                  "oa": "FZ",
                  "fa": [],
                  "fdur": "02:00",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "2",
                    "arr": "2"
                  }
                }
              ],
              "flightNumbers": "2267",
              "bgd": [
                {
                  "qun": "25",
                  "wt": "kg"
                }
              ]
            },
            {
              "tid": "0cqVN9Ra6THuNAOB_1",
              "ref": 1,
              "sdur": "01:55",
              "re": "Refundable Before Departure",
              "stop": 0,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "EK",
              "seg": [
                {
                  "ref": 1,
                  "da": "RUH",
                  "ar": "DXB",
                  "dd": "2023-07-17T09:35:00",
                  "ad": "2023-07-17T12:30:00",
                  "rbd": "L",
                  "fn": "820",
                  "trn": "2",
                  "eq": "77W",
                  "ac": "EK",
                  "ma": "EK",
                  "seat": "9",
                  "oa": "EK",
                  "fa": [],
                  "fdur": "01:55",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "2",
                    "arr": "3"
                  }
                }
              ],
              "flightNumbers": "820",
              "bgd": [
                {
                  "qun": "25",
                  "wt": "kg"
                }
              ]
            },
            {
              "tid": "0cqVN9Ra6THuNAOB_3",
              "ref": 3,
              "sdur": "01:55",
              "re": "Refundable Before Departure",
              "stop": 0,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "EK",
              "seg": [
                {
                  "ref": 1,
                  "da": "DXB",
                  "ar": "RUH",
                  "dd": "2023-07-25T06:55:00",
                  "ad": "2023-07-25T07:50:00",
                  "rbd": "Y",
                  "fn": "819",
                  "trn": "3",
                  "eq": "77W",
                  "ac": "EK",
                  "ma": "EK",
                  "seat": "9",
                  "oa": "EK",
                  "fa": [],
                  "fdur": "01:55",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "3",
                    "arr": "2"
                  }
                }
              ],
              "flightNumbers": "819",
              "bgd": [
                {
                  "qun": "35",
                  "wt": "kg"
                }
              ]
            },
            {
              "tid": "I4nzxFhct2xJ0rbf_1",
              "ref": 1,
              "sdur": "02:05",
              "re": "Refundable Before Departure",
              "stop": 0,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "EK",
              "seg": [
                {
                  "ref": 1,
                  "da": "RUH",
                  "ar": "DXB",
                  "dd": "2023-07-17T03:55:00",
                  "ad": "2023-07-17T07:00:00",
                  "rbd": "L",
                  "fn": "816",
                  "trn": "2",
                  "eq": "77W",
                  "ac": "EK",
                  "ma": "EK",
                  "seat": "9",
                  "oa": "EK",
                  "fa": [],
                  "fdur": "02:05",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "2",
                    "arr": "3"
                  }
                }
              ],
              "flightNumbers": "816",
              "bgd": [
                {
                  "qun": "25",
                  "wt": "kg"
                }
              ]
            },
            {
              "tid": "BSQDkoiaqqUTPJpH_3",
              "ref": 3,
              "sdur": "02:00",
              "re": "Refundable Before Departure",
              "stop": 0,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "EK",
              "seg": [
                {
                  "ref": 1,
                  "da": "DXB",
                  "ar": "RUH",
                  "dd": "2023-07-25T01:25:00",
                  "ad": "2023-07-25T02:25:00",
                  "rbd": "Y",
                  "fn": "815",
                  "trn": "3",
                  "eq": "77W",
                  "ac": "EK",
                  "ma": "EK",
                  "seat": "9",
                  "oa": "EK",
                  "fa": [],
                  "fdur": "02:00",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "3",
                    "arr": "2"
                  }
                }
              ],
              "flightNumbers": "815",
              "bgd": [
                {
                  "qun": "35",
                  "wt": "kg"
                }
              ]
            }
          ],
          "pr": {
            "bf": 81536,
            "tf": 129248,
            "tax": [
              {
                "YQ": 15008
              },
              {
                "OT": 32704
              },
              {
                "dealmkp": 0
              },
              {
                "attmkp": 0
              },
              {
                "tamkp": 0
              },
              {
                "stamkp": 0
              },
              {
                "attdisc": 0
              },
              {
                "tadisc": 0
              },
              {
                "stadisc": 0
              }
            ],
            "scur": "QAR",
            "ucur": "INR",
            "exchange_rate": 22.4,
            "sup_fare": "3640_5770",
            "markupnames": []
          },
          "paxpr": [
            {
              "tf": 129248,
              "tax": [
                {
                  "YQ": 15008
                },
                {
                  "OT": 32704
                },
                {
                  "attmkp": 0
                },
                {
                  "tamkp": 0
                },
                {
                  "stamkp": 0
                },
                {
                  "attdisc": 0
                },
                {
                  "tadisc": 0
                },
                {
                  "stadisc": 0
                }
              ],
              "bf": 81536,
              "fbc": "LLXEPSA1",
              "fareType": "RP",
              "pusup_fare": "3640_5770",
              "psup_fare": "81536_129248",
              "ptype": "ADT"
            }
          ],
          "sup": "0",
          "airlinesList": "EK",
          "oaairlineList": "EK",
          "servicefee": []
        },
        {
          "srcorigns": [
            {
              "tid": "akmphV1qu3IvbPp1_1",
              "ref": 1,
              "sdur": "02:10",
              "re": "Refundable Before Departure",
              "stop": 0,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "EK",
              "seg": [
                {
                  "ref": 1,
                  "da": "RUH",
                  "ar": "DXB",
                  "dd": "2023-07-17T21:25:00",
                  "ad": "2023-07-18T00:35:00",
                  "rbd": "L",
                  "fn": "818",
                  "trn": "2",
                  "eq": "77W",
                  "ac": "EK",
                  "ma": "EK",
                  "seat": "9",
                  "oa": "EK",
                  "fa": [],
                  "fdur": "02:10",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "2",
                    "arr": "3"
                  }
                }
              ],
              "flightNumbers": "818",
              "bgd": [
                {
                  "qun": "25",
                  "wt": "kg"
                }
              ]
            },
            {
              "tid": "sV7EwaLaihLTgoJ1_1",
              "ref": 1,
              "sdur": "02:00",
              "re": "Refundable Before Departure",
              "stop": 0,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "EK",
              "seg": [
                {
                  "ref": 1,
                  "da": "RUH",
                  "ar": "DXB",
                  "dd": "2023-07-17T21:10:00",
                  "ad": "2023-07-18T00:10:00",
                  "rbd": "L",
                  "fn": "2265",
                  "trn": "2",
                  "eq": "7M9",
                  "ac": "EK",
                  "ma": "EK",
                  "seat": "9",
                  "oa": "FZ",
                  "fa": [],
                  "fdur": "02:00",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "2",
                    "arr": "2"
                  }
                }
              ],
              "flightNumbers": "2265",
              "bgd": [
                {
                  "qun": "25",
                  "wt": "kg"
                }
              ]
            },
            {
              "tid": "4TuQeVDTOZoaAq0Q_2",
              "ref": 2,
              "sdur": "07:00",
              "re": "Refundable Before Departure",
              "stop": 0,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "EK",
              "seg": [
                {
                  "ref": 1,
                  "da": "LHR",
                  "ar": "DXB",
                  "dd": "2023-07-21T19:50:00",
                  "ad": "2023-07-22T05:50:00",
                  "rbd": "Y",
                  "fn": "32",
                  "trn": "3",
                  "eq": "388",
                  "ac": "EK",
                  "ma": "EK",
                  "seat": "9",
                  "oa": "EK",
                  "fa": [],
                  "fdur": "07:00",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "3",
                    "arr": "3"
                  }
                }
              ],
              "flightNumbers": "32",
              "bgd": [
                {
                  "qun": "35",
                  "wt": "kg"
                }
              ]
            },
            {
              "tid": "qnGdlEAbbQFvZrSL_3",
              "ref": 3,
              "sdur": "02:00",
              "re": "Refundable Before Departure",
              "stop": 0,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "EK",
              "seg": [
                {
                  "ref": 1,
                  "da": "DXB",
                  "ar": "RUH",
                  "dd": "2023-07-25T19:15:00",
                  "ad": "2023-07-25T20:15:00",
                  "rbd": "Y",
                  "fn": "2264",
                  "trn": "2",
                  "eq": "7M9",
                  "ac": "EK",
                  "ma": "EK",
                  "seat": "9",
                  "oa": "FZ",
                  "fa": [],
                  "fdur": "02:00",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "2",
                    "arr": "2"
                  }
                }
              ],
              "flightNumbers": "2264",
              "bgd": [
                {
                  "qun": "35",
                  "wt": "kg"
                }
              ]
            },
            {
              "tid": "GAu8rEZdhnMiyV7C_1",
              "ref": 1,
              "sdur": "02:00",
              "re": "Refundable Before Departure",
              "stop": 0,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "EK",
              "seg": [
                {
                  "ref": 1,
                  "da": "RUH",
                  "ar": "DXB",
                  "dd": "2023-07-17T09:50:00",
                  "ad": "2023-07-17T12:50:00",
                  "rbd": "L",
                  "fn": "2267",
                  "trn": "2",
                  "eq": "7M8",
                  "ac": "EK",
                  "ma": "EK",
                  "seat": "9",
                  "oa": "FZ",
                  "fa": [],
                  "fdur": "02:00",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "2",
                    "arr": "2"
                  }
                }
              ],
              "flightNumbers": "2267",
              "bgd": [
                {
                  "qun": "25",
                  "wt": "kg"
                }
              ]
            },
            {
              "tid": "4TuQeVDTOZoaAq0Q_1",
              "ref": 1,
              "sdur": "01:55",
              "re": "Refundable Before Departure",
              "stop": 0,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "EK",
              "seg": [
                {
                  "ref": 1,
                  "da": "RUH",
                  "ar": "DXB",
                  "dd": "2023-07-17T09:35:00",
                  "ad": "2023-07-17T12:30:00",
                  "rbd": "L",
                  "fn": "820",
                  "trn": "2",
                  "eq": "77W",
                  "ac": "EK",
                  "ma": "EK",
                  "seat": "9",
                  "oa": "EK",
                  "fa": [],
                  "fdur": "01:55",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "2",
                    "arr": "3"
                  }
                }
              ],
              "flightNumbers": "820",
              "bgd": [
                {
                  "qun": "25",
                  "wt": "kg"
                }
              ]
            },
            {
              "tid": "4TuQeVDTOZoaAq0Q_3",
              "ref": 3,
              "sdur": "02:00",
              "re": "Refundable Before Departure",
              "stop": 0,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "EK",
              "seg": [
                {
                  "ref": 1,
                  "da": "DXB",
                  "ar": "RUH",
                  "dd": "2023-07-25T07:50:00",
                  "ad": "2023-07-25T08:50:00",
                  "rbd": "Y",
                  "fn": "2266",
                  "trn": "2",
                  "eq": "7M8",
                  "ac": "EK",
                  "ma": "EK",
                  "seat": "9",
                  "oa": "FZ",
                  "fa": [],
                  "fdur": "02:00",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "2",
                    "arr": "2"
                  }
                }
              ],
              "flightNumbers": "2266",
              "bgd": [
                {
                  "qun": "35",
                  "wt": "kg"
                }
              ]
            },
            {
              "tid": "CAEAlQpSbx0BPJ3I_1",
              "ref": 1,
              "sdur": "02:05",
              "re": "Refundable Before Departure",
              "stop": 0,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "EK",
              "seg": [
                {
                  "ref": 1,
                  "da": "RUH",
                  "ar": "DXB",
                  "dd": "2023-07-17T03:55:00",
                  "ad": "2023-07-17T07:00:00",
                  "rbd": "L",
                  "fn": "816",
                  "trn": "2",
                  "eq": "77W",
                  "ac": "EK",
                  "ma": "EK",
                  "seat": "9",
                  "oa": "EK",
                  "fa": [],
                  "fdur": "02:05",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "2",
                    "arr": "3"
                  }
                }
              ],
              "flightNumbers": "816",
              "bgd": [
                {
                  "qun": "25",
                  "wt": "kg"
                }
              ]
            }
          ],
          "pr": {
            "bf": 130592,
            "tf": 167328,
            "tax": [
              {
                "YQ": 15008
              },
              {
                "OT": 21728
              },
              {
                "dealmkp": 0
              },
              {
                "attmkp": 0
              },
              {
                "tamkp": 0
              },
              {
                "stamkp": 0
              },
              {
                "attdisc": 0
              },
              {
                "tadisc": 0
              },
              {
                "stadisc": 0
              }
            ],
            "scur": "QAR",
            "ucur": "INR",
            "exchange_rate": 22.4,
            "sup_fare": "5830_7470",
            "markupnames": []
          },
          "paxpr": [
            {
              "tf": 167328,
              "tax": [
                {
                  "YQ": 15008
                },
                {
                  "OT": 21728
                },
                {
                  "attmkp": 0
                },
                {
                  "tamkp": 0
                },
                {
                  "stamkp": 0
                },
                {
                  "attdisc": 0
                },
                {
                  "tadisc": 0
                },
                {
                  "stadisc": 0
                }
              ],
              "bf": 130592,
              "fbc": "LLXEPSA1",
              "fareType": "RP",
              "pusup_fare": "5830_7470",
              "psup_fare": "130592_167328",
              "ptype": "ADT"
            }
          ],
          "sup": "0",
          "airlinesList": "EK",
          "oaairlineList": "EK",
          "servicefee": []
        },
        {
          "srcorigns": [
            {
              "tid": "sr163aVR5cRfpou8_2",
              "ref": 2,
              "sdur": "06:50",
              "re": "Refundable Before Departure",
              "stop": 0,
              "sup": "0",
              "cl": "Premium Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "EK",
              "seg": [
                {
                  "ref": 1,
                  "da": "LHR",
                  "ar": "DXB",
                  "dd": "2023-07-21T22:15:00",
                  "ad": "2023-07-22T08:05:00",
                  "rbd": "W",
                  "fn": "6",
                  "trn": "3",
                  "eq": "388",
                  "ac": "EK",
                  "ma": "EK",
                  "seat": "7",
                  "oa": "EK",
                  "fa": [],
                  "fdur": "06:50",
                  "cl": "Premium Economy",
                  "terminal": {
                    "dep": "3",
                    "arr": "3"
                  }
                }
              ],
              "flightNumbers": "6",
              "bgd": [
                {
                  "qun": "35",
                  "wt": "kg"
                }
              ]
            },
            {
              "tid": "5SbQ4u4MUCfZwQ8B_1",
              "ref": 1,
              "sdur": "02:10",
              "re": "Refundable Before Departure",
              "stop": 0,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "EK",
              "seg": [
                {
                  "ref": 1,
                  "da": "RUH",
                  "ar": "DXB",
                  "dd": "2023-07-17T21:25:00",
                  "ad": "2023-07-18T00:35:00",
                  "rbd": "L",
                  "fn": "818",
                  "trn": "2",
                  "eq": "77W",
                  "ac": "EK",
                  "ma": "EK",
                  "seat": "9",
                  "oa": "EK",
                  "fa": [],
                  "fdur": "02:10",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "2",
                    "arr": "3"
                  }
                }
              ],
              "flightNumbers": "818",
              "bgd": [
                {
                  "qun": "25",
                  "wt": "kg"
                }
              ]
            },
            {
              "tid": "X38ssRhlU51erKmA_1",
              "ref": 1,
              "sdur": "02:00",
              "re": "Refundable Before Departure",
              "stop": 0,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "EK",
              "seg": [
                {
                  "ref": 1,
                  "da": "RUH",
                  "ar": "DXB",
                  "dd": "2023-07-17T21:10:00",
                  "ad": "2023-07-18T00:10:00",
                  "rbd": "L",
                  "fn": "2265",
                  "trn": "2",
                  "eq": "7M9",
                  "ac": "EK",
                  "ma": "EK",
                  "seat": "9",
                  "oa": "FZ",
                  "fa": [],
                  "fdur": "02:00",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "2",
                    "arr": "2"
                  }
                }
              ],
              "flightNumbers": "2265",
              "bgd": [
                {
                  "qun": "25",
                  "wt": "kg"
                }
              ]
            },
            {
              "tid": "LMNAZGD7rrynOa2C_2",
              "ref": 2,
              "sdur": "06:55",
              "re": "Refundable Before Departure",
              "stop": 0,
              "sup": "0",
              "cl": "Premium Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "EK",
              "seg": [
                {
                  "ref": 1,
                  "da": "LHR",
                  "ar": "DXB",
                  "dd": "2023-07-21T20:40:00",
                  "ad": "2023-07-22T06:35:00",
                  "rbd": "W",
                  "fn": "4",
                  "trn": "3",
                  "eq": "388",
                  "ac": "EK",
                  "ma": "EK",
                  "seat": "7",
                  "oa": "EK",
                  "fa": [],
                  "fdur": "06:55",
                  "cl": "Premium Economy",
                  "terminal": {
                    "dep": "3",
                    "arr": "3"
                  }
                }
              ],
              "flightNumbers": "4",
              "bgd": [
                {
                  "qun": "35",
                  "wt": "kg"
                }
              ]
            },
            {
              "tid": "yxrahayVAaP7Ev5o_3",
              "ref": 3,
              "sdur": "02:00",
              "re": "Refundable Before Departure",
              "stop": 0,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "EK",
              "seg": [
                {
                  "ref": 1,
                  "da": "DXB",
                  "ar": "RUH",
                  "dd": "2023-07-25T19:15:00",
                  "ad": "2023-07-25T20:15:00",
                  "rbd": "B",
                  "fn": "2264",
                  "trn": "2",
                  "eq": "7M9",
                  "ac": "EK",
                  "ma": "EK",
                  "seat": "9",
                  "oa": "FZ",
                  "fa": [],
                  "fdur": "02:00",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "2",
                    "arr": "2"
                  }
                }
              ],
              "flightNumbers": "2264",
              "bgd": [
                {
                  "qun": "35",
                  "wt": "kg"
                }
              ]
            },
            {
              "tid": "X2r3pQsBIeR5xB6U_2",
              "ref": 2,
              "sdur": "07:00",
              "re": "Refundable Before Departure",
              "stop": 0,
              "sup": "0",
              "cl": "Premium Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "EK",
              "seg": [
                {
                  "ref": 1,
                  "da": "LHR",
                  "ar": "DXB",
                  "dd": "2023-07-21T14:20:00",
                  "ad": "2023-07-22T00:20:00",
                  "rbd": "W",
                  "fn": "2",
                  "trn": "3",
                  "eq": "388",
                  "ac": "EK",
                  "ma": "EK",
                  "seat": "7",
                  "oa": "EK",
                  "fa": [],
                  "fdur": "07:00",
                  "cl": "Premium Economy",
                  "terminal": {
                    "dep": "3",
                    "arr": "3"
                  }
                }
              ],
              "flightNumbers": "2",
              "bgd": [
                {
                  "qun": "35",
                  "wt": "kg"
                }
              ]
            },
            {
              "tid": "8037L5wXRBElbggZ_1",
              "ref": 1,
              "sdur": "02:00",
              "re": "Refundable Before Departure",
              "stop": 0,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "EK",
              "seg": [
                {
                  "ref": 1,
                  "da": "RUH",
                  "ar": "DXB",
                  "dd": "2023-07-17T09:50:00",
                  "ad": "2023-07-17T12:50:00",
                  "rbd": "L",
                  "fn": "2267",
                  "trn": "2",
                  "eq": "7M8",
                  "ac": "EK",
                  "ma": "EK",
                  "seat": "9",
                  "oa": "FZ",
                  "fa": [],
                  "fdur": "02:00",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "2",
                    "arr": "2"
                  }
                }
              ],
              "flightNumbers": "2267",
              "bgd": [
                {
                  "qun": "25",
                  "wt": "kg"
                }
              ]
            },
            {
              "tid": "sr163aVR5cRfpou8_1",
              "ref": 1,
              "sdur": "01:55",
              "re": "Refundable Before Departure",
              "stop": 0,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "EK",
              "seg": [
                {
                  "ref": 1,
                  "da": "RUH",
                  "ar": "DXB",
                  "dd": "2023-07-17T09:35:00",
                  "ad": "2023-07-17T12:30:00",
                  "rbd": "L",
                  "fn": "820",
                  "trn": "2",
                  "eq": "77W",
                  "ac": "EK",
                  "ma": "EK",
                  "seat": "9",
                  "oa": "EK",
                  "fa": [],
                  "fdur": "01:55",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "2",
                    "arr": "3"
                  }
                }
              ],
              "flightNumbers": "820",
              "bgd": [
                {
                  "qun": "25",
                  "wt": "kg"
                }
              ]
            },
            {
              "tid": "sr163aVR5cRfpou8_3",
              "ref": 3,
              "sdur": "02:00",
              "re": "Refundable Before Departure",
              "stop": 0,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "EK",
              "seg": [
                {
                  "ref": 1,
                  "da": "DXB",
                  "ar": "RUH",
                  "dd": "2023-07-25T07:50:00",
                  "ad": "2023-07-25T08:50:00",
                  "rbd": "B",
                  "fn": "2266",
                  "trn": "2",
                  "eq": "7M8",
                  "ac": "EK",
                  "ma": "EK",
                  "seat": "9",
                  "oa": "FZ",
                  "fa": [],
                  "fdur": "02:00",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "2",
                    "arr": "2"
                  }
                }
              ],
              "flightNumbers": "2266",
              "bgd": [
                {
                  "qun": "35",
                  "wt": "kg"
                }
              ]
            },
            {
              "tid": "X4r9qHXlbAGrEa0z_1",
              "ref": 1,
              "sdur": "02:05",
              "re": "Refundable Before Departure",
              "stop": 0,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "EK",
              "seg": [
                {
                  "ref": 1,
                  "da": "RUH",
                  "ar": "DXB",
                  "dd": "2023-07-17T03:55:00",
                  "ad": "2023-07-17T07:00:00",
                  "rbd": "L",
                  "fn": "816",
                  "trn": "2",
                  "eq": "77W",
                  "ac": "EK",
                  "ma": "EK",
                  "seat": "9",
                  "oa": "EK",
                  "fa": [],
                  "fdur": "02:05",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "2",
                    "arr": "3"
                  }
                }
              ],
              "flightNumbers": "816",
              "bgd": [
                {
                  "qun": "25",
                  "wt": "kg"
                }
              ]
            }
          ],
          "pr": {
            "bf": 130592,
            "tf": 178304,
            "tax": [
              {
                "YQ": 15008
              },
              {
                "OT": 32704
              },
              {
                "dealmkp": 0
              },
              {
                "attmkp": 0
              },
              {
                "tamkp": 0
              },
              {
                "stamkp": 0
              },
              {
                "attdisc": 0
              },
              {
                "tadisc": 0
              },
              {
                "stadisc": 0
              }
            ],
            "scur": "QAR",
            "ucur": "INR",
            "exchange_rate": 22.4,
            "sup_fare": "5830_7960",
            "markupnames": []
          },
          "paxpr": [
            {
              "tf": 178304,
              "tax": [
                {
                  "YQ": 15008
                },
                {
                  "OT": 32704
                },
                {
                  "attmkp": 0
                },
                {
                  "tamkp": 0
                },
                {
                  "stamkp": 0
                },
                {
                  "attdisc": 0
                },
                {
                  "tadisc": 0
                },
                {
                  "stadisc": 0
                }
              ],
              "bf": 130592,
              "fbc": "LLXEPSA1",
              "fareType": "RP",
              "pusup_fare": "5830_7960",
              "psup_fare": "130592_178304",
              "ptype": "ADT"
            }
          ],
          "sup": "0",
          "airlinesList": "EK",
          "oaairlineList": "EK",
          "servicefee": []
        },
        {
          "srcorigns": [
            {
              "tid": "IvcJkuNQ7enb3Amh_3",
              "ref": 3,
              "sdur": "02:00",
              "re": "Refundable Before Departure",
              "stop": 0,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "EK",
              "seg": [
                {
                  "ref": 1,
                  "da": "DXB",
                  "ar": "RUH",
                  "dd": "2023-07-25T22:30:00",
                  "ad": "2023-07-25T23:30:00",
                  "rbd": "Y",
                  "fn": "553",
                  "trn": "1",
                  "eq": "773",
                  "ac": "SV",
                  "ma": "SV",
                  "seat": "9",
                  "oa": "SV",
                  "fa": [],
                  "fdur": "02:00",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "1",
                    "arr": "4"
                  }
                }
              ],
              "flightNumbers": "553",
              "bgd": [
                {
                  "qun": "2",
                  "wt": "pieces"
                }
              ]
            },
            {
              "tid": "fhbh5bSZ0lsHOvNX_2",
              "ref": 2,
              "sdur": "06:50",
              "re": "Refundable Before Departure",
              "stop": 0,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "EK",
              "seg": [
                {
                  "ref": 1,
                  "da": "LHR",
                  "ar": "DXB",
                  "dd": "2023-07-21T22:15:00",
                  "ad": "2023-07-22T08:05:00",
                  "rbd": "Y",
                  "fn": "6",
                  "trn": "3",
                  "eq": "388",
                  "ac": "EK",
                  "ma": "EK",
                  "seat": "9",
                  "oa": "EK",
                  "fa": [],
                  "fdur": "06:50",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "3",
                    "arr": "3"
                  }
                }
              ],
              "flightNumbers": "6",
              "bgd": [
                {
                  "qun": "35",
                  "wt": "kg"
                }
              ]
            },
            {
              "tid": "Ozm62wwqyQaWhDRv_1",
              "ref": 1,
              "sdur": "02:10",
              "re": "Refundable Before Departure",
              "stop": 0,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "EK",
              "seg": [
                {
                  "ref": 1,
                  "da": "RUH",
                  "ar": "DXB",
                  "dd": "2023-07-17T21:25:00",
                  "ad": "2023-07-18T00:35:00",
                  "rbd": "Y",
                  "fn": "818",
                  "trn": "2",
                  "eq": "77W",
                  "ac": "EK",
                  "ma": "EK",
                  "seat": "9",
                  "oa": "EK",
                  "fa": [],
                  "fdur": "02:10",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "2",
                    "arr": "3"
                  }
                }
              ],
              "flightNumbers": "818",
              "bgd": [
                {
                  "qun": "35",
                  "wt": "kg"
                }
              ]
            },
            {
              "tid": "c6qcvMfME6R9M6LF_2",
              "ref": 2,
              "sdur": "06:55",
              "re": "Refundable Before Departure",
              "stop": 0,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "EK",
              "seg": [
                {
                  "ref": 1,
                  "da": "LHR",
                  "ar": "DXB",
                  "dd": "2023-07-21T20:40:00",
                  "ad": "2023-07-22T06:35:00",
                  "rbd": "Y",
                  "fn": "4",
                  "trn": "3",
                  "eq": "388",
                  "ac": "EK",
                  "ma": "EK",
                  "seat": "9",
                  "oa": "EK",
                  "fa": [],
                  "fdur": "06:55",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "3",
                    "arr": "3"
                  }
                }
              ],
              "flightNumbers": "4",
              "bgd": [
                {
                  "qun": "35",
                  "wt": "kg"
                }
              ]
            },
            {
              "tid": "byLHLDaSMPvXMwbT_3",
              "ref": 3,
              "sdur": "02:00",
              "re": "Refundable Before Departure",
              "stop": 0,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "EK",
              "seg": [
                {
                  "ref": 1,
                  "da": "DXB",
                  "ar": "RUH",
                  "dd": "2023-07-25T20:30:00",
                  "ad": "2023-07-25T21:30:00",
                  "rbd": "Y",
                  "fn": "561",
                  "trn": "1",
                  "eq": "773",
                  "ac": "SV",
                  "ma": "SV",
                  "seat": "9",
                  "oa": "SV",
                  "fa": [],
                  "fdur": "02:00",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "1",
                    "arr": "4"
                  }
                }
              ],
              "flightNumbers": "561",
              "bgd": [
                {
                  "qun": "2",
                  "wt": "pieces"
                }
              ]
            },
            {
              "tid": "4PlRsq4nScKsouzx_2",
              "ref": 2,
              "sdur": "07:00",
              "re": "Refundable Before Departure",
              "stop": 0,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "EK",
              "seg": [
                {
                  "ref": 1,
                  "da": "LHR",
                  "ar": "DXB",
                  "dd": "2023-07-21T19:50:00",
                  "ad": "2023-07-22T05:50:00",
                  "rbd": "Y",
                  "fn": "32",
                  "trn": "3",
                  "eq": "388",
                  "ac": "EK",
                  "ma": "EK",
                  "seat": "9",
                  "oa": "EK",
                  "fa": [],
                  "fdur": "07:00",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "3",
                    "arr": "3"
                  }
                }
              ],
              "flightNumbers": "32",
              "bgd": [
                {
                  "qun": "35",
                  "wt": "kg"
                }
              ]
            },
            {
              "tid": "UbbXDy5utXC0H8CF_3",
              "ref": 3,
              "sdur": "02:00",
              "re": "Refundable Before Departure",
              "stop": 0,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "EK",
              "seg": [
                {
                  "ref": 1,
                  "da": "DXB",
                  "ar": "RUH",
                  "dd": "2023-07-25T18:15:00",
                  "ad": "2023-07-25T19:15:00",
                  "rbd": "Y",
                  "fn": "555",
                  "trn": "1",
                  "eq": "777",
                  "ac": "SV",
                  "ma": "SV",
                  "seat": "9",
                  "oa": "SV",
                  "fa": [],
                  "fdur": "02:00",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "1",
                    "arr": "4"
                  }
                }
              ],
              "flightNumbers": "555",
              "bgd": [
                {
                  "qun": "2",
                  "wt": "pieces"
                }
              ]
            },
            {
              "tid": "KSVo3H80JyyvBchb_3",
              "ref": 3,
              "sdur": "02:05",
              "re": "Refundable Before Departure",
              "stop": 0,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "EK",
              "seg": [
                {
                  "ref": 1,
                  "da": "DXB",
                  "ar": "RUH",
                  "dd": "2023-07-25T16:00:00",
                  "ad": "2023-07-25T17:05:00",
                  "rbd": "Y",
                  "fn": "559",
                  "trn": "1",
                  "eq": "330",
                  "ac": "SV",
                  "ma": "SV",
                  "seat": "9",
                  "oa": "SV",
                  "fa": [],
                  "fdur": "02:05",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "1",
                    "arr": "4"
                  }
                }
              ],
              "flightNumbers": "559",
              "bgd": [
                {
                  "qun": "2",
                  "wt": "pieces"
                }
              ]
            },
            {
              "tid": "0eRa25V5NwMqtOrz_2",
              "ref": 2,
              "sdur": "07:00",
              "re": "Refundable Before Departure",
              "stop": 0,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "EK",
              "seg": [
                {
                  "ref": 1,
                  "da": "LHR",
                  "ar": "DXB",
                  "dd": "2023-07-21T14:20:00",
                  "ad": "2023-07-22T00:20:00",
                  "rbd": "Y",
                  "fn": "2",
                  "trn": "3",
                  "eq": "388",
                  "ac": "EK",
                  "ma": "EK",
                  "seat": "9",
                  "oa": "EK",
                  "fa": [],
                  "fdur": "07:00",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "3",
                    "arr": "3"
                  }
                }
              ],
              "flightNumbers": "2",
              "bgd": [
                {
                  "qun": "35",
                  "wt": "kg"
                }
              ]
            },
            {
              "tid": "3XC4AH3ib8KZ4vFJ_3",
              "ref": 3,
              "sdur": "02:00",
              "re": "Refundable Before Departure",
              "stop": 0,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "EK",
              "seg": [
                {
                  "ref": 1,
                  "da": "DXB",
                  "ar": "RUH",
                  "dd": "2023-07-25T13:30:00",
                  "ad": "2023-07-25T14:30:00",
                  "rbd": "Y",
                  "fn": "597",
                  "trn": "1",
                  "eq": "320",
                  "ac": "SV",
                  "ma": "SV",
                  "seat": "9",
                  "oa": "SV",
                  "fa": [],
                  "fdur": "02:00",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "1",
                    "arr": "4"
                  }
                }
              ],
              "flightNumbers": "597",
              "bgd": [
                {
                  "qun": "2",
                  "wt": "pieces"
                }
              ]
            },
            {
              "tid": "fhbh5bSZ0lsHOvNX_1",
              "ref": 1,
              "sdur": "01:55",
              "re": "Refundable Before Departure",
              "stop": 0,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "EK",
              "seg": [
                {
                  "ref": 1,
                  "da": "RUH",
                  "ar": "DXB",
                  "dd": "2023-07-17T09:35:00",
                  "ad": "2023-07-17T12:30:00",
                  "rbd": "Y",
                  "fn": "820",
                  "trn": "2",
                  "eq": "77W",
                  "ac": "EK",
                  "ma": "EK",
                  "seat": "9",
                  "oa": "EK",
                  "fa": [],
                  "fdur": "01:55",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "2",
                    "arr": "3"
                  }
                }
              ],
              "flightNumbers": "820",
              "bgd": [
                {
                  "qun": "35",
                  "wt": "kg"
                }
              ]
            },
            {
              "tid": "fhbh5bSZ0lsHOvNX_3",
              "ref": 3,
              "sdur": "02:00",
              "re": "Refundable Before Departure",
              "stop": 0,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "EK",
              "seg": [
                {
                  "ref": 1,
                  "da": "DXB",
                  "ar": "RUH",
                  "dd": "2023-07-25T07:00:00",
                  "ad": "2023-07-25T08:00:00",
                  "rbd": "Y",
                  "fn": "591",
                  "trn": "1",
                  "eq": "320",
                  "ac": "SV",
                  "ma": "SV",
                  "seat": "9",
                  "oa": "SV",
                  "fa": [],
                  "fdur": "02:00",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "1",
                    "arr": "4"
                  }
                }
              ],
              "flightNumbers": "591",
              "bgd": [
                {
                  "qun": "2",
                  "wt": "pieces"
                }
              ]
            },
            {
              "tid": "V2HtveGF7qhpntMP_1",
              "ref": 1,
              "sdur": "02:05",
              "re": "Refundable Before Departure",
              "stop": 0,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "EK",
              "seg": [
                {
                  "ref": 1,
                  "da": "RUH",
                  "ar": "DXB",
                  "dd": "2023-07-17T03:55:00",
                  "ad": "2023-07-17T07:00:00",
                  "rbd": "Y",
                  "fn": "816",
                  "trn": "2",
                  "eq": "77W",
                  "ac": "EK",
                  "ma": "EK",
                  "seat": "9",
                  "oa": "EK",
                  "fa": [],
                  "fdur": "02:05",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "2",
                    "arr": "3"
                  }
                }
              ],
              "flightNumbers": "816",
              "bgd": [
                {
                  "qun": "35",
                  "wt": "kg"
                }
              ]
            }
          ],
          "pr": {
            "bf": 236768,
            "tf": 269248,
            "tax": [
              {
                "YQ": 10752
              },
              {
                "OT": 21728
              },
              {
                "dealmkp": 0
              },
              {
                "attmkp": 0
              },
              {
                "tamkp": 0
              },
              {
                "stamkp": 0
              },
              {
                "attdisc": 0
              },
              {
                "tadisc": 0
              },
              {
                "stadisc": 0
              }
            ],
            "scur": "QAR",
            "ucur": "INR",
            "exchange_rate": 22.4,
            "sup_fare": "10570_12020",
            "markupnames": []
          },
          "paxpr": [
            {
              "tf": 269248,
              "tax": [
                {
                  "YQ": 10752
                },
                {
                  "OT": 21728
                },
                {
                  "attmkp": 0
                },
                {
                  "tamkp": 0
                },
                {
                  "stamkp": 0
                },
                {
                  "attdisc": 0
                },
                {
                  "tadisc": 0
                },
                {
                  "stadisc": 0
                }
              ],
              "bf": 236768,
              "fbc": "WRTSA7",
              "fareType": "RP",
              "pusup_fare": "10570_12020",
              "psup_fare": "236768_269248",
              "ptype": "ADT"
            }
          ],
          "sup": "0",
          "airlinesList": "SV_EK",
          "oaairlineList": "SV",
          "servicefee": []
        },
        {
          "srcorigns": [
            {
              "tid": "ItHEUhhe94bf1Sfr_2",
              "ref": 2,
              "sdur": "06:50",
              "re": "Refundable Before Departure",
              "stop": 0,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "EK",
              "seg": [
                {
                  "ref": 1,
                  "da": "LHR",
                  "ar": "DXB",
                  "dd": "2023-07-21T22:15:00",
                  "ad": "2023-07-22T08:05:00",
                  "rbd": "Y",
                  "fn": "6",
                  "trn": "3",
                  "eq": "388",
                  "ac": "EK",
                  "ma": "EK",
                  "seat": "9",
                  "oa": "EK",
                  "fa": [],
                  "fdur": "06:50",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "3",
                    "arr": "3"
                  }
                }
              ],
              "flightNumbers": "6",
              "bgd": [
                {
                  "qun": "35",
                  "wt": "kg"
                }
              ]
            },
            {
              "tid": "PKNek1R6hXeGABC8_1",
              "ref": 1,
              "sdur": "02:10",
              "re": "Refundable Before Departure",
              "stop": 0,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "EK",
              "seg": [
                {
                  "ref": 1,
                  "da": "RUH",
                  "ar": "DXB",
                  "dd": "2023-07-17T21:25:00",
                  "ad": "2023-07-18T00:35:00",
                  "rbd": "Y",
                  "fn": "818",
                  "trn": "2",
                  "eq": "77W",
                  "ac": "EK",
                  "ma": "EK",
                  "seat": "9",
                  "oa": "EK",
                  "fa": [],
                  "fdur": "02:10",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "2",
                    "arr": "3"
                  }
                }
              ],
              "flightNumbers": "818",
              "bgd": [
                {
                  "qun": "35",
                  "wt": "kg"
                }
              ]
            },
            {
              "tid": "H8TDrxMHPEc1fy19_2",
              "ref": 2,
              "sdur": "06:55",
              "re": "Refundable Before Departure",
              "stop": 0,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "EK",
              "seg": [
                {
                  "ref": 1,
                  "da": "LHR",
                  "ar": "DXB",
                  "dd": "2023-07-21T20:40:00",
                  "ad": "2023-07-22T06:35:00",
                  "rbd": "Y",
                  "fn": "4",
                  "trn": "3",
                  "eq": "388",
                  "ac": "EK",
                  "ma": "EK",
                  "seat": "9",
                  "oa": "EK",
                  "fa": [],
                  "fdur": "06:55",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "3",
                    "arr": "3"
                  }
                }
              ],
              "flightNumbers": "4",
              "bgd": [
                {
                  "qun": "35",
                  "wt": "kg"
                }
              ]
            },
            {
              "tid": "e1xBkAc1EfXZCQfT_2",
              "ref": 2,
              "sdur": "07:00",
              "re": "Refundable Before Departure",
              "stop": 0,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "EK",
              "seg": [
                {
                  "ref": 1,
                  "da": "LHR",
                  "ar": "DXB",
                  "dd": "2023-07-21T19:50:00",
                  "ad": "2023-07-22T05:50:00",
                  "rbd": "Y",
                  "fn": "32",
                  "trn": "3",
                  "eq": "388",
                  "ac": "EK",
                  "ma": "EK",
                  "seat": "9",
                  "oa": "EK",
                  "fa": [],
                  "fdur": "07:00",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "3",
                    "arr": "3"
                  }
                }
              ],
              "flightNumbers": "32",
              "bgd": [
                {
                  "qun": "35",
                  "wt": "kg"
                }
              ]
            },
            {
              "tid": "ItHEUhhe94bf1Sfr_3",
              "ref": 3,
              "sdur": "04:40",
              "re": "Refundable Before Departure",
              "stop": 1,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "EK",
              "seg": [
                {
                  "ref": 1,
                  "da": "DXB",
                  "ar": "BAH",
                  "dd": "2023-07-25T17:00:00",
                  "ad": "2023-07-25T17:25:00",
                  "rbd": "W",
                  "fn": "507",
                  "trn": "1",
                  "eq": "32N",
                  "ac": "GF",
                  "ma": "GF",
                  "seat": "7",
                  "oa": "GF",
                  "fa": [],
                  "fdur": "01:25",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "1",
                    "arr": ""
                  }
                },
                {
                  "ref": 2,
                  "da": "BAH",
                  "ar": "RUH",
                  "dd": "2023-07-25T19:25:00",
                  "ad": "2023-07-25T20:40:00",
                  "rbd": "W",
                  "fn": "169",
                  "eq": "789",
                  "ac": "GF",
                  "ma": "GF",
                  "seat": "7",
                  "oa": "GF",
                  "fa": [],
                  "fdur": "01:15",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": "1"
                  }
                }
              ],
              "flightNumbers": "507/169",
              "bgd": [
                {
                  "qun": "1",
                  "wt": "pieces"
                }
              ]
            },
            {
              "tid": "Wdm6UkrbF1pMDg0b_2",
              "ref": 2,
              "sdur": "07:00",
              "re": "Refundable Before Departure",
              "stop": 0,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "EK",
              "seg": [
                {
                  "ref": 1,
                  "da": "LHR",
                  "ar": "DXB",
                  "dd": "2023-07-21T14:20:00",
                  "ad": "2023-07-22T00:20:00",
                  "rbd": "Y",
                  "fn": "2",
                  "trn": "3",
                  "eq": "388",
                  "ac": "EK",
                  "ma": "EK",
                  "seat": "9",
                  "oa": "EK",
                  "fa": [],
                  "fdur": "07:00",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "3",
                    "arr": "3"
                  }
                }
              ],
              "flightNumbers": "2",
              "bgd": [
                {
                  "qun": "35",
                  "wt": "kg"
                }
              ]
            },
            {
              "tid": "ItHEUhhe94bf1Sfr_1",
              "ref": 1,
              "sdur": "01:55",
              "re": "Refundable Before Departure",
              "stop": 0,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "EK",
              "seg": [
                {
                  "ref": 1,
                  "da": "RUH",
                  "ar": "DXB",
                  "dd": "2023-07-17T09:35:00",
                  "ad": "2023-07-17T12:30:00",
                  "rbd": "Y",
                  "fn": "820",
                  "trn": "2",
                  "eq": "77W",
                  "ac": "EK",
                  "ma": "EK",
                  "seat": "9",
                  "oa": "EK",
                  "fa": [],
                  "fdur": "01:55",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "2",
                    "arr": "3"
                  }
                }
              ],
              "flightNumbers": "820",
              "bgd": [
                {
                  "qun": "35",
                  "wt": "kg"
                }
              ]
            },
            {
              "tid": "lQtwdvAXoM5wVdlB_1",
              "ref": 1,
              "sdur": "02:05",
              "re": "Refundable Before Departure",
              "stop": 0,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "EK",
              "seg": [
                {
                  "ref": 1,
                  "da": "RUH",
                  "ar": "DXB",
                  "dd": "2023-07-17T03:55:00",
                  "ad": "2023-07-17T07:00:00",
                  "rbd": "Y",
                  "fn": "816",
                  "trn": "2",
                  "eq": "77W",
                  "ac": "EK",
                  "ma": "EK",
                  "seat": "9",
                  "oa": "EK",
                  "fa": [],
                  "fdur": "02:05",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "2",
                    "arr": "3"
                  }
                }
              ],
              "flightNumbers": "816",
              "bgd": [
                {
                  "qun": "35",
                  "wt": "kg"
                }
              ]
            }
          ],
          "pr": {
            "bf": 236768,
            "tf": 269696,
            "tax": [
              {
                "YQ": 10752
              },
              {
                "OT": 22176
              },
              {
                "dealmkp": 0
              },
              {
                "attmkp": 0
              },
              {
                "tamkp": 0
              },
              {
                "stamkp": 0
              },
              {
                "attdisc": 0
              },
              {
                "tadisc": 0
              },
              {
                "stadisc": 0
              }
            ],
            "scur": "QAR",
            "ucur": "INR",
            "exchange_rate": 22.4,
            "sup_fare": "10570_12040",
            "markupnames": []
          },
          "paxpr": [
            {
              "tf": 269696,
              "tax": [
                {
                  "YQ": 10752
                },
                {
                  "OT": 22176
                },
                {
                  "attmkp": 0
                },
                {
                  "tamkp": 0
                },
                {
                  "stamkp": 0
                },
                {
                  "attdisc": 0
                },
                {
                  "tadisc": 0
                },
                {
                  "stadisc": 0
                }
              ],
              "bf": 236768,
              "fbc": "WRTSA7",
              "fareType": "RP",
              "pusup_fare": "10570_12040",
              "psup_fare": "236768_269696",
              "ptype": "ADT"
            }
          ],
          "sup": "0",
          "airlinesList": "EK_GF",
          "oaairlineList": "EK",
          "servicefee": []
        },
        {
          "srcorigns": [
            {
              "tid": "HvpsqhOmp9Ewe31x_3",
              "ref": 3,
              "sdur": "05:20",
              "re": "Refundable Before Departure",
              "stop": 1,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "EK",
              "seg": [
                {
                  "ref": 1,
                  "da": "DXB",
                  "ar": "MCT",
                  "dd": "2023-07-25T22:45:00",
                  "ad": "2023-07-25T23:55:00",
                  "rbd": "U",
                  "fn": "612",
                  "trn": "1",
                  "eq": "7M8",
                  "ac": "WY",
                  "ma": "WY",
                  "seat": "7",
                  "oa": "WY",
                  "fa": [],
                  "fdur": "01:10",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "1",
                    "arr": ""
                  }
                },
                {
                  "ref": 2,
                  "da": "MCT",
                  "ar": "RUH",
                  "dd": "2023-07-26T01:45:00",
                  "ad": "2023-07-26T03:05:00",
                  "rbd": "N",
                  "fn": "685",
                  "eq": "738",
                  "ac": "WY",
                  "ma": "WY",
                  "seat": "7",
                  "oa": "WY",
                  "fa": [],
                  "fdur": "02:20",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "",
                    "arr": "2"
                  }
                }
              ],
              "flightNumbers": "612/685",
              "bgd": [
                {
                  "qun": "30",
                  "wt": "kg"
                }
              ]
            },
            {
              "tid": "HvpsqhOmp9Ewe31x_2",
              "ref": 2,
              "sdur": "06:50",
              "re": "Refundable Before Departure",
              "stop": 0,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "EK",
              "seg": [
                {
                  "ref": 1,
                  "da": "LHR",
                  "ar": "DXB",
                  "dd": "2023-07-21T22:15:00",
                  "ad": "2023-07-22T08:05:00",
                  "rbd": "Y",
                  "fn": "6",
                  "trn": "3",
                  "eq": "388",
                  "ac": "EK",
                  "ma": "EK",
                  "seat": "9",
                  "oa": "EK",
                  "fa": [],
                  "fdur": "06:50",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "3",
                    "arr": "3"
                  }
                }
              ],
              "flightNumbers": "6",
              "bgd": [
                {
                  "qun": "35",
                  "wt": "kg"
                }
              ]
            },
            {
              "tid": "wQpLF1ZJZxOvEUI2_2",
              "ref": 2,
              "sdur": "06:55",
              "re": "Refundable Before Departure",
              "stop": 0,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "EK",
              "seg": [
                {
                  "ref": 1,
                  "da": "LHR",
                  "ar": "DXB",
                  "dd": "2023-07-21T20:40:00",
                  "ad": "2023-07-22T06:35:00",
                  "rbd": "Y",
                  "fn": "4",
                  "trn": "3",
                  "eq": "388",
                  "ac": "EK",
                  "ma": "EK",
                  "seat": "9",
                  "oa": "EK",
                  "fa": [],
                  "fdur": "06:55",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "3",
                    "arr": "3"
                  }
                }
              ],
              "flightNumbers": "4",
              "bgd": [
                {
                  "qun": "35",
                  "wt": "kg"
                }
              ]
            },
            {
              "tid": "GB2KTUqOHp93mdID_2",
              "ref": 2,
              "sdur": "07:00",
              "re": "Refundable Before Departure",
              "stop": 0,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "EK",
              "seg": [
                {
                  "ref": 1,
                  "da": "LHR",
                  "ar": "DXB",
                  "dd": "2023-07-21T19:50:00",
                  "ad": "2023-07-22T05:50:00",
                  "rbd": "Y",
                  "fn": "32",
                  "trn": "3",
                  "eq": "388",
                  "ac": "EK",
                  "ma": "EK",
                  "seat": "9",
                  "oa": "EK",
                  "fa": [],
                  "fdur": "07:00",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "3",
                    "arr": "3"
                  }
                }
              ],
              "flightNumbers": "32",
              "bgd": [
                {
                  "qun": "35",
                  "wt": "kg"
                }
              ]
            },
            {
              "tid": "9fOSbyTFm46mZl8P_2",
              "ref": 2,
              "sdur": "07:00",
              "re": "Refundable Before Departure",
              "stop": 0,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "EK",
              "seg": [
                {
                  "ref": 1,
                  "da": "LHR",
                  "ar": "DXB",
                  "dd": "2023-07-21T14:20:00",
                  "ad": "2023-07-22T00:20:00",
                  "rbd": "Y",
                  "fn": "2",
                  "trn": "3",
                  "eq": "388",
                  "ac": "EK",
                  "ma": "EK",
                  "seat": "9",
                  "oa": "EK",
                  "fa": [],
                  "fdur": "07:00",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "3",
                    "arr": "3"
                  }
                }
              ],
              "flightNumbers": "2",
              "bgd": [
                {
                  "qun": "35",
                  "wt": "kg"
                }
              ]
            },
            {
              "tid": "HvpsqhOmp9Ewe31x_1",
              "ref": 1,
              "sdur": "01:55",
              "re": "Refundable Before Departure",
              "stop": 0,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "EK",
              "seg": [
                {
                  "ref": 1,
                  "da": "RUH",
                  "ar": "DXB",
                  "dd": "2023-07-17T09:35:00",
                  "ad": "2023-07-17T12:30:00",
                  "rbd": "Y",
                  "fn": "820",
                  "trn": "2",
                  "eq": "77W",
                  "ac": "EK",
                  "ma": "EK",
                  "seat": "9",
                  "oa": "EK",
                  "fa": [],
                  "fdur": "01:55",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "2",
                    "arr": "3"
                  }
                }
              ],
              "flightNumbers": "820",
              "bgd": [
                {
                  "qun": "35",
                  "wt": "kg"
                }
              ]
            },
            {
              "tid": "o5KD19mTFNUbmQ01_1",
              "ref": 1,
              "sdur": "02:05",
              "re": "Refundable Before Departure",
              "stop": 0,
              "sup": "0",
              "cl": "Economy",
              "ft": "GDS",
              "tecst": "yes",
              "dc": "no",
              "dealcode": [],
              "tktair": "EK",
              "seg": [
                {
                  "ref": 1,
                  "da": "RUH",
                  "ar": "DXB",
                  "dd": "2023-07-17T03:55:00",
                  "ad": "2023-07-17T07:00:00",
                  "rbd": "Y",
                  "fn": "816",
                  "trn": "2",
                  "eq": "77W",
                  "ac": "EK",
                  "ma": "EK",
                  "seat": "9",
                  "oa": "EK",
                  "fa": [],
                  "fdur": "02:05",
                  "cl": "Economy",
                  "terminal": {
                    "dep": "2",
                    "arr": "3"
                  }
                }
              ],
              "flightNumbers": "816",
              "bgd": [
                {
                  "qun": "35",
                  "wt": "kg"
                }
              ]
            }
          ],
          "pr": {
            "bf": 253792,
            "tf": 288288,
            "tax": [
              {
                "YQ": 10752
              },
              {
                "OT": 23744
              },
              {
                "dealmkp": 0
              },
              {
                "attmkp": 0
              },
              {
                "tamkp": 0
              },
              {
                "stamkp": 0
              },
              {
                "attdisc": 0
              },
              {
                "tadisc": 0
              },
              {
                "stadisc": 0
              }
            ],
            "scur": "QAR",
            "ucur": "INR",
            "exchange_rate": 22.4,
            "sup_fare": "11330_12870",
            "markupnames": []
          },
          "paxpr": [
            {
              "tf": 288288,
              "tax": [
                {
                  "YQ": 10752
                },
                {
                  "OT": 23744
                },
                {
                  "attmkp": 0
                },
                {
                  "tamkp": 0
                },
                {
                  "stamkp": 0
                },
                {
                  "attdisc": 0
                },
                {
                  "tadisc": 0
                },
                {
                  "stadisc": 0
                }
              ],
              "bf": 253792,
              "fbc": "WRTSA7",
              "fareType": "RP",
              "pusup_fare": "11330_12870",
              "psup_fare": "253792_288288",
              "ptype": "ADT"
            }
          ],
          "sup": "0",
          "airlinesList": "WY_EK",
          "oaairlineList": "WY",
          "servicefee": []
        }
      ],
      "mappings": [
        {
          "airportCode": "IST",
          "airportName": "Istanbul Ataturk Airport",
          "cityName": "Istanbul",
          "countryName": "Turkey",
          "countryCode": "TR"
        },
        {
          "airportCode": "IST",
          "airportName": "Istanbul Ataturk Airport",
          "cityName": "Istanbul",
          "countryName": "Turkey",
          "countryCode": "TR"
        },
        {
          "airportCode": "IST",
          "airportName": "Istanbul Ataturk Airport",
          "cityName": "Istanbul",
          "countryName": "Turkey",
          "countryCode": "TR"
        },
        {
          "airportCode": "BAH",
          "airportName": "Bahrain International Airport",
          "cityName": "Manama",
          "countryName": "Bahrain",
          "countryCode": "BH"
        },
        {
          "airportCode": "RUH",
          "airportName": "King Khalid International Airport",
          "cityName": "Riyadh",
          "countryName": "Saudi Arabia",
          "countryCode": "SA"
        },
        {
          "airportCode": "RUH",
          "airportName": "King Khalid International Airport",
          "cityName": "Riyadh",
          "countryName": "Saudi Arabia",
          "countryCode": "SA"
        },
        {
          "airportCode": "LHR",
          "airportName": "London Heathrow Airport",
          "cityName": "London",
          "countryName": "United Kingdom",
          "countryCode": "GB"
        },
        {
          "airportCode": "LHR",
          "airportName": "London Heathrow Airport",
          "cityName": "London",
          "countryName": "United Kingdom",
          "countryCode": "GB"
        },
        {
          "airportCode": "LHR",
          "airportName": "London Heathrow Airport",
          "cityName": "London",
          "countryName": "United Kingdom",
          "countryCode": "GB"
        },
        {
          "airportCode": "LHR",
          "airportName": "London Heathrow Airport",
          "cityName": "London",
          "countryName": "United Kingdom",
          "countryCode": "GB"
        },
        {
          "airportCode": "LHR",
          "airportName": "London Heathrow Airport",
          "cityName": "London",
          "countryName": "United Kingdom",
          "countryCode": "GB"
        },
        {
          "airportCode": "LHR",
          "airportName": "London Heathrow Airport",
          "cityName": "London",
          "countryName": "United Kingdom",
          "countryCode": "GB"
        },
        {
          "airportCode": "MCT",
          "airportName": "Muscat International Airport",
          "cityName": "Muscat",
          "countryName": "Oman",
          "countryCode": "OM"
        },
        {
          "airportCode": "DXB",
          "airportName": "Dubai International Airport",
          "cityName": "Dubai",
          "countryName": "United Arab Emirates",
          "countryCode": "AE"
        },
        {
          "airportCode": "DXB",
          "airportName": "Dubai International Airport",
          "cityName": "Dubai",
          "countryName": "United Arab Emirates",
          "countryCode": "AE"
        },
        {
          "airportCode": "DXB",
          "airportName": "Dubai International Airport",
          "cityName": "Dubai",
          "countryName": "United Arab Emirates",
          "countryCode": "AE"
        },
        {
          "airportCode": "DXB",
          "airportName": "Dubai International Airport",
          "cityName": "Dubai",
          "countryName": "United Arab Emirates",
          "countryCode": "AE"
        },
        {
          "airportCode": "DXB",
          "airportName": "Dubai International Airport",
          "cityName": "Dubai",
          "countryName": "United Arab Emirates",
          "countryCode": "AE"
        },
        {
          "airportCode": "IST",
          "airportName": "Istambul All",
          "countryName": "Turkey",
          "countryCode": "TU"
        }
      ],
      "echo": "5rmp1w7g0xe",
      "cid": "327",
      "tt": 3,
      "sty": "normal",
      "sec": [
        {
          "src": "RUH",
          "des": "DXB",
          "dd": "2023-07-17",
          "ad": "",
          "leg": 1
        },
        {
          "src": "LHR",
          "des": "DXB",
          "dd": "2023-07-21",
          "ad": "",
          "leg": 2
        },
        {
          "src": "DXB",
          "des": "RUH",
          "dd": "2023-07-25",
          "ad": "",
          "leg": 3
        }
      ],
      "filter": {
        "nerby": [],
        "nerbyarr": [],
        "fstst": 116928,
        "chpst": 116928,
        "minprc": 116928,
        "maxprc": 288288,
        "best": 129248,
        "fare": {
          "totalCount": {
            "nonstop": 6,
            "stops": 5
          },
          "fareSummary": [
            {
              "airlines": "TK",
              "price": {
                "nonstop": null,
                "stop": 116928,
                "stopcount": 4,
                "tid": "KtpZEeKraCQaroJ4_3"
              },
              "options": [
                "KtpZEeKraCQaroJ4_3",
                "HixpADJCSEQoJFQt_2",
                "VvTwT0zCHe7kPeze_2",
                "M5Gt0vQpplKi9N3L_2"
              ],
              "currency": "INR"
            },
            {
              "airlines": "EK",
              "price": {
                "nonstop": 118272,
                "stop": null,
                "stopcount": 0,
                "tid": "7HdkE859nfWMHEDe_1"
              },
              "options": [
                "7HdkE859nfWMHEDe_1",
                "0cqVN9Ra6THuNAOB_2",
                "akmphV1qu3IvbPp1_1",
                "sr163aVR5cRfpou8_2",
                "ItHEUhhe94bf1Sfr_2"
              ],
              "currency": "INR"
            },
            {
              "airlines": "SV",
              "price": {
                "nonstop": 269248,
                "stop": null,
                "stopcount": 0,
                "tid": "IvcJkuNQ7enb3Amh_3"
              },
              "options": [
                "IvcJkuNQ7enb3Amh_3"
              ],
              "currency": "INR"
            },
            {
              "airlines": "WY",
              "price": {
                "nonstop": null,
                "stop": 288288,
                "stopcount": 1,
                "tid": "HvpsqhOmp9Ewe31x_3"
              },
              "options": [
                "HvpsqhOmp9Ewe31x_3"
              ],
              "currency": "INR"
            }
          ]
        },
        "stps": [
          {
            "count": 0,
            "prc": 118272,
            "airline": "EK"
          },
          {
            "count": 1,
            "prc": 116928,
            "airline": "TK"
          }
        ],
        "airl": [
          {
            "count": 1,
            "prc": 116928,
            "airline": "TK"
          },
          {
            "count": 0,
            "prc": 118272,
            "airline": "EK"
          },
          {
            "count": 0,
            "prc": 269248,
            "airline": "SV"
          },
          {
            "count": 1,
            "prc": 288288,
            "airline": "WY"
          }
        ]
      },
      "cur": "INR",
      "adt": 1,
      "chd": 0,
      "inf": 0,
      "airports": [
        "RUH",
        "DXB",
        "LHR",
        "IST",
        "BAH",
        "MCT"
      ],
      "cha": "B2C",
      "region_type": "International"
    }
  ]
}