import React, { useEffect, useState,useContext } from "react";
import { Context } from "../../App";
import FlightDetailsContent from "../bookingflow/flights/results/FlightDetailsContent";
import PassengersDetails from "../bookingflow/flights/confirmation/PassengersDetails";
import { isArrayNotEmpty } from "../utilities/validators";

const MyTripsItineraryPdf = React.forwardRef(({itiData}, ref) => {
  const [context, setContext] = useContext(Context);
  const [sectors,setSectors] = useState();
  useEffect(() => {
    constructSectors();
  }, []);

  const constructSectors = () => {
    // Initialize the result array
    var sec = [];
    var tt = parseInt(itiData?.tt);
    if (tt === 1 || tt === 2 || tt === 3) {
      itiData?.origns[0].srcorigns.forEach((srcorign, index) => {
        var src = srcorign.seg[0].da;
        var des = srcorign.seg[srcorign.seg.length - 1].ar;
        var dd = srcorign.seg[0].dd.split("T")[0]; // Format "dd" as yyyy-MM-dd
        var ad = srcorign.seg[srcorign.seg.length - 1].ad.split("T")[0]; // Format "ad" as yyyy-MM-dd
        sec.push({
          src,
          des,
          dd,
          ad,
          leg: index + 1, // Increment leg number
        });
      });
      if (tt === 2) {
        itiData?.origns[0].destorigins.forEach((destorigin, index) => {
          var src = destorigin.seg[0].da;
          var des = destorigin.seg[destorigin.seg.length - 1].ar;
          var dd = destorigin.seg[0].dd.split("T")[0]; // Format "dd" as yyyy-MM-dd
          var ad = destorigin.seg[destorigin.seg.length - 1].ad.split("T")[0]; // Format "ad" as yyyy-MM-dd

          // Push the data into the "sec" array
          sec.push({
            src,
            des,
            dd,
            ad,
            leg: itiData?.origns[0].srcorigns.length + index + 1, // Increment leg number
          });
        });
      }
    }
    if(isArrayNotEmpty(sec)){
      setSectors(sec);
    }
  };

  return (
    <div ref={ref} className="viewItineraryWrap">
      <div className="commonFlex paddingRight">
        <span className="itineraryTitle">{context.translation && context.translation.urItinerary}</span>
        {/* <span className="manageBooking">Manage Booking</span> */}
      </div>
      {itiData && sectors &&
        itiData?.origns[0] && itiData?.origns[0].srcorigns &&
        itiData?.origns[0].srcorigns.length > 0 &&
        itiData?.origns[0].srcorigns.map((s, i) => (
          <>
            <FlightDetailsContent
              key={i}
              mapping={itiData?.mappings}
              sectors={itiData?.tt.toString()!=="3"?sectors:(sectors.length > 0 &&
                sectors.filter((sec) => {
                  return sec.leg === i+1;
                }))}
              secType={"onward"}
              secTypeIndex={i}
              trip={itiData?.tt.toString()}
              flt={s} // Use s directly as the flt prop
              seg={s.seg[0]}
              refund={s.re}
              flow={false}
            />
            <PassengersDetails sector={"O"} sup={s.sup} response={itiData} />
          </>
        ))}

      {itiData && sectors &&
        itiData?.origns[0] && itiData?.origns[0].destorigins &&
        itiData?.origns[0].destorigins.length > 0 &&
        itiData?.origns[0].destorigins.map((s, i) => (
          <>
            <FlightDetailsContent
              key={i}
              mapping={itiData?.mappings}
              sectors={sectors}
              secType={"arrival"}
              secTypeIndex={0}
              trip={itiData?.tt.toString()}
              flt={s} // Use s directly as the flt prop
              seg={s.seg[0]}
              refund={s.re}
              flow={false}
            />
            <PassengersDetails sector={"O"} sup={s.sup} response={itiData} />
          </>
        ))}
    </div>
    );
});

export default MyTripsItineraryPdf;
