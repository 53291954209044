/**
 * @ Author: Srikanth Bandaru
 * @ Create Time: 2023-04-27 15:42:53
 * @ Description: Predective Search component
 */

import axios from "axios";
import api from '../../../../utilities/api'; // Import the custom axios instance

const API_URL = process.env.REACT_APP_B2B_URL;
const API_B2C_URL = process.env.REACT_APP_B2C_ENGINE_URL;
class PredectiveSearch {
  //For retrieving airports based on inputs
  // async retreieveSearch(data,lng) {
  async retreieveSearch(obj,token) {
    // const obj = {
    //   searchCriteria: data,
    //   lang : lng
    // };
    const headers = {
      headers: {
          'Content-Type': 'application/json',
          'auth-token': token
        }
     };
    return await api.post(`${API_B2C_URL}/cnt/common/airports`, obj, headers);
  }

  //For retrieving airports based on multiple inputs
  // async retreieveAirportData(data,lng) {
  async retreieveAirportData(obj,token) {
    // const obj = {
    //   searchCriteria: data,
    //   lang : lng
    // };
    const headers = {
      headers: {
          'Content-Type': 'application/json',
          'auth-token': token
        }
     };
    return await api.post(`${API_B2C_URL}/cnt/common/airports-data`, obj,headers);
  }

  //For retrieving airports based on inputs
  async retriveRecentSearch(id) {
    return await api.get(`${API_URL}/common/fetchPredictive/${id}`);
  }
}
export default new PredectiveSearch();
