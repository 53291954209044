/**
 * @ Author: Ubedullah Mohammed
 * @ Create Time: 2024-01-16 12:10:53
 * @ Description: Error component when the API has no response
 */

import { Button, Typography } from 'antd';
import React from 'react';
import Offline from "../../assets/img/offline.png";

const { Title } = Typography;

function NetworkError(props) {
    const reload = () => {
      window.location.reload();
    }
    
  return (
    <>
    <div className='errorOuterWrap networkError'>
        <div className='errorInnerWrap networkError'>
            <center>
                <img src={Offline} alt='Offline' />
                <Title level={2}>{props.context.translation && props.context.translation.networkerr}</Title>
                <p>{props.context.translation && props.context.translation.youOff}</p>
                <Button onClick={reload}>{props.context.translation && props.context.translation.retry}</Button>
            </center>
        </div>
    </div>
    </>
 )
}

export default NetworkError;