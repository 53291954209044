/**
 * @ Author: Srikanth Bandaru
 * @ Create Time: 2023-06-06 20:28:49
 * @ Description: This is for displaying the flight fare rules under the flight details pop up
 */

import React, { useEffect, useState,useContext } from "react";
import { getLongfareRules } from "../../flights/common/services/operations";
import { isNotNull } from "../../../utilities/validators";
import { Context } from "../../../../App";
import AirportMemo from "../common/airlines/AirportNames";
import FlightFareRulesTerms from "./FlightFareRulesTerms";
import FlightStaticFareRules from "./FlightStaticFareRules";
import Utilities from "../../../utilities/Utilities";
function FlightFareRules(props) {
  const { 
    flights, selectedFlight, request, rtnflt, sty, mapping, sf, sectors, secType } =
    props;
    const [context, setContext] = useContext(Context)
  const [tripType, setTripType] = useState("");
  const [loading, setLoading] = useState(false);
  const [selFlight, setSelFlight] = useState(selectedFlight);
  const [showData, setShowData] = useState("");
  const [longrules, setLongRules] = useState([]);
  const [showSectors, setShowSectors] = useState([]);
  const [applicables, setApplicables] = useState([]);
  const [showLong, setShowLong] = useState(false);
  const [showFareContent, setShowFareContent] = useState(false);
  const [serviceFee, setServiceFee] = useState("");
  const [lgShow, setLgShow] = useState(false);
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [showPenalty, setShowPenalty] = useState(false);

  useEffect(() => {
    setLoading(true);
    if (request !== undefined && request.tt !== undefined) {
      setTripType(request.tt);
    }
    if (selFlight !== undefined) {
      var farerequest = "";
      if(sty === "normal"){
        if (parseInt(request.tt) === 1 || parseInt(request.tt) === 3) {
          farerequest = createOneWayRequest(false, "");
        } else if (parseInt(request.tt) === 2) {
          farerequest = createRoundTripRequest(false, "");
        }
      } else if(sty === "split"){
        if(flights.sup === "0" && flights.sup === rtnflt.sup){
          farerequest = createRoundTripRequest(false, "");
        } else if(((flights.sup === "0" || rtnflt.sup === "0") && flights.sup !== rtnflt.sup)){
          farerequest = createOneWayRequest(false, "");
        }
      }
      if(isNotNull(farerequest)){
      farerequest.pcid = 0;
      farerequest.butype = "ta_user";
      farerequest.bid = 601;
      try {
        let tokenKey = Utilities.generateAPIToken(farerequest);
        getLongfareRules(farerequest,tokenKey).then((response) => {
          if (
            response.data !== undefined &&
            response.data.longRuleRes !== undefined &&
            response.data.longRuleRes.status === "success" && 
            response.data.longRuleRes.data[0].sectors.length > 0
          ) {
            setShowSectors(response.data.longRuleRes.data[0].sectors);
            setShowLong(true);
            setLoading(false);
          } else {
            setLoading(false);
            setShowLong(true);
            setShowFareContent(true);
          }
        }).catch(function (error) {
          console.error("Error:" + error);      
        });        
      } catch (error) {
        console.error("Error:", error); 
      }
    }  
    }
  }, []);

  //=====================================
  // Create payload for One way
  //=====================================
  const createOneWayRequest = (selected, code) => {
    const payload = {
      echo: request.echo,
      cha: request.cha,
      cur: request.cur,
      adt: request.adt,
      chd: request.chd,
      inf: request.inf,
      cid: request.cid,
      sty: request.sty,
      tt: "1",
      tid: flights.tid,
      ruleType: !selected ? "PE" : code,
      origns: [
        {
          srcorigns: (sty === "normal")?[convertFareReq(flights)]:
          flights.sup === "0"?[convertFareReq(flights)]:[convertFareReq(rtnflt)]
          ,
        },
      ],
    };
    return payload;
  };
  //=====================================
  // create Round FareRule Req
  //=====================================
  const createRoundTripRequest = (selected, code) => {
    const payload = {
      echo: request.echo,
      cha: request.cha,
      cur: request.cur,
      adt: request.adt,
      chd: request.chd,
      inf: request.inf,
      cid: request.cid,
      sty: request.sty,
      tt: request.tt,
      tid: flights.tid,
      ruleType: !selected ? "PE" : code,
      origns: [
        {
          srcorigns: [convertFareReq(flights)],
          destorigins: [convertFareReq(rtnflt)],
        },
      ],
    };
    return payload;
  };
  //=====================================
  // create Round FareRule Req
  //=====================================
  const convertFareReq = (selectedFlt) => {
    const payload = {
      tid: selectedFlt.tid,
      ref: selectedFlt.ref,
      ofcid: "DOHQR28IH",
      sdur: selectedFlt.sdur,
      re: selectedFlt.re,
      stop: selectedFlt.stop,
      sup: selectedFlt.sup,
      cl: selectedFlt.cl,
      ft: selectedFlt.ft,
      tktair: selectedFlt.tktair,
      tecst: selectedFlt.tecst,
      seg: selectedFlt.seg,
      bgd: selectedFlt.bgd,
      pr: selFlight.pr,
      paxpr: selFlight.paxpr,
    };
    return payload;
  };

  //Showing the Rules for Mini fares
  const openRules = (obj) => (evt) => {
    setLgShow(true);
    setTitle(obj.type);
    setContent(obj.notes);
  };
  //Updating seleted segment
  const handleChangeRule = (val) => (e) => {
    if (longrules.length && applicables.length !== 0) {
      setShowPenalty(true);
      let secs = longrules[0].sectors.filter((q, idx) => q.selected);
      for (let rule of applicables) {
        if (!rule.selected && rule.value === val) {
          rule.selected = true;
          if (props.sty !== undefined && props.sty === "normal") {
            if (selFlight !== undefined) {
              var farerequest = "";
              if(sty === "normal"){
                if (parseInt(request.tt) === 1 || parseInt(request.tt) === 3) {
                  farerequest = createOneWayRequest(true, val);
                } else if (parseInt(request.tt) === 2) {
                  farerequest = createRoundTripRequest(true, val);
                }
              } else if(sty === "split"){
                if(flights.sup === "0" && flights.sup === rtnflt.sup){
                  farerequest = createRoundTripRequest(true, val);
                } else if(((flights.sup === "0" || rtnflt.sup === "0") && flights.sup !== rtnflt.sup)){
                  farerequest = createOneWayRequest(true, val);
                }
              }
              setTripType(request.tt);
              let tokenKey = Utilities.generateAPIToken(farerequest);
              getLongfareRules(farerequest,tokenKey).then((response) => {
                if (
                  response.data !== undefined &&
                  response.data.longRuleRes !== undefined &&
                  response.data.longRuleRes.status === "success" && 
                  response.data.longRuleRes.data[0].sectors.length > 0
                ) {
                  setShowSectors(response.data.longRuleRes.data[0].sectors);
                  setShowLong(true);
                  setLoading(false);
                } else {
                  setLoading(false);
                }
              }).catch(function (error) {
                console.error("Error:" + error);      
              });
            }
          }
        } else {
          rule.selected = false;
        }
      }
    }
  };
  const handleTabChange = (obj) => {
    for (let sec of longrules[0].sectors) {
      if (sec.id === obj.id) {
        sec.selected = true;
        setShowData(obj);
      } else {
        sec.selected = false;
      }
    }
  };
  return (
    <>
      {!showLong && (
          <div className="circleLoaderWrap preLoaderWrap">
               <span className="circleLoader"></span>
          </div>
      )}
      {showLong && !showFareContent && (
        <>
         {/* <div> */}
          {showSectors &&
            showSectors.map((sec, index) => (
             
              <div className="fareRulesMainWrap" span={12} key={index}>
              <div key={sec.id} className="rb-top">
                <div className="info">
                <span className="smallFlight"><i className="icon icon-flight"></i></span>
                  <span className="route">
                   {sec.dep!=="LON" &&
                   <AirportMemo
                      field="ResultSectionHeader"
                      code={sec.dep}
                      mapping={mapping}
                    />
                   }
                  {sec.dep==="LON" &&
                   "London"
                   }
                  </span>
                  <span className="rightArrow">
                    <i className="icon icon-next-long-arrow-right-svgrepo-com"></i>
                  </span>
                  <span className="route">
                  {sec.arr!=="LON" &&
                    <AirportMemo
                      field="ResultSectionHeader"
                      code={sec.arr}
                      mapping={mapping}
                    />
                  }
                  {sec.arr==="LON" &&
                   "London"
                   }
                  </span>
                </div>
                <div className="listItemWrap">
                  <div className="listItemHeading">{context.translation && context.translation.cancals}</div>
                  <ul className="listItemCharge">
                    <li>
                      {sec.rules.length >= 0 &&
                      sec.rules.map((s,i) => {
                        if (s.type === "CANCELLATION") {
                          return <span key={i}>{s.notes}</span>;
                        }
                        return null;
                      })}
                    </li>
                  </ul>
                </div>
                <div className="listItemWrap">
                  <div className="listItemHeading">{context.translation && context.translation.changes}</div>
                  <ul className="listItemCharge">
                    <li>
                      {sec.rules.length >= 0 &&
                      sec.rules.map((s,i) => {
                        if (s.type === "CHANGE") {
                          return <span key={i}>{s.notes}</span>;
                        }
                        return null; 
                      })}
                    </li>
                  </ul>
                </div>
              </div>
              </div>
              
            ))
          }
          {/* </div> */}
          {/* <FlightFareRulesTerms cur={request.cur} sf={sf} /> */}
        </>
      )}

      {showFareContent && (
        <FlightStaticFareRules
          mapping={mapping}
          secType={secType}
          sect={sectors}
          flights={flights}
          rtnflt={rtnflt}
          showSectors={showSectors}
          sf={sf}
          cur={request.cur} />
      )}


    </>
  );
  
}

export default FlightFareRules;
