/**
 * @ Author: Srikanth Bandaru
 * @ Create Time: 2023-06-23 14:26:17
 * @ Description: This is for displaying the Fare Rules for LCC flights under FareRules tab of FlightDetails
 */

import React, { useEffect } from "react";
import FlightFareOption from "./FlightFareOption";
import { Col, Row } from "antd";
import FlightFareOptionDetails from "./FlightFareOptionDetails";

function FlightLccFareRules(props) {
  const {
    secType,
    sty,
    flights,
    selectedFlight,
    request,
    rtnflt,
    selFlt,
    regionType,
    mapping,
    sect,
  } = props;
  
  return (
    <>
      {flights !== undefined && secType !== undefined && (
        <>
          {flights !== undefined && flights.fareop !== undefined && (
              flights.fareop.map((fare, index) => (
                <>
                  {index === 0 && (
                    <FlightFareOptionDetails
                      fare={fare}
                      serviceFee={fare.servicefee}
                      secType={secType}
                      sty={sty}
                      flights={flights}
                      selectedFlight={selectedFlight}
                      request={request}
                      rtnflt={rtnflt}
                      selFlt={selFlt}
                      region={regionType}
                      mapping={mapping}
                      sect={sect}
                    />
                  )}
                 {/*  {index === 0 && (
                    <FlightFareOption
                      fare={fare}
                      flights={flights}
                      serviceFee={fare.servicefee}
                      request={request}
                      region={regionType}
                      mapping={mapping}
                      sect={sect}
                    />
                  )} */}
                </>
              ))
          )}
          {rtnflt !== undefined && rtnflt.fareop !== undefined && (
              rtnflt.fareop.map((fare, index) => (
                <>
                  {index === 0 && (
                    <FlightFareOptionDetails
                      fare={fare}
                      serviceFee={fare.servicefee}
                      secType={"arrival"}
                      sty={sty}
                      flights={flights}
                      selectedFlight={selectedFlight}
                      request={request}
                      rtnflt={rtnflt}
                      selFlt={selFlt}
                      region={regionType}
                      mapping={mapping}
                      sect={sect}
                    />
                  )}
                  {/* {index === 0 && (
                    <Row>
                      <Col xs={12}>
                        <FlightFareOption
                          fare={fare}
                          flights={rtnflt}
                          serviceFee={fare.servicefee}
                          srequest={request}
                          region={regionType}
                          mapping={mapping}
                          sect={sect}
                        />
                      </Col>
                    </Row>
                  )} */}
                </>
              ))
          )}
        </>
      )}
    </>
  );
}

export default FlightLccFareRules;
