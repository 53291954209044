/**
 * @ Author: Srikanth Bandaru
 * @ Create Time: 2023-05-30 18:01:46
 * @ Description: This is for fare details to be shown inside the flight details popup
 */

import { Col, Row } from "antd";
import Link from "antd/es/typography/Link";
import React, { useEffect, useState, useReducer, useContext } from "react";
import FormatCurrency from "../../../utilities/FormatCurrency";
import Utilities from "../../../utilities/Utilities";
import Title from "antd/es/typography/Title";
import { Context } from "../../../../App";

/**
 * @description:This function will Calculate the Pax wise Fare
 * @author: Ubedullah
 * @param {*}
 * @function Traveller details
 * @date : 01-09-2020
 */

/**
* Initial State Declaration
*/
const initialState = {
  tsfee: 0, tdisc: 0, totalfare: 0, discount: 0 ,gt : 0 , mealAdt:0,mealChd:0,mealInf:0,adtList:[],totalExtra:0,adtBagList:[],chdBagList:[],mealAdtlist:[],mealChdlist:[],otCharge:0,seatPrice:0
};

//====================================
// Reducer function for userReact Hook
// which will update the state
//=====================================
const reducer = (state, action) => {
  switch (action.type) {
    default:
      return { ...state, [action.type]: action.payload };
  }
};

function FlightFareDetails(props) {
  const { paxpr, req, pr, sf, flow, dis } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const [context, setContext] = useContext(Context)
  const [showAdultFare, setShowAdultFare] = useState(true);
  const [showChildFare, setShowChildFare] = useState(false);
  const [showInfantFare, setShowInfantFare] = useState(false); 
  const [showExtrasFare, setShowExtrasFare] = useState(false); 

  useEffect(()=>{
    if(props.baggagePrice!="" && props.baggagePrice!==undefined){
     if(props.baggagePrice[1]==="ADT"){
          const payload={
            "no":props.baggagePrice[2],
            "flow":props.baggagePrice[3],
            "price":props.baggagePrice[0].price
          }
          if(state.adtBagList.length===0){
            let list=[];
            list.push(payload);
            dispatch({ type: 'adtBagList', payload: list })
          }else{
            let match=true;
            for(let val of state.adtBagList){
              if(val.no===props.baggagePrice[2] && val.flow===props.baggagePrice[3]){
                 val.price=props.baggagePrice[0].price;
                 match=false;
              }
            }
            if(match){
              let bagsList=[];
              bagsList=state.adtBagList;
              bagsList.push(payload);
              dispatch({ type: 'adtBagList', payload: bagsList })
            }else{
              dispatch({ type: 'adtBagList', payload: state.adtBagList })
            }
          }
     }else if(props.baggagePrice[1]==="CHD"){
      const payload={
        "no":props.baggagePrice[2],
        "flow":props.baggagePrice[3],
        "price":props.baggagePrice[0].price
      }
      if(state.chdBagList.length===0){
        let list=[];
        list.push(payload);
        dispatch({ type: 'chdBagList', payload: list })
      }else{
        let match=true;
        for(let val of state.chdBagList){
          if(val.no===props.baggagePrice[2] && val.flow===props.baggagePrice[3]){
             val.price=props.baggagePrice[0].price;
             match=false;
          }
        }
        if(match){
          let bagsList=[];
          bagsList=state.chdBagList;
          bagsList.push(payload);
          dispatch({ type: 'chdBagList', payload: bagsList })
        }else{
          dispatch({ type: 'chdBagList', payload: state.chdBagList })
        }
      }
     }else if(props.baggagePrice[1]==="INF"){
      if(props.baggagePrice[3]==="O"){
        dispatch({ type: 'oInfp', payload:Number(props.baggagePrice[0].price) })
        dispatch({ type: 'bagInf', payload:state.rInfp+Number(props.baggagePrice[0].price) })
       }else if(props.baggagePrice[3]==="R"){
        dispatch({ type: 'rInfp', payload:Number(props.baggagePrice[0].price) })
        dispatch({ type: 'bagInf', payload:state.oInfp+Number(props.baggagePrice[0].price) })
       }
     }

    }
  },[props.baggagePrice])

  

  useEffect(()=>{
    if(props.selMealprice!="" && props.selMealprice!==undefined){
     if(props.selMealprice[1]==="ADT"){
      const payload={
        "no":props.selMealprice[2],
        "flow":props.selMealprice[3],
        "price":props.selMealprice[0].price,
        "route":props.selMealprice[4]
      }
      if(state.mealAdtlist.length===0){
        let list=[];
        list.push(payload);
        dispatch({ type: 'mealAdtlist', payload: list })
      }else{
        let match=true;
        for(let val of state.mealAdtlist){
          if(val.no===props.selMealprice[2] && val.flow===props.selMealprice[3] && val.route===props.selMealprice[4]){
             val.price=props.selMealprice[0].price;
             match=false;
          }
        }
        if(match){
          let bagsList=[];
          bagsList=state.mealAdtlist;
          bagsList.push(payload);
          dispatch({ type: 'mealAdtlist', payload: bagsList })
        }else{
          dispatch({ type: 'mealAdtlist', payload: state.mealAdtlist })
        }
      }
     }else if(props.selMealprice[1]==="CHD"){
      const payload={
        "no":props.selMealprice[2],
        "flow":props.selMealprice[3],
        "price":props.selMealprice[0].price,
        "route":props.selMealprice[4]
      }
      if(state.mealChdlist.length===0){
        let list=[];
        list.push(payload);
        dispatch({ type: 'mealChdlist', payload: list })
      }else{
        let match=true;
        for(let val of state.mealChdlist){
          if(val.no===props.selMealprice[2] && val.flow===props.selMealprice[3] && val.route===props.selMealprice[4]){
             val.price=props.selMealprice[0].price;
             match=false;
          }
        }
        if(match){
          let bagsList=[];
          bagsList=state.mealChdlist;
          bagsList.push(payload);
          dispatch({ type: 'mealChdlist', payload: bagsList })
        }else{
          dispatch({ type: 'mealChdlist', payload: state.mealChdlist })
        }
      }
     }else if(props.selMealprice[1]==="INF"){
      dispatch({ type: 'mealInf', payload:state.mealInf + Number(props.selMealprice[0].price) })
     }
    }
  },[props.selMealprice])

  const calAdtBagPrice = () => {
    let totalPrice=0;
       if(state.adtBagList.length!==0){
         for(let val of state.adtBagList){
           if(totalPrice===0 && val.price!==undefined){
            totalPrice=Number(val.price);
           }else if(val.price!==undefined){
            totalPrice=Number(totalPrice+val.price);
           }
          }
          props.sendAdtPrice(totalPrice);
       }
    return totalPrice;
  }

  const calChdBagPrice = () => {
      let totalPrice=0;
      if(state.chdBagList.length!==0){
        for(let val of state.chdBagList){
          if(totalPrice===0 && val.price!==undefined){
          totalPrice=Number(val.price);
          }else if(val.price!==undefined){
          totalPrice=Number(totalPrice+val.price);
          }
        }
        props.sendChdPrice(totalPrice);
      }

    return totalPrice;
  }

  const calAdtMealPrice = () => {
    let totalPrice=0;
      if(state.mealAdtlist.length!==0){
        for(let val of state.mealAdtlist){
          if(totalPrice===0 && val.price!==undefined){
            totalPrice=Number(val.price);
          }else if(val.price!==undefined){
            totalPrice=Number(totalPrice+val.price);
          }
          }
          props.sendMealAdtPrice(totalPrice);
      }
    return totalPrice;
  }

  const calChdMealPrice = () => {
    let totalPrice=0;
      if(state.mealChdlist.length!==0){
        for(let val of state.mealChdlist){
          if(totalPrice===0 && val.price!==undefined){
            totalPrice=Number(val.price);
          }else if(val.price!==undefined){
            totalPrice=Number(totalPrice+val.price);
          }
          }
          props.sendMealChdPrice(totalPrice);
      }
    return totalPrice;
  }

  const calServiceFee = () => {
    let serviceFee=0;
       if(sf!==undefined ){
        if(sf.length > 0 && sf[0].complete_sfvalue!==undefined){
          serviceFee=sf[0].complete_sfvalue;
        }
       }
    return serviceFee;
  }

  const calPrice = (price,reVal,reSign) => {
    let total=price;
      if(reVal!==undefined && reSign!==undefined){
        if(reSign==="+"){
          total = Number(total) + Number(reVal);
         }else{
          total = Number(total) - Number(reVal);
         }
      }
    return total;
  }

  return (
    <>
      {paxpr && (
        <div className="innerSummary">
          {paxpr
            .filter((pax) => pax.ptype === "ADT")
            .map((prc, idx) => (
              <div key={idx}>
                <div className="paxDetailWrap">
                    <div className="dFlex">
                      <div onClick={() => setShowAdultFare(!showAdultFare)}
                        className={showAdultFare ? "rightArrow active" : "rightArrow"}>
                        <span className="pax">
                          {context.translation && context.translation.adts} ({req.adt?req.adt:req.search?req.search.adt:""})
                        </span>
                      </div>
                      <div>
                        <span className="currencyCode">{req.cur}</span>
                        &nbsp;
                        <span className="currencyAmount">
                          <FormatCurrency
                            value={calPrice(prc.tf,prc.rv,prc.rs) * (req.adt?req.adt:req.search?req.search.adt:1) * props.base_usr_exrate}
                            className="currencyAmount"
                          />
                        </span>
                      </div>
                    </div>
                  {showAdultFare && (
                    <>
                      <div className="paxInnerDetail">
                        <div className="dFlex">
                          <div>
                            <span className="paxDetailList">{context.translation && context.translation.fare}</span>
                          </div>
                          <div>
                            <span className="currencyCode">{req.cur}</span>
                            &nbsp;
                            <span className="currencyAmount">
                              <FormatCurrency
                                value={calPrice(prc.bf,prc.rv,prc.rs) * (req.adt?req.adt:req.search?req.search.adt:1) * props.base_usr_exrate}
                                className="currencyAmount"
                              />
                            </span>
                          </div>
                        </div>
                        <div className="dFlex">
                          <div>
                            <span className="paxDetailList">{context.translation && context.translation.taxNfee}</span>
                          </div>
                          <div>
                            <span className="currencyCode">{req.cur}</span>
                            &nbsp;
                            <span className="currencyAmount">
                              <FormatCurrency
                                value={(Number(Utilities.taxCalculation(prc.tax, req.sty))) * (req.adt?req.adt:req.search?req.search.adt:1) * props.base_usr_exrate}
                                className="currencyAmount"
                              />
                            </span>
                          </div>
                        </div>
                        <div className="dFlex discountWrapper">
                          <div>
                            <span className="discount">{context.translation && context.translation.discount}</span>
                          </div>
                          <div>
                            <span className="currencyCode">{req.cur}</span>
                            &nbsp;
                            <span className="currencyAmount">
                              <FormatCurrency
                                value={Number(Utilities.discountCalculation(prc.tax, req.sty)) * (req.adt?req.adt:req.search?req.search.adt:1) * props.base_usr_exrate}
                                className="currencyAmount"
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
                <div className="innerSumDivider"></div>
              </div>
            ))}

          {paxpr
            .filter((pax) => pax.ptype === "CHD")
            .map((prc, idx) => (
              <div key={idx}>
                <div className="paxDetailWrap">
                    <div className="dFlex">
                    <div onClick={() => setShowChildFare(!showChildFare)}
                        className={showChildFare ? "rightArrow active" : "rightArrow"}>
                        <span className="pax">{context.translation && context.translation.chd} ({req.chd?req.chd:req.search?req.search.chd:""})</span>
                      </div>
                      <div>
                        <span className="currencyCode">{req.cur}</span>
                        &nbsp;
                        <span className="currencyAmount">
                          <FormatCurrency
                            value={calPrice(prc.tf,prc.rv,prc.rs) * (req.chd?req.chd:req.search?req.search.chd:1) * props.base_usr_exrate}
                            className="currencyAmount"
                          />
                        </span>
                      </div>
                    </div>
                  {showChildFare && (
                    <>
                      <div className="paxInnerDetail">
                        <div className="dFlex">
                          <div>
                            <span className="paxDetailList">{context.translation && context.translation.fare}</span>
                          </div>
                          <div>
                            <span className="currencyCode">{req.cur}</span>
                            &nbsp;
                            <span className="currencyAmount">
                              <FormatCurrency
                                value={calPrice(prc.bf,prc.rv,prc.rs) * (req.chd?req.chd:req.search?req.search.chd:1) * props.base_usr_exrate}
                                className="currencyAmount"
                              />
                            </span>
                          </div>
                        </div>
                        <div className="dFlex">
                          <div>
                            <span className="paxDetailList">{context.translation && context.translation.taxNfee}</span>
                          </div>
                          <div>
                            <span className="currencyCode">{req.cur}</span>
                            &nbsp;
                            <span className="currencyAmount">
                              <FormatCurrency
                                value={(Number(Utilities.taxCalculation(prc.tax, req.sty))) * (req.chd?req.chd:req.search?req.search.chd:1) * props.base_usr_exrate}
                                className="currencyAmount"
                              />
                            </span>
                          </div>
                        </div>
                        <div className="dFlex discountWrapper">
                          <div>
                            <span className="discount">{context.translation && context.translation.discount}</span>
                          </div>
                          <div>
                            <span className="currencyCode">{req.cur}</span>
                            &nbsp;
                            <span className="currencyAmount">
                              <FormatCurrency
                                value={Number(Utilities.discountCalculation(prc.tax, req.sty)) * (req.chd?req.chd:req.search?req.search.chd:1) * props.base_usr_exrate}
                                className="currencyAmount"
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
                <div className="innerSumDivider"></div>
              </div>
            ))}

          {paxpr
            .filter((pax) => pax.ptype === "INF")
            .map((prc, idx) => (
              <div key={idx}>
                <div className="paxDetailWrap">
                    <div className="dFlex">
                      <div onClick={() => setShowInfantFare(!showInfantFare)}
                        className={showInfantFare ? "rightArrow active" : "rightArrow"}>
                        <span className="pax">
                          {context.translation && context.translation.inf} ({req.inf?req.inf:req.search?req.search.inf:""})
                        </span>
                      </div>
                      <div>
                        <span className="currencyCode">{req.cur}</span>
                        &nbsp;
                        <span className="currencyAmount">
                          <FormatCurrency
                            value={prc.tf * (req.inf?req.inf:req.search?req.search.inf:1)* props.base_usr_exrate}
                            className="currencyAmount"
                          />
                        </span>
                      </div>
                    </div>
                  {showInfantFare && (
                    <>
                      <div className="paxInnerDetail">
                        <div className="dFlex">
                          <div>
                            <span className="paxDetailList">{context.translation && context.translation.fare}</span>
                          </div>
                          <div>
                            <span className="currencyCode">{req.cur}</span>
                            &nbsp;
                            <span className="currencyAmount">
                              <FormatCurrency
                                value={prc.bf  * (req.inf?req.inf:req.search?req.search.inf:1)* props.base_usr_exrate}
                                className="currencyAmount"
                              />
                            </span>
                          </div>
                        </div>
                        <div className="dFlex">
                          <div>
                            <span className="paxDetailList">{context.translation && context.translation.taxNfee}</span>
                          </div>
                          <div>
                            <span className="currencyCode">{req.cur}</span>
                            &nbsp;
                            <span className="currencyAmount">
                              <FormatCurrency
                                value={(Number(Utilities.taxCalculation(prc.tax, req.sty))) * (req.inf?req.inf:req.search?req.search.inf:1)* props.base_usr_exrate}
                                className="currencyAmount"
                              />
                            </span>
                          </div>
                        </div>
                        <div className="dFlex discountWrapper">
                          <div>
                            <span className="discount">{context.translation && context.translation.discount}</span>
                          </div>
                          <div>
                            <span className="currencyCode">{req.cur}</span>
                            &nbsp;
                            <span className="currencyAmount">
                              <FormatCurrency
                                value={Number(Utilities.discountCalculation(prc.tax, req.sty)) * (req.inf?req.inf:req.search?req.search.inf:1) * props.base_usr_exrate}
                                className="currencyAmount"
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
                <div className="innerSumDivider"></div>
              </div>
            ))}    
          
          {!props.notReview && (
            <>
            {(calAdtBagPrice() !== 0  || calAdtMealPrice() !== 0 || props.seatPrice !== 0) &&  
              <>
                <div className="paxDetailWrap">
                  {/* <Link
                    onClick={() => setShowExtrasFare(!showExtrasFare)}
                    className={showExtrasFare && "active"}
                  > */}
                    
                    <div className="dFlex">
                      <div onClick={() => setShowExtrasFare(!showExtrasFare)}
                    className={showExtrasFare ? "rightArrow active" : "rightArrow"}>
                        <span className="pax">
                          {context.translation && context.translation.extras}
                        </span>
                      </div>
                      <div>
                        <span className="currencyCode">{req.cur}</span>
                        &nbsp;
                        <span className="currencyAmount">
                          <FormatCurrency
                            value={Utilities.addPrice(calAdtBagPrice() + calChdBagPrice() + calAdtMealPrice() + calChdMealPrice() + props.seatPrice, 0, "Add") * props.base_usr_exrate}
                            className="currencyAmount"
                          />
                        </span>
                      </div>
                    </div>
                  {/* </Link> */}
                  {showExtrasFare && (
                    <>
                      <div className="paxInnerDetail">
                        {props.seatPrice !== 0 && (
                          <div className="dFlex">
                            <div>
                              <span className="paxDetailList">{context.translation && context.translation.seat}</span>
                            </div>
                            <div>
                              <span className="currencyCode">{req.cur}</span>
                              &nbsp;
                              <span className="currencyAmount">
                                <FormatCurrency
                                  value={props.seatPrice * props.base_usr_exrate}
                                  className="currencyAmount"
                                />
                              </span>
                            </div>
                          </div>
                        )}
                        {calAdtMealPrice() !== 0 && (
                          <div className="dFlex">
                            <div>
                              <span className="paxDetailList">{context.translation && context.translation.meal}</span>
                            </div>
                            <div>
                              <span className="currencyCode">{req.cur}</span>
                              &nbsp;
                              <span className="currencyAmount">
                                <FormatCurrency
                                  value={Utilities.addPrice(calAdtMealPrice() + calChdMealPrice(), 0, "Add") * props.base_usr_exrate}
                                  className="currencyAmount"
                                />
                              </span>
                            </div>
                          </div>
                        )}
                        {calAdtBagPrice() !== 0 && (
                          <div className="dFlex">
                            <div>
                              <span className="paxDetailList">{context.translation && context.translation.bagg}</span>
                            </div>
                            <div>
                              <span className="currencyCode">{req.cur}</span>
                              &nbsp;
                              <span className="currencyAmount">
                                <FormatCurrency
                                  value={Utilities.addPrice(calAdtBagPrice() + calChdBagPrice(), 0, "Add") * props.base_usr_exrate}
                                  className="currencyAmount"
                                />
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                    </>
                  )}
                </div>
                <div className="innerSumDivider"></div>
              </>
            }
            </>
            )}

            {/* <div className="innerSumDivider"></div>
            {flow === 'REVIEW' && 
              <div className="addOn dashedTopBorder">
                <Title level={5}>{context.translation && context.translation.addon}</Title>
                <div className="dFlex travelWrapper">
                  <div>
                    <span className="travel">Travel Insurance</span>
                  </div>
                  <div>
                    <span className="currencyCode">QAR </span>
                    &nbsp;
                    <span className="currencyAmount">0.00</span>
                  </div>
                </div>
              </div>
             } */}
              
              {flow === 'SRP' && 
              <div className="commonFlex serviceWrapper">
                <div>
                  <span className="serviceTitle">{context.translation && context.translation.serFee}</span>
                </div>
                <div>
                  <span className="currencyCode">{req.cur} </span>
                  <span className="currencyAmount">
                    <FormatCurrency value={calServiceFee() * props.base_usr_exrate} />
                  </span>
                </div>
              </div>}
             
              {flow === 'REVIEW' && 
              <div className="serviceDiscount">
              <div className="dFlex serviceWrapper">
                <div>
                  <span className="serviceTitle">{context.translation && context.translation.serFee}</span>
                </div>
                <div>
                  <span className="currencyCode">{req.cur} </span>
                  &nbsp;
                  <FormatCurrency value={sf!==undefined && sf.length > 0?sf[0].complete_sfvalue * props.base_usr_exrate : 0} className="currencyAmount"/>
                </div>
              </div>
              {props.promoDisc!==0 &&
                <div className="dFlex discountWrapper">
                <div>
                  <span className="discount">{context.translation && context.translation.prodisc}</span>
                </div>
                <div>
                  <span className="currencyCode">{req.cur} </span>
                  &nbsp;
                  <FormatCurrency value={props.promoDisc * props.base_usr_exrate} className="currencyAmount"/>
                </div>
              </div>
                }
              {/* <div className="dFlex discountWrapper">
                <div>
                  <span className="discount">{context.translation && context.translation.discount}</span>
                </div>
                <div>
                  <span className="currencyCode">{req.cur} </span>
                  &nbsp;
                  <span className="currencyAmount"><FormatCurrency value={dis ? dis : 0.0} /></span>
                </div>
              </div> */}
              </div>
              }
              <div className="innerSumDivider"></div>
              <div className={flow === 'SRP'?"commonFlex payablePriceWrapper":"commonFlex payablePriceWrapper"}>
                <div>
                  {flow === 'SRP' && <span className="payablePrice">{context.translation && context.translation.totPrice}</span>}
                  {flow === 'REVIEW' && <span className="payablePrice">{context.translation && context.translation.totPayPrice}</span>}
                  
                </div>
                <div>
                  <span className="currencyCode">{req.cur} </span>
                  <span className="currencyAmount">
                    <FormatCurrency
                      value={Utilities.addPrice(pr.tf + calAdtBagPrice() + calChdBagPrice() + calAdtMealPrice() + calChdMealPrice() - (props.promoDisc?props.promoDisc:0) + (props.seatPrice?props.seatPrice:0), calServiceFee(), "Add") * props.base_usr_exrate}
                    />
                  </span>
                </div>
              </div>
              <div className={flow === 'SRP'?"allTaxes":"allTaxes"}>{context.translation && context.translation.inclAllTaxNfee}</div>
        </div>
      )}
    </>
  );
}

export default FlightFareDetails;
