/**
 * @ Author: Srikanth Bandaru
 * @ Create Time: 2023-04-24 12:32:04
 * @ Description: This is an service for hotels api
 */

import axios from "axios";
import api from '../../components/utilities/api'

const HOTEL_BOOK_URL = process.env.REACT_APP_B2C_HOTEL_COMMON;

class HotelService {
  async fireHotelSearchContent(url, headers_params) {
    const responseData = await api.get(url, headers_params);
    return responseData && responseData.data ? responseData.data : undefined;
  }

  async fireHotelSearch(url) {
    const req = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    return await api.get(
      `${HOTEL_BOOK_URL}/hotelavailability/check?${url}`,
      req
    );
  }

  async getHotelReviewContent(url) {
    const req = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    return await api.get(`${url}`, req);
  }

  async saveHotelPriceCheck(url, obj, token) {
    const req = {
        headers: {
            'Content-Type': 'application/json',
            'auth-token': token
          }
       };
    return await api.post(`${url}`, obj, req);
  }

  async getGuestReviews(url) {
    const req = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    return await api.get(`${url}`, req);
  }
}

export default new HotelService();
