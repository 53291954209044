import React from "react";
import { useContext } from "react";
import { Context } from "../../../../App";
import { Collapse, Radio, Row, Col, Typography, Button } from "antd";
import Link from "antd/es/typography/Link";
const CDNURL = process.env.REACT_APP_CNT_CDN_URL


const { Panel } = Collapse;
const { Title } = Typography;

function ResultsBlock() {
  const [context, setContext] = useContext(Context)
  return (
    <>
    <div className="results-block">
            <div className="topSemicircle"></div>

            <div className="rb-contLeft">
              <div className="rb-top dFlex">
                <div className="info">
                  <span className="smallFlight">
                    <i className="icon icon-flight loaderOpacity"></i>
                  </span>
                  <span className="route routeLoader">
                    <div className="emptyLoader100-12"></div>
                  </span>
                  <span className="rightArrow">
                    <i className="icon icon-next-long-arrow-right-svgrepo-com loaderOpacity"></i>
                  </span>
                  <span className="route routeLoader">
                    <div className="emptyLoader100-12"></div>
                  </span>
                  <span className="">
                    <div className="emptyLoader100-12"></div>
                  </span>
                  <span className="refund refundLoader">
                    <div className="emptyLoader100-12"></div>
                  </span>
                </div>
              </div>

              <div className="rb-mid">
                
                    <Row>
                      <Col md={24}>
                        <div className="ResultInfo dFlex">
                          <div className="onlyFlex order">
                            <div className="airlineName">
                              {/* <img
                                src={
                                  CDNURL+"/airlines/1x/airline-logos/multipleAirline.png"
                                }
                                alt="AirLine"
                                className="loaderOpacity"
                              /> */}
                              <img className="emptyLoaderCircle36" />
                              <div>
                                <strong className="planeName">
                                  <div className="emptyLoader100-12"></div>
                                </strong>
                                <span>
                                  <div className="emptyLoader60-6"></div>
                                </span>
                              </div>
                            </div>
                            <div className="airlineInfo">
                              <Row className="aiTop">
                                <Col className="fromTime">
                                  <div
                                    className="emptyLoader60-12"
                                    style={{ height: "20px" }}
                                  ></div>
                                </Col>
                                <Col className="">
                                  <span>
                                    <i className="icon icon-time loaderOpacity"></i>{" "}
                                    <div className="sortLoader"></div>
                                  </span>
                                </Col>
                                <Col className="toTime">
                                  <div
                                    className="emptyLoader60-12"
                                    style={{ height: "20px" }}
                                  ></div>
                                </Col>
                              </Row>
                              <Row className="aiBottom">
                                <Col className="fromDest">
                                  <div className="emptyLoader40-6"></div>
                                </Col>
                                <Col className="stop">
                                  <span></span>
                                </Col>
                                <Col className="toDest">
                                  <div className="emptyLoader40-6"></div>
                                </Col>
                              </Row>
                            </div>
                          </div>

                          <div className="weightSeats order">
                            <div className="weight">
                              <span>
                                <i className="icon icon-Baggage loaderOpacity"></i>
                                <div className="emptyLoader40-12"></div>
                              </span>
                            </div>
                            <div className="seats loaderOpacity">
                              <span>
                                <div
                                  className="emptyLoader60-6"
                                  style={{ height: "8px" }}
                                ></div>
                              </span>{" "}
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  
              </div>


              <div className="rb-top">
                <div className="info">
                  <span className="smallFlight">
                    <i className="icon icon-flight loaderOpacity"></i>
                  </span>
                  <span className="route routeLoader">
                    <div className="emptyLoader100-12"></div>
                  </span>
                  <span className="rightArrow">
                    <i className="icon icon-next-long-arrow-right-svgrepo-com loaderOpacity"></i>
                  </span>
                  <span className="route routeLoader">
                    <div className="emptyLoader100-12"></div>
                  </span>
                  <span className="">
                    <div className="emptyLoader100-12"></div>
                  </span>
                  <span className="refund refundLoader">
                    <div className="emptyLoader100-12"></div>
                  </span>
                </div>
              </div>

              <div className="rb-mid">
                
                    <Row>
                      <Col md={24}>
                        <div className="ResultInfo dFlex">
                          <div className="onlyFlex order">
                            <div className="airlineName">
                              {/* <img
                                src={
                                  CDNURL+"/airlines/1x/airline-logos/multipleAirline.png"
                                }
                                alt="AirLine"
                                className="loaderOpacity"
                              /> */}
                              <img className="emptyLoaderCircle36" />
                              <div>
                                <strong className="planeName">
                                  <div className="emptyLoader100-12"></div>
                                </strong>
                                <span>
                                  <div className="emptyLoader60-6"></div>
                                </span>
                              </div>
                            </div>
                            <div className="airlineInfo">
                              <Row className="aiTop">
                                <Col className="fromTime">
                                  <div
                                    className="emptyLoader60-12"
                                    style={{ height: "20px" }}
                                  ></div>
                                </Col>
                                <Col className="">
                                  <span>
                                    <i className="icon icon-time loaderOpacity"></i>{" "}
                                    <div className="sortLoader"></div>
                                  </span>
                                </Col>
                                <Col className="toTime">
                                  <div
                                    className="emptyLoader60-12"
                                    style={{ height: "20px" }}
                                  ></div>
                                </Col>
                              </Row>
                              <Row className="aiBottom">
                                <Col className="fromDest">
                                  <div className="emptyLoader40-6"></div>
                                </Col>
                                <Col className="stop">
                                  <span></span>
                                </Col>
                                <Col className="toDest">
                                  <div className="emptyLoader40-6"></div>
                                </Col>
                              </Row>
                            </div>
                          </div>

                          <div className="weightSeats order">
                            <div className="weight">
                              <span>
                                <i className="icon icon-Baggage loaderOpacity"></i>
                                <div className="emptyLoader40-12"></div>
                              </span>
                            </div>
                            <div className="seats loaderOpacity">
                              <span>
                                <div
                                  className="emptyLoader60-6"
                                  style={{ height: "8px" }}
                                ></div>
                              </span>{" "}
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                   
              </div>

              <div className="rb-bot">
                <div className="dFlex flightDetails">
                    <div className="emptyLoader100-12"></div>
                    <div className="emptyLoader100-12"></div>
                </div>
              </div>
            </div>
            <div className="rb-contRight">
              <div className="shareIconLoader">
                <i className=""><div className="emptyLoader30-30"></div></i>
              </div>
              <div className="rb-price">
                <Title level={5}>
                  <div className="emptyLoader100-12"></div>
                </Title>
                <span className="travellerPrice">
                  <div className="emptyLoader80-6"></div>
                </span>
                <Button type="primary" className="cntBtn">
                  {/* {context.translation && context.translation.bokFlgt}
                  <i className="sprite btn-arrow-sprite"></i> */}
                  <div className="emptyLoader100-12"></div>
                </Button>
              </div>
            </div>
            <div className="botSemicircle"></div>
            <div className="botSemicircleCover"></div>
          </div>
    </>
  )
}

function FlightSearchLoader() {
  const [context, setContext] = useContext(Context)
  return (
    <div className="container resultsPage">
      <Row>
      <div className="openFilter openFilterLoader">
            <Button type="secondary" className="openFilterLoader">
            <i className="icon icon-Filter loaderOpacity"></i>
            <div className="emptyLoader100-12" style={{ height: "20px" }}></div>
            </Button>
          </div>
      <Col xl={6} lg={0} md={0} sm={0} xs={0}>
          <div className="filterWrapper">
            <div className="cardWithShadow">
              <Title level={5} className="collapseLoader">
                <i className=""><div className="emptyLoader15-12:empty" style={{ height: "12px" }}></div></i> <div className="emptyLoader100-12" style={{ height: "20px" }}></div>
              </Title>
            </div>

            <div className="filterYourResult marginTop30">
              <div className="commonFlex">
                <div>
                  <Title level={4}><div className="emptyLoader100-12" style={{ height: "20px" }}></div></Title>
                </div>
                <div className="commonFlex">
                  <Link>
                    <div className="emptyLoader100-12"></div>
                  </Link>
                </div>
              </div>
              <span><div className="emptyLoader100-12"></div></span>
            </div>

            <Collapse
              size="small"
              defaultActiveKey={['1', '2', '3', '4', '5', '6', '7', '8']}
              items={[
                {
                key: '1',
                label: <><div className="emptyLoader100-12"></div></>,
                className: "marginTop30 rangeSliders collapseLoader",
                children: <>
                  <div className="priceLoader" style={{ margin: "0" }}></div>
                  </>,
                },
                {
                  key: '2',
                  label: <><div className="emptyLoader100-12"></div></>,
                  className: "marginTop30 custumCheckBox collapseLoader",
                  children: <>
                    <div className="stops">
                      <div className="selectListLoader"></div>
    
                      <div className="selectListLoader"></div>
                    </div>
                  </>,
                },
                {
                  key: '3',
                  label: <><div className="emptyLoader100-12"></div></>,
                  className: "marginTop30 custumRadio collapseLoader",
                  children: <>
                    <div className="selectListLoader"></div>
                    <div className="selectListLoader"></div>
                    <div className="selectListLoader"></div>
                    <div className="selectListLoader"></div>
                  </>,
                },
                {
                  key: '4',
                  label: <><div className="emptyLoader100-12"></div></>,
                  className: "marginTop30 flightTimings rangeSliders collapseLoader",
                  children: <>
                    <Title level={5}><div className="emptyLoader100-12"></div></Title>
                    <div className="priceLoader"></div>
                    <Title level={5} className="mt">
                    <div className="emptyLoader100-12"></div>
                    </Title>
                    <div className="priceLoader"></div>
                    <Title level={5}><div className="emptyLoader100-12"></div></Title>
                    <div className="priceLoader"></div>
                    <Title level={5} className="mt">
                    <div className="emptyLoader100-12"></div>
                    </Title>
                    <div className="priceLoader"></div>
                  </>,
                },
                {
                  key: '5',
                  label: <><div className="emptyLoader100-12"></div></>,
                  className: "marginTop30 custumRadio collapseLoader",
                  children: <>
                    <div className="selectListLoader"></div>
                    <div className="selectListLoader"></div>
                  </>,
                },
                {
                  key: '6',
                  label: <><div className="emptyLoader100-12"></div></>,
                  className: "marginTop30 custumCheckBox collapseLoader",
                  children: <>
                    <div className="selectListLoader"></div>
                    <div className="selectListLoader"></div>
                    <div className="selectListLoader"></div>
                    <div className="selectListLoader"></div>
                    <div className="selectListLoader"></div>
                  </>,
                },
                {
                  key: '7',
                  label: <><div className="emptyLoader100-12"></div></>,
                  className: "marginTop30 custumCheckBox collapseLoader",
                  children: <>
                    <div className="selectListLoader"></div>
                    <div className="selectListLoader"></div>
                    <div className="selectListLoader"></div>
                  </>,
                },
                {
                  key: '8',
                  label: <><div className="emptyLoader100-12"></div></>,
                  className: "marginTop30 custumCheckBox collapseLoader",
                  children: <>
                    <div className="selectListLoader"></div>
                    <div className="selectListLoader"></div>
                    <div className="selectListLoader"></div>
                  </>,
                }
              ]}
            >
            </Collapse>
          </div>
        </Col>
        <Col xl={18} lg={24} md={24} sm={24} xs={24}>
        <div className="rightSideResult">
          <Row>
            <Col xl={10} lg={24} md={24} xs={24}>
              <div className="cardWithShadow sorting">
                <div className="dFlex">
                  <div className="cheapest">
                    <i className=""><div className="emptyLoaderCircle32" style={{ height: "32px" }}></div></i>
                    <span className="heading"><div className="emptyLoader100-12"></div></span>
                    <span className="currency">
                      <div className="emptyLoader80-6"></div>
                    </span>
                  </div>
                  <div className="quickest">
                    <i className="" style={{ background: "transparent" }}><div className="emptyLoaderCircle34" style={{ height: "34px" }}></div></i>
                    <span className="heading"><div className="emptyLoader100-12"></div></span>
                    <span className="currency">
                      <div className="emptyLoader80-6"></div>
                    </span>
                  </div>
                </div>
              </div>
            </Col>
            <Col xl={14} lg={24} md={24} xs={24}>
              <div className="cardWithShadow sortingOption">
                <div className="dFlex">
                <i className=""><div className="emptyLoaderCircle32" style={{ height: "32px" }}></div></i>
                  <div className="">
                    <div className="sortLoader"></div>
                  </div>

                  <div className="">
                    <div className="sortLoader"></div>
                  </div>

                  <div className="">
                    <div className="sortLoader"></div>
                  </div>

                  <div className="ant-select ant-select-single ant-select-show-arrow">
                    <div className="sortLoader"></div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>

          <ResultsBlock/>
          <ResultsBlock/>
          <ResultsBlock/>
          <ResultsBlock/>
          <ResultsBlock/>
        </div>
        </Col>
      </Row>
    </div>
  );
}

export default FlightSearchLoader;
