/**
 * @ Author: Lakshmi Narayana
 * @ Create Time: 2023-07-13
 * @ Description: This is an service for Common CNT Sevice
 */

import axios from "axios";
import api from "../../components/utilities/api"
const API_URL = process.env.REACT_APP_CHKOUT_PAY_URL;
const SK_KEY = process.env.REACT_APP_PAY_SECURITY_KEY;
const CRYPTO = process.env.REACT_APP_B2C_ENGINE_URL
class PaymentService {
    
  async callCheckoutPay(data){
    const headers = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${SK_KEY}`
          }
    };
    return await api.post(`${API_URL}`,data,headers);
  }

  async getPaymentInfo(payId){
    const headers = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${SK_KEY}`
          }
    };
    return await api.get(`${API_URL}/${payId}`,headers);
  }

  async validatePay(obj,token) {
    const headers = {
      headers: {
        "Content-Type": "application/json",
        'auth-token': token
      },
    };
    return await api.post(`${CRYPTO}/common/crypto/validate_pay`,obj,headers)
  }

}

export default new PaymentService();
